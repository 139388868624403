import React from "react";
import {
    Row,
    Col,
    Fade,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import FormikEditable from '../../../../components/formik/FormikEditable';
import { FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import { object, string } from 'yup';
import { success, fail } from '@spordle/toasts';
import PropTypes from 'prop-types';
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';

// Context
import { PeriodsContext } from '../../../../contexts/contexts';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import withContexts from '../../../../helpers/withContexts';

// Language
import Translate from "@spordle/intl-elements";
import SidePanel from "../../../../components/sidePanel/SidePanel";
import UserImg from "../../../../components/UserImg";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { RolesContext } from "../../../../contexts/RolesContext";
import OverlayLoader from "../../../../components/loading/OverlayLoader";

class SidePanelOrganizationActivityPeriods extends React.Component{

    state = {
        loadingState: 'lazy',
        activeTab: '1',
    }

    toggleTab = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState({ activeTab: tab });
        }
    }

    // updateI18nInfo = () => {
    //     this.i18n = initSidePanelViewI18n(this.props.i18n.languages, [ 'name', 'description' ], this.props.getCommonValue('i18n'));
    // }

    componentDidMount(){
        // this.updateI18nInfo();
        this.setState({ loadingState: 'success' })
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows !== this.props.selectedRows){
            this.setState(() => ({ loadingState: 'lazy' }))
            // this.updateI18nInfo();
            this.setState(() => ({ loadingState: 'success' }))
        }
    }

    canEdit = this.props.RolesContext.canDoAction('EDIT', 'organization', 'organization_activity_periods')

    render(){
        return (
            <I18nHelperContext.Consumer>
                { (i18nHelper) => (
                    <OverlayLoader isLoading={this.state.loadingState === 'lazy'}>
                        <Fade in={this.state.loadingState === 'success'}>
                            <SidePanel.Header noBorder>
                                <div className='d-flex mb-2 align-items-center'>
                                    <SidePanel.ToggleButton />
                                    <SidePanel.ActionsMenu action='DELETE' componentCode='organization' componentPermissionCode='organization_activity_periods'>
                                        <SidePanel.MenuDelete
                                            onConfirm={() => {
                                                this.props.PeriodsContext.deleteActivityPeriod(this.props.getCommonValue('activity_period_id'))
                                                    .then(() => {
                                                        this.props.tableRef.refreshTable();
                                                        success();
                                                        this.props.forceCloseSidePanel();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                            }}
                                            translateModalMsg
                                            modalMsg='organization.settings.sidePanelOrganizationPeriods.actions.remove.topText'
                                            modalContent={
                                                <Row className='mt-3'>
                                                    <Col md='6' className='d-flex align-items-center justify-content-center flex-column border-right'>
                                                        <div className='font-medium'>
                                                            <DisplayI18n
                                                                field="name"
                                                                i18n={this.props.getCommonValue('i18n')}
                                                                defaultValue={this.props.getCommonValue('name')}
                                                            />
                                                        </div>
                                                        <div className='font-muted'>
                                                            <DisplayI18n
                                                                field="description"
                                                                i18n={this.props.getCommonValue('i18n')}
                                                                defaultValue={this.props.getCommonValue('description')}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md='6' className='d-flex align-items-center justify-content-center flex-column'>
                                                        <div className='mr-2'>
                                                            <UserImg
                                                                filePos={this.props.OrganizationContext.logo?.file_position}
                                                                src={this.props.OrganizationContext.logo?.full_path}
                                                                alt={this.props.OrganizationContext.organisation_name}
                                                                abbr={this.props.OrganizationContext.abbreviation}
                                                            />
                                                        </div>
                                                        <div className='font-medium'>{this.props.OrganizationContext.organisation_name}</div>
                                                    </Col>
                                                </Row>
                                            }
                                        />
                                    </SidePanel.ActionsMenu>
                                </div>
                                <SidePanel.Title>
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={this.props.getCommonValue('name')}
                                        i18n={this.props.getCommonValue('i18n')}
                                    />
                                </SidePanel.Title>
                            </SidePanel.Header>

                            <Nav tabs>
                                <NavItem className="flex-grow-1 text-center w-100">
                                    <NavLink
                                        className={stringBuilder({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggleTab('1'); }}
                                    >
                                        <Translate id='organization.settings.sidePanelOrganizationPeriods.tabs.details' />
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    {/* PERIOD INFO */}
                                    <div className='p-3 border-bottom'>
                                        <div className="h4 font-bold mb-3"><Translate id='organization.settings.sidePanelOrganizationPeriods.title' /></div>
                                        <Row>
                                            <Col md='12'>
                                                {/* STATUS */}
                                                <div className="mb-3">
                                                    <div className="text-muted"><Translate id='organization.settings.sidePanelOrganizationPeriods.status' /></div>
                                                    <FormikEditable
                                                        readOnly={!this.canEdit}
                                                        noConfirmation
                                                        id="active_form"
                                                        initialValues={{
                                                            active: this.props.getCommonValue("active"),
                                                        }}
                                                        validationSchema={object().shape({
                                                            active: string().required(<Translate id='organization.settings.sidePanelOrganizationPeriods.status.required' />),
                                                        })}
                                                        onSubmit={(values) => {
                                                            if(values.active !== this.props.getCommonValue("active")){
                                                                this.setState({ loadingState: 'lazy' });
                                                                const newValues = this.props.createNewValues(values);
                                                                this.props.PeriodsContext.updateActivityPeriod(this.props.getCommonValue('activity_period_id'), values)
                                                                    .then(() => {
                                                                        this.props.syncRows(newValues);
                                                                        success();
                                                                        this.setState({ loadingState: 'success' });
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                            this.setState({ loadingState: 'success' });
                                                                        }
                                                                    })
                                                            }
                                                        }}
                                                    >
                                                        {(isEditing, options) => {
                                                            if(!isEditing){
                                                                return (
                                                                    <div className="font-medium">
                                                                        <i className={`mdi ${this.props.getCommonValue("active") == '1' ? 'mdi-check text-primary' : 'mdi-close text-danger'} mr-2`} />
                                                                        <span><Translate id={`organization.settings.sidePanelOrganizationPeriods.status.${this.props.getCommonValue("active") == '1' ? 'active' : 'inactive'}`} /></span>
                                                                    </div>
                                                                )
                                                            }
                                                            return (
                                                                <FormikSelect
                                                                    id='active' name='active'
                                                                    autoFocus menuIsDefaultOpen
                                                                    onOptionSelected={() => {
                                                                        options.stopEditing()
                                                                    }}
                                                                    defaultData={[
                                                                        { value: '1', label: 'organization.settings.sidePanelOrganizationPeriods.status.active', translateLabel: true },
                                                                        { value: '0', label: 'organization.settings.sidePanelOrganizationPeriods.status.inactive', translateLabel: true },
                                                                    ]}
                                                                    loadingStatus='success'
                                                                />
                                                            )

                                                        }}
                                                    </FormikEditable>
                                                </div>
                                            </Col>

                                            <RenderI18nForm field="name">
                                                {({ fieldName, fieldLabel }) => {
                                                    return (
                                                        <Col md='12'>
                                                            <div className='mb-3'>
                                                                <div className='text-muted'>{fieldLabel}</div>
                                                                <FormikEditable
                                                                    readOnly={!this.canEdit}
                                                                    id={fieldName}
                                                                    initialValues={i18nHelper.getInitialValues(this.props.selectedRows[0])}
                                                                    validationSchema={object().shape(
                                                                        i18nHelper.getValidationSchema({ name: string().required(<Translate id='form.validation.name.required' />) }),
                                                                    )}
                                                                    onSubmit={(values) => {
                                                                        this.setState({ loadingState: 'lazy' });

                                                                        this.props.PeriodsContext.updateActivityPeriod(this.props.getCommonValue('activity_period_id'), i18nHelper.getAPIValues(values))
                                                                            .then(() => {
                                                                                this.props.syncRows(this.props.createNewValues(values));
                                                                                success();
                                                                                this.setState({ loadingState: 'success' });
                                                                            })
                                                                            .catch((error) => {
                                                                                if(!AxiosIsCancelled(error.message)){
                                                                                    fail({
                                                                                        msg: 'misc.error',
                                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                        skipInfoTranslate: true,
                                                                                    })
                                                                                    console.error(error.message)
                                                                                    this.setState({ loadingState: 'success' });
                                                                                }
                                                                            })
                                                                    }}
                                                                >
                                                                    {(isEditing) => {
                                                                        if(!isEditing){
                                                                            return (
                                                                                <div className='font-medium text-dark'>
                                                                                    <DisplayI18n
                                                                                        field={fieldName}
                                                                                        defaultValue={this.props.selectedRows[0].name || '-'}
                                                                                        i18n={this.props.selectedRows[0].i18n}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }
                                                                        return (
                                                                            <FormikInputText id={fieldName} name={fieldName} trim />
                                                                        )

                                                                    }}
                                                                </FormikEditable>
                                                            </div>
                                                        </Col>

                                                    )
                                                }}
                                            </RenderI18nForm>
                                            <RenderI18nForm field="description">
                                                {({ fieldName, fieldLabel }) => {
                                                    return (
                                                        <Col md='12'>
                                                            <div className='mb-3'>
                                                                <div className='text-muted'>{fieldLabel}</div>
                                                                <FormikEditable
                                                                    readOnly={!this.canEdit}
                                                                    id={fieldName}
                                                                    initialValues={i18nHelper.getInitialValues(this.props.selectedRows[0])}
                                                                    validationSchema={object().shape(
                                                                        i18nHelper.getValidationSchema({ description: string().required(<Translate id='form.validation.description.required' />) }),
                                                                    )}
                                                                    onSubmit={(values) => {
                                                                        this.setState({ loadingState: 'lazy' });

                                                                        this.props.PeriodsContext.updateActivityPeriod(this.props.getCommonValue('activity_period_id'), i18nHelper.getAPIValues(values))
                                                                            .then(() => {
                                                                                this.props.syncRows(this.props.createNewValues(values));
                                                                                success();
                                                                                this.setState({ loadingState: 'success' });
                                                                            })
                                                                            .catch((error) => {
                                                                                if(!AxiosIsCancelled(error.message)){
                                                                                    fail({
                                                                                        msg: 'misc.error',
                                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                        skipInfoTranslate: true,
                                                                                    })
                                                                                    console.error(error.message)
                                                                                    this.setState({ loadingState: 'success' });
                                                                                }
                                                                            })
                                                                    }}
                                                                >
                                                                    {(isEditing) => {
                                                                        if(!isEditing){
                                                                            return (
                                                                                <div className='font-medium text-dark'>
                                                                                    <DisplayI18n
                                                                                        field={fieldName}
                                                                                        defaultValue={this.props.selectedRows[0].name || '-'}
                                                                                        i18n={this.props.selectedRows[0].i18n}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }
                                                                        return (
                                                                            <FormikInputText id={fieldName} name={fieldName} trim />
                                                                        )

                                                                    }}
                                                                </FormikEditable>
                                                            </div>
                                                        </Col>

                                                    )
                                                }}
                                            </RenderI18nForm>
                                        </Row>

                                        {/* DISPLAY ORDER */}
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='organization.settings.organizationPeriods.table.display_order' /></div>
                                            <FormikEditable
                                                readOnly={!this.canEdit}
                                                noConfirmation
                                                id="display_order_form"
                                                initialValues={{
                                                    display_order: this.props.getCommonValue("display_order"),
                                                }}
                                                validationSchema={object().shape({
                                                    display_order: string().required(<Translate id='organization.settings.sidePanelOrganizationPeriods.display_order.required' />),
                                                })}
                                                onSubmit={(values) => {
                                                    if(values.display_order !== this.props.getCommonValue("display_order")){
                                                        this.setState({ loadingState: 'lazy' });
                                                        const newValues = this.props.createNewValues(values);
                                                        this.props.PeriodsContext.updateActivityPeriod(this.props.getCommonValue('activity_period_id'), values)
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                                this.setState({ loadingState: 'success' });
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                    this.setState({ loadingState: 'success' });
                                                                }
                                                            })
                                                    }
                                                }}
                                            >
                                                {(isEditing, options) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className="font-medium">
                                                                {this.props.getCommonValue("display_order")}
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikInputNumber allowNegative={false} allowedDecimalSeparators={false} allowLeadingZeros={false} id={'display_order'} name={'display_order'} />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>


                                        {/* activityPeriod */}
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='form.fields.excludeAffiliationFee' /></div>
                                            <FormikEditable
                                                readOnly={!this.canEdit}
                                                noConfirmation
                                                id="exclude_affiliation_fee"
                                                initialValues={{
                                                    exclude_affiliation_fee: this.props.getCommonValue("exclude_affiliation_fee"),
                                                }}
                                                validationSchema={object().shape({
                                                    exclude_affiliation_fee: string().required(<Translate id='form.fields.excludeAffiliationFee.required' />),
                                                })}
                                                onSubmit={(values) => {
                                                    if(values.active !== this.props.getCommonValue("active")){
                                                        this.setState({ loadingState: 'lazy' });
                                                        const newValues = this.props.createNewValues(values);
                                                        this.props.PeriodsContext.updateActivityPeriod(this.props.getCommonValue('activity_period_id'), values)
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                                this.setState({ loadingState: 'success' });
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                    this.setState({ loadingState: 'success' });
                                                                }
                                                            })
                                                    }
                                                }}
                                            >
                                                {(isEditing, options) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className="font-medium">
                                                                <i className={`mdi ${this.props.getCommonValue("exclude_affiliation_fee") == '1' ? 'mdi-check text-primary' : 'mdi-close text-danger'} mr-2`} />
                                                                <span><Translate id={`misc.yes.no.${this.props.getCommonValue("exclude_affiliation_fee")}`} /></span>
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikSelect
                                                            id='exclude_affiliation_fee' name='exclude_affiliation_fee'
                                                            autoFocus menuIsDefaultOpen
                                                            onOptionSelected={() => {
                                                                options.stopEditing()
                                                            }}
                                                            defaultData={[
                                                                { value: '1', label: 'misc.yes', translateLabel: true },
                                                                { value: '0', label: 'misc.no', translateLabel: true },
                                                            ]}
                                                            loadingStatus='success'
                                                        />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </Fade>

                    </OverlayLoader>
                )}
            </I18nHelperContext.Consumer>
        )
    }
}

SidePanelOrganizationActivityPeriods.propTypes = {
    tableRef: PropTypes.object.isRequired,
    i18n: PropTypes.object,
}

export default withContexts(PeriodsContext, OrganizationContext, RolesContext)(SidePanelOrganizationActivityPeriods);