export default{
    "settings.crc.tab"                                  : "Background Checks",
    "settings.crc.title"                                : "Background Checks",
    "settings.crc.memberType"                           : "Member type",
    "settings.crc.memberType.required"                  : "Member type is required",
    "settings.crc.rules"                                : "Rules",
    "settings.crc.rules.none"                           : "No rules set",
    "settings.crc.rules.yup.validationType.required"    : "Please select a validation type",
    "settings.crc.rules.yup.duration.required"          : "Please select a duration",
    "settings.crc.validationType"                       : "Validation type",
    "settings.crc.validationType.background_check"      : "Background Check",
    "settings.crc.validationType.vsc"                   : "Vulnerability Check",
    "settings.crc.validationType.declaration"           : "Declaration",
    "settings.crc.duration"                             : "Duration",
    "settings.crc.duration.years"                       : "{yearCount, plural, one {# year} other {# years}}",
    "settings.crc.supportingDocuments"                  : "Supporting document required",
    "settings.crc.supportingDocuments.notRequired"      : "Supporting document not required",
    "settings.crc.complianceDate"                       : "Compliance due date",
    "settings.crc.complianceDate.placeholder"           : "MM-DD",
    "settings.crc.complianceDate.tip"                   : "This is the latest date where the background check needs to be completed. After this date, the member will be flagged as deficient.",
    "settings.crc.minAge"                               : "Minimum age",
    "settings.crc.minAge.tip"                           : "If no minimum age is entered, the background check will be required for all the members of that type.",
    "settings.crc.expirationMethod"                     : "Expiration method",
    "settings.crc.expirationMethod.tip.period"          : "The fixed period method will expire the background check after the date and the background check duration combined. If the total duration exceeds the year duration, the system will cut off 1 year.",
    "settings.crc.expirationMethod.tip.date"            : "The fixed date method will expire the background check after the background check duration starting from when the background check was created.",
    "settings.crc.expirationMethod.required"            : "Please check an expiration method",
    "settings.crc.expirationMethod.expiresOn"           : "Expires on",
    "settings.crc.expirationMethod.date.required"       : "Date required",
    "settings.crc.expirationMethod.date.format.invalid" : "Invalid date format",
    "settings.crc.expirationMethod.date.placeholder"    : "MM-DD",
    "settings.crc.required"                             : "Required",
    "settings.crc.optional"                             : "Optional",
    "settings.crc.enable"                               : "Enable background checks",
    "settings.crc.enabled"                              : "Background Checks Enabled",
    "settings.crc.enabled.by"                           : "Enabled by",
    "settings.crc.disabled"                             : "Background Checks Disabled",
    "settings.crc.enableTip"                            : "Enable background checks to manage rules.",

    "settings.backgroundCheckType.add" : "Add a Background Check Type",
}