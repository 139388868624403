import { Button, Collapse, ModalBody, ModalFooter } from 'reactstrap';
import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import CrossFade from '../../../../../../../components/crossFade/CrossFade';
import CustomAlert from '../../../../../../../components/CustomAlert';
import CustomAnimatedIcon from '../../../../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import { CartsContext } from '../../../../../../../contexts/CartsContext';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext';
import { PeriodsContext } from '../../../../../../../contexts/contexts';
import moment from 'moment';

const MemberProfileRegistrationAddManualConfirmation = ({ registrationContext }) => {
    const cartsContext = useContext(CartsContext);
    const membersContext = useContext(MembersContext);
    const orgContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext)

    const [ isCompleted, setIsCompleted ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);

    const completeRegistration = () => {
        registrationContext.setModalLoading(true)
        cartsContext.signDivisionWaivers({
            organisation_id: orgContext.organisation_id,
            period_id: periodsContext.selectedPeriod.period_id,
            signature_date: moment(registrationContext.viewsInfo.invoiceDate).format('YYYY-MM-DD'),
            identity_id: registrationContext.viewsInfo.selectedIdentity || '',
            first_name: registrationContext.viewsInfo.identityFirstName || '',
            last_name: registrationContext.viewsInfo.identityLastName || '',
            members: [ {
                member_id: membersContext.currentMember.member_id,
                division_id: registrationContext.viewsInfo.divisionId,
                waivers: registrationContext.viewsInfo.formattedWaivers.map((waiver) => ({
                    ...waiver,
                    member_type_id: registrationContext.viewsInfo.memberTypeId,
                })) || [],
            } ],
        })
            .then(() => {
                registrationContext.viewsInfo.isFinal = true;
                setIsCompleted(true);
                registrationContext.setModalLoading(false)
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                    registrationContext.setModalLoading(false)
                    setErrorMessage(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                }
            })
    }

    return (
        <>
            <CrossFade isVisible={!isCompleted}>
                <ModalBody className="text-center">
                    <i className='text-primary mdi mdi-information-outline' style={{ fontSize: 75 }} />
                    <div className="h4 font-bold mb-2"><Translate id='memberProfile.registration.manual.confirmation.title' /></div>
                    <Translate
                        id='memberProfile.registration.manual.confirmation.text'
                        values={{
                            confirm: <Translate id='misc.confirm' />,
                        }}
                    />
                    <Collapse isOpen={!!errorMessage} appear mountOnEnter unmountOnExit className='text-left'>
                        {errorMessage &&
                            <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={errorMessage} translateText={false} toggle={() => setErrorMessage(null)} />
                        }
                    </Collapse>
                </ModalBody>
                <ModalFooter className='justify-content-between'>
                    <Button color='primary' outline type='button' onClick={() => registrationContext.goToViewEmmanuel(registrationContext.views.waivers, true)}><Translate id='misc.previous' /></Button>

                    <div>
                        <Button type='button' onClick={completeRegistration} color='primary'><Translate id='misc.confirm' /></Button>
                        <Button color='primary' outline type='button' onClick={registrationContext.toggle} className='ml-2'><Translate id='misc.cancel' /></Button>
                    </div>
                </ModalFooter>
            </CrossFade>
            <CrossFade isVisible={isCompleted}>
                <ModalBody className="text-center">
                    <CustomAnimatedIcon
                        icon='checkmark'
                        size={75}
                        withCircle
                        className='text-success mb-3'
                    />
                    <div className="h4 font-bold mb-2"><Translate id='memberProfile.registration.manual.confirmation.congrats' /></div>
                    <Translate id='memberProfile.registration.manual.confirmation.completed' />

                    <div className='mt-3'>
                        <Button color='primary' onClick={registrationContext.toggle}><Translate id='misc.close' /></Button>
                    </div>
                </ModalBody>
            </CrossFade>
        </>
    )
}

export default MemberProfileRegistrationAddManualConfirmation