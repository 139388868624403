import { useContext, useState } from "react";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import { useSteps } from "../../../../../components/customHooks/useSteps";
import SignWaiversModalSignatureType from './SignWaiversModalSignatureType';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import { CartsContext } from '../../../../../contexts/CartsContext';
import { waiverAccepted } from "../../../../../helpers/waiversHelper";
import SignWaiversModalReview from "./SignWaiversModalReview";
import SignWaiversModalConfirmation from "./SignWaiversModalConfirmation";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { WaiversContext } from "../../../../../contexts/WaiversContext";
import { AuthContext, PeriodsContext } from "../../../../../contexts/contexts";
import { I18nContext } from "../../../../../contexts/I18nContext";

const TeamSignWaiversModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal analyticsName='team-sign-waivers-modal' isOpen={isOpen}>
            <TeamSignWaiversModalInner {...props} />
        </AnalyticsModal>
    )
}

const TeamSignWaiversModalInner = ({ ...props }) => {
    const [ activeStep, handlers ] = useSteps(3, 0);
    const [ signatureType, setSignatureType ] = useState('');
    const [ teamMemberWaivers, setTeamMemberWaivers ] = useState([]);
    const membersContext = useContext(MembersContext);
    const teamsContext = useContext(TeamsContext);
    const cartsContext = useContext(CartsContext);
    const orgContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext);
    const i18nContext = useContext(I18nContext);
    const authContext = useContext(AuthContext);
    const waiversContext = useContext(WaiversContext);

    const steps = {
        signatureType: 0,
        review: 1,
        confirmation: 2,
    }

    const getTeamMemberWaivers = () => {
        return teamsContext.getRosterTablesData(props.teamId)
            .then((teamMembers) => {
                return Promise.all(teamMembers.filter((m) => m.active == 1).reduce((newArray, teamMember) => {
                    newArray.push(getWaivers(teamMember))
                    return newArray
                }, []))
                    .then((teamMemberWaivers) => {
                        setTeamMemberWaivers(teamMemberWaivers)
                        return teamMemberWaivers
                    })
            })
    }

    const getWaivers = (teamMember) => {
        return membersContext.getMemberMemberTypes(teamMember.member.member_id, teamsContext.cachedTeam?.organisation?.organisation_id)
            .then(async(memberMemberTypes) => {
                const findMemberType = () => {
                    switch (teamMember.position.position_group.position_type){
                        case 'PLAYER':
                            return memberMemberTypes.find((memberMemberType) => memberMemberType.member_type.system_type === 'PLAYER')?.member_type
                        case 'COACH':
                        case 'STAFF':
                            return memberMemberTypes.find((memberMemberType) => memberMemberType.member_type.system_type === 'COACH' || memberMemberType.member_type.system_type === 'BENCH_STAFF')?.member_type
                    }
                }
                const memberType = findMemberType();

                const waivers = await waiversContext.getWaiversByDivisions(teamsContext.cachedTeam.team_category?.division?.division_id, {
                    organisation_id: teamsContext.cachedTeam?.organisation?.organisation_id || orgContext.organisation_id,
                    member_type_id: memberType?.member_type_id,
                    period_id: periodsContext.selectedPeriod.period_id,
                    member_id: teamMember.member.member_id,
                })
                return {
                    teamMember: {
                        ...teamMember,
                        member_type: memberType,
                    },
                    waivers: waivers.filter((waiver) => waiver.critical == 1),
                }
            })
    }

    const signWaivers = () => {
        return cartsContext.signDivisionWaivers({
            organisation_id: teamsContext.cachedTeam?.organisation?.organisation_id || orgContext.organisation_id,
            period_id: periodsContext.selectedPeriod.period_id,
            identity_id: authContext.account.userName,
            members: teamMemberWaivers.reduce((newArray, teamMemberWaiver) => {
                if(teamMemberWaiver.waivers?.length > 0 && !!teamMemberWaiver.teamMember.member_type){
                    newArray.push({
                        member_id: teamMemberWaiver.teamMember.member.member_id,
                        division_id: teamsContext.cachedTeam.team_category?.division?.division_id,
                        waivers: teamMemberWaiver.waivers.map((waiver) => ({
                            waiver_id: waiver.waiver_id,
                            answer: waiverAccepted,
                            language_code: i18nContext.getGenericLocale(),
                            signature_type: signatureType,
                            member_type_id: teamMemberWaiver.teamMember.member_type.member_type_id,
                        })),
                    })
                }
                return newArray
            }, []),
        })
    }

    return (
        <>
            <CrossFade isVisible={activeStep === steps.signatureType}>
                <SignWaiversModalSignatureType {...props} getTeamMemberWaivers={getTeamMemberWaivers} setSignatureType={setSignatureType} next={handlers.next} />
            </CrossFade>
            <CrossFade isVisible={activeStep === steps.review}>
                <SignWaiversModalReview {...props} teamMemberWaivers={teamMemberWaivers} signWaivers={signWaivers} previous={handlers.previous} next={handlers.next} />
            </CrossFade>
            <CrossFade isVisible={activeStep === steps.confirmation}>
                <SignWaiversModalConfirmation {...props} />
            </CrossFade>
        </>
    )
}

export default TeamSignWaiversModal