export default{
    "newnav.toast.msg"         : "Essayez notre nouvelle navigation !",
    "newnav.toast.info"        : "Nous travaillons actuellement pour améliorer votre expérience usager. <link>Cliquez ici pour en savoir plus.</link>",
    "newnav.toast.btn.try"     : "Essayer",
    "newnav.toast.btn.dismiss" : "Non merci",

    "newnav.feedbackModal.lvl.terrible" : "Terrible",
    "newnav.feedbackModal.lvl.ok"       : "Ok",
    "newnav.feedbackModal.lvl.great"    : "Super",
    "newnav.feedbackModal.title"        : "Avez-vous aimé votre expérience ?",
    "newnav.feedbackModal.subtitle"     : "Nous apprécierions avoir vos commentaires pour améliorer votre expérience !",
    "newnav.feedbackModal.skip"         : "Ne plus demander",

    "newnav.feedbackModal.thankYou.title"    : "Merci pour vos commentaires !",
    "newnav.feedbackModal.thankYou.subtitle" : "Nous les lierons pour améliorer votre expérience.",
    "newnav.feedbackModal.thankYou.btn"      : "Poursuivre vers l'ancienne navigation",

    "newnav.recentlyViewed"        : "Récent(s)",
    "newnav.noRecentlyViewed"      : "Aucun récent",
    "newnav.recentlyViewedHistory" : "Historique",
}