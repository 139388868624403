import { stringBuilder } from "@spordle/helpers";
import styles from "./CustomAnimatedIcon.module.scss";

/**
 * Returns an SVG that will animate on mount
 * @param {Object} props
 * @param {string} props.id Id of the component
 * @param {number|string} [props.size] Size of the SVG
 * @param {'checkmark'|'error'} props.icon Icon to display
 * @param {bool} [props.withCircle] Wether or not to show a circle around the icon
 * @param {string} [props.strokeWidth] Size of the stroke (boldness) in px
 * @param {string} [props.className] Class names to be applied on the SVG tag
 * @returns {JSX.Element}
 */
const CustomAnimatedIcon = ({ id, size = '30px', icon, withCircle, strokeWidth = '5px', className }) => {
    const baseMaxViewPort = 188;
    const strokeWidthNumber = parseInt(strokeWidth.split('px')[0]);
    const maxViewPortWithStrokeWidth = baseMaxViewPort + strokeWidthNumber;

    // it might be hard to place these icons with a thicker boldness or different width / height
    // i'd need some time to analyse how these icons, especially the checkmark, are made to make them dynamic
    const getIcon = () => {
        switch (icon){
            case 'checkmark':
                // i took this one online, it has a margin of 46.9 on the sides and a margin of 61.9 for the top and bottom
                // the middle point is placed to have a 90 degrees angle between the 2 lines, ill leave the "hard-coded" values there since im not a mathematician
                return <polyline className={stringBuilder(styles.Checkmark)} points={`46.9 101.4 76.9 131.4 146.4 61.9`} />
            case 'error':
                return (
                    <>
                        <polyline className={stringBuilder(styles.Error1)} points="50,50 143.3,143.3" />
                        <polyline className={stringBuilder(styles.Error2)} points="143.3,50 50,143.3" />
                    </>
                )
            default:
                break;
        }
    }

    return (
        (icon === "lock" || icon === "unlock") ?
            <div style={{ marginLeft: parseInt(size.split("px")[0]) * 1.75 / 2, marginRight: parseInt(size.split("px")[0]) * 1.75 / 2, width: size, height: parseInt(size.split("px")[0]) * 1.5 }} className="d-inline-flex align-items-end">
                <div style={{ borderWidth: strokeWidthNumber / 1.7 }} className={stringBuilder(styles.Lock, { [styles.openLock]: icon === "unlock", [styles.closeLock]: icon === "lock" })} />
            </div>
            :
            <svg
                id={id}
                className={stringBuilder(styles.Svg, 'animate', className)}
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox={`0 0 ${maxViewPortWithStrokeWidth} ${maxViewPortWithStrokeWidth}`}
                width={size}
                height={size}
                strokeWidth={strokeWidth}
            >
                {withCircle &&
                    <circle
                        className={stringBuilder(styles.Circle)}
                        cx={maxViewPortWithStrokeWidth / 2}
                        cy={maxViewPortWithStrokeWidth / 2}
                        r={(baseMaxViewPort - strokeWidthNumber) / 2}
                    />
                }
                {getIcon()}
            </svg>)
}

export default CustomAnimatedIcon