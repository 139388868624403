import { Form, Formik } from "formik"
import AnalyticsModal from "../../../../../analytics/AnalyticsModal"
import OverlayLoader from "../../../../../components/loading/OverlayLoader"
import I18nHelperContextProvider, { displayI18n, DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../../helpers/i18nHelper';
import { Button, FormGroup, FormText, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Translate from "@spordle/intl-elements";
import { FormikInputText } from "@spordle/formik-elements";
import { object, string } from "yup";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { useContext } from 'react';
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import Required from "../../../../../components/formik/Required";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { displayCategory } from "../../../../teams/TeamHelpers";
import { genderLabel } from "../../../../../components/GenderLabel";
import { useGenderSettingsSWR } from "../../../../reports/reportEngine/reportsEngineHelpers";
import { useIntl } from "react-intl";

const EditCompetitionModal = ({ isOpen, ...props }) => {
    return (
        <I18nHelperContextProvider fields={[ 'name' ]}>
            <AnalyticsModal analyticsName="editCompetitionModal" isOpen={isOpen}>
                <EditCompetitionModalInner {...props} />
            </AnalyticsModal>
        </I18nHelperContextProvider>
    )
}

const EditCompetitionModalInner = ({ onSubmit, toggle, competition }) => {
    const orgContext = useContext(OrganizationContext)
    const { getGenericLocale } = useContext(I18nContext);
    const { formatMessage } = useIntl();
    const { data } = useGenderSettingsSWR();

    return (
        <I18nHelperContext.Consumer>
            {(i18nHelper) => (
                <Formik
                    initialValues={{
                        ...i18nHelper.getInitialValues(competition),
                    }}
                    validationSchema={object().shape({
                        ...i18nHelper.getValidationSchema({ name: string().required(<Translate id='organization.grouping.sidePanel.edit.name.required' />) }),
                    })}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();
                        setSubmitting(true);
                        const apiValues = i18nHelper.getAPIValues(values)

                        return orgContext.partiallyUpdateCompetition(competition.competition_id, apiValues)
                            .then(async() => {
                                await onSubmit?.(values);
                                toggle();
                                setSubmitting(false)
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    setSubmitting(false)
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                }
                            })
                    }}
                >
                    {(formik) => (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalHeader toggle={toggle}>
                                    <Translate id='organization.grouping.sidePanel.edit.title' />
                                </ModalHeader>
                                <ModalBody>
                                    <RenderI18nForm field="name">
                                        {({ fieldName, fieldLabel }) => {
                                            const suggestedName = `${displayI18n('name', competition.organisation.i18n, competition.organisation.organisation_name, getGenericLocale())} - ${displayCategory(competition.team_category ? competition.team_category : competition, 'name', getGenericLocale())}${competition.team_category?.gender ? ' - ' + genderLabel(competition.team_category?.gender, data, formatMessage, getGenericLocale()) : ''}`
                                            return (
                                                <FormGroup key={fieldName}>
                                                    <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                    {suggestedName &&
                                                        <FormText>
                                                            <Translate id='organization.grouping.sidePanel.edit.suggested' />:
                                                            <button
                                                                className='reset-btn text-link ml-1' type='button'
                                                                onClick={() => {
                                                                    formik.setFieldValue(fieldName, suggestedName);
                                                                }}
                                                            >
                                                                {suggestedName}
                                                            </button>
                                                        </FormText>
                                                    }
                                                </FormGroup>
                                            )
                                        }}
                                    </RenderI18nForm>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type="submit">
                                        <Translate id='misc.confirm' />
                                    </Button>
                                    <Button color='primary' outline onClick={() => toggle()}>
                                        <Translate id='misc.cancel' />
                                    </Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )}
                </Formik>
            )}
        </I18nHelperContext.Consumer>
    )
}

export default EditCompetitionModal