import OrganizationSettingsBackgroundChecks from '../../views/settings/organization/components/backgroundChecks/OrganizationSettingsBackgroundChecks';
import OrganizationSettingsContactTypes from '../../views/settings/organization/components/contactTypes/OrganizationSettingsContactTypes';
import OrganizationSettingsMerchantAccountProviders from '../../views/settings/organization/components/merchantAccountProviders/OrganizationSettingsMerchantAccountProviders';
import OrganizationSettingsDocuments from '../../views/settings/organization/components/documents/OrganizationSettingsDocuments';
import OrganizationSettingsMemberTypes from '../../views/settings/organization/components/memberTypes/OrganizationSettingsMemberTypes';
import OrganizationSettingsList from '../../views/settings/organization/components/OrganizationSettingsList';
import OrganizationSettingsPhoneTypes from '../../views/settings/organization/components/phoneTypes/OrganizationSettingsPhoneTypes';
import OrganizationSettingsTransfer from '../../views/settings/organization/components/transfer/OrganizationSettingsTransfer';
import OrganizationSettingsTravelPermits from '../../views/settings/organization/components/travelPermits/OrganizationSettingsTravelPermits';
import settingsBackgroundChecksRoutes from './organizationSettings/settingsBackgroundChecksRoutes';
import settingsContactTypesRoutes from './organizationSettings/settingsContactTypesRoutes';
import settingsDocumentsRoutes from './organizationSettings/settingsDocumentsRoutes';
import settingsMemberTypesRoutes from './organizationSettings/settingsMemberTypesRoutes';
import settingsPhoneTypesRoutes from './organizationSettings/settingsPhoneTypesRoutes';
import settingsTransferRoutes from './organizationSettings/settingsTransferRoutes';
import settingsTravelPermitsRoutes from './organizationSettings/settingsTravelPermitsRoutes';
import settingsMerchantAccountRoutes from './organizationSettings/settingsMerchantAccountRoutes';
import OrganizationMessagesSettings from '../../views/settings/organization/components/messages/OrganizationMessagesSettings';
import settingsMessagesRoutes from './organizationSettings/settingsMessagesRoutes';

/**
 * @typedef {object} TabRoutes
 * @property {React.ReactNode} [name]
 * @property {boolean} [redirect]
 */

/** @type {Array.<import('react-router-dom').RouteProps & TabRoutes>} */
const settingsOrganizationTabsRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        path: '',
        exact: true,
        noBreadcrumb: true,
        name: 'settings.tabs.organization',
        component: OrganizationSettingsList,
    },
    {
        path: '/documents',
        name: 'settings.tabs.organization.documents',
        component: OrganizationSettingsDocuments,
        tempTab: true,
        noLink: true,
        subPermissionsRoutes: settingsDocumentsRoutes,
    },
    {
        path: '/background-checks',
        name: 'settings.tabs.organization.backgroundChecks',
        component: OrganizationSettingsBackgroundChecks,
        tempTab: true,
        noLink: true,
        subPermissionsRoutes: settingsBackgroundChecksRoutes,
    },
    {
        path: '/travel-permits',
        name: 'settings.tabs.organization.travelPermits',
        component: OrganizationSettingsTravelPermits,
        tempTab: true,
        noLink: true,
        subPermissionsRoutes: settingsTravelPermitsRoutes,
    },
    {
        path: '/transfer',
        name: 'settings.tabs.organization.transfer',
        component: OrganizationSettingsTransfer,
        tempTab: true,
        noLink: true,
        subPermissionsRoutes: settingsTransferRoutes,
    },
    {
        path: '/member-types',
        name: 'settings.tabs.organization.memberTypes',
        component: OrganizationSettingsMemberTypes,
        tempTab: true,
        noLink: true,
        subPermissionsRoutes: settingsMemberTypesRoutes,
    },
    {
        path: '/phone-types',
        name: 'settings.tabs.organization.phoneTypes',
        component: OrganizationSettingsPhoneTypes,
        tempTab: true,
        noLink: true,
        subPermissionsRoutes: settingsPhoneTypesRoutes,
    },
    {
        path: '/contact-types',
        name: 'settings.tabs.organization.contactTypes',
        component: OrganizationSettingsContactTypes,
        tempTab: true,
        noLink: true,
        subPermissionsRoutes: settingsContactTypesRoutes,
    },
    {
        path: '/merchant-accounts',
        name: 'settings.tabs.organization.merchantAccounts',
        component: OrganizationSettingsMerchantAccountProviders,
        tempTab: true,
        noLink: true,
        subPermissionsRoutes: settingsMerchantAccountRoutes,
    },
    {
        path: '/messages',
        name: 'settings.tabs.organization.messagesSettings',
        component: OrganizationMessagesSettings,
        tempTab: true,
        noLink: true,
        subPermissionsRoutes: settingsMessagesRoutes,
    },
    {
        redirect: true,
        dynamicRedirect: true,
        from: '/setting/organization',
        to: '',
    },
];
export default settingsOrganizationTabsRoutes;