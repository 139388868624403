import { useContext } from "react";
import { ReportsContext } from "../../contexts/ReportsContext";
import Breadcrumbs from "../../layouts/layout-components/breadcrumbs/Breadcrumbs";
import { useLocation, matchPath } from "react-router";
import { DisplayI18n } from "../../helpers/i18nHelper";
import { isMobileDevice } from "../../helpers/constants";

const compareLinks = (currentReport, pathname) => {
    if(currentReport.report_id){
        try{
            const parsedParam = JSON.parse(currentReport.parameter);

            return pathname.includes(parsedParam.route);

        }catch(e){
            console.error(e);
        }
    }

    return false;
}

const ReportsBreadcrumbs = () => {
    if(isMobileDevice)return null;

    const { currentReport } = useContext(ReportsContext);
    const { pathname } = useLocation();
    const match = matchPath(pathname, {
        path: `/reports/:reportPath`,
        exact: true,
        strict: false,
    });
    const isReportPath = (match && match.params.reportPath && compareLinks(currentReport, pathname));
    const isDashboardPath = match?.params?.reportPath === "dashboard";
    return (
        <Breadcrumbs
            withRefresh
            favoriteData={
                isReportPath ?
                    {
                        label: currentReport.name,
                        i18n: currentReport.i18n,
                        breadcrumbs: [ { path: pathname, name: 'sidebar.reports' } ],
                        translate: false,
                    }
                    :
                    isDashboardPath ?
                        {
                            label: 'sidebar.reports',
                            breadcrumbs: [],
                        }
                        :
                        {}
            }
            extraBreadcrumbs={
                isReportPath ?
                    [
                        {
                            skipTranslate: true,
                            path: pathname,
                            name: <DisplayI18n i18n={currentReport.i18n} field="name" defaultValue={currentReport.name} />,
                        },
                    ]
                    :
                    []
            }
            className="mb-2"
        />
    );
}

export default ReportsBreadcrumbs;