export default{
    "organization.profile.staff.modals.addStaff.members"                         : "Existing members",
    "organization.profile.staff.modals.addStaff.title"                           : "Add Staff member",
    "organization.profile.staff.modals.addStaff.btn.selectMember"                : "Select this member",
    "organization.profile.staff.modals.addStaff.btn.createMember"                : "Create a new staff member",
    "organization.profile.staff.modals.addStaff.validation.contactType.required" : "Role is required",
    "organization.profile.staff.modals.addStaff.no.members.found"                : "No members found",

    "organization.profile.staff.spordleTable.noResult"      : "No staff member was found.",
    "organization.profile.staff.spordleTable.label.role"    : "Role(s)",
    "organization.profile.staff.spordleTable.label.contact" : "Contact",

    "organization.profile.staff.minimum.role" : "Minimum one role.",

    "organization.profile.staff.sidePanel.tab.member"          : "Member",
    "organization.profile.staff.sidePanel.formik.label.number" : "Number",
    "organization.profile.staff.sidePanel.formik.label.yrs"    : "yrs.",
    "organization.profile.staff.sidePanel.delete"              : "You are about to delete this staff member",

    "organization.profile.staff.formik.label.contactTypes"                  : "Roles",
    "organization.profile.staff.formik.label.contactTypes.singular"         : "Role",
    "organization.profile.staff.formik.label.contactTypes.role.required"    : "Role is required",
    "organization.profile.staff.formik.label.contactTypes.role.noDuplicate" : "A staff member cannot have a duplicate role",
    "organization.profile.staff.formik.label.contactTypes.add"              : "Add Role",
    "organization.profile.staff.formik.isElected"                           : "Role is Elected",
    "organization.profile.staff.formik.electedUntil"                        : "Elected until",
    "organization.profile.staff.formik.electedUntil.valid"                  : "Date must be valid.",
    "organization.profile.staff.formik.elected"                             : "Elected",

    "organization.profile.staff.show.on" : "Show this contact on the",
    "organization.profile.staff.website" : "website",

    "organization.profile.staff.empty" : "No staff",


}