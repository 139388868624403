import { stringBuilder } from '@spordle/helpers';
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import React from "react";
import { withRouter } from "react-router";
import { Card, CardBody } from "reactstrap";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import EditInstallmentModal from '../../../../../../components/transactions/EditInstallmentModal';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import withContexts from '../../../../../../helpers/withContexts';
import TransactionCredits from "./components/TransactionCredits";
import TransactionHeader from "./components/TransactionHeader";
import TransactionPaymentReceptions from "./components/TransactionPaymentReceptions";
import TransactionRegistration from '../../../../../../components/transactions/TransactionRegistration';
import TransactionTotalDue from "./components/TransactionTotalDue";
import TransationRefunds from "./components/TransationRefunds";
import EditPaymentReceptionModal from '../../../../../../components/transactions/EditPaymentReceptionModal';
import { WatermarkWrapper } from '../../../../../../components/Watermark';

class MemberProfileTransactionsSidepanelInvoice extends React.Component{

    state = {
        editInstallmentModalIsOpen: false,
        invoicePayment: null,
        invoiceItemId: '',
        field: '',
        editPaymentReceptionModalOpen: false,
        currentPaymentReceptionId: '',
    }

    toggleEditModal = () => {
        this.setState((prevState) => ({
            editInstallmentModalIsOpen: !prevState.editInstallmentModalIsOpen,
        }))
    }

    toggleEditPaymentModal = (paymentReceptionId) => {
        this.setState((prevState) => ({
            currentPaymentReceptionId: paymentReceptionId,
            editPaymentReceptionModalOpen: !prevState.editPaymentReceptionModalOpen,
        }))
    }

    onDeletePaymentReception = () => {
        this.props.formatAndSyncRows().then(this.props.setForceRegistrationRefresh);
    }

    onDeleteRefund = () => {
        this.props.formatAndSyncRows().then(this.props.setForceRegistrationRefresh);
    }

    onPaymentReception = (paymentReceptionId, values) => {
        this.props.formatAndSyncRows().then(this.props.setForceRegistrationRefresh);
    }

    onEditInstallment = (values, paymentId, invoiceItemId) => {
        this.props.syncRows(this.props.createNewValues({
            registrations: [
                ...this.props.selectedRows[0].registrations.reduce((newArray, invoiceItem) => {
                    if(invoiceItem.invoice_item_id === invoiceItemId){
                        newArray.push({
                            ...invoiceItem,
                            payments_to_display: invoiceItem.payments_to_display.reduce((newArrayPayments, payment) => {
                                if(payment.invoice_payment_id === paymentId){
                                    newArrayPayments.push({
                                        ...payment,
                                        ...values,
                                    })
                                }else{
                                    newArrayPayments.push(payment)
                                }
                                return newArrayPayments
                            }, []),
                        })
                    }else{
                        newArray.push(invoiceItem)
                    }
                    return newArray
                }, []),
            ],
        }))
    }

    render(){
        const isVoid = this.props.selectedRows[0].status === 'VOID'
        return (
            <>
                {this.state.editPaymentReceptionModalOpen &&
                    <EditPaymentReceptionModal
                        isOpen={this.state.editPaymentReceptionModalOpen}
                        toggle={this.toggleEditPaymentModal}
                        payment={// finds the correct payment reception object from the paymentReceptionId set in the toggle function
                            this.props.selectedRows[0].payment_receptions.find(
                                (paymentReception) => paymentReception.payment_reception_id === this.state.currentPaymentReceptionId,
                            )
                        }
                        invoiceNumber={this.props.selectedRows[0].invoice_number}
                        paymentReceptionId={this.state.currentPaymentReceptionId}// this.props.selectedRows[0].payment_receptions?.[0]?.payment_reception_id
                        onEdit={(values) => {
                            const targetedReception = this.props.selectedRows[0].payment_receptions.findIndex(
                                (paymentReception) => paymentReception.payment_reception_id === this.state.currentPaymentReceptionId,
                            )

                            const newReceptions = this.props.selectedRows[0].payment_receptions;
                            newReceptions[targetedReception].note = values.note;
                            newReceptions[targetedReception].received_at = values.received_at;

                            const newValues = this.props.createNewValues({ payment_receptions: newReceptions });
                            this.props.syncRows(newValues);
                        }}
                    />
                }
                <EditInstallmentModal
                    isOpen={this.state.editInstallmentModalIsOpen}
                    toggle={this.toggleEditModal}
                    toggleSidepanel={this.props.toggle}
                    invoicePayment={this.state.invoicePayment}
                    invoiceItemId={this.state.invoiceItemId}
                    field={this.state.field}
                    onEditInstallment={this.onEditInstallment}
                />

                <WatermarkWrapper
                    showWatermark={isVoid}
                    watermarkProps={{
                        text: 'members.profile.transactions.memberProfileTransactions.sidePanel.status.VOID',
                    }}
                >
                    <SidePanel.Header>
                        <TransactionHeader
                            {...this.props}
                            refreshTable={this.props.tableRef.refreshTable}
                            formatAndSyncRows={this.props.formatAndSyncRows}
                            hideHeader={!!this.props.hideHeader}
                            //onCancelInstallments={this.onCancelInstallments}
                            onPaymentReception={this.onPaymentReception}
                            transaction={this.props.selectedRows[0]}
                            disableActions={isVoid}
                        />
                    </SidePanel.Header>

                    <div className="p-3">
                        {/* TOTAL / DUE */}
                        <TransactionTotalDue totalAmount={this.props.selectedRows[0].total} dueAmount={this.props.selectedRows[0].due} />

                        {/* Refunds */}
                        <TransationRefunds
                            totalRefundAmount={this.props.selectedRows[0].total_refunded_amount}
                            refunds={this.props.selectedRows[0].refunds}
                            invoiceNumber={this.props.selectedRows[0].invoice_number}
                            onDeleteRefund={this.onDeleteRefund}
                            disableActions={isVoid}
                        />

                        {/* Payments */}
                        <TransactionPaymentReceptions
                            paymentReceptions={this.props.selectedRows[0].payment_receptions}
                            invoiceNumber={this.props.selectedRows[0].invoice_number}
                            onDeletePaymentReception={this.onDeletePaymentReception}
                            toggleEdit={this.toggleEditPaymentModal}
                            disableActions={isVoid}
                        />

                        {/* Credits */}
                        <TransactionCredits credits={this.props.selectedRows[0].credits} />

                        {/* REGISTRATIONS */}
                        {(this.props.selectedRows[0].registrations && this.props.selectedRows[0].registrations.length > 0) &&
                            <div className='mb-3'>
                                <div className="h4 font-bold"><Translate id='members.profile.transactions.memberProfileTransactions.registrations' /></div>
                                {this.props.selectedRows[0].registrations.map((item) => <TransactionRegistration {...this.props} key={item.invoice_item_id} registration={item} disableActions={isVoid} />)}
                            </div>
                        }

                        {/* ITEMS */}
                        {(this.props.selectedRows[0].otherItems && this.props.selectedRows[0].otherItems.length > 0) &&
                            <div className='mb-3'>
                                <div className="h4 font-bold"><Translate id='members.profile.transactions.memberProfileTransactions.items' /></div>
                                {this.props.selectedRows[0].otherItems.map((item) => {
                                    return (
                                        <Card className={stringBuilder('card-shadow mb-3', { 'bg-light-inverse': item.cancelled_at })} key={item.invoice_item_id}>
                                            <CardBody>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='text-truncate'>
                                                        <div className='h6 mb-0 text-truncate'>
                                                            <span className='font-bold'>
                                                                <DisplayI18n
                                                                    field='name'
                                                                    i18n={item.other_fee.fee.i18n}
                                                                    defaultValue={item.other_fee.fee.name}
                                                                />
                                                            </span>
                                                        </div>
                                                        {item.cancelled_at &&
                                                            <div className='small text-danger'><Translate id='memberProfile.registration.sidepanel.item.cancelled' /></div>
                                                        }
                                                    </div>
                                                    <div className='ml-3'><CurrencyFormat value={parseInt(item.new_amount) / 100} /></div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </WatermarkWrapper>
            </>
        )
    }
}

export default withRouter(withContexts(MembersContext)(MemberProfileTransactionsSidepanelInvoice));