import { Tooltip } from '@mantine/core';
import Translate from '@spordle/intl-elements';

const EnforcedLabel = () => {
    return (
        <div className="small">
            <Tooltip
                withArrow
                position={'right'}
                label={
                    <div className='text-wrap' style={{ maxWidth: '300px' }}>
                        <Translate id={'teams.profile.teamSetting.restrictions.enforced.tooltip'} />
                    </div>
                }
            >
                <i className='mdi mdi-information-outline text-primary mr-1' />
            </Tooltip>
            <Translate id='teams.profile.teamSetting.restrictions.enforced' />
        </div>
    )
}

export default EnforcedLabel