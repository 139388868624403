import Translate from '@spordle/intl-elements';
import {
    Alert,
    Button,
    Collapse,
    Fade,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter, ModalHeader
} from "reactstrap";
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { FormikTextArea } from '@spordle/formik-elements';
import Required from '../../../../../components/formik/Required';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { useContext, useState } from 'react';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import CustomAnimatedIcon from '../../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import InlineCopy from '../../../../../components/inlineCopy/InlineCopy';
import GenderLabel from '../../../../../components/GenderLabel';
import DisplayBirthdateAge from '../../../../../components/DisplayBirthdateAge';

const BlockMemberModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName='BlockMemberModal'>
            <BlockMemberModalInner {...props} />
        </AnalyticsModal>
    )
}

const BlockMemberModalInner = ({ isOpen, toggle, member, refreshMemberProfile, forceCloseSidepanel }) => {
    const membersContext = useContext(MembersContext)
    const [ isSuccess, setIsSuccess ] = useState(false);

    return (
        <Formik
            initialValues={{
                note: '',
            }}
            validationSchema={object().shape({
                note: string().trim().required(<Translate id='members.profile.blockModal.field.note.required' />),
            })}
            onSubmit={({ note }, { setSubmitting, setStatus }) => {
                setStatus();
                membersContext.patchMemberStatus(member.member_id, 'BLOCKED', note)
                    .then(() => {
                        setStatus('success')
                        setSubmitting(false);
                        setIsSuccess(true);
                        refreshMemberProfile();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            setSubmitting(false);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                        }
                    });
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggle}>
                            <Translate id='members.profile.blockModal.title' />
                        </ModalHeader>
                        {(!isSuccess && formik.status !== 'success') &&
                                <div>
                                    <ModalBody className="text-center">
                                        <UserDisplay card className='w-100'>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    abbr={member.first_name[0] + member.last_name[0]}
                                                    src={null}
                                                    width={50}
                                                    height={50}
                                                    alt={`${member.first_name} ${member.last_name}`}
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>{member.first_name} {member.last_name}</UserDisplay.Title>
                                                <UserDisplay.Subtitle className="font-medium text-dark"><InlineCopy tag='div' toCopy={member.unique_identifier} className="font-medium">{'#' + member.unique_identifier || '-'}</InlineCopy></UserDisplay.Subtitle>
                                                <UserDisplay.Subtitle><DisplayBirthdateAge birthdate={member.birthdate} age={member.age} /></UserDisplay.Subtitle>
                                                <UserDisplay.Subtitle><GenderLabel gender={member.gender} /></UserDisplay.Subtitle>
                                            </UserDisplay.Container>
                                            <UserDisplay.Container className='ml-auto text-body'>
                                                <CustomAnimatedIcon strokeWidth="6px" size="30px" icon="lock" />
                                            </UserDisplay.Container>
                                        </UserDisplay>

                                        <FormGroup className="text-left">
                                            <Label for='note' className='text-muted'><Translate id='members.profile.blockModal.field.note' /> <Required /></Label>
                                            <FormikTextArea id='note' name='note' rows='2' trim />
                                        </FormGroup>

                                        <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                            <Alert color='danger' className='text-left'>{formik.status}</Alert>
                                        </Collapse>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                                            <Translate id='transfer.submit' />
                                        </Button>
                                        <Button color='primary' type='button' onClick={toggle} outline disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                    </ModalFooter>
                                </div>
                        }
                        {(isSuccess && formik.status == 'success') &&
                                <div>
                                    <ModalBody className="text-center text-dark">
                                        <Fade in>
                                            <div className='position-relative d-inline-block'>
                                                <CustomAnimatedIcon strokeWidth="6px" size="30px" icon="lock" withCircle />
                                                <i className='mdi mdi-check-circle text-success position-absolute bottom-0 right-0 mr-3 mb-n2 z-index-1 font-16' />
                                            </div>

                                            <div className="text-center h5 font-bold pt-2">
                                                <Translate id="misc.success" />
                                            </div>
                                            <div><Translate id='members.profile.action.block.successText' /></div>
                                        </Fade>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color='primary' type='button' onClick={() => { toggle(); setIsSuccess(false); forceCloseSidepanel && forceCloseSidepanel(); }} outline disabled={formik.isSubmitting}><Translate id='misc.close' /></Button>
                                    </ModalFooter>
                                </div>
                        }
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default BlockMemberModal;