import * as Sentry from "@sentry/react";
// Lang
import Translate from '@spordle/intl-elements';
import React from 'react';
import {
    Button,
    Card,
    Container,
    Modal,
    Spinner
} from "reactstrap";
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import OverlayLoader from "../../../components/loading/OverlayLoader";
import { fail } from "@spordle/toasts";
import Svg from "../../../components/Svg";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { ClinicsContext } from '../../../contexts/ClinicsContext';
import { I18nContext } from '../../../contexts/I18nContext';
import { AppContext } from '../../../contexts/contexts';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { UtilsContext } from '../../../contexts/UtilsContext';
import { DisplayI18n, displayI18n } from '../../../helpers/i18nHelper';
import withContexts from '../../../helpers/withContexts';
import FormClinicCommunications from '../components/clinicCommunications/FormClinicCommunications';
import FormClinicInformation from '../components/clinicInformation/FormClinicInformation';
import FormClinicPaymentOptions from '../components/clinicPaymentOptions/FormClinicPaymentOptions';
import ClinicSessionInformation from "../components/clinicSessionInformation/ClinicSessionInformation";
import CreateClinicReview from './components/CreateClinicReview';
import { getOrganizationCourses } from "../../../api/client/organization";
import { OrganizationContext } from "../../../contexts/OrganizationContext";

class CreateClinic extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            activeStep: 1,
            highestStep: 1,
            duplicateModalOpen: false,
            underCreationModalOpen: false,
            loading: true,
            paymentMethods: null,
            userClinics: null,
            isDeletingUnderCreation: false,
            duplicateWithAttendees: false,
            startDuplicationLoading: false,
            fromDuplication: false,
            moodleCourses: false,
        }
    }

    componentDidUpdate(_prevProps, prevState){
        if(prevState.activeStep !== this.state.activeStep){
            Sentry.addBreadcrumb({
                type: 'info',
                message: 'Step changed',
                level: Sentry.Severity.Log,
                category: 'CreateClinic',
                data: {
                    activeStep: this.state.activeStep,
                    highestStep: this.state.highestStep,
                },
            })
        }
    }

    nextStep = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        this.setState((prevState) => ({ activeStep: prevState.activeStep + 1, highestStep: this.props.location?.data?.duplicate ? 5 : prevState.highestStep === prevState.activeStep ? prevState.highestStep + 1 : prevState.highestStep }))
    }

    previousStep = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
        this.setState((prevState) => ({ activeStep: prevState.activeStep - 1 }))
    }

    findActiveStepFromClinicsContext = () => {
        if(this.props.ClinicsContext.currentClinic?.contacts?.length === 0 || this.props.ClinicsContext.currentClinic?.sessions?.length === 0){
            // either contacts or sessions are missing so we go to step 2
            return 2
        }else if(!(this.props.ClinicsContext.currentClinic?.message_after_registration && this.props.ClinicsContext.currentClinic?.message_confirmation_email)){
            // step 3 is not completed
            return 3
        }else if(!(this.props.ClinicsContext.currentClinic?.accepted_payment_methods.length > 0 || this.props.ClinicsContext.currentClinic?.merchant_account)){
            // step 4 is not completed
            return 4
        }
        // all steps are done, but the clinic is still "UNDER_CREATION", so send the user to step 5 to click the button at the bottom to change the status to "DRAFT"
        return 5

    }

    componentDidMount(){
        // under creation & duplicate logic
        // we basically have 4 options here
        // 1: the user doesn't have an "UNDER_CREATION" clinic with current period and organization ids -> create clinic step 1
        // 2: the user has an "UNDER_CREATION" clinic with current period and organization ids -> load it up and go to whatever step he was on
        // 3: the user has an "UNDER_CREATION" clinic with current period and organization ids and clinic_id is the same as the one in ClinicContext -> no load and go to whatever step he was on
        // 4: if this component receives duplicate as true in the props, we load the clinic, unset the clinic_id and go to step 1
        // all the fields will be pre filled, the user will be able to modify the parts he wants and as soon as the user validates step 1, we create everything
        // new clinic, new contacts, new instructors, new sessions
        // we need to create everything for the steps 2, 3 and 4 because if the user leaves the wizard without finishing the duplicate, we want to keep all the info
        if(this.props.location?.data?.duplicate?.clinic_id){
            this.setState(() => ({ duplicateModalOpen: true }))
        }else{
            // UNDER CREATION LOGIC
            this.props.ClinicsContext.getClinics({ created_by: this.props.IdentityRolesContext.identity.identity_id, active_status: 'UNDER_CREATION' })
                .then(async(userClinics) => {
                    const validClinics = userClinics.filter((clinic) => {
                        if(clinic.created_from_organisation){ // new feature, some clinics won't have that
                            return clinic.created_from_organisation.organisation_id === this.props.IdentityRolesContext.organisation.organisation_id
                        }
                        return true
                    })
                    if(validClinics.length === 0){
                        await this.props.ClinicsContext.clearCurrentClinic()
                        this.setState(() => ({ loading: false, activeStep: 1, highestStep: 1 }))
                    }else{
                        this.setState(() => ({ userClinics: validClinics, underCreationModalOpen: true }))
                    }
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }

        this.props.UtilsContext.getPaymentMethods()
            .then((pM) => this.setState({ paymentMethods: pM }))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

        //Verify if current org has moodle api partner
        this.props.OrganizationContext.getApiPartners()
            .then((apiPartners) => {
                if(apiPartners.find((partner) => partner.api_partner?.name === "Moodle")){
                    getOrganizationCourses(this.props.OrganizationContext.organisation_id, { external_partner: 'MOODLE' })
                        .then((data) => {
                            this.setState(() => ({ moodleCourses: data }));
                        })
                        .catch((error) => { console.error(error.message) });
                }
            })
            .catch((error) => { console.error(error.message) });
    }

    getMobileActiveStepName = () => {
        switch (this.state.activeStep){
            case 1:
                return <Translate id='clinics.createClinic.steps.clinicInfo' />
            case 2:
                return <Translate id='clinics.createClinic.steps.sessionInfo' />
            case 3:
                return <Translate id='clinics.createClinic.steps.communications' />
            case 4:
                return <Translate id='clinics.createClinic.steps.paymentOptions' />
            case 5:
                return <Translate id='clinics.createClinic.steps.review' />
        }
    }

    render(){

        return (
            <>

                {/*--------------------------------------------------------------------------------*/}
                {/* View Header                                                                  */}
                {/*--------------------------------------------------------------------------------*/}
                <ViewHeaderV2
                    title='clinics.createClinic.title'
                    breadcrumbsProps={{
                        disablePeriodSelect: true,
                    }}
                />


                {/* DUPLICATE MODAL */}
                <Modal isOpen={this.state.duplicateModalOpen} unmountOnClose>
                    <OverlayLoader isLoading={this.state.startDuplicationLoading}>
                        {this.props.location.data &&
                            <div className='p-3'>
                                <Card body className={'clinic-header-' + (isNaN(parseInt(this.props.location.data?.duplicate.qualification?.qualification_category?.color)) ? 0 : this.props.location.data?.duplicate.qualification?.qualification_category?.color) + ' mb-3'}>
                                    <div className='font-medium text-white'>
                                        <div className='h4 mb-0 font-bold text-reset'>
                                            <span>
                                                {displayI18n("name", this.props.location.data?.duplicate.qualification?.qualification_category?.i18n, this.props.location.data?.duplicate.qualification?.qualification_category?.name, this.props.I18nContext.getGenericLocale())}
                                            </span>
                                        </div>
                                        <div>
                                            <span>{this.props.location.data?.duplicate.clinic_reference}</span>
                                            <span className='mx-2'>|</span>
                                            <span>
                                                {displayI18n("name", this.props.location.data?.duplicate.qualification?.i18n, this.props.location.data?.duplicate.qualification?.name, this.props.I18nContext.getGenericLocale())}
                                                {this.props.location.data?.duplicate.qualification?.qualification_level ? ' - ' + (displayI18n('name', this.props.location.data?.duplicate.qualification?.qualification_level?.i18n, this.props.location.data?.duplicate.qualification?.qualification_level?.name, this.props.I18nContext.getGenericLocale())) : ''}
                                            </span>
                                        </div>
                                    </div>
                                </Card>
                                {/* <div className='mb-4'>
                                    <div className='font-bold h4'><Translate id='clinics.createClinic.duplication.topText1'/></div>
                                    <Translate id='clinics.createClinic.duplication.topText2'/>
                                </div> */}
                                <div className=''>
                                    <UserDisplay
                                        block card hover
                                        className={`mb-3 ${!this.state.duplicateWithAttendees ? 'border-primary shadow-primary' : ''}`}
                                        onClick={() => this.setState(() => ({ duplicateWithAttendees: false }))}
                                    >
                                        <UserDisplay.Container className="mr-3">
                                            <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                                                <Svg icon={"members"} />
                                            </div>
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title className="font-bold"><Translate id="clinics.createClinic.duplication.simple.title" /></UserDisplay.Title>
                                            <UserDisplay.Subtitle><Translate id="clinics.createClinic.duplication.simple.desc" /></UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                    </UserDisplay>

                                    {/* duplicate with attendees */}
                                    <UserDisplay
                                        block card
                                        hover={this.props.ClinicsContext.currentClinic?.clinic_attendees?.length > 0}
                                        className={`${this.props.ClinicsContext.currentClinic?.clinic_attendees?.length <= 0 ? 'bg-light' : ''} mb-3 ${this.state.duplicateWithAttendees ? 'border-primary shadow-primary' : ''}`}
                                        onClick={() => {
                                            if(this.props.ClinicsContext.currentClinic?.clinic_attendees?.length > 0)
                                                this.setState(() => ({ duplicateWithAttendees: this.props.location.data.duplicate.clinic_id }))
                                        }}
                                    >
                                        <UserDisplay.Container className="mr-3">
                                            <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                                                <Svg icon={"teams"} />
                                            </div>
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title className="font-bold"><Translate id="clinics.createClinic.duplication.withAttendees.title" /></UserDisplay.Title>
                                            <UserDisplay.Subtitle><Translate id="clinics.createClinic.duplication.withAttendees.desc" /></UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                    </UserDisplay>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button
                                        color='primary' className='btn btn-primary mr-2' onClick={() => {
                                            this.setState(() => ({ startDuplicationLoading: true }));
                                            this.props.ClinicsContext.getAllClinicInfos(this.props.location.data.duplicate.clinic_id, { sessions: true, contacts: true, instructors: true, memberTypes: true, merchantAccounts: true })
                                                .then(async() => {
                                                    await this.props.ClinicsContext.clearCurrentClinicId()

                                                    this.setState(() => ({ loading: false, activeStep: 1, highestStep: 1, duplicateModalOpen: false, startDuplicationLoading: false, fromDuplication: true }));
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message);
                                                    }
                                                    this.setState(() => ({ duplicateModalOpen: false, startDuplicationLoading: false }));
                                                    this.props.history.push('/clinics');
                                                })
                                        }}
                                    >
                                        <Translate id='clinics.createClinic.duplication.continue' />
                                    </Button>
                                    <Button color='outline' className='btn btn-outline-primary' onClick={() => { this.props.history.push('/clinics') }}>
                                        <Translate id='misc.cancel' />
                                    </Button>
                                </div>
                            </div>
                        }
                    </OverlayLoader>
                </Modal>


                {/* CLINIC UNDER CREATION MODAL (this modal is triggered if a user already has a clinic under creation, prompting them to either continue the creation of the started clinic or create a new one) */}
                <Modal isOpen={this.state.underCreationModalOpen} unmountOnClose>
                    {
                        this.state.userClinics &&
                            <div className='p-3'>
                                <Card body className={'clinic-header-' + (isNaN(parseInt(this.state.userClinics[0].qualification?.qualification_category?.color)) ? 0 : this.state.userClinics[0].qualification?.qualification_category?.color) + ' mb-3'}>
                                    <div className='font-medium text-white'>
                                        <div className='h4 mb-0 font-bold text-reset'>
                                            <span>
                                                {displayI18n("name", this.state.userClinics[0].qualification?.qualification_category?.i18n, this.state.userClinics[0].qualification?.qualification_category?.name, this.props.I18nContext.getGenericLocale())}
                                            </span>
                                        </div>
                                        <div>
                                            <span>{this.state.userClinics[0].clinic_reference}</span>
                                            <span className='mx-2'>|</span>
                                            <span>
                                                {displayI18n("name", this.state.userClinics[0].qualification?.i18n, this.state.userClinics[0].qualification?.name, this.props.I18nContext.getGenericLocale())}
                                                {this.state.userClinics[0].qualification?.qualification_level ? ' - ' + (displayI18n('name', this.state.userClinics[0].qualification?.qualification_level?.i18n, this.state.userClinics[0].qualification?.qualification_level?.name, this.props.I18nContext.getGenericLocale())) : ''}
                                            </span>
                                        </div>
                                    </div>
                                </Card>
                                <div className='mb-4'>
                                    <div className='font-bold h4'><Translate id='clinics.createClinic.underCreation.topText1' /></div>
                                    <Translate id='clinics.createClinic.underCreation.topText2' />
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <Button
                                        color='primary' className='btn btn-primary mr-2' onClick={() => {
                                            this.props.ClinicsContext.getAllClinicInfos(this.state.userClinics[0].clinic_id, { sessions: true, contacts: true, instructors: true, memberTypes: true, merchantAccounts: true })
                                                .then(() => {
                                                    const step = this.findActiveStepFromClinicsContext();
                                                    this.setState(() => ({ activeStep: step, highestStep: step, loading: false, underCreationModalOpen: false }));
                                                }).catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                        console.error(error);
                                                    }
                                                })
                                        }}
                                    >
                                        <Translate id='clinics.createClinic.underCreation.continue' />
                                    </Button>
                                    <Button
                                        disabled={this.state.isDeletingUnderCreation} color='outline' className='btn btn-outline-primary'
                                        onClick={() => {
                                            this.setState(() => ({ isDeletingUnderCreation: true }))
                                            this.props.ClinicsContext.deleteClinic(this.state.userClinics[0].clinic_id).then(() => {
                                                this.props.ClinicsContext.clearCurrentClinic()
                                                this.setState(() => ({ loading: false, activeStep: 1, highestStep: 1, underCreationModalOpen: false, isDeletingUnderCreation: false }))
                                            })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                        }}
                                    >
                                        <Translate id='clinics.createClinic.underCreation.new' />
                                    </Button>
                                </div>
                            </div>
                    }
                </Modal>

                <div className="page-content container-fluid p-4">
                    <Container className="ml-0">
                        <div className="wizard mb-4">
                            <ol className="step-list">
                                <li className={`step-item ${this.state.activeStep === 1 ? 'current' : this.state.highestStep >= 1 ? 'done' : 'disabled'}`}>
                                    <button
                                        type='button'
                                        className="reset-btn step-link font-medium" onClick={() => {
                                            if(this.state.highestStep >= 1)
                                                this.setState(() => ({ activeStep: 1 }))
                                        }}
                                    >
                                        <span className="step-number">1</span>
                                        <span className="step-text d-none d-sm-block"><Translate id='clinics.createClinic.steps.clinicInfo' /></span>
                                    </button>
                                </li>
                                <li className={`step-item ${this.state.activeStep === 2 ? 'current' : this.state.highestStep >= 2 ? 'done' : 'disabled'}`}>
                                    <button
                                        type='button'
                                        className="reset-btn step-link font-medium" onClick={() => {
                                            if(this.state.highestStep >= 2)
                                                this.setState(() => ({ activeStep: 2 }))
                                        }}
                                    >
                                        <span className="step-number">2</span>
                                        <span className="step-text d-none d-sm-block"><Translate id='clinics.createClinic.steps.sessionInfo' /></span>
                                    </button>
                                </li>
                                <li className={`step-item ${this.state.activeStep === 3 ? 'current' : this.state.highestStep >= 3 ? 'done' : 'disabled'}`}>
                                    <button
                                        type='button'
                                        className="reset-btn step-link font-medium" onClick={() => {
                                            if(this.state.highestStep >= 3)
                                                this.setState(() => ({ activeStep: 3 }))
                                        }}
                                    >
                                        <span className="step-number">3</span>
                                        <span className="step-text d-none d-sm-block"><Translate id='clinics.createClinic.steps.communications' /></span>
                                    </button>
                                </li>
                                <li className={`step-item ${this.state.activeStep === 4 ? 'current' : this.state.highestStep >= 4 ? 'done' : 'disabled'}`}>
                                    <button
                                        type='button'
                                        className="reset-btn step-link font-medium" onClick={() => {
                                            if(this.state.highestStep >= 4)
                                                this.setState(() => ({ activeStep: 4 }))
                                        }}
                                    >
                                        <span className="step-number">4</span>
                                        <span className="step-text d-none d-sm-block"><Translate id='clinics.createClinic.steps.paymentOptions' /></span>
                                    </button>
                                </li>
                                <li className={`step-item ${this.state.activeStep === 5 ? 'current' : this.state.highestStep >= 5 ? 'done' : 'disabled'}`}>
                                    <button
                                        type='button'
                                        className="reset-btn step-link font-medium" onClick={() => {
                                            if(this.state.highestStep >= 5)
                                                this.setState(() => ({ activeStep: 5 }))
                                        }}
                                    >
                                        <span className="step-number">5</span>
                                        <span className="step-text d-none d-sm-block"><Translate id='clinics.createClinic.steps.review' /></span>
                                    </button>
                                </li>
                            </ol>
                            <div className="text-center">
                                <div className="step-title d-sm-none h2 mt-2 font-medium">
                                    {this.getMobileActiveStepName()}
                                </div>
                            </div>
                        </div>

                        {this.state.loading &&
                            <div className='text-center'><Spinner color='primary' type='grow' /></div>
                        }
                        {!this.state.loading &&
                            <>
                                {/* easier workflow that way, each component is unmounted after submitting and mounted when clicking in the wizard */}
                                {this.state.activeStep === 1 &&
                                    <FormClinicInformation exited={this.nextStep} wizard duplicate={!!this.props.location?.data?.duplicate} duplicateWithAttendees={this.state.duplicateWithAttendees} fromDuplication={this.state.fromDuplication} moodleCourses={this.state.moodleCourses} />
                                }
                                {this.state.activeStep === 2 &&
                                    <ClinicSessionInformation exited={this.nextStep} previousStep={this.previousStep} wizard clinicId={this.props.ClinicsContext.currentClinic?.clinic_id} />
                                }
                                {this.state.activeStep === 3 &&
                                    <FormClinicCommunications exited={this.nextStep} previousStep={this.previousStep} wizard />
                                }
                                {this.state.activeStep === 4 &&
                                    <FormClinicPaymentOptions exited={this.nextStep} previousStep={this.previousStep} wizard paymentMethods={this.state.paymentMethods} />
                                }
                                {this.state.activeStep === 5 &&
                                    <CreateClinicReview wizard moodleCourses={this.state.moodleCourses} />
                                }
                            </>
                        }
                    </Container>
                </div>
            </>
        )
    }
}

export default withContexts(ClinicsContext, IdentityRolesContext, I18nContext, UtilsContext, AppContext, OrganizationContext)(CreateClinic);

