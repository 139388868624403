import generalSettings from "./generalSettings";
import merchantAccounts from "./merchantAccounts";
import taxes from "./taxes";
import criminalRecordCheck from "./criminalRecordCheck";
import documentSettings from "./documentSettings";
import transferSettings from "./transferSettings";

export default{
    ...generalSettings,
    ...merchantAccounts,
    ...taxes,
    ...criminalRecordCheck,
    ...documentSettings,
    ...transferSettings,
}