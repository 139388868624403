import { useSpordleTable } from "@spordle/datatables";
import { FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { Button, Card, Col, Row } from "reactstrap";
import { DisplayI18n } from "../../../../helpers/i18nHelper";

const OfficiatingCoachesFilter = ({ supervisionTypes }) => {
    const { filterChange, state, getFilters } = useSpordleTable();

    return (
        <Card body className='card-shadow'>
            <Formik
                enableReinitialize
                initialValues={{
                    supervision_type_ids: getFilters().types || [],
                }}
                onSubmit={(values) => {
                    return filterChange('types', values.supervision_type_ids);
                }}
            >
                {(formik) => (
                    <Form>
                        <Row className="align-items-end" form>
                            <Col sm="8">
                                <FormikSelect
                                    multi
                                    placeholder="components.assignOfficiatingPermissionsModal.supervisionTypes"
                                    clearable
                                    search={false}
                                    id="supervision_type_ids"
                                    name="supervision_type_ids"
                                    renderOption={({ option }) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={option.i18n}
                                            defaultValue={option.name}
                                        />
                                    )}
                                    isLoading={!supervisionTypes}
                                    options={supervisionTypes ? supervisionTypes.map((type) => ({ ...type, label: type.name, value: type.supervision_type_id })) : []}
                                />
                            </Col>
                            <Col sm="4">
                                <Button disabled={state.loadingState == 'loading'} block type="submit" color="primary"><Translate id="misc.search" /></Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}

export default OfficiatingCoachesFilter;