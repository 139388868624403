import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { DisplayI18n, I18nHelperContext } from "../../../../../../helpers/i18nHelper";

const MemberFieldsView = ({ memberFields }) => {
    const { state } = useContext(I18nHelperContext);

    return (
        <>
            <Row form>
                {(memberFields || []).map((field) => (
                    <Col key={field.code} sm={6} className="mb-2">
                        <Card className="card-shadow mb-0">
                            <CardHeader className="text-dark bg-white font-weight-bold px-3 h5"><DisplayI18n field="name" defaultValue={field.name} i18n={field.i18n} /></CardHeader>
                            <CardBody className="p-3">
                                {(state.languages || []).map((lang, i) => (
                                    <div key={lang} className={stringBuilder({ "mt-3": i > 0 })}>
                                        {state.languages.length > 1 && <small className="text-uppercase text-muted"><Translate id={`misc.${lang}.long`} /></small>}
                                        <p className="mb-0 text-dark">{field.i18n?.[lang]?.description || "-"}</p>
                                    </div>
                                ))}
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    );
}

export default MemberFieldsView;