/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React, { createContext } from 'react';
import { ModalHeader } from 'reactstrap';
import OverlayLoader from '../loading/OverlayLoader';
import AppealsAdd1 from './AppealsAdd1';
import AppealsAdd2 from './AppealsAdd2';
import AppealsAdd3 from './AppealsAdd3';
import AnalyticsModal from '../../analytics/AnalyticsModal';

// contexts
import withContexts from '../../helpers/withContexts';
import { MembersContext } from '../../contexts/MembersContext';
import Translate from '@spordle/intl-elements';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../../helpers/i18nHelper';

/** @type {React.Context<Omit<AppealsAddContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const AppealsAddContext = createContext()
AppealsAddContext.displayName = 'AppealsAddContext';

class AppealsAddContextProvider extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            isLoading: false,
            currentView: 'appealInfo',
            formData: {},

            organizationCategoriesIds: [],

            // the contexts for these doesn't handle caching, so we do it here
            // cache to prevent recalling the API everytime the user clicks previous or next to switch steps
            periods: null,
            divisions: null,
            classes: null,
            categories: null,
            status: null,
            approves: null,
            types: null,
            paymentMethods: null,
        }

        this.views = {
            appealInfo: 'appealInfo',
            invoiceInfo: 'invoiceInfo',
            invoiceInfo2: 'invoiceInfo2',
        }
    }

    toggleModal = () => this.props.toggle()

    setLoading = (_isLoading) => this.setState(() => ({ isLoading: _isLoading }))

    setData = (_data) => this.setState((prevState) => ({ formData: { ...prevState.formData, ..._data } }))

    setOrganizationCategoriesIds = (categories) => this.setState(() => ({ organizationCategoriesIds: categories }));

    // caching
    setPeriods = (_periods) => this.setState(() => ({ periods: _periods }));

    setStatus = (_status) => this.setState(() => ({ status: _status }));

    setApproves = (_approves) => this.setState(() => ({ approves: _approves }));

    setTypes = (_types) => this.setState(() => ({ types: _types }));

    setPaymentMethods = (_paymentMethods) => this.setState(() => ({ paymentMethods: _paymentMethods }));

    modalOnClosed = () => {
        this.goToView(this.views.appealInfo);
        this.setLoading(false);

        this.setState(() => ({
            formData: {},

            periods: null,
            divisions: null,
            classes: null,
            categories: null,
            status: null,
            approves: null,
        }))
    }

    goToView = (newView) => {
        // try to keep the calls to this function static for each view, if a skip is to be managed, do it from here, check HCR-site RegistrationContext for an example
        switch (newView){
            case this.views.appealInfo:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.invoiceInfo:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.invoiceInfo2:
                this.setState(() => ({ currentView: newView }))
                break;
        }
    }

    createMemberAppeal = (formData) => {
        if(formData.otherFiles?.length > 0 || formData.accountReceivableFiles?.length > 0){
            formData.attachments = [ ...formData.otherFiles || [], ...formData.accountReceivableFiles || [] ].map((file) => ({
                attachment: file.file,
                active: 1,
                type: file.type,
            }))
            delete formData.otherFiles;
            delete formData.accountReceivableFiles;
        }

        return this.props.MembersContext.createMemberAppeal(this.props.memberId, formData)
            .then(async(id) => {
                if(this.props.refreshTable){
                    this.props.refreshTable();
                }
                this.toggleModal()
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    render(){
        return (
            <AppealsAddContext.Provider value={{ ...this }}>
                <AnalyticsModal analyticsName='AppealsAddContextProvider' isOpen={this.props.isOpen} onClosed={this.modalOnClosed}>
                    <OverlayLoader isLoading={this.state.isLoading}>
                        <ModalHeader tag='div' toggle={this.toggleModal}>
                            <Translate id='members.profile.appeals.sidepanel.add.title' />
                        </ModalHeader>
                        {this.state.currentView === this.views.appealInfo &&
                            <AppealsAdd1 />
                        }
                        {this.state.currentView === this.views.invoiceInfo &&
                            <AppealsAdd2 />
                        }
                        {this.state.currentView === this.views.invoiceInfo2 &&
                            <AppealsAdd3 />
                        }
                    </OverlayLoader>
                </AnalyticsModal>
            </AppealsAddContext.Provider>
        )
    }
}

export default withContexts(MembersContext)(AppealsAddContextProvider)