export default{
    "registration.settings.division.sidePanelRegistrationDivisions.title" : "Division Information",

    "registration.settings.division.sidePanelRegistrationDivisions.shortName.required"   : "Name is required",
    "registration.settings.division.sidePanelRegistrationDivisions.description.required" : "Description is required",

    "registration.settings.division.sidePanelRegistrationDivisions.status"          : "Status",
    "registration.settings.division.sidePanelRegistrationDivisions.status.active"   : "Active",
    "registration.settings.division.sidePanelRegistrationDivisions.status.inactive" : "Inactive",
    "registration.settings.division.sidePanelRegistrationDivisions.status.required" : "Status is required",

    "registration.settings.division.sidePanelRegistrationDivisions.update.success.text" : "Division updated",
    "registration.settings.division.sidePanelRegistrationDivisions.update.fail.text"    : "An error occurred during the Division update, please refresh the page et try again",

    // actions
    "registration.settings.division.sidePanelRegistrationDivisions.actions.remove"         : "Remove",
    "registration.settings.division.sidePanelRegistrationDivisions.actions.remove.topText" : "You are about to remove this division",

    // tabs
    "registration.settings.division.sidePanelRegistrationDivisions.tabs.details" : "Details",
}