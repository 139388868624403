import { useContext } from 'react';
import settingsBackgroundChecksRoutes from '../../../../routes/tabRoutes/organizationSettings/settingsBackgroundChecksRoutes';
import settingsContactTypesRoutes from '../../../../routes/tabRoutes/organizationSettings/settingsContactTypesRoutes';
import settingsDocumentsRoutes from '../../../../routes/tabRoutes/organizationSettings/settingsDocumentsRoutes';
import settingsMemberTypesRoutes from '../../../../routes/tabRoutes/organizationSettings/settingsMemberTypesRoutes';
import settingsPhoneTypesRoutes from '../../../../routes/tabRoutes/organizationSettings/settingsPhoneTypesRoutes';
import settingsTransferRoutes from '../../../../routes/tabRoutes/organizationSettings/settingsTransferRoutes';
import settingsTravelPermitsRoutes from '../../../../routes/tabRoutes/organizationSettings/settingsTravelPermitsRoutes';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import settingsMerchantAccountRoutes from '../../../../routes/tabRoutes/organizationSettings/settingsMerchantAccountRoutes';
import settingsMessagesRoutes from '../../../../routes/tabRoutes/organizationSettings/settingsMessagesRoutes';
import SettingsListView from '../../components/SettingsListView';

const OrganizationSettingsList = () => {
    const orgContext = useContext(OrganizationContext)

    return (
        <SettingsListView
            id='OrganizationSettingsListTable'
            setting="organization"
            routesList={[
                {
                    code: 'documents',
                    path: '/documents',
                    subPermissionsRoutes: settingsDocumentsRoutes,
                },
                {
                    code: 'backgroundChecks',
                    path: '/background-checks',
                    subPermissionsRoutes: settingsBackgroundChecksRoutes,
                },
                {
                    code: 'travelPermits',
                    path: '/travel-permits',
                    subPermissionsRoutes: settingsTravelPermitsRoutes,
                    showWhenSetting: 'enable_travel_permit',
                },
                {
                    code: 'transfer',
                    path: '/transfer',
                    // TODO: hardcoded
                    additionalCondition: orgContext.organisation_category?.name === 'Member branches' || orgContext.organisation_category?.name === 'NSO',
                    subPermissionsRoutes: settingsTransferRoutes,
                },
                {
                    code: 'phoneTypes',
                    path: '/phone-types',
                    subPermissionsRoutes: settingsPhoneTypesRoutes,
                },
                {
                    code: 'contactTypes',
                    path: '/contact-types',
                    subPermissionsRoutes: settingsContactTypesRoutes,
                },
                {
                    code: 'memberTypes',
                    path: '/member-types',
                    subPermissionsRoutes: settingsMemberTypesRoutes,
                },
                {
                    code: 'merchantAccounts',
                    path: '/merchant-accounts',
                    subPermissionsRoutes: settingsMerchantAccountRoutes,
                },
                {
                    code: 'messagesSettings',
                    path: '/messages',
                    subPermissionsRoutes: settingsMessagesRoutes,
                },
            ]}
        />
    );
}

export default OrganizationSettingsList;