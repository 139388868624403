import affiliationFeesAdd from "./affiliationFeesAdd";
import affiliationFeeSidepanel from "./affiliationFeeSidepanel";

export default{
    "catalog.affiliationFees.title"                               : "Frais d'affiliation",
    "catalog.affiliationFees.count"                               : "{count} frais d'affiliation",
    "catalog.affiliationFees.help"                                : "Le frais d'adhésion des membres représente le montant déboursé par le membre pour valider son inscription à sa fédération sportive pour une période déterminée (c'est-à-dire une année). Ce montant comprend les frais d'adhésion à son entité provinciale et/ou nationale ainsi que les coûts reliés à la couverture en assurances du membre.",
    "catalog.affiliationFees.iUnderstand"                         : "Je comprends",
    "catalog.affiliationFees.warning"                             : "Changer les frais d'affiliation aura un impact sur les frais d'inscriptions des organisations enfant. Pour activer la modification des frais, vous devez confirmer que vous voulez procéder.",
    "catalog.affiliationFees.warningContact"                      : "N'hésitez pas à contacter notre équipe de support si la saison est déjà commencée.",
    "catalog.affiliationFees.active"                              : "Actif",
    "catalog.affiliationFees.inactive"                            : "Inactif",
    "catalog.affiliationFees.organisation"                        : "Organisation",
    "catalog.affiliationFees.category"                            : "Categorie",
    "catalog.affiliationFees.division"                            : "Division",
    "catalog.affiliationFees.memberType"                          : "Type de membre",
    "catalog.affiliationFees.membershipFees"                      : "Frais d'affiliation",
    "catalog.affiliationFees.membershipFees.all"                  : "Frais des organisations parentes",
    "catalog.affiliationFees.membershipFees.myOrganisationColumn" : "Organisation que vous gérez actuellement",

    "catalog.affiliationFees.status" : "Statut",

    "catalog.affiliationFees.table.empty"         : "Aucun frais d'affiliation.",
    "catalog.affiliationFees.table.empty.message" : "Vous pouvez filtrer en haut de la page ou ajouter un nouveau frais.",

    "catalog.affiliationFees.division.birthYear" : "Année de naissance de {minYear} à {maxYear}",

    "catalog.affiliationFees.allBranches"       : "Toutes les branches",
    "catalog.affiliationFees.allTypes"          : "Tous les types",
    "catalog.affiliationFees.branches.selected" : "{count} branches sélectionnées",
    "catalog.affiliationFees.divsions.selected" : "{count} divisions sélectionnées",

    "catalog.affiliationFees.feeNeededMessage" : "Un frais de l'organisation parent est nécessaire pour activer cette division sur votre organisation",

    "catalog.affiliationFees.waivers.not.multi" : "Les dispenses ne peuvent pas être modifiées en groupe. Veuillez sélectionner un seul frais d'affiliation pour définir des dispenses.",

    ...affiliationFeesAdd,
    ...affiliationFeeSidepanel,
}