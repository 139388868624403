import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    Card,
    CardBody,
    Button,
    Badge,
    Table
} from "reactstrap";
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import SpordlePanelTable from '../../../../../../components/sidePanel/SpordlePanel';
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableContext, SpordleTableView } from '@spordle/datatables';
import MemberSuspensionAddContext from './components/add/MemberSuspensionAddContext';
import MemberSuspensionsSidepanel from './components/MemberSuspensionsSidepanel';
import { displayI18n, DisplayI18n } from '../../../../../../helpers/i18nHelper';
import moment from 'moment';
import CanDoAction from "../../../../../../components/permissions/CanDoAction";

// contexts
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { useIntl } from 'react-intl';
import { RolesContext } from '../../../../../../contexts/RolesContext';
import { IdentityRolesContext } from '../../../../../../contexts/IdentityRolesContext';
import { stringBuilder } from '@spordle/helpers';

const MemberSuspensions = ({ godAccess }) => {
    const membersContext = useContext(MembersContext);
    const { canDoAction } = useContext(RolesContext);
    const i18nContext = useContext(I18nContext);
    const { isGod } = useContext(IdentityRolesContext);
    const intl = useIntl();

    const [ modalAdd, setModalAdd ] = useState(false)
    const toggleModalAdd = () => setModalAdd(!modalAdd)

    const getStatus = (duration, endDate) => {
        if(duration === 'DEFINITE'){
            if(endDate && !moment().isBefore(moment(endDate))){
                return {
                    render: <Badge color='danger'><Translate id='members.profile.suspensions.status.expired' /></Badge>,
                    string: 'expired',
                }
            }
            return {
                render: <Badge color='success'><Translate id='members.profile.suspensions.status.active' /></Badge>,
                string: 'active',
            }

        }
        return {
            render: <Badge color='success'><Translate id='members.profile.suspensions.status.active' /></Badge>,
            string: 'active',
        }

    }

    return (
        <Card id="memberSuspensionsCard" className="card-shadow">
            <CardBody>
                <CardSectionTitle title='members.profile.suspensions.title' titleClassname={godAccess ? "text-purple" : undefined} />
                <SpordlePanelTable
                    allowOutsideClick
                    sidePanel={(props) => <MemberSuspensionsSidepanel {...props} getStatus={getStatus} />}
                    dataIndex='member_suspension_id'
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='suspensions'
                                tableHover bordered striped
                                clickable
                                ref={panelProps.spordleTableRef}
                                desktopWhen
                                dataIndex='member_suspension_id'
                                pagination={5}
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                loadData={(from, data, spordleTable) => {
                                    const formatData = (suspensions) => suspensions.map((suspension) => {
                                        const option = [];

                                        if(suspension.duration !== 'DEFINITE'){
                                            option.push(intl.formatMessage({ id: 'members.profile.suspensions.duration.indefinite' }))
                                        }

                                        option.push(intl.formatMessage({ id: `members.profile.suspensions.status.${getStatus(suspension.duration, suspension.end_date).string}` }));

                                        return {
                                            ...suspension,
                                            option: option,
                                            date: suspension.received_date || suspension.start_date,
                                        }
                                    })

                                    switch (from){
                                        case 'REFRESH':
                                            spordleTable.setLoading()
                                        case 'CDM':
                                            return membersContext.getMemberSuspensions(membersContext.currentMember.member_id)
                                                .then(formatData)
                                        default:
                                            break;
                                    }
                                }}
                                searchKeys={[
                                    'other_infraction',
                                    `suspension.i18n.${i18nContext.getGenericLocale()}.name`,
                                    'suspension.name',
                                    `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                    'organisation.name',
                                    'received_date',
                                    'duration',
                                    'game_suspended',
                                    'end_date',
                                    'reference_number',
                                ]}
                                columnSorting={(suspensionA, suspensionB, _spordleTable, order) => {
                                    switch (order.column){
                                        case 'infraction':
                                            const valueAInfraction = suspensionA.other_infraction || displayI18n('name', suspensionA.suspension.i18n, suspensionA.suspension.name, i18nContext.getGenericLocale())
                                            const valueBInfraction = suspensionB.other_infraction || displayI18n('name', suspensionB.suspension.i18n, suspensionB.suspension.name, i18nContext.getGenericLocale())
                                            return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueAInfraction, valueBInfraction) * (order.order === 'ASC' ? 1 : -1);
                                        case 'organization':
                                            const valueAOrganization = displayI18n('name', suspensionA.organisation.i18n, suspensionA.organisation.name, i18nContext.getGenericLocale())
                                            const valueBOrganization = displayI18n('name', suspensionB.organisation.i18n, suspensionB.organisation.name, i18nContext.getGenericLocale())
                                            return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueAOrganization, valueBOrganization) * (order.order === 'ASC' ? 1 : -1);
                                        case 'duration':
                                            if(suspensionA.duration === 'DEFINITE' && suspensionB.duration === 'DEFINITE'){
                                                return parseInt(suspensionA.game_suspended) - parseInt(suspensionB.game_suspended)
                                            }else if(suspensionA.duration === 'DEFINITE' && suspensionB.duration === 'INDEFINITE'){
                                                return order.order === 'ASC' ? 1 : -1
                                            }else if(suspensionA.duration === 'INDEFINITE' && suspensionB.duration === 'DEFINITE'){
                                                return order.order === 'ASC' ? -1 : 1
                                            }
                                            return 0

                                        case 'status':
                                            const statusA = suspensionA.duration === 'DEFINITE' && !moment().isBefore(moment(suspensionA.end_date)) ? 'expired' : 'active';
                                            const statusB = suspensionB.duration === 'DEFINITE' && !moment().isBefore(moment(suspensionB.end_date)) ? 'expired' : 'active';
                                            if(statusA === 'expired' && statusB === 'active'){
                                                return order.order === 'ASC' ? 1 : -1
                                            }else if(statusA === 'active' && statusB === 'expired'){
                                                return order.order === 'ASC' ? -1 : 1
                                            }
                                            return 0

                                    }
                                }}
                                columns={[
                                    {
                                        label: <Translate id='members.profile.suspensions.date' />,
                                        key: 'date',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.suspensions.infraction' />,
                                        key: 'infraction',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.suspensions.organization' />,
                                        key: 'organization',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.suspensions.duration' />,
                                        key: 'games',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.suspensions.endDate' />,
                                        key: 'end_date',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.suspensions.status' />,
                                        key: 'status',
                                        sortable: true,
                                    },
                                ]}
                                renderRow={(columnKey, suspension) => {
                                    switch (columnKey){
                                        case 'date':
                                            return (
                                                <div className='text-nowrap'>
                                                    <div className='small text-muted'><Translate id={suspension.suspension?.system_type === 'ADMINISTRATIVE' ? 'members.profile.suspensions.startDate' : 'members.profile.suspensions.receivedDate'} /></div>
                                                    <DateFormat value={suspension.date} utc />
                                                </div>
                                            )
                                        case 'infraction':
                                            if(suspension.other_infraction){
                                                return (
                                                    <div>
                                                        <div>{suspension.other_infraction}</div>
                                                        <div className='text-muted small'>{suspension.reference_number}</div>
                                                        {suspension.member_type &&
                                                            <div className='text-muted small'><DisplayI18n field='name' defaultValue={suspension.member_type.name} i18n={suspension.member_type.i18n} /></div>
                                                        }
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div>
                                                    <DisplayI18n field='name' defaultValue={suspension.suspension?.name} i18n={suspension.suspension?.i18n} />
                                                    <div className='text-muted small'>{suspension.reference_number}</div>
                                                    {suspension.member_type &&
                                                        <div className='text-muted small'><DisplayI18n field='name' defaultValue={suspension.member_type.name} i18n={suspension.member_type.i18n} /></div>
                                                    }
                                                </div>
                                            )
                                        case 'organization':
                                            return (
                                                <DisplayI18n field='name' defaultValue={suspension.organisation?.name} i18n={suspension.organisation?.i18n} />
                                            )
                                        case 'games':
                                            return (
                                                <div>
                                                    {suspension.game_suspended ?
                                                        <Translate id='members.profile.suspensions.duration.games' values={{ games: suspension.game_suspended }} />
                                                        :
                                                        '-'
                                                    }
                                                </div>
                                            )
                                        case 'end_date':
                                            if(suspension.duration === 'DEFINITE'){
                                                return (
                                                    <div className='text-nowrap'>
                                                        {suspension.end_date ?
                                                            <DateFormat value={suspension.end_date} utc />
                                                            :
                                                            '-'

                                                        }
                                                    </div>
                                                )
                                            }
                                            return (
                                                <Translate id='members.profile.suspensions.duration.indefinite' />
                                            )

                                        case 'status':
                                            return getStatus(suspension.duration, suspension.end_date).render
                                        default:
                                            break;
                                    }
                                }}
                                onColumnClick={(e, data) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            panelProps.onSingleSelectChange(data);
                                            break;
                                    }
                                }}
                                rowIsHighlighted={(data) => data.checked}
                                emptyLayout={
                                    <Table bordered striped hover>
                                        <tbody>
                                            <tr>
                                                <td className='text-center'>
                                                    {(canDoAction('ADD', 'members', 'members_suspensions') || isGod()) ?
                                                        <button className={stringBuilder('font-medium reset-btn text-primary', { 'text-purple': !canDoAction('ADD', 'members', 'members_suspensions') && isGod() })} type='button' onClick={toggleModalAdd}><i className='mdi mdi-plus' /><Translate id='misc.add' /> <Translate id="members.profile.suspensions.title" /></button>
                                                        :
                                                        <Translate id="misc.search.empty.title" />
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                }
                            >
                                <div className='mb-2'>
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <SearchInput />
                                        <div className='d-flex ml-auto text-right'>
                                            <Refresh />
                                            <CanDoAction action='ADD' componentCode='members' componentPermissionCode='members_suspensions'>
                                                <Button className='ml-2' color='primary' onClick={toggleModalAdd}><i className='mdi mdi-plus' /> <Translate id='misc.add' /></Button>
                                                <SpordleTableContext.Consumer>
                                                    {(spordleTable) => (
                                                        <MemberSuspensionAddContext isOpen={modalAdd} toggle={toggleModalAdd} memberId={membersContext.currentMember.member_id} refreshTable={() => spordleTable.refreshTable()} />
                                                    )}
                                                </SpordleTableContext.Consumer>
                                            </CanDoAction>
                                        </div>
                                    </div>
                                </div>
                                <SpordleTableView />
                            </SpordleTableProvider>
                        )
                    }}
                />
            </CardBody>
        </Card>
    );
}

export default MemberSuspensions;