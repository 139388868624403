import SpordleTableProvider from "@spordle/datatables";
import { useContext } from "react";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";
import { MessagesContext } from "../../contexts/MessagesContext";
import { InboxContext } from "./components/inboxContext/InboxContext";
import SpordleListView from "./components/inboxTableComponents/SpordleListView";
import { useCheckMsg } from "./components/useCheckMsg";
import { PAGINATION } from "./helpers/communicationsHelper";

const FavoriteMessages = () => {
    const checkMsgHandlers = useCheckMsg();
    const { selectMsg } = useContext(InboxContext);
    const { getReceivedMessages } = useContext(MessagesContext);
    const { identity } = useContext(IdentityRolesContext);

    const handleLoadData = (from, filterData, spordleTable) => {
        const currentPage = filterData.filters.currentPage;
        const pagin = spordleTable.getPagination();
        const offset = (currentPage - 1) * pagin;

        switch (from){
            case 'REFRESH':
                if(spordleTable.getData().length === 0){
                    spordleTable.setLoading();
                }
            case 'CDM':
                return getReceivedMessages({
                    identity_id: identity.identity_id,
                    offset: offset,
                    limit: pagin,
                    sort: "-created_at",
                    favorite: "YES",
                })
                    .then(({ total, communication_messages }) => {
                        spordleTable.filterChange('totalResults', total, false);

                        return communication_messages || [];
                    })
                    .catch(() => {
                        return [];
                    })
            default:
                break;
        }
    }

    const handleOnSelectMsg = (msg) => {
        return () => {
            selectMsg(msg);
        }
    }

    return (
        <SpordleTableProvider
            id="communicationsFavoriteMessages"
            dataIndex="message_id"
            loadData={handleLoadData}
            initFilter={{
                currentPage: 1,
                totalResults: null,
            }}
            filterJSCallback={(msg) => msg.favorite == 1}
            pagination={PAGINATION}
            paginationMessage={() => null}
            viewMode='GRID'
        >
            <SpordleListView
                handleOnSelectMsg={handleOnSelectMsg}
                toolsProps={{
                    data: {
                        favorite: "0",
                    },
                }}
                {...checkMsgHandlers}
            />
        </SpordleTableProvider>
    );
}

export default FavoriteMessages;