import { FormikError, FormikInputText, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { Fragment, useContext, useRef } from 'react';
import {
    Alert, Button, Col, Collapse, FormGroup,
    Label, ModalBody, ModalFooter, Row
} from "reactstrap";
import { array, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../../../api/CancellableAPI';
import Required from '../../../../../../../../components/formik/Required';
import { fail } from "@spordle/toasts";
import FileUpload from '../../../../../../../../components/uploader/fileUpload/FileUpload';
import { I18nContext } from '../../../../../../../../contexts/I18nContext';
// contexts
import { MembersContext } from '../../../../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../../../../contexts/OrganizationContext';
import { PositionsContext } from '../../../../../../../../contexts/PositionsContext';
import { displayI18n, DisplayI18n } from '../../../../../../../../helpers/i18nHelper';
import { DisplayCategory } from './../../../../../../../teams/TeamHelpers';
import { MemberSuspensionAddContext } from './MemberSuspensionAddContext';
import { PeriodsContext } from '../../../../../../../../contexts/contexts';
import { stringBuilder } from '@spordle/helpers';


const MemberSuspensionAdd2 = () => {
    const memberSuspensionAddContext = useContext(MemberSuspensionAddContext);
    const membersContext = useContext(MembersContext);
    const positionsContext = useContext(PositionsContext);
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);
    const { periods, selectedPeriod } = useContext(PeriodsContext);
    const teamRef = useRef();
    const { getGenericLocale } = useContext(I18nContext);

    const getMemberTeams = async(filters) => {
        return membersContext.getMemberTeams(memberSuspensionAddContext.memberId, { period_id: filters.period_id })
            .then((_memberTeams) => {
                memberSuspensionAddContext.setMemberTeams(_memberTeams)
                return formatMemberTeams(_memberTeams)
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

    }

    const formatMemberTeams = (_memberTeams) => {
        return _memberTeams.reduce((newArray, memberTeam) => {
            if((memberTeam.active == 1 || memberTeam.release_date) && !newArray.some((v) => v.value == memberTeam.team.team_id)){
                newArray.push({
                    value: memberTeam.team.team_id,
                    label: memberTeam.team.name,
                    i18n: memberTeam.team.i18n,
                    category: memberTeam.team.team_category,
                    division: memberTeam.team.division,
                    team: memberTeam.team,
                    isPrimary: memberTeam.primary_team == 1,
                    release_date: memberTeam.release_date,
                })
            }
            return newArray
        }, [])
    }

    const getPositions = async() => {
        if(memberSuspensionAddContext.state.positions){
            return Promise.resolve(formatPositions(memberSuspensionAddContext.state.positions))
        }
        return positionsContext.getPositions(organizationContext.organisation_id)
            .then((_positions) => {
                memberSuspensionAddContext.setPositions(_positions)
                return formatPositions(_positions)
            })

    }

    const formatPositions = (_positions) => {
        return _positions.reduce((newArray, position) => {
            if(position.active == 1){
                newArray.push({
                    value: position.position_id,
                    label: position.name,
                    i18n: position.i18n,
                })
            }
            return newArray
        }, [])
    }

    return (
        <Formik
            initialValues={{
                team_id: memberSuspensionAddContext.state.formData.team_id || '',
                position_id: memberSuspensionAddContext.state.formData.position_id || memberSuspensionAddContext.state.memberTeam?.position?.position_id || '',
                opposing_team_name: memberSuspensionAddContext.state.formData.opposing_team_name || '',
                comment: memberSuspensionAddContext.state.formData.comment || '',
                attachments: memberSuspensionAddContext.state.formData.attachments || [],
                period_id: selectedPeriod.period_id,
            }}
            validationSchema={object().shape({
                team_id: string().required(<Translate id='members.profile.suspensions.team.required' />),
                position_id: string().when('team_id', {
                    is: (val) => val && !memberSuspensionAddContext.props.linkToMaltreatment,
                    then: string().required(<Translate id='members.profile.suspensions.position.required' />),
                    otherwise: string(),
                }),
                opposing_team_name: string(),
                comment: string(),
                attachments: array(),
            })}
            onSubmit={async({ period_id: _, ...values }, { setStatus }) => {
                memberSuspensionAddContext.setLoading(true);

                // if(memberSuspensionAddContext.state.formData.memberTypes){
                //     const successSus = [];
                //     const failsSus = [];

                //     for(let i = 0; i < memberSuspensionAddContext.state.formData.memberTypes.length; i++){
                //         const memberType = memberSuspensionAddContext.state.formData.memberTypes[i];
                //         const optionnalData = {};
                //         if(memberType.fine)
                //             optionnalData.fine = (memberType.fine * 100).toFixed(0)
                //         if(memberType.fee)
                //             optionnalData.fee = (memberType.fee * 100).toFixed(0)

                //         await memberSuspensionAddContext.createMemberSuspension({
                //             ...memberSuspensionAddContext.state.formData,
                //             ...values,
                //             ...optionnalData,
                //             member_type_id: memberType.memberTypeId,
                //         })
                //             .then(() => successSus.push(<DisplayI18n field="name" defaultValue={memberType.memberType.name} i18n={memberType.memberType.i18n} />))
                //             .catch(() => failsSus.push(<DisplayI18n field="name" defaultValue={memberType.memberType.name} i18n={memberType.memberType.i18n} />))

                //         memberSuspensionAddContext.setLoading(false);

                //         successSus.forEach((memberType) => {
                //             success({
                //                 info: <Translate id='members.profile.suspensions.success.toast' values={{ memberType: memberType }} />,
                //                 skipInfoTranslate: true,
                //             })
                //         })
                //         failsSus.forEach((memberType) => {
                //             fail({
                //                 info: <Translate id='members.profile.suspensions.fail.toast' values={{ memberType: memberType }} />,
                //                 skipInfoTranslate: true,
                //             })
                //         })
                //     }
                // }else{
                // }
                memberSuspensionAddContext.createMemberSuspension({
                    ...memberSuspensionAddContext.state.formData,
                    ...values,
                })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            if(error.i18n){
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            }else{
                                setStatus('error-' + error.message)
                            }
                        }
                    })
                    .finally(() => memberSuspensionAddContext.setLoading(false))
            }}
        >
            {(formik) => {
                const selectedTeams = memberSuspensionAddContext.state.memberTeams?.filter((memberTeam) => memberTeam.team.team_id === formik.values.team_id) || null

                // ** memberSuspensionAddContext.props.team & memberSuspensionAddContext.props.memberId == undefined when maltreament is against a team
                // ** memberSuspensionAddContext.props.memberId when maltreament is against a member
                // Need to show team select and position select when maltreatment is not against a team
                // Can be against a member without a sleected team
                // Can be against a member with a selected team
                const showTeamsAndPositions = (memberSuspensionAddContext.props.memberId && !memberSuspensionAddContext.props.team) || (memberSuspensionAddContext.props.memberId && memberSuspensionAddContext.props.team)

                return (
                    <Form>
                        <ModalBody>
                            <Row form>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="period_id" className="text-muted"><Translate id="invoice.table.season" /> <Required /></Label>
                                        <FormikSelect
                                            name="period_id"
                                            id="period_id"
                                            disabled={!!memberSuspensionAddContext.props.team && !!formik.values.team_id} // If team has been already selected in the maltreatment complaint, we disable the period also because this changes the teams and positions
                                            renderSelectedOption={(option) => (
                                                <>
                                                    <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />
                                                    {option.ressource.current == 1 && <small className="text-muted"> (<Translate id="misc.current" />)</small>}
                                                </>
                                            )}
                                            renderOption={({ option, isSelected }) => (
                                                <>
                                                    <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />
                                                    {option.ressource.current == 1 && <small className={`d-block text-${isSelected ? 'light' : 'muted'}`}><Translate id="misc.current" /></small>}
                                                </>
                                            )}
                                            options={Array.isArray(periods) ? periods.reduce((options, period) => {
                                                if(period.active == 1){
                                                    options.push({
                                                        label: period.name,
                                                        ressource: period,
                                                        value: period.period_id,
                                                    })
                                                }
                                                return options;
                                            }, []).sort((a, b) => {
                                                const aName = displayI18n("name", a.ressource.i18n, a.label, getGenericLocale())
                                                const bName = displayI18n("name", b.ressource.i18n, b.label, getGenericLocale())
                                                return bName.localeCompare(aName, { numeric: true })
                                            }) : []}
                                            onOptionSelected={([ val ]) => {
                                                teamRef.current?.getSpordleTable?.()?.filterChange?.('period_id', val);
                                                formik.setFieldValue('team_id', "");
                                            }}
                                        />
                                    </FormGroup>

                                </Col>

                                {(!memberSuspensionAddContext.props.linkToMaltreatment || showTeamsAndPositions) &&
                                    <>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label for='team_id' className='text-muted'><Translate id='members.profile.suspensions.team' /> <Required /></Label>
                                                <FormikSelect
                                                    name='team_id'
                                                    id='team_id'
                                                    ref={teamRef}
                                                    disabled={!!memberSuspensionAddContext.props.team && !!formik.values.team_id}
                                                    renderSelectedOption={(team) => (
                                                        <div>
                                                            <div>{team.label}{team.isPrimary && <small> (<Translate id="members.profile.teams.primaryTeam" />)</small>}</div>
                                                            <div className="text-muted small">
                                                                {team.division ? team.division : <DisplayCategory category={{ ...team?.category }} short /> }
                                                                {team.category?.gender && <> - <Translate id={`form.fields.gender.${team.category.gender.toLowerCase()}`} /></>}
                                                            </div>
                                                        </div>
                                                    )}
                                                    renderOption={({ option: team, isSelected }) => (
                                                        <div>
                                                            <div>{team.label}{team.isPrimary && <small> (<Translate id="members.profile.teams.primaryTeam" />)</small>}</div>
                                                            <div className={`text-${isSelected ? 'light' : 'muted'} small`}>
                                                                {team.team?.unique_identifier && <div>#{team.team.unique_identifier}</div> }
                                                                {team.division ? team.division : <div><DisplayCategory category={{ ...team?.category }} short /></div> }
                                                                {team.category?.gender && <div> - <Translate id={`form.fields.gender.${team.category.gender.toLowerCase()}`} /></div>}
                                                                {team.release_date && <div><Translate id={`teams.profile.header.cards.rows.released`} />: <DateFormat value={team.release_date} format='YYYY-MM-DD' /></div>}
                                                            </div>
                                                        </div>
                                                    )}
                                                    initFilter={{
                                                        period_id: selectedPeriod.period_id,
                                                    }}
                                                    searchKeys={[
                                                        `i18n.${i18nContext.getGenericLocale()}.name`,
                                                        `division.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                                        `category.class.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                                    ]}
                                                    loadData={(from, { filters }) => {
                                                        switch (from){
                                                            case 'FILTER':
                                                            case 'CDM':
                                                                return getMemberTeams(filters);
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm="12">
                                            <FormGroup>
                                                <Label for='position_id' className='text-muted'><Translate id='members.profile.suspensions.position' /> <Required /></Label>
                                                <FormikSelect
                                                    name='position_id'
                                                    id='position_id'
                                                    searchKeys={[
                                                        `i18n.${i18nContext.getGenericLocale()}.name`,
                                                    ]}
                                                    loadData={(from) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return getPositions();
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                />
                                                {(selectedTeams && selectedTeams.length > 0) &&
                                                    <small className='text-muted d-flex mt-1'>
                                                        <div className='text-muted'><Translate id={`members.profile.suspensions.suggestedPosition${stringBuilder({ "s": selectedTeams.length > 1 })}`} /></div>
                                                        {selectedTeams.map((team, i) => (
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            <Fragment key={team.position.position_id + i}>
                                                                <button type="button" className='reset-btn text-link mx-1' onClick={() => formik.setFieldValue('position_id', team.position.position_id)}><DisplayI18n field="name" defaultValue={team.position.name} i18n={team.position.i18n} /></button>
                                                                {!!selectedTeams[i + 1] && <>|</>}
                                                            </Fragment>
                                                        ))}
                                                    </small>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </>
                                }
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for='opposing_team_name' className='text-muted'><Translate id='members.profile.suspensions.opposingTeamName' /></Label>
                                        <FormikInputText name='opposing_team_name' id='opposing_team_name' trim />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label for='comment' className='text-muted'><Translate id='members.profile.suspensions.notes' /></Label>
                                <FormikTextArea id='comment' name='comment' rows='3' />
                            </FormGroup>
                            <FormGroup>
                                <Label for='attachments' className='text-muted'><Translate id='members.profile.suspensions.attachments' /></Label>
                                <FileUpload
                                    onFileSelect={(files) => {
                                        formik.setFieldValue('attachments', files);
                                    }}
                                    dropzoneProps={{
                                        multiple: true,
                                        accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                    }}
                                />
                                <FormikError name='attachments' />
                            </FormGroup>

                            <Collapse isOpen={formik.status?.includes('error')}>
                                <Alert color='danger' toggle={() => formik.setStatus('')}>
                                    {formik.status && // to prevent error message in console when status is undefined
                                            <Translate id={'contexts.membersContext.' + formik.status?.split('-')?.[1]} defaultMessageId='misc.error' />
                                    }
                                </Alert>
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' onClick={() => memberSuspensionAddContext.goToView(memberSuspensionAddContext.views.suspensionInfo)} outline className="mr-auto"><Translate id='misc.previous' /></Button>
                            <Button color='primary' type='submit'><Translate id='misc.add' /></Button>
                            <Button color='primary' onClick={memberSuspensionAddContext.toggleModal} outline><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </Form>
                )
            }}
        </Formik>
    );
}

export default MemberSuspensionAdd2;