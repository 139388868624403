import { Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import IsDev from "../../../../../components/permissions/IsDev";
import EditFilterModal from "./EditFilterModal";

const EditFilter = ({ currentFilter }) => {
    const [ isOpen, isOpenHandlers ] = useDisclosure();

    return (
        <IsDev tooltipOff className="position-absolute right-0 top-0 z-index-1">
            <Tooltip
                position="top"
                withArrow
                label={"Dev Only → Edit filter"}
            >
                <button type="button" onClick={isOpenHandlers.open} className="reset-btn text-dev px-2 mdi mdi-pencil" />
            </Tooltip>
            <EditFilterModal currentFilter={currentFilter} toggle={isOpenHandlers.close} isOpen={isOpen} />
        </IsDev>
    );
}

export default EditFilter;