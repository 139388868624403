import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useState, useRef } from 'react';
import { mixed, object, string } from 'yup';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import CrossFade from '../../../../../components/crossFade/CrossFade';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { UtilsContext } from '../../../../../contexts/UtilsContext';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { useSpordleTable } from '@spordle/datatables';
import PostalCodesSelection from './PostalCodesSelection';
import PostalCodesSuccess from './PostalCodesSuccess';
import PostalCodesSearch from './PostalCodesSearch';

const AddPostalCodesModal = ({ isOpen, toggle, ...props }) => {
    const { updateOrganisationPostalCode, organisation_id, postal_codes, setCurrentOrg } = useContext(OrganizationContext);
    const spordleTable = useSpordleTable();

    const onSubmit = (values, removedCodes, setFieldValue, setStatus) => {
        // make the PUT request
        const postalCodes = new Set([ ...postal_codes, ...values.postalCodes ]);

        removedCodes.forEach((removed) => {
            postalCodes.delete(removed);
        });

        return updateOrganisationPostalCode(organisation_id, Array.from(postalCodes))
            .then(async() => {
                await setCurrentOrg(organisation_id).then(spordleTable.refreshTable);
                return setFieldValue('step', 3, false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error);
                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                }
            })
    }

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='AddPostalCodesModal'>
            <AddPostalCodesModalInner
                toggle={toggle}
                onSubmit={onSubmit}
                canEdit // if the user can open the modal he can edit the postal codes
                {...props}
            />
        </AnalyticsModal>
    )
}

export const AddPostalCodesModalInner = ({
    toggle,
    onSubmit,
    canEdit, // Permission
    isEdit, // Modal mode
    selectedPostalCodes,
    goToPrevious,
}) => {
    const { getPostalCodesFor } = useContext(UtilsContext);
    const { city } = useContext(OrganizationContext);
    const selectedFetched = useRef({});
    const [ postalCodesData, setPostalCodesData ] = useState({ total: 0 });

    return (
        <Formik
            initialValues={{
                step: 1,
                province: '',
                city: city || '',
                partialPostalCode: '',
                postalCodes: {},
            }}
            validationSchema={object().shape({
                province: string().required(<Translate id='form.validation.province.required' />),
                city: string().when('partialPostalCode', {
                    is: (val) => !val,
                    then: string().required(<Translate id='form.validation.city.required' />),
                }),
                partialPostalCode: string().when('city', {
                    is: (val) => !val,
                    then: string()
                        .required(<Translate id='organization.profile.postalCodes.modals.add.validation.partialPostalCode.length' />)// Because .length doesn't check for empty string
                        .length(3, <Translate id='organization.profile.postalCodes.modals.add.validation.partialPostalCode.length' />),
                }),
                postalCodes: mixed().when('step', {
                    is: 2,
                    then: (schema) => schema.test({
                        name: 'add at least 1',
                        message: <Translate id='organization.profile.postalCodes.modals.add.validation.postalCodes.required' />,
                        test: function(selectedCodes){
                            const values = Object.values(selectedCodes || {});

                            if(values.length === 0){
                                return false;
                            }

                            // The every() method stops when there is a false
                            // so if a size is bigger than 0, the loop will stop and true will be returned as a pass.
                            // Otherwise, the test will not pass.
                            return !(values.every((value) => value.size === 0));
                        },
                    }),
                }),
            }, [ [ 'partialPostalCode', 'city' ] ])}
            onSubmit={async({ step, ...values }, { setFieldValue, setFieldTouched, setStatus }) => {
                setStatus();

                if(step == 1){
                    // make the get postal codes request
                    setPostalCodesData({});

                    const getInitPostalCodes = (isPartialCode, allCodes = []) => {
                        return (selectedPostalCodes || []).reduce((formatted, code) => {
                            const postalCodeRessource = allCodes.find(({ postal_code }) => postal_code === code);
                            const codePrefix = isPartialCode ? postalCodeRessource?.city_name : code.split(' ')[0];

                            if(postalCodeRessource){
                                if(!formatted[codePrefix]){
                                    formatted[codePrefix] = new Set();
                                }

                                formatted[codePrefix].add(code);
                            }

                            return formatted;
                        }, {});
                    };

                    return getPostalCodesFor({
                        province_code: values.province,
                        city: values.city,
                        partial_postal_code: values.partialPostalCode,
                    })
                        .then((postalCodes) => {

                            /**
                             * @example
                             * const splitPostalCodes = {
                             *  'H7L': [
                             *      {
                             *          postal_code: 'H7L 2L2',
                             *          city_name: 'Laval',
                             *          province_abbreviation: 'QC',
                             *          province_name: 'Quebec',
                             *          latitude: '45.60',
                             *          longitude: '-73.68',
                             *      }
                             *  ]
                             * }
                             * @example
                             * const splitPostalCodes = {
                             *  'laval': [
                             *      {
                             *          postal_code: 'H7L 2L2',
                             *          city_name: 'Laval',
                             *          province_abbreviation: 'QC',
                             *          province_name: 'Quebec',
                             *          latitude: '45.60',
                             *          longitude: '-73.68',
                             *      }
                             *  ]
                             * }
                             */
                            const splitPostalCodes = postalCodes.reduce((formattedPrefixes, postalCode) => {
                                // If search with postal code prefix, we separate cards per city. If we search per city, cards will be separated with the prefix of the postal codes.
                                const codePrefix = values.partialPostalCode ? postalCode.city_name : postalCode.postal_code.split(' ')[0];

                                if(!formattedPrefixes[codePrefix]){
                                    formattedPrefixes[codePrefix] = [];
                                }

                                formattedPrefixes[codePrefix].push(postalCode);

                                return formattedPrefixes;
                            }, {});

                            const initPostalCodes = getInitPostalCodes(values.partialPostalCode, postalCodes);

                            for(const key in splitPostalCodes){
                                if(Object.hasOwnProperty.call(splitPostalCodes, key)){
                                    (splitPostalCodes[key] || []).sort((_, pCodeB) => {
                                        const bPrefix = pCodeB.postal_code.split(' ')[0];
                                        const selectedCodes = initPostalCodes[bPrefix];

                                        if(selectedCodes && selectedCodes.has(pCodeB.postal_code)){
                                            return -1;
                                        }

                                        return 0;
                                    });
                                }
                            }

                            setPostalCodesData({
                                total: postalCodes.length,
                                codes: splitPostalCodes,
                            });

                            return postalCodes;
                        })
                        .then((postalCodes) => {
                            const initPostalCodes = getInitPostalCodes(values.partialPostalCode, postalCodes);
                            setFieldValue('postalCodes', getInitPostalCodes(values.partialPostalCode, postalCodes), false);
                            selectedFetched.current = initPostalCodes;
                            setFieldTouched('step', false);
                            setFieldValue('step', 2);
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error);
                                selectedFetched.current = {};
                                setPostalCodesData({ total: 0 });
                                setFieldValue('postalCodes', {});
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            }
                        })
                }else if(step == 2){
                    setFieldValue('step', 1);

                    const newPostalCodes = new Set();
                    const removedCodes = new Set();
                    const apiValues = {
                        ...values,
                    };

                    Object.values(values.postalCodes).forEach((codesSet) => {
                        codesSet.forEach((code) => {
                            newPostalCodes.add(code);
                        })
                    });

                    Object.values(selectedFetched.current).forEach((codesSet) => {
                        codesSet.forEach((code) => {
                            if(!newPostalCodes.has(code)){
                                removedCodes.add(code);
                            }
                        });
                    });

                    apiValues.postalCodes = Array.from(newPostalCodes);

                    return onSubmit(apiValues, Array.from(removedCodes), setFieldValue, setStatus);
                }
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <CrossFade isVisible={formik.values.step == 1}>
                            <PostalCodesSearch
                                toggle={toggle}
                                goToPrevious={goToPrevious}
                            />
                        </CrossFade>
                        <CrossFade isVisible={formik.values.step == 2} mountOnEnter unmountOnExit>
                            <PostalCodesSelection
                                toggle={toggle}
                                canEdit={canEdit}
                                isEdit={isEdit}
                                postalCodesData={postalCodesData}
                            />
                        </CrossFade>
                        <CrossFade isVisible={formik.values.step == 3} mountOnEnter unmountOnExit>
                            <PostalCodesSuccess toggle={toggle} />
                        </CrossFade>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default AddPostalCodesModal;