import { useContext, useState } from 'react';
import { FormikCheckedButton } from "@spordle/formik-elements";
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import { Formik, Form } from "formik";
import { Alert, Button, ModalBody, ModalFooter, Badge, Table, Card, CardBody, Fade } from "reactstrap";
import { object, bool } from 'yup';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import { fail } from '@spordle/toasts';
import UserDisplay from '../../../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../../../components/UserImg';

// Contexts
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import { RefundModalContext } from './OnlineStoreTransactionRefundModal';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import InlineCopy from '../../../../../../../components/inlineCopy/InlineCopy';

const RefundModalStepMemberInstallments = (props) => {

    const memberContext = useContext(MembersContext);
    const refundModalContext = useContext(RefundModalContext);

    const [ registrationsWithInstallments, setRegistrationsWithInstallments ] = useState(refundModalContext.state.transaction.registrations.filter((invoiceItem) => invoiceItem.payments?.length > 1 &&
        invoiceItem.payments?.some((payment) => payment.status === 'PENDING')).map((invoiceItem) => ({
        registration: invoiceItem,
        hasBeenCancelled: false,
    })))

    const [ activeIndex, setActiveIndex ] = useState(0);

    const totalCreditAmount = parseInt(refundModalContext.state.transaction.credits.reduce((sum, credit) => sum += parseInt(credit.amount), 0));

    const onInstallmentsUpdate = (hasBeenCancelled) => {
        if(activeIndex + 1 === registrationsWithInstallments.length){
            if(refundModalContext.state.onlyCancelInstallment){
                refundModalContext.handleToggleRefresh();
            }else{
                refundModalContext.goToView(refundModalContext.views.confirmation)
            }
        }else{
            const tempArray = [ ...registrationsWithInstallments ];
            tempArray[activeIndex].hasBeenCancelled = hasBeenCancelled;
            setRegistrationsWithInstallments(tempArray)
            setActiveIndex(activeIndex + 1)
        }
    }

    return (
        <Formik
            key={activeIndex}
            initialValues={{
                cancelInstallments: false,
            }}
            validationSchema={object().shape({
                cancelInstallments: bool(),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                if(values.cancelInstallments){
                    Promise.all(
                        registrationsWithInstallments[activeIndex].registration.payments.reduce((newArray, payment) => {
                            if(payment.status !== 'CANCELED' && payment.status !== 'COMPLETED' && payment.status !== 'MANUAL_PAYMENT' && payment.payment_type === 'INSTALLMENT'){
                                newArray.push(
                                    memberContext.updateInvoicePayment(payment.invoice_payment_id, {
                                        status: 'CANCEL',
                                    }),
                                )
                            }
                            return newArray
                        }, []),
                    )
                        .then(() => {
                            setSubmitting(false);
                            refundModalContext.setHasCancelledInstallments(true);
                            onInstallmentsUpdate(values.cancelInstallments)
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                setSubmitting(false);
                            }
                        })
                }else{
                    onInstallmentsUpdate(registrationsWithInstallments[activeIndex].hasBeenCancelled ? true : values.cancelInstallments)
                }
            }}
        >
            {(formik) => {
                const item = registrationsWithInstallments[activeIndex].registration;

                return (
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <Form>
                            <Fade in>
                                <ModalBody>
                                    <Card className='card-shadow'>
                                        <CardBody>
                                            <div className='h5 font-medium mb-3'>
                                                <Translate id='onlineStore.profile.transactions.modals.refundModal.from.cancelInstallments.members' values={{ active: activeIndex + 1, max: registrationsWithInstallments.length }} />
                                            </div>

                                            <UserDisplay className="mb-3">
                                                <UserDisplay.Container>
                                                    <UserImg
                                                        abbr={item.member.first_name.substring(0, 1) + item.member.last_name.substring(0, 1)}
                                                        src={undefined}
                                                        width={30}
                                                        height={30}
                                                        alt={item.member.first_name + ' ' + item.member.last_name}
                                                    />
                                                </UserDisplay.Container>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title>{item.member.first_name + ' ' + item.member.last_name}</UserDisplay.Title>
                                                    <InlineCopy toCopy={item.member.unique_identifier} className="mb-2 d-inline-flex">
                                                        <div className="font-medium">#{item.member.unique_identifier || '-'}</div>
                                                    </InlineCopy>
                                                </UserDisplay.Container>
                                            </UserDisplay>

                                            {registrationsWithInstallments[activeIndex].hasBeenCancelled ?
                                                <Translate id='onlineStore.profile.transactions.modals.refundModal.from.cancelInstallments.cancelled' />
                                                :
                                                <Table size="sm" responsive className="mb-0">
                                                    <thead>
                                                        <tr>
                                                            <td className='td-shrink border-top-0'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.items.date' /></td>
                                                            <td className="text-center border-top-0"><Translate id='misc.status' /></td>
                                                            <td className="border-top-0"><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.items.amount' /></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.payments.map((payment, index) => (
                                                            <tr key={payment.invoice_payment_id}>
                                                                <td className='td-shrink'><DateFormat value={payment.due_date} /></td>
                                                                <td className="text-center">
                                                                    <Badge color={payment.status === 'COMPLETED' ? 'success' : (payment.status === 'PENDING' || payment.status === 'NEED_REVIEW' || payment.status === 'PARTIALLY_PAID') ? 'warning' : 'danger'} size="lg" pill className="px-3 align-self-start">
                                                                        <Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.status.' + payment.status} />
                                                                    </Badge>
                                                                </td>
                                                                <td className="td-shrink text-right">
                                                                    {(index === 0 && (parseInt(item?.affiliation_fee_amount || 0) - parseInt(totalCreditAmount)) > 0) ? // Add affiliations fees for first payment - temporary touch up with credit calculation
                                                                        <CurrencyFormat value={(parseInt(payment.amount) + (parseInt(item?.affiliation_fee_amount || 0) - parseInt(totalCreditAmount))) / 100} />
                                                                        :
                                                                        <CurrencyFormat value={parseInt(payment.amount) / 100} />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            }
                                        </CardBody>
                                    </Card>

                                    <FormikCheckedButton
                                        id='cancelInstallments' name='cancelInstallments'
                                        label='onlineStore.profile.transactions.modals.refundModal.form.cancelInstallments'
                                        translateLabel
                                        disabled={registrationsWithInstallments[activeIndex].hasBeenCancelled}
                                    />
                                    {formik.status &&
                                        <Alert color='danger' className='mt-3'><Translate id={'onlineStore.profile.transactions.modals.refundModal.error.' + formik.status} defaultMessageId='misc.error' /></Alert>
                                    }
                                </ModalBody>
                                <ModalFooter className='justify-content-between'>
                                    {activeIndex !== 0 &&
                                        <Button type='button' color='primary' outline onClick={() => setActiveIndex(activeIndex - 1)} disabled={formik.isSubmitting}>
                                            <Translate id='misc.previous' />
                                        </Button>
                                    }

                                    <div className='ml-auto'>
                                        <Button type="submit" color="primary" disabled={formik.isSubmitting || (registrationsWithInstallments.length === 1 && !formik.values.cancelInstallments)}>
                                            <Translate id='misc.confirm' />
                                        </Button>
                                        <Button className='ml-2' type="button" color="primary" onClick={refundModalContext.handleToggleRefresh} outline disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                    </div>
                                </ModalFooter>
                            </Fade>
                        </Form>
                    </OverlayLoader>
                )
            }}
        </Formik>
    )
}

export default RefundModalStepMemberInstallments;