import Translate from "@spordle/intl-elements"
import { useContext, useEffect, useState } from "react"
import { Alert, Button, ModalBody, ModalHeader } from "reactstrap"
import AnalyticsModal from "../../../../../analytics/AnalyticsModal"
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI"
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy"
import { AppContext } from "../../../../../contexts/contexts"
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext"
import { OrganizationContext } from "../../../../../contexts/OrganizationContext"
import { DiscriminationContext } from "../DiscriminationContext"
import MaltreatmentMemberCard, { MaltreatmentMemberCardLoading } from "./MaltreatmentMemberCard"

const MaltreatmentOverviewModal = ({ isOpen, toggle, ...props }) => {
    return (
        <AnalyticsModal analyticsName="maltreatmentOverviewModal" isOpen={isOpen}>
            <ModalHeader toggle={toggle}>
                <Translate id='discrimination.overview.title' />
            </ModalHeader>
            <ModalBody className="text-center">
                <MaltreatmentOverview toggle={toggle} {...props} />
            </ModalBody>
        </AnalyticsModal>
    )
}

const MaltreatmentOverview = ({ referenceNumber, toggle, numberOfMembers, ...props }) => {
    const orgContext = useContext(OrganizationContext)
    const appContext = useContext(AppContext)
    const discriminationContext = useContext(DiscriminationContext)
    const identityRolesContext = useContext(IdentityRolesContext)

    const [ discriminations, setDiscriminations ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        orgContext.getDiscriminations()
            .then(async(discriminations) => {
                const filteredDiscriminations = discriminations.filter((discrimination) => discrimination.reference_number === referenceNumber)

                // add function will check and prevent duplicates
                await appContext.addRecentMaltreatmentComplaint(referenceNumber, filteredDiscriminations.length, identityRolesContext.identity_role_id);

                discriminationContext.setRecentMaltreatmentComplaint({
                    referenceNumber,
                    numberOfMembers: filteredDiscriminations.length,
                })

                setDiscriminations(filteredDiscriminations)
                setIsLoading(false)
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    setDiscriminations()
                    setIsLoading(false)
                }
            })
    }, [])

    return (
        <>
            <div className="text-muted">
                <Translate id='discrimination.global.itpReferenceNumber' />
            </div>
            <div className="mb-3">
                <InlineCopy
                    toCopy={referenceNumber}
                >
                    #{referenceNumber}
                </InlineCopy>
            </div>

            {isLoading ?
                Array(numberOfMembers).fill("").map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MaltreatmentMemberCardLoading key={index} />
                ))
                :
                discriminations?.map((discrimination) => (
                    <MaltreatmentMemberCard
                        key={discrimination.discrimination_id}
                        discrimination={discrimination}
                        toggle={toggle}
                    />
                ))
            }

            <Alert className='mt-3 text-left' color='info'>
                <i className="mdi mdi-information-outline mr-1" /><Translate id="discrimination.overview.finish.text" />
            </Alert>

            {/* <InlineConfirmation
                primaryButtonTranslation='misc.finish'
                color='primary'
                confirmationTooltipTitle='discrimination.overview.finish.title'
                confirmationTooltipText='discrimination.overview.finish.text'
                onClick={() => {
                    appContext.removeRecentMaltreatmentComplaint(referenceNumber)
                    toggle();
                }}
            /> */}
            <Button
                onClick={() => {
                    toggle();
                }}
                type="button"
                color="primary"
                className="mr-2"
                outline
            >
                <Translate id="misc.close" />
            </Button>
            <Button
                onClick={() => {
                    appContext.removeRecentMaltreatmentComplaint(referenceNumber)
                    toggle();
                }}
                type="button"
                color="primary"
                className="mr-2"
            >
                <Translate id="misc.finish" />
            </Button>
        </>
    )
}

export default MaltreatmentOverviewModal
export{ MaltreatmentOverview }
