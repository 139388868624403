import Translate from '@spordle/intl-elements';
import moment from 'moment';
import { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, CardBody } from 'reactstrap';
import useSWR from 'swr';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { MembersContext } from '../../../../contexts/MembersContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import CanDoAction from '../../../permissions/CanDoAction';
import { fail } from "@spordle/toasts";
import UserDisplay from '../../../userDisplay/UserDisplay';
import TransferRequestComment from '../TransferRequestComment';
import DisplayOrg from '../../../DisplayOrg';

const CommentsTab = ({ transfer, isClosed }) => {
    const memberContext = useContext(MembersContext);

    const { data: comments, error, isValidating } = useSWR(
        [ 'getTransferComments', transfer.member.member_id, transfer.member_transfer_id ],
        () => memberContext.getTransferComments(transfer.member.member_id, transfer.member_transfer_id)
            .then((comments) => {
                return comments.sort((commentA, commentB) => {
                    if(commentB.created_at){
                        return commentA.created_at ? commentB.created_at.localeCompare(commentA.created_at) : 1;
                    }
                    return commentA.created_at ? -1 : 0;

                })
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        ,
        {
            revalidateOnFocus: false,
        },
    );

    return (
        <div className="p-3">
            {error ?
                <div className='text-dark text-center font-bold mb-0'>
                    <Translate id='misc.error' />
                </div>
                :
                <>
                    {!isClosed &&
                        <AddCommentButton transfer={transfer} />
                    }
                    {!isValidating && comments ?
                        <>
                            {comments.length == 0 ?
                                <div className='text-muted text-center mb-0'>
                                    <Translate id='transfer.sidepanel.tabs.comment.none' />
                                </div>
                                :
                                comments.map((comment) => (
                                    <Card key={comment.member_transfer_comment_id} className="card-shadow mb-3">
                                        <CardBody className="p-3">
                                            <div className="border-bottom pb-1 mb-3">
                                                <div className='d-flex justify-content-between'>
                                                    <div className='text-dark font-bold mb-0 text-break'>
                                                        {comment.identity ?
                                                            <>{comment.identity.name} {comment.identity.family_name}</>
                                                            :
                                                            <DisplayOrg org={comment.organisation} />
                                                        }
                                                    </div>
                                                    <div className='small text-right flex-shrink-0 ml-1'>
                                                        {comment.created_at && moment(comment.created_at).fromNow()}
                                                    </div>
                                                </div>
                                                {(comment.identity) &&
                                                    <div className='small'>
                                                        <DisplayOrg org={comment.organisation} />
                                                    </div>
                                                }
                                            </div>
                                            <div>
                                                {comment.comment}
                                            </div>
                                        </CardBody>
                                    </Card>
                                ))
                            }
                        </>
                        :
                        <Skeleton height={120} count={3} className='mb-4' />
                    }
                </>
            }
        </div>
    );
}

const AddCommentButton = ({ transfer }) => {
    const [ modalIsOpen, setModalOpen ] = useState(false);
    const toggleModal = () => { setModalOpen(!modalIsOpen) };

    return (
        <CanDoAction action='EDIT' componentCode='members' componentPermissionCode='members_request_transfer'>
            <div className="border-bottom mb-3">
                <UserDisplay card hover className="border-primary p-0 text-center w-100 mb-3" style={{ borderStyle: 'dashed' }}>
                    <button
                        onClick={toggleModal}
                        type="button"
                        className="reset-btn text-link p-3 w-100"
                    >
                        <i className="mdi mdi-plus mr-1" /><Translate id='transfer.sidepanel.actions.addComment' />
                        <TransferRequestComment isOpen={modalIsOpen} toggle={toggleModal} transfer={transfer} />
                    </button>
                </UserDisplay>
            </div>
        </CanDoAction>
    )
}

export default CommentsTab;