export default{
    "members.profile.overview.memberMemos.title" : "Memos",

    // table
    "members.profile.overview.memberMemos.table.hint.none1" : "You can add a memo",
    "members.profile.overview.memberMemos.table.hint.none2" : "Add a memo",
    "members.profile.overview.memberMemos.table.noData"     : "No memos",
    "members.profile.overview.memberMemos.createdBy"        : "Created by",

    // add
    "members.profile.overview.memberMemos.add.title" : "Add a memo",

    // side panel
    "members.profile.overview.memberMemos.remove"              : "Remove",
    "members.profile.overview.memberMemos.remove.swal.success" : "Memo removed!",
    "members.profile.overview.memberMemos.update.name.success" : "Name updated!",
    "members.profile.overview.memberMemos.update.desc.success" : "Description updated!",
}