import { stringBuilder } from "@spordle/helpers";
import { useFormikContext } from "formik";
import { CustomInput } from "reactstrap";
import UserDisplay from "../../../../userDisplay/UserDisplay";

/**
 * @description Card that displays the identity
 * @param {Object} props
 * @param {object} identity
 * @returns {JSX.Element}
 */
const IdentityCard = ({ identity }) => {
    const { setFieldValue, values } = useFormikContext();

    return (
        <UserDisplay
            tag="label"
            key={identity.identity_id}
            card
            hover
            block
            className={stringBuilder("mb-3", { "border-primary": values.identity_id === identity.identity_id })}
        >
            <UserDisplay.Container>
                <CustomInput
                    checked={values.identity_id === identity.identity_id}
                    id={identity.identity_id}
                    type="radio"
                    name="type"
                    onClick={function(){}}
                    onChange={() => {
                        setFieldValue('identity_id', identity.identity_id)
                    }}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title>{identity.name + ' ' + identity.family_name}</UserDisplay.Title>
                <UserDisplay.Subtitle>{identity.email}</UserDisplay.Subtitle>
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default IdentityCard;