import { Link } from 'react-router-dom';

const PageNotFound404 = () => {
    return (
        <>
            <div className="page-content container-fluid">
                <div className="text-center">
                    <div className="display-4 mb-5">404 Page not found</div>
                    <Link to={'/'} className="btn btn-primary">Back to Dashboard</Link>
                </div>
            </div>
        </>
    );
}

export default PageNotFound404;