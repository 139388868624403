import Axios from "axios";

/**
 * Determines if the Axios call was cancelled or not
 * @param {*} error
 * @example
 * return API_ACCESS.get(queryString.stringifyUrl({url: `organisations/${organizationId}`}))
 *     .then(response => {
 *         if(response.data.status){
 *             return response.data.organisation[0];
 *         }
 *         throw response.data.errors[0]
 *     }, serverError)
 */
export const serverError = (error) => {
    if(Axios.isCancel(error)){
        throw new Error('Cancelled');
    }else if(error.message == 'forbidden'){
        throw new Error("forbidden");
    }else if(error.message == 'unauthorized'){
        throw new Error("unauthorized");
    }else if(error.message == 'teapot'){
        throw new Error("teapot");
    }else{
        throw new Error("server");
    }
}

export let activeCalls = [];
// added a list of active calls specifically for reports to be able to cancel them seperately from all other calls
export let activeReportCalls = [];
// ^ same but /members calls
export let activeMembersCalls = [];
// ^ same but /teams calls
export let activeTeamsCalls = [];
// ^ same but /clinics calls
export let activeClinicsCalls = [];
// ^ same but  /transfers calls
export let activeTransferCalls = [];
// ^ same but /discrimination calls
export let activeDiscriminationCalls = [];
// ^ same but /insurance calls
export let activeInsuranceCalls = [];
// ^ same but game incident re calls
export let activeGIRCalls = [];


export const AxiosCancelAll = () => {
    activeCalls.forEach((call) => { call() });
    activeReportCalls.forEach((call) => { call() });
    activeMembersCalls.forEach((call) => { call() });
    activeTeamsCalls.forEach((call) => { call() });
    activeTransferCalls.forEach((call) => { call() });
    activeDiscriminationCalls.forEach((call) => { call() });
    activeInsuranceCalls.forEach((call) => { call() });
    activeGIRCalls.forEach((call) => { call() });

    activeCalls = [];
    activeReportCalls = [];
    activeMembersCalls = [];
    activeTeamsCalls = [];
    activeClinicsCalls = [];
    activeTransferCalls = [];
    activeDiscriminationCalls = [];
    activeInsuranceCalls = [];
    activeGIRCalls = [];
}

// cancel all GET calls starting with /reports/
export const AxiosCancelReportCalls = () => {
    activeReportCalls.forEach((call) => { call() });
    activeReportCalls = [];
}

// cancel all GET calls starting with /members
// this works well for members advanced search, but be very careful if you reuse this somewhere else as this will cancel all member calls
export const AxiosCancelMembersCalls = () => {
    activeMembersCalls.forEach((call) => { call() });
    activeMembersCalls = [];
}

// cancel all GET calls starting with /teams
// this works well for teams advanced search, but be very careful if you reuse this somewhere else as this will cancel all /teams calls
export const AxiosCancelTeamsCalls = () => {
    activeTeamsCalls.forEach((call) => { call() });
    activeTeamsCalls = [];
}

// cancel all GET calls starting with /teams
// this works well for teams advanced search, but be very careful if you reuse this somewhere else as this will cancel all /teams calls
export const AxiosCancelClinicsCalls = () => {
    activeClinicsCalls.forEach((call) => { call() });
    activeClinicsCalls = [];
}

export const AxiosCancelTransferCalls = () => {
    activeTransferCalls.forEach((call) => { call() });
    activeTransferCalls = [];
}

export const AxiosCancelDiscriminationCalls = () => {
    activeDiscriminationCalls.forEach((call) => { call() });
    activeDiscriminationCalls = [];
}

export const AxiosCancelInsuranceCalls = () => {
    activeInsuranceCalls.forEach((call) => { call() });
    activeInsuranceCalls = [];
}

export const AxiosCancelGIRCalls = () => {
    activeGIRCalls.forEach((call) => { call() });
    activeGIRCalls = [];
}

/**
 * @param {string} message The message to check
 * @returns {boolean} If the axios call is Cancelled
 */
export const AxiosIsCancelled = (message) => message === 'Cancelled' || message === 'forbidden' || message === 'unauthorized' || message === 'teapot';