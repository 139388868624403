import InlineCopy from "../../../../components/inlineCopy/InlineCopy";
import QuickViewButton from "../../../../components/quickView/QuickViewButton";
import UserImg from "../../../../components/UserImg";
import { Link } from 'react-router-dom';
import { Fade, ListGroup, ListGroupItem, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Translate from "@spordle/intl-elements";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import DisplayMember from "../../../../components/display/DisplayMember";
import DuplicateMatchScore from "../DuplicateMatchScore";
import moment from "moment";
import StatusBadge from "../../../../components/badges/StatusBadge";
import DisplayOrg from "../../../../components/DisplayOrg";

const SinglePotentialMemberDuplicatesSidePanel = ({ potentialDuplicate }) => {
    const member = potentialDuplicate.member;
    const organisation = potentialDuplicate.organisation;
    const duplicates = potentialDuplicate.duplicate_members;

    return (
        <>
            <div className="d-flex align-items-center px-3 pb-3">
                <UserImg
                    alt={member.first_name + ' ' + member.last_name}
                    abbr={member.first_name?.charAt(0) + member.last_name?.charAt(0)}
                    src={member.picture?.full_path}
                    filePos={member.picture?.file_position}
                    width={60}
                    className="mr-2"
                />
                <div className="w-100">
                    <div className="d-flex align-items-center text-break">
                        <span className='h4 font-bold mb-0'>
                            <QuickViewButton member={member}>
                                {member.first_name} {member.last_name}
                            </QuickViewButton>
                        </span>
                    </div>
                    <div>
                        <InlineCopy tag="span" toCopy={member.unique_identifier}>
                            <Link className="text-nowrap" to={`/members/profile/${member.member_id}`}>
                                {member.unique_identifier ?
                                    <>#{member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                    :
                                    '-'
                                }
                            </Link>
                        </InlineCopy>
                    </div>
                    <StatusBadge color={member.member_status?.color_code} status={member.member_status} />
                </div>
            </div>
            <Nav tabs>
                <NavItem className="text-center" style={{ width: '100%' }}>
                    <NavLink className="active">
                        <Translate id='task.mandatoryChangesRequests.sidepanel.request' />
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab="1">
                <TabPane tabId="1">
                    <Fade in>
                        <div className="p-3">
                            <div className='text-muted d-flex'>
                                <Translate id='misc.organization' />
                            </div>
                            {/* hardcoded because this API call only returns pending member change requests */}
                            <div className='text-dark mb-3'>
                                <DisplayOrg org={organisation} />
                            </div>
                            <div className='text-muted d-flex'>
                                <Translate id="tasks.memberDuplicates.lastSubmitted" />
                            </div>
                            <div className='text-dark mb-3'>
                                {moment(potentialDuplicate.updated_at).format("YYYY-MM-DD HH:mm")}
                            </div>
                            {duplicates.length > 0 &&
                                <>
                                    <div className='h5 font-bold mt-3'>
                                        <QuickViewButton member={duplicates.map(({ member }) => member)}>
                                            <Translate id="tasks.memberDuplicates.potentialDuplicates.count" values={{ count: duplicates.length }} />
                                        </QuickViewButton>
                                    </div>
                                    <ListGroup className='mb-2 shadow-sm'>
                                        {duplicates.map(({ member: duplicateMember, ...duplicate }) => (
                                            <ListGroupItem className='px-3' key={duplicateMember.member_potential_ineligible_id}>
                                                <DisplayMember
                                                    member={duplicateMember}
                                                    withLink
                                                    extra={{
                                                        left: (
                                                            <UserDisplay.Container className="ml-auto text-center">
                                                                <DuplicateMatchScore duplicate={duplicate} />
                                                            </UserDisplay.Container>
                                                        ),
                                                        infoBottom: (
                                                            <div className="small">
                                                                <div>
                                                                    <Translate id="misc.status" />: <StatusBadge color={member.member_status?.color_code} status={member.member_status} />
                                                                </div>
                                                                <div>
                                                                    <Translate id="reports.filters.submitted" />: <span className="font-medium">{moment(duplicate.updated_at).format("YYYY-MM-DD HH:mm")}</span>
                                                                </div>
                                                            </div>
                                                        ),
                                                    }}
                                                />
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                </>
                            }
                        </div>
                    </Fade>
                </TabPane>
            </TabContent>
        </>
    );
}

export default SinglePotentialMemberDuplicatesSidePanel;