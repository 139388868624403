import { FormikDateTime, FormikInputText, FormikSelect } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import moment from "moment";
import { useContext } from "react";
import { Alert, Button, Col, Collapse, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { array, mixed, object, string } from "yup";
import FormikTiny from "../../../../../../components/formik/FormikTiny";
import Required from "../../../../../../components/formik/Required";
import FileUpload from "../../../../../../components/uploader/fileUpload/FileUpload";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { DISCRIMINATION_REASONS } from "../../DiscriminationHelper";
import { DiscriminationAddContext } from "../components/DiscriminationAddContext";

const DiscriminationAddModalStepIncident = ({ stepNb }) => {
    const { isLoading, next, setComplaint, step } = useContext(DiscriminationAddContext);
    const { getMaltreatmentTypes, getMaltreatmentLocations } = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                arena: '',
                closed_reason: [],
                date: '',
                discrimination_status_id: '',
                files: [],
                grounds: [],
                reference_number: '',
                summary: '',
                maltreatment_type_id: '',
                maltreatment_location_id: '',
                maltreatment_location: null,
            }}
            validationSchema={object().shape({
                reference_number: string().required(<Translate id="discrimination.form.referenceNb.required" />).max(100, <Translate id="form.validation.string.max" values={{ count: 100 }} />),
                arena: string().when('maltreatment_location', {
                    is: (val) => !val || val.need_playing_surface == 1,
                    then: (schema) => schema.required(<Translate id='discrimination.form.arena.required' />),
                }).max(100, <Translate id="form.validation.string.max" values={{ count: 100 }} />),
                date: mixed().required(<Translate id='form.validation.date.required' />)
                    .isDate()
                    .test({
                        name: 'isBeforeToday',
                        message: <Translate id='discrimination.form.incident.future' />,
                        test: (date) => moment(date).isSameOrBefore(moment(), 'days'),
                    }),
                maltreatment_location: object().nullable().shape({
                    need_playing_surface: string().oneOf([ "1", "0" ]),
                }),
                maltreatment_type_id: string().required(<Translate id="discrimination.form.type.required" />),
                maltreatment_location_id: string().required(<Translate id="discrimination.form.type.required" />),
                grounds: array().min(1, <Translate id='discrimination.form.discriminatoryGrounds.min' />),
                summary: string().required(<Translate id='discrimination.form.details.required' />).max(1000, <Translate id="form.validation.string.max" values={{ count: 1000 }} />),
                files: array().of(mixed()),
            })}
            onSubmit={(values) => {
                setComplaint('incident', values);
                next();
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <Row form>
                            {/* Complaint Location & Date Section */}
                            <Col sm="12" className="form-group">
                                <Label className="text-muted" for="reference_number"><Translate id='discrimination.global.itpReferenceNumber' /> <Required /></Label>
                                <FormikInputText name="reference_number" />
                            </Col>
                            <Col sm={12} className="form-group">
                                <Label className="text-muted" for="maltreatment_location_id">
                                    <Translate id='discrimination.global.incidentLocation' /> <Required />
                                </Label>
                                <FormikSelect
                                    name="maltreatment_location_id"
                                    id="maltreatment_location_id"
                                    search={false}
                                    renderOption={({ option, isSelected }) => (
                                        <>
                                            <DisplayI18n
                                                field="name"
                                                i18n={option.ressource.i18n}
                                                defaultValue={option.label}
                                            />
                                            <small className={stringBuilder("d-block", `text-${isSelected ? 'light' : 'muted'}`)}>
                                                <DisplayI18n
                                                    field="description"
                                                    i18n={option.ressource.i18n}
                                                    defaultValue={option.ressource.description}
                                                />
                                            </small>
                                        </>
                                    )}
                                    renderSelectedOption={(option) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={option.ressource.i18n}
                                            defaultValue={option.label}
                                        />
                                    )}
                                    searchKeys={[
                                        `ressource.i18n.${getGenericLocale()}.name`,
                                    ]}
                                    onOptionSelected={([ val ], select) => {
                                        const ressource = select.getSpordleTable().getData().find(({ value }) => value === val)?.ressource;
                                        formik.setFieldValue('maltreatment_location_id', val);
                                        formik.setFieldValue('maltreatment_location', ressource);
                                        return true;
                                    }}
                                    loadData={(from) => {
                                        switch (from){
                                            case 'CDM':
                                                return getMaltreatmentLocations({ active: 1 })
                                                    .then((locations) => (
                                                        locations.map((loc) => ({
                                                            value: loc.maltreatment_location_id,
                                                            label: loc.name,
                                                            ressource: loc,
                                                        }))
                                                            .sort((a, b) => {
                                                                const aName = displayI18n('name', a.ressource.i18n, a.label, getGenericLocale());
                                                                const bName = displayI18n('name', b.ressource.i18n, b.label, getGenericLocale());
                                                                return aName.localeCompare(bName);
                                                            })
                                                    ))
                                            default:
                                                break;
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm="6" className="form-group">
                                <Label className="text-muted" for="date"><Translate id='discrimination.global.incidentDate' /> <Required /></Label>
                                <FormikDateTime
                                    name="date"
                                    id="date"
                                    isValidDate={(current) => current.isSameOrBefore(moment(), 'days')}
                                    timeFormat={false}
                                />
                            </Col>
                            <Col sm="6" className="form-group">
                                <Label className="text-muted" for="arena"><Translate id='discrimination.label.arena' /> {(!formik.values.maltreatment_location || formik.values.maltreatment_location.need_playing_surface == 1) && <Required />}</Label>
                                <FormikInputText trim name="arena" />
                            </Col>
                            {/* Complaint Type / Status & Suspensions */}
                            <Col sm={12} className="form-group">
                                <hr />
                                <Label className="text-muted" for="maltreatment_type_id"><Translate id='discrimination.global.maltreatmentType' /> <Required /></Label>
                                <FormikSelect
                                    name="maltreatment_type_id"
                                    id="maltreatment_type_id"
                                    search={false}
                                    renderOption={({ option, isSelected }) => (
                                        <>
                                            <DisplayI18n
                                                field="name"
                                                i18n={option.ressource.i18n}
                                                defaultValue={option.label}
                                            />
                                            <small className={stringBuilder("d-block", `text-${isSelected ? 'light' : 'muted'}`)}>
                                                <DisplayI18n
                                                    field="description"
                                                    i18n={option.ressource.i18n}
                                                    defaultValue={option.ressource.description}
                                                />
                                            </small>
                                        </>
                                    )}
                                    renderSelectedOption={(option) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={option.ressource.i18n}
                                            defaultValue={option.label}
                                        />
                                    )}
                                    searchKeys={[
                                        `ressource.i18n.${getGenericLocale()}.name`,
                                    ]}
                                    loadData={(from) => {
                                        switch (from){
                                            case 'CDM':
                                                return getMaltreatmentTypes({ active: 1 })
                                                    .then((types) => (
                                                        types.map((type) => ({
                                                            value: type.maltreatment_type_id,
                                                            label: type.name,
                                                            ressource: type,
                                                        }))
                                                            .sort((a, b) => {
                                                                const aName = displayI18n('name', a.ressource.i18n, a.label, getGenericLocale());
                                                                const bName = displayI18n('name', b.ressource.i18n, b.label, getGenericLocale());
                                                                return aName.localeCompare(bName);
                                                            })
                                                    ))
                                            default:
                                                break;
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm="12" className="form-group">
                                <Label className="text-muted" for="grounds"><Translate id='discrimination.global.discriminatoryGrounds' /> <Required /></Label>
                                <FormikSelect
                                    multi
                                    textWhenSetting={{
                                        count: 1,
                                        label: 'discrimination.addModal.form.select.groundsSelected',
                                    }}
                                    name="grounds"
                                    id="grounds"
                                    search={false}
                                    defaultData={DISCRIMINATION_REASONS.map((reason) => ({
                                        value: reason,
                                        label: 'discrimination.global.discriminatoryGrounds.' + reason,
                                        translateLabel: true,
                                    }))}
                                    loadingStatus='success'
                                />
                            </Col>
                            {/* Documents Section */}
                            <Col sm="12" className="form-group">
                                <hr />
                                <Label className="text-muted" for="files">
                                    <Translate id="form.fields.documents" />
                                </Label>
                                <FileUpload
                                    dropzoneProps={{
                                        multiple: true,
                                        accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx",
                                    }}
                                    name="files"
                                    onFileSelect={(files) => formik.setFieldValue('files', files)}
                                />
                            </Col>

                            {/* Details Section */}
                            <Col sm="12">
                                <hr />
                                <Label className="text-muted" for="summary"><Translate id='discrimination.label.details' /> <Required /></Label>
                                <FormikTiny init={{ height: 150 }} name="summary" />
                                <small className="d-block text-muted mt-2">
                                    <Translate id='discrimination.addModal.stepIncident.details.helper' />
                                </small>
                            </Col>
                        </Row>
                        <Collapse isOpen={!!formik.status}>
                            <Alert color="danger">
                                {formik.status}
                            </Alert>
                        </Collapse>
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button disabled={isLoading} onClick={previous} outline type="button" color="primary" className='mr-auto'><Translate id="misc.previous" /></Button> */}
                        <Button disabled={isLoading || step !== stepNb} type="submit" color="primary"><Translate id="misc.next" /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

export default DiscriminationAddModalStepIncident;