import { useDisclosure } from "@mantine/hooks";
import MemberNumberModal from "./MemberNumberModal";
import { Button } from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";

const MemberNumberCard = ({ member, className }) => {
    const [ isOpen, { open, close } ] = useDisclosure(false);

    if(!member?.unique_identifier){
        return null;
    }

    return (
        <>
            <MemberNumberModal
                isOpen={isOpen}
                toggle={close}
                member={member}
            />
            <Button
                onClick={open}
                color="primary"
                outline
                className={stringBuilder(className, "with-icon mdi mdi-account-card-details")}
            >
                <Translate id="members.profile.overview.card" />
            </Button>
        </>
    )
}

export default MemberNumberCard;