import { useState } from "react";
import {
    Button,
    Card
} from "reactstrap";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';

import Translate from "@spordle/intl-elements";

// Contexts
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import withContexts from "../../../../../../helpers/withContexts";
import OrganizationContactTypesAdd from "./OrganizationContactTypesAdd";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";

const OrganizationContactTypes = () => {
    const [ modalAdd, setModalAdd ] = useState(false);
    const toggleModalAdd = () => setModalAdd(!modalAdd);

    return (
        <Card body className="card-shadow">
            <CardSectionTitle title='settings.tabs.contactTypes' />
            <div className="mb-2">
                <div className='d-flex justify-content-between'>
                    <SpordleTableProvider.SearchInput />
                    <div className='d-flex ml-auto text-right'>
                        <SpordleTableProvider.Refresh />
                        <CanDoAction action='ADD' componentCode='settings' componentPermissionCode='contact_type'>
                            <OrganizationContactTypesAdd isOpen={modalAdd} toggle={toggleModalAdd} />
                            <Button className='ml-2' color='primary' onClick={toggleModalAdd}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                        </CanDoAction>
                    </div>
                </div>
            </div>
            <SpordleTableView />
        </Card>


    )

}

export default withContexts(OrganizationContext)(OrganizationContactTypes);