import { DateFormat } from "@spordle/intl-elements"
import { DisplayI18n } from "../../../helpers/i18nHelper"

const ListDataType = ({ data }) => {

    const getValueItem = (valueType, value) => {
        switch (valueType){
            case 'url':
                if(value)
                    return <a href={value} title={value} target='_blank' rel='noopener noreferrer'>Go to link</a>
                break;
            case 'date':
                if(value)
                    return <DateFormat value={value} />
                break;
            default:
                return value || '-'
        }
        return value || '-';
    }

    return (
        <ul className="list-unstyled bg-light-inverse p-2 mb-3">
            {data.map((listItem) => (
                <li className="d-flex small font-bold" key={listItem.itemId}>
                    <div className="text-nowrap"><DisplayI18n field="name" defaultValue={listItem.key.label} i18n={listItem.key.i18n} />:</div>
                    <div className="ml-2">{getValueItem(listItem.valueType, listItem.value)}</div>
                </li>
            ))}
        </ul>
    )
}

export default ListDataType