import { useSpordleTable } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import moment from "moment";
import { useContext, useState } from "react";
import { Alert, Button, Col, Collapse, ModalBody, Row } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import { GameIncidentsContext } from "../../../../../../../contexts/GameIncidentsContext";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { isSubmitted } from "../../../gameIncidentHelper";

const AddGameIncidentStepStatus = ({ girData, setIsLoading, isLoading, statuses, toggle }) => {
    const [ error, setError ] = useState(null);
    const { partiallyUpdateGameIncident } = useContext(GameIncidentsContext);
    const { updateDataWith } = useSpordleTable();

    const handleYes = () => {
        const submittedStatus = statuses.find(isSubmitted);

        if(submittedStatus && girData.game_incident_id){
            setIsLoading(true);
            partiallyUpdateGameIncident(girData.game_incident_id, { game_incident_status_id: submittedStatus.game_incident_status_id })
                .then(() => {
                    updateDataWith(girData.game_incident_id,
                                   { game_incident_status: submittedStatus, submitted_date: moment().toISOString() })
                    toggle();
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        setIsLoading(false);
                        setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                    }
                })
        }else{
            toggle();
        }
    }

    return (
        <ModalBody className="text-center justify-content-center d-flex flex-column d-md-block">
            <div className="h4 font-bold mb-4">
                <Translate id='gameIncident.addModal.steps.status.title' />
            </div>
            <Row form>
                <Col className="mb-2" md="6">
                    <Button className="border shadow-sm" disabled={isLoading} color="light" block size="lg" type="button" onClick={handleYes}>
                        <span className="d-block font-20 font-bold">
                            <Translate id='gameIncident.addModal.steps.status.btn.yes' />
                        </span>
                        <span>
                            <Translate id='gameIncident.addModal.steps.status.btn.yes.helper' />
                        </span>
                    </Button>
                </Col>
                <Col className="mb-2" md="6">
                    <Button className="border shadow-sm" disabled={isLoading} color="light" block size="lg" type="button" onClick={toggle}>
                        <span className="d-block font-20 font-bold">
                            <Translate id='gameIncident.addModal.steps.status.btn.no' />
                        </span>
                        <span>
                            <Translate id='gameIncident.addModal.steps.status.btn.no.helper' />
                        </span>
                    </Button>
                </Col>
            </Row>
            {error &&
                <Collapse appear isOpen>
                    <Alert color="danger" toggle={() => setError(null)}>
                        {error}
                    </Alert>
                </Collapse>
            }
        </ModalBody>
    );
}

export default AddGameIncidentStepStatus;