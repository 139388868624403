import { fail, fire } from '@spordle/toasts';
import { roundForMoney } from "./helper";
import { MONEY_FIELDS } from "./reportsHelper";
import InlineCopy from "../components/inlineCopy/InlineCopy";

async function copyContent(text){
    return new Promise(async(resolve, reject) => {
        try{
            if(navigator?.clipboard && window.isSecureContext){ // copy api https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
                await navigator.clipboard.writeText(text);
            }else{ // fallback
                await _fallbackCopy(text);
            }

            resolve(text);
        }catch(e){
            reject(e);
        }
    })
}

async function copyToClipBoard(text, successMsg = 'misc.clipboard.success', failMsg = 'misc.clipboard.error'){
    try{
        if(navigator?.clipboard && window.isSecureContext){ // copy api https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
            await navigator.clipboard.writeText(text);
        }else{ // fallback
            await _fallbackCopy(text);
        }

        fire({ msg: successMsg, info: text.length < 75 ? text : text.substring(0, 75) + '...', skipInfoTranslate: true, theme: 'success' });
    }catch(err){
        console.error(err);
        fail({ msg: failMsg });
    }
}

async function _fallbackCopy(text){
    const textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
    // May cease to work, but it still is a fallback for some browsers
    const successful = document.execCommand('copy');

    document.body.removeChild(textArea);

    if(!successful){
        throw new Error('Could not copy');
    }

    return Promise.resolve(successful);
}

/**
 * @type {import('@spordle/datatables').default['props']['onColumnClick']}
 */
function controlClickCopy(e, rowData, spordleTable, columnName){
    if(e.nativeEvent.ctrlKey && rowData[columnName]){
        if(!isNaN(rowData[columnName]) && MONEY_FIELDS.includes(columnName)){
            //for money
            copyToClipBoard(roundForMoney(rowData[columnName].toString()))
        }else{
            // for everything else
            copyToClipBoard(rowData[columnName].toString());
        }
        return false;
    }
    return true;
}

// copyAddressToClipBoard
export{ copyToClipBoard, controlClickCopy, copyContent };

/**
 * @deprecated please use < InlineCopy /> instead
 * @param {string} className the className for style
 * @param {string} tag
 * @param {string} toCopy
 * @description replaced the innards of this component with < InlineCopy /> component by Gab, so we can have the Jira-style copy tooltip
 */
export const HoverableCopy = ({ className, tag: Tag = "div", children, toCopy }) => (
    <InlineCopy className={className} tag={Tag} toCopy={toCopy}>
        {children}
    </InlineCopy>
)