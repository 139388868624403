import { Tooltip } from "@mantine/core";
import { stringBuilder } from "@spordle/helpers";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Badge, Fade } from "reactstrap";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import BlinkDot from "../../../../components/blinkDot/BlinkDot";
import Svg from "../../../../components/Svg";
import { MessagesContext } from "../../../../contexts/MessagesContext";
import { Link } from "react-router-dom";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import { removeHTML } from "../../../../helpers/helper";

const CommunicationsList = ({ mutate, close, messages }) => {
    const isLoading = !Array.isArray(messages);
    const { partiallyUpdateMessage } = useContext(MessagesContext);

    const markAllMessagesAsRead = () => {
        mutate(messages.map((msg) => ({ ...msg, is_read: "1" })), false);

        Promise.all(messages.map((msg) => partiallyUpdateMessage(msg.communication_message_id, { is_read: 1 })))
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }
            })
    }

    return (
        <div style={{ overflow: "auto" }} className="notification d-flex flex-column text-center border-top-0">
            {isLoading ?
                <Skeleton count={5} />
                :
                messages.length > 0 ?
                    <>
                        <CanDoAction action="EDIT" componentCode="communications" componentPermissionCode="communications_manage">
                            <div className="text-right px-3 py-2">
                                <button onClick={markAllMessagesAsRead} type='button' className="notification-read-all"><Translate id='header.notifications.btn.clearAll' /></button>
                            </div>
                        </CanDoAction>
                        <div className="h-100">
                            {messages.map((message) => (
                                <Message
                                    key={message.message_id}
                                    msg={message}
                                    mutate={mutate}
                                    close={close}
                                />
                            ))}
                        </div>
                    </>
                    :
                    <Fade className="p-3">
                        <i className="mdi mdi-inbox text-muted fa-6x" />
                        <div className="h4 font-bold mb-0"><Translate id="header.communications.empty.title" /></div>
                        <div><Translate id="header.communications.empty.msg" /></div>
                    </Fade>
            }
        </div>
    )
}

const Message = ({ mutate, msg, close }) => {
    const [ isRead, setIsRead ] = useState(msg.is_read == 1);
    const { partiallyUpdateMessage } = useContext(MessagesContext);

    const markAsRead = (e) => {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }

        if(!isRead){
            setIsRead(true);
            partiallyUpdateMessage(msg.communication_message_id, { is_read: 1 })
                .then(() => {
                    mutate();
                })
                .catch(console.error);
        }
    }

    const handleOnClick = () => {
        markAsRead();
        close();
    }

    return (
        <div
            className="p-3 border-top text-left d-flex align-items-center position-relative list-group-item-action"
        >
            <Badge color="light" className="round border bg-primary-light text-primary d-flex mr-3 align-items-center">
                <Svg icon="communications" />
            </Badge>
            <div className="w-100 min-w-0">
                <div className="d-flex h5 font-bold align-items-center justify-content-between w-100">
                    <div className={stringBuilder("text-truncate mr-2")}>
                        {msg.title}
                    </div>
                    {msg.is_read == 0 && !isRead &&
                        <CanDoAction action="EDIT" componentCode="communications" componentPermissionCode="communications_manage">
                            <Tooltip zIndex={2000} withArrow label={<Translate id="header.notifications.item.tip.read" />}>
                                <BlinkDot onClick={markAsRead} size="lg" className="mr-2 clickable" />
                            </Tooltip>
                        </CanDoAction>
                    }
                </div>
                <div style={{ maxHeight: "1.25em" }} className="min-w-0 text-truncate small">
                    {removeHTML(msg.body)}
                </div>
                <time className="text-body text-nowrap small"><DateFormat value={msg.created_at} /></time>
            </div>
            <Link onClick={handleOnClick} to={{ pathname: "/communications/received", state: msg }} className="stretched-link" />
        </div>
    )
}

export default CommunicationsList;