import { Col, Row } from "reactstrap";
import ChecklistIcon from "./components/ChecklistIcon";
import { Link } from 'react-router-dom'
import Translate from "@spordle/intl-elements";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";

const ChecklistNotFound = () => {
    return (
        <>
            <ViewHeaderV2 />
            <div className="page-content pt-3 px-4 container-fluid">
                <Row>
                    <Col sm="12" md="4" lg="4">
                        <div className="h1 font-bold dashboard-title mb-4"><Translate id="checklists.notFound" /></div>
                        <p className="font-16"><Translate id="checklists.notFound.txt" /></p>
                        <Link to='/dashboard' className="btn btn-primary mr-2 mb-2 mdi mdi-arrow-left with-icon"><Translate id='engine.report.not.found.return' /></Link>
                    </Col>
                    <Col sm="12" md="8" lg="8">
                        <ChecklistIcon width="50%" isComplete style={{ maxWidth: 400 }} className="ml-4" />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ChecklistNotFound;