import { stringBuilder } from "@spordle/helpers";
import styles from "./ChecklistProgress.module.scss";
import Translate from "@spordle/intl-elements";

const ChecklistProgress = ({ total, complete }) => {
    const percentCompleted = complete / total * 100;

    return (
        <>
            <small className="text-muted">
                {complete} / {total} <Translate id="checklists.stepsComplete" />
            </small>
            <div
                style={{ "--progress-percent": `${percentCompleted}%` }}
                className={stringBuilder("bg-light text-success mb-3", styles.bar)}
            />
        </>
    );
}

export default ChecklistProgress;