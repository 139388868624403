import React from "react";
import {
    Button,
    Card
} from "reactstrap";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';

import SidePanelOrganizationTeamContactTypes from './SidePanelOrganizationTeamContactTypes';
import Translate from "@spordle/intl-elements";
import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../../components/table/EmptyLayout";
import TeamContactTypesAddModal from "./TeamContactTypesAddModal";
import CardSectionTitle from "../../../../../components/CardSectionTitle";


// Contexts
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import I18nHelperContextProvider, { I18nHelperContext } from '../../../../../helpers/i18nHelper';
import withContexts from "../../../../../helpers/withContexts";
import { I18nContext } from "../../../../../contexts/I18nContext";


class OrganizationTeamContactTypes extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            addContactTypeModalOpen: false,
            isFederation: this.props.OrganizationContext.organisation_id === this.props.IdentityRolesContext.federation.organisation_id,
        }
    }

    toggleAddContactType = () => {
        this.setState((prevState) => ({ addContactTypeModalOpen: !prevState.addContactTypeModalOpen }));
    }

    render(){
        return (
            <I18nHelperContextProvider fields={[ 'name' ]}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <div>
                            <SpordlePanelTable
                                allowOutsideClick
                                sidePanel={(props) => <SidePanelOrganizationTeamContactTypes {...props} tableRef={this.contactTypesTableRef} i18n={i18nHelper} isFederation={this.state.isFederation} />}
                                dataIndex='team_contact_type_id'
                                table={(panelProps) => {
                                    return (
                                        <Card body className='card-shadow'>
                                            <CardSectionTitle title='settings.tabs.teamContactTypes' />
                                            <SpordleTableProvider
                                                id='OrganizationTeamContactTypesTable'
                                                tableHover clickable striped
                                                bordered
                                                ref={(r) => { this.contactTypesTableRef = r; panelProps.spordleTableRef(r); }}
                                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                                dataIndex='team_contact_type_id'
                                                searchKeys={[
                                                    `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                    'name',
                                                ]}
                                                columns={[
                                                    ...i18nHelper.getTableColumns('name'),
                                                    {
                                                        label: <Translate id='organization.settings.organizationTeamContactTypes.table.active' />,
                                                        key: "active",
                                                        mobile: true,
                                                        sortable: true,
                                                    },
                                                ]}
                                                onColumnClick={(e, contactType) => {
                                                    switch (e.button){
                                                        case 0: // Left mouse button
                                                            panelProps.onSingleSelectChange(contactType);
                                                            break;
                                                    }
                                                }}
                                                desktopWhen='md'
                                                emptyLayout={<EmptyLayout />}
                                                renderRow={(columnKey, contactType, spordleTable) => {
                                                    switch (columnKey){
                                                        case 'active':
                                                            return (
                                                                <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                    {contactType.active == '1' ?
                                                                        <i className="text-primary mdi mdi-check" />
                                                                        :
                                                                        <i className="text-danger mdi mdi-close" />
                                                                    }
                                                                </div>
                                                            )
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                rowIsHighlighted={(contactType) => !!contactType.checked}
                                                loadData={(from, _filterData, spordleTable) => {
                                                    switch (from){
                                                        case 'REFRESH':
                                                            spordleTable.setLoading()
                                                        case 'CDM':
                                                            return this.props.TeamsContext.getTeamContactTypes({ organisation_id: this.props.OrganizationContext.organisation_id })
                                                                .then((data) => {
                                                                    return data;
                                                                });
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            >
                                                <TopSection toggleAddContactType={this.toggleAddContactType} panelProps={panelProps} addContactTypeModalOpen={this.state.addContactTypeModalOpen} />
                                                <SpordleTableView />
                                            </SpordleTableProvider>
                                        </Card>
                                    )
                                }}
                            />
                        </div>
                    )}
                </I18nHelperContext.Consumer>
            </I18nHelperContextProvider>
        )
    }
}

const TopSection = (props) => {
    return (
        <div className="mb-2">
            <div className='d-flex justify-content-between'>
                <SpordleTableProvider.SearchInput />
                <div className='d-flex ml-auto text-right'>
                    <SpordleTableProvider.Refresh />
                    <Button color='primary' className='ml-2' onClick={props.toggleAddContactType}><i className='mdi mdi-plus' /> <Translate id='misc.add' /></Button>
                    <TeamContactTypesAddModal addContactTypeModalOpen={props.addContactTypeModalOpen} toggleAddContactType={props.toggleAddContactType} />
                </div>
            </div>
        </div>
    )
}

export default withContexts(TeamsContext, OrganizationContext, IdentityRolesContext, I18nContext)(OrganizationTeamContactTypes);