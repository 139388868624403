export default{
    // exchange
    "clinics.profile.attendees.addModal.exchange.categories"                                : "Catégories",
    "clinics.profile.attendees.addModal.exchange.qualifications"                            : "Qualifications",
    "clinics.profile.attendees.addModal.exchange.errors.3420"                               : "Le membre a déjà participé au stage actuel",
    "clinics.profile.attendees.addModal.exchange.currentClinic"                             : "Stage actuel",
    "clinics.profile.attendees.addModal.exchange.soldOut"                                   : "Vendu",
    // waiting list
    "clinics.profile.attendees.addModal.waitingList.title"                                  : "Le stage est actuellement plein",
    "clinics.profile.attendees.addModal.waitingList.text"                                   : "Cliquez sur {confirm} pour inscrire le membre à la liste d'attente.",
    // waiting list confirmation
    "clinics.profile.attendees.addModal.waitingListConfirmation.number"                     : "Numéro de liste d'attente",
    "clinics.profile.attendees.addModal.waitingListConfirmation.goToWaitlist"               : "Voir la liste d'attente",
    // waiting list return
    "clinics.profile.attendees.addModal.waitingListReturn.title"                            : "Complétez l'inscription",
    "clinics.profile.attendees.addModal.waitingListReturn.text"                             : "Complétez l'inscription pour {firstName} {lastName}.",
    "clinics.profile.attendees.addModal.waitingListReturn.manual"                           : "Inscription manuelle",
    "clinics.profile.attendees.addModal.waitingListReturn.manual.desc"                      : "Complétez l'inscription manuellement pour ce membre.",
    "clinics.profile.attendees.addModal.waitingListReturn.identity"                         : "Sélectionner une identité",
    "clinics.profile.attendees.addModal.waitingListReturn.identity.desc"                    : "Envoyer un courriel à un utilisateur pour compléter l'inscription.",
    // waiting list return confirmation
    "clinics.profile.attendees.addModal.waitingListReturnConfirmation.title"                : "Vous avez presque terminé!",
    "clinics.profile.attendees.addModal.waitingListReturnConfirmation.text"                 : "Cliquez sur {confirm} pour envoyer un courriel à {name}",
    "clinics.profile.attendees.addModal.waitingListReturnConfirmation.confirmation"         : "Félicitations!",
    "clinics.profile.attendees.addModal.waitingListReturnConfirmation.confirmationMessage"  : "Le courriel a été envoyé. Le membre est dorénavant en attente du client pour que ce dernier complète l'inscription.",
    // clinic infos
    "clinics.profile.attendees.addModal.prerequisitesRequired"                              : "Préalables",
    "clinics.profile.attendees.addModal.prerequisites.qualification"                        : "Préalables de la qualification",
    "clinics.profile.attendees.addModal.prerequisites.organization"                         : "Préalables de l'organisation",
    "clinics.profile.attendees.addModal.prerequisites.none"                                 : "Aucun préalable",
    "clinics.profile.attendees.addModal.prerequisites.restrictions.all"                     : "Tous obligatoires",
    "clinics.profile.attendees.addModal.prerequisites.restrictions.one"                     : "Un seul obligatoire",
    "clinics.profile.attendees.addModal.tax"                                                : "taxe",
    "clinics.profile.attendees.addModal.fees"                                               : "frais",
    "clinics.profile.attendees.addModal.priceOneParticipant"                                : "Prix pour un participant",
    "clinics.profile.attendees.addModal.until"                                              : "Jusqu'au",
    "clinics.profile.attendees.addModal.form.registration.date"                             : "Date d'inscription",
    "clinics.profile.attendees.addModal.form.registration.date.before"                      : "La date doit être avant aujourd'hui",
    "clinics.profile.attendees.addModal.form.registration.time"                             : "Heure d'inscription",
    "clinics.profile.attendees.addModal.form.registration.time.before"                      : "Le temps doit être avant maintenant",
    // member select
    "clinics.profile.attendees.addModal.memberSelect.title"                                 : "Inscription",
    "clinics.profile.attendees.addModal.memberSelect.or"                                    : "Ou",
    "clinics.profile.attendees.addModal.memberSelect.memberNumber"                          : "{unique_identifier}",
    "clinics.profile.attendees.addModal.memberSelect.memberNumber.hint"                     : "La vérification ignorera les autres champs si celui-ci est spécifié",
    "clinics.profile.attendees.addModal.memberSelect.noData"                                : "Aucun résultat",
    "clinics.profile.attendees.addModal.memberSelect.select"                                : "Sélectionner",
    // warning messages
    "clinics.profile.attendees.addModal.warnings.clinic_inactive"                           : "Le stage est inactif",
    "clinics.profile.attendees.addModal.warnings.clinic_must_have_period"                   : "Le stage doit être lié à une période",
    "clinics.profile.attendees.addModal.warnings.clinic_period_doesnt_exist_or_inactive"    : "La période du stage est inexistante ou invalide",
    "clinics.profile.attendees.addModal.warnings.invalid_clinic_status"                     : "Le statut du stage est invalide",
    "clinics.profile.attendees.addModal.warnings.clinic_already_started"                    : "Le stage est déjà commencé",
    "clinics.profile.attendees.addModal.warnings.mandatory_fees_needed_for_clinic"          : "Des articles obligatoires sont demandés pour le stage",
    "clinics.profile.attendees.addModal.warnings.clinic_soldout"                            : "Aucune place disponible restante pour le stage",
    "clinics.profile.attendees.addModal.warnings.clinic_must_have_terms_and_conditions"     : "Le stage doit avoir des conditions de paiement",
    "clinics.profile.attendees.addModal.warnings.member_has_already_clinic_qualification"   : "La qualification de ce stage est déjà obtenu pour ce membre",
    "clinics.profile.attendees.addModal.warnings.inactive_member"                           : "Ce membre est inactif",
    "clinics.profile.attendees.addModal.warnings.temporary_member"                          : "Ce membre est un membre temporaire",
    "clinics.profile.attendees.addModal.warnings.clinic_unavailable_in_member_organisation" : "Ce stage n'est pas disponible pour l'organisation de ce membre",
    "clinics.profile.attendees.addModal.warnings.member_need_background_check"              : "Ce membre nécessite une vérification d'antécédents",
    "clinics.profile.attendees.addModal.warnings.link.member_need_crc"                      : "Gérer les vérifications d'antécédents",
    "clinics.profile.attendees.addModal.warnings.member_had_already_purchased_this_clinic"  : "Ce membre a déjà acheté ce stage",
    "clinics.profile.attendees.addModal.warnings.missing_birthdate"                         : "Ce membre n'a pas de date de naissance",
    "clinics.profile.attendees.addModal.warnings.member_doesnt_respect_min_age"             : "Ce membre ne respecte pas l'âge minimum du stage",
    "clinics.profile.attendees.addModal.warnings.member_doesnt_respect_max_age"             : "Ce membre ne respecte pas l'âge maximum du stage",
    "clinics.profile.attendees.addModal.warnings.need_all_prerequisites"                    : "Ce membre doit posséder tous les préalables du stage",
    "clinics.profile.attendees.addModal.warnings.member_had_an_unwanted_qualification"      : "Ce membre avait une qualification non-voulue",
    "clinics.profile.attendees.addModal.warnings.member_need_prerequisite"                  : "Ce membre doit posséder le ou les préalables nécessaires pour le stage",
}