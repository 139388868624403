import { FormikSelect } from '@spordle/formik-elements';
import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useState, useContext } from 'react';
import { Alert, Button, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { array, object, string } from "yup";
import AnalyticsModal from '../../../../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import CollapsibleCard from '../../../../../../../components/collapsibleCard/CollapsibleCard';
import CustomAnimatedIcon from '../../../../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import Required from '../../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import { ClaimStatus, CLOSED_REASONS, FINAL_STATUSES } from '../../../DiscriminationHelper';
import OutcomeCard from '../tabs/OutcomeCard';

/**
 * @description Modal that updates the status of a discrimination claim
 * @param {Object} props
 * @param {function} props.toggle
 * @param {boolean} props.isOpen
 * @param {object[]} props.status List of all available status
 * @param {object[]} props.selectedRows SpordleTablePanel prop selectedRows
 * @param {function} props.refreshData Utility function to call to update spordle table
 * @param {object[]} props.statusWithReasons status that need a closed_reason to be sent
 * @returns {JSX.Element}
 */
const UpdateStatusModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName="discriminationUpdateStatusModal">
            <UpdateStatusModalInner {...props} />
        </AnalyticsModal>
    )
}

const UpdateStatusModalInner = ({ toggle, status, selectedRows, refreshData, statusWithReasons, isAutomatic }) => {
    const [ selectedStatus, setSelectedStatus ] = useState(null);
    const { partiallyUpdateDiscrimination } = useContext(OrganizationContext);
    const [ step, setStep ] = useState(isAutomatic ? 1 : 0);
    const finalStatus = (status || []).find(({ system }) => FINAL_STATUSES.includes(system));

    return (
        <Formik
            initialValues={{
                discrimination_status_id: selectedRows[0].discrimination_status?.discrimination_status_id || '',
                closed_reason: selectedRows[0].closed_reason ? selectedRows[0].closed_reason.split(',') : [],
            }}
            validationSchema={object().shape({
                discrimination_status_id: string().required(<Translate id='discrimination.form.claimStatus.required' />),
                closed_reason: array().when('discrimination_status_id', (data, schema) => {
                    return statusWithReasons?.includes(data) ? schema.min(1, <Translate id='discrimination.form.closedReasons.min' />) : schema;
                }),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                const apiVal = {
                    discrimination_status_id: values.discrimination_status_id,
                };

                if(statusWithReasons?.includes(values.discrimination_status_id)){
                    apiVal.closed_reason = values.closed_reason.join(',');
                }

                return partiallyUpdateDiscrimination(selectedRows[0].discrimination_id, apiVal)
                    .then(() => {
                        refreshData();
                        setStep(3);
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            setSubmitting(false);
                        }
                    })
            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={formik.isSubmitting}>
                    <Collapse isOpen={step == 3}>
                        <ModalBody className="text-center">
                            <CustomAnimatedIcon
                                className="text-success"
                                icon="checkmark"
                                withCircle
                                size="60"
                                strokeWidth="8px"
                            />
                            <div className="h4 font-bold mt-2"><Translate id="misc.success" />!</div>
                            <p><Translate id="discrimination.sidePanel.editStatusModal.success" /></p>
                            <Button disabled={formik.isSubmitting} onClick={toggle} type="button" color="primary" outline><Translate id="misc.close" /></Button>
                        </ModalBody>
                    </Collapse>
                    <Collapse isOpen={step == 1}>
                        <ModalHeader toggle={toggle}><Translate id="discrimination.sidePanel.editStatusModal.allComplete.title" /></ModalHeader>
                        <ModalBody>
                            <p><Translate id="discrimination.sidePanel.editStatusModal.allComplete.text" /></p>
                            <p><Translate id="discrimination.sidePanel.editStatusModal.allComplete.question" values={{ status: <ClaimStatus discriminationStatus={finalStatus} tag="b" /> }} /></p>
                            <CollapsibleCard
                                initOpen={(selectedRows[0].maltreatment_outcomes || [])?.length < 3}
                                className="mb-3"
                                title={
                                    <div className="h5 mb-0 font-bold d-flex align-items-center justify-content-between">
                                        <Translate id='discrimination.label.outcomes' /> ({(selectedRows[0].maltreatment_outcomes || [])?.length})
                                    </div>
                                }
                            >
                                {(selectedRows[0].maltreatment_outcomes || []).map((maltreatment_outcome) => (
                                    <OutcomeCard
                                        key={maltreatment_outcome.maltreatment_outcome_id}
                                        maltreatment_id={selectedRows[0].discrimination_id}
                                        maltreatment_outcome={maltreatment_outcome}
                                        readOnly
                                    />
                                ))}

                            </CollapsibleCard>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                type='button'
                                color="primary"
                                disabled={formik.isSubmitting}
                                onClick={() => {
                                    formik.setFieldValue('discrimination_status_id', finalStatus.discrimination_status_id);
                                    formik.handleSubmit();
                                }}
                                className="mr-2"
                            >
                                <Translate id="discrimination.sidePanel.editStatusModal.allComplete.update" />
                            </Button>
                            <Button
                                type="button"
                                color="primary"
                                outline
                                onClick={toggle}
                            >
                                <Translate id="misc.cancel" />
                            </Button>
                        </ModalFooter>
                    </Collapse>
                    <Collapse isOpen={step == 0}>
                        <Form>
                            <ModalHeader toggle={toggle}>
                                <Translate id='discrimination.sidePanel.editStatusModal.title' />
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label className="text-muted" for="discrimination_status_id"><Translate id='discrimination.label.status' /> <Required /></Label>
                                    <FormikSelect
                                        key={status}
                                        name="discrimination_status_id"
                                        id="discrimination_status_id"
                                        renderOption={(opt) => {
                                            if(opt){
                                                return (
                                                    <DisplayI18n
                                                        field="name"
                                                        defaultValue={opt.option.label}
                                                        i18n={opt.option.i18n}
                                                    />
                                                )
                                            }
                                        }}
                                        onOptionSelected={([ val ], select) => {
                                            const status = select.getSpordleTable().getData().find((option) => option.value == val);
                                            setSelectedStatus(status?.ressource);
                                        }}
                                        defaultData={status?.map((claimStatus) => ({
                                            value: claimStatus.discrimination_status_id,
                                            label: claimStatus.name,
                                            i18n: claimStatus.i18n,
                                            ressource: claimStatus,
                                        })) || []}
                                        loadingStatus={status ? 'success' : 'loading'}
                                    />
                                </FormGroup>
                                <Collapse isOpen={statusWithReasons?.includes(formik.values?.discrimination_status_id)}>
                                    <FormGroup>
                                        <Label className="text-muted" for="closed_reason"><Translate id='discrimination.label.closedReason' /> <Required /></Label>
                                        <FormikSelect
                                            multi
                                            id="closed_reason"
                                            textWhenSetting={{
                                                count: 1,
                                                label: 'discrimination.addModal.form.select.closedReasonsSelected',
                                            }}
                                            name="closed_reason"
                                            renderOption={(option) => (
                                                <Translate id={option.option.label} />
                                            )}
                                            defaultData={CLOSED_REASONS.map((reason) => ({
                                                value: reason,
                                                label: `discrimination.global.closedReasons.${reason}`,
                                            }))}
                                            loadingStatus='success'
                                        />
                                    </FormGroup>
                                </Collapse>
                                <Collapse isOpen={FINAL_STATUSES.includes(selectedStatus?.system)}>
                                    <Alert color="primary" className={stringBuilder({ "mb-0": !formik.status })}>
                                        <i className="mdi mdi-information-outline text-primary mr-1" />
                                        <Translate id="discrimination.sidePanel.editStatusModal.final" />
                                    </Alert>
                                </Collapse>
                                {formik.status &&
                                    <Collapse isOpen appear>
                                        <Alert color="danger">
                                            {formik.status}
                                        </Alert>
                                    </Collapse>
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button disabled={formik.isSubmitting} type="submit" color="primary"><Translate id="misc.confirm" /></Button>
                                <Button disabled={formik.isSubmitting} type="button" color="primary" onClick={toggle} outline><Translate id="misc.cancel" /></Button>
                            </ModalFooter>
                        </Form>
                    </Collapse>
                </OverlayLoader>
            )}
        </Formik>
    )
}

export default UpdateStatusModal;