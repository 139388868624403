export default{
    "dashboard.widgets.remove" : "You are about to remove",
    "dashboard.widgets.view"   : "View",

    "dashboard.btn.changeLayout" : "Change Layout",

    "dashboard.addModal.title"             : "Add a widget",
    "dashboard.addModal.dataType.required" : "Please select a widget type",
    "dashboard.addModal.widget.required"   : "Please select a widget",
    "dashboard.addModal.widget.preview"    : "Widget preview",

    "dashboard.widget.welcome.title"         : "Welcome!",
    "dashboard.widget.welcome.subtitle"      : "We are happy to welcome you to your new dashboard",
    "dashboard.widget.welcome.text.1"        : "Soon, you'll be able to:",
    "dashboard.widget.welcome.text.2"        : "Add new widgets",
    "dashboard.widget.welcome.text.3"        : "Customize your dashboard",
    "dashboard.widget.welcome.btn.tour"      : "Show me around",
    "dashboard.widget.welcome.btn.learnMore" : "Learn More",

    "dashboard.widget.preview.supportRequest.empty.title"    : "No support request",
    "dashboard.widget.preview.supportRequest.empty.subtitle" : "No support request has been created with your account.",
    "dashboard.widget.preview.supportRequest.empty.btn"      : "Create a support request",
}