import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import { FormikCheckedButton, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import SpordleSelect from '@spordle/spordle-select';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { fire } from '@spordle/toasts';
import { Button, Card, Col, CustomInput, Fade, ListGroup, ListGroupItem, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { object, string } from 'yup';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import FormikTiny from '../../../../components/formik/FormikTiny';
import { AuthContext } from '../../../../contexts/contexts';
import { I18nContext } from '../../../../contexts/I18nContext';
import createRandomDate from '../../../../helpers/dateHelper';

class ClinicAttendeeRefund extends React.Component{
    state = {
        refundTotal: 0,
        refundPerItem: {},
        headerChecked: false,
        confirm: false,
    }

    calculateRefundAmount = (id, value) => {
        this.setState((prevState) => {
            prevState.refundPerItem[id] = value;
            let newTotal = 0;
            for(const itemIndex in prevState.refundPerItem){
                newTotal += prevState.refundPerItem[itemIndex];
            }
            return { refundPerItem: prevState.refundPerItem, refundTotal: newTotal, headerChecked: this.tableRef.getData().every((row) => row.checked) };
        });
    }

    selectAll = () => {
        this.setState((prevState) => {
            this.tableRef.getData().forEach((refundItem) => {
                refundItem.checked = true;
                prevState.refundPerItem[refundItem.id] = refundItem.totalPrice - refundItem.ammountRefunded;
            })
            return { refundPerItem: prevState.refundPerItem, headerChecked: true };
        });
    }

    unselectAll = () => {
        this.setState((prevState) => {
            this.tableRef.getData().forEach((refundItem) => {
                refundItem.checked = false;
                prevState.refundPerItem[refundItem.id] = 0;
            })
            return { refundPerItem: prevState.refundPerItem, headerChecked: false };
        });
    }

    toggleModal = () => {
        this.setState((prevState) => ({ confirm: !prevState.confirm }))
    }

    render(){
        return (
            <>
                <AnalyticsModal isOpen={this.state.confirm} toggle={this.toggleModal} analyticsName='ClinicAttendeeRefund'>
                    <ModalHeader toggle={this.toggleModal}>
                        Refund Details
                    </ModalHeader>
                    <ModalBody>
                        <div className='mb-4'>
                            <div className='pb-1'>
                                <div className="h5 font-bold mb-0">Refund details</div>
                            </div>
                            <ListGroup className='mb-2' flush>
                                {this.tableRef?.getData().map((refundItem, index) => {
                                    if(refundItem.checked && this.state.refundPerItem[refundItem.id] !== 0){
                                        return (
                                            <ListGroupItem key={refundItem.id} className='border-top'>
                                                <Row>
                                                    <Col md='8'>
                                                        <div className='font-medium'>{refundItem.barcode}</div>
                                                        <div className='text-muted small'>{refundItem.method}</div>
                                                    </Col>
                                                    <Col md='4'>
                                                        <div className='text-right'>Amount: <span className='font-medium'><CurrencyFormat value={this.state.refundPerItem[refundItem.id]} /></span></div>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                        )
                                    }
                                    return null;
                                })}
                            </ListGroup>
                            <div className='text-right'>
                                Total amount to refund: <span className='font-medium'><CurrencyFormat value={this.state.refundTotal} /></span>
                            </div>
                        </div>
                        <div>
                            <div className='pb-1'>
                                <div className='h5 font-bold mb-0'>Send refund confirmation to:</div>
                            </div>
                            <ListGroup flush>
                                {/* Client email */}
                                <ListGroupItem className='border-top'>
                                    bob.woods@gmail.com
                                </ListGroupItem>
                                {/* Others */}
                                {this.formikRef?.values.extraEmails.split(';').map((email, index) => email && <ListGroupItem key={email}>{email}</ListGroupItem>)}
                                {/* This account */}
                                {this.formikRef?.values.emailMyself &&
                                    <ListGroupItem>
                                        Me
                                        <AuthContext.Consumer>
                                            {({ account }) => <span className='font-medium'> ({account.email})</span>}
                                        </AuthContext.Consumer>
                                    </ListGroupItem>
                                }
                            </ListGroup>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color='primary' onClick={this.toggleModal}>Cancel</Button>
                        <Button
                            color='primary' onClick={() => {
                                this.toggleModal();
                                fire({ theme: "warning", msg: 'Refund confirmed', skipMsgTranslate: true });

                            }}
                        >Confirm
                        </Button>
                    </ModalFooter>
                </AnalyticsModal>
                <Fade in>
                    <Card body>
                        <div className='mb-3'>
                            <div className="d-flex align-items-center">
                                <div className="h4 mb-0 pb-1 font-bold card-title">Refund History</div>
                            </div>
                            <RefundHistory />
                        </div>
                        <div className='mb-4'>
                            <div className="d-flex align-items-center">
                                <div className="h4 mb-0 pb-1 font-bold card-title">Items To Refund</div>
                            </div>
                            <SpordleTableProvider
                                id='attendee-refund'
                                ref={(r) => this.tableRef = r}
                                desktopWhen hasFooter striped
                                bordered tableHover
                                columns={[
                                    {
                                        key: 'select',
                                        // Dynamic label needs to be a component
                                        label: () => (
                                            <CustomInput
                                                type='checkbox' id='check-uncheck-all-attendee-refund-modal' checked={this.state.headerChecked}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    // Select all
                                                    if(!this.state.headerChecked){
                                                        this.selectAll();
                                                    }else{ // Unselect all
                                                        this.unselectAll();
                                                    }
                                                }}
                                            />
                                        ),
                                        className: 'text-center',
                                        dataClassName: 'text-center',
                                    },
                                    {
                                        key: 'payment',
                                        label: 'Payment',
                                    },
                                    {
                                        key: 'price',
                                        label: 'Price',
                                        className: 'text-left',
                                        dataClassName: 'text-right',
                                        hasTotal: true,
                                    },
                                    {
                                        key: 'taxes',
                                        label: 'GST/QST',
                                        className: 'text-left',
                                        dataClassName: 'text-right',
                                        hasTotal: true,
                                    },
                                    {
                                        key: 'fees',
                                        label: 'Fees',
                                        className: 'text-left',
                                        dataClassName: 'text-right',
                                        hasTotal: true,
                                    },
                                    {
                                        key: 'totalPrice',
                                        label: 'Total Price',
                                        className: 'text-left',
                                        dataClassName: 'text-right',
                                        hasTotal: true,
                                    },
                                    {
                                        key: 'refundAmount',
                                        label: 'Total Refunded',
                                        className: 'text-left',
                                        dataClassName: 'text-right',
                                        hasTotal: true,
                                    },
                                    {
                                        key: 'amountToRefund',
                                        label: 'Amount To Refund',
                                        className: 'text-center',
                                        dataClassName: 'text-center',
                                        hasTotal: true,
                                    },
                                ]}
                                loadingStatus='success'
                                defaultSorting='+payment'
                                defaultData={[
                                    {
                                        id: '1',
                                        totalPrice: 48.74,
                                        price: 40.00,
                                        gst: 4.52,
                                        qst: 2.22,
                                        fees: 2.00,
                                        ammountRefunded: 20.00,
                                        paymentDate: createRandomDate(),
                                        barcode: '#5064033',
                                        item: 'Session 1 - 14h',
                                        method: 'Paysafe',
                                        checked: false,
                                        footer: {
                                            totalPrice: 48.74,
                                            refundAmount: 20.00,
                                            fees: 2,
                                            taxes: 4.52 + 2.22, //gst + qst
                                            price: 40,
                                        },
                                    },
                                    {
                                        id: '2',
                                        totalPrice: 40.85,
                                        price: 35.00,
                                        gst: 3.28,
                                        qst: 1.57,
                                        fees: 1.00,
                                        ammountRefunded: 0.00,
                                        paymentDate: createRandomDate(),
                                        barcode: '#7455056',
                                        item: 'Session 1 - 13h',
                                        method: 'Paysafe',
                                        checked: false,
                                        footer: {
                                            totalPrice: 40.85,
                                            refundAmount: 0,
                                            fees: 1,
                                            taxes: 3.28 + 1.57, //gst + qst
                                            price: 25,
                                        },
                                    },
                                    {
                                        id: '3',
                                        totalPrice: 28.12,
                                        price: 26.00,
                                        gst: 1.06,
                                        qst: 1.06,
                                        fees: 0,
                                        ammountRefunded: 5.00,
                                        paymentDate: createRandomDate(),
                                        barcode: '#2439639',
                                        item: 'Session 1 - 14h',
                                        method: 'Cash',
                                        checked: false,
                                        footer: {
                                            totalPrice: 28.12,
                                            refundAmount: 5,
                                            fees: 0,
                                            taxes: 1.06 + 1.06, //gst + qst
                                            price: 26,
                                        },
                                    },
                                    {
                                        id: '4',
                                        totalPrice: 42.26,
                                        price: 38.00,
                                        gst: 2.38,
                                        qst: 1.88,
                                        fees: 0,
                                        ammountRefunded: 15.00,
                                        paymentDate: createRandomDate(),
                                        barcode: '#2226255',
                                        item: 'Session 1 - 15h',
                                        method: 'Cheque',
                                        checked: false,
                                        footer: {
                                            totalPrice: 42.26,
                                            refundAmount: 15,
                                            fees: 0,
                                            taxes: 2.38 + 1.88, //gst + qst
                                            price: 38,
                                        },
                                    },
                                ]}
                                renderRow={(columnName, refundItem, _isMobile) => {
                                    switch (columnName){
                                        case 'refundAmount':
                                            return <CurrencyFormat value={refundItem.ammountRefunded} />;
                                        case 'price':
                                            return <CurrencyFormat value={refundItem.price} />;
                                        case 'fees':
                                            return <CurrencyFormat value={refundItem.fees} />;
                                        case 'taxes':
                                            return <CurrencyFormat value={refundItem.gst + refundItem.qst} />;
                                        case 'select':
                                            return (
                                                <CustomInput
                                                    type='checkbox' checked={!!this.state.refundPerItem[refundItem.id]} id={'attendee-refund-' + refundItem.id}
                                                    onChange={(e) => {
                                                        e.stopPropagation();
                                                        refundItem.checked = e.target.checked;
                                                        if(refundItem.checked){
                                                            this.calculateRefundAmount(refundItem.id, refundItem.totalPrice - refundItem.ammountRefunded);
                                                        }else{
                                                            this.calculateRefundAmount(refundItem.id, 0);
                                                        }
                                                    }}
                                                />
                                            )
                                        case 'payment':
                                            return (
                                                <div>
                                                    <div className='font-medium'><DateFormat value={refundItem.paymentDate} format={DateFormat.formats.longMonthDayYear} /></div>
                                                    <div className='font-light'>{refundItem.barcode}</div>
                                                    <div className='text-muted small'>{refundItem.method}</div>
                                                </div>
                                            );
                                        case 'totalPrice':
                                            return <CurrencyFormat value={refundItem.totalPrice} />;
                                        case 'amountToRefund':
                                            return (
                                                <I18nContext.Consumer>
                                                    {({ getGenericLocale }) => (
                                                        <NumberFormat
                                                            className='form-control text-center d-inline-block w-50'
                                                            ///////////// Currency formatting fr vs en ///////////////
                                                            prefix={getGenericLocale() === 'fr' ? undefined : '$'}
                                                            suffix={getGenericLocale() === 'fr' ? '$' : undefined}
                                                            decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                            //////////////////////////////////////////////////////////
                                                            decimalScale={2} fixedDecimalScale
                                                            allowLeadingZeros={false} allowNegative={false}
                                                            defaultValue={0}
                                                            value={this.state.refundPerItem[refundItem.id] ?? 0}
                                                            onValueChange={(e) => {
                                                                refundItem.checked = e.floatValue !== 0;
                                                                this.calculateRefundAmount(refundItem.id, e.floatValue);
                                                            }}
                                                            isAllowed={(values) => {
                                                                const { floatValue } = values;
                                                                // Between 0 and amount left to refund
                                                                return floatValue >= 0 && floatValue <= refundItem.totalPrice - refundItem.ammountRefunded
                                                            }}
                                                        />
                                                    )}
                                                </I18nContext.Consumer>
                                            );
                                        default:
                                            break;
                                    }
                                }}
                                renderFooter={(columnKey, columnTotal) => {
                                    switch (columnKey){
                                        case 'amountToRefund':
                                            return (
                                                <div className='text-center font-medium'>
                                                    <CurrencyFormat value={this.state.refundTotal} />
                                                </div>
                                            )
                                        case 'totalPrice':
                                        case 'refundAmount':
                                        case 'price':
                                        case 'fees':
                                        case 'taxes':
                                            return <div className='text-right font-medium'><CurrencyFormat value={columnTotal} /></div>

                                        default:
                                            break;
                                    }
                                }}
                                columnSorting={(columnKey, orderingBy, refundItemA, refundItemB) => {
                                    if(columnKey === 'payment'){
                                        if(refundItemA.paymentDate === refundItemB.paymentDate){
                                            return 0;
                                        }

                                        if(orderingBy === 'ASC'){
                                            return refundItemA.paymentDate > refundItemB.paymentDate ? 1 : -1
                                        } // order is 'DESC'
                                        return refundItemA.paymentDate > refundItemB.paymentDate ? -1 : 1

                                    }
                                }}
                            >
                                <SpordleTableView />
                            </SpordleTableProvider>
                        </div>
                        <Formik
                            initialValues={{
                                tinyEmail: "Hi dear customer,<br/><br/>This email is to tell you that you have been refunded!<br/><br/>Thank you and have a good day!",
                                reason: 'cancelled',
                                extraEmails: 'test.email@spordle.com;',
                                emailMyself: true,
                            }}
                            validationSchema={object().shape({
                                tinyEmail: string().required('Please provide some content to the email'),
                                reason: string().required('You must select a reason why it is being refunded'),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                                this.setState(() => ({ confirm: true }))
                            }}
                            innerRef={(r) => this.formikRef = r}
                        >
                            {(formik) => (
                                <Form id='attendee-modal-refund-form'>
                                    <div className='mb-4'>
                                        <div className="mb-3">
                                            <div className="d-flex align-items-center border-bottom">
                                                <div className="h4 mb-0 pb-1 font-bold card-title">Refund Info</div>
                                            </div>
                                        </div>
                                        <div className='w-50'>
                                            <FormikSelect name='reason' id='attendee-modal-form-reason' label='Refund reason:' translateLabel={false} placeholder='Select a reason'>
                                                <SpordleSelect.Option id='cancelled' label='Clinic cancelled' value='cancelled' selected />
                                                <SpordleSelect.Option id='other' label='Other' value='other' />
                                            </FormikSelect>
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-1">
                                            <div className="d-flex align-items-center">
                                                <div className="h4 mb-0 font-bold card-title">Email</div>
                                            </div>
                                        </div>
                                        <FormikTiny name='tinyEmail' />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-3">
                                            <div className="d-flex align-items-center border-bottom">
                                                <div className="h4 mb-0 pb-1 font-bold card-title">Send a copy to...</div>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col md='12' className='d-flex align-items-center mb-2'>
                                                <FormikCheckedButton
                                                    id='emailMyself' name='emailMyself' translateLabel={false}
                                                    label={
                                                        <div>
                                                                This account:
                                                            <AuthContext.Consumer>
                                                                {({ account }) => <span className='font-medium'> {account.email}</span>}
                                                            </AuthContext.Consumer>
                                                        </div>
                                                    }
                                                />
                                            </Col>
                                            <Col md='12'>
                                                <FormikInputText name='extraEmails' label='Others' helper='Split the emails with a semi-colon i.e. ;' translateHelper={false} translateLabel={false} />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <Link component={Button} type='button' outline color='primary' className='mr-2' to='attendees'>Cancel</Link>
                                        <Button type='submit' color='primary' disabled={this.state.refundTotal <= 0}>Refund</Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </Fade>
            </>
        );
    }
}

const RefundHistory = () => {
    useEffect(() => {
        // API call here to fetch history data
        // setHistory()
    }, []);

    return (
        <SpordleTableProvider
            id='refund-history'
            desktopWhen hasFooter bordered
            striped tableHover
            dataIndex='refundId'
            columns={[
                {
                    key: 'refundDate',
                    label: 'Date',
                },
                {
                    key: 'refundId',
                    label: 'Refund #',
                },
                {
                    key: 'reason',
                    label: 'Reason',
                },
                {
                    key: 'refundAmount',
                    label: 'Amount Refunded',
                    className: 'text-right',
                    dataClassName: 'text-right',
                    hasTotal: true,
                },
            ]}
            loadingStatus='success'
            defaultSorting='+payment'
            defaultData={[
                {
                    refundDate: createRandomDate(),
                    refundAmount: 20.00,
                    refundId: 5064033,
                    item: 'Clinic 3 - 16h',
                    reason: 'Clinic cancelled',
                    method: 'Paysafe',
                    footer: {
                        refundAmount: 20.00,
                    },
                },
                {
                    refundDate: createRandomDate(),
                    refundAmount: 5,
                    refundId: 2439639,
                    item: 'Clinic 3 - 10h',
                    reason: 'Clinic cancelled',
                    method: 'Cash',
                    footer: {
                        refundAmount: 5,
                    },
                },
                {
                    refundDate: createRandomDate(),
                    refundAmount: 15,
                    refundId: 2226255,
                    item: 'Clinic 3 - 11h',
                    reason: 'Clinic cancelled',
                    method: 'Cheque',
                    footer: {
                        refundAmount: 15.00,
                    },
                },
            ]}
            renderRow={(columnName, refundItem, _isMobile) => {
                switch (columnName){
                    case 'refundAmount':
                        return <CurrencyFormat value={refundItem.refundAmount} />;
                    case 'refundDate':
                        return <DateFormat value={refundItem.refundDate} format={DateFormat.formats.longMonthDayYear} />
                    default:
                        break;
                }
            }}
            renderFooter={(columnKey, columnTotal) => {
                switch (columnKey){
                    case 'refundAmount':
                        return <div className='text-right font-medium'><CurrencyFormat value={columnTotal} /></div>

                    default:
                        break;
                }
            }}
            columnSorting={(columnKey, orderingBy, refundItemA, refundItemB) => {
                if(columnKey === 'payment'){
                    if(refundItemA.refundDate === refundItemB.refundDate){
                        return 0;
                    }

                    if(orderingBy === 'ASC'){
                        return refundItemA.refundDate > refundItemB.refundDate ? 1 : -1
                    } // order is 'DESC'
                    return refundItemA.refundDate > refundItemB.refundDate ? -1 : 1

                }
            }}
        >
            <SpordleTableView />
        </SpordleTableProvider>
    )
}

export default ClinicAttendeeRefund;