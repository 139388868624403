import { TeamsContext } from "../../../../../contexts/TeamsContext";
import { useContext } from "react";
import TeamHeaderCompetitiveOrg from "./TeamHeaderCompetitiveOrg";
import { isLeague, isTournament } from "../../../../../helpers/orgHelper";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { displayI18n } from "../../../../../helpers/i18nHelper";

const TeamHeaderLeagueTournaments = ({ refreshTeam }) => {
    const { cachedTeam } = useContext(TeamsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const [ leagues, tournaments ] = (cachedTeam.competition_teams || []).reduce((competitiveOrgs, compTeam) => {
        const competitionOrg = compTeam.competition.organisation;
        const orgCategory = competitionOrg.organisation_category?.category_system;
        const addTo = isLeague(orgCategory) ? 0 : isTournament(orgCategory) ? 1 : -1;

        const isAlreadyAdded = (index) => {
            return competitiveOrgs[index].some(({ competition_id }) => competition_id === compTeam.competition?.competition_id);
        }

        if(addTo !== -1 && !isAlreadyAdded(addTo)){
            competitiveOrgs[addTo].push({
                ...competitionOrg,
                team: {
                    ...compTeam,
                    team: cachedTeam,
                },
            });
        }

        return competitiveOrgs;
    }, [ [], [] ]);

    if((leagues.length + tournaments.length) === 0){
        return null;
    }

    const sortCompetitions = (competitions) => {
        const collator = new Intl.Collator(getGenericLocale(), { sensitivity: 'base' });

        return competitions.sort((a, b) => {
            const aStatus = a.team?.competition_team_status?.system;
            const bStatus = b.team?.competition_team_status?.system;

            if(aStatus === bStatus){
                const aName = displayI18n("name", a.i18n, a.organisation_name, getGenericLocale());
                const bName = displayI18n("name", b.i18n, b.organisation_name, getGenericLocale());
                return collator.compare(aName, bName);
            }

            if(aStatus === "APPROVED" || bStatus === "DECLINED"){
                return -1;
            }

            if(bStatus === "APPROVED" || aStatus === "DECLINED"){
                return 1;
            }

            return 0;
        })
    }

    return (
        <div className="d-flex flex-wrap mb-2">
            {leagues.length > 0 &&
                sortCompetitions(leagues).map((league) => (
                    <TeamHeaderCompetitiveOrg refreshTeam={refreshTeam} org={league} key={league.competition_id} />
                ))
            }
            {tournaments.length > 0 &&
                sortCompetitions(tournaments).map((tournament) => (
                    <TeamHeaderCompetitiveOrg refreshTeam={refreshTeam} org={tournament} key={tournament.competition_id} />
                ))
            }
        </div>
    );
}

export default TeamHeaderLeagueTournaments;