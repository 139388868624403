import Translate from '@spordle/intl-elements';
import { useContext, useRef, useState } from 'react';
import {
    Row,
    Col,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Alert
} from "reactstrap";
import { Form, Formik } from 'formik';
import { FormikDateTime, FormikInputText, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Required from '../../../../../components/formik/Required';
import { object, string, mixed } from 'yup';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import ClinicCategory from '../../../../clinics/ClinicCategory';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import moment from 'moment';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';

// contexts
import { I18nContext } from '../../../../../contexts/I18nContext';
import { PeriodsContext } from '../../../../../contexts/contexts';
import { QualificationCategoriesContext } from '../../../../../contexts/QualificationCategoriesContext';
import { QualificationsContext } from '../../../../../contexts/QualificationsContext';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { UtilsContext } from '../../../../../contexts/UtilsContext';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';

const MemberQualificationsAdd = ({ isOpen, toggle, refreshTable }) => {
    const i18nContext = useContext(I18nContext);
    const periodsContext = useContext(PeriodsContext);
    const organizationContext = useContext(OrganizationContext);
    const qualificationCategoriesContext = useContext(QualificationCategoriesContext);
    const qualificationsContext = useContext(QualificationsContext);
    const membersContext = useContext(MembersContext);
    const utilsContext = useContext(UtilsContext);
    const identityRolesContext = useContext(IdentityRolesContext)

    const formikRef = useRef(null)

    /**  @type {React.MutableRefObject<import('@spordle/spordle-select/dist/components/SpordleSelect').default>} */
    const qualificationsSelectRef = useRef(null)

    const [ categoryCanExpire, setCategoryCanExpire ] = useState(false);

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='MemberQualificationsAdd'>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    period: periodsContext.selectedPeriod.period_id,
                    category: '',
                    qualification: '',
                    validationDate: moment(),
                    expirationDate: '',
                    certificationNumber: '',
                    location: '',
                    city: '',
                    province: '',
                    clinicAttendeeId: '',
                    memo: '',
                }}
                validationSchema={object().shape({
                    period: string().required(<Translate id='members.profile.qualifications.addModal.season.required' />),
                    category: string().required(<Translate id='members.profile.qualifications.addModal.category.required' />),
                    qualification: string().required(<Translate id='members.profile.qualifications.addModal.qualification.required' />),
                    validationDate: mixed().required(<Translate id='members.profile.qualifications.validationDate.required' />)
                        .test({
                            name: 'validationDateFormat',
                            message: <Translate id='form.validation.date.format' />,
                            test: moment.isMoment,
                        }).test({
                            name: 'beforeExpiration',
                            message: <Translate id='members.profile.qualifications.validationDate.expirationDate' />,
                            test: function(certificationDate){
                                if(this.parent.expirationDate){
                                    return moment(certificationDate).isBefore(moment(this.parent.expirationDate))
                                }
                                return true
                            },
                        }),
                    expirationDate: mixed()
                        .test({
                            name: 'expirationDateFormat',
                            message: <Translate id='form.validation.date.format' />,
                            test: (date) => {
                                if(date){
                                    return moment.isMoment(date)
                                }
                                return true // not required
                            },
                        }).test({
                            name: 'afterCertification',
                            message: <Translate id='members.profile.qualifications.expirationDate.validationDate' />,
                            test: function(expirationDate){
                                if(expirationDate && moment.isMoment(expirationDate) && this.parent.validationDate){
                                    return moment(this.parent.validationDate).isBefore(moment(expirationDate))
                                }
                                return true
                            },
                        }),
                    certificationNumber: string(),
                    location: string(),
                    city: string(),
                    province: string(),
                    clinicAttendeeId: string(),
                    memo: string(),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    membersContext.createMemberQualification(membersContext.currentMember.member_id, {
                        periodId: values.period,
                        qualificationId: values.qualification,
                        validationDate: values.validationDate.format('YYYY-MM-DD'),
                        expirationDate: values.expirationDate ? values.expirationDate.format('YYYY-MM-DD') : '',
                        certificationNumber: values.certificationNumber,
                        location: values.location,
                        city: values.city,
                        province: values.province,
                        clinicAttendeeId: values.clinicAttendeeId,
                        memo: values.memo,
                    })
                        .then(() => {
                            setSubmitting(false);
                            refreshTable();
                            toggle();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setSubmitting(false);
                                if(error.i18n){
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                }else{
                                    setStatus('error' + error.message)
                                }
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader tag='div' toggle={toggle}>
                                <Translate id='members.profile.qualifications.addModal.title' />
                            </ModalHeader>
                            <ModalBody>
                                <Row form>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='period' className='text-muted'><Translate id='members.profile.qualifications.season' /> <Required /></Label>
                                            <FormikSelect
                                                name='period' id='period'
                                                onOptionSelected={(values) => {
                                                    if(formik.values.category){
                                                        formik.setFieldValue('qualification', '')
                                                        qualificationsSelectRef.current?.getSpordleTable().filterChange('periodId', values[0], false);
                                                        qualificationsSelectRef.current?.getSpordleTable().filterChange('categoryId', formik.values.category, true);
                                                    }
                                                }}
                                                renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                searchKeys={[
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                ]}
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            return periodsContext.getOrganizationPeriods(organizationContext.organisation_id)
                                                                .then((_periods) => {
                                                                    return _periods.reduce((newArray, period) => {
                                                                        if(period.active == 1){
                                                                            newArray.push({
                                                                                value: period.period_id,
                                                                                label: period.name,
                                                                                i18n: period.i18n,
                                                                            })
                                                                        }
                                                                        return newArray
                                                                    }, [])
                                                                })
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='validationDate' className='text-muted'><Translate id='members.profile.qualifications.validationDate' /> <Required /></Label>
                                            <FormikDateTime
                                                name='validationDate' id='validationDate'
                                                timeFormat={false}
                                                dateFormat='YYYY-MM-DD'
                                                isValidDate={(current) => formik.values.expirationDate ? moment(current).isBefore(moment(formik.values.expirationDate)) : true}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='category' className='text-muted'><Translate id='members.profile.qualifications.category' /> <Required /></Label>
                                            <FormikSelect
                                                name='category' id='category'
                                                renderOption={({ option }) => (
                                                    <ClinicCategory color={option.color}>
                                                        <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                    </ClinicCategory>
                                                )}
                                                onOptionSelected={(values, select) => {
                                                    if(formik.values.period){
                                                        const selectedOption = select.getSpordleTable().getData().find((option) => option.value === values[0]);
                                                        setCategoryCanExpire(selectedOption.canExpire);
                                                        formik.setFieldValue('qualification', '')
                                                        qualificationsSelectRef.current?.getSpordleTable().filterChange('periodId', formik.values.period, false);
                                                        qualificationsSelectRef.current?.getSpordleTable().filterChange('categoryId', values[0], true);
                                                    }
                                                }}
                                                searchKeys={[
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                ]}
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            return qualificationCategoriesContext.getQualificationCategories()
                                                                .then((_categories) => {
                                                                    return _categories.reduce((newArray, cat) => {
                                                                        if(cat.active == 1){
                                                                            newArray.push({
                                                                                value: cat.qualification_category_id,
                                                                                label: cat.name,
                                                                                i18n: cat.i18n,
                                                                                color: isNaN(parseInt(cat.color)) ? 0 : cat.color,
                                                                                canExpire: cat.can_expire == 1,
                                                                            })
                                                                        }
                                                                        return newArray
                                                                    }, [])
                                                                })
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='qualification' className='text-muted'><Translate id='members.profile.qualifications.qualification' /> <Required /></Label>
                                            <FormikSelect
                                                name='qualification' id='qualification'
                                                ref={qualificationsSelectRef}
                                                disabled={!formik.values.period || !formik.values.category}
                                                renderOption={({ option }) => (
                                                    <>
                                                        <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                        {option.levelLabel &&
                                                                <>
                                                                    {' - '}
                                                                    <DisplayI18n field='name' defaultValue={option.levelLabel} i18n={option.levelI18n} />
                                                                </>
                                                        }
                                                    </>
                                                )}
                                                searchKeys={[
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                    `levelLabel`,
                                                    `levelI18n.${i18nContext.getGenericLocale()}.name`,
                                                ]}
                                                initFilter={{
                                                    periodId: '',
                                                    categoryId: '',
                                                }}
                                                loadData={(from, extra, spordleTable) => {
                                                    switch (from){
                                                        case 'FILTER':
                                                            spordleTable.setLoading();
                                                        case 'CDM':
                                                            if(extra.filters.periodId && extra.filters.categoryId){
                                                                return qualificationsContext.getQualifications({
                                                                    period_id: extra.filters.periodId,
                                                                    qualification_category_id: extra.filters.categoryId,
                                                                })
                                                                    .then((_qualifications) => {
                                                                        return _qualifications.reduce((newArray, qual) => {
                                                                            if(qual.active == 1){
                                                                                newArray.push({
                                                                                    value: qual.qualification_id,
                                                                                    label: qual.name,
                                                                                    i18n: qual.i18n,

                                                                                    levelI18n: qual.qualification_level?.i18n,
                                                                                    levelLabel: qual.qualification_level?.name,
                                                                                })
                                                                            }
                                                                            return newArray
                                                                        }, [])
                                                                    })
                                                            }
                                                            return Promise.resolve([]);

                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='expirationDate' className='text-muted'><Translate id='members.profile.qualifications.expirationDate' /> </Label>
                                            <FormikDateTime
                                                name='expirationDate' id='expirationDate'
                                                timeFormat={false}
                                                dateFormat='YYYY-MM-DD'
                                                isValidDate={(current) => formik.values.validationDate ? moment(formik.values.validationDate).isBefore(moment(current)) : true}
                                                inputProps={{
                                                    disabled: !(categoryCanExpire || identityRolesContext.isGod()),
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='certificationNumber' className='text-muted'><Translate id='members.profile.qualifications.certificationNumber' /></Label>
                                            <FormikInputText id='certificationNumber' name='certificationNumber' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='location' className='text-muted'><Translate id='members.profile.qualifications.location' /></Label>
                                            <FormikInputText id='location' name='location' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='city' className='text-muted'><Translate id='members.profile.qualifications.city' /></Label>
                                            <FormikInputText id='city' name='city' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='province' className='text-muted'><Translate id='form.fields.province' /></Label>
                                            <FormikSelect
                                                name='province' id='province'
                                                renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                searchKeys={[
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                ]}
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            return utilsContext.getCountries()
                                                                .then((countries) => {
                                                                    const canada = countries.find((country) => country.code === 'CA')
                                                                    if(canada && canada.sub_divisions){
                                                                        return canada.sub_divisions.reduce((newArray, province) => {
                                                                            if(province.active == 1){
                                                                                newArray.push({
                                                                                    value: province.code,
                                                                                    label: province.code,
                                                                                    i18n: province.i18n,
                                                                                })
                                                                            }
                                                                            return newArray
                                                                        }, [])
                                                                    }
                                                                    return []

                                                                })
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='clinicAttendeeId' className='text-muted'><Translate id='members.profile.qualifications.clinic' /></Label>
                                            <FormikSelect
                                                name='clinicAttendeeId' id='clinicAttendeeId'
                                                renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                searchKeys={[
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                ]}
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            return membersContext.getMemberAttendees(membersContext.currentMember.member_id)
                                                                .then((attendees) => {
                                                                    return attendees.reduce((newArray, attendee) => {
                                                                        newArray.push({
                                                                            value: attendee.clinic_attendee_id,
                                                                            label: attendee.clinic.name,
                                                                            i18n: attendee.clinic.i18n,
                                                                        })
                                                                        return newArray
                                                                    }, [])
                                                                })
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for='memo' className='text-muted'><Translate id='members.profile.qualifications.memo' /></Label>
                                    <FormikTextArea id='memo' name='memo' rows={2} trim />
                                </FormGroup>
                                {formik.status?.includes('error') &&
                                        <Alert color='danger'><Translate id={'misc.error'} defaultMessageId='misc.error' /></Alert>
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type="submit"><Translate id='misc.add' /></Button>
                                <Button color='primary' type='button' onClick={toggle} outline><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

export default MemberQualificationsAdd;