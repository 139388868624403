import { SpordleTableContext } from '@spordle/datatables';
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import {
    Button, FormGroup,
    Label, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { object, string } from 'yup';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import Required from '../../../../../components/formik/Required';
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { PositionsContext } from "../../../../../contexts/PositionsContext";
import { success, fail } from '@spordle/toasts';
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../../../helpers/i18nHelper";

const OrganizationPositionGroupsAdd = ({ isOpen, toggle }) => {

    const i18nHelper = useContext(I18nHelperContext);
    const spordleTable = useContext(SpordleTableContext);
    const positionsContext = useContext(PositionsContext);
    const organizationContext = useContext(OrganizationContext);

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='OrganizationPositionGroupsAdd'>
            <Formik
                initialValues={{
                    ...i18nHelper.getInitialValues(),
                    status: '1',
                    abbreviation: '',
                }}
                validationSchema={object().shape({
                    ...i18nHelper.getValidationSchema({ name: string().required(<Translate id='form.validation.name.required' />) }),
                    status: string().required(<Translate id='organization.settings.organizationPositionGroups.add.status.required' />),
                    abbreviation: string().required(<Translate id='organization.settings.organizationPositionGroups.sidepanel.abbreviation.required' />),
                })}
                onSubmit={(values, { setSubmitting }) => {

                    const apiValues = {
                        ...values,
                        ...i18nHelper.getAPIValues(values),
                        active: values.status,
                    };
                    apiValues.organisation_id = organizationContext.organisation_id;
                    delete apiValues.i18n;
                    positionsContext.createPositionGroup(apiValues)
                        .then((data) => {
                            const tableData = {
                                position_group_id: data,
                                ...values,
                                active: values.status,
                            }
                            spordleTable.addRow(tableData).then(toggle);
                            success({ timeout: 3 });
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                setSubmitting(false);
                            }
                        })
                }}
            >
                {(formik) => {
                    return (
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <Form>
                                <ModalHeader toggle={toggle}><Translate id='organization.settings.organizationPositionGroups.add.title' /></ModalHeader>
                                <ModalBody>
                                    <RenderI18nForm field="name">
                                        {({ fieldName, fieldLabel }) => {
                                            return (
                                                <FormGroup key={fieldName}>
                                                    <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /> </Label>
                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                </FormGroup>
                                            )
                                        }}
                                    </RenderI18nForm>

                                    <FormGroup>
                                        <Label className='text-muted' for='abbreviation'><Translate id='organization.settings.organizationPositionGroups.table.column.abbreviation' /> <Required /> </Label>
                                        <FormikInputText name='abbreviation' translateLabel id='organization.settings.organizationPositionGroups.table.column.abbreviation' trim maxLength={6} />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for='status' className='text-muted'><Translate id='organization.settings.organizationPositionGroups.add.status' /> <Required /></Label>
                                        <FormikSelect
                                            id='status_OrganizationPositionGroupsAdd'
                                            name='status'
                                            placeholder='misc.select.placeholder'
                                            search={false}
                                            loadingStatus='success'
                                            defaultData={[
                                                { selected: formik.initialValues.status == '1', id: 'status-active', label: 'misc.active', translateLabel: true, value: '1' },
                                                { id: 'status-inactive', label: 'misc.inactive', translateLabel: true, value: '0' },
                                            ]}
                                        />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type='button' outline onClick={toggle} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </Form>
                        </OverlayLoader>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    )
}

export default OrganizationPositionGroupsAdd;