export default{
    // ONLY ADD GENERAL FIELDS
    // Example of a general field   : First Name (can be used for attendees, instructors, users, etc.)
    // Example of a specific field  : Organization Name (only used in organization)

    // Validation
    "form.required" : "Champ requis",
    "form.min"      : "Veuillez respecter la valeur minimum",
    "form.max"      : "Veuillez respecter la valeur maximum",

    // Fields
    "form.fields.uniqueIdentifier"                 : "{unique_identifier}",
    "form.fields.forms"                            : "Questionnaires",
    "form.fields.firstName"                        : "Prénom",
    "form.fields.lastName"                         : "Nom de famille",
    "form.fields.name"                             : "Nom",
    "form.fields.name.lang"                        : "Nom ({lang})",
    "form.fields.title"                            : "Titre",
    "form.fields.title.lang"                       : "Titre ({lang})",
    "form.fields.short_name"                       : "Nom abrégé",
    "form.fields.short_name.lang"                  : "Nom abrégé ({lang})",
    "form.fields.description"                      : "Description",
    "form.fields.description.lang"                 : "Description ({lang})",
    "form.fields.price"                            : "Prix",
    "form.fields.memo"                             : "Mémo",
    "form.fields.email"                            : "Courriel",
    "form.fields.document"                         : "Document",
    "form.fields.documents"                        : "Document(s)",
    "form.fields.amount"                           : "Montant",
    "form.fields.fees"                             : "Frais",
    "form.fields.order"                            : "Ordre",
    "form.fields.displayOrder"                     : "Ordre d'affichage",
    "form.fields.phone"                            : "Numéro de téléphone",
    "form.fields.extension"                        : "Poste",
    "form.fields.mobilePhone"                      : "Téléphone cellulaire",
    "form.fields.phoneType"                        : "Type de téléphone",
    "form.fields.activityPeriod"                   : "Période d'activité",
    "form.fields.activityPeriod.required"          : "La période d'activité est requise",
    "form.fields.dateOfBirth"                      : "Date de naissance",
    "form.fields.date.helper"                      : "AAAA-MM-JJ",
    "form.fields.date"                             : "Date",
    "form.fields.gender"                           : "Identité de genre",
    "form.fields.genderDescription"                : "Identification du genre",
    "form.fields.genderDescription.required"       : "Veuillez spécifier le genre auquel vous vous identifiez",
    "form.fields.item.type.registration"           : "Inscription",
    "form.fields.item.type.affiliation"            : "Affiliation",
    "form.fields.item.type.clinic"                 : "Clinique",
    "form.fields.gender.placeholder"               : "Sélectionnez un identité de genre",
    "form.fields.gender.male"                      : "Masculin",
    "form.fields.gender.female"                    : "Féminin",
    "form.fields.gender.other"                     : "Préfère ne pas répondre",
    "form.fields.gender.mixed"                     : "Mixte",
    "form.fields.gender.prefer_not_to_say"         : "Préfère ne pas répondre",
    "form.fields.gender.prefer_to_self_describe"   : "Préfère se décrire eux-même",
    "form.fields.birth_country"                    : "Pays d'origine",
    "form.fields.birth_country.placeholder"        : "Sélectionner un pays d'origine",
    "form.fields.streetNumber"                     : "Numéro d'adresse",
    "form.fields.address"                          : "Adresse",
    "form.fields.address2.placeholder"             : "Appartement, suite, unité, étage, bâtiment, etc.",
    "form.fields.addressType"                      : "Type d'adresse",
    "form.fields.city"                             : "Ville",
    "form.fields.country"                          : "Pays",
    "form.fields.state"                            : "État",
    "form.fields.province"                         : "Province",
    "form.fields.zip"                              : "Code postal",
    "form.fields.number"                           : "Numéro",
    "form.fields.language"                         : "Langue",
    "form.fields.primaryLanguage"                  : "Langue maternelle",
    "form.fields.primaryLanguage.placeholder"      : "Sélectionnez une langue",
    "form.fields.secondaryLanguages"               : "Langue seconde",
    "form.fields.nationality"                      : "Citoyenneté",
    "form.fields.nationality.placeholder"          : "Sélectionnez une citoyenneté",
    "form.fields.nationality.selected"             : "{count} citoyennetés sélectionnées",
    "form.fields.nationality.CA"                   : "Canadien",
    "form.fields.nationality.US"                   : "Américain",
    "form.fields.lastConnection"                   : "Dernière connexion",
    "form.fields.general"                          : "Général",
    "form.fields.generalInfo"                      : "Informations générales",
    "form.fields.additionalInfo"                   : "Informations démographiques",
    "form.fields.status"                           : "Statut",
    "form.fields.method"                           : "Méthode",
    "form.fields.comment"                          : "Commentaire",
    "form.fields.team_status"                      : "Statut d'équipe",
    "form.fields.period"                           : "Période",
    "form.fields.period.required"                  : "Période est requise",
    "form.fields.years_same_address"               : "Année de déménagement",
    "form.fields.identifyAsIndigenous"             : "S'identifier comme Autochtone",
    "form.fields.identifyAsIndigenous.required"    : "Veuillez spécifier si vous vous identifiez comme Autochtone.",
    "form.fields.indigenousGroup"                  : "Groupe Autochtone",
    "form.fields.indigenousGroup.required"         : "Le groupe Autochtone est requis.",
    "form.fields.ethnicity"                        : "Ethnicité",
    "form.fields.ethnicity.required"               : "L'ethnicité est requise.",
    "form.fields.note"                             : "Note",
    "form.fields.ethnicityNote"                    : "Description de l'ethnicité",
    "form.fields.ethnicityNote.required"           : "La description est requise.",
    "form.fields.content.lang"                     : "Contenu ({lang})",
    "form.fields.custom_link.lang"                 : "Lien personnalisé ({lang})",
    "form.fields.video_id.lang"                    : "Identifiant de vidéo ({lang})",
    "form.fields.video_link.lang"                  : "Lien de vidéo ({lang})",
    "form.fields.visible_on_website"               : "Visible sur le site web",
    "form.fields.email_visible_on_website"         : "Courriel visible",
    "form.fields.phone_visible_on_website"         : "Téléphone visible",
    "form.fields.term"                             : "Terme",
    "form.fields.term.lang"                        : "Terme ({lang})",
    "form.fields.reason"                           : "Raison",
    "form.fields.excludeAffiliationFee"            : "Exclure les frais d'affiliation",
    "form.fields.excludeAffiliationFee.required"   : "Le champ «Exclure les frais d'affiliation»",
    "form.fields.identityGroupIndigenous"          : "Description du groupe Autochtone",
    "form.fields.identityGroupIndigenous.required" : "Veuillez identifier le groupe.",

    "form.fields.waiver.required"           : "Veuillez sélectionner une option.",
    "form.fields.waiver.defaultAccept"      : "J'ai lu et j'accepte la dispense",
    "form.fields.waiver.doNotSign"          : "Je préfère ne pas signer",
    "form.fields.waiver.notMandatory"       : "Cette dispense n'est pas obligatoire",
    "form.fields.waiver.mandatory"          : "Cette dispense est obligatoire",
    "form.fields.waiver.clear"              : "Effacer la sélection",
    "form.fields.waiver.signature.required" : "Le type d'accord est requis.",

    // Canada
    "form.fields.country.ca"  : "Canada",
    "form.fields.state.ca.ab" : "Alberta",
    "form.fields.state.ca.bc" : "Colombie-Britannique",
    "form.fields.state.ca.pe" : "Île-du-Prince-Édouard",
    "form.fields.state.ca.mb" : "Manitoba",
    "form.fields.state.ca.nb" : "Nouveau-Brunswick",
    "form.fields.state.ca.ns" : "Nouvelle-Écosse",
    "form.fields.state.ca.on" : "Ontario",
    "form.fields.state.ca.qc" : "Québec",
    "form.fields.state.ca.sk" : "Saskatchewan",
    "form.fields.state.ca.nl" : "Terre-Neuve-et-Labrador",
    "form.fields.state.ca.nu" : "Nunavut",
    "form.fields.state.ca.nt" : "Territoires du Nord-Ouest",
    "form.fields.state.ca.yt" : "Yukon",

    // Error messages (Yup)
    "form.validation.string.max"                  : "Le nombre de caractères ne doit pas dépasser {count}",
    "form.validation.status.required"             : "Le statut est requis",
    "form.validation.field.required"              : "{field} est requis",
    "form.validation.firstName.required"          : "Le prénom est requis",
    "form.validation.lastName.required"           : "Le nom est requis",
    "form.validation.name.required"               : "Le nom est requis",
    "form.validation.name.lang.required"          : "Le nom ({lang}) est requis",
    "form.validation.title.required"              : "Le titre est requis",
    "form.validation.description.required"        : "La description est requise",
    "form.validation.description.lang.required"   : "La description ({lang}) est requise",
    "form.validation.email.required"              : "Le courriel est requis",
    "form.validation.email.valid"                 : "Le courriel doit être valide",
    "form.validation.phone.required"              : "Le numéro de téléphone est requis",
    "form.validation.phone.valid"                 : "Le numéro de téléphone doit être valide",
    "form.validation.phoneType.required"          : "Le type de téléphone est requis",
    "form.validation.dateOfBirth.required"        : "La date de naissance est requise",
    "form.validation.dateOfBirth.valid"           : "La date de naissance doit être d'un bon format",
    "form.validation.gender.required"             : "L'identité de genre est requis",
    "form.validation.address.required"            : "L'adresse est requise",
    "form.validation.addressType.required"        : "Le type d'adresse est requis",
    "form.validation.years_same_address.required" : "L'année de déménagement est requise.",
    "form.validation.years_same_address.tooltip"  : "Date depuis laquelle le participant réside à l'adresse actuelle",
    "form.validation.streetNumber.required"       : "Le numéro d'adresse est requis",
    "form.validation.POBox.required"              : "La boîte postale est requise",
    "form.validation.city.required"               : "La ville est requise",
    "form.validation.country.required"            : "Le pays est requis",
    "form.validation.state.required"              : "L'état est requis",
    "form.validation.province.required"           : "La province est requise",
    "form.validation.zip.required"                : "Le code postal est requis",
    "form.validation.zip.valid"                   : "Le code postal doit être valide",
    "form.validation.primaryLanguage.required"    : "La langue primaire est requise",
    "form.validation.birth_country.required"      : "Pays d'origine est requis",
    "form.validation.nationality.required"        : "La citoyenneté est requise",
    "form.validation.ethnicity.required"          : "L'ethnicité est requise",
    "form.validation.language.required"           : "La langue est requise",
    "form.validation.order.minimum"               : "Le chiffre d'ordre doit être égal ou plus grand que 0",
    "form.validation.order.required"              : "L'ordre est requis",
    "form.validation.price.required"              : "Le prix est requis",
    "form.validation.number"                      : "Cette valeur doit être un nombre",
    "form.validation.number.positive"             : "Cette valeur doit être un nombre positif",
    "form.validation.url.format"                  : "Format d'URL invalide",
    "form.validation.displayOrder.required"       : "Ordre d'affichage requis",
    "form.validation.note.required"               : "La note est requise",

    "form.cart.required"          : "Champ requis",
    "form.cart.date.before.today" : "La date doit être avant aujourd'hui ",

    "form.validation.date.required"    : "La date est requise",
    "form.validation.date.placeholder" : "AAAA-MM-JJ",
    "form.validation.date.format"      : "Date doit être d'un format valide",
    "form.validation.time.format"      : "Le temps doit être d'un format valide",

    "form.validation.atLeastOneField" : "Au moins un champ doit être rempli",

    "form.manualRegistration.print.msg"  : "Preparing print",
    "form.manualRegistration.print.info" : "We are preparing the printable version of the registration form",
}