export function setLocalStorageItem(key, value){
    try{
        localStorage.setItem(key, value);
    }catch(error){
        // Do nothing
        // Access is denied by browser configs
    }
}

export function setSessionStorageItem(key, value){
    try{
        sessionStorage.setItem(key, value);
    }catch(error){
        // Do nothing
        // Access is denied by browser configs
    }
}

export function getLocalStorageItem(key){
    try{
        return localStorage.getItem(key);
    }catch(error){
        // Do nothing
        // Access is denied by browser configs
    }
}

export function getSessionStorageItem(key){
    try{
        return sessionStorage.getItem(key);
    }catch(error){
        // Do nothing
        // Access is denied by browser configs
    }
}

export function removeLocalStorageItem(key){
    try{
        return localStorage.removeItem(key);
    }catch(error){
        // Do nothing
        // Access is denied by browser configs
    }
}

export function removeSessionStorageItem(key){
    try{
        return sessionStorage.removeItem(key);
    }catch(error){
        // Do nothing
        // Access is denied by browser configs
    }
}

export function clearLocalStorage(){
    try{
        localStorage.clear();
    }catch(error){
        // Do nothing
        // Access is denied by browser configs
    }
}

export function clearSessionStorage(){
    try{
        sessionStorage.clear();
    }catch(error){
        // Do nothing
        // Access is denied by browser configs
    }
}