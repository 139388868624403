import { useState } from "react"
import CanDoAction from "../permissions/CanDoAction";
import HelpCenterV2 from './HelpCenterV2';

const HelpCenterV2StateWrapper = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return (
        <CanDoAction className='nav-item' tag="li" componentPermissionCode="manage_help_bubble" componentCode="settings" action="READ">
            <HelpCenterV2 isOpen={isOpen} toggle={toggle} />
        </CanDoAction>
    )
}

export default HelpCenterV2StateWrapper