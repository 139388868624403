export default{
    "catalog.registrationFees.rollover.dropdown.button"    : "Rollover",
    "catalog.registrationFees.rollover.title"              : "Registration fees rollover",
    "catalog.registrationFees.rollover.warning"            : "The current season already has {amount, plural, one {# registration fee} other {# registration fees}}. Please verify if a rollover has already been done to minimize the risk of duplicate data.",
    "catalog.registrationFees.rollover.prevSeasons"        : "Previous season",
    "catalog.registrationFees.rollover.selectAll"          : "Select all",
    "catalog.registrationFees.rollover.selectNone"         : "Unselect all",
    "catalog.registrationFees.rollover.required"           : "Please select at least one registration fee",
    "catalog.registrationFees.rollover.confirm"            : "Click the button down below to create these registration fees in the new season",
    "catalog.registrationFees.rollover.additionalFeesHint" : "Some of these registration fees have early and/or late fees. We have preconfigured the date of these fees to the same as the old season but for the year {year}.",
    "catalog.registrationFees.rollover.additionalFields"   : "We have also prefilled the additional fields with the existing answers from the old season.",
    "catalog.registrationFees.rollover.earlyFee"           : "Early fee: {amount}",
    "catalog.registrationFees.rollover.earlyDate.required" : "Early date and time are required",
    "catalog.registrationFees.rollover.lateFee"            : "Late fee: {amount}",
    "catalog.registrationFees.rollover.lateDate.required"  : "Late date and time are required",
    "catalog.registrationFees.rollover.invalid"            : "Please verify the data for this registration fee",
}