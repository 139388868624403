import moment from "moment";

/**
 * Create a random date between today and 2 years ago
 * @example
 * <DateFormat value={createRandomDate()}/>;
 * @returns A date string in ISO format of the random date
 */
function createRandomDate(){
    return new Date(new Date().setDate(new Date().getDate() - Math.ceil(Math.random() * 730))).toISOString();
}

/**
 * Calculated the age from a given birthdate
 * @param {moment.MomentInput} birthDate The birthdate to calculate the age from
 * @returns {number} The age
 */
export function getAge(birthDate){
    return moment().diff(birthDate, 'years');
}

export default createRandomDate;