import Translate, { DateFormat } from '@spordle/intl-elements';
import React from "react";
import images from '@spordle/ui-kit';
import { Alert, Card, CardBody, Collapse } from 'reactstrap';
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import SpordlePanelTable from '../../../../../../components/sidePanel/SpordlePanel';
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper";
import AddContactModal from './AddContactModal';
import SidePanelMemberContacts from './SidePanelFormMemberContacts';
import UpdateEmailModal from './UpdateEmailModal';
import { stringBuilder } from '@spordle/helpers';
import { AxiosCancelAll, AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { PhoneTypesContext } from '../../../../../../contexts/PhoneTypesContext';
import withContexts from '../../../../../../helpers/withContexts';
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import { DisplayPhoneNumber } from '@spordle/formik-elements';
import { fail } from "@spordle/toasts";
import moment from 'moment';
import SpordleSelect from '@spordle/spordle-select';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { withSavedSearch } from '../../../../../../components/customHooks/useSavedSearch';
import { Tooltip } from '@mantine/core';

const noProfileLogo = images.noprofile;

class MemberContacts extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loading: false,

            // add modal
            addContact: false,
            contactTypes: [],
            phoneTypes: [],

            // update email
            updateEmailIsOpen: false,
            updateEmail: '',

            error: '',
        };
    }

    ////////////////////////////////////////////////////////////////
    // Add modal
    ////////////////////////////////////////////////////////////////

    contactAlreadyExists = (contact) => {
        const newContact = {
            ...contact,
            compare: `${contact.firstName}, ${contact.lastName}, ${contact.email}, ${contact.phones?.map((phone) => phone.phoneNumber)?.toString()}`,
        };

        return this.contactsTableRef?.getData().find((contact) => contact.compare === newContact.compare)
    }

    onAdd = () => {
        if(!this.props.MembersContext.currentMember.email && this.state.updateEmail){ // ask the user if he wants to set contact's email to the member
            this.toggleUpdateEmailModal();
        }
    }

    toggleAddContact = () => {
        this.setState((prevState) => ({ addContact: !prevState.addContact }))
    }
    ////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////
    // Update Email
    ////////////////////////////////////////////////////////////////
    toggleUpdateEmailModal = () => {
        this.setState((prevState) => ({ updateEmailIsOpen: !prevState.updateEmailIsOpen }))
    }
    ////////////////////////////////////////////////////////////////

    componentDidMount(){
        Promise.all([
            this.props.PhoneTypesContext.getPhoneTypes({ organisation_id: this.props.MembersContext.currentMember.organisation?.organisation_id || this.props.OrganizationContext.organisation_id }),
            this.props.MembersContext.getMemberContactTypes({ organisation_id: this.props.MembersContext.currentMember.organisation?.organisation_id || this.props.OrganizationContext.organisation_id }),
        ])
            .then((promises) => {
                this.setState(() => ({
                    phoneTypes: promises[0],
                    contactTypes: promises[1],
                }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    componentWillUnmount(){
        AxiosCancelAll();
    }

    render(){
        return (
            <>
                <AddContactModal
                    phoneTypes={this.state.phoneTypes}
                    contactTypes={this.state.contactTypes}
                    contactAlreadyExists={this.contactAlreadyExists}
                    isOpen={this.state.addContact}
                    toggle={this.toggleAddContact}
                    refresh={this.props.refreshMemberProfile}
                    onAdd={this.onAdd}
                />
                <UpdateEmailModal email={this.state.updateEmail} isOpen={this.state.updateEmailIsOpen} toggle={() => this.toggleUpdateEmailModal()} />
                <Card className="card-shadow">
                    <OverlayLoader isLoading={this.state.loading}>
                        <CardBody>
                            <SpordlePanelTable
                                allowOutsideClick
                                sidePanel={(props) => <SidePanelMemberContacts {...props} contactTypes={this.state.contactTypes} phoneTypes={this.state.phoneTypes} refresh={this.props.refreshMemberProfile} />}
                                dataIndex='id'
                                table={(panelProps) => {
                                    return (
                                        <SpordleTableProvider
                                            key={this.props.MembersContext.currentMember.contacts?.length}
                                            id='formMemberContact'
                                            tableHover clickable
                                            viewMode='GRID'
                                            ref={(r) => { this.contactsTableRef = r; panelProps.spordleTableRef(r); }}
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                            dataIndex='id'
                                            onColumnClick={(e, contact) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        if(contact.id === 'template'){
                                                            this.toggleAddContact();
                                                        }else{
                                                            panelProps.onSingleSelectChange(contact);
                                                        }
                                                        break;
                                                }
                                            }}
                                            defaultSorting={this.props.savedSearch.savedSearch || '-lastUpdated'} // plug useSavedSearch
                                            columnSorting={(contactA, contactB, spordleTable) => {
                                                switch (spordleTable.state.orderingBy.column){
                                                    case 'lastUpdated':
                                                        // the unix() function returns a date in unix format (number)
                                                        const unixA = moment(contactA.lastUpdated).unix();
                                                        const unixB = moment(contactB.lastUpdated).unix();
                                                        if(contactA.id === 'template') // sort the add button last
                                                            return 1;
                                                        if(contactB.id === 'template')
                                                            return -1;
                                                        return spordleTable.state.orderingBy.order === 'ASC' ? unixA - unixB : unixB - unixA; // sort most recently udpated first
                                                    case 'type':
                                                        if(contactA.id === 'template') // sort the add button last
                                                            return 1;
                                                        if(contactB.id === 'template')
                                                            return -1;
                                                        const contactATranslated = displayI18n('name', contactA.contactType?.i18n, contactA.contactType?.name, this.props.I18nContext.getGenericLocale())
                                                        const contactBTranslated = displayI18n('name', contactB.contactType?.i18n, contactB.contactType?.name, this.props.I18nContext.getGenericLocale())
                                                        return new Intl.Collator(this.props.I18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(contactATranslated, contactBTranslated) * (spordleTable.state.orderingBy.order === 'ASC' ? 1 : -1);
                                                    case 'emergency':
                                                        if(contactA.id === 'template') // sort the add button last
                                                            return 1;
                                                        if(contactB.id === 'template')
                                                            return -1;
                                                        return contactA.emergency ? -1 : 1; // no distincting between ascending and descending, we want to show emergency first
                                                }
                                            }}
                                            gridConfig={{
                                                col: {
                                                    sm: 12,
                                                    md: 6,
                                                    lg: 4,
                                                    className: 'mb-3 member-contacts-col',
                                                },
                                                gridRender: (contact) => {
                                                    if(contact.id === 'template'){
                                                        return (
                                                            <CanDoAction className='ml-auto text-link h-100' action='ADD' componentCode='members' componentPermissionCode='member_contacts'>
                                                                <UserDisplay card hover className={stringBuilder('w-100 mb-0 border-primary h-100 ', { 'shadow-danger': contact.errors })} style={{ minWidth: 'unset', borderStyle: 'dashed', borderWidth: '1px' }}>
                                                                    <UserDisplay.Container>
                                                                        <UserImg
                                                                            src={contact.logo ?? noProfileLogo}
                                                                            width={50}
                                                                            alt={`${contact.firstName} ${contact.lastName}`}
                                                                            className="p-2"
                                                                        />
                                                                    </UserDisplay.Container>
                                                                    <UserDisplay.Container>
                                                                        <UserDisplay.Title tag="span" className='text-link'><i className='mdi mdi-plus' /> <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact' /></UserDisplay.Title>
                                                                    </UserDisplay.Container>
                                                                </UserDisplay>
                                                            </CanDoAction>
                                                        )
                                                    }
                                                    return (
                                                        <UserDisplay card hover className={`w-100 mb-0 h-100 ${this.state.error && !contact.contactType ? ' border-danger' : ''}`} style={{ minWidth: 'unset' }}>
                                                            <UserDisplay.Container>
                                                                <UserImg
                                                                    abbr={`${contact.firstName.charAt(0)}${contact.lastName.charAt(0)}`}
                                                                    src={contact.logo}
                                                                    width={50}
                                                                    alt={`${contact.firstName} ${contact.lastName}`}
                                                                    className="p-2"
                                                                />
                                                                {/* <img className="object-fit-contain" src={contact.logo ?? noProfileLogo} width="40" height="40" alt={`${contact.firstName} ${contact.lastName}`}/> */}
                                                            </UserDisplay.Container>
                                                            <UserDisplay.Container className='text-truncate w-100'>
                                                                <UserDisplay.Title className="d-flex align-items-center">
                                                                    <div>
                                                                        {contact.firstName} {contact.lastName}
                                                                    </div>
                                                                    <div className='d-flex justify-content-end ml-auto'>
                                                                        {contact.allow_communication &&
                                                                            <Tooltip withArrow zIndex={3000} label={<Translate id='members.profile.overview.memberContacts.allow_communication.tooltip' />}>
                                                                                <div><i className='mdi mdi-email text-primary font-16 ml-1' /></div>
                                                                            </Tooltip>
                                                                        }
                                                                        {contact.emergency &&
                                                                            <div><i className='mdi mdi-ambulance text-danger font-16 ml-1' /></div>
                                                                        }
                                                                        {contact.tax_receipt_recipient &&
                                                                            <Tooltip withArrow zIndex={3000} label={<Translate id='members.profile.overview.memberContacts.taxReceiptRecipient.tooltip' />}>
                                                                                <div><i className='mdi mdi-cash-usd text-success font-16 ml-1' /></div>
                                                                            </Tooltip>
                                                                        }
                                                                    </div>
                                                                </UserDisplay.Title>
                                                                <UserDisplay.Subtitle><DisplayI18n field='name' defaultValue={contact.contactType?.name} i18n={contact.contactType?.i18n} /></UserDisplay.Subtitle>
                                                                <UserDisplay.Subtitle className='text-truncate'>{contact.email}</UserDisplay.Subtitle>
                                                                <UserDisplay.Subtitle>
                                                                    <DisplayPhoneNumber phoneString={contact.phones[0]?.phoneNumber} format='INTERNATIONAL' />
                                                                </UserDisplay.Subtitle>
                                                                <UserDisplay.Subtitle>
                                                                    <Translate id='members.profile.overview.memberContacts.lastUpdated' />: <DateFormat value={contact.lastUpdated} />
                                                                </UserDisplay.Subtitle>
                                                            </UserDisplay.Container>
                                                        </UserDisplay>
                                                    )

                                                },
                                            }}
                                            desktopWhen='md'
                                            rowIsHighlighted={(contact) => !!contact.checked}
                                            loadData={(from, _, spordleTable) => {
                                                const formatContacts = (contacts) => {
                                                    const contactsArray = contacts.map((contact) => ({
                                                        id: contact.member_contact_id,
                                                        firstName: contact.first_name,
                                                        lastName: contact.last_name,
                                                        email: contact.email,
                                                        emergency: contact.emergency === '1',
                                                        tax_receipt_recipient: contact.tax_receipt_recipient === '1',
                                                        allow_communication: contact.allow_communication === '1',
                                                        lastUpdated: contact.updated_at,
                                                        contactType: contact.member_contact_type ? {
                                                            i18n: contact.member_contact_type.i18n,
                                                            memberContactTypeId: contact.member_contact_type.member_contact_type_id,
                                                            name: contact.member_contact_type.name,
                                                        } : null,
                                                        phones: contact.phones?.map((phone) => ({
                                                            phoneNumber: phone.phone_number,
                                                            phoneType: {
                                                                i18n: phone.phone_type.i18n,
                                                                name: phone.phone_type.name,
                                                                phoneTypeId: phone.phone_type.phone_type_id,
                                                            },
                                                            memberContactPhoneId: phone.member_contact_phone_id,
                                                        })),
                                                        created_by: contact.created_by,
                                                    }));
                                                    contactsArray.push({ id: 'template' })
                                                    return contactsArray
                                                }

                                                switch (from){
                                                    case 'CDM':
                                                        if(this.props.MembersContext.currentMember.contacts?.length > 0){
                                                            return Promise.resolve(formatContacts(this.props.MembersContext.currentMember.contacts))
                                                        }
                                                        return Promise.resolve(formatContacts([]))

                                                    case 'REFRESH':
                                                        return this.props.MembersContext.getMemberContacts(this.props.MembersContext.currentMember.member_id)
                                                            .then(formatContacts)
                                                    default:
                                                        break;
                                                }
                                            }}
                                        >
                                            {(spordleTable) => (
                                                <>
                                                    <CardSectionTitle className='w-100 mb-2' title='members.profile.overview.memberContacts.title' />
                                                    <div className='mb-3'>
                                                        <SpordleSelect
                                                            id='contactSortSelect'
                                                            className='w-25 mb-2'
                                                            renderSelectedOption={(option) => (
                                                                <><Translate id='members.profile.overview.memberContacts.sort' />: <Translate id={option.label} /></>
                                                            )}
                                                            onOptionSelected={(value) => {
                                                                this.props.savedSearch.saveSearch(value[0]);
                                                                spordleTable.setColumnSorting(value[0]);
                                                            }}
                                                            defaultValues={[ `${this.props.savedSearch.savedSearch ? this.props.savedSearch.savedSearch : '-lastUpdated'}` ]}
                                                            loadingStatus='success'
                                                            search={false}
                                                            defaultData={[
                                                                { label: 'members.profile.overview.memberContacts.contactTypes', value: '+type', translateLabel: true },
                                                                { label: 'members.profile.overview.memberContacts.lastUpdated', value: '-lastUpdated', translateLabel: true },
                                                                { label: 'members.profile.overview.memberContacts.sort.emergency', value: '+emergency', translateLabel: true },
                                                            ]}
                                                        />
                                                    </div>
                                                    <SpordleTableView />
                                                </>
                                            )}

                                        </SpordleTableProvider>
                                    )
                                }}
                            />
                            { this.state.error &&
                                <Collapse isOpen appear>
                                    <Alert color="danger">
                                        {this.state.error}
                                    </Alert>
                                </Collapse>
                            }
                        </CardBody>
                    </OverlayLoader>
                </Card>
            </>
        )
    }
}

export default withSavedSearch(withContexts(OrganizationContext, MembersContext, PhoneTypesContext, I18nContext)(MemberContacts), 'memberContactSortingMethod')