import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";
import FavoriteToggler from "../../../../components/favoriteToggler/FavoriteToggler";
import { FAV_TOGGLE_BREADCRUMBS } from "../../../../helpers/breadcrumbs";

const BreadcrumbsFavBtn = ({ breadcrumbs, favoriteData }) => {
    return (
        <FavoriteToggler
            to={location.pathname}
            breadcrumbs={breadcrumbs}
            favoriteData={favoriteData}
            toggler={({ isFav, isAdding, match: _, ...props }) => (
                <Tooltip
                    withArrow
                    label={<Translate id='misc.favorite' />}
                    zIndex={3000}
                >
                    <button
                        {...props}
                        id={location.pathname + 'breadcrumbs' + isFav}
                        data-id={FAV_TOGGLE_BREADCRUMBS}
                        style={isFav ? { color: "#dda407" } : {}}
                        className={`reset-btn btn btn-link ml-2 mdi mdi-star${isFav ? "" : "-outline"}`}
                        type="button"
                    />
                </Tooltip>
            )}
        />
    );
}

export default BreadcrumbsFavBtn;