import * as Sentry from "@sentry/react";
import { jsObjectToApi } from '@spordle/helpers';
import queryString from 'query-string';
import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import API_PUBLIC from '../api/API-Public';
import { AxiosIsCancelled, serverError } from '../api/CancellableAPI';
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';
import { PeriodsContext } from './contexts';
import { RolesContext } from "./RolesContext";
import { DisplayI18n } from '../helpers/i18nHelper';
import { IdentityRolesContext } from "./IdentityRolesContext";
import { fail } from "@spordle/toasts";
import moment from "moment";

/** @type {React.Context<Omit<MembersContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & MembersContextProvider['state']>} */
export const MembersContext = createContext();
MembersContext.displayName = 'MembersContext';

////////////////////////////////////////////////////////////////////////////////////
// Sections (CTRL + F)
// - Member Medical Information
// - Member Contact Types
// - Member Contacts
// - Member Relations
// - Member Memos
// - Member Addresses
// - Member Credit
// - Member Registrations
// - Member Transactions
// - Member Qualifications
// - Member Transfer
// - Member Appeals
// - Member Teams
// - Member Suspensions
// - Medical Severities
// - Medical Types
// - Member Types
// - Member attendees
// - Criminal Record Check
// - Confirm and Status
// - Member Request Changes
// - Member Classification Changes
////////////////////////////////////////////////////////////////////////////////////

class MembersContextProvider extends React.Component{

    state = {
        currentMember: {},
        shouldAttemptAutoConfirm: false,
        shouldAttemptUploadProfilePicDocument: false,
        shouldOpenChangeRequestModal: false,
        shouldOpenClassificationChangeRequestModal: false,
        shouldOpenPermanentReleaseRequestsModal: false,
        shouldOpenPotentialIneligibleModal: false,

        permanentReleaseRequests: [],
        memberSuspensions: [],
        memberCRC: [],
    }

    componentDidUpdate(){
        Sentry.setContext('member', this.state.currentMember.member_id ? {
            memberId: this.state.currentMember.member_id,
            uniqueIdentifier: this.state.currentMember.unique_identifier,
            organizationId: this.state.currentMember.organisation?.organisation_id,
            organizationName: this.state.currentMember.organisation?.organisation_name,
        } : null);
        Sentry.setTag('memberId', this.state.currentMember.member_id);
    }

    presetCurrentMember = (memberData) => {
        return new Promise((resolve) => {
            this.setState(() => ({ currentMember: memberData }), resolve(true))
        })
    }

    setShouldAttemptAutoConfirm = (shouldAttemptAutoConfirm) => this.setState(() => ({ shouldAttemptAutoConfirm: shouldAttemptAutoConfirm }))

    setShouldAttemptUploadProfilePicDocument = (shouldAttemptUploadProfilePicDocument) => this.setState(() => ({ shouldAttemptUploadProfilePicDocument: shouldAttemptUploadProfilePicDocument }))

    setShouldOpenChangeRequestModal = (shouldOpenChangeRequestModal) => this.setState(() => ({ shouldOpenChangeRequestModal: shouldOpenChangeRequestModal }))

    setShouldOpenClassificationChangeRequestModal = (shouldOpenClassificationChangeRequestModal) => this.setState(() => ({ shouldOpenClassificationChangeRequestModal: shouldOpenClassificationChangeRequestModal }))

    setShouldOpenPermanentReleaseRequestsModal = (shouldOpenPermanentReleaseRequestsModal) => this.setState(() => ({ shouldOpenPermanentReleaseRequestsModal: shouldOpenPermanentReleaseRequestsModal }))

    setShouldOpenPotentialIneligibleModal = (shouldOpenPotentialIneligibleModal) => this.setState(() => ({ shouldOpenPotentialIneligibleModal: shouldOpenPotentialIneligibleModal }))

    setPermanentReleaseRequests = (permanentReleaseRequests) => this.setState(() => ({ permanentReleaseRequests: permanentReleaseRequests }))


    emptyCachedInvoiceItems = () => {
        this.setState((prevState) => ({ currentMember: { ...prevState.currentMember, invoiceItems: false, credits: false } }))
    }

    /**
     *
     * @param {string} memberId
     * @param {object} info
     * @returns
     */
    getAllMemberInfos = (memberId, { medicalInformation = false, memos = false, invoices = false, credits = false, externalIdentifiers = false } = {}, orgId) => {
        return this.getMember(memberId)
            .then((member) => {
                if(!member.minimalistic_view){ // If user has access to member's profile
                    return Promise.all([
                        memos ? this.getMemberMemos(memberId) : Promise.resolve(),
                        externalIdentifiers ? this.getExternalIdentifiers(memberId) : Promise.resolve(),
                        // medicalInformation ? this.getMemberMedicalInformation(memberId) : Promise.resolve(),
                    ])
                        .then((promises) => {
                            if(invoices){
                                this.getMemberInvoices(memberId, { organisation_id: orgId })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                            }

                            if(credits){
                                this.getMemberCredits(orgId, { member_id: memberId })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                            }

                            return new Promise((resolve) => {
                                this.setState((prev) => ({
                                    currentMember: {
                                        ...prev.currentMember,
                                        ...member,
                                        addresses: (member.addresses || []),
                                        memos: memos ? promises[0] : prev.currentMember.memos,
                                        externalIdentifiers: memos ? promises[1] : prev.currentMember.externalIdentifiers,
                                    // medicalInformation: medicalInformation ? promises[2] : this.state.currentMember.medicalInformation,
                                    },
                                }), resolve(member))
                            })
                        }, serverError)
                }
                return new Promise((resolve) => {
                    this.setState(() => ({
                        currentMember: {
                            ...member,
                            minimalistic_view: 1,
                        },
                    }), resolve(member))
                })

            })
    }

    /**
     * Get the Members within an organization
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Members/Apicontroller%5CMembers%5CMembers%3A%3AsearchMember|documentation}
     * @returns {Promise.<Array>}
     */
    getMembers = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/members',
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                with_member_status_log: '1',
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    // Lite Format
                    const members = response.data.members || [];
                    const identities = response.data.identities || {};
                    const member_type_statuses = response.data.member_type_statuses || {};

                    return members.map((member) => {
                        member.member_types = (member.member_types || []).map((type) => {
                            if(member_type_statuses[type.member_type_status_id]){
                                type.member_type_status = member_type_statuses[type.member_type_status_id];
                                type.member_type_status.member_type_status_id = type.member_type_status_id;
                            }

                            if(identities[type.decision_made_by]){
                                type.decision_made_by = {
                                    identity_id: type.decision_made_by,
                                    ...identities[type.decision_made_by],
                                };
                            }

                            delete type.member_type_status_id;
                            return type;
                        })

                        return member;
                    });
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the Members within an organization
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Members/Apicontroller%5CMembers%5CMembers%3A%3AsearchMember|documentation}
     * @returns {Promise.<Array>}
     */
    getMembersElasticSearch = (queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: '/public/members/elastic-search',
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.members;
                }
                throw new Error(response.data.errors[0].code);
            }, serverError)
    }

    /**
     * GET - Check member ineligibility status
     * @param {string} member_id
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Members/cda4e55ecb1762cd11fdf6b95e4f2196|documentation}
     * @returns {Promise.<Array>}
     */
    getCheckMemberStatus = (queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/check-member-status`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return { ineligibleCheck: response.data.ineligible_check, linkedMemberInfo: response.data.linked_member_info };
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * [GET] Get the Members within an organization
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Spordle%20Page/79a87696234a9227a949ed5086dabdc8|documentation}
     * @returns {Promise.<Array>}
     * @throws {Error}
     */
    getPublicMembers = (queryParams = {}) => {
        return API_PUBLIC.get(queryString.stringifyUrl({
            url: '/public/members',
            query: Object.assign({
                target_organisation_id: this.props.OrganizationContext.organisation_id,
                organisation_id: this.props.IdentityRolesContext.federation?.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.members;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Get a specific member
    * @param {string} memberId ID of the member to get information from
    * @returns {Promise.<Array>}
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Members/Apicontroller%5CMembers%5CMembers%3A%3AgetMemberInfo|documentation}
    */
    getMember = (memberId, skipSetState = false) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}`,
            query: Object.assign({
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                with_member_status_log: '1',
            }),
        }))
            .then((response) => {
                if(response.data.status){
                    // Lite Format
                    const member = response.data.members || {};
                    const member_type_statuses = response.data.member_type_statuses || {};
                    const identities = response.data.identities || {};
                    const member_fields = response.data.member_fields || {};

                    member.member_request_changes = (response.data.members.member_request_changes || []).map((changeReq) => {
                        if(identities[changeReq.created_by]){
                            changeReq.created_by = {
                                identity_id: changeReq.created_by,
                                ...identities[changeReq.created_by],
                            };
                        }

                        changeReq.data_to_change = changeReq.data_to_change.map((dataPoint) => {
                            if(member_fields[dataPoint.code]){
                                dataPoint.memberField = member_fields[dataPoint.code];
                            }

                            return dataPoint;
                        })

                        return changeReq;
                    });

                    member.member_types = (response.data.members.member_types || []).map((type) => {
                        if(member_type_statuses[type.member_type_status_id]){
                            type.member_type_status = member_type_statuses[type.member_type_status_id];
                            type.member_type_status.member_type_status_id = type.member_type_status_id;
                        }

                        if(identities[type.decision_made_by]){
                            type.decision_made_by = {
                                identity_id: type.decision_made_by,
                                ...identities[type.decision_made_by],
                            }
                        }

                        delete type.member_type_status_id;

                        return type;
                    });

                    member.classification_request_changes = member.classification_request_changes || [];
                    member.createdAsPermanentlyIneligible = member.member_status_logs && member.member_status_logs.length === 1 && member.member_status_logs?.[0]?.member_status?.code == "INELIGIBLE";

                    if(!skipSetState){
                        this.setState((prev) => ({
                            currentMember: {
                                ...prev.currentMember,
                                ...member,
                            },
                        }));
                    }

                    return member;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Get member statements
    * @param {string} memberId ID of the member to get information from
    * @param {string} orgId ID of the organization to get information from
    * @param {string} periodId ID of the period to get information from
    * @returns {Promise}
    * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Members/0e64f4a7e22056cad8e441c1cf800f05|documentation}
    */
    getMemberStatements = (memberId, orgId, periodId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/statements`,
            query: {
                member_id: memberId,
                organisation_id: orgId,
                period_id: periodId,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.statements;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getMemberMemberTypes = (memberId, orgId = false) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/member-types`,
            query: Object.assign({
                organisation_id: orgId || this.props.OrganizationContext.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            }),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_member_types
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Links member types to a member. This is only for SPECIAL member types
     * @param {string} memberId Id of the member we want to add member types to
     * @param {Object} values Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Members/484016025bba272fbac1c1ffe747e5cf|documentation}
     * @returns {Promise}
     */
    linkMemberTypesToMember = (memberId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/members/${memberId}/member-types` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    updateMember = (memberId, values) => {
        const params = new URLSearchParams()
        params.append('first_name', values.firstName);
        params.append('last_name', values.lastName);
        params.append('email', values.email);
        params.append('birthdate', values.dateOfBirth);
        params.append('gender', values.gender);
        params.append('primary_language', values.primaryLanguage);
        if(values.birth_country)
            params.append('birth_country', values.birth_country);
        if(values.secondaryLanguages.length > 0){
            values.secondaryLanguages.forEach((lang, index) => {
                params.append(`secondary_languages[${index}]`, lang)
            })
        }
        params.append('citizenship', values.nationality.toString());
        if(values.outstanding_balance)
            params.append('outstanding_balance', values.outstanding_balance);

        params.append('identify_as_indigenous', values.identifyAsIndigenous);
        if(values.indigenousGroupId)
            params.append('indigenous_group_id', values.indigenousGroupId);
        params.append('ethnicity_id', values.ethnicityId);
        if(values.ethnicityNote)
            params.append('ethnicity_note', values.ethnicityNote)

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `members/${memberId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    partiallyUpdateMember = (memberId, values) => {
        const params = new URLSearchParams()
        const secondaryLanguages = values.secondary_languages || []


        jsObjectToApi(values, params, { skipNull: true });

        if(secondaryLanguages.length > 0){
            secondaryLanguages.forEach((lang, index) => {
                params.append(`secondary_languages[${index}]`, lang)
            })
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `members/${memberId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a Member under an Organization with specified values
     * @param {object} values Values to create a Member
     * @returns {Promise}
     * @see https://api.id.dev.spordle.dev/documentations/#/Members/f92cd223dc7c8759b6a5ec92480ddbaf
     */
    createMember = (values) => {
        const params = new URLSearchParams();

        params.append('organisation_id', values.organizationId);
        params.append('first_name', values.firstName);
        params.append('last_name', values.lastName);
        params.append('address2', values.address2);
        params.append('birthdate', values.dateOfBirth);
        params.append('email', values.email);
        params.append('source', "MANUAL");
        params.append('citizenship', values.nationality.toString());
        params.append('confirmed', !!values.confirmed >>> 0);
        params.append('member_status', values.member_status);

        params.append('gender', values.gender);

        if(values.gender_description && values.gender === 'PREFER_TO_SELF_DESCRIBE'){
            params.append('gender_description', values.gender_description);
        }

        if(values.birth_country){
            params.append('birth_country', values.birth_country);
        }

        params.append('identify_as_indigenous', values.identifyAsIndigenous);

        if(values.indigenousGroupId){
            params.append('indigenous_group_id', values.indigenousGroupId);

            if(values.indigenous_group_note){
                params.append('indigenous_group_note', values.indigenous_group_note);
            }
        }

        params.append('ethnicity_id', values.ethnicityId);

        if(values.ethnicityNote){
            params.append('ethnicity_note', values.ethnicityNote)
        }

        // Languages
        params.append('primary_language', values.primary_language);

        values.secondary_languages?.map((lang, key) => {
            params.append(`secondary_languages[${key}]`, lang);
        });

        if(values.need_address_document !== undefined){
            params.append("need_address_document", values.need_address_document);
        }

        // Google places
        params.append('address[0][default_address]', "1"); // Primary address, will be implemented in interface soon
        params.append('address[0][address_type_id]', values.addressTypeId || ''); // Domicile, secondaire, appartement, bureau, etc
        params.append('address[0][street_number]', values.address.streetNumber || '');
        params.append('address[0][street]', values.address.address || '');
        params.append('address[0][city]', values.address.city || '');
        params.append('address[0][zip_code]', values.address.zipCode || '');
        params.append('address[0][country_division_code]', values.address.state || '');
        params.append('address[0][country_code]', values.address.country || 'CA');
        params.append('address[0][map_url]', values.address.mapsUrl || '');
        params.append('address[0][unit_number]', values.address2 || '');
        params.append('address[0][origin_address]', values.address.origin);
        params.append('address[0][years_same_address]', values.years_same_address);
        params.append('address[0][address_mode]', values.address.addressMode);
        params.append('address[0][po_box]', values.address.POBox);

        if(values.addressNote){
            params.append('address[0][note]', values.addressNote);
        }

        if(values.phone?.phoneNumber){
            params.append('address[0][phones][0][phone_number]', values.phone.phoneNumber);
            params.append('address[0][phones][0][phone_type_id]', values.phone.phoneType);
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `members` }), params)
            .then((response) => {
                if(response.data.status){
                    return {
                        member_id: response.data.member_id,
                        warnings: response.data.warnings,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    inviteMember = (memberId, values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params);

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/members/${memberId}/invite`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return {
                        identity_id: response.data.identity_id,
                        meta_member_id: response.data.meta_member_id,
                        meta_member_member_id: response.data.meta_member_member_id,
                    }
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Invites multiple members to an identity
     * @param {object} values
     * @returns {Promise.<{identity_id: string, meta_member_ids: [], meta_member_member_ids: []}>}
     * @throws {Error}
     * @see Refer to {@link https://api.id.dev.spordle.dev/documentations/#/Members/e221f479389d2bbbfd4d8c5e4f13aedc|documentation}
     */
    inviteMembers = (values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params);

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/members/multiple-invite`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return {
                        identity_id: response.data.identity_id,
                        meta_member_id: response.data.meta_member_ids,
                        meta_member_member_ids: response.data.meta_member_member_ids,
                    }
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete a specific member
     * @param {string} memberId The member id we want to retrive from the db
     * @returns {Promise.<boolean>}
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Members/Apicontroller%5CMembers%5CMembers%3A%3AdeleteMember|documentation}
     */
    deleteMember = (memberId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/members/${memberId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    updateMemberPartial = (memberId, values) => {
        const params = new URLSearchParams()
        jsObjectToApi(values, params)

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `members/${memberId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    updateMemberOutstandingBalance = (memberId, outstandingBalance, orgId) => {
        const params = new URLSearchParams()
        params.append('member_id', memberId);
        params.append('organisation_id', orgId);
        params.append('outstanding_balance', outstandingBalance);

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `member-outstanding-balances` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    deleteMemberOutstandingBalance = (memberOutstandingBalanceId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/member-outstanding-balances/${memberOutstandingBalanceId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PUT] Call to merge two members
     * @param {object} values {@link https://api.id.dev.spordle.dev/documentations/#/Members/Apicontroller%5CMembers%5CMembers%3A%3AMergeMembers|Documentation}
     * @returns {Promise<boolean>}
     */
    mergeMembers = (values) => {
        const params = new URLSearchParams({
            auto_merge: 1,
        });

        params.append('member_to_keep_id', values.member_to_keep_id);
        params.append('member_to_delete_id', values.member_to_delete_id);

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `members/merge` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
    * Get all accounts(identities) of a given member
    * @param {string} memberId ID of the member to get information from
    * @returns {Promise.<Array>}
    * @see Refer to the {@link https://api.id.spordle.dev/documentations|documentation}
    */
    getMemberAccounts = (memberId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `members/${memberId}/accounts` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.identities
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /////////////////////////////////////////////////////////////////////
    // #region Member Medical Information
    /////////////////////////////////////////////////////////////////////

    /**
     * Gets the medical information of a member
     * @param {string} memberId ID of the member to get the medical information from
     * @returns {Promise}
     */
    getMemberMedicalInformation = (memberId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `members/${memberId}/medical-information` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_medical_informations
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a medical information for a member and returns its ID
     * @param {string} memberId ID of the member to create a medical information for
     * @param {Object} values Object containing the values for the creation
     * @param {string} values.typeId ID of the medical type
     * @param {string} values.severityId ID of the medical severity
     * @param {string} values.emergencyAction Action to do in case of an emergency
     * @param {string} [values.notes] Additionnal notes - Optional
     * @returns {Promise}
     */
    createMemberMedicalInformation = (memberId, values) => {
        const params = new URLSearchParams();
        params.append('medical_type_id', values.typeId)
        params.append('medical_severity_id', values.severityId)
        params.append('name', values.emergencyAction)
        params.append('description', values.notes)
        params.append('active', 1)

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `members/${memberId}/medical-information` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_medical_information_id
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a medical information for a member
     * @param {string} memberId ID of the member to update a medical information for
     * @param {string} memberMedicalInformationId ID of the medical information to update
     * @param {Object} values Object containing the values to update - Not all values are mandatory
     * @param {string} [values.typeId] ID of the medical type
     * @param {string} [values.severityId] ID of the medical severity
     * @param {string} [values.emergencyAction] Action to do in case of an emergency
     * @param {string} [values.notes] Additionnal Notes
     */
    updateMemberMedicalInformation = (memberId, memberMedicalInformationId, values) => {
        const params = new URLSearchParams();

        for(const key in values){
            switch (key){
                case 'typeId':
                    params.append('medical_type_id', values[key])
                    break;
                case 'severityId':
                    params.append('medical_severity_id', values[key])
                    break;
                case 'emergencyAction':
                    params.append('name', values[key])
                    break;
                case 'notes':
                    params.append('description', values[key])
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `members/${memberId}/medical-information/${memberMedicalInformationId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    deleteMemberMedicalInformation = (memberId, memberMedicalInformationId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `members/${memberId}/medical-information/${memberMedicalInformationId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Medical Information

    /////////////////////////////////////////////////////////////////////
    // #region Member Attachments
    /////////////////////////////////////////////////////////////////////

    /**
     * [GET] Gets all the member's attachment
     * @param {string} [memberId] ID of the member
     * @param {object} [queryParams] Query params
     * @returns {Promise.<object[]>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Attachments/Apicontroller%5CMembers%5CMemberattachments%3A%3AgetAllMemberAttachments|documentation}
     */
    getMemberAttachments = (memberId = this.state.currentMember.member_id, queryParams = {}) => (
        API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/attachments`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.attachments;
                }
                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * [GET] Gets the download link of a member attachment
     * @param {string} memberId memberId
     * @param {object} attId Member attachment id
     * @returns {Promise.<string>} Returns a string of the download link
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Attachments/Apicontroller%5CMembers%5CMemberattachments%3A%3AdownloadMemberAttachment|documentation}
     */
    getMemberAttachmentDownloadLink = (memberId, attId) => (
        API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/attachments/${attId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return {
                        full_path: response.data.full_path,
                        preview_full_path: response.data.preview_full_path,
                        original_file_mime_type: response.data.original_file_mime_type,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * [POST] Create member attachments
     * @param {string} [memberId] ID of the member
     * @param {object} [queryParams] Query params
     * @returns {Promise.<object[]>}
     * @see https://api.id.dev.spordle.dev/documentations/#/Member%20Attachments/5261fbb2f1097097c477c35cb2d94575
     */
    createMemberAttachments = (memberId = this.state.currentMember.member_id, values) => {
        const params = new FormData();

        params.append('attachments[0][attachment]', values.attachment)
        if(values.document_type_id)
            params.append('attachments[0][document_type_id]', values.document_type_id)
        if(values.note)
            params.append('attachments[0][note]', values.note)

        return API_SPORDLE.post(`members/${memberId}/attachments`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.attachments;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] Partially update a member attachment
     * @param {string} memberId ID of the member
     * @param {string} attachmentId ID of the member
     * @param {object} [values] Query params
     * @returns {Promise.<object[]>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Attachments/Apicontroller%5CMembers%5CMemberattachments%3A%3AupdateMemberAttachmentPartially|documentation}
     */
    partiallyUpdateMemberAttachment = (memberId, attachmentId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(`members/${memberId}/attachments/${attachmentId}`, params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes a member attachment
     * @param {string} memberId ID of the member to get the criminal record checks from
     * @param {string} memberAttId Member Attachment Id
     * @returns {Promise.<boolean>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Attachments/Apicontroller%5CMembers%5CMemberattachments%3A%3AdeleteMemberAttachment|documentation}
     */
    deleteMemberAttachment = (memberId = this.state.currentMember.member_id, memberAttId) => (
        API_SPORDLE.delete(`members/${memberId}/attachments/${memberAttId}`)
            .then((response) => {
                if(response.data.status)return true

                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * [GET] Gets all the member attachments in need of approval
     * @param {object} [queryParams] Query params
     * @returns {Promise.<object[]>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Attachments/Apicontroller%5CMembers%5CMemberattachments%3A%3AgetAllMemberAttachments|documentation}
     */
    getMemberAttachmentsApprobation = (queryParams = {}) => (
        API_SPORDLE.get(queryString.stringifyUrl({
            url: `/member-attachments/approbation`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.approbations.map((approbation) => ({
                        ...approbation,
                        organisation: response.data.organisation?.[approbation.organisation_id],
                        document_type: response.data.document_type?.[approbation.document_type_id],
                    }));
                }
                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * [PUT] Sets the status on an array of member attachments
     * @param {object} [values] the values
     * @returns {Promise.<object[]>}
     */
    updateMemberDocumentStatus = (values) => {
        const params = new URLSearchParams();

        values.forEach((attachment, index) => {
            params.append(`approbations[${index}][member_attachement_id]`, attachment.member_attachement_id);
            params.append(`approbations[${index}][review_status]`, attachment.review_status);

            if(attachment.note)
                params.append(`approbations[${index}][note]`, attachment.note);

            if(attachment.expiration_date)
                params.append(`approbations[${index}][expiration_date]`, attachment.expiration_date);

            if(attachment.reviewed_at)
                params.append(`approbations[${index}][reviewed_at]`, attachment.reviewed_at);

            if(attachment.file_position)
                params.append(`approbations[${index}][file_position]`, attachment.file_position);
        })

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/member-attachments/approbation`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Attachments

    /////////////////////////////////////////////////////////////////////
    // #region Member Criminal Record Check
    /////////////////////////////////////////////////////////////////////

    /**
     * [GET] Gets the criminal record checks of the member
     * @param {string} memberId ID of the member to get the criminal record checks from
     * @param {object} queryParams Query params - Refer to
     * @returns {Promise.<object[]>}
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Criminal%20Record%20Check/Apicontroller%5CMembers%5CMembercriminalrecordchecks%3A%3AgetAllMemberCriminalRecordChecks|documentation}
     */
    getMemberCrcInfo = (memberId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/crc`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    // put the member suspensions in the state so the member profile header can
                    // display the expiration date for valid CRC
                    this.setState(() => ({
                        memberCRC: response.data.member_crcs,
                    }))

                    return response.data.member_crcs;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] gets a member specific criminal record check
     * @param {string} memberId ID of the member to get the criminal record checks from
     * @param {string} crcId ID of the criminal record check
     * @returns {Promise.<object>}
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Criminal%20Record%20Check/Apicontroller%5CMembers%5CMembercriminalrecordchecks%3A%3AgetAllMemberCriminalRecordChecks|documentation}
     */
    getMemberSpecificCrc = (memberId, crcId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/crc/${crcId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_crcs[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Creates a member specific criminal record check
     * @param {string} memberId ID of the member to get the criminal record checks from
     * @param {string} orgId ID of the organisation
     * @param {object} values Values of the creation
     * @param {string} values.background_check_type_id Background check type id
     * @param {string} values.issue_date Issue date
     * @returns {Promise.<boolean>}
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Criminal%20Record%20Check/Apicontroller%5CMembers%5CMembercriminalrecordchecks%3A%3AcreateMemberCriminalRecordCheck|documentation}
     */
    createMemberCrc = (memberId, orgId, values) => {
        const params = new FormData();

        params.append('organisation_id', orgId);
        params.append('period_id', this.props.PeriodsContext.selectedPeriod.period_id);
        params.append('background_check_type_id', values.background_check_type_id);
        params.append('issue_date', values.issue_date);
        params.append('criminal_record_check_status_id', values.criminal_record_check_status_id);
        params.append('member_type_id', values.member_type_id);

        if(values.files){
            values.files.forEach((file, i) => {
                params.append(`attachments[${i}][attachment]`, file);
            });
        }

        return API_SPORDLE.post(`members/${memberId}/crc`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status)
                    return response.data.crc_id;

                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] Partially update a member specific criminal record check
     * @param {string} memberId ID of the member to get the criminal record checks from
     * @param {string} crcId ID of the criminal record check
     * @param {object} values Values to patch
     * @param {string} [values.background_check_type_id] background check type id
     * @param {string} [values.issue_date] Issue date
     * @param {string} [values.expiration_date] expiration date
     * @param {string} [values.status] Status
     * @returns {Promise.<boolean>}
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Criminal%20Record%20Check/Apicontroller%5CMembers%5CMembercriminalrecordchecks%3A%3AupdatePartialMemberCriminalRecordCheck|documentation}
     */
    partiallyUpdateMemberCrc = (memberId, crcId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(`members/${memberId}/crc/${crcId}`, params)
            .then((response) => {
                if(response.data.status)return true;

                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update a member crc
     * @param {string} member_id
     * @param {string} crc_id
     * @param {object} values
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Criminal%20Record%20Check/Apicontroller%5CMembers%5CMembercriminalrecordchecks%3A%3AupdateMemberCriminalRecordCheck|documentation}
     * @returns {Promise}
     */
    updateMemberCRC = (member_id, crc_id, values = {}) => {
        const params = new URLSearchParams();

        params.append('background_check_type_id', values.background_check_type_id)
        params.append('issue_date', values.issue_date)
        params.append('status', values.status)
        params.append('active', values.active)

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `members/${member_id}/crc/${crc_id}`,
        }),
                               params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes a member specific criminal record check
     * @param {string} memberId ID of the member to get the criminal record checks from
     * @param {string} crcId ID of the criminal record check
     * @returns {Promise.<boolean>}
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Criminal%20Record%20Check/Apicontroller%5CMembers%5CMembercriminalrecordchecks%3A%3AdeleteMemberCriminalRecordCheck|documentation}
     */
    deleteMemberCrc = (memberId, crcId) => (
        API_SPORDLE.delete(`members/${memberId}/crc/${crcId}`)
            .then((response) => {
                if(response.data.status)return true

                throw response.data.errors[0];
            }, serverError)
    )

    //#endregion Member Criminal Record Check

    /////////////////////////////////////////////////////////////////////
    // #region Member Contact Types
    /////////////////////////////////////////////////////////////////////

    /**
     * Gets member contact types
     * @param {string} [queryParams] The query params for that call
     * @returns {Promise}
     */
    getMemberContactTypes = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `member-contact-types`,
            query: queryParams,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_contact_types
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Contact Types

    /////////////////////////////////////////////////////////////////////
    // #region Member Contacts
    /////////////////////////////////////////////////////////////////////

    /**
     * Get the contacts for a member
     * @param {string} memberId ID of the member
     * @returns {Promise}
     */
    getMemberContacts = (memberId) => {
        return API_SPORDLE.get(`members/${memberId}/contacts`)
            .then((response) => {
                if(response.data.status){
                    const contacts = response.data.member_contacts?.map((contact) => ({
                        ...contact,
                        phones: contact.member_contact_phone,
                    }))
                    this.setState((prev) => ({
                        currentMember: {
                            ...prev.currentMember,
                            contacts: contacts,
                        },
                    }))
                    return contacts
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates one or multiple contacts for a member
     * @param {string} memberId ID of the member to create contacts for
     * @param {Array<Object>} contacts Array of the contacts to create
     * @returns {Promise}
     */
    createMemberContacts = (memberId, contacts, identityId) => {
        const params = new URLSearchParams();

        if(identityId){
            params.append('identity_id', identityId)
        }
        contacts.forEach((contact, index) => {
            params.append(`contacts[${index}][member_contact_type_id]`, contact.contactType?.memberContactTypeId || '');
            params.append(`contacts[${index}][first_name]`, contact.firstName);
            params.append(`contacts[${index}][last_name]`, contact.lastName);
            params.append(`contacts[${index}][email]`, contact.email || '');
            params.append(`contacts[${index}][emergency]`, !!contact.emergency >>> 0);
            params.append(`contacts[${index}][tax_receipt_recipient]`, !!contact.tax_receipt_recipient >>> 0);
            params.append(`contacts[${index}][allow_communication]`, !!contact.allow_communication >>> 0);

            contact.phones?.forEach((phone, indexPhone) => {
                params.append(`contacts[${index}][phones][${indexPhone}][phone_type_id]`, phone.phoneType.phoneTypeId);
                params.append(`contacts[${index}][phones][${indexPhone}][phone_number]`, phone.phoneNumber);
            })
        })

        return API_SPORDLE.post(`members/${memberId}/contacts`, params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_contact_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    updateMemberContactPartial = (memberId, memberContactId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/members/${memberId}/contacts/${memberContactId}`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    updateMemberContact = async(memberId, memberContactId, contact, canDelete = false) => {
        const params = new URLSearchParams();

        params.append(`member_contact_type_id`, contact.contactType.memberContactTypeId);
        params.append(`first_name`, contact.firstName);
        params.append(`last_name`, contact.lastName);
        params.append(`email`, contact.email || '');
        params.append(`emergency`, !!contact.emergency >>> 0);
        params.append(`tax_receipt_recipient`, !!contact.tax_receipt_recipient >>> 0);
        params.append(`allow_communication`, !!contact.allow_communication >>> 0);

        if(contact.phones && contact.phones.length > 0){
            contact.phones.forEach((phone, indexPhone) => {
                if(phone.memberContactPhoneId){
                    params.append(`phones[${indexPhone}][member_contact_phone_id]`, phone.memberContactPhoneId);
                }
                params.append(`phones[${indexPhone}][phone_type_id]`, phone.phoneType.phoneTypeId);
                params.append(`phones[${indexPhone}][phone_number]`, phone.phoneNumber);
            })
        }else if(canDelete){
            // because PUT below wont delete all the phones numbers if none are sent, you know, as it should :)
            await this.deleteMemberContactPhones(memberId, memberContactId).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        }

        return API_SPORDLE.put(`members/${memberId}/contacts/${memberContactId}`, params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    deleteMemberContactPhones = (memberId, memberContactId) => {
        return API_SPORDLE.delete(`members/${memberId}/contacts/${memberContactId}/phones`)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    deleteMemberContact = (memberId, memberContactId) => {
        return API_SPORDLE.delete(`members/${memberId}/contacts/${memberContactId}`)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Contacts

    /////////////////////////////////////////////////////////////////////
    // #region Member Relations
    /////////////////////////////////////////////////////////////////////

    createMemberRelation = (memberId, relationId, linkedMemberId) => {
        const params = new URLSearchParams();
        params.append('relation_id', relationId);
        params.append('linked_member_id', linkedMemberId);

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `members/${memberId}/relations`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_relation_id
                }
                throw response.data.errors[0];
            }, serverError)
    }

    updateMemberRelation = (memberId, memberRelationId, relationId) => {
        const params = new URLSearchParams();
        params.append('relation_id', relationId);

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `members/${memberId}/relations/${memberRelationId}`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    deleteMemberRelation = (memberId, memberRelationId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `members/${memberId}/relations/${memberRelationId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Relations

    /////////////////////////////////////////////////////////////////////
    // #region Member Memos
    /////////////////////////////////////////////////////////////////////

    /**
     * Gets all the Members Notes (Member Memos)
     * @param {string} memberId The member id we want to retrive from the db
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Memos/Apicontroller%5CMembers%5CMembermemos%3A%3AgetAllMemberMemos|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberMemos = (memberId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/memos`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_memos;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets a specifique Member Note (Member Memos)
     * @param {string} memberId The member id we want to retrive from the db
     * @param {string} memberMemoId The memberMemoId we want to retrive from the db
     * @returns {Promise.<Array>}
     */
    getMemberMemo = (memberId, memberMemoId) => {
        return API_SPORDLE.get(`/members/${memberId}/memos/${memberMemoId}`)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_memos[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets a specifique Member Note (Member Memos)
     * @param {string} memberId The member id we want to retrive from the db
     * @param {string} orgId Organization id of the member
     * @param {object} [values] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Memos/Apicontroller%5CMembers%5CMembermemos%3A%3AcreateMemberMemo|documentation}
     * @returns {Promise.<Array>}
     */
    createMemberMemo = (memberId, orgId, values) => {
        const params = new URLSearchParams();

        params.append('organisation_id', orgId);
        params.append('name', values.name);
        params.append('description', values.description);
        params.append('active', 1);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `members/${memberId}/memos` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_memo_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets a specifique Member Note (Member Memos)
     * @param {string} memberId The member id we want to retrive from the db
     * @param {string} [values.name]
     * @param {string} [values.description]
     * @returns {Promise.<Array>}
     */
    updateMemberMemoPartial = (memberId, memberMemoId, values) => {
        const params = new URLSearchParams();

        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values[key] || '')
                    break;
                case 'description':
                    params.append('description', values[key] || '')
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `members/${memberId}/memos/${memberMemoId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete a specific member
     * @param {string} memberId The member id we want to retrive from the db
     * @returns {Promise.<boolean>}
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Memos/Apicontroller%5CMembers%5CMembermemos%3A%3AdeleteMemberMemo|documentation}
     */
    deleteMemberMemo = (memberId, memberMemoId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/members/${memberId}/memos/${memberMemoId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Memos

    /////////////////////////////////////////////////////////////////////
    // #region Member Addresses
    /////////////////////////////////////////////////////////////////////

    getMemberAddresses = (memberId, queryParams) => {
        const params = new URLSearchParams();
        if(queryParams)
            params.append('with_address_status_log', queryParams.with_address_status_log)

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `members/${memberId}/addresses`,
            query: queryParams,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_addresses
                }
                throw response.data.errors[0];
            }, serverError)
    }

    createMemberAddress = (memberId, values) => {
        const params = new URLSearchParams()
        jsObjectToApi(values, params)

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `members/${memberId}/addresses`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_address_id
                }
                throw response.data.errors[0];
            }, serverError)
    }

    deleteMemberAddress = (memberId, memberAddressId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `members/${memberId}/addresses/${memberAddressId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    updateMemberAddress = (memberId, memberAddressId, values) => {
        const params = new URLSearchParams();

        params.append('address_type_id', values.addressTypeId)
        params.append('unit_number', values.address2)
        params.append('street_number', values.address.streetNumber)
        params.append('street', values.address.address)
        params.append('origin_address', 'GOOGLE')
        params.append('map_url', values.address.mapsUrl)
        params.append('country_code', values.address.country)
        params.append('country_division_code', values.address.state)
        params.append('city', values.address.city)
        params.append('zip_code', values.address.zipCode)
        params.append('default_address', 1)
        params.append('active', 1)

        values.phones.forEach((phone, indexPhone) => {
            if(phone.memberAddressPhoneId){
                params.append(`phones[${indexPhone}][member_contact_phone_id]`, phone.memberAddressPhoneId);
            }
            params.append(`phones[${indexPhone}][phone_type_id]`, phone.phoneTypeId);
            params.append(`phones[${indexPhone}][phone_number]`, phone.phoneNumber);
        })

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `members/${memberId}/addresses/${memberAddressId}`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    updateAllMemberAddresses = (memberId, values) => {
        const params = new URLSearchParams();

        // let addrPhoneIndex = 0;
        values.addresses.forEach((address, indexAddress) => {
            if(address.memberAddressId){
                params.append(`address[${indexAddress}][member_address_id]`, address.memberAddressId);
            }
            // if(address.defaultAddress == 1) {
            //     addrPhoneIndex = indexAddress;
            // }
            params.append(`address[${indexAddress}][address_type_id]`, address.addressTypeId);
            if(address.years_same_address)
                params.append(`address[${indexAddress}][years_same_address]`, moment(address.years_same_address).format('YYYY'));
            params.append(`address[${indexAddress}][unit_number]`, address.address2);
            params.append(`address[${indexAddress}][street_number]`, address.streetNumber);
            params.append(`address[${indexAddress}][street]`, address.address);
            params.append(`address[${indexAddress}][origin_address]`, address.origin);
            params.append(`address[${indexAddress}][map_url]`, address.mapsUrl);
            params.append(`address[${indexAddress}][country_code]`, address.country);
            params.append(`address[${indexAddress}][country_division_code]`, address.state);
            params.append(`address[${indexAddress}][city]`, address.city);
            params.append(`address[${indexAddress}][zip_code]`, address.zipCode || address.zip);
            params.append(`address[${indexAddress}][default_address]`, address.defaultAddress);
            params.append(`address[${indexAddress}][active]`, 1);

            address.phones.forEach((phone, indexPhone) => {
                if(phone.memberAddressPhoneId){
                    params.append(`address[${indexAddress}][phones][${indexPhone}][member_address_phone_id]`, phone.memberAddressPhoneId);
                }

                params.append(`address[${indexAddress}][phones][${indexPhone}][phone_type_id]`, phone.phoneTypeId);
                params.append(`address[${indexAddress}][phones][${indexPhone}][phone_number]`, phone.phoneNumber);
            })
        })

        // Address[0] because it cannot be deleted
        // values.phones.forEach((phone, indexPhone) => {
        //     // if (phone.memberAddressPhoneId) {
        //     //     params.append(`address[0][phones][${indexPhone}][member_address_phone_id]`, phone.memberAddressPhoneId);
        //     // }
        //     params.append(`address[${addrPhoneIndex}][phones][${indexPhone}][phone_type_id]`, phone.phoneTypeId);
        //     params.append(`address[${addrPhoneIndex}][phones][${indexPhone}][phone_number]`, phone.phoneNumber);
        // })

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `members/${memberId}/addresses`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    deleteMemberAddressPhone = (memberId, memberAddressId, memberAddressPhoneId) => {
        return API_SPORDLE.delete(`members/${memberId}/addresses/${memberAddressId}/phones/${memberAddressPhoneId}`)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    deleteAllMemberAddressPhone = (memberId, memberAddressId) => {
        return API_SPORDLE.delete(`members/${memberId}/addresses/${memberAddressId}/phones`)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    updateMemberAddressPartial = (member_id, member_address_id, values) => {
        const params = new URLSearchParams()
        jsObjectToApi(values, params)

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/members/${member_id}/addresses/${member_address_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];

            }, serverError)
    }

    //#endregion Member Addresses


    /////////////////////////////////////////////////////////////////////
    // #region Member Credit
    /////////////////////////////////////////////////////////////////////

    /**
     * Get member credit by organisation id
     * @param {string} organisation_id
     * @param {object} [queryParams]
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Credits/Apicontroller%5CMemberCredits%5CMembercredits%3A%3AgetAllCredits|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberCredits = (organisation_id, queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/member-credits',
            query: Object.assign({
                organisation_id: organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this.setState((prevState) => ({ currentMember: {
                        ...prevState.currentMember,
                        credits: response.data.member_credits,
                    } }))
                    return response.data.member_credits;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Assign credit to a member
     * @param {string} member_id
     * @param {object} values
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Credits/Apicontroller%5CMemberCredits%5CMembercredits%3A%3AcreateMemberCredit|documentation}
     * @returns {Promise}
     */
    createMemberCredit = (member_id, values = {}) => {
        const params = new URLSearchParams();

        params.append('organisation_id', values.organisation_id)
        params.append('credit_type_id', values.credit_type_id)
        params.append('amount', values.amount)
        params.append('expiration_date', values.expiration_date)
        params.append('active', values.active)
        params.append('note', values?.note)

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/members/${member_id}/credits` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_credit_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update member assigned credit by member credit id
     * @param {string} member_id
     * @param {string} member_credit_id
     * @param {Object} values
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Credits/Apicontroller%5CMemberCredits%5CMembercredits%3A%3AupdatePatchMemberCredit|documentation}
     * @returns {Promise}
     */
    updateMemberCredit = (member_id, member_credit_id, values = {}) => {
        const params = new URLSearchParams();

        params.append('amount', values.amount)
        params.append('expiration_date', values.expiration_date)
        params.append('active', values.active)

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/members/${member_id}/credits/${member_credit_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Delete member credit by member credit id
    * @param {string} member_id
    * @param {string} member_credit_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Credits/Apicontroller%5CMemberCredits%5CMembercredits%3A%3AdeleteMemberCredit|documentation}
    * @returns {Promise}
    */
    deleteMemberCredit = (member_id, member_credit_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `members/${member_id}/credits/${member_credit_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Get refund info for member credit
    * @param {string} organisation_id
    * @param {Object} params
    * @returns {Promise}
    */
    getRefundCreditInfo = (organisation_id, params = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `member-credits/refunds`,
            query: Object.assign({
                organisation_id: organisation_id,
            }, params),
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_credit_refunds;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Get refund info for member credit
    * @param {string} organisation_id
    * @param {Object} params
    * @returns {Promise}
    */
    getRefundAmounts = (invoice_number, params = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/invoices/${invoice_number}/available-to-refund`,
            query: Object.assign({}, params),
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.available_amount_to_refund;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Refund a credit
    * @param {string} member_credit_id
    * @param {Object} values
    * @returns {Promise}
    */
    refundMemberCredit = (member_credit_id, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `member-credits/${member_credit_id}/refunds` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Credit

    /////////////////////////////////////////////////////////////////////
    // #region Member picture
    /////////////////////////////////////////////////////////////////////

    /**
     * [POST] Adds a picture to a member account
     * @param {string} memberId Id of the member
     * @param {object} values Values of the picture to post
     * @param {string} [dataUrl] temp file for immediate change in state
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Members/Apicontroller%5CMembers%5CMembers%3A%3AupdateMemberPicture|documentation}
     * @returns {Promise.<Array>}
     */
    createMemberPicture = (memberId, values, dataUrl) => {
        // https://developer.mozilla.org/en-US/docs/Web/API/FormData/FormData
        const params = new FormData();

        params.append('picture', values.picture);
        params.append('file_position', values.file_position);

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `members/${memberId}/pictures`,
        }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    this.setState((prev) => ({ currentMember: { ...prev.currentMember, picture: { ...response.data.logo, full_path: dataUrl || response.data.logo?.full_path, file_position: values.file_position } } }));

                    return response.data.logo;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member picture

    /////////////////////////////////////////////////////////////////////
    // #region Member Registrations
    /////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Gets all member invoices
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Invoices/Apicontroller%5CInvoices%5CInvoices%3A%3AgetInvoices|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberInvoices = (memberId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/invoices`,
            query: Object.assign({
                organisation_id: this.state.currentMember?.organisation?.organisation_id, // Will be undefined if we refresh the page. getAllMemberInfos will retrigger with good org_id
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                member_id: memberId,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this.setState((prevState) => ({ currentMember: {
                        ...prevState.currentMember,
                        invoices: response.data.invoices,
                    } }))
                    return response.data.invoices;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     *
     * @param {string} invoiceNumber Invoice number to update
     * @param {Object} values Object containing the values to update
     * @param {string} values.street Street
     * @param {string} values.street_number street number
     * @param {string} values.country_id ID of the country
     * @param {string} values.province_id ID of the province
     * @param {string} values.city City
     * @param {string} values.zip_code Zip code
     * @param {string} values.phone_number Phone number
     * @returns
     */
    updateMemberInvoice = (invoiceNumber, values) => {
        const params = new URLSearchParams();

        for(const key in values){
            if(Object.hasOwnProperty.call(values, key)){
                const value = values[key];
                switch (key){
                    case 'street':
                        params.append('street', value);
                        break;
                    case 'street_number':
                        params.append('street_number', value);
                        break;
                    case 'country_id':
                        params.append('country_id', value);
                        break;
                    case 'province_id':
                        params.append('province_id', value);
                        break;
                    case 'city':
                        params.append('city', value);
                        break;
                    case 'zip_code':
                        params.append('zip_code', value);
                        break;
                    case 'phone_number':
                        params.append('phone_number', value);
                        break;
                    default:
                        params.append(key, value);
                        break;
                }
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/invoices/${invoiceNumber}/information`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     *
     * @param {string} invoiceNumber Invoice number to update
     * @param {Object} values Object containing the values to update
     * @throws {Error}
     * @returns {Promise.<boolean>}
     */
    updateInvoiceInstallments = (invoiceNumber, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/invoices/${invoiceNumber}/installments-batch`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Gets all member forms
     * @param {string} member_id
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Custom%20Forms/Apicontroller%5CMembers%5CCustomforms%3A%3AgetCustomFormAnswers|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberForms = (memberId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/custom-forms`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.custom_forms;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] - Partially updates a custom form answer
     * @param {string} memberId Id of the member
     * @param {string} formId Id of the custom form
     * @param {string} answerId Id of the custom form field answer to update
     * @param {object} values The values for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Custom%20Forms/Apicontroller%5CMembers%5CCustomforms%3A%3AgetCustomFormAnswers|documentation}
     * @returns {Promise.<Array>}
     */
    updatePartiallyForm = (memberId, formId, answerId, values) => {
        const params = new URLSearchParams();

        if(values.custom_form_field_option_selected) params.append('custom_form_field_option_selected', values.custom_form_field_option_selected);
        if(values.answer) params.append('answer', values.answer);

        params.append('active', "1");

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/members/${memberId}/custom-forms/${formId}/answers/${answerId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PUT] - Updates a custom form
     * @param {string} memberId Id of the member
     * @param {string} formId Id of the custom form to update
     * @param {object} values The values for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Custom%20Forms/Apicontroller%5CMembers%5CCustomforms%3A%3AgetCustomFormAnswers|documentation}
     * @returns {Promise.<Array>}
     */
    updateForm = (memberId, formId, values) => {
        const params = new URLSearchParams();

        params.append('invoice_item_id', values.invoice_item_id);

        // jsObjectToApi(postData, params, {skipNull: true, skipEmptyString: true});

        values.fields.forEach((field, i) => {
            params.append(`fields[${i}][custom_form_field_id]`, field.custom_form_field_id);

            if(field.custom_form_field_option_selected){
                params.append(`fields[${i}][custom_form_field_option_selected]`, field.custom_form_field_option_selected);
            }

            params.append(`fields[${i}][answer]`, field.answer || ''); // this is probably the culprit
            params.append(`fields[${i}][active]`, 1);
        })

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/members/${memberId}/custom-forms/${formId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Refund an invoice
     * @param {string} invoiceNumber
     * @param {object} [postData] The post data - Refer to the {@link https://api.id.spordle.dev/documentations/#/Invoices/Apicontroller%5CFinances%5CPayments%3A%3Arefunds|documentation}
     * @returns {Promise.<boolean>}
     */
    refundInvoice = (invoiceNumber, postData) => {
        const data = new URLSearchParams();
        data.append('organisation_id', this.props.OrganizationContext.organisation_id);
        jsObjectToApi(postData, data, { skipNull: true, skipEmptyString: true });
        if(process.env.REACT_APP_ENVIRONMENT !== 'prod')
            data.append('test_mode', 1);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/invoices/${invoiceNumber}/refunds` }), data)
            .then((response) => {
                if(response.data.status){
                    return response.data.refund_id;
                }
                if(response.data.provider_return_data){
                    throw{
                        message: response.data.provider_return_data.message,
                        i18n: {},
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] - Delete a refund
     * @param {string} invoiceNumber Invoice number
     * @param {string} invoicePaymentId Invoice payment ID
     * @returns {Promise}
     */
    deleteRefund = (invoiceNumber, invoicePaymentId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `invoices/${invoiceNumber}/refunds/${invoicePaymentId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     *
     * @param {string} invoicePaymentId Invoice payment ID to update
     * @param {Object} values Object containing the values to update. Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Invoices/Apicontroller%5CFinances%5CPayments%3A%3AupdateExternalInstallment|documentation}
     * @returns {Promise.<boolean>}
     */
    updateInvoicePayment = (invoicePaymentId, values) => {
        const data = new URLSearchParams();
        jsObjectToApi(values, data, { skipNull: true, skipEmptyString: true })

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/payments/${invoicePaymentId}` }), data)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Retry an installment
     * @param {string} invoicePaymentId Invoice payment ID to update. Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Invoices/Apicontroller%5CFinances%5CPayments%3A%3AupdateExternalInstallmentRetry|documentation}
     * @returns {Promise.<boolean>}
     */
    retryInvoicePayment = (invoicePaymentId) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/payments/${invoicePaymentId}/retry` }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Process a payment
     * @param {string} invoicePaymentId Invoice payment ID to update. Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Invoices/f940cf8bce85a804f4ace4320660bcc6|documentation}
     * @returns {Promise.<boolean>}
     */
    processPayment = (invoicePaymentId) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/payments/${invoicePaymentId}/process-payment` }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Refund an invoice
     * @param {string} invoiceNumber
     * @returns {Promise.<boolean>}
     */
    resendInvoice = (invoiceNumber, withReceipt) => {
        const data = new URLSearchParams();

        invoiceNumber.forEach((iNumber, index) => {
            //data.append(`invoice_number[${index}]`, iNumber);
            data.append(`invoices[${index}][invoice_number]`, iNumber);
            data.append(`invoices[${index}][with_receipt]`, !!withReceipt === true ? 1 : 0);
        })

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/invoices/send-invoices-email` }), data)
            .then((response) => {
                if(response.data.status){
                    return response.data.refund_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Cancel an invoice item
     * @param {string} invoiceItemId
     * @param {string} [reason] A reason for the cancellation
     * @see The {@link https://api.id.spordle.dev/documentations/#/Invoices%20Items/Apicontroller%5CFinances%5CInvoiceItems%3A%3AcancelInvoiceItem|documentation}
     * @returns {Promise.<boolean>}
     */
    cancelInvoiceItem = (invoiceItemId, reason) => {
        const data = new URLSearchParams({ cancellation_reason: reason });
        return API_SPORDLE.patch(`/invoice-items/${invoiceItemId}/cancel`, data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete an invoice item's cancellation
     * @param {string} invoiceItemId
     * @see The {@link https://api.id.spordle.dev/documentations/#/Invoices%20Items/Apicontroller%5CFinances%5CInvoiceItems%3A%3AdeleteInvoiceItemCancellation|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteInvoiceItemCancellation = (invoiceItemId) => {
        return API_SPORDLE.delete(`/invoice-items/${invoiceItemId}/cancel`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Confirm an invoice item
     * @param {string} invoiceItemId
     * @see The {@link https://api.id.spordle.dev/documentations/#/Invoices%20Items/Apicontroller%5CFinances%5CInvoiceItems%3A%3AconfirmInvoiceItem|documentation}
     * @returns {Promise.<boolean>}
     */
    confirmInvoiceItem = (invoiceItemId) => {
        return API_SPORDLE.patch(`/invoice-items/${invoiceItemId}/confirm`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete an invoice item's confirmation
     * @param {string} invoiceItemId
     * @see The {@link https://api.id.spordle.dev/documentations/#/Invoices%20Items/Apicontroller%5CFinances%5CInvoiceItems%3A%3AdeleteInvoiceItemConfirmation|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteInvoiceItemConfirm = (invoiceItemId) => {
        return API_SPORDLE.delete(`/invoice-items/${invoiceItemId}/confirm`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a payment reception for a transaction
     * @param {string} invoiceNumber Invoice number
     * @param {Object} postData The post data - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Payment%20Receptions/Apicontroller%5CFinances%5CPaymentreceptions%3A%3AreceivePayment|documentation}
     * @returns
     */
    createPaymentReception = (invoiceNumber, postData) => {
        const data = new URLSearchParams();
        data.append('organisation_id', this.props.OrganizationContext.organisation_id);
        jsObjectToApi(postData, data, { skipNull: true, skipEmptyString: true });

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/invoices/${invoiceNumber}/payment-receptions` }), data)
            .then((response) => {
                if(response.data.status){
                    return response.data.payment_reception_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a payment reception (partial)
     * @param {string} invoiceNumber Invoice number
     * @param {string} paymentReceptionId Payment reception ID
     * @param {Object} values The values - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Payment%20Receptions/cdca28a9a86c98d54711941ce967df0f|documentation}
     * @returns {Promise}
     */
    updatePaymentReception = (invoiceNumber, paymentReceptionId, values) => {
        const data = new URLSearchParams();
        jsObjectToApi(values, data, { skipNull: true });

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/invoices/${invoiceNumber}/payment-receptions/${paymentReceptionId}` }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a payment reception
     * @param {string} invoiceNumber Invoice number
     * @param {Object} paymentReceptionId The payment reception ID - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Payment%20Receptions/7641f1b3bb77d3022db7d93f1166fe1b|documentation}
     * @returns {Promise}
     */
    deletePaymentReception = (invoiceNumber, paymentReceptionId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/invoices/${invoiceNumber}/payment-receptions/${paymentReceptionId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getWaivers = (memberId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `members/${memberId}/waivers`,
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.waivers;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getInvoiceDues = (invoiceNumber) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `invoices/${invoiceNumber}/amounts-to-receive`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.amounts_to_receive;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getAvailableToBeFunded = (invoiceNumber, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/invoices/${invoiceNumber}/amount-to-be-funded`,
            query: Object.assign({
                member_id: queryParams.memberId,
            }),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.available_amount_to_be_funded;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Registrations

    /////////////////////////////////////////////////////////////////////
    //#region Member Qualifications
    /////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Gets all member qualifications
     * @param {string} member_id
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Member%20Qualifications/Apicontroller%5CMembers%5CMemberqualifications%3A%3AgetAllMemberQualifications|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberQualifications = (memberId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/qualifications`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_qualifications;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a member qualification for a specific member
     * @param {string} memberId ID of the member to add a qualification to
     * @param {Object} values Object containing the data for the creation of a member qualification
     * @param {string} values.periodId ID of the period
     * @param {string} values.qualificationId ID of the qualification
     * @param {string} values.validationDate Date when the qualification whould be valid (YYYY-MM-DD)
     * @param {string} [values.expirationDate] Date when the qualification should expire (YYYY-MM-DD) - OPTIONNAL
     * @param {string} [values.certificationNumber] String representing the certification number - OPTIONNAL
     * @param {string} [values.location] Location where the qualification has been done - OPTIONNAL
     * @param {string} [values.city] City where the qualification has been done - OPTIONNAL
     * @param {string} [values.province] Province where the clinic has been done - OPTIONNAL
     * @param {string} [values.memo] Memo - OPTIONNAL
     * @returns {Promise}
     */
    createMemberQualification = (memberId, { periodId, qualificationId, validationDate, expirationDate, certificationNumber, location, city, province, clinicAttendeeId, memo }) => {
        const params = new URLSearchParams();
        params.append('period_id', periodId);
        params.append('qualification_id', qualificationId);
        params.append('passed_date', validationDate);
        if(expirationDate)
            params.append('expiration_date', expirationDate);
        if(certificationNumber)
            params.append('certification_number', certificationNumber);
        if(location)
            params.append('location', location);
        if(city)
            params.append('city', city);
        if(province)
            params.append('province', province);
        if(clinicAttendeeId)
            params.append('clinic_attendee_id', clinicAttendeeId);
        if(memo)
            params.append('memo', memo);

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/members/${memberId}/qualifications`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_qualification_id
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Partially updates a member qualification
     * @param {string} memberId ID of the member
     * @param {string} memberQualificationId ID of the member qualification to update
     * @param {Object} values Object containing the data for the update of a member qualification
     * @param {string} [values.validationDate] Date when the qualification whould be valid (YYYY-MM-DD) - OPTIONNAL
     * @param {string} [values.expirationDate] Date when the qualification should expire (YYYY-MM-DD) - OPTIONNAL
     * @param {string} [values.certificationNumber] String representing the certification number - OPTIONNAL
     * @param {string} [values.memo] Memo - OPTIONNAL
     * @returns {Promise}
     */
    updateMemberQualificationPartial = (memberId, memberQualificationId, values) => {
        const params = new URLSearchParams();

        for(const key in values){
            if(Object.hasOwnProperty.call(values, key)){
                const value = values[key];
                switch (key){
                    case 'validationDate':
                        params.append('passed_date', value);
                        break;
                    case 'expirationDate':
                        params.append('expiration_date', value);
                        break;
                    case 'certificationNumber':
                        params.append('certification_number', value);
                        break;
                    case 'memo':
                        params.append('memo', value);
                        break;
                    default:
                        params.append(key, value);
                        break;
                }
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/members/${memberId}/qualifications/${memberQualificationId}`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Gets all member attendees
     * @param {string} member_id
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Members/cda4e55ecb1762cd11fdf6b95e4f2196|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberAttendees = (memberId, queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/attendees`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_attendees;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Trasnfer the qualification to the given member
     * @param {string} memberId
     * @param {string} memberQualificationId
     * @param {object} values The params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Qualifications/91d9d6d9fd257cd6a296742f1e97d47f|documentation}
     * @returns {Promise.<string>}
     */
    moveQualificationTransfer = (memberId, memberQualificationId, values) => {
        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/members/${memberId}/qualifications/${memberQualificationId}/transfer`,
        }), new URLSearchParams(values))
            .then((response) => {
                if(response.data.status){
                    return response.data.new_member_qualification_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Share the qualification to the given member
     * @param {string} memberId
     * @param {string} memberQualificationId
     * @param {object} values The params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Qualifications/2c05154118db13c5144c4ad9a749f723|documentation}
     * @returns {Promise.<string>}
     */
    moveQualificationShare = (memberId, memberQualificationId, values) => {
        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/members/${memberId}/qualifications/${memberQualificationId}/share`,
        }), new URLSearchParams(values))
            .then((response) => {
                if(response.data.status){
                    return response.data.new_member_qualification_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Certify the qualification for the given member
     * @param {string} memberId
     * @param {string} memberQualificationId
     * @param {object} values The params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Qualifications/79fd75602e13383bb36630ef0c3f4cf7|documentation}
     * @returns {Promise.<string>}
     */
    certify = (memberId, memberQualificationId, values) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/members/${memberId}/qualifications/${memberQualificationId}/certify`,
        }), new URLSearchParams(values))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Qualifications

    /////////////////////////////////////////////////////////////////////
    //#region Member Transfer
    /////////////////////////////////////////////////////////////////////

    /**
     * Creates a member transfer request
     * @param {string} member_id
     * @param {object} [params] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfers/Apicontroller%5CMembers%5CMembertransfers%3A%3AcreateMemberTransfer|documentation}
     * @returns {Promise.<string>}
     */
    createMemberTransferRequest = (memberId, params = {}) => {
        const apiParams = new FormData();

        for(const key in params){
            if(Object.hasOwnProperty.call(params, key)){
                const value = params[key];
                switch (key){
                    case 'attachments':
                        value.forEach((file, index) => {
                            apiParams.append(`attachments[${index}][attachment]`, file.attachment);
                            apiParams.append(`attachments[${index}][active]`, file.active);
                            if(file.documentTypeId){
                                apiParams.append(`attachments[${index}][document_type_id]`, file.documentTypeId);
                            }
                        })
                        break;
                    default:
                        apiParams.append(key, value);
                        break;
                }
            }
        }

        return API_SPORDLE.post(`/members/${memberId}/transfers`, apiParams, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.member_transfer_id;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Creates a member manual (bypass) transfer request
     * @param {string} memberId
     * @param {object} [params] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfers/Apicontroller%5CMembers%5CMembertransfers%3A%3AcreateMemberTransferWithoutWorkflow|documentation}
     * @returns {Promise.<string>}
     */
    createMemberManualTransferRequest = (memberId, params = {}) => {
        const apiParams = new FormData();

        for(const key in params){
            if(Object.hasOwnProperty.call(params, key)){
                const value = params[key];
                switch (key){
                    case 'attachments':
                        value.forEach((file, index) => {
                            apiParams.append(`attachments[${index}][attachment]`, file.attachment);
                            apiParams.append(`attachments[${index}][active]`, file.active);
                            if(file.documentTypeId){
                                apiParams.append(`attachments[${index}][document_type_id]`, file.documentTypeId);
                            }
                        })
                        break;
                    default:
                        apiParams.append(key, value);
                        break;
                }
            }
        }

        return API_SPORDLE.post(`/members/${memberId}/transfers-bypass`, apiParams, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.member_transfer_id;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Creates an international member transfer request
     * @param {string} member_id
     * @param {object} [params] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfers/Apicontroller%5CMembers%5CMembertransfers%3A%3AcreateMemberTransferInternational|documentation}
     * @returns {Promise.<string>}
     */
    createMemberInternationalTransferRequest = (memberId, params = {}) => {
        const apiParams = new FormData();

        for(const key in params){
            if(Object.hasOwnProperty.call(params, key)){
                const value = params[key];
                switch (key){
                    case 'attachments':
                        value.forEach((file, index) => {
                            apiParams.append(`attachments[${index}][attachment]`, file.attachment);
                            apiParams.append(`attachments[${index}][active]`, file.active);
                        })
                        break;
                    default:
                        apiParams.append(key, value);
                        break;
                }
            }
        }

        return API_SPORDLE.post(`/members/${memberId}/transfers-international`, apiParams, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.member_transfer_id;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Creates a member transfer request
     * @param {string} member_id
     * @param {object} [params] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfers/Apicontroller%5CMembers%5CMembertransfers%3A%3AcreateMemberTransfer|documentation}
     * @returns {Promise.<string>}
     */
    createMemberTransferCTIRequest = (memberId, params = {}) => {
        const apiParams = new FormData();

        for(const key in params){
            if(Object.hasOwnProperty.call(params, key)){
                const value = params[key];
                switch (key){
                    case 'attachments':
                        value.forEach((file, index) => {
                            apiParams.append(`attachments[${index}][attachment]`, file.attachment);
                            apiParams.append(`attachments[${index}][active]`, file.active);
                            if(file.documentTypeId){
                                apiParams.append(`attachments[${index}][document_type_id]`, file.documentTypeId);
                            }
                        })
                        break;
                    default:
                        apiParams.append(key, value);
                        break;
                }
            }
        }

        return API_SPORDLE.post(`/members/${memberId}/transfers-international`, apiParams, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.member_transfer_id;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Get all member transfer requests
     * @param {string} memberId
     * @param {object} [params] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfers/Apicontroller%5CMembers%5CMembertransfers%3A%3AgetAllMemberTransfers|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberTransfers = (memberId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/transfers`,
            query: {
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_transfers;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update partially a member transfer
     * @param {string} memberId
     * @param {string} memberTransferId
     * @param {object} values The values for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfers/53383e844d29f682d7e43a2ee3b813ad documentation}
     * @returns {Promise}
     */
    updateMemberTransferPartial = (memberId, memberTransferId, values = {}) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params)

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/members/${memberId}/transfers/${memberTransferId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    deleteMemberTransferShare = (memberId, memberTransferId) => {
        return API_SPORDLE.delete(`/members/${memberId}/transfers/${memberTransferId}/share`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get all Member Transfer Approbation States
     * @param {string} memberId
     * @param {string} memberTransferId
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfer%20Approbation%20States/Apicontroller%5CMembers%5CMembertransferapprobationstates%3A%3AgetAllMemberTransferApprobationStates documentation}
     * @returns {Promise.<Array>}
     */
    getMemberTransfersStates = (memberId, memberTransferId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/transfers/${memberTransferId}/states`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_transfer_approbation_states;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the full workflow from a specific member transfer
     * @param {string} memberId
     * @param {string} memberTransferId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfers/Apicontroller%5CMembers%5CMembertransfers%3A%3AgetMemberTransferWorkflow|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberTransfersWorkflow = (memberId, memberTransferId) => {
        return API_SPORDLE.get(`/members/${memberId}/transfers/${memberTransferId}/workflow`)
            .then((response) => {
                if(response.data.status){
                    return response.data.workflow;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Approves or decline a transfer step
     * @param {string} memberId
     * @param {string} memberTransferId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfer%20Approbation%20States/cb6a6e2a4e19574234688ab332f499c5|documentation}
     * @returns {Promise.<string>}
     */
    createMemberTransfersStates = (memberId, memberTransferId, params = {}) => {
        const data = new URLSearchParams({
            organisation_id: params.organisation_id || this.props.OrganizationContext.organisation_id,
            step: params.step,
            state: params.state,
        });

        if(params.details){
            data.append('details', params.details);
        }

        return API_SPORDLE.post(`/members/${memberId}/transfers/${memberTransferId}/states`, data)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_transfer_approbation_state_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Override Approves or decline a transfer step
     * @param {string} memberId
     * @param {string} memberTransferId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfer%20Approbation%20States/cb9b184c3d90b27ec1c76178b3a02d8f|documentation}
     * @returns {Promise.<string>}
     */
    createMemberTransfersStatesOverride = (memberId, memberTransferId, params = {}) => {
        const data = new URLSearchParams({
            organisation_id: params.organisation_id || this.props.OrganizationContext.organisation_id,
            state: params.state,
        });

        if(params.details){
            data.append('details', params.details);
        }

        return API_SPORDLE.post(`/members/${memberId}/transfers/${memberTransferId}/override`, data)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_transfer_approbation_state_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the member transfer attachments
     * @param {string} memberId
     * @param {string} memberTransferId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Transfer%20Attachments/Apicontroller%5CMembers%5CTransferattachments%3A%3AgetAllTransferAttachments|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberTransferAttachments = (memberId, memberTransferId) => {
        return API_SPORDLE.get(`/members/${memberId}/transfers/${memberTransferId}/attachments`)
            .then((response) => {
                if(response.data.status){
                    return response.data.attachments;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the downloadable link for the attachment
     * @param {string} memberId
     * @param {string} memberTransferId
     * @param {string} memberTransferAttachmentId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfers/Apicontroller%5CMembers%5CMembertransfers%3A%3AgetMemberTransferWorkflow|documentation}
     * @returns {Promise.<string>}
     */
    downloadMemberTransferAttachment = (memberId, memberTransferId, memberTransferAttachmentId) => {
        return API_SPORDLE.get(`/members/${memberId}/transfers/${memberTransferId}/attachments/${memberTransferAttachmentId}`)
            .then((response) => {
                if(response.data.status){
                    return {
                        full_path: response.data.full_path,
                        preview_full_path: response.data.preview_full_path,
                        original_file_mime_type: response.data.original_file_mime_type,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create a member transfer attachment
     * @param {string} memberId
     * @param {string} memberTransferId
     * @param {Array} attachments
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Transfer%20Attachments/Apicontroller%5CMembers%5CTransferattachments%3A%3AcreateTransferAttachment|documentation}
     * @returns {Promise.<Array>}
     */
    createMemberTransferAttachment = (memberId, memberTransferId, attachments) => {
        const params = new FormData();
        attachments.forEach((file, index) => {
            if(file.documentTypeId){
                params.append(`attachments[${index}][document_type_id]`, file.documentTypeId);
            }
            params.append(`attachments[${index}][attachment]`, file.attachment);
            params.append(`attachments[${index}][active]`, file.active);
        })

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/members/${memberId}/transfers/${memberTransferId}/attachments` }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.transfer_attachments;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete a member transfer attachment
     * @param {string} memberId
     * @param {string} memberTransferId
     * @param {string} memberTransferAttachmentId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Transfer%20Attachments/Apicontroller%5CMembers%5CTransferattachments%3A%3AdeleteTransferAttachment|documentation}
     * @returns {Promise.<Array>}
     */
    deleteMemberTransferAttachment = (memberId, memberTransferId, memberTransferAttachmentId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/members/${memberId}/transfers/${memberTransferId}/attachments/${memberTransferAttachmentId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create a member transfer comment
     * @param {string} memberId
     * @param {string} memberTransferId
     * @param {object} values
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfer%20Comments/Apicontroller%5CMembers%5CMembertransfercomments%3A%3AcreateMemberTransferComment|documentation}
     * @returns {Promise.<string>}
     */
    createTransferComment = (memberId, memberTransferId, values) => {
        const data = new URLSearchParams(values);
        return API_SPORDLE.post(`/members/${memberId}/transfers/${memberTransferId}/comments`, data)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_transfer_comment_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get member transfer comments
     * @param {string} memberId
     * @param {string} memberTransferId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfer%20Comments/Apicontroller%5CMembers%5CMembertransfercomments%3A%3AgetAllMemberTransferComments documentation}
     * @returns {Promise.<Array>}
     */
    getTransferComments = (memberId, memberTransferId) => {
        return API_SPORDLE.get(`/members/${memberId}/transfers/${memberTransferId}/comments`)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_transfer_comments;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get member transfer details
     * @param {string} memberId
     * @param {string} memberTransferId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfers/Apicontroller%5CMembers%5CMembertransfers%3A%3AgetSpecificMemberTransfers documentation}
     * @returns {Promise}
     */
    getTransfer = (memberId, memberTransferId) => {
        return API_SPORDLE.get(`/members/${memberId}/transfers/${memberTransferId}`)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_transfers[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Transfer

    /////////////////////////////////////////////////////////////////////
    //#region Member Discriminations
    /////////////////////////////////////////////////////////////////////
    /**
     * Gets all the member discriminations
     * @param {string} memberId Member ID
     * @param {Object} queryParams The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Discriminations/Apicontroller%5COrganisations%5CDiscriminations%3A%3AgetAllDiscriminationsByMemberId|documentation}
     * @returns {Promise.<object[]>}
     * @throws {Error}
     */
    getMemberDiscriminations = (memberId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/discriminations`,
            query: queryParams,
        }))
            .then((response) => {
                if(response.data.status){
                    const {
                        attachments,
                        categories,
                        classes,
                        discrimination_statuses,
                        discriminations,
                        divisions,
                        document_types,
                        identities,
                        maltreatment_locations,
                        maltreatment_types,
                        member_suspensions,
                        members,
                        outcomes,
                        organisations,
                        periods,
                        suspensions,
                        teams,
                    } = response.data;
                    // *** SAME FORMATTING IS PRESENT IN: getMemberDiscriminations, getDiscriminations, getDiscriminationSpecific
                    return discriminations.map((discrimination) => {
                        discrimination.away_team = teams[discrimination.away_team_id];
                        delete discrimination.away_team_id;

                        discrimination.discrimination_attachments?.map((discrimination_attachment) => {
                            discrimination_attachment.attachment = attachments[discrimination_attachment.attachment_id];
                            delete discrimination_attachment.attachment_id;

                            discrimination_attachment.document_type = document_types[discrimination_attachment.document_type_id];
                            delete discrimination_attachment.document_type_id;
                        })

                        discrimination.complaint_by_member = members[discrimination.complaint_by_id];
                        delete discrimination.complaint_by_id;
                        if(discrimination.complaint_by_member){
                            discrimination.complaint_by_member.organisation = organisations[discrimination.complaint_by_member.organisation_id];
                            delete discrimination.complaint_by_member.organisation_id;
                        }

                        discrimination.discrimination_member.map((member) => {
                            member.member = members[member.member_id];
                            member.team = members[member.team_id];

                            if(member.team){
                                const category = categories[member.team.team_category_id];


                                if(category){
                                    category.division = divisions[category.division_id];
                                    category.class = classes[category.class_id];
                                }

                                member.team.category = category;
                            }

                            if(member.member){
                                member.member.organisation = organisations[member.member.organisation_id];
                                delete member.member.organisation_id;
                            }
                        })

                        discrimination.discrimination_status = discrimination_statuses[discrimination.discrimination_status_id];
                        delete discrimination.discrimination_status_id;

                        discrimination.home_team = teams[discrimination.home_team_id];
                        delete discrimination.home_team_id;

                        discrimination.identity = identities[discrimination.identity_id];
                        delete discrimination.identity_id;

                        discrimination.maltreatment_location = maltreatment_locations[discrimination.maltreatment_location_id];
                        delete discrimination.maltreatment_location_id;

                        discrimination.maltreatment_member_suspensions?.map((suspension) => {
                            suspension.linked_by = identities[suspension.linked_by];

                            suspension.member_suspension = member_suspensions[suspension.member_suspension_id];
                            delete suspension.member_suspension_id;

                            suspension.member_suspension.member = members[suspension.member_suspension.member_id];
                            delete suspension.member_suspension.member_id;

                            suspension.member_suspension.suspension = suspensions[suspension.member_suspension.suspension_id];
                            delete suspension.member_suspension.suspension_id;

                            suspension.member_suspension.organisation = organisations[suspension.member_suspension.organisation_id];
                            delete suspension.member_suspension.organisation_id;
                        })

                        discrimination.maltreatment_outcomes?.map((outcome) => {
                            outcome.created_by = identities[outcome.created_by];
                            outcome.updated_by = identities[outcome.updated_by];

                            outcome.outcome = outcomes[outcome.outcome_id];
                            delete outcome.outcome_id;
                        })

                        discrimination.maltreatment_type = maltreatment_types[discrimination.maltreatment_type_id];
                        delete discrimination.maltreatment_type_id;

                        discrimination.organisation = organisations[discrimination.organisation_id];
                        delete discrimination.organisation_id;

                        discrimination.period = periods[discrimination.period_id];
                        delete discrimination.period_id;

                        return discrimination;
                    });
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Discriminations

    /////////////////////////////////////////////////////////////////////
    //#region Member Appeals
    /////////////////////////////////////////////////////////////////////

    /**
     * Gets all the member appeals
     * @param {string} memberId Member ID
     * @param {Object} queryParams The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Appeals/Apicontroller%5CMembers%5CMemberappeals%3A%3AgetAllMemberAppeals|documentation}
     * @returns
     */
    getMemberAppeals = (memberId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/appeals`,
            query: queryParams,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.appeals
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets appeal regulations
     * @param {string} organization_id Organization ID
     * @param {Object} queryParams The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Appeal%20Regulations/52cf643a523a88d530159d64290dc1fa|documentation}
     * @returns {Promise}
     */
    getAppealRegulations = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/appeal-regulations`,
            query: {
                ...queryParams,
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.appeal_regulation
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a member appeal
     * @param {string} memberId
     * @param {object} [params] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Appeals/Apicontroller%5CMembers%5CMemberappeals%3A%3AcreateAppeals|documentation}
     * @returns {Promise.<string>}
     */
    createMemberAppeal = (memberId, params = {}) => {
        const apiParams = new FormData();

        for(const key in params){
            if(Object.hasOwnProperty.call(params, key)){
                const value = params[key];
                switch (key){
                    case 'attachments':
                        value.forEach((file, index) => {
                            apiParams.append(`attachments[${index}][attachment]`, file.attachment);
                            apiParams.append(`attachments[${index}][active]`, file.active);
                            apiParams.append(`attachments[${index}][type]`, file.type);
                        })
                        break;
                    default:
                        apiParams.append(key, value);
                        break;
                }
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/members/${memberId}/appeals`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }), apiParams, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.member_appeal_id;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Update partially a member appeal
     * @param {string} memberId
     * @param {string} memberAppealId
     * @param {object} values The values for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Appeals/Apicontroller%5CMembers%5CMemberappeals%3A%3AupdatePatchAppeals|documentation}
     * @returns {Promise.<Array>}
     */
    updateMemberAppealPartial = (memberId, memberAppealId, values = {}) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params)

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/members/${memberId}/appeals/${memberAppealId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete a member appeal
     * @param {string} memberId
     * @param {string} memberAppealId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Appeals/Apicontroller%5CMembers%5CMemberappeals%3A%3AdeleteSpecificAppeals|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteMemberAppeal = (memberId, memberAppealId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/members/${memberId}/appeals/${memberAppealId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the member appeal attachments
     * @param {string} memberAppealId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Appeal%20Attachments/Apicontroller%5CMembers%5CMemberappealattachments%3A%3AgetAllMemberAppealAttachments|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberAppealAttachments = (memberAppealId) => {
        return API_SPORDLE.get(`/appeals/${memberAppealId}/attachments`)
            .then((response) => {
                if(response.data.status){
                    return response.data.attachments;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the downloadable link for the attachment
     * @param {string} memberAppealId
     * @param {string} memberAppealAttachmentId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Appeal%20Attachments/Apicontroller%5CMembers%5CMemberappealattachments%3A%3AdownloadMemberAppealAttachment|documentation}
     * @returns {Promise.<string>}
     */
    downloadMemberAppealAttachment = (memberAppealId, memberAppealAttachmentId) => {
        return API_SPORDLE.get(`/appeals/${memberAppealId}/attachments/${memberAppealAttachmentId}`)
            .then((response) => {
                if(response.data.status){
                    return {
                        full_path: response.data.full_path,
                        preview_full_path: response.data.preview_full_path,
                        original_file_mime_type: response.data.original_file_mime_type,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create a member appeal attachment
     * @param {string} memberAppealId
     * @param {Array} attachments
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Appeal%20Attachments/Apicontroller%5CMembers%5CMemberappealattachments%3A%3AcreateMemberAppealAttachment|documentation}
     * @returns {Promise.<Array>}
     */
    createMemberAppealAttachment = (memberAppealId, attachments) => {
        const params = new FormData();
        attachments.forEach((file, index) => {
            params.append(`attachments[${index}][attachment]`, file.attachment);
            params.append(`attachments[${index}][active]`, file.active);
            params.append(`attachments[${index}][type]`, file.type);
        })

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/appeals/${memberAppealId}/attachments` }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.member_appeal_attachments;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete a member appeal attachment
     * @param {string} memberAppealId
     * @param {string} memberAppealAttachmentId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Appeal%20Attachments/Apicontroller%5CMembers%5CMemberappealattachments%3A%3AdeleteSpecificMemberAppealAttachement|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteMemberAppealAttachment = (memberAppealId, memberAppealAttachmentId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/appeals/${memberAppealId}/attachments/${memberAppealAttachmentId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Appeals

    /////////////////////////////////////////////////////////////////////
    //#region Member Teams
    /////////////////////////////////////////////////////////////////////

    /**
     * Get the member teams
     * @param {string} memberId
     * @param {object} [params={}]
     * @returns {Promise.<Array>}
     */
    getMemberTeams = (memberId, params = {}) => {
        return API_SPORDLE.get(
            queryString.stringifyUrl(
                {
                    url: `/members/${memberId}/teams`,
                    query: params,
                },
                {
                    arrayFormat: 'comma',
                    skipEmptyString: true,
                    skipNull: true,
                },
            ),
        )
            .then((response) => {
                if(response.data.status){
                    return response.data.member_teams;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the member teams
     * @param {string} memberId
     * @param {string} teamId
     * @param {object} values
     * @returns {Promise.<Array>}
     */
    setMemberPrimaryTeam = (memberId, teamId, values = {}) => {
        const params = new URLSearchParams()
        jsObjectToApi(values, params)

        params.append('period_id', this.props.PeriodsContext.selectedPeriod.period_id);

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/members/${memberId}/primary-team/${teamId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Teams

    /////////////////////////////////////////////////////////////////////
    //#region Member Share
    /////////////////////////////////////////////////////////////////////

    /**
     * Get all member shared orgs
     * @param {string} memberId
     * @returns {Promise.<object[]>}
     */
    getMemberShare = (memberId) => {
        return API_SPORDLE.get(`/members/${memberId}/share`)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_organisation_share;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Share

    /////////////////////////////////////////////////////////////////////
    //#region Member Suspensions
    /////////////////////////////////////////////////////////////////////

    /**
     * Gets all the member suspensions
     * @param {string} memberId Member ID
     * @param {Object} queryParams The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Suspensions/Apicontroller%5CMembers%5CMembersuspensions%3A%3AgetAllMemberSuspensions|documentation}
     * @returns {Promise<Array>}
     */
    getMemberSuspensions = (memberId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/member-suspensions`,
            query: queryParams,
        }))
            .then((response) => {
                if(response.data.status){
                    // Lite Format
                    const member_suspensions = response.data.member_suspensions || [];
                    const member_types = response.data.member_types || {};

                    // Build resources
                    const fullMemberSuspensions = member_suspensions.map((suspension) => {
                        if(member_types[suspension.member_type_id]){
                            suspension.member_type = member_types[suspension.member_type_id];
                            suspension.member_type.member_type_id = suspension.member_type_id;
                        }

                        // Cleanup resource IDs
                        delete suspension.member_type_id;

                        return suspension;
                    });

                    // put the member suspensions in the state so the member profile header can
                    // display the suspension for member types
                    this.setState(() => ({
                        memberSuspensions: fullMemberSuspensions,
                    }))

                    return fullMemberSuspensions
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a member suspension
     * @param {string} memberId
     * @param {object} [params] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Suspensions/Apicontroller%5CMembers%5CMembersuspensions%3A%3AcreateMemberSuspension|documentation}
     * @returns {Promise.<string>}
     */
    createMemberSuspension = (memberId, params = {}) => {
        const apiParams = new FormData();

        for(const key in params){
            if(Object.hasOwnProperty.call(params, key)){
                const value = params[key];
                switch (key){
                    case 'attachments':
                        value.forEach((file, index) => {
                            apiParams.append(`attachments[${index}][attachment]`, file.attachment);
                            apiParams.append(`attachments[${index}][active]`, file.active);
                        })
                        break;
                    default:
                        if(value){
                            apiParams.append(key, value);
                        }
                        break;
                }
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/members/${memberId}/member-suspensions`,
        }), apiParams, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.member_suspension_id;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Update partially a member suspension
     * @param {string} memberId
     * @param {string} memberSuspensionId
     * @param {object} values The values for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Suspensions/Apicontroller%5CMembers%5CMembersuspensions%3A%3AupdatePatchMemberSuspension|documentation}
     * @returns {Promise.<Array>}
     */
    updateMemberSuspensionPartial = (memberId, memberSuspensionId, values = {}) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params)

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/members/${memberId}/member-suspensions/${memberSuspensionId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete a member appeal
     * @param {string} memberId
     * @param {string} memberSuspensionId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Suspensions/Apicontroller%5CMembers%5CMembersuspensions%3A%3AdeleteMemberSuspension|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteMemberSuspension = (memberId, memberSuspensionId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/members/${memberId}/member-suspensions/${memberSuspensionId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the member suspension attachments
     * @param {string} memberId
     * @param {string} memberSuspensionId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Suspension%20Attachments/Apicontroller%5CMembers%5CMembersuspensionattachments%3A%3AgetAllMemberSuspensionAttachments|documentation}
     * @returns {Promise.<Array>}
     */
    getMemberSuspensionAttachments = (memberId, memberSuspensionId) => {
        return API_SPORDLE.get(`/members/${memberId}/suspensions/${memberSuspensionId}/attachments`)
            .then((response) => {
                if(response.data.status){
                    return response.data.attachments;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the downloadable link for the attachment
     * @param {string} memberId
     * @param {string} memberSuspensionId
     * @param {string} memberSuspensionAttachmentId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Suspension%20Attachments/Apicontroller%5CMembers%5CMembersuspensionattachments%3A%3AdownloadMemberSuspensionAttachment|documentation}
     * @returns {Promise.<string>}
     */
    downloadMemberSuspensionAttachment = (memberId, memberSuspensionId, memberSuspensionAttachmentId) => {
        return API_SPORDLE.get(`/members/${memberId}/suspensions/${memberSuspensionId}/attachments/${memberSuspensionAttachmentId}`)
            .then((response) => {
                if(response.data.status){
                    return {
                        full_path: response.data.full_path,
                        preview_full_path: response.data.preview_full_path,
                        original_file_mime_type: response.data.original_file_mime_type,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create a member suspension attachment
     * @param {string} memberId
     * @param {string} memberSuspensionId
     * @param {Array} attachments
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Suspension%20Attachments/Apicontroller%5CMembers%5CMembersuspensionattachments%3A%3AcreateMemberSuspensionAttachments|documentation}
     * @returns {Promise.<Array>}
     */
    createMemberSuspensionAttachment = (memberId, memberSuspensionId, attachments) => {
        const params = new FormData();
        attachments.forEach((file, index) => {
            params.append(`attachments[${index}][attachment]`, file.attachment);
            params.append(`attachments[${index}][active]`, file.active);
        })

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/members/${memberId}/suspensions/${memberSuspensionId}/attachments` }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.member_suspension_attachments;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete a member suspension attachment
     * @param {string} memberId
     * @param {string} memberSuspensionId
     * @param {string} memberSuspensionAttachmentId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Suspension%20Attachments/Apicontroller%5CMembers%5CMembersuspensionattachments%3A%3AdeleteMemberSuspensionAttachment|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteMemberSuspensionAttachment = (memberId, memberSuspensionId, memberSuspensionAttachmentId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/members/${memberId}/suspensions/${memberSuspensionId}/attachments/${memberSuspensionAttachmentId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Get the member suspension letter PDF
    * @param {string} memberSuspensionId
    * @returns {Promise.<Array>}
    */
    getMemberSuspensionLetter = (memberSuspensionId) => {
        return API_SPORDLE.post(`/members/member-suspensions/${memberSuspensionId}`)
            .then((response) => {
                if(response.data.status){
                    return response.data?.letter_link;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Member Suspensions


    /////////////////////////////////////////////////////////////////////
    // #region Medical Severities
    /////////////////////////////////////////////////////////////////////
    getMedicalSeverities = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `medical-severities`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.medical_severities
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Medical Severities

    /////////////////////////////////////////////////////////////////////
    // #region Medical Types
    /////////////////////////////////////////////////////////////////////
    getMedicalTypes = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `medical-types`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.medical_types
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Medical Types

    /////////////////////////////////////////////////////////////////////
    // #region Member Types
    /////////////////////////////////////////////////////////////////////
    /**
     * Get all the Member Types within an organization
     * @param {string} organizationId The organization id we want to get the Member Types from
     * @returns {Promise<Array>}
     */
    getMemberTypes = (organizationId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `member-types`, query: { organisation_id: organizationId, ...queryParams } }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get Member Type information
     * @param {string} memberTypeId
     * @returns {Promise}
     */
    getMemberType = (memberTypeId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `member-types/${memberTypeId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_types[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get Member Type linked forms
     * @param {string} memberTypeId
     * @param {Object} query Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Types/1df447f467a96463e10152548880ac0e|documentation}
     * @returns {Promise}
     */
    getMemberTypeForms = (memberTypeId, query) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `member-types/${memberTypeId}/forms`,
            query: Object.assign({
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, query),
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_type_custom_forms[0];
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Deletes a link between a member type and a form.
     * @param {string} memberTypeId The member type id from the link we would like to remove
     * @param {string} formId The form id from the link we would like to remove
     * @param {string} [periodId] The period id from the link we would like to remove
     * @returns {Promise}
     */
    deleteFormMemberTypeLink = (memberTypeId, formId, periodId) => {
        return API_SPORDLE.delete(`/member-types/${memberTypeId}/forms/${formId}/period/${periodId || this.props.PeriodsContext.selectedPeriod.period_id}`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError);
    }

    /**
     * Get Member Type linked waivers
     * @param {string} memberTypeId
     * @param {Object} query Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Types/07cf7d859a9be087ab249f506ddd927a|documentation}
     * @returns {Promise}
     */
    getMemberTypeWaivers = (memberTypeId, query) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `member-types/${memberTypeId}/waivers`,
            query: Object.assign({
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, query),
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_type_waivers;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get all the Member Status within an organization
     * @param {string} organizationId The organization id we want to get the Member Types from
     * @returns {Promise<Array>}
     */
    getMemberStatus = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `member-status`, query: { organisation_id: organizationId } }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_status;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a link between waivers and a member type
     * @param {string} memberTypeId The member type id we want to link to waivers
     * @param {Object} values organisation_id, period_id and waivers[]
     * @returns {Promise}
     */
    linkMemberTypeWaivers = (memberTypeId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params)

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/member-types/${memberTypeId}/waivers` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Updates a specified Member Type with new values
     * @param {string} memberTypeId ID of the Member Type to update
     * @param {object} values Object containing the values to update - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Types/0429d0c314bdfd4595ebed97a2ad686b|documentation}
     * @returns {Promise}
     */
    updateMemberType = (memberTypeId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params)

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `member-types/${memberTypeId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a Member Type under an Organization with specified values
     * @param {string} organizationId ID of the Organization to add a Member Type to
     * @param {object} values Values to create a Member Type with - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Types/4d927384a28b7ff28a9865b02544127b|documentation}
     * @returns {Promise}
     */
    createMemberType = (organizationId, values) => {
        const params = new URLSearchParams();

        params.append('organisation_id', organizationId);

        for(const key in values){
            switch (key){
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `member-types` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_type_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific Member Type
     * @param {string} memberTypeId ID of the Member Type to delete
     * @returns {Promise}
     */
    deleteMemberType = (memberTypeId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `member-types/${memberTypeId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Member Types

    /////////////////////////////////////////////////////////////////////
    // #region Member Attendees
    /////////////////////////////////////////////////////////////////////

    /**
     * @param {string} memberId
     * @param {object} qualificationParams
     * @param {object} attendeesParams
     * @returns {Promise.<[object[],object[]]>}
     */
    getQualificationsAndAttendees = (memberId, qualificationParams = {}, attendeesParams = {}) => {
        return Promise.all([
            this.getMemberQualifications(memberId, qualificationParams)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                }),
            this.getMemberAttendees(memberId, attendeesParams)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                }),
        ])
    }
    // #endregion Member Attendees

    /////////////////////////////////////////////////////////////////////
    // #region Member Communications
    /////////////////////////////////////////////////////////////////////
    // getMemberCommunications = (memberId) => {

    // }

    //#endregion Member Communications

    /////////////////////////////////////////////////////////////////////
    // #region Void
    /////////////////////////////////////////////////////////////////////

    /**
     * Void an invoice
     * @param {string} invoiceNumber
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Invoices/3812ea119b10ad00a73294dd9e4cf2c7|documentation}
     * @returns {Promise}
     */
    voidInvoice = (invoiceNumber) => {
        return API_SPORDLE.put(queryString.stringifyUrl({ url: `invoices/${invoiceNumber}/void` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    // #endregion Void

    /////////////////////////////////////////////////////////////////////
    // #region Confirm and Status
    /////////////////////////////////////////////////////////////////////

    /**
     * Confirm members
     * @param {Array} memberIds
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Members/96bf1ddc692d1159d63616e56d3a3cb2|documentation}
     * @returns {Promise}
     */
    confirmMembers = (memberIds) => {
        const params = new URLSearchParams();
        for(let i = 0; i < memberIds.length; i++){
            const memberId = memberIds[i];
            params.append(`members[${i}]`, memberId);
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `members/confirm` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the Member statuses
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Members%20Status/7e6b5a78acf4b8d1a9651c3f59641d7f|documentation}
     * @returns {Promise.<Array>}
     */
    getStatuses = () => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/member-status',
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_status;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * patch Member statuses
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Members%20Status/7e6b5a78acf4b8d1a9651c3f59641d7f|documentation}
     * @param {string} [member_id] Id of the member for which we are updating the status
     * @param {string} [member_status] the new status
     * @param {string} [note] note if we are blocking the user
     * @returns {Promise.<Array>}
     */
    patchMemberStatus = (member_id, member_status, note, member_status_reason_id, organisation_deciding) => {

        const params = new URLSearchParams();
        params.append('member_status', member_status);
        params.append('note', note || '');
        params.append('member_status_reason_id', member_status_reason_id || '');
        if(organisation_deciding)
            params.append('organisation_deciding', organisation_deciding);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/members/${member_id}/status` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }


    // #endregion Confirm

    /////////////////////////////////////////////////////////////////////
    // #region Meta members
    /////////////////////////////////////////////////////////////////////

    /**
     * Creates a meta member for the given identity
     * @param {string} memberId Member ID to create a meta member for
     * @param {string} identityId Identity ID to create a meta member for
     * @returns {Promise<string>}
     */
    createMetaMember = (memberId, identityId) => {
        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/members/${memberId}/accounts/${identityId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.meta_member_id
                }
                throw response.data.errors[0];
            }, serverError)
    }

    // #endregion Meta members


    /////////////////////////////////////////////////////////////////////
    // #region External Identifiers
    /////////////////////////////////////////////////////////////////////

    /**
     * Creates a meta member for the given identity
     * @param {string} memberId Member ID to create a meta member for
     * @param {string} identityId Identity ID to create a meta member for
     * @returns {Promise<string>}
     */
    getExternalIdentifiers = (memberId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/external-identifiers`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.external_identifiers
                }
                throw response.data.errors[0];
            }, serverError)
    }

    // #endregion External Identifiers
    render(){
        return (
            <MembersContext.Provider value={{ ...this.state, ...this }}>
                {this.props.children}
            </MembersContext.Provider>
        );
    }
}

export default withContexts(OrganizationContext, PeriodsContext, RolesContext, IdentityRolesContext)(MembersContextProvider);
