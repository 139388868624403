import { FormikDateTime } from "@spordle/formik-elements"
import { useFormikContext } from "formik"
import { FormGroup } from "reactstrap"
import { filterHasValue, formikCodesHaveValues, handleFilterChange } from "../reportsEngineHelpers"
import FilterLabel from "./FilterLabel"

const EngineTime = ({ filter, filterRefs }) => {
    const formik = useFormikContext()
    return (
        <FormGroup>
            <FilterLabel filter={filter} />
            <FormikDateTime
                {...filter.parsedParams?.componentProps}
                id={filter.code}
                name={filter.code}
                placeholder='HH:MM'
                translatePlaceholder={false}
                value={formik.values[filter.code]}
                timeFormat
                dateFormat={false}
                inputProps={{ disabled: !formikCodesHaveValues(filter.parsedParams?.enabledWhen, formik) || !filterHasValue(filter.parsedParams?.enabledWhenValue?.filter, filter.parsedParams?.enabledWhenValue?.value, formik) }}
                className={`w-100 ${filter.parsedParams?.componentProps?.className}`}
                onChange={(value, spordleSelect) => handleFilterChange(filter, formik, value, filterRefs)}
            />
        </FormGroup>
    )
}

export default EngineTime