export default{
    // Review
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.review.title"         : "Edit payment option(s)",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.review.saveChanges"   : "Save changes",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.review.cancelChanges" : "Cancel changes",

    // fees
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.title"            : "Clinic Items",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.amount"     : "Amount",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.taxes"      : "Taxes",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.total"      : "Total",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.optional"   : "Mandatory",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.hint.none1" : "You can add clinic items.",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.hint.none2" : "Add an item",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.empty"      : "No clinic items",

    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.title"           : "Add clinic item",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.fee"             : "Clinic item",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.fee.noOptions"   : "No clinic items",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.fee.placeholder" : "Select a clinic item",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.fee.required"    : "Item is required",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.optional"        : "Mandatory",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.create"          : "Create and add an item",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.cancelCreate"    : "Cancel the item creation",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.duplicate"       : "This item is already added",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.creation"        : "Something went wrong when creating the clinic item",

    // payment methods
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.title"                   : "Payment method(s)",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.validation.mandatoryFee" : "At least one mandatory clinic item is required.",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.validation.online"       : "An online payment method is required if no prepayment method is specified.",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.validation.prepaid"      : "At least one prepayment method is required if no online payment method is specified.",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.online.title"            : "Online payment",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.merchant.validation"     : "Please select an online payment option",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.merchant.none"           : "No online payment options available, please contact support.",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.merchant.placeholder"    : "Select an online payment option",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.merchant.noOptions"      : "No online payment options available",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.other.title"             : "Prepayment method(s)",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.CHEQUE"                  : "Cheque",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.CASH"                    : "Cash",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.CREDITCARD"              : "Credit card",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.PAYMENTPROXY"            : "Credit card (payment proxy)",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.INTERAC"                 : "Interac transfer",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.FUNDING"                 : "Funding",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.minimum"                 : "Check at least one payment method",
}