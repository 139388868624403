import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import EmptyLayout from "../../../components/table/EmptyLayout";
import { I18nContext } from "../../../contexts/I18nContext";
import { ReportsContext } from "../../../contexts/ReportsContext";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import ReportExports from "../exports/ReportExports";
import queryString from 'query-string';
import ReportHeader from "../../../components/reports/ReportHeader";
import ReportsDashboardFilterCards from "./ReportsDashboardFilterCards";
import ConfirmModal from "../../../components/confirmModal/ConfirmModal";
import { fail, success } from "@spordle/toasts";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import { stringBuilder } from "@spordle/helpers";
import Skeleton from "react-loading-skeleton";
import { Tooltip } from '@mantine/core';
import { useReportsLiteSWR } from "../../../components/reports/useReportsSWR";

const ReportsDashboard = () => {
    const history = useHistory();
    const location = useLocation();
    const urlCategory = queryString.parse(location.search).category;
    const i18nContext = useContext(I18nContext);
    const reportsContext = useContext(ReportsContext);

    /**  @type {React.MutableRefObject<SpordleTableProvider>} */
    const tableRef = useRef();

    useEffect(() => {
        const currentGroupCode = tableRef.current.getFilters().groupFilter.groupCode;
        tableRef.current.filterChange('groupFilter', { groupCode: urlCategory || (currentGroupCode === 'exports' ? 'ALL' : currentGroupCode) }, false)
    }, [ urlCategory ]);

    const { data, error, mutate } = useReportsLiteSWR();

    useEffect(() => {
        if(error){
            tableRef.current.setError(error);
        }else if(Array.isArray(data)){
            tableRef.current.setData(data);
        }
    }, [ data, error ])

    return (
        <SpordleTableProvider
            id="reportsDashboardTable"
            ref={tableRef}
            searchKeys={[
                `i18n.${i18nContext.getGenericLocale()}.name`,
                `i18n.${i18nContext.getGenericLocale()}.description`,
                'name',
            ]}
            defaultSorting={`+i18n.${i18nContext.getGenericLocale()}.name`}
            columnSorting={(reportA, reportB) => {
                const genericLocale = i18nContext.getGenericLocale();
                return new Intl.Collator(genericLocale, { sensitivity: 'base' })
                    .compare(
                        reportA.i18n?.[genericLocale]?.name || reportA.name,
                        reportB.i18n?.[genericLocale]?.name || reportB.name,
                    );
            }}
            filterJSCallback={(report, { groupFilter }) => {
                if(groupFilter.groupCode === 'CUSTOM'){
                    return report.is_custom == 1;
                }else if(groupFilter.groupCode === 'exports'){
                    return false// Don't show any reports when exports
                }
                return groupFilter.groupCode === 'ALL' ? true : report.type === groupFilter.groupCode;
            }}
            initFilter={{
                groupFilter: {
                    groupCode: queryString.parse(location.search).category || 'ALL',
                },
            }}
            viewMode='GRID'
            dataIndex="code"
            defaultData={data}
            loadingStatus={Array.isArray(data) ? 'success' : 'loading'}
            loadData={(from, { filters }) => {
                switch (from){
                    case 'FILTER':
                        if(filters.groupFilter.groupCode === 'exports'){
                            history.replace(queryString.stringifyUrl({
                                url: location.pathname,
                                query: {
                                    category: 'exports',
                                },
                                fragmentIdentifier: location.hash.split('#')[1],
                            }));
                        }else{
                            history.replace(queryString.stringifyUrl({
                                url: location.pathname,
                                fragmentIdentifier: location.hash.split('#')[1],
                            }));
                        }
                        break;
                    default:
                        break;
                }
            }}
            pagination={50}
            gridConfig={{
                col: {
                    sm: '12',
                },
                gridRender: (report, spordleTable) => {
                    const parameter = JSON.parse(report.parameter);
                    const LinkComponent = ({ children, ...props }) => {
                        return (
                            report.component ?
                                /* This is for custom report components: for now only used for printing the registration form, the report
                                    contains a component in the reportGroups file this group will have to be appended when we receive the groups from the API */
                                <report.component {...props}>{children}</report.component>
                                :
                                <>
                                    {report.deprecated == 1 ?
                                        <div {...props}>{children}</div>
                                        :
                                        <Link
                                            {...props}
                                            to={{
                                                pathname: `/reports/${parameter.route}`,
                                                search: report.is_custom == 1 ? `?custom_report_id=${report.custom_report_id}` : null,
                                                state: { report: report },
                                            }}
                                        >
                                            {children}
                                        </Link>
                                    }
                                </>
                        )
                    }

                    return (
                        <Card
                            key={report.code}
                            className={stringBuilder('card-shadow mb-1 p-0 d-flex align-items-center flex-row', { 'bg-light': report.deprecated == 1, 'clickable card-hover': report.deprecated != 1 })}
                        >
                            <LinkComponent className="pl-3 py-3 flex-grow-1">
                                <div className={stringBuilder("text-primary font-medium d-flex", { 'text-body': report.deprecated == 1 })}>
                                    {((report.summary_report || report.detailed_report) || report.is_custom == 1) &&
                                        <div className="mr-2 text-muted">
                                            {(report.summary_report || report.detailed_report) &&
                                                <IconToolTipSummaryDetailed code={report.code} />
                                            }
                                            {(report.is_custom == 1 && report.deprecated == 0) &&
                                                <IconCustomReportIcon code={report.code} />
                                            }
                                            {(report.is_custom == 1 && report.deprecated == 1) &&
                                                <IconDeprecatedCustomReportIcon code={report.code} />
                                            }
                                        </div>
                                    }
                                    <DisplayI18n field='name' defaultValue={report.name} i18n={report.i18n} />
                                    <i className="mdi mdi-chevron-right" />
                                </div>
                                <div className="text-body">
                                    <DisplayI18n field='description' defaultValue={report.description} i18n={report.i18n} />
                                </div>
                            </LinkComponent>
                            <div className="pr-3 py-3">
                                <ConfirmModal
                                    toggler={(fn) => (
                                        <UncontrolledDropdown tag="span">
                                            <DropdownToggle color="link" disabled={!!report.component}>
                                                <i className='mdi mdi-dots-vertical font-22 text-primary' />
                                            </DropdownToggle>
                                            <DropdownMenu right container={document.body}>
                                                <DropdownItem
                                                    disabled={report.deprecated == 1}
                                                    tag={Link}
                                                    to={{
                                                        pathname: `/reports/${parameter.route}`,
                                                        search: report.is_custom == 1 ? `?custom_report_id=${report.custom_report_id}` : null,
                                                        state: { report: report },
                                                    }}
                                                >
                                                    <Translate id='reports.dashboard.open' />
                                                </DropdownItem>
                                                <DropdownItem onClick={fn} disabled={!report.custom_report_id}>
                                                    <Translate id='misc.delete' />
                                                    {!report.custom_report_id &&
                                                        <div className="text-muted small"><Translate id='reports.customize.modal.deleteTitle.hint' /></div>
                                                    }
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )}
                                    color='danger'
                                    actionButtonText='misc.delete'
                                    translateActionButtonText
                                    modalMsg="reports.customize.modal.deleteTitle"
                                    translateModalMsg
                                    modalTitle={<DisplayI18n field="name" defaultValue={report.name} i18n={report.i18n} />}
                                    onConfirm={() => {
                                        return reportsContext.deleteCustomReport(report.custom_report_id)
                                            .then(async() => {
                                                spordleTable.setData(await mutate());
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    }}
                                />
                            </div>
                        </Card>
                    )
                },
            }}
            paginationMessage={() => null}
            errorMessage={({ error }) => <div className="text-center mt-3"><DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} /></div>}
            emptyLayout={<EmptyLayout hideArrow />}
            loadingMessage={<Skeleton count={10} height={82} className='mb-1' />}
        >
            {(spordleTable) => (
                <div className="container">
                    <ReportHeader title='reports.dashboard.title' hideOrg />
                    {/* REPORT GROUPS FILTER SQUARES */}
                    <div id="all-filter-cards">
                        <ReportsDashboardFilterCards
                            allReports={data}
                            error={error}
                            getReportsList={mutate}
                        />
                    </div>

                    {spordleTable.getFilters().groupFilter.groupCode !== 'exports' ?
                        <>
                            <Card className='card card-shadow p-3 mb-1'>
                                {/* SEARCH */}
                                <div id="reports-search" className="search-wrapper">
                                    <SpordleTableProvider.SearchInput placeholder='reports.dashboard.search.placeholder' />
                                </div>
                            </Card>

                            <SpordleTableView />
                        </>
                        :
                        <ReportExports />
                    }
                </div>
            )}
        </SpordleTableProvider>
    )
}

const IconToolTipSummaryDetailed = () => {
    return (
        <Tooltip
            label={<Translate id='report.dashboard.summary.detailed.report.tooltip' />}
            position='right'
            withArrow
            transition='fade'
        >
            <i style={{ fontSize: '16px' }} className="mdi mdi-source-fork mdi-rotate-90" />
        </Tooltip>
    )
}
const IconCustomReportIcon = () => {
    return (
        <Tooltip
            label={<Translate id='report.dashboard.custom.report.tooltip' />}
            position='right'
            withArrow
            transition='fade'
        >
            <i style={{ fontSize: '16px' }} className="mdi mdi-bookmark-outline" />
        </Tooltip>
    )
}

const IconDeprecatedCustomReportIcon = () => {
    return (
        <Tooltip
            label={<Translate id='report.dashboard.custom.report.deprecated.tooltip' />}
            position='right'
            withArrow
            transition='fade'
        >
            <i style={{ fontSize: '16px' }} className="mdi mdi-bookmark-outline" />
        </Tooltip>
    )
}

export default ReportsDashboard