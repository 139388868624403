import { Tooltip } from '@mantine/core';
import Translate, { DateFormat } from '@spordle/intl-elements';

const TeamMemberAge = ({ member }) => {
    const Container = member.overage_underage !== null ? AgeWithToolTip : AgeWithoutToolTip;

    return (
        <Container member={member}>
            <DateFormat value={member.member.birthdate} />
        </Container>
    );
}

const AgeWithoutToolTip = ({ member, children }) => {
    return (
        <div>
            {children} (<Translate id="misc.yearsOld" values={{ age: member.age }} />)
        </div>
    )
}

const AgeWithToolTip = ({ member, children }) => {

    return (
        <div className="d-flex flex-wrap align-items-center">
            <div className='mr-1'>{children}</div>
            <div className='d-flex flex-nowrap align-items-center'>
                <div>(<b className="text-body"><Translate id="misc.yearsOld" values={{ age: member.age }} /></b>)</div>
                <Tooltip withArrow label={<Translate id={"teams.profile.roster.table.age." + member.overage_underage || "OVERAGE"} />}>
                    <i className="ml-1 font-14 text-body mdi mdi-information" />
                </Tooltip>
            </div>
        </div>
    )
}

export default TeamMemberAge;