import { Container } from "reactstrap";
import settingsBackgroundChecksRoutes from "../../../../../routes/tabRoutes/organizationSettings/settingsBackgroundChecksRoutes";
import SubRouteSettingWrapper from "../../../components/SubRouteSettingWrapper";

const OrganizationSettingsBackgroundChecks = () => {

    return (
        <Container>
            <SubRouteSettingWrapper
                back="organization"
                routes={settingsBackgroundChecksRoutes}
            />
        </Container>
    );
}

export default OrganizationSettingsBackgroundChecks