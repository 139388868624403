export default{
    "onlineStore.profile.overview.tabs.overview"     : "Aperçu",
    "onlineStore.profile.overview.tabs.items"        : "Articles et frais",
    "onlineStore.profile.overview.tabs.transaction"  : "Transactions",
    "onlineStore.profile.overview.tabs.registration" : "Inscriptions",
    "onlineStore.profile.overview.tabs.otherItems"   : "Autres frais",
    "onlineStore.profile.overview.tabs.invoice"      : "Facture",
    "onlineStore.profile.overview.tabs.receipt"      : "Reçu",

    "onlineStore.profile.overview.tooltip.status"   : "Statut de la boutique",
    "onlineStore.profile.overview.tooltip.open"     : "Ouvrir boutique",
    "onlineStore.profile.overview.tooltip.copyUrl"  : "Copier l'url de la boutique",
    "onlineStore.profile.overview.tooltip.settings" : "Paramètres avancées",

    "onlineStore.profile.overview.singular.shared"                           : "organisation partagée",
    "onlineStore.profile.overview.plural.shared"                             : "organisations partagées",
    "onlineStore.profile.overview.tooltip.share"                             : "Partager l'inscription en ligne",
    "onlineStore.profile.button.share"                                       : "Partager",
    "onlineStore.profile.share.modal.title"                                  : "Partagez l'inscription en ligne",
    "onlineStore.profile.share.modal.organization.label"                     : "Organisation",
    "onlineStore.profile.share.modal.organization.add"                       : "Ajouter une organisation partagée",
    "onlineStore.profile.share.modal.organization.duplicate.error"           : "Assurez-vous qu'il n'y a pas d'organisations en double.",
    "onlineStore.profile.share.modal.organization.empty.error"               : "Assurez-vous qu'il n'y a pas d'organisations vides.",
    "onlineStore.profile.share.modal.organization.info.bubble"               : "Le partage d'une boutique en ligne permettra aux membres d'une autre organisation d'acheter des articles dans cette boutique.",
    "onlineStore.profile.share.modal.organization.info.bubble.validInvoices" : "Les organisations partagées des magasins en ligne ne peuvent pas être changer une fois que celle-ci a des factures.",

    "onlineStore.profile.overview.status.ACTIVE"          : "Actif",
    "onlineStore.profile.overview.status.DRAFT"           : "Brouillon",
    "onlineStore.profile.overview.status.CLOSE"           : "Fermée",
    "onlineStore.profile.overview.status.UNDER_CREATION"  : "En création",
    "onlineStore.profile.overview.status.updated.success" : "Statut mis à jour",
    "onlineStore.profile.overview.status.updated.error"   : "Impossible de mettre le statut à jour",

    "onlineStore.profile.overview.notFound.title"       : "Inscription en ligne non trouvée",
    "onlineStore.profile.overview.notFound.description" : "L'inscription en ligne que vous recherchez n'a pas été trouvé, elle a peut-être été déplacé ou supprimé.",
    "onlineStore.profile.overview.notFound.backButton"  : "Retour",

    "onlineStore.profile.header.back" : "Retour à la liste des inscriptions en ligne",

}