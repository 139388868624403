import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';

/** @type {React.Context<Omit<RebatesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const RebatesContext = createContext();
RebatesContext.displayName = 'RebatesContext';

class RebatesContextProvider extends React.Component{

    /**
     * Gets all rebates
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Rebates/Apicontroller%5CPricing%5CRebates%3A%3AgetAllRebates|documentation}
     * @returns {Promise.<Array>}
     */
    getRebates = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/rebates`,
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.rebates
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * Create a rebate
     * @param {object} [values] The values for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Rebates/Apicontroller%5CPricing%5CRebates%3A%3AcreateRebate|documentation}
     * @returns {Promise.<string>}
     */
    createRebate = (values) => {
        const data = new URLSearchParams({
            organisation_id: this.props.OrganizationContext.organisation_id,
        })
        for(const key in values){
            switch (key){

                case 'title':
                    data.append('title', values.title);
                    break;
                case 'type':
                    data.append('type', values.type);
                    break;
                case 'mode':
                    data.append('mode', values.mode);
                    break;
                case 'promo_code':
                    data.append('promo_code', values.promo_code);
                    break;
                case 'value':
                    data.append('value', values.value);
                    break;
                case 'expiration_date':
                    data.append('expiration_date', values.expiration_date);
                    break;
                case 'active':
                    data.append('active', values.active);
                    break;
                case 'rules':
                    if(Array.isArray(values[key])){
                        values[key].forEach((item, index) => {
                            for(const key in item){
                                if(key === 'postalCodes'){
                                    data.append(`rules[${index}][postal_codes]`, JSON.stringify(item[key]));
                                }else{
                                    data.append(`rules[${index}][${key}]`, item[key]);
                                }
                            }
                        });
                    }else{
                        data.append(`rules[0]`, values[key]);
                    }
                    break;
                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;

            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/rebates`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return response.data.rebate_id;
                }
                throw response.data.errors[0];
            }, serverError);
    }


    /**
     * Update rebate partially
     * @param {string} rebate_id
     * @param {object} [values] The values for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Rebates/Apicontroller%5CPricing%5CRebates%3A%3ApatchRebate|documentation}
     * @returns {Promise.<boolean>}
     */
    partialUpdateRebate = (rebate_id, values) => {
        const data = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'title':
                    data.append('title', values[key]);
                    break;
                case 'type':
                    data.append('type', values[key]);
                    break;
                case 'mode':
                    data.append('mode', values[key]);
                    break;
                case 'promo_code':
                    data.append('promo_code', values[key]);
                    break;
                case 'value':
                    data.append('value', values[key]);
                    break;
                case 'expiration_date':
                    data.append('expiration_date', values[key]);
                    break;
                case 'active':
                    data.append('active', values[key]);
                    break;
                case 'rules':
                    if(Array.isArray(values[key])){
                        values[key].forEach((item, index) => {
                            for(const key in item){
                                if(key === 'postal_codes'){
                                    data.append(`rules[${index}][postal_codes]`, JSON.stringify(item[key]));
                                }else{
                                    data.append(`rules[${index}][${key}]`, item[key]);
                                }
                            }
                        });
                    }else{
                        data.append(`rules[0]`, values[key]);
                    }
                    break;

                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;

            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/rebates/${rebate_id}` }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Delete rebate
     * @param {string} rebate_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Rebates/Apicontroller%5CPricing%5CRebates%3A%3AdeleteRebate|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteRebate = (rebate_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/rebates/${rebate_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }


    render(){
        return (
            <RebatesContext.Provider value={this}>
                {this.props.children}
            </RebatesContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext)(RebatesContextProvider);