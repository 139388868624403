import { stringBuilder } from "@spordle/helpers";
import { useFormikContext } from "formik";
import { Alert, Collapse } from "reactstrap";
import generatePassword from "../../helpers/passwordGenerator";
import ApiErrorMsg from "./ApiErrorMsg";
import styles from "./FormikApiError.module.scss";

/**
 * @description
 * Has to be used with Formik initialStatus prop.
 * The initialStatus must be an object that includes 'hasError' (bool) and 'error' (object) with i18n structure
 * @example
 * <Formik
 *      initialStatus={{
 *          showError: false,
 *          error: null,
 *          isSuccess: false,
 *      }}
 *      onSubmit={(values, { setStatus }) => {
 *          return apiCall(values)
 *              .then(() => {
 *                  setStatus({ isSuccess: true, showError: false, error: null });
 *              })
 *              .catch((e) => {
 *                  console.error(e);
 *                  setStatus({ isSuccess: true, showError: true, error: e });
 *              })
 *      }}
 * >
 *   {(formik) => (
 *      <Form>
 *          <FormikApiError />
 *      </Form>
 *   )}
 * </Formik>
 */
const FormikApiError = ({ className }) => {
    const formik = useFormikContext();
    const error = formik.status?.error;

    if(!formik.status){
        return null;
    }

    return (
        <Collapse isOpen={formik.status.showError}>
            <div className={stringBuilder(styles.wrapper, className)}>
                <Alert color="danger" className="mb-0" toggle={() => formik.setStatus({ ...formik.status, showError: false })}>
                    {Array.isArray(error) ?
                        error.length > 1 ?
                            <ul style={{ listStylePosition: "inside" }} className="pl-0 pb-0 mb-0">
                                {error.map((err) => (
                                    <li key={generatePassword()}>
                                        <ApiErrorMsg error={err} />
                                    </li>
                                ))}
                            </ul>
                            :
                            <ApiErrorMsg error={error[0]} />
                        :
                        error
                    }
                </Alert>
            </div>
        </Collapse>
    );
}

export default FormikApiError;