import { FormikSelect } from '@spordle/formik-elements';
// Language
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import React from "react";
import {
    Button, Collapse, FormGroup, Label, ModalBody, ModalFooter
} from 'reactstrap';
import * as Yup from 'yup';
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import CustomAlert from "../../../../../../components/CustomAlert";
import { fail, success } from '@spordle/toasts';
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";
// Contexts
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { IdentityRolesContext } from '../../../../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { UtilsContext } from '../../../../../../contexts/UtilsContext';
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import withContexts from '../../../../../../helpers/withContexts';

class AddFamilyRelationForm extends React.Component{
    render(){
        return (
            <Formik
                initialValues={{
                    member: this.props.member,
                    relationId: '',
                    relation: null,
                }}
                validationSchema={Yup.object().shape({
                    relationId: Yup.string().required(<Translate id='members.profile.overview.memberFamilyRelations.relation.required' />),
                })}
                onSubmit={(values, { setStatus }) => {
                    if(!this.props.relationAlreadyExists(this.props.MembersContext.currentMember.member_id, values.member.member_id)){
                        this.props.setLoading(true)
                        this.props.MembersContext.createMemberRelation(this.props.MembersContext.currentMember.member_id, values.relationId, values.member.member_id)
                            .then(() => {
                                this.props.setLoading(false);
                                success({ msg: 'members.profile.overview.memberFamilyRelations.add.success' });
                                this.props.refreshTable();
                                this.props.toggle();
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                    this.props.setLoading(false);
                                    if(error.i18n){
                                        setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                    }else{
                                        setStatus(<Translate id={'members.profile.overview.memberFamilyRelations.add.' + error.message} defaultMessageId='misc.error' />)
                                    }

                                }
                            })
                    }else{
                        setStatus(<Translate id='members.profile.overview.memberFamilyRelations.add.alreadyExists' />)
                    }
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <ModalBody>

                                <UserDisplay card className='w-100 mb-3' style={{ minWidth: 'unset' }}>
                                    <UserDisplay.Container>
                                        <UserImg
                                            abbr={`${this.props.member.first_name.charAt(0)}${this.props.member.last_name.charAt(0)}`}
                                            src={this.props.member.logo}
                                            width='50'
                                            alt={`${this.props.member.first_name} ${this.props.member.last_name}`}
                                            className="p-2"
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title>{this.props.member.first_name} {this.props.member.last_name}</UserDisplay.Title>
                                        <UserDisplay.Subtitle><DisplayI18n field='name' defaultValue={this.props.member.organisation?.organisation_name} i18n={this.props.member.organisation?.i18n} /></UserDisplay.Subtitle>
                                        <UserDisplay.Subtitle>{this.props.member.unique_identifier ? <><Translate id='members.profile.overview.memberFamilyRelations.uniqueIdentifier' /> {this.props.member.unique_identifier}</> : ''}</UserDisplay.Subtitle>
                                        <UserDisplay.Subtitle>{this.props.member.birthdate} (<Translate id='misc.yearsOld' values={{ age: this.props.member.age }} />)</UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                                <FormGroup>
                                    <Label for='relationId' className='text-muted'><Translate id='members.profile.overview.memberFamilyRelations.relation' /></Label>
                                    <FormikSelect
                                        id='relationId'
                                        name='relationId'
                                        onOptionSelected={([ value ], table) => {
                                            const select = table.getSpordleTable().getData().find((d) => d.value === value);

                                            formik.setFieldValue('relation', {
                                                name: select.label,
                                                i18n: select.i18n,
                                                relation_id: select.value,
                                            })
                                        }}
                                        renderOption={({ option }) => (
                                            <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                        )}
                                        searchKeys={[
                                            `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                        ]}
                                        noOptionLayout={<Translate id='members.profile.overview.memberFamilyRelations.add.select.noOptions' />}
                                        placeholder='members.profile.overview.memberFamilyRelations.add.select.placeholder'
                                        options={this.props.relations ?
                                            this.props.relations.map((relation) => ({
                                                label: relation.name,
                                                i18n: relation.i18n,
                                                value: relation.relation_id,
                                            }))
                                            :
                                            []
                                        }
                                    />
                                </FormGroup>

                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    {formik.status &&
                                        <CustomAlert color='danger' text={formik.status} translateText={false} withTitle toggle={() => formik.setStatus(null)} />
                                    }
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' outline type='button' onClick={this.props.back}><Translate id='misc.previous' /></Button>
                                <div className="ml-auto">
                                    <Button color='primary' type='submit' className='mr-2'><Translate id='misc.add' /></Button>
                                    <Button color='primary' outline type='button' onClick={this.props.toggle}><Translate id='misc.cancel' /></Button>
                                </div>
                            </ModalFooter>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}

export default withContexts(MembersContext, IdentityRolesContext, UtilsContext, I18nContext)(AddFamilyRelationForm);