import { FormikInputNumber } from "@spordle/formik-elements"
import { useFormikContext } from "formik"
import { FormGroup } from "reactstrap"
import { filterHasValue, formikCodesHaveValues, handleFilterChange } from "../reportsEngineHelpers"
import FilterLabel from "./FilterLabel"

const EngineNumberInput = ({ filter, filterRefs }) => {
    const formik = useFormikContext();

    return (
        <FormGroup name={filter.code}>
            <FilterLabel filter={filter} />
            <FormikInputNumber
                {...filter.parsedParams?.componentProps}
                id={filter.code}
                name={filter.code}
                ref={(r) => { filterRefs.current[filter.code] = r }}
                className={filter.parsedParams?.componentProps?.className}
                allowNegative={false}
                decimalSeparator={filter.parsedParams?.componentProps?.decimalSeparator || false}
                onChange={(number) => { handleFilterChange(filter, formik, number.target.value, filterRefs) }}
                disabled={!formikCodesHaveValues(filter.parsedParams?.enabledWhen, formik) || !filterHasValue(filter.parsedParams?.enabledWhenValue?.filter, filter.parsedParams?.enabledWhenValue?.value, formik)}
            />
        </FormGroup>
    )
}

export default EngineNumberInput