import Translate from '@spordle/intl-elements';
import { Link } from "react-router-dom";
import {
    ListGroup,
    ListGroupItem
} from "reactstrap";
import StatusBadge from '../../../../components/badges/StatusBadge';
import QuickViewButton from '../../../../components/quickView/QuickViewButton';
import SidePanel from '../../../../components/sidePanel/SidePanel';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';


const SidePanelWaitingListMulti = ({ spordleTable, members, ...props }) => {

    return (
        <>
            <SidePanel.Header>
                <div className='d-flex mb-2'>
                    <SidePanel.ToggleButton />
                </div>
                <SidePanel.Title className='d-flex'>
                    <QuickViewButton member={members}>{members.length} <Translate id='members.search.sidePanelMemberSearch.title.selectedMembers' /></QuickViewButton>
                </SidePanel.Title>
            </SidePanel.Header>
            <ListGroup>
                {members.map((member) => (
                    <ListGroupItem key={member.member_id}>
                        <UserDisplay>
                            <UserDisplay.Container>
                                <UserImg
                                    alt={member.first_name + ' ' + member.last_name}
                                    abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                    src={member.picture?.full_path}
                                    filePos={member.picture?.file_position}
                                    width={60}
                                    className="mr-2"
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container className="w-100">
                                <UserDisplay.Title className="d-flex justify-content-between">
                                    <QuickViewButton member={member} className='d-flex justify-content-start'>
                                        {member.first_name} {member.last_name}
                                    </QuickViewButton>
                                    <div>{member.member_status && <StatusBadge color={member.member_status.color_code} status={member.member_status} icon={member.has_been_merged == 1 ? <i className='mdi mdi-call-merge' /> : null} />}</div>
                                </UserDisplay.Title>
                                <UserDisplay.Subtitle>
                                    <Link className="text-nowrap" to={`/members/profile/${member.member_id}`}>
                                        {member.unique_identifier ?
                                            <>#{member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                            :
                                            '-'
                                        }
                                    </Link>
                                </UserDisplay.Subtitle>
                            </UserDisplay.Container>
                        </UserDisplay>
                    </ListGroupItem>
                ))}
            </ListGroup>

        </>
    );
}

export default SidePanelWaitingListMulti;