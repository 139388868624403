import { stringBuilder } from "@spordle/helpers";

const CompetitionTeamStatusIcon = ({ status, className }) => {
    if(!status){
        return null;
    }

    const getValues = (code) => {
        switch (code){
            case 'DECLINED':
                return [ 'close-circle', 'danger' ];
            case 'CONFIRMED':
            case 'APPROVED':
                return [ 'check-circle', 'success' ];
            case 'PENDING':
                return [ 'clock-outline', 'warning' ];
            default:
                return [];
        }
    }

    const [ icon, color ] = getValues(status.system);

    if(!icon && !color){
        return null;
    }

    return (
        <i className={stringBuilder(className, `text-${color} mdi mdi-${icon}`)} />
    );
}

export default CompetitionTeamStatusIcon;