import AddOutcomeForm from "./AddOutcomeForm";

const AddSingleOutcome = (props) => {
    const onSuccess = (values) => {
        props.refreshData(values);
        props.toggle();
    }

    return (
        <AddOutcomeForm onSuccess={onSuccess} {...props} />
    )
}

export default AddSingleOutcome;