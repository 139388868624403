import { useContext } from 'react';
import { I18nContext } from '../../../contexts/I18nContext';
import { stringBuilder } from '@spordle/helpers';
import { DisplayI18n } from '../../../helpers/i18nHelper';

const HelpLinks = ({ helpCenterResources, linkClassName }) => {
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <>
            {helpCenterResources && helpCenterResources?.map((resource) => {
                return (
                    <RessourceLink className={linkClassName} key={resource.help_bubble_resource_id} href={getGenericLocale() == 'en' ? resource.i18n?.en?.link : resource.i18n?.fr?.link}>
                        <DisplayI18n field='name' defaultValue={resource.name} i18n={resource.i18n} />
                    </RessourceLink>
                )
            })}
        </>
    );
}

export const RessourceLink = ({ href, icon = "mdi-file-document-box", customIcon, skipHrefIcon, children, className, ...props }) => {
    const Tag = href ? "a" : "button";
    const tagProps = href ? { href: href, target: "_blank", rel: "noopener noreferrer", ...props } : { type: "button", ...props }; // make sure to keep rel: "noopenenr noreferrer"
    return (
        <Tag
            {...tagProps}
            className={stringBuilder("d-flex py-1 px-2 btn btn-link align-items-center bg-light-inverse w-100", className)}
        >
            {
                customIcon
                ||
                <i
                    className={`border mdi ${icon} mr-2 rounded-circle bg-white text-primary d-inline-flex flex-center line-height-0`}
                    style={{ height: 25, width: 25 }}
                />
            }
            {children}
            {href && !skipHrefIcon &&
                <i className="pl-2 ml-auto mdi mdi-launch text-primary" />
            }
        </Tag>
    )
}

export default HelpLinks;