import organizationProfile from "./organizationProfile";
import accounting from "./accounting";
import overview from "./overview";
import roles from "./roles";
import staff from "./staff";
import users from "./users";
import venues from "./venues";
import postalCodes from "./postalCodes";
import grouping from "./grouping";

export default{
    ...organizationProfile,
    ...accounting,
    ...overview,
    ...roles,
    ...staff,
    ...users,
    ...venues,
    ...postalCodes,
    ...grouping,
}