export default{
    "organization.profile.roles.addRole.title"                             : "Ajouter un Rôle",
    "organization.profile.roles.addRole.roleTitle"                         : "Titre",
    "organization.profile.roles.addRole.roleTitle.required"                : "Le Titre est requis",
    "organization.profile.roles.addRole.description"                       : "Description",
    "organization.profile.roles.addRole.description.required"              : "La Description est requise",
    "organization.profile.roles.addRole.isSystem"                          : "Rôle système",
    "organization.profile.roles.addRole.status"                            : "Statut",
    "organization.profile.roles.addRole.status.required"                   : "Le Statut est requis",
    "organization.profile.roles.addRole.status.active"                     : "Actif",
    "organization.profile.roles.addRole.status.inactive"                   : "Inactif",
    "organization.profile.roles.addRole.dates.start"                       : "Le Rôle sera disponible à parti du",
    "organization.profile.roles.addRole.dates.end"                         : "Jusqu'au",
    "organization.profile.roles.addRole.dates.startDate.format.validation" : "La date de début doit être d'un format valide",
    "organization.profile.roles.addRole.dates.endDate.format.validation"   : "La date de fin doit être d'un format valide",
    "organization.profile.roles.addRole.dates.validation"                  : "La date de début doit être avant la date de fin",

    // Table
    "organization.profile.roles.table.title"       : "Titre",
    "organization.profile.roles.table.description" : "Description",
    "organization.profile.roles.table.active"      : "Actif",
    "organization.profile.roles.table.myRole"      : "Mon rôle",
    "organization.profile.roles.table.emptyLayout" : "Aucun rôle",

    // Multi Select
    "organization.profile.roles.multiSelect.active"   : "Actif",
    "organization.profile.roles.multiSelect.inactive" : "Inactif",
}