import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";
import { copyToClipBoard } from "../../../../helpers/clipboardHelper";

const BreadcrumbsCopyBtn = () => {

    return (
        <Tooltip
            withArrow
            label={<Translate id='misc.copy' />}
            zIndex={3000}
        >

            <button
                color="link"
                type="button"
                className='reset-btn btn btn-link mdi mdi-content-copy clickable ml-3'
                onClick={() => { copyToClipBoard(window.location.href) }}
            />
        </Tooltip>
    );
}

export default BreadcrumbsCopyBtn;