import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { Button } from "reactstrap";
import ConfigurationSettingsViewMemberType from "./ConfigurationSettingsViewMemberType";
import { useContext } from "react";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import generatePassword from "../../../../../../helpers/passwordGenerator";
import ConfigurationSettingsFormPositions from "./ConfigurationSettingsFormPositions";
import { array, number, object, string } from "yup";
import { stringifyJSON } from "../../../../../../helpers/helper";
import ConfigurationSettingsFormPosGroups from "./ConfigurationSettingsFormPosGroups";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import ApiErrorMsg from "../../../../../../components/formik/ApiErrorMsg";
import FormikApiError from "../../../../../../components/formik/FormikApiError";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import { success } from "@spordle/toasts";
import ConfigurationSettingsViewPosition from "./ConfigurationSettingsViewPosition";
import ConfigurationSettingsViewPosGroup from "./ConfigurationSettingsViewPosGroup";
import { getSettingVal, useTeamConfigPosGroupsSWR } from "../teamSettingsConfigurationHelper";
import HasAccessTo from "../../../../../../components/permissions/HasAccessTo";
import { useTeamPositionsSWR } from "../../../../../../api/useSWRHelpers/positionsSWR";

const ConfigurationSettingsForm = ({ stopEditing }) => {
    const { settings, organisation_id, updateSettings } = useContext(OrganizationContext);
    const { data: posGroups } = useTeamConfigPosGroupsSWR();
    const { data: positions } = useTeamPositionsSWR();

    const memberTypeSetting = settings.require_member_type_for_rostering;
    const positionSetting = settings.count_per_position;
    const posGroupSetting = settings.count_per_position_group;

    return (
        <Formik
            initialStatus={{
                error: null,
                showError: false,
            }}
            initialValues={{
                require_member_type_for_rostering: memberTypeSetting?.value || "0",
                count_per_position: getSettingVal(positionSetting?.value).map(([ positionId, count ]) => ({
                    id: positionId,
                    count: count,
                    key: generatePassword(),
                }))
                    .filter(({ id }) => positions?.some(({ position_id }) => position_id === id)),
                count_per_position_group: getSettingVal(posGroupSetting?.value).map(([ posGroupId, count ]) => ({
                    key: generatePassword(),
                    id: posGroupId,
                    count: count,
                }))
                    .filter(({ id }) => posGroups?.some(({ position_group_id }) => position_group_id === id)),
            }}
            validationSchema={object().shape({
                require_member_type_for_rostering: string().oneOf([ "1", "0" ]),
                count_per_position: array().of(object().shape({
                    count: number().required(<Translate id="settings.teamsSettings.configuration.needLimit" />),
                    id: string().required(<Translate id="settings.teamsSettings.configuration.needPosition" />),
                })),
                count_per_position_group: array().of(object().shape({
                    count: number().required(<Translate id="settings.teamsSettings.configuration.needLimit" />),
                    id: string().required(<Translate id="settings.teamsSettings.configuration.needPosGroup" />),
                })),
            })}
            onSubmit={(values, { setStatus }) => {
                const apiValues = {};

                const convertValToJSON = (field) => {
                    // Will convert to an object with ids as keys, then JSON stringify.
                    // Example: { "uuid": 3, "uuid2": 6 }
                    return stringifyJSON(
                        values[field].reduce((formatted, { id, count }) => {
                            formatted[id] = count;
                            return formatted;
                        }, {}), "",
                    )
                }

                if(positionSetting?.can_manage == 1){
                    apiValues.count_per_position = convertValToJSON("count_per_position");
                }

                if(posGroupSetting?.can_manage == 1){
                    apiValues.count_per_position_group = convertValToJSON("count_per_position_group");
                }

                if(memberTypeSetting?.can_manage == 1){
                    apiValues.require_member_type_for_rostering = values.require_member_type_for_rostering;
                }

                return updateSettings(organisation_id, apiValues)
                    .then(() => {
                        success();
                        stopEditing();
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e?.message)){
                            console.error(e);
                            setStatus({
                                showError: true,
                                error: Array.isArray(e) ? e : <ApiErrorMsg error={e} />,
                            })
                        }
                    })
            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={formik.isSubmitting}>
                    <Form>
                        <ConfigurationSettingsViewMemberType isEdit={memberTypeSetting?.can_manage == 1} />
                        <HasAccessTo componentCode="teams" componentPermissionCode="team_positions">
                            {positionSetting?.can_manage == 1 ?
                                <div className="pb-3">
                                    <ConfigurationSettingsFormPositions positionSetting={positionSetting} />
                                </div>
                                :
                                <ConfigurationSettingsViewPosition />
                            }
                        </HasAccessTo>
                        <HasAccessTo componentCode="teams" componentPermissionCode="team_position_groups">
                            {posGroupSetting?.can_manage == 1 ?
                                <ConfigurationSettingsFormPosGroups posGroupSetting={posGroupSetting} />
                                :
                                <ConfigurationSettingsViewPosGroup />
                            }
                        </HasAccessTo>
                        <FormikApiError className="pb-0" />
                        <div className="d-flex justify-content-end pt-3">
                            <Button disabled={formik.isSubmitting} type="submit" color="primary" className="mr-1"><Translate id="misc.save" /></Button>
                            <Button disabled={formik.isSubmitting} type="button" outline onClick={stopEditing} color="primary"><Translate id="misc.cancel" /></Button>
                        </div>
                    </Form>
                </OverlayLoader>
            )}
        </Formik>
    );
}

export default ConfigurationSettingsForm;