export default{
    "organization.settings.sidePanelOrganizationMemberTypes.title"                  : "Informations de type de membre",
    "organization.settings.sidePanelOrganizationMemberTypes.status"                 : "Statut",
    "organization.settings.sidePanelOrganizationMemberTypes.status.active"          : "Actif",
    "organization.settings.sidePanelOrganizationMemberTypes.status.inactive"        : "Inactif",
    "organization.settings.sidePanelOrganizationMemberTypes.status.required"        : "Le Statut est requis",
    "organization.settings.sidePanelOrganizationMemberTypes.forms"                  : "Questionnaire",
    "organization.settings.sidePanelOrganizationMemberTypes.waivers"                : "Dispenses",
    "organization.settings.sidePanelOrganizationMemberTypes.selected"               : "{amount, plural, =0 {Aucune sélectionné} one {# sélectionnée} other {# sélectionnées}}",
    "organization.settings.sidePanelOrganizationMemberTypes.update.success.text"    : "Type de membre mis à jour",
    "organization.settings.sidePanelOrganizationMemberTypes.update.fail.text"       : "Une erreur est survenue lors de la mise à jour du type de membre, veuillez rafraichir la page et réessayer",
    // actions
    "organization.settings.sidePanelOrganizationMemberTypes.actions.remove"         : "Retirer",
    "organization.settings.sidePanelOrganizationMemberTypes.actions.remove.topText" : "Vous êtes sur le point de retirer ce type de membre de votre organisation",
    // tabs
    "organization.settings.sidePanelOrganizationMemberTypes.tabs.details"           : "Détails",
}