import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

/** @type {React.Context<Omit<ClinicFeesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & ClinicFeesContextProvider['state']>} */
export const ClinicFeesContext = createContext();
ClinicFeesContext.displayName = 'ClinicFeesContext';

class ClinicFeesContextProvider extends React.Component{

    /**
     * Updates a clinic fee
     * @param {string} clinicFeeId ID of the clinic fee to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @returns {Promise}
     */
    updateClinicFee = (clinicFeeId, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'mandatory':
                    params.append('mandatory', !!values[key] >>> 0);
                    break;
                default:
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `clinic-fees/${clinicFeeId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a fee under a clinic
     * @param {string} clinicId ID of the clinic to add a fee to
     * @param {string} feeId ID of the fee to add to a clinic
     * @param {boolean} mandatory Wether or not the clinic fee is mandatory
     * @returns {Promise}
     */
    createClinicFee = (clinicId, feeId, mandatory) => {
        const params = new URLSearchParams();
        params.append('clinic_id', clinicId);
        params.append('fee_id', feeId);
        params.append('mandatory', !!mandatory >>> 0);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `clinic-fees` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_fee_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a clinic fee
     * @param {string} clinicFeeId ID of the clinic fee to delete
     * @returns {Promise}
     */
    deleteClinicFee = (clinicFeeId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `clinic-fees/${clinicFeeId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <ClinicFeesContext.Provider value={{ ...this.state, ...this }}>
                {this.props.children}
            </ClinicFeesContext.Provider>
        )
    }
}

export default ClinicFeesContextProvider