import { Card } from "@mantine/core";
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import SpordlePanelTable from "../../../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../../../components/table/EmptyLayout";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper";
import CompetitionsSidepanel from "./CompetitionsSidepanel";

const Competitions = () => {
    const i18nContext = useContext(I18nContext);
    const orgContext = useContext(OrganizationContext);

    return (
        <Card body className="card-shadow">
            <CardSectionTitle title='organisation.profile.tabs.competitions' />

            <SpordlePanelTable
                allowOutsideClick
                sidePanel={(sPanelProps) => <CompetitionsSidepanel {...sPanelProps} />}
                dataIndex='competition_id'
                table={(panelProps) => {
                    return (
                        <SpordleTableProvider
                            id={'organization-groupings-leagues-competitions'}
                            tableHover bordered striped
                            clickable
                            ref={panelProps.spordleTableRef}
                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                            searchKeys={[
                                'name',
                                `i18n.${i18nContext.getGenericLocale()}.name`,
                            ]}
                            dataIndex='competition_id'
                            pagination={10}
                            emptyLayout={<EmptyLayout />}
                            columns={[
                                {
                                    label: <Translate id='form.fields.name' />,
                                    key: "name",
                                    mobile: true,
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='organization.grouping.table.teams.column' />,
                                    key: "teams",
                                    mobile: true,
                                    dataClassName: 'text-center',
                                },
                            ]}
                            renderRow={(columnKey, data) => {
                                switch (columnKey){
                                    case 'name':
                                        return (
                                            <>
                                                <DisplayI18n
                                                    field="name"
                                                    defaultValue={data.name}
                                                    i18n={data.i18n}
                                                />
                                            </>
                                        )
                                    case 'teams':
                                        return (
                                            <Translate
                                                id='organization.grouping.table.teams.count'
                                                values={{
                                                    number: data.teams.length,
                                                }}
                                            />
                                        )
                                    default: break;
                                }
                            }}
                            desktopWhen
                            onColumnClick={(e, data) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        panelProps.onSingleSelectChange(data);
                                        break;
                                }
                            }}
                            rowIsHighlighted={(data) => !!data.checked}
                            columnSorting={(compA, compB, _spordleTable, order) => {
                                switch (order.column){
                                    case 'name':
                                        const compNameA = displayI18n('name', compA.i18n, compA.name, i18nContext.getGenericLocale());
                                        const compNameB = displayI18n('name', compB.i18n, compB.name, i18nContext.getGenericLocale());
                                        return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(compNameA, compNameB) * (order.order === 'ASC' ? 1 : -1);
                                }
                            }}
                            loadData={(from, _, spordleTable) => {
                                switch (from){
                                    case 'REFRESH':
                                        spordleTable.setLoading();
                                    case 'CDM':
                                        return orgContext.getCompetitions({ organisation_id: orgContext.organisation_id })
                                            .then((competitions) => {
                                                return orgContext.getCompetitionTeams(orgContext.organisation_id)
                                                    .then((competitionsTeams) => {
                                                        return competitions?.map((competition) => ({
                                                            ...competition,
                                                            teams: competitionsTeams.teams.filter((team) => team.competition_id === competition.competition_id),
                                                        }))
                                                    })
                                            })
                                    default:
                                        break;
                                }
                            }}
                        >
                            {(spordleTable) => (
                                <>
                                    <div className="d-flex justify-content-between mb-2">
                                        <SearchInput />

                                        <div className="ml-auto">
                                            <Refresh />
                                        </div>
                                    </div>

                                    <SpordleTableView />
                                </>
                            )}
                        </SpordleTableProvider>
                    )
                }}
            />
        </Card>
    )
}

export default Competitions