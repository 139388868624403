import OrganizationLeagues from "./leagues/OrganizationLeagues"
import OrganizationZones from "./zones/OrganizationZones"
import OrganizationVenues from "../venues/OrganizationVenues"
import OrganizationTournaments from "./tournaments/OrganizationTournaments"
import { default as OrganizationsTournaments } from "./tournaments/viewAsTournament/Organizations"
import { default as OrganizationsLeagues } from "./leagues/viewAsLeague/Organizations"
import { default as OrganizationsZones } from "./zones/viewAsZone/Organizations"
import { default as CompetitionsTournaments } from "./tournaments/viewAsTournament/Competitions"
import { default as CompetitionsLeagues } from "./leagues/viewAsLeague/Competitions"

export const ZONES = 'ZONES'
export const LEAGUES = 'LEAGUES'
export const TOURNAMENTS = 'TOURNAMENTS'
export const ZONES_CATEGORY_NAME = 'Zone'
export const LEAGUES_CATEGORY_NAME = 'League'
export const TOURNAMENTS_CATEGORY_NAME = 'Tournament'

export const venuesRoute = {
    path: '/venues',
    name: 'organisation.profile.tabs.venues',
    component: OrganizationVenues,
    permissions: { code: 'organization' },
}

export const zonesParentRoute = {
    path: '/zones',
    name: 'organisation.profile.tabs.zones',
    component: OrganizationZones,
    permissions: { code: 'organization', permissionCode: 'organisation_zone' },
}

export const leaguesParentRoute = {
    path: '/leagues',
    name: 'organisation.profile.tabs.leagues',
    component: OrganizationLeagues,
    permissions: { code: 'organization', permissionCode: 'organisation_league' },
}

export const tournamentsParentRoute = {
    path: '/tournaments',
    name: 'organisation.profile.tabs.tournaments',
    component: OrganizationTournaments,
    permissions: { code: 'tournaments', permissionCode: 'tournaments_module' },
}

export const zonesViewRoutes = [
    {
        path: '/zones',
        name: 'organisation.profile.tabs.zones',
        component: OrganizationsZones,
        permissions: { code: 'organization', permissionCode: 'organisation_zone' },
    },
]

export const leaguesViewRoutes = [
    {
        path: '/competition-organizations',
        name: 'organisation.profile.tabs.orgs',
        component: OrganizationsLeagues,
        permissions: { code: 'organization', permissionCode: 'organisation_zone' },
    },
    {
        path: '/competitions',
        name: 'organisation.profile.tabs.competitions',
        component: CompetitionsLeagues,
        permissions: { code: 'organization', permissionCode: 'organisation_zone' },
    },
]

export const tournamentsViewRoutes = [
    {
        path: '/competition-organizations',
        name: 'organisation.profile.tabs.orgs',
        component: OrganizationsTournaments,
        permissions: { code: 'organization', permissionCode: 'organisation_zone' },
    },
    {
        path: '/competitions',
        name: 'organisation.profile.tabs.competitions',
        component: CompetitionsTournaments,
        permissions: { code: 'organization', permissionCode: 'organisation_zone' },
    },
]