import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useRef, useState } from "react";
import { Button, Label, ModalBody } from "reactstrap";
import { array, object, string } from "yup";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper";
import OrganizationSearch from "../../../../../organization/OrganizationSearch";
import UserDisplay from "../../../../../userDisplay/UserDisplay";
import { SendCommModalContext } from "../../../../context/SendCommModalContext";
import ReviewFooter from "../ReviewFooter";
import { exportToCsv } from "../../../../../../helpers/export";
import moment from "moment";
import { useIntl } from "react-intl";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { Skeleton, Tooltip, Loader } from "@mantine/core";
import CrossFade from "../../../../../crossFade/CrossFade";
import { fail } from "@spordle/toasts";
import DisplayAllSelectedContacts from "./DisplayAllContacts";
import FormikApiError from "../../../../../formik/FormikApiError";

const ReviewOrgs = () => {
    const [ isFetchingContacts, setIsFetchingContacts ] = useState(false);
    const [ contacts, setContacts ] = useState([]);
    const { isLoading, next, setTheRecipients } = useContext(SendCommModalContext);
    const { getOrganizationsContacts } = useContext(OrganizationContext);
    const { formatMessage } = useIntl();
    const { getGenericLocale } = useContext(I18nContext);

    const tableRef = useRef(null)

    const fetchContacts = (orgIds) => {
        if(!isFetchingContacts){
            setIsFetchingContacts(true);

            // array of arrays of orgIds (each element in the main array is an array of 30 org ids)
            const orgIdChunks = [];

            const chunkSize = 30;
            for(let i = 0; i < orgIds.length; i += chunkSize){
                const chunk = orgIds.slice(i, i + chunkSize);
                orgIdChunks.push(chunk);
            }


            Promise.all(orgIdChunks.map((orgIds) => (getOrganizationsContacts({
                organisation_id: orgIds,
                active: 1,
                with_identities: 1,
            })))).then((promises) => {
                const allContacts = [];

                // array that merges contacts and identites
                // an Identity is a "User" as displayed in the "Users" list in the organiztion profile
                // a contact is a "Contact" as displayed in the "Staff" list in the organiztion profile
                promises.map(({ contacts, identities }) => {
                    const mergedContacts = [];

                    // format contacts then push them to the the array of all the received contacts and identities
                    mergedContacts.pushArray(contacts.map((contact) => {
                        const formatted = {
                            ...contact,
                            member_id: contact.member?.member_id || "",
                            id: contact.organisation_contact_id,
                            checked: true,
                        };

                        return formatted;
                    }))

                    // format identities then push them to the the array of all the received contacts and identities
                    mergedContacts.pushArray(identities.map((identity) => {
                        const formatted = {
                            ...identity,
                            id: identity.identity_id,
                            isUser: true,
                            checked: true,
                        };

                        return formatted;
                    }))

                    // prevents duplicates and pushes to the array of all contacts
                    allContacts.pushArray(mergedContacts.reduce((keptMerged, contact) => {
                        const existingContact = allContacts.find((prevContact) => prevContact.id === contact.id);

                        // pushes the contact to the list of keptContacts if existingContacts is null
                        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator
                        keptMerged.push(existingContact ?? contact);

                        return keptMerged;
                    }, []))
                })
                setContacts(allContacts);
                setIsFetchingContacts(false);
            }).catch((error) => {
                setIsFetchingContacts(false);
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            })
        }
    }

    const handleOnMenuClose = (orgIds) => {
        return () => {
            fetchContacts(orgIds);
        }
    }

    /**
     * @param {{ first_name: string }[]} list
     * @returns {function}
     */
    const handleOnDownloadRecipients = (list) => {
        return () => {
            exportToCsv(
                `SpordleID-OrganizationCommunicationRecipients__${moment().format('YYYY-MM-DD_H:mm')}.csv`,
                [
                    [// Headers
                        formatMessage({ id: "form.fields.firstName" }),
                        formatMessage({ id: "form.fields.lastName" }),
                        formatMessage({ id: "members.search.memberSearch.filters.memberNumber" }),
                        formatMessage({ id: "form.fields.email" }),
                        formatMessage({ id: "misc.organization" }),
                    ],
                    ...list.map((recipient) => ([
                        recipient.first_name,
                        recipient.last_name,
                        recipient.unique_identifier || "-",
                        (recipient.email || "-").trim(),
                        displayI18n("name", recipient.organisation?.i18n, recipient.organisation?.organisation_name, getGenericLocale()) || "",
                    ])),
                ],
            )
        }
    }

    return (
        <Formik
            initialValues={{
                organisation_id: [],
            }}
            validationSchema={object().shape({
                organisation_id: array().of(string()).min(1, <Translate id="components.communications.modal.orgs.min" />),
            })}
            onSubmit={(values, { setStatus }) => {
                const selected = tableRef.current?.getData().filter((c) => c.checked) || [];

                if(selected.length === 0){
                    setStatus({ error: <Translate id="components.communications.modal.orgs.contacts.min" />, showError: true });
                }else{
                    setTheRecipients((prev) => ({ ...prev, valid: selected }));
                    next();
                }
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <Label className="text-muted d-flex align-items-center">
                            <Translate id="communications.inbox.to" />
                        </Label>
                        <OrganizationSearch
                            isCompact
                            withTree
                            fromApi={false}
                            disabled={isFetchingContacts}
                            onMenuClose={handleOnMenuClose(formik.values.organisation_id)}
                            onTreeClose={handleOnMenuClose(formik.values.organisation_id)}
                            onOptionSelected={(options) => {
                                if(options.length === 0){
                                    setContacts([]);
                                }
                            }}
                            id="organisation_id"
                            name="organisation_id"
                            withFormik
                            clearable
                            multi
                        />

                        <div className="mt-3">
                            <CrossFade isVisible={contacts.length === 0 && !isFetchingContacts}>
                                <UserDisplay card block className='mb-0 shadow-none bg-light-inverse'>
                                    <UserDisplay.Container className="mr-auto">
                                        <UserDisplay.Title>
                                            <Translate id="components.communications.modal.label.no.contacts" />
                                            {formik.values.organisation_id.length === 0 && <span className="ml-1 small text-muted"><Translate id='components.communications.modal.label.no.contacts.please' /></span>}
                                        </UserDisplay.Title>
                                    </UserDisplay.Container>
                                </UserDisplay>
                            </CrossFade>
                            <CrossFade isVisible={isFetchingContacts}>
                                <div className="position-relative d-flex align-items-center justify-content-center">
                                    <Skeleton height={56} />
                                    <span className="position-absolute font-bold" style={{ zIndex: 3000 }}>
                                        <Loader color="gray" variant="dots" />
                                    </span>
                                </div>
                            </CrossFade>
                            <CrossFade unmountOnExit isVisible={contacts.length > 0 && !isFetchingContacts}>
                                <DisplayAllSelectedContacts
                                    contacts={contacts}
                                    tableRef={tableRef}
                                />
                            </CrossFade>
                            <FormikApiError className="mb-0" />
                        </div>
                        {contacts.length > 0 &&
                            <div className="d-flex align-items-start justify-content-end mt-2">
                                <Tooltip
                                    className="flex-shrink-0"
                                    wrapLines
                                    withArrow
                                    width={200}
                                    zIndex={3000}
                                    label={<Translate values={{ count: contacts.filter((c) => c.checked).length }} id="communications.message.recipients.download.selected" />}
                                >
                                    <Button onClick={handleOnDownloadRecipients(contacts.filter((c) => c.checked))} className="rounded-lg" type="button" color="light" size="sm">
                                        <Translate id='misc.download' /> <i className="mdi mdi-download" />
                                    </Button>
                                </Tooltip>
                            </div>
                        }
                    </ModalBody>
                    <ReviewFooter
                        nextBtn={<Button disabled={isLoading || isFetchingContacts || formik.values.organisation_id.length <= 0} color="primary" type="submit"><Translate id="misc.next" /></Button>}
                    />
                </Form>
            )}
        </Formik>
    );
}

export default ReviewOrgs;