import { useContext, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Container, Badge } from 'reactstrap';
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate, { DateFormat } from '@spordle/intl-elements';
import SidePanelOnlineStoreSearch from './SidePanelOnlineStoreSearch';
import SpordlePanelTable from '../../../components/sidePanel/SpordlePanel';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { AxiosCancelAll } from '../../../api/CancellableAPI';
// Contexts
import { OnlineStoreContext } from '../../../contexts/OnlineStoreContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import EmptyLayout from '../../../components/table/EmptyLayout';
import { I18nContext } from '../../../contexts/I18nContext';
import CanDoAction from '../../../components/permissions/CanDoAction';
import { PeriodsContext } from '../../../contexts/contexts';
import { PeriodChangeHandler } from '../../../helpers/periodHelper';

const OnlineStoreSearch = (props) => {
    const onlineStoreContext = useContext(OnlineStoreContext);
    const i18nContext = useContext(I18nContext);
    const periodsContext = useContext(PeriodsContext);

    useLayoutEffect(() => AxiosCancelAll, []);

    return (
        <SpordlePanelTable
            allowOutsideClick
            sidePanel={(props) => <SidePanelOnlineStoreSearch {...props} />}
            dataIndex='online_store_id'
            table={(panelProps) => (
                <SpordleTableProvider
                    id='OnlineStoreSearchTable'
                    ref={panelProps.spordleTableRef}
                    tableHover bordered striped
                    clickable
                    searchKeys={[
                        `i18n.${i18nContext.getGenericLocale()}.name`,
                        'name',
                        'start_date',
                        'end_date',
                        'status',
                    ]}
                    dataIndex='online_store_id'
                    columns={[
                        {
                            label: <Translate id='form.fields.order' />,
                            key: "display_order",
                            fallbackSortKey: "start_date",
                            sortable: true,
                        },
                        {
                            label: <Translate id='onlineStore.search.table.column.title' />,
                            key: "name",
                            mobile: true,
                            sortable: true,
                            sortKey: () => `i18n.${i18nContext.getGenericLocale()}.name`,
                            fallbackSortKey: 'name',
                        },
                        {
                            label: <Translate id='onlineStore.search.table.column.startDate' />,
                            key: "start_date",
                            mobile: true,
                            sortable: true,
                        },
                        {
                            label: <Translate id='onlineStore.search.table.column.endDate' />,
                            key: "end_date",
                            mobile: true,
                            sortable: true,
                        },
                        {
                            label: <Translate id='onlineStore.search.status' />,
                            key: "status",
                            mobile: true,
                            sortable: true,
                        },
                    ]}
                    onColumnClick={(e, data) => {
                        switch (e.button){
                            case 0: // Left mouse button
                                panelProps.onSingleSelectChange(data);
                                break;
                        }
                    }}
                    renderRow={(key, onlineStore) => {
                        switch (key){
                            case 'name':
                                return (
                                    <Link to={{ pathname: `/onlineregistration/profile/${onlineStore.online_store_id}`, data: onlineStore }}>
                                        <DisplayI18n field='name' i18n={onlineStore.i18n} defaultValue={onlineStore.name} />
                                        <i className="mdi mdi-chevron-right" />
                                    </Link>
                                );
                            case "start_date":
                                return (
                                    <>
                                        <DateFormat value={onlineStore.start_date} /> <DateFormat value={onlineStore.start_date} format={'HH:mm'} />
                                    </>
                                )
                            case "end_date":
                                return (
                                    <>
                                        <DateFormat value={onlineStore.end_date} /> <DateFormat value={onlineStore.end_date} format={'HH:mm'} />
                                    </>
                                )
                            case "status":
                                if(onlineStore.status === 'CLOSE'){
                                    return <Badge color="danger"><Translate id={`onlineStore.search.status.${onlineStore.status}`} /></Badge>
                                }else if(onlineStore.status === 'DRAFT' || onlineStore.status === 'UNDER_CREATION'){
                                    return <Badge color="warning"><Translate id={`onlineStore.search.status.${onlineStore.status}`} /></Badge>
                                }else if(onlineStore.status === 'ACTIVE'){
                                    return <Badge color="success"><Translate id={`onlineStore.search.status.${onlineStore.status}`} /></Badge>
                                }
                            default:
                                break;
                        }
                    }}
                    emptyLayout={<EmptyLayout flipArrow translateMsg msg='onlineStore.search.table.emptyLayout.noResultMessage' />}
                    desktopWhen='sm'
                    rowIsHighlighted={(data) => panelProps.selectedRows[0]?.online_store_id === data.online_store_id}
                    defaultSorting='+display_order'
                    pagination={10}
                    paginationMessage='onlineStore.search.table.paginationMessage'
                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                    loadData={(from, filterData, spordleTable) => {
                        switch (from){
                            case 'REFRESH':
                                spordleTable.setLoading();
                            case 'CDM':
                                return onlineStoreContext.getOnlineStores({ status: [ 'ACTIVE', 'DRAFT', 'CLOSE' ], period_id: periodsContext.selectedPeriod.period_id });
                            default:
                                break;
                        }
                    }}
                >
                    {/*--------------------------------------------------------------------------------*/}
                    {/* View Header                                                                  */}
                    {/*--------------------------------------------------------------------------------*/}
                    <ViewHeaderV2 title='onlineStore.search.title' />

                    <div className="page-content container-fluid px-4 pt-0 mt-0 mt-sm-n4">
                        <Container className="ml-0" style={{ maxWidth: "none" }}>
                            <PeriodChangeHandler useRefreshTable />

                            {/* CREATE STORE BUTTON */}
                            <CanDoAction className="w-100 pb-3 d-flex align-items-center justify-content-end" action='ADD' componentPermissionCode='online_store_manage' componentCode='page'>
                                <Button to='/onlineregistration/create' tag={Link} color='primary' className='ml-2'>
                                    <i className='mdi mdi-plus mr-1' /><Translate id='onlineStore.search.btn.create' />
                                </Button>
                            </CanDoAction>

                            {/* TABLE */}
                            <Card body className='card-shadow'>
                                {/* TABLE SEARCH & EXPORT */}
                                <div className="w-100 mb-2 d-flex align-items-center justify-content-between flex-wrap">
                                    <SpordleTableProvider.SearchInput className='mb-2 mb-sm-0' />

                                    <div className='d-flex ml-0 ml-sm-auto text-right'>
                                        <SpordleTableProvider.Refresh />
                                        <UncontrolledDropdown className='ml-2'>
                                            <DropdownToggle color='primary' outline caret disabled>
                                                <i className='mdi mdi-download mr-1' /><Translate id='misc.export' />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem disabled><i className='fas fa-file-excel mr-1' /><Translate id='misc.excel' /></DropdownItem>
                                                <DropdownItem disabled><i className='fas fa-file-pdf mr-1' /><Translate id='misc.pdf' /></DropdownItem>
                                                <DropdownItem disabled><i className='fas fa-print mr-1' /><Translate id='misc.print' /></DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>

                                </div>
                                <SpordleTableView />
                            </Card>
                        </Container>
                    </div>
                </SpordleTableProvider>
            )}
        />
    )
}

export default OnlineStoreSearch;