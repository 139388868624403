export default{
    "clinics.search.advancedSearch.select.placeholder.search" : "Rechercher...",
    "clinics.search.advancedSearch.select.placeholder.select" : "Sélectionner...",

    "clinics.search.advancedSearch.organization.label"                  : "Organisation",
    "clinics.search.advancedSearch.startDate.label"                     : "De",
    "clinics.search.advancedSearch.endDate.label"                       : "À",
    "clinics.search.advancedSearch.onlySelectedOrgs.label"              : "Afficher uniquement les stages hébergés par les organisations sélectionnées",
    "clinics.search.advancedSearch.category.label"                      : "Catégories",
    "clinics.search.advancedSearch.category.disabled"                   : "Actuellement désactivé",
    "clinics.search.advancedSearch.category.textCountLabel"             : "{count} catégories sélectionnées",
    "clinics.search.advancedSearch.category.noOptions"                  : "Aucune catégorie",
    "clinics.search.advancedSearch.qualification.label"                 : "Qualifications",
    "clinics.search.advancedSearch.qualification.textCountLabel"        : "{count} qualifications sélectionnées",
    "clinics.search.advancedSearch.qualification.noOptions"             : "Aucune qualification",
    "clinics.search.advancedSearch.status.label"                        : "Statut",
    "clinics.search.advancedSearch.status.option.DRAFT"                 : "Brouillon",
    "clinics.search.advancedSearch.status.option.PRIVATE"               : "Privée",
    "clinics.search.advancedSearch.status.option.OPEN_PUBLIC_SHOW_LIST" : "Ouvert au public",
    "clinics.search.advancedSearch.status.option.OPEN_PUBLIC_SIGNUP"    : "Inscription ouverte",
    "clinics.search.advancedSearch.status.option.UNDER_CREATION"        : "En création",
    "clinics.search.advancedSearch.status.textCountLabel"               : "{count} statuts sélectionnés",
    "clinics.search.advancedSearch.clinicFormat.label"                  : "Format de stage",
    "clinics.search.advancedSearch.clinicFormat.label.ONLINE"           : "En ligne - Sur demande",
    "clinics.search.advancedSearch.clinicFormat.label.ONLINE_LIVE"      : "En ligne - En direct",
    "clinics.search.advancedSearch.clinicFormat.label.ONSITE"           : "En personne",
    "clinics.search.advancedSearch.moreFilters"                         : "Plus de filtres",
    "clinics.search.advancedSearch.lessFilters"                         : "Moins de filtres",
}