import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import moment from "moment";
import { useContext } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../components/table/EmptyLayout";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";
import { IdentityRolesContext } from "../../../contexts/IdentityRolesContext";
import UsersSettingsSearch from "./UsersSettingsSearch";
import UsersSettingsSidePanel from "./UsersSettingsSidePanel";

const UsersSettings = () => {
    const { getAccounts } = useContext(IdentityRolesContext);

    return (
        <>
            {/* Settings View Header */}
            <ViewHeaderV2
                title='sidebar.settings.users'
                breadcrumbsProps={{
                    disablePeriodSelect: true,
                }}
            />

            <div className="px-4">
                <Container>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Tabs                                                                  */}
                    {/*--------------------------------------------------------------------------------*/}

                    <SpordlePanelTable
                        allowOutsideClick
                        sidePanel={(sProps) => <UsersSettingsSidePanel {...sProps} />}
                        dataIndex='identity_id'
                        table={(panelProps) => {
                            return (
                                <SpordleTableProvider
                                    id='UsersSettingsTable'
                                    ref={(r) => { panelProps.spordleTableRef(r); }}
                                    tableHover bordered striped
                                    clickable
                                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                    searchKeys={[
                                        'name',
                                        'email',
                                        'family_name',
                                    ]}
                                    emptyLayout={<EmptyLayout />}
                                    dataIndex='identity_id'
                                    defaultSorting='+name'
                                    columns={[
                                        {
                                            label: <Translate id='organization.profile.users.table.name' />,
                                            key: "name",
                                            sortKey: "name",
                                            fallbackSortKey: "family_name",
                                            mobile: true,
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='organization.profile.users.table.email' />,
                                            key: "email",
                                            mobile: true,
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='organization.profile.users.table.status' />,
                                            key: "active",
                                            mobile: true,
                                            sortable: true,
                                        },
                                    ]}
                                    desktopWhen
                                    pagination={10}
                                    onColumnClick={(e, user) => {
                                        switch (e.button){
                                            case 0: // Left mouse button
                                                panelProps.onSingleSelectChange(user);
                                                break;
                                        }
                                    }}
                                    columnSorting={(a, b, _sTable, orderData) => {
                                        const getNb = (identity) => {
                                            let nb = "0";

                                            if(identity.end_date && !moment().isBefore(identity.end_date)){
                                                nb = "1";
                                            }else if(!identity.signin_lock_date){
                                                nb = identity.active === '1' ? "2" : "3";
                                            }

                                            return nb;
                                        }
                                        switch (orderData.column){
                                            case 'active':
                                                const aNb = getNb(a);
                                                const bNb = getNb(b);

                                                if(orderData.order === 'ASC'){
                                                    return aNb > bNb ? 1 : aNb < bNb ? -1 : 0
                                                }
                                                return aNb > bNb ? -1 : aNb < bNb ? 1 : 0;
                                            default:
                                                break;
                                        }
                                    }}
                                    renderRow={(columnKey, identity, spordleTable) => {
                                        switch (columnKey){
                                            case 'name':
                                                return identity.name + ' ' + identity.family_name;
                                            case 'active':
                                                return (
                                                    <div className={spordleTable.isMobile() ? undefined : 'text-center'}>
                                                        {identity.signin_lock_date !== null ?
                                                            <i className="text-danger mdi mdi-lock-outline" />
                                                            : identity.end_date && !moment().isBefore(identity.end_date) ?
                                                                <span className='text-danger'><Translate id="organization.profile.users.table.status.expired" /></span>
                                                                : identity.active === '1' ?
                                                                    <i className="text-primary mdi mdi-check" />
                                                                    :
                                                                    <i className="text-danger mdi mdi-close" />
                                                        }
                                                    </div>
                                                )
                                            default:
                                                break;
                                        }
                                    }}
                                    rowIsHighlighted={(user) => !!user.checked}
                                    loadingStatus={"success"}
                                    loadData={(from, { filters }, { setLoading }) => {
                                        switch (from){
                                            case 'FILTER':
                                            case 'REFRESH':
                                                setLoading(true);
                                                return getAccounts(filters.search);
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    <>
                                        <Card body className="card-shadow">
                                            <UsersSettingsSearch />
                                        </Card>

                                        <Card body className="card-shadow">
                                            <Row className='mb-3'>
                                                <Col md='6'>
                                                    <SpordleTableProvider.SearchInput />
                                                </Col>
                                                <Col md='6'>
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <SpordleTableProvider.Refresh />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <SpordleTableView />
                                        </Card>
                                    </>
                                </SpordleTableProvider>
                            )
                        }}
                    />
                </Container>
            </div>
        </>
    )
}

export default UsersSettings;