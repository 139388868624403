import catalogFormsTabsRoutes from '../tabRoutes/catalogFormsTabsRoutes';
import catalogTermsTabsRoutes from '../tabRoutes/catalogTermsTabsRoutes';
import catalogWaiversTabsRoutes from '../tabRoutes/catalogWaiversTabsRoutes';

// Components
import AffiliationFees from '../../views/catalog/affiliationFees/AffiliationFees';
import ClinicItems from '../../views/catalog/clinicItems/ClinicItems';
import Forms from '../../views/catalog/forms/Forms';
import Installment from '../../views/catalog/installment/Installment';
import OtherItems from '../../views/catalog/otherItems/OtherItems';
import Rebates from '../../views/catalog/rebates/Rebates';
import RegistrationFees from '../../views/catalog/registrationFees/RegistrationFees';
import Terms from '../../views/catalog/terms/Terms';
import Waivers from '../../views/catalog/waivers/Waivers';

const catalogSidebar = [
    {
        navlabel: true,
        name: 'sidebar.catalog.productsAndServices',
        subPermissionsRoutes: [
            {
                path: '/catalog/registrationfees',
                name: 'sidebar.catalog.registrationFees',
                component: RegistrationFees,
                permissions: { code: 'catalog', permissionCode: 'registration_fees' },
            },
            {
                path: '/catalog/membershipfees',
                name: 'sidebar.catalog.membershipFees',
                component: AffiliationFees,
                permissions: { code: 'catalog', permissionCode: 'affiliation_fees' },
            },
            {
                path: '/catalog/otheritems',
                name: 'sidebar.catalog.otherItems',
                component: OtherItems,
                permissions: { code: 'catalog', permissionCode: 'other_fees' },
            },
            {
                path: '/catalog/clinicitems',
                name: 'sidebar.catalog.clinicItems',
                component: ClinicItems,
                permissions: { code: 'catalog', permissionCode: 'qualification_fees' },
            },
        ],
    },

    {
        navlabel: true,
        name: 'sidebar.catalog.legalAndForms',
        subPermissionsRoutes: [
            {
                path: '/catalog/forms',
                name: 'sidebar.catalog.forms',
                component: Forms,
                permissions: { code: 'catalog', permissionCode: 'form_settings' },
                tabRoutes: catalogFormsTabsRoutes,
            },
            {
                path: '/catalog/waivers',
                name: 'sidebar.catalog.waivers',
                component: Waivers,
                permissions: { code: 'catalog', permissionCode: 'organization_waivers' },
                tabRoutes: catalogWaiversTabsRoutes,
            },
            {
                path: '/catalog/terms',
                name: 'sidebar.catalog.terms',
                component: Terms,
                permissions: { code: 'catalog', permissionCode: 'terms_and_conditions' },
                tabRoutes: catalogTermsTabsRoutes,
            },
        ],
    },

    {
        navlabel: true,
        name: 'sidebar.catalog.finances',
        subPermissionsRoutes: [
            {
                path: '/catalog/rebates',
                name: 'sidebar.catalog.rebates',
                component: Rebates,
                permissions: { code: 'catalog', permissionCode: 'rebate' },
            },
            {
                path: '/catalog/installments',
                name: 'sidebar.catalog.installments',
                component: Installment,
                permissions: { code: 'catalog', permissionCode: 'installment_mode' },
            },
        ],
    },
];
export default catalogSidebar;
