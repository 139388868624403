export default{
    "tour.step.1.title"   : "Nouvelle interface et navigation",
    "tour.step.1.message" : "Nous avons mis à jour {spordle_id} pour vous rendre la vie plus facile!",

    "tour.step.2.title"   : "Tableau de bord",
    "tour.step.2.message" : "Un nouveau tableau de bord qui regroupe l'information essentielle pour vos tâches quotidiennes. ",

    "tour.step.3.title.old"   : "Gestion",
    "tour.step.3.message.old" : "Gérer tous les aspects de votre organisation.",

    "tour.step.3.title"   : "Navigation principale",
    "tour.step.3.message" : "Tout ce que vous recherchez peut être trouvé à travers les menus de navigation d'en-tête.",

    "tour.step.4.title"   : "Tâches",
    "tour.step.4.message" : "Compléter les tâches pour votre organisation.",

    "tour.step.5.title"   : "Catalogue",
    "tour.step.5.message" : "Configurez et lancez simplement votre saison d'inscription.",

    "tour.step.6.title"   : "Paramètres",
    "tour.step.6.message" : "Configurer les paramètres de votre organisation et de l'inscription.",

    "tour.step.7.title"   : "Menu Latéral",
    "tour.step.7.message" : "Naviguez avec un menu raffiné afin d'améliorer l'accès aux modules voulus.<break></break><break></break>Historique & Favoris: Deux fonctionnalités qui peuvent vous aider à vous déplacer plus rapidement.",

    "tour.step.helpCenter.title"   : "Centre d'aide",
    "tour.step.helpCenter.message" : "Notre nouveau centre d'aide peut maintenant être déplacé dans l'écran.<break></break><break></break>Ceci vous permettra de conserver le Centre d'Aide ouvert pendant que vous naviguez. Le contenu affiché est relié automatiquement à la page sur laquelle vous naviguez.",

    "tour.step.8.title"   : "Votre profil",
    "tour.step.8.message" : "Ici, vous pouvez rapidement accéder à votre compte et à vos demandes de support ainsi qu'à la déconnexion.",

    "tour.step.9.title"   : "Basculer vers l'ancienne navigation",
    "tour.step.9.message" : "Vous pouvez basculer en tout temps vers l'ancienne navigation. Laissez nous un commentaire pour que nous puissions améliorer votre expérience.",

    "tour.step.10.title"   : "Vous êtes prêts !",
    "tour.step.10.message" : "Nous espérons que cette nouvelle navigation va améliorer votre expérience au quotidien avec la plateforme. Nous sommes à l'écoute de vos commentaires, n'hésitez pas à nous les soumettre en utilisant la bulle d'aide.",
    "tour.step.10.optin"   : "Ne plus voir",
    "tour.step.gotIt.btn"  : "Compris",

    "tour.step.reports.title"   : "Rapports",
    "tour.step.reports.message" : "Notre nouveau tableau de bord de rapports vous permet d'accéder rapidement à vos rapports préférés. Nous avons réorganisé les rapports pour rendre la navigation plus simple.",

    "tour.step.quickViewBtn.title"   : "Aperçu rapide!",
    "tour.step.quickViewBtn.message" : "Vous pouvez cliquer sur ce pictogramme pour ouvrir un ou plusieurs profils dans un accès rapide. Ce pictogramme se situe à côté des noms des participants à travers la plateforme.",

    "tour.step.quickViewToggler.title"   : "Voir les aperçus enregistrés",
    "tour.step.quickViewToggler.message" : "Vous pouvez consulter à tout moment vos aperçus rapides en cliquant ici. Tant que vous ne fermez pas l'onglet de la fiche de participant, elle reste disponible dans cet onglet.",

    "tour.step.newOrgSettings.title"   : "Nouveaux paramètres d'organisation !",
    "tour.step.newOrgSettings.message" : "Nous avons combiné les informations de l'organisation et les paramètres dans une seule section.",

    "tour.step.newOrgSettings.general.title"   : "Général",
    "tour.step.newOrgSettings.general.message" : "L'onglet Général vous donne maintenant accès à toute l'information de base de votre organisation ainsi qu'aux paramètres de comptes marchands.",

    "tour.step.newOrgSettings.documents.title"   : "Documents",
    "tour.step.newOrgSettings.documents.message" : "Configurez les documents requis lors de la création d'un compte. Vous pouvez créer vous même des types de documents.",

    "tour.step.newOrgSettings.registrationSettings.title"   : "Paramètres d'inscription",
    "tour.step.newOrgSettings.registrationSettings.message" : "Remplissez ce formulaire pour nous fournir de l'information sur les dates importantes de la prochaine saison.   Ceci nous aider à planifier nos formations.",

    "tour.step.newOrgSettings.regionalSettings.title"   : "Paramètres régionaux et d'inscription",
    "tour.step.newOrgSettings.regionalSettings.message" : "Ici sont désormais situés les paramètres régionaux ainsi que les paramètres d'inscription.",

    "tour.step.newOrgSettings.crc.title"   : "Vérification d'antécédents",
    "tour.step.newOrgSettings.crc.message" : "Configurez les paramètres reliés au vérification d'antécédents pour votre organisation.",

    "tour.step.newOrgSettings.payments.title"   : "Comptes marchands",
    "tour.step.newOrgSettings.payments.message" : "Configurez les comptes marchands pour votre organisation.",

    "tour.step.saveReports.title"   : "Nouveaux rapports personnalisés!",
    "tour.step.saveReports.message" : "Il est désormais possible de sauvegarder un rapport personnalisé avec ce bouton.",

    "tour.step.reportsDashboard.title"           : "Les rapports sont encore en développement.",
    "tour.step.reportsDashboard.subtitle"        : "Des groupes et une meilleure accessibilité",
    "tour.step.reportsDashboard.message"         : "Prenez note que certains rapports, colonnes et/ou données pourraient être incorrects. Une version complète et fonctionnelle du module de rapport sera disponible la semaine prochaine. Surveillez la fenêtre contextuelle lors du lancement de la nouvelle version.",
    "tour.step.reportsDashboard.groups.title"    : "Groupes",
    "tour.step.reportsDashboard.groups.message"  : "Les listes de rapport ont été divisées en plusieurs groupes pour une navigation plus rapide.",
    "tour.step.reportsDashboard.custom.title"    : "Rapports personnalisés",
    "tour.step.reportsDashboard.custom.message"  : "Vous pouvez consulter vos rapports personnalisés ici.",
    "tour.step.reportsDashboard.exports.title"   : "Rapports exportés",
    "tour.step.reportsDashboard.exports.message" : "Vos rapports exportés sont listés ici.",
    "tour.step.reportsDashboard.search.title"    : "Barre de recherche",
    "tour.step.reportsDashboard.search.message"  : "Vous pouvez rechercher un rapport spécifique à l'aide de cette barre de recherche.",

    "tour.step.rollover.title"                  : "Copier vos frais d'inscription à la prochaine saison !",
    "tour.step.rollover.message"                : "Cette nouvelle fonctionnalité vous permettra de copier vos frais d'inscription d'un seul clic à partir d'une saison sélectionnée. Attention de ne pas les importer plusieurs fois.",
    "tour.step.rollover.season.title"           : "Saison",
    "tour.step.rollover.season.message"         : "Les frais seront créés dans la saison sélectionnée. Assurez vous de sélectionner la bonne période. ",
    "tour.step.rollover.previousSeason.title"   : "Saison précédente",
    "tour.step.rollover.previousSeason.message" : "Sélectionner la saison à partir de laquelle vous voulez importer et cliquez sur Recherche pour lancer le processus.",
}