import Translate from '@spordle/intl-elements';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CollapsibleCard from '../../../../components/collapsibleCard/CollapsibleCard';
import CustomAlert from '../../../../components/CustomAlert';
import MemberCard from './MemberCard';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const AllMembers = ({ validMembers, invalidMembers, memberStatus, toggle, setView, views, ...props }) => {

    return (
        <>
            <ModalHeader toggle={toggle}>
                <Translate id='members.search.sidePanelMemberSearch.multi.title.bulkUpdate' /> - <Translate id='members.search.sidePanelMemberSearch.multi.title.blockMembers' />
            </ModalHeader>
            <ModalBody>
                <div className='mb-3'>
                    <Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.topMessage.block' />
                </div>
                <CollapsibleCard
                    initOpen={validMembers.length <= 5}
                    title={<Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validTitle' values={{ members: validMembers.length }} />}
                    subtitle={<Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validText' />}
                >
                    <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }} className='px-3 mx-n3'>
                        {validMembers.length ?
                            validMembers.map(({ member }) => (
                                <MemberCard
                                    member={member}
                                    key={member.member_id}
                                    memberStatus={memberStatus}
                                />
                            ))
                            :
                            <Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validNone' />
                        }
                    </PerfectScrollbar>
                </CollapsibleCard>

                {!!invalidMembers.length &&
                    <CollapsibleCard
                        error
                        title={<Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.invalidTitle' values={{ members: invalidMembers.length }} />}
                        subtitle={<Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.invalidText' />}
                        className='mt-3'
                    >
                        <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }} className='px-3 mx-n3'>
                            {invalidMembers.map(({ member }) => (
                                <MemberCard
                                    member={member}
                                    withStatus
                                    key={member.member_id}
                                />
                            ))}
                        </PerfectScrollbar>
                    </CollapsibleCard>
                }

                {!validMembers.length &&
                    <CustomAlert
                        color='danger'
                        className='mt-3 mb-0'
                        text='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.actionNone'
                        translateText
                    />
                }
            </ModalBody>
            <ModalFooter>
                <Button color='primary' type='button' onClick={() => setView(views.validMembers)} disabled={!validMembers.length}><Translate id='misc.confirm' /></Button>
                <Button color='primary' type='button' outline onClick={toggle} className='ml-2'><Translate id='misc.cancel' /></Button>
            </ModalFooter>
        </>
    )
}

export default AllMembers