import { FormikSelect } from "@spordle/formik-elements";
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import { Collapse, FormGroup, Label } from "reactstrap";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { useContext } from "react";
import Required from "../../../../../components/formik/Required";
import { formatSelectData } from "@spordle/spordle-select";
import { sortRessource } from "./categorySelectsHelper";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import DisplayOrg from "../../../../../components/DisplayOrg";

const RegistrationFeeDivisionSelect = ({ divisions, selectDivision, selectedDivisionFees }) => {
    const formik = useFormikContext();
    const { getGenericLocale } = useContext(I18nContext);
    const { organisation_id, settings } = useContext(OrganizationContext);
    const canManageAge = settings.manage_min_max_age_registration_fee?.value == 1;
    const options = formatSelectData(
        (divisions || []).sort((a, b) => {
            const order = sortRessource(a, b, organisation_id, getGenericLocale());

            if(order !== 0)return order;

            if(a.valid_affiliation == b.valid_affiliation){
                return parseInt(a.display_order) - parseInt(b.display_order);
            }

            return a.valid_affiliation == 1 ? -1 : 1;
        }),
        {
            getGroupId: (data) => data.organisation?.organisation_id + data.valid_affiliation,
            getValue: (data) => data.division_id,
            getLabel: (data) => data.short_name,
            createGroupWithId: (data) => ({
                organisation: data.organisation,
                valid: data.valid_affiliation,
                label: data.organisation?.organisation_name,
            }),
        },

    );

    const manageAge = (myDivision) => {
        formik.setFieldValue('max_age', myDivision.max_age ? parseInt(myDivision.max_age) : "")

        if(myDivision.min_age){
            formik.setFieldValue('min_age', parseInt(myDivision.min_age))
            formik.setFieldTouched('min_age', false)
        }else{
            formik.setFieldValue('min_age', "")
        }
    }

    return (
        <FormGroup>
            <Label for="division_id" className="text-muted"><Translate id='catalog.registrationFees.add.form.division' /> <Required /></Label>
            <FormikSelect
                name='division_id'
                id='division_id'
                isOptionDisabled={(option) => option.valid_affiliation == 0}
                onOptionSelected={([ value ]) => {
                    const myDivision = (divisions || []).find((div) => div.division_id === value);

                    selectDivision(value, formik.values.member_type_id);
                    formik.setFieldValue('team_category_id', '');

                    if(canManageAge){
                        manageAge(myDivision);
                    }

                }}
                searchKeys={[
                    { name: `i18n.${getGenericLocale()}.short_name`, weight: 2 },
                    'short_name',
                ]}
                renderSelectedOption={(option) => <DisplayI18n field="short_name" defaultValue={option.label} i18n={option.i18n} />}
                renderOption={({ option, isSelected }) => (
                    option.isGroup ?
                        <>
                            <DisplayOrg org={option.organisation} />
                            {option.valid == 0 && <> - <Translate id="misc.inactive" /></>}
                        </>
                        :
                        <>
                            <div className="font-medium">
                                <DisplayI18n field="short_name" defaultValue={option.label} i18n={option.i18n} />
                            </div>
                            {(option.birth_year_from && option.birth_year_to) &&
                                <div className={`text-${isSelected ? 'light' : 'muted'} small`}>
                                    {option.birth_year_from != option.birth_year_to ?
                                        <Translate id='catalog.affiliationFees.division.birthYear' values={{ minYear: option.birth_year_from, maxYear: option.birth_year_to }} />
                                        :
                                        <><Translate id='registration.settings.category.label.birthYear' /> {option.birth_year_from}</>
                                    }
                                </div>
                            }
                        </>
                )}
                isLoading={!divisions}
                options={options}
            />
            <Collapse isOpen={!!formik.values.division_id}>
                <span className="small"><Translate id='catalog.registrationFees.add.form.membershipFee' />: <CurrencyFormat value={selectedDivisionFees.amount / 100} /></span>
            </Collapse>
            {(divisions || []).find((div) => div.valid_affiliation == 0) &&
                <div className="small text-muted">
                    <Translate id='catalog.registrationFees.add.form.division.disabled' />
                </div>
            }
        </FormGroup>
    );
}

export default RegistrationFeeDivisionSelect;