import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import { DisplayPhoneNumber } from '@spordle/formik-elements';
import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useRef, useState } from 'react';
import SpordlePanelTable from '../../../../../../components/sidePanel/SpordlePanel';
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import { ClinicsContext } from '../../../../../../contexts/ClinicsContext';
import ContactsInstructorsSidepanel from '../ContactsInstructorsSidepanel';
import images from '@spordle/ui-kit';
import FormClinicAddContactModal from '../AddContactModal/FormClinicAddContactModal';
import { AuthContext } from '../../../../../../contexts/contexts';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import { fail, success } from '@spordle/toasts';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';
const noProfileLogo = images.noprofile;

const InsructorsTable = (props) => {

    const clinicsContext = useContext(ClinicsContext);
    const authContext = useContext(AuthContext);
    const authContextContact = {
        firstName: authContext.account.name,
        lastName: authContext.account.family_name,
        email: authContext.account.email,
        phone: authContext.account.phone_number || '',
    }
    const tableRef = useRef();

    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const toggleModal = () => setModalIsOpen(!modalIsOpen);

    const [ addMyselfDisabled, setAddMyselfDisabled ] = useState(false);

    useEffect(() => {
        tableRef.current?.refreshTable();
    }, [ props.instructorsKey ])

    const alreadyExists = async(contact, formattedInstructors) => {
        const newCompare = contact.firstName + '-' + contact.lastName + '-' + contact.email + '-' + contact.phone;
        const compare = (row) => newCompare === row.firstName + '-' + row.lastName + '-' + row.email + '-' + row.phone;
        const instructorsArray = formattedInstructors || tableRef.current.getData()

        let found = false;
        if(instructorsArray.find((row) => compare(row)))
            found = 'instructor'

        return found;
    }

    const onUpdate = async() => {
        const response = await alreadyExists(authContextContact)
        setAddMyselfDisabled(response === 'instructor')
    }

    return (
        <>
            {/* INSTRUCTOR MODAL */}
            <FormClinicAddContactModal
                isOpen={modalIsOpen}
                toggle={toggleModal}
                onCreate={() => {
                    tableRef.current.refreshTable();
                }}
                alreadyExists={alreadyExists}
                clinicId={props.clinicId}
                isInstructor
            />

            <SpordlePanelTable
                allowOutsideClick
                sidePanel={(panelProps) => <ContactsInstructorsSidepanel {...panelProps} isContact={false} clinicId={props.clinicId} onUpdate={onUpdate} />}
                dataIndex='clinicInstructorId'
                table={(panelProps) => {
                    return (
                        <SpordleTableProvider
                            id='instructors'
                            tableHover clickable
                            viewMode='GRID'
                            ref={(r) => { tableRef.current = r; panelProps.spordleTableRef(r) }}
                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                            dataIndex='clinicInstructorId'
                            onColumnClick={(e, contact) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        if(contact.clinicInstructorId === 'template'){
                                            toggleModal();
                                        }else{
                                            panelProps.onSingleSelectChange(contact);
                                        }
                                        break;
                                }
                            }}
                            gridConfig={{
                                col: {
                                    sm: 12,
                                    md: 6,
                                    lg: 4,
                                    className: 'mb-3',
                                },
                                gridRender: (instructor) => {
                                    if(instructor.clinicInstructorId === 'template'){
                                        return (
                                            <CanDoAction action='ADD' componentCode='clinics' componentPermissionCode='clinics_manage'>
                                                <UserDisplay
                                                    card
                                                    hover
                                                    className={stringBuilder('w-100 mb-0 border-primary', { 'shadow-danger': instructor.errors })}
                                                    style={{ minWidth: 'unset', borderStyle: 'dashed', borderWidth: '1px' }}
                                                >
                                                    <UserDisplay.Container>
                                                        <div className="border rounded-circle shadow-sm p-2">
                                                            <img className="object-fit-contain" src={noProfileLogo} width="40" height="40" alt='templateCard' />
                                                        </div>
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container>
                                                        <UserDisplay.Title tag="span" className='text-link'><i className='mdi mdi-plus' /> <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor' /></UserDisplay.Title>
                                                    </UserDisplay.Container>
                                                </UserDisplay>

                                                {instructor.errors &&
                                                    <div className='small text-danger mt-2'><Translate id={'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.' + instructor.errors[0]} /></div>
                                                }
                                            </CanDoAction>
                                        )
                                    }
                                    return (
                                        <UserDisplay card hover className='w-100 mb-0' style={{ minWidth: 'unset' }}>
                                            <UserDisplay.Container>
                                                <div className="border rounded-circle shadow-sm p-2">
                                                    <img className="object-fit-contain" src={instructor.logo ?? noProfileLogo} width="40" height="40" alt={`${instructor.firstName} ${instructor.lastName}`} />
                                                </div>
                                            </UserDisplay.Container>
                                            <UserDisplay.Container style={{ minWidth: 0 }}>
                                                <UserDisplay.Title tag="span">{instructor.firstName} {instructor.lastName}</UserDisplay.Title>
                                                {instructor.member?.unique_identifier && <UserDisplay.Subtitle>#{instructor.member.unique_identifier}</UserDisplay.Subtitle>}
                                                <UserDisplay.Subtitle className="text-truncate">{instructor.email}</UserDisplay.Subtitle>
                                                <UserDisplay.Subtitle><DisplayPhoneNumber phoneString={instructor.phone} withExtension format='INTERNATIONAL' /></UserDisplay.Subtitle>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    )

                                },
                            }}
                            desktopWhen='md'
                            rowIsHighlighted={(instructor) => !!instructor.checked}
                            loadData={(from, extra, spordleTable) => {
                                switch (from){
                                    case 'REFRESH':
                                        spordleTable.setLoading();
                                    case 'CDM':
                                        return clinicsContext.getClinicInstructors(props.clinicId)
                                            .then(async(instructors) => {
                                                const formattedInstructors = instructors.map((instructor) => ({
                                                    firstName: instructor.first_name,
                                                    lastName: instructor.last_name,
                                                    email: instructor.email,
                                                    phone: instructor.phone || '',
                                                    clinicInstructorId: instructor.clinic_instructor_id,
                                                    clinicPermission: instructor.clinic_permission,
                                                    hasIdentity: !!instructor.identity,
                                                    member: instructor.member,
                                                    identity: instructor.identity,
                                                    showStatus: instructor.visibility_on_website,
                                                }))
                                                formattedInstructors.push({ clinicInstructorId: 'template' })

                                                const response = await alreadyExists(authContextContact, formattedInstructors)
                                                setAddMyselfDisabled(response === 'instructor')

                                                return formattedInstructors
                                            })
                                }
                            }}
                        >
                            {(spordleTable) => (
                                <>
                                    <div className={stringBuilder('d-flex mb-2', { 'border-bottom mb-3 pb-1': props.wizard })}>
                                        <div className={`font-bold mb-0 ${props.wizard ? 'h3 card-title' : 'h5 '}`}>
                                            <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.title' values={{ count: spordleTable.getData().filter((row) => row.clinicInstructorId !== 'template').length }} />
                                        </div>

                                        <CanDoAction action='ADD' componentCode='clinics' componentPermissionCode='clinics_manage' className='ml-auto'>
                                            <button
                                                className='ml-auto btn btn-link p-0'
                                                disabled={addMyselfDisabled}
                                                type='button'
                                                onClick={async() => {
                                                    return clinicsContext.createInstructor(props.clinicId, authContextContact)
                                                        .then(() => {
                                                            success();
                                                            spordleTable.refreshTable();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                }}
                                            >
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.myself' />
                                            </button>
                                        </CanDoAction>
                                    </div>
                                    <SpordleTableView />
                                </>
                            )}
                        </SpordleTableProvider>
                    )
                }}
            />
        </>
    )
}

export default InsructorsTable