import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Button,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Alert,
    Fade,
    Collapse,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import { object, string, mixed, number } from 'yup';
import { Formik, Form } from 'formik';
import { FormikDateTime, FormikSelect, FormikCheckedButton, FormikGroup, FormikCurrencyInput } from '@spordle/formik-elements';
import UserDisplay from '../../../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../../../components/UserImg';
import Required from '../../../../../../../components/formik/Required';
import moment from 'moment';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import CustomAlert from '../../../../../../../components/CustomAlert';
import { fail } from '@spordle/toasts';

// Contexts
import withContexts from '../../../../../../../helpers/withContexts';
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext';
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import { PeriodsContext } from '../../../../../../../contexts/contexts';
import { PositionsContext } from '../../../../../../../contexts/PositionsContext';
import { CartsContext } from '../../../../../../../contexts/CartsContext';
import { FormsContext } from '../../../../../../../contexts/FormsContext';
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { RegistrationDivisionsContext } from '../../../../../../../contexts/RegistrationDivisionsContext';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import MemberProfileRegistrationAdd1Simple from './MemberProfileRegistrationAdd1Simple';
import MemberProfileRegistrationAdd1Position from './MemberProfileRegistrationAdd1Position';
import { WaiversContext } from '../../../../../../../contexts/WaiversContext';
import CrossFade from '../../../../../../../components/crossFade/CrossFade';
import MemberStatusIcons from '../../../../../search/components/MemberStatusIcons';
import { Tooltip } from '@mantine/core';
import CollapsibleCard from '../../../../../../../components/collapsibleCard/CollapsibleCard';
import StatusBadge from '../../../../../../../components/badges/StatusBadge';
import { MerchantAccountContext } from '../../../../../../../contexts/MerchantAccountContext';

const NONE_VALUE = 'none';
const MANUAL_REGISTRATION_VALUE = 'manualRegistration';

class MemberProfileRegistrationAdd1 extends React.Component{
    constructor(props){
        super(props);

        // if the exchange item contains an other_fee, that means we need to force select the "No registration" option
        // we wanted to totally skip this step to avoid confusion, but we need to let the user change the registration date and time in this step first
        // we also need to display an other items select to select the item that will replace the initial one
        // we cannot go to the other items step yet because we need to filter out the initial item from the list
        // we still let the user go to the other items step afterwards, in case he wants to add another other item to the cart
        this.simpleView = !!props.memberProfileRegistrationAddContext.viewsInfo.selectedExchangeItem?.other_fee

        this.waitingListReturnMode = props.memberProfileRegistrationAddContext.waitingListReturnMode

        this.hasCreditCard = this.props.MerchantAccountContext.merchantAccounts ? this.props.MerchantAccountContext.merchantAccounts?.some((m) => m.active == 1) : false
        this.hasPlatformFees = props.OrganizationContext.settings.fee_for_registration?.value === 'CLIENT' && this.hasCreditCard;

        this.state = {
            wrongOrganisation: false,
            registrationsSelectOptions: [],
            registrationsSelectLoading: true,
            showMemberTypes: true,
            selectedRegistration: false,
            selectedMemberTypeId: false,
            errors: {},
            affiliationFeesAmount: 0,
        }

        this.formik = null;
    }

    getRegistrations = (period_id, overwrite_restrictions = false) => {
        const _selectedPeriodId = period_id || this.props.PeriodsContext.selectedPeriod.period_id;

        this.props.OrganizationContext.getOrganisationRegistrations({
            period_id: _selectedPeriodId,
            member_id: this.props.MembersContext.currentMember.member_id,
            overwrite_restrictions: overwrite_restrictions | 0, //bitwise OR operator
        })
            .then(({ other_fees, registration_fees }) => {
                const _selectedPeriod = this.props.PeriodsContext.periods.filter((period) => period.period_id === _selectedPeriodId)[0]

                this.props.memberProfileRegistrationAddContext.viewsInfo.otherItems = other_fees.filter((oFee) => oFee.active == 1);

                // Creating the groups for the options according to the org
                const registrationsSelectOptions = registration_fees
                    .reduce((builtOptions, item) => {
                        if(item.active == 1 && (this.state?.selectedMemberTypeId === false || (!!this.state?.selectedMemberTypeId && this.state.selectedMemberTypeId === item.member_type.member_type_id))){

                            if(this.waitingListReturnMode && item.registration_fee_id === this.props.memberProfileRegistrationAddContext.waitingListReturnData?.registrationFeeId){
                                this.setState(() => ({
                                    selectedMemberTypeId: item.member_type.member_type_id,
                                }))
                                this.formik?.setFieldValue('member_type_id', item.member_type.member_type_id)
                                this.setSelectedRegistrationAndPrices(item)
                            }

                            builtOptions.push({
                                id: item.registration_fee_id,
                                value: item.registration_fee_id,
                                label: item.name,
                                i18n: item.i18n,
                                item: item,
                                isDisabled: item.already_registered || (item.sold_out == 1 && item.manage_waiting_list == 0),
                                withPosition: item.with_position == '1',
                            })
                        }
                        return builtOptions
                    }, [])

                if(this.props.memberProfileRegistrationAddContext.viewsInfo.otherItems.length > 0){
                    registrationsSelectOptions.unshift({
                        value: NONE_VALUE,
                        label: 'memberProfile.registration.form.registration.none',
                        translateLabel: true,
                    })
                }

                registrationsSelectOptions.push({
                    value: MANUAL_REGISTRATION_VALUE,
                    label: 'memberProfile.registration.form.registration.manual',
                    translateLabel: true,
                })

                const data = this.waitingListReturnMode ? {} : {
                    selectedRegistration: false,
                }
                this.setState({
                    selectedPeriod: _selectedPeriod,
                    wrongOrganisation: false,
                    registrationsSelectLoading: false,
                    registrationsSelectOptions: registrationsSelectOptions,
                    ...data,
                });
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    if(error.message === "3045"){
                        this.setState({ wrongOrganisation: true, selectedRegistration: false, registrationsSelectLoading: false });
                    }
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    showCartError = (errorCode) => {
        if(errorCode === "3045"){
            this.setState(() => ({ errors: { registrationAgeError: true } }));
        }else{
            this.setState(() => ({ errors: {} }));
            //this.props.CartsContext.clearCarts();
        }
    }

    setSelectedRegistrationAndPrices = (item) => {
        this.setState(() => ({
            selectedRegistration: item,
            affiliationFeesAmount: item?.affiliation_fees?.reduce((total, fee) => total += parseInt(fee?.fee?.amount), 0) / 100 || 0,
        }));
        if(item){
            this.formik?.setFieldValue('custom_price', parseInt(this.getAmountForCurrentTime(item)) / 100);
        }
    }

    getAmountForCurrentTime = (item) => {
        if(moment().isBefore(item.fee.early_amount_until)){ // Is early
            return item.fee.early_amount
        }else if(moment().isAfter(item.fee.late_amount_after)){ // Is late
            return item.fee.late_amount;
        }
        return item.fee.amount
    }

    componentDidMount(){
        if(!this.props.wireFrame)
            this.getRegistrations(undefined, this.waitingListReturnMode);
    }

    render(){
        return (
            <Formik
                innerRef={(r) => this.formik = r}
                initialValues={{
                    period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                    registrationDate: moment(),
                    registrationTime: moment(),
                    member_type_id: '',
                    registration_fee_id: this.waitingListReturnMode ? this.props.memberProfileRegistrationAddContext.waitingListReturnData?.registrationFeeId : '',
                    position_id: '',
                    group_position_id: '',
                    other_fee_id: '',
                    // setting it as true when waiting list return because the selected registration could be one from overwrite_restrictions, doesn't change anything if true for nothing
                    overwrite_restrictions: !!this.waitingListReturnMode,
                    custom_price: '',
                    division_id: '',
                }}
                validationSchema={object().shape({
                    period_id: string().when('does_not_matter', {
                        is: () => !this.simpleView,
                        then: string().required(<Translate id='memberProfile.registration.add.validation.season.required' />),
                    }),
                    group_position_id: string().when('registration_fee_id', {
                        is: (registrationFeeId) => !this.simpleView && this.state.registrationsSelectOptions.find((option) => option.value === registrationFeeId)?.withPosition,
                        then: string().required(<Translate id='memberProfile.registration.add.validation.groupPostition.required' />),
                    }),
                    registrationDate: mixed().test({
                        name: 'registrationDate-valid',
                        message: <Translate id='form.validation.date.format' />,
                        test: moment.isMoment,
                    })
                        .test({
                            name: 'registrationDate-before-today',
                            message: <Translate id='memberProfile.registration.add.validation.date.isBefore' />,
                            test: (registrationDate) => moment.isMoment(registrationDate) && registrationDate.isBefore(new Date()),
                        }),
                    registrationTime: mixed().test({
                        name: 'registrationTime-valid',
                        message: <Translate id='form.validation.time.format' />,
                        test: moment.isMoment,
                    })
                        .when('registrationDate', (registrationDate, schema) => {
                            if(moment.isMoment(registrationDate) && registrationDate.isSame(new Date(), 'day')){ // When registrationDate is today
                                return schema.test({
                                    name: 'registrationTime-before-now',
                                    message: <Translate id='memberProfile.registration.add.validation.time.isBefore' />,
                                    test: (registrationTime) => moment.isMoment(registrationTime) && registrationTime.isBefore(new Date()),
                                });
                            }
                            return schema;
                        }),
                    registration_fee_id: string().when('does_not_matter', {
                        is: () => !this.simpleView,
                        then: string().required(<Translate id='memberProfile.registration.add.validation.category.required' />),
                    }),
                    custom_price: number().when('registration_fee_id', {
                        is: (registrationFeeId) => !this.simpleView && registrationFeeId !== NONE_VALUE && registrationFeeId !== MANUAL_REGISTRATION_VALUE,
                        then: number().required(<Translate id='form.validation.price.required' />),
                    }),
                    member_type_id: string().when('does_not_matter', {
                        is: () => !this.simpleView,
                        then: string().required(<Translate id='memberProfile.registration.add.validation.memberType.required' />),
                    }),
                    other_fee_id: string().when('does_not_matter', {
                        is: () => this.simpleView,
                        then: string().required(<Translate id='memberProfile.registration.add.validation.otherItem' />),
                    }),
                    division_id: string().when('registration_fee_id', {
                        is: MANUAL_REGISTRATION_VALUE,
                        then: string().required(<Translate id='memberProfile.registration.form.division.required' />),
                    }),
                })}
                onSubmit={async(values, { setSubmitting, setStatus }) => {
                    try{
                        this.props.memberProfileRegistrationAddContext.setEmmanuelMode(false);
                        this.props.memberProfileRegistrationAddContext.setModalLoading(true);

                        const mergedMoments = moment(values.registrationDate);
                        mergedMoments.set({
                            hour: values.registrationTime.get('hour'),
                            minute: values.registrationTime.get('minute'),
                        });
                        this.props.memberProfileRegistrationAddContext.viewsInfo.invoiceDate = mergedMoments.toISOString();
                        this.props.memberProfileRegistrationAddContext.viewsInfo.memberTypeId = values.member_type_id;
                        this.props.memberProfileRegistrationAddContext.viewsInfo.divisionId = values.division_id;

                        // Create new cart
                        let shopping_cart_id = '';
                        if(this.props.memberProfileRegistrationAddContext.exchangeMode){
                            shopping_cart_id = await this.props.CartsContext.exchangeCart(this.props.memberProfileRegistrationAddContext.viewsInfo.selectedExchangeItem.invoice_item_id, values.registration_fee_id || values.other_fee_id, values.overwrite_restrictions)

                            if(values.registration_fee_id){ // registration exchange
                                this.props.memberProfileRegistrationAddContext.viewsInfo.selectedRegistration = this.state.selectedRegistration;
                            }else{ // other item exchange
                                this.props.memberProfileRegistrationAddContext.viewsInfo.selectedOtherItems.push({
                                    itemId: values.other_fee_id,
                                    quantity: 1,
                                })
                            }
                        }else if(values.registration_fee_id !== NONE_VALUE && values.registration_fee_id !== MANUAL_REGISTRATION_VALUE){
                            shopping_cart_id = await this.props.CartsContext.createCart({
                                item: {
                                    item_id: values.registration_fee_id || values.other_fee_id,
                                    type: values.registration_fee_id ? "REGISTRATION" : "OTHER",
                                    member_id: this.props.MembersContext.currentMember.member_id,
                                    overwrite_restrictions: values.overwrite_restrictions,
                                    custom_price: Math.round((values.custom_price + this.state.affiliationFeesAmount) * 100),
                                    waiting_list_item_id: this.waitingListReturnMode ? this.props.memberProfileRegistrationAddContext.waitingListReturnData.waitingListNumber : false,
                                },
                            })

                            this.props.memberProfileRegistrationAddContext.viewsInfo.selectedRegistration = this.state.selectedRegistration;
                        }

                        this.props.memberProfileRegistrationAddContext.viewsInfo.selectedPeriod = this.state.selectedPeriod;

                        if(shopping_cart_id){
                            // Get cart to get row details
                            const cart = await this.props.CartsContext.getCart(shopping_cart_id);
                            const isWaitingList = cart.cart_detail.find((row) => row.item_type === 'REGISTRATION')?.waiting_list == 1;

                            if(isWaitingList){
                                await this.props.memberProfileRegistrationAddContext.checkout();
                                this.props.memberProfileRegistrationAddContext.setModalLoading(false);
                            }else{
                                if(values.group_position_id && values.registration_fee_id && values.registration_fee_id !== NONE_VALUE){
                                    const rowId = cart.cart_detail.find((row) => row.item_id === values.registration_fee_id)?.row_id;

                                    if(rowId)
                                        await this.props.CartsContext.associateItemPosition(shopping_cart_id, rowId, {
                                            position_group_id: values.group_position_id,
                                            position_id: values.position_id,
                                        })
                                }

                                if(cart.cart_detail?.[0]?.row_id){
                                    this.props.memberProfileRegistrationAddContext.viewsInfo.cartPrice = parseInt(cart.cart_summary.grand_total)
                                    this.props.memberProfileRegistrationAddContext.viewsInfo.creditAmount = parseInt(cart.cart_summary.credit_total)
                                    this.props.memberProfileRegistrationAddContext.viewsInfo.customPrice = Math.round(values.custom_price * 100)

                                    // set installments
                                    // 3 conditions for installments, installments available, full price > 0, full price >= minimum threshold
                                    if(parseInt(cart.cart_summary.grand_total) > 0 && this.state.selectedRegistration?.installment_mode && parseInt(cart.cart_summary.grand_total) >= this.state.selectedRegistration.installment_mode.minimum_threshold){
                                        this.props.memberProfileRegistrationAddContext.viewsInfo.installments = this.state.selectedRegistration.installment_mode;
                                    }

                                    // Send forms
                                    let formGroups = null;
                                    const customFormIds = [];
                                    const rowIds = [];
                                    if(this.state.selectedRegistration?.custom_form?.custom_form_id){
                                        customFormIds.push(this.state.selectedRegistration.custom_form.custom_form_id)
                                        rowIds.push(cart.cart_detail.find((r) => r.item_type === 'REGISTRATION')?.row_id)
                                    }
                                    if(this.state.selectedRegistration?.affiliation_fees?.length > 0){
                                        this.state.selectedRegistration.affiliation_fees.map((affiliationFee) => {
                                            if(affiliationFee.custom_form?.custom_form_id){
                                                customFormIds.push(affiliationFee.custom_form.custom_form_id)
                                                rowIds.push(cart.cart_detail.find((row) => row.item_id === affiliationFee.affiliation_fee_id)?.row_id)
                                            }
                                        })
                                    }

                                    if(customFormIds.length > 0){
                                        formGroups = await Promise.all(customFormIds.map((formId) => {
                                            return this.props.FormsContext.getForm(formId)
                                        }))
                                    }

                                    if(formGroups && cart){
                                        this.props.memberProfileRegistrationAddContext.viewsInfo.customForms = customFormIds.map((formId, index) => {
                                            return {
                                                formGroups: formGroups[index],
                                                customFormId: formId,
                                                rowId: rowIds[index],
                                            }
                                        })
                                    }

                                    // set waivers now so we can set the state so we can check if there are any both here and in the custom forms view
                                    // Get current registration
                                    const memberItems = this.props.CartsContext.state.cachedCart.cart_detail.filter((r) => r.member.member_id === this.props.MembersContext.currentMember.member_id) // Get all items linked to the current member
                                    let waivers = []

                                    // for ... of doesn't go through prototypes as for ... in does
                                    // decided not to use forEach because it acts weird in promises
                                    // Add all item waivers
                                    for(const item of memberItems){
                                        // Each waiver to sign for the current item (grouped by organisation)
                                        for(const waiverToSign of item.waivers_to_sign || []){
                                            // Each waiver givin by the current organisation for the current item
                                            for(const waiver of waiverToSign.waivers || []){
                                                const _newWaiver = [ {
                                                    ...waiver,
                                                    organisation: waiverToSign.organisation,
                                                    item_id: waiverToSign.item_id,
                                                    row_id: item.row_id,
                                                } ]
                                                waivers = [ ...waivers, ..._newWaiver ]
                                            }
                                        }
                                        if(waivers.length > 0){
                                            this.props.memberProfileRegistrationAddContext.viewsInfo.waivers = waivers
                                        }

                                    }
                                }

                                this.props.memberProfileRegistrationAddContext.goToView(this.props.memberProfileRegistrationAddContext.views.otherItems)
                                setSubmitting(false);
                                this.props.memberProfileRegistrationAddContext.setModalLoading(false);
                            }
                        }else if(values.registration_fee_id === MANUAL_REGISTRATION_VALUE){
                            this.props.memberProfileRegistrationAddContext.setEmmanuelMode(true)
                            const divisionWaivers = await this.props.WaiversContext.getWaiversByDivisions(values.division_id, {
                                organisation_id: this.props.OrganizationContext.organisation_id,
                                member_type_id: values.member_type_id,
                                period_id: values.period_id,
                                member_id: this.props.MembersContext.currentMember.member_id,
                            })

                            if(divisionWaivers.length < 1){
                                throw{
                                    code: 'NO_WAIVERS',
                                }
                            }else{
                                this.props.memberProfileRegistrationAddContext.viewsInfo.waivers = divisionWaivers
                                this.props.memberProfileRegistrationAddContext.goToViewEmmanuel(this.props.memberProfileRegistrationAddContext.views.manualSelectIdentity)
                                setSubmitting(false);
                                this.props.memberProfileRegistrationAddContext.setModalLoading(false);
                            }
                        }else{
                            this.props.memberProfileRegistrationAddContext.goToView(this.props.memberProfileRegistrationAddContext.views.otherItems)
                            setSubmitting(false);
                            this.props.memberProfileRegistrationAddContext.setModalLoading(false);
                        }
                    }catch(e){
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e.message)
                            setSubmitting(false);
                            this.props.memberProfileRegistrationAddContext.setModalLoading(false);

                            if(e.i18n){
                                setStatus(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />)
                            }else if(e.code === 'NO_WAIVERS'){
                                setStatus(<Translate id='memberProfile.registration.form.division.none' />)
                            }else if(this.props.memberProfileRegistrationAddContext.exchangeMode){
                                setStatus(<Translate id={'memberProfile.registration.add.exchangeErrors.' + e.message} defaultMessageId='misc.error' />)
                            }else{
                                // using e.code because the createCart returns the full error object instead of the message only
                                setStatus(<Translate id={'memberProfile.registration.add.errors.' + e.code} defaultMessageId='misc.error' />)
                                this.showCartError(e.code)
                            }
                        }
                    }
                }}
            >
                {(formik) => (
                    <Form id="memberRegistrationStep1" name="memberRegistrationStep1">
                        <ModalBody>
                            {this.props.isMulti ?
                                <CollapsibleCard
                                    className="mb-3"
                                    title={`${this.props.isMulti?.length} selected members`}
                                >
                                    <ListGroup>
                                        {Array.isArray(this.props.isMulti) && this.props.isMulti.map((member) => (
                                            <ListGroupItem key={member.member_id}>
                                                <UserDisplay>
                                                    <UserDisplay.Container>
                                                        <UserImg
                                                            alt={member.first_name + ' ' + member.last_name}
                                                            abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                                            src={member.picture?.full_path}
                                                            filePos={member.picture?.file_position}
                                                            width={60}
                                                            className="mr-2"
                                                        />
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container className="w-100">
                                                        <UserDisplay.Title className="d-flex justify-content-between">
                                                            <div className='d-flex justify-content-start'>
                                                                <div>{member.first_name} {member.last_name}</div>
                                                                <MemberStatusIcons member={member} className="ml-1 d-flex" />
                                                            </div>
                                                            <div>{member.member_status && <StatusBadge color={member.member_status.color_code} status={member.member_status} icon={member.has_been_merged == 1 ? <i className='mdi mdi-call-merge' /> : null} />}</div>
                                                        </UserDisplay.Title>
                                                        <UserDisplay.Subtitle>
                                                            <Link className="text-nowrap" to={`/members/profile/${member.member_id}`}>
                                                                {member.unique_identifier ?
                                                                    <>#{member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                                    :
                                                                    '-'
                                                                }
                                                            </Link>
                                                        </UserDisplay.Subtitle>
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                </CollapsibleCard>

                                :

                                <UserDisplay card className="p-2 d-flex w-100">
                                    <UserDisplay.Container>
                                        <UserImg
                                            abbr={this.props.MembersContext.currentMember.first_name}
                                            src={this.props.MembersContext.currentMember.picture?.full_path}
                                            filePos={this.props.MembersContext.currentMember.picture?.file_position}
                                            alt={this.props.MembersContext.currentMember.first_name + ' ' + this.props.MembersContext.currentMember.last_name}
                                            width="50"
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title className='d-flex'>
                                            {this.props.MembersContext.currentMember.first_name + ' ' + this.props.MembersContext.currentMember.last_name}
                                            <span className='ml-1'><MemberStatusIcons member={this.props.MembersContext.currentMember} /></span>
                                        </UserDisplay.Title>
                                        <UserDisplay.Subtitle>{this.props.MembersContext.currentMember.organisation.organisation_name}</UserDisplay.Subtitle>
                                        <UserDisplay.Subtitle className="font-medium">{this.props.MembersContext.currentMember.unique_identifier ? ` #${this.props.MembersContext.currentMember.unique_identifier}` : '-'}</UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                            }

                            {this.simpleView ?
                                <MemberProfileRegistrationAdd1Simple memberProfileRegistrationAddContext={this.props.memberProfileRegistrationAddContext} />
                                : this.state.wrongOrganisation ?
                                    <Alert color="danger">
                                        <Translate id='memberProfile.registration.form.wrongOrganization' values={{ organization: this.props.MembersContext.currentMember.organisation.organisation_name }} />
                                        <Link to="/changeorganization" className="ml-1"><Translate id='memberProfile.registration.form.changeOrganization' /></Link>
                                    </Alert>
                                    :
                                    <Fade>
                                        <Row form>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label for="period_id_MemberProfileRegistrationAdd1" className="text-muted"><Translate id='memberProfile.registration.form.season' /> <Required /></Label>
                                                    <FormikSelect
                                                        name='period_id'
                                                        id='period_id_MemberProfileRegistrationAdd1'
                                                        disabled={this.waitingListReturnMode}
                                                        onOptionSelected={(option) => this.getRegistrations(option[0])}
                                                        renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                                        options={this.props.PeriodsContext.periods ?
                                                            this.props.PeriodsContext.periods.reduce((keptPeriods, period) => {
                                                                if(period.active == 1){
                                                                    keptPeriods.push({
                                                                        value: period.period_id,
                                                                        label: period.name,
                                                                        i18n: period.i18n,
                                                                    })
                                                                }
                                                                return keptPeriods;
                                                            }, [])
                                                            :
                                                            []
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col hidden={this.state.showMemberTypes} sm="6">
                                                <FormGroup>
                                                    <Label for='member_type_id_MemberProfileregistrationAdd1' className='text-muted'><Translate id='memberProfile.registration.form.memberType' /> <Required /></Label>
                                                    <FormikSelect
                                                        name='member_type_id'
                                                        id='member_type_id_MemberProfileregistrationAdd1'
                                                        disabled={this.waitingListReturnMode}
                                                        renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                        onOptionSelected={(option) => {
                                                            this.setState(() => ({ selectedMemberTypeId: option[0] }));
                                                            this.getRegistrations(formik.values.period_id, formik.values.overwrite_restrictions);
                                                            formik.setFieldValue('registration_fee_id', '');
                                                        }}
                                                        searchKeys={[
                                                            `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                        ]}
                                                        loadData={(from) => {
                                                            switch (from){
                                                                case 'CDM':
                                                                    return this.props.MembersContext.getMemberTypes(this.props.OrganizationContext.organisation_id)
                                                                        .then((memberTypes) => {
                                                                            this.setState(() => ({ showMemberTypes: (memberTypes.length === 1) }));

                                                                            // Creating the groups for the options according to the org
                                                                            return memberTypes.reduce((newArray, memberType) => {
                                                                                if(memberType.active == 1){
                                                                                    newArray.push({
                                                                                        value: memberType.member_type_id,
                                                                                        label: memberType.name,
                                                                                        i18n: memberType.i18n,
                                                                                    })
                                                                                }
                                                                                return newArray
                                                                            }, [])
                                                                        })
                                                                default:
                                                                    break;
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="12">
                                                <FormGroup>
                                                    <div className="d-flex justify-content-between">
                                                        <Label for='registration_fee_id' className='text-muted'><Translate id='memberProfile.registration.form.registration' /> {!formik.values.other_fee_id && <Required />}</Label>
                                                        <FormikGroup name='checked' rowProps={{ className: 'mx-0' }}>
                                                            <FormikCheckedButton
                                                                id='overwrite_restrictions'
                                                                name='overwrite_restrictions'
                                                                label='memberProfile.registration.form.registration.showAll'
                                                                disabled={this.waitingListReturnMode}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue('overwrite_restrictions', e.target.checked);
                                                                    this.getRegistrations(formik.values.period_id, e.target.checked)
                                                                }}
                                                            />
                                                        </FormikGroup>
                                                    </div>
                                                    <FormikSelect
                                                        name='registration_fee_id'
                                                        id='registration_fee_id_MemberProfileRegistrationAdd1'
                                                        disabled={!this.state.selectedMemberTypeId || this.waitingListReturnMode}
                                                        isLoading={this.state.registrationsSelectLoading}
                                                        options={this.state.registrationsSelectOptions}
                                                        renderOption={(option) => {
                                                            const data = option.option.item

                                                            if(data && option.option.value !== NONE_VALUE){
                                                                return (
                                                                    <>
                                                                        <div className='font-medium'>
                                                                            <DisplayI18n
                                                                                field='name'
                                                                                i18n={data.fee?.i18n}
                                                                                defaultValue={data.fee?.name}
                                                                            />
                                                                        </div>
                                                                        <div className='small'>
                                                                            <CurrencyFormat value={this.getAmountForCurrentTime(data) / 100 || 0} />
                                                                            {data.exclude_affiliation_fee == 0 && <span className='ml-1'>+ <Translate id='memberProfile.registration.error.affiliationFees' /></span>}
                                                                            {this.hasPlatformFees && <span className='ml-1'>+ <Translate id='memberProfile.registration.error.platformFees' /></span>}
                                                                        </div>
                                                                        {(!!data.already_registered || !!data.sold_out) &&
                                                                        <div className="d-flex justify-content-start">
                                                                            {!!data.already_registered &&
                                                                                <div className="text-warning small mr-2"><i className="mdi mdi-alert-outline mr-1" /><Translate id='memberProfile.registration.error.alreadyRegistered' /></div>
                                                                            }
                                                                            {(!!data.sold_out && data.manage_waiting_list == 0) &&
                                                                                <div className="text-danger small"><i className="fas fa-ban mr-1" /><Translate id='memberProfile.registration.error.soldOut' /></div>
                                                                            }
                                                                            {(!!data.sold_out && data.manage_waiting_list == 1) &&
                                                                                <div className="small"><i className="mdi mdi-clock-alert-outline mr-1" /><Translate id='memberProfile.registration.error.waitingList' /></div>
                                                                            }
                                                                        </div>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                            // return nothing for default behavior
                                                        }}
                                                        onOptionSelected={([ option ]) => {
                                                            if(option !== NONE_VALUE){
                                                                if(option === MANUAL_REGISTRATION_VALUE){
                                                                    // back to initial values
                                                                    this.setState(() => ({
                                                                        selectedRegistration: false,
                                                                        affiliationFeesAmount: 0,
                                                                    }));
                                                                    formik.setFieldValue('custom_price', 0)
                                                                }else{
                                                                    const registrationItem = this.state.registrationsSelectOptions.find((opt) => opt.id === option)?.item
                                                                    this.setSelectedRegistrationAndPrices(registrationItem);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    {this.state.errors?.registrationAgeError ?
                                                        <div className="text-danger small"><Translate id='memberProfile.registration.error.registrationAgeError' /></div>
                                                        :
                                                        <div className="small text-muted"><Translate id='memberProfile.registration.form.possibleRegistrations' values={{ count: this.state.registrationsSelectOptions.filter((r) => r.id !== NONE_VALUE).length }} /></div>
                                                    }

                                                    <CrossFade isVisible={formik.values.registration_fee_id === MANUAL_REGISTRATION_VALUE}>
                                                        <CustomAlert
                                                            className='mt-3' color='info'
                                                            withTitle
                                                            text='memberProfile.registration.form.registration.manual.info'
                                                        />
                                                    </CrossFade>

                                                </FormGroup>
                                            </Col>

                                            <Collapse tag={Col} sm='12' isOpen={formik.values.registration_fee_id !== NONE_VALUE}>
                                                <FormGroup>
                                                    <CrossFade isVisible={formik.values.registration_fee_id !== MANUAL_REGISTRATION_VALUE}>
                                                        <Row form>
                                                            <Col xs='4'>
                                                                <Label for='price' className='text-muted'><Translate id='form.fields.price' /> <Required /></Label>
                                                            </Col>
                                                        </Row>
                                                        <Row form className='d-flex align-items-center'>
                                                            <Col xs='4'>
                                                                <FormikCurrencyInput id='price' name='custom_price' disabled={!formik.values.registration_fee_id || formik.values.registration_fee_id === MANUAL_REGISTRATION_VALUE} />
                                                            </Col>
                                                            <Col xs='8'>
                                                                <Collapse isOpen={!!this.state.selectedRegistration}>
                                                                    <Row form>
                                                                        <Col xs='1' className='text-center'>+</Col>
                                                                        <Col xs='3' className='text-center'><CurrencyFormat value={this.state.affiliationFeesAmount} /></Col>
                                                                        <Col xs='1' className='text-center'>=</Col>
                                                                        <Col xs='3' className='text-center'><CurrencyFormat value={(this.state.affiliationFeesAmount + formik.values.custom_price)} /></Col>
                                                                        <Col xs='1'>
                                                                            <Tooltip zIndex={3000} withArrow label={<Translate id='memberProfile.registration.form.helper' />}>
                                                                                <i className="mdi mdi-help-circle-outline text-primary mx-1" />
                                                                            </Tooltip>
                                                                        </Col>
                                                                    </Row>
                                                                </Collapse>
                                                            </Col>
                                                        </Row>
                                                    </CrossFade>
                                                    <CrossFade isVisible={formik.values.registration_fee_id === MANUAL_REGISTRATION_VALUE}>
                                                        <Row form>
                                                            <Col xs='6'>
                                                                <Label for='division_id_MemberProfileRegistrationAdd1' className='text-muted'><Translate id='memberProfile.registration.form.division' /> <Required /></Label>
                                                                <FormikSelect
                                                                    name='division_id'
                                                                    id='division_id_MemberProfileRegistrationAdd1'
                                                                    searchKeys={[
                                                                        `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                                    ]}
                                                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                                    loadData={(from) => {
                                                                        switch (from){
                                                                            case 'CDM':
                                                                                return this.props.RegistrationDivisionsContext.getRegistrationDivisions(this.props.OrganizationContext.organisation_id, { active: 1 })
                                                                                    .then((divisions) => {
                                                                                        return divisions.map((division) => ({
                                                                                            value: division.division_id,
                                                                                            label: division.name,
                                                                                            i18n: division.i18n,
                                                                                        }))
                                                                                    })
                                                                            default:
                                                                                break;
                                                                        }
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </CrossFade>
                                                </FormGroup>
                                            </Collapse>
                                        </Row>
                                        <hr className='mt-0' />
                                        <Row form>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label for="registrationDate" className="text-muted"><Translate id='memberProfile.registration.form.registration.date' /> <Required /></Label>
                                                    <FormikDateTime
                                                        name='registrationDate' id='registrationDate' timeFormat={false}
                                                        isValidDate={(current) => moment(current).isBefore(new Date())}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label for="registrationTime" className="text-muted"><Translate id='memberProfile.registration.form.registration.time' /> <Required /></Label>
                                                    <FormikDateTime name='registrationTime' id='registrationTime' dateFormat={false} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Collapse tag={Row} form isOpen={!!formik.values.registration_fee_id && !!this.state.registrationsSelectOptions.find((option) => option.value === formik.values.registration_fee_id)?.withPosition}>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label for='group_position_id_MemberProfileRegistrationAdd1' className='text-muted'><Translate id='memberProfile.registration.form.groupPosition' /> <Required /></Label>
                                                    <FormikSelect
                                                        name='group_position_id'
                                                        id='group_position_id_MemberProfileRegistrationAdd1'
                                                        searchKeys={[
                                                            `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                        ]}
                                                        renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                        loadData={(from) => {
                                                            switch (from){
                                                                case 'CDM':
                                                                    return this.props.PositionsContext.getPositionGroups(this.props.OrganizationContext.organisation_id, { active: 1 })
                                                                        .then((positions) => {
                                                                            // Creating the groups for the options according to the org
                                                                            return positions.map((position) => ({
                                                                                value: position.position_group_id,
                                                                                label: position.name,
                                                                                i18n: position.i18n,
                                                                            }))
                                                                        })
                                                                default:
                                                                    break;
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <MemberProfileRegistrationAdd1Position />
                                            </Col>
                                        </Collapse>

                                        {(!!this.props.MembersContext.currentMember.outstanding_balance && this.props.MembersContext.currentMember.outstanding_balance > 0) &&
                                            <CustomAlert
                                                color='warning'
                                                text='memberProfile.registration.add.outstandingBalance'
                                            />
                                        }

                                        {formik.status &&
                                            <CustomAlert
                                                color='danger'
                                                withTitle
                                                text={formik.status}
                                                translateText={false}
                                                toggle={() => formik.setStatus(null)}
                                            />
                                        }
                                    </Fade>
                            }
                        </ModalBody>
                        <ModalFooter>
                            {this.props.memberProfileRegistrationAddContext.canGoBack(this.props.memberProfileRegistrationAddContext.views.exchangeItems) &&
                                // not checking this.simpleView because we want this button for both registration exchange et other item exchange
                                <Button
                                    color='primary' type="button"
                                    outline className='mr-auto'
                                    onClick={() => {
                                        this.props.memberProfileRegistrationAddContext.goToView(
                                            this.props.memberProfileRegistrationAddContext.views.exchangeItems,
                                        )
                                    }}
                                >
                                    <Translate id='misc.previous' />
                                </Button>
                            }

                            {this.props.memberProfileRegistrationAddContext.waitingListReturnMode &&
                                <Button
                                    color='primary' type="button"
                                    outline className='mr-auto'
                                    onClick={() => {
                                        this.props.memberProfileRegistrationAddContext.goToView(
                                            this.props.memberProfileRegistrationAddContext.views.waitingListReturn,
                                        )
                                    }}
                                >
                                    <Translate id='misc.previous' />
                                </Button>
                            }

                            <Button form="memberRegistrationStep1" color='primary' type="submit"><Translate id='misc.next' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withContexts(OrganizationContext, MembersContext, PeriodsContext, CartsContext, PositionsContext, FormsContext, I18nContext, RegistrationDivisionsContext, WaiversContext, MerchantAccountContext)(MemberProfileRegistrationAdd1);
