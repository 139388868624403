import { useSpordleTable } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import MemberAlertsIcon from "../../../../../../components/memberBadges/MemberAlertsIcon";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import ValidateDocumentsAction from "./ValidateDocumentsAction";

const MemberDocumentsTitle = ({ refreshMemberProfile }) => {
    const { getData } = useSpordleTable();
    const { currentMember } = useContext(MembersContext);

    return (
        <CardSectionTitle
            className="d-flex"
            title='members.profile.overview.memberDocuments.title'
        >
            {currentMember?.need_address_document == 1 && // testing on, icon not centered get help from Gab, can't figure it out for the life of me http://localhost:3000/members/profile/1ece3698-ac7f-6c2a-b0ef-06bf14840f98/overview
                <>
                    <span className="ml-2 text-danger align-items-center d-inline-flex">
                        <MemberAlertsIcon className='line-height-0 mr-2' icon="documents" height={12} width={12} /> <Translate id="members.search.memberSearch.missingDocument" />
                    </span>
                    <ValidateDocumentsAction
                        onSuccess={refreshMemberProfile}
                        currentDocuments={getData() || []}
                    />
                </>
            }
        </CardSectionTitle>
    );
}

export default MemberDocumentsTitle;