import Translate from "@spordle/intl-elements";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import CanDoAction from "../../../../../../../components/permissions/CanDoAction";
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../components/UserImg";
import EditComplaineeModal from "../modals/EditComplaineeModal";
import { Tooltip } from "@mantine/core";
import QuickViewButton from "../../../../../../../components/quickView/QuickViewButton";
import InlineCopy from "../../../../../../../components/inlineCopy/InlineCopy";
import { DisplayCategory } from "../../../../../../teams/TeamHelpers";

/**
 * @description Discrimination Member SidePanel Card
 * @param {Object} props
 * @param {object} props.member
 * @param {object} props.complaint Discrimination complaint to which this member is linked to
 * @param {function} props.handleDelete Function called when delete option is confirmed
 * @param {function} props.refreshTable Function called after the member was edited
 * @returns {JSX}
 */
const ComplaineeMember = ({ refreshTable, member, complaint, handleDelete, isTeam, readOnly }) => {
    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const canRemoveMember = complaint.discrimination_member.length > 1;

    return (
        <div className="position-relative">
            <EditComplaineeModal
                complaint={complaint}
                refreshTable={refreshTable}
                toggle={() => setModalIsOpen(false)}
                isOpen={modalIsOpen}
                member={member}
                isTeam={isTeam}
            />
            <UserDisplay className="d-flex mb-2" card>
                <UserDisplay.Container>
                    {isTeam ?
                        <UserImg
                            className="text-uppercase"
                            width={50}
                            src={null}
                            alt={member.team?.name || member.team_name || "unknown"}
                            abbr={member.team?.name || member.team_name || "unknown"}
                        />
                        :
                        <UserImg
                            className="text-uppercase"
                            width={50}
                            src={member.member?.picture?.full_path}
                            filePos={member.member?.picture?.file_position}
                            abbr={(member.first_name?.charAt(0) + member.last_name?.charAt(0)) || ""}
                            alt={member.first_name + ' ' + member.last_name}
                        />
                    }
                </UserDisplay.Container>
                <UserDisplay.Container>
                    {isTeam ?
                        <>
                            <UserDisplay.Title>{member.team?.name || member.team_name}</UserDisplay.Title>
                            {member.team?.short_name &&
                                <UserDisplay.Subtitle>{member.team.short_name}</UserDisplay.Subtitle>
                            }
                            {member.team?.unique_identifier &&
                                <UserDisplay.Subtitle>
                                    <InlineCopy toCopy={member.team.unique_identifier}>
                                        <Link className="text-nowrap" to={`/teams/profile/${member.team.team_id}`}>
                                            #{member.team.unique_identifier} <i className="mdi mdi-chevron-right" />
                                        </Link>
                                    </InlineCopy>
                                </UserDisplay.Subtitle>
                            }
                            {member.team?.category &&
                                <UserDisplay.Subtitle>
                                    <DisplayCategory category={member.team.category} short />
                                </UserDisplay.Subtitle>
                            }
                        </>
                        :
                        <>
                            <UserDisplay.Title>
                                <QuickViewButton member={{ ...member.member }}>{member.first_name} {member.last_name}</QuickViewButton>
                            </UserDisplay.Title>
                            {member.member?.unique_identifier && (
                                <UserDisplay.Subtitle>
                                    <Link className="text-nowrap" to={`/members/profile/${member.member.member_id}`}>
                                        #{member.member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" />
                                    </Link>
                                </UserDisplay.Subtitle>
                            )}
                            <UserDisplay.Subtitle>
                                {(!!member.team_name || !!member.team) && (
                                    <span className="d-block">
                                        <span className="font-medium mr-1"><Translate id="discrimination.label.team" /> :</span>
                                        {member.team?.name || member.team_name} {member.team?.category &&
                                            <>(<DisplayCategory category={member.team.category} short />)</>
                                        }
                                    </span>
                                )}
                                {!!member.role &&
                                    <FormattedMessage id={`discrimination.global.roles.${member.role}`} defaultMessage="noTranslate">
                                        {(message) => (message[0] !== 'noTranslate') ? message : member.role || ''}
                                    </FormattedMessage>
                                }
                            </UserDisplay.Subtitle>
                        </>

                    }
                </UserDisplay.Container>
            </UserDisplay>
            {!readOnly &&
                <div className="position-absolute top-0 right-0 p-1 d-flex align-items-center">
                    <CanDoAction action="EDIT" componentCode="organization" componentPermissionCode="discrimination_claims">
                        <Button onClick={() => setModalIsOpen(true)} size="sm" color="link" className="text-link bg-transparent p-1 border-0">
                            <Translate id="misc.edit" />
                        </Button>
                    </CanDoAction>
                    { canRemoveMember &&
                        <>
                            |
                            <CanDoAction action="DELETE" componentCode="organization" componentPermissionCode="discrimination_claims">
                                <ConfirmModal
                                    modalMsg="You are about to delete this complainee from the complaint"
                                    modalTitle={member.first_name + ' ' + member.last_name}
                                    toggler={(fn) => (
                                        <Tooltip zIndex={3000} withArrow label={<Translate id='misc.delete' />}>
                                            <Button onClick={fn} size="sm" color="link" className="text-danger">
                                                <i className="fas fa-trash" />
                                            </Button>
                                        </Tooltip>
                                    )}
                                    onConfirm={() => handleDelete(member.discrimination_member_id)}
                                />
                            </CanDoAction>
                        </>
                    }
                </div>
            }
        </div>
    )
}

export default ComplaineeMember;