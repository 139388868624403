import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import { Alert, Collapse } from 'reactstrap';
import { PeriodsContext, AppContext } from '../../contexts/contexts';
import { DisplayI18n } from '../../helpers/i18nHelper';

const PeriodWarning = ({ className, disablePeriodSelect }) => {
    const { updateRouteKey } = useContext(AppContext);
    const { selectedPeriod, activePeriods, setSelectedPeriod } = useContext(PeriodsContext);
    const currentPeriod = activePeriods.find((p) => !!p.current);

    const setCurrentPeriod = () => {
        setSelectedPeriod(currentPeriod);
        updateRouteKey();
    }

    return (
        <Collapse unmountOnExit style={{ minHeight: "auto" }} className={className || "px-4 pt-3"} isOpen={!selectedPeriod.current}>
            <Alert color="warning" className="mb-0 d-flex align-items-center">
                <i className="mdi font-18 mdi-alert mr-2" />
                <div className='d-flex flex-wrap'>
                    <div className='mr-1'><Translate id='components.viewHeader.warning' values={{ period: <DisplayI18n field="name" defaultValue={selectedPeriod.name} i18n={selectedPeriod.i18n} /> }} /></div>
                    {(currentPeriod && !disablePeriodSelect) && <button type='button' className="reset-btn font-bold" onClick={setCurrentPeriod}><Translate id='components.viewHeader.warning.btn' /></button>}
                </div>
            </Alert>
        </Collapse>
    )
}

export default PeriodWarning;