import { useSpordleTable } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledButtonDropdown } from "reactstrap";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import { DisplayI18n } from "../../helpers/i18nHelper";
import { exportReportsToCSV, handleExcelExport } from "../../helpers/reportsHelper";
import { fail } from "@spordle/toasts";
import { ReportsContext } from "../../contexts/ReportsContext";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { I18nContext } from "../../contexts/I18nContext";
import { Tooltip } from "@mantine/core";

// formikFilters must be pre formatted (with the toStrings where needed)
const ExportDropdown = ({ getData, formikRef, formikFilters, justExcelItem }) => {
    const spordleTable = useSpordleTable();
    const intl = useIntl();
    const { currentReport } = useContext(ReportsContext);
    const reportType = currentReport.code;

    const [ isLoading, setLoading ] = useState(false);
    const [ canExport, setCanExport ] = useState(false);

    if(spordleTable.getDisplayedData().length > 0){
        if(!canExport)
            setCanExport(true);
    }else if(canExport)
        setCanExport(false);

    const handleExport = () => {
        if(!justExcelItem)
            setLoading(true);
        getData(formikFilters)
            .then((reports) => {
                exportReportsToCSV(Array.isArray(reports) ? reports : reports.report_results, spordleTable.getColumns(), intl, reportType);
                if(!justExcelItem)
                    setLoading(false);
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    if(!justExcelItem)
                        setLoading(false);
                }
            })
    }

    return (
        <>
            {justExcelItem ?
                <ExcelExportDropDownItem formikRef={formikRef} formikFilters={formikFilters} reportType={reportType} setLoading={() => { return false }} isJustExcel />
                :
                <UncontrolledButtonDropdown className='ml-auto'>
                    <Tooltip
                        withArrow
                        label={<Translate id='misc.export' />}
                    >
                        <DropdownToggle color='primary' outline caret id={spordleTable.generateId('exportDropdownButton')}>
                            <i className='mdi mdi-download mr-1' />
                        </DropdownToggle>
                    </Tooltip>
                    <DropdownMenu right>
                        <DropdownItem
                            disabled={isLoading || !canExport}
                            onClick={handleExport}
                            id={spordleTable.generateId('exportDropdown', 'CSV')}
                        >
                            <i className='fas fa-file-excel mr-1' />CSV{isLoading && <Spinner size='sm' color='primary' type='grow' className='float-right' />}
                        </DropdownItem>
                        <ExcelExportDropDownItem formikRef={formikRef} formikFilters={formikFilters} reportType={reportType} setLoading={setLoading} />
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            }
        </>
    )
}

const ExcelExportDropDownItem = ({ formikRef, formikFilters, reportType, setLoading, isJustExcel }) => {
    const spordleTable = useSpordleTable();
    const reportsContext = useContext(ReportsContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);

    return (
        <DropdownItem
            onClick={() => {
                // this code block allows us to use an excel export without submitting the form
                formikRef.current?.validateForm().then((valid) => {
                    // empty object (no keys) means the form is valid
                    if(Object.keys(valid).length === 0){
                        // removes any object key value pairs where the value is falsy
                        const excelFilters = Object.entries(formikFilters).reduce((a, [ k, v ]) => (v ? (a[k] = v, a) : a), {})
                        // export using the filters directly from formik
                        handleExcelExport(
                            setLoading,
                            reportType,
                            excelFilters,
                            (spordleTable.getData().length === 0 || spordleTable.state.loadingState === 'loading') ? 9999 : spordleTable.getData().length,
                            reportsContext,
                            identityRolesContext.identity_role_id,
                            identityRolesContext.identity.identity_id,
                            i18nContext.getGenericLocale(),
                            organizationContext.organisation_id,
                        )
                    }else{
                        // submitting triggers visual validation errors (red text)
                        formikRef.current?.submitForm();
                    }

                }).catch((error) => {
                    console.error(error.message);
                });

            }}
            id={spordleTable.generateId('exportDropdown', 'Excel')}
        >
            {!isJustExcel &&
                <i className='fas fa-file-excel mr-1' />
            }
            <Translate id={isJustExcel ? 'report.just.export.excel' : 'misc.excel'} />
        </DropdownItem>
    )
}

export default ExportDropdown;
