import React, { useContext, useState } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { object, string } from 'yup';
import { Button, ModalBody, ModalFooter, Collapse, Label, CustomInput, Fade, Row, Col, FormGroup } from 'reactstrap';
import Translate from '@spordle/intl-elements';
import CustomAlert from '../CustomAlert';
import Required from '../formik/Required';
import UserDisplay from '../userDisplay/UserDisplay';
import { FormikError, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import { fail } from '@spordle/toasts';
import OverlayLoader from '../loading/OverlayLoader';

// contexts
import { CartsContext } from '../../contexts/CartsContext';
import { MerchantAccountContext } from '../../contexts/MerchantAccountContext';
import withContexts from '../../helpers/withContexts';
import { AuthContext } from '../../contexts/contexts';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { MembersContext } from '../../contexts/MembersContext';
import { DisplayI18n } from '../../helpers/i18nHelper';

class ManualRegistrationMemberIdentities extends React.Component{

    state = {
        isCreating: false,
    }

    toggleCreate = () => {
        this.setState((prevState) => ({
            isCreating: !prevState.isCreating,
        }))
    }

    skipAccountLink = (setStatus) => {
        this.props.onSubmit(null, setStatus)
    }

    render(){
        return (
            this.state.isCreating ?
                <CreateIdentityForm toggleCreate={this.toggleCreate} member={this.props.member} onSubmit={this.props.onSubmit} from={this.props.from} />
                :
                <Formik
                    initialValues={{
                        identity_id: this.props.selectedIdentity || this.props.memberIdentities?.[0]?.identity_id || '',
                    }}
                    validationSchema={object().shape({
                        identity_id: string().required(<Translate id='memberProfile.registration.memberIdentities.required' />),
                    })}
                    onSubmit={(values, { setStatus }) => {
                        const identity = this.props.memberIdentities.find((i) => i.identity_id === values.identity_id)
                        return this.props.onSubmit(values.identity_id, setStatus, `${identity?.name} ${identity.family_name}`)
                    }}
                >
                    {(formik) => (
                        <Form>
                            <>
                                <ModalBody>
                                    {this.props.memberIdentities &&
                                        <Fade in appear mountOnEnter unmountOnExit>
                                            <Label className='text-muted'><Translate id='memberProfile.registration.memberIdentities.label' /> <Required /></Label>
                                            {this.props.memberIdentities.length <= 0 ?
                                                <div className='mb-3 font-bold'>
                                                    <Translate id='memberProfile.registration.memberIdentities.label.noAccount' />
                                                </div>
                                                :
                                                <>
                                                    {this.props.memberIdentities.map((identity) => (
                                                        <IdentityCard key={identity.identity_id} identity={identity} defaultSelected={formik.values.identity_id === identity.identity_id} />
                                                    ))}
                                                </>
                                            }

                                            <UserDisplay hover card className="w-100 border-primary p-0 mb-2" style={{ borderStyle: 'dashed' }}>
                                                <button
                                                    className='reset-btn text-link w-100 h-100 p-3'
                                                    type='button'
                                                    onClick={this.toggleCreate}
                                                >
                                                    <i className='mdi mdi-plus' /> <Translate id='memberProfile.registration.memberIdentities.add' />
                                                </button>
                                            </UserDisplay>
                                        </Fade>
                                    }

                                    <FormikError name='identity_id' />

                                    <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                        {formik.status &&
                                            <CustomAlert className='mt-3' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                        }
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    {!this.props.hidePreviousButton &&
                                        <Button color='primary' outline type='button' onClick={() => this.props.goToPrevious()} className="mr-auto" disabled={formik.isSubmitting}>
                                            <Translate id='misc.previous' />
                                        </Button>
                                    }
                                    {this.props.showSkip &&
                                        <Button color='primary' outline type='button' disabled={formik.isSubmitting} onClick={() => this.skipAccountLink(formik.setStatus)}>
                                            <Translate id='memberProfile.registration.memberIdentities.skipLink' />
                                        </Button>
                                    }
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                                        <Translate id={this.props.submitLabel || 'misc.next'} />
                                    </Button>
                                    {this.props.from === 'instructor' && this.props.toggle &&
                                        <Button type="button" outline color="primary" onClick={this.props.toggle}><Translate id="misc.cancel" /></Button>
                                    }
                                </ModalFooter>
                            </>
                        </Form>
                    )}
                </Formik>
        )
    }
}

export const IdentityCard = ({ identity, defaultSelected }) => {
    const formik = useFormikContext();

    return (
        <UserDisplay tag="label" key={identity.identity_id} card hover className={`mb-2 w-100 ${defaultSelected ? 'border-primary' : ''}`}>
            <UserDisplay.Container className="mr-0">
                <CustomInput defaultChecked={defaultSelected} id={identity.identity_id} type="radio" name="type" onChange={() => formik.setFieldValue('identity_id', identity.identity_id)} />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title>{identity.name + ' ' + identity.family_name}</UserDisplay.Title>
                <UserDisplay.Subtitle>{identity.email}</UserDisplay.Subtitle>
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export const CreateIdentityForm = ({ toggleCreate, member, onSubmit, from, ...props }) => {
    const authContext = useContext(AuthContext);
    const membersContext = useContext(MembersContext);
    const [ identityId, setIdentityId ] = useState('');
    const [ identityName, setIdentityName ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState(null);

    const createMetaMemberAndLink = () => {
        setIsLoading(true);
        membersContext.createMetaMember(member.member_id, identityId)
            .then(async(metaMemberId) => {
                setIsLoading(false); // onSubmit will trigger another loading state
                onSubmit?.(identityId, setError, identityName);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setIsLoading(false);
                    setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                }
            })
    }

    return (
        identityId ?
            <OverlayLoader isLoading={isLoading}>
                <ModalBody>
                    <span className='text-dark'>
                        <Translate id='memberProfile.registration.memberIdentities.identityExists' values={{ from: from }} />
                    </span>
                    <Collapse isOpen={!!error} appear mountOnEnter unmountOnExit>
                        {error &&
                            <CustomAlert className='mt-3' color='danger' withTitle text={error} translateText toggle={() => setError(null)} />
                        }
                    </Collapse>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' type='button' onClick={() => createMetaMemberAndLink()}><Translate id='memberProfile.registration.memberIdentities.link' /></Button>
                    <Button
                        color='primary' outline type='button'
                        onClick={() => {
                            setIdentityId('');
                            setIdentityName('');
                        }}
                    >
                        <Translate id='misc.cancel' />
                    </Button>
                </ModalFooter>
            </OverlayLoader>
            :
            <>
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        locale: '',
                    }}
                    validationSchema={object().shape({
                        firstName: string().required(<Translate id='form.validation.firstName.required' />),
                        lastName: string().required(<Translate id='form.validation.lastName.required' />),
                        email: string().email(<Translate id='form.validation.email.valid' />).required(<Translate id='form.validation.email.required' />),
                        locale: string().required(<Translate id='form.validation.language.required' />),
                    })}
                    onSubmit={(values, { setStatus }) => {
                        return authContext.getUsersList('email', '=', values.email)
                            .then(async(response) => {
                                if(response.Users.length > 0){ // identity exists
                                    setIdentityId(response.Users[0].Username) // there shouldn't be more than 1 identity with this email
                                    setIdentityName(`${response.Users[0].name} ${response.Users[0].family_name}`)
                                }else{
                                    const response = await membersContext.inviteMember(member.member_id, {
                                        email: values.email,
                                        name: values.firstName,
                                        family_name: values.lastName,
                                        language_code: values.locale,
                                    }).catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                                    onSubmit?.(response.identity_id, setStatus, `${values.firstName} ${values.lastName}`)
                                }
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message);
                                    fail();
                                    setStatus('misc.error')
                                }
                            })
                    }}
                >
                    {(formik) => (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalBody>
                                    <Row form>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label for='firstName' className='text-muted'><Translate id='form.fields.firstName' /> <Required /></Label>
                                                <FormikInputText id='firstName' name='firstName' trim />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6'>
                                            <FormGroup>
                                                <Label for='lastName' className='text-muted'><Translate id='form.fields.lastName' /> <Required /></Label>
                                                <FormikInputText id='lastName' name='lastName' trim />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='12'>
                                            <FormGroup>
                                                <Label for='email' className='text-muted'><Translate id='form.fields.email' /> <Required /></Label>
                                                <FormikInputText id='email' name='email' trim />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='12'>
                                            <FormGroup>
                                                <Label for='locale' className='text-muted'><Translate id='form.fields.language' /> <Required /></Label>
                                                <FormikSelect
                                                    name='locale' id='ManualRegistrationMemberIdentitiesLocaleSelect'
                                                    search={false}
                                                    placeholder='form.fields.primaryLanguage.placeholder'
                                                    loadingStatus='success'
                                                    renderOption={(option) => <Translate id={option.option.label} />}
                                                    defaultData={[
                                                        { value: 'fr', label: 'misc.fr.long' },
                                                        { value: 'en', label: 'misc.en.long' },
                                                    ]}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                        {formik.status &&
                                            <CustomAlert className='mt-3' color='danger' withTitle text={formik.status} translateText toggle={() => formik.setStatus()} />
                                        }
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button type='submit' color='primary' disabled={formik.isSubmitting}><Translate id='misc.create' /></Button>
                                    <Button type='button' color='primary' outline onClick={toggleCreate} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )}
                </Formik>
            </>
    )
}

export default withContexts(CartsContext, MerchantAccountContext)(ManualRegistrationMemberIdentities)