export default{
    "organization.profile.overview.generalInfo.title" : "Informations générales",

    // Form Fields
    "organization.profile.overview.generalInfo.form.fields.orgName"        : "Nom d'organisation ({lang})",
    "organization.profile.overview.generalInfo.form.fields.orgNameEn"      : "Nom d'organisation (EN)",
    "organization.profile.overview.generalInfo.form.fields.orgNameFr"      : "Nom d'organisation (FR)",
    "organization.profile.overview.generalInfo.form.fields.abb"            : "Abréviation",
    "organization.profile.overview.generalInfo.form.fields.code"           : "Code",
    "organization.profile.overview.generalInfo.form.fields.usualNameShort" : "Nom usuel",
    "organization.profile.overview.generalInfo.form.fields.usualName"      : "Nom usuel ({lang})",
    "organization.profile.overview.generalInfo.form.fields.usualNameEn"    : "Nom usuel (EN)",
    "organization.profile.overview.generalInfo.form.fields.usualNameFr"    : "Nom usuel (FR)",
    "organization.profile.overview.generalInfo.form.fields.website"        : "Site web de contact",
    "organization.profile.overview.generalInfo.form.fields.website.visit"  : "Visitez le site Web",
    "organization.profile.overview.generalInfo.form.fields.phoneExt"       : "Poste",
    "organization.profile.overview.generalInfo.form.fields.tags"           : "Tags",

    // Form Validation
    "organization.profile.overview.generalInfo.form.validation.orgName"        : "Le nom d'organisation est requis",
    "organization.profile.overview.generalInfo.form.validation.orgNameEn"      : "Le nom d'organisation (EN) est requis",
    "organization.profile.overview.generalInfo.form.validation.orgNameFr"      : "Le nom d'organisation (FR) est requis",
    "organization.profile.overview.generalInfo.form.validation.email"          : "Le courriel est requis",
    "organization.profile.overview.generalInfo.form.validation.abb"            : "L'abréviation est requise",
    "organization.profile.overview.generalInfo.form.validation.email.format"   : "Le format de l'adresse courriel n'est pas valide",
    "organization.profile.overview.generalInfo.form.validation.website.format" : "Le format du site web n'est pas valide",

    // Swal toast
    "organization.profile.overview.generalInfo.toast.success" : "Informations mises à jour",
    "organization.profile.overview.generalInfo.toast.fail"    : "Impossible de mettre les informations à jour",
}