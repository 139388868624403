import { ChecklistsContext } from "../../../contexts/ChecklistsContext";
import { useLocation, matchPath } from "react-router";
import { useContext } from "react";
import { isMobileDevice } from "../../../helpers/constants";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";

const ChecklistsBreadcrumbs = () => {
    if(isMobileDevice)return null;

    const checklistCode = matchPath(location.pathname, {
        path: '/tasks/checklists/:checklist',
        exact: true,
        strict: false,
    })?.params?.checklist;

    const { checklists } = useContext(ChecklistsContext);
    const { pathname } = useLocation();
    const currentChecklist = checklists?.[checklistCode];

    const isChecklistPath = checklistCode && !!currentChecklist && pathname.includes(checklistCode);

    return (
        <ViewHeaderV2
            breadcrumbsProps={{
                withRefresh: true,
                favoriteData:
                    isChecklistPath ?
                        {
                            label: currentChecklist.name,
                            i18n: currentChecklist.i18n,
                            breadcrumbs: [ { path: pathname, name: 'sidebar.checklists' } ],
                            translate: false,
                        }
                        :
                        {},
                extraBreadcrumbs:
                    isChecklistPath ?
                        [
                            {
                                skipTranslate: true,
                                path: pathname,
                                name: <DisplayI18n i18n={currentChecklist.i18n} field="name" defaultValue={currentChecklist.name} />,
                            },
                        ]
                        :
                        [],
                className: "mb-2",
            }}
        />
    );
}

export default ChecklistsBreadcrumbs;