import clinicCommunications from "./clinicCommunications";
import clinicInformation from "./clinicInformation";
import clinicPaymentOptions from "./clinicPaymentOptions";
import clinicSessionInformation from "./clinicSessionInformation";

export default{
    ...clinicCommunications,
    ...clinicInformation,
    ...clinicPaymentOptions,
    ...clinicSessionInformation,
}