// /* eslint-disable react/no-unused-class-component-methods */
import queryString from 'query-string';
import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';

/** @type {React.Context<Omit<MessagesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & MessagesContextProvider['state']>} */
export const MessagesContext = createContext();
MessagesContext.displayName = 'MessagesContext';

class MessagesContextProvider extends React.PureComponent{
    getReceivedMessages = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/communications/messages/received`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {
                if(res.data.status){
                    return res.data;
                }

                throw res.data.errors[0]
            }, serverError)
    }

    getSentMessages = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/communications/messages/sent`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {
                if(res.data.status){
                    return res.data;
                }

                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * @param {string} commEmailLogId
     * @param {object} values
     * @see {@link https://api.id.dev.spordle.dev/documentations/index.php#/Communications/4011eeb404cb23c707cf1c058557b895|documentation}
     * @returns {Promise}
     * @throws {object}
     */
    partiallyUpdateMessage = (commEmailLogId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(`/communications/${commEmailLogId}`, params)
            .then((res) => {
                if(res.data.status){
                    return res.data
                }
                throw res.data.errors[0]
            }, serverError)
    }

    sendMessage = (orgId, values = {}) => {
        const params = new URLSearchParams();

        return API_SPORDLE.post(`/organisations/${orgId}/communications`, params)
            .then((res) => {

                if(res.data.status){
                    return res.data.messages
                }

                throw res.data.errors[0]
            }, serverError)

    }

    getSettingMessage = (messageId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/messages/${messageId}`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {
                if(res.data.status){
                    return res.data.messages;
                }

                throw res.data.errors[0]
            }, serverError)
    }

    partialUpdateMessage = (messageId, values = {}) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(`/messages/${messageId}`, params)
            .then((res) => {
                if(res.data.status){
                    return true;
                }

                throw res.data.errors[0]
            }, serverError)
    }

    deleteSettingMessage = (messageId) => {
        return API_SPORDLE.delete(`/messages/${messageId}`)
            .then((res) => {
                if(res.data.status){
                    return true;
                }

                throw res.data.errors[0]
            }, serverError)
    }

    createSettingMessage = (values = {}) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.post(`/messages`, params)
            .then((res) => {

                if(res.data.status){
                    return res.data.message_id
                }

                throw res.data.errors[0]
            }, serverError)
    }

    render(){
        return (
            <MessagesContext.Provider value={{ ...this.state, ...this }}>
                {this.props.children}
            </MessagesContext.Provider>
        );
    }
}

export default MessagesContextProvider;
