import { FormikDateTime, FormikError, FormikInputNumber } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Form, Formik, useFormikContext } from "formik";
import moment from "moment";
import { useContext } from "react";
import { Alert, Button, Col, Collapse, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { mixed, number, object, string } from "yup";
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import CrossFade from "../../../../components/crossFade/CrossFade";
import Required from "../../../../components/formik/Required";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";

const RANGE = 1;
const IDENTITY = 2;

const PdfSettingsModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName="insurancePdfSettingsModal">
            <PdfSettingsModalInner {...props} />
        </AnalyticsModal>
    );
}

const PdfSettingsModalInner = ({ printCheques, toggle, initMode, disabledModes = [] }) => {
    const { identity } = useContext(IdentityRolesContext);

    return (
        <Formik
            initialStatus={{
                isOpen: false,
                error: null,
            }}
            initialValues={{
                chequeNbTab: IDENTITY,
                chequeDate: moment(),
                chequeNbStart: '',
                chequeNbFrom: '',
                chequeNbTo: '',

                print_mode: initMode ?? 'unprinted',
            }}
            validationSchema={object().shape({
                chequeNbTab: number().integer(),
                chequeNbStart: number().when('print_mode', {
                    is: (v) => v === 'unprinted',
                    then: (schema) => schema.required(<Translate id='insurance.validation.startCheque.required' />),
                }),
                chequeDate: mixed().when('print_mode', {
                    is: (v) => v === 'unprinted',
                    then: (schema) => schema.test({
                        name: 'validDate',
                        message: <Translate id='form.validation.date.format' />,
                        test: function(date){
                            return !date || moment.isMoment(date);
                        },
                    })
                        .required(<Translate id='form.validation.date.required' />),
                }),
                chequeNbFrom: number().when([ 'print_mode', 'chequeNbTab' ], {
                    is: (v, tab) => v === 'chequeNb' && tab === RANGE,
                    then: (schema) => schema.required(<Translate id='insurance.sidePanel.cheque.print.number.from.required' />),
                }),
                chequeNbTo: number().when([ 'print_mode', 'chequeNbTab' ], {
                    is: (v, tab) => v === 'chequeNb' && tab === RANGE,
                    then: (schema) => schema.required(<Translate id='insurance.sidePanel.cheque.print.number.to.required' />),
                }),
                print_mode: string().required(<Translate id='insurance.sidePanel.cheque.print.mode.required' />),
            })}
            onSubmit={async(values, { setStatus, setSubmitting }) => {
                const apiVal = {};

                if(values.print_mode === "unprinted"){
                    apiVal.first_cheque_number = values.chequeNbStart;
                    apiVal.cheque_date = moment(values.chequeDate).format('YYYY-MM-DD');
                }else if(values.print_mode === "chequeNb"){
                    if(values.chequeNbTab === RANGE){
                        apiVal.injury_case_number = values.chequeNbFrom + ',' + values.chequeNbTo;
                    }else{
                        apiVal.created_by = identity.identity_id;
                        apiVal.is_printed = 0;
                    }
                }

                return printCheques(apiVal)
                    .then(toggle)
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e.message);
                            setSubmitting(false);
                            if(e.message == 'nocheques'){
                                setStatus({
                                    isOpen: true,
                                    info:
                                    <>
                                        <i className="mr-1 mdi mdi-information" />
                                        <Translate id='insurance.sidePanel.cheque.print.noCheques.info' />
                                    </>,
                                })
                            }else{
                                setStatus({ isOpen: true, error: <Translate id="misc.error" /> })
                            }
                        }
                    })

            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={formik.isSubmitting}>
                    <Form>
                        <ModalHeader toggle={toggle}>
                            <Translate id='insurance.sidePanel.cheque.print.modal.title' />
                        </ModalHeader>
                        <ModalBody>
                            <CrossFade isVisible={formik.values.print_mode === "chequeNb"}>
                                <PrintChequeNbSettings />
                            </CrossFade>
                            <CrossFade isVisible={formik.values.print_mode === "unprinted"}>
                                <div className="font-medium h5">
                                    <Translate id="insurance.sidePanel.cheque.print.btn.unprinted.helper" />
                                </div>
                                <p className="mb-3 text-muted"><Translate id='insurance.sidePanel.cheque.print.unprinted.text' /></p>
                                <Row form>
                                    <Col sm="6">
                                        <Label className="mb-1 text-muted" for="chequeNbStart">
                                            <Translate id='insurance.sidePanel.cheque.print.unprinted.start' /> <Required />
                                        </Label>
                                        <FormikInputNumber
                                            id="chequeNbStart"
                                            name="chequeNbStart"
                                            allowNegative={false}
                                            placeholder="000000010"
                                            allowLeadingZeros
                                            translatePlaceholder={false}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <Label className="mb-1 text-muted" for="chequeDate">Date <Required /></Label>
                                        <FormikDateTime
                                            id="chequeDate"
                                            name="chequeDate"
                                            placeholder="YYYY-MM-DD"
                                            translatePlaceholder={false}
                                            timeFormat={false}
                                        />
                                    </Col>
                                </Row>
                            </CrossFade>
                            {formik.errors.print_mode &&
                                <div className="pt-3">
                                    <FormikError name="print_mode" />
                                </div>
                            }
                            <Collapse isOpen={formik.status.isOpen}>
                                <Alert className="mt-3 mb-0" color={formik.status.error ? "danger" : "primary"} toggle={() => formik.setStatus({ ...formik.status, isOpen: false })}>
                                    {formik.status.error || formik.status.info}
                                </Alert>
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button disabled={formik.isSubmitting} type="submit" color="primary">
                                <Translate id="misc.print" />
                            </Button>
                            <Button disabled={formik.isSubmitting} onClick={toggle} type="button" color="primary" outline>
                                <Translate id="misc.cancel" />
                            </Button>
                        </ModalFooter>
                    </Form>
                </OverlayLoader>
            )}
        </Formik>
    )
}

const PrintChequeNbSettings = () => {
    const { setFieldValue, values } = useFormikContext();
    const activeTab = values.chequeNbTab;

    const setActiveTab = (val) => {
        setFieldValue('chequeNbTab', val);
    }

    return (
        <>
            <div className="d-flex align-items-center">
                <div
                    onClick={() => setActiveTab(IDENTITY)}
                    className={stringBuilder(
                        "mr-2 flex-grow-1 btn btn-lg select-request",
                        activeTab === IDENTITY ? "border-primary bg-primary-light text-primary" : "border",
                    )}
                    color="light"
                >
                    <Translate id='insurance.sidePanel.cheque.print.chequeNb.identity' />
                </div>
                <div
                    onClick={() => setActiveTab(RANGE)}
                    className={stringBuilder(
                        "ml-2 flex-grow-1 btn btn-lg select-request",
                        activeTab === RANGE ? "border-primary bg-primary-light text-primary" : "border",
                    )}
                    color="light"
                >
                    <Translate id='insurance.sidePanel.cheque.print.chequeNb.range' />
                </div>
            </div>
            <CrossFade isVisible={activeTab === IDENTITY}>
                <div className="font-medium h5 mt-3"><Translate id='insurance.sidePanel.cheque.print.chequeNb.identity.helper.title' /></div>
                <p className="mb-0"><Translate id='insurance.sidePanel.cheque.print.chequeNb.identity.helper' /></p>
            </CrossFade>
            <CrossFade isVisible={activeTab === RANGE}>
                <div className="font-medium h5 mt-3">
                    <Translate id="insurance.sidePanel.cheque.print.btn.chequeNb.helper" />
                </div>
                <Row form>
                    <Col sm="6">
                        <Label className="mb-1 text-muted" for="chequeNbFrom">
                            <Translate id='insurance.sidePanel.cheque.print.from' /> <Required />
                        </Label>
                        <FormikInputNumber
                            id="chequeNbFrom"
                            name="chequeNbFrom"
                            allowNegative={false}
                            placeholder="000000010"
                            allowLeadingZeros
                            translatePlaceholder={false}
                        />
                    </Col>
                    <Col sm="6">
                        <Label className="mb-1 text-muted" for="chequeNbTo"><Translate id='insurance.sidePanel.cheque.print.to' /> <Required /></Label>
                        <FormikInputNumber
                            placeholder="000000016"
                            allowLeadingZeros
                            translatePlaceholder={false}
                            id="chequeNbTo"
                            name="chequeNbTo"
                            allowNegative={false}
                        />
                    </Col>
                </Row>
            </CrossFade>
        </>
    )
}

export default PdfSettingsModal;