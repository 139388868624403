import AnalyticsModal from "../../analytics/AnalyticsModal";
import PropTypes from "prop-types";
import OverlayLoader from "../loading/OverlayLoader";
import { useContext } from "react";
import Translate from "@spordle/intl-elements";
import { ModalHeader } from "reactstrap";
import CrossFade from "../crossFade/CrossFade";
import FormView from "./components/steps/formView/FormView";
import SuccessView from "./components/steps/successView/SuccessView";
import ReviewRecipients from "./components/steps/reviewRecipients/ReviewRecipients";
import SelectCommType from "./components/steps/selectCommType/SelectCommType.jsx";
import SendCommModalContextProvider, { SendCommModalContext } from "./context/SendCommModalContext";

/**
 * @callback OnConfirm
 * @param {{ msg: string, subject: string }} values
 * @returns {Promise<{fail: string[], success: string[]}>}
 */

/**
 * @param {Object} props
 * @param {string} props.id
 * @param {boolean} props.isOpen
 * @param {function} props.toggle
 * @param {OnConfirm} props.onConfirm
 * @param {object} [props.initValues={}]
 * @param {string|React.ReactElement} [props.initValues.msg]
 * @param {string} [props.initValues.subject]
 * @param {object[]} props.recipients
 * @param {string} props.recipients.email
 * @returns {React.ReactElement}
 * @example
 * <SendCommunicationModal
 *  isOpen={modalIsOpen}
 *  toggle={() => setModalIsOpen(false)}
 *  id="attendeesSidePanel"
 *  onConfirm={(values) =>
 *      return sendCommunication(attendeeId, values)
 *          .then((apiVal) => ({ fail: apiVal.failed_email, success: apiVal.success_email }))
 *  }
 * />
 */
const SendCommModal = ({ id, ...props }) => {
    return (
        <AnalyticsModal size="lg" isOpen={props.isOpen} analyticsName={`CommunicationsModal_${id}`}>
            <SendCommModalContextProvider {...props}>
                <SendCommModalInner {...props} />
            </SendCommModalContextProvider>
        </AnalyticsModal>
    );
}

const SendCommModalInner = ({ onConfirm }) => {
    const modalContext = useContext(SendCommModalContext);
    const isActiveStep = (nb) => modalContext.step === nb;

    const toggleSearchRecipient = () => {
        modalContext.goTo(1);
    }

    return (
        <OverlayLoader isLoading={modalContext.isLoading}>
            <ModalHeader toggle={modalContext.toggle}>
                <Translate id='components.communications.modal.title' />
            </ModalHeader>
            <CrossFade isVisible={isActiveStep(0)} unmountOnExit>
                <SelectCommType />
            </CrossFade>
            <CrossFade isVisible={isActiveStep(1)}>
                <ReviewRecipients
                    stepNb={1}
                    toggleSearchRecipient={toggleSearchRecipient}
                />
            </CrossFade>
            <CrossFade isVisible={isActiveStep(2)}>
                <FormView stepNb={2} />
            </CrossFade>
            <CrossFade isVisible={isActiveStep(3)}>
                <SuccessView isVisible={isActiveStep(3)} />
            </CrossFade>
        </OverlayLoader>
    )
}

SendCommModal.propTypes = {
    id: PropTypes.string.isRequired,
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func,
    recipients: PropTypes.arrayOf(PropTypes.objectOf({ email: PropTypes.email })),
    initValues: PropTypes.objectOf({
        subject: PropTypes.string,
        msg: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
    }),
}

export default SendCommModal;