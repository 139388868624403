import { FormikError } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import { Button, FormGroup, Label, ModalBody, ModalFooter } from "reactstrap";
import { object } from "yup";
import FileUpload from "../../../../../../components/uploader/fileUpload/FileUpload";
import { mbToBytes } from "../../../../../../components/uploader/uploadHelpers";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper";

const StepInsuranceDocuments = ({ updateData, previous, next, currentStep, docInfo, isCurrentStep }) => {
    const previousStep = useRef(currentStep);
    const { getGenericLocale } = useContext(I18nContext);

    useEffect(() => {
        if(docInfo.requiredTypes.length <= 0 && isCurrentStep){

            if(previousStep.current < currentStep){
                next();
            }else if(previousStep.current > currentStep){
                previous();
            }
        }

        previousStep.current = currentStep;
    }, [ currentStep ]);

    return (
        <Formik
            enableReinitialize
            initialValues={docInfo.initVal}
            validationSchema={object().shape(docInfo.validationSchema)}
            onSubmit={(values) => {
                const attachmentsArray = Object.entries(values).flatMap(([ docTypeId, files ]) => (
                    files.map((file) => ({ document_type_id: docTypeId, file: file }))
                ));

                updateData('attachments', attachmentsArray);
                next();
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <div className="font-bold h4">Documents</div>
                        {docInfo.requiredTypes.length > 0 &&
                            docInfo.requiredTypes.map((docType) => (
                                <React.Fragment key={docType.document_type_id}>
                                    <FormGroup>
                                        <Label className="text-muted">
                                            <DisplayI18n field='name' defaultValue={docType.name} i18n={docType.i18n} />
                                        </Label>
                                        <FileUpload
                                            showCreatedAt
                                            defaultFiles={[]}
                                            dropzoneProps={{
                                                multiple: true,
                                                maxSize: docType.file_size ? mbToBytes(parseFloat(docType.file_size)) : undefined,
                                                accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                            }}
                                            id={`attachments-${docType.document_type_id}`}
                                            onFileSelect={(files) => formik.setFieldValue(docType.document_type_id, files)}
                                            name={`attachments-${docType.document_type_id}`}
                                        />
                                    </FormGroup>
                                    <FormikError name={docType.document_type_id} />
                                    {docType.description &&
                                        <div
                                            className='small text-muted'
                                            dangerouslySetInnerHTML={{
                                                __html: displayI18n("description", docType.i18n, docType.description, getGenericLocale()),
                                            }}
                                        />
                                    }
                                </React.Fragment>
                            ))
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button className="mr-auto" color="primary" type="button" outline onClick={previous}><Translate id="misc.previous" /></Button>
                        <Button color="primary" type="submit">
                            <Translate id="misc.next" />
                        </Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default StepInsuranceDocuments;