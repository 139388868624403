import Translate from "@spordle/intl-elements";
import { Col, Collapse, Label, Row } from "reactstrap";
import FormikEditable from "../../../../../components/formik/FormikEditable";
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import { useContext } from "react";
import { TeamsContext } from "../../../../../contexts/TeamsContext";
import { fail, success } from "@spordle/toasts";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { object, string } from "yup";
import Required from "../../../../../components/formik/Required";
import { DisplayCategory } from "../../../../teams/TeamHelpers";
import { RegistrationCategoriesContext } from "../../../../../contexts/RegistrationCategoriesContext";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";

const TravelPermitSidepanelHostCategory = ({ syncRows, setIsLoading, createNewValues, data }) => {
    const teamsContext = useContext(TeamsContext);
    const organizationContext = useContext(OrganizationContext);
    const { getRegistrationCategories } = useContext(RegistrationCategoriesContext);
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <FormikEditable
            id='host_division'
            initialValues={{
                host_team_category: data.host_team_category || null,
                host_team_category_id: data.host_team_category?.host_team_category_id || "NOT_LISTED",
                host_division: data.host_division || '',
                host_class: data.host_class || '',
                host_category: data.host_category || '',
            }}
            validationSchema={object().shape({
                host_team_category_id: string().required(<Translate id="travelPermit.addModal.hostCategoryRequired" />),
                host_division: string().when('host_team_category_id', {
                    is: 'NOT_LISTED',
                    then: (schema) => schema.required(<Translate id='travelPermit.addModal.hostDivisionRequired' />),
                }),
            })}
            onSubmit={(values) => {
                const divIsDiff = values.host_division !== (data.host_division || "");
                const classIsDiff = values.host_class !== (data.host_call || "");
                const catIsDiff = values.host_category !== (data.host_category || "");
                const catIdIsDiff = (values.host_team_category_id === "NOT_LISTED" ? "" : values.host_team_category_id) !== (data.host_team_category?.host_team_category_id || "");
                const valuesHaveChanged = divIsDiff || classIsDiff || catIdIsDiff || catIsDiff;

                if(valuesHaveChanged){
                    setIsLoading(true);
                    const formattedValues = { ...values };

                    if(formattedValues.host_team_category_id === "NOT_LISTED"){
                        formattedValues.host_team_category_id = "";
                    }

                    delete formattedValues.host_team_category;

                    teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, formattedValues)
                        .then(() => {
                            const rowValues = { ...formattedValues };

                            rowValues.host_team_category = values.host_team_category ? {
                                ...values.host_team_category,
                                host_team_category_id: values.host_team_category?.team_category_id,
                            } : null

                            const newValues = createNewValues(rowValues);
                            syncRows(newValues);
                            success({ msg: 'travelPermit.toast.success' });
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                            setIsLoading(false);
                        })
                }
            }}
        >
            {(isEditing, _, formik) => {
                if(!isEditing){
                    return (
                        <Row form>
                            <Col sm={12} className="mb-3">
                                <Label for='host_team_category_id' className='text-muted mb-0'><Translate id="travelPermit.addModal.hostTeamCategory" /></Label>
                                <div className='font-medium text-dark'>
                                    {data.host_team_category ?
                                        <DisplayI18n
                                            field="name"
                                            i18n={data.host_team_category.i18n}
                                            defaultValue={data.host_team_category.name}
                                        />
                                        :
                                        "-"
                                    }
                                </div>
                            </Col>
                            {!data.host_team_category &&
                                <>
                                    <Col sm={6} className="mb-3">
                                        <Label for='host_division' className='text-muted mb-0'><Translate id='travelPermit.sidePanelHost.division' /></Label>
                                        <div className='font-medium text-dark'>{data.host_division || '-'}</div>
                                    </Col>
                                    <Col sm={6} className="mb-3">
                                        <Label for='host_division' className='text-muted mb-0'><Translate id='travelPermit.sidePanelHost.class' /></Label>
                                        <div className='font-medium text-dark'>{data.host_class || '-'}</div>
                                    </Col>
                                    <Col sm={6} className="mb-3">
                                        <Label for='host_division' className='text-muted mb-0'><Translate id='travelPermit.sidePanelHost.category' /></Label>
                                        <div className='font-medium text-dark'>{data.host_category || '-'}</div>
                                    </Col>
                                </>
                            }
                        </Row>
                    )
                }

                return (
                    <Row form>
                        <Col sm={12} className="mb-3">
                            <Label for='host_division' className='text-muted mb-0'><Translate id="travelPermit.addModal.hostTeamCategory" /> <Required /></Label>
                            <FormikSelect
                                id="host_team_category_id"
                                name="host_team_category_id"
                                searchKeys={[
                                    `i18n.${getGenericLocale()}.name`,
                                    'gender',
                                ]}
                                renderSelectedOption={(option) => option.value == "NOT_LISTED" ? <Translate id="travelPermit.addModal.category.notListed" /> : <DisplayCategory category={option} />}
                                renderOption={({ option, isSelected }) => option.value == "NOT_LISTED" ?
                                    <>
                                        <Translate id="travelPermit.addModal.category.notListed" />
                                        <div className={`small text-${isSelected ? 'light' : 'muted'} mdi mdi-information-outline with-icon`}>
                                            <Translate id="travelPermit.addModal.category.notListed.helper" />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <DisplayCategory category={option} />
                                        {!!option.gender &&
                                            <div className={`small text-${isSelected ? 'light' : 'muted'}`}>
                                                <Translate id={`form.fields.gender.${option.gender.toLowerCase()}`} />
                                            </div>
                                        }
                                    </>
                                }
                                onOptionSelected={([ val ], select) => {
                                    formik.setFieldValue(
                                        'host_team_category',
                                        val === "NOT_LISTED" ?
                                            null
                                            :
                                            select.getSpordleTable().getData().find(({ value }) => value == val),
                                    )
                                }}
                                loadData={(from) => {
                                    switch (from){
                                        case 'CDM':
                                            return getRegistrationCategories(organizationContext.organisation_id, { active: 1 })
                                                .then((categories) => {
                                                    const options = categories.map((category) => ({
                                                        ...category,
                                                        label: category.name,
                                                        value: category.team_category_id,
                                                    }));
                                                    options.sort((a, b) => parseInt(a.display_order || 0) - parseInt(b.display_order || 0))
                                                    return [ { label: "travelPermit.addModal.category.notListed", value: 'NOT_LISTED' }, ...options ];
                                                })
                                        default:
                                            break;
                                    }
                                }}
                            />
                        </Col>
                        <Col sm={12}>
                            <Collapse isOpen={formik.values.host_team_category_id == "NOT_LISTED"}>
                                <Row form>
                                    <Col sm={6} className="mb-3">
                                        <Label for='host_division' className='text-muted mb-0'><Translate id='travelPermit.sidePanelHost.division' /> <Required /></Label>
                                        <FormikInputText id='host_division' name='host_division' />
                                    </Col>
                                    <Col sm={6} className="mb-3">
                                        <Label for='host_division' className='text-muted mb-0'><Translate id='travelPermit.sidePanelHost.class' /></Label>
                                        <FormikInputText id='host_class' name='host_class' />
                                    </Col>
                                    <Col sm={6} className="mb-3">
                                        <Label for='host_division' className='text-muted mb-0'><Translate id='travelPermit.sidePanelHost.category' /></Label>
                                        <FormikInputText id='host_category' name='host_category' />
                                    </Col>
                                </Row>
                            </Collapse>
                        </Col>
                    </Row>
                )

            }}
        </FormikEditable>
    );
}

export default TravelPermitSidepanelHostCategory;