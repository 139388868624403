import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { OrganizationContext } from './OrganizationContext';
import withContexts from '../helpers/withContexts';

/** @type {React.Context<Omit<DocumentTypesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const DocumentTypesContext = createContext();
DocumentTypesContext.displayName = 'DocumentTypesContext';

class DocumentTypesContextProvider extends React.PureComponent{
    /**
     * [GET] Gets all document types
     * @param {string} [orgId] ID of the organisation
     * @param {object} [queryParams] Query params
     * @returns {Promise.<object[]>} Returns an array of all document types
     * @see https://api.id.dev.spordle.dev/documentations/#/Document%20Types/c6bc2c25d37d24f068282b77fee737d0
     */
    getDocumentTypes = (orgId = this.props.OrganizationContext.organisation_id, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/document-types`,
            query: Object.assign({
                organisation_id: orgId,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.document_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Gets all document type expirations by org id
     * @param {string} [orgId] ID of the organisation
     * @param {object} [queryParams] Query params
     * @returns {Promise.<object[]>} Returns an array of all document types
     * @see https://api.id.dev.spordle.dev/documentations/#/Document%20Types/c6bc2c25d37d24f068282b77fee737d0
     */
    getDocumentTypeExpirations = (orgId = this.props.OrganizationContext.organisation_id, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/document-type-expiration`,
            query: Object.assign({
                organisation_id: orgId,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.document_type_expirations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a specified Document Type Expiration with new values
     * @param {string} documentTypeId ID of the Document Type to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @returns {Promise}
     */
    updateDocumentTypeExpiration = (documentTypeExpirationId, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `document-type-expiration/${documentTypeExpirationId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Creates a document type expiration
     * @param {string} organizationId ID of the Organization to add a Document Type to
     * @param {boolean} active Wether or not the Document Type is active
     * @returns {Promise}
     */
    createDocumentTypeExpiration = (organizationId, values) => {
        const params = new URLSearchParams();
        params.append('organisation_id', organizationId);
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `document-type-expiration` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.document_type_expiration_id;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Deletes a document expiration
     * @param {string} documentTypeExpirationId The fee id to delete - Refer to the {@link https://api.id.spordle.dev/documentations/#/Fees/Apicontroller%5CPricing%5CFees%3A%3AdeleteFee|documentation}
     * @returns {Promise}
     */
    deleteDocumentTypeExpiration = (documentTypeExpirationId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/document-type-expiration/${documentTypeExpirationId}` }))
            .then((response) => {
                if(response.data.status){
                    return;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <DocumentTypesContext.Provider value={{ ...this }}>
                {this.props.children}
            </DocumentTypesContext.Provider>
        );
    }
}

export default withContexts(OrganizationContext)(DocumentTypesContextProvider);