import Translate from '@spordle/intl-elements';
import {
    Button,
    ModalBody,
    ModalHeader
} from "reactstrap";
import CustomAnimatedIcon from '../../../../../components/customAnimatedIcon/CustomAnimatedIcon';

/**
 * Last step, shows that the merge has been successful
 * @param {Object} props
 * @param {function} props.toggle
 * @returns {JSX.Element}
 */
const MemberMergeConfirmation = ({ toggle, handleToggle, keptMember }) => {
    return (
        <>
            <ModalHeader tag='div' toggle={toggle}><Translate id='members.search.memberMerge.modal.title' /></ModalHeader>
            <ModalBody className="text-center">
                <CustomAnimatedIcon
                    className="text-success"
                    icon="checkmark"
                    withCircle
                    size="60"
                    strokeWidth="8px"
                />
                <div className="h4 font-bold mt-2"><Translate id="misc.success" />!</div>
                <p><Translate id='members.search.memberMerge.merge.success' /></p>
                <Button color="primary" type="button" onClick={handleToggle}><Translate id="misc.close" /> <i className="mdi mdi-check" /></Button>
            </ModalBody>
        </>
    );
}

export default MemberMergeConfirmation;