import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import useSWR from 'swr';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { MembersContext } from '../../../contexts/MembersContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { fail } from "@spordle/toasts";
import UserDisplay from '../../userDisplay/UserDisplay';

const TransferMemberAddress = ({ member }) => {
    const memberContext = useContext(MembersContext);

    const buildFullAddress = (address) => address.street ? `${address.street_number || ''}, ${address.street || ''}, ${address.city || ''}, ${address.province_id || ''} ${address.zip_code || ''}, ${address.country_id || ''}` : '';

    const { data: mainAddress, error, isValidating } = useSWR(
        [ 'getMemberAddresses', member.member_id ],
        () => memberContext.getMemberAddresses(member.member_id, { active: 1 })
            .then((addresses) => {
                return addresses.find((address) => address.default_address == '1') || addresses[0];
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        ,
        {
            revalidateOnFocus: false,
        },
    )
    return (
        <UserDisplay.Subtitle>
            {error ?
                <Translate id='misc.error' />
                : !isValidating ?
                    mainAddress ?
                        buildFullAddress(mainAddress)
                        :
                        <Translate id='transfer.sidepanel.address.none' />
                    :
                    <Skeleton />
            }
        </UserDisplay.Subtitle>
    );
}

export default TransferMemberAddress;