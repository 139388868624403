const CriticalWaivers = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 11.87 10.55"
            width={width}
            height={height}
        >
            <path
                className="fill-current"
                d="M10.16,1.18l-.8-.79c-.51-.52-1.35-.52-1.86,0l-1.77,1.76L0,7.88v2.67H2.66l5.77-5.76,1.73-1.74c.52-.51,.52-1.35,0-1.87ZM2.11,9.23h-.79v-.8L7.03,2.72l.8,.8L2.11,9.23Z"
            />
            <path
                className="fill-current"
                d="M4.89,9.96l1.95-1.95c.06-.06,.15-.1,.24-.1h4.2c.33,0,.59,.26,.59,.59v1.46c0,.33-.26,.59-.59,.59H5.13c-.31,0-.46-.37-.24-.59Z"
            />
        </svg>
    );
}

export default CriticalWaivers;