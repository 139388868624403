export default{
    "members.profile.suspensions.title" : "Suspensions",
    "members.profile.status.log.title"  : "Registre de statut du membre",

    // fields
    "members.profile.suspensions.infraction"             : "Infraction",
    "members.profile.suspensions.infraction.existing"    : "Infraction existante",
    "members.profile.suspensions.infraction.other"       : "Autre Infraction",
    "members.profile.suspensions.organization"           : "Organisation",
    "members.profile.suspensions.penaltyType"            : "Type de pénalité",
    "members.profile.suspensions.suspensionType"         : "Type de suspension",
    "members.profile.suspensions.date"                   : "Date",
    "members.profile.suspensions.duration"               : "Durée",
    "members.profile.suspensions.duration.definite"      : "Définie",
    "members.profile.suspensions.duration.indefinite"    : "Indéfinie",
    "members.profile.suspensions.duration.games"         : "{games} parties",
    "members.profile.suspensions.duration.numberOfGames" : "Nombre de parties",
    "members.profile.suspensions.startDate"              : "Date de début",
    "members.profile.suspensions.endDate"                : "Date de fin",
    "members.profile.suspensions.incidentDate"           : "Date de l'incident",
    "members.profile.suspensions.receivedDate"           : "Date de réception",
    "members.profile.suspensions.changeDate"             : "Date de modification",
    "members.profile.suspensions.location"               : "Lieu de l'incident",
    "members.profile.suspensions.status"                 : "Statut",
    "members.profile.suspensions.status.active"          : "Actif",
    "members.profile.suspensions.status.expired"         : "Expiré",
    "members.profile.suspensions.team"                   : "Équipe",
    "members.profile.suspensions.position"               : "Position",
    "members.profile.suspensions.opposingTeamName"       : "Nom de l'équipe adverse",
    "members.profile.suspensions.suggestedPosition"      : "Position suggérée:",
    "members.profile.suspensions.suggestedPositions"     : "Positions suggérées:",
    "members.profile.suspensions.notes"                  : "Notes",
    "members.profile.suspensions.attachments"            : "Pièces jointes",
    "members.profile.suspensions.created_date"           : "Date de création",
    "members.profile.suspensions.submitted_by"           : "Soumis par",
    "members.profile.suspensions.submitted_email"        : "Soumis par courriel",
    "members.profile.suspensions.teamMember"             : "Membre de l'équipe",
    "members.profile.suspensions.teamMember.min"         : "Au moin un membre doit être sélectionné",
    "members.profile.suspensions.memberTypes"            : "Types de membre",
    "members.profile.suspensions.memberTypes.required"   : "Au moins un type de membre est requis",
    "members.profile.suspensions.fee"                    : "Frais",
    "members.profile.suspensions.fine"                   : "Amende",
    "members.profile.suspensions.success.toast"          : "Suspension pour {memberType} créée avec succès",
    "members.profile.suspensions.fail.toast"             : "Échec de la création de suspension pour {memberType}",

    // validation
    "members.profile.suspensions.infraction.required"         : "L'infraction est requise",
    "members.profile.suspensions.penaltyType.required"        : "Le type de pénalité est requis",
    "members.profile.suspensions.games.required"              : "Le nombre de parties est requis",
    "members.profile.suspensions.startDate.required"          : "La date de début est requise",
    "members.profile.suspensions.endDate.required"            : "La date de fin est requise",
    "members.profile.suspensions.endDate.afterReceived"       : "La date de fin doit être après la date de réception",
    "members.profile.suspensions.endDate.afterStart"          : "La date de fin ne peut pas être avant la date de début",
    "members.profile.suspensions.endDate.afterToday"          : "Date cannot be set after today",
    "members.profile.suspensions.incidentDate.required"       : "La date de l'incident est requise",
    "members.profile.suspensions.incidentDate.afterToday"     : "La date de l'incident ne peut pas être après aujourd'hui",
    "members.profile.suspensions.incidentDate.beforeReceived" : "La date de l'incident ne peut pas être après la date de réception",
    "members.profile.suspensions.receivedDate.required"       : "La date de réception est requise",
    "members.profile.suspensions.receivedDate.afterIncident"  : "La date de réception ne peut pas être avant la date de l'incident",
    "members.profile.suspensions.location.required"           : "Le lieu de l'incident est requis",
    "members.profile.suspensions.team.required"               : "L'équipe est requise",
    "members.profile.suspensions.position.required"           : "La position est requise",

    // table

    // add modal
    "members.profile.suspensions.add.title" : "Ajouter une suspension",

    // sidepanel
    "members.profile.suspensions.sidepanel.title" : "Suspendu le",
    "members.profile.suspensions.sidepanel.PDF"   : "Exporter PDF",

    // delete suspension modal
    "members.profile.suspensions.delete.message" : "Vous allez supprimer une suspension pour ce joueur. Cette action est irréversible.",

    // activate modal
    "members.profile.suspensions.activate.title"             : "Activer une suspension",
    "members.profile.suspensions.activate.btn"               : "Activer",
    "members.profile.suspensions.activate.confirm"           : "Suspension activée avec succès!",
    "members.profile.suspensions.deactivate.title"           : "Désactiver une suspension",
    "members.profile.suspensions.deactivate.btn"             : "Désactiver",
    "members.profile.suspensions.deactivate.confirm"         : "Suspension désactivée avec succès!",
    "members.profile.suspensions.validation.dateAfterToday"  : "La date doit être après aujourd'hui",
    "members.profile.suspensions.validation.dateBeforeToday" : "La date ne peut pas être après aujourd'hui",
}
