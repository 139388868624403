import { Tooltip } from "@mantine/core";
import { useSpordleTable } from "@spordle/datatables";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { fail, success } from "@spordle/toasts";
import { useContext } from "react";
import { FormattedDate } from "react-intl";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { ConfirmModalInner } from "../../../../components/confirmModal/ConfirmModal";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import { I18nContext } from "../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { NewsContext } from "../../../../contexts/NewsContext";
import { removeHTML } from "../../../../helpers/helper";
import { displayI18n, DisplayI18n } from "../../../../helpers/i18nHelper";
import { useHistory } from "react-router-dom";
import Notice from "../../../../components/noticesList/noticesList/notice/Notice";
import styles from "./NewsPreview.module.scss";
import { newsIsLive, NEWS_TYPE_DATA } from "../../../../components/noticesList/newsHelpers";
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { useMountedState } from "../../../../helpers/mountedState";
import { useDisclosure } from "@mantine/hooks";

/**
 * @param {object} props
 * @param {object} props.news
 * @param {boolean} props.canEdit
 * @param {boolean} props.canDelete
 */
const NewsPreview = ({ news, canEdit, canDelete }) => {
    const spordleTable = useSpordleTable();
    const { isGod } = useContext(IdentityRolesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { deleteNews } = useContext(NewsContext);
    const newsTypeData = NEWS_TYPE_DATA[news.type];
    const history = useHistory();
    const isLive = newsIsLive(news);
    const [ deleteModalOpen, deleteModalHandlers ] = useDisclosure(false);

    const handleOnDelete = () => {
        return deleteNews(news.news_id)
            .then(() => {
                success({
                    msg: "news.delete.toast.success.title",
                    info: "news.delete.toast.success.subtitle",
                });

                spordleTable.deleteRow(news.news_id);
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    fail({
                        skipInfoTranslate: true,
                        info: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />,
                    });
                    console.error(e);
                }
            })
    }

    const handleOnEdit = () => {
        history.push({
            state: news,
            pathname: "/news/edit",
        });
    }

    return (
        <div
            className="position-relative"
            style={{
                transformStyle: "preserve-3d",
            }}
        >
            <UserDisplay
                onClick={handleOnEdit}
                card
                block
                hover={canEdit || isGod()}
                className="mb-2 position-static flex-wrap flex-md-nowrap p-0 p-md-3"
            >
                <UserDisplay.Container className="w-100 w-md-auto mb-3 mb-md-0 mr-0 mr-md-2">
                    <div className={`${styles.ImgContainer} rounded-lg overflow-hidden bg-light-inverse`}>
                        {(newsTypeData?.img || news.banner?.banner_id) &&
                            <img
                                className={styles.Img}
                                src={news.banner?.path_url || displayI18n('img', newsTypeData.i18n, newsTypeData.img, getGenericLocale())}
                                alt={news.banner?.name || newsTypeData.name}
                            />
                        }
                    </div>
                </UserDisplay.Container>
                <UserDisplay.Container className="flex-shrink-1 px-3 px-md-0 w-100 min-w-0">
                    <UserDisplay.Subtitle>
                        <time dateTime={news.published_at || news.created_at}>
                            {news.published_until && <><Translate id="misc.from" />: </>}<i className="mdi mdi-calendar mr-1" /><span className={stringBuilder({ "font-bold": isLive })}><FormattedDate value={news.published_at || news.created_at} /></span>
                        </time>
                        {news.published_until &&
                            <>
                                {' - '}
                                <time dateTime={news.published_until}>
                                    <Translate id="misc.to" />: <i className="mdi mdi-calendar mr-1" /><span className={stringBuilder({ "font-bold": isLive })}><FormattedDate value={news.published_until} /></span>
                                </time>
                            </>
                        }
                    </UserDisplay.Subtitle>
                    <UserDisplay.Title className={stringBuilder({ "text-link clickable": canEdit || isGod() })}>
                        <DisplayI18n field="title" defaultValue={news.title} i18n={news.i18n} />
                        {(canEdit || isGod()) && <i className="ml-1 mdi mdi-chevron-right" />}
                    </UserDisplay.Title>
                    <UserDisplay.Subtitle className="w-100">
                        <div className="text-truncate" style={{ height: '1.5em' }}>
                            {removeHTML(displayI18n("description", news.i18n, news.description, getGenericLocale()))}
                        </div>
                    </UserDisplay.Subtitle>
                </UserDisplay.Container>
                <UserDisplay.Container className="w-100 min-w-0 flex-shrink-0 w-md-auto px-3 pb-3 pb-md-0 px-md-0 ml-auto d-flex align-items-center">
                    <div className="text-nowrap flex-shrink-0 mr-auto mr-md-0">
                        <NewsIcon isLive={isLive} active={news.active} />
                    </div>
                    {(canEdit || canDelete || isGod()) &&
                        <>
                            <ConfirmModal
                                color="danger"
                                icon="mdi-alert-outline"
                                isOpen={deleteModalOpen}
                                closeModal={deleteModalHandlers.close}
                                modalMsg="news.delete.modal.msg"
                                translateModalMsg
                                onConfirm={handleOnDelete}
                                modalContent={
                                    <Notice
                                        className="text-left"
                                        articleClassName="min-h-auto border"
                                        notice={news}
                                        tag="div"
                                        id="deletingNotice"
                                    />
                                }
                            />

                            <UncontrolledDropdown className={styles.Options} onClick={(e) => e.stopPropagation()}>
                                <DropdownToggle
                                    color={!canEdit && !canDelete ? "link text-purple" : "link"}
                                    className="mdi font-20 mdi-dots-vertical bg-white"
                                />
                                <DropdownMenu container={"body"} right>
                                    {(isGod() || canEdit) &&
                                        <Tooltip
                                            className="w-100"
                                            disabled={canEdit}
                                            label={
                                                <>
                                                    <u>Power user only</u>
                                                    <div>communications → communication_news_manage → EDIT</div>
                                                </>
                                            }
                                            zindex={1010}
                                            withArrow
                                            position="left"
                                        >
                                            <DropdownItem onClick={handleOnEdit} className={stringBuilder({ "text-purple": !canEdit })}>
                                                <Translate id="misc.edit" />
                                            </DropdownItem>
                                        </Tooltip>
                                    }
                                    {(canDelete || isGod()) &&
                                        <Tooltip
                                            className="w-100"
                                            disabled={canDelete}
                                            label={
                                                <>
                                                    <u>Power user only</u>
                                                    <div>communications → communication_news_manage → DELETE</div>
                                                </>
                                            }
                                            zindex={1010}
                                            withArrow
                                            position="left"
                                        >
                                            <DropdownItem onClick={deleteModalHandlers.open} className={stringBuilder({ "text-purple": !canDelete })}>
                                                <Translate id="misc.delete" />
                                            </DropdownItem>
                                        </Tooltip>
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </>
                    }
                </UserDisplay.Container>
            </UserDisplay>
        </div>
    );
}

const NewsIcon = ({ isLive, active }) => (
    <>
        <i className={`mdi mdi-${isLive ? "access-point text-primary" : active == 1 ? "check text-primary" : "close text-danger"} mr-1`} />
        <Translate id={isLive ? "news.table.status.live" : `misc.status.${active}`} />
    </>
)

const ConfirmModal = ({ className, godAccess, isOpen, closeModal, ...props }) => {
    const isMounted = useMountedState();

    return (
        <>
            {isMounted &&
                <AnalyticsModal analyticsName='ConfirmModal' isOpen={isOpen}>
                    <ConfirmModalInner toggle={closeModal} {...props} />
                </AnalyticsModal>
            }
        </>
    )
}

export default NewsPreview;