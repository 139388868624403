export default{
    "teams.profile.teamRoster.activeRoster.add.title"                  : "Add players to",
    "teams.profile.teamRoster.activeRoster.add.label.season"           : "Season",
    "teams.profile.teamRoster.activeRoster.add.label.team"             : "Team",
    "teams.profile.teamRoster.activeRoster.add.label.member"           : "Member",
    "teams.profile.teamRoster.activeRoster.add.label.member_age"       : "Age",
    "teams.profile.teamRoster.activeRoster.add.label.status"           : "Status",
    "teams.profile.teamRoster.activeRoster.add.label.uniqueIdentifier" : "{unique_identifier}",

    "teams.profile.teamRoster.activeRoster.add.searchName"      : "Search by name",
    "teams.profile.teamRoster.activeRoster.add.searchTeam"      : "Search by team",
    "teams.profile.teamRoster.activeRoster.add.searchBirthdate" : "Search by date of birth",
    "teams.profile.teamRoster.activeRoster.add.searchBranch"    : "Search in branch",
    "teams.profile.teamRoster.activeRoster.alreadyInTeam"       : "Already in this team.",

    "teams.profile.teamRoster.activeRoster.add.notRostered"   : "Hide members that already are in this team",
    "teams.profile.teamRoster.activeRoster.add.alreadyInTeam" : "Already in another team",
    "teams.profile.teamRoster.activeRoster.add.onlyRostered"  : "Rostered players only",
    "teams.profile.teamRoster.activeRoster.add.selected"      : "Selected member(s):",

    "teams.profile.teamRoster.activeRoster.add.applyAll" : "Apply all prefered positions",

    "teams.profile.teamRoster.activeRoster.add.regular"            : "Regular",
    "teams.profile.teamRoster.activeRoster.add.tryout"             : "Tryout",
    "teams.profile.teamRoster.activeRoster.add.import"             : "Imported",
    "teams.profile.teamRoster.activeRoster.add.primaryTeam"        : "Set as primary team",
    "teams.profile.teamRoster.activeRoster.add.alreadyPrimaryTeam" : "Already set as primary team",

    "teams.profile.teamRoster.activeRoster.add.selectTransfer"  : "Transfer type",
    "teams.profile.teamRoster.activeRoster.add.shareExpiration" : "Share Expiration",
    "teams.profile.teamRoster.activeRoster.add.select.transfer" : "Transfer",
    "teams.profile.teamRoster.activeRoster.add.select.share"    : "Share",

    "teams.profile.teamRoster.activeRoster.add.searchName.validation"   : "Please specifify a first name, last name or a {unique_identifier}.",
    "teams.profile.teamRoster.activeRoster.add.validation.position"     : "Please specify a position.",
    "teams.profile.teamRoster.activeRoster.add.validation.category"     : "Please specify a category.",
    "teams.profile.teamRoster.activeRoster.add.validation.type"         : "Please specify a type.",
    "teams.profile.teamRoster.activeRoster.add.validation.period"       : "Please specify a season.",
    "teams.profile.teamRoster.activeRoster.add.validation.targetTeam"   : "Please specify a team.",
    "teams.profile.teamRoster.activeRoster.add.validation.dateFrom"     : "Please specify a valid date.",
    "teams.profile.teamRoster.activeRoster.add.validation.transferType" : "Please specify a transfer type.",

    "teams.profile.teamRoster.activeRoster.add.confirmation.players" : "Players have been successfully added",
    "teams.profile.teamRoster.activeRoster.add.confirmation.staff"   : "Bench staff members have been successfully added",
    "teams.profile.teamRoster.activeRoster.add.confirmation.button"  : "Got it!",

    "teams.profile.teamRoster.activeRoster.add.toast.title" : "Members have been added!",
    "teams.profile.teamRoster.activeRoster.add.toast.desc"  : "Branch member processing will now start.",
}