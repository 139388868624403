import { Form, Formik } from "formik";
import { Button, Label } from "reactstrap";
import { object, string } from "yup";
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../../../../helpers/i18nHelper";
import { Fragment, useContext } from "react";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import FormikTiny from "../../../../../../components/formik/FormikTiny";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import { MessagesContext } from "../../../../../../contexts/MessagesContext";
import { DEMOGRAPHIC_SETTING_NAME } from "./MessagesSettingsDemographic";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import FormikApiError from "../../../../../../components/formik/FormikApiError";
import { useIntl } from "react-intl";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { InlineConfirmation } from "../../../../../../components/InlineConfirmation";

const DemographicMsgForm = ({ message, stopEditing, onSuccess, onDelete }) => {
    const msgContext = useContext(MessagesContext);
    const orgContext = useContext(OrganizationContext);
    const i18nHelper = useContext(I18nHelperContext);
    const { getValidationSchema, getInitialValues, getAPIValues } = useContext(I18nHelperContext);
    const { formatMessage } = useIntl();

    return (
        <Formik
            initialStatus={{
                showError: false,
                error: null,
            }}
            initialValues={getInitialValues(message)}
            validationSchema={object().shape(getValidationSchema({ body: string() }))}
            onSubmit={(values, { setStatus }) => {
                const apiValues = getAPIValues(values);

                const updateOrCreate = () => {
                    if(message?.message_id){
                        return msgContext.partialUpdateMessage(message.message_id, apiValues).then(() => message.message_id);
                    }

                    return msgContext.createSettingMessage({
                        ...apiValues,
                        active: 1,
                        title: DEMOGRAPHIC_SETTING_NAME,
                    })
                        .then((msgId) => {
                            return orgContext.updateSettings(orgContext.organisation_id, { [DEMOGRAPHIC_SETTING_NAME]: msgId })
                                .then(() => msgId)
                                .catch(async(e) => {
                                    await msgContext.deleteSettingMessage(msgId);
                                    throw e;
                                })
                        })
                }

                return updateOrCreate()
                    .then((msgId) => {
                        onSuccess(msgId);
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            const error = <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message || formatMessage({ id: "misc.error" })} />;
                            setStatus({ showError: true, error });
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <RenderI18nForm field='body'>
                            {({ fieldName, lang, index }) => (
                                <Fragment key={lang + fieldName}>
                                    {i18nHelper.state.languages.length > 1 && <Label for={fieldName} className={stringBuilder({ "mt-3": index })}><Translate id={`misc.${lang}.long`} /></Label>}
                                    <FormikTiny
                                        id={fieldName}
                                        name={fieldName}
                                        init={{
                                            height: 300,
                                        }}
                                    />
                                </Fragment>
                            )}
                        </RenderI18nForm>
                        <FormikApiError />
                        <footer className="mt-3 d-flex align-items-center">
                            {onDelete && message?.message_id &&
                                <InlineConfirmation
                                    disabled={formik.isSubmitting}
                                    type="button"
                                    color="danger"
                                    confirmationTooltipTitle="organization.settings.registrationSettings.demographic.setting.delete"
                                    onClick={() => {
                                        formik.setSubmitting(true);
                                        onDelete(message?.message_id)
                                            .catch((e) => {
                                                if(!AxiosIsCancelled(e.message)){
                                                    console.error(e);
                                                    const error = <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message || formatMessage({ id: "misc.error" })} />;
                                                    formik.setStatus({ showError: true, error });
                                                }
                                            })
                                    }}
                                >
                                    <Translate id="misc.delete" />
                                </InlineConfirmation>
                            }
                            <Button disabled={formik.isSubmitting} type="submit" color="primary" className="ml-auto mr-2"><Translate id="misc.save" /></Button>
                            <Button disabled={formik.isSubmitting} type="button" outline color="primary" onClick={stopEditing}><Translate id="misc.cancel" /></Button>
                        </footer>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default DemographicMsgForm;