import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Button, Card } from "reactstrap";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import SpordlePanelControl from "../../../components/sidePanel/SpordlepanelControl";
import TablePagination from "../../../components/table/TablePagination";
import { I18nContext } from "../../../contexts/I18nContext";
import FiltersCard from "./FiltersCard";
import { PeriodsContext } from "../../../contexts/contexts";
import MemberTypeRemovalSidepanel from "./sidePanel/MemberTypeRemovalSidepanel";
import DisplayOrg from "../../../components/DisplayOrg";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import DisplayRequestChangeStatus from "../../../components/display/DisplayRequestChangeStatus";
import { FormattedDate } from "react-intl";
import { useDisclosure } from "@mantine/hooks";
import DisplayMember from "../../../components/display/DisplayMember";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import GenderLabel from "../../../components/GenderLabel";

const MemberTypeRemoval = (props) => {
    const i18nContext = useContext(I18nContext);
    const periodsContext = useContext(PeriodsContext);

    // FiltersCard will get these and set them so we can send them to the sidepanel, and then to the modal to send to the API
    const [ requestChangesStatuses, setRequestChangeStatuses ] = useState(null)

    const [ isOpen, { toggle } ] = useDisclosure(false);

    return (
        <SpordlePanelTable
            allowOutsideClick
            sidePanel={(props) => <MemberTypeRemovalSidepanel {...props} modalIsOpen={isOpen} modalToggle={toggle} requestChangesStatuses={requestChangesStatuses} />}
            dataIndex='member_type_removal_request_id'
            table={(panelProps) => {
                return (
                    <SpordleTableProvider
                        id='memberTypeRemoval'
                        tableHover bordered striped
                        clickable
                        ref={panelProps.spordleTableRef}
                        desktopWhen
                        dataIndex='member_type_removal_request_id'
                        pagination={25}
                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                        defaultSorting='+created_at'
                        onColumnClick={(e, data, _spordleTable, columnKey) => {
                            switch (e.button){
                                case 0: // Left mouse button
                                    if(columnKey === 'checkbox'){
                                        panelProps.onMultiSelectChange(data);
                                    }else{
                                        panelProps.onSingleSelectChange(data);
                                    }
                                    break;
                            }
                        }}
                        searchKeys={[
                            'unique_identifier',
                            'first_name',
                            'last_name',
                            `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                            `organisation.organisation_name`,
                            'created_at',
                            `review_status`,
                        ]}
                        initFilter={{
                            form: {
                                requestChangesStatusId: '',
                            },
                        }}
                        loadData={(from, { filters }, spordleTable) => {
                            switch (from){
                                case 'FILTER':
                                case 'REFRESH':
                                    spordleTable.setLoading();
                                    const extraFilters = {}
                                    if(filters.form.requestChangesStatusId)
                                        extraFilters.request_change_status_id = filters.form.requestChangesStatusId

                                    return props.getData({
                                        ...extraFilters,
                                        period_id: periodsContext.selectedPeriod.period_id,
                                    });
                                default:
                                    break;
                            }
                        }}
                        columns={[
                            {
                                // yes keep this
                                label: '-',
                                key: 'checkbox',
                                className: 'th-shrink',
                                dataClassName: 'td-shrink',
                            },
                            {
                                label: <Translate id='tasks.memberTypeRemoval.columns.createdAt' />,
                                key: 'created_at',
                                sortable: true,
                                sortKey: 'created_at',
                            },
                            {
                                label: <Translate id='tasks.memberTypeRemoval.columns.member' />,
                                key: 'member',
                                sortable: true,
                                sortKey: 'last_name',
                            },
                            {
                                label: <Translate id='tasks.memberTypeRemoval.columns.createdBy' />,
                                key: 'created_by',
                                sortKey: `created_by.name`,
                                sortable: true,
                            },
                            {
                                label: <Translate id='tasks.memberTypeRemoval.columns.memberType' />,
                                key: 'member_type',
                                sortKey: `member_type.i18n.${i18nContext.getGenericLocale()}.name`,
                                fallbackSortKey: `member_type.name`,
                                sortable: true,
                            },
                            {
                                label: <Translate id='tasks.memberTypeRemoval.columns.status' />,
                                key: 'request_change_status',
                                sortable: true,
                                sortKey: `request_change_status.i18n.${i18nContext.getGenericLocale()}.name`,
                                fallbackSortKey: `request_change_status.name`,
                                className: "td-shrink",
                                dataClassName: 'text-center td-shrink',
                            },
                        ]}
                        renderRow={(columnKey, row) => {
                            switch (columnKey){
                                case 'checkbox':
                                    return (
                                        <label className='my-auto' htmlFor={'attachment-checked-' + row.member_type_removal_request_id} onClick={(e) => { e.stopPropagation() }}>
                                            <input
                                                id={'attachment-checked-' + row.member_type_removal_request_id} type="checkbox" checked={!!row.checked}
                                                onClick={() => { panelProps.onMultiSelectChange(row); }}
                                                onChange={function(){}}// This needs to be here to remove the console error
                                            />
                                            <span className="table-checkbox" />
                                        </label>
                                    )
                                case 'created_at':
                                    return (
                                        <FormattedDate value={row.created_at} />
                                    )
                                case 'member':
                                    return (
                                        <DisplayMember
                                            member={row.member}
                                            extra={{
                                                infoBottom: (
                                                    <UserDisplay.Subtitle>
                                                        <div>
                                                            <GenderLabel gender={row.member.gender} />
                                                        </div>
                                                        <FormattedDate value={row.member.birthdate} />
                                                    </UserDisplay.Subtitle>
                                                ),
                                            }}
                                        />
                                    )
                                case 'created_by':
                                    return (
                                        <>
                                            <div className="font-medium">{row.created_by.name} {row.created_by.family_name}</div>
                                            <small className="text-muted"><DisplayOrg org={row.organisation} /></small>
                                        </>
                                    )
                                case 'member_type':
                                    return (
                                        <DisplayI18n field='name' defaultValue={row.member_type.name} i18n={row.member_type.i18n} />
                                    )
                                case 'request_change_status':
                                    return <DisplayRequestChangeStatus status={row.request_change_status} />
                                default:
                                    break;
                            }
                        }}
                    >
                        {(spordleTable) => (
                            <>
                                <FiltersCard setRequestChangeStatuses={setRequestChangeStatuses} />
                                <Card body className="card-shadow">
                                    <div className="mb-2">
                                        <div className='d-flex flex-wrap justify-content-between mb-2'>
                                            <SpordlePanelControl />
                                            <SearchInput />
                                            <div className='d-flex ml-auto'>
                                                {spordleTable.getData().length > 0 && !spordleTable.getData().some((row) => row.request_change_status.system !== 'PENDING') &&
                                                    <Button
                                                        color='primary'
                                                        onClick={async() => {
                                                            await panelProps.checkAllPages()
                                                            toggle()
                                                        }}
                                                    >
                                                        <Translate id='task.mandatoryChangesRequests.manage.all' />
                                                    </Button>
                                                }
                                                <TablePagination className='ml-2' paginationArray={[ 25, 50, 100 ]} />
                                                <Refresh className='ml-2' />
                                            </div>
                                        </div>
                                    </div>
                                    <SpordleTableView />
                                </Card>
                            </>
                        )}
                    </SpordleTableProvider>
                )
            }}
        />
    );
};


export default MemberTypeRemoval;
