import { FormikInputText } from "@spordle/formik-elements";
import { Form, Formik } from "formik";
import { Button, Col, Row, FormGroup, Collapse } from "reactstrap";
import { object, string } from "yup";

// Helpers
import Translate from "@spordle/intl-elements";

// Contexts
import { useSpordleTable } from "@spordle/datatables";

const UsersSettingsSearch = () => {
    const { filterChange, state } = useSpordleTable();

    const initVal = {
        first_name: '',
        last_name: '',
        email: '',
        validationField: '',
    }

    return (
        <Formik
            initialValues={initVal}
            validationSchema={object().shape({
                email: string().email(<Translate id="form.validation.email.valid" />),
            })}
            onSubmit={async(values, { setStatus }) => {
                if(!values.first_name && !values.last_name && !values.email){
                    setStatus(<Translate id="form.validation.atLeastOneField" />);
                    return false;
                }
                setStatus()

                const filterVal = {
                    first_name: [ values.first_name ],
                    last_name: [ values.last_name ],
                    email: [ values.email ],
                };

                filterChange('search', filterVal, true);
            }}
        >
            {(formik) => (
                <Form>
                    <Row form>
                        <Col md="3" lg="3">
                            <FormGroup>
                                <FormikInputText trim label="form.fields.firstName" name="first_name" id="first_name" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md="3" lg="3">
                            <FormGroup>
                                <FormikInputText trim label="form.fields.lastName" name="last_name" id="last_name" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md="3" lg="3">
                            <FormGroup>
                                <FormikInputText trim label="form.fields.email" name="email" id="email" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md="3" lg="3" className="align-self-end">
                            <FormGroup>
                                <Button
                                    block
                                    type="submit"
                                    color='primary'
                                    className='text-nowrap'
                                    disabled={state.loadingState === 'loading' || state.loadingState === 'lazy'}
                                >
                                    <i className='fas fa-search mr-1' /><Translate id='misc.search' />
                                </Button>
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            {/* This is only used to make sure that the user fills up at least 1 field */}
                            <Collapse isOpen={!!formik.status}>
                                <div className='text-danger small'>{formik.status}</div>
                            </Collapse>
                        </Col>

                    </Row>
                </Form>
            )}
        </Formik>
    )
}

export default UsersSettingsSearch;