import { Badge } from "reactstrap";
import { DisplayI18n } from "../../helpers/i18nHelper";

const DisplayRequestChangeStatus = ({ status, ...props }) => {
    if(!status){
        return null;
    }

    const getColor = (code) => {
        switch (code){
            case 'PENDING':
                return 'warning';
            case 'APPROVED':
                return 'success';
            case 'DECLINED':
            case 'CANCELLED':
                return 'danger';
            default:
                return 'light';
        }
    }

    return (
        <Badge {...props} color={getColor(status.system)}>
            <DisplayI18n
                field="name"
                i18n={status.i18n}
                defaultValue={status.name}
            />
        </Badge>
    );
}

export default DisplayRequestChangeStatus;