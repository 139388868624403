export default{
    "components.transaction.editInstallmentsModal.header"                   : "Modifier les versements",
    "components.transaction.editInstallmentsModal.balance"                  : "Balance",
    "components.transaction.editInstallmentsModal.currentTotal"             : "Total actuel",
    "components.transaction.editInstallmentsModal.invoiceTotal"             : "Total de la facture",
    "components.transaction.editInstallmentsModal.date.label"               : "Date de paiement",
    "components.transaction.editInstallmentsModal.date.validation.after"    : "The date must be after today",
    "components.transaction.editInstallmentsModal.paymentMethod.label"      : "Méthode de paiement",
    "components.transaction.editInstallmentsModal.paymentMethod.required"   : "La méthode de paiement est requise",
    "components.transaction.editInstallmentsModal.total.wrong"              : "Le total des paiements devraient correspondre à {price, number, ::currency/CAD}",
    "components.transaction.editInstallmentsModal.warning.title"            : "Changements irréversibles",
    "components.transaction.editInstallmentsModal.warning.subtitle1"        : "Tous les paiements à <b>0$</b> seront changés pour une <b>méthode de paiment manuelle</b>.",
    "components.transaction.editInstallmentsModal.warning.subtitle2"        : "Chaque paiement <b>annulé</b> modifié sera forcé à une <b>méthode de paiment manuelle</b> et un statut <b>en attente</b>.",
    "components.transaction.editInstallmentsModal.warning.subtitle3"        : "Toutes les méthodes de paiement changées qui étaient à l'origine <b>carte de crédit</b> ne pourront pas être remis à carte de crédit plus tard.",
    "components.transaction.editInstallmentsModal.warning.subtitle.confirm" : "Veuillez confirmer pour procéder.",
    "components.transaction.retryInstallmentBadge.tooltip"                  : "Réessayer le paiement",
}