export default{
    "components.validateMember.PLAYER" : "Player",
    "components.validateMember.COACH"  : "Coach",
    "components.validateMember.STAFF"  : "Staff",

    "components.validateMember.title"                     : "Validate a member",
    "components.validateMember.uniqueIdentifier"          : "{unique_identifier}",
    "components.validateMember.uniqueIdentifier.required" : "{unique_identifier} is required",
    "components.validateMember.team"                      : "Team",
    "components.validateMember.team.required"             : "Team is required",
    "components.validateMember.memberType"                : "Validate as",
    "components.validateMember.memberType.required"       : "Member type is required",
    "components.validateMember.validate"                  : "Validate",
    "components.validateMember.valid"                     : "This member is valid for the team!",
    "components.validateMember.affiliate"                 : "Affiliated",

    // for the team add roster modal
    "components.validateMember.emptyLayout" : "You can use the search at the top or go to the {validate} section and see why a member doesn't show here.",
    "components.validateMember.addMember"   : "Add member",

    // for the member part
    "components.validateMember.member.action" : "Validate member",
}