import API_SPORDLE from "../API-Spordle";
import queryString from "query-string";
import { serverError } from "../CancellableAPI";
import { jsObjectToApi } from "@spordle/helpers";

export function getPotentialMemberDuplicates(queryParams) {
    return API_SPORDLE.get(queryString.stringifyUrl({
        url: `/member-potential-duplicates`,
        query: queryParams
    }))
        .then((response) => {
            if (response.data.status) {
                // Lite Format
                const memberPotentialDuplicates = response.data.member_potential_duplicates || [];
                const members = response.data.members || {};
                const organisations = response.data.organisations || {};
                const identities = response.data.identities || {};
                const member_statuses = response.data.member_statuses || {};

                // Build resources
                return memberPotentialDuplicates.map((potentialDuplicates: Record<string, any>) => {
                    if(members[potentialDuplicates.member_id]) {
                        potentialDuplicates.member = members[potentialDuplicates.member_id];
                        potentialDuplicates.member.member_id = potentialDuplicates.member_id;

                        if(member_statuses[potentialDuplicates.member.member_status_id]) {
                            potentialDuplicates.member.member_status = member_statuses[potentialDuplicates.member.member_status_id];
                            potentialDuplicates.member.member_status.member_status_id = potentialDuplicates.member.member_status_id;
                        }
                    }

                    if(organisations[potentialDuplicates.member_organisation_id]) {
                        potentialDuplicates.organisation = organisations[potentialDuplicates.member_organisation_id];
                        potentialDuplicates.organisation.organisation_id = potentialDuplicates.member_organisation_id;
                    }

                    (potentialDuplicates.duplicate_members||[]).forEach((duplicate: Record<string, any>) => {
                        if(members[duplicate.duplicate_member_id]) {
                            duplicate.member = members[duplicate.duplicate_member_id];
                            duplicate.member.member_id = duplicate.duplicate_member_id;

                            if(member_statuses[duplicate.member.member_status_id]) {
                                duplicate.member.member_status = member_statuses[duplicate.member.member_status_id];
                                duplicate.member.member_status.member_status_id = duplicate.member.member_status_id;
                            }
                        }

                        if(organisations[duplicate.duplicate_member_organisation_id]) {
                            duplicate.organisation = organisations[duplicate.duplicate_member_organisation_id];
                            duplicate.organisation.organisation_id = duplicate.duplicate_member_organisation_id;
                        }

                        duplicate.merged_by = identities[duplicate.merged_by];
                        duplicate.released_by = identities[duplicate.released_by];

                        // Cleanup resource IDs
                        delete duplicate.duplicate_member_id;
                        delete duplicate.member?.member_status_id;
                        delete duplicate.duplicate_member_organisation_id;
                    });

                    delete potentialDuplicates.member_organisation_id;
                    // delete potentialDuplicates.member_id; // Not deleted because the ressource does not have any uuid

                    return potentialDuplicates;
                })
            }
            throw response.data.errors;
        }, serverError)
}

export function releaseMemberPotentialDuplicates(values: { released_at?: string, note?: string, member_to_keep_id: string, member_to_release_id: string }) {
    const params = new URLSearchParams();
    jsObjectToApi(values, params);

    return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/member-potential-duplicates` }), params)
        .then((response) => {
            if (response.data.status) {
                return true
            }
            throw response.data.errors;
        }, serverError)
}