import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { useContext } from "react";
import { DisplayI18n, displayI18n } from "../../../../../../helpers/i18nHelper";
import { Skeleton } from "@mantine/core";
import { getSettingVal, useTeamConfigPosGroupsSWR } from "../teamSettingsConfigurationHelper";
import Translate from "@spordle/intl-elements";
import SettingLabel from "../../../../components/SettingLabel";
import { I18nContext } from "../../../../../../contexts/I18nContext";

const ConfigurationSettingsViewPosGroup = () => {
    const { settings } = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);
    const countPerPosGroup = settings.count_per_position_group;
    const { data, isValidating } = useTeamConfigPosGroupsSWR();
    const posGroups = (data || []).reduce((formatted, posGroup) => {
        formatted[posGroup.position_group_id] = posGroup;
        return formatted;
    }, {});

    const values = getSettingVal(countPerPosGroup?.value)
        .sort(([ idA, idB ]) => {
            const a = posGroups[idA];
            const b = posGroups[idB];

            const nameA = displayI18n("name", a?.i18n, a?.name, getGenericLocale()) || "";
            const nameB = displayI18n("name", b?.i18n, b?.name, getGenericLocale()) || "";

            return nameA.localeCompare(nameB);
        });

    const isLoading = !data && isValidating;

    if(!countPerPosGroup){
        return null;
    }

    return (
        <div className="mb-3">
            <SettingLabel setting={countPerPosGroup} code="count_per_position_group" />
            {isLoading ?
                <ul className="mt-1 pl-3 mb-0">
                    <li className="line-height-1"><Skeleton height="1em" width="15ch" /></li>
                </ul>
                :
                (values || []).length === 0 ?
                    <div className="mt-1 text-muted">
                        <Translate id="settings.teamsSettings.configuration.noLimit" />
                    </div>
                    :
                    posGroups && Array.isArray(values) &&
                        <ul className="mt-1 pl-3 text-dark mb-0">
                            {values.map(([ posGroupId, count ]) => {
                                const group = posGroups[posGroupId];

                                if(!group){ // No need to show position groups that aren't used in the org.
                                    return null;
                                }

                                return (
                                    <li key={posGroupId}>
                                        <span className="mr-1">
                                            <DisplayI18n field="name" i18n={group.i18n} defaultValue={group.name} />:
                                        </span>
                                        <b>{count}</b>
                                    </li>
                                )
                            })}
                        </ul>
            }
        </div>
    );
}

export default ConfigurationSettingsViewPosGroup;