import { FormikError } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { Button, Col, ModalBody, ModalFooter, Row } from "reactstrap";
import { object, string } from "yup";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import dashboardData from "../../dashboardData"

const WidgetList = ({ toggle, goToPrevious, dashboardWidgets, addWidget, selectedDataTypeCode, goToConfigure, setSelectedWidgetId }) => {

    return (
        <Formik
            initialValues={{
                selectedWidgetId: '',
            }}
            validationSchema={object().shape({
                selectedWidgetId: string().required(<Translate id='dashboard.addModal.widget.required' />),
            })}
            onSubmit={(values) => {
                const newWidget = dashboardData.availableWidgets.find((w) => w.widgetId === values.selectedWidgetId);
                addWidget(newWidget)
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <Row form className="flex-grow-1">
                            {dashboardData.availableWidgets.reduce((newArray, widget) => {
                                if(widget.dataType.code === selectedDataTypeCode){
                                    const isSelected = formik.values.selectedWidgetId === widget.widgetId;
                                    const isDisabled = !!dashboardWidgets.find((w) => widget.widgetId === w.widgetId)

                                    newArray.push(
                                        <Col sm='12' md='12' lg='6' key={widget.widgetId} className='mb-2'>
                                            <UserDisplay
                                                card hover={!isDisabled} block
                                                className={stringBuilder('mb-0 h-100', { 'border-primary': isSelected, 'bg-light': isDisabled })}
                                                onClick={() => {
                                                    if(!isDisabled){
                                                        formik.setFieldValue('selectedWidgetId', widget.widgetId);
                                                        setSelectedWidgetId(widget.widgetId)
                                                    }
                                                }}
                                            >
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title>
                                                        <DisplayI18n field='label' defaultValue={widget.label} i18n={widget.i18n} />
                                                    </UserDisplay.Title>
                                                    <UserDisplay.Subtitle>
                                                        <DisplayI18n field='description' defaultValue={widget.description} i18n={widget.i18n} />
                                                    </UserDisplay.Subtitle>
                                                </UserDisplay.Container>
                                                {!isDisabled &&
                                                    <UserDisplay.Container className='ml-auto'>
                                                        <button
                                                            type='button'
                                                            onClick={() => {
                                                                goToConfigure();
                                                                setSelectedWidgetId(widget.widgetId);
                                                            }}
                                                            className='reset-btn text-link'
                                                        >
                                                            <i className="mdi mdi-settings font-18" />
                                                        </button>
                                                    </UserDisplay.Container>
                                                }
                                            </UserDisplay>
                                        </Col>,
                                    )
                                }
                                return newArray
                            }, [])}
                        </Row>
                        <FormikError name='selectedWidgetId' />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' outline onClick={goToPrevious} type='button'><Translate id='misc.previous' /></Button>
                        <div className="ml-auto">
                            <Button color='primary' type='submit'><Translate id='misc.add' /></Button>
                            <Button color='primary' outline onClick={toggle} type='button' className="ml-2"><Translate id='misc.cancel' /></Button>
                        </div>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

export default WidgetList