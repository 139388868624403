import Translate from "@spordle/intl-elements";
import { MemberStatusButton } from "./MemberHeaderStatusButtons";
import PotentialMemberDuplicatesModal from "../../../../tasks/duplicates/modal/PotentialMemberDuplicatesModal";
import { useDisclosure } from "@mantine/hooks";
import useSWR from "swr";
import { MembersContext } from "../../../../../contexts/MembersContext";
import { useContext, useRef } from "react";
import { getPotentialMemberDuplicates } from "../../../../../api/client/potentialMemberDuplicates";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { useHistory } from "react-router";
import { RolesContext } from "../../../../../contexts/RolesContext";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";

const MemberHeaderPotentialDuplicate = ({ refreshMemberProfile }) => {
    const { currentMember } = useContext(MembersContext);
    const { organisation_id } = useContext(OrganizationContext);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const { data: potentialDuplicates } = useSWR(
        [ 'getPotentialMemberDuplicates', currentMember.member_id, organisation_id ],
        () => getPotentialMemberDuplicates({
            member_id: currentMember.member_id,
            organisation_id,
            status: "PENDING",
        }),
    );
    const successKeptMemberId = useRef(null); // Will contain a member_id when duplicate managment has been successfully completed.
    const history = useHistory();
    const [ isOpen, { open, close } ] = useDisclosure(false);
    const canManage = canDoAction("EDIT", "members", "manage_potential_duplicate_members");

    const handleOnClick = () => {
        open();
    }

    const handleOnClose = () => {
        if(currentMember.member_id === successKeptMemberId.current){
            refreshMemberProfile();
        }else if(successKeptMemberId.current){ // Redirect to kept member
            history.push(`/members/profile/${successKeptMemberId.current}`);
        }

        successKeptMemberId.current = null;
        close();
    }

    const handleOnSuccess = (keptMemberId) => {
        successKeptMemberId.current = keptMemberId;
    }

    const readOnly = !potentialDuplicates || potentialDuplicates.length === 0 || (!canManage && !isGod());

    return (
        <>
            <MemberStatusButton disabled={readOnly} onClick={handleOnClick}>
                <i className="text-danger mdi mdi-account-multiple" />
                <span className="align-middle ml-2"><Translate id="tasks.memberDuplicate" /></span>
            </MemberStatusButton>
            <PotentialMemberDuplicatesModal
                isOpen={isOpen}
                toggle={handleOnClose}
                onSuccess={handleOnSuccess}
                potentialDuplicates={potentialDuplicates}
            />
        </>
    );
}

export default MemberHeaderPotentialDuplicate;