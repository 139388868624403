import Translate, { DateFormat } from '@spordle/intl-elements';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import HasAccessTo from '../../../permissions/HasAccessTo';
import UserDisplay from '../../../userDisplay/UserDisplay';
import UserImg from '../../../UserImg';
import TransferMemberAddress from '../TransferMemberAddress';
import QuickViewButton from '../../../quickView/QuickViewButton';
import InlineCopy from '../../../inlineCopy/InlineCopy';
import GenderLabel from '../../../GenderLabel';

const TasksHeader = ({ transfer }) => {
    return (
        <div className='mb-3'>
            <UserDisplay>
                <UserDisplay.Container>
                    <UserImg
                        abbr={transfer.member.first_name[0] + transfer.member.last_name[0]}
                        src={null}
                        width={50}
                        height={50}
                        alt={`${transfer.member.first_name} ${transfer.member.last_name}`}
                    />
                </UserDisplay.Container>
                <UserDisplay.Container>
                    <UserDisplay.Title>
                        <QuickViewButton member={transfer.member}>
                            {transfer.member.first_name} {transfer.member.last_name}
                        </QuickViewButton>
                    </UserDisplay.Title>
                    <div className="font-medium">
                        <InlineCopy toCopy={transfer.member.unique_identifier}>
                            <Link className="text-nowrap" to={`/members/profile/${transfer.member.member_id}`}>
                                {transfer.member.unique_identifier ?
                                    <>#{transfer.member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                    : '-'}
                            </Link>
                        </InlineCopy>
                    </div>
                    <UserDisplay.Subtitle>
                        <GenderLabel gender={transfer.member.gender} />
                        <span className='mx-1'>-</span>
                        <DateFormat value={transfer.member.birthdate} utc /> (<Translate id='misc.yearsOld' values={{ age: transfer.member.age }} />)
                    </UserDisplay.Subtitle>
                    <HasAccessTo componentCode='members' componentPermissionCode='members_address'>
                        <TransferMemberAddress member={transfer.member} />
                    </HasAccessTo>
                </UserDisplay.Container>
            </UserDisplay>
        </div>
    );
};

export default memo(TasksHeader, (prevProps, nextProps) => prevProps.transfer.member.member_id === nextProps.transfer.member.member_id);