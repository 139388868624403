import Translate from "@spordle/intl-elements"
import { ModalBody, ModalHeader } from "reactstrap"
import AnalyticsModal from "../../analytics/AnalyticsModal"
import ValidateMemberInner from "./ValidateMemberInner"

const ValidateMemberModal = ({ isOpen, toggle, member, team }) => {

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='ValidateMemberModal'>
            <ModalHeader toggle={toggle}>
                <Translate id='components.validateMember.title' />
            </ModalHeader>
            <ModalBody>
                <ValidateMemberInner member={member} team={team} />
            </ModalBody>
        </AnalyticsModal>
    )
}

export default ValidateMemberModal