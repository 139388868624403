import { FormikSwitch } from "@spordle/formik-elements"
import Translate from "@spordle/intl-elements"
import { Form, Formik } from "formik"
import { Collapse } from "reactstrap"
import BtnState from "../../../../../components/input/BtnState"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import NotificationsSettingsDataManagerProvider, { NotificationsSettingsDataManager } from "../../../header/notifications/components/NotificationsSettingsDataManager"

const MobileNotificationsSettings = ({ ...props }) => {

    return (
        <NotificationsSettingsDataManagerProvider>
            <NotificationsSettingsDataManager.Consumer>
                {(helper) => (
                    <Formik
                        enableReinitialize
                        initialValues={{
                            subscriptionsGroups: helper.initFormik,
                        }}
                        onSubmit={({ subscriptionsGroups }, { setSubmitting, setStatus }) => {
                            helper.onSubmit(subscriptionsGroups, setSubmitting, setStatus)
                        }}
                    >
                        {(formik) => (
                            <Form className="d-flex flex-column mt-n2" style={{ "minHeight": 0 }}>
                                <div className="mb-3">
                                    <div className="h5 mb-1 font-medium">
                                        <Translate id='header.notifications.settings.title' />
                                    </div>
                                    <small>
                                        <Translate id='header.notifications.settings.subtitle' />
                                    </small>
                                </div>
                                <div className="bg-light-inverse rounded-lg flex-grow-1" style={{ 'overflowY': 'scroll' }}>
                                    <ul className="list-unstyled mb-3 p-2">
                                        {formik.values.subscriptionsGroups?.map((group, groupI) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <li key={group.component.component_id + groupI} className="pr-2">
                                                <div className="py-2 font-bold">
                                                    <DisplayI18n
                                                        field="title"
                                                        defaultValue={group.component.title}
                                                        i18n={group.component.i18n}
                                                    />
                                                </div>
                                                <ul className="list-unstyled px-0">
                                                    {group.subscriptions.map((sub, subI) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <li key={sub.notification_type_id + groupI + subI} className="mb-2 d-flex align-items-center justify-content-end">
                                                            <span>
                                                                <DisplayI18n
                                                                    field="name"
                                                                    defaultValue={sub.name}
                                                                    i18n={sub.i18n}
                                                                />
                                                            </span>
                                                            <div className="small ml-auto">
                                                                <FormikSwitch
                                                                    disabled={formik.isSubmitting}
                                                                    id={`subscriptionsGroups.${groupI}.subscriptions.${subI}.subscribed`}
                                                                    name={`subscriptionsGroups.${groupI}.subscriptions.${subI}.subscribed`}
                                                                />
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <Collapse isOpen={!!formik.status}>
                                    <small className="text-danger">
                                        <Translate id='header.notifications.settings.error' values={{ b: (chunks) => <b>{chunks}</b> }} />
                                    </small>
                                </Collapse>
                                <div className="d-flex align-items-center justify-content-end mt-3">
                                    <BtnState
                                        disabled={formik.isSubmitting || helper.isValidating}
                                        loadState={helper.updateState}
                                        className="mr-2"
                                        size="sm"
                                        color="primary"
                                        type="submit"
                                    >
                                        <Translate id='header.notifications.settings.btn.save' />
                                    </BtnState>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </NotificationsSettingsDataManager.Consumer>
        </NotificationsSettingsDataManagerProvider>
    )
}

export default MobileNotificationsSettings