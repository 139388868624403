import Translate from "@spordle/intl-elements";
import SpordleSelect from "@spordle/spordle-select";
import { useFormikContext } from "formik";
import { useCallback, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Collapse, Row } from "reactstrap";
import { isVisibleInMenu } from "./RolePermissions";

const ApplyToSelects = ({ permission, moduleComponent }) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const applyToAllSelect = useRef(null);
    const formik = useFormikContext();
    const isDisabled = !formik.values[moduleComponent.component_permission_id];

    const getAvailableActions = useCallback(() => {
        const availableActions = [];
        for(let index = 0; index < permission.component_permissions.length; index++){
            const cp = permission.component_permissions[index];
            if(!isVisibleInMenu(cp)){
                availableActions.pushArray(cp.action.split(','));
            }
        }
        return availableActions.removeDuplicates();
    }, [ permission ]);

    const updateForm = (replace) => {
        const newValues = {};
        const selectedValues = applyToAllSelect.current.state.selectedOptions;
        for(let index = 0; index < permission.component_permissions.length; index++){
            const cp = permission.component_permissions[index];
            if(!isVisibleInMenu(cp)){
                if(replace){ // Replace all current values
                    newValues[cp.component_permission_id] = selectedValues.reduce((formattedActions, action) => {
                        if(cp.action.split(',').indexOf(action) !== -1){ // Can have action
                            const rcpId = cp.role_component_permissions.find((rcp) => rcp.action === action)?.role_component_permission_id;
                            formattedActions.push(`${action}${rcpId ? `---${rcpId}` : ''}`);
                        }
                        return formattedActions;
                    }, []);
                }else{ // Add the ones that are not yet selected
                    newValues[cp.component_permission_id] = [
                        ...formik.values[cp.component_permission_id],
                        ...selectedValues.reduce((formattedActions, action) => {
                            if(cp.action.split(',').indexOf(action) !== -1){ // Can have action
                                const currentValues = formik.values[cp.component_permission_id];
                                const currently = currentValues.find((c) => {
                                    if(typeof c === "string"){
                                        const [ currentAction ] = c.split('---');
                                        return currentAction === action;
                                    }
                                    return c.action === action;
                                });
                                if(!currently){ // Value isn't selected -> add it
                                    const rcpId = cp.role_component_permissions.find((rcp) => rcp.action === action)?.role_component_permission_id;
                                    formattedActions.push(`${action}${rcpId ? `---${rcpId}` : ''}`);
                                }
                            }
                            return formattedActions;
                        }, []),
                    ];
                }
            }
        }
        formik.setValues({
            ...formik.values,
            ...newValues,
        });
    }

    return (getAvailableActions().length > 0 &&
        <Card className="card-shadow mb-0 mt-3">
            <CardBody>
                <div onClick={() => { setIsOpen(!isOpen) }} className="clickable d-flex align-items-center text-dark font-medium">
                    <i className='mdi mdi-content-duplicate mr-1' />
                    <Translate id="organization.settings.roles.modals.select.applyToAll.title" />
                    <i className="mdi mdi-chevron-down ml-auto" />
                </div>
                <Collapse isOpen={isOpen}>
                    <div className="my-3">
                        <SpordleSelect
                            ref={applyToAllSelect}
                            id="applyToAll"
                            clearable multi search={false}
                            disabled={isDisabled}
                            preventSelectedReorder
                            placeholder='organization.settings.roles.modals.select.placeholder'
                            loadingStatus="success"
                            defaultData={getAvailableActions().map((action) => ({
                                value: action,
                                label: 'organization.settings.roles.modals.select.option.' + action,
                                translateLabel: true,
                            }))}
                        />
                    </div>
                    <Row form>
                        <Col>
                            <Button color="light" type="button" block onClick={() => { updateForm(false) }} disabled={isDisabled}>
                                <Translate id="organization.settings.roles.modals.select.applyToAll.add" />
                            </Button>
                        </Col>
                        <Col>
                            <Button color="light" type="button" block onClick={() => { updateForm(true) }} disabled={isDisabled}>
                                <Translate id="organization.settings.roles.modals.select.applyToAll.replace" />
                            </Button>
                        </Col>
                    </Row>
                </Collapse>
            </CardBody>
        </Card>
    )
}

export default ApplyToSelects
