import { FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { Button, Collapse, Label, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import CustomAlert from '../../../../../../../components/CustomAlert';
import Required from '../../../../../../../components/formik/Required';
import { fail } from "@spordle/toasts";
// contexts
import { CartsContext } from '../../../../../../../contexts/CartsContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import withContexts from '../../../../../../../helpers/withContexts';

class ManualRegistrationInstallments extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            installmentsOpen: this.props.memberProfileRegistrationAddContext.viewsInfo.selectedInstallment ? this.props.memberProfileRegistrationAddContext.viewsInfo.selectedInstallment !== 'FULL_PAY' : false,
        }

        this.cartPayments = this.props.memberProfileRegistrationAddContext.viewsInfo.selectedInstallment ? this.props.CartsContext.state.cachedCart.cart_summary?.members_amount?.[0] : null;
        this.selectedInstallmentId = this.props.memberProfileRegistrationAddContext.viewsInfo.selectedInstallment || 'FULL_PAY';
    }

    updateInstallment = (installmentId) => {
        this.props.memberProfileRegistrationAddContext.setModalLoading(true);
        const cartItem = this.props.CartsContext.state.cachedCart.cart_detail.find((row) => row.item_type === 'REGISTRATION');
        new Promise((resolve, reject) => {
            if(installmentId !== 'FULL_PAY' && cartItem){
                this.props.CartsContext.updateCartItemInstallment(this.props.CartsContext.state.cachedCart.shopping_cart_id, cartItem.row_id, installmentId)
                    .then(resolve).catch(reject)
            }else if(installmentId !== this.selectedInstallmentId && cartItem){
                this.props.CartsContext.deleteCartItemInstallment(this.props.CartsContext.state.cachedCart.shopping_cart_id, cartItem.row_id)
                    .then(resolve).catch(reject)
            }else{
                resolve()
            }
        })
            .then(async() => {
                const cart = await this.props.CartsContext.getCart(this.props.CartsContext.state.cachedCart.shopping_cart_id)
                this.cartPayments = cart.cart_summary?.members_amount?.[0]; // [0] because there is only 1 member for a manual registration cart
                this.selectedInstallmentId = installmentId;
                this.props.memberProfileRegistrationAddContext.viewsInfo.selectedInstallment = installmentId
                this.props.memberProfileRegistrationAddContext.setModalLoading(false);
                this.setState(() => ({ installmentsOpen: installmentId !== 'FULL_PAY' }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.props.memberProfileRegistrationAddContext.setModalLoading(false);
                }
            })
    }

    render(){
        return (
            <Formik
                initialValues={{
                    installment: this.props.memberProfileRegistrationAddContext.viewsInfo.selectedInstallment || '',
                }}
                validationSchema={Yup.object().shape({
                    installment: Yup.string().required(<Translate id='memberProfile.registration.installments.required' />),
                })}
                onSubmit={(values, { setStatus }) => {
                    this.props.onSubmit(setStatus)
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalBody>
                            <Label className='text-muted'><Translate id='memberProfile.registration.installments.label' /> <Required /></Label>
                            <FormikSelect
                                search={false}
                                id='installment'
                                name='installment'
                                renderOption={({ option }) => option.value === 'FULL_PAY' ? <Translate id={option.label} /> : <Translate id={option.label} values={option.values} />}
                                onOptionSelected={([ value ]) => this.updateInstallment(value)}
                                loadingStatus='success'
                                defaultData={[
                                    {
                                        value: 'FULL_PAY',
                                        label: 'memberProfile.registration.installments.payFull',
                                        translateLabel: true,
                                    },
                                    {
                                        value: this.props.installments.installment_mode_id,
                                        values: { number: parseInt(this.props.installments.number_of_payment) + 1 },
                                        label: 'memberProfile.registration.installments.installments',
                                        translateLabel: true,
                                    },
                                ]}
                            />

                            <Collapse isOpen={this.state.installmentsOpen}>
                                <div className="overflow-auto max-vh-25 border-top border-bottom mt-3 p-2">
                                    <div className="w-sm-50">
                                        {this.cartPayments?.payments?.map((payment, index) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <div className={`d-flex${index === 0 ? ' font-bold' : ''}`} key={index}>
                                                <div>{index !== 0 ? moment(payment.payment_date).format('YYYY-MM-DD') : <Translate id='memberProfile.registration.installments.payNow' />}</div>
                                                <div className="ml-auto"><CurrencyFormat value={payment.amount / 100} /></div>
                                            </div>
                                        ))}
                                    </div>
                                    <hr />
                                    <div className="font-bold d-flex w-sm-50">
                                        <div><Translate id='memberProfile.registration.installments.total' />: </div>
                                        <div className="ml-auto">
                                            {this.cartPayments?.payments && <CurrencyFormat value={this.cartPayments.total_amount / 100} />}
                                        </div>
                                    </div>
                                </div>
                            </Collapse>

                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                {formik.status &&
                                    <CustomAlert className='mt-3' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                }
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            {!this.props.hidePreviousButton &&
                                <Button color='primary' outline type='button' onClick={() => this.props.goToPrevious()} className="mr-auto"><Translate id='misc.previous' /></Button>
                            }
                            <Button color='primary' type='submit'><Translate id='misc.next' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default withContexts(CartsContext)(ManualRegistrationInstallments)