export default{
    // Error codes
    "contexts.teamsContext.errors.3227" : "One or more of these members is already added to this team.",
    "contexts.teamsContext.errors.3384" : "Team rollover has been disabled.",
    "contexts.teamsContext.errors.3004" : "Team not found.",
    "contexts.teamsContext.errors.3226" : "The position does not belong to the team's federation.",

    "contexts.teamsContext.errors.3060" : "Resource not found. Please contact support.",
    "contexts.teamsContext.errors.3074" : "Member not found. Please contact support.",
    "contexts.teamsContext.errors.3045" : "Incompatible data. Please contact support.",
}