export default{
    "members.profile.crc.tab"                            : "Vérification d'antécédents",
    "members.profile.dreamComeTrue.title"                : "Rêves devenus réalité",
    "members.profile.crc.title"                          : "Vérification d'antécédents",
    "members.profile.incomplete.title"                   : "Profil incomplet",
    "members.profile.potential.ineligible.title"         : "Potentiellement inéligible",
    "members.profile.unconfirmed_address.title"          : "Adresse non-confirmée",
    "members.profile.crc.createdBy"                      : "Créé par",
    "members.profile.crc.memberType"                     : "Type du membre",
    "members.profile.crc.type"                           : "Type",
    "members.profile.crc.type.CRC"                       : "Vérification d'antécédents",
    "members.profile.crc.type.VSC"                       : "Vérification de vulnérabilité",
    "members.profile.crc.type.DECLARATION"               : "Déclaration",
    "members.profile.crc.type.crc"                       : "Vérification d'antécédents",
    "members.profile.crc.type.vsc"                       : "Vérification de vulnérabilité",
    "members.profile.crc.type.declaration"               : "Déclaration",
    "members.profile.crc.validOn"                        : "Date de validité",
    "members.profile.crc.expirationDate"                 : "Date d'expiration",
    "members.profile.crc.cannotAdd"                      : "Les réglages ne permettent pas d'appliquer une vérification d'antécédents à ce membre.",
    "members.profile.crc.expiresAt"                      : "Expire le",
    "members.profile.crc.status.expired"                 : "Expirée",
    "members.profile.crc.on"                             : "Le",
    "members.profile.crc.lastUpdated"                    : "Modifié le",
    "members.profile.crc.supportiveDocument"             : "Document de soutien",
    "members.profile.crc.supportiveDocument.helper.bold" : "SVP ne soumettez pas la vérification d'antécédents original.",
    "members.profile.crc.supportiveDocument.helper"      : "Ce document de soutien est le document qui confirme que la vérification d'antécédents a bien été faite.",

    "members.profile.crc.add.title"                           : "Ajouter une vérification d'antécédents",
    "members.profile.crc.form.validation.type.required"       : "Le type est requis",
    "members.profile.crc.form.validation.memberType.required" : "Le type du membre est requis",
    "members.profile.crc.form.validation.file.required"       : "Le document est requis",
    "members.profile.crc.form.validation.status.required"     : "Le status est requis",
    "members.profile.crc.form.validation.date.required"       : "La date est requise",
    "members.profile.crc.form.expirationDate.alert"           : "La {expirationDate} sera calculée automatiquement par le système en se basant sur les paramètres de votre organisation.",

    "members.profile.crc.sidepanel.delete" : "Vous êtes sur le point de supprimer cette vérification",

    "members.profile.crc.editModal.title"   : "Modifier la vérification d'antécédents",
    "members.profile.crc.editModal.success" : "La vérification d'antécédents a été mise à jour!",
}