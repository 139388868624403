import Translate from "@spordle/intl-elements";
import { useState } from "react"
import { ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import RolloverModalConfirmation from "./RolloverModalConfirmation";
import RolloverModalSelection from "./RolloverModalSelection";
import OverlayWarning from "../../../../../components/overlayWarning/OverlayWarning";

const RolloverModal = ({ isOpen, toggle, currentSeasonFees, divisions, categories, refreshTable }) => {
    const steps = {
        selection: 'selection',
        confirmation: 'confirmation',
    }

    const showWarning = currentSeasonFees?.length > 0;
    const [ activeStep, setActiveStep ] = useState('selection');
    const [ selectedFees, setSelectedFees ] = useState([]);

    return (
        <AnalyticsModal id="rollover-modal" isOpen={isOpen} analyticsName='RegistrationFeesRolloverModal' size='lg' onClosed={() => setActiveStep('selection')}>
            <ModalHeader toggle={toggle}>
                <Translate id='catalog.registrationFees.rollover.title' />
            </ModalHeader>

            <CrossFade isVisible={activeStep === steps.selection}>
                <OverlayWarning
                    showWarning={showWarning}
                    storageKey='registrationFeesRollover'
                    text={(
                        <p><Translate id='catalog.registrationFees.rollover.warning' values={{ amount: currentSeasonFees?.length }} /></p>
                    )}
                >
                    <RolloverModalSelection
                        onSubmit={(fees) => {
                            setSelectedFees(fees);
                            setActiveStep(steps.confirmation);
                        }}
                    />
                </OverlayWarning>
            </CrossFade>
            <CrossFade isVisible={activeStep === steps.confirmation} unmountOnExit>
                <RolloverModalConfirmation
                    selectedFees={selectedFees}
                    goToPrevious={() => setActiveStep(steps.selection)}
                    divisions={divisions}
                    categories={categories}
                    toggle={toggle}
                    refreshTable={refreshTable}
                />
            </CrossFade>
        </AnalyticsModal>
    )
}

export default RolloverModal