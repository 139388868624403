import SpordleTableProvider, { Refresh, SearchInput, SpordleTableContext, SpordleTableView } from '@spordle/datatables';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { useFormikContext } from 'formik';
import { useContext, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    Fade, Table
} from "reactstrap";
import NoImgItem from '../../../../../assets/images/NoImage_Item.svg';
import SearchImage from '../../../../../assets/images/Search_Image.png';
import HoverableComponent from '../../../../../components/HoverableComponent';
import SpordlePanelTable from '../../../../../components/sidePanel/SpordlePanel';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { OnlineStoreContext } from '../../../../../contexts/OnlineStoreContext';
import { ReportsContext } from '../../../../../contexts/ReportsContext';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import OnlineStoreOtherItemsAdd from './components/OnlineStoreOtherItemsAdd';
import OnlineStoreOtherItemsSidepanel from './components/OnlineStoreOtherItemsSidepanel';

/**
this manages the otherItems table + it's sidepanel and Add modal
*/
const OnlineStoreOtherItems = (props) => {
    const [ addModal, setAddModal ] = useState(false);
    const toggleAddModal = () => setAddModal(!addModal);
    const formik = useFormikContext();
    const i18nContext = useContext(I18nContext);
    const reportsContext = useContext(ReportsContext);

    const onlineStoreContext = useContext(OnlineStoreContext);

    const FROM = props.from;
    return (
        <Card className="card-shadow">
            <CardBody>
                <div className="d-flex align-items-end pb-1 border-bottom mb-3">
                    <div className="h4 font-bold card-title mb-0"><Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.title' /></div>
                </div>
                <SpordlePanelTable
                    ref={props.otherItemsRef}
                    allowOutsideClick
                    dataIndex='other_fee_id'
                    sidePanel={(props) => <OnlineStoreOtherItemsSidepanel {...props} saveItems={() => FROM !== 'CREATE' ? formik.handleSubmit() : undefined} />}
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='OnlineStoreOtherItemsTable'
                                tableHover bordered striped
                                ref={panelProps.spordleTableRef}
                                desktopWhen
                                dataIndex='other_fee_id'
                                defaultSorting={'+display_order'}
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                searchKeys={[
                                `fee.i18n.${i18nContext.getGenericLocale()}.name`,
                                `fee.i18n.${i18nContext.getGenericLocale()}.description`,
                                'fee.name',
                                'fee.description',
                                ]}
                                loadData={(from, _, spordleTable) => {
                                    switch (from){
                                        case 'REFRESH':
                                            spordleTable.setLoading(true);
                                        case 'CDM':
                                            return onlineStoreContext.getOnlineStoreOtherFee(props.onlineStoreId)
                                                .then((items) => {
                                                    return reportsContext.getRegistrationCountReport({ online_store_id: props.onlineStoreId, type: 'OTHER' }).then((count) => {

                                                        const mappedItems = items.map((item) => {
                                                            const other_fee = { ...item.other_fee }
                                                            delete other_fee.active
                                                            return {
                                                                ...item,
                                                                ...other_fee,
                                                                items_sold: count.find((sold) => (sold.item_id === item.other_fee.other_fee_id)).item_sold,
                                                            }
                                                        })
                                                        formik.setFieldValue('otherItems', mappedItems);
                                                        return mappedItems;
                                                    })
                                                })
                                        default:
                                            break;
                                    }
                                }}
                                columns={[
                                    {
                                        label: <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.table.display_order' />,
                                        key: 'display_order',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink text-center',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.table.item' />,
                                        key: 'item',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.table.price' />,
                                        key: 'price',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink text-right',
                                    },
                                    {
                                        label: <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.table.items_sold' />,
                                        key: 'items_sold',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink text-right',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.table.status' />,
                                        key: 'active',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                        sortable: true,
                                    },
                                ]}
                                renderRow={(columnKey, item) => {
                                    switch (columnKey){
                                        case 'price':
                                            return (
                                                <div className='font-medium'>
                                                    <CurrencyFormat value={item.fee.amount / 100} />
                                                    {item.fee.tax_class &&
                                                        <sup className='ml-1'>(<Translate id='catalog.registrationFees.add.form.tax' />)</sup>
                                                    }
                                                </div>
                                            )
                                        case 'items_sold':
                                            return (
                                                <div>{item.items_sold || 0}</div>
                                            )
                                        case 'display_order':
                                            return (
                                                <div>{item.display_order || 0}</div>
                                            )
                                        case 'item':
                                            return (
                                                <UserDisplay>
                                                    <UserDisplay.Container>
                                                        <UserImg
                                                            className="rounded-lg border shadow-none"
                                                            width={60}
                                                            src={item.image?.full_path || NoImgItem}
                                                            filePos={item.image?.file_position}
                                                            alt={item.fee.name}
                                                        />
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container className="min-w-0">
                                                        <div className="d-flex align-items-center">
                                                            <div className="font-bold h6 mb-0"><DisplayI18n i18n={item.fee.i18n} field='name' defaultValue={item.fee.name} /></div>
                                                        </div>
                                                        <div className='text-muted'>
                                                            <DisplayI18n i18n={item.fee.i18n} field='description' defaultValue={item.fee.description} />
                                                        </div>
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            )
                                        case 'active':
                                            let myStatus = item.active == 1 ? 'ACTIVE' : 'INACTIVE';
                                            if(item.other_fee.active == 0) // overwrite with inactive if fee is active=0
                                                myStatus = 'INACTIVE';
                                            switch (myStatus){
                                                case 'ACTIVE':
                                                    return <span className="text-success"><Translate id={`onlineStore.components.onlineStoreItemsFees.status.${myStatus}`} /></span>;
                                                case 'INACTIVE':
                                                    return <span className="text-danger"><i className="mdi mdi-alert-outline mr-1" /><Translate id={`onlineStore.components.onlineStoreItemsFees.status.${myStatus}`} /></span>;
                                                default:
                                                    break;
                                            }
                                        default:
                                            break;
                                    }
                                }}
                                onColumnClick={(e, data) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            panelProps.onSingleSelectChange(data);
                                            break;
                                    }
                                }}
                                rowIsHighlighted={(data) => !!data.checked}
                                emptyLayout={
                                    <div className='text-center'>
                                        <div>
                                            <img src={SearchImage} alt="Search icon" height='150px' />
                                        </div>
                                        <div>
                                            <div className="h3"><Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.table.empty' /></div>
                                        </div>
                                    </div>
                                }
                            >
                                <div className='mb-2'>
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <SearchInput />
                                        <div className='d-flex ml-auto text-right nowrap'>
                                            {FROM === "PROFILE" && <Refresh />}
                                            <OnlineStoreOtherItemsAdd
                                                isOpen={addModal}
                                                toggle={() => {
                                                    if(FROM !== "CREATE")
                                                        formik.handleSubmit();
                                                    toggleAddModal();
                                                }}
                                            />
                                            <Button className='ml-2' color='primary' onClick={toggleAddModal}><i className='mdi mdi-plus' /> <Translate id='misc.add' /></Button>
                                        </div>
                                    </div>
                                </div>
                                <SpordleTableContext.Consumer>
                                    {(spordleTable) => (
                                        spordleTable.getData().length === 0 && spordleTable.state.loadingState === 'success' ?
                                            <Fade>
                                                <Table bordered striped hover>
                                                    <thead>
                                                        <tr>
                                                            <HoverableComponent>
                                                                {(isHovering) => <th className={stringBuilder({ 'bg-light': isHovering })}><Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.table.item' /></th>}
                                                            </HoverableComponent>
                                                            <HoverableComponent>
                                                                {(isHovering) => <th className={stringBuilder('th-shrink', { 'bg-light': isHovering })}><Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.table.status' /></th>}
                                                            </HoverableComponent>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={2} className='text-center'>
                                                                <div><Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.table.hint1' /></div>
                                                                <button className='font-medium reset-btn text-primary' type='button' onClick={toggleAddModal}><i className='mdi mdi-plus' /><Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.table.hint2' /></button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Fade>
                                            :
                                            <SpordleTableView />
                                    )}
                                </SpordleTableContext.Consumer>
                            </SpordleTableProvider>
                        )
                    }}
                />
            </CardBody>
        </Card>
    );
}

export default OnlineStoreOtherItems;