export default{
    "task.navigation.unsanctionedClubs" : "Unconfirmed Organizations",

    "task.unconfirmedOrg.list.organization"    : "Organization",
    "task.unconfirmedOrg.list.submission.date" : "Submission Date",

    "task.unconfirmedOrg.sidepanel.manage.approval" : "Manage Approval",
    "task.unconfirmedOrg.sidepanel.submission.date" : "Submission date",

    "task.unconfirmedOrg.modal.title"           : "Manage Organization Approval",
    "task.unconfirmedOrg.modal.multi.collapse"  : "{count} organizations selected",
    "task.unconfirmedOrg.modal.multi.info"      : "All selected organizations will be affected by the change in status.",
    "task.unconfirmedOrg.modal.success"         : "Successfully changed the organizations' status.",
    "task.unconfirmedOrg.modal.note.multi.info" : "This note will be applied on all organizations.",
    "task.unconfirmedOrg.modal.note.required"   : "A note is required to decline organizations.",
}