import { FormikCurrencyInput, FormikDateTime, FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import moment from "moment";
import { useContext } from "react";
import { Col, Collapse, FormGroup, Row } from "reactstrap";
import { mixed, object, string, number } from 'yup';
import FormikEditable from "../../../../../../components/formik/FormikEditable";
import BtnDisplay from "../../../../../../components/input/BtnDisplay";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import HasAccessTo from "../../../../../../components/permissions/HasAccessTo";
import { fail, success } from "@spordle/toasts";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { InjuriesContext } from "../../../../../../contexts/InjuriesContext";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { EVENT_TYPES, formikOtherTest, InjuryStatus, INJURY_TYPES, PARTICIPANT_TYPES, useInjurySelect, YES_NO_INFO_DATA } from "../../InsuranceHelper";
import InfoCheque from "./components/InfoCheque";
import Skeleton from "react-loading-skeleton";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";
import { formatSelectData } from "@spordle/spordle-select";
import { stringBuilder } from "@spordle/helpers";
import InsuranceSidePanelEditTeam from "./components/InsuranceSidePanelEditTeam";

const InsuranceSidePanelAccident = (props) => {
    const {
        canEdit,
        injuryDataLists,
        injury,
        updateInjury,
        toggleChequeModal,
        cheques,
        canReadCheques,
        setCheques,
        getCheques,
        statuses,
    } = props;
    const { environments, environments_w_other, conditions, conditions_w_other, status } = injuryDataLists;
    const { canDoAction } = useContext(RolesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { getOrganizationBySettings } = useContext(OrganizationContext);
    const { federation } = useContext(IdentityRolesContext);
    const getSelectInjuryProps = useInjurySelect();
    const canEditSensitiveData = canDoAction('EDIT', 'members', 'member_insurance_sensitive_data');
    const { deleteInsuranceCheque } = useContext(InjuriesContext);
    const { openStatusId, openStatusesIds, pendingStatusId, closedStatuses } = statuses;

    /**
     * Deletes an injury cheque id. Will throw an error if the cheque has a number
     * @param {string} id injury cheque id
     * @returns {Promise}
     * @throws {Error}
     */
    const deleteCheque = async(id) => {
        return deleteInsuranceCheque(injury.injury_id, id)
            .then(() => {
                setCheques(cheques.filter((c) => c.injury_cheque_id !== id));
                success();
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    const isStatusClosed = (selectedStatusId) => {
        return closedStatuses.includes(selectedStatusId)
    }

    return (
        <>
            <div className="h5 font-bold"><Translate id='insurance.label.claim' /></div>
            <Row form>
                <Col sm="6" className="form-group">
                    <div className="text-muted"><Translate id='insurance.label.office' /></div>
                    <FormikEditable
                        id="edit-office-id"
                        noConfirmation
                        disabled={!canEdit}
                        initialValues={{
                            organisation: injury.organisation || {},
                        }}
                        validationSchema={object().shape({
                            organisation: object().shape({
                                organisation_id: string().required(),
                            }),
                        })}
                        onSubmit={(values) => {
                            if(values.organisation.organisation_id !== injury.organisation?.organisation_id){
                                updateInjury({ organisation_id: values.organisation.organisation_id }, { organisation: values.organisation }, true);
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return (
                                    <div id='office_organisation_id'>
                                        {
                                            injury.organisation ?
                                                <DisplayI18n
                                                    field="name"
                                                    defaultValue={injury.organisation?.organisation_name}
                                                    i18n={injury.organisation?.i18n}
                                                />
                                                :
                                                '-'
                                        }
                                    </div>
                                )
                            }
                            return (
                                <FormikSelect
                                    id="office_organisation_id"
                                    name="organisation.organisation_id"
                                    renderSelectedOption={(opt) => (
                                        <DisplayI18n
                                            field="name"
                                            defaultValue={opt.label}
                                            i18n={opt.i18n}
                                        />
                                    )}
                                    renderOption={(opt) => (
                                        <>
                                            {opt.option.isGroup && <span><Translate id='insurance.label.category' />: </span>}
                                            <span className={stringBuilder({ "font-medium pt-2": opt.option.isGroup })}>
                                                <DisplayI18n
                                                    field="name"
                                                    defaultValue={opt.option.label}
                                                    i18n={opt.option.i18n}
                                                />
                                            </span>
                                            {!opt.option.isGroup && (
                                                <small className="d-block text-muted">
                                                    {opt.option.organisation_parent?.abbreviation}
                                                </small>
                                            )}
                                        </>
                                    )}
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                        `organisation_category.i18n.${getGenericLocale()}.name`,
                                        `organisation_parent.abbreviation`,
                                    ]}
                                    menuIsDefaultOpen
                                    onOptionSelected={async([ value ], select) => {
                                        const org = select.getSpordleTable().getData().find((org) => org.organisation_id === value);
                                        await formik.setFieldValue("organisation", org);
                                        options.stopEditing();
                                        return true;
                                    }}
                                    loadData={(from) => {
                                        switch (from){
                                            case 'CDM':
                                                return getOrganizationBySettings(federation.organisation_id, { setting_code: "office_list_insurance" })
                                                    .then((orgs) => {
                                                        const sortedOrgs = orgs.sort((a, b) => {
                                                            const aOrgName = displayI18n('name', a.i18n, a.organisation_name, getGenericLocale());
                                                            const bOrgName = displayI18n('name', b.i18n, b.organisation_name, getGenericLocale());

                                                            return aOrgName.localeCompare(bOrgName);
                                                        });

                                                        return formatSelectData(
                                                            sortedOrgs,
                                                            {
                                                                getValue: (org) => org.organisation_id,
                                                                getLabel: (org) => org.organisation_name,
                                                                getGroupId: (org) => org.organisation_category.organisation_category_id,
                                                                createGroupWithId: (org) => ({
                                                                    ...org.organisation_category,
                                                                    label: org.name,
                                                                }),
                                                            },
                                                        );
                                                    })
                                            default:
                                                break;
                                        }
                                    }}
                                />
                            )
                        }}
                    </FormikEditable>
                </Col>
                <Col className="mb-3" sm="6" id='insurance-accident-branch'>
                    <div className="text-muted"><Translate id="insurance.label.branch" /></div>
                    <DisplayI18n
                        field="name"
                        i18n={injury.branch.i18n}
                        defaultValue={injury.branch.organisation_name}
                    />
                </Col>
                <Col className="form-group" sm="12">
                    <InsuranceSidePanelEditTeam canEdit={canEdit} {...props} />
                </Col>
                {injury.team_official_name &&
                    <Col className="form-group" sm="12" id='insurance-accident-teamOfficial'>
                        <div className="text-muted"><Translate id='insurance.label.teamOfficial' /></div>
                        {injury.team_official_name} {injury.team_official_position && <small>({injury.team_official_position})</small>}
                    </Col>
                }
                <Col className="form-group" sm="12">
                    <div className="text-muted"><Translate id='insurance.label.type' /></div>
                    <FormikEditable
                        id="edit-injury-type"
                        noConfirmation
                        disabled
                        initialValues={{
                            injury_type: injury.injury_type || '',
                        }}
                        validationSchema={object().shape({
                            injury_type: string().required(),
                        })}
                        onSubmit={(values) => {
                            if(values.injury_type !== injury.injury_type){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return <Translate id={`insurance.label.injuryTypes.${injury.injury_type}`} values={{ name: federation?.organisation_name }} />;
                            }
                            return (
                                <FormikSelect
                                    name="injury_type"
                                    id="insurance-accident-injuryType"
                                    autoFocus
                                    search={false}
                                    menuIsDefaultOpen
                                    onOptionSelected={() => {
                                        options.stopEditing();
                                    }}
                                    defaultData={INJURY_TYPES.map((type) => ({
                                        label: <Translate id={`insurance.label.injuryTypes.${type}`} values={{ name: federation?.organisation_name }} />,
                                        value: type,
                                        translateLabel: false,
                                    }))}
                                    loadingStatus="success"
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
            </Row>
            <HasAccessTo componentCode="members" componentPermissionCode="member_insurance_sensitive_data">
                <div className="mb-3">
                    <Row form>
                        <Col xs="6">
                            <div className="label-permission">
                                <Translate id='insurance.label.claimStatus' />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="label-permission">
                                <Translate id='insurance.label.reserve' />
                            </div>
                        </Col>
                        <Col xs="12">
                            <FormikEditable
                                id="edit-reserve"
                                manualIcon
                                className="position-relative"
                                disabled={!canEdit && !canEditSensitiveData}
                                initialValues={{
                                    reserve: (injury.reserve && !isStatusClosed(injury.reserve)) ? (parseInt(injury.reserve) / 100) : 0,
                                    injury_status_id: injury.injury_status?.injury_status_id || '',
                                }}
                                validationSchema={object().shape({
                                    reserve: number(<Translate id='form.validation.number' />).min(0).test({
                                        name: 'needValWhenOpen',
                                        message: <Translate id='insurance.sidePanel.cheque.addModal.validation.reserve.whenOpen' />,
                                        test: function(val){
                                            return !openStatusesIds.includes(this.parent.injury_status_id) || !!val;
                                        },
                                    }),
                                    injury_status_id: string().required(<Translate id='insurance.validation.status.required' />),
                                })}
                                onSubmit={(values) => {
                                    const calcReserve = Math.round((values.reserve || 0) * 100);
                                    if(calcReserve !== injury.reserve || values.injury_status_id !== injury.injury_status?.injury_status_id){
                                        updateInjury(
                                            {
                                                reserve: calcReserve,
                                                injury_status_id: values.injury_status_id,
                                            },
                                            {
                                                reserve: calcReserve,
                                                injury_status: status.find((s) => s.injury_status_id === values.injury_status_id),
                                            },
                                            true,
                                        );
                                    }
                                }}
                            >
                                {(isEditing, _options, formik) => {
                                    if(!isEditing){
                                        return (
                                            <Row form className="w-100">
                                                <Col xs="6">
                                                    <InjuryStatus injuryStatus={injury.injury_status} id='insurance-accident-injuryStatus' />
                                                </Col>
                                                <Col xs="6">
                                                    <span className="ml-1" id='insurance-accident-injuryReserve'>
                                                        <CurrencyFormat
                                                            value={isStatusClosed(injury.injury_status?.injury_status_id) ? 0 : (parseInt(injury.reserve) / 100)}
                                                        />
                                                    </span>
                                                    <i className="mdi mdi-pencil text-primary ml-2 position-absolute top-0 right-0" />
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    return (
                                        <Row form className="w-100">
                                            <Col xs="6">
                                                <FormikSelect
                                                    {...getSelectInjuryProps("injury_status_id", status)}
                                                    onOptionSelected={async([ value ]) => {
                                                        // Both values are set at the same time before onOptionSelected is called
                                                        // before the select value is set, so there were a visual glitches when a formik error was triggered
                                                        await formik.setFieldValue('injury_status_id', value);

                                                        if(isStatusClosed(value)){
                                                            formik.setFieldValue('reserve', 0);

                                                        }

                                                        return true;
                                                    }}
                                                />
                                            </Col>
                                            <Col xs="6">
                                                <FormikCurrencyInput
                                                    id='insurance-accident-injuryReserve'
                                                    name="reserve"
                                                    allowNegative={false}
                                                    disabled={isStatusClosed(formik.values.injury_status_id)}
                                                    onChange={(e) => {
                                                        const theAmount = parseFloat(e.currentTarget.value.match(/([0-9.])+/, "")?.[0] || 0);

                                                        if(!pendingStatusId || openStatusesIds.length <= 0){
                                                            return;
                                                        }

                                                        if(formik.values.injury_status_id === pendingStatusId && theAmount > 0){
                                                            formik.setFieldValue('injury_status_id', openStatusId);
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col xs="12">
                                                <Collapse isOpen={isStatusClosed(formik.values.injury_status_id)}>
                                                    <small className="text-muted">
                                                        <i className="mdi mdi-information-outline text-primary mr-1" /><Translate id='insurance.label.claimStatus.helper' />
                                                    </small>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    )
                                }}
                            </FormikEditable>
                        </Col>
                        {injury.previous_injury_status &&
                            <Col className="mt-3" sm="6">
                                <div className="label-permission"><Translate id="insurance.label.previousClaimStatus" /></div>
                                <span><InjuryStatus injuryStatus={injury.previous_injury_status} id='insurance-accident-previousInjuryStatus' /></span>
                            </Col>
                        }
                    </Row>
                </div>
            </HasAccessTo>
            <div className="h5 font-bold"><Translate id='insurance.label.accident' /></div>
            <Row form>
                <Col sm="6" className="form-group">
                    <div className="text-muted"><Translate id='insurance.label.participantType' /></div>
                    <FormikEditable
                        id="edit-participant-type"
                        noConfirmation
                        disabled={!canEdit}
                        initialValues={{
                            participant_type: injury.participant_type || '',
                        }}
                        validationSchema={object().shape({
                            participant_type: string().required(<Translate id='insurance.validation.participantType.required' />),
                        })}
                        onSubmit={(values) => {
                            if(values.participant_type !== injury.participant_type){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return injury.participant_type ? <Translate id={`insurance.label.participantType.${injury.participant_type}`} /> : '-';
                            }
                            return (
                                <FormikSelect
                                    name="participant_type"
                                    id="participant_type"
                                    autoFocus
                                    search={false}
                                    menuIsDefaultOpen
                                    onOptionSelected={() => {
                                        options.stopEditing();
                                    }}
                                    defaultData={PARTICIPANT_TYPES.map((type) => ({
                                        label: 'insurance.label.participantType.' + type,
                                        translateLabel: true,
                                        value: type,
                                    }))}
                                    loadingStatus="success"
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="6" className="form-group">
                    <div className="text-muted"><Translate id='insurance.label.guardian' /></div>
                    <FormikEditable
                        id="edit-guardian"
                        disabled={!canEdit}
                        initialValues={{
                            guardian: injury.guardian || '',
                        }}
                        validationSchema={object().shape({
                            guardian: string(),
                        })}
                        onSubmit={(values) => {
                            if(values.guardian !== injury.guardian){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, _options) => {
                            if(!isEditing){
                                return injury.guardian || '-';
                            }
                            return <FormikInputText trim name="guardian" id='insurance-accident-guardian' autoFocus />

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="6" className="form-group">
                    <div className="text-muted"><Translate id='insurance.label.arena' /></div>
                    <FormikEditable
                        id="edit-venue_name"
                        disabled={!canEdit}
                        initialValues={{
                            venue_name: injury.venue_name,
                        }}
                        validationSchema={object().shape({
                            venue_name: string(),
                        })}
                        onSubmit={(values) => {
                            if(values.venue_name !== injury.venue_name){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, _options) => {
                            if(!isEditing){
                                return injury.venue_name || '-';
                            }
                            return <FormikInputText trim name="venue_name" id='insurance-accident-vanueName' autoFocus />

                        }}
                    </FormikEditable>
                </Col>
            </Row>
            <FormGroup>
                <div className="text-muted"><Translate id='insurance.label.eventType' /></div>
                <FormikEditable
                    id="edit-event_type"
                    disabled={!canEdit}
                    initialValues={{
                        event_type: injury.event_type || '',
                        event_type_other: injury.event_type_other || '',
                    }}
                    validationSchema={object().shape({
                        event_type: string().required(<Translate id='insurance.validation.eventType.required' />),
                        event_type_other: string().test(formikOtherTest('event_type')),
                    })}
                    onSubmit={(values) => {
                        if(values.event_type !== injury.event_type || values.event_type_other !== injury.event_type_other){
                            updateInjury(values, values);
                        }
                    }}
                >
                    {(isEditing, _options, formik) => {
                        if(!isEditing){
                            return injury.event_type ? <Translate id={`insurance.label.eventTypes.${injury.event_type}`} /> : '-';
                        }
                        return (
                            <>
                                <FormikSelect
                                    name="event_type"
                                    id="event_type"
                                    autoFocus
                                    menuIsDefaultOpen={formik.values.event_type !== "OTHER"}
                                    defaultData={[
                                        ...EVENT_TYPES.map((event) => ({
                                            label: 'insurance.label.eventTypes.' + event,
                                            value: event,
                                            translateLabel: true,
                                        })),
                                        {
                                            label: "misc.other",
                                            value: "OTHER",
                                            translateLabel: true,
                                        },
                                    ]}
                                    loadingStatus="success"
                                />
                                <Collapse isOpen={formik.values.event_type === "OTHER"}>
                                    <FormikInputText
                                        translateHelper={false}
                                        bsSize="sm"
                                        helper={<span className="text-muted small"><i className="mdi mdi-information-outline mr-1" /><Translate id='insurance.validation.eventType.required' /></span>}
                                        className="mt-2"
                                        name="event_type_other"
                                        id="event_type_other"
                                    />
                                </Collapse>
                            </>
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <FormGroup>
                <Row className="w-100" form>
                    <Col sm="6">
                        <div className="text-muted">
                            <Translate id='insurance.label.accidentDate' />
                        </div>
                    </Col>
                    <Col sm="6">
                        <div className="text-muted">
                            <Translate id='insurance.label.deliveredDate' />
                        </div>
                    </Col>
                </Row>
                <FormikEditable
                    id="edit-accident"
                    manualIcon
                    disabled={!canEdit}
                    initialValues={{
                        accident_date: moment(injury.accident_date),
                        delivered_date: injury.delivered_date ? moment(injury.delivered_date) : '',
                    }}
                    validationSchema={object().shape({
                        accident_date: mixed().isDate().required(<Translate id='insurance.validation.incidentDate.required' />),
                        delivered_date: mixed().isDate().test({
                            name: 'cannotBePast',
                            message: <Translate id='insurance.validation.deliveredDate.cannotBeFuture' />,
                            test: function(date){
                                if(!this.parent.accident_date || !date)return true;
                                const theDate = moment(date);

                                return theDate.isSameOrAfter(moment(this.parent.accident_date), 'days') && theDate.isSameOrBefore(moment(), 'days');
                            },
                        }),
                    })}
                    onSubmit={(values) => {
                        const accidentDate = moment(values.accident_date);
                        const deliveredDate = values.delivered_date ? moment(values.delivered_date) : null;

                        if(!(accidentDate.isSame(moment(injury.accident_date), 'day'))
                        || ((deliveredDate ? deliveredDate.format('YYYY-MM-DD') : null) !== (injury.delivered_date ? moment(injury.delivered_date).format('YYYY-MM-DD') : null))){
                            const apiData = {
                                accident_date: accidentDate.toISOString(),
                                delivered_date: deliveredDate?.toISOString(),
                            };

                            updateInjury(apiData, apiData);
                        }
                    }}
                >
                    {(isEditing, _options, formik) => {
                        if(!isEditing){
                            return (
                                <Row className="w-100 position-relative" form>
                                    <Col sm="6">
                                        <div id='insurance-accident-accidentDate'>
                                            <DateFormat value={injury.accident_date} utc />
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <div id='insurance-accident-accidentDeliveredDate'>
                                            {injury.delivered_date ?
                                                <DateFormat value={injury.delivered_date} utc />
                                                :
                                                '-'
                                            }
                                        </div>
                                        {canEdit && <i className="mdi mdi-pencil text-primary ml-2 position-absolute top-0 right-0" />}
                                    </Col>
                                </Row>
                            );
                        }
                        return (
                            <Row form className="w-100">
                                <Col sm="6">
                                    <FormikDateTime
                                        name="accident_date"
                                        id="accident_date"
                                        isValidDate={(current) => current.isSameOrBefore(moment(), 'days')}
                                        timeFormat={false}
                                        placeholder='form.validation.date.placeholder'
                                    />
                                </Col>
                                <Col sm="6">
                                    <FormikDateTime
                                        name="delivered_date"
                                        className="date-picker-left"
                                        id="delivered_date"
                                        inputProps={{
                                            disabled: !formik.values.accident_date,
                                        }}
                                        placeholder='form.validation.date.placeholder'
                                        isValidDate={(current) => current.isSameOrBefore(moment(), 'days') && current.isSameOrAfter(formik.values.accident_date, 'days')}
                                        timeFormat={false}
                                    />
                                </Col>
                            </Row>
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <FormGroup>
                <div className="text-muted"><Translate id='insurance.label.environment' /></div>
                <FormikEditable
                    id="edit-participant-type"
                    disabled={!canEdit}
                    initialValues={{
                        injury_environment_id: injury.injury_environment?.injury_environment_id || '',
                        injury_environment_other: injury.injury_environment_other || '',
                    }}
                    validationSchema={object().shape({
                        injury_environment_id: string().required(<Translate id='insurance.validation.injuryEnv.required' />),
                        injury_environment_other: string().test(formikOtherTest('injury_environment_id', environments_w_other)),
                    })}
                    onSubmit={(values) => {
                        if(values.injury_environment_id !== injury.injury_environment?.injury_environment_id || values.injury_environment_other !== injury.injury_environment_other){
                            updateInjury(values, { injury_environment_other: values.injury_environment_other, injury_environment: environments.find((env) => env.injury_environment_id === values.injury_environment_id) });
                        }
                    }}
                >
                    {(isEditing, _options, formik) => {
                        if(!isEditing){
                            return !environments_w_other.includes(injury.injury_environment.injury_environment_id) ?
                                <DisplayI18n
                                    field="name"
                                    i18n={injury.injury_environment.i18n}
                                    defaultValue={injury.injury_environment.name}
                                />
                                :
                                injury.injury_environment_other || '-';
                        }
                        return (
                            <>
                                <FormikSelect
                                    {...getSelectInjuryProps("injury_environment_id", environments)}
                                    autoFocus
                                    menuIsDefaultOpen={!environments_w_other.includes(formik.values.injury_environment_id)}
                                />
                                <Collapse isOpen={environments_w_other.includes(formik.values.injury_environment_id)}>
                                    <FormikInputText
                                        bsSize="sm"
                                        className="mt-2"
                                        name="injury_environment_other"
                                        id="injury_environment_other"
                                        translateHelper={false}
                                        helper={<span className="text-muted small"><i className="mdi mdi-information-outline" /> <Translate id='insurance.validation.injuryEnv.required' /></span>}
                                    />
                                </Collapse>
                            </>
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <Row form>
                <Col sm="6" className="form-group">
                    <div className="text-muted"><Translate id='insurance.label.penalty' /></div>
                    <FormikEditable
                        id="edit-penalty_involved"
                        noConfirmation
                        disabled={!canEdit}
                        initialValues={{
                            penalty_involved: injury.penalty_involved || '',
                        }}
                        onSubmit={(values) => {
                            if(values.penalty_involved !== injury.penalty_involved){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return <Translate id={`misc.${injury.penalty_involved?.toLowerCase() || "noInfo"}`} />;
                            }
                            return (
                                <FormikSelect
                                    name="penalty_involved"
                                    id="penalty_involved"
                                    menuIsDefaultOpen
                                    search={false}
                                    autoFocus
                                    onOptionSelected={options.stopEditing}
                                    defaultData={YES_NO_INFO_DATA}
                                    loadingStatus="success"
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="6" className="form-group">
                    <div className="text-muted">
                        <Translate id='insurance.label.condition' />
                    </div>
                    <FormikEditable
                        id="edit-injury_event_time"
                        disabled={!canEdit}
                        initialValues={{
                            injury_condition_id: injury.injury_condition?.injury_condition_id || '',
                            injury_condition_other: injury.injury_condition_other || "",
                        }}
                        validationSchema={object().shape({
                            injury_condition_id: string().required(<Translate id='insurance.validation.condition.required' />),
                            injury_condition_other: string().test(formikOtherTest('injury_condition_id', conditions_w_other)),
                        })}
                        onSubmit={(values) => {
                            if(values.injury_condition_id !== injury.injury_condition?.injury_condition_id
                                || values.injury_condition_other !== injury.injury_condition_other){
                                updateInjury(values, {
                                    injury_condition: conditions.find((c) => c.injury_condition_id === values.injury_condition_id),
                                    injury_condition_other: values.injury_condition_other,
                                });
                            }
                        }}
                    >
                        {(isEditing, _options, formik) => {
                            if(!isEditing){
                                return (
                                    !conditions_w_other.includes(injury.injury_condition.injury_condition_id) ?
                                        <DisplayI18n
                                            field="name"
                                            i18n={injury.injury_condition?.i18n}
                                            defaultValue={injury.injury_condition?.name}
                                        />
                                        :
                                        injury.injury_condition_other || '-'
                                );
                            }
                            return (
                                <>
                                    <FormikSelect
                                        name="injury_condition_id"
                                        id="injury_condition_id"
                                        search
                                        autoFocus
                                        menuIsDefaultOpen={!conditions_w_other.includes(formik.values.injury_condition_id)}
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        renderOption={(opt) => (
                                            <DisplayI18n
                                                field="name"
                                                i18n={opt.option.i18n}
                                                defaultValue={opt.option.name}
                                            />
                                        )}
                                        defaultData={conditions.map((cond) => ({
                                            label: cond.name,
                                            i18n: cond.i18n,
                                            value: cond.injury_condition_id,
                                        }))}
                                        loadingStatus="success"
                                    />
                                    <Collapse isOpen={conditions_w_other.includes(formik.values.injury_condition_id)}>
                                        <FormikInputText
                                            bsSize="sm"
                                            name="injury_condition_other"
                                            helper={
                                                <span style={{ lineHeight: 1 }} className="text-muted small">
                                                    <i className="mdi mdi-information-outline" /> <Translate id='insurance.validation.condition.required' />
                                                </span>
                                            }
                                            className="mt-2"
                                            translateHelper={false}
                                        />
                                    </Collapse>
                                </>
                            )

                        }}
                    </FormikEditable>
                </Col>
            </Row>
            {canReadCheques &&
                <>
                    <div className="h5 font-bold d-flex align-items-center">
                        <span className="mr-auto">
                            <Translate id='insurance.sidePanel.cheque.label' />
                        </span>
                    </div>
                    <CanDoAction
                        componentCode="members"
                        componentPermissionCode="member_checks_generation"
                        action="ADD"
                    >
                        <BtnDisplay
                            title={<Translate id='insurance.sidePanel.cheque.addBtn' />}
                            subtitle={<Translate id='insurance.sidePanel.cheque.addBtn.subtitle' />}
                            icon="finance"
                            onClick={() => toggleChequeModal(true)}
                        />
                    </CanDoAction>
                    <ul className="list-unstyled">
                        {!cheques ?
                            <Skeleton className="mb-3" count={2} height={105} width="100%" />
                            :
                            cheques.map((cheque) => (
                                <li key={cheque.injury_cheque_id}>
                                    <InfoCheque
                                        cheque={cheque}
                                        getCheques={getCheques}
                                        deleteCheque={deleteCheque}
                                        {...props}
                                    />
                                </li>
                            ))
                        }
                    </ul>
                </>
            }
        </>
    )
}

export default InsuranceSidePanelAccident;