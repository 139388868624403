import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Badge } from "reactstrap";
import { I18nContext } from "../../../../contexts/I18nContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";

// CONSTANTS
export const INJURY_TYPES = [
    'INFO',
    'CLAIM',
];

export const CHEQUE_ISSUED_TO = [
    'CLAIMANT',
    'GUARDIAN',
    'OTHER',
];

export const PARTICIPANT_TYPES = [
    'PLAYER',
    'TEAM_OFFICIAL',
    'GAME_OFFICIAL',
    'SPECTATOR',
];

export const EMPLOYED_OPTIONS = [
    // 'NO',
    'EMPLOYED',
    'EMPLOYED_PART_TIME',
    "STUDENT",
    'UNEMPLOYED',
    "NO_INFO",
];

export const EVENT_TYPES = [
    'NO_INFO',
    'EXHIBITION',
    'REGULAR_SEASON',
    'PLAYOFF',
    'TOURNAMENT',
    'PRACTICE',
    'TRYOUT',
];

export const NO_TEAM = "NONE";
// END CONSTANTS

/**
 * @description Sorts a ressource array by display_order
 * @param {{display_order: string|number}[]} ressource
 * @returns {{display_order: string|number}[]} sorted ressource
 */
export const sortRessource = (ressource) => ressource.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order));


/**
 * @description Used for common logic 'if value is 'OTHER', then this field is required
 * @param {string} id Id of the formik field that this tests depends on
 * @returns {object}
 */
export const formikOtherTest = (id, arr) => ({
    name: 'otherSelect',
    message: <Translate id='insurance.validation.other.pleaseSpecify' />,
    test: function(data){
        return !(this.parent[id] === "OTHER" || arr?.includes(this.parent[id])) || !!data;
    },
});

/**
 * @description used to display a badge of the injury's status
 * @param {Object} props
 * @param {object} props.injuryStatus Object containing injury_status ressource
 * @returns {JSX.Element}
 */
export const InjuryStatus = ({ injuryStatus, ...props }) => {
    if(!injuryStatus)return null;

    const getColor = (name) => {
        switch (name.toLowerCase()){
            case 'open':
                return 'success';
            case 'pending':
                return 'warning';
            case 'closed':
                return 'danger';
            case 'new injury report':
                return 'light';
            default:
                return 'info';
        }
    }

    return (
        <Badge color={getColor(injuryStatus.name)} {...props}>
            <DisplayI18n
                field="name"
                defaultValue={injuryStatus.name}
                i18n={injuryStatus.i18n}
            />
        </Badge>
    )
}

/**
 * @param {object[]} arr
 * @param {string} idField The field name of the id
 * @returns {string[]} An array of ids
 */
export const getOtherNeededIds = (arr, idField) => {
    return arr.filter((item) => item.is_other_status == 1).flatMap((item) => item[idField]);
}

export const YES_NO_DATA = [
    {
        label: "misc.yes",
        value: "YES",
        translateLabel: true,
    },
    {
        label: "misc.no",
        value: "NO",
        translateLabel: true,
    },
];

export const YES_NO_INFO_DATA = [
    ...YES_NO_DATA,
    {
        label: "misc.noInfo",
        value: "NO_INFO",
        translateLabel: true,
    },
]

/**
 * @typedef {Function} getInjurySelectProps
 * @param {string} name
 * @param {object} obj
 * @returns {{
 *  name: string,
 *  id: string,
 *  search: true,
 *  isLoading: boolean,
 *  searchKeys: array,
 *  renderOption: function,
 *  options: array,
 * }}
 */

/**
 * @hook
 * @returns {getInjurySelectProps}
 */
export const useInjurySelect = () => {
    const { getGenericLocale } = useContext(I18nContext);

    const getInjurySelectProps = (name, obj) => ({
        name: name,
        id: name,
        search: true,
        isLoading: !obj,
        searchKeys: [
            `i18n.${getGenericLocale()}.name`,
        ],
        renderOption: (opt) => (
            <DisplayI18n
                field="name"
                i18n={opt.option.i18n}
                defaultValue={opt.option.label}
            />
        ),
        options: obj ? obj.map((part) => (
            {
                label: part.name,
                i18n: part.i18n,
                value: part[name],
            }
        )) : [],
    })

    return getInjurySelectProps;
}

/**
 * @description Filter status ids into different categories
 * @param {object[]} statuses
 * @returns {{ openStatusId: string, openStatusesIds: [], pendingStatusId: string, closedStatuses: [] }}
 */
export const getStatusIdsPerTypes = (statuses) => {
    return (statuses || []).reduce((filteredStatuses, theStatus) => {
        const statusName = (theStatus.name || "").toLowerCase();

        if([ "open", "open on hold" ].includes(statusName)){
            filteredStatuses.openStatusesIds.push(theStatus.injury_status_id);

            if(statusName === "open"){
                filteredStatuses.openStatusId = theStatus.injury_status_id;
            }
        }else if(statusName === "pending"){
            filteredStatuses.pendingStatusId = theStatus.injury_status_id;
        }else if([ "filed", "closed", "filed - nw" ].includes(statusName)){
            filteredStatuses.closedStatuses.push(theStatus.injury_status_id);
        }

        return filteredStatuses;
    }, { openStatusId: "", openStatusesIds: [], pendingStatusId: "", closedStatuses: [] });
}