import { useSpordleTable } from "@spordle/datatables";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { AppContext } from "../../../../contexts/contexts";

const SpordleListLoading = () => {
    const { getPagination } = useSpordleTable();
    const { isMobileDevice } = useContext(AppContext);

    return <Skeleton className="rounded-0" height={isMobileDevice ? 66 : 52} count={getPagination()} />
}

export default SpordleListLoading;