import TeamInfo from '../../views/teams/profile/TeamInfo/TeamInfo';
import TeamRoster from '../../views/teams/profile/TeamRoster/TeamRoster';
import TeamRosterEdit from '../../views/teams/profile/TeamRoster/TeamRosterEdit';
import TeamSettings from '../../views/teams/profile/teamSettings/TeamSettings';

/**
 * @typedef {object} TabRoutes
 * @property {React.ReactNode} [name]
 * @property {boolean} [redirect]
 */

/** @type {Array.<import('react-router-dom').RouteProps & TabRoutes>} */
const teamProfileTabsRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        path: '/roster',
        name: 'teams.profile.roster.tab.title',
        permissions: { code: 'teams', permissionCode: 'team_members' },
        component: TeamRoster,
    },
    {
        path: '/info',
        name: 'teams.profile.teamInfos.tab.title',
        component: TeamInfo,
        permissions: { code: 'teams', permissionCode: 'teams_profile' },
    },
    {
        path: '/settings',
        name: 'teams.profile.teamSetting.tab.title',
        component: TeamSettings,
        permissions: { code: 'teams', permissionCode: 'teams_profile' },
    },
    {
        path: '/edit',
        name: 'teams.profile.teamRosterEdit.tab.title',
        permissions: { code: 'teams', permissionCode: 'team_members', actions: [ 'EDIT' ] },
        component: TeamRosterEdit,
        tempTab: true,
    },
    {
        hiddenRoute: true,
        hiddenTab: true,
        path: '/teams/create',
        name: 'misc.create',
    },
    {
        hiddenRoute: true,
        hiddenTab: true,
        path: '/teams/rollover',
        name: 'teams.teamSearch.rollover.wizard.title',
    },
    {
        redirect: true,
        dynamicRedirect: true,
        from: '/team/profile/',
        to: '/roster',
    },
];
export default teamProfileTabsRoutes;