import { FormikError } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Alert, Button, Collapse, Input, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import EmptyLayout from "../../../../../components/table/EmptyLayout";
import PostalCodesCard from "./PostalCodesCard";
import { isFn } from "../../../../../helpers/helper";

const PostalCodesSelection = ({ postalCodesData, toggle, isEdit, canEdit }) => {
    const formik = useFormikContext();
    const { formatMessage } = useIntl();
    const [ search, setSearch ] = useState('');

    if(!postalCodesData){
        return null;
    }

    const splitPostalCodes = postalCodesData.codes;
    const postalCodesObject = Object.keys(splitPostalCodes || {});
    const isOnlyOne = postalCodesObject.length == 1;

    const handleOnSetSelectedCodes = (codesKey) => {
        return (val) => {
            formik.setFieldValue(`postalCodes.${codesKey}`, isFn(val) ? val(formik.values.postalCodes[codesKey]) : val)
        }
    }

    const countSelected = () => {
        const values = Object.values(formik.values.postalCodes);

        if(values.length === 0){
            return 0;
        }

        return values.reduce((count, val) => {
            return count + (val ? val.size : 0);
        }, 0);
    }

    return (
        <>
            <ModalHeader toggle={toggle}>
                <Translate id={`organization.profile.postalCodes.modals.${isEdit ? 'edit' : 'add'}.step2.title`} values={{ postalCode: formik.values.partialPostalCode }} />
            </ModalHeader>
            <ModalBody>
                {postalCodesObject.length === 0 ?
                    <EmptyLayout
                        hideArrow
                        msg={(
                            <Translate
                                id='organization.profile.postalCodes.modals.step2.empty.search'
                                values={{
                                    searchValue: (
                                        <span className={stringBuilder('text-dark', { 'text-uppercase': formik.values.partialPostalCode, 'text-capitalize': !formik.values.partialPostalCode })}>
                                            {formik.values.partialPostalCode || <>{formik.values.city}, {formik.values.province}</>}
                                        </span>
                                    ),
                                }}
                            />
                        )}
                    />
                    :
                    <>
                        <p className='text-center'>
                            <Translate id={`organization.profile.postalCodes.modals.${isEdit ? 'edit' : 'add'}.step2.description`} />
                        </p>
                        <div className='d-flex justify-content-between mb-2'>
                            <div>
                                <span className={stringBuilder('text-dark mr-1', { 'text-uppercase': formik.values.partialPostalCode, 'text-capitalize': !formik.values.partialPostalCode })}>
                                    {formik.values.partialPostalCode || <>{formik.values.city}, {formik.values.province}</>}
                                </span>
                                ({countSelected()}/{postalCodesData.total})
                            </div>
                        </div>
                        <Input
                            type='search'
                            className='mb-3'
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                            placeholder={formatMessage({ id: 'misc.search' })}
                            role='textbox'
                            aria-autocomplete="list"
                        />
                        {postalCodesObject.map((codesKey) => {
                            const allCodes = splitPostalCodes[codesKey];
                            const firstCode = allCodes[0];

                            return (
                                <PostalCodesCard
                                    key={codesKey}
                                    isEdit={isEdit}
                                    canEdit={canEdit}
                                    prefix={codesKey}
                                    selectedCodes={formik.values.postalCodes[codesKey]}
                                    setSelectedCodes={handleOnSetSelectedCodes(codesKey)}
                                    postalCodes={allCodes}
                                    search={search}
                                    initOpen={isOnlyOne}
                                    title={!formik.values.partialPostalCode ? codesKey : `${firstCode.city_name}, ${firstCode.province_abbreviation}`}
                                />
                            )
                        })}
                        <FormikError name='postalCodes' />
                        <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                            <Alert color='danger' className='mb-0 mt-3'>
                                {formik.status}
                            </Alert>
                        </Collapse>
                    </>
                }
            </ModalBody>
            <ModalFooter>
                {canEdit ?
                    <>
                        {!isEdit &&
                            <Button color="primary" className="mr-auto" outline type='button' onClick={() => { formik.setFieldValue('step', formik.values.step - 1) }}><Translate id='misc.previous' /></Button>
                        }
                        {postalCodesObject.length !== 0 &&
                            <Button color="primary" type='submit'><Translate id={`misc.${isEdit ? 'edit' : 'add'}`} /></Button>
                        }
                        <Button color="primary" outline type='button' onClick={toggle}><Translate id='misc.cancel' /></Button>
                    </>
                    :
                    <Button color="primary" outline type='button' onClick={toggle}><Translate id='misc.close' /></Button>
                }
            </ModalFooter>
        </>
    )
};

export default PostalCodesSelection;
