import { FormikDateTime, FormikSelect } from "@spordle/formik-elements";
import Translate, { DateFormat } from "@spordle/intl-elements";
import moment from "moment";
import { useContext } from "react";
import { Col, FormGroup, Row } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import FormikEditable from "../../../../../../components/formik/FormikEditable";
import { fail, success } from '@spordle/toasts';
import { ClinicsContext } from "../../../../../../contexts/ClinicsContext";
import { mixed, object, string } from 'yup';
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";

/**
 * @typedef {Object} SharedProps
 * @prop {object[]} selectedRows
 * @prop {function} setLoading
 * @prop {function} syncRows
 * @prop {function} createNewValues
 * @prop {function} getCommonValue
 */

/**
 * @description Component that can be shared between a sidepanel with a single selected row and a sidepanel with multiple selected rows
 *
 * @param {SharedProps} props
 * @returns {JSX.Element} Formik editable fields + common information
 */
const SidePanelAttendeeSharedInfo = ({ selectedRows, setLoading, syncRows, createNewValues, getCommonValue }) => {
    const { currentClinic, updateClinicAttendeePartial, certifyClinicAttendee } = useContext(ClinicsContext);
    const notAttendedAndPassed = (getCommonValue('attended') == "0" || getCommonValue('attended') == '-') && (getCommonValue('passed') != "0" || getCommonValue('attended') == '-') && selectedRows.length > 1;

    function handleError(e){
        if(!AxiosIsCancelled(e.message)){
            console.error(e.message)
            fail({
                msg: 'misc.error',
                info: <DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />,
                skipInfoTranslate: true,
            })
            setLoading(false);
        }
    }

    function handleSuccess(newValues){
        syncRows(newValues)
        success();
        setLoading(false);
    }

    return (
        <>
            <FormGroup>
                <div className="text-muted"><Translate id='clinics.profile.attendees.sidepanel.attended.label' /></div>
                <FormikEditable
                    id='attended'
                    initialValues={{
                        attended: getCommonValue('attended') == "-" ? '' : getCommonValue('attended'),
                        // doing this weird moment thing because moment() wouldn't work
                        attendedDate: !getCommonValue('attended_date') || getCommonValue('attended_date') === "-" ? moment(moment().format('YYYY-MM-DD')) : moment(getCommonValue('attended_date')),
                    }}
                    disabled={notAttendedAndPassed}
                    validationSchema={object().shape({
                        attended: string().required(<Translate id='clinics.profile.attendees.sidepanel.attended.required' />),
                        attendedDate: mixed().when('attended', {
                            is: (attended) => attended == 1,
                            then: mixed().required(<Translate id='clinics.profile.attendees.sidepanel.attended.date.required' />)
                                .test({
                                    name: 'attendedDateTest',
                                    message: <Translate id='form.validation.date.format' />,
                                    test: moment.isMoment,
                                })
                                .test({
                                    name: 'attendedDateBeforePassed',
                                    message: <Translate id='clinics.profile.attendees.sidepanel.attended.date.before' />,
                                    test: (attendedDate) => {
                                        if(getCommonValue('passed_date') && getCommonValue('passed_date') !== '-'){
                                            return moment(attendedDate).isBefore(moment(getCommonValue('passed_date')).add(1, 'day'))
                                        }
                                        return true
                                    },
                                }),
                        }),
                    })}
                    onSubmit={(values) => {
                        const newDate = values.attended == 1 ? values.attendedDate.format('YYYY-MM-DD') : '';
                        if(values.attended !== getCommonValue('attended') || newDate !== getCommonValue('attended_date')){
                            setLoading(true);

                            const newValues = createNewValues({
                                attended: values.attended,
                                attended_date: newDate,
                            });

                            Promise.all(
                                selectedRows.map((attendee) => {
                                    return updateClinicAttendeePartial(
                                        currentClinic.clinic_id,
                                        attendee.clinic_attendee_id,
                                        {
                                            attended: values.attended,
                                            attended_date: newDate,
                                        },
                                    )
                                }),
                            )
                                .then(() => handleSuccess(newValues))
                                .catch(handleError);
                        }
                    }}
                >
                    {(isEditing, _options, formik) => {
                        if(!isEditing){
                            return (
                                <div className="font-medium">
                                    {
                                        getCommonValue('attended') == '-' ?
                                            '-'
                                            :
                                            getCommonValue('attended') == 1 ?
                                                <>
                                                    <i className='mdi mdi-check text-primary mr-2' />
                                                    <Translate values={{ count: selectedRows.length }} id='clinics.profile.attendees.sidepanel.attended' />
                                                    {(getCommonValue('attended_date') && selectedRows.length == 1) &&
                                                    <span className='small'> - <DateFormat value={getCommonValue('attended_date')} utc /></span>
                                                    }
                                                </>
                                                :
                                                <Translate values={{ count: selectedRows.length }} id='clinics.profile.attendees.sidepanel.attended.not' />
                                    }
                                    {notAttendedAndPassed &&
                                        <span className='d-block small'><i className='mdi mdi-information-outline text-primary' /> <Translate id={`clinics.profile.attendees.sidepanel.attended.unable${selectedRows.length > 1 ? '.multiple' : ''}`} /></span>
                                    }
                                </div>
                            )
                        }
                        return (
                            <>
                                <Row className="align-items-start" form>
                                    <Col md='6' className='mb-2'>
                                        <FormikSelect
                                            name="attended"
                                            id="ClinicAttendeeSidepanelAttendedSelect"
                                            disabled={getCommonValue('passed') != '0'}
                                            renderOption={(option) => <Translate id={option.option.label} values={{ count: selectedRows.length }} />}
                                            loadingStatus='success'
                                            defaultData={[
                                                { value: '1', label: 'clinics.profile.attendees.sidepanel.attended' },
                                                { value: '0', label: 'clinics.profile.attendees.sidepanel.attended.not' },
                                            ]}
                                        />
                                    </Col>
                                    <Col sm='6' className='mb-2'>
                                        <FormikDateTime
                                            id='attendedDate'
                                            name='attendedDate'
                                            className='date-picker-left'
                                            inputProps={{ disabled: formik.values.attended == 0 }}
                                            timeFormat={false}
                                            dateFormat='YYYY-MM-DD'
                                            isValidDate={(current) => (getCommonValue('passed_date') && getCommonValue('passed_date') !== '-') ? moment(current).isBefore(moment(getCommonValue('passed_date')).add(1, 'day')) : true}
                                        />
                                    </Col>
                                </Row>
                                {(getCommonValue('passed') == 1 || selectedRows.length > 1) &&
                                        <span className='small'><i className='mdi mdi-information-outline text-primary' /> <Translate id={`clinics.profile.attendees.sidepanel.attended.unable${selectedRows.length > 1 ? '.multiple' : ''}`} /></span>
                                }
                            </>
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <FormGroup>
                <div className="text-muted">
                    <Translate id='clinics.profile.attendees.sidepanel.passed.label' />
                </div>
                <FormikEditable
                    id='passed'
                    disabled={getCommonValue('attended') != 1}
                    initialValues={{
                        passed: getCommonValue('passed'),
                        // doing this weird moment thing because moment() wouldn't work
                        passedDate: getCommonValue('passed_date') ? moment(getCommonValue('passed_date')) : moment(moment().format('YYYY-MM-DD')),
                        // always false because we only want the user to check certify once
                        // if he has not done it, checkbox will show, and this formik variable will become true, making the certify API call
                        // if he has already done it, checkbox won't show because of a condition below, and no API call will ever be made again
                        certified: false,
                    }}
                    validationSchema={object().shape({
                        passed: string(),
                        passedDate: mixed().when('passed', {
                            is: (passed) => passed == 1,
                            then: mixed()
                                .test({
                                    name: 'presenceDateTest',
                                    message: <Translate id='clinics.profile.attendees.sidepanel.passed.date.noPresence' />,
                                    test: () => getCommonValue('attended_date'),
                                })
                                .required(<Translate id='clinics.profile.attendees.sidepanel.passed.date.required' />)
                                .test({
                                    name: 'passedDateTest',
                                    message: <Translate id='form.validation.date.format' />,
                                    test: moment.isMoment,
                                })
                                .test({
                                    name: 'passedDateAfterTest',
                                    message: <Translate id='clinics.profile.attendees.sidepanel.passed.date.after' />,
                                    test: (date) => {
                                        if(getCommonValue('attended_date') && getCommonValue('attended_date') !== '-'){
                                            return moment(date).isAfter(moment(getCommonValue('attended_date')).subtract(1, 'day'))
                                        }
                                        return false

                                    },
                                })
                                .test({
                                    name: 'passedDateBeforeTest',
                                    message: <Translate id='clinics.profile.attendees.sidepanel.passed.date.before' />,
                                    test: (date) => {
                                        if(getCommonValue('certified_date') && getCommonValue('certified_date') !== '-'){
                                            return moment(date).isBefore(moment(getCommonValue('certified_date')).add(1, 'day'))
                                        }
                                        return true
                                    },
                                }),
                        }),
                    })}
                    onSubmit={(values) => {
                        const newDate = values.passed == 1 ? values.passedDate.format('YYYY-MM-DD') : '';
                        if(values.passed !== getCommonValue('passed') || newDate !== getCommonValue('passed_date') || (values.certified && getCommonValue('certified') == 0)){

                            setLoading(true);

                            const newValueObj = {
                                passed: values.passed,
                                passed_date: newDate,
                            }

                            if(values.passed == 1 && values.certified){
                                newValueObj.certified = '1';
                                newValueObj.certified_date = newDate;
                            }

                            const newValues = createNewValues(newValueObj);

                            Promise.all(
                                selectedRows.map((attendee) => {
                                    return updateClinicAttendeePartial(
                                        currentClinic.clinic_id,
                                        attendee.clinic_attendee_id,
                                        {
                                            passed: values.passed,
                                            passed_date: newDate,
                                        },
                                    )
                                }),
                            )
                                .then(async() => {
                                    if(values.passed == 1 && values.certified){
                                        await Promise.all(
                                            selectedRows.map((attendee) => {
                                                return certifyClinicAttendee(
                                                    currentClinic.clinic_id,
                                                    attendee.clinic_attendee_id,
                                                    {
                                                        certificationDate: newDate,
                                                    },
                                                )
                                            }),
                                        )
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    }

                                    handleSuccess(newValues);
                                })
                                .catch(handleError);
                        }
                    }}
                >
                    {(isEditing, _options, formik) => {
                        if(!isEditing){
                            return (
                                <div className="font-medium">
                                    {
                                        getCommonValue('passed') == '-' ?
                                            '-'
                                            :
                                            getCommonValue('passed') == 1 ?
                                                <>
                                                    <i className='mdi mdi-check text-primary mr-2' />
                                                    <Translate values={{ count: selectedRows.length }} id='clinics.profile.attendees.sidepanel.passed' />
                                                    {getCommonValue('passed_date') !== "-" &&
                                                    <span className='small'> - <DateFormat value={getCommonValue('passed_date')} utc /></span>
                                                    }
                                                </>
                                                :
                                                <Translate values={{ count: selectedRows.length }} id='clinics.profile.attendees.sidepanel.passed.not' />
                                    }
                                </div>
                            )
                        }
                        return (
                            <>
                                <Row form>
                                    <Col md='6' className='mb-2'>
                                        <FormikSelect
                                            name="passed"
                                            id="ClinicAttendeeSidepanelPassedSelect"
                                            disabled={getCommonValue('certified') == 1}
                                            renderOption={(option) => <Translate id={option.option.label} values={{ count: selectedRows.length }} />}
                                            loadingStatus='success'
                                            defaultData={[
                                                { value: '1', label: 'clinics.profile.attendees.sidepanel.passed' },
                                                { value: '0', label: 'clinics.profile.attendees.sidepanel.passed.not' },
                                            ]}
                                        />
                                    </Col>
                                    <Col sm='6' className='d-flex align-items-center mb-2'>
                                        <div>
                                            <FormikDateTime
                                                id='passedDate'
                                                name='passedDate'
                                                className='date-picker-left'
                                                inputProps={{ disabled: formik.values.passed == 0 }}
                                                timeFormat={false}
                                                dateFormat='YYYY-MM-DD'
                                                isValidDate={(current) => {
                                                    const attendedDateValid = (getCommonValue('attended_date') && getCommonValue('attended_date') !== '-') ? moment(current).isAfter(moment(getCommonValue('attended_date')).subtract(1, 'day')) : true
                                                    const certifiedDateValid = (getCommonValue('certified_date') && getCommonValue('certified_date') !== '-') ? moment(current).isBefore(moment(getCommonValue('certified_date')).add(1, 'day')) : true
                                                    return attendedDateValid && certifiedDateValid
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {(getCommonValue('certified') == 1 && selectedRows.length == 1) &&
                                        <span className='small'><i className='mdi mdi-information-outline text-primary' /> <Translate id='clinics.profile.attendees.sidepanel.passed.unable' /></span>
                                }
                            </>
                        )

                    }}
                </FormikEditable>
                {selectedRows.length > 1 &&
                    <>
                        {getCommonValue('attended') != 1 ?
                            <span className='d-block small'><i className='mdi mdi-information-outline text-primary' /> <Translate id='clinics.profile.attendees.sidepanel.passed.date.noPresence.multiple' /></span>
                            :
                            getCommonValue('certified') != "0" &&
                                <span className='d-block small'><i className='mdi mdi-information-outline text-primary' /> <Translate id='clinics.profile.attendees.sidepanel.passed.unable.multiple' /></span>
                        }
                    </>
                }
            </FormGroup>
        </>
    )
}

export default SidePanelAttendeeSharedInfo;