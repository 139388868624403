import UserDisplay from "../../../../userDisplay/UserDisplay";

const CommTypeBtn = ({ title, subtitle, onClick }) => {
    return (
        <UserDisplay onClick={onClick} className="mb-2" card block hover>
            <UserDisplay.Container>
                <UserDisplay.Title>{title}</UserDisplay.Title>
                <UserDisplay.Subtitle>{subtitle}</UserDisplay.Subtitle>
            </UserDisplay.Container>
        </UserDisplay>
    );
}

export default CommTypeBtn;