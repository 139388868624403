import { useSpordleTable } from '@spordle/datatables';
import { FormikCheckedButton, FormikDateTime, FormikGroup, FormikInputNumber, FormikInputText, FormikRadioButton, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { Form, Formik } from 'formik';
import moment from 'moment';
import { Tooltip } from "@mantine/core";
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Collapse, Fade, FormGroup, Label, Row } from 'reactstrap';
import { bool, mixed, number, object, ref, string } from 'yup';
import { AxiosCancelMembersCalls, AxiosIsCancelled } from '../../../api/CancellableAPI';
import OrganizationSearch from '../../../components/organization/OrganizationSearch';
import HasAccessTo from '../../../components/permissions/HasAccessTo';
import { I18nContext } from '../../../contexts/I18nContext';
import { MembersContext } from '../../../contexts/MembersContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { DisplayI18n, displayI18n } from '../../../helpers/i18nHelper';
import { fail } from "@spordle/toasts";
import { RolesContext } from '../../../contexts/RolesContext';
import { PeriodsContext } from '../../../contexts/contexts';
import useSavedSearch from '../../../components/customHooks/useSavedSearch';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import SavedSearchCue from '../../../components/savedSearchCue/SavedSearchCue';
import GenderLabel from '../../../components/GenderLabel';
import MemberFieldLabel from '../../../components/memberRegistration/MemberFieldLabel';

const AdvancedSearchSection = () => {
    const [ formikKey, setFormikKey ] = useState(0); // This is used to force a rerender of formik to empty FormikDateTime fields
    const [ moreFiltersOpen, setMoreFiltersOpen ] = useState(true);
    const spordleTable = useSpordleTable();
    const organizationContext = useContext(OrganizationContext);
    const { identity_role_id } = useContext(IdentityRolesContext);
    const i18nContext = useContext(I18nContext);
    const membersContext = useContext(MembersContext);
    const rolesContext = useContext(RolesContext);
    const periodsContext = useContext(PeriodsContext);

    const { savedSearch, clearSavedSearch, saveSearch } = useSavedSearch(`member-search-filters-${organizationContext.organisation_id}-${identity_role_id}-${periodsContext.selectedPeriod.period_id}`);

    const toggleMoreFilters = () => {
        setMoreFiltersOpen(!moreFiltersOpen);
    }

    // This is used to force a rerender of formik to empty FormikDateTime fields
    const rerenderFormik = () => {
        clearSavedSearch();
        setFormikKey(formikKey + 1);
    }

    ////////////////////////////////////////////////////////////////////////////
    // Member Types Select
    ////////////////////////////////////////////////////////////////////////////
    const [ memberTypes, setMemberTypes ] = useState(null);

    const originalValues = {
        memberType: [],
        noMemberType: false,
        organization: organizationContext.organisation_id,
        period_id: periodsContext.selectedPeriod.period_id,
        firstName: '',
        lastName: '',
        email: '',
        // crcExpired: false,
        member_status: '',
        startDate: '',
        endDate: '',
        status: [],
        gender: [],
        unique_identifier: '',
        searchType: organizationContext.organisation_category?.organisation_category_id !== '1eb5d984-b7a2-6d8e-a1b7-06ba8f7ba4e0' ? 'subOrgs' : '', // Any parent organization should be searching through sub orgs //TODO: Hardcoded organisation_category
        minAge: '',
        maxAge: '',
        with_unconfirmed_address: false,
        need_address_document: false,
        with_missing_required_field: false,
        withoutProfilePicture: false,
        hasOutstandingBalance: false,
    }

    const getInitFilters = () => {
        if(savedSearch){
            return {
                ...originalValues,
                ...savedSearch,
                startDate: !savedSearch.startDate ? '' : moment(savedSearch.startDate),
                endDate: !savedSearch.endDate ? '' : moment(savedSearch.endDate),
            };
        }

        return originalValues;
    }

    useEffect(() => {
        membersContext.getMemberTypes(organizationContext.organisation_id)
            .then(async(data) => {
                /** @type {Array}*/
                const result = data.reduce((newArray, memberType) => {
                    if(memberType.active === '1'){
                        newArray.push({
                            value: memberType.member_type_id,
                            i18n: memberType.i18n,
                            label: memberType.name,
                        })
                    }
                    return newArray;
                }, []).sort((a, b) => {
                    const valueA = (displayI18n("name", a.i18n, a.label, i18nContext.getGenericLocale())).toLowerCase()
                    const valueB = (displayI18n("name", b.i18n, b.label, i18nContext.getGenericLocale())).toLowerCase()
                    if(valueA > valueB)
                        return 1;
                    else if(valueA < valueB)
                        return -1;
                    return 0;
                })

                setMemberTypes(result);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });

        if(organizationContext.organisation_category?.organisation_category_id === '1eb5d984-b7a2-6d8e-a1b7-06ba8f7ba4e0'){ // TODO: Hardcoded organisation_category
            spordleTable.filterChange();
        }
    }, [])

    return (
        <Formik
            key={formikKey}
            initialValues={getInitFilters()}
            validationSchema={object().shape({
                searchType: string(),
                firstName: string().when([ 'searchType', 'unique_identifier', 'with_unconfirmed_address', 'member_status', 'memberType', 'need_address_document', 'email', 'noMemberType', 'with_missing_required_field' ], {
                    is: (searchType, unique_identifier, with_unconfirmed_address, member_status, memberType, need_address_document, email, noMemberType, with_missing_required_field) => {
                        if(searchType == 'subOrgs'){
                            // don't require name if searching in subOrganization using any of the following filters
                            return !(member_status || (Array.isArray(memberType) && memberType.length > 0) || with_unconfirmed_address || need_address_document || email || unique_identifier || noMemberType || with_missing_required_field)
                        }
                        return (!!searchType && !unique_identifier && !with_unconfirmed_address)// if a searchType is specified AND if member number is empty
                    },
                    then: string().required(<Translate id='members.search.memberAdvanceSearch.validation.firstName.requiredFilters' />),
                }),
                lastName: string().when([ 'searchType', 'unique_identifier', 'with_unconfirmed_address', 'member_status', 'memberType', 'need_address_document', 'email', 'noMemberType', 'with_missing_required_field' ], {
                    is: (searchType, unique_identifier, with_unconfirmed_address, member_status, memberType, need_address_document, email, noMemberType, with_missing_required_field) => {
                        if(searchType == 'subOrgs'){
                            // don't require name if searching in subOrganization using any of the following filters
                            return !(member_status || (Array.isArray(memberType) && memberType.length > 0) || with_unconfirmed_address || need_address_document || email || unique_identifier || noMemberType || with_missing_required_field)
                        }
                        return (!!searchType && !unique_identifier && !with_unconfirmed_address)// if a searchType is specified AND if member number is empty
                    },
                    then: string().required(<Translate id='members.search.memberAdvanceSearch.validation.lastName.requiredFilters' />),
                }),
                email: string().email(<Translate id='members.search.memberAdvanceSearch.validation.email.valid' />),
                // crcExpired: bool(),
                with_unconfirmed_address: bool(),
                need_address_document: bool(),
                with_missing_required_field: bool(),
                withoutProfilePicture: bool(),
                hasOutstandingBalance: bool(),
                member_status: string(),
                startDate: mixed().when([ 'minAge', 'maxAge' ], {
                    is: (minAge, maxAge) => minAge == null && maxAge == null,
                    then: mixed().when('endDate', {
                        is: (endDate) => !!endDate,
                        then: mixed().required(<Translate id='members.search.memberAdvanceSearch.validation.startDate.required' />),
                    }).test({
                        name: 'valid-startDate',
                        message: <Translate id='members.search.memberAdvanceSearch.validation.startDate.invalidFormat' />,
                        test: (startDate) => {
                            if(!startDate)
                                return true;
                            return moment.isMoment(startDate);
                        },
                    }).test({
                        name: 'beforeToday-startDate',
                        message: <Translate id='members.search.memberAdvanceSearch.validation.startDate.beforeToday' />,
                        test: (startDate) => {
                            if(!startDate)
                                return true;
                            return moment(startDate).isBefore();
                        },
                    }),
                }),
                endDate: mixed().when([ 'minAge', 'maxAge' ], {
                    is: (minAge, maxAge) => minAge == null && maxAge == null,
                    then: mixed().when('startDate', {
                        is: (startDate) => !!startDate,
                        then: mixed().required(<Translate id='members.search.memberAdvanceSearch.validation.endDate.required' />),
                    }).test({
                        name: 'valid-endDate',
                        message: <Translate id='members.search.memberAdvanceSearch.validation.endDate.invalidFormat' />,
                        test: (endDate) => {
                            if(!endDate)
                                return true;
                            return moment.isMoment(endDate);
                        },
                    }).test({
                        name: 'afterFromDate-endDate',
                        message: <Translate id='members.search.memberAdvanceSearch.validation.endDate.beforeStartDate' />,
                        test: function(endDate){
                            if(!endDate)
                                return true;
                            return moment(endDate).isAfter(moment(this.parent.startDate).subtract(1, 'days'));
                        },
                    }),
                }),
                minAge: number().when('maxAge', {
                    is: (maxAge) => maxAge != null,
                    then: number()
                        .required(<Translate id='members.search.memberAdvanceSearch.validation.minAge.required' />)
                        .min(1, <Translate id='members.search.memberAdvanceSearch.validation.minAge.1.required' />)
                        .max(ref('maxAge'), <Translate id='members.search.memberAdvanceSearch.validation.minAge.max' />),
                }),
                maxAge: number().when('minAge', {
                    is: (minAge) => minAge != null,
                    then: number()
                        .required(<Translate id='members.search.memberAdvanceSearch.validation.maxAge.required' />)
                        .min(ref('minAge'), <Translate id='members.search.memberAdvanceSearch.validation.maxAge.min' />),
                }),
            }, [ [ 'startDate', 'endDate' ], [ 'minAge', 'maxAge' ] ])}
            onSubmit={({ minAge, maxAge, ...values }) => {

                // this function cancels all GET calls starting with /members, be extremely careful if you reuse this somewhere else
                // (this prevents API spam but mostly implemented to prevent users from wiaintg for previous calls if they spam click the search or want to change their query while waiting for the call to run)
                AxiosCancelMembersCalls();

                spordleTable.setInputValue();
                spordleTable.filterChange("memberSearchFilters", { ...values, minAge: minAge, maxAge: maxAge });
                saveSearch({
                    ...values,
                    minAge: minAge,
                    maxAge: maxAge,
                    paginationValue: spordleTable.getPagination(),
                })
            }}
        >
            {(formik) => (
                <Form id='clinic-advanced-search-section'>
                    <Row form>
                        <Col md="6" lg='6'>
                            <FormGroup>
                                <Label for="organization" className="text-muted"><Translate id='members.search.memberAdvanceSearch.filters.organization' /></Label>
                                <OrganizationSearch
                                    selectedDefault={formik.initialValues.organization}
                                    fromIdentityRole withFormik fromApi
                                    className='w-100'
                                    name="organization"
                                    id='organization'
                                    isDisabled={!!formik.values.unique_identifier}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" lg='3' className='d-flex align-items-end mb-3 order-last order-md-5'>
                            {/* Sorry for that hard-coded height :) */}
                            {/* The FormikSelect has a height of 38px and it looks a lot better when all elements have the same height */}
                            {/* TODO - either find a solution to make it look good without hard-coding the height, or change the layout to avoid this situation */}
                            <Button color='primary' type='submit' className='w-100 text-nowrap' style={{ minHeight: '38px' }}>
                                {/*  ^^^ removed the disabled since we implemented the cancel call, (see onSubmit), allows users to change their query without waiting for the original one to complete disabled={spordleTable.state.loadingState === 'loading'} */}
                                <i className='fas fa-search mr-1' /><Translate id='misc.search' />
                            </Button>
                        </Col>
                        <Col md="3" lg='3'>
                            <FormGroup>
                                <Label for="memberType" className="text-muted"><Translate id='members.search.memberAdvanceSearch.filters.type' /></Label>
                                <FormikSelect
                                    disabled={!!formik.values.unique_identifier || !!formik.values.noMemberType}
                                    isLoading={!memberTypes}
                                    multi
                                    clearable
                                    search={false}
                                    name='memberType'
                                    id='memberType'
                                    renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                    options={memberTypes ?? []}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Collapse isOpen={moreFiltersOpen}>
                        <FormikGroup rowProps={{ form: true }} name='searchType'>
                            <Col md="6" lg='6'>
                                <FormGroup>
                                    <div className='mb-1'>
                                        <FormikRadioButton value='' name='searchType' id='searchType-none' label='members.search.memberAdvanceSearch.filters.currentOrg' disabled={!!formik.values.unique_identifier} />
                                    </div>
                                    <div className='mb-1'>
                                        <FormikRadioButton value='subOrgs' name='searchType' id='searchType-subOrgs' label='members.search.memberAdvanceSearch.filters.allSubOrgs' disabled={!!formik.values.unique_identifier} />
                                    </div>
                                    <div>
                                        <FormikRadioButton value='all' name='searchType' id='searchType-all' label='members.search.memberAdvanceSearch.filters.all' disabled={!!formik.values.unique_identifier} />
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md="6" lg='6'>
                                {/* <HasAccessTo componentCode='members' componentPermissionCode='member_background_check'>
                                    <FormikCheckedButton label='members.search.memberAdvanceSearch.filters.onlyCrc' id="filter-crc-expired" name="crcExpired" className="mb-1" />
                                </HasAccessTo> */}
                                <HasAccessTo componentCode='members'>
                                    <div className='d-flex'>
                                        <FormikCheckedButton
                                            disabled={!!formik.values.unique_identifier}
                                            label='members.search.memberAdvanceSearch.filters.noMemberType'
                                            id="filter-noMemberType"
                                            name="noMemberType"
                                            className="mb-1"
                                            onChange={(event) => {
                                                formik.setFieldValue('noMemberType', event.target.checked)
                                                if(event.target.checked)
                                                    formik.setFieldValue('memberType', [])
                                            }}
                                        />
                                        <Tooltip
                                            withArrow
                                            wrapLines
                                            width={400}
                                            label={<Translate id="members.search.memberAdvanceSearch.filters.noMemberType.tip" />}
                                        >
                                            <i className='ml-1 mdi mdi-information-outline text-primary clickable' />
                                        </Tooltip>
                                    </div>
                                </HasAccessTo>
                                <HasAccessTo componentCode='members'>
                                    <FormikCheckedButton
                                        disabled={!!formik.values.unique_identifier}
                                        label='members.search.memberAdvanceSearch.filters.missingRequiredFields'
                                        id="filter-missingRequiredFields"
                                        name="with_missing_required_field"
                                        className="mb-1"
                                    />
                                </HasAccessTo>
                                <HasAccessTo componentCode='members' componentPermissionCode='members_address'>
                                    <FormikCheckedButton
                                        disabled={!!formik.values.unique_identifier}
                                        label='members.search.memberAdvanceSearch.filters.onlyUnconfirmedAddress'
                                        id="filter-unconfirmedAddress"
                                        name="with_unconfirmed_address"
                                        className="mb-1"
                                    />
                                </HasAccessTo>
                                <HasAccessTo componentCode='members' componentPermissionCode='members_address'>
                                    <FormikCheckedButton
                                        disabled={!!formik.values.unique_identifier}
                                        label='members.search.memberAdvanceSearch.filters.onlyNeedAddressDocument'
                                        id="filter-needAddressDocument"
                                        name="need_address_document"
                                        className="mb-1"
                                    />
                                </HasAccessTo>
                                <FormikCheckedButton
                                    disabled={!!formik.values.unique_identifier}
                                    label='members.search.memberAdvanceSearch.filters.withoutProfilePicture'
                                    id="filter-withoutProfilePicture"
                                    name="withoutProfilePicture"
                                    className="mb-1"
                                />
                                <FormikCheckedButton
                                    disabled={!!formik.values.unique_identifier}
                                    label='members.search.memberAdvanceSearch.filters.hasOutstandingBalance'
                                    id="filter-hasOutstandingBalance"
                                    name="hasOutstandingBalance"
                                    className="mb-2"
                                />
                            </Col>
                        </FormikGroup>
                        <Row form>
                            <Col md='12' lg='12'>
                                <Row form>
                                    <Col xs='12' md='6' lg='3'>
                                        <FormGroup>
                                            <MemberFieldLabel
                                                className="text-muted"
                                                hideDescription
                                                field="first_name"
                                                fallbackLabel={<Translate id='form.fields.firstName' />}
                                                for='firstName'
                                            />
                                            <FormikInputText id='firstName' name='firstName' trim disabled={!!formik.values.unique_identifier} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs='12' md='6' lg='3'>
                                        <FormGroup>
                                            <MemberFieldLabel
                                                className="text-muted"
                                                hideDescription
                                                field="last_name"
                                                fallbackLabel={<Translate id='form.fields.lastName' />}
                                                for='lastName'
                                            />
                                            <FormikInputText id='lastName' name='lastName' trim disabled={!!formik.values.unique_identifier} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs='12' md='6' lg='3'>
                                        <FormGroup>
                                            <MemberFieldLabel
                                                className="text-muted"
                                                hideDescription
                                                field="email"
                                                fallbackLabel={<Translate id='form.fields.email' />}
                                                for='email'
                                            />
                                            <FormikInputText id='email' name='email' trim disabled={!!formik.values.unique_identifier} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" lg='3'>
                                        <FormGroup>
                                            <Label for="member_status" className="text-muted"><Translate id='members.search.memberAdvanceSearch.filters.memberStatus' /></Label>
                                            <FormikSelect
                                                clearable
                                                name='member_status'
                                                id='member_status'
                                                disabled={!!formik.values.unique_identifier}
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'FILTER':
                                                        case 'CDM':
                                                            return membersContext.getStatuses()
                                                                .then((statuses) => {
                                                                    return (statuses.map((status) => ({
                                                                        id: status.member_status_id,
                                                                        value: status.code,
                                                                        label: status.name,
                                                                        active: status.active,
                                                                        i18n: status.i18n,
                                                                    })).filter((status) => {
                                                                        if(status.value === 'INELIGIBLE' && !rolesContext.canDoAction('READ', 'members', 'manage_ineligible_members')){
                                                                            return false;
                                                                        }
                                                                        return true;
                                                                    }))
                                                                })
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                renderOption={({ option }) => {
                                                    return <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                }}
                                                searchKeys={[
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                    'label',
                                                ]}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md='6'>
                                <Row form>
                                    <Col xs='6'>
                                        <FormGroup>
                                            <div className="text-muted d-flex align-items-center mb-2">
                                                <MemberFieldLabel
                                                    className="text-muted mb-0 mr-1"
                                                    hideDescription
                                                    field="birthdate"
                                                    fallbackLabel={<Translate id='form.fields.dateOfBirth' />}
                                                    for='dateOfBirth'
                                                /> - <Translate id='members.search.memberAdvanceSearch.filters.from' />
                                            </div>
                                            <FormikDateTime
                                                name='startDate' timeFormat={false} utc
                                                inputProps={{ disabled: !!formik.values.unique_identifier || formik.values.minAge !== '' || formik.values.maxAge !== '' }}
                                                isValidDate={(current) => moment(current).isBefore()}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs='6'>
                                        <FormGroup>
                                            <Label for="endDate" className="text-muted"><Translate id='members.search.memberAdvanceSearch.filters.to' /></Label>
                                            <FormikDateTime
                                                name='endDate' timeFormat={false} utc
                                                inputProps={{ disabled: !!formik.values.unique_identifier || formik.values.minAge !== '' || formik.values.maxAge !== '' }}
                                                isValidDate={(current) => moment(current).isAfter(moment(formik.values.startDate).subtract(1, 'days'))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md='6'>
                                <Row form>
                                    <Col xs='6'>
                                        <FormGroup>
                                            <Label for="minAge" className="text-muted"><Translate id='members.search.memberAdvanceSearch.filters.minAge' /></Label>
                                            <FormikInputNumber
                                                disabled={!!formik.values.unique_identifier}
                                                name='minAge' allowNegative={false} decimalScale={0}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs='6'>
                                        <FormGroup>
                                            <Label for="maxAge" className="text-muted"><Translate id='members.search.memberAdvanceSearch.filters.maxAge' /></Label>
                                            <FormikInputNumber
                                                disabled={!!formik.values.unique_identifier}
                                                name='maxAge' allowNegative={false} decimalScale={0}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md='6'>
                                <FormGroup>
                                    <MemberFieldLabel
                                        className="text-muted"
                                        hideDescription
                                        field="gender"
                                        fallbackLabel={<Translate id='members.search.memberAdvanceSearch.filters.gender' />}
                                        for='gender'
                                    />
                                    <FormikSelect
                                        multi
                                        clearable
                                        search={false}
                                        name='gender'
                                        id='gender'
                                        disabled={!!formik.values.unique_identifier}
                                        placeholder='members.search.memberAdvanceSearch.filters.gender.placeholder'
                                        loadingStatus='success'
                                        defaultData={organizationContext.settings.gender_selection?.value?.map((gender) => ({
                                            value: gender,
                                            label: <GenderLabel gender={gender} toString />,
                                            translateLabel: false,
                                        })) || []}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className='d-flex align-items-center mb-3'>
                            <div className='border-top flex-grow-1' />
                            <div className='px-3 font-medium'><Translate id='members.search.memberAdvanceSearch.filters.or' /></div>
                            <div className='border-top flex-grow-1' />
                        </div>
                        <Row form>
                            <Col md='4'>
                                <FormGroup>
                                    <Label for='unique_identifier' className='text-muted'><Translate id='members.search.memberAdvanceSearch.filters.memberNumber' /></Label>
                                    <div className="search-wrapper">
                                        <FormikInputText id='unique_identifier' name='unique_identifier' trim type='search' />
                                        <button type='button' className={stringBuilder("reset-btn text-link search-clear", { 'd-none': !formik.values.unique_identifier })} onClick={() => formik.setFieldValue('unique_identifier', '')}><i className="mdi mdi-close" /></button>
                                    </div>
                                    <Collapse isOpen={!!formik.values.unique_identifier} className='small text-warning'>
                                        <i className='mdi mdi-alert-outline' /> <Translate id='members.search.memberAdvanceSearch.memberNumber.ignoredFilters' />
                                    </Collapse>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Collapse>

                    <div className="w-100 d-flex justify-content-end flex-wrap flex-md-nowrap">
                        <SavedSearchCue className="order-1 mt-3 mt-md-0 order-md-0 mr-auto w-100 w-md-auto" savedSearch={savedSearch} clearSavedSearch={rerenderFormik} />
                        <Fade in={JSON.stringify(originalValues) !== JSON.stringify(formik.values)}>
                            <button type='button' className='reset-btn ml-2 mr-3 text-danger font-12' onClick={rerenderFormik}>
                                <Translate id='members.search.memberAdvanceSearch.resetFilters' />
                            </button>
                        </Fade>
                        <button type='button' onClick={toggleMoreFilters} className='reset-btn ml-2 text-link font-12'>
                            <Translate id={`members.search.memberAdvanceSearch.${moreFiltersOpen ? 'less' : 'more'}Filter`} /><i className={`ml-1 mdi ${moreFiltersOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'}`} />
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default AdvancedSearchSection;