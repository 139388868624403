import { FormikDateTime, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { formatSelectData } from '@spordle/spordle-select';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Badge, Col,
    FormGroup,
    Label, Row
} from "reactstrap";
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import FormikEditable from '../../../../../components/formik/FormikEditable';
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { fail, success } from '@spordle/toasts';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import { UtilsContext } from '../../../../../contexts/UtilsContext';
import { displayI18n, DisplayI18n } from '../../../../../helpers/i18nHelper';
import FormikEditableFile from '../../../../../components/formik/FormikEditableFile';
import { object, array, mixed } from 'yup';
import { compareAndFormatFiles } from '../../../../../components/uploader/uploadHelpers';
import { CrossFade } from '../../../discrimination/components/modal/DiscriminationAddModal';
import { DocumentTypesContext } from '../../../../../contexts/DocumentTypesContext';
import InlineCopy from '../../../../../components/inlineCopy/InlineCopy';

const TravelPermitSidepanelGeneral = ({ data, syncRows, createNewValues, settings }) => {
    const teamsContext = useContext(TeamsContext);
    const utilsContext = useContext(UtilsContext);
    const i18nContext = useContext(I18nContext);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ provinces, setProvinces ] = useState(null);

    const getTravelPermitStatus = () => {
        switch ((data.travel_permit_status?.name || '').toLowerCase()){
            case 'draft':
            case 'pending':
                return (
                    <Badge color='warning' className='mb-0'>
                        <DisplayI18n
                            field="name"
                            defaultValue={data.travel_permit_status?.name}
                            i18n={data.travel_permit_status?.i18n}
                        />
                    </Badge>
                )
            case 'approved':
                return (
                    <Badge color='success' className='mb-0'>
                        <DisplayI18n
                            field="name"
                            defaultValue={data.travel_permit_status?.name}
                            i18n={data.travel_permit_status?.i18n}
                        />
                    </Badge>
                )
            case 'declined':
                return (
                    <Badge color='danger' className='mb-0'>
                        <DisplayI18n
                            field="name"
                            defaultValue={data.travel_permit_status?.name}
                            i18n={data.travel_permit_status?.i18n}
                        />
                    </Badge>
                )
            case 'submitted':
                return (
                    <Badge color='info' className='mb-0'>
                        <DisplayI18n
                            field="name"
                            defaultValue={data.travel_permit_status?.name}
                            i18n={data.travel_permit_status?.i18n}
                        />
                    </Badge>
                )
            default:
                return data.travel_permit_status?.name || '-'
        }
    }

    const getProvinces = (countryId) => {
        if(countryId){
            setProvinces(null);
            utilsContext.getProvinces(countryId)
                .then((countries) => {
                    setProvinces(countries[0].sub_divisions?.map((state) => ({
                        value: state.code,
                        label: state.code,
                        i18n: state.i18n,
                    })) || [])
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        setProvinces([]);
                        console.error(error)
                    }
                })
        }
    };

    const handleFileClick = (file) => {
        return teamsContext.downloadTravelPermitAttachment(data.travel_permit_id, file.travel_permit_attachement_id)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    };

    const attachmentsOnSubmit = async(attachments, documentTypeId) => {
        setIsLoading(true);
        const { toDelete, toCreate } = compareAndFormatFiles(data.attachments?.filter((att) => documentTypeId ? att.document_type?.document_type_id === documentTypeId : att.document_type == null) || [], attachments.attachments);
        if(toDelete?.length > 0){
            await Promise.all(
                toDelete.map((att) => (
                    teamsContext.deleteTravelPermitAttachment(data.travel_permit_id, att.travel_permit_attachement_id)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                            }
                        })
                )),
            )
            // syncRows(createNewValues({ attachments: [ ...toKeep ] })); // necessary in case of error later
        }

        if(toCreate?.length > 0 && toCreate?.[0]?.length > 0){
            await Promise.all(
                toCreate.map((subArr) => {
                    return teamsContext.createTravelPermitAttachment(
                        data.travel_permit_id,
                        subArr,
                        documentTypeId,
                    ).catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                        }
                    });
                }),
            );
        }

        const travelPermit = await teamsContext.getTravelPermit(data.team?.team_id, data.travel_permit_id)
            .then((travelPermit) => travelPermit)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

        syncRows({ ...travelPermit });

        setIsLoading(false);
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <div>
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <div className="text-muted"><Translate id='travelPermit.sidePanelGeneral.TeamNumber' /></div>
                            <InlineCopy className="font-medium mb-3" toCopy={data.team.unique_identifier}>
                                <Link to={"/teams/profile/" + data.team.team_id}>
                                    {data.team.unique_identifier ?
                                        <>#{data.team.unique_identifier}<i className="mdi mdi-chevron-right" /></>
                                        : '-'}
                                </Link>
                            </InlineCopy>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='travel_permit_status_id' className='text-muted mb-0'><Translate id='travelPermit.sidePanelGeneral.status' /></Label>
                            <div>
                                {getTravelPermitStatus()}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <div className="text-muted"><Translate id='transfer.createdBy' /></div>
                            <div className="font-medium text-dark">{data.created_by.name} {data.created_by.family_name}</div>
                            <div className="small text-muted" style={{ lineHeight: 1 }}>{data.created_by.email}</div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className="mt-2">
                <div className="h5 font-bold">
                    <Translate id='travelPermit.sidePanelGeneral.location' />
                </div>
                <Row>
                    <Col sm="12">
                        <FormGroup>
                            <Label for='location_name' className='text-muted mb-0'><Translate id='travelPermit.sidePanelGeneral.arena' /></Label>
                            <FormikEditable
                                id='location_name'
                                initialValues={{
                                    location_name: data?.location_name,
                                }}
                                onSubmit={(values) => {
                                    if(values.location_name !== data?.location_name){
                                        setIsLoading(true);
                                        teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                            .then(() => {
                                                const newValues = createNewValues(values);
                                                syncRows(newValues);
                                                success({ msg: 'travelPermit.toast.success' });
                                                setIsLoading(false);
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                                setIsLoading(false);
                                            })
                                    }

                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium text-dark'>{data.location_name}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='location_name' name='location_name' />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='city' className='text-muted mb-0'><Translate id='travelPermit.sidePanelGeneral.city' /></Label>
                            <FormikEditable
                                id='city'
                                initialValues={{
                                    city: data?.city || '',
                                }}
                                onSubmit={(values) => {
                                    if(values.city !== data?.city){
                                        setIsLoading(true);
                                        teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                            .then(() => {
                                                const newValues = createNewValues(values);
                                                syncRows(newValues);
                                                success({ msg: 'travelPermit.toast.success' });
                                                setIsLoading(false);
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                                setIsLoading(false);
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className="font-medium text-dark">{data?.city ? data.city : '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='city' name='city' />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                </Row>
                <div className='mb-3'>
                    <Label for='country_province_code' className='text-muted mb-0'><Translate id='travelPermit.sidePanelGeneral.countryProvince' /></Label>
                    <FormikEditable
                        id='country_province_code'
                        initialValues={{
                            country_code: data.country_code,
                            province_code: data.province_id,
                        }}
                        onSubmit={(values) => {
                            if(values.country_code !== data.country_code || values.province_code !== data.province_id){
                                setIsLoading(true);
                                teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                    .then(() => {
                                        const newValues = createNewValues({ country_code: values.country_code, province_id: values.province_code });
                                        syncRows(newValues);
                                        success({ msg: 'travelPermit.toast.success' });
                                        setIsLoading(false);
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                        setIsLoading(false);
                                    })
                            }
                        }}
                    >
                        {(isEditing, _options, formik) => {
                            if(!isEditing){
                                return (
                                    <div className="font-medium text-dark">{data.country_code} - {data.province_id}</div>
                                )
                            }
                            return (
                                <Row form>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <FormikSelect
                                                autoFocus
                                                name="country_code"
                                                id="country_code"
                                                dataIndex="code"
                                                search
                                                searchKeys={[
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                ]}
                                                onOptionSelected={([ select ], spordleSelect) => {
                                                    const country = spordleSelect.getSpordleTable().getData().find((d) => d.code === select);

                                                    if(country){
                                                        formik.setFieldValue('province_code', '');
                                                        getProvinces(country.country_id);
                                                    }

                                                }}
                                                renderOption={(opt) => (
                                                    !opt.option.isGroup ?
                                                        <DisplayI18n
                                                            field="name"
                                                            defaultValue={opt.option.label}
                                                            i18n={opt.option.i18n}
                                                        />
                                                        :
                                                        <Translate id={opt.option.label} />
                                                )}
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            return utilsContext.getCountries()
                                                                .then((countries) => {
                                                                    getProvinces(countries.find((c) => (c.code === data.country_code))?.country_id);
                                                                    return formatSelectData(
                                                                        countries,
                                                                        {
                                                                            getValue: (country) => country.code,
                                                                            getGroupId: (country) => [ "CA", "US" ].includes(country.code) ? 'suggested' : undefined,
                                                                            newGroupIndexes: {
                                                                                0: {
                                                                                    code: 'SuggestedGroup',
                                                                                    groupId: "suggested",
                                                                                    label: 'misc.select.suggested',
                                                                                    isGroup: true,
                                                                                },
                                                                            },
                                                                        },
                                                                    );
                                                                });
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <FormikSelect
                                                name='province_code'
                                                id='province_code'
                                                renderOption={(opt) => <DisplayI18n field='name' defaultValue={opt.option.label} i18n={opt.option.i18n} />}
                                                search
                                                searchKeys={[
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                ]}
                                                isLoading={!provinces}
                                                options={provinces ?? []}
                                                isDisabled={!formik.values.country_code}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )

                        }}
                    </FormikEditable>
                </div>
            </div>
            <div className="mt-2">
                <div className="h5 font-bold"><Translate id='travelPermit.sidePanelGeneral.dates' /></div>
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='start_date' className='text-muted mb-0'><Translate id='travelPermit.sidePanelGeneral.startDate' /></Label>
                            <FormikEditable
                                id='start_date'
                                initialValues={{
                                    start_date: data?.start_date,
                                }}
                                validationSchema={object().shape({
                                    start_date: mixed().required(<Translate id='travelPermit.addModal.startDateRequired' />)
                                        .test({
                                            name: 'start_date_format',
                                            message: <Translate id='organization.settings.sidePanelOrganizationPeriods.startDate.format' />,
                                            test: moment.isMoment,
                                        }),
                                })}
                                onSubmit={(values) => {
                                    if(values.start_date !== data.start_date && moment(values.start_date).isBefore(moment(data.end_date))){
                                        setIsLoading(true);
                                        teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, { start_date: values.start_date.format('YYYY-MM-DD') })
                                            .then(() => {
                                                const newValues = createNewValues(values);
                                                syncRows(newValues);
                                                success({ msg: 'travelPermit.toast.success' });
                                                setIsLoading(false);
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                                setIsLoading(false);
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className="font-medium text-dark">{moment(data.start_date).format('YYYY-MM-DD')}</div>
                                        )
                                    }
                                    return (
                                        <FormikDateTime name='start_date' id='start_date' timeFormat={false} />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for='end_date' className='text-muted mb-0'><Translate id='travelPermit.sidePanelGeneral.endDate' /></Label>
                            <FormikEditable
                                id='end_date'
                                initialValues={{
                                    end_date: data?.end_date,
                                }}
                                validationSchema={object().shape({
                                    end_date: mixed().test({
                                        name: 'endDateAfter',
                                        message: <Translate id='organization.settings.sidePanelOrganizationPeriods.endDate.after' />,
                                        test: (endDate) => {
                                            return endDate ? moment.isMoment(endDate) && endDate.isAfter(moment(data?.start_date)) : true
                                        },
                                    }),
                                })}
                                onSubmit={(values) => {
                                    if(!values.end_date || (values.end_date !== data.end_date && moment(values.end_date).isAfter(moment(data.start_date)))){
                                        setIsLoading(true);
                                        teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, { end_date: values.end_date.format('YYYY-MM-DD') })
                                            .then(() => {
                                                const newValues = createNewValues(values);
                                                syncRows(newValues);
                                                success({ msg: 'travelPermit.toast.success' });
                                                setIsLoading(false);
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                                setIsLoading(false);
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className="font-medium text-dark">{data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : '-'}</div>
                                        )
                                    }
                                    return (
                                        <FormikDateTime name='end_date' id='end_date' timeFormat={false} className='date-picker-left' />
                                    )

                                }}
                            </FormikEditable>
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Label for='note' className='text-muted mb-0'><Translate id='travelPermit.sidePanelGeneral.note' /></Label>
                    <FormikEditable
                        id='note'
                        initialValues={{
                            note: data?.note,
                        }}
                        onSubmit={(values) => {
                            if(values.note !== data.note){
                                setIsLoading(true);
                                teamsContext.updateTeamTravelPermitPartial(data.travel_permit_id, data.team.team_id, values)
                                    .then(() => {
                                        const newValues = createNewValues(values);
                                        syncRows(newValues);
                                        success({ msg: 'travelPermit.toast.success' });
                                        setIsLoading(false);
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                        setIsLoading(false);
                                    })
                            }
                        }}
                    >
                        {(isEditing) => {
                            if(!isEditing){
                                return (
                                    <div className='font-medium text-dark'>{data?.note ? data.note : '-'}</div>
                                )
                            }
                            return (
                                <FormikInputText id='note' name='note' />
                            )

                        }}
                    </FormikEditable>
                </FormGroup>
            </div>

            <TravelPermitAttachmentsFromSettings team={data.team} settings={settings} attachments={data.attachments} handleFileClick={handleFileClick} attachmentsOnSubmit={attachmentsOnSubmit} />

            <div className="mt-2">
                <div className="h5 font-bold"><Translate id='travelPermit.addModal.additionalAttachments' /></div>
                <FormGroup>
                    <FormikEditableFile
                        id='travelPermits-attachments'
                        dropzoneProps={{
                            multiple: true,
                            accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx",
                        }}
                        name="attachments"
                        initialValues={{
                            attachments: data.attachments.filter((att) => att.document_type === null),
                        }}
                        validationSchema={object().shape({
                            attachments: array(),
                        })}
                        onFileClick={handleFileClick}
                        onSubmit={(att) => attachmentsOnSubmit(att, null)}
                        showCreatedAt
                    />
                </FormGroup>
            </div>
        </OverlayLoader>
    );
}


const TravelPermitAttachmentsFromSettings = ({ team, settings, attachments, handleFileClick, attachmentsOnSubmit }) => {
    const { getDocumentTypes } = useContext(DocumentTypesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const [ documentTypes, setDocumentTypes ] = useState(false);

    useEffect(() => {
        const settingDocumentTypes = settings?.attachment_for_travel_permit?.value || [];
        getDocumentTypes(team.organisation.organisation_id)
            .then((documents) => {
                const docs = settingDocumentTypes.map((documentTypeId) => (
                    documents.find(({ document_type_id }) => document_type_id === documentTypeId)
                )).filter((document) => !!document)
                setDocumentTypes(docs)
            })
            .catch(console.error);
    }, [ team.organisation.organisation_id, settings?.attachment_for_travel_permit?.value?.[0] ])

    return (
        <CrossFade isVisible={Array.isArray(documentTypes) && documentTypes.length > 0} mountOnEnter unmountOnExit>
            <div className="h5 font-bold"><Translate id='travelPermit.addModal.attachments' /></div>
            {documentTypes && documentTypes.map((documentType) => (
                <FormGroup key={documentType.document_type_id}>
                    <Label for={'documentType_' + documentType.document_type_id}><DisplayI18n field='name' defaultValue={documentType.name} i18n={documentType.i18n} /></Label>
                    <FormikEditableFile
                        id='travelPermits-attachments'
                        dropzoneProps={{
                            multiple: true,
                            accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx",
                        }}
                        name="attachments"
                        initialValues={{
                            attachments: attachments ? attachments.filter((att) => att.document_type?.document_type_id === documentType.document_type_id) : [],
                        }}
                        validationSchema={object().shape({
                            attachments: array(),
                        })}
                        onFileClick={handleFileClick}
                        onSubmit={(att) => attachmentsOnSubmit(att, documentType.document_type_id)}
                        showCreatedAt
                    />
                    {documentType.description &&
                        <div
                            className='small text-muted'
                            dangerouslySetInnerHTML={{
                                __html: displayI18n("description", documentType.i18n, documentType.description, getGenericLocale()),
                            }}
                        />
                    }
                </FormGroup>
            ))}
        </CrossFade>
    )
};

export default TravelPermitSidepanelGeneral;