import { FormikDateTime, FormikSelect } from '@spordle/formik-elements';
import Translate, { DateFormat } from '@spordle/intl-elements';
import moment from 'moment';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';
import useSWR from 'swr';
import { array, mixed, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import arrowBottom from '../../../../assets/images/icons/movement/arrowBottom.svg';
import { I18nContext } from '../../../../contexts/I18nContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { RolesContext } from '../../../../contexts/RolesContext';
import { TransferContext } from '../../../../contexts/TransferContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { getActualStatus, getTransferStatusBadge } from '../../../../helpers/transferHelper';
import withContexts from '../../../../helpers/withContexts';
import FormikEditable from '../../../formik/FormikEditable';
import FormikEditableFile from '../../../formik/FormikEditableFile';
import DisplayOrganization from '../../../organization/DisplayOrganization';
import { fail, success } from '@spordle/toasts';
import { compareAndFormatFiles } from '../../../uploader/uploadHelpers';
import TransferRequestGraph from '../TransferRequestGraph';
import TransferDuration from './editables/TransferDuration';
import TransferInvoice from './editables/TransferInvoice';
import TransferReasonType from './editables/TransferReasonType';
import TransferTargetTeam from './editables/TransferTargetTeam';
import TransferTeamMemberStatus from './editables/TransferTeamMemberStatus';

const TransferTab = ({ transfer, onTransfer, from, setLoading, transferLoading, updateData }) => {
    const memberContext = useContext(MembersContext);
    const transferContext = useContext(TransferContext);
    const rolesContext = useContext(RolesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const intl = useIntl();

    const handleError = (error) => {
        if(!AxiosIsCancelled(error.message)){
            console.error(error.message)
            fail({
                msg: 'misc.error',
                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                skipInfoTranslate: true,
            })
        }
    }

    const handleFileClick = (file) => {
        return memberContext.downloadMemberTransferAttachment(transfer.member.member_id, transfer.member_transfer_id, file.transfer_attachment_id);
    };

    const { data: transferStates, error: statesError, isValidating: isValidatingStates } = useSWR(
        [ 'getMemberTransfersStates', transfer.member_transfer_id ],
        () => memberContext.getMemberTransfersStates(transfer.member.member_id, transfer.member_transfer_id)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }),
        {
            onError: handleError,
        },
    );

    const { data: transferWorkflow, error: workflowError, isValidating: isValidatingWorkflow } = useSWR(
        [ 'getMemberTransfersWorkflow', transfer.member_transfer_id ],
        () => memberContext.getMemberTransfersWorkflow(transfer.member.member_id, transfer.member_transfer_id)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }),
        {
            onError: handleError,
        },
    );

    const { data: transferStatus, isValidating: statusIsValidating } = useSWR(
        [ 'getTransferStatus', transfer.transfer_type ],
        () => transferContext.getTransferStatus({ active: 1, is_international: 1 }, false, true)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }),
        {
            fallbackData: [],
            revalidateOnMount: true,
            onError: handleError,
        },
    );

    const canEditTransfer = rolesContext.canDoAction('EDIT', 'members', 'members_request_transfer') && !transfer.expired_share && ![ "COMPLETED", "REFUSED" ].includes(getActualStatus(transfer));
    const canDeleteTransferDocument = rolesContext.canDoAction('DELETE', 'members', 'members_request_transfer') || rolesContext.canDoAction('DELETE', 'members', 'members_request_transfer_usa') || rolesContext.canDoAction('DELETE', 'members', 'members_request_transfer_international');

    return (
        <div className="p-3">
            <Row form>
                <Col sm="6">
                    <div className="mb-3">
                        <div className="text-muted"><Translate id='transfer.status' /></div>
                        <FormikEditable
                            id='transfer-status'
                            noConfirmation
                            // disabled={!canEditTransfer && transfer.transfer_type !== 'INTERNATIONAL'}
                            disabled // always keep disabled because this goes against workflow
                            readOnly={transfer.transfer_request_state === 'CLOSED'}
                            initialValues={{
                                status: transfer.transfer_status.transfer_status_id,
                            }}
                            validationSchema={object().shape({
                                status: string().required(),
                            })}
                            onSubmit={(values) => {
                                if(values.status !== transfer.transfer_status.transfer_status_id){
                                    setLoading(true)
                                    memberContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                        transfer_status_id: values.status,
                                    })
                                        .then(() => {
                                            updateData();
                                            success();
                                        })
                                        .catch(handleError)
                                        .finally(() => setLoading(false))
                                }
                            }}
                        >
                            {(isEditing, options) => {
                                if(!isEditing){
                                    return (
                                        <div className="font-medium text-dark">
                                            {transfer.expired_share ?
                                                <Badge color='light'><Translate id="transfer.table.header.status.EXPIRED_SHARE" /></Badge>
                                                :
                                                getTransferStatusBadge(transfer.transfer_status, transfer.previous_action_done)
                                            }
                                        </div>
                                    )
                                }
                                return (
                                    <FormikSelect
                                        id='status' name='status' menuIsDefaultOpen
                                        isLoading={statusIsValidating}
                                        onOptionSelected={() => {
                                            options.stopEditing();
                                        }}
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        autoFocus backspaceRemovesValue={false}
                                        options={transferStatus ?
                                            transferStatus.map((status) => ({
                                                value: status.transfer_status_id,
                                                i18n: status.i18n,
                                                label: status.name,
                                            }))
                                            :
                                            []
                                        }
                                    />
                                )

                            }}
                        </FormikEditable>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="mb-3">
                        <div className="text-muted"><Translate id='transfer.requestedOn' /></div>
                        <div className="font-medium text-dark"><DateFormat format={'YYYY-MM-DD h:mm A'} value={transfer.external_id ? transfer.sent_date : transfer.created_at} utc={!!transfer.external_id} /></div>
                    </div>
                </Col>
                {transfer.transfer_duration && from === 'memberProfile' &&
                    <Col sm="6">
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='transfer.type' /></div>
                            <div className="font-medium text-dark">
                                <DisplayI18n
                                    field='name'
                                    defaultValue={transfer.transfer_duration.name}
                                    i18n={transfer.transfer_duration.i18n}
                                />
                            </div>
                        </div>
                    </Col>
                }
                {(transferLoading || transfer.identity) &&
                    <Col sm="6">
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='transfer.createdBy' /></div>
                            {transferLoading ?
                                <Skeleton height={32} />
                                :
                                <>
                                    <div className="font-medium text-dark">{transfer.identity.name} {transfer.identity.family_name}</div>
                                    <div className="small text-muted" style={{ lineHeight: 1 }}>{transfer.identity.email}</div>
                                </>
                            }
                        </div>
                    </Col>
                }
            </Row>
            {transfer.actualStatus === 'REFUSED' &&
                <div className="mb-3">
                    <div className="text-danger"><Translate id='transfer.sidepanel.refuseDetail' /></div>
                    <div className="p-2 bg-light border rounded">
                        {transferStates?.find((transferState) => transferState.workflow_step.step_action === 'DECLINE')?.details}
                    </div>
                </div>
            }

            {from === 'tasks' &&
                <Card className="card-shadow">
                    <CardBody>
                        <div className="border-bottom pb-1 mb-3 d-flex justify-content-between">
                            <div className='h5 font-bold mb-0 mr-2'>
                                <Translate id='transfer.title' />
                            </div>
                            <TransferDuration canEditTransfer={canEditTransfer} setLoading={setLoading} transfer={transfer} updateData={updateData} />
                        </div>
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='transfer.currentOrg' /></div>
                            <div className="font-medium text-dark">
                                <DisplayOrganization organisation={transfer.organisation}>
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={transfer.organisation.organisation_name}
                                        i18n={transfer.organisation.i18n}
                                    />
                                </DisplayOrganization>
                            </div>
                        </div>
                        <div className="mb-3">
                            <img src={arrowBottom} alt={intl.formatMessage({ id: 'transfer.to.alt' })} height="20" />
                            <span>
                                {transfer.workflow ?
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={transfer.workflow.name}
                                        i18n={transfer.workflow.i18n}
                                    />
                                    : transfer.transfer_type ?
                                        <Translate id={`transfer.type.${transfer.transfer_type}`} />
                                        : null}
                            </span>
                        </div>
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='transfer.newOrg' /></div>
                            {transfer.target_organisation ?
                                <div className="font-medium text-dark">
                                    <DisplayOrganization organisation={transfer.target_organisation}>
                                        <DisplayI18n
                                            field='name'
                                            defaultValue={transfer.target_organisation.organisation_name}
                                            i18n={transfer.target_organisation.i18n}
                                        />
                                    </DisplayOrganization>

                                </div>
                                :
                                <div>-</div>
                            }
                        </div>
                    </CardBody>
                </Card>
            }

            <div className="mb-3">
                {/** TODO: HARDCODED */}
                {transfer?.transfer_direction?.name == 'Incoming' ?
                    <div className="text-muted"><Translate id='transfer.fromTeam' /></div>
                    :
                    <div className="text-muted"><Translate id='transfer.targetTeam' /></div>
                }
                <div className="font-medium text-dark">
                    <TransferTargetTeam canEditTransfer={false} handleError={handleError} setLoading={setLoading} transfer={transfer} updateData={updateData} transferLoading={transferLoading} />
                </div>
            </div>

            {transfer.target_position &&
                <div className="mb-3">
                    <div className="text-muted"><Translate id='transfer.targetPosition' /></div>
                    <div className="font-medium text-dark">
                        <DisplayI18n defaultValue={transfer.target_position.name} field='name' i18n={transfer.target_position.i18n} />
                    </div>
                </div>
            }

            {(!!transfer.target_team?.team_id) &&
                <div className="mb-3">
                    <div className="text-muted"><Translate id='teams.profile.roster.table.affiliated' /> / <Translate id='teams.profile.roster.table.tryout' /> / <Translate id='teams.profile.roster.table.imported' /></div>
                    <div className="font-medium text-dark">
                        <TransferTeamMemberStatus canEditTransfer={false} handleError={handleError} setLoading={setLoading} transfer={transfer} updateData={updateData} transferLoading={transferLoading} />
                    </div>
                </div>
            }

            <div className="mb-3">
                <div className="text-muted"><Translate id='transfer.invoice' /></div>
                <div className="font-medium text-dark">
                    <TransferInvoice canEditTransfer={canEditTransfer} handleError={handleError} setLoading={setLoading} transfer={transfer} updateData={updateData} transferLoading={transferLoading} />
                </div>
            </div>

            <div className="mb-3">
                <div className="text-muted"><Translate id='transfer.reason' /></div>
                <div className="font-medium text-dark">
                    <TransferReasonType canEditTransfer={canEditTransfer} transfer={transfer} setLoading={setLoading} updateData={updateData} />
                </div>
            </div>

            {transfer.transfer_details &&
                <div className="mb-3">
                    <div className="text-muted"><Translate id='transfer.detail' /></div>
                    <div className="font-medium text-dark">
                        {transfer.transfer_details}
                    </div>
                </div>
            }

            <div className="mb-3">
                <div className="text-muted"><Translate id='transfer.sharedExpiration' /></div>
                <div className="font-medium text-dark">
                    <FormikEditable
                        id='transfer-shared-expiration'
                        disabled={!canEditTransfer}
                        readOnly={transfer.transfer_request_state === 'CLOSED'}
                        initialValues={{
                            expirationDate: transfer.shared_expiration_date ? moment(transfer.shared_expiration_date) : '',
                        }}
                        validationSchema={object().shape({
                            expirationDate: mixed().isDate(<Translate id='form.validation.date.format' />),
                        })}
                        onSubmit={(values) => {
                            const formattedSharedExpirationDate = transfer.shared_expiration_date ? moment(transfer.shared_expiration_date).format('YYYY-MM-DD') : '';
                            const formattedExpirationDate = moment.isMoment(values.expirationDate) ? values.expirationDate.format('YYYY-MM-DD') : '';
                            if(formattedExpirationDate != formattedSharedExpirationDate){
                                setLoading(true);
                                return memberContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                                    shared_expiration_date: formattedExpirationDate,
                                })
                                    .then(async() => {
                                        await updateData({ // Updating data without having to make the api call
                                            ...transfer,
                                            shared_expiration_date: formattedExpirationDate,
                                        }, false)
                                        setLoading(false);
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                            setLoading(false);
                                        }
                                    })
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (transfer.shared_expiration_date ?
                                    <DateFormat value={transfer.shared_expiration_date} utc />
                                    : '-'
                                );
                            }
                            return (
                                <FormikDateTime name='expirationDate' timeFormat={false} />
                            )

                        }}
                    </FormikEditable>
                </div>
            </div>

            <div className="mb-3">
                <div className="text-muted"><Translate id='transfer.supportingDocument' /></div>
                {transferLoading ?
                    <Skeleton height={70} />
                    :
                    <FormikEditableFile
                        id='transfer-documents'
                        preventRemoveExistant={!canDeleteTransferDocument}
                        dropzoneProps={{
                            multiple: true,
                            accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx",
                        }}
                        name="files"
                        initialValues={{
                            files: transfer.attachments,
                        }}
                        validationSchema={object().shape({
                            files: array(),
                        })}
                        onFileClick={handleFileClick}
                        onSubmit={async({ files }) => {
                            setLoading(true);
                            const { toDelete, toCreate } = compareAndFormatFiles(transfer.attachments || [], files);

                            if(toDelete?.length > 0){
                                await Promise.all(
                                    toDelete.map((att) => (
                                        memberContext.deleteMemberTransferAttachment(transfer.member.member_id, transfer.member_transfer_id, att.transfer_attachment_id)
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                }
                                            })
                                    )),
                                )
                            }

                            if(toCreate?.length > 0 && toCreate?.[0]?.length > 0){
                                // separateFilesPerSize returns an array of subarray of files in which the total size does not exceed 14MB.
                                // Avoid php errors for the api
                                await Promise.all(
                                    toCreate.map((subArr) => {
                                        return memberContext.createMemberTransferAttachment(
                                            transfer.member.member_id,
                                            transfer.member_transfer_id,
                                            subArr,
                                        ).catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                            }
                                        });
                                    }),
                                );
                            }

                            setLoading(false);
                            onTransfer();
                        }}
                        showCreatedAt
                    />
                }
            </div>

            <TransferRequestGraph
                currentStep={transfer.current_step ? parseInt(transfer.current_step) : 0}
                transfer={transfer}
                states={transferStates}
                workflow={transferWorkflow}
                isLoading={!transferWorkflow || isValidatingStates || isValidatingWorkflow}
                hasError={statesError || workflowError}
            />
        </div>
    )
}

export default withContexts(OrganizationContext, MembersContext)(TransferTab);
