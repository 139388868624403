import { Row, Col, FormGroup, Label, Card, CardBody } from 'reactstrap';
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from '@spordle/intl-elements';

const CreateVenueStep2Group = ({ id, venueIndex, cloneVenue, deleteVenue }) => {
    return (
        <Card className="card-shadow mb-3">
            { venueIndex !== 0 &&
                <div className="position-absolute top-0 right-0 z-index-dropdown"> {/* z-index cause of label */}
                    <button type="button" onClick={deleteVenue} className="btn btn-link text-danger"><i className="fas fa-trash" /></button>
                </div>
            }
            <CardBody className="p-3">
                <Row form className={`create-venue-group`}>
                    <Col md="8">
                        <FormGroup>
                            <Label for={`${id}.venueName`} className="text-muted"><Translate id='organization.profile.venues.modals.components.createVenueStep2group.venue.name' /></Label>
                            <FormikInputText name={`${id}.venueName`} type="text" />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Label for={`${id}.venueAbbr`} className="text-muted"><Translate id='organization.profile.venues.modals.components.createVenueStep2group.venue.abbr' /></Label>
                            <FormikInputText name={`${id}.venueAbbr`} type="text" />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for={`${id}.venueType`} className="text-muted"><Translate id='organization.profile.venues.modals.components.createVenueStep2group.venue.type' /></Label>
                            <FormikSelect
                                id={`${id}.venueType_CreateVenueStep2Group`}
                                name={`${id}.venueType`}
                                placeholder="Venue Type"
                                loadingStatus='success'
                                defaultData={[
                                    { id: 'venueType1', value: 'type 1', label: 'All Types' },
                                    { id: 'venueType2', value: 'type 2', label: 'Ice Sheet' },
                                ]}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for={`${id}.venueSize`} className="text-muted"><Translate id='organization.profile.venues.modals.components.createVenueStep2group.venue.size' /></Label>
                            <FormikSelect
                                id={`${id}.venueSize`}
                                name={`${id}.venueSize`}
                                placeholder="Venue Size"
                                loadingStatus='success'
                                defaultData={[
                                    { id: 'venueSize1', value: 'size 1', label: 'All Sizes' },
                                    { id: 'venueSize2', value: 'size 2', label: 'NHL Standard' },
                                ]}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default CreateVenueStep2Group;