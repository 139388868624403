import TabRoutesPanes from '../../../components/subSidebar/TabRoutesPanes';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import catalogWaiversTabsRoutes from '../../../routes/tabRoutes/catalogWaiversTabsRoutes';

const Waivers = () => {

    return (
        <>
            <ViewHeaderV2
                title='catalog.waivers.title'
                breadcrumbsProps={{
                    disablePeriodSelect: true,
                }}
            />

            <div className="px-4">
                <TabRoutesPanes
                    routes={catalogWaiversTabsRoutes}
                    getComponentProps={(route) => {
                        switch (route.path){
                            case '/add':
                                return { mode: 'add' };
                            case '/edit':
                                return { mode: 'edit' };
                            default:
                                break;
                        }
                    }}
                />
            </div>
        </>
    );
}

export default Waivers;