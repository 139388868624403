export default{
    "settings.teamsSettings.list.duplicate" : "Dupliquer",

    "settings.teamsSettings.list.hasTeamsSettings" : "Possède des restrictions",
    "settings.teamsSettings.list.none"             : "Le formulaire est vide.",
    "settings.teamsSettings.list.none2"            : "Si vous souhaitez supprimer les restrictions pour cette catégorie, veuillez faire ainsi depuis la liste des catégories.",

    "settings.teamsSettings.configuration.teamConfiguration"        : "Paramètres de configuration d'équipe",
    "settings.teamsSettings.configuration.addLimit"                 : "Ajouter une limite",
    "settings.teamsSettings.configuration.noLimit"                  : "Aucune limite paramétrée.",
    "settings.teamsSettings.configuration.needPosGroup"             : "Veuillez sélectionner un groupe de positions.",
    "settings.teamsSettings.configuration.needPosition"             : "Veuillez sélectionner une position.",
    "settings.teamsSettings.configuration.needLimit"                : "Veuillez spécifier une limite",
    "settings.teamsSettings.configuration.count"                    : "Limite",
    "settings.teamsSettings.configuration.restriction"              : "Restriction #{nb}",
    "settings.teamsSettings.configuration.addRestriction"           : "Ajouter une restriction",
    "settings.teamsSettings.configuration.addRule"                  : "Ajouter une règle",
    "settings.teamsSettings.configuration.category.validation"      : "Veuillez sélectionner au moins une catégorie",
    "settings.teamsSettings.configuration.position.validation"      : "Veuillez sélectionner la position",
    "settings.teamsSettings.configuration.qualification.validation" : "Veuillez sélectionner la qualification",
    "settings.teamsSettings.configuration.count.validation"         : "Le minimum devrait être plus grand que 0",

    "settings.teamsSettings.cardTitle"                  : "Équipes",
    "settings.teamsSettings.division"                   : "Division",
    "settings.teamsSettings.categoryDivision.required"  : "Vous devez avoir au moins une division/catégorie",
    "settings.teamsSettings.division.required"          : "La division ou la catégorie est requise",
    "settings.teamsSettings.teamCategory"               : "Catégorie",
    "settings.teamsSettings.teamCategory.textWhen"      : "{count} catégories sélectionnées",
    "settings.teamsSettings.teamCategory.add"           : "Ajouter une division / catégorie",
    "settings.teamsSettings.restrictions"               : "Restrictions",
    "settings.teamsSettings.qualifications"             : "Qualifications",
    "settings.teamsSettings.settingName"                : "Nom",
    "settings.teamsSettings.qualification"              : "Qualification",
    "settings.teamsSettings.qualification.required"     : "Au moins une qualification est requise",
    "settings.teamsSettings.qualification.required.one" : "Au moins deux qualifications sont requises avec la restriction sélectionnée",
    "settings.teamsSettings.qualification.textWhen"     : "{count, plural, one {# qualification} other {# qualifications}}",
    "settings.teamsSettings.position"                   : "Position",
    "settings.teamsSettings.position.required"          : "La position est requise",
    "settings.teamsSettings.position.textWhen"          : "{count, plural, one {# position} other {# positions}}",
    "settings.teamsSettings.restriction"                : "Restriction",
    "settings.teamsSettings.restriction.all"            : "Toutes obligatoires",
    "settings.teamsSettings.restriction.one"            : "Au moins une obligatoire",
    "settings.teamsSettings.value"                      : "Valeur",
    "settings.teamsSettings.enforced"                   : "Forcé",
    "settings.teamsSettings.enforced.tooltip"           : "Un paramètre forcé empêchera l'utilisateur de faire une action tandis qu'un paramètre non-forcé ne donnera qu'un avertissement visible sur le profil de l'équipe.",
    "settings.teamsSettings.active"                     : "Actif",
    "settings.teamsSettings.active.tooltip"             : "Un paramètre inactif n'empêchera aucune action et n'affichera pas d'avertissement pour les équipes.",

    "settings.teamsSettings.deleteMessage" : "Supprimer ces restrictions d'équipes?",


    "settings.teamsSettings.required" : "Ce champ est requis",

    "settings.teamsSettings.errors.3366" : "Impossible de créer de nouvelles restrictions. L'une des divisions / catégories sélectionnées a déjà des restrictions.",
}