import SpordleTableProvider, { SpordleTableContext, SpordleTableView, useSpordleTable } from '@spordle/datatables';
import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import moment from 'moment';
import queryString from 'query-string';
import { useContext, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
    Button, Card,
    CardBody, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledButtonDropdown
} from "reactstrap";
import CardSectionTitle from '../../../../components/CardSectionTitle';
import CanDoAction from '../../../../components/permissions/CanDoAction';
import HasAccessTo from '../../../../components/permissions/HasAccessTo';
import OpenSidePanelOnSearch from '../../../../components/sidePanel/OpenSidePanelOnSearch';
import SpordlePanelTable from '../../../../components/sidePanel/SpordlePanel';
import EmptyLayout from '../../../../components/table/EmptyLayout';
// contexts
import { MembersContext } from '../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { displayI18n, DisplayI18n } from '../../../../helpers/i18nHelper';
import ClinicCategory from '../../../clinics/ClinicCategory';
import MemberProfileTransactionAdd from './components/AddModal/MemberProfileTransactionsAdd';
import MemberProfileTransactionsSidepanel from './components/Sidepanel/MemberProfileTransactionsSidepanel';
import { I18nContext } from '../../../../contexts/I18nContext';

const MemberProfileTransactions = (props) => {
    const membersContext = useContext(MembersContext);
    const { organisation_id } = useContext(OrganizationContext);

    const tableRef = useRef(null);

    const [ addModal, setAddModal ] = useState(false)
    const toggleAddModal = () => setAddModal(!addModal);

    const filterRegistrations = (item) => item.registration_fee != null;
    const filterOtherItems = (item) => item.other_fee != null;
    const getClinic = (item) => item.clinic != null;
    const filterClinicFees = (item) => item.clinic_fee != null;

    // const getDueAmount = (invoice) => {
    //     const getInvoiceItemsPaymentsTotal = () => {
    //         return invoice.invoice_items.reduce((sum, item) => {
    //             return sum += parseInt(item.payments.reduce((paymentSum, payment) => {
    //                 return paymentSum += parseInt((payment.status == "COMPLETED") ? payment.amount : 0)
    //             }, 0))
    //         }, 0)
    //     }

    //     // if we find payment_receptions (manual payment), we change the way to calculate the due amount
    //     // we get the total amount and subtract the total of every payment_reception
    //     if(Array.isArray(invoice.payment_receptions) && invoice.payment_receptions.length > 0){
    //         const totalReceived = invoice.payment_receptions.reduce((total, pM) => {
    //             return total += parseInt(pM.amount)
    //         }, 0)

    //         // there are 2 types of credits
    //         // applied credits (from the credits array), and the credits from payment_receptions
    //         // the applied credits are never subtracted from the dueAmount when there are payment_receptions
    //         // they are subtracted from the invoice item payments, so we only want to do that here
    //         let dueTotal = parseInt(invoice.total_amount) - totalReceived - getCreditAmount(invoice)

    //         if(invoice.invoice_payment_method === 'CREDITCARD' && getTotalCreditAmount(invoice) !== parseInt(invoice.total_amount)){
    //             dueTotal -= getInvoiceItemsPaymentsTotal();
    //         }

    //         return dueTotal
    //     } else {
    //         // if we dont find any payment_receptions, that means that the payment method is either online (CREDITCARD), and we do the old way
    //         // or that the manual payment is manual, but that no payments have been done
    //         // Sum of everything that is not payed
    //         return invoice.invoice_items.reduce((sum, item) => {
    //             return sum += parseInt(item.payments.reduce((paymentSum, payment) => {
    //                 return paymentSum += parseInt((payment.status !== "CANCELED" && payment.status !== "CANCELLED" && payment.status !== "REFUNDED" && payment.status !== "COMPLETED") ? payment.amount : 0)
    //             }, 0))
    //         }, 0);
    //     }
    // }

    // const getPaidAmount = (invoice) => {
    //     const getInvoiceItemsPaymentsTotal = () => {
    //         return parseInt(invoice.invoice_items.reduce((sum, item) => {
    //             return sum += parseInt(item.payments.reduce((paymentSum, payment) => {
    //                 return paymentSum += parseInt(payment.status === "COMPLETED" ? payment.amount : 0)
    //             }, 0))
    //         }, 0))
    //     }

    //     // if we find payment_receptions (manual payment), we change the way to calculate the paid amount
    //     // we get the total of all the payment_receptions
    //     if(invoice.payment_receptions && Array.isArray(invoice.payment_receptions) && invoice.payment_receptions.length > 0){
    //         let totalReceptions = invoice.payment_receptions.reduce((total, pM) => {
    //             if(!pM.member_credit_id){
    //                 total += parseInt(pM.amount)
    //             }
    //             return total
    //         }, 0)

    //         if(invoice.invoice_payment_method === 'CREDITCARD' && getTotalCreditAmount(invoice) !== parseInt(invoice.total_amount)){
    //             totalReceptions += getInvoiceItemsPaymentsTotal();
    //         }
    //         return totalReceptions
    //     } else {
    //         // if we dont find any payment_receptions, that means that the payment method is either online (CREDITCARD), and we do the old way
    //         // or that the manual payment is manual, but that no payments have been done
    //         return getInvoiceItemsPaymentsTotal();
    //         // removed _creditAmount from paid, we don't want to consider credits in the paid column's total
    //     }
    // }

    const getPaymentReceptionsCreditAmount = (invoice) => {
        let paymentReceptionsCreditsTotal = 0;

        if(invoice.payment_receptions && Array.isArray(invoice.payment_receptions) && invoice.payment_receptions.length > 0){
            paymentReceptionsCreditsTotal += invoice.payment_receptions.reduce((total, pM) => {
                if(pM.member_credit_id){
                    total += parseInt(pM.amount)
                }
                return total
            }, 0)
        }

        return paymentReceptionsCreditsTotal
    }

    const getCreditAmount = (invoice) => {
        return invoice.credits.reduce((sum, credit) => sum += parseInt(credit.amount), 0);
    }

    // const getTotalCreditAmount = (invoice) => {
    //     return getCreditAmount(invoice) + getPaymentReceptionsCreditAmount(invoice);
    // }

    const formatData = (invoices, credits) => {
        const _invoices = invoices.filter((invoice) => invoice.type !== 'WAIVER').map((invoice) => {
            return formatSingleInvoiceData(invoice);
        })

        // credit
        // credits logic:
        // total is the used amount of the credit (amount - balance * -1)
        // paid is never used (the paid credit is added on the invoice's paid cell)
        // due is the remaining (unused) of the credit (balance * -1)
        // total and due are negative so they can subtract a certain amount off the total at the bottom
        _invoices.pushArray(credits.map((credit) => ({
            ...credit,
            isCredit: true,
            invoice_number: credit.member_credit_id, // to simulate an invoice && be valid for table using invoice_number ad data index
            invoiceNumber: '', // added for search, user will probably search for an invoice number, we want to hide all credits when he does
            invoice_date: moment(credit.created_at).subtract(30, 'seconds').toISOString(), // for sort
            total: (parseInt(credit.amount) - parseInt(credit.balance)) * -1,
            paid: 0, // no paid, see logic
            due: parseInt(credit.balance) * -1,
        })))
        return _invoices;
    }

    const formatSingleInvoiceData = (invoice) => {
        const paidAmount = invoice.invoice_items.reduce((sum, item) => sum += parseInt(item.paid_amount), 0) + getCreditAmount(invoice);
        return ({
            ...invoice,
            registrations: invoice.invoice_items.filter(filterRegistrations),
            otherItems: invoice.invoice_items.filter(filterOtherItems),
            clinic: invoice.invoice_items.find(getClinic),
            clinicFees: invoice.invoice_items.filter(filterClinicFees),

            creditAmount: getCreditAmount(invoice) + getPaymentReceptionsCreditAmount(invoice),
            total: parseInt(invoice.total_amount),
            paid: paidAmount, //getPaidAmount(invoice),
            due: invoice.total_amount - paidAmount, //getDueAmount(invoice),
        })
    }

    return (
        <>
            <MemberProfileTransactionAdd isOpen={addModal} toggle={toggleAddModal} refreshTable={() => tableRef.current.refreshTable()} />
            <Card className="card-shadow">
                <CardBody>
                    <CardSectionTitle title='members.profile.memberProfile.tabs.transactions' />
                    {(!(!!membersContext.currentMember.invoices && !!membersContext.currentMember.credits) && membersContext.currentMember.minimalistic_view == 0) &&
                        <div className='text-center'>
                            <Spinner color='primary' type='grow' />
                            <div className='h4'><Translate id='misc.loading' /></div>
                        </div>
                    }
                    {((!!membersContext.currentMember.invoices && !!membersContext.currentMember.credits) || membersContext.currentMember.minimalistic_view == 1) &&
                        <SpordlePanelTable
                            allowOutsideClick
                            dataIndex='invoice_number'
                            sidePanel={(sProps) => <MemberProfileTransactionsSidepanel {...props} {...sProps} formatSingleInvoiceData={formatSingleInvoiceData} />}
                            table={(panelProps) => {
                                return (
                                    <SpordleTableProvider
                                        id='transaction'
                                        key={props.forceRefresh}
                                        tableHover bordered striped
                                        clickable hasFooter
                                        ref={(r) => { tableRef.current = r; panelProps.spordleTableRef(r) }}
                                        dataIndex='invoice_number'
                                        desktopWhen
                                        pagination={20}
                                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                        defaultSorting='-invoice_date'
                                        searchKeys={[
                                            { name: 'invoice_number', weight: 2 }, // search for invoiceNumber and not invoice_number because of credits
                                            'invoice_items.clinic.clinic_reference',
                                            'invoice_date',
                                            'identity.family_name',
                                            'identity.name',
                                        ]}
                                        emptyLayout={<EmptyLayout />}
                                        defaultSearchValue={queryString.parse(window.location.search).search}
                                        loadData={(from, _filters, spordleTable) => {
                                            switch (from){
                                                case 'REFRESH':
                                                    props.setHeaderCardsRefresh();
                                                    spordleTable.setLoading();
                                                case 'CDM':
                                                    return Promise.all([
                                                        membersContext.getMemberInvoices(membersContext.currentMember.member_id, {
                                                            organisation_id: organisation_id, // Has to be active organisation because of what is allowed to be shown
                                                        }),
                                                        membersContext.getMemberCredits(organisation_id, {
                                                            member_id: membersContext.currentMember.member_id,
                                                        }),
                                                    ])
                                                        .then((promises) => formatData(promises[0], promises[1]))
                                                default:
                                                    break;
                                            }
                                        }}
                                        columns={[
                                            {
                                                label: <Translate id='members.profile.transactions.memberProfileTransactions.tableView.header.transaction' />,
                                                key: 'invoice_date',
                                                sortable: true,
                                            },
                                            {
                                                label: <Translate id='members.profile.transactions.memberProfileTransactions.paidBy' />,
                                                key: 'paidBy',
                                                sortable: true,
                                            },
                                            {
                                                label: <Translate id='members.profile.transactions.memberProfileTransactions.tableView.header.items' />,
                                                key: 'items',
                                            },
                                            {
                                                label: <Translate id='members.profile.transactions.memberProfileTransactions.total' />,
                                                key: 'total',
                                                sortable: true,
                                                hasTotal: true,
                                            },
                                            {
                                                label: <Translate id='members.profile.transactions.memberProfileTransactions.tableView.header.paid' />,
                                                key: 'paid',
                                                sortable: true,
                                                hasTotal: true,
                                            },
                                            {
                                                label: <Translate id='members.profile.transactions.memberProfileTransactions.tableView.header.due' />,
                                                key: 'due',
                                                sortable: true,
                                                hasTotal: true,
                                            },
                                        ]}
                                        renderRow={(columnKey, invoice) => {
                                            switch (columnKey){
                                                case 'invoice_date':
                                                    return (
                                                        <>
                                                            <div className="small"><DateFormat value={invoice.invoice_date} utc /></div>
                                                            {!invoice.isCredit ?
                                                                <Link to={'invoice?invoiceNumber=' + invoice.invoice_number}>#{invoice.invoice_number}</Link>
                                                                :
                                                                <DisplayI18n field='name' defaultValue={invoice.credit_type.name} i18n={invoice.credit_type.i18n} />
                                                            }
                                                        </>
                                                    )
                                                case 'paidBy':
                                                    if(!invoice.isCredit){
                                                        return (
                                                            <>
                                                                {invoice.identity && <div className="font-medium">{invoice.identity?.name + ' ' + invoice.identity?.family_name}</div>}
                                                                <div className="text-muted"><DisplayI18n field='name' defaultValue={invoice.invoice_payment_method?.name} i18n={invoice.invoice_payment_method?.i18n} /></div>
                                                            </>
                                                        )
                                                    }
                                                    return '-'

                                                case 'items':
                                                    if(!invoice.isCredit){
                                                        if(invoice.type === 'CLINIC'){
                                                            const registration = invoice.invoice_items.find((invoiceItem) => !!invoiceItem.clinic);
                                                            if(registration?.clinic){
                                                                return (
                                                                    <>
                                                                        <DisplayI18n field='name' defaultValue={registration.clinic.name} i18n={registration.clinic.i18n} />
                                                                        <div className="small"><ClinicCategory.Builder qualification={registration.clinic.qualification} /></div>
                                                                        <div>
                                                                            <Link className="small" to={`/clinics/profile/${registration.clinic.clinic_id}`}>
                                                                                #{registration.clinic.clinic_reference} <i className="mdi mdi-chevron-right" />
                                                                            </Link>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            return (
                                                                <div> - </div>
                                                            )

                                                        }

                                                        return (
                                                            <>
                                                                {(invoice.registrations && invoice.registrations.length > 0) &&
                                                                        <div>{invoice.registrations.length} <Translate id='members.profile.transactions.memberProfileTransactions.registrations' /></div>
                                                                }
                                                                {(invoice.otherItems && invoice.otherItems.length > 0) &&
                                                                        <div>{invoice.otherItems.length} <Translate id='members.profile.transactions.memberProfileTransactions.items' /></div>
                                                                }
                                                            </>
                                                        )

                                                    }
                                                    return (
                                                        <>
                                                            <Translate id='members.profile.transactions.memberProfileTransactions.credit' />
                                                            {invoice.active === '0' &&
                                                                    <div className='text-warning small'><i className='mdi mdi-alert-outline' /> <Translate id='misc.inactive' /></div>
                                                            }
                                                        </>
                                                    )

                                                case 'total':
                                                    if(invoice.total !== 0){
                                                        return (
                                                            <div className={stringBuilder("text-right font-medium", { 'text-success': invoice.isCredit, 'text-muted': invoice.active === '0' })}>
                                                                <CurrencyFormat value={invoice.total / 100} />
                                                                {invoice.isCredit && invoice.member_credit_refunds && invoice.member_credit_refunds.length > 0 &&
                                                                    <div className='small'>
                                                                        (<Translate id='members.profile.transactions.memberProfileTransactions.refunded' />: <CurrencyFormat value={invoice.member_credit_refunds.reduce((sum, refund) => sum += parseInt(refund.amount), 0) / 100} />)
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    return '-'

                                                case 'paid':
                                                    if(invoice.paid > 0 || !!invoice.creditAmount){
                                                        return (
                                                            <div className={stringBuilder("text-right font-medium", { 'text-muted': invoice.active === '0' })}>
                                                                <CurrencyFormat value={parseInt(invoice.paid) / 100} />
                                                                {!!invoice.creditAmount &&
                                                                    <div className="d-block text-success font-medium small">(<CurrencyFormat value={(parseInt(invoice.creditAmount)) / 100} />)</div>
                                                                }
                                                                {invoice.total_refunded_amount > 0 &&
                                                                    <div className='text-danger font-medium small'>(<Translate id='members.profile.transactions.memberProfileTransactions.refunded' />: {<CurrencyFormat value={invoice.total_refunded_amount / 100} />})</div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    return '-'

                                                case 'due':
                                                    // because it can be < 0 in case of a credit
                                                    if(invoice.due !== 0){
                                                        return (
                                                            <div className='text-right'>
                                                                {/* {!data.isCredit && <i className="mdi mdi-alert-outline text-warning mr-2"></i>} */}
                                                                <span className={stringBuilder("font-medium", { 'text-success': invoice.isCredit, 'text-muted': invoice.active === '0' })}>
                                                                    <CurrencyFormat value={invoice.due / 100} />
                                                                </span>
                                                            </div>
                                                        )
                                                    }
                                                    return '-'

                                                default:
                                                    break;
                                            }
                                        }}
                                        renderFooter={(columnKey, columnTotal) => {
                                            switch (columnKey){
                                                case 'total':
                                                case 'paid':
                                                case 'due':
                                                    return <div className='text-right font-medium'><CurrencyFormat value={columnTotal / 100} /></div>
                                                default:
                                                    break;
                                            }
                                        }}
                                        onColumnClick={(e, data) => {
                                            switch (e.button){
                                                case 0: // Left mouse button
                                                    panelProps.onSingleSelectChange(data);
                                                    break;
                                            }
                                        }}
                                        rowIsHighlighted={(data) => !!data.checked}
                                    >
                                        <div className='mb-2'>
                                            <div className='d-flex flex-wrap justify-content-between'>
                                                <SpordleTableProvider.SearchInput />
                                                <div className='d-flex ml-auto text-right'>
                                                    <SpordleTableContext.Consumer>
                                                        {(spordleTable) => <Button className='mdi mdi-refresh mr-2' outline color='primary' onClick={() => spordleTable.refreshTable()} />}
                                                    </SpordleTableContext.Consumer>
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle color='primary' outline caret>
                                                            <i className='mdi mdi-download mr-1' /><Translate id='misc.export' />
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            <CanDoAction action='EXPORT' componentCode='members' componentPermissionCode='member_transactions'>
                                                                <ToCSV />
                                                            </CanDoAction>
                                                            <DropdownItem disabled><i className='fas fa-file-excel mr-1' /><Translate id='misc.excel' /></DropdownItem>
                                                            <DropdownItem disabled><i className='fas fa-file-pdf mr-1' /><Translate id='misc.pdf' /></DropdownItem>
                                                            <DropdownItem disabled><i className='fas fa-print mr-1' /><Translate id='misc.print' /></DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>

                                                    <HasAccessTo componentCode='settings' componentPermissionCode='credit_type'>
                                                        <CanDoAction action='ADD' componentCode='members' componentPermissionCode='member_transactions'>
                                                            <Button className='ml-2' color='primary' onClick={toggleAddModal}><i className='mdi mdi-plus' /> <Translate id='members.profile.transactions.memberProfileTransactions.add' /></Button>
                                                        </CanDoAction>
                                                    </HasAccessTo>
                                                </div>
                                            </div>
                                        </div>
                                        <SpordleTableView />
                                        <OpenSidePanelOnSearch />
                                    </SpordleTableProvider>
                                )
                            }}
                        />
                    }
                </CardBody>
            </Card>
        </>
    );
}

const ToCSV = () => {
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);
    const spordleTable = useSpordleTable();
    const intl = useIntl();

    const formatData = () => {
        return spordleTable.getData().map((invoice) => ({
            invoice_date: moment(invoice.invoice_date).format('YYYY-MM-DD H:mm'),
            invoice_number: !invoice.isCredit ? invoice.invoice_number : 'N/A',
            invoice_payment_method: displayI18n("name", invoice.invoice_payment_method?.i18n, invoice.invoice_payment_method?.name, i18nContext.getGenericLocale()),
            total: (invoice.total / 100).toFixed(2),
            paid: (invoice.paid / 100).toFixed(2),
            due: (invoice.due / 100).toFixed(2),
        }));
    }

    return (
        <CSVLink
            className="dropdown-item" separator=','
            filename={`${membersContext.currentMember.first_name + ' ' + membersContext.currentMember.last_name}-Transactions-${moment().format('YYYY-MM-DD H:mm')}.csv`}
            headers={[
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.invoiceDate' }),
                    key: 'invoice_date',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.invoiceNumber' }),
                    key: 'invoice_number',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.paymentMethod' }),
                    key: 'invoice_payment_method',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.total' }),
                    key: 'total',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.paid' }),
                    key: 'paid',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.due' }),
                    key: 'due',
                },
            ]}
            data={formatData()}
        >
            <i className='fas fa-file-excel mr-1' />CSV
        </CSVLink>
    )
}

export default MemberProfileTransactions;