import { Tooltip } from "@mantine/core";
import { stringBuilder } from "@spordle/helpers";
import React from "react";
import { Button, DropdownToggle, NavLink, UncontrolledDropdown } from "reactstrap";

/**
 * JSX implementation of EnvFeatureFlag
 * ENV based feature flags. Dev will bypass if int is not specified.
 * v0.1 Before API implementation
 * @param {object} props
 * @param {React.ReactNode|Function} children
 * @param {array|'prod'|'preprod'|'training'|'uat'|'stage'|'int'} env
 * @returns
 */
const EnvFeatureFlag = ({ env, tooltipOff, tag: Tag, ...props }) => {
    if(!env)
        return props.children;

    const childrenWrapper = React.Children.map(props.children, (child) => {
        if(React.isValidElement(child)){
            switch (child.type){
                case NavLink:// Reactstrap Button
                case Button:// Reactstrap Button
                case DropdownToggle:// Reactstrap Button
                    return React.cloneElement(child, {
                        color: 'primary',
                    });
                case UncontrolledDropdown:// Reactstrap Button
                case 'button':// Normal html Button
                    return React.cloneElement(child, {
                        className: stringBuilder('text-dev', child.props.className),
                    });
                default:
                    return React.cloneElement(child, {
                        className: stringBuilder('text-dev', child.props.className),
                    });
            }
        }
        return child;
    })

    if((Array.isArray(env) && env.indexOf(process.env.REACT_APP_ENVIRONMENT) !== -1) || process.env.REACT_APP_ENVIRONMENT === env)
        return <Tag {...props} />;
    else if(process.env.NODE_ENV === 'development')
        return (
            <Tag {...props}>{tooltipOff ? childrenWrapper :
                <span
                    className='text-dev'
                >
                    <Tooltip
                        withArrow
                        zIndex={3000}
                        className='w-100'
                        label={
                            <u>
                            Env Feature Flag: {Array.isArray(env) ? env.join(", ") : env}
                            </u>
                        }
                    >
                        {childrenWrapper}
                    </Tooltip>
                </span>
            }
            </Tag>
        );

    return null;
}

EnvFeatureFlag.defaultProps = {
    tag: 'span',
    tooltipOff: false,
}

export default EnvFeatureFlag;