import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Col, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import CustomAlert from "../../../../../components/CustomAlert";
import { MembersContext } from "../../../../../contexts/MembersContext";
import { RolesContext } from "../../../../../contexts/RolesContext";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import { FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import { PeriodsContext } from "../../../../../contexts/contexts";
import { TeamsContext } from "../../../../../contexts/TeamsContext";
import Required from "../../../../../components/formik/Required";

const TeamRosterReleaseModal = ({ isOpen, ...props }) => {

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='teamRosterReleaseModal'>
            <TeamRosterReleaseModalInner {...props} />
        </AnalyticsModal>
    )
}

const TeamRosterReleaseModalInner = ({ toggle, teamMember, releaseMember, onConfirm }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ memberTeams, setMemberTeams ] = useState([]);
    const [ activeStep, setActiveStep ] = useState('release');
    const intl = useIntl();
    const membersContext = useContext(MembersContext);
    const periodsContext = useContext(PeriodsContext);
    const rolesContext = useContext(RolesContext);
    const teamsContext = useContext(TeamsContext);

    const toggleModal = () => {
        toggle();
        onConfirm();
    }

    const onConfirmRelease = () => {
        setIsLoading(true);
        releaseMember()
            .then(async() => {
                // this portion allows us to set a different primary team if need be
                if(rolesContext.hasAccessTo('teams', 'team_members')){
                    const memberTeams = await membersContext.getMemberTeams(teamMember.member.member_id)
                    const memberTeamsInPeriod = memberTeams.filter((memberTeam) => memberTeam.team?.period?.period_id === periodsContext.selectedPeriod.period_id && memberTeam.team?.team_id !== teamsContext.cachedTeam.team_id)

                    if(memberTeamsInPeriod.length > 0 && teamMember.member_primary_teams?.find((primaryTeam) => primaryTeam.active == 1)){
                        setActiveStep('changePrimary');
                        setMemberTeams(memberTeamsInPeriod);
                    }else{
                        toggleModal();
                    }
                }else{
                    toggleModal();
                }
                setIsLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                    setIsLoading(false);
                }
            })
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <CrossFade isVisible={activeStep === 'release'}>
                <ModalBody className="text-center position-relative">
                    <button onClick={toggle} type="button" aria-label={intl.formatMessage({ id: 'misc.close' })} className="close position-absolute top-0 right-0 p-3">
                        <span aria-hidden>x</span>
                    </button>
                    <i style={{ fontSize: 75 }} className='mdi mdi-alert-outline text-danger' />
                    <div className="mb-2"><Translate id={'teams.profile.roster.sidePanel.actions.release.text'} /></div>
                    <UserDisplay className='justify-content-center'>
                        <UserDisplay.Container>
                            <UserImg
                                abbr={teamMember.member.first_name.charAt(0) + teamMember.member.last_name.charAt(0)}
                                src={teamMember.member.picture?.full_path}
                                filePos={teamMember.member.picture?.file_position}
                                width={40}
                                height={40}
                                alt={teamMember.member.first_name + ' ' + teamMember.member.last_name}
                            />
                        </UserDisplay.Container>
                        <UserDisplay.Container>
                            <UserDisplay.Title>{teamMember.member.first_name + ' ' + teamMember.member.last_name}</UserDisplay.Title>
                                #{teamMember.member.unique_identifier}
                            <UserDisplay.Subtitle><DateFormat value={teamMember.member.birthdate} /></UserDisplay.Subtitle>
                        </UserDisplay.Container>
                    </UserDisplay>

                    <Collapse isOpen={!!error} appear mountOnEnter unmountOnExit>
                        {error &&
                            <CustomAlert className='mt-3 text-left' color='danger' withTitle text={error} translateText={false} toggle={() => setError(null)} />
                        }
                    </Collapse>
                </ModalBody>
                <ModalFooter className="border-top-0 justify-content-center">
                    <Button disabled={isLoading} color='danger' onClick={onConfirmRelease}>
                        <Translate id={"teams.profile.roster.sidePanel.actions.release"} />
                    </Button>
                    <Button color="primary" outline onClick={toggle}>
                        <Translate id="misc.cancel" />
                    </Button>
                </ModalFooter>
            </CrossFade>

            <CrossFade isVisible={activeStep === 'changePrimary'}>
                <Formik
                    initialValues={{
                        teamId: '',
                        comment: '',
                    }}
                    validationSchema={object().shape({
                        teamId: string().required(<Translate id='teams.profile.roster.sidePanel.actions.release.primary.team.required' />),
                        comment: string().required(<Translate id='teams.profile.roster.sidePanel.actions.release.primary.comment.required' />),
                    })}
                    onSubmit={(values) => {
                        setIsLoading(true);
                        membersContext.setMemberPrimaryTeam(teamMember.member.member_id, values.teamId, { comment: values.comment })
                            .then(async() => {
                                toggleModal();
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                    setIsLoading(false);
                                }
                            })
                    }}
                >
                    {(formik) => (
                        <Form>
                            <ModalHeader toggle={toggle}>
                                <Translate id='teams.profile.roster.sidePanel.actions.release.primary.title' />
                            </ModalHeader>
                            <ModalBody>
                                <div className="mb-2 text-center"><Translate id='teams.profile.roster.sidePanel.actions.release.primary.text' /></div>
                                <UserDisplay className='justify-content-center mb-3' block>
                                    <UserDisplay.Container>
                                        <UserImg
                                            abbr={teamMember.member.first_name.charAt(0) + teamMember.member.last_name.charAt(0)}
                                            src={teamMember.member.picture?.full_path}
                                            filePos={teamMember.member.picture?.file_position}
                                            width={40}
                                            height={40}
                                            alt={teamMember.member.first_name + ' ' + teamMember.member.last_name}
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title>{teamMember.member.first_name + ' ' + teamMember.member.last_name}</UserDisplay.Title>
                                            #{teamMember.member.unique_identifier}
                                        <UserDisplay.Subtitle><DateFormat value={teamMember.member.birthdate} /></UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>

                                <Row form>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label for='teamId' className='text-muted'><Translate id='teams.profile.roster.sidePanel.actions.release.primary.team' /> <Required /></Label>
                                            <FormikSelect
                                                id='teamId'
                                                name='teamId'
                                                loadingStatus="success"
                                                options={memberTeams.map((memberTeam) => ({
                                                    label: memberTeam.team?.name,
                                                    value: memberTeam.team?.team_id,
                                                }))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label for='comment' className='text-muted'><Translate id='teams.profile.roster.sidePanel.actions.release.primary.comment' /> <Required /></Label>
                                            <FormikTextArea id='comment' name='comment' rows={1} trim />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Collapse isOpen={!!error} appear mountOnEnter unmountOnExit>
                                    {error &&
                                        <CustomAlert className='mt-3 text-left' color='danger' withTitle text={error} translateText={false} toggle={() => setError(null)} />
                                    }
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button disabled={isLoading} color='primary' type='submit'>
                                    <Translate id="teams.profile.roster.sidePanel.actions.release.primary.btn.change" />
                                </Button>
                                <Button color="primary" outline onClick={toggleModal} type="button">
                                    <Translate id="teams.profile.roster.sidePanel.actions.release.primary.btn.skip" />
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </CrossFade>
        </OverlayLoader>
    )
}

export default TeamRosterReleaseModal