export default{
    "clinics.search.title" : "Clinics",

    // top section
    "clinics.search.createClinic"   : "Create clinic",
    "clinics.search.advancedSearch" : "Advanced search",

    // table
    "clinics.search.table.favorite"                            : "Starred",
    "clinics.search.table.id"                                  : "Clinic ID",
    "clinics.search.table.qualification"                       : "Qualification",
    "clinics.search.table.season"                              : "Season",
    "clinics.search.table.status"                              : "Status",
    "clinics.search.table.startDate"                           : "Start Date",
    "clinics.search.table.location"                            : "Format",
    "clinics.search.table.host"                                : "Host organization",
    "clinics.search.table.clinic_attendee_count"               : "Attendees",
    "clinics.search.table.emptyLayout.noResult"                : "No results...",
    "clinics.search.table.emptyLayout.noResultTip"             : "You can search or use the advanced search at the top of this page",
    "clinics.search.table.column.noStartDate"                  : "No start date",
    "clinics.search.table.column.favorite.favorite"            : "Starred",
    "clinics.search.table.column.favorite.unfavorite"          : "Unstarred",
    "clinics.search.table.column.location.ONLINE"              : "Online - Recorded/On-Demand",
    "clinics.search.table.column.location.ONLINE_LIVE"         : "Online - Live",
    "clinics.search.table.column.location.ONSITE"              : "In Person",
    "clinics.search.table.column.location.UNDEFINED"           : "Not defined",
    "clinics.search.table.column.status.OPEN_PUBLIC_SHOW_LIST" : "Open to the public",
    "clinics.search.table.column.status.OPEN_PUBLIC_SIGNUP"    : "Open registration",
    "clinics.search.table.column.status.PRIVATE"               : "Private",
    "clinics.search.table.column.status.DRAFT"                 : "Draft",
    "clinics.search.table.column.status.UNDER_CREATION"        : "In creation",
    "clinics.search.table.paginationMessage"                   : "Clinics {itemStartPos} to {itemEndPos} out of a total of {totalItems} clinics",

    // Tabs
    "clinics.search.tabs.search"  : "Search results",
    "clinics.search.tabs.starred" : "Starred",

    // Calendar filter
    "clinics.search.calendarFilter.today" : "Today",
    "clinics.search.calendarFilter.back"  : "Previous",
    "clinics.search.calendarFilter.next"  : "Next",
    "clinics.search.calendarFilter.month" : "Month",
    "clinics.search.calendarFilter.week"  : "Week",
    "clinics.search.calendarFilter.day"   : "Day",
}