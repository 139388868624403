import { useEffect, useState } from "react";
import { Button, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../analytics/AnalyticsModal";
import DisplayOrg from "../../components/DisplayOrg";
import UserDisplay from "../../components/userDisplay/UserDisplay";
import UserImg from "../../components/UserImg";
import { DisplayI18n } from "../../helpers/i18nHelper";
import { DisplayCategory, DisplayTeamCategory } from "./TeamHelpers";
import Svg from "../../components/Svg";
import FormikModalForm from "../../components/formik/FormikModalForm";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import { getRequestChangesStatus } from "../../api/client/requestChangesStatus";
import CrossFade from "../../components/crossFade/CrossFade";
import Translate from "@spordle/intl-elements";
import { FormikTextArea } from "@spordle/formik-elements";
import { partialUpdateCompetitionTeam } from "../../api/client/teams";
import FormikApiError from "../../components/formik/FormikApiError";
import ApiErrorMsg from "../../components/formik/ApiErrorMsg";
import CompetitionTeamStatus from "./competitionTeamsSearch/CompetitionTeamStatus";

const STATUS_CODE_DECLINED = "DECLINED";
const STATUS_CODE_APPROVED = "APPROVED";

const ReviewCompetitionRequestModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName="reviewCompetitionRequestModal">
            <ReviewCompetitionRequestModalInner {...props} />
        </AnalyticsModal>
    );
}

const ReviewCompetitionRequestModalInner = ({ teams, toggle, onSuccess, competitiveOrg }) => {
    const [ statuses, setStatuses ] = useState(null);
    const { organisation_category } = competitiveOrg;
    const isLeague = "league" === (organisation_category?.category_system || "").toLowerCase(); // TODO: need code

    useEffect(() => {
        getRequestChangesStatus()
            .then((allStatuses) => {
                setStatuses(allStatuses.reduce((keptStatus, status) => {
                    if([ STATUS_CODE_APPROVED, STATUS_CODE_DECLINED ].includes(status.system)){
                        keptStatus[status.system] = status;
                    }

                    return keptStatus;
                }, {}));
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }
            })
    }, []);

    return (
        <FormikModalForm
            isLoading={!statuses}
            toggle={toggle}
            initialValues={{
                active: 0,
                teams: teams.map((team) => ({
                    ...team,
                    decision: "",
                    decision_note: '',
                    competition_team_status_id: '',
                })),
            }}
            onSubmit={(values, { setStatus }) => {
                const approvedStatus = statuses[STATUS_CODE_APPROVED];
                const declinedStatus = statuses[STATUS_CODE_DECLINED];

                const toUpdate = values.teams.filter(({ decision }) => !!decision); // Because user can skip

                return Promise.all(toUpdate.map((team) => (
                    partialUpdateCompetitionTeam(team.competition_team_id, {
                        decision_note: team.decision_note,
                        competition_team_status_id: (team.decision === STATUS_CODE_APPROVED ? approvedStatus : declinedStatus).request_change_status_id,
                    })
                )))
                    .then(() => {
                        onSuccess?.();
                        setStatus({
                            isSuccess: true,
                            showError: false,
                            error: null,
                        })
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            setStatus({
                                showError: true,
                                isSuccess: false,
                                error: Array.isArray(e) ? e : <ApiErrorMsg error={e} />,
                            })
                        }
                    })
            }}
            successText={(formik) => (
                <>
                    <p>
                        <Translate id="teams.teamSearch.reviewRequest.success" values={{ count: (formik.values.teams || []).filter(({ decision }) => !!decision).length }} />
                    </p>
                    {formik.values.teams.length > 1 &&
                        <div className="my-3 pretty-scrollbar overflow-auto" style={{ maxHeight: 500 }}>
                            {(formik.values.teams || []).map(({ team, competition_team_id, decision, decision_note }) => (
                                decision ?
                                    <UserDisplay key={`review-${competition_team_id}`} card block className="mb-3 flex-wrap">
                                        <div className="d-flex align-items-center w-100">
                                            <UserDisplay.Container>
                                                <UserImg
                                                    src={team.logo?.full_path}
                                                    filePos={team.logo?.file_position}
                                                    alt={team.name}
                                                    width={40}
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Subtitle><DisplayOrg org={team.organisation} /></UserDisplay.Subtitle>
                                                <UserDisplay.Title>{team.name}</UserDisplay.Title>
                                                <UserDisplay.Subtitle>
                                                    <DisplayCategory category={team.team_category} />
                                                </UserDisplay.Subtitle>
                                            </UserDisplay.Container>
                                            {statuses &&
                                                <UserDisplay.Container className="ml-auto">
                                                    <CompetitionTeamStatus status={statuses[decision]} />
                                                </UserDisplay.Container>
                                            }
                                        </div>
                                        {decision_note &&
                                    <div className="border-top w-100 mt-2 pt-2">
                                        <div className="text-muted small mb-1">Note</div>
                                        {decision_note}
                                    </div>
                                        }
                                    </UserDisplay>
                                    :
                                    null
                            ))}
                        </div>
                    }
                </>
            )}
        >
            {(formik) => (
                teams.map(({ team, competition, competition_team_status }, i) => (
                    <CrossFade isVisible={formik.values.active === i} key={team.team_id}>
                        <ModalHeader toggle={toggle}>
                            <Translate id="teams.teamSearch.reviewRequest" />{teams.length > 1 && ` (${i + 1}/${teams.length})`}
                        </ModalHeader>
                        <ModalBody>
                            <div className="text-center mb-3">
                                <Svg icon={isLeague ? "leagues" : "tournaments"} width={50} height={50} />
                                <div className="h5 font-medium mb-0">
                                    <DisplayI18n
                                        field="name"
                                        i18n={competition.i18n}
                                        defaultValue={competition.name}
                                    />
                                </div>
                                <DisplayTeamCategory ressource={competition} />
                            </div>
                            <UserDisplay card block className="mb-0">
                                <UserDisplay.Container>
                                    <UserImg
                                        src={team.logo?.full_path}
                                        filePos={team.logo?.file_position}
                                        alt={team.name}
                                        width={40}
                                    />
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Subtitle><DisplayOrg org={team.organisation} /></UserDisplay.Subtitle>
                                    <UserDisplay.Title>{team.name}</UserDisplay.Title>
                                    <UserDisplay.Subtitle>
                                        <DisplayCategory category={team.team_category} />
                                    </UserDisplay.Subtitle>
                                </UserDisplay.Container>
                                {competition_team_status &&
                                    <UserDisplay.Container className="ml-auto pl-3">
                                        <CompetitionTeamStatus status={competition_team_status} />
                                    </UserDisplay.Container>
                                }
                            </UserDisplay>
                            {i === teams.length - 1 &&
                                <FormikApiError />
                            }
                        </ModalBody>
                        <CrossFade isVisible={!formik.values.teams[i].decision}>
                            <ModalFooter className="flex-wrap">
                                <div className="w-100 d-flex align-items-center">
                                    <Button
                                        className="mr-1 w-100"
                                        type="button"
                                        color="danger"
                                        outline
                                        onClick={() => formik.setFieldValue(`teams.${i}.decision`, STATUS_CODE_DECLINED)}
                                    >
                                        <Translate id="misc.decline" />
                                    </Button>
                                    <Button
                                        className="ml-1 w-100"
                                        type="button"
                                        color="success"
                                        outline
                                        onClick={() => formik.setFieldValue(`teams.${i}.decision`, STATUS_CODE_APPROVED)}
                                    >
                                        <Translate id="misc.approve" />
                                    </Button>
                                </div>
                                {formik.values.teams.length > 1 &&
                                    <Button
                                        color="link"
                                        type="button"
                                        className="w-100 mt-2 mb-n2"
                                        onClick={() => i === teams.length - 1 ? formik.submitForm() : formik.setFieldValue('active', i + 1)}
                                    >
                                        <Translate id="misc.skip" />
                                    </Button>
                                }
                            </ModalFooter>
                        </CrossFade>
                        <CrossFade isVisible={!!formik.values.teams[i].decision}>
                            <ModalFooter className="flex-wrap">
                                <div className="mx-0 mb-3 w-100">
                                    <Label className="text-muted" for={`teams.${i}.decision_note`}>Note</Label>
                                    <FormikTextArea
                                        name={`teams.${i}.decision_note`}
                                        id={`teams.${i}.decision_note`}
                                    />
                                </div>
                                <div className="d-flex w-100 mx-0">
                                    <Button
                                        type="button"
                                        onClick={() => formik.setFieldValue(`teams.${i}.decision`, '')}
                                        color="primary"
                                        className="mr-3 w-50"
                                        outline
                                    >
                                        <Translate id="misc.cancel" />
                                    </Button>
                                    <Button
                                        className="w-50"
                                        type="button"
                                        onClick={() => i === teams.length - 1 ? formik.submitForm() : formik.setFieldValue('active', i + 1)}
                                        color={formik.values.teams[i].decision === STATUS_CODE_DECLINED ? "danger" : "success"}
                                    >
                                        <Translate id={`misc.${formik.values.teams[i].decision === STATUS_CODE_DECLINED ? "decline" : "approve"}`} />
                                    </Button>
                                </div>
                            </ModalFooter>
                        </CrossFade>
                    </CrossFade>
                ))
            )}
        </FormikModalForm>
    )
}

export default ReviewCompetitionRequestModal;