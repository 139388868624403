import { useContext, useRef } from "react";
import { Fade } from "reactstrap";
import styles from "./Notice.module.scss";
import { displayI18n, DisplayI18n } from "../../../../helpers/i18nHelper";
import { FormattedDate } from "react-intl";
import { I18nContext } from "../../../../contexts/I18nContext";
import UserImg from "../../../UserImg";
import SpordleLogo from "../../../../assets/images/spordleSuite/spordle.svg";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { NEWS_ID_PREFIX, NEWS_TYPE_DATA } from "../../newsHelpers";

/**
 * @param {object} props
 * @param {string} props.id
 * @param {string|import("react").ElementType} [props.tag="li"]
 * @param {string} props.className
 * @param {string} props.articleClassName
 * @param {number} props.noticeNb
 * @param {number} props.index
 * @param {object} props.notice
 */
const Notice = ({ id, tag = "li", className, articleClassName, noticeNb, notice, index }) => {
    const noticeRef = useRef(null);
    const { getGenericLocale } = useContext(I18nContext);
    const newsData = NEWS_TYPE_DATA[notice.type];

    return (
        <Fade
            tag={tag}
            id={id ?? `${NEWS_ID_PREFIX}${notice.news_id}`}
            style={{ transitionDelay: `${index * 100}ms` }}
            className={stringBuilder(styles.ListItem, className)}
            innerRef={noticeRef}
        >
            <article data-observe={noticeNb} className={stringBuilder(styles.Section, articleClassName)}>
                <figure className={styles.Figure}>
                    {(notice.banner?.path_url || newsData) ?
                        <img
                            alt={notice.banner?.name || newsData.name}
                            className={styles.FigureImg}
                            src={notice.banner?.path_url || displayI18n('img', newsData.i18n, newsData.img, getGenericLocale())}
                        />
                        :
                        newsData &&
                            <i
                                style={{
                                    opacity: 0.25,
                                    fontSize: "calc(10rem + 1vw)",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                                className={`position-absolute mdi ${newsData.icon}`}
                            />
                    }
                </figure>
                {notice.organisation ?
                    <div className="px-3 d-flex align-items-center small">
                        <UserImg
                            src={notice.organisation.logo?.full_path}
                            alt={notice.organisation.organisation_name}
                            abbr={notice.organisation.abbreviation || notice.organisation.organisation_name}
                            filePos={notice.organisation.logo?.file_position}
                            width={25}
                            className="mr-1"
                        />
                        <DisplayI18n
                            field="name"
                            i18n={notice.organisation.i18n}
                            defaultValue={notice.organisation.organisation_name}
                        />
                    </div>
                    :
                    <img className="d-block mx-3" height={29} width={80} src={SpordleLogo} alt="Spordle" />
                }
                <small className="px-3 text-uppercase">
                    <Translate id="misc.from" /> <span className="font-bold">{notice.published_by?.name} {notice.published_by?.family_name}</span>
                </small>
                {(notice.published_at || notice.created_at) &&
                    <div className="d-flex align-items-center px-3 small text-muted">
                        <time dateTime={notice.published_at || notice.created_at}>
                            <FormattedDate value={notice.published_at || notice.created_at} />
                        </time>
                        {notice.published_until &&
                            <>
                                <span className="text-lowercase mx-1"><Translate id="misc.to" /></span>
                                <time dateTime={notice.published_until}>
                                    <FormattedDate value={notice.published_until} />
                                </time>
                            </>
                        }
                    </div>
                }
                <h3 className="px-3 mt-3 h1 font-bold">
                    <DisplayI18n field="title" i18n={notice.i18n} defaultValue={notice.title} />
                </h3>
                <div
                    className="px-3 pb-3 text-wrap"
                    dangerouslySetInnerHTML={{
                        __html: displayI18n("description", notice.i18n, notice.description, getGenericLocale()),
                    }}
                />
            </article>
        </Fade>
    );
}

export default Notice;