// Sidebar routes
import tasksSidebar from '../routes/navigationRoutes/tasksSidebar';
import catalogSidebar from '../routes/navigationRoutes/catalogSidebar';
import settingsSidebar from '../routes/navigationRoutes/settingsSidebar';
import searchSidebar from '../routes/navigationRoutes/searchSidebar';
import reportsSidebar from '../routes/navigationRoutes/reportsSidebar';
import { history } from '../helpers';
import { matchPath } from "react-router";
import communicationsSidebar from '../routes/navigationRoutes/communicationsSidebar';

export const activeRoute = (routeName) => {
    const location = history.location;
    return location.pathname.startsWith(routeName);
}

export const isActiveSidebar = (sidebar) => {
    const location = history.location;
    if(sidebar.some((r) => r.navlabel)){
        return -1 !== sidebar.findIndex((r) => {
            if(r.subPermissionsRoutes){
                return r.subPermissionsRoutes.findIndex((r) => location.pathname.startsWith(r.path)) != -1
            }
            return location.pathname.startsWith(r.path)
        })
    }
    return sidebar.findIndex((r) => location.pathname.startsWith(r.path)) != -1

}

export const sortRoutesAlphabetically = (routes, formatMsg) => {
    return Array.isArray(routes) ? routes.sort((a, b) => formatMsg({ id: a.name }).localeCompare(formatMsg({ id: b.name }))) : [];
}

/**
 * @param {string} pathname
 * @param {(object) => boolean} [checkIfContinue]
 * @returns {object|null}
 */
export async function findRouteExactMatch(pathname, checkIfContinue){
    /**
     * This is used to avoid cyclic dependencies of routes
     */
    const routes = await import("../routes/index").then((module) => module.default).catch((e) => {
        console.error(e);
        return [];
    });
    let foundRoute = null;

    function findMatchRecursively(path, theRoutes, prefix){
        for(let r = 0; r < theRoutes.length && !foundRoute; r++){
            const theRoute = theRoutes[r];
            const canProceed = typeof checkIfContinue === "function" ? checkIfContinue(theRoute) : !!theRoute?.path;

            if(canProceed){
                const pathToCompare = `${prefix || ""}${theRoute.path}`;
                const match = matchPath(path, {
                    path: pathToCompare,
                    exact: true,
                    strict: false,
                });

                if(match && !theRoute.redirect){
                    foundRoute = { ...theRoute, match: match };
                }else if(theRoute.subPermissionsRoutes && Array.isArray(theRoute.subPermissionsRoutes)){
                    findMatchRecursively(path, theRoute.subPermissionsRoutes, pathToCompare);
                }
            }

        }
    }

    findMatchRecursively(pathname, routes);

    return foundRoute;
}

/*--------------------------------------------------------------------------------*/
/* Get the current active sidebar                                                 */
/*--------------------------------------------------------------------------------*/
export const getSidebarRoutes = () => {
    if(isActiveSidebar(searchSidebar))return searchSidebar
    else if(isActiveSidebar(tasksSidebar))return tasksSidebar;
    else if(isActiveSidebar(catalogSidebar))return catalogSidebar;
    else if(isActiveSidebar(settingsSidebar))return settingsSidebar;
    else if(isActiveSidebar(reportsSidebar))return reportsSidebar;
    else if(isActiveSidebar(communicationsSidebar))return communicationsSidebar;
}

/*--------------------------------------------------------------------------------*/
/* Return a flattened sidebar route without the groups or labels                  */
/*--------------------------------------------------------------------------------*/
export const flattenSidebarRoutes = (routes) => {
    const hasGroups = routes?.some((r) => r.navlabel)
    if(hasGroups){
        return routes?.reduce((newArray, navGroup) => {
            if(navGroup.redirect || navGroup.divider || ((navGroup.devOnly && process.env.REACT_APP_ENVIRONMENT === 'prod')))
                return null;

            navGroup.subPermissionsRoutes?.forEach((prop, key) => {
                if(prop.hiddenRoute || prop.redirect || navGroup.divider || ((prop.devOnly && process.env.REACT_APP_ENVIRONMENT === 'prod')))
                    return null;

                newArray.push(prop)
            })
            return newArray
        }, [])
    }
    return routes?.map((navGroup, i) => {
        if(navGroup.redirect || navGroup.divider || ((navGroup.devOnly && process.env.REACT_APP_ENVIRONMENT === 'prod')))
            return null;
        return navGroup
    })

}

export const wrapSubPermissionsRoutes = (subPermissions) => {
    return {
        subPermissionsRoutes: subPermissions,
    }
}