import { FormattedNumber } from "react-intl";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import { fail } from '@spordle/toasts';

/**
 * @description multiple used for calculating bytes
 * @Refer {@see https://en.wikipedia.org/wiki/Megabyte}
 */
const MULTIPLE = 1024;


/**
 * @description Converter used to convert mb to bytes or bytes to mb
 * @Refer {@see https://en.wikipedia.org/wiki/Megabyte}
 */
const CONVERTER = MULTIPLE * MULTIPLE;


/**
 * @description Converts megabytes to bytes
 * @param {number} mb Megabytes to convert
 * @returns {number} bytes
 */
export const mbToBytes = (mb) => mb * CONVERTER;


/**
 * @description Converts bytes to megabytes
 * @param {number} bytes Bytes to convert
 * @returns {number} megabytes
 */
export const bytesToMb = (bytes) => bytes / CONVERTER;


/**
 * @description Max accepted upload size for an api call, if exceeds will crash
 */
export const MAX_UPLOAD_SIZE = mbToBytes(14);

const MAX_UPLOAD_LENGTH = 10;


/**
 * @description Checks if is a File type, which means it's a new uploaded file
 * @param {File|{}} file
 * @returns {boolean}
 */
export const isNewFile = (file) => file instanceof File;


/**
 * @description Formats bytes
 * @param {Object} props
 * @param {number} props.bytes Bytes to convert
 * @param {number} [props.decimals] decimals
 * @returns {FormattedNumber|String} Formatted bytes
 */
export const FormatBytes = ({ bytes, decimals = 2 }) => {
    if(bytes === 0 || !bytes)return '0';

    const units = [ 'byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte', 'petabyte' ];
    const i = Math.floor(Math.log(bytes) / Math.log(MULTIPLE));

    return (
        <FormattedNumber
            maximumFractionDigits={decimals < 0 ? 0 : decimals}
            style="unit"
            unit={units[i] ?? units[units.length - 1]}
            unitDisplay="narrow"
            value={parseFloat((bytes / Math.pow(MULTIPLE, i)))}
        />
    );
}


/**
 * @description Triggers a download
 * @param {File|string} path
 * @param {string} [name]
 */
export const triggerDownload = (path, name) => {
    const link = document.createElement('a');

    if(path instanceof File){
        // eslint-disable-next-line prefer-const
        let objectUrl;
        if(objectUrl){
            URL.revokeObjectURL(objectUrl);
        }
        objectUrl = URL.createObjectURL(path);
        link.href = objectUrl;
        link.download = path.name;
    }else{
        link.href = path;
        link.target = "_blank";
        link.download = name || path;
    }

    //Firefox requires the link to be in the body
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


/**
 * @description Used to make an api call to get a downloadable path then trigger a download
 * @param {Promise<string>} call Needs to return the path to download
 * @param {File|{}} file
 * @param {string} name
 * @returns {Promise}
 */
export const apiTriggerDownload = (call, file) => {
    if(!isNewFile(file)){
        return call?.()
            ?.then((path) => triggerDownload(path, file.name))
            ?.catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    fail();
                    console.error(e);
                }
            })
    }
    return new Promise((resolve) => {
        isNewFile(file) && triggerDownload(file);
        resolve();
    })

}


/**
 * @description Truncates file name if too long, but leaves the extension
 * @param {string} name file type
 * @returns {React.ReactNode} icon class name
 */
export const formatFileName = (name) => {
    const arr = name.split(/[.]+/);
    const fileName = arr.slice(0, arr.length - 1).join('.');
    const extension = arr.pop();

    return <><span style={{ minWidth: 0 }} className="d-inline-block text-truncate" title={`${fileName}.${extension}`}>{fileName}</span><span>.{extension}</span></>
}


/**
 * @description Returns an icon depending on the file type. Default one is a generic document icon
 * @param {string} [type] file type
 * @returns {'mdi mdi-file-pdf'|'mdi mdi-file-image'|'mdi mdi-file-document'} icon class name. Default will be mdi-file-document
 */
export const getIcon = (type) => {
    const base = "mdi mdi-";

    switch (type){
        case 'application/xml':
        case 'xml':
            return base + "file-xml"
        case 'application/pdf':
        case 'pdf':
            return base + "file-pdf"
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/png':
        case 'image/webp':
        case 'image/gif':
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'webp':
        case 'gif':
        case 'jfif':
            return base + "file-image"
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.oasis.opendocument.spreadsheet':
        case 'xls':
        case 'xlsx':
        case 'ods':
            return base + "file-excel"
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.oasis.opendocument.text':
        case 'doc':
        case 'docx':
        case 'odt':
            return base + "file-word"
        default:
            if(/audio\/*/.test(type)){
                return base + "file-music"
            }else if(/video\/*/.test(type)){
                return base + "file-video"
            }else if(/image\/*/.test(type)){
                return base + "file-image"
            }
            return base + "file-document"
    }
};


/**
 * @description Helper that calcs size of an array of files
 * @param {File[]|{size:number}} files Array of files
 * @returns {number} total size in bytes
 */
export const getFilesTotalSize = (files) => {
    return files.reduce((size, file) => size + file.size, 0);
}


const formatFileToCreate = (file) => {
    return {
        attachment: file,
        size: file.size,
        active: 1,
    }
}


/**
 * @description Helper that separate files in subarrays of total size <= max upload size
 * @param {(File|{size:number})[]} files
 * @param {boolean} shouldFormat Formats files so it's formatted in an object {attachment: file, active: 1}
 * @returns {(File|{size:number})[][]} An array with subarray of files
 */
export const separateFilesPerSize = (files, shouldFormat = true) => {
    const finalArr = [];
    let tempArr = [];

    for(let index = 0; index < files.length; index++){
        const file = files[index];
        const formatted = shouldFormat ? formatFileToCreate(file) : file;

        if((getFilesTotalSize(tempArr) + file.size < MAX_UPLOAD_SIZE) && tempArr.length <= MAX_UPLOAD_LENGTH){
            tempArr.push(formatted);
        }else{
            if(tempArr.length > 0){
                finalArr.push(tempArr);
            }
            tempArr = [ formatted ];
        }
    }

    finalArr.push(tempArr);

    return finalArr;
};

export const separateAndFormatDocs = (documents, fileIndex) => {
    const finalArr = [];
    let tempArr = [];

    for(let i = 0; i < documents.length; i++){
        const { [fileIndex]: file, ...doc } = documents[i];

        const formattedDoc = { ...doc, ...formatFileToCreate(file) };

        if((getFilesTotalSize(tempArr) + file.size < MAX_UPLOAD_SIZE) && tempArr.length <= MAX_UPLOAD_LENGTH){
            tempArr.push(formattedDoc);
        }else{
            if(tempArr.length > 0){
                finalArr.push(tempArr);
            }
            tempArr = [ formattedDoc ];
        }
    }

    finalArr.push(tempArr);

    return finalArr;
}

/**
 * @typedef Attachment
 * @prop {object} attachment
 * @prop {string} attachment.attachement_id
 */

/**
 * @typedef FilteredObject
 * @prop {object} filteredObject
 * @prop {(object|File)[]} filteredObject.toKeep
 * @prop {(object|File)[]} filteredObject.toDelete
 * @prop {(object|File)[][]} filteredObject.toCreate
 */

/**
 * @param {(Attachment)[]} initFiles
 * @param {(object|File)[]} newFiles
 * @returns {FilteredObject}
 */
export const compareAndFormatFiles = (initFiles, newFiles) => {
    const obj = initFiles.reduce((newObj, initFile) => {
        const string = !newFiles?.some((f) => f.id == initFile.attachment.attachement_id) ? 'toDelete' : 'toKeep';
        newObj[string]?.push(initFile);

        return newObj;
    }, { toKeep: [], toDelete: [] });

    const toCreate = [];
    for(let i = 0; i < newFiles.length; i++){
        const file = newFiles[i];
        if(isNewFile(file)){
            toCreate.push(file);
        }
    }

    obj.toCreate = separateFilesPerSize(toCreate);

    return obj;
}