import React from "react";
import {
    Card,
    CardBody,
    Row,
    Col
} from "reactstrap";
import FormClinicInformation from './FormClinicInformation';
import Translate from "@spordle/intl-elements";
import ClinicCategory from "../../ClinicCategory";
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { ClinicsContext } from '../../../../contexts/ClinicsContext'
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { I18nContext } from '../../../../contexts/I18nContext';
import withContexts from '../../../../helpers/withContexts';
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import DisplayOrganization from "../../../../components/organization/DisplayOrganization";
import PrerequisitesSetCard from "./prerequisites/PrerequisitesSetCard";
import { SESSION_ONLINE, SESSION_ONSITE, clinicQualificationBuilder } from "../../clinicHelpers";
import ExpirationSection from "./expiration/ExpirationSection";
import { getOrganizationCourses } from "../../../../api/client/organization";

class ClinicInformation extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            edit: false,
            moodleCourses: false,
        };
    }

    toggleEdit = () => { this.setState((prevState) => ({ edit: !prevState.edit })) }

    isLoading = () => {
        return !(this.props.ClinicsContext.currentClinic?.organisation && this.props.ClinicsContext.currentClinic?.category && this.props.ClinicsContext.currentClinic?.qualification && this.props.ClinicsContext.currentClinic?.qualification?.prerequisites && this.props.ClinicsContext.currentClinic?.prerequisites)
    }

    hasPrerequisites = () => {
        return (this.props.ClinicsContext.currentClinic?.qualification?.prerequisites && this.props.ClinicsContext.currentClinic?.qualification?.prerequisites.length > 0) || (this.props.ClinicsContext.currentClinic?.prerequisites && this.props.ClinicsContext.currentClinic?.prerequisites.length > 0)
    }

    getMoodleCourseName = () => {
        if(!this.state.moodleCourses)return false
        return this.state.moodleCourses?.find((course) => course.course_external_id == this.props.ClinicsContext.currentClinic?.clinic_external_identifier?.[0]?.external_id)?.course_name
    }

    componentDidMount(){
        if(this.props.ClinicsContext.currentClinic?.clinic_id){
            //Verify if current org has moodle api partner
            this.props.OrganizationContext.getApiPartners()
                .then((apiPartners) => {
                    if(apiPartners.find((partner) => partner.api_partner?.name === "Moodle")){
                        getOrganizationCourses(this.props.OrganizationContext.organisation_id, { external_partner: 'MOODLE' })
                            .then((data) => {
                                this.setState(() => ({ moodleCourses: data }));
                            })
                            .catch((error) => { console.error(error.message) });
                    }
                })
                .catch((error) => { console.error(error.message) });
        }
    }

    render(){
        return (
            <>
                {!this.state.edit ?
                    <I18nContext.Consumer>
                        {({ getGenericLocale }) => (
                            <Card className="card-shadow">
                                <OverlayLoader isLoading={this.isLoading()}>
                                    <CardBody>
                                        <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                                            <div className="card-title font-bold h4 mb-0"><Translate id='clinics.clinicInformation.clinicInformation.title' /></div>
                                            {this.props.isHost &&
                                                <CanDoAction className='ml-auto text-link' action='EDIT' componentCode='clinics' componentPermissionCode='clinics_manage'>
                                                    <button className="reset-btn" type="button" onClick={this.toggleEdit}><Translate id='misc.edit' /></button>
                                                </CanDoAction>
                                            }
                                        </div>
                                        <div className="mb-5">
                                            <div className='font-bold h5'><Translate id='clinics.clinicInformation.clinicInformation.host.title' /></div>
                                            <div className="d-flex">
                                                <div className="mr-2 align-self-center">
                                                    <img src={this.props.ClinicsContext.currentClinic?.organisation?.logo?.full_path} width={'40'} alt={this.props.ClinicsContext.currentClinic?.organisation?.organisation_name} />
                                                </div>
                                                <div className="align-self-center mr-2">
                                                    {this.props.ClinicsContext.currentClinic?.organisation &&
                                                        <DisplayOrganization organisation={this.props.ClinicsContext.currentClinic.organisation}>
                                                            <div className={`mb-0 font-medium h5`}>{this.props.ClinicsContext.currentClinic.organisation.organisation_name}</div>
                                                        </DisplayOrganization>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <div className='font-bold h5'><Translate id='clinics.clinicInformation.clinicInformation.clinicName' /></div>
                                            <div className="d-flex">
                                                <DisplayI18n field='name' defaultValue={this.props.ClinicsContext.currentClinic.name} i18n={this.props.ClinicsContext.currentClinic.i18n} />
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <div className={`font-bold h5`}><Translate id='clinics.clinicInformation.clinicInformation.qualification.title' /></div>
                                            <Row>
                                                <Col sm="6" md="4" className="mb-3">
                                                    <div className="text-muted"><Translate id='clinics.clinicInformation.clinicInformation.qualification.category' /></div>
                                                    <ClinicCategory color={isNaN(parseInt(this.props.ClinicsContext.currentClinic?.category?.color)) ? 0 : this.props.ClinicsContext.currentClinic?.category?.color} className="font-medium">
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={this.props.ClinicsContext.currentClinic?.category?.name}
                                                            i18n={this.props.ClinicsContext.currentClinic?.category?.i18n}
                                                        />
                                                    </ClinicCategory>
                                                </Col>
                                                <Col sm="6" md="8" className="mb-3">
                                                    <div className="text-muted"><Translate id='clinics.clinicInformation.clinicInformation.qualification.qualification' /></div>
                                                    <div className="font-medium">
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={this.props.ClinicsContext.currentClinic?.qualification?.name}
                                                            i18n={this.props.ClinicsContext.currentClinic?.qualification?.i18n}
                                                        />
                                                        {this.props.ClinicsContext.currentClinic?.qualification?.qualification_level &&
                                                            <>
                                                                <span className='mx-1'>-</span>
                                                                <DisplayI18n
                                                                    field='name'
                                                                    defaultValue={this.props.ClinicsContext.currentClinic?.qualification?.qualification_level?.name}
                                                                    i18n={this.props.ClinicsContext.currentClinic?.qualification?.qualification_level?.i18n}
                                                                />
                                                            </>
                                                        }
                                                    </div>
                                                </Col>

                                            </Row>

                                            {(!!this.state.moodleCourses && !!this.props.ClinicsContext.currentClinic?.clinic_external_identifier?.[0]?.external_id) &&
                                                <Row>
                                                    <Col sm="6" md="4" className="mb-3">
                                                        <div className="text-muted"><Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.moodle' /></div>
                                                        <div className="font-medium">{this.getMoodleCourseName()}</div>
                                                    </Col>
                                                    <Col sm="6" md="8" className="mb-3">
                                                        <div className="text-muted"><Translate id="clinics.clinicInformation.formClinicInformation.clinicInfo.passingGrade" /></div>
                                                        <div className="font-medium">{this.props.ClinicsContext.currentClinic.clinic_external_identifier[0].passing_grade || "-"}</div>
                                                    </Col>
                                                    <Col sm={6} className="mb-3 mb-sm-0">
                                                        <div className="text-muted"><Translate id="clinics.clinicSessionInformation.formClinicSessionInformation.ruleForQualifications" /></div>
                                                        <div className="font-medium">
                                                            {this.props.ClinicsContext.currentClinic.rule_to_obtain_qualification ?
                                                                this.props.ClinicsContext.currentClinic.rule_to_obtain_qualification.split(',').map((sessionFormat) => (
                                                                    <>
                                                                        <i className={`mdi mr-1 mdi-${sessionFormat === SESSION_ONSITE ? 'map-marker' : sessionFormat === SESSION_ONLINE ? 'access-point' : 'play' }`} />
                                                                        <Translate id={'clinics.clinicSessionInformation.formClinicSessionInformation.sessions.' + sessionFormat} />
                                                                    </>
                                                                ))
                                                                :
                                                                "-"
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>

                                        {this.props.ClinicsContext.currentClinic?.category?.can_expire == 1 &&
                                            <ExpirationSection
                                                qualificationExpiration={{
                                                    expirationType: this.props.ClinicsContext.currentClinic?.expiration_type,
                                                    expirationDuration: this.props.ClinicsContext.currentClinic?.expiration_duration,
                                                    expirationDate: this.props.ClinicsContext.currentClinic?.expiration_date,
                                                }}
                                            />
                                        }

                                        <div className="mb-4">
                                            <div className='font-bold h5 mb-3'><Translate id='clinics.clinicInformation.clinicInformation.qualification.prerequisites' /></div>

                                            {this.hasPrerequisites() ?
                                                <>
                                                    {this.props.ClinicsContext.currentClinic?.qualification?.prerequisites && this.props.ClinicsContext.currentClinic?.qualification?.prerequisites.length > 0 &&
                                                        <div className='mb-3'>
                                                            <div className={`font-bold h6`}>
                                                                <Translate
                                                                    id='clinics.clinicInformation.clinicInformation.qualification.prerequisites.title'
                                                                    values={{ qualName: clinicQualificationBuilder(this.props.ClinicsContext.currentClinic?.qualification, getGenericLocale()) }}
                                                                />
                                                            </div>
                                                            {this.props.ClinicsContext.currentClinic?.qualification.prerequisites.map((group, index) => (
                                                                <PrerequisitesSetCard
                                                                    key={group.prerequisite_id}
                                                                    title={<Translate id='clinics.clinicInformation.clinicInformation.qualification.groups.title' values={{ index: index + 1 }} />}
                                                                    requirements={group.requirements}
                                                                    restriction={group.restriction_condition}
                                                                />
                                                            ))}
                                                        </div>
                                                    }

                                                    {this.props.ClinicsContext.currentClinic?.prerequisites && this.props.ClinicsContext.currentClinic?.prerequisites.length > 0 &&
                                                        <>
                                                            <div className={`font-bold h6`}><Translate id='clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites' /></div>
                                                            {this.props.ClinicsContext.currentClinic?.prerequisites.map((group, index) => (
                                                                <PrerequisitesSetCard
                                                                    key={group.prerequisite_id}
                                                                    title={<Translate id='clinics.clinicInformation.clinicInformation.qualification.groups.title' values={{ index: index + 1 }} />}
                                                                    requirements={group.requirements}
                                                                    restriction={group.restriction_condition}
                                                                />
                                                            ))}
                                                        </>
                                                    }
                                                </>
                                                :
                                                <Translate id="clinics.clinicInformation.clinicInformation.qualification.prerequisites.empty" />}
                                        </div>
                                        <div className="mb-2">
                                            <div className={`font-bold h5`}><Translate id='clinics.clinicInformation.clinicInformation.attendees.title' /></div>
                                            <div className="mb-3">
                                                {this.props.ClinicsContext.currentClinic?.memberTypes?.filter((type) => type.active === '1' && this.props.ClinicsContext.currentClinic?.member_types.includes(type.member_type_id))?.map((memberType, index) => (
                                                    <span className="mr-3 text-nowrap" key={memberType.member_type_id}>
                                                        <i className="mdi mdi-check text-primary align-middle mr-2" />
                                                        <span className="align-middle font-medium">
                                                            <DisplayI18n
                                                                field='name'
                                                                defaultValue={memberType?.name}
                                                                i18n={memberType?.i18n}
                                                            />
                                                        </span>
                                                    </span>
                                                ))}
                                            </div>
                                            <Row>
                                                <Col sm="12" md="6" className="mb-3">
                                                    <div className="h6 font-medium"><Translate id='clinics.clinicInformation.clinicInformation.attendees.requirements.attendees' /></div>
                                                    <div className="d-table">
                                                        <div className="d-table-row">
                                                            <span className="d-table-cell text-muted"><Translate id='clinics.clinicInformation.clinicInformation.attendees.requirements.attendees.min' /> :</span>
                                                            <span className="d-table-cell px-2 text-center font-medium">
                                                                {this.props.ClinicsContext.currentClinic?.min_attendee || <Translate id='clinics.clinicInformation.clinicInformation.attendees.requirements.attendees.min.none' />}
                                                            </span>
                                                        </div>
                                                        <div className="d-table-row">
                                                            <span className="d-table-cell text-muted"><Translate id='clinics.clinicInformation.clinicInformation.attendees.requirements.attendees.max' /> :</span>
                                                            <span className="d-table-cell px-2 text-center font-medium">
                                                                {this.props.ClinicsContext.currentClinic?.max_attendee || <Translate id='clinics.clinicInformation.clinicInformation.attendees.requirements.attendees.max.none' />}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="12" md="6" className="mb-3">
                                                    <div className="h6 font-medium"><Translate id='clinics.clinicInformation.clinicInformation.attendees.requirements.age' /></div>
                                                    <div className="d-table">
                                                        <div className="d-table-row">
                                                            <span className="d-table-cell text-muted"><Translate id='clinics.clinicInformation.clinicInformation.attendees.requirements.age.min' /> :</span>
                                                            <span className="d-table-cell px-2 text-center font-medium">
                                                                {this.props.ClinicsContext.currentClinic?.min_age || <Translate id='clinics.clinicInformation.clinicInformation.attendees.requirements.age.min.none' />}
                                                            </span>
                                                        </div>
                                                        <div className="d-table-row">
                                                            <span className="d-table-cell text-muted"><Translate id='clinics.clinicInformation.clinicInformation.attendees.requirements.age.max' /> :</span>
                                                            <span className="d-table-cell px-2 text-center font-medium">
                                                                {this.props.ClinicsContext.currentClinic?.max_age || <Translate id='clinics.clinicInformation.clinicInformation.attendees.requirements.age.max.none' />}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="mb-3">
                                                <span className="text-nowrap">
                                                    {this.props.ClinicsContext.currentClinic?.manage_waiting_list == '1' ?
                                                        <i className="mdi mdi-check text-primary align-middle mr-2" />
                                                        :
                                                        <i className="mdi mdi-close text-danger align-middle mr-2" />
                                                    }
                                                    <span className="align-middle font-medium"><Translate id='clinics.clinicInformation.formClinicInformation.attendees.waitingList' /></span>
                                                </span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="text-nowrap">
                                                    <i className={`mdi align-middle mr-2 text-${this.props.ClinicsContext.currentClinic?.crc === '1' ? 'primary mdi-check' : 'danger mdi-close'}`} />
                                                    <span className="align-middle font-medium"><Translate id='clinics.clinicInformation.clinicInformation.attendees.criminalRecord' /></span>
                                                </span>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="text-muted"><Translate id='clinics.clinicInformation.clinicInformation.description.title' /></div>
                                            {this.props.ClinicsContext.currentClinic?.description ?
                                                <div className="font-medium" dangerouslySetInnerHTML={{ __html: this.props.ClinicsContext.currentClinic?.description }} />
                                                : <div className='font-medium'><Translate id='clinics.clinicInformation.clinicInformation.description.none' /></div>}
                                        </div>
                                    </CardBody>
                                </OverlayLoader>
                            </Card>
                        )}
                    </I18nContext.Consumer>
                    :
                    <FormClinicInformation exited={this.toggleEdit} waitListCount={this.props.waitListCount} moodleCourses={this.state.moodleCourses} />
                }
            </>
        )
    }
}

export default withContexts(OrganizationContext, ClinicsContext)(ClinicInformation);