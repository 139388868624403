export default{
    "checklists.goTo"                   : "Aller à la page de guide",
    "checklists.goToModule"             : "Aller au module",
    "checklists.canComplete"            : "Peut compléter",
    "checklists.notStarted"             : "Not Started",
    "checklists.markIncomplete"         : "Marquer comme <b>incomplet</b>",
    "checklists.markComplete"           : "Marquer comme <b>complété</b>",
    "checklists.waitingForCompletion"   : "En attente d'approbation",
    "checklists.approvedBy"             : "Approuvé par",
    "checklists.date"                   : "Date d'approbation",
    "checklists.startChecklist"         : "Commencer guide",
    "checklists.startChecklist.txt"     : "Cliquez ici pour commencer depuis le début ou cliquez sur une des étapes pour y accéder directement.",
    "checklists.notFound"               : "Guide introuvable",
    "checklists.openChecklistAssistant" : "Ouvrir Guide",
    "checklists.notFound.txt"           : "Le guide que vous recherchez n'a pas été trouvé. Il a peut-être été déplacé ou supprimé.",
    "checklists.notSet"                 : "Le guide n'a pas été préparé pour cette saison.",
    "checklists.stepsComplete"          : "complétée(s)",
    "checklists.noAccess"               : "Vous n'avez pas accès à ce module.",
}