export default{
    "organization.settings.organizationSuspensions.table.code"   : "Code",
    "organization.settings.organizationSuspensions.table.active" : "Active",
    "organization.settings.organizationSuspensions.table.empty"  : "No Suspension",

    // add
    "organization.settings.organizationSuspensions.add.title"                    : "Add Suspension",
    "organization.settings.organizationSuspensions.add.code"                     : "Code",
    "organization.settings.organizationSuspensions.add.code.required"            : "Code is required",
    "organization.settings.organizationSuspensions.add.infraction"               : "Infraction",
    "organization.settings.organizationSuspensions.add.infraction.required"      : "Infraction is required",
    "organization.settings.organizationSuspensions.add.suspension.type"          : "Suspension Type",
    "organization.settings.organizationSuspensions.add.suspension.type.required" : "Suspension type is required",
    "organization.settings.organizationSuspensions.add.status"                   : "Status",
    "organization.settings.organizationSuspensions.add.status.active"            : "Active",
    "organization.settings.organizationSuspensions.add.status.inactive"          : "Inactive",
    "organization.settings.organizationSuspensions.add.status.required"          : "Status is required",
    "organization.settings.organizationSuspensions.add.code.duplicate"           : "Duplicate suspension code.",
}