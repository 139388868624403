import { useEffect, useState } from "react";
import { Collapse, Fade } from "reactstrap"

const CrossFade = ({ isVisible, children, fadeProps = {}, unmountOnExit, onEntering, onExited, ...props }) => {
    const [ isMounted, setIsMounted ] = useState(unmountOnExit ? isVisible : true);

    const handleOnExited = () => {
        if(unmountOnExit){
            setIsMounted(false);
        }
        onExited?.();
    }

    useEffect(() => {
        if(unmountOnExit && isVisible && !isMounted){
            setIsMounted(true);
        }
    }, [ isVisible ]);

    return (
        <Collapse {...props} onExited={handleOnExited} isOpen={!!isVisible}>
            <Fade {...fadeProps} in={!!isVisible}>
                {
                    isMounted &&
                        children
                }
            </Fade>
        </Collapse>
    )
}

export default CrossFade;