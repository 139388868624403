import React from "react";

// components
import MemberProfileTransactionsSidepanelInvoice from './MemberProfileTransactionsSidepanelInvoice';
import MemberProfileTransactionsSidepanelCredit from './MemberProfileTransactionsSidepanelCredit';
import MemberProfileTransactionsSidepanelClinic from "./MemberProfileTransactionsSidepanelClinic";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import withContexts from "../../../../../../helpers/withContexts";
import { PeriodsContext } from "../../../../../../contexts/contexts";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";

class MemberProfileTransactionsSidepanel extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
        }
    }

    formatAndSyncRows = (withRefreshTable = false) => {
        this.setState(() => ({
            isLoading: true,
        }))

        if(withRefreshTable){
            this.props.tableRef?.refreshTable?.();
        }

        // get specific invoice
        return this.props.MembersContext.getMemberInvoices(this.props.MembersContext.currentMember.member_id, {
            organisation_id: this.props.OrganizationContext.organisation_id,
            period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            invoice_number: this.props.selectedRows[0].invoice_number || '',
        }).then((fetchedInvoice) => {
            this.props.syncRows(this.props.formatSingleInvoiceData(fetchedInvoice[0]));
            this.setState(() => ({
                isLoading: false,
            }))

        }).catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
                this.setState(() => ({
                    isLoading: false,
                }))
            }
            this.setState(() => ({ isLoading: false }));
        })
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                {this.props.selectedRows[0].isCredit ?
                    <MemberProfileTransactionsSidepanelCredit {...this.props} formatAndSyncRows={this.formatAndSyncRows} />
                    :
                    this.props.selectedRows[0].type === 'CLINIC' ?
                        <MemberProfileTransactionsSidepanelClinic {...this.props} formatAndSyncRows={this.formatAndSyncRows} />
                        :
                        <MemberProfileTransactionsSidepanelInvoice {...this.props} onlineStoreView={this.props.onlineStoreView} formatAndSyncRows={this.formatAndSyncRows} />
                }
            </OverlayLoader>
        )
    }
}

export default withContexts(OrganizationContext, MembersContext, PeriodsContext)(MemberProfileTransactionsSidepanel);