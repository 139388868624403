const MissingRequiredFields = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 12"
            width={width}
            height={height}
        >
            <path
                className="fill-current"
                d="M7.38,0c-.02,.38-.04,.77-.06,1.15-.02,.35-.04,.71-.06,1.06-.02,.45-.05,.89-.07,1.34-.02,.35-.04,.71-.06,1.06,0,.06,0,.11,0,.19l4.11-1.63,.77,2.49-4.29,1.2,2.83,3.6-2.14,1.54-2.49-3.94-2.4,3.77-2.14-1.46,2.91-3.69L0,5.49,.77,3.09l4.19,1.71c0-.1,0-.19,0-.29-.04-.68-.08-1.36-.11-2.04-.04-.68-.07-1.35-.11-2.03,0-.15-.02-.29-.03-.44,.89,0,1.77,0,2.66,0Z"
            />
        </svg>
    );
}

export default MissingRequiredFields;