export default{
    // Review
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.review.title"         : "Modifier les option(s) de paiement",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.review.saveChanges"   : "Sauvegarder les changements",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.review.cancelChanges" : "Annuler les changements",

    // registration
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.title"            : "Articles de stage",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.amount"     : "Montant",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.taxes"      : "Taxes",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.total"      : "Total",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.optional"   : "Obligatoire",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.hint.none1" : "Vous pouvez ajouter des articles de stage.",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.hint.none2" : "Ajouter un article",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.empty"      : "Aucun article de stage",

    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.title"           : "Ajouter un article de stage",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.fee"             : "Articles de stage",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.fee.noOptions"   : "Aucun article de stage",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.fee.placeholder" : "Sélectionnez un article de stage",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.fee.required"    : "L'article est obligatoire",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.optional"        : "Obligatoire",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.create"          : "Créer et ajouter un article",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.cancelCreate"    : "Annuler la création",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.duplicate"       : "Cet article est déjà ajouté",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.creation"        : "Une erreur s'est produite lors de la création de l'article",

    // confirmation
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.title"                   : "Méthode(s) de paiement",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.validation.mandatoryFee" : "Au moins un article de stage obligatoire est requis.",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.validation.online"       : "Une méthode de paiement en ligne est requise si aucune méthode de prépaiement est spécifiée.",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.validation.prepaid"      : "Au moins une méthode de prépaiement est requise si aucune méthode de paiement en ligne est spécifiée.",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.online.title"            : "Paiement en ligne",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.merchant.validation"     : "Veuillez choisir une option de paiement en ligne",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.merchant.none"           : "Aucune option de paiement en ligne disponible, veuillez contacter le support.",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.merchant.placeholder"    : "Sélectionnez une option de paiement en ligne",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.merchant.noOptions"      : "Aucune option de paiement en ligne disponible",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.other.title"             : "Méthode(s) de prépaiement",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.CHEQUE"                  : "Chèque",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.CASH"                    : "Argent comptant",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.CREDITCARD"              : "Carte de crédit",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.PAYMENTPROXY"            : "Carte de crédit (paiement proxy)",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.INTERAC"                 : "Transfert Interac",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.FUNDING"                 : "Financement",
    "clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.minimum"                 : "Veuillez cocher au moins une méthode de prépaiement",
}