import AnalyticsModal from "../../../../../analytics/AnalyticsModal"
import { MembersContext } from "../../../../../contexts/MembersContext"
import { useContext } from 'react';
import { number, object } from "yup";
import FormikModalForm from "../../../../../components/formik/FormikModalForm";
import { Button, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Translate from "@spordle/intl-elements";
import FormikApiError from "../../../../../components/formik/FormikApiError";
import { FormikInputNumber } from "@spordle/formik-elements";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import ApiErrorMsg from "../../../../../components/formik/ApiErrorMsg";
import Required from "../../../../../components/formik/Required";

const OutstandingBalanceModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal analyticsName="outstandingBalanceModal" isOpen={isOpen}>
            <OutstandingBalanceModalInner {...props} />
        </AnalyticsModal>
    )
}

const OutstandingBalanceModalInner = ({ toggle, onSuccess }) => {
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);
    const organizationContext = useContext(OrganizationContext);

    return (
        <FormikModalForm
            toggle={toggle}
            successText={<p><Translate id="members.profile.memberProfile.header.card.outstandingBalance.success" /></p>}
            initialValues={{
                outstandingBalance: (membersContext.currentMember.outstanding_balance_details?.find((bal) => bal.organisation.organisation_id === organizationContext.organisation_id)?.outstanding_balance || 0) / 100,
            }}
            validationSchema={object().shape({
                outstandingBalance: number().required(<Translate id='members.profile.memberProfile.header.card.outstandingBalance.required' />),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                const newOutstandingBalance = Math.round(values.outstandingBalance * 100).toString();
                const currentOutstandingBalance = membersContext.currentMember.outstanding_balance_details?.find((bal) => bal.organisation.organisation_id === organizationContext.organisation_id)
                return Promise.all([
                    newOutstandingBalance == 0 ? Promise.resolve() : membersContext.updateMemberOutstandingBalance(membersContext.currentMember.member_id, newOutstandingBalance, organizationContext.organisation_id),
                    (newOutstandingBalance == 0 && currentOutstandingBalance) ? membersContext.deleteMemberOutstandingBalance(
                        currentOutstandingBalance.member_outstanding_balance_id,
                    ) : Promise.resolve(),
                ])
                    .then(async() => {
                        await membersContext.getMember(membersContext.currentMember.member_id)
                        onSuccess();
                        setStatus({
                            showError: false,
                            error: null,
                            isSuccess: true,
                        });
                    })
                    .catch((e) => {
                        setSubmitting(false);
                        setStatus({
                            showError: true,
                            isSuccess: false,
                            error: Array.isArray(e) ? e : <ApiErrorMsg error={e} />,
                        })
                    })
            }}
        >
            {(formik) => (
                <>
                    <ModalHeader toggle={toggle}><Translate id="members.profile.memberProfile.header.card.outstandingBalance.title" /></ModalHeader>
                    <ModalBody>
                        <div className="mb-3">
                            <Label className='text-muted'><Translate id='members.profile.memberProfile.header.card.outstandingBalance' /> <Required /></Label>
                            <FormikInputNumber
                                allowLeadingZeros
                                fixedDecimalScale
                                thousandSeparator=' '
                                allowNegative={false}
                                decimalScale={2}
                                name="outstandingBalance"
                                id="outstandingBalance"
                                decimalSeparator={i18nContext.getGenericLocale() === 'fr' ? ',' : '.'}
                                suffix={i18nContext.getGenericLocale() === 'fr' ? ' $' : ''}
                                prefix={i18nContext.getGenericLocale() !== 'fr' ? '$ ' : ''}
                            />
                        </div>
                        <FormikApiError />
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="primary" disabled={formik.isSubmitting || formik.status.isSuccess}>
                            <Translate id="misc.submit" />
                        </Button>
                        <Button
                            disabled={formik.isSubmitting || formik.status.isSuccess}
                            type="button"
                            color="primary"
                            outline
                            onClick={toggle}
                        >
                            <Translate id="misc.cancel" />
                        </Button>
                    </ModalFooter>
                </>
            )}
        </FormikModalForm>
    )
}

export default OutstandingBalanceModal