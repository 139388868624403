import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import EnvFeatureFlag from "../../../../components/featureFlag/EnvFeatureFlag";
import { RolesContext } from "../../../../contexts/RolesContext";
import SidebarLink from "./sidebarLink/SidebarLink";


const SidebarLinks = ({ routes, closeMenu }) => {
    const rolesContext = useContext(RolesContext);

    return (
        <ul className="sid-sidebar-list hide-menu" id="sidebarnav">
            {routes?.map((prop, key) => {
                const showFor = rolesContext.validateRouteAccess(prop);
                if(!showFor || prop.redirect || ((prop.devOnly && process.env.REACT_APP_ENVIRONMENT === 'prod'))){
                    return null;
                }else if(prop.navlabel){
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <li className="sid-sidebar-item-title nav-small-cap text-capitalize mt-0 pb-1" key={key}>
                            <span className="pl-2">
                                <i className="fa fa-chevron-down sid-sidebar-item-chevron" />{prop.skipTranslate ? prop.name : <Translate id={prop.name} />}
                            </span>
                        </li>
                    );
                }else if(prop.divider){
                    // eslint-disable-next-line react/no-array-index-key
                    return <li className="sidebar-separator" key={key} />;
                }
                return showFor && (
                    /*--------------------------------------------------------------------------------*/
                    /* Adding Sidebar Item                                                            */
                    /*--------------------------------------------------------------------------------*/
                    <EnvFeatureFlag env={prop.envFeatureFlag} key={prop.path + prop.name}>
                        <SidebarLink
                            key={prop.path + prop.name}
                            onClick={closeMenu}
                            to={prop.path}
                            linkClassName={stringBuilder({ 'text-purple': showFor === 'ADMIN' })}
                            className={stringBuilder({ 'active active-pro': !!prop.pro })}
                        >
                            {prop.skipTranslate ? prop.name : <Translate id={prop.name} />}
                        </SidebarLink>
                    </EnvFeatureFlag>
                );

            })}
        </ul>
    )
}

export default SidebarLinks;