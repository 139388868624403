import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Editor } from "@tinymce/tinymce-react";
import { useField } from 'formik';
import { I18nContext } from '../../contexts/I18nContext';
import { FormikError } from '@spordle/formik-elements';
import { stringBuilder } from '@spordle/helpers';

const FormikTiny = React.forwardRef((props, ref) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const [ { onChange, ...field }, meta, helpers ] = useField(props);
    const I18n = useContext(I18nContext);

    function stripHtml(html){
        // removes all html tags and only keeps the content in plain text
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    return (
        <div tabIndex={-1} className={stringBuilder("tiny-mce-custom-container", { "is-error": meta.touched && meta.error })}>
            <Editor
                {...field}
                // Default settings
                outputFormat='html'

                // New settings overiding the default settings
                {...props}

                onEditorChange={(content) => {
                    helpers.setValue(content);
                    props.onEditorChange?.(content);
                }}

                // Default init
                init={{
                    height: 250,
                    menubar: false,
                    statusbar: false,
                    branding: false,
                    plugins: [
                        'lists',
                        'link',
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link',
                    extended_valid_elements: 'a[href|target=_blank]', // Convert all links to have target='_blank'
                    default_link_target: '_blank', // Default to target='_blank'
                    target_list: false, // Can't change the default value above
                    link_title: false,
                    language: I18n.getGenericLocale() === 'fr' ? 'fr_FR' : undefined,

                    // https://stackoverflow.com/questions/27010110/tinymce-onpaste-doesnt-set-content-paste-with-context-menu-doesnt-trigger-cha
                    setup: function(editor){
                        // Listen for paste event, "Paste as plain text" callback
                        editor.on('paste', (e) => {
                            // Prevent default paste behavior
                            e.preventDefault();

                            // Check for clipboard data in various places for cross-browser compatibility.
                            // Get that data as text.
                            let content = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                            content = stripHtml(content);

                            // Let TinyMCE do the heavy lifting for inserting that content into the editor.
                            editor.editorCommands.execCommand('mceInsertContent', false, content);
                        })
                    },

                    // Custom init
                    ...props.init,
                }}

                // Definitive values -> Cannot be overriden
                ref={ref}
                apiKey={process.env.REACT_APP_TINY_KEY}
            />
            <FormikError name={props.name} />
        </div>
    )
});

FormikTiny.propTypes = {
    name: PropTypes.string.isRequired,
}

export default FormikTiny;