import discrimination from "./discrimination";
import gameIncident from "./gameIncident";
import insurance from "./insurance";
import memberAttachmentApprobation from "./memberAttachmentApprobation";
import officialSupervision from "./officialSupervision";
import transfer from "./transfer";
import travelPermit from "./travelPermit";
import unconfirmedOrganization from "./unconfirmedOrganization";
import waitingList from "./waitingList";
import mandatoryChangesRequests from "./mandatoryChangesRequests";
import permanentReleaseRequests from "./permanentReleaseRequests";
import ctiRequests from "./ctiRequests";
import manageRequests from "./manageRequests";
import classificationChangesRequests from "./classificationChangesRequests";
import potentialIneligibleMembers from "./potentialIneligibleMembers";
import memberDuplicates from "./potentialMemberDuplicates";
import seasonRollover from "./seasonRollover";
import memberTypeValidation from "./memberTypeValidation";
import renewMembers from "./renewMembers";
import memberTypeRemoval from "./memberTypeRemoval";

export default{
    ...discrimination,
    ...gameIncident,
    ...insurance,
    ...transfer,
    ...travelPermit,
    ...officialSupervision,
    ...waitingList,
    ...unconfirmedOrganization,
    ...memberAttachmentApprobation,
    ...mandatoryChangesRequests,
    ...permanentReleaseRequests,
    ...ctiRequests,
    ...manageRequests,
    ...classificationChangesRequests,
    ...potentialIneligibleMembers,
    ...memberDuplicates,
    ...seasonRollover,
    ...memberTypeValidation,
    ...renewMembers,
    ...memberTypeRemoval,
}