export default{
    "catalog.affiliationFees.add.title"       : "Add Membership Fee",
    "catalog.affiliationFees.add.membertype"  : "Member Type",
    "catalog.affiliationFees.add.branch"      : "Branch",
    "catalog.affiliationFees.add.category"    : "Category",
    "catalog.affiliationFees.add.division"    : "Division",
    "catalog.affiliationFees.add.class"       : "Class",
    "catalog.affiliationFees.add.fee"         : "Fee",
    "catalog.affiliationFees.add.taxIncluded" : "Tax included",
    "catalog.affiliationFees.add.error.3207"  : "Branch & Division combination already exists.",
    "catalog.affiliationFees.add.error.3060"  : "A selected division was not found.",

    "catalog.affiliationFees.branch.required"     : "Branch is required",
    "catalog.affiliationFees.memberType.required" : "Member type is required",
    "catalog.affiliationFees.division.required"   : "Division is required",
    "catalog.affiliationFees.class.required"      : "Class is required",
    "catalog.affiliationFees.fee.required"        : "Fee is required",
}