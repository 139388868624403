import { addExtension, DisplayPhoneNumber, FormikAddress, FormikInputNumber, FormikInputText, FormikPhoneInput, getFormikAddressInitialValues } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Col, FormGroup, Row } from "reactstrap";
import { number, object, string } from 'yup';
import FormikEditable from "../../../../../../../components/formik/FormikEditable";
import QuickViewButton from "../../../../../../../components/quickView/QuickViewButton";
import Svg from "../../../../../../../components/Svg";
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../components/UserImg";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import { isAnonymousComplaint } from "../../../DiscriminationHelper";
import ComplainedAgainst from "./ComplainedAgainst";

const DiscriminationSidePanelPeople = ({
    handleCatch,
    selectedRows,
    setIsLoading,
    syncNewVal,
    updateValue,
    canEdit,
    readOnly,
    refreshData,
}) => {
    const { partiallyUpdateDiscrimination } = useContext(OrganizationContext);
    const complaintByAddress = buildAddress({
        address: complaintBy("address") || null,
        city: complaintBy("city") || null,
        postal_code: complaintBy("postal_code") || null,
        province_id: complaintBy("province_id") || null,
    });

    const complaintIsAgainstTeam = (selectedRows[0].discrimination_member || []).filter((member) => member.first_name)?.length <= 0;
    function buildAddress(val){
        const address = [];

        if(val.address) address.push(val.address);
        if(val.city) address.push(val.city);
        if(val.province_id) address.push(val.province_id);
        if(val.postal_code) address.push(val.postal_code);

        return address.length == 0 ? '-' : address.join(', ');
    }

    /**
     * @description Small helper that returns the complaint_by fields without writing the whole stuff
     * @param {string} field
     * @returns {string}
     */
    function complaintBy(field){
        return selectedRows[0]?.['complaint_by' + (field ? '_' + field : '')] || '';
    }

    return (
        <>
            <FormGroup>
                <div className="h5 mt-2 mb-3 font-bold">
                    <Translate id='discrimination.label.submittedBy' />
                </div>
                <UserDisplay card className="d-flex">
                    <UserDisplay.Container>
                        {isAnonymousComplaint(selectedRows[0]) ?
                            <div
                                className="d-flex flex-center bg-light border shadow rounded-circle text-dark"
                                style={{ width: 50, height: 50 }}
                            >
                                <Svg
                                    icon="members"
                                    width={40}
                                    height={40}
                                />
                            </div>
                            :
                            <UserImg
                                alt={complaintBy("first_name") + ' ' + complaintBy("last_name")}
                                src={complaintBy("member")?.picture?.full_path}
                                filePos={complaintBy("member")?.picture?.file_position}
                                abbr={complaintBy("first_name")?.charAt(0) + complaintBy("last_name")?.charAt(0)}
                                className="text-uppercase"
                                width={50}
                            />

                        }
                    </UserDisplay.Container>
                    <UserDisplay.Container>
                        <UserDisplay.Title>
                            {isAnonymousComplaint(selectedRows[0]) ?
                                <>
                                    <Translate id='gameIncident.label.anonymous' /> <i className="mdi mdi-lock-outline" />
                                </>
                                :
                                <QuickViewButton member={{ ...complaintBy("member") }}>{complaintBy("first_name") + " " + complaintBy("last_name")}</QuickViewButton>
                            }
                        </UserDisplay.Title>
                        {!!complaintBy("member") && (
                            <UserDisplay.Subtitle>
                                <Link to={`/members/profile/${complaintBy("member").member_id}`}>
                                    #{complaintBy("member").unique_identifier} <i className="mdi mdi-chevron-right" />
                                </Link>
                            </UserDisplay.Subtitle>
                        )}
                        {complaintBy() && complaintBy("first_name") &&
                            <UserDisplay.Subtitle>
                                <Translate id={'discrimination.global.roles.' + complaintBy()} />
                            </UserDisplay.Subtitle>
                        }
                    </UserDisplay.Container>
                </UserDisplay>
                {!isAnonymousComplaint(selectedRows[0]) &&
                    <Row form>
                        {/* <Col sm="6" className="form-group">
                            <div className="text-muted"><Translate id='form.fields.firstName' /></div>
                            <FormikEditable
                                id="complaint_by_first_name"
                                disabled={!canEdit}
                                initialValues={{
                                    complaint_by_first_name: complaintBy("first_name"),
                                }}
                                validationSchema={object().shape({
                                    complaint_by_first_name: string().required(<Translate id="form.fields.firstName" />)
                                })}
                                onSubmit={({ complaint_by_first_name }) => {
                                    if(complaintBy("first_name") !== complaint_by_first_name) {
                                        updateValue("complaint_by_first_name", complaint_by_first_name);
                                    }
                                }}
                            >
                                {(isEditing, options) => {
                                    if(!isEditing){
                                        return (
                                            <div className="text-truncate">{complaintBy("first_name")}</div>
                                        )
                                    } else {
                                        return <FormikInputText trim name="complaint_by_first_name" />
                                    }
                                }}
                            </FormikEditable>
                        </Col> */}
                        {/* <Col sm="6" className="form-group">
                            <div className="text-muted"><Translate id='form.fields.lastName' /></div>
                            <FormikEditable
                                id="complaint_by_last_name"
                                disabled={!canEdit}
                                initialValues={{
                                    complaint_by_last_name: complaintBy("last_name"),
                                }}
                                validationSchema={object().shape({
                                    complaint_by_last_name: string().required(<Translate id="form.fields.lastName" />)
                                })}
                                onSubmit={({ complaint_by_last_name }) => {
                                    if(complaintBy("last_name") !== complaint_by_last_name) {
                                        updateValue("complaint_by_last_name", complaint_by_last_name);
                                    }
                                }}
                            >
                                {(isEditing, _options) => {
                                    if(!isEditing){
                                        return (
                                            <div className="text-truncate">{complaintBy("last_name")}</div>
                                        )
                                    } else {
                                        return <FormikInputText trim name="complaint_by_last_name" />
                                    }
                                }}
                            </FormikEditable>
                        </Col> */}
                        <Col sm="6" className="form-group">
                            <div className="text-muted"><Translate id='form.fields.email' /></div>
                            <FormikEditable
                                id="complaint_by_email"
                                disabled={!canEdit}
                                readOnly={readOnly}
                                initialValues={{
                                    complaint_by_email: complaintBy("email") || "",
                                }}
                                validationSchema={object().shape({
                                    complaint_by_email: string().test({
                                        name: 'shouldBeRequired',
                                        message: <Translate id="form.fields.email" />,
                                        test: function(val){
                                            return !!complaintBy("phone") || !!val;
                                        },
                                    }),
                                })}
                                onSubmit={({ complaint_by_email }) => {
                                    if(complaintBy("email") !== complaint_by_email){
                                        updateValue("complaint_by_email", complaint_by_email);
                                    }
                                }}
                            >
                                {(isEditing, options) => {
                                    if(!isEditing){
                                        return (
                                            <div className="text-truncate">{complaintBy("email") || '-'}</div>
                                        )
                                    }
                                    return <FormikInputText trim name="complaint_by_email" />

                                }}
                            </FormikEditable>
                        </Col>
                        <Col sm="12" className="form-group">
                            <div className="text-muted"><Translate id='form.fields.phone' /></div>
                            <FormikEditable
                                id="complaint_by_phone"
                                disabled={!canEdit}
                                readOnly={readOnly}
                                initialValues={{
                                    phone: complaintBy("phone")?.split("#")?.[0] || "",
                                    ext: complaintBy("phone")?.split("#")?.[1] || "",
                                }}
                                validationSchema={object().shape({
                                    phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />)
                                        .test({
                                            name: 'shouldBeRequired',
                                            message: <Translate id="form.validation.phone.required" />,
                                            test: function(val){
                                                return !!complaintBy("email") || !!val;
                                            },
                                        }),
                                    ext: number().integer(),
                                })}
                                onSubmit={async(values) => {
                                    const fullPhone = await addExtension(values.phone, values.ext);
                                    if(complaintBy("phone") !== fullPhone){
                                        await updateValue("complaint_by_phone", fullPhone);
                                    }
                                }}
                            >
                                {(isEditing, _options, formik) => {
                                    if(!isEditing){
                                        return (
                                            <DisplayPhoneNumber phoneString={complaintBy("phone")} format='INTERNATIONAL' emptyValue='-' />
                                        )
                                    }
                                    return (
                                        <Row form>
                                            <Col sm="8">
                                                <FormikPhoneInput id='phone' name='phone' />
                                            </Col>
                                            <Col sm="4">
                                                <FormikInputNumber id="ext" name="ext" />
                                            </Col>
                                        </Row>
                                    )

                                }}
                            </FormikEditable>
                        </Col>
                        <Col sm="12" className="form-group">
                            <div className="text-muted"><Translate id='form.fields.address' /></div>
                            <FormikEditable
                                id="address"
                                disabled={!canEdit}
                                readOnly={readOnly}
                                initialValues={{
                                    address: getFormikAddressInitialValues({
                                        streetNumber: complaintBy("address") ? complaintBy("address").split(' ')[0] : '',
                                        address: complaintBy("address") ? complaintBy('address').substring(complaintBy("address").indexOf(' ') + 1, complaintBy("address").length) : '',
                                        city: complaintBy("city") || '',
                                        zipCode: complaintBy("postal_code") || '',
                                        state: complaintBy("province_id") || '',
                                    }),
                                }}
                                validationSchema={object().shape({
                                    address: object().shape({
                                        streetNumber: string(),
                                        address: string(),
                                        city: string(),
                                        zipCode: string(),
                                        state: string(),
                                    }),
                                })}
                                onSubmit={({ address }) => {
                                    if(buildAddress(address) !== complaintByAddress){
                                        setIsLoading(true);

                                        const newValues = {
                                            complaint_by_address: address.streetNumber + ' ' + address.address,
                                            complaint_by_city: address.city,
                                            complaint_by_postal_code: address.zipCode,
                                            complaint_by_province_id: address.state,
                                        }

                                        partiallyUpdateDiscrimination(selectedRows[0].discrimination_id, newValues)
                                            .then(() => {
                                                syncNewVal(newValues);
                                            })
                                            .catch(handleCatch)
                                    }
                                }}
                            >
                                {(isEditing, _options, formik) => {
                                    if(!isEditing){
                                        return (
                                            <div>
                                                {complaintByAddress}
                                            </div>
                                        )
                                    }
                                    return (
                                        <FormikAddress id='address' name='address' />
                                    )

                                }}
                            </FormikEditable>
                        </Col>
                    </Row>
                }
            </FormGroup>
            <ComplainedAgainst
                complaint={selectedRows[0]}
                complainees={selectedRows[0].discrimination_member}
                handleCatch={handleCatch}
                setIsLoading={setIsLoading}
                readOnly={readOnly || (selectedRows[0].maltreatment_outcomes || []).length > 0 || (selectedRows[0].maltreatment_member_suspensions || []).length > 0}
                syncNewVal={syncNewVal}
                refreshData={refreshData}
                isAgainstTeam={complaintIsAgainstTeam}
            />
        </>
    )
}

export default DiscriminationSidePanelPeople;