export default{
    "catalog.rebates.title"                          : "Rabais",
    "catalog.rebates.form.type"                      : "Type",
    "catalog.rebates.form.title"                     : "Titre",
    "catalog.rebates.form.status"                    : "Statut",
    "catalog.rebates.form.promoCode"                 : "Code Promo",
    "catalog.rebates.form.mode"                      : "Mode",
    "catalog.rebates.form.rules"                     : "Règles",
    "catalog.rebates.form.addRules"                  : "Ajouter une règle",
    "catalog.rebates.form.nbParticipant"             : "Appliquer au participant",
    "catalog.rebates.form.nbParticipant.postalCodes" : "Appliqué à partir du participant",
    "catalog.rebates.form.nbParticipant.number"      : "Numéro",
    "catalog.rebates.form.discount"                  : "Rabais",
    "catalog.rebates.form.postalCodes"               : "{number, plural, 0 {# code postal} one {# code postal} other {# codes postaux}}",
    "catalog.rebates.form.postalCodesLabel"          : "Codes postaux",
    "catalog.rebates.form.editPostalCodes"           : "Modifier les codes postaux",
    "catalog.rebates.form.expDate"                   : "Date d'expiration",

    "catalog.rebates.form.validation.title.required"                        : "Veuillez entrer un titre.",
    "catalog.rebates.form.validation.promoCode.required"                    : "Veuillez entrer un code promotionnel",
    "catalog.rebates.form.validation.promoCode.existing"                    : "Ce code promo existe déjà. Veuillez en choisir un autre.",
    "catalog.rebates.form.validation.promoValue.required"                   : "Veuillez entrer un montant ou un pourcentage.",
    "catalog.rebates.form.validation.promoValue.percent.exceed"             : "Le pourcentage ne peut pas dépasser 100%.",
    "catalog.rebates.form.validation.participantNumber.required"            : "Veuillez indiquer le nombre de participants requis.",
    "catalog.rebates.form.validation.expDate.required"                      : "Veuillez entrer une date d'expiration.",
    "catalog.rebates.form.validation.participantNumber.nb.required"         : "Veuillez indiquer un nombre entier positif.",
    "catalog.rebates.form.validation.participantNumber.min.required"        : "Veuillez indiquer deux participants ou plus.",
    "catalog.rebates.form.validation.participantNumber.min.required.postal" : "Veuillez indiquer un participant ou plus.",
    "catalog.rebates.form.validation.postalCodes.required"                  : "Veuillez ajouter au moins un code postal",

    "catalog.rebates.sidePanel.delete" : "Vous êtes sur le point de supprimer ce rabais",

    "catalog.rebates.table.multi.rule.1" : "- Rabais de ",
    "catalog.rebates.table.multi.rule.2" : " pour participant #{nbParticipant}",
    "catalog.rebates.table.promo.rule.1" : "Rabais de ",
    "catalog.rebates.table.promo.rule.2" : " avec le code {code}",
    "catalog.rebates.table.rebate"       : "Rabais",
    "catalog.rebates.table.empty.title"  : "Aucun rabais",

    "catalog.rebates.modal.title" : "Ajouter un rabais",

    "catalog.rebates.type.multi"      : "Multiples participants",
    "catalog.rebates.type.postalCode" : "Code postal",
    "catalog.rebates.type.promo"      : "Code promotionnel",
}