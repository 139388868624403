import { stringBuilder } from '@spordle/helpers';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import {
    Button, Collapse,
    CustomInput, ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import StatusBadge from '../../../../../../../components/badges/StatusBadge';
import CanDoAction from '../../../../../../../components/permissions/CanDoAction';
import EmptyLayout from '../../../../../../../components/table/EmptyLayout';
// Contexts
import UserDisplay from '../../../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../../../components/UserImg';
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { TeamsContext } from '../../../../../../../contexts/TeamsContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import MemberStatusIcons from '../../../../../../members/search/components/MemberStatusIcons';
import { SEARCH_BIRTHDATE, SEARCH_BRANCH, SEARCH_NAME, SEARCH_TEAM } from '../../TeamRosterAdd';
import HasAccessTo from './../../../../../../../components/permissions/HasAccessTo';
import TeamRosterSearchBirthdate from './tabs/TeamRosterSearchBirthdate';
import TeamRosterSearchBranch from './tabs/TeamRosterSearchBranch';
import TeamRosterSearchName from './tabs/TeamRosterSearchName';
import TeamRosterSearchTeam from './tabs/TeamRosterSearchTeam';
import ValidateMemberInner from '../../../../../../../components/validateMember/ValidateMemberInner'
import MemberAlertsIcon from '../../../../../../../components/memberBadges/MemberAlertsIcon';

const TeamRosterAddSearch = ({ from, toggle, selected, removeMember, addMember, nextStep }) => {
    // -- Contexts
    const { cachedTeam } = useContext(TeamsContext);
    const { getGenericLocale } = useContext(I18nContext);

    // -- States
    const [ notRostered, setNotRostered ] = useState(false);
    const [ activeTab, setActiveTab ] = useState(SEARCH_NAME);
    const [ collapseIsOpen, setCollapseIsOpen ] = useState(false);

    // -- Functions
    /**
     * Formats the data received by the api call
     * @param {[object]} data
     * @returns {[object]} array of members formatted
     */
    const formatData = (data) => {
        const allMembers = data.members.map((member) => ({
            team_category: data.categories[member.team_category_id],
            ...member,
        }));
        return allMembers;
    };

    /**
     * Preformats for every repetitive needed arguments for the apiCall
     * @param {object} values Submitted values from formik
     * @returns {object}
     */
    const preformatSubmit = (values) => {
        return {
            ...values,
            member_type: from === 'bench' ? "STAFF" : "PLAYER",
            ...from === 'affiliated' && { already_in_another_team: "1", not_currently_rostered: "1" },
            ...notRostered && { not_currently_rostered: "1" },
        }
    }

    /**
     * Checks if member id sent is in selectedMembers array
     * @param {string} memberId
     * @returns {boolean}
     */
    const checkIfSelected = (memberId) => selected.some((s) => s.member_id === memberId);

    /**
     * Only thing that changes between views is the apiCall
     * Should separate the props when views completely change
     * @param {Promise<[object]>} apiCall
     * @returns {SpordleTableProvider['props']}
     */
    const getTableProps = (apiCall, branchSearch = false) => ({
        smallTable: true,
        tableHover: true,
        bordered: true,
        striped: true,
        clickable: true,
        desktopWhen: true,
        pagination: 10,
        dataIndex: "member_id",
        initFilter: { search: {} },
        emptyLayout: (
            <EmptyLayout
                msg={
                    <Translate
                        id='components.validateMember.emptyLayout'
                        values={{
                            validate: <span className='text-link clickable' onClick={() => setActiveTab('validateMember')}><Translate id='components.validateMember.validate' /></span>,
                        }}
                    />
                }
                translateMsg={false}
            />
        ),
        searchKeys: [
            { name: "unique_identifier", weight: 2 },
            'last_name',
            'first_name',
            'birthdate',
            `organisation.i18n.${getGenericLocale()}.name`,
            `organisation.abbreviation`,
        ],
        loadingStatus: 'success',
        loadData: (from, { filters }, { setLoading }) => {
            switch (from){
                case 'FILTER':
                    setLoading();
                    return apiCall(cachedTeam.team_id, filters.search).then(formatData);
                default:
                    break;
            }
        },
        renderRow: (columnKey, member) => {
            switch (columnKey){
                case 'checkbox':
                    return (
                        member.already_on_roster == "1" ?
                            <div style={{ width: 16, height: 16 }} className="border bg-light-inverse" />
                            :
                            <label className='my-auto' htmlFor={'checkbox-' + member.member_id}>
                                <input
                                    type='checkbox'
                                    checked={!!checkIfSelected(member.member_id)}
                                    id={'checkbox-' + member.member_id}
                                    onChange={() => {}}
                                />
                                <span className='table-checkbox' />
                            </label>

                    );
                case 'last_name':
                    return (
                        <div className='d-flex align-items-center'>
                            <div className='d-flex flex-wrap'>
                                <div className='w-100'>
                                    {member.last_name}
                                    {/* {(from === 'bench' && member?.crc_is_expired == 1) && // if criminal record check is expired or non existant
                                        <HasAccessTo className='ml-1' componentCode='members' componentPermissionCode='member_crc'>
                                            <img src={CRC_Red} alt="Criminal Record Check Required" height='20' />
                                            <UserDisplay.Container className="ml-auto">
                                            </UserDisplay.Container>
                                        </HasAccessTo>
                                    } */}
                                </div>
                                {branchSearch && <div className='small'><DisplayI18n field='name' defaultValue={member.organisation.organisation_name} i18n={member.organisation.i18n} /> {member.organisation.abbreviation && '(' + member.organisation.abbreviation + ')'}</div>}
                                {member.already_on_roster == "1" && <UserDisplay.Subtitle className="text-warning"><i className="mdi mdi-alert-outline" /> <Translate id='teams.profile.teamRoster.activeRoster.alreadyInTeam' /></UserDisplay.Subtitle>}
                            </div>
                            <MemberStatusIcons member={{ ...member, crc_is_expired: from === 'bench' ? member?.crc_is_expired : 0 }} />
                        </div>
                    );
                case 'first_name':
                    return (
                        <div>
                            <div className='d-flex'>
                                <div className=''>{member.first_name}</div>
                            </div>
                        </div>
                    );
                case 'status':
                    return (
                        <div>
                            <StatusBadge color={member.member_status?.color_code} icon={null} status={member.member_status} />
                        </div>
                    )
                case 'member':
                    return (
                        <div>
                            <div className='d-flex'>
                                <div className=''>{member.first_name} {member.last_name}</div>
                                {(from === 'bench' && member?.crc_is_expired == 1) && // if criminal record check is expired or non existant
                                    <HasAccessTo className='ml-1' componentCode='members' componentPermissionCode='member_background_check'>
                                        <UserDisplay.Container className="ml-auto">
                                            <MemberAlertsIcon icon="crc" />
                                        </UserDisplay.Container>
                                    </HasAccessTo>
                                }
                            </div>
                            {branchSearch && <div className='small'><DisplayI18n field='name' defaultValue={member.organisation.organisation_name} i18n={member.organisation.i18n} /> {member.organisation.abbreviation && '(' + member.organisation.abbreviation + ')'}</div>}
                            {member.already_on_roster == "1" && <UserDisplay.Subtitle className="text-warning"><i className="mdi mdi-alert-outline" /> <Translate id='teams.profile.teamRoster.activeRoster.alreadyInTeam' /></UserDisplay.Subtitle>}
                        </div>
                    );
                case 'hcr_number':
                    return (
                        <div>
                            <div>#{member.unique_identifier}</div>
                            {member.position_invoice &&
                                <span className='text-muted small'><DisplayI18n field='name' defaultValue={member.position_invoice.name} i18n={member.position_invoice.i18n} /></span>
                            }
                        </div>
                    )
                case 'age':
                    return (
                        <>
                            <Translate id="misc.yearsOld" values={{ age: member.age }} />
                            <div className="small"><DateFormat value={member.birthdate} /></div>
                        </>
                    )
                    // case 'category':
                    //     return member.team_category ? <DisplayCategory category={member.team_category} /> : '-'
                default:
                    break;
            }
        },
        onColumnClick: (e, member) => {
            // Needed for checkbox glitch
            e.preventDefault();
            e.stopPropagation();

            switch (e.button){
                case 0: // Left mouse button
                    if(member.already_on_roster !== "1"){
                        if(!checkIfSelected(member.member_id)){
                            addMember({ ...member, branchSearch: branchSearch });
                        }else{
                            removeMember(member.member_id);
                        }
                    }

                    break;
            }
        },
        columns: [
            {
                label: '-',
                key: 'checkbox',
                className: 'th-shrink',
                dataClassName: 'td-shrink',
            },
            {
                label: <Translate id='teams.profile.teamRoster.activeRoster.add.label.uniqueIdentifier' />,
                key: 'hcr_number',
                sortable: true,
                sortKey: 'hcr_number',
            },
            {
                label: <Translate id='form.fields.lastName' />,
                key: 'last_name',
                sortable: true,
                sortKey: 'last_name',
            },
            {
                label: <Translate id='form.fields.firstName' />,
                key: 'first_name',
                sortable: true,
                sortKey: 'first_name',
            },
            {
                label: <Translate id='teams.profile.teamRoster.activeRoster.add.label.status' />,
                key: 'status',
                className: 'th-shrink',
                dataClassName: 'td-shrink',
            },
            {
                label: <Translate id='teams.profile.teamRoster.activeRoster.add.label.member_age' />,
                key: 'age',
                sortable: true,
                sortKey: 'age',

            },
            // {
            //     label: <Translate id='teams.teamSearch.table.label.category' />,
            //     key: 'category',
            //     sortKey: `team_category.division.i18n.${getGenericLocale()}.short_name`,
            //     fallbackSortKey: 'team_category.division.short_name',
            //     dataClassName: 'font-medium p-0 pl-2',
            //     sortable: true
            // },
        ],
    });

    // -- React cycles
    useEffect(() => {
        if(selected.length <= 0){
            setCollapseIsOpen(false);
        }
    }, [ selected ]);

    return (
        <>
            {from !== 'affiliated' &&
                <ModalBody>
                    {/* <div className="d-flex align-items-center">
                        <span className="align-middle"><i className="mdi mdi-check text-success" /> <Translate id='teams.profile.teamRoster.activeRoster.add.alreadyInTeam'/></span>
                    </div> */}
                    <CustomInput
                        id="not-rostered"
                        label={<Translate id='teams.profile.teamRoster.activeRoster.add.notRostered' />}
                        checked={notRostered}
                        onChange={(e) => setNotRostered(e.target.checked)}
                        type="checkbox"
                    />
                </ModalBody>
            }

            {/*--------------------------------------------------------------------------------*/}
            {/* Search Tabs */}
            {/*--------------------------------------------------------------------------------*/}
            <Nav tabs className="mt-3">
                <NavItem>
                    <NavLink
                        className={stringBuilder({ active: activeTab === SEARCH_NAME })}
                        onClick={() => { setActiveTab(SEARCH_NAME); }}
                    >
                        <Translate id='teams.profile.teamRoster.activeRoster.add.searchName' />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={stringBuilder({ active: activeTab === SEARCH_TEAM })}
                        onClick={() => { setActiveTab(SEARCH_TEAM); }}
                    >
                        <Translate id='teams.profile.teamRoster.activeRoster.add.searchTeam' />
                    </NavLink>
                </NavItem>
                {from !== 'bench' &&
                    <NavItem>
                        <NavLink
                            className={stringBuilder({ active: activeTab === SEARCH_BIRTHDATE })}
                            onClick={() => { setActiveTab(SEARCH_BIRTHDATE); }}
                        >
                            <Translate id='teams.profile.teamRoster.activeRoster.add.searchBirthdate' />
                        </NavLink>
                    </NavItem>
                }
                <CanDoAction tag={NavItem} action="ADD" componentCode="teams" componentPermissionCode="teams_approbation">
                    <NavLink
                        className={stringBuilder({ active: activeTab === SEARCH_BRANCH })}
                        onClick={() => { setActiveTab(SEARCH_BRANCH); }}
                    >
                        <Translate id='teams.profile.teamRoster.activeRoster.add.searchBranch' />
                    </NavLink>
                </CanDoAction>
                <NavItem>
                    <NavLink
                        className={stringBuilder({ active: activeTab === 'validateMember' })}
                        onClick={() => { setActiveTab('validateMember'); }}
                    >
                        <Translate id='components.validateMember.title' />
                    </NavLink>
                </NavItem>
            </Nav>
            <ModalBody>
                <TabContent activeTab={activeTab}>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Search by Name */}
                    {/*--------------------------------------------------------------------------------*/}
                    <TabPane tabId={SEARCH_NAME}>
                        <TeamRosterSearchName
                            preformatSubmit={preformatSubmit}
                            getTableProps={getTableProps}
                        />
                    </TabPane>

                    {/*--------------------------------------------------------------------------------*/}
                    {/* Search by Team */}
                    {/*--------------------------------------------------------------------------------*/}
                    <TabPane tabId={SEARCH_TEAM}>
                        <TeamRosterSearchTeam
                            preformatSubmit={preformatSubmit}
                            activeTab={activeTab}
                            getTableProps={getTableProps}
                        />
                    </TabPane>

                    {/*--------------------------------------------------------------------------------*/}
                    {/* Search by Date of birth */}
                    {/*--------------------------------------------------------------------------------*/}
                    {from !== 'bench' &&
                        <TabPane tabId={SEARCH_BIRTHDATE}>
                            <TeamRosterSearchBirthdate
                                preformatSubmit={preformatSubmit}
                                activeTab={activeTab}
                                getTableProps={getTableProps}
                            />
                        </TabPane>
                    }


                    {/*--------------------------------------------------------------------------------*/}
                    {/* Search by branch */}
                    {/*--------------------------------------------------------------------------------*/}
                    <CanDoAction tag={TabPane} tabId={SEARCH_BRANCH} action="ADD" componentCode="teams" componentPermissionCode="teams_approbation">
                        <TeamRosterSearchBranch
                            preformatSubmit={preformatSubmit}
                            activeTab={activeTab}
                            getTableProps={getTableProps}
                        />
                    </CanDoAction>

                    {/*--------------------------------------------------------------------------------*/}
                    {/* Validate member */}
                    {/*--------------------------------------------------------------------------------*/}
                    <TabPane tabId='validateMember'>
                        <ValidateMemberInner team={cachedTeam} addMember={addMember} selected={selected} />
                    </TabPane>
                </TabContent>
                <div className="mt-3 text-muted">
                    {selected.length > 0 ?
                        <>
                            <button type="button" onClick={() => setCollapseIsOpen(!collapseIsOpen)} className="mb-3 reset-btn text-link">
                                <Translate id='teams.profile.teamRoster.activeRoster.add.selected' /> <span className="font-medium">{selected.length}</span>
                                <i style={{ transform: `rotate(${collapseIsOpen ? "180" : "0"}deg)` }} className="ml-1 d-inline-block mdi mdi-chevron-down transition" />
                            </button>
                            <Collapse isOpen={collapseIsOpen}>
                                {selected.map((member) => (
                                    <UserDisplay key={member.member_id} className="d-flex mb-2 p-2" card>
                                        <UserDisplay.Container>
                                            <UserImg
                                                abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                                src={member.picture?.full_path}
                                                filePos={member.picture?.file_position}
                                                width={40}
                                                alt={member.first_name + ' ' + member.last_name}
                                            />
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title>{member.first_name} {member.last_name}</UserDisplay.Title>
                                            <UserDisplay.Subtitle>#{member.unique_identifier}</UserDisplay.Subtitle>
                                            <UserDisplay.Subtitle><Translate id='members.profile.memberProfile.header.yearsOld' values={{ age: member.age }} /></UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                        <UserDisplay.Container className="ml-auto">
                                            <button
                                                type="button"
                                                onClick={() => removeMember(member.member_id)}
                                                className="reset-btn text-danger"
                                            >
                                                <span className="small">
                                                    <Translate id="misc.remove" />
                                                </span>
                                            </button>
                                        </UserDisplay.Container>
                                    </UserDisplay>
                                ))}
                            </Collapse>
                        </>
                        :
                        <>
                            <Translate id='teams.profile.teamRoster.activeRoster.add.selected' /><span className="text-dark font-medium ml-1">{selected.length}</span>
                        </>
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button disabled={selected.length <= 0} color='primary' type="button" onClick={nextStep}><Translate id='misc.next' /></Button>
                <Button color='primary' onClick={toggle} type="button" outline><Translate id='misc.cancel' /></Button>
            </ModalFooter>
        </>
    )
}

export default TeamRosterAddSearch;