import { stringBuilder } from "@spordle/helpers";

const SidebarGroupTitle = ({ tag: Tag = "li", children, className, ...props }) => {
    return (
        <Tag
            className={stringBuilder(
                "nav-small-cap text-capitalize mt-0 pb-1 clickable",
                className,
            )}
            {...props}
        >
            <span className="pl-2">
                {children}
            </span>
        </Tag>
    );
}

export default SidebarGroupTitle;