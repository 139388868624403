import { useContext, useEffect, useRef, useState } from 'react'
import OrganizationSearch from './../../../../components/organization/OrganizationSearch';
import { object, string, mixed } from 'yup';
import {
    Button,
    Card,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';

import { FormikDateTime, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import { Form, Formik } from 'formik';
import Required from '../../../../components/formik/Required';
import Translate from '@spordle/intl-elements';
import moment from 'moment';

// contexts
import { I18nContext } from './../../../../contexts/I18nContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { RolesContext } from '../../../../contexts/RolesContext';


const AddUserModalForm = ({ organizationId, organization, role, roleId, email, startDate, endDate, toggle, verifyEmail, setIsLoading, isLoading, ...props }) => {
    const organizationContext = useContext(OrganizationContext);
    const rolesContext = useContext(RolesContext);

    const roleRef = useRef(null);

    const [ inactiveRole, setInactiveRole ] = useState(false);

    useEffect(() => {
        roleRef.current?.getSpordleTable().filterChange();
    }, [])

    return (
        <Formik
            initialValues={{
                organization: organizationContext.organisation_id, // default organization
                role: '',
                startDate: '',
                endDate: '',
                email: '',
            }}
            validationSchema={object().shape({
                organization: string().required(<Translate id='organization.profile.users.addUser.validation.organization.required' />),
                role: string().required(<Translate id='organization.profile.users.addUser.validation.role.required' />),
                startDate: mixed()
                    .test({
                        name: 'start_date_format',
                        message: <Translate id='organization.profile.users.addUser.dates.startDate.format.validation' />,
                        test: (value) => {
                            if(value !== undefined){
                                return moment.isMoment(value)
                            }
                            return true // return true because the field is not required

                        },
                    })
                    .when('endDate', {
                        is: (endDate) => endDate !== undefined,
                        then: mixed()
                            .test({
                                name: 'start_date_end_date_check',
                                message: <Translate id='organization.profile.users.addUser.dates.validation' />,
                                test: function(value){ return moment(value).isBefore(this.parent.endDate) },
                            }),
                    }),
                endDate: mixed()
                    .test({
                        name: 'end_date_format',
                        message: <Translate id='organization.profile.users.addUser.dates.endDate.format.validation' />,
                        test: (value) => {
                            if(value !== undefined){
                                return moment.isMoment(value)
                            }
                            return true // return true because the field is not required

                        },
                    }),
                email: string().required(<Translate id='form.validation.email.required' />),
            })}
            onSubmit={(values, { setSubmitting }) => {
                setIsLoading('lazy');
                setSubmitting(false);
                verifyEmail(values.email, values.organization, values.role, values.startDate, values.endDate)
            }}
        >
            {(formik) => {
                return (
                    <Form>
                        <ModalHeader toggle={toggle}>
                            <Translate id='organization.profile.users.addUser.title' />
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for='organization' className='text-muted'><Translate id='organization.profile.users.addUser.fields.organization' /> <Required /></Label>
                                <OrganizationSearch
                                    name="organization"
                                    id="organization"
                                    manualError withFormik
                                    onChange={(org) => {
                                        formik.setFieldValue('role', '');
                                    }}
                                    treeOrganizationClick={(organization) => { formik.setFieldValue('organization', organization.organisation_id) }}
                                    selectedDefault={organizationContext.organisation_id}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for='role' className='text-muted'><Translate id='organization.profile.users.addUser.fields.role' /> <Required /></Label>
                                <FormikSelect
                                    name={'role'}
                                    id={'role_AddUserModalForm'}
                                    ref={roleRef}
                                    onOptionSelected={(role, spordle) => {
                                        const selectedOption = spordle.getSpordleTable().getData().find((option) => option.value === role[0]);
                                        setInactiveRole(!selectedOption.active);
                                    }}
                                    loadData={(from) => {
                                        switch (from){
                                            case 'CDM':
                                            case 'FILTER':
                                                return rolesContext.getOrganizationRoles(organizationContext.organisation_id)
                                                    .then((data) => {
                                                        return (
                                                            data.map((option) => ({
                                                                label: option.title,
                                                                id: option.role_id,
                                                                value: option.role_id,
                                                                active: option.active === '1',
                                                            }))
                                                        )
                                                    });
                                            default:
                                                break;
                                        }
                                    }}
                                    renderOption={(option) => (
                                        <div className='d-flex justify-content-between align-items-center'>
                                            {option.option.label}
                                            {!option.option.active &&
                                                    <i className='text-warning mdi mdi-alert-outline' />
                                            }
                                        </div>
                                    )}
                                    renderSelectedOption={(option) => (
                                        <div className='d-flex justify-content-between align-items-center'>
                                            {!option.active &&
                                                    <i className='text-warning mdi mdi-alert-outline mr-2' />
                                            }
                                            {option.label}
                                        </div>
                                    )}
                                />
                                {inactiveRole &&
                                    <span className='text-warning small'><Translate id='organization.profile.users.addUser.fields.role.inactive' /></span>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label for='email' className='text-muted'><Translate id='form.fields.email' /> <Required /></Label>
                                <FormikInputText id='email' name='email' label='' translateLabel={false} trim />
                            </FormGroup>
                            <Card body className='card-shadow'>
                                <I18nContext.Consumer>
                                    {({ getGenericLocale }) => (
                                        <>
                                            <div className='text-right small text-link'>* <Translate id='organization.profile.users.addUser.dates.optional' /></div>
                                            <FormGroup>
                                                <Label for='startDate' className='text-muted'><Translate id='organization.profile.users.addUser.dates.start' /></Label>
                                                <FormikDateTime
                                                    id='startDate'
                                                    name='startDate'
                                                    isValidDate={(current) => moment(current).isAfter(moment().subtract(1, "days"))}
                                                    timeFormat={false}
                                                    dateFormat='LL'
                                                    locale={getGenericLocale()}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for='endDate' className='text-muted'><Translate id='organization.profile.users.addUser.dates.end' /></Label>
                                                <FormikDateTime
                                                    id='endDate'
                                                    name='endDate'
                                                    isValidDate={(current) => moment(current).isAfter(moment())}
                                                    timeFormat={false}
                                                    dateFormat='LL'
                                                    locale={getGenericLocale()}
                                                />
                                            </FormGroup>
                                        </>
                                    )}
                                </I18nContext.Consumer>
                            </Card>
                            {isLoading === 'error' &&
                                <div className={'alert alert-danger'}><Translate id='misc.error' /></div>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='organization.profile.users.addUser.verifyEmail' /></Button>
                            <Button color='primary' type='button' outline onClick={toggle} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default AddUserModalForm
