import Translate from '@spordle/intl-elements';
import React, { } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Button,
    ModalBody,
    ModalFooter
} from "reactstrap";
import UserDisplay from '../userDisplay/UserDisplay';
import UserImg from '../UserImg';
import { RolesContext } from '../../contexts/RolesContext';
import withContexts from '../../helpers/withContexts';

class ManualRegistrationWaitingListConfirmation extends React.Component{

    close = () => {
        this.props.toggle();
    }

    goToWaitingList = () => {
        this.props.history.push(this.props.waitlistRoute);
    }

    render(){
        return (
            <>
                <ModalBody className="text-center">
                    {this.props.member &&
                        <UserDisplay card className="p-2 d-flex">
                            <UserDisplay.Container>
                                <UserImg
                                    abbr={this.props.member.first_name}
                                    src={this.props.member.picture?.full_path}
                                    filePos={this.props.member.picture?.file_position}
                                    alt={this.props.member.first_name + ' ' + this.props.member.last_name}
                                    width="50"
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container className="d-flex align-center justify-content-between w-100">
                                <div>
                                    <UserDisplay.Title>{this.props.member.first_name + ' ' + this.props.member.last_name}</UserDisplay.Title>
                                    <UserDisplay.Subtitle className="font-medium ml-1">{this.props.member.unique_identifier ? ` #${this.props.member.unique_identifier}` : '-'}</UserDisplay.Subtitle>
                                </div>
                                <div><i className="mdi mdi-check-circle font-24 text-success" /></div>
                            </UserDisplay.Container>
                        </UserDisplay>
                    }
                    <div className="h4 font-bold mb-2"><Translate id='memberProfile.registration.add.confirmation.title' /></div>
                    {this.props.waitingListNumber &&
                        <>
                            {this.props.RolesContext.hasAccessTo('clinics', 'manage_clinic_waiting_list') ?
                                <>
                                    <p className="mb-1"><Translate id='components.manualRegistration.waitingListConfirmation.number' /></p>
                                    <button
                                        type='button'
                                        className='reset-btn text-link'
                                        onClick={() => {
                                            this.close();
                                            this.goToWaitingList();
                                        }}
                                    >
                                        <p className="text-primary"># {this.props.waitingListNumber}</p>
                                    </button>
                                </>
                                :
                                <>
                                    <p className="mb-1"><Translate id='components.manualRegistration.waitingListConfirmation.number' /></p>
                                    # {this.props.waitingListNumber}
                                </>
                            }
                        </>
                    }
                </ModalBody>
                <ModalFooter>
                    {this.props.RolesContext.hasAccessTo(this.props.waitlistComponentCode, this.props.waitlistComponentPermissionCode) &&
                        <Button
                            onClick={() => {
                                this.close();
                                this.goToWaitingList();
                            }}
                            color='primary'
                        >
                            <Translate id='components.manualRegistration.waitingListConfirmation.goToWaitlist' />
                        </Button>
                    }
                    <Button color='primary' outline onClick={this.close}><Translate id='misc.close' /></Button>
                </ModalFooter>
            </>
        );
    }
}

export default withRouter(withContexts(RolesContext)(ManualRegistrationWaitingListConfirmation));