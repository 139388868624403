import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { SupervisionsContext } from "../../../contexts/SupervisionsContext";
import OfficiatingCoachesList from "./officialCoachesList/OfficiatingCoachesList";
import { stringBuilder } from "@spordle/helpers";
import OfficiatingReportsList from "./components/OfficiatingReportsList";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";

const OfficialSupervision = () => {
    const [ activeTab, setActiveTab ] = useState(1);
    const [ supervisionTypes, setSupervisionTypes ] = useState([]);
    const { organisation_id } = useContext(OrganizationContext);
    const { getSupervisionTypes } = useContext(SupervisionsContext);

    useEffect(() => {
        getSupervisionTypes({ organisation_id, active: 1 })
            .then(setSupervisionTypes)
            .catch(console.error);
    }, []);

    return (
        <>
            {/* Task View Header */}
            <ViewHeaderV2 title='officialSupervision.title' />

            <div className="px-4">
                <Nav tabs className="mb-4 font-medium font-16">
                    <NavItem>
                        <NavLink onClick={() => setActiveTab(1)} className={stringBuilder({ "active": activeTab === 1 })}>
                            <Translate id='officialSupervision.tab.reports' />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => setActiveTab(2)} className={stringBuilder({ "active": activeTab === 2 })}>
                            <Translate id='officialSupervision.tab.coaches' />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent className="bg-transparent" activeTab={activeTab}>
                    <TabPane tabId={1}>
                        <OfficiatingReportsList supervisionTypes={supervisionTypes} />
                    </TabPane>
                    <TabPane tabId={2}>
                        <OfficiatingCoachesList supervisionTypes={supervisionTypes} />
                    </TabPane>
                </TabContent>
            </div>
        </>
    );
}

export default OfficialSupervision;