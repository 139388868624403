import { useState } from "react";
import CollapsibleCard from "../../../../../../../components/collapsibleCard/CollapsibleCard";
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { FormField } from "./SidepanelFormField";

export const CustomFormGroup = ({ group, index, ...props }) => {
    const [ isLoading, setIsLoading ] = useState(false);

    // If this group only has deleted question with no answer, return null
    if(!group.fields.some((field) => ((field.deleted_at && field.answer) || !field.deleted_at)))return null;

    return (
        <OverlayLoader className="mb-2" isLoading={isLoading}>
            <CollapsibleCard
                title={<DisplayI18n field="name" i18n={group.i18n} defaultValue={group.name} />}
                initOpen
            >
                { group.fields.map((field, i) => (
                    <FormField
                        key={field.custom_form_field_id + group.custom_form_group_id}
                        setIsLoading={setIsLoading}
                        groupIndex={index}
                        field={field}
                        index={i}
                        {...props}
                    />
                ))}
            </CollapsibleCard>
        </OverlayLoader>
    )
}