import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedPlural } from 'react-intl';
import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import { Badge, Card, Collapse, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import ConfirmModal from '../../../../../../../components/confirmModal/ConfirmModal';
import { fail, success } from '@spordle/toasts';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import { stringBuilder } from '@spordle/helpers';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';

const TransationRefunds = ({ refunds, totalRefundAmount, invoiceNumber, onDeleteRefund, disableActions }) => {
    return (
        (refunds && refunds.length > 0) &&
            <div className='mb-3'>
                <div className="h4 font-bold">
                    <FormattedPlural
                        value={refunds.length}
                        one={<Translate id='members.profile.transactions.memberProfileTransactions.refund' />}
                        other={<Translate id='members.profile.transactions.memberProfileTransactions.refunds' />}
                    />
                </div>
                <Card className='card-shadow mb-3 p-3'>
                    {refunds.map((refund) => (
                        <RefundItem
                            key={refund.invoice_payment_id}
                            refund={refund}
                            invoiceNumber={invoiceNumber}
                            onDeleteRefund={onDeleteRefund}
                            disableActions={disableActions}
                        />
                    ))}
                    <div className='text-right pr-2'>
                        <span className='font-bold text-dark'>
                            <Translate id='members.profile.transactions.memberProfileTransactions.total' />
                            <span className='mx-1'>:</span>
                        </span>
                        <CurrencyFormat value={totalRefundAmount / 100} />
                    </div>
                </Card>
            </div>

    );
}

const RefundItem = ({ refund, invoiceNumber, onDeleteRefund, disableActions }) => {
    const [ noteIsOpen, setNoteOpen ] = useState(false);
    const membersContext = useContext(MembersContext);

    return (
        <>
            <Card className='border p-2 mb-2 card-shadow'>
                <div onClick={refund.internal_note ? () => { setNoteOpen(!noteIsOpen) } : undefined} className={stringBuilder({ 'clickable': !!refund.internal_note })}>
                    <div className='d-flex justify-content-between'>
                        <DateFormat value={refund.refund_date} />
                        <UncontrolledDropdown inNavbar>
                            <DropdownToggle tag='button' className='reset-btn text-link' onClick={(e) => e.stopPropagation()} disabled={disableActions || refund.refund_method?.code === "CREDITCARD"}><i className='mdi mdi-dots-horizontal font-20' /></DropdownToggle>
                            <DropdownMenu right>
                                <ConfirmModal
                                    toggler={(toggle) => (
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggle();
                                            }}
                                        >
                                            <Translate id='misc.delete' />
                                        </DropdownItem>
                                    )}
                                    modalTitle={<Translate id='members.profile.transactions.memberProfileTransactions.refunds.deleteText' values={{ date: <DateFormat value={refund.refund_date} /> }} />}
                                    actionButtonText='misc.confirm'
                                    translateActionButtonText
                                    color='danger'
                                    onConfirm={() => {
                                        return membersContext.deleteRefund(invoiceNumber, refund.invoice_payment_id)
                                            .then(() => {
                                                success();
                                                onDeleteRefund();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message);
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    }}
                                />
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <div className='d-flex justify-content-between mb-1'>
                        <Badge color={refund.status === 'COMPLETED' ? 'success' : (refund.status === 'PENDING' || refund.status === 'NEED_REVIEW' || refund.status === 'PARTIALLY_PAID') ? 'warning' : 'danger'} size="lg" pill>
                            <Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.status.' + refund.status} />
                        </Badge>
                        <div>
                            <DisplayI18n field='name' defaultValue={refund.refund_method.name} i18n={refund.refund_method?.i18n} />
                        </div>
                        <CurrencyFormat value={refund.amount / 100} />
                    </div>
                    {refund.internal_note &&
                        <>
                            <Collapse isOpen={noteIsOpen}>
                                <div className='bg-light p-1 rounded'>
                                    {refund.internal_note}
                                </div>
                            </Collapse>
                            <div className='d-flex justify-content-center mb-n3 mt-n2'>
                                <i className='font-20 mdi mdi-chevron-down d-block' style={{ transition: 'transform 0.15s ease-in-out', transform: `rotate(${noteIsOpen ? '180' : '0'}deg)` }} />
                            </div>
                        </>
                    }
                </div>
            </Card>
        </>
    )
}

TransationRefunds.propTypes = {
    refunds: PropTypes.array.isRequired,
};

export default TransationRefunds;