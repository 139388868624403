import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import React from "react";
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { fail } from '@spordle/toasts';
// Contexts
import { RegistrationFeeContext } from "../../../../../contexts/RegistrationFeeContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import withContexts from "../../../../../helpers/withContexts";
import RegistrationFeesSidepanelCatalog from "./components/RegistrationFeesSidepanelCatalog";
import RegistrationFeesSidepanelFee from "./components/RegistrationFeesSidepanelFee";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import { Tooltip } from "@mantine/core";


class RegistrationFeesSidepanel extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            activeTab: '1',
            isLoading: false,

            registrationFeeData: null,
        }
        this.toggle = this.toggleTab.bind(this);
    }

    toggleTab(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    toggleState = (bool) => this.setState((prevState) => ({ isLoading: bool ?? !prevState.isLoading }))

    handleDelete = () => {
        return this.props.RegistrationFeeContext.deleteRegistrationFee(this.props.getCommonValue('registration_fee_id'))
            .then(() => {
                this.props.tableRef.deleteRow(this.props.getCommonValue('registration_fee_id')).then(this.props.toggle)
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.toggleState(false);
                }
            })
    }

    componentDidMount(){
        this.getRegistrationFee()
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0].registration_fee_id !== this.props.selectedRows[0].registration_fee_id){
            this.getRegistrationFee()
        }
    }

    getRegistrationFee = () => {
        this.setState(() => ({ isLoading: true }))
        return this.props.RegistrationFeeContext.getRegistrationFees()
            .then((rFees) => {
                const rFee = rFees.find((fee) => fee.registration_fee_id === this.props.selectedRows[0].registration_fee_id)
                this.setState(() => ({
                    registrationFeeData: rFee,
                    isLoading: false,
                }))
                return rFee;
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    })
                    this.setState(() => ({ isLoading: false }))
                }
            })
    }

    refreshSidepanel = () => {
        return this.getRegistrationFee()
            .then((fee) => this.props.syncRows(fee))
            .catch((error) => console.error(error.message));
    }

    getTeamCategoryAges = () => {
        if(this.props.selectedRows[0].team_category.min_age != this.props.selectedRows[0].team_category.max_age)
            return <Translate id="misc.ageRange" values={{ from: this.props.selectedRows[0].team_category.min_age, to: this.props.selectedRows[0].team_category.max_age }} />
        return <Translate id='misc.yearsOld' values={{ age: this.props.selectedRows[0].team_category.min_age }} />
    }

    getRowAges = () => {
        if(this.props.selectedRows[0].min_age != this.props.selectedRows[0].max_age)
            return <Translate id="misc.ageRange" values={{ from: this.props.selectedRows[0].min_age, to: this.props.selectedRows[0].max_age }} />
        return <Translate id='misc.yearsOld' values={{ age: this.props.selectedRows[0].min_age }} />
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <div className="p-3">
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />
                        <SidePanel.ActionsMenu>
                            {/* OPENS ADD MODAL IN DUPLICATION MODE */}
                            <CanDoAction action='ADD' componentCode='catalog' componentPermissionCode='registration_fees'>
                                <SidePanel.MenuAction onClick={() => { this.props.toggleDuplicate(this.props.selectedRows[0]) }}>
                                    <Translate id='catalog.registrationFees.duplicate.title' />
                                </SidePanel.MenuAction>
                            </CanDoAction>
                            <CanDoAction action='DELETE' componentCode='catalog' componentPermissionCode='registration_fees'>
                                <SidePanel.MenuDelete
                                    translateModalMsg
                                    modalMsg='catalog.clinicItems.sidepanel.delete.message'
                                    onConfirm={this.handleDelete}
                                    modalTitle={
                                        <>
                                            <DisplayI18n
                                                field="short_name"
                                                i18n={this.props.selectedRows[0].division?.i18n}
                                                defaultValue={this.props.selectedRows[0].division?.short_name}
                                            />
                                            {(this.props.selectedRows[0].team_category && this.props.selectedRows[0].team_category?.class) ? '-' : ''}
                                            <DisplayI18n
                                                field="short_name"
                                                i18n={this.props.selectedRows[0].team_category?.class?.i18n}
                                                defaultValue={this.props.selectedRows[0].team_category?.class?.short_name}
                                            />
                                        </>
                                    }
                                />
                            </CanDoAction>
                        </SidePanel.ActionsMenu>
                    </div>
                    <SidePanel.Title>
                        <DisplayI18n field='name' i18n={this.props.selectedRows[0].i18n} defaultValue={this.props.selectedRows[0].name} />
                    </SidePanel.Title>
                    {this.props.selectedRows[0].manage_waiting_list == 1 &&
                        <>
                            <i className='text-primary mdi mdi-clock-alert-outline mr-1' />
                            <Translate id='catalog.registrationFees.add.form.waitingList' />
                        </>
                    }
                    <SidePanel.Subtitle>
                        <DisplayI18n field='name' defaultValue={this.props.selectedRows[0].member_type.name} i18n={this.props.selectedRows[0].member_type.i18n} />
                    </SidePanel.Subtitle>
                    {this.props.selectedRows[0].team_category ?
                        <>
                            {this.props.selectedRows[0].team_category.gender &&
                                <SidePanel.Subtitle>
                                    <Translate
                                        id={`form.fields.gender.${this.props.selectedRows[0].team_category.gender.toLowerCase()}`}
                                        defaultMessage={this.props.selectedRows[0].team_category.gender}
                                    />
                                </SidePanel.Subtitle>
                            }
                            {(this.props.selectedRows[0].team_category.min_age && this.props.selectedRows[0].team_category.max_age) &&
                                <SidePanel.Subtitle>
                                    {(this.props.selectedRows[0].min_age && this.props.selectedRows[0].max_age && (this.props.selectedRows[0].min_age !== this.props.selectedRows[0].team_category.min_age || this.props.selectedRows[0].max_age !== this.props.selectedRows[0].team_category.max_age)) ?
                                        <>
                                            <Tooltip
                                                withArrow wrapLines
                                                zIndex={3000}
                                                width={200}
                                                label={<Translate id="catalog.registrationFees.modifiedAges" />}
                                            >
                                                <>
                                                    <div><del>{this.getTeamCategoryAges()}</del><i className="mdi mdi-information-outline text-primary ml-1" /></div>
                                                    <div>{this.getRowAges()}</div>
                                                </>
                                            </Tooltip>
                                        </>
                                        :
                                        this.getTeamCategoryAges()
                                    }
                                </SidePanel.Subtitle>
                            }
                        </>
                        :
                        (this.props.selectedRows[0].min_age && this.props.selectedRows[0].max_age) ?
                            <SidePanel.Subtitle>
                                <div>{this.getRowAges()}</div>
                            </SidePanel.Subtitle>
                            :
                            null
                    }

                </div>
                <Nav tabs>
                    <NavItem className="w-50 text-center">
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                        >
                            <Translate id='catalog.registrationFees.sidePanel.tab.fee' />
                        </NavLink>
                    </NavItem>
                    <NavItem className="w-50 text-center">
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                        >
                            <Translate id='catalog.registrationFees.sidePanel.tab.catalog' />
                        </NavLink>
                    </NavItem>
                </Nav>
                <div className="p-3 mb-5">
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <RegistrationFeesSidepanelFee
                                panelProps={this.props}
                                fee={this.props.selectedRows[0]}
                                memberTypes={this.props.memberTypes}
                                divisions={this.props.divisions}
                                refreshSidepanel={this.refreshSidepanel}
                            />
                        </TabPane>
                        <TabPane tabId="2">
                            <RegistrationFeesSidepanelCatalog
                                toggle={this.props.toggle}
                                spordleTable={this.props.tableRef}
                                syncRows={this.props.syncRows}
                                createNewValues={this.props.createNewValues}
                                toggleState={this.toggleState}
                                installmentModes={this.props.installmentModes}
                                fee={this.props.selectedRows[0]}
                                forms={this.props.forms}
                                waivers={this.props.waivers}
                                registrationFeeData={this.state.registrationFeeData}
                                getRegistrationFee={this.getRegistrationFee}
                            />
                        </TabPane>
                    </TabContent>
                </div>
            </OverlayLoader>
        )
    }
}

export default withContexts(RegistrationFeeContext)(RegistrationFeesSidepanel);