import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import { stringBuilder } from '@spordle/helpers';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import SpordleSelect from '@spordle/spordle-select';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Collapse, FormGroup, Label, ModalBody, Row } from 'reactstrap';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import CustomAlert from '../../../../../../components/CustomAlert';
import EmptyLayout from '../../../../../../components/table/EmptyLayout';
import { fail } from "@spordle/toasts";
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import { CartsContext } from '../../../../../../contexts/CartsContext';
import { ClinicsContext } from '../../../../../../contexts/ClinicsContext';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { QualificationCategoriesContext } from '../../../../../../contexts/QualificationCategoriesContext';
import { QualificationsContext } from '../../../../../../contexts/QualificationsContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import ClinicCategory from '../../../../ClinicCategory';

const ClinicProfileRegistrationAddExchange = (props) => {
    const [ allClinics, setAllClinics ] = useState([]);
    const [ error, setError ] = useState(null);

    const clinicsContext = useContext(ClinicsContext);
    const cartsContext = useContext(CartsContext);
    const qualificationsContext = useContext(QualificationsContext);
    const qualificationCategoriesContext = useContext(QualificationCategoriesContext);
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);

    // eurobeat

    useEffect(() => {
        cartsContext.clearCarts().catch(console.error);
    }, [])

    const selectClinic = (clinicId) => {
        props.setModalLoading(true)
        cartsContext.exchangeCart(props.invoiceItemId, clinicId)
            .then(async(shopping_cart_id) => {
                const cart = await cartsContext.getCart(shopping_cart_id);
                const grandTotal = parseInt(cart.cart_summary?.grand_total)
                const clinic = await clinicsContext.getClinic(clinicId);
                const member = await membersContext.getMember(props.member.member_id)
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    });

                props.setMember(member)
                props.setTotalAmount(grandTotal)
                if(grandTotal <= 0){
                    props.setPaymentMethod('CASH');
                }
                props.setCustomForms(clinic?.custom_form?.[0]?.custom_form_id)
                props.setShoppingCartId(shopping_cart_id);
                props.onSubmit();
                props.setModalLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    if(error.i18n){
                        setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                    }else if(error.origin){
                        setError(<Translate id={'clinics.profile.attendees.addModal.warnings.' + error.origin?.split(',')[0]} defaultMessageId='misc.error' />)
                    }else{
                        setError(<Translate id={'clinics.profile.attendees.addModal.exchange.errors.' + error.code} defaultMessageId='misc.error' />)
                    }
                    console.error(error.message)
                    props.setModalLoading(false);
                }
            })
    }

    return (
        <ModalBody>
            <SpordleTableProvider
                id='ClinicExchangeTable'
                dataIndex='clinic_id'
                pagination={5}
                initFilter={{
                    category: '',
                    qualification: '',
                }}
                searchKeys={[
                    { name: 'clinic_reference', weight: 2 },
                    `i18n.${i18nContext.getGenericLocale()}.name`,
                    'name',
                ]}
                emptyLayout={<EmptyLayout hideImg />}
                loadData={(from, data, spordleTable) => {
                    switch (from){
                        case 'FILTER':
                            if(data.filters.category || data.filters.qualification){
                                return Promise.resolve(allClinics.filter((clinic) => data.filters.qualification === clinic.qualification.qualification_id || data.filters.category === clinic.qualification.qualification_category.qualification_category_id))
                            }
                            return Promise.resolve(allClinics)

                        case 'CDM':
                            return clinicsContext.getClinics({
                                active_status: [ "PRIVATE", "OPEN_PUBLIC_SIGNUP" ],
                                only_hosted_by_my_organisations: 1,
                                qualification_category_id: data.filters.category,
                                qualification_id: data.filters.qualification,
                            })
                                .then((clinics) => {
                                    setAllClinics(clinics);
                                    return clinics
                                })
                        default:
                            break;
                    }
                }}
                viewMode='GRID'
                gridConfig={{
                    col: { xs: 12 },
                    gridRender: (data) => (
                        <UserDisplay card className={stringBuilder('w-100 mb-2', { 'bg-light-inverse': data.clinic_id === props.currentClinicId || data.sold_out == 1 })}>
                            <UserDisplay.Container>
                                <UserDisplay.Title>
                                    <ClinicCategory color={isNaN(parseInt(data.qualification.qualification_category.color)) ? 0 : data.qualification.qualification_category.color}>
                                        <DisplayI18n field='name' defaultValue={data.name} i18n={data.i18n} />
                                    </ClinicCategory>
                                </UserDisplay.Title>
                                <UserDisplay.Subtitle>#{data.clinic_reference}</UserDisplay.Subtitle>
                                <UserDisplay.Subtitle>
                                    {data.total_amount <= 0 ?
                                        <Translate id='clinics.profile.attendees.sidepanel.paymentMethod.FREE' />
                                        :
                                        <CurrencyFormat value={data.total_amount / 100} />
                                    }
                                </UserDisplay.Subtitle>
                            </UserDisplay.Container>
                            <UserDisplay.Container className='ml-auto'>
                                {data.clinic_id === props.currentClinicId ?
                                    <Translate id='clinics.profile.attendees.addModal.exchange.currentClinic' />
                                    : data.sold_out == 1 ?
                                        <span className='text-warning'><i className='mr-1 mdi mdi-alert-outline' /><Translate id='clinics.profile.attendees.addModal.exchange.soldOut' /></span>
                                        :
                                        <Button
                                            color='primary'
                                            type='button'
                                            onClick={() => selectClinic(data.clinic_id)}
                                        ><Translate id='misc.select' />
                                        </Button>
                                }
                            </UserDisplay.Container>
                        </UserDisplay>
                    ),
                }}
            >
                {(spordleTable) => (
                    <>
                        <Row form>
                            <Col sm='6'>
                                <FormGroup>
                                    <Label className='text-muted'><Translate id='clinics.profile.attendees.addModal.exchange.categories' /></Label>
                                    <SpordleSelect
                                        id='ClinicProfileRegistrationAddExchangeCategorySelect' name='categoryId' clearable
                                        renderOption={(option) => (
                                            <ClinicCategory color={option.option.color}>
                                                <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                            </ClinicCategory>
                                        )}
                                        onOptionSelected={(values) => {
                                            spordleTable.filterChange('category', values[0] || '');
                                        }}
                                        searchKeys={[
                                            'label',
                                            `i18n.${i18nContext.getGenericLocale()}.name`,
                                        ]}
                                        loadData={(from, extra, spordleTable) => {
                                            switch (from){
                                                case 'CDM':
                                                    return qualificationCategoriesContext.getQualificationCategories(true)
                                                        .then((categories) => {
                                                            return categories.reduce((newArray, cat) => {
                                                                if(cat.active == 1){
                                                                    newArray.push({
                                                                        value: cat.qualification_category_id,
                                                                        label: cat.name,
                                                                        color: isNaN(parseInt(cat.color)) ? 0 : cat.color,
                                                                        i18n: cat.i18n,
                                                                    })
                                                                }
                                                                return newArray
                                                            }, [])
                                                        })
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <Label className='text-muted'><Translate id='clinics.profile.attendees.addModal.exchange.qualifications' /></Label>
                                    <SpordleSelect
                                        id='ClinicProfileRegistrationAddExchangeQualificationSelect' name='qualificationId' clearable
                                        renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                        onOptionSelected={(values) => {
                                            spordleTable.filterChange('qualification', values[0] || '');
                                        }}
                                        searchKeys={[
                                            'label',
                                            `i18n.${i18nContext.getGenericLocale()}.name`,
                                            'level.name',
                                            `level.i18n.${i18nContext.getGenericLocale()}.name`,
                                        ]}
                                        loadData={(from, extra, spordleTable) => {
                                            switch (from){
                                                case 'CDM':
                                                    return qualificationsContext.getQualifications()
                                                        .then((qualifications) => {
                                                            return qualifications.reduce((newArray, qual) => {
                                                                if(qual.active == 1){
                                                                    newArray.push({
                                                                        value: qual.qualification_id,
                                                                        label: qual.name,
                                                                        i18n: qual.i18n,
                                                                        level: qual.qualification_level,
                                                                    })
                                                                }
                                                                return newArray
                                                            }, [])
                                                        })
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm='12'>
                                <FormGroup>
                                    <SpordleTableProvider.SearchInput />
                                </FormGroup>
                            </Col>
                        </Row>

                        <SpordleTableView hidePagination />

                        <Collapse isOpen={!!error} appear mountOnEnter unmountOnExit>
                            {error &&
                                    <CustomAlert className='mt-3' color='danger' text={error} translateText={false} withTitle toggle={() => setError(null)} />
                            }
                        </Collapse>

                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='mt-3'>
                                <Button color='primary' outline onClick={() => props.goToPrevious()} type='button'><Translate id='misc.previous' /></Button>
                            </div>
                            <SpordleTableProvider.Paginator />
                        </div>
                    </>
                )}
            </SpordleTableProvider>
        </ModalBody>
    )
}

export default ClinicProfileRegistrationAddExchange