import SpordleTableProvider from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Container } from "reactstrap";
import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../../components/table/EmptyLayout";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import { DocumentTypesContext } from "../../../../../contexts/DocumentTypesContext";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { removeHTML } from "../../../../../helpers/helper";
import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext } from "../../../../../helpers/i18nHelper";
import settingsDocumentsRoutes from "../../../../../routes/tabRoutes/organizationSettings/settingsDocumentsRoutes";
import SidePanelOrganizationDocumentTypes from "./documentTypes/SidePanelOrganizationDocumentTypes";
import SubRouteSettingWrapper from "../../../components/SubRouteSettingWrapper";

const OrganizationSettingsDocuments = () => {
    const documentTypesContext = useContext(DocumentTypesContext);
    const i18nContext = useContext(I18nContext);

    const getDocTypes = () => {
        return documentTypesContext.getDocumentTypes()
    }

    return (
        <Container>
            <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <div>
                            <SpordlePanelTable
                                allowOutsideClick
                                sidePanel={(props) => <SidePanelOrganizationDocumentTypes {...props} />}
                                dataIndex='document_type_id'
                                table={(panelProps) => {
                                    return (
                                        <SpordleTableProvider
                                            id='OrganizationDocumentTypes'
                                            tableHover clickable striped
                                            bordered
                                            ref={panelProps.spordleTableRef}
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                            dataIndex='document_type_id'
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                                'name',
                                            ]}
                                            columns={[
                                                ...i18nHelper.getTableColumns('name'),
                                                {
                                                    label: <Translate id='settings.documentTypes.status' />,
                                                    key: "active",
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                            ]}
                                            onColumnClick={(e, documentType) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        panelProps.onSingleSelectChange(documentType);
                                                        break;
                                                }
                                            }}
                                            desktopWhen='md'
                                            emptyLayout={<EmptyLayout translateTitle title='settings.documentTypes.empty' />}
                                            renderRow={(columnKey, documentType, spordleTable) => {
                                                if(columnKey.includes('i18n')){
                                                    const lang = columnKey.split('.')[1]; // Get column key lang for i18n helper
                                                    const desc = documentType.i18n?.[lang]?.description;

                                                    return (
                                                        <UserDisplay>
                                                            <UserDisplay.Container>
                                                                <UserDisplay.Title>
                                                                    <DisplayI18n
                                                                        field={columnKey}
                                                                        defaultValue={documentType.name}
                                                                        i18n={documentType.i18n}
                                                                    />
                                                                </UserDisplay.Title>
                                                                <UserDisplay.Subtitle style={{ maxWidth: 300 }} className="text-truncate">
                                                                    {desc ? removeHTML(desc) : "-"}
                                                                </UserDisplay.Subtitle>
                                                            </UserDisplay.Container>
                                                        </UserDisplay>
                                                    )
                                                }
                                                switch (columnKey){
                                                    case 'active':
                                                        return (
                                                            <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                {documentType.active == '1' ?
                                                                    <i className="text-primary mdi mdi-check" />
                                                                    :
                                                                    <i className="text-danger mdi mdi-close" />
                                                                }
                                                            </div>
                                                        )
                                                    default:
                                                        break;
                                                }
                                            }}
                                            rowIsHighlighted={(documentType) => !!documentType.checked}
                                            loadData={(from, filterData, spordleTable) => {
                                                switch (from){
                                                    case 'REFRESH':
                                                        spordleTable.setLoading();
                                                    case 'CDM':
                                                        return getDocTypes();
                                                    default:
                                                        break;
                                                }
                                            }}
                                        >
                                            <SubRouteSettingWrapper
                                                back="organization"
                                                routes={settingsDocumentsRoutes}
                                            />
                                            {/* <DocumentSettings />
                                            <OrganizationDocumentTypes /> */}
                                        </SpordleTableProvider>
                                    )
                                }}
                            />
                        </div>
                    )}
                </I18nHelperContext.Consumer>
            </I18nHelperContextProvider>
        </Container>
    )
}

export default OrganizationSettingsDocuments