import { FormikDateTime, FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate, { DateFormat } from "@spordle/intl-elements";
import moment from "moment";
import { useContext } from "react";
import { Col, FormGroup, Row } from "reactstrap";
import { array, mixed, object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import FormikEditable from "../../../../../../../components/formik/FormikEditable";
import FormikEditableFile from "../../../../../../../components/formik/FormikEditableFile";
import { fail, success } from '@spordle/toasts';
import { compareAndFormatFiles } from "../../../../../../../components/uploader/uploadHelpers";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import { DISCRIMINATION_REASONS } from "../../../DiscriminationHelper";
import { displayI18n, DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { I18nContext } from "../../../../../../../contexts/I18nContext";
import { stringBuilder } from "@spordle/helpers";

const DiscriminationSidePanelIncident = ({ readOnly, canEdit, selectedRows, updateValue, updateValues, setIsLoading, syncNewVal, refreshData, setAddOutcomeIsOpen, setAddSuspensionIsOpen, editOutcome }) => {
    const {
        createDiscriminationAttachments,
        getDiscriminationAttachments,
        downloadDiscriminationAttachment,
        deleteDiscriminationAttachment,
        getMaltreatmentLocations,
        getMaltreatmentTypes,
    } = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);

    const handleOnFileClick = (file) => {
        return downloadDiscriminationAttachment(
            null,
            selectedRows[0].discrimination_id,
            file.discrimination_attachment_id,
        ).catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            }
        })
    }

    const updateDiscriminationAttachments = async(files) => {
        const { toDelete, toCreate } = compareAndFormatFiles(selectedRows[0].discrimination_attachments || [], files);
        let newVal = { attachments: [] };

        if(toDelete?.length > 0){
            await Promise.all(
                toDelete.map((att) => (
                    deleteDiscriminationAttachment(null, selectedRows[0].discrimination_id, att.discrimination_attachment_id)
                )),
            ).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        }

        if(toCreate?.length > 0 && toCreate?.[0]?.length > 0){
            // separateFilesPerSize returns an array of subarray of files in which the total size does not exceed 14MB.
            // Avoid php errors for the api
            await Promise.all(
                toCreate.map((subArr) => {
                    return createDiscriminationAttachments(
                        null,
                        selectedRows[0].discrimination_id,
                        subArr,
                    ).catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })
                }),
            );

        }

        newVal = { discrimination_attachments: await getDiscriminationAttachments(null, selectedRows[0].discrimination_id) };
        syncNewVal(newVal, true);
    }

    return (
        <>
            <div className="h5 mt-2 font-bold"><Translate id='discrimination.label.overview' /></div>
            <Row form>
                <Col sm={12} className="form-group">
                    <Row form className="w-100">
                        <Col sm={6} className="text-muted">
                            <Translate id='discrimination.global.incidentLocation' />
                        </Col>
                        <Col sm={6} className="text-muted">
                            <Translate id='discrimination.label.arena' />
                        </Col>
                    </Row>
                    <FormikEditable
                        id="maltreatment_location_id"
                        readOnly={readOnly}
                        initialValues={{
                            location: selectedRows[0].maltreatment_location,
                            maltreatment_location_id: selectedRows[0].maltreatment_location?.maltreatment_location_id || '',
                            arena: selectedRows[0].arena || "",
                        }}
                        validationSchema={object().shape({
                            location: object().nullable(),
                            arena: string().when('location', {
                                is: (v) => v?.need_playing_surface == 1,
                                then: (s) => s.required(<Translate id='discrimination.form.arena.required' />),
                            }).max(100, <Translate id="form.validation.string.max" values={{ count: 100 }} />),
                            maltreatment_location_id: string().required(<Translate id='discrimination.form.complaintType.required' />),
                        })}
                        manualIcon
                        className="w-100"
                        disabled={!canEdit}
                        onSubmit={({ maltreatment_location_id, arena }) => {
                            if(maltreatment_location_id !== selectedRows[0].maltreatment_location?.maltreatment_location_id || arena !== selectedRows[0].arena){
                                updateValues({
                                    maltreatment_location_id: maltreatment_location_id,
                                    arena: arena,
                                });
                            }
                        }}
                    >
                        {(isEditing, _, formik) => {
                            if(!isEditing){
                                return (
                                    <Row form className="w-100 position-relative">
                                        <Col sm={6}>
                                            <DisplayI18n
                                                field="name"
                                                i18n={selectedRows[0]?.maltreatment_location?.i18n}
                                                defaultValue={selectedRows[0]?.maltreatment_location?.name}
                                            />
                                        </Col>
                                        <Col sm={6} className="text-break">
                                            {selectedRows[0].arena || "-"}
                                            {!readOnly && canEdit && <i style={{ top: '50%', transform: 'translateY(-50%)' }} className="right-0 position-absolute mdi mdi-pencil text-primary" />}
                                        </Col>
                                    </Row>
                                )
                            }
                            return (
                                <Row form className="w-100">
                                    <Col sm={6}>
                                        <FormikSelect
                                            name="maltreatment_location_id"
                                            id="maltreatment_location_id"
                                            search={false}
                                            renderOption={({ option, isSelected }) => (
                                                <>
                                                    <DisplayI18n
                                                        field="name"
                                                        i18n={option.ressource.i18n}
                                                        defaultValue={option.label}
                                                    />
                                                    <small className={stringBuilder("d-block", `text-${isSelected ? 'light' : 'muted'}`)}>
                                                        <DisplayI18n
                                                            field="description"
                                                            i18n={option.ressource.i18n}
                                                            defaultValue={option.ressource.description}
                                                        />
                                                    </small>
                                                </>
                                            )}
                                            onOptionSelected={([ val ], select) => {
                                                const location = select.getSpordleTable().getData().find(({ value }) => value === val);

                                                formik.setFieldValue('location', location?.ressource, true);
                                                formik.setFieldValue('maltreatment_location_id', val, true);
                                                return true;
                                            }}
                                            renderSelectedOption={(option) => (
                                                <DisplayI18n
                                                    field="name"
                                                    i18n={option.ressource.i18n}
                                                    defaultValue={option.label}
                                                />
                                            )}
                                            searchKeys={[
                                                `ressource.i18n.${getGenericLocale()}.name`,
                                            ]}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return getMaltreatmentLocations({ active: 1 })
                                                            .then((locations) => (
                                                                locations.map((loc) => ({
                                                                    value: loc.maltreatment_location_id,
                                                                    label: loc.name,
                                                                    ressource: loc,
                                                                }))
                                                                    .sort((a, b) => {
                                                                        const aName = displayI18n('name', a.ressource.i18n, a.label, getGenericLocale());
                                                                        const bName = displayI18n('name', b.ressource.i18n, b.label, getGenericLocale());
                                                                        return aName.localeCompare(bName);
                                                                    })
                                                            ))
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <FormikInputText name="arena" />
                                    </Col>
                                </Row>
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm={12} className="form-group">
                    <div className="text-muted">
                        <Translate id='discrimination.global.maltreatmentType' />
                    </div>
                    <FormikEditable
                        noConfirmation
                        id="maltreatment_type_id"
                        readOnly={readOnly}
                        initialValues={{
                            maltreatment_type_id: selectedRows[0].maltreatment_type?.maltreatment_type_id || '',
                        }}
                        validationSchema={object().shape({
                            maltreatment_type_id: string().required(<Translate id='discrimination.form.complaintType.required' />),
                        })}
                        disabled={!canEdit}
                        onSubmit={({ maltreatment_type_id }) => {
                            if(maltreatment_type_id !== selectedRows[0].maltreatment_type?.maltreatment_type_id){
                                updateValue('maltreatment_type_id', maltreatment_type_id);
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    <DisplayI18n
                                        field="name"
                                        i18n={selectedRows[0]?.maltreatment_type?.i18n}
                                        defaultValue={selectedRows[0]?.maltreatment_type?.name}
                                    />
                                )
                            }
                            return (
                                <FormikSelect
                                    name="maltreatment_type_id"
                                    id="maltreatment_type_id"
                                    autoFocus
                                    menuIsDefaultOpen
                                    search={false}
                                    onOptionSelected={() => options.stopEditing()}
                                    renderOption={({ option, isSelected }) => (
                                        <>
                                            <DisplayI18n
                                                field="name"
                                                i18n={option.ressource.i18n}
                                                defaultValue={option.label}
                                            />
                                            <small className={stringBuilder("d-block", `text-${isSelected ? 'light' : 'muted'}`)}>
                                                <DisplayI18n
                                                    field="description"
                                                    i18n={option.ressource.i18n}
                                                    defaultValue={option.ressource.description}
                                                />
                                            </small>
                                        </>
                                    )}
                                    renderSelectedOption={(option) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={option.ressource.i18n}
                                            defaultValue={option.label}
                                        />
                                    )}
                                    searchKeys={[
                                        `ressource.i18n.${getGenericLocale()}.name`,
                                    ]}
                                    loadData={(from) => {
                                        switch (from){
                                            case 'CDM':
                                                return getMaltreatmentTypes({ active: 1 })
                                                    .then((types) => (
                                                        types.map((type) => ({
                                                            value: type.maltreatment_type_id,
                                                            label: type.name,
                                                            ressource: type,
                                                        }))
                                                            .sort((a, b) => {
                                                                const aName = displayI18n('name', a.ressource.i18n, a.label, getGenericLocale());
                                                                const bName = displayI18n('name', b.ressource.i18n, b.label, getGenericLocale());
                                                                return aName.localeCompare(bName);
                                                            })
                                                    ))
                                            default:
                                                break;
                                        }
                                    }}
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
            </Row>
            <div className="h5 mt-2 font-bold">
                <Translate id='discrimination.label.incident' />
            </div>

            <Row form>
                <Col sm="6" className="form-group">
                    <FormGroup>
                        <div className="text-muted"><Translate id='discrimination.global.incidentDate' /></div>
                        <FormikEditable
                            id='incident_date'
                            readOnly={readOnly}
                            initialValues={{
                                incident_date: moment(selectedRows[0].incident_date),
                            }}
                            disabled={!canEdit}
                            validationSchema={object().shape({
                                incident_date: mixed().required(<Translate id='form.validation.date.required' />)
                                    .test({
                                        name: 'validDate',
                                        message: <Translate id='form.validation.date.format' />,
                                        test: moment.isMoment,
                                    })
                                    .test({
                                        name: 'isBeforeToday',
                                        message: <Translate id='discrimination.form.incident.afterCreation' />,
                                        test: (date) => moment(date).isSameOrBefore(moment(selectedRows[0].created_at), 'days'),
                                    }),
                            })}
                            onSubmit={(values) => {
                                const date = moment(values.incident_date).set({ 'hour': values.time.hour(), 'minute': values.time.minute() }).toISOString();

                                if(date !== selectedRows[0].incident_date){
                                    updateValue('incident_date', date);
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (<DateFormat value={selectedRows[0].incident_date} />);
                                }

                                return (
                                    <FormikDateTime
                                        name="incident_date"
                                        id="incident_date"
                                        isValidDate={(current) => current.isSameOrBefore(selectedRows[0].created_at, 'days')}
                                        timeFormat={false}
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <div className="text-muted">
                    <Translate id="form.fields.documents" />
                </div>
                <FormikEditableFile
                    disabled={!canEdit || readOnly}
                    dropzoneProps={{
                        multiple: true,
                        accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx",
                    }}
                    name="files"
                    onFileClick={handleOnFileClick}
                    initialValues={{
                        files: selectedRows[0].discrimination_attachments || [],
                    }}
                    onSubmit={(values) => {
                        setIsLoading(true);
                        updateDiscriminationAttachments(values.files || [])
                            .then(() => {
                                success({ info: 'components.uploader.fileUpload.toast.success.info' });
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message);
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    });
                                    setIsLoading(false);
                                }
                            })
                    }}
                />
            </FormGroup>
            <FormGroup>
                <div className="text-muted">
                    <Translate id='discrimination.global.discriminatoryGrounds' />
                </div>
                <FormikEditable
                    id='discrimination_reason'
                    noConfirmation
                    disabled={!canEdit}
                    readOnly={readOnly}
                    initialValues={{
                        discrimination_reason: selectedRows[0].discrimination_reason.split(','),
                    }}
                    validationSchema={object().shape({
                        discrimination_reason: array().min(1, <Translate id='discrimination.form.discriminatoryGrounds.min' />),
                    })}
                    onSubmit={({ discrimination_reason }) => {
                        const joinedReasons = discrimination_reason.join(',');
                        if(joinedReasons !== selectedRows[0].discrimination_reason){
                            setIsLoading(true);
                            updateValue('discrimination_reason', joinedReasons);
                        }
                    }}
                >
                    {(isEditing, _options) => {
                        if(!isEditing){
                            return <Translate values={{ count: selectedRows[0].discrimination_reason.split(',').length }} id='discrimination.addModal.form.select.groundsSelected' />;
                        }
                        return (
                            <FormikSelect
                                multi
                                autoFocus
                                menuIsDefaultOpen
                                textWhenSetting={{
                                    count: 1,
                                    label: 'discrimination.addModal.form.select.groundsSelected',
                                }}
                                name="discrimination_reason"
                                id="discrimination_reason"
                                defaultData={DISCRIMINATION_REASONS.map((reason) => ({
                                    value: reason,
                                    label: 'discrimination.global.discriminatoryGrounds.' + reason,
                                    translateLabel: true,
                                }))}
                                loadingStatus='success'
                            />
                        )

                    }}
                </FormikEditable>
                <div className="bg-light mt-2 p-2 border rounded">
                    <div className="font-medium mb-1 text-dark">
                        <Translate id='discrimination.sidePanel.list.groundsSelected' />
                    </div>
                    <ul className="mb-0 pl-3">
                        {
                            selectedRows[0].discrimination_reason.split(',').map((reason) => (
                                <li key={'list' + reason}><Translate id={'discrimination.global.discriminatoryGrounds.' + reason} /></li>
                            ))
                        }
                    </ul>
                </div>
            </FormGroup>
        </>
    )
}

export default DiscriminationSidePanelIncident;