import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import TeamGeneralInfoEdit from './TeamGeneralInfoEdit';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';

// contexts
import { TeamsContext } from '../../../../../../contexts/TeamsContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { DisplayCategory } from '../../../../TeamHelpers';
import { DisplayPhoneNumber } from '@spordle/formik-elements';
import { fail } from "@spordle/toasts";
import moment from 'moment';
import InlineCopy from '../../../../../../components/inlineCopy/InlineCopy';

const TeamGeneralInfo = ({ teamId }) => {
    const [ edit, setEdit ] = useState(false)
    const toggleEdit = () => setEdit(!edit)
    const [ teamAddressTypes, setTeamAddressTypes ] = useState(null)

    const teamsContext = useContext(TeamsContext)
    const organizationContext = useContext(OrganizationContext)

    const buildFullAddress = (address) => address.street ? `${address.street_number || ''}, ${address.street || ''}, ${address.city || ''}, ${address.province_code || ''} ${address.zip_code || ''}, ${address.country_code || ''}` : '';

    // do the API call here to prevent making it everytime the user toggles edit mode
    useEffect(() => {
        teamsContext.getAddressTypes(organizationContext.organisation_id)
            .then(setTeamAddressTypes)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setTeamAddressTypes(null);
                }
            })
    }, [])

    return (
        <>
            {!edit ?
                <Card className="card-shadow">
                    <OverlayLoader isLoading={!teamsContext.cachedTeam.team_id || teamsContext.cachedTeam.team_id !== teamId}>
                        <CardBody>
                            <CardSectionTitle title="teams.profile.teamInfos.general.title" className="d-flex flex-wrap">
                                <CanDoAction className='ml-auto' action='EDIT' componentCode='teams' componentPermissionCode='teams_profile'>
                                    <button id='team-edit-generalInfo' onClick={toggleEdit} className="reset-btn text-link" type='button'><Translate id='misc.edit' /></button>
                                </CanDoAction>
                            </CardSectionTitle>
                            <Row form>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='teams.profile.teamInfos.general.teamName' /></div>
                                        <div className="font-medium text-dark" id='teamInfo-name'>{teamsContext.cachedTeam.name || '-'}</div>
                                    </div>
                                </Col>
                                <Col sm="3">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='teams.profile.teamInfos.general.shortName' /></div>
                                        <div className="font-medium text-dark" id='teamInfo-shrotName'>{teamsContext.cachedTeam.short_name || '-'}</div>
                                    </div>
                                </Col>
                                <Col sm="3">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='teams.profile.teamInfos.general.teamFee' /></div>
                                        <div className="font-medium text-dark" id='teamInfo-teamFee'>
                                            {teamsContext.cachedTeam.team_fee ?
                                                <CurrencyFormat value={teamsContext.cachedTeam.team_fee / 100} />
                                                :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='teams.profile.teamInfos.general.category' /></div>
                                        <div className="font-medium text-dark" id='teamInfo-teamCategory'>
                                            <DisplayCategory category={teamsContext.cachedTeam.team_category} />
                                            {teamsContext.cachedTeam.team_category?.gender &&
                                                <> - <Translate id={`form.fields.gender.${teamsContext.cachedTeam.team_category.gender.toLowerCase()}`} /></>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <Row form>
                                        <Col xs="6">
                                            <div className="mb-3">
                                                <div className="text-muted"><Translate id='teams.profile.teamInfos.general.colorHome' /></div>
                                                <div className="font-medium text-dark" id='teamInfo-homeColor'>{teamsContext.cachedTeam.home_color || '-'}</div>
                                            </div>
                                        </Col>
                                        <Col xs="6">
                                            <div className="mb-3">
                                                <div className="text-muted"><Translate id='teams.profile.teamInfos.general.colorAway' /></div>
                                                <div className="font-medium text-dark" id='teamInfo-awayColor'>{teamsContext.cachedTeam.away_color || '-'}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                {/* <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='teams.profile.teamInfos.general.league'/></div>
                                        <div className="font-medium text-dark">{teamsContext.cachedTeam.league || '-'}</div>
                                    </div>
                                </Col> */}
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='teams.profile.teamInfos.general.sponsor' /></div>
                                        <div className="font-medium text-dark" id='teamInfo-sponsorName'>{teamsContext.cachedTeam.sponsor_name || '-'}</div>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='teams.profile.teamInfos.general.tournamentNumber' /></div>
                                        <div className="font-medium text-dark" id='teamInfo-torunamentNumber'>{teamsContext.cachedTeam.tournament_number || '-'}</div>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='form.fields.phone' /></div>
                                        <div className="font-medium text-dark" id='teamInfo-phone'>
                                            <DisplayPhoneNumber phoneString={teamsContext.cachedTeam.phone} format='INTERNATIONAL' emptyValue='-' />
                                        </div>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className='text-muted'><Translate id='form.fields.address' /></div>
                                        <div className='font-medium text-dark' id='teamInfo-address'>
                                            {teamsContext.cachedTeam.addresses && Array.isArray(teamsContext.cachedTeam.addresses) && teamsContext.cachedTeam.addresses[0] && buildFullAddress(teamsContext.cachedTeam.addresses[0]) ?
                                                <InlineCopy tag='div' toCopy={buildFullAddress(teamsContext.cachedTeam.addresses[0])}>
                                                    <a href={teamsContext.cachedTeam.addresses[0].map_url || '/'} target='_blank' rel='noopener noreferrer'>
                                                        {buildFullAddress(teamsContext.cachedTeam.addresses[0])}
                                                    </a>

                                                </InlineCopy>
                                                : '-'}
                                        </div>
                                    </div>
                                </Col>
                                <Col sm='6'>
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='teams.profile.teamInfos.general.addressType' /></div>
                                        <div className="font-medium text-dark" id='teamInfo-addressType'>
                                            {teamsContext.cachedTeam.addresses && Array.isArray(teamsContext.cachedTeam.addresses) && teamsContext.cachedTeam.addresses[0] ?
                                                <DisplayI18n field='name' defaultValue={teamsContext.cachedTeam.addresses[0].address_type?.name || '-'} i18n={teamsContext.cachedTeam.addresses[0].address_type?.i18n} />
                                                : '-'}
                                        </div>
                                    </div>
                                </Col>
                                <Col md='6'>
                                    <div className="mb-3">
                                        <div className='text-muted'><Translate id='form.fields.address' /> 2 (<Translate id='form.fields.address2.placeholder' />)</div>
                                        <div className='font-medium text-dark' id='teamInfo-address2'>{teamsContext.cachedTeam.addresses?.[0]?.unit_number || '-'}</div>
                                    </div>
                                </Col>

                                <Col md='6'>
                                    <div className="mb-3">
                                        <div className='text-muted'><Translate id='teams.profile.teamInfos.general.lock_roster_date' /></div>
                                        <div className='font-medium text-dark' id='teamInfo-lockRosterDate'>{teamsContext.cachedTeam.lock_roster_date ? moment(teamsContext.cachedTeam.lock_roster_date).format('lll') : '-'}</div>
                                    </div>
                                </Col>
                                <Col md='6'>
                                    <div className="mb-3">
                                        <div className='text-muted'><Translate id='teams.profile.teamInfos.general.lock_affiliate_roster_date' /></div>
                                        <div className='font-medium text-dark' id='teamInfo-lockAffiliateRosterDate'>{teamsContext.cachedTeam.lock_affiliate_roster_date ? moment(teamsContext.cachedTeam.lock_affiliate_roster_date).format('lll') : '-'}</div>
                                    </div>
                                </Col>
                                {organizationContext.settings.enable_organization_activity_periods?.value == '1' &&
                                    <Col md='6'>
                                        <div className="mb-3">
                                            <div className='text-muted'><Translate id='form.fields.activityPeriod' /></div>
                                            <div className='font-medium text-dark' id='teamInfo-activityPeriod'>{teamsContext.cachedTeam.activity_period ? <DisplayI18n field='name' defaultValue={teamsContext.cachedTeam.activity_period?.name} i18n={teamsContext.cachedTeam.activity_period?.i18n} /> : '-'}</div>
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </CardBody>
                    </OverlayLoader>
                </Card>
                :
                <TeamGeneralInfoEdit onCancel={toggleEdit} onSubmit={toggleEdit} teamAddressTypes={teamAddressTypes} />
            }
        </>
    );
}

export default TeamGeneralInfo;