import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    Card,
    CardBody,
    Button,
    Spinner
} from "reactstrap";
import SpordlePanelTable from '../../../../components/sidePanel/SpordlePanel';
import SpordleTableProvider, { SpordleTableContext, SpordleTableView } from '@spordle/datatables';
import MemberQualificationsAdd from './component/MemberQualificationsAdd';
import MemberQualificationSidepanel from './component/MemberQualificationSidepanel';
import CanDoAction from '../../../../components/permissions/CanDoAction';
import CardSectionTitle from '../../../../components/CardSectionTitle';
import { displayI18n, DisplayI18n } from '../../../../helpers/i18nHelper';
import ClinicCategory from '../../../clinics/ClinicCategory';
import EmptyLayout from '../../../../components/table/EmptyLayout';
import StatusBadge from '../../../../components/badges/StatusBadge';
import { RolesContext } from '../../../../contexts/RolesContext';
import { I18nContext } from '../../../../contexts/I18nContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { useIntl } from 'react-intl';
import queryString from 'query-string';

const MemberQualification = () => {
    const { formatMessage } = useIntl();
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);
    const { canDoAction } = useContext(RolesContext);
    const [ add, setAdd ] = useState(false);
    const toggleAdd = () => setAdd(!add);
    const canEdit = canDoAction('EDIT', 'members', 'members_qualifications');
    const [ search, setSearch ] = useState(queryString.parse(location.search).search);


    const formatList = ([ qualifications, attendees ]) => {
        const merged = qualifications || [];

        if(attendees && Array.isArray(attendees)){
            for(let a = 0; a < attendees.length; a++){
                const attendee = attendees[a];

                // Must only show attendees that haven't passed nor have transferred_qualification
                // Passed attendees usually have a qualification that will be shown with the qualifications get
                if(attendee.passed != "1" && attendee.transferred_qualification != '1'){
                    merged.push({
                        ...attendee,
                        completed: "0",
                        completed_date: null,
                        qualification_status: attendee.qualification_status ? attendee.qualification_status : {
                            color_code: 3,
                            i18n: {
                                fr: {
                                    name: formatMessage({ id: `members.profile.qualifications.status.attendee` }),
                                },
                                en: {
                                    name: formatMessage({ id: `members.profile.qualifications.status.attendee` }),
                                },
                            },
                        },
                        member_qualification_id: attendee.clinic_attendee_id,
                        qualification: attendee.clinic.qualification,
                        qualification_level: attendee.clinic.qualification?.qualification_level,
                        period: attendee.clinic.period,
                        isAttendee: true,
                    });
                }
            }
        }

        return merged;
    }


    return (
        <Card className="card-shadow">
            <CardBody>
                <CardSectionTitle title='members.profile.qualifications.title' />
                {!membersContext.currentMember.member_id ?
                    <div className='text-center'>
                        <Spinner color='primary' type='grow' />
                        <div className='h4'><Translate id='misc.loading' /></div>
                    </div>
                    :
                    <SpordlePanelTable
                        allowOutsideClick
                        dataIndex='member_qualification_id'
                        sidePanel={(props) => <MemberQualificationSidepanel canEdit={canEdit} {...props} />}
                        table={(panelProps) => {
                            return (
                                <SpordleTableProvider
                                    id='qualification'
                                    tableHover bordered striped
                                    clickable
                                    ref={panelProps.spordleTableRef}
                                    dataIndex='member_qualification_id'
                                    defaultSearchValue={search || null} // needed for notification redirect
                                    desktopWhen
                                    pagination={10}
                                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                    rowIsHighlighted={(data) => data.checked}
                                    emptyLayout={<EmptyLayout />}
                                    searchKeys={[
                                        `qualification.i18n.${i18nContext.getGenericLocale()}.name`,
                                        'qualification.name',
                                        'qualification.qualification_id',
                                        `qualification.qualification_level.i18n.${i18nContext.getGenericLocale()}.name`,
                                        'qualification.qualification_level.name',
                                        `qualification.qualification_category.i18n.${i18nContext.getGenericLocale()}.name`,
                                        'qualification.qualification_category.name',
                                        `qualification_status.i18n.${i18nContext.getGenericLocale()}.name`,
                                        `period.i18n.${i18nContext.getGenericLocale()}.name`,
                                        'period.name',
                                    ]}
                                    columnSorting={(qualA, qualB, _spordleTable, order) => {
                                        switch (order.column){
                                            case 'season':
                                                // this is amazing
                                                const valueASeason = displayI18n('name', qualA.period.i18n, qualA.period.name, i18nContext.getGenericLocale())
                                                const valueBSeason = displayI18n('name', qualB.period.i18n, qualB.period.name, i18nContext.getGenericLocale())
                                                return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueASeason, valueBSeason) * (order.order === 'ASC' ? 1 : -1);
                                            case 'category':
                                                const valueACategory = displayI18n('name', qualA.qualification.qualification_category.i18n, qualA.qualification.qualification_category.name, i18nContext.getGenericLocale())
                                                const valueBCategory = displayI18n('name', qualB.qualification.qualification_category.i18n, qualB.qualification.qualification_category.name, i18nContext.getGenericLocale())
                                                return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueACategory, valueBCategory) * (order.order === 'ASC' ? 1 : -1);
                                            case 'qualification':
                                                const valueAQualification = displayI18n('name', qualA.qualification.i18n, qualA.qualification.name, i18nContext.getGenericLocale())
                                                const valueBQualification = displayI18n('name', qualB.qualification.i18n, qualB.qualification.name, i18nContext.getGenericLocale())
                                                return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueAQualification, valueBQualification) * (order.order === 'ASC' ? 1 : -1);
                                            case 'level':
                                                const valueALevel = displayI18n('name', qualA.qualification.qualification_level?.i18n, qualA.qualification.qualification_level?.name, i18nContext.getGenericLocale())
                                                const valueBLevel = displayI18n('name', qualB.qualification.qualification_level?.i18n, qualB.qualification.qualification_level?.name, i18nContext.getGenericLocale())
                                                return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueALevel, valueBLevel) * (order.order === 'ASC' ? 1 : -1);
                                            case 'qualification_status':
                                                return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(qualA.qualification_status, qualB.qualification_status) * (order.order === 'ASC' ? 1 : -1);
                                        }
                                    }}
                                    defaultSorting='-season'
                                    loadData={(from, data, spordleTable) => {
                                        switch (from){
                                            case 'REFRESH':
                                                spordleTable.setLoading()
                                            case 'CDM':
                                                setSearch(false);
                                                return membersContext.getQualificationsAndAttendees(membersContext.currentMember.member_id).then(formatList)
                                            default:
                                                break;
                                        }
                                    }}
                                    columns={[
                                        {
                                            label: <Translate id='members.profile.qualifications.season' />,
                                            key: 'season',
                                            className: 'th-shrink',
                                            dataClassName: 'td-shrink',
                                            sortable: true,
                                            mobile: true,
                                        },
                                        {
                                            label: <Translate id='members.profile.qualifications.category' />,
                                            key: 'category',
                                            sortable: true,
                                            mobile: true,
                                        },
                                        {
                                            label: <Translate id='members.profile.qualifications.qualification' />,
                                            key: 'qualification',
                                            sortable: true,
                                            mobile: true,
                                        },
                                        {
                                            label: <Translate id='members.profile.qualifications.level' />,
                                            key: 'level',
                                            sortable: true,
                                            mobile: true,
                                        },
                                        {
                                            label: <Translate id='members.profile.qualifications.table.status' />,
                                            key: 'qualification_status',
                                            sortable: true,
                                            mobile: true,
                                        },
                                    ]}
                                    renderRow={(columnKey, memberQual) => {
                                        switch (columnKey){
                                            case 'season':
                                                return (
                                                    memberQual.period?.period_id ?
                                                        <DisplayI18n field='name' defaultValue={memberQual.period?.name} i18n={memberQual.period?.i18n} />
                                                        :
                                                        '-'
                                                )
                                            case 'category':
                                                return (
                                                    <ClinicCategory color={isNaN(parseInt(memberQual.qualification.qualification_category.color)) ? 0 : memberQual.qualification.qualification_category.color}>
                                                        <DisplayI18n field='name' defaultValue={memberQual.qualification.qualification_category.name} i18n={memberQual.qualification.qualification_category.i18n} />
                                                    </ClinicCategory>
                                                )
                                            case 'qualification':
                                                return (
                                                    <DisplayI18n field='name' defaultValue={memberQual.qualification.name} i18n={memberQual.qualification.i18n} />
                                                )
                                            case 'level':
                                                if(memberQual.qualification.qualification_level){
                                                    return <DisplayI18n field='name' defaultValue={memberQual.qualification.qualification_level.name} i18n={memberQual.qualification.qualification_level.i18n} />
                                                }
                                                if(memberQual.qualification.description){
                                                    return <DisplayI18n field='description' defaultValue={memberQual.qualification.name} i18n={memberQual.qualification.i18n} />
                                                }
                                                return '-';

                                            case 'qualification_status':
                                                return <StatusBadge icon={null} status={memberQual.qualification_status ? memberQual.qualification_status : memberQual.member_qualification_status} color={memberQual.qualification_status ? memberQual.qualification_status.color_code : memberQual.member_qualification_status?.color} />
                                            default:
                                                break;
                                        }
                                    }}
                                    onColumnClick={(e, data) => {
                                        switch (e.button){
                                            case 0: // Left mouse button
                                                panelProps.onSingleSelectChange(data);
                                                break;
                                        }
                                    }}
                                >
                                    <div className='mb-2'>
                                        <div className='d-flex flex-wrap justify-content-between'>
                                            <SpordleTableProvider.SearchInput />

                                            <div className='d-flex ml-auto text-right'>
                                                <SpordleTableProvider.Refresh />
                                                <CanDoAction action='ADD' componentCode='members' componentPermissionCode='members_qualifications'>
                                                    <Button className='ml-2' color='primary' onClick={toggleAdd}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                    <SpordleTableContext.Consumer>
                                                        {(spordleTable) => (
                                                            <MemberQualificationsAdd toggle={toggleAdd} isOpen={add} refreshTable={spordleTable.refreshTable} />
                                                        )}
                                                    </SpordleTableContext.Consumer>
                                                </CanDoAction>
                                            </div>
                                        </div>
                                    </div>
                                    <SpordleTableView />
                                </SpordleTableProvider>
                            )
                        }}
                    />
                }
            </CardBody>
        </Card>
    );
}

export default MemberQualification;