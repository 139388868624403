export default{
    "members.search.addCompetitionTeam.modal"                : "Ajouter une ou des équipe(s)",
    "members.search.addCompetitionTeam.team.required"        : "Veuillez ajouter au moins une équipe",
    "members.search.addCompetitionTeam.competition.required" : "Please select the competition.",
    "members.search.addCompetitionTeam.availableTeams"       : "Équipes disponibles",
    "members.search.addCompetitionTeam.filterTeams"          : "Filtrer les équipes avec la division de la compétition",
    "members.search.addCompetitionTeam.filterDiv"            : "Seulement les équipes <b>{division}</b>",
    "members.search.addCompetitionTeam.add.success"          : "Équipe(s) ajoutée(s) avec succès!",

    "members.search.memberAdvanceSearch.filters.organization"            : "Organisation",
    "members.search.memberAdvanceSearch.filters.type"                    : "Type",
    "members.search.memberAdvanceSearch.filters.type.placeholder"        : "Choisir un type",
    "members.search.memberAdvanceSearch.filters.status"                  : "Statut",
    "members.search.memberAdvanceSearch.filters.status.placeholder"      : "Choisir le statut",
    "members.search.memberAdvanceSearch.filters.gender"                  : "Identité de genre",
    "members.search.memberAdvanceSearch.filters.gender.placeholder"      : "Choisir l'identité de genre",
    "members.search.memberAdvanceSearch.filters.from"                    : "Du",
    "members.search.memberAdvanceSearch.filters.to"                      : "Jusqu'au",
    "members.search.memberAdvanceSearch.filters.minAge"                  : "Âge minimum",
    "members.search.memberAdvanceSearch.filters.maxAge"                  : "Âge maximum",
    "members.search.memberAdvanceSearch.filters.or"                      : "Ou",
    "members.search.memberAdvanceSearch.filters.currentOrg"              : "Inclure les membres de l'organisation sélectionnée",
    "members.search.memberAdvanceSearch.filters.all"                     : "Inclure les membres de toutes les organisations",
    "members.search.memberAdvanceSearch.filters.allSubOrgs"              : "Inclure les membres de toutes les sous-organisations",
    "members.search.memberAdvanceSearch.filters.onlyCrc"                 : "Vérification d'antécédents invalides",
    "members.search.memberAdvanceSearch.filters.onlyUnconfirmedAddress"  : "Adresses non-confirmées",
    "members.search.memberAdvanceSearch.filters.onlyNeedAddressDocument" : "Membres avec documents manquants",
    "members.search.memberAdvanceSearch.filters.withoutProfilePicture"   : "Membres sans photo de profil",
    "members.search.memberAdvanceSearch.filters.hasOutstandingBalance"   : "Membres avec solde impayé",
    "members.search.memberAdvanceSearch.filters.onlyUnconfirmed"         : "Seulement les membres non confirmé(e)",
    "members.search.memberAdvanceSearch.filters.memberNumber"            : "{unique_identifier}",
    "members.search.memberAdvanceSearch.filters.memberStatus"            : "Statut",
    "members.search.memberAdvanceSearch.filters.missingRequiredFields"   : "Champs obligatoires manquants",
    "members.search.memberAdvanceSearch.filters.noMemberType"            : "Aucun type de membre / Membres non inscrits",
    "members.search.memberAdvanceSearch.filters.noMemberType.tip"        : "Utilisez cette option pour rechercher les membres non inscrits. Un membre qui n’a pas de type de membre n’a aucune activité dans aucune organisation pour la période courante.",

    "members.search.memberAdvanceSearch.memberNumber.ignoredFilters" : "Les autres filtres seront ignorés",

    "members.search.memberAdvanceSearch.moreFilter"   : "Plus de filtres",
    "members.search.memberAdvanceSearch.lessFilter"   : "Moins de filtres",
    "members.search.memberAdvanceSearch.resetFilters" : "Réinitialiser les filtres",

    "members.search.memberAdvanceSearch.csv.confirmed"      : "Confirmé",
    "members.search.memberAdvanceSearch.csv.confirmed_date" : "Date Confirmé",
    "members.search.memberAdvanceSearch.csv.member_status"  : "Statut",

    "members.search.memberAdvanceSearch.validation.startDate.required"        : "La date est requise",
    "members.search.memberAdvanceSearch.validation.startDate.invalidFormat"   : "Format de date non valide",
    "members.search.memberAdvanceSearch.validation.startDate.beforeToday"     : "La date doit être antérieure à aujourd'hui",
    "members.search.memberAdvanceSearch.validation.endDate.required"          : "La date est requise",
    "members.search.memberAdvanceSearch.validation.endDate.invalidFormat"     : "Format de date non valide",
    "members.search.memberAdvanceSearch.validation.endDate.beforeStartDate"   : "La date ne peut pas être antérieure à la date de début",
    "members.search.memberAdvanceSearch.validation.firstName.requiredFilters" : "Un prénom est requis",
    "members.search.memberAdvanceSearch.validation.lastName.requiredFilters"  : "Un nom de famille est requis",
    "members.search.memberAdvanceSearch.validation.email.valid"               : "Courriel doit être valide",
    "members.search.memberAdvanceSearch.validation.minAge.required"           : "Veuillez saisir un âge minimum",
    "members.search.memberAdvanceSearch.validation.minAge.1.required"         : "Veuillez entrer 1 ou plus comme âge minimum",
    "members.search.memberAdvanceSearch.validation.minAge.max"                : "Ne peut pas être supérieur à l'âge maximum",
    "members.search.memberAdvanceSearch.validation.maxAge.required"           : "Veuillez saisir un âge maximum",
    "members.search.memberAdvanceSearch.validation.maxAge.min"                : "Ne peut pas être inférieur à l'âge minimum",
}