import { jsObjectToApi } from "@spordle/helpers";
import API_SPORDLE from "../API-Spordle";
import queryString from "query-string";
import { serverError } from "../CancellableAPI";

    /**
     * Update partially a member suspension
     * @param {string} member_status_log_id
     * @param {object} values The values for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Suspensions/Apicontroller%5CMembers%5CMembersuspensions%3A%3AupdatePatchMemberSuspension|documentation}
     * @returns {Promise.<Array>}
     */
    export function updateMemberStatusLogNote (member_status_log_id, values = {}) {
        const params = new URLSearchParams();

        jsObjectToApi(values, params)

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/member-status-log/${member_status_log_id}/note`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }
