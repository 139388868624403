/**
 * @typedef {object} SubRoutes
 * @property {React.ReactNode} [name]
 * @property {boolean} [redirect]
 */

import Discrimination from '../../views/tasks/discrimination/Discrimination';
import GameIncident from '../../views/tasks/gameIncident/GameIncident';
import Insurance from '../../views/tasks/insurance/Insurance';
import OfficialSupervision from '../../views/tasks/officialSupervision/OfficialSupervision';
import Transfers from '../../views/tasks/transfers/Transfers';
import TravelPermit from '../../views/tasks/travelPermit/TravelPermit';
import WaitingList from '../../views/tasks/waitingList/WaitingList';
import ManageRequests from '../../views/tasks/manageRequests/ManageRequests';
import tasksManageRequestsTabsRoutes from '../tabRoutes/tasksManageRequestsTabsRoutes';
import PotentialIneligibleMembers from '../../views/tasks/potentialIneligibleMembers/PotentialIneligibleMembers';
import PotentialMemberDuplicates from '../../views/tasks/duplicates/PotentialMemberDuplicates';
import Checklists from '../../views/tasks/checklists/Checklists';
import ChecklistNotFound from '../../views/tasks/checklists/ChecklistNotFound';
import RenewMembers from '../../views/tasks/renewMembers/RenewMembers';

/** @type {Array.<import('react-router-dom').RouteProps & SubRoutes>} */
const tasksRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        title: 'sidebar.settings.menu',
    },
    {
        path: '/transfer',
        name: 'transfer.title',
        permissions: { code: 'members', permissionCode: 'members_request_transfer' },
        component: Transfers,
    },
    {
        path: '/travelpermit',
        name: 'travelPermit.title',
        component: TravelPermit,
        permissions: { code: 'teams', permissionCode: 'team_travel_permits' },
        showWhenSetting: 'enable_travel_permit',
        // showWhenSetting: ['enable_travel_permit', 'patate_setting']
        // showWhenSettingLogic: 'AND' or 'OR'
    },
    {
        path: '/discrimination',
        name: 'discrimination.title',
        component: Discrimination,
        permissions: { code: 'organization', permissionCode: 'discrimination_claims' },
    },
    {
        path: '/insurance',
        name: 'insurance.title',
        component: Insurance,
        permissions: [ { code: 'members', permissionCode: 'members_injuries' }, { code: 'settings', permissionCode: 'injury_configurations' } ],
    },
    {
        path: '/gameincident',
        name: 'gameIncident.title',
        component: GameIncident,
        permissions: [ { code: 'organization', permissionCode: 'game_incidents' } ],
    },
    {
        path: '/officialsupervision',
        name: 'officialSupervision.title',
        component: OfficialSupervision,
        permissions: [ { code: 'members', permissionCode: 'manage_official_supervisions' } ],
    },
    {
        path: '/waiting-list',
        name: 'task.waitingList.title',
        component: WaitingList,
        permissions: [ { code: 'members', permissionCode: 'manage_registration_waiting_list' } ],
    },
    {
        path: '/potential-ineligible-members',
        name: 'task.ineligible.title',
        component: PotentialIneligibleMembers,
        permissions: [ { code: 'members', permissionCode: 'manage_potential_ineligible_members' } ],
    },
    {
        path: '/potential-member-duplicates',
        component: PotentialMemberDuplicates,
        name: 'tasks.memberDuplicates.title',
        permissions: [ { code: 'members', permissionCode: 'manage_potential_duplicate_members' } ],
    },
    {
        path: '/renew-members',
        component: RenewMembers,
        name: 'task.renewMembers.title',
        permissions: [ { code: 'members', permissionCode: 'members_profile' } ],
        showWhenSetting: 'manage_affiliation_invoice',
    },
    // Requests
    {
        path: '/manage-requests',
        component: ManageRequests,
        name: 'task.manageRequests.title',
        permissions: { code: 'organization' },
        subPermissionsRoutes: tasksManageRequestsTabsRoutes,
    },

    // Checklists / Lifecycles
    {
        path: '/checklists/404',
        component: ChecklistNotFound,
        hidden: true,
        skipRecents: true,
        exact: true,
    },
    {
        path: '/checklists/:checklist',
        component: Checklists,
        hidden: true,
        exact: true,
        permissions: { code: 'organization', permissionCode: 'organization_profile' },
    },
    // {
    //     path: '/checklist-season-rollover',
    //     component: ChecklistSeasonRollover,
    //     name: 'sidebar.checklists.seasonRollover',
    //     permissions: [ { code: 'organization', permissionCode: "season_rollover" } ],
    // },
    // {
    //     path: '/checklist-onlineregistration',
    //     component: ChecklistOnlineRegistration,
    //     name: 'sidebar.checklists.onlineRegistration',
    //     permissions: [ { code: 'organization', permissionCode: "season_rollover" } ],
    //     envFeatureFlag: [ "int" ],
    // },
    // {
    //     path: '/checklist-clinics',
    //     component: ChecklistClinics,
    //     name: 'sidebar.checklists.clinic',
    //     permissions: [ { code: 'organization', permissionCode: "season_rollover" } ],
    //     envFeatureFlag: [ "int" ],
    // },

    {
        redirect: true,
        from: '/tasks',
        to: '/transfer',
    },
];
export default tasksRoutes;
