import useSWR from "swr";
import { getMemberFields } from "../../api/client/memberFields";

export function useMemberFieldsSWR(){
    return useSWR(
        [ 'getMemberFields' ],
        () => getMemberFields(),
        {
            revalidateOnFocus: false,
            dedupingInterval: 60000,
        },
    )
}