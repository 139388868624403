import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Button, ModalBody } from "reactstrap";
import { AddSupervisionFormContext } from "../components/AddSupervisionFormContext";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import SearchMembers from "../components/SearchMembers";

const StepFindOfficial = () => {
    const { next, previous, updateFormData, formData } = useContext(AddSupervisionFormContext);

    const handleOnSelectMember = (member) => {
        updateFormData('official', member);
        next();
    }

    return (
        <>
            <ModalBody className="pb-0">
                <div className="h5 font-bold"><Translate id='officialSupervision.addSupervision.identityOfficial' /></div>
            </ModalBody>
            <SearchMembers
                previous={previous}
                withOrg
                renderMember={(member) => (
                    <UserDisplay className="mb-2 w-100" card>
                        <UserDisplay.Container>
                            <UserImg
                                width={45}
                                abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                alt={member.first_name + ' ' + member.last_name}
                                src={member.picture?.full_path}
                                filePos={member.picture?.file_position}
                            />
                        </UserDisplay.Container>
                        <UserDisplay.Container>
                            <UserDisplay.Title>{member.first_name + ' ' + member.last_name}</UserDisplay.Title>
                            <UserDisplay.Subtitle>#{member.unique_identifier}</UserDisplay.Subtitle>
                            <UserDisplay.Subtitle>{member.birthdate} (<Translate id='misc.yearsOld' values={{ age: member.age }} />)</UserDisplay.Subtitle>
                            <UserDisplay.Subtitle>
                                {member.member_id === formData.officiatingCoach?.member_id ?
                                    <span className="px-1 bg-light-warning text-body"><i className="mdi mdi-alert-outline text-warning" /> <Translate id='officialSupervision.addSupervision.officialCannotSuperviseThemselves' /></span>
                                    :
                                    !member.member_qualification ?
                                        <span className="px-1 bg-light-warning text-body"><i className="mdi mdi-alert-outline text-warning" /> <Translate id='officialSupervision.addSupervision.notOfficial.label' /></span>
                                        :
                                        <DisplayI18n
                                            field="name"
                                            defaultValue={member.organisation?.organisation_name}
                                            i18n={member.organisation?.i18n}
                                        />
                                }
                            </UserDisplay.Subtitle>
                            {member.member_qualification && member.member_id !== formData.officiatingCoach?.member_id &&
                                <UserDisplay.Subtitle>
                                    <DisplayI18n field='name' defaultValue={member.member_qualification.qualification.name} i18n={member.member_qualification.qualification.i18n} />{(member.member_qualification.qualification_level) && <> - <DisplayI18n field='name' defaultValue={member.member_qualification.qualification_level.name} i18n={member.member_qualification.qualification_level.i18n} /></>}
                                </UserDisplay.Subtitle>
                            }
                        </UserDisplay.Container>
                        <UserDisplay.Container className="ml-auto text-right">
                            <Button
                                disabled={!member.member_qualification || member.member_id === formData.officiatingCoach?.member_id}
                                size="sm"
                                onClick={() => {
                                    handleOnSelectMember(member);
                                }}
                                color="primary"
                                type="button"
                            >
                                <Translate id='misc.select' />
                            </Button>
                        </UserDisplay.Container>
                    </UserDisplay>
                )}
            />
        </>
    );
}

export default StepFindOfficial;