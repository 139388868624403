export default{
    "officialSupervision.title" : "Official Supervision",

    "officialSupervision.tab.reports" : "Reports",
    "officialSupervision.tab.coaches" : "Officiating Coaches",

    "officialSupervision.homeTeam.label"                            : "Home Team",
    "officialSupervision.member.label"                              : "Member",
    "officialSupervision.nationalCoach.label"                       : "National Officiating Coach",
    "officialSupervision.branch.label"                              : "Branch",
    "officialSupervision.supervisionType.label"                     : "Supervision Type",
    "officialSupervision.awayTeam.label"                            : "Away Team",
    "officialSupervision.team.label"                                : "Team",
    "officialSupervision.supervisionDate.label"                     : "Supervision Date",
    "officialSupervision.overallScore.label"                        : "Overall ratio",
    "officialSupervision.official.label"                            : "Official",
    "officialSupervision.officiatingCoach.label"                    : "Officiating Coach",
    "officialSupervision.official.uniqueIdentifier.label"           : "Official {unique_identifier}",
    "officialSupervision.officiatingCoach.uniqueIdentifier.label"   : "Officialing Coach {unique_identifier}",
    "officialSupervision.supervisionReport.label"                   : "Supervision Report",
    "officialSupervision.season.label"                              : "Season",
    "officialSupervision.allReportsBtn.short"                       : "All reports",
    "officialSupervision.sendEmail"                                 : "Send by email",
    "officialSupervision.sendEmail.help"                            : "Send report now by email",
    "officialSupervision.allReportsBtn.long"                        : "See all supervisions on this official",
    "officialSupervision.privateComment.label"                      : "Private Comment",
    "officialSupervision.areaOfImprovement.label"                   : "Area of improvements",
    "officialSupervision.comments.label"                            : "Comments",
    "officialSupervision.strengths.label"                           : "Strengths",
    "officialSupervision.teamCategory.label"                        : "Team Category",
    "officialSupervision.teamName.label"                            : "Team Name",
    "officialSupervision.teamName.home.label"                       : "Home Team Name",
    "officialSupervision.teamName.away.label"                       : "Away Team Name",
    "officialSupervision.supervisionType.categories.label"          : "Categories",
    "officialSupervision.supervisedBy.label"                        : "Supervised by",
    "officialSupervision.date.label"                                : "Date",
    "officialSupervision.supervisor.label"                          : "Officiating Coach",
    "officialSupervision.assignPermissions.search"                  : "Search an official",
    "officialSupervision.officialType.label"                        : "Official Type",
    "officialSupervision.officialType.REFEREE"                      : "Referee",
    "officialSupervision.officialType.LINESPERSON"                  : "Linesperson",
    "officialSupervision.officialType.OFFICIAL_SYSTEM"              : "Official System",
    "officialSupervision.assignPermissions.alreadyOfficiatingCoach" : "{name} is already an officiating coach. You are currently editing their permissions.",

    "officialSupervision.mySupervisions"                                         : "My supervision forms",
    "officialSupervision.myReports"                                              : "My reports",
    "officialSupervision.allMemberReports"                                       : "All {name} supervisions",
    "officialSupervision.allMemberReports.backToList"                            : "Back to list",
    "officialSupervision.allMemberReports.noReports.title"                       : "No supervision reports",
    "officialSupervision.allMemberReports.noReports.subtitle"                    : "There is no supervision report on this official",
    "officialSupervision.allMemberReports.noReports.officiating.subtitle"        : "You currently have no supervision form filled",
    "officialSupervision.allMemberReports.noReports.search.subtitle"             : "No supervision report was found with this search",
    "officialSupervision.allMemberReports.noReports.officiating.search.subtitle" : "No supervision form was found with this search",

    "officialSupervision.sidePanel.delete.msg" : "You are about to delete this supervision report",
    "officialSupervision.sidePanel.tab.info"   : "Info",
    "officialSupervision.sidePanel.tab.type"   : "Type",
    "officialSupervision.sidePanel.tab.notes"  : "Notes",

    "officialSupervision.addSupervision.existingTeam"           : "Existing team",
    "officialSupervision.addSupervision.searchMember.noResults" : "No member was found with these parameters",
    "officialSupervision.addSupervision.existingTeam.required"  : "Please select if the team is existing or not",
    "officialSupervision.addSupervision.otherTeam"              : "Other",
    "officialSupervision.addSupervision.branch.label"           : "Branch",
    "officialSupervision.addSupervision.org.label"              : "Organisation",

    "officialSupervision.addSupervision.teamId.required"       : "Please select the team",
    "officialSupervision.addSupervision.branch.required"       : "Please select the branch",
    "officialSupervision.addSupervision.org.required"          : "Please select the organization",
    "officialSupervision.addSupervision.teamName.required"     : "Please specify the team name",
    "officialSupervision.addSupervision.teamCategory.required" : "Please specify the team category",
    "officialSupervision.addSupervision.period.required"       : "Please specify the season",
    "officialSupervision.addSupervision.date.required"         : "Please specify the date",
    "officialSupervision.addSupervision.officialType.required" : "Please specify the official type",

    "officialSupervision.addSupervision.step.findOfficial"          : "Find an official to supervise",
    "officialSupervision.addSupervision.step.findOfficiating"       : "Find the officiating coach",
    "officialSupervision.addSupervision.step.global"                : "Fill out the global info",
    "officialSupervision.addSupervision.step.supervisionType"       : "Select the type of supervision",
    "officialSupervision.addSupervision.step.selectSupervisionType" : "Please select the type of supervision",
    "officialSupervision.addSupervision.step.selectLevels"          : "Select the levels",

    "officialSupervision.addSupervision.notOfficial.label"                 : "Not an official",
    "officialSupervision.addSupervision.notOfficiating.label"              : "Does not have officiating access",
    "officialSupervision.addSupervision.officialCannotSuperviseThemselves" : "The officiating coach cannot be his own supervisor",
    "officialSupervision.addSupervision.seeProfile"                        : "See profile",
    "officialSupervision.addSupervision.officiatingCoachSupervises"        : "{name} supervises:",
    "officialSupervision.addSupervision.willSupervise"                     : "{name} will supervise:",
    "officialSupervision.addSupervision.identityOfficiating"               : "Please identify the officiating coach",
    "officialSupervision.addSupervision.identityOfficial"                  : "Please identify the official supervised",
    "officialSupervision.addSupervision.league.label"                      : "League",
    "officialSupervision.addSupervision.privateComment.helper"             : "Will only be shown the the supervisors and administrators",
    "officialSupervision.addSupervision.not_listed"                        : "Not listed",

    "officialSupervision.categories.skating"           : "Skating",
    "officialSupervision.categories.positioning"       : "Positioning",
    "officialSupervision.categories.procedures"        : "Procedures",
    "officialSupervision.categories.mental_skills"     : "Mental Skills",
    "officialSupervision.categories.presence"          : "Teamwork & Coachability",
    "officialSupervision.categories.communication"     : "Communication",
    "officialSupervision.categories.judgement"         : "Judgement",
    "officialSupervision.categories.rule_application"  : "Rule Application",
    "officialSupervision.categories.penalty_standards" : "Standard",
    "officialSupervision.categories.choice.BEGINNING"  : "Beginning",
    "officialSupervision.categories.choice.DEVELOPING" : "Developing",
    "officialSupervision.categories.choice.MEETING"    : "Meeting",
    "officialSupervision.categories.choice.EXCEEDING"  : "Exceeding",

    "officialSupervision.categories.required" : "Please select the level of {category}",
}