import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString, { stringifyUrl } from 'query-string';
import { OrganizationContext } from './OrganizationContext';
import withContexts from '../helpers/withContexts';

/** @type {React.Context<Omit<CrcContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const CrcContext = createContext();
CrcContext.displayName = 'CrcContext';

class CrcContextProvider extends React.PureComponent{
    /**
     * [GET] Gets all criminal record check status
     * @param {string} [orgId] ID of the organisation
     * @param {object} [queryParams] Query params
     * @returns {Promise.<object[]>} Returns an array of all status
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Criminal%20Record%20Check%20Status/Apicontroller%5CCriminals%5CCriminalRecordCheckStatus%3A%3AgetAllCriminalsRecordStatus|documentation}
     */
    getAllCrcStatus = (orgId = this.props.OrganizationContext.organisation_id, queryParams = {}) => (
        API_SPORDLE.get(queryString.stringifyUrl({
            url: `/criminal-record-check-status`,
            query: Object.assign({
                organisation_id: orgId,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.criminal_record_check_status;
                }
                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * [GET] Gets the download link of a crc document
     * @param {string} crcId Crc id
     * @param {object} attId Member crc attachment id
     * @returns {Promise.<string>} Returns a string of the download link
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Attachments/Apicontroller%5CMembers%5CMembercriminalrecordcheckattachments%3A%3AdownloadMemberCrcAttachment|documentation}
     */
    getCrcDownloadLink = (crcId, attId) => (
        API_SPORDLE.get(queryString.stringifyUrl({
            url: `/crc/${crcId}/attachments/${attId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return {
                        full_path: response.data.full_path,
                        preview_full_path: response.data.preview_full_path,
                        original_file_mime_type: response.data.original_file_mime_type,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * [GET] Gets all CRC attachments
     * @param {string} [crcId] ID of the organisation
     * @param {object} [queryParams] Query params
     * @returns {Promise.<object>} Returns an array of all status
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Criminal%20Record%20Check%20Status/Apicontroller%5CCriminals%5CCriminalRecordCheckStatus%3A%3AgetAllCriminalsRecordStatus|documentation}
     */
    getCrcAttachments = (crcId, queryParams = {}) => (
        API_SPORDLE.get(queryString.stringifyUrl({
            url: `/crc/${crcId}/attachments`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.attachments;
                }
                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * [POST] Creates a new member crc attachment
     * @param {string} crcId
     * @param {File} file
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20CRC%20Attachments/Apicontroller%5CMembers%5CMembercriminalrecordcheckattachments%3A%3AcreateMemberCriminalRecordCheckAttachments|documentation}
     * @returns {Promise.<string>} Returns member crc attachment id
     */
    createMemberCrcAttachment = (crcId, files) => {
        const params = new FormData();

        files.forEach((file, i) => {
            params.append(`attachments[${i}][attachment]`, file);
        })

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/crc/${crcId}/attachments` }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((res) => {
                if(res.data.status){
                    return res.data.member_crc_attachment_ids;
                }
                throw res.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes member crc attachments
     * @param {string} crcId Crc id
     * @param {string} attachmentId Attachments id
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20CRC%20Attachments/Apicontroller%5CMembers%5CMembercriminalrecordcheckattachments%3A%3AdeleteSpecificMemberCRCAttachement|documentation}
     * @returns {Promise.<boolean>} Returns true if success
     */
    deleteMemberCrcAttachment = (crcId, attachmentId) => (
        API_SPORDLE.delete(`crc/${crcId}/attachments/${attachmentId}`)
            .then((response) => {
                if(response.data.status)return true

                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * [GET] - Gets the background check types
     * @param {string} orgIg ID of the organization to get the background check types from
     * @returns {Promise.<Array>}
     */
    getBackgroundCheckTypes = (orgId) => {
        return API_SPORDLE.get(stringifyUrl({
            url: '/background-check-types',
            query: {
                organisation_id: orgId,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.background_check_types
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] - Specific Background Check
     * @param {string} background_check_type_id
     * @returns {Promise.<Array>}
     */
    getBackgroundCheckType = (background_check_type_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/background-check-types/${background_check_type_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.background_check_types[0]
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] - Creates a Background Check
     * @param {object} [values] The query params for that call
     * @returns {Promise}
     */
    createBackgroundCheckType = (values = {}) => {
        const params = new URLSearchParams();

        for(const key in values){
            switch (key){
                case 'organisation_id':
                    params.append('organisation_id', values.organisation_id);
                    break;
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'description':
                    params.append('description', values.description);
                    break;
                case 'display_order':
                    params.append('display_order', values.display_order);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/background-check-types` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.background_check_type_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
    * [PUT] - Updates a Background Check
    * @param {string} background_check_type_id
    * @param {object} values
    * @returns {Promise}
    */
    updateBackgroundCheckType = (background_check_type_id, values = {}) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'organisation_id':
                    params.append('organisation_id', values.organisation_id);
                    break;
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'description':
                    params.append('description', values.description);
                    break;
                case 'display_order':
                    params.append('display_order', values.display_order);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/background-check-types/${background_check_type_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * [PATCH] - Partially updates a specified Background Check
    * @param {string} background_check_type_id
    * @param {object} values
    * @returns {Promise}
    */
    partialUpdateBackgroundCheckType = (background_check_type_id, values = {}) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'organisation_id':
                    params.append('organisation_id', values.organisation_id);
                    break;
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'description':
                    params.append('description', values.description);
                    break;
                case 'display_order':
                    params.append('display_order', values.display_order);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/background-check-types/${background_check_type_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * [DELETE] - Deletes a specific Background Check
    * @param {string} background_check_type_id
    * @returns {Promise}
    */
    deleteBackgroundCheckType = (background_check_type_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/background-check-types/${background_check_type_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <CrcContext.Provider value={this}>
                {this.props.children}
            </CrcContext.Provider>
        );
    }
}

export default withContexts(OrganizationContext)(CrcContextProvider);