import React, { useContext } from 'react';
import { Card, CardBody, Row, Col, Spinner } from 'reactstrap';

import Translate, { DateFormat } from '@spordle/intl-elements';

import I18nHelperContextProvider, { I18nHelperContext, DisplayI18n, RenderI18nForm } from '../../../../helpers/i18nHelper';
import { OnlineStoreContext } from '../../../../contexts/OnlineStoreContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';

import FormikEditable from '../../../../components/formik/FormikEditable';
import { FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import { object, array, string, number } from 'yup';
import { fail, success } from '@spordle/toasts';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { Link } from 'react-router-dom';
import CanDoAction from '../../../../components/permissions/CanDoAction';

const SidePanelOnlineStoreGeneralTab = (props) => {
    const onlineStoreContext = useContext(OnlineStoreContext);

    const handleCatch = (e) => {
        if(!AxiosIsCancelled(e.message)){
            console.error(e);
            fail({
                msg: 'misc.error',
                info: <DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />,
                skipInfoTranslate: true,
            })
            props.setIsLoading(false)
        }
    }

    return (
        <>
            <div className="p-3 border-bottom">
                <Row form>
                    <Col xs="6">
                        <Card className="card-shadow card-hover text-center mb-0 clickable">
                            <CardBody>
                                <Link to={{ pathname: `/onlineregistration/profile/${props.onlineStore.online_store_id}/registration` }}>
                                    {/* Value is generated in JS in parent component -> getRegistrationFeesAndOtherFees() */}
                                    <Spinner className={props.feesCountLoading ? 'd-inline-block' : 'd-none'} style={{ height: '26px', width: '26px' }} color='primary' type='grow' />
                                    <div id="registrationFeesCount" className={`h2 mb-0 font-medium ${props.feesCountLoading ? 'd-none' : 'd-inline'}`}>{0}</div>
                                    <div className="small"><Translate id='onlineStore.search.sidepanel.title.registrations.sold' /></div>
                                </Link>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="6">
                        <Card className="card-shadow card-hover text-center mb-0 clickable">
                            <CardBody>
                                <Link to={{ pathname: `/onlineregistration/profile/${props.onlineStore.online_store_id}/transaction` }}>
                                    {/* Value is generated in JS in parent component -> getRegistrationFeesAndOtherFees() */}
                                    <Spinner className={props.feesCountLoading ? 'd-inline-block' : 'd-none'} style={{ height: '26px', width: '26px' }} color='primary' type='grow' />
                                    <div id="otherFeesCount" className={`h2 mb-0 font-medium ${props.feesCountLoading ? 'd-none' : 'd-inline'}`}>{0}</div>
                                    <div className="small"><Translate id='onlineStore.search.sidepanel.title.otherItems.sold' /></div>
                                </Link>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            <div className="p-3 border-bottom">
                {/* LANGUAGES */}
                <div className='mb-3'>
                    <div className="text-muted"><Translate id='onlineStore.search.sidepanel.languages' /></div>
                    <FormikEditable
                        readOnly
                        id='languages'
                        disabled
                        noConfirmation
                        initialValues={{
                            languages: props.onlineStore.languages,
                        }}
                        validationSchema={object().shape({
                            languages: array().of(string()).min(1, <Translate id='onlineStore.search.sidepanel.languages.min' />),
                        })}
                        onSubmit={(values) => {
                            if(values.languages !== props.onlineStore.languages){
                                props.setIsLoading(true);
                                onlineStoreContext.partialUpdateOnlineStore(props.onlineStore.online_store_id, values)
                                    .then(() => {
                                        // Update cached store only if it is the cached one
                                        if(onlineStoreContext.state.cachedStore.online_store_id === props.onlineStore.online_store_id)
                                            onlineStoreContext.updateCachedStore(values);

                                        success();
                                        props.setIsLoading(false);
                                        props.panelProps.syncRows(props.panelProps.createNewValues(values));
                                    })
                                    .catch(handleCatch)
                            }
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return (
                                    <div>
                                        <i className="mdi mdi-check text-primary mr-1" />{props.onlineStore.languages.map((lang, index) => <React.Fragment key={lang}><Translate id={`misc.${lang}.long`} />{index !== props.onlineStore.languages.length - 1 && ', '}</React.Fragment>)}
                                    </div>
                                )
                            }
                            return (
                                <OrganizationContext.Consumer>
                                    {(orgContext) => (
                                        <FormikSelect
                                            multi
                                            search={false}
                                            name='languages'
                                            id='languages_SidepanelOnlineStoreGeneralTab'
                                            options={orgContext.settings.language.value.map((lang) => ({
                                                key: lang,
                                                value: lang,
                                                label: `misc.${lang}.text`,
                                                selected: formik.initialValues.languages.includes(lang),
                                                translateLabel: true,
                                            }))}
                                            autoFocus menuIsDefaultOpen
                                            onOptionSelected={options.stopEditing}
                                        />
                                    )}
                                </OrganizationContext.Consumer>
                            )

                        }}
                    </FormikEditable>
                </div>

                {/* STATUS */}
                <div className='mb-3'>
                    <div className="text-muted"><Translate id='form.fields.status' /></div>
                    <FormikEditable
                        id='status'
                        noConfirmation
                        initialValues={{
                            status: props.onlineStore.status,
                        }}
                        onSubmit={(values) => {
                            if(values.status !== props.onlineStore.status){
                                props.setIsLoading(true)
                                onlineStoreContext.partialUpdateOnlineStore(props.onlineStore.online_store_id, values)
                                    .then(() => {
                                        // Update cached store only if it is the cached one
                                        if(onlineStoreContext.state.cachedStore.online_store_id === props.onlineStore.online_store_id)
                                            onlineStoreContext.updateCachedStore(values);

                                        success();
                                        props.setIsLoading(false);
                                        props.panelProps.syncRows(props.panelProps.createNewValues(values));
                                    })
                                    .catch(handleCatch)
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    <div>
                                        <div className="font-medium">
                                            {/* TRANSLATE */}
                                            {props.onlineStore.status ? <Translate id={`onlineStore.search.status.${props.onlineStore.status}`} /> : '-'}
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <FormikSelect
                                    name='status'
                                    id='status_SidepanelOnlineStoreGeneralTab'
                                    search={false}
                                    autoFocus
                                    menuIsDefaultOpen
                                    onOptionSelected={() => {
                                        options.stopEditing();
                                    }}
                                    loadingStatus='success'
                                    defaultData={[
                                        { id: 'active', value: 'ACTIVE', label: 'onlineStore.search.status.ACTIVE', selected: props.onlineStore.status === 'ACTIVE', translateLabel: true },
                                        { id: 'draft', value: 'DRAFT', label: 'onlineStore.search.status.DRAFT', selected: props.onlineStore.status === 'DRAFT', translateLabel: true },
                                        { id: 'close', value: 'CLOSE', label: 'onlineStore.search.status.CLOSE', selected: props.onlineStore.status === 'CLOSE', translateLabel: true },
                                    ]}
                                />
                            )

                        }}
                    </FormikEditable>
                </div>

                {/* STATUS */}
                <CanDoAction className='mb-3' action='EDIT' componentCode='catalog' componentPermissionCode='online_store_manage_skip_postal_code' skipAccess>
                    <div className="text-muted"><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.bypassPostalCode' /></div>
                    <FormikEditable
                        id='skip_postal_code_validation'
                        noConfirmation
                        initialValues={{
                            skip_postal_code_validation: props.onlineStore.skip_postal_code_validation || "0", // **needed double negative to "enable". inverted the value from the meaning of the label to make it more simple
                        }}
                        onSubmit={(values) => {
                            if(values.skip_postal_code_validation !== props.onlineStore.skip_postal_code_validation){
                                props.setIsLoading(true)
                                onlineStoreContext.partialUpdateOnlineStore(props.onlineStore.online_store_id, values)
                                    .then(() => {
                                        // Update cached store only if it is the cached one
                                        if(onlineStoreContext.state.cachedStore.online_store_id === props.onlineStore.online_store_id)
                                            onlineStoreContext.updateCachedStore(values);

                                        success();
                                        props.setIsLoading(false);
                                        props.panelProps.syncRows(props.panelProps.createNewValues(values));
                                    })
                                    .catch(handleCatch)
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    <div>
                                        <div className="font-medium">
                                            {/* TRANSLATE */}
                                            {props.onlineStore.skip_postal_code_validation ? <Translate id={`misc.yes.no.${props.onlineStore.skip_postal_code_validation === "1" ? "0" : "1"}`} /> : '-'} {/* **needed double negative to "enable". inverted the value from the meaning of the label to make it more simple */}
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <FormikSelect
                                    search={false} autoFocus menuIsDefaultOpen
                                    name="skip_postal_code_validation"
                                    id='skip_postal_code_validation_FormOnlineStoreGeneralInformation'
                                    onOptionSelected={() => {
                                        options.stopEditing();
                                    }}
                                    options={[
                                        {
                                            label: "misc.yes",
                                            translateLabel: true,
                                            value: '0', // **needed double negative to "enable". inverted the value from the meaning of the label to make it more simple
                                        },
                                        {
                                            label: "misc.no",
                                            translateLabel: true,
                                            value: '1', // **needed double negative to "enable". inverted the value from the meaning of the label to make it more simple
                                        },
                                    ]}
                                />
                            )

                        }}
                    </FormikEditable>
                </CanDoAction>

                {/* DISPLAY ORDER */}
                <div className='mb-3'>
                    <div className="text-muted"><Translate id='form.fields.displayOrder' /></div>
                    <FormikEditable
                        id='display_order'
                        initialValues={{
                            display_order: props.onlineStore.display_order,
                        }}
                        validationSchema={object().shape({
                            display_order: number().min(0, <Translate id='form.validation.order.minimum' />).required(<Translate id='form.validation.order.required' />),
                        })}
                        onSubmit={(values) => {
                            if(values.display_order !== props.onlineStore.display_order){
                                props.setIsLoading(true)
                                onlineStoreContext.partialUpdateOnlineStore(props.onlineStore.online_store_id, values)
                                    .then(() => {
                                        // Update cached store only if it is the cached one
                                        if(onlineStoreContext.state.cachedStore.online_store_id === props.onlineStore.online_store_id)
                                            onlineStoreContext.updateCachedStore(values);

                                        success();
                                        props.setIsLoading(false);
                                        props.panelProps.syncRows(props.panelProps.createNewValues(values));
                                    })
                                    .catch(handleCatch)
                            }
                        }}
                    >
                        {(isEditing) => {
                            if(!isEditing){
                                return (
                                    <div className="font-medium">
                                        {/* TRANSLATE */}
                                        {props.onlineStore.display_order ?? '-'}
                                    </div>
                                )
                            }
                            return (
                                <FormikInputNumber name="display_order" id="display_order" />
                            )

                        }}
                    </FormikEditable>
                </div>

                {/* NAMES */}
                <I18nHelperContextProvider key={props.onlineStore.languages} fields={[ 'name', 'description', 'confirmation_message_email' ]} languages={props.onlineStore.languages}>
                    <I18nHelperContext.Consumer>
                        {(i18nHelper) => (
                            <RenderI18nForm field='name'>
                                {({ fieldName, fieldLabel, lang }) => (
                                    <div className='mb-3' key={fieldName}>
                                        <div className="text-muted">{fieldLabel}</div>
                                        <FormikEditable
                                            id={fieldName}
                                            initialValues={
                                                i18nHelper.getInitialValues(props.onlineStore)
                                            }
                                            validationSchema={object().shape(
                                                i18nHelper.getValidationSchema({ 'name': string().required(<Translate id='form.validation.name.required' />) }),
                                            )}
                                            onSubmit={(values) => {
                                                // using JSON stringify because a simple compare like !== will not detect differences between deep values in objects, first level comparison
                                                // by formatting these objects to strings, every character is compared
                                                if(JSON.stringify(values.i18n) !== JSON.stringify(props.onlineStore.i18n)){
                                                    props.setIsLoading(true)
                                                    onlineStoreContext.partialUpdateOnlineStore(props.onlineStore.online_store_id, i18nHelper.getAPIValues(values))
                                                        .then(() => {
                                                            // Update cached store only if it is the cached one
                                                            if(onlineStoreContext.state.cachedStore.online_store_id === props.onlineStore.online_store_id)
                                                                onlineStoreContext.updateCachedStore(values);

                                                            success();
                                                            props.setIsLoading(false);
                                                            props.panelProps.syncRows(props.panelProps.createNewValues(values));
                                                        })
                                                        .catch(handleCatch)
                                                }
                                            }}
                                        >
                                            {(isEditing) => {
                                                if(!isEditing){
                                                    return (
                                                        <div className="font-medium">
                                                            <DisplayI18n field='name' defaultValue={props.onlineStore.name} i18n={props.onlineStore.i18n} lang={lang} />
                                                        </div>
                                                    )
                                                }
                                                return (
                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                )

                                            }}
                                        </FormikEditable>
                                    </div>
                                )}
                            </RenderI18nForm>
                        )}
                    </I18nHelperContext.Consumer>
                </I18nHelperContextProvider>

                {/* REGISTRAR EMAIL */}
                <div className='mb-3'>
                    <div className="text-muted"><Translate id='onlineStore.search.sidepanel.email' /></div>
                    <FormikEditable
                        id='email'
                        disabled
                        initialValues={{
                            email: props.onlineStore.email,
                        }}
                        validationSchema={object().shape({
                            email: string().required(<Translate id='form.validation.email.required' />).email(<Translate id='form.validation.email.valid' />),
                        })}
                        onSubmit={(values) => {
                            if(values.email !== props.onlineStore.email){
                                props.setIsLoading(true)
                                onlineStoreContext.partialUpdateOnlineStore(props.onlineStore.online_store_id, values)
                                    .then(() => {
                                        // Update cached store only if it is the cached one
                                        if(onlineStoreContext.state.cachedStore.online_store_id === props.onlineStore.online_store_id)
                                            onlineStoreContext.updateCachedStore(values);

                                        props.setIsLoading(false);
                                        props.panelProps.syncRows(props.panelProps.createNewValues(values));
                                    })
                                    .catch(handleCatch)
                            }
                        }}
                    >
                        {(isEditing) => {
                            if(!isEditing){
                                return (
                                    <div className="font-medium">{props.onlineStore.email}</div>
                                )
                            }
                            return (
                                <FormikInputText id='email' name='email' trim />
                            )

                        }}
                    </FormikEditable>
                </div>

                <Card body className='card-shadow mb-3'>
                    <div className='h6 font-bold mb-3'><Translate id='onlineStore.search.sidepanel.registration.start' /></div>
                    <Row>
                        <Col md='6'>
                            <div className='text-muted'><Translate id='onlineStore.search.sidepanel.registration.date' /></div>
                            <DateFormat value={props.onlineStore.start_date} />
                        </Col>
                        <Col md='6'>
                            <div className='text-muted'><Translate id='onlineStore.search.sidepanel.registration.time' /></div>
                            <DateFormat value={props.onlineStore.start_date} format={'HH:mm a'} />
                        </Col>
                    </Row>
                </Card>

                <Card body className='card-shadow mb-3'>
                    <div className='h6 font-bold mb-3'><Translate id='onlineStore.search.sidepanel.registration.end' /></div>
                    <Row>
                        <Col md='6'>
                            <div className='text-muted'><Translate id='onlineStore.search.sidepanel.registration.date' /></div>
                            <DateFormat value={props.onlineStore.end_date} />
                        </Col>
                        <Col md='6'>
                            <div className='text-muted'><Translate id='onlineStore.search.sidepanel.registration.time' /></div>
                            <DateFormat value={props.onlineStore.end_date} format={'HH:mm a'} />
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    )
}

export default SidePanelOnlineStoreGeneralTab;