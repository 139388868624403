import { stringBuilder } from "@spordle/helpers";

const CollapseIcon = ({ isOpen, iconColor = 'light' }) => {
    return (
        <i
            className={
                stringBuilder(
                    { "rotate-180": isOpen },
                    `bg-${iconColor} border d-flex align-items-center justify-content-center transition d-inline-block mdi mdi-chevron-down rounded-circle`,
                )
            }
            style={{
                height: 25,
                width: 25,
            }}
        />
    );
}

export default CollapseIcon;