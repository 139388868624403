import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Alert, Button, Card, Collapse, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../../../analytics/AnalyticsModal";
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import { MembersContext } from "../../../../../../../contexts/MembersContext";
import { getLocation as buildFullAddress } from '../../../../../../../views/teams/TeamHelpers';
import CrossFade from "../../../../../../../components/crossFade/CrossFade";
import { Form, Formik } from "formik";
import { FormikCheckedButton, FormikTextArea } from "@spordle/formik-elements";
import { bool, object, string } from "yup";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import Required from "../../../../../../../components/formik/Required";
import CustomAnimatedIcon from "../../../../../../../components/customAnimatedIcon/CustomAnimatedIcon";
import { IdentityRolesContext } from "../../../../../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import { DocumentTypesContext } from "../../../../../../../contexts/DocumentTypesContext";
import useSWR from "swr";
import InlineCopy from "../../../../../../../components/inlineCopy/InlineCopy";


const AddressConfirmationModal = ({ toggle, isOpen, currentAddress, allAddresses, getAddresses }) => {
    // TOOK EXAMPLE ON CreateClinic.js
    const { federation } = useContext(IdentityRolesContext);
    const { settings } = useContext(OrganizationContext);
    const { getDocumentTypes } = useContext(DocumentTypesContext);

    const { data: documentTypes } = useSWR(
        [ 'getFederationDocumentTypes', settings.attachment_for_new_member_in_registration ],
        () => getDocumentTypes(federation.organisation_id)
            .then((documents) => {
                return settings.attachment_for_new_member_in_registration.value.map((documentTypeId) => (
                    documents.find(({ document_type_id }) => document_type_id === documentTypeId)
                )).filter((document) => !!document);
            })
            .catch(console.error)
        ,
    );

    const membersContext = useContext(MembersContext);
    const [ currentSelectedOption, setCurrentSelectedOption ] = useState(null);
    const [ currentStep, setCurrentStep ] = useState(1);

    return (
        <AnalyticsModal analyticsName="AddressConfirmationModal" isOpen={isOpen} unmountOnClose>
            <ModalHeader toggle={toggle}>
                <Translate id='members.profile.overview.memberAddressPhone.modal.title' />
            </ModalHeader>

            <Formik
                initialValues={{
                    rejectNote: '',
                    documentsAreValidated: false,
                }}
                validationSchema={object().shape({
                    rejectNote: string().test({
                        name: 'noteRequiredOnReject',
                        message: <Translate id='members.profile.overview.memberAddressPhone.modal.reject.required' />,
                        test: function(note){
                            return !(!note && currentSelectedOption === 2)
                        },
                    }),
                    documentsAreValidated: bool(),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    setSubmitting(true);
                    const additionalApiValues = {};

                    if(currentAddress.years_same_address){
                        additionalApiValues.years_same_address = currentAddress.years_same_address;
                    }

                    switch (currentSelectedOption){
                        // confirm main address
                        case 0:
                            membersContext.updateMemberAddressPartial(
                                membersContext.currentMember.member_id,
                                currentAddress.member_address_id,
                                {
                                    status: 'CONFIRMED',
                                    default_address: '1',
                                    ...additionalApiValues,
                                },
                            )
                                .then(async() => {
                                    if(values.documentsAreValidated){
                                        await membersContext.updateMemberPartial(
                                            membersContext.currentMember.member_id,
                                            { need_address_document: 0 },
                                        );
                                    }

                                    await getAddresses()

                                    // success page
                                    setStatus('success');
                                    setCurrentStep(2);
                                    setSubmitting(false);
                                    membersContext.getMember(membersContext.currentMember.member_id);
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message);
                                        setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                        setSubmitting(false);
                                    }
                                })
                            break;
                        // confirm additional address
                        case 1:
                            membersContext.updateMemberAddressPartial(
                                membersContext.currentMember.member_id,
                                currentAddress.member_address_id,
                                { status: 'CONFIRMED', ...additionalApiValues },
                            )
                                .then(async() => {
                                    if(values.documentsAreValidated){
                                        await membersContext.updateMemberPartial(
                                            membersContext.currentMember.member_id,
                                            { need_address_document: 0 },
                                        );
                                    }

                                    await getAddresses()

                                    // success page
                                    setStatus('success');
                                    setCurrentStep(2);
                                    setSubmitting(false);
                                    membersContext.getMember(membersContext.currentMember.member_id);
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message);
                                        setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                        setSubmitting(false);
                                    }
                                })
                            break;
                        //reject address
                        case 2:
                            membersContext.updateMemberAddressPartial(
                                membersContext.currentMember.member_id,
                                currentAddress.member_address_id,
                                { status: 'REJECTED', status_note: values.rejectNote },
                            ).then(async() => {
                                await getAddresses()

                                // success page
                                setStatus('success');
                                setCurrentStep(2);
                                setSubmitting(false);
                                membersContext.getMember(membersContext.currentMember.member_id);
                            }).catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message);
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                    setSubmitting(false);
                                }
                            })
                            break;

                        default:
                            break;
                    }
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            {(currentStep === 1 && formik.status != 'success') &&
                                <>
                                    <ModalBody>
                                        <div className="font-bold ml-1 mb-2">
                                            <Translate id='members.profile.overview.memberAddressPhone.modal.addressToChange' />
                                        </div>
                                        <Card body className={'mb-4 card-shadow'}>
                                            <div className='font-medium'>
                                                <InlineCopy tag='div' toCopy={buildFullAddress(currentAddress)} className='font-medium'>
                                                    {currentAddress.map_url ?
                                                        <a href={currentAddress.map_url} target='_blank' rel='noopener noreferrer'>
                                                            {buildFullAddress(currentAddress)}
                                                        </a>
                                                        :
                                                        buildFullAddress(currentAddress)
                                                    }
                                                    {currentAddress.unit_number &&
                                                        <>
                                                            <span className="mx-2">-</span>
                                                            {currentAddress.unit_number}
                                                        </>
                                                    }
                                                </InlineCopy>
                                            </div>
                                        </Card>
                                        <div className=''>
                                            {/* ACCEPT AND PRIMARY - OPTION 0 */}
                                            <UserDisplay
                                                block card hover
                                                className={`mb-3 ${currentSelectedOption === 0 ? 'border-primary shadow-primary' : ''}`} // TODO: make real condition for selected
                                                onClick={() => setCurrentSelectedOption(0)}
                                            >
                                                <UserDisplay.Container className="mr-3">
                                                    <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                                                        <i style={{ fontSize: '30px' }} className="mdi mdi-home-outline" />
                                                        <i style={{ height: '17px', width: '17px' }} className='text-primary d-flex flex-center mdi mdi-star-circle line-height-1 bg-light rounded-circle position-absolute ml-3 mb-n3 z-index-1 font-16' />
                                                        {/* color: '#dda407' */}
                                                    </div>
                                                </UserDisplay.Container>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title className="font-bold"><Translate id="members.profile.overview.memberAddressPhone.modal.accept.primary" /></UserDisplay.Title>
                                                    <UserDisplay.Subtitle><Translate id="members.profile.overview.memberAddressPhone.modal.accept.primary.desc" /></UserDisplay.Subtitle>
                                                </UserDisplay.Container>
                                            </UserDisplay>

                                            {/* ACCEPT AND ADD ADDITIONAL - OPTION 1 */}
                                            <UserDisplay
                                                block card hover
                                                className={`mb-3 ${currentSelectedOption === 1 ? 'border-primary shadow-primary' : ''}`}
                                                onClick={() => setCurrentSelectedOption(1)}
                                            >
                                                <UserDisplay.Container className="mr-3">
                                                    <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                                                        <i style={{ fontSize: '30px' }} className="mdi mdi-home-outline" />
                                                        <i style={{ height: '17px', width: '17px' }} className='text-success d-flex flex-center mdi mdi-plus-circle-outline line-height-1 bg-light rounded-circle position-absolute ml-3 mb-n3 z-index-1 font-16' />
                                                    </div>
                                                </UserDisplay.Container>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title className="font-bold"><Translate id="members.profile.overview.memberAddressPhone.modal.accept.additional" /></UserDisplay.Title>
                                                    <UserDisplay.Subtitle><Translate id="members.profile.overview.memberAddressPhone.modal.accept.additional.desc" /></UserDisplay.Subtitle>
                                                </UserDisplay.Container>
                                            </UserDisplay>

                                            {/* REJECT - OPTION 2 */}
                                            <UserDisplay
                                                block card hover
                                                className={`mb-3 ${currentSelectedOption === 2 ? 'border-primary shadow-primary' : ''}`}
                                                onClick={() => setCurrentSelectedOption(2)}
                                            >
                                                <UserDisplay.Container className="mr-3">
                                                    <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                                                        <i style={{ fontSize: '30px' }} className="mdi mdi-home-outline" />
                                                        <i style={{ height: '17px', width: '17px' }} className='text-danger d-flex flex-center mdi mdi-close-circle-outline line-height-1 bg-light rounded-circle position-absolute ml-3 mb-n3 z-index-1 font-16' />
                                                    </div>
                                                </UserDisplay.Container>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title className="font-bold"><Translate id="members.profile.overview.memberAddressPhone.modal.reject" /></UserDisplay.Title>
                                                </UserDisplay.Container>
                                            </UserDisplay>

                                            <CrossFade isVisible={currentSelectedOption === 2} className='mb-2'>
                                                <Label><Translate id='members.profile.overview.memberAddressPhone.modal.reject.noteLabel' /> <Required /></Label>
                                                <FormikTextArea className="w-100 border" name="rejectNote" id="rejectNote" rows="2" />
                                            </CrossFade>

                                            {formik.status &&
                                                <Collapse isOpen appear>
                                                    <Alert color='danger'>{formik.status}</Alert>
                                                </Collapse>
                                            }
                                            {Array.isArray(documentTypes) && documentTypes.length > 0 &&
                                                <CrossFade isVisible={(currentSelectedOption === 0 || currentSelectedOption === 1)}>
                                                    <FormikCheckedButton
                                                        name="documentsAreValidated"
                                                        id="documentsAreValidated"
                                                        label="members.profile.overview.memberAddressPhone.modal.checkbox.documentsValidated"
                                                    />
                                                </CrossFade>
                                            }
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button disabled={formik.isSubmitting || !isOpen || currentSelectedOption === null} type='submit' color='primary'><Translate id='members.profile.overview.button.validate' /></Button>
                                        <Button color='outline' className='btn btn-outline-primary' onClick={toggle}>
                                            <Translate id='misc.cancel' />
                                        </Button>
                                    </ModalFooter>
                                </>
                            }
                            {currentStep === 2 &&
                                <>
                                    <ModalBody>
                                        <CustomAnimatedIcon
                                            className={` ${currentSelectedOption === 2 ? 'text-danger' : 'text-success' } mb-2`}
                                            id='customStatusIconButtonNotifications'
                                            size='50px'
                                            icon={currentSelectedOption === 2 ? 'error' : 'checkmark'}
                                            strokeWidth='10px'
                                            withCircle
                                        />
                                        <div className="font-bold ml-1 mb-2 text-center text-dark">
                                            <Translate id={`members.profile.overview.memberAddressPhone.modal.confirm.${currentSelectedOption}`} />
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button disabled={formik.isSubmitting || !isOpen} onClick={() => { toggle(); setCurrentStep(1); membersContext.setShouldAttemptAutoConfirm(true); }} color='primary'><Translate id='misc.close' /></Button>
                                    </ModalFooter>
                                </>
                            }
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    );
};

export default AddressConfirmationModal;
