import { useContext } from 'react';
import { Badge } from 'reactstrap';
import { ChecklistsContext } from '../../../../contexts/ChecklistsContext';

const ChecklistNav = () => {
    const { checklists, activeChecklist } = useContext(ChecklistsContext);
    const hasActive = activeChecklist && checklists?.[activeChecklist];

    return (!hasActive || process.env.REACT_APP_ENVIRONMENT !== "int") ? null : <ChecklistNavBtn />
}

const ChecklistNavBtn = () => {
    const checklistsContext = useContext(ChecklistsContext);
    const activeChecklist = checklistsContext.checklists?.[checklistsContext.activeChecklist];

    return (
        <>
            <li className="dropdown nav-item">
                <div id="quick-view-header-toggle" className="position-relative nav-link clickable" onClick={() => checklistsContext.toggleAssistant()}>
                    <div>
                        <span className='small font-medium'>
                            <i className="mdi mdi-checkbox-multiple-marked-outline font-18" />
                        </span>
                        {(Array.isArray(activeChecklist.steps) && activeChecklist.steps.length > 0) &&
                            <Badge
                                id="quick-view-header-toggle-badge"
                                className="position-absolute top-0 right-0 font-normal"
                                color={"federation"}
                                style={{ fontSize: "0.55rem" }}
                                pill
                            >
                                {activeChecklist.steps.length}
                            </Badge>
                        }
                    </div>
                </div>
            </li>
        </>
    );
}

export default ChecklistNav;