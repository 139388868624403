import { FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { Alert, Button, Card, CardBody, Col, Collapse, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import Required from "../../../../../../../../components/formik/Required";
import { fail } from "@spordle/toasts";
import { PeriodsContext } from "../../../../../../../../contexts/contexts";
import { I18nContext } from "../../../../../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../../../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../../../../../../contexts/OrganizationContext";
import { TeamsContext } from "../../../../../../../../contexts/TeamsContext";
import { DisplayI18n, displayI18n } from "../../../../../../../../helpers/i18nHelper";
import { DisplayCategory } from "../../../../../../../teams/TeamHelpers";

const BRANCH_NAME = "Member branches";

const GuiltyTeamSearch = ({ onSubmit, isLoading, previous, initValues = {}, type, teamId }) => {
    const { getOrganizationByCategories, getOrganizationCategory, organisation_id, organisation_category } = useContext(OrganizationContext);
    const { federation } = useContext(IdentityRolesContext);
    const { getTeams } = useContext(TeamsContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const currentOrgIsNSO = organisation_category.name == "NSO";
    const isMemberPartner = organisation_category.name == "Member partner";
    const isBranch = organisation_category.name == BRANCH_NAME;
    const formikRef = useRef();

    const orgSelectRef = useRef(null);
    const teamSelectRef = useRef(null);
    const { getGenericLocale } = useContext(I18nContext);
    const [ ressources, setRessources ] = useState({
        branches: null,
        categories: null,
    });

    const orgCategories = ressources.categories?.reduce((keptCat, category) => {
        if([ "mha", "district", "region", "team" ].includes((category.default_name || '').toLowerCase())){
            keptCat.push(category.category_id);
        }
        return keptCat;
    }, []) || [];

    const updateData = (data) => setRessources((prev) => ({ ...prev, ...data }));

    const getTeamOptionLabel = (option) => {
        const team = option.option.team;

        return (
            <>
                <div className="font-medium">
                    <DisplayI18n
                        field="name"
                        i18n={team.i18n}
                        defaultValue={team.name}
                    />
                </div>
                <div className="font-14">
                    <DisplayI18n
                        field="short_name"
                        i18n={team.i18n}
                        defaultValue={team.short_name}
                    />
                </div>
                <div className="text-muted small">
                    #{team.unique_identifier}
                </div>
                <div className="text-muted small">
                    <DisplayCategory short category={team.team_category} /> (<Translate id={'form.fields.gender.' + (team.team_category?.gender || '').toLowerCase()} />)
                </div>
            </>
        )
    }

    const getBranches = async() => {
        const categories = await getOrganizationCategory(organisation_id);
        const branchCatId = categories?.find((c) => (c.default_name || '').toLowerCase() === BRANCH_NAME.toLowerCase())?.category_id;
        const branches = await getOrganizationByCategories(federation.organisation_id, { organisation_category_id: branchCatId });

        updateData({
            categories: categories || [],
            branches: (branches || []).sort((a, b) => {
                const aName = displayI18n('name', a.i18n, a.organisation_name, getGenericLocale());
                const bName = displayI18n('name', b.i18n, b.organisation_name, getGenericLocale());
                return aName.localeCompare(bName);
            }),
        });

        if(!currentOrgIsNSO){
            const table = orgSelectRef.current?.getSpordleTable?.();
            table?.filterChange?.('branchId', organisation_id);
        }
    }

    const getNewTeam = (values) => ({
        team: teamSelectRef?.current?.getSpordleTable()?.getData()?.find((team) => team.value === values.team_id),
    })

    useEffect(() => { // on mount
        getBranches()
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    updateData({ branches: [] });
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    useEffect(() => {
        if(type == "TEAM" && formikRef.current && formikRef.current.values.team_id !== teamId){
            formikRef.current.setFieldValue('team_id', "");
            formikRef.current.setFieldValue('organisation_id', "");
        }
    }, [ teamId, type ])

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                branch_id: !currentOrgIsNSO ? organisation_id : '',
                organisation_id: !isBranch && !currentOrgIsNSO && !isMemberPartner ? organisation_id : '',
                team_id: '',
                belongs_to: 'HOME',
                ...initValues,
            }}
            validationSchema={object().shape({
                branch_id: string().required(<Translate id='discrimination.form.branch.required' />),
                organisation_id: string().required(<Translate id='discrimination.form.org.required' />),
                team_id: string().required(<Translate id='discrimination.form.team.required' />),
                belongs_to: string().required(<Translate id='discrimination.form.belongsTo.required' />),
            })}
            onSubmit={(values, formikBag) => onSubmit(values, formikBag, getNewTeam(values))}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <Card className="card-shadow mb-0">
                            <CardBody className="p-3">
                                <div className="font-bold h4 mb-2">
                                    <Translate id='discrimination.addModal.stepGuilty.team.title' />
                                </div>
                                <p><Translate id='discrimination.addModal.stepGuilty.team.helper' /></p>
                                <Row form>
                                    {currentOrgIsNSO &&
                                        <Col className="form-group" sm="12">
                                            <Label for="branch_id" className="text-muted">
                                                <Translate id='gameIncident.addModal.label.branch' />
                                            </Label>
                                            <FormikSelect
                                                name="branch_id"
                                                id="branch_id"
                                                renderOption={(opt) => (
                                                    <DisplayI18n
                                                        field="name"
                                                        i18n={opt.option.i18n}
                                                        defaultValue={opt.option.label}
                                                    />
                                                )}
                                                search
                                                searchKeys={[ `i18n.${getGenericLocale()}.name` ]}
                                                onOptionSelected={([ value ]) => {
                                                    orgSelectRef.current?.getSpordleTable().filterChange('branchId', value);
                                                    formik.setFieldValue('organisation_id', '');
                                                    formik.setFieldValue('team_id', '');
                                                }}
                                                isLoading={ressources.branches === null}
                                                options={ressources.branches ? ressources.branches.map((org) => ({
                                                    value: org.organisation_id,
                                                    label: org.organisation_name,
                                                    i18n: org.i18n,
                                                })) : []}
                                            />
                                        </Col>
                                    }
                                    {(currentOrgIsNSO || isBranch || isMemberPartner) &&
                                        <Col style={{ opacity: !formik.values.branch_id ? 0.5 : 1, pointerEvents: !formik.values.branch_id ? "none" : undefined }} className="form-group" sm="12">
                                            <Label for="organisation_id" className="text-muted">
                                                <Translate id='gameIncident.addModal.label.org' /> <Required />
                                            </Label>
                                            <FormikSelect
                                                name="organisation_id"
                                                id="organisation_id"
                                                ref={orgSelectRef}
                                                initFilter={{ branchId: '' }}
                                                searchKeys={[ `i18n.${getGenericLocale()}.name` ]}
                                                renderOption={(opt) => (
                                                    <DisplayI18n
                                                        field="name"
                                                        i18n={opt.option.i18n}
                                                        defaultValue={opt.option.label}
                                                    />
                                                )}
                                                clearable
                                                onOptionSelected={([ value ]) => {
                                                    teamSelectRef.current?.getSpordleTable().filterChange('orgId', value);
                                                    formik.setFieldValue('team_id', '');
                                                }}
                                                loadData={(from, { filters }, { setLoading }) => {
                                                    const formatOrgs = (orgs) => (
                                                        (orgs || []).sort((a, b) => {
                                                            const aName = displayI18n('name', a.i18n, a.organisation_name, getGenericLocale());
                                                            const bName = displayI18n('name', b.i18n, b.organisation_name, getGenericLocale());
                                                            return aName.localeCompare(bName);
                                                        })
                                                            .map((org) => ({
                                                                value: org.organisation_id,
                                                                label: org.organisation_name,
                                                                i18n: org.i18n,
                                                            }))
                                                    )

                                                    switch (from){
                                                        case 'FILTER':
                                                            if(filters.branchId){
                                                                setLoading();

                                                                return getOrganizationByCategories(filters.branchId, { organisation_category_id: orgCategories }).then(formatOrgs);
                                                            }
                                                            break;
                                                        case 'CDM':
                                                            return Promise.resolve([]);
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            />
                                        </Col>
                                    }
                                    <Col
                                        sm="12"
                                        style={!isBranch && !currentOrgIsNSO ? void 0 : { opacity: !formik.values.branch_id || !formik.values.organisation_id ? 0.5 : 1,
                                                                                          pointerEvents: !formik.values.branch_id || !formik.values.organisation_id ? "none" : undefined }}
                                    >
                                        <Label className="text-muted" for="team_id"><Translate id='discrimination.label.team' /> <Required /></Label>
                                        <FormikSelect
                                            name="team_id"
                                            id="team_id"
                                            ref={teamSelectRef}
                                            initFilter={{
                                                orgId: !isBranch && !currentOrgIsNSO ? organisation_id : '',
                                            }}
                                            clearable
                                            renderOption={getTeamOptionLabel}
                                            renderSelectedOption={(opt) => (
                                                <>
                                                    <div className="font-medium">
                                                        <DisplayI18n
                                                            field="name"
                                                            i18n={opt.team.i18n}
                                                            defaultValue={opt.team.name}
                                                        />
                                                    </div>
                                                    <div className="text-muted small">
                                                        <DisplayCategory short category={opt.team.team_category} /> (<Translate id={'form.fields.gender.' + (opt.team.team_category?.gender || '').toLowerCase()} />)
                                                    </div>
                                                </>
                                            )}
                                            searchKeys={[
                                                'team.name',
                                                'team.short_name',
                                                'team.unique_identifier',
                                                'team.team_category.gender',
                                                `team.team_category.division.i18n.${getGenericLocale()}.short_name`,
                                                `team.team_category.class.i18n.${getGenericLocale()}.short_name`,
                                            ]}
                                            loadData={(from, { filters }, { setLoading }) => {
                                                switch (from){
                                                    case 'FILTER':
                                                    case 'CDM':
                                                        if(filters.orgId){
                                                            setLoading();

                                                            const formatTeams = (teams) => (
                                                                teams.map((team) => ({
                                                                    ...team,
                                                                    value: team.team_id,
                                                                    label: team.name,
                                                                    i18n: team.i18n,
                                                                    team: team,
                                                                }))
                                                            );

                                                            return getTeams({ organisation_id: filters.orgId, period_id: selectedPeriod.period_id }).then(formatTeams)
                                                        }
                                                        return Promise.resolve([]);
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        {formik.status &&
                            <Collapse isOpen appear>
                                <Alert color="danger" className="mt-3 mb-0">
                                    {formik.status}
                                </Alert>
                            </Collapse>
                        }
                    </ModalBody>
                    <ModalFooter>
                        {previous &&
                            <Button disabled={isLoading} type="button" onClick={previous} outline color="primary" className="mr-auto"><Translate id="misc.previous" /></Button>
                        }
                        <Button disabled={isLoading} type="submit" color="primary"><Translate id="misc.next" /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default GuiltyTeamSearch;