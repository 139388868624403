import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { array } from "yup";
import { AxiosCancelInsuranceCalls, AxiosIsCancelled } from "../../../api/CancellableAPI";
import { Button, Card, Spinner } from "reactstrap";
import CanDoAction from "../../../components/permissions/CanDoAction";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../components/table/EmptyLayout";
import { fire, removeToast, updateToast } from "@spordle/toasts";
import { triggerDownload } from "../../../components/uploader/uploadHelpers";
import { I18nContext } from "../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../contexts/IdentityRolesContext";
import { InjuriesContext } from "../../../contexts/InjuriesContext";
import { RolesContext } from "../../../contexts/RolesContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import { PeriodChangeHandler } from "../../../helpers/periodHelper";
import ExportCheques from "./components/ExportCheques";
import { InjuryStatus } from "./components/InsuranceHelper";
import InsuranceAddModal from "./components/modal/InsuranceAddModal";
import InsuranceSidePanel from "./components/sidePanel/InsuranceSidePanel";
import { useInsuranceContent } from "./components/useInsuranceContent";
import InsuranceSearchFilter from "./InsuranceSearchFilter";
import { getLocation } from "../../teams/TeamHelpers";
import { DocumentTypesContext } from "../../../contexts/DocumentTypesContext";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";

const Insurance = () => {
    const { organisation_id } = useContext(OrganizationContext);
    const injuryContext = useContext(InjuriesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { canDoAction, hasAccessTo } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const injuryDataLists = useInsuranceContent();
    const { getOrganizationSettings } = useContext(OrganizationContext);
    const { federation } = useContext(IdentityRolesContext);
    const { getDocumentTypes } = useContext(DocumentTypesContext);
    const [ isOpen, setIsOpen ] = useState(false);
    const canEdit = canDoAction("EDIT", "members", "members_injuries") || isGod();
    const canPrint = canDoAction("EXPORT", "members", "member_checks_generation") || isGod();
    const canReadCheques = canDoAction("READ", "members", "member_checks_generation") || isGod();
    const canSeeSensitive = hasAccessTo('members', 'member_insurance_sensitive_data');

    const [ docInfo, setDocInfo ] = useState({
        initVal: {},
        validationSchema: {},
        requiredTypes: [],
    });

    const tableColumns = [
        {
            label: <Translate id='insurance.label.season' />,
            key: 'season',
            sortKey: `period.i18n.${getGenericLocale()}.name`,
            fallbackSortKey: 'period.name',
            sortable: true,
        },
        {
            label: '#',
            key: 'injury_case_number',
            sortable: true,
        },
        {
            label: <Translate id='insurance.label.member' />,
            key: 'member',
            sortKey: 'member.first_name',
            fallbackSortKey: 'member.last_name',
            sortable: true,
        },
        {
            label: <Translate id='insurance.label.branch' />,
            key: 'branch',
            sortKey: `branch.${getGenericLocale()}.name`,
            fallbackSortKey: 'branch.organisation_name',
            sortable: true,
        },
        {
            label: <Translate id='insurance.label.injury' />,
            key: 'injury',
            sortKey: `body_part.i18n.${getGenericLocale()}.name`,
            fallbackSortKey: 'body_part.name',
            sortable: true,
        },
        {
            label: <Translate id='insurance.label.reserve' />,
            key: 'reserve',
            sortable: true,
        },
        {
            label: 'Date',
            key: 'date',
            sortable: false,
        },
        {
            label: <Translate id='insurance.label.updatedBy' />,
            key: 'updated_by',
            sortable: false,
        },
        {
            label: <Translate id="misc.status" />,
            key: 'status',
            sortable: false,
        },
    ]

    /**
     * @param {() => Promise<string>} promise
     * @param {string} id
     */
    const printItems = (promise, id) => {
        fire({
            id: id,
            icon: <Spinner color="primary" size="sm" />,
            msg: 'insurance.sidePanel.cheque.download.msg',
            info: 'insurance.sidePanel.cheque.download.info',
            permanent: true,
        });

        return promise()
            .then((path) => {
                triggerDownload(path);
                removeToast(id);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    updateToast({
                        id: id,
                        icon: false,
                        msg: 'misc.error',
                        info:
                        <DisplayI18n
                            field="message"
                            i18n={error.i18n}
                            defaultValue={error.message}
                        />,
                        skipInfoTranslate: true,
                        permanent: false,
                        theme: 'danger',
                    });
                }
            });
    }

    /**
     * @description Function used to print one or multiple cheques
     * @param {object} params
     */
    const printCheques = (params) => {
        printItems(() => {
            return injuryContext.printInsuranceCheques(params)
        }, 'printChequeToast');
    };

    /**
     * @description Function used to print cheques worksheet
     * @param {object} params
     */
    const printWorksheet = (params) => {
        return printItems(() => {
            return injuryContext.printInsuranceWorksheet(params)
        }, 'printWorksheetToast')
    }

    const getDocumentInfos = async() => {
        const docTypes = await getDocumentTypes(federation.organisation_id).catch(() => []);
        const settings = await getOrganizationSettings(federation.organisation_id).catch(() => []);
        const insuranceDocs = settings.document_type_list_insurance;

        if(insuranceDocs){
            const newDocInfo = insuranceDocs.value.reduce((docs, docId) => {
                const settingDoc = docTypes.find((dT) => dT.document_type_id === docId);

                if(settingDoc){
                    docs.requiredTypes.push(settingDoc);
                    docs.initVal[settingDoc.document_type_id] = [];
                    docs.validationSchema[settingDoc.document_type_id] = array();
                }

                return docs;
            }, {
                initVal: {},
                validationSchema: {},
                requiredTypes: [],
            });

            setDocInfo(newDocInfo);
        }

    }

    useEffect(() => {
        getDocumentInfos();
    }, [])

    return (
        <>
            {/* Task View Header */}
            <ViewHeaderV2 title='insurance.title' />

            <div className="px-4">
                <SpordlePanelTable
                    dataIndex='injury_id'
                    allowOutsideClick
                    sidePanel={(props) => (
                        <InsuranceSidePanel
                            docInfo={docInfo}
                            canReadCheques={canReadCheques}
                            printWorksheet={printWorksheet}
                            canPrint={canPrint}
                            printCheques={printCheques}
                            canEdit={canEdit}
                            injuryDataLists={injuryDataLists}
                            {...props}
                        />
                    )}
                    table={(panelProps) => (
                        <SpordleTableProvider
                            id='TasksInsuranceTable'
                            tableHover bordered striped
                            clickable
                            ref={panelProps.spordleTableRef}
                            dataIndex='injury_id'
                            pagination={10}
                            emptyLayout={<EmptyLayout />}
                            searchKeys={[
                                { name: 'injury_case_number', weight: 2 },
                                'member.first_name',
                                'member.last_name',
                                `branch.${getGenericLocale()}.name`,
                                'branch.organisation_name',
                                'member.address.street',
                                'member.address.zip_code',
                                'member.address.city',
                                'member.unique_identifier',
                                'reserve',
                                `body_part.i18n.${getGenericLocale()}.title`,
                                `injury_status.i18n.${getGenericLocale()}.title`,
                                'accident_date',
                            ]}
                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                            loadData={(from, { filters }, spordleTable) => {
                                switch (from){
                                    case 'FILTER':
                                    case 'REFRESH':
                                        spordleTable.setLoading();
                                        if(Object.keys(filters)?.length > 0){
                                            AxiosCancelInsuranceCalls();
                                            const apiParams = { ...filters, all: 1, organisation_id };

                                            return injuryContext.getInjuryList(apiParams)
                                                .catch((error) => {
                                                    if(AxiosIsCancelled(error.message)){
                                                        return true;
                                                    }
                                                })
                                        }
                                    case 'CDM':
                                        return Promise.resolve([]);
                                    default:
                                        break;
                                }
                            }}
                            columns={canSeeSensitive || isGod() ? tableColumns : tableColumns.filter((c) => c.key !== 'status' && c.key !== 'reserve')}
                            renderRow={(columnKey, injury) => {
                                switch (columnKey){
                                    case 'season':
                                        return <DisplayI18n field="name" defaultValue={injury.period?.name} i18n={injury.period?.i18n} />
                                    case 'member':
                                        return (
                                            <>
                                                <div className="text-muted small">
                                                    <FormattedMessage id={'insurance.label.participantType.' + injury.participant_type} defaultMessage="noTranslate">
                                                        {(message) => (message[0] !== 'noTranslate') ? message : injury.participant_type || ''}
                                                    </FormattedMessage>
                                                </div>
                                                <div className="font-medium">{injury.member.first_name} {injury.member.last_name}</div>
                                                <div className="text-link small text-nowrap">
                                                    <Link to={`/members/profile/${injury.member.member_id}`}>
                                                        #{injury.member.unique_identifier} <i className="mdi mdi-chevron-right" />
                                                    </Link>
                                                </div>
                                                <div className="text-muted small">{injury.member.birthdate}</div>
                                                <div className="text-muted small">{getLocation(injury.member.address)}</div>
                                            </>
                                        )
                                    case 'branch':
                                        return (
                                            <DisplayI18n
                                                field="name"
                                                defaultValue={injury.branch?.organisation_name}
                                                i18n={injury.branch?.i18n}
                                            />
                                        )
                                    case 'injury':
                                        return (
                                            <DisplayI18n
                                                field="name"
                                                defaultValue={injury.body_part?.name}
                                                i18n={injury.body_part?.i18n}
                                            />
                                        )
                                    case 'reserve':
                                        return (
                                            <CurrencyFormat
                                                value={parseInt(injury.reserve) / 100}
                                            />
                                        );
                                    case 'updated_by':
                                        return (
                                            <div>
                                                {injury.updated_at && <div><Translate id='insurance.label.updatedBy' />: <b>{injury.updated_by?.name} {injury.updated_by?.family_name}</b></div>}
                                                {injury.updated_at && <div><Translate id='insurance.label.updatedOn' />: <b><DateFormat value={injury.updated_at} utc /></b></div> }
                                            </div>
                                        );
                                    case 'date':
                                        return (
                                            <div>
                                                <div className="text-muted small">
                                                    <Translate id="insurance.label.accidentDate" />
                                                </div>
                                                <div className="text-nowrap font-medium mb-2">
                                                    <DateFormat value={injury.accident_date} utc />
                                                </div>
                                                <div className="text-muted small">
                                                    <Translate id="insurance.label.deliveredDate" />
                                                </div>
                                                <div className="text-nowrap font-medium">
                                                    {injury.delivered_date ?
                                                        <DateFormat value={injury.delivered_date} utc />
                                                        :
                                                        '-'
                                                    }
                                                </div>
                                            </div>
                                        )
                                    case 'status':
                                        return <InjuryStatus injuryStatus={injury.injury_status} />
                                    default:
                                        break;
                                }
                            }}
                            onColumnClick={(e, data) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        panelProps.onSingleSelectChange(data);
                                        break;
                                }
                            }}
                            rowIsHighlighted={(data) => data.checked}
                        >
                            <InsuranceSearchFilter canSeeSensitive={canSeeSensitive} status={injuryDataLists.status} />
                            <Card body className='card-shadow'>
                                <PeriodChangeHandler useRefreshTable />
                                <div className='mb-2'>
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <SpordleTableProvider.SearchInput />
                                        <div className='d-flex ml-auto text-right'>
                                            <SpordleTableProvider.Refresh />
                                            <ExportCheques
                                                printWorksheet={printWorksheet}
                                                printCheques={printCheques}
                                                canPrint={canPrint}
                                            />
                                            <CanDoAction componentCode="members" componentPermissionCode="members_injuries" action="ADD">
                                                <Button type="button" className='ml-2' color='primary' onClick={() => setIsOpen(!isOpen)}>
                                                    <i className='ti-plus mr-1' /><Translate id='misc.add' />
                                                </Button>
                                                <InsuranceAddModal
                                                    docInfo={docInfo}
                                                    injuryDataLists={injuryDataLists}
                                                    isOpen={isOpen}
                                                    toggle={() => setIsOpen(false)}
                                                />
                                            </CanDoAction>
                                        </div>
                                    </div>
                                </div>
                                <SpordleTableView />
                            </Card>
                        </SpordleTableProvider>
                    )}
                />
            </div>
        </>
    )
}

export default Insurance;