import { useIntl } from "react-intl";
import { Button } from "reactstrap";

const I18n = ({ i18n }) => {
    const { locale } = useIntl();
    const myLang = locale.split('-')[0] || "en";

    return i18n[myLang]
}

const PrintableMsg = ({ msg }) => {

    return (
        <div className="container py-4">
            <div className="text-right d-print-none mb-3">
                <Button id="printBtn" type="button">
                    <I18n i18n={{ en: "Print", fr: "Imprimer" }} />
                </Button>
            </div>
            <div className="font-bold h4">{msg.title}</div>
            <div className="small font-medium">
                <span className="text-muted font-normal mr-1">Date:</span>
                {msg.created_at}
            </div>
            <div className="small font-medium">
                <span className="text-muted font-normal mr-1"><I18n i18n={{ en: "From", fr: "De" }} />:</span>
                {msg.sent_by.email} ({msg.sent_by.name} {msg.sent_by.family_name})
            </div>
            <div className="small font-medium">
                <span className="text-muted font-normal mr-1"><I18n i18n={{ en: "To", fr: "À" }} />:</span>
                {msg.email_to}
            </div>
            <hr />
            <div className="mt-3" dangerouslySetInnerHTML={{ __html: msg.body }} />
        </div>
    );
}

export default PrintableMsg;