import moment from 'moment';
import { Component, createContext } from 'react';
import { LOCALES } from '../i18n/locales';
import { getLocalStorageItem, setLocalStorageItem } from '../helpers/browserStorage';
import { setTag } from '@sentry/react';
import { setGA } from '@spordle/ga4';
import { displayI18n } from '../helpers/i18nHelper';
import { IntlElementsContextProvider } from '@spordle/intl-elements';

/** @type {React.Context<Omit<I18nContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & I18nContextProvider['state']>} */
export const I18nContext = createContext();
I18nContext.displayName = 'I18nContext';

export const getGenericLocale = (specificLang) => {
    switch (specificLang){
        case 'en-ca':
            return 'en';
        default: // This is valid for default language (fr) and language that is not available
            return 'fr';
    }
}

export const builtLocale = (locale) => {
    switch (locale?.split(/(-|_)/)[0]){
        default:
        case 'en':
            return LOCALES.ENGLISH;
        case 'fr':
            return LOCALES.FRENCH;
    }
};
class I18nContextProvider extends Component{
    constructor(props){
        super(props);
        this.state = window.frameElement && window.frameElement?.getAttribute('i18ncontext') ? JSON.parse(window.frameElement?.getAttribute('i18ncontext')) : {
            locale: builtLocale(getLocalStorageItem('lang') || window.navigator.userLanguage?.toLowerCase() || window.navigator.language.toLowerCase() || 'en-ca'),
            lexicon: {},
        }
        moment.locale(this.state.locale);
        setGA({
            language: this.state.locale,
        })
        setTag('language', this.state.locale);
        document.documentElement.lang = getGenericLocale(this.state.locale);
    }

    getFullLanguage = () => {
        switch (this.state.locale){
            case 'en-ca':
                return 'English';
            default: // This is valid for default language (fr-ca) and language that is not available
                return 'Français';
        }
    }

    getGenericLocale = () => getGenericLocale(this.state.locale);


    /**
     * Will be used inside the QuickView Iframe
     * @param {orgId} orgId
     * @returns {Promise}
     */
    setCurrentState = (state) => { this.setState(() => ({ ...state })); this.setLocale(state.locale) }

    /**
     * Sets the lang of the platform
     * @param {string} locale
     */
    setLocale = (locale) => {
        const formattedLocale = builtLocale(locale);
        setLocalStorageItem('lang', formattedLocale)
        this.setState({ locale: formattedLocale });
        moment.locale(formattedLocale);
        setTag('language', formattedLocale);
        setGA({
            language: formattedLocale,
        })
        document.documentElement.lang = getGenericLocale(formattedLocale);
    }

    formatLexiconValues = (lexicon, locale) => {
        return Array.isArray(lexicon) ? lexicon.reduce((formattedLexicon, lex) => {
            formattedLexicon[lex.code] = displayI18n("term", lex.i18n, lex.term, locale)
            return formattedLexicon
        }, {}) : {};
    }

    setLexicon = (lexicon) => {
        this.setState(() => ({ lexicon: lexicon }));
    }

    render(){
        return (
            <I18nContext.Provider value={{
                ...this.state,
                ...this,
            }}
            >
                <IntlElementsContextProvider
                    // currencyFormat={{
                    //     currency: "CAD",
                    //     currencyDisplay: "code",
                    //     currencySign: "accounting",
                    // }}
                    translate={{
                        values: this.formatLexiconValues(this.state.lexicon, getGenericLocale(this.state.locale)),
                        defaultMessageId: "misc.nonExistant",
                    }}
                >
                    {this.props.children}
                </IntlElementsContextProvider>
            </I18nContext.Provider>
        );
    }
}
export default I18nContextProvider;