export default{
    "discrimination.title" : "Maltreatment Complaints",

    "discrimination.label.member"                   : "Individual",
    "discrimination.label.members"                  : "Member(s)",
    "discrimination.label.complaintBy"              : "Complaint submitted by",
    "discrimination.label.complaintType"            : "Complaint Type",
    "discrimination.label.submittedBy"              : "Submitted by",
    "discrimination.label.onBehalf"                 : "On behalf",
    "discrimination.label.onBehalfSubmitter"        : "On behalf of themselves",
    "discrimination.label.sameAsSumittedBy"         : "Same as the person who submitted the complaint",
    "discrimination.label.role"                     : "Role",
    "discrimination.label.validation.role.required" : "Role is required",
    "discrimination.label.validation.team.required" : "Team is required",
    "discrimination.label.team"                     : "Team",
    "discrimination.label.teamName"                 : "Team Name",
    "discrimination.label.complainees"              : "Complaint against",
    "discrimination.label.details"                  : "Details",
    "discrimination.label.summary"                  : "Summary",
    "discrimination.label.people"                   : "People involved",
    "discrimination.label.incident"                 : "Incident",
    "discrimination.label.info"                     : "Info",
    "discrimination.label.overview"                 : "Overview",
    "discrimination.label.arena"                    : "Arena / Location",
    "discrimination.label.status"                   : "Complaint Status",
    "discrimination.label.closedReason"             : "Closed Reason(s)",
    "discrimination.label.outcomes"                 : "Outcomes",
    "discrimination.label.suspensions"              : "Suspensions",
    "discrimination.label.homeAway"                 : "Home / Away",

    "discrimination.form.incident.future"           : "The incident date cannot be set in the future",
    "discrimination.form.incidentTime.required"     : "Please specify the time of the incident",
    "discrimination.form.incident.afterCreation"    : "The incident date cannot be after the date the complaint was created",
    "discrimination.form.discriminatoryGrounds.min" : "Please choose at least one ground",
    "discrimination.form.closedReasons.min"         : "Please choose at least choose one reason",
    "discrimination.form.belongsTo.required"        : "Please specify if this team is visiting or not",
    "discrimination.form.details.required"          : "Details of the incident are required",
    "discrimination.form.complaintType.required"    : "Please specify the complaint type",
    "discrimination.form.arena.required"            : "Please specify where the event took place",
    "discrimination.form.referenceNb.required"      : "Please specify the reference number",
    "discrimination.form.type.required"             : "Please specify where the discrimination",
    "discrimination.form.onIce.required"            : "Please specify where the event took place",
    "discrimination.form.claimStatus.required"      : "Please specify the status complaint",
    "discrimination.form.team.required"             : "Please specify the team",
    "discrimination.form.teamName.required"         : "Please specify the team name",
    "discrimination.form.branch.required"           : "Please specify the branch",
    "discrimination.form.org.required"              : "Please specify the organisation",
    "discrimination.form.existing.required"         : "Please specify whether the team is in our system or not",
    "discrimination.form.phoneOrEmail.required"     : "Please specify the email or the phone number",

    "discrimination.form.team.helper"        : "Please specify on which team the member was playing when the incident happened. Leave empty if this doesn't apply to your maltreatment complaint.",
    "discrimination.form.team.helper.short"  : "Leave empty if this doesn't apply to your maltreatment complaint.",
    "discrimination.form.team.helper.plural" : "Please specify on which team the {count, plural, =0 {member was} one {member was} other {members were}} playing when the incident happened. Leave empty if this doesn't apply to your maltreatment complaint.",

    "discrimination.overview.title"        : "Complaint overview",
    "discrimination.overview.itp"          : "ITP: #{number}",
    "discrimination.overview.members"      : "{count, plural, one {# member} other {# members}}",
    "discrimination.overview.outcomes"     : "{number, plural, =0 {# outcomes} one {# outcome} other {# outcomes}}",
    "discrimination.overview.suspensions"  : "{number, plural, =0 {# suspensions} one {# suspension} other {# suspensions}}",
    "discrimination.overview.finish.title" : "Please confirm",
    "discrimination.overview.finish.text"  : 'Once finished, this complaint will be removed from your "Recently created complaints" at the top of the page.',

    "discrimination.overview.recentComplaints.title"           : "Recent complaints",
    "discrimination.overview.recentComplaints.recentlyCreated" : "Recently created",
    "discrimination.overview.recentComplaints.quickTour"       : "Recently created complaints will appear here for quick access.<br></br><br></br>They will stay here until you click <bold>«Finish»</bold> after clicking on these cards.",

    "discrimination.global.itpReferenceNumber"                    : "ITP Reference Number",
    "discrimination.global.incidentLocation"                      : "Where did Complaint Occur?",
    "discrimination.global.incidentDate"                          : "Incident Date",
    "discrimination.global.incidentTime"                          : "Incident Time",
    "discrimination.global.discriminatoryGrounds"                 : "Maltreatment ground(s)",
    "discrimination.global.discriminatoryGrounds.*"               : "All maltreatment grounds",
    "discrimination.global.discriminatoryGrounds.RACE"            : "Race, national or ethnic origin, skin colour or language spoken",
    "discrimination.global.discriminatoryGrounds.RELIGION"        : "Religion, faith or beliefs",
    "discrimination.global.discriminatoryGrounds.AGE"             : "Age",
    "discrimination.global.discriminatoryGrounds.GENDER_IDENTITY" : "Sex, sexual orientation or gender identity/ expression",
    "discrimination.global.discriminatoryGrounds.MARITAL_STATUS"  : "Marital or familial status",
    "discrimination.global.discriminatoryGrounds.GENETIC"         : "Genetic characteristics",
    "discrimination.global.discriminatoryGrounds.DISABILITY"      : "Disability",
    "discrimination.global.alreadyAdded"                          : "Already added",
    "discrimination.global.suspensions"                           : "Suspensions",
    "discrimination.global.maltreatmentType"                      : "Maltreatment Type",

    "discrimination.global.complaintType.ON_ICE"          : "On Ice",
    "discrimination.global.complaintType.OUTSIDE_OF_PLAY" : "Outside of Play",
    "discrimination.global.complaintType.OTHER"           : "Other",

    "discrimination.global.teamPosition.HOME" : "Home",
    "discrimination.global.teamPosition.AWAY" : "Away",

    "discrimination.global.complaintType.onIce.helper"         : "Event took place on ice during a game",
    "discrimination.global.complaintType.outsideOfPlay.helper" : "Event took place outside of a game",

    "discrimination.global.closedReasons.WRITTEN_WARNING"          : "Written Warning",
    "discrimination.global.closedReasons.EDUCATION"                : "Education",
    "discrimination.global.closedReasons.PROBATION"                : "Probation",
    "discrimination.global.closedReasons.ELIGIBILITY_RESTRICTIONS" : "Eligibility restrictions",
    "discrimination.global.closedReasons.OTHER_SANCTIONS"          : "Other sanctions",
    "discrimination.global.closedReasons.COMPLAINT_DISMISSED"      : "Complaint dismissed",


    "discrimination.global.roles.PLAYER"           : "Player",
    "discrimination.global.roles.PARENT"           : "Parent",
    "discrimination.global.roles.BENCH"            : "Bench Staff / Coach",
    "discrimination.global.roles.BENCH_STAFF"      : "Bench Staff / Coach",
    "discrimination.global.roles.VOLUNTEER"        : "Volunteer",
    "discrimination.global.roles.OFFICIAL"         : "Official",
    "discrimination.global.roles.EMPLOYEE"         : "Employee",
    "discrimination.global.roles.ANONYMOUS"        : "Anonymous",
    "discrimination.global.roles.SPECTATOR"        : "Spectator",
    "discrimination.global.roles.OFF_ICE_OFFICIAL" : "Off Ice Official",
    "discrimination.global.roles.OTHER"            : "Other",

    // ---------- ADD DISCRIMINATION MODAL
    "discrimination.addModal.title"                           : "Add Maltreatment Complaint",
    "discrimination.addModal.matchingProfiles.title"          : "{count, plural, =0 {Matching profile found} one {Matching profile found} other {Matching profiles found}}",
    "discrimination.addModal.matchingProfiles.btn.next"       : "No, continue",
    "discrimination.addModal.matchingProfiles.step2.subtitle" : "We found {count, plural, =0 {a matching profile. Is this the profile of the person} one {a matching profile. Is this the profile of the person} other {matching profiles. Are any of these people the one}} on whose behalf this complaint is being issued?",
    "discrimination.addModal.matchingProfiles.step3.subtitle" : "{count, plural, =0 {Is this person} one {Is this person} other {Are any of these people}} the one against who this maltreatment complaint is being issued?",


    "discrimination.addModal.stepComplaint.title"                  : "Who is making the complaint",
    "discrimination.addModal.stepComplaint.subtitle"               : "Please specify if this complaint is reported by an official.",
    "discrimination.addModal.stepComplaint.btn.official.title"     : "Complaint reported by an official",
    "discrimination.addModal.stepComplaint.btn.official.subtitle"  : "This maltreatment complaint is being reported by an official",
    "discrimination.addModal.stepComplaint.btn.other.title"        : "Complaint submitted by someone else",
    "discrimination.addModal.stepComplaint.btn.other.subtitle"     : "This maltreatment complaint is being submitted by someone other than an official",
    "discrimination.addModal.stepComplaint.btn.anonymous.title"    : "Anonymous complaint",
    "discrimination.addModal.stepComplaint.btn.anonymous.subtitle" : "This maltreatment complaint is being submitted by someone who wants to keep their identity private",


    // step 1
    "discrimination.addModal.stepComplainer.title"                    : "Person making the complaint",
    "discrimination.addModal.stepComplainer.title.official"           : "Name of Official reporting the complaint",
    "discrimination.addModal.stepComplainer.title.official.error"     : "We did not find the member in your organization. If you think this is an error, please contact our support team.",
    "discrimination.addModal.stepComplainer.subtitle"                 : "Please specify who is issuing this maltreatment complaint.",
    "discrimination.addModal.stepComplainer.btn.me.title"             : "Me",
    "discrimination.addModal.stepComplainer.btn.me.subtitle"          : "I'm the one issuing the complaint",
    "discrimination.addModal.stepComplainer.btn.someoneElse.title"    : "Someone Else",
    "discrimination.addModal.stepComplainer.btn.someoneElse.subtitle" : "Someone else is issuing the complaint",

    // step 2
    "discrimination.addModal.stepVictim.title"             : "Is the person issuing this maltreatment complaint doing it on their behalf ?",
    "discrimination.addModal.stepVictim.subtitle"          : "Please specify on whose behalf this maltreatment complaint is being made.",
    "discrimination.addModal.stepVictim.btn.yes.subtitle"  : "The person issuing this maltreatment complaint is doing it on their behalf",
    "discrimination.addModal.stepVictim.btn.no.subtitle"   : "The person issuing this maltreatment complaint is doing it on someone's else behalf",
    "discrimination.addModal.stepVictim.substep2.title"    : "Person who was discriminated",
    "discrimination.addModal.stepVictim.substep2.subtitle" : "Please specify the information of the person that was descriminated",

    // step 3
    "discrimination.addModal.stepGuilty.selected"              : "{count, plural, =0 {<b>#</b> selected complainee} =1 {<b>#</b> selected complainee} other {<b>#</b> selected complainees}}",
    "discrimination.addModal.stepGuilty.title.teamOrMEmber"    : "Complained against",
    "discrimination.addModal.stepGuilty.subtitle.teamOrMEmber" : "Please specify if this complaint is against a member or a team",
    "discrimination.addModal.stepGuilty.title"                 : "Person complained against",
    "discrimination.addModal.stepGuilty.subtitle"              : "Please indicate the person whom the complaint is against.",
    "discrimination.addModal.stepGuilty.review.title"          : "Complaint {count, plural, =0 {recipient} one {recipient} other {recipients}} review",
    "discrimination.addModal.stepGuilty.review.subtitle.1"     : "You are about to enter a complaint against {count, plural, =0 {this person.} one {this person.} other {these people.}} Please click on the “Fill the form” button to proceed or add other people involved if applicable.",
    "discrimination.addModal.stepGuilty.review.subtitle.2"     : "You are about to enter a complaint against this person/these people. Please click on the “Fill the form” button to proceed or add other people involved if applicable.",
    "discrimination.addModal.stepGuilty.review.btn.add"        : "Add a person",
    "discrimination.addModal.stepGuilty.review.btn.list"       : "Complaint Against List",
    "discrimination.addModal.stepGuilty.review.btn.fillForm"   : "Fill the form",

    "discrimination.addModal.stepGuilty.team.title"  : "Team complained against",
    "discrimination.addModal.stepGuilty.team.helper" : "Please select which team this maltreatment complaint is against",

    "discrimination.addModal.stepConfirm.text"         : "You are about to create the maltreatment complaint.",
    "discrimination.addModal.stepConfirm.success.text" : "You have successfully created maltreatment complaint.",
    "discrimination.addModal.stepConfirm.addOutcome"   : "Add Outcomes",
    "discrimination.addModal.stepConfirm.addOutcomes"  : "Add Outcomes to All",
    "discrimination.addModal.stepConfirm.changeTeam"   : "Change Team",

    // step 4
    "discrimination.addModal.stepIncident.details.helper" : "Please provide a detailed and accurate summary outlining the particulars of the incident to support your compliant.",

    // ---------- END ADD DISCRIMINATION MODAL

    "discrimination.spordleTable.discriminatoryGrounds.more" : "{count, plural, =0 {+# more maltreatment ground} one {+# more maltreatment ground} other {+# more maltreatment grounds}}",

    "discrimination.sidePanel.case"                                     : "Case",
    "discrimination.sidePanel.people.addModal.title"                    : "Add Complaint Against",
    "discrimination.sidePanel.people.editModal.title"                   : "Edit Complaint Against",
    "discrimination.sidePanel.people.editModal.linkedMember"            : "Linked Member",
    "discrimination.sidePanel.people.editModal.linkedMember.btn.remove" : "Unlink",
    "discrimination.sidePanel.people.complainees"                       : "{count, plural, =0 {Person complained against} one {Person complained against} other {People complained against}}",
    "discrimination.sidePanel.people.complaineeTeam"                    : "Team complained against",
    "discrimination.sidePanel.people.onBehalf.allFilled.firstName"      : "Please specify first name or empty all fields",
    "discrimination.sidePanel.people.onBehalf.allFilled.lastName"       : "Please specify last name or empty all fields",
    "discrimination.sidePanel.people.onBehalf.allFilled.birthdate"      : "Please specify birthdate or empty all fields",
    "discrimination.sidePanel.list.groundsSelected"                     : "Maltreatment ground(s) selected",

    "discrimination.sidePanel.closeComplaint" : "Close Complaint",

    "discrimination.sidePanel.editStatusModal.title"                : "Edit Status",
    "discrimination.sidePanel.editStatusModal.btn"                  : "Update Status",
    "discrimination.sidePanel.editStatusModal.final"                : "This is a final status. You will not be able to modify or delete the claim once this status is applied",
    "discrimination.sidePanel.editStatusModal.success"              : "The status of the maltreatment complaint was successfully updated.",
    "discrimination.sidePanel.editStatusModal.allComplete.title"    : "All outcomes are completed",
    "discrimination.sidePanel.editStatusModal.allComplete.text"     : "All of the maltreatment outcomes have been completed.",
    "discrimination.sidePanel.editStatusModal.allComplete.update"   : "Update Status",
    "discrimination.sidePanel.editStatusModal.allComplete.question" : "Set complaint as {status}",

    "discrimination.sidePanel.deleteClaim.btn"     : "Delete Complaint",
    "discrimination.sidePanel.deleteClaim.title"   : "Delete this maltreatment complaint",
    "discrimination.sidePanel.deleteClaim.success" : "Maltreatment complaint has been deleted",

    "discrimination.addModal.form.select.groundsSelected"       : "{count, plural, =0 {# maltreatment ground selected} one {# maltreatment ground selected} other {# maltreatment grounds selected}}",
    "discrimination.addModal.form.select.closedReasonsSelected" : "{count, plural, =0 {# closed reason selected} one {# closed reason selected} other {# closed reasons selected}}",

    // Discrimination Outcomes
    "discrimination.outcomes.addSuspension"       : "Click here to add a suspension",
    "discrimination.outcomes.addSuspension.toast" : "Add a suspension for {name}",
    "discrimination.outcomes.addOutcomes"         : "Add outcomes",
    "discrimination.outcomes.outcome.empty"       : "No outcomes",
    "discrimination.outcomes.outcome"             : "Outcomes",
    "discrimination.outcomes.causesSuspension"    : "Causes a suspension",
    "discrimination.outcomes.addOutcome"          : "Add Outcome",
    "discrimination.outcomes.dueDate"             : "Due Date",
    "discrimination.outcomes.createdBy"           : "Created By",
    "discrimination.outcomes.updatedBy"           : "Updated By",
    "discrimination.outcomes.due.date.validation" : "Due date must be after incident date.",


    "discrimination.outcomes.status.PENDING"   : "Pending",
    "discrimination.outcomes.status.COMPLETED" : "Completed",
    "discrimination.outcomes.selected"         : "Already added",

    "discrimination.spordleTable.outcomes.more" : "{count, plural, =0 {+# more outcome} one {+# more outcome} other {+# more outcomes}}",

    "discrimination.outcomes.outcome.required" : "Outcome is required",
    "discrimination.outcomes.comment.required" : "Selected outcome requires a comment",
    "discrimination.outcomes.dueDate.format"   : "Invalid date format",

    // Discrimination Suspensions
    "discrimination.suspensions.addSuspension"         : "Add Suspension",
    "discrimination.suspensions.createSuspension"      : "Create a suspension",
    "discrimination.suspensions.createSuspension.desc" : "Create a new suspension for the member. This suspension will also be link to this maltreatment complaint.",
    "discrimination.suspensions.linkSuspension"        : "Link a suspension",
    "discrimination.suspensions.linkSuspension.desc"   : "Link an existing suspension the member has to this maltreatment complaint",
}