export default{
    // status
    "memberProfile.registration.status.confirmed"      : "Confirmed",
    "memberProfile.registration.status.paid"           : "Paid",
    "memberProfile.registration.status.partially_paid" : "Partially Paid",
    "memberProfile.registration.status.pending"        : "Pending",
    "memberProfile.registration.status.cancelled"      : "Cancelled",

    // transaction
    "memberProfile.registration.transaction"                     : "Transaction",
    "memberProfile.registration.transaction.order"               : "Order",
    "memberProfile.registration.transaction.date"                : "Transaction Date",
    "memberProfile.registration.transaction.registrationFee"     : "Registration Fee",
    "memberProfile.registration.transaction.otherItemFee"        : "Other Item Fee",
    "memberProfile.registration.transaction.annualMembershipFee" : "Annual Membership Fee",
    "memberProfile.registration.transaction.creditAmount"        : "Applied credit",
    "memberProfile.registration.transaction.platformFees"        : "Platform fees",
    "memberProfile.registration.transaction.tax"                 : "Taxes",
    "memberProfile.registration.transaction.totalPrice"          : "Total Price",
    "memberProfile.registration.transaction.balance"             : "Balance",

    // Exchange
    "memberProfile.registration.exchange.warning.text"              : "Make sure you follow the process to completion to avoid unwanted errors. If the process isn't finished, changes will be reverted in the following 24-48 hours.",
    "memberProfile.registration.exchange.items.registrations.label" : "{items, plural, one {Registration item} other {Registration items}}",
    "memberProfile.registration.exchange.items.others.label"        : "{items, plural, one {Other item} other {Other items}}",
    "memberProfile.registration.exchange.items.required"            : "Please select an item to exchange",
    "memberProfile.registration.exchange.items.alreadyCancelled"    : "Already exchanged",

    // Manual registration (Emmanuel)
    "memberProfile.registration.manual.select.title"           : "Registration Identity",
    "memberProfile.registration.manual.select.subtitle"        : "Specify who is in charge of the registration for {member}.",
    "memberProfile.registration.manual.select.manual"          : "Manual",
    "memberProfile.registration.manual.select.manual.desc"     : "Enter the first and last name of the person that is not in the system.",
    "memberProfile.registration.manual.select.identity"        : "Select an identity",
    "memberProfile.registration.manual.select.identity.desc"   : "Create a new identity or search an existing one in the system.",
    "memberProfile.registration.manual.confirmation.title"     : "You are almost done!",
    "memberProfile.registration.manual.confirmation.text"      : "Click {confirm} to complete the registration.",
    "memberProfile.registration.manual.confirmation.congrats"  : "Congratulations!",
    "memberProfile.registration.manual.confirmation.completed" : "The manual registration is completed.",

    // Questionnaires
    "memberProfile.registration.questionnaires.form.label.between" : "between",
    "memberProfile.registration.questionnaires.form.label.max"     : "Max",
    "memberProfile.registration.questionnaires.form.label.min"     : "Min",
    "memberProfile.registration.questionnaires.form.label.and"     : "and",
    "memberProfile.registration.questionnaires.form.required"      : "This field is mandatory",
    "memberProfile.registration.questionnaires.noForm.title"       : "No questionnaire",
    "memberProfile.registration.questionnaires.noForm"             : "There is no questionnaire associated with this registration.",

    // waivers
    "memberProfile.registration.sendWaivers.title"               : "Send waivers",
    "memberProfile.registration.sendWaivers.selectWaiver"        : "Select Waiver",
    "memberProfile.registration.sendWaivers.selectContact"       : "Select Contact",
    "memberProfile.registration.sendWaivers.waiversWillBeSendTo" : "waiver(s) will be send to :",
    "memberProfile.registration.sendWaivers.submit"              : "Registrer",

    "memberProfile.registration.confirmWaivers.title" : "Accept waivers",
    "memberProfile.registration.confirmWaivers.hint"  : "* The selected waivers will be marked as \"recieved by me\"",

    // Installments
    "memberProfile.registration.installments.label"        : "Payment Schedule",
    "memberProfile.registration.installments.payFull"      : "Full payment",
    "memberProfile.registration.installments.installments" : "{number} installments",
    "memberProfile.registration.installments.payNow"       : "Pay now",
    "memberProfile.registration.installments.total"        : "Total",
    "memberProfile.registration.installments.required"     : "Select an installment modality",

    // Merchant Accounts
    "memberProfile.registration.merchantAccounts.label"                     : "Merchant Accounts",
    "memberProfile.registration.merchantAccounts.label.none"                : "There are no merchant accounts for your organization.",
    "memberProfile.registration.merchantAccounts.required"                  : "Please select a merchant account",
    "memberProfile.registration.merchantAccounts.provider.PAYSAFE"          : "Paysafe",
    "memberProfile.registration.merchantAccounts.provider.QUICK_ENROLLMENT" : "SportsPay",

    // Member Identities
    "memberProfile.registration.memberIdentities.label"           : "Available accounts for the member",
    "memberProfile.registration.memberIdentities.label.noAccount" : "There are no available accounts for this member",
    "memberProfile.registration.memberIdentities.required"        : "Please select an account or create a new one for the selected member",
    "memberProfile.registration.memberIdentities.add"             : "Create a new account",
    "memberProfile.registration.memberIdentities.identityExists"  : "This accounts already exists, do you want to link it to this member {from, select, instructor {} other {and continue with the registration}}?",
    "memberProfile.registration.memberIdentities.link"            : "Link",
    "memberProfile.registration.memberIdentities.skipLink"        : "Skip link",

    // add modal
    "memberProfile.registration.form.status"                          : "Status",
    "memberProfile.registration.form.status.ONLINE"                   : "Online",
    "memberProfile.registration.form.status.ADMIN"                    : "Manual",
    "memberProfile.registration.form.status.THIRD_PARTY"              : "Third Party",
    "memberProfile.registration.form.status.ONLINE.registration"      : "Online registration",
    "memberProfile.registration.form.status.ADMIN.registration"       : "Manual registration",
    "memberProfile.registration.form.status.THIRD_PARTY.registration" : "Third Party registration",

    "memberProfile.registration.form.confirm"                  : "Confirm",
    "memberProfile.registration.form.waivers"                  : "Waivers",
    "memberProfile.registration.form.member"                   : "Member",
    "memberProfile.registration.form.category"                 : "Category",
    "memberProfile.registration.form.registration"             : "Registration",
    "memberProfile.registration.form.registration.partner"     : "Registration from :",
    "memberProfile.registration.form.registration.showAll"     : "Show all packages",
    "memberProfile.registration.form.otherItem"                : "Other Item",
    "memberProfile.registration.form.position"                 : "Member Position",
    "memberProfile.registration.form.groupPosition"            : "Group Position",
    "memberProfile.registration.form.division"                 : "Division",
    "memberProfile.registration.form.division.none"            : "There are no waivers to sign for this division",
    "memberProfile.registration.form.division.required"        : "Division is required",
    "memberProfile.registration.form.memberType"               : "Member Type",
    "memberProfile.registration.form.registrationDate"         : "Registration Date",
    "memberProfile.registration.form.season"                   : "Season",
    "memberProfile.registration.form.registration.none"        : "No registration",
    "memberProfile.registration.form.registration.affiliation" : "Manual registration with membership fees only",
    "memberProfile.registration.form.registration.manual"      : "Manual registration with waivers only",
    "memberProfile.registration.form.registration.manual.info" : "This option will create a registration only containing the waivers for the selected division. This will not create any online transactions. Additionally, the linked account (parent or supervisor) will be able to see and sign the waivers in their {spordle_account}.",
    "memberProfile.registration.form.registration.date"        : "Registration date",
    "memberProfile.registration.form.registration.time"        : "Registration time",
    "memberProfile.registration.form.wrongOrganization"        : "Oops! You must be managing {organization} to register this member.",
    "memberProfile.registration.form.changeOrganization"       : "Click here to change organization.",
    "memberProfile.registration.form.possibleRegistrations"    : "{count} possible registrations",
    "memberProfile.registration.form.showAllPackages"          : "Show all packages",
    "memberProfile.registration.form.helper"                   : "Affiliation fees will be added to the price unless the regstration excludes them.",

    "memberProfile.registration.form.signedBy"        : "Signed by {name}",
    "memberProfile.registration.form.accepted"        : "Accepted by {name}",
    "memberProfile.registration.form.declined"        : "Declined by {name}",
    "memberProfile.registration.form.waiver.agree"    : "I agree with this waiver.",
    "memberProfile.registration.form.waiver.disagree" : "I disagree with this waiver.",
    "memberProfile.registration.form.waiver.required" : "You answer this waiver before saving.",
    "memberProfile.registration.form.waiver.signed"   : "Waiver signed!",

    "memberProfile.registration.add.customForms.title"              : "Questionnaire",
    "memberProfile.registration.add.customForms.required"           : "This field is required",
    "memberProfile.registration.add.customForms.min"                : "Please respect the minimum value",
    "memberProfile.registration.add.customForms.max"                : "Please respect the maximum value",
    "memberProfile.registration.add.customForms.label.between"      : "Between",
    "memberProfile.registration.add.customForms.label.and"          : "and",
    "memberProfile.registration.add.customForms.label.min"          : "Minimum",
    "memberProfile.registration.add.customForms.label.max"          : "Maximum",
    "memberProfile.registration.add.customForms.notMandatoryHelper" : "This questionnaire is not mandatory",

    "memberProfile.registration.waivers.title"              : "Waivers",
    "memberProfile.registration.waivers.of"                 : "of",
    "memberProfile.registration.waivers.accept"             : "I have read and accept the waiver",
    "memberProfile.registration.waivers.required"           : "This waiver is mandatory",
    "memberProfile.registration.waivers.notMandatoryHelper" : "This waiver is not mandatory",

    "memberProfile.registration.add.title"                     : "Member Registration",
    "memberProfile.registration.add.confirmation.title"        : "Congratulations!",
    "memberProfile.registration.add.confirmation.confirmedFor" : "Registration confirmed for",
    "memberProfile.registration.add.confirmation.confirmed"    : "Registration Number",
    "memberProfile.registration.add.confirmation.seeInvoice"   : "See invoice",

    "memberProfile.registration.add.validation.date.isBefore"           : "The date must be before today",
    "memberProfile.registration.add.validation.time.isBefore"           : "The time must be before now",
    "memberProfile.registration.add.validation.registrationOrOther"     : "Please add a category or an other item",
    "memberProfile.registration.add.validation.otherItem"               : "Please select an other item",
    "memberProfile.registration.add.validation.season.required"         : "Please choose a season",
    "memberProfile.registration.add.validation.memberType.required"     : "Please choose a member type",
    "memberProfile.registration.add.validation.category.required"       : "Please choose a category",
    "memberProfile.registration.add.validation.groupPostition.required" : "Please choose a group position",

    "memberProfile.registration.add.errors.3030" : "There are no places remaining for this registration.",
    "memberProfile.registration.add.errors.3266" : "The member’s postal code is not configured in the organization’s postal codes.",

    "memberProfile.registration.add.exchangeErrors.3406" : "An exchange cannot be done if refunds were applied.",
    "memberProfile.registration.add.exchangeErrors.3407" : "An exchange cannot be done if the transaction has pending installments.",

    "memberProfile.registration.add.outstandingBalance" : "This member has an outstanding balance",

    // table
    "memberProfile.registration.table.emptyLayout.noResult" : "There are no registrations for this member in the current period.",

    // errors
    "memberProfile.registration.error.registrationAgeError" : "This registration does not apply to this member (wrong age)",
    "memberProfile.registration.error.affiliationFees"      : "Affiliation fees",
    "memberProfile.registration.error.platformFees"         : "Fees",
    "memberProfile.registration.error.alreadyRegistered"    : "Already registered",
    "memberProfile.registration.error.soldOut"              : "Sold out",
    "memberProfile.registration.error.waitingList"          : "Waiting list",

    // cancel registration
    "memberProfile.registration.cancelRegistration"                     : "Cancel Item(s)",
    "memberProfile.registration.cancelRegistration.registrations.label" : "{items, plural, one {Registration item} other {Registration items}}",
    "memberProfile.registration.cancelRegistration.others.label"        : "{items, plural, one {Other item} other {Other items}}",
    "memberProfile.registration.cancelRegistration.items.cancelled"     : "Already cancelled",
    "memberProfile.registration.cancelRegistration.selection.required"  : "Please select at least one item",
    "memberProfile.registration.cancelRegistration.registration"        : "Registration",
    "memberProfile.registration.cancelRegistration.items"               : "{items, plural, one {Item} other {Items}}",
    "memberProfile.registration.cancelRegistration.reason"              : "Reason",
    "memberProfile.registration.cancelRegistration.reason.required"     : "Please provide a reason.",
    "memberProfile.registration.cancelRegistration.submit"              : "Proceed",
    "memberProfile.registration.cancelRegistration.refund"              : "Refund",
    "memberProfile.registration.cancelRegistration.success"             : "Item(s) cancelled",
    "memberProfile.registration.cancelRegistration.error"               : "Could not cancel item(s)",
    "memberProfile.registration.cancelRegistration.refund.message"      : "{items, plural, one {This item was successfully cancelled} other {These items were successfully cancelled}}. No money was refunded to the customer.",
    "memberProfile.registration.cancelRegistration.refund.question"     : "Do you wish to refund now?",

    // confirm registration
    "memberProfile.registration.confirmRegistration.confirm"                        : "Are you sure you want to confirm {registrationName}?",
    "memberProfile.registration.confirmRegistration.success"                        : "Registration confirmed!",
    "memberProfile.registration.confirmRegistration.error"                          : "Could not confirm registration",
    "memberProfile.registration.confirmRegistration.confirm.btn"                    : "Confirm",
    "memberProfile.registration.confirmRegistration.multi.title"                    : "Confirm registrations",
    "memberProfile.registration.confirmRegistration.multi.description"              : "You are about to confirm the registration of all the members below",
    "memberProfile.registration.confirmRegistration.multi.valid.title"              : "Registrations ({registrations})",
    "memberProfile.registration.confirmRegistration.multi.valid.subtitle"           : "These registrations will be confirmed",
    "memberProfile.registration.confirmRegistration.multi.valid.none"               : "No registrations can be confirmed",
    "memberProfile.registration.confirmRegistration.multi.invalid.title"            : "Invalid registrations ({registrations})",
    "memberProfile.registration.confirmRegistration.multi.invalid.subtitle"         : "These registrations cannot be confirmed",
    "memberProfile.registration.confirmRegistration.multi.invalid.alreadyConfirmed" : "This registration is already confirmed",

    // side panel
    "memberProfile.registration.sidepanel.confirmPayment"              : "Confirm payment",
    "memberProfile.registration.sidepanel.confirmPayment.confirm"      : "Are you sure you want to confirm the payment?",
    "memberProfile.registration.sidepanel.confirmPayment.success"      : "Payment confirmed!",
    "memberProfile.registration.sidepanel.confirmPayment.error"        : "Could not confirm payment.",
    "memberProfile.registration.sidepanel.confirmRegistration"         : "Confirm Registration",
    "memberProfile.registration.sidepanel.confirmRegistrations"        : "Confirm Registrations",
    "memberProfile.registration.sidepanel.confirmRegistration.already" : "Already confirmed",
    "memberProfile.registration.sidepanel.confirmRegistration.by"      : "Confirmed By",
    "memberProfile.registration.sidepanel.cancelRegistration"          : "Cancel Registration",
    "memberProfile.registration.sidepanel.cancelRegistration.already"  : "Already cancelled",
    "memberProfile.registration.sidepanel.cancelRegistration.by"       : "Cancelled By",
    "memberProfile.registration.sidepanel.exchangeItems"               : "Exchange items",
    "memberProfile.registration.sidepanel.transfer"                    : "Transfer",
    "memberProfile.registration.sidepanel.item.cancelled"              : "Cancelled",
    "memberProfile.registration.sidepanel.sendWaivers"                 : "Send waivers by Email",
    "memberProfile.registration.sidepanel.primaryOrg"                  : "Primary Organization",
    "memberProfile.registration.sidepanel.registration"                : "Registration",
    "memberProfile.registration.sidepanel.confirmedBy"                 : "Registered by",
    "memberProfile.registration.sidepanel.confirmWaivers"              : "Confirm waivers",
    "memberProfile.registration.sidepanel.form"                        : "Questionnaire",
    "memberProfile.registration.sidepanel.selectedAmount"              : "registrations selected",
}