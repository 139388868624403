/* eslint-disable react/no-unused-class-component-methods */
import React from "react"
import { Droppable } from "react-beautiful-dnd"
import { droppableMap } from './dragNDropMaps';

class SpordleDroppable extends React.Component{
    componentDidMount(){
        droppableMap[this.props.droppableId] = this
    }

    componentWillUnmount(){
        delete droppableMap[this.props.droppableId]
    }

    callHandler = (handler, result) => {
        this.props[handler] && this.props[handler](result)
    }

    getPayload = () => this.props.payload

    render(){
        return <Droppable {...this.props} />
    }
}

export default SpordleDroppable;