import React from "react";

// Context
import { AuthContext } from "../../../../../contexts/contexts";
import { I18nContext } from '../../../../../contexts/I18nContext';
import withContext from '../../../../../helpers/withContexts';

// Language
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { Alert, Col, Fade, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from "reactstrap";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import RequestCall from "@spordle/request-calls";
import { MembersContext } from "../../../../../contexts/MembersContext";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import SidePanelGeneral from "./SidePanelGeneral";
import { Link } from "react-router-dom";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { fail } from '@spordle/toasts';
import { DisplayPhoneNumber, TelHref } from "@spordle/formik-elements";

class SidePanelOrganizationStaff extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            activeTab: '1',
            isLoading: true,
        }
    }

    componentDidMount(){
        this.setState({ isLoading: false });
    }

    componentDidUpdate(prev){
        if(prev.selectedRows[0].organisation_contact_id !== this.props.selectedRows[0].organisation_contact_id && this.state.activeTab !== '1'){
            this.toggleTab('1');
        }
    }

    toggleTab = (tab) => this.setState({ activeTab: tab });

    toggleLoading = (bool) => this.setState((prevState) => ({ isLoading: bool ?? !prevState.isLoading }))

    deleteStaffMember = () => {
        return this.props.OrganizationContext.deleteOrganizationContact(
            this.props.OrganizationContext.organisation_id,
            this.props.selectedRows[0].organisation_contact_id,
        )
            .then(() => {
                this.props.tableRef.deleteRow(this.props.selectedRows[0].organisation_contact_id).then(this.props.toggle)
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <div className="p-3">
                    <div className='d-flex mb-2 align-items-center'>
                        <SidePanel.ToggleButton onClick={() => this.toggleTab('1')} />
                        <SidePanel.ActionsMenu action='DELETE' componentCode='organization' componentPermissionCode='organization_staff'>
                            <SidePanel.MenuDelete
                                translateModalMsg
                                modalMsg='organization.profile.staff.sidePanel.delete'
                                modalTitle={this.props.selectedRows[0].first_name + ' ' + this.props.selectedRows[0].last_name}
                                onConfirm={this.deleteStaffMember}
                            />
                        </SidePanel.ActionsMenu>
                    </div>
                    <SidePanel.Title>{this.props.selectedRows[0].first_name} {this.props.selectedRows[0].last_name}</SidePanel.Title>
                </div>
                <Nav tabs>
                    <NavItem className="flex-grow-1 text-center w-100">
                        <NavLink
                            active={this.state.activeTab === '1'}
                            onClick={() => { this.toggleTab('1'); }}
                        >
                            <Translate id='form.fields.generalInfo' />
                        </NavLink>
                    </NavItem>
                    { this.props.selectedRows[0].member &&
                        <NavItem className="flex-grow-1 text-center w-100">
                            <NavLink
                                active={this.state.activeTab === '2'}
                                onClick={() => { this.toggleTab('2'); }}
                            >
                                <Translate id='organization.profile.staff.sidePanel.tab.member' />
                            </NavLink>
                        </NavItem>
                    }
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    {/* Default Tab -> Staff overview */}
                    <TabPane tabId="1">
                        <SidePanelGeneral toggleLoading={this.toggleLoading} {...this.props} />
                    </TabPane>

                    {/* Member tab -> Available if linked to member */}
                    { this.props.selectedRows[0].member &&
                        <TabPane tabId="2">
                            <RequestCall
                                key={this.props.selectedRows[0].organisation_contact_id}
                                call={() => this.props.MembersContext.getMember(this.props.selectedRows[0].member.member_id)}
                            >
                                {(snapshot) => {
                                    if(snapshot.isLoading){
                                        return (
                                            <Fade className="text-center mt-3">
                                                <Spinner color="primary" type="grow" />
                                                <div className="h4"><Translate id='misc.loading' /></div>
                                            </Fade>
                                        );
                                    }
                                    if(snapshot.waitingForResponse){
                                        return null;
                                    }
                                    if(snapshot.error || !snapshot.data?.contacts){
                                        return (
                                            <Fade className="text-center p-3">
                                                <Alert color="danger" className="mb-0">
                                                    <Translate id='misc.error' />
                                                </Alert>
                                            </Fade>
                                        );
                                    }

                                    // Getting the first emergency contact in the contact list
                                    const emergencyContact = snapshot.data.contacts.find((contact) => contact.emergency == 1);
                                    return (
                                        <Fade>
                                            <div className="p-3 border-bottom">
                                                <div className="h4 font-bold mb-0"><Translate id='members.search.sidePanelMemberSearch.section.profileInfo' /></div>
                                                <div className="small text-muted">#{snapshot.data.unique_identifier}</div>
                                                <Link className="d-block small mb-3" to={{ pathname: `/members/profile/${snapshot.data.member_id}`, data: snapshot.data }}><Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" /></Link>
                                                <Row>
                                                    <Col className="mb-3">
                                                        <div className='text-muted'><Translate id='members.search.sidePanelMemberSearch.profileInfo.gender' /></div>
                                                        <div><Translate id={`members.search.sidePanelMemberSearch.profileInfo.gender.${snapshot.data.gender}`} /></div>
                                                    </Col>
                                                    <Col className="mb-3">
                                                        <div className='text-muted'><Translate id='members.search.sidePanelMemberSearch.profileInfo.birthDate' /></div>
                                                        {snapshot.data.birthdate ?
                                                            <div>
                                                                <Translate id='misc.yearsOld' values={{ age: snapshot.data.age }} /> (<DateFormat value={snapshot.data.birthdate} utc />)
                                                            </div>
                                                            : '-'}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="mb-3">
                                                        <div className='text-muted'><Translate id='form.fields.primaryLanguage' /></div>
                                                        <div>
                                                            {snapshot.data.primary_language ?
                                                                <Translate id={`members.search.sidePanelMemberSearch.profileInfo.language.${snapshot.data.primary_language.toUpperCase()}`} />
                                                                :
                                                                <Translate id='misc.none' />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-3">
                                                        <div className='text-muted'><Translate id='form.fields.secondaryLanguages' /></div>
                                                        {snapshot.data.secondary_languages?.length === 0 ?
                                                            <Translate id='misc.none' />
                                                            :
                                                            <div>{snapshot.data.secondary_languages?.map((language, index) => <React.Fragment key={language}><Translate id={`members.search.sidePanelMemberSearch.profileInfo.language.${language.toUpperCase()}`} />{snapshot.data.secondary_languages.length - 1 !== index && ', '}</React.Fragment>)}</div>
                                                        }
                                                    </Col>
                                                </Row>
                                                {/* <Row form>
                                                    <Col sm="6">
                                                        <div className="mb-3">
                                                            <div className="text-muted">NCCP</div>
                                                            <div className="font-medium">#435345345</div>
                                                        </div>
                                                    </Col>
                                                    <Col sm="6">
                                                        <div className="mb-3">
                                                            <div className="text-muted">Respect in Sports</div>
                                                            <div className="font-medium">#435345345</div>
                                                        </div>
                                                    </Col>
                                                </Row> */}
                                            </div>
                                            <div className="p-3">
                                                <div className="h4 font-bold mb-3"><Translate id='members.search.sidePanelMemberSearch.section.contact' /></div>
                                                <div className="mb-3">
                                                    <div className="h6 font-medium mb-2"><Translate id='members.search.sidePanelMemberSearch.contact.primary' /></div>
                                                    {snapshot.data.contacts[0] ?
                                                        <>
                                                            <div>{snapshot.data.contacts[0].first_name} {snapshot.data.contacts[0].last_name}</div>
                                                            {snapshot.data.contacts[0].phones[0] &&
                                                                <div>
                                                                    <TelHref phoneString={snapshot.data.contacts[0].phones[0].phone_number}>
                                                                        <DisplayPhoneNumber phoneString={snapshot.data.contacts[0].phones[0].phone_number} format='INTERNATIONAL' />
                                                                    </TelHref>
                                                                </div>
                                                            }
                                                            <div className="text-truncate">
                                                                <a title={snapshot.data.contacts[0].email} href={`mailto:${snapshot.data.contacts[0].email}`} target='_blank' rel="noreferrer">{snapshot.data.contacts[0].email}</a>
                                                            </div>
                                                        </>
                                                        :
                                                        <Translate id='misc.undefined' />
                                                    }
                                                </div>
                                                <div className="h6 font-medium mb-2 text-danger"><Translate id='members.search.sidePanelMemberSearch.contact.emergency' /></div>
                                                {emergencyContact ?
                                                    <div>
                                                        <div>{emergencyContact.first_name} {emergencyContact.last_name}</div>
                                                        {emergencyContact.phones[0] &&
                                                            <div>
                                                                <TelHref phoneString={emergencyContact.phones[0].phone_number}>
                                                                    <DisplayPhoneNumber phoneString={emergencyContact.phones[0].phone_number} format='INTERNATIONAL' />
                                                                </TelHref>
                                                            </div>
                                                        }
                                                        <div className="text-truncate">
                                                            <a title={emergencyContact.email} href={`mailto:${emergencyContact.email}`} target='_blank' rel="noreferrer">{emergencyContact.email}</a>
                                                        </div>
                                                    </div>
                                                    :
                                                    <Translate id='misc.undefined' />
                                                }
                                            </div>
                                        </Fade>
                                    )
                                }}
                            </RequestCall>
                        </TabPane>
                    }
                </TabContent>
            </OverlayLoader>
        )
    }
}

export default withContext(I18nContext, AuthContext, MembersContext, OrganizationContext)(SidePanelOrganizationStaff);