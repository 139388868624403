import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import SpordlePanelTable from '../../../../../components/sidePanel/SpordlePanel';
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import TeamInactiveRosterSidepanel from './TeamInactiveRosterSidepanel';
import { getTeamMemberStatus } from '../../../TeamHelpers';
import { useIntl } from 'react-intl';
import EmptyLayout from '../../../../../components/table/EmptyLayout';
import HasAccessTo from '../../../../../components/permissions/HasAccessTo';
import { displayI18n, DisplayI18n } from '../../../../../helpers/i18nHelper';
import { I18nContext } from '../../../../../contexts/I18nContext';
import MemberTeamStatus from '../../../../members/profile/teams/MemberTeamStatus';
import TeamMemberAge from '../components/TeamMemberAge';
import MemberStatusIcons from '../../../../members/search/components/MemberStatusIcons';
import { useDisclosure } from '@mantine/hooks';
import { stringBuilder } from '@spordle/helpers';
import CrossFade from '../../../../../components/crossFade/CrossFade';
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import CanDoAction from '../../../../../components/permissions/CanDoAction';
import SendCommModal from '../../../../../components/communications/SendCommModal';
import TeamRosterTableHeader from '../components/TeamRosterTableHeader';
import GenderLabel from '../../../../../components/GenderLabel';


const TeamInactiveRoster = ({ teamId, playerLimit, uniqueJerseySetting, getLockedRoster }) => {

    const intl = useIntl();
    const spordleTableRef = useRef(null);
    const teamsContext = useContext(TeamsContext)

    const [ isOpen, isOpenHandlers ] = useDisclosure(false)
    const i18nContext = useContext(I18nContext);
    const [ sendCommIsOpen, sendCommIsOpenHandlers ] = useDisclosure(false);

    const getJerseyNumber = (numberHome, numberAway) => {
        // if away is null, it means we have only 1 jersey number for both away and home
        if(numberHome){
            return (
                <div className='text-center'>
                    <div>
                        <span className=""><Translate id='teams.profile.roster.table.jerseyNumber.home' /> : </span>
                        <span className="font-medium text-dark">{numberHome}</span>
                    </div>
                    <div>
                        <span className=""><Translate id='teams.profile.roster.table.jerseyNumber.away' /> : </span>
                        <span className="font-medium text-dark">{numberAway || numberHome}</span>
                    </div>
                </div>
            )
        }
        return '';

    }

    useEffect(() => {
        if(teamsContext.rosterTablesLoading){
            spordleTableRef.current?.setLoading();
        }
    }, [ teamsContext.rosterTablesLoading ])

    useEffect(() => {
        if(teamsContext.rosterTablesDataError){
            spordleTableRef.current?.setError();
        }
    }, [ teamsContext.rosterTablesDataError ])

    useEffect(() => {
        if(teamsContext.rosterTablesData){
            spordleTableRef.current?.setData(teamsContext.formatDataInactive(teamsContext.rosterTablesData, intl), 'success')
        }
    }, [ teamsContext.rosterTablesData ])

    const displayCount = (spordleTable) => {
        return (
            <span className='ml-2'>
                (
                {spordleTable.getData().length}
                {(!!teamsContext.filters && Object.keys(teamsContext.filters)?.length !== 0) &&
                    ' / ' + teamsContext.formatDataInactive(teamsContext.completeRoster, intl).length
                }
                )
            </span>
        )
    }

    return (
        <Card className={stringBuilder("card-shadow", { "clickable": !isOpen })} onClick={() => !isOpen ? isOpenHandlers.toggle() : null}>
            {/* loading for when the card is collapsed */}
            <OverlayLoader isLoading={(teamsContext.rosterTablesLoading && !isOpen)}>
                <CardBody>
                    <SpordlePanelTable
                        allowOutsideClick
                        sidePanel={(props) => <TeamInactiveRosterSidepanel getLockedRoster={getLockedRoster} uniqueJerseySetting={uniqueJerseySetting} {...props} />}
                        dataIndex='team_member_id'
                        table={(panelProps) => {
                            return (
                                <SpordleTableProvider
                                    tableHover bordered striped
                                    clickable
                                    id='teamInactiveRoster'
                                    ref={(r) => { spordleTableRef.current = r; panelProps.spordleTableRef(r) }}
                                    desktopWhen
                                    dataIndex='team_member_id'
                                    emptyLayout={<EmptyLayout />}
                                    searchKeys={[
                                        { name: 'member.unique_identifier', weight: 2 },
                                        'name',
                                        'member.last_name',
                                        'member.first_name',
                                        'option',
                                        `position.i18n.${i18nContext.getGenericLocale()}.name`,
                                        'position.name',
                                        `team_member_status.i18n.${i18nContext.getGenericLocale()}.name`,
                                        'team_member_status.name',
                                        'registration_date',
                                        'jersey_number',
                                        'jersey_number_alternate',
                                    ]}
                                    // TODO: Add total players
                                    // paginationMessage='members.search.memberSearch.table.pagination.message'
                                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                    loadData={(from, data, spordleTable) => {
                                        switch (from){
                                            case 'REFRESH':
                                                teamsContext.getRosterTablesData(teamId)
                                            default:
                                                break;
                                        }
                                    }}
                                    columnSorting={(teamMemberA, teamMemberB, _spordleTable, order) => {
                                        switch (order.column){
                                            case 'position':
                                                const valueAPosition = displayI18n('name', teamMemberA.position.i18n, teamMemberA.position.name, i18nContext.getGenericLocale())
                                                const valueBPosition = displayI18n('name', teamMemberB.position.i18n, teamMemberB.position.name, i18nContext.getGenericLocale())
                                                return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueAPosition, valueBPosition) * (order.order === 'ASC' ? 1 : -1);
                                            case 'playerStatus':
                                                const valueAPlayerStatus = displayI18n('name', teamMemberA.team_member_status?.i18n, teamMemberA.team_member_status?.name, i18nContext.getGenericLocale())
                                                const valueBPlayerStatus = displayI18n('name', teamMemberB.team_member_status?.i18n, teamMemberB.team_member_status?.name, i18nContext.getGenericLocale())
                                                return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueAPlayerStatus, valueBPlayerStatus) * (order.order === 'ASC' ? 1 : -1);
                                        }
                                    }}
                                    defaultSorting='+name'
                                    columns={[
                                        {
                                            label: <Translate id='teams.profile.roster.table.releaseDate' />,
                                            key: 'releaseDate',
                                            className: 'th-shrink',
                                            dataClassName: 'td-shrink',
                                            sortKey: 'release_date',
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='form.fields.name' />,
                                            key: 'name',
                                            // className: '',
                                            // dataClassName: '',
                                            sortable: true,
                                            sortKey: 'member.last_name',
                                        },
                                        {
                                            label: <Translate id='form.fields.dateOfBirth' />,
                                            key: 'age',
                                            sortKey: 'member.birthdate',
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='teams.profile.roster.table.position' />,
                                            key: 'position',
                                            // className: '',
                                            // dataClassName: '',
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='teams.profile.roster.table.jerseyNumber' />,
                                            key: 'jerseyNumber',
                                            // className: '',
                                            // dataClassName: '',
                                            // sortable: true
                                        },
                                        {
                                            label: <Translate id='teams.profile.roster.table.playerStatus' />,
                                            key: 'playerStatus',
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='teams.profile.roster.table.status' />,
                                            key: 'status',
                                            // className: '',
                                            // dataClassName: '',
                                            sortable: true,
                                        },
                                    ]}
                                    renderRow={(columnKey, teamMember) => {
                                        switch (columnKey){
                                            case 'releaseDate':
                                                return (
                                                    teamMember.release_date ?
                                                        <DateFormat value={teamMember.release_date} format={'YYYY-MM-DD h:mm A'} />
                                                        : '-'
                                                )
                                            case 'name':
                                                return (
                                                    <UserDisplay>
                                                        <UserDisplay.Container>
                                                            <UserImg
                                                                abbr={teamMember.member.first_name.charAt(0) + teamMember.member.last_name.charAt(0)}
                                                                src={teamMember.member.picture?.full_path}
                                                                filePos={teamMember.member.picture?.file_position}
                                                                width={40}
                                                                height={40}
                                                                alt={teamMember.member.first_name + ' ' + teamMember.member.last_name}
                                                            />
                                                        </UserDisplay.Container>
                                                        <UserDisplay.Container>
                                                            <UserDisplay.Title>
                                                                <div className='d-flex'>
                                                                    {teamMember.member.first_name + ' ' + teamMember.member.last_name}
                                                                    {/* {(teamMember.crc_is_expired == 1) && // if criminal record check is expired or non existant
                                                                        <HasAccessTo className='ml-1' componentCode='members' componentPermissionCode='member_crc'>
                                                                            <UserDisplay.Container className="ml-auto">
                                                                                <img src={CRC_Red} alt="Criminal Record Check Required" height='20'/>
                                                                            </UserDisplay.Container>
                                                                        </HasAccessTo>
                                                                    } */}
                                                                </div>
                                                            </UserDisplay.Title>
                                                            <UserDisplay.Subtitle>
                                                                <HasAccessTo componentCode='members' componentPermissionCode='members_profile'>
                                                                    <Link className="text-nowrap" to={"/members/profile/" + teamMember.member.member_id}>#{teamMember.member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></Link>
                                                                </HasAccessTo>
                                                            </UserDisplay.Subtitle>
                                                            <UserDisplay.Subtitle>
                                                                <GenderLabel gender={teamMember.member.gender} />
                                                            </UserDisplay.Subtitle>
                                                        </UserDisplay.Container>
                                                        <UserDisplay.Container className="ml-auto">
                                                            {/* crc_is_expired 0 because we don't want to show that here */}
                                                            <MemberStatusIcons member={{ ...teamMember.member, crc_is_expired: 0 }} />
                                                        </UserDisplay.Container>
                                                    </UserDisplay>
                                                )
                                            case 'age':
                                                return (<TeamMemberAge member={teamMember} />)
                                            case 'position':
                                                return (
                                                    <>
                                                        {teamMember.position ?
                                                            <DisplayI18n field='name' defaultValue={teamMember.position.name} i18n={teamMember.position.i18n} />
                                                            : '-'}
                                                    </>
                                                )
                                            case 'jerseyNumber':
                                                return (
                                                    getJerseyNumber(teamMember.jersey_number, teamMember.jersey_number_alternate)
                                                )
                                            case 'playerStatus':
                                                if(teamMember.team_member_status){
                                                    return <DisplayI18n field='name' defaultValue={teamMember.team_member_status.name} i18n={teamMember.team_member_status.i18n} />
                                                }
                                                return '-'
                                            case 'status':
                                                return (
                                                    <>
                                                        {teamMember.status || teamMember.import == 1 || teamMember.tryout == 1 || teamMember.affiliate == 1 ?
                                                            <>
                                                                {teamMember.status &&
                                                                    <div className="mb-2">
                                                                        {getTeamMemberStatus(teamMember.status)}
                                                                    </div>
                                                                }
                                                                {teamMember.tryout == 1 &&
                                                                    <MemberTeamStatus
                                                                        className="teamRoster-status mr-2"
                                                                        tip="teams.profile.roster.table.tryout"
                                                                    >
                                                                        <Translate id='teams.profile.roster.table.tryout.short' />
                                                                    </MemberTeamStatus>
                                                                }
                                                                {teamMember.import == 1 &&
                                                                    <MemberTeamStatus
                                                                        className="teamRoster-status mr-2"
                                                                        tip="teams.profile.roster.table.imported"
                                                                    >
                                                                        <Translate id='teams.profile.roster.table.imported.short' />
                                                                    </MemberTeamStatus>
                                                                }
                                                                {teamMember.affiliate == 1 &&
                                                                    <MemberTeamStatus
                                                                        className="teamRoster-status mr-2"
                                                                        tip="teams.profile.roster.table.affiliated"
                                                                    >
                                                                        <Translate id='teams.profile.roster.table.affiliated.short' />
                                                                    </MemberTeamStatus>
                                                                }
                                                                {/* TODO: HARDCODED */}
                                                                {teamMember.team_member_status?.team_member_status_id === '527e78d1-d9e0-11eb-b24f-06b0069ada2c' &&
                                                                    <MemberTeamStatus
                                                                        className="teamRoster-status teamRoster-status-danger mr-2"
                                                                        tip="teams.profile.roster.table.injured"
                                                                    >
                                                                        <Translate id='teams.profile.roster.table.injured.short' />
                                                                    </MemberTeamStatus>
                                                                }
                                                            </>
                                                            : '-'}
                                                    </>
                                                )
                                            default:
                                                break;
                                        }
                                    }}
                                    onColumnClick={(e, data) => {
                                        switch (e.button){
                                            case 0: // Left mouse button
                                                panelProps.onSingleSelectChange(data);
                                                break;
                                        }
                                    }}
                                    rowIsHighlighted={(data) => data.checked}
                                >
                                    {(spordleTable) => (
                                        <>
                                            {/* Collapse Card Roster Table Header */}
                                            <TeamRosterTableHeader
                                                title={<Translate id='teams.profile.roster.table.inactiveRoster.title' />}
                                                isOpen={isOpen}
                                                isOpenHandlers={isOpenHandlers}
                                                spordleTable={spordleTable}
                                                displayCount={displayCount}
                                            />

                                            {/* Roster table view and header search & actions */}
                                            <CrossFade isVisible={isOpen}>
                                                <div className='mb-2'>
                                                    <div className='d-flex flex-wrap justify-content-between'>
                                                        <SearchInput />

                                                        <div className='d-flex ml-auto text-right'>
                                                            <Refresh />
                                                            {spordleTable.getData().length > 0 &&
                                                                <CanDoAction action="ADD" componentCode='communications' componentPermissionCode='communications_manage'>
                                                                    {(isGod) => (
                                                                        <UncontrolledDropdown inNavbar>
                                                                            <DropdownToggle caret color="primary" className="ml-2" outline><Translate id="misc.actions" /></DropdownToggle>
                                                                            <DropdownMenu right>
                                                                                <DropdownItem onClick={sendCommIsOpenHandlers.open} className={stringBuilder({ "text-purple": isGod })} id={spordleTable.generateId('send-communication')}>
                                                                                    <Translate id="communications.sendMessage" />
                                                                                </DropdownItem>
                                                                                <SendCommModal
                                                                                    isOpen={!!sendCommIsOpen}
                                                                                    toggle={sendCommIsOpenHandlers.close}
                                                                                    recipients={spordleTable.getData().map((participant) => ({ ...participant, ...participant.member }))}
                                                                                />
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    )}
                                                                </CanDoAction>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <SpordleTableView />
                                            </CrossFade>
                                        </>
                                    )}
                                </SpordleTableProvider>
                            )
                        }}
                    />
                </CardBody>
            </OverlayLoader>
        </Card>
    );
}

export default TeamInactiveRoster;