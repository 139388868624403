import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Badge } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import { fail } from '@spordle/toasts';
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import { useIntl } from "react-intl";
import CanDoAction from "../../../../../../../components/permissions/CanDoAction";
import { Tooltip } from "@mantine/core";

const OutcomeCard = ({ maltreatment_id, readOnly, maltreatment_outcome, editOutcome, refreshData }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const { deleteDiscriminationOutcomes } = useContext(OrganizationContext);
    const { formatMessage } = useIntl();

    const handleOnDelete = () => {
        setIsLoading(true);
        deleteDiscriminationOutcomes(maltreatment_id, maltreatment_outcome.maltreatment_outcome_id)
            .then(() => {
                setIsLoading(false);
                refreshData();
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    fail({ info: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, skipInfoTranslate: true });
                    setIsLoading(false);
                }
            });
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <UserDisplay className="mb-3" card block>
                <UserDisplay.Container className="align-items-center d-flex flex-wrap w-100">
                    <div className="border-bottom mb-2 d-flex justify-content-between w-100 pb-2">
                        <UserDisplay.Title>
                            <DisplayI18n field='name' defaultValue={maltreatment_outcome.outcome.name} i18n={maltreatment_outcome.outcome.i18n} />
                            {maltreatment_outcome.outcome.causes_suspension == '1' && <div className="small text-muted"><i className="mdi mdi-information-outline text-primary mr-1" /><Translate id="discrimination.outcomes.causesSuspension" /></div>}
                        </UserDisplay.Title>
                        {!readOnly &&
                            <div>
                                <CanDoAction action="EDIT" componentCode="organization" componentPermissionCode="discrimination_claims">
                                    <Tooltip
                                        label={<Translate id="misc.edit" />}
                                        withArrow
                                        zIndex={1040}
                                    >
                                        <button title={formatMessage({ id: "misc.edit" })} type="button" className="reset-btn mdi mdi-pencil px-3 mr-n3 text-primary" onClick={() => editOutcome(maltreatment_outcome)} />
                                    </Tooltip>
                                </CanDoAction>
                                <CanDoAction action="DELETE" componentCode="organization" componentPermissionCode="discrimination_claims">
                                    <Tooltip
                                        label={<Translate id="misc.delete" />}
                                        withArrow
                                        zIndex={1040}
                                    >
                                        <button title={formatMessage({ id: "misc.delete" })} type="button" className="reset-btn ml-3 px-3 mr-n3 border-left fas fa-trash text-danger" onClick={handleOnDelete} />
                                    </Tooltip>
                                </CanDoAction>
                            </div>
                        }
                        {/* {!readOnly &&
                            <UncontrolledDropdown>
                                <DropdownToggle color="link" className="p-0 text-primary">
                                    <i className="line-height-1 mdi mdi-dots-horizontal font-20" />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <CanDoAction action="EDIT" componentCode="organization" componentPermissionCode="discrimination_claims">
                                        <DropdownItem onClick={() => editOutcome(maltreatment_outcome)}>
                                            <Translate id='misc.edit' />
                                        </DropdownItem>
                                    </CanDoAction>
                                    <CanDoAction action="DELETE" componentCode="organization" componentPermissionCode="discrimination_claims">
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => handleOnDelete()}>
                                            <Translate id='misc.delete' />
                                        </DropdownItem>
                                    </CanDoAction>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        } */}
                    </div>
                    <div className="d-flex align-items-start w-100">
                        <div className="pr-2 text-break">
                            {maltreatment_outcome.created_by && <UserDisplay.Subtitle className="text-dark"><b><Translate id="discrimination.outcomes.createdBy" />:</b> {maltreatment_outcome.created_by.name} {maltreatment_outcome.created_by.family_name}</UserDisplay.Subtitle>}
                            {maltreatment_outcome.updated_by && <UserDisplay.Subtitle className="text-dark"><b><Translate id="discrimination.outcomes.updatedBy" />:</b> {maltreatment_outcome.updated_by.name} {maltreatment_outcome.updated_by.family_name}</UserDisplay.Subtitle>}
                            {maltreatment_outcome.due_date && <UserDisplay.Subtitle className="text-dark"><b><Translate id="discrimination.outcomes.dueDate" />:</b> {maltreatment_outcome.due_date}</UserDisplay.Subtitle>}
                            {maltreatment_outcome.comment &&
                                <>
                                    <UserDisplay.Subtitle className="text-dark mb-0 pb-0"><b><Translate id="form.fields.comment" />:</b></UserDisplay.Subtitle>
                                    <UserDisplay.Subtitle className="text-dark">{maltreatment_outcome.comment}</UserDisplay.Subtitle>
                                </>
                            }
                        </div>
                        <div className="ml-auto text-right min-w-25">
                            <Badge color={maltreatment_outcome.status == "PENDING" ? 'warning' : 'success'}><Translate id={`discrimination.outcomes.status.${maltreatment_outcome.status}`} defaultMessage={maltreatment_outcome.status} /></Badge>
                        </div>
                    </div>
                </UserDisplay.Container>
            </UserDisplay>
        </OverlayLoader>
    );
}

export default OutcomeCard;