import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import { FormikDateTime, FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import moment from 'moment';
import React from "react";
import {
    Button,
    Card,
    Col,
    Fade,
    FormGroup,
    Label, ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import * as Yup from 'yup';
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import CardSectionTitle from "../../../../components/CardSectionTitle";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import SpordlePanelTable from "../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../components/table/EmptyLayout";
import { PeriodsContext } from '../../../../contexts/contexts';
import { I18nContext } from '../../../../contexts/I18nContext';
// Contexts
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { formatI18nForTable, initTableViewI18n, renderI18nFields, renderI18nTableColumn } from '../../../../helpers/i18nHelper';
import withContexts from "../../../../helpers/withContexts";
import SidePanelOrganizationPeriods from './SidePanelOrganizationPeriods';

class OrganizationPeriods extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            addPeriodModalOpen: false,
            modalLoading: 'success',
        }

        this.errorCode = '';

        this.i18n = initTableViewI18n(this.props.OrganizationContext.settings.language.value, 'name');
    }


    toggleAddPeriod = () => {
        this.setState((prevState) => ({ addPeriodModalOpen: !prevState.addPeriodModalOpen }));
    }

    addPeriod = (values) => {
        return this.props.PeriodsContext.createPeriod(this.props.OrganizationContext.organisation_id, { ...values, active: values.status === 'active' }, this.i18n)
            .then((data) => {
                const returnData = {
                    period_id: data,
                    ...formatI18nForTable(this.i18n.languages, 'name', values),
                    start_date: values.startDate, // Dates in iso
                    end_date: values.endDate,
                    shared_date: values.sharedDate,
                    calculation_date: values.calculationDate,
                    active: values.status === 'active' ? '1' : '0',
                }
                this.periodsTableRef.addRow(returnData)
            })
            .catch((error) => {
                this.errorCode = `contexts.periodsContext.errors.${error.message}`;
                console.error(error.message);
            })
    }

    render(){
        return (
            <div>
                {/* ADD ROLE MODAL */}
                <AnalyticsModal isOpen={this.state.addPeriodModalOpen} toggle={this.toggleAddPeriod} analyticsName='OrganizationPeriods'>
                    <Formik
                        initialValues={{
                            ...this.i18n.initialValues,
                            startDate: '',
                            endDate: '',
                            sharedDate: '',
                            calculationDate: '',
                            status: 'active',
                        }}
                        validationSchema={Yup.object().shape({
                            ...this.i18n.validationSchema,
                            startDate: Yup.mixed().required(<Translate id='organization.settings.organizationPeriods.add.startDate.required' />)
                                .test({
                                    name: 'start_date_format',
                                    message: <Translate id='organization.settings.organizationPeriods.add.startDate.format' />,
                                    test: (value) => moment.isMoment(value),
                                })
                                .when('endDate', {
                                    is: (endDate) => endDate !== undefined,
                                    then: Yup.mixed()
                                        .test({
                                            name: 'start_date_end_date_check',
                                            message: <Translate id='organization.settings.organizationPeriods.add.startDate.before' />,
                                            test: function(value){ return moment(value).isBefore(this.parent.endDate) },
                                        }),
                                }),
                            endDate: Yup.mixed().required(<Translate id='organization.settings.organizationPeriods.add.endDate.required' />)
                                .test({
                                    name: 'end_date_format',
                                    message: <Translate id='organization.settings.organizationPeriods.add.endDate.format' />,
                                    test: (value) => moment.isMoment(value),
                                }),
                            sharedDate: Yup.mixed()
                                .test({
                                    name: 'shared_date_format',
                                    message: <Translate id='organization.settings.organizationPeriods.add.sharedDate.format' />,
                                    test: (value) => {
                                        if(value !== undefined){
                                            return moment.isMoment(value)
                                        }
                                        return true // return true because the field is not required

                                    },
                                }),
                            calculationDate: Yup.mixed()
                                .test({
                                    name: 'calculation_date_format',
                                    message: <Translate id='organization.settings.organizationPeriods.add.calculationDate.format' />,
                                    test: (value) => {
                                        if(value !== undefined){
                                            return moment.isMoment(value)
                                        }
                                        return true // return true because the field is not required

                                    },
                                }),
                            status: Yup.string().required(<Translate id='organization.settings.organizationPeriods.add.status.required' />),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            this.setState(() => ({ modalLoading: 'lazy' }))
                            const formattedValues = {
                                ...values,
                                startDate: values.startDate.toISOString(),
                                endDate: values.endDate.toISOString(),
                                sharedDate: moment.isMoment(values.sharedDate) ? values.sharedDate.toISOString() : null,
                                calculationDate: moment.isMoment(values.calculationDate) ? values.calculationDate.toISOString() : null,
                            }
                            this.addPeriod(formattedValues)
                                .then(() => {
                                    this.setState(() => ({ modalLoading: 'success' }))
                                    this.toggleAddPeriod();
                                })
                                .catch(() => {
                                    this.setState(() => ({ modalLoading: 'error' }))
                                })
                                .finally(() => {
                                    setSubmitting(false)
                                })
                        }}
                    >
                        {(formik) => {
                            return (
                                <Form>
                                    {this.state.modalLoading === 'lazy' ?
                                        <Fade in={this.state.modalLoading === 'lazy'}>
                                            <div className='d-flex justify-content-center align-items-center p-5'>
                                                <Spinner type='grow' color='primary' />
                                            </div>
                                        </Fade>
                                        : null}
                                    {this.state.modalLoading !== 'lazy' ?
                                        <Fade in={this.state.modalLoading !== 'lazy'}>
                                            <ModalHeader toggle={this.toggleAddPeriod}><Translate id='organization.settings.organizationPeriods.add.title' /></ModalHeader>
                                            <ModalBody>
                                                <I18nContext.Consumer>
                                                    {({ getGenericLocale }) => (
                                                        <>
                                                            {renderI18nFields(this.i18n.languages, 'name', (fieldName, index) => {
                                                                return (
                                                                    <Row key={index}>
                                                                        <Col md='12'>
                                                                            <FormGroup>
                                                                                <Label for={fieldName} className='text-muted'>{this.i18n.columns[index].label}</Label>
                                                                                <FormikInputText id={fieldName} name={fieldName} trim />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            })}

                                                            <Row>
                                                                <Col md='6'>
                                                                    <FormGroup>
                                                                        <Label for='startDate' className='text-muted'><Translate id='organization.settings.organizationPeriods.add.startDate' /></Label>
                                                                        <FormikDateTime id='startDate' name='startDate' timeFormat={false} />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md='6'>
                                                                    <FormGroup>
                                                                        <Label for='endDate' className='text-muted'><Translate id='organization.settings.organizationPeriods.add.endDate' /></Label>
                                                                        <FormikDateTime id='endDate' name='endDate' timeFormat={false} />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col md='6'>
                                                                    <FormGroup>
                                                                        <Label for='sharedDate' className='text-muted'><Translate id='organization.settings.organizationPeriods.add.sharedDate' /></Label>
                                                                        <FormikDateTime id='sharedDate' name='sharedDate' timeFormat={false} />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md='6'>
                                                                    <FormGroup>
                                                                        <Label for='calculationDate' className='text-muted'><Translate id='organization.settings.organizationPeriods.add.calculationDate' /></Label>
                                                                        <FormikDateTime id='calculationDate' name='calculationDate' timeFormat={false} />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                </I18nContext.Consumer>

                                                <Row>
                                                    <Col md='12'>
                                                        <FormGroup>
                                                            <Label for='status' className='text-muted'><Translate id='organization.settings.organizationPeriods.add.status' /></Label>
                                                            <FormikSelect
                                                                id='status' name='status'
                                                                defaultData={[
                                                                    { value: 'active', label: 'organization.settings.organizationPeriods.add.status.active', translateLabel: true },
                                                                    { value: 'inactive', label: 'organization.settings.organizationPeriods.add.status.inactive', translateLabel: true },
                                                                ]}
                                                                loadingStatus='success'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                {this.state.modalLoading === 'error' ?
                                                    <div className='alert alert-danger'>{this.errorCode === '' ? <Translate id='misc.error' /> : <Translate id={this.errorCode} defaultMessageId='misc.error' />}</div>
                                                    : null}
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                                <Button color='primary' type='button' outline onClick={this.toggleAddPeriod} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                            </ModalFooter>
                                        </Fade>
                                        : null}
                                </Form>
                            )
                        }}
                    </Formik>
                </AnalyticsModal>

                <SpordlePanelTable
                    allowOutsideClick
                    sidePanel={(props) => <SidePanelOrganizationPeriods {...props} tableRef={this.periodsTableRef} i18n={this.i18n} />}
                    dataIndex='period_id'
                    table={(panelProps) => {
                        return (
                            <Card body className="card-shadow">
                                <CardSectionTitle title='settings.tabs.periods' />
                                <SpordleTableProvider
                                    id='OrganizationPeriodsTable'
                                    tableHover clickable striped
                                    bordered
                                    ref={(r) => { this.periodsTableRef = r; panelProps.spordleTableRef(r); }}
                                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                    dataIndex='period_id'
                                    defaultSorting='+start_date'
                                    searchKeys={[
                                        `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                        'name',
                                        'member_number_prefix',
                                    ]}
                                    columns={[
                                        ...this.i18n.columns,
                                        {
                                            label: <Translate id='organization.settings.organizationPeriods.table.startDate' />,
                                            key: "start_date",
                                            className: 'text-left',
                                            dataClassName: 'text-left',
                                            mobile: true,
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='organization.settings.organizationPeriods.table.endDate' />,
                                            key: "end_date",
                                            className: 'text-left',
                                            dataClassName: 'text-left',
                                            mobile: true,
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='organization.settings.organizationPeriods.table.active' />,
                                            key: "active",
                                            mobile: true,
                                            sortable: true,
                                        },
                                    ]}
                                    onColumnClick={(e, period) => {
                                        switch (e.button){
                                            case 0: // Left mouse button
                                                panelProps.onSingleSelectChange(period);
                                                break;
                                        }
                                    }}
                                    desktopWhen='md'
                                    emptyLayout={<EmptyLayout translateTitle title='organization.settings.organizationPeriods.table.empty' />}
                                    renderRow={(columnKey, period, spordleTable) => {
                                        if(columnKey.includes('name')){
                                            return renderI18nTableColumn(columnKey, period)
                                        }
                                        switch (columnKey){
                                            case 'active':
                                                return (
                                                    <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                        {period.active == '1' ?
                                                            <i className="text-primary mdi mdi-check" />
                                                            :
                                                            <i className="text-danger mdi mdi-close" />
                                                        }
                                                    </div>
                                                )
                                            default:
                                                break;
                                        }
                                    }}
                                    rowIsHighlighted={(period) => !!period.checked}
                                    loadData={(from, filterData, spordleTable) => {
                                        switch (from){
                                            case 'REFRESH':
                                                spordleTable.setLoading();
                                            case 'CDM':
                                                return this.props.PeriodsContext.getOrganizationPeriods(this.props.OrganizationContext.organisation_id);
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    <TopSection toggleAddPeriod={this.toggleAddPeriod} panelProps={panelProps} />
                                    <SpordleTableView />
                                </SpordleTableProvider>
                            </Card>
                        )
                    }}
                />
            </div>
        )
    }
}

const TopSection = (props) => {
    return (
        <div className="mb-2">
            <div className='d-flex justify-content-between'>
                <SpordleTableProvider.SearchInput />
                <div className='d-flex ml-auto text-right'>
                    <SpordleTableProvider.Refresh />
                    <CanDoAction action='ADD' componentCode='settings' componentPermissionCode='organization_periods'>
                        <Button color='primary' className='ml-2' onClick={props.toggleAddPeriod}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                    </CanDoAction>
                </div>
            </div>
        </div>
    )
}

export default withContexts(PeriodsContext, OrganizationContext, I18nContext)(OrganizationPeriods);