export default{
    "invoice.title"              : "Facture",
    "invoice.date"               : "Date",
    "invoice.taxNumber"          : "{count, plural, one {Numéro de taxe} other {Numéros de taxe}}",
    "invoice.invoiceNo"          : "Facture #",
    "invoice.paymentMethod"      : "Méthode de paiement",
    "invoice.payedBy"            : "Payé par",
    "invoice.organization"       : "Organisation",
    "invoice.table.item"         : "Article",
    "invoice.table.credits"      : "Crédit",
    "invoice.table.fees"         : "Frais de plateforme",
    "invoice.table.discount"     : "Rabais",
    "invoice.table.registration" : "Inscription",
    "invoice.table.affiliation"  : "Frais d'affiliation",
    "invoice.table.clinic"       : "Stage",
    "invoice.table.season"       : "Saison",
    "invoice.table.season.to"    : "au",
    "invoice.table.tax"          : "{count, plural, one {Taxe} other {Taxes}}",
    "invoice.table.price"        : "Prix",
    "invoice.table.qty"          : "Qté",
    "invoice.table.total"        : "Total",
    "invoice.table.subTotal"     : "Sous Total",
    "invoice.table.poweredBy"    : "Propulsé par Spordle © {year} Spordle Inc. Tous droits réservés.",

    "invoice.receipt.members"     : "Membre(s)",
    "invoice.receipt.total"       : "Total",
    "invoice.receipt.paid"        : "Payé",
    "invoice.receipt.alreadyPaid" : "Déjà payé",
    "invoice.receipt.balance"     : "Balance",

    "invoice.error.title"   : "Une erreur s'est produite",
    "invoice.error.message" : "Nous n'avons pas pu trouver de facture. Veuillez revenir plus tard ou contact l'équipe de support.",

    "invoice.transactions.paymentMethods.CHEQUE"       : "Chèque",
    "invoice.transactions.paymentMethods.CASH"         : "Argent comptant",
    "invoice.transactions.paymentMethods.CREDITCARD"   : "Carte de crédit",
    "invoice.transactions.paymentMethods.PAYMENTPROXY" : "Carte de crédit (paiement proxy)",
    "invoice.transactions.paymentMethods.INTERAC"      : "Transfert Interac",
    "invoice.transactions.paymentMethods.FUNDING"      : "Financement",
    "invoice.transactions.paymentMethods.undefined"    : "N/A",


    "invoice.editModal.title"                     : "Modifier info de la facture",
    "invoice.editModal.editIdentity.btn"          : "Identité",
    "invoice.editModal.editIdentity.btn.helper"   : "Cliquez ici si vous souhaitez modifier l'identité de cette facture.",
    "invoice.editModal.editIdentity.success.msg"  : "Identité mise à jour",
    "invoice.editModal.editIdentity.success.info" : "L'identité de la facture a été mise à jour",

    "invoice.editModal.editIdentity.existing" : "Ce compte existe déjà. Souhaitez-vous lier les membres de cette facture à ce compte existant ?",

    "invoice.editModal.editAddress.btn"          : "Adresse",
    "invoice.editModal.editAddress.btn.helper"   : "Cliquez ici si vous souhaitez modifier l'adresse de cette facture.",
    "invoice.editModal.editAddress.success.msg"  : "Adresse à jour",
    "invoice.editModal.editAddress.success.info" : "L'addresse de la facture a été mise à jour",

    "invoice.changeAddress.title"              : "Changer l'adresse de la facture",
    "invoice.changeAddress.addresses"          : "Adresses du membre",
    "invoice.changeAddress.addresses.required" : "Veuillez sélectionner une adresse",
}