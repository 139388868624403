export default{
    "organization.settings.sidePanelOrganizationTeamAddressTypes.title" : "Informations de type d'adresse",

    "organization.settings.sidePanelOrganizationTeamAddressTypes.status"          : "Statut",
    "organization.settings.sidePanelOrganizationTeamAddressTypes.status.active"   : "Actif",
    "organization.settings.sidePanelOrganizationTeamAddressTypes.status.inactive" : "Inactif",
    "organization.settings.sidePanelOrganizationTeamAddressTypes.status.required" : "Le statut est requis",

    "organization.settings.sidePanelOrganizationTeamAddressTypes.update.success.text" : "Type d'adresse mis à jour",
    "organization.settings.sidePanelOrganizationTeamAddressTypes.update.fail.text"    : "Une erreur est survenue lors de la mise à jour du type d'adresse, veuillez rafraichir la page et réessayer",

    // actions
    "organization.settings.sidePanelOrganizationTeamAddressTypes.actions.remove"         : "Retirer",
    "organization.settings.sidePanelOrganizationTeamAddressTypes.actions.remove.topText" : "Vous êtes sur le point de retirer ce type d'adresse de votre organisation",

    // tabs
    "organization.settings.sidePanelOrganizationTeamAddressTypes.tabs.details" : "Détails",
}