import { useContext, useState } from "react";
import {
    Button,
    Card
} from "reactstrap";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate from "@spordle/intl-elements";
import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel";

// Contexts
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import I18nHelperContextProvider, { I18nHelperContext } from "../../../../../helpers/i18nHelper";
import SidePanelOrganizationPositionGroups from "./SidePanelOrganizationPositionGroups";
import OrganizationPositionGroupsAdd from "./OrganizationPositionGroupsAdd";
import { PositionsContext } from "../../../../../contexts/PositionsContext";
import EmptyLayout from "../../../../../components/table/EmptyLayout";
import CardSectionTitle from "../../../../../components/CardSectionTitle";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import { I18nContext } from "../../../../../contexts/I18nContext";

const OrganizationPositionGroups = () => {

    const positionsContext = useContext(PositionsContext);
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);

    const [ modalAdd, setModalAdd ] = useState(false);
    const toggleModalAdd = () => setModalAdd(!modalAdd);

    return (
        <I18nHelperContextProvider fields={[ 'name' ]}>
            <I18nHelperContext.Consumer>
                {(i18nHelper) => (
                    <div>
                        <SpordlePanelTable
                            allowOutsideClick
                            sidePanel={(props) => <SidePanelOrganizationPositionGroups {...props} />}
                            dataIndex='position_group_id'
                            table={(panelProps) => {
                                return (
                                    <Card body className="card-shadow">
                                        <CardSectionTitle title='settings.tabs.positionGroups' />
                                        <SpordleTableProvider
                                            id='OrganizationPositionGroupsTable'
                                            tableHover clickable striped
                                            bordered
                                            ref={panelProps.spordleTableRef}
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                            dataIndex='position_group_id'
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                                'name',
                                                'abbreviation',
                                            ]}
                                            columns={[
                                                ...i18nHelper.getTableColumns('name'),
                                                {
                                                    label: <Translate id='organization.settings.organizationPositionGroups.table.column.abbreviation' />,
                                                    key: "abbreviation",
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='organization.settings.organizationPositionGroups.table.column.status' />,
                                                    key: "active",
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                            ]}
                                            onColumnClick={(e, positionGroup) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        panelProps.onSingleSelectChange(positionGroup);
                                                        break;
                                                }
                                            }}
                                            desktopWhen='md'
                                            emptyLayout={<EmptyLayout translateTitle title='organization.settings.organizationPositionGroups.table.empty' />}
                                            renderRow={(columnKey, positionGroup, spordleTable) => {
                                                switch (columnKey){
                                                    case 'active':
                                                        return (
                                                            <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                {positionGroup.active == '1' ?
                                                                    <i className="text-primary mdi mdi-check" />
                                                                    :
                                                                    <i className="text-danger mdi mdi-close" />
                                                                }
                                                            </div>
                                                        )
                                                    case 'abbreviation':
                                                        return (
                                                            <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                {
                                                                    positionGroup.abbreviation == '' ? '-' : positionGroup.abbreviation
                                                                }
                                                            </div>
                                                        )
                                                    default:
                                                        break;
                                                }
                                            }}
                                            rowIsHighlighted={(positionGroup) => !!positionGroup.checked}
                                            loadData={(from, filterData, spordleTable) => {
                                                switch (from){
                                                    case 'REFRESH':
                                                        spordleTable.setLoading();
                                                    case 'CDM':
                                                        return positionsContext.getPositionGroups(organizationContext.organisation_id);
                                                    default:
                                                        break;
                                                }
                                            }}
                                        >
                                            <div className="mb-2">
                                                <div className='d-flex justify-content-between'>
                                                    <SpordleTableProvider.SearchInput />
                                                    <div className='d-flex ml-auto text-right'>
                                                        <SpordleTableProvider.Refresh />
                                                        <CanDoAction action='ADD' componentCode='teams' componentPermissionCode='team_position_groups'>
                                                            <OrganizationPositionGroupsAdd isOpen={modalAdd} toggle={toggleModalAdd} />
                                                            <Button className='ml-2' color='primary' onClick={toggleModalAdd}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                        </CanDoAction>
                                                    </div>
                                                </div>
                                            </div>
                                            <SpordleTableView />
                                        </SpordleTableProvider>
                                    </Card>
                                )
                            }}
                        />
                    </div>
                )}
            </I18nHelperContext.Consumer>
        </I18nHelperContextProvider>

    )

}

export default OrganizationPositionGroups;