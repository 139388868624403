import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import SpordleTableProvider, { SpordleTableContext, SpordleTableView, useSpordleTable } from '@spordle/datatables';
import { useFormikContext } from 'formik';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useContext, useEffect, useRef, useState } from 'react';
import {
    Card,
    CardBody,
    Button,
    ModalHeader,
    ModalBody,
    Label,
    ModalFooter,
    Collapse,
    Input,
    Fade
} from "reactstrap";
import { RegistrationFeeContext } from '../../../../../../contexts/RegistrationFeeContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { stringBuilder } from "@spordle/helpers";
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import { I18nContext } from '../../../../../../contexts/I18nContext';

import * as Sentry from "@sentry/react";
import { Link } from 'react-router-dom';
import { Tooltip } from '@mantine/core';

const OnlineStoreRegistrationAdd = ({ isOpen, toggle, submit }) => {

    const registrationFeeContext = useContext(RegistrationFeeContext);
    const i18nContext = useContext(I18nContext);

    const [ collapseIsOpen, setCollapseIsOpen ] = useState(false);

    const tableRef = useRef(null);
    const perfectScrollBarRef = useRef(null);

    const table = useSpordleTable();
    const formik = useFormikContext();

    useEffect(() => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: `Opened Add Modal - OnlineStoreRegistrationAdd`,
            level: Sentry.Severity.Log,
            category: 'OnlineStore',
        });
    }, [])

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='OnlineStoreRegistrationAdd'>
            <ModalHeader toggle={toggle}>
                <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.modal.title' />
            </ModalHeader>
            <SpordleTableProvider
                id='OnlineStoreRegistrationAddTable'
                ref={tableRef}
                searchKeys={[
                    `i18n.${i18nContext.getGenericLocale()}.name`,
                    `fee.i18n.${i18nContext.getGenericLocale()}.name`,
                    'name',
                    'fee.name',
                    'fee.sku',
                ]}
                dataIndex='registration_fee_id'
                viewMode='GRID'
                gridConfig={{
                    col: { xs: 12 },
                    gridRender: (otherItem) => (
                        <RegistrationCardOption otherItem={otherItem} />
                    ),
                }}
                onColumnClick={(e, otherItem, spordleTable) => {
                    switch (e.button){
                        case 0: // Left mouse button
                            if(otherItem.can_be_sold === 1)
                                spordleTable.updateDataWith(otherItem.registration_fee_id, { checked: !otherItem.checked })
                            break;
                    }
                }}
                loadData={(from) => {
                    switch (from){
                        case 'CDM':
                            return registrationFeeContext.getRegistrationFees().then((items) => {
                                // Because cards rendered in gridRender are rendered over the scrollbar which is problematic
                                // TODO: Find a cleaner solution if possible
                                perfectScrollBarRef.current._ps.scrollbarYRail.style.zIndex = 10;
                                // Default checkes the items that are already in the list view
                                return items.map((item) => {
                                    const itemFromList = table.getData().find((i) => {
                                        return (i.registration_fee_id === item.registration_fee_id)
                                    });

                                    if(itemFromList){
                                        return { ...itemFromList, ...item, checked: true };
                                    }
                                    return item
                                })
                            });
                        default:
                            break;
                    }
                }}
            >
                <ModalBody>
                    <div className='mb-3'>
                        <Label for="search" className="text-muted"><Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.modal.searchLabel' /></Label>
                        <SpordleTableContext.Consumer>
                            {(spordleTable) => (
                                <div className='search-wrapper'>
                                    <Input type='search' value={spordleTable.getInputValue()} onChange={spordleTable.searchInputChange} />
                                    <button type='button' className={stringBuilder('reset-btn text-link search-clear', { 'd-none': !spordleTable.getInputValue() })} onClick={() => spordleTable.setInputValue()}><i className='mdi mdi-close' /></button>
                                </div>
                            )}
                        </SpordleTableContext.Consumer>
                    </div>
                    <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }} ref={perfectScrollBarRef}>
                        <SpordleTableView />
                    </PerfectScrollbar>
                    <SpordleTableContext.Consumer>
                        {(spordleTable) => {
                            const checkedData = spordleTable.getData().filter((item) => !!item.checked);
                            // Displaying only if there is selected items
                            if(checkedData.length === 0)
                                return (
                                    <div className="border-top pt-3">
                                        <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.modal.selectedItems' values={{ count: 0 }} />
                                    </div>
                                )
                            return (
                                <div className="border-top pt-3">
                                    <button type='button' onClick={() => setCollapseIsOpen(!collapseIsOpen)} className="reset-btn text-link">
                                        <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.modal.selectedItems' values={{ count: checkedData.length }} />
                                        <i style={{ transform: `rotate(${collapseIsOpen ? "180" : "0"}deg)` }} className="ml-1 d-inline-block mdi mdi-chevron-down transition" />
                                    </button>
                                    <Collapse tag="ul" className="list-unstyled mb-0 pt-3" isOpen={collapseIsOpen}>
                                        {checkedData.map((otherItem) => (
                                            <Collapse tag='li' appear isOpen key={otherItem.registration_fee_id}>
                                                <Fade>
                                                    <RegistrationCardOption selected otherItem={otherItem} spordleTable={spordleTable} />
                                                </Fade>
                                            </Collapse>
                                        ))}
                                    </Collapse>
                                </div>
                            )
                        }}
                    </SpordleTableContext.Consumer>
                </ModalBody>
            </SpordleTableProvider>
            <ModalFooter>
                <Button
                    color='primary' onClick={() => {
                    // Removing the checked props which was used in this view but is not usefull in the list view.
                        table.setData(tableRef.current.getData()
                            .filter((item) => !!item.checked)
                            .map(({ checked, ...item }) => ({ ...item, status: 'ACTIVE', registration_fee: item, linked_to: item.linked_to || [] })))
                            .then(() => {
                                formik.setFieldValue('registrationFees', table.getData())
                            })
                            .then(submit)
                            .then(toggle)
                    }}
                >
                    <Translate id='misc.add' />
                </Button>
                <Button color='primary' onClick={toggle} outline><Translate id='misc.cancel' /></Button>
            </ModalFooter>
        </AnalyticsModal>
    );
}

const RegistrationCardOption = ({ otherItem, spordleTable, selected }) => {
    return (
        <Card className={stringBuilder("card-shadow mb-2", { "clickable": !selected })}>
            <CardBody>
                <div className='d-flex align-items-center'>
                    {!selected &&
                        <div className="custom-control custom-checkbox" onClick={(e) => { e.preventDefault() }}>
                            <input
                                disabled={otherItem.can_be_sold === 0}
                                type="checkbox" id={`checkbox-otherItem-${otherItem.registration_fee_id}`} className="custom-control-input"
                                onChange={function(){}}// Prevents having an error in the console because this is a controlled input
                                checked={!!otherItem.checked}// This props makes it the input a controlled input
                            />
                            <label className="custom-control-label" htmlFor={`checkbox-otherItem-${otherItem.registration_fee_id}`} />
                        </div>
                    }
                    <div className='mr-auto'>
                        <div className={stringBuilder("font-bold h6 mb-0", { "text-muted": otherItem.can_be_sold === 0 })}><DisplayI18n i18n={otherItem.fee.i18n} field='name' defaultValue={otherItem.fee.name} /></div>
                        {otherItem.team_category?.min_age && otherItem.team_category.max_age &&
                            <div className="small">
                                {otherItem.team_category.min_age != otherItem.team_category.max_age ?
                                    <Translate id="misc.ageRange" values={{ from: otherItem.team_category.min_age, to: otherItem.team_category.max_age }} />
                                    :
                                    <Translate id='misc.yearsOld' values={{ age: otherItem.team_category.min_age }} />
                                }
                            </div>
                        }
                        {otherItem?.active == '0' &&
                            <div className='text-danger small'><i className='mdi mdi-alert-outline mr-1' /><Translate id='onlineStore.components.onlineStoreItemsFees.status.INACTIVE' /></div>
                        }
                        {!!otherItem?.activity_period &&
                            <div className='text-muted small'><DisplayI18n field='name' defaultValue={otherItem.activity_period.name} i18n={otherItem.activity_period.i18n} /><Tooltip zIndex={3000} withArrow label={<Translate id='onlineStore.components.onlineStoreItemsFees.add.activity.periods' />}><i className='mdi mdi-information-outline text-primary ml-1' /></Tooltip></div>
                        }
                        {!selected && otherItem.can_be_sold === 0 &&
                            <div className="small text-danger">
                                <Tooltip
                                    multiline
                                    width={300}
                                    zIndex={3000}
                                    withArrow
                                    label={<div className='text-wrap'><Translate id={'onlineStore.components.onlineStoreItemsFees.membershipFees.tooltip'} /></div>}
                                >
                                    <div>
                                        <i className='mdi mdi-alert-outline mr-1' />
                                        <Translate id='onlineStore.components.onlineStoreItemsFees.membershipFees.warning' />
                                    </div>
                                </Tooltip>
                                <div>
                                    <Link target={'_blank'} to="/catalog/membershipfees" className="reset-btn text-primary">
                                        <Translate id="onlineStore.components.onlineStoreItemsFees.membershipFees.goTo" />
                                    </Link>
                                </div>
                            </div>
                        }
                        <div className='text-muted'>
                            <DisplayI18n i18n={otherItem.fee.i18n} field='description' defaultValue={otherItem.fee.description} />
                        </div>
                    </div>
                    <div>
                        <div className="font-medium">
                            <CurrencyFormat value={
                                (
                                    parseInt(otherItem.fee.amount) +
                                    (otherItem.exclude_affiliation_fee == '0' && otherItem.affiliation_fees ?// We want them included
                                        parseInt(otherItem.affiliation_fees.total?.[otherItem.member_type?.member_type_id]?.amount || 0)
                                        : 0)
                                ) / 100
                            }
                            />
                        </div>
                    </div>
                    {selected &&
                        <div>
                            <button type='button' className='btn btn-link text-danger btn-sm ml-auto' onClick={() => { spordleTable?.updateDataWith(otherItem.registration_fee_id, { checked: !otherItem.checked }) }}>
                                <i className='fas fa-trash' />
                            </button>
                        </div>
                    }
                </div>
            </CardBody>
        </Card>
    )
}


export default OnlineStoreRegistrationAdd;
