import React from 'react';
import {
    Container
} from "reactstrap";
import TabRoutesNavs from '../../../components/subSidebar/TabRoutesNavs';
import TabRoutesPanes from '../../../components/subSidebar/TabRoutesPanes';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import withContexts from '../../../helpers/withContexts';
import settingsCategoryTabsRoutes from '../../../routes/tabRoutes/settingsCategoryTabsRoutes';

const FED_ID = "1eb5d97a-ffc7-6bbe-a381-06ba8f7ba4e0";
const BRANCH_ID = "1eb5d981-f851-654a-97e0-06ba8f7ba4e0";
class SettingsRegistration extends React.Component{
    constructor(props){
        super(props);
        let _viewAs = 2
        if(this.props.OrganizationContext?.organisation_category?.organisation_category_id === FED_ID)
            _viewAs = 0
        else if(this.props.OrganizationContext?.organisation_category?.organisation_category_id === BRANCH_ID)
            _viewAs = 1
        this.state = {
            viewAs: _viewAs,
        };
    }

    render(){
        const routes = settingsCategoryTabsRoutes.filter((route) => {
            switch (route.path){
                case '/division':
                case '/class':
                    return this.state.viewAs !== 2;
                case '/settings':
                    return this.state.viewAs === 0;
                default:
                    return true;
            }
        })
        return (
            <Container>
                {/* Settings View Header */}
                <ViewHeaderV2 title='registration.settings.category.title' />

                <div className="px-4">
                    <TabRoutesNavs routes={routes} />
                    <TabRoutesPanes
                        routes={routes}
                        getComponentProps={(route) => {
                            switch (route.path){
                                case '/category':
                                case '/class':
                                case '/division':
                                    return { viewAs: this.state.viewAs };
                                default:
                                    return true;
                            }
                        }}
                    />
                </div>
            </Container>
        );
    }
}

export default withContexts(OrganizationContext)(SettingsRegistration);