import affiliationFeesAdd from "./affiliationFeesAdd";
import affiliationFeeSidepanel from "./affiliationFeeSidepanel";

export default{
    "catalog.affiliationFees.title"                               : "Membership Fees",
    "catalog.affiliationFees.count"                               : "{count, plural, =0 {# Membership Fee} =1 {# Membership Fee} other {# Membership Fees}}",
    "catalog.affiliationFees.help"                                : "The membership fee represents the amount required from the member for registration to its governing body and is valid for a specific period of time (for example: one (1) season or one (1) year). This amount covers the membership fees for its provincial and / or national entity as well as the costs related to the member's insurance coverage.",
    "catalog.affiliationFees.iUnderstand"                         : "I understand",
    "catalog.affiliationFees.warning"                             : "Changing any fees here will have an impact on the sub level organization registration fee. In order to enable the modification of the fees, please confirm that you want to proceed.",
    "catalog.affiliationFees.warningContact"                      : "You can contact our support team to help you with the change if the season is already started.",
    "catalog.affiliationFees.active"                              : "Active",
    "catalog.affiliationFees.inactive"                            : "Inactive",
    "catalog.affiliationFees.organisation"                        : "Organization",
    "catalog.affiliationFees.category"                            : "Category",
    "catalog.affiliationFees.division"                            : "Division",
    "catalog.affiliationFees.memberType"                          : "Member Type",
    "catalog.affiliationFees.membershipFees"                      : "Membership Fees",
    "catalog.affiliationFees.membershipFees.all"                  : "Fees of parent organizations",
    "catalog.affiliationFees.membershipFees.myOrganisationColumn" : "Organization you are currently managing",
    "catalog.affiliationFees.status"                              : "Status",

    "catalog.affiliationFees.table.empty"         : "No membership fees.",
    "catalog.affiliationFees.table.empty.message" : "You can change the filters at the top of the page or add more.",

    "catalog.affiliationFees.division.birthYear" : "Birth year from {minYear} to {maxYear}",

    "catalog.affiliationFees.allBranches"       : "All Branches",
    "catalog.affiliationFees.allTypes"          : "All Types",
    "catalog.affiliationFees.branches.selected" : "{count} selected branch(s)",
    "catalog.affiliationFees.divsions.selected" : "{count} selected division(s)",

    "catalog.affiliationFees.feeNeededMessage" : "A fee from your parent organization is needed to activate this division for your organization",

    "catalog.affiliationFees.waivers.not.multi" : "Waivers cannot be edited in batches. Please select one membership fee to set waivers.",

    ...affiliationFeesAdd,
    ...affiliationFeeSidepanel,

}
