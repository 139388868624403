import { Component } from 'react';
import { array, lazy, number, object, string } from 'yup';
import { mobileView, smallDesktopView } from '../helpers/constants';
import mergeDeep, { promisedParseJSON, promisedStringifyJSON } from '../helpers/helper';
import withContexts from '../helpers/withContexts';
import { AuthContext, AppContext } from './contexts';
import { I18nContext } from './I18nContext';
import { getLocalStorageItem, setLocalStorageItem } from "../helpers/browserStorage";
import { IdentityRolesContext } from './IdentityRolesContext';
import { UAParser } from 'ua-parser-js';
import { AxiosIsCancelled } from '../api/CancellableAPI';
import { fire, updateToast } from '@spordle/toasts';
import { Spinner } from 'reactstrap';
import { addBreadcrumb, captureException, Severity, withScope } from '@sentry/react';

/**
 * 1 or 0
 */
const onOff = () => number().min(0).max(1);

class AppContextProvider extends Component{
    constructor(props){
        super(props);

        const parser = new UAParser();

        this.setFrontEndParams = this.setFrontEndParams.bind(this);
        this.getFrontEndParams = this.getFrontEndParams.bind(this);

        this.device = parser?.getDevice();
        this.isMobileDevice = [ 'mobile', 'console', 'smarttv', 'wearable' ].includes(this.device?.type);

        this.state = {
            isUpdating: false,
            routeKey: 0,
            subSidebarOpen: true,
            recents: [],
            isMiniSidebar: window.innerWidth < smallDesktopView,
            identityFrontEndParams: {
                [this._app]: {
                    quickViewState: {},
                    versions: {},
                    tours: {},
                    recents: {},
                    manageRequestTabs: [],
                },
            },
        }

        // QuickView - Load state data loaded in iframe element
        if(window.frameElement && window.frameElement.getAttribute('appcontext')){
            this.state = JSON.parse(window.frameElement.getAttribute('appcontext'));
        }
    }


    // #region Types
    /**
     * @typedef {object} Organization
     * @property {string} organisation_id
     * @property {string} organisation_name
     * @property {string} abbreviation
     * @property {object} i18n
     * @property {object} logo
     */

    /**
     * @typedef {object} Role
     * @property {string} title
     */

    /**
     * @typedef {object} IdentityRole
     * @property {string} identity_role_id
     * @property {Organization} organisation
     * @property {Role} role
     */

    /**
     * @typedef {object} RecentOrg
     * @property {string} id The id of the recent org (identity_role_id + organisation_id)
     * @property {IdentityRole} identityRole
     * @property {Organization} organisation
     * @property {string} date The date when the recent org was last used
     */
    // #endregion Types

    // #region Properties
    /**
     * @private Maximum history count
     */
    _maxRecentsCount = 20;

    /**
     * @private Maximum favorite count
     */
    _maxFavorites = Number.MAX_SAFE_INTEGER;

    /**
     * @private Maximum recent maltreatment count
     */
    _maxRecentMaltreatmentComplaints = 5;

    /**
     * @private Current app
     */
    _app = "SID";

    /**
     * @private default structure of params for this app if none is available
     */
    _defaultParams = {
        [this._app]: {
            quickViewState: { },
            versions: {
                navigation: 0,
            },
            recents: {
                favoriteLinks: {},
                orgs: [],
                maltreatmentComplaints: [],
            },
            tours: {
                // 1 means "on" by default, turn them off when the user has seen them
                navigation: 1,
                registrationFeesRollover: 1, // RegistrationFeesRolloverTour.jsx
                quickViewAdd: 1,
                quickViewToggle: 1,
                newOrgSettings: 1, // OrgProfileTour.jsx
                reportsDashboard: 0, // ReportsDashboardTour.jsx
                reportsView: 0, //
                quickTourConfirmButtonMemberProfile: 1,
                quickTourDeficienciesMemberProfile: 1,
                quickTourRecentMaltreatmentComplaints: 1,
            },
            manageRequestTabs: [],
        },
    }

    /**
     * @private
     * @returns {object}
     */
    _frontEndParamsSchema = object().shape({
        versions: object().shape({
            navigation: onOff(),
        }),
        recents: object().shape({
            favoriteLinks: lazy((obj) => object().shape(
                Object.keys(obj || {}).reduce((objSchema, key) => {
                    objSchema[key] = array().of(
                        object().shape({
                            path: string().required(),
                        }),
                    ).max(this._maxFavorites);

                    return objSchema;
                }, {}),
            )),
            orgs: array(),
            maltreatmentComplaints: array().max(this._maxRecentMaltreatmentComplaints).of(object().shape({
                referenceNumber: string(),
                numberOfMembers: number(),
            })),
        }),
        tours: object().shape({
            navigation: onOff(),
            registrationFeesRollover: onOff(),
            quickViewAdd: onOff(),
            quickViewToggle: onOff(),
            newOrgSettings: onOff(),
            reportsDashboard: onOff(),
            reportsView: onOff(),
            quickTourConfirmButtonMemberProfile: onOff(),
            quickTourDeficienciesMemberProfile: onOff(),
            quickTourRecentMaltreatmentComplaints: onOff(),
        }),
        quickViewState: object().shape({ }),
        manageRequestTabs: array(),
    }).strict().noUnknown();
    // #endregion Properties

    componentDidUpdate(prevProps){
        if(this.props.IdentityRolesContext.identity_role_id && prevProps.IdentityRolesContext.identity_role_id !== this.props.IdentityRolesContext.identity_role_id){
            const localRecents = getLocalStorageItem(`recents-${this.props.IdentityRolesContext.identity_role_id}`);
            if(localRecents){
                promisedParseJSON(localRecents)
                    .then((recents) => {
                        this.setState((prev) => ({ ...prev, recents: recents }));
                    })
                    .catch(console.error);
            }else{
                this.setState((prev) => ({ ...prev, recents: [] }));
            }
        }

    }

    /**
     * Will be used inside the QuickView Iframe
     * @param {orgId} orgId
     * @returns {Promise}
     */
    setCurrentState = (state) => this.setState(() => ({ ...state }));

    // #region Private Functions

    /**
     * @param {string} field
     * @param {object} values
     * @returns {Promise}
     * @throws {Error}
     */
    _validateParams = async(values) => this._frontEndParamsSchema.validate(values, { abortEarly: false });

    /**
     * @private
     * @param {object} values
     */
    _updateStateParams = (values) => {
        return new Promise((resolve) => {
            this.setState((prev) => {
                const newState = ({
                    ...prev,
                    identityFrontEndParams: {
                        ...prev.identityFrontEndParams,
                        [this._app]: mergeDeep(prev.identityFrontEndParams[this._app], values),
                    },
                });

                addBreadcrumb({
                    message: 'Update Front End Params State',
                    level: 'info',
                    category: 'info',
                    data: JSON.stringify(newState.identityFrontEndParams[this._app]),
                    type: 'info',
                });

                return newState;
            }, () => {
                resolve(this.getFrontEndParams());
            });
        })
    }

    /**
     * Updates the current users params to add new front end parameters
     * @param {object} newVal
     */
    _updateApiParams = async(newVal) => {
        const apiFrontEndParams = newVal ? await promisedStringifyJSON(newVal).catch(() => null) : "";

        addBreadcrumb({
            message: 'updateUserInfo Custom Params',
            level: 'info',
            category: 'info',
            data: JSON.stringify({ json: apiFrontEndParams, received: newVal }),
            type: 'info',
        });

        return apiFrontEndParams !== null ? this.props.AuthContext.updateUserInfo({
            ...this.props.AuthContext.account,
            language_code: this.props.I18nContext.getGenericLocale(),
            locale: this.props.I18nContext.getGenericLocale() == "fr" ? "fr_CA" : "en_US",
            front_end_custom_parameters: apiFrontEndParams,
        }) : Promise.reject("JSON parse failed");
    }

    /**
     * Updates the current front end params. Will update the cache first, then update the api.
     * @param {object} values
     * @returns {Promise}
     */
    _updateFrontEndParams = (values) => {
        return this._updateStateParams(values)
            .then(async(newVal) => {
                await this._updateApiParams({
                    ...this.state.identityFrontEndParams,
                    [this._app]: newVal,
                })
                    .catch(console.error);

                return newVal;
            });
    }

    /**
     * Updates a field in the current app front end parameters
     * @param {string} field
     * @param {object} values
     * @returns {Promise}
     */
    _partiallyUpdateFrontEndParams = async(values) => {
        return this._validateParams(values)
            .then(() => this._updateFrontEndParams(mergeDeep(this.getFrontEndParams(), values)))
            .catch((e) => {
                console.error(e.errors);
            });
    }

    // #endregion Private Functions

    /**
     * Sets the front end params of the user with the api return. Usually on initial load.
     * @param {string|null} jsonParams Should be a JSON string
     */
    setFrontEndParams = async(jsonParams) => {
        const parsedParams = await promisedParseJSON(jsonParams)
            .catch(() => {
                if(jsonParams){
                    withScope(function(scope){
                        scope.setLevel(Severity.Error);
                        scope.setContext("params", { json: jsonParams });
                        captureException(new Error("Parse Error -> Front end params"));
                    });
                }

                return this._defaultParams
            });

        const platformParams = parsedParams?.[this._app];

        return this._updateStateParams(
            platformParams ?
                mergeDeep(this._defaultParams[this._app], platformParams)
                :
                this._defaultParams[this._app],
        );
    }

    /**
     * Get the front end params of this plateform
     * @param {string} [field]
     * @returns {object}
     */
    getFrontEndParams = (field) => {
        const params = this.state.identityFrontEndParams[this._app];
        return field ? params[field] : params;
    }

    /**
     * @typedef {object} RecentLink
     * @prop {string|ReactNode} label
     * @prop {string|ReactNode} subtitle
     * @prop {string} path
     * @prop {{ [lang]: { label: string }}} i18n
     * @prop {bool} translate
     */

    /**
     * Function used to get the favorited links of the current identity role id
     * @returns {array}
     */
    getFavoriteLinks = () => {
        return this.getFrontEndParams('recents').favoriteLinks?.[this.props.IdentityRolesContext.identity_role_id] || [];
    }

    /**
     * @param {string} activePath
     * @param {number} [count=3]
     * @returns {RecentLink[]}
     */
    getRecentLinks = (activePath, count = 3) => {
        const favorites = this.getFavoriteLinks();

        if(favorites.length < count){
            const filteredRecents = this.state.recents.reduce((recents, r) => {
                const isInFav = favorites.some((favRecent) => favRecent.path == r.path);
                const isCurrentPath = r.path === activePath;

                if(!isInFav && !isCurrentPath && recents.length + favorites.length < count){
                    recents.push(r);
                }

                return recents;
            }, []);

            return [ ...favorites, ...filteredRecents ];
        }

        return favorites;
    }

    /**
     * @param {object} value
     * @returns {Promise}
     */
    updateParamsRecents = (value) => this._partiallyUpdateFrontEndParams({ recents: value });

    /**
     * @param {object} value
     * @returns {Promise}
     */
    updateParamsVersions = (value) => this._partiallyUpdateFrontEndParams({ versions: value });

    /**
     * @param {object} value
     * @returns {Promise}
     */
    updateParamsTours = (value) => this._partiallyUpdateFrontEndParams({ tours: value });

    /**
     * @param {object} value
     * @returns {Promise}
     */
    updateParamsQuickViews = (value) => this._partiallyUpdateFrontEndParams({ quickViewState: value });

    /**
     * @param {object} value
     * @returns {Promise}
     */
    updateParamsManageRequestTabs = (value) => this._partiallyUpdateFrontEndParams({ manageRequestTabs: value });

    /**
     * Function to get the recent organization in the front end params
     * @returns {Array.<RecentOrg>}
     */
    getRecentOrganizations = () => this.getFrontEndParams('recents').orgs;

    /**
     * Function to add a recent org to the state & API
     * @param {IdentityRole} identityRole
     * @param {Organization} organisation
     * @returns {Promise}
     */
    addRecentOrg = (identityRole, organisation) => {
        let recentOrgs = this.getRecentOrganizations();
        const recentOrg = {
            id: identityRole.identity_role_id + organisation.organisation_id,
            identity_role: identityRole,
            organisation: organisation,
            date: new Date().toISOString(),
        }

        if(recentOrgs){
            recentOrgs = recentOrgs.filter((recent) => recent.id !== recentOrg.id)

            // unshift inserts elements at the start of the array
            recentOrgs.unshift(recentOrg);

            if(recentOrgs.length > 5){
                recentOrgs.splice(5, recentOrgs.length - 5)
            }
        }else{
            recentOrgs = [ recentOrg ];
        }

        return this.updateParamsRecents({ orgs: recentOrgs })
    }

    removeRecentOrg = (recentOrgId) => {
        return this.updateParamsRecents({ orgs: this.getRecentOrganizations().filter((recent) => recent.id !== recentOrgId) });
    }

    /**
     * Updates the rows corresponding to the orgId
     * @param {string} orgId ID of the organization to update data for
     * @param {Function} setValue Function to call to set the new values in the array, the old data is sent as a parameter
     * @returns {Promise}
     */
    updateRecentOrg(orgId, setValue){
        const recentOrgs = this.getRecentOrganizations();
        const newRecentOrgs = recentOrgs?.reduce((newArray, recent) => {
            if(recent.organisation.organisation_id === orgId){
                newArray.push(setValue(recent))
            }else{
                newArray.push(recent)
            }
            return newArray
        }, []);

        return this.updateParamsRecents({ orgs: newRecentOrgs });
    }

    /**
     * Function to get the recent maltreatment complaints in the front end params
     * @returns {Array}
     */
    getRecentMaltreatmentComplaints = () => this.getFrontEndParams('recents')?.maltreatmentComplaints || [];

    /**
     * Function to add a recent maltreatment complaint to the state & API
     * @param {string} referenceNumber
     * @param {number} numberOfMembers
     * @returns {Promise}
     */
    addRecentMaltreatmentComplaint = (referenceNumber, numberOfMembers, identityRoleId) => {
        const recentMaltreatmentComplaints = this.getRecentMaltreatmentComplaints();
        const newRecentMaltreatmentComplaint = {
            referenceNumber,
            numberOfMembers,
            identityRoleId,
        }
        const existingComplaintIndex = recentMaltreatmentComplaints.findIndex((complaint) => complaint.referenceNumber === referenceNumber)

        // no duplicates
        if(existingComplaintIndex === -1){
            // unshift inserts elements at the start of the array
            recentMaltreatmentComplaints.unshift(newRecentMaltreatmentComplaint);
        }else if(recentMaltreatmentComplaints[existingComplaintIndex].numberOfMembers !== numberOfMembers){
            // remove old
            recentMaltreatmentComplaints.splice(existingComplaintIndex, 1)
            // insert new
            recentMaltreatmentComplaints.unshift(newRecentMaltreatmentComplaint);
        }

        if(recentMaltreatmentComplaints.length > this._maxRecentMaltreatmentComplaints){
            recentMaltreatmentComplaints.splice(this._maxRecentMaltreatmentComplaints, recentMaltreatmentComplaints.length - this._maxRecentMaltreatmentComplaints)
        }

        return this.updateParamsRecents({ maltreatmentComplaints: recentMaltreatmentComplaints })
    }

    removeRecentMaltreatmentComplaint = (referenceNumber) => {
        return this.updateParamsRecents({ maltreatmentComplaints: this.getRecentMaltreatmentComplaints().filter((recent) => recent.referenceNumber !== referenceNumber) });
    }

    /**
     * Function used for debugging. Will reset the params.
     * Will set front end params to null.
     * @returns {Promise}
     */
    resetFrontEndParams = () => {
        fire({
            permanent: true,
            id: "resetting-front-end-params",
            icon: (
                <div className="py-2">
                    <Spinner style={{ height: 18, width: 18 }} color="primary" size="sm" />
                </div>
            ),
            msg: "Resetting front end params...",
            skipMsgTranslate: true,
        });

        return this._updateApiParams()
            .then(() => {
                return new Promise((resolve) => {
                    updateToast({
                        permanent: false,
                        id: "resetting-front-end-params",
                        icon: false,
                        theme: "success",
                        msg: "Front end params updated",
                    });

                    this.setState((prev) => ({ ...prev, identityFrontEndParams: this._defaultParams }), resolve);
                })
            })
            .catch((e) => {
                console.error(e);
                updateToast({
                    permanent: false,
                    id: "resetting-front-end-params",
                    icon: false,
                    theme: "danger",
                    msg: "Failed to reset front end params",
                })
            });
    }

    updateRouteKey = () => {
        this.setState((prevState) => ({ routeKey: prevState.routeKey + 1 }));
    };

    /**
     * @param {RecentLink} recent
     */
    toggleRecentInFavorites = async(recent) => {
        const favorites = this.getFavoriteLinks();

        function removeFromFav(path){
            return favorites.filter((f) => f.path !== path);
        }

        function addToFav(link){
            return [ ...favorites, { ...link, isFavorite: true } ];
        }

        const isFavorite = favorites.some((f) => f.path === recent.path);
        const newFavorites = isFavorite ? removeFromFav(recent.path) : favorites.length < this._maxFavorites ? addToFav(recent) : favorites;

        return this.updateParamsRecents({ favoriteLinks: { [this.props.IdentityRolesContext.identity_role_id]: newFavorites } })
            .then(() => !isFavorite)
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }
            })
    }

    /**
     * @param {RecentLink} routeData
     */
    updateRecentlyViewed = async(routeData) => {
        await Promise.resolve(
            this.setState((prev) => {
                const newRecents = [
                    routeData,
                    ...prev.recents.filter((r) => r.path !== routeData.path).slice(0, this._maxRecentsCount - 1),
                ];

                try{
                    const localRecents = JSON.stringify(newRecents);

                    if(localRecents){
                        setLocalStorageItem(`recents-${this.props.IdentityRolesContext.identity_role_id}`, localRecents);
                    }
                }catch(e){
                    console.error(e);
                }

                return {
                    ...prev,
                    recents: newRecents,
                }

            }),
        );
    }

    /**
     * @returns {RecentLink[]}
     */
    getAllRecentLinks = () => this.state.recents || [];

    emptyRecentLinks = () => {
        try{
            const localRecents = JSON.stringify([]);

            if(localRecents){
                setLocalStorageItem(`recents-${this.props.IdentityRolesContext.identity_role_id}`, localRecents);
            }
        }catch(e){
            console.error(e);
        }

        this.setState((prev) => ({ ...prev, recents: [] }));
    }

    setSubSideBarOpen = (isOpen) => {
        this.setState({ subSidebarOpen: isOpen });
    }

    toggleSidebar = () => {
        this.setState((prev) => ({ ...prev, isMiniSidebar: !prev.isMiniSidebar }))
    };

    setSidebarOpen = (isOpen) => {
        this.setState((prev) => ({ ...prev, isMiniSidebar: !isOpen }));
    }

    updateMiniSidebar = () => {
        this.setState((prev) => ({ ...prev, isMiniSidebar: window.innerWidth < mobileView }));
    }

    render(){
        return (
            <AppContext.Provider
                value={{
                    ...this.state,
                    device: this.device,
                    isMobileDevice: this.isMobileDevice,
                    setSubSideBarOpen: this.setSubSideBarOpen,
                    setSidebarOpen: this.setSidebarOpen,
                    toggleSidebar: this.toggleSidebar,
                    updateRouteKey: this.updateRouteKey,
                    setFrontEndParams: this.setFrontEndParams,
                    setNavigationVersion: this.setNavigationVersion,
                    getFrontEndParams: this.getFrontEndParams,
                    updateParamsRecents: this.updateParamsRecents,
                    updateParamsVersions: this.updateParamsVersions,
                    updateParamsTours: this.updateParamsTours,
                    updateParamsManageRequestTabs: this.updateParamsManageRequestTabs,
                    resetFrontEndParams: this.resetFrontEndParams,
                    updateRecentlyViewed: this.updateRecentlyViewed,
                    toggleRecentInFavorites: this.toggleRecentInFavorites,
                    getRecentLinks: this.getRecentLinks,
                    getAllRecentLinks: this.getAllRecentLinks,
                    emptyRecentLinks: this.emptyRecentLinks,
                    getFavoriteLinks: this.getFavoriteLinks,
                    getRecentOrganizations: this.getRecentOrganizations,
                    addRecentOrg: this.addRecentOrg,
                    removeRecentOrg: this.removeRecentOrg,
                    updateRecentOrg: this.updateRecentOrg,
                    getRecentMaltreatmentComplaints: this.getRecentMaltreatmentComplaints,
                    addRecentMaltreatmentComplaint: this.addRecentMaltreatmentComplaint,
                    removeRecentMaltreatmentComplaint: this.removeRecentMaltreatmentComplaint,
                    startChecklist: this.startChecklist,
                    stopChecklist: this.stopChecklist,
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default withContexts(AuthContext, IdentityRolesContext, I18nContext)(AppContextProvider);