export default{
    "organization.settings.organizationSuspensions.table.code"   : "Code",
    "organization.settings.organizationSuspensions.table.active" : "Actif",
    "organization.settings.organizationSuspensions.table.empty"  : "Aucune Suspension",

    // add
    "organization.settings.organizationSuspensions.add.title"                    : "Ajouter une Suspension",
    "organization.settings.organizationSuspensions.add.code"                     : "Code",
    "organization.settings.organizationSuspensions.add.code.required"            : "Le Code est requis",
    "organization.settings.organizationSuspensions.add.infraction"               : "Infraction",
    "organization.settings.organizationSuspensions.add.infraction.required"      : "Infraction est requis",
    "organization.settings.organizationSuspensions.add.suspension.type"          : "Type de suspension",
    "organization.settings.organizationSuspensions.add.suspension.type.required" : "Type de suspension est requis",
    "organization.settings.organizationSuspensions.add.status"                   : "Statut",
    "organization.settings.organizationSuspensions.add.status.active"            : "Actif",
    "organization.settings.organizationSuspensions.add.status.inactive"          : "Inactif",
    "organization.settings.organizationSuspensions.add.status.required"          : "Le Statut est requis",
    "organization.settings.organizationSuspensions.add.code.duplicate"           : "Code de suspension en double.",
}