// eslint-disable-next-line unused-imports/no-unused-imports
import React, { useContext, useEffect, useRef } from "react";
import { stringBuilder } from "@spordle/helpers";
import { SwiperContext } from "./Swiper";
import { useSwipeTransition } from "./useSwipeTransition";
import PropTypes from "prop-types";

/**
 * @param {object} props
 * @param {string|React.ElementType} props.tag
 * @param {number} props.nb
 * @param {string} props.className
 */
const SwipeSlide = ({ tag: Tag = "div", className, nb, onEntered, onExited, ...props }) => {
    const swiper = useContext(SwiperContext);
    const [ triggerTransition ] = useSwipeTransition(200); // TODO: Try to make only one triggerTransition for all slides
    const slideRef = useRef();
    const index = nb - 1;
    const previousPos = useRef(getPos(swiper.activeSlide));

    function getPos(activeSlide){
        return (index - activeSlide) * 100;
    }

    function updateSlidePos(val){
        slideRef.current.style.transform = `translateX(${val}%)`;
    }

    function handleOnTransitionEnd(){
        if(onExited && swiper.activeSlide !== index){
            onExited();
        }else if(onEntered && swiper.activeSlide === index){
            onEntered();
        }

        swiper.setIsTransitioning(false);
    }

    function handleTransitionCallback(val, isEnding){
        updateSlidePos(val);

        if(isEnding){
            handleOnTransitionEnd();
        }
    }

    useEffect(() => {
        const currentPos = getPos(swiper.activeSlide);

        if(currentPos !== previousPos.current){
            triggerTransition(previousPos.current, currentPos, handleTransitionCallback);
        }else{
            updateSlidePos(previousPos.current);
        }

        previousPos.current = currentPos;

    }, [ swiper.activeSlide ]);

    return (
        <Tag
            ref={slideRef}
            style={{
                willChange: "transform",
            }}
            className={stringBuilder(className, "position-absolute top-0 left-0 right-0 bottom-0")}
        >
            {typeof props.children === "function" ? props.children(swiper) : props.children}
        </Tag>
    )
}

SwipeSlide.displayName = "SwipeSlide";

SwipeSlide.defaultProps = {
    tag: "div",
}

SwipeSlide.propTypes = {
    className: PropTypes.string,
    nb: PropTypes.number.isRequired,
    onEntered: PropTypes.func,
    tag: PropTypes.oneOfType([ PropTypes.string, PropTypes.elementType ]),
    onExited: PropTypes.func,
}

export default SwipeSlide;