import { useSpordleTable } from "@spordle/datatables";
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext } from "react";
import {
    Button,
    Col,
    FormGroup,
    Label, ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import * as Yup from 'yup';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import Required from '../../../../../components/formik/Required';
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { TeamsContext } from "../../../../../contexts/TeamsContext";
import { displayI18n, I18nHelperContext, RenderI18nForm } from '../../../../../helpers/i18nHelper'; // these are deprecated, look at OrganizationPhoneTypes i18nhelper

const TeamTravelTypesAddModal = (props) => {
    const i18nHelper = useContext(I18nHelperContext);
    const spordleTable = useSpordleTable();
    const teamsContext = useContext(TeamsContext);
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <AnalyticsModal isOpen={props.addTravelTypeModalOpen} toggle={props.toggleAddTravelType} analyticsName='TeamTravelTypesAddModal'>
            <Formik
                initialValues={{
                    ...i18nHelper.getInitialValues(),
                    status: 'active',
                    active: true,
                }}
                validationSchema={Yup.object().shape({
                    ...i18nHelper.getValidationSchema({ name: Yup.string().required(<Translate id='form.validation.name.required' />) }),
                    status: Yup.string().required(<Translate id='organization.settings.organizationTeamTravelTypes.add.status.required' />),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    values.active = !!(values.status === 'active')
                    const newItem = values // data that will be sent back to table

                    const apiValues = { ...values, ...i18nHelper.getAPIValues(values) };
                    delete apiValues.i18n

                    teamsContext.createTravelType(apiValues)
                        .then((id) => {
                            const returnData = {
                                travel_type_id: id,
                                ...newItem,
                            }

                            spordleTable.addRow(returnData).then(() => {
                                props.toggleAddTravelType();
                            })
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                            }
                            setStatus(displayI18n('message', error.i18n, error.message, getGenericLocale()));
                        })
                        .finally(() => {
                            setSubmitting(false)
                        })
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalHeader toggle={props.toggleAddTravelType}><Translate id='organization.settings.organizationTeamTravelTypes.add.title' /></ModalHeader>
                                <ModalBody>
                                    <RenderI18nForm field={'name'}>
                                        {({ fieldName, fieldLabel }) => {
                                            return (
                                                <Row className='mb-3' key={fieldName}>
                                                    <Col md='12'>
                                                        <FormGroup>
                                                            <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                            <FormikInputText id={fieldName} name={fieldName} trim />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            )
                                        }}
                                    </RenderI18nForm>
                                    <Row>
                                        <Col md='12'>
                                            <FormGroup>
                                                <Label for='status' className='text-muted'><Translate id='organization.settings.organizationTeamTravelTypes.add.status' /> <Required /></Label>
                                                <FormikSelect
                                                    id='status_TeamTravelTypesAddModal'
                                                    name='status'
                                                    search={false}
                                                    loadingStatus='success'
                                                    defaultData={[
                                                        { id: 'active', label: 'organization.settings.organizationTeamTravelTypes.add.status.active', translateLabel: true, value: 'active', selected: formik.values.status === 'active' },
                                                        { id: 'inactive', label: 'organization.settings.organizationTeamTravelTypes.add.status.inactive', translateLabel: true, value: 'inactive', selected: formik.values.status === 'inactive' },
                                                    ]}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type='button' outline onClick={props.toggleAddTravelType} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    );
}

export default TeamTravelTypesAddModal;