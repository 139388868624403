import { FormikError } from '@spordle/formik-elements';
import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { Button, Collapse, Fade, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import CustomAlert from '../../../../../../components/CustomAlert';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

const MemberQualificationChoice = ({ isOpen, toggle, member, back, next, memberQualification, removeQualification }) => {
    const membersContext = useContext(MembersContext);

    return (
        <Formik
            initialValues={{
                choice: 'TRANSFER',
            }}
            validationSchema={object().shape({
                choice: string().required(<Translate id='members.profile.qualifications.sidePanel.trasnferShareModal.choice.required' />),
            })}
            onSubmit={({ choice }, { setStatus }) => {
                if(choice === 'TRANSFER'){
                    return membersContext.moveQualificationTransfer(memberQualification.member.member_id, memberQualification.member_qualification_id, { destination_member_id: member.member_id })
                        .then(() => { next(choice); })
                        .then(removeQualification)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            }
                        })
                }
                return membersContext.moveQualificationShare(memberQualification.member.member_id, memberQualification.member_qualification_id, { destination_member_id: member.member_id })
                    .then(() => { next(choice); })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                        }
                    });
            }}
        >
            {(formik) => (
                <Collapse isOpen={isOpen}>
                    <Fade in={isOpen}>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <Form>
                                <ModalHeader toggle={toggle}>
                                    <Translate id='members.profile.qualifications.sidePanel.trasnferShareModal.title' />
                                </ModalHeader>
                                <ModalBody>
                                    <UserDisplay
                                        block card hover
                                        className={stringBuilder('mb-3', { 'border-primary shadow-primary': formik.values.choice === 'TRANSFER' })}
                                        onClick={() => formik.setFieldValue('choice', 'TRANSFER')}
                                    >
                                        <UserDisplay.Container className="mr-3">
                                            <div
                                                style={{ height: 50, width: 50 }}
                                                className="border bg-light rounded-circle d-flex align-items-center justify-content-center"
                                            >
                                                <i className="fas fa-exchange-alt" />
                                            </div>
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title className="font-bold"><Translate id='members.profile.qualifications.sidePanel.trasnferShareModal.choice.transfer.title' /></UserDisplay.Title>
                                            <UserDisplay.Subtitle><Translate id='members.profile.qualifications.sidePanel.trasnferShareModal.choice.transfer.subtitle' /></UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                    </UserDisplay>

                                    <UserDisplay
                                        block card hover
                                        className={stringBuilder('mb-3', { 'border-primary shadow-primary': formik.values.choice === 'SHARE' })}
                                        onClick={() => formik.setFieldValue('choice', 'SHARE')}
                                    >
                                        <UserDisplay.Container className="mr-3">
                                            <div
                                                style={{ height: 50, width: 50 }}
                                                className="border bg-light rounded-circle d-flex align-items-center justify-content-center"
                                            >
                                                <i className="fas fa-share-alt" />
                                            </div>
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title className="font-bold"><Translate id='members.profile.qualifications.sidePanel.trasnferShareModal.choice.share.title' /></UserDisplay.Title>
                                            <UserDisplay.Subtitle><Translate id='members.profile.qualifications.sidePanel.trasnferShareModal.choice.share.subtitle' /></UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                    </UserDisplay>

                                    <FormikError name='choice'>
                                        {(error) => (
                                            <CustomAlert color='danger' text={error} translateText={false} />
                                        )}
                                    </FormikError>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" type="button" onClick={back} disabled={formik.isSubmitting} outline className='mr-auto'><Translate id='misc.back' /></Button>
                                    <Button color="primary" type="submit" disabled={formik.isSubmitting}><Translate id='misc.confirm' /></Button>
                                </ModalFooter>
                            </Form>
                        </OverlayLoader>
                    </Fade>
                </Collapse>
            )}
        </Formik>
    )
}

export default MemberQualificationChoice
