export default{
    // Review
    "clinics.clinicCommunications.formClinicCommunications.review.title"         : "Modifier les informations de communication",
    "clinics.clinicCommunications.formClinicCommunications.review.saveChanges"   : "Sauvegarder les changements",
    "clinics.clinicCommunications.formClinicCommunications.review.cancelChanges" : "Annuler les changements",

    // registration
    "clinics.clinicCommunications.formClinicCommunications.registration.title"                  : "Confirmation d'inscription",
    "clinics.clinicCommunications.formClinicCommunications.registration.message"                : "Message",
    "clinics.clinicCommunications.formClinicCommunications.registration.message.required"       : "Un message est requis",
    "clinics.clinicCommunications.formClinicCommunications.registration.attachments"            : "Pièce(s) jointe(s)",
    "clinics.clinicCommunications.formClinicCommunications.registration.attachments.comingSoon" : "Module en développement",

    // confirmation
    "clinics.clinicCommunications.formClinicCommunications.confirmation.title"            : "Courriel de confirmation",
    "clinics.clinicCommunications.formClinicCommunications.confirmation.message"          : "Message",
    "clinics.clinicCommunications.formClinicCommunications.confirmation.message.required" : "Un message est requis",
    "clinics.clinicCommunications.formClinicCommunications.confirmation.attachments"      : "Pièce(s) jointe(s)",

    // forms
    "clinics.clinicCommunications.formClinicCommunications.custom_forms.title"   : "Questionnaire",
    "clinics.clinicCommunications.formClinicCommunications.custom_forms.message" : "Associer un questionnaire à un stage",
    "clinics.clinicCommunications.formClinicCommunications.terms.title"          : "Conditions de paiement",
    "clinics.clinicCommunications.formClinicCommunications.terms.required"       : "Veuillez sélectionner un terme et condition",
    "clinics.clinicCommunications.formClinicCommunications.waivers.title"        : "Dispenses",
    "clinics.clinicCommunications.formClinicCommunications.waivers.textWhen"     : "{count} dispenses sélectionnées",
}