import CreateOnlineStore from "../../views/onlineStore/create/CreateOnlineStore";
import OnlineStoreProfile from "../../views/onlineStore/profile/OnlineStoreProfile";
import InvalidOnlineStoreProfileId from '../../views/onlineStore/profile/InvalidOnlineStoreProfileId';
import onlineStoreTabsRoutes from "../tabRoutes/onlineStoreTabsRoutes";
import OnlineStoreSearch from "../../views/onlineStore/search/OnlineStoreSearch";

const onlineStoreRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        exact: true,
        name: 'sidebar.onlineStore',
        path: '/onlineregistration',
        permissions: { code: 'page', permissionCode: 'online_store_manage' },
        component: OnlineStoreSearch,
    },
    {
        exact: true,
        name: 'sidebar.onlineStore.create',
        path: '/onlineregistration/create',
        skipRecents: true,
        permissions: { code: 'page', permissionCode: 'online_store_manage', actions: [ 'ADD' ] },
        component: CreateOnlineStore,
    },
    {
        name: 'sidebar.onlineStore.profile',
        path: '/onlineregistration/profile/:onlineStoreId',
        permissions: { code: 'page', permissionCode: 'online_store_manage' },
        skipRecents: true,
        subPermissionsRoutes: onlineStoreTabsRoutes,
        component: OnlineStoreProfile,
    },
    {
        exact: true,
        skipRecents: true,
        path: '/onlineregistration/404',
        permissions: { code: 'page' },
        component: InvalidOnlineStoreProfileId,
    },
];
export default onlineStoreRoutes;
