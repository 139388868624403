import Translate from '@spordle/intl-elements';
import React from 'react';
import { object } from 'yup';
import {
    Row,
    Col,
    Button,
    ModalBody,
    Label,
    Collapse,
    Alert,
    ModalFooter
} from "reactstrap";
import { Form, Formik } from 'formik';

// Contexts
import withContexts from '../../../../../helpers/withContexts';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import FileUpload from '../../../../../components/uploader/fileUpload/FileUpload';

/**
 * IMPORTANT
 * Wireframe only / feature flag environment testing
*/
class MemberImportForm1 extends React.Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){
        return (
            <Formik
                initialValues={{
                }}
                validationSchema={object().shape({
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalBody>
                            <Row form>
                                <Col sm="12" className="mb-3">
                                    <Label><Translate id='form' defaultMessage='Import a list of members from an excel file. The file must be compatible with this tool, you may download a template file to follow.' /></Label>
                                    <button type='button' className='reset-btn btn-link text-primary font-medium'><i className='mdi mdi-upload' />Download template file</button>
                                </Col>
                                <Col sm="12" className="mb-3">
                                    <Label for=""><Translate id='form.fields.excel' defaultMessage='Upload an excel file' /></Label>
                                    <FileUpload
                                        defaultFiles={[]}
                                        onFileSelect={(files) => {
                                            formik.setFieldValue('attachments', files);
                                        }}
                                        dropzoneProps={{
                                            multiple: true,
                                            accept: ".xls, .xlsx",
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Collapse mountOnEnter unmountOnExit isOpen={!!formik.status}>
                                <Alert color='danger'>
                                    {formik.status}
                                </Alert>
                            </Collapse>
                        </ModalBody>
                        <ModalFooter className='bg-white'>
                            <Button type="submit" color="primary" disabled={formik.isSubmitting}><Translate id='misc.search' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        );
    }
}


export default withContexts(OrganizationContext, MembersContext, IdentityRolesContext)(MemberImportForm1);