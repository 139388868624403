import { Container } from "reactstrap";
import settingsPhoneTypesRoutes from "../../../../../routes/tabRoutes/organizationSettings/settingsPhoneTypesRoutes";
import SubRouteSettingWrapper from "../../../components/SubRouteSettingWrapper";

const OrganizationSettingsPhoneTypes = () => {

    return (
        <Container>
            <SubRouteSettingWrapper
                back="organization"
                routes={settingsPhoneTypesRoutes}
            />
        </Container>
    );
}

export default OrganizationSettingsPhoneTypes