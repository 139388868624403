export default{
    "members.profile.overview.sidePanelMemberMedicalNotes.title"               : "Medical notes",
    "members.profile.overview.sidePanelMemberMedicalNotes.remove"              : "Remove",
    "members.profile.overview.sidePanelMemberMedicalNotes.remove.swal.success" : "Medical note removed!",

    "members.profile.overview.sidePanelMemberMedicalNotes.type"                   : "Type",
    "members.profile.overview.sidePanelMemberMedicalNotes.type.swal.success"      : "Type updated!",
    "members.profile.overview.sidePanelMemberMedicalNotes.severity"               : "Severity",
    "members.profile.overview.sidePanelMemberMedicalNotes.severity.swal.success"  : "Severity updated!",
    "members.profile.overview.sidePanelMemberMedicalNotes.emergency"              : "Emergency action",
    "members.profile.overview.sidePanelMemberMedicalNotes.emergency.swal.success" : "Emergency action updated!",
    "members.profile.overview.sidePanelMemberMedicalNotes.notes"                  : "Notes",
    "members.profile.overview.sidePanelMemberMedicalNotes.notes.swal.success"     : "Notes updated!",

    // validation
    "members.profile.overview.sidePanelMemberMedicalNotes.type.validation"      : "Type is required",
    "members.profile.overview.sidePanelMemberMedicalNotes.severity.validation"  : "Severity is required",
    "members.profile.overview.sidePanelMemberMedicalNotes.emergency.validation" : "Emergency action is required",
}