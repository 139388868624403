import InlineCopy from "../../../components/inlineCopy/InlineCopy";
import IsGod from "../../../components/permissions/IsGod";

const SettingCode = ({ code }) => {
    if(!code){
        return null;
    }

    return (
        <IsGod tooltipOff tag="small" className="text-purple">
            Setting: <InlineCopy toCopy={code}>{code}</InlineCopy>
        </IsGod>
    );
}

export default SettingCode;