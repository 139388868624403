const DisplayClinicAttendeeGrade = ({ attendee, hideMemo, ...props }) => {
    if(!attendee){
        return null;
    }

    return (
        <>
            <div {...props}>{attendee.grade ? attendee.grade : "-"}</div>
            {!hideMemo && attendee.grade_memo && <small>{attendee.grade_memo}</small>}
        </>
    );
}

export default DisplayClinicAttendeeGrade;