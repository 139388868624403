import { useContext, useRef, useState } from 'react';
import { RolesContext } from '../../contexts/RolesContext';
import SidePanelOrganizationRolesPermission from '../../views/settings/platform/roles/SidePanelOrganizationRolesPermission';
import PermissionsModal from '../../views/settings/platform/roles/modals/RolePermissions';
import SidePanel from '../sidePanel/SidePanel';
import Translate from '@spordle/intl-elements';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import OverlayLoader from '../loading/OverlayLoader';
import { stringifyUrl } from 'query-string';

/**
 * @param {Object} props
 * @param {function} props.toggle Function called to close sidepanel
 * @returns {JSX.Element}
 */
const ManageRolePermissions = ({ closeSidePanel }) => {
    const rolesContext = useContext(RolesContext);
    const organizationContext = useContext(OrganizationContext);

    const isSystemRole = rolesContext.is_system === "1";
    const isSharedRole = rolesContext.is_shared === "1";
    const isActiveRole = rolesContext.active === "1";
    const rolePermissionRef = useRef(null);

    const { data, isValidating } = useSWR(
        [ organizationContext.organisation_id, 'ManageRolePermissions' ],
        () => rolesContext.getOrganizationRoles(organizationContext.organisation_id),
    )

    const [ permissionsManagement, setPermissionsManagement ] = useState({
        showPermissionsModal: false,
        searchQuery: "",
    });

    /**
     * @param {boolean} permission
     * @param {string} [searchQuery=""]
     */
    const openPermissionsModal = (permission, searchQuery = "") => {
        setPermissionsManagement(() => ({ showPermissionsModal: permission, searchQuery: searchQuery }));
    }

    const closePermissionsModal = () => {
        setPermissionsManagement((prev) => ({ ...prev, showPermissionsModal: false }))
    }

    const handleUpdateComponents = () => {
        rolePermissionRef.current?.fetchRolesPermissions(true);
    }

    return (
        <OverlayLoader isLoading={isValidating}>
            <SidePanel.Header>
                <div className='d-flex justify-content-between'>
                    <button className='reset-btn p-2 text-dark mr-auto' onClick={closeSidePanel} type="button">
                        <i className="mdi mdi-arrow-right font-20" />
                    </button>
                    <Link
                        to={stringifyUrl({
                            url: '/settings/platform/roles',
                            query: {
                                search: `="${rolesContext.title}"`,
                            },
                        })}
                        onClick={closeSidePanel}
                    >
                        <Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.seeSettings' /><i className='mdi mdi-chevron-right ml-1' />
                    </Link>
                </div>
                <SidePanel.Title>
                    {rolesContext.title}
                </SidePanel.Title>
                <SidePanel.Subtitle>
                    {rolesContext.description || "-"}
                </SidePanel.Subtitle>
                {isSystemRole && <SidePanel.Subtitle className='text-warning'><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.isSytem' /></SidePanel.Subtitle>}
                {isSharedRole && <SidePanel.Subtitle className='text-warning'><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.isShared' /></SidePanel.Subtitle>}
                {!isActiveRole && <SidePanel.Subtitle className="text-warning"><i className='mdi mdi-alert-outline mr-1' /><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.isInactive' /></SidePanel.Subtitle>}
            </SidePanel.Header>
            <PermissionsModal
                permission={permissionsManagement.showPermissionsModal}
                roleId={rolesContext.role_id}
                rolesList={data || []}
                toggle={closePermissionsModal}
                updateComponents={handleUpdateComponents}
                search={permissionsManagement.searchQuery}
                fromGlobalModule={!(data || []).some((role) => role.role_id === rolesContext.role_id)}
            />
            <SidePanelOrganizationRolesPermission
                key={rolesContext.role_id}
                roleIds={[ rolesContext.role_id ]}
                openPermissionsModal={openPermissionsModal}
                ref={rolePermissionRef}
            />
        </OverlayLoader>
    )
}

export default ManageRolePermissions;