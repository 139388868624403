export default{
    "helpCenter.resources.title"      : "Ressources",
    "helpCenter.editModal.title"      : "Modifier le centre d'aide",
    "helpCenter.edit.center"          : "Modifier centre d'aide",
    "helpCenter.editAlertModal.title" : "Modifier une alerte",
    "helpCenter.editModal.add.alert"  : "Ajouter une alerte",

    // help center form
    "help_center.editModal.video.id.tooltip"    : "L'ID de vidéo est l'identifiant d'une vidéo YouTube. Il peut être trouvé en regardant l'URL d'une page vidéo et en copiant la partie de l'URL trouvée après 'watch?v='. Un exemple d'id de vidéo valide est 'W85oD8FEF78'.",
    "help_center.editModal.custom.link.tooltip" : "Un lien personnalisé apparaît sous la vidéo, transformant le titre et le contenu en un bouton qui redirigera vers l'URL entrée.",
    "helpCenter.editModal.and.or"               : "Et / Ou",

    //help center form validation
    "help_center.editModal.validation.title.when.custom_link"            : "Un titre est requis lorsque vous utilisez un lien personnalisé.",
    "help_center.editModal.validation.title.when.content"                : "Un titre est requis lors de la saisie du contenu.",
    "help_center.editModal.validation.content.when.custom_link"          : "Le contenu est requis lorsque vous utilisez un lien personnalisé.",
    "help_center.editModal.validation.content.when.title"                : "Le contenu est requis lors de la saisie d'un titre.",
    "help_center.editModal.validation.custom_url"                        : "Une URL personnalisée doit être une URL valide. Exemple:'https://www.spordle.com'.",
    "help_center.editModal.validation.video.id.required.when.video.link" : "Un identifiant vidéo est requis lors de la saisie d'un lien vidéo.",
    "help_center.editModal.validation.video.link.required.when.video.id" : "Un lien vidéo est requis lors de la saisie d'un identifiant vidéo.",
    "help_center.editModal.validation.video.link.required.when.no.title" : "Un lien vidéo et un identifiant sont requis si vous n'entrez pas un titre et un contenu.",
    "help_center.editModal.validation.title.required.when.no.video.link" : "Un titre et un contenu sont requis si vous n'entrez pas un lien vidéo et un identifiant vidéo.",
    "help_center.editModal.validation.video.link.valid.url"              : "Un lien vidéo doit être une URL valide. Exemple: 'https://www.youtube.com/watch?v=w85od8fef78'.",

    // help center alert form validation
    "help_center.alert.editModal.validation.title.required"       : "Un titre est requis.",
    "help_center.alert.editModal.validation.content.required"     : "Le contenu est requis.",
    "help_center.alert.editModal.validation.type.required"        : "Le type d'alerte est requis.",
    "help_center.alert.editModal.validation.display.for.required" : "Une organisation à qui afficher l'alerte est requise.",

    // Help center additional (alert) form fields
    "help_center_additional.editModal.alert.section.title"   : "Alertes de centre d'aide",
    "help_center_additional.editModal.alert.color"           : "Type d'alerte",
    "help_center_additional.editModal.alert.organisation_id" : "Afficher l'alerte pour:",

    // colors
    "helpCenter.editModal.alert.color.0" : "Danger (rouge)",
    "helpCenter.editModal.alert.color.1" : "Avertissement (jaune)",
    "helpCenter.editModal.alert.color.2" : "Succès (vert)",
    "helpCenter.editModal.alert.color.3" : "Info (bleu)",

    // delete confirmation in modals
    "helpCenter.deleteConfirmation.tooltip.title"          : "Réinitialiser le centre d'aide.",
    "helpCenter.deleteConfirmation.tooltip.text"           : "Avertissement, cette action est irréversible!",
    "helpCenter.deleteConfirmation.delete.if.submit.empty" : "Veuillez utiliser la fonction pour réinitialiser.",

    // help center form
    "help.center.form.field.i18n.en.title"       : "Titre (EN)",
    "help.center.form.field.i18n.fr.title"       : "Titre (FR)",
    "help.center.form.field.i18n.en.content"     : "Contenu (EN)",
    "help.center.form.field.i18n.fr.content"     : "Contenu (FR)",
    "help.center.form.field.i18n.en.custom_link" : "Lien personnalisé (EN)",
    "help.center.form.field.i18n.fr.custom_link" : "Lien personnalisé (FR)",
    "help.center.form.field.i18n.en.video_link"  : "Lien vidéo (EN)",
    "help.center.form.field.i18n.fr.video_link"  : "Lien vidéo (FR)",
    "help.center.form.field.i18n.en.video_id"    : "ID vidéo (EN)",
    "help.center.form.field.i18n.fr.video_id"    : "ID vidéo (FR)",

}