import Translate from "@spordle/intl-elements"
import { Button, ModalBody, ModalFooter } from "reactstrap"

const RolloverModalWarning = ({ onSubmit, currentSeasonInstallments }) => {

    return (
        <>
            <ModalBody className="text-center">
                <i style={{ fontSize: 75 }} className='mdi mdi-information-outline text-warning' />
                <div className="h4"><Translate id='catalog.installment.rollover.warning' values={{ amount: currentSeasonInstallments?.length }} /></div>
            </ModalBody>
            <ModalFooter>
                <Button id="rollover-modal-warning-next" color='primary' type='button' onClick={onSubmit}><Translate id='misc.next' /></Button>
            </ModalFooter>
        </>
    )
}

export default RolloverModalWarning