import addOrganizationVenuesModalSelect from "./addOrganizationVenuesModalSelect";
import createVenueStep1 from "./createVenueStep1";
import createVenueStep2 from "./createVenueStep2";
import createVenueStep2Group from "./createVenueStep2Group";

export default{
    ...addOrganizationVenuesModalSelect,
    ...createVenueStep1,
    ...createVenueStep2,
    ...createVenueStep2Group,
}