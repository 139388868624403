import { Tooltip } from '@mantine/core';
import { FormattedMessage } from 'react-intl';
import { Collapse } from 'reactstrap';
/**
 * @description Options that will show in each group vertically aligned on the right
 * @param {object} props
 * @param {function} props.addQuestion
 * @param {function} props.addSection
 * @param {function} props.clone
 * @param {function} props.delete
 */
const VerticalOptions = (props) => {
    return (
        <Collapse isOpen>
            <div className="d-flex flex-column align-items-center justify-content-between pl-3">
                <Collapse isOpen={!!props.addQuestion}>
                    <FormattedMessage id='catalog.forms.components.formEditor.verticalOptions.addQuestion'>
                        {(message) => (
                            <Tooltip withArrow label={message}>
                                <button className="btn option m-1" type='button' onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.addQuestion(); }}>
                                    <i className="far fa-plus-square" />
                                </button>
                            </Tooltip>

                        )}
                    </FormattedMessage>
                </Collapse>

                <Collapse isOpen={!!props.addSection}>
                    <FormattedMessage id='catalog.forms.components.formEditor.verticalOptions.addSection'>
                        {(message) => (
                            <Tooltip withArrow label={message}>
                                <button className="btn option m-1" type='button' onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.addSection() }}>
                                    <div className="wi-rotate-90 d-flex justify-content-center align-items-center">
                                        <i className="ti-layout-sidebar-left" />
                                    </div>
                                </button>
                            </Tooltip>
                        )}
                    </FormattedMessage>
                </Collapse>

                <Collapse isOpen={!!props.clone}>
                    <FormattedMessage id='catalog.forms.components.formEditor.verticalOptions.clone'>
                        {(message) => (
                            <Tooltip withArrow label={message}>
                                <button className="btn option m-1" type='button' onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.clone() }}>
                                    <i className="far fa-clone" />
                                </button>
                            </Tooltip>

                        )}
                    </FormattedMessage>
                </Collapse>

                <Collapse mountOnEnter unmountOnExit isOpen={!!props.delete}>
                    <FormattedMessage id='misc.delete'>
                        {(message) => (
                            <Tooltip withArrow label={message}>
                                <button className="btn option m-1 text-danger" type='button' onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.delete(); }}>
                                    <i className="ti-trash" />
                                </button>
                            </Tooltip>
                        )}
                    </FormattedMessage>
                </Collapse>
            </div>
        </Collapse>
    );
}

export default VerticalOptions;