import Translate from '@spordle/intl-elements';
import { Button, Collapse, Fade, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const MemberQualificationConfirm = ({ toggle, isOpen, qualificationMove }) => {
    return (
        <Collapse isOpen={isOpen}>
            <Fade in={isOpen}>
                <ModalHeader toggle={toggle}>
                    <Translate id='members.profile.qualifications.sidePanel.trasnferShareModal.title' />
                </ModalHeader>
                <ModalBody className='text-center'>
                    <div>
                        <i className='mdi mdi-check-circle text-success' style={{ fontSize: 75 }} />
                    </div>
                    <div className='font-bold h4'>
                        <Translate id='members.profile.qualifications.sidePanel.trasnferShareModal.confirm' values={{ mode: qualificationMove }} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="button" onClick={toggle}><Translate id='misc.close' /></Button>
                </ModalFooter>
            </Fade>
        </Collapse>
    )
}

export default MemberQualificationConfirm
