import { useEffect, useRef } from "react";

export function usePrevious(fn, value){
    const previous = useRef(value);

    useEffect(() => {
        if(previous.current){
            fn(previous.current);
        }
        // Finally update previousProps with current props for next hook call
        previous.current = value;
    }, [ value ]);
}