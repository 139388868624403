import { FormikInputNumber, FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Button, Col, Fade, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { object, string } from "yup";
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { PeriodsContext } from "../../../../contexts/contexts";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { RenderI18nForm } from "../../../../helpers/i18nHelper";

const AddOrganizationActivityPeriods = ({ i18nHelper, addPeriodModalOpen, toggleAddPeriod, spordleTableRef }) => {
    const periodsContext = useContext(PeriodsContext)
    const organizationContext = useContext(OrganizationContext)

    const [ modalLoading, setModalLoading ] = useState('success')
    const [ errorCode, setErrorCode ] = useState('success')

    return (
        <AnalyticsModal isOpen={addPeriodModalOpen} toggle={toggleAddPeriod} analyticsName='OrganizationActivityPeriods'>
            <Formik
                initialValues={{
                    ...i18nHelper.getInitialValues(),
                    status: 'active',
                    display_order: 1,
                    exclude_affiliation_fee: "0",
                }}
                validationSchema={object().shape({
                    ...i18nHelper.getValidationSchema({
                        name: string().required(<Translate id='form.validation.name.required' />),
                        description: string().required(<Translate id='form.validation.description.required' />),
                    }),
                    display_order: string().required(<Translate id='organization.settings.sidePanelOrganizationPeriods.display_order.required' />),
                    status: string().required(<Translate id='organization.settings.organizationPeriods.add.status.required' />),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setModalLoading('lazy');
                    const apiValues = { ...values, ...i18nHelper.getAPIValues(values), active: values.status === 'active' };
                    delete apiValues.i18n;


                    periodsContext.createActivityPeriod(organizationContext.organisation_id, { ...apiValues, active: values.status === 'active' })
                        .then((data) => {
                            spordleTableRef.current?.tableRef?.refreshTable()
                            setModalLoading('success');
                            toggleAddPeriod();
                        })
                        .catch((error) => {
                            setErrorCode(`contexts.periodsContext.errors.${error.message}`);
                            console.error(error.message);
                            setModalLoading('error');

                        })
                        .finally(() => {
                            setSubmitting(false)
                        })
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            {modalLoading === 'lazy' ?
                                <Fade in={modalLoading === 'lazy'}>
                                    <div className='d-flex justify-content-center align-items-center p-5'>
                                        <Spinner type='grow' color='primary' />
                                    </div>
                                </Fade>
                                : null}
                            {modalLoading !== 'lazy' ?
                                <Fade in={modalLoading !== 'lazy'}>
                                    <ModalHeader toggle={toggleAddPeriod}><Translate id='organization.settings.organizationPeriods.add.title' /></ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col md='12'>
                                                <FormGroup>
                                                    <Label for='status' className='text-muted'><Translate id='organization.settings.organizationPeriods.add.status' /></Label>
                                                    <FormikSelect
                                                        id='status' name='status'
                                                        defaultData={[
                                                            { value: 'active', label: 'organization.settings.organizationPeriods.add.status.active', translateLabel: true },
                                                            { value: 'inactive', label: 'organization.settings.organizationPeriods.add.status.inactive', translateLabel: true },
                                                        ]}
                                                        loadingStatus='success'
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <RenderI18nForm field="name">
                                                {({ fieldName, fieldLabel }) => (
                                                    <Col md='12'>
                                                        <FormGroup>
                                                            <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                            <FormikInputText id={fieldName} name={fieldName} trim />
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                            </RenderI18nForm>
                                            <RenderI18nForm field="description">
                                                {({ fieldName, fieldLabel }) => (
                                                    <Col md='12'>
                                                        <FormGroup>
                                                            <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                            <FormikInputText id={fieldName} name={fieldName} trim />
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                            </RenderI18nForm>
                                        </Row>
                                        <Row>
                                            <Col md='6'>
                                                <FormGroup>
                                                    <Label for={'display_order'} className='text-muted'><Translate id='organization.settings.organizationPeriods.table.display_order' /></Label>
                                                    <FormikInputNumber allowNegative={false} allowedDecimalSeparators={false} allowLeadingZeros={false} id={'display_order'} name={'display_order'} />
                                                </FormGroup>
                                            </Col>
                                            <Col md='6'>
                                                <FormGroup>
                                                    <Label for='exclude_affiliation_fee' className='text-muted'><Translate id='form.fields.excludeAffiliationFee' /></Label>
                                                    <FormikSelect
                                                        id='exclude_affiliation_fee' name='exclude_affiliation_fee'
                                                        defaultData={[
                                                            { value: '1', label: 'misc.yes', translateLabel: true },
                                                            { value: '0', label: 'misc.no', translateLabel: true },
                                                        ]}
                                                        loadingStatus='success'
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {modalLoading === 'error' ?
                                            <div className='alert alert-danger'>{errorCode === '' ? <Translate id='misc.error' /> : <Translate id={errorCode} defaultMessageId='misc.error' />}</div>
                                            : null}
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                        <Button color='primary' type='button' outline onClick={toggleAddPeriod} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                    </ModalFooter>
                                </Fade>
                                : null}
                        </Form>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    )
}

export default AddOrganizationActivityPeriods