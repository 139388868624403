import { FormikSelect, FormikSwitch } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { Button, Collapse, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { array, bool, object } from "yup";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import IsAdmin from "../../../../../components/permissions/IsAdmin";
import { RolesContext } from "../../../../../contexts/RolesContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { isVisibleInMenu } from "./RolePermissions";
import CustomAlert from "../../../../../components/CustomAlert";
import { showWarning } from "./RoleListItem";
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy";

const ApplyPermissionsRoles = ({
    allPermissions,
    selectedPermission,
    mainPermission,
    roleId,
    rolesList,
    removeSelectedPermissionId,
    ...props
}) => {
    const isInMenu = isVisibleInMenu(selectedPermission ? allPermissions.find((p) => p.component_permission_id === selectedPermission.id) : {});
    const roleContext = useContext(RolesContext);

    const handleOnPrevious = () => {
        removeSelectedPermissionId();
    }

    const formatValuesForApi = (component, newValues) => {
        return component?.component_permissions
            .reduce((newformattedValues, cp) => {
                if(cp.component_permission_id === selectedPermission.id){
                    // Is the permission we want to bulk update
                    if(isVisibleInMenu(cp)){ // Visible in menu: newValues will be boolean
                        newformattedValues.push({
                            key: cp.component_permission_id,
                            value: [ {
                                action: 'READ',
                                role_component_permission_id: cp.role_component_permissions[0]?.role_component_permission_id,
                                active: newValues,
                            } ],
                        });
                    }else{ // NOT visible in menu: newValues will be array
                        const mappedValues = newValues.map((newValue) => {
                            if(typeof newValue === 'string'){ // Needs formatting
                                const [ action ] = newValue.split('---');
                                const rcp = cp.role_component_permissions.find((rcp) => rcp.action === action);
                                return {
                                    action: action,
                                    role_component_permission_id: rcp?.role_component_permission_id,
                                    active: true, // Always true here since these represent the selected values from the select
                                }
                            }
                            return newValue;// Is already formatted -> Is the initial object a.k.a it didn't change
                        });
                        // Adding missing ones
                        mappedValues.pushArray(
                            cp.role_component_permissions.reduce((missingRCPs, rcp) => {
                                const isMissing = !mappedValues.some((v) => v.action === rcp.action);
                                if(isMissing){
                                    missingRCPs.push({
                                        action: rcp.action,
                                        role_component_permission_id: rcp.role_component_permission_id,
                                        active: false, // Always false here since it isMissing from the select
                                    })
                                }
                                return missingRCPs;
                            }, []),
                        );
                        newformattedValues.push({
                            key: cp.component_permission_id,
                            value: mappedValues,
                        })
                    }
                }else{
                    // Is NOT the permission we want to bulk update -> Simply format the values to be compatible with the PUT call
                    newformattedValues.push({
                        key: cp.component_permission_id,
                        value: cp.role_component_permissions.map((rcp) => ({
                            action: rcp.action,
                            role_component_permission_id: rcp.role_component_permission_id,
                            active: rcp.active == '1',
                        })),
                    });
                }
                return newformattedValues;
            }, []) || [];
    }

    const updateOtherRoleId = async(roleId, newValues) => {
        const { components } = await roleContext.getRolesPermissions(roleId);
        const formattedValues = formatValuesForApi(components.find((component) => component.component_id === mainPermission.component_id), newValues);
        return roleContext.updateComponentsPermissions(roleId, formattedValues);
    }

    return (
        <Formik
            initialValues={{
                values: selectedPermission.formikValue,
                otherRoles: [],
            }}
            validationSchema={object().shape({
                otherRoles: array().min(1, <Translate id='form.required' />),
                values: isInMenu ? bool() : array(),
            })}
            onSubmit={async({ otherRoles, values }, { setStatus }) => {
                setStatus();
                return Promise.all([
                    roleContext.updateComponentsPermissions(roleId, formatValuesForApi(mainPermission, values)),
                    ...otherRoles.map((otherRoleId) => updateOtherRoleId(otherRoleId, values)),
                ])
                    .then(props.updateComponents)
                    .then(props.toggle)
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            setStatus(<DisplayI18n field="message" defaultValue={error.message} i18n={error.i18n} />);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={props.toggle}>
                            <Translate id='organization.settings.roles.modals.title' values={{ permissionName: selectedPermission.title }} />
                        </ModalHeader>
                        <ModalBody>
                            <div className="h5 font-bold"><Translate id='organization.settings.roles.modals.applyPermission.title' /></div>
                            <IsAdmin className='small text-monospace'>
                                <InlineCopy toCopy={selectedPermission.code}>
                                    code: {selectedPermission.code}
                                </InlineCopy>
                            </IsAdmin>
                            <div className={stringBuilder('mb-3', { 'd-flex': isInMenu })}>
                                <div>{selectedPermission.description}</div>
                                {isInMenu &&
                                    <div className='d-flex ml-auto align-items-center p-1'>
                                        <FormikSwitch name="values" id="values" />
                                    </div>
                                }
                            </div>
                            {!isInMenu &&
                                <div className="form-group">
                                    <Label className="text-muted" for="values"><Translate id='organization.settings.roles.modals.applyPermission.label.autorisations' /></Label>
                                    <FormikSelect
                                        id="values"
                                        name="values"
                                        clearable multi search={false}
                                        placeholder='organization.settings.roles.modals.select.placeholder'
                                        values={formik.values.values ? formik.values.values.reduce((newArray, permission) => {
                                            if(typeof permission === 'string'){
                                                newArray.push(permission);
                                            }else if(permission.active){
                                                newArray.push(`${permission.action}${permission?.role_component_permission_id ? `---${permission?.role_component_permission_id}` : ''}`)
                                            }
                                            return newArray;
                                        }, []) : []}
                                        defaultData={selectedPermission.action.split(',').map((selectedPermissionAction) => {
                                            /**
                                            * This function will append the value with the role_component_permission_id if the role already exsit.
                                            * This way, we can use this role_component_permission_id when we update the existing component_permission
                                            * @returns {{value: string, label: string, translateLabel: boolean}}
                                            */
                                            const getOption = (action) => {
                                                const permissionValue = selectedPermission.role_component_permissions?.find((initialValue) => initialValue.action === action);
                                                return {
                                                    label: 'organization.settings.roles.modals.select.option.' + action,
                                                    translateLabel: true,
                                                    value: `${action}${permissionValue?.role_component_permission_id ? `---${permissionValue?.role_component_permission_id}` : ''}`,
                                                }
                                            }
                                            switch (selectedPermissionAction){
                                                case 'READ':
                                                case 'ADD':
                                                case 'EDIT':
                                                case 'EXPORT':
                                                case 'IMPORT':
                                                case 'DELETE':
                                                    return getOption(selectedPermissionAction)
                                                default:
                                                    break;
                                            }
                                        })}
                                        loadingStatus='success'
                                    />
                                    {/* Show a warning text if there is selected roles without the READ permission */}
                                    <Collapse isOpen={showWarning(formik.values.values)} className='small mt-1 text-warning' mountOnEnter unmountOnExit>
                                        <i className='mdi mdi-alert-outline mr-1' />
                                        <Translate id='organization.settings.roles.modals.warning.availability' />
                                    </Collapse>
                                </div>
                            }
                            <Label className="text-muted" for="otherRoles"><Translate id='organization.settings.roles.modals.applyPermission.label.otherRoles' /></Label>
                            <FormikSelect
                                id="otherRoles"
                                name="otherRoles"
                                renderOption={({ option, isDisabled }) => (
                                    <>
                                        {option.label}
                                        {isDisabled &&
                                            <div className="text-muted small"><Translate id='organization.settings.roles.modals.applyPermission.otherRoles.disabled' /></div>
                                        }
                                    </>
                                )}
                                clearable
                                multi
                                isOptionDisabled={({ value }) => (roleId || "").includes(value)}
                                loadData={(from) => {
                                    switch (from){
                                        case 'CDM':
                                            return Promise.resolve(
                                                rolesList ?
                                                    rolesList.map((role) => (
                                                        {
                                                            ...role,
                                                            value: role.role_id,
                                                            label: role.title,
                                                        }
                                                    )) : [],
                                            );
                                        default:
                                            break;
                                    }
                                }}
                            />
                            <div className="bg-light mt-3 p-2 rounded d-flex">
                                <div className="d-flex align-items-center mr-1">
                                    <i className="mr-1 mdi mdi-information-outline text-primary" />
                                </div>
                                <div className="font-medium text-dark">
                                    <Translate id='organization.settings.roles.modals.applyPermission.helperText' values={{ currentRole: rolesList.find((role) => role.role_id === roleId)?.title }} />
                                </div>
                            </div>
                            <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                <CustomAlert
                                    color="danger"
                                    className="mt-3"
                                    text={formik.status || <Translate id='misc.error' />}
                                    translateText={false}
                                    toggle={() => { formik.setStatus() }}
                                    withTitle
                                />
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={handleOnPrevious} type="button" outline color="primary" className="mr-auto">
                                <Translate id="misc.previous" />
                            </Button>
                            <Button type="submit" color="primary">
                                <Translate id='misc.replace' />
                            </Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default ApplyPermissionsRoles;