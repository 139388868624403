import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import useSWR from 'swr';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { MembersContext } from '../../../../contexts/MembersContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { fail } from "@spordle/toasts";
import SidePanel from '../../SidePanel';
import TransferRequestApprove from '../TransferRequestApprove';
import TransferRequestApproveOverride from '../TransferRequestApproveOverride';

const ApproveAction = ({ transfer, onTransfer, override = false }) => {
    const membersContext = useContext(MembersContext);
    const [ modalIsOpen, setModalOpen ] = useState(false);
    const toggleModal = () => { setModalOpen(!modalIsOpen) };

    const { data: workflow, error } = useSWR(
        [ 'getMemberTransfersWorkflow', transfer.member.member_transfer_id ],
        () => membersContext.getMemberTransfersWorkflow(transfer.member.member_id, transfer.member_transfer_id)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }),
        {
            revalidateOnFocus: false,
            onError: (error) => {
                console.error(error);
            },
        },
    );

    const review = workflow && transfer.current_step != Object.keys(workflow[0].workflow_steps || {}).length;

    const Tag = !override ? TransferRequestApprove : TransferRequestApproveOverride
    const tagPermissionProps = override ? { action: 'ADD', componentCode: 'members', componentPermissionCode: 'member_transfer_override' } : { action: 'EDIT', componentCode: 'members', componentPermissionCode: 'members_request_transfer' }

    return (!error &&
        <SidePanel.MenuAction {...tagPermissionProps} onClick={toggleModal}>
            {review && !override ?
                <Translate id={'transfer.sidepanel.reviewRequest'} />
                :
                <Translate id={override ? 'transfer.sidepanel.approveRequest.override' : 'transfer.sidepanel.approveRequest'} />
            }
            <Tag
                isOpen={modalIsOpen} toggle={toggleModal} transfer={transfer}
                onTransfer={onTransfer} review={review && !override}
            />
        </SidePanel.MenuAction>
    );
}

export default ApproveAction;