import { FormikError } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import { useFormikContext } from "formik";
import { ListGroupItem } from "reactstrap";
import QuickViewButton from "../../../../components/quickView/QuickViewButton";
import FormikRadioBtn from "../../officialSupervision/components/addModal/components/FormikRadioBtn";
import PotentialMemberDuplicateKeepBtn from "./PotentialMemberDuplicateKeepBtn";
import Translate from "@spordle/intl-elements";

const PotentialMemberListItem = ({ member, index }) => {
    const formik = useFormikContext();
    const isMemberKept = formik.values.keep === member.member_id;

    // Result can be "MERGE" or "IGNORE".
    const resultField = `duplicates.${index}.result`;

    return (
        <ListGroupItem className="p-2">
            <div className="d-flex align-items-center">
                <div>
                    <QuickViewButton className={stringBuilder(`font-${isMemberKept ? "bold" : "medium"}`, { "text-dark": isMemberKept || !formik.values.keep }, "line-height-1")} member={member}>
                        {member.first_name} {member.last_name}
                    </QuickViewButton>
                    <small className={stringBuilder({ "font-medium": isMemberKept }, "d-block")}>
                        {member.unique_identifier ? `#${member.unique_identifier}` : '-'}
                    </small>
                </div>
                <div className="ml-auto d-flex align-items-center">
                    <div className="border-right pr-2 mr-2">
                        <PotentialMemberDuplicateKeepBtn
                            id={`${resultField}Keep`}
                            value={member.member_id}
                            isSelected={isMemberKept}
                        />
                    </div>
                    <FormikRadioBtn
                        value="MERGE"
                        disabled={isMemberKept || !formik.values.keep}
                        id={`${resultField}Merge`}
                        name={resultField}
                        label={<Translate id='tasks.memberDuplicates.manage.merge' />}
                        btnClassName="rounded-pill"
                        className="mb-0"
                    />
                    <FormikRadioBtn
                        label={<Translate id='tasks.memberDuplicates.manage.ignore' />}
                        disabled={isMemberKept || !formik.values.keep}
                        id={`${resultField}Ignore`}
                        name={resultField}
                        value="IGNORE"
                        btnClassName="rounded-pill"
                        className="mb-0"
                    />
                </div>
            </div>
            <div>
                <FormikError name={resultField} />
            </div>
        </ListGroupItem>
    );
}

export default PotentialMemberListItem;