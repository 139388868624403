import { Card, CardBody, CardHeader } from "reactstrap";
import InlineCopy from "../../../../components/inlineCopy/InlineCopy";
import IsGod from "../../../../components/permissions/IsGod";

const OrgSettingCardPreview = ({ code, title, desc, children, ...props }) => {
    return (
        <Card className="card-shadow h-100 mb-0 p-1" {...props}>
            <CardHeader className="bg-white">
                <div className="h5 font-bold mb-1">{title}</div>
                <div className="small font-medium mb-0">{desc}</div>
                {code && (
                    <IsGod tag="code">
                        <InlineCopy toCopy={code}>
                                setting: {code}
                        </InlineCopy>
                    </IsGod>
                )}
            </CardHeader>
            <CardBody>
                {children}
            </CardBody>
        </Card>
    );
}

export default OrgSettingCardPreview;