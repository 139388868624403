import clinics from "./clinics";

import settings from "./settings";
import teamAddressTypes from "./teamAddressTypes";
import teamContactTypes from "./teamContactTypes";
import periods from "./periods";
import contactTypes from "./contactTypes";
import memberTypes from "./memberTypes";
import suspensions from "./suspensions";
import divisions from "./divisions";
import classes from "./classes";
import category from "./category";
import general from "./general";
import phoneTypes from "./phoneTypes";
import positions from "./positions";
import positionGroups from "./positionGroups";
import teamCategoryTypes from "./teamCategoryTypes";
import teamTravelTypes from "./teamTravelTypes";
import teamsSettings from "./teamsSettings";
import payee from "./payee";
import merchantAccounts from "./merchantAccounts"
import registrationSettings from "./registrationSettings";
export default{
    ...settings,
    ...clinics,
    ...teamAddressTypes,
    ...periods,
    ...contactTypes,
    ...memberTypes,
    ...suspensions,
    ...divisions,
    ...classes,
    ...category,
    ...general,
    ...phoneTypes,
    ...positions,
    ...positionGroups,
    ...teamCategoryTypes,
    ...teamTravelTypes,
    ...teamContactTypes,
    ...teamsSettings,
    ...payee,
    ...merchantAccounts,
    ...registrationSettings,
}