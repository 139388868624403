import { useContext, useEffect, useState } from 'react';
import { AxiosCancelAll, AxiosIsCancelled } from '../../api/CancellableAPI';
import API_SPORDLE from '../../api/API-Spordle';
import { AuthContext, PeriodsContext, AppContext } from '../../contexts/contexts';
import { exclude, parse, stringifyUrl, stringify } from 'query-string';
import { OrganizationContext, localStorageOrganizationId } from '../../contexts/OrganizationContext';
import { IdentityRolesContext, localStorageIdentityRoleId } from '../../contexts/IdentityRolesContext';

import Spinner from 'reactstrap/es/Spinner';
import getAccountUrl from '../../helpers/getExternalUrl';
import { RolesContext } from '../../contexts/RolesContext';
import { I18nContext } from '../../contexts/I18nContext';
import { clearLocalStorage, getLocalStorageItem, getSessionStorageItem, setLocalStorageItem } from '../../helpers/browserStorage';
import AuthRolePicker from '../../components/authRolePicker/AuthRolePicker';
import { isSidMultiSport } from '../../helpers/getReferer';
import { getPlatformLexicon } from '../../api/client/lexicon';

const Auth = (props) => {
    const auth = useContext(AuthContext);
    const orgContext = useContext(OrganizationContext);
    const identityContext = useContext(IdentityRolesContext);
    const roleContext = useContext(RolesContext);
    const periodsContext = useContext(PeriodsContext);
    const i18n = useContext(I18nContext);
    const appContext = useContext(AppContext);

    var lazyLoad = null;

    const [ showLoading, setLoading ] = useState(false);
    const [ hasNotIdRole, setHasNotIdRole ] = useState(false);

    const paramObject = parse(props.location.search);

    const redirect = (params) => {
        props.history.replace(`/auth?${stringify(params)}`);
    }

    const setUpData = ({ identityRoleId, orgId, type, accessToken, ...otherParams }) => {
        API_SPORDLE.defaults.headers.common['X-Identity-Role'] = identityRoleId;
        return Promise.all([
            orgContext.setCurrentOrg(orgId),
            identityContext.getIdentityRole(identityRoleId),
            periodsContext.getOrganizationPeriods(orgId),
            getPlatformLexicon(),
        ])
            .then(async(values) => {
                // ** no need to do the getRolesPermissions anymore, getIdentityRole returns all components data now
                // const promiseReturn = await Promise.all([
                //     identityContext.setCurrentIdentityRole(values[1]),
                //     //roleContext.getRolesPermissions(values[1].role.role_id),
                // ])
                i18n.setLexicon(values[3]);
                setLocalStorageItem(localStorageOrganizationId, orgId);
                setLocalStorageItem(localStorageIdentityRoleId, identityRoleId);
                identityContext.setCurrentIdentityRole(values[1]);
                roleContext.setCurrentRole({ // ** spread the needed role data that is returned with the identity role now
                    ...values[1].role,
                    organisation_id: values[1].organisation.organisation_id,
                    components: values[1].components,
                }, () => {
                    auth.setAccessInfo({ accessToken: accessToken, type: type }, () => { redirect(otherParams) });
                })
            }).catch((error) => {
                if(error.message !== 'teapot')
                    window.location.replace(getAccountUrl('SPORDLE'));
            })
    }

    useEffect(() => {
        const goToAccess = (toAccount = false) => {
            // Remove data from state: "data" is our generic data object to pass data to a view
            // through react router that is easy to remove when we need to do redirects
            const locationState = props.location?.state
            if(locationState?.from){
                delete locationState.from.data;
                locationState.from.search = exclude(locationState.from.search, [ 'db' ]);
            }

            if(paramObject.db){
                clearLocalStorage();
            }

            const url = stringifyUrl({
                url: getAccountUrl(toAccount ? 'SPORDLE' : 'HCR'),
                query: toAccount ? {
                    lang: paramObject.lang ?? i18n.getGenericLocale() ?? 'en',
                } : {
                    ...paramObject,
                    lang: paramObject.lang ?? i18n.getGenericLocale() ?? 'en',
                    referrer: stringifyUrl({
                        url: window.location.origin + '/auth',
                        query: {
                            loggedIn: 1,
                        },
                    }),
                    lastPage: JSON.stringify(locationState),
                },
            });
            window.location.replace(url);
        };

        if(!hasNotIdRole){
            if(isSidMultiSport() && process.env.REACT_APP_VERSION_CLIENT !== 'EIHA' && !auth.hasToken() && !paramObject.accessToken && getSessionStorageItem('demo-accessToken')){
                redirect({ ...paramObject, accessToken: getSessionStorageItem('demo-accessToken') })
            }else if(paramObject.accessToken){
                // Extracting these params because we don't want them in the replace url
                // eslint-disable-next-line unused-imports/no-unused-vars
                const { accessToken, type, identityRoleId, organizationId, usePrevious, redirectCount, ...otherParams } = paramObject;
                delete otherParams.db;
                // Prioritize storage variables when usePrevious == '1';
                const orgId = usePrevious == '1' ?
                    getLocalStorageItem(localStorageOrganizationId) ?? organizationId
                    :
                    organizationId ?? getLocalStorageItem(localStorageOrganizationId);

                const identityId = usePrevious == '1' ?
                    getLocalStorageItem(localStorageIdentityRoleId) ?? identityRoleId
                    :
                    identityRoleId ?? getLocalStorageItem(localStorageIdentityRoleId);

                if(!orgId || !identityId){ // If orgId or identityId is not provided -> simply go back to account
                    auth.setAccessInfo({ accessToken: accessToken, type: type }, () => {
                        setHasNotIdRole(true);
                        redirect(otherParams);
                    });
                    return;
                }
                // Setting up the api object
                setUpData({ ...paramObject, orgId: orgId, identityRoleId: identityId });
            }else if(auth.hasToken()){
                // We have the access token in the authContext
                auth.authenticate()
                    .then(async(data) => {
                        await appContext.setFrontEndParams(data.front_end_custom_parameters).catch(console.error);

                        try{
                            const { from } = JSON.parse(paramObject.lastPage);
                            props.history.replace(from);
                        }catch(_exception){
                            props.history.replace('/');
                        }
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            auth.logout().finally(goToAccess);
                        }
                    });
            }else{
                // Is not authenticated
                goToAccess(paramObject.loggedIn == '1');
            }
        }

        return AxiosCancelAll;
    }, [ paramObject.accessToken ]);

    // Lazyload handler
    useEffect(() => {
        lazyLoad = setTimeout(() => {
            if(!showLoading) setLoading(true);
            lazyLoad = 0;
        }, 500);
        return (() => {
            if(lazyLoad){
                clearTimeout(lazyLoad);
            }
        })
    });

    return hasNotIdRole ?
        <AuthRolePicker onSelect={setUpData} />
        :
        showLoading ? <div className='min-vh-100 d-flex align-items-center justify-content-center'><Spinner type='grow' color='primary' /></div> : <div />
};

export default Auth;
