import Translate from "@spordle/intl-elements";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { AppContext } from "../../../../../contexts/contexts";
import { DashboardContext } from "../../../../../contexts/DashboardContext";
import { isMobileDevice } from "../../../../../helpers/constants";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";

const RecentsWidget = () => {
    const { getAllRecentLinks } = useContext(AppContext);
    const dashboardContext = useContext(DashboardContext);
    const allRecents = getAllRecentLinks().filter(({ path }) => path !== "/dashboard");

    return (
        <Card className="card-shadow h-100 mb-0 position-relative overflow-hidden">
            <CardBody className="d-flex flex-column h-100">
                {!isMobileDevice &&
                    <>
                        <div className='dashboard-grid-toolbar'>
                            <div className='dashboard-grid-toolbar-inner h-100'>
                                <i className='mdi mdi-drag dashboard-grid-drag-handle dashboard-grid-toolbar-inner-icon dashboard-grid-tool font-22' />

                                {dashboardContext.state.configureWidgetFeature &&
                                    // logic not done smile
                                    <i className='mdi mdi-settings text-muted dashboard-grid-toolbar-inner-icon dashboard-grid-tool' />
                                }
                                <div className='dashboard-grid-toolbar-icon-container'>
                                    <i className='mdi mdi-dots-vertical font-22' />
                                </div>
                            </div>
                        </div>
                        <div className='dashboard-grid-toolbar-overlay' />
                    </>
                }
                <div className="d-flex align-items-center border-bottom pb-1 mb-2">
                    <h4 className="font-bold mb-0">
                        <span className="position-relative">
                            <Translate id="newnav.recentlyViewed" />
                        </span>
                    </h4>
                </div>
                {allRecents.length > 0 ?
                    <div className="message-center message-body overflow-hidden">
                        <PerfectScrollbar>
                            {allRecents.map((recent) => (
                                <Link to={recent.path} title={recent.path} key={recent.path}>
                                    <UserDisplay className="align-items-center mb-2 py-2 px-3 bg-light-hover" card block>
                                        <UserDisplay.Container>
                                            <div className="d-flex flex-center bg-primary-light text-primary rounded-circle border align-middle" style={{ height: 50, width: 50 }}>
                                                <i className="mdi mdi-history font-22" />
                                            </div>
                                        </UserDisplay.Container>
                                        <UserDisplay.Container className="w-100 min-w-0">
                                            {(
                                                !!recent.subtitle
                                                || (Array.isArray(recent.breadcrumbs) && recent.breadcrumbs.length > 0)
                                            ) &&
                                                <div className="w-100 small text-truncate text-muted mt-1">
                                                    {recent.subtitle ?
                                                        recent.subtitle
                                                        :
                                                        recent.breadcrumbs.map((crumb, index) => (
                                                            <React.Fragment key={recent.path + crumb.name}>
                                                                {index === 0 ? "" : " / "}
                                                                {crumb.skipTranslate ? crumb.name : <Translate id={crumb.name} />}
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </div>
                                            }
                                            <div className="font-medium text-dark">
                                                {
                                                    recent.translate ?
                                                        <Translate id={recent.label} />
                                                        :
                                                        recent.i18n ?
                                                            <DisplayI18n field="label" defaultValue={recent.label} i18n={recent.i18n} />
                                                            :
                                                            recent.label
                                                }
                                            </div>
                                            <div className="small text-truncate w-100">
                                                {window.origin}{recent.path}
                                            </div>
                                        </UserDisplay.Container>
                                        <UserDisplay.Container className="ml-auto">
                                            <i className="mdi font-18 text-dark mdi-chevron-right" />
                                        </UserDisplay.Container>
                                    </UserDisplay>
                                </Link>
                            ))}
                        </PerfectScrollbar>
                    </div>
                    :
                    <div className="h-100 text-muted text-center flex-column d-flex flex-center">
                        <div className="mdi line-height-1 fa-5x mdi-history" />
                        <div><Translate id="newnav.noRecentlyViewed" /></div>
                    </div>

                }
            </CardBody>
        </Card>
    );
}

export default RecentsWidget;