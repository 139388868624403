import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";

const CriticalLabel = () => {
    return (
        <Tooltip withArrow label={<Translate id='catalog.waivers.critical.tooltip' />}>
            <Translate id='misc.critical' /><i className="ml-2 mdi mdi-information-outline text-primary" />
        </Tooltip>

    );
}

export default CriticalLabel;