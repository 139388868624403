import Translate from "@spordle/intl-elements";
import { stringBuilder } from "@spordle/helpers";
import React from "react";
import { Link } from "react-router-dom";
import {
    Badge, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import { PeriodsContext } from '../../../../../contexts/contexts';
import { I18nContext } from "../../../../../contexts/I18nContext";
import { MembersContext } from '../../../../../contexts/MembersContext';
import { OnlineStoreContext } from "../../../../../contexts/OnlineStoreContext";
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { displayI18n, DisplayI18n } from "../../../../../helpers/i18nHelper";
// Contexts
import withContexts from '../../../../../helpers/withContexts';
import MemberProfileItemCancel from "../../../../members/profile/registration/components/CancelItems/MemberProfileItemCancel";
import MemberProfileRegistrationConfirmWaivers from "../../../../members/profile/registration/components/MemberProfileRegistrationConfirmWaivers";
import ConfirmCancelledBy, { getConfirmCancelled } from "../../../../members/profile/registration/components/Sidepanel/components/ConfirmCancelledBy";
import MemberProfileRegistrationSidepanelForms from "../../../../members/profile/registration/components/Sidepanel/components/MemberProfileRegistrationSidepanelForm";
import OnlineStoreTransactionRefundModal from "../../transactions/components/modals/refundModal/OnlineStoreTransactionRefundModal";
import OnlineStoreTransactionsSidepanel from "../../transactions/components/OnlineStoreTransactionsSidepanel";
import OnlineStoreRegistrationsSidepanelRegistration from "./OnlineStoreRegistrationsSidepanelRegistration";
import QuickViewButton from "../../../../../components/quickView/QuickViewButton";
import UpdateInvoicePositionModal from "../../../../../components/updateInvoicePositionModal/UpdateInvoicePositionModal";
import QuickViewActionButton from "../../../../../components/quickView/QuickViewActionButton";
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy";


class OnlineStoreRegistrationsSidepanel extends React.Component{

    constructor(props){
        super(props);
        this.toggleTabs = this.toggleTabs.bind(this);
        // this.toggleCancelModal = this.toggleCancelModal.bind(this);
        this.state = {
            activeTab: '1',
            cancelModal: false,
            waiversModal: false,
            refundModal: false,
            updateInvoiceItemPositionModal: false,
            invoiceNumber: this.props.selectedRows[0].invoice_number,
            memberRegistration: null,

            mode: '',

            refundAmounts: false,
        };

        this._isMounted;
        this.modes = {
            refundToCancel: 'refundToCancel',
            cancelToRefund: 'cancelToRefund',
        }
    }

    refreshTable = () => {
        this.props.tableRef.refreshTable();
        this.props.forceCloseSidePanel();
        this.props.getRegistrationFeesAndOtherFees()
    }

    toggleTabs(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    toggleModal = (modal) => {
        this.setState((prevState) => ({ [modal]: !prevState[modal] }))
    }

    getRefundAmounts = () => {
        this.setState(() => ({ refundAmounts: false }));
        this.props.MembersContext.getRefundAmounts(this.props.selectedRows[0].invoice_number)
            .then((refundAmounts) => {
                this.setState(() => ({ refundAmounts: refundAmounts }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    // toggleCancelModal = ()  => {
    //     this.setState({ cancelModal: !this.state.cancelModal });
    // }
    // toggleWaiversModal = ()  => {
    //     this.setState({ waiversModal: !this.state.waiversModal });
    // }
    // toggleRefundModal = () => {
    //     this.setState({ refundModal: !this.state.refundModal });
    // }

    getStatusBadge = () => {
        switch (getConfirmCancelled(this.props.selectedRows[0].memberRegistration)){
            case 'CONFIRMED':
                return <Badge color="success" size="lg" className="px-3 align-self-start"><Translate id='memberProfile.registration.status.confirmed' /></Badge>
            case 'CANCELLED':
                return <Badge color="danger" size="lg" className="px-3 align-self-start"><Translate id='memberProfile.registration.status.cancelled' /></Badge>
            case 'PENDING':
                return <Badge color="warning" size="lg" className="px-3 align-self-start"><Translate id='memberProfile.registration.status.pending' /></Badge>
            default:
                return ''
        }
    }

    getSpecificInvoice = () => {
        const registration = this.props.selectedRows[0];

        this.props.OnlineStoreContext.getInvoice(registration.invoice_number, {
            organisation_id: this.props.OrganizationContext.organisation_id,
            period_id: this.props.OnlineStoreContext.state?.cachedStore?.period?.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
        })
            .then((invoice) => {
                if(this._isMounted){
                    const myRegistration = invoice.invoice_items.find((item) => item.registration_fee !== null && item.member.member_id == registration.memberRegistration.member.member_id)
                    this.setState(() => ({ memberRegistration: myRegistration }));

                    // Sync rows to make the "member sidepanel form" work after update inside online store invoices logic with sidearrays
                    // Do not use create new values. We actually want the registration that was selected, no necessarily the current one (if user changes fast)
                    this.props.syncRows([ { ...registration, memberRegistration: { ...myRegistration, registration_fee_id: myRegistration.registration_fee.registration_fee_id } } ])
                }
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    handleResendInvoice = (withReceipt = false) => {
        this.props.MembersContext.resendInvoice([ this.props.selectedRows[0].invoice_number ], withReceipt)
            .then(() => {
                success();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    if(error.i18n){
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }else{
                        fail({ msg: 'members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice.error' });
                    }
                }
            })
    }

    componentDidMount(){
        this._isMounted = true;
        this.getSpecificInvoice();
        if(this.props.selectedRows[0].invoice_number){
            this.getRefundAmounts();
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidUpdate(prevState){
        if(this.props.selectedRows[0].invoice_number && prevState.selectedRows[0].invoice_number !== this.props.selectedRows[0].invoice_number){
            this.getSpecificInvoice();
            this.getRefundAmounts();
        }else if(
            !!this.state.memberRegistration
            && this.props.selectedRows[0].memberRegistration.member.member_id !== this.state.memberRegistration?.member?.member_id
            && this.props.selectedRows[0].invoice_number == prevState.selectedRows[0].invoice_number
        ){
            this.setState((prev) => ({
                ...prev,
                memberRegistration: {
                    ...prev.memberRegistration,
                    member: this.props.selectedRows[0].memberRegistration.member,
                    custom_forms: this.props.selectedRows[0].memberRegistration.custom_forms,
                },
            }));
        }
    }

    render(){
        return (
            <>
                <SidePanel.Header noBorder>
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />
                        <MemberProfileRegistrationConfirmWaivers
                            isOpen={this.state.waiversModal}
                            toggle={() => this.toggleModal('waiversModal')}
                        />

                        {/* CANCEL THEN REFUND MODALS */}
                        <MemberProfileItemCancel
                            isOpen={this.state.cancelModal}

                            // If registration was cancelled, this will be called on toggle
                            // which will close the modal as well
                            refreshTable={this.refreshTable}
                            toggle={() => this.toggleModal('cancelModal')}
                            chainWithRefund={this.state.mode === this.modes.cancelToRefund}

                            toggleRefund={() => this.toggleModal('refundModal')}
                            availableRefund={this.state.refundAmounts}

                            // Will popup the refund modal if user has permission to refund
                            refundPermissions={{ action: "EDIT", componentCode: "members", componentPermissionCode: "members_transaction" }}
                            invoiceItems={this.props.selectedRows[0].invoice_items.reduce((newArray, item) => {
                                if(item.registration_fee || item.other_fee){
                                    newArray.push({
                                        ...item,
                                        registration_fee: item.registration_fee,
                                        other_fee: item.other_fee,
                                    })
                                }
                                return newArray
                            }, [])}
                            defaultItemIds={[ this.props.selectedRows[0].memberRegistration.invoice_item_id ]}
                        />

                        {/* If this was open and it closes, it means for a fact
                         That we need to refresh the table when the modal closes
                         this.toggleModal('refundModal'); */}
                        <OnlineStoreTransactionRefundModal
                            chainWithCancel={this.state.mode === this.modes.refundToCancel}
                            transaction={this.props.selectedRows[0]}
                            isOpen={this.state.refundModal}
                            toggle={() => {
                                this.toggleModal('refundModal');
                                this.props.toggle();
                            }}
                            refreshTable={this.refreshTable}
                            onCancelRegistration={() => {
                                this.toggleModal('refundModal');
                                this.toggleModal('cancelModal');
                            }}
                        />
                        {/* END CANCEL THEN REFUND MODALS */}

                        <UpdateInvoicePositionModal
                            isOpen={this.state.updateInvoiceItemPositionModal}
                            toggle={() => this.toggleModal('updateInvoiceItemPositionModal')}
                            invoiceItemPosition={this.props.selectedRows[0].memberRegistration.positions?.[0]}
                            onSuccess={() => {
                                this.refreshTable();
                                this.props.toggle();
                            }}
                        />

                        <SidePanel.ActionsMenu action='EDIT' componentCode='page' componentPermissionCode='online_store_manage'>

                            {/* CONFIRM REGISTRATION */}
                            <ConfirmModal
                                icon='mdi-check-circle-outline'
                                translateActionButtonText
                                modalTitle={<Translate id='memberProfile.registration.confirmRegistration.confirm' values={{ registrationName: displayI18n('name', this.props.selectedRows[0].memberRegistration.registration_fee?.i18n, this.props.selectedRows[0].memberRegistration.registration_fee?.name, this.props.I18nContext.getGenericLocale()) }} />}
                                actionButtonText='misc.confirm'
                                color='primary'
                                toggler={(fn) => (
                                    <SidePanel.MenuAction onClick={fn} disabled={!!this.props.selectedRows[0].memberRegistration.confirmed_at}>
                                        <Translate id='memberProfile.registration.sidepanel.confirmRegistration' />
                                        {!!this.props.selectedRows[0].memberRegistration.confirmed_at && <div className='small'><Translate id='memberProfile.registration.sidepanel.confirmRegistration.already' /></div>}
                                    </SidePanel.MenuAction>
                                )
                                }
                                onConfirm={() => {
                                    Promise.all([
                                        this.props.MembersContext.confirmInvoiceItem(this.props.selectedRows[0].memberRegistration.invoice_item_id),
                                        this.props.selectedRows[0].memberRegistration.cancelled_at ? this.props.MembersContext.deleteInvoiceItemCancellation(this.props.selectedRows[0].memberRegistration.invoice_item_id) : Promise.resolve(),
                                    ])
                                        .then(() => {
                                            success({ msg: 'memberProfile.registration.confirmRegistration.success' });
                                            this.refreshTable();
                                            this.props.getRegistrationFeesAndOtherFees();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                if(error.i18n){
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }else{
                                                    fail({ msg: 'memberProfile.registration.confirmRegistration.error' });
                                                }
                                            }
                                        })
                                }}
                            />

                            {/* CONFIRM WAIVERS  */}
                            <SidePanel.MenuAction disabled onClick={() => this.toggleModal('waiversModal')}>
                                <Translate id='memberProfile.registration.sidepanel.confirmWaivers' />
                            </SidePanel.MenuAction>

                            <SidePanel.MenuAction divider />

                            {/* CANCEL REGISTRATION */}
                            <SidePanel.MenuAction
                                onClick={() => {
                                    this.setState(() => ({ mode: this.modes.cancelToRefund }))
                                    this.toggleModal('cancelModal')
                                }}
                                disabled={!!this.props.selectedRows[0].memberRegistration.cancelled_at}
                            >
                                <Translate id='memberProfile.registration.sidepanel.cancelRegistration' />
                                {!!this.props.selectedRows[0].memberRegistration.cancelled_at && <div className='small'><Translate id='memberProfile.registration.sidepanel.cancelRegistration.already' /></div>}
                            </SidePanel.MenuAction>

                            {/* REFUND  */}
                            <SidePanel.MenuAction
                                onClick={() => {
                                    this.setState(() => ({ mode: this.modes.refundToCancel }))
                                    this.toggleModal('refundModal')
                                }}
                            >
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.refund' />
                            </SidePanel.MenuAction>

                            {this.props.selectedRows[0].memberRegistration.positions && this.props.selectedRows[0].memberRegistration.positions[0] &&
                                <SidePanel.MenuAction
                                    onClick={() => this.toggleModal('updateInvoiceItemPositionModal')}
                                >
                                    <Translate id='components.updateInvoicePositionModal.header' />
                                </SidePanel.MenuAction>
                            }

                            <SidePanel.MenuAction divider />

                            {/* RESEND INVOICE */}
                            <SidePanel.MenuAction onClick={() => this.handleResendInvoice(false)}>
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice' />
                            </SidePanel.MenuAction>

                            {/* RESEND INVOICE */}
                            <SidePanel.MenuAction onClick={() => this.handleResendInvoice(true)}>
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoiceWithReceipt' />
                            </SidePanel.MenuAction>

                            {/* SEND WAIVERS */}
                            <SidePanel.MenuAction disabled>
                                <Translate id='memberProfile.registration.sidepanel.sendWaivers' />
                            </SidePanel.MenuAction>

                            <QuickViewActionButton member={this.props.selectedRows[0].memberRegistration.member} />
                        </SidePanel.ActionsMenu>
                    </div>
                    <SidePanel.Subtitle>
                        <DisplayI18n
                            field='name'
                            defaultValue={this.props.PeriodsContext.selectedPeriod.name}
                            i18n={this.props.PeriodsContext.selectedPeriod.i18n}
                        />
                    </SidePanel.Subtitle>
                    <SidePanel.Title>
                        <DisplayI18n
                            field='name'
                            defaultValue={this.props.selectedRows[0].memberRegistration.registration_fee?.fee?.name}
                            i18n={this.props.selectedRows[0].memberRegistration.registration_fee?.fee?.i18n}
                        />
                        <SidePanel.Subtitle className="mt-1">
                            {this.getStatusBadge()}
                        </SidePanel.Subtitle>
                    </SidePanel.Title>
                    <UserDisplay className="mt-3">
                        <UserDisplay.Container>
                            <UserImg
                                abbr={this.props.selectedRows[0].memberRegistration.member.first_name.substring(0, 1) + this.props.selectedRows[0].memberRegistration.member.last_name.substring(0, 1)}
                                src={undefined}
                                width={40}
                                height={40}
                                alt={this.props.selectedRows[0].memberRegistration.member.first_name + ' ' + this.props.selectedRows[0].memberRegistration.member.last_name}
                            />
                        </UserDisplay.Container>
                        <UserDisplay.Container>
                            <UserDisplay.Title>
                                <QuickViewButton member={this.props.selectedRows[0].memberRegistration.member} className="d-flex">
                                    {this.props.selectedRows[0].memberRegistration.member.first_name + ' ' + this.props.selectedRows[0].memberRegistration.member.last_name}
                                </QuickViewButton>
                            </UserDisplay.Title>
                            <div className="d-inline-flex">
                                <div className="small mr-4 d-flex align-items-center">
                                    <InlineCopy tag='div' toCopy={this.props.selectedRows[0].memberRegistration.member.unique_identifier} className="font-medium">{this.props.selectedRows[0].memberRegistration.member.unique_identifier || '-'}</InlineCopy>
                                </div>
                            </div>
                            <div className="small"><Link to={{ pathname: `/members/profile/${this.props.selectedRows[0].memberRegistration.member.member_id}` }}><Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" /></Link></div>
                        </UserDisplay.Container>
                    </UserDisplay>

                    <ConfirmCancelledBy invoiceItem={this.props.selectedRows[0].memberRegistration} className='mt-3' />
                </SidePanel.Header>
                <Nav tabs style={{ overflow: "hidden" }}>
                    <NavItem className="text-center" style={{ width: "32%" }}>
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggleTabs('1'); }}
                        >
                            <Translate id='memberProfile.registration.sidepanel.registration' />
                        </NavLink>
                    </NavItem>
                    <NavItem className="text-center" style={{ width: "32%" }}>
                        <NavLink

                            className={stringBuilder({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggleTabs('2'); }}
                        >
                            <Translate id='memberProfile.registration.transaction' />
                        </NavLink>
                    </NavItem>
                    <NavItem className="text-center" style={{ width: "33%" }}>
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.toggleTabs('3'); }}
                        >
                            <Translate id='memberProfile.registration.sidepanel.form' />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <OnlineStoreRegistrationsSidepanelRegistration {...this.props} />
                    </TabPane>
                    <TabPane tabId="2">
                        <OnlineStoreTransactionsSidepanel {...this.props} hideHeader />
                    </TabPane>
                    <TabPane tabId="3">
                        <MemberProfileRegistrationSidepanelForms {...this.props} registration={this.state.memberRegistration} invoiceNumber={this.props.selectedRows[0].invoice_number} />
                    </TabPane>
                </TabContent>
            </>
        )
    }
}

export default withContexts(OrganizationContext, MembersContext, PeriodsContext, I18nContext, OnlineStoreContext)(OnlineStoreRegistrationsSidepanel);