export default{
    "teams.profile.teamRoster.activeRoster.add.title"                  : "Ajouter des joueurs à l'équipe",
    "teams.profile.teamRoster.activeRoster.add.label.season"           : "Saison",
    "teams.profile.teamRoster.activeRoster.add.label.team"             : "Équipe",
    "teams.profile.teamRoster.activeRoster.add.label.member"           : "Membre",
    "teams.profile.teamRoster.activeRoster.add.label.member_age"       : "Âge",
    "teams.profile.teamRoster.activeRoster.add.label.status"           : "Statut",
    "teams.profile.teamRoster.activeRoster.add.label.uniqueIdentifier" : "{unique_identifier}",

    "teams.profile.teamRoster.activeRoster.add.searchName"      : "Rechercher par nom",
    "teams.profile.teamRoster.activeRoster.add.searchTeam"      : "Rechercher par équipe",
    "teams.profile.teamRoster.activeRoster.add.searchBirthdate" : "Rechercher par date de naissance",
    "teams.profile.teamRoster.activeRoster.add.searchBranch"    : "Recherche par branche",
    "teams.profile.teamRoster.activeRoster.alreadyInTeam"       : "Déjà dans cette équipe.",

    "teams.profile.teamRoster.activeRoster.add.notRostered"   : "Cacher les membres qui sont déjà dans l'équipe",
    "teams.profile.teamRoster.activeRoster.add.onlyRostered"  : "Cacher les membres qui sont déjà dans cette équipe",
    "teams.profile.teamRoster.activeRoster.add.alreadyInTeam" : "Déjà dans une autre équipe",
    "teams.profile.teamRoster.activeRoster.add.selected"      : "Membre(s) sélectionné(s)",

    "teams.profile.teamRoster.activeRoster.add.applyAll" : "Appliquer toutes les positions préférées",

    "teams.profile.teamRoster.activeRoster.add.regular"                  : "Régulier",
    "teams.profile.teamRoster.activeRoster.add.tryout"                   : "Essaie",
    "teams.profile.teamRoster.activeRoster.add.import"                   : "Importé",
    "teams.profile.teamRoster.activeRoster.add.primaryTeam"              : "Définir en tant qu'équipe primaire",
    "teams.profile.teamRoster.activeRoster.add.alreadyPrimaryTeam.share" : "Déjà l'équipe primaire du membre",

    "teams.profile.teamRoster.activeRoster.add.selectTransfer"  : "Type de transfert",
    "teams.profile.teamRoster.activeRoster.add.shareExpiration" : "Expiration du partage",
    "teams.profile.teamRoster.activeRoster.add.select.transfer" : "Transfert",
    "teams.profile.teamRoster.activeRoster.add.select.share"    : "Partage",

    "teams.profile.teamRoster.activeRoster.add.searchName.validation"   : "Veuillez spécifier un prénom, nom ou un {unique_identifier}",
    "teams.profile.teamRoster.activeRoster.add.validation.position"     : "Veuillez spécifier une position.",
    "teams.profile.teamRoster.activeRoster.add.validation.category"     : "Veuillez spécifier une catégorie.",
    "teams.profile.teamRoster.activeRoster.add.validation.type"         : "Veuillez spécifier un type.",
    "teams.profile.teamRoster.activeRoster.add.validation.period"       : "Veuillez spécifier une saison.",
    "teams.profile.teamRoster.activeRoster.add.validation.targetTeam"   : "Veuillez spécifier une équipe.",
    "teams.profile.teamRoster.activeRoster.add.validation.dateFrom"     : "Veuillez spécifier une date valide.",
    "teams.profile.teamRoster.activeRoster.add.validation.transferType" : "Veuillez spécifier un type de transfert.",

    "teams.profile.teamRoster.activeRoster.add.confirmation.players" : "Les joueurs ont bien été ajoutés",
    "teams.profile.teamRoster.activeRoster.add.confirmation.staff"   : "Les membres du personnel de banc ont bien été ajoutés",
    "teams.profile.teamRoster.activeRoster.add.confirmation.button"  : "J'ai compris!",

    "teams.profile.teamRoster.activeRoster.add.toast.title" : "Les membres ont été ajoutés!",
    "teams.profile.teamRoster.activeRoster.add.toast.desc"  : "Le traitement des membres de branche va maintenant commencer.",
}