import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useRef } from "react";
import { Button } from "reactstrap";
import { RolesContext } from "../../../contexts/RolesContext";
import searchSidebar from "../../../routes/navigationRoutes/searchSidebar";
import { TourManagerContext } from "../TourManager";
import AnimatedImg from "../animatedImg/AnimatedImg";
import { useTour } from "@reactour/tour";

export function useNavigationTour(){
    const lastTourSelector = useRef("");
    const lastSelectorHadMore = useRef(false);
    const isInit = useRef(false);
    const { validateRouteAccess } = useContext(RolesContext);
    const { isOpen } = useTour();
    const { updateTour, triggerTour } = useContext(TourManagerContext);

    const closePreviousDropdown = () => {
        const lastSelectedDropdown = document.querySelector(`${lastTourSelector.current}-dropdown`);

        lastSelectedDropdown?.classList?.remove("show");
    }

    const resetLastDropdown = () => {
        closePreviousDropdown();
        lastTourSelector.current = "";
    }

    const validateRoutePermissions = (tourSteps) => {
        return tourSteps.reduce((newArray, step) => {
            const showFor = validateRouteAccess(step);

            if(showFor && showFor !== "ADMIN"){
                newArray.push(step);
            }

            return newArray;
        }, []);
    }

    const handleMoreMenu = (cssSelector) => {
        // Detect if the "more" dropdown if present from the current dropdown option
        const moreDropdown = document.querySelector(cssSelector + '-dropdown')?.closest("#more");
        let lastHadMore = false;

        if(moreDropdown){
            lastHadMore = true;
            moreDropdown.classList.add("show");
            moreDropdown.querySelector('.dropdown-menu').classList.add("show");
        }else if(lastSelectorHadMore.current){
            // Need to close more menu when we leave it
            lastHadMore = false;
            document.querySelector('#more').classList.remove("show");
            document.querySelector('#more').querySelector('.dropdown-menu').classList.remove("show");
        }

        lastSelectorHadMore.current = lastHadMore;
    }

    const triggerDropdownInTour = (cssSelector) => {
        const selectedDropdown = document.querySelector(cssSelector + '-dropdown');

        handleMoreMenu(cssSelector);

        if(lastTourSelector.current !== cssSelector){
            closePreviousDropdown();
            selectedDropdown?.classList.add("show");
        }

        lastTourSelector.current = cssSelector;

        window.dispatchEvent(new Event('resize'));
    }

    const getTourSteps = () => {
        const tourSteps = [
            { // Welcome
                action: resetLastDropdown,
                content: (tourBag) => (
                    <div className="bg-light text-center position-relative mt-n4 mx-n4">
                        <AnimatedImg isAnimated />
                        <button onClick={() => tourBag.setIsOpen(false)} type="button" className="btn btn-link btn-lg position-absolute bg-transparent top-0 right-0 border-0"><i className="text-body font-18 mdi mdi-close" /></button>
                        <div className="p-5 mb-n4 bg-white position-relative">
                            <div className="text-primary mb-3 font-medium"><Translate id='tour.step.1.title' /></div>
                            <div className="text-center">
                                <div className="overflow-hidden px-5">
                                    <div className='h1 font-medium mx-auto slide-up'><Translate id='tour.step.1.message' /></div>
                                </div>
                                <div onClick={() => tourBag.setCurrentStep(tourBag.currentStep + 1)} style={{ top: "50%", transform: 'translateY(-50%)' }} className="sid-btn-tour btn-lg right-0 mr-4 position-absolute">
                                    <i className="font-22 mdi mdi-arrow-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                ),
                showPrevNext: false,
                bypassElem: true,
                styles: {
                    popover: (styles) => ({ ...styles, width: "750px", maxWidth: "100%", overflow: "hidden" }),
                    button: () => ({ display: "none" }),
                    navigation: ({ justifyContent, ...styles }) => ({ ...styles, justifyContent: "center", width: "100%" }),
                    maskArea: () => ({ opacity: 0 }),
                    dot: ({ background, ...styles }, { current }) => {
                        const newStyles = {
                            ...styles,
                            ["&:focus"]: { outline: "none" },
                        }

                        if(!current){
                            newStyles.background = "#f4f6f9";
                            newStyles.border = "1px solid #f4f6f9";
                        }else{
                            newStyles.background = styles.color;
                        }

                        return newStyles;
                    },
                },
                highlightedSelectors: [],
                position: 'center',
            },
            { // Dashboard
                action: resetLastDropdown,
                content: () => (
                    <div>
                        <div className='h3 font-medium'><Translate id='tour.step.2.title' /></div>
                        <div className='h5'><Translate id='tour.step.2.message' /></div>
                    </div>
                ),
                selector: '.org-navbar-tour-class',
            },
            { // Header Navigation
                subPermissionsRoutes: searchSidebar,
                // action: () => {
                //     triggerDropdownInTour('.header-tour-link-manage');
                // },
                content: () => (
                    <div>
                        <div className='h3 font-medium'><Translate id='tour.step.3.title' /></div>
                        <div className='h5'><Translate id='tour.step.3.message' /></div>
                    </div>
                ),
                selector: '.navbar-nav',
                highlightedSelectors: [ '.navbar-nav', '.sid-nav-item' ],
            },
            // { // Manage
            //     subPermissionsRoutes: searchSidebar,
            //     action: () => {
            //         triggerDropdownInTour('.header-tour-link-manage');
            //     },
            //     content: () => (
            //         <div>
            //             <div className='h3 font-medium'><Translate id='tour.step.3.title' /></div>
            //             <div className='h5'><Translate id='tour.step.3.message' /></div>
            //         </div>
            //     ),
            //     selector: '.header-tour-link-manage',
            //     highlightedSelectors: [ '.header-tour-link-manage', '.header-tour-link-manage-dropdown' ],
            // },
            // { // Tasks
            //     subPermissionsRoutes: tasksSidebar,
            //     action: () => {
            //         triggerDropdownInTour('.header-tour-link-tasks');
            //     },
            //     content: () => (
            //         <div>
            //             <div className='h3 font-medium'><Translate id='tour.step.4.title' /></div>
            //             <div className='h5'><Translate id='tour.step.4.message' /></div>
            //         </div>
            //     ),
            //     selector: '.header-tour-link-tasks',
            //     highlightedSelectors: [ '.header-tour-link-tasks', '.header-tour-link-tasks-dropdown' ],
            // },
            // { // Catalog
            //     subPermissionsRoutes: catalogSidebar,
            //     action: () => {
            //         triggerDropdownInTour('.header-tour-link-catalog');
            //     },
            //     content: () => (
            //         <div>
            //             <div className='h3 font-medium'><Translate id='tour.step.5.title' /></div>
            //             <div className='h5'><Translate id='tour.step.5.message' /></div>
            //         </div>
            //     ),
            //     selector: '.header-tour-link-catalog',
            //     highlightedSelectors: [ '.header-tour-link-catalog', '.header-tour-link-catalog-dropdown' ],
            // },
            // { // Rapports
            //     subPermissionsRoutes: reportsSidebar,
            //     action: () => {
            //         triggerDropdownInTour('.header-tour-link-reports');
            //     },
            //     content: () => (
            //         <div>
            //             <div className='h3 font-medium'><Translate id='tour.step.reports.title' /></div>
            //             <div className='h5'><Translate id='tour.step.reports.message' /></div>
            //         </div>
            //     ),
            //     selector: '.header-tour-link-reports',
            //     highlightedSelectors: [ '.header-tour-link-reports', '.header-tour-link-reports-dropdown' ],
            // },
            // { // Settings
            //     subPermissionsRoutes: settingsSidebar,
            //     action: () => {
            //         triggerDropdownInTour('.header-tour-link-settings');
            //     },
            //     content: () => (
            //         <div>
            //             <div className='h3 font-medium'><Translate id='tour.step.6.title' /></div>
            //             <div className='h5'><Translate id='tour.step.6.message' /></div>
            //         </div>
            //     ),
            //     selector: '.header-tour-link-settings',
            //     highlightedSelectors: [ '.header-tour-link-settings', '.header-tour-link-settings-dropdown' ],
            // },
            { // Sidebar
                action: () => {
                    // closes the final dropdown when switching to the sidebar step
                    // and sets last css selector state to empty so if user goes back to previous step the dropdown will open
                    closePreviousDropdown();
                    document.getElementById("help-center-dropdown-close")?.click();
                },
                content: () => (
                    <div>
                        <div className='h3 font-medium'><Translate id="tour.step.7.title" /></div>
                        <div className='h5'><Translate id="tour.step.7.message" values={{ break: (_) => <br /> }} /></div>
                    </div>
                ),
                selector: '.scroll-sidebar',
            },
            { // Help center
                action: () => {
                    closePreviousDropdown();
                    const toggleContainer = document.getElementById("help-center-dropdown");

                    if(toggleContainer && !toggleContainer.classList.contains("show")){
                        document.getElementById("help-center-dropdown-toggle")?.click();
                    }

                    lastTourSelector.current = ".help-center-tour";
                },
                resizeObservables: [ '#help-dropdown-menu-container' ],
                content: () => (
                    <div>
                        <div className='h3 font-medium'><Translate id="tour.step.helpCenter.title" /></div>
                        <div className='h5'><Translate id='tour.step.helpCenter.message' values={{ break: (_) => <br /> }} /></div>
                    </div>
                ),
                highlightedSelectors: [ '#help-center-dropdown-toggle', '#help-dropdown-menu-container' ],
            },
            { // Your Profile dropdown
                action: () => {
                    document.getElementById("help-center-dropdown-close")?.click();
                    triggerDropdownInTour('.account-picture-navigation');
                },
                content: () => (
                    <div>
                        <div className='h3 font-medium'><Translate id='tour.step.8.title' /></div>
                        <div className='h5'><Translate id='tour.step.8.message' /></div>
                    </div>
                ),
                selector: '.account-picture-navigation',
                highlightedSelectors: [ '.account-picture-navigation-dropdown', '.account-picture-navigation' ],
            },
            { // Completed tour
                showPrevNext: false,
                bypassElem: true,
                highlightedSelectors: [],
                position: 'center',
                content: (tourBag) => (
                    <div className="bg-light text-center position-relative mt-n4 mx-n4">
                        <AnimatedImg isReverse>
                            <i className="sid-tour-thumbs-up fa fa-thumbs-up" />
                        </AnimatedImg>
                        <button onClick={() => tourBag.setIsOpen(false)} type="button" className="line-height-1 p-4 btn btn-link btn-lg position-absolute bg-transparent top-0 right-0 border-0"><i className="text-body font-18 mdi mdi-close" /></button>
                        <div className="px-5 py-4 mb-n4 bg-white position-relative">
                            <div className="text-center">
                                <div className="overflow-hidden px-5 mb-3">
                                    <div className='h1 font-medium mx-auto slide-up'><Translate id='tour.step.10.title' /></div>
                                    <div className="h5 slide-up mx-auto"><Translate id="tour.step.10.message" /></div>
                                </div>
                                <div className="mt-2">
                                    <Button onClick={() => tourBag.setIsOpen(false)} type="button" color="primary"><Translate id="tour.step.gotIt.btn" /></Button>
                                </div>
                                <div onClick={() => tourBag.setCurrentStep(tourBag.currentStep - 1)} style={{ top: "50%", transform: 'translateY(-50%)' }} className="sid-btn-tour btn-lg left-0 ml-4 position-absolute">
                                    <i className="font-22 mdi mdi-arrow-left" />
                                </div>
                            </div>
                        </div>
                    </div>
                ),
                styles: {
                    popover: (styles) => ({ ...styles, width: "600px", maxWidth: "100%", overflow: "hidden" }),
                    button: () => ({ display: "none" }),
                    navigation: ({ justifyContent, ...styles }) => ({ ...styles, justifyContent: "center", width: "100%" }),
                    maskArea: () => ({ opacity: 0 }),
                    dot: ({ background, ...styles }, { current }) => {
                        const newStyles = {
                            ...styles,
                            ["&:focus"]: { outline: "none" },
                        }

                        if(!current){
                            newStyles.background = "#f4f6f9";
                            newStyles.border = "1px solid #f4f6f9";
                        }else{
                            newStyles.background = styles.color;
                        }

                        return newStyles;
                    },
                },
            },
        ];


        return validateRoutePermissions(tourSteps);
    }

    const updateTheTour = () => {
        updateTour({
            name: "navigation",
            steps: getTourSteps(),
            beforeClose: closePreviousDropdown,
        });
    }

    const startTheTour = () => {
        if(isInit.current && !isOpen){
            triggerTour({
                name: "navigation",
                steps: getTourSteps(),
                beforeClose: closePreviousDropdown,
                nextButton: ({ btn, currentStep, stepsLength }) => (
                    (currentStep !== stepsLength - 1 && currentStep !== 0) ? btn : null
                ),
                prevButton: ({ btn, currentStep, stepsLength }) => (
                    (currentStep !== stepsLength - 1 && currentStep !== 0) ? btn : null
                ),
            });
        }else{
            updateTheTour();
        }
    }

    useEffect(() => {
        isInit.current = true;

        return () => {
            isInit.current = false;
        }
    }, []);

    return [ startTheTour, updateTheTour ];
}