export default{
    "catalog.registrationFees.rollover.dropdown.button"    : "Roulement",
    "catalog.registrationFees.rollover.title"              : "Roulement de frais d'inscription",
    "catalog.registrationFees.rollover.warning"            : "La saison courante possède déjà {amount} frais d'inscription. Veuillez vérifier si un roulement a déjà été fait pour minimiser les risques de données dupliquées.",
    "catalog.registrationFees.rollover.prevSeasons"        : "Saison précédente",
    "catalog.registrationFees.rollover.selectAll"          : "Tout sélectionner",
    "catalog.registrationFees.rollover.selectNone"         : "Tout désélectionner",
    "catalog.registrationFees.rollover.required"           : "Veuillez sélectionner au moins un frais d'inscription",
    "catalog.registrationFees.rollover.confirm"            : "Cliquez le bouton ci-dessous pour créer ces frais d'inscription dans la nouvelle saison.",
    "catalog.registrationFees.rollover.additionalFeesHint" : "Quelques-uns de ces frais d'inscription possèdent des frais d'inscription hâtives et/ou tardives. Nous avons préconfiguré la date de ces frais à la même que l'ancienne saison mais pour l'année {year}.",
    "catalog.registrationFees.rollover.additionalFields"   : "Nous avons également rempli les champs additionnels avec les réponses existantes de l'ancienne saison.",
    "catalog.registrationFees.rollover.earlyFee"           : "Frais d'inscription hâtive: {amount}",
    "catalog.registrationFees.rollover.earlyDate.required" : "La date et l'heure du frais hâtifs sont requises",
    "catalog.registrationFees.rollover.lateFee"            : "Frais d'inscription tardive: {amount}",
    "catalog.registrationFees.rollover.lateDate.required"  : "La date et l'heure du frais tardifs sont requises",
    "catalog.registrationFees.rollover.invalid"            : "Veuillez vérifier les données pour ce frais d'inscription",
}