import { stringBuilder } from "@spordle/helpers";
import { useContext, useEffect, useRef, useState } from "react"
import { exportToCsv } from "../../../../../../helpers/export";
import { SendCommModalContext } from "../../../../context/SendCommModalContext"
import SearchInput from "../searchInput/SearchInput";
import moment from "moment";
import { useIntl } from "react-intl";
import { Button, FormGroup, Label, ModalBody } from "reactstrap";
import CollapsibleCard from "../../../../../collapsibleCard/CollapsibleCard";
import Translate from "@spordle/intl-elements";
import UserDisplay from "../../../../../userDisplay/UserDisplay";
import styles from "./ReviewMembers.module.scss";
import InvalidRecipient from "../invalidRecipient/InvalidRecipient";
import PerfectScrollbar from 'react-perfect-scrollbar';
import CrossFade from "../../../../../crossFade/CrossFade";
import SearchRecipients from "./searchRecipients/SearchRecipients";
import ReviewFooter from "../ReviewFooter";
import DisplayRecipient from "../../../displayRecipient/DisplayRecipient";
import { Tooltip } from "@mantine/core";

const ReviewMembers = () => {
    const [ isSearch, setIsSearch ] = useState(false);
    const { formatMessage } = useIntl();
    const [ addedRecipients, setAddedRecipients ] = useState(0);
    const { setTheRecipients, recipients } = useContext(SendCommModalContext);
    const { all, valid, invalid } = recipients;
    const prevLength = useRef(valid.length);

    /**
     * @param {{ first_name: string }[]} list
     * @returns {function}
     */
    const handleOnDownloadRecipients = (list) => {
        return () => {
            exportToCsv(
                `SpordleID-CommunicationRecipients__${moment().format('YYYY-MM-DD_H:mm')}.csv`,
                [
                    [// Headers
                        formatMessage({ id: "form.fields.firstName" }),
                        formatMessage({ id: "form.fields.lastName" }),
                        formatMessage({ id: "members.search.memberSearch.filters.memberNumber" }),
                        formatMessage({ id: "form.fields.email" }),
                    ],
                    ...list.map((recipient) => ([
                        recipient.first_name,
                        recipient.last_name,
                        recipient.unique_identifier || "-",
                        (recipient.email || "-").trim(),
                    ])),
                ],
            )
        }
    }

    /**
     * Used when a contact email is set on an invalid member.
     * @param {string} member
     */
    const validateInvalid = (member) => {
        setTheRecipients((prev) => {
            const invalidIndex = prev.invalid.findIndex((recipient) => recipient.member_id === member.member_id);

            if(invalidIndex !== -1){
                const sortedInvalid = prev.invalid.reduce(
                    (sortedInvalid, invalid, index) => {

                        if(index !== invalidIndex){
                            sortedInvalid.newInvalid.push(invalid);
                        }else{
                            sortedInvalid.newValid.push(member);
                        }

                        return sortedInvalid;
                    }, { newInvalid: [], newValid: prev.valid },
                );

                return ({
                    ...prev,
                    invalid: sortedInvalid.newInvalid,
                    valid: sortedInvalid.newValid,
                })

            }

            return prev;
        })
    }

    /**
     * Function used to add a recipient to the list.
     * @param {object} recipient
     * @returns {boolean} Returns false is recipient wasn't added because it exists. Returns true otherwise.
     */
    const addRecipient = (recipient) => {
        const recipientExists = recipients.all.some((v) => v.member_id === recipient.member_id);
        const newRecipientIsValid = !!recipient.email;

        if(recipientExists){
            return false;
        }

        setTheRecipients((prev) => ({
            ...prev,
            valid: (recipientExists || !newRecipientIsValid) ? prev.valid : [ recipient, ...prev.valid ],
            all: recipientExists ? prev.all : [ recipient, ...prev.all ],
            invalid: (!recipientExists && !newRecipientIsValid) ? [ recipient, ...prev.invalid ] : prev.invalid,
        }));

        setIsSearch(false);

        return true;
    }

    /**
     * Function used to remove a recipient.
     * @param {object} recipient
     */
    const removeRecipient = (recipient) => {
        setTheRecipients((prev) => ({
            ...prev,
            valid: prev.valid.filter((re) => re.member_id !== recipient.member_id),
            all: prev.all.filter((re) => re.member_id !== recipient.member_id),
        }))
    }


    const handleOnRemove = (recipient) => {
        return () => {
            removeRecipient(recipient);
        }
    }

    useEffect(() => {
        let timeout = null;

        if(valid.length > prevLength.current){
            setAddedRecipients(valid.length - prevLength.current);

            timeout = setTimeout(() => {
                setAddedRecipients(0);
            }, 500);
        }

        prevLength.current = valid.length;

        return () => {
            clearTimeout(timeout);
        }

    }, [ valid.length ])

    const hasAtLeastOneMember = all.length > 0 && valid.length > 0;

    return (
        <>
            <CrossFade isVisible={isSearch}>
                <SearchRecipients
                    addRecipient={addRecipient}
                    onPrevious={() => setIsSearch(false)}
                />
            </CrossFade>
            <CrossFade isVisible={!isSearch}>
                <ModalBody>
                    <Label className="text-muted d-flex align-items-center">
                        <Translate id="communications.inbox.to" />
                    </Label>
                    {all.length > 1 ?
                        <>
                            {valid.length > 0 &&
                                <FormGroup>
                                    <CollapsibleCard
                                        className={stringBuilder({ "shadow-primary": addedRecipients > 0 })}
                                        arrowIsRight
                                        title={
                                            <div className="position-relative">
                                                <Translate id="components.communications.modal.label.recipients" /> (<span className={stringBuilder("transition", { "text-primary font-bold": addedRecipients > 0 })}>{valid.length}</span>)
                                            </div>
                                        }
                                    >
                                        <PerfectScrollbar className="max-vh-50">
                                            {valid.map((recipient) => (
                                                <DisplayRecipient
                                                    className="mb-2"
                                                    recipient={recipient}
                                                    key={`${recipient.email}-${recipient.member_id}`}
                                                >
                                                    <UserDisplay.Container className="ml-auto pl-2">
                                                        <Tooltip
                                                            withArrow
                                                            wrapLines
                                                            width={200}
                                                            label={<Translate id="communications.sendComm.recipients.delete.tooltip" />}
                                                        >
                                                            <Button
                                                                onClick={handleOnRemove(recipient)}
                                                                size="sm"
                                                                className="fas fa-trash text-danger"
                                                                type="button"
                                                                color="link"
                                                            />
                                                        </Tooltip>
                                                    </UserDisplay.Container>
                                                </DisplayRecipient>
                                            ))}
                                        </PerfectScrollbar>
                                    </CollapsibleCard>
                                    <div className="d-flex justify-content-end mt-2">
                                        <Tooltip
                                            withArrow
                                            wrapLines
                                            width={200}
                                            label={<Translate id="communications.message.recipients.download.valid" values={{ count: valid.length }} />}
                                        >
                                            <Button onClick={handleOnDownloadRecipients(valid)} className="rounded-lg" size="sm" type="button" color="light">
                                                <Translate id="misc.download" /><i className="mdi mdi-download ml-1" />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </FormGroup>
                            }
                            {invalid.length > 0 &&
                                <FormGroup>
                                    <div className={stringBuilder(styles.InvalidContainer, "rounded mb-3")}>
                                        <CollapsibleCard
                                            className="border-danger"
                                            arrowIsRight
                                            title={
                                                <div className="text-danger">
                                                    <Translate id="components.communications.modal.label.invalidRecipients" /> ({invalid.length})
                                                </div>
                                            }
                                        >
                                            {invalid.map((recipient) => (
                                                <InvalidRecipient
                                                    validateInvalid={validateInvalid}
                                                    recipient={recipient}
                                                    key={recipient.member_id}
                                                />
                                            ))}
                                        </CollapsibleCard>
                                    </div>
                                    <div className="d-flex align-items-start justify-content-between">
                                        <div className="small mr-2">
                                            <Translate id="components.communications.modal.helper.invalidRecipients" />
                                        </div>
                                        <Tooltip
                                            className="flex-shrink-0"
                                            wrapLines
                                            withArrow
                                            width={200}
                                            label={<Translate values={{ count: invalid.length }} id="communications.message.recipients.download.invalid" />}
                                        >
                                            <Button onClick={handleOnDownloadRecipients(invalid)} className="rounded-lg" type="button" color="light" size="sm">
                                                <Translate id='misc.download' /> <i className="mdi mdi-download" />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </FormGroup>
                            }
                        </>
                        :
                        valid.length === 1 ?
                            <div>
                                <DisplayRecipient
                                    recipient={valid[0]}
                                    className="flex-wrap flex-md-nowrap"
                                >
                                    <UserDisplay.Container className="ml-md-auto w-100 w-md-auto mt-2 mt-md-0">
                                        <Tooltip
                                            withArrow
                                            wrapLines
                                            width={200}
                                            label={<Translate id="communications.message.recipients.download.valid" values={{ count: 1 }} />}
                                        >
                                            <Button onClick={handleOnDownloadRecipients(valid)} className="rounded-lg" size="sm" type="button" color="light">
                                                <Translate id="misc.download" /><i className="mdi mdi-download ml-1" />
                                            </Button>
                                        </Tooltip>
                                    </UserDisplay.Container>
                                </DisplayRecipient>
                            </div>
                            :
                            invalid.length === 1 ?
                                <InvalidRecipient
                                    validateInvalid={validateInvalid}
                                    recipient={invalid[0]}
                                    key={invalid[0].member_id}
                                />
                                :
                                <div className="text-muted small">
                                    <Translate id="components.communications.modal.empty.recipients" />
                                </div>
                    }
                    <SearchInput addRecipient={addRecipient} />
                    <button onClick={() => setIsSearch(true)} className="reset-btn text-primary mb-3" type="button">
                        <Translate id="components.communications.modal.search.members.toggleAdvanced" />
                    </button>
                </ModalBody>
                <ReviewFooter disabled={isSearch || !hasAtLeastOneMember} />
            </CrossFade>
        </>
    )
}

export default ReviewMembers;