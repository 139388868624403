export default{
    "organization.profile.users.title" : "Utilisateurs de l'Organisation",

    "organization.profile.users.table.name"            : "Nom",
    "organization.profile.users.table.role"            : "Rôle",
    "organization.profile.users.table.email"           : "Courriel",
    "organization.profile.users.table.status"          : "Actif",
    "organization.profile.users.table.status.active"   : "Actif",
    "organization.profile.users.table.status.inactive" : "Inactif",
    "organization.profile.users.table.status.expired"  : "Expiré",
}