import SpordleTableProvider, { SearchInput, SpordleTableView } from '@spordle/datatables';
import Translate, { DateFormat } from '@spordle/intl-elements';
import moment from 'moment';
import { useContext, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, Container } from 'reactstrap';
import { AxiosCancelAll, AxiosIsCancelled } from '../../../api/CancellableAPI';
import StatusBadge from '../../../components/badges/StatusBadge';
import CheckboxStar from '../../../components/checkboxStar/CheckboxStar';
import CanDoAction from '../../../components/permissions/CanDoAction';
import SpordlePanelTable from '../../../components/sidePanel/SpordlePanel';
import EmptyLayout from '../../../components/table/EmptyLayout';
import { success } from '@spordle/toasts';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { ClinicsContext } from '../../../contexts/ClinicsContext';
import { I18nContext } from '../../../contexts/I18nContext';
import { DisplayI18n, displayI18n } from '../../../helpers/i18nHelper';
import ClinicCategory from '../ClinicCategory';
import AdvancedSearchSection from './AdvancedSearch';
import SidePanelClinicSearch from './SidePanelClinicSearch';

const ClinicSearch = (props) => {
    const [ , setActiveTab ] = useState('results');
    const clinicContext = useContext(ClinicsContext);
    const i18nContext = useContext(I18nContext);

    const getClinicLocation = (firstSession) => {
        if(firstSession.location_name)
            return firstSession.location_name;
        else if(firstSession.city && firstSession.province_code)
            return firstSession.city + ', ' + firstSession.province_code;
        else if(firstSession.province_code)
            return firstSession.province_code;
    }

    // componentWillUnmount
    useLayoutEffect(() => AxiosCancelAll, []);

    return (
        <SpordlePanelTable
            allowOutsideClick
            sidePanel={(props) => <SidePanelClinicSearch {...props} />}
            dataIndex='clinic_id'
            table={(panelProps) => (
                <SpordleTableProvider
                    id='clinics'
                    ref={panelProps.spordleTableRef}
                    tableHover bordered striped
                    clickable
                    searchKeys={[
                        { name: 'clinic_reference', weight: 2 },
                        `i18n.${i18nContext.getGenericLocale()}.name`,
                        'name',
                        `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                        'organisation.organisation_name',
                        'clinic_sessions.location_name',
                        'clinic_sessions.city',
                        'clinic_sessions.province_code',
                        'clinic_sessions.start_date',
                        'start_date',
                        'clinic_attendee_count',
                    ]}
                    dataIndex='clinic_id'
                    columns={[
                        {
                            label: <span><Translate id='clinics.search.table.id' values={{ number: <sup>o</sup> }} /></span>,
                            key: "clinic_reference",
                            mobile: true,
                            sortable: true,
                        },
                        {
                            label: <Translate id='clinics.search.table.qualification' />,
                            key: "qualificationName",
                            mobile: true,
                            sortable: true,
                        },
                        {
                            label: <Translate id='clinics.search.table.status' />,
                            key: "active_status",
                            mobile: true,
                            sortable: true,
                        },
                        {
                            label: <Translate id='clinics.search.table.startDate' />,
                            key: "start_date",
                            mobile: true,
                            sortable: true,
                        },
                        {
                            label: <Translate id='clinics.search.table.location' />,
                            key: "location",
                            mobile: true,
                            sortable: true,
                        },
                        {
                            label: <Translate id='clinics.search.table.clinic_attendee_count' />,
                            key: "clinic_attendee_count",
                            mobile: true,
                            sortable: true,
                        },
                        {
                            label: <Translate id='clinics.search.table.host' />,
                            key: "organisation_name",
                            mobile: true,
                            sortable: true,
                        },
                    ]}
                    onColumnClick={(e, clinic) => {
                        switch (e.button){
                            case 0: // Left mouse button
                                panelProps.onSingleSelectChange(clinic);
                                break;
                        }
                    }}
                    renderRow={(key, clinic, spordleTable) => {
                        switch (key){
                            case 'clinicFav':
                                return (
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <CheckboxStar
                                            checked={clinic.clinicFav}
                                            id={'clinicFav-' + clinic.clinic_id}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                spordleTable.updateDataWith(clinic.clinic_id, {
                                                    clinicFav: !clinic.clinicFav,
                                                }).then(() => {
                                                    success({ msg: !clinic.clinicFav ? 'clinics.search.table.column.favorite.favorite' : 'clinics.search.table.column.favorite.unfavorite' });
                                                })
                                            }}
                                        />
                                        {/* <button type='button' className='reset-btn' onClick={(e) => {
                                                e.stopPropagation();
                                                spordleTable.updateDataWith(clinic.clinic_id, {
                                                    clinicFav: !clinic.clinicFav
                                                }).then(() => {
                                                    React Swal Toast({
                                                        icon: 'success',
                                                        title: (!clinic.clinicFav ? 'clinics.search.table.column.favorite.favorite' : 'clinics.search.table.column.favorite.unfavorite')
                                                    })
                                                })
                                            }}
                                        >
                                            <i className={clinic?.clinicFav ? 'fas fa-star text-warning': 'far fa-star text-muted'}/>
                                        </button> */}
                                    </div>
                                )
                            case 'clinic_reference':
                                return <Link to={{ pathname: `/clinics/profile/${clinic.clinic_id}`, data: clinic }} className="d-flex align-items-center">{clinic.clinic_reference}<i className="ml-1 mdi mdi-chevron-right" /></Link>
                            case 'active_status':
                                if(clinic.sold_out){
                                    return <Badge color="danger"><Translate id='clinics.profile.attendees.addModal.exchange.soldOut' /></Badge>
                                }

                                const state = clinic.status?.find((status) => clinic.active_clinic_state_id === status.clinic_state?.clinic_state_id)?.clinic_state
                                return <StatusBadge color={state?.color_code} status={state} />
                            case 'qualificationName':
                                return (
                                    <ClinicCategory color={isNaN(parseInt(clinic.qualification?.qualification_category?.color)) ? 0 : clinic.qualification.qualification_category?.color}>
                                        <DisplayI18n field='name' defaultValue={clinic.name} i18n={clinic.i18n} />
                                    </ClinicCategory>
                                )
                            case 'location':
                                switch (clinic.location){
                                    case 'ONLINE':
                                        return (
                                            <>
                                                <i className='mdi mdi-play mr-1' />
                                                <Translate id='clinics.search.table.column.location.ONLINE' />
                                                <div className='text-muted small'>
                                                    {clinic.clinic_sessions[0].url_link}
                                                </div>
                                            </>
                                        );
                                    case 'ONLINE_LIVE':
                                        return (
                                            <>
                                                <i className='mdi mdi-access-point mr-1' />
                                                <Translate id='clinics.search.table.column.location.ONLINE_LIVE' />
                                                <div className='text-muted small'>
                                                    {clinic.clinic_sessions[0].url_link}
                                                </div>
                                            </>
                                        );
                                    case 'ONSITE':
                                        return (
                                            <>
                                                <i className='mdi mdi-map-marker mr-1' />{clinic.clinic_sessions[0] ? getClinicLocation(clinic.clinic_sessions[0]) : <Translate id='clinics.search.table.column.location.ONSITE' />}
                                            </>
                                        );
                                    default:
                                        return <Translate id='clinics.search.table.column.location.UNDEFINED' />;
                                }
                            case 'start_date':
                                if(clinic.start_date)
                                    return (
                                        <div>
                                            <div><DateFormat value={clinic.start_date} /></div>
                                            <div className='small'><DateFormat value={clinic.start_date} format={'HH:mm'} /></div>
                                        </div>
                                    )
                                else if(Array.isArray(clinic?.clinic_sessions) && clinic.clinic_sessions.length > 0 && clinic.clinic_sessions[0].start_date)
                                    return (
                                        <div>
                                            <div><DateFormat value={clinic.clinic_sessions[0].start_date} /></div>
                                            <div className='small'><DateFormat value={clinic.clinic_sessions[0].start_date} format={'HH:mm'} /></div>
                                        </div>
                                    )
                                return <Translate id='clinics.search.table.column.noStartDate' />;
                            default:
                                break;
                        }
                    }}
                    emptyLayout={<EmptyLayout translateMsg msg='clinics.search.table.emptyLayout.noResultTip' />}
                    desktopWhen='sm'
                    rowIsHighlighted={(clinic) => panelProps.selectedRows[0]?.clinic_id === clinic.clinic_id}
                    defaultSorting='+start_date'
                    pagination={10}
                    paginationMessage='clinics.search.table.paginationMessage'
                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                    initFilter={{
                        advancedSearch: {
                            organization: [],
                            category: [],
                            qualification: [],
                            level: [],
                            sessionFormat: [],
                            status: [],
                            startDate: '',
                            endDate: '',
                            onlyHosted: true,
                        },
                    }}
                    loadData={(from, data, spordleTable) => {
                        var apiObject = {}
                        const formatData = (clinics) => {
                            return clinics.map((clinic) => ({
                                ...clinic,
                                organisation_name: clinic.organisation.organisation_name,
                                location: clinic.clinic_sessions[0]?.session_format,
                            }))
                        }
                        switch (from){
                            // no CDM because in the advanced search filters we do a submit form one the clinic states are loaded
                            // that way we can send them all right from the start
                            case 'FILTER':
                                // We don't do anything with the periodId filter because the api call from the context already take the selected period
                                spordleTable.setLoading();
                                apiObject = {
                                    qualification_category_id: data.filters.advancedSearch.category,
                                    'end_date.lte': moment.isMoment(data.filters.advancedSearch.endDate) ? data.filters.advancedSearch.endDate.toISOString() : null,
                                    'start_date.gte': moment.isMoment(data.filters.advancedSearch.startDate) ? data.filters.advancedSearch.startDate.toISOString() : null,
                                    session_format: data.filters.advancedSearch.sessionFormat,
                                    qualification_level_id: data.filters.advancedSearch.level,
                                    qualification_id: data.filters.advancedSearch.qualification,
                                    clinic_state_id: data.filters.advancedSearch.status, // advanced search submit will always send all the statuses unless one is selected
                                    only_hosted_by_my_organisations: 1, // data.filters.advancedSearch.onlyHosted >>> 0 === 1 ? 1 : null,
                                };
                                if(data.filters.advancedSearch.organization.length !== 0)
                                    apiObject.organisation_id = data.filters.advancedSearch.organization;

                                return clinicContext.getClinics(apiObject).then(formatData).catch((err) => {
                                    if(AxiosIsCancelled(err.message))
                                        return true;
                                });
                            default:
                                break;
                        }
                    }}
                    columnSorting={(clinicA, clinicB, _spordleTable, order) => {
                        switch (order.column){
                            case 'qualificationName':
                                const clinicQualificationA = `${displayI18n('name', clinicA.qualification?.qualification_category?.i18n, clinicA.qualification?.qualification_category?.name, i18nContext.getGenericLocale())} ${displayI18n('name', clinicA.qualification?.i18n, clinicA.qualification?.name, i18nContext.getGenericLocale())}${clinicA.qualification?.qualification_level ? ' ' + displayI18n('name', clinicA.qualification?.qualification_level?.i18n, clinicA.qualification?.qualification_level?.name, i18nContext.getGenericLocale()) : ''}`;
                                const clinicQualificationB = `${displayI18n('name', clinicB.qualification?.qualification_category?.i18n, clinicB.qualification?.qualification_category?.name, i18nContext.getGenericLocale())} ${displayI18n('name', clinicB.qualification?.i18n, clinicB.qualification?.name, i18nContext.getGenericLocale())}${clinicB.qualification?.qualification_level ? ' ' + displayI18n('name', clinicB.qualification?.qualification_level?.i18n, clinicB.qualification?.qualification_level?.name, i18nContext.getGenericLocale()) : ''}`;
                                return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(clinicQualificationA, clinicQualificationB) * (order.order === 'ASC' ? 1 : -1);
                        }
                    }}
                >
                    {/*--------------------------------------------------------------------------------*/}
                    {/* View Header                                                                  */}
                    {/*--------------------------------------------------------------------------------*/}
                    <ViewHeaderV2 title='clinics.search.title' />

                    <div className={"page-content container-fluid px-4 pt-0 mt-0 mt-sm-n4"}>
                        <Container className="ml-0" style={{ maxWidth: "none" }}>

                            {/* CREATE CLINIC BUTTON */}
                            <div className="w-100 pb-3 d-flex align-items-center justify-content-end">
                                <CanDoAction action='ADD' componentCode='clinics' componentPermissionCode='clinics_manage'>
                                    <Button color='primary' className='ml-2' tag={Link} to='/clinics/create'>
                                        <i className='mdi mdi-plus mr-1' /><Translate id='clinics.search.createClinic' />
                                    </Button>
                                </CanDoAction>
                            </div>

                            {/* ADVANCED SEARCH */}
                            <Card body className='card-shadow'>
                                <AdvancedSearchSection setActiveTab={setActiveTab} />
                            </Card>

                            {/* TABS */}
                            {/* <Nav tabs className="mb-4 font-medium font-16 text-dark">
                                <NavItem>
                                    <NavLink
                                        active={activeTab === 'results'}
                                        href='#results'
                                    >
                                        <Translate id='clinics.search.tabs.search'/>
                                    </NavLink>
                                </NavItem>
                                {/* TODO - only display the favorite/starred clinics when this tab is active *
                                <NavItem>
                                    <NavLink
                                        active={activeTab === 'starred'}
                                        href='#starred'
                                    >
                                        <i className='fas fa-star text-warning'/> <Translate id='clinics.search.tabs.starred'/>
                                    </NavLink>
                                </NavItem>
                            </Nav> */}

                            {/* TABLE */}
                            <Card body className='card-shadow'>
                                {/* TABLE SEARCH & EXPORT */}
                                <div className="w-100 pb-3 d-flex align-items-center justify-content-between">
                                    <SearchInput />
                                </div>
                                <SpordleTableView />
                            </Card>
                        </Container>
                    </div>
                </SpordleTableProvider>
            )}
        />
    )
}

export default ClinicSearch;