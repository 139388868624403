import { stringBuilder } from "@spordle/helpers";
import PropTypes from 'prop-types';

/**
 * Component used for SidePanel Titles
 * @param {Object} props
 * @param {string|import("react").ReactElement} [props.tag=div]
 * @returns {JSX.Element}
 */
const SidePanelTitle = ({ tag: Tag = 'div', ...props }) => <Tag {...props} className={stringBuilder('font-bold h3 mb-0', props.className)} />;

export default SidePanelTitle;

SidePanelTitle.propTypes = {
    tag: PropTypes.oneOfType([ PropTypes.string, PropTypes.elementType ]),
}

SidePanelTitle.defaultProps = {
    tag: "div",
}