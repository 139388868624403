import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { InboxContext } from "../../inboxContext/InboxContext";
import ActionBtn from "./ActionBtn";

const MessagesActionsBtns = ({ isLoading, handleOnArchive, handleOnFavorite, handleOnMarkAsRead, handleOnDownloadMsg, handleOnPrintMsg }) => {
    const { selectedMsg: msg } = useContext(InboxContext);

    return (
        <div className="d-flex justify-content-end">
            {!msg.hideTools &&
                <>
                    <ActionBtn
                        icon="mdi-archive"
                        label={<Translate id={`communications.inbox.tools.${msg.archive == 1 ? "unarchive" : "archive"}`} />}
                        disabled={isLoading}
                        onClick={handleOnArchive}
                    />
                    <ActionBtn
                        label={<Translate id={`communications.inbox.tools.${msg.favorite == 1 ? "unfavorite" : "favorite"}`} />}
                        disabled={isLoading}
                        onClick={handleOnFavorite}
                        icon="mdi-star"
                    />
                    <ActionBtn
                        label={<Translate id={`communications.inbox.tools.mark${msg.is_read == 1 ? "Unread" : "Read"}`} />}
                        icon={`mdi-email${msg.is_read ? "-open" : ""}`}
                        disabled={isLoading}
                        onClick={handleOnMarkAsRead}
                    />
                </>
            }
            <ActionBtn
                icon="mdi-download"
                label={<Translate id="misc.download" />}
                disabled={isLoading}
                onClick={handleOnDownloadMsg}
            />
            <ActionBtn
                icon="mdi-printer"
                label={<Translate id="misc.print" />}
                disabled={isLoading}
                onClick={handleOnPrintMsg}
            />
        </div>
    );
}

export default MessagesActionsBtns;