export default{
    "catalog.otherItems.sidepanel.form.status"      : "Status",
    "catalog.otherItems.sidepanel.form.type"        : "Type",
    "catalog.otherItems.sidepanel.form.description" : "Description",
    "catalog.otherItems.sidepanel.form.price"       : "Price",
    "catalog.otherItems.sidepanel.form.tax"         : "Tax",
    "catalog.otherItems.sidepanel.form.sku"         : "SKU",

    "catalog.otherItems.sidepanel.error.3197" : "This item is already in use.",
    "catalog.otherItems.sidepanel.error.3191" : "Insufficient permissions.",
    "catalog.otherItems.sidepanel.error.3179" : "Forbidden access.",

    "catalog.otherItems.sidepanel.validation.name.required" : "Name is required",
}