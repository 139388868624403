import { forwardRef, useEffect, useState } from "react";
import { getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } from "../../helpers/browserStorage"

function deserializeJSON(value){
    try{
        return JSON.parse(value);
    }catch{
        return value;
    }
}

function isDisabled(){
    switch (process.env.REACT_APP_ENVIRONMENT){
        case 'int':
        case 'dev':
        case 'hcstage':
            return false;
        default:
            return false;
    }
}

/**
 * Hook to handle saving search to sessionStorage
 * @param {string} storageKey The sessionStorage key
 */
const useSavedSearch = (storageKey) => {
    const CUSTOM_EVENT_NAME = 'saveSearchEvent';
    const [ value, setValue ] = useState(deserializeJSON(getSessionStorageItem(storageKey)));

    function saveSearch(content){
        const serializedContent = JSON.stringify(content);
        setSessionStorageItem(storageKey, serializedContent);
        dispatchEvent(new CustomEvent(CUSTOM_EVENT_NAME, { detail: { storageKey } }));
    }

    function clearSavedSearch(){
        removeSessionStorageItem(storageKey);
        dispatchEvent(new CustomEvent(CUSTOM_EVENT_NAME, { detail: { storageKey } }));
    }

    useEffect(() => {
        /** @type {EventListenerOrEventListenerObject} */
        const onEvent = (e) => {
            // If event is triggered for this key
            if(e.detail.storageKey === storageKey){
                setValue(deserializeJSON(getSessionStorageItem(storageKey)));
            }
        }
        addEventListener(CUSTOM_EVENT_NAME, onEvent);
        return () => {
            removeEventListener(CUSTOM_EVENT_NAME, onEvent);
        }
    }, [])

    return {
        saveSearch,
        clearSavedSearch,
        savedSearch: isDisabled() ? null : value,
    }
}

/**
 * HOC that implements the `useSavedSearch` hook
 * @param {React.Component} Component Component to inject the saved search properties as props
 * @param {string} storageKey The sessionStorage key that will be passed to the hook
 * @param {string} [propsName='savedSearch'] Prop name. Defaults to `savedSearch`
 */
export function withSavedSearch(Component, storageKey, propsName = 'savedSearch'){
    return forwardRef((props, ref) => <Component {...props} ref={ref} {...{ [propsName]: useSavedSearch(storageKey) }} />)
}

export default useSavedSearch;