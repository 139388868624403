import QuestionTypeChoices from './QuestionTypeChoices';
import QuestionTypeField from './QuestionTypeField';

/**
 * @description Renders the appropriate for layout according to the wanted question type
 * @param {object} props
 * @param {import('../../../../../../contexts/FormsContext').FormField['code']} props.questionType
 */
const QuestionTypeRenderer = ({ questionType, ...props }) => {
    switch (questionType){
        case "TEXT":
        case "TEXT_AREA":
        case "DATE":
        case "DATETIME":
        case "NUMBER":
            return <QuestionTypeField {...props} type={questionType} />
        case "SELECTBOX_MULTI":
        case "SELECTBOX":
        case "RADIO":
        case "CHECKBOX":
            return <QuestionTypeChoices {...props} type={questionType} />;

        default:
            return <div />
    }
}

export default QuestionTypeRenderer;