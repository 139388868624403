import Translate from '@spordle/intl-elements'
import React from 'react'
import { Card, ListGroup, ListGroupItemHeading } from 'reactstrap'
import CardSectionTitle from '../../../../../components/CardSectionTitle'
import { DisplayI18n } from '../../../../../helpers/i18nHelper'
import useSWR from 'swr'
import { getValidatedQualificationRules } from '../../../../../api/client/teams';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI'
import { fail } from '@spordle/toasts'
import { groupByAsArray } from '@spordle/helpers'
import TeamSettingsCategoryQualificationsRow from './TeamSettingsCategoryQualificationsRow'

const TeamSettingsCategoryQualifications = ({ teamId, canSeeMember }) => {

    const { data, error } = useSWR(
        [ 'getTeamsCategoryQualificationRules', teamId ],
        () => getValidatedQualificationRules(teamId)
            .then((categoryQualificationRules) => {
                const [ positionRules, positionGroupsRules ] = categoryQualificationRules.reduce((splitQualifications, rule) => {
                    if(rule.position?.position_id){
                        splitQualifications[0].push({ ...rule, position_id: rule.position.position_id });
                    }else if(rule.position_group?.position_group_id){
                        splitQualifications[1].push({ ...rule, position_group_id: rule.position_group.position_group_id });
                    }
                    return splitQualifications;
                }, [ [], [] ])

                return [
                    groupByAsArray(positionRules, 'position_id'),
                    groupByAsArray(positionGroupsRules, 'position_group_id'),
                ]
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }),
        {
            revalidateOnFocus: false,
            fallbackData: [],
        },
    )

    const [ qualPositionRules, qualPositionGroupRules ] = data || [];

    if((qualPositionRules?.length || 0) + (qualPositionGroupRules?.length || 0) > 0){
        return (
            <Card className='card-shadow' body>
                <CardSectionTitle title='teams.profile.teamSetting.settings.categoryQualifications.title' />
                {error ?
                    <Translate id='misc.error' />
                    :
                    <>
                        {qualPositionGroupRules.length > 0 && <div className='h5 font-bold mb-2'><Translate id="settings.tabs.positionGroups" /></div>}
                        {qualPositionGroupRules.map((group) => (
                            <React.Fragment key={group.key}>
                                <div className="border rounded p-3 mb-3">
                                    <ListGroup flush>
                                        <ListGroupItemHeading className="h6 font-bold">
                                            <DisplayI18n field='name' defaultValue={group.values[0].position_group.name} i18n={group.values[0].position_group.i18n} />
                                        </ListGroupItemHeading>
                                        {group.values.map((categoryQualificationRule) => (
                                            <TeamSettingsCategoryQualificationsRow
                                                categoryQualificationRule={categoryQualificationRule}
                                                key={categoryQualificationRule.qualification.qualification_id}
                                                canSeeMember={canSeeMember}
                                            />
                                        ))}
                                    </ListGroup>
                                </div>
                            </React.Fragment>
                        ))}
                        {qualPositionRules.length > 0 && <div className='h5 font-bold mb-2'><Translate id="settings.tabs.teams.positions" /></div>}
                        {qualPositionRules.map((group) => (
                            <React.Fragment key={group.key}>
                                <div className="border rounded p-3 mb-3">
                                    <ListGroup flush>
                                        <ListGroupItemHeading className="h6 font-bold">
                                            <DisplayI18n field='name' defaultValue={group.values[0].position.name} i18n={group.values[0].position.i18n} />
                                        </ListGroupItemHeading>
                                        {group.values.map((categoryQualificationRule) => (
                                            <TeamSettingsCategoryQualificationsRow
                                                categoryQualificationRule={categoryQualificationRule}
                                                key={categoryQualificationRule.qualification.qualification_id}
                                                canSeeMember={canSeeMember}
                                            />
                                        ))}
                                    </ListGroup>
                                </div>
                            </React.Fragment>
                        ))}
                    </>
                }
            </Card>
        )
    }

    return null
}

export default TeamSettingsCategoryQualifications
