import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';
import Translate from '@spordle/intl-elements';
import queryString from 'query-string';
import SpordleSelect from '@spordle/spordle-select';
import { useContext, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Card, Collapse, DropdownItem, DropdownMenu, DropdownToggle, ModalBody, ModalFooter, ModalHeader, UncontrolledDropdown } from 'reactstrap';
import useSWR from 'swr';
import AnalyticsModal from '../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import CustomAlert from '../../../components/CustomAlert';
import OverlayLoader from '../../../components/loading/OverlayLoader';
import CanDoAction from '../../../components/permissions/CanDoAction';
import EmptyLayout from '../../../components/table/EmptyLayout';
import { fail } from "@spordle/toasts";
import { PeriodsContext } from '../../../contexts/contexts';
import { I18nContext } from '../../../contexts/I18nContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { RolesContext } from '../../../contexts/RolesContext';
import { TeamsContext } from '../../../contexts/TeamsContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { displayName } from './helpers';

const TeamsSettingsList = (props) => {
    const teamsContext = useContext(TeamsContext);
    const orgContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext);
    const rolesContext = useContext(RolesContext);
    const genericLocale = useContext(I18nContext).getGenericLocale();
    const location = useLocation();

    const spordleTableRef = useRef()
    const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
    const [ group, setGroup ] = useState(false);


    const { data: organizations } = useSWR(
        [ 'getTeamSettingsTree' ],
        () => teamsContext.getTeamSettingsTree(orgContext.organisation_id, periodsContext.selectedPeriod.period_id)
            .then((orgs) => orgs.sort((a, b) => b.has_team_settings - a.has_team_settings))
            .then((orgs) => orgs.sort((a, b) => a.organisation_id === orgContext.organisation_id ? -1 : 1))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        ,
        {
            revalidateOnMount: true,
            fallbackData: [],
        },
    );

    /**
     * Acts as a groupByAsArray but with 2 different groups (division_id and team_category_id)
     */
    const formatData = (teamSettings) => {
        const addToGroup = (groups, teamSetting, id) => {
            const currentGroup = groups.find((group) => group.key === id)
            if(currentGroup){
                currentGroup.values.push(teamSetting)
            }else{
                groups.push({
                    key: id,
                    values: [ teamSetting ],
                })
            }
        }

        return teamSettings.reduce((groups, teamSetting) => {
            if(teamSetting.setting.team_setting_category.division){
                addToGroup(groups, teamSetting, teamSetting.setting.team_setting_category.division.division_id)
            }else{
                addToGroup(groups, teamSetting, teamSetting.setting.team_setting_category.team_category.team_category_id)
            }
            return groups
        }, [])
    }

    const canEdit = rolesContext.canDoAction('EDIT', 'teams', 'team_settings');

    return (
        <Card body className="card-shadow">
            <TeamSettingDeleteModal
                deleteModalOpen={deleteModalOpen}
                setDeleteModalOpen={setDeleteModalOpen}
                group={group}
                refreshTable={spordleTableRef.current?.refreshTable}
                orgId={spordleTableRef.current?.getFilters().organisationId}
            />
            <SpordleTableProvider
                id='TeamsSettingsListTable'
                dataIndex='key'
                viewMode='GRID'
                ref={spordleTableRef}
                key={organizations}
                defaultSearchValue={queryString.parse(location.search).search}
                searchKeys={[
                    `values.setting.team_setting_category.division.i18n.${genericLocale}.short_name`,
                    `values.setting.team_setting_category.division.i18n.${genericLocale}.name`,
                    `values.setting.team_setting_category.division.short_name`,
                    `values.setting.team_setting_category.division.name`,

                    `values.setting.team_setting_category.team_category.division.i18n.${genericLocale}.short_name`,
                    `values.setting.team_setting_category.team_category.division.i18n.${genericLocale}.name`,
                    `values.setting.team_setting_category.team_category.division.short_name`,
                    `values.setting.team_setting_category.team_category.division.name`,

                    `values.setting.team_setting_category.team_category.class.i18n.${genericLocale}.short_name`,
                    `values.setting.team_setting_category.team_category.class.i18n.${genericLocale}.name`,
                    `values.setting.team_setting_category.team_category.class.short_name`,
                    `values.setting.team_setting_category.team_category.class.name`,

                    `values.setting.team_setting_category.team_category.gender`, // TODO: Need to bring the i18n data to the formateData so the i18n is in the table data, then it would be possible to search on it but I don't have time to do it and it's friday at 4:53 and it's finally sunny and 20 degrees outside so I'm trying to get this done
                ]}
                gridConfig={{
                    col: { xs: 12, md: 6, lg: 4 },
                    row: { form: true },
                    gridRender: (group, spordleTable) => {
                        const org = organizations.find((org) => org.organisation_id === spordleTable.getFilters().organisationId)
                        return (
                            <Card className="border p-3 card-shadow mb-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        {canEdit ?
                                            <Link to={{ pathname: 'edit', teamSettings: group.values, organization: org }}>
                                                <div className='d-flex'><div className='text-nowrap'>{displayName(group.values)}</div><i className="mdi mdi-chevron-right ml-1" /></div>
                                            </Link>
                                            :
                                            <>
                                                {displayName(group.values)}<i className="mdi mdi-chevron-right ml-1" />
                                            </>
                                        }
                                    </div>
                                    <div className="text-right">
                                        <UncontrolledDropdown tag="span">
                                            <DropdownToggle color="link" className="w-25">
                                                <i className="mdi mdi-dots-vertical font-22" />
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <CanDoAction action='EDIT' componentCode='teams' componentPermissionCode='team_settings'>
                                                    <DropdownItem tag={Link} to={{ pathname: 'edit', teamSettings: group.values, organization: org }}><Translate id='misc.edit' /></DropdownItem>
                                                    <DropdownItem tag={Link} to={{ pathname: 'create', teamSettings: group.values, organization: org }}><Translate id='settings.teamsSettings.list.duplicate' /></DropdownItem>
                                                    <CanDoAction action='DELETE' componentCode='teams' componentPermissionCode='team_settings'>
                                                        <DropdownItem
                                                            onClick={() => {
                                                                setGroup(group);
                                                                setDeleteModalOpen(true);
                                                            }}
                                                        >
                                                            <Translate id='misc.delete' />
                                                        </DropdownItem>
                                                    </CanDoAction>
                                                </CanDoAction>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </Card>
                        )
                    },
                }}
                pagination={21}
                initFilter={{
                    organisationId: location.orgId || orgContext.organisation_id,
                }}
                loadData={(from, _filters, spordleTable) => {
                    switch (from){
                        case 'REFRESH':
                        case 'FILTER':
                            spordleTable.setLoading();
                        case 'CDM':
                            return teamsContext.getTeamsSettings({ organisation_id: _filters.filters.organisationId })
                                .then((data) => formatData(data).sort((a, b) => a.values[0].setting.team_setting_category.division?.short_name > b.values[0].setting.team_setting_category.division?.short_name ? 1 : -1));
                        default:
                            break;
                    }
                }}
                emptyLayout={<EmptyLayout />}
            >
                {(spordleTable) => (
                    <>
                        <div className='mb-2'>
                            <div className='d-flex flex-wrap justify-content-between'>
                                <div className='d-flex'>
                                    <SearchInput />
                                    <div className='ml-2' style={{ minWidth: '300px' }}>
                                        <SpordleSelect
                                            name='teamSettingsTree'
                                            id='teamSettingsTree'
                                            renderOption={(option) => (
                                                <div>
                                                    <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                                    {option.option.abbreviation &&
                                                        <span className='text-muted ml-1'>({option.option.abbreviation})</span>
                                                    }
                                                    {option.option.hasSettings && option.option.value !== orgContext.organisation_id &&
                                                        <div className='small'>
                                                            <i className='text-warning mr-1 mdi mdi-alert-outline' /><Translate id='settings.teamsSettings.list.hasTeamsSettings' />
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                            onOptionSelected={(values) => {
                                                spordleTable.filterChange('organisationId', values[0])
                                            }}
                                            values={[ spordleTable.getFilters().organisationId ]}
                                            options={organizations.map((org) => ({
                                                value: org.organisation_id,
                                                label: org.organisation_name,
                                                i18n: org.i18n,
                                                abbreviation: org.abbreviation,
                                                hasSettings: org.has_team_settings == 1,
                                            }))}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <Refresh />
                                    <CanDoAction action='ADD' componentCode='teams' componentPermissionCode='team_settings'>
                                        <Button color="primary" className='ml-2' tag={Link} to={{ pathname: 'create', organization: organizations.find((org) => org.organisation_id === spordleTable.getFilters().organisationId) }}>
                                            <i className='mdi mdi-plus mr-1' /><Translate id='misc.add' />
                                        </Button>
                                    </CanDoAction>
                                </div>
                            </div>
                        </div>
                        <SpordleTableView />
                    </>
                )}
            </SpordleTableProvider>
        </Card>
    );
}

const TeamSettingDeleteModal = ({ setDeleteModalOpen, deleteModalOpen, group, refreshTable, orgId }) => {
    const teamsContext = useContext(TeamsContext);
    const periodsContext = useContext(PeriodsContext);

    const [ errorMsg, setErrorMsg ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);

    return (
        <AnalyticsModal analyticsName='TeamSettingDeleteModal' isOpen={deleteModalOpen}>
            <OverlayLoader isLoading={isLoading}>
                <ModalHeader>
                    <Translate id='misc.delete' />
                </ModalHeader>
                <ModalBody className='text-center d-block'>
                    <div>
                        <i style={{ fontSize: 75 }} className={`mdi mdi-alert-outline text-danger`} />
                    </div>
                    <div>
                        <Translate id='settings.teamsSettings.deleteMessage' />
                    </div>
                    <div className='h3 font-bold'>{group.values && displayName(group.values)}</div>
                    <div>
                        <Collapse className='px-2' isOpen={!!errorMsg} mountOnEnter unmountOnExit>
                            {!!errorMsg &&
                                <CustomAlert
                                    className='mt-3 text-left'
                                    color='danger'
                                    withTitle
                                    text={errorMsg}
                                    translateText={false}
                                    toggle={() => setErrorMsg('')}
                                />
                            }
                        </Collapse>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            setIsLoading(true);

                            teamsContext.deleteTeamSettings({
                                organisation_id: orgId,
                                period_id: periodsContext.selectedPeriod.period_id,
                                division_id: group.values?.[0].setting.team_setting_category.division?.division_id || null,
                                team_category_id: group.values?.[0].setting.team_setting_category.team_category?.team_category_id || null,
                            }).then(() => {
                                setIsLoading(false);
                                setDeleteModalOpen(!deleteModalOpen)
                                refreshTable();
                            }).catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            })
                        }} color='danger'
                    ><Translate id='misc.delete' />
                    </Button>
                    <Button
                        color='primary' outline
                        onClick={() => {
                            setDeleteModalOpen(!deleteModalOpen)
                        }}
                    ><Translate id='misc.cancel' />
                    </Button>
                </ModalFooter>
            </OverlayLoader>
        </AnalyticsModal>
    )
}


export default TeamsSettingsList;