import { useContext } from "react"
import useSWR from "swr";
import { NewsContext } from "../../contexts/NewsContext";
import BannerInfo from "../../assets/images/news/banner_information.png";
import BannerWarning from "../../assets/images/news/banner_warning.png";
import BannerCritical from "../../assets/images/news/banner_critical.png";
import BannerThankYou from "../../assets/images/news/banner_thank_you_en.jpg";
import BannerThankYouFr from "../../assets/images/news/banner_thank_you_fr.jpg";
import BannerNewYear from "../../assets/images/news/banner_happy_newyear_en.jpg";
import BannerNewYearFr from "../../assets/images/news/banner_happy_newyear_fr.jpg";
import moment from "moment";

export const USER_LIST_KEY = "news-list";
export const NEWS_ID_PREFIX = "notice-";

export const useBannersSWR = () => {
    const { getNewsBanners } = useContext(NewsContext);
    const SWRData = useSWR(
        'getNewsBanners',
        () => getNewsBanners()
            .catch((e) => {
                console.error(e);
                return [];
            }),
        {
            fallbackData: [],
        },
    )

    return SWRData;
}

export const NEWS_TYPE_DATA = {
    "INFORMATION": {
        color: "primary",
        icon: "mdi-information-outline",
        img: BannerInfo,
        name: "Information",
    },
    "WARNING": {
        color: "warning",
        icon: "mdi-alert-outline",
        img: BannerWarning,
        name: "Warning",
    },
    "CRITICAL": {
        color: "danger",
        icon: "mdi-close-circle-outline",
        img: BannerCritical,
        name: "Critical",
    },
    "THANK_YOU": {
        color: "primary",
        icon: "mdi-close-circle-outline",
        img: BannerThankYou,
        i18n: {
            fr: {
                img: BannerThankYouFr,
            },
            en: {
                img: BannerThankYou,
            },
        },
        name: "Thank You",
    },
    "HAPPY_NEW_YEAR": {
        color: "primary",
        icon: "mdi-close-circle-outline",
        img: BannerNewYear,
        i18n: {
            fr: {
                img: BannerNewYearFr,
            },
            en: {
                img: BannerNewYear,
            },
        },
        name: "Thank You",
    },
};

const isBetweenDates = (start, end) => {
    const momentTarget = moment();
    return momentTarget.isSameOrAfter(start) && (!end || momentTarget.isSameOrBefore(end));
}

export const newsIsLive = (news) => {
    return news.active == 1 && (!news.published_at || isBetweenDates(news.published_at, news.published_until));
}