import { stringBuilder } from "@spordle/helpers";
import { useField } from "formik";

/**
 * @param {Object} props
 * @param {React.ReactNode} props.label
 * @param {string} props.id
 * @param {string} props.name
 * @param {string} props.value
 * @returns {JSX.Element}
 */
const FormikRadioBtn = ({ label, className, btnClassName, ...props }) => {
    const [ field ] = useField({ ...props, type: 'radio' });

    return (
        <label className={stringBuilder("radio-btn-label", className)} htmlFor={props.id}>
            <input {...field} {...props} className="d-none" type="radio" />
            <span className={stringBuilder(btnClassName, { "disabled": props.disabled }, "radio-btn btn")}>
                {label}
            </span>
        </label>
    );
}

export default FormikRadioBtn;