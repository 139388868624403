import { Tooltip } from "@mantine/core";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Link } from "react-router-dom";
import { BreadcrumbItem } from "reactstrap";

const BreadCrumbsItem = ({ path, crumbsLength, crumb, isLastLink }) => {
    return (
        <BreadcrumbItem
            className={stringBuilder("sid-breadcrumbs-item line-height-1")}
            style={{ maxWidth: `${Math.floor(100 / (crumbsLength + 1))}%` }}
        >
            {(path && !crumb.noLink) ?
                <Tooltip
                    withArrow
                    zIndex={2000}
                    label={`${window.origin}${path}`}
                >
                    <Link
                        to={path}
                        className="sid-breadcrumbs-link line-height-1"
                    >
                        <span className="text-truncate py-1 my-n1 d-inline-block w-100">
                            {crumb.skipTranslate ? crumb.name : <Translate id={crumb.name} />}
                        </span>
                    </Link>
                </Tooltip>
                :
                <span className="sid-breadcrumbs-text line-height-1 d-block min-w-0">
                    <span className="text-truncate py-1 my-n1 d-inline-block w-100">
                        {crumb.skipTranslate ? crumb.name : <Translate id={crumb.name} />}
                    </span>
                </span>
            }
        </BreadcrumbItem>
    );
}

export default BreadCrumbsItem;