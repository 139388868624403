export default{
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.title.contact"    : "Contact information",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.title.instructor" : "Instructor information",

    // formik editables
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.update.contacts"    : "A contact already exists with this information",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.update.instructors" : "An instructor already exists with this information",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.settings"           : "Additional settings",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.notify"             : "Notify of new registrations",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.accessInfo"         : "Access Information",

    // actions
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.giveAccess.btn"                    : "Click here to give access to this intructor",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.giveAccess.modal.title"            : "Give access to instructor",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove"                    : "Remove",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove.text.contact"       : "You are about to remove this contact.",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove.success.contact"    : "Contact deleted!",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove.text.instructor"    : "You are about to remove this instructor.",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove.success.instructor" : "Instructor deleted!",

    // tabs
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.tabs.details" : "Details",
}