import React from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { object, string } from 'yup';
import { Button, ModalBody, ModalFooter, Collapse, Label } from 'reactstrap';
import Translate from '@spordle/intl-elements';
import { FormikGroup, FormikRadioButton } from '@spordle/formik-elements';
import CustomAlert from '../../../../../../../components/CustomAlert';
import UserDisplay from '../../../../../../../components/userDisplay/UserDisplay';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import { stringBuilder } from "@spordle/helpers";

class MemberProfileRegistrationAddExchange extends React.Component{

    constructor(props){
        super(props)

        this.registrationsItems = props.invoiceItems.filter((item) => item.registration_fee && this.props.member.member_id === item.member.member_id)
        this.otherItems = props.invoiceItems.filter((item) => item.other_fee && this.props.member.member_id === item.member.member_id)

        const activeRegistrations = this.registrationsItems.filter((item) => !item.cancelled_at);
        const activeOtherItems = this.otherItems.filter((item) => !item.cancelled_at);
        this.initialItem = this.props.memberProfileRegistrationAddContext.viewsInfo.selectedExchangeItem || activeRegistrations[0] || activeOtherItems[0] || null;
        this.initialItemId = this.initialItem?.invoice_item_id || '';
    }

    render(){
        return (
            <Formik
                initialValues={{
                    itemId: this.initialItemId,
                    item: this.initialItem,
                }}
                validationSchema={object().shape({
                    itemId: string().required(<Translate id='memberProfile.registration.exchange.items.required' />),
                })}
                onSubmit={(values) => {
                    this.props.memberProfileRegistrationAddContext.viewsInfo.selectedExchangeItem = values.item;
                    this.props.memberProfileRegistrationAddContext.goToView(this.props.memberProfileRegistrationAddContext.views.registrationInfo)
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalBody>
                            {this.registrationsItems.length > 0 &&
                                <>
                                    <Label className='font-bold'><Translate id='memberProfile.registration.exchange.items.registrations.label' values={{ items: this.registrationsItems.length }} /></Label>
                                    <FormikGroup name='itemId' rowProps={{ className: 'mx-0 d-block' }}>
                                        {this.registrationsItems.map((item) => (
                                            <ItemCard key={item.invoice_item_id} item={item} />
                                        ))}
                                    </FormikGroup>
                                </>
                            }

                            {this.otherItems.length > 0 &&
                                <>
                                    <Label className='font-bold'><Translate id='memberProfile.registration.exchange.items.others.label' values={{ items: this.otherItems.length }} /></Label>
                                    <FormikGroup name='itemId' rowProps={{ className: 'mx-0 d-block' }}>
                                        {this.otherItems.map((item) => (
                                            <ItemCard key={item.invoice_item_id} item={item} />
                                        ))}
                                    </FormikGroup>
                                </>
                            }

                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                {formik.status &&
                                    <CustomAlert className='mt-3' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                }
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color='primary' outline
                                type='button'
                                onClick={() => {
                                    this.props.memberProfileRegistrationAddContext.goToView(
                                        this.props.memberProfileRegistrationAddContext.views.exchangeWarning,
                                    )
                                }}
                                className="mr-auto"
                            ><Translate id='misc.previous' />
                            </Button>
                            <Button color='primary' type='submit'><Translate id='misc.next' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        )
    }
}

const ItemCard = ({ item }) => {
    const formik = useFormikContext();
    const fee = item.registration_fee?.fee || item.other_fee.fee || null;
    const disabled = !!item.cancelled_at;

    return (
        <UserDisplay
            tag="label" htmlFor={item.invoice_item_id} card
            hover={!disabled}
            className={stringBuilder('mb-2 p-4 w-100 font-medium text-dark', { 'border-primary': formik.values.itemId === item.invoice_item_id, 'text-muted': disabled })}
        >
            <UserDisplay.Container>
                <FormikRadioButton
                    id={item.invoice_item_id}
                    name='itemId'
                    value={item.invoice_item_id}
                    onChange={() => {
                        formik.setFieldValue('itemId', item.invoice_item_id)
                        formik.setFieldValue('item', item)
                    }}
                    disabled={!!disabled}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <DisplayI18n field="name" defaultValue={fee.name} i18n={fee.i18n} />
                {disabled &&
                    <div className='small mt-n1'><Translate id='memberProfile.registration.exchange.items.alreadyCancelled' /></div>
                }
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default MemberProfileRegistrationAddExchange