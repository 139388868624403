import Translate from '@spordle/intl-elements';
import { success } from '@spordle/toasts';
import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Badge,
    Button, Card, Col, Collapse, FormGroup, ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import { object } from 'yup';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { updateMemberRequestChangeStatus } from '../../../../api/client/memberRequestChanges';
import CrossFade from '../../../../components/crossFade/CrossFade';
import CustomAlert from '../../../../components/CustomAlert';
import CustomAnimatedIcon from '../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import QuickViewButton from '../../../../components/quickView/QuickViewButton';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { DisplayI18n } from '../../../../helpers/i18nHelper';

//IMPORTANT NOTE: CHAINING MULTIPLE DOCUMENTS IS NOT CURRENTLY COMPATIBLE WITH memberProfileMode
const MemberChangeRequestsModal = ({ toggle, isOpen, selectedRows, spordleTable, forceCloseSidePanel, changeRequestStatuses }) => {
    // the attachment currently being approved, this is made this way to support approval of multiple attachments in a row
    const [ currentlyApprovingRow, setCurrentlyApprovingRow ] = useState(selectedRows[0]);
    // for chaining multiple documents one after one another
    const [ currentRowIndex, setCurrentRowIndex ] = useState(0);

    // state of buttons at bottom of page
    // possible state: 'INITIAL', 'DECLINED', 'APPROVED'
    const [ buttonState, setButtonState ] = useState('INITIAL');

    // hasApprovedAtLeastOne
    const [ hasApprovedAtLeastOne, setHasApprovedAtLeastOne ] = useState(false);


    // for single approval and so that the first attachment is in state at first open
    useEffect(() => {
        setCurrentlyApprovingRow(selectedRows[0])
    }, [ selectedRows[0].member_request_change_id ])

    const nextRowIndex = currentRowIndex + 1;

    const nextApproval = async(formik) => {
        if(nextRowIndex !== selectedRows.length){
            await setCurrentlyApprovingRow(selectedRows[nextRowIndex])
            setCurrentRowIndex(nextRowIndex)
        }else{
            // show success dialog
            formik.setStatus('success')
        }
    }

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='memberChangeRequestModal'>
            <Formik
                initialValues={{}}
                onSubmit={(data, formik) => {
                    formik.setSubmitting(true)

                    const newStatus = changeRequestStatuses.find((status) => status.code === buttonState)

                    return updateMemberRequestChangeStatus(currentlyApprovingRow.member_request_change_id, { member_request_change_status_id: newStatus.member_request_change_status_id })
                        .then(() => {
                            setHasApprovedAtLeastOne(true);
                            if(selectedRows.length === 1){
                                formik.setStatus('success');
                            }else if(selectedRows.length > 1){
                                setButtonState('INITIAL');
                                success();
                                nextApproval(formik);
                            }
                        }).catch((error) => {
                            formik.setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            if(AxiosIsCancelled(error.message)){
                                console.error(error.message);
                                formik.setSubmitting(false);
                            }
                        })

                }}
                validationSchema={object().shape({})}
            >
                {(formik) => (
                    <>
                        <ModalHeader toggle={() => {
                            if(hasApprovedAtLeastOne){
                                spordleTable.refreshTable();
                                forceCloseSidePanel();
                            }
                            setButtonState("INITIAL")
                            toggle();
                        }}
                        >
                            <Translate id='task.mandatoryChangesRequests.modal.title' />
                        </ModalHeader>
                        <Form>
                            <CrossFade isVisible={formik.status !== 'success'}>
                                <OverlayLoader isLoading={formik.isSubmitting}>
                                    <ModalBody>
                                        <Card className='card-shadow px-3 py-3'>
                                            <UserDisplay className='w-100'>
                                                <UserDisplay.Container>
                                                    <UserImg
                                                        alt={currentlyApprovingRow.member.first_name + ' ' + currentlyApprovingRow.member.last_name}
                                                        abbr={currentlyApprovingRow.member.first_name.charAt(0) + currentlyApprovingRow.member.last_name.charAt(0)}
                                                        src={currentlyApprovingRow.picture?.full_path}
                                                        filePos={currentlyApprovingRow.picture?.file_position}
                                                        width={60}
                                                        className="mr-2"
                                                    />
                                                </UserDisplay.Container>
                                                <UserDisplay.Container className="w-100">
                                                    <UserDisplay.Title className="d-flex justify-content-between">
                                                        <div className='d-flex justify-content-start'>
                                                            <QuickViewButton member={{ member_id: currentlyApprovingRow.member_id }}>
                                                                {currentlyApprovingRow.member.first_name + ' ' + currentlyApprovingRow.member.last_name}
                                                            </QuickViewButton>
                                                        </div>
                                                    </UserDisplay.Title>
                                                    <UserDisplay.Subtitle>
                                                        <Link className="text-nowrap" to={`/members/profile/${currentlyApprovingRow.member_id}`}>
                                                            {(currentlyApprovingRow.member.unique_identifier) ?
                                                                <>#{currentlyApprovingRow.member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                                :
                                                                '-'
                                                            }
                                                        </Link>
                                                    </UserDisplay.Subtitle>
                                                </UserDisplay.Container>
                                            </UserDisplay>
                                        </Card>
                                        <Row form>
                                            <Col md='6'>
                                                <FormGroup>
                                                    <div className='text-muted d-flex'>
                                                        <Translate id='task.mandatoryChangesRequests.sidepanel.request.status' />
                                                    </div>
                                                    {/* hardcoded because this API call only returns pending member change requests */}
                                                    <Badge color={"warning"}><Translate id='reports.column.status.PENDING' /></Badge>
                                                </FormGroup>
                                            </Col>
                                            <Col md='6'>
                                                <FormGroup>
                                                    <div className='text-muted d-flex'>
                                                        <Translate id='task.mandatoryChangesRequests.sidepanel.request.requested_by' />
                                                    </div>
                                                    {currentlyApprovingRow.created_by ?
                                                        <div className="">
                                                            <div className="mb-0">{currentlyApprovingRow.created_by.name + ' ' + currentlyApprovingRow.created_by.family_name}</div>
                                                            <a href={`mailto:${currentlyApprovingRow.created_by.email}`}>{currentlyApprovingRow.created_by.email}</a>
                                                        </div>
                                                        :
                                                        ' - '
                                                    }
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className='h5 font-bold'><Translate id='task.mandatoryChangesRequests.sidepanel.request.data' /></div>
                                        <Card body className='card-shadow'>
                                            <Row>
                                                {currentlyApprovingRow.data_to_change.map((data) => (
                                                    <Col key={data.code} md='12'>
                                                        <FormGroup>
                                                            <div className='text-muted d-flex'>
                                                                <DisplayI18n defaultValue={data.memberField.name} i18n={data.memberField.i18n} field='name' />
                                                            </div>
                                                            <div className='d-flex flex-wrap'>
                                                                <div>
                                                                    {currentlyApprovingRow.member[data.code]}
                                                                </div>
                                                                <div className='mx-2'>
                                                                →
                                                                </div>
                                                                <div className='font-bold'>
                                                                    {data.value}
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Card>
                                        {/* ERROR MANAGEMENT */}
                                        {formik.status &&
                                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                                <CustomAlert className='mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                            </Collapse>
                                        }
                                        <div className='pt-2'>
                                            {/* INITIAL STATE */}
                                            <CrossFade isVisible={buttonState === 'INITIAL'}>
                                                <Row>
                                                    <Col className='pr-1'>
                                                        <Button
                                                            outline
                                                            className='w-100'
                                                            color='danger'
                                                            onClick={() => setButtonState('DECLINED')}
                                                        >
                                                            <Translate id='task.document.approval.modal.refuse' />
                                                        </Button>
                                                    </Col>
                                                    <Col className='pl-1'>
                                                        <Button
                                                            outline
                                                            className='w-100'
                                                            color='success'
                                                            onClick={() => setButtonState('APPROVED')}
                                                        >
                                                            <Translate id='task.document.approval.modal.approve' />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                {selectedRows.length > 1 &&
                                                    <div className='w-100 mt-2'>
                                                        <Button
                                                            className='w-100'
                                                            color='link'
                                                            onClick={() => nextApproval(formik)}
                                                        >
                                                            <Translate id='task.document.approval.modal.skip' />
                                                        </Button>
                                                    </div>
                                                }
                                            </CrossFade>
                                            {/* APPROVAL STATE: display approval request */}
                                            <CrossFade isVisible={buttonState === 'APPROVED'}>
                                                <Row>
                                                    <Col className='pr-2'>
                                                        <Button
                                                            outline
                                                            className='w-100'
                                                            color='primary'
                                                            onClick={() => setButtonState('INITIAL')}
                                                        >
                                                            <Translate id='misc.cancel' />
                                                        </Button>
                                                    </Col>
                                                    <Col className='pl-2'>
                                                        <Button
                                                            className='w-100'
                                                            color='success'
                                                            onClick={() => formik.submitForm()}
                                                        >
                                                            <Translate id='task.document.approval.modal.approve.confirm' />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CrossFade>
                                            {/* REFUSAL STATE: display refusal request  */}
                                            <CrossFade isVisible={buttonState === 'DECLINED'}>
                                                <Row>
                                                    <Col className='pr-2'>
                                                        <Button
                                                            outline
                                                            className='w-100'
                                                            color='primary'
                                                            onClick={() => setButtonState('INITIAL')}
                                                        >
                                                            <Translate id='misc.cancel' />
                                                        </Button>
                                                    </Col>
                                                    <Col className='pl-2'>
                                                        <Button
                                                            className='w-100'
                                                            color='danger'
                                                            onClick={() => formik.submitForm()}
                                                        >
                                                            <Translate id='task.document.approval.modal.refuse.confirm' />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CrossFade>
                                        </div>

                                    </ModalBody>
                                </OverlayLoader>
                            </CrossFade>
                            {/* SUCCESS STEP ONLY ACTIVE IF SINGLE APPROVAL */}
                            <CrossFade isVisible={formik.status === 'success'}>
                                <ModalBody className="text-center">
                                    <CustomAnimatedIcon withCircle className="text-success" size={50} icon="checkmark" />
                                    <div className="h4 font-bold mt-2">
                                        <Translate id="misc.success" />
                                    </div>
                                    <p>
                                        <Translate id={selectedRows.length > 1 ? 'task.mandatoryChangesRequests.sidepanel.request.multi' : 'task.mandatoryChangesRequests.sidepanel.request.success'} />
                                    </p>

                                    <Button
                                        color="primary" type="button" onClick={() => {
                                            setButtonState("INITIAL")
                                            toggle(); spordleTable.refreshTable(); forceCloseSidePanel();
                                        }}
                                    >
                                        <Translate id="misc.close" />
                                    </Button>
                                </ModalBody>
                            </CrossFade>
                        </Form>
                    </>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

export default MemberChangeRequestsModal;