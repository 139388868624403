import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import moment from 'moment';
import { useContext, useState } from 'react';
import {
    Button, Card,
    CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown
} from "reactstrap";
import CanDoAction from '../../../components/permissions/CanDoAction';
import SpordlePanelTable from '../../../components/sidePanel/SpordlePanel';
import EmptyLayout from '../../../components/table/EmptyLayout';
import UserDisplay from '../../../components/userDisplay/UserDisplay';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { I18nContext } from '../../../contexts/I18nContext';
import { RebatesContext } from '../../../contexts/RebatesContext';
import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext } from '../../../helpers/i18nHelper';
import RebatesAdd from './components/RebatesAdd';
import RebatesSidepanel from './components/RebatesSidepanel';
import RebatesTypes from './components/RebatesTypes';


const Rebates = () => {
    const rebatesContext = useContext(RebatesContext);
    const i18nContext = useContext(I18nContext);
    const [ addModal, setAddModal ] = useState(false);
    const toggleAddModal = () => setAddModal(!addModal);

    return (
        <I18nHelperContextProvider fields={'title'}>
            <I18nHelperContext.Consumer>
                {(i18nHelper) => (
                    <>
                        <ViewHeaderV2
                            title='catalog.rebates.title'
                            breadcrumbsProps={{
                                disablePeriodSelect: true,
                            }}
                        />

                        <div className="px-4">
                            <Card className="card-shadow">
                                <CardBody>
                                    <SpordlePanelTable
                                        allowOutsideClick
                                        dataIndex='rebate_id'
                                        sidePanel={(props) => <RebatesSidepanel {...props} />}
                                        table={(panelProps) => (
                                            <SpordleTableProvider
                                                id='CatalogRebatesTable'
                                                tableHover bordered striped
                                                clickable
                                                ref={panelProps.spordleTableRef}
                                                desktopWhen
                                                dataIndex='rebate_id'
                                                pagination={20}
                                                emptyLayout={<EmptyLayout />}
                                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                                searchKeys={[
                                                    'promo_code',
                                                    'title',
                                                    'value',
                                                    'expiration_date',
                                                    `i18n.${i18nContext.getGenericLocale()}.title`,
                                                ]}
                                                loadData={(from, _filterData, spordleTable) => {
                                                    switch (from){
                                                        case 'REFRESH':
                                                            spordleTable.setLoading(true);
                                                        case 'CDM':
                                                            return rebatesContext.getRebates()
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                columns={[
                                                    ...i18nHelper.getTableColumns('title'),
                                                    {
                                                        label: <Translate id='catalog.rebates.table.rebate' />,
                                                        key: 'rebate',
                                                        sortable: true,
                                                    },
                                                    {
                                                        label: <Translate id='catalog.rebates.form.expDate' />,
                                                        key: 'expDate',
                                                        sortable: true,
                                                    },
                                                    {
                                                        label: <Translate id='misc.status' />,
                                                        key: 'active',
                                                        dataClassName: 'text-center',
                                                        sortable: true,
                                                    },

                                                ]}
                                                renderRow={(columnKey, data) => {
                                                    if(columnKey.includes('i18n')){
                                                        return (
                                                            <UserDisplay>
                                                                <UserDisplay.Title>
                                                                    <DisplayI18n
                                                                        field={columnKey}
                                                                        defaultValue={data.title}
                                                                        i18n={data.i18n}
                                                                    />
                                                                </UserDisplay.Title>
                                                            </UserDisplay>
                                                        )
                                                    }
                                                    switch (columnKey){
                                                        case 'rebate':
                                                            if(data.type === RebatesTypes.type.multi.tag || data.type === RebatesTypes.type.postalCode.tag){
                                                                return (
                                                                    <div>
                                                                        <div className="text-muted"><Translate id={data.type == RebatesTypes.type.multi.tag ? RebatesTypes.type.multi.label : RebatesTypes.type.postalCode.label} /></div>
                                                                        {data.rules.map((rule, key) => (
                                                                            // eslint-disable-next-line react/no-array-index-key
                                                                            <div key={key}>
                                                                                <Translate id='catalog.rebates.table.multi.rule.1' />
                                                                                <span className="font-medium">
                                                                                    {data.mode === RebatesTypes.mode.amount.tag ? <CurrencyFormat value={rule.rule_value / 100} /> : `${rule.rule_value}%`}
                                                                                </span>
                                                                                <Translate id='catalog.rebates.table.multi.rule.2' values={{ nbParticipant: rule.rule_registration_number }} />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )
                                                            }else if(data.type === RebatesTypes.type.promo.tag){
                                                                return (
                                                                    <>
                                                                        <div className="text-muted"><Translate id={RebatesTypes.type.promo.label} /></div>
                                                                        <Translate id='catalog.rebates.table.promo.rule.1' />
                                                                        <span className="font-medium">
                                                                            {data.mode === RebatesTypes.mode.amount.tag ? <CurrencyFormat value={data.value ? data.value / 100 : 0} /> : `${data.value}%`}
                                                                        </span>
                                                                        <Translate id='catalog.rebates.table.promo.rule.2' values={{ code: <span className="bg-light p-1 small rounded font-medium text-primary">{data.promo_code}</span> }} />
                                                                    </>
                                                                )
                                                            }
                                                        case 'expDate':
                                                            return data.expiration_date ? moment(data.expiration_date).format('YYYY-MM-DD') : '-'
                                                        case 'active':
                                                            return data.active === "1" ? <i className="text-primary mdi mdi-check" /> : <i className="text-danger mdi mdi-close" />;
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                onColumnClick={(e, data) => {
                                                    switch (e.button){
                                                        case 0: // Left mouse button
                                                            panelProps.onSingleSelectChange(data);
                                                            break;
                                                    }
                                                }}
                                                rowIsHighlighted={(data) => data.checked}
                                            >
                                                <div className='mb-2'>
                                                    <div className='d-flex flex-wrap justify-content-between'>
                                                        <SearchInput />
                                                        <div className='d-flex ml-auto text-right'>
                                                            <Refresh />
                                                            <UncontrolledButtonDropdown className='ml-2'>
                                                                <DropdownToggle disabled color='primary' outline caret>
                                                                    <i className='mdi mdi-download mr-1' /><Translate id='misc.export' />
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem><i className='fas fa-file-excel' /> <Translate id='misc.excel' /></DropdownItem>
                                                                    <DropdownItem><i className='fas fa-file-pdf' /> <Translate id='misc.pdf' /></DropdownItem>
                                                                    <DropdownItem><i className='fas fa-print' /> <Translate id='misc.print' /></DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>

                                                            <CanDoAction action='ADD' componentCode='catalog' componentPermissionCode='rebate'>
                                                                <RebatesAdd isOpen={addModal} toggle={toggleAddModal} />
                                                                <Button onClick={toggleAddModal} className='ml-2' color='primary'><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                            </CanDoAction>
                                                        </div>
                                                    </div>
                                                </div>
                                                <SpordleTableView />
                                            </SpordleTableProvider>
                                        )}
                                    />
                                </CardBody>
                            </Card>
                        </div>
                    </>
                )}
            </I18nHelperContext.Consumer>
        </I18nHelperContextProvider>
    );
}

export default Rebates;