import axios from 'axios';
import createApiTesterUrl from '../helpers/createApiTester';
import queryString from 'query-string';
import { history } from '../helpers';
import { getVersionHeaders } from '../helpers/helper';
import { addBreadcrumb, Severity, captureException, withScope } from '@sentry/react';
import { fire } from '@spordle/toasts';
import { currentMaintenanceUrl } from './API-Spordle';
import getAccountUrl from '../helpers/getExternalUrl';
import { getApiBaseUrlMultiSport } from '../helpers/getReferer';

const DEV_URL = "https://pub-proxy.id.dev.spordle.dev/v1";
const INT_URL = "https://pub-proxy.id.int.spordle.dev/v1";
const STAGE_URL = getApiBaseUrlMultiSport(true) || "https://pub-proxy.id.stage.spordle.dev/v1";
const HCSTAGE_URL = "https://pub-proxy.id.hcstage.spordle.dev/v1";
const UAT_URL = "https://pub-proxy.id.uat.spordle.dev/v1";
const TRAINING_URL = "https://pub-proxy.training.hcr.spordle.com/v1";
const PREPROD_URL = `https://pub-proxy.preprod.hcr.spordle.com/v1`;
const PROD_URL = getApiBaseUrlMultiSport(true) || `https://pub-proxy.hcr.spordle.com/v1`;

var current_url = DEV_URL;
if(process.env.REACT_APP_ENVIRONMENT === 'int')
    current_url = INT_URL
else if(process.env.REACT_APP_ENVIRONMENT === 'stage')
    current_url = STAGE_URL;
else if(process.env.REACT_APP_ENVIRONMENT === 'hcstage')
    current_url = HCSTAGE_URL;
else if(process.env.REACT_APP_ENVIRONMENT === 'uat')
    current_url = UAT_URL;
else if(process.env.REACT_APP_ENVIRONMENT === 'training')
    current_url = TRAINING_URL;
else if(process.env.REACT_APP_ENVIRONMENT === 'preprod')
    current_url = PREPROD_URL;
else if(process.env.REACT_APP_ENVIRONMENT === 'prod')
    current_url = PROD_URL;

const API_SPORDLE = axios.create({
    baseURL: current_url,
    //withCredentials: true,
    headers: {
        common: {
            ...getVersionHeaders(),
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Language': 'fr;en', // All supported languages
        },
        patch: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        put: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    },
    validateStatus: (status) => {
        if([ 401, 403, 418, 503 ].indexOf(status) !== -1 && process.env.NODE_ENV !== 'development'){
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            switch (status){
                case 418:// I'm a teapot
                    addBreadcrumb({
                        type: 'info',
                        category: "version",
                        message: "New version detected",
                    });
                    fire({ theme: 'warning', msg: 'warning.versionOverlapping', timeout: 60, horizontalLoad: true, id: "I'm a teapot", showCloseButton: false });
                case 503: // Maintenance
                    if(!currentMaintenanceUrl)
                        // eslint-disable-next-line no-import-assign
                        currentMaintenanceUrl = location.href;
                    history.push('/maintenance');
                    break;
                case 403: // Forbidden
                    withScope(function(scope){
                        scope.setTag('api-status', 403);
                        scope.setLevel(Severity.Warning);
                        captureException(new Error("403 -> Forbidden"));
                    });
                    fire({ theme: 'warning', msg: 'warning.403', id: '403' });
                    history.replace('/dashboard');
                    break;
                case 401: // Unauthorized -> AccessToken is invalid
                default:
                    addBreadcrumb({
                        type: 'navigation',
                        category: "navigation",
                        message: `Redirecting to ACCOUNT because of ${status}`,
                        data: {
                            from: window.location.href,
                            to: getAccountUrl('SPORDLE'),
                        },
                    });
                    window.location.href = getAccountUrl('SPORDLE');
                    break;
            }
        }
        return (status >= 200 && status < 300) || status === 400; // default
    },
    /*catch: (error) => {
        console.log("API_SID CATCH");
        console.log(error);
    }*/
    /*transformResponse: [function (data) {
        return data;
    }]*/
});

API_SPORDLE.interceptors.response.use(null, (error) => {
    if((process.env.REACT_APP_ENVIRONMENT === 'dev' || process.env.REACT_APP_ENVIRONMENT === 'stage') && !axios.isCancel(error)){
        const jsonSpacing = 4;
        const parsedUrl = queryString.parseUrl(error.response.config.url);
        console.error(createApiTesterUrl(error.response.config.baseURL, error.response.config.method, parsedUrl.url, JSON.stringify(parsedUrl.query, undefined, jsonSpacing), JSON.stringify(error.response.config.headers, undefined, jsonSpacing), JSON.stringify(error.response.config.body, undefined, jsonSpacing)));
    }
    switch (error.response.status){
        case 403:
            return Promise.reject(new Error("teapot"));
        default:
            break;
    }

    return Promise.reject(error);
});

export default API_SPORDLE;
export{ current_url };