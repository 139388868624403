import { useContext } from "react";
import useSWR from "swr";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";
import { ReportsContext } from "../../contexts/ReportsContext";

export function useReportsSWR(){
    const { identity_role_id } = useContext(IdentityRolesContext);
    const { getReportsList } = useContext(ReportsContext);
    const swrData = useSWR(
        [ 'getAllReports', identity_role_id ],
        () => getReportsList({ skipSummary: false }),
    );

    return swrData;
}

export function useReportsLiteSWR(){
    const { identity_role_id } = useContext(IdentityRolesContext);
    const { getReportsList } = useContext(ReportsContext);
    const swrData = useSWR(
        [ 'getAllReportsList', identity_role_id ],
        () => getReportsList(),
    );

    return swrData;
}