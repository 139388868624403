import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
// Language
import Translate, { DateFormat } from "@spordle/intl-elements";
import React from "react";
// phone input
import {
    Col,
    Row
} from "reactstrap";
import { object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import FormikEditable from '../../../../../../components/formik/FormikEditable';
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
// Contexts
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import withContexts from '../../../../../../helpers/withContexts';
import SidePanelMemberContactsInfo from "./components/SidePanelMemberContactsInfo";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";
import images from '@spordle/ui-kit';
import EditIdentityModal from "./components/EditIdentityModal";

const noProfileLogo = images.noprofile;

class SidePanelMemberContacts extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,

            identityModalOpen: false,
        }
    }

    toggleIdentityModal = () => {
        this.setState((prevState) => ({
            identityModalOpen: !prevState.identityModalOpen,
        }))
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <SidePanel.Header>
                    <div className='d-flex mb-3 align-items-center'>
                        <SidePanel.ToggleButton />
                        <SidePanel.ActionsMenu action="DELETE" componentCode="members" componentPermissionCode="member_contacts" label="misc.action">
                            <ConfirmModal
                                toggler={(toggle) => (
                                    <SidePanel.MenuAction onClick={toggle}>
                                        <Translate id='misc.delete' />
                                    </SidePanel.MenuAction>
                                )}
                                modalTitle={<Translate id='members.profile.overview.memberContacts.sidePanel.remove.text' />}
                                actionButtonText='misc.confirm'
                                translateActionButtonText
                                color='danger'
                                onConfirm={async() => {
                                    return this.props.MembersContext.deleteMemberContact(this.props.MembersContext.currentMember.member_id, this.props.getCommonValue('id'))
                                        .then(() => {
                                            success({ msg: 'members.profile.overview.memberContacts.sidePanel.remove.success' });
                                            this.props.tableRef.deleteRow(this.props.getCommonValue('id')).then(this.props.forceCloseSidePanel)
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                }}
                            />
                        </SidePanel.ActionsMenu>
                    </div>
                    <SidePanel.Title>
                        {this.props.getCommonValue('firstName')} {this.props.getCommonValue('lastName')}
                    </SidePanel.Title>
                    <SidePanel.Subtitle>
                        <Translate id='members.profile.overview.memberContacts.lastUpdated' />: <DateFormat value={this.props.selectedRows[0].updated_at} />
                    </SidePanel.Subtitle>
                </SidePanel.Header>
                <div className="mb-phat">
                    <div className="p-3 border-bottom">
                        <Row className='mb-3'>
                            <Col md='6'>
                                {/* FIRST NAME */}
                                <div className="text-muted"><Translate id='form.fields.firstName' /></div>
                                <FormikEditable
                                    id='firstNameFormikEditable'
                                    initialValues={{
                                        firstName: this.props.getCommonValue('firstName'),
                                    }}
                                    validationSchema={object().shape({
                                        firstName: string().required(<Translate id='form.validation.firstName.required' />),
                                    })}
                                    onSubmit={(values) => {
                                        if(!this.props.getCommonValue('contactType')){
                                            fail({
                                                msg: 'misc.error',
                                                info: <Translate id='members.profile.overview.memberContacts.sidePanel.contactType.required' />,
                                                skipInfoTranslate: true,
                                            })
                                        }else if(values.firstName !== this.props.getCommonValue('firstName')){
                                            this.setState(() => ({ isLoading: true }))
                                            const newValues = this.props.createNewValues(values);
                                            this.props.MembersContext.updateMemberContact(this.props.MembersContext.currentMember.member_id, this.props.getCommonValue('id'), newValues, this.props.RolesContext.canDoAction("DELETE", "members", "member_contacts"))
                                                .then(() => {
                                                    this.props.syncRows(newValues)
                                                    success({ msg: 'members.profile.overview.memberContacts.sidePanel.edit.success' })
                                                    this.setState(() => ({ isLoading: false }))
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                        this.setState(() => ({ isLoading: false }))
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium'>{this.props.getCommonValue('firstName')}</div>
                                            )
                                        }
                                        return (
                                            <FormikInputText id='firstName' name='firstName' trim />
                                        )

                                    }}
                                </FormikEditable>
                            </Col>
                            <Col mb='6'>
                                {/* LAST NAME */}
                                <div className="text-muted"><Translate id='form.fields.lastName' /></div>
                                <FormikEditable
                                    id='lastNameFormikEditable'
                                    initialValues={{
                                        lastName: this.props.getCommonValue('lastName'),
                                    }}
                                    validationSchema={object().shape({
                                        lastName: string().required(<Translate id='form.validation.lastName.required' />),
                                    })}
                                    onSubmit={(values) => {
                                        if(!this.props.getCommonValue('contactType')){
                                            fail({
                                                msg: 'misc.error',
                                                info: <Translate id='members.profile.overview.memberContacts.sidePanel.contactType.required' />,
                                                skipInfoTranslate: true,
                                            })
                                        }else if(values.lastName !== this.props.getCommonValue('lastName')){
                                            this.setState(() => ({ isLoading: true }))
                                            const newValues = this.props.createNewValues(values);
                                            this.props.MembersContext.updateMemberContact(this.props.MembersContext.currentMember.member_id, this.props.getCommonValue('id'), newValues, this.props.RolesContext.canDoAction("DELETE", "members", "member_contacts"))
                                                .then(() => {
                                                    this.props.syncRows(newValues)
                                                    success({ msg: 'members.profile.overview.memberContacts.sidePanel.edit.success' })
                                                    this.setState(() => ({ isLoading: false }))
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                        this.setState(() => ({ isLoading: false }))
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium'>{this.props.getCommonValue('lastName')}</div>
                                            )
                                        }
                                        return (
                                            <FormikInputText id='lastName' name='lastName' trim />
                                        )

                                    }}
                                </FormikEditable>
                            </Col>
                        </Row>
                        <div className='mb-3 pb-3 border-bottom'>
                            {/* CONTACT TYPE */}
                            <div className="text-muted"><Translate id='members.profile.overview.memberContacts.contactTypes' /></div>
                            <FormikEditable
                                id='contactTypeFormikEditable'
                                noConfirmation
                                initialValues={{
                                    contactTypeId: this.props.getCommonValue('contactType')?.memberContactTypeId || '',
                                    contactType: this.props.getCommonValue('contactType'),
                                }}
                                validationSchema={object().shape({
                                    contactTypeId: string().required(<Translate id='members.profile.overview.memberContacts.contactTypes.required' />),
                                })}
                                onSubmit={(values) => {

                                    if(values.contactTypeId !== this.props.getCommonValue('contactType')?.memberContactTypeId || ''){
                                        this.setState(() => ({ isLoading: true }))
                                        const newValues = this.props.createNewValues({
                                            contactType: {
                                                i18n: values.contactType?.i18n,
                                                name: values.contactType?.label,
                                                memberContactTypeId: values.contactTypeId,
                                            },
                                        });
                                        this.props.MembersContext.updateMemberContact(this.props.MembersContext.currentMember.member_id, this.props.getCommonValue('id'), newValues, this.props.RolesContext.canDoAction("DELETE", "members", "member_contacts"))
                                            .then(() => {
                                                this.props.syncRows(newValues)
                                                success({ msg: 'members.profile.overview.memberContacts.sidePanel.edit.success' })
                                                this.setState(() => ({ isLoading: false }))
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                    this.setState(() => ({ isLoading: false }))
                                                }
                                            })
                                    }
                                }}
                            >
                                {(isEditing, options, formik) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium'>
                                                {this.props.getCommonValue('contactType') ?
                                                    <DisplayI18n field='name' defaultValue={this.props.getCommonValue('contactType').name} i18n={this.props.getCommonValue('contactType').i18n} />
                                                    : '-'}
                                            </div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            id='contactTypeId'
                                            name='contactTypeId'
                                            onOptionSelected={([ value ], table) => {
                                                const select = table.getSpordleTable().getData().find((d) => d.value == value);
                                                // because we also want the object to be sent to the table
                                                formik.setFieldValue('contactType', select)
                                                options.stopEditing()
                                            }}
                                            renderOption={({ option }) => (
                                                <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                            )}
                                            menuIsDefaultOpen autoFocus
                                            searchKeys={[
                                                `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                            ]}
                                            options={this.props.contactTypes ?
                                                this.props.contactTypes.map((type) => ({
                                                    ...type,
                                                    label: type.name,
                                                    value: type.member_contact_type_id,
                                                }))
                                                :
                                                []
                                            }
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </div>
                        <SidePanelMemberContactsInfo {...this.props} setLoading={(isLoading) => this.setState(() => ({ isLoading: isLoading }))} />

                        <div className="mb-3 pb-3">
                            <EditIdentityModal
                                isOpen={this.state.identityModalOpen}
                                toggle={this.toggleIdentityModal}
                                memberContact={this.props.selectedRows[0]}
                                refresh={this.props.refresh}
                                onSuccess={() => this.props.toggle()}
                            />

                            <div className="h5 font-medium">
                                <Translate id='members.profile.overview.memberContacts.sidePanel.identity' />
                            </div>
                            {this.props.selectedRows[0].created_by ?
                                <UserDisplay
                                    card hover
                                    className={`w-100 mb-0 border-primary`}
                                    style={{ minWidth: 'unset', borderStyle: 'dashed', borderWidth: '1px' }}
                                    onClick={() => this.toggleIdentityModal()}
                                >
                                    <UserDisplay.Container>
                                        <UserImg
                                            abbr={`${this.props.selectedRows[0].created_by.name.charAt(0)}${this.props.selectedRows[0].created_by.family_name.charAt(0)}`}
                                            src={null}
                                            width={30}
                                            alt={`${this.props.selectedRows[0].created_by.name} ${this.props.selectedRows[0].created_by.family_name}`}
                                            className="p-2"
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container className='text-truncate'>
                                        <UserDisplay.Title>{this.props.selectedRows[0].created_by.name} {this.props.selectedRows[0].created_by.family_name}</UserDisplay.Title>
                                        <UserDisplay.Subtitle>{this.props.selectedRows[0].created_by.email}</UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                    <UserDisplay.Container className='align-self-center ml-auto'>
                                        <div className='text-right'>
                                            <i className='mdi mdi-pencil text-primary font-16' />
                                        </div>
                                    </UserDisplay.Container>
                                </UserDisplay>
                                :
                                <UserDisplay
                                    card hover
                                    className={`w-100 mb-0 border-primary`}
                                    style={{ minWidth: 'unset', borderStyle: 'dashed', borderWidth: '1px' }}
                                    onClick={() => this.toggleIdentityModal()}
                                >
                                    <UserDisplay.Container>
                                        <UserImg
                                            src={noProfileLogo}
                                            width={30}
                                            alt='no identity'
                                            className="p-2"
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container className='text-truncate text-primary'>
                                        <UserDisplay.Title><Translate id='members.profile.overview.memberContacts.sidePanel.identity.none' /></UserDisplay.Title>
                                    </UserDisplay.Container>
                                    <UserDisplay.Container className='align-self-center ml-auto'>
                                        <div className='text-right'>
                                            <i className='mdi mdi-pencil text-primary font-16' />
                                        </div>
                                    </UserDisplay.Container>
                                </UserDisplay>
                            }
                        </div>
                    </div>
                </div>
            </OverlayLoader>
        )
    }
}

export default withContexts(I18nContext, MembersContext, RolesContext)(SidePanelMemberContacts);