import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from "./CheckboxStar.module.scss";
import { stringBuilder } from '@spordle/helpers';

/**
 * @description Star checkbox
 *
 * @param {Object} props
 * @param {String|Number} props.id Id added to input and to the for of the label.
 * @param {String|Number} [props.name] Name added to input. If not specified, id will be the name.
 * @param {boolean} [props.checked] Default state of the checkbox.
 * @param {sm} [props.size] Size of the star.
 * @param {string} [props.className] Added to label container
 */
const CheckboxStar = ({ defaultChecked, useCurrentColor, size, name, className, ...props }) => {
    const [ isChecked, setIsChecked ] = useState(defaultChecked);
    const toggleCheckbox = (e) => setIsChecked(e.currentTarget.checked);

    useEffect(() => {
        if((props.checked === undefined && props.onChange !== undefined) || (props.onChange === undefined && props.checked !== undefined)){
            console.warn(
                '%c Checkbox Star',
                "font-weight:bold;",
                '\n',
                'Uncontrolled input component',
                '\n',
                'Please specify the "checked" and the "onChange" if you wish for this input to be controlled. Do not specify any otherwise.',
            );
        }
    }, []);

    return (
        <>
            <label
                htmlFor={props.id}
                className={stringBuilder(
                    styles.label,
                    styles[(props.checked !== undefined ? props.checked : isChecked) ? `checked` : `unchecked`],
                    className,
                    styles[size],
                    { [styles.current]: useCurrentColor },
                )}
            >
                <input
                    name={name ?? props.id}
                    checked={isChecked}
                    onChange={(e) => toggleCheckbox(e)}
                    className={styles.input}
                    type="checkbox"
                    {...props}
                />
                <span className={`mdi mdi-star ${styles.icon}`} />
            </label>
        </>
    );
}

CheckboxStar.defaultProps = {
    checked: false,
}

CheckboxStar.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    checked: PropTypes.bool,
}

export default CheckboxStar;
