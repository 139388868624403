export default{
    "supportRequests.search.title"           : "My Support Requests",
    "supportRequests.search.filter.priority" : "Filter priorities",

    "supportRequests.search.table.idSearch"     : "Search by reference",
    "supportRequests.search.table.nameSearch"   : "Search by subject",
    "supportRequests.search.table.onlyMyIssues" : "Show only my tickets",
    "supportRequests.search.table.priority"     : "Priority",
    "supportRequests.search.table.reference"    : "Reference",
    "supportRequests.search.table.subject"      : "Subject",

    "supportRequests.search.table.priority.placeholder" : "Filter by priority",
    "supportRequests.search.table.status.placeholder"   : "Filter by status",
    "supportRequests.search.table.priority.low"         : "Low",
    "supportRequests.search.table.priority.medium"      : "Medium",
    "supportRequests.search.table.priority.high"        : "High",

    "supportRequests.search.table.status.2" : "To Do",
    "supportRequests.search.table.status.4" : "In Progress",
    "supportRequests.search.table.status.3" : "Done",
}