export default{
    // table
    "clinics.profile.attendees.table.organization"                      : "Organization",
    "clinics.profile.attendees.table.paid"                              : "Paid",
    "clinics.profile.attendees.table.paid.not"                          : "Not Paid",
    "clinics.profile.attendees.table.attended"                          : "Attended",
    "clinics.profile.attendees.table.attended.not"                      : "Not Attended",
    "clinics.profile.attendees.table.passed"                            : "Passed",
    "clinics.profile.attendees.table.passed.not"                        : "Not Passed",
    "clinics.profile.attendees.table.grade"                             : "Grade",
    "clinics.profile.attendees.table.paginationMessage"                 : "Attendee {itemStartPos} to {itemEndPos} out of a total of {totalItems} attendees",
    "clinics.profile.attendees.table.header.statusFilter.placeholder"   : "Status",
    "clinics.profile.attendees.table.header.paidFilter.placeholder"     : "Paid Status",
    "clinics.profile.attendees.table.header.attendedFilter.placeholder" : "Attended Status",
    "clinics.profile.attendees.table.header.passedFilter.placeholder"   : "Passed Status",
    "clinics.profile.attendees.table.filters.unconfirmed"               : "Show only unconfirmed",
    "clinics.profile.attendees.table.filters.cancelled"                 : "Show only cancelled",

    // sidepanel
    "clinics.profile.attendees.sidepanel.tabs.details"         : "Details",
    "clinics.profile.attendees.sidepanel.tabs.profile"         : "Profile",
    "clinics.profile.attendees.sidepanel.tabs.member"          : "Member",
    "clinics.profile.attendees.sidepanel.tabs.forms"           : "Questionnaire",
    "clinics.profile.attendees.sidepanel.header.notConfirmed"  : "Unconfirmed",
    "clinics.profile.attendees.sidepanel.header.cancelled"     : "Participation Cancelled",
    "clinics.profile.attendees.sidepanel.header.cancelledAt"   : "Cancelled at {date}",
    "clinics.profile.attendees.sidepanel.header.cancelledAtBy" : "Cancelled at {date} by {name}",

    "clinics.profile.attendees.sidepanel.actions.transfer"                        : "Transfer Clinic",
    "clinics.profile.attendees.sidepanel.actions.transfer.noRefund"               : "Cannot transfer with refunds.",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.reason.label"     : "Cancel Reason",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee"                  : "Cancel {count, plural, one {Attendee} other {Attendees}}",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.text"             : "You are about to cancel this attendee.",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.valid"            : "{count, plural, one {Member to cancel (#)} other {Members to cancel (#)}}",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.valid.subtitle"   : "The following {count, plural, one {member} other {members}} can be cancelled",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.invalid"          : "Invalid {count, plural, one {member (#)} other {members (#)}}",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.invalid.subtitle" : "The following {count, plural, one {member} other {members}} cannot be cancelled",

    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.error.alreadyCancelled" : "Attendee already cancelled",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.error.alreadyPassed"    : "Attendee has already passed the clinic",

    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.review.title"    : "Cancellation review",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.review.subtitle" : "{count, plural, one {Cancellation status} other {Cancellations statuses}}",

    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.success.single.title" : "The attendee has been cancelled with success !",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.fail.single.title"    : "The attendee couldn't be cancelled",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.success.title"        : "{count, plural, one {# attendee successfully cancelled} other {# attendees successfully cancelled}}",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.fail.title"           : "{count, plural, one {# attendee} other {# attendees}} couldn't be cancelled",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.mail.title"           : "Your participation has been cancelled",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.mail.subject"         : "Clinic attendee cancellation",

    "clinics.profile.attendees.sidepanel.actions.confirmMember"        : "Confirm member",
    "clinics.profile.attendees.sidepanel.actions.confirmMember.text"   : "You are about to confirm this member.",
    "clinics.profile.attendees.sidepanel.actions.notification"         : "Send Notification",
    "clinics.profile.attendees.sidepanel.actions.addPayment"           : "Apply Payment",
    "clinics.profile.attendees.sidepanel.actions.refund"               : "Refund",
    "clinics.profile.attendees.sidepanel.actions.exchange"             : "Exchange items",
    "clinics.profile.attendees.sidepanel.actions.certify"              : "Certify",
    "clinics.profile.attendees.sidepanel.actions.certify.irreversible" : "This action is irreversible",

    "clinics.profile.attendees.sidepanel.refund.attendee.confirmation.question" : "Do you wish to cancel the attendee linked to this transaction as well?",
    "clinics.profile.attendees.sidepanel.refund.attendee.confirmation.btn"      : "Cancel Attendee",

    "clinics.profile.attendees.sidepanel.attendeeInfo"                    : "Attendee Info",
    "clinics.profile.attendees.sidepanel.attendeesInfo"                   : "Attendees Info",
    "clinics.profile.attendees.sidepanel.selected.attendees"              : "selected attendees",
    "clinics.profile.attendees.sidepanel.selected.attendees.hasPaid"      : "Has paid",
    "clinics.profile.attendees.sidepanel.attended.label"                  : "Presence",
    "clinics.profile.attendees.sidepanel.attended"                        : "Attended",
    "clinics.profile.attendees.sidepanel.attended.required"               : "Please select a present status",
    "clinics.profile.attendees.sidepanel.attended.not"                    : "Did not attend",
    "clinics.profile.attendees.sidepanel.attended.unable"                 : "The presence status cannot be changed if the attendee has passed the clinic",
    "clinics.profile.attendees.sidepanel.attended.unable.multiple"        : "The presence status cannot be changed if one of the attendees has passed the clinic",
    "clinics.profile.attendees.sidepanel.attended.date.required"          : "Presence date is required",
    "clinics.profile.attendees.sidepanel.attended.date.before"            : "Presence date cannot be after the passed date",
    "clinics.profile.attendees.sidepanel.passed.label"                    : "Result",
    "clinics.profile.attendees.sidepanel.passed"                          : "Passed",
    "clinics.profile.attendees.sidepanel.passed.not"                      : "Did not pass",
    "clinics.profile.attendees.sidepanel.passed.unable"                   : "The passage status cannot be changed if the attendee has been certified for the clinic",
    "clinics.profile.attendees.sidepanel.passed.unable.multiple"          : "The passage status cannot be changed if one of the attendees has been certified for the clinic",
    "clinics.profile.attendees.sidepanel.passed.date.required"            : "Passed date is required",
    "clinics.profile.attendees.sidepanel.passed.date.noPresence"          : "The participant must have attended the clinic to have passage status",
    "clinics.profile.attendees.sidepanel.passed.date.noPresence.multiple" : "The participant must have all attended the clinic to have passage status",
    "clinics.profile.attendees.sidepanel.passed.date.after"               : "Passed date cannot be before the presence date",
    "clinics.profile.attendees.sidepanel.passed.date.before"              : "Passed date cannot be after the certification date",
    "clinics.profile.attendees.sidepanel.certified.label"                 : "Certification",
    "clinics.profile.attendees.sidepanel.certified"                       : "Certified",
    "clinics.profile.attendees.sidepanel.certified.not"                   : "Not certified",
    "clinics.profile.attendees.sidepanel.certified.hint"                  : "This action will create a qualification for this member. To specify a different date, a certification number or any other information, please use the \"Certify\" action.",
    "clinics.profile.attendees.sidepanel.notSpecified"                    : "Not specified",
    "clinics.profile.attendees.sidepanel.invoice.not.found"               : "Payment information is unavailable.",

    "clinics.profile.attendees.sidepanel.registeredBy"          : "Registered by",
    "clinics.profile.attendees.sidepanel.registeredFrom.ONLINE" : "Registered via online store",
    "clinics.profile.attendees.sidepanel.registeredFrom.ADMIN"  : "Registered via this platform",
    "clinics.profile.attendees.sidepanel.registeredAt"          : "at",

    "clinics.profile.attendees.sidepanel.paymentInfo"                   : "Payment Information",
    "clinics.profile.attendees.sidepanel.paymentInfo.refund"            : "Refund",
    "clinics.profile.attendees.sidepanel.paymentInfo.refunds"           : "Refunds",
    "clinics.profile.attendees.sidepanel.paymentInfo.paymentReception"  : "Received payment",
    "clinics.profile.attendees.sidepanel.paymentInfo.paymentReceptions" : "Received payments",
    "clinics.profile.attendees.sidepanel.paymentInfo.clinic"            : "Clinic",
    "clinics.profile.attendees.sidepanel.paymentInfo.clinicFees"        : "Clinic Fee(s)",
    "clinics.profile.attendees.sidepanel.paymentInfo.date"              : "Date",
    "clinics.profile.attendees.sidepanel.paymentInfo.amount"            : "Amount",
    "clinics.profile.attendees.sidepanel.paymentInfo.status"            : "Status",
    "clinics.profile.attendees.sidepanel.paymentInfo.total"             : "Total",
    "clinics.profile.attendees.sidepanel.paymentInfo.due"               : "Due",
    "clinics.profile.attendees.sidepanel.paymentInfo.price"             : "Price",

    "clinics.profile.attendees.sidepanel.waivers" : "Waivers",

    "clinics.profile.attendees.sidepanel.paymentMethod.CHEQUE"              : "Cheque",
    "clinics.profile.attendees.sidepanel.paymentMethod.CASH"                : "Cash",
    "clinics.profile.attendees.sidepanel.paymentMethod.CREDITCARD"          : "Credit card",
    "clinics.profile.attendees.sidepanel.paymentMethod.PAYMENTPROXY"        : "Credit card (proxy payment)",
    "clinics.profile.attendees.sidepanel.paymentMethod.INTERAC"             : "Interac transfer",
    "clinics.profile.attendees.sidepanel.paymentMethod.FUNDING"             : "Funding",
    "clinics.profile.attendees.sidepanel.paymentMethod.undefined"           : "N/A",
    "clinics.profile.attendees.sidepanel.paymentMethod.ORGANISATION_CREDIT" : "Credited (Free)",
    "clinics.profile.attendees.sidepanel.paymentMethod.MEMBER_CREDIT"       : "Member credit",
    "clinics.profile.attendees.sidepanel.paymentMethod.FREE"                : "Free",
    "clinics.profile.attendees.sidepanel.paymentMethod.EXTERNALPAYMENT"     : "External Payment",

    "clinics.profile.attendees.sidepanel.status.PENDING"        : "Pending",
    "clinics.profile.attendees.sidepanel.status.PAID"           : "Paid",
    "clinics.profile.attendees.sidepanel.status.COMPLETED"      : "Completed",
    "clinics.profile.attendees.sidepanel.status.NEED_REVIEW"    : "In review",
    "clinics.profile.attendees.sidepanel.status.SUSPENDED"      : "Suspended",
    "clinics.profile.attendees.sidepanel.status.DECLINED"       : "Declined",
    "clinics.profile.attendees.sidepanel.status.PARTIALLY_PAID" : "Partially Paid",
}