import AllImg from "../../../assets/images/reports/all.svg";
import RegistrationImg from "../../../assets/images/reports/registration.svg";
import TeamsImg from "../../../assets/images/reports/team.svg";
import MemberImg from "../../../assets/images/reports/member.svg";
import FinanceImg from "../../../assets/images/reports/finance.svg";
import ExportsImg from "../../../assets/images/reports/exports.svg";
import CustomImg from "../../../assets/images/reports/custom.svg";

// Group object example, the groups are sent to the table in an array
// {
//     groupCode: 'registrations',
//     groupTitle: 'reports.dashboard.group.registrations',
//     groupIcon: 'mdi-pencil-box-outline',
// }

import NewWindowOpener from "../registrationForm/NewWindowOpener";

export const reportsGroups = [
    // ALL
    {
        groupCode: 'ALL',
        groupTitle: 'reports.dashboard.group.all',
        groupIcon: <img height="35%" src={AllImg} alt="All reports" />,
    },
    // favorites
    {
        groupCode: 'favourites',
        groupTitle: 'reports.dashboard.group.favourites',
        groupIcon: <i style={{ fontSize: "33px" }} className='mdi mdi-star-outline' />,
        hidden: true,
    },
    // registrations
    {
        groupCode: 'REGISTRATION',
        groupTitle: 'reports.dashboard.group.registrations',
        groupIcon: <img height="35%" src={RegistrationImg} alt="Registration reports" />,
    },
    // tasks
    {
        groupCode: 'tasks',
        groupTitle: 'reports.dashboard.group.tasks',
        groupIcon: 'mdi-clipboard-outline',
        hidden: true,
    },
    // teams
    {
        groupCode: 'TEAM',
        groupTitle: 'reports.dashboard.group.teams',
        groupIcon: <img height="35%" src={TeamsImg} alt="Teams reports" />,
    },
    // members
    {
        groupCode: 'MEMBER',
        groupTitle: 'reports.dashboard.group.members',
        groupIcon: <img height="35%" src={MemberImg} alt="Member reports" />,
    },
    // financial
    {
        groupCode: 'FINANCIAL',
        groupTitle: 'reports.dashboard.group.financial',
        groupIcon: <img height="35%" src={FinanceImg} alt="Finance reports" />,
    },
    // savedCustom
    {
        groupCode: 'CUSTOM',
        groupTitle: 'reports.dashboard.group.savedCustom',
        groupIcon: <img height="35%" src={CustomImg} alt="Custom reports" />,
    },
    // exports
    {
        groupCode: 'exports',
        groupTitle: 'sidebar.reports.exports',
        groupIcon: <img height="35%" src={ExportsImg} alt="Exported reports" />,
    },
]

export const customReports = [
    {
        code: 'print-manual-registration-form',
        original_code: 'print-manual-registration-form',
        report_id: 'print-manual-registration-form',
        component: NewWindowOpener, // A custom report absolutely needs a component key. This is how we know to render it custom
        parameter: "{\"route\":\"dashboard#print-manual-registration-form\"}",
        type: 'REGISTRATION',
        summary_report: null,
        detailed_report: null,
        deprecated: 0,
        name: 'Print Registration Form',
        component_permissions: [],
        description: "Print Registration Form",
        i18n: {
            en: {
                name: "Print Registration Form",
                description: "Manually manage registrations by printing a PDF form",
            },
            fr: {
                name: "Imprimer le formulaire d'inscription",
                description: "Gérer manuellement les inscriptions en imprimant un formulaire PDF",
            },
        },
    },
]