import Translate from '@spordle/intl-elements';
import { memo, useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { I18nContext } from '../../../../contexts/I18nContext';
import { displayI18n, DisplayI18n } from '../../../../helpers/i18nHelper';
import SidePanel from '../../SidePanel';

const MemberHeader = ({ transfer }) => {
    const i18nContext = useContext(I18nContext);
    const i18nFromOrg = displayI18n('name', transfer.organisation?.i18n, transfer.organisation?.organisation_name, i18nContext.getGenericLocale());
    const i18nToOrg = displayI18n('name', transfer.target_organisation?.i18n, transfer.target_organisation?.organisation_name, i18nContext.getGenericLocale());
    return (
        <>
            <SidePanel.Title className='mb-2'><Translate id={'transfer.type.transfer.' + transfer.transfer_type} /></SidePanel.Title>
            <Row>
                <Col sm="6">
                    <div className="h6 font-bold"><Translate id='transfer.from' /></div>
                    <div className="mb-3">
                        <div className='font-medium text-dark text-truncate' title={i18nFromOrg}><DisplayI18n field='name' defaultValue={transfer.organisation?.organisation_name} i18n={transfer.organisation?.i18n} /></div>
                    </div>
                </Col>
                {transfer.target_organisation && transfer.target_organisation.organisation_name ?
                    <Col sm="6">
                        <div className="h6 font-bold"><Translate id='transfer.to' /></div>
                        <div className="mb-3">
                            <div className='font-medium text-dark text-truncate' title={i18nToOrg}><DisplayI18n field='name' defaultValue={transfer.target_organisation.organisation_name} i18n={transfer.target_organisation.i18n} /></div>
                        </div>
                    </Col>
                    :
                    <Col>
                        <div className="small text-truncate mx-1">-</div>
                    </Col>
                }
            </Row>
        </>
    );
};

export default memo(MemberHeader, (prevProps, nextProps) => prevProps.transfer.member_transfer_id === nextProps.transfer.member_transfer_id);