import { stringBuilder } from "@spordle/helpers"
import Translate from "@spordle/intl-elements"
import { useState } from "react"
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import JiraNotificationList from "../../../header/notifications/components/JiraNotificationList"
import { NotificationsDataManager } from "../../../header/notifications/components/NotificationsDataManager"
import OrganizationNotificationList from "../../../header/notifications/components/OrganizationNotificationList"

const MobileNotifications = ({ setActiveStep, handleOnToggle, menuIsOpen }) => {

    const [ tab, setActiveTab ] = useState('1');

    return (
        <>
            <div className="d-flex mb-2 w-100 mt-n2">
                <div className="h5 font-medium mb-0 ml-3"><Translate id="header.notifications.title" /></div>
                <Button
                    className='reset-btn text-dark font-bold ml-auto mr-3'
                    color='primary'
                    onClick={() => setActiveStep('settings')}
                >
                    <i className="font-18 fa fa-cog" />
                </Button>
            </div>
            <NotificationsDataManager.Consumer>
                {(helper) => (
                    <>
                        <Nav tabs className="notification-tabs is-full flex-shrink-0 w-100 mx-n3">
                            <NavItem className="flex-grow-1">
                                <NavLink
                                    tag="button"
                                    className={stringBuilder('w-100 bg-white', { 'active': tab === '1' })}
                                    onClick={() => setActiveTab('1')}
                                >
                                    <Translate id='header.notifications.tabs.organization' />{helper.notifsCount > 0 && ` (${helper.notifsCount})`}
                                </NavLink>
                            </NavItem>
                            <NavItem className="flex-grow-1">
                                <NavLink
                                    tag="button"
                                    className={stringBuilder('w-100 bg-white', { 'active': tab === '2' })}
                                    onClick={() => setActiveTab('2')}
                                >
                                    <Translate id='header.notifications.tabs.supportRequest' />{helper.jiraNotifs?.length > 0 && ` (${helper.jiraNotifs?.length})`}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={tab} className="bg-transparent flex-grow-1" style={{ 'overflowY': 'scroll' }}>
                            {/* Organization Notifs */}
                            <TabPane tabId="1">
                                <OrganizationNotificationList {...helper} toggle={handleOnToggle} />
                            </TabPane>

                            {/* Jira Notifs */}
                            <TabPane tabId="2">
                                <JiraNotificationList {...helper} toggle={handleOnToggle} isOpen={menuIsOpen} />
                            </TabPane>
                        </TabContent>
                    </>
                )}
            </NotificationsDataManager.Consumer>
        </>
    )
}

export default MobileNotifications