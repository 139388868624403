import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { RolesContext } from "../../../../contexts/RolesContext";
import { activeRoute } from "../../../../helpers/navigationHelper";
import MobileFooterMenu from "./menu/MobileFooterMenu";

const MobileFooterNavItem = ({ icon, children, links = [], isSmall, handleToggle, isOpened, isActive }) => {
    const rolesContext = useContext(RolesContext);

    return (
        <div
            className={stringBuilder("sid-mobile-footer-item-inner", { "is-open": isOpened, "is-small": isSmall, "is-active": isActive })}
            tabIndex="-1"
            onClick={handleToggle}
        >
            {icon}
            {
                (links.length > 0 || children) ?
                    <MobileFooterMenu
                        innerTag="ul"
                        isOpened={isOpened}
                        isSmall={isSmall}
                        handleToggle={handleToggle}
                    >
                        {children || links.reduce((newArray, link) => {
                            const showFor = rolesContext.validateRouteAccess(link);
                            if(showFor){
                                newArray.push(
                                    <li className={stringBuilder("sid-mobile-footer-menu-subitem sidebar-link", { "active": activeRoute(link.path) })} key={link.path + link.name}>
                                        <Link
                                            to={link.path}
                                            className={stringBuilder(
                                                "sid-mobile-footer-menu-subitem-link",
                                                { "text-purple": showFor === 'ADMIN' },
                                            )}
                                        >
                                            {link.icon} {link.skipTranslate ? link.name : <Translate id={link.name} />} <i className="mdi mdi-chevron-right ml-auto font-18 line-height-1" />
                                        </Link>
                                    </li>,
                                )
                            }
                            return newArray
                        }, [])}
                    </MobileFooterMenu>
                    :
                    null
            }
        </div>
    );
}


export default MobileFooterNavItem;