import React from "react";
import {
    Button,
    Card
} from "reactstrap";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';

import SidePanelOrganizationSuspensions from './SidePanelOrganizationSuspensions';
import Translate from "@spordle/intl-elements";
import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel";
import I18nHelperContextProvider, { I18nHelperContext } from '../../../../../helpers/i18nHelper';
import SuspensionsAddModal from "./SuspensionsAddModal";
import CardSectionTitle from "../../../../../components/CardSectionTitle";

// Contexts
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { SuspensionsContext } from '../../../../../contexts/SuspensionsContext';
import withContexts from "../../../../../helpers/withContexts";
import { I18nContext } from "../../../../../contexts/I18nContext";
import EmptyLayout from "../../../../../components/table/EmptyLayout";

class OrganizationSuspensions extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            addSuspensionModalOpen: false,
            isFederation: this.props.OrganizationContext.organisation_id === this.props.IdentityRolesContext.federation.organisation_id,
        }
    }

    toggleAddSuspension = () => {
        this.setState((prevState) => ({ addSuspensionModalOpen: !prevState.addSuspensionModalOpen }));
    }

    render(){
        return (
            <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <SpordlePanelTable
                            allowOutsideClick
                            sidePanel={(props) => <SidePanelOrganizationSuspensions {...props} tableRef={this.suspensionsTableRef} isFederation={this.state.isFederation} />}
                            dataIndex='suspension_id'
                            table={(panelProps) => {
                                return (
                                    <Card body className='card-shadow'>
                                        <CardSectionTitle title='settings.tabs.suspensions' />
                                        <SpordleTableProvider
                                            id='OrganizationSuspensionsTable'
                                            tableHover clickable striped
                                            bordered
                                            ref={(r) => { this.suspensionsTableRef = r; panelProps.spordleTableRef(r); }}
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                            dataIndex='suspension_id'
                                            // first index is most likely to be searched
                                            searchKeys={[
                                                `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                'name',
                                                'code',
                                            ]}
                                            columns={[
                                                ...i18nHelper.getTableColumns('name'),
                                                {
                                                    label: <Translate id='organization.settings.organizationSuspensions.table.code' />,
                                                    key: 'code',
                                                    className: 'text-left',
                                                    dataClassName: 'text-left',
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='organization.settings.organizationSuspensions.table.active' />,
                                                    key: "active",
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                            ]}
                                            onColumnClick={(e, suspension) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        panelProps.onSingleSelectChange(suspension);
                                                        break;
                                                }
                                            }}
                                            desktopWhen='md'
                                            emptyLayout={<EmptyLayout translateTitle title='organization.settings.organizationSuspensions.table.empty' />}
                                            renderRow={(columnKey, suspension, spordleTable) => {
                                                switch (columnKey){
                                                    case 'active':
                                                        return (
                                                            <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                {suspension.active == '1' ?
                                                                    <i className="text-primary mdi mdi-check" />
                                                                    :
                                                                    <i className="text-danger mdi mdi-close" />
                                                                }
                                                            </div>
                                                        )
                                                    default:
                                                        break;
                                                }
                                            }}
                                            rowIsHighlighted={(suspension) => !!suspension.checked}
                                            loadData={(from, _filterData, spordleTable) => {
                                                switch (from){
                                                    case 'REFRESH':
                                                        spordleTable.setLoading()
                                                    case 'CDM':
                                                        return this.props.SuspensionsContext.getSuspensions(this.props.OrganizationContext.organisation_id);
                                                    default:
                                                        break;
                                                }
                                            }}
                                        >
                                            <div className="mb-2">
                                                <div className='d-flex justify-content-between'>
                                                    <SpordleTableProvider.SearchInput />

                                                    <div className='d-flex ml-auto text-right'>
                                                        <SpordleTableProvider.Refresh />
                                                        { this.state.isFederation &&
                                                            <>
                                                                <Button color='primary' className='ml-2' onClick={this.toggleAddSuspension}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                                <SuspensionsAddModal toggleAddSuspension={this.toggleAddSuspension} addSuspensionModalOpen={this.state.addSuspensionModalOpen} />
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <SpordleTableView />
                                        </SpordleTableProvider>
                                    </Card>
                                )
                            }}
                        />
                    )}
                </I18nHelperContext.Consumer>
            </I18nHelperContextProvider>
        )
    }
}

export default withContexts(SuspensionsContext, OrganizationContext, IdentityRolesContext, I18nContext)(OrganizationSuspensions);