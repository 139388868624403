import { FormikError, FormikInputText } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { FieldArray, useFormikContext } from 'formik';
import { Card, CardBody, CardHeader, Collapse, FormGroup } from 'reactstrap';
import SpordleDraggable from '../../../../../components/DragNDrop/SpordleDraggable';
import SpordleDroppable from '../../../../../components/DragNDrop/SpordleDroppable';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { groupOptionsOpened, nakedQuestion } from '../CustomFormEditor';
import GroupQuestion from './GroupQuestion';
import VerticalOptions from './VerticalOptions';

/**
 * @description Renders the section body
 * @param {object} props
 */
const GroupSection = (props) => {
    const formik = useFormikContext();
    const { error, touched } = formik.getFieldMeta(`sections.${props.sectionIndex}.questions`);
    const hasError = touched && !Array.isArray(error) && error != null;
    return (
        <FieldArray name={`sections.${props.sectionIndex}.questions`}>
            {(questionsArrayHelpers) => (
                <>
                    <Card className={stringBuilder('border mb-3 card-shadow', { 'shadow-danger': hasError })}>
                        <CardHeader className="bg-white font-medium d-flex align-center">
                            <i className="mr-1 mdi mdi-drag-vertical font-20 position-absolute left-0" style={{ top: "8px", cursor: "grab" }} />
                            <div><Translate id='catalog.forms.components.formEditor.groupSection.countOf' values={{ count: props.sectionIndex + 1, total: formik.values.sections.length }} /></div>
                            <div className='ml-auto'>
                                {/* Because it is validating an array, we not to filter out the inner validation */}
                                <FormikError name={`sections.${props.sectionIndex}.questions`}>
                                    {(error) => !Array.isArray(error) && <div className='text-danger font-medium'><i className='mdi mdi-alert-outline mr-1' />{error}</div>}
                                </FormikError>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className="d-flex">
                                <OrganizationContext.Consumer>
                                    {({ hasFrench, hasEnglish }) => (
                                        <div className="leftSide flex-grow-1">
                                            {hasEnglish() &&
                                                <FormGroup>
                                                    <FormikInputText name={`sections.${props.sectionIndex}.i18n.en.name`} trim label='catalog.forms.components.formEditor.groupSection.title.en' />
                                                </FormGroup>
                                            }
                                            {hasFrench() &&
                                                <FormGroup>
                                                    <FormikInputText name={`sections.${props.sectionIndex}.i18n.fr.name`} trim label='catalog.forms.components.formEditor.groupSection.title.fr' />
                                                </FormGroup>
                                            }
                                        </div>
                                    )}
                                </OrganizationContext.Consumer>
                                <div className='rightSide' style={groupOptionsOpened}>
                                    <VerticalOptions
                                        addQuestion={questionsArrayHelpers.handlePush(nakedQuestion)}
                                        addSection={props.addSection}
                                        clone={props.cloneSection}
                                        delete={formik.values.sections.length > 1 ? props.deleteSection : undefined}// Cannot delete a section if it is the only one
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                    <SpordleDroppable droppableId={`sections.${props.sectionIndex}.questions`} type='QUESTION' direction='vertical' payload={questionsArrayHelpers}>
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {formik.values.sections[props.sectionIndex].questions.map((question, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <SpordleDraggable key={index} draggableId={`sections.${props.sectionIndex}.questions.${index}`} index={index} payload={question}>
                                        {(dragProvided) => (
                                            <div
                                                ref={dragProvided.innerRef}
                                                {...dragProvided.draggableProps}
                                                {...dragProvided.dragHandleProps}
                                            >
                                                {/* eslint-disable-next-line react/no-array-index-key */}
                                                <Collapse appear isOpen key={index}>
                                                    <GroupQuestion
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        key={index}
                                                        questionIndex={index}
                                                        sectionIndex={props.sectionIndex}
                                                        id={`sections.${props.sectionIndex}.questions.${index}`}
                                                        question={question}

                                                        addQuestion={questionsArrayHelpers.handlePush(nakedQuestion)}
                                                        addSection={props.addSection}
                                                        clone={questionsArrayHelpers.handleInsert(index + 1, {
                                                            ...question,
                                                            formFieldId: '', // remove id of the field so API creates a new one
                                                            options: question.options?.map((option) => ({
                                                                ...option,
                                                                fieldOptionId: '', // remove id of the option so API creates a new one
                                                            })),
                                                        })}
                                                        delete={questionsArrayHelpers.handleRemove(index)}
                                                    />
                                                </Collapse>
                                            </div>
                                        )}
                                    </SpordleDraggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </SpordleDroppable>
                </>
            )
            }
        </FieldArray>
    );
}

export default GroupSection;