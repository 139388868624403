export default{
    "settings.teamsSettings.list.duplicate" : "Duplicate",

    "settings.teamsSettings.list.hasTeamsSettings" : "Has restrictions",
    "settings.teamsSettings.list.none"             : "The form is empty.",
    "settings.teamsSettings.list.none2"            : "If you wish to delete the restrictions for this category, please do so from the category list.",

    "settings.teamsSettings.configuration.teamConfiguration"        : "Team Configuration Settings",
    "settings.teamsSettings.configuration.addLimit"                 : "Add Limit",
    "settings.teamsSettings.configuration.noLimit"                  : "No Limit Set.",
    "settings.teamsSettings.configuration.needPosGroup"             : "Please select a position group",
    "settings.teamsSettings.configuration.needPosition"             : "Please select a position",
    "settings.teamsSettings.configuration.needLimit"                : "Please specify a limit",
    "settings.teamsSettings.configuration.count"                    : "Limit",
    "settings.teamsSettings.configuration.restriction"              : "Restriction #{nb}",
    "settings.teamsSettings.configuration.addRestriction"           : "Add Restriction",
    "settings.teamsSettings.configuration.addRule"                  : "Add Rule",
    "settings.teamsSettings.configuration.category.validation"      : "Please select at least one category.",
    "settings.teamsSettings.configuration.position.validation"      : "Please select the position.",
    "settings.teamsSettings.configuration.qualification.validation" : "Please select the qualification.",
    "settings.teamsSettings.configuration.count.validation"         : "The minimum should be higher than 0.",

    "settings.teamsSettings.cardTitle"                  : "Teams",
    "settings.teamsSettings.categoryDivision.required"  : "You must have at least one division/category",
    "settings.teamsSettings.division"                   : "Division",
    "settings.teamsSettings.division.required"          : "The division or the category is required",
    "settings.teamsSettings.teamCategory"               : "Category",
    "settings.teamsSettings.teamCategory.textWhen"      : "{count} selected categories",
    "settings.teamsSettings.teamCategory.add"           : "Add a division / category",
    "settings.teamsSettings.restrictions"               : "Restrictions",
    "settings.teamsSettings.qualifications"             : "Qualifications",
    "settings.teamsSettings.settingName"                : "Name",
    "settings.teamsSettings.qualification"              : "Qualification",
    "settings.teamsSettings.qualification.required"     : "At least one qualification is required",
    "settings.teamsSettings.qualification.required.one" : "At least two qualifications are required with the selected restriction",
    "settings.teamsSettings.qualification.textWhen"     : "{count, plural, one {# qualification} other {# qualifications}}",
    "settings.teamsSettings.position"                   : "Position",
    "settings.teamsSettings.position.required"          : "The position is required",
    "settings.teamsSettings.position.textWhen"          : "{count, plural, one {# position} other {# positions}}",
    "settings.teamsSettings.restriction"                : "Restriction",
    "settings.teamsSettings.restriction.all"            : "All mandatory",
    "settings.teamsSettings.restriction.one"            : "At least one mandatory",
    "settings.teamsSettings.value"                      : "Value",
    "settings.teamsSettings.enforced"                   : "Enforced",
    "settings.teamsSettings.enforced.tooltip"           : "An enforced setting will prevent a user from doing an action while a non-enforced setting will only give a warning visible on the team's profile.",
    "settings.teamsSettings.active"                     : "Active",
    "settings.teamsSettings.active.tooltip"             : "An inactive setting won't prevent actions or display any warning for the teams.",
    "settings.teamsSettings.deleteMessage"              : "Delete these team restrictions?",
    "settings.teamsSettings.required"                   : "This field is required",
    "settings.teamsSettings.errors.3366"                : "Cannot create new restrictions. One of the selected divisions / categories already has restrictions.",
}