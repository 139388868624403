import { useContext, useState } from 'react';
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal";
import FormClinicSearchContact from './FormClinicSearchContact';
import FormClinicAddContact from './FormClinicAddContact';
import FormClinicSelectIdentities from './FormClinicSelectIdentities';
import { ClinicsContext } from '../../../../../../contexts/ClinicsContext';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { fail } from '@spordle/toasts';

/**
 *
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {boolean} props.isInstructor
 * @param {function} props.toggle
 * @returns {React.ReactNode}
 */
const FormClinicAddContactModal = (props) => {
    return (
        <AnalyticsModal analyticsName='FormClinicSessionInformation' isOpen={props.isOpen}>
            <FormClinicAddContactModalInner {...props} />
        </AnalyticsModal>
    )
}

const FormClinicAddContactModalInner = ({
    onCreate,
    alreadyExists,
    toggle,
    isInstructor = false,
    clinicId,
}) => {
    const [ currentStep, setCurrentStep ] = useState(0);
    const [ selectedMember, setSelectedMember ] = useState(null);
    const clinicsContext = useContext(ClinicsContext);

    const apiCall = (values) => {
        if(isInstructor){
            return clinicsContext.createInstructor(clinicId, values)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                });
        }
        if(values.asInstructor){
            return Promise.all([
                clinicsContext.createContact(clinicId, values),
                clinicsContext.createInstructor(clinicId, values),
            ]).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        }
        return clinicsContext.createContact(clinicId, values).catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            }
        });
    }

    return (
        <>
            {/* <FormClinicCreateContact isInstructor={isInstructor} toggle={toggle} isOpen={currentStep === 0} selectMember={selectMember} next={lastStep} toggleSearch={() => setCurrentStep(1)} /> */}
            <FormClinicSearchContact
                isInstructor={isInstructor}
                toggle={toggle}
                isOpen={currentStep === 0}
                selectMember={(member) => {
                    setSelectedMember(member);
                    setCurrentStep(1);
                }}
            />
            <FormClinicAddContact
                member={selectedMember}
                previous={() => setCurrentStep(0)}
                isInstructor={isInstructor}
                onCreate={onCreate}
                alreadyExists={alreadyExists}
                clinicId={clinicId}
                toggle={toggle}
                isOpen={currentStep === 1}
                // Will be used only when the member already exists
                selectMember={(member) => {
                    setSelectedMember(member);
                    setCurrentStep(2);
                }}
                apiCall={apiCall}
            />
            <FormClinicSelectIdentities
                isInstructor={isInstructor}
                isOpen={currentStep === 2}
                member={selectedMember}
                onCreate={onCreate}
                toggle={toggle}
                goToPrevious={() => {
                    setCurrentStep(1);
                }}
                apiCall={apiCall}
                selectMember={(member) => {
                    setSelectedMember(member);
                }}
            />
        </>
    )
}

export default FormClinicAddContactModal;