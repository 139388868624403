import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    Card
} from "reactstrap";
import MerchantAccontProviderSettingsForm from './MerchantAccontProviderSettingsForm';
import MerchantAccontProviderSettingsView from './MerchantAccontProviderSettingsView';
import { IdentityRolesContext } from '../../../../../../contexts/IdentityRolesContext';
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import CrossFade from '../../../../../../components/crossFade/CrossFade';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';

const MerchantAccontProviderSettings = () => {
    const [ isEditing, setIsEditing ] = useState(false);
    const toggleIsEditing = () => setIsEditing(!isEditing);

    const identityRolesContext = useContext(IdentityRolesContext);
    const organizationContext = useContext(OrganizationContext)

    const canEdit = organizationContext.settings.organisation_merchant_account_provider.can_manage == 1

    return (
        <Card body className="card-shadow">
            <CardSectionTitle title='organization.settings.merchantAccounts.setting.title' className='d-flex'>
                {!isEditing &&
                    <div className={stringBuilder('ml-auto', { 'text-link': canEdit })}>
                        <button
                            disabled={!canEdit && !identityRolesContext.isGod()}
                            onClick={toggleIsEditing}
                            className={stringBuilder("reset-btn align-self-end", { "text-purple": (identityRolesContext.isGod() && !canEdit) })}
                            type='button'
                        >
                            <Translate id='misc.edit' />
                        </button>
                    </div>
                }

            </CardSectionTitle>
            <CrossFade isVisible={isEditing}>
                <MerchantAccontProviderSettingsForm toggleIsEditing={toggleIsEditing} />
            </CrossFade>
            <CrossFade isVisible={!isEditing}>
                <MerchantAccontProviderSettingsView />
            </CrossFade>

        </Card>
    );
}

export default MerchantAccontProviderSettings;