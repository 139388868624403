import { useContext } from "react";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import { SendCommModalContext } from "../../../context/SendCommModalContext";
import { MODE_MEMBER, MODE_ORG } from "../../../sendCommHelper";
import CommTypeBtn from "./CommTypeBtn";
import Translate from "@spordle/intl-elements";

const SelectCommType = () => {
    const { setMode, next, toggle } = useContext(SendCommModalContext);

    const handleOnClick = (mode) => {
        return () => {
            setMode(mode);
            next();
        }
    }

    return (
        <>
            <ModalBody>
                <CommTypeBtn
                    onClick={handleOnClick(MODE_ORG)}
                    title={<Translate id="communications.sendComm.selectType.org" />}
                    subtitle={<Translate id="communications.sendComm.selectType.org.subtitle" />}
                />
                <CommTypeBtn
                    onClick={handleOnClick(MODE_MEMBER)}
                    title={<Translate id="communications.sendComm.selectType.member" />}
                    subtitle={<Translate id="communications.sendComm.selectType.member.subtitle" />}
                />
                {/* <CommTypeBtn
                    onClick={handleOnClick(MODE_IDENTITY)}
                    title="MyAccount"
                    subtitle="Send a communication to one or multiple accounts."
                /> */}
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="primary" outline onClick={toggle}>
                    <Translate id="misc.cancel" />
                </Button>
            </ModalFooter>
        </>
    );
}

export default SelectCommType;