import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { useContext } from 'react';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink as ReactStrapNavLink, UncontrolledDropdown } from 'reactstrap';
import { RolesContext } from '../../contexts/RolesContext';
import { QuickViewContext } from '../../contexts/QuickViewContext';
import IsGod from '../permissions/IsGod';
import { AppContext } from '../../contexts/contexts';
import styles from "./TabRoutesNavs.module.scss";

const TabRoutesNavs = ({ routes }) => {
    const { setQuickViewMemberProfileUrl } = useContext(QuickViewContext);
    const { isMobileDevice } = useContext(AppContext);
    const roleContext = useContext(RolesContext);
    const location = useLocation();
    const match = useRouteMatch();

    // In QuickView, we will have a different link that will simulate the link and use QuickViewContext for it's routing
    // const TabRouteLink = !isQuickView ? ReactStrapNavLink : QuickViewTabNavLink;
    const { tabs, dropdownItems } = routes.reduce((formattedRoutes, route) => {
        if(!route.redirect){
            const routeAccess = roleContext.validateRouteAccess(route);
            const Wrapper = routeAccess === 'ADMIN' && !route.disabled ? IsGod : NavItem;

            if(!route.hiddenTab && !!routeAccess){
                formattedRoutes.tabs.push(
                    <Wrapper id={`tab${match.url.replace('/', '-')}${route.path.replace('/', '-')}`} key={route.path} tag={NavItem} className={route.tempTab && !route.disabled ? 'nav-item-temp' : undefined} hidden={route.tempTab ? location.pathname !== `${match.url}${route.path}` : false}>
                        <ReactStrapNavLink
                            to={`${match.url}${route.path}`}
                            exact={route.exact}
                            activeClassName='active'
                            className={stringBuilder({ 'text-purple': routeAccess === 'ADMIN' && !route.disabled, 'disabled': route.disabled })}
                            tag={NavLink}
                            onClick={() => setQuickViewMemberProfileUrl(route.path)}
                        >
                            <Translate id={route.name} />
                        </ReactStrapNavLink>
                    </Wrapper>,
                );

                if(!route.tempTab){
                    formattedRoutes.dropdownItems.push(
                        <DropdownItem
                            to={`${match.url}${route.path}`}
                            exact={route.exact}
                            tag={NavLink}
                            onClick={() => setQuickViewMemberProfileUrl(route.path)}
                            className={stringBuilder({ 'text-purple': routeAccess === 'ADMIN' && !route.disabled, 'disabled': route.disabled })}
                        >
                            <Translate id={route.name} />
                        </DropdownItem>,
                    );
                }
            }
        }
        return formattedRoutes;
    }, { tabs: [], dropdownItems: [] })

    return (
        <div className="position-relative">
            <Nav tabs className="mb-4 font-medium font-16 text-dark pretty-scrollbar">
                {tabs}
            </Nav>
            {isMobileDevice && dropdownItems.length > 3 &&
                <UncontrolledDropdown className={`position-absolute right-0 top-0 mr-n1 ${styles.Dropdown}`}>
                    <DropdownToggle color="link" className="bg-light-inverse px-1 mdi mdi-chevron-down text-dark" />
                    <DropdownMenu right>
                        {dropdownItems}
                    </DropdownMenu>
                </UncontrolledDropdown>
            }
        </div>
    );
};

export default TabRoutesNavs;