import { Button } from "reactstrap";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from "react-router-dom";
import { Status } from "../../../supportRequests/SupportRequestHelpers";
import Priority from "../../../supportRequests/Priority";
import Skeleton from "react-loading-skeleton";
import Translate from "@spordle/intl-elements";
import { useState } from "react";
import SubmitTicket from "../../../../components/helpCenter/submitTicket/SubmitTicket";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";

const SupportRequestWidgetPreview = ({ isLoading, getApiData, content }) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const isEmpty = content.length === 0;

    const toggleSubmitTicket = () => {
        setIsOpen((prev) => !prev);
    }

    const onTicketCreated = async() => {
        await getApiData();
    }

    return (
        <div className="mailbox position-relative min-h-0 h-100 d-flex flex-column">
            <SubmitTicket onSuccess={onTicketCreated} isOpen={isOpen} toggle={toggleSubmitTicket} />
            {!isLoading && isEmpty ?
                <div className="rounded-lg bg-light-inverse text-center h-100 d-flex flex-center flex-column">
                    <div className="font-bold h4 text-dark mb-1"><Translate id="dashboard.widget.preview.supportRequest.empty.title" /></div>
                    <p className="mb-1"><Translate id="dashboard.widget.preview.supportRequest.empty.subtitle" /></p>
                    <Button type="button" onClick={toggleSubmitTicket} className="mb-n2" color="link">
                        <Translate id="dashboard.widget.preview.supportRequest.empty.btn" />
                    </Button>
                </div>
                :
                <>
                    <div className="text-right mb-2 mt-n2">
                        <Button className="ml-n2" onClick={toggleSubmitTicket} size="sm" type="button" color="link">
                            <i style={{ height: 15, width: 15 }} className="font-12 d-inline-flex flex-center mdi mdi-plus line-height-0 text-white bg-primary rounded-circle" /> <Translate id="misc.create" />
                        </Button>
                    </div>
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        <div className="overflow-hidden">
                            {isLoading ?
                                <Skeleton height={68} count={3} />
                                :
                                <>
                                    {
                                        content.map((supportRequest) => (
                                            <Link
                                                key={supportRequest.id}
                                                to={`/supportRequest/${supportRequest.key}`}
                                                className='mb-2 d-block'
                                            >
                                                <UserDisplay card block className="bg-light-hover align-items-center mb-2">
                                                    <UserDisplay.Container>
                                                        <div
                                                            className="d-flex flex-center bg-primary-light text-primary rounded-circle border align-middle"
                                                            style={{ height: 50, width: 50 }}
                                                        >
                                                            <i className="mdi mdi-wrench font-22" />
                                                        </div>
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container>
                                                        <div className="small">
                                                            {supportRequest.key}<span className="text-muted line-height-0"><span className="mx-2">|</span> <Priority color={supportRequest.fields.priority.id}>{supportRequest.fields.priority.name}</Priority></span>
                                                        </div>
                                                        <div className="font-medium h5 mb-0">
                                                            {supportRequest.fields.summary}
                                                        </div>
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container className="ml-auto text-md-right">
                                                        <Status status={supportRequest.fields.status.statusCategory} />
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            </Link>
                                        ))
                                    }
                                </>
                            }
                        </div>
                    </PerfectScrollbar>
                </>
            }
        </div>
    );
}

export default SupportRequestWidgetPreview;