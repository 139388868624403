import React, { useEffect } from "react";
import {
    Badge,
    Card,
    Spinner
} from "reactstrap";

import SpordleTableProvider, { SpordleTableView, useSpordleTable } from '@spordle/datatables';
import Translate, { DateFormat } from '@spordle/intl-elements';
import SpordlePanelTable from "../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../components/table/EmptyLayout";

// contexts
import { ClinicsContext } from '../../../../contexts/ClinicsContext';
import withContexts from '../../../../helpers/withContexts';
import { withRouter, useLocation } from "react-router";
import { I18nContext } from "../../../../contexts/I18nContext";
import { ReportsContext } from "../../../../contexts/ReportsContext";
import { Link } from "react-router-dom";
import TablePagination from "../../../../components/table/TablePagination";
import SidePanelWaitlist from "./sidePanel/SidePanelWaitlist";
import SpordleSelect from "@spordle/spordle-select";
import { Tooltip } from "@mantine/core";

class ClinicProfileWaitlist extends React.Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    getBadgeColour = (status) => {
        switch (status){
            case 'PENDING':
            case 'WAITING_FOR_CLIENT':
                return 'warning';
            case 'CANCELLED':
            case 'CANCELED':
                return 'danger';
            case 'CONVERTED':
                return 'success';
            default:
                return 'danger';
        }
    }

    render(){
        return (
            <Card body className="card-shadow">
                <div className="h4 font-bold pb-1 border-bottom mb-3 card-title">
                    <Translate id='clinics.profile.clinicProfile.tabs.waitlist' />
                </div>
                <div>
                    {this.props.ClinicsContext.currentClinic?.clinic_id === this.props.clinicId ?
                        <SpordlePanelTable
                            allowOutsideClick
                            sidePanel={(props) => <SidePanelWaitlist {...props} getBadgeColour={this.getBadgeColour} attendeeTable={this.props.attendeeTable} />}
                            dataIndex='waiting_list_item_sequential_number'
                            table={(panelProps) => {
                                return (
                                    <SpordleTableProvider
                                        id='clinic_profile_waitlist'
                                        tableHover bordered striped
                                        clickable
                                        ref={(r) => {
                                            this.props.waitlistTable.current = r;
                                            panelProps.spordleTableRef(r);
                                        }}
                                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                        searchKeys={[
                                            { name: 'member.unique_identifier', weight: 2 },
                                            'waiting_list_item_sequential_number',
                                            'member.last_name',
                                            'member.first_name',
                                            'created_at',
                                            'birthdate',
                                        ]}
                                        pagination={25}
                                        desktopWhen
                                        emptyLayout={<EmptyLayout />}
                                        dataIndex='waiting_list_item_sequential_number'
                                        defaultSorting="+created_at"
                                        columns={[
                                            {
                                                label: <Translate id='clinics.profile.waitlist.table.waiting_list_item_sequential_number' />,
                                                key: 'waiting_list_item_sequential_number',
                                                className: 'th-shrink',
                                                dataClassName: 'td-shrink',
                                                sortable: true,
                                            },
                                            {
                                                label: <Translate id='clinics.profile.waitlist.table.created_at' />,
                                                key: 'created_at',
                                                className: 'th-shrink',
                                                dataClassName: 'td-shrink',
                                                sortable: true,
                                            },
                                            {
                                                label: <Translate id='form.fields.name' />,
                                                key: "name",
                                                className: 'text-left',
                                                dataClassName: 'text-left',
                                                mobile: true,
                                                sortable: true,
                                                sortKey: 'member.first_name',
                                            },
                                            {
                                                label: <Translate id='form.fields.dateOfBirth' />,
                                                key: "birthdate",
                                                className: 'text-left',
                                                dataClassName: 'text-left',
                                                mobile: true,
                                                sortable: true,
                                                sortKey: 'member.birthdate',
                                            },
                                            {
                                                label: <Translate id='form.fields.status' />,
                                                key: "status",
                                                mobile: true,
                                                sortable: true,
                                            },
                                        ]}
                                        onColumnClick={(e, attendee, _spordleTable, columnKey) => {
                                            switch (e.button){
                                                case 0: // Left mouse button
                                                    panelProps.onSingleSelectChange(attendee);
                                                    break;
                                            }
                                        }}
                                        renderRow={(columnKey, attendee) => {
                                            switch (columnKey){
                                                case 'waiting_list_item_sequential_number':
                                                    return (
                                                        <>
                                                            <div>{attendee.waiting_list_item_sequential_number}</div>
                                                            <small>
                                                                <i className="text-primary mdi mdi-information-outline mr-1" />
                                                                {attendee.identity ?
                                                                    <Translate id='clinics.profile.waitlist.table.online' />
                                                                    :
                                                                    <Translate id='clinics.profile.waitlist.table.admin' />
                                                                }
                                                            </small>
                                                        </>
                                                    );
                                                case 'name':
                                                    return (
                                                        <NameRender member={attendee.member} />
                                                    );
                                                case 'birthdate':
                                                    return (
                                                        <>
                                                            {(attendee.member.birthdate || attendee.member.age) ?
                                                                <>
                                                                    {attendee.member.birthdate && <div><DateFormat value={attendee.member.birthdate} utc /></div>}
                                                                    {attendee.member.age && <div className="text-muted small"><Translate id='misc.yearsOld' values={{ age: attendee.member.age }} /></div>}
                                                                </>
                                                                : '-'}
                                                        </>
                                                    );
                                                case 'created_at':
                                                    return (
                                                        <DateFormat value={attendee.created_at} utc format='YYYY-MM-DD HH:mm' />
                                                    );
                                                case 'status':
                                                    return (
                                                        <Badge color={this.getBadgeColour(attendee.status)}><Translate id={`clinics.profile.waitlist.status.${attendee.status}`} /></Badge>
                                                    );
                                                default:
                                                    return attendee[columnKey];
                                            }
                                        }}
                                        loadData={(from, data, spordleTable) => {
                                            switch (from){
                                                case 'FILTER':
                                                case 'REFRESH':
                                                    spordleTable.setLoading();
                                                    return this.props.ClinicsContext.getClinicWaitinglist({
                                                        clinic_id: this.props.ClinicsContext.currentClinic?.clinic_id,
                                                        period_id: this.props.ClinicsContext.currentClinic?.period.period_id,
                                                        organisation_id: this.props.ClinicsContext.currentClinic?.organisation.organisation_id,
                                                        type: 'CLINIC',
                                                    }).then((waitlist) => {
                                                        this.props.setWaitListCount(waitlist.length);
                                                        if(spordleTable.getFilters().status){
                                                            return waitlist.filter((item) => (item.status === spordleTable.getFilters().status));
                                                        }
                                                        return waitlist;

                                                    })
                                                case 'CDM':
                                                    return this.props.ClinicsContext.getClinicWaitinglist({
                                                        clinic_id: this.props.ClinicsContext.currentClinic?.clinic_id,
                                                        period_id: this.props.ClinicsContext.currentClinic?.period.period_id,
                                                        organisation_id: this.props.ClinicsContext.currentClinic?.organisation.organisation_id,
                                                        type: 'CLINIC',
                                                    }).then((waitlist) => {
                                                        this.props.setWaitListCount(waitlist.length);
                                                        return waitlist;
                                                    })
                                                default:
                                                    break;
                                            }
                                        }}
                                        initFilter={{
                                            status: null,
                                        }}
                                        paginationMessage='clinics.profile.attendees.table.paginationMessage'
                                    >
                                        {(spordleTable) => (
                                            <>
                                                <AttendeeHandleSearch panelProps={panelProps} />
                                                <div className="mb-2">
                                                    <div className='d-flex flex-wrap justify-content-between mb-2'>
                                                        <SpordleTableProvider.SearchInput />
                                                        <div className="ml-2" style={{ minWidth: '226px' }}>
                                                            <SpordleSelect
                                                                id='statusFilter'
                                                                search={false} clearable
                                                                defaultData={[
                                                                    { value: 'PENDING', label: 'clinics.profile.waitlist.status.PENDING', translateLabel: true },
                                                                    { value: 'WAITING_FOR_CLIENT', label: 'clinics.profile.waitlist.status.WAITING_FOR_CLIENT', translateLabel: true },
                                                                    { value: 'CANCELED', label: 'clinics.profile.waitlist.status.CANCELED', translateLabel: true },
                                                                    { value: 'CONVERTED', label: 'clinics.profile.waitlist.status.CONVERTED', translateLabel: true },
                                                                ]}
                                                                loadingStatus='success'
                                                                placeholder='clinics.profile.attendees.table.header.statusFilter.placeholder'
                                                                onOptionSelected={([ value ]) => {
                                                                    spordleTable.filterChange('status', value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='d-flex ml-auto'>
                                                            <TablePagination className='ml-2' paginationArray={[ 25, 50, 100 ]} />
                                                            <SpordleTableProvider.Refresh className='ml-2' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <SpordleTableView />
                                            </>
                                        )}
                                    </SpordleTableProvider>
                                )
                            }}
                        />
                        :
                        <div className='text-center'>
                            <Spinner color='primary' type='grow' />
                            <h4><Translate id='misc.loading' /></h4>
                        </div>
                    }
                </div>
            </Card>
        )
    }
}

const AttendeeHandleSearch = ({ panelProps }) => {
    const spordleTable = useSpordleTable();
    const location = useLocation();

    const getInitialSearch = () => {
        const searchAttendee = `${location.search}`;
        if(searchAttendee){
            return searchAttendee.split('?')[1];
        }
        return "";
    }

    const selectTableAttendee = (search) => {
        const waitlistMember = spordleTable.getData().find((waitlistMember) => waitlistMember.member.unique_identifier === search);

        if(waitlistMember){
            // This verification is necessary because of mutated data in cache
            // Specific case :
            // 1. Getting in the clinic attendees with search query,
            // 2. then leaving
            // 3. then coming back on the same clinic with the same search
            if(waitlistMember.checked){
                panelProps.uncheckAll();
            }

            spordleTable.setInputValue(search);
            panelProps.onSingleSelectChange(waitlistMember);
        }
    }

    useEffect(() => {
        if(spordleTable.state.loadingState === 'success'){
            const search = getInitialSearch();
            if(search){
                selectTableAttendee(search);
            }

        }

    }, [ spordleTable.state.loadingState ]);

    return null;

}

const NameRender = ({ member }) => {
    return (
        <div className='d-flex align-items-start justify-content-between'>
            <div className='flex-grow-1'>
                <div className="font-medium">{member.first_name + ' ' + member.last_name}</div>
                <div className="small">
                    <Link className="text-nowrap" to={`/members/profile/${member.member_id}`}>
                        {member.unique_identifier ?
                            <>
                                #{member.unique_identifier}
                            </>
                            :
                            <Translate id='clinics.profile.waitlist.table.goToProfile' />
                        }
                        <i className="ml-1 mdi mdi-chevron-right" />
                    </Link>
                </div>
            </div>
            {member.confirmed == '0' &&
                <div className='flex-grow-0'>
                    <Tooltip withArrow label={<Translate id='clinics.profile.attendees.sidepanel.header.notConfirmed' />}>
                        <i className='text-danger mdi mdi-information-outline' style={{ fontSize: '18px' }} />
                    </Tooltip>

                </div>
            }
        </div>
    )
}

export default withRouter(withContexts(ClinicsContext, I18nContext, ReportsContext)(ClinicProfileWaitlist));
