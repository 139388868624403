import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import {
    Button,
    Col,
    Collapse,
    Fade,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import OverlayLoader from "../loading/OverlayLoader";
import { object, string } from 'yup';
import { FormikAddress, FormikError, FormikInputText, FormikSelect, getFormikAddressInitialValues } from "@spordle/formik-elements";
import Required from "../formik/Required";
import OrganizationSearch from "./OrganizationSearch";
import { useContext, useRef, useState } from "react";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { Link } from "react-router-dom";
import AnalyticsModal from "../../analytics/AnalyticsModal";
import { DisplayI18n } from "../../helpers/i18nHelper";
import CustomAlert from '../CustomAlert';
import { I18nContext } from "../../contexts/I18nContext";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";
import { getAdditionalFieldsInitialValues, getAdditionalFieldsRender, getAdditionalFieldsValidationSchema, getFormattedAdditionalFields } from "../../helpers/additionalFieldsHelpers";

const OrganizationCreationModal = (props) => (
    <AnalyticsModal analyticsName='OrganizationCreationModal' isOpen={props.isOpen} unmountOnClose>
        <OrganizationCreationModalInner {...props} />
    </AnalyticsModal>
)

const OrganizationCreationModalInner = ({ isOpen, toggle }) => {
    const [ isCreated, setIsCreated ] = useState(false);
    const { createOrganization, getOrganizationCategory, additionalFieldsComponents } = useContext(OrganizationContext);
    const i18n = useContext(I18nContext);
    const identityRolesContext = useContext(IdentityRolesContext)

    /**  @type {React.MutableRefObject<import('@spordle/spordle-select/dist/components/SpordleSelect').default>} */
    const orgCategoryRef = useRef();

    const getFormGroups = () => {
        return additionalFieldsComponents?.find((a) => a.component === 'ORGANISATION')?.groups || []
    }

    return (
        isCreated ?
            <Fade>
                <ModalHeader><Translate id='components.createOrganizationModal.success.title' /></ModalHeader>
                <ModalBody>
                    <Translate id='components.createOrganizationModal.success.msg' />
                </ModalBody>
                <ModalFooter>
                    <Link
                        className="btn btn-primary"
                        to={(location) => ({
                            pathname: '/changeorganization',
                            state: { selected: isCreated },
                        })}
                        onClick={toggle}
                    >
                        <Translate id='components.createOrganizationModal.success.btn' />
                    </Link>
                    <Button type="button" outline onClick={toggle} color="primary">
                        <Translate id="misc.close" />
                    </Button>
                </ModalFooter>
            </Fade>
            :
            <Formik
                initialValues={{
                    name: '',
                    usual_name: '',
                    abbreviation: '',
                    organisation_id_parent: '',
                    organisation_category_id: '',
                    address: getFormikAddressInitialValues(),
                    fields: getAdditionalFieldsInitialValues(getFormGroups()),
                }}
                validationSchema={object().shape({
                    name: string().required(<Translate id='form.validation.name.required' />),
                    usual_name: string(),
                    abbreviation: string().required(<Translate id='components.createOrganizationModal.form.abbreviation.required' />),
                    organisation_id_parent: string().required(<Translate id='components.createOrganizationModal.form.parent.required' />),
                    organisation_category_id: string().required(<Translate id='components.createOrganizationModal.form.orgCategory.required' />),
                    address: object().address(true, {
                        streetNumber: <Translate id='form.validation.streetNumber.required' />,
                        address: <Translate id='form.validation.address.required' />,
                        city: <Translate id='form.validation.city.required' />,
                        zipCode: <Translate id='form.validation.zip.required' />,
                        state: <Translate id='form.validation.province.required' />,
                        country: <Translate id='form.validation.country.required' />,
                    }),
                    fields: getAdditionalFieldsValidationSchema(getFormGroups()),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    createOrganization({ ...values, ...values.address, fields: getFormattedAdditionalFields(values.fields, getFormGroups(), i18n.getGenericLocale()) })
                        .then((newId) => {
                            setSubmitting(false);
                            setIsCreated({
                                identityRole: {
                                    identity_role_id: identityRolesContext.identity_role_id,
                                    organisation: identityRolesContext.organisation,
                                    role: identityRolesContext.role,
                                },
                                org: {
                                    organisation_id: newId,
                                    organisation_name: values.name,
                                    i18n: null,
                                    logo: null,
                                    abbreviation: values.abbreviation,
                                },
                            });
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                setSubmitting(false);
                            }
                        })
                }}
            >
                {(formik) => (
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <Form>
                            <ModalHeader toggle={toggle}>
                                <Translate id='components.createOrganizationModal.title' /> <span className="text-purple">(Power User)</span>
                            </ModalHeader>
                            <ModalBody>
                                <div className="font-bold text-dark mb-2"><Translate id='misc.baseData' /></div>
                                <FormGroup>
                                    <Label for="organisation_id_parent" className="text-muted"><Translate id='components.createOrganizationModal.form.parent.label' /> <Required /></Label>
                                    <div className="position-relative z-index-popover">
                                        <OrganizationSearch
                                            withFormik
                                            onOptionSelected={(values) => {
                                                formik.setFieldValue('organisation_category_id', '');
                                                orgCategoryRef.current?.getSpordleTable().filterChange('orgId', values[0])
                                            }}
                                            id="organisation_id_parent"
                                            name="organisation_id_parent"
                                        />
                                    </div>
                                    <FormikError name="organisation_id_parent" />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="text-muted"><Translate id='components.createOrganizationModal.form.orgCategory.label' /> <Required /></Label>
                                    <FormikSelect
                                        name='organisation_category_id'
                                        id='organisation_category_id'
                                        ref={orgCategoryRef}
                                        disabled={!formik.values.organisation_id_parent}
                                        renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                        searchKeys={[
                                            `i18n.${i18n.getGenericLocale()}.name`,
                                        ]}
                                        initFilter={{
                                            orgId: '',
                                        }}
                                        loadData={(from, extra, spordleTable) => {
                                            switch (from){
                                                case 'FILTER':
                                                    spordleTable.setLoading();
                                                case 'CDM':
                                                    if(extra.filters.orgId){
                                                        return getOrganizationCategory(extra.filters.orgId)
                                                            .then((categories) => {
                                                                return categories.map((category) => ({
                                                                    value: category.category_id,
                                                                    label: category.default_name,
                                                                    i18n: category.i18n,
                                                                }))
                                                            })
                                                    }
                                                    return Promise.resolve([]);

                                            }
                                        }}
                                    />
                                </FormGroup>
                                <Row form>
                                    <Col className="form-group" md="12">
                                        <Label for="name" className="text-muted"><Translate id='form.fields.name' /> <Required /></Label>
                                        <FormikInputText id="name" name="name" trim />
                                    </Col>
                                    <Col className="form-group" md="6">
                                        <Label for="usual_name" className="text-muted"><Translate id='organization.profile.overview.generalInfo.form.fields.usualNameShort' /></Label>
                                        <FormikInputText id="usual_name" name="usual_name" trim />
                                    </Col>
                                    <Col className="form-group" md="6">
                                        <Label for="abbreviation" className="text-muted"><Translate id='components.createOrganizationModal.form.abbreviation.label' /> <Required /></Label>
                                        <FormikInputText id="abbreviation" name="abbreviation" trim />
                                    </Col>
                                    <Col md="12">
                                        <FormikAddress
                                            id="address"
                                            name="address"
                                            label='form.fields.address'
                                            labelClassName="text-muted"
                                            allowCopyAddress
                                            allowManualPlace
                                            allowOpenInMaps
                                            required
                                        />
                                    </Col>
                                </Row>
                                {getAdditionalFieldsRender(getFormGroups(), 'fields')}
                                {formik.status &&
                                <Collapse isOpen appear>
                                    <CustomAlert
                                        className='mt-3 text-left'
                                        color='danger'
                                        withTitle
                                        text={formik.status}
                                        translateText={false}
                                        toggle={() => formik.setStatus('')}
                                    />
                                </Collapse>
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button type="submit" disabled={formik.isSubmitting || !isOpen} color="primary"><Translate id="misc.add" /></Button>
                                <Button type="button" disabled={formik.isSubmitting || !isOpen} color="primary" outline onClick={toggle}><Translate id="misc.cancel" /></Button>
                            </ModalFooter>
                        </Form>
                    </OverlayLoader>
                )}
            </Formik>
    )
}

export default OrganizationCreationModal;