import { stringBuilder } from '@spordle/helpers';
import Translate from "@spordle/intl-elements";

/**
 * @description Common style of a step title
 * @param {Object} props
 * @param {string} props.title Translate id
 * @param {string} props.className
 * @returns {JSX.Element}
 */
const StepTitle = ({ title, className, ...props }) => <div {...props} className={stringBuilder("font-bold h4", className)}><Translate id={title} /></div>
export default StepTitle;