import { FieldLabel } from "@spordle/cart-engine";
import { stringBuilder } from "@spordle/helpers";
import SpecialFieldComponentWrapper from "../../views/members/components/SpecialFieldComponentWrapper";
import { useMemberFieldsSWR } from "../customHooks/useMemberFieldsSWR";

const MemberFieldLabel = ({ withSpecialRequired, ...props }) => {
    const { data } = useMemberFieldsSWR();

    return (
        <FieldLabel
            fieldsSettings={data}
            tooltipProps={{
                iconProps: {
                    className: 'text-primary',
                },
            }}
            requiredStarRender={withSpecialRequired ? <SpecialFieldComponentWrapper withRequired={props.isRequired} field={props.field} /> : void 0}
            {...props}
            className={stringBuilder(props.className, "d-block member-field")}
        />
    );
}

export default MemberFieldLabel;