import { useContext } from 'react';
import Translate from "@spordle/intl-elements";
import { Button, ModalBody, ModalFooter } from "reactstrap";

// contexts
import { RefundModalContext } from './OnlineStoreTransactionRefundModal';

const RefundModalConfirmation = (props) => {
    const refundModalContext = useContext(RefundModalContext)
    return (
        <>
            {refundModalContext.props.mode == 'clinics' ?
                <>
                    <ModalBody>
                        <div className="h5 font-bold">
                            <Translate id="misc.success" />
                        </div>
                        <p>
                            <Translate id='onlineStore.profile.transactions.modals.refundModal.cancel.msg' />
                        </p>
                        <p>
                            <Translate id='clinics.profile.attendees.sidepanel.refund.attendee.confirmation.question' />
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="primary" onClick={refundModalContext.handleCancel}>
                            <Translate id='clinics.profile.attendees.sidepanel.refund.attendee.confirmation.btn' />
                        </Button>
                        <Button type="button" color="primary" onClick={refundModalContext.handleToggleRefresh} outline>
                            <Translate id='misc.close' />
                        </Button>
                    </ModalFooter>
                </>
                :
                <>
                    <ModalBody>
                        <div className="h5 font-bold">
                            <Translate id="misc.success" />
                        </div>
                        <p>
                            <Translate id='onlineStore.profile.transactions.modals.refundModal.cancel.msg' />
                        </p>
                        <p>
                            <Translate id='onlineStore.profile.transactions.modals.refundModal.cancel.question' />
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="primary" onClick={refundModalContext.handleCancel}>
                            <Translate id='onlineStore.profile.transactions.modals.refundModal.cancel.btn' />
                        </Button>
                        <Button type="button" color="primary" onClick={refundModalContext.handleToggleRefresh} outline>
                            <Translate id='misc.close' />
                        </Button>
                    </ModalFooter>
                </>
            }
        </>
    )
}

export default RefundModalConfirmation;