import { useTour } from "@reactour/tour";
import { useEffect, useRef } from "react";

const TourUpdater = ({ queue = [] }) => {
    const { setIsOpen, setSteps, setCurrentStep, isOpen, currentStep, steps } = useTour();
    const wasOpen = useRef(false);

    useEffect(() => {
        const activeTour = queue[0];
        if(activeTour && !isOpen && !wasOpen.current && activeTour.steps.length > 0){
            setSteps(activeTour.steps);
            setCurrentStep(0);
            setIsOpen(true);
        }

        wasOpen.current = isOpen;
    }, [ queue[0]?.name ]);

    useEffect(() => {
        if(!steps[currentStep] && isOpen){ // Tour doesn't set isOpen to false when step doesn't exist and doesn't call onBeforeClose
            setIsOpen(false);
        }

    }, [ currentStep, steps.length ]);

    return null;
}

export default TourUpdater;