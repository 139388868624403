import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { Component } from 'react';
import { Button, Card, CardBody, Col, Collapse, Label, Row } from "reactstrap";
import { fail, success } from "@spordle/toasts";
import { stringBuilder } from '@spordle/helpers';
import queryString from 'query-string';

//
import withContexts from '../../../../../helpers/withContexts'
import { OrganizationContext } from '../../../../../contexts/OrganizationContext'
import { MerchantAccountContext } from '../../../../../contexts/MerchantAccountContext'
import { I18nContext } from '../../../../../contexts/I18nContext';
import APIGatewayAccount from '../../../../../api/APIGatewayAccount';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { AuthContext } from '../../../../../contexts/contexts';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';

class OrganizationMerchantAccounts extends Component{

    constructor(props){
        super(props)

        this.state = {
            modalAdd: false,
            helpCollapse: false,

            isLoading: false,
        }

        this.toggleModalAdd = this.toggleModalAdd.bind(this)
        this.toggleHelpCollapse = this.toggleHelpCollapse.bind(this)
    }

    setLoading = (isLoading) => {
        this.setState(() => ({
            isLoading: isLoading,
        }))
    }

    toggleModalAdd = () => {
        this.setState((prevState) => ({ modalAdd: !prevState.modalAdd }));
    }

    toggleHelpCollapse = () => {
        this.setState((prevState) => ({ helpCollapse: !prevState.helpCollapse }));
    }

    selectGateway = (e) => {
        const target = e.target;
        e.preventDefault();
        e.stopPropagation();
        target.disabled = true;

        const ref_id = this.props.OrganizationContext.organisation_id;
        const product = "HCR"; // HCR is Spordle's umbrella name

        APIGatewayAccount.post("getAccessToken", {
            "product": product,
            "ref_id": ref_id,
            "first_name": this.props.AuthContext.account.name,
            "last_name": this.props.AuthContext.account.family_name,
            "email": this.props.AuthContext.account.email,
            "logo": this.props.OrganizationContext.logo?.full_path,
            "referrer": window.location.href,
        })
            .then((response) => {
                //handle success
                const data = response.data;

                if(data.status){
                    const link = process.env.REACT_APP_ENVIRONMENT === 'prod' ? "https://gateway.spordle.com" : "https://stage.d3sz9k7gr0d2ai.amplifyapp.com";

                    window.location.replace(
                        queryString.stringifyUrl({
                            url: `${link}/accountCreation`,
                            query: {
                                lang: this.props.I18nContext.getGenericLocale(),
                                product: product,
                                ref_id: ref_id,
                                tok: data.token,
                            },
                        }),
                    );
                    target.disabled = false;
                }else{
                    // Managed error
                    console.error("oops");
                    target.disabled = false;
                }
            })
            .catch((response) => {
                //handle error
                target.disabled = false;
                if(!AxiosIsCancelled(response.message)){
                    console.error(response);
                }
            });
    }

    render(){
        return (
            <Card id="profileComponentMerchantAccounts" className="card-shadow">
                <OverlayLoader isLoading={this.state.isLoading}>
                    <CardBody>
                        <div className="h4 mb-2 font-bold pb-1 border-bottom card-title">
                            <Translate id='settings.merchantAccounts.title' />
                            <button onClick={this.toggleHelpCollapse} className="reset-btn ml-2 text-primary" type='button'><i className="mdi mdi-help-circle-outline " /></button>
                        </div>
                        <Collapse isOpen={this.state.helpCollapse}>
                            <div className="pb-1 border-bottom"> {/* padding-bottom to stop the collapse+margin glich  */}
                                <p><Translate id='settings.merchantAccounts.help.1' /></p>
                                <p><Translate id='settings.merchantAccounts.help.2' /></p>
                            </div>
                        </Collapse>
                        <SpordleTableProvider
                            id='SettingsMerchantAccountsTable'
                            searchKeys={[
                                'name',
                                'provider',
                            ]}
                            dataIndex="merchant_account_id"
                            viewMode='GRID'
                            // emptyLayout={<EmptyLayout hideArrow hideMsg />}
                            emptyLayout={<></>}
                            gridConfig={{
                                col: { xs: 12 },
                                gridRender: (merchantAccount) => {
                                    return (
                                        <div className='border-bottom p-3'>
                                            <Row className='align-items-center'>
                                                <Col xs='6' lg="6">
                                                    <div className="text-dark font-medium">{merchantAccount.name}</div>
                                                    <div className='text-muted small'>#{merchantAccount.credit_account_id }</div>
                                                </Col>
                                                <Col xs='4' lg="5">
                                                    <div className="text-dark font-medium"><Translate id={`settings.merchantAccounts.provider.${merchantAccount.provider.toUpperCase()}`} /></div>
                                                    <div className='text-muted small'><Translate id='settings.merchantAccounts.activatedOn' /> <DateFormat value={merchantAccount.created_at} /></div>
                                                </Col>
                                                <Col xs="2" lg="1">
                                                    <Label for={`status-${merchantAccount.merchant_account_id}`} className='switch'>
                                                        <input
                                                            id={`status-${merchantAccount.merchant_account_id}`} type='checkbox'
                                                            defaultChecked={merchantAccount.active == 1}
                                                            onChange={(e) => {
                                                                this.setLoading(true);
                                                                this.props.MerchantAccountContext.updateMerchantAccountPartial(merchantAccount.merchant_account_id, {
                                                                    active: e.target.checked >>> 0,
                                                                })
                                                                    .then(() => {
                                                                        success();
                                                                        this.setLoading(false);
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                            this.setLoading(false);
                                                                        }
                                                                    })
                                                            }}
                                                        />
                                                        <span className='switch-toggler' />
                                                    </Label>
                                                </Col>
                                                {/* <Col xs='2' lg="1" className='text-right'>
                                                            <UncontrolledDropdown tag='span'>
                                                                <DropdownToggle tag='button' className='btn btn-link'>
                                                                    <i className='mdi mdi-dots-vertical font-20'></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu right>
                                                                    {/* if delete is the only action, we could put a trash icon instead */}
                                                {/*<DropdownItem><Translate id='misc.delete'/></DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </Col> */}
                                            </Row>
                                        </div>
                                    )
                                },
                            }}
                            loadData={(from) => {
                                switch (from){
                                    case 'CDM':
                                        return this.props.MerchantAccountContext.getMerchantAccountsByOrganization(this.props.OrganizationContext.organisation_id, true)
                                            .then((merchantAccounts) => {
                                                if(merchantAccounts?.length > 0){
                                                    this.props.setShowPaymentSetting(true);
                                                }
                                                return merchantAccounts
                                            })
                                    default:
                                        break;
                                }
                            }}
                        >
                            {(spordleTable) => {
                                return (
                                    <>
                                        <div className={stringBuilder({ 'border-bottom mb-4': (spordleTable.getData() || [])?.length > 0 })}>
                                            <SpordleTableView />
                                        </div>
                                        <Collapse isOpen className="mt-4">
                                            <div className="mb-3 font-bold h5"><Translate id='settings.merchantAccounts.offers' /></div>
                                            <Row form>
                                                {this.props.OrganizationContext.settings.organisation_merchant_account_provider.value.includes('PAYSAFE') &&
                                                    <Col md="6" className="mb-3 mb-md-0">
                                                        <Card className="card-shadow text-center h-100 mb-0">
                                                            <CardBody>
                                                                {/* <div className="position-absolute right-0 top-0 p-3">
                                                                        <CheckboxStar
                                                                            checked={showPaysafe}
                                                                            id={'star-paysafe'}
                                                                        />
                                                                    </div> */}
                                                                <div className="mb-2">
                                                                    <div className="d-flex align-items-center justify-content-center"><span className="h3 font-bold mb-0 mr-1">Paysafe</span></div>
                                                                    {/* {false ? // if has logo
                                                                        <img src="https://via.placeholder.com/200x50" alt="Paysafe" width="200" />
                                                                        :
                                                                        <div className="d-flex align-items-center justify-content-center"><span className="h3 font-bold mb-0 mr-1">Paysafe</span></div>
                                                                    } */}
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div><a className="font-medium" href="mailto:onboardingHC@paysafe.com">onboardingHC@paysafe.com</a></div>
                                                                    <div><a className="font-medium" href="mailto:+18887098753">1-888-709-8753</a></div>
                                                                </div>
                                                                <Button onClick={this.selectGateway} className="w-md-75 mx-auto" color="primary" block><Translate id='settings.merchantAccounts.choose' /></Button>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                }
                                                {this.props.OrganizationContext.settings.organisation_merchant_account_provider.value.includes('QUICK_ENROLLMENT') &&
                                                    <Col md="6">
                                                        <Card className="card-shadow text-center h-100 mb-0">
                                                            <CardBody className="d-flex flex-column">
                                                                {/* <div className="position-absolute right-0 top-0 p-3">
                                                                        <CheckboxStar
                                                                            checked={showSportPay}
                                                                            id={'star-sportpay'}
                                                                        />
                                                                    </div> */}
                                                                <div className="mb-2">
                                                                    {/* {false ? // if has logo
                                                                        <img src="https://via.placeholder.com/200x50" alt="Paysafe" width="200" />
                                                                        :
                                                                        <div className="d-flex align-items-center justify-content-center"><span className="h3 font-bold mb-0 mr-1">SportsPay</span></div>
                                                                    } */}
                                                                    <div className="d-flex align-items-center justify-content-center"><span className="h3 font-bold mb-0 mr-1">SportsPay</span></div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div><a className="font-medium" href="mailto:support@sportspay.ca">support@sportspay.ca</a></div>
                                                                    <div><a className="font-medium" href="mailto:+18778800321">1-877-880-0321</a></div>
                                                                    <div />
                                                                </div>
                                                                <Button className="mx-auto w-md-75 mt-auto" tag="a" target="_blank" href="https://sportspay.com/hcr" rel="noopener noreferrer" color="primary" block><Translate id='settings.merchantAccounts.chooseSportsPay' /></Button>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                }
                                            </Row>
                                        </Collapse>
                                    </>
                                )
                            }}
                        </SpordleTableProvider>
                    </CardBody>
                </OverlayLoader>
            </Card>
        );
    }
}

export default withContexts(AuthContext, OrganizationContext, MerchantAccountContext, I18nContext)(OrganizationMerchantAccounts);