import React from 'react';
import PropTypes from 'prop-types';
import {
    Collapse,
    CustomInput
} from "reactstrap";
import { DisplayI18n } from '../../helpers/i18nHelper';
import { stringBuilder } from "@spordle/helpers";
import { withRouter } from 'react-router-dom';
import Translate from '@spordle/intl-elements';
import styles from './OrganizationTree.module.scss';

class OrganizationTree extends React.Component{
    constructor(props){
        super(props);

        this.state = {};
        this.createInitState(props.treeData, this.state, this.props.selectedOrgs);
        // because we want the top level to always be open by default
        if(props.treeData[0])
            this.state[props.treeData[0].organisation_id] = true;
    }

    /**
     * This function recursively open the organizations if the organization itself or any children organizations are selected
     * @param {Array} arr
     * @param {Record.<string, boolean>} [builtData={}]
     */
    createInitState = (arr, builtData = {}, orgs = this.props.selectedOrgs) => {
        return arr.map((currentOrg) => {
            const isSelected = orgs.includes(currentOrg.organisation_id);

            if(Array.isArray(currentOrg.sub_organisations)){
                builtData[currentOrg.organisation_id] = this.createInitState(currentOrg.sub_organisations, builtData, orgs).includes(true) || isSelected;
            }else{
                builtData[currentOrg.organisation_id] = isSelected;
            }

            return builtData[currentOrg.organisation_id];
        });
    };

    toggleTree = (id, e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState((state) => ({ [id]: !state[id] }));
    }

    componentDidUpdate(prevProps){
        if(this.props.showOrg != prevProps.showOrg && this.props.showOrg){
            this.setState(() => {
                const newState = {};
                this.createInitState(this.props.treeData, newState, this.props.showOrg ? [ this.props.showOrg ] : []);
                return newState;
            });
        }
    }

    hasSelectedOrgWithSubOrgs = (orgList) => {
        const hasSelectedOrgs = (orgs) => {
            return orgs.some((o) => {
                let hasSelected = false
                if(this.props.selectedOrgs.includes(o.organisation_id)){
                    hasSelected = true
                }
                if(!hasSelected && (o.sub_organisations && o.sub_organisations.length > 0)){
                    hasSelected = hasSelectedOrgs(o.sub_organisations)
                }
                return hasSelected;
            })
        }
        return !!hasSelectedOrgs(orgList)// orgList.some((o) => this.props.selectedOrgs.includes(o.organisation_id))
    }

    everySelectedOrgWithSubOrgs = (orgList) => {
        const everySelectedOrgs = (orgs) => {
            return orgs.every((o) => o.isSelectAll || this.props.selectedOrgs.includes(o.organisation_id) && ((o.sub_organisations && o.sub_organisations.length > 0) ? everySelectedOrgs(o.sub_organisations) : true))
        }
        return !!everySelectedOrgs(orgList)// orgList.every((o) => this.props.selectedOrgs.includes(o.organisation_id))
    }

    countSelectedOrgs = (orgList) => {
        let selected = 0
        const countSelected = (orgs) => {
            orgs.forEach((o) => {
                if(this.props.selectedOrgs.includes(o.organisation_id)){
                    selected++
                }
                if(o.sub_organisations && o.sub_organisations.length > 0){
                    countSelected(o.sub_organisations)
                }
            })
        }
        countSelected(orgList)
        return selected
    }

    generateTree = (orgList) => {
        return (
            <ul className="treeView-list">
                {orgList.sort((a, b) => (parseInt(a.display_order) - parseInt(b.display_order))).map((org) => {
                    const hasSubOrgs = Array.isArray(org.sub_organisations) && org.sub_organisations.length !== 0;
                    const isSelected = org.isSelectAll ? undefined : this.props.selectedOrgs.includes(org.organisation_id);
                    const hasAllSelected = org.isSelectAll ? this.everySelectedOrgWithSubOrgs(orgList) : undefined;
                    return (
                        <li className={stringBuilder('treeView-list-item text-nowrap', { [`border-bottom pl-2 ml-n2 pt-0 ${styles.selectAllSubOrgs}`]: org.isSelectAll })} key={org.organisation_id} id={`orgTree-view-org-${org.organisation_id}`}>
                            {!org.isSelectAll &&
                                <button
                                    id={`orgTree-view-org-subOrgs-${org.organisation_id}`}
                                    type='button'
                                    className={stringBuilder('treeView-arrow', { 'rotate': this.state[org.organisation_id] && hasSubOrgs, 'text-light': !hasSubOrgs })}
                                    onClick={hasSubOrgs ? (e) => {
                                        this.toggleTree(org.organisation_id, e);
                                    } : undefined}
                                    disabled={!hasSubOrgs}
                                >
                                    <i className='ti-angle-right align-middle mr-1 font-14' />
                                </button>
                            }
                            <TextTag mark={this.props.showOrg === org.organisation_id}>
                                {this.props.multi ?
                                    <CustomInput
                                        id={`orgTree-view-org-label-${org.organisation_id}`}
                                        innerRef={org.isSelectAll ? (r) => {
                                            if(r)
                                                r.indeterminate = org.isSelectAll && !hasAllSelected && this.hasSelectedOrgWithSubOrgs(orgList)
                                        } : undefined}
                                        onClick={org.isSelectAll ? () => {
                                            this.props.clickOnAll(orgList.filter((o) => !o.isSelectAll), !hasAllSelected);
                                        } : this.props.organizationClick ?
                                            () => { this.props.organizationClick(org) }
                                            :
                                            undefined
                                        }
                                        className={stringBuilder('ml-1 d-inline-flex align-items-center text-wrap', styles.input, { 'font-12': org.isSelectAll })}
                                        type="checkbox"
                                        checked={hasAllSelected ?? isSelected}
                                        onChange={function(){}}// Prevents console warning
                                        label={org.isSelectAll ? <><Translate id='organizationTree.organizationSearch.modal.org.tree.selectAll' values={{ selectAll: !hasAllSelected }} /> ({this.countSelectedOrgs(orgList)})</> : <DisplayI18n field='name' defaultValue={org.organisation_name} i18n={org.i18n} />}
                                    />
                                    :
                                    <span role='button' onClick={this.props.organizationClick ? () => this.props.organizationClick(org) : undefined} className='clickable' id={`orgTree-view-org-label-${org.organisation_id}`}>
                                        <span className={stringBuilder('font-16', { 'text-primary': isSelected })}>
                                            <DisplayI18n field='name' defaultValue={org.organisation_name} i18n={org.i18n} />
                                            {org.abbreviation && <span className={stringBuilder('ml-1', { 'text-muted': !isSelected })}>({org.abbreviation})</span>}
                                        </span>
                                    </span>
                                }
                            </TextTag>
                            {hasSubOrgs &&
                                <Collapse isOpen={this.state[org.organisation_id]} mountOnEnter unmountOnExit>
                                    {this.generateTree(this.props.multi ? [ { isSelectAll: true, organisation_id: `select-all-${org.organisation_id}` }, ...org.sub_organisations ] : org.sub_organisations)}
                                </Collapse>
                            }
                        </li>
                    );
                })}
            </ul>
        )
    }

    render(){
        return (
            <div className={stringBuilder('treeView', { 'treeView-checkboxes': this.props.multi })}>
                {this.generateTree(this.props.treeData)}
            </div>
        )
    }
}

const TextTag = ({ children, mark }) => (mark ? <mark>{children}</mark> : children)

const orgShape = {
    organisation_id: PropTypes.string.isRequired,
    organisation_name: PropTypes.string.isRequired,
    // sub_organisations: PropTypes.arrayOf(PropTypes.shape(orgShape)),
}

OrganizationTree.propTypes = {
    multi: PropTypes.bool.isRequired,
    treeData: PropTypes.arrayOf(PropTypes.shape(orgShape)).isRequired,
    organizationClick: PropTypes.func,
    clickOnAll: PropTypes.func.isRequired,
    selectedOrgs: PropTypes.arrayOf(PropTypes.string),
    showOrg: PropTypes.string,
}

export default withRouter(OrganizationTree);