import { Form, Formik, useFormikContext } from "formik";
import { useContext, useState } from "react";
import { array, bool, mixed, object, string } from "yup";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../helpers/i18nHelper";
import moment from "moment";
import { Alert, Button, Card, CardBody, Col, Collapse, Container, Fade, Label, Row } from "reactstrap";
import { FormikDateTime, FormikInputText, FormikSwitch } from "@spordle/formik-elements";
import Required from "../../../components/formik/Required";
import { IdentityRolesContext } from "../../../contexts/IdentityRolesContext";
import OrganizationSearch from "../../../components/organization/OrganizationSearch";
import Translate from "@spordle/intl-elements";
import CardSectionTitle from "../../../components/CardSectionTitle";
import { Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import NoticesList from "../../../components/noticesList/noticesList/NoticesList";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import { NewsContext } from "../../../contexts/NewsContext";
import BannerSelector from "./bannerSelector/BannerSelector";
import FormikTiny from "../../../components/formik/FormikTiny";
import OverlayLoader from "../../../components/loading/OverlayLoader";
import { NEWS_TYPE_DATA } from "../../../components/noticesList/newsHelpers";
import CrossFade from "../../../components/crossFade/CrossFade";
import Notice from "../../../components/noticesList/noticesList/notice/Notice";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import CustomAnimatedIcon from "../../../components/customAnimatedIcon/CustomAnimatedIcon";
import { stringBuilder } from "@spordle/helpers";
import styles from "./NewsForm.module.scss";

export const MODE_CREATE = 1;
export const MODE_EDIT = 2;

export const STATE_CREATE = 1;
export const STATE_CONFIRM = 2;
export const STATE_SUCCESS = 3;

/**
 * @param {object} props
 * @param {1|2} props.mode
 * @param {object} props.initialValues
 */
const NewsForm = ({ mode = MODE_CREATE, initialValues = {} }) => {
    const { getInitialValues, getValidationSchema, getAPIValues } = useContext(I18nHelperContext);
    const { state } = useContext(OrganizationContext);
    const { createNews, updateNews } = useContext(NewsContext);
    const { isGod, identity } = useContext(IdentityRolesContext);
    const [ previewIsOpen, { open, close } ] = useDisclosure();
    const [ creationState, setCreationState ] = useState(STATE_CREATE);

    /**
     * @param {"CREATE"|"CONFIRM"|"SUCCESS"} state
     */
    const switchCreationState = (state) => {
        setCreationState(state);
    }

    const isConfirming = creationState === STATE_CONFIRM;
    const isSuccess = creationState === STATE_SUCCESS;
    const isCreating = creationState === STATE_CREATE;

    return (
        <Formik
            enableReinitialize={mode === MODE_EDIT}
            initialStatus={{
                isVisible: false,
                msg: null,
            }}
            initialValues={{
                organizations: initialValues?.organizations || [],
                organisation: initialValues?.organisation || { ...state },
                published_by: initialValues?.identity || identity,
                type: initialValues?.type || "",
                ...getInitialValues(initialValues),
                published_at: initialValues?.published_at ? moment(initialValues.published_at) : moment(),
                published_until: initialValues?.published_until ? moment(initialValues.published_until) : "",
                active: !initialValues?.active || initialValues.active == 1,
            }}
            validationSchema={object().shape({
                published_at: mixed().isDate(<Translate id="form.validation.date.format" />).required(<Translate id="news.form.field.startDate.required" />),
                active: bool(),
                type: string().required(<Translate id="news.form.field.banner.required" />),
                organizations: array().of(string()),
                organisation: object().shape({
                    organisation_id: string(),
                }),
                published_until: mixed().isDate(<Translate id="form.validation.date.format" />).test({
                    name: "isAfterStart",
                    message: <Translate id="news.form.field.endDate.isAfterStart" />,
                    test: function(date){
                        if(!moment.isMoment(date) || !moment.isMoment(this.parent.published_at)){
                            return true;
                        }

                        return date.isAfter(this.parent.published_at);

                    },
                }),
                ...getValidationSchema({
                    title: string().required(<Translate id="form.validation.title.required" />),
                    description: string().required(<Translate id="form.validation.description.required" />),
                }),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                if(isCreating){
                    switchCreationState(STATE_CONFIRM);
                    setSubmitting(false);
                }else if(isConfirming){
                    const apiValues = {
                        type: Object.keys(NEWS_TYPE_DATA).includes(values.type) ? values.type : "INFORMATION",
                        published_at: moment(values.published_at).format('YYYY-MM-DD HH:mm:ss'),
                        active: values.active ? "1" : "0",
                        organisation_id: values.organisation?.organisation_id,
                        news_for_organisation: values.organizations.join(','),
                        ...getAPIValues(values),
                    };

                    if(moment.isMoment(values.published_until)){
                        apiValues.published_until = moment(values.published_until).format('YYYY-MM-DD HH:mm:ss');
                    }

                    const getModeFn = (theMode) => {
                        if(theMode === MODE_CREATE){
                            return createNews
                        }

                        return (val) => updateNews(initialValues?.news_id, val)
                    }

                    return getModeFn(mode)(apiValues)
                        .then(() => {
                            switchCreationState(STATE_SUCCESS);
                        })
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                console.error(e);
                                setStatus({
                                    isVisible: true,
                                    msg: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />,
                                })
                            }
                        })
                }
            }}
        >
            {(formik) => (
                <Form>
                    <CrossFade isVisible={isConfirming || isSuccess}>
                        <Container>
                            <Card className="card-shadow h-100 mb-0 rounded-lg">
                                <CardBody>
                                    <OverlayLoader isLoading={formik.isSubmitting}>
                                        <Row>
                                            {isConfirming &&
                                                <Col xs="12" className="d-block d-lg-none">
                                                    <CardSectionTitle
                                                        title={mode === MODE_CREATE ? "news.form.title.confirm" : "news.form.title.confirm.edit"}
                                                    />
                                                </Col>
                                            }
                                            <Col lg="6" className="d-flex flex-column order-1 order-lg-0">
                                                <Fade className={stringBuilder(styles.SuccessContainer, "h-lg-100 top-0 left-0 right-0 bottom-0", { "z-index-1": isSuccess })} in={isSuccess}>
                                                    <div className="d-flex flex-column text-center flex-center h-100">
                                                        <div>
                                                            <CustomAnimatedIcon key={isSuccess} size={60} className="text-success" icon="checkmark" withCircle />
                                                            <div className="h3 mt-3 font-medium"><Translate id="misc.success" /></div>
                                                            <p><Translate id="news.form.create.success.msg" /></p>
                                                            <Link to="/news" className="text-primary mb-3 d-block">
                                                                <Translate id="news.form.btn.back" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </Fade>
                                                <Fade className={stringBuilder("h-lg-100", { "z-index-1": isConfirming })} in={isConfirming}>
                                                    <Collapse isOpen={isConfirming} className="h-lg-100 d-lg-block">
                                                        <div className="d-flex h-100 flex-column">
                                                            <div>
                                                                <CardSectionTitle
                                                                    className="d-lg-block d-none"
                                                                    title={mode === MODE_CREATE ? "news.form.title.confirm" : "news.form.title.confirm.edit"}
                                                                />
                                                                <p>
                                                                    <Translate id="news.form.create.confirm.msg" values={{ b: (chunks) => <b>{chunks}</b>, count: formik.values.organizations.length }} />
                                                                </p>
                                                                <p>
                                                                    {formik.values.active && formik.values.published_at ?
                                                                        <>
                                                                            <Translate id="news.form.create.confirm.date.from" values={{ b: (chunks) => <b>{chunks}</b>, time: moment(formik.values.published_at).format('HH:mm'), date: moment(formik.values.published_at).format('YYYY-MM-DD') }} />
                                                                            {formik.values.published_until ? <span className="ml-1"><Translate id="news.form.create.confirm.date.until" values={{ b: (chunks) => <b>{chunks}</b>, time: moment(formik.values.published_until).format('HH:mm'), date: moment(formik.values.published_until).format('YYYY-MM-DD') }} /></span> : "."}
                                                                        </>
                                                                        :
                                                                        <Translate id="news.form.create.confirm.notActive" />
                                                                    }
                                                                </p>
                                                                {formik.values.active &&
                                                                    <p>
                                                                        <Translate id="news.form.create.confirm.visible" />
                                                                    </p>
                                                                }
                                                                <RenderError />
                                                            </div>
                                                            <div className="mt-auto pt-3 border-top d-flex justify-content-between">
                                                                <Button disabled={formik.isSubmitting} type="button" color="primary" outline onClick={() => switchCreationState(STATE_CREATE)}><Translate id="misc.edit" /></Button>
                                                                <Button disabled={formik.isSubmitting} type="submit" color="primary"><Translate id="misc.confirm" /></Button>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </Fade>
                                            </Col>
                                            <Col xs={12} lg="6" className="h-100">
                                                <PerfectScrollbar className="p-3 m-n3 d-none d-lg-block" options={{ suppressScrollX: true }}>
                                                    <div style={{ maxHeight: "50vh", height: "100%" }}>
                                                        <Notice
                                                            articleClassName="min-h-0"
                                                            className="py-0"
                                                            tag="div"
                                                            notice={{ ...formik.values, news_id: "confirm-new-news" }}
                                                            noticeNb={1}
                                                        />
                                                    </div>
                                                </PerfectScrollbar>
                                                <div className="d-block d-lg-none border-bottom pb-3 mb-3">
                                                    <Notice
                                                        articleClassName="min-h-0"
                                                        className="py-0 mb-0"
                                                        tag="div"
                                                        notice={{ ...formik.values, news_id: "confirm-new-news" }}
                                                        noticeNb={1}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </OverlayLoader>
                                </CardBody>
                            </Card>
                        </Container>
                    </CrossFade>
                    <CrossFade isVisible={isCreating}>
                        <Link disabled={formik.isSubmitting} to="/news" className="text-primary mb-3 d-block">
                            <i className="mdi mdi-arrow-left mr-1" /><Translate id="news.form.btn.back" />
                        </Link>
                        <Card className="card-shadow">
                            <CardBody>
                                <OverlayLoader isLoading={formik.isSubmitting}>
                                    <CardSectionTitle
                                        className="d-flex align-items-center"
                                        title={mode === MODE_CREATE ? "news.form.title.create" : "news.form.title.edit"}
                                    >
                                        <div className="ml-auto">
                                            <Tooltip label={<Translate id="news.form.btn.preview.tip" />}>
                                                <button
                                                    onClick={open}
                                                    type="button"
                                                    className="font-22 reset-btn mdi mdi-eye text-secondary"
                                                />
                                            </Tooltip>
                                        </div>
                                    </CardSectionTitle>
                                    <Row form>
                                        <Col className="mb-3" sm="12">
                                            <Label for="active" className="d-flex align-items-center">
                                                <FormikSwitch name="active" id="active" /><span className="ml-2"><Translate id="news.form.field.active" /></span>
                                            </Label>
                                            <small className="text-muted"><i className="mdi mdi-information-outline text-primary mr-1" /><Translate id="news.form.field.active.helper" /></small>
                                        </Col>
                                        <Col className="mb-3" sm="6">
                                            <Label for="published_at" className="text-muted"><Translate id="news.form.field.startDate" /> <Required /></Label>
                                            <FormikDateTime
                                                name="published_at"
                                                id="published_at"
                                                placeholder="form.fields.date.helper"
                                            />
                                        </Col>
                                        <Col className="mb-3" sm="6">
                                            <Label for="published_until" className="text-muted">
                                                <Translate id="news.form.field.endDate" />
                                            </Label>
                                            <FormikDateTime
                                                name="published_until"
                                                id="published_until"
                                                placeholder="form.fields.date.helper"
                                                isValidDate={(current) => current.isAfter(moment(formik.values.published_at), 'days')}
                                            />
                                        </Col>
                                        {isGod() &&
                                            <Col sm={6} className="mb-3">
                                                <Label for="from" className="text-purple"><Translate id="news.form.field.from" /></Label>
                                                <OrganizationSearch
                                                    id="organisation_from"
                                                    name="organisation.organisation_id"
                                                    withFormik
                                                    withTree
                                                    isCompact
                                                    onOptionSelected={([ value ], select) => {
                                                        const selectOption = value ? select.getSpordleTable().getData().find((o) => o.value === value) : {};
                                                        const newVal = {
                                                            organisation_name: selectOption?.label,
                                                            organisation_id: selectOption?.value,
                                                            ...selectOption,
                                                        }

                                                        formik.setFieldValue('organisation', newVal);
                                                        return true;
                                                    }}
                                                />
                                            </Col>
                                        }
                                        <Col className="mb-3" sm={6}>
                                            <Label for="organizations" className="text-muted"><Translate id="news.form.field.to" /></Label>
                                            <OrganizationSearch
                                                id="organisation_to"
                                                name="organizations"
                                                clearable
                                                placeholder="news.form.field.to.placeholder"
                                                multi
                                                withFormik
                                                withTree
                                                isCompact
                                            />
                                            <div className="small text-muted">
                                                <i className="mdi mdi-information-outline mr-1 text-primary" /><Translate id="news.form.field.to.helper" />
                                            </div>
                                        </Col>
                                        <Col xs="12" className="font-medium h5 my-3">
                                            <Translate id="news.form.title.content" />
                                        </Col>
                                        <Col xs="12" sm="12" md="6" xl="4" className="mb-3">
                                            <BannerSelector />
                                        </Col>
                                        <Col xs="12" />
                                        <RenderI18nForm field="description">
                                            {({ fieldName, fieldLabel, lang }) => {
                                                const titleField = `i18n.${lang}.title`;

                                                return (
                                                    <Col xs={12} xl={6} key={fieldName}>
                                                        <Row form>
                                                            <Col className="mb-3" sm="12">
                                                                <Label className="text-muted">
                                                                    <Translate id="form.fields.title.lang" values={{ lang: lang.toUpperCase() }} /> <Required />
                                                                </Label>
                                                                <FormikInputText id={titleField} name={titleField} />
                                                            </Col>
                                                            <Col className="mb-3" sm="12">
                                                                <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                                <FormikTiny id={fieldName} name={fieldName} />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )
                                            }}
                                        </RenderI18nForm>
                                    </Row>
                                    {/* <Alert color="primary" className="mt-3">
                                        <i className="mdi mdi-information-outline text-primary mr-1" />
                                        <Translate id="news.form.helper" values={{ a: (chunks) => <span>{chunks}</span> }} />
                                    </Alert> */}
                                    <RenderError />
                                    <div className="d-flex justify-content-end">
                                        <Button disabled={formik.isSubmitting} onClick={open} type="button" color="primary" className="mr-2" outline><Translate id="news.form.btn.preview" /></Button>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            type="submit"
                                            color="primary"
                                        >
                                            <Translate id="misc.submit" />
                                        </Button>
                                    </div>
                                </OverlayLoader>
                            </CardBody>
                        </Card>
                    </CrossFade>
                    <NoticesList canManageNews={false} isOpen={previewIsOpen} toggle={close} notices={[ { ...formik.values, news_id: "temp-preview" } ]} />
                </Form>
            )}
        </Formik>
    );
}

const RenderError = () => {
    const formik = useFormikContext();

    return (
        <Collapse isOpen={formik.status.isVisible}>
            <Alert color="danger" toggle={() => formik.setStatus({ ...formik.status, isVisible: false })}>
                {formik.status.msg}
            </Alert>
        </Collapse>
    )
}

export default NewsForm;