import Invoice from '../../components/invoice/Invoice';
import Receipt from '../../components/invoice/Receipt';
import MemberProfileTransferAdd from '../../views/members/profile/history/component/transfer/component/MemberProfileTransferAdd';
import MemberProfileTransferCTIAdd from '../../views/members/profile/history/component/transfer/component/MemberProfileTransferCTIAdd';
import MemberProfileOverview from '../../views/members/profile/overview/MemberProfileOverview';
import MemberProfileRegistration from '../../views/members/profile/registration/MemberProfileRegistration';
import MemberTeams from '../../views/members/profile/teams/MemberTeams';
import MemberProfileTransactions from '../../views/members/profile/transactions/MemberProfileTransactions';
// import MemberProfileTransactions from '../../views/members/profile/transactions/MemberProfileTransactions';

/**
 * @typedef {object} TabRoutes
 * @property {React.ReactNode} [name]
 * @property {boolean} [redirect]
 */

/** @type {Array.<import('react-router-dom').RouteProps & TabRoutes>} */
const memberProfileTabsRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        path: '/overview',
        name: 'members.profile.memberProfile.tabs.overview',
        component: MemberProfileOverview,
    },
    {
        path: '/teams',
        name: 'members.profile.memberProfile.tabs.team',
        component: MemberTeams,
        permissions: { code: 'teams', permissionCode: 'team_members' },
    },
    {
        path: '/registrations',
        name: 'members.profile.memberProfile.tabs.registration',
        component: MemberProfileRegistration,
        permissions: { code: 'members', permissionCode: 'members_registrations' }, //permissions && (subPermissionsRoutes1 || subPermissionsRoutes2 ...)
    },
    {
        path: '/transactions',
        name: 'members.profile.memberProfile.tabs.transactions',
        component: MemberProfileTransactions,
        permissions: { code: 'members', permissionCode: [ 'member_transactions', 'member_credit' ] },
    },
    {
        path: '/usa',
        name: 'members.profile.memberProfile.tabs.usa',
        component: MemberProfileTransferAdd,
        tempTab: true,
        permissions: { code: 'members', permissionCode: 'members_request_transfer_usa' },
    },
    {
        path: '/international',
        name: 'members.profile.memberProfile.tabs.international',
        component: MemberProfileTransferAdd,
        tempTab: true,
        permissions: { code: 'members', permissionCode: 'members_request_transfer_international' },
    },
    {
        path: '/cti',
        name: 'members.profile.memberProfile.tabs.cti',
        component: MemberProfileTransferCTIAdd,
        tempTab: true,
        permissions: { code: 'members', permissionCode: 'members_request_transfer_international' },
    },
    {
        path: '/invoice',
        name: 'members.profile.memberProfile.tabs.invoice',
        component: Invoice,
        tempTab: true,
    },
    {
        path: '/receipt',
        name: 'members.profile.memberProfile.tabs.receipt',
        component: Receipt,
        tempTab: true,
    },
    {
        redirect: true,
        from: '/members/profile/:memberId',
        to: '/overview',
    },
];
export default memberProfileTabsRoutes;