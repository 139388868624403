import Translate from '@spordle/intl-elements';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import IsGod from '../../../../../../components/permissions/IsGod';
import CrossFade from '../../../../../../components/crossFade/CrossFade';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';

const DocumentSettingsView = ({ isDocumentsLoading, insuranceDocumentTypes, memberRegistrationDocumentTypes, clinicRegistrationDocumentTypes, travelPermitDocumentTypes }) => {

    return (
        <>
            <Row form className="mb-n3">
                <CanDoAction tag={Col} sm="6" className='mb-3' action='READ' componentCode='settings' componentPermissionCode='member_registration_document_type_configuration'>
                    <Card className="card-shadow h-100 mb-0 p-1">
                        <CardHeader className="bg-white">
                            <div className="h5 font-bold mb-1"><Translate id="settings.documentSettings.member.registration.title" /></div>
                            <div className="small font-medium mb-0"><Translate id="settings.documentSettings.member.registration.desc" /></div>
                            <IsGod><div className="small mt-1 font-medium text-purple">setting: attachment_for_new_member_in_registration</div></IsGod>
                        </CardHeader>
                        <CardBody>
                            <CrossFade isVisible={!isDocumentsLoading}>
                                {(memberRegistrationDocumentTypes && memberRegistrationDocumentTypes.length > 0) ?
                                    <ul className="pl-4">
                                        {memberRegistrationDocumentTypes.map((documentType) => (
                                            <li key={documentType.document_type_id}>
                                                <DisplayI18n field='name' defaultValue={documentType.name} i18n={documentType.i18n} />
                                            </li>
                                        ))}
                                    </ul>
                                    :
                                    !isDocumentsLoading && <Translate id="settings.documentSettings.documents.notDefined" />
                                }
                            </CrossFade>
                        </CardBody>
                    </Card>
                </CanDoAction>

                <CanDoAction tag={Col} sm="6" className='mb-3' action='READ' componentCode='settings' componentPermissionCode='clinic_document_type_configuration'>
                    <Card className="card-shadow h-100 mb-0 p-1">
                        <CardHeader className="bg-white">
                            <div className="h5 font-bold mb-1"><Translate id="settings.documentSettings.clinic.registration.title" /></div>
                            <div className="small font-medium mb-0"><Translate id="settings.documentSettings.clinic.registration.desc" /></div>
                            <IsGod><div className="small mt-1 font-medium text-purple">setting: attachment_for_new_member_in_clinic_registration</div></IsGod>
                        </CardHeader>
                        <CardBody>
                            <CrossFade isVisible={!isDocumentsLoading}>
                                {(clinicRegistrationDocumentTypes && clinicRegistrationDocumentTypes.length > 0) ?
                                    <ul className="pl-4">
                                        {clinicRegistrationDocumentTypes.map((documentType) => (
                                            <li key={documentType.document_type_id}>
                                                <DisplayI18n field='name' defaultValue={documentType.name} i18n={documentType.i18n} />
                                            </li>
                                        ))}
                                    </ul>
                                    :
                                    !isDocumentsLoading && <Translate id="settings.documentSettings.documents.notDefined" />
                                }
                            </CrossFade>
                        </CardBody>
                    </Card>
                </CanDoAction>

                <CanDoAction tag={Col} sm="6" className='mb-3' action='READ' componentCode='settings' componentPermissionCode='insurance_document_type_configuration'>
                    <Card className="card-shadow h-100 mb-0 p-1">
                        <CardHeader className="bg-white">
                            <div className="h5 font-bold mb-1"><Translate id="settings.documentSettings.insurance.title" /></div>
                            <div className="small font-medium mb-0"><Translate id="settings.documentSettings.insurance.desc" /></div>
                            <IsGod><div className="small mt-1 font-medium text-purple">setting: document_type_list_insurance</div></IsGod>
                        </CardHeader>
                        <CardBody>
                            <CrossFade isVisible={!isDocumentsLoading}>
                                {(insuranceDocumentTypes && insuranceDocumentTypes.length > 0) ?
                                    <ul className="pl-4">
                                        {insuranceDocumentTypes.map((documentType) => (
                                            <li key={documentType.document_type_id}>
                                                <DisplayI18n field='name' defaultValue={documentType.name} i18n={documentType.i18n} />
                                            </li>
                                        ))}
                                    </ul>
                                    :
                                    !isDocumentsLoading && <Translate id="settings.documentSettings.documents.notDefined" />
                                }
                            </CrossFade>
                        </CardBody>
                    </Card>
                </CanDoAction>

                <CanDoAction tag={Col} sm="6" className='mb-3' action='READ' componentCode='teams' componentPermissionCode='team_travel_permits'>
                    <Card className="card-shadow h-100 mb-0 p-1">
                        <CardHeader className="bg-white">
                            <div className="h5 font-bold mb-1"><Translate id="settings.documentSettings.travelPermit.title" /></div>
                            <div className="small font-medium mb-0"><Translate id="settings.documentSettings.travelPermit.desc" /></div>
                            <IsGod><div className="small mt-1 font-medium text-purple">setting: attachment_for_travel_permit</div></IsGod>
                        </CardHeader>
                        <CardBody>
                            <CrossFade isVisible={!isDocumentsLoading}>
                                {(travelPermitDocumentTypes && travelPermitDocumentTypes.length > 0) ?
                                    <ul className="pl-4">
                                        {travelPermitDocumentTypes.map((documentType) => (
                                            <li key={documentType.document_type_id}>
                                                <DisplayI18n field='name' defaultValue={documentType.name} i18n={documentType.i18n} />
                                            </li>
                                        ))}
                                    </ul>
                                    :
                                    !isDocumentsLoading && <Translate id="settings.documentSettings.documents.notDefined" />
                                }
                            </CrossFade>
                        </CardBody>
                    </Card>
                </CanDoAction>

            </Row>
        </>
    );
}

export default DocumentSettingsView;