import React from "react";
import {
    Card,
    Collapse
} from 'reactstrap';
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import SpordlePanelTable from '../../../../../../components/sidePanel/SpordlePanel';
import SidePanelMemberLogs from './SidePanelMemberLogs';

// Language
import Translate from '@spordle/intl-elements';

import images from '@spordle/ui-kit';
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
const noProfileLogo = images.noprofile;

class MemberLogs extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            show: false,
        }
    }

    demoData = [
        {
            id: '1',
            date: '2020-11-12',
            time: '10:02',
            by: {
                image: null,
                firstName: 'Bob',
                lastName: 'Woods',
                memberNumber: '432545345',
            },
            // by: 'Bob Woods',
            // memberNumber: '432545345',
            section: 'Primary Information',
        },
        {
            id: '2',
            date: '2020-11-12',
            time: '10:03',
            by: {
                image: null,
                firstName: 'Eve',
                lastName: 'Asselin',
                memberNumber: '4353453',
            },
            // by: 'Eve Asselin',
            // memberNumber: '4353453',
            section: 'Allergies & Medical Notes',
        },
    ];

    toggleShow = () => {
        this.setState((prevState) => ({ show: !prevState.show }))
    }

    render(){
        return (
            <Card body className="card-shadow">
                <div className="pb-1 d-flex align-items-end" style={{ backgroundColor: 'white', opacity: 0.5, position: 'relative' }}>
                    <div className="card-title font-bold h4 mb-0"><Translate id='members.profile.overview.memberLogs.title' /></div>
                    <div className='position-absolute justify-content-center align-items-center d-flex font-bold w-100 h4 mb-0'>
                        <Translate id='misc.comingSoon' />
                    </div>
                    <button color='primary' className="ml-auto reset-btn text-link" type="button" disabled onClick={this.toggleShow}>
                        <Translate id={this.state.show ? 'members.profile.overview.memberLogs.hide' : 'members.profile.overview.memberLogs.show'} /> <i className={this.state.show ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />
                    </button>
                </div>
                {/* delete above and uncomment below to show */}
                {/* <div className="pb-1 d-flex align-items-end">
                        <div className="card-title font-bold h4 mb-0"><Translate id='members.profile.overview.memberLogs.title'/></div>
                        <button color='primary' className="ml-auto reset-btn text-link" type="button" disabled onClick={this.toggleShow}>
                            <Translate id={this.state.show ? 'members.profile.overview.memberLogs.hide' : 'members.profile.overview.memberLogs.show'}/> <i className={this.state.show ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'}/>
                        </button>
                    </div> */}

                <Collapse isOpen={this.state.show}>
                    <div className="pt-3">
                        <SpordlePanelTable
                            allowOutsideClick
                            sidePanel={(props) => <SidePanelMemberLogs {...props} />}
                            table={(panelProps) => {
                                return (
                                    <SpordleTableProvider
                                        id='logs'
                                        ref={panelProps.spordleTableRef}
                                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                        tableHover clickable striped
                                        bordered
                                        columns={[
                                            {
                                                key: 'date',
                                                label: <Translate id='members.profile.overview.memberLogs.table.date' />,
                                                sortable: true,
                                                mobile: true,
                                            },
                                            {
                                                key: 'time',
                                                label: <Translate id='members.profile.overview.memberLogs.table.time' />,
                                                sortable: true,
                                                mobile: true,
                                            },
                                            {
                                                key: 'by',
                                                label: <Translate id='members.profile.overview.memberLogs.table.by' />,
                                                sortable: true,
                                                mobile: true,
                                            },
                                            {
                                                key: 'section',
                                                label: <Translate id='members.profile.overview.memberLogs.table.section' />,
                                                sortable: true,
                                                mobile: true,
                                            },
                                            {
                                                key: "detail",
                                                label: <Translate id='members.profile.overview.memberLogs.table.detail' />,
                                                mobile: true,
                                            },
                                        ]}
                                        desktopWhen='md'
                                        pagination={20}
                                        renderRow={(key, row) => {
                                            switch (key){
                                                case 'detail':
                                                    return <span className='text-link'><Translate id='members.profile.overview.memberLogs.table.view' /></span>;
                                                case 'by':
                                                    return (
                                                        <UserDisplay>
                                                            <UserDisplay.Container>
                                                                <div className="border rounded-circle shadow-sm p-2">
                                                                    <img className="object-fit-contain" src={row.by.image ?? noProfileLogo} width="20" height="20" alt={`${row.by.firstName} ${row.by.lastName}`} />
                                                                </div>
                                                            </UserDisplay.Container>
                                                            <UserDisplay.Container>
                                                                <UserDisplay.Title>{row.by.firstName} {row.by.lastName}</UserDisplay.Title>
                                                                <UserDisplay.Subtitle>#{row.by.memberNumber}</UserDisplay.Subtitle>
                                                            </UserDisplay.Container>
                                                        </UserDisplay>
                                                    )
                                                default:
                                                    break;
                                            }
                                        }}
                                        onColumnClick={(e, communication) => {
                                            switch (e.button){
                                                case 0: // Left mouse button
                                                    panelProps.onSingleSelectChange(communication);
                                                    break;
                                            }
                                        }}
                                        rowIsHighlighted={(communication) => !!communication.checked}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return new Promise((resolve) => {
                                                        resolve(this.demoData)
                                                    });
                                                default:
                                                    break;
                                            }
                                        }}
                                    >
                                        <SpordleTableView />
                                    </SpordleTableProvider>
                                )
                            }}
                        />
                    </div>
                </Collapse>
            </Card>
        )
    }
}

export default MemberLogs;