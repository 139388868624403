import { stringBuilder } from "@spordle/helpers";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/contexts";
import UserImg from "./UserImg";
import PropTypes from 'prop-types';

/**
 * Photo to display primary meta member's photo of the account
 * @param {Object} props
 * @param {string} [props.className]
 * @param {number|string} [props.width=40]
 * @param {number|string} [props.height=props.width]
 * @returns {JSX.Element}
 */
const MainPhoto = ({ className, width = 40, height = width }) => {
    const { account, getPrimaryMeta } = useContext(AuthContext);
    const [ primary, setPrimary ] = useState(null);

    useEffect(() => {
        getPrimaryMeta()
            .then(setPrimary)
            .catch(console.error);
    }, []);

    return (
        account ?
            <UserImg
                className={stringBuilder('p-0', className)}
                width={width}
                height={height}
                alt={account.name + ' ' + account.family_name}
                abbr={account.name?.charAt(0) + account.family_name?.charAt(0)}
                src={primary?.attachment?.full_path || account.logo}
                filePos={primary?.attachment?.file_position}
            />
            :
            <div
                className={stringBuilder('bg-light rounded-circle', className)}
                style={{
                    width: width,
                    height: height,
                }}
            />
    )
}

export default MainPhoto;

MainPhoto.propTypes = {
    className: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

MainPhoto.defaultProps = {
    width: 40,
    height: 40,
};