import { useContext, useEffect } from 'react';
import FormOnlineStoreGeneralInformation from './FormOnlineStoreGeneralInformation';
import ReadOnlineStoreGeneralInformation from './ReadOnlineStoreGeneralInformation';
import { OnlineStoreContext } from '../../../../contexts/OnlineStoreContext';
import OverlayLoader from '../../../../components/loading/OverlayLoader';

import * as Sentry from "@sentry/react";

/**
* @param {object} props
* @param {boolean} props.isEditing
* @param {boolean} props.wizard
* @param {function} [props.onFormSave]
* @param {function} [props.toggle]
* @param {string} [props.onlineStoreId]
*/
const OnlineStoreGeneralInformation = (props) => {
    const onlineStoreContext = useContext(OnlineStoreContext);

    useEffect(() => {
        if(!props.wizard){
            Sentry.addBreadcrumb({
                type: 'info',
                message: `${props.isEditing ? 'Editing' : 'Reading'} online store general info`,
                level: Sentry.Severity.Log,
                category: 'OnlineStore',
            });
        }
    }, [ props.isEditing ]);

    return (
        <div className='position-relative'>
            <OverlayLoader isLoading={props.onlineStoreId ? props.onlineStoreId != onlineStoreContext.state.cachedStore?.online_store_id : false}>
                {props.isEditing ?
                    <FormOnlineStoreGeneralInformation
                        {...props}
                        key={onlineStoreContext.state.cachedStore?.online_store_id}
                        defaultData={props.onlineStoreId ? onlineStoreContext.state.cachedStore : null}
                        save={props.onFormSave}
                        cancel={props.toggle}
                        wizard={!props.onlineStoreId}// Create if we don't have an existing online store id
                    />
                    :
                    <ReadOnlineStoreGeneralInformation {...props} toggleEdit={props.toggle} data={onlineStoreContext.state.cachedStore?.online_store_id ? onlineStoreContext.state.cachedStore : null} />
                }
            </OverlayLoader>
        </div>
    );
}

export default OnlineStoreGeneralInformation;
