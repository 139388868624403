import Translate from "@spordle/intl-elements";
import { useHistory } from "react-router-dom";
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Row, UncontrolledButtonDropdown } from "reactstrap";
import ExportDropdown from "./ExportDropdown";

/**
 * @class
 * @prop {boolean} isDoubleReport isDoubleReport determines whether or not se show the dropdown entry to switch between detailed and summary reports
 * @prop {string} switchReportLabel only used if isSummary: place this on the summary report.
 * @prop {jsx or string} children The children contained between both tags of ReportLink
 *
 * @requires IMPORTANT MAKE SURE TO HAVE A DEFAULT CASE THAT RETURNS data[columnKey] in renderRow
 * @copyright Spordle
 */
const SubmitButtonDropdown = ({ isSummary, isDoubleReport, formikRef, reportType, formikFilters, switchPath, isOpenHandlers }) => {
    const history = useHistory()

    // for the initial values we need the arrays to be arrays, but for the export we need to stringify them
    // this functions stringifies the arrays before the export
    const makeObjArraysStrings = (formattedFilters) => {
        Object.keys(formikFilters).map((key) => {
            if(Array.isArray(formattedFilters[key])){
                formattedFilters[key] = formattedFilters[key].join();
            }
        })
        return formattedFilters;
    }

    return (
        <FormGroup>
            <Row form>
                <Col xs={6}>
                    <Button
                        block
                        type="submit"
                        color='primary'
                        className='text-nowrap'
                    >
                        <i className='fas fa-search mr-1' />
                        <Translate id='misc.search' />
                    </Button>
                </Col>
                <Col xs={6}>
                    <UncontrolledButtonDropdown className="text-nowrap w-100">
                        <DropdownToggle
                            id='SubmitButtonDropdownToggle'
                            color='primary'
                            caret outline
                        >
                            <Translate id='misc.actions' />
                        </DropdownToggle>
                        <DropdownMenu right>
                            {isDoubleReport &&
                                <>
                                    <DropdownItem
                                        id={'SubmitButtonDropdown-0'}
                                        onClick={() => {
                                            history.push({ pathname: switchPath, data: formikFilters })
                                        }}
                                    >
                                        {isSummary ?
                                            <Translate id={'reports.submit.button.dropdown.detailed'} />
                                            :
                                            <Translate id={'reports.submit.button.dropdown.summary'} />
                                        }
                                        <i className="mdi mdi-arrow-right ml-1" />
                                    </DropdownItem>
                                    <DropdownItem divider />
                                </>
                            }
                            {isOpenHandlers &&
                                <>
                                    <DropdownItem onClick={isOpenHandlers.open}>
                                        {formikFilters.custom_report_id ?
                                            <Translate id='reports.customize.modal.triggerModal.edit' />
                                            :
                                            <Translate id='reports.customize.modal.triggerModal' />
                                        }
                                    </DropdownItem>
                                    <DropdownItem divider />
                                </>
                            }
                            <ExportDropdown
                                justExcelItem
                                getData={() => {}}
                                formikRef={formikRef}
                                reportType={reportType}
                                formikFilters={makeObjArraysStrings(formikFilters)}
                            />
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </Col>
            </Row>
        </FormGroup>
    )
}

export default SubmitButtonDropdown;