export default{
    "members.profile.overview.memberContacts.title"       : "Contacts",
    "members.profile.overview.memberContacts.edit.title"  : "Modifier les contacts",
    "members.profile.overview.memberContacts.edit.save"   : "Sauvegarder les changements",
    "members.profile.overview.memberContacts.edit.cancel" : "Annuler les changements",

    // errors
    "members.profile.overview.memberContacts.error.contactType" : "Veuillez spécifier le type de contact de {count, plural, =0 {ce membre} one {ce membre} other {ces membres}}: {names}",

    // general
    "members.profile.overview.memberContacts.emergencyContact"            : "Contact en cas d'urgence",
    "members.profile.overview.memberContacts.taxReceiptRecipient"         : "Émettre le reçu d'impôts",
    "members.profile.overview.memberContacts.taxReceiptRecipient.tooltip" : "Ce contact recevra le reçu d'impôts par courriel.",
    "members.profile.overview.memberContacts.allow_communication"         : "Autoriser les communications",
    "members.profile.overview.memberContacts.allow_communication.tooltip" : "Ce contact recevra des communications par courriel.",
    "members.profile.overview.memberContacts.contactTypes"                : "Type de contact",
    "members.profile.overview.memberContacts.contactTypes.required"       : "Le type de contact est requis",
    "members.profile.overview.memberContacts.addPhone"                    : "Ajouter un numéro de téléphone",
    "members.profile.overview.memberContacts.lastUpdated"                 : "Dernière mise à jour",
    "members.profile.overview.memberContacts.sort.emergency"              : "Contact d'urgence",
    "members.profile.overview.memberContacts.sort"                        : "Tri",

    // table specifics
    "members.profile.overview.memberContacts.table.noData" : "Aucun contact",

    // add modal specifics
    "members.profile.overview.memberContacts.addContact.title"         : "Ajouter un contact",
    "members.profile.overview.memberContacts.addContact.tabs.new"      : "Nouveau contact",
    "members.profile.overview.memberContacts.addContact.tabs.deleted"  : "Contact retiré",
    "members.profile.overview.memberContacts.addContact.alreadyExists" : "Un contact existe déjà avec ces informations",
    "members.profile.overview.memberContacts.addContact.3078"          : "Le type de contact n'a pas pu être trouvé",
    "members.profile.overview.memberContacts.addContact.3172"          : "Le type de téléphone n'a pas pu être trouvé",
    "members.profile.overview.memberContacts.addContact.3229"          : "L'organisation ne correspond pas",
    "members.profile.overview.memberContacts.addContact.success"       : "Contact ajouté!",

    "members.profile.overview.memberContacts.addContact.updateEmail" : "Le membre ne possède pas d'adresse courriel, voulez-vous ajouter celle-ci?",
    "members.profile.overview.memberContacts.addContact.confirm"     : "L'adresse courriel du membre a été mise à jour!",

    // side panel specifics
    "members.profile.overview.memberContacts.sidePanel.contactType.required"      : "Un type de contact est requis. Veuillez l'ajouter avant de procéder à d'autres changements.",
    "members.profile.overview.memberContacts.sidePanel.phoneNumbers"              : "Numéro(s) de téléphone",
    "members.profile.overview.memberContacts.sidePanel.phoneNumbers.none"         : "Aucun numéro de téléphone",
    "members.profile.overview.memberContacts.sidePanel.emergency.no"              : "Ce contact n'est pas un contact en cas d'urgence",
    "members.profile.overview.memberContacts.sidePanel.emergency.yes"             : "Ce contact est un contact en cas d'urgence",
    "members.profile.overview.memberContacts.sidePanel.tax_receipt_recipient.yes" : "Ce contact est le bénificiaire du reçu d'impôts",
    "members.profile.overview.memberContacts.sidePanel.tax_receipt_recipient.no"  : "Ce contact n'est pas le bénificiaire du reçu d'impôts",
    "members.profile.overview.memberContacts.sidePanel.allow_communication.yes"   : "Ce contact autorise les communications",
    "members.profile.overview.memberContacts.sidePanel.allow_communication.no"    : "Ce contact n'autorise pas pas les communications",
    "members.profile.overview.memberContacts.sidePanel.emergency.phone"           : "Au moins un numéro de téléphone doit être spécifié pour activer cette option",
    "members.profile.overview.memberContacts.sidePanel.remove.text"               : "Vous êtes sur le point de supprimer ce contact.",
    "members.profile.overview.memberContacts.sidePanel.remove.success"            : "Contact supprimé!",
    "members.profile.overview.memberContacts.sidePanel.edit.success"              : "Contact mis à jour!",
    "members.profile.overview.memberContacts.sidePanel.contactInfo"               : "Information de contact",
    "members.profile.overview.memberContacts.sidePanel.identity"                  : "Identité liée",
    "members.profile.overview.memberContacts.sidePanel.identity.none"             : "Aucune identité liée",
    "members.profile.overview.memberContacts.sidePanel.identity.modal.title"      : "Lier une identité",
    "members.profile.overview.memberContacts.sidePanel.identity.modal.link"       : "Lier",
}