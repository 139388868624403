import { useSpordleTable } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import moment from "moment";
import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledButtonDropdown } from "reactstrap";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { I18nContext } from "../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { PeriodsContext } from "../../../../contexts/contexts";
import { ReportsContext } from "../../../../contexts/ReportsContext";
import { SupervisionsContext } from "../../../../contexts/SupervisionsContext";
import { exportToCsv } from "../../../../helpers/export";
import { displayI18n } from "../../../../helpers/i18nHelper";
import { handleExcelExport } from "../../../../helpers/reportsHelper";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import { ALL_TYPE_CATEGORIES } from "../officialSupervisionHelper";
import { displayCategory } from "../../../teams/TeamHelpers";

const ExportDropdown = () => {
    return (
        <UncontrolledButtonDropdown className='ml-2'>
            <DropdownToggle color='primary' outline caret>
                <i className='mdi mdi-download mr-1' /><Translate id='misc.export' />
            </DropdownToggle>
            <DropdownMenu right>
                <ExportCSV />
                <ExportExcel />
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
}

const ExportExcel = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const { getData } = useSpordleTable();
    const reportsContext = useContext(ReportsContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { organisation_id } = useContext(OrganizationContext);

    const handleOnExcelExport = () => {
        handleExcelExport(
            setIsLoading,
            'supervision',
            {
                organisation_id: organisation_id,
                period_id: selectedPeriod.period_id,
            },
            getData().length,
            reportsContext,
            identityRolesContext.identity_role_id,
            identityRolesContext.identity.identity_id,
            getGenericLocale(),
            organisation_id,
        );
    }

    return (
        <CanDoAction action="READ" componentCode="reports" componentPermissionCode="supervision_reports">
            <DropdownItem disabled={isLoading} onClick={handleOnExcelExport}><i className='fas fa-file-excel mr-1' />Excel</DropdownItem>
        </CanDoAction>
    )
}

const ExportCSV = () => {
    const { organisation_id } = useContext(OrganizationContext);
    const { getSupervisionsList } = useContext(SupervisionsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const { formatMessage } = useIntl();


    const formatData = (supervisions) => {
        return supervisions.map((supervision) => ([
            supervision.supervision_date,
            displayI18n("name", supervision.supervision_type.i18n, supervision.supervision_type.name, getGenericLocale()),
            `${supervision.officiating_coach.first_name} ${supervision.officiating_coach.last_name}`,
            supervision.officiating_coach.unique_identifier,
            `${supervision.official.first_name} ${supervision.official.last_name}`,
            supervision.official.unique_identifier,
            displayCategory(supervision.team_category, "name", getGenericLocale()),
            supervision.home_team ? displayI18n("short_name", supervision.home_team?.i18n, supervision.home_team?.name, getGenericLocale()) : supervision.home_team_name,
            supervision.away_team ? displayI18n("short_name", supervision.away_team?.i18n, supervision.away_team?.name, getGenericLocale()) : supervision.away_team_name,
            supervision.strengths || "-",
            supervision.areas_of_improvements || "-",
            supervision.comments || "-",
            ...ALL_TYPE_CATEGORIES.map((cat) => supervision[cat] ? formatMessage({ id: `officialSupervision.categories.choice.${supervision[cat]}` }) : "-"),
        ]));
    }

    const handleOnCSVExport = () => {
        setIsLoading(true);
        getSupervisionsList({ organisation_id })
            .then((supervisions) => {
                exportToCsv(`SpordleID-OfficialSupervision_${moment().format('YYYY-MM-DD H:mm')}.csv`, [
                    [// Headers
                        formatMessage({ id: 'officialSupervision.supervisionDate.label' }),
                        formatMessage({ id: 'officialSupervision.supervisionType.label' }),
                        formatMessage({ id: 'officialSupervision.officiatingCoach.label' }),
                        formatMessage({ id: 'officialSupervision.officiatingCoach.uniqueIdentifier.label' }),
                        formatMessage({ id: 'officialSupervision.official.label' }),
                        formatMessage({ id: 'officialSupervision.official.uniqueIdentifier.label' }),
                        formatMessage({ id: 'officialSupervision.teamCategory.label' }),
                        formatMessage({ id: 'officialSupervision.homeTeam.label' }),
                        formatMessage({ id: 'officialSupervision.awayTeam.label' }),
                        formatMessage({ id: 'officialSupervision.strengths.label' }),
                        formatMessage({ id: 'officialSupervision.areaOfImprovement.label' }),
                        formatMessage({ id: 'officialSupervision.comments.label' }),
                        ...ALL_TYPE_CATEGORIES.map((category) => formatMessage({ id: `officialSupervision.categories.${category}` })),
                    ],
                    ...formatData(supervisions),
                ]);
                setIsLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                    setIsLoading(false);
                }
            });
    }

    return (
        <DropdownItem disabled={isLoading} onClick={handleOnCSVExport}><i className='fas fa-file-excel mr-1' />CSV{isLoading && <Spinner size='sm' color='primary' type='grow' className='float-right' />}</DropdownItem>
    )
}

export default ExportDropdown;