export default{
    // Review
    "clinics.clinicSessionInformation.clinicSessionInformation.title" : "Informations de(s) séance(s)",

    // contact info
    "clinics.clinicSessionInformation.clinicSessionInformation.hostContactInfo.title" : "Contact(s)",

    // instructor info
    "clinics.clinicSessionInformation.clinicSessionInformation.instructorContactInfo.title" : "Instructeur(s)",

    // Sessions
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.title"           : "Séance(s)",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.number"          : "Séance n{number}",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.format"          : "Format",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.language"        : "Langue",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.location"        : "Emplacement",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.source"          : "Source",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.url"             : "Lien URL",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.online.source"   : "Source",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.online.external" : "Lien externe",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.online.huc"      : "Cours d'Université du Hockey",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.online.coach"    : "Coach.ca",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.online.respect"  : "Respect et sport",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.notes"           : "Notes",

    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.ONSITE"      : "En personne",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.ONLINE_LIVE" : "En ligne - En direct",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.ONLINE"      : "En ligne - Sur demande",

    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.title" : "Horaire",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.text1" : "La séance aura lieu le ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.text2" : " à ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.text3" : "et durera ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.text4" : " heure(s) et ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.text5" : " minute(s) ",

    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text1" : "La séance sera disponible du ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text2" : " à ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text3" : " jusqu'au ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text4" : " à ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text5" : "et durera ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text6" : " heure(s) et ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text7" : " minute(s) ",
}