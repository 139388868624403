import Translate from '@spordle/intl-elements';
import moment from 'moment';
import { useIntl } from 'react-intl';

const ExpirationSection = ({ qualificationExpiration, ...props }) => {
    const intl = useIntl();

    const getFixedDateString = (dateString) => {
        const momentDate = moment(dateString, 'MM-DD');
        if(momentDate.isValid()){
            return momentDate.toDate().toLocaleDateString(intl.locale, { day: 'numeric', month: 'long' });
        }
        return <Translate id='misc.invalid.date' />;
    }

    return (
        <div className={props.wizard ? 'mb-5' : 'mb-4'}>
            <div className='font-bold mb-3 h5'>
                <Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration' />
            </div>

            <div className='mb-3 d-flex'>
                <div>
                    <div className='text-muted'><Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.type' /></div>
                    <div className='font-medium'><Translate id={'clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.' + (qualificationExpiration.expirationType || 'NO_EXPIRATION')} /></div>
                </div>

                {qualificationExpiration.expirationDuration &&
                    <div className='ml-5'>
                        <div className='text-muted'><Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.duration' /></div>
                        <div className='font-medium'><Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.duration.years' values={{ number: qualificationExpiration.expirationDuration }} /></div>
                    </div>
                }

                {qualificationExpiration.expirationDate &&
                    <div className='ml-5'>
                        <div className='text-muted'><Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.date' /></div>
                        <div className='font-medium'>
                            {getFixedDateString(qualificationExpiration.expirationDate)}
                        </div>
                    </div>
                }
            </div>

        </div>
    )
}

export default ExpirationSection