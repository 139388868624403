import { FormikDateTime, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useContext } from 'react';
import { Button, Collapse, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import useSWR from 'swr';
import { mixed, object, string } from 'yup';
import AnalyticsModal from '../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { I18nContext } from '../../contexts/I18nContext';
// contexts
import { MembersContext } from '../../contexts/MembersContext';
import { UtilsContext } from '../../contexts/UtilsContext';
import { DisplayI18n } from '../../helpers/i18nHelper';
import CustomAlert from '../CustomAlert';
import Required from '../formik/Required';
import OverlayLoader from '../loading/OverlayLoader';
import { fail, success } from '@spordle/toasts';


const EditInstallmentModal = ({ isOpen, toggle, invoicePayment, invoiceItemId, onEditInstallment, field }) => {
    const membersContext = useContext(MembersContext);
    const utilsContext = useContext(UtilsContext);
    const i18nContext = useContext(I18nContext);

    const { data: paymentMethods, isValidating } = useSWR(
        [ 'getPaymentMethods' ],
        () => utilsContext.getPaymentMethods()
            .then((paymentMethods) => paymentMethods.filter((pm) => pm.category === 'MANUAL'))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        ,
        {
            fallbackData: [],
        },
    );

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='editInstallmentModal' size='sm'>
            <ModalHeader toggle={toggle}>
                <Translate id='components.transaction.editInstallmentsModal.header' />
            </ModalHeader>

            <Formik
                initialValues={{
                    paymentDate: moment(invoicePayment?.due_date, 'YYYY-MM-DD'),
                    paymentMethod: '',
                }}
                validationSchema={object().shape({
                    paymentDate: mixed().when('does_not_matter', {
                        is: () => field === 'date',
                        then: mixed().required(<Translate id='form.validation.date.required' />).test({
                            name: 'paymentDateValidTest',
                            message: <Translate id='form.validation.date.format' />,
                            test: moment.isMoment,
                        }),
                    }),
                    paymentMethod: string().when('doesn_not_matter', {
                        is: () => field === 'paymentMethod',
                        then: string().required(<Translate id='components.transaction.editInstallmentsModal.paymentMethod.required' />),
                    }),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    const getNewData = () => {
                        switch (field){
                            case 'date':
                                return { due_date: moment(values.paymentDate).format('YYYY-MM-DD') }
                            case 'paymentMethod':
                                return { payment_method: values.paymentMethod }
                        }
                    }

                    const newData = getNewData();
                    membersContext.updateInvoicePayment(invoicePayment?.invoice_payment_id, newData)
                        .then(() => {
                            setSubmitting(false);
                            onEditInstallment?.(newData, invoicePayment?.invoice_payment_id, invoiceItemId);
                            toggle();
                            success();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                setSubmitting(false);
                                if(error.i18n){
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                }else{
                                    switch (error.message){
                                        case '3191':
                                            setStatus(<Translate id='contexts.membersContext.3191' />)
                                            break;
                                        case '3060':
                                            setStatus(<Translate id='contexts.membersContext.3060' />)
                                            break;
                                        case '3179':
                                            setStatus(<Translate id='contexts.membersContext.3179' />)
                                            break;
                                        default:
                                            setStatus(<Translate id='misc.error' />)
                                            break;
                                    }
                                }
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalBody>
                                {field === 'date' ?
                                    <>
                                        <Label for='paymentDate' className='text-muted'><Translate id='components.transaction.editInstallmentsModal.date.label' /> <Required /></Label>
                                        <FormikDateTime
                                            id='paymentDate' name='paymentDate'
                                            timeFormat={false}
                                            isValidDate={(current) => moment(current).isAfter(moment())}
                                            initialViewDate={moment(invoicePayment?.due_date)}
                                        />
                                    </>
                                    : field === 'paymentMethod' ?
                                        <>
                                            <Label for='paymentMethod' className='text-muted'><Translate id='components.transaction.editInstallmentsModal.paymentMethod.label' /> <Required /></Label>
                                            <FormikSelect
                                                isLoading={isValidating}
                                                id='paymentMethod'
                                                name='paymentMethod'
                                                renderOption={({ option }) => <DisplayI18n field='name' i18n={option.i18n} defaultValue={option.label} />}
                                                searchKeys={[
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                ]}
                                                options={paymentMethods ?
                                                    paymentMethods.map((method) => ({
                                                        value: method.code,
                                                        i18n: method.i18n,
                                                        label: method.name,
                                                    }))
                                                    :
                                                    []
                                                }

                                            />
                                        </>
                                        : null}

                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    {formik.status &&
                                        <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                    }
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button type='submit' color='primary'><Translate id='misc.confirm' /></Button>
                                <Button type='button' color='primary' outline onClick={() => toggle()}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    )
}

export default EditInstallmentModal