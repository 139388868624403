export default{
    "clinics.clinicInformation.clinicInformation.title"      : "Clinic information",
    "clinics.clinicInformation.clinicInformation.clinicName" : "Clinic Name",

    // host organization
    "clinics.clinicInformation.clinicInformation.host.title"       : "Host organization",
    "clinics.clinicInformation.clinicInformation.host.placeholder" : "Select an organization",
    "clinics.clinicInformation.clinicInformation.host.validation"  : "Organization is required",

    // clinic info
    "clinics.clinicInformation.clinicInformation.qualification.title"                          : "Qualification",
    "clinics.clinicInformation.clinicInformation.qualification.type.qualification"             : "Official qualification ({federationName})",
    "clinics.clinicInformation.clinicInformation.qualification.type.other"                     : "Qualification or course from your the host organization",
    "clinics.clinicInformation.clinicInformation.qualification.category"                       : "Category",
    "clinics.clinicInformation.clinicInformation.qualification.qualification"                  : "Qualification",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites"                  : "Prerequisites",
    "clinics.clinicInformation.clinicInformation.qualification.qualPrerequisites"              : "Because the qualification is: {qualificationName}.",
    "clinics.clinicInformation.clinicInformation.qualification.qualPrerequisites.hint.all"     : "The prerequisites below are the default prerequisites for the qualification. All of them are mandatory for the registration of an attendee.",
    "clinics.clinicInformation.clinicInformation.qualification.qualPrerequisites.hint.one"     : "The prerequisites below are the default prerequisites for the qualification. One of them is mandatory for the registration of an attendee.",
    "clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites"            : "Your organization's prerequisites",
    "clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites.hint"       : "Minimum number of mandatory prerequisites for the registration of an attendee",
    "clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites.hint.none1" : "You can add prerequisites from your organization.",
    "clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites.hint.none2" : "Click here to add a prerequisite",
    "clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites.all"        : "All mandatory",
    "clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites.one"        : "At least one mandatory",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.description"      : "Description",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.organization"     : "Organization",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.type"             : "Type",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.empty"            : "No prerequisites for this clinic",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.number"           : "{number, plural, one {# prerequisite} other {# prerequisites}}",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.title"            : "Prerequisites for {qualName}",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.none"             : "No prerequisites",
    "clinics.clinicInformation.clinicInformation.qualification.groups.title"                   : "Group #{index}",

    // attendees
    "clinics.clinicInformation.clinicInformation.attendees.title"                           : "Attendees",
    "clinics.clinicInformation.clinicInformation.attendees.types"                           : "Attendee type(s)",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.attendees"          : "Attendees requirements",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.attendees.min"      : "Minimum number",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.attendees.min.none" : "No minimum number",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.attendees.max"      : "Maximum number",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.attendees.max.none" : "No maximum number",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.age"                : "Age requirements",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.age.min"            : "Minimum age",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.age.min.none"       : "No minimum age",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.age.max"            : "Maximum age",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.age.max.none"       : "No maximum age",
    "clinics.clinicInformation.clinicInformation.attendees.criminalRecord"                  : "Background Check",

    // description
    "clinics.clinicInformation.clinicInformation.description.title" : "Description",
    "clinics.clinicInformation.clinicInformation.description.none"  : "No description",
}