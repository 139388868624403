import { FormikDateTime, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { success } from '@spordle/toasts';
import { Form, Formik, useFormikContext } from 'formik';
import moment, { isMoment } from 'moment';
import { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
    Row,
    Col,
    Card,
    Label,
    FormGroup,
    CardBody,
    CardFooter,
    Button,
    Collapse,
    Alert
} from "reactstrap";
import useSWR from 'swr';
import { array, mixed, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';
import { PeriodsContext } from '../../../../../../contexts/contexts';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { UtilsContext } from '../../../../../../contexts/UtilsContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

const OrganizationRegistrationSettings = () => {
    const { getSettings } = useContext(UtilsContext);
    const { getOrganizationSettings, organisation_id, updateSettings, organisation_category } = useContext(OrganizationContext);
    const { selectedPeriod } = useContext(PeriodsContext);

    const [ isEdit, setIsEdit ] = useState(false);

    const { data, isValidating, mutate } = useSWR(
        [ 'SettingsRegistrationSettings', organisation_id, selectedPeriod.period_id ],
        () => Promise.all([
            getSettings({ with_period: 1, active: 1 }),
            getSettings({ active: 1, code: 'block_incomplete_member_rostering' }),
        ])
            .then(([ settings, blockSetting ]) => {
                const settingsKeys = Object.keys(settings);
                const blockSettingsKeys = Object.keys(blockSetting);

                return [
                    settingsKeys.reduce((filteredSettings, key) => {
                        if(!settings[key].managed_by || settings[key].managed_by?.name === organisation_category?.name){
                            filteredSettings[key] = settings[key];
                        }
                        return filteredSettings
                    }, {}),
                    blockSettingsKeys.reduce((filteredSettings, key) => {
                        if(!blockSetting[key].managed_by || blockSetting[key].managed_by?.name === organisation_category?.name){
                            filteredSettings[key] = blockSetting[key];
                        }
                        return filteredSettings
                    }, {}),
                ]
            })
            .then(async([ settings, blockSetting ]) => {
                const allSettings = { ...settings, ...blockSetting };
                const settingsKeys = Object.keys(allSettings);
                const orgSettings = await getOrganizationSettings(organisation_id, { active: 1, period_id: selectedPeriod.period_id, code: settingsKeys })
                return {
                    settings: settings,
                    settingsAllPeriods: blockSetting,
                    orgSettings,
                    initialValues: settingsKeys.reduce((initialValues, settingKey) => {
                        switch (allSettings[settingKey].type){
                            case 'DATE':
                            case 'TIME':
                            case 'DATETIME':
                                initialValues[settingKey] = orgSettings[settingKey]?.value ? moment(orgSettings[settingKey].value) : '';
                                break;
                            case 'MULTIPLE':
                                initialValues[settingKey] = orgSettings[settingKey]?.value || [];
                                break;
                            case 'BOOLEAN':
                            case 'RADIO':
                            case 'TEXT':
                            default:
                                initialValues[settingKey] = orgSettings[settingKey]?.value || '';
                                break;
                        }
                        return initialValues;
                    }, {}),
                    validationSchema: object().shape(settingsKeys.reduce((validationSchema, settingKey) => {
                        switch (allSettings[settingKey].type){
                            case 'TIME':
                                validationSchema[settingKey] = mixed().isDate(<Translate id='form.validation.time.format' />);
                                break;
                            case 'DATE':
                            case 'DATETIME':
                                validationSchema[settingKey] = mixed().isDate(<Translate id='form.validation.date.format' />);
                                break;
                            case 'MULTIPLE':
                                validationSchema[settingKey] = array();
                                break;
                            case 'BOOLEAN':
                            case 'RADIO':
                            case 'TEXT':
                                if(settingKey === 'official_website_url'){
                                    validationSchema[settingKey] = string().url(<Translate id='form.validation.url.format' />);
                                    break;
                                }
                            default:
                                validationSchema[settingKey] = string();
                                break;
                        }
                        return validationSchema;
                    }, {})),
                };
            }),
        {
            fallbackData: {
                settings: {},
                settingsAllPeriods: {},
                orgSettings: {},
                initialValues: {},
                validationSchema: null,
            },
        },
    );

    return (isEdit ?
        <Formik
            enableReinitialize
            initialValues={data.initialValues}
            validationSchema={data.validationSchema}
            onSubmit={async(values, { setStatus }) => {
                setStatus();
                const apiValues = Object.keys(values).reduce((formattedValues, valueKey) => {
                    if(isMoment(values[valueKey])){
                        switch (data.settings[valueKey]?.type){
                            case 'TIME':
                                formattedValues[valueKey] = values[valueKey].format('HH:mm');
                                break;
                            case 'DATE':
                                formattedValues[valueKey] = values[valueKey].format('YYYY-MM-DD');
                                break;
                            case 'DATETIME':
                            default:
                                formattedValues[valueKey] = values[valueKey].toISOString();
                                break;
                        }
                    }else{
                        formattedValues[valueKey] = values[valueKey]
                    }
                    return formattedValues
                }, values)
                return updateSettings(organisation_id, apiValues, selectedPeriod.period_id, data.settingsAllPeriods)
                    .then(async() => {
                        await mutate()
                        setIsEdit(false)
                        success();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                        }
                    })
            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={formik.isSubmitting || isValidating}>
                    <Card className="card-shadow">
                        <Form>
                            <CardBody>
                                <div className="border-bottom pb-1 mb-3 d-flex">
                                    <div className="card-title font-bold h4 mb-0">
                                        <Translate id='settings.general.tabs.regionalSettings' />
                                    </div>
                                </div>
                                <div className="text-dark font-bold mb-2">
                                    <Translate id='settings.general.periodHint.select' values={{ periodName: <DisplayI18n field='name' defaultValue={selectedPeriod.name} i18n={selectedPeriod.i18n} /> }} />
                                    <i className='mdi mdi-information-outline ml-1 text-primary' />
                                </div>
                                <Row>
                                    {Object.keys(data.settings).map((settingKey) => (
                                        <Col xs='12' sm='6' key={settingKey}>
                                            <FormGroup>
                                                <Label for={settingKey} className='text-muted'>
                                                    <DisplayI18n field='name' defaultValue={data.settings[settingKey].name} i18n={data.settings[settingKey].i18n} />
                                                </Label>
                                                <InputType settingKey={settingKey} type={data.settings[settingKey].type} values={data.settings[settingKey].value} />
                                            </FormGroup>
                                        </Col>
                                    ))}
                                </Row>
                                {Object.keys(data.settingsAllPeriods).length > 0 &&
                                    <>
                                        <div className="text-dark font-bold mb-2">
                                            <Translate id='settings.general.periodHint.all' />
                                            <i className='mdi mdi-information-outline ml-1 text-primary' />
                                        </div>
                                        <Row>
                                            {Object.keys(data.settingsAllPeriods).map((settingKey) => (
                                                <Col xs='12' sm='6' key={settingKey}>
                                                    <FormGroup>
                                                        <Label for={settingKey} className='text-muted'>
                                                            <DisplayI18n field='name' defaultValue={data.settingsAllPeriods[settingKey].name} i18n={data.settingsAllPeriods[settingKey].i18n} />
                                                        </Label>
                                                        <InputType settingKey={settingKey} type={data.settingsAllPeriods[settingKey].type} values={data.settingsAllPeriods[settingKey].value} />
                                                    </FormGroup>
                                                </Col>
                                            ))}
                                        </Row>
                                    </>
                                }
                                <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                    <Alert color='danger' className='mb-0'>
                                        {formik.status}
                                    </Alert>
                                </Collapse>
                            </CardBody>
                            <CardFooter className='bg-white text-right'>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting} className='mr-1'><Translate id='misc.save' /></Button>
                                <Button color='primary' outline type='button' disabled={formik.isSubmitting} onClick={() => { setIsEdit(false) }}><Translate id='misc.cancel' /></Button>
                            </CardFooter>
                        </Form>
                    </Card>
                </OverlayLoader>
            )}
        </Formik>
        :
        <Card className="card-shadow">
            <CardBody>
                <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                    <div className="card-title font-bold h4 mb-0">
                        <Translate id='settings.general.tabs.regionalSettings' />
                    </div>

                    <CanDoAction className='ml-auto' action='EDIT' componentCode='settings' componentPermissionCode='settings_regional'>
                        {(godAccess) => (
                            <button type='button' disabled={isValidating} className={stringBuilder('reset-btn', { 'text-link': !godAccess, 'text-purple': godAccess })} onClick={() => { setIsEdit(true) }}>
                                <Translate id='misc.edit' />
                            </button>
                        )}
                    </CanDoAction>
                </div>
                <div className="text-dark font-bold mb-2">
                    <Translate id='settings.general.periodHint.select' values={{ periodName: <DisplayI18n field='name' defaultValue={selectedPeriod.name} i18n={selectedPeriod.i18n} /> }} />
                    <i className='mdi mdi-information-outline ml-1 text-primary' />
                </div>
                <Row>
                    {isValidating ?
                        Array.from({ length: Object.keys(data.settings).length || 4 }, (_, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Col xs='12' sm='6' key={index}>
                                <FormGroup>
                                    <Skeleton className='w-50' />
                                    <Skeleton className='w-75' />
                                </FormGroup>
                            </Col>
                        ))
                        :
                        Object.keys(data.settings).map((settingKey) => (
                            <Col xs='12' sm='6' key={settingKey}>
                                <FormGroup>
                                    <div className='text-muted'>
                                        <DisplayI18n field='name' defaultValue={data.settings[settingKey].name} i18n={data.settings[settingKey].i18n} />
                                    </div>
                                    <SettingValue orgSetting={data.orgSettings[settingKey]} setting={data.settings[settingKey]} settingKey={settingKey} />
                                </FormGroup>
                            </Col>
                        ))
                    }
                </Row>
                {Object.keys(data.settingsAllPeriods).length > 0 &&
                    <>
                        <div className="text-dark font-bold mb-2">
                            <Translate id='settings.general.periodHint.all' />
                            <i className='mdi mdi-information-outline ml-1 text-primary' />
                        </div>
                        <Row>
                            {isValidating ?
                                Array.from({ length: Object.keys(data.settingsAllPeriods).length || 2 }, (_, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Col xs='12' sm='6' key={index}>
                                        <FormGroup>
                                            <Skeleton className='w-50' />
                                            <Skeleton className='w-75' />
                                        </FormGroup>
                                    </Col>
                                ))
                                :
                                Object.keys(data.settingsAllPeriods).map((settingKey) => (
                                    <Col xs='12' sm='6' key={settingKey}>
                                        <FormGroup>
                                            <div className='text-muted'>
                                                <DisplayI18n field='name' defaultValue={data.settingsAllPeriods[settingKey].name} i18n={data.settingsAllPeriods[settingKey].i18n} />
                                            </div>
                                            <SettingValue orgSetting={data.orgSettings[settingKey]} setting={data.settingsAllPeriods[settingKey]} settingKey={settingKey} />
                                        </FormGroup>
                                    </Col>
                                ))
                            }
                        </Row>
                    </>
                }
            </CardBody>
        </Card>
    )
};

export const SettingValue = ({ setting, orgSetting, settingKey }) => {
    const value = setting?.value?.[orgSetting?.value];
    if(setting.type === 'BOOLEAN' && orgSetting?.value){
        return <Translate id={`misc.${orgSetting?.value == 1 ? 'yes' : 'no'}`} />
    }
    if(value){
        return <DisplayI18n field='name' defaultValue={orgSetting.value} i18n={value.i18n} />
    }
    if(settingKey === 'official_website_url' && orgSetting?.value){
        return <a href={orgSetting.value} target='_blank' rel='noopener noreferrer'>{orgSetting.value}</a>
    }
    return orgSetting?.value || '-';
}

export const InputType = ({ type, settingKey, values }) => {
    const formik = useFormikContext();
    switch (type){
        case 'TEXT':
            return (
                <FormikInputText
                    name={settingKey} id={settingKey} trim
                    inputMode={settingKey === 'official_website_url' ? 'url' : undefined}
                    onChange={(e) => {
                        let value = e.target.value
                        if(settingKey === 'official_website_url'){
                            if(value != ''){
                                if(!value.startsWith('https://') && !value.startsWith('http://') && !value.startsWith('https://www') && !value.startsWith('http://www') && !value.startsWith('www')){
                                    value = "https://www." + value;
                                }else if(value.startsWith('www')){
                                    value = "https://" + value;
                                }else if(value.startsWith('http://')){
                                    value = "https://" + value.split('http://')[1];
                                }
                            }
                        }
                        formik.setFieldValue(settingKey, value);
                    }}
                />
            );
        case 'DATE':
            return <FormikDateTime name={settingKey} id={settingKey} timeFormat={false} />;
        case 'TIME':
            return <FormikDateTime name={settingKey} id={settingKey} dateFormat={false} />;
        case 'DATETIME':
            return <FormikDateTime name={settingKey} id={settingKey} />;
        case 'MULTIPLE':
        case 'RADIO':
            const i18nContext = useContext(I18nContext);
            if(values)
                return (
                    <FormikSelect
                        id={settingKey}
                        name={settingKey}
                        loadingStatus='success'
                        defaultData={Object.keys(values).sort((a, b) => parseInt(values[a].order) - parseInt(values[b].order)).map((valueKey) => ({
                            value: valueKey,
                            label: valueKey,
                            i18n: values[valueKey].i18n,
                        }))}
                        renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                        searchKeys={[
                        `i18n.${i18nContext.getGenericLocale()}.name`,
                        ]}
                        clearable={type !== 'RADIO'}
                        multi={type === 'MULTIPLE'}
                    />
                );
            return null;
        case 'BOOLEAN':
            return (
                <FormikSelect
                    id={settingKey}
                    name={settingKey}
                    loadingStatus='success'
                    search={false}
                    defaultData={[
                        { label: 'misc.yes', translateLabel: true, value: '1' },
                        { label: 'misc.no', translateLabel: true, value: '0' },
                    ]}
                />
            );
        default:
            return null;
    }
}

export default OrganizationRegistrationSettings;