import Translate from "@spordle/intl-elements"
import { useContext, useState, useEffect } from "react"
import { mutate } from "swr"
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI"
import { getMemberRequestChangesStatus } from "../../../../../api/client/memberRequestChanges"
import CustomAlert from "../../../../../components/CustomAlert"
import { MembersContext } from "../../../../../contexts/MembersContext"
import RequestMandatoryChangesModal from "../../profileHeader/components/RequestMandatoryChangesModal"

const MemberGeneralEditTopSection = ({ refreshMemberProfile }) => {
    const [ isOpen, setIsOpen ] = useState()
    const [ changeRequestStatuses, setChangeRequestStatuses ] = useState([])
    const { currentMember } = useContext(MembersContext)

    useEffect(() => {
        getMemberRequestChangesStatus()
            .then(setChangeRequestStatuses)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                }
            })
    }, [])

    return (
        <>
            <CustomAlert
                color='info' className='mb-3'
                withTitle
                translateText={false}
                text={
                    <Translate
                        id='members.profile.overview.memberPrimaryInformation.edit.disabledFields'
                        values={{
                            request:
                                <button
                                    className='reset-btn text-link text-decoration-underline'
                                    type='button'
                                    onClick={() => setIsOpen(true)}
                                >
                                    <Translate id='members.profile.action.requestMandatoryChanges' />
                                </button>,
                        }}
                    />
                }
            />
            <RequestMandatoryChangesModal
                isOpen={isOpen}
                toggle={() => setIsOpen(false)}
                member={currentMember}
                refreshMemberProfile={() => { refreshMemberProfile(); mutate("getMemberStatements"); }}
                changeRequestStatuses={changeRequestStatuses}
            />
        </>
    )
}

export default MemberGeneralEditTopSection