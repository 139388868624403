import EngineFilterSearchButton from "../EngineSearchButton"
import EngineDateBetween from "../EngineDateBetween"
import EngineDate from "../EngineDate"
import EngineTime from "../EngineTime";
// selects
import EngineSelect from "../select/EngineSelect"
import EnginePeriodSelect from "../select/EnginePeriodSelect"
import EngineMultiOrganization from "../select/EngineMultiOrganization";
import EngineOrganization from "../select/EngineOrganization";
import EngineNumberInput from "../EngineNumberInput";
import EngineNumberBetween from "../EngineNumberBetween";
import EngineCheckbox from "../EngineCheckbox";
import IsGod from "../../../../../components/permissions/IsGod";
import EngineQuestionnaireFormSelect from "../customFilters/EngineQuestionnaireFormSelect";
import EngineMultiSelect from "../select/EngineMultiSelect";
import EnginePeriodReferencePeriodSelect from "../customFilters/EnginePeriodReferencePeriodSelect";
import EngineGenderSelect from "../select/EngineGenderSelect";
import EngineGenderMultiSelect from "../select/EngineGenderMultiSelect";

// this part of the engineFilterSwitch is where the actual filter switching happens
// we DETECT the filter TYPE and send out the correct filter component

const EngineFilterSwitchInner = ({ formikRef, currentFilter, filterRefs, customReportId, isOpenHandlers, setCustomFormId, customFormId }) => {
    switch (currentFilter.type){
        case 'SEARCH':
            return (
                <EngineFilterSearchButton formikRef={formikRef} filter={currentFilter} isOpenHandlers={isOpenHandlers} customReportId={customReportId} />
            )
        case 'PERIOD_SELECT':
            return (
                <EnginePeriodSelect filter={currentFilter} filterRefs={filterRefs} />
            )
        case 'GENDER_SELECT':
            return (
                <EngineGenderSelect filter={currentFilter} filterRefs={filterRefs} />
            )
        case 'GENDER_MULTI_SELECT':
            return (
                <EngineGenderMultiSelect filter={currentFilter} filterRefs={filterRefs} />
            )
        case 'TIME':
            return (
                <EngineTime filter={currentFilter} filterRefs={filterRefs} />
            )
        case 'DATE':
            return (
                <EngineDate filter={currentFilter} filterRefs={filterRefs} />
            )
        case 'DATE_BETWEEN':
            return (
                <EngineDateBetween filter={currentFilter} filterRefs={filterRefs} />
            )
        case 'ORGANIZATION_SELECT':
            return (
                <EngineOrganization filter={currentFilter} filterRefs={filterRefs} />
            );
        case 'MULTI_ORGANIZATION_SELECT':
            return (
                <EngineMultiOrganization filter={currentFilter} filterRefs={filterRefs} />
            )
        case 'SELECT':
            return (
                <EngineSelect filter={currentFilter} filterRefs={filterRefs} />
            )
        case 'MULTI_SELECT':
            return (
                <EngineMultiSelect filter={currentFilter} filterRefs={filterRefs} />
            )
        case 'NUMBER':
            return (
                <EngineNumberInput filter={currentFilter} filterRefs={filterRefs} />
            )
        case 'NUMBER_BETWEEN':
            return (
                <EngineNumberBetween filter={currentFilter} filterRefs={filterRefs} />
            )
        case 'CHECKBOX':
            return (
                <EngineCheckbox filter={currentFilter} filterRefs={filterRefs} />
            )
        case 'QUESTIONNAIRE_FORM':
            return (
                <EngineQuestionnaireFormSelect
                    filter={currentFilter} filterRefs={filterRefs} setCustomFormId={setCustomFormId}
                    customFormId={customFormId}
                />
            )
        case 'PERIOD_AND_REFERENCE_PERIOD':
            return (
                <EnginePeriodReferencePeriodSelect filter={currentFilter} filterRefs={filterRefs} />
            )

        default:
            return (
                <IsGod>
                    <div className="text-danger font-weight-bold">Filter is not supported: type: {currentFilter.type}, code: {currentFilter.code}</div>
                </IsGod>
            )
    }
}

export default EngineFilterSwitchInner