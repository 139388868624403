import Translate from '@spordle/intl-elements';
import { useState } from 'react';
import {
    ModalBody, Nav, NavLink, NavItem, TabContent, TabPane, Fade
} from "reactstrap";
import SubmitTicketCreate from './SubmitTicketCreate';
import SubmitTicketSearchResult from './SubmitTicketSearchResult';

/**
* For now, this is always step 2 of a bug report (after search)
* @param {function} back function called onClick of the "back" btn
*/
const SubmitTicket2 = ({ back, type, types, toggle, toggleLoading, success }) => {
    const [ tab, setTab ] = useState("1");
    return (
        <ModalBody>
            <Nav tabs>
                <NavItem className="">
                    <NavLink className={tab === "1" ? 'active' : ''} onClick={() => setTab('1')}>
                        <Translate id='helpCenter.submitTicket.search.createTicket' />
                    </NavLink>
                </NavItem>
                <NavItem className="">
                    <NavLink className={tab === "2" ? 'active' : ''} onClick={() => setTab('2')}>
                        <Translate id='helpCenter.submitTicket.search.tab.title' />
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent className="pt-3" activeTab={tab}>
                <TabPane tabId="1">
                    <Fade>
                        <SubmitTicketCreate toggleLoading={toggleLoading} back={back} success={success} type={type?.id} types={types} />
                    </Fade>
                </TabPane>
                <TabPane tabId="2">
                    <SubmitTicketSearchResult toggle={toggle} back={back} />
                </TabPane>
            </TabContent>
        </ModalBody>
    );
}

export default SubmitTicket2;