import Translate from '@spordle/intl-elements';
import { useEffect } from 'react';
import { currentMaintenanceUrl } from '../../api/API-Spordle';
import Img from '../../assets/images/Maintenance.png';

const Maintenance = () => {
    useEffect(() => {
        const oldTitle = document.title;
        document.title = 'Spordle Maintenance';

        const gStaticLink = document.createElement('link');
        gStaticLink.href = "https://fonts.gstatic.com";
        gStaticLink.rel = 'preconnect';
        const googleFontsLink = document.createElement('link');
        googleFontsLink.href = "https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap";
        googleFontsLink.rel = 'stylesheet';

        document.head.appendChild(gStaticLink);
        document.head.appendChild(googleFontsLink);

        setTimeout(() => {
            location.href = currentMaintenanceUrl;
        }, 60000);

        return () => {
            document.title = oldTitle;
            gStaticLink.remove();
            googleFontsLink.remove();
        }
    }, []);

    return (
        <main className="maintenance-container">
            <div className="maintenance-container-inner">
                <img src={Img} alt="Website in maintenance" width="300" />
                <div className="text-center">
                    <div className="maintenance-title w-75 h1 d-inline-block"><Translate id="maintenance.title" /></div>
                </div>
            </div>
            <div className="easter-egg w-100 text-center h6" style={{ color: '#f2f2f2', position: 'fixed', bottom: 30, cursor: 'default' }}>Gloogloo time!</div>
        </main>
    )
}

export default Maintenance;