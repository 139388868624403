import { useSpordleTable } from "@spordle/datatables";
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext } from "react";
import {
    Button,
    Col,
    Collapse,
    FormGroup,
    Label, ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import * as Yup from 'yup';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import CustomAlert from "../../../../../components/CustomAlert";
import Required from "../../../../../components/formik/Required";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { TeamsContext } from "../../../../../contexts/TeamsContext";
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../../helpers/i18nHelper'; // these are deprecated, look at OrganizationPhoneTypes i18nhelper

const TeamContactTypesAddModal = (props) => {
    const i18nHelper = useContext(I18nHelperContext);
    const spordleTable = useSpordleTable();
    const teamsContext = useContext(TeamsContext);

    return (
        <AnalyticsModal isOpen={props.addContactTypeModalOpen} toggle={props.toggleAddContactType} analyticsName='TeamContactTypesAddModal'>
            <Formik
                initialValues={{
                    ...i18nHelper.getInitialValues(),
                    active: "1",
                }}
                validationSchema={Yup.object().shape({
                    ...i18nHelper.getValidationSchema({ name: Yup.string().required(<Translate id='form.validation.name.required' />) }),
                    active: Yup.string().required(<Translate id='organization.settings.organizationTeamContactTypes.add.status.required' />),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    const newItem = values // data that will be sent back to table

                    const apiValues = { ...values, ...i18nHelper.getAPIValues(values) };
                    delete apiValues.i18n

                    teamsContext.createTeamContactType(apiValues)
                        .then((id) => {
                            const returnData = {
                                team_contact_type_id: id,
                                ...newItem,
                            }

                            spordleTable.addRow(returnData).then(() => {
                                props.toggleAddContactType();
                            })
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                // setStatus(displayI18n('message', error.i18n, error.message, getGenericLocale()));
                            }
                        })
                        .finally(() => {
                            setSubmitting(false)
                        })
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalHeader toggle={props.toggleAddContactType}><Translate id='organization.settings.organizationTeamContactTypes.add.title' /></ModalHeader>
                                <ModalBody>
                                    <RenderI18nForm field={'name'}>
                                        {({ fieldName, fieldLabel }) => {
                                            return (
                                                <Row className='mb-3' key={fieldName}>
                                                    <Col md='12'>
                                                        <FormGroup>
                                                            <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                            <FormikInputText id={fieldName} name={fieldName} trim />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            )
                                        }}
                                    </RenderI18nForm>
                                    <Row>
                                        <Col md='12'>
                                            <FormGroup>
                                                <Label for='active' className='text-muted'><Translate id='organization.settings.organizationTeamContactTypes.add.status' /> <Required /></Label>
                                                <FormikSelect
                                                    id='active_TeamContactTypesAddModal'
                                                    name='active'
                                                    search={false}
                                                    loadingStatus='success'
                                                    defaultData={[
                                                        { id: 'active', label: 'organization.settings.organizationTeamContactTypes.add.status.active', translateLabel: true, value: '1', selected: formik.values.active == 1 },
                                                        { id: 'inactive', label: 'organization.settings.organizationTeamContactTypes.add.status.inactive', translateLabel: true, value: '0', selected: formik.values.active == 0 },
                                                    ]}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                        <CustomAlert
                                            color='danger'
                                            className='mt-3 mb-0'
                                            text={formik.status}
                                            translateText={false}
                                            toggle={() => formik.setStatus(false)}
                                        />
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type='button' outline onClick={props.toggleAddContactType} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    );
}

export default TeamContactTypesAddModal;