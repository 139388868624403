import OnlineStoreRegistrationList from './components/OnlineStoreRegistrationsList';

const OnlineStoreRegistrations = (props) => {

    return (
        <>
            {/* <OnlineStoreRegistrationDetails /> */}
            <OnlineStoreRegistrationList {...props} />
        </>
    );
}

export default OnlineStoreRegistrations;