import { useState } from "react";
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal"
import MemberQualificationChoice from "./MemberQualificationChoice";
import MemberQualificationConfirm from "./MemberQualificationConfirm";
import MemberQualificationSearch from "./MemberQualificationSearch";

const MemberQualificationTransferShare = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal analyticsName='MemberQualificationTransferShare' isOpen={isOpen}>
            <MemberQualificationTransferShareInner {...props} />
        </AnalyticsModal>
    )
}

const MemberQualificationTransferShareInner = ({ toggle, memberQualification, spordleTable, closeSidePanel }) => {
    const [ currentStep, setCurrentStep ] = useState(0);
    const [ updateMode, setUpdateMode ] = useState('');
    const [ selectedMember, setSelectedMember ] = useState(null);

    return (
        <>
            <MemberQualificationSearch
                toggle={toggle}
                isOpen={currentStep === 0}
                selectMember={(member) => {
                    setSelectedMember(member);
                    setCurrentStep(1);
                }}
            />
            <MemberQualificationChoice
                toggle={toggle}
                isOpen={currentStep === 1}
                next={(mode) => { setCurrentStep(2); setUpdateMode(mode); }}
                back={() => { setCurrentStep(0); }}
                member={selectedMember}
                memberQualification={memberQualification}
            />
            <MemberQualificationConfirm
                toggle={() => {
                    if(updateMode === 'TRANSFER'){
                        spordleTable.deleteRow(memberQualification.member_qualification_id).then(toggle).then(closeSidePanel)
                    }else{
                        toggle();
                        closeSidePanel();
                    }
                }}
                isOpen={currentStep === 2}
                member={selectedMember}
                qualificationMove={updateMode}
            />
        </>
    )
}

export default MemberQualificationTransferShare
