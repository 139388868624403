import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import Translate, { DateFormat } from "@spordle/intl-elements";
import React from "react";
import {
    Button, Col, FormGroup, Label, Nav,
    NavItem,
    NavLink, Row, TabContent,
    TabPane
} from "reactstrap";
import { mutate } from "swr";
import { number, object, string } from "yup";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import FormikEditable from "../../../../../components/formik/FormikEditable";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import { fail, success } from '@spordle/toasts';
import { I18nContext } from "../../../../../contexts/I18nContext";
import { RolesContext } from "../../../../../contexts/RolesContext";
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import { displayI18n, DisplayI18n } from '../../../../../helpers/i18nHelper';
import withContexts from '../../../../../helpers/withContexts';
import TeamRosterSidePanelAttendees from "../components/list/TeamRosterSidePanelAttendees";
import TeamRosterSidePanelQualifications from "../components/list/TeamRosterSidePanelQualifications";
import TeamCommentsSidepanel from "../components/TeamCommentsSidepanel";
import TeamHistorySidepanel from "../components/TeamHistorySidepanel";
import TeamRosterRegistrationDate from "../components/TeamRosterRegistrationDate";
import TeamRosterSidepanelHeader from "../components/TeamRosterSidepanelHeader";
import TeamInactiveRosterReleaseDate from "./TeamInactiveRosterReleaseDate";

class TeamInactiveRosterSidepanel extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            activeTab: '1',
            teamMemberStatus: null,
        }
    }

    componentDidMount(){
        this.setState(() => ({ isLoading: true }))

        this.props.TeamsContext.getTeamMemberStatus({ active: '1', available_for: 'PLAYER' })
            .then((teamMemberStatus) => {
                this.setState(() => ({
                    isLoading: false,
                    teamMemberStatus: teamMemberStatus,
                }))
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({
                        isLoading: false,
                        teamMemberStatus: [],
                    }))
                }
            })
        this.props.TeamsContext.getTeamMemberStatus({ active: '1', available_for: 'STAFF' })
            .then((teamMemberStaffStatus) => {
                this.setState(() => ({
                    isLoading: false,
                    teamMemberStaffStatus: teamMemberStaffStatus,
                }))
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({
                        isLoading: false,
                        teamMemberStaffStatus: [],
                    }))
                }
            })
    }

    activatePlayer = () => {
        this.setState(() => ({ isLoading: true }))
        this.props.TeamsContext.updateTeamMember(this.props.TeamsContext.cachedTeam.team_id, this.props.selectedRows[0].team_member_id, {
            active: 1,
        }, true)
            .then(() => {
                mutate([ 'getTeamsSettings', this.props.TeamsContext.cachedTeam.team_id ]);
                this.props.toggle();
                this.props.tableRef.refreshTable();
                success();
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                    this.showError(error);
                }
                this.setState(() => ({ isLoading: false }));

            })
    }

    validateJerseyNumber = (number, home = true) => {
        if(!this.props.uniqueJerseySetting)
            return true;
        return !this.props.tableRef.getData().some((teamMember) => teamMember.jersey_number && teamMember.jersey_number == number)
    }

    // Returns if the released player belongs in "ACTIVE, AFFILIATE or STAFF"
    getRosterType = () => {
        const teamMember = this.props.selectedRows[0];
        if(teamMember.position?.position_group?.position_type === 'PLAYER' && teamMember.affiliate == 0)
            return 'ACTIVE'
        else if(teamMember.position?.position_group?.position_type === 'PLAYER' && teamMember.affiliate == 1)
            return 'AFFILIATE'
        else if(teamMember.position?.position_group?.position_type === 'STAFF' || teamMember.position?.position_group?.position_type === 'COACH')
            return 'STAFF'
    }

    showError = (error) => {
        if(error.i18n){
            fail({
                msg: 'misc.error',
                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                skipInfoTranslate: true,
            })
        }else if(Array.isArray(error)){
            fail({
                msg: 'misc.error',
                info: <ul className='mb-0' style={{ 'paddingInlineStart': "15px" }}>{error.map((err) => <li key={err.code}>{displayI18n('message', err.i18n, err.message, this.props.I18nContext.getGenericLocale)}</li>)}</ul>,
                skipInfoTranslate: true,
            })
        }else{
            switch (error.code){
                case '3371':// Team setting related error
                case '3372':
                case '3373':
                case '3374':
                case '3375':
                case '3376':
                case '3380':
                case '3438':
                case '3494':
                    fail({ info: <Translate id={`teams.profile.settings.error.${error.code}`} values={{ data: error.origin }} />, skipInfoTranslate: true });
                    break;
                default:
                    fail();
                    break;
            }
        }
    }

    /*--------------------------------------------------------------------------------*/
    /* Update the table row and sidepanel                                             */
    /* Do a GET specific after partial update then sync rows with new GET specific    */
    /* This keeps data live and prevents desync errors where F/E data != B/E data     */
    /*--------------------------------------------------------------------------------*/
    updateRowAndSidepanel = (teamMember, updateParams) => {
        this.setState(() => ({ isLoading: true }))
        this.props.TeamsContext.updateTeamMember(this.props.TeamsContext.cachedTeam.team_id, teamMember.team_member_id, updateParams, true)
            .then(() => {
                Promise.all([
                    this.props.TeamsContext.getTeamMembers(this.props.TeamsContext.cachedTeam.team_id, {
                        team_member_id: teamMember.team_member_id,
                    }),
                    this.props.TeamsContext.getTeam(this.props.TeamsContext.cachedTeam.team_id, true, { components: 'contact,address' }),
                ])
                    .then(([ team_members, team ]) => {
                        this.props.syncRows({ ...team_members[0], name: team_members[0].member?.first_name + ' ' + team_members[0].member?.last_name }); // name for table sorting
                        success();
                    })
            })
            .catch((errors) => {
                if(!AxiosIsCancelled(errors.message)){
                    console.error(errors.message);
                    this.showError(errors);
                }
            })
            .finally(() => this.setState(() => ({ isLoading: false })))
    }

    render(){
        const teamMember = this.props.selectedRows[0];
        const rosterType = this.getRosterType()
        const lockedRoster = this.props.getLockedRoster(rosterType)
        const canEdit = (!lockedRoster || this.props.RolesContext.canDoAction('EDIT', 'teams', 'bypass_lock_roster_date')) && this.props.RolesContext.canDoAction('EDIT', 'teams', 'team_members');
        const canEditTeamMemberStatus = (!lockedRoster || this.props.RolesContext.canDoAction('EDIT', 'teams', 'bypass_lock_roster_date')) && this.props.RolesContext.canDoAction('ADD', 'teams', 'teams_approbation');

        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <TeamRosterSidepanelHeader teamMember={teamMember} from='INACTIVE' rosterType={rosterType} lockedRoster={lockedRoster} />

                <Nav tabs>
                    <NavItem className="text-center" style={{ width: '33%' }}>
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.setState(() => ({ activeTab: '1' })) }}
                        >
                            <Translate id='teams.profile.roster.sidePanel.tabs.general' />
                        </NavLink>
                    </NavItem>
                    <NavItem className="text-center" style={{ width: '33%' }}>
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.setState(() => ({ activeTab: '2' })) }}
                        >
                            <Translate id='teams.profile.roster.sidePanel.tabs.teamHistory' />
                        </NavLink>
                    </NavItem>
                    <NavItem className="text-center" style={{ width: '34%' }}>
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.setState(() => ({ activeTab: '3' })) }}
                        >
                            <Translate id='teams.profile.roster.sidePanel.teamMemberComments' />
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId='1'>
                        {(!lockedRoster || this.props.RolesContext.canDoAction('EDIT', 'teams', 'bypass_lock_roster_date')) &&
                            <CanDoAction action='EDIT' componentCode='teams' componentPermissionCode='activate_player' className='p-3 border-bottom d-flex justify-content-center'>
                                <Button color='primary' outline onClick={this.activatePlayer}>
                                    <Translate id='teams.profile.roster.sidePanel.actions.setAsActive' />
                                </Button>
                            </CanDoAction>
                        }
                        <div className="p-3">
                            {teamMember.position?.position_group?.position_type === 'PLAYER' ?
                                <Row>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='form.fields.dateOfBirth' /></div>
                                            <div className="font-medium text-dark"><DateFormat value={teamMember.member.birthdate} utc /></div>
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='teams.profile.roster.table.position' /></div>
                                            <div className="font-medium text-dark">
                                                {teamMember.position ?
                                                    <DisplayI18n field='name' defaultValue={teamMember.position.name} i18n={teamMember.position.i18n} />
                                                    : '-'}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='jerseyNumberHome' className='text-muted mb-0 text-truncate'><Translate id='teams.profile.roster.sidePanel.actions.jerseyHome' /></Label>
                                            <FormikEditable
                                                disabled={!canEdit}
                                                id='jerseyNumberHome'
                                                initialValues={{
                                                    jerseyNumberHome: parseInt(teamMember.jersey_number) >= 0 ? teamMember.jersey_number : '',
                                                }}
                                                validationSchema={object().shape({
                                                    jerseyNumberHome: number().typeError(<Translate id="form.validation.number" />)
                                                        .test({
                                                            name: 'requirePositive',
                                                            message: <Translate id="form.validation.number.positive" />,
                                                            test: (number) => {
                                                                const isMinusZero = (value) => (1 / value === -Infinity)
                                                                if(number === '' || number === undefined)return true // let empty value pass
                                                                return parseInt(number) >= 0 && !isMinusZero(number) && Math.sign(number).toString() !== "-1" // prevent -0
                                                            },
                                                        })
                                                        .test({
                                                            name: 'uniqueJerseyNumber',
                                                            message: <Translate id="teams.profile.roster.sidePanel.jerseyNumber.error.unique" />,
                                                            test: (number) => {
                                                                return this.validateJerseyNumber(number, true)
                                                            },
                                                        }),
                                                })}
                                                onSubmit={(values) => {
                                                    if(values.jerseyNumberHome !== teamMember.jersey_number){
                                                        this.setState(() => ({ isLoading: true }))
                                                        const newValues = this.props.createNewValues({ jersey_number: values.jerseyNumberHome })
                                                        this.props.TeamsContext.updateTeamMember(this.props.TeamsContext.cachedTeam.team_id, teamMember.team_member_id, {
                                                            jersey_number: values.jerseyNumberHome,
                                                        }, true)
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                            }).catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    this.showError(error);
                                                                }
                                                            })
                                                            .finally(() => this.setState(() => ({ isLoading: false })))
                                                    }
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark'>{parseInt(teamMember.jersey_number) >= 0 ? teamMember.jersey_number : '-'}</div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikInputText autoFocus autoComplete="new-password" id='jerseyNumberHome' name='jerseyNumberHome' />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='jerseyNumberAway' className='text-muted mb-0 text-truncate'><Translate id='teams.profile.roster.sidePanel.actions.jerseyAway' /></Label>
                                            <FormikEditable
                                                disabled={!canEdit}
                                                id='jerseyNumberAway'
                                                initialValues={{
                                                    jerseyNumberAway: parseInt(teamMember.jersey_number_alternate) >= 0 ? teamMember.jersey_number_alternate : '',
                                                }}
                                                validationSchema={object().shape({
                                                    jerseyNumberAway: number().typeError(<Translate id="form.validation.number" />)
                                                        .test({
                                                            name: 'requirePositive',
                                                            message: <Translate id="form.validation.number.positive" />,
                                                            test: (number) => {
                                                                const isMinusZero = (value) => (1 / value === -Infinity)
                                                                if(number === '' || number === undefined)return true // let empty value pass
                                                                return parseInt(number) >= 0 && !isMinusZero(number) && Math.sign(number).toString() !== "-1" // prevent -0
                                                            },
                                                        })
                                                        .test({
                                                            name: 'uniqueJerseyNumber',
                                                            message: <Translate id="teams.profile.roster.sidePanel.jerseyNumber.error.unique" />,
                                                            test: (number) => {
                                                                return this.validateJerseyNumber(number, false)
                                                            },
                                                        }),
                                                })}
                                                onSubmit={(values) => {
                                                    if(values.jerseyNumberAway !== teamMember.jersey_number_alternate){
                                                        this.setState(() => ({ isLoading: true }))
                                                        const newValues = this.props.createNewValues({ jersey_number_alternate: values.jerseyNumberAway })
                                                        this.props.TeamsContext.updateTeamMember(this.props.TeamsContext.cachedTeam.team_id, teamMember.team_member_id, {
                                                            jersey_number_alternate: values.jerseyNumberAway,
                                                        }, true)
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                            }).catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    this.showError(error);
                                                                }
                                                            })
                                                            .finally(() => this.setState(() => ({ isLoading: false })))
                                                    }
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        if(parseInt(teamMember.jersey_number_alternate) >= 0){
                                                            return (
                                                                <div className='font-medium text-dark'>{parseInt(teamMember.jersey_number_alternate) >= 0 ? teamMember.jersey_number_alternate : '-'}</div>
                                                            )
                                                        }
                                                        return (
                                                            <div className='font-medium text-muted'>{parseInt(teamMember.jersey_number) >= 0 ? teamMember.jersey_number : '-'}</div>
                                                        )

                                                    }
                                                    return (
                                                        <FormikInputText autoFocus autoComplete="new-password" id='jerseyNumberAway' name='jerseyNumberAway' placeholder={teamMember.jersey_number} translatePlaceholder={false} />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </FormGroup>
                                    </Col>
                                    {/* <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted text-truncate"><Translate id='teams.profile.roster.sidePanel.actions.jerseyHome'/></div>
                                            <div className='font-medium text-dark'>{teamMember.jersey_number || '-'}</div>
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted text-truncate"><Translate id='teams.profile.roster.sidePanel.actions.jerseyAway'/></div>
                                            {teamMember.jersey_number_alternate ?
                                                <div className='font-medium text-dark'>{teamMember.jersey_number_alternate}</div>
                                            :
                                                <div className='font-medium text-muted'>{teamMember.jersey_number || '-'}</div>
                                            }
                                        </div>
                                    </Col> */}
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='teams.profile.roster.sidePanel.actions.teamMemberStatus.player' /></div>
                                            <FormikEditable
                                                disabled={!canEditTeamMemberStatus}
                                                id='teamMemberStatus'
                                                noConfirmation
                                                initialValues={{
                                                    teamMemberStatus: teamMember.team_member_status?.team_member_status_id,
                                                }}
                                                validationSchema={object().shape({
                                                    teamMemberStatus: string(),
                                                })}
                                                onSubmit={(values) => {
                                                    if(values.teamMemberStatus !== teamMember.team_member_status?.team_member_status_id){
                                                        this.updateRowAndSidepanel(teamMember, {
                                                            team_member_status_id: values.teamMemberStatus,
                                                        })
                                                    }
                                                }}
                                            >
                                                {(isEditing, options) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark'>
                                                                {teamMember.team_member_status ?
                                                                    <DisplayI18n field='name' defaultValue={teamMember.team_member_status.name} i18n={teamMember.team_member_status.i18n} />
                                                                    : '-'}
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikSelect
                                                            clearable
                                                            name='teamMemberStatus' id='teamMemberStatus'
                                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                            autoFocus menuIsDefaultOpen
                                                            onOptionSelected={options.stopEditing}
                                                            isLoading={!this.state.teamMemberStatus}
                                                            searchKeys={[
                                                                `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                            ]}
                                                            options={this.state.teamMemberStatus?.map((stat) => ({
                                                                value: stat.team_member_status_id,
                                                                i18n: stat.i18n,
                                                                label: stat.name,
                                                            }))}
                                                        />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='teams.profile.roster.sidePanel.actions.addedToTeam' /></div>
                                            <TeamRosterRegistrationDate
                                                teamMember={teamMember}
                                                hasPermissionToEdit={canEdit}
                                                updateRowAndSidepanel={this.updateRowAndSidepanel}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="12">
                                        <div className="mb-3">
                                            <TeamInactiveRosterReleaseDate
                                                teamMember={teamMember}
                                                startLoading={() => this.setState(() => ({ isLoading: true }))}
                                                removeLoading={() => this.setState(() => ({ isLoading: false }))}
                                                hasPermissionToEdit={canEdit}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='teams.profile.roster.table.tryout' /></div>
                                            <div className='font-medium text-dark'>
                                                {teamMember.tryout == 1 ?
                                                    <div className='font-medium text-dark'><i className="mdi mdi-check text-success" /> <Translate id='misc.yes' /></div>
                                                    :
                                                    <div className='font-medium text-dark'><i className="mdi mdi-close text-danger" /> <Translate id='misc.no' /></div>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='teams.profile.roster.table.imported' /></div>
                                            <div className="font-medium text-dark">
                                                {teamMember.import == 1 ?
                                                    <><i className="mdi mdi-check text-success" /> <Translate id='misc.yes' /></>
                                                    :
                                                    <><i className="mdi mdi-close text-danger" /> <Translate id='misc.no' /></>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='teams.profile.roster.table.affiliated' /></div>
                                            <div className="font-medium text-dark">
                                                {teamMember.affiliate == 1 ?
                                                    <><i className="mdi mdi-check text-success" /> <Translate id='misc.yes' /></>
                                                    :
                                                    <><i className="mdi mdi-close text-danger" /> <Translate id='misc.no' /></>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    {/* {teamMember.affiliated &&
                                        <Col sm="12">
                                            <div className="mb-3">
                                                <div className="text-muted">Affiliated Document</div>
                                                <button className="reset-btn text-link">{affiliatedDocument || '-'}</button>
                                            </div>
                                        </Col>
                                    } */}
                                </Row>
                                : // STAFF
                                <Row>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='teams.profile.roster.table.position' /></div>
                                            <div className="font-medium text-dark">
                                                {teamMember.position ?
                                                    <DisplayI18n field='name' defaultValue={teamMember.position.name} i18n={teamMember.position.i18n} />
                                                    : '-'}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='teams.profile.roster.sidePanel.actions.teamMemberStatus.staff' /></div>
                                            <div className='font-medium text-dark'>
                                                <FormikEditable
                                                    disabled={!canEditTeamMemberStatus}
                                                    id='teamMemberStatus'
                                                    noConfirmation
                                                    initialValues={{
                                                        teamMemberStatus: teamMember.team_member_status?.team_member_status_id,
                                                    }}
                                                    validationSchema={object().shape({
                                                        teamMemberStatus: string(),
                                                    })}
                                                    onSubmit={(values) => {
                                                        if(values.teamMemberStatus !== teamMember.team_member_status?.team_member_status_id){
                                                            this.setState(() => ({ isLoading: true }))
                                                            const teamMemberStatusState = this.state.teamMemberStaffStatus.find((t) => t.team_member_status_id === values.teamMemberStatus)
                                                            const newValues = this.props.createNewValues({
                                                                team_member_status: teamMemberStatusState || null,
                                                                status: teamMemberStatusState ? teamMemberStatusState.status_type : "PENDING",
                                                            })
                                                            this.props.TeamsContext.updateTeamMember(this.props.TeamsContext.cachedTeam.team_id, teamMember.team_member_id, {
                                                                team_member_status_id: values.teamMemberStatus,
                                                            }, true)
                                                                .then(() => {
                                                                    this.props.syncRows(newValues);
                                                                    success();
                                                                }).catch((error) => {
                                                                    if(!AxiosIsCancelled(error.message)){
                                                                        console.error(error.message)
                                                                        this.showError(error);
                                                                    }
                                                                })
                                                                .finally(() => this.setState(() => ({ isLoading: false })))
                                                        }
                                                    }}
                                                >
                                                    {(isEditing, options) => {
                                                        if(!isEditing){
                                                            return (
                                                                <div className='font-medium text-dark'>
                                                                    {teamMember.team_member_status ?
                                                                        <DisplayI18n field='name' defaultValue={teamMember.team_member_status.name} i18n={teamMember.team_member_status.i18n} />
                                                                        : '-'}
                                                                </div>
                                                            )
                                                        }
                                                        return (
                                                            <FormikSelect
                                                                clearable
                                                                name='teamMemberStatus' id='teamMemberStatus'
                                                                renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                                autoFocus menuIsDefaultOpen
                                                                searchKeys={[
                                                                    `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                                ]}
                                                                onOptionSelected={options.stopEditing}
                                                                isLoading={!this.state.teamMemberStaffStatus}
                                                                options={this.state.teamMemberStaffStatus?.map((stat) => ({
                                                                    label: stat.name,
                                                                    i18n: stat.i18n,
                                                                    value: stat.team_member_status_id,
                                                                }))}
                                                            />
                                                        )

                                                    }}
                                                </FormikEditable>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="6" className="mb-3">
                                        <div className="text-muted"><Translate id='teams.profile.roster.sidePanel.actions.addedToTeam' /></div>
                                        <TeamRosterRegistrationDate
                                            teamMember={teamMember}
                                            hasPermissionToEdit={canEdit}
                                            updateRowAndSidepanel={this.updateRowAndSidepanel}
                                        />
                                    </Col>
                                    <Col sm="12" className="mb-3 font-medium text-dark">
                                        <TeamInactiveRosterReleaseDate
                                            teamMember={teamMember}
                                            startLoading={() => this.setState(() => ({ isLoading: true }))}
                                            removeLoading={() => this.setState(() => ({ isLoading: false }))}
                                            showError={this.showError}
                                            hasPermissionToEdit={canEdit}
                                        />
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col sm="12" className="mb-3">
                                    <TeamRosterSidePanelAttendees memberId={this.props.selectedRows[0].member?.member_id} />
                                </Col>
                                <Col sm="12">
                                    <TeamRosterSidePanelQualifications
                                        emptyMsg="No Qualifications"
                                        memberId={this.props.selectedRows[0].member?.member_id}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </TabPane>
                    <TabPane tabId='2' className='p-3'>
                        { this.state.activeTab == "2" &&
                            // Avoids useless api calls
                            <TeamHistorySidepanel teamMember={teamMember} sidePanelProps={this.props} />
                        }
                    </TabPane>
                    <TabPane tabId="3" className="p-3">
                        { this.state.activeTab == "3" &&
                            <TeamCommentsSidepanel teamMember={teamMember} />
                        }
                    </TabPane>
                </TabContent>
            </OverlayLoader>
        )
    }
}

export default withContexts(TeamsContext, RolesContext, I18nContext)(TeamInactiveRosterSidepanel);