export default{
    // ONLY ADD GENERAL FIELDS
    // Example of a general field   : First Name (can be used for attendees, instructors, users, etc.)
    // Example of a specific field  : Organization Name (only used in organization)

    // Validation
    "form.required" : "Required field",
    "form.min"      : "Please respsect the minimum value",
    "form.max"      : "Please respect the maximum value",

    // Fields
    "form.fields.uniqueIdentifier"                 : "{unique_identifier}",
    "form.fields.forms"                            : "Questionnaires",
    "form.fields.firstName"                        : "First name",
    "form.fields.lastName"                         : "Last name",
    "form.fields.name"                             : "Name",
    "form.fields.name.lang"                        : "Name ({lang})",
    "form.fields.title"                            : "Title",
    "form.fields.title.lang"                       : "Title ({lang})",
    "form.fields.short_name"                       : "Short name",
    "form.fields.short_name.lang"                  : "Short name ({lang})",
    "form.fields.description"                      : "Description",
    "form.fields.description.lang"                 : "Description ({lang})",
    "form.fields.price"                            : "Price",
    "form.fields.memo"                             : "Memo",
    "form.fields.order"                            : "Order",
    "form.fields.displayOrder"                     : "Display Order",
    "form.fields.email"                            : "Email",
    "form.fields.document"                         : "Document",
    "form.fields.documents"                        : "Document(s)",
    "form.fields.amount"                           : "Amount",
    "form.fields.fees"                             : "Fees",
    "form.fields.phone"                            : "Phone number",
    "form.fields.extension"                        : "Extension",
    "form.fields.mobilePhone"                      : "Cellphone",
    "form.fields.phoneType"                        : "Phone type",
    "form.fields.activityPeriod"                   : "Activity Period",
    "form.fields.activityPeriod.required"          : "Activity period is required",
    "form.fields.dateOfBirth"                      : "Date of birth",
    "form.fields.date.helper"                      : "YYYY-MM-DD",
    "form.fields.date"                             : "Date",
    "form.fields.gender"                           : "Gender Identity",
    "form.fields.gender.placeholder"               : "Select a gender identity",
    "form.fields.genderDescription"                : "Gender Identification",
    "form.fields.genderDescription.required"       : "Please specify the gender you identify as",
    "form.fields.item.type.registration"           : "Registration",
    "form.fields.item.type.affiliation"            : "Affiliation",
    "form.fields.item.type.clinic"                 : "Clinic",
    "form.fields.gender.male"                      : "Male",
    "form.fields.gender.female"                    : "Female",
    "form.fields.gender.other"                     : "Prefer not to say",
    "form.fields.gender.mixed"                     : "Mixed",
    "form.fields.gender.prefer_not_to_say"         : "Prefer not to say",
    "form.fields.gender.prefer_to_self_describe"   : "Prefer to self-describe",
    "form.fields.birth_country"                    : "Birth Country",
    "form.fields.birth_country.placeholder"        : "Select a birth country",
    "form.fields.streetNumber"                     : "Street Number",
    "form.fields.address"                          : "Address",
    "form.fields.address2.placeholder"             : "Apartment, suite, unit, floor, building, etc.",
    "form.fields.addressType"                      : "Address Type",
    "form.fields.city"                             : "City",
    "form.fields.country"                          : "Country",
    "form.fields.state"                            : "State",
    "form.fields.number"                           : "Number",
    "form.fields.province"                         : "Province",
    "form.fields.zip"                              : "Postal Code",
    "form.fields.language"                         : "Language",
    "form.fields.primaryLanguage"                  : "Primary Language",
    "form.fields.primaryLanguage.placeholder"      : "Select a language",
    "form.fields.secondaryLanguages"               : "Secondary Language",
    "form.fields.nationality"                      : "Citizenship",
    "form.fields.nationality.placeholder"          : "Select a citizenship",
    "form.fields.nationality.selected"             : "{count} selected citizenships",
    "form.fields.nationality.CA"                   : "Canadian",
    "form.fields.nationality.US"                   : "American",
    "form.fields.lastConnection"                   : "Last connection",
    "form.fields.general"                          : "General",
    "form.fields.generalInfo"                      : "General Information",
    "form.fields.additionalInfo"                   : "Demographic Information",
    "form.fields.status"                           : "Status",
    "form.fields.method"                           : "Method",
    "form.fields.comment"                          : "Comment",
    "form.fields.team_status"                      : "Team Status",
    "form.fields.period"                           : "Period",
    "form.fields.period.required"                  : "Period is required",
    "form.fields.years_same_address"               : "Move in year",
    "form.fields.identifyAsIndigenous"             : "Identify as Indigenous",
    "form.fields.identifyAsIndigenous.required"    : "Please specify if you identify as Indigenous.",
    "form.fields.indigenousGroup"                  : "Indigenous Group",
    "form.fields.indigenousGroup.required"         : "Indigenous group is required.",
    "form.fields.ethnicity"                        : "Ethnicity",
    "form.fields.ethnicity.required"               : "Ethnicity is required.",
    "form.fields.note"                             : "Note",
    "form.fields.ethnicityNote"                    : "Ethnicity Description",
    "form.fields.ethnicityNote.required"           : "Description is required.",
    "form.fields.content.lang"                     : "Content ({lang})",
    "form.fields.custom_link.lang"                 : "Custom Link ({lang})",
    "form.fields.video_id.lang"                    : "Video Identifier ({lang})",
    "form.fields.video_link.lang"                  : "Video Link ({lang})",
    "form.fields.visible_on_website"               : "Visible on website",
    "form.fields.email_visible_on_website"         : "Email visible on website",
    "form.fields.phone_visible_on_website"         : "Phone visible on website",
    "form.fields.term"                             : "Term",
    "form.fields.term.lang"                        : "Term ({lang})",
    "form.fields.reason"                           : "Reason",
    "form.fields.excludeAffiliationFee"            : "Exclude Affiliation Fee",
    "form.fields.excludeAffiliationFee.required"   : "Exclude Affiliation Fee is required",
    "form.fields.identityGroupIndigenous"          : "Indigenous Group Description",
    "form.fields.identityGroupIndigenous.required" : "Please specifiy your group identification",


    "form.fields.waiver.required"           : "Please select an option.",
    "form.fields.waiver.defaultAccept"      : "I have read and accept the waiver",
    "form.fields.waiver.doNotSign"          : "Skip waiver",
    "form.fields.waiver.notMandatory"       : "This waiver is not mandatory",
    "form.fields.waiver.mandatory"          : "This waiver is mandatory",
    "form.fields.waiver.clear"              : "Clear selected",
    "form.fields.waiver.signature.required" : "The agreement type is required.",

    // Canada
    "form.fields.country.ca"  : "Canada",
    "form.fields.state.ca.ab" : "Alberta",
    "form.fields.state.ca.bc" : "British Columbia",
    "form.fields.state.ca.pe" : "Prince Edward Island",
    "form.fields.state.ca.mb" : "Manitoba",
    "form.fields.state.ca.nb" : "New Brunswick",
    "form.fields.state.ca.ns" : "Nova Scotia",
    "form.fields.state.ca.on" : "Ontario",
    "form.fields.state.ca.qc" : "Quebec",
    "form.fields.state.ca.sk" : "Saskatchewan",
    "form.fields.state.ca.nl" : "Newfoundland and Labrador",
    "form.fields.state.ca.nu" : "Nunavut",
    "form.fields.state.ca.nt" : "Northwest Territories",
    "form.fields.state.ca.yt" : "Yukon",

    // Error messages (Yup)
    "form.validation.string.max"                  : "The characters count cannot exceed {count}",
    "form.validation.status.required"             : "The status is required",
    "form.validation.field.required"              : "{field} is required",
    "form.validation.firstName.required"          : "First name is required",
    "form.validation.lastName.required"           : "Last name is required",
    "form.validation.name.required"               : "Name is required",
    "form.validation.name.lang.required"          : "Name ({lang}) is required",
    "form.validation.title.required"              : "Title is required",
    "form.validation.description.required"        : "Description is required",
    "form.validation.description.lang.required"   : "Description ({lang}) is required",
    "form.validation.email.required"              : "Email is required",
    "form.validation.email.valid"                 : "Email must be valid",
    "form.validation.phone.required"              : "Phone number is required",
    "form.validation.phone.valid"                 : "Phone number must be valid",
    "form.validation.phoneType.required"          : "Phone type is required",
    "form.validation.dateOfBirth.required"        : "Date of birth is required",
    "form.validation.dateOfBirth.valid"           : "Date of birth must be of a valid format",
    "form.validation.gender.required"             : "Gender identity is required",
    "form.validation.address.required"            : "Address is required",
    "form.validation.streetNumber.required"       : "Street number is required",
    "form.validation.POBox.required"              : "P.O. Box is required",
    "form.validation.addressType.required"        : "Address Type is required",
    "form.validation.years_same_address.required" : "Move-in year is required",
    "form.validation.years_same_address.tooltip"  : "The year in which the participant moved to the current address.",
    "form.validation.city.required"               : "City is required",
    "form.validation.country.required"            : "Country is required",
    "form.validation.state.required"              : "State is required",
    "form.validation.province.required"           : "Province is required",
    "form.validation.zip.required"                : "Postal code is required",
    "form.validation.zip.valid"                   : "Postal code must be valid",
    "form.validation.primaryLanguage.required"    : "Primary language is required",
    "form.validation.birth_country.required"      : "Birth country is required",
    "form.validation.nationality.required"        : "Citizenship is required",
    "form.validation.ethnicity.required"          : "Ethnicity is required",
    "form.validation.language.required"           : "Language is required",
    "form.validation.order.minimum"               : "Order number must be 0 or higher",
    "form.validation.order.required"              : "Order number is required",
    "form.validation.price.required"              : "Price is required",
    "form.validation.number"                      : "This value must be a number",
    "form.validation.number.positive"             : "This value must be a positive number",
    "form.validation.url.format"                  : "Invalid url format",
    "form.validation.displayOrder.required"       : "Display Order required",
    "form.validation.note.required"               : "Note is required",

    "form.cart.required"          : "Field is required",
    "form.cart.date.before.today" : "Date must be before today",

    "form.validation.date.required"    : "Date is required",
    "form.validation.date.placeholder" : "YYYY-MM-DD",
    "form.validation.date.format"      : "Date must be of a valid format",
    "form.validation.time.format"      : "Time must be of a valid format",

    "form.validation.atLeastOneField" : "At least one field must be filled",

    "form.manualRegistration.print.msg"  : "Preparing print",
    "form.manualRegistration.print.info" : "We are preparing the printable version of the registration form",
}