import Translate from "@spordle/intl-elements";

const DuplicateMatchScore = ({ duplicate }) => {
    const color = duplicate.score === "EXACT" ? "success" : duplicate.score === "PARTIAL" ? "info" : "light";

    return (
        <div className={`text-${color}`}>
            <div className="small text-muted">
                <Translate id="tasks.memberDuplicates.potentialDuplicates.match" />
            </div>
            <Translate
                id={`tasks.memberDuplicates.potentialDuplicates.match.${duplicate.score}`}
                defaultMessage={duplicate.score}
            />
        </div>
    );
}

export default DuplicateMatchScore;