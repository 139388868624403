import { stringBuilder } from "@spordle/helpers";
import PropTypes from 'prop-types';

/**
 * Component used for SidePanel Subtitles
 * @param {Object} props
 * @param {string|import("react").ReactElement} [props.tag=div]
 * @returns {JSX.Element}
 */
const SidePanelSubtitle = ({ tag: Tag = 'div', ...props }) => <Tag {...props} className={stringBuilder('small', props.className)} />;

export default SidePanelSubtitle;

SidePanelSubtitle.propTypes = {
    tag: PropTypes.oneOfType([ PropTypes.string, PropTypes.elementType ]),
}

SidePanelSubtitle.defaultProps = {
    tag: "div",
}