export default{
    "components.assignOfficiatingPermissionsModal.officiatingCoach"            : "Superviseur d'officiel",
    "components.assignOfficiatingPermissionsModal.btn"                         : "Gestion de supervision d'officiels",
    "components.assignOfficiatingPermissionsModal.removeAccess.btn"            : "Retirer les accès",
    "components.assignOfficiatingPermissionsModal.title"                       : "Gestion des permissions de superviseur d'officiels",
    "components.assignOfficiatingPermissionsModal.msg"                         : "Gestion des permissions de superviseur d'officiels. Il est possible de donner accès à divers types de supervision en les sélectionnant ci-dessous.",
    "components.assignOfficiatingPermissionsModal.supervisionTypes.selected"   : "Type(s) de supervision sélectionnée(s)",
    "components.assignOfficiatingPermissionsModal.supervisionTypes.count"      : "{count, plural, =0 {# supervision sélectionnée} one {# supervision sélectionnée} other {# supervisions sélectionnées}}",
    "components.assignOfficiatingPermissionsModal.supervisionTypes"            : "Types de supervision",
    "components.assignOfficiatingPermissionsModal.supervisionTypes.validation" : "Veuillez sélectionner au moins un type de supervision",
    "components.assignOfficiatingPermissionsModal.isNational"                  : "Est un superviseur d'officiels national",
    "components.assignOfficiatingPermissionsModal.isNotNational"               : "N'est pas un superviseur d'officiels national",
    "components.assignOfficiatingPermissionsModal.canSupervise"                : "Peut superviser ce type",
    "components.assignOfficiatingPermissionsModal.cannotSupervise"             : "Ne peut pas superviser ce type",
    "components.assignOfficiatingPermissionsModal.success.msg.edit"            : "Les divers accès du superviseur d'officiels ont été mis à jour:",
    "components.assignOfficiatingPermissionsModal.success.msg.create"          : "Les divers accès du superviseur d'officiels ont été créés",
    "components.assignOfficiatingPermissionsModal.success.delete"              : "Accès à la supervision d'officiels retirés",
    "components.assignOfficiatingPermissionsModal.success.delete.msg"          : "Tous les accès aux types de supervision ont été retirés du membre {name}, qui n'a plus le statut de superviseur d'officiels.",

    "components.assignOfficiatingPermissionsModal.delete.warning.title" : "Attention",
    "components.assignOfficiatingPermissionsModal.delete.warning"       : "En retirant tous les accès, le membre n'aura pas les droits de superviseur d'officiels.",
}