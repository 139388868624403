import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';
import { jsObjectToApi } from '@spordle/helpers';

/** @type {React.Context<Omit<MerchantAccountContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const MerchantAccountContext = createContext();
MerchantAccountContext.displayName = 'MerchantAccountContext';

class MerchantAccountContextProvider extends React.PureComponent{

    state = {
        merchantAccounts: false,
    }

    /**
     * Returns all the available merchant accounts for an organization
     * @param {string} organizationId ID of the organization to get merchant accounts from
     * @return {Promise<Array<Object>>}
     */
    getMerchantAccountsByOrganization = (organizationId, fromApi = false) => {
        if(this.state.merchant_accounts && !fromApi){
            return Promise.resolve(this.state.merchant_accounts);
        }
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/merchant-accounts`, query: { organisation_id: organizationId } }))
            .then((response) => {
                if(response.data.status){
                    this.setState(() => ({
                        merchantAccounts: response.data.merchant_accounts,
                    }));
                    return response.data.merchant_accounts;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Returns all the available merchant accounts
     * @param {object} organizationId ID of the organization to get merchant accounts from
     * @return {Promise<Object[]>}
     */
    getMerchantAccounts = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/merchant-accounts`,
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.merchant_accounts;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Partially update a merchant account
     * @param {string} merchantAccountId ID of the merchant account
     * @param {Object} values Object containing the values to update - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Merchant%20Account/Apicontroller%5COrganisations%5CMerchantaccounts%3A%3AupdateMerchantAccount|documentation}
     * @returns {Promise}
     */
    updateMerchantAccountPartial = (merchantAccountId, values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params);

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/merchant-accounts/${merchantAccountId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <MerchantAccountContext.Provider value={Object.assign(this.state, this)}>
                {this.props.children}
            </MerchantAccountContext.Provider>
        );
    }
}

export default withContexts(OrganizationContext)(MerchantAccountContextProvider);
