import Translate from "@spordle/intl-elements";
import moment from "moment";
import { useContext } from "react";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import QualificationsAttendeesListContainer from "./QualificationsAttendeesListContainer";

const TeamRosterSidePanelAttendees = ({ memberId }) => {
    const { getMemberAttendees } = useContext(MembersContext);
    const checkIfExpired = (date) => {
        return !date ? false : moment(date).isBefore(moment().add(1, 'day')); //moment(date).isBefore(moment().add(1, 'day'));
    }

    const sortAttendees = (attendees) => {
        return attendees
            .sort((a, b) => {
                const aExp = a.expiration_date;
                const bExp = b.expiration_date;
                if((checkIfExpired(aExp) && aExp) && (checkIfExpired(bExp) && bExp))return aExp < bExp ? -1 : 1
                if(checkIfExpired(aExp) && aExp)return 1
                if(checkIfExpired(bExp) && bExp)return -1

                return a.period.start_date > b.period.start_date ? -1 : a.period.start_date < b.period.start_date ? 1 : 0
            })
    }

    const formatAttendees = (attendees) => (
        sortAttendees(attendees.filter((attendee) => attendee.passed != "1").map((attendee) => ({
            ...attendee,
            member_qualification_id: attendee.clinic_attendee_id,
            qualification: attendee.clinic?.qualification,
            qualification_level: attendee.clinic?.qualification?.qualification_level,
            period: attendee.clinic.period || {},
        })))
    )

    return (
        <QualificationsAttendeesListContainer
            title={<Translate id='teams.profile.roster.sidePanel.attending' />}
            getList={(memberId) => getMemberAttendees(memberId).then(formatAttendees)}
            memberId={memberId}
        />
    )
}

export default TeamRosterSidePanelAttendees;