import SpordleTableProvider from "@spordle/datatables";
import { FormikDateTime } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Formik } from "formik";
import { useContext } from "react";
import { Button, Col, FormGroup, Label } from "reactstrap";
import { TeamsContext } from "../../../../../../../../contexts/TeamsContext";
import { SEARCH_BIRTHDATE } from "../../../TeamRosterAdd";
import { object, mixed } from "yup";
import TeamRosterSearchView from "../components/TeamRosterSearchView";
import Required from "../../../../../../../../components/formik/Required";
import moment from "moment";

const TeamRosterSearchBirthdate = ({ activeTab, preformatSubmit, getTableProps }) => {
    const { getAvailableTeamMembers } = useContext(TeamsContext);

    return (
        <SpordleTableProvider {...getTableProps(getAvailableTeamMembers)} id='roster-search-bday'>
            {({ filterChange }) => (
                <Formik
                    initialValues={{
                        ddbFrom: '',
                        ddbTo: '',
                    }}
                    validationSchema={object().shape({
                        ddbFrom: mixed().test({
                            name: 'isRequired',
                            message: <Translate id='teams.profile.teamRoster.activeRoster.add.validation.dateFrom' />,
                            test: function(bday){
                                return activeTab === SEARCH_BIRTHDATE ? moment.isMoment(bday) : true;
                            },
                        }),
                        ddbTo: mixed().test({
                            name: 'isMoment',
                            message: <Translate id='form.validation.date.format' />,
                            test: function(bday){
                                return bday ? moment.isMoment(bday) : true;
                            },
                        }),
                    })}
                    onSubmit={(values) => {
                        const newVal = preformatSubmit(values);

                        newVal.birthdate = newVal.ddbFrom.format('YYYY-MM-DD') + (moment.isMoment(newVal.ddbTo) ? ',' + newVal.ddbTo.format('YYYY-MM-DD') : '');

                        delete newVal.ddbFrom;
                        delete newVal.ddbTo;

                        filterChange('search', newVal);
                    }}
                >
                    {() => (
                        <TeamRosterSearchView>
                            <Col sm="3">
                                <FormGroup>
                                    <Label for='ddbFrom' className='text-muted'><Translate id='members.search.memberAdvanceSearch.filters.from' /> <Required /></Label>
                                    <FormikDateTime name='ddbFrom' id='ddbFrom' timeFormat={false} />
                                </FormGroup>
                            </Col>
                            <Col sm="3">
                                <FormGroup>
                                    <Label for='ddbTo' className='text-muted'><Translate id='members.search.memberAdvanceSearch.filters.to' /></Label>
                                    <FormikDateTime name='ddbTo' id='ddbTo' timeFormat={false} />
                                </FormGroup>
                            </Col>
                            <Col sm="3" className="align-self-end">
                                <FormGroup>
                                    <Button type="submit" block color="primary"><i className="ti-search" /> <Translate id='misc.search' /></Button>
                                </FormGroup>
                            </Col>
                        </TeamRosterSearchView>
                    )}
                </Formik>
            )}
        </SpordleTableProvider>
    )
}

export default TeamRosterSearchBirthdate;