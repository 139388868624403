import { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Fade
} from "reactstrap";
import SubmitTicket from './submitTicket/SubmitTicket';
import { HelpCenterContext } from '../../contexts/HelpCenterContext';
import Draggable from 'react-draggable';
import MobileFooterMenu from '../../layouts/layout-components/mobile/footer/menu/MobileFooterMenu';
import { stringBuilder } from '@spordle/helpers';
import { HelpCenterInner } from './HelpCenterInner';
import NotifBadge from '../../layouts/layout-components/header/notifBadge/NotifBadge';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { fail } from '@spordle/toasts';
import { DisplayI18n } from '../../helpers/i18nHelper';
import useSWR from 'swr';
import { useTour } from '@reactour/tour';
import { Tooltip } from '@mantine/core';
import Translate from '@spordle/intl-elements';

const HelpCenter = (props) => {
    const defaultPosition = { x: -340, y: 30 };

    const helpContext = useContext(HelpCenterContext);
    const orgContext = useContext(OrganizationContext);

    const [ draggablePosition, setDraggablePosition ] = useState(defaultPosition) // default starting position, set it here as backup also
    const [ submitTicket, setSubmitTicket ] = useState(false)
    const [ helpLoading, setHelpLoading ] = useState(false);
    const [ initialPos, setInitialPos ] = useState(null);
    const [ initialSize, setInitialSize ] = useState(null);

    const { isOpen: tourIsOpen, steps, currentStep } = useTour();
    const canShowHelp = !tourIsOpen || (steps[currentStep]?.highlightedSelectors || [])?.includes("#help-center-dropdown-toggle");

    /**
    * Resize help center functions
    */
    const initial = (e) => {
        const resizable = document.getElementById('help-center-resizable');
        setInitialPos({ x: e.screenX, y: e.screenY });
        setInitialSize(resizable.offsetWidth);
    }

    const resize = (e) => {
        const resizable = document.getElementById('help-center-resizable');
        const newWidth = parseInt(initialSize) + parseInt(e.screenX - initialPos.x);
        if(newWidth < 0 || newWidth > 2000) // patch bypass
            return false
        if(newWidth < 300)
            resizable.style.width = "300px"
        else if(newWidth > 800)
            resizable.style.width = "800px"
        else
            resizable.style.width = `${newWidth}px`
    }

    const newPath = props.location.pathname.split('/');
    let path = '';

    newPath.forEach((_part) => {
        if(_part.match(/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/)){
            path += '/{ID}';
        }else if(_part) path += '/' + _part
    })

    const helpUrl = path + props.location.hash;

    const { data: { helpCenter, helpCenterResources }, mutate } = useSWR(
        [ 'getCurrentHelpBubble', props.location, props.isOpen, orgContext.organisation_id ],
        () => {
            setHelpLoading(true);
            return helpContext.getHelpBubble({ page_url: helpUrl, organisation_id: orgContext.organisation_id })
                .then(({ helpBubble, helpBubbleResources }) => {
                    return {
                        helpCenter: helpBubble?.length > 0 ? helpBubble[0] : null,
                        helpCenterResources: helpBubbleResources,
                    };
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
                .finally(() => setHelpLoading(false))
        },
        {
            fallbackData: {},
        },
    );

    /**
    * this can be used to open the submitTicket modal with a default type
    * @param {string} [type=null]
    */
    const toggleSubmitTicket = (defaultType = null) => {

        setSubmitTicket((prev) => ({
            isOpen: !prev.isOpen,
            defaultType: defaultType,
        }))

    }

    return (
        <>
            <SubmitTicket isOpen={submitTicket.isOpen} toggle={toggleSubmitTicket} defaultType={submitTicket.defaultType} />

            {props.toggler ?
                props.toggler(props.toggle, props.isOpen)
                :
                <span id="help-center-dropdown" className={stringBuilder("help-center-tour-dropdown nav-link position-relative", { "show": props.isOpen })} onClick={props.toggle}>
                    {/* this is mobile view no touch */}
                    <div className="clickable position-relative" id="help-center-dropdown-toggle">
                        <div>
                            <Tooltip
                                withArrow
                                label={<Translate id='header.helpCenter.title' />}
                                zIndex={3000}
                            >
                                <i className="mdi mdi-help-circle-outline font-18" />
                            </Tooltip>
                        </div>
                    </div>
                    {!!helpCenter?.video_id &&
                            <NotifBadge>i</NotifBadge>
                    }
                </span>
            }

            {props.fullscreen ?
                <MobileFooterMenu innerClassName="p-0" handleToggle={props.toggle} isOpened={props.isOpen}>
                    <HelpCenterInner
                        fullscreen={props.fullscreen}
                        initial={initial}
                        resize={resize}
                        toggleMenu={props.toggle}
                        helpLoading={helpLoading}
                        helpCenter={helpCenter}
                        toggleSubmitTicket={() => { toggleSubmitTicket(); props.toggle(); }}
                        getHelpCenter={mutate}
                        helpCenterResources={helpCenterResources}
                    />
                </MobileFooterMenu>
                :
                <div style={{ zIndex: 999999, margin: "0.125rem", height: 0, width: 0 }}>
                    {/* height and width are necessary, this is the anchor for the bubble and otherwise it takes up space in the navbar */}
                    <Draggable
                        axis="both"
                        handle=".help-dropdown-menu-handle"
                        scale={1}
                        defaultPosition={defaultPosition} // To fit under header like the other dropdowns
                        bounds="body"
                        position={draggablePosition}
                        cancel=".help-center-resize-handle"
                        onStop={(_event, targetElement) => {
                            setDraggablePosition({ x: targetElement.x, y: targetElement.y })
                            // boundingRect contains information about the target elements height, width and position
                            const boundingRect = targetElement.node.getBoundingClientRect()
                            if(boundingRect.height + targetElement.y > window.innerHeight)
                                setDraggablePosition({ x: targetElement.x, y: window.innerHeight - boundingRect.height })
                        }}
                    >
                        <div id="help-dropdown-menu-container" className='help-dropdown-menu-handle'>
                            <Fade in={props.isOpen && canShowHelp} mountOnEnter unmountOnExit>
                                {/* probably toggle form here */}
                                <HelpCenterInner
                                    initial={initial}
                                    resize={resize} // this is a BONBON
                                    toggleMenu={props.toggle}
                                    helpLoading={helpLoading}
                                    helpCenter={helpCenter}
                                    toggleSubmitTicket={toggleSubmitTicket}
                                    getHelpCenter={mutate}
                                    helpCenterResources={helpCenterResources}
                                />
                            </Fade>
                        </div>
                    </Draggable>
                </div>
            }
        </>
    );
}

export default withRouter(HelpCenter);