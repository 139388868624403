export default{
    "components.transaction.editInstallmentsModal.header"                   : "Edit installments",
    "components.transaction.editInstallmentsModal.balance"                  : "Balance",
    "components.transaction.editInstallmentsModal.currentTotal"             : "Current Total",
    "components.transaction.editInstallmentsModal.invoiceTotal"             : "Invoice Total",
    "components.transaction.editInstallmentsModal.date.label"               : "Payment date",
    "components.transaction.editInstallmentsModal.date.validation.after"    : "La date doit être après aujourd'hui",
    "components.transaction.editInstallmentsModal.paymentMethod.label"      : "Payment method",
    "components.transaction.editInstallmentsModal.paymentMethod.required"   : "Payment method is required",
    "components.transaction.editInstallmentsModal.total.wrong"              : "Total of the installments should be {price, number, ::currency/CAD}",
    "components.transaction.editInstallmentsModal.warning.title"            : "Irreversible changes",
    "components.transaction.editInstallmentsModal.warning.subtitle1"        : "Every installment set to <b>0$</b> will change the payment method to a <b>manual payment method</b>.",
    "components.transaction.editInstallmentsModal.warning.subtitle2"        : "Every <b>cancelled</b> installment that is changed will be forced to <b>pending</b> and to a <b>manual payment method</b>.",
    "components.transaction.editInstallmentsModal.warning.subtitle3"        : "Every installment method changed from <b>credit card</b> to another method <b>cannot be reverted</b>.",
    "components.transaction.editInstallmentsModal.warning.subtitle.confirm" : "Please confirm to proceed.",
    "components.transaction.retryInstallmentBadge.tooltip"                  : "Retry payment",
}