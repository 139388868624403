import { stringifyUrl } from "query-string";
import API_SPORDLE from "../API-Spordle";
import { serverError } from "../CancellableAPI";

export function getMemberFields() {
    return API_SPORDLE.get(stringifyUrl({
        url: `/member-fields`,
        query: { active: 1 },
    }))
        .then((response) => {
            if(response.data.status){
                return response.data.member_fields;
            }
            throw response.data.errors[0]
        }, serverError)
}