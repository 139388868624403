import Translate from "@spordle/intl-elements";
import { Badge } from "reactstrap";

export const PaymentStatus = ({ payment, className, ...props }) => (
    !payment ?
        null
        :
        <Badge color={payment.status === 'COMPLETED' ? 'success' : (payment.status === 'PENDING' || payment.status === 'PARTIALLY_PAID') ? 'warning' : 'danger'} size="lg" pill>
            <Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.status.' + payment.status} />
        </Badge>
)