import memberAdvanceSearch from "./memberAdvanceSearch";
import memberSearch from "./memberSearch";
import sidePanelMemberSearch from "./sidePanelMemberSearch";
import memberAdd from "./memberAdd";
import memberMerge from "./memberMerge";

export default{
    ...memberSearch,
    ...sidePanelMemberSearch,
    ...memberAdd,
    ...memberMerge,
    ...memberAdvanceSearch,
}