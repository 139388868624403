export default{
    "registration.settings.class.sidePanelRegistrationClasses.title" : "Class Information",

    "registration.settings.class.sidePanelRegistrationClasses.status"          : "Status",
    "registration.settings.class.sidePanelRegistrationClasses.status.active"   : "Active",
    "registration.settings.class.sidePanelRegistrationClasses.status.inactive" : "Inactive",
    "registration.settings.class.sidePanelRegistrationClasses.status.required" : "Status is required",

    "registration.settings.class.sidePanelRegistrationClasses.update.success.text" : "Class updated",
    "registration.settings.class.sidePanelRegistrationClasses.update.fail.text"    : "An error occurred during the Class update, please refresh the page et try again",

    // actions
    "registration.settings.class.sidePanelRegistrationClasses.actions.remove"         : "Remove",
    "registration.settings.class.sidePanelRegistrationClasses.actions.remove.topText" : "You are about to remove this Class from your Organization",

    // tabs
    "registration.settings.class.sidePanelRegistrationClasses.tabs.details" : "Details",
}