export default{
    // status
    "memberProfile.registration.status.confirmed"      : "Confirmé",
    "memberProfile.registration.status.paid"           : "Payé",
    "memberProfile.registration.status.partially_paid" : "Partiel",
    "memberProfile.registration.status.pending"        : "En attente",
    "memberProfile.registration.status.cancelled"      : "Annulé",

    // transaction
    "memberProfile.registration.transaction"                     : "Transaction",
    "memberProfile.registration.transaction.order"               : "Commande",
    "memberProfile.registration.transaction.date"                : "Date de transaction",
    "memberProfile.registration.transaction.registrationFee"     : "Frais d'inscription",
    "memberProfile.registration.transaction.otherItemFee"        : "Autre frais",
    "memberProfile.registration.transaction.annualMembershipFee" : "Frais d'affiliation",
    "memberProfile.registration.transaction.creditAmount"        : "Crédit appliqué",
    "memberProfile.registration.transaction.platformFees"        : "Frais de plateforme",
    "memberProfile.registration.transaction.tax"                 : "Taxes",
    "memberProfile.registration.transaction.totalPrice"          : "Prix Total",
    "memberProfile.registration.transaction.balance"             : "Balance",

    // Exchange
    "memberProfile.registration.exchange.warning.text"              : "Assurez-vous de compléter le processus en entier pour éviter des erreurs indésirées. Si le processus n'est pas terminé, les changements seront annulés dans les 24 à 48 heures suivantes.",
    "memberProfile.registration.exchange.items.registrations.label" : "{items, plural, one {Article d'inscription} other {Articles d'inscription}}",
    "memberProfile.registration.exchange.items.others.label"        : "{items, plural, one {Autre frais} other {Autres frais}}",
    "memberProfile.registration.exchange.items.required"            : "Veuillez sélectionner un article à échanger",
    "memberProfile.registration.exchange.items.alreadyCancelled"    : "Déjà échangé",

    // Manual registration (Emmanuel)
    "memberProfile.registration.manual.select.title"           : "Identité d'inscription",
    "memberProfile.registration.manual.select.subtitle"        : "Spécifiez qui est en charge de l'inscription de {member}.",
    "memberProfile.registration.manual.select.manual"          : "Manuel",
    "memberProfile.registration.manual.select.manual.desc"     : "Entrez le nom et le prénom de la personne qui ne se trouve pas dans le système.",
    "memberProfile.registration.manual.select.identity"        : "Sélectionner une identité",
    "memberProfile.registration.manual.select.identity.desc"   : "Créer une nouvelle identité ou en rechercher une existante dans le système.",
    "memberProfile.registration.manual.confirmation.title"     : "Vous avez presque terminé!",
    "memberProfile.registration.manual.confirmation.text"      : "Cliquez sur {confirm} pour compléter l'inscription.",
    "memberProfile.registration.manual.confirmation.congrats"  : "Félicitations!",
    "memberProfile.registration.manual.confirmation.completed" : "L'inscription manuelle est complétée.",

    // Questionnaires
    "memberProfile.registration.questionnaires.form.label.between" : "entre",
    "memberProfile.registration.questionnaires.form.label.max"     : "Max",
    "memberProfile.registration.questionnaires.form.label.min"     : "Min",
    "memberProfile.registration.questionnaires.form.label.and"     : "et",
    "memberProfile.registration.questionnaires.form.required"      : "Ce champ est obligatoire",
    "memberProfile.registration.questionnaires.noForm.title"       : "Aucun questionnaire",
    "memberProfile.registration.questionnaires.noForm"             : "Aucun questionnaire n'est associé à cette inscription.",

    // waivers
    "memberProfile.registration.sendWaivers.title"               : "Envoyer les dispenses",
    "memberProfile.registration.sendWaivers.selectWaiver"        : "Dispense(s)",
    "memberProfile.registration.sendWaivers.selectContact"       : "Contacte(s)",
    "memberProfile.registration.sendWaivers.waiversWillBeSendTo" : "Les dispenses seront envoyé à :",
    "memberProfile.registration.sendWaivers.submit"              : "S'inscrire",

    "memberProfile.registration.confirmWaivers.title" : "Accepter les dispenses",
    "memberProfile.registration.confirmWaivers.hint"  : "* Les dispenses sélectionné seront marqué en tant que \"reçu par moi\"",

    // Installments
    "memberProfile.registration.installments.label"        : "Modalités de versements",
    "memberProfile.registration.installments.payFull"      : "Versement complet",
    "memberProfile.registration.installments.installments" : "{number} versements",
    "memberProfile.registration.installments.payNow"       : "Payez maintenant",
    "memberProfile.registration.installments.total"        : "Total",
    "memberProfile.registration.installments.required"     : "Sélectionnez une modalité de versement",

    // Merchant Accounts
    "memberProfile.registration.merchantAccounts.label"                     : "Comptes marchands",
    "memberProfile.registration.merchantAccounts.label.none"                : "Il n'existe aucun compte marchand pour votre organisation.",
    "memberProfile.registration.merchantAccounts.required"                  : "Veuillez sélectionner un compte marchand",
    "memberProfile.registration.merchantAccounts.provider.PAYSAFE"          : "Paysafe",
    "memberProfile.registration.merchantAccounts.provider.QUICK_ENROLLMENT" : "SportsPay",

    // Member Identities
    "memberProfile.registration.memberIdentities.label"           : "Comptes disponibles pour le membre",
    "memberProfile.registration.memberIdentities.label.noAccount" : "Aucun compte n'est disponible pour ce membre",
    "memberProfile.registration.memberIdentities.required"        : "Veuillez sélectionner un compte ou en créer un nouveau pour le membre sélectionné",
    "memberProfile.registration.memberIdentities.add"             : "Créer un nouveau compte",
    "memberProfile.registration.memberIdentities.identityExists"  : "Ce compte existe déjà, voulez-vous le lier au membre {from, select, instructor {} other {et continuer l'inscription}}?",
    "memberProfile.registration.memberIdentities.link"            : "Lier",
    "memberProfile.registration.memberIdentities.skipLink"        : "Sauter cette étape",

    // add modal
    "memberProfile.registration.form.status"                          : "Statut",
    "memberProfile.registration.form.status.ONLINE"                   : "En ligne",
    "memberProfile.registration.form.status.ADMIN"                    : "Manuel",
    "memberProfile.registration.form.status.THIRD_PARTY"              : "Tierce partie",
    "memberProfile.registration.form.status.ONLINE.registration"      : "Inscription en ligne",
    "memberProfile.registration.form.status.ADMIN.registration"       : "Inscription manuel",
    "memberProfile.registration.form.status.THIRD_PARTY.registration" : "Inscription tierce partie",

    "memberProfile.registration.form.confirm"                  : "Confirmer",
    "memberProfile.registration.form.waivers"                  : "Dispense(s)",
    "memberProfile.registration.form.category"                 : "Categorie",
    "memberProfile.registration.form.member"                   : "Membre",
    "memberProfile.registration.form.registration"             : "Inscription",
    "memberProfile.registration.form.registration.partner"     : "Inscription de :",
    "memberProfile.registration.form.registration.showAll"     : "Afficher toutes les inscriptions",
    "memberProfile.registration.form.otherItem"                : "Autre frais",
    "memberProfile.registration.form.position"                 : "Position du membre",
    "memberProfile.registration.form.groupPosition"            : "Groupe de position",
    "memberProfile.registration.form.division"                 : "Division",
    "memberProfile.registration.form.division.none"            : "Il n'y a aucune dispense à signer pour cette division",
    "memberProfile.registration.form.division.required"        : "La division est requise",
    "memberProfile.registration.form.memberType"               : "Type de membre",
    "memberProfile.registration.form.registrationDate"         : "Date d'inscription",
    "memberProfile.registration.form.season"                   : "Saison",
    "memberProfile.registration.form.registration.none"        : "Aucune inscription",
    "memberProfile.registration.form.registration.affiliation" : "Inscription manuelle avec frais d'affiliation seulement",
    "memberProfile.registration.form.registration.manual"      : "Inscription manuelle avec dispenses seulement",
    "memberProfile.registration.form.registration.manual.info" : "Cette option créera une inscription contenant uniquement les dispenses pour la division sélectionnée. Cela ne créera aucune transaction en ligne. De plus, le compte lié (parent ou superviseur) pourra voir et signer les dispenses dans son {spordle_account}.",
    "memberProfile.registration.form.registration.date"        : "Date d'inscription",
    "memberProfile.registration.form.registration.time"        : "Heure d'inscription",
    "memberProfile.registration.form.wrongOrganization"        : "Oops! Vous devez gérer {organization} pour inscrire ce membre.",
    "memberProfile.registration.form.changeOrganization"       : "Cliquez ici pour changer d'organisation.",
    "memberProfile.registration.form.possibleRegistrations"    : "{count} inscriptions possibles",
    "memberProfile.registration.form.showAllPackages"          : "Afficher tous les forfaits",
    "memberProfile.registration.form.helper"                   : "Les frais d'inscription sont ajoutés au prix sauf si l'inscription les exclut.",

    "memberProfile.registration.form.signedBy"        : "Signée par {name}",
    "memberProfile.registration.form.accepted"        : "Acceptée par {name}",
    "memberProfile.registration.form.declined"        : "Refusée par {name}",
    "memberProfile.registration.form.waiver.agree"    : "Je suis d'accord avec cette dispense.",
    "memberProfile.registration.form.waiver.disagree" : "Je ne suis pas d'accord avec cette dispense.",
    "memberProfile.registration.form.waiver.required" : "Vous devez répondre à cette dispense avant de continuer.",
    "memberProfile.registration.form.waiver.signed"   : "Dispense signée!",

    "memberProfile.registration.add.customForms.title"              : "Questionnaire",
    "memberProfile.registration.add.customForms.required"           : "Ce champ est requis",
    "memberProfile.registration.add.customForms.min"                : "Veuillez respecter la valeur minimum",
    "memberProfile.registration.add.customForms.max"                : "Veuillez respecter la valeur maximum",
    "memberProfile.registration.add.customForms.label.between"      : "Entre",
    "memberProfile.registration.add.customForms.label.and"          : "et",
    "memberProfile.registration.add.customForms.label.min"          : "Minimum",
    "memberProfile.registration.add.customForms.label.max"          : "Maximum",
    "memberProfile.registration.add.customForms.notMandatoryHelper" : "Ce questionnaire n'est pas obligatoire",

    "memberProfile.registration.waivers.title"              : "Dispenses",
    "memberProfile.registration.waivers.of"                 : "de",
    "memberProfile.registration.waivers.accept"             : "J'ai lu et j'accepte la dispense",
    "memberProfile.registration.waivers.required"           : "Cette dispense est obligatoire",
    "memberProfile.registration.waivers.notMandatoryHelper" : "Cette dispense n'est pas obligatoire",

    "memberProfile.registration.add.title"                     : "Inscription",
    "memberProfile.registration.add.confirmation.title"        : "Félicitations!",
    "memberProfile.registration.add.confirmation.confirmedFor" : "Inscription confirmée pour",
    "memberProfile.registration.add.confirmation.confirmed"    : "Numéro d'inscription",
    "memberProfile.registration.add.confirmation.seeInvoice"   : "Voir la facture",

    "memberProfile.registration.add.validation.date.isBefore"           : "La date doit être avant aujourd'hui",
    "memberProfile.registration.add.validation.time.isBefore"           : "Le temps doit être avant maintenant",
    "memberProfile.registration.add.validation.registrationOrOther"     : "Veuillez ajouter une catégorie ou un autre frais",
    "memberProfile.registration.add.validation.otherItem"               : "Veuillez choisir un autre frais",
    "memberProfile.registration.add.validation.season.required"         : "Veuillez sélectionner une saison",
    "memberProfile.registration.add.validation.memberType.required"     : "Veuillez choisir un type de membre",
    "memberProfile.registration.add.validation.category.required"       : "Veuillez choisir une catégorie.",
    "memberProfile.registration.add.validation.groupPostition.required" : "Veuillez choisir une position de groupe",

    "memberProfile.registration.add.errors.3030" : "Il ne reste plus de place dans cette inscription.",
    "memberProfile.registration.add.errors.3266" : "Le code postal du membre n'est pas configurer dans les codes postaux de l’organisation.",

    "memberProfile.registration.add.exchangeErrors.3406" : "Un échange ne peut pas être fait si des remboursements ont été appliqués.",
    "memberProfile.registration.add.exchangeErrors.3407" : "Un échange ne peut pas être fait si la transaction contient des versements en attente.",

    "memberProfile.registration.add.outstandingBalance" : "Ce membre a un solde impayé",

    // cancel registration
    "memberProfile.registration.cancelRegistration"                     : "Annuler un ou des article(s)",
    "memberProfile.registration.cancelRegistration.registrations.label" : "{items, plural, one {Article d'inscription} other {Articles d'inscription}}",
    "memberProfile.registration.cancelRegistration.others.label"        : "{items, plural, one {Autre frais} other {Autres frais}}",
    "memberProfile.registration.cancelRegistration.items.cancelled"     : "Déjà annulé",
    "memberProfile.registration.cancelRegistration.selection.required"  : "Veuillez sélectionner au moins un article",
    "memberProfile.registration.cancelRegistration.registration"        : "Inscription",
    "memberProfile.registration.cancelRegistration.items"               : "{items, plural, one {Article} other {Articles}}",
    "memberProfile.registration.cancelRegistration.reason"              : "Raison",
    "memberProfile.registration.cancelRegistration.reason.required"     : "Veuillez fournir une raison.",
    "memberProfile.registration.cancelRegistration.submit"              : "Procéder",
    "memberProfile.registration.cancelRegistration.refund"              : "Rembourser",
    "memberProfile.registration.cancelRegistration.success"             : "Article(s) annulé(s)",
    "memberProfile.registration.cancelRegistration.error"               : "Impossible d'annuler le ou les article(s)",
    "memberProfile.registration.cancelRegistration.refund.message"      : "{items, plural, one {Cet article a été annulé} other {Ces articles ont été annulé}}. Toutefois, aucun montant n'a été remboursé.",
    "memberProfile.registration.cancelRegistration.refund.question"     : "Souhaitez-vous rembourser cette transaction ?",

    // confirm registration
    "memberProfile.registration.confirmRegistration.confirm"                        : "Êtes-vous sûr de vouloir confirmer {registrationName}?",
    "memberProfile.registration.confirmRegistration.success"                        : "Inscription confirmée!",
    "memberProfile.registration.confirmRegistration.error"                          : "Impossible de confirmer l'inscription",
    "memberProfile.registration.confirmRegistration.confirm.btn"                    : "Confirmer",
    "memberProfile.registration.confirmRegistration.multi.title"                    : "Confirmer les inscriptions",
    "memberProfile.registration.confirmRegistration.multi.description"              : "Vous êtes sur le point de confirmer l'inscription de tous les membres ci-dessous",
    "memberProfile.registration.confirmRegistration.multi.valid.title"              : "Inscriptions ({registrations})",
    "memberProfile.registration.confirmRegistration.multi.valid.subtitle"           : "Ces inscriptions seront confirmés",
    "memberProfile.registration.confirmRegistration.multi.valid.none"               : "Aucune inscription ne peut être confirmée",
    "memberProfile.registration.confirmRegistration.multi.invalid.title"            : "Inscriptions non valides ({registrations})",
    "memberProfile.registration.confirmRegistration.multi.invalid.subtitle"         : "Ces inscriptions ne peuvent pas être confirmés",
    "memberProfile.registration.confirmRegistration.multi.invalid.alreadyConfirmed" : "Cette inscription est déjà confirmée",

    // table
    "memberProfile.registration.table.emptyLayout.noResult" : "Aucune inscription effectuée pour ce membre pour la période en cours.",

    // side panel
    "memberProfile.registration.sidepanel.confirmPayment"              : "Confirmer le paiement",
    "memberProfile.registration.sidepanel.confirmPayment.confirm"      : "Êtes-vous sûr de vouloir confirmer le paiement?",
    "memberProfile.registration.sidepanel.confirmPayment.success"      : "Paiement confirmé!",
    "memberProfile.registration.sidepanel.confirmPayment.error"        : "Impossible de confirmer le paiement.",
    "memberProfile.registration.sidepanel.confirmRegistration"         : "Confirmer l'inscription",
    "memberProfile.registration.sidepanel.confirmRegistrations"        : "Confirmer les inscriptions",
    "memberProfile.registration.sidepanel.confirmRegistration.already" : "Déjà confirmé",
    "memberProfile.registration.sidepanel.confirmRegistration.by"      : "Confirmé par",
    "memberProfile.registration.sidepanel.cancelRegistration"          : "Annuler l'inscription",
    "memberProfile.registration.sidepanel.cancelRegistration.already"  : "Déjà annulé",
    "memberProfile.registration.sidepanel.cancelRegistration.by"       : "Annulé par",
    "memberProfile.registration.sidepanel.exchangeItems"               : "Changer d'articles",
    "memberProfile.registration.sidepanel.transfer"                    : "Transférer",
    "memberProfile.registration.sidepanel.item.cancelled"              : "Annulé",
    "memberProfile.registration.sidepanel.sendWaivers"                 : "Envoyer les dispenses par courriel",
    "memberProfile.registration.sidepanel.primaryOrg"                  : "Organisation primaire",
    "memberProfile.registration.sidepanel.registration"                : "Inscription",
    "memberProfile.registration.sidepanel.confirmedBy"                 : "Inscrit par",
    "memberProfile.registration.sidepanel.confirmWaivers"              : "Accepter les dispenses",
    "memberProfile.registration.sidepanel.form"                        : "Questionnaire",
    "memberProfile.registration.sidepanel.selectedAmount"              : "inscriptions sélectionnées",

    // errors
    "memberProfile.registration.error.registrationAgeError" : "Cette inscription ne s'applique pas à ce membre (mauvaise âge)",
    "memberProfile.registration.error.affiliationFees"      : "Frais d'affiliation",
    "memberProfile.registration.error.platformFees"         : "Frais",
    "memberProfile.registration.error.alreadyRegistered"    : "Déjà inscrit",
    "memberProfile.registration.error.soldOut"              : "Épuisé",
    "memberProfile.registration.error.waitingList"          : "Liste d'attente",
}