import { useDisclosure } from "@mantine/hooks";
import DisplayMemberType from "../../../components/display/DisplayMemberType";
import { useContext } from "react";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import UpdateMemberTypeStatusModal from "../components/UpdateMemberTypeStatusModal";
import { RolesContext } from "../../../contexts/RolesContext";
import { IdentityRolesContext } from "../../../contexts/IdentityRolesContext";
import { stringBuilder } from "@spordle/helpers";

const SidePanelMemberSearchMemberType = ({ memberType, member, onSuccess }) => {
    const { settings } = useContext(OrganizationContext);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);

    const [ isOpen, { open, close } ] = useDisclosure(false);
    const statusIsEnabled = settings.manage_member_type_approbation?.value == 1;
    const canManageStatus = canDoAction("EDIT", "members", "manage_member_type_approbation");
    const canManage = statusIsEnabled && (canManageStatus || isGod());

    return (
        <div onClick={canManage ? open : void 0} className={stringBuilder({ "editable": canManage })}>
            <DisplayMemberType memberType={memberType} />
            {canManage &&
                <>
                    <i className={`ml-2 mdi mdi-pencil text-${canManageStatus ? 'primary' : 'purple'}`} />
                    <UpdateMemberTypeStatusModal
                        toggle={close}
                        isOpen={isOpen}
                        onSuccess={onSuccess}
                        members={[
                            {
                                ...member,
                                member_types: [ memberType ],
                            },
                        ]}
                    />
                </>
            }
        </div>
    );
}

export default SidePanelMemberSearchMemberType;