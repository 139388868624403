import Translate from "@spordle/intl-elements"
import { Fade, ListGroup } from "reactstrap"
import JiraNotification from "./JiraNotification"
import SpordleCoach from '../../../../../assets/images/helpCenter/spordleCoach_115.png';
import { Link } from "react-router-dom";

const JiraNotificationList = ({
    jiraNotifs,
    isClearing,
    markAllJiraAsRead,
    toggle,
    isOpen,
    deleteJiraNotif,
}) => {

    return (
        <>
            {jiraNotifs?.length > 0 ?
                <>
                    <div className="text-right py-2 px-3">
                        <button type='button' disabled={isClearing} className="notification-read-all" onClick={markAllJiraAsRead}><Translate id='header.notifications.btn.clearAll' /></button>
                    </div>
                    <ListGroup tag="div" flush className="message-center notification">
                        {jiraNotifs.map((notif) => (
                            <JiraNotification
                                toggle={toggle}
                                isShowing={isOpen}
                                isClearing={isClearing}
                                key={notif.issue_key}
                                notif={notif}
                                deleteNotif={deleteJiraNotif}
                            />
                        ))}
                    </ListGroup>
                </>
                :
                <div className="notification px-3 py-4 text-center border-top-0">
                    <Fade>
                        <img className="w-25 mb-2" src={SpordleCoach} alt="No notification" />
                        <div className="h4 font-bold mb-0"><Translate id='header.notifications.empty.title' /></div>
                        <div><Translate id='header.notifications.empty.text' /></div>
                    </Fade>
                </div>

            }
            <div className="px-3 py-2 border-top text-center">
                <Link onClick={toggle} to="/supportRequest"><Translate id="header.notifications.jira.goTo" /></Link>
            </div>
        </>
    )
}

export default JiraNotificationList