import Translate from '@spordle/intl-elements';
// Form
import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
// Helpers
import { useHistory } from 'react-router-dom';
// Visual
import {
    Alert, Button, Card, CardBody, Collapse, Fade, UncontrolledAlert
} from "reactstrap";
import { array, mixed, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import AlmostDoneImage from '../../../../../assets/images/AlmostDone_Image.png';
import CardSectionTitle from '../../../../../components/CardSectionTitle';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { success, fail } from "@spordle/toasts";
import { I18nContext } from '../../../../../contexts/I18nContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { RegistrationCategoriesContext } from '../../../../../contexts/RegistrationCategoriesContext';
// Contexts
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import { DisplayI18n, displayI18n } from '../../../../../helpers/i18nHelper';
import { DisplayCategory } from '../../../TeamHelpers';
import TeamRolloverReviewEditAll from './TeamRolloverReviewEditAll';
import TeamRolloverReviewTeamCard from './TeamRolloverReviewTeamCard';
import { batchCreateInitialValues, batchCreateOnSubmit, getBatchCreateValidationSchema, BatchCreateForm, BatchCreateTitle } from '../../BatchCreateForm';
import { groupByAsArray, stringBuilder } from '@spordle/helpers';

const renderOption = ({ option }) => (
    <>
        <div className="line-height-1">
            <DisplayCategory category={option} short />
            {!option.isActive &&
                <i className='text-warning mdi mdi-alert-outline ml-2' />
            }
        </div>
        <small className="text-muted"><Translate id={option.label} /></small>
    </>
)

const renderSelectedOption = (option) => (
    <>
        {!option.isActive &&
            <i className='text-warning mdi mdi-alert-outline mr-2' />
        }
        <DisplayCategory category={option} short />
    </>
)

/**
 * Review step of the import teams modal, used to check selected teams an remove if necessary
 * @param {Object} props
 * @param {function} props.toggle Toggle modal
 * @param {[object]} props.selected Selected teams
 * @param {function} props.removeTeam Function that removed a team from the selected teams
 * @param {function} props.previousStep Function that is called when 'Previous' button is clicked
 * @param {function} props.nextStep Function that is called when 'Next' button is clicked
 * @returns {JSX.Element}
 */
const TeamRolloverReview = ({ selected, removeTeam, previousStep }) => {
    const { importTeams } = useContext(TeamsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const registrationCategoriesContext = useContext(RegistrationCategoriesContext);
    const organizationContext = useContext(OrganizationContext);
    const history = useHistory();

    const teamsContext = useContext(TeamsContext);

    const [ categories, setCategories ] = useState([])
    const [ categoriesLoading, setCategoriesLoading ] = useState(false)

    const groupedSelected = groupByAsArray(selected.map((team) => ({ ...team, team_category_id: team.team_category?.team_category_id })), 'team_category_id')
    const groupMode = groupedSelected.some((group) => group.values.length > 1)

    groupedSelected.forEach((group) => {
        group.categoryForAll = group.values[0].team_category_id ? group.values[0].team_category : null
    })

    useEffect(() => {
        if(selected.length <= 0) previousStep();
    }, [ selected ]);

    useEffect(() => {
        setCategoriesLoading(true)
        registrationCategoriesContext.getRegistrationCategories(organizationContext.organisation_id)
            .then((categories) => {
                setCategories(categories.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order)).map((cat) => ({
                    ...cat,
                    isActive: (cat.active == 1 && cat.active_in_this_period == 1),
                })))
                setCategoriesLoading(false)
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setCategories([]);
                    setCategoriesLoading(false)
                }
            })
    }, []);

    return (
        <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
                groups: groupedSelected.map((group) => ({
                    categoryForAll: group.categoryForAll ? categories?.find((c) => c.team_category_id === group.categoryForAll.team_category_id)?.team_category_id || '' : '',
                    teamFeeForAll: '',
                    teams: group.values.map((team) => {
                        const suggestedCategory = categories.find((c) => c.team_category_id === team.team_category?.team_category_id && c.division.name !== 'Historical');

                        return ({
                            category_id: suggestedCategory ? suggestedCategory.team_category_id : team.new_team_category?.team_category_id || '',
                            team_id: team.team_id,
                            team_fee: '',
                        })
                    }),
                })),
                ...batchCreateInitialValues,
            }}
            validationSchema={
                object().shape({
                    groups: array().of(object().shape({
                        categoryForAll: string().when('does_note_matter', {
                            is: () => groupMode,
                            then: string().required(<Translate id='teams.teamSearch.rollover.step2.form.categoryForAll.required' />),
                        }),
                        teamFeeForAll: mixed(),
                        teams: array().of(
                            object().shape({
                                category_id: string().test({
                                    name: 'isRequired',
                                    message: <Translate id='teams.teamSearch.rollover.step2.form.category.required' />,
                                    test: function(cat){
                                        return groupMode || !!cat;
                                    },
                                }),
                                team_id: string(),
                                team_fee: mixed(),
                            }),
                        ),
                    })),
                    ...getBatchCreateValidationSchema(),
                })
            }
            onSubmit={({ groups, categoryForAll, teamFeeForAll, teams, ...values }, { setSubmitting, setStatus }) => {
                importTeams(groups.reduce((newArray, group) => {
                    group.teams.forEach((team) => {
                        newArray.push({
                            ...team,
                            category_id: groupMode ? group.categoryForAll : team.category_id,
                            team_fee: groupMode ? group.teamFeeForAll ? Math.round(group.teamFeeForAll * 100) : null : team.team_fee ? Math.round(team.team_fee * 100) : null,
                        })
                    })
                    return newArray
                }, []))
                    .then(async() => {
                        await batchCreateOnSubmit(values, teamsContext);

                        setSubmitting(false);
                        success({ info: 'teams.teamSearch.rollover.toast.success' })
                        history.push('/teams');
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            setSubmitting(false);
                            let msg;

                            if(error.i18n){
                                setStatus(displayI18n('message', error.i18n, error.message, getGenericLocale()));
                            }else{
                                switch (error.message){
                                    case '3384':
                                        msg = `contexts.teamsContext.errors.${error.message}`;
                                        break;
                                    default:
                                        msg = "misc.error";
                                        break;
                                }
                                setStatus(<Translate id={msg} />);
                            }

                        }
                    })
            }}
        >
            {({ isSubmitting, status, values, errors, touched, setFieldValue }) => (
                <OverlayLoader isLoading={isSubmitting}>
                    <Form>
                        <div className="text-center mx-auto mb-5" style={{ maxWidth: 500 }}>
                            <img className="mb-3" src={AlmostDoneImage} alt="Form not confirmed" width="250" />
                            <div className="h4 font-medium">
                                <Translate id="misc.wizard.review.almostDone" />
                            </div>
                            <p className="text-muted">
                                <Translate id='teams.teamSearch.rollover.step2.review.almostDone' />
                            </p>
                        </div>

                        <Card className="card-shadow mb-3">
                            <CardBody>
                                <CardSectionTitle className="d-flex" title='teams.teamSearch.rollover.step2.title' />
                                {groupedSelected.map((group, groupIndex) => (
                                    <div key={group.categoryForAll?.team_category_id || groupIndex} className={stringBuilder('mb-2', { 'border-top pt-3 mb-3': groupIndex !== 0 && groupMode })}>
                                        {groupMode ?
                                            <TeamRolloverReviewEditAll
                                                categories={categories}
                                                categoriesLoading={categoriesLoading}
                                                teams={group.values}
                                                renderOption={renderOption}
                                                renderSelectedOption={renderSelectedOption}
                                                setFieldValue={setFieldValue}
                                                fieldPrefix={`groups[${groupIndex}].`}
                                            />
                                            :
                                            <Fade in>
                                                {group.values.map((team, index) => (
                                                    <TeamRolloverReviewTeamCard
                                                        key={team.team_id}
                                                        categories={categories}
                                                        categoriesLoading={categoriesLoading}
                                                        team={team}
                                                        index={index}
                                                        errors={errors.groups?.[groupIndex]}
                                                        touched={touched.groups?.[groupIndex]}
                                                        values={values.groups?.[groupIndex]}
                                                        setFieldValue={setFieldValue}
                                                        removeTeam={removeTeam}
                                                        renderOption={renderOption}
                                                        renderSelectedOption={renderSelectedOption}
                                                        fieldPrefix={`groups[${groupIndex}].`}
                                                    />
                                                ))}
                                                <Collapse appear isOpen={!!errors?.teams}>
                                                    <Alert className="mb-0 d-flex align-items-center" color="danger">
                                                        <i className="line-height-1 mr-1 mdi-playlist-remove mdi font-24" /> <Translate id='teams.teamSearch.rollover.step2.form.category.missing' />
                                                    </Alert>
                                                </Collapse>
                                            </Fade>
                                        }
                                        {status &&
                                            <Collapse appear isOpen>
                                                <UncontrolledAlert color="danger">
                                                    {status}
                                                </UncontrolledAlert>
                                            </Collapse>
                                        }
                                    </div>
                                ))}
                            </CardBody>
                        </Card>

                        <Card className="card-shadow mb-3">
                            <CardBody>
                                <CardSectionTitle title={<BatchCreateTitle />} translateTitle={false} />

                                {/* <BatchCreateForm defaultCategory={categoryForAll ? categories?.find((c) => c.team_category_id === categoryForAll.team_category_id) || '' : ''} /> */}
                                <BatchCreateForm defaultCategory={''} />
                            </CardBody>
                        </Card>
                        <div className="d-flex align-items-center mt-3">
                            <Button color='primary' disabled={isSubmitting} type="button" onClick={previousStep} outline className="mr-auto"><Translate id='misc.previous' /></Button>
                            <Button color='primary' disabled={isSubmitting} type="submit"><Translate id='misc.submit' /></Button>
                        </div>
                    </Form>
                </OverlayLoader>
            )}
        </Formik>
    );
}

export default TeamRolloverReview;