import { FormikTextArea } from "@spordle/formik-elements"
import Translate from "@spordle/intl-elements"
import { Form, Formik } from "formik"
import { useState, useEffect, useRef } from "react"
import { Button, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { object, string } from "yup"
import CollapsibleCard from "../../../../../components/collapsibleCard/CollapsibleCard"
import CrossFade from "../../../../../components/crossFade/CrossFade"
import TeamCard from "./TeamCard";
import PerfectScrollbar from 'react-perfect-scrollbar';

const ValidTeams = ({ validTeams, views, setView, toggle, action, toggleAfterSuccess, getHeaderText, ...props }) => {
    const [ hasFinished, setHasFinished ] = useState(false);

    const [ promises, setPromises ] = useState(validTeams.reduce((promisesObject, team) => {
        promisesObject[team.team.team_id] = false;
        return promisesObject
    }, {}))

    const formikRef = useRef();

    useEffect(() => {
        if(Object.keys(promises).every((key) => promises[key])){ // every promise is resolved
            setHasFinished(true);
            formikRef.current?.setSubmitting(false);
        }
    }, [ promises ])

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                comment: '',
            }}
            validationSchema={object().shape({
                comment: string(),
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
            }}
        >
            {(formik) => (
                <Form>
                    <ModalHeader toggle={hasFinished ? () => { toggle(); toggleAfterSuccess(); } : toggle}>
                        {getHeaderText()}
                    </ModalHeader>
                    <ModalBody>
                        <CollapsibleCard
                            title={<Translate id='teams.teamSearch.sideTable.bulkUpdate.allTeams.validTitle' values={{ teams: validTeams.length }} />}
                            subtitle={<Translate id='teams.teamSearch.sideTable.bulkUpdate.allTeams.validText' />}
                            className='mb-3'
                            initOpen={formik.isSubmitting || hasFinished}
                        >
                            <PerfectScrollbar options={{ suppressScrollX: true }} className='px-3 mx-n3'>
                                {validTeams.length ?
                                    validTeams.map((team) => (
                                        <TeamCard
                                            team={team}
                                            isSubmitting={formik.isSubmitting}
                                            comment={formik.values.comment}
                                            action={action}
                                            updatePromises={(teamId) => setPromises((prev) => ({ ...prev, [teamId]: true }))}
                                            key={team.team.team_id}
                                        />
                                    ))
                                    :
                                    <Translate id='teams.teamSearch.sideTable.bulkUpdate.allTeams.validNone' />
                                }
                            </PerfectScrollbar>
                        </CollapsibleCard>

                        <CrossFade isVisible={!formik.isSubmitting && !hasFinished}>
                            <FormGroup>
                                <Label><Translate id='teams.teamSearch.sideTable.bulkUpdate.validTeams.comment' /></Label>
                                <FormikTextArea
                                    id='comment' name='comment'
                                    trim rows={3}
                                    helper={<div className="small text-muted"><Translate id='teams.teamSearch.sideTable.bulkUpdate.validTeams.comment.hint' /></div>}
                                />
                            </FormGroup>
                        </CrossFade>
                    </ModalBody>
                    <CrossFade isVisible={!hasFinished}>
                        <ModalFooter>
                            <Button color='primary' type='submit' disabled={!validTeams.length || formik.isSubmitting}><Translate id='misc.confirm' /></Button>
                            <Button color='primary' type='button' outline onClick={toggle} className='ml-2' disabled={!validTeams.length || formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </CrossFade>
                    <CrossFade isVisible={hasFinished}>
                        <ModalFooter>
                            <Button
                                color='primary'
                                type='button'
                                disabled={formik.isSubmitting}
                                onClick={() => {
                                    toggle();
                                    toggleAfterSuccess();
                                }}
                            >
                                <Translate id='misc.close' />
                            </Button>
                        </ModalFooter>
                    </CrossFade>
                </Form>
            )}
        </Formik>
    )
}

export default ValidTeams