import React from "react";
import {
    Button,
    Card
} from "reactstrap";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';

import SidePanelOrganizationTeamAddressTypes from './SidePanelOrganizationTeamAddressTypes';
import Translate from "@spordle/intl-elements";
import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../../components/table/EmptyLayout";
import TeamAddressTypesAddModal from "./TeamAddressTypesAddModal";
import CardSectionTitle from "../../../../../components/CardSectionTitle";


// Contexts
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import I18nHelperContextProvider, { I18nHelperContext } from '../../../../../helpers/i18nHelper';
import { AddressTypesContext } from '../../../../../contexts/AddressTypesContext';
import withContexts from "../../../../../helpers/withContexts";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { I18nContext } from "../../../../../contexts/I18nContext";

class OrganizationTeamAddressTypes extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            addAddressTypeModalOpen: false,
            isFederation: this.props.OrganizationContext.organisation_id === this.props.IdentityRolesContext.federation.organisation_id,
        }
    }

    toggleAddAddressType = () => {
        this.setState((prevState) => ({ addAddressTypeModalOpen: !prevState.addAddressTypeModalOpen }));
    }

    render(){
        return (
            <I18nHelperContextProvider fields={[ 'name' ]}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <div>
                            <SpordlePanelTable
                                allowOutsideClick
                                sidePanel={(props) => <SidePanelOrganizationTeamAddressTypes {...props} tableRef={this.addressTypesTableRef} i18n={i18nHelper} isFederation={this.state.isFederation} />}
                                dataIndex='team_address_type_id'
                                table={(panelProps) => {
                                    return (
                                        <Card body className='card-shadow'>
                                            <CardSectionTitle title='settings.tabs.teamAddressTypes' />
                                            <SpordleTableProvider
                                                id='OrganisationTeamAddressTypesTable'
                                                tableHover clickable striped
                                                bordered
                                                ref={(r) => { this.addressTypesTableRef = r; panelProps.spordleTableRef(r); }}
                                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                                dataIndex='team_address_type_id'
                                                searchKeys={[
                                                    `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                    'name',
                                                ]}
                                                columns={[
                                                    ...i18nHelper.getTableColumns('name'),
                                                    {
                                                        label: <Translate id='organization.settings.organizationTeamAddressTypes.table.active' />,
                                                        key: "active",
                                                        mobile: true,
                                                        sortable: true,
                                                    },
                                                ]}
                                                onColumnClick={(e, addressType) => {
                                                    switch (e.button){
                                                        case 0: // Left mouse button
                                                            panelProps.onSingleSelectChange(addressType);
                                                            break;
                                                    }
                                                }}
                                                desktopWhen='md'
                                                emptyLayout={<EmptyLayout />}
                                                renderRow={(columnKey, addressType, spordleTable) => {
                                                    switch (columnKey){
                                                        case 'active':
                                                            return (
                                                                <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                    {addressType.active == '1' ?
                                                                        <i className="text-primary mdi mdi-check" />
                                                                        :
                                                                        <i className="text-danger mdi mdi-close" />
                                                                    }
                                                                </div>
                                                            )
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                rowIsHighlighted={(addressType) => !!addressType.checked}
                                                loadData={(from, data, spordleTable) => {
                                                    switch (from){
                                                        case 'REFRESH':
                                                            spordleTable.setLoading()
                                                        case 'CDM':
                                                            return this.props.TeamsContext.getAddressTypes(this.props.OrganizationContext.organisation_id);
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            >
                                                <TopSection toggleAddAddressType={this.toggleAddAddressType} panelProps={panelProps} addAddressTypeModalOpen={this.state.addAddressTypeModalOpen} />
                                                <SpordleTableView />
                                            </SpordleTableProvider>
                                        </Card>
                                    )
                                }}
                            />
                        </div>
                    )}
                </I18nHelperContext.Consumer>
            </I18nHelperContextProvider>
        )
    }
}

const TopSection = (props) => {
    return (
        <div className="mb-2">
            <div className='d-flex justify-content-between'>
                <SpordleTableProvider.SearchInput />
                <div className='d-flex ml-auto text-right'>
                    <SpordleTableProvider.Refresh />
                    <Button color='primary' className='ml-2' onClick={props.toggleAddAddressType}><i className='mdi mdi-plus' /> <Translate id='misc.add' /></Button>
                    <TeamAddressTypesAddModal addAddressTypeModalOpen={props.addAddressTypeModalOpen} toggleAddAddressType={props.toggleAddAddressType} />
                </div>
            </div>
        </div>
    )
}

export default withContexts(TeamsContext, OrganizationContext, AddressTypesContext, IdentityRolesContext, I18nContext)(OrganizationTeamAddressTypes);