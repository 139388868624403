import { Tooltip } from "@mantine/core"
import Translate from "@spordle/intl-elements"
import MemberAlertsIcon from "../../../../components/memberBadges/MemberAlertsIcon"
import HasAccessTo from "../../../../components/permissions/HasAccessTo"
import { DisplayI18n } from "../../../../helpers/i18nHelper"
import useMissingMandatoryContactTypes from "../../../../helpers/useMissingMandatoryContactTypes"
import { useHistory } from "react-router-dom";

const MissingMandatoryRolesBadge = () => {

    const { data: missingMandatoryContactTypes } = useMissingMandatoryContactTypes();
    const history = useHistory();

    return (
        <>
            {missingMandatoryContactTypes && missingMandatoryContactTypes.length > 0 &&
                <HasAccessTo componentCode='organization' componentPermissionCode='organization_staff'>
                    <Tooltip
                        label={
                            <ul className='ml-n3 mb-0'>
                                {missingMandatoryContactTypes.map((contactType) => (
                                    <li key={contactType.organisation_contact_type_id}><DisplayI18n field='name' defaultValue={contactType.name} i18n={contactType.i18n} /></li>
                                ))}
                            </ul>
                        }
                    >
                        <div className='d-flex'>
                            <div className={`text-center text-md-left mb-4 mr-2`}>
                                <div
                                    className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                                    onClick={() => {
                                        history.push(`/organization/staff`)
                                    }}
                                >
                                    <MemberAlertsIcon icon="profile" />
                                    <span className="align-middle ml-2"><Translate id='organisation.profile.badge.missingMandatoryRoles' /></span>
                                </div>
                            </div>
                        </div>
                    </Tooltip>
                </HasAccessTo>
            }
        </>
    )
}

export default MissingMandatoryRolesBadge