import { Form, Formik } from "formik";
import AnalyticsModal from "../../../../../../../analytics/AnalyticsModal";
import { object, string } from 'yup';
import Translate from "@spordle/intl-elements";
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import { Button, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../../../../../helpers/i18nHelper";
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Required from "../../../../../../../components/formik/Required";
import { useContext } from "react";
import { MembersContext } from "../../../../../../../contexts/MembersContext";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import CustomAlert from "../../../../../../../components/CustomAlert";


const MemberTypesAddModal = ({ isOpen, toggle, refreshTable }) => {
    const membersContext = useContext(MembersContext);
    const orgContext = useContext(OrganizationContext);
    const i18nHelper = useContext(I18nHelperContext);

    return (
        <AnalyticsModal isOpen={isOpen} toggle={toggle} analyticsName='OrganizationMemberTypes'>
            <Formik
                initialValues={{
                    ...i18nHelper.getInitialValues(),
                    status: 'active',
                }}
                validationSchema={object().shape({
                    ...i18nHelper.getValidationSchema({ name: string().required(<Translate id='organization.settings.organizationMemberTypes.add.name.required' />) }),
                    status: string().required(<Translate id='organization.settings.organizationMemberTypes.add.status.required' />),
                })}
                onSubmit={({ status, ...values }, { setSubmitting, setStatus }) => {
                    setSubmitting(true)
                    membersContext.createMemberType(orgContext.organisation_id, {
                        ...i18nHelper.getAPIValues(values),
                        active: (status === 'active') >>> 0,
                    })
                        .then((data) => {
                            setSubmitting(false)
                            refreshTable();
                            toggle();
                        })
                        .catch((error) => {
                            setSubmitting(false)
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            console.error(error.message);
                        })
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalHeader toggle={toggle}><Translate id='organization.settings.organizationMemberTypes.add.title' /></ModalHeader>
                                <ModalBody>
                                    <RenderI18nForm field="name">
                                        {({ fieldName, fieldLabel }) => (
                                            <FormGroup key={fieldName}>
                                                <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                <FormikInputText id={fieldName} name={fieldName} trim />
                                            </FormGroup>
                                        )}
                                    </RenderI18nForm>

                                    <FormGroup>
                                        <Label for='status' className='text-muted'><Translate id='organization.settings.organizationMemberTypes.add.status' /></Label>
                                        <FormikSelect
                                            id='status' name='status'
                                            defaultData={[
                                                { value: 'active', label: 'organization.settings.organizationMemberTypes.add.status.active', translateLabel: true },
                                                { value: 'inactive', label: 'organization.settings.organizationMemberTypes.add.status.inactive', translateLabel: true },
                                            ]}
                                            loadingStatus='success'
                                        />
                                    </FormGroup>

                                    <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                        {formik.status &&
                                            <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                        }
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type='button' outline onClick={toggle} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    )
}

export default MemberTypesAddModal