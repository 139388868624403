import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import { DisplayI18n } from "../../helpers/i18nHelper";

const InvoiceItemsOther = ({ item, number, isVoid }) => {
    return (
        <tr key={item.invoice_item_id}>
            <td className="border-top-0 pt-0 ">
                <span className="font-medium"><Translate id='invoice.table.item' /></span>: <DisplayI18n field="name" defaultValue={item.other_fee.fee.name} i18n={item.other_fee.fee.i18n} />
            </td>
            <td className="border-top-0 pt-0 text-right">
                <CurrencyFormat value={item.amount / 100} />
            </td>
            <td className="border-top-0 pt-0">X</td>
            <td className="border-top-0 pt-0">{number}</td>
            <td className="border-top-0 pt-0 text-right">
                <CurrencyFormat value={isVoid ? 0 : (parseInt(item.amount) * number) / 100} />
            </td>
        </tr>
    )
}

export default InvoiceItemsOther;