import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Badge,
    Collapse
} from "reactstrap";
import Translate from '@spordle/intl-elements';

import { JiraContext } from '../../../../../contexts/JiraContext';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { Tooltip } from '@mantine/core';

const JiraNotification = ({ notif, isClearing, deleteNotif, isShowing, toggle }) => {
    const [ info, setInfo ] = useState(null);
    const [ isDeleting, setIsDeleting ] = useState(false);
    const jiraContext = useContext(JiraContext);


    const history = useHistory();
    const toggleRead = () => {
        if(!isDeleting){
            setIsDeleting(true);

            jiraContext.deleteNotification(notif.issue_key)
                .then(() => {
                    setInfo('deleted')
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
                .finally(() => setIsDeleting(false));
        }
    }

    useEffect(() => {
        if(isShowing && !info){
            jiraContext.getRequest(notif.issue_key)
                .then((_info) => {
                    const latest = _info.comments.reduce((_latest, _comment) => {
                        if(!_latest?.body){
                            _latest = _comment;
                        }else if(_latest.created.iso8601 < _comment.created.iso8601){
                            _latest = _comment;
                        }

                        return _latest;
                    }, {});

                    setInfo({ ..._info, latestComment: latest });
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        setInfo('none');
                    }
                })
        }
    }, [ isShowing ]);

    return (
        (info ?
            info !== 'none' &&
                <Collapse isOpen={info !== 'deleted'} onExited={() => info === "deleted" && deleteNotif(notif.issue_key)}>
                    <div
                        className="list-group-item list-group-item-action notification-item is-new p-3"
                        onClick={(e) => {
                            if(!isDeleting && e.target.nodeName !== "BUTTON"){
                                toggle?.();
                                toggleRead();
                                history.push(`/supportRequest/${notif.issue_key}${info?.latestComment ? `#${info.latestComment.id}` : ''}`);
                            }
                        }}
                    >
                        <Badge color="light" className="round border bg-primary-light text-primary d-flex align-items-center flex-shrink-0">
                            <i className="d-block mx-auto mdi mdi-wrench font-16" />
                        </Badge>
                        <div className="pl-3 flex-grow-1">
                            <div className="h5 font-bold mb-0"><Translate id='header.notifications.item.title' /></div>
                            <p className="font-medium mb-0">{notif.issue_key} - <Translate id='header.notifications.item.unread' values={{ count: notif.unread_messages }} /></p>
                            {info.latestComment?.body && <p className="text-muted mb-0">{info.latestComment.author.displayName}: {info.latestComment.body.substring(0, 100)}...</p>}
                            <span className="text-muted small">{info.latestComment?.created?.friendly}</span>
                        </div>
                        <div className="align-self-start d-flex align-items-center">
                            <Tooltip zIndex={2000} withArrow label={<Translate id='header.notifications.item.tip.clear' />}>
                                <button type='button' className="notification-delete mdi mdi-close mr-1" disabled={isDeleting} />
                            </Tooltip>
                            <button type='button' className="notification-read" data-tip='header.notifications.item.tip.read' onClick={toggleRead} disabled={isDeleting} />
                        </div>
                    </div>
                </Collapse>
            :
            <div className="list-group-item notification-item p-3">
                <Badge color="primary" className="notification-loading-info round d-flex align-items-center" />
                <div className="pl-3 flex-grow-1">
                    <div style={{ height: 40 }} className="notification-loading-info mb-1" />
                    <div style={{ height: 15 }} className="notification-loading-info small" />
                </div>
            </div>
        )
    )
}

export default JiraNotification;