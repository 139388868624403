import { useIntl } from "react-intl";
import { IdentityRolesContext } from "../../../contexts/IdentityRolesContext";
import { useContext } from "react";
import { RolesContext } from "../../../contexts/RolesContext";
import { Card } from "reactstrap";
import SpordleTableProvider, { SearchInput, SpordleTableView } from "@spordle/datatables";
import { groupByAsArray, stringBuilder } from "@spordle/helpers";
import { Link } from "react-router-dom";
import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";
import EmptyLayout from "../../../components/table/EmptyLayout";

const SettingsListView = ({ id, routesList, setting }) => {
    const intl = useIntl();
    const identityRolesContext = useContext(IdentityRolesContext);
    const rolesContext = useContext(RolesContext);

    return (
        <Card body className="card-shadow">
            <SpordleTableProvider
                id={id}
                dataIndex='code'
                viewMode='GRID'
                searchKeys={[ 'code', 'description' ]}
                gridConfig={{
                    col: { xs: 12 },
                    gridRender: (row) => {
                        const additionalCondition = row.additionalCondition === void 0 || row.additionalCondition;
                        const permissions = row.subPermissionsRoutes?.reduce((newArray, route) => {
                            if(!route.redirect && route.path){
                                if(route.permissions && Array.isArray(route.permissions)){
                                    route.permissions.forEach((perm) => {
                                        newArray.push({
                                            path: route.path,
                                            code: perm.code,
                                            permissionCode: perm.permissionCode,
                                        })
                                    })
                                }else{
                                    newArray.push({
                                        path: route.path,
                                        code: route.permissions?.code,
                                        permissionCode: route.permissions?.permissionCode,
                                    })
                                }
                            }
                            return newArray;
                        }, []);

                        const isGod = identityRolesContext.isGod();
                        const routesWithAccess = permissions.filter((permission) => (!permission.code && !permission.permissionCode) || rolesContext.hasAccessTo(permission.code, permission.permissionCode));
                        const routesWithPermissions = permissions.filter((permission) => (!!permission.code || !!permission.permissionCode));

                        // check settings, the second parameter used here is settingsOnlyMode, therefore the permissions are not double-checked
                        const settingIsActive = rolesContext.validateRouteAccess(row, true) === true;

                        const hasAccess = settingIsActive && (permissions.length > 0 ? routesWithAccess.length > 0 : true)
                        const canShow = hasAccess || isGod;

                        if(canShow && additionalCondition && !row.redirect){
                            return (
                                <div
                                    key={row.code}
                                    className="border-top p-3"
                                >
                                    <Link
                                        to={{
                                            pathname: `/settings/${setting}${row.path}${routesWithAccess[0]?.path || ''}`,
                                        }}
                                        className={stringBuilder('stretched-link', { 'text-purple': !hasAccess && isGod })}
                                    >
                                        <div>
                                            {isGod &&
                                                <Tooltip
                                                    label={routesWithPermissions.length > 0 ?
                                                        <>
                                                            <Translate id='settings.tabs.organization.settings.tooltip' />
                                                            <ul className='mb-0'>
                                                                {groupByAsArray(routesWithPermissions, 'path').map((permPath) => (
                                                                    <>
                                                                        <li>
                                                                            {permPath.key ?
                                                                                <>
                                                                                    {permPath.key}
                                                                                    <ul className='mb-0'>
                                                                                        {permPath.values.map((permission) => (
                                                                                            <li key={permission.permissionCode}>
                                                                                                {permission.code} <i className='mdi mdi-arrow-right' /> {permission.permissionCode}
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </>
                                                                                :
                                                                                permPath.values.map((permission) => (
                                                                                    <li key={permission.permissionCode}>
                                                                                        {permission.code} <i className='mdi mdi-arrow-right' /> {permission.permissionCode}
                                                                                    </li>
                                                                                ))
                                                                            }
                                                                        </li>
                                                                    </>
                                                                ))}
                                                            </ul>
                                                        </>
                                                        :
                                                        <Translate id='settings.tabs.organization.settings.tooltip.none' />
                                                    }
                                                    wrapLines
                                                    width={500}
                                                    zIndex={3000}

                                                    style={{ zIndex: 3000 }}
                                                >
                                                    <i className='mdi mdi-information-outline text-purple mr-2' />
                                                </Tooltip>
                                            }
                                            {row.code ? <Translate id={`settings.tabs.${setting}.${row.code}`} /> : row.name ? row.skipTranslate ? row.name : <Translate id={row.name} /> : null}
                                            <i className="mdi mdi-chevron-right ml-1" />
                                        </div>
                                        {row.code &&
                                            <span className='text-muted small'>
                                                <Translate id={`settings.tabs.${setting}.${row.code}.description`} />
                                            </span>
                                        }
                                    </Link>
                                </div>
                            )
                        }
                        return null
                    },
                }}
                loadingStatus='success'
                defaultData={routesList}
                fuseOptions={{
                    getSearchValue: (_, data, searchKey) => {
                        switch (searchKey){
                            case 'description':
                                return intl.formatMessage({ id: `settings.tabs.${setting}.${data.code}.description` }).normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                            case 'code':
                                return intl.formatMessage({ id: `settings.tabs.${setting}.${data.code}` }).normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                            default:
                                break;
                        }
                    },
                }}
                emptyLayout={<EmptyLayout />}
            >
                <div className='mb-2'>
                    <div className='d-flex flex-wrap justify-content-between'>
                        <SearchInput />
                    </div>
                </div>
                <SpordleTableView />
                <div className="border-top" />
            </SpordleTableProvider>
        </Card>
    );
}

export default SettingsListView;