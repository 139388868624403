import { FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import { FormGroup, Label } from "reactstrap";
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import FormikEditable from '../../../../../components/formik/FormikEditable';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { object, string } from 'yup';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { RolesContext } from '../../../../../contexts/RolesContext';
import SidePanel from '../../../../../components/sidePanel/SidePanel';
import { fail, success } from '@spordle/toasts';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';

const SidePanelOrganizationAccountingLedger = ({
    selectedRows,
    syncRows,
    tableRef,
    createNewValues,
    toggle,
    ...props
}) => {
    const { partiallyUpdateOrganisationLedgers, deleteOrganisationLedger, organisation_id } = useContext(OrganizationContext);
    const { canDoAction } = useContext(RolesContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const canEdit = canDoAction("EDIT", "organization", "organization_profile");

    const partUpdate = (val) => {
        setIsLoading(true);
        partiallyUpdateOrganisationLedgers(organisation_id, selectedRows[0].organisation_ledger_id, val)
            .then(() => {
                syncRows(createNewValues(val));
                success();
                setIsLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setIsLoading(false);
                }
            });
    }

    const deleteLedger = () => {
        return deleteOrganisationLedger(organisation_id, selectedRows[0].organisation_ledger_id)
            .then(() => {
                tableRef.deleteRow(selectedRows[0].organisation_ledger_id).then(toggle);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <div className="p-3 border-bottom">
                <div className='d-flex mb-2 align-items-center'>
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu action='DELETE' componentCode='organization' componentPermissionCode='organization_profile'>
                        <SidePanel.MenuDelete
                            translateModalMsg
                            modalMsg='organization.profile.accounting.ledger.sidePanel.delete.title'
                            modalTitle={selectedRows[0].name}
                            onConfirm={deleteLedger}
                        />
                    </SidePanel.ActionsMenu>
                </div>
                <SidePanel.Title>{selectedRows[0].name}</SidePanel.Title>
                <SidePanel.Subtitle>#{selectedRows[0].number}</SidePanel.Subtitle>
            </div>
            <div className="p-3">
                <FormGroup>
                    <Label for="status" className="text-muted mb-0"><Translate id="misc.status" /></Label>
                    <FormikEditable
                        id='status'
                        disabled={!canEdit}
                        noConfirmation
                        initialValues={{
                            status: selectedRows[0].active,
                        }}
                        onSubmit={(values) => {
                            if(values.status !== selectedRows[0].active)
                                partUpdate({ active: values.status });
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    <div className="font-medium">
                                        {selectedRows[0].active == "1" ?
                                            <><i className="mdi mdi-check text-primary mr-1" /><Translate id="misc.active" /></>
                                            :
                                            <><i className="mdi mdi-close text-danger mr-1" /><Translate id="misc.inactive" /></>
                                        }
                                    </div>
                                )
                            }
                            return (
                                <FormikSelect
                                    autoFocus
                                    menuIsDefaultOpen
                                    onOptionSelected={options.stopEditing}
                                    search={false}
                                    name='status'
                                    id='status_SidePanelOrganizationAccountingLedger'
                                    loadingStatus='success'
                                    defaultData={[
                                        { id: 'status-1', label: 'misc.active', value: '1', selected: selectedRows[0].active == "1", translateLabel: true },
                                        { id: 'status-0', label: 'misc.inactive', value: '0', selected: selectedRows[0].active == "0", translateLabel: true },
                                    ]}
                                />
                            )

                        }}
                    </FormikEditable>
                </FormGroup>
                <FormGroup>
                    <Label for="name" className='text-muted mb-0'><Translate id='organization.profile.accounting.ledger.organizationAccountingLedger.accountName' /></Label>
                    <FormikEditable
                        id='name'
                        disabled={!canEdit}
                        initialValues={{
                            name: selectedRows[0].name,
                        }}
                        validationSchema={object().shape({
                            name: string().required(<Translate id='form.validation.name.required' />),
                        })}
                        onSubmit={(values) => {
                            if(values.name !== selectedRows[0].name)
                                partUpdate(values);
                        }}
                    >
                        {(isEditing) => {
                            if(!isEditing){
                                return <div className="font-medium">{selectedRows[0].name}</div>
                            }
                            return <FormikInputText trim type="text" name="name" id="name" />

                        }}
                    </FormikEditable>
                </FormGroup>
                <FormGroup>
                    <Label for="number" className='text-muted mb-0'><Translate id='organization.profile.accounting.ledger.organizationAccountingLedger.accountNumber' /></Label>
                    <FormikEditable
                        id='number'
                        disabled={!canEdit}
                        initialValues={{
                            number: selectedRows[0].number,
                        }}
                        validationSchema={object().shape({
                            number: string().required(<Translate id='organization.profile.accounting.ledger.organizationAccountingLedger.accountNumber.required' />),
                        })}
                        onSubmit={(values) => {
                            if(values.number !== selectedRows[0].number)
                                partUpdate(values);
                        }}
                    >
                        {(isEditing) => {
                            if(!isEditing){
                                return <div className="font-medium">#{selectedRows[0].number}</div>
                            }
                            return <FormikInputText trim type="text" name="number" id="number" />

                        }}
                    </FormikEditable>
                </FormGroup>
                <FormGroup>
                    <Label for="description" className='text-muted mb-0'><Translate id='organization.profile.accounting.ledger.organizationAccountingLedger.accountDesc' /></Label>
                    <FormikEditable
                        id='description'
                        disabled={!canEdit}
                        initialValues={{
                            description: selectedRows[0].description,
                        }}
                        onSubmit={(values) => {
                            if(values.description !== selectedRows[0].description)
                                partUpdate(values);
                        }}
                    >
                        {(isEditing) => {
                            if(!isEditing){
                                return <div className="font-medium">{selectedRows[0].description || '-'}</div>
                            }
                            return <FormikInputText trim type="textarea" name="description" id="description" />

                        }}
                    </FormikEditable>
                </FormGroup>
            </div>
        </OverlayLoader>
    )
}

export default SidePanelOrganizationAccountingLedger;