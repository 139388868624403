import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { TeamsContext } from "../../../../contexts/TeamsContext";
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { FormikTextArea } from '@spordle/formik-elements';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { Alert, Button, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Required from '../../../../components/formik/Required';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { DisplayCategory } from '../../TeamHelpers';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import InlineCopy from '../../../../components/inlineCopy/InlineCopy';

const TeamSearchCommentsAddModal = ({ isOpen, toggle, team, mutate }) => {
    const teamsContext = useContext(TeamsContext);
    return (
        <AnalyticsModal analyticsName='TeamSearchCommentsAddModal' isOpen={isOpen}>
            <Formik
                initialValues={{
                    comment: '',
                }}
                validationSchema={object().shape({
                    comment: string().trim().required(<Translate id='teams.profile.roster.sidePanel.teamComments.required' />),
                })}
                onSubmit={({ comment }, { setSubmitting, setStatus }) => {
                    setStatus();
                    teamsContext.createTeamComment(team.team_id, { comment: comment })
                        .then(() => {
                            setSubmitting(false);
                        })
                        .then(() => {
                            mutate()
                        })
                        .then(toggle)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setSubmitting(false);
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            }
                        });
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader toggle={toggle}>
                                <Translate id='transfer.sidepanel.actions.addComment' />
                            </ModalHeader>
                            <ModalBody className="text-center">
                                {/* CHANGE THIS FOR TEAM */}
                                <UserDisplay card className="w-100">
                                    <UserDisplay.Container>
                                        <UserImg
                                            abbr={team.short_name || ''}
                                            src={team.logo?.full_path}
                                            width={50}
                                            height={50}
                                            alt={team.short_name}
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title>{team.name}</UserDisplay.Title>
                                        <InlineCopy toCopy={team.unique_identifier} tag='div' className="font-medium">{'#' + team.unique_identifier || '-'}</InlineCopy>
                                        <UserDisplay.Subtitle>
                                            <DisplayCategory category={team.team_category} />
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>

                                <FormGroup className="text-left">
                                    <Label for='comment' className='text-muted'><Translate id='transfer.sidepanel.modal.addComment.comment' /> <Required /></Label>
                                    <FormikTextArea id='comment' name='comment' rows='5' trim />
                                </FormGroup>

                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    <Alert color='danger' className='text-left'>{formik.status}</Alert>
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                                    <Translate id='transfer.submit' />
                                </Button>
                                <Button color='primary' type='button' onClick={toggle} outline disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

export default TeamSearchCommentsAddModal;