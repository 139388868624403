import React from "react";
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import FormikEditable from '../../../../../components/formik/FormikEditable';
import { FormikInputText, FormikSelect } from '@spordle/formik-elements';
import { success, fail } from '@spordle/toasts';
import PropTypes from 'prop-types';
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import UserImg from "../../../../../components/UserImg";

// Context
import { SuspensionsContext } from '../../../../../contexts/SuspensionsContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import withContexts from '../../../../../helpers/withContexts';
import { I18nHelperContext, RenderI18nForm, DisplayI18n } from '../../../../../helpers/i18nHelper';

// Language
import Translate from "@spordle/intl-elements";
import { getInfractions } from "../../../../../api/client/infractions";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { getSuspensionTypes } from "../../../../../api/client/suspensionTypes";
import { object, string } from "yup";

class SidePanelOrganizationSuspensions extends React.Component{
    state = {
        activeTab: '1',
        isLoading: false,

        infractionOptions: [],
        infractionIsLoading: false,

        suspensionTypeOptions: [],
        suspensionTypeIsLoading: false,
    }

    toggleTab = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState({ activeTab: tab });
        }
    }

    getInfractions = () => {
        this.setState({ infractionIsLoading: true })
        return getInfractions({ organisation_id: this.props.OrganizationContext.organisation_id, active: 1 })
            .then((infractions) => {
                const infractionOptions = infractions.map((infraction) => ({
                    value: infraction.infraction_id,
                    label: infraction.name,
                    i18n: infraction.i18n,
                }))

                this.setState({ infractionOptions: infractionOptions })

                this.setState({ infractionIsLoading: false })
                return infractionOptions;
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                }
                this.setState({ infractionIsLoading: false })
            })
    }

    getSuspensionTypes = () => {
        this.setState({ suspensionTypeIsLoading: true })
        return getSuspensionTypes({ organisation_id: this.props.OrganizationContext.organisation_id, active: 1 })
            .then((infractions) => {
                const suspensionTypeOptions = infractions.map((infraction) => ({
                    value: infraction.suspension_type_id,
                    label: infraction.name,
                    i18n: infraction.i18n,
                }))

                this.setState({ suspensionTypeOptions: suspensionTypeOptions })

                this.setState({ suspensionTypeIsLoading: false })
                return suspensionTypeOptions;
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                }
                this.setState({ suspensionTypeIsLoading: false })
            })
    }

    componentDidMount(){
        this.getInfractions();
        this.getSuspensionTypes();
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading || this.state.suspensionTypeIsLoading || this.state.infractionIsLoading}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <>
                            <SidePanel.Header noBorder>
                                <div className='d-flex mb-2 align-items-center'>
                                    <SidePanel.ToggleButton />
                                    <div className="ml-auto d-flex align-items-center">
                                        {this.props.isFederation && // hides
                                            <SidePanel.ActionsMenu>
                                                <SidePanel.MenuDelete
                                                    translateModalMsg
                                                    modalMsg='organization.settings.sidePanelOrganizationSuspensions.actions.remove.topText'
                                                    onConfirm={() => {
                                                        return this.props.SuspensionsContext.deleteSuspension(this.props.getCommonValue('suspension_id'))
                                                            .then(() => {
                                                                this.props.tableRef.deleteRow(this.props.getCommonValue('suspension_id')).then(this.props.toggle);
                                                                success();
                                                            }).catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                }
                                                                fail();
                                                            })
                                                    }}
                                                    modalContent={
                                                        <Row className='mt-3'>
                                                            <Col md='6' className='d-flex align-items-center justify-content-center flex-column border-right'>
                                                                <div className='font-medium'>
                                                                    <DisplayI18n
                                                                        field="name"
                                                                        i18n={this.props.getCommonValue('i18n')}
                                                                        defaultValue={this.props.getCommonValue('name')}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md='6' className='d-flex align-items-center justify-content-center flex-column'>
                                                                <div className='mr-2'>
                                                                    <UserImg
                                                                        src={this.props.OrganizationContext.logo?.full_path}
                                                                        filePos={this.props.OrganizationContext.logo?.file_position}
                                                                        alt={this.props.OrganizationContext.organisation_name}
                                                                        abbr={this.props.OrganizationContext.abbreviation}
                                                                    />
                                                                </div>
                                                                <div className='font-medium'>{this.props.OrganizationContext.organisation_name}</div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                />
                                            </SidePanel.ActionsMenu>
                                        }
                                    </div>
                                </div>
                                <SidePanel.Title>
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={this.props.getCommonValue('name')}
                                        i18n={this.props.getCommonValue('i18n')}
                                    />
                                </SidePanel.Title>
                            </SidePanel.Header>
                            <Nav tabs>
                                <NavItem className="flex-grow-1 text-center w-100">
                                    <NavLink
                                        className={stringBuilder({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggleTab('1'); }}
                                    >
                                        <Translate id='organization.settings.sidePanelOrganizationSuspensions.tabs.details' />
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    {/* SUSPENSION INFO */}
                                    <div className='p-3 border-bottom'>
                                        <div className="h4 font-bold mb-3"><Translate id='organization.settings.sidePanelOrganizationSuspensions.title' /></div>

                                        <RenderI18nForm field='name'>
                                            {({ fieldName, fieldLabel }) => {
                                                return (
                                                    <div className='mb-3' key={fieldName}>
                                                        <div className='text-muted'>{fieldLabel}</div>
                                                        <FormikEditable
                                                            id={fieldName}
                                                            disabled={!this.props.isFederation}
                                                            initialValues={i18nHelper.getInitialValues(this.props.selectedRows[0])}
                                                            validationSchema={object().shape(
                                                                i18nHelper.getValidationSchema({ name: string().required(<Translate id='form.validation.name.required' />) }),
                                                            )}
                                                            onSubmit={(values) => {
                                                                this.setState(() => ({ isLoading: true }));
                                                                const newValues = this.props.createNewValues(values);

                                                                this.props.SuspensionsContext.updateSuspension(this.props.getCommonValue('suspension_id'), i18nHelper.getAPIValues(values))
                                                                    .then(() => {
                                                                        this.props.syncRows(newValues);
                                                                        success();
                                                                        this.setState({ isLoading: false });
                                                                    }).catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                        }
                                                                        this.setState({ isLoading: false });
                                                                    })
                                                            }}
                                                        >
                                                            {(isEditing) => {
                                                                if(!isEditing){
                                                                    return (
                                                                        <DisplayI18n
                                                                            field={fieldName}
                                                                            defaultValue={this.props.selectedRows[0].name || '-'}
                                                                            i18n={this.props.selectedRows[0].i18n}
                                                                        />
                                                                    )
                                                                }
                                                                return (
                                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                                )

                                                            }}
                                                        </FormikEditable>
                                                    </div>

                                                )
                                            }}
                                        </RenderI18nForm>

                                        {/* CODE */}
                                        <div className='mb-3'>
                                            <div className='text-muted'><Translate id='organization.settings.sidePanelOrganizationSuspensions.code' /></div>
                                            <FormikEditable
                                                id={'code'}
                                                disabled={!this.props.isFederation}
                                                initialValues={{
                                                    code: this.props.getCommonValue('code'),
                                                }}
                                                validationSchema={object().shape({
                                                    code: string().required(<Translate id='organization.settings.sidePanelOrganizationSuspensions.code.required' />).test({
                                                        name: "code-is-unique",
                                                        message: <Translate id="organization.settings.organizationSuspensions.add.code.duplicate" />,
                                                        test: (code) => {
                                                            return !this.props.tableRef.getData().some((row) => row.code == code);
                                                        },
                                                    }),
                                                })}
                                                onSubmit={(values) => {
                                                    this.setState({ isLoading: true });
                                                    const newValues = this.props.createNewValues(values);
                                                    this.props.SuspensionsContext.updateSuspension(this.props.getCommonValue('suspension_id'), values)
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                            this.setState({ isLoading: false });
                                                        }).catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                            this.setState({ isLoading: false });
                                                        })
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            this.props.getCommonValue('code')
                                                        )
                                                    }
                                                    return (
                                                        <FormikInputText id='code' name='code' trim />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>

                                        {/* INFRACTION */}
                                        <div className='mb-3'>
                                            <div className='text-muted'><Translate id='organization.settings.organizationSuspensions.add.infraction' /></div>
                                            <FormikEditable
                                                id={'infraction_id'}
                                                disabled={!this.props.isFederation}
                                                initialValues={{
                                                    infraction_id: this.props.selectedRows[0].infraction?.infraction_id || '',
                                                }}
                                                validationSchema={object().shape({
                                                    infraction_id: string().required(<Translate id='organization.settings.organizationSuspensions.add.infraction.required' />),
                                                })}
                                                onSubmit={(values) => {
                                                    this.setState({ isLoading: true });
                                                    const newValues = this.props.createNewValues({ infraction: values });
                                                    this.props.SuspensionsContext.updateSuspension(this.props.getCommonValue('suspension_id'), values)
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                            this.setState({ isLoading: false });
                                                        }).catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                            this.setState({ isLoading: false });
                                                        })
                                                }}
                                                noConfirmation
                                            >
                                                {(isEditing, options) => {
                                                    if(!isEditing){
                                                        const currentInfraction = this.state.infractionOptions?.find((infraction) => infraction.value === this.props.selectedRows[0].infraction?.infraction_id)
                                                        return (
                                                            this.props.selectedRows[0].infraction ?
                                                                <DisplayI18n field='name' defaultValue={currentInfraction?.label} i18n={currentInfraction?.i18n} />
                                                                :
                                                                ' - ' // no infraction selected
                                                        )
                                                    }
                                                    return (
                                                        <FormikSelect
                                                            menuIsDefaultOpen autoFocus
                                                            name='infraction_id'
                                                            id='infraction_id'
                                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                            searchKeys={[
                                                                `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                                `name`,
                                                            ]}
                                                            onOptionSelected={options.stopEditing}
                                                            isLoading={this.state.infractionIsLoading}
                                                            options={this.state.infractionOptions}
                                                        />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>

                                        {/* SUSPENSION TYPES */}
                                        <div className='mb-3'>
                                            <div className='text-muted'><Translate id='organization.settings.organizationSuspensions.add.suspension.type' /></div>
                                            <FormikEditable
                                                id={'suspension_type_id'}
                                                disabled={!this.props.isFederation}
                                                initialValues={{
                                                    suspension_type_id: this.props.selectedRows[0].suspension_type?.suspension_type_id || '',
                                                }}
                                                validationSchema={object().shape({
                                                    suspension_type_id: string().required(<Translate id='organization.settings.organizationSuspensions.add.suspension.type.required' />),
                                                })}
                                                onSubmit={(values) => {
                                                    this.setState({ isLoading: true });
                                                    const newValues = this.props.createNewValues({ suspension_type: values });
                                                    this.props.SuspensionsContext.updateSuspension(this.props.getCommonValue('suspension_id'), values)
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                            this.setState({ isLoading: false });
                                                        }).catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                            this.setState({ isLoading: false });
                                                        })
                                                }}
                                                noConfirmation
                                            >
                                                {(isEditing, options) => {
                                                    if(!isEditing){
                                                        const currentSuspensionType = this.state.suspensionTypeOptions?.find((suspensionType) => suspensionType.value === this.props.selectedRows[0].suspension_type?.suspension_type_id)
                                                        return (
                                                            this.props.selectedRows[0].suspension_type ?
                                                                <DisplayI18n field='name' defaultValue={currentSuspensionType?.label} i18n={currentSuspensionType?.i18n} /> // find suspension_type from state
                                                                :
                                                                ' - ' // no suspension_type selected
                                                        )
                                                    }
                                                    return (
                                                        <FormikSelect
                                                            menuIsDefaultOpen autoFocus
                                                            name='suspension_type_id'
                                                            id='suspension_type_id'
                                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                            searchKeys={[
                                                                `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                                `name`,
                                                            ]}
                                                            onOptionSelected={options.stopEditing}
                                                            isLoading={this.state.suspensionTypeIsLoading}
                                                            options={this.state.suspensionTypeOptions}
                                                        />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>

                                        {/* STATUS */}
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='organization.settings.sidePanelOrganizationSuspensions.status' /></div>
                                            <FormikEditable
                                                id={'active'}
                                                disabled={!this.props.isFederation}
                                                noConfirmation
                                                initialValues={{
                                                    active: this.props.getCommonValue("active"),
                                                }}
                                                validationSchema={object().shape({
                                                    active: string().required(<Translate id='organization.settings.sidePanelOrganizationSuspensions.status.required' />),
                                                })}
                                                onSubmit={(values) => {
                                                    this.setState({ isLoading: true });
                                                    if(values.active !== this.props.getCommonValue("active")){
                                                        const newValues = this.props.createNewValues(values);
                                                        this.props.SuspensionsContext.updateSuspension(this.props.getCommonValue('suspension_id'), values)
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                                this.setState({ isLoading: false });
                                                            }).catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                }
                                                                this.setState({ isLoading: false });
                                                            })
                                                    }
                                                }}
                                            >
                                                {(isEditing, options) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className="font-medium">
                                                                <i className={`mdi ${this.props.getCommonValue("active") == '1' ? 'mdi-check text-primary' : 'mdi-close text-danger'} mr-2`} />
                                                                <span><Translate id={`organization.settings.sidePanelOrganizationSuspensions.status.${this.props.getCommonValue("active") == '1' ? 'active' : 'inactive'}`} /></span>
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikSelect
                                                            autoFocus
                                                            menuIsDefaultOpen
                                                            name="active"
                                                            id="active_SidepanelOrganizationSuspensionsSettings"
                                                            search={false}
                                                            onOptionSelected={() => {
                                                                options.stopEditing();
                                                            }}
                                                            loadingStatus='success'
                                                            defaultData={[
                                                                { id: 'active', value: '1', label: 'organization.settings.sidePanelOrganizationSuspensions.status.active', translateLabel: true, selected: this.props.getCommonValue("active") == '1' },
                                                                { id: 'inactive', value: '0', label: 'organization.settings.sidePanelOrganizationSuspensions.status.inactive', translateLabel: true, selected: this.props.getCommonValue("active") == '0' },
                                                            ]}
                                                        />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </>
                    )}
                </I18nHelperContext.Consumer>
            </OverlayLoader>
        )
    }
}

SidePanelOrganizationSuspensions.propTypes = {
    tableRef: PropTypes.object.isRequired,
    i18n: PropTypes.object,
    isFederation: PropTypes.bool,
}

export default withContexts(SuspensionsContext, OrganizationContext, I18nContext)(SidePanelOrganizationSuspensions);