import AnalyticsModal from "../../../analytics/AnalyticsModal"
import { useContext } from 'react'
import Translate from "@spordle/intl-elements"
import { Button, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { OrganizationContext } from "../../../contexts/OrganizationContext"
import { PeriodsContext } from "../../../contexts/contexts"
import FormikModalForm from "../../../components/formik/FormikModalForm"
import { object, string } from "yup"
import { createAffiliationInvoice } from "../../../api/client/unaffiliatedMembers"
import FormikApiError from "../../../components/formik/FormikApiError"
import CollapsibleCard from "../../../components/collapsibleCard/CollapsibleCard"
import RenewMemberCard from "./sidepanel/RenewMemberCard"
import { stringBuilder } from "@spordle/helpers"
import { FormikSelect } from "@spordle/formik-elements"
import { DisplayI18n } from "../../../helpers/i18nHelper"
import { I18nContext } from "../../../contexts/I18nContext"
import { UtilsContext } from "../../../contexts/UtilsContext"
import Required from "../../../components/formik/Required"

const RenewMembersModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal analyticsName="renewMembersModal" isOpen={isOpen}>
            <RenewMembersModalInner {...props} />
        </AnalyticsModal>
    )
}

const RenewMembersModalInner = ({ toggle, members, refreshTable, memberTypeId, divisionId }) => {
    const orgContext = useContext(OrganizationContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const i18nContext = useContext(I18nContext);
    const utilsContext = useContext(UtilsContext);

    return (
        <FormikModalForm
            toggle={toggle}
            initialValues={{
                paymentMethod: '',
            }}
            validationSchema={object().shape({
                paymentMethod: string().required(<Translate id='task.renewMembers.paymentMethod.required' />),
            })}
            onSubmit={(values, { showError, showSuccess }) => {
                const apiValues = {
                    organisation_id: orgContext.organisation_id,
                    period_id: selectedPeriod.period_id,
                    payment_method: values.paymentMethod,
                    members: members.map((member) => ({
                        member_id: member.member_id,
                        member_type_id: memberTypeId,
                        division_id: divisionId,
                    })),
                }

                return createAffiliationInvoice(apiValues)
                    .then(() => {
                        showSuccess();
                        refreshTable();
                    })
                    .catch((e) => {
                        showError(e);
                    })
            }}
            successText={<p><Translate id='task.renewMembers.renew.success' /></p>}
        >
            {(formik) => (
                <>
                    <ModalHeader toggle={() => toggle()}>
                        <Translate id='task.renewMembers.title' />
                    </ModalHeader>
                    <ModalBody>
                        <p className="text-center text-dark font-medium">
                            <Translate id='task.renewMembers.text' />
                        </p>
                        <CollapsibleCard
                            title={<Translate id='task.renewMembers.members' values={{ count: members.length }} />}
                            subtitle={<Translate id='task.renewMembers.members.desc' values={{ count: members.length }} />}
                            className="mb-3"
                            initOpen={members.length == 1}
                        >
                            <div className="max-vh-50 pretty-scrollbar overflow-auto">
                                {members.map((member, index) => (
                                    <RenewMemberCard key={member.member_id} member={member} className={stringBuilder('py-2', { 'border-top': index !== 0 })} />
                                ))}
                            </div>
                        </CollapsibleCard>
                        <div className='mb-3'>
                            <Label className='text-muted'><Translate id='task.renewMembers.paymentMethod' /> <Required /></Label>
                            <FormikSelect
                                id='paymentMethod' name='paymentMethod'
                                renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                searchKeys={[
                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                ]}
                                loadData={(from) => {
                                    switch (from){
                                        case 'CDM':
                                            return utilsContext.getPaymentMethods()
                                                .then((paymentMethods) => paymentMethods.reduce((newArray, method) => {
                                                    if(method.category === 'MANUAL'){
                                                        newArray.push({
                                                            value: method.code,
                                                            label: method.name,
                                                            i18n: method.i18n,
                                                        })
                                                    }
                                                    return newArray
                                                }, []))
                                        default:
                                            break;
                                    }
                                }}
                            />
                        </div>
                        <FormikApiError />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                            <Translate id='misc.submit' />
                        </Button>
                        <Button color='primary' outline type='button' onClick={() => toggle()} disabled={formik.isSubmitting}>
                            <Translate id='misc.cancel' />
                        </Button>
                    </ModalFooter>
                </>
            )}
        </FormikModalForm>
    )
}

export default RenewMembersModal