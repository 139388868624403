import { Component } from 'react'

export class FormikObserver extends Component{
    // this compares previous and new received props to see whether or not formi has a change
    componentDidUpdate(prevProps){
        const prevValues = Object.values(prevProps.value).join(', ');
        const newValues = Object.values(this.props.value).join(', ');
        if(!!prevProps?.value && prevValues !== newValues){
            this.props.onChange(this.props.value)
        }
    }

    render(){ return null }
}

export default FormikObserver