import SpordleTableProvider, { SpordleTableView, useSpordleTable } from '@spordle/datatables';
import { FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
// Form & table
import { Form, Formik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
// Display
import {
    Button, Card,
    CardBody, Col, Collapse, DropdownItem,
    DropdownMenu, DropdownToggle, FormGroup, Label, Row, UncontrolledDropdown
} from "reactstrap";
// Helpers
import { array, object, string } from 'yup';
import CardSectionTitle from '../../../../../components/CardSectionTitle';
import CrossFade from '../../../../../components/crossFade/CrossFade';
import CustomAlert from '../../../../../components/CustomAlert';
import Required from '../../../../../components/formik/Required';
import OrganizationSearch from '../../../../../components/organization/OrganizationSearch';
import TablePagination from '../../../../../components/table/TablePagination';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
// Contexts
import { PeriodsContext } from '../../../../../contexts/contexts';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { RegistrationCategoriesContext } from '../../../../../contexts/RegistrationCategoriesContext';
import { RegistrationDivisionsContext } from '../../../../../contexts/RegistrationDivisionsContext';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { DisplayCategory } from '../../../TeamHelpers';

/**
 * Search step of the import teams modal
 * @param {Object} props
 * @param {function} props.toggle Toggle modal
 * @param {[object]} props.selected Selected teams
 * @param {function} props.removeTeam Remove selected team
 * @param {function} props.nextStep Function called when 'Next' button is clicked
 * @returns {JSX.Element}
 */
const TeamRolloverSearch = ({ checkAll, uncheckAll, removeTeam, selected, nextStep }) => {
    // -- Contexts
    const { filterChange, state, getFilters } = useSpordleTable();
    const { getGenericLocale } = useContext(I18nContext);
    const { activePeriods, selectedPeriod } = useContext(PeriodsContext);
    const organizationContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext);
    const categoriesContext = useContext(RegistrationCategoriesContext);
    const divisionsContext = useContext(RegistrationDivisionsContext);

    /**  @type {React.MutableRefObject<import('@spordle/spordle-select/dist/npm/types/components/SpordleSelect').default>} */
    const catSelectRef = useRef(null);
    /**  @type {React.MutableRefObject<import('@spordle/spordle-select/dist/npm/types/components/SpordleSelect').default>} */
    const divSelectRef = useRef(null);

    // -- States
    const [ collapseIsOpen, setCollapseIsOpen ] = useState(false);

    // -- React cycles
    useEffect(() => {
        if(selected.length <= 0){
            setCollapseIsOpen(false);
        }
    }, [ selected ])

    return (
        <Formik
            initialValues={{
                season: getFilters().search.period_id || '',
                organizations: getFilters().search.organisation_id?.split('.') || [ organizationContext.organisation_id ],
                divisionId: getFilters().search.division_id?.split('.') || [],
                teamCategoryId: getFilters().search.team_category_id?.split('.') || [],
                activity_period_id: getFilters().search.activity_period_id?.split('.') || [],
            }}
            validationSchema={object().shape({
                season: string().required(<Translate id='teams.teamSearch.addTeam.search.season.required' />),
                organizations: array().min(1, <Translate id='teams.teamSearch.addTeam.search.organization.required' />).max(25, <Translate id='teams.teamSearch.addTeam.search.organization.max' />),
                divisionId: array().min(1, <Translate id='teams.teamSearch.addTeam.search.division.required' />),
                teamCategoryId: array(),
                activity_period_id: array(),
            })}
            onSubmit={(values) => {
                const newVal = {
                    period_id: values.season,
                    organisation_id: values.organizations.join(','),
                    team_category_id: values.teamCategoryId.join(','),
                    division_id: values.divisionId.join(','),
                    activity_period_id: values.activity_period_id.join(','),
                }
                filterChange('search', newVal);
            }}
        >
            {(formik) => (
                <Form>
                    <Card className="card-shadow mb-3">
                        <CardBody>
                            <CardSectionTitle title='teams.teamSearch.rollover.wizard.step1' />
                            <div className="border-bottom mb-3">
                                <Row form>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='season' className='text-muted'><Translate id='members.profile.teams.previousSeason' /> <Required /></Label>
                                            <FormikSelect
                                                id="season"
                                                name="season"
                                                renderOption={({ option }) => <DisplayI18n defaultValue={option.label} i18n={option.i18n} field="name" />}
                                                searchKeys={[
                                                    `i18n.${getGenericLocale()}.name`,
                                                ]}
                                                options={activePeriods.reduce((shownP, period) => {
                                                    if(period.end_date < selectedPeriod.start_date){
                                                        shownP.push({
                                                            value: period.period_id,
                                                            i18n: period.i18n,
                                                            label: period.name,
                                                        })
                                                    }
                                                    return shownP;
                                                }, [])}
                                                onOptionSelected={(values) => {
                                                    catSelectRef.current?.getSpordleTable().filterChange('periodId', values[0])
                                                    divSelectRef.current?.getSpordleTable().filterChange('periodId', values[0])
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label for='organizations' className='text-muted'><Translate id='members.profile.teams.organizations' /> <Required /></Label>
                                            <OrganizationSearch
                                                id="organizations"
                                                name="organizations"
                                                fromIdentityRole
                                                withFormik
                                                fromApi
                                                multi
                                                selectedDefault={organizationContext.organisation_id}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label for='divisionId' className='text-muted'><Translate id='members.profile.teams.division' /> <Required /></Label>
                                            <FormikSelect
                                                ref={divSelectRef}
                                                clearable multi
                                                disabled={!formik.values.season}
                                                id="divisionId"
                                                name="divisionId"
                                                searchKeys={[
                                                    `i18n.${getGenericLocale()}.short_name`,
                                                ]}
                                                initFilter={{
                                                    periodId: formik.values.season || '',
                                                }}
                                                onOptionSelected={(values) => {
                                                    formik.setFieldValue('teamCategoryId', [])
                                                    catSelectRef.current.getSpordleTable().filterChange('divisionId', values.join())
                                                }}
                                                renderOption={({ option }) => <DisplayI18n field="short_name" defaultValue={option.label} i18n={option.i18n} />}
                                                loadData={(from, extra, spordleTable) => {
                                                    switch (from){
                                                        case 'FILTER':
                                                            spordleTable.setLoading();
                                                        case 'CDM':
                                                            if(extra.filters.periodId){
                                                                return divisionsContext.getRegistrationDivisions(organizationContext.organisation_id, { period_id: extra.filters.periodId })
                                                                    .then((divisions) => divisions.map((div) => ({
                                                                        value: div.division_id,
                                                                        label: div.short_name,
                                                                        i18n: div.i18n,
                                                                    })))
                                                            }
                                                            return Promise.resolve([])
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label for='teamCategoryId' className='text-muted'><Translate id='members.profile.teams.category' /></Label>
                                            <FormikSelect
                                                ref={catSelectRef}
                                                clearable multi
                                                disabled={!formik.values.season}
                                                id="teamCategoryId"
                                                name="teamCategoryId"
                                                searchKeys={[
                                                    `category.division.i18n.${getGenericLocale()}.short_name`,
                                                    `category.class.i18n.${getGenericLocale()}.short_name`,
                                                ]}
                                                initFilter={{
                                                    periodId: formik.values.season || '',
                                                    divisionId: formik.values.divisionId?.join() || '',
                                                }}
                                                renderOption={({ option }) => (
                                                    <>
                                                        <div className="mb-0">
                                                            <DisplayCategory category={option.category} short />
                                                        </div>
                                                        {option.category?.gender && <div className="small">{<Translate id={`form.fields.gender.${option.category?.gender.toLowerCase()}`} />}</div>}
                                                    </>
                                                )}
                                                loadData={(from, extra, spordleTable) => {
                                                    switch (from){
                                                        case 'FILTER':
                                                            spordleTable.setLoading();
                                                        case 'CDM':
                                                            if(extra.filters.periodId){
                                                                return categoriesContext.getRegistrationCategories(organizationContext.organisation_id, { team_view: 1, period_id: extra.filters.periodId, division_id: extra.filters.divisionId })
                                                                    .then((cats) => cats.map((cat) => ({
                                                                        value: cat.team_category_id,
                                                                        label: cat.name,
                                                                        category: cat,
                                                                    })))
                                                            }
                                                            return Promise.resolve([])
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {organizationContext.settings.enable_organization_activity_periods?.value == '1' &&
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label for='phone' className='text-muted'><Translate id='form.fields.activityPeriod' /></Label>
                                                <FormikSelect
                                                    name="activity_period_id"
                                                    id="activity_period_id"
                                                    multi clearable
                                                    renderOption={(option) => (
                                                        <>
                                                            <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                                            <div className="small text-muted"><DisplayI18n field='description' defaultValue={option.option.description} i18n={option.option.i18n} /></div>
                                                        </>
                                                    )}
                                                    loadData={(from) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return periodsContext.getOrganizationActivityPeriods({ organisation_id: organizationContext.organisation_id, active: '1' })
                                                                    .then((activityPeriods) => (activityPeriods.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order)).map((activityPeriod) => ({
                                                                        value: activityPeriod.activity_period_id,
                                                                        label: activityPeriod.name,
                                                                        description: activityPeriod.description,
                                                                        i18n: activityPeriod.i18n,
                                                                    }))));
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                    <Col sm={organizationContext.settings.enable_organization_activity_periods?.value == '1' ? '3' : '6'}>
                                        <FormGroup>
                                            <Label>&nbsp;</Label>
                                            <Button color="primary" block type="submit" disabled={state.loadingState === 'loading'}>
                                                <i className="ti-search" /> <Translate id='misc.search' />
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <CrossFade isVisible={formik.values.divisionId.length > 1 || formik.values.organizations.length > 1 || formik.values.teamCategoryId.length > 1}>
                                    <CustomAlert color='warning' withTitle text='teams.teamSearch.addTeam.search.data.warning' translateText />
                                </CrossFade>
                            </div>
                            <div className='mb-2'>
                                <div className='d-flex flex-wrap'>
                                    <UncontrolledDropdown>
                                        <DropdownToggle color='primary' className='d-flex align-items-center mr-2' caret>
                                            <div className='align-self-center custom-checkbox custom-control'>
                                                <input onChange={() => {}} checked={selected.length > 0} type='checkbox' className='custom-control-input d-none' />
                                                <label onClick={checkAll} className='custom-control-label' htmlFor='topCheckID' />
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={checkAll}>
                                                <Translate id='misc.all' />
                                            </DropdownItem>
                                            <DropdownItem onClick={uncheckAll}>
                                                <Translate id='misc.none' />
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <SpordleTableProvider.SearchInput />
                                    <TablePagination className="ml-auto" paginationArray={[ 0, 15, 25, 50, 100 ]} />
                                </div>
                            </div>
                            <SpordleTableView />
                            <div className="mt-3 text-muted">
                                {selected.length > 0 ?
                                    <>
                                        <button type="button" onClick={() => setCollapseIsOpen(!collapseIsOpen)} className="mb-3 reset-btn text-link">
                                            <Translate id='teams.teamSearch.addTeam.rollover.selectedTeams' />: <span className="font-medium">{selected.length}</span>
                                            <i style={{ transform: `rotate(${collapseIsOpen ? "180" : "0"}deg)` }} className="ml-1 d-inline-block mdi mdi-chevron-down transition" />
                                        </button>
                                        <Collapse isOpen={collapseIsOpen}>
                                            {selected.map((team) => (
                                                <UserDisplay key={team.team_id} className="d-flex mb-2 p-2" card>
                                                    <UserDisplay.Container className="d-flex align-items-center border-right pr-2 w-50">
                                                        <UserImg
                                                            abbr={team.abbreviation || team.short_name || team.name}
                                                            src={team.logo?.full_path}
                                                            filePos={team.logo?.file_position}
                                                            width={40}
                                                            alt={team.name}
                                                        />
                                                        <div className="pl-2">
                                                            <UserDisplay.Title>{team.name}</UserDisplay.Title>
                                                            {team.unique_identifier && <UserDisplay.Subtitle>#{team.unique_identifier}</UserDisplay.Subtitle>}
                                                        </div>
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container className="d-flex align-items-center w-50">
                                                        <UserImg
                                                            abbr={team.organisation.abbreviation || team.organisation.organisation_name}
                                                            src={team.organisation.logo?.full_path}
                                                            filePos={team.organisation.logo?.file_position}
                                                            width={40}
                                                            alt={team.organisation.organisation_name}
                                                        />
                                                        <UserDisplay.Title className="ml-2">
                                                            <DisplayI18n
                                                                field="name"
                                                                defaultValue={team.organisation.organisation_name}
                                                                i18n={team.organisation.i18n}
                                                            />
                                                        </UserDisplay.Title>
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container className="ml-auto w-50 text-right">
                                                        <button
                                                            onClick={() => removeTeam(team.team_id)}
                                                            className="reset-btn text-danger"
                                                            type='button'
                                                        >
                                                            <span className="small">
                                                                <Translate id="misc.remove" />
                                                            </span>
                                                        </button>
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            ))}
                                        </Collapse>
                                    </>
                                    :
                                    <>
                                        <Translate id='teams.teamSearch.addTeam.rollover.selectedTeams' />: <span className="text-dark font-medium">{selected.length}</span>
                                    </>
                                }
                            </div>
                        </CardBody>
                    </Card>
                    <div className="d-flex justify-content-end">
                        <Button disabled={selected.length <= 0} color='primary' onClick={nextStep}><Translate id='misc.next' /></Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default TeamRolloverSearch;