import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useRef, useState } from 'react';
import {
    Row,
    Col,
    Button,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label
} from "reactstrap";
import { Form, Formik } from 'formik';
import { FormikDateTime, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Required from '../formik/Required';
import { object, string, mixed } from 'yup';
import OrganizationSearch from '../organization/OrganizationSearch';
import moment from 'moment';
import { displayI18n, DisplayI18n } from '../../helpers/i18nHelper';

// contexts
import { AppealsAddContext } from './AppealsAddContext';
import { PeriodsContext } from '../../contexts/contexts';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { RegistrationCategoriesContext } from '../../contexts/RegistrationCategoriesContext';
import { I18nContext } from '../../contexts/I18nContext';
import CrossFade from '../crossFade/CrossFade';
import { TeamsContext } from '../../contexts/TeamsContext';
import UserImg from '../UserImg';
import { DisplayCategory } from '../../views/teams/TeamHelpers';
import { MembersContext } from '../../contexts/MembersContext';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { AppealsContext } from '../../contexts/AppealsContext';

const AppealsAdd1 = () => {

    // context
    const appealsAddContext = useContext(AppealsAddContext);
    const periodsContext = useContext(PeriodsContext);
    const organizationContext = useContext(OrganizationContext);
    const categoriesContext = useContext(RegistrationCategoriesContext);
    const i18nContext = useContext(I18nContext);
    const teamsContext = useContext(TeamsContext);
    const membersContext = useContext(MembersContext);
    const appealsContext = useContext(AppealsContext)

    // states
    const [ fromTeamisManual, setFromTeamIsManual ] = useState(!!appealsAddContext.state.formData.appeal_from_team_name);
    const toggleFromTeamIsManual = () => setFromTeamIsManual(!fromTeamisManual);
    const [ toTeamisManual, setToTeamIsManual ] = useState(!!appealsAddContext.state.formData.appeal_to_team_name);
    const toggleToTeamIsManual = () => setToTeamIsManual(!toTeamisManual);
    const [ orgCategoriesLoading, setOrgCategoriesLoading ] = useState(false);

    // selects
    /**  @type {React.MutableRefObject<import('@spordle/spordle-select/dist/components/SpordleSelect').default>} */
    const categorySelectRef = useRef();
    /**  @type {React.MutableRefObject<import('@spordle/spordle-select/dist/components/SpordleSelect').default>} */
    const fromTeamIdSelectRef = useRef();
    /**  @type {React.MutableRefObject<import('@spordle/spordle-select/dist/components/SpordleSelect').default>} */
    const toTeamIdSelectRef = useRef();

    const selectProps = () => {
        return {
            renderOption: ({ option }, fromSelectedOption) => (
                <div className='d-flex align-items-center'>
                    <div className="d-flex justify-content-center align-items-center mr-2">
                        <UserImg
                            abbr={option.short_name || option.label || ''}
                            src={option.logo?.full_path}
                            filePos={option.logo?.file_position}
                            width={!fromSelectedOption ? '40' : '20'}
                            height={!fromSelectedOption ? '40' : '20'}
                            alt={option.label + ' logo'}
                            className="p-1"
                        />
                    </div>
                    <div>
                        <span className='font-medium'>{option.label}</span>
                        {option.short_name &&
                            <span className='text-muted ml-1'>({option.short_name})</span>
                        }
                        {option.team_category && !fromSelectedOption &&
                            <div className='small text-muted text-truncate'>
                                <DisplayCategory category={option.team_category} short />
                            </div>
                        }
                    </div>
                </div>
            ),
            searchKeys: [
                'short_name',
                'team_category.class.name',
                'team_category.class.short_name',
                `team_category.class.i18n.${i18nContext.getGenericLocale()}.name`,
                `team_category.class.i18n.${i18nContext.getGenericLocale()}.short_name`,
                'team_category.division.name',
                'team_category.division.short_name',
                `team_category.division.i18n.${i18nContext.getGenericLocale()}.name`,
                `team_category.division.i18n.${i18nContext.getGenericLocale()}.short_name`,
            ],
            loadData: (from, extra, spordleTable) => {
                switch (from){
                    case 'FILTER':
                        spordleTable.setLoading();
                    case 'CDM':
                        if(extra.filters.orgId){
                            // more than 25000 rows causes a 500 error
                            return teamsContext.getTeams({ organisation_id: extra.filters.orgId, limit: 25000 })
                                .then((teams) => teams.map((team) => ({
                                    value: team.team_id,
                                    label: team.name,
                                    short_name: team.short_name,
                                    logo: team.logo,
                                    team_category: team.team_category,
                                })))
                        }
                        return Promise.resolve([]);


                }
            },
        }
    }

    const getPeriods = () => {
        if(appealsAddContext.state.periods){
            return Promise.resolve(formatPeriods(appealsAddContext.state.periods))
        }
        return periodsContext.getOrganizationPeriods(organizationContext.organisation_id)
            .then((_periods) => {
                appealsAddContext.setPeriods(_periods)
                return formatPeriods(_periods)
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

    }

    function formatPeriods(_periods){
        return _periods.reduce((newArray, period) => {
            if(period.active == 1){
                newArray.push({
                    value: period.period_id,
                    label: period.name,
                    i18n: period.i18n,
                })
            }
            return newArray
        }, []);
    }

    useEffect(() => {
        if(appealsAddContext.state.organizationCategoriesIds.length <= 0){
            setOrgCategoriesLoading(true);
            appealsContext.getAppealsOrgCategories()
                .then((categories) => {
                    appealsAddContext.setOrganizationCategoriesIds(categories);
                    setOrgCategoriesLoading(false);
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        setOrgCategoriesLoading(false);
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                });
        }
    }, [])

    return (
        <>
            <Formik
                initialValues={{
                    fromOrgId: appealsAddContext.state.formData.appeal_from_org_id || '',
                    fromTeamId: appealsAddContext.state.formData.appeal_from_team_id || '',
                    fromTeamName: appealsAddContext.state.formData.appeal_from_team_name || '',
                    toOrgId: appealsAddContext.state.formData.appeal_to_org_id || '',
                    toTeamId: appealsAddContext.state.formData.appeal_to_team_id || '',
                    toTeamName: appealsAddContext.state.formData.appeal_to_team_name || '',

                    periodId: appealsAddContext.state.formData.period_id || '',
                    divisionId: appealsAddContext.state.formData.division_id || '',
                    classId: appealsAddContext.state.formData.class_id || '',
                    categoryId: appealsAddContext.state.formData.team_category_id || '',
                    submittedDate: appealsAddContext.state.formData.appeal_date ? moment(appealsAddContext.state.formData.appeal_date) : '',
                    hearingDate: appealsAddContext.state.formData.appeal_approve_date ? moment(appealsAddContext.state.formData.appeal_approve_date) : '',
                    sentDate: appealsAddContext.state.formData.appeal_sent_date ? moment(appealsAddContext.state.formData.appeal_sent_date) : '',
                    regulationId: appealsAddContext.state.formData.appeal_regulation_id || '',
                }}
                validationSchema={object().shape({
                    fromOrgId: string().required(<Translate id='members.profile.appeals.sidepanel.add.organization.required' />)
                        .test({
                            name: 'differentOrgsTest',
                            message: <Translate id='members.profile.appeals.sidepanel.add.organization.same' />,
                            test: function(fromOrgId){
                                return fromOrgId !== this.parent.toOrgId
                            },
                        }),
                    fromTeamId: string().test({
                        name: 'requiredTestFrom',
                        message: <Translate id='members.profile.appeals.sidepanel.add.teamName.select.required' />,
                        test: (value) => {
                            if(!fromTeamisManual){
                                return !!value
                            }
                            return true
                        },
                    }),
                    fromTeamName: string().test({
                        name: 'requiredTestFromName',
                        message: <Translate id='members.profile.appeals.sidepanel.add.teamName.required' />,
                        test: (value) => {
                            if(fromTeamisManual){
                                return !!value
                            }
                            return true
                        },
                    }),
                    toOrgId: string().required(<Translate id='members.profile.appeals.sidepanel.add.organization.required' />)
                        .test({
                            name: 'differentOrgsTest2',
                            message: <Translate id='members.profile.appeals.sidepanel.add.organization.same' />,
                            test: function(toOrgId){
                                return toOrgId !== this.parent.fromOrgId
                            },
                        }),
                    toTeamId: string().test({
                        name: 'requiredTestTo',
                        message: <Translate id='members.profile.appeals.sidepanel.add.teamName.select.required' />,
                        test: (value) => {
                            if(!toTeamisManual){
                                return !!value
                            }
                            return true
                        },
                    }),
                    toTeamName: string().test({
                        name: 'requiredTestToName',
                        message: <Translate id='members.profile.appeals.sidepanel.add.teamName.required' />,
                        test: (value) => {
                            if(toTeamisManual){
                                return !!value
                            }
                            return true
                        },
                    }),

                    periodId: string().required(<Translate id='members.profile.appeals.sidepanel.add.period.required' />),
                    divisionId: string(),
                    classId: string(),
                    categoryId: string().required(<Translate id='members.profile.appeals.category.required' />),
                    submittedDate: mixed().required(<Translate id='members.profile.appeals.submittedDate.required' />)
                        .test({
                            name: 'submittedDateFormatTest',
                            message: <Translate id='form.validation.date.format' />,
                            test: moment.isMoment,
                        }),
                    hearingDate: mixed()
                        .test({
                            name: 'hearingDateFormatTest',
                            message: <Translate id='form.validation.date.format' />,
                            test: (date) => {
                                if(date){
                                    return moment.isMoment(date)
                                }
                                return true // not required
                            },
                        })
                        .test({
                            name: 'hearingDateAfterSentDate',
                            message: <Translate id='members.profile.appeals.hearingDate.beforeSent' />,
                            test: function(date){
                                if(date && this.parent.sentDate){
                                    return moment(date).isSameOrBefore(moment(this.parent.sentDate))
                                }
                                return true
                            },
                        })
                        .test({
                            name: 'hearingDateAfterAppealDate',
                            message: <Translate id='members.profile.appeals.hearingDate.afterSubmitted' />,
                            test: function(date){
                                if(date && this.parent.submittedDate){
                                    return moment(date).isAfter(moment(this.parent.submittedDate))
                                }
                                return true
                            },
                        }),
                    sentDate: mixed()
                        .test({
                            name: 'sentDateFormatTest',
                            message: <Translate id='form.validation.date.format' />,
                            test: (date) => {
                                if(date){
                                    return moment.isMoment(date)
                                }
                                return true // not required
                            },
                        }).test({
                            name: 'sentDateBeforeHearingDate',
                            message: <Translate id='members.profile.appeals.sentDate.afterHearing' />,
                            test: function(date){
                                if(date && this.parent.hearingDate){
                                    return moment(date).isSameOrAfter(moment(this.parent.hearingDate))
                                }
                                return true
                            },
                        }),
                    regulationId: string(),
                })}
                onSubmit={(values) => {
                    appealsAddContext.setData({
                        appeal_from_org_id: values.fromOrgId,
                        appeal_from_team_id: fromTeamisManual ? '' : values.fromTeamId,
                        appeal_from_team_name: fromTeamisManual ? values.fromTeamName : '',
                        appeal_to_org_id: values.toOrgId,
                        appeal_to_team_id: toTeamisManual ? '' : values.toTeamId,
                        appeal_to_team_name: toTeamisManual ? values.toTeamName : '',

                        period_id: values.periodId,
                        division_id: values.divisionId,
                        class_id: values.classId,
                        team_category_id: values.categoryId,
                        appeal_date: values.submittedDate.toISOString(),
                        appeal_approve_date: values.hearingDate ? values.hearingDate.toISOString() : '',
                        appeal_sent_date: values.sentDate ? values.sentDate.toISOString() : '',
                        appeal_regulation_id: values.regulationId,
                    })
                    appealsAddContext.goToView(appealsAddContext.views.invoiceInfo)
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalBody>
                            <div className="mt-2">
                                <div className="h6 font-bold"><Translate id='members.profile.appeals.from' /></div>
                                <FormGroup>
                                    <Label for='fromOrgId' className='text-muted'><Translate id='members.profile.appeals.sidepanel.add.organization' /> <Required /></Label>
                                    <OrganizationSearch
                                        id="fromOrgId" name="fromOrgId" withFormik
                                        key={appealsAddContext.state.organizationCategoriesIds}
                                        fromIdentityRole
                                        onOptionSelected={(values) => {
                                            formik.setFieldValue('fromTeamId', '')
                                            fromTeamIdSelectRef.current?.getSpordleTable().filterChange('orgId', values[0]);
                                            categorySelectRef.current?.getSpordleTable().filterChange('orgId', values[0]);
                                        }}
                                        categories={appealsAddContext.state.organizationCategoriesIds}
                                        isLoading={orgCategoriesLoading}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <div className='d-flex'>
                                        <Label for='fromTeamName' className='text-muted'>
                                            <Translate id='members.profile.appeals.sidepanel.add.teamName' /> <Required />
                                        </Label>
                                        <button className='reset-btn text-link ml-auto mb-2' type='button' onClick={toggleFromTeamIsManual}>
                                            <Translate id={fromTeamisManual ? 'members.profile.appeals.sidepanel.add.teamName.select' : 'members.profile.appeals.sidepanel.add.teamName.manual'} />
                                        </button>
                                    </div>

                                    <CrossFade isVisible={fromTeamisManual}>
                                        <FormikInputText id='fromTeamName' name='fromTeamName' />
                                    </CrossFade>
                                    <CrossFade isVisible={!fromTeamisManual}>
                                        <FormikSelect
                                            id='fromTeamId' name='fromTeamId'
                                            ref={fromTeamIdSelectRef}
                                            initFilter={{
                                                orgId: appealsAddContext.state.formData.appeal_from_org_id,
                                            }}
                                            {...selectProps()}
                                        />
                                    </CrossFade>
                                </FormGroup>
                            </div>
                            <div className="mt-2">
                                <div className="h6 font-bold"><Translate id='members.profile.appeals.to' /></div>
                                <FormGroup>
                                    <Label for='toOrgId' className='text-muted'><Translate id='members.profile.appeals.sidepanel.add.organization' /> <Required /></Label>
                                    <OrganizationSearch
                                        id="toOrgId" name="toOrgId" withFormik
                                        key={appealsAddContext.state.organizationCategoriesIds}
                                        fromIdentityRole
                                        onOptionSelected={(values) => {
                                            formik.setFieldValue('toTeamId', '')
                                            toTeamIdSelectRef.current?.getSpordleTable().filterChange('orgId', values[0]);
                                        }}
                                        categories={appealsAddContext.state.organizationCategoriesIds}
                                        isLoading={orgCategoriesLoading}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <div className='d-flex'>
                                        <Label for='toTeamName' className='text-muted'>
                                            <Translate id='members.profile.appeals.sidepanel.add.teamName' /> <Required />
                                        </Label>
                                        <button className='reset-btn text-link ml-auto mb-2' type='button' onClick={toggleToTeamIsManual}>
                                            <Translate id={toTeamisManual ? 'members.profile.appeals.sidepanel.add.teamName.select' : 'members.profile.appeals.sidepanel.add.teamName.manual'} />
                                        </button>
                                    </div>

                                    <CrossFade isVisible={toTeamisManual}>
                                        <FormikInputText id='toTeamName' name='toTeamName' />
                                    </CrossFade>
                                    <CrossFade isVisible={!toTeamisManual}>
                                        <FormikSelect
                                            id='toTeamId' name='toTeamId'
                                            ref={toTeamIdSelectRef}
                                            initFilter={{
                                                orgId: appealsAddContext.state.formData.appeal_to_org_id,
                                            }}
                                            {...selectProps()}
                                        />
                                    </CrossFade>
                                </FormGroup>
                            </div>
                            <div className="mt-2">
                                <div className="h6 font-bold"><Translate id='members.profile.appeals.sidepanel.add.details' /></div>
                                <Row form>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='periodId' className='text-muted'><Translate id='members.profile.appeals.period' /> <Required /></Label>
                                            <FormikSelect
                                                name='periodId'
                                                id='periodId'
                                                renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                                searchKeys={[
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                ]}
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            return getPeriods();
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='categoryId' className='text-muted'><Translate id='members.profile.appeals.category' /> <Required /></Label>
                                            <FormikSelect
                                                name='categoryId'
                                                id='categoryId'
                                                ref={categorySelectRef}
                                                initFilter={{
                                                    orgId: formik.values.fromOrgId,
                                                }}
                                                loadData={(from, extra, spordleTable) => {
                                                    switch (from){
                                                        case 'FILTER':
                                                            spordleTable.setLoading();
                                                        case 'CDM':
                                                            if(extra.filters.orgId){
                                                                return categoriesContext.getRegistrationCategories(extra.filters.orgId)
                                                                    .then((categories) => {
                                                                        return categories.reduce((newArray, category) => {
                                                                            if(category.active == 1){
                                                                                const catDivision = displayI18n('short_name', category.division.i18n, category.division.short_name, i18nContext.getGenericLocale());
                                                                                const catClass = category.class && displayI18n('short_name', category.class.i18n, category.class.short_name, i18nContext.getGenericLocale());
                                                                                const label = `${catDivision}${catClass ? ' - ' + catClass : ''}`;

                                                                                newArray.push({
                                                                                    value: category.team_category_id,
                                                                                    label: label,
                                                                                })
                                                                            }
                                                                            return newArray
                                                                        }, [])
                                                                    })
                                                            }
                                                            return Promise.resolve([]);

                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='submittedDate' className='text-muted'><Translate id='members.profile.appeals.submittedDate' /> <Required /></Label>
                                            <FormikDateTime name='submittedDate' id='submittedDate' timeFormat={false} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='hearingDate' className='text-muted'><Translate id='members.profile.appeals.hearingDate' /></Label>
                                            <FormikDateTime
                                                name='hearingDate' id='hearingDate'
                                                timeFormat={false}
                                                isValidDate={(current) => {
                                                    const sentDateValid = formik.values.sentDate ? moment(current).isSameOrBefore(moment(formik.values.sentDate)) : true
                                                    const submittedDateValid = formik.values.submittedDate ? moment(current).isAfter(moment(formik.values.submittedDate)) : true
                                                    return sentDateValid && submittedDateValid
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='sentDate' className='text-muted'><Translate id='members.profile.appeals.sentDate' /></Label>
                                            <FormikDateTime
                                                name='sentDate' id='sentDate'
                                                timeFormat={false}
                                                isValidDate={(current) => {
                                                    return formik.values.hearingDate ? moment(current).isSameOrAfter(moment(formik.values.hearingDate)) : true
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='regulationId' className='text-muted'><Translate id='members.profile.appeals.regulation' /></Label>
                                            <FormikSelect
                                                name='regulationId'
                                                id='regulationId'
                                                searchKeys={[
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                ]}
                                                renderOption={(option) => <DisplayI18n field="name" defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                loadData={(from, extra, spordleTable) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            return membersContext.getAppealRegulations()
                                                                .then((regulations) => regulations.reduce((newArray, regulation) => {
                                                                    if(regulation.active == 1){
                                                                        newArray.push({
                                                                            value: regulation.appeal_regulation_id,
                                                                            label: regulation.name,
                                                                            i18n: regulation.i18n,
                                                                        })
                                                                    }
                                                                    return newArray
                                                                }, []))
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </ModalBody>
                        <ModalFooter className='justify-content-end'>
                            <Button color='primary' type='submit' className='mr-2'><Translate id='misc.next' /></Button>
                            <Button color='primary' type='button' onClick={appealsAddContext.toggleModal} outline><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default AppealsAdd1;