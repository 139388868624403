import manualRegistrationForms from "./manualRegistrationForms";
import manualRegistrationWaivers from "./manualRegistrationWaivers";
import manualRegistrationPaymentMethods from "./manualRegistrationPaymentMethods";
import manualRegistrationConfirmation from "./manualRegistrationConfirmation";
import manualRegistrationOtherItems from "./manualRegistrationOtherItems";
import manualRegistrationConvertInvoiceConfirm from "./manualRegistrationConvertInvoiceConfirm";
import manualRegistrationWaitingList from "./manualRegistrationWaitingList";

export default{
    ...manualRegistrationForms,
    ...manualRegistrationWaivers,
    ...manualRegistrationPaymentMethods,
    ...manualRegistrationConfirmation,
    ...manualRegistrationOtherItems,
    ...manualRegistrationConvertInvoiceConfirm,
    ...manualRegistrationWaitingList,
}