import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements'
import { stringBuilder } from "@spordle/helpers";
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Col, Collapse, Fade, ListGroupItem, Row } from 'reactstrap'
import { DisplayI18n } from '../../../../helpers/i18nHelper'
import EnforcedLabel from './EnforcedLabel'
import TeamSettingWarningsMembers from './TeamSettingWarningsMembers'

const TeamSettingsRestrictionsRow = ({ teamSettingCode, setting, canSeeMember }) => {
    const [ showWarnings, setShowWarnings ] = useState(false);
    const { memberRelatedWarnings, genericWarnings } = setting.warnings.reduce((warningTypes, warning) => {
        if(warning.resource){
            warningTypes.memberRelatedWarnings.push(warning);
        }else{
            warningTypes.genericWarnings.push(warning);
        }
        return warningTypes;
    }, {
        memberRelatedWarnings: [],
        genericWarnings: [],
    })

    return (
        <ListGroupItem className={setting.warnings.length ? 'bg-light-warning' : undefined}>
            <Row className={setting.warnings.length ? 'clickable' : undefined} onClick={setting.warnings.length ? () => { setShowWarnings(!showWarnings) } : undefined}>
                <Col sm="8" className='mb-3 mb-sm-0'>
                    <div className='d-sm-none font-bold text-dark'><Translate id='teams.profile.teamSetting.restrictions.settingName' /></div>
                    <div className='text-dark'><DisplayI18n field='name' defaultValue={setting.name} i18n={setting.i18n} /></div>
                    <FormattedMessage id={`teams.profile.teamSetting.restrictions.description.${teamSettingCode}`} defaultMessage="noTranslate">
                        {(message) => (message[0] !== 'noTranslate') ? <div className="small">{message}</div> : ''}
                    </FormattedMessage>
                    {setting.enforced == '1' && <div className="mt-1"><EnforcedLabel /></div>}
                </Col>
                <Col sm="4" className='text-sm-center mb-0'>
                    <div className='d-sm-none font-bold text-dark'><Translate id='teams.profile.teamSetting.restrictions.value' /></div>
                    {teamSettingCode === 'team_fee' ? // only team fee is displayed as a price
                        <CurrencyFormat value={setting.value / 100} />
                        :
                        <SettingValue value={setting.value} response={setting.response} actualValue={setting.actual_value} />
                    }
                    {setting.warnings.length > 0 && <i className={stringBuilder('position-absolute d-inline-block transition right-0 top-0 mdi mdi-chevron-down', { "rotate-180": showWarnings })} />}
                </Col>
            </Row>
            <Collapse isOpen={showWarnings}>
                <Fade in={showWarnings}>
                    {genericWarnings.length > 0 &&
                        <ul className='py-2 pl-3'>
                            {genericWarnings.map((warning) => (
                                <li key={warning.code + warning.origin}>
                                    <Translate id={`teams.profile.settings.error.${warning.code}.raw`} />
                                </li>
                            ))}
                        </ul>
                    }
                    {memberRelatedWarnings.length > 0 &&
                        <TeamSettingWarningsMembers warnings={memberRelatedWarnings} canSeeMember={canSeeMember} />
                    }
                </Fade>
            </Collapse>
        </ListGroupItem>
    )
}

const SettingValue = ({ value, response, actualValue }) => {
    const parsedResponse = JSON.parse(response)
    const isDate = /\d{4}-\d{2}-\d{2}/; // match this format 0000-00-00 (not exact, so there can by things after or before)

    switch (value){
        case 'no':
        case 'yes':
            return <i className={stringBuilder('mdi', { 'mdi-close text-danger': value == 'no', 'mdi-check text-primary': value == 'yes' })} />
        default:
            if(response){
                try{
                    const parsedResponse = JSON.parse(response);
                    return <DisplayI18n field='name' defaultValue={value} i18n={parsedResponse[value].i18n} />
                }catch(_error){
                    // Do nothing
                }
            }
            if(typeof parsedResponse === 'object' && response !== null && !actualValue){
                const splitValues = value.split(',');
                return (
                    <ul className='mb-0' style={{ marginLeft: '60px' }}>
                        {
                            splitValues.map((item) => {
                                return (
                                    <li key={item} className='text-left'><DisplayI18n field='name' defaultValue={item} i18n={parsedResponse[item]?.i18n} /></li>
                                )
                            })
                        }
                    </ul>
                )
            }
            if(value.match(isDate)){
                return (
                    <DateFormat value={value} />
                )
            }
            return (
                <>
                    {actualValue &&
                            <>
                                {actualValue}
                                <span className='mx-1'>/</span>
                            </>
                    }
                    {value}
                </>
            );
    }
}

export default TeamSettingsRestrictionsRow
