export default{
    availableWidgets: [
        {
            positioning: {
                xxs: {
                    x: 0,
                    y: 0,
                },
                xs: {
                    x: 0,
                    y: 0,
                },
                sm: {
                    x: 0,
                    y: 0,
                },
                md: {
                    x: 0,
                    y: 0,
                },
                lg: {
                    x: 0,
                    y: 0,
                },
            },
            widgetId: '2',
            type: 'small',
            label: 'Members',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            i18n: {
                en: { label: 'Members', description: '' },
                fr: { label: 'Membres', description: '' },
            },
            dataType: {
                name: 'Count',
                code: 'count',
                i18n: {
                    fr: { name: 'Compteur', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                    en: { name: 'Count', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                },
            },
            data: '90',
            link: 'members',
        },
        {
            positioning: {
                xxs: {
                    x: 0,
                    y: 1,
                },
                xs: {
                    x: 1,
                    y: 0,
                },
                sm: {
                    x: 0,
                    y: 1,
                },
                md: {
                    x: 0,
                    y: 1,
                },
                lg: {
                    x: 0,
                    y: 1,
                },
            },
            widgetId: '3',
            type: 'small',
            label: 'Teams',
            description: 'Generated amount of money from teams',
            i18n: {
                en: { label: 'Teams', description: 'Generated amount of money from teams' },
                fr: { label: 'Équipes', description: 'Génération d\'argent des équipes' },
            },
            dataType: {
                name: 'Currency',
                code: 'currency',
                i18n: {
                    fr: { name: 'Argent', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                    en: { name: 'Currency', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                },
            },
            data: '500',
            link: 'teams',
        },
        {
            positioning: {
                xxs: {
                    x: 0,
                    y: 2,
                },
                xs: {
                    x: 0,
                    y: 1,
                },
                sm: {
                    x: 1,
                    y: 0,
                },
                md: {
                    x: 1,
                    y: 0,
                },
                lg: {
                    x: 1,
                    y: 0,
                },
            },
            widgetId: '1',
            type: 'medium',
            label: 'Registrations',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            i18n: {
                en: { label: 'Registrations', description: '' },
                fr: { label: 'Inscriptions', description: '' },
            },
            dataType: {
                name: 'Bar graph',
                code: 'bar-graph',
                i18n: {
                    fr: { name: 'Graphique à barres', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                    en: { name: 'Bar graph', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                },
            },
            data: {
                options: {
                    chart: {
                        id: "onlineStoresGraph",
                        type: 'bar',
                        stacked: true,
                        parentHeightOffset: 0,
                        redrawOnParentResize: true,
                    },
                    stroke: {
                        width: 1,
                        colors: [ '#fff' ],
                    },
                    xaxis: {
                        categories: [ 'Hockey Registration 2021-2022', 'Test Shaun 2' ],
                    },
                    fill: {
                        opacity: 1,
                    },
                    legend: {
                        onItemHover: {
                            highlightDataSeries: true,
                        },
                        onItemClick: {
                            toggleDataSeries: true,
                        },
                    },
                    colors: [ '#FFA900', '#00B74A', '#dd0000' ],
                },
                series: [
                    {
                        name: 'Pending',
                        data: [ 44, 55 ],
                    },
                    {
                        name: 'Confirmed',
                        data: [ 53, 32 ],
                    },
                    {
                        name: 'Cancelled',
                        data: [ 12, 17 ],
                    },
                ],
            },
            link: 'onlinestores',
        },
        {
            positioning: {
                xxs: {
                    x: 0,
                    y: 4,
                },
                xs: {
                    x: 1,
                    y: 3,
                },
                sm: {
                    x: 0,
                    y: 2,
                },
                md: {
                    x: 3,
                    y: 0,
                },
                lg: {
                    x: 3,
                    y: 0,
                },
            },
            widgetId: '4',
            type: 'small',
            label: 'Online Stores',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            i18n: {
                en: { label: 'Online Stores', description: '' },
                fr: { label: 'Inscriptions en ligne', description: '' },
            },
            dataType: {
                name: 'Count',
                code: 'count',
                i18n: {
                    fr: { name: 'Compteur', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                    en: { name: 'Count', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                },
            },
            data: '7',
            link: 'onlinestores',
        },
        {
            positioning: {
                xxs: {
                    x: 0,
                    y: 5,
                },
                xs: {
                    x: 0,
                    y: 3,
                },
                sm: {
                    x: 1,
                    y: 2,
                },
                md: {
                    x: 3,
                    y: 1,
                },
                lg: {
                    x: 3,
                    y: 1,
                },
            },
            widgetId: '5',
            type: 'small',
            label: 'Clinics',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            i18n: {
                en: { label: 'Clinics', description: '' },
                fr: { label: 'Stages', description: '' },
            },
            dataType: {
                name: 'Count',
                code: 'count',
                i18n: {
                    fr: { name: 'Compteur', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                    en: { name: 'Count', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                },
            },
            data: '6',
            link: 'clinics',
        },
        {
            positioning: {
                xxs: {
                    x: 0,
                    y: 6,
                },
                xs: {
                    x: 0,
                    y: 4,
                },
                sm: {
                    x: 0,
                    y: 3,
                },
                md: {
                    x: 0,
                    y: 2,
                },
                lg: {
                    x: 0,
                    y: 2,
                },
            },
            widgetId: '6',
            type: 'large',
            label: 'Sales Overview',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            i18n: {
                en: { label: 'Sales Overview', description: '' },
                fr: { label: 'Sommaire des ventes', description: '' },
            },
            dataType: {
                name: 'Line graph',
                code: 'line-graph',
                i18n: {
                    fr: { name: 'Graphique linéaire', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                    en: { name: 'Line graph', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                },
            },
            data: {
                options: {
                    chart: {
                        id: "basic-bar",
                    },
                    xaxis: {
                        categories: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    markers: {
                        size: 4,
                    },
                    colors: [ '#3994FF', '#0060cf', '#0a3d78' ],
                    stroke: {
                        curve: 'straight',
                        width: '3',
                    },
                    grid: {
                        borderColor: 'rgba(0,0,0,0.2)',
                    },
                },
                series: [
                    {
                        name: "Registrations",
                        data: [ 0, 100, 260, 300, 360, 500, 550, 700, 800, 860, 1000, 1200 ],
                    },
                    {
                        name: "Clinics",
                        data: [ 0, 60, 220, 300, 320, 360, 380, 420, 430, 435, 460, 520 ],
                    },
                    {
                        name: "Others",
                        data: [ 0, 20, 30, 60, 80, 120, 130, 145, 160, 220, 240, 290 ],
                    },
                ],
            },
            link: null,
        },
        {
            positioning: {
                xxs: {
                    x: 0,
                    y: 9,
                },
                xs: {
                    x: 0,
                    y: 7,
                },
                sm: {
                    x: 0,
                    y: 6,
                },
                md: {
                    x: 0,
                    y: 5,
                },
                lg: {
                    x: 0,
                    y: 5,
                },
            },
            widgetId: '7',
            type: 'tall',
            label: 'Transfers',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            i18n: {
                en: { label: 'Transfers', description: '' },
                fr: { label: 'Transfert', description: '' },
            },
            dataType: {
                name: 'Preview',
                code: 'preview',
                i18n: {
                    fr: { name: 'Aperçu', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                    en: { name: 'Preview', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                },
            },
            data: {
                headers: [
                    {
                        headerId: '1',
                        data: '100',
                        label: 'Opened Transfers',
                        i18n: {
                            en: { label: 'Opened Transfers' },
                            fr: { label: 'Transferts ouverts' },
                        },
                    },
                    {
                        headerId: '2',
                        data: '9',
                        label: 'Action Required',
                        i18n: {
                            en: { label: 'Action Required' },
                            fr: { label: 'Action requise' },
                        },
                    },
                ],
                content: [
                    {
                        "member_transfer_id": "1ebc8691-b9b0-6d40-abf5-060fdfa4c4f0",
                        "transfer_type": "ASSOCIATION",
                        "transfer_reason_type_id": "25a685e6-b970-11eb-b6f2-06b680167fa2",
                        "transfer_status_id": "d1e8b839-b7cc-11eb-b6f2-06b680167fa2",
                        "transfer_request_state": "OPEN",
                        "period_id": "1ec85e7b-a7d4-6fea-aef3-a8a1592b6e57",
                        "member_id": "1ebb6583-c7e3-6f78-bf90-068fc9a36a40",
                        "workflow_id": null,
                        "current_step": null,
                        "created_at": "2021-06-08T14:52:12Z",
                        "updated_at": "2021-06-08T14:52:12Z",
                        "current_workflow_step": null,
                        "previous_action_done": null,
                        "organisation_id": "1eb81976-c8a2-6efc-abda-06ba8f7ba4e0",
                        "target_organisation_id": "1ebbd84f-ce35-6630-9e6b-02bb3c474244",
                        "member": {
                            "member_id": "1ebb6583-c7e3-6f78-bf90-068fc9a36a40",
                            "unique_identifier": "1500000618575",
                            "first_name": "member",
                            "last_name": "2207601",
                            "gender": "MALE",
                            "birthdate": "2011-09-28",
                            "age": 10,
                        },
                        "organisation": {
                            "organisation_id": "1eb81976-c8a2-6efc-abda-06ba8f7ba4e0",
                            "organisation_name": "ORILLIA",
                            "abbreviation": "O",
                            "i18n": {
                                "fr": {
                                    "name": "ORILLIA",
                                    "description": "",
                                    "active": "1",
                                },
                                "en": {
                                    "name": "ORILLIA",
                                    "description": "",
                                    "active": "1",
                                },
                            },
                        },
                        "target_organisation": {
                            "organisation_id": "1ebbd84f-ce35-6630-9e6b-02bb3c474244",
                            "organisation_name": "LAKEFIELD",
                            "abbreviation": "L",
                            "i18n": {
                                "fr": {
                                    "name": "LAKEFIELD",
                                    "description": "",
                                    "active": "1",
                                },
                                "en": {
                                    "name": "LAKEFIELD",
                                    "description": "",
                                    "active": "1",
                                },
                            },
                        },
                        "period": {
                            "period_id": "1ec85e7b-a7d4-6fea-aef3-a8a1592b6e57",
                            "name": "2021-2022",
                            "active": "1",
                            "i18n": null,
                        },
                        "transfer_reason_type": {
                            "transfer_reason_type_id": "25a685e6-b970-11eb-b6f2-06b680167fa2",
                            "name": "Share for a year",
                            "active": "1",
                            "i18n": {
                                "fr": {
                                    "name": "Partager pour un an",
                                    "active": "1",
                                },
                                "en": {
                                    "name": "Share for a year",
                                    "active": "1",
                                },
                            },
                        },
                        "transfer_status": {
                            "transfer_status_id": "d1e8b839-b7cc-11eb-b6f2-06b680167fa2",
                            "name": "Open",
                            "active": "1",
                            "i18n": {
                                "en": {
                                    "name": "Open",
                                    "active": "1",
                                },
                                "fr": {
                                    "name": "À L'Étude",
                                    "active": "1",
                                },
                            },
                        },
                    },
                    {
                        "member_transfer_id": "1ebebdcb-5135-6a90-9f15-02694ce0d08e",
                        "transfer_type": "ASSOCIATION",
                        "transfer_reason_type_id": "5f03f09b-bb28-11eb-b6f2-06b680167fa2",
                        "transfer_status_id": "d1e8b839-b7cc-11eb-b6f2-06b680167fa2",
                        "transfer_request_state": "OPEN",
                        "period_id": "1ec85e7b-a7d4-6fea-aef3-a8a1592b6e57",
                        "member_id": "1ebbdc43-b7df-665e-816d-02262e436632",
                        "workflow_id": "d17da0ff-b31e-11eb-b6f2-06b680167fa2",
                        "current_step": "2",
                        "created_at": "2021-07-23T17:37:52Z",
                        "updated_at": "2021-07-23T17:37:52Z",
                        "current_workflow_step": "1ebbdc03-1a36-68ac-987f-0678bdd6e0be",
                        "previous_action_done": null,
                        "organisation_id": "1ebbdc03-1a36-68ac-987f-0678bdd6e0be",
                        "target_organisation_id": "1ebbdc01-484a-6754-a85c-0678bdd6e0be",
                        "member": {
                            "member_id": "1ebbdc43-b7df-665e-816d-02262e436632",
                            "unique_identifier": "1500001080727",
                            "first_name": "member",
                            "last_name": "4330577",
                            "gender": "MALE",
                            "birthdate": "1986-05-20",
                            "age": 35,
                        },
                        "organisation": {
                            "organisation_id": "1ebbdc03-1a36-68ac-987f-0678bdd6e0be",
                            "organisation_name": "COLLINGWOOD ADMIRALS JR. C",
                            "abbreviation": "CAJC",
                            "i18n": {
                                "fr": {
                                    "name": "COLLINGWOOD ADMIRALS JR. C",
                                    "description": "",
                                    "active": "1",
                                },
                                "en": {
                                    "name": "COLLINGWOOD ADMIRALS JR. C",
                                    "description": "",
                                    "active": "1",
                                },
                            },
                        },
                        "target_organisation": {
                            "organisation_id": "1ebbdc01-484a-6754-a85c-0678bdd6e0be",
                            "organisation_name": "COLLINGWOOD COLTS",
                            "abbreviation": "CC",
                            "i18n": {
                                "fr": {
                                    "name": "COLLINGWOOD COLTS",
                                    "description": "",
                                    "active": "1",
                                },
                                "en": {
                                    "name": "COLLINGWOOD COLTS",
                                    "description": "",
                                    "active": "1",
                                },
                            },
                        },
                        "period": {
                            "period_id": "1ec85e7b-a7d4-6fea-aef3-a8a1592b6e57",
                            "name": "2021-2022",
                            "active": "1",
                            "i18n": null,
                        },
                        "transfer_reason_type": {
                            "transfer_reason_type_id": "5f03f09b-bb28-11eb-b6f2-06b680167fa2",
                            "name": "Bench Staff",
                            "active": "1",
                            "i18n": {
                                "fr": {
                                    "name": "Personnel de banc",
                                    "active": "1",
                                },
                                "en": {
                                    "name": "Bench Staff",
                                    "active": "1",
                                },
                            },
                        },
                        "transfer_status": {
                            "transfer_status_id": "d1e8b839-b7cc-11eb-b6f2-06b680167fa2",
                            "name": "Open",
                            "active": "1",
                            "i18n": {
                                "en": {
                                    "name": "Open",
                                    "active": "1",
                                },
                                "fr": {
                                    "name": "À L'Étude",
                                    "active": "1",
                                },
                            },
                        },
                    },
                    {
                        "member_transfer_id": "1ebee49e-63e7-6f36-bc19-06d07a919fd4",
                        "transfer_type": "BRANCH",
                        "transfer_reason_type_id": "bb7e38e2-a78c-11eb-b6f2-06b680167fa2",
                        "transfer_status_id": "d1e8b839-b7cc-11eb-b6f2-06b680167fa2",
                        "transfer_request_state": "OPEN",
                        "period_id": "1ec85e7b-a7d4-6fea-aef3-a8a1592b6e57",
                        "member_id": "1eb95a5e-2142-6eee-be9d-02ab9829ff00",
                        "workflow_id": "760737d3-c3a4-11eb-b24f-06b0069ada2c",
                        "current_step": "3",
                        "created_at": "2021-07-26T19:44:32Z",
                        "updated_at": "2021-07-26T20:51:57Z",
                        "current_workflow_step": "1eb5da90-685c-62fe-875d-06ba8f7ba4e0",
                        "previous_action_done": "APPROVE",
                        "organisation_id": "1eb81466-e50e-6ce0-95b7-06ba8f7ba4e0",
                        "target_organisation_id": "1ebbdbfe-4f32-6e0c-ac5c-02ea426c0e36",
                        "member": {
                            "member_id": "1eb95a5e-2142-6eee-be9d-02ab9829ff00",
                            "unique_identifier": "1500000782331",
                            "first_name": "member",
                            "last_name": "1255287",
                            "gender": "FEMALE",
                            "birthdate": "2013-09-10",
                            "age": 8,
                        },
                        "organisation": {
                            "organisation_id": "1eb81466-e50e-6ce0-95b7-06ba8f7ba4e0",
                            "organisation_name": "SARNIA",
                            "abbreviation": "S",
                            "i18n": {
                                "en": {
                                    "name": "SARNIA",
                                    "description": "",
                                    "active": "1",
                                },
                                "fr": {
                                    "name": "SARNIA",
                                    "description": "",
                                    "active": "1",
                                },
                            },
                        },
                        "target_organisation": {
                            "organisation_id": "1ebbdbfe-4f32-6e0c-ac5c-02ea426c0e36",
                            "organisation_name": "CORNWALL GHA",
                            "abbreviation": "CG",
                            "i18n": {
                                "fr": {
                                    "name": "CORNWALL GHA",
                                    "description": "",
                                    "active": "1",
                                },
                                "en": {
                                    "name": "CORNWALL GHA",
                                    "description": "",
                                    "active": "1",
                                },
                            },
                        },
                        "period": {
                            "period_id": "1ec85e7b-a7d4-6fea-aef3-a8a1592b6e57",
                            "name": "2021-2022",
                            "active": "1",
                            "i18n": null,
                        },
                        "transfer_reason_type": {
                            "transfer_reason_type_id": "bb7e38e2-a78c-11eb-b6f2-06b680167fa2",
                            "name": "Move with parents",
                            "active": "1",
                            "i18n": {
                                "fr": {
                                    "name": "Déménagement avec parents",
                                    "active": "1",
                                },
                                "en": {
                                    "name": "Move with parents",
                                    "active": "1",
                                },
                            },
                        },
                        "transfer_status": {
                            "transfer_status_id": "d1e8b839-b7cc-11eb-b6f2-06b680167fa2",
                            "name": "Open",
                            "active": "1",
                            "i18n": {
                                "en": {
                                    "name": "Open",
                                    "active": "1",
                                },
                                "fr": {
                                    "name": "À L'Étude",
                                    "active": "1",
                                },
                            },
                        },
                    },
                ],
                contentType: 'transfer',
            },
            link: 'transfers',
        },
        {
            positioning: {
                xxs: {
                    x: 0,
                    y: 11,
                },
                xs: {
                    x: 0,
                    y: 9,
                },
                sm: {
                    x: 1,
                    y: 8,
                },
                md: {
                    x: 2,
                    y: 5,
                },
                lg: {
                    x: 2,
                    y: 5,
                },
            },
            widgetId: '8',
            type: 'medium',
            label: 'Carousel name',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            i18n: {
                en: { label: 'Carousel name', description: '' },
                fr: { label: 'Nom du carrousel', description: '' },
            },
            dataType: {
                name: 'Carousel',
                code: 'caroussel',
                i18n: {
                    fr: { name: 'Carousel', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                    en: { name: 'Carousel', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                },
            },
            data: [// List of widgets -> will allow for recursivity
                {
                    widgetId: 'sekgsdkhfb',
                    label: 'Package satisfaction',
                    i18n: {
                        en: { label: 'Package satisfaction' },
                        fr: { label: 'Satisfaction des librairies' },
                    },
                    dataType: {
                        name: 'Scatter graph',
                        code: 'scatter-graph',
                        i18n: {
                            fr: { name: 'Graphique en nuage de points', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                            en: { name: 'Scatter graph', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                        },
                    },
                    data: {
                        series: [
                            {
                                name: "SpordleSelect V1",
                                data: [
                                    [ 3.3, 8 ],
                                ],
                            },
                            {
                                name: "SpordleSelect V2",
                                data: [
                                    [ 8.9, 8 ],
                                ],
                            },
                            {
                                name: "SpordleTable",
                                data: [
                                    [ 8.9, 6.7 ],
                                ],
                            },
                            {
                                name: "nextJS",
                                data: [
                                    [ 10, 10 ],
                                ],
                            },
                            {
                                name: "reactswal",
                                data: [
                                    [ 2, 5 ],
                                ],
                            },
                            {
                                name: "GooglePlaces",
                                data: [
                                    [ 4, 5 ],
                                ],
                            },
                            {
                                name: "react-datetime",
                                data: [
                                    [ 6, 7 ],
                                ],
                            },
                        ],
                        options: {
                            chart: {
                                type: 'scatter',
                            },
                            xaxis: {
                                min: 0,
                                max: 10,
                                tickAmount: 5,
                                labels: {
                                    formatter: function(val){
                                        return parseFloat(val).toFixed(1)
                                    },
                                },
                                title: {
                                    text: 'DX satisfaction',
                                },
                            },
                            yaxis: {
                                min: 0,
                                max: 10,
                                tickAmount: 5,
                                labels: {
                                    formatter: function(val){
                                        return parseFloat(val).toFixed(1)
                                    },
                                },
                                title: {
                                    text: 'UX satisfaction',
                                },
                            },
                        },
                    },
                },
                {
                    widgetId: '2',
                    data: '90',
                    dataType: {
                        name: 'Count',
                        code: 'count',
                        i18n: {
                            fr: { name: 'Compteur', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                            en: { name: 'Count', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                        },
                    },
                    label: 'Members',
                    i18n: {
                        en: { label: 'Members' },
                        fr: { label: 'Membres' },
                    },
                },
                {
                    widgetId: 'ndr',
                    data: '651',
                    dataType: {
                        name: 'Currency',
                        code: 'currency',
                        i18n: {
                            fr: { name: 'Argent', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                            en: { name: 'Currency', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                        },
                    },
                    label: 'Some amount',
                    i18n: {
                        en: { label: 'Some amount' },
                        fr: { label: 'Un certain montant' },
                    },
                },
                {
                    widgetId: '7',
                    label: 'Transfers',
                    description: '',
                    i18n: {
                        en: { label: 'Transfers', description: '' },
                        fr: { label: 'Transfert', description: '' },
                    },
                    dataType: {
                        name: 'Preview',
                        code: 'preview',
                        i18n: {
                            fr: { name: 'Aperçu', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                            en: { name: 'Preview', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                        },
                    },
                    data: {
                        headers: [
                            {
                                headerId: '1',
                                data: '100',
                                label: 'Opened Transfers',
                                i18n: {
                                    en: { label: 'Opened Transfers' },
                                    fr: { label: 'Transferts ouverts' },
                                },
                            },
                            {
                                headerId: '2',
                                data: '9',
                                label: 'Action Required',
                                i18n: {
                                    en: { label: 'Action Required' },
                                    fr: { label: 'Action requise' },
                                },
                            },
                        ],
                        content: [
                            {
                                "member_transfer_id": "1ebc8691-b9b0-6d40-abf5-060fdfa4c4f0",
                                "transfer_type": "ASSOCIATION",
                                "transfer_reason_type_id": "25a685e6-b970-11eb-b6f2-06b680167fa2",
                                "transfer_status_id": "d1e8b839-b7cc-11eb-b6f2-06b680167fa2",
                                "transfer_request_state": "OPEN",
                                "period_id": "1ec85e7b-a7d4-6fea-aef3-a8a1592b6e57",
                                "member_id": "1ebb6583-c7e3-6f78-bf90-068fc9a36a40",
                                "workflow_id": null,
                                "current_step": null,
                                "created_at": "2021-06-08T14:52:12Z",
                                "updated_at": "2021-06-08T14:52:12Z",
                                "current_workflow_step": null,
                                "previous_action_done": null,
                                "organisation_id": "1eb81976-c8a2-6efc-abda-06ba8f7ba4e0",
                                "target_organisation_id": "1ebbd84f-ce35-6630-9e6b-02bb3c474244",
                                "member": {
                                    "member_id": "1ebb6583-c7e3-6f78-bf90-068fc9a36a40",
                                    "unique_identifier": "1500000618575",
                                    "first_name": "member",
                                    "last_name": "2207601",
                                    "gender": "MALE",
                                    "birthdate": "2011-09-28",
                                    "age": 10,
                                },
                                "organisation": {
                                    "organisation_id": "1eb81976-c8a2-6efc-abda-06ba8f7ba4e0",
                                    "organisation_name": "ORILLIA",
                                    "abbreviation": "O",
                                    "i18n": {
                                        "fr": {
                                            "name": "ORILLIA",
                                            "description": "",
                                            "active": "1",
                                        },
                                        "en": {
                                            "name": "ORILLIA",
                                            "description": "",
                                            "active": "1",
                                        },
                                    },
                                },
                                "target_organisation": {
                                    "organisation_id": "1ebbd84f-ce35-6630-9e6b-02bb3c474244",
                                    "organisation_name": "LAKEFIELD",
                                    "abbreviation": "L",
                                    "i18n": {
                                        "fr": {
                                            "name": "LAKEFIELD",
                                            "description": "",
                                            "active": "1",
                                        },
                                        "en": {
                                            "name": "LAKEFIELD",
                                            "description": "",
                                            "active": "1",
                                        },
                                    },
                                },
                                "period": {
                                    "period_id": "1ec85e7b-a7d4-6fea-aef3-a8a1592b6e57",
                                    "name": "2021-2022",
                                    "active": "1",
                                    "i18n": null,
                                },
                                "transfer_reason_type": {
                                    "transfer_reason_type_id": "25a685e6-b970-11eb-b6f2-06b680167fa2",
                                    "name": "Share for a year",
                                    "active": "1",
                                    "i18n": {
                                        "fr": {
                                            "name": "Partager pour un an",
                                            "active": "1",
                                        },
                                        "en": {
                                            "name": "Share for a year",
                                            "active": "1",
                                        },
                                    },
                                },
                                "transfer_status": {
                                    "transfer_status_id": "d1e8b839-b7cc-11eb-b6f2-06b680167fa2",
                                    "name": "Open",
                                    "active": "1",
                                    "i18n": {
                                        "en": {
                                            "name": "Open",
                                            "active": "1",
                                        },
                                        "fr": {
                                            "name": "À L'Étude",
                                            "active": "1",
                                        },
                                    },
                                },
                            },
                            {
                                "member_transfer_id": "1ebebdcb-5135-6a90-9f15-02694ce0d08e",
                                "transfer_type": "ASSOCIATION",
                                "transfer_reason_type_id": "5f03f09b-bb28-11eb-b6f2-06b680167fa2",
                                "transfer_status_id": "d1e8b839-b7cc-11eb-b6f2-06b680167fa2",
                                "transfer_request_state": "OPEN",
                                "period_id": "1ec85e7b-a7d4-6fea-aef3-a8a1592b6e57",
                                "member_id": "1ebbdc43-b7df-665e-816d-02262e436632",
                                "workflow_id": "d17da0ff-b31e-11eb-b6f2-06b680167fa2",
                                "current_step": "2",
                                "created_at": "2021-07-23T17:37:52Z",
                                "updated_at": "2021-07-23T17:37:52Z",
                                "current_workflow_step": "1ebbdc03-1a36-68ac-987f-0678bdd6e0be",
                                "previous_action_done": null,
                                "organisation_id": "1ebbdc03-1a36-68ac-987f-0678bdd6e0be",
                                "target_organisation_id": "1ebbdc01-484a-6754-a85c-0678bdd6e0be",
                                "member": {
                                    "member_id": "1ebbdc43-b7df-665e-816d-02262e436632",
                                    "unique_identifier": "1500001080727",
                                    "first_name": "member",
                                    "last_name": "4330577",
                                    "gender": "MALE",
                                    "birthdate": "1986-05-20",
                                    "age": 35,
                                },
                                "organisation": {
                                    "organisation_id": "1ebbdc03-1a36-68ac-987f-0678bdd6e0be",
                                    "organisation_name": "COLLINGWOOD ADMIRALS JR. C",
                                    "abbreviation": "CAJC",
                                    "i18n": {
                                        "fr": {
                                            "name": "COLLINGWOOD ADMIRALS JR. C",
                                            "description": "",
                                            "active": "1",
                                        },
                                        "en": {
                                            "name": "COLLINGWOOD ADMIRALS JR. C",
                                            "description": "",
                                            "active": "1",
                                        },
                                    },
                                },
                                "target_organisation": {
                                    "organisation_id": "1ebbdc01-484a-6754-a85c-0678bdd6e0be",
                                    "organisation_name": "COLLINGWOOD COLTS",
                                    "abbreviation": "CC",
                                    "i18n": {
                                        "fr": {
                                            "name": "COLLINGWOOD COLTS",
                                            "description": "",
                                            "active": "1",
                                        },
                                        "en": {
                                            "name": "COLLINGWOOD COLTS",
                                            "description": "",
                                            "active": "1",
                                        },
                                    },
                                },
                                "period": {
                                    "period_id": "1ec85e7b-a7d4-6fea-aef3-a8a1592b6e57",
                                    "name": "2021-2022",
                                    "active": "1",
                                    "i18n": null,
                                },
                                "transfer_reason_type": {
                                    "transfer_reason_type_id": "5f03f09b-bb28-11eb-b6f2-06b680167fa2",
                                    "name": "Bench Staff",
                                    "active": "1",
                                    "i18n": {
                                        "fr": {
                                            "name": "Personnel de banc",
                                            "active": "1",
                                        },
                                        "en": {
                                            "name": "Bench Staff",
                                            "active": "1",
                                        },
                                    },
                                },
                                "transfer_status": {
                                    "transfer_status_id": "d1e8b839-b7cc-11eb-b6f2-06b680167fa2",
                                    "name": "Open",
                                    "active": "1",
                                    "i18n": {
                                        "en": {
                                            "name": "Open",
                                            "active": "1",
                                        },
                                        "fr": {
                                            "name": "À L'Étude",
                                            "active": "1",
                                        },
                                    },
                                },
                            },
                        ],
                        contentType: 'transfer',
                    },
                    link: 'transfers',
                },
                {
                    widgetId: 'jmtyhm',
                    label: 'Registration period',
                    i18n: {
                        en: { label: 'Registration period' },
                        fr: { label: 'Période d\'inscription' },
                    },
                    dataType: {
                        name: 'Range bar graph',
                        code: 'rangeBar-graph',
                        i18n: {
                            fr: { name: 'Graphique à barres de gamme', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                            en: { name: 'Range bar graph', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                        },
                    },
                    data: {
                        series: [
                            {
                                name: 'Registration',
                                data: [
                                    {
                                        x: 'Online store 1',
                                        y: [
                                            new Date('2022-03-05').getTime(),
                                            new Date('2022-05-05').getTime(),
                                        ],
                                    },
                                    {
                                        x: 'Online store 2',
                                        y: [
                                            new Date('2022-04-12').getTime(),
                                            new Date('2022-06-15').getTime(),
                                        ],
                                    },
                                    {
                                        x: 'Clinic 1',
                                        y: [
                                            new Date('2022-03-01').getTime(),
                                            new Date('2022-03-27').getTime(),
                                        ],
                                    },
                                    {
                                        x: 'Clinic 2',
                                        y: [
                                            new Date('2022-03-23').getTime(),
                                            new Date('2022-05-09').getTime(),
                                        ],
                                    },
                                ],
                            },
                            {
                                name: 'Early',
                                data: [
                                    {
                                        x: 'Online store 1',
                                        y: [
                                            new Date('2022-03-05').getTime(),
                                            new Date('2022-03-25').getTime(),
                                        ],
                                    },
                                    {
                                        x: 'Online store 2',
                                        y: [
                                            new Date('2022-04-12').getTime(),
                                            new Date('2022-04-30').getTime(),
                                        ],
                                    },
                                ],
                            },
                            {
                                name: 'Late',
                                data: [
                                    {
                                        x: 'Online store 1',
                                        y: [
                                            new Date('2022-04-25').getTime(),
                                            new Date('2022-05-05').getTime(),
                                        ],
                                    },
                                    {
                                        x: 'Clinic 2',
                                        y: [
                                            new Date('2022-04-26').getTime(),
                                            new Date('2022-05-09').getTime(),
                                        ],
                                    },
                                ],
                            },
                        ],
                        options: {
                            chart: {
                                type: 'rangeBar',
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: true,
                                    barHeight: '80%',
                                },
                            },
                            xaxis: {
                                type: 'datetime',
                            },
                            stroke: {
                                width: 1,
                            },
                            fill: {
                                type: 'solid',
                                opacity: 0.6,
                            },
                            legend: {
                                position: 'top',
                                horizontalAlign: 'left',
                            },
                        },
                    },
                },
            ],
            link: null,
        },
    ],
    dashboardWidgets: [
        {
            positioning: {
                xxs: {
                    x: 0,
                    y: 1,
                },
                xs: {
                    x: 0,
                    y: 1,
                },
                sm: {
                    x: 0,
                    y: 1,
                },
                md: {
                    x: 0,
                    y: 1,
                },
                lg: {
                    x: 0,
                    y: 1,
                },
            },
            widgetId: '7',
            label: 'Support requests',
            fromApi: true,
            name: 'supportRequest',
            type: 'tall',
            description: '',
            i18n: {
                en: { label: 'Support requests', description: '' },
                fr: { label: 'Demandes de soutien', description: '' },
            },
            dataType: {
                name: 'Preview',
                code: 'preview',
                i18n: {
                    fr: { name: 'Aperçu', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                    en: { name: 'Preview', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
                },
            },
            data: {
                headers: [
                    {
                        headerId: '1',
                        data: '100',
                        label: 'Created support request(s)',
                        i18n: {
                            en: { label: 'Created support request(s)' },
                            fr: { label: 'Demande(s) de soutien créée(s)' },
                        },
                    },
                    {
                        headerId: '2',
                        data: '9',
                        label: 'Open support request(s)',
                        i18n: {
                            en: { label: 'Open support request(s)' },
                            fr: { label: 'Demande(s) de soutien ouverte(s)' },
                        },
                    },
                ],
                content: [],
                contentType: 'supportRequest',
            },
            link: 'supportRequest',
        },
        // {
        //     positioning: {
        //         xxs: {
        //             x: 0,
        //             y: 1,
        //         },
        //         xs: {
        //             x: 1,
        //             y: 0,
        //         },
        //         sm: {
        //             x: 0,
        //             y: 1,
        //         },
        //         md: {
        //             x: 0,
        //             y: 1,
        //         },
        //         lg: {
        //             x: 0,
        //             y: 1,
        //         },
        //     },
        //     widgetId: '3',
        //     type: 'small',
        //     label: 'Teams',
        //     description: 'Generated amount of money from teams',
        //     i18n: {
        //         en: { label: 'Teams', description: 'Generated amount of money from teams' },
        //         fr: { label: 'Équipes', description: 'Génération d\'argent des équipes' },
        //     },
        //     dataType: {
        //         name: 'Currency',
        //         code: 'currency',
        //         i18n: {
        //             fr: { name: 'Argent', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
        //             en: { name: 'Currency', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
        //         },
        //     },
        //     data: '500',
        //     link: 'teams',
        // },
        // {
        //     positioning: {
        //         xxs: {
        //             x: 0,
        //             y: 2,
        //         },
        //         xs: {
        //             x: 0,
        //             y: 1,
        //         },
        //         sm: {
        //             x: 1,
        //             y: 0,
        //         },
        //         md: {
        //             x: 1,
        //             y: 0,
        //         },
        //         lg: {
        //             x: 1,
        //             y: 0,
        //         },
        //     },
        //     widgetId: '1',
        //     type: 'medium',
        //     label: 'Registrations',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //     i18n: {
        //         en: { label: 'Registrations', description: '' },
        //         fr: { label: 'Inscriptions', description: '' },
        //     },
        //     dataType: {
        //         name: 'Bar graph',
        //         code: 'bar-graph',
        //         i18n: {
        //             fr: { name: 'Graphique à barres', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
        //             en: { name: 'Bar graph', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
        //         },
        //     },
        //     data: {
        //         options: {
        //             chart: {
        //                 id: "onlineStoresGraph",
        //                 type: 'bar',
        //                 stacked: true,
        //                 parentHeightOffset: 0,
        //                 redrawOnParentResize: true,
        //             },
        //             stroke: {
        //                 width: 1,
        //                 colors: [ '#fff' ],
        //             },
        //             xaxis: {
        //                 categories: [ 'Hockey Registration 2021-2022', 'Test Shaun 2' ],
        //             },
        //             fill: {
        //                 opacity: 1,
        //             },
        //             legend: {
        //                 onItemHover: {
        //                     highlightDataSeries: true,
        //                 },
        //                 onItemClick: {
        //                     toggleDataSeries: true,
        //                 },
        //             },
        //             colors: [ '#FFA900', '#00B74A', '#dd0000' ],
        //         },
        //         series: [
        //             {
        //                 name: 'Pending',
        //                 data: [ 44, 55 ],
        //             },
        //             {
        //                 name: 'Confirmed',
        //                 data: [ 53, 32 ],
        //             },
        //             {
        //                 name: 'Cancelled',
        //                 data: [ 12, 17 ],
        //             },
        //         ],
        //     },
        //     link: 'onlinestores',
        // },
        // {
        //     positioning: {
        //         xxs: {
        //             x: 0,
        //             y: 4,
        //         },
        //         xs: {
        //             x: 1,
        //             y: 3,
        //         },
        //         sm: {
        //             x: 0,
        //             y: 2,
        //         },
        //         md: {
        //             x: 3,
        //             y: 0,
        //         },
        //         lg: {
        //             x: 3,
        //             y: 0,
        //         },
        //     },
        //     widgetId: '4',
        //     type: 'small',
        //     label: 'Online Stores',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //     i18n: {
        //         en: { label: 'Online Stores', description: '' },
        //         fr: { label: 'Inscriptions en ligne', description: '' },
        //     },
        //     dataType: {
        //         name: 'Count',
        //         code: 'count',
        //         i18n: {
        //             fr: { name: 'Compteur', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
        //             en: { name: 'Count', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
        //         },
        //     },
        //     data: '7',
        //     link: 'onlinestores',
        // },
        // {
        //     positioning: {
        //         xxs: {
        //             x: 0,
        //             y: 5,
        //         },
        //         xs: {
        //             x: 0,
        //             y: 3,
        //         },
        //         sm: {
        //             x: 1,
        //             y: 2,
        //         },
        //         md: {
        //             x: 3,
        //             y: 1,
        //         },
        //         lg: {
        //             x: 3,
        //             y: 1,
        //         },
        //     },
        //     widgetId: '5',
        //     type: 'small',
        //     label: 'Clinics',
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //     i18n: {
        //         en: { label: 'Clinics', description: '' },
        //         fr: { label: 'Stages', description: '' },
        //     },
        //     dataType: {
        //         name: 'Count',
        //         code: 'count',
        //         i18n: {
        //             fr: { name: 'Compteur', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
        //             en: { name: 'Count', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
        //         },
        //     },
        //     data: '6',
        //     link: 'clinics',
        // },
    ],
}