import CartProvider, { FieldLabel } from "@spordle/cart-engine";
import { DisplayPhoneNumber } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import { useIntl } from "react-intl";
import { Button, Table } from "reactstrap";
import Required from "../../../components/formik/Required";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import { displayI18n, DisplayI18n } from "../../../helpers/i18nHelper";
import { getLocation } from "../../teams/TeamHelpers";
import styles from './RegistrationForm.module.scss';

const UserImg = ({
    abbr,
    alt,
    className,
    container,
    filePos,
    height,
    src,
    width,
}) => {
    const pos = filePos ? JSON.parse(filePos) : null;
    const rDelta = pos?.cropSize?.width && parseInt(width) / parseFloat(pos.cropSize?.width);
    const styles = pos && rDelta ? { width: pos.width, height: pos.height, transform: `translate(${parseFloat(pos.x) * (rDelta || 1)}px, ${parseFloat(pos.y) * (rDelta || 1)}px) scale(${parseFloat(pos.zoom) * (rDelta || 1)})` } : { maxHeight: '100%', maxWidth: '100%' }

    return (
        (src ?
            <div className={stringBuilder('userImg-container d-flex align-items-center justify-content-center', (container ? className : ''))} style={{ width: width + 'px', height: (width || height) + 'px' }}>
                <img style={styles} src={src} alt={alt} />
            </div>
            :
            <div className={stringBuilder('userImg-abbr', className)} style={{ width: width + 'px', height: (width || height) + 'px' }}>
                <div className="text-center" style={{ width: width + 'px', lineHeight: (height || width) + 'px', fontSize: Math.round(parseInt(height || width) / 3) + 'px' }}>
                    {abbr.substring(0, 2)}
                </div>
            </div>
        )
    )
}

const MemberFieldLabel = ({ fieldsSettings, ...props }) => {
    return (
        <FieldLabel
            fieldsSettings={fieldsSettings}
            hideDescription
            {...props}
            className={stringBuilder(props.className, "d-block member-field")}
        />
    );
}

const I18n = ({ i18n }) => {
    const intl = useIntl();
    const myLang = intl.locale.split('-')[0];

    return i18n[myLang]
}

const PrintSection = ({ className, ...props }) => <div className={stringBuilder(className, styles.block)} {...props} />

const RegistrationForm = ({ fed, org, documents, waivers, lang, genders, indigenousGroup, fieldsSettings, ethnicities, phoneTypes, contactTypes, demographicMessage, requiredFields }) => {
    const intl = useIntl();
    const myLang = intl.locale.split('-')[0];

    const isRequired = (field) => {
        return requiredFields?.find((code) => code === field)
    }

    const Field = () => <div style={{ height: 20, borderWidth: "3px" }} className="" />;
    const ChoiceField = ({ label, inline, className }) => (
        <div className={stringBuilder(inline ? "d-inline-flex" : "d-flex", "align-items-center", className)}>
            <input onChange={() => {}} checked={false} className="mr-1 bg-white" style={{ width: 15, height: 15 }} type="radio" /> {label}
        </div>
    );

    const Header = ({ isPageBreak = false, hasBorder = false }) => (
        <div
            style={isPageBreak ? { pageBreakBefore: "always" } : undefined}
            className={`clearfix mb-5${hasBorder ? " border-bottom pb-3" : ""}`}
        >
            <div className="float-left p-1">
                <UserDisplay>
                    <UserDisplay.Container className="position-relative">
                        <UserImg
                            abbr={org.abbreviation}
                            src={org.logo?.full_path}
                            filePos={org.logo?.file_position}
                            alt={org.organisation_name}
                            width="80"
                        />
                        {(fed && fed?.organisation_id !== org?.organisation_id) &&
                            <div className="position-absolute bottom-0 left-0 ml-n2 mb-n1">
                                <UserImg
                                    abbr={fed.abbreviation}
                                    src={fed.logo?.full_path}
                                    filePos={fed.logo?.file_position}
                                    alt={fed.organisation_name}
                                    width="30"
                                />
                            </div>
                        }
                    </UserDisplay.Container>
                </UserDisplay>
            </div>
            <div className="float-right">
                <b>
                    <DisplayI18n
                        field="name"
                        defaultValue={org.organisation_name}
                        i18n={org.i18n}
                    />
                </b>
                <div>{getLocation(org)}</div>
                <div>
                    <I18n i18n={{ en: "Phone", fr: "Téléphone" }} />: {org.phone ? <DisplayPhoneNumber phoneString={org.phone} /> : "-"}
                </div>
                <div>
                    <I18n i18n={{ en: "Email", fr: "Courriel" }} />: {org.organisation_email || "-"}
                </div>
            </div>
        </div>
    )

    return (
        <div className="text-dark">
            <div className="text-right d-print-none mb-3">
                <Button id="printBtn" type="button">
                    <I18n i18n={{ en: "Print", fr: "Imprimer" }} />
                </Button>
            </div>
            <Header hasBorder={false} />

            <PrintSection>
                <div className="d-flex justify-content-between">
                    <div className="h3 mb-3 font-bold"><I18n i18n={{ en: "Member Profile Information", fr: "Informations du profil de membre" }} /></div>
                    <div className="h-100 border-bottom border-dark pb-3 mt-n1" style={{ flexBasis: '33%' }}>
                        <b><I18n i18n={{ en: "Registration Date", fr: "Date d'inscription" }} /> <Required /></b>
                    </div>
                </div>
                <Table style={{ tableLayout: "fixed" }} size="sm" className='mb-5 border-dark' bordered>
                    <tbody>
                        <tr>
                            <td className="border-dark">
                                <b>
                                    <MemberFieldLabel
                                        fallbackLabel={<I18n i18n={{ en: "First Name", fr: "Prénom" }} />}
                                        fieldsSettings={fieldsSettings}
                                        isRequired
                                        field="first_name"
                                    />
                                </b>
                                <Field />
                            </td>
                            <td className="border-dark">
                                <b>
                                    <MemberFieldLabel
                                        fallbackLabel={<I18n i18n={{ en: "Last Name", fr: "Nom" }} />}
                                        fieldsSettings={fieldsSettings}
                                        isRequired
                                        field="last_name"
                                    />
                                </b>
                                <Field />
                            </td>
                            <td className="border-dark">
                                <b><I18n i18n={{ en: "Primary Email", fr: "Courriel principal" }} /> {isRequired('email') && <Required />}</b>
                                <Field />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-dark">
                                <b className="d-block mb-1">
                                    <MemberFieldLabel
                                        fallbackLabel={<I18n i18n={{ en: "Gender Identity", fr: "Identité du genre" }} />}
                                        fieldsSettings={fieldsSettings}
                                        isRequired
                                        field="gender"
                                    />
                                </b>
                                {genders.map((gender) => (
                                    <ChoiceField key={gender.value} label={<I18n i18n={gender.i18n} />} />
                                ))}
                            </td>
                            <td className="border-dark">
                                <b className="d-block mb-1">
                                    <MemberFieldLabel
                                        fallbackLabel={<I18n i18n={{ en: "Primary Language", fr: "Langue primaire" }} />}
                                        fieldsSettings={fieldsSettings}
                                        isRequired={isRequired('primary_language')}
                                        field="primary_language"
                                    />
                                </b>
                                <ChoiceField label={<I18n i18n={{ en: "English", fr: "Anglais" }} />} />
                                <ChoiceField label={<I18n i18n={{ en: "French", fr: "Français" }} />} />
                            </td>
                            <td className="border-dark">
                                <b className="d-block mb-1"><I18n i18n={{ en: "Secondary Language", fr: "Langue secondaire" }} /> {isRequired('secondary_languages') && <Required />}</b>
                                <ChoiceField label={<I18n i18n={{ en: "English", fr: "Anglais" }} />} />
                                <ChoiceField label={<I18n i18n={{ en: "French", fr: "Français" }} />} />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-dark">
                                <b>
                                    <MemberFieldLabel
                                        fallbackLabel={<I18n i18n={{ en: "Date of Birth", fr: "Date de naissance" }} />}
                                        fieldsSettings={fieldsSettings}
                                        isRequired
                                        field="birthdate"
                                    />
                                </b>
                                <Field />
                            </td>
                            <td className="border-dark">
                                <b>
                                    <MemberFieldLabel
                                        fallbackLabel={<I18n i18n={{ en: "Citizenship", fr: "Citoyenneté" }} />}
                                        fieldsSettings={fieldsSettings}
                                        isRequired={isRequired('citizenship')}
                                        field="citizenship"
                                    />
                                </b>
                                <Field />
                            </td>
                            <td className="border-dark">
                                <b>
                                    <MemberFieldLabel
                                        fallbackLabel={<I18n i18n={{ en: "Birth Country", fr: "Pays de naissance" }} />}
                                        fieldsSettings={fieldsSettings}
                                        field="birth_country"
                                        isRequired={isRequired('birth_country')}
                                    />
                                </b>
                                <Field />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-dark">
                                <b className="d-block mb-1">
                                    <MemberFieldLabel
                                        fallbackLabel={<I18n i18n={{ en: "Identify as Indigenous", fr: "S'identifie en tant qu'autochtone" }} />}
                                        fieldsSettings={fieldsSettings}
                                        isRequired={isRequired('identify_as_indigenous')}
                                        field="identify_as_indigenous"
                                    />
                                </b>
                                <ChoiceField label={<I18n i18n={{ en: "Yes", fr: "Oui" }} />} />
                                <ChoiceField label={<I18n i18n={{ en: "No", fr: "Non" }} />} />
                                <ChoiceField label={<I18n i18n={{ en: "Prefer not to say", fr: "Préfère ne pas dire" }} />} />
                            </td>
                            <td className="border-dark" colSpan="2">
                                <b className="d-block mb-1"><I18n i18n={{ en: "If yes, please indicate the group", fr: "Si oui, veuillez indiquer le groupe" }} /> {isRequired('identify_as_indigenous') && <Required />}</b>
                                {indigenousGroup.map((indigenousGroup) => (
                                    <ChoiceField key={indigenousGroup.value} label={<I18n i18n={indigenousGroup.i18n} />} />
                                ))}
                            </td>
                        </tr>
                        <tr>
                            <td className="border-dark" colSpan={3}>
                                <b className="d-block mb-2">
                                    <MemberFieldLabel
                                        fallbackLabel={<I18n i18n={{ en: "Ethnicity", fr: "Ethnicité" }} />}
                                        fieldsSettings={fieldsSettings}
                                        isRequired={isRequired('ethnicity_id')}
                                        field="ethnicity_id"
                                    />
                                </b>
                                {ethnicities.map((ethnicity) => (
                                    <ChoiceField className="mr-4" inline key={ethnicity.value} label={<I18n i18n={ethnicity.i18n} />} />
                                ))}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintSection>

            <PrintSection>
                <div className="h4 mb-3 font-bold"><I18n i18n={{ en: "Address Information", fr: "Informations de l'adresse" }} /></div>
                <Table style={{ tableLayout: "fixed" }} size="sm" className="mb-5" bordered>
                    <tbody>

                        <tr>
                            <td className="border-dark" colSpan={2}>
                                <b className="mb-2 d-block"><I18n i18n={{ en: "Address Type", fr: "Type d'adresse" }} /> <Required /></b>
                                <div>
                                    <ChoiceField className="mr-4" inline label={<I18n i18n={{ en: "Resident", fr: "Résident" }} />} />
                                    <ChoiceField inline label={<I18n i18n={{ en: "Billet residence", fr: "Résidence secondaire" }} />} />
                                </div>
                            </td>
                            <td className="border-dark">
                                <b><I18n i18n={{ en: "Street Number", fr: "Numéro civique" }} /> <Required /></b>
                                <Field />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-dark" colSpan={2}>
                                <b><I18n i18n={{ en: "Address", fr: "Adresse" }} /> <Required /></b>
                                <Field />
                            </td>
                            <td className="border-dark">
                                <b><I18n i18n={{ en: "Country", fr: "Pays" }} /> <Required /></b>
                                <Field />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-dark" colSpan={3}>
                                <b><I18n i18n={{ en: "Rural Route / Postal Office Station", fr: "Route rurale / Bureau de poste" }} /> <Required /></b>
                                <Field />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-dark">
                                <b><I18n i18n={{ en: "City", fr: "Ville" }} /> <Required /></b>
                                <Field />
                            </td>
                            <td className="border-dark">
                                <b><I18n i18n={{ en: "Province", fr: "Province" }} /> <Required /></b>
                                <Field />
                            </td>
                            <td className="border-dark">
                                <b><I18n i18n={{ en: "Postal Code", fr: "Code postal" }} /> <Required /></b>
                                <Field />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-dark" colSpan={3}>
                                <b><I18n i18n={{ en: "Phone Number", fr: "Numéro de téléphone" }} /> <Required /></b>
                                <Field />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-dark" colSpan={2}>
                                <b className="d-block mb-2"><I18n i18n={{ en: "Phone Type", fr: "Type de téléphone" }} /> <Required /></b>
                                {phoneTypes.map((phoneType) => (
                                    <ChoiceField className="mr-4" inline key={phoneType.value} label={<I18n i18n={phoneType.i18n} />} />
                                ))}
                            </td>
                            <td className="border-dark">
                                <b><I18n i18n={{ en: "Move In Year", fr: "Année de déménagement" }} /> <Required /></b>
                                <Field />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintSection>

            <PrintSection>
                <div className="h4 font-bold mb-3"><I18n i18n={{ en: "Contact Information", fr: "Information de contact" }} /></div>
                <Table style={{ tableLayout: "fixed" }} size="sm" className="mb-5" bordered>
                    <tbody>
                        <tr>
                            <td className="border-dark" colSpan={3}>
                                <b className="d-block mb-2"><I18n i18n={{ en: "Contact Type", fr: "Type de contact" }} /> <Required /></b>
                                {contactTypes.map((phoneType) => (
                                    <ChoiceField className="mr-4" inline key={phoneType.value} label={<I18n i18n={phoneType.i18n} />} />
                                ))}
                            </td>
                        </tr>
                        <tr>
                            <td className="border-dark">
                                <b>
                                    <MemberFieldLabel
                                        fallbackLabel={<I18n i18n={{ en: "First Name", fr: "Prénom" }} />}
                                        fieldsSettings={fieldsSettings}
                                        isRequired
                                        field="first_name"
                                    />
                                </b>
                                <Field />
                            </td>
                            <td className="border-dark">
                                <b>
                                    <MemberFieldLabel
                                        fallbackLabel={<I18n i18n={{ en: "Last Name", fr: "Nom" }} />}
                                        fieldsSettings={fieldsSettings}
                                        isRequired
                                        field="last_name"
                                    />
                                </b>
                                <Field />
                            </td>
                            <td className="border-dark">
                                <b>
                                    <MemberFieldLabel
                                        fallbackLabel={<I18n i18n={{ en: "Email", fr: "Courriel" }} />}
                                        fieldsSettings={fieldsSettings}
                                        isRequired
                                        field="email"
                                    />
                                </b>
                                <Field />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-dark">
                                <b><I18n i18n={{ en: "Phone Number", fr: "Numéro de téléphone" }} /> <Required /></b>
                                <Field />
                            </td>
                            <td className="border-dark">
                                <b><I18n i18n={{ en: "Phone Type", fr: "Type de téléphone" }} /> <Required /></b>
                                <Field />
                            </td>
                            <td className="border-dark">
                                <b className="d-block mb-2"><I18n i18n={{ en: "Emergency Contact", fr: "Contact d'urgence" }} /> <Required /></b>
                                <ChoiceField className="mr-4" inline label={<I18n i18n={{ en: "Yes", fr: "Oui" }} />} />
                                <ChoiceField inline label={<I18n i18n={{ en: "No", fr: "Non" }} />} />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintSection>

            {demographicMessage &&
                <div dangerouslySetInnerHTML={{ __html: demographicMessage.i18n?.[myLang]?.body || "-" }} />
            }

            {Array.isArray(documents) && documents.length > 0 &&
                <>
                    <Header hasBorder isPageBreak />
                    <div className="h3 mb-3 font-bold">Documents</div>
                    {documents.map((doc) => (
                        <PrintSection key={doc.document_type_id} className="mb-3">
                            <div className="font-bold h5 mb-2">
                                <DisplayI18n field="name" i18n={doc.i18n} defaultValue={doc.name} />
                            </div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: doc.i18n?.[myLang]?.description || doc.description || "",
                                }}
                            />
                        </PrintSection>
                    ))}
                </>
            }
            {waivers.length > 0 &&
                <>
                    <Header hasBorder isPageBreak />
                    <div className="h3 mb-4 mt-4 font-bold"><I18n i18n={{ en: "Mandatory Waivers", fr: "Dispenses obligatoires" }} /></div>
                    {waivers.map((waiver) => (
                        <PrintSection key={waiver.waiver_id} className="d-flex mb-5">
                            <div className="mr-2">
                                <UserImg
                                    src={waiver.organisation.logo?.full_path}
                                    filePos={waiver.organisation.logo?.file_position}
                                    abbr={waiver.organisation.abbreviation || waiver.organisation.organisation_name}
                                    alt={waiver.organisation.organisation_name}
                                    width={50}
                                />
                            </div>
                            <div>
                                <div className="font-bold h5 mb-2">
                                    <DisplayI18n field="name" i18n={waiver.i18n} defaultValue={waiver.name} />
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: displayI18n("description", waiver.i18n, waiver.description, lang) }} />
                                <ChoiceField
                                    label={
                                        waiver?.confirm_label ?
                                            <DisplayI18n field='confirm_label' defaultValue={waiver?.confirm_label} i18n={waiver?.i18n} />
                                            :
                                            <I18n i18n={{ en: "I have read and accept the waiver", fr: "J'ai lu et j'accepte la dispense" }} />
                                    }
                                />
                                {waiver?.decline_label &&
                                    <ChoiceField label={<DisplayI18n field='decline_label' defaultValue={waiver?.decline_label} i18n={waiver?.i18n} />} />
                                }

                                <Table style={{ tableLayout: "fixed" }} size="sm" className='mt-3 mb-0 border-dark' bordered>
                                    <tbody>
                                        <tr>
                                            <td className="border-dark">
                                                <b><I18n i18n={{ en: "First Name", fr: "Prénom" }} /> <Required /></b>
                                                <Field />
                                            </td>
                                            <td className="border-dark">
                                                <b><I18n i18n={{ en: "Last Name", fr: "Nom" }} /> <Required /></b>
                                                <Field />
                                            </td>
                                            <td className="border-dark">
                                                <b><I18n i18n={{ en: "Date", fr: "Date" }} /> <Required /></b>
                                                <Field />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </PrintSection>
                    ))}
                </>
            }
        </div>
    )
}

const RegistrationFormWrapper = (props) => {
    return <CartProvider><RegistrationForm {...props} /></CartProvider>
}

export default RegistrationFormWrapper;