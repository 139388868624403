import useSWR from "swr";
import { MessagesContext } from "../../contexts/MessagesContext";
import { useContext } from "react";

export function useMemberFieldsSWR(messageId){
    const { getSettingMessage } = useContext(MessagesContext);

    return useSWR(
        [ 'getMessage', messageId ],
        () => messageId ? getSettingMessage(messageId).then((messages) => Array.isArray(messages) ? messages[0] : null) : Promise.resolve(null),
        {
            revalidateOnFocus: false,
            dedupingInterval: 60000,
        },
    )
}