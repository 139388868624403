import { Container } from "reactstrap";
import settingsMessagesRoutes from "../../../../../routes/tabRoutes/organizationSettings/settingsMessagesRoutes";
import SubRouteSettingWrapper from "../../../components/SubRouteSettingWrapper";

const OrganizationMessagesSettings = () => {
    return (
        <Container>
            <SubRouteSettingWrapper
                back="organization"
                routes={settingsMessagesRoutes}
            />
        </Container>
    );
}

export default OrganizationMessagesSettings