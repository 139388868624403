import { useContext, useState } from 'react';
import { Form, Formik } from "formik";
import { Button, Card, Col, FormGroup, Label, Row } from "reactstrap";
import OrganizationSearch from "../../../components/organization/OrganizationSearch";
import { FormikSelect } from "@spordle/formik-elements";
import { useSpordleTable } from "@spordle/datatables";
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { PeriodsContext } from '../../../contexts/contexts';
import useSavedSearch from '../../../components/customHooks/useSavedSearch';
import Translate from '@spordle/intl-elements';
import GenderLabel from '../../../components/GenderLabel';
import { getMemberTypeStatuses } from '../../../api/client/memberTypeValidation';
import SavedSearchCue from '../../../components/savedSearchCue/SavedSearchCue';
import { MembersContext } from '../../../contexts/MembersContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import DisplayMemberTypeStatusIcon from '../../../components/display/DisplayMemberTypeStatusIcon';
import { I18nContext } from '../../../contexts/I18nContext';
import { array, object } from 'yup';

const FiltersCard = () => {
    const spordleTable = useSpordleTable();
    const identityRolesContext = useContext(IdentityRolesContext)
    const organizationContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext);
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);

    const { savedSearch, saveSearch, clearSavedSearch } = useSavedSearch(`member-type-validation-search-filters-${identityRolesContext.identity_role_id}-${organizationContext.organisation_id}-${periodsContext.selectedPeriod.period_id}`);
    const [ formikKey, setFormikKey ] = useState(0);

    const originalValues = {
        organizationIds: [],
        memberTypeIds: [],
        gender: '',
        memberTypeStatuses: [],
    }

    const getInitFilters = () => {
        if(savedSearch){
            return {
                ...originalValues,
                ...savedSearch,
            };
        }

        return originalValues;
    }

    // This is used to force a rerender of formik to empty FormikDateTime fields
    const rerenderFormik = () => {
        clearSavedSearch();
        setFormikKey(formikKey + 1);
    }

    return (
        <Formik
            key={formikKey}
            initialValues={getInitFilters()}
            validationSchema={object().shape({
                memberTypeIds: array().min(1, <Translate id='tasks.memberTypeValidation.filter.memberType.required' />),
            })}
            onSubmit={(values) => {
                spordleTable.setInputValue();
                spordleTable.filterChange('form', values)
                saveSearch({
                    ...values,
                })
            }}
        >
            {(formik) => (
                <Form>
                    <Card body className='card-shadow'>
                        <Row form className='align-items-center'>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for='organizationIds' className='text-muted'>
                                        <Translate id='tasks.memberTypeValidation.columns.organization' />
                                    </Label>
                                    <OrganizationSearch
                                        id='organizationIds'
                                        name='organizationIds'
                                        withTree isCompact
                                        multi withFormik
                                        clearable
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for='gender' className='text-muted'>
                                        <Translate id='form.fields.gender' />
                                    </Label>
                                    <FormikSelect
                                        name='gender'
                                        id='gender'
                                        loadingStatus='success'
                                        search={false}
                                        clearable
                                        defaultData={organizationContext.settings.gender_selection?.value?.map((gender) => ({
                                            value: gender,
                                            label: <GenderLabel gender={gender} />,
                                            translateLabel: false,
                                        })) || []}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3} className='mb-3'>
                                <Label>&nbsp;</Label>
                                <Button
                                    id='memberTypeValidationFiltersSearch'
                                    color='primary' className='w-100'
                                    type='submit'
                                >
                                    <i className='fas fa-search mr-1' /><Translate id='misc.search' />
                                </Button>
                            </Col>
                            <Col md={3}>
                                <Label for='memberTypeIds' className='text-muted'>
                                    <Translate id='tasks.memberTypeValidation.columns.memberType' />
                                </Label>
                                <FormikSelect
                                    name='memberTypeIds'
                                    id='memberTypeIds'
                                    searchKeys={[
                                        `i18n.${i18nContext.getGenericLocale()}.name`,
                                    ]}
                                    multi
                                    textWhenSetting={{
                                        count: 2,
                                    }}
                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                    loadData={(from) => {
                                        switch (from){
                                            case 'CDM':
                                                return membersContext.getMemberTypes(organizationContext.organisation_id)
                                                    .then((memberTypes) => memberTypes.map((type) => ({
                                                        label: type.name,
                                                        value: type.member_type_id,
                                                        i18n: type.i18n,
                                                        code: type.system_type,
                                                    })))
                                                    .then((data) => {
                                                        // this controls the initial load of the table
                                                        // we need to send a member type
                                                        const memberTypeIds = formik.values.memberTypeIds.length > 0 ?
                                                            formik.values.memberTypeIds
                                                            :
                                                            [ data.find((type) => type.code === "PLAYER")?.value ]

                                                        if(formik.values.memberTypeIds.length == 0){
                                                            formik.setFieldValue('memberTypeIds', memberTypeIds)
                                                        }

                                                        spordleTable.filterChange('form', {
                                                            ...formik.values,
                                                            memberTypeIds: memberTypeIds,
                                                        })

                                                        return data
                                                    })
                                        }
                                    }}
                                />
                            </Col>
                            <Col md={3}>
                                <Label for='memberTypeStatuses' className='text-muted'>
                                    <Translate id='tasks.memberTypeValidation.columns.memberTypeStatus' />
                                </Label>
                                <FormikSelect
                                    name='memberTypeStatuses'
                                    id='memberTypeStatuses'
                                    searchKeys={[
                                        `memberTypeStatus.i18n.${i18nContext.getGenericLocale()}.name`,
                                    ]}
                                    clearable multi
                                    textWhenSetting={{
                                        count: 2,
                                    }}
                                    renderOption={({ option }) => (
                                        <>
                                            <DisplayMemberTypeStatusIcon status={option.memberTypeStatus} className="mr-2" />
                                            <DisplayI18n field='name' defaultValue={option.memberTypeStatus.name} i18n={option.memberTypeStatus.i18n} />
                                        </>
                                    )}
                                    loadData={(from) => {
                                        switch (from){
                                            case 'CDM':
                                                return getMemberTypeStatuses()
                                                    .then((memberTypesStatuses) => memberTypesStatuses.map((status) => ({
                                                        label: status.name,
                                                        value: status.member_type_status_id,
                                                        memberTypeStatus: status,
                                                    })))
                                        }
                                    }}
                                />
                            </Col>
                            <Col md={12}>
                                <SavedSearchCue className="pt-3" savedSearch={savedSearch} clearSavedSearch={rerenderFormik} />
                            </Col>
                        </Row>
                    </Card>
                </Form>
            )}
        </Formik>
    )
}

export default FiltersCard