import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Button } from "reactstrap";
import { ChecklistsContext } from "../../../../contexts/ChecklistsContext";
import ChecklistIcon from "./ChecklistIcon";
import { DisplayI18n, displayI18n } from "../../../../helpers/i18nHelper";
import { I18nContext } from "../../../../contexts/I18nContext";
import { PeriodsContext } from "../../../../contexts/contexts";

const ChecklistHeader = ({ checklist, checklistId, completedSteps, steps, isLoading }) => {
    const { startChecklist } = useContext(ChecklistsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const isInProgress = completedSteps.length > 0;
    const canComplete = steps.length > 0 && !isLoading;
    const isComplete = completedSteps.length >= steps.length;

    const handleOnStart = () => {
        startChecklist(checklistId);
    }

    return (
        <div className="mb-4 text-center">
            <ChecklistIcon width={120} isComplete={isComplete && canComplete} />
            <div className="text-uppercase h6 mb-0">
                <DisplayI18n
                    field="name"
                    i18n={selectedPeriod.i18n}
                    defaultValue={selectedPeriod.name}
                />
            </div>
            <div className="h2 font-medium bold">
                <DisplayI18n
                    field="name"
                    i18n={checklist.i18n}
                    defaultValue={checklist.name}
                />
            </div>
            <div
                dangerouslySetInnerHTML={{
                    __html: displayI18n("name", checklist.i18n, checklist.name, getGenericLocale()),
                }}
            />
            <div className="mt-4">
                <Button
                    disabled={!canComplete}
                    className="mdi mdi-checkbox-multiple-marked-outline with-icon"
                    size="lg"
                    type="button"
                    color={isComplete && canComplete ? "success" : "primary"}
                    onClick={handleOnStart}
                >
                    <Translate id={isLoading ? "misc.loading" : `checklists.${((isComplete || isInProgress) && canComplete) ? "openChecklistAssistant" : "startChecklist"}`} />
                </Button>
                {canComplete && <div className="text-muted small mt-2 mdi mdi-information-outline with-icon"><Translate id="checklists.startChecklist.txt" /></div>}
            </div>
        </div>

    );
};

export default ChecklistHeader;
