import { FormikSelect } from '@spordle/formik-elements'
import Translate from '@spordle/intl-elements'
import { Form, Formik } from 'formik'
import { Button, Col, Collapse, Fade, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { object, string } from 'yup'
import CustomAlert from '../../../../../../components/CustomAlert'
import Required from '../../../../../../components/formik/Required'

const FormClinicSelectPermission = ({ onSubmit, member, previous, isLoading, toggle }) => {

    return (
        <Formik
            initialValues={{
                clinic_permission: 'NO_ACCESS',
            }}
            validationSchema={object().shape({
                clinic_permission: string().required(),
            })}
            onSubmit={onSubmit}
        >
            {({ values, status, setStatus }) => (
                <Form>
                    <ModalHeader toggle={toggle}>
                        <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission' />
                    </ModalHeader>
                    <ModalBody>
                        {member?.member_id &&
                            <Row from>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="clinic_permission" className="text-muted">
                                            <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission' /> <Required />
                                        </Label>
                                        <FormikSelect
                                            id='clinic_permission' name='clinic_permission'
                                            defaultData={[
                                                {
                                                    label: 'clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.NO_ACCESS',
                                                    value: 'NO_ACCESS',
                                                    translateLabel: true,
                                                },
                                                {
                                                    label: 'clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.READ_ONLY',
                                                    value: 'READ_ONLY',
                                                    translateLabel: true,
                                                },
                                                {
                                                    label: 'clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.READ_WRITE',
                                                    value: 'READ_WRITE',
                                                    translateLabel: true,
                                                },
                                            ]}
                                            loadingStatus='success'
                                        />
                                        <div className="bg-light mt-3 p-2 rounded d-flex">
                                            <div className="position-relative mr-2 p-1">
                                                <div className="border bg-white rounded-circle d-flex justify-content-center align-items-center" style={{ width: "38px", height: "38px" }}>
                                                    <Fade className="d-flex align-items-center position-absolute" in={values.clinic_permission === "NO_ACCESS"}>
                                                        <i className="mdi mdi-eye-off text-primary font-20" />
                                                    </Fade>
                                                    <Fade className="d-flex align-items-center position-absolute" in={values.clinic_permission === "READ_ONLY"}>
                                                        <i className="mdi mdi-eye text-primary font-20" />
                                                    </Fade>
                                                    <Fade className="d-flex align-items-center position-absolute" in={values.clinic_permission === "READ_WRITE"}>
                                                        <i className="mdi mdi-account-edit text-primary font-20" />
                                                    </Fade>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-dark"><Translate id={`clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.desc.${values.clinic_permission}`} values={{ bold: (word) => <b>{word}</b> }} /></div>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                        <Collapse isOpen={!!status} appear mountOnEnter unmountOnExit>
                            <CustomAlert color='danger' text={status || ""} translateText={false} withTitle toggle={() => setStatus(null)} />
                        </Collapse>
                    </ModalBody>
                    <ModalFooter>
                        {previous &&
                            <Button onClick={previous} className="mr-auto" color="primary" outline disabled={isLoading} type="button">
                                <Translate id="misc.previous" />
                            </Button>
                        }
                        <Button color="primary" type="submit">
                            <Translate id="misc.submit" />
                        </Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default FormClinicSelectPermission;