export default{
    "organization.settings.roles.modals.title"         : "Autorisations de rôle pour {permissionName}",
    "organization.settings.roles.modals.action.save"   : "Enregistrer",
    "organization.settings.roles.modals.action.cancel" : "Annuler",
    "organization.settings.roles.modals.action.clone"  : "Cloner à d'autres rôles",

    "organization.settings.roles.modals.permission.bulk"           : "Appliquer à d'autres rôles",
    "organization.settings.roles.modals.select.placeholder"        : "Sélectionnez les autorisations...",
    "organization.settings.roles.modals.select.applyToAll.title"   : "Appliquer à toutes les autorisations",
    "organization.settings.roles.modals.select.applyToAll.add"     : "Ajouter",
    "organization.settings.roles.modals.select.applyToAll.replace" : "Remplacer",
    "organization.settings.roles.modals.select.option.ADD"         : "Ajout",
    "organization.settings.roles.modals.select.option.DELETE"      : "Suppression",
    "organization.settings.roles.modals.select.option.READ"        : "Lecture",
    "organization.settings.roles.modals.select.option.EDIT"        : "Modification",
    "organization.settings.roles.modals.select.option.IMPORT"      : "Importation",
    "organization.settings.roles.modals.select.option.EXPORT"      : "Exportation",

    "organization.settings.roles.modals.applyPermission.title"               : "Appliquer la permission à d'autres rôles",
    "organization.settings.roles.modals.applyPermission.label.autorisations" : "Autorisations",
    "organization.settings.roles.modals.applyPermission.label.otherRoles"    : "Autres rôles",
    "organization.settings.roles.modals.applyPermission.otherRoles.disabled" : "Actuellement géré",
    "organization.settings.roles.modals.applyPermission.helperText"          : "Cette action mettra à jour le rôle actuel ({currentRole}) et tous les autres rôles sélectionnés",

    "organization.settings.roles.modals.clone.title"               : "Cloner une autorisation de rôle",
    "organization.settings.roles.modals.clone.description"         : "Les autorisations du rôle {roleName} seront clonées sur les autres rôles sélectionnés ci-dessous.",
    "organization.settings.roles.modals.clone.otherRoles.disabled" : "Impossible de cloner au rôle actuel",
    "organization.settings.roles.modals.clone.helperText"          : "Seuls les autorisations <b>actuellement</b> enregistrées seront clonées aux autres rôles.",
    "organization.settings.roles.modals.clone.helperText2"         : "Si vous avez des modifications que vous souhaitez cloner, veuillez les enregistrer à l'étape précédente avant de cloner.",
    "organization.settings.roles.modals.clone.action.clone"        : "Cloner",

    "organization.settings.roles.modals.warning.availability" : "Les autorisations sur cette composante ne seront pas disponibles sans l'autorisation «LECTURE»",
}