import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik, useFormikContext } from 'formik';
import { object, string } from 'yup';
import { Button, CustomInput, Label, ModalBody, ModalFooter } from 'reactstrap';
import Translate from '@spordle/intl-elements';
import UserDisplay from '../userDisplay/UserDisplay';
import { DisplayI18n } from '../../helpers/i18nHelper';
import { FormikError } from '@spordle/formik-elements';
class ManualRegistrationPaymentMethods extends React.Component{

    render(){
        return (
            <Formik
                initialValues={{
                    paymentMethod: this.props.selectedPaymentMethod || (this.props.creditCardDisabled ?
                        this.props.paymentMethods?.filter((pMethod) => pMethod.category === "MANUAL")?.[0]?.code
                        :
                        this.props.paymentMethods?.[0]?.code || ''
                    ),
                }}
                validationSchema={object().shape({
                    paymentMethod: string().required(<Translate id='components.manualRegistration.paymentMethods.required' />),
                })}
                onSubmit={(values) => {
                    this.props.onSubmit(values.paymentMethod)
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalBody>
                            {/* Online Payment Methods */}
                            <Label><Translate id='components.manualRegistration.paymentMethods.online.title' /></Label>
                            {this.props.paymentMethods?.filter((pMethod) => pMethod.category === "ONLINE").map((pMethod) => (
                                <PaymentMethod key={pMethod.code} method={pMethod} defaultSelected={pMethod.code === formik.values.paymentMethod} disabled={!!this.props.creditCardDisabled} />
                            ))}

                            {/* Manual Payment Methods */}
                            <Label className="mt-3"><Translate id='components.manualRegistration.paymentMethods.manual.title' /></Label>
                            {this.props.paymentMethods?.filter((pMethod) => pMethod.category === "MANUAL").map((pMethod) => (
                                <PaymentMethod key={pMethod.code} method={pMethod} defaultSelected={pMethod.code === formik.values.paymentMethod} />
                            ))}
                            <FormikError name='paymentMethod' />
                        </ModalBody>
                        <ModalFooter>
                            {!this.props.hidePreviousButton &&
                                <Button color='primary' outline type='button' onClick={() => this.props.goToPrevious()} className="mr-auto"><Translate id='misc.previous' /></Button>
                            }
                            <Button color='primary' type='submit'><Translate id='misc.next' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        )
    }
}

const PaymentMethod = ({ method, defaultSelected, disabled }) => {
    const formik = useFormikContext();

    const getIcon = useCallback(() => {
        switch (method.code){
            case 'CASH':
                return <img src={"/images/payment/Cash.svg"} height='35' alt='CASH' />;
            case 'CREDITCARD':
                return <img src={"/images/payment/Credit.svg"} height='35' alt='CREDIT CARD' />;
            case 'INTERAC':
                return <img src={"/images/payment/Interac.svg"} height='35' alt='INTERAC' />
            case 'CHEQUE':
                return <img src={"/images/payment/Check.svg"} height='35' alt='CHECK' />
            default:
                return <img src={"/images/payment/Cash.svg"} height='35' alt='CASH' />;
        }
    }, [ method ]);

    return (
        <UserDisplay tag="label" htmlFor={method.code} key={method.code} card hover={!disabled} className={`mb-2 w-100 ${formik.values.paymentMethod === method.code ? 'border-primary' : ''}`}>
            <UserDisplay.Container className="mr-0">
                <CustomInput disabled={disabled} defaultChecked={defaultSelected} id={method.code} type="radio" name="type" onChange={() => formik.setFieldValue('paymentMethod', method.code)} />
            </UserDisplay.Container>
            <UserDisplay.Container className='d-flex align-items-center'>
                <div className="mx-1">
                    {method.icon ? <img src={method.icon} height='35' alt={method.name} /> : getIcon()}
                </div>
                <UserDisplay.Title className={`${disabled ? 'text-muted' : ''}`}>
                    <DisplayI18n
                        field="name"
                        defaultValue={method.name}
                        i18n={method.i18n}
                    />
                </UserDisplay.Title>
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default ManualRegistrationPaymentMethods

ManualRegistrationPaymentMethods.propTypes = {
    paymentMethods: PropTypes.array,
    selectedPaymentMethod: PropTypes.string.isRequired,
    goToPrevious: PropTypes.func.isRequired,
    activateLoading: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};