import {
    Button, Card, CardFooter, Col, Row
} from "reactstrap";

// context
import { FieldArray, Form, Formik } from 'formik';

import SpordleDragDropContext from "../../../../components/DragNDrop/SpordleDragDropContext";
import SpordleDroppable from '../../../../components/DragNDrop/SpordleDroppable';
import SpordleDraggable from '../../../../components/DragNDrop/SpordleDraggable';
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import Translate from "@spordle/intl-elements";
import SpordleSelect from "@spordle/spordle-select";
import { useContext, useState } from "react";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { RolesContext } from "../../../../contexts/RolesContext";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { FormikSelect } from "@spordle/formik-elements";


const AssignRoles = () => {
    const [ availableLoading, setAvailableLoading ] = useState(false)
    const [ selectedLoading, _ ] = useState(false)

    const rolesContext = useContext(RolesContext)
    const organizationContext = useContext(OrganizationContext)

    const onDragEnd = (result) => {
        // dropped outside the list
        if(!result.destination)return;

        if(result.type === 'SECTION'){ // Section drag n drop
            result.source.payload.move(result.source.index, result.destination.index);
        }else if(result.source.droppableId === result.destination.droppableId){ // In the same section
            result.source.payload.move(result.source.index, result.destination.index);
        }else{ // Inter section
            result.source.payload.remove(result.source.index);
            result.destination.payload.insert(result.destination.index, result.payload);
        }
    }

    const searchAvailableUsers = (formik, role) => {
        setAvailableLoading(true)
        rolesContext.getRole(role.id)
            .then((roles) => {
                setAvailableLoading(false)
                // Make identities unique
                const users = roles.users.filter((user) => !!user.identity && user.active == 1).reduce((newUsers, user) => {
                    if(!newUsers.some((u) => u.identity?.identity_id === user.identity?.identity_id)){
                        newUsers.push(user)
                    }
                    return newUsers
                }, [])
                formik.setFieldValue("availableUsers", users || [])
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    setAvailableLoading(false)
                    console.error(error.message)
                    //setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                }
            });
    }

    return (
        <Formik
            initialValues={{
                availableUsers: [],
                selectedUsers: [],
                selectedRole: '',
                //alreadySelectedUsers: [],
            }}
            onSubmit={(values, { setSubmitting }) => {
                // setAvailableColumns(values.availableColumns);
                // setColumns(values.selectedColumns).finally(() => {
                //     setSubmitting(false);
                //     setColumnDefineOpen(false);
                // })
            }}
        >
            { (formik) => (
                <OverlayLoader isLoading={formik.isSubmitting}>
                    <Form>
                        <Card body className="card-shadow">
                            <SpordleDragDropContext onDragEnd={onDragEnd}>
                                <Row>
                                    <Col md='6' className='pb-3'>
                                        <div className='h4 font-bold'>Available Users</div>
                                        <div className="mb-3">
                                            <SpordleSelect
                                                name={'role'}
                                                id={'availableUsersRole'}
                                                onOptionSelected={(role, spordle) => {
                                                    const selectedOption = spordle.getSpordleTable().getData().find((option) => option.value === role[0]);
                                                    searchAvailableUsers(formik, selectedOption)
                                                }}
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                        case 'FILTER':
                                                            return rolesContext.getOrganizationRoles(organizationContext.organisation_id)
                                                                .then((data) => {
                                                                    return (
                                                                        data.map((option) => ({
                                                                            label: option.title,
                                                                            id: option.role_id,
                                                                            value: option.role_id,
                                                                            active: option.active === '1',
                                                                            users: option.users,
                                                                        }))
                                                                    )
                                                                });
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                renderOption={(option) => (
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        {option.option.label}
                                                        {!option.option.active &&
                                                                <i className='text-warning mdi mdi-alert-outline' />
                                                        }
                                                    </div>
                                                )}
                                                renderSelectedOption={(option) => (
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        {!option.active &&
                                                                <i className='text-warning mdi mdi-alert-outline mr-2' />
                                                        }
                                                        {option.label}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <FieldArray name='availableColumns'>
                                            {(sectionsArrayHelper) => (
                                                // this div makes it so even when the list is empty we can still drag into it, otherwise the droppable size is 0x0
                                                <OverlayLoader isLoading={availableLoading} className='w-100 h-100 d-flex'>
                                                    <SpordleDroppable
                                                        droppableId="available_section"
                                                        direction='vertical'
                                                        payload={sectionsArrayHelper}
                                                    >
                                                        {(provided) => {
                                                            return (
                                                                <div
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                    className='w-100 shadow'
                                                                    style={{ height: '500px', overflow: 'hidden', overflowY: 'scroll', border: '1px solid lightgray' }}
                                                                    id={"available_droppable"}
                                                                >
                                                                    {formik.values.availableUsers.map((user, index) => (
                                                                        <SpordleDraggable
                                                                            key={user.identity.identity_id}
                                                                            draggableId={user.identity.identity_id}
                                                                            index={index}
                                                                            payload={user}
                                                                        >
                                                                            {(provided) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    className='pl-2 py-1 hoverable d-flex align-items-center'
                                                                                    id={"available" + user.identity.identity_id + index}
                                                                                >
                                                                                    <i className='mdi mdi-drag text-muted font-16' />
                                                                                    {user.identity.name + " " + user.identity.family_name}
                                                                                </div>
                                                                            )}
                                                                        </SpordleDraggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )
                                                        }}
                                                    </SpordleDroppable>
                                                </OverlayLoader>
                                            )}
                                        </FieldArray>
                                    </Col>

                                    <Col md='6' className='pb-3'>
                                        <div className='d-flex'>
                                            <div className='h4 font-bold text-nowrap'>
                                                Selected Users
                                            </div>
                                            <div className='ml-2 h5 text-nowrap'>{`(${formik.values.selectedUsers.length})`}</div>
                                        </div>
                                        <div className="mb-3">
                                            <FormikSelect
                                                name={'selectedRole'}
                                                id={'selectedRole'}
                                                // onOptionSelected={(role, spordle) => {
                                                //     const selectedOption = spordle.getSpordleTable().getData().find((option) => option.value === role[0]);

                                                //     //searchAlreadySelectedUsers(formik, selectedOption)
                                                // }}
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                        case 'FILTER':
                                                            return rolesContext.getOrganizationRoles(organizationContext.organisation_id)
                                                                .then((data) => {
                                                                    return (
                                                                        data.map((option) => ({
                                                                            label: option.title,
                                                                            id: option.role_id,
                                                                            value: option.role_id,
                                                                            active: option.active === '1',
                                                                            users: option.users,
                                                                        }))
                                                                    )
                                                                });
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                renderOption={(option) => (
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        {option.option.label}
                                                        {!option.option.active &&
                                                                <i className='text-warning mdi mdi-alert-outline' />
                                                        }
                                                    </div>
                                                )}
                                                renderSelectedOption={(option) => (
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        {!option.active &&
                                                                <i className='text-warning mdi mdi-alert-outline mr-2' />
                                                        }
                                                        {option.label}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <FieldArray name='selectedColumns'>
                                            {(sectionsArrayHelper) => (
                                                <OverlayLoader isLoading={selectedLoading} className='w-100 h-100 d-flex'>
                                                    <SpordleDroppable
                                                        droppableId="selected_section"
                                                        direction='vertical'
                                                        payload={sectionsArrayHelper}
                                                    >
                                                        {(provided) => {
                                                            return (
                                                                <div
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                    className='w-100 shadow'
                                                                    style={{ height: '500px', overflow: 'hidden', overflowY: 'scroll', border: '1px solid lightgray' }}
                                                                    id={'selected_droppable'}
                                                                >
                                                                    {formik.values.selectedUsers.map((user, index) => (
                                                                        <SpordleDraggable
                                                                            key={user.identity.identity_id}
                                                                            draggableId={user.identity.identity_id}
                                                                            index={index}
                                                                            payload={user}
                                                                        >
                                                                            {(provided) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    className='pl-2 py-1 hoverable d-flex'
                                                                                    id={'selected' + user.identity.identity_id + index}
                                                                                >
                                                                                    <i className='mdi mdi-drag text-muted' />
                                                                                    {user.identity.name + " " + user.identity.family_name}
                                                                                </div>
                                                                            )}
                                                                        </SpordleDraggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )
                                                        }}
                                                    </SpordleDroppable>
                                                </OverlayLoader>
                                            )}
                                        </FieldArray>
                                    </Col>
                                </Row>
                            </SpordleDragDropContext>

                            <CardFooter className="bg-white text-right">
                                <Button outline color='primary' disabled={formik.isSubmitting} onClick={() => { }}><Translate id='misc.cancel' /></Button>
                                <Button type='submit' color='primary' disabled={formik.isSubmitting} className='ml-1'><Translate id='misc.submit' /></Button>
                            </CardFooter>
                        </Card>
                    </Form>
                </OverlayLoader>
            )}
        </Formik>
    )
}


export default AssignRoles;