import { FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { Button, Col, Collapse, FormGroup, FormText, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import useSWR from 'swr';
import { object, string } from 'yup';
import AnalyticsModal from '../../../../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import CustomAlert from '../../../../../../../components/CustomAlert';
import FormikTiny from '../../../../../../../components/formik/FormikTiny';
import Required from '../../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import { fail, success } from '@spordle/toasts';
import { I18nContext } from '../../../../../../../contexts/I18nContext';
// contexts
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import { UtilsContext } from '../../../../../../../contexts/UtilsContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';


const RefundCreditModal = ({ isOpen, toggle, credit, onCreditRefund }) => {
    const membersContext = useContext(MembersContext);
    const utilsContext = useContext(UtilsContext);
    const i18nContext = useContext(I18nContext);

    const { data: paymentMethods, isValidating } = useSWR(
        [ 'getPaymentMethodsRefundCredit' ],
        () => utilsContext.getPaymentMethods()
            .then((paymentMethods) => paymentMethods.filter((pm) => pm.category === 'MANUAL'))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        ,
        {
            fallbackData: [],
        },
    );

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='refundCreditModal'>
            <ModalHeader toggle={toggle}>
                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.title' />
            </ModalHeader>

            <Formik
                initialValues={{
                    paymentMethod: '',
                    internalNote: '',
                    externalNote: '',
                }}
                validationSchema={object().shape({
                    paymentMethod: string().required(<Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.paymentMethod.required' />),
                    internalNote: string(),
                    externalNote: string(),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    membersContext.refundMemberCredit(credit.member_credit_id, {
                        payment_method: values.paymentMethod,
                        internal_note: values.internalNote,
                        external_note: values.externalNote,
                    })
                        .then(() => {
                            onCreditRefund?.();
                            setSubmitting(false);
                            toggle();
                            success();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                if(error.i18n){
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                }else{
                                    setStatus(<Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.error.' + error.message} defaultMessageId='misc.error' />)
                                }
                                setSubmitting(false);
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalBody>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label for='paymentMethod'><Translate id='components.transaction.editInstallmentsModal.paymentMethod.label' /> <Required /></Label>
                                            <FormikSelect
                                                id='paymentMethod'
                                                name='paymentMethod'
                                                isLoading={isValidating}
                                                renderOption={({ option }) => <DisplayI18n field='name' i18n={option.i18n} defaultValue={option.label} />}
                                                searchKeys={[
                                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                                ]}
                                                options={paymentMethods ?
                                                    paymentMethods.map((method) => ({
                                                        value: method.code,
                                                        i18n: method.i18n,
                                                        label: method.name,
                                                    }))
                                                    :
                                                    []
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    <Label for='internalNote'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.internalNote' /></Label>
                                    <FormikTextArea name='internalNote' id='internalNote' trim rows='2' helper='members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.internalNote.hint' />
                                </FormGroup>
                                <div className="mb-3">
                                    <Label for='externalNote'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.externalNote' /></Label>
                                    <FormikTiny name='externalNote' id='externalNote' init={{ height: 150, selector: 'textarea' }} />
                                    <FormText><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.externalNote.hint' /></FormText>
                                </div>

                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    {formik.status &&
                                        <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                    }
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button type='submit' color='primary'><Translate id='misc.confirm' /></Button>
                                <Button type='button' color='primary' outline onClick={() => toggle()}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    )
}

export default RefundCreditModal