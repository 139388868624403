import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react'
import { Button, Collapse, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import CustomAlert from '../../../../../components/CustomAlert';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { QualificationsContext } from '../../../../../contexts/QualificationsContext';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';

export const MemberQualificationsDeleteModal = ({ setDeleteModalOpen, deleteModalOpen, refreshTable, member_id, member_qualification_id, closeSidePanel, qualification }) => {
    const qualificationsContext = useContext(QualificationsContext);
    const [ errorMsg, setErrorMsg ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);

    return (
        <AnalyticsModal analyticsName='MemberQualificationDeleteModal' isOpen={deleteModalOpen}>
            <OverlayLoader isLoading={isLoading}>
                <ModalHeader>
                    <Translate id='misc.delete' />
                </ModalHeader>
                <ModalBody className='text-center d-block'>
                    <div>
                        <i style={{ fontSize: 75 }} className={`mdi mdi-alert-outline text-danger`} />
                    </div>
                    <div>
                        <Translate id='members.profile.qualifications.deleteMessage' />
                    </div>
                    <div className='h3 font-bold'><DisplayI18n field="name" i18n={qualification?.i18n} defaultValue={qualification?.name} /></div>
                    <div>
                        <Collapse className='px-2' isOpen={!!errorMsg} mountOnEnter unmountOnExit>
                            {!!errorMsg &&
                                <CustomAlert
                                    className='mt-3 text-left'
                                    color='danger'
                                    withTitle
                                    text={errorMsg}
                                    translateText={false}
                                    toggle={() => setErrorMsg('')}
                                />
                            }
                        </Collapse>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            setIsLoading(true);
                            qualificationsContext.deleteMemberQualification(member_id, member_qualification_id)
                                .then(() => {
                                    refreshTable();
                                    setDeleteModalOpen(false);
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        if(error.i18n){
                                            setErrorMsg(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                        }else{
                                            switch (error.message){
                                                case '3191':
                                                case '1000':
                                                case '3179':
                                                    setErrorMsg(<Translate id={`contexts.qualifications.${error.message}`} />);
                                                    break;
                                                default:
                                                    setErrorMsg(<Translate id='misc.error' />);
                                                    break;
                                            }
                                        }
                                    }
                                })
                                .finally(() => {
                                    setIsLoading(false);
                                    closeSidePanel();
                                })
                        }} color='danger'
                    ><Translate id='misc.delete' />
                    </Button>
                    <Button onClick={() => setDeleteModalOpen(!deleteModalOpen)} color='primary' outline><Translate id='misc.cancel' /></Button>
                </ModalFooter>
            </OverlayLoader>
        </AnalyticsModal>
    )
}
