import { FormikError } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { Button, ModalBody, ModalFooter } from "reactstrap"
import { AddSupervisionFormContext } from "../components/AddSupervisionFormContext";
import { string, object } from "yup";
import FormikRadioBtn from "../components/FormikRadioBtn";
import styles from "./StepFillSelectedForm.module.scss";
import { getCategoryTypeChoices, getCategoryTypes } from "../../../officialSupervisionHelper";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";

const StepFillSelectedForm = () => {
    const { formData, previous, updateFormData, next } = useContext(AddSupervisionFormContext);

    const categories = getCategoryTypes(formData.type?.name);
    const stepChoices = getCategoryTypeChoices(formData.type?.name);
    const initValuesCategoryValues = categories.reduce((values, cat) => {
        values.initial[cat] = "";
        values.validation[cat] = string();
        values.rows.push(
            <div className={`${styles.ChoicesContainer} mb-2`} key={`${cat}-form-row`}>
                <div className={`${styles.ChoicesLabel} text-muted mb-1`}>
                    <Translate id={`officialSupervision.categories.${cat}`} />
                </div>
                <div className={`${styles.ChoicesRow} d-flex`}>
                    {stepChoices.map((choice) => (
                        <FormikRadioBtn
                            className="flex-grow-1 w-100"
                            key={`${choice}-${cat}`}
                            name={cat}
                            id={`${cat}-${choice}`}
                            value={choice}
                            label={<Translate id={`officialSupervision.categories.choice.${choice}`} />}
                        />
                    ))}
                </div>
                <FormikError name={cat} />
            </div>,
        );

        return values;
    }, { initial: {}, validation: {}, rows: [] });

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...initValuesCategoryValues.initial,
                overall_score: "",
            }}
            validationSchema={object().shape({
                ...initValuesCategoryValues.validation,
                overall_score: string(),
            })}
            onSubmit={(values) => {
                updateFormData('levels', values);
                next();
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody className="pt-0">
                        <div className="font-bold h4 mb-4">
                            <DisplayI18n
                                field="name"
                                defaultValue={formData.type?.name}
                                i18n={formData.type?.i18n}
                            />
                        </div>
                        <div className="font-medium h5 mb-3"><Translate id='officialSupervision.supervisionType.categories.label' /></div>
                        {initValuesCategoryValues.rows}
                        <div className="pt-3 mt-3 border-top font-medium h5 mb-3"><Translate id='officialSupervision.overallScore.label' /></div>
                        <div className="d-flex">
                            {stepChoices.map((choice) => (
                                <FormikRadioBtn
                                    className="flex-grow-1 w-100 mb-0"
                                    key={`${choice}-overall`}
                                    name="overall_score"
                                    id={`overall-${choice}`}
                                    value={choice}
                                    label={<Translate id={`officialSupervision.categories.choice.${choice}`} />}
                                />
                            ))}
                        </div>
                        <FormikError name="overall_score" />
                    </ModalBody>
                    <ModalFooter>
                        <Button className="mr-auto w-50 w-md-auto" onClick={previous} color="primary" type="button" outline>
                            <Translate id="misc.previous" />
                        </Button>
                        <Button className="w-50 w-md-auto" color="primary" type="submit">
                            <Translate id="misc.next" />
                        </Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default StepFillSelectedForm;