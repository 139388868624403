import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Button, ModalFooter } from "reactstrap";
import { SendCommModalContext } from "../../../context/SendCommModalContext";

const ReviewFooter = ({ disabled, nextBtn }) => {
    const { isLoading, previous, next, toggle, resetTheRecipients, withInitRecipients } = useContext(SendCommModalContext);

    return (
        <ModalFooter>
            {!withInitRecipients &&
                <Button
                    onClick={() => {
                        resetTheRecipients();
                        previous();
                    }}
                    color="primary"
                    type="button"
                    outline
                    className="mr-auto"
                >
                    <Translate id="misc.previous" />
                </Button>
            }
            {nextBtn || <Button disabled={isLoading || disabled} color="primary" type="button" onClick={next}><Translate id="misc.next" /></Button>}
            <Button disabled={isLoading || disabled} color="primary" type="button" outline onClick={toggle}><Translate id="misc.cancel" /></Button>
        </ModalFooter>
    );
}

export default ReviewFooter;