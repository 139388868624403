export default{
    "clinics.clinicInformation.clinicInformation.title"      : "Informations de stage",
    "clinics.clinicInformation.clinicInformation.clinicName" : "Clinic Name",

    // host organization
    "clinics.clinicInformation.clinicInformation.host.title"       : "Organisation hôte",
    "clinics.clinicInformation.clinicInformation.host.placeholder" : "Sélectionnez une organisation",
    "clinics.clinicInformation.clinicInformation.host.validation"  : "L'organisation est requise",

    // clinic info
    "clinics.clinicInformation.clinicInformation.qualification.title"                          : "Qualification",
    "clinics.clinicInformation.clinicInformation.qualification.type.qualification"             : "Qualification officielle ({federationName})",
    "clinics.clinicInformation.clinicInformation.qualification.type.other"                     : "Qualification ou cours de l'organisation hôte",
    "clinics.clinicInformation.clinicInformation.qualification.category"                       : "Catégorie",
    "clinics.clinicInformation.clinicInformation.qualification.qualification"                  : "Qualification",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites"                  : "Préalables",
    "clinics.clinicInformation.clinicInformation.qualification.qualPrerequisites"              : "Parce que la qualification est: {qualificationName}.",
    "clinics.clinicInformation.clinicInformation.qualification.qualPrerequisites.hint.all"     : "Les préalables ci-dessous sont les préalables de base pour la qualification. Ceux-ci sont tous obligatoires pour l'inscription d'un participant.",
    "clinics.clinicInformation.clinicInformation.qualification.qualPrerequisites.hint.one"     : "Les préalables ci-dessous sont les préalables de base pour la qualification. Au moins un parmis ceux-ci est obligatoire pour l'inscription d'un participant.",
    "clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites"            : "Préalables de votre organisation",
    "clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites.hint"       : "Nombre minimum de préalables obligatoire pour l'inscription d'un participant",
    "clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites.hint.none1" : "Vous pouvez ajouter des préalables provenant de votre organisation.",
    "clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites.hint.none2" : "Cliquez ici pour ajouter un préalable",
    "clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites.all"        : "Tous obligatoires",
    "clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites.one"        : "Au moins un obligatoire",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.description"      : "Description",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.organization"     : "Organisation",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.type"             : "Type",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.empty"            : "Aucun préalable pour ce stage",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.number"           : "{number, plural, one {# préalable} other {# préalables}}",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.title"            : "Préalables pour {qualName}",
    "clinics.clinicInformation.clinicInformation.qualification.prerequisites.none"             : "Aucun préalable",
    "clinics.clinicInformation.clinicInformation.qualification.groups.title"                   : "Groupe #{index}",

    // attendees
    "clinics.clinicInformation.clinicInformation.attendees.title"                           : "Participants",
    "clinics.clinicInformation.clinicInformation.attendees.types"                           : "Types de participants",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.attendees"          : "Restrictions de participants",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.attendees.min"      : "Nombre minimum",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.attendees.min.none" : "Aucun nombre minimum",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.attendees.max"      : "Nombre maximum",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.attendees.max.none" : "Aucun nombre maximum",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.age"                : "Restrictions d'âges",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.age.min"            : "Âge minimum",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.age.min.none"       : "Aucun âge minimum",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.age.max"            : "Âge maximum",
    "clinics.clinicInformation.clinicInformation.attendees.requirements.age.max.none"       : "Aucun âge maximum",
    "clinics.clinicInformation.clinicInformation.attendees.criminalRecord"                  : "Vérification d'antécédents",

    // description
    "clinics.clinicInformation.clinicInformation.description.title" : "Description",
    "clinics.clinicInformation.clinicInformation.description.none"  : "Aucune description",
}