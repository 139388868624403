import footerOptions from "./footerOptions";
import groupQuestion from "./groupQuestion";
import groupSection from "./groupSection";
import verticalOptions from "./verticalOptions";
import questionType from "./questionType";

export default{
    ...footerOptions,
    ...groupQuestion,
    ...groupSection,
    ...verticalOptions,
    ...questionType,
}