export default{
    "communications.title"       : "Communications",
    "communications.sendMessage" : "Send Communication",

    "communications.inbox"                         : "Inbox",
    "communications.inbox.recipients.modal.title"  : "All recipients of {title}",
    "communications.inbox.recipients.search.empty" : "No recipient found.",
    "communications.inbox.from"                    : "From",
    "communications.inbox.to"                      : "To",
    "communications.inbox.sent"                    : "Sent",
    "communications.inbox.sent.empty"              : "No sent messages",
    "communications.inbox.btn.back"                : "Back to inbox",
    "communications.inbox.favorites"               : "Favorites",
    "communications.inbox.archived"                : "Archived",
    "communications.inbox.received"                : "Received",
    "communications.inbox.empty"                   : "No messages",
    "communications.inbox.search.empty"            : "No messages found",
    "communications.inbox.selected.empty"          : "No message selected",
    "communications.inbox.deletedSent"             : "Deleted User",

    "communications.inbox.tools.favorite"   : "Favorite",
    "communications.inbox.tools.unfavorite" : "Unfavorite",
    "communications.inbox.tools.archive"    : "Archive",
    "communications.inbox.tools.unarchive"  : "Remove from Archives",
    "communications.inbox.tools.markRead"   : "Mark as Read",
    "communications.inbox.tools.markUnread" : "Mark as Unread",

    "communications.inbox.action.toast.markUnread" : "Marked as Unread",
    "communications.inbox.action.toast.markRead"   : "Marked as Read",
    "communications.inbox.action.toast.archive"    : "Has been archived",
    "communications.inbox.action.toast.unarchive"  : "Has been removed from archived",
    "communications.inbox.action.toast.favorite"   : "Favorited",
    "communications.inbox.action.toast.unfavorite" : "Removed from favorites",

    "communications.btn.compose"         : "Compose",
    "communications.messages.list.title" : "{count, plural, one {Message (#)} other {Messages (#)}}",

    "communications.sendComm.selectType.org"             : "Organization",
    "communications.sendComm.selectType.org.subtitle"    : "Send a communication to one or multiple organizations.",
    "communications.sendComm.selectType.member"          : "Participant",
    "communications.sendComm.selectType.member.subtitle" : "Send a communication to one or multiple participants.",
    "communications.sendComm.recipients.delete.tooltip"  : "This will only remove this email address from the current email list and not from the member profile.",
    "communications.sendComm.recipients.add.plus"        : "Add a participant",

    "communications.message.recipients.download.selected" : "Download Selected {count, plural, one {Contact} other {Contacts}}",
    "communications.message.recipients.download.valid"    : "Download {count, plural, one {# valid recipient} other {# valid recipients}}",
    "communications.message.recipients.download.invalid"  : "Download {count, plural, one {# invalid recipient} other {# invalid recipients}}",
    "communications.message.recipients.downloaded.title"  : "List downloaded!",
    "communications.message.recipients.downloaded.msg"    : "Didn't work ? Try again by <a>clicking here</a>.",
}