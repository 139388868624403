import { FormikSelect } from "@spordle/formik-elements"
import Translate from "@spordle/intl-elements"
import { useFormikContext } from "formik"
import { useContext } from "react"
import { FormGroup } from "reactstrap"
import { PeriodsContext } from "../../../../../contexts/contexts"
import { I18nContext } from "../../../../../contexts/I18nContext"
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext"
import { OrganizationContext } from "../../../../../contexts/OrganizationContext"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import { DisplayCategory } from "../../../../teams/TeamHelpers"
import { formikCodesHaveValues, handleContextQuery, handleQuery, handleSelectFilterChange, sortSelectQueryResult, getRecursiveKeyVal, getTextWhenSettings, filterHasValue } from "../../reportsEngineHelpers"
import FilterLabel from "../FilterLabel"
import SelectOptionRender from "./SelectOptionRender"
import { useQueryContext } from "./useQueryContext"

const EngineMultiSelect = ({ filter, filterRefs }) => {
    const formik = useFormikContext();
    const orgContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext);
    const i18nContext = useContext(I18nContext);
    const contextFunc = useQueryContext(filter.parsedParams?.query?.context, filter.parsedParams?.query?.function);
    const identityRolesContext = useContext(IdentityRolesContext)
    return (
        <FormGroup name={filter.code}>
            <FilterLabel filter={filter} />
            <FormikSelect
                {...filter.parsedParams?.componentProps}
                multi clearable
                id={filter.code}
                name={filter.code}
                ref={(r) => { filterRefs.current[filter.code] = r }}
                search={filter.parsedParams?.componentProps?.searchKeys?.length > 0}
                searchKeys={filter.parsedParams?.componentProps?.searchKeys?.map((search) => search.replace('{{LOCALE}}', i18nContext.getGenericLocale()))}
                loadData={(from, extra, spordleTable) => {
                    if(filter.parsedParams?.query?.endpoint || filter.parsedParams?.query?.context){
                        const promise = filter.parsedParams.query.context ? handleContextQuery(contextFunc) : handleQuery;

                        switch (from){
                            case 'CDM':
                            case 'REFRESH':
                            case 'FILTER':
                                spordleTable.setLoading();
                                return promise(filter, formik, orgContext.organisation_id, periodsContext.selectedPeriod.period_id, identityRolesContext.federation.organisation_id)
                                    .then((options) => {
                                        const loadedOptions = (options.map((option) => {
                                            return ({
                                                id: option[filter.parsedParams?.query.optionValue],
                                                value: option[filter.parsedParams?.query.optionValue],
                                                label: option.name,
                                                i18n: option.i18n,
                                                option: option,
                                            })
                                        }));

                                        if(filter.parsedParams.query.sort){
                                            const sortKey = filter.parsedParams.query.sort;
                                            sortSelectQueryResult(loadedOptions, sortKey, i18nContext.getGenericLocale());
                                        }

                                        if(filter.parsedParams.query.jsFilter){
                                            const filterKeys = Object.entries(filter.parsedParams.query.jsFilter)
                                            return loadedOptions.filter((option) => filterKeys.every(([ filterKey, filterObj ]) => {
                                                const valueToCompare = getRecursiveKeyVal({ ...option }, filterObj.key)
                                                if(filterObj.currentState){
                                                    if(filterKey === 'organisation_id'){
                                                        return valueToCompare === orgContext.organisation_id;
                                                    }else if(filterKey === 'period_id'){
                                                        return valueToCompare === periodsContext.selectedPeriod.period_id;
                                                    }
                                                }else if(filterObj.currentReportFilters){
                                                    if(Array.isArray(formik.values[filterKey])){
                                                        return formik.values[filterKey]?.length > 0 ? formik.values[filterKey].includes(valueToCompare) : true
                                                    }
                                                    return formik.values[filterKey] ? valueToCompare == formik.values[filterKey] || '' : true
                                                }else if(filterObj.value){
                                                    return valueToCompare == filterObj.value;
                                                }
                                            }))
                                        }

                                        return loadedOptions;
                                    })
                            default:
                                break;
                        }
                    }else{
                        return Promise.resolve(filter.parsedOptions || []);
                    }
                }}
                loadingStatus='success'
                onOptionSelected={(period, spordleSelect) => handleSelectFilterChange(filter, formik, period, spordleSelect, filterRefs)}
                className={filter.parsedParams?.componentProps?.className}
                renderOption={(option) => {
                    switch (filter.parsedParams?.componentProps?.renderOption?.renderType){
                        case 'Displayi18n':
                            const fieldi18n = filter.parsedParams?.componentProps?.renderOption?.key;
                            return <DisplayI18n field={fieldi18n === "organisation_name" ? "name" : fieldi18n} defaultValue={option.option[filter.parsedParams?.componentProps?.renderOption?.key]} i18n={option.option.i18n} />
                        case 'DisplayTeamCategory':
                            return (
                                <>
                                    <DisplayCategory short category={option.option[filter.parsedParams?.componentProps?.renderOption?.key]} />
                                    {option.option.option.gender && <div className="small text-muted"><Translate id={`form.fields.gender.${option.option.option.gender.toLowerCase()}`} defaultMessage={option.option.option.gender} /></div>}
                                </>
                            )
                        case 'DisplayQualification':
                            return (
                                <>
                                    <div className='text-nowrap'><DisplayI18n field='name' defaultValue={option.option[filter.parsedParams?.componentProps?.renderOption?.key].qualification_category.name} i18n={option.option[filter.parsedParams?.componentProps?.renderOption?.key].qualification_category.i18n} /> - <DisplayI18n field='name' defaultValue={option.option[filter.parsedParams?.componentProps?.renderOption?.key].name} i18n={option.option[filter.parsedParams?.componentProps?.renderOption?.key].i18n} /> </div>
                                    <div className='text-nowrap small text-muted'>{option.option[filter.parsedParams?.componentProps?.renderOption?.key].qualification_level && <DisplayI18n field='name' defaultValue={option.option[filter.parsedParams?.componentProps?.renderOption?.key].qualification_level.name} i18n={option.option[filter.parsedParams?.componentProps?.renderOption?.key].qualification_level.i18n} />}</div>
                                </>
                            )
                        case 'default':
                            const allOptionInfo = option.option
                            return (
                                <>
                                    <SelectOptionRender
                                        dataKey={filter.parsedParams?.componentProps?.renderOption?.___label?.key}
                                        type={filter.parsedParams?.componentProps?.renderOption?.___label?.type}
                                        allOptionInfo={allOptionInfo}
                                    />
                                    {filter.parsedParams?.componentProps?.renderOption?.___sublabel &&
                                        <SelectOptionRender
                                            dataKey={filter.parsedParams?.componentProps?.renderOption?.___sublabel?.key}
                                            type={filter.parsedParams?.componentProps?.renderOption?.___sublabel?.type}
                                            allOptionInfo={allOptionInfo}
                                            className='small'
                                        />
                                    }
                                </>
                            )
                        default:
                            return <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                    }


                }}
                disabled={!formikCodesHaveValues(filter.parsedParams?.enabledWhen, formik) || !filterHasValue(filter.parsedParams?.enabledWhenValue?.filter, filter.parsedParams?.enabledWhenValue?.value, formik)}
                textWhenSetting={getTextWhenSettings(filter.parsedParams?.componentProps?.textWhenSetting)}
            />
        </FormGroup>
    )
}

export default EngineMultiSelect