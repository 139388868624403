import { FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { success } from '@spordle/toasts';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
    Badge,
    Button, Card, Col, Collapse, ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import { object, string } from 'yup';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { getMemberStatusChangeReason, releasePotentialIneligibleMember } from '../../../../api/client/potentialIneligibleMembers';
import CrossFade from '../../../../components/crossFade/CrossFade';
import CustomAlert from '../../../../components/CustomAlert';
import CustomAnimatedIcon from '../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import DisplayOrganization from '../../../../components/organization/DisplayOrganization';
import QuickViewButton from '../../../../components/quickView/QuickViewButton';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { I18nContext } from '../../../../contexts/I18nContext';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { organisationCategoryIntl } from '../../../../helpers/constants';
import { DisplayI18n } from '../../../../helpers/i18nHelper';

const PotentialIneligibleMembersModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName='memberPermanentReleaseModal'>
            <PotentialIneligibleMembersModalInner {...props} />
        </AnalyticsModal>
    )
}

const PotentialIneligibleMembersModalInner = ({ toggle, selectedRows, spordleTable, forceCloseSidePanel }) => {
    const membersContext = useContext(MembersContext)
    const { getGenericLocale } = useContext(I18nContext)
    const orgContext = useContext(OrganizationContext);
    const idRolesContext = useContext(IdentityRolesContext);


    // the attachment currently being approved, this is made this way to support approval of multiple attachments in a row
    const [ currentlyApprovingRow, setCurrentlyApprovingRow ] = useState(selectedRows[0]);
    // for chaining multiple documents one after one another
    const [ currentRowIndex, setCurrentRowIndex ] = useState(0);

    // state of buttons at bottom of page
    // possible state: 'INITIAL', 'RELEASE', 'INELIGIBLE'
    const [ buttonState, setButtonState ] = useState('INITIAL');

    // hasApprovedAtLeastOne
    const [ hasApprovedAtLeastOne, setHasApprovedAtLeastOne ] = useState(false);


    // for single approval and so that the first attachment is in state at first open
    useEffect(() => {
        setCurrentlyApprovingRow(selectedRows[0])
    }, [ selectedRows[0].member_potential_ineligible_id ])

    const nextRowIndex = currentRowIndex + 1;

    const nextApproval = async(formik) => {
        if(nextRowIndex !== selectedRows.length){
            await setCurrentlyApprovingRow(selectedRows[nextRowIndex])
            setCurrentRowIndex(nextRowIndex)
        }else{
            // show success dialog
            formik.setStatus('success')
        }
    }

    return (
        <Formik
            initialValues={{
                note: '',
                ineligibleReason: '',
                organisation_deciding: '',
            }}
            onSubmit={(data, formik) => {
                formik.setSubmitting(true)
                if(buttonState === 'RELEASE'){
                    return releasePotentialIneligibleMember(currentlyApprovingRow.member_potential_ineligible_id, {
                        note: data.note || '',
                        released_at: moment().format('YYYY-MM-DD'),
                    })
                        .then(() => {
                            formik.resetForm();
                            setHasApprovedAtLeastOne(true);
                            if(selectedRows.length === 1){
                                formik.setStatus('success');
                            }else if(selectedRows.length > 1){
                                setButtonState('INITIAL');
                                success();
                                nextApproval(formik);
                            }
                        }).catch((error) => {
                            formik.setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            if(AxiosIsCancelled(error.message)){
                                console.error(error.message);
                                formik.setSubmitting(false);
                            }
                            setButtonState('INITIAL');
                        })
                }
                // confirm ineligibility
                if(buttonState === 'INELIGIBLE'){
                    return membersContext.patchMemberStatus(currentlyApprovingRow.member_id, 'INELIGIBLE', data.note, data.ineligibleReason, data.organisation_deciding)
                        .then(() => {
                            formik.resetForm();
                            setHasApprovedAtLeastOne(true);
                            if(selectedRows.length === 1){
                                formik.setStatus('success');
                            }else if(selectedRows.length > 1){
                                setButtonState('INITIAL');
                                success();
                                nextApproval(formik);
                            }
                        }).catch((error) => {
                            formik.setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            if(AxiosIsCancelled(error.message)){
                                console.error(error.message);
                                formik.setSubmitting(false);
                            }
                            setButtonState('INITIAL');
                        })
                }
            }}
            validationSchema={object().shape({
                note: string().required(<Translate id='task.permanentReleaseRequests.modal.reject.note.validation' />),
                ineligibleReason: string().test({
                    name: 'noteWhenReject',
                    message: <Translate id='task.ineligible.modal.reason.validation' />,
                    test: (ineligibleReason) => {
                        if(buttonState === 'INELIGIBLE'){
                            return ineligibleReason;
                        }
                        return true // not required
                    },
                }),
            })}
        >
            {(formik) => (
                <>
                    <ModalHeader
                        toggle={() => {
                            if(hasApprovedAtLeastOne){
                                spordleTable?.refreshTable();
                                forceCloseSidePanel();
                            }
                            setButtonState("INITIAL")
                            toggle();
                        }}
                    >
                        <Translate id='task.ineligible.modal.manage.title' />
                    </ModalHeader>
                    <Form>
                        <CrossFade isVisible={formik.status !== 'success'}>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalBody>
                                    <Card className='card-shadow px-3 py-3'>
                                        <UserDisplay className='w-100'>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    alt={currentlyApprovingRow.member.first_name + ' ' + currentlyApprovingRow.member.last_name}
                                                    abbr={currentlyApprovingRow.member.first_name.charAt(0) + currentlyApprovingRow.member.last_name.charAt(0)}
                                                    src={currentlyApprovingRow.picture?.full_path}
                                                    filePos={currentlyApprovingRow.picture?.file_position}
                                                    width={60}
                                                    className="mr-2"
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container className="w-100">
                                                <UserDisplay.Title className="d-flex justify-content-between">
                                                    <div className='d-flex justify-content-start'>
                                                        <QuickViewButton member={{ member_id: currentlyApprovingRow.member_id }}>
                                                            {currentlyApprovingRow.member.first_name + ' ' + currentlyApprovingRow.member.last_name}
                                                        </QuickViewButton>
                                                    </div>
                                                </UserDisplay.Title>
                                                <UserDisplay.Subtitle>
                                                    <Link className="text-nowrap" to={`/members/profile/${currentlyApprovingRow.member_id}`}>
                                                        {(currentlyApprovingRow.member.unique_identifier) ?
                                                            <>#{currentlyApprovingRow.member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                            :
                                                            '-'
                                                        }
                                                    </Link>
                                                </UserDisplay.Subtitle>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    </Card>
                                    <div>
                                        <div className='text-muted d-flex'>
                                            <Translate id='misc.organization' />
                                        </div>
                                        {/* hardcoded because this API call only returns pending member change requests */}
                                        <div className='text-dark'>
                                            <DisplayOrganization organisation={{ organisation_id: currentlyApprovingRow.organisation_id }}>
                                                <DisplayI18n i18n={currentlyApprovingRow.organisation.i18n} defaultValue={currentlyApprovingRow.organisation.name} field='name' />
                                            </DisplayOrganization>
                                        </div>
                                    </div>
                                    <div className='pt-2'>
                                        <div className='text-muted d-flex'>
                                            <Translate id='task.unconfirmedOrg.sidepanel.submission.date' />
                                        </div>
                                        {/* hardcoded because this API call only returns pending member change requests */}
                                        <div className='text-dark'>
                                            {moment(currentlyApprovingRow.created_at).format("YYYY-MM-DD HH:mm")}
                                        </div>
                                    </div>
                                    <div className='pt-2 pb-3'>
                                        <div className='text-muted d-flex'>
                                            <Translate id='misc.status' />
                                        </div>
                                        {/* hardcoded because this API call only returns pending member change requests */}
                                        <Badge color={"warning"}><Translate id='reports.column.status.PENDING' /></Badge>
                                    </div>

                                    {/* ERROR MANAGEMENT */}
                                    {formik.status &&
                                        <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                            <CustomAlert className='mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                        </Collapse>
                                    }
                                    <div className='pt-2'>
                                        {/* INITIAL STATE */}
                                        <CrossFade isVisible={buttonState === 'INITIAL'}>
                                            <Row>
                                                <Col className='pr-1'>
                                                    <Button
                                                        outline
                                                        className='w-100'
                                                        color='primary'
                                                        onClick={() => setButtonState('RELEASE')}
                                                    >
                                                        <Translate id='task.ineligible.modal.release' />
                                                    </Button>
                                                </Col>
                                                <Col className='pl-1'>
                                                    <Button
                                                        outline
                                                        className='w-100'
                                                        color='danger'
                                                        onClick={() => setButtonState('INELIGIBLE')}
                                                    >
                                                        <Translate id='task.ineligible.modal.ineligible' />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            {selectedRows.length > 1 &&
                                                <div className='w-100 mt-2'>
                                                    <Button
                                                        className='w-100'
                                                        color='link'
                                                        onClick={() => nextApproval(formik)}
                                                    >
                                                        <Translate id='task.document.approval.modal.skip' />
                                                    </Button>
                                                </div>
                                            }
                                        </CrossFade>
                                        <CrossFade isVisible={buttonState === 'INELIGIBLE'} unmountOnExit>
                                            <div className='pb-2'>
                                                <FormikSelect
                                                    name='ineligibleReason'
                                                    id='ineligibleReason'
                                                    className='mb-2'
                                                    renderOption={({ option }) => (
                                                        <>
                                                            <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                            <div className='small text-muted'><DisplayI18n field='description' defaultValue={option.description} i18n={option.i18n} /></div>
                                                        </>
                                                    )}
                                                    searchKeys={[ `i18n.${getGenericLocale()}.name` ]}
                                                    placeholder='task.ineligible.modal.reason.placeholder'
                                                    loadData={(from) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return membersContext.getStatuses()
                                                                    .then((statuses) => {
                                                                        const query = {};
                                                                        const newStatus = statuses.find((status) => status.code === 'INELIGIBLE')
                                                                        if(newStatus){
                                                                            query.apply_to_member_status = newStatus.member_status_id
                                                                        }
                                                                        return getMemberStatusChangeReason(query).then((reasons) => reasons.map((reason) => ({
                                                                            value: reason.member_status_reason_id,
                                                                            label: reason.name,
                                                                            i18n: reason.i18n,
                                                                            description: reason.description,
                                                                        })))
                                                                    });
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                />
                                                <FormikSelect
                                                    name='organisation_deciding'
                                                    id='organisation_deciding'
                                                    placeholder='members.profile.statusChange.organization'
                                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                    searchKeys={[ `i18n.${getGenericLocale()}.name` ]}
                                                    loadData={(from) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                formik.setFieldValue('organisation_deciding', idRolesContext.federation.organisation_id)
                                                                return orgContext.getOrganizationCategory(orgContext.organisation_id)
                                                                    .then((cats) => cats.filter((cat) => organisationCategoryIntl.includes(cat.default_name)).map((cat) => cat.category_id))
                                                                    .then((categories) => {
                                                                        return orgContext.getOrganizationByCategories(idRolesContext.federation.organisation_id, { organisation_category_id: categories })
                                                                    })
                                                                    .then((orgs) => [
                                                                        { // we also want federation
                                                                            value: idRolesContext.federation.organisation_id,
                                                                            label: idRolesContext.federation.organisation_name,
                                                                            i18n: idRolesContext.federation.i18n,
                                                                        },
                                                                        ...orgs.map((org) => ({
                                                                            value: org.organisation_id,
                                                                            label: org.organisation_name,
                                                                            i18n: org.i18n,
                                                                        })),
                                                                    ]);
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </CrossFade>
                                        <CrossFade className='mb-2' isVisible={buttonState === 'INELIGIBLE' || buttonState === 'RELEASE'}>
                                            <FormikTextArea
                                                trim
                                                translatePlaceholder
                                                placeholder={'task.document.approval.modal.refuse.placeholder'}
                                                rows={2}
                                                name='note'
                                                id='releaseRefusalNote'
                                            />
                                        </CrossFade>
                                        {/* CONFIRM MAKE INELIGIBLE */}
                                        <CrossFade isVisible={buttonState === 'INELIGIBLE'}>
                                            <CustomAlert color='warning' text={'members.profile.ineligibleModal.message'} />
                                            <Row>
                                                <Col className='pr-2'>
                                                    <Button
                                                        outline
                                                        className='w-100'
                                                        color='primary'
                                                        onClick={() => setButtonState('INITIAL')}
                                                    >
                                                        <Translate id='misc.cancel' />
                                                    </Button>
                                                </Col>
                                                <Col className='pl-2'>
                                                    <Button
                                                        className='w-100'
                                                        color='danger'
                                                        onClick={() => formik.submitForm()}
                                                    >
                                                        <Translate id='task.ineligible.modal.confirm.ineligible' />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CrossFade>
                                        {/* CONFIRM RELEASE FROM LIST  */}
                                        <CrossFade isVisible={buttonState === 'RELEASE'}>
                                            <Row className='pt-2'>
                                                <Col className='pr-2'>
                                                    <Button
                                                        outline
                                                        className='w-100'
                                                        color='primary'
                                                        onClick={() => setButtonState('INITIAL')}
                                                    >
                                                        <Translate id='misc.cancel' />
                                                    </Button>
                                                </Col>
                                                <Col className='pl-2'>
                                                    <Button
                                                        className='w-100'
                                                        color='primary'
                                                        onClick={() => formik.submitForm()}
                                                    >
                                                        <Translate id='task.ineligible.modal.confirm.release' />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CrossFade>
                                    </div>

                                </ModalBody>
                            </OverlayLoader>
                        </CrossFade>
                        {/* SUCCESS STEP ONLY ACTIVE IF SINGLE APPROVAL */}
                        <CrossFade isVisible={formik.status === 'success'}>
                            <ModalBody className="text-center">
                                <CustomAnimatedIcon withCircle className="text-success" size={50} icon="checkmark" />
                                <div className="h4 font-bold mt-2">
                                    <Translate id="misc.success" />
                                </div>
                                <p>
                                    <Translate id={selectedRows.length > 1 ? 'task.ineligible.modal.step.success.multi' : 'task.ineligible.modal.step.success'} />
                                </p>

                                <Button
                                    color="primary" type="button" onClick={() => {
                                        setButtonState("INITIAL")
                                        toggle(); spordleTable?.refreshTable(); forceCloseSidePanel();
                                    }}
                                >
                                    <Translate id="misc.close" />
                                </Button>
                            </ModalBody>
                        </CrossFade>
                    </Form>
                </>
            )}
        </Formik>
    );
}

export default PotentialIneligibleMembersModal;