export default{
    "settings.merchantAccounts.title"           : "Comptes marchands",
    "settings.merchantAccounts.help.1"          : "Un compte marchand est nécessaire pour pouvoir traiter les paiements pour votre organisation.",
    "settings.merchantAccounts.help.2"          : "Les participants et les membres peuvent payer avec une carte de crédit. Ces paiements seront traités par le processeur de paiement choisi ci-dessous.",
    "settings.merchantAccounts.activatedOn"     : "Activé le",
    "settings.merchantAccounts.offers"          : "Processeurs de paiement",
    "settings.merchantAccounts.selectOffers"    : "Sélectionnez un processeur de paiement",
    "settings.merchantAccounts.contact"         : "Contacter",
    "settings.merchantAccounts.viewOffer"       : "Voir l'offre",
    "settings.merchantAccounts.choose"          : "Ouverture d’un compte marchand Paysafe",
    "settings.merchantAccounts.chooseSportsPay" : "Contacter SportsPay",
    "settings.merchantAccounts.external"        : "Externe",
    "settings.merchantAccounts.embedded"        : "Intégré",
    "settings.merchantAccounts.preferred"       : "Partenaire préféré",

    "settings.merchantAccounts.note.paysafe.1" : "Création de compte marchand dans la plateforme",
    "settings.merchantAccounts.note.paysafe.2" : "Les paiements sont effectués dans la plateforme",
    "settings.merchantAccounts.note.paysafe.3" : "Les utilisateurs gèrent leurs paiements et cartes de crédit dans la plateforme via {spordle_account}",
    "settings.merchantAccounts.note.paysafe.4" : "Rapports transactionnels dans la plateforme ",
    "settings.merchantAccounts.note.paysafe.5" : "Meilleure expérience utilisateur et meilleur support",

    "settings.merchantAccounts.note.sportspay.1" : "Création de compte marchand sur SportsPay",
    "settings.merchantAccounts.note.sportspay.2" : "Redirection des paiements à SportPay",
    "settings.merchantAccounts.note.sportspay.3" : "Les utilisateurs gèrent leurs paiements sur SportPay",
    "settings.merchantAccounts.note.sportspay.4" : "Rapports transactionnels disponibles sur SportsPay",

    "settings.merchantAccounts.provider.QUICK_ENROLLMENT" : "SportsPay",
    "settings.merchantAccounts.provider.PAYSAFE"          : "Paysafe",
}