import React from 'react';
import { Nav,
    Navbar,
    NavItem } from 'reactstrap';
// Context Providers
import { OrganizationContext } from '../../../contexts/OrganizationContext';
// I18N


import withContexts from '../../../helpers/withContexts';
import UserImg from '../../../components/UserImg';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import Svg from '../../../components/Svg';
import Translate from '@spordle/intl-elements';
import { RolesContext } from '../../../contexts/RolesContext';
import { stringBuilder } from '@spordle/helpers';
import MobileMenu from './MobileMenu';
import { lockBodyScroll, unlockBodyScroll } from '../../../helpers/uiHelper';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { AuthContext } from '../../../contexts/contexts';
import MobileFooter from './footer/MobileFooter';

class MobileNav extends React.Component{
    constructor(props){
        super(props);
        this.searchtoggle = this.searchtoggle.bind(this);

        this.menus = {
            search: 'search',
            tasks: 'tasks',
            help: 'help',
            profile: 'profile',
        }

        this.state = {
            burgerMenuIsOpen: false,
            collapse: false,
            shouldShowLink: false,

            footerMenuActiveTab: false,
        };
    }

    shouldShowChangeOrgLink = () => {
        Promise.all([
            this.props.OrganizationContext.getOrganizationChildren(this.props.IdentityRolesContext.organisation.organisation_id),
            this.props.AuthContext.getUserIdentityRole(),
        ])
            .then(([ orgChildren, userIdentities ]) => {
                this.setState(() => ({ shouldShowLink: (orgChildren.length > 0 || userIdentities.length > 1) }));
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                }
            })
    }

    openMenu = () => {
        lockBodyScroll();
        this.setState((prev) => ({ ...prev, burgerMenuIsOpen: true }));
    }

    closeMenu = () => {
        unlockBodyScroll();
        this.setState((prev) => ({ ...prev, burgerMenuIsOpen: false }));
    }

    toggleMenu = () => {
        if(this.state.burgerMenuIsOpen){
            this.closeMenu();
        }else{
            this.openMenu();
        }
    }

    searchtoggle(){
        this.setState((state) => ({
            collapse: !state.collapse,
        }))
    }

    goToChange = () => {
        this.props.history.push('/changeorganization');
    }

    componentDidMount(){
        this.shouldShowChangeOrgLink();
    }

    setFooterMenuActiveTab = (tab) => {
        if(tab === this.menus.help || tab === this.menus.profile){
            lockBodyScroll();
        }else if(!tab){
            unlockBodyScroll();
        }
        this.setState(() => ({
            footerMenuActiveTab: tab,
        }))
    }

    componentDidUpdate(prevProps){
        if(prevProps.IdentityRolesContext.organisation.organisation_id !== this.props.IdentityRolesContext.organisation.organisation_id){
            this.shouldShowChangeOrgLink();
        }
    }

    render(){
        return (
            <>
                <header className={stringBuilder({ "sid-mobile-burger-is-open": this.state.burgerMenuIsOpen }, "topbar navbarbg sid-mobile-header")}>
                    <Navbar className={"top-navbar navbar-light px-3"} expand="md">
                        <Nav className="align-items-center flex-grow-1 mw-100" navbar>
                            <NavItem className="sid-mobile-header-burger-btn" onClick={this.toggleMenu}>
                                <i className="sid-mobile-header-burger-icon" />
                            </NavItem>
                            <NavItem
                                tag="button"
                                disabled={!this.state.shouldShowLink}
                                className='sid-mobile-header-org'
                                onClick={() => {
                                    if(this.state.burgerMenuIsOpen){
                                        this.closeMenu();
                                    }else if(this.state.footerMenuActiveTab){
                                        this.setFooterMenuActiveTab('');
                                    }
                                    this.goToChange()
                                }}
                            >
                                <UserImg
                                    abbr={this.props.OrganizationContext.abbreviation}
                                    src={this.props.OrganizationContext.logo?.full_path}
                                    filePos={this.props.OrganizationContext.logo?.file_position}
                                    alt={this.props.OrganizationContext.short_url + ' logo'}
                                    width={40}
                                    className="mr-2 p-0 shadow-none"
                                />
                                <div className="sid-mobile-header-org-name">
                                    <div className="small font-medium line-height-1 text-truncate">{this.props.RolesContext.title}</div>
                                    <DisplayI18n field='name' defaultValue={this.props.OrganizationContext.organisation_name} i18n={this.props.OrganizationContext.i18n} />
                                </div>
                                {this.state.shouldShowLink &&
                                    <div className="sid-mobile-header-org-change text-nowrap">
                                        <small>
                                            <Translate id="header.change" />
                                        </small>
                                        <Svg width={25} icon="changeOrganization" />
                                    </div>
                                }
                            </NavItem>
                        </Nav>
                    </Navbar>
                </header>
                <aside
                    className={stringBuilder({ "sid-mobile-burger-is-open": this.state.burgerMenuIsOpen }, "sid-mobile-menu")}
                    onClick={(e) => {
                        if(e.target === e.currentTarget){
                            this.closeMenu();
                        }
                    }}
                >
                    <MobileMenu closeMenu={this.closeMenu} {...this.props} />
                </aside>
                <MobileFooter
                    {...this.props}
                    eatHamburger={this.closeMenu}
                    hamburgerIsOpened={this.state.burgerMenuIsOpen}
                    setFooterMenuActiveTab={this.setFooterMenuActiveTab}
                    footerMenuActiveTab={this.state.footerMenuActiveTab}
                    menus={this.menus}
                />
            </>
        );
    }
}

export default withContexts(OrganizationContext, AuthContext, IdentityRolesContext, RolesContext)(MobileNav);
