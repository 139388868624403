import { FormikDateTime } from '@spordle/formik-elements'
import Translate, { DateFormat } from '@spordle/intl-elements'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { mixed, object } from 'yup'
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI'
import FormikEditable from '../../../../../components/formik/FormikEditable'
import { SpordletablePanelContext } from '../../../../../components/sidePanel/SpordlePanel'
import { fail, success } from '@spordle/toasts'
import { TeamsContext } from '../../../../../contexts/TeamsContext'
import { DisplayI18n } from '../../../../../helpers/i18nHelper'
import { Tooltip } from '@mantine/core'
import { Col, Row } from 'reactstrap'

const TeamInactiveRosterReleaseDate = ({ teamMember, startLoading, removeLoading, hasPermissionToEdit }) => {
    const teamsContext = useContext(TeamsContext);
    const teamId = useRouteMatch("/teams/profile/:teamId").params.teamId;
    const [ canEdit, setCanEdit ] = useState(false);
    const [ tip, setTip ] = useState("");
    const sidePanelContext = useContext(SpordletablePanelContext);

    useEffect(() => {
        teamsContext.getTeamSettings(teamId)
            .then((settings) => {
                const filteredSettings = {}
                for(const key in settings){
                    if(Object.hasOwnProperty.call(settings, key)){
                        filteredSettings[key] = settings[key];
                    }
                }
                return filteredSettings
            })
            .then((settings) => {
                if(hasPermissionToEdit){
                    if(Object.keys(settings || {}).length > 0 && settings.protect_released_date?.active == "1" && settings.protect_released_date?.value === 'yes'){
                        setTip('teams.profile.roster.sidePanel.releasedDate.disabled');
                    }else{
                        setCanEdit(true);
                    }
                }
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    return (
        <Row>
            <Col>
                <div className="text-muted"><Translate id='teams.profile.roster.table.releaseDate' /></div>
                <FormikEditable
                    id='playerRealeaseDate'
                    disabled={!canEdit}
                    initialValues={{
                        releaseDate: teamMember.release_date ? moment(teamMember.release_date) : '',
                    }}
                    validationSchema={object().shape({
                        releaseDate: mixed().test({
                            name: 'formatTest',
                            test: moment.isMoment,
                        }),
                    })}
                    onSubmit={({ releaseDate }) => {
                        if(!teamMember.release_date || releaseDate.format('YYYY-MM-DD') != moment(teamMember.release_date).format('YYYY-MM-DD')){
                            startLoading();
                            const newDate = releaseDate.toISOString();
                            return teamsContext.releaseTeamMember(teamId, teamMember.team_member_id, true, newDate)
                                .then(() => {
                                    teamsContext.getTeamMembers(teamsContext.cachedTeam.team_id, {
                                        team_member_id: teamMember.team_member_id,
                                    })
                                        .then((team_members) => {
                                            const newValues = sidePanelContext.createNewValues({ ...team_members[0], name: team_members[0].member?.first_name + ' ' + team_members[0].member?.last_name }) // name for table sorting
                                            sidePanelContext.syncRows(newValues);
                                            success();
                                        })
                                })
                                .catch((errors) => {
                                    if(!AxiosIsCancelled(errors[0].message)){
                                        console.error(errors[0].message);
                                        switch (errors[0].code){
                                            case '3381':// Team setting related error
                                            case '3494':
                                                fail({ info: <Translate id='teams.profile.settings.error.3381' values={{ data: errors[0].origin }} />, skipInfoTranslate: true });
                                                break;
                                            default:
                                                fail();
                                                break;
                                        }
                                    }
                                })
                                .finally(removeLoading)
                        }
                    }}
                >
                    {(isEditing) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'>
                                    <Tooltip disabled={!tip || !canEdit} zIndex={3000} withArrow label={<Translate id={tip} />}>
                                        {teamMember.release_date ?
                                            <DateFormat value={teamMember.release_date} />
                                            :
                                            '-'
                                        }
                                    </Tooltip>
                                </div>
                            )
                        }
                        return <FormikDateTime id='releaseDate' name='releaseDate' timeFormat={false} />

                    }}
                </FormikEditable>
            </Col>
            <Col>
                <div className="text-muted"><Translate id='teams.profile.roster.table.releaseTime' /></div>
                <FormikEditable
                    id='playerRealeaseTime'
                    disabled={!canEdit}
                    initialValues={{
                        releaseTime: teamMember.release_date ? moment(teamMember.release_date) : '',
                    }}
                    validationSchema={object().shape({
                        releaseTime: mixed().test({
                            name: 'isValid',
                            message: <Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.time.invalidFormat' />,
                            test: function(date){
                                return moment.isMoment(date);
                            },
                        }),
                    })}
                    onSubmit={({ releaseTime }) => {
                        if(!teamMember.release_date || releaseTime.format('h:mm A') != moment(teamMember.release_date).format('h:mm A')){
                            startLoading();
                            const newDate = releaseTime.toISOString();
                            return teamsContext.releaseTeamMember(teamId, teamMember.team_member_id, true, newDate)
                                .then(() => {
                                    teamsContext.getTeamMembers(teamsContext.cachedTeam.team_id, {
                                        team_member_id: teamMember.team_member_id,
                                    })
                                        .then((team_members) => {
                                            const newValues = sidePanelContext.createNewValues({ ...team_members[0], name: team_members[0].member?.first_name + ' ' + team_members[0].member?.last_name }) // name for table sorting
                                            sidePanelContext.syncRows(newValues);
                                            success();
                                        })
                                })
                                .catch((errors) => {
                                    if(!AxiosIsCancelled(errors[0].message)){
                                        console.error(errors[0].message);
                                        switch (errors[0].code){
                                            case '3381':// Team setting related error
                                            case '3494':
                                                fail({ info: <Translate id='teams.profile.settings.error.3381' values={{ data: errors[0].origin }} />, skipInfoTranslate: true });
                                                break;
                                            default:
                                                fail();
                                                break;
                                        }
                                    }
                                })
                                .finally(removeLoading)
                        }
                    }}
                >
                    {(isEditing, _, formik) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'>
                                    <Tooltip disabled={!tip || !canEdit} zIndex={3000} withArrow label={<Translate id={tip} />}>
                                        {teamMember.release_date ?
                                            <DateFormat format='h:mm A' value={teamMember.release_date} />
                                            :
                                            '-'
                                        }
                                    </Tooltip>
                                </div>
                            )
                        }
                        return (
                            <FormikDateTime
                                className='date-picker-top date-picker-left'
                                dateFormat={false}
                                timeFormat={'h:mm A'}
                                id='releaseTime'
                                name='releaseTime'
                            />
                        )
                    }}
                </FormikEditable>
            </Col>
        </Row>
    )
}

export default TeamInactiveRosterReleaseDate
