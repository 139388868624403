import { FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { array, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import { fail } from '@spordle/toasts';

const ReginalSettingsEdit = ({ toggleEditMode, settings }) => {
    const organisationContext = useContext(OrganizationContext);

    const [ langs, setLangs ] = useState([]);

    useEffect(() => {
        if(settings){
            const languageOptions = [];
            for(const key in settings.language.value){
                if(Object.hasOwnProperty.call(settings.language.value, key)){
                    const langValue = settings.language.value[key];
                    languageOptions.push({
                        value: key,
                        label: langValue.i18n.en.name,
                        i18n: langValue.i18n,
                    })
                }
            }
            setLangs(languageOptions)
        }
    }, [ settings ])

    return (
        <Formik
            // initialValues={Object.keys(organisationContext.settings).reduce((values, settingKey) => {
            //     switch (settingKey) {
            //         case 'language':
            //         case 'timezone':
            //         case 'date_format':
            //             values[settingKey] = organisationContext.settings[settingKey].value;
            //             break;
            //         default:
            //             break;
            //     }
            //     return values;
            // }, {language:})}
            initialValues={{
                language: organisationContext.settings.language?.value || [],
                timezone: organisationContext.settings.timezone?.value || '',
                date_format: organisationContext.settings.date_format?.value || 'YYYY-MM-DD', // Isn't used yet
                // create_new_member_online: organisationContext.settings.create_new_member_online?.value == '1', // Isn't used yet
            }}
            validationSchema={object().shape(Object.keys(organisationContext.settings).reduce((validation, settingKey) => {
                switch (settingKey){
                    case 'language':
                    //case 'timezone':
                    //case 'date_format':
                        if(Array.isArray(organisationContext.settings[settingKey].value)){
                            validation[settingKey] = array().min(1, <Translate id='form.required' />);
                        }else{
                            validation[settingKey] = string().required(<Translate id='form.required' />);
                        }
                        break;
                    // case 'create_new_member_online':
                    //     validation[settingKey] = bool();
                    //     break;
                    default:
                        break;
                }
                return validation;
            }, {}))}
            onSubmit={(values, { setSubmitting }) => {
                organisationContext.updateSettings(organisationContext.organisation_id, values)
                    .then(toggleEditMode)
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setSubmitting(false);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <Row>
                            <Col xs="12" sm="3" md="3" lg="3">
                                <div className="text-muted">
                                    <DisplayI18n
                                        field='name'
                                        i18n={settings?.timezone?.i18n}
                                        defaultValue={settings?.timezone?.name}
                                    />:
                                </div>
                                <div className="font-medium">
                                    {organisationContext.settings.timezone.value}
                                </div>
                            </Col>
                            <Col xs="12" sm="3" md="3" lg="3">
                                <div className="text-muted">
                                    <DisplayI18n
                                        field='name'
                                        i18n={settings?.date_format?.i18n}
                                        defaultValue={settings?.date_format?.name}
                                    />:
                                </div>
                                <div className="font-medium">
                                    <Translate id='form.fields.date.helper' />
                                </div>
                            </Col>
                            <Col xs="12" sm="3" md="3" lg="3">
                                <FormGroup>
                                    <div className="text-muted">
                                        <DisplayI18n
                                            field='name'
                                            i18n={settings?.language?.i18n}
                                            defaultValue={settings?.language?.name}
                                        />:
                                    </div>
                                    <div className="font-medium">
                                        <FormikSelect
                                            name='language' id='OrganisationSettingsLanguagesSelect'
                                            multi search={false}
                                            renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                            options={langs}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                            {/* <Col xs="12" sm="3" md="3" lg="3">
                                <FormGroup>
                                    <div className="text-muted">
                                        <DisplayI18n
                                            field='name'
                                            i18n={settings?.create_new_member_online?.i18n}
                                            defaultValue={settings?.create_new_member_online?.name}
                                        />:
                                    </div>
                                    <div className="font-medium">
                                        <FormikSwitch name='create_new_member_online' id='create_new_member_online' />
                                    </div>
                                </FormGroup>
                            </Col> */}
                        </Row>
                        <div className='d-flex justify-content-end'>
                            <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                                <Translate id='misc.save' />
                            </Button>
                            <Button color='primary' type='button' outline className='ml-2' onClick={toggleEditMode} disabled={formik.isSubmitting}>
                                <Translate id='misc.cancel' />
                            </Button>
                        </div>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default ReginalSettingsEdit;