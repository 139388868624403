import Translate from '@spordle/intl-elements';
import { useEffect, useContext } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Alert, Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CollapsibleCard from '../../../../components/collapsibleCard/CollapsibleCard';
import CustomAlert from '../../../../components/CustomAlert';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import MemberCard from './MemberCard';
import { MembersContext } from '../../../../contexts/MembersContext';
import Skeleton from "react-loading-skeleton";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { getLocation } from "../../../teams/TeamHelpers";

const AllMembers = ({ validMembers, unconfirmedMemberAddresses, setUnconfirmedMemberAddresses, invalidMembers, views, setView, toggle, action, memberStatus, canConfirmAddress, ...props }) => {
    const unconfirmedAddresses = validMembers.filter(({ member }) => member.with_unconfirmed_address == 1);
    const { getMemberAddresses } = useContext(MembersContext);

    useEffect(() => {
        const allUnconfirmed = {};

        Promise.all(
            unconfirmedAddresses.map(({ member }) => (
                getMemberAddresses(member.member_id)
                    .then((addresses) => {
                        return allUnconfirmed[member.member_id] = addresses.filter((a) => a.status === "UNCONFIRMED");
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            allUnconfirmed[member.member_id] = [];
                        }
                    })
            )),
        )
            .then(() => {
                setUnconfirmedMemberAddresses(allUnconfirmed);
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }
            })
    }, [])

    return (
        <>
            <ModalHeader toggle={toggle}>
                <Translate id='members.search.sidePanelMemberSearch.multi.title.bulkUpdate' /> - <Translate id='members.search.sidePanelMemberSearch.multi.title.confirmMembers' />
            </ModalHeader>
            <ModalBody>
                <div className='mb-3'>
                    <Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.topMessage' />
                </div>
                {(unconfirmedAddresses.length > 0 && canConfirmAddress) &&
                    <Alert className="d-flex align-items-center bg-light-danger border-danger" color="danger">
                        <i className="mdi text-danger mdi-map-marker-radius fa-3x mr-3" />
                        <span className="text-danger">
                            <div className="font-bold mb-1 text-danger"><Translate id="members.search.sidePanelMemberSearch.multi.validMembersWithUnconfirmed.title" values={{ count: unconfirmedAddresses.length }} /></div>
                            <Translate id="members.search.sidePanelMemberSearch.multi.validMembersWithUnconfirmed.text" values={{ count: unconfirmedAddresses.length }} />
                        </span>
                    </Alert>
                }
                <CollapsibleCard
                    initOpen={validMembers.length <= 5}
                    title={<Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validTitle' values={{ members: validMembers.length }} />}
                    subtitle={<Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validText' />}
                >
                    <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }} className='px-3 mx-n3'>
                        {validMembers.length ?
                            validMembers.map(({ member }) => (
                                <MemberCard
                                    member={member}
                                    withStatus
                                    key={member.member_id}
                                    memberStatus={memberStatus}
                                    extraInfo={
                                        member.with_unconfirmed_address == 1 ?
                                            <MemberAddresses addresses={unconfirmedMemberAddresses[member.member_id]} member={member} />
                                            :
                                            null

                                    }
                                    canConfirmAddress={canConfirmAddress}
                                />
                            ))
                            :
                            <Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validNone' />
                        }
                    </PerfectScrollbar>
                </CollapsibleCard>

                {!!invalidMembers.length &&
                    <CollapsibleCard
                        error
                        title={<Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.invalidTitle' values={{ members: invalidMembers.length }} />}
                        subtitle={<Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.invalidText' />}
                        className='mt-3'
                    >
                        <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }} className='px-3 mx-n3'>
                            {invalidMembers.map(({ member }) => (
                                <MemberCard
                                    member={member}
                                    withStatus
                                    key={member.member_id}
                                />
                            ))}
                        </PerfectScrollbar>
                    </CollapsibleCard>
                }

                {!validMembers.length &&
                    <CustomAlert
                        color='danger'
                        className='mt-3 mb-0'
                        text='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.actionNone'
                        translateText
                    />
                }
            </ModalBody>
            <ModalFooter>
                <Button color='primary' type='button' onClick={() => setView(views.validMembers)} disabled={!validMembers.length}><Translate id='misc.confirm' /></Button>
                <Button color='primary' type='button' outline onClick={toggle} className='ml-2'><Translate id='misc.cancel' /></Button>
            </ModalFooter>
        </>
    )
}

const MemberAddresses = ({ addresses }) => {
    const isLoading = !addresses;

    return (
        <UserDisplay.Subtitle>
            {isLoading ?
                <Skeleton style={{ lineheight: 0 }} height={10} count={1} width="100%" />
                :
                addresses.map((address) => (
                    <div key={address.member_address_id}>
                        <i className="mdi mdi-map-marker-radius text-danger mr-1" /> {getLocation(address)}
                    </div>
                ))
            }
        </UserDisplay.Subtitle>
    )
}

export default AllMembers