export default{
    "travelPermit.title"      : "Permis de voyage",
    "travelPermit.modalTitle" : "Ajouter un permis de voyage",

    //Global
    "travelPermit.noTeam" : "Aucune équipe sur le permis de voyage",
    "travelPermit.fee"    : "Frais de permis de voyage",
    "travelPermit.print"  : "Imprimer (PDF)",

    // Columns
    "travelPermit.column.team"      : "Équipe",
    "travelPermit.column.event"     : "Événement",
    "travelPermit.column.location"  : "Emplacement",
    "travelPermit.column.startDate" : "Date de début",
    "travelPermit.column.endDate"   : "Date de fin",
    "travelPermit.column.status"    : "Statut",

    // Add Modal Step 1
    "travelPermit.addModal.teamLabel"           : "Équipe",
    "travelPermit.addModal.travelId"            : "Numéro de réference",
    "travelPermit.addModal.titleLabel"          : "Nom de l'événement",
    "travelPermit.addModal.titleLabel.tip"      : "Veuillez entrer le nom de l'événement ou une description de celui-ci",
    "travelPermit.addModal.eventTypeLabel"      : "Type d'événement",
    "travelPermit.addModal.eventTypeLabel.home" : "Local",
    "travelPermit.addModal.eventTypeLabel.away" : "Visiteur",
    "travelPermit.addModal.travelIdLabel"       : "Type de déplacement",
    "travelPermit.addModal.hostWebsiteLabel"    : "Site Web hôte",
    "travelPermit.addModal.hostNameLabel"       : "Nom de l'équipe hôte",
    "travelPermit.addModal.nbGamesLabel"        : "Nombre de parties",
    "travelPermit.addModal.hostDivision"        : "Division hôte",
    "travelPermit.addModal.hostClass"           : "Classe d'accueil",
    "travelPermit.addModal.hostCategory"        : "Catégorie hôte",
    "travelPermit.addModal.hostTeamCategory"    : "Catégorie d'équipe hôte",
    "travelPermit.addModal.hostCountry"         : "Pays d'accueil",
    "travelPermit.addModal.hostCity"            : "Ville hôte",
    "travelPermit.addModal.hostOrganization"    : "Organisme d'accueil",
    "travelPermit.addModal.hostArena"           : "Arena hôte",
    "travelPermit.addModal.hostProvinceState"   : "Province / État d'accueil",

    // Modal step 1 validation
    "travelPermit.addModal.teamRequired"         : "L'équipe est requise.",
    "travelPermit.addModal.titleRequired"        : "Titre est requis.",
    "travelPermit.addModal.eventTypeRequired"    : "Type d'événement est requis.",
    "travelPermit.addModal.travelIdRequired"     : "L'identifiant de voyage est requis.",
    "travelPermit.addModal.hostNameRequired"     : "L'arène hôte est requise.",
    "travelPermit.addModal.hostCountryRequired"  : "Le pays hôte est requis.",
    "travelPermit.addModal.hostProvinceRequired" : "La province hôte est requise.",
    "travelPermit.addModal.hostCityRequired"     : "La ville hôte est requise.",
    "travelPermit.addModal.hostDivisionRequired" : "La division hôte est requise.",
    "travelPermit.addModal.hostCategoryRequired" : "La catégorie d'équipe hôte est requise.",

    // Modal step 2
    "travelPermit.addModal.startDate"                 : "Date de début",
    "travelPermit.addModal.endDate"                   : "Date de fin",
    "travelPermit.addModal.notes"                     : "Remarques",
    "travelPermit.addModal.attachments"               : "Pièces jointes",
    "travelPermit.addModal.additionalAttachments"     : "Pièces jointes additionnelles",
    "travelPermit.addModal.startDateRequired"         : "La date de début est requise.",
    "travelPermit.addModal.endDateRequired"           : "La date de fin est requise.",
    "travelPermit.addModal.category.notListed"        : "Option non listée",
    "travelPermit.addModal.category.notListed.helper" : "La catégorie que je recherche n'est pas dans la liste.",

    "travelPermit.lineup.title"                : "Alignement",
    "travelPermit.lineup.editLineup"           : "Modifier l'alignement",
    "travelPermit.lineup.teamMembers"          : "Membres d'équipe",
    "travelPermit.lineup.availableTeamMembers" : "Membres disponibles",
    "travelPermit.lineup.addAll"               : "Ajouter tous les membres",
    "travelPermit.lineup.removeAll"            : "Retirer les membres",
    "travelPermit.lineup.searchMembers"        : "Recherche de membre",
    "travelPermit.lineup.emptyTeam"            : "Aucun membre disponible dans l'équipe",
    "travelPermit.lineup.emptyLineup"          : "Aucun membre dans l'alignement.",
    "travelPermit.lineup.helper"               : "Veuillez spécifier quels membres participeront.",
    "travelPermit.lineup.minimum.validation"   : "Veuillez ajouter au moins un membre.",
    "travelPermit.lineup.position.validation"  : "Veuillez sélectionner la position du membre.",

    // Sidepanel Header
    "travelPermit.panelHeader.travelId" : "Id de déplacement",

    // Sidepanel General
    "travelPermit.sidePanelGeneral.tabTitle"        : "Général",
    "travelPermit.sidePanelGeneral.TeamNumber"      : "Numéro d'équipe",
    "travelPermit.sidePanelGeneral.status"          : "Statut",
    "travelPermit.sidePanelGeneral.location"        : "Emplacement",
    "travelPermit.sidePanelGeneral.arena"           : "Aréna",
    "travelPermit.sidePanelGeneral.city"            : "Ville",
    "travelPermit.sidePanelGeneral.countryProvince" : "Pays et Province/État",
    "travelPermit.sidePanelGeneral.dates"           : "Dates",
    "travelPermit.sidePanelGeneral.startDate"       : "Date de début",
    "travelPermit.sidePanelGeneral.endDate"         : "Date de fin",
    "travelPermit.sidePanelGeneral.note"            : "Note",
    "travelPermit.sidePanelGeneral.noOptions"       : "Aucune option",
    "travelPermit.sidePanelGeneral.attachments"     : "Pièces jointes",

    // Sidepanel Host
    "travelPermit.sidePanelHost.tabTitle"     : "Hôte",
    "travelPermit.sidePanelHost.travel_id"    : "Numéro de réference",
    "travelPermit.sidePanelHost.name"         : "Nom de l'événement",
    "travelPermit.sidePanelHost.eventType"    : "Type d'événement",
    "travelPermit.sidePanelHost.division"     : "Division",
    "travelPermit.sidePanelHost.category"     : "Catégorie",
    "travelPermit.sidePanelHost.class"        : "Classe",
    "travelPermit.sidePanelHost.website"      : "Site Internet",
    "travelPermit.sidePanelHost.hostTeamName" : "Nom de l'équipe hôte",
    "travelPermit.sidePanelHost.nbGames"      : "Nombre de parties",

    // Toasts
    "travelPermit.toast.success" : "Permis de voyage mis à jour.",
    "travelPermit.toast.fail"    : "Une erreur s'est produite lors du permis de voyage mis à jour, veuillez actualiser la page et essayez à nouveau.",

    // delete modal
    "travelPermit.deleteModal.message" : "Vous êtes sur le point de supprimer ce permis de voyage.",
    "travelPermit.approve.message"     : "Vous êtes sur le point d'approuver ce permis de voyage.",
    "travelPermit.approve"             : "Approuver",
    "travelPermit.decline.message"     : "Vous êtes sur le point de refuser ce permis de voyage.",
    "travelPermit.decline"             : "Refuser",
    "travelPermit.submit.message"      : "Vous êtes sur le point de soumettre ce permis de voyage.",
    "travelPermit.submit"              : "Soumettre",

    // step 3
    "travelPermit.addModal.submit"             : "Soumettre le permis",
    "travelPermit.addModal.submit.subtitle"    : "Ce permis sera disponible pour l'approbation",
    "travelPermit.addModal.notSubmit"          : "Soumettre plus tard",
    "travelPermit.addModal.notSubmit.subtitle" : "Vous pouvez apporter des changements et soumettre le permis plus tard",
}