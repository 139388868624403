import { useEffect, useState } from 'react';
import {
    Container
} from "reactstrap";
import Steps from '../../../../components/steps/Steps';
import ViewHeaderV2 from '../../../../components/viewHeader/ViewHeaderV2';
import TeamGeneralInfoEdit from '../../profile/TeamInfo/components/TeamGeneralInfo/TeamGeneralInfoEdit';
import TeamContactEdit from '../../profile/TeamInfo/components/TeamContact/TeamContactEdit';
import TeamCreationReview from './TeamCreationReview';
import Translate from '@spordle/intl-elements';

const TeamCreation = (props) => {
    const [ teamId, setTeamId ] = useState('');
    const [ activeStep, setActiveStep ] = useState(0); // saves the index of the active step
    const previousStep = () => {
        if(activeStep > 0){
            setActiveStep(activeStep - 1);
        }
    }
    const nextStep = () => {
        if(activeStep < 2){
            setActiveStep(activeStep + 1);
        }
    }
    const [ steps, setSteps ] = useState([
        {
            name: <Translate id='teams.teamSearch.addTeam.wizard.step1' />,
            isAccessible: false,
        },
        // {
        //     name: 'Address and phone number',
        //     isAccessible: false,
        // },
        {
            name: <Translate id='teams.teamSearch.addTeam.wizard.step2' />,
            isAccessible: false,
        },
        {
            name: <Translate id='teams.teamSearch.addTeam.wizard.step3' />,
            isAccessible: false,
        },
    ])

    useEffect(() => {
        if(!steps[activeStep].isAccessible){
            setSteps((prev) => {
                prev[activeStep].isAccessible = true;
                return prev
            });
        }

        /* Sentry.addBreadcrumb({
            type: 'info',
            message: 'Active step changed',
            level: Sentry.Severity.Log,
            category: 'OnlineStore',
            data: {
                activeStep: activeStep,
                onlineStoreId: onlineStoreId
            }
        }); */
    }, [ activeStep ])

    return (
        <>
            {/*--------------------------------------------------------------------------------*/}
            {/* View Header                                                                  */}
            {/*--------------------------------------------------------------------------------*/}
            <ViewHeaderV2
                title='teams.teamSearch.addTeam.title'
                breadcrumbsProps={{
                    disablePeriodSelect: true,
                }}
            />

            <div className="page-content px-4 pt-0">
                <Container>
                    <Steps activeStepName={steps[activeStep].name} className="mb-4">
                        {steps.map((step, key) => (
                            <Steps.Item
                                // eslint-disable-next-line react/no-array-index-key
                                key={key}
                                number={key + 1}
                                name={step.name}
                                onClick={() => setActiveStep(key)}
                                isActive={key === activeStep}
                                isAccessible={step.isAccessible}
                            />
                        ))}
                    </Steps>
                    {activeStep === 0 &&
                        <TeamGeneralInfoEdit
                            isEditing wizard
                            teamId={teamId}
                            onSubmit={(myTeamId) => {
                                setTeamId(myTeamId);
                                nextStep();
                            }}
                        />
                    }
                    {/* {activeStep === 1 &&
                        <TeamAddressEdit wizard onCancel={previousStep} onSubmit={nextStep} />
                    } */}
                    {activeStep === 1 &&
                        <TeamContactEdit wizard teamId={teamId} onCancel={previousStep} onSubmit={nextStep} />
                    }
                    {activeStep === 2 &&
                        <TeamCreationReview teamId={teamId} />
                    }
                </Container>
            </div>
        </>
    );
}

export default TeamCreation;