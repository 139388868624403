import { useSpordleTable } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { success } from "@spordle/toasts";
import { useContext, useState } from "react";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { MessagesContext } from "../../../../../contexts/MessagesContext";
import { InboxContext } from "../../inboxContext/InboxContext";
import ToolBtn from "../ToolBtn";

const ReadTool = ({ checked, updateCheckedMsgs, disabled }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const { partiallyUpdateMessage } = useContext(MessagesContext);
    const { updateDataWith, refreshTable } = useSpordleTable();
    const shouldBeUnread = checked.length > 0 && checked.every((msg) => msg.is_read == 1);
    const { setShowTools } = useContext(InboxContext);

    const markMessagesAsRead = (e) => {
        e.stopPropagation();
        setIsLoading(true);

        const newData = shouldBeUnread ? "0" : "1";

        updateDataWith(checked.map((msg) => msg.message_id), { is_read: newData });
        updateCheckedMsgs({ is_read: newData });
        setShowTools(false);

        Promise.all(
            checked.map((msg) => partiallyUpdateMessage(msg.communication_message_id, { is_read: newData })),
        )
            .then(() => {
                refreshTable();
                success({ msg: `communications.inbox.action.toast.mark${newData === "0" ? "Unread" : "Read"}` });
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <ToolBtn
            label={<Translate id={`communications.inbox.tools.mark${shouldBeUnread ? "Unread" : "Read"}`} />}
            onClick={markMessagesAsRead}
            isLoading={isLoading}
            disabled={disabled}
            icon={shouldBeUnread ? "email" : "email-open"}
        />
    );
}

export default ReadTool;