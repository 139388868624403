import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { Button, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CrossFade from "../../../../components/crossFade/CrossFade";
import CollapsibleCard from "../../../../components/collapsibleCard/CollapsibleCard";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useState, useRef, useEffect } from 'react';
import MemberCard from "./MemberCard";
import { FormikTextArea } from "@spordle/formik-elements";
import { object, string } from "yup";
import Required from "../../../../components/formik/Required";

const ValidMembers = ({ validMembers, views, setView, toggle, toggleAfterSuccess, memberStatus, ...props }) => {
    const [ hasFinished, setHasFinished ] = useState(false);

    const [ promises, setPromises ] = useState(validMembers.reduce((promisesObject, member) => {
        if(member.member.member_id)
            promisesObject[member.member.member_id] = false;
        return promisesObject
    }, {}))

    const formikRef = useRef();

    useEffect(() => {
        if(Object.keys(promises).every((key) => promises[key])){ // every promise is resolved
            setHasFinished(true);
            formikRef.current?.setStatus({ isSubmitting: false });
        }
    }, [ promises ])

    // Submit when we load this step (logic comes from team batch update that uses a comment box so the extra confirm was needed)
    // useEffect(() => formikRef.current?.handleSubmit(), [])

    return (
        <Formik
            innerRef={formikRef}
            initialStatus={{
                isSubmitting: false,
            }}
            initialValues={{
                note: '',
            }}
            validationSchema={object().shape({
                note: string().max(255, <Translate id="form.validation.string.max" values={{ count: 255 }} />).required(<Translate id='form.validation.note.required' />),
            })}
            onSubmit={(values, { setStatus }) => {
                setStatus({
                    isSubmitting: true, // not using isSubmitting directly from formik because it's set to true even when validation fails
                });
            }}
        >
            {(formik) => (
                <Form>
                    <ModalHeader toggle={hasFinished ? () => { toggle(); toggleAfterSuccess(); } : toggle}>
                        <Translate id='members.search.sidePanelMemberSearch.multi.title.bulkUpdate' /> - <Translate id='members.search.sidePanelMemberSearch.multi.title.confirmMembers' />
                    </ModalHeader>
                    <ModalBody>
                        <CollapsibleCard
                            title={<Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validTitle' values={{ members: validMembers.length }} />}
                            subtitle={<Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validText' />}
                            className='mb-3'
                            initOpen={formik.status.isSubmitting || hasFinished || validMembers.length <= 5}
                        >
                            <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }} className='px-3 mx-n3'>
                                {validMembers.length ?
                                    validMembers.map((member) => (
                                        <MemberCard
                                            member={member.member}
                                            isSubmitting={formik.status.isSubmitting}
                                            updatePromises={(memberId) => setPromises((prev) => ({ ...prev, [memberId]: true }))}
                                            key={member.member.member_id}
                                            memberStatus={memberStatus}
                                        />
                                    ))
                                    :
                                    <Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validNone' />
                                }
                            </PerfectScrollbar>
                        </CollapsibleCard>

                        <CrossFade isVisible={!formik.status.isSubmitting && !hasFinished}>
                            <FormGroup>
                                <Label className="text-muted"><Translate id='form.fields.note' /> <Required /></Label>
                                <FormikTextArea
                                    id='note' name='note'
                                    trim rows={2}
                                    helper={<div className="small text-muted"><Translate id='members.search.sidePanelMemberSearch.bulkUpdate.block.note.helper' /></div>}
                                />
                            </FormGroup>
                        </CrossFade>
                    </ModalBody>
                    <CrossFade isVisible={!hasFinished}>
                        <ModalFooter>
                            <Button color='primary' type='submit' disabled={!validMembers.length || formik.status.isSubmitting}><Translate id='misc.confirm' /></Button>
                            <Button color='primary' type='button' outline onClick={toggle} className='ml-2' disabled={!validMembers.length || formik.status.isSubmitting}><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </CrossFade>
                    <CrossFade isVisible={hasFinished}>
                        <ModalFooter>
                            <Button
                                color='primary'
                                type='button'
                                disabled={formik.status.isSubmitting}
                                onClick={() => {
                                    toggle();
                                    toggleAfterSuccess();
                                }}
                            >
                                <Translate id='misc.close' />
                            </Button>
                        </ModalFooter>
                    </CrossFade>
                </Form>
            )}
        </Formik>
    )
}

export default ValidMembers