const InvalidCrc = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 13.37"
            width={width}
            height={height}
        >
            <path
                className="fill-current"
                d="M11.29,5.97c-.39-.55-.6-1.21-.66-1.87-.06-1,.39-1.96,1.23-2.53-.42-.48-.84-.96-1.29-1.42-.09-.06-.21-.06-.3,0C8.91,.94,7.23,.88,5.94,0c-.81,.57-1.8,.82-2.79,.64-.45-.13-.93-.28-1.38-.46-.12-.03-.3-.06-.36,0C.96,.64,.54,1.09,.12,1.57c.03,0,.03,.03,.06,.06,1.2,.9,1.53,2.56,.78,3.85C.48,6.21,.15,7.02,0,7.86v.61c.36,1.5,1.35,2.77,2.7,3.49,.9,.57,1.83,1.02,2.85,1.35,.15,.06,.3,.06,.48,.06s.36-.03,.54-.09c.72-.27,1.44-.6,2.13-.96,.81-.48,1.57-1.05,2.23-1.72,1.29-1.23,1.44-3.22,.36-4.63Zm-3.22,.63c-.18,.15-.27,.39-.24,.63,.06,.69,.09,1.39,.15,2.14-.6-.3-1.17-.57-1.71-.84-.18-.12-.39-.12-.57,0-.54,.3-1.11,.54-1.71,.84,.06-.69,.06-1.36,.15-1.99,.06-.33-.09-.66-.33-.87-.45-.39-.84-.82-1.32-1.3,.72-.12,1.38-.21,2.01-.3,.21,0,.42-.15,.48-.36,.3-.66,.63-1.29,.99-2.02,.36,.73,.69,1.39,1.02,2.05,.06,.18,.24,.3,.42,.33,.66,.09,1.32,.18,2.04,.3-.48,.48-.9,.94-1.38,1.39Z"
            />
        </svg>
    );
}

export default InvalidCrc;