import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import { MembersContext } from '../../../../../contexts/MembersContext';
import FormikEditable from '../../../../formik/FormikEditable';
import { success } from '@spordle/toasts';
import MemberTeamStatus from '../../../../../views/members/profile/teams/MemberTeamStatus';
import { FormGroup } from 'reactstrap';
import { FormikCheckedButton } from '@spordle/formik-elements';

const TransferTeamMemberStatus = ({ canEditTransfer, transfer, setLoading, updateData, handleError, transferLoading }) => {
    const memberContext = useContext(MembersContext);
    return (
        <FormikEditable
            id='editTransferTeamMemberStatus'
            disabled readOnly
            initialValues={{
                target_affiliate: transfer.target_affiliate == 1 || false,
                target_tryout: transfer.target_tryout == 1 || false,
                target_import: transfer.target_import == 1 || false,
            }}
            // validationSchema={}
            onSubmit={({ target_affiliate, target_tryout, target_import }) => {
                setLoading(true);
                return memberContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                    target_affiliate: target_affiliate,
                    target_tryout: target_tryout,
                    target_import: target_import,
                })
                    .then(async() => {
                        await updateData({ // Updating data without having to make the api call
                            ...transfer,
                            target_affiliate: target_affiliate,
                            target_tryout: target_tryout,
                            target_import: target_import,
                        }, false);
                    })
                    .then(success)
                    .catch(handleError)
                    .finally(() => setLoading(false))

            }}
        >
            {(isEditing, options) => {
                if(!isEditing){
                    return (
                        <div className='py-1 pr-1'>
                            {transfer.target_affiliate == 1 &&
                                <MemberTeamStatus
                                    className="teamRoster-status mr-2"
                                    tip="teams.profile.roster.table.affiliated"
                                >
                                    <Translate id='teams.profile.roster.table.affiliated.short' />
                                </MemberTeamStatus>
                            }
                            {transfer.target_tryout == 1 &&
                                <MemberTeamStatus
                                    className="teamRoster-status mr-2"
                                    tip="teams.profile.roster.table.tryout"
                                >
                                    <Translate id='teams.profile.roster.table.tryout.short' />
                                </MemberTeamStatus>
                            }
                            {transfer.target_import == 1 &&
                                <MemberTeamStatus
                                    className="teamRoster-status mr-2"
                                    tip="teams.profile.roster.table.imported"
                                >
                                    <Translate id='teams.profile.roster.table.imported.short' />
                                </MemberTeamStatus>
                            }
                        </div>
                    )
                }
                return (
                    <div className='d-flex pt-1 pr-1'>
                        <FormGroup>
                            <div className='pr-3'>
                                <FormikCheckedButton
                                    id='target_affiliate'
                                    name='target_affiliate'
                                    label='transfer.targetAffiliate'
                                    translateLabel

                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className='pr-3'>
                                <FormikCheckedButton
                                    id='target_tryout'
                                    name='target_tryout'
                                    label='transfer.targetTryout'
                                    translateLabel
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div>
                                <FormikCheckedButton
                                    id='target_import'
                                    name='target_import'
                                    label='transfer.targetImport'
                                    translateLabel
                                />
                            </div>
                        </FormGroup>
                    </div>
                )

            }}
        </FormikEditable>
    )
}

export default TransferTeamMemberStatus
