import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import moment from 'moment';
import { DisplayI18n } from '../../../../helpers/i18nHelper';

const WorkflowStart = ({ currentOrg, requestedDate, isLastAction, identity }) => {
    return (
        <div className="d-table-row">
            <div className="d-table-cell py-2 requestBar-container">
                <div className={stringBuilder('requestBar requestBar-top requestBar-top-active', { 'requestBar-bottom-active': isLastAction })}>
                    <div className="requestBar-dot" />
                </div>
            </div>
            <div className="d-table-cell py-2">
                <div className="font-medium text-dark"><Translate id='transfer.newOrg' /></div>
                <div className="text-muted">
                    <DisplayI18n
                        field='name'
                        defaultValue={currentOrg.organisation_name}
                        i18n={currentOrg.i18n}
                    />
                    {identity &&
                        <>
                            <span className='mx-1'>-</span>
                            <>{identity.name} {identity.family_name}</>
                        </>
                    }
                    <span className='mx-1'>-</span>
                    {moment(requestedDate).format('lll')}
                </div>
            </div>
        </div>
    );
}

export default WorkflowStart;