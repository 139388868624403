import { Tooltip } from "@mantine/core";
import { useFormikContext } from "formik";
import React from "react";
import { Label } from "reactstrap";
import Required from "../../../../components/formik/Required";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { filterHasValue, formikCodesHaveValues } from "../reportsEngineHelpers";

const FilterLabel = ({ filter, skipTip = false, suffix, skipWrapper }) => {
    const formik = useFormikContext()
    const Wrapper = skipWrapper ? React.Fragment : ({ children }) => <Label for={filter.code}>{children}</Label>;
    const tooltipInfo = filter.parsedParams.infoTip || false;

    return (
        <Wrapper>
            <DisplayI18n field="name" defaultValue={filter.name} i18n={filter.i18n} />
            {suffix}
            {(filter.required == 1 || (filter.parsedParams?.requiredWhen && formikCodesHaveValues(filter.parsedParams?.requiredWhen, formik)) || (filter.parsedParams?.requiredWhenValue && filterHasValue(filter.parsedParams?.requiredWhenValue?.filter, filter.parsedParams?.requiredWhenValue?.value, formik))) &&
                <Required />
            }
            {tooltipInfo && !skipTip && <TooltipInfo tip={tooltipInfo} />}
        </Wrapper>
    );
}

const TooltipInfo = ({ tip }) => {
    return (
        <Tooltip wrapLines withArrow width={200} position="top" label={<DisplayI18n field="name" defaultValue={tip.name} i18n={tip.i18n} />}>
            <i className="ml-2 mdi mdi-information-outline text-primary" />
        </Tooltip>
    )
}

export default FilterLabel;