export default{
    "teams.profile.title" : "Team profile",

    "teams.profile.label.organization"               : "Organization",
    "teams.profile.label.tournament"                 : "Tournament",
    "teams.profile.label.tournament.enter"           : "I want to enter this team in a tournament",
    "teams.profile.label.league"                     : "League",
    "teams.profile.label.league.enter"               : "I want to enter this team in a league",
    "teams.profile.participate.competition.required" : "Please specify the competition this team will participate in.",
    "teams.profile.participate.success"              : "The request for this team to participate in the competition has been successfully submitted !",
    "teams.profile.participate"                      : "Participate in League/Tournament",
    "teams.profile.participate.league"               : "Participate in League",
    "teams.profile.participate.tournament"           : "Participate in Tournament",

    "teams.profile.notFound.title"       : "Team not found",
    "teams.profile.notFound.description" : "The team you are looking for was not found, it may have been moved or removed.",
    "teams.profile.notFound.btn"         : "Go back",

    "teams.profile.header.organization"            : "Organization",
    "teams.profile.header.participate"             : "Participate in League/Tournament",
    "teams.profile.header.league"                  : "League",
    "teams.profile.header.category"                : "Category",
    "teams.profile.header.status"                  : "Status",
    "teams.profile.header.status.PENDING"          : "Pending",
    "teams.profile.header.status.ACTIVE"           : "Active",
    "teams.profile.header.back"                    : "Back to team list",
    "teams.profile.header.verifyRules"             : "Verify Rules",
    "teams.profile.header.verifyRules.hasWarnings" : "This team has warning(s)",

    "teams.profile.header.signWaivers.title"            : "Confirm all missing waivers",
    "teams.profile.header.signWaivers.subtitle"         : "The bulk confirmation action will mark all waivers for all rostered participants as \"accepted\" (yes, I agree, etc). If waivers for rostered participants are in exception to this, and were not accepted (no, I disagree, etc.), then first use the individual waiver management function for the exceptions. Then return to this screen to bulk confirm for remaining participants.",
    "teams.profile.header.signWaivers.text"             : "Select an agreement type to accept all critical waivers for your team members.",
    "teams.profile.header.signWaivers.noMissingWaivers" : "There are no waivers to sign for your team.",
    "teams.profile.header.signWaivers.hasWaivers"       : "Members with waivers to sign",
    "teams.profile.header.signWaivers.hasNoWaivers"     : "Members with no waivers to sign",
    "teams.profile.header.signWaivers.waivers"          : "{amount, plural, one {# waiver to sign} other {# waivers to sign}}",
    "teams.profile.header.signWaivers.sign"             : "Sign all waivers",
    "teams.profile.header.signWaivers.confirmation"     : "All waivers are signed!",

    "teams.profile.header.cards.players"                         : "Players",
    "teams.profile.header.cards.bench_staff"                     : "Bench staff",
    "teams.profile.header.cards.columns.MALE"                    : "M",
    "teams.profile.header.cards.columns.NON_BINARY"              : "N",
    "teams.profile.header.cards.columns.FEMALE"                  : "F",
    "teams.profile.header.cards.columns.PREFER_NOT_TO_SAY"       : "PNS",
    "teams.profile.header.cards.columns.PREFER_TO_SELF_DESCRIBE" : "PSD",
    "teams.profile.header.cards.columns.TOTAL"                   : "Total",
    "teams.profile.header.cards.rows.affiliate"                  : "Affiliate",
    "teams.profile.header.cards.rows.import"                     : "Import",
    "teams.profile.header.cards.rows.injured_ineligible"         : "Injured/Ineligible",
    "teams.profile.header.cards.rows.overage"                    : "Overage",
    "teams.profile.header.cards.rows.regular"                    : "Regular",
    "teams.profile.header.cards.rows.released"                   : "Released",
    "teams.profile.header.cards.rows.tryout"                     : "Tryout",
    "teams.profile.header.cards.rows.underage"                   : "Underage",
    "teams.profile.header.cards.rows.pending_deficient"          : "Pending - Deficient",
    "teams.profile.header.cards.showMore"                        : "Show more",
    "teams.profile.header.cards.showLess"                        : "Show less",

    "teams.profile.header.cards.total"             : "Total",
    "teams.profile.header.cards.male"              : "{itemCount, plural, =0 {# Male} one {# Male} other {# Males}}",
    "teams.profile.header.cards.female"            : "{itemCount, plural, =0 {# Female} one {# Female} other {# Females}}",
    "teams.profile.header.cards.preferNotToSay"    : "{itemCount, plural, =0 {# X} one {# X} other {# X}}",
    "teams.profile.header.cards.benchStaff"        : "{itemCount, plural, =0 {# Bench Staff} one {# Bench Staff} other {# Bench Staff}}",
    "teams.profile.header.cards.regular"           : "Regular",
    "teams.profile.header.cards.affiliated"        : "Affiliated",
    "teams.profile.header.cards.released"          : "Released",
    "teams.profile.header.cards.imported"          : "Imported",
    "teams.profile.header.cards.tryout"            : "Tryout",
    "teams.profile.header.cards.pending_deficient" : "Pending - Deficient",

    "teams.profile.header.approbation.title"                             : "Team approval",
    "teams.profile.header.approbation.error.team_member_not_found"       : "Team doesn't have team members",
    "teams.profile.header.approbation.reasons.crc_is_expired"            : "Background check is non existant or expired",
    "teams.profile.header.approbation.reasons.invalid_background_check"  : "Background check is non existant or expired",
    "teams.profile.header.approbation.reasons.has_outstanding_balance"   : "Outstanding balance",
    "teams.profile.header.approbation.reasons.incomplete_profile"        : "Incomplete profile",
    "teams.profile.header.approbation.reasons.member_status"             : "Invalid status",
    "teams.profile.header.approbation.reasons.missing_required_fields"   : "Required fields are missing",
    "teams.profile.header.approbation.reasons.unsigned_critical_waivers" : "Critical waiver needs to be signed",
    "teams.profile.header.approbation.bypass.title"                      : "Bypass and {action}",
    "teams.profile.header.approbation.bypass.text"                       : "Skip the status validations",

    "teams.profile.header.approbation.text.approved" : "You are about to approve the following team",
    "teams.profile.header.approbation.text.rejected" : "You are about to reject the following team",
    "teams.profile.header.approbation.text.pending"  : "You are about to submit the following team for approval",
    "teams.profile.header.approbation.text.cancel"   : "You are about to cancel the following team's approval",
    "teams.profile.header.approbation.text.draft"    : "You are about to cancel the following team's approval",

    "teams.profile.header.approbation.comment"          : "Comment",
    "teams.profile.header.approbation.comment.required" : "Please enter a comment",

    "teams.profile.header.approbation.confirm.approved" : "Approve",
    "teams.profile.header.approbation.confirm.rejected" : "Reject",
    "teams.profile.header.approbation.confirm.pending"  : "Submit for approval",
    "teams.profile.header.approbation.confirm.cancel"   : "Cancel approval",
    "teams.profile.header.approbation.confirm.draft"    : "Cancel approval",

    "teams.profile.roster.button.export.csv"          : "Team Book (CSV)",
    "teams.profile.roster.button.export.pdf"          : "Team Book (PDF)",
    "teams.profile.roster.button.export.pdf.disabled" : "Team book exporting is disabled for empty rosters.",
    "teams.profile.roster.tab.title"                  : "Team roster",
    "teams.profile.roster.lockdate"                   : "Lock Date",
    "teams.profile.roster.locked"                     : "The roster is currently locked",
    "teams.profile.roster.lock.bypass"                : "Permission to bypas locked roster",

    "teams.profile.roster.table.age.OVERAGE"  : "Overage",
    "teams.profile.roster.table.age.UNDERAGE" : "Underage",

    "teams.profile.roster.table.filters.title"        : "Filters {filterCount, plural, =0 {} one {(# active filter)} other {(# active filters)}}",
    "teams.profile.roster.table.activeRoster.title"   : "Active Roster",
    "teams.profile.roster.table.activeRoster.empty"   : "No active players",
    "teams.profile.roster.table.affiliated.title"     : "Affiliated Players",
    "teams.profile.roster.table.affiliated.empty"     : "No affiliated players",
    "teams.profile.roster.table.benchStaff.title"     : "Team Officials",
    "teams.profile.roster.table.benchStaff.empty"     : "No team officials",
    "teams.profile.roster.table.inactiveRoster.title" : "Released Participants",
    "teams.profile.roster.table.inactiveRoster.empty" : "No released participants",

    "teams.profile.roster.table.addedDate"         : "Added date",
    "teams.profile.roster.table.releaseDate"       : "Release date",
    "teams.profile.roster.table.releaseTime"       : "Release time",
    "teams.profile.roster.table.position"          : "Position",
    "teams.profile.roster.table.position.required" : "Position is required",
    "teams.profile.roster.table.jerseyNumber"      : "Jersey #",
    "teams.profile.roster.table.jerseyNumber.home" : "Home",
    "teams.profile.roster.table.jerseyNumber.away" : "Away",
    "teams.profile.roster.table.status"            : "Roster status",
    "teams.profile.roster.table.playerStatus"      : "Player status",
    "teams.profile.roster.table.teamMemberStatus"  : "Member Status",
    "teams.profile.roster.table.tryout"            : "Tryout",
    "teams.profile.roster.table.tryout.short"      : "T",
    "teams.profile.roster.table.imported"          : "Imported",
    "teams.profile.roster.table.imported.short"    : "I",
    "teams.profile.roster.table.affiliated"        : "Affiliated",
    "teams.profile.roster.table.affiliated.short"  : "A",
    "teams.profile.roster.table.injured"           : "Injured/Ineligible",
    "teams.profile.roster.table.injured.short"     : "I",

    "teams.profile.roster.table.editRoster" : "Edit Roster",

    "teams.profile.roster.table.status.APPROVED"          : "Approved",
    "teams.profile.roster.table.status.PENDING"           : "Pending",
    "teams.profile.roster.table.status.DECLINED"          : "Rejected",
    "teams.profile.roster.table.status.INELIGIBLE"        : "Ineligible",
    "teams.profile.roster.table.status.PENDING_DEFICIENT" : "Pending - Deficient",

    "teams.profile.roster.sidePanel.tabs.general"     : "General",
    "teams.profile.roster.sidePanel.tabs.teamHistory" : "Teams",

    "teams.profile.roster.sidePanel.rosterType.ACTIVE"    : "From the Active Roster",
    "teams.profile.roster.sidePanel.rosterType.AFFILIATE" : "From the Affiliated Players",
    "teams.profile.roster.sidePanel.rosterType.STAFF"     : "From the Bench Staff",

    "teams.profile.roster.sidePanel.qualifications"           : "Qualifications",
    "teams.profile.roster.sidePanel.emptyQualifications"      : "No qualifications",
    "teams.profile.roster.sidePanel.attending"                : "Attending",
    "teams.profile.roster.sidePanel.qualification.expiration" : "Expiration",
    "teams.profile.roster.sidePanel.qualification.expired"    : "Expired",
    "teams.profile.roster.sidePanel.qualification.goTo"       : "Go to clinic",

    "teams.profile.roster.sidePanel.teamHistory.memberTeams" : "Member Teams",
    "teams.profile.roster.sidePanel.teamHistory.none"        : "No teams for this member",

    "teams.profile.roster.sidePanel.releasedDate.disabled" : "The released date is protected in the team settings",
    "teams.profile.roster.sidePanel.releasedDate.missing"  : "Missing team settings",

    "teams.profile.roster.sidePanel.registrationDate.disabled" : "The registration date is protected in the team settings",
    "teams.profile.roster.sidePanel.registrationDate.missing"  : "Missing team settings",

    "teams.profile.roster.sidePanel.teamMemberComments"          : "Comments",
    "teams.profile.roster.sidePanel.teamMemberComments.none"     : "There are no comments for this member",
    "teams.profile.roster.sidePanel.teamMemberComments.add"      : "Add a comment",
    "teams.profile.roster.sidePanel.teamMemberComments.required" : "Please enter a comment",
    "teams.profile.roster.sidePanel.teamMemberComments.delete"   : "Delete Comment",

    "teams.profile.roster.sidePanel.teamComments.none"      : "There are no comments for this team",
    "teams.profile.roster.sidePanel.teamComments.deleteMsg" : "You are about to delete this comment.",
    "teams.profile.roster.sidePanel.teamComments.required"  : "Please enter a comment",

    "teams.profile.roster.sidePanel.actions.release.primary.title"            : "Change primary team",
    "teams.profile.roster.sidePanel.actions.release.primary.text"             : "This member is part of other teams. You can select one of them to set as the new primary team.",
    "teams.profile.roster.sidePanel.actions.release.primary.team"             : "Team",
    "teams.profile.roster.sidePanel.actions.release.primary.team.required"    : "Team is required",
    "teams.profile.roster.sidePanel.actions.release.primary.comment"          : "Comment",
    "teams.profile.roster.sidePanel.actions.release.primary.comment.required" : "Comment is required",
    "teams.profile.roster.sidePanel.actions.release.primary.btn.change"       : "Change",
    "teams.profile.roster.sidePanel.actions.release.primary.btn.skip"         : "Skip",

    "teams.profile.roster.sidePanel.actions.release"                   : "Release",
    "teams.profile.roster.sidePanel.actions.release.text"              : "You are about to release this player. This action is irreversible.",
    "teams.profile.roster.sidePanel.actions.setAsActive"               : "Reinstate member",
    "teams.profile.roster.sidePanel.actions.jerseyHome"                : "Jersey # (Home)",
    "teams.profile.roster.sidePanel.actions.jerseyAway"                : "Jersey # (Away)",
    "teams.profile.roster.sidePanel.actions.teamMemberStatus.player"   : "Player's status",
    "teams.profile.roster.sidePanel.actions.teamMemberStatus.staff"    : "Bench staff status",
    "teams.profile.roster.sidePanel.actions.teamMemberStatus.required" : "Status is required",
    "teams.profile.roster.sidePanel.actions.addedToTeam"               : "Added to team",
    "teams.profile.roster.sidePanel.actions.remove.activeRoster"       : "You are about to delete this player from the active roster",
    "teams.profile.roster.sidePanel.actions.remove.inactiveRoster"     : "You are about to delete this player from the inactive roster",
    "teams.profile.roster.sidePanel.actions.remove.staffRoster"        : "You are about to delete this staff member",

    "teams.profile.roster.sidePanel.jerseyNumber.error.unique"  : "Jersey number must be unique within your team.",
    "teams.profile.roster.sidePanel.jerseyNumbers.error.unique" : "Jersey numbers must be unique within your team.",

    "teams.profile.comments.title"              : "Team Comments",
    "teams.profile.comments.noComments.message" : "You can add a comment",
    "teams.profile.comments.noComments.button"  : "Add a comment",
    "teams.profile.comments.date"               : "Modification Date",
    "teams.profile.comments.sidepanel.title"    : "Comment",
    "teams.profile.comments.sidepanel.name"     : "Name",
    "teams.profile.comments.sidepanel.email"    : "Email",

    // Team profile settings errors/warnings
    "teams.profile.settings.warning.hint.title" : "Team warnings",
    "teams.profile.settings.warning.hint.msg"   : "See restrictions",
    "teams.profile.settings.warning.hint.more"  : "{warningCount, plural, one {One more warning} other {# more warnings}}",
    "teams.profile.settings.errors"             : "{errorCount, plural, one {Error} other {Errors}}",
    "teams.profile.settings.error.3371"         : "Maximum player allotment exceeded ({data})",
    "teams.profile.settings.error.3372"         : "Maximum active players exceeded ({data})",
    "teams.profile.settings.error.3373"         : "Maximum affiliated players exceeded ({data})",
    "teams.profile.settings.error.3374"         : "Maximum imported players exceeded ({data})",
    "teams.profile.settings.error.3375"         : "Maximum try out players exceeded ({data})",
    "teams.profile.settings.error.3376"         : "Cannot add or update member with a specific registration date",
    "teams.profile.settings.error.3377"         : "Cannot add member with a specific release date",
    "teams.profile.settings.error.3378"         : "Member must have a card from previous season",
    "teams.profile.settings.error.3379"         : "Member is missing a qualification ({data})",
    "teams.profile.settings.error.3380"         : "Cannot update a released member that is approved",
    "teams.profile.settings.error.3381"         : "Maximum released players exceeded ({data})",
    "teams.profile.settings.error.3408"         : "Member is missing a pre-registration ({data})",
    "teams.profile.settings.error.3423"         : "Member is missing a valid background check",
    "teams.profile.settings.error.3438"         : "Jersey numbers must be unique",
    "teams.profile.settings.error.3494"         : "The roster is locked",

    "teams.profile.settings.error.3371.raw" : "Maximum player allotment exceeded",
    "teams.profile.settings.error.3372.raw" : "Maximum active players exceeded",
    "teams.profile.settings.error.3373.raw" : "Maximum affiliated players exceeded",
    "teams.profile.settings.error.3374.raw" : "Maximum imported players exceeded",
    "teams.profile.settings.error.3375.raw" : "Maximum try out players exceeded",
    "teams.profile.settings.error.3376.raw" : "Cannot add or update member with a specific registration date",
    "teams.profile.settings.error.3377.raw" : "Cannot add member with a specific release date",
    "teams.profile.settings.error.3378.raw" : "Member must have a card from previous season",
    "teams.profile.settings.error.3379.raw" : "Member is missing a qualification",
    "teams.profile.settings.error.3380.raw" : "Cannot update a released member that is approved",
    "teams.profile.settings.error.3381.raw" : "Maximum released players exceeded",
    "teams.profile.settings.error.3408.raw" : "Member is missing a pre-registration",
    "teams.profile.settings.error.3423.raw" : "Member is missing a valid background check",
    "teams.profile.settings.error.3438.raw" : "Jersey numbers must be unique",
    "teams.profile.settings.error.3494.raw" : "The roster is locked",

    "teams.profile.settings.open.all.warning.quickview"         : "Open all members in Quick View",
    "teams.profile.settings.open.all.warning.quickview.tooltip" : "Open members that are creating warnings.",
    "teams.profile.settings.open.all.warning.number"            : "members have warnings",

    "teams.profile.actionsMenu.transfer"           : "Transfer Team Organization",
    "teams.profile.actionsMenu.transfer.title"     : "Transfer Team Organization",
    "teams.profile.actionsMenu.transfer.alert"     : "Use this functionality to move a team to a different organization.",
    "teams.profile.actionsMenu.transfer.success.1" : "This team was successfully transferred to the following organization:",
    "teams.profile.actionsMenu.transfer.success.2" : "You will now be redirected to the team search page.",

}