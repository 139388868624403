import { Tooltip } from "@mantine/core";
import { FormikCheckedButton, FormikInputNumber, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Col, Label, Row } from "reactstrap";
import { bool, number, object, string } from 'yup';
import CollapsibleCard from "../../../../../../../components/collapsibleCard/CollapsibleCard";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import CrossFade from "../../../../../../../components/crossFade/CrossFade";
import FormikEditable from "../../../../../../../components/formik/FormikEditable";
import CanDoAction from "../../../../../../../components/permissions/CanDoAction";
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { GAME_PERIODS, useGirSelectProps } from "../../../gameIncidentHelper";

const GameIncidentCard = ({ incident, handleDelete, index, updateValues, suspensions, suspensionTypes, canEdit }) => {
    const getGirProps = useGirSelectProps();

    return (
        <CollapsibleCard
            title={incident.member.first_name + ' ' + incident.member.last_name}
            subtitle={
                <>
                    {
                        !!incident.jersey_number &&
                            <span className="mr-1">
                                #{incident.jersey_number} -
                            </span>
                    }
                    <DisplayI18n
                        field="name"
                        defaultValue={incident.team.name}
                        i18n={incident.team.i18n}
                    />
                </>
            }
            extraInfo={
                index === 0 ?
                    null
                    :
                    <ConfirmModal
                        modalMsg='gameIncident.sidePanel.delete.incident'
                        translateModalMsg
                        modalTitle={incident.member.first_name + ' ' + incident.member.last_name}
                        toggler={(fn) => (
                            <CanDoAction className="p-3 m-n3 align-self-start" componentCode="organization" componentPermissionCode="game_incidents" action="ADD">
                                <Tooltip zIndex={3000} withArrow label={<Translate id={'misc.delete'} />}>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            fn();
                                        }}
                                        className="reset-btn text-danger"
                                        type="button"
                                    >
                                        <small><i className="fas fa-trash" /></small>
                                    </button>
                                </Tooltip>
                            </CanDoAction>
                        )}
                        onConfirm={handleDelete}
                    />
            }
            className="mb-3"
            initOpen={index === 0}
        >
            <UserDisplay.Container>
                <Label className="d-block mb-0 text-muted"><Translate id='gameIncident.addModal.label.infractionType' /></Label>
                <FormikEditable
                    id="suspension_type_id"
                    disabled={!canEdit}
                    noConfirmation
                    initialValues={{
                        suspension_type_id: incident.suspension_type.suspension_type_id || '',
                    }}
                    className="editable-transparent"
                    validationSchema={object().shape({
                        suspension_type_id: string(),
                    })}
                    onSubmit={(values) => {
                        if(values.suspension_type_id !== incident.suspension_type.suspension_type_id){
                            const newSuspensionType = suspensionTypes.find((s) => s.suspension_type_id === values.suspension_type_id);
                            updateValues(incident.game_incident_player_id, values, { suspension_type: newSuspensionType });
                        }
                    }}
                >
                    {(isEditing, options) => {
                        if(!isEditing){
                            return (
                                <div>
                                    <DisplayI18n
                                        field="name"
                                        i18n={incident.suspension_type.i18n}
                                        defaultValue={incident.suspension_type.name}
                                    />
                                </div>
                            )
                        }
                        return (
                            <FormikSelect
                                {...getGirProps("suspension_type_id", suspensionTypes)}
                                menuIsDefaultOpen
                                autoFocus
                                onOptionSelected={options.stopEditing}
                            />
                        )

                    }}
                </FormikEditable>
                <Label className="d-block mt-2 mb-0 text-muted"><Translate id='gameIncident.addModal.label.infraction' /></Label>
                <FormikEditable
                    id="suspension_id"
                    disabled={!canEdit}
                    noConfirmation
                    initialValues={{
                        suspension_id: incident.suspension.suspension_id || '',
                    }}
                    className="editable-transparent"
                    validationSchema={object().shape({
                        suspension_id: string(),
                    })}
                    onSubmit={(values) => {
                        if(values.suspension_id !== incident.suspension.suspension_id){
                            const newSuspension = suspensions.find((s) => s.suspension_id === values.suspension_id);
                            updateValues(incident.game_incident_player_id, values, { suspension: newSuspension });
                        }
                    }}
                >
                    {(isEditing, options) => {
                        if(!isEditing){
                            return (
                                <div>
                                    <DisplayI18n
                                        field="name"
                                        i18n={incident.suspension.i18n}
                                        defaultValue={incident.suspension.name}
                                    />
                                </div>
                            )
                        }
                        return (
                            <FormikSelect
                                {...getGirProps("suspension_id", suspensions)}
                                menuIsDefaultOpen
                                autoFocus
                                onOptionSelected={options.stopEditing}
                            />
                        )

                    }}
                </FormikEditable>
                <Label className="d-block mt-2 mb-0 text-muted"><Translate id='gameIncident.addModal.label.period' /></Label>
                <FormikEditable
                    id="description"
                    disabled={!canEdit}
                    initialValues={{
                        game_period: incident.game_period || '',
                        period_minutes: incident.period_minutes || '',
                        period_seconds: incident.period_seconds || '',
                    }}
                    className="editable-transparent"
                    validationSchema={object().shape({
                        game_period: string(),
                        period_minutes: number().max(20, <Translate id='gameIncident.addModal.validation.time.max' values={{ max: 20 }} />).min(0, <Translate id='gameIncident.addModal.validation.time.min' />),
                        period_seconds: number().max(59, <Translate id='gameIncident.addModal.validation.time.max' values={{ max: 60 }} />).min(0, <Translate id='gameIncident.addModal.validation.time.min' />),
                    })}
                    onSubmit={(values) => {
                        const apiValues = {};
                        if(values.game_period !== incident.game_period){
                            apiValues.game_period = values.game_period;
                        }

                        if(values.period_minutes !== incident.period_minutes){
                            apiValues.period_minutes = values.period_minutes;
                        }

                        if(values.period_seconds !== incident.period_seconds){
                            apiValues.period_seconds = values.period_seconds;
                        }

                        if(Object.keys(apiValues).length > 0){
                            updateValues(incident.game_incident_player_id, apiValues);
                        }
                    }}
                >
                    {(isEditing, _options) => {
                        if(!isEditing){
                            return (
                                <div>{ incident.game_period &&
                                    <span className="mr-1"><Translate id={`gameIncident.addModal.label.gamePeriod.${incident.game_period}`} /> -</span>
                                }
                                {incident.period_minutes && incident.period_minutes + 'm'} {incident.period_seconds && incident.period_seconds + 's'}
                                </div>
                            )
                        }
                        return (
                            <Row form>
                                <Col xs="6">
                                    <small><Translate id='gameIncident.addModal.label.period' /></small>
                                    <FormikSelect
                                        clearable
                                        name="game_period"
                                        id="game_period"
                                        defaultData={GAME_PERIODS.map((period) => ({
                                            label: `gameIncident.addModal.label.gamePeriod.${period}`,
                                            value: period,
                                            translateLabel: true,
                                        }))}
                                        loadingStatus='success'
                                    />
                                </Col>
                                <Col xs="3">
                                    <small><Translate id='gameIncident.addModal.label.minutes' /></small>
                                    <FormikInputNumber
                                        name="period_minutes"
                                        max={59}
                                        format='##'
                                        min={0}
                                        translatePlaceholder={false}
                                        placeholder="00"
                                    />
                                </Col>
                                <Col xs="3">
                                    <small><Translate id='gameIncident.addModal.label.seconds' /></small>
                                    <FormikInputNumber
                                        name="period_seconds"
                                        max={59}
                                        format='##'
                                        min={0}
                                        translatePlaceholder={false}
                                        placeholder="00"
                                    />
                                </Col>
                            </Row>
                        )

                    }}
                </FormikEditable>
                <Label className="d-block mt-2 mb-0 text-muted">Description</Label>
                <FormikEditable
                    id="description"
                    disabled={!canEdit}
                    initialValues={{
                        description: incident.description || '',
                    }}
                    className="editable-transparent"
                    validationSchema={object().shape({
                        description: string(),
                    })}
                    onSubmit={(values) => {
                        if(values.description !== incident.description){
                            updateValues(incident.game_incident_player_id, values);
                        }
                    }}
                >
                    {(isEditing, _options) => {
                        if(!isEditing){
                            return (
                                <div>{incident.description || '-'}</div>
                            )
                        }
                        return <FormikTextArea trim name="description" />

                    }}
                </FormikEditable>
                <Label className="d-block mt-2 mb-0 text-muted">Injury</Label>
                <FormikEditable
                    id="injured"
                    disabled={!canEdit}
                    initialValues={{
                        injured: incident.injured == "1",
                        returned_to_game: incident.returned_to_game == "1",
                    }}
                    className="editable-transparent"
                    validationSchema={object().shape({
                        injured: bool(),
                        returned_to_game: bool(),
                    })}
                    onSubmit={(values) => {
                        if((values.injured !== incident.injured == "1") || ((values.returned_to_game !== incident.returned_to_game == "1") && values.injured)){
                            const apiVal = {
                                injured: +values.injured,
                            }
                            if(values.injured){
                                apiVal.returned_to_game = +values.returned_to_game;
                            }
                            updateValues(incident.game_incident_player_id, apiVal);
                        }
                    }}
                >
                    {(isEditing, _options, formik) => {
                        if(!isEditing){
                            return (
                                <div>
                                    {
                                        incident.injured == "1" ?
                                            <>
                                                <span className='mr-1'>
                                                    <Translate id='gameIncident.addModal.label.injury' />
                                                </span>
                                                {incident.returned_to_game ?
                                                    <small>(<Translate id='gameIncident.addModal.label.returned' />)</small>
                                                    :
                                                    <small>(<Translate id='gameIncident.addModal.label.notReturned' />)</small>
                                                }
                                            </>
                                            :
                                            'No injury'
                                    }
                                </div>
                            )
                        }
                        return (
                            <div className="mt-1">
                                <FormikCheckedButton label='gameIncident.addModal.label.injury' name="injured" />
                                <CrossFade isVisible={formik.values.injured}>
                                    <FormikCheckedButton className="mt-2" label='gameIncident.addModal.label.returned' name="returned_to_game" />
                                </CrossFade>
                            </div>
                        )

                    }}
                </FormikEditable>
            </UserDisplay.Container>
        </CollapsibleCard>
    );
}

export default GameIncidentCard;