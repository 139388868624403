export default{
    "organization.settings.organizationPositions.table.empty"                : "No Positions",
    "organization.settings.organizationPositions.table.column.status"        : "Status",
    "organization.settings.organizationPositions.table.column.managed"       : "Managed",
    "organization.settings.organizationPositions.add.title"                  : "Add Position",
    "organization.settings.organizationPositions.add.status"                 : "Status",
    "organization.settings.organizationPositions.add.status.active"          : "Active",
    "organization.settings.organizationPositions.add.status.inactive"        : "Inactive",
    "organization.settings.organizationPositions.add.name.required"          : "The name is required",
    "organization.settings.organizationPositions.add.status.required"        : "The status is required",
    "organization.settings.organizationPositions.table.column.abbreviation"  : "Abbreviation",
    "organization.settings.organizationPositions.table.column.positionGroup" : "Position Group",
    "organization.settings.organizationPositions.add.abbreviation"           : "Abbreviation",
    "organization.settings.organizationPositions.add.abbreviation.max"       : "You exceeds the maximum character limit",
    "organization.settings.organizationPositions.add.managePosition"         : "Manage this position for my branch",
    "organization.settings.organizationPositions.add.group.required"         : "Position Group is required.",
}