import Translate from '@spordle/intl-elements';
import { useFormikContext } from 'formik';
import { Button, ModalBody, ModalFooter } from 'reactstrap';

const CancelItemCancelled = ({ toggle, toggleRefund, refreshTable, ...props }) => {
    const formik = useFormikContext();

    const handleRefund = () => {
        toggle();
        toggleRefund?.();
    };

    return (
        <>
            <ModalBody>
                <div>
                    <div className="h5 font-bold">
                        <Translate id="misc.success" />
                    </div>
                    <p>
                        <Translate id='memberProfile.registration.cancelRegistration.refund.message' values={{ items: formik.values.items.length }} />
                    </p>
                    <p>
                        <Translate id='memberProfile.registration.cancelRegistration.refund.question' />
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleRefund} color='primary' type='button'>
                    <Translate id='memberProfile.registration.cancelRegistration.refund' />
                </Button>
                <Button onClick={() => { refreshTable?.(); toggle(); }} color='primary' type='button' outline className="ml-2">
                    <Translate id='misc.close' />
                </Button>
            </ModalFooter>
        </>
    )
}

export default CancelItemCancelled