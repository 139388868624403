export default{
    "officialSupervision.title" : "Supervision d'officiels",

    "officialSupervision.tab.reports" : "Rapports",
    "officialSupervision.tab.coaches" : "Superviseur d'officiels",

    "officialSupervision.sendEmail"                                              : "Envoyer par courriel",
    "officialSupervision.sendEmail.help"                                         : "Envoyer le rapport maintenant par courriel",
    "officialSupervision.member.label"                                           : "Membre",
    "officialSupervision.nationalCoach.label"                                    : "Superviseur d'officiels national",
    "officialSupervision.homeTeam.label"                                         : "Équipe locale",
    "officialSupervision.awayTeam.label"                                         : "Équipe visite",
    "officialSupervision.branch.label"                                           : "Branche",
    "officialSupervision.supervisionType.label"                                  : "Type de supervision",
    "officialSupervision.supervisionDate.label"                                  : "Date de supervision",
    "officialSupervision.date.label"                                             : "Date",
    "officialSupervision.team.label"                                             : "Équipe",
    "officialSupervision.overallScore.label"                                     : "Ratio global",
    "officialSupervision.official.label"                                         : "Officiel",
    "officialSupervision.officiatingCoach.label"                                 : "Superviseur d'officiels",
    "officialSupervision.official.uniqueIdentifier.label"                        : "{unique_identifier} de l'officiel",
    "officialSupervision.officiatingCoach.uniqueIdentifier.label"                : "{unique_identifier} du superviseur d'officiels",
    "officialSupervision.supervisor.label"                                       : "Superviseur",
    "officialSupervision.supervisionReport.label"                                : "Rapport de supervision",
    "officialSupervision.season.label"                                           : "Saison",
    "officialSupervision.allReportsBtn.short"                                    : "Tous les rapports",
    "officialSupervision.allReportsBtn.long"                                     : "Voir toutes les supervisions",
    "officialSupervision.privateComment.label"                                   : "Commentaire privé",
    "officialSupervision.areaOfImprovement.label"                                : "Faiblesses",
    "officialSupervision.comments.label"                                         : "Commentaires",
    "officialSupervision.strengths.label"                                        : "Forces",
    "officialSupervision.teamCategory.label"                                     : "Catégorie d'équipe",
    "officialSupervision.teamName.label"                                         : "Nom de l'équipe",
    "officialSupervision.teamName.home.label"                                    : "Nom de l'équipe locale",
    "officialSupervision.teamName.away.label"                                    : "Nom de l'équipe visiteur",
    "officialSupervision.supervisionType.categories.label"                       : "Catégories",
    "officialSupervision.supervisedBy.label"                                     : "Supervisé par",
    "officialSupervision.officialType.label"                                     : "Type d'officiel",
    "officialSupervision.officialType.REFEREE"                                   : "Arbitre",
    "officialSupervision.officialType.LINESPERSON"                               : "Juge de lignes",
    "officialSupervision.officialType.OFFICIAL_SYSTEM"                           : "Système d'officiel",
    "officialSupervision.allMemberReports"                                       : "Toutes les supervisions sur {name}",
    "officialSupervision.allMemberReports.backToList"                            : "Retour à la liste",
    "officialSupervision.allMemberReports.noReports.title"                       : "Aucun rapport",
    "officialSupervision.allMemberReports.noReports.officiating.subtitle"        : "Vous n'avez aucun formulaire de supervision de créé.",
    "officialSupervision.allMemberReports.noReports.officiating.search.subtitle" : "Aucun formulaire de supervision n'a été trouvé avec cette recherche.",
    "officialSupervision.allMemberReports.noReports.subtitle"                    : "Il n'y a pas de rapports de supervision sur cet officiel.",
    "officialSupervision.allMemberReports.noReports.search.subtitle"             : "Aucun rapport de supervision n'a été trouvé.",

    "officialSupervision.assignPermissions.search"                  : "Chercher un officiel",
    "officialSupervision.assignPermissions.alreadyOfficiatingCoach" : "{name} est déjà un superviseur d'officiel. Vous éditez présentement ses permissions.",

    "officialSupervision.mySupervisions"       : "Mes formulaires de supervision",
    "officialSupervision.myReports"            : "Mes reports",
    "officialSupervision.sidePanel.delete.msg" : "Vous êtes sur le point de supprimer ce rapport de supervision",
    "officialSupervision.sidePanel.tab.info"   : "Info",
    "officialSupervision.sidePanel.tab.type"   : "Type",
    "officialSupervision.sidePanel.tab.notes"  : "Notes",


    "officialSupervision.addSupervision.existingTeam"          : "Équipe existante",
    "officialSupervision.addSupervision.existingTeam.required" : "Veuillez sélectionner si l'équipe est existante ou non",
    "officialSupervision.addSupervision.otherTeam"             : "Autre",
    "officialSupervision.addSupervision.branch.label"          : "Branche",
    "officialSupervision.addSupervision.org.label"             : "Organisation",

    "officialSupervision.addSupervision.teamId.required"       : "Veuillez sélectionner l'équipe",
    "officialSupervision.addSupervision.teamName.required"     : "Veuillez spécifier le nom de l'équipe",
    "officialSupervision.addSupervision.branch.required"       : "Veuillez sélectionner la branche",
    "officialSupervision.addSupervision.org.required"          : "Veuillez sélectionner l'organisation'",
    "officialSupervision.addSupervision.teamCategory.required" : "Veuillez spécifier la catégorie de l'équipe",
    "officialSupervision.addSupervision.period.required"       : "Veuillez spécifier la saison",
    "officialSupervision.addSupervision.date.required"         : "Veuillez spécifier la date",
    "officialSupervision.addSupervision.officialType.required" : "Veuillez spécifier le type d'officiel",

    "officialSupervision.addSupervision.step.findOfficial"                 : "Trouver l'officiel",
    "officialSupervision.addSupervision.seeProfile"                        : "Voir le profil",
    "officialSupervision.addSupervision.officiatingCoachSupervises"        : "{name} supervise:",
    "officialSupervision.addSupervision.willSupervise"                     : "{name} supervisera:",
    "officialSupervision.addSupervision.notOfficiating.label"              : "N'a pas les droits d'un superviseur d'officiels",
    "officialSupervision.addSupervision.identityOfficiating"               : "Veuillez identifier le superviseur d'officiels",
    "officialSupervision.addSupervision.identityOfficial"                  : "Veuillez identifier l'officiel supervisé",
    "officialSupervision.addSupervision.officialCannotSuperviseThemselves" : "Le superviseur d'officiel ne peut pas se superviser lui-même",
    "officialSupervision.addSupervision.searchMember.noResults"            : "Aucun officiel n'a été trouvé avec ces paramètres",
    "officialSupervision.addSupervision.step.findOfficiating"              : "Trouver le superviseur d'officiels",
    "officialSupervision.addSupervision.step.global"                       : "Remplir l'information globale",
    "officialSupervision.addSupervision.step.supervisionType"              : "Choisir le type de supervision",
    "officialSupervision.addSupervision.step.selectSupervisionType"        : "Choisissez le type de supervision",
    "officialSupervision.addSupervision.step.selectLevels"                 : "Sélectionner les niveaux",
    "officialSupervision.addSupervision.privateComment.helper"             : "Ne sera visible que pour les superviseurs et les administrateurs",
    "officialSupervision.addSupervision.not_listed"                        : "Non listée",
    "officialSupervision.addSupervision.notOfficial.label"                 : "Pas un officiel",
    "officialSupervision.addSupervision.league.label"                      : "Ligue",

    "officialSupervision.categories.skating"           : "Patinage",
    "officialSupervision.categories.positioning"       : "Positionnement",
    "officialSupervision.categories.procedures"        : "Procédures",
    "officialSupervision.categories.mental_skills"     : "Compétences mentales",
    "officialSupervision.categories.presence"          : "Présence",
    "officialSupervision.categories.communication"     : "Communication",
    "officialSupervision.categories.judgement"         : "Jugement",
    "officialSupervision.categories.rule_application"  : "Application des règles",
    "officialSupervision.categories.penalty_standards" : "Standard de pénalité",
    "officialSupervision.categories.choice.BEGINNING"  : "À Améliorer",
    "officialSupervision.categories.choice.DEVELOPING" : "Acceptable",
    "officialSupervision.categories.choice.MEETING"    : "Bon",
    "officialSupervision.categories.choice.EXCEEDING"  : "Excellent",

    "officialSupervision.categories.required" : "Veuillez sélectionner un choix pour {category}",
}