import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

export const RegistrationClassesContext = createContext();
RegistrationClassesContext.displayName = 'RegistrationClassesContext';

class RegistrationClassesContextProvider extends React.Component{

    /**
     * Get all the Classes within an organization
     * @param {string} organizationId The organization id we want to get the Classes from
     * @returns {Promise<Array>}
     */
    getRegistrationClasses = (organizationId, params) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `classes`, query: { organisation_id: organizationId, ...params } }))
            .then((response) => {
                if(response.data.status){
                    return response.data.classes;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a specified Class with new values
     * @param {string} classId ID of the Class to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @param {string} [lang] Language variable corresponding to the values - Used for the I18N logic
     * @param {object} [i18n] Object containing an array of all the languages available and an object of the initialValues for language variables - Used for the I18N logic
     * @returns {Promise}
     */
    updateRegistrationClass = (classId, values, lang, i18n) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                    // case 'name':
                    //     appendI18nForUpdate(params, 'name', lang, values, i18n)
                    //     break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `classes/${classId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a Class under an Organization with specified values
     * @param {string} organizationId ID of the Organization to add a Class to
     * @param {object} values Values to create a Class with
     * @param {object} [i18n] I18n object containing the languages array
     * @returns {Promise}
     */
    createRegistrationClass = (organizationId, values, i18n) => {
        const params = new URLSearchParams();

        // appendI18nForCreate(params, 'name', values, i18n)

        params.append('organisation_id', organizationId);
        params.append('active', values.active ? 1 : 0);

        for(const key in values){
            switch (key){
                default: // name, descritpion, etc
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `classes` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.class_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific Class
     * @param {string} classId ID of the Class to delete
     * @returns {Promise}
     */
    deleteRegistrationClass = (classId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `classes/${classId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <RegistrationClassesContext.Provider value={{
                getRegistrationClasses: this.getRegistrationClasses,
                updateRegistrationClass: this.updateRegistrationClass,
                createRegistrationClass: this.createRegistrationClass,
                deleteRegistrationClass: this.deleteRegistrationClass,
            }}
            >
                {this.props.children}
            </RegistrationClassesContext.Provider>
        )
    }
}

export default RegistrationClassesContextProvider