import React from "react";
import {
    Collapse
} from "reactstrap";
import Translate from "@spordle/intl-elements";

// contexts
import { OnlineStoreContext } from '../../../../../../contexts/OnlineStoreContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { PeriodsContext } from '../../../../../../contexts/contexts';
import withContexts from '../../../../../../helpers/withContexts';
import { waiverNotSigned } from "../../../../../../helpers/waiversHelper";
import CrossFade from "../../../../../../components/crossFade/CrossFade";
import WaiversModal from "../../../../../members/profile/registration/components/Sidepanel/components/waivers/WaiversModal";
import WaiverCard from "../../../../../members/profile/registration/components/Sidepanel/components/waivers/WaiverCard";

class SidePanelWaivers extends React.Component{

    state = {
        isOpen: false,
        selectedWaiver: null,
    }

    getWaiversCount = () => {
        if(this.props.invoice?.waivers && this.props.invoice?.waivers.length > 0){
            const acceptedWaivers = this.props.invoice.waivers?.reduce((sum, waiver) => {
                let _sum = sum
                if(waiver.answer !== waiverNotSigned)
                    _sum++
                return _sum
            }, 0)
            if(acceptedWaivers === 0){
                return <span className="text-danger font-medium">{acceptedWaivers} / {this.props.invoice.waivers.length}</span>
            }else if(acceptedWaivers < this.props.invoice.waivers.length){
                return <span className="text-warning font-medium">{acceptedWaivers} / {this.props.invoice.waivers.length}</span>
            }
            return <span className="text-success font-medium">{acceptedWaivers} / {this.props.invoice.waivers.length}</span>

        }
        return <span className="text-muted font-medium">-</span>
    }

    render(){
        return (
            <CrossFade isVisible={!!this.props.invoice && this.props.invoice?.waivers?.length > 0}>
                <div className='p-3 border-bottom'>
                    <div
                        className='clickable d-flex justify-content-between' onClick={() => {
                            this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
                        }}
                    >
                        <div>
                            <div className='h4 font-bold mb-1'><Translate id='clinics.profile.attendees.sidepanel.waivers' /></div>
                            {this.getWaiversCount()}
                        </div>

                        <i className={`${this.state.isOpen ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} ml-auto font-22 align-self-center`} />
                    </div>
                    <Collapse isOpen={this.state.isOpen}>
                        <div className='mt-3'>
                            <WaiversModal
                                signedBy={{ ...this.state.selectedWaiver?.identity, invoice_date: this.state.selectedWaiver?.updated_at }}
                                waiver={this.state.selectedWaiver}
                                answer={this.state.selectedWaiver?.answer}
                                invoiceNumber={this.props.invoice?.invoice_number}
                                organization={this.props.invoice?.organisation}
                                closeModal={() => {
                                    this.setState(() => ({
                                        selectedWaiver: null,
                                    }))
                                }}
                                onSubmit={() => {
                                    this.props.tableRef.refreshTable();
                                    this.props.toggle();
                                }}
                            />
                            {this.props.invoice?.waivers?.map(((waiver) => {
                                return (
                                    <WaiverCard
                                        key={waiver.waiver.waiver_id}
                                        waiver={{ ...waiver, invoiceItem: { ...waiver.invoiceItem, organisation: this.props.invoice?.organisation } }}
                                        signedBy={{ ...waiver.identity, invoice_date: waiver.updated_at }}
                                        answer={waiver.answer}
                                        openModal={() => {
                                            this.setState(() => ({
                                                selectedWaiver: {
                                                    ...waiver,
                                                    identity_id: waiver.identity.identity_id,
                                                },
                                            }))
                                        }}
                                    />
                                )
                            }))}
                        </div>
                    </Collapse>
                </div>
            </CrossFade>
        )
    }
}

export default withContexts(OnlineStoreContext, OrganizationContext, PeriodsContext)(SidePanelWaivers)