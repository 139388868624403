import HcrGuide from "../views/hcrGuide/HcrGuide";
import ChangeOrganization from "../views/organization/changeOrganization/ChangeOrganization";
import SupportRequestProfile from "../views/supportRequests/profile/SupportRequestProfile";
import SupportRequestSearch from "../views/supportRequests/search/SupportRequestSearch";
import Dashboard from '../views/dashboard/Dashboard';
import { BundledDevRoutes } from "./customRoutes";
import Communications from "../views/communications/Communications";
import OrganizationProfile from "../views/organization/profile/OrganizationProfile";
import Settings from "../views/settings/Settings";
import Catalog from '../views/catalog/Catalog';
import Reports from '../views/reports/Reports';
import Tasks from '../views/tasks/Tasks';

import catalogRoutes from './routerRoutes/catalogRoutes';
import settingsRoutes from './routerRoutes/settingsRoutes';
import tasksRoutes from './routerRoutes/tasksRoutes';
import membersRoutes from "./routerRoutes/membersRoutes";
import teamRoutes from "./routerRoutes/teamRoutes";
import clinicRoutes from "./routerRoutes/clinicRoutes";
import onlineStoreRoutes from "./routerRoutes/onlineStoreRoutes";
import newsRoutes from "./routerRoutes/newsRoutes";


// ===========================================
// All routes used as main path in FullLayout
// ===========================================
const fullLayoutRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/

    // Main built-in routes
    {
        exact: true,
        path: '/dashboard',
        name: 'sidebar.dashboard',
        component: Dashboard,
    },
    {
        path: '/organization',
        permissions: { code: 'organization' },
        component: OrganizationProfile,
    },
    {
        path: '/changeorganization',
        skipRecents: true,
        component: ChangeOrganization,
        name: 'sidebar.changeorganization',
    },
    {
        path: '/communications',
        permissions: { code: 'communications', permissionCode: 'communications_manage' },
        name: 'sidebar.communications',
        component: Communications,
    },

    // News
    ...newsRoutes,

    // Main Routes
    ...membersRoutes,
    ...teamRoutes,
    ...clinicRoutes,
    ...onlineStoreRoutes,

    // Wrapper Modules that will render their own subroutes
    {
        path: '/tasks',
        subPermissionsRoutes: tasksRoutes,
        component: Tasks,
    },
    {
        exact: false,
        path: '/catalog',
        permissions: { code: 'catalog' },
        subPermissionsRoutes: catalogRoutes,
        component: Catalog,
    },
    {
        path: '/reports',
        permissions: { code: 'reports' },
        component: Reports,
    },
    {
        path: '/settings',
        subPermissionsRoutes: settingsRoutes,
        component: Settings,
    },

    // Support Requests
    {
        path: '/supportRequest/:issueKey',
        name: 'sidebar.changeorganization',
        component: SupportRequestProfile,
    },
    {
        path: '/supportRequest',
        name: "sidebar.supportRequest",
        component: SupportRequestSearch,
    },

    // HCR Guide
    {
        path: '/hcrguide/:pageId',
        skipRecents: true,
        component: HcrGuide,
    },
    {
        redirect: true,
        path: '/hcrguide',
        pathTo: '/dashboard',
    },

    // Dev
    {
        path: '/templateui',
        skipRecents: true,
        inProduction: false,
        bundleRoute: BundledDevRoutes,
        bundleComponentKey: 'templateUi',
    },
    {
        path: '/context-tester',
        skipRecents: true,
        inProduction: false,
        bundleRoute: BundledDevRoutes,
        bundleComponentKey: 'context-tester',
    },

    { path: '/', pathTo: '/dashboard', name: 'sidebar.dashboard', redirect: true, exact: true },
];
export default fullLayoutRoutes;
