import { FormikSelect } from '@spordle/formik-elements';
import { success, fail } from '@spordle/toasts';
import { useContext } from 'react';
import useSWR from 'swr';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import FormikEditable from '../../../../components/formik/FormikEditable';
import { AffiliationFeesContext } from '../../../../contexts/AffiliationFeesContext';
import { FormsContext } from '../../../../contexts/FormsContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';

const AffiliationFeeCustomForm = ({ refreshSidepanel, canEdit, selectedRows, setLoading, updateMultipleAffilationFees, ...props }) => {
    const { getForms } = useContext(FormsContext);
    const { updateAffiliationFeePartial } = useContext(AffiliationFeesContext);
    const { organisation_id } = useContext(OrganizationContext);

    const { data: forms, isValidating } = useSWR(
        [ 'AffiliationFeeCustomForm', organisation_id ],
        () => getForms(),
    );

    return (
        <FormikEditable
            noConfirmation
            id='custom_form_id'
            disabled={!canEdit || (!!selectedRows[0]?.noCurrentParentFee && selectedRows.length <= 1)}
            readOnly={selectedRows[0]?.noCurrentParentFee && selectedRows.length <= 1}
            initialValues={{
                custom_form_id: selectedRows.length > 1 ? null : selectedRows[0].custom_form?.custom_form_id || '',
            }}
            onSubmit={(values) => {
                if(selectedRows.length > 1 && values.custom_form_id){
                    setLoading(true);
                    updateMultipleAffilationFees(values)
                }else if(values.custom_form_id != (selectedRows[0].custom_form?.custom_form_id || '')){
                    setLoading(true);
                    return updateAffiliationFeePartial(selectedRows[0].affiliation_fee_id, { custom_form_id: values.custom_form_id })
                        .then(() => {
                            refreshSidepanel();
                            success();
                        })
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                console.error(e);
                                setLoading(false);
                                fail();
                            }
                        });
                }
            }}
        >
            {(isEditing) => {
                if(!isEditing){
                    if(selectedRows.length > 1)
                        return <div className='font-medium text-dark'> - </div>
                    return (
                        <div className='font-medium text-dark'>
                            {selectedRows[0].custom_form?.name || '-'}
                        </div>
                    )
                }
                return (
                    <FormikSelect
                        name='custom_form_id'
                        id='custom_form_id'
                        clearable
                        autoFocus
                        menuIsDefaultOpen
                        options={forms.map((form) => ({
                            label: form.name,
                            value: form.custom_form_id,
                        }))}
                        isLoading={isValidating}
                    />
                )

            }}
        </FormikEditable>
    )
};

export default AffiliationFeeCustomForm;
