import { Tooltip } from "@mantine/core";
import { stringBuilder } from "@spordle/helpers";
import { useContext } from "react";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";

const IsDev = ({ tag: Tag = "span", className, style, tooltipOff, ...props }) => {
    const { isDev } = useContext(IdentityRolesContext);

    return (
        isDev() ?
            tooltipOff ?
                <Tag style={style} className={stringBuilder(className, "text-dev")} {...props} />
                :
                <Tooltip style={style} zIndex={3000} withArrow className={stringBuilder(className, "text-dev")} position="top" label="Dev Only">
                    <Tag {...props} />
                </Tooltip>
            :
            null
    );
}

export default IsDev;