import Translate, { DateFormat } from '@spordle/intl-elements';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { Badge, Fade, ListGroup, ListGroupItem, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import useSWR from 'swr';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import CrossFade from '../../../../components/crossFade/CrossFade';
import InlineCopy from '../../../../components/inlineCopy/InlineCopy';
import QuickViewActionButton from '../../../../components/quickView/QuickViewActionButton';
import QuickViewButton from '../../../../components/quickView/QuickViewButton';
import SidePanel from '../../../../components/sidePanel/SidePanel';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { MembersContext } from '../../../../contexts/MembersContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import PotentialIneligibleMembersModal from './PotentialIneligibleMembersModal';

const PotentialIneligibleMembersSidePanel = (props) => {

    const membersContext = useContext(MembersContext);
    const [ filteredPotentialMatches, setFilteredPotentialMatches ] = useState([]);

    const { data: potentialMatches, isValidating: potentialMatchesLoading } = useSWR(
        [ 'member-potential-ineligible-related-members', props.selectedRows[0].member_id ],
        () => membersContext.getMembersElasticSearch({
            first_name: props.selectedRows[0].member?.first_name,
            last_name: props.selectedRows[0].member?.last_name,
            birthdate: props.selectedRows[0].member?.birthdate,
            member_status: 'INELIGIBLE',
        })
            .then((potentialMatches) => potentialMatches.filter((match) => match.current_member_status === 'INELIGIBLE'))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                }
            }),
        {
            fallbackData: [],
        },
    )

    // the specific member causing the potential ineligible flag
    const { data: causeMember, isValidating: causeMemberLoading } = useSWR(
        [ 'member-causing-ineligible-members', props.selectedRows[0].member_id ],
        () => membersContext.getCheckMemberStatus({
            first_name: props.selectedRows[0].member?.first_name,
            last_name: props.selectedRows[0].member?.last_name,
            birthdate: props.selectedRows[0].member?.birthdate,
        })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                }
            }),
        {
            fallbackData: {},
        },
    )

    useEffect(() => {
        if(!potentialMatchesLoading && !causeMemberLoading){
            setFilteredPotentialMatches(potentialMatches?.filter((potentialMatch) => (potentialMatch.unique_identifier !== causeMember.linkedMemberInfo?.unique_identifier)))
        }
    }, [ potentialMatchesLoading, causeMemberLoading ])

    const allMembers = potentialMatches.length !== filteredPotentialMatches.length ? [ props.selectedRows[0].member, ...potentialMatches ] : [ props.selectedRows[0].member, causeMember.linkedMemberInfo, ...potentialMatches ]

    return (
        <Fade in>
            <PotentialIneligibleMembersModal
                toggle={props.isModalOpenHandlers?.toggle}
                isOpen={props.isModalOpen}
                selectedRows={props.selectedRows}
                spordleTable={props.tableRef}
                forceCloseSidePanel={props.forceCloseSidePanel}
                changeRequestStatuses={props.changeRequestStatuses}
            />
            <SidePanel.Header noBorder className='sticky-top bg-white z-index-1'>
                <div className='d-flex mb-3 align-items-center'>
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu>
                        <SidePanel.MenuAction
                            action="EDIT" componentCode="members" componentPermissionCode="manage_potential_ineligible_members"
                            onClick={props.isModalOpenHandlers?.toggle}
                        >
                            <Translate id='task.document.approval.manage.approval' />
                        </SidePanel.MenuAction>
                        <QuickViewActionButton member={allMembers} />
                    </SidePanel.ActionsMenu>
                </div>
            </SidePanel.Header>
            {(props.selectedRows.length <= 1) ?
                <>
                    <div className="d-flex align-items-center px-3 pb-3">
                        <UserImg
                            alt={props.selectedRows[0].first_name + ' ' + props.selectedRows[0].last_name}
                            abbr={props.selectedRows[0].member.first_name?.charAt(0) + props.selectedRows[0].member.last_name?.charAt(0)}
                            src={props.selectedRows[0].picture?.full_path}
                            filePos={props.selectedRows[0].picture?.file_position}
                            width={60}
                            className="mr-2"
                        />
                        <div className="w-100">
                            <div className="d-flex align-items-center text-break">
                                <span className='h4 font-bold mb-0'>
                                    <QuickViewButton member={props.selectedRows[0]}>
                                        {props.selectedRows[0].member.first_name} {props.selectedRows[0].member.last_name}
                                    </QuickViewButton>
                                </span>
                            </div>
                            <div>
                                <InlineCopy tag="span" toCopy={props.selectedRows[0].member.unique_identifier}>
                                    <Link className="text-nowrap" to={`/members/profile/${props.selectedRows[0].member_id}`}>
                                        {props.selectedRows[0].member.unique_identifier ?
                                            <>#{props.selectedRows[0].member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                            :
                                            '-'
                                        }
                                    </Link>
                                </InlineCopy>
                            </div>
                        </div>
                    </div>

                    <Nav tabs>
                        <NavItem className="text-center" style={{ width: '100%' }}>
                            <NavLink
                                className={"active"}
                            >
                                <Translate id='task.mandatoryChangesRequests.sidepanel.request' />
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={"1"}>
                        <TabPane tabId="1">
                            <Fade in>
                                <div className="p-3">
                                    <div>
                                        <div className='text-muted d-flex'>
                                            <Translate id='misc.organization' />
                                        </div>
                                        {/* hardcoded because this API call only returns pending member change requests */}
                                        <div className='text-dark'>
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={props.selectedRows[0].organisation.organisation_name}
                                                i18n={props.selectedRows[0].organisation.i18n}
                                            />
                                        </div>
                                    </div>
                                    <div className='pt-2'>
                                        <div className='text-muted d-flex'>
                                            <Translate id='task.unconfirmedOrg.sidepanel.submission.date' />
                                        </div>
                                        {/* hardcoded because this API call only returns pending member change requests */}
                                        <div className='text-dark'>
                                            {moment(props.selectedRows[0].created_at).format("YYYY-MM-DD HH:mm")}
                                        </div>
                                    </div>

                                    {/* MEMBER CAUSING INELIGIBILITY */}
                                    <div className='h5 font-bold mt-2'>
                                        <Translate id='task.ineligible.sidepanel.matching.caused.members' />
                                    </div>
                                    {causeMemberLoading ?
                                        <Skeleton className="mb-3" count={1} height={106.43} />
                                        :
                                        <UserDisplay className='w-100 mb-3' card>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    alt={causeMember.linkedMemberInfo?.first_name + ' ' + causeMember.linkedMemberInfo?.last_name}
                                                    abbr={causeMember.linkedMemberInfo ? causeMember.linkedMemberInfo?.first_name?.charAt(0) + causeMember.linkedMemberInfo?.last_name?.charAt(0) : ''}
                                                    src={causeMember.linkedMemberInfo?.picture?.full_path}
                                                    width={45}
                                                    className="mr-2"
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container className="w-100">
                                                <UserDisplay.Title className="d-flex justify-content-between">
                                                    <div className='d-flex justify-content-start'>
                                                        <QuickViewButton member={causeMember.linkedMemberInfo}>
                                                            <div>{causeMember.linkedMemberInfo?.first_name} {causeMember.linkedMemberInfo?.last_name}</div>
                                                        </QuickViewButton>
                                                    </div>
                                                </UserDisplay.Title>
                                                <UserDisplay.Subtitle>
                                                    <Link className="text-nowrap" to={`/members/profile/${causeMember.linkedMemberInfo?.member_id}`}>
                                                        {causeMember.linkedMemberInfo?.unique_identifier ?
                                                            <>#{causeMember.linkedMemberInfo?.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                            :
                                                            '-'
                                                        }
                                                    </Link>
                                                    <div className='text-muted'><Translate id='task.unconfirmedOrg.sidepanel.submission.date' /> : <span className='text-dark'><DateFormat value={causeMember.linkedMemberInfo?.created_at} /></span></div>
                                                    <div>
                                                        <Translate id='misc.status' /> :
                                                        <Badge className='ml-1' color={"danger"}><Translate id='task.ineligible.sidepanel.matching.members.status' /></Badge>
                                                    </div>
                                                </UserDisplay.Subtitle>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    }
                                    {/* POTENTIALLY MATCHING MEMBERS (elastic search call)*/}
                                    <div className='h5 font-bold'>
                                        <QuickViewButton member={filteredPotentialMatches}>
                                            <Translate id='task.ineligible.sidepanel.matching.members' />
                                        </QuickViewButton>
                                    </div>
                                    <CrossFade isVisible={filteredPotentialMatches.length === 0 && !potentialMatchesLoading}>
                                        <div><Translate id='task.ineligible.sidepanel.matching.no.potential.matches' /></div>
                                    </CrossFade>
                                    {potentialMatchesLoading ?
                                        <Skeleton className="mb-2" count={5} height={106.43} />
                                        :
                                        <ListGroup className='mb-2'>
                                            {(filteredPotentialMatches.length > 0) && filteredPotentialMatches.map((relatedMember) => (
                                                <ListGroupItem className='px-3' key={relatedMember.member_potential_ineligible_id}>
                                                    <UserDisplay>
                                                        <UserDisplay.Container>
                                                            <UserImg
                                                                alt={relatedMember.first_name + ' ' + relatedMember.last_name}
                                                                abbr={relatedMember.first_name.charAt(0) + relatedMember.last_name.charAt(0)}
                                                                src={relatedMember.picture?.full_path}
                                                                filePos={relatedMember.picture?.file_position}
                                                                width={45}
                                                                className="mr-2"
                                                            />
                                                        </UserDisplay.Container>
                                                        <UserDisplay.Container className="w-100">
                                                            <UserDisplay.Title className="d-flex justify-content-between">
                                                                <div className='d-flex justify-content-start'>
                                                                    <QuickViewButton member={relatedMember}>
                                                                        <div>{relatedMember.first_name} {relatedMember.last_name}</div>
                                                                    </QuickViewButton>
                                                                </div>
                                                            </UserDisplay.Title>
                                                            <UserDisplay.Subtitle>
                                                                <Link className="text-nowrap" to={`/members/profile/${relatedMember.member_id}`}>
                                                                    {relatedMember.unique_identifier ?
                                                                        <>#{relatedMember.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                                        :
                                                                        '-'
                                                                    }
                                                                </Link>
                                                                <div className='text-muted'><Translate id='task.unconfirmedOrg.sidepanel.submission.date' /> : <span className='text-dark'><DateFormat value={relatedMember.created_at} /></span></div>
                                                                <div>
                                                                    <Translate id='misc.status' /> :
                                                                    <Badge className='ml-1' color={"danger"}><Translate id='task.ineligible.sidepanel.matching.members.status' /></Badge>
                                                                </div>
                                                            </UserDisplay.Subtitle>
                                                        </UserDisplay.Container>
                                                    </UserDisplay>
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup>
                                    }
                                </div>
                            </Fade>
                        </TabPane>
                    </TabContent>
                </>
                :
                <>
                    <div className="d-flex align-items-center px-3 pb-3 w-100 h4 font-bold mb-0 text-break">
                        <QuickViewButton member={props.selectedRows}>
                            <Translate values={{ count: props.selectedRows.length }} id="task.mandatoryChangesRequests.sidepanel.members.number" />
                        </QuickViewButton>
                    </div>

                    <ListGroup>
                        {props.selectedRows.map((potentialIneligibleMember) => (
                            <ListGroupItem className='px-3' key={potentialIneligibleMember.member_potential_ineligible_id}>
                                <UserDisplay>
                                    <UserDisplay.Container>
                                        <UserImg
                                            alt={potentialIneligibleMember.member.first_name + ' ' + potentialIneligibleMember.member.last_name}
                                            abbr={potentialIneligibleMember.member.first_name.charAt(0) + potentialIneligibleMember.member.last_name.charAt(0)}
                                            src={potentialIneligibleMember.member.picture?.full_path}
                                            filePos={potentialIneligibleMember.member.picture?.file_position}
                                            width={45}
                                            className="mr-2"
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container className="w-100">
                                        <UserDisplay.Title className="d-flex justify-content-between">
                                            <QuickViewButton member={potentialIneligibleMember} className='d-flex justify-content-start'>
                                                <div>{potentialIneligibleMember.member.first_name} {potentialIneligibleMember.member.last_name}</div>
                                            </QuickViewButton>
                                        </UserDisplay.Title>
                                        <UserDisplay.Subtitle>
                                            <Link className="text-nowrap" to={`/members/profile/${potentialIneligibleMember.member_id}`}>
                                                {potentialIneligibleMember.member.unique_identifier ?
                                                    <>#{potentialIneligibleMember.member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                    :
                                                    '-'
                                                }
                                            </Link>
                                            <div className='text-muted'><Translate id='task.unconfirmedOrg.sidepanel.submission.date' /> : <span className='text-dark'><DateFormat value={potentialIneligibleMember.created_at} /></span></div>
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </>
            }

        </Fade>
    );
};

export default PotentialIneligibleMembersSidePanel;
