import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';

const ReginalSettingsRead = ({ settings }) => {
    const organisationContext = useContext(OrganizationContext);

    return (
        <Row>
            <Col xs="12" sm="3" md="3" lg="3">
                <div className="text-muted">
                    <DisplayI18n
                        field='name'
                        i18n={settings?.timezone?.i18n}
                        defaultValue={settings?.timezone?.name}
                    />:
                </div>
                <div className="font-medium">{organisationContext.settings.timezone.value}</div>
            </Col>
            <Col xs="12" sm="3" md="3" lg="3">
                <div className="text-muted">
                    <DisplayI18n
                        field='name'
                        i18n={settings?.date_format?.i18n}
                        defaultValue={settings?.date_format?.name}
                    />:
                </div>
                <div className="font-medium"><Translate id='form.fields.date.helper' /></div>
            </Col>
            <Col xs="12" sm="3" md="3" lg="3">
                <div className="text-muted">
                    <DisplayI18n
                        field='name'
                        i18n={settings?.language?.i18n}
                        defaultValue={settings?.language?.name}
                    />:
                </div>
                <div className="font-medium">
                    {organisationContext.settings.language.value.map((lang, index) => (
                        <span key={lang}><Translate id={`misc.${lang}.text`} />{index !== organisationContext.settings.language.value.length - 1 && ', '}</span>
                    ))}
                </div>
            </Col>
            {/* <Col xs="12" sm="3" md="3" lg="3">
                <div className="text-muted">
                    <DisplayI18n
                        field='name'
                        i18n={settings?.create_new_member_online?.i18n}
                        defaultValue={settings?.create_new_member_online?.name}
                    />:
                </div>
                <div className="font-medium">
                    {organisationContext.settings.create_new_member_online.value == '1' ? <i className='mdi mdi-check text-primary' /> : <i className='mdi mdi-close text-danger' />}
                </div>
            </Col> */}
        </Row>
    );
}

export default ReginalSettingsRead;