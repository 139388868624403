export default{

    "clinics.profile.clinicProfile.title" : "Stage",

    "clinics.profile.clinicProfile.tabs.overview"       : "Vue d'ensemble",
    "clinics.profile.clinicProfile.tabs.attendees"      : "Participants",
    "clinics.profile.clinicProfile.tabs.multipleAdd"    : "Inscription multiple",
    "clinics.profile.clinicProfile.tabs.refund"         : "Remboursement",
    "clinics.profile.clinicProfile.tabs.waitinglist"    : "Liste d'attente",
    "clinics.profile.clinicProfile.tabs.waitlist"       : "Liste d'attente",
    "clinics.profile.clinicProfile.tabs.unconfirmed"    : "Non confirmés",
    "clinics.profile.clinicProfile.tabs.communications" : "Communications",
    "clinics.profile.clinicProfile.tabs.finance"        : "Finances",
    "clinics.profile.clinicProfile.showAs.GUEST"        : "Voir comme invité",
    "clinics.profile.clinicProfile.showAs.HOST"         : "Voir comme hôte",
    "clinics.profile.clinicProfile.viewingAs.GUEST"     : "Vu d'invité",

    // waitlist table
    "clinics.profile.waitlist.table.created_at"                          : "Date d'ajout",
    "clinics.profile.waitlist.table.waiting_list_item_sequential_number" : "# de référence",
    "clinics.profile.waitlist.table.goToProfile"                         : "Voir le profil",
    "clinics.profile.waitlist.table.admin"                               : "Inscription admin",
    "clinics.profile.waitlist.table.online"                              : "Inscription en ligne",

    // waitlist status
    "clinics.profile.waitlist.status.PENDING"            : "En attente",
    "clinics.profile.waitlist.status.WAITING_FOR_CLIENT" : "En attente du client",
    "clinics.profile.waitlist.status.CANCELLED"          : "Annulé",
    "clinics.profile.waitlist.status.CANCELED"           : "Annulé",
    "clinics.profile.waitlist.status.CONVERTED"          : "Converti",

    "clinics.profile.waitlist.status.alert.title"     : "Ce membre a été converti.",
    "clinics.profile.waitlist.status.alert.clickHere" : "Cliquez ici pour voir le participant.",
    "clinics.profile.waitlist.status.alert.text"      : "Date de la facture: {date}",

    //waitlist actions
    "clinics.profile.waitlist.actions.add_as_attendee"     : "Ajouter comme participant",
    "clinics.profile.waitlist.actions.reset"               : "Réinitialiser le statut",
    "clinics.profile.waitlist.actions.cancel"              : "Annuler",
    "clinics.profile.waitlist.actions.add_as_attendee.msg" : "Ajouter un membre en tant que participant.",
    "clinics.profile.waitlist.actions.reset.msg"           : "Réinitialiser le statut à en attente.",
    "clinics.profile.waitlist.actions.cancel.msg"          : "Supprimer le membre de la liste d'attente.",

    // cancellation modal
    "clinics.profile.waitlist.cancelModal.label" : "Raison d'annulation",


    // Advanced Settings
    "clinics.profile.clinicProfile.advancedSettings.title"                           : "Paramètres avancées",
    "clinics.profile.clinicProfile.advancedSettings.visibility"                      : "Visibilité - Programmer changements de statut ",
    "clinics.profile.clinicProfile.advancedSettings.visibility.status.required"      : "Vous devez sélectionner un statut.",
    "clinics.profile.clinicProfile.advancedSettings.visibility.date.required"        : "La date est requise.",
    "clinics.profile.clinicProfile.advancedSettings.visibility.time.required"        : "L'heure est requise.",
    "clinics.profile.clinicProfile.advancedSettings.changeVisibility"                : "Changer le status à",
    "clinics.profile.clinicProfile.advancedSettings.changeVisibility.on"             : "à partir de quand",
    "clinics.profile.clinicProfile.advancedSettings.changeVisibility.at"             : "à quelle heure",
    "clinics.profile.clinicProfile.advancedSettings.changeVisibility.add"            : "Ajouter un changement de status",
    "clinics.profile.clinicProfile.advancedSettings.sharedOrganizations"             : "Organisations",
    "clinics.profile.clinicProfile.advancedSettings.sharedOrganizations.message"     : "Précisez les organisations qui devraient avoir accès à ce stage",
    "clinics.profile.clinicProfile.advancedSettings.sharedOrganizations.not.current" : "Vous ne pouvez pas partager à votre organisation actuellement gérée",

    // Status
    "clinics.profile.clinicProfile.status.DRAFT"                 : "Brouillon",
    "clinics.profile.clinicProfile.status.PRIVATE"               : "Privée",
    "clinics.profile.clinicProfile.status.OPEN_PUBLIC_SHOW_LIST" : "Ouvert au public",
    "clinics.profile.clinicProfile.status.OPEN_PUBLIC_SIGNUP"    : "Inscription ouverte",
    "clinics.profile.clinicProfile.status.UNDER_CREATION"        : "En création",

    // Tooltips
    "clinics.profile.clinicProfile.tooltip.status"           : "Statut du stage",
    "clinics.profile.clinicProfile.tooltip.store"            : "Voir le stage",
    "clinics.profile.clinicProfile.tooltip.copyUrl"          : "Copier l'URL du stage!",
    "clinics.profile.clinicProfile.tooltip.advancedSettings" : "Paramètres avancées",

    // Success Toast
    "clinics.profile.clinicProfile.success.statusUpdate" : "Mise à jour du statut",

    "clinics.profile.clinicProfile.notFound.title"       : "Stage introuvable",
    "clinics.profile.clinicProfile.notFound.description" : "Le stage que vous recherchez n'a pas été trouvé, il a peut-être été déplacé ou supprimé.",
    "clinics.profile.clinicProfile.notFound.backbutton"  : "Retour",

    "clinics.profile.header.back" : "Retour à la liste des stages",

    "clinics.profile.payment.error.3197" : "Vous ne pouvez pas supprimer un article de stage déjà acheté.",
}
