import { addExtension, DisplayPhoneNumber, FormikAddress, FormikInputNumber, FormikInputText, FormikPhoneInput, getFormikAddressInitialValues } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import React from 'react';
import {
    Button, Card, CardBody, CardFooter, CardHeader, Col, Fade, FormGroup, Label, Row
} from 'reactstrap';
import { number, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import Required from '../../../../../components/formik/Required';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import CanDoAction from '../../../../../components/permissions/CanDoAction';
import { fail, success } from '@spordle/toasts';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../../helpers/i18nHelper';
import withContexts from '../../../../../helpers/withContexts';
import OrganizationTags from './OrganizationTags';
import { UtilsContext } from '../../../../../contexts/UtilsContext';
import { RolesContext } from '../../../../../contexts/RolesContext';
import InlineCopy from '../../../../../components/inlineCopy/InlineCopy';
import { getAdditionalFieldsInitialValues, getAdditionalFieldsRender, getAdditionalFieldsValidationSchema, getFormattedAdditionalFields } from '../../../../../helpers/additionalFieldsHelpers';
import IsGod from '../../../../../components/permissions/IsGod';

class OrganizationGeneralInfo extends React.Component{
    state = {
        edit: false,
    }

    toggleEdit = () => {
        this.setState((prevState) => ({ edit: !prevState.edit }))
    }

    getFormGroups = () => {
        return this.props.OrganizationContext.additionalFieldsComponents?.find((a) => a.component === 'ORGANISATION')?.groups || []
    }

    getAdditionalFields = () => {
        return (this.props.OrganizationContext.addtional_fields || this.props.OrganizationContext.additional_fields)?.map((field) => ({
            fieldId: field.custom_form_field.custom_form_field_id,
            answer: field.answer,
            optionId: field.custom_form_field_option_selected,
        })) || null
    }

    /**
     * @returns {string} The phone extention
     */
    getPhoneExtension = () => this.props.OrganizationContext.phone?.split('#')[1] || '';

    /**
     * @returns {string} The full address
     */
    buildFullAddress = () => this.props.OrganizationContext.street ? `${this.props.OrganizationContext.street_number || ''}, ${this.props.OrganizationContext.street || ''}, ${this.props.OrganizationContext.city || ''}, ${this.props.OrganizationContext.province_code || ''} ${this.props.OrganizationContext.zip_code || ''}, ${this.props.OrganizationContext.country_code || ''}` : '';

    render(){
        return (
            <I18nHelperContextProvider fields={[ 'name', 'description', 'usual_name' ]}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        this.state.edit ?
                            <I18nContext.Consumer>
                                {({ getGenericLocale }) => (
                                    <Formik
                                        initialValues={{
                                            ...i18nHelper.getInitialValues(this.props.OrganizationContext),
                                            abbreviation: this.props.OrganizationContext.abbreviation ?? '',
                                            email: this.props.OrganizationContext.organisation_email ?? '',
                                            phone: this.props.OrganizationContext.phone || "",
                                            extension: this.getPhoneExtension(),
                                            website: this.props.OrganizationContext.website ?? '',
                                            address2: this.props.OrganizationContext.unit_number ?? '',
                                            address: getFormikAddressInitialValues({
                                                ...this.props.OrganizationContext,
                                                state: undefined,
                                            }),
                                            tags: this.props.tags?.map(({ tag }) => ({ label: tag.name, value: tag.tag_id })) || [],
                                            display_order: this.props.OrganizationContext.display_order ?? 0,
                                            fields: getAdditionalFieldsInitialValues(this.getFormGroups(), this.getAdditionalFields()),
                                            // usualName: this.props.OrganizationContext.usual_name ?? '',
                                        }}
                                        validationSchema={object().shape({
                                            ...i18nHelper.getValidationSchema({ 'name': string().required(<Translate id='organization.profile.overview.generalInfo.form.validation.orgName' />) }),
                                            abbreviation: string().required(<Translate id='organization.profile.overview.generalInfo.form.validation.abb' />),
                                            email: this.props.IdentityRolesContext.isGod() ? string() : string().email(<Translate id='organization.profile.overview.generalInfo.form.validation.email.format' />).required(<Translate id='organization.profile.overview.generalInfo.form.validation.email' />),
                                            website: string().url(<Translate id='organization.profile.overview.generalInfo.form.validation.website.format' />),
                                            address: this.props.IdentityRolesContext.isGod() ? object() : object().address(true, {
                                                streetNumber: <Translate id='form.validation.streetNumber.required' />,
                                                address: <Translate id='form.validation.address.required' />,
                                                city: <Translate id='form.validation.city.required' />,
                                                zipCode: <Translate id='form.validation.zip.required' />,
                                                state: <Translate id='form.validation.province.required' />,
                                                country: <Translate id='form.validation.country.required' />,
                                            }),
                                            phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />),
                                            fields: getAdditionalFieldsValidationSchema(this.getFormGroups()),
                                            usual_name: string(),
                                            display_order: number(),
                                        })}
                                        onSubmit={async({ tags, address, fields, ...values }, { setSubmitting }) => {
                                            return this.props.OrganizationContext.updateOrganizationPartial({
                                                ...values,
                                                ...i18nHelper.getAPIValues(values),
                                                phone: await addExtension(values.phone, values.extension),
                                                ...address,
                                                fields: getFormattedAdditionalFields(fields, this.getFormGroups(), getGenericLocale()),
                                            })
                                                .then(async() => {
                                                    // Handle tags only if the user has the permission for it
                                                    if(this.props.RolesContext.canDoAction([ 'ADD', 'EDIT' ], 'organization', 'organization_tags') || this.props.IdentityRolesContext.isGod()){
                                                        const newTags = tags.filter((tag) => tag.newTag);
                                                        const allTagIds = tags.filter((tag) => !tag.newTag).map((tag) => tag.value);
                                                        if(newTags.length){
                                                            // Create new tags before linking to org
                                                            allTagIds.pushArray(
                                                                await Promise.all(newTags.map((tag) => this.props.UtilsContext.createTags({ organisation_id: this.props.OrganizationContext.organisation_id, name: tag.label }))),
                                                            )
                                                        }
                                                        await this.props.OrganizationContext.updateOrganizationTags(undefined, allTagIds);
                                                        this.props.fetchOrgTags();
                                                    }

                                                    // get organization again to update the additional fields answers in the state
                                                    const org = await this.props.OrganizationContext.getOrganization(this.props.OrganizationContext.organisation_id);
                                                    this.props.OrganizationContext.setCurrentState({ ...this.props.OrganizationContext.state, ...org })
                                                })
                                                .then(() => {
                                                    success({ msg: 'organization.profile.overview.generalInfo.toast.success' });
                                                    setSubmitting(false)
                                                    this.setState(() => ({ edit: false }));
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                        setSubmitting(false);
                                                    }
                                                });
                                        }}
                                    >
                                        {(formik) => (
                                            <Form>
                                                <Fade appear in>
                                                    <Card className='card-shadow shadow-primary'>
                                                        <OverlayLoader isLoading={formik.isSubmitting}>
                                                            <CardHeader className='d-flex align-items-center'>
                                                                <div className="font-bold h4 mb-0"><Translate id='misc.edit' /> - <Translate id='organization.profile.overview.generalInfo.title' /></div>
                                                                <Button className="ml-auto" type='submit' color="primary" disabled={formik.isSubmitting}>
                                                                    <Translate id='misc.save' />
                                                                </Button>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <div className="font-bold text-dark mb-2"><Translate id='misc.baseData' /></div>
                                                                <Row>
                                                                    <RenderI18nForm field='name'>
                                                                        {({ fieldName, lang }) => (
                                                                            <Col key={fieldName} md='4' className={lang === 'fr' && getGenericLocale() === 'fr' ? 'order-md-first' : undefined}>
                                                                                <FormGroup>
                                                                                    <Label for={fieldName} className='text-muted'><Translate id='organization.profile.overview.generalInfo.form.fields.orgName' values={{ lang: lang.toUpperCase() }} /> <Required /></Label>
                                                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        )}
                                                                    </RenderI18nForm>
                                                                    <Col md='4'>
                                                                        <FormGroup>
                                                                            <Label className='text-muted' for='abbreviation'><Translate id='organization.profile.overview.generalInfo.form.fields.abb' /> <Required /></Label>
                                                                            <FormikInputText name='abbreviation' id='abbreviation' trim maxLength={6} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <RenderI18nForm field='usual_name'>
                                                                        {({ fieldName, lang }) => (
                                                                            <Col key={fieldName} md='4' className={lang === 'fr' && getGenericLocale() === 'fr' ? 'order-md-first' : undefined}>
                                                                                <FormGroup>
                                                                                    <Label for={fieldName} className='text-muted'><Translate id='organization.profile.overview.generalInfo.form.fields.usualName' values={{ lang: lang.toUpperCase() }} /> <Required /></Label>
                                                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        )}
                                                                    </RenderI18nForm>
                                                                </Row>
                                                                <Row>
                                                                    <Col md='4'>
                                                                        <FormGroup>
                                                                            <Label for='email' className='text-muted'><Translate id='form.fields.email' /> <Required /></Label>
                                                                            <FormikInputText id='email' name='email' trim type='email' />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md='4'>
                                                                        <FormGroup>
                                                                            <Label for='phone' className='text-muted'><Translate id='form.fields.phone' /></Label>
                                                                            <FormikPhoneInput name='phone' id='phone' />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md='4'>
                                                                        <Label for='extension' className='text-muted'><Translate id='organization.profile.overview.generalInfo.form.fields.phoneExt' /></Label>
                                                                        <FormikInputNumber id='extension' name='extension' />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md='12'>
                                                                        <FormGroup>
                                                                            <Label for='website' className='text-muted'><Translate id='organization.profile.overview.generalInfo.form.fields.website' /></Label>
                                                                            <FormikInputText
                                                                                id='website'
                                                                                name='website'
                                                                                trim
                                                                                onChange={(e) => {
                                                                                    let website = e.target.value
                                                                                    if(website != ''){
                                                                                        if(!website.startsWith('https://') && !website.startsWith('http://') && !website.startsWith('https://www') && !website.startsWith('http://www') && !website.startsWith('www')){
                                                                                            website = "https://www." + website;
                                                                                        }else if(website.startsWith('www')){
                                                                                            website = "https://" + website;
                                                                                        }else if(website.startsWith('http://')){
                                                                                            website = "https://" + website.split('http://')[1];
                                                                                        }
                                                                                    }
                                                                                    formik.setFieldValue('website', website);
                                                                                }}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <FormikAddress
                                                                    id='address' name='address'
                                                                    required={!this.props.IdentityRolesContext.isGod()}
                                                                    allowCopyAddress
                                                                    allowManualPlace
                                                                    allowOpenInMaps
                                                                    label='form.fields.address'
                                                                    labelClassName='text-muted'
                                                                />
                                                                <FormGroup>
                                                                    <Label className='text-muted' for='address2'><Translate id='form.fields.address' /> 2 (<Translate id='form.fields.address2.placeholder' />)</Label>
                                                                    <FormikInputText id='address2' name='address2' trim />
                                                                </FormGroup>
                                                                <CanDoAction action={[ 'EDIT', 'ADD' ]} componentCode='organization' componentPermissionCode='organization_tags'>
                                                                    <OrganizationTags />
                                                                </CanDoAction>

                                                                <IsGod>
                                                                    <Row>
                                                                        <Col md='4'>
                                                                            <FormGroup>
                                                                                <Label className='text-muted' for='display_order'><Translate id='form.fields.displayOrder' /> <Required /></Label>
                                                                                <FormikInputText name='display_order' id='display_order' trim maxLength={6} />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </IsGod>

                                                                {getAdditionalFieldsRender(this.getFormGroups(), 'fields')}
                                                            </CardBody>
                                                            <CardFooter className='bg-white text-right'>
                                                                <Button type='submit' color="primary" disabled={formik.isSubmitting}>
                                                                    <Translate id='misc.save' />
                                                                </Button>
                                                                <Button type='button' color="primary" outline className='ml-1' onClick={() => { this.setState(() => ({ edit: false })) }}>
                                                                    <Translate id='misc.cancel' />
                                                                </Button>
                                                            </CardFooter>
                                                        </OverlayLoader>
                                                    </Card>
                                                </Fade>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            </I18nContext.Consumer>
                            :
                        // Read only data
                            <Fade appear in>
                                <OverlayLoader isLoading={this.props.isLoadingTags}>
                                    <Card body className="card-shadow">
                                        <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                                            <div className="card-title font-bold h4 mb-0"><Translate id='organization.profile.overview.generalInfo.title' /></div>
                                            <CanDoAction className='ml-auto text-link' action="EDIT" componentCode="organization" componentPermissionCode="organization_profile">
                                                <button className="reset-btn" type="button" onClick={this.toggleEdit}><Translate id='misc.edit' /></button>
                                            </CanDoAction>
                                        </div>

                                        <div className="font-bold text-dark mb-2"><Translate id='misc.baseData' /></div>
                                        <Row className='mb-3'>
                                            <Col md='4'>
                                                <div className='text-muted'><Translate id='organization.profile.overview.generalInfo.form.fields.orgNameEn' /></div>
                                                <div className='font-medium'><DisplayI18n i18n={this.props.OrganizationContext.i18n} field='name' defaultValue={this.props.OrganizationContext.organisation_name} lang='en' /></div>
                                            </Col>
                                            <I18nContext.Consumer>
                                                {({ getGenericLocale }) => (
                                                    <Col md='4' className={getGenericLocale() === 'fr' ? 'order-md-first' : undefined}>
                                                        <div className='text-muted'><Translate id='organization.profile.overview.generalInfo.form.fields.orgNameFr' /></div>
                                                        <div className='font-medium'><DisplayI18n i18n={this.props.OrganizationContext.i18n} field='name' defaultValue={this.props.OrganizationContext.organisation_name} lang='fr' /></div>
                                                    </Col>
                                                )}
                                            </I18nContext.Consumer>
                                            <Col md='4'>
                                                <div className='text-muted'><Translate id='organization.profile.overview.generalInfo.form.fields.abb' /></div>
                                                <div className='font-medium'>{this.props.OrganizationContext.abbreviation || '-'}</div>
                                            </Col>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Col md='4'>
                                                <div className='text-muted'><Translate id='organization.profile.overview.generalInfo.form.fields.usualNameEn' /></div>
                                                <div className='font-medium'><DisplayI18n i18n={this.props.OrganizationContext.i18n} field='usual_name' defaultValue={this.props.OrganizationContext.usual_name || '-'} lang='en' /></div>
                                            </Col>
                                            <I18nContext.Consumer>
                                                {({ getGenericLocale }) => (
                                                    <Col md='4' className={getGenericLocale() === 'fr' ? 'order-md-first' : undefined}>
                                                        <div className='text-muted'><Translate id='organization.profile.overview.generalInfo.form.fields.usualNameFr' /></div>
                                                        <div className='font-medium'><DisplayI18n i18n={this.props.OrganizationContext.i18n} field='usual_name' defaultValue={this.props.OrganizationContext.usual_name || '-'} lang='fr' /></div>
                                                    </Col>
                                                )}
                                            </I18nContext.Consumer>
                                            <Col md='4'>
                                                <div className='text-muted'><Translate id='organization.profile.overview.generalInfo.form.fields.code' /></div>
                                                <div className='font-medium'>{this.props.OrganizationContext.organisation_code || '-'}</div>
                                            </Col>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Col md='4'>
                                                <div className='text-muted'><Translate id='form.fields.email' /></div>
                                                <div className='font-medium'>{this.props.OrganizationContext.organisation_email ? <a href={`mailto:${this.props.OrganizationContext.organisation_email}`}>{this.props.OrganizationContext.organisation_email}</a> : '-'}</div>
                                            </Col>
                                            <Col md='4'>
                                                <div className='text-muted'><Translate id='form.fields.phone' /></div>
                                                <div className='font-medium'>
                                                    <DisplayPhoneNumber phoneString={this.props.OrganizationContext.phone} format='INTERNATIONAL' emptyValue='-' />
                                                </div>
                                            </Col>
                                            <Col md='4'>
                                                <div className='text-muted'><Translate id='organization.profile.overview.generalInfo.form.fields.website' /></div>
                                                <div className='font-medium'>{this.props.OrganizationContext.website ? <a href={this.props.OrganizationContext.website} title={this.props.OrganizationContext.website} target='_blank' rel='noopener noreferrer'><Translate id='organization.profile.overview.generalInfo.form.fields.website.visit' /></a> : '-'}</div>
                                            </Col>
                                        </Row>
                                        <Row className='mb-3'>
                                            {this.buildFullAddress() ?
                                                <Col md='6'>
                                                    <div className='text-muted'><Translate id='form.fields.address' /></div>
                                                    <InlineCopy classname='font-medium' toCopy={this.buildFullAddress()}>
                                                        {this.props.OrganizationContext.map_url ?
                                                            <a href={this.props.OrganizationContext.map_url || '/'} target='_blank' rel='noopener noreferrer'>{this.buildFullAddress()}</a>
                                                            :
                                                            this.buildFullAddress()
                                                        }
                                                    </InlineCopy>
                                                </Col>
                                                :
                                                <Col md='6'>
                                                    <div className='text-muted'><Translate id='form.fields.address' /></div>
                                                    <div className='font-medium'>-</div>
                                                </Col>
                                            }
                                            <Col md='6'>
                                                <div className='text-muted'><Translate id='form.fields.address' /> 2 (<Translate id='form.fields.address2.placeholder' />)</div>
                                                <div className='font-medium'>{this.props.OrganizationContext.unit_number || '-'}</div>
                                            </Col>
                                        </Row>

                                        {getAdditionalFieldsRender(this.getFormGroups(), undefined, this.getAdditionalFields(), true)}
                                    </Card>
                                </OverlayLoader>
                            </Fade>
                    )}
                </I18nHelperContext.Consumer>
            </I18nHelperContextProvider>
        )
    }
}

export default withContexts(OrganizationContext, IdentityRolesContext, UtilsContext, RolesContext)(OrganizationGeneralInfo);