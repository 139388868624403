export default{
    "settings.merchantAccounts.title"           : "Merchant Accounts",
    "settings.merchantAccounts.help.1"          : "A Merchant Account is needed to be able to process payments for your Organization.",
    "settings.merchantAccounts.help.2"          : "Participants and Members may pay with a credit card. These payments will be processed by the chosen Payment Processor below.",
    "settings.merchantAccounts.activatedOn"     : "Activated on",
    "settings.merchantAccounts.offers"          : "Payment Processors",
    "settings.merchantAccounts.selectOffers"    : "Select a payment processors",
    "settings.merchantAccounts.contact"         : "Contact",
    "settings.merchantAccounts.viewOffer"       : "View Offer",
    "settings.merchantAccounts.choose"          : "Create a new Paysafe merchant account",
    "settings.merchantAccounts.chooseSportsPay" : "Contact SportsPay",
    "settings.merchantAccounts.external"        : "External",
    "settings.merchantAccounts.embedded"        : "Embedded",
    "settings.merchantAccounts.preferred"       : "Preferred Partner",

    "settings.merchantAccounts.note.paysafe.1" : "Merchant account creation within the platform",
    "settings.merchantAccounts.note.paysafe.2" : "Payments are done within the platform",
    "settings.merchantAccounts.note.paysafe.3" : "Users manage their payments and credit cards within the platform via {spordle_account}",
    "settings.merchantAccounts.note.paysafe.4" : "Transactions reporting within the platform",
    "settings.merchantAccounts.note.paysafe.5" : "Overall better experience and support",

    "settings.merchantAccounts.note.sportspay.1" : "Merchant account creation on SportsPay",
    "settings.merchantAccounts.note.sportspay.2" : "Payments redirection to SportPay",
    "settings.merchantAccounts.note.sportspay.3" : "Users manage their payments on SportPay",
    "settings.merchantAccounts.note.sportspay.4" : "Transactions reporting available on SportsPay",

    "settings.merchantAccounts.provider.QUICK_ENROLLMENT" : "SportsPay",
    "settings.merchantAccounts.provider.PAYSAFE"          : "Paysafe",

}