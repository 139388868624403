import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Container } from "reactstrap";
import EmptyLayout from "../../../../../components/table/EmptyLayout";
import { I18nContext } from "../../../../../contexts/I18nContext";
import settingsContactTypesRoutes from "../../../../../routes/tabRoutes/organizationSettings/settingsContactTypesRoutes";
import I18nHelperContextProvider, { I18nHelperContext } from '../../../../../helpers/i18nHelper'
import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel";
import SidePanelOrganizationContactTypes from "./types/SidePanelOrganizationContactTypes";
import SpordleTableProvider from "@spordle/datatables";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import SubRouteSettingWrapper from "../../../components/SubRouteSettingWrapper";


const OrganizationSettingsContactTypes = () => {
    const i18nContext = useContext(I18nContext);
    const organizationContext = useContext(OrganizationContext);
    return (
        <Container>
            <I18nHelperContextProvider fields={[ 'name' ]}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <div>
                            <SpordlePanelTable
                                allowOutsideClick
                                sidePanel={(props) => <SidePanelOrganizationContactTypes {...props} />}
                                dataIndex='organisation_contact_type_id'
                                table={(panelProps) => {
                                    return (
                                        <SpordleTableProvider
                                            id='OrganizationContactTypes'
                                            tableHover clickable striped
                                            bordered
                                            ref={panelProps.spordleTableRef}
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                            dataIndex='organisation_contact_type_id'
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                                'name',
                                            ]}
                                            columns={[
                                                ...i18nHelper.getTableColumns('name'),
                                                {
                                                    label: <Translate id='organization.settings.organizationContactTypes.table.column.status' />,
                                                    key: "active",
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                            ]}
                                            onColumnClick={(e, contactType) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        panelProps.onSingleSelectChange(contactType);
                                                        break;
                                                }
                                            }}
                                            desktopWhen='md'
                                            emptyLayout={<EmptyLayout translateTitle title='organization.settings.organizationContactTypes.table.empty' />}
                                            renderRow={(columnKey, contactType, spordleTable) => {
                                                switch (columnKey){
                                                    case 'active':
                                                        return (
                                                            <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                {contactType.active == '1' ?
                                                                    <i className="text-primary mdi mdi-check" />
                                                                    :
                                                                    <i className="text-danger mdi mdi-close" />
                                                                }
                                                            </div>
                                                        )
                                                    default:
                                                        break;
                                                }
                                            }}
                                            rowIsHighlighted={(contactType) => !!contactType.checked}
                                            loadData={(from, filterData, spordleTable) => {
                                                switch (from){
                                                    case 'REFRESH':
                                                        spordleTable.setLoading();
                                                    case 'CDM':
                                                        return organizationContext.getOrganizationContactTypes(organizationContext.organisation_id);
                                                    default:
                                                        break;
                                                }
                                            }}
                                        >
                                            <SubRouteSettingWrapper
                                                back="organization"
                                                routes={settingsContactTypesRoutes}
                                            />
                                        </SpordleTableProvider>
                                    )
                                }}
                            />
                        </div>
                    )}
                </I18nHelperContext.Consumer>
            </I18nHelperContextProvider>
        </Container>
    );
}

export default OrganizationSettingsContactTypes