import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";
import { DisplayI18n } from "../../helpers/i18nHelper";
import CheckboxStar from "../checkboxStar/CheckboxStar";
import UserDisplay from "../userDisplay/UserDisplay";
import UserImg from "../UserImg";

/**
 * @description Role card displayed in AuthRolePicker
 * @param {Object} props
 * @param {object} props.idRole Object container identity role ressource
 * @param {string} props.primary String that contains the uuid of the primary role
 * @param {function} props.setPrimary Changed the uuid of the primary role
 * @param {function} props.setSelectedRole Function called when role is selected
 * @returns {JSX.Element}
 */
const Role = ({ idRole, primary, setPrimary, setSelectedRole }) => {

    return (
        <UserDisplay onClick={() => setSelectedRole(idRole)} card hover className="d-flex mb-2">
            <UserDisplay.Container>
                <UserImg
                    alt={idRole.organisation.organisation_name}
                    abbr={idRole.organisation.abbreviation}
                    src={idRole.organisation.logo?.full_path}
                    filePos={idRole.organisation.logo?.file_position}
                    width={60}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title>{idRole.role.title}</UserDisplay.Title>
                <UserDisplay.Subtitle>
                    <DisplayI18n
                        field="name"
                        defaultValue={idRole.organisation.organisation_name}
                        i18n={idRole.organisation.i18n}
                    />
                </UserDisplay.Subtitle>
                <div className="text-link"><Translate id='organization.changeOrganization.selectRole' /></div>
            </UserDisplay.Container>
            <Tooltip
                withArrow
                position='top'
                label={<Translate id={'components.authRolePicker.tooltip.' + (idRole.identity_role_id === primary ? 'primary' : 'setPrimary')} />}
            >
                <div
                    className="position-absolute top-0 right-0 bottom-0 p-3 z-index-1"
                    onClick={(e) => e.stopPropagation()}
                >
                    <CheckboxStar
                        id={`primaryRole-${idRole.identity_role_id}`}
                        name="primaryRole"
                        type="radio"
                        checked={idRole.identity_role_id === primary}
                        onChange={(e) => {
                            e.currentTarget.checked && setPrimary();
                        }}
                    />
                </div>
            </Tooltip>
        </UserDisplay>
    )
}

export default Role;