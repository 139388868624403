import React from "react";
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import MemberProfileTransferSidepanelOverview from "./MemberProfileTransferSidepanelOverview";
import MemberProfileTransferSidepanelFinances from "./MemberProfileTransferSidepanelFinances";

import TransferSidepanel from '../../../../../../../../components/sidePanel/transfers/TransferSidepanel';
import OverlayLoader from "../../../../../../../../components/loading/OverlayLoader";
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import SidePanel from "../../../../../../../../components/sidePanel/SidePanel";
import FinancesInternationalTab from "../../../../../../../../components/sidePanel/transfers/tabs/FinancesInternational";
import { OrganizationContext } from "../../../../../../../../contexts/OrganizationContext";
import withContexts from "../../../../../../../../helpers/withContexts";
import MemberProfileTransferCTISidepanelOverview from "./MemberProfileTransferCTISidepanelOverview";
import ConfirmModal from "../../../../../../../../components/confirmModal/ConfirmModal";
import { deleteMemberTransfer } from "../../../../../../../../api/client/memberTransfers"
import { fail, success } from "@spordle/toasts";
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";

class MemberProfileTransferSidepanel extends React.Component{

    constructor(props){
        super(props);

        this.toggleTab = this.toggleTab.bind(this);

        this.state = {
            activeTab: '1',
            isLoading: false,
        };

        this.hasCTI = !!this.props.OrganizationContext.settings.international_transfer_fields?.value;
    }

    setLoading = (_isLoading) => {
        this.setState(() => ({ isLoading: _isLoading }))
    }

    toggleTab(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    render(){
        if(this.props.selectedRows[0].transfer_type === 'BRANCH' || this.props.selectedRows[0].transfer_type === 'ASSOCIATION' || this.props.selectedRows[0].transfer_type === 'USA'){
            return <TransferSidepanel {...this.props} from='memberProfile' />
        }
        return ( // International Transfer
            <OverlayLoader isLoading={this.state.isLoading}>
                <div className="p-3">
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />
                        <div className="ml-auto d-flex align-items-center">
                            <SidePanel.ActionsMenu right>
                                <ConfirmModal
                                    toggler={(toggle) => (
                                        <SidePanel.MenuAction onClick={toggle} componentCode="members" componentPermissionCode="members_request_transfer_international" action="DELETE">
                                            <Translate id='misc.delete' />
                                        </SidePanel.MenuAction>
                                    )}
                                    modalTitle={<Translate id='members.profile.transfers.sidepanel.action.delete' />}
                                    actionButtonText='misc.delete'
                                    translateActionButtonText
                                    color='danger'
                                    onConfirm={() => {
                                        // return true
                                        return deleteMemberTransfer(this.props.selectedRows[0].member.member_id, this.props.selectedRows[0].member_transfer_id)
                                            .then(() => {
                                                success();
                                                this.props.tableRef.deleteRow(this.props.selectedRows[0].member_transfer_id).then(this.props.toggle);
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    }}
                                />
                            </SidePanel.ActionsMenu>
                        </div>
                    </div>

                    {this.hasCTI ?
                        <CTIHeader />
                        :
                        <TransferHeader selectedRows={this.props.selectedRows} />
                    }
                </div>

                <div>
                    <Nav tabs>
                        <NavItem className="w-50 text-center">
                            <NavLink
                                className={stringBuilder({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggleTab('1'); }}
                            >
                                <Translate id='members.profile.transfers.sidepanel.tabs.overview' />
                            </NavLink>
                        </NavItem>
                        {!this.hasCTI &&
                            <NavItem className="w-50 text-center">
                                <NavLink
                                    className={stringBuilder({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggleTab('2'); }}
                                >
                                    <Translate id='members.profile.transfers.sidepanel.tabs.finances' />
                                </NavLink>
                            </NavItem>
                        }
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            {this.hasCTI ?
                                <MemberProfileTransferCTISidepanelOverview {...this.props} isLoading={this.state.isLoading} setLoading={this.setLoading} />
                                :
                                <MemberProfileTransferSidepanelOverview {...this.props} isLoading={this.state.isLoading} setLoading={this.setLoading} international={this.props.selectedRows[0].transfer_type === 'INTERNATIONAL'} />
                            }
                        </TabPane>
                        {!this.hasCTI &&
                            <TabPane tabId="2">
                                {this.props.selectedRows[0].transfer_type === 'INTERNATIONAL' ?
                                    <FinancesInternationalTab {...this.props} transfer={this.props.selectedRows[0]} />
                                    :
                                    <MemberProfileTransferSidepanelFinances {...this.props} isLoading={this.state.isLoading} setLoading={this.setLoading} />
                                }
                            </TabPane>
                        }
                    </TabContent>
                </div>
            </OverlayLoader>
        )

    }
}

const CTIHeader = (props) => {
    return (
        <div className='mb-3'>
            <SidePanel.Title><Translate id={'members.profile.transfers.sidepanel.transfer.CTI'} /></SidePanel.Title>
        </div>
    )
}


const TransferHeader = (props) => {
    return (
        <>
            <div className='mb-3'>
                <SidePanel.Title><Translate id={'members.profile.transfers.sidepanel.transfer.' + props.selectedRows[0].transfer_type} /></SidePanel.Title>
            </div>
            <Row>
                <Col sm="6">
                    <div className="h6 font-bold"><Translate id='members.profile.transfers.sidepanel.from' /></div>
                    <div className="mb-3">
                        {props.selectedRows[0].team ?
                            <div className="small text-truncate">
                                {props.selectedRows[0].organisation ?
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={props.selectedRows[0].organisation.organisation_name}
                                        i18n={props.selectedRows[0].organisation.i18n}
                                    />
                                    :
                                    '-'
                                }
                            </div>
                            :
                            <div className='font-medium text-dark text-truncate'>
                                {props.selectedRows[0].organisation ?
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={props.selectedRows[0].organisation.organisation_name}
                                        i18n={props.selectedRows[0].organisation.i18n}
                                    />
                                    :
                                    '-'
                                }
                            </div>
                        }
                    </div>
                </Col>
                <Col sm="6">
                    <div className="h6 font-bold"><Translate id='members.profile.transfers.sidepanel.to' /></div>
                    <div className="mb-3">
                        {props.selectedRows[0].target_team ?
                            <div className="small text-truncate">
                                {props.selectedRows[0].target_organisation ?
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={props.selectedRows[0].target_organisation.organisation_name}
                                        i18n={props.selectedRows[0].target_organisation.i18n}
                                    />
                                    :
                                    '-'
                                }
                            </div>
                            :
                            <div className='font-medium text-dark text-truncate'>
                                {props.selectedRows[0].target_organisation ?
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={props.selectedRows[0].target_organisation.organisation_name}
                                        i18n={props.selectedRows[0].target_organisation.i18n}
                                    />
                                    :
                                    '-'

                                }
                            </div>
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default withContexts(OrganizationContext)(MemberProfileTransferSidepanel);