export default{
    "components.createOrganizationModal.title"                      : "Create an Organization",
    "components.createOrganizationModal.success.title"              : "Organization created!",
    "components.createOrganizationModal.success.msg"                : "Your new organization was successfully created.",
    "components.createOrganizationModal.success.btn"                : "Manage new organization",
    "components.createOrganizationModal.form.parent.label"          : "Parent Organization",
    "components.createOrganizationModal.form.parent.required"       : "Please specify a parent organization",
    "components.createOrganizationModal.form.orgCategory.label"     : "Organization Category",
    "components.createOrganizationModal.form.orgCategory.required"  : "Please specify an organization category",
    "components.createOrganizationModal.form.abbreviation.label"    : "Abbreviation",
    "components.createOrganizationModal.form.abbreviation.required" : "Please specify an abbreviation",
    "components.createOrganizationModal.form.error.3045"            : "You cannot create an organization of this category under this parent organization.",
    "components.createOrganizationModal.form.error.3191"            : "You do not have the permissions required to create an organization.",
    "components.createOrganizationModal.form.error.3252"            : "The organisation external identifier is already in use.",

}