export default{
    // table
    "clinics.profile.attendees.table.organization"                      : "Organisation",
    "clinics.profile.attendees.table.paid"                              : "Payé",
    "clinics.profile.attendees.table.paid.not"                          : "Impayé",
    "clinics.profile.attendees.table.attended"                          : "Assisté",
    "clinics.profile.attendees.table.attended.not"                      : "Pas assisté",
    "clinics.profile.attendees.table.passed"                            : "Passé",
    "clinics.profile.attendees.table.passed.not"                        : "Échoué",
    "clinics.profile.attendees.table.grade"                             : "Note",
    "clinics.profile.attendees.table.paginationMessage"                 : "Participant {itemStartPos} à {itemEndPos} sur un total de {totalItems} participants",
    "clinics.profile.attendees.table.header.statusFilter.placeholder"   : "Statut",
    "clinics.profile.attendees.table.header.paidFilter.placeholder"     : "Statut de paiement",
    "clinics.profile.attendees.table.header.attendedFilter.placeholder" : "Statut de participation",
    "clinics.profile.attendees.table.header.passedFilter.placeholder"   : "Statut de passage",
    "clinics.profile.attendees.table.filters.unconfirmed"               : "Participants non confirmés seulement",
    "clinics.profile.attendees.table.filters.cancelled"                 : "Participations annulées seulement",

    // sidepanel
    "clinics.profile.attendees.sidepanel.tabs.details"         : "Détails",
    "clinics.profile.attendees.sidepanel.tabs.profile"         : "Profil",
    "clinics.profile.attendees.sidepanel.tabs.member"          : "Membre",
    "clinics.profile.attendees.sidepanel.tabs.forms"           : "Questionnaire",
    "clinics.profile.attendees.sidepanel.header.notConfirmed"  : "Non confirmé(e)",
    "clinics.profile.attendees.sidepanel.header.cancelled"     : "Participation annulée",
    "clinics.profile.attendees.sidepanel.header.cancelledAt"   : "Participation annulée le {date}",
    "clinics.profile.attendees.sidepanel.header.cancelledAtBy" : "Participation annulée le {date} par {name}",

    "clinics.profile.attendees.sidepanel.actions.transfer"                        : "Transférer de stage",
    "clinics.profile.attendees.sidepanel.actions.transfer.noRefund"               : "Impossible de transférer avec des remboursements.",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.reason.label"     : "Raison d'annulation",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee"                  : "Annuler {count, plural, one {le participant} other {les participants}}",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.text"             : "Vous êtes sur le point d'annuler ce participant.",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.valid"            : "{count, plural, one {Participant à canceller (#)} other {Participants à canceller (#)}}",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.valid.subtitle"   : "{count, plural, one {La participation du membre suivant peut être annulée} other {Les participations des membres suivants peuvent être annulées}}",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.invalid"          : "{count, plural, one {Cancellation impossible (#)} other {Cancellations impossibles (#)}}",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.invalid.subtitle" : "{count, plural, one {La participation du membre suivant ne peut pas peut être annulée} other {Les participations des membres suivants ne peuvent pas être annulées}}",

    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.error.alreadyCancelled" : "La participation est déjà annulée",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.error.alreadyPassed"    : "Le participant a déjà passé ce stage",

    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.review.title"    : "Confirmation d'annulation",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.review.subtitle" : "{count, plural, one {Le status d'annulation} other {Les status d'annulation}}",

    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.success.single.title" : "La participation a été annulée avec succès",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.fail.single.title"    : "La participation n'a pas pu être annulée",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.success.title"        : "{count, plural, one {# participation annulée avec succès} other {# participations annulées avec succès}}",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.fail.title"           : "{count, plural, one {# participation n'a pas pu être annulée} other {# participations n'ont pas pu être annulées}}",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.mail.title"           : "Votre participation a été annulée",
    "clinics.profile.attendees.sidepanel.actions.cancelAttendee.mail.subject"         : "Participation au stage annulée",


    "clinics.profile.attendees.sidepanel.actions.confirmMember"        : "Confirmer le membre",
    "clinics.profile.attendees.sidepanel.actions.confirmMember.text"   : "Vous êtes sur le point de confirmer ce membre.",
    "clinics.profile.attendees.sidepanel.actions.notification"         : "Envoyer une notification",
    "clinics.profile.attendees.sidepanel.actions.addPayment"           : "Appliquer un paiement",
    "clinics.profile.attendees.sidepanel.actions.refund"               : "Rembourser",
    "clinics.profile.attendees.sidepanel.actions.exchange"             : "Changer d'articles",
    "clinics.profile.attendees.sidepanel.actions.certify"              : "Certifier",
    "clinics.profile.attendees.sidepanel.actions.certify.irreversible" : "Cette action est irréversible",

    "clinics.profile.attendees.sidepanel.refund.attendee.confirmation.question" : "Souhaitez-vous annuler le participant lié à cette transaction?",
    "clinics.profile.attendees.sidepanel.refund.attendee.confirmation.btn"      : "Annuler le participant",

    "clinics.profile.attendees.sidepanel.attendeeInfo"                    : "Informations du participant",
    "clinics.profile.attendees.sidepanel.attendeesInfo"                   : "Informations des participants",
    "clinics.profile.attendees.sidepanel.selected.attendees"              : "participants sélectionnés",
    "clinics.profile.attendees.sidepanel.selected.attendees.hasPaid"      : "A payé",
    "clinics.profile.attendees.sidepanel.attended.label"                  : "Présence",
    "clinics.profile.attendees.sidepanel.attended"                        : "{count, plural, =0 {A assisté} one {A assisté} other {Ont assisté}}",
    "clinics.profile.attendees.sidepanel.attended.required"               : "Veuillez sélectionner un statut de présence",
    "clinics.profile.attendees.sidepanel.attended.not"                    : "{count, plural, =0 {N'a pas assisté} one {N'a pas assisté} other {N'ont pas assisté}}",
    "clinics.profile.attendees.sidepanel.attended.unable"                 : "Le statut de présence ne peut pas être modifié si le participant a passé le stage",
    "clinics.profile.attendees.sidepanel.attended.unable.multiple"        : "Le statut de présence ne peut pas être modifié si un des participants a passé le stage",
    "clinics.profile.attendees.sidepanel.attended.date.required"          : "La date de présence est requise",
    "clinics.profile.attendees.sidepanel.attended.date.before"            : "La date de présence ne peut pas être après la date de passage",
    "clinics.profile.attendees.sidepanel.passed.label"                    : "Résultat",
    "clinics.profile.attendees.sidepanel.passed"                          : "{count, plural, =0 {A réussi} one {A réussi} other {Ont réussi}}",
    "clinics.profile.attendees.sidepanel.passed.not"                      : "N'a pas réussi",
    "clinics.profile.attendees.sidepanel.passed.unable"                   : "Le statut de passage ne peut pas être modifié si le participant a été certifié pour le stage",
    "clinics.profile.attendees.sidepanel.passed.unable.multiple"          : "Le statut de passage ne peut pas être modifié si un des participants a été certifié pour le stage",
    "clinics.profile.attendees.sidepanel.passed.date.required"            : "La date de passage est requise",
    "clinics.profile.attendees.sidepanel.passed.date.noPresence"          : "Le participant doit avoir assisté au stage pour avoir le statut de passage",
    "clinics.profile.attendees.sidepanel.passed.date.noPresence.multiple" : "Tous les participants doivent avoir assisté au stage pour avoir le statut de passage",
    "clinics.profile.attendees.sidepanel.passed.date.after"               : "La date de passage ne peut pas être avant la date de présence",
    "clinics.profile.attendees.sidepanel.passed.date.before"              : "La date de passage ne peut pas être après la date de certification",
    "clinics.profile.attendees.sidepanel.certified.label"                 : "Certification",
    "clinics.profile.attendees.sidepanel.certified"                       : "Certifié",
    "clinics.profile.attendees.sidepanel.certified.not"                   : "Non certifié",
    "clinics.profile.attendees.sidepanel.certified.hint"                  : "Cette action créera une qualification pour ce membre. Pour spécifier une date différente, un numéro de certification ou autre information, veuillez utiliser l'action \"Certifier\".",
    "clinics.profile.attendees.sidepanel.notSpecified"                    : "Non spécifié",
    "clinics.profile.attendees.sidepanel.invoice.not.found"               : "Les informations de paiement ne sont pas disponibles.",

    "clinics.profile.attendees.sidepanel.registeredBy"          : "Inscrit par",
    "clinics.profile.attendees.sidepanel.registeredFrom.ONLINE" : "Inscrit via la boutique en ligne",
    "clinics.profile.attendees.sidepanel.registeredFrom.ADMIN"  : "Inscrit via cette plateforme",
    "clinics.profile.attendees.sidepanel.registeredAt"          : "à",

    "clinics.profile.attendees.sidepanel.paymentInfo"                   : "Informations de paiement",
    "clinics.profile.attendees.sidepanel.paymentInfo.refund"            : "Remboursement",
    "clinics.profile.attendees.sidepanel.paymentInfo.refunds"           : "Remboursements",
    "clinics.profile.attendees.sidepanel.paymentInfo.paymentReception"  : "Paiement reçu",
    "clinics.profile.attendees.sidepanel.paymentInfo.paymentReceptions" : "Paiements reçus",
    "clinics.profile.attendees.sidepanel.paymentInfo.clinic"            : "Stage",
    "clinics.profile.attendees.sidepanel.paymentInfo.clinicFees"        : "Articles de stage",
    "clinics.profile.attendees.sidepanel.paymentInfo.date"              : "Date",
    "clinics.profile.attendees.sidepanel.paymentInfo.amount"            : "Montant",
    "clinics.profile.attendees.sidepanel.paymentInfo.status"            : "Statut",
    "clinics.profile.attendees.sidepanel.paymentInfo.total"             : "Total",
    "clinics.profile.attendees.sidepanel.paymentInfo.due"               : "Dû",
    "clinics.profile.attendees.sidepanel.paymentInfo.price"             : "Prix",

    "clinics.profile.attendees.sidepanel.waivers" : "Dispenses",

    "clinics.profile.attendees.sidepanel.paymentMethod.CHEQUE"              : "Chèque",
    "clinics.profile.attendees.sidepanel.paymentMethod.CASH"                : "Argent comptant",
    "clinics.profile.attendees.sidepanel.paymentMethod.CREDITCARD"          : "Carte de crédit",
    "clinics.profile.attendees.sidepanel.paymentMethod.PAYMENTPROXY"        : "Carte de crédit (paiement proxy)",
    "clinics.profile.attendees.sidepanel.paymentMethod.INTERAC"             : "Transfert Interac",
    "clinics.profile.attendees.sidepanel.paymentMethod.FUNDING"             : "Financement",
    "clinics.profile.attendees.sidepanel.paymentMethod.undefined"           : "N/A",
    "clinics.profile.attendees.sidepanel.paymentMethod.ORGANISATION_CREDIT" : "Crédité (Gratuit)",
    "clinics.profile.attendees.sidepanel.paymentMethod.MEMBER_CREDIT"       : "Crédit de membre",
    "clinics.profile.attendees.sidepanel.paymentMethod.FREE"                : "Gratuit",
    "clinics.profile.attendees.sidepanel.paymentMethod.EXTERNALPAYMENT"     : "Paiement externe",

    "clinics.profile.attendees.sidepanel.status.PENDING"        : "En attente",
    "clinics.profile.attendees.sidepanel.status.PAID"           : "Payé",
    "clinics.profile.attendees.sidepanel.status.COMPLETED"      : "Complété",
    "clinics.profile.attendees.sidepanel.status.NEED_REVIEW"    : "En révision",
    "clinics.profile.attendees.sidepanel.status.SUSPENDED"      : "Suspendu",
    "clinics.profile.attendees.sidepanel.status.DECLINED"       : "Refusé",
    "clinics.profile.attendees.sidepanel.status.PARTIALLY_PAID" : "Partiellement payé",
}