import { useSpordleTable } from "@spordle/datatables";
import { FormikSelect, FormikSwitch } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Alert, Button, Collapse, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useSWR from "swr";
import { object, string, array, bool } from "yup";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import CustomAnimatedIcon from "../../../../../components/customAnimatedIcon/CustomAnimatedIcon";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import { PeriodsContext } from "../../../../../contexts/contexts";
import { OfficiatingCoachContext } from "../../../../../contexts/OfficiatingCoachContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { MemberCard } from "./AddOfficiatingCoachModal";

const AssignPermissions = ({ member, setIsLoading, isLoading, setStep, toggle, supervisionTypes }) => {
    const { refreshTable } = useSpordleTable();
    const [ showSuccess, setShowSuccess ] = useState(false);
    const { createMemberOfficiatingCoach, getMemberOfficiatingCoachList, updateMemberOfficiatingCoach } = useContext(OfficiatingCoachContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const { data: officiatingCoach, isValidating } = useSWR(
        [ 'getMemberOfficiatingCoach', member ],
        () => member ? getMemberOfficiatingCoachList(member.member_id, { current_period_only: 1 }).then((list) => list[0]) : null,
        {
            revalidateOnMount: true,
        },
    );

    const assignPermissionsCall = (mode, memberId, { ...apiVal }) => {
        if(mode === "create"){
            return createMemberOfficiatingCoach(memberId, apiVal);
        }

        return updateMemberOfficiatingCoach(memberId, officiatingCoach?.member_officiating_coach_id, apiVal);
    };

    return (
        <Formik
            enableReinitialize
            initialStatus={{
                msg: null,
                isVisible: false,
            }}
            initialValues={{
                supervision_type_ids: officiatingCoach ? officiatingCoach.supervision_types?.map((sT) => sT.supervision_type_id) : [],
                national_officiating_coach: officiatingCoach ? officiatingCoach.national_officiating_coach == "1" : false,
                active: officiatingCoach ? officiatingCoach.active : "1",
            }}
            validationSchema={object().shape({
                supervision_type_ids: array().of(string()).min(1, <Translate id='components.assignOfficiatingPermissionsModal.supervisionTypes.validation' />),
                national_officiating_coach: bool(),
            })}
            onSubmit={(values, { setStatus }) => {
                setIsLoading(true);

                return assignPermissionsCall(
                    officiatingCoach ? "edit" : "create",
                    member?.member_id,
                    {
                        ...values,
                        national_officiating_coach: values.national_officiating_coach ? 1 : 0,
                        period_id: selectedPeriod.period_id,
                    },
                )
                    .then(() => {
                        refreshTable();
                        setShowSuccess(true);
                        setIsLoading(false);
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            setIsLoading(false);
                            setStatus({
                                isVisible: true,
                                msg: <DisplayI18n field="message" defaultValue={e.message} i18n={e.i18n} />,
                            });
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <CrossFade isVisible={!showSuccess}>
                        <ModalHeader toggle={toggle}><Translate id='components.assignOfficiatingPermissionsModal.title' /></ModalHeader>
                        <ModalBody>
                            {member &&
                                <MemberCard member={member} />
                            }
                            {member &&
                                <Collapse isOpen={!!officiatingCoach}>
                                    <div className="bg-light small p-2 rounded border">
                                        <i className="mdi mdi-information-outline text-primary" /> <Translate values={{ name: `${member.first_name} ${member.last_name}` }} id='officialSupervision.assignPermissions.alreadyOfficiatingCoach' />
                                    </div>
                                </Collapse>
                            }
                            <div className="mt-3">
                                <Label className="text-muted" for="supervision_type_ids">
                                    <Translate id='components.assignOfficiatingPermissionsModal.supervisionTypes' />
                                </Label>
                                <FormikSelect
                                    multi
                                    clearable
                                    search={false}
                                    id="supervision_type_ids"
                                    name="supervision_type_ids"
                                    renderOption={({ option }) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={option.i18n}
                                            defaultValue={option.name}
                                        />
                                    )}
                                    isLoading={isValidating || supervisionTypes === null}
                                    options={supervisionTypes ? supervisionTypes.map((type) => ({ ...type, label: type.name, value: type.supervision_type_id })) : []}
                                />
                            </div>
                            <div className="text-muted">
                                <CanDoAction action="ADD" componentCode="members" componentPermissionCode="member_national_officiating_coach">
                                    <div className="d-flex align-items-center mt-3">
                                        <FormikSwitch id="national_officiating_coach" name="national_officiating_coach" />
                                        <Label check className="ml-2" for="national_officiating_coach">
                                            <Translate id='components.assignOfficiatingPermissionsModal.isNational' />
                                        </Label>
                                    </div>
                                </CanDoAction>
                            </div>
                            <Collapse isOpen={formik.status.isVisible}>
                                <div className="pt-3">
                                    <Alert color="danger" toggle={() => formik.setStatus({ ...formik.status, isVisible: false })}>
                                        {formik.status.msg}
                                    </Alert>
                                </div>
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button disabled={isLoading || formik.isSubmitting || isValidating} onClick={() => setStep(1)} type="button" color="primary" outline className="mr-auto">
                                <Translate id="misc.previous" />
                            </Button>
                            <Button disabled={isLoading || formik.isSubmitting || isValidating} type="submit" color="primary">
                                <Translate id="misc.submit" />
                            </Button>
                        </ModalFooter>
                    </CrossFade>
                    <CrossFade isVisible={showSuccess}>
                        <ModalBody className="text-center">
                            <CustomAnimatedIcon withCircle className="text-success" size={50} icon="checkmark" />
                            <div className="h4 font-bold mt-2">
                                <Translate id="misc.success" />
                            </div>
                            <p>
                                <Translate id={`components.assignOfficiatingPermissionsModal.success.msg.${officiatingCoach ? "edit" : "create"}`} />
                            </p>
                            <div className="mb-3">
                                {Array.isArray(supervisionTypes) &&
                                    supervisionTypes.map((type) => {
                                        const hasAccess = (formik.values.supervision_type_ids || []).includes(type.supervision_type_id);
                                        return (
                                            <UserDisplay className="mb-2" card key={type.supervision_type_id}>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title>
                                                        <DisplayI18n
                                                            field="name"
                                                            i18n={type.i18n}
                                                            defaultValue={type.name}
                                                        />
                                                    </UserDisplay.Title>
                                                    <UserDisplay.Subtitle>
                                                        {hasAccess ?
                                                            <>
                                                                <i className="mdi mdi-check text-primary mr-1" /><Translate id='components.assignOfficiatingPermissionsModal.canSupervise' />
                                                            </>
                                                            :
                                                            <>
                                                                <i className="mdi mdi-close text-danger mr-1" /><Translate id='components.assignOfficiatingPermissionsModal.cannotSupervise' />
                                                            </>
                                                        }
                                                    </UserDisplay.Subtitle>
                                                </UserDisplay.Container>
                                                <UserDisplay.Container className="ml-auto">
                                                    <div className="border bg-light rounded-circle d-flex justify-content-center align-items-center" style={{ width: 38, height: 38 }}>
                                                        <i className={stringBuilder("mdi font-20 text-body", hasAccess ? "mdi-account-edit" : "mdi-account-off")} />
                                                    </div>
                                                </UserDisplay.Container>
                                            </UserDisplay>
                                        )
                                    })
                                }
                            </div>
                            <Button color="primary" type="button" onClick={toggle}>
                                <Translate id="misc.close" />
                            </Button>
                        </ModalBody>
                    </CrossFade>
                </Form>
            )}
        </Formik>
    );
}

export default AssignPermissions;