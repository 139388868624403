import { useIntl } from "react-intl";
import AnalyticsModal from "../../analytics/AnalyticsModal";
import { renderInWindow } from "../../views/reports/registrationForm/NewWindowOpener";
import { useContext } from "react";
import { I18nContext } from "../../contexts/I18nContext";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";
import { stringBuilder } from "@spordle/helpers";
import { Button, Card, CardHeader, ModalBody } from "reactstrap";
import UserImg from "../UserImg";
import Translate from "@spordle/intl-elements";
import MemberNumberPrintableCard from "./MemberNumberPrintableCard";
import { QRCode } from "react-qrcode-logo";

const MemberNumberModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal
            isOpen={isOpen}
            analyticsName="memberNumberModal"
            className="min-h-100 d-flex flex-center flex-column m-0 m-sm-auto"
            contentClassName="w-100 w-sm-auto mx-auto bg-transparent border-0 shadow-lg min-h-100 flex-grow-1 flex-sm-grow-0 h-md-auto align-self-stretch d-flex"
        >
            <MemberNumberModalInner {...props} />
        </AnalyticsModal>
    );
}

const MemberNumberModalInner = ({ toggle, member, className }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { formatMessage } = useIntl();
    const { federation } = useContext(IdentityRolesContext);

    const handleOnPrint = () => {
        renderInWindow(
            <div className="p-3">
                <MemberNumberPrintableCard member={member} federation={federation} locale={getGenericLocale()} />
            </div>,
            formatMessage({ id: "members.profile.overview.card" }),
            true,
        );
    }

    return (
        <Card
            style={{
                minWidth: 270,
            }}
            className={stringBuilder("mb-0", className)}
        >
            <CardHeader className="d-flex align-items-center"><Translate id="members.profile.overview.card" /> {toggle && <button className="reset-btn mdi ml-auto mdi-close" type="button" onClick={toggle} />}</CardHeader>
            <ModalBody className="d-flex flex-column">
                <div className="d-flex align-items-center flex-grow-1 w-100 pb-3">
                    <UserImg
                        abbr={member.first_name}
                        src={member.picture?.full_path}
                        filePos={member.picture?.file_position}
                        alt={`${member.first_name} ${member.last_name}`}
                        placeholder="/no-profile.png"
                        className="flex-shrink-0 mr-3"
                        width={60}
                    />
                    <div className="w-100">
                        <div className="font-medium">{member.first_name} {member.last_name}</div>
                        <div>{member.unique_identifier}</div>
                        <div className="small text-muted"><Translate id="misc.yearsOld" values={{ age: member.age }} /></div>
                    </div>
                </div>
                <div className="text-center border-top pt-3">
                    <QRCode
                        qrStyle="dots"
                        eyeRadius={{
                            inner: 1000,
                            outer: 8,
                        }}
                        size={170}
                        value={member.unique_identifier}
                    />
                    {member.picture_expiration_date && <div className="text-muted small">Exp.: {member.picture_expiration_date}</div>}
                </div>
                <Button onClick={handleOnPrint} type="button" color="primary" block size="sm" className="mt-3 mdi mdi-printer with-icon">
                    <Translate id="misc.print" />
                </Button>
            </ModalBody>
        </Card>
    )
}

export default MemberNumberModal;