import Translate from "@spordle/intl-elements"
import { Button, Collapse, ListGroup, ListGroupItem, ModalBody, ModalFooter } from "reactstrap"
import CollapsibleCard from "../../../../../components/collapsibleCard/CollapsibleCard"
import PerfectScrollbar from 'react-perfect-scrollbar';
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { useState } from "react";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import CustomAlert from "../../../../../components/CustomAlert";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import SignWaiversModalHeader from './SignWaiversModalHeader'

const SignWaiversModalReview = ({ toggle, teamMemberWaivers, previous, next, signWaivers, ...props }) => {

    const hasWaivers = teamMemberWaivers.filter((teamMemberWaiver) => teamMemberWaiver.waivers?.length > 0 && !!teamMemberWaiver.teamMember.member_type);
    const hasNoWaivers = teamMemberWaivers.filter((teamMemberWaiver) => !teamMemberWaiver.waivers || teamMemberWaiver.waivers?.length == 0 || !teamMemberWaiver.teamMember.member_type);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState(false);

    const confirm = () => {
        setIsLoading(true);
        signWaivers()
            .then(() => {
                setIsLoading(false);
                next();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error);
                    setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                    setIsLoading(false);
                }
            })
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <SignWaiversModalHeader toggle={toggle} />
            <ModalBody>
                <CollapsibleCard
                    title={<Translate id='teams.profile.header.signWaivers.hasWaivers' />}
                    arrowIsRight
                    className="mb-3"
                >
                    <div style={{ maxHeight: '300px' }} className='overflow-hidden d-flex flex-column'>
                        <PerfectScrollbar>
                            <ListGroup>
                                {hasWaivers.map((teamMemberWaiver) => (
                                    <ListGroupItem key={teamMemberWaiver.teamMember.team_member_id} className='d-flex align-items-center'>
                                        <MemberName teamMemberWaiver={teamMemberWaiver} />
                                        <div className="ml-auto">
                                            <Translate id='teams.profile.header.signWaivers.waivers' values={{ amount: teamMemberWaiver.waivers.length }} />
                                        </div>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </PerfectScrollbar>
                    </div>
                </CollapsibleCard>

                {hasNoWaivers.length > 0 &&
                    <CollapsibleCard
                        title={<Translate id='teams.profile.header.signWaivers.hasNoWaivers' />}
                        arrowIsRight
                        className="mb-3"
                    >
                        <div style={{ maxHeight: '300px' }} className='overflow-hidden d-flex flex-column'>
                            <PerfectScrollbar>
                                <ListGroup>
                                    {hasNoWaivers.map((teamMemberWaiver) => (
                                        <ListGroupItem key={teamMemberWaiver.teamMember.team_member_id}>
                                            <MemberName teamMemberWaiver={teamMemberWaiver} />
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </PerfectScrollbar>
                        </div>
                    </CollapsibleCard>
                }

                <Collapse isOpen={!!error} appear mountOnEnter unmountOnExit>
                    {error &&
                        <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={error} translateText={false} toggle={() => setError()} />
                    }
                </Collapse>
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button
                    color='primary' outline
                    type='button'
                    onClick={previous}
                >
                    <Translate id='misc.previous' />
                </Button>

                <div>
                    <Button
                        color='primary'
                        type='button'
                        onClick={confirm}
                        className='mr-2'
                    >
                        <Translate id='teams.profile.header.signWaivers.sign' />
                    </Button>
                    <Button
                        color='primary' outline
                        type='button'
                        onClick={toggle}
                    >
                        <Translate id='misc.cancel' />
                    </Button>
                </div>
            </ModalFooter>
        </OverlayLoader>
    )
}

const MemberName = ({ teamMemberWaiver }) => (
    <div>
        <div className="line-height-1 text-dark font-medium">
            {teamMemberWaiver.teamMember.member.first_name} {teamMemberWaiver.teamMember.member.last_name}
        </div>
        <span className="small text-muted">#{teamMemberWaiver.teamMember.member.unique_identifier}</span>
    </div>
)

export default SignWaiversModalReview