import React, { useContext } from 'react';
import { IdentityRolesContext } from '../../contexts/IdentityRolesContext';
import { RolesContext } from '../../contexts/RolesContext';
import IsGod from './IsGod';

/**
 * JSX implementation of RolesContext.canDoAction
 * @param {object} props
 * @param {React.ReactNode|Function} props.children
 * @param {'READ'|'ADD'|'EDIT'|'DELETE'|'IMPORT'|'EXPORT'} props.action
 * @param {string} props.componentCode
 * @param {string} props.componentPermissionCode
 * @param {boolean} skipAccess Bypass the "hasAccessTo" that checks if a READ is present. Used for componentPermissionCode that have no READ option. EX: link_suspension_maltreatment_claim, members_outstanding_balance
 * @returns
 */
const CanDoAction = ({ action, componentCode, componentPermissionCode, skipAccess, children, tag: Tag, ...props }) => {
    const roleContext = useContext(RolesContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    if(roleContext.canDoAction(action, componentCode, componentPermissionCode, skipAccess)){
        return <Tag {...props}>{typeof children === 'function' ? children(false) : children}</Tag>;
    }else if(identityRolesContext.isGod() && children){
        return (
            <IsGod {...props} tag={Tag} action={action} componentCode={componentCode} componentPermissionCode={componentPermissionCode}>
                {typeof children === 'function' ? children(true) : React.Children.map(children, (child) => {
                    if(React.isValidElement(child)){
                        return React.cloneElement(child, {
                            godAccess: true,
                        });
                    }
                    return child;
                })}
            </IsGod>
        );
    }// Don't show
    return null;
}

CanDoAction.defaultProps = {
    tag: 'span',
    skipAccess: false,
}

export default CanDoAction;