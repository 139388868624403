import TabRoutesNavs from "../../../components/subSidebar/TabRoutesNavs";
import TabRoutesPanes from "../../../components/subSidebar/TabRoutesPanes";
import { Link } from "react-router-dom"
import Translate from "@spordle/intl-elements";

const SubRouteSettingWrapper = ({ routes, back }) => {
    return (
        <>
            <div className="mb-3">
                <Link className="font-medium" to={`/settings/${back}`}>
                    <i className="mdi mdi-arrow-left mr-1" />
                    <Translate id='settings.tabs.organization.back' />
                </Link>
            </div>
            <TabRoutesNavs routes={routes} />
            <TabRoutesPanes routes={routes} />
        </>
    );
}

export default SubRouteSettingWrapper;