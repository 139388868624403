import { useContext, useEffect, useState } from "react";
import Translate from "@spordle/intl-elements";
import { useHistory } from "react-router-dom";
import {
    ListGroup,
    ListGroupItem
} from "reactstrap";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import { DisplayCategory } from "../../../TeamHelpers";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { displayI18n, DisplayI18n } from "../../../../../helpers/i18nHelper";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { stringBuilder } from "@spordle/helpers";
import MemberTeamStatus from '../../../../../views/members/profile/teams/MemberTeamStatus';
import moment from 'moment';

// contexts
import { MembersContext } from "../../../../../contexts/MembersContext";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { TeamsContext } from "../../../../../contexts/TeamsContext";
import { fail } from "@spordle/toasts";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { Tooltip } from "@mantine/core";

const TeamHistorySidepanel = ({ teamMember, sidePanelProps, isMemberSearch }) => {
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);
    const teamsContext = useContext(TeamsContext);
    const organizationContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext)

    const [ teams, setTeams ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if(!sidePanelProps.hideTeamsTab){ // don't make org tree call if we don't display the teams tab in the member search sidepanel
            setIsLoading(true);

            return Promise.all([
                organizationContext.getOrganizationsTree(),
                membersContext.getMemberTeams(teamMember.member.member_id),
            ])
                .then(([ orgTree, memberTeams ]) => {
                    //Flatten
                    const orgTreeFlatten = orgTree.flattenArray('sub_organisations', (org) => ({
                        id: org?.organisation_id,
                    }))

                    setTeams(memberTeams
                        .map((team) => ({
                            ...team,
                            hasAccess: organizationContext.organisation_id === identityRolesContext.federation?.organisation_id || organizationContext.organisation_id === team.team.organisation.organisation_id || orgTreeFlatten.some((org) => org.id == team.team?.organisation?.organisation_id),
                        }))
                        .sort((teamA, teamB) => {
                            const periodA = displayI18n('name', teamA.team.period.i18n, teamA.team.period.name, i18nContext.getGenericLocale())
                            const periodB = displayI18n('name', teamB.team.period.i18n, teamB.team.period.name, i18nContext.getGenericLocale())
                            return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(periodA, periodB) * -1
                        }));

                    setIsLoading(false);
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        setTeams(false);
                        setIsLoading(false);
                    }
                })
        }
    }, [ teamMember.team_member_id, teamMember.member.member_id ]);

    const teamOnClick = (team) => {
        if(team.team.team_id !== teamsContext.cachedTeam.team_id && team.hasAccess){
            sidePanelProps.forceCloseSidePanel();
            sidePanelProps.toggle();
            history.push(`/teams/profile/${team.team.team_id}`)
        }
    }

    const displayTeams = () => {
        if(teams === false){ // error
            return <Translate id='misc.error' />
        }else if(teams && Array.isArray(teams)){
            if(teams.length > 0){
                return (
                    <div>
                        <ListGroup className="rounded-lg border overflow-hidden">
                            {teams.map((team, index) => (
                                <ListGroupItem className={stringBuilder("p-3", index === 0 ? "border-0" : "border-left-0 border-right-0 border-bottom-0")} key={team.team.team_id + team.position.position_id}>
                                    <UserDisplay className={stringBuilder({ 'clickable': team.team.team_id !== teamsContext.cachedTeam.team_id && team.hasAccess })} onClick={() => teamOnClick(team)}>
                                        <UserDisplay.Container>
                                            <UserImg
                                                abbr={team.team.abbreviation || team.team.short_name || team.team.name}
                                                src={team.team.logo?.full_path}
                                                filePos={team.team.logo?.file_position}
                                                alt={team.team.name}
                                                width={45}
                                                className="shadow-sm"
                                            />
                                        </UserDisplay.Container>
                                        <UserDisplay.Container className="w-100">
                                            <div className='text-nowrap mb-1 font-12 text-body'>
                                                <DisplayI18n field='name' defaultValue={team.team.period.name} i18n={team.team.period.i18n} />
                                            </div>
                                            <UserDisplay.Title className="mb-1" style={{ lineHeight: 1 }}>
                                                <TeamNameDisplay team={team} />
                                            </UserDisplay.Title>
                                            {/* <Link to="/team/profile">{team.team_id}</Link> */}
                                            <UserDisplay.Subtitle>
                                                {team.team.division ?
                                                    team.team.division
                                                    :
                                                    <div className='d-flex'>
                                                        <DisplayCategory category={team.team.team_category} short />
                                                    </div>
                                                }
                                            </UserDisplay.Subtitle>
                                            <UserDisplay.Subtitle>
                                                {team.position &&
                                                    <div className='d-flex'>
                                                        <span className="font-medium"><DisplayI18n field='name' defaultValue={team.position.position_group?.name} i18n={team.position.position_group?.i18n} /></span>: <DisplayI18n field='name' defaultValue={team.position.name} i18n={team.position.i18n} />
                                                    </div>
                                                }
                                            </UserDisplay.Subtitle>
                                            <div className='d-flex w-100'>
                                                <div className='d-block w-100'>
                                                    {team.release_date &&
                                                        <UserDisplay.Subtitle>
                                                            <span className="font-medium"><Translate id='members.profile.teams.released' /></span>: {moment(team.release_date).format('YYYY-MM-DD')}
                                                        </UserDisplay.Subtitle>
                                                    }
                                                </div>
                                                <div className='d-block text-right w-100'>
                                                    {team.affiliate == 1 &&
                                                        <MemberTeamStatus className="teamRoster-status mr-2" tip="teams.profile.roster.table.affiliated">
                                                            <Translate id='teams.profile.roster.table.affiliated.short' />
                                                        </MemberTeamStatus>
                                                    }
                                                </div>
                                            </div>
                                        </UserDisplay.Container>
                                    </UserDisplay>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </div>
                )
            } // should never happen
            return <Translate id='teams.profile.roster.sidePanel.teamHistory.none' />

        }
        return null

    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <div className={`${isMemberSearch ? 'h4' : 'h5'} font-bold mb-3`}>
                <Translate id='teams.profile.roster.sidePanel.teamHistory.memberTeams' />
            </div>
            <div className="mb-3">
                {displayTeams()}
            </div>
        </OverlayLoader>
    );
}

const TeamNameDisplay = ({ team }) => {
    return !!team.member_primary_teams?.find((primaryTeam) => primaryTeam.active == 1) == 1 ?
        <Tooltip withArrow label={<Translate id='members.profile.teams.primaryTeam' />}>
            <div>{team.team.name}<i className={`pl-1 mdi mdi-star text-warning`} /></div>
        </Tooltip>
        :
        <div>{team.team.name}</div>;
}

export default TeamHistorySidepanel