import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { useState } from 'react';
import {
    Card
} from "reactstrap";
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import TransferSettingsForm from './TransferSettingsForm';
import TransferSettingsView from './TransferSettingsView';

const TransferSettings = () => {
    const [ isEditing, setIsEditing ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    return (
        <OverlayLoader isLoading={isLoading}>
            <Card body className="card-shadow">
                <CardSectionTitle title='settings.transfer.title' className='d-flex'>
                    {!isEditing &&
                        <div className='ml-auto text-link'>
                            <button onClick={() => setIsEditing(true)} className={stringBuilder("reset-btn align-self-end")} type='button'><Translate id='misc.edit' /></button>
                        </div>
                    }
                </CardSectionTitle>
                {isEditing ?
                    <TransferSettingsForm isLoading={isLoading} setIsLoading={setIsLoading} setIsEditing={setIsEditing} />
                    :
                    <TransferSettingsView />
                }
            </Card>
        </OverlayLoader>
    );
}

export default TransferSettings;