export default{
    "organization.settings.organizationPeriods.table.startDate"     : "Date de début",
    "organization.settings.organizationPeriods.table.endDate"       : "Date de fin",
    "organization.settings.organizationPeriods.table.active"        : "Actif",
    "organization.settings.organizationPeriods.table.display_order" : "Ordre d'affichage",
    "organization.settings.organizationPeriods.table.empty"         : "Aucune période",

    // add
    "organization.settings.organizationPeriods.add.title"                  : "Ajouter une période",
    "organization.settings.organizationPeriods.add.startDate"              : "Date de début",
    "organization.settings.organizationPeriods.add.startDate.format"       : "La date de début doit être d'un format valide",
    "organization.settings.organizationPeriods.add.startDate.required"     : "La date de début est requise",
    "organization.settings.organizationPeriods.add.startDate.before"       : "La date de début doit être avant la date de fin",
    "organization.settings.organizationPeriods.add.endDate"                : "Date de fin",
    "organization.settings.organizationPeriods.add.endDate.format"         : "La date de fin doit être d'un format valide",
    "organization.settings.organizationPeriods.add.endDate.required"       : "La date de fin est requise",
    "organization.settings.organizationPeriods.add.sharedDate"             : "Date partagée",
    "organization.settings.organizationPeriods.add.sharedDate.format"      : "La date partagée doit être d'un format valide",
    "organization.settings.organizationPeriods.add.calculationDate"        : "Date de calcul",
    "organization.settings.organizationPeriods.add.calculationDate.format" : "La date de calcul doit être d'un format valide",
    "organization.settings.organizationPeriods.add.status"                 : "Statut",
    "organization.settings.organizationPeriods.add.status.active"          : "Actif",
    "organization.settings.organizationPeriods.add.status.inactive"        : "Inactif",
    "organization.settings.organizationPeriods.add.status.required"        : "Le Statut est requis",
}