import { stringBuilder } from '@spordle/helpers'
import { Fragment, memo } from 'react';
import styles from './FileViewer.module.scss';

const TextFiles = ({ fileData }) => {
    return (
        <code className={stringBuilder("mt-5 p-3 bg-white text-dark", styles.PreviewText)}>
            {fileData.map((line, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                    <span
                        className="mr-1 bg-light px-2 py-1 d-inline-block text-right line-height-1"
                        style={{
                            userSelect: "none",
                            minWidth: `calc(${fileData.length.toString().length}ch + 16px)`,
                        }}
                    >
                        {index + 1}
                    </span>
                    {line}
                    <br />
                </Fragment>
            ))}
        </code>
    )
};

// Using memo because this file type can be very big and ressource hungry
export default memo(TextFiles)
