import { Card, Tooltip } from "@mantine/core";
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables";
import { groupByAsArray } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import SpordlePanelTable from "../../../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../../../components/table/EmptyLayout";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { DisplayCategory } from "../../../../../teams/TeamHelpers";
import OrganizationsSidepanel from "./OrganizationsSidepanel";

const Organizations = () => {
    const i18nContext = useContext(I18nContext);
    const orgContext = useContext(OrganizationContext);

    return (
        <Card body className="card-shadow">
            <CardSectionTitle title='organisation.profile.tabs.orgs' />

            <SpordlePanelTable
                allowOutsideClick
                sidePanel={(sPanelProps) => <OrganizationsSidepanel {...sPanelProps} />}
                dataIndex='key'
                table={(panelProps) => {
                    return (
                        <SpordleTableProvider
                            id={'organization-groupings-leagues-orgs'}
                            tableHover bordered striped
                            clickable
                            ref={panelProps.spordleTableRef}
                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                            searchKeys={[
                                'values.linked_organisation.organisation_name',
                                `values.linked_organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                            ]}
                            dataIndex='key'
                            pagination={10}
                            emptyLayout={<EmptyLayout />}
                            columns={[
                                {
                                    label: <Translate id='form.fields.name' />,
                                    key: "name",
                                    mobile: true,
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='organization.grouping.table.divisions.column' />,
                                    key: "divisions",
                                    mobile: true,
                                    dataClassName: 'text-center',
                                },
                            ]}
                            renderRow={(columnKey, data) => {
                                switch (columnKey){
                                    case 'name':
                                        return (
                                            <>
                                                <DisplayI18n
                                                    field="name"
                                                    defaultValue={data.values[0].linked_organisation.organisation_name}
                                                    i18n={data.values[0].linked_organisation.i18n}
                                                />
                                            </>
                                        )
                                    case 'divisions':
                                        const filteredValues = data.values.filter((value) => value.active == 1);
                                        const slicedValues = filteredValues.slice(0, 3);
                                        const withTooltip = filteredValues.length > 3;
                                        const renderDivision = (value) => {
                                            if(value.team_category){
                                                return <DisplayCategory category={value.team_category} short />
                                            }
                                            return <DisplayI18n field='name' defaultValue={value.division.name} i18n={value.division.i18n} />
                                        }

                                        if(withTooltip){
                                            return (
                                                <Tooltip
                                                    label={
                                                        <ul className="mb-0 mr-3">
                                                            {filteredValues.map((value) => <li key={value.affiliated_organisation_id}>{renderDivision(value)}</li>)}
                                                        </ul>
                                                    }
                                                >
                                                    {slicedValues.map((value, index) => <>{renderDivision(value)}{index + 1 < slicedValues.length && ', '}</>)}...
                                                </Tooltip>
                                            )
                                        }
                                        return filteredValues.map((value, index) => <>{renderDivision(value)}{index + 1 < filteredValues.length && ', '}</>)
                                    default: break;
                                }
                            }}
                            desktopWhen
                            onColumnClick={(e, data) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        panelProps.onSingleSelectChange(data);
                                        break;
                                }
                            }}
                            rowIsHighlighted={(data) => !!data.checked}
                            columnSorting={(groupA, groupB, _spordleTable, order) => {
                                switch (order.column){
                                    case 'name':
                                        const orgNameA = displayI18n('name', groupA.values[0].linked_organisation.i18n, groupA.values[0].linked_organisation.organisation_name, i18nContext.getGenericLocale());
                                        const orgNameB = displayI18n('name', groupB.values[0].linked_organisation.i18n, groupB.values[0].linked_organisation.organisation_name, i18nContext.getGenericLocale());
                                        return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(orgNameA, orgNameB) * (order.order === 'ASC' ? 1 : -1);
                                }
                            }}
                            loadData={(from, _, spordleTable) => {
                                switch (from){
                                    case 'REFRESH':
                                        spordleTable.setLoading();
                                    case 'CDM':
                                        return orgContext.getAffiliatedOrganizations(orgContext.organisation_id)
                                            .then((affiliatedOrgs) => {
                                                return groupByAsArray(affiliatedOrgs.map((org) => ({ ...org, orgId: org.linked_organisation.organisation_id })), 'orgId')
                                                    .filter((group) => group.values.filter((value) => value.active == 1).length > 0)
                                            })
                                    default:
                                        break;
                                }
                            }}
                        >
                            {(spordleTable) => (
                                <>
                                    <div className="d-flex justify-content-between mb-2">
                                        <SearchInput />

                                        <div className="ml-auto">
                                            <Refresh />
                                        </div>
                                    </div>

                                    <SpordleTableView />
                                </>
                            )}
                        </SpordleTableProvider>
                    )
                }}
            />
        </Card>
    )
}

export default Organizations