import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext } from 'react';
import { Col, Row } from "reactstrap";
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';

const TransferSettingsView = ({ ...props }) => {
    const orgContext = useContext(OrganizationContext);

    return (
        <Row>
            <Col sm='6'>
                <div className='text-muted'><Translate id='settings.transfer.expiryDate' /></div>
                {orgContext.settings.default_share_expiration_date?.value ?
                    <DateFormat value={orgContext.settings.default_share_expiration_date?.value} />
                    :
                    '-'
                }
            </Col>
        </Row>
    );
}

export default TransferSettingsView;