import Translate, { getTranslation } from "@spordle/intl-elements";
import { useContext } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CollapsibleCard from "../../../../../../../components/collapsibleCard/CollapsibleCard";
import CustomAnimatedIcon from "../../../../../../../components/customAnimatedIcon/CustomAnimatedIcon";
import { ClinicsContext } from "../../../../../../../contexts/ClinicsContext";
import { I18nContext } from "../../../../../../../contexts/I18nContext";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { clinicQualificationBuilder } from "../../../../../clinicHelpers";
import CancelAttendeeCard from "./CancelAttendeeCard";

const ConfirmStep = ({ toggle, attendeesLength, feedback, sendMessage }) => {
    const { currentClinic } = useContext(ClinicsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const translatedSubject = getTranslation('clinics.profile.attendees.sidepanel.actions.cancelAttendee.mail.subject', getGenericLocale());
    const translatedTitle = getTranslation('clinics.profile.attendees.sidepanel.actions.cancelAttendee.mail.title', getGenericLocale());
    const clinicName = clinicQualificationBuilder(currentClinic.qualification, getGenericLocale());
    const { failed, success, cancelledAt } = feedback;

    const handleOnSendComm = () => {
        sendMessage(
            <>
                <h3>{translatedTitle}</h3>
                <b>#{currentClinic.clinic_reference}</b> | {clinicName}
                {feedback.reason && <p>{feedback.reason}</p>}
            </>,
            translatedSubject,
            success,
        );
        toggle();
    }

    return (
        <>
            <ModalHeader toggle={toggle}><Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee.review.title' /></ModalHeader>
            <ModalBody>
                {attendeesLength > 1 ?
                    <>
                        <div className="font-medium h5 text-center mb-3">
                            <Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee.review.subtitle' values={{ count: attendeesLength }} />
                        </div>
                        {success.length > 0 &&
                            <CollapsibleCard
                                title={
                                    <div className="d-flex align-items-center">
                                        <div className="mr-3">
                                            <CustomAnimatedIcon
                                                strokeWidth="6px"
                                                className="text-success"
                                                withCircle
                                                size={20}
                                                id="successCancelAttendees"
                                                icon="checkmark"
                                            />
                                        </div>
                                        <Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee.success.title' values={{ count: success.length }} />
                                    </div>
                                }
                                arrowIsRight
                                initOpen={success.length <= 3}
                            >
                                {success.map((attendee, index) => (
                                    <CancelAttendeeCard
                                        key={`successfullyCancelledAttendee-${attendee.clinic_attendee_id}`}
                                        attendee={attendee}
                                        className={index === success.length - 1 ? "mb-0" : "mb-2"}
                                        subtitle={<Translate id='clinics.profile.attendees.sidepanel.header.cancelledAt' values={{ date: cancelledAt }} />}
                                    />
                                ))}
                            </CollapsibleCard>
                        }
                        {failed.length > 0 &&
                            <CollapsibleCard
                                title={
                                    <div className="d-flex align-items-center">
                                        <div className="ml-3">
                                            <CustomAnimatedIcon
                                                strokeWidth="6px"
                                                className="text-danger"
                                                withCircle
                                                size={20}
                                                id="failedCancelAttendee"
                                                icon="error"
                                            />
                                        </div>
                                        <Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee.fail.title' values={{ count: failed.length }} />
                                    </div>
                                }
                                arrowIsRight
                                error
                            >
                                {failed.map((attendee, index) => (
                                    <CancelAttendeeCard
                                        key={`failedCancelAttendee-${attendee.clinic_attendee_id}`}
                                        attendee={attendee}
                                        error={<DisplayI18n field="message" defaultValue={attendee.failReason?.message} i18n={attendee.failReason?.i18n} />}
                                        className={index === failed.length - 1 ? "mb-0" : "mb-2"}
                                    />
                                ))}
                            </CollapsibleCard>
                        }
                    </>
                    :
                    attendeesLength > 0 &&
                        <>
                            {feedback.warnings?.length > 0 ?
                                <div className="text-center">
                                    <CustomAnimatedIcon
                                        strokeWidth="6px"
                                        className="text-danger mb-3"
                                        withCircle
                                        size={50}
                                        id="failedCancelAttendee"
                                        icon="error"
                                    />
                                    <span className="font-medium h4">
                                        <Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee.fail.single.title' />
                                    </span>
                                    <p><DisplayI18n defaultValue={feedback.warnings[0].message} field="message" i18n={feedback.warnings[0].i18n} /></p>
                                </div>
                                :
                                <div className="text-center mb-3">
                                    <CustomAnimatedIcon
                                        strokeWidth="6px"
                                        className="text-success mb-3"
                                        withCircle
                                        size={50}
                                        id="failedCancelAttendee"
                                        icon="checkmark"
                                    />
                                    <span className="font-medium h4">
                                        <Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee.success.single.title' />
                                    </span>
                                </div>
                            }
                            {(success[0] || failed[0]) && <CancelAttendeeCard attendee={success[0] || failed[0]} subtitle={success[0] && <Translate id='clinics.profile.attendees.sidepanel.header.cancelledAt' values={{ date: cancelledAt }} />} />}
                        </>
                }
            </ModalBody>
            <ModalFooter>
                {success.length > 0 &&
                    <Button onClick={handleOnSendComm} type="button" color="primary">
                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.sendCommunication' />
                    </Button>
                }
                <Button onClick={toggle} type="button" color="primary" outline>
                    <Translate id="misc.close" />
                </Button>
            </ModalFooter>
        </>
    );
}

export default ConfirmStep;