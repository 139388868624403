import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Collapse
} from "reactstrap";
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { PositionsContext } from '../../../../../contexts/PositionsContext';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import styles from './teamCards.module.scss';
import { stringBuilder } from '@spordle/helpers';
import { useDisclosure } from '@mantine/hooks';
import Skeleton from 'react-loading-skeleton';

const TeamCards = ({ team, teamId, column, minimal }) => {
    const positionsContext = useContext(PositionsContext)

    const [ isLoading, setIsLoading ] = useState(true);
    const [ positions, setPositions ] = useState(null);

    const [ isOpen, isOpenHandlers ] = useDisclosure(false);

    useEffect(() => {
        if(team?.organisation?.organisation_id && team.team_id === teamId){ // teamId -> because cached team could not be updated yet, caused 403
            positionsContext.getPositions(team?.organisation?.organisation_id)
                .then(setPositions)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                    }
                })
        }
    }, [ team?.organisation?.organisation_id, team?.team_id ])

    useEffect(() => {
        if(team?.team_counts && positions){
            setIsLoading(false)
        }
    }, [ team?.team_counts, positions ])

    return (
        <>
            {isLoading ?
                column ?
                    <>
                        <Skeleton width='100px' />
                        <Row form>
                            <Col sm='12' lg='12'>
                                <Skeleton height='159px' />
                            </Col>
                        </Row>
                        <Skeleton width='100px' />
                        <Row form>
                            <Col sm='12' lg='12'>
                                <Skeleton height='159px' />
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <Skeleton width='100px' />
                        <Row form>
                            <Col sm='12' lg='6'>
                                <Skeleton height='159px' />
                            </Col>
                            <Col sm='12' lg='6'>
                                <Skeleton height='159px' />
                            </Col>
                        </Row>
                    </>
                :
                <Row form className='mb-3'>
                    {team?.team_counts &&
                            Object.keys(team.team_counts).map((cardKey, index) => (
                                <TeamCard
                                    key={cardKey}
                                    cardKey={cardKey}
                                    minimal={minimal} // only show total
                                    column={column} // have the cards always on top of eachother and have them control their own collapse
                                    collapseHandlers={column ? undefined : { ...isOpenHandlers, isOpen: isOpen }}
                                    index={index}
                                    team={team}
                                    positions={positions}
                                />
                            ))
                    }
                </Row>
            }
        </>
    );
}

const TeamCard = ({ team, positions, cardKey, minimal, column, collapseHandlers, index }) => {
    const [ isOpen, isOpenHandlers ] = useDisclosure(false)
    const toggle = () => {
        if(collapseHandlers)
            collapseHandlers.toggle()
        else
            isOpenHandlers.toggle()
    }
    const collapseIsOpen = () => {
        if(collapseRows.length == 0)
            return true
        if(collapseHandlers)
            return collapseHandlers.isOpen

        return isOpen
    }

    const numberOfRows = 3; // change this to show more rows when collapsed

    const cardCounts = team.team_counts[cardKey]

    const columns = Object.keys(cardCounts[Object.keys(cardCounts)[0]]).filter((columnKey) => minimal ? columnKey === 'TOTAL' : true)
    const { shownRows, collapseRows } = Object.keys(cardCounts).reduce(({ shownRows, collapseRows }, rowKey, index) => {
        if(index < numberOfRows){
            shownRows.push(rowKey)
        }else{
            collapseRows.push(rowKey)
        }
        return { shownRows: shownRows, collapseRows: collapseRows }
    }, { shownRows: [], collapseRows: [] })

    const displayButton = () => {
        const button = (
            <button className='reset-btn text-link' type='button' onClick={() => toggle()}>
                <Translate id={collapseIsOpen() ? 'teams.profile.header.cards.showLess' : 'teams.profile.header.cards.showMore'} />
                <i className={stringBuilder("ml-1 d-inline-block mdi", { "mdi-chevron-down": !collapseIsOpen(), "mdi-chevron-up": collapseIsOpen() })} />
            </button>
        )
        // empty button to take the same space above the card when no button needs to be shown
        // could've probably done the same thing with margins but i feel like this is a better solution
        const emptyButton = (
            <button className='reset-btn text-link' type='button' onClick={() => {}}>
                &nbsp;
            </button>
        )

        if(collapseHandlers){
            if(index == 0){
                return button
            }
            return emptyButton
        }else if(collapseRows.length > 0){
            return button
        }
    }

    return (
        <Col className='mb-3 d-flex flex-column min-h-0' sm='12' lg={column ? '12' : '6'} key={cardKey}>
            <div>
                {displayButton()}
            </div>
            <Card className={stringBuilder("card-shadow mb-0 flex-grow-1")}>
                <OverlayLoader isLoading={!positions}>
                    <CardBody>
                        <div className={stringBuilder(styles.container, { [styles.isExpanded]: collapseIsOpen() })}>
                            <div>
                                <Row form>
                                    <Col sm='5' className={stringBuilder(styles.columnTitle)}>
                                        <Translate id={'teams.profile.header.cards.' + cardKey} defaultMessage='-' />
                                    </Col>

                                    <Col sm='7'>
                                        <div className={stringBuilder(styles.columnTitle, styles.innerRow, 'justify-content-end')}>
                                            {columns.map((columnKey) => {
                                                return (
                                                    <span key={columnKey} className={(stringBuilder(styles.cell, { [styles["innerRow-col"]]: !minimal }))}>
                                                        <Translate id={"teams.profile.header.cards.columns." + columnKey} />
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {shownRows.map((rowKey) => (
                                <DisplayRow minimal={minimal} cardCounts={cardCounts} positions={positions} key={rowKey} rowKey={rowKey} />
                            ))}
                            <Collapse isOpen={collapseIsOpen()}>
                                {collapseRows.map((rowKey) => (
                                    <DisplayRow minimal={minimal} cardCounts={cardCounts} positions={positions} key={rowKey} rowKey={rowKey} />
                                ))}
                            </Collapse>
                        </div>
                    </CardBody>
                </OverlayLoader>
            </Card>
        </Col>
    )
}

const DisplayRow = ({ positions, rowKey, index, cardCounts, minimal }) => {
    const foundPosition = positions?.find((p) => p.position_id === rowKey);
    return (
        <div>
            <Row form className={styles.row}>
                <Col sm='5' className={stringBuilder(styles.rowTitle, 'text-truncate')}>
                    <div className='text-truncate'>
                        {foundPosition ?
                            <DisplayI18n field='name' defaultValue={foundPosition.name} i18n={foundPosition.i18n} />
                            :
                            <Translate id={'teams.profile.header.cards.rows.' + rowKey} />
                        }
                    </div>
                </Col>
                <Col sm='7'>
                    <div className={stringBuilder(styles.innerRow, 'justify-content-end')}>
                        {Object.keys(cardCounts[rowKey]).reduce((newArray, columnKey) => {
                            if(minimal){
                                if(columnKey === 'TOTAL'){
                                    newArray.push(
                                        <span key={columnKey} className={(stringBuilder(styles.cell, styles["innerRow-col"]))}>
                                            {cardCounts[rowKey][columnKey]}
                                        </span>,
                                    )
                                }
                                return newArray
                            }
                            newArray.push(
                                <span key={columnKey} className={(stringBuilder(styles.cell, styles["innerRow-col"]))}>
                                    {cardCounts[rowKey][columnKey]}
                                </span>,
                            )
                            return newArray
                        }, [])}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default TeamCards;