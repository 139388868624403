import { useRef, useState, useContext, useEffect } from "react";
import {
    Card,
    Row,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown
} from 'reactstrap';

import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate from "@spordle/intl-elements";

import SidePanelUsers from './SidePanelUsers';
import AddUserModal from './AddUserModal';
import SpordlePanelTable from "../../../../components/sidePanel/SpordlePanel";
import moment from 'moment';
import queryString from 'query-string';

// Contexts
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { AuthContext } from '../../../../contexts/contexts';
import { RolesContext } from "../../../../contexts/RolesContext";
import EmptyLayout from "../../../../components/table/EmptyLayout";
import { Link, useHistory, useLocation } from "react-router-dom";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import OpenSidePanelOnSearch from "../../../../components/sidePanel/OpenSidePanelOnSearch";

const OrganizationProfileUsers = (props) => {
    const [ addUserModalOpen, setAddUserModalOpen ] = useState(false);
    const tableRef = useRef(null);
    const location = useLocation();
    const history = useHistory();

    // Contexts
    const identityRolesContext = useContext(IdentityRolesContext);
    const organizationContext = useContext(OrganizationContext);
    const authContext = useContext(AuthContext);
    const rolesContext = useContext(RolesContext);

    const toggleAddUserModal = () => {
        setAddUserModalOpen(!addUserModalOpen);
    }

    useEffect(() => {
        if(location.state?.refresh && tableRef.current){
            history.push({ hash: location.hash, state: null });
            tableRef.current.refreshTable();
        }
    }, [ location.hash ]);

    return (
        <>
            <AddUserModal isOpen={addUserModalOpen} toggle={toggleAddUserModal} tableRef={tableRef} />

            <Card body className="card-shadow">
                <div className="h4 font-bold pb-1 border-bottom mb-3 card-title"><Translate id='organisation.profile.tabs.users' /></div>
                <SpordlePanelTable
                    allowOutsideClick
                    sidePanel={(props) => <SidePanelUsers {...props} tableRef={tableRef} authContext={authContext} identityRolesContext={identityRolesContext} rolesContext={rolesContext} organizationContext={organizationContext} />}
                    dataIndex='identity_role_id'
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='org-users'
                                ref={(r) => { tableRef.current = r; panelProps.spordleTableRef(r); }}
                                tableHover bordered striped
                                clickable
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                searchKeys={[
                                    'user.identity_id',
                                    'user.name',
                                    'user.family_name',
                                    'user.email',
                                    'role',
                                ]}
                                emptyLayout={<EmptyLayout />}
                                dataIndex='identity_role_id'
                                defaultSorting='+name'
                                columns={[
                                    {
                                        label: <Translate id='organization.profile.users.table.name' />,
                                        key: "name",
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='organization.profile.users.table.email' />,
                                        key: "email",
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='organization.profile.users.table.role' />,
                                        key: "role",
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='organization.profile.users.table.status' />,
                                        key: "active",
                                        mobile: true,
                                        sortable: true,
                                    },
                                ]}
                                desktopWhen
                                pagination={10}
                                onColumnClick={(e, user) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            panelProps.onSingleSelectChange(user);
                                            break;
                                    }
                                }}
                                renderRow={(columnKey, identityRole, spordleTable) => {
                                    switch (columnKey){
                                        case 'name':
                                            return (
                                                identityRole.name + ' ' + identityRole.family_name
                                            );
                                        case 'role':
                                            if(identityRole?.role_info?.active === '0')
                                                return (
                                                    <div>
                                                        <div>{identityRole.role}</div>
                                                        <div className="small text-warning"><i className='mdi mdi-alert-outline mr-1' /><Translate id="organization.profile.users.sidePanelUsers.userInfo.role.inactive.warning" /></div>
                                                    </div>
                                                );
                                            break;
                                        case 'active':
                                            return (
                                                <div className={spordleTable.isMobile() ? undefined : 'text-center'}>
                                                    {identityRole.signin_lock_date !== null ?
                                                        <i className="text-danger mdi mdi-lock-outline" />
                                                        : identityRole.end_date && !moment().isBefore(identityRole.end_date) ?
                                                            <span className='text-danger'><Translate id="organization.profile.users.table.status.expired" /></span>
                                                            : identityRole.active === '1' ?
                                                                <i className="text-primary mdi mdi-check" />
                                                                :
                                                                <i className="text-danger mdi mdi-close" />
                                                    }
                                                </div>
                                            )
                                        default:
                                            break;
                                    }
                                }}
                                rowIsHighlighted={(user) => !!user.checked}
                                loadData={(from, filterData, spordleTable) => {
                                    switch (from){
                                        case 'REFRESH':
                                            spordleTable.setLoading(true);
                                        case 'CDM':
                                            const search = queryString.parse(window.location.search).search
                                            if(search) // default table search
                                                spordleTable.setInputValue(search)
                                            return identityRolesContext.getIdentityRoleList(organizationContext.organisation_id)
                                                .then((data) => {
                                                    const yo = data.reduce((arr, identityRole) => {
                                                        identityRole.role_info = identityRole.role
                                                        identityRole.description = identityRole.role.description;
                                                        identityRole.role = identityRole.role.title;

                                                        arr.push({
                                                            ...identityRole,
                                                            ...identityRole.user,
                                                        })

                                                        return arr;
                                                    }, []);
                                                    return yo;
                                                })
                                        default:
                                            break;
                                    }
                                }}
                            >
                                <TopSection toggleAddUserModal={toggleAddUserModal} />
                                <SpordleTableView />
                                <OpenSidePanelOnSearch />
                            </SpordleTableProvider>
                        )
                    }}
                />
            </Card>
        </>
    );
}


const TopSection = (props) => {
    return (
        <Row className='mb-3'>
            <Col md='6'>
                <SpordleTableProvider.SearchInput />
            </Col>
            <Col md='6'>
                <div className="d-flex justify-content-end align-items-center">
                    <SpordleTableProvider.Refresh />
                    <UncontrolledButtonDropdown className='ml-2'>
                        <DropdownToggle disabled color='primary' outline caret>
                            <i className='mdi mdi-download mr-1' /><Translate id='misc.export' />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem><i className='fa fa-file-excel mr-1' /><Translate id='misc.excel' /></DropdownItem>
                            <DropdownItem><i className='fa fa-file-pdf mr-1' /><Translate id='misc.pdf' /></DropdownItem>
                            <DropdownItem><i className='mdi mdi-printer mr-1' /><Translate id='misc.print' /></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <CanDoAction tag={UncontrolledButtonDropdown} className='ml-2' action={'ADD'} componentCode={'organization'} componentPermissionCode={'organization_users'}>
                        <DropdownToggle color='primary' caret>
                            <i className='mdi mdi-plus mr-1' /><Translate id='misc.add' />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={props.toggleAddUserModal}><Translate id='misc.add' /></DropdownItem>
                            <DropdownItem tag={Link} to='add-multiple'><Translate id='misc.addMultiple' /></DropdownItem>
                        </DropdownMenu>
                    </CanDoAction>
                </div>
            </Col>
        </Row>
    );
}

export default OrganizationProfileUsers;