import { useEffect, useState } from 'react';
import {
    Row,
    Col
} from "reactstrap";
import AppealsSidepanel1General from './components/AppealsSidepanel1General';
import AppealsSidepanel1Notes from './components/AppealsSidepanel1Notes';

const AppealsSidepanel1 = (props) => {
    const [ generalLoading, setGeneralLoading ] = useState(false);
    const [ notesLoading, setNotesLoading ] = useState(false);

    useEffect(() => {
        if(!generalLoading && !notesLoading){
            props.setLoading(false) // when all components are done loading, stop the loading for the sidepanel
        }else if(generalLoading || notesLoading){
            props.setLoading(true) // if at least 1 component is loading, turn on the loading for the sidepanel
        }
    }, [ generalLoading, notesLoading ])

    return (
        <div className="p-3">
            <Row form>
                <AppealsSidepanel1General setGeneralLoading={setGeneralLoading} {...props} />

                <Col sm="12">
                    <hr className="mt-3 mb-3" />
                </Col>

                <AppealsSidepanel1Notes setNotesLoading={setNotesLoading} {...props} />
            </Row>
        </div>
    );
}

export default AppealsSidepanel1;