export default{
    "clinics.profile.attendees.sidePanelActions.certify.title" : "Certifier",

    "clinics.profile.attendees.sidePanelActions.certify.certification.label"    : "Date de certification",
    "clinics.profile.attendees.sidePanelActions.certify.certification.required" : "La date de certification est requise",
    "clinics.profile.attendees.sidePanelActions.certify.certification.before"   : "La date de certification doit être avant la date d'expiration",
    "clinics.profile.attendees.sidePanelActions.certify.certification.after"    : "La date de certification ne peut pas être avant la date de passage",

    "clinics.profile.attendees.sidePanelActions.certify.expiration.label" : "Date d'expiration",
    "clinics.profile.attendees.sidePanelActions.certify.expiration.after" : "La date d'expiration ne peut pas être avant la date de certification",

    "clinics.profile.attendees.sidePanelActions.certify.memo" : "Memo",

    "clinics.profile.attendees.sidePanelActions.certify.certificationNumber" : "Numéro de certification",
}