import { useDisclosure } from "@mantine/hooks";
import Translate from "@spordle/intl-elements";
import { useState, useContext } from "react";
import DisplayOrg from "../../../../components/DisplayOrg";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../components/sidePanel/SidePanel";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../components/UserImg";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import ReviewCompetitionRequestModal from "../../ReviewCompetitionRequest";
import MultiAffiliatedTeamsSidePanel from "./MultiAffiliatedTeamsSidePanel";
import SingleAffiliatedTeamSidePanel from "./SingleAffiliatedTeamSidePanel";
import { Link } from 'react-router-dom';
import { DisplayCategory } from "../../TeamHelpers";

const CompetitionTeamsSearchSidePanel = ({ selectedRows, tableRef }) => {
    const [ isLoading ] = useState(false);
    const [ isOpen, { open, close } ] = useDisclosure(false);
    const org = useContext(OrganizationContext);

    return (
        <OverlayLoader isLoading={isLoading}>
            <SidePanel.Header>
                <ReviewCompetitionRequestModal
                    isOpen={isOpen}
                    toggle={close}
                    teams={selectedRows}
                    onSuccess={() => tableRef.refreshTable()}
                    competitiveOrg={org}
                />
                <div className="d-flex align-items-center mb-3">
                    <SidePanel.ToggleButton className="mr-auto" />
                    <SidePanel.ActionsMenu action='EDIT' componentCode='organization' componentPermissionCode='manage_competition_teams' id='affiliatedTeamSearch-sidepanel-menu'>
                        <SidePanel.MenuAction onClick={open} id='affiliatedTeamSearch-sidepanel-action-approve'>
                            <Translate id="task.document.approval.manage.approval" />
                        </SidePanel.MenuAction>
                    </SidePanel.ActionsMenu>
                </div>
                {selectedRows.length > 1 ?
                    <SidePanel.Title>{selectedRows.length} <Translate id='teams.teamSearch.sideTable.selectedTeams' /></SidePanel.Title>
                    :
                    <UserDisplay>
                        <UserDisplay.Container>
                            <UserImg
                                width={50}
                                alt={selectedRows[0].team?.name}
                                abbr={selectedRows[0].team?.name}
                                src={selectedRows[0].team?.logo?.full_path}
                                filePos={selectedRows[0].team?.logo?.file_position}
                            />
                        </UserDisplay.Container>
                        <UserDisplay.Container>
                            <SidePanel.Subtitle>
                                <DisplayOrg org={selectedRows[0].team?.organisation} />
                            </SidePanel.Subtitle>
                            <SidePanel.Title>{selectedRows[0].team?.name}</SidePanel.Title>
                            <Link to={`/teams/profile/${selectedRows[0].team_id}`}>
                                {selectedRows[0].team?.unique_identifier ?
                                    <>
                                        #{selectedRows[0].team?.unique_identifier}
                                    </>
                                    :
                                    <>
                                        <Translate id="misc.goToProfile" /> <i className="mdi mdi-chevron-right" />
                                    </>
                                }
                            </Link>
                            <SidePanel.Subtitle>
                                <DisplayCategory category={selectedRows[0].team.team_category} />
                            </SidePanel.Subtitle>
                            {selectedRows[0].team.team_category?.gender &&
                                <SidePanel.Subtitle>
                                    <Translate id={`form.fields.gender.${selectedRows[0].team.team_category.gender.toLowerCase()}`} />
                                </SidePanel.Subtitle>
                            }
                        </UserDisplay.Container>
                    </UserDisplay>
                }
            </SidePanel.Header>
            {selectedRows.length > 1 ?
                <MultiAffiliatedTeamsSidePanel teams={selectedRows} />
                :
                <SidePanel.Body>
                    <SingleAffiliatedTeamSidePanel team={selectedRows[0]} />
                </SidePanel.Body>
            }
        </OverlayLoader>
    );
}

export default CompetitionTeamsSearchSidePanel;