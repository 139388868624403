import { Tooltip } from "@mantine/core";
import { FormikSelect, FormikSwitch } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import { Button, Collapse } from "reactstrap";
import IsAdmin from "../../../../../components/permissions/IsAdmin";
import { isVisibleInMenu } from "./RolePermissions";
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy";

/**
 * @return {boolean}
 */
export const showWarning = (formikValues) => {
    if(Array.isArray(formikValues)){ // Only select options -> visible_in_menu don't have this warning
        const activeValues = formikValues.filter((rcp) => {
            if(typeof rcp === 'string'){
                return true;
            }
            return rcp.active;

        });
        if(activeValues.length !== 0){
            return activeValues.findIndex((rcp) => { // Checks if one of the selected permissions is READ
                if(typeof rcp === 'string'){
                    return rcp.split('---')[0] === 'READ';
                }
                return rcp.action === 'READ' && rcp.active;

            }) === -1;
        }
    }
    return false;
}

const RoleListItem = ({ className, componentPermission, setSelectedPermission, moduleComponent, fromGlobalModule }) => {
    if(!componentPermission.component_permission_id)return null;
    const formik = useFormikContext();
    const formikRole = formik.values[componentPermission.component_permission_id];
    const isInMenu = isVisibleInMenu(componentPermission);
    const isDisabled = !formik.values[moduleComponent.component_permission_id];

    const toggleOtherRolesView = () => setSelectedPermission(componentPermission.component_permission_id);

    return (
        <div className={className}>
            <div className="h5 font-medium mb-1 d-flex justify-content-between align-items-center">
                {componentPermission.title}
                {!fromGlobalModule &&
                    <Tooltip zIndex={3000} withArrow label={<Translate id={'organization.settings.roles.modals.permission.bulk'} />}>
                        <Button size="sm" color='light' disabled={!isInMenu ? isDisabled : undefined} onClick={toggleOtherRolesView} type='button'>
                            <i className='mdi mdi-content-duplicate' />
                        </Button>
                    </Tooltip>
                }
            </div>
            <IsAdmin className='small text-monospace'>
                <InlineCopy toCopy={componentPermission.code}>
                    code: {componentPermission.code}
                </InlineCopy>
            </IsAdmin>
            <div className={isInMenu ? "d-flex" : "mb-3"}>
                <div className="text-muted">{componentPermission.description}</div>
                {isInMenu &&
                    <div className='d-flex ml-auto align-items-center p-1'>
                        <FormikSwitch name={componentPermission.component_permission_id} id={componentPermission.component_permission_id} />
                    </div>
                }
            </div>
            {!isInMenu &&
                <FormikSelect
                    id={componentPermission.component_permission_id}
                    name={componentPermission.component_permission_id}
                    clearable multi search={false}
                    preventSelectedReorder
                    disabled={isDisabled}
                    placeholder='organization.settings.roles.modals.select.placeholder'
                    values={formik.values[componentPermission.component_permission_id]?.reduce((newArray, permission) => {
                        if(typeof permission === 'string'){
                            newArray.push(permission);
                        }else if(permission.active){
                            newArray.push(`${permission.action}${permission?.role_component_permission_id ? `---${permission?.role_component_permission_id}` : ''}`)
                        }
                        return newArray
                    }, [])}
                    defaultData={componentPermission.action.split(',').map((componentPermissionAction) => {
                        /**
                        * This function will append the value with the role_component_permission_id if the role already exsit.
                        * This way, we can use this role_component_permission_id when we update the existing component_permission
                        * @returns {{value: string, label: string, translateLabel: boolean}}
                        */
                        const getOption = (action) => {
                            const permissionValue = formik.initialValues[componentPermission.component_permission_id].find((initialValue) => initialValue.action === action);
                            return {
                                label: 'organization.settings.roles.modals.select.option.' + action,
                                translateLabel: true,
                                value: `${action}${permissionValue?.role_component_permission_id ? `---${permissionValue?.role_component_permission_id}` : ''}`,
                            }
                        }
                        switch (componentPermissionAction){
                            case 'READ':
                            case 'ADD':
                            case 'EDIT':
                            case 'EXPORT':
                            case 'IMPORT':
                            case 'DELETE':
                                return getOption(componentPermissionAction)
                            default:
                                break;
                        }
                    })}
                    loadingStatus='success'
                />
            }
            {/* Show a warning text if there is selected roles without the READ permission */}
            <Collapse isOpen={showWarning(formikRole)} className='small mt-1 text-warning' mountOnEnter unmountOnExit>
                <i className='mdi mdi-alert-outline mr-1' />
                <Translate id='organization.settings.roles.modals.warning.availability' />
            </Collapse>
        </div>
    );
}

export default RoleListItem;