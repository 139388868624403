import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import settingsOrganizationTabsRoutes from '../../../routes/tabRoutes/settingsOrganizationTabsRoutes';
import SubRouteRenderer from '../../../components/subSidebar/SubRouteRenderer';

const OrganizationSettings = () => {
    return (
        <>
            {/* Settings View Header */}
            <ViewHeaderV2 title='settings.tabs.organizationSettings' />

            <div className="px-4">
                <SubRouteRenderer routes={settingsOrganizationTabsRoutes} />
            </div>
        </>
    );
}

export default OrganizationSettings;