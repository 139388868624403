import { Container } from "reactstrap";
import settingsTransferRoutes from "../../../../../routes/tabRoutes/organizationSettings/settingsTransferRoutes";
import SubRouteSettingWrapper from "../../../components/SubRouteSettingWrapper";

const OrganizationSettingsTransfer = () => {
    return (
        <Container>
            <SubRouteSettingWrapper
                back="organization"
                routes={settingsTransferRoutes}
            />
        </Container>
    );
}

export default OrganizationSettingsTransfer