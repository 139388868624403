import { useEffect, useState } from 'react';
import { Button, Fade, Spinner } from 'reactstrap';
import CustomAnimatedIcon from '../customAnimatedIcon/CustomAnimatedIcon';

/**
 * @description Button that renders a msg indicating the state of the action its on click event does
 * @param {Object&import('reactstrap').ButtonProps} props
 * @param {'init'|'loading'|'success'|'error'} props.loadState
 * @returns {JSX.Element}
 */
const BtnState = ({ loadState = 'init', children, disabled, ...props }) => {
    const [ showLoader, setShowLoader ] = useState(false);
    const [ showSuccess, setShowSuccess ] = useState(false);

    useEffect(() => {
        if(loadState === 'loading'){
            setShowLoader(true);
        }

        if(loadState !== 'loading' && showLoader){
            const timeout = setTimeout(() => {
                setShowLoader(false);
                if(loadState === 'success'){
                    setShowSuccess(true);
                }
            }, 400);

            return () => {
                clearTimeout(timeout);
            };
        }

    }, [ loadState, showLoader ]);

    useEffect(() => {
        if(loadState !== 'loading' && showSuccess){
            const timeout = setTimeout(() => {
                setShowSuccess(false);
            }, 2000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [ showSuccess ])

    return (
        <Button
            {...props}
            disabled={showLoader || disabled || loadState === 'loading'}
            style={{
                minWidth: '20px',
            }}
        >
            <Fade in={showLoader} timeout={50} className='position-absolute left-0 right-0 top-0 bottom-0 d-flex flex-center'>
                <Spinner style={{ borderWidth: '1px', width: '15px', height: '15px' }} />
            </Fade>
            <Fade in={showSuccess && !showLoader} timeout={50} className='position-absolute left-0 right-0 top-0 bottom-0 d-flex flex-center'>
                <CustomAnimatedIcon
                    id='customStatusIconButtonNotifications'
                    size='15px'
                    icon='checkmark'
                    strokeWidth='15px'
                    withCircle
                />
            </Fade>
            <span style={{ opacity: showLoader || showSuccess ? 0 : 1 }}>
                {children}
            </span>
        </Button>
    )
}

export default BtnState;