import { Collapse, ModalBody, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import AddSupervisionFormContextProvider, { AddSupervisionFormContext } from "./components/AddSupervisionFormContext";
import OfficialInfo from "./components/OfficialInfo";

const AddSupervisionForm = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName="AddOfficiatingCoachReport">
            <AddSupervisionFormInner {...props} />
        </AnalyticsModal>
    );
}

const AddSupervisionFormInner = ({ toggle }) => {
    return (
        <AddSupervisionFormContextProvider toggle={toggle}>
            <AddSupervisionFormContext.Consumer>
                {({ isLoading, steps, activeStep, formData }) => (
                    <OverlayLoader isLoading={isLoading}>
                        <ModalHeader toggle={toggle}>{steps[activeStep].title}</ModalHeader>
                        <Collapse isOpen={!!formData.officiatingCoach?.member_id && activeStep > 0}>
                            <ModalBody className="pb-0">
                                <OfficialInfo officiatingCoach={formData.officiatingCoach} official={formData.official} />
                            </ModalBody>
                        </Collapse>
                        {steps.map((step, index) => {
                            const View = step.component;

                            return (
                                <CrossFade key={step.id} isVisible={index === activeStep && formData.viewMode == "steps"}>
                                    <View />
                                </CrossFade>
                            )
                        })}
                    </OverlayLoader>
                )}
            </AddSupervisionFormContext.Consumer>
        </AddSupervisionFormContextProvider>
    )
}

export default AddSupervisionForm;