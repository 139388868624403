import API_SPORDLE from "../API-Spordle";
import queryString from "query-string";
import { serverError } from "../CancellableAPI";


    /**
     * Get infraction for an organization
     * @param {object} queryParams
     * @returns {Promise}
     */
    export function getSuspensionTypes (queryParams) {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/suspension-types',
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
        .then((response) => {
            if(response.data.status){
                return response.data.suspension_types
            }
            throw response.data.errors[0];
        }, serverError)
    }