import { useContext } from 'react';
import {
    Badge,
    FormGroup,
    Label
} from "reactstrap";
import FormikEditable from '../../../../../../../components/formik/FormikEditable';
import { displayI18n, DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import Translate from '@spordle/intl-elements';
import moment from 'moment';
import { getInitVal, getValidationSchema, displayField, getLabelHelper } from './AnswersHelper';
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { fail, success } from '@spordle/toasts';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';

export const FormField = ({ field, index, groupIndex, setIsLoading, registration, onSuccess, formId, customForm }) => {
    const fieldName = `group_${groupIndex}_fields_${index}`;
    const { getGenericLocale } = useContext(I18nContext);
    const { updateForm } = useContext(MembersContext);

    // If this field is a deleted question with no answer, return null
    if(!((field.deleted_at && field.answer) || !field.deleted_at))return null;

    return (
        <FormGroup>
            <Label for={fieldName} className='mb-0 d-flex align-items-center'>
                <DisplayI18n
                    field='title'
                    defaultValue={field?.title || '-Question-'}
                    i18n={field?.i18n}
                />
                {getLabelHelper(field)}
                {field.seen == 0 &&
                    <sup className="small">
                        <small>
                            <Badge color='primary' pill className="ml-1 text-uppercase outline"><Translate id='misc.new' /></Badge>
                        </small>
                    </sup>
                }
            </Label>
            { field.deleted_at ?
                <div className='font-medium text-muted'>
                    <div className="bg-light-inverse rounded p-1">
                        {field.form_field_code == "DATETIME" ? (moment(field?.answer).format('HH:mm') || '-') : (field?.answer || '-')}
                    </div>
                    <div className="text-danger small"><i className="mdi mdi-alert-outline" /> <Translate id="misc.deleted" /></div>
                </div>
                :
                <FormikEditable
                    noConfirmation={field.form_field_code == "SELECTBOX" || field.form_field_code == "SELECTBOX_MULTI"}
                    id={field.custom_form_field_id}
                    initialValues={getInitVal(field, fieldName)}
                    validationSchema={getValidationSchema(field, fieldName)}
                    onSubmit={(values) => {
                        setIsLoading(true);

                        const newVal = { custom_form_field_id: field.custom_form_field_id };
                        const apiVal = [];

                        if(field.options){
                            newVal.answer = field.options.reduce((arr, _opt) => {
                                if(Array.isArray(values[fieldName])){
                                    if(values[fieldName].some((_selected) => _selected === _opt.custom_form_field_option_id)){
                                        arr.pushArray([ displayI18n('field_option', _opt.i18n, _opt.field_option, getGenericLocale()) ]);
                                    }
                                }else if(values[fieldName] === _opt.custom_form_field_option_id){
                                    arr.pushArray([ displayI18n('field_option', _opt.i18n, _opt.field_option, getGenericLocale()) ]);
                                }
                                return arr;
                            }, [])?.join(',');
                            newVal.custom_form_field_option_selected = Array.isArray(values[fieldName]) ? String(values[fieldName]) : values[fieldName];
                        }else if(field.form_field_code == "DATE"){ // DATE, YYYY-MM-DD format
                            newVal.answer = values[fieldName] ? moment.isMoment(values[fieldName]) ? values[fieldName].format('YYYY-MM-DD') : moment(values[fieldName]).format('YYYY-MM-DD') : '';
                        }else if(field.form_field_code == "DATETIME"){ // DATETIME, ISO format
                            newVal.answer = moment.isMoment(values[fieldName]) ? values[fieldName].toISOString() : moment(values[fieldName]).toISOString();
                        }else{
                            newVal.answer = values[fieldName];
                        }

                        customForm?.groups.forEach((gr) => {
                            gr.fields.forEach((_f) => {
                                if(!_f.deleted_at){
                                    if(_f.custom_form_field_id === field.custom_form_field_id){
                                        apiVal.pushArray([ newVal ]);
                                    }else{
                                        apiVal.pushArray([ _f ]);
                                    }
                                }
                            });
                        })

                        return updateForm(
                            registration.member.member_id,
                            formId,
                            {
                                fields: apiVal,
                                invoice_item_id: customForm.invoice_item_id,
                            },
                        )
                            .then(() => {
                                onSuccess?.();
                                success();
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                    setIsLoading(false);
                                }
                            })
                    }}
                >
                    {(isEditing, _stopEditing, formik) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'>
                                    {field.form_field_code == "DATETIME" ? (field.answer ? moment(field.answer).format('HH:mm') : '-') : (field?.answer || '-')}
                                </div>
                            )
                        }
                        return formik && displayField({ ...field, options: field.options?.filter((opt) => !opt.deleted_at) }, fieldName, formik)

                    }}
                </FormikEditable>
            }
        </FormGroup>
    )
}