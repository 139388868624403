import Translate from '@spordle/intl-elements';
import React from 'react';
import { Fade, ModalHeader } from 'reactstrap';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import MemberAddForm1 from './components/MemberAddForm1';
import MemberAddForm2 from './components/MemberAddForm2';
import MemberAddViews from './components/MemberAddViews';

const initState = {
    isLoading: false,
    activeView: MemberAddViews.step1,
    member: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
    },
}
class MemberAdd extends React.Component{
    constructor(props){
        super(props);
        this.state = initState
    }

    setLoading = (toggle) => {
        this.setState(() => ({ isLoading: toggle }));
    }

    setActiveView = (view) => {
        this.setState(() => ({ activeView: view }));
    }

    startCreateMember = (values) => {
        this.setState(() => ({ member: values, activeView: MemberAddViews.step2, isLoading: false }));
    }

    closeModal = () => {
        this.setState(() => (initState));
    }

    render(){
        return (
            <AnalyticsModal isOpen={this.props.isOpen} onClosed={this.closeModal} analyticsName='MemberAdd'>
                <OverlayLoader isLoading={this.state.isLoading}>
                    <ModalHeader toggle={this.props.toggle}><Translate id='members.search.memberAdd.title' /></ModalHeader>
                    {this.state.activeView === MemberAddViews.step1 &&
                        <Fade>
                            <MemberAddForm1
                                setLoading={this.setLoading}
                                setActiveView={this.setActiveView}
                                startCreateMember={this.startCreateMember}
                                memberInfo={this.state.member}
                            />
                        </Fade>
                    }
                    {this.state.activeView === MemberAddViews.step2 &&
                        <Fade>
                            <MemberAddForm2
                                setLoading={this.setLoading}
                                setActiveView={this.setActiveView}
                                toggle={this.props.toggle}
                                memberInfo={this.state.member}
                            />
                        </Fade>
                    }
                </OverlayLoader>
            </AnalyticsModal>
        );
    }
}

export default MemberAdd;