import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { RolesContext } from '../../contexts/RolesContext';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import DisplayOrganization from '../organization/DisplayOrganization';
import QuickViewButton from '../quickView/QuickViewButton';

export const accesses = new Map();

/**
 * @class
 * @prop {string} columnKey (provided by renderRow) String representing what column is being rendered by this function
 * @prop {object} rowData The data object
 * @prop {jsx or string} children The children contained between both tags of ReportLink
 *
 * @requires IMPORTANT MAKE SURE TO HAVE A DEFAULT CASE THAT RETURNS data[columnKey] in renderRow
 *
 * @example
 *   renderRow={(columnKey, data, spordleTable) => {
 *       return(
 *           <ReportLink columnKey={columnKey} rowData={data} spordleTable={spordleTable}>
 *               {
 *                   (() => {
 *                       switch (columnKey) {
 *                          case 'email':
 *                              return <a href={`mailto:${data.email}`}>{data.email}</a>
 *                          case 'gender':
 *                              return data.gender ? <GenderLabel gender={data.gender} /> : '';
 *                          case 'waiver_status':
 *                               return data.waiver_status === 'SIGNED' ? <i className='mdi mdi-check text-success'/> : <i className='mdi mdi-close text-danger'/>;
 *                          case 'is_mandatory':
 *                              return data.is_mandatory == 1 ? <i className='mdi mdi-check text-success'/> : <i className='mdi mdi-close text-danger'/>;
 *                          case 'signature_date':
 *                              return <DateFormat value={data.signature_date} format='YYYY-MM-DD hh:mm a' utc />
 *                          default:
 *                              return data[columnKey];
 *                      }
 *                  })()
 *              }
 *          </ReportLink>
 *      )
 *   }}
 * @copyright Spordle
 */
const ReportLink = ({ columnKey, rowData, children, spordleTable }) => {
    const organizationContext = useContext(OrganizationContext);
    const rolesContext = useContext(RolesContext);

    const getAccesses = (code, permissionCode) => {
        const key = `${code}_${permissionCode}`;
        if(!accesses.has(key)){
            accesses.set(key, rolesContext.hasAccessTo(code, permissionCode));
        }
        return accesses.get(key);
    }

    function stripHtml(html){
        // removes all html tags and only keeps the content in plain text
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    const currentColumn = spordleTable?.state?.orderedColumns?.find((column) => column.key == columnKey);
    if(currentColumn?.deprecated == 1){
        // if a column is depracted we want to hide the data to not confuse the client
        return null;
    }
    if(rowData[columnKey]){
        switch (columnKey){
            case 'first_name':
                // FIRST_NAME, MEMBER_ID: Opens member in quick view. Must have access to member profiles.
                if(rowData.member_id && getAccesses('members', 'members_profile')){
                    return (
                        <QuickViewButton member={{ member_id: rowData.member_id }} iconBeforeChild disableTour>
                            {children}
                        </QuickViewButton>
                    )
                }
                break;
            case 'duplicate_member_first_name':
                // FIRST_NAME, MEMBER_ID: Opens member in quick view. Must have access to member profiles.
                if(rowData.duplicate_member_id && getAccesses('members', 'members_profile')){
                    return (
                        <QuickViewButton member={{ member_id: rowData.duplicate_member_id }} iconBeforeChild disableTour>
                            {children}
                        </QuickViewButton>
                    )
                }
                break;
            case 'hcr_number':
            case 'unique_identifier':
                // UNIQUE IDENTIFIER: Links to the member profile. Must have access to member profiles.
                if(rowData.member_id && getAccesses('members', 'members_profile')){
                    return (
                        <Link
                            target="_blank"
                            to={`/members/profile/${rowData.member_id}`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'official_unique_identifier':
                // UNIQUE IDENTIFIER: Links to the member profile. Must have access to member profiles.
                if(rowData.official_id && getAccesses('members', 'members_profile')){
                    return (
                        <Link
                            target="_blank"
                            to={`/members/profile/${rowData.official_id}`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'duplicate_member_unique_identifier':
                // UNIQUE IDENTIFIER: Links to the member profile. Must have access to member profiles.
                if(rowData.duplicate_member_id && getAccesses('members', 'members_profile')){
                    return (
                        <Link
                            target="_blank"
                            to={`/members/profile/${rowData.duplicate_member_id}`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'invoice_number':
                // INVOICE NUMBER: Links to the member profile invoice tab. Must have access to member profiles and invoices.
                if(rowData.member_id && rowData.invoice_number && getAccesses('members', 'members_profile') && rowData.organisation_id === organizationContext.organisation_id){
                    return (
                        <Link
                            target="_blank"
                            to={`/members/profile/${rowData.member_id}/invoice?invoiceNumber=${rowData.invoice_number}`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'registration_name':
                // REGISTRATION NAME: Links to registration fees catalog. Must have access to registration fees catalog.
                if(rowData.registration_name && getAccesses('catalog', 'registration_fees')){
                    return (
                        <Link
                            target="_blank"
                            to={`/catalog/registrationfees?search=${rowData.registration_name}`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'fee_name':
                // FEE NAME: Links to the registration fee catalog, clinic items catalog or the other items catalog depending on the fee type.
                if(rowData.fee_type && rowData.fee_name){
                    if(rowData.fee_type == 'REGISTRATION' && getAccesses('catalog', 'registration_fees')){
                        return (
                            <Link
                                target="_blank"
                                to={`/catalog/registrationfees`}
                            >
                                {children}
                                <i className="ml-1 mdi mdi-launch" />
                            </Link>
                        )

                    }else if(rowData.fee_type == 'OTHER' && getAccesses('catalog', 'other_fees')){
                        return (
                            <Link
                                target="_blank"
                                to={`/catalog/otheritems`}
                            >
                                {children}
                                <i className="ml-1 mdi mdi-launch" />
                            </Link>
                        )
                    }else if(rowData.fee_type == 'CLINIC' && getAccesses('catalog', 'qualification_fees')){
                        return (
                            <Link
                                target="_blank"
                                to={`/catalog/clinicitems`}
                            >
                                {children}
                                <i className="ml-1 mdi mdi-launch" />
                            </Link>
                        )
                    }
                }
                break;
            case 'division_name':
                // DIVISION NAME: links to the division settings
                if(rowData.division_name && rowData.division_name != '-' && getAccesses('teams', 'team_divisions')){
                    return (
                        <Link
                            target="_blank"
                            to={`/settings/registration/division`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'class_name':
                // CLASS NAME: links to the class settings
                if(rowData.class_name && rowData.class_name != '-' && getAccesses('teams', 'team_classes')){
                    return (
                        <Link
                            target="_blank"
                            to={`/settings/registration/class`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'team_category_name':
                // CATEGORY NAME: links to the category settings
                if(rowData.team_category_name && rowData.team_category_name != '-' && getAccesses('teams', 'team_categories')){
                    return (
                        <Link
                            target="_blank"
                            to={`/settings/registration/category`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'taken_date':
                // QUALIFICATION NAME: Links to the qualifications section of the member profile.
                if(rowData.member_id && rowData.taken_date && getAccesses('members', 'members_qualifications')){
                    return (
                        <Link
                            target="_blank"
                            to={`/members/profile/${rowData.member_id}/qualifications`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'qualification_category':
                // QUALIFICATION CATEGORY: Links to the categories tab of the clinic settings.
                if(rowData.qualification_category && getAccesses('clinics', 'clinics_settings')){
                    return (
                        <Link
                            target="_blank"
                            to={`/settings/clinics/category`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'qualification_name':
                // QUALIFICATION NAME: Links to the categories tab of the clinic settings.
                if(rowData.qualification_name && getAccesses('clinics', 'clinics_settings')){
                    return (
                        <Link
                            target="_blank"
                            to={`/settings/clinics/qualifications`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'waiver_name':
                // WAIVER NAME: Links to the waivers catalog.
                if(rowData.waiver_name && getAccesses('catalog', 'organization_waivers')){
                    return (
                        <Link
                            target="_blank"
                            to={`/catalog/waivers`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
            case 'primary_organisation_name':
                // ORGANIZATION NAME: Adds in the DisplayOrganization component.
                if(rowData?.primary_organisation_id && rowData?.primary_organisation_name){
                    return <DisplayOrganization organisation={{ organisation_id: rowData?.primary_organisation_id }}>{children}</DisplayOrganization>
                }
                break;
            case 'member_primary_organisation_name':
                // ORGANIZATION NAME: Adds in the DisplayOrganization component.
                if(rowData?.member_primary_organisation_id && rowData?.member_primary_organisation_name){
                    return <DisplayOrganization organisation={{ organisation_id: rowData?.member_primary_organisation_id }}>{children}</DisplayOrganization>
                }
                break;
            case 'member_shared_organisation_id':
                // ORGANIZATION NAME: Adds in the DisplayOrganization component.
                if(rowData?.member_shared_organisation_id && rowData?.member_shared_organisation_name){
                    return <DisplayOrganization organisation={{ organisation_id: rowData?.member_shared_organisation_id }}>{children}</DisplayOrganization>
                }
                break;
            case 'appeal_to_organisation_name':
                // ORGANIZATION NAME: Adds in the DisplayOrganization component.
                if(rowData?.appeal_to_organisation_name && rowData?.appeal_to_organisation_id){
                    return <DisplayOrganization organisation={{ organisation_id: rowData?.appeal_to_organisation_id }}>{children}</DisplayOrganization>
                }
                break;
            case 'appeal_from_organisation_name':
                // ORGANIZATION NAME: Adds in the DisplayOrganization component.
                if(rowData?.appeal_from_organisation_name && rowData?.appeal_from_organisation_id){
                    return <DisplayOrganization organisation={{ organisation_id: rowData?.appeal_from_organisation_id }}>{children}</DisplayOrganization>
                }
                break;
            case 'appeal_amount_organisation_name':
                // ORGANIZATION NAME: Adds in the DisplayOrganization component.
                if(rowData?.appeal_amount_organisation_name && rowData?.appeal_amount_organisation_id){
                    return <DisplayOrganization organisation={{ organisation_id: rowData?.appeal_amount_organisation_id }}>{children}</DisplayOrganization>
                }
                break;
            case 'organisation_name':
                // ORGANIZATION NAME: Adds in the DisplayOrganization component.
                if(rowData?.organisation_id && rowData?.organisation_name){
                    return <DisplayOrganization organisation={{ organisation_id: rowData?.organisation_id }}>{children}</DisplayOrganization>
                }
                break;
            case 'waiver_organisation_name':
                // ORGANIZATION NAME: Adds in the DisplayOrganization component.
                if(rowData?.waiver_organisation_id && rowData?.waiver_organisation_name){
                    return <DisplayOrganization organisation={{ organisation_id: rowData?.waiver_organisation_id }}>{children}</DisplayOrganization>
                }
                break;
            case 'qualification_organisation':
                // ORGANIZATION NAME: Adds in the DisplayOrganization component.
                if(rowData?.qualification_organisation_id && rowData?.qualification_organisation){
                    return <DisplayOrganization organisation={{ organisation_id: rowData?.qualification_organisation_id }}>{children}</DisplayOrganization>
                }
                break;
            case 'member_organisation':
                // ORGANIZATION NAME: Adds in the DisplayOrganization component.
                if(rowData?.member_organisation_id && rowData?.member_organisation){
                    return <DisplayOrganization organisation={{ organisation_id: rowData?.member_organisation_id }}>{children}</DisplayOrganization>
                }
                break;
            case 'signed_with_organisation_name':
                // ORGANIZATION NAME: Adds in the DisplayOrganization component.
                if(rowData?.signed_with_organisation_id && rowData?.signed_with_organisation_name){
                    return <DisplayOrganization organisation={{ organisation_id: rowData?.signed_with_organisation_id }}>{children}</DisplayOrganization>
                }
                break;
            case 'registration_organisation_name':
                // ORGANIZATION NAME: Adds in the DisplayOrganization component.
                if(rowData?.registration_organisation_id && rowData?.registration_organisation_name){
                    return <DisplayOrganization organisation={{ organisation_id: rowData?.registration_organisation_id }}>{children}</DisplayOrganization>
                }
                break;
            case 'clinic_organisation_name':
                // ORGANIZATION NAME: Adds in the DisplayOrganization component.
                if(rowData?.clinic_organisation_id && rowData?.clinic_organisation_name){
                    return <DisplayOrganization organisation={{ organisation_id: rowData?.clinic_organisation_id }}>{children}</DisplayOrganization>
                }
                break;
            case 'clinic_name':
                // CLINIC NAME: Links to the clinic profile.
                if(rowData.clinic_id && getAccesses('clinics', 'clinics_profile')){
                    return (
                        <Link
                            target="_blank"
                            to={`/clinics/profile/${rowData.clinic_id}/overview`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'team_number':
            case 'team_name':
                // TEAM NAME: Links to the team roster.
                if(rowData.team_id && getAccesses('teams', 'teams_profile')){
                    return (
                        <Link
                            target="_blank"
                            to={`/teams/profile/${rowData.team_id}/roster`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'appeal_from_team_name':
                // TEAM NAME: Links to the team roster.
                if(rowData.appeal_from_team_id && getAccesses('teams', 'teams_profile')){
                    return (
                        <Link
                            target="_blank"
                            to={`/teams/profile/${rowData.appeal_from_team_id}/roster`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'appeal_to_team_name':
                // TEAM NAME: Links to the team roster.
                if(rowData.appeal_to_team_id && getAccesses('teams', 'teams_profile')){
                    return (
                        <Link
                            target="_blank"
                            to={`/teams/profile/${rowData.appeal_to_team_id}/roster`}
                        >
                            {children}
                            <i className="ml-1 mdi mdi-launch" />
                        </Link>
                    )
                }
                break;
            case 'detailed_summary':
                // this case is used to strip HTML from a row of the maltreatment complaint report.
                if(rowData.detailed_summary){
                    return (
                        stripHtml(rowData.detailed_summary)
                    )
                }
                break;
            default:
                return (children || '');
        }
    }
    return (children || '');
}

export default ReportLink
