import { isSidMultiSport } from "./getReferer";

/**
 *  Allows us to round money values while maintaining as much precision as possible.
 *  Number.EPSILON allows us to round to 2 decimals without rounding to the unit.
 *  @see https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
*/
export const roundForMoney = (number) => {
    return Math.round((parseInt(number) + Number.EPSILON) * 100) / 100 / 100;
}

/**
 * @deprecated Use spordle/helpers clamp instead
 * @param {number} val
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

/**
 * Function to get all the HTTP headers according to the current environment
 */
export function getVersionHeaders(){
    if((process.env.REACT_APP_VERSION_CLIENT === 'EIHA' || !isSidMultiSport()) && process.env.REACT_APP_VERSION_ID && process.env.REACT_APP_VERSION_PRODUCT && process.env.REACT_APP_VERSION_CLIENT && process.env.REACT_APP_VERSION_ENVIRONMENT)
        return {
            'X-Version': process.env.REACT_APP_VERSION_ID,
            'X-Product': process.env.REACT_APP_VERSION_PRODUCT,
            'X-Client': process.env.REACT_APP_VERSION_CLIENT,
            'X-Environment': process.env.REACT_APP_VERSION_ENVIRONMENT,
        }
    if(process.env.NODE_ENV !== 'development'){
        console.warn('Version variables are not set');
    }
}

/**
 * Helper function that can be used to make a pause
 * @param {number} time Ms
 * @returns {Promise}
 */
export async function delay(time){
    return new Promise((resolve) => setTimeout(resolve, time));
}

export function parseJSON(json, fallback){
    try{
        const parsed = JSON.parse(json);

        return parsed;
    }catch(e){
        console.error(e);

        return fallback || null;
    }
}

/**
 * @param {string} json
 * @returns {Promise}
 * @throws {Error}
 */
export function promisedParseJSON(json){
    return new Promise((resolve, reject) => {
        try{
            if(json){
                resolve(JSON.parse(json));
            }else{
                throw Error("No params");
            }
        }catch(e){
            reject(e);
        }
    })
}

export function stringifyJSON(json, fallback){
    try{
        const parsed = JSON.stringify(json);

        return parsed;
    }catch(e){
        console.error(e);

        return fallback || "";
    }
}

/**
 * @param {any} data
 * @returns {Promise<string>}
 * @throws {Error}
 */
export function promisedStringifyJSON(data){
    return new Promise((resolve, reject) => {
        try{
            resolve(JSON.stringify(data));
        }catch(e){
            reject(e);
        }
    })
}

export function isObject(item){
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * @param {object} target Smaller Object. It's properties if duplicate will be overwritten
 * @param {object} source Bigger Object. If has duplicate properties of target, will overwrite them.
 * @returns {object}
 */
export default function mergeDeep(target, source){
    const output = Object.assign({}, target);

    if(isObject(target) && isObject(source)){
        Object.keys(source).forEach((key) => {
            if(isObject(source[key])){
                if(!(key in target)){
                    Object.assign(output, { [key]: source[key] });
                }else{
                    output[key] = mergeDeep(target[key], source[key]);
                }
            }else{
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}

export function removeHTML(str){
    const tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
}


// checks if two arrays contain the same values no matter the order
export function arraysEqual(a, b){
    if(a === b)return true;
    if(a == null || b == null)return false;
    if(a.length !== b.length)return false;

    const sortedA = a.sort();
    const sortedB = b.sort();

    for(var i = 0; i < sortedA.length; ++i){
        if(sortedA[i] !== sortedB[i])return false;
    }
    return true;
}

export function isFn(any){
    return typeof any === "function";
}

export function isNullish(any){
    return any === undefined || any === null;
}