import { FormikCurrencyInput, FormikDateTime, FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { formatSelectData } from "@spordle/spordle-select";
import { Form, Formik, useFormikContext } from "formik";
import moment from "moment";
import { useContext } from "react";
import { Button, Col, Collapse, Fade, FormGroup, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { mixed, object, string, number } from 'yup';
import Required from "../../../../../../components/formik/Required";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { EVENT_TYPES, formikOtherTest, getStatusIdsPerTypes, PARTICIPANT_TYPES, useInjurySelect, YES_NO_INFO_DATA } from "../../InsuranceHelper";
import { stringBuilder } from "@spordle/helpers";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";

const StepAccident = ({ fromMemberView, changeStep, injuryDataLists, updateData, next }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { federation } = useContext(IdentityRolesContext);
    const { getOrganizationBySettings } = useContext(OrganizationContext);
    const getInjurySelectProps = useInjurySelect();

    const { environments, environments_w_other, conditions, conditions_w_other, status } = injuryDataLists;
    const { openStatusId, openStatusesIds, pendingStatusId, closedStatuses } = getStatusIdsPerTypes(status);

    const getOrgs = async() => {
        const orgs = await getOrganizationBySettings(federation.organisation_id, { setting_code: "office_list_insurance" });
        const sortedOrgs = orgs.sort((a, b) => {
            const aOrgName = displayI18n('name', a.i18n, a.organisation_name, getGenericLocale());
            const bOrgName = displayI18n('name', b.i18n, b.organisation_name, getGenericLocale());

            return aOrgName.localeCompare(bOrgName);
        });

        return formatSelectData(
            sortedOrgs,
            {
                getValue: (org) => org.organisation_id,
                getLabel: (org) => org.organisation_name,
                getGroupId: (org) => org.organisation_category.organisation_category_id,
                createGroupWithId: (org) => ({
                    ...org.organisation_category,
                    label: org.name,
                }),
            },
        );

    }

    const isStatusClosed = (selectedStatusId) => {
        return closedStatuses.includes(selectedStatusId)
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                arena: '',
                delivered_date: '',
                division_id: '',
                event_type: '',
                event_type_other: '',
                guardian: '',
                accident_date: '',
                injury_condition_id: '',
                injury_condition_other: '',
                injury_environment_id: '',
                injury_environment_other: '',
                injury_event_id: '',
                injury_event_other: '',
                reserve: 0,
                injury_status_id: status?.find((s) => s.default_status == 1)?.injury_status_id || '',
                injury_type: 'CLAIM',
                location: '',
                organisation_id: '',
                participant_type: '',
                penalty_involved: '',
                team_category_id: '',
                venue_name: '',
            }}
            validationSchema={
                object().shape({
                    arena: string(),
                    guardian: string(),
                    division_id: string(),
                    team_category_id: string(),
                    reserve: number(<Translate id='form.validation.number' />).min(0).test({
                        name: 'needValWhenOpen',
                        message: <Translate id='insurance.sidePanel.cheque.addModal.validation.reserve.whenOpen' />,
                        test: function(val){
                            return !openStatusesIds.includes(this.parent.injury_status_id) || !!val;
                        },
                    }),
                    injury_status_id: string().required(<Translate id='insurance.validation.status.required' />),
                    accident_date: mixed().isDate().required(<Translate id='insurance.validation.incidentDate.required' />),
                    delivered_date: mixed().isDate().test({
                        name: 'cannotBePast',
                        message: <Translate id='insurance.validation.deliveredDate.cannotBeFuture' />,
                        test: function(date){
                            if(!this.parent.accident_date || !date)return true;
                            const theDate = moment(date);

                            return theDate.isSameOrAfter(moment(this.parent.accident_date), 'days') && theDate.isSameOrBefore(moment(), 'days');
                        },
                    }),
                    event_type: string().required(<Translate id='insurance.validation.eventType.required' />),
                    event_type_other: string().test(formikOtherTest('event_type')),
                    organisation_id: string().required(<Translate id='insurance.validation.office.required' />),
                    penalty_involved: string(),
                    participant_type: string().required(<Translate id='insurance.validation.participantType.required' />),
                    injury_condition_id: string().required(<Translate id='insurance.validation.condition.required' />),
                    injury_condition_other: string().test(formikOtherTest('injury_condition_id', conditions_w_other)),
                    injury_type: string().required(<Translate id='insurance.validation.injuryType.required' />),
                    injury_environment_id: string().required(<Translate id='insurance.validation.injuryEnv.required' />),
                    injury_environment_other: string().test(formikOtherTest('injury_environment_id', environments_w_other)),
                })
            }
            onSubmit={(values) => {
                updateData('accident', values);
                next();
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <CanDoAction action="ADD" componentCode="members" componentPermissionCode="member_insurance_sensitive_data">
                            <div className="h4 font-bold"><Translate id='insurance.label.claim' /></div>
                            <Row form className="mb-4">
                                <Col className='form-group' sm='6'>
                                    <Label for="reserve" className="label-permission">
                                        <Translate id='insurance.label.reserve' /> <Fade className="d-inline" in={formik.values.status === openStatusId}><Required /></Fade>
                                    </Label>
                                    <FormikCurrencyInput
                                        name="reserve"
                                        id="reserve"
                                        className="text-inherit"
                                        allowNegative={false}
                                        disabled={isStatusClosed(formik.values.injury_status_id)}
                                        onChange={(e) => {
                                            const theAmount = parseFloat(e.currentTarget.value.match(/([0-9.])+/, "")?.[0] || 0);

                                            if(!pendingStatusId || openStatusesIds.length <= 0){
                                                return;
                                            }

                                            if(formik.values.injury_status_id === pendingStatusId && theAmount > 0){
                                                formik.setFieldValue('injury_status_id', openStatusId);
                                            }
                                        }}
                                    />
                                </Col>
                                <Col className="mb-3" sm="6">
                                    <Label for="injury_status_id" className="label-permission">
                                        <Translate id="misc.status" />
                                    </Label>
                                    <FormikSelect
                                        name="injury_status_id"
                                        id="insurance-status"
                                        renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        onOptionSelected={async([ value ]) => {
                                            // Both values are set at the same time before onOptionSelected is called
                                            // before the select value is set, so there were a visual glitches when a formik error was triggered
                                            await formik.setFieldValue('injury_status_id', value);

                                            if(isStatusClosed(value)){
                                                formik.setFieldValue('reserve', 0);

                                            }

                                            return true;
                                        }}
                                        options={status ?
                                            status.map((stat) => ({
                                                i18n: stat.i18n,
                                                label: stat.name,
                                                value: stat.injury_status_id,
                                            }))
                                            :
                                            []
                                        }
                                    />
                                </Col>
                                <Col sm="12">
                                    <Collapse isOpen={isStatusClosed(formik.values.injury_status_id)}>
                                        <small className="text-muted">
                                            <i className="mdi mdi-information-outline text-primary" /> <Translate id='insurance.label.claimStatus.helper' />
                                        </small>
                                    </Collapse>
                                </Col>
                            </Row>
                        </CanDoAction>
                        <div className="h4 font-bold"><Translate id='insurance.label.accident' /></div>
                        <FormGroup>
                            <Row form>
                                <Col sm="6" className="form-group">
                                    <Label for="participant_type" className="text-muted"><Translate id='insurance.label.participantType' /> <Required /></Label>
                                    <FormikSelect
                                        name="participant_type"
                                        id="participant_type"
                                        defaultData={PARTICIPANT_TYPES.map((type) => ({
                                            label: `insurance.label.participantType.${type}`,
                                            value: type,
                                            translateLabel: true,
                                        }))}
                                        loadingStatus='success'
                                    />
                                </Col>
                                <Col sm="6" className="form-group">
                                    <Label for="guardian" className="text-muted"><Translate id='insurance.label.guardian' /></Label>
                                    <FormikInputText name="guardian" id="guardian" />
                                </Col>
                                <Col sm="6" className="form-group">
                                    <Label for="organisation_id" className="text-muted"><Translate id='insurance.label.office' /> <Required /></Label>
                                    <FormikSelect
                                        name="organisation_id"
                                        id="organisation_id"
                                        renderSelectedOption={(opt) => (
                                            <DisplayI18n
                                                field="name"
                                                defaultValue={opt.label}
                                                i18n={opt.i18n}
                                            />
                                        )}
                                        renderOption={(opt) => (
                                            <>
                                                {opt.option.isGroup && <span><Translate id='insurance.label.category' />: </span>}
                                                <span className={stringBuilder({ "font-medium pt-2": opt.option.isGroup })}>
                                                    <DisplayI18n
                                                        field="name"
                                                        defaultValue={opt.option.label}
                                                        i18n={opt.option.i18n}
                                                    />
                                                </span>
                                                {!opt.option.isGroup && (
                                                    <small className="d-block text-muted">
                                                        {opt.option.organisation_parent?.abbreviation}
                                                    </small>
                                                )}
                                            </>
                                        )}
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                            `organisation_category.i18n.${getGenericLocale()}.name`,
                                            `organisation_parent.abbreviation`,
                                        ]}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return getOrgs();
                                            }
                                        }}
                                    />
                                </Col>
                                <Col sm="6" className="form-group">
                                    <Label for="venue_name" className="text-muted"><Translate id='insurance.label.arena' /></Label>
                                    <FormikInputText name="venue_name" id="venue_name" />
                                </Col>
                                <AccidentDates />
                                <Col sm="6" className="form-group">
                                    <Label for="injury_environment_other" className="text-muted"><Translate id='insurance.label.environment' /> <Required /></Label>
                                    <FormikSelect {...getInjurySelectProps("injury_environment_id", environments)} />
                                    <Collapse isOpen={environments_w_other.includes(formik.values.injury_environment_id)}>
                                        <FormikInputText
                                            bsSize="sm"
                                            className="mt-2"
                                            name="injury_environment_other"
                                            id="injury_environment_other"
                                            translateHelper={false}
                                            helper={<span className="text-muted small"><i className="mdi mdi-information-outline" /> <Translate id='insurance.validation.injuryEnv.required' /></span>}
                                        />
                                    </Collapse>
                                </Col>
                                <Col sm="6" className="form-group">
                                    <Label for="penalty_involved" className="text-muted"><Translate id='insurance.label.penalty' /></Label>
                                    <FormikSelect
                                        clearable
                                        name="penalty_involved"
                                        id="penalty_involved"
                                        defaultData={YES_NO_INFO_DATA}
                                        loadingStatus="success"
                                    />
                                </Col>
                                <Col sm="6" className="form-group">
                                    <Label for="event_type" className="text-muted"><Translate id='insurance.label.eventType' /> <Required /></Label>
                                    <FormikSelect
                                        name="event_type"
                                        id="event_type"
                                        defaultData={[
                                            ...EVENT_TYPES.map((event) => (
                                                {
                                                    label: 'insurance.label.eventTypes.' + event,
                                                    value: event,
                                                    translateLabel: true,
                                                }
                                            )),
                                            {
                                                label: "misc.other",
                                                value: "OTHER",
                                                translateLabel: true,
                                            },
                                        ]}
                                        loadingStatus="success"
                                    />
                                    <Collapse isOpen={formik.values.event_type === "OTHER"}>
                                        <FormikInputText
                                            translateHelper={false}
                                            bsSize="sm"
                                            helper={<span className="text-muted small"><i className="mdi mdi-information-outline" /> <Translate id='insurance.validation.eventType.required' /></span>}
                                            className="mt-2"
                                            name="event_type_other"
                                            id="event_type_other"
                                        />
                                    </Collapse>
                                </Col>
                                <Col className="form-group" sm="6">
                                    <Label for="injury_condition_id" className="text-muted"><Translate id='insurance.label.condition' /> <Required /></Label>
                                    <FormikSelect {...getInjurySelectProps('injury_condition_id', conditions)} />
                                    <Collapse isOpen={conditions_w_other.includes(formik.values.injury_condition_id)}>
                                        <FormikInputText
                                            helper={
                                                <span className="text-muted small">
                                                    <i className="mdi mdi-information-outline" /> <Translate id='insurance.validation.condition.required' />
                                                </span>
                                            }
                                            className="mt-2"
                                            translateHelper={false}
                                            bsSize="sm"
                                            id="injury_condition_other"
                                            name="injury_condition_other"
                                        />
                                    </Collapse>
                                </Col>
                            </Row>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        {!fromMemberView &&
                            <Button
                                onClick={() => {
                                    changeStep(6);
                                    updateData('member', {})
                                }}
                                color="primary"
                                outline
                                type="button"
                                className="mr-auto"
                            >
                                <Translate id="misc.previous" />
                            </Button>
                        }
                        <Button color="primary" type="submit">
                            <Translate id="misc.next" />
                        </Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

const AccidentDates = () => {
    const { values } = useFormikContext();

    // useEffect(() => {
    //     if(!!values.accident_date && moment.isMoment(values.accident_date) && !values.delivered_date){
    //         setFieldValue('delivered_date', moment(values.accident_date));
    //     }else if(!values.accident_date){
    //         setFieldValue('delivered_date', '');
    //     }

    // }, [ values.accident_date ]);

    return (
        <>
            <Col sm="6" className="form-group">
                <Label for="accident_date" className="text-muted"><Translate id='insurance.label.accidentDate' /> <Required /></Label>
                <FormikDateTime
                    name="accident_date"
                    id="accident_date"
                    placeholder='form.validation.date.placeholder'
                    isValidDate={(current) => current.isSameOrBefore(moment(), 'days')}
                    timeFormat={false}
                />
            </Col>
            <Col sm="6" className="form-group">
                <Label for="delivered_date" className="text-muted"><Translate id='insurance.label.deliveredDate' /></Label>
                <FormikDateTime
                    key={!!values.delivered_date}
                    name="delivered_date"
                    id="delivered_date"
                    inputProps={{
                        disabled: !values.accident_date,
                    }}
                    placeholder='form.validation.date.placeholder'
                    isValidDate={(current) => current.isSameOrBefore(moment(), 'days') && current.isSameOrAfter(values.accident_date, 'days')}
                    timeFormat={false}
                />
            </Col>
        </>
    )
}

export default StepAccident;