import { FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { Label } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../../../../api/CancellableAPI";
import Required from "../../../../../../../../../components/formik/Required";
import { fail } from "@spordle/toasts";
import { MembersContext } from "../../../../../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../../../../../helpers/i18nHelper";
import { getLocation } from "../../../../../../../../teams/TeamHelpers";

const ChequeAddressSelect = ({ injury }) => {
    const { getMemberAddresses } = useContext(MembersContext);
    const { setFieldValue, values } = useFormikContext();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ addressInfo, setAddressInfo ] = useState({
        primary: null,
        list: [],
    });

    useEffect(() => {
        if(injury.member?.member_id){
            getMemberAddresses(injury.member.member_id)
                .then((addresses) => {
                    const primaryAddress = addresses?.find((a) => a.default_address == 1) || null;
                    setAddressInfo({ primaryAddress: primaryAddress, list: addresses || [] });
                    if(!values.member_address_id && primaryAddress){
                        setFieldValue('member_address_id', primaryAddress.member_address_id);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        setAddressInfo({ primaryAddress: null, list: [] });
                        setIsLoading(false);
                    }
                });
        }
    }, []);

    return (
        <>
            <Label className="text-muted"><Translate id='form.fields.address' /> <Required /></Label>
            {/* {!!primaryAddress &&
                <div className="bg-light p-2 rounded border mb-3">
                    <div className="text-uppercase font-medium mb-1"><Translate id='form.fields.address' /></div>
                    {getLocation(primaryAddress)}
                </div>
            } */}
            <FormikSelect
                id="member_address_id"
                name="member_address_id"
                search
                isLoading={isLoading}
                renderOption={({ option }) => (
                    <span>
                        {option.label} {option.address.default_address == 1 && <sup className="mdi mdi-star" />}
                    </span>
                )}
                options={addressInfo.list ? addressInfo.list.map((add) => ({
                    value: add.member_address_id,
                    label: getLocation(add),
                    address: add,
                })) : []}
            />
        </>
    )
}

export default ChequeAddressSelect;