import { useContext, useState } from "react";
import { InboxContext } from "./inboxContext/InboxContext";

export function useCheckMsg(){
    const [ checked, setChecked ] = useState([]);
    const { setShowTools } = useContext(InboxContext);

    const addCheck = (msg) => {
        setChecked((prev) => {
            if(prev.some((prevMsg) => prevMsg.message_id === msg.message_id)){
                return prev;
            }

            return [ ...prev, msg ];
        })
    }

    const removeCheck = (msg) => {
        setChecked((prev) => {
            const newList = prev.filter((prevMsg) => prevMsg.message_id !== msg.message_id);

            if(newList.length <= 0){
                setShowTools(false);
            }

            return newList;
        });
    }

    const handleOnCheck = (msg) => {
        return () => {
            addCheck(msg);
        }
    }

    const handleOnUncheck = (msg) => {
        return () => {
            removeCheck(msg);
        }
    }

    const getIsChecked = (msg) => {
        return checked.some((checkMsg) => checkMsg.message_id === msg.message_id)
    }

    const selectAll = (list) => {
        setChecked(list);
    }

    const unselectAll = () => {
        setChecked([]);
    }

    const updateCheckedMsgs = (newVal = {}) => {
        setChecked((prev) => {
            return prev.reduce((newChecked, checked) => {
                newChecked.push({ ...checked, ...newVal });

                return newChecked;
            }, [])
        })
    }

    return {
        selectAll,
        unselectAll,
        checked,
        addCheck,
        updateCheckedMsgs,
        removeCheck,
        handleOnCheck,
        handleOnUncheck,
        getIsChecked,
    }
}