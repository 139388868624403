export default{
    // Error codes
    "contexts.teamsContext.errors.3227" : "Un ou plusieurs des membres choisis a déjà été ajouté à cette équipe.",
    "contexts.teamsContext.errors.3384" : "Le roulement d'équipe a été désactivé.",
    "contexts.teamsContext.errors.3004" : "Équipe non trouvée.",
    "contexts.teamsContext.errors.3226" : "La position n'appartient pas à la Fédération de l'équipe. ",

    "contexts.teamsContext.errors.3060" : "Ressource introuvable. Veuillez contacter le support.",
    "contexts.teamsContext.errors.3074" : "Membre introuvable. Veuillez contacter le support.",
    "contexts.teamsContext.errors.3045" : "Données incompatibles. Veuillez contacter le support.",
}