import Translate from "@spordle/intl-elements"
import { useState } from "react"
import { Button, ModalBody, ModalFooter } from "reactstrap"
import AnalyticsModal from "../../../../analytics/AnalyticsModal"
import OverlayLoader from "../../../../components/loading/OverlayLoader"

/**
 *
 * @param {Object} props
 * @param {function} props.toggle
 * @param {boolean} props.isOpen
 * @param {Promise} props.onConfirm
 * @returns {React.ReactNode}
 */
const UsersSettingsUnlockModal = ({ isOpen, toggle, onConfirm, user }) => {
    const [ isLoading, setIsLoading ] = useState(false);

    const handleOnConfirm = async() => {
        setIsLoading(true);
        await onConfirm?.();
        toggle();
    }

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName="UsersSettingsUnlockModal">
            <OverlayLoader isLoading={isLoading}>
                <ModalBody className="text-center">
                    <button onClick={toggle} type="button" aria-label="Close" className="close position-absolute top-0 right-0 p-3">
                        <span aria-hidden="true">×</span>
                    </button>
                    <i style={{ fontSize: 75 }} className={`mdi mdi-alert-outline text-primary`} />
                    <p>
                        <Translate id='organization.profile.users.sidePanelUsers.unlock.msg' />
                    </p>
                    <div className="h4 font-bold">{user}</div>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    <Button color="primary" type="button" onClick={handleOnConfirm}><Translate id="misc.confirm" /></Button>
                    <Button color="primary" type="button" onClick={toggle} outline><Translate id="misc.cancel" /></Button>
                </ModalFooter>
            </OverlayLoader>
        </AnalyticsModal>
    )
}

export default UsersSettingsUnlockModal;