import UserDisplay from "../../../userDisplay/UserDisplay";
import Svg from "../../../Svg";
import { stringBuilder } from "@spordle/helpers";

/**
 * @description Button to choose which part of the invoice is gonna be edited. Wraps repetitive styles
 * @param {Object} props
 * @param {string} [props.icon]
 * @param {string|JSX.Element} props.title
 * @param {string|JSX.Element} props.subtitle
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
const InvoiceButton = ({ icon, title, subtitle, className, ...props }) => {
    return (
        <UserDisplay className={stringBuilder("mb-3", className)} block card hover {...props}>
            <UserDisplay.Container className="mr-3">
                <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                    <Svg icon={icon || "members"} />
                </div>
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title className="font-bold">{title}</UserDisplay.Title>
                <UserDisplay.Subtitle>{subtitle}</UserDisplay.Subtitle>
            </UserDisplay.Container>
        </UserDisplay>
    );
}

export default InvoiceButton;