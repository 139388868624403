import teamSettingsRestrictionsRoutes from "../../../routes/tabRoutes/teamSettings/teamSettingsRestrictionsRoutes";
import teamSettingsConfigurationRoutes from "../../../routes/tabRoutes/teamSettings/teamSettingsConfigurationRoutes";
import teamSettingsSuspensionsRoutes from "../../../routes/tabRoutes/teamSettings/teamSettingsSuspensionsRoutes";
import teamSettingsPositionsRoutes from "../../../routes/tabRoutes/teamSettings/teamSettingsPositionsRoutes";
import teamSettingsPositionGroupsRoutes from "../../../routes/tabRoutes/teamSettings/teamSettingsPositionGroupsRoutes";
import teamSettingsTeamAddressTypesRoutes from "../../../routes/tabRoutes/teamSettings/teamSettingsTeamAddressTypesRoutes";
import teamSettingsTeamCategoryTypesRoutes from "../../../routes/tabRoutes/teamSettings/teamSettingsTeamCategoryTypesRoutes";
import teamSettingsTeamTravelTypesRoutes from "../../../routes/tabRoutes/teamSettings/teamSettingsTeamTravelTypesRoutes";
import teamSettingsTeamContactTypesRoutes from "../../../routes/tabRoutes/teamSettings/teamSettingsTeamContactTypesRoutes";
import SettingsListView from "../components/SettingsListView";

const TeamSettingsList = () => {
    return (
        <SettingsListView
            id='teamSettingsListTable'
            setting="teams"
            routesList={[
                {
                    code: 'configuration',
                    path: '/configuration',
                    subPermissionsRoutes: teamSettingsConfigurationRoutes,
                },
                {
                    code: 'restrictions',
                    path: '/restrictions',
                    subPermissionsRoutes: teamSettingsRestrictionsRoutes,
                },
                {
                    code: 'suspensions',
                    path: '/suspensions',
                    subPermissionsRoutes: teamSettingsSuspensionsRoutes,
                },
                {
                    code: 'positions',
                    path: '/positions',
                    subPermissionsRoutes: teamSettingsPositionsRoutes,
                },
                {
                    code: 'positionGroups',
                    path: '/position-groups',
                    subPermissionsRoutes: teamSettingsPositionGroupsRoutes,
                },
                {
                    code: 'teamAddressTypes',
                    path: '/team-address-types',
                    subPermissionsRoutes: teamSettingsTeamAddressTypesRoutes,
                },
                {
                    code: 'teamCategoryTypes',
                    path: '/team-category-types',
                    subPermissionsRoutes: teamSettingsTeamCategoryTypesRoutes,
                },
                {
                    code: 'teamTravelTypes',
                    path: '/team-travel-types',
                    subPermissionsRoutes: teamSettingsTeamTravelTypesRoutes,
                },
                {
                    code: 'teamContactTypes',
                    path: '/team-contact-types',
                    subPermissionsRoutes: teamSettingsTeamContactTypesRoutes,
                },
            ]}
        />
    )
}

export default TeamSettingsList;