import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import Skeleton from "react-loading-skeleton";
import { Link, useHistory } from 'react-router-dom';
import { Button, Card,
    CardBody,
    Container } from "reactstrap";
import EmptyLayout from '../../../components/table/EmptyLayout';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { JiraContext } from '../../../contexts/JiraContext';
import Priority from '../Priority';
import { Status, TASK_PREFIX } from '../SupportRequestHelpers';
import SupportRequestAdvancedSearch from './components/SupportRequestAdvancedSearch';
import PaginationApi from '../../../components/table/PaginationApi';
import { useDisclosure } from '@mantine/hooks';
import SubmitTicket from '../../../components/helpCenter/submitTicket/SubmitTicket';
import { I18nContext } from '../../../contexts/I18nContext';
import DisplayOrganization from '../../../components/organization/DisplayOrganization';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import useSavedSearch from '../../../components/customHooks/useSavedSearch';

const SupportRequestSearch = () => {
    const jiraContext = useContext(JiraContext);
    const { getGenericLocale } = useContext(I18nContext);
    const [ submitTicketOpen, submitTicket ] = useDisclosure(false);
    const history = useHistory();

    const { savedSearch } = useSavedSearch(`support-request-advanced-search`);


    const handleLoadData = (from, filterData, spordleTable) => {
        const filters = filterData.filters;
        const priorityFilter = filters.form.priority;
        const currentPage = filters.currentPage;
        const pagination = spordleTable.getPagination();
        const offset = (currentPage - 1) * pagination;

        switch (from){
            case 'REFRESH':
            case 'FILTER':
                spordleTable.setLoading();
            case 'CDM':
                return jiraContext.getRequests(
                    undefined,
                    {
                        start_at: offset,
                        limit: pagination,
                        status_category: savedSearch?.status || filters.form.status,
                        priority: savedSearch?.priority || filters.form.priority,
                        issue_number: savedSearch?.idSearch ? `${TASK_PREFIX}${savedSearch.idSearch}` : filters.form.idSearch,
                        text_filter: savedSearch?.nameSearch || filters.form.nameSearch,
                        show_only_my_issues: savedSearch?.showOnlyMyIssues !== null
                            ? (savedSearch?.showOnlyMyIssues === false) ? '0' : '1'
                            : (filters.form.showOnlyMyIssues === false) ? '0' : '1',
                    },
                )
                    .then(({ issues, total }) => {
                        spordleTable.filterChange('totalResults', total, false);
                        if(priorityFilter.length > 0){
                            return issues.filter((issue) => priorityFilter.includes(issue.fields.priority.id))
                        }
                        return issues || [];
                    })
                    .catch(() => {
                        return [];
                    })
            default:
                break;
        }
    }

    return (
        <>
            {/*--------------------------------------------------------------------------------*/}
            {/* View Header                                                                  */}
            {/*--------------------------------------------------------------------------------*/}
            <ViewHeaderV2 title='supportRequests.search.title' />

            <SpordleTableProvider
                id='SupportRequestSearchTable'
                bordered
                striped
                desktopWhen='sm'
                dataIndex='id'
                pagination={10}
                initFilter={{
                    currentPage: 1,
                    totalResults: null,
                    // putting the filters in a object to have only 1 filter change in the advanced search
                    form: {
                        priority: "",
                        status: "",
                        idSearch: '',
                        nameSearch: '',
                    },
                }}
                loadData={handleLoadData}
                searchKeys={[
                    'key',
                    'fields.summary',
                    'fields.description',
                    'fields.created',
                    'fields.status.name',
                    'fields.status.statusCategory.name',
                    'fields.priority.name',
                    'fields.issuetype.name',
                    'fields.organisation.organisation_name',
                    `fields.organisation.i18n.${getGenericLocale()}.name`,
                ]}
                columns={[
                    {
                        label: <Translate id='supportRequests.search.table.reference' />,
                        key: 'reference',
                        sortKey: 'key',
                    },
                    {
                        label: <Translate id='supportRequests.search.table.subject' />,
                        key: 'subject',
                        sortKey: 'fields.summary',
                    },
                    {
                        label: <Translate id='supportRequests.search.table.priority' />,
                        key: 'priority',
                    },
                    {
                        label: <Translate id='misc.organization' />,
                        key: 'organization',
                        sortKey: 'fields.status.name',
                    },
                    {
                        label: <Translate id='misc.status' />,
                        key: 'status',
                        sortKey: 'fields.status.name',
                    },
                ]}
                loadingMessage={<Skeleton height={54} width="100%" count={10} />}
                onColumnClick={(event, row) => {
                    history.push(`/supportRequest/${row.key}`)
                }}
                renderRow={(columnKey, data) => {
                    switch (columnKey){
                        case 'reference':
                            return <Link className="text-nowrap" to={`/supportRequest/${data.key}`}>{data.key} <i className="mdi mdi-chevron-right ml-1" /></Link>
                        case 'status':
                            return <Status status={data.fields.status.statusCategory} />;
                        case 'priority':
                            return <Priority color={data.fields.priority.id}>{data.fields.priority.name}</Priority>
                        case 'subject':
                            return <div>{data.fields.summary}</div>
                        case 'organization':
                            return data.fields.organisation ? <DisplayOrganization organisation={{ organisation_id: data.fields.organisation?.organisation_id }}><DisplayI18n field='name' defaultValue={data.fields.organisation?.organisation_name} i18n={data.fields.organisation?.i18n} /></DisplayOrganization> : <div className='text-center'> - </div>
                        default:
                            break;
                    }
                }}
                emptyLayout={<EmptyLayout />}
            >
                {(spordleTable) => (
                    <div className="page-content pt-0 mt-0 mt-sm-n4">

                        <div className="clearfix">
                            <div className="d-flex justify-content-end flex-wrap mb-3">
                                <SubmitTicket onSuccess={() => spordleTable.refreshTable()} isOpen={submitTicketOpen} toggle={() => submitTicket.close()} />
                                <Button id="new-support-requests" color="primary" onClick={() => submitTicket.open()}><i className='mdi mdi-plus' /><Translate id="helpCenter.submitTicket" /></Button>
                            </div>
                        </div>

                        <Container>
                            <Card className='card-shadow'>
                                <CardBody>
                                    <SupportRequestAdvancedSearch />
                                </CardBody>
                            </Card>

                            <Card className='card-shadow'>
                                <CardBody>
                                    <SpordleTableView />
                                    <div className="d-flex justify-content-end">
                                        <PaginationApi />
                                    </div>
                                </CardBody>
                            </Card>
                        </Container>
                    </div>
                )}
            </SpordleTableProvider>
        </>
    );
}

export default SupportRequestSearch;