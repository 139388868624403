export default{
    "registration.settings.class.table.active" : "Active",
    "registration.settings.class.table.empty"  : "No Class",
    "registration.settings.class.delete"       : "You are about to delete this class",

    // add
    "registration.settings.class.modal.title"         : "Add Class",
    "registration.settings.class.add.status.active"   : "Active",
    "registration.settings.class.add.status.inactive" : "Inactive",
    "registration.settings.class.add.status.required" : "Status is required",

    // fields & form validation
    "registration.settings.class.form.label.status" : "Status",

    "registration.settings.class.form.label.order"                : "Order",
    "registration.settings.class.form.validation.order.integer"   : "Order number must not have decimals.",
    "registration.settings.class.form.validation.order.moreThan0" : "Order number must be higher than 0.",
    "registration.settings.class.form.validation.order.required"  : "Order number is required.",
}