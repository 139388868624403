import Translate from '@spordle/intl-elements';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { stringBuilder } from "@spordle/helpers";

/**
 * Component that displays a custom Alert based on the one from reactstrap
 *
 * @param {object} props
 * @param {string} [props.icon] Icon to display - Will default to the color's icon ('mdi mdi-check' for success)
 * @param {function} [props.toggle] Function to call when the 'X' button is clicked - Will display the 'X' button
 * @param {'success'|'warning'|'danger'|'info'} props.color Color of the alert
 * @param {string} props.className ClassName to apply to the Alert
 * @param {string} [props.title] Title of the Alert, will default to the color's title ('Success' for success)
 * @param {boolean} [props.translateTitle] Wether or not to translate the title - Defaults to true
 * @param {boolean} [props.withTitle] Wether or not to display the title - Defaults to false
 * @param {string} props.text Text of the Alert
 * @param {boolean} [props.translateText] Wether or not to translate the title - Defaults to true
 * @returns {React.ReactNode}
 */
const CustomAlert = ({
    icon,
    toggle,
    color,
    className,
    title,
    translateTitle,
    withTitle,
    text,
    translateText,
}) => {

    const getIcon = () => {
        if(icon){
            return icon
        }
        switch (color){
            case 'success':
                return 'mdi mdi-check';
            case 'warning':
                return 'mdi mdi-alert-outline';
            case 'danger':
                return 'mdi mdi-close-circle-outline';
            case 'info':
                return 'mdi mdi-information-outline';
        }

    }

    const getTitle = () => {
        if(title){
            if(translateTitle){
                return <Translate id={title} />
            }
            return title

        }
        switch (color){
            case 'success':
                return <Translate id='misc.success' />;
            case 'warning':
                return <Translate id='misc.warning' />;
            case 'danger':
                return <Translate id='misc.danger' />;
            case 'info':
                return <Translate id='misc.info' />;
        }

    }

    return (
        <Alert color={color} className={stringBuilder(`custom-alert-border-${color}`, className)} toggle={toggle} closeClassName='position-static order-2 p-1'>
            <div className='font-18 mr-2'>
                <i className={getIcon()} />
            </div>
            <div className='flex-grow-1'>
                {withTitle &&
                    <div className='font-bold'>
                        {getTitle()}
                    </div>
                }
                {translateText ?
                    <Translate id={text} />
                    :
                    text
                }
            </div>
        </Alert>
    )
}

export default CustomAlert;

CustomAlert.propTypes = {
    icon: PropTypes.string,
    toggle: PropTypes.func,
    color: PropTypes.oneOf([ 'success', 'warning', 'danger', 'info' ]).isRequired,
    className: PropTypes.string,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    translateTitle: PropTypes.bool,
    withTitle: PropTypes.bool,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]).isRequired,
    translateText: PropTypes.bool,
};

CustomAlert.defaultProps = {
    translateTitle: true,
    translateText: true,
    withTitle: false,
};