import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
// Language
import Translate, { DateFormat } from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import React from "react";
import { Button, Card, Table } from 'reactstrap';
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import SpordlePanelTable from '../../../../../../components/sidePanel/SpordlePanel';
// contexts
import withContexts from '../../../../../../helpers/withContexts';
import SidePanelTeamComments from './SidePanelTeamComments';
import { TeamsContext } from '../../../../../../contexts/TeamsContext';
import TeamSearchCommentsAddModal from '../../../../search/components/TeamSearchCommentsAddModal';
import { getStatus } from '../../../../TeamHelpers';
import { Tooltip } from '@mantine/core';

class TeamComments extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            addComment: false,
            team: {},
            // used for the spordle table mock up
            editHover: false,
        };
    }

    toggleAddComment = () => {
        this.setState((prevState) => ({ addComment: !prevState.addComment }))
    }

    componentDidMount(){
        this.props.TeamsContext.getTeam(this.props.teamId).then((team) => {
            this.setState(() => ({ team: team }))
        }).catch(console.error)
    }


    render(){
        return (
            <>
                <TeamSearchCommentsAddModal isOpen={this.state.addComment} toggle={this.toggleAddComment} team={this.state.team} mutate={() => { this.spordleTableRef.refreshTable(); }} />
                <Card body className="card-shadow">
                    <CardSectionTitle title='teams.profile.comments.title' />
                    <SpordlePanelTable
                        allowOutsideClick
                        sidePanel={(props) => <SidePanelTeamComments {...props} mutate={() => { props.forceCloseSidePanel(); this.spordleTableRef.refreshTable(); }} />}
                        dataIndex='team_comment_id'
                        table={(panelProps) => {
                            return (
                                <SpordleTableProvider
                                    id='comments'
                                    ref={(r) => { this.spordleTableRef = r; panelProps.spordleTableRef(r); }}
                                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                    tableHover clickable striped
                                    bordered
                                    dataIndex='team_comment_id'
                                    defaultSorting='-updated_at'
                                    columns={[
                                        {
                                            key: 'updated_at',
                                            label: <Translate id='teams.profile.comments.date' />,
                                            sortable: true,
                                            mobile: true,
                                        },
                                        {
                                            key: 'team_status',
                                            label: <Translate id='form.fields.team_status' />,
                                            sortable: true,
                                            mobile: true,
                                        },
                                        {
                                            label: <Translate id='team.profile.teamInfos.logs.identity.name' />,
                                            key: "identity.name",
                                            mobile: true,
                                            sortable: true,
                                            dataClassName: 'text-nowrap',
                                        },
                                        {
                                            label: <Translate id='team.profile.teamInfos.logs.identity.family_name' />,
                                            key: "identity.family_name",
                                            mobile: true,
                                            sortable: true,
                                            dataClassName: 'text-nowrap',
                                        },
                                        {
                                            label: <Translate id='team.profile.teamInfos.logs.identity.email' />,
                                            key: "identity.email",
                                            mobile: true,
                                            sortable: true,
                                            dataClassName: 'text-nowrap',
                                        },
                                        {
                                            key: 'comment',
                                            label: <Translate id='form.fields.comment' />,
                                            sortable: true,
                                            mobile: true,
                                        },
                                    ]}
                                    searchKeys={[
                                        'updated_at',
                                        'comment',
                                    ]}
                                    desktopWhen='md'
                                    pagination={5}
                                    renderRow={(key, row) => {
                                        switch (key){
                                            case 'comment':
                                                return (
                                                    <CommentComponent row={row} rowKey={key} />
                                                );
                                            case 'updated_at':
                                                return <DateFormat value={row.updated_at} format='YYYY-MM-DD H:mm' />
                                            case 'team_status':
                                                return (
                                                    row.team_status
                                                        ?
                                                        <div className='text-nowrap'>
                                                            {row.previous_team_status &&
                                                                <>
                                                                    {getStatus(row.previous_team_status)}
                                                                    <i className='mdi mdi-arrow-right mx-2' />
                                                                </>
                                                            }
                                                            {getStatus(row.team_status)}
                                                        </div>
                                                        :
                                                        '-'
                                                )
                                            case 'identity.name':
                                            case 'identity.family_name':
                                                return row.identity?.[key]
                                            case 'identity.email':
                                                return <a href={`mailto:${row.identity.email}`}>{row.identity.email}</a>;
                                            default:
                                                break;
                                        }
                                    }}
                                    onColumnClick={(e, comment) => {
                                        switch (e.button){
                                            case 0: // Left mouse button
                                                panelProps.onSingleSelectChange(comment)
                                                break;
                                        }
                                    }}
                                    rowIsHighlighted={(comment) => !!comment.checked}
                                    loadData={(from) => {
                                        switch (from){
                                            case 'REFRESH':
                                            case 'CDM':
                                                return this.props.TeamsContext.getTeamComments(this.props.teamId)
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    {(spordleTable) => (
                                        <>
                                            <div className='mb-2'>
                                                <div className='d-flex flex-wrap justify-content-between'>
                                                    <SpordleTableProvider.SearchInput />
                                                    <div className='d-flex ml-auto text-right'>
                                                        <SpordleTableProvider.Refresh />
                                                        <CanDoAction action='ADD' componentCode='teams' componentPermissionCode='team_comments_manage'>
                                                            <Button onClick={this.toggleAddComment} className='ml-2' color='primary'><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                        </CanDoAction>
                                                    </div>
                                                </div>
                                            </div>
                                            {spordleTable.getData().length === 0 ?
                                                <CanDoAction action='ADD' componentCode='teams' componentPermissionCode='team_comments_manage'>
                                                    <Table bordered striped hover>
                                                        <thead>
                                                            <tr>
                                                                <th onMouseEnter={() => this.setState(() => ({ editHover: true }))} onMouseLeave={() => this.setState(() => ({ editHover: false }))} className={stringBuilder('text-center', { 'bg-light': this.state.editHover })}><Translate id='form.fields.team_status' /></th>
                                                                <th onMouseEnter={() => this.setState(() => ({ severityHover: true }))} onMouseLeave={() => this.setState(() => ({ severityHover: false }))} className={stringBuilder({ 'bg-light': this.state.severityHover })}><Translate id='form.fields.comment' /></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={3} className='text-center'>
                                                                    <div><Translate id='teams.profile.comments.noComments.message' /></div>
                                                                    <button className='font-medium reset-btn text-primary' type='button' onClick={this.toggleAddComment}><i className='mdi mdi-plus' /><Translate id='teams.profile.comments.noComments.button' /></button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </CanDoAction>
                                                :
                                                <SpordleTableView />
                                            }
                                        </>
                                    )}
                                </SpordleTableProvider>
                            )
                        }}
                    />
                </Card>
            </>
        )
    }
}

export default withContexts(TeamsContext)(TeamComments);

const CommentComponent = ({ row, rowKey }) => {
    return (
        <Tooltip withArrow label={<div style={{ maxWidth: "300px" }} className='text-wrap'>{row[rowKey]}</div>}>
            <div
                className='text-truncate'
                style={{ maxWidth: '400px' }}
            >
                {row[rowKey]}
            </div>
        </Tooltip>
    )
}
