import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

// Contexts
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';
import { MembersContext } from './MembersContext';

/** @type {React.Context<Omit<AppealsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const AppealsContext = createContext();
AppealsContext.displayName = 'AppealsContext';

class AppealsContextProvider extends React.Component{

    /**
     * Gets all appeal approves
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Appeal%20Approve/Apicontroller%5CAppealApprove%5CAppealapprove%3A%3AgetAllAppealApprove|documentation}
     * @returns {Promise.<Array>}
     */
    getAppealApproves = (queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/appeal-approve',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.appeal_approve.sort((a, b) => a.display_order - b.display_order);
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets all appeal status
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Appeal%20Status/Apicontroller%5CAppealApprove%5CAppealstatus%3A%3AgetAllAppealStatus|documentation}
     * @returns {Promise.<Array>}
     */
    getAppealStatus = (queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/appeal-status',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.appeal_status.sort((a, b) => a.display_order - b.display_order);
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getAppealTypes = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/appeal-types`,
            query: {
                ...queryParams,
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.appeal_type.sort((a, b) => a.display_order - b.display_order)
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getAppealPaymentMethods = () => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/appeal-payment-methods`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.appeal_payment_methods.sort((a, b) => a.display_order - b.display_order)
                }
                throw response.data.errors[0];
            }, serverError)
    }

    // this was previously in the AppealsAddContext, moved it here because it is now also used in the sidepanel and I didn't want to duplicate filtering code
    getAppealsOrgCategories = () => {
        return this.props.OrganizationContext.getOrganizationCategory(this.props.MembersContext.currentMember.organisation.organisation_id, true)
            .then((categories) => {
                return categories
                    .filter((cat) => cat.default_name === 'NSO' || cat.default_name === 'Member branches' || cat.default_name === 'Member partner')
                    .map((cat) => cat.category_id)
            })
    }

    render(){
        return (
            <AppealsContext.Provider value={{ ...this }}>
                {this.props.children}
            </AppealsContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext, MembersContext)(AppealsContextProvider);