import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { AddSupervisionFormContext } from "../components/AddSupervisionFormContext";

const StepSelectFormType = () => {
    const { updateFormData, next, isLoading, previous, types, toggle } = useContext(AddSupervisionFormContext);

    const handleOnSelectType = (type) => {
        updateFormData('type', type);
        next();
    }

    return (
        <>
            <ModalBody>
                <div className="font-bold h5 mb-3">
                    <Translate id='officialSupervision.addSupervision.step.selectSupervisionType' />
                </div>
                {types.length > 1 &&
                    types.map((type) => (
                        <UserDisplay onClick={() => handleOnSelectType(type)} hover block card className="mb-2" key={type.supervision_type_id}>
                            <UserDisplay.Container>
                                <UserDisplay.Title>
                                    <DisplayI18n
                                        field="name"
                                        i18n={type.i18n}
                                        defaultValue={type.name}
                                    />
                                </UserDisplay.Title>
                            </UserDisplay.Container>
                        </UserDisplay>
                    ))
                }
            </ModalBody>
            <ModalFooter>
                <Button className="mr-auto w-50 w-md-auto" disabled={isLoading} outline type="button" color="primary" onClick={previous}>
                    <Translate id="misc.previous" />
                </Button>
                <Button className="w-50 w-md-auto" disabled={isLoading} color="primary" outline type="button" onClick={toggle}>
                    <Translate id="misc.cancel" />
                </Button>
            </ModalFooter>
        </>
    );
}

export default StepSelectFormType;