import useSWR from "swr";
import { getTaxClassRates, getTaxClasses } from "../client/taxes";

export function useTaxClassesSWR(){
    return useSWR(
        "getTaxClasses",
        () => getTaxClasses(),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshWhenHidden: false,
            dedupingInterval: (1000 * 60) * 60, // 1 hour
        }
        );
    }

    export function useTaxClassRatesSWR(taxClassId: string){
        return useSWR(
            ["getTaxClassRates", taxClassId ],
            (_, id) => id ? getTaxClassRates(id) : Promise.resolve([]),
            {
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
                refreshWhenHidden: false,
                dedupingInterval: (1000 * 60) * 60, // 1 hour
            }
    );
}