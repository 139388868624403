import React, { useState } from 'react';

const HoverableComponent = ({ children }) => {
    const [ isHovering, setHover ] = useState(false);

    return React.Children.map(children(isHovering), (child) => {
        if(React.isValidElement(child)){
            return React.cloneElement(child, {
                onMouseEnter: () => { setHover(true) },
                onMouseLeave: () => { setHover(false) },
            });
        }
        return child;
    })
}

export default HoverableComponent;