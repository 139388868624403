import { useContext } from "react"
import { GameIncidentsContext } from "../../../../../contexts/GameIncidentsContext";
import { QualificationCategoriesContext } from "../../../../../contexts/QualificationCategoriesContext";
import { TeamsContext } from "../../../../../contexts/TeamsContext"

export const useQueryContext = (contextName = "", funcName = "") => {
    const contexts = {
        "TeamsContext": useContext(TeamsContext),
        "GameIncidentsContext": useContext(GameIncidentsContext),
        "QualificationCategoriesContext": useContext(QualificationCategoriesContext),
    }
    const selectedContext = contexts[contextName];

    if(!selectedContext){
        return null;
    }

    if(!funcName || !selectedContext[funcName]){
        console.error(`Function${funcName ? ` (${funcName}) does not exist` : " is missing"} in: ${contextName}`);
        return null;
    }

    return selectedContext[funcName];
}