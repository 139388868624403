import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Fade } from "reactstrap";
import HoverableComponent from "./HoverableComponent";

const EnvBanner = ({ mobile }) => {
    if(process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENVIRONMENT === "prod" || process.env.NEXT_PUBLIC_ENVIRONMENT === "prod")
        return null;

    const currentEnv = (process.env.REACT_APP_ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT) + (process.env.NEXT_PUBLIC_ENVIRONMENT ? ".next" : "")

    const getSwitchURL = () => {
        switch (window.location.origin){
            // SID INT
            case "https://id-next.int.spordle.dev": // Vercel -> Amplify
                return "https://id.int.spordle.dev"
            case "https://id.int.spordle.dev": // Amplify -> Vercel
            case "http://localhost:3000": // Amplify -> Vercel
                return "https://id-next.int.spordle.dev"

            default:
                break;
        }
    }

    return (
        <>
            <div className={stringBuilder({ "env-border-danger": !mobile, "env-mobile-border-danger": mobile })} />
            <div className="env-header-container">
                <div className={stringBuilder({ "env-header-element-danger": !mobile, "env-mobile-header-element-danger": mobile })}>
                    <HoverableComponent>
                        {(isHovering) => {
                            const showHoverLink = isHovering && getSwitchURL();
                            return (
                                <div className={stringBuilder("font-medium", { "env-header-content-danger": !mobile, "env-mobile-header-content-danger": mobile, "env-header-hover": showHoverLink })}>
                                    <div><i className="mdi mdi-alert" /> <Translate id='components.viewHeader.env' /><b style={{ textTransform: "uppercase" }}>{currentEnv}</b></div>
                                    {(showHoverLink) && <Fade in><a href={getSwitchURL()} target='_blank' rel='noopener noreferrer'>{getSwitchURL()} <i className="mdi mdi-launch" /></a></Fade>}
                                </div>
                            )
                        }}
                    </HoverableComponent>
                </div>
            </div>
        </>
    )
};

export default EnvBanner;
