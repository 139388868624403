import { useEffect } from "react";
import { Spinner } from "reactstrap";
import ClinicCommunications from "../../components/clinicCommunications/ClinicCommunications";
import ClinicInformation from "../../components/clinicInformation/ClinicInformation";
import ClinicPaymentOptions from "../../components/clinicPaymentOptions/ClinicPaymentOptions";
import ClinicSessionInformation from "../../components/clinicSessionInformation/ClinicSessionInformation";

const ClinicProfileOverview = ({ isHost, isLoading, clinicId, waitListCount }) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])

    return (isLoading ?
        <div className='w-100 text-center'><Spinner color='primary' type='grow' /></div>
        :
        <>
            <ClinicInformation isHost={isHost} waitListCount={waitListCount} />
            <ClinicSessionInformation isHost={isHost} clinicId={clinicId} />
            {isHost &&
                <ClinicCommunications isHost={isHost} />
            }
            <ClinicPaymentOptions isHost={isHost} />
        </>
    )
}

export default ClinicProfileOverview;