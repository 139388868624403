import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useRef, useState } from 'react';
import {
    Card,
    CardBody,
    Button,
    Container,
    Badge,
    CustomInput
} from "reactstrap";
import SpordlePanelTable from '../../../components/sidePanel/SpordlePanel';
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableContext, SpordleTableView } from '@spordle/datatables';
import UserDisplay from '../../../components/userDisplay/UserDisplay';
import UserImg from '../../../components/UserImg';
import TravelPermitAddContext from './components/TravelPermitAdd/TravelPermitAddContext';
import moment from 'moment';
import EmptyLayout from "../../../components/table/EmptyLayout";

// contexts
import TravelPermitSidepanel from './components/TravelPermitSidepanel/TravelPermitSidepanel';
import { TeamsContext } from '../../../contexts/TeamsContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { I18nContext } from '../../../contexts/I18nContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import SpordleSelect from '@spordle/spordle-select';

const TravelPermit = () => {
    const [ addModal, setAddModal ] = useState(false);
    const toggleAddModal = () => setAddModal(!addModal);
    const teamsContext = useContext(TeamsContext);
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);

    const travelTypes = useRef([]);

    useEffect(() => {
        teamsContext.getTravelTypes({ organisation_id: organizationContext.organisation_id })
            .then((allTravelTypes) => {
                travelTypes.current = allTravelTypes;
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, [])

    return (
        <>
            {/* Task View Header */}
            <ViewHeaderV2 title='travelPermit.title' />
            <div className="px-4">
                <Container>
                    <Card className="card-shadow">
                        <CardBody>
                            <SpordlePanelTable
                                dataIndex='travel_permit_id'
                                allowOutsideClick
                                sidePanel={(props) => <TravelPermitSidepanel {...props} travelTypes={travelTypes} />}
                                table={(panelProps) => {
                                    return (
                                        <SpordleTableProvider
                                            id='TasksTravelPermitTable'
                                            tableHover bordered striped
                                            clickable
                                            ref={panelProps.spordleTableRef}
                                            dataIndex='travel_permit_id'
                                            searchKeys={[
                                                'travel_event_name',
                                                'team.name',
                                                'location_name',
                                                `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                                'organisation.organisation_name',
                                                'organisation.abbreviation',
                                                'travel_permit_status.name',
                                                'start_date',
                                                'end_date',
                                            ]}
                                            emptyLayout={<EmptyLayout />}
                                            desktopWhen
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : void 0}
                                            initFilter={{
                                                to_approve: true,
                                            }}
                                            loadData={(from, data, spordleTable) => {
                                                switch (from){
                                                    case 'FILTER':
                                                    case 'REFRESH':
                                                        spordleTable.setLoading();
                                                    case 'CDM':
                                                        return teamsContext.getOrgTravelPermits(organizationContext.organisation_id, { to_approve: !!data.filters.to_approve >>> 0 })
                                                            .then((travelPermits) => ((data.filters.status && data.filters.status.length > 0) ? travelPermits.filter((t) => data.filters.status.includes(t.travel_permit_status.travel_permit_status_id)) : travelPermits))
                                                    default:
                                                        break;
                                                }
                                            }}
                                            columns={[
                                                {
                                                    label: <Translate id='travelPermit.column.team' />,
                                                    key: 'team',
                                                    sortKey: 'team.name',
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='travelPermit.column.event' />,
                                                    key: 'event',
                                                    sortKey: 'travel_event_name',
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='travelPermit.column.location' />,
                                                    key: 'location',
                                                    sortKey: 'location_name',
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='travelPermit.column.startDate' />,
                                                    key: 'start_date',
                                                    // className: 'text-center',
                                                    // dataClassName: 'text-center',
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='travelPermit.column.endDate' />,
                                                    key: 'end_date',
                                                    // className: 'text-center',
                                                    // dataClassName: 'text-center',
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='travelPermit.column.status' />,
                                                    key: 'status',
                                                    sortKey: 'travel_permit_status.name',
                                                    sortable: true,
                                                },
                                            ]}
                                            renderRow={(columnKey, data) => {
                                                switch (columnKey){
                                                    case 'team':
                                                        return data.team ?
                                                            <UserDisplay>
                                                                <UserDisplay.Container>
                                                                    <UserImg
                                                                        abbr={data.team.name}
                                                                        src={null}
                                                                        width={40}
                                                                        height={40}
                                                                        alt={data.team.name}
                                                                    />
                                                                </UserDisplay.Container>
                                                                <UserDisplay.Container>
                                                                    <UserDisplay.Title>{data.team.name}</UserDisplay.Title>
                                                                    <UserDisplay.Subtitle>{data.team.organisation.organisation_name}</UserDisplay.Subtitle>
                                                                </UserDisplay.Container>
                                                            </UserDisplay>
                                                            :
                                                            ''
                                                    case 'event':
                                                        return (
                                                            <>
                                                                <div className="font-medium text-dark">{data.travel_event_name}</div>
                                                                <div className="text-muted small">{data.travel_game_type}</div>
                                                            </>
                                                        )
                                                    case 'location':
                                                        return (
                                                            <>
                                                                <div className="font-medium text-dark">{data.location_name}</div>
                                                                <div className="text-muted small">{data.country_code}</div>
                                                            </>
                                                        )
                                                    case 'start_date':
                                                        return (
                                                            <div className="font-medium text-dark">{moment(data.start_date).format('YYYY-MM-DD')}</div>
                                                        )
                                                    case 'end_date':
                                                        return (
                                                            <div className="font-medium text-dark">{data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : '-'}</div>
                                                        )
                                                    case 'status':
                                                        switch ((data.travel_permit_status?.name || '').toLowerCase()){
                                                            case 'pending':
                                                            case 'draft':
                                                                return (
                                                                    <Badge color='warning'>
                                                                        <DisplayI18n
                                                                            field="name"
                                                                            i18n={data.travel_permit_status.i18n}
                                                                            defaultValue={data.travel_permit_status.name}
                                                                        />
                                                                    </Badge>
                                                                )
                                                            case 'approved':
                                                                return (
                                                                    <Badge color='success'>
                                                                        <DisplayI18n
                                                                            field="name"
                                                                            i18n={data.travel_permit_status.i18n}
                                                                            defaultValue={data.travel_permit_status.name}
                                                                        />
                                                                    </Badge>
                                                                )
                                                            case 'declined':
                                                                return (
                                                                    <Badge color='danger'>
                                                                        <DisplayI18n
                                                                            field="name"
                                                                            i18n={data.travel_permit_status.i18n}
                                                                            defaultValue={data.travel_permit_status.name}
                                                                        />
                                                                    </Badge>
                                                                )
                                                            case 'submitted':
                                                                return (
                                                                    <Badge color='info'>
                                                                        <DisplayI18n
                                                                            field="name"
                                                                            i18n={data.travel_permit_status.i18n}
                                                                            defaultValue={data.travel_permit_status.name}
                                                                        />
                                                                    </Badge>
                                                                )
                                                            default:
                                                                return data.travel_permit_status?.name || '-'
                                                        }

                                                    default:
                                                        break;
                                                }
                                            }}
                                            onColumnClick={(e, data) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        panelProps.onSingleSelectChange(data);
                                                        break;
                                                }
                                            }}
                                            rowIsHighlighted={(data) => data.checked}
                                        >
                                            <SpordleTableContext.Consumer>
                                                {(spordleTable) => (
                                                    <>
                                                        <div className='mb-2'>
                                                            <div className='d-flex flex-wrap justify-content-between'>
                                                                <SearchInput />

                                                                <div className="mx-2" style={{ minWidth: "200px" }}>
                                                                    <SpordleSelect
                                                                        multi clearable
                                                                        name='travelPermitStatus'
                                                                        id='travelPermitStatus'
                                                                        placeholder='misc.status'
                                                                        onOptionSelected={(value) => {
                                                                            spordleTable.filterChange("status", value)
                                                                        }}
                                                                        renderOption={({ option }) => {
                                                                            if(option.value === 'ALL'){
                                                                                return <Translate id='misc.all' />;
                                                                            }
                                                                            return (
                                                                                <DisplayI18n
                                                                                    field='name'
                                                                                    defaultValue={option.label}
                                                                                    i18n={option.i18n}
                                                                                />
                                                                            )

                                                                        }}
                                                                        loadData={(from) => {
                                                                            switch (from){
                                                                                case 'CDM':
                                                                                    return teamsContext.getOrgTravelPermitStatuses()
                                                                                        .then((status) => status.map((st) => ({
                                                                                            id: st.travel_permit_status_id,
                                                                                            value: st.travel_permit_status_id,
                                                                                            label: st.name,
                                                                                            i18n: st.i18n,
                                                                                        })))
                                                                            }
                                                                        }}
                                                                    />

                                                                </div>

                                                                <div className='d-flex align-items-center ml-1'>
                                                                    <CustomInput
                                                                        type='checkbox' id='filterAction' key={spordleTable.getFilters().to_approve}
                                                                        label={<Translate id='transfer.table.filters.action.label' />}
                                                                        checked={spordleTable.getFilters().to_approve}
                                                                        onChange={(e) => {
                                                                            spordleTable.filterChange('to_approve', e.target.checked);
                                                                        }}
                                                                    />
                                                                </div>


                                                                <div className='d-flex ml-auto text-right'>
                                                                    <Refresh />
                                                                    <Button className='ml-2' color='primary' onClick={toggleAddModal}><i className='ti-plus' /> <Translate id='misc.add' /></Button>
                                                                </div>
                                                                <TravelPermitAddContext toggle={toggleAddModal} isOpen={addModal} />
                                                            </div>
                                                        </div>
                                                        <SpordleTableView />
                                                    </>
                                                )}
                                            </SpordleTableContext.Consumer>
                                        </SpordleTableProvider>
                                    )
                                }}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    );
}

export default TravelPermit;