import { Card } from "reactstrap";
import ChecklistStep from "./components/ChecklistStep";
import ChecklistHeader from "./components/ChecklistHeader";
import ChecklistProgress from "./components/ChecklistProgress";
import { matchPath, useHistory } from "react-router";
import { ChecklistsContext } from "../../../contexts/ChecklistsContext";
import { useContext, useEffect, useState } from 'react';
import { getChecklistStepLogs } from "../../../api/client/checklists";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { AppContext, PeriodsContext } from "../../../contexts/contexts";
import Translate from "@spordle/intl-elements";
import ChecklistIcon from "./components/ChecklistIcon";
import { Skeleton } from "@mantine/core";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import ChecklistsBreadcrumbs from "./ChecklistsBreadcrumbs";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";

const Checklists = () => {
    const { updateRecentlyViewed } = useContext(AppContext);
    const { organisation_id } = useContext(OrganizationContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const { checklists, getCompletedSteps, updateCompletedSteps, isLoading: checklistsLoading } = useContext(ChecklistsContext);

    const [ logsLoading, setLogsLoading ] = useState(false);
    const isLoading = logsLoading || checklistsLoading;

    const history = useHistory();
    const checklistId = matchPath(location.pathname, {
        path: '/tasks/checklists/:checklist',
        exact: true,
        strict: false,
    })?.params?.checklist;

    const checklist = checklists?.[checklistId];
    const steps = (checklist?.checklist_steps || [])
        .sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))
        .filter(({ active }) => active == 1);

    const completedSteps = getCompletedSteps(checklistId);

    useEffect(() => {
        if(!checklistsLoading){
            if(checklistId && checklist){
                setLogsLoading(true);
                getChecklistStepLogs({
                    checklist_id: checklist.checklist_id,
                    period_id: selectedPeriod.period_id,
                    organisation_id,
                })
                    .then((logs) => {
                        setLogsLoading(false);
                        updateCompletedSteps(checklistId, logs);
                        updateRecentlyViewed({
                            label: checklist.name,
                            path: location.pathname,
                            i18n: checklist.i18n,
                            translate: false,
                            breadcrumbs: [ { path: location.pathname, name: 'sidebar.checklists' } ],
                        });
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            setLogsLoading(false);
                            console.error(e);
                            history.push('/tasks/checklists/404');
                        }
                    });
            }else if(!checklist){
                history.push('/tasks/checklists/404');
            }
        }
    }, [ checklistId, checklistsLoading ])

    return (
        <>
            {/* <ViewHeaderV2 /> */}
            <ChecklistsBreadcrumbs />
            <div className="px-4">
                {(checklist) ?
                    <Card body className="border shadow p-4">
                        <ChecklistHeader
                            checklist={checklist}
                            checklistId={checklistId}
                            isLoading={isLoading}
                            completedSteps={completedSteps}
                            steps={steps}
                        />
                        {isLoading ?
                            <StepsLoading />
                            :
                            steps.length > 0 ?
                                <>
                                    <ChecklistProgress
                                        total={steps.length}
                                        complete={completedSteps.length}
                                    />
                                    <div className="border rounded bg-light-inverse p-4">
                                        {steps.map((step, index) => (
                                            <ChecklistStep
                                                key={step.checklist_step_id}
                                                index={index}
                                                step={step}
                                                checklistId={checklistId}
                                            />
                                        ))}
                                    </div>
                                </>
                                :
                                <div className="border rounded bg-light-inverse px-4 py-5 text-center">
                                    <Translate id="checklists.notSet" />
                                </div>

                        }
                    </Card>
                    :
                    <Card body className="border shadow p-4">
                        <div className="text-center">
                            <ChecklistIcon width={150} />
                            <div className="h2 font-weight-bold"><Translate id="misc.loading" /></div>
                            <Skeleton height="1em" width="20ch" className="mx-auto mb-3" />
                        </div>
                        <StepsLoading />
                    </Card>
                }
            </div>
        </>
    );
}

const StepsLoading = () => (
    <>
        <ChecklistProgress total={0} complete={0} />
        <div className="border rounded bg-light-inverse p-4">
            <StepLoading className="mb-3" />
            <StepLoading className="mb-0" />
        </div>
    </>
)

const StepLoading = ({ className }) => {
    return (
        <UserDisplay card block className={className}>
            <UserDisplay.Container className="mr-3 flex-shrink-0">
                <div
                    className='bg-light-inverse border rounded-circle'
                    style={{ width: 32, height: 32 }}
                />
            </UserDisplay.Container>
            <UserDisplay.Container className="w-100">
                <UserDisplay.Title className="mb-1">
                    <Skeleton width="10ch" height="1em" />
                </UserDisplay.Title>
                <UserDisplay.Subtitle>
                    <Skeleton width="15ch" height="1em" />
                </UserDisplay.Subtitle>
            </UserDisplay.Container>
        </UserDisplay>

    )
}

export default Checklists;