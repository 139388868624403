import { FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Alert, Button, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import FormikModalForm from "../../../../components/formik/FormikModalForm";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { useContext } from "react";
import { DisplayTeamCategory } from "../../TeamHelpers";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { array, bool, object, string } from "yup";
import FormikApiError from "../../../../components/formik/FormikApiError";
import ApiErrorMsg from "../../../../components/formik/ApiErrorMsg";
import { I18nContext } from "../../../../contexts/I18nContext";
import AddCompetitionTeamSelect from "./AddCompetitionTeamSelect";

const AddCompetitionTeamModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName="addCompetitionTeam">
            <AddCompetitionTeamModalInner {...props} />
        </AnalyticsModal>
    );
}

const AddCompetitionTeamModalInner = ({ toggle, orgId, onSuccess, allCompetitionTeams, selectedCompetition, ...props }) => {
    const { getCompetitions, addTeamToCompetition } = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <FormikModalForm
            toggle={toggle}
            successText={<p><Translate id="members.search.addCompetitionTeam.add.success" /></p>}
            initialValues={{
                competition_id: selectedCompetition?.competition_id || "",
                filter: false,
                division: selectedCompetition?.division || '',
                category: '',
                team_ids: [],
            }}
            validationSchema={object().shape({
                competition_id: string().required(<Translate id="members.search.addCompetitionTeam.competition.required" />),
                filter: bool(),
                team_ids: array().of(string()).min(1, <Translate id="members.search.addCompetitionTeam.team.required" />),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                const existingTeamsInCompetition = allCompetitionTeams.reduce((existing, { competition_id, team_id, submission_note }) => {
                    if(competition_id === values.competition_id && !values.team_ids.includes(team_id)){
                        existing.push({
                            team_id,
                            submission_note,
                        })
                    }

                    return existing;
                }, []);

                return addTeamToCompetition(
                    values.competition_id,
                    {
                        teams: [
                            ...existingTeamsInCompetition,
                            ...values.team_ids.map((id) => ({ team_id: id })),
                        ],
                    },
                )
                    .then(() => {
                        setStatus({
                            showError: false,
                            error: null,
                            isSuccess: true,
                        });
                        onSuccess?.();
                    })
                    .catch((e) => {
                        setSubmitting(false);
                        setStatus({
                            showError: true,
                            isSuccess: false,
                            error: Array.isArray(e) ? e : <ApiErrorMsg error={e} />,
                        })
                    })
            }}
        >
            {(formik) => (
                <>
                    <ModalHeader toggle={toggle}><Translate id="members.search.addCompetitionTeam.modal" /></ModalHeader>
                    <ModalBody>
                        {selectedCompetition?.competition_id ?
                            <Alert color='dark' className='mb-0'>
                                <Label className="font-medium d-block mb-0"><Translate id="teams.teamSearch.competition" /></Label>
                                <DisplayI18n field='name' defaultValue={selectedCompetition.name} i18n={selectedCompetition.i18n} />
                                <div className="small">(<DisplayTeamCategory ressource={selectedCompetition} />)</div>
                            </Alert>
                            :
                            <>
                                <Label className="text-muted" for="competition_id"><Translate id="teams.teamSearch.competition" /></Label>
                                <FormikSelect
                                    name="competition_id"
                                    id="competition_id"
                                    renderSelectedOption={(option) => (
                                        <>
                                            <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />
                                            {" "}
                                            <small className="text-muted">(<DisplayTeamCategory ressource={option} />)</small>
                                        </>
                                    )}
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                        `division.i18n.${getGenericLocale()}.short_name`,
                                        `team_category.i18n.${getGenericLocale()}.short_name`,
                                    ]}
                                    renderOption={({ option, isSelected }) => (
                                        <>
                                            <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />
                                            <div className={`small text-${isSelected ? 'light' : 'muted'}`}>
                                                <DisplayTeamCategory ressource={option} />
                                            </div>
                                        </>
                                    )}
                                    onOptionSelected={([ val ], select) => {
                                        const data = select.getSpordleTable().getData();
                                        const selected = data.find(({ value }) => val === value);

                                        formik.setFieldValue('division', selected?.division || null);
                                    }}
                                    loadData={(from) => {
                                        switch (from){
                                            case "CDM":
                                                return getCompetitions({ organisation_id: orgId, active: 1 })
                                                    .then((competitions) => {
                                                        return competitions.map((competition) => ({
                                                            ...competition,
                                                            value: competition.competition_id,
                                                            label: competition.name,
                                                        }))
                                                    })
                                            default:
                                                break;
                                        }
                                    }}
                                />
                            </>
                        }
                        <AddCompetitionTeamSelect orgId={orgId} existingTeams={allCompetitionTeams} />
                        <FormikApiError />
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="primary" disabled={formik.isSubmitting || formik.status.isSuccess}>
                            <Translate id="misc.submit" />
                        </Button>
                        <Button
                            disabled={formik.isSubmitting || formik.status.isSuccess}
                            type="button"
                            color="primary"
                            outline
                            onClick={toggle}
                        >
                            <Translate id="misc.cancel" />
                        </Button>
                    </ModalFooter>
                </>
            )}
        </FormikModalForm>
    );
}

export default AddCompetitionTeamModal;