export default{
    "components.manualRegistration.forms.title"              : "Questionnaire(s) ({current} / {count})",
    "components.manualRegistration.forms.skipAll"            : "Skip all questionnaires",
    "components.manualRegistration.forms.required"           : "This field is required",
    "components.manualRegistration.forms.min"                : "Please respect the minimum value",
    "components.manualRegistration.forms.max"                : "Please respect the maximum value",
    "components.manualRegistration.forms.label.between"      : "Between",
    "components.manualRegistration.forms.label.and"          : "and",
    "components.manualRegistration.forms.label.min"          : "Minimum",
    "components.manualRegistration.forms.label.max"          : "Maximum",
    "components.manualRegistration.forms.notMandatoryHelper" : "This questionnaire is not mandatory",
}