export default{
    "tasks.memberTypeValidation.title" : "Member Type Approval",

    "tasks.memberTypeValidation.columns.member"             : "Member",
    "tasks.memberTypeValidation.columns.expiry"             : "Picture expiry",
    "tasks.memberTypeValidation.columns.organization"       : "Organization",
    "tasks.memberTypeValidation.columns.crc"                : "CRC Status",
    "tasks.memberTypeValidation.columns.crc.date"           : "Valid on",
    "tasks.memberTypeValidation.columns.crc.expiry"         : "Expires on",
    "tasks.memberTypeValidation.columns.memberType"         : "Member Type",
    "tasks.memberTypeValidation.columns.memberTypeStatus"   : "Type Status",
    "tasks.memberTypeValidation.filter.memberType.required" : "Please select a member type",

    "tasks.memberTypeValidation.sidepanel.manage"     : "Manage Approval",
    "tasks.memberTypeValidation.sidepanel.count"      : "{count, plural, one {# Selected Member} other {# Selected Members}}",
    "tasks.memberTypeValidation.sidepanel.memberType" : "Member type",
    "tasks.memberTypeValidation.sidepanel.status"     : "Status",
    "tasks.memberTypeValidation.sidepanel.decidedBy"  : "Decided by",
    "tasks.memberTypeValidation.sidepanel.note"       : "Note",
}