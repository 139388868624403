import { Badge, Col, Row } from "reactstrap";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import Translate from "@spordle/intl-elements";
import { getTransferStatusBadge } from "../../../../helpers/transferHelper";
import { Link } from "react-router-dom";

const TransferWidgetPreview = ({ content }) => {
    return (
        <div className="mailbox position-relative min-h-0 h-100">
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <div className="message-center message-body overflow-hidden">
                    {content.map((transfer) => (
                        <Link key={transfer.member_transfer_id} to='/dashboard' className='mb-2 d-block card card-body card-shadow message-item'>
                            <Row>
                                <Col md="6">
                                    <div className="small">
                                        <span className="mail-desc">#{transfer.member.unique_identifier}</span>
                                        <h5 className="message-title">{transfer.member.first_name} {transfer.member.last_name}</h5>
                                    </div>
                                </Col>
                                <Col md="6">
                                    {transfer.expired_share ?
                                        <Badge color='light'><Translate id="transfer.table.header.status.EXPIRED_SHARE" /></Badge>
                                        :
                                        getTransferStatusBadge(transfer.transfer_status, transfer.previous_action_done)
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <div className="small">
                                        <div className="small"><Translate id='transfer.currentOrg' /></div>
                                        <div className="text-dark font-medium">
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={transfer.organisation?.organisation_name}
                                                i18n={transfer.organisation?.i18n}
                                            />
                                        </div>
                                        <div className="small"><Translate id='transfer.newOrg' /></div>
                                        <div className="text-dark font-medium">
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={transfer.target_organisation.organisation_name}
                                                i18n={transfer.target_organisation.i18n}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="small">
                                        <div className="small"><Translate id={`transfer.type.${transfer.transfer_type}`} /></div>
                                        <div className="text-dark font-medium"><DisplayI18n field='name' defaultValue={transfer.transfer_reason_type.name} i18n={transfer.transfer_reason_type.i18n} /></div>
                                    </div>
                                </Col>
                            </Row>
                        </Link>
                    ))}
                </div>
            </PerfectScrollbar>
        </div>
    )
}

export default TransferWidgetPreview