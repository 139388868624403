import { FormikSelect } from '@spordle/formik-elements'
import Translate from '@spordle/intl-elements'
import { useFormikContext } from 'formik'
import { useContext } from 'react'
import { FormGroup, Label } from 'reactstrap'
import useSWR from 'swr'
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI'
import { I18nContext } from '../../../../../../../contexts/I18nContext'
import { OrganizationContext } from '../../../../../../../contexts/OrganizationContext'
import { PositionsContext } from '../../../../../../../contexts/PositionsContext'
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper'
import { fail } from '@spordle/toasts'

const MemberProfileRegistrationAdd1Position = () => {
    const formik = useFormikContext();
    const positionsContext = useContext(PositionsContext);
    const i18nContext = useContext(I18nContext);
    const organizationContext = useContext(OrganizationContext);

    const { data: positions, isValidating } = useSWR(
        [ 'MemberProfileRegistrationAdd1Position', 'getPositions', organizationContext.organisation_id ],
        () => positionsContext.getPositions(organizationContext.organisation_id, { active: 1 })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }),
    )

    return (
        <FormGroup>
            <Label for='position_id_MemberProfileRegistrationAdd1' className='text-muted'><Translate id='memberProfile.registration.form.position' /></Label>
            <FormikSelect
                name='position_id'
                id='position_id_MemberProfileRegistrationAdd1'
                disabled={!formik.values.group_position_id}
                searchKeys={[
                    `i18n.${i18nContext.getGenericLocale()}.name`,
                ]}
                isLoading={isValidating}
                renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                options={Array.isArray(positions) ?
                    positions.reduce((options, position) => {
                        if(position.position_group.position_group_id === formik.values.group_position_id){
                            options.push({
                                value: position.position_id,
                                label: position.name,
                                i18n: position.i18n,
                                positionGroup: position.position_group,
                            })
                        }
                        return options;
                    }, [])
                    : undefined
                }
            />
        </FormGroup>
    )
}

export default MemberProfileRegistrationAdd1Position