import Translate from "@spordle/intl-elements";
import { useHistory } from "react-router";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import NewUserCard from "./NewUserCard";

const AddMultipleModal = (props) => (
    <AnalyticsModal size="lg" isOpen={props.isOpen} toggle={props.toggle} unmountOnClose backdrop="static" analyticsName='AddMultipleModal'>
        <AddMultipleInner {...props} />
    </AnalyticsModal>
)

const AddMultipleInner = ({ createdUsers, resetForm, toggle }) => {
    const history = useHistory();

    const close = () => {
        toggle();
        history.push({ hash: '#users', state: { refresh: true } });
    }

    const continueAdd = () => {
        toggle();
        resetForm();
    }

    return (
        <>
            <ModalHeader tag="div" toggle={continueAdd}><Translate id='organization.profile.users.mutilple.addUser.title' /></ModalHeader>
            <ModalBody>
                <p><Translate id='organization.profile.users.multiple.addUser.modal.msg' /></p>
                {createdUsers?.map((user) => <NewUserCard key={user.id} user={user} />)}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" type="button" onClick={close} outline><Translate id="misc.back" /></Button>
                <Button color="primary" type="button" onClick={continueAdd} className="ml-auto"><Translate id='organization.profile.users.multiple.addUser.modal.btn' /></Button>
            </ModalFooter>
        </>
    )
}

export default AddMultipleModal;