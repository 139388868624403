import Translate from "@spordle/intl-elements";
import HelpLinks, { RessourceLink } from "./helpLinks/HelpLinks";
import spordleCoach from '../../assets/images/helpCenter/spordleCoach.png'
import { useIntl } from "react-intl";
import { stringBuilder } from "@spordle/helpers";
import { useDisclosure } from "@mantine/hooks";
import { useContext, useState } from "react";
import HelpCenterFormModal from "./helpCenterForm/HelpCenterFormModal";
import CustomAlert from "../CustomAlert";
import { displayI18n, DisplayI18n } from "../../helpers/i18nHelper";
import styles from "./videoUserGuide/VideoUserGuide.module.scss";
import { I18nContext } from "../../contexts/I18nContext";
import CanDoAction from "../permissions/CanDoAction";
import HelpCenterAdditionalAlertFormModal from "./helpCenterForm/HelpCenterAdditionalAlertFormModal";
import OverlayLoader from "../loading/OverlayLoader";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import helpStyles from "./HelpCenterInner.module.scss";
import { OrganizationContext } from "../../contexts/OrganizationContext";

// TERMINOLOGY WARNING: Any reference to the word 'HELP BUBBLE' just means Help Center, originally the help center was a help bubble, but we changed the terminology in the front end
// however the back end didn't make the switch so API values still refer to a "help bubble"
// help_bubble_additional are the colored alerts that are displayed at the top of the help center
export const HelpCenterInner = ({ fullscreen, toggleMenu, helpLoading, toggleSubmitTicket, helpCenter, getHelpCenter, helpCenterResources }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { organisation_id } = useContext(OrganizationContext);
    const { formatMessage } = useIntl();

    const [ editMode, editModeHandlers ] = useDisclosure(false);
    const [ editAlertMode, editAlertModeHandlers ] = useDisclosure(false);
    const [ currentHelpCenterAdditionalAlert, setCurrentHelpCenterAdditionalAlert ] = useState({});

    const getColor = (color) => {
        switch (parseInt(color)){
            case 0:
                return 'danger' // red
            case 1:
                return 'warning' // yellow
            case 2:
                return 'success' // green
            case 3:
            default:
                return 'info'
        }
    }

    return (
        <div
            id="help-center-resizable"
            className={stringBuilder(
                helpStyles.Container,
                "max-vh-100 help-dropdown-menu rounded-lg card card-body card-shadow border mb-0",
                fullscreen ? "w-100" : `tourIsOpen ${helpStyles.NotFull}`,
            )}
        >
            <HelpCenterFormModal
                editMode={editMode}
                editModeHandlers={editModeHandlers}
                helpBubble={helpCenter}
                getHelpCenter={getHelpCenter}
            />
            <HelpCenterAdditionalAlertFormModal
                editAlertMode={editAlertMode}
                editAlertModeHandlers={editAlertModeHandlers}
                helpBubble={helpCenter}
                getHelpCenter={getHelpCenter}
                currentHelpBubbleAdditionalAlert={currentHelpCenterAdditionalAlert}
                getColor={getColor}
            />
            {/* HEADER */}
            <div className="help-dropdown-header text-break">
                <div
                    className={stringBuilder(helpStyles.Header, 'd-flex align-items-center justify-content-between border-bottom pb-2 pt-3 pr-3 pl-2')}
                >
                    <div className={stringBuilder('h4 font-bold mb-0 d-flex align-items-center', { "pt-4": fullscreen })}>
                        {!fullscreen && <i className="mdi mdi-drag mr-1" />}
                        <Translate id='helpCenter.title' />
                    </div>
                    <CanDoAction
                        action="EDIT"
                        componentCode="settings"
                        componentPermissionCode="manage_help_bubble"
                        className="ml-auto"
                    >
                        <UncontrolledDropdown className={stringBuilder(fullscreen ? "mt-4" : "mr-3")}>
                            <DropdownToggle
                                color="white"
                                size="sm"
                                className={`${helpStyles.MoreBtn} text-dark border-0 rounded-circle p-0`}
                            >
                                <i className="d-inline-block mdi mdi-dots-horizontal font-14" />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem
                                    disabled={helpLoading}
                                    onClick={editModeHandlers.open}
                                >
                                    <Translate id='helpCenter.edit.center' />
                                </DropdownItem>
                                <DropdownItem
                                    disabled={helpLoading || !helpCenter}
                                    onClick={() => {
                                        setCurrentHelpCenterAdditionalAlert({});
                                        editAlertModeHandlers.open();
                                    }}
                                >
                                    <Translate id='helpCenter.editModal.add.alert' />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </CanDoAction>
                    {!fullscreen &&
                        <button
                            id="help-center-dropdown-close"
                            className="reset-btn p-3 m-n3 line-height-1"
                            type="button"
                            onClick={toggleMenu}
                        >
                            <i className="d-inline-block mdi mdi-close font-14" />
                        </button>
                    }
                </div>
            </div>
            <OverlayLoader className="min-h-0 flex-shrink-1 h-100 d-flex flex-column" isLoading={helpLoading}>
                <PerfectScrollbar
                    className="flex-shrink-1 h-100 min-h-0 bg-light-inverse"
                    options={{ suppressScrollX: true }}
                >
                    {/* HELP CENTER ALERTs (help_bubble_additional) */}
                    {helpCenter?.help_bubble_additional?.filter((helpCenterAlert) => helpCenterAlert.i18n?.[getGenericLocale()]?.title).map((helpCenterAlert) => (
                        <CustomAlert
                            className='mb-0 border-0 rounded-0'
                            key={helpCenterAlert.help_bubble_additional_id}
                            color={getColor(helpCenterAlert.color)}
                            withTitle
                            title={
                                <div className="d-flex">
                                    {helpCenterAlert.i18n?.[getGenericLocale()]?.title}
                                    {helpCenterAlert.organisation.organisation_id === organisation_id &&
                                        <CanDoAction className="ml-auto" action="EDIT" componentCode="settings" componentPermissionCode="manage_help_bubble">
                                            <Button
                                                id={`editHelpCenterAdditionalAlert_${helpCenterAlert.help_bubble_additional_id}`}
                                                type="button"
                                                color="link"
                                                size="sm"
                                                className="py-0 pr-2"
                                                onClick={() => {
                                                    setCurrentHelpCenterAdditionalAlert(helpCenterAlert);
                                                    editAlertModeHandlers.open();
                                                }}
                                            >
                                                <Translate id="misc.edit" />
                                            </Button>
                                        </CanDoAction>
                                    }
                                </div>
                            }
                            translateTitle={false}
                            text={<div className="text-wrap text-break" dangerouslySetInnerHTML={{ __html: helpCenterAlert.i18n?.[getGenericLocale()]?.content }} />}
                            translateText={false}
                        />
                    ))}
                    {/* VIDEO */}
                    {helpCenter?.i18n?.[getGenericLocale()]?.video_id &&
                        <div className={`w-100 overflow-hidden position-relative ${styles.iframeContainer}`}>
                            <iframe
                                className='position-absolute top-0 bottom-0 right-0 left-0 w-100 h-100'
                                width='640'
                                height='360'
                                src={`https://www.youtube-nocookie.com/embed/${helpCenter?.i18n?.[getGenericLocale()]?.video_id}?modestbranding=1`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="YouTube video player"
                            />
                        </div>
                    }
                    {/* HELP CENTER TITLE AND CONTENT */}
                    {helpCenter?.i18n?.[getGenericLocale()]?.title &&
                        <>
                            {helpCenter?.i18n?.[getGenericLocale()]?.custom_link ?
                                <RessourceLink
                                    // With custom_link a title is required
                                    href={displayI18n('custom_link', helpCenter.i18n, helpCenter.custom_link, getGenericLocale())}
                                    customIcon={<></>}
                                    skipHrefIcon
                                    className='rounded-0 py-3 mb-1 bg-white border-bottom'
                                >
                                    <div className="text-left pl-2">
                                        <div className="font-medium">
                                            <DisplayI18n field='title' defaultValue={helpCenter.title} i18n={helpCenter.i18n} />
                                            <i className="ml-1 mdi mdi-launch text-primary" />
                                        </div>
                                        <div className="text-wrap text-dark" dangerouslySetInnerHTML={{ __html: helpCenter?.i18n?.[getGenericLocale()]?.content }} />
                                    </div>
                                </RessourceLink>
                                :
                                <div className="w-100 p-3 bg-white mb-1 border-bottom">
                                    <div className="h6 font-medium"><DisplayI18n field='title' defaultValue={helpCenter.title} i18n={helpCenter.i18n} /></div>
                                    <div className="text-wrap" dangerouslySetInnerHTML={{ __html: helpCenter?.i18n?.[getGenericLocale()]?.content }} />
                                </div>
                            }
                        </>
                    }
                    <section className="border-bottom border-top bg-white mb-1 mt-1">
                        <div className="h5 font-medium px-3 mb-2 mt-3"><Translate id='helpCenter.resources.title' /></div>
                        {/* onClick is called as an anonymus function because we do not want to send the event in prop */}
                        <div className="mx-2 mb-2">
                            {/* normal resource links */}
                            <HelpLinks linkClassName="bg-white text-dark" helpCenterResources={helpCenterResources} />
                        </div>
                    </section>
                </PerfectScrollbar>
                {toggleSubmitTicket &&
                    <div className={stringBuilder(helpStyles.FooterTicket, "border-top position-relative flex-shrink-0")}>
                        <RessourceLink
                            tag="button"
                            type="button"
                            onClick={() => toggleSubmitTicket()} // onClick is called as an anonymous function because we do not want to send the event in prop
                            className="reset-btn bg-white rounded-none p-3"
                            customIcon={
                                <img
                                    className="align-middle mr-2"
                                    src={spordleCoach}
                                    alt={formatMessage({ id: 'helpCenter.submitTicket' })}
                                    height={25}
                                    width={25}
                                />
                            }
                        >
                            <span className="text-primary">
                                <Translate id='helpCenter.submitTicket' />
                            </span>
                            <i className="text-primary pl-2 ml-auto mdi mdi-plus-box-outline" />
                        </RessourceLink>
                    </div>
                }
            </OverlayLoader>
        </div>

    )
}