import Translate from '@spordle/intl-elements';
import React from 'react';
import { Fade, ModalHeader } from 'reactstrap';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import MemberAddIneligibleForm1 from './components/MemberAddIneligibleForm1';

class MemberAddIneligible extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
        }
    }

    setLoading = (toggle) => {
        this.setState(() => ({ isLoading: toggle }));
    }

    render(){
        return (
            <AnalyticsModal isOpen={this.props.isOpen} analyticsName='MemberAdd'>
                <OverlayLoader isLoading={this.state.isLoading}>
                    <ModalHeader toggle={this.props.toggle}><Translate id='members.search.memberAddInleligible.title' /></ModalHeader>
                    <Fade>
                        <MemberAddIneligibleForm1
                            setLoading={this.setLoading}
                            closeModal={this.props.toggle}
                            setActiveView={this.setActiveView}
                            memberInfo={this.state.member}
                            toggle={this.props.toggle}
                        />
                    </Fade>
                </OverlayLoader>
            </AnalyticsModal>
        );
    }
}

export default MemberAddIneligible;