import React, { useContext } from 'react';
import { Button, DropdownToggle, NavLink, UncontrolledDropdown } from 'reactstrap';
import { IdentityRolesContext } from '../../contexts/IdentityRolesContext';
import { stringBuilder } from "@spordle/helpers";
import { Tooltip } from '@mantine/core';

/**
 * Display the children only if the user is a power user. This is a JSX implementation of IdentityRolesContext.isGod method.
 * It always apply the class `text-purple`
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @param {boolean} props.extraValidation
 * @param {boolean} props.tooltipOff Turn on or off the tooltip
 * @returns
 */
const IsGod = ({ tag: Tag, children, extraValidation, tooltipOff, action, componentCode, componentPermissionCode, ...props }) => {
    const identityRole = useContext(IdentityRolesContext);

    const childrenWrapper = React.Children.map(children, (child) => {
        if(React.isValidElement(child)){
            switch (child.type){
                case NavLink:// Reactstrap Button
                case Button:// Reactstrap Button
                case DropdownToggle:// Reactstrap Button
                    return React.cloneElement(child, {
                        color: 'purple',
                    });
                case UncontrolledDropdown:// Reactstrap Button
                case 'button':// Normal html Button
                    return React.cloneElement(child, {
                        className: stringBuilder('text-purple', child.props.className),
                    });
                default:
                    return React.cloneElement(child, {
                        className: stringBuilder('text-purple', child.props.className),
                        godAccess: true,
                    });
            }
        }
        return child;
    })

    let permissionRoute = '';
    if(componentCode){
        permissionRoute = componentCode;
        if(componentPermissionCode){
            permissionRoute += ` → ${componentPermissionCode}`;
            if(action){
                permissionRoute += ` → ${action}`;
            }
        }
    }
    return identityRole.isGod() && (extraValidation == null ? true : extraValidation) &&
        <Tag {...props}>
            {tooltipOff ? childrenWrapper :
                <span className='text-purple power-user'>
                    <Tooltip
                        withArrow
                        zIndex={11000}
                        className={props.className + ' w-100'}
                        label={<><u>Power user only</u>{permissionRoute ? <div className='text-nowrap'>{permissionRoute}</div> : null}</>}
                    >
                        {childrenWrapper}
                    </Tooltip>
                </span>
            }
        </Tag>
}

IsGod.defaultProps = {
    tag: 'div',
    tooltipOff: false,
};

export default IsGod;