export default{
    "catalog.forms.components.formEditor.questionType.questionTypeField.min.number"     : "Minimum value",
    "catalog.forms.components.formEditor.questionType.questionTypeField.min.number.tip" : "Validate the answer's value to be <underline>greater than or equal</underline> to the «minimum value»",
    "catalog.forms.components.formEditor.questionType.questionTypeField.min.date"       : "Minimum date",
    "catalog.forms.components.formEditor.questionType.questionTypeField.min.time"       : "Minimum time",
    "catalog.forms.components.formEditor.questionType.questionTypeField.max.number"     : "Maximum value",
    "catalog.forms.components.formEditor.questionType.questionTypeField.max.number.tip" : "Validate the answer's value to be <underline>less than or equal</underline> to the «maximum value»",
    "catalog.forms.components.formEditor.questionType.questionTypeField.max.date"       : "Maximum date",
    "catalog.forms.components.formEditor.questionType.questionTypeField.max.time"       : "Maximum time",

    "catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.date"   : "Date field",
    "catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.time"   : "Time field",
    "catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.number" : "Number input",
    "catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.short"  : "Short text answer",
    "catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.long"   : "Long text answer",
}