import { useSpordleTable } from '@spordle/datatables';
import { FormikError, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { Button, Card, Col, FormGroup, Row } from 'reactstrap';
import { object, string } from 'yup';
import CrossFade from '../../../components/crossFade/CrossFade';
import useSavedSearch from '../../../components/customHooks/useSavedSearch';
import SavedSearchCue from '../../../components/savedSearchCue/SavedSearchCue';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { RegistrationFeeContext } from '../../../contexts/RegistrationFeeContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { PeriodsContext } from '../../../contexts/contexts';

const WaitingListTopSection = ({ ...props }) => {
    const spordleTable = useSpordleTable();
    const registrationFeesContext = useContext(RegistrationFeeContext);
    const orgContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const periodsContext = useContext(PeriodsContext);
    const [ formikKey, setFormikKey ] = useState(0);

    const { savedSearch, clearSavedSearch, saveSearch } = useSavedSearch(`waiting-list-search-filters-${identityRolesContext.identity_role_id}-${orgContext.organisation_id}-${periodsContext.selectedPeriod.period_id}`);

    const rerenderFormik = () => {
        clearSavedSearch();
        setFormikKey(formikKey + 1);
    }

    const originalValues = {
        registrationFeeId: '',
        status: '',
    }

    const getInitFilters = () => {
        const filters = savedSearch;
        if(filters){
            return {
                ...originalValues,
                ...filters,
            };
        }

        return originalValues;
    }

    return (
        <Formik
            key={formikKey}
            initialValues={getInitFilters()}
            validationSchema={object().shape({
                registrationFeeId: string().required(<Translate id='task.waitlist.registration.required' />),
                status: string(),
            })}
            onSubmit={(values) => {
                spordleTable.filterChange('form', {
                    registrationFeeId: values.registrationFeeId,
                    status: values.status,
                })
                saveSearch(values)
            }}
        >
            {(formik) => (
                <Form>
                    <Card body className='card-shadow'>
                        <Row form className='align-items-center'>
                            <Col md='4'>
                                <FormGroup className='mb-md-0'>
                                    <FormikSelect
                                        name='registrationFeeId'
                                        id='registrationFeeId'
                                        placeholder="task.waitlist.registration"
                                        renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return registrationFeesContext.getRegistrationFees()
                                                        .then((registrationFees) => {
                                                            return registrationFees.reduce((newArray, registrationFee) => {
                                                                if(registrationFee.active == 1){
                                                                    newArray.push({
                                                                        value: registrationFee.registration_fee_id,
                                                                        label: registrationFee.fee.name,
                                                                        i18n: registrationFee.fee.i18n,
                                                                    })
                                                                }
                                                                return newArray
                                                            }, [])
                                                        })
                                            }
                                        }}
                                        manualError
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup className='mb-md-0'>
                                    <FormikSelect
                                        id='status'
                                        name='status'
                                        search={false} clearable
                                        defaultData={[
                                            { value: 'PENDING', label: 'task.waitlist.status.PENDING', translateLabel: true },
                                            { value: 'WAITING_FOR_CLIENT', label: 'task.waitlist.status.WAITING_FOR_CLIENT', translateLabel: true },
                                            { value: 'CANCELED', label: 'task.waitlist.status.CANCELED', translateLabel: true },
                                            { value: 'CONVERTED', label: 'task.waitlist.status.CONVERTED', translateLabel: true },
                                        ]}
                                        loadingStatus='success'
                                        placeholder='task.waitlist.status'
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <Button
                                    color='primary'
                                    type='submit'
                                    className='w-100'
                                >
                                    <Translate id='misc.search' />
                                </Button>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md='4'>
                                <FormikError name='registrationFeeId' />
                            </Col>
                        </Row>
                        <div className="w-100 d-flex flex-wrap flex-md-nowrap justify-content-end">
                            <SavedSearchCue className="mr-auto order-1 order-md-0 w-100 w-md-auto pt-3" savedSearch={savedSearch} clearSavedSearch={rerenderFormik} />
                            <CrossFade isVisible={JSON.stringify(originalValues) !== JSON.stringify(formik.values)}>
                                <button type='button' color='primary' className='reset-btn ml-2 text-danger font-12 mt-3' onClick={rerenderFormik}>
                                    <Translate id='members.search.memberAdvanceSearch.resetFilters' />
                                </button>
                            </CrossFade>
                        </div>
                    </Card>
                </Form>
            )}
        </Formik>
    )
}

export default WaitingListTopSection