import { SpordleTableContext } from '@spordle/datatables';
import { FormikInputNumber, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import {
    Button, Col, FormGroup,
    Label, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { number, object, ref, string } from 'yup';
import { success } from '@spordle/toasts';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { DocumentTypesContext } from "../../../../../../contexts/DocumentTypesContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import Required from '../../../../../../components/formik/Required';
import CrossFade from '../../../../../../components/crossFade/CrossFade';
import CustomAlert from '../../../../../../components/CustomAlert';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { I18nContext } from '../../../../../../contexts/I18nContext';

const OrganizationDocumentExpirationsAdd = ({ isOpen, toggle, documentTypeSpordleTable }) => {
    const spordleTable = useContext(SpordleTableContext);
    const documentTypeContext = useContext(DocumentTypesContext);
    const organizationContext = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext)

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='OrganizationDocumentTypesAdd'>
            <Formik
                initialValues={{
                    document_type_id: '',
                    expiration_duration: '',
                    min_age: '',
                    max_age: '',
                    delete_after_expiration: '0',
                    active: '1',
                }}
                validationSchema={object().shape({
                    document_type_id: string().required(<Translate id='settings.documentExpirations.add.docType.required' />),
                    expiration_duration: number().min(1, <Translate id='settings.documentTypeExpiration.expiration_duration.validation.minimum' />).required(<Translate id='settings.documentExpirations.add.months.required' />),
                    delete_after_expiration: string().required(),
                    min_age: number()
                        .required(<Translate id='members.search.memberAdvanceSearch.validation.minAge.required' />)
                        .lessThan(ref('max_age'), <Translate id='settings.documentExpirations.add.min.smaller' />),
                    max_age: number()
                        .required(<Translate id='members.search.memberAdvanceSearch.validation.maxAge.required' />)
                        .moreThan(ref('min_age'), <Translate id='settings.documentExpirations.add.max.larger' />),
                }, [ [ 'min_age', 'max_age' ] ])}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    documentTypeContext.createDocumentTypeExpiration(organizationContext.organisation_id, values)
                        .then((data) => {
                            spordleTable.refreshTable();
                            toggle();
                            success();
                        })
                        .catch((e) => {
                            setSubmitting(false);
                            if(!AxiosIsCancelled(e.message)){
                                console.error(e.message);
                                setStatus(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />);

                            }
                        })
                }}
            >
                {(formik) => {
                    return (
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <Form>
                                <ModalHeader toggle={toggle}><Translate id='settings.documentExpirations.add.title' /></ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <Label for="document_type_id" className="text-muted"><Translate id='settings.documentTypeExpiration.document_types.row' /> <Required /></Label>
                                        <FormikSelect
                                            id='document_type_id' name='document_type_id'
                                            options={documentTypeSpordleTable.getData().map((docType) => ({
                                                value: docType.document_type_id,
                                                label: docType.name,
                                                i18n: docType.i18n,
                                            }))}
                                            renderOption={({ option }) => (<DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />)}
                                            searchKeys={[
                                                `i18n.${getGenericLocale()}.name`,
                                                'label',
                                            ]}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="expiration_duration" className="text-muted"><Translate id='settings.documentTypeExpiration.expiration_duration.row' /> <Required /></Label>
                                        <FormikInputNumber
                                            id='expiration_duration' name='expiration_duration'
                                            translatePlaceholder
                                            placeholder="settings.documentTypeExpiration.expiration_duration.row.months"
                                        />
                                    </FormGroup>
                                    <Row form>
                                        <Col xs='6'>
                                            <FormGroup>
                                                <Label for="min_age" className="text-muted"><Translate id='members.search.memberAdvanceSearch.filters.minAge' /> <Required /></Label>
                                                <FormikInputNumber
                                                    name='min_age' id='min_age'
                                                    allowNegative={false}
                                                    decimalScale={0}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs='6'>
                                            <FormGroup>
                                                <Label for="max_age" className="text-muted"><Translate id='members.search.memberAdvanceSearch.filters.maxAge' /> <Required /></Label>
                                                <FormikInputNumber
                                                    name='max_age' id='max_age'
                                                    allowNegative={false}
                                                    decimalScale={0}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup>
                                        <Label for="delete_after_expiration" className="text-muted"><Translate id='settings.documentTypeExpiration.delete_after_expiration.row' /> <Required /> </Label>
                                        <FormikSelect
                                            id='delete_after_expiration' name='delete_after_expiration'
                                            defaultData={[
                                                { value: '1', label: 'misc.yes', translateLabel: true },
                                                { value: '0', label: 'misc.no', translateLabel: true },
                                            ]}
                                            search={false}
                                            loadingStatus='success'
                                        />
                                    </FormGroup>
                                    <CrossFade isVisible={!!formik.status} appear mountOnEnter unmountOnExit>
                                        {formik.status &&
                                            <CustomAlert className='mt-3' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                        }
                                    </CrossFade>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type='button' outline onClick={toggle} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </Form>
                        </OverlayLoader>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    )
}

export default OrganizationDocumentExpirationsAdd;
