export const TYPE_PROMO = "PROMO_CODE";
export const TYPE_MULTI = "MULTI";
export const MODE_PERCENT = "PERCENTAGE";
export const MODE_AMOUNT = "AMOUNT";

export default{
    type: {
        promo: {
            tag: "PROMO_CODE",
            label: 'catalog.rebates.type.promo',
        },
        multi: {
            tag: "MULTI_PARTICIPANT",
            label: 'catalog.rebates.type.multi',
        },
        postalCode: {
            tag: "POSTAL_CODE",
            label: 'catalog.rebates.type.postalCode',
        },
    },
    mode: {
        percent: {
            tag: "PERCENTAGE",
        },
        amount: {
            tag: "AMOUNT",
        },
    },
}