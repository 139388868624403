import Invoice from '../../components/invoice/Invoice';
import Receipt from '../../components/invoice/Receipt';
import ClinicProfileAttendees from '../../views/clinics/profile/attendees/ClinicProfileAttendees';
import ClinicProfileOverview from '../../views/clinics/profile/overview/ClinicProfileOverview';
import ClinicAttendeeRefund from '../../views/clinics/profile/refund/ClinicAttendeeRefund';
import ClinicProfileWaitlist from '../../views/clinics/profile/waitlist/ClinicProfileWaitlist';

/**
 * @typedef {object} TabRoutes
 * @property {React.ReactNode} [name]
 * @property {boolean} [redirect]
 */

/** @type {Array.<import('react-router-dom').RouteProps & TabRoutes>} */
const clinicsTabsRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        path: '/overview',
        name: 'clinics.profile.clinicProfile.tabs.overview',
        component: ClinicProfileOverview,
    },
    {
        path: '/attendees',
        name: 'clinics.profile.clinicProfile.tabs.attendees',
        component: ClinicProfileAttendees,
        permissions: { code: 'clinics', permissionCode: 'clinics_manage' },
    },
    {
        path: '/waitlist',
        name: 'clinics.profile.clinicProfile.tabs.waitlist',
        component: ClinicProfileWaitlist,
        permissions: { code: 'clinics', permissionCode: 'manage_clinic_waiting_list' },
    },
    {
        path: '/refund',
        name: 'clinics.profile.clinicProfile.tabs.refund',
        permissions: { code: 'clinics', permissionCode: 'clinics_manage', actions: [ 'EDIT' ] },
        component: ClinicAttendeeRefund,
        tempTab: true,
    },
    // {
    //     path: '/communications',
    //     name: 'clinics.profile.clinicProfile.tabs.communications',
    //     component: ClinicProfileCommunications,
    //     permissions: { code: 'clinics', permissionCode: 'clinics_manage' },
    //     powerUserOnly: true,
    // },
    // {
    //     path: '/finances',
    //     name: 'clinics.profile.clinicProfile.tabs.finance',
    //     component: ClinicProfileFinances,
    //     powerUserOnly: true,
    // },
    {
        path: '/invoice',
        name: 'members.profile.memberProfile.tabs.invoice',
        component: Invoice,
        tempTab: true,
    },
    {
        path: '/receipt',
        name: 'members.profile.memberProfile.tabs.receipt',
        component: Receipt,
        tempTab: true,
    },
    {
        hiddenTab: true,
        path: '/clinics/create',
        name: 'misc.create',
    },
    {
        redirect: true,
        from: '/clinics/profile/:clinicId',
        to: '/overview',
    },
];
export default clinicsTabsRoutes;