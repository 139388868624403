import Translate from "@spordle/intl-elements";
import GameIncidentTeamCard from "./components/GameIncidentTeamCard";

const GameIncidentTeamsTab = ({ gameIncident, updateValues, ...props }) => {
    return (
        <>
            <div className="font-bold h5">
                <Translate id='gameIncident.addModal.steps.teams.title' />
            </div>
            <div className="mb-1">
                <Translate id='gameIncident.addModal.teams.home.label' />
            </div>
            <GameIncidentTeamCard updateValues={updateValues} gameIncident={gameIncident} team={gameIncident.home_team} {...props} />

            <div className="mb-1">
                <Translate id='gameIncident.addModal.teams.away.label' />
            </div>
            <GameIncidentTeamCard updateValues={updateValues} pos="away" gameIncident={gameIncident} team={gameIncident.away_team} {...props} />
        </>
    );
}

export default GameIncidentTeamsTab;