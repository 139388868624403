import useSWR from "swr";
import { getMemberMemberTypeRemovalRequests } from "../client/memberTypeRemoval";

export function useMemberTypeRemovalRequestsSWR(memberId: string){
    return useSWR(
        ["getMemberTypeRemovalRequests", memberId ],
        (_, memberId) => memberId ? getMemberMemberTypeRemovalRequests(memberId) : Promise.resolve([]),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshWhenHidden: false,
        }
    );
}