import { stringBuilder } from "@spordle/helpers";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import { memo } from "react";

const PostalCodeItem = ({ isSelected, onClick, postalCode, isOrgPostalCode, canEdit }) => {
    const calculatedSelected = isOrgPostalCode || isSelected;

    return (
        <UserDisplay
            card
            hover={!isOrgPostalCode && canEdit}
            className={stringBuilder('mb-0 min-w-100 h-100', { 'border-primary': calculatedSelected })}
            onClick={(isOrgPostalCode || !canEdit) ? void 0 : onClick}
            style={{ opacity: isOrgPostalCode ? 0.6 : void 0 }}
        >
            {/* <UserDisplay.Container>
                <CustomInput
                    type="checkbox"
                    checked={isSelected}
                    onChange={(e) => e.preventDefault()}
                />
            </UserDisplay.Container> */}
            <UserDisplay.Container>
                <UserDisplay.Title>
                    {postalCode.postal_code}
                </UserDisplay.Title>
            </UserDisplay.Container>
            <UserDisplay.Container className='ml-auto'>
                {calculatedSelected ?
                    <div className='border rounded-circle border-primary bg-light-inverse d-flex flex-center' style={{ width: 20, height: 20 }}>
                        <i className="mdi mdi-check text-primary" />
                    </div>
                    :
                    <div className='border rounded-circle bg-light-inverse' style={{ width: 20, height: 20 }} />
                }
            </UserDisplay.Container>
        </UserDisplay>
    )
};

export default memo(PostalCodeItem);
