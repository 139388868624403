import { useContext, useState } from 'react';
import { Form, Formik } from "formik";
import { Button, Card, Col, Row } from "reactstrap";
import { FormikSelect } from "@spordle/formik-elements";
import { useSpordleTable } from "@spordle/datatables";
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { PeriodsContext } from '../../../contexts/contexts';
import useSavedSearch from '../../../components/customHooks/useSavedSearch';
import Translate from '@spordle/intl-elements';
import SavedSearchCue from '../../../components/savedSearchCue/SavedSearchCue';
import { I18nContext } from '../../../contexts/I18nContext';
import { object, string } from 'yup';
import DisplayRequestChangeStatus from '../../../components/display/DisplayRequestChangeStatus';
import { getRequestChangesStatus } from '../../../api/client/requestChangesStatus';

const FiltersCard = ({ setRequestChangeStatuses }) => {
    const spordleTable = useSpordleTable();
    const identityRolesContext = useContext(IdentityRolesContext)
    const organizationContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext);
    const i18nContext = useContext(I18nContext);

    const { savedSearch, saveSearch, clearSavedSearch } = useSavedSearch(`member-type-removal-search-filters-${identityRolesContext.identity_role_id}-${organizationContext.organisation_id}-${periodsContext.selectedPeriod.period_id}`);
    const [ formikKey, setFormikKey ] = useState(0);

    const originalValues = {
        requestChangesStatusId: '',
    }

    const getInitFilters = () => {
        if(savedSearch){
            return {
                ...originalValues,
                ...savedSearch,
            };
        }

        return originalValues;
    }

    // This is used to force a rerender of formik to empty FormikDateTime fields
    const rerenderFormik = () => {
        clearSavedSearch();
        setFormikKey(formikKey + 1);
    }

    return (
        <Formik
            key={formikKey}
            initialValues={getInitFilters()}
            validationSchema={object().shape({
                requestChangesStatusId: string(),
            })}
            onSubmit={(values) => {
                spordleTable.setInputValue();
                spordleTable.filterChange('form', values)
                saveSearch({
                    ...values,
                })
            }}
        >
            {(formik) => (
                <Form>
                    <Card body className='card-shadow'>
                        <Row form className='align-items-center'>
                            <Col sm={3}>
                                <FormikSelect
                                    placeholder='tasks.memberTypeRemoval.columns.status'
                                    name='requestChangesStatusId'
                                    id='requestChangesStatusId'
                                    searchKeys={[
                                        `i18n.${i18nContext.getGenericLocale()}.name`,
                                    ]}
                                    renderOption={({ option }) => <DisplayRequestChangeStatus status={option.status} />}
                                    loadData={(from) => {
                                        switch (from){
                                            case 'CDM':
                                                return getRequestChangesStatus()
                                                    .then((statuses) => {
                                                        setRequestChangeStatuses(statuses)

                                                        return statuses.reduce((options, status) => {
                                                            if(status.system !== "CANCELLED"){
                                                                options.push({
                                                                    label: status.name,
                                                                    value: status.request_change_status_id,
                                                                    i18n: status.i18n,
                                                                    status: status,
                                                                })
                                                            }

                                                            return options;
                                                        }, []);
                                                    })
                                                    .then((data) => {
                                                        // this controls the initial load of the table
                                                        // we need to send a request change status id
                                                        const requestChangesStatusId = formik.values.requestChangesStatusId || data.find((status) => status.status.system === "PENDING")?.value

                                                        if(!formik.values.requestChangesStatusId){
                                                            formik.setFieldValue('requestChangesStatusId', requestChangesStatusId)
                                                        }

                                                        spordleTable.filterChange('form', {
                                                            requestChangesStatusId: requestChangesStatusId,
                                                        })

                                                        return data
                                                    })
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={6} />
                            <Col sm={3}>
                                <Button
                                    id='memberTypeValidationFiltersSearch'
                                    color='primary' className='w-100'
                                    type='submit'
                                >
                                    <i className='fas fa-search mr-1' /><Translate id='misc.search' />
                                </Button>
                            </Col>
                            <Col md={12}>
                                <SavedSearchCue className="pt-3" savedSearch={savedSearch} clearSavedSearch={rerenderFormik} />
                            </Col>
                        </Row>
                    </Card>
                </Form>
            )}
        </Formik>
    )
}

export default FiltersCard