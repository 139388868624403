import { useContext } from 'react';
import { AppContext } from '../../contexts/contexts';
import { QuickViewContext } from '../../contexts/QuickViewContext';
import * as Sentry from "@sentry/react";
import SidePanel from '../sidePanel/SidePanel';
import Translate from '@spordle/intl-elements';

/**
 * Component that wraps children and applies an onClick to open a given member profile in the QuickView
 * @param {jsx or string} className
 * @param {object|Array} member
 * @param {boolean} alwaysShowIcon // ended up wanting to always show icon
 * @param {boolean} skipDivider
 * @return {React.ReactNode}
 */
const QuickViewActionButton = (props) => {
    const quickViewContext = useContext(QuickViewContext);
    const { isMobileDevice } = useContext(AppContext);
    const canOpenMember = props.member?.member_id || (Array.isArray(props.member) && props.member.length > 0)

    if(quickViewContext.inIframe || !canOpenMember || isMobileDevice || !document.getElementById('quick-view-iframe')?.contentWindow){
        return null;
    }

    return <QuickViewButtonInner {...props} />
}

export const QUICK_TOUR_NAME = "quickViewAdd";

const QuickViewButtonInner = ({ className, member, skipDivider }) => {
    const quickViewContext = useContext(QuickViewContext);

    const btnId = `quickview-${Array.isArray(member) ? `arr-${member[0]?.member_id}` : member.member_id}`;

    const handleOnClick = (e, theMember) => {
        if(quickViewContext.iframeIsLoaded){
            Sentry.addBreadcrumb({
                type: 'info',
                message: 'Quickview member opened from action button',
                level: Sentry.Severity.Log,
                category: 'QuickView',
                data: {
                    btnId: btnId,
                    member: theMember,
                },
            })
            e.stopPropagation();
            quickViewContext.openMemberQuickView(theMember);
        }
    }

    return (
        <>
            {!skipDivider &&
                <SidePanel.MenuAction divider className='border-bottom border-top bg-light' style={{ height: '0.5rem' }} />
            }
            <SidePanel.MenuAction className={className} onClick={(e) => handleOnClick(e, member)}>
                <i
                    id={btnId}
                    className={'mdi mdi-loupe mr-1'}
                />
                <Translate id='misc.quickview.open' />
            </SidePanel.MenuAction>
        </>
    );
}
export default QuickViewActionButton;