import { useDisclosure } from "@mantine/hooks";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Badge } from "reactstrap";
import NoticesList from "../../../../../../components/noticesList/noticesList/NoticesList";
import { useNewsSWR } from "../../../../../../components/noticesList/useNewsSWR";
import { useReadNews } from "../../../../../../components/noticesList/useReadNews";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";
import { RolesContext } from "../../../../../../contexts/RolesContext";

const NoticeLink = ({ handleOnToggle }) => {
    const { hasAccessTo, canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const canSeeNews = hasAccessTo("communications", "communication_news_manage");
    const canManageNews = canDoAction("EDIT", "communications", "communication_news_manage") || canDoAction("DELETE", "communications", "communication_news_manage") || canDoAction("ADD", "communications", "communication_news_manage");

    const { data: notices } = useNewsSWR(canSeeNews || isGod());
    const [ isOpen, isOpenHandlers ] = useDisclosure(false);
    const [ readNotices ] = useReadNews(notices);
    const unreadNotices = notices?.filter((n) => !n.read);

    const handleOnOpen = () => {
        readNotices(unreadNotices);
        isOpenHandlers.open();
    }

    return (
        ((canSeeNews || isGod()) && notices && notices.length > 0) ?
            <li onClick={handleOnOpen}>
                <span className={`sid-mobile-footer-menu-subitem text-${canSeeNews ? "dark" : "purple"}`}>
                    <i className="fa fa-newspaper mr-3 font-22" />
                    <Translate id='sidebar.news' />
                    <i className="mdi mdi-chevron-right ml-auto font-18 line-height-1" />
                    { unreadNotices.length > 0 &&
                        <Badge className="sid-mobile-footer-notif" pill>
                            {unreadNotices.length > 9 ? '9+' : unreadNotices.length}
                        </Badge>
                    }
                </span>
                <NoticesList
                    notices={notices}
                    isOpen={isOpen}
                    toggle={(isRedirect) => {
                        isOpenHandlers.close();
                        if(isRedirect){
                            handleOnToggle();
                        }
                    }}
                    canManageNews={canManageNews}
                />
            </li>
            :
            null
    );
}

export default NoticeLink;