import React from "react";
import {
    Button,
    Card,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown
} from "reactstrap";
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import { initTableViewI18n, renderI18nTableColumn, DisplayI18n } from '../../../../helpers/i18nHelper';
import SpordleSelect from "@spordle/spordle-select";
import ClinicCategory from "../../../clinics/ClinicCategory";

// Sidepanel
import SpordlePanelTable from "../../../../components/sidePanel/SpordlePanel";
import SidePanelClinicQualifications from './SidePanelClinicQualifications';

// Contexts
import withContexts from "../../../../helpers/withContexts";
import { I18nContext } from "../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { QualificationsContext } from '../../../../contexts/QualificationsContext';
import { QualificationCategoriesContext } from "../../../../contexts/QualificationCategoriesContext";
import EmptyLayout from "../../../../components/table/EmptyLayout";
import { Link } from "react-router-dom";
import CanDoAction from "../../../../components/permissions/CanDoAction";

class ClinicSettingsQualifications extends React.Component{
    constructor(props){
        super(props);

        // Setup i18n
        this.tableI18n = initTableViewI18n(this.props.OrganizationContext.settings.language.value, [ 'name' ]) //
    }

    render(){
        return (
            <Card body className="card-shadow">
                <div className="h4 font-bold pb-1 border-bottom mb-3 card-title">
                    <Translate id='settings.clinics.tab.qualifications' />
                </div>
                <SpordlePanelTable
                    allowOutsideClick
                    sidePanel={(props) => <SidePanelClinicQualifications {...props} tableRef={this.qualificationTableRef} i18n={this.tableI18n} />}
                    dataIndex="qualification_id"
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='ClinicSettingsQualificationsTable'
                                tableHover clickable striped
                                bordered
                                ref={(r) => { this.qualificationTableRef = r; panelProps.spordleTableRef(r); }}
                                dataIndex="qualification_id"
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                searchKeys={[
                                    `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                    `i18n.${this.props.I18nContext.getGenericLocale()}.description`,
                                    `qualification_category.i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                    `description`,
                                    `name`,
                                ]}
                                initFilter={{
                                    categories: [],
                                }}
                                emptyLayout={<EmptyLayout />}
                                columns={[
                                    ...this.tableI18n.columns,
                                    {
                                        label: 'Level',
                                        key: "qualification_level",
                                        className: 'text-left',
                                        dataClassName: 'text-left',
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: 'Category',
                                        key: "qualification_category",
                                        className: 'text-left',
                                        dataClassName: 'text-left',
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: 'Price',
                                        key: "fee",
                                        className: 'text-left',
                                        dataClassName: 'text-left',
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: 'Active',
                                        key: "active",
                                        className: 'text-center',
                                        dataClassName: 'text-center',
                                        mobile: true,
                                        sortable: true,
                                    },
                                ]}
                                onColumnClick={(e, qualification) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            panelProps.onSingleSelectChange(qualification);
                                            break;
                                    }
                                }}
                                desktopWhen='md'
                                pagination={20}
                                renderRow={(columnKey, qualification, spordleTable) => {
                                    if(columnKey.includes('name')){
                                        const lang = columnKey.split('_')[1]; // Get column key lang for i18n helper
                                        return (
                                            <div>
                                                {renderI18nTableColumn(columnKey, qualification)}
                                                <div className="text-muted">{renderI18nTableColumn(`description_${lang}`, qualification)}</div>
                                            </div>
                                        )
                                    }
                                    switch (columnKey){
                                        case 'qualification_level':
                                            return (
                                                <div>
                                                    <DisplayI18n
                                                        field='name'
                                                        defaultValue={qualification?.qualification_level?.name || <Translate id='misc.undefined' />}
                                                        i18n={qualification?.qualification_level?.i18n}
                                                    />
                                                </div>
                                            )
                                        case 'qualification_category':
                                            return (
                                                <div>
                                                    <ClinicCategory color={isNaN(parseInt(qualification?.qualification_category?.color)) ? 0 : qualification?.qualification_category?.color}>
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={qualification?.qualification_category?.name || <Translate id='misc.undefined' />}
                                                            i18n={qualification?.qualification_category?.i18n}
                                                        />
                                                    </ClinicCategory>
                                                </div>
                                            )
                                        case 'fee':
                                            return (
                                                <div>
                                                    {qualification.fee ? <CurrencyFormat currencyDisplay='narrowSymbol' value={qualification.fee / 100} /> : <CurrencyFormat currencyDisplay='narrowSymbol' value={0} />}
                                                </div>
                                            )
                                        case 'active':
                                            return (
                                                <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                    {qualification.active == '1' ?
                                                        <i className="text-primary mdi mdi-check" />
                                                        :
                                                        <i className="text-danger mdi mdi-close" />
                                                    }
                                                </div>
                                            )
                                        default:
                                            break;
                                    }
                                }}
                                rowIsHighlighted={(qualification) => !!qualification.checked}
                                loadData={(from, filterData, spordleTable) => {
                                    const categoriesFilter = filterData.filters.categories;
                                    switch (from){
                                        case 'REFRESH':
                                            spordleTable.setLoading();
                                        case 'FILTER':
                                        case 'CDM':
                                            return this.props.QualificationsContext.getQualifications()
                                                .then((data) => {
                                                    if(categoriesFilter?.length > 0){
                                                        return data.filter((qualification) => {
                                                            return categoriesFilter?.includes(qualification?.qualification_category?.qualification_category_id) ? qualification : false
                                                        })
                                                    }
                                                    return data;

                                                })
                                        default:
                                            break;
                                    }
                                }}
                            >
                                {(spordleTable) => (
                                    <>
                                        <div className='d-flex justify-content-between mb-2'>
                                            <div className="d-flex flex-grow-1 align-items-center ">

                                                {/* Categories Select */}
                                                <div className="mr-2" style={{ minWidth: "220px" }}>
                                                    <SpordleSelect
                                                        multi
                                                        textWhenSetting={{
                                                            count: 1,
                                                            label: 'misc.select.valueContainer.plural',
                                                        }}
                                                        renderOption={(option) => (
                                                            <ClinicCategory color={option.option.color}>
                                                                <DisplayI18n
                                                                    field='name'
                                                                    defaultValue={option.option.label}
                                                                    i18n={option.option.i18n}
                                                                />
                                                            </ClinicCategory>
                                                        )}
                                                        searchKeys={[
                                                            `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                        ]}
                                                        onOptionSelected={(values) => {
                                                            spordleTable.filterChange('categories', values);
                                                            spordleTable.setLoading();
                                                        }}
                                                        loadData={(from, extra, spordleTable) => {
                                                            switch (from){
                                                                case 'CDM':
                                                                    return this.props.QualificationCategoriesContext.getQualificationCategories()
                                                                        .then((qualificationCategories) => {
                                                                            return qualificationCategories.map((qualificationCategorie) => ({
                                                                                value: qualificationCategorie.qualification_category_id,
                                                                                label: qualificationCategorie.name,
                                                                                i18n: qualificationCategorie.i18n,
                                                                                color: isNaN(parseInt(qualificationCategorie.color)) ? 0 : qualificationCategorie.color,
                                                                            }));
                                                                        })
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                {/* Search Input */}
                                                <SpordleTableProvider.SearchInput />
                                            </div>
                                            <div className='text-right nowrap'>
                                                <SpordleTableProvider.Refresh />
                                                <UncontrolledButtonDropdown className='ml-2'>
                                                    <DropdownToggle color='primary' outline caret disabled>
                                                        <i className='fas fa-download' /> <Translate id='misc.export' />
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem disabled><i className='fas fa-file-excel' /> <Translate id='misc.excel' /></DropdownItem>
                                                        <DropdownItem disabled><i className='fas fa-file-pdf' /> <Translate id='misc.pdf' /></DropdownItem>
                                                        <DropdownItem disabled><i className='fas fa-print' /> <Translate id='misc.print' /></DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>

                                                <CanDoAction action='ADD' componentCode='clinics' componentPermissionCode='clinics_settings'>
                                                    <Button tag={Link} className='ml-2' color="primary" to='qualifications-add'><i className="mdi mdi-plus mr-1" /><Translate id='misc.add' /></Button>
                                                </CanDoAction>

                                            </div>
                                        </div>

                                        <SpordleTableView />
                                    </>
                                )}
                            </SpordleTableProvider>
                        )
                    }}
                />
            </Card>
        )
    }
}

export default withContexts(I18nContext, OrganizationContext, QualificationsContext, QualificationCategoriesContext)(ClinicSettingsQualifications);