/*eslint indent: "off"*/
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { history } from './helpers';

// Routes
import PrivateRoute from './routes/PrivateRoutes';
import PublicRoute from './routes/PublicRoutes';

import authRoutes from './routes/authRoutes';
import customRoutes from './routes/customRoutes';

// Context Providers
import I18nContextProvider, { I18nContext } from './contexts/I18nContext';
import AppContextProvider from './contexts/AppContext';
import AuthContextProvider from './contexts/AuthContext';

import OrganizationContextProvider from './contexts/OrganizationContext';

import RolesContextProvider from './contexts/RolesContext';
import IdentityRolesContextProvider from './contexts/IdentityRolesContext';
import MembersContextProvider from './contexts/MembersContext';
import PeriodsContextProvider from './contexts/PeriodsContext';
import SuspensionsContextProvider from './contexts/SuspensionsContext';
import RegistrationDivisionsContextProvider from './contexts/RegistrationDivisionsContext';
import RegistrationClassesContextProvider from './contexts/RegistrationClassesContext';
import ClinicsContextProvider from './contexts/ClinicsContext';
import QualificationCategoriesContextProvider from './contexts/QualificationCategoriesContext';
import QualificationsContextProvider from './contexts/QualificationsContext';
import PrerequisitesContextProvider from './contexts/PrerequisitesContext';
import UtilsContextProvider, { UtilsContext } from './contexts/UtilsContext';
import MerchantAccountContextProvider from './contexts/MerchantAccountContext';
import OtherFeesContextProvider from './contexts/OtherFeesContext';
import ClinicFeesContextProvider from './contexts/ClinicFeesContext';
import OnlineStoreContextProvider from './contexts/OnlineStoreContext';
import AddressTypesContextProvider from './contexts/AddressTypesContext';
import HelpCenterContextProvider from './contexts/HelpCenterContext';
import RegistrationFeeContextProvider from './contexts/RegistrationFeeContext';
import PhoneTypesContextProvider from './contexts/PhoneTypesContext';
import FormsContextProvider from './contexts/FormsContext';
import RebatesContextProvider from './contexts/RebatesContext';
import TermsConditionsContextProvider from './contexts/TermsConditionsContext';
import AffiliationFeesContextProvider from './contexts/AffiliationFeesContext';
import RegistrationCategoriesContextProvider from './contexts/RegistrationCategoriesContext';
import WaiversContextProvider from './contexts/WaiversContext';
import JiraContextProvider from './contexts/JiraContext';
import CrcContextProvider from './contexts/CrcContext';
import DocumentTypesProvider from './contexts/DocumentTypesContext';
import TransferContextProvider from './contexts/TransferContext';
import AppealsContextProvider from './contexts/AppealsContext';
import ReportsContextProvider from './contexts/ReportsContext';
import InjuryContextProvider from './contexts/InjuriesContext';
import NewsContextProvider from './contexts/NewsContext';

import InstallmentModesContextProvider from './contexts/InstallmentModesContext';
import TeamsContextProvider from './contexts/TeamsContext';
import PositionsContextProvider from './contexts/PositionsContext';
import CartsContextProvider from './contexts/CartsContext';
import NotificationsContextProvider from './contexts/NotificationsContext';
import GameIncidentsContextProvider from './contexts/GameIncidentsContext';
import OfficiatingCoachContextProvider from './contexts/OfficiatingCoachContext';
import SupervisionsContextProvider from './contexts/SupervisionsContext';
import DashboardContextProvider from './contexts/DashboardContext';

import QuickViewContextProvider from './contexts/QuickViewContext';

// Language
import { I18nProvider } from './i18n';
import CreditTypesContextProvider from './contexts/CreditTypesContext';

import { ErrorBoundary as SentryErrorBoundary, withProfiler as withSentryProfiler } from '@sentry/react';
import FatalError from './FatalError';
import Spinner from 'reactstrap/es/Spinner';
import SpordleToast from '@spordle/toasts';

import { captureGAError } from '@spordle/ga4';
import { SWRConfig } from 'swr';
import Maintenance from './views/maintenance/Maintenance';
import { DisplayOrganizationModal } from './components/organization/DisplayOrganization';
import Fulllayout from './layouts/fulllayout';
import MessagesContextProvider from './contexts/MessagesContext';
import { FormikElementsGlobalConfigs } from '@spordle/formik-elements';
import { isProdFishNChips } from './helpers/getReferer';
import CartProvider from '@spordle/cart-engine';
import ChecklistsContextProvider from './contexts/ChecklistsContext';
import ChecklistAssistant from "./views/tasks/checklists/components/ChecklistAssistant";

const BrowserNotSupported = lazy(() => import('./BrowserNotSupported'));// Will be loaded only when the user's browser is not supported

//Force amplify rebuild 35
class App extends React.Component{
    constructor(props){
        super(props);
        this.isSupportedBrowser = process.env.NODE_ENV === 'development' || new RegExp(process.env.REACT_APP_USERAGENT_REGEX.substring(1, process.env.REACT_APP_USERAGENT_REGEX.length - 1)).test(window.navigator.userAgent);
    }

    render(){
        return (
            // <React.StrictMode>
            <SentryErrorBoundary
                fallback={FatalError} showDialog onError={(error) => {
                    captureGAError(error.message);
                }}
            >
                <SWRConfig value={{
                    shouldRetryOnError: false,
                    revalidateOnFocus: false,
                    onError: (error) => { console.error(error); },
                }}
                >
                    <I18nContextProvider>
                    <AuthContextProvider>
                    <IdentityRolesContextProvider>
                    <PeriodsContextProvider>
                    <OrganizationContextProvider>
                    <RolesContextProvider>
                    <MembersContextProvider>
                    <SuspensionsContextProvider>
                    <RegistrationDivisionsContextProvider>
                    <RegistrationClassesContextProvider>
                    <RegistrationCategoriesContextProvider>
                    <QualificationCategoriesContextProvider>
                    <QualificationsContextProvider>
                    <PrerequisitesContextProvider>
                    <MerchantAccountContextProvider>
                    <ClinicsContextProvider>
                    <FormsContextProvider>
                    <UtilsContextProvider>
                    <OtherFeesContextProvider>
                    <ClinicFeesContextProvider>
                    <OnlineStoreContextProvider>
                    <AddressTypesContextProvider>
                    <RegistrationFeeContextProvider>
                    <PhoneTypesContextProvider>
                    <RebatesContextProvider>
                    <TermsConditionsContextProvider>
                    <WaiversContextProvider>
                    <AffiliationFeesContextProvider>
                    <CrcContextProvider>
                    <DocumentTypesProvider>
                    <TeamsContextProvider>
                    <CartsContextProvider>
                    <InstallmentModesContextProvider>
                    <PositionsContextProvider>
                    <CreditTypesContextProvider>
                    <TransferContextProvider>
                    <AppealsContextProvider>
                    <ReportsContextProvider>
                    <JiraContextProvider>
                    <InjuryContextProvider>
                    <HelpCenterContextProvider>
                    <NotificationsContextProvider>
                    <GameIncidentsContextProvider>
                    <SupervisionsContextProvider>
                    <MessagesContextProvider>
                    <NewsContextProvider>
                    <OfficiatingCoachContextProvider>
                    <AppContextProvider>
                    <ChecklistsContextProvider>
                    <DashboardContextProvider>
                    <QuickViewContextProvider>
                        <UtilsContext.Consumer>
                            {(utilsContext) => (
                                <FormikElementsGlobalConfigs
                                    formikPhoneInput={{
                                        suggestedCountries: isProdFishNChips() ? [ 'gb' ] : [ 'ca', 'us' ],
                                        defaultSelectedCountry: isProdFishNChips() ? 'gb' : 'ca',
                                    }}
                                    formikDateTime={{
                                        dateFormat: 'YYYY-MM-DD',
                                    }}
                                    formikAddress={{
                                        getCountries: utilsContext.getCountries,
                                        getProvinces: utilsContext.getProvinces,
                                        countryRestrictions: isProdFishNChips() ? [ 'gb' ] : [ 'ca', 'us' ],
                                    }}
                                >
                                    <I18nContext.Consumer>
                                        {({ locale }) => (
                                            <I18nProvider locale={locale}>
                                                <CartProvider accessToken={""} organizationId={""} environment={"int"} initialStepCode={""} steps={[]}>
                                                    <DisplayOrganizationModal />
                                                    <Router basename="/" history={history}>
                                                        <SpordleToast config={{ position: { top: 70 } }} />
                                                        <ChecklistAssistant />
                                                        <Suspense fallback={
                                                            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                                                                <Spinner color="primary" type="grow" />
                                                            </div>
                                                        }
                                                        >
                                                            <Switch>
                                                                {!this.isSupportedBrowser &&
                                                                    <Route component={BrowserNotSupported} />
                                                                }
                                                                <Route path='/maintenance' component={Maintenance} />
                                                                {customRoutes.map((prop, key) => {
                                                                    if(process.env.REACT_APP_ENVIRONMENT !== 'prod' || prop.inProduction){
                                                                        return (
                                                                            <Route
                                                                                // eslint-disable-next-line react/no-array-index-key
                                                                                path={prop.path} key={key}
                                                                                render={(routeProps) => (
                                                                                    <prop.bundleRoute routeProps={routeProps} {...prop} />
                                                                                )}
                                                                            />
                                                                        )
                                                                    }
                                                                    // eslint-disable-next-line react/no-array-index-key
                                                                    return <Redirect to='/' from={prop.path} key={key} />
                                                                    // if(process.env.REACT_APP_ENVIRONMENT !== 'prod')
                                                                    //     return <PublicRoute path={prop.path} key={key} component={prop.component} />;
                                                                    // else if(prop.inProduction)
                                                                    //     return <PublicRoute path={prop.path} key={key} component={prop.component} />;
                                                                    // No route created if IN production AND inProduction IS false
                                                                    // return null;
                                                                })}
                                                                {authRoutes.map((prop, key) => {
                                                                    // eslint-disable-next-line react/no-array-index-key
                                                                    return <PublicRoute path={prop.path} key={key} component={prop.component} />;
                                                                })}
                                                                <PrivateRoute path={'/'} key={'fullLayout'} component={Fulllayout} />
                                                            </Switch>
                                                        </Suspense>
                                                    </Router>
                                                </CartProvider>
                                            </I18nProvider>
                                        )}
                                    </I18nContext.Consumer>
                                </FormikElementsGlobalConfigs>
                            )}
                        </UtilsContext.Consumer>
                    </QuickViewContextProvider>
                    </DashboardContextProvider>
                    </ChecklistsContextProvider>
                    </AppContextProvider>
                    </OfficiatingCoachContextProvider>
                    </NewsContextProvider>
                    </MessagesContextProvider>
                    </SupervisionsContextProvider>
                    </GameIncidentsContextProvider>
                    </NotificationsContextProvider>
                    </HelpCenterContextProvider>
                    </InjuryContextProvider>
                    </JiraContextProvider>
                    </ReportsContextProvider>
                    </AppealsContextProvider>
                    </TransferContextProvider>
                    </CreditTypesContextProvider>
                    </PositionsContextProvider>
                    </InstallmentModesContextProvider>
                    </CartsContextProvider>
                    </TeamsContextProvider>
                    </DocumentTypesProvider>
                    </CrcContextProvider>
                    </AffiliationFeesContextProvider>
                    </WaiversContextProvider>
                    </TermsConditionsContextProvider>
                    </RebatesContextProvider>
                    </PhoneTypesContextProvider>
                    </RegistrationFeeContextProvider>
                    </AddressTypesContextProvider>
                    </OnlineStoreContextProvider>
                    </ClinicFeesContextProvider>
                    </OtherFeesContextProvider>
                    </UtilsContextProvider>
                    </FormsContextProvider>
                    </ClinicsContextProvider>
                    </MerchantAccountContextProvider>
                    </PrerequisitesContextProvider>
                    </QualificationsContextProvider>
                    </QualificationCategoriesContextProvider>
                    </RegistrationCategoriesContextProvider>
                    </RegistrationClassesContextProvider>
                    </RegistrationDivisionsContextProvider>
                    </SuspensionsContextProvider>
                    </MembersContextProvider>
                    </RolesContextProvider>
                    </OrganizationContextProvider>
                    </PeriodsContextProvider>
                    </IdentityRolesContextProvider>
                    </AuthContextProvider>
                    </I18nContextProvider>
                </SWRConfig>
            </SentryErrorBoundary>
            // </React.StrictMode>
        )
    }
}

export default withSentryProfiler(App, { name: 'App' });