import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';

const TransactionCredits = ({ credits }) => {
    return (
        (credits && credits.length > 0) &&
            <div className='mb-3'>
                <div className="h4 font-bold"><Translate id='members.profile.transactions.memberProfileTransactions.credit' /></div>
                <Card className='card-shadow mb-3 p-3'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='h6 mb-0 font-bold'><Translate id='members.profile.transactions.memberProfileTransactions.appliedCredit' /></div>
                        <div className="ml-3 font-medium text-success"><CurrencyFormat value={parseInt(credits.reduce((sum, credit) => sum += parseInt(credit.amount), 0)) / 100} /></div>
                    </div>
                </Card>
            </div>

    );
}

TransactionCredits.propTypes = {
    credits: PropTypes.array.isRequired,
};

export default TransactionCredits;