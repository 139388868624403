import Translate from "@spordle/intl-elements";
import { useState } from "react";
import { ModalBody, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../analytics/AnalyticsModal";
import { CrossFade } from "../../../views/tasks/discrimination/components/modal/DiscriminationAddModal";
import OverlayLoader from "../../loading/OverlayLoader";
import InvoiceEditAddress from "./components/address/InvoiceEditAddress";
import InvoiceEditIdentity from "./components/identity/InvoiceEditIdentity";
import InvoiceButton from "./components/InvoiceButton";

const InvoiceEditModal = (props) => {
    return (
        <AnalyticsModal analyticsName="InvoiceEditModal" isOpen={props.isOpen}>
            <InvoiceEditModalInner {...props} />
        </AnalyticsModal>
    );
}

const InvoiceEditModalInner = ({ toggle, invoice, getInvoice }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ step, setStep ] = useState(1);

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader toggle={toggle}>
                <Translate id='invoice.editModal.title' />
            </ModalHeader>
            <CrossFade isVisible={step === 1}>
                <ModalBody>
                    <InvoiceButton
                        onClick={() => setStep(3)}
                        title={<Translate id='invoice.editModal.editIdentity.btn' />}
                        subtitle={<Translate id='invoice.editModal.editIdentity.btn.helper' />}
                    />
                    <InvoiceButton
                        onClick={() => setStep(2)}
                        icon="dashboard"
                        title={<Translate id='invoice.editModal.editAddress.btn' />}
                        subtitle={<Translate id='invoice.editModal.editAddress.btn.helper' />}
                    />
                </ModalBody>
            </CrossFade>
            <CrossFade isVisible={step === 2}>
                <InvoiceEditAddress
                    step={step}
                    invoice={invoice}
                    getInvoice={getInvoice}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    previous={() => setStep(1)}
                    toggle={toggle}
                />
            </CrossFade>
            <CrossFade isVisible={step === 3}>
                <InvoiceEditIdentity
                    step={step}
                    invoice={invoice}
                    getInvoice={getInvoice}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    previous={() => setStep(1)}
                    toggle={toggle}
                />
            </CrossFade>
        </OverlayLoader>
    )
}

export default InvoiceEditModal;