import { Drawer } from "@mantine/core"
import Translate from "@spordle/intl-elements";
import { fail, success } from "@spordle/toasts";
import moment, { isMoment } from "moment";
import { useContext } from "react";
import { Col, FormGroup, Label, Row, Spinner } from "reactstrap";
import useSWR from "swr";
import { array, mixed, object, string } from "yup";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { UtilsContext } from "../../contexts/UtilsContext";
import { DisplayI18n } from "../../helpers/i18nHelper";
import { InputType, SettingValue } from "../../views/organization/profile/overview/components/regionalSettings/OrganizationRegistrationSettings";
import FormikEditable from "../formik/FormikEditable";

const GlobalSettingsDrawer = ({ settingsDrawerOpen, setSettingsDrawerOpen, ...props }) => {
    const { getSettings } = useContext(UtilsContext);
    const { getOrganizationSettings, organisation_id, updateOrganizationSetting } = useContext(OrganizationContext);


    const { data, isValidating, mutate } = useSWR(
        [ 'SettingsGlobalAdminSettings', organisation_id ],
        () => getSettings({ active: 1 }).then(async(settings) => {
            const filteredSettings = Object.keys(settings)
                .filter((key) => settings[key].is_internal == 1 && !settings[key].resource_id)
                .reduce((filteredSettings, key) => (filteredSettings[key] = settings[key], filteredSettings), {})

            const settingsKeys = Object.keys(filteredSettings);
            const orgSettings = await getOrganizationSettings(organisation_id, { active: 1, code: settingsKeys })
            return {
                settings: filteredSettings,
                orgSettings,
                initialValues: settingsKeys.reduce((initialValues, settingKey) => {
                    switch (filteredSettings[settingKey].type){
                        case 'DATE':
                        case 'TIME':
                        case 'DATETIME':
                            initialValues[settingKey] = orgSettings[settingKey]?.value ? moment(orgSettings[settingKey].value) : '';
                            break;
                        case 'MULTIPLE':
                            initialValues[settingKey] = orgSettings[settingKey]?.value || [];
                            break;
                        case 'BOOLEAN':
                        case 'RADIO':
                        case 'TEXT':
                        default:
                            initialValues[settingKey] = orgSettings[settingKey]?.value || '';
                            break;
                    }
                    return initialValues;
                }, {}),
            };
        }),
        {
            fallbackData: {
                settings: {},
                orgSettings: {},
                initialValues: {},
            },
        },
    );

    return (
        <Drawer
            opened={settingsDrawerOpen}
            onClose={() => setSettingsDrawerOpen(false)}
            title="Global Settings"
            padding="lg"
            size="xl"
            zIndex={3500}
            position={'top'}
            className='d-flex flex-column'
        >
            {isValidating ?
                <div className="text-center py-4"><Spinner type="grow" color="primary" /></div>
                :
                <div style={{ maxHeight: '100%', overflowY: 'scroll', overflowX: "hidden" }}>
                    <Row>
                        {Object.keys(data.settings)
                            .filter((settingKey) => (data.settings[settingKey].type === 'MULTIPLE' || data.settings[settingKey].type === 'RADIO') ? !!data.settings[settingKey].value : true)
                            .map((settingKey) => {
                                return (
                                    <Col xs='12' sm='6' key={settingKey}>
                                        <FormGroup>
                                            <Label for={settingKey} className='text-muted'>
                                                <DisplayI18n field='name' defaultValue={data.settings[settingKey].name} i18n={data.settings[settingKey].i18n} />
                                            </Label>
                                            <FormikEditable
                                                id={settingKey}
                                                disabled={isValidating}
                                                initialValues={{ [settingKey]: data.initialValues[settingKey] }}
                                                validationSchema={object().shape((() => {
                                                    switch (data.settings[settingKey].type){
                                                        case 'TIME':
                                                            return mixed().isDate(<Translate id='form.validation.time.format' />);
                                                        case 'DATE':
                                                        case 'DATETIME':
                                                            return mixed().isDate(<Translate id='form.validation.date.format' />);
                                                        case 'MULTIPLE':
                                                            return array();
                                                        case 'BOOLEAN':
                                                        case 'RADIO':
                                                        case 'TEXT':
                                                            if(settingKey === 'official_website_url'){
                                                                return string().url(<Translate id='form.validation.url.format' />);
                                                            }
                                                        default:
                                                            return string();
                                                    }
                                                }))}
                                                onSubmit={async(values) => {
                                                    const apiValues = Object.keys(values).reduce((formattedValues, valueKey) => {
                                                        if(isMoment(values[valueKey])){
                                                            switch (data.settings[valueKey]?.type){
                                                                case 'TIME':
                                                                    formattedValues[valueKey] = values[valueKey].format('HH:mm');
                                                                    break;
                                                                case 'DATE':
                                                                    formattedValues[valueKey] = values[valueKey].format('YYYY-MM-DD');
                                                                    break;
                                                                case 'DATETIME':
                                                                default:
                                                                    formattedValues[valueKey] = values[valueKey].toISOString();
                                                                    break;
                                                            }
                                                        }else{
                                                            formattedValues[valueKey] = values[valueKey]
                                                        }
                                                        return formattedValues
                                                    }, values)

                                                    return updateOrganizationSetting(organisation_id, settingKey, apiValues[settingKey])
                                                        .then(async() => {
                                                            await mutate()
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message);
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                });
                                                            }
                                                        })
                                                }}
                                            >
                                                {(isEditing, options) => {
                                                    if(!isEditing){
                                                        return <SettingValue orgSetting={data.orgSettings[settingKey]} setting={data.settings[settingKey]} settingKey={settingKey} />

                                                    }
                                                    return (
                                                        <InputType settingKey={settingKey} type={data.settings[settingKey].type} values={data.settings[settingKey].value} />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </FormGroup>
                                    </Col>
                                )
                            })}
                    </Row>
                </div>
            }
        </Drawer>
    )
}

export default GlobalSettingsDrawer;