import Translate, { DateFormat } from '@spordle/intl-elements';
import { fail } from '@spordle/toasts';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Fade, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import FileViewer from '../../../../components/fileViewer/FileViewer';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import CanDoAction from '../../../../components/permissions/CanDoAction';
import QuickViewButton from '../../../../components/quickView/QuickViewButton';
import SidePanel from '../../../../components/sidePanel/SidePanel';
import { formatFileName, getIcon } from '../../../../components/uploader/uploadHelpers';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { MembersContext } from '../../../../contexts/MembersContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import MemberAttachmentApprobationModal from './MemberAttachmentApprobationModal';

const MemberAttachmentApprobationSidepanel = (props) => {
    const membersContext = useContext(MembersContext)

    const [ isFileLoading, setIsFileLoading ] = useState(false)
    const [ fileViewerOpen, setFileViewerOpen ] = useState(false)

    const getDownloadableFile = (member_id, member_attachement_id) => {
        setIsFileLoading(true);
        return membersContext.getMemberAttachmentDownloadLink(member_id, member_attachement_id)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
            .finally(() => setIsFileLoading(false))
    }

    return (
        <Fade in>
            {props.isModalOpen &&
                <MemberAttachmentApprobationModal
                    toggle={props.isModalOpenHandlers.toggle}
                    isOpen={props.isModalOpen}
                    selectedRows={props.selectedRows}
                    getBadgeColour={props.getBadgeColour}
                    spordleTable={props.tableRef}
                    forceCloseSidePanel={props.forceCloseSidePanel}
                />
            }
            {(props.selectedRows.length <= 1) ?
                <OverlayLoader isLoading={isFileLoading}>
                    <SidePanel.Header>
                        <div className='d-flex mb-3 align-items-center'>
                            <SidePanel.ToggleButton />
                            <SidePanel.ActionsMenu>
                                <SidePanel.MenuAction
                                    action="EDIT" componentCode="members" componentPermissionCode="system_document_type_need_approbation"
                                    onClick={props.isModalOpenHandlers.toggle}
                                >
                                    <Translate id='task.document.approval.manage.approval' />
                                </SidePanel.MenuAction>
                            </SidePanel.ActionsMenu>
                        </div>
                        <SidePanel.Title><Translate id='members.profile.overview.memberDocuments.form.label.document' /></SidePanel.Title>
                        <h6 className='font-bold text-dark mb-0'>
                            <QuickViewButton member={{ member_id: props.selectedRows[0].member_id }}>
                                {props.selectedRows[0].first_name} {props.selectedRows[0].last_name}
                            </QuickViewButton>
                        </h6>
                        <SidePanel.Subtitle>
                            <Badge
                                color={props.getBadgeColour(props.selectedRows[0].review_status)}
                            >
                                <Translate id={`task.document.approval.status.${props.selectedRows[0].review_status}`} />
                            </Badge>
                        </SidePanel.Subtitle>
                    </SidePanel.Header>
                    <div className="p-3">
                        <div
                            onClick={() => !isFileLoading && setFileViewerOpen(true)}
                            style={{ height: 150, width: 150 }}
                            className="mb-3 rounded-lg overflow-hidden border shadow-sm clickable card-hover d-flex align-items-center justify-content-center position-relative"
                        >
                            <FileViewer
                                key={props.selectedRows[0].member_attachement_id}
                                fullPath={() => getDownloadableFile(props.selectedRows[0].member_id, props.selectedRows[0].member_attachement_id)}
                                createdBy={props.selectedRows[0].submitted_by}
                                creationDate={<DateFormat value={props.selectedRows[0].created_at} format={DateFormat.formats.longMonthDayYearTime} />}
                                fileName={props.selectedRows[0].attachment.file_name}
                                type={props.selectedRows[0].attachment.file_ext}
                                isOpen={fileViewerOpen}
                                close={() => setFileViewerOpen(false)}
                            />
                            <i style={{ fontSize: '40px' }} className={getIcon(props.selectedRows[0].attachment.file_ext)} />
                            <i className="mdi mdi-download position-absolute text-primary top-0 right-0 font-20 p-1" />
                            <span className="small font-medium text-dark bg-light-inverse position-absolute bottom-0 left-0 right-0 text-center p-1">
                                <span className="d-flex justify-content-center">{formatFileName(props.selectedRows[0].attachment.file_name)}</span>
                                <span className="text-muted"><DateFormat value={props.selectedRows[0].created_at} utc /></span>
                            </span>
                        </div>
                        <div className='mb-3'>
                            <Label tag="div" className="text-muted"><Translate id='members.profile.overview.memberDocuments.form.label.documentName' /></Label>
                            <div className="font-medium text-dark">{props.selectedRows[0].attachment.file_name}</div>
                        </div>
                        <div className='mb-3'>
                            <Label tag="div" className="text-muted"><Translate id='members.profile.overview.memberDocuments.form.label.documentType' /></Label>
                            <div className="font-medium text-dark">
                                {props.selectedRows[0].document_type ?
                                    <DisplayI18n
                                        field="name"
                                        defaultValue={props.selectedRows[0].document_type?.name}
                                        i18n={props.selectedRows[0].document_type?.i18n}
                                    />
                                    :
                                    <Translate id='misc.other' />
                                }
                            </div>
                        </div>
                        {props.selectedRows[0].note &&
                            <div>
                                <Label tag="div" className="text-muted"><Translate id='form.fields.note' /></Label>
                                <div className="font-medium text-dark">
                                    {props.selectedRows[0].note || '-'}
                                </div>
                            </div>
                        }

                        <CanDoAction
                            tag={Button}
                            outline color="primary"
                            className="w-100 mt-4"
                            action="EDIT" componentCode="members" componentPermissionCode="system_document_type_need_approbation"
                            onClick={props.isModalOpenHandlers.toggle}
                        >
                            <Translate id='task.document.approval.manage.approval' />
                        </CanDoAction>
                    </div>
                </OverlayLoader>
                :
                <>
                    <SidePanel.Header noBorder className='sticky-top bg-white z-index-1'>
                        <div className='d-flex mb-2 align-items-center'>
                            <SidePanel.ToggleButton />
                            <div className="ml-auto d-flex align-items-center">
                                <SidePanel.ActionsMenu>
                                    <SidePanel.MenuAction
                                        action="EDIT" componentCode="members" componentPermissionCode="system_document_type_need_approbation"
                                        onClick={props.isModalOpenHandlers.toggle}
                                    >
                                        <Translate id='task.unconfirmedOrg.sidepanel.manage.approval' />
                                    </SidePanel.MenuAction>
                                </SidePanel.ActionsMenu>
                            </div>
                        </div>
                        <div className='w-100 h4 font-bold mb-0 text-break'>
                            <Translate values={{ count: props.selectedRows.length }} id="task.document.sidepanel.count" />
                        </div>
                    </SidePanel.Header>
                    <ListGroup>
                        {props.selectedRows.map((document) => (
                            <ListGroupItem className='px-3' key={document.member_attachement_id}>
                                <UserDisplay>
                                    <UserDisplay.Container>
                                        <UserImg
                                            alt={document.first_name + ' ' + document.last_name}
                                            abbr={document.first_name.charAt(0) + document.last_name.charAt(0)}
                                            src={document.picture?.full_path}
                                            filePos={document.picture?.file_position}
                                            width={60}
                                            className="mr-2"
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container className="w-100">
                                        <UserDisplay.Title className="d-flex justify-content-between">
                                            <div className='d-flex justify-content-start'>
                                                <div>{document.first_name} {document.last_name}</div>
                                                {/* <MemberStatusIcons member={member} className="ml-1 d-flex" /> */}
                                            </div>
                                        </UserDisplay.Title>
                                        <UserDisplay.Subtitle>
                                            <Link className="text-nowrap" to={`/members/profile/${document.member_id}`}>
                                                {document.unique_identifier ?
                                                    <>#{document.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                    :
                                                    '-'
                                                }
                                            </Link>
                                            <div className='text-muted'><Translate id='task.document.approval.filename' /> : <span className='text-dark'>{document.attachment.file_name}</span></div>
                                            <div className='text-muted'><Translate id='task.unconfirmedOrg.sidepanel.submission.date' /> : <span className='text-dark'><DateFormat value={document.submission_date} /></span></div>
                                            <div>
                                                <Translate id='misc.status' /> :
                                                <Badge className='ml-1' color={props.getBadgeColour(document.review_status)}>
                                                    <Translate id={`task.document.approval.status.${document.review_status}`} />
                                                </Badge>
                                            </div>
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </>
            }

        </Fade>
    );
};

export default MemberAttachmentApprobationSidepanel;
