import { FormikError } from "@spordle/formik-elements"
import Translate from "@spordle/intl-elements"
import { Form, Formik } from "formik"
import { Button, Collapse, CustomInput, ModalBody, ModalFooter } from "reactstrap"
import { object, string } from "yup"
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI"
import CustomAlert from "../../../../../components/CustomAlert"
import OverlayLoader from "../../../../../components/loading/OverlayLoader"
import UserDisplay from "../../../../../components/userDisplay/UserDisplay"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import { waiverAccepted, waiverPhysical, waiverVerbal } from "../../../../../helpers/waiversHelper"
import SignWaiversModalHeader from './SignWaiversModalHeader'

const SignWaiversModalSignatureType = ({ toggle, setSignatureType, next, getTeamMemberWaivers, ...props }) => {

    return (
        <Formik
            initialValues={{
                answer: waiverAccepted,
                signatureType: '',
            }}
            validationSchema={object().shape({
                signatureType: string().required(<Translate id='form.fields.waiver.signature.required' />),
            })}
            onSubmit={async(values, { setStatus, setSubmitting }) => {
                setSignatureType(values.signatureType)
                await getTeamMemberWaivers()
                    .then((teamMemberWaivers) => {
                        setSubmitting(false);
                        if(teamMemberWaivers.some((teamMemberWaivers) => teamMemberWaivers.waivers?.length > 0)){
                            next();
                        }else{
                            setStatus(<Translate id='teams.profile.header.signWaivers.noMissingWaivers' />)
                        }
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            setSubmitting(false);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <SignWaiversModalHeader toggle={toggle} />
                        <ModalBody>
                            <CustomAlert
                                color="info" withTitle
                                translateText={false}
                                text={
                                    <>
                                        <div className="mb-3"><Translate id='teams.profile.header.signWaivers.text' /></div>
                                        <Translate id='teams.profile.header.signWaivers.subtitle' />
                                    </>
                                }
                            />
                            <UserDisplay tag="label" htmlFor={waiverVerbal} card hover className={`mb-2 w-100 ${formik.values.signatureType === waiverVerbal ? 'border-primary' : ''}`}>
                                <UserDisplay.Container className="mr-0">
                                    <CustomInput id={waiverVerbal} type="radio" name="type" onChange={() => formik.setFieldValue('signatureType', waiverVerbal)} />
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Title>
                                        <Translate id='components.manualRegistration.waivers.VERBAL' />
                                    </UserDisplay.Title>
                                </UserDisplay.Container>
                            </UserDisplay>
                            <UserDisplay tag="label" htmlFor={waiverPhysical} card hover className={`mb-2 w-100 ${formik.values.signatureType === waiverPhysical ? 'border-primary' : ''}`}>
                                <UserDisplay.Container className="mr-0">
                                    <CustomInput id={waiverPhysical} type="radio" name="type" onChange={() => formik.setFieldValue('signatureType', waiverPhysical)} />
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Title>
                                        <Translate id='components.manualRegistration.waivers.PHYSICAL' />
                                    </UserDisplay.Title>
                                </UserDisplay.Container>
                            </UserDisplay>
                            <FormikError name='signatureType' />

                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                {formik.status &&
                                    <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                }
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                className='ml-auto'
                                color='primary'
                                type='submit'
                            >
                                <Translate id='misc.next' />
                            </Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default SignWaiversModalSignatureType