import { Skeleton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Translate from "@spordle/intl-elements";
import { success } from "@spordle/toasts";
import { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import CrossFade from "../../../../../../components/crossFade/CrossFade";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import I18nHelperContextProvider from '../../../../../../helpers/i18nHelper';
import MemberFieldsEmpty from "./MemberFieldsEmpty";
import MemberFieldsForm from "./MemberFieldsForm";
import MemberFieldsView from "./MemberFieldsView";
import SettingsError from "../SettingsError";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";
import { stringBuilder } from "@spordle/helpers";

const MessagesSettingsMemberFields = () => {
    const [ isEditing, { open: startEditing, close: stopEditing } ] = useDisclosure(false);
    const [ { memberFields, error, isLoading }, setMemberFields ] = useState({ memberFields: [], error: null, isLoading: true });

    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const organizationContext = useContext(OrganizationContext);
    const canEdit = canDoAction("EDIT", "members", "manage_member_fields", true); // Power Users cannot bypass this permission.

    const getMemberFields = () => {
        return organizationContext.getMemberFields()
            .then((memberFields) => {
                setMemberFields({ isLoading: false, error: null, memberFields })
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    setMemberFields((p) => ({ ...p, error: e, isLoading: false }));
                }
            });
    }

    const handleOnSuccess = async() => {
        success();
        await getMemberFields();
        stopEditing();
    }

    useEffect(() => {
        getMemberFields();
    }, []);

    return (
        <Card body className="card-shadow">
            <CardSectionTitle title="organization.settings.registrationSettings.memberFields.title" className='d-flex'>
                {!isEditing && (canEdit || isGod()) &&
                    <div className="ml-auto text-link">
                        <button
                            disabled={!canEdit && !isGod()}
                            onClick={startEditing}
                            className={stringBuilder("reset-btn align-self-end", { "text-purple": isGod() && !canEdit })}
                            type='button'
                        >
                            <Translate id='misc.edit' />
                        </button>
                    </div>
                }
            </CardSectionTitle>
            <p><Translate id="organization.settings.registrationSettings.memberFields.desc" /></p>
            {error ?
                <SettingsError error={error} />
                :
                isLoading ?
                    <Row form>
                        {Array.from({ length: 4 }).map((_, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Col sm={6} className="mb-3" key={`skeleton-${i}`}>
                                <Card className="card-shadow mb-0">
                                    <CardHeader className="h5 bg-white">
                                        <Skeleton width="10ch" height="1em" />
                                    </CardHeader>
                                    <CardBody>
                                        <small><Skeleton width="6ch" height="1em" /></small>
                                        <div className="mt-1"><Skeleton width="15ch" height="1em" /></div>
                                        <small><Skeleton width="6ch" height="1em" /></small>
                                        <div className="mb-0 mt-1"><Skeleton width="20ch" height="1em" /></div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    :
                    memberFields.length === 0 ?
                        <MemberFieldsEmpty />
                        :
                        <I18nHelperContextProvider fields={[ 'description' ]}>
                            <CrossFade unmountOnExit isVisible={isEditing}>
                                <MemberFieldsForm onSuccess={handleOnSuccess} memberFields={memberFields} stopEditing={stopEditing} />
                            </CrossFade>
                            <CrossFade isVisible={!isEditing}>
                                <MemberFieldsView memberFields={memberFields} />
                            </CrossFade>
                        </I18nHelperContextProvider>

            }
        </Card>
    );
}

export default MessagesSettingsMemberFields;