// Prod (HCR30-)
export default(process.env.REACT_APP_ENVIRONMENT == 'prod') ? {
    bug: "100",
    feature: "99",
    training: "98",
    api: '68',
} : { // (UAT-)
    bug: "87",
    feature: "88",
    training: "89",
    api: '68',
}
