import { useSpordleTable } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { FieldArray, Form, Formik } from "formik";
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AnalyticsModal from "../../analytics/AnalyticsModal";
import OverlayLoader from '../../components/loading/OverlayLoader';
import SpordleDraggable from "../DragNDrop/SpordleDraggable";
import SpordleDroppable from "../DragNDrop/SpordleDroppable";
import SpordleDragDropContext from "../DragNDrop/SpordleDragDropContext";

const ColumnDefineModal = ({ columnDefineOpen, setColumnDefineOpen, availableColumns, setAvailableColumns }) => {
    const { getColumns, setColumns, generateId } = useSpordleTable();

    const columns = getColumns();

    const onDragEnd = (result) => {
        // dropped outside the list
        if(!result.destination)return;

        if(result.type === 'SECTION'){ // Section drag n drop
            result.source.payload.move(result.source.index, result.destination.index);
        }else if(result.source.droppableId === result.destination.droppableId){ // In the same section
            result.source.payload.move(result.source.index, result.destination.index);
        }else{ // Inter section
            result.source.payload.remove(result.source.index);
            result.destination.payload.insert(result.destination.index, result.payload);
        }
    }

    return (
        <AnalyticsModal
            analyticsName='registrationReportColumnDefinition'
            isOpen={columnDefineOpen}
            size='lg'
        >
            {columnDefineOpen && // Why did Arnaud not want this here?? EXPLAINNNN
                <Formik
                    initialValues={{
                        availableColumns: [ ...availableColumns ],
                        selectedColumns: [ ...columns ],
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setAvailableColumns(values.availableColumns);
                        setColumns(values.selectedColumns).finally(() => {
                            setSubmitting(false);
                            setColumnDefineOpen(false);
                        })
                    }}
                >{ (formik) => (
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <Form id='registrationReportTableSettingsForm'>
                                <ModalHeader toggle={() => { setColumnDefineOpen(false) }} className="font-medium">
                                    <div className="h4 mb-0 font-medium">
                                        <Translate id='reports.customize.columns' />
                                    </div>
                                </ModalHeader>
                                <ModalBody>
                                    <SpordleDragDropContext onDragEnd={onDragEnd}>
                                        <Row>
                                            <Col md='6' className='pb-3'>
                                                <div className='h4 font-bold'><Translate id='reports.customize.availableColumns' /></div>
                                                <FieldArray name='availableColumns'>
                                                    {(sectionsArrayHelper) => (
                                                    // this div makes it so even when the list is empty we can still drag into it, otherwise the droppable size is 0x0
                                                        <div className='w-100 h-100 d-flex'>
                                                            <SpordleDroppable
                                                                droppableId="available"
                                                                direction='vertical'
                                                                payload={sectionsArrayHelper}
                                                            >
                                                                {(provided) => {
                                                                    return (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            className='w-100 shadow'
                                                                            style={{ height: '400px', overflow: 'hidden', overflowY: 'scroll', border: '1px solid lightgray' }}
                                                                            id={generateId('available', 'droppable')}
                                                                        >
                                                                            {formik.values.availableColumns.map((column, index) => (
                                                                                <SpordleDraggable
                                                                                    key={column.key}
                                                                                    draggableId={column.key}
                                                                                    index={index}
                                                                                    payload={column}
                                                                                >
                                                                                    {(provided) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            className='pl-2 py-1 hoverable d-flex'
                                                                                            id={generateId('available', column.key)}
                                                                                        >
                                                                                            <i className='mdi mdi-drag text-muted' />
                                                                                            {column.isCustomField ?
                                                                                                column.labelText
                                                                                                :
                                                                                                column.label
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                </SpordleDraggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )
                                                                }}
                                                            </SpordleDroppable>
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            </Col>
                                            <Col md='6' className='pb-3'>
                                                <div className='d-flex'>
                                                    <div className='h4 font-bold text-nowrap'>
                                                        <Translate id='reports.customize.selectedColumns' />
                                                    </div>
                                                    <div className='ml-2 h5 text-nowrap'>{`(${formik.values.selectedColumns.length} / ${formik.values.selectedColumns.length + formik.values.availableColumns.length})`}</div>
                                                </div>
                                                <FieldArray name='selectedColumns'>
                                                    {(sectionsArrayHelper) => (
                                                        <div className='w-100 h-100 d-flex'>
                                                            <SpordleDroppable
                                                                droppableId="selected"
                                                                direction='vertical'
                                                                payload={sectionsArrayHelper}
                                                            >
                                                                {(provided) => {
                                                                    return (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            className='w-100 shadow'
                                                                            style={{ height: '400px', overflow: 'hidden', overflowY: 'scroll', border: '1px solid lightgray' }}
                                                                            id={generateId('selected', 'droppable')}
                                                                        >
                                                                            {formik.values.selectedColumns.map((column, index) => (
                                                                                <SpordleDraggable
                                                                                    key={column.key}
                                                                                    draggableId={column.key}
                                                                                    index={index}
                                                                                    payload={column}
                                                                                >
                                                                                    {(provided) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            className='pl-2 py-1 hoverable d-flex'
                                                                                            id={generateId('selected', column.key)}
                                                                                        >
                                                                                            <i className='mdi mdi-drag text-muted' />
                                                                                            {column.isCustomField ?
                                                                                                column.labelText
                                                                                                :
                                                                                                column.label
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                </SpordleDraggable>
                                                                            ))}
                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )
                                                                }}
                                                            </SpordleDroppable>
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            </Col>
                                        </Row>
                                    </SpordleDragDropContext>
                                </ModalBody>
                                <ModalFooter>
                                    <div><Button id={generateId('columnDefine', 'cancelButton')} outline color='primary' disabled={!columnDefineOpen} onClick={() => { setColumnDefineOpen(false) }}><Translate id='misc.cancel' /></Button></div>
                                    <div><Button id={generateId('columnDefine', 'submitButton')} type='submit' color='primary' disabled={formik.isSubmitting || !columnDefineOpen}><Translate id='misc.submit' /></Button></div>
                                </ModalFooter>
                            </Form>
                        </OverlayLoader>
                    )}
                </Formik>
            }
        </AnalyticsModal>

    )
}

export default ColumnDefineModal