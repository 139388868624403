export default{
    "organization.settings.roles.modals.title"         : "Role Permissions for {permissionName}",
    "organization.settings.roles.modals.action.save"   : "Save",
    "organization.settings.roles.modals.action.cancel" : "Cancel",
    "organization.settings.roles.modals.action.clone"  : "Clone to other roles",

    "organization.settings.roles.modals.permission.bulk"           : "Apply to other roles",
    "organization.settings.roles.modals.select.placeholder"        : "Select permissions...",
    "organization.settings.roles.modals.select.applyToAll.title"   : "Apply to all permissions",
    "organization.settings.roles.modals.select.applyToAll.add"     : "Add",
    "organization.settings.roles.modals.select.applyToAll.replace" : "Replace",
    "organization.settings.roles.modals.select.option.ADD"         : "Add",
    "organization.settings.roles.modals.select.option.DELETE"      : "Delete",
    "organization.settings.roles.modals.select.option.READ"        : "Read",
    "organization.settings.roles.modals.select.option.EDIT"        : "Edit",
    "organization.settings.roles.modals.select.option.IMPORT"      : "Import",
    "organization.settings.roles.modals.select.option.EXPORT"      : "Export",

    "organization.settings.roles.modals.applyPermission.title"               : "Apply permission to other roles",
    "organization.settings.roles.modals.applyPermission.label.autorisations" : "Permissions",
    "organization.settings.roles.modals.applyPermission.label.otherRoles"    : "Other roles",
    "organization.settings.roles.modals.applyPermission.otherRoles.disabled" : "Currently managed",
    "organization.settings.roles.modals.applyPermission.helperText"          : "This action will update the current role ({currentRole}) and all other selected roles",

    "organization.settings.roles.modals.clone.title"               : "Clone role permission",
    "organization.settings.roles.modals.clone.description"         : "The {roleName} role permissions will be cloned to the selected other roles bellow",
    "organization.settings.roles.modals.clone.otherRoles.disabled" : "Cannot clone to the current role",
    "organization.settings.roles.modals.clone.helperText"          : "Only the <b>currently</b> saved permissions will be cloned to the other roles.",
    "organization.settings.roles.modals.clone.helperText2"         : "If you have unsaved changes you wish to clone, please save them in the previous step before cloning.",
    "organization.settings.roles.modals.clone.action.clone"        : "Clone",

    "organization.settings.roles.modals.warning.availability" : 'Permissions on this component will not be available without the "READ" permission',
}