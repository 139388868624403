export default{
    "members.profile.transfers.title" : "{transfer_title}",

    "members.profile.transfers.form.title.usa"           : "Create USA {transfer}",
    "members.profile.transfers.form.title.international" : "Create international {transfer}",
    "members.profile.transfers.form.title.cti"           : "Create CTI request",

    "members.profile.transfers.type.ASSOCIATION"   : "Association",
    "members.profile.transfers.type.BRANCH"        : "Branch",
    "members.profile.transfers.type.USA"           : "USA",
    "members.profile.transfers.type.INTERNATIONAL" : "International",
    "members.profile.transfers.status.OPEN"        : "Open",
    "members.profile.transfers.status.CLOSED"      : "Closed",
    "members.profile.transfers.status.REFUSED"     : "Refused",
    "members.profile.transfers.status.COMPLETED"   : "Completed",

    "members.profile.transfers.period"          : "Period",
    "members.profile.transfers.transferType"    : "Type",
    "members.profile.transfers.from"            : "From",
    "members.profile.transfers.to"              : "To",
    "members.profile.transfers.approvedOn"      : "Approved on",
    "members.profile.transfers.status"          : "Status",
    "members.profile.transfers.permanent.share" : "Permanent/Share",

    // table


    // sidepanel
    "members.profile.transfers.sidepanel.transfer.USA"           : "USA {transfer}",
    "members.profile.transfers.sidepanel.transfer.INTERNATIONAL" : "Internationnal {transfer}",
    "members.profile.transfers.sidepanel.transfer.CTI"           : "CTI",
    "members.profile.transfers.sidepanel.from"                   : "From",
    "members.profile.transfers.sidepanel.to"                     : "To",
    "members.profile.transfers.sidepanel.tabs.overview"          : "Overview",
    "members.profile.transfers.sidepanel.tabs.finances"          : "Finances",

    "members.profile.transfers.sidepanel.action.delete" : "You are about to delete this {transfer}",

    "members.profile.transfers.sidepanel.overview.attachments" : "Attachments",

    "members.profile.transfers.sidepanel.finances.receivable"        : "Accounts Receivable",
    "members.profile.transfers.sidepanel.finances.iihfCard"          : "IIHF Card",
    "members.profile.transfers.sidepanel.finances.HCApp"             : "HC App",
    "members.profile.transfers.sidepanel.finances.formerFederation"  : "Former federation",
    "members.profile.transfers.sidepanel.finances.federationId"      : "Federation ID",
    "members.profile.transfers.sidepanel.finances.HCInvoice"         : "HC Invoice #",
    "members.profile.transfers.sidepanel.finances.payable"           : "Accounts Payable",
    "members.profile.transfers.sidepanel.finances.federationAmount"  : "Federation Amount",
    "members.profile.transfers.sidepanel.finances.federationInvoice" : "Federation Invoice #",
    "members.profile.transfers.sidepanel.finances.paid"              : "Paid",

    "members.profile.transfers.sidepanel.finances.owes"               : "Owes",
    "members.profile.transfers.sidepanel.finances.clientOrganization" : "Client organization",
    "members.profile.transfers.sidepanel.finances.invoice"            : "HC invoice number",

    // add (the sidepanel also uses these)
    "members.profile.transfers.add.section.details" : "Details",
    "members.profile.transfers.add.section.to"      : "To",
    "members.profile.transfers.add.section.from"    : "From",
    "members.profile.transfers.add.section.status"  : "Status",

    "members.profile.transfers.add.fields.transferTypes.label"                : "{transfer_title} Type",
    "members.profile.transfers.add.fields.transferTypes.validation.required"  : "Please select a {transfer} type",
    "members.profile.transfers.add.fields.duration.label"                     : "Duration",
    "members.profile.transfers.add.fields.duration.validation.required"       : "Please select a {transfer} duration",
    "members.profile.transfers.add.fields.reason.label"                       : "Reason",
    "members.profile.transfers.add.fields.reason.validation.required"         : "Please select a {transfer} reason",
    "members.profile.transfers.add.fields.iihfCard.label"                     : "IIHF Card Number",
    "members.profile.transfers.add.fields.iihfCard.validation.required"       : "Please enter a IIHF card number",
    "members.profile.transfers.add.fields.extension.label"                    : "Ext",
    "members.profile.transfers.add.fields.extension.validation.required"      : "Please select an extension",
    "members.profile.transfers.add.fields.direction.label"                    : "Direction",
    "members.profile.transfers.add.fields.direction.validation.required"      : "Please select a direction",
    "members.profile.transfers.add.fields.federation.label"                   : "Federation",
    "members.profile.transfers.add.fields.federation.validation.required"     : "Please select a target organization",
    "members.profile.transfers.add.fields.team.label"                         : "Team",
    "members.profile.transfers.add.fields.team.validation.required"           : "Please enter a team name",
    "members.profile.transfers.add.fields.season.label"                       : "Season",
    "members.profile.transfers.add.fields.season.usa.label"                   : "{transfer_title} occurs in",
    "members.profile.transfers.add.fields.season.validation.required"         : "Please select a season",
    "members.profile.transfers.add.fields.status.label"                       : "Status",
    "members.profile.transfers.add.fields.status.validation.required"         : "Please select a status",
    "members.profile.transfers.add.fields.tryOut.label"                       : "Try Out Date",
    "members.profile.transfers.add.fields.sentDate.label"                     : "Sent Date",
    "members.profile.transfers.add.fields.sentDate.validation.required"       : "Please enter a date",
    "members.profile.transfers.add.fields.approveDate.label"                  : "Approve Date",
    "members.profile.transfers.add.fields.withParent.label"                   : "Move with parent",
    "members.profile.transfers.add.fields.notes.label"                        : "Notes",
    "members.profile.transfers.add.fields.releaseFee.label"                   : "Team Release Fee",
    "members.profile.transfers.add.fields.releaseFee.validation.required"     : "Please enter a release fee",
    "members.profile.transfers.add.fields.releaseFeeTeam.label"               : "Team Name (Release Fee)",
    "members.profile.transfers.add.fields.releaseFeeTeam.validation.required" : "Please enter a team name",
    "members.profile.transfers.add.fields.fromTo.validation.same"             : "The target organization cannot be the same as the from organization",

    "members.profile.transfers.add.error.3335" : "The member has already submitted a {transfer} request",
    "members.profile.transfers.add.error.3416" : "There is no current workflow for this {transfer}, we have been notified of the issue. Contact our support for more information.",
    "members.profile.transfers.add.error.3417" : "There is no current workflow for this {transfer}, we have been notified of the issue. Contact our support for more information.",
    "members.profile.transfers.add.error.3441" : "{transfers_title} with the selected target team have been disabled. Contact our support for more information.",
}