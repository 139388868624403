import { useState } from "react";
import { clamp } from "../../helpers/helper";

/**
 * @param {number} stepCount Amount of steps
 * @param {number} [init=0] Initial step. If unspecified, will be set to 0. The count is based on 0, like arrays.
 * @returns {[ number, { next: function, previous: function, goTo: (number) => void}]}
 */
export function useSteps(stepCount, init = 0){
    const [ step, setStep ] = useState(init);

    const goTo = (nb) => setStep(clamp(nb, 0, stepCount));
    const previous = () => goTo(step - 1);
    const next = () => goTo(step + 1);

    return [ step, { next, previous, goTo } ]
}