import SearchImage from '../../assets/images/Search_Image.png';
import ArrowImage from '../../assets/images/ArrowSearch_Image.png';
import Translate from '@spordle/intl-elements';
import PropTypes from 'prop-types';

/**
 * This component shows a preset empty layout, with the arrow and the img
 * @param {Object} props
 * @param {Boolean} [props.noData] Default false, if true doesn't show the arrow img since there is no data to search
 * @param {String|React.ReactNode} [props.title] Title of the empty layout
 * @param {String|React.ReactNode} [props.msg] Message of the empty layout
 * @param {Boolean} [props.flipArrow] Will flip the arrow
 * @param {Boolean} [props.translateTitle] Default false, if true, translates the title
 * @param {Boolean} [props.translateMsg] Default false, if true, translates the msg
 * @param {Boolean} [props.hideTitle] Default false, if true hides the title
 * @param {Boolean} [props.hideMsg] Default false, if true hides the message
 * @param {Boolean} [props.hideArrow] Default false, if true hides the arrow
 * @param {Boolean} [props.hideImage] Default false, if true hides the image
 * @returns {React.ReactNode}
 * @copyright Spordle
 */
const EmptyLayout = ({
    noData = false,
    title = <Translate id='misc.search.empty.title' />,
    msg = <Translate id='misc.search.empty.message.short' />,
    flipArrow = false,
    translateTitle = false,
    translateMsg = false,
    hideTitle = false,
    hideMsg = false,
    hideArrow = false,
    hideImg = false,
}) => (
    <div className='text-center py-4'>
        { (!hideArrow || !hideImg) &&
            <div className="d-flex flex-column flex-md-row justify-content-md-around align-items-md-start align-items-center">
                {!noData && !hideArrow && (flipArrow ? <div className="order-1" style={{ width: '160px' }} /> : <img className="order-1" style={{ width: "160px" }} src={ArrowImage} alt="Search icon" />)}
                {!hideImg && <img className="order-2" src={SearchImage} alt="Search icon" />}
                {!noData && !hideArrow && (flipArrow ? <img className="order-0 order-md-3" style={{ width: "160px", transform: 'scaleX(-1)' }} src={ArrowImage} alt="Search icon" /> : <div className="order-3" style={{ width: '160px' }} />)}
            </div>
        }
        <div>
            {!hideTitle && <div className="h2">{translateTitle ? <Translate id={title} /> : title}</div>}
            {!hideMsg && <div className="h5">{translateMsg ? <Translate id={msg} /> : msg}</div>}
        </div>
    </div>
)

export default EmptyLayout;

EmptyLayout.propTypes = {
    noData: PropTypes.bool,
    title: PropTypes.oneOfType([ PropTypes.element, PropTypes.string ]),
    msg: PropTypes.oneOfType([ PropTypes.element, PropTypes.string ]),
    flipArrow: PropTypes.bool,
    translateTitle: PropTypes.bool,
    translateMsg: PropTypes.bool,
    hideTitle: PropTypes.bool,
    hideMsg: PropTypes.bool,
    hideArrow: PropTypes.bool,
    hideImg: PropTypes.bool,
};

EmptyLayout.defaultProps = {
    noData: false,
    title: <Translate id='misc.search.empty.title' />,
    msg: <Translate id='misc.search.empty.message.short' />,
    flipArrow: false,
    translateTitle: false,
    translateMsg: false,
    hideTitle: false,
    hideMsg: false,
    hideArrow: false,
    hideImg: false,
}