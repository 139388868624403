import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { useContext } from 'react';
import { Button, Collapse, FormGroup, FormText, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AnalyticsModal from '../analytics/AnalyticsModal';
import OverlayLoader from './loading/OverlayLoader';
import { FormikTextArea } from '@spordle/formik-elements';
import CustomAlert from './CustomAlert';
import { success, fail } from '@spordle/toasts';

// contexts
import { CartsContext } from '../contexts/CartsContext';
import { AxiosIsCancelled } from '../api/CancellableAPI';
import FormikTiny from './formik/FormikTiny';
import { DisplayI18n } from '../helpers/i18nHelper';

const CreditInvoiceModal = ({ isOpen, toggle, invoiceNumber, onCreditInvoice }) => {
    const cartsContext = useContext(CartsContext);

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='refundCreditModal'>
            <ModalHeader toggle={toggle}>
                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.credit' />
            </ModalHeader>

            <Formik
                initialValues={{
                    internalNote: '',
                    externalNote: '',
                }}
                validationSchema={object().shape({
                    internalNote: string(),
                    externalNote: string(),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    cartsContext.creditInvoice(invoiceNumber, {
                        internal_note: values.internalNote,
                        external_note: values.externalNote,
                    })
                        .then(() => {
                            onCreditInvoice?.();
                            setSubmitting(false);
                            toggle();
                            success();
                        }).catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                if(error.i18n){
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                }else{
                                    setStatus(<Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.actions.credit.errors.' + error.message} defaultMessageId='misc.error' />)
                                }
                                setSubmitting(false);
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalBody>
                                <FormGroup>
                                    <Label for='internalNote'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.internalNote' /></Label>
                                    <FormikTextArea name='internalNote' id='internalNote' trim rows='2' helper='members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.internalNote.hint' />
                                </FormGroup>
                                <div className="mb-3">
                                    <Label for='externalNote'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.externalNote' /></Label>
                                    <FormikTiny name='externalNote' id='externalNote' init={{ height: 150, selector: 'textarea' }} />
                                    <FormText><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.externalNote.hint' /></FormText>
                                </div>

                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    {formik.status &&
                                        <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                    }
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button disabled={formik.isSubmitting || !isOpen} type='submit' color='primary'><Translate id='misc.confirm' /></Button>
                                <Button disabled={formik.isSubmitting || !isOpen} type='button' color='primary' outline onClick={() => toggle()}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    )
}

export default CreditInvoiceModal