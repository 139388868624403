export default{
    "task.ineligible.title"                             : "Membres potentiellement inéligibles",
    "task.ineligible.modal.manage.title"                : "Gérer les membres potentiellement inéligibles",
    "task.ineligible.member.profile.modal.manage.title" : "Gérer l'inéligibilité potentielle ",
    "task.ineligible.modal.release"                     : "Retirer de la liste",
    "task.ineligible.modal.confirm.release"             : "Confirmer le retrait",
    "task.ineligible.modal.ineligible"                  : "Rendre inéligible",
    "task.ineligible.modal.confirm.ineligible"          : "Confirmer l'inéligibilité",
    "task.ineligible.modal.reason.placeholder"          : "Raison de l'inéligibilité",
    "task.ineligible.modal.reason.validation"           : "Une raison est nécessaire pour rendre un membre inadmissible.",
    "task.ineligible.modal.step.success"                : "Mis à jour avec succès.",
    "task.ineligible.modal.step.success.multi"          : "Il n'y a plus de membres potentiellement inéligibles dans la file d'attente.",

    "task.ineligible.sidepanel.matching.members"              : "Profils potentiels",
    "task.ineligible.sidepanel.matching.caused.members"       : "Causé par",
    "task.ineligible.sidepanel.matching.members.status"       : "Définitivement Inéligible",
    "task.ineligible.sidepanel.matching.no.potential.matches" : "Aucun profils potentiels trouvés.",

    "task.ineligible.member.source.label"                : "Source",
    "task.ineligible.member.created.submitted"           : "Soumis",
    "task.ineligible.member.created.from.ONLINE"         : "En ligne",
    "task.ineligible.member.created.from.MANUAL"         : "Manuel",
    "task.ineligible.member.created.from.THIRD_PARTY"    : "Tierce partie",
    "task.ineligible.member.created.from.IMPORT"         : "Importé",
    "task.ineligible.member.created.from.INITIAL_IMPORT" : "Import initial",
}