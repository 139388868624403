import Translate from '@spordle/intl-elements';
import {
    Alert,
    Button,
    Collapse,
    Fade,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter, ModalHeader
} from "reactstrap";
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { Form, Formik } from 'formik';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { useContext, useRef } from 'react';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import CustomAnimatedIcon from '../../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import { object, string } from 'yup';
import { FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Required from '../../../../../components/formik/Required';
import InlineCopy from '../../../../../components/inlineCopy/InlineCopy';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { getMemberStatusChangeReason } from '../../../../../api/client/potentialIneligibleMembers';
import CrossFade from '../../../../../components/crossFade/CrossFade';
import CustomAlert from '../../../../../components/CustomAlert';
import StatusBadge from '../../../../../components/badges/StatusBadge';
import useSWR from 'swr';
import GenderLabel from '../../../../../components/GenderLabel';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import DisplayBirthdateAge from '../../../../../components/DisplayBirthdateAge';

const ChangeMemberStatusModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName='ChangeMemberStatusModal'>
            <ChangeMemberStatusModalInner {...props} />
        </AnalyticsModal>
    )
}

const ChangeMemberStatusModalInner = ({ toggle, member, refreshMemberProfile, forceCloseSidepanel, statusModalInitialValue }) => {
    const membersContext = useContext(MembersContext)
    const i18nContext = useContext(I18nContext)
    const orgContext = useContext(OrganizationContext)
    const idRolesContext = useContext(IdentityRolesContext);
    const ineligibleOrgCategorySetting = orgContext.settings.organisation_imposing_ineligibility;

    const formikRef = useRef()


    const { data: statuses, isValidating } = useSWR(
        'status-change-modal-statuses',
        () => membersContext.getStatuses().catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
            }
        }),
        {
            fallbackData: [],
        },
    )

    const newStatus = statuses?.find((status) => (status.code === statusModalInitialValue))
    const previousStatus = member.member_status_logs[member.member_status_logs.length - 1]?.member_status
    const previousStatusAPI = statuses?.find((status) => (status.code === previousStatus?.code))

    // statuses that display a lock icon
    const lockedStatuses = [ 'INELIGIBLE', 'BLOCKED' ]

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                member_status: statusModalInitialValue,
                note: '',
                reason: '',
                organisation_deciding: '',
            }}
            validationSchema={object().shape({
                member_status: string().required(<Translate id='members.profile.action.status.required' />),
                note: string().test({
                    name: 'noteWhenNeeded',
                    message: <Translate id='members.profile.action.note.required' />,
                    test: function(note){
                        if(previousStatusAPI?.need_note_out == 1 || newStatus?.need_note == 1)
                            return note;
                        return true // not required
                    },
                }),
                reason: string().test({
                    name: 'reasonWhenNeeded',
                    message: <Translate id='members.profile.action.reason.required' />,
                    test: function(reason){
                        if(previousStatusAPI?.need_reason_out == 1 || newStatus?.need_reason == 1){
                            return reason;
                        }
                        return true // not required
                    },
                }),
                organisation_deciding: string().test({
                    name: 'orgWhenNeeded',
                    message: <Translate id='members.profile.action.org.required' />,
                    test: function(reason){
                        if(previousStatusAPI?.need_reason_out == 1 || newStatus?.need_reason == 1){
                            return reason;
                        }
                        return true // not required
                    },
                }),
            })}
            onSubmit={({ member_status, note, reason, organisation_deciding }, { setSubmitting, setStatus }) => {
                setStatus();
                membersContext.patchMemberStatus(member.member_id, member_status, note, reason, organisation_deciding)
                    .then(() => {
                        membersContext.setShouldAttemptAutoConfirm(false);
                        setStatus('success')
                        setSubmitting(false);
                        refreshMemberProfile();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            setSubmitting(false);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                        }
                    });
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={() => { membersContext.setShouldAttemptAutoConfirm(false); toggle(); }}>
                            <Translate id='members.profile.changeStatus.title' />
                        </ModalHeader>
                        {(formik.status !== 'success') &&
                            <OverlayLoader isLoading={isValidating}>
                                <ModalBody className="text-center">
                                    <UserDisplay card className='w-100'>
                                        <UserDisplay.Container>
                                            <UserImg
                                                abbr={member.first_name[0] + member.last_name[0]}
                                                src={null}
                                                width={50}
                                                height={50}
                                                alt={`${member.first_name} ${member.last_name}`}
                                            />
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title>{member.first_name} {member.last_name}</UserDisplay.Title>
                                            <UserDisplay.Subtitle className="font-medium text-dark"><InlineCopy tag='div' toCopy={member.unique_identifier} className="font-medium">{'#' + member.unique_identifier || '-'}</InlineCopy></UserDisplay.Subtitle>
                                            <UserDisplay.Subtitle><DisplayBirthdateAge birthdate={member.birthdate} age={member.age} /></UserDisplay.Subtitle>
                                            <UserDisplay.Subtitle><GenderLabel gender={member.gender} /></UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                        {lockedStatuses.includes(formik.values.member_status) ?
                                            <UserDisplay.Container className='ml-auto text-body'>
                                                <CustomAnimatedIcon strokeWidth="6px" size="30px" icon="lock" />
                                            </UserDisplay.Container>
                                            :
                                            lockedStatuses.includes(previousStatus?.code) ?
                                                <UserDisplay.Container className='ml-auto text-body'>
                                                    <CustomAnimatedIcon strokeWidth="6px" size="30px" icon="unlock" />
                                                </UserDisplay.Container>
                                                : null
                                        }
                                    </UserDisplay>

                                    <FormGroup className='text-left'>
                                        <Label for="member_status" className="text-muted"><Translate id='members.search.memberAdvanceSearch.filters.memberStatus' /></Label>
                                        <div className='d-flex'>
                                            <div>
                                                <StatusBadge
                                                    color={previousStatus?.color_code}
                                                    status={previousStatus}
                                                />
                                            </div>
                                            <i className='mdi mdi-arrow-right mx-2' />
                                            <div>
                                                <StatusBadge
                                                    color={newStatus?.color_code}
                                                    status={newStatus}
                                                />
                                            </div>
                                        </div>
                                    </FormGroup>
                                    <CrossFade isVisible={newStatus?.need_reason == 1 || previousStatusAPI?.need_reason_out == 1} unmountOnExit>
                                        <FormGroup className="text-left">
                                            <Label for='reason' className='text-muted'><Translate id='members.profile.statusChange.reason' /> <Required /></Label>
                                            <FormikSelect
                                                name='reason'
                                                id='reason'
                                                className='mb-2'
                                                renderOption={({ option }) => (
                                                    <>
                                                        <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                        <div className='small text-muted'><DisplayI18n field='description' defaultValue={option.description} i18n={option.i18n} /></div>
                                                    </>
                                                )}
                                                searchKeys={[ `i18n.${i18nContext.getGenericLocale()}.name` ]}
                                                loadData={(from) => {
                                                    const query = {};

                                                    if(newStatus?.need_reason == 1){
                                                        query.apply_to_member_status = newStatus.member_status_id
                                                    }
                                                    if(previousStatusAPI?.need_reason_out == 1){
                                                        query.apply_to_member_status_out = previousStatusAPI.member_status_id
                                                    }

                                                    switch (from){
                                                        case 'CDM':
                                                            return getMemberStatusChangeReason(query)
                                                                .then((reasons) => reasons.map((reason) => ({
                                                                    value: reason.member_status_reason_id,
                                                                    label: reason.name,
                                                                    i18n: reason.i18n,
                                                                    description: reason.description,
                                                                })));
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                        <FormGroup className="text-left">
                                            <Label for='organisation_deciding' className='text-muted'><Translate id='members.profile.statusChange.organization' /> <Required /></Label>
                                            <FormikSelect
                                                name='organisation_deciding'
                                                id='organisation_deciding'
                                                className='mb-2'
                                                renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                searchKeys={[ `i18n.${i18nContext.getGenericLocale()}.name` ]}
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            formik.setFieldValue('organisation_deciding', idRolesContext.federation.organisation_id)
                                                            return orgContext.getOrganizationByCategories(idRolesContext.federation.organisation_id, { organisation_category_id: ineligibleOrgCategorySetting.value })
                                                                .then((orgs) => [
                                                                    { // we also want federation
                                                                        value: idRolesContext.federation.organisation_id,
                                                                        label: idRolesContext.federation.organisation_name,
                                                                        i18n: idRolesContext.federation.i18n,
                                                                    },
                                                                    ...orgs.map((org) => ({
                                                                        value: org.organisation_id,
                                                                        label: org.organisation_name,
                                                                        i18n: org.i18n,
                                                                    })),
                                                                ]);
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </CrossFade>
                                    <CrossFade isVisible={newStatus?.need_note == 1 || previousStatusAPI?.need_note_out == 1}>
                                        <FormGroup className="text-left">
                                            <Label for='note' className='text-muted'><Translate id='members.profile.blockModal.field.note' /> <Required /></Label>
                                            <FormikTextArea id='note' name='note' rows='2' trim />
                                        </FormGroup>
                                    </CrossFade>

                                    {/* Warning for registrar notification */}
                                    <CrossFade isVisible={formik.values.member_status === 'INELIGIBLE'}>
                                        <CustomAlert className='text-left' color='warning' text={'members.profile.ineligibleModal.message'} />
                                    </CrossFade>

                                    {/* Warning for ineligible */}
                                    <CrossFade isVisible={newStatus?.notification_type_id}>
                                        <CustomAlert className='text-left' color='info' text={'members.profile.changeStatus.notification.message'} />
                                    </CrossFade>
                                    <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                        <Alert color='danger' className='text-left'>{formik.status}</Alert>
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                                        <Translate id='transfer.submit' />
                                    </Button>
                                    <Button color='primary' type='button' onClick={() => { membersContext.setShouldAttemptAutoConfirm(false); toggle(); }} outline disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        }

                        {(formik.status == 'success') &&
                            <div>
                                <ModalBody className="text-center text-dark">
                                    <Fade in>
                                        <div className='position-relative d-inline-block'>
                                            <CustomAnimatedIcon
                                                icon="checkmark"
                                                size={60}
                                                strokeWidth={'6px'}
                                                withCircle
                                                className='text-success mb-2'
                                            />
                                        </div>

                                        <div className="text-center h5 font-bold">
                                            <Translate id="misc.success" />
                                        </div>
                                        <div>
                                            <Translate id='members.profile.action.changestatus.successText' />
                                        </div>
                                        <div className='mt-2'>
                                            <StatusBadge
                                                color={newStatus?.color_code}
                                                status={newStatus}
                                            />
                                        </div>
                                    </Fade>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='button' onClick={() => { toggle(); formik.setStatus(''); forceCloseSidepanel && forceCloseSidepanel(); }} outline disabled={formik.isSubmitting}><Translate id='misc.close' /></Button>
                                </ModalFooter>
                            </div>
                        }
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default ChangeMemberStatusModal;