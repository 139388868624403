import { SpordleTableContext } from '@spordle/datatables';
import { FormikInputText } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import {
    Button, Col, Collapse, FormGroup,
    Label, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { object, string } from 'yup';
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../../helpers/i18nHelper";
import { createPlatformLexicon } from '../../../../api/client/lexicon';
import CustomAlert from '../../../../components/CustomAlert';

const PlatformLexiconAdd = ({ isOpen, toggle }) => {
    const i18nHelper = useContext(I18nHelperContext);
    const spordleTable = useContext(SpordleTableContext);

    return (
        <AnalyticsModal isOpen={isOpen} toggle={toggle} analyticsName='OrganizationPeriods'>
            <Formik
                initialValues={{
                    ...i18nHelper.getInitialValues(),
                    code: '',
                }}
                validationSchema={object().shape({
                    ...i18nHelper.getValidationSchema({ name: string().required(<Translate id='form.validation.name.required' />) }),
                    code: string().required("Duplicate code").test({
                        name: "code-is-unique",
                        message: "Duplicate code",
                        test: (code) => (!spordleTable.getData().some((row) => row.code == code)),
                    }),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    // data to send to api
                    const apiValues = { ...values, ...i18nHelper.getAPIValues(values) };
                    delete apiValues.i18n

                    createPlatformLexicon(apiValues)
                        .then(() => {
                            setStatus(false)
                            spordleTable.refreshTable();
                            toggle();
                        })
                        .catch((e) => {
                            setStatus(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />)
                        })
                        .finally(() => {
                            setSubmitting(false)
                            setStatus(false)
                        })
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalHeader toggle={toggle}><Translate id='settings.tabs.platformLexicon' /></ModalHeader>
                                <ModalBody>
                                    <Row>
                                        <Col md='6'>
                                            <FormGroup>
                                                <Label for='code' className='text-muted'>Code</Label>
                                                <FormikInputText id='code' name='code' />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <RenderI18nForm field={'term'}>
                                        {({ fieldName, fieldLabel }) => {
                                            return (
                                                <Row key={fieldName}>
                                                    <Col md='12'>
                                                        <FormGroup>
                                                            <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                            <FormikInputText id={fieldName} name={fieldName} trim />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            )
                                        }}
                                    </RenderI18nForm>


                                    <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                        {formik.status &&
                                            <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} />
                                        }
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type='button' outline onClick={toggle} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    )
}

export default PlatformLexiconAdd;