export default{
    "teams.profile.teamSetting.tab.title" : "Paramètres",

    "teams.profile.teamSetting.restrictions.title"            : "Restrictions d'équipe",
    "teams.profile.teamSetting.restrictions.none"             : "Aucune restriction...",
    "teams.profile.teamSetting.restrictions.settingName"      : "Nom",
    "teams.profile.teamSetting.restrictions.value"            : "Valeur",
    "teams.profile.teamSetting.restrictions.enforced"         : "Forcé",
    "teams.profile.teamSetting.restrictions.enforced.tooltip" : "Un paramètre forcé empêchera l'utilisateur de faire une action tandis qu'un paramètre non-forcé ne donnera qu'un avertissement visible sur le profil de l'équipe.",

    "teams.profile.teamSetting.settings.categoryQualifications.title" : "Qualifications minimales requises",

    "teams.profile.teamSetting.settings.qualifications.title"             : "Qualifications",
    "teams.profile.teamSetting.settings.qualifications.none"              : "Aucune qualification...",
    "teams.profile.teamSetting.settings.qualifications.all"               : "Toutes obligatoires",
    "teams.profile.teamSetting.settings.qualifications.one"               : "Au moins une obligatoire",
    "teams.profile.teamSetting.settings.qualifications.qualificationName" : "Nom de la qualification",

    "teams.profile.teamSetting.restrictions.description.protect_registration_date"             : "Empêcher l’usager de modifier la date d’ajout du joueur à l’alignement.",
    "teams.profile.teamSetting.restrictions.description.protect_released_date"                 : "Empêcher l’usager de modifier la date de libération du joueur.",
    "teams.profile.teamSetting.restrictions.description.team_fee"                              : "Frais facturé pour l'équipe.",
    "teams.profile.teamSetting.restrictions.description.new_registration_setting"              : "Permettre toutes les inscriptions permet de prendre tous les joueurs même s'ils n'ont jamais fait partie d’une équipe. Sinon, le joueur doit avoir fait partie d’une équipe.",
    "teams.profile.teamSetting.restrictions.description.released_registration_setting"         : "Indique si l’utilisateur peut libérer ou non des joueurs.",
    "teams.profile.teamSetting.restrictions.description.allow_inline_editing_of_jersey"        : "Conserver l'approbation de l'équipe lors de la modification des numéros de chandails.",
    "teams.profile.teamSetting.restrictions.description.maximum_affiliate_registrations"       : "Nombre maximum de joueurs affiliés.",
    "teams.profile.teamSetting.restrictions.description.maximum_import_registrations"          : "Nombre maximum de joueurs importés.",
    "teams.profile.teamSetting.restrictions.description.maximum_tryout_registrations"          : "Nombre maximum de joueurs à l’essai.",
    "teams.profile.teamSetting.restrictions.description.maximum_released_registrations"        : "Nombre maximum de joueurs libérés.",
    "teams.profile.teamSetting.restrictions.description.participants_require_pre_registration" : "Indique si le participant doit avoir une pré-inscription.",
    "teams.profile.teamSetting.restrictions.description.send_email_notice_when_team_changes"   : "Indique si un courriel est envoyé lors du changement sur une équipe.",
    "teams.profile.teamSetting.restrictions.description.maximum_number_players"                : "Nombre maximum de joueurs actifs.",
    "teams.profile.teamSetting.restrictions.description.player_allotment"                      : "Nombre de joueurs permis dans l'équipe (incluant les joueurs libérés).",
    "teams.profile.teamSetting.restrictions.description.minimum_age"                           : "Âge minimum pour le sous-classement («Forcé» est ignoré).",
    "teams.profile.teamSetting.restrictions.description.maximum_age"                           : "Âge maximum pour le sur-classement («Forcé» est ignoré).",

    "teams.profile.teamSetting.settings.warnings.title" : "Avertissements",

    "teams.profile.teamSetting.goTo.settings" : "Aller aux restrictions de l'équipe",
}