import PropTypes from 'prop-types';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import ModalFormBranch from './ModalFormBranch';
import ModalFormFederation from './ModalFormFederation';

/**
* @param {number} viewAs // 0: HC | 1: Branch | 2: MHA
*/
const SettingsRegistrationCategoryAddModal = ({ types, classDivision, modal, toggleModal, viewAs }) => {
    return (
        <AnalyticsModal isOpen={modal} analyticsName='SettingsRegistrationCategoryAddModal'>
            { viewAs == 0 ?
                <ModalFormFederation toggleModal={toggleModal} classDivision={classDivision} />
                :
                <ModalFormBranch toggleModal={toggleModal} classDivision={classDivision} types={types} />
            }
        </AnalyticsModal>
    );
}

export default SettingsRegistrationCategoryAddModal;

SettingsRegistrationCategoryAddModal.propTypes = {
    viewAs: PropTypes.number.isRequired,
};