import Translate from '@spordle/intl-elements'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import QuickViewButton from '../../../../../components/quickView/QuickViewButton'
import UserDisplay from '../../../../../components/userDisplay/UserDisplay'

const TeamSettingsCategoryQualificationsMembers = ({ members, canSeeMember }) => {

    return (
        <Row form className='py-2'>
            {members.map((member) => (
                <Col key={member?.member_id} md='6' lg='4' className='mb-2'>
                    <UserDisplay card className='d-flex h-100 mb-0' style={{ minWidth: '100%' }}>
                        <UserDisplay.Container>
                            <UserDisplay.Title className='text-break d-flex'>
                                <QuickViewButton member={member}>
                                    {member.first_name} {member.last_name}
                                </QuickViewButton>
                            </UserDisplay.Title>
                            <UserDisplay.Subtitle>
                                <div>
                                    {canSeeMember ?
                                        <div className='d-flex'>
                                            <Link to={`/members/profile/${member.member_id}`}>
                                                #{member.unique_identifier}<i className='mdi mdi-chevron-right ml-1' />
                                            </Link>
                                        </div>
                                        :
                                        <>#{member.unique_identifier}</>
                                    }
                                </div>
                            </UserDisplay.Subtitle>
                        </UserDisplay.Container>
                    </UserDisplay>
                </Col>
            ))}
            {canSeeMember && members.length > 1 &&
                <Col key={'quickViewCustomCard'} md='6' lg='4' className='mb-2'>
                    <UserDisplay card className='d-flex h-100 mb-0 clickable' style={{ minWidth: '100%' }}>
                        <UserDisplay.Container>
                            <UserDisplay.Title className='text-break d-flex'>
                                <QuickViewButton member={members}>
                                    <Translate id='teams.profile.settings.open.all.warning.quickview' />
                                </QuickViewButton>
                            </UserDisplay.Title>
                        </UserDisplay.Container>
                    </UserDisplay>
                </Col>
            }
        </Row>
    )
}

export default TeamSettingsCategoryQualificationsMembers
