import React from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { Button, ModalBody, ModalFooter, Collapse, Label, CustomInput, Fade } from 'reactstrap';
import Translate from '@spordle/intl-elements';
import CustomAlert from '../CustomAlert';

// contexts
import { CartsContext } from '../../contexts/CartsContext';
import { MerchantAccountContext } from '../../contexts/MerchantAccountContext';
import withContexts from '../../helpers/withContexts';
import Required from '../formik/Required';
import UserDisplay from '../userDisplay/UserDisplay';

class ManualRegistrationMerchantAccounts extends React.Component{
    render(){
        return (
            <Formik
                enableReinitialize
                initialValues={{
                    merchantAccount: this.props.selectedMerchantAccount || this.props.merchantAccounts?.[0]?.merchant_account_id || '',
                }}
                validationSchema={object().shape({
                    merchantAccount: string().required(<Translate id='memberProfile.registration.merchantAccounts.required' />),
                })}
                onSubmit={(values) => {
                    this.props.onSubmit(values.merchantAccount)
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalBody>
                            <Label className='text-muted'><Translate id='memberProfile.registration.merchantAccounts.label' /> <Required /></Label>

                            {this.props.merchantAccounts &&
                                <Fade in appear mountOnEnter unmountOnExit>
                                    {this.props.merchantAccounts.length <= 0 ?
                                        <Translate id='memberProfile.registration.merchantAccounts.label.none' />
                                        :
                                        this.props.merchantAccounts.map((merchantAccount) => (
                                            <MerchantAccountCard
                                                key={merchantAccount.merchant_account_id}
                                                formik={formik}
                                                merchantAccount={merchantAccount}
                                                defaultSelected={formik.values.merchantAccount === merchantAccount.merchant_account_id}
                                            />
                                        ))
                                    }
                                </Fade>
                            }

                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                {formik.status &&
                                    <CustomAlert className='mt-3' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                }
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            {!this.props.hidePreviousButton &&
                                <Button color='primary' outline type='button' onClick={() => this.props.goToPrevious()} className="mr-auto"><Translate id='misc.previous' /></Button>
                            }
                            <Button color='primary' type='submit'><Translate id='misc.next' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        )
    }
}

const MerchantAccountCard = ({ formik, merchantAccount, defaultSelected }) => {
    return (
        <UserDisplay tag="label" key={merchantAccount.merchant_account_id} card hover className={`mb-2 w-100 ${defaultSelected ? 'border-primary' : ''}`}>
            <UserDisplay.Container className="mr-0">
                <CustomInput key={defaultSelected} defaultChecked={defaultSelected} id={merchantAccount.merchant_account_id} type="radio" name="merchantAccount" onChange={() => formik.setFieldValue('merchantAccount', merchantAccount.merchant_account_id)} />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title>{merchantAccount.name}</UserDisplay.Title>
                <UserDisplay.Subtitle><Translate id={`memberProfile.registration.merchantAccounts.provider.${merchantAccount.provider}`} /></UserDisplay.Subtitle>
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default withContexts(CartsContext, MerchantAccountContext)(ManualRegistrationMerchantAccounts)