import { Tooltip } from '@mantine/core'
import { useSpordleTable } from '@spordle/datatables'
import Translate from '@spordle/intl-elements'
import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { SpordletablePanelContext } from './SpordlePanel'

const SpordlePanelControl = ({ className }) => {
    const spordlePanel = useContext(SpordletablePanelContext);
    const { filteredData, props } = useSpordleTable();
    const { formatMessage } = useIntl();

    return (
        <UncontrolledDropdown>
            <DropdownToggle color='primary' className={className} caret>
                <div className='align-self-center custom-checkbox custom-control'>
                    <input id={props.id + '-topCheckID'} type='checkbox' className='custom-control-input d-none' checked={spordlePanel.state.topChecked} onChange={spordlePanel.onTopCheckChange} />
                    <label className='custom-control-label' htmlFor={props.id + '-topCheckID'} />
                </div>
            </DropdownToggle>
            <DropdownMenu>
                <Tooltip
                    label={formatMessage({ id: 'misc.select.all.tooltip' })}
                    disabled={filteredData.length <= 500}
                    classNames={{
                        root: 'dropdown-item clickable',
                    }}
                    role='menuitem'
                    tabIndex={0}
                    onClick={spordlePanel.checkAllPages}
                    position='right'
                >
                    <Translate id='misc.select.all' />
                </Tooltip>
                <DropdownItem onClick={spordlePanel.checkAll}>
                    <Translate id='misc.select.currentPage' />
                </DropdownItem>
                <DropdownItem onClick={spordlePanel.uncheckAll}><Translate id='misc.unselect.all' /></DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

SpordlePanelControl.defaultProps = {
    className: 'd-flex align-items-center mr-2',
}

export default SpordlePanelControl;