import {
    Container
} from "reactstrap";
import TabRoutesNavs from '../../../components/subSidebar/TabRoutesNavs';
import TabRoutesPanes from '../../../components/subSidebar/TabRoutesPanes';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import settingsClinicTabsRoutes from '../../../routes/tabRoutes/settingsClinicTabsRoutes';

const ClinicSettings = (props) => {
    return (
        <>
            {/* Settings View Header */}
            <ViewHeaderV2 title='settings.clinics.title' />

            <div className="px-4">
                <Container>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Nav Tabs                                                                  */}
                    {/*--------------------------------------------------------------------------------*/}
                    <TabRoutesNavs routes={settingsClinicTabsRoutes} />
                    <TabRoutesPanes routes={settingsClinicTabsRoutes} />
                </Container>
            </div>
        </>
    );
}

export default ClinicSettings;