export default{
    "onlineStore.profile.transactions.modals.refundModal.btn.refund"                             : "Rembourser",
    "onlineStore.profile.transactions.modals.refundModal.form.refundAmount"                      : "Montant",
    "onlineStore.profile.transactions.modals.refundModal.form.refundAmount.helper"               : "Montant disponible",
    "onlineStore.profile.transactions.modals.refundModal.form.paymentMethod"                     : "Méthode  de paiement",
    "onlineStore.profile.transactions.modals.refundModal.form.paymentMethod.CHEQUE"              : "Chèque",
    "onlineStore.profile.transactions.modals.refundModal.form.paymentMethod.CREDITCARD"          : "Carte de crédit",
    "onlineStore.profile.transactions.modals.refundModal.form.paymentMethod.CASH"                : "Espèces",
    "onlineStore.profile.transactions.modals.refundModal.form.cancelInstallments.title"          : "Annuler les versements",
    "onlineStore.profile.transactions.modals.refundModal.form.cancelInstallments"                : "Annuler tous les versements en attente pour ce membre",
    "onlineStore.profile.transactions.modals.refundModal.from.cancelInstallments.members"        : "Membre {active}/{max}",
    "onlineStore.profile.transactions.modals.refundModal.from.cancelInstallments.cancelled"      : "Les versements ont été annulé pour ce membre.",
    "onlineStore.profile.transactions.modals.refundModal.form.internalNote"                      : "Note interne",
    "onlineStore.profile.transactions.modals.refundModal.form.internalNote.helper"               : "Cette note sera visible par les administrateurs uniquement.",
    "onlineStore.profile.transactions.modals.refundModal.form.externalNote"                      : "Note externe",
    "onlineStore.profile.transactions.modals.refundModal.form.externalNote.helper"               : "Cette note sera visible au client. Elle sera envoyée avec le courriel de remboursement et pourra être consultée dans la liste de transactions de leur compte.",
    "onlineStore.profile.transactions.modals.refundModal.form.validation.refundAmount.required"  : "Veuillez indiquer le montant du remboursement.",
    "onlineStore.profile.transactions.modals.refundModal.form.validation.refundAmount.positive"  : "Veuillez indiquer un nombre positif.",
    "onlineStore.profile.transactions.modals.refundModal.form.validation.refundAmount.max"       : "Impossible de rembourser plus que le montant payé ({paidAmount}).",
    "onlineStore.profile.transactions.modals.refundModal.form.validation.paymentMethod.required" : "Veuillez indiquer un moyen de paiement.",
    "onlineStore.profile.transactions.modals.refundModal.title"                                  : "Rembourser la transaction",
    "onlineStore.profile.transactions.modals.refundModal.cancel.msg"                             : "Le remboursement a été réussi.",
    "onlineStore.profile.transactions.modals.refundModal.cancel.question"                        : "Souhaitez-vous annuler le(s) article(s) lié(s) à cette transaction ?",
    "onlineStore.profile.transactions.modals.refundModal.cancel.btn"                             : "Annuler article(s)",

    "onlineStore.profile.transactions.modals.refundModal.error.3045" : "La méthode de paiement du remboursement ne correspond pas à la méthode de paiement de la facture.",
    "onlineStore.profile.transactions.modals.refundModal.error.3433" : "Erreur de remboursement Interpay. SVP réessayez dans quelques minutes.",
    "onlineStore.profile.transactions.modals.refundModal.error.3434" : "Interpay a renvoyé des données non valides. SVP réessayez dans quelques minutes.",
    "onlineStore.profile.transactions.modals.refundModal.error.3435" : "Le montant de remboursement était supérieur au montant à rembourser.",
    "onlineStore.profile.transactions.modals.refundModal.error.3436" : "La transaction n'a pas encore été complétée ou le statut est en attente.",
    "onlineStore.profile.transactions.modals.refundModal.error.3437" : "Erreur de remboursement Paysafe. SVP réessayez dans quelques minutes.",
    "onlineStore.profile.transactions.modals.refundModal.error.3446" : "P:aiement pas trouvé. SVP réessayez dans quelques minutes.",
    "onlineStore.profile.transactions.modals.refundModal.error.3447" : "Erreur de remboursement. SVP réessayez dans quelques minutes.",
    "onlineStore.profile.transactions.modals.refundModal.error.3448" : "Carte expiré ou invalide.",

    "onlineStore.profile.transactions.modals.paymentReceptionModal.title"                     : "Confirmer la réception d'un paiement",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.date"                      : "Date de réception",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.date.required"             : "La date de réception est requise",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.date.after"                : "La date de réception ne peut pas être avant la date de la transaction",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.date.before"               : "La date de réception ne peut pas être après aujourd'hui",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount"                    : "Montant",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.positive"           : "Le montant reçu doit être positif",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.max.credit"         : "Impossible de payer plus que le montant du crédit",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.max.due.member"     : "Impossible d'appliquer ce montant en crédit puisqu'il reste {maxAmount} à payer pour ce membre",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.max.funding.member" : "Le montant de financement maximum pour le membre sélectionné est de {maxAmount}.",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.max.due"            : "Impossible de payer plus que le montant dû ({maxAmount})",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.required"           : "Le montant est requis",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.applicableAmount"   : "Montant applicable ",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.paymentMethod"             : "Moyen de paiement",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.paymentMethod.required"    : "Veuillez indiquer un moyen de paiement",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.memberCredit"              : "Crédit du membre",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.memberCredit.required"     : "Veuillez choisir un crédit de membre",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.memberFunding"             : "Membre",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.memberFunding.required"    : "Veuillez choisir un membre",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.note"                      : "Note",
}