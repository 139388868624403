import { FormikSelect } from "@spordle/formik-elements";
import { Label } from "reactstrap";
import Required from "../../../../../components/formik/Required";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { useContext } from "react";
import { useFormikContext } from "formik";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import Translate from "@spordle/intl-elements";

const AddIneligibleOrgSelect = () => {
    const formik = useFormikContext();
    const orgContext = useContext(OrganizationContext);
    const idRolesContext = useContext(IdentityRolesContext);
    const i18nContext = useContext(I18nContext);
    const orgCategorySetting = orgContext.settings.organisation_imposing_ineligibility.value || [];

    // org imposing ineligibility
    // in settings
    return (
        <>
            <Label for="organisation_deciding" className="text-muted"><Translate id='members.profile.statusChange.organization' /> <Required /></Label>
            <FormikSelect
                name='organisation_deciding'
                id='organisation_deciding'
                className='mb-2'
                renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                searchKeys={[ `i18n.${i18nContext.getGenericLocale()}.name` ]}
                loadData={(from) => {
                    switch (from){
                        case 'CDM':
                            formik.setFieldValue('organisation_deciding', idRolesContext.federation.organisation_id)
                            return orgCategorySetting.length > 0 ? orgContext.getOrganizationByCategories(idRolesContext.federation.organisation_id, { organisation_category_id: orgCategorySetting })
                                .then((orgs) => [
                                    { // we also want federation
                                        value: idRolesContext.federation.organisation_id,
                                        label: idRolesContext.federation.organisation_name,
                                        i18n: idRolesContext.federation.i18n,
                                    },
                                    ...orgs.map((org) => ({
                                        value: org.organisation_id,
                                        label: org.organisation_name,
                                        i18n: org.i18n,
                                    })),
                                ]) : Promise.resolve([]);
                        default:
                            break;
                    }
                }}
            />
            {(orgCategorySetting.length == 0) && <div className="text-danger small"><Translate id='members.setting.organisation_imposing_ineligibility.missing' /></div>}
        </>
    );
}

export default AddIneligibleOrgSelect;