import { stringBuilder } from "@spordle/helpers"
import Translate from "@spordle/intl-elements"
import { Fade } from "reactstrap"
import CardSectionTitle from "../../../../../components/CardSectionTitle"
import CrossFade from "../../../../../components/crossFade/CrossFade"
import HoverableComponent from "../../../../../components/HoverableComponent"
import QuickViewButton from "../../../../../components/quickView/QuickViewButton"

const TeamRosterTableHeader = ({ title, isOpen, isOpenHandlers, spordleTable, displayCount, displayLockNotice }) => {
    const hasMembersInSection = spordleTable.getData().some((teamMember) => teamMember.member)
    return (
        <HoverableComponent>
            {(isHovering) => (
                <CardSectionTitle
                    className={stringBuilder('d-flex clickable', { "border-0 mb-0 p-0": !isOpen })}
                    style={{
                        transition: 'margin-bottom 0.15s ease-in-out, padding-bottom 0.15s ease-in-out',
                    }}
                    onClick={() => isOpen ? isOpenHandlers.toggle() : null}
                >
                    <div className='align-items-center d-flex'>
                        <span className='align-items-center card-section-title d-flex position-absolute' style={{ height: "70px" }}>
                            <div>
                                <div>
                                    {title}
                                    {displayCount(spordleTable)}
                                    {(hasMembersInSection) &&
                                        <QuickViewButton iconBeforeChild disabled={!isOpen} alwaysShowIcon className="d-inline-block ml-2" member={spordleTable.getData().map((teamMember) => teamMember.member)}>
                                            <Fade className='d-inline-block align-middle' in={(isHovering && isOpen)}><div className='font-normal h6 mb-0'><Translate id="misc.quickview.open" /></div></Fade>
                                        </QuickViewButton>
                                    }
                                </div>
                                {(!!displayLockNotice) &&
                                    <div className='small'>
                                        <CrossFade isVisible={!isOpen}>
                                            {displayLockNotice()}
                                        </CrossFade>
                                    </div>
                                }
                            </div>
                        </span>
                    </div>
                    <div className="ml-auto">
                        <div className='align-items-center d-flex justify-content-center p-3 position-relative'>
                            <div onClick={isOpenHandlers.toggle} className={stringBuilder({ "rotate-180": isOpen }, "d-inline-block transition position-absolute align-items-center d-flex justify-content-center")} style={{ height: "70px", width: "70px" }}><i className="mdi mdi-chevron-down font-22" /></div>
                        </div>
                    </div>
                </CardSectionTitle>
            )}
        </HoverableComponent>
    )
}

export default TeamRosterTableHeader;