import { stringBuilder } from "@spordle/helpers";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useState } from "react";
import FileViewer from "../../fileViewer/FileViewer";
import { FormatBytes, formatFileName, getIcon } from "../uploadHelpers";

/**
 * @description Renders a stylized file's info
 * @param {object} props
 * @param {File|object} props.file File to show
 * @param {function} [props.onFileClick] Function called when clicking on file name
 * @param {function} props.handleOnRemove Function called when clicking on remove
 * @param {bool} [props.showRemove=true] Shows or hide remove btn
 * @param {bool} [props.showCreatedAt] Wether or not to show the created date and created by next to the file names
 * @returns {React.ReactNode}
 */
const SingleFile = ({ file, onFileClick, handleOnRemove, showRemove, showCreatedAt }) => {
    const [ isFileViewerOpen, setIsFileViewerOpen ] = useState(false);

    /**
     * @description Renders the name truncated with a label that says new if it's a newly uploaded file
     * @returns {React.ReactNode}
     */
    const renderName = () => {
        return (
            <>
                {formatFileName(file.name)}
                {file instanceof File ?
                    <small className="ml-1 text-success">
                        <div className="small">
                            <Translate id='components.uploader.fileUpload.newFile' />
                        </div>
                    </small>
                    : showCreatedAt && file.created_at ?
                        <div className='ml-auto pl-2 align-self-center small text-muted text-nowrap'>
                            {file.created_by && `${file.created_by.name} ${file.created_by.family_name}`}
                            <span className={stringBuilder({ 'ml-1': file.created_by })}>(<DateFormat value={file.created_at} />)</span>
                        </div>
                        :
                        null}
            </>
        )
    }

    return (
        <div className="d-flex align-items-center">
            <span style={{ minWidth: 0 }} className="files-list-item">
                <i className={stringBuilder(getIcon(file.type), 'mr-1')} />
                <div
                    style={{ minWidth: 0 }} className="d-flex btn-link clickable w-100"
                    onClick={() => setIsFileViewerOpen(true)}
                >
                    <FileViewer
                        createdBy={file.created_by}
                        creationDate={<DateFormat value={file.created_at} format={DateFormat.formats.longMonthDayYearTime} />}
                        fileName={file.name}
                        fullPath={file instanceof File ? file : onFileClick ? () => onFileClick(file) : undefined}
                        isOpen={isFileViewerOpen}
                        close={() => setIsFileViewerOpen(false)}
                        type={file.type}
                    />
                    {renderName()}
                </div>
            </span>
            {file.size && // Shows the file size when there's one
                <span className="small flex-shrink-0 mr-3 text-nowrap">
                    <FormatBytes bytes={file.size} />
                </span>
            }
            {showRemove && // If show remove, will show the btn
                <button
                    type="button"
                    onClick={handleOnRemove}
                    className="reset-btn ml-auto mr-1 text-danger mdi mdi-close flex-shrink-0"
                />
            }
        </div>
    )
}

export default SingleFile;