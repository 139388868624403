export default{
    "transfer.title"                       : "{transfer_title}",
    "transfer.requestType"                 : "Request Type",
    "transfer.from"                        : "From",
    "transfer.to"                          : "To",
    "transfer.to.alt"                      : "{tranfer_title} To",
    "transfer.type"                        : "Type",
    "transfer.currentOrg"                  : "Current Organization",
    "transfer.newOrg"                      : "New Organization",
    "transfer.newOrg.required"             : "Please select a new organization",
    "transfer.newOrg.isNotDifferent"       : "Cannot be the member's current organization",
    "transfer.types"                       : "{transfer_title} Type",
    "transfer.types.required"              : "Please select a {transfer} type",
    "transfer.status"                      : "Status",
    "transfer.status.required"             : "Please select a status",
    "transfer.requestedOn"                 : "Requested On",
    "transfer.createdBy"                   : "Created by",
    "transfer.reason"                      : "Reason",
    "transfer.reason.required"             : "Please select a reason",
    "transfer.invoice"                     : "Invoice",
    "transfer.invoice.CHARGE"              : "Charge",
    "transfer.invoice.NO_CHARGE"           : "No charge",
    "transfer.targetTeam"                  : "Target team",
    "transfer.fromTeam"                    : "From team",
    "transfer.targetAffiliate"             : "Affiliate",
    "transfer.targetPosition"              : "Target position",
    "transfer.targetTryout"                : "Tryout",
    "transfer.targetImport"                : "Import",
    "transfer.organization"                : "Target organization",
    "transfer.organization.required"       : "Please select an organization",
    "transfer.organization.different"      : "The target organization must be different from the member's organization",
    "transfer.sharedExpiration"            : "Shared expiration date",
    "transfer.sharedExpiration.required"   : "Shared expiration date is required.",
    "transfer.sharedExpiration.afterToday" : "Shared expiration date must be after today.",
    "transfer.documentType"                : "Document type",
    "transfer.documentType.required"       : "Document type is required",
    "transfer.documentType.helper"         : "The required document type is missing, please add it to the member registration documents in the organization settings.",
    "transfer.documents.required"          : "At least 1 document is required",
    "transfer.detail"                      : "Detail",
    "transfer.detail.required"             : "Please provide more detail",
    "transfer.supportingDocument"          : "Supporting documents",
    "transfer.supportingDocument.required" : "You must upload at least one support file",
    "transfer.uploadSupportingDocument"    : "Upload Supporting documents",
    "transfer.sendCopyTo"                  : "Send a copy to :",
    "transfer.submit"                      : "Submit",

    "transfer.sidepanel.tabs.transfer.title"                    : "Details",
    "transfer.sidepanel.tabs.team.title"                        : "Teams",
    "transfer.sidepanel.tabs.team.none"                         : "There is no other team associated with this member",
    "transfer.sidepanel.tabs.comment.title"                     : "Comments",
    "transfer.sidepanel.tabs.comment.none"                      : "There is no comment on this {transfer}",
    "transfer.sidepanel.actions.removeShare"                    : "Remove share",
    "transfer.sidepanel.actions.removeShare.msg"                : "You are about to remove sharing for",
    "transfer.sidepanel.actions.removeShare.error.3404"         : "You don't have permission to remove sharing",
    "transfer.sidepanel.actions.removeShare.error.3405"         : "There is no share on this {transfer}",
    "transfer.sidepanel.actions.removeShare.success"            : "Share removed",
    "transfer.sidepanel.actions.removeShare.refresh.fail.title" : "Error while refreshing member",
    "transfer.sidepanel.actions.removeShare.refresh.fail.msg"   : "We could not refresh the member's informations. We recommend leaving the member's profile and coming back.",
    "transfer.sidepanel.actions.addComment"                     : "Add a comment",
    "transfer.sidepanel.modal.addComment.comment"               : "Comment",
    "transfer.sidepanel.modal.addComment.comment.required"      : "Please enter a comment",
    "transfer.sidepanel.review"                                 : "Review",
    "transfer.sidepanel.approveRequest"                         : "Approve Request",
    "transfer.sidepanel.approveRequest.override"                : "Override - Approve",
    "transfer.sidepanel.reviewRequest"                          : "Review Request",
    "transfer.sidepanel.refuseRequest"                          : "Reject Request",
    "transfer.sidepanel.refuseRequest.override"                 : "Override - Reject",
    "transfer.sidepanel.refuseDetail"                           : "Refused Detail",
    "transfer.sidepanel.workflow.step.APPROVED"                 : "Approved",
    "transfer.sidepanel.workflow.step.REFUSED"                  : "Refused",
    "transfer.sidepanel.workflow.step.PENDING"                  : "Pending",
    "transfer.sidepanel.address.none"                           : "This member doesn't have an address",

    "transfer.sidepanel.workflow.override.approved" : "{transfer_title} Override (Approved)",
    "transfer.sidepanel.workflow.override.rejected" : "{transfer_title} Override (Rejected)",

    "transfer.modal.approve.message"         : "You are about to confirm the following request",
    "transfer.modal.approve.approve"         : "Approve",
    "transfer.modal.refuse.message"          : "You are about to refuse the following request",
    "transfer.modal.refuse.details"          : "Details",
    "transfer.modal.refuse.details.required" : "Please provide some details",
    "transfer.modal.refuse.refuse"           : "Refuse",

    "transfer.validation.isPositionSelected" : "A position is mandatory when a team is selected.",

    "transfer.error.3335" : "The member has already submitted a {transfer} request",
    "transfer.error.3191" : "Insufficient permissions",
    "transfer.error.3179" : "Forbidden access",
    "transfer.error.3045" : "The data seems to be incompatible",
    "transfer.error.3060" : "The resource could not be found",
    "transfer.error.3229" : "The organization does not match",
    "transfer.error.3332" : "Invalid file extension",
    "transfer.error.3331" : "The size of the file exceeds the limit",
    "transfer.error.3330" : "The storage system could not delete the file",
    "transfer.error.3403" : "Cannot initiate a {transfer} while the member has an outstanding balance",

    "transfer.table.header.date"                 : "Date",
    "transfer.table.header.details"              : "Details",
    "transfer.table.header.member"               : "Member",
    "transfer.table.header.organization"         : "Organization",
    "transfer.table.header.status"               : "Status",
    "transfer.table.header.status.OPEN"          : "Open",
    "transfer.table.header.status.CLOSED"        : "Closed",
    "transfer.table.header.status.REFUSED"       : "Refused",
    "transfer.table.header.status.COMPLETED"     : "Completed",
    "transfer.table.header.status.REJECTED"      : "Rejected",
    "transfer.table.header.status.EXPIRED"       : "Expired",
    "transfer.table.header.status.EXPIRED_SHARE" : "Expired",
    "transfer.table.header.direction.OUTGOING"   : "Outgoing",
    "transfer.table.header.direction.INCOMING"   : "Incoming",

    "transfer.table.filters.status.placeholder"    : "Filter by status",
    "transfer.table.filters.type.placeholder"      : "Filter by type",
    "transfer.table.filters.direction.placeholder" : "Filter by direction",
    "transfer.table.filters.action.label"          : "Action required",
    "transfer.table.filters.atLeastOne"            : "Must have at least one filter on status or type.",

    "transfer.type.ASSOCIATION"            : "Association",
    "transfer.type.BRANCH"                 : "Branch",
    "transfer.type.USA"                    : "USA",
    "transfer.type.INTERNATIONAL"          : "International",
    "transfer.type.transfer.ASSOCIATION"   : "Association {transfer}",
    "transfer.type.transfer.BRANCH"        : "Branch {transfer}",
    "transfer.type.transfer.USA"           : "USA {transfer}",
    "transfer.type.transfer.INTERNATIONAL" : "International {transfer}",
}