export default{
    "members.profile.overview.memberAddressPhone.title"            : "Adresse(s) et numéro(s) de téléphone",
    "members.profile.overview.memberAddressPhone.title.incomplete" : "Veuillez vérifier toutes les adresses pour ce membre.",
    "members.profile.overview.memberAddressPhone.edit.title"       : "Modifier - Addresse et numéro(s) de téléphone",
    "members.profile.overview.memberAddressPhone.edit.save"        : "Sauvegarder les changements",
    "members.profile.overview.memberAddressPhone.edit.cancel"      : "Annuler les changements",

    "members.profile.overview.memberAddressPhone.address"         : "Adresse(s)",
    "members.profile.overview.memberAddressPhone.address.primary" : "Primaire",
    "members.profile.overview.memberAddressPhone.phoneNumbers"    : "Numéro(s) de téléphone",
    "members.profile.overview.memberAddressPhone.addNumber"       : "Ajouter un numéro de téléphone",
    "members.profile.overview.memberAddressPhone.addAddress"      : "Ajouter une addresse",
    "members.profile.overview.memberAddressPhone.verifyLink"      : "Vérifier l'adresse",
    "members.profile.overview.memberAddressPhone.primaryAddress"  : "Adresse primaire",
    "members.profile.overview.memberAddressPhone.primaryVerify"   : "Veuillez vérifier l'adresse primaire du membre",
    "members.profile.overview.memberAddressPhone.noAdditional"    : "Ce membre ne possède aucune adresse supplémentaire",
    "members.profile.overview.memberAddressPhone.noPrimary"       : "Ce membre ne possède aucune adresse primaire<break></break>Cliquez pour ajouter une adresse",

    "members.profile.overview.memberAddressPhone.modal.addressToChange" : "Adresse à valider:",
    "members.profile.overview.memberAddressPhone.modal.title"           : "Confirmation d'adresse",

    "members.profile.overview.memberAddressPhone.modal.accept.primary"      : "Accepter et définir cette adresse comme primaire.",
    "members.profile.overview.memberAddressPhone.modal.accept.primary.desc" : "Cette option validera l'adresse et la définira comme l'adresse principale de ce membre. ",

    "members.profile.overview.memberAddressPhone.modal.accept.additional"      : "Accepter l'adresse comme une adresse supplémentaire.",
    "members.profile.overview.memberAddressPhone.modal.accept.additional.desc" : "Cette option validera l'adresse et l'ajoutera comme une adresse secondaire.",

    "members.profile.overview.memberAddressPhone.modal.checkbox.documentsValidated" : "Les documents requis ont été validés.",

    "members.profile.overview.memberAddressPhone.modal.reject"      : "Refuser l'adresse.",
    "members.profile.overview.memberAddressPhone.modal.reject.desc" : "Cette option va rejeter l'adresse",

    "members.profile.overview.memberAddressPhone.modal.reject.noteLabel" : "Note de refus",
    "members.profile.overview.memberAddressPhone.modal.reject.required"  : "Une note est requise lors du refus d'une addresse.",

    "members.profile.overview.memberAddressPhone.editModal.title"            : "Modification d'adresse",
    "members.profile.overview.memberAddressPhone.createModal.title"          : "Création d'adresse",
    "members.profile.overview.memberAddressPhone.deleteModal.title"          : "Vous êtes sur le point de supprimer cette adresse",
    "members.profile.overview.memberAddressPhone.status.note"                : "Note de statut de rejection:",
    "members.profile.overview.memberAddressPhone.years_same_address"         : "Année d'aménagement:",
    "members.profile.overview.memberAddressPhone.years_same_address.tooltip" : "Année depuis laquelle le membre a aménagé à cette addresse.",
    "members.profile.overview.memberAddressPhone.created_at"                 : "Créé le:",
    "members.profile.overview.memberAddressPhone.updated_at"                 : "Mis à jour le:",
    "members.profile.overview.memberAddressPhone.open.reject"                : "Cliquer ici pour voir les adresses rejetées ",
    "members.profile.overview.memberAddressPhone.close.reject"               : "Cliquer ici pour fermer les adresses rejetées ",
    "members.profile.overview.button.validate"                               : "Valider",

    "members.profile.overview.memberAddressPhone.status.CONFIRMED"   : "Confirmé",
    "members.profile.overview.memberAddressPhone.status.REJECTED"    : "Rejetée",
    "members.profile.overview.memberAddressPhone.status.UNCONFIRMED" : "Non-confirmée",

    "members.profile.overview.memberAddressPhone.modal.confirm.0" : "L'adresse du membre a été validée et mise comme addresse principale avec succès.",
    "members.profile.overview.memberAddressPhone.modal.confirm.1" : "L'adresse du membre a été validée avec succès.",
    "members.profile.overview.memberAddressPhone.modal.confirm.2" : "Rejeté avec succès l'adresse des membres.",

}