import PlatformSettings from '../../views/settings/platform/PlatformSettings';
import ClinicSettings from '../../views/settings/clinics/ClinicSettings';
import RegistrationSettings from '../../views/settings/registration/SettingsRegistration';
import TeamSettings from '../../views/settings/teams/TeamSettings'
import settingsCategoryTabsRoutes from '../tabRoutes/settingsCategoryTabsRoutes';
import settingsClinicTabsRoutes from '../tabRoutes/settingsClinicTabsRoutes';
import settingsPlatfomTabsRoutes from '../tabRoutes/settingsPlatfomTabsRoutes';
import settingsTeamsTabsRoutes from '../tabRoutes/settingsTeamsTabsRoutes';
import UsersSettings from '../../views/settings/users/UsersSettings';
import Payees from '../../views/settings/payees/Payees';
import settingsOrganizationTabsRoutes from '../tabRoutes/settingsOrganizationTabsRoutes';
import OrganizationSettings from '../../views/settings/organization/OrganizationSettings';

/**
 * @typedef {object} SubRoutes
 * @property {React.ReactNode} [name]
 * @property {boolean} [redirect]
 */
/** @type {Array.<import('react-router-dom').RouteProps & SubRoutes>} */
const settingsRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        title: 'sidebar.settings.menu',
    },
    {
        path: '/platform',
        name: 'sidebar.settings.platformSettings',
        permissions: { code: 'platform' },
        subPermissionsRoutes: settingsPlatfomTabsRoutes,
        component: PlatformSettings,
    },
    {
        path: '/organization',
        name: 'sidebar.settings.organizationSettings',
        permissions: { code: 'settings' },
        subPermissionsRoutes: settingsOrganizationTabsRoutes,
        component: OrganizationSettings,
    },
    {
        path: '/registration',
        name: 'sidebar.settings.registrationSettings', //Category, Division, Class',
        permissions: { code: 'teams' },
        subPermissionsRoutes: settingsCategoryTabsRoutes,
        component: RegistrationSettings,
    },
    {
        path: '/clinics',
        name: 'sidebar.settings.clinicSettings',
        permissions: { code: 'clinics', permissionCode: 'clinics_settings' },
        subPermissionsRoutes: settingsClinicTabsRoutes,
        component: ClinicSettings,
    },
    {
        path: '/teams',
        name: 'sidebar.settings.teams',
        subPermissionsRoutes: settingsTeamsTabsRoutes,
        component: TeamSettings,
    },
    {
        path: '/payees',
        name: 'sidebar.settings.payees',
        permissions: { code: 'organization', permissionCode: 'payee_management' },
        component: Payees,
    },
    {
        path: '/users',
        name: 'sidebar.settings.users',
        permissions: { code: 'settings', permissionCode: 'settings_view_accounts' },
        component: UsersSettings,
    },
    {
        redirect: true,
        dynamicRedirect: true,
        from: '/settings',
        to: '/organization',
    },
];
export default settingsRoutes;
