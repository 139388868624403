import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { stringBuilder } from "@spordle/helpers";

const ClinicColorPicker = (props) => {
    const {
        className,
        // eslint-disable-next-line unused-imports/no-unused-vars
        onChangeColor,
        ...attributes
    } = props;

    const [ dropdown, setDropdown ] = useState(false)
    const toggleDropdown = () => setDropdown(!dropdown)


    const [ colorId, setColorId ] = useState((isNaN(parseInt(props?.color))) ? 0 : parseInt(props.color))
    const setCurrentColor = (id) => {
        toggleDropdown();
        if(props.onChangeColor){
            props.onChangeColor(id)
                .then(() => {
                    setColorId(id);
                })
                .catch(() => {
                    return false;
                })
        }
    }

    const getDropdownClinicDropdown = () => {
        // hardcoded for now
        const nbClinicColors = 6;

        const colors = [];
        for(let i = 0; i < nbClinicColors; i++){
            colors.push(
                <Fragment key={`dropdownColor-${i}`}>
                    <button type="button" onClick={() => setCurrentColor(i)} className={`clinicColor-dropdown-color bg-clinic-${i}`} />
                </Fragment>,
            );
        }

        return (
            <div className="clinicColor-dropdown">
                {colors}
            </div>
        );
    }


    return (
        <div className="clinicColor-wrapper">
            <div {...attributes} onClick={toggleDropdown} className={stringBuilder('clinicColor', className)}>
                <button type="button" className={`clinicColor-currentColor bg-clinic-${colorId}`} />
            </div>
            {dropdown &&
                getDropdownClinicDropdown()
            }
        </div>
    );
};

export default ClinicColorPicker;

ClinicColorPicker.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.number,
    onChangeColor: PropTypes.func,
};