export default{
    "settings.title"                  : "Paramètres",
    "settings.title.platformSettings" : "Configuration de la plateforme",

    "settings.tabs.roles"                : "Rôles",
    "settings.tabs.assignRoles"          : "Assigner des Rôles",
    "settings.tabs.periods"              : "Périodes",
    "settings.tabs.activityPeriods"      : "Périodes d'activité",
    "settings.tabs.contactTypes"         : "Types de contacts",
    "settings.tabs.memberTypes"          : "Types de membres",
    "settings.tabs.general"              : "Général",
    "settings.tabs.suspensions"          : "Suspensions",
    "settings.tabs.divisions"            : "Divisions",
    "settings.tabs.classes"              : "Classes",
    "settings.tabs.phoneTypes"           : "Types de téléphones",
    "settings.tabs.backgroundCheckTypes" : "Types de vérifications d'antécédents",
    "settings.tabs.positions"            : "Positions",
    "settings.tabs.positionGroups"       : "Groupes de positions",
    "settings.tabs.teamAddressTypes"     : "Types d'adresses d'équipes",
    "settings.tabs.teamTravelTypes"      : "Types de voyage d'équipes",
    "settings.tabs.teamContactTypes"     : "Types de contactes d'équipes",
    "settings.tabs.platformLexicon"      : "Lexique",

    // organization settings
    "settings.tabs.organization"                               : "Organisation",
    "settings.tabs.organizationSettings"                       : "Paramètres d'organisation",
    "settings.tabs.organization.settings"                      : "Paramètres",
    "settings.tabs.organization.settings.tooltip"              : "Une des permissions suivantes est requise pour accéder à ces paramètres",
    "settings.tabs.organization.settings.tooltip.none"         : "Aucune permission n'est requise pour accéder à ces paramètres",
    "settings.tabs.organization.settings.tooltip.noPermission" : "Aucune permission n'est requise",
    "settings.tabs.organization.back"                          : "Retour à la liste",
    "settings.tabs.organization.documents"                     : "Documents",
    "settings.tabs.organization.documents.description"         : "Gérer les documents requis pour les inscriptions, les stages et les réclamations d'assurance",
    "settings.tabs.organization.documents.types"               : "Types de documents",
    "settings.tabs.organization.documents.expirations"         : "Expirations de documents",
    "settings.tabs.organization.backgroundChecks"              : "Vérifications d'antécédents",
    "settings.tabs.organization.backgroundChecks.description"  : "Gérer les vérifications d'antécédents disponibles pour votre organisation",
    "settings.tabs.organization.backgroundChecks.types"        : "Types de vérifications d'antécédents",
    "settings.tabs.organization.travelPermits"                 : "Permis de voyage",
    "settings.tabs.organization.travelPermits.description"     : "Gérer les paramètres pour les permis de voyage de votre organisation",
    "settings.tabs.organization.transfer"                      : "Transfert",
    "settings.tabs.organization.transfer.description"          : "Gérer les paramètres pour les transferts de votre organisation",
    "settings.tabs.organization.phoneTypes"                    : "Types de téléphones",
    "settings.tabs.organization.phoneTypes.description"        : "Gérer les types de téléphones pour votre organisation",
    "settings.organization.contactTypes"                       : "Contacts et personnel",
    "settings.tabs.organization.contactTypes"                  : "Contacts et personnel",
    "settings.tabs.organization.contactTypes.description"      : "Gérer les paramètres de contacts et de personnel pour votre organisation",
    "settings.tabs.organization.merchantAccounts"              : "Fournisseurs de compte marchand",
    "settings.tabs.organization.merchantAccounts.settings"     : "Paramètres de fournisseurs",
    "settings.tabs.organization.merchantAccounts.description"  : "Gérer les paramètres des fournisseurs de comptes de marchands de votre organisation.",
    "settings.tabs.organization.contactSettings"               : "Paramètres de contact et de personnel",
    "settings.tabs.organization.messagesSettings"              : "Messages et descriptions",
    "settings.tabs.organization.messagesSettings.settings"     : "Paramètres",
    "settings.tabs.organization.messagesSettings.description"  : "Gérer vos paramètres de messages et descriptions",
    "settings.tabs.organization.contactSettings.description"   : "Gérer les paramètres de contact et de personnel de votre organisation ",
    "settings.tabs.organization.memberTypes"                   : "Types de membres",
    "settings.tabs.organization.memberTypes.description"       : "Gérer les types de membres pour votre organisation",

    "settings.tabs.teams.suspensions"                   : "Suspensions",
    "settings.tabs.teams.suspensions.description"       : "Manage the possible team suspensions.",
    "settings.tabs.teams.positions"                     : "Positions",
    "settings.tabs.teams.positions.description"         : "Manage the available team positions.",
    "settings.tabs.teams.positionGroups"                : "Position Groups",
    "settings.tabs.teams.positionGroups.description"    : "Manage the team position groups.",
    "settings.tabs.teams.teamAddressTypes"              : "Team Address Types",
    "settings.tabs.teams.teamAddressTypes.description"  : "Manage the team address types.",
    "settings.tabs.teams.teamCategoryTypes"             : "Team Category Types",
    "settings.tabs.teams.teamCategoryTypes.description" : "Manage  the team category types.",
    "settings.tabs.teams.teamTravelTypes"               : "Team Travel Types",
    "settings.tabs.teams.teamTravelTypes.description"   : "Manage the team travel types.",
    "settings.tabs.teams.teamContactTypes"              : "Team Contact Types",
    "settings.tabs.teams.teamContactTypes.description"  : "Manage the team contact types.",

    "settings.tabs.teams.ressources"                             : "Ressources",
    "settings.tabs.teams.ressources.description"                 : "Gérer les ressources des équipes.",
    "settings.tabs.teams.print"                                  : "Livre d'équipe",
    "settings.tabs.teams.print.description"                      : "Gérer les paramètres d'impression du livre d'équipe.",
    "settings.tabs.teams.configuration"                          : "Configuration",
    "settings.tabs.teams.configuration.description"              : "Gérer les types de membre de votre équipe.",
    "settings.tabs.teams.restrictions"                           : "Restrictions",
    "settings.tabs.teams.restrictions.description"               : "Gérer les restrictions des équipes de votre organisation.",
    "settings.tabs.teams.qualifications"                         : "Qualifications",
    "settings.tabs.teams.configuration.qualifications"           : "Restrictions de qualification",
    "settings.tabs.teams.configuration.qualifications.empty"     : "Aucune restriction",
    "settings.tabs.teams.configuration.qualifications.empty.txt" : "Aucune restriction de qualification n'a encore été paramétrée.",
}