import { Card } from "reactstrap";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { useContext } from "react";
import { useDisclosure } from "@mantine/hooks";
import CardSectionTitle from "../../../../../components/CardSectionTitle";
import Translate from "@spordle/intl-elements";
import { stringBuilder } from "@spordle/helpers";
import ConfigurationSettingsView from "./view/ConfigurationSettingsView";
import ConfigurationSettingsForm from "./view/ConfigurationSettingsForm";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";


const TeamSettingsConfigurationSettings = () => {
    const [ isEditing, { open: startEditing, close: stopEditing } ] = useDisclosure(false);
    const { isGod } = useContext(IdentityRolesContext);
    const { settings } = useContext(OrganizationContext);

    const canManageMemberTypeSetting = settings.require_member_type_for_rostering?.can_manage == 1;
    const canManagePositionSetting = settings.count_per_position?.can_manage == 1;
    const canManagePosGroupSetting = settings.count_per_position_group?.can_manage == 1;

    const canEdit = canManageMemberTypeSetting || canManagePosGroupSetting || canManagePositionSetting;

    return (
        <Card body className="card-shadow">
            <CardSectionTitle title="settings.teamsSettings.configuration.teamConfiguration" className='d-flex'>
                {(canEdit && !isEditing) &&
                    <div className='ml-auto text-link'>
                        <button
                            onClick={startEditing}
                            className={stringBuilder("reset-btn align-self-end", { "text-purple": isGod() && !canEdit })} type='button'
                        >
                            <Translate id='misc.edit' />
                        </button>
                    </div>
                }
            </CardSectionTitle>
            {isEditing ?
                <ConfigurationSettingsForm stopEditing={stopEditing} />
                :
                <ConfigurationSettingsView />
            }
        </Card>
    );
}

export default TeamSettingsConfigurationSettings;