import Translate from "@spordle/intl-elements";
import { useContext, useState } from 'react';
import { ModalHeader, Collapse, Fade, ModalBody } from 'reactstrap';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { MembersContext } from "../../../../../contexts/MembersContext";
import { RolesContext } from "../../../../../contexts/RolesContext";
import InsuranceMemberSearch from './search/InsuranceMemberSearch';
import StepAccident from './stepAccident/StepAccident';
import StepInjury from './stepInjury/StepInjury';
import StepInsuranceDocuments from "./stepInsuranceDocuments/StepInsuranceDocuments";
import StepInsuranceInfo from './stepInsuranceInfo/StepInsuranceInfo';
import StepTeam from './stepTeam/StepTeam';

/**
 * @typedef {Object} InjuryData
 * @prop {array} bodyParts
 * @prop {array} causes
 * @prop {array} conditions
 * @prop {array} conditions_w_other
 * @prop {array} environments
 * @prop {array} environments_w_other
 * @prop {array} equipments
 * @prop {array} natures
 * @prop {array} onSiteCares
 * @prop {array} payTos
 * @prop {array} payTos_w_other
 * @prop {array} status
 */

/**
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {InjuryData} props.injuryDataLists
 * @param {boolean} props.fromMemberView
 * @param {function} props.toggle
 * @returns {React.ReactNode}
 */
const InsuranceAddModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName="insuranceAddModal">
            <InsuranceAddModalInner {...props} />
        </AnalyticsModal>
    )
}


const InsuranceAddModalInner = ({ fromMemberView = false, injuryDataLists, toggle, docInfo }) => {
    const { isGod } = useContext(IdentityRolesContext);
    const { canDoAction } = useContext(RolesContext);
    const canManageDocuments = canDoAction("ADD", "settings", "manage_injury_attachments");
    const StepComponents = [
        StepAccident,
        StepInjury,
        StepInsuranceInfo,
        ...canManageDocuments || isGod() ? [ StepInsuranceDocuments ] : [],
        StepTeam,
        InsuranceMemberSearch,
    ];
    const SEARCH_STEP = StepComponents.length;

    const { currentMember } = useContext(MembersContext);
    const [ step, setStep ] = useState(fromMemberView ? 1 : SEARCH_STEP);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ data, setData ] = useState({
        accident: {},
        injury: {},
        insurance: {},
        member: fromMemberView ? currentMember : {},
    });

    const updateData = (field, values, partial = false) => {
        setData((prev) => ({ ...prev, [field]: partial ? { ...prev[field], ...values } : values }));
    }

    const changeStep = (nb) => setStep(nb);

    const previous = () => {
        changeStep(step - 1 >= 0 ? step - 1 : 0);
    }

    const next = () => {
        changeStep(step + 1 < StepComponents.length ? step + 1 : StepComponents.length - 1);
    }


    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader toggle={toggle}>
                <Translate id='insurance.addModal.title' /> {step !== SEARCH_STEP && `(${step}/${StepComponents.length - 1})`}
            </ModalHeader>
            {!!data.member?.unique_identifier &&
                <ModalBody className="pb-0">
                    <Collapse appear isOpen>
                        <UserDisplay card className="mb-2 d-flex">
                            <UserDisplay.Container>
                                <UserImg
                                    className="text-uppercase"
                                    width={50}
                                    filePos={data.member.picture?.file_position}
                                    src={data.member.picture?.full_path}
                                    alt={data.member.first_name + ' ' + data.member.last_name}
                                    abbr={data.member.first_name.charAt(0) + data.member.last_name.charAt(0)}
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container>
                                <UserDisplay.Title>{data.member.first_name} {data.member.last_name}</UserDisplay.Title>
                                <UserDisplay.Subtitle>#{data.member.unique_identifier}</UserDisplay.Subtitle>
                            </UserDisplay.Container>
                        </UserDisplay>
                    </Collapse>
                </ModalBody>
            }
            {StepComponents.map((Component, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Collapse key={'step' + index} isOpen={index === step - 1}>
                    <Fade in={index === step - 1}>
                        <Component
                            setIsLoading={setIsLoading}
                            isLoading={isLoading}
                            changeStep={changeStep}
                            injuryDataLists={injuryDataLists}
                            toggle={toggle}
                            next={next}
                            previous={previous}
                            updateData={updateData}
                            data={data}
                            isCurrentStep={index === step - 1}
                            docInfo={docInfo}
                            currentStep={step}
                            fromMemberView={fromMemberView}
                        />
                    </Fade>
                </Collapse>
            ))}
        </OverlayLoader>
    )
}

export default InsuranceAddModal;