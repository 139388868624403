import { FormikCurrencyInput, FormikDateTime, FormikGroup, FormikInputNumber, FormikInputText, FormikRadioButton, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import { useContext, useRef, useState } from 'react';
import {
    Button, Col, Collapse, FormGroup,
    Label, ModalBody, ModalFooter, Row
} from "reactstrap";
import { array, mixed, number, object, string } from 'yup';
import Required from '../../../../../../../../components/formik/Required';
import { I18nContext } from '../../../../../../../../contexts/I18nContext';
import { OrganizationContext } from '../../../../../../../../contexts/OrganizationContext';
import { SuspensionsContext } from '../../../../../../../../contexts/SuspensionsContext';
import { DISCRIMINATION_REASONS } from '../../../../../../../tasks/discrimination/components/DiscriminationHelper';
import { MemberSuspensionAddContext } from './MemberSuspensionAddContext';
import IsGod from '../../../../../../../../components/permissions/IsGod';
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';
import { DisplayCategory } from '../../../../../../../teams/TeamHelpers';
import UserDisplay from '../../../../../../../../components/userDisplay/UserDisplay';
import InlineCopy from '../../../../../../../../components/inlineCopy/InlineCopy';
import { TeamsContext } from '../../../../../../../../contexts/TeamsContext';
import MemberTeamStatus from '../../../../../teams/MemberTeamStatus';
import { stringBuilder } from '@spordle/helpers';
import { MembersContext } from '../../../../../../../../contexts/MembersContext';
import CrossFade from '../../../../../../../../components/crossFade/CrossFade';
import OverlayLoader from '../../../../../../../../components/loading/OverlayLoader';
import { createMemberAdministrativeSuspension } from '../../../../../../../../api/client/members';
import { PeriodsContext } from '../../../../../../../../contexts/contexts';
import { fail, success } from '@spordle/toasts';

const MemberSuspensionAdd1 = () => {
    const formikRef = useRef(null);

    const memberSuspensionAddContext = useContext(MemberSuspensionAddContext);
    const teamsContext = useContext(TeamsContext);
    const suspensionsContext = useContext(SuspensionsContext);
    const organizationContext = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);
    const membersContext = useContext(MembersContext);
    const periodsContext = useContext(PeriodsContext);

    const [ isAdministrative, setIsAdministrative ] = useState(false);

    const formatSuspensions = (suspensions) => {
        return suspensions.reduce((newArray, suspension) => {
            if(suspension.active == 1){
                newArray.push({
                    value: suspension.suspension_id,
                    label: suspension.name,
                    i18n: suspension.i18n,
                    suspension_type: suspension.suspension_type,
                    isAdministrative: suspension.system_type === "ADMINISTRATIVE",
                })
            }
            return newArray
        }, [])
    }

    const formatTeamMembers = (teamMembers) => {
        return teamMembers.reduce((members, tMember) => {
            if(!members.find((m) => m.member_id === tMember.member.member_id)){
                members.push({
                    value: tMember.member.member_id,
                    label: tMember.member.first_name + ' ' + tMember.member.last_name,
                    member_id: tMember.member.member_id,
                    member: tMember.member,
                    tryout: tMember.tryout,
                    import: tMember.import,
                    team_member_status: tMember.team_member_status,
                    position: tMember.position,
                    active: tMember.active,
                })
            }
            return members;
        }, []).sort((a) => a.active == 1 ? -1 : 0)

    }

    /**
     * @description Checks if the code of the suspension corresponds to the discrimination suspension
     * @param {string} id Id of the suspension
     * @returns {boolean}
     */
    const isDiscriminatory = (id) => memberSuspensionAddContext.state?.suspensions?.find((s) => s.suspension_id === id)?.code === "11,4";

    const askForTeamMember = memberSuspensionAddContext.props.linkToMaltreatment && memberSuspensionAddContext.props.team && !memberSuspensionAddContext.props.memberId

    const getMemberMemberTypes = () => {
        const ids = new Set();
        return membersContext.currentMember.member_types.reduce((newArray, memberType) => {
            if(!ids.has(memberType.member_type.member_type_id) && memberType.current_season == 1){
                ids.add(memberType.member_type.member_type_id)
                newArray.push({
                    label: memberType.member_type.name,
                    value: memberType.member_type.member_type_id,
                    memberType: memberType.member_type,
                    i18n: memberType.member_type.i18n,
                })
            }
            return newArray
        }, [])
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                infraction: memberSuspensionAddContext.state.formData.infraction || 'SUSPENSION',
                suspension_id: memberSuspensionAddContext.state.formData.suspension_id || '', // corresponds to the Infraction in the UI
                other_suspension: memberSuspensionAddContext.state.formData.other_suspension || '', // custom suspension, will be added as a suspension by the API
                duration: memberSuspensionAddContext.state.formData.duration || 'DEFINITE', // corresponds to the Suspension Type in the UI
                game_suspended: memberSuspensionAddContext.state.formData.game_suspended || '',
                end_date: memberSuspensionAddContext.state.formData.end_date ? moment(memberSuspensionAddContext.state.formData.end_date, 'YYYY-MM-DD') : '',
                start_date: '',
                discrimination_reason: [],
                incident_date: memberSuspensionAddContext.state.formData.incident_date ? moment(memberSuspensionAddContext.state.formData.incident_date, 'YYYY-MM-DD') : '',
                received_date: memberSuspensionAddContext.state.formData.received_date ? moment(memberSuspensionAddContext.state.formData.received_date, 'YYYY-MM-DD') : '',
                location: memberSuspensionAddContext.state.formData.location || '',
                created_date: memberSuspensionAddContext.state.formData.created_date ? moment(memberSuspensionAddContext.state.formData.created_date, 'YYYY-MM-DD') : '',
                member_id: memberSuspensionAddContext.state.formData.member_id || memberSuspensionAddContext.memberId || [],
                memberTypes: memberSuspensionAddContext.state.formData.memberTypes || [],
                memberTypesSelect: memberSuspensionAddContext.state.formData.memberTypesSelect || [],
                note: '',
            }}
            validationSchema={object().shape({
                infraction: string().required(),
                suspension_id: string().when('infraction', {
                    is: 'SUSPENSION',
                    then: string().required(<Translate id='members.profile.suspensions.infraction.required' />),
                }),
                memberTypes: array().of(object().shape({
                    fee: number(),
                    fine: number(),
                })),
                memberTypesSelect: array().when('doesNotMatter', {
                    is: () => isAdministrative,
                    then: array().min(1, <Translate id='members.profile.suspensions.memberTypes.required' />),
                }),
                discrimination_reason: array().test({
                    name: 'at least one',
                    message: <Translate id='discrimination.form.discriminatoryGrounds.min' />,
                    test: function(grounds){
                        if(this.parent.infraction === 'SUSPENSION'
                            && isDiscriminatory(this.parent.suspension_id)){
                            return grounds.length > 0;
                        }
                        return true;
                    },
                }),
                other_suspension: string().when('infraction', {
                    is: 'OTHER',
                    then: string().required(<Translate id='members.profile.suspensions.infraction.required' />),
                }),
                duration: string().required(),
                game_suspended: number().when('duration', {
                    is: (duration) => duration === 'DEFINITE' && !isAdministrative,
                    then: number().test({
                        name: 'shouldBeRequired',
                        message: <Translate id='members.profile.suspensions.games.required' />,
                        test: function(games){
                            return !!this.parent.end_date || !!games;
                        },
                    }),
                }),
                start_date: mixed().test({
                    name: 'shouldBeRequired',
                    message: <Translate id='members.profile.suspensions.startDate.required' />,
                    test: function(date){
                        return isAdministrative ? isAdministrative && !!date : true;
                    },
                }).test({
                    name: 'startDateFormatTest',
                    message: <Translate id='form.validation.date.format' />,
                    test: function(date){
                        return !date || moment.isMoment(date);
                    },
                }),
                end_date: mixed().when('duration', {
                    is: 'DEFINITE',
                    then: mixed()
                        .test({
                            name: 'shouldBeRequired',
                            message: <Translate id='members.profile.suspensions.endDate.required' />,
                            test: function(date){
                                return !!this.parent.game_suspended || !!date;
                            },
                        })
                        .test({
                            name: 'shouldBeRequiredAdministrative',
                            message: <Translate id='members.profile.suspensions.endDate.required' />,
                            test: function(date){
                                return this.parent.duration === 'DEFINITE' && !!date || !isAdministrative;
                            },
                        })
                        .test({
                            name: 'endDateFormatTest',
                            message: <Translate id='form.validation.date.format' />,
                            test: function(date){
                                return !date || moment.isMoment(date);
                            },
                        })
                        .test({
                            name: 'endDateAfterReceivedTest',
                            message: <Translate id='members.profile.suspensions.endDate.afterReceived' />,
                            test: function(date){
                                if(moment.isMoment(date) && this.parent.received_date && moment.isMoment(this.parent.received_date)){
                                    return moment(date).isAfter(moment(this.parent.received_date))
                                }
                                return true
                            },
                        })
                        .test({
                            name: 'endDateAfterStartDate',
                            message: <Translate id='members.profile.suspensions.endDate.afterStart' />,
                            test: function(date){
                                if(moment.isMoment(date) && this.parent.start_date && moment.isMoment(this.parent.start_date)){
                                    return moment(date).isSameOrAfter(moment(this.parent.start_date))
                                }
                                return true
                            },
                        }),
                }),
                incident_date: mixed().when('doesNotMatter', {
                    is: () => !isAdministrative,
                    then: mixed().required(<Translate id='members.profile.suspensions.incidentDate.required' />).test({
                        name: 'incidentDateFormatTest',
                        message: <Translate id='form.validation.date.format' />,
                        test: moment.isMoment,
                    }).test({
                        name: 'incidentDateAfterTodayTest',
                        message: <Translate id='members.profile.suspensions.incidentDate.afterToday' />,
                        test: (date) => {
                            return moment.isMoment(date) && moment(date).isBefore(moment())
                        },
                    }),
                }),
                received_date: mixed().when('doesNotMatter', {
                    is: () => !isAdministrative,
                    then: mixed().required(<Translate id='members.profile.suspensions.receivedDate.required' />).test({
                        name: 'receivedDateFormatTest',
                        message: <Translate id='form.validation.date.format' />,
                        test: moment.isMoment,
                    }).test({
                        name: 'receivedDateNotBeforeIncidentDateTest',
                        message: <Translate id='members.profile.suspensions.receivedDate.afterIncident' />,
                        test: function(date){
                            if(moment.isMoment(date) && this.parent.incident_date && moment.isMoment(this.parent.incident_date)){
                                return !moment(date).isBefore(moment(this.parent.incident_date))
                            }
                            return true
                        },
                    })
                        .test({
                            name: 'notFuture',
                            message: <Translate id="members.profile.suspensions.endDate.afterToday" />,
                            test: function(date){
                                if(moment.isMoment(date) && this.parent.incident_date && moment.isMoment(this.parent.incident_date)){
                                    return moment(date).isSameOrBefore(moment())
                                }
                                return true
                            },
                        }),
                }),
                location: string().when('doesNotMatter', {
                    is: () => !isAdministrative,
                    then: string().required(<Translate id='members.profile.suspensions.location.required' />),
                }),
                member_id: mixed()
                    .test({
                        name: 'required',
                        message: <Translate id="members.profile.suspensions.teamMember.min" />,
                        test: function(member_id){
                            if(askForTeamMember){
                                return !(!member_id || member_id?.length <= 0)
                            }
                            return true
                        },
                    }),
                note: string(),
            })}
            onSubmit={async(values, { setSubmitting }) => {
                if(isAdministrative){
                    const successSus = [];
                    const failsSus = [];

                    for(let i = 0; i < values.memberTypes.length; i++){
                        const memberType = values.memberTypes[i];
                        const optionnalData = {};
                        if(memberType.fine)
                            optionnalData.fine = (memberType.fine * 100).toFixed(0)
                        if(memberType.fee)
                            optionnalData.fee = (memberType.fee * 100).toFixed(0)

                        await createMemberAdministrativeSuspension(membersContext.currentMember.member_id, {
                            suspension_id: values.suspension_id,
                            member_type_id: memberType.memberTypeId,
                            organisation_id: organizationContext.organisation_id,
                            period_id: periodsContext.selectedPeriod.period_id,
                            duration: values.duration,
                            start_date: values.start_date.format('YYYY-MM-DD'),
                            end_date: moment.isMoment(values.end_date) ? values.end_date.format('YYYY-MM-DD') : '',
                            note: values.note,
                            ...optionnalData,
                        })
                            .then(() => successSus.push(<DisplayI18n field="name" defaultValue={memberType.memberType.name} i18n={memberType.memberType.i18n} />))
                            .catch(() => failsSus.push(<DisplayI18n field="name" defaultValue={memberType.memberType.name} i18n={memberType.memberType.i18n} />))

                    }
                    setSubmitting(false);
                    memberSuspensionAddContext.onSuccess();

                    successSus.forEach((memberType) => {
                        success({
                            info: <Translate id='members.profile.suspensions.success.toast' values={{ memberType: memberType }} />,
                            skipInfoTranslate: true,
                        })
                    })
                    failsSus.forEach((memberType) => {
                        fail({
                            info: <Translate id='members.profile.suspensions.fail.toast' values={{ memberType: memberType }} />,
                            skipInfoTranslate: true,
                        })
                    })
                }else{
                    memberSuspensionAddContext.setData({
                        ...values,
                        end_date: values.duration === 'DEFINITE' && values.end_date ? values.end_date.format('YYYY-MM-DD') : '',
                        incident_date: values.incident_date.format('YYYY-MM-DD'),
                        received_date: values.received_date.format('YYYY-MM-DD'),
                        created_date: values.created_date ? values.created_date.format('YYYY-MM-DD') : '',
                        position_id: values.position_id,
                        member_id: values.member_id,
                        memberTypes: values.memberTypes,
                        memberTypesSelect: values.memberTypesSelect,
                    })
                    memberSuspensionAddContext.goToView(memberSuspensionAddContext.views.suspensionNotes)
                }
            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={formik.isSubmitting}>

                    <Form>
                        <ModalBody>
                            <Row form>
                                {!memberSuspensionAddContext.props.linkToMaltreatment &&
                                    <Col sm="12">
                                        <FormikGroup rowProps={{ className: 'mx-0' }} name='infraction'>
                                            <FormikRadioButton
                                                className='mr-3 mb-3'
                                                id='infraction1' name='infraction'
                                                label='members.profile.suspensions.infraction.existing'
                                                value='SUSPENSION' translateLabel
                                                selected={formik.values.infraction === 'SUSPENSION'}
                                                onChange={() => {
                                                    formik.setFieldValue('infraction', 'SUSPENSION')
                                                    formik.setFieldValue('suspension_id', '');
                                                    setIsAdministrative(false)
                                                }}
                                            />
                                            <FormikRadioButton
                                                className='mr-3 mb-3'
                                                id='infraction2' name='infraction'
                                                label='members.profile.suspensions.infraction.other'
                                                value='OTHER' translateLabel
                                                selected={formik.values.infraction === 'OTHER'}
                                                onChange={() => {
                                                    formik.setFieldValue('infraction', 'OTHER')
                                                    formik.setFieldValue('suspension_id', '');
                                                    setIsAdministrative(false)
                                                }}
                                            />
                                        </FormikGroup>
                                    </Col>
                                }
                                {(memberSuspensionAddContext.props.linkToMaltreatment && memberSuspensionAddContext.props.team && !memberSuspensionAddContext.props.memberId) &&
                                    <>
                                        <Col sm="12">
                                            <FormGroup>
                                                <UserDisplay className="mb-2 w-100" card>
                                                    <UserDisplay.Container>
                                                        <UserDisplay.Title>
                                                            {memberSuspensionAddContext.props.team.name}
                                                        </UserDisplay.Title>
                                                        <UserDisplay.Subtitle>
                                                            <InlineCopy toCopy={memberSuspensionAddContext.props.team.unique_identifier}>
                                                                #{memberSuspensionAddContext.props.team.unique_identifier}
                                                            </InlineCopy>
                                                        </UserDisplay.Subtitle>
                                                        <UserDisplay.Subtitle>
                                                            <DisplayCategory category={memberSuspensionAddContext.props.team.category} short />
                                                        </UserDisplay.Subtitle>
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            </FormGroup>
                                        </Col>

                                        <Col sm="12">
                                            <FormGroup>
                                                <Label for='member_id' className='text-muted'><Translate id='members.profile.suspensions.teamMember' /> <Required /></Label>
                                                <FormikSelect
                                                    name='member_id'
                                                    id='member_id'
                                                    clearable multi
                                                    searchKeys={[
                                                        `member_id`,
                                                        `position.i18n.${getGenericLocale()}.name`,
                                                    ]}
                                                    renderSelectedOption={({ member }) => member.first_name + ' ' + member.last_name}
                                                    renderOption={({ option }) => (
                                                        <>
                                                            <div className='d-flex align-items-center'>
                                                                <div>{option.member.first_name} {option.member.last_name}</div>
                                                                <div className='d-flex align-items-center ml-3'>
                                                                    {option.tryout == 1 &&
                                                                        <MemberTeamStatus
                                                                            className="teamRoster-status mr-2"
                                                                            tip='teams.profile.roster.table.tryout'
                                                                        >
                                                                            <Translate id='teams.profile.roster.table.tryout.short' />
                                                                        </MemberTeamStatus>
                                                                    }
                                                                    {option.import == 1 &&
                                                                        <MemberTeamStatus
                                                                            className="teamRoster-status mr-2"
                                                                            tip="teams.profile.roster.table.imported"
                                                                        >
                                                                            <Translate id='teams.profile.roster.table.imported.short' />
                                                                        </MemberTeamStatus>
                                                                    }
                                                                    {/* TODO: HARDCODED */}
                                                                    {option.team_member_status?.team_member_status_id === '527e78d1-d9e0-11eb-b24f-06b0069ada2c' &&
                                                                        <MemberTeamStatus
                                                                            className="teamRoster-status teamRoster-status-danger mr-2"
                                                                            tip="teams.profile.roster.table.injured"
                                                                        >
                                                                            <Translate id='teams.profile.roster.table.injured.short' />
                                                                        </MemberTeamStatus>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='small'>(<DisplayI18n field="name" defaultValue={option.position.name} i18n={option.position.i18n} />)</div>
                                                            {option.active == 0 && <div className='small text-danger'><i className='mdi mdi-information-outline' /> <Translate id="misc.inactive" /></div>}
                                                        </>
                                                    )}
                                                    onOptionSelected={(values, select) => {
                                                        const selectedMember = select.getSpordleTable().getData().find((option) => option.value === values[0]);
                                                        if(selectedMember){
                                                            formik.setFieldValue('member_id', values)
                                                            formik.setFieldValue('position_id', selectedMember.position.position_id)
                                                        }else{
                                                            formik.setFieldValue('member_id', "")
                                                            formik.setFieldValue('position_id', "")
                                                        }
                                                    }}
                                                    loadData={(from) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return teamsContext.getTeamMembers(memberSuspensionAddContext.props.team.team_id)
                                                                    .then((teamMembers) => formatTeamMembers(teamMembers))
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </>
                                }
                                <Col sm="12">
                                    <FormGroup>
                                        <Collapse isOpen={formik.values.infraction === 'SUSPENSION'}>
                                            <Label for='suspension_id' className='text-muted'><Translate id='members.profile.suspensions.infraction' /> <Required /></Label>
                                            <FormikSelect
                                                name='suspension_id'
                                                id='suspension_id'
                                                searchKeys={[
                                                    `i18n.${getGenericLocale()}.name`,
                                                    `suspension_type.i18n.${getGenericLocale()}.name`,
                                                ]}
                                                renderOption={({ option }) => (
                                                    <>
                                                        <div className='mb-0'><DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} /></div>
                                                        {option.suspension_type && <div className='small'><DisplayI18n field="name" defaultValue={option.suspension_type.name} i18n={option.suspension_type.i18n} /></div>}
                                                    </>
                                                )}
                                                values={memberSuspensionAddContext.state.suspensions?.length === 1 ? [ memberSuspensionAddContext.state.suspensions?.[0]?.suspension_id ] : undefined}
                                                loadData={(from) => {
                                                    switch (from){
                                                        case 'CDM':
                                                            return suspensionsContext.getSuspensions(organizationContext.organisation_id, { active: 1, visible_on_maltreatment: memberSuspensionAddContext.props.linkToMaltreatment ? 1 : 0 })
                                                                .then((suspensions) => {
                                                                    if(suspensions.length === 1){
                                                                        formikRef.current?.setFieldValue('suspension_id', suspensions[0].suspension_id)
                                                                        setIsAdministrative(suspensions[0].system_type === "ADMINISTRATIVE")
                                                                    }

                                                                    if(suspensions.find((suspension) => suspension.suspension_id === formik.values.suspension_id)?.system_type === "ADMINISTRATIVE"){
                                                                        setIsAdministrative(true)
                                                                    }
                                                                    memberSuspensionAddContext.setSuspensions(suspensions)
                                                                    return formatSuspensions(suspensions)
                                                                })
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                onOptionSelected={(values, select) => {
                                                    setIsAdministrative(select.getSpordleTable().getData().find((option) => option.value === values[0])?.isAdministrative)
                                                }}
                                            />
                                        </Collapse>
                                        <Collapse isOpen={formik.values.infraction === 'OTHER'}>
                                            <Label for='other_suspension' className='text-muted'><Translate id='members.profile.suspensions.infraction.other' /> <Required /></Label>
                                            <FormikInputText id='other_suspension' name='other_suspension' />
                                        </Collapse>
                                    </FormGroup>
                                </Col>

                                {!memberSuspensionAddContext.props.linkToMaltreatment &&
                                    <FieldArray name='memberTypes'>
                                        {(helpers) => (
                                            <>
                                                {/* we have a FieldArray controlled by a FormikSelect */}
                                                {/* We need 2 formik values for this, because the FormikSelect's value can only be a string */}
                                                {/* and we need fee, fine, id, etc. in a FieldArray's values */}
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label for='memberTypesSelect' className='text-muted'><Translate id='members.profile.suspensions.memberTypes' /> {isAdministrative && <Required />}</Label>
                                                        <FormikSelect
                                                            name='memberTypesSelect'
                                                            id='memberTypesSelect'
                                                            multi
                                                            searchKeys={[
                                                                `i18n.${getGenericLocale()}.name`,
                                                            ]}
                                                            options={getMemberMemberTypes()}
                                                            renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                                            onOptionSelected={(values, spordleTable) => {
                                                                if(values.length === 0){
                                                                    for(let i = 0; i < formik.values.memberTypes.length; i++){
                                                                        helpers.remove(i)
                                                                    }
                                                                }
                                                                if(formik.values.memberTypes.length > values.length){ // unselected option
                                                                    const missingIndex = formik.values.memberTypes.findIndex((formikType) => !values.find((selectTypeId) => formikType.memberTypeId === selectTypeId))
                                                                    helpers.remove(missingIndex)
                                                                }
                                                                if(formik.values.memberTypes.length < values.length){ // selected option
                                                                    const addedTypeIds = values.filter((selectTypeId) => !formik.values.memberTypes.find((formikType) => formikType.memberTypeId === selectTypeId))
                                                                    for(let i = 0; i < addedTypeIds.length; i++){
                                                                        const addedTypeId = addedTypeIds[i];
                                                                        helpers.push({
                                                                            fine: '',
                                                                            fee: '',
                                                                            memberTypeId: addedTypeId,
                                                                            memberType: spordleTable.getSpordleTable().getData().find((option) => option.value === addedTypeId),
                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                {formik.values.memberTypes.length > 0 &&
                                                    <Col sm='12'>
                                                        <div className='border rounded p-3 mb-3'>
                                                            {formik.values.memberTypes.map((memberType, index) => (
                                                                <div className={stringBuilder({ 'mb-3': index + 1 !== formik.values.memberTypes.length })} key={memberType.memberTypeId}>
                                                                    <small className='text-dark font-bold'><DisplayI18n field="name" defaultValue={memberType.memberType.name} i18n={memberType.memberType.i18n} /></small>
                                                                    <Row form>
                                                                        <Col sm='6'>
                                                                            <Label className='text-muted'><Translate id='members.profile.suspensions.fine' /></Label>
                                                                            <FormikCurrencyInput id={`memberTypes.${index}.fine`} name={`memberTypes.${index}.fine`} />
                                                                        </Col>
                                                                        <Col sm='6'>
                                                                            <Label className='text-muted'><Translate id='members.profile.suspensions.fee' /></Label>
                                                                            <FormikCurrencyInput id={`memberTypes.${index}.fee`} name={`memberTypes.${index}.fee`} />
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Col>
                                                }
                                            </>
                                        )}
                                    </FieldArray>
                                }
                                <Col sm="12">
                                    <Collapse isOpen={isDiscriminatory(formik.values.suspension_id)}>
                                        <FormGroup>
                                            <Label for="discrimination_reason" className="text-muted">
                                                <Translate id='discrimination.global.discriminatoryGrounds' /> <Required />
                                            </Label>
                                            <FormikSelect
                                                multi
                                                textWhenSetting={{
                                                    label: 'discrimination.addModal.form.select.groundsSelected',
                                                    count: 1,
                                                }}
                                                name="discrimination_reason"
                                                id="discrimination_reason"
                                                defaultData={DISCRIMINATION_REASONS.map((reason) => ({
                                                    value: reason,
                                                    translateLabel: true,
                                                    label: 'discrimination.global.discriminatoryGrounds.' + reason,
                                                }))}
                                                loadingStatus='success'
                                            />
                                        </FormGroup>
                                    </Collapse>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for='duration' className='text-muted'><Translate id='members.profile.suspensions.suspensionType' /> <Required /></Label>
                                        <FormikSelect
                                            name='duration'
                                            id='duration'
                                            search={false}
                                            loadingStatus='success'
                                            defaultData={[
                                                {
                                                    label: 'members.profile.suspensions.duration.definite',
                                                    translateLabel: true,
                                                    value: 'DEFINITE',
                                                },
                                                {
                                                    label: 'members.profile.suspensions.duration.indefinite',
                                                    translateLabel: true,
                                                    value: 'INDEFINITE',
                                                },
                                            ]}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <Collapse isOpen={formik.values.duration === 'DEFINITE' || isAdministrative}>
                                        <Row form>
                                            <Col xs="6">
                                                {isAdministrative ?
                                                    <FormGroup>
                                                        <Label for='start_date' className='text-muted'><Translate id='members.profile.suspensions.startDate' /> <Required /></Label>
                                                        <FormikDateTime
                                                            id='start_date' name='start_date'
                                                            timeFormat={false}
                                                            dateFormat='YYYY-MM-DD'
                                                        />
                                                    </FormGroup>
                                                    :
                                                    <FormGroup className="mb-0 pb-3">
                                                        <Label for='game_suspended' className='text-muted'><Translate id='members.profile.suspensions.duration.numberOfGames' /> {!formik.values.end_date && <Required />}</Label>
                                                        <FormikInputNumber
                                                            id='game_suspended' name='game_suspended'
                                                            allowNegative={false}
                                                            decimalSeparator={false}
                                                            allowLeadingZeros={false}
                                                        />
                                                    </FormGroup>
                                                }
                                            </Col>
                                            <Col xs="6">
                                                <FormGroup>
                                                    <Label for='end_date' className='text-muted'><Translate id='members.profile.suspensions.endDate' /> {((!formik.values.game_suspended && !isAdministrative) || (isAdministrative && formik.values.duration === 'DEFINITE')) && <Required />}</Label>
                                                    <FormikDateTime
                                                        id='end_date' name='end_date'
                                                        timeFormat={false}
                                                        dateFormat='YYYY-MM-DD'
                                                        isValidDate={(current) => formik.values.received_date ? moment(current).isAfter(moment(formik.values.received_date)) : true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Collapse>
                                </Col>

                                <Col sm='12'>
                                    <CrossFade isVisible={!isAdministrative}>
                                        <Row form>
                                            <Col sm="12">
                                                <hr />
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label for='incident_date' className='text-muted'><Translate id='members.profile.suspensions.incidentDate' /> <Required /></Label>
                                                    <FormikDateTime
                                                        name='incident_date' id='incident_date'
                                                        timeFormat={false}
                                                        dateFormat='YYYY-MM-DD'
                                                        isValidDate={(current) => moment(current).isBefore(moment())}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label for='received_date' className='text-muted'><Translate id='members.profile.suspensions.receivedDate' /> <Required /></Label>
                                                    <FormikDateTime
                                                        name='received_date' id='received_date'
                                                        timeFormat={false}
                                                        dateFormat='YYYY-MM-DD'
                                                        isValidDate={(current) => formik.values.incident_date ? moment(current).isAfter(moment(formik.values.incident_date).subtract(1, 'day')) && moment(current).isSameOrBefore(moment()) : true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <IsGod tag={Col} sm={6}>
                                                <FormGroup className=''>
                                                    <Label for='created_date' className='text-muted'><Translate id='members.profile.suspensions.created_date' /></Label>
                                                    <FormikDateTime
                                                        name='created_date' id='suspension_incident_created_date'
                                                        timeFormat={false}
                                                        dateFormat='YYYY-MM-DD'
                                                    />
                                                </FormGroup>
                                            </IsGod>
                                            <Col sm="12">
                                                <FormGroup>
                                                    <Label for='location' className='text-muted'><Translate id='members.profile.suspensions.location' /> <Required /></Label>
                                                    <FormikInputText id='location' name='location' />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CrossFade>
                                </Col>
                                <Col sm='12'>
                                    <CrossFade isVisible={isAdministrative}>
                                        <Row form>
                                            <Col sm='12'>
                                                <FormGroup>
                                                    <Label for='note' className='text-muted'><Translate id='members.profile.suspensions.notes' /></Label>
                                                    <FormikTextArea id='note' name='note' rows='3' maxLength={255} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CrossFade>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' type='submit'><Translate id={isAdministrative ? 'misc.submit' : 'misc.next'} /></Button>
                            <Button color='primary' type='button' onClick={memberSuspensionAddContext.toggleModal} outline><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </Form>
                </OverlayLoader>
            )}
        </Formik>
    );
}

export default MemberSuspensionAdd1;