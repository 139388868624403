import Translate from '@spordle/intl-elements';
import React from 'react';
import { Fade, ModalHeader } from 'reactstrap';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import MemberImportForm1 from './components/MemberImportForm1';

const initState = {
    isLoading: false,
    activeView: 1,
}
class MemberImport extends React.Component{
    constructor(props){
        super(props);
        this.state = initState
    }

    setLoading = (toggle) => {
        this.setState(() => ({ isLoading: toggle }));
    }

    setActiveView = (view) => {
        this.setState(() => ({ activeView: view }));
    }

    closeModal = () => {
        this.setState(() => (initState));
    }

    render(){
        return (
            <AnalyticsModal isOpen={this.props.isOpen} onClosed={this.closeModal} analyticsName='MemberImport'>
                <OverlayLoader isLoading={this.state.isLoading}>
                    <ModalHeader toggle={this.props.toggle}><Translate id='members.search.memberImport.title' /></ModalHeader>
                    {this.state.activeView === 1 &&
                        <Fade>
                            <MemberImportForm1
                                setLoading={this.setLoading}
                                setActiveView={this.setActiveView}
                            />
                        </Fade>
                    }
                </OverlayLoader>
            </AnalyticsModal>
        );
    }
}

export default MemberImport;