import { useFormikContext } from "formik"
import { useContext } from "react"
import { FormGroup } from "reactstrap"
import OrganizationSearch from "../../../../../components/organization/OrganizationSearch"
import { OrganizationContext } from "../../../../../contexts/OrganizationContext"
import { filterHasValue, formikCodesHaveValues, handleSelectFilterChange } from "../../reportsEngineHelpers"
import FilterLabel from "../FilterLabel"

const EngineMultiOrganization = ({ filter, filterRefs }) => {
    const formik = useFormikContext();
    const { organisation_id } = useContext(OrganizationContext);

    return (
        <FormGroup name={filter.code}>
            <FilterLabel filter={filter} />
            <OrganizationSearch
                {...filter.parsedParams?.componentProps}
                id={filter.code}
                name={filter.code}
                multi
                withTree
                selectedDefault={filter.default_value === "{{CURRENT}}" ? organisation_id : filter.default_value || ""}
                withFormik
                fromApi
                isCompact
                className='w-100'
                onOptionSelected={(period, spordleSelect) => handleSelectFilterChange(filter, formik, period, spordleSelect, filterRefs)}
                disabled={!formikCodesHaveValues(filter.parsedParams?.enabledWhen, formik) || !filterHasValue(filter.parsedParams?.enabledWhenValue?.filter, filter.parsedParams?.enabledWhenValue?.value, formik)}
            />
        </FormGroup>
    )
}

export default EngineMultiOrganization;