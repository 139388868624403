import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import OverlayLoader from '../../../../components/loading/OverlayLoader';
import DisplayOrganization from '../../../../components/organization/DisplayOrganization';
import ProfileHeader from '../../../../components/profileHeader/ProfileHeader';
import { TeamsContext } from '../../../../contexts/TeamsContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { DisplayCategory, getStatus } from '../../TeamHelpers';
import TeamApprobationModal from './TeamApprobationModal';
import TeamCards from './teamCards/TeamCards';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { fail, success } from '@spordle/toasts';
import TeamHeaderActions from './TeamHeaderActions';
import InlineCopy from '../../../../components/inlineCopy/InlineCopy';
import { RolesContext } from '../../../../contexts/RolesContext';
import TeamHeaderLeagueTournaments from './participateInTournamentLeague/TeamHeaderLeagueTournaments';

const TeamHeader = ({ teamId, hasWarnings, lockedRoster }) => {
    const history = useHistory();

    const teamsContext = useContext(TeamsContext);
    const { canDoAction } = useContext(RolesContext);

    const [ approbationModal, setApprobationModal ] = useState(false) // PENDING, APPROVED, REJECTED, CANCEL

    const goToEditRoster = () => {
        teamsContext.getRosterTablesData(teamId, null, true);
        history.push('edit');
    }

    /**
     * Deletes selected team(s)
     * @returns {Promise}
     */
    const handleDelete = () => {
        teamsContext.deleteTeam(teamId)
            .then(() => {
                success();
                // GO BACK to teams search
                history.push('/teams')
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e)){
                    console.error(e);
                    fail();
                }
            });
    }

    const refreshTeam = () => {
        teamsContext.getTeam(teamId, true, { components: 'contact,address,competition' })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });
    }

    return (
        <OverlayLoader isLoading={!teamsContext.cachedTeam.team_id || teamsContext.cachedTeam.team_id !== teamId}>
            <TeamApprobationModal
                isOpen={!!approbationModal}
                toggle={() => {
                    setApprobationModal(false)
                }}
                action={approbationModal}
                onAction={() => {
                    teamsContext.getRosterTablesData(teamId);
                    refreshTeam(); // to refresh the team status
                }}
                team={teamsContext.cachedTeam}
            />

            <TeamHeaderActions
                goToEditRoster={goToEditRoster}
                handleDelete={handleDelete}
                hasWarnings={hasWarnings}
                setApprobationModal={setApprobationModal}
                teamId={teamId}
                lockedRoster={lockedRoster}
                refreshTeam={refreshTeam}
            />

            <ProfileHeader className="mt-3">
                <ProfileHeader.Image
                    alt={teamsContext.cachedTeam.name}
                    file={teamsContext.cachedTeam.logo}
                    abbr={teamsContext.cachedTeam.name?.substring(0, 2)}
                    canEdit={canDoAction([ 'EDIT' ], 'teams', 'teams_profile')}
                    onSave={(logo, filePos, dataUrl) => (
                        teamsContext.createTeamLogo(teamId, { attachment: logo, file_position: filePos }, dataUrl)
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            })
                    )}
                />
                <ProfileHeader.Info className='w-100'>
                    <div className="d-md-flex flex-wrap align-items-center">
                        <div className="mr-md-3">
                            {getStatus(teamsContext.cachedTeam.team_status)}
                            <div className="h3 mt-1 mb-0 font-medium font-bold">{teamsContext.cachedTeam.name || '-'}</div>
                        </div>
                    </div>

                    <div className="mb-2 d-inline-flex">
                        <InlineCopy toCopy={teamsContext.cachedTeam.unique_identifier} tag='div' className="d-flex align-items-center">
                            <div className="font-medium">{teamsContext.cachedTeam.unique_identifier || '-'}</div>
                        </InlineCopy>
                    </div>

                    <div className="mb-2">
                        <div className="small"><Translate id='teams.profile.header.organization' /></div>
                        <div className="font-medium text-dark">
                            {teamsContext.cachedTeam.organisation ?
                                <DisplayOrganization organisation={teamsContext.cachedTeam.organisation}>
                                    <DisplayI18n field='name' defaultValue={teamsContext.cachedTeam.organisation.organisation_name} i18n={teamsContext.cachedTeam.organisation.i18n} />
                                </DisplayOrganization>
                                : '-'}
                        </div>
                    </div>

                    <div className="mb-2">
                        <div className='d-inline-block'>
                            <div className="small"><Translate id='teams.profile.header.category' /></div>
                            <div className="font-medium text-dark">
                                <DisplayCategory category={teamsContext.cachedTeam.team_category} />
                                {teamsContext.cachedTeam.team_category?.gender &&
                                    <> - <Translate id={`form.fields.gender.${teamsContext.cachedTeam.team_category.gender.toLowerCase()}`} /></>
                                }
                            </div>
                        </div>

                        {teamsContext.cachedTeam.api_partner &&
                            <div className='d-inline-block ml-3'>
                                <div className="small"><Translate id='misc.apiPartner' /></div>
                                <div className="font-medium text-dark">{teamsContext.cachedTeam.api_partner.name}</div>
                            </div>
                        }
                    </div>
                </ProfileHeader.Info>
            </ProfileHeader>
            <TeamHeaderLeagueTournaments refreshTeam={refreshTeam} />
            <TeamCards team={teamsContext.cachedTeam} teamId={teamId} />
        </OverlayLoader>
    );
}

export default TeamHeader;