import { stringBuilder } from "@spordle/helpers";
import { Badge } from "reactstrap";
import styles from "./NotifBadge.module.scss";

const NotifBadge = ({ className, animate = false, color, children }) => {

    return (
        <Badge
            className={stringBuilder(styles.Pill, { "animate": animate }, "rounded-pill", className)}
            color={color || "federation"}
            pill
        >
            {children}
        </Badge>
    );
}

export default NotifBadge;