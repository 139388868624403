export default{
    "catalog.installment.title"                                : "Installments",
    "catalog.installment.form.installments"                    : "Installments",
    "catalog.installment.rule.on"                              : " on ",
    "catalog.installment.form.installments.note"               : "Apply the percentages so it adds up to 100% of the rest of the payment.",
    "catalog.installment.form.installments.required"           : "Please select an installment",
    "catalog.installment.form.installments.date.afterPrevious" : "This date must be later than the date of the previous installment.",
    "catalog.installment.form.installments.label.plural"       : "{count} installments",
    "catalog.installment.form.installments.label.single"       : "1 installment",
    "catalog.installment.form.title"                           : "Title",
    "catalog.installment.form.title.note"                      : "The title of the installments will not be visible to the public",
    "catalog.installment.form.rules"                           : "Rules",
    "catalog.installment.form.initialValue"                    : "Initial payment (%)",
    "catalog.installment.form.initialValue.note"               : "The percentage of the first payment.",
    "catalog.installment.form.value"                           : "Value (%)",
    "catalog.installment.form.value.required"                  : "Please specify a percentage.",
    "catalog.installment.form.value.total"                     : "Total of percentages must be 100%.",
    "catalog.installment.form.date"                            : "Date",
    "catalog.installment.form.date.helper"                     : "YYYY-MM-DD",
    "catalog.installment.form.addRule"                         : "Add Installment",
    "catalog.installment.form.status"                          : "Status",

    "catalog.installment.add.title" : "Add Installments",

    "catalog.installment.sidepanel.catalog.registrationFees"     : "Registration Fees",
    "catalog.installment.sidepanel.catalog.delete"               : "You are about to delete these installments",
    "catalog.installment.sidepanel.catalog.catalog"              : "Catalog",
    "catalog.installment.sidepanel.catalog.selectedRegistration" : "Selected registration fees",

    "catalog.installment.linkedFees.error"       : "We could not find the linked registration fees, please contact the support",
    "catalog.installment.linkedFees.title"       : "Unlink registration fees",
    "catalog.installment.linkedFees.text"        : "You cannot deactivate this installment because it is linked to the following registration fees.",
    "catalog.installment.linkedFees.submit"      : "Unlink all",
    "catalog.installment.linkedFees.success"     : "All registration fees are unlinked!",
    "catalog.installment.linkedFees.successText" : "You may now deactivate the installment.",
    "catalog.installment.linkedFees.deactivate"  : "Deactivate",

    "catalog.installment.table.initialPayment"      : "Initial payment",
    "catalog.installment.table.initialPayment.text" : " of the total amount",

    "catalog.installment.table.empty.title" : "No installment",

    "catalog.installment.delete.error.3197" : "Linked to one or more registration fees.",


    "catalog.installment.rollover.dropdown.button" : "Rollover",
    "catalog.installment.rollover.title"           : "Installments rollover",
    "catalog.installment.rollover.warning"         : "The current season already has {amount, plural, one {# installment} other {# installments}}. Please verify if a rollover has already been done to minimize the risk of duplicate data.",
    "catalog.installment.rollover.prevSeasons"     : "Previous season",
    "catalog.installment.rollover.selectAll"       : "Select all",
    "catalog.installment.rollover.selectNone"      : "Unselect all",
    "catalog.installment.rollover.required"        : "Please select at least one installment",
    "catalog.installment.rollover.confirm"         : "Click the button down below to create these installments in the new season",
}