export default{
    "organization.profile.postalCodes.modals.delete.title"   : "Vous êtes sur le point de supprimer tous les codes postaux {postalCode}.",
    "organization.profile.postalCodes.modals.delete.content" : 'Si vous souhaitez supprimer certains des codes postaux {postalCode}, utilisez la fonctionnalité "Modifier".',

    "organization.profile.postalCodes.modals.add.validation.partialPostalCode.length" : "Veuillez fournir les 3 premiers caractères du code postal",
    "organization.profile.postalCodes.modals.add.validation.postalCodes.required"     : "Veuillez sélectionner au moins un code postal",

    "organization.profile.postalCodes.modals.add.step1.title"                       : "Rechercher les codes postaux",
    "organization.profile.postalCodes.modals.add.step1.fields.partialZipCode.label" : "Code postal partiel",
    "organization.profile.postalCodes.modals.add.step1.fields.partialZipCode.hint"  : "Fournir les 3 premiers caractères du code postal",

    "organization.profile.postalCodes.modals.step2.empty.search"      : "Aucun code postal n'a été trouvé pour {searchValue}.",
    "organization.profile.postalCodes.modals.step2.empty.postalCodes" : "Aucun code postal correspond à la recherche",
    "organization.profile.postalCodes.modals.edit.step2.title"        : "Modifier les codes postaux: {postalCode}",
    "organization.profile.postalCodes.modals.add.step2.title"         : "Ajouter des codes postaux",
    "organization.profile.postalCodes.modals.edit.step2.description"  : "Sélectionnez les codes postaux à modifier",
    "organization.profile.postalCodes.modals.add.step2.description"   : "Sélectionnez les codes postaux à ajouter",

    "organization.profile.postalCodes.modals.add.step3.description" : "Codes postaux ajoutés avec succès",
}