export default{
    "tasks.memberTypeValidation.title" : "Approbation des types de membre",

    "tasks.memberTypeValidation.columns.member"             : "Membre",
    "tasks.memberTypeValidation.columns.expiry"             : "Expiration de la photo",
    "tasks.memberTypeValidation.columns.organization"       : "Organisation",
    "tasks.memberTypeValidation.columns.crc"                : "Statut de VCJ",
    "tasks.memberTypeValidation.columns.crc.date"           : "Date de validité",
    "tasks.memberTypeValidation.columns.crc.expiry"         : "Date d'expiration",
    "tasks.memberTypeValidation.columns.memberType"         : "Type de membre",
    "tasks.memberTypeValidation.columns.memberTypeStatus"   : "Statut du type",
    "tasks.memberTypeValidation.filter.memberType.required" : "Veuillez sélectionner un type de membre",

    "tasks.memberTypeValidation.sidepanel.manage"     : "Gérer l'approbation",
    "tasks.memberTypeValidation.sidepanel.count"      : "{count, plural, one {# membre sélectionné} other {# membres sélectionnés}}",
    "tasks.memberTypeValidation.sidepanel.memberType" : "Type de membre",
    "tasks.memberTypeValidation.sidepanel.status"     : "Statut",
    "tasks.memberTypeValidation.sidepanel.decidedBy"  : "Décidé par",
    "tasks.memberTypeValidation.sidepanel.note"       : "Note",
}