import { useSpordleTable } from "@spordle/datatables";
import { useContext } from "react";
import useDidUpdate from "../components/customHooks/useDidUpdate";
import { PeriodsContext } from "../contexts/contexts";
import PropTypes from 'prop-types';

/**
 * Uses Periods Context & SpordleTable
 * @param {Object} props
 * @param {function} [props.onPeriodChange] Function called when period is changed
 * @param {boolean} [props.useRefreshTable=false] Used when inside a SpordleTableProvider -> refreshes the table
 * @returns {null}
 * @example
 * <SpordleTableProvider>
 *      <PeriodChangeHandler useRefreshTable onPeriodChange={getData} />
 * </SpordleTableProvier>
 */
export const PeriodChangeHandler = ({ onPeriodChange, useRefreshTable = false }) => {
    const { selectedPeriod } = useContext(PeriodsContext);
    const table = useSpordleTable({ suppressWarning: true });

    useDidUpdate(() => {
        useRefreshTable && table?.refreshTable();
        onPeriodChange?.();
    }, [ selectedPeriod.period_id ]);

    return null;
}

PeriodChangeHandler.propTypes = {
    useRefreshTable: PropTypes.bool,
    onPeriodChange: PropTypes.func,
};

PeriodChangeHandler.defaultProps = {
    useRefreshTable: false,
};

/**
 * @param {function} func function called on period change
 * @example
 * useOnPeriodChange(() => {
 *      func();
 * })
 */
export function useOnPeriodChange(func){
    const { selectedPeriod } = useContext(PeriodsContext);

    useDidUpdate(() => {
        func();
    }, [ selectedPeriod.period_id ]);
}