export default{
    "members.profile.qualifications.title" : "Qualifications",

    // general
    "members.profile.qualifications.status.certified" : "Certified",
    "members.profile.qualifications.status.valid"     : "Valid",
    "members.profile.qualifications.status.expired"   : "Expired",
    "members.profile.qualifications.status.attendee"  : "Attendee",

    "members.profile.qualifications.season"              : "Season",
    "members.profile.qualifications.category"            : "Category",
    "members.profile.qualifications.qualification"       : "Qualification",
    "members.profile.qualifications.level"               : "Level",
    "members.profile.qualifications.validationDate"      : "Validation date",
    "members.profile.qualifications.certifiedDate"       : "Certification date",
    "members.profile.qualifications.expirationDate"      : "Renewal / expiry date",
    "members.profile.qualifications.certificationNumber" : "Certification number",
    "members.profile.qualifications.location"            : "Location",
    "members.profile.qualifications.city"                : "City",
    "members.profile.qualifications.clinic"              : "Clinic",
    "members.profile.qualifications.memo"                : "Memo",
    "members.profile.qualifications.deleteMessage"       : "Are you sure you want to delete this qualification?",

    "members.profile.qualifications.validationDate.required"       : "Validation date is required",
    "members.profile.qualifications.certifiedDate.required"        : "Certification date is required",
    "members.profile.qualifications.validationDate.expirationDate" : "Validation date must be before the expiration date",
    "members.profile.qualifications.certifiedDate.expirationDate"  : "Certification date must be before the expiration date",
    "members.profile.qualifications.expirationDate.validationDate" : "Renewal / expiry date must be after the validation date",

    // table
    "members.profile.qualifications.table.status" : "Status",

    // add modal
    "members.profile.qualifications.addModal.title"                  : "Add qualification",
    "members.profile.qualifications.addModal.category.required"      : "Category is required",
    "members.profile.qualifications.addModal.qualification.required" : "Qualification is required",
    "members.profile.qualifications.addModal.season.required"        : "Season is required",

    // sidepanel
    "members.profile.qualifications.sidePanel.actions.removeCertification"      : "Remove Certification",
    "members.profile.qualifications.sidePanel.actions.removeCertification.text" : "You are about to remove this certification.",
    "members.profile.qualifications.sidePanel.actions.certify"                  : "Certify",
    "members.profile.qualifications.sidePanel.actions.trasnferShare"            : "Transfer / Share",
    "members.profile.qualifications.sidePanel.registeredAs"                     : "Registered as attendee",
    "contexts.qualifications.3191"                                              : "Insufficient permissions",
    "contexts.qualifications.1000"                                              : "Missing parameters",
    "contexts.qualifications.3179"                                              : "Forbidden access",
    "contexts.qualifications.999"                                               : "Internal error",

    // Transfer / Share modal
    "members.profile.qualifications.sidePanel.trasnferShareModal.title"                    : "Transfer / Share qualification",
    "members.profile.qualifications.sidePanel.trasnferShareModal.choice.transfer.title"    : "Transfer",
    "members.profile.qualifications.sidePanel.trasnferShareModal.choice.transfer.subtitle" : "The transfer will move the qualification to the new member",
    "members.profile.qualifications.sidePanel.trasnferShareModal.choice.share.title"       : "Share",
    "members.profile.qualifications.sidePanel.trasnferShareModal.choice.share.subtitle"    : "The share will copy the same information to the new member",
    "members.profile.qualifications.sidePanel.trasnferShareModal.choice.required"          : "Select an option",
    "members.profile.qualifications.sidePanel.trasnferShareModal.confirm"                  : "The qualification was successfully {mode, select, TRANSFER {transfered} SHARE {shared} other {updated}}",

    // Certify modal
    "members.profile.qualifications.sidePanel.certifyModal.title"                                : "Certify member qualification",
    "members.profile.qualifications.sidePanel.certifyModal.certification.label"                  : "Certification date",
    "members.profile.qualifications.sidePanel.certifyModal.certification.validation.beforeToday" : "The certification date must be before today",
}