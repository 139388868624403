import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { AxiosIsCancelled, serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { OrganizationContext } from './OrganizationContext';
import { QualificationCategoriesContext } from './QualificationCategoriesContext';
import { MembersContext } from './MembersContext';
import { MerchantAccountContext } from './MerchantAccountContext';
import { PeriodsContext } from './contexts';
import withContexts from '../helpers/withContexts';

import * as Sentry from "@sentry/react";
import { jsObjectToApi } from '@spordle/helpers';
import { DisplayI18n } from '../helpers/i18nHelper';
import { fail } from "@spordle/toasts";

/** @type {React.Context<Omit<ClinicsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & ClinicsContextProvider['state']>} */
export const ClinicsContext = createContext();
ClinicsContext.displayName = 'ClinicsContext';

class ClinicsContextProvider extends React.PureComponent{
    // this context manages both the clinics endpoint and the clinics sessions endpoint

    state = {
        currentClinic: {},
    }

    componentDidUpdate(){
        Sentry.setContext('clinic', this.state.currentClinic.clinic_id ? {
            clinicId: this.state.currentClinic.clinic_id,
            name: this.state.currentClinic.name,
            activeStatus: this.state.currentClinic.active_status,
            createdBy: this.state.currentClinic.created_by?.identity_id,
        } : null);
        Sentry.setTag('clinicId', this.state.currentClinic.clinic_id);
    }

    clearCurrentClinic = () => {
        return new Promise((resolve) => {
            this.setState(() => ({ currentClinic: {} }), resolve(true))
        })
    }

    clearCurrentClinicId = () => {
        return new Promise((resolve) => {
            this.setState((prevState) => ({ currentClinic: { ...prevState.currentClinic, clinic_id: null } }), resolve(true))
        })
    }

    /**
     * Will preset clinic data when we pass data throw routing
     * Because of the state of the API, the data we preset might not be 100%.
     * -> Solution: We will preset and still asyc getAllClinicInfos in the profile view.
     * -> Effect: The page will load with the preset context state and rerender when api returns all data.
     *            This should be almost transparent for the user
     * @param {Object} clinicData ID of the clinic we are presetting in the state
     * @returns {Promise}
     */
    presetCurrentClinic = (clinicData) => {
        return new Promise((resolve) => {
            // Split data to simulate getAllClinicInfos()
            this.setState((prevState) => ({
                currentClinic: {
                    // only keep clinic attendees in the state if the clinic for these attendees match the clinic received in clinicData
                    clinic_attendees: prevState.currentClinic.clinic_attendees ?
                        prevState.currentClinic.clinic_attendees.find((attendee) => attendee.clinic.clinic_id === clinicData?.clinic_id) || prevState.currentClinic.clinic_attendees.length == 0 ?
                            prevState.currentClinic.clinic_attendees
                            : null
                        : null,

                    ...clinicData,
                    category: clinicData?.qualification?.qualification_category || null,
                    sessions: null,
                    contacts: null,
                    instructors: null,
                    memberTypes: null,
                    merchantAccounts: null,
                    clinic_id: null, // Force profile to reload currentClinic when if compares clinic_id
                },
            }), resolve(true))
        })
    }

    getAllClinicInfos = (clinicId, settings = { sessions: false, contacts: false, instructors: false, memberTypes: false, merchantAccounts: false }) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/clinics/${clinicId}` }))
            .then((response) => {
                if(response.data.status){
                    return Promise.all([
                        settings.sessions ? this._getAllClinicSessions(clinicId) : Promise.resolve(),
                        settings.contacts ? this._getAllClinicContacts(clinicId) : Promise.resolve(),
                        settings.instructors ? this._getAllClinicInstructors(clinicId) : Promise.resolve(),
                        settings.memberTypes ? this.props.MembersContext.getMemberTypes(response.data.clinics[0].organisation.organisation_id) : Promise.resolve(),
                        settings.merchantAccounts ? this.props.MerchantAccountContext.getMerchantAccountsByOrganization(response.data.clinics[0].organisation.organisation_id) : Promise.resolve(),
                    ])
                        .then((promises) => {
                            this.setState((prevState) => ({
                                currentClinic: {
                                    // only keep clinic_attendees in the state if the clinic for these attendees match the new clinic
                                    clinic_attendees: prevState.currentClinic.clinic_attendees ?
                                        prevState.currentClinic.clinic_attendees.find((attendee) => attendee.clinic.clinic_id === clinicId) || prevState.currentClinic.clinic_attendees.length == 0 ?
                                            prevState.currentClinic.clinic_attendees
                                            : null
                                        : null,

                                    ...response.data.clinics[0],
                                    sessions: settings.sessions ? promises[0] : prevState.currentClinic.sessions,
                                    contacts: settings.contacts ? promises[1] : prevState.currentClinic.contacts,
                                    instructors: settings.instructors ? promises[2] : prevState.currentClinic.instructors,
                                    memberTypes: settings.memberTypes ? promises[3] : prevState.currentClinic.memberTypes,
                                    merchantAccounts: settings.merchantAccounts ? promises[4] : prevState.currentClinic.merchantAccounts,
                                    category: response.data.clinics[0].qualification.qualification_category, // easier access to the category
                                },
                            }), () => { return true })
                            return response.data.clinics[0];
                        }).catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                }
                throw response.data.errors[0]

            }, serverError)
    }

    /**
     * Returns an array of all the contacts for a clinic
     * @param {string} clinicId ID of the clinic to get contacts from
     * @private
     */
    _getAllClinicContacts = (clinicId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `clinics/${clinicId}/contacts` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_contacts
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Returns an array of all the instructors for a clinic
     * @param {string} clinicId ID of the clinic to get instructors from
     * @private
     */
    _getAllClinicInstructors = (clinicId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `clinics/${clinicId}/instructors` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_instructors
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Returns an array of all the sessions for a clinic
     * @param {string} clinicId ID of the clinic to get sessions from
     * @private
     */
    _getAllClinicSessions = (clinicId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/clinics/${clinicId}/sessions`, query: { sort: '+start_date' } }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_sessions
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Clinic Creation
     * @param {Object} clinic Clinic object to create
     * @param {string} clinic.hostOrganization Host Organization of the Clinic
     * @param {string} clinic.qualificationId Qualification of the Clinic
     * @param {string} clinic.periodId Period of the Clinic
     * @param {string} clinic.clinicType Clinic Type
     * @param {Array} clinic.memberTypes Array used to specify which type of member can attend this clinic
     * @param {number} [clinic.expirationDuration] Duration of the expiration
     * @param {string} [clinic.expirationType] Type of the expiration
     * @param {string} [clinic.expirationDate] Date of the expiration (MM-DD)
     * @param {Array} [clinic.prerequisites] Array of qualification IDs to set as prerequisites
     * @param {string} [clinic.prerequisiteRestriction] Either 'all' or 'one', represents how many prerequisites should be required
     * @param {number} [clinic.minAttendees] Minimum amount of attendees
     * @param {number} [clinic.maxAttendees] Maximum amount of attendees
     * @param {number} [clinic.minAgeRegistration] Minimum age for attendees
     * @param {number} [clinic.maxAgeRegistration] Maximum age for attendees
     * @param {string} [clinic.merchantAccountId] Merchant account for the clinic
     * @param {string} [clinic.afterRegistration] Clinic's after registration message
     * @param {string} [clinic.confirmation] Clinic's confirmation email message
     * @param {boolean} clinic.criminalRecordCheck Wether or not a check for a criminal record should be done
     * @param {string} clinic.description Clinic's description
     * @param {Array} languages Array of languages accepted by the organization
     * @returns {Promise.<string>} Clinic ID
     */
    createClinic = (clinic, languages) => {
        const params = new URLSearchParams();

        params.append('organisation_id', clinic.hostOrganization);
        params.append('qualification_id', clinic.qualificationId);
        params.append('period_id', clinic.periodId);
        params.append('clinic_type', clinic.clinicType);
        params.append('criminal_record_check', !!clinic.criminalRecordCheck >>> 0);
        params.append('manage_waiting_list', !!clinic.waitingListCheck >>> 0);
        params.append('active', 1);

        clinic.memberTypes.forEach((id, index) => {
            params.append(`member_type_id[${index}]`, id)
        })
        params.append('description', clinic.description)

        if(clinic.passingGrade){
            params.append('passing_grade', clinic.passingGrade);
        }

        if(clinic.moodleExternalId){
            params.append('external_status', 'MOODLE')
            params.append('external_id', clinic.moodleExternalId)
        }

        if(clinic.ruleForQualifications && clinic.ruleForQualifications.length > 0){
            params.append('rule_to_obtain_qualification', clinic.ruleForQualifications.join(','));
        }

        // prerequisites
        if(clinic.prerequisites && clinic.prerequisites.length > 0){
            clinic.prerequisites.forEach((group, groupIndex) => {
                params.append(`prerequisite[${groupIndex}][restriction_condition]`, group.restriction)
                params.append(`prerequisite[${groupIndex}][period_id]`, clinic.periodId)
                group.qualifications.forEach((id, qualIndex) => {
                    params.append(`prerequisite[${groupIndex}][qualifications][${qualIndex}]`, id)
                })
            })
        }

        if(clinic.expirationDuration)
            params.append('expiration_duration', clinic.expirationDuration);
        if(clinic.expirationType)
            params.append('expiration_type', clinic.expirationType);
        if(clinic.expirationDate)
            params.append('expiration_date', clinic.expirationDate);
        if(clinic.minAttendees)
            params.append('min_attendee', clinic.minAttendees);
        if(clinic.maxAttendees)
            params.append('max_attendee', clinic.maxAttendees);
        if(clinic.minAgeRegistration)
            params.append('min_age', clinic.minAgeRegistration);
        if(clinic.maxAgeRegistration)
            params.append('max_age', clinic.maxAgeRegistration);
        if(clinic.merchantAccountId)
            params.append('merchant_account_id', clinic.merchantAccountId)
        if(clinic.afterRegistration)
            params.append('message_after_registration', clinic.afterRegistration)
        if(clinic.confirmation)
            params.append('message_confirmation_email', clinic.confirmation)
        if(clinic.termAndConditionId)
            params.append('term_and_condition_id', clinic.termAndConditionId)
        if(clinic.duplicateWithAttendees)
            params.append('import_attendee_from', clinic.duplicateWithAttendees)
        if(clinic.createdFrom)
            params.append('created_from_organisation_id', clinic.createdFrom)

        // Clinic name & i18n
        if(clinic[`clinicName_${languages[0]}`])
            params.append('name', clinic[`clinicName_${languages[0]}`])
        else
            params.append('name', clinic[`clinicCategoryName_${languages[0]}`] + ' - ' + clinic[`clinicQualificationName_${languages[0]}`])

        languages.forEach((lang) => {
            if(clinic[`clinicName_${lang}`])
                params.append(`i18n[${lang}][name]`, clinic[`clinicName_${lang}`])
            else
                params.append(`i18n[${lang}][name]`, clinic[`clinicCategoryName_${lang}`] + ' - ' + clinic[`clinicQualificationName_${lang}`])

            if(lang === 'en'){
                params.delete('name');

                if(clinic[`clinicName_${lang}`])
                    params.append(`name`, clinic[`clinicName_${lang}`])
                else
                    params.append('name', clinic[`clinicCategoryName_${lang}`] + ' - ' + clinic[`clinicQualificationName_${lang}`])
            }
        })

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/clinics` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_id;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Update a Clinic
     * @param {string} clinicId Clinic ID to update
     * @param {Object} clinic Clinic object
     * @param {string} clinic.hostOrganization Host Organization of the Clinic
     * @param {string} clinic.qualificationId Qualifications of the Clinic
     * @param {string} clinic.periodId Period of the Clinic
     * @param {string} clinic.merchantAccountId Merchant account for the clinic
     * @param {string} clinic.clinicType Clinic Type
     * @param {Array} clinic.prerequisites Array of qualification IDs to set as prerequisites
     * @param {string} clinic.prerequisiteRestriction Either 'all' or 'one', represents how many prerequisites should be required
     * @param {Array} clinic.memberTypes Array used to specify which type of member can attend this clinic
     * @param {number} [clinic.expirationDuration] Duration of the expiration
     * @param {string} [clinic.expirationType] Type of the expiration
     * @param {string} [clinic.expirationDate] Date of the expiration (MM-DD)
     * @param {number} [clinic.minAttendees] Minimum amount of attendees
     * @param {number} [clinic.maxAttendees] Maximum amount of attendees
     * @param {number} [clinic.minAgeRegistration] Minimum age for attendees
     * @param {number} [clinic.maxAgeRegistration] Maximum age for attendees
     * @param {boolean} clinic.criminalRecordCheck Wether or not a check for a criminal record should be done
     * @param {string} clinic.description Clinic's description
     * @param {string} clinic.afterRegistration Clinic's after registration message
     * @param {string} clinic.confirmation Clinic's confirmation message
     * @param {string} clinic.startDate Clinic's start date
     * @param {string} clinic.endDate Clinic's end date
     * @returns {Promise}
     */
    updateClinicPartial = (clinicId, clinic, languages) => {
        const params = new URLSearchParams();

        for(const key in clinic){
            switch (key){
                case 'hostOrganization':
                    params.append('organisation_id', clinic[key])
                    break;
                case 'qualificationId':
                    params.append('qualification_id', clinic[key])
                    break;
                case 'termAndConditionId':
                    params.append('term_and_condition_id', clinic[key])
                    break;
                case 'periodId':
                    params.append('period_id', clinic[key])
                    break;
                case 'merchantAccountId':
                    params.append('merchant_account_id', clinic[key])
                    break;
                case 'clinicType':
                    params.append('clinic_type', clinic[key])
                    break;
                case 'criminalRecordCheck':
                    params.append('criminal_record_check', !!clinic[key] >>> 0)
                    break;
                case 'active':
                    params.append('active', 1)
                    break;
                case 'memberTypes':
                    clinic[key].forEach((id, index) => {
                        params.append(`member_type_id[${index}]`, id)
                    })
                    break;
                case 'description':
                    params.append('description', clinic[key])
                    break;
                case 'minAttendees':
                    if(clinic[key])
                        params.append('min_attendee', clinic[key])
                    break;
                case 'maxAttendees':
                    if(clinic[key])
                        params.append('max_attendee', clinic[key])
                    break;
                case 'minAgeRegistration':
                    if(clinic[key])
                        params.append('min_age', clinic[key])
                    break;
                case 'maxAgeRegistration':
                    if(clinic[key])
                        params.append('max_age', clinic[key])
                    break;
                case 'afterRegistration':
                    params.append('message_after_registration', clinic[key])
                    break;
                case 'confirmation':
                    params.append('message_confirmation_email', clinic[key])
                    break;
                case 'startDate':
                    params.append('start_date', clinic[key])
                    break;
                case 'endDate':
                    params.append('end_date', clinic[key])
                    break;
                case 'expirationDuration':
                    params.append('expiration_duration', clinic[key])
                    break;
                case 'expirationType':
                    params.append('expiration_type', clinic[key])
                    break;
                case 'expirationDate':
                    params.append('expiration_date', clinic[key])
                    break;
            }
        }

        // i18n
        if(languages && clinic[`clinicCategoryName_${languages[0]}`] && clinic[`clinicQualificationName_${languages[0]}`]){
            params.append('name', clinic[`clinicCategoryName_${languages[0]}`] + ' - ' + clinic[`clinicQualificationName_${languages[0]}`])
            languages.forEach((lang) => {
                params.append(`i18n[${lang}][name]`, clinic[`clinicCategoryName_${lang}`] + ' - ' + clinic[`clinicQualificationName_${lang}`])

                if(lang === 'en'){
                    params.delete('name');
                    params.append('name', clinic[`clinicCategoryName_${lang}`] + ' - ' + clinic[`clinicQualificationName_${lang}`])
                }
            })
        }

        // prerequisites
        if(clinic.prerequisites && clinic.prerequisites.length > 0){
            clinic.prerequisites.forEach((group, groupIndex) => {
                params.append(`prerequisite[${groupIndex}][restriction_condition]`, group.restriction)
                params.append(`prerequisite[${groupIndex}][period_id]`, clinic.periodId)
                group.qualifications.forEach((id, qualIndex) => {
                    params.append(`prerequisite[${groupIndex}][qualifications][${qualIndex}]`, id)
                })
            })
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/clinics/${clinicId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get the downloadable link for the message attachment
     * @param {string} messageId
     * @param {string} messageAttachmentId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/index.php#/Message%20Attachments/563c5e634e67d4de46196fc1ee9b6f90 documentation}
     * @returns {Promise}
     */
    downloadMessageAttachments = (messageId, messageAttachmentId) => {
        return API_SPORDLE.get(`/messages/${messageId}/attachments/${messageAttachmentId}`)
            .then((response) => {
                if(response.data.status){
                    return {
                        full_path: response.data.full_path,
                        preview_full_path: response.data.preview_full_path,
                        original_file_mime_type: response.data.original_file_mime_type,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update a Clinic
     * @param {string} clinicId Clinic ID to update
     * @param {Object} clinic Clinic object
     * @param {string} clinic.hostOrganization Host Organization of the Clinic
     * @param {string} clinic.qualificationId Qualifications of the Clinic
     * @param {string} clinic.periodId Period of the Clinic
     * @param {string} clinic.merchantAccountId Merchant account for the clinic
     * @param {string} clinic.clinicType Clinic Type
     * @param {Array} clinic.prerequisites Array of qualification IDs to set as prerequisites
     * @param {string} clinic.prerequisiteRestriction Either 'all' or 'one', represents how many prerequisites should be required
     * @param {Array} clinic.memberTypes Array used to specify which type of member can attend this clinic
     * @param {number} [clinic.expirationDuration] Duration of the expiration
     * @param {string} [clinic.expirationType] Type of the expiration
     * @param {string} [clinic.expirationDate] Date of the expiration (MM-DD)
     * @param {number} [clinic.minAttendees] Minimum amount of attendees
     * @param {number} [clinic.maxAttendees] Maximum amount of attendees
     * @param {number} [clinic.minAgeRegistration] Minimum age for attendees
     * @param {number} [clinic.maxAgeRegistration] Maximum age for attendees
     * @param {boolean} clinic.criminalRecordCheck Wether or not a check for a criminal record should be done
     * @param {string} clinic.description Clinic's description
     * @param {string} clinic.afterRegistration Clinic's after registration message
     * @param {string} clinic.confirmation Clinic's confirmation message
     * @param {string} clinic.startDate Clinic's start date
     * @param {string} clinic.endDate Clinic's end date
     * @returns {Promise}
     */
    updateClinic = (clinicId, clinic, languages) => {
        const params = new URLSearchParams();

        params.append('organisation_id', clinic.hostOrganization)
        params.append('qualification_id', clinic.qualificationId)
        //params.append('term_and_condition_id', clinic.termAndConditionId)
        params.append('period_id', clinic.periodId)
        //params.append('merchant_account_id', clinic.merchantAccountId)
        params.append('clinic_type', clinic.clinicType)
        params.append('criminal_record_check', !!clinic.criminalRecordCheck >>> 0)
        params.append('manage_waiting_list', !!clinic.waitingListCheck >>> 0);
        params.append('active', 1)
        clinic.memberTypes.forEach((id, index) => {
            params.append(`member_type_id[${index}]`, id)
        })
        params.append('description', clinic.description)

        if(clinic.passingGrade){
            params.append('passing_grade', clinic.passingGrade);
        }

        if(clinic.moodleExternalId){
            params.append('external_status', 'MOODLE')
            params.append('external_id', clinic.moodleExternalId)
        }

        if(clinic.ruleForQualifications && clinic.ruleForQualifications.length > 0){
            params.append('rule_to_obtain_qualification', clinic.ruleForQualifications.join(','));
        }

        // if these are not set, do not send them so they are deleted from the clinic (no restrictions)
        if(clinic.termAndConditionId)
            params.append('term_and_condition_id', clinic.termAndConditionId)

        if(clinic.merchantAccountId)
            params.append('merchant_account_id', clinic.merchantAccountId)

        if(clinic.minAttendees)
            params.append('min_attendee', clinic.minAttendees)

        if(clinic.maxAttendees)
            params.append('max_attendee', clinic.maxAttendees)

        if(clinic.minAgeRegistration)
            params.append('min_age', clinic.minAgeRegistration)

        if(clinic.maxAgeRegistration)
            params.append('max_age', clinic.maxAgeRegistration)

        if(clinic.expirationDuration)
            params.append('expiration_duration', clinic.expirationDuration);
        if(clinic.expirationType)
            params.append('expiration_type', clinic.expirationType);
        if(clinic.expirationDate)
            params.append('expiration_date', clinic.expirationDate);

        params.append('message_after_registration', clinic.afterRegistration)
        params.append('message_confirmation_email', clinic.confirmation)

        // Clinic name & i18n
        if(clinic[`clinicName_${languages[0]}`])
            params.append('name', clinic[`clinicName_${languages[0]}`])
        else
            params.append('name', clinic[`clinicCategoryName_${languages[0]}`] + ' - ' + clinic[`clinicQualificationName_${languages[0]}`])

        languages.forEach((lang) => {
            if(clinic[`clinicName_${lang}`])
                params.append(`i18n[${lang}][name]`, clinic[`clinicName_${lang}`])
            else
                params.append(`i18n[${lang}][name]`, clinic[`clinicCategoryName_${lang}`] + ' - ' + clinic[`clinicQualificationName_${lang}`])

            if(lang === 'en'){
                params.delete('name');

                if(clinic[`clinicName_${lang}`])
                    params.append(`name`, clinic[`clinicName_${lang}`])
                else
                    params.append('name', clinic[`clinicCategoryName_${lang}`] + ' - ' + clinic[`clinicQualificationName_${lang}`])
            }
        })

        // prerequisites
        if(clinic.prerequisites && clinic.prerequisites.length > 0){
            clinic.prerequisites.forEach((group, groupIndex) => {
                params.append(`prerequisite[${groupIndex}][restriction_condition]`, group.restriction)
                params.append(`prerequisite[${groupIndex}][period_id]`, clinic.periodId)
                group.qualifications.forEach((id, qualIndex) => {
                    params.append(`prerequisite[${groupIndex}][qualifications][${qualIndex}]`, id)
                })
            })
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/clinics/${clinicId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update a clinic's waivers
     * @param {string} clinicId Clinic ID to update
     * @param {string[]} [waiverIds] Waivers id
     * @returns {Promise}
     */
    updateWaivers = (clinicId, waiverIds = []) => {
        const params = new URLSearchParams();
        jsObjectToApi({ waivers: waiverIds }, params);
        return API_SPORDLE.put(`/clinics/${clinicId}/waivers`, params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a clinic
     * @param {string} clinicId ID of the clinic to delete
     * @returns {Promise}
     */
    deleteClinic = (clinicId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/clinics/${clinicId}` }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Sets the available payment methods for a clinic
     * @param {string} clinicId ID of the clinic to update payment methods to
     * @param {Array<string>} paymentMethods Array of the payment methods
     * @returns {Promise}
     */
    updatePaymentMethods = (clinicId, paymentMethods) => {
        const params = new URLSearchParams();

        if(paymentMethods.length === 0){
            params.append('payment_methods', '')
        }else{
            paymentMethods.forEach((method, index) => {
                params.append(`payment_methods[${index}]`, method);
            })
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `clinics/${clinicId}/payments` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes the payment methods for a clinic
     * @param {string} clinicId ID of the clinic to delete payment methods from
     */
    deletePaymentMethods = (clinicId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `clinics/${clinicId}/payments` }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets all the clinic's contacts
     * @param {string} clinicId The clinic we want to get the contacts from
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinics/Apicontroller%5CClinics%5CClinics%3A%3AgetAllClinicContacts|documentation}
     * @returns {Promise.<Array>}
     */
    getClinicContacts = (clinicId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinicId}/contacts`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_contacts;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a contact for a clinic
     * @param {string} clinicId ID of the clinic
     * @param {Object} values Contact values
     * @param {string} values.firstName Contact's first name
     * @param {string} values.lastName Contact's last name
     * @param {string} values.email Contact's email
     * @param {string} values.phone Contact's phone number
     * @param {boolean} values.active Wether or not the contact shoud be active
     * @param {boolean} values.facilitator Wether or not the contact should be set as facilitator
     * @param {boolean} values.notify Wether or not the contact should receive a notification for each registrations
     * @param {string} [values.member_id] Contact member id, if specified
     * @returns {Promise}
     */
    createContact = (clinicId, values) => {
        const params = new URLSearchParams();
        params.append('first_name', values.firstName);
        params.append('last_name', values.lastName);
        params.append('email', values.email);
        params.append('active', 1); // no possibility to "deactivate" a contact (user may delete and re-insert)
        //params.append('add_as_facilitator', !!values.facilitator >>> 0); //not used for the front end

        if(values.phone)
            params.append('phone', values.phone); // optional

        // we will eventually have more notification types, hence the array
        if(values.notify)
            params.append('notification_types[0]', 'clinic_registration');

        if(values.memberId)
            params.append('member_id', values.memberId);

        if(values.showStatus){
            params.append('visibility_on_website', values.showStatus);
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/clinics/${clinicId}/contacts` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_contact_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a contact for a clinic
     * @param {string} clinicId ID of the clinic
     * @param {string} contactId ID of the contact
     * @param {Object} values Contact values
     * @param {string} values.firstName Contact's first name
     * @param {string} values.lastName Contact's last name
     * @param {string} values.email Contact's email
     * @param {string} values.phone Contact's phone number
     * @param {boolean} values.active Wether or not the contact shoud be active
     * @param {boolean} values.facilitator Wether or not the contact should be set as facilitator
     * @param {boolean} values.notify Wether or not the contact should receive a notification for each registration
     * @param {string} [values.member_id] Contact member id, if specified
     * @returns {Promise}
     */
    updateContact = (clinicId, contactId, values) => {
        const params = new URLSearchParams();
        params.append('first_name', values.firstName);
        params.append('last_name', values.lastName);
        params.append('email', values.email);
        params.append('phone', values.phone); // optional

        // we will eventually have more notification types, hence the array
        if(values.notify)
            params.append('notification_types[0]', 'clinic_registration');

        if(values.memberId)
            params.append('member_id', values.memberId);

        if(values.showStatus){
            params.append('visibility_on_website', values.showStatus);
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/clinics/${clinicId}/contacts/${contactId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a contact from a clinic
     * @param {string} clinicId ID of the clinic to delete a contact from
     * @param {string} contactId ID of the contact to delete
     * @returns {Promise}
     */
    deleteContact = (clinicId, contactId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/clinics/${clinicId}/contacts/${contactId}` }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets all the clinic's instructors
     * @param {string} clinicId The clinic we want to get the instructors from
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinics/Apicontroller%5CClinics%5CClinics%3A%3AgetAllClinicInstructors|documentation}
     * @returns {Promise.<Array>}
     */
    getClinicInstructors = (clinicId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinicId}/instructors`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_instructors;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates an instructor for a clinic
     * @param {string} clinicId ID of the clinic
     * @param {Member} values Instructor values
     * @param {string} values.firstName Instructor's first name
     * @param {string} values.lastName Instructor's last name
     * @param {string} values.email Instructor's email
     * @param {string} values.phone Instructor's phone number
     * @param {boolean} values.active Wether or not the instructor should be active
     * @param {string} [values.member_id] Instructor member id, if specified
     * @returns {Promise}
     */
    createInstructor = (clinicId, values) => {
        const params = new URLSearchParams();
        params.append('first_name', values.firstName);
        params.append('last_name', values.lastName);
        params.append('email', values.email);
        params.append('active', 1); // no possibility to "deactivate" a contact (user may delete and re-insert)

        if(values.phone)
            params.append('phone', values.phone); // optional
        if(values.memberId)
            params.append('member_id', values.memberId);
        if(values.identityId)
            params.append('identity_id', values.identityId);
        if(values.clinic_permission)
            params.append('clinic_permission', values.clinic_permission);
        if(values.showStatus){
            params.append('visibility_on_website', values.showStatus);
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/clinics/${clinicId}/instructors` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_instructor_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    updateClinicInstructors = (clinicId, instructors = []) => {
        const params = new URLSearchParams();

        instructors.forEach((instructor, instructorIndex) => {
            const instructorKeys = Object.keys(instructor);

            instructorKeys.forEach((key) => {
                const value = instructor[key];

                if(value){
                    if(key === "member"){
                        params.append(`instructor[${instructorIndex}][member_id]`, value.member_id);
                    }else if(key === "lastName"){
                        params.append(`instructor[${instructorIndex}][last_name]`, value);
                    }else if(key === "firstName"){
                        params.append(`instructor[${instructorIndex}][first_name]`, value);
                    }else if(key === "memberId"){
                        params.append(`instructor[${instructorIndex}][member_id]`, value);
                    }else if(key === "clinicPermission"){
                        params.append(`instructor[${instructorIndex}][clinic_permission]`, value);
                    }else if(key === "identityId"){
                        params.append(`instructor[${instructorIndex}][identity_id]`, value);
                    }else if(key === "clinicInstructorId"){
                        params.append(`instructor[${instructorIndex}][instructor_id]`, value);
                    }else if([ "phone", "email" ].includes(key)){
                        params.append(`instructor[${instructorIndex}][${key}]`, value);
                    }
                }
            })
        })

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/clinics/${clinicId}/instructors` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_instructor_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates an instructor for a clinic
     * @param {string} clinicId ID of the clinic
     * @param {string} instructorId ID of the instructor
     * @param {Member} values Instructor values
     * @param {string} values.firstName Instructor's first name
     * @param {string} values.lastName Instructor's last name
     * @param {string} values.email Instructor's email
     * @param {string} values.phone Instructor's phone number
     * @param {boolean} values.active Wether or not the instructor shoud be active
     * @param {string} [values.member_id] Instructor member id, if specified
     * @returns {Promise}
     */
    updateInstructor = (clinicId, instructorId, values) => {
        const params = new URLSearchParams();

        params.append('first_name', values.firstName);
        params.append('last_name', values.lastName);
        params.append('email', values.email);
        params.append('phone', values.phone);// optional
        params.append('clinic_permission', values.clinicPermission);

        if(values.memberId)
            params.append('member_id', values.memberId);


        if(values.identityId)
            params.append('identity_id', values.identityId);

        if(values.showStatus){
            params.append('visibility_on_website', values.showStatus);
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/clinics/${clinicId}/instructors/${instructorId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes an instructor from a clinic
     * @param {string} clinicId ID of the clinic to delete an instructor from
     * @param {string} instructorId ID of the instructor to delete
     * @returns {Promise}
     */
    deleteInstructor = (clinicId, instructorId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/clinics/${clinicId}/instructors/${instructorId}` }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a session and returns its ID with a Promise
     * @param {string} clinicId ID of the clinic to add a session to
     * @param {Object} values Object containing all the values needed to create a session
     * @param {string} values.format Session's format, can be either onlineLive, onlineVODs or onSite
     * @param {string} values.note Session's note
     * @param {string} values.urlLink Session's URL link (if the session is online)
     * @param {string} values.startDate Session's start date (ISO format)
     * @param {string} values.endDate Session's end date (ISO format)
     * @param {string} values.duration Session's duration (example: 12:30)
     * @param {boolean} values.mandatory Wether or not the session is mandatory
     * @param {string} values.locationName Session's location's name (if the session is on site)
     * @param {string} values.address Session's location's address (if the session is on site)
     * @param {string} values.city Session's location's city (if the session is on site)
     * @param {string} values.zip Session's location's zip (if the session is on site)
     * @param {string} values.country Session's location's country (if the session is on site, example: 'QC')
     * @param {string} values.province Session's location's province (if the session is on site, example: 'CA')
     * @param {boolean} values.active Wether or not the session should be active
     * @param {string} values.languages Languages available for the session (example: 'en,fr')
     * @returns {Promise}
     */
    createSession = (clinicId, values) => {
        const params = new URLSearchParams();
        params.append('session_format', values.format);
        params.append('note', values.note);
        params.append('url_link', values.urlLink);
        params.append('start_date', values.startDate);
        params.append('end_date', values.endDate);
        params.append('duration', values.duration);
        params.append('mandatory', !!values.mandatory >>> 0);
        params.append('location_name', values.locationName);
        params.append('street_number', values.streetNumber);
        params.append('street', values.address);
        params.append('city', values.city);
        params.append('postal_code', values.zipCode);
        params.append('country_code', values.country);
        params.append('province_code', values.state);
        params.append('origin_address', values.origin);
        params.append('active', !!values.active >>> 0);
        params.append('languages', values.languages)

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/clinics/${clinicId}/sessions` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_session_id
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a clinic session - This function uses a PUT, send all parameters
     * @param {string} clinicId ID of the clinic corresponding to the session to update
     * @param {string} sessionId ID of the session to update
     * @param {Object} values Object containing all the values needed to update a session
     * @param {string} values.format Session's format, can be either onlineLive, onlineVODs or onSite
     * @param {string} values.note Session's note
     * @param {string} values.urlLink Session's URL link (if the session is online)
     * @param {string} values.startDate Session's start date (ISO format)
     * @param {string} values.endDate Session's end date (ISO format)
     * @param {string} values.duration Session's duration (example: 12:30)
     * @param {boolean} values.mandatory Wether or not the session is mandatory
     * @param {string} values.locationName Session's location's name (if the session is on site)
     * @param {string} values.address Session's location's address (if the session is on site)
     * @param {string} values.city Session's location's city (if the session is on site)
     * @param {string} values.zip Session's location's zip (if the session is on site)
     * @param {string} values.country Session's location's country (if the session is on site, example: 'QC')
     * @param {string} values.province Session's location's province (if the session is on site, example: 'CA')
     * @param {boolean} values.active Wether or not the session should be active
     * @param {string} values.languages Languages available for the session (example: 'en,fr')
     * @returns {Promise}
     */
    updateSession = (clinicId, sessionId, values) => {
        const params = new URLSearchParams();
        params.append('session_format', values.format);
        params.append('note', values.note);
        params.append('url_link', values.urlLink);
        params.append('start_date', values.startDate);
        params.append('end_date', values.endDate);
        params.append('duration', values.duration);
        params.append('mandatory', !!values.mandatory >>> 0);
        params.append('location_name', values.locationName);
        params.append('street_number', values.streetNumber);
        params.append('street', values.address);
        params.append('city', values.city);
        params.append('postal_code', values.zipCode);
        params.append('country_code', values.country);
        params.append('province_code', values.state);
        params.append('origin_address', values.origin);
        params.append('active', !!values.active >>> 0);
        params.append('languages', values.languages)

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/clinics/${clinicId}/sessions/${sessionId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets all the clinic's sessions
     * @param {string} clinicId The clinic we want to get the sessions from
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinic%20Sessions/Apicontroller%5CClinics%5CClinicsessions%3A%3AgetClinicSessions|documentation}
     * @returns {Promise.<Array>}
     */
    getClinicSessions = (clinicId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinicId}/sessions`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_sessions;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a session from a clinic
     * @param {string} clinicId ID of the clinic to delete a session from
     * @param {string} sessionId ID of the session to delete
     * @returns {Promise}
     */
    deleteSession = (clinicId, sessionId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/clinics/${clinicId}/sessions/${sessionId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * @param {object} [queryParams] The params for that specific api call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinics/GetClinicsByParams|documentation}
     * @returns {Promise}
     */
    getClinics = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/clinics',
            // Creates an object with an org id by default being the current org
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinics;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * @param {object} [queryParams] The params for that specific api call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinics/GetClinicsByParams|documentation}
     * @returns {Promise}
     */
    getClinicsLite = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/clinics/lite',
            // Creates an object with an org id by default being the current org
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinics;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets a clinic
     * @param {string} clinicId ID of the clinic to get
     * @returns {Promise}
     */
    getClinic = (clinicId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinicId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinics[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets all the organization's item
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Fees/Apicontroller%5CPricing%5CFees%3A%3AgetAllFees|documentation}
     * @returns {Promise.<Array>}
     */
    getClinicItems = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/fees',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
                //period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.fees;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets a specific fee from its ID
     * @param {string} feeId Fee id that we want to get
     * @returns {Promise}
     */
    getClinicItem = (feeId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/fees/${feeId}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.fees
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a clinic item
     * @param {object} [formData] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Fees/Apicontroller%5CPricing%5CFees%3A%3AcreateFees|documentation}
     * @param {object} [i18n] The query params for that call
     * @returns {Promise.<string>} Returns the fee_id created
     */
    createClinicItems = (formData) => {
        const validData = new URLSearchParams({
            organisation_id: this.props.OrganizationContext.organisation_id,
        })

        for(const key in formData){
            switch (key){
                default: // name, descritpion, etc
                    validData.append(key, formData[key]);
                    break;
            }
        }

        return API_SPORDLE.post('/fees', validData)
            .then((response) => {
                if(response.data.status){
                    return response.data.fee_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a clinic item
     * @param {string} feeId The fee id to delete - Refer to the {@link https://api.id.spordle.dev/documentations/#/Fees/Apicontroller%5CPricing%5CFees%3A%3AdeleteFee|documentation}
     * @returns {Promise}
     */
    deleteClinicItems = (feeId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/fees/${feeId}` }))
            .then((response) => {
                if(response.data.status){
                    return;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * partially updated a clinic item
     * @param {string} feeId The fee id to update - Refer to the {@link https://api.id.spordle.dev/documentations/#/Fees/Apicontroller%5CPricing%5CFees%3A%3ApatchFees|documentation}
     * @param {object} [values] The values to update
     * @param {string} [lang] Language variable corresponding to the values - Used for the I18N logic
     * @param {object} [i18n] Object containing an array of all the languages available and an object of the initialValues for language variables - Used for the I18N logic
     * @returns {Promise.<any>}
     */
    updateClinicItems = (feeId, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'status':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                case 'price':
                    params.append('amount', values[key]);
                    break;
                default: // name, descritpion, etc
                    params.append(key, values[key]);
                    break;
            }
        }
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/fees/${feeId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a new status for a clinic
     * @param {string} clinicId ID of the clinic to add a status to
     * @param {Object} values Values to create a status with
     * @param {string} values.status Name of the status to create
     * @param {string} values.startDate Start date of the status
     */
    createStatus = (clinicId, values, active_clinic_state_id) => {
        const params = new URLSearchParams();
        params.append('status', values.status);
        params.append('start_date', values.startDate);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/clinics/${clinicId}/status` }), params)
            .then(async(response) => {
                if(response.data.status){
                    this.setState((prev) => ({
                        currentClinic: {
                            ...prev.currentClinic,
                            active_status: values.status,
                            active_clinic_state_id: active_clinic_state_id,
                        },
                    }));
                    // gets clinic info to update the clinic statuses: clinicContext.currentClinic.status
                    await this.getAllClinicInfos(clinicId).catch((error) => { console.error(error.message) })
                    return response.data.status_id
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a statuses for a clinic
     * @param {string} clinicId ID of the clinic to add a status to
     * @param {Array} statuses Values to update statuses with - Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinics/Apicontroller%5CClinics%5CClinics%3A%3AupdateClinicStatusBatch|documentation}
     * @returns {Promise}
     */
    updateStatuses = (clinicId, statuses = []) => {
        const params = new URLSearchParams();
        jsObjectToApi({ clinic_status: statuses }, params, {
            skipNull: true,
        });

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/clinics/${clinicId}/status` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Share a clinic with other organisations
     * @param {string} clinicId ID of the clinic to share
     * @param {string[]} orgIds Organisations to share to - Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinics/Apicontroller%5CClinics%5CClinics%3A%3Ashare|documentation}
     * @returns {Promise}
     */
    shareClinicWith = (clinicId, orgIds = []) => {
        const params = new URLSearchParams();
        jsObjectToApi({ organisations: orgIds }, params);
        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/clinics/${clinicId}/share` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get all clinic attendees from a clinic
     * @param {string} clinic_id
     * @param {boolean} fromAPI
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinic%20Attendees/Apicontroller%5CClinics%5CClinicAttendees%3A%3AgetClinicAttendees|documentation}
     * @returns {Promise.<Array>}
     */
    getClinicAttendees = (clinic_id, fromAPI = false) => {
        if(this.state.currentClinic.clinic_attendees && this.state.currentClinic.clinic_attendees.find((attendee) => attendee.clinic.clinic_id === clinic_id) && !fromAPI){
            return Promise.resolve(this.state.currentClinic.clinic_attendees)
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinic_id}/attendees?lite=1`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    const {
                        clinic_attendees,
                        clinic,
                        identities,
                        invoices,
                        members,
                        organisations,
                    } = response.data;

                    const clinicAttendees = clinic_attendees.map((attendee) => {
                        // Main ressources
                        attendee.clinic = clinic[attendee.clinic_id];
                        delete attendee.clinic_id;
                        attendee.member = members[attendee.member_id];
                        delete attendee.member_id;
                        attendee.invoice = invoices[attendee.invoice_number];

                        // Sub resources - member
                        attendee.member.organisation = organisations[attendee.member.organisation_id];

                        // Sub resources - invoice
                        if(attendee.invoice){
                            attendee.invoice.initiated_by = identities[attendee.invoice.initiated_by_id];
                            delete attendee.invoice.initiated_by_id;
                        }

                        return attendee;
                    });

                    this.setState((prevState) => ({
                        currentClinic: {
                            ...prevState.currentClinic,
                            clinic_attendees: clinicAttendees,
                        },
                    }))

                    return clinicAttendees
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a specific clinic attendee by member_id
     * @param {string} clinic_id
     * @param {string} member_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinic%20Attendees/Apicontroller%5CClinics%5CClinicAttendees%3A%3AgetSpecificClinicAttendeeByMemberId|documentation}
     * @returns {Promise}
     */
    getClinicAttendeeByMemberId = (clinic_id, member_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinic_id}/members/${member_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_attendee[0];
                }
                throw new Error(response.data.errors[0].code);
            }, serverError)
    }


    /**
     * Create a Clinic Attendee
     * @param {Object} values
     * @param {string} clinic_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinic%20Attendees/Apicontroller%5CClinics%5CClinicAttendees%3A%3AcreateClinicAttendees|documentation}
     * @returns {Promise}
     */
    createClinicAttendee = (clinic_id, values = {}) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'member_id':
                    params.append('member_id', values.member_id);
                    break;
                case 'invoice_item_id':
                    params.append('invoice_item_id', values.invoice_item_id);
                    break;
                case 'qualification_external_provider_id':
                    params.append('qualification_external_provider_id', values.qualification_external_provider_id);
                    break;
                case 'attended':
                    params.append('attended', values.attended);
                    break;
                case 'attended_date':
                    params.append('attended_date', values.attended_date);
                    break;
                case 'passed':
                    params.append('passed', values.passed);
                    break;
                case 'passed_date':
                    params.append('passed_date', values.passed_date);
                    break;
                case 'certified':
                    params.append('certified', values.certified);
                    break;
                case 'certified_date':
                    params.append('certified_date', values.certified_date);
                    break;
                case 'sent_to_external_provider':
                    params.append('sent_to_external_provider', values.sent_to_external_provider);
                    break;
                case 'certification_number':
                    params.append('certification_number', values.certification_number);
                    break;
                case 'expiration_date':
                    params.append('expiration_date', values.expiration_date);
                    break;
                case 'memo':
                    params.append('memo', values.memo);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/clinics/${clinic_id}/attendees`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_attendee_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Certifies a clinic attendee
     * @param {string} clinicId Clinic ID
     * @param {string} clinicAttendeeId Clinic attendee ID
     * @param {Object} values Object containing the qualification values
     * @param {string} values.certificationDate Date of the certification (YYYY-MM-DD)
     * @param {string} [values.certificationNumber] String representing the certification number of the qualification - OPTIONNAL
     * @param {string} [values.expirationDate] Expiration date of the qualification - OPTIONNAL
     * @param {string} [values.memo] Memo - OPTIONNAL
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Clinic%20Attendees/Apicontroller%5CClinics%5CClinicattendees%3A%3AcertifyClinicAttendee|documentation}
     * @returns {Promise}
     */
    certifyClinicAttendee = (clinicId, clinicAttendeeId, { certificationDate, certificationNumber, expirationDate, memo }) => {
        const params = new URLSearchParams()

        params.append('certified_date', certificationDate);
        if(certificationNumber)
            params.append('certification_number', certificationNumber)
        if(expirationDate)
            params.append('expiration_date', expirationDate)
        if(memo)
            params.append('memo', memo)

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/clinics/${clinicId}/attendees/${clinicAttendeeId}/certify`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_qualification_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * update a Clinic Attendee (partial)
     * @param {string} clinic_id
     * @param {string} clinic_attendee_id
     * @param {Object} values
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinic%20Attendees/Apicontroller%5CClinics%5CClinicAttendees%3A%3AupdateClinicAttendee|documentation}
     * @returns {Promise}
     */
    updateClinicAttendeePartial = (clinic_id, clinic_attendee_id, values = {}) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'attended':
                    params.append('attended', values.attended);
                    break;
                case 'attended_date':
                    params.append('attended_date', values.attended_date);
                    break;
                case 'passed':
                    params.append('passed', values.passed);
                    break;
                case 'passed_date':
                    params.append('passed_date', values.passed_date);
                    break;
                case 'post_task':
                    params.append('post_task', values.post_task);
                    break;
                case 'pre_task':
                    params.append('pre_task', values.pre_task);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/clinics/${clinic_id}/attendees/${clinic_attendee_id}`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update multiple Clinic Attendees (partial)
     * @param {string} clinic_id
     * @param {Object} values
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Clinic%20Attendees/ae00a2243019732893715560f14d1ea6 documentation}
     * @returns {Promise}
     */
    updateClinicAttendeesPartial = (clinic_id, values = {}) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'clinic_attendee_id':
                    params.append('clinic_attendee_id', Array.isArray(values.clinic_attendee_id) ? values.clinic_attendee_id.join(',') : values.clinic_attendee_id);
                    break;
                case 'attended':
                    params.append('attended', values.attended);
                    break;
                case 'attended_date':
                    params.append('attended_date', values.attended_date);
                    break;
                case 'passed':
                    params.append('passed', values.passed);
                    break;
                case 'passed_date':
                    params.append('passed_date', values.passed_date);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/clinics/${clinic_id}/attendees`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return {
                        failed: response.data.invalid_clinic_attendees,
                        success: response.data.updated_clinic_attendees,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Deletes a Clinic Attendees by clicnic
    * @param {string} clinic_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinic%20Attendees/Apicontroller%5CClinics%5CClinicAttendees%3A%3AdeleteClinicAttendee|documentation}
    * @returns {Promise}
    */
    deleteClinicAttendees = (clinic_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/clinics/${clinic_id}/attendees` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
    * Deletes a specific Clinic Attendee
    * @param {string} clinic_id
    * @param {string} clinic_attendee_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Clinic%20Attendees/Apicontroller%5CClinics%5CClinicAttendees%3A%3AdeleteSpecificClinicAttendee|documentation}
    * @returns {Promise}
    */
    deleteClinicAttendee = (clinic_id, clinic_attendee_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/clinics/${clinic_id}/attendees/${clinic_attendee_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get all members on a clinic waiting list
     * @param {string} clinic_id
     * @param {string} period_id
     * @param {string} organisation_id
     * @param {string} type
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Waiting%20List%20Items/d371567b6068153668f258d8defd23e8|documentation}
     * @returns {Promise.<Array>}
     */
    getClinicWaitinglist = ({ clinic_id, period_id, organisation_id, type }) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/waiting-list-items`,
            query: {
                item_id: clinic_id,
                period_id: period_id || this.state.currentClinic.period_id,
                organisation_id: organisation_id || this.state.currentClinic.organisation_id,
                type: type,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.waiting_list_items;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * approve a member on a clinic waitlist
     * @param {string} waiting_list_item_sequential_number the waiting list number to approve {@link https://api.id.spordle.dev/documentations/#/Fees/Apicontroller%5CPricing%5CFees%3A%3ApatchFees|documentation}
     * @param {object} [values] The values to update
     * @returns {Promise.<any>}
     */
    approveWaitlistMember = (waiting_list_item_sequential_number, identity_id) => {
        const params = new URLSearchParams();
        if(identity_id)
            params.append('identity_id', identity_id);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/waiting-list-items/${waiting_list_item_sequential_number}/approve` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * approve a member on a clinic waitlist
     * @param {string} waiting_list_item_sequential_number the waiting list number to approve {@link https://api.id.spordle.dev/documentations/#/Fees/Apicontroller%5CPricing%5CFees%3A%3ApatchFees|documentation}
     * @param {string} cancellation_reason the reason for which the waitlist entry was cancelled
     * @param {object} [values] The values to update
     * @returns {Promise.<any>}
     */
    cancelWaitlistMember = (waiting_list_item_sequential_number, cancellation_reason) => {
        const params = new URLSearchParams();
        params.append('cancellation_reason', cancellation_reason);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/waiting-list-items/${waiting_list_item_sequential_number}/cancel` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * approve a member on a clinic waitlist
     * @param {string} waiting_list_item_sequential_number the waiting list number to approve {@link https://api.id.spordle.dev/documentations/#/Fees/Apicontroller%5CPricing%5CFees%3A%3ApatchFees|documentation}
     * @param {string} cancellation_reason the reason for which the waitlist entry was cancelled
     * @param {object} [values] The values to update
     * @returns {Promise.<any>}
     */
    resetWaitlistMember = (waiting_list_item_sequential_number) => {
        const params = new URLSearchParams();

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/waiting-list-items/${waiting_list_item_sequential_number}/reset` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Cancel or uncancel a list of given attendees
    * @param {string} clinic_id
    * @param {object} queryParams
    * @param {string[]} queryParams.attendeesIds
    * @param {'CANCEL'|'UNCANCEL'} queryParams.mode
    * @param {string} queryParams.cancelReason
    * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Clinic%20Attendees/d22d4956d79c81477499771fbcfe02d4|documentation}
    * @returns {Promise}
    * @throws {object}
    */
    partiallyUpdateClinicAttendeeState = (clinic_id, queryParams = {}) => {
        const params = new URLSearchParams();

        params.append('mode', queryParams.mode);
        params.append('cancellation_reason', queryParams.cancelReason);

        for(let i = 0; i < queryParams.attendeesIds.length; i++){
            const attendeeId = queryParams.attendeesIds[i];
            params.append(`clinic_attendee_id[${i}]`, attendeeId);
        }

        return API_SPORDLE.patch(
            queryString.stringifyUrl({ url: `/clinics/${clinic_id}/attendees/state` }),
            params,
        )
            .then((response) => {
                if(response.data.status){
                    return {
                        warnings: response.data.warnings,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Sends emails to a specified list of clinic attendees
    * @param {string} clinic_id
    * @param {object} queryParams
    * @param {string[]} queryParams.attendeesIds
    * @param {string} queryParams.message
    * @param {string} queryParams.subject
    * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Clinic%20Attendees/b10bfd494a82299d64d78ae98f282ffa|documentation}
    * @returns {Promise}
    * @throws {object}
    */
    sendCommunicationToAttendees = (clinic_id, queryParams = {}) => {
        const params = new URLSearchParams();

        params.append('message', queryParams.message);
        params.append('subject', queryParams.subject);

        for(let i = 0; i < queryParams.attendeesIds.length; i++){
            const attendeeId = queryParams.attendeesIds[i];
            params.append(`clinic_attendee_id[${i}]`, attendeeId);
        }

        return API_SPORDLE.post(
            queryString.stringifyUrl({ url: `/clinics/${clinic_id}/attendees/communications` }),
            params,
        )
            .then((response) => {
                if(response.data.status){
                    return {
                        fail: response.data.email_fail_sent_to,
                        success: response.data.email_sent_to,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET - get list of all clinic states by organization id
     * @param {string} [organisation_id] ID of the identity role
     * @returns {Promise.<Array>}
    */
    getClinicStates = (organisation_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/clinic-states',
            query: { organisation_id: organisation_id || this.props.OrganizationContext.organisation_id },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.clinic_states.filter((state) => state.active == '1' && state.visible_on_platform == '1');
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * GET - get list of external courses for a clinic (1st version: moodle for now)
     * @param {string} [clinicId] ID of the clinic
     * @param {string} [partner] Partners (MOODLE)
     * @returns {Promise.<Array>}
    */
    getClinicExternalCourses = (clinicId, partner = 'MOODLE') => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/clinics/${clinicId}/external-course`,
            query: { external_partner: partner },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.clinic_external_courses;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    render(){
        return (
            <ClinicsContext.Provider value={{ ...this.state, ...this }}>
                {this.props.children}
            </ClinicsContext.Provider>
        );
    }
}

export default withContexts(PeriodsContext, QualificationCategoriesContext, MembersContext, MerchantAccountContext, OrganizationContext)(ClinicsContextProvider);
