import { FormikTextArea } from '@spordle/formik-elements';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
    Alert, Button, Col,
    Collapse, FormGroup,
    Label, ModalBody,
    ModalFooter, ModalHeader, Row
} from "reactstrap";
import { object, string } from 'yup';
import AnalyticsModal from "../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import arrowRightBlocked from '../../../assets/images/icons/movement/arrowRightBlocked.svg';
import { MembersContext } from '../../../contexts/MembersContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import GenderLabel from '../../GenderLabel';
import OverlayLoader from '../../loading/OverlayLoader';
import UserDisplay from '../../userDisplay/UserDisplay';
import UserImg from '../../UserImg';

const TransferRequestRefuse = ({ isOpen, toggle, transfer, onTransfer }) => {
    const membersContext = useContext(MembersContext);

    return (
        <AnalyticsModal analyticsName='TransferRequestRefuse' isOpen={isOpen}>
            <Formik
                initialValues={{
                    details: '',
                }}
                validationSchema={object().shape({
                    details: string(),
                })}
                onSubmit={({ details }, { setSubmitting, setStatus }) => {
                    setStatus();
                    membersContext.createMemberTransfersStates(transfer.member.member_id, transfer.member_transfer_id, {
                        step: transfer.current_step,
                        state: 'DECLINE',
                        details: details,
                    })
                        .then(() => {
                            setSubmitting(false);
                        })
                        .then(toggle)
                        .then(onTransfer)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setSubmitting(false);
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            }
                        });
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader toggle={toggle}>
                                <Translate id='transfer.title' />
                            </ModalHeader>
                            <ModalBody className="text-center">
                                <div className="h4 font-bold mb-3"><Translate id='transfer.modal.refuse.message' /></div>
                                <UserDisplay card>
                                    <UserDisplay.Container>
                                        <UserImg
                                            abbr={transfer.member.first_name[0] + transfer.member.last_name[0]}
                                            src={null}
                                            width={50}
                                            height={50}
                                            alt={`${transfer.member.first_name} ${transfer.member.last_name}`}
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title>{transfer.member.first_name} {transfer.member.last_name}</UserDisplay.Title>
                                        <Link className="text-nowrap" to={`/members/profile/${transfer.member.member_id}`}>#{transfer.member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></Link>
                                        <UserDisplay.Subtitle>
                                            <GenderLabel gender={transfer.member.gender} />
                                            <span className='mx-1'>-</span>
                                            <DateFormat value={transfer.member.birthdate} utc /> (<Translate id='misc.yearsOld' values={{ age: transfer.member.age }} />)
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                                <div className="font-bold text-dark mb-3">
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={transfer.workflow?.name}
                                        i18n={transfer.workflow?.i18n}
                                    />
                                    {transfer.transfer_duration &&
                                        <div>
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={transfer.transfer_duration.name}
                                                i18n={transfer.transfer_duration.i18n}
                                            />
                                        </div>
                                    }
                                </div>
                                <Row form>
                                    <Col xs="4">
                                        <div className='d-flex justify-content-center'>
                                            <UserImg
                                                abbr={transfer.organisation.abbreviation}
                                                src={transfer.organisation.logo?.full_path}
                                                filePos={transfer.organisation.logo?.file_position}
                                                width={60}
                                                height={60}
                                                alt={transfer.organisation.organisation_name}
                                            />
                                        </div>
                                        <div className="font-medium text-dark mt-1">
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={transfer.organisation.organisation_name}
                                                i18n={transfer.organisation.i18n}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs="4" className="mt-4"><img src={arrowRightBlocked} alt="Transfer to" width="50" /></Col>
                                    {transfer.target_organisation ?
                                        <Col xs="4">
                                            <div className='d-flex justify-content-center'>
                                                <UserImg
                                                    abbr={transfer.target_organisation.abbreviation}
                                                    src={transfer.target_organisation.logo?.full_path}
                                                    filePos={transfer.target_organisation.logo?.file_position}
                                                    width={60}
                                                    height={60}
                                                    alt={transfer.target_organisation.organisation_name}
                                                />
                                            </div>
                                            <div className="font-medium text-dark mt-1">
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={transfer.target_organisation.organisation_name}
                                                    i18n={transfer.target_organisation.i18n}
                                                />
                                            </div>
                                        </Col>
                                        :
                                        <Col><div>-</div></Col>
                                    }
                                </Row>
                                <FormGroup className="text-left">
                                    <Label for='details' className='text-muted'><Translate id='transfer.modal.refuse.details' /></Label>
                                    <FormikTextArea id='details' name='details' rows='5' trim />
                                </FormGroup>

                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    <Alert color='danger' className='text-left'>{formik.status}</Alert>
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='transfer.modal.refuse.refuse' /></Button>
                                <Button color='primary' type='button' onClick={toggle} outline disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

export default TransferRequestRefuse;