import Translate from "@spordle/intl-elements";
import { useState } from "react";
import QuickViewActionButton from "../../../../../../components/quickView/QuickViewActionButton";
import QuickViewButton from "../../../../../../components/quickView/QuickViewButton";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import AttendeeCard from "../sidePanelActionsModals/AttendeeCard";
import AttendeeModalBulkUpdate from "../sidePanelActionsModals/AttendeeModalBulkUpdate";

/**
 * @typedef {Object} MultiProps
 * @prop {object[]} selectedRows
 * @prop {function} handleResendInvoice
 * @prop {function} onMultiSelectChange
 */

/**
 * @description SidePanel shown when multiple attendees are selected in the spordle table
 * @param {MultiProps & import("./SidePanelAttendeeSharedInfo").SharedProps} props
 * @returns {JSX.Element}
 */
const SidePanelAttendeeMulti = ({ selectedRows: attendees, showCommModal, showCommModalV2, showCancelModal, handleResendInvoice, onMultiSelectChange, ...props }) => {
    const [ bulkUpdateMode, setBulkUpdateMode ] = useState(null);

    return (
        <>
            <SidePanel.Header>
                <AttendeeModalBulkUpdate {...props} participants={attendees} mode={bulkUpdateMode} isOpen={!!bulkUpdateMode} toggle={() => { setBulkUpdateMode(null) }} />
                <div className='d-flex mb-3 align-items-center'>
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu>
                        <SidePanel.MenuAction onClick={() => { setBulkUpdateMode('PRESENCE') }}>
                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.bulkUpdate.presence' />
                        </SidePanel.MenuAction>
                        <SidePanel.MenuAction onClick={() => { setBulkUpdateMode('RESULT') }}>
                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.bulkUpdate.result' />
                        </SidePanel.MenuAction>
                        <SidePanel.MenuAction onClick={showCancelModal}>
                            <Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee' values={{ count: attendees.length }} />
                        </SidePanel.MenuAction>
                        <SidePanel.MenuAction divider />
                        <SidePanel.MenuAction onClick={() => handleResendInvoice(false)}>
                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoices' />
                        </SidePanel.MenuAction>
                        <SidePanel.MenuAction onClick={() => handleResendInvoice(true)}>
                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoicesWithReceipts' />
                        </SidePanel.MenuAction>
                        <SidePanel.MenuAction divider />
                        <SidePanel.MenuAction action="EDIT" componentCode="communications" componentPermissionCode="communications_manage" onClick={showCommModal}>
                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.sendCommunication' />
                        </SidePanel.MenuAction>
                        <QuickViewActionButton member={attendees.map((attendee) => attendee.member)} />
                    </SidePanel.ActionsMenu>
                </div>
                <SidePanel.Title>
                    <QuickViewButton member={attendees.map((a) => a.member)} className="d-flex">
                        {attendees.length} <Translate id='clinics.profile.attendees.sidepanel.selected.attendees' />
                    </QuickViewButton>
                </SidePanel.Title>
            </SidePanel.Header>
            <SidePanel.Body>
                {attendees.map((att) => (
                    <AttendeeCard att={att} onMultiSelectChange={onMultiSelectChange} key={att.clinic_attendee_id} />
                ))}
            </SidePanel.Body>
        </>
    )
}

export default SidePanelAttendeeMulti;