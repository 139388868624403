import React, { createRef } from "react";
import {
    Label,
    Table
} from "reactstrap";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import withContexts from "../../../../../helpers/withContexts";

// Contexts
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { RolesContext } from "../../../../../contexts/RolesContext";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import { groupByAsArray } from "@spordle/helpers";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import AddOrganizationModal from "../components/AddOrganizationModal";
import Translate from "@spordle/intl-elements";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import CollapsibleCard from "../../../../../components/collapsibleCard/CollapsibleCard";
import DisplayOrganization from "../../../../../components/organization/DisplayOrganization";
import { ZONES } from "../groupingsConstants";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import BtnDisplay from "../../../../../components/input/BtnDisplay";
import { withRouter } from 'react-router-dom';
import SpordleTableProvider, { SearchInput, SpordleTableView } from "@spordle/datatables";
import { I18nContext } from "../../../../../contexts/I18nContext";

class ZonesParentSidepanel extends React.Component{
    state = {
        isLoading: false,
        affiliatedOrgs: null,
        addModalIsOpen: false,
        parentOrg: null,
    };

    orgsTableRef = createRef();

    componentDidMount(){
        this.getAffiliatedOrgs();
        this.getOrganization();
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0].organisation_id !== this.props.selectedRows[0].organisation_id){
            this.getAffiliatedOrgs();
            this.getOrganization();
        }
    }

    toggleAddModal = () => {
        this.setState((prev) => ({
            addModalIsOpen: !prev.addModalIsOpen,
        }))
    }

    getAffiliatedOrgs = () => {
        this.setState(() => ({
            isLoading: true,
        }))
        this.props.OrganizationContext.getAffiliatedOrganizations(this.props.selectedRows[0].organisation_id)
            .then((affiliatedOrgs) => {
                this.setState(() => ({
                    isLoading: false,
                    affiliatedOrgs: groupByAsArray(affiliatedOrgs.map((org) => ({ ...org, orgId: org.linked_organisation.organisation_id })), 'orgId'),
                }))
                this.orgsTableRef.current?.refreshTable?.();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({
                        isLoading: false,
                    }))
                }
            })
    }

    getOrganization = () => {
        this.setState(() => ({
            isLoading: true,
        }))
        this.props.OrganizationContext.getOrganization(this.props.selectedRows[0].organisation_id)
            .then((org) => {
                this.setState(() => ({
                    isLoading: false,
                    parentOrg: org.organisation_parent,
                }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({
                        isLoading: false,
                    }))
                }
            })
    }

    updateAffiliatedOrg = (affiliatedOrgId, orgId, active) => {
        this.setState(() => ({
            isLoading: true,
        }))
        this.props.OrganizationContext.updateAffiliatedOrganization(affiliatedOrgId, {
            active: active,
        })
            .then(() => {
                const groupIndex = this.state.affiliatedOrgs.findIndex((group) => group.key === orgId);
                const index = this.state.affiliatedOrgs[groupIndex].values.findIndex((org) => org.affiliated_organisation_id === affiliatedOrgId);
                const newAffiliatedOrgs = [ ...this.state.affiliatedOrgs ];
                newAffiliatedOrgs[groupIndex].values[index] = {
                    ...newAffiliatedOrgs[groupIndex].values[index],
                    active: active >>> 0,
                }

                this.setState((prevState) => ({
                    isLoading: false,
                    affiliatedOrgs: newAffiliatedOrgs,
                }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({
                        isLoading: false,
                    }))
                }
            })
    }

    deleteAffiliatedOrg = (affiliatedOrgId) => {
        this.setState(() => ({
            isLoading: true,
        }))
        return this.props.OrganizationContext.deleteAffiliatedOrganization(affiliatedOrgId)
            .then(() => {
                this.getAffiliatedOrgs()
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({
                        isLoading: false,
                    }))
                }
            })
    }

    render(){
        const canEdit = this.props.RolesContext.canDoAction("EDIT", "organization", "affiliated_organization");
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <AddOrganizationModal
                    isOpen={this.state.addModalIsOpen}
                    toggle={this.toggleAddModal}
                    organizationId={this.props.selectedRows[0].organisation_id}
                    from={ZONES}
                    parentOrgId={this.state.parentOrg?.organisation_id}
                    affiliatedOrgs={this.state.affiliatedOrgs}
                    onSuccess={() => {
                        this.getAffiliatedOrgs();
                    }}
                />

                <SidePanel.Header>
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />

                        <SidePanel.ActionsMenu action='ADD' componentCode='organization' componentPermissionCode='affiliated_organization' label="misc.action">
                            <SidePanel.MenuAction onClick={() => this.toggleAddModal()}>
                                <Translate id='misc.add' />
                            </SidePanel.MenuAction>
                            <SidePanel.MenuAction
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: '/changeorganization',
                                        state: {
                                            selected: {
                                                identityRole: this.props.IdentityRolesContext.state,
                                                org: this.props.selectedRows[0],
                                            },
                                        },
                                    })
                                }}
                            >
                                <Translate id={'organization.grouping.add.confirmation.manage.' + ZONES} />
                            </SidePanel.MenuAction>
                        </SidePanel.ActionsMenu>
                    </div>
                    <SidePanel.Title>
                        <DisplayI18n
                            field='name'
                            defaultValue={this.props.selectedRows[0].organisation_name}
                            i18n={this.props.selectedRows[0].i18n}
                        />
                    </SidePanel.Title>
                </SidePanel.Header>
                <div className='p-3'>
                    <CrossFade isVisible={!!this.state.affiliatedOrgs}>
                        <SpordleTableProvider
                            id='leaguesAffiliatedOrgs'
                            ref={this.orgsTableRef}
                            dataIndex='key'
                            defaultData={this.state.affiliatedOrgs}
                            viewMode="GRID"
                            searchKeys={[
                                `values.linked_organisation.${this.props.I18nContext.getGenericLocale()}.name`,
                                'values.linked_organisation.organisation_name',
                            ]}
                            pagination={5}
                            loadData={(from) => {
                                switch (from){
                                    case 'REFRESH':
                                    case 'CDM':
                                        return Promise.resolve(this.state.affiliatedOrgs)
                                }
                            }}
                            gridConfig={{
                                gridRender: (group, spordleTable) => (
                                    <CollapsibleCard
                                        className="mt-3"
                                        initOpen={spordleTable.getData().length === 1}
                                        key={group.key}
                                        title={
                                            <div className="font-bold text-dark d-flex">
                                                <DisplayI18n
                                                    field='name'
                                                    i18n={group.values[0].linked_organisation.i18n}
                                                    defaultValue={group.values[0].linked_organisation.organisation_name}
                                                />
                                                <DisplayOrganization onlyButton organisation={{ organisation_id: group.values[0].linked_organisation.organisation_id }} className="font-bold text-dark" />
                                            </div>
                                        }
                                    >
                                        <Table bordered>
                                            <tbody>
                                                {group.values?.map((affiliatedOrg) => (
                                                    <tr key={affiliatedOrg.affiliated_organisation_id}>
                                                        <td className="font-medium">
                                                            <DisplayI18n field='name' defaultValue={affiliatedOrg.division.name} i18n={affiliatedOrg.division.i18n} />
                                                        </td>
                                                        <td className="text-center align-middle td-shrink">
                                                            <Label for={`${affiliatedOrg.affiliated_organisation_id}-switch`} className='switch'>
                                                                <input
                                                                    id={`${affiliatedOrg.affiliated_organisation_id}-switch`} type='checkbox' checked={affiliatedOrg.active == 1}
                                                                    onChange={(e) => {
                                                                        this.updateAffiliatedOrg(affiliatedOrg.affiliated_organisation_id, affiliatedOrg.orgId, e.target.checked)
                                                                    }}
                                                                    disabled={!canEdit}
                                                                />
                                                                <span className="switch-toggler" />
                                                            </Label>
                                                        </td>
                                                        <CanDoAction tag="td" className="text-center align-middle td-shrink" action="DELETE" componentCode="organization" componentPermissionCode="affiliated_organization">
                                                            <ConfirmModal
                                                                toggler={(toggle) => (
                                                                    <button className="reset-btn text-danger" type='button' onClick={toggle}><i className="mdi mdi-delete font-18" /></button>
                                                                )}
                                                                modalTitle={(
                                                                    <>
                                                                        <DisplayI18n
                                                                            field='name'
                                                                            i18n={group.values[0].linked_organisation.i18n}
                                                                            defaultValue={group.values[0].linked_organisation.organisation_name}
                                                                        />
                                                                        <div className="text-muted font-medium"><DisplayI18n field='name' defaultValue={affiliatedOrg.division.name} i18n={affiliatedOrg.division.i18n} /></div>
                                                                    </>
                                                                )}
                                                                actionButtonText='misc.delete'
                                                                translateActionButtonText
                                                                modalMsg="organization.grouping.sidePanel.delete.msg"
                                                                translateModalMsg
                                                                color='danger'
                                                                onConfirm={() => {
                                                                    return this.deleteAffiliatedOrg(affiliatedOrg.affiliated_organisation_id)
                                                                }}
                                                            />
                                                        </CanDoAction>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </CollapsibleCard>
                                ),
                            }}
                        >
                            <BtnDisplay
                                title={<Translate id='organization.grouping.sidePanel.addOrg' />}
                                onClick={() => this.toggleAddModal()}
                                subtitle={<Translate id={'organization.grouping.sidePanel.addOrg.subtitle.' + ZONES} />}
                                icon="organisation"
                            />
                            {this.state.affiliatedOrgs?.length > 0 &&
                                <>
                                    <SearchInput />
                                    <SpordleTableView />
                                </>
                            }
                        </SpordleTableProvider>
                    </CrossFade>
                </div>
            </OverlayLoader>
        )
    }
}

export default withContexts(OrganizationContext, RolesContext, IdentityRolesContext, I18nContext)(withRouter(ZonesParentSidepanel));