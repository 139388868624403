import questionTypeChoices from "./questionTypeChoices";
import questionTypeField from "./questionTypeField";
import questionTypePicker from "./questionTypePicker";
import questionTypeRenderer from "./questionTypeRenderer";

export default{
    ...questionTypeChoices,
    ...questionTypeField,
    ...questionTypePicker,
    ...questionTypeRenderer,
}