import { useDisclosure } from "@mantine/hooks"
import { FormikCheckedButton, FormikSelect } from "@spordle/formik-elements"
import { stringBuilder } from "@spordle/helpers"
import Translate from "@spordle/intl-elements"
import { Form, Formik } from "formik"
import { useContext } from "react"
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap"
import CardSectionTitle from "../../../../../components/CardSectionTitle"
import CrossFade from "../../../../../components/crossFade/CrossFade"
import GenderLabel from "../../../../../components/GenderLabel"
import OverlayLoader from "../../../../../components/loading/OverlayLoader"
import { I18nContext } from "../../../../../contexts/I18nContext"
import { OrganizationContext } from "../../../../../contexts/OrganizationContext"
import { TeamsContext } from "../../../../../contexts/TeamsContext"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import { getTeamMemberStatus } from "../../../TeamHelpers"

const TeamRosterFilters = ({ teamId }) => {
    const teamsContext = useContext(TeamsContext);
    const i18nContext = useContext(I18nContext)
    const organizationContext = useContext(OrganizationContext)

    const [ isOpen, isOpenHandlers ] = useDisclosure(false);

    return (
        <Card className={stringBuilder("card-shadow", { "clickable": !isOpen })} onClick={() => !isOpen ? isOpenHandlers.toggle() : null}>
            <Formik
                key={teamsContext.state.resetFiltersKey}
                initialValues={{
                    gender: '',
                    playerStatusId: '',
                    rosterStatusId: '',
                    tryout: false,
                    import: false,
                    invalidCrc: false,
                    incompleteProfile: false,
                    criticalWaivers: false,
                    missingRequiredFields: false,
                }}
                onSubmit={(values) => {
                    const filters = {};
                    Object.keys(values).forEach((key) => {
                        const value = values[key]
                        if(value && value != 0){
                            switch (key){
                                case 'gender':
                                    filters['member.gender'] = values.gender
                                    break;
                                case 'playerStatusId':
                                    filters['team_member_status.team_member_status_id'] = values.playerStatusId
                                    break;
                                case 'rosterStatusId':
                                    filters.status = values.rosterStatusId
                                    break;
                                case 'tryout':
                                    filters.tryout = 1
                                    break;
                                case 'import':
                                    filters.import = 1
                                    break;
                                case 'invalidCrc':
                                    filters.crc_is_expired = 1
                                    break;
                                case 'incompleteProfile':
                                    filters['member.incomplete_profile'] = 1
                                    break;
                                case 'criticalWaivers':
                                    filters['member.unsigned_critical_waivers'] = 1
                                    break;
                                case 'missingRequiredFields':
                                    filters.missing_required_fields = 1
                                    break;
                            }
                        }
                    })

                    teamsContext.getRosterTablesData(teamId, filters)
                }}
            >
                {(formik) => (
                    <OverlayLoader isLoading={teamsContext.rosterTablesLoading}>
                        <Form>
                            <CardBody>
                                <CardSectionTitle
                                    className={stringBuilder('d-flex align-items-center clickable', { "border-0 mb-0 p-0": !isOpen })}
                                    style={{
                                        transition: 'margin-bottom 0.15s ease-in-out, padding-bottom 0.15s ease-in-out',
                                    }}
                                    onClick={isOpenHandlers.toggle}
                                >
                                    <div className='align-items-center d-flex'>
                                        <span className='align-items-center card-section-title d-flex position-absolute' style={{ height: "70px" }}>
                                            <Translate id='teams.profile.roster.table.filters.title' values={{ filterCount: Object.keys(teamsContext.state.filters || {}).length }} />
                                        </span>
                                    </div>
                                    <div className="ml-auto">
                                        <div className='align-items-center d-flex justify-content-center p-3 position-relative'>
                                            <div onClick={isOpenHandlers.toggle} className={stringBuilder({ "rotate-180": isOpen }, "d-inline-block transition position-absolute align-items-center d-flex justify-content-center")} style={{ height: "70px", width: "70px" }}><i className="mdi mdi-chevron-down font-22" /></div>
                                        </div>
                                    </div>
                                </CardSectionTitle>

                                <CrossFade isVisible={isOpen}>
                                    <Row form>
                                        <Col sm='3'>
                                            <FormGroup>
                                                <Label className='text-muted' for='gender'><Translate id='form.fields.gender' /></Label>
                                                <FormikSelect
                                                    clearable
                                                    name='gender'
                                                    id='gender'
                                                    loadingStatus='success'
                                                    search={false}
                                                    defaultData={organizationContext.settings.gender_selection?.value?.map((gender) => ({
                                                        value: gender,
                                                        label: <GenderLabel gender={gender} />,
                                                        translateLabel: false,
                                                    })) || []}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='3'>
                                            <FormGroup>
                                                <Label className='text-muted' for='playerStatusId'><Translate id='teams.profile.roster.table.playerStatus' /></Label>
                                                <FormikSelect
                                                    clearable
                                                    name='playerStatusId' id='playerStatusId'
                                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                    searchKeys={[
                                                        `i18n.${i18nContext.getGenericLocale()}.name`,
                                                    ]}
                                                    loadData={(from, extra, spordleTable) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return teamsContext.getTeamMemberStatus({ active: '1' })
                                                                    .then((teamMemberStatus) => teamMemberStatus.map((status) => ({
                                                                        label: status.name,
                                                                        value: status.team_member_status_id,
                                                                        i18n: status.i18n,
                                                                    })))
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='3'>
                                            <FormGroup>
                                                <Label className='text-muted' for='rosterStatusId'><Translate id='teams.profile.roster.table.status' /></Label>
                                                <FormikSelect
                                                    clearable search={false}
                                                    name='rosterStatusId' id='rosterStatusId'
                                                    renderOption={({ option }) => getTeamMemberStatus(option.label)}
                                                    loadingStatus='success'
                                                    defaultData={[
                                                        {
                                                            label: 'APPROVED',
                                                            value: 'APPROVED',
                                                        },
                                                        {
                                                            label: 'PENDING',
                                                            value: 'PENDING',
                                                        },
                                                        {
                                                            label: 'DECLINED',
                                                            value: 'DECLINED',
                                                        },
                                                        {
                                                            label: 'PENDING_DEFICIENT',
                                                            value: 'PENDING_DEFICIENT',
                                                        },
                                                    ]}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='3'>
                                            <FormGroup>
                                                <Label>&nbsp;</Label>
                                                <Button color='primary' type='submit' block><i className="fas fa-search mr-1" /><Translate id='misc.search' /></Button>
                                            </FormGroup>
                                        </Col>
                                        <Col sm='4'>
                                            <FormGroup>
                                                <FormikCheckedButton id='tryout' name='tryout' label='teams.profile.roster.table.tryout' translateLabel />
                                                <FormikCheckedButton id='import' name='import' label='teams.profile.roster.table.imported' translateLabel />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='4'>
                                            <FormGroup>
                                                <FormikCheckedButton id='incompleteProfile' name='incompleteProfile' label='members.search.memberSearch.incomplete_profile.tooltip' translateLabel />
                                                <FormikCheckedButton id='missingRequiredFields' name='missingRequiredFields' label='members.search.memberSearch.missingFields.tooltip' translateLabel />
                                            </FormGroup>
                                        </Col>
                                        <Col sm='4'>
                                            <FormGroup>
                                                <FormikCheckedButton id='criticalWaivers' name='criticalWaivers' label='members.search.memberSearch.unsignedCriticalWaiver.tooltip' translateLabel />
                                                <FormikCheckedButton id='invalidCrc' name='invalidCrc' label='members.search.memberSearch.crc.tooltip' translateLabel />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <CrossFade isVisible={!!teamsContext.filters && Object.keys(teamsContext.filters)?.length !== 0}>
                                        <div className="d-flex">
                                            <button className="reset-btn text-danger ml-auto" type='button' onClick={() => teamsContext.getRosterTablesData(teamId, null, true)}>
                                                <span className="small"><Translate id='members.search.memberAdvanceSearch.resetFilters' /></span>
                                            </button>
                                        </div>
                                    </CrossFade>
                                </CrossFade>
                            </CardBody>
                        </Form>
                    </OverlayLoader>
                )}
            </Formik>
        </Card>
    )
}

export default TeamRosterFilters