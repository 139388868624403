export default{
    "components.fileViewer.loading"                     : "Loading PDF...",
    "components.fileViewer.error"                       : "An error occurred when loading the PDF",
    "components.fileViewer.download"                    : "Download",
    "components.fileViewer.noData"                      : "No pdf",
    "components.fileViewer.noPreview.title"             : "No preview",
    "components.fileViewer.noPreview.text"              : "We could not load the preview.",
    "components.fileViewer.noPreview.downloadAvailable" : "You may try to download the file:",

    "components.fileViewer.noPDF.text"  : "The PDF file request wasn't found.<br></br> The preview file and download link couldn't be generated.",
    "components.fileViewer.noPDF.close" : "Close preview",

    "components.fileViewer.zoom.in"  : "Zoom In",
    "components.fileViewer.zoom.out" : "Zoom Out",

    "components.fileViewer.accessibility.loading.label" : "Loading file {fileName}...",
}