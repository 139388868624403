import header from "./header";
import misc from "./misc";
import sidebar from "./sidebar";
import warnings from "./warnings";
import form from "./form";
import reports from "./reports";
import tour from "./tour"
import newNav from "./newNav";

export default{
    ...header,
    ...misc,
    ...sidebar,
    ...warnings,
    ...form,
    ...newNav,
    ...reports,
    ...tour,
}