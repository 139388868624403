import { FormikCheckedButton, FormikDateTime, FormikInputText, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate, { DateFormat } from '@spordle/intl-elements';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import {
    Col,
    FormGroup,
    Label, Row
} from "reactstrap";
import { array, bool, mixed, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../../../api/CancellableAPI';
import FormikEditable from '../../../../../../../../components/formik/FormikEditable';
import FormikEditableFile from '../../../../../../../../components/formik/FormikEditableFile';
import { fail, success } from '@spordle/toasts';
import { I18nContext } from '../../../../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../../../../contexts/MembersContext';
import { TransferContext } from '../../../../../../../../contexts/TransferContext';
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';

/* CTRL + F

    TRANSFER TYPES
    EXTENSION
    IIHF
    DURATION
    STATUS
    SENT DATE
    TRYOUT
    APPROVEDATE
    MOVE PARENT
    NOTES
    ATTACHMENTS

*/

const MemberProfileTransferCTISidepanelOverview = ({ international, ...props }) => {

    const transferContext = useContext(TransferContext);
    const membersContext = useContext(MembersContext);
    const { getGenericLocale } = useContext(I18nContext);

    // eslint-disable-next-line unused-imports/no-unused-vars
    const [ transferTypes, setTransferTypes ] = useState([])
    const [ transferDurations, setTransferDurations ] = useState([])
    const [ transferStatus, setTransferStatus ] = useState([])
    const [ transferExtensions, setTransferExtensions ] = useState([])
    const [ loading, setLoading ] = useState(false)

    const getTransferTypes = () => {
        return transferContext.getUSATransferTypes()
            .then(setTransferTypes)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                    setTransferTypes([]);
                }
            })
    }

    const getTransferDurations = () => {
        return transferContext.getTransferDurations({ active: 1 })
            .then((durations) => setTransferDurations(durations.filter((duration) => duration.scope === 'USA/INTERNATIONAL')))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    setTransferDurations([]);
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    const getTransferStatus = () => {
        return transferContext.getTransferStatus({ active: 1, is_international: international ? 1 : null }, international ? false : undefined, international ? true : undefined)
            .then(setTransferStatus)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setTransferStatus([]);
                }
            })
    }

    const getTransferExtensions = () => {
        return transferContext.getTransferExtensions()
            .then(setTransferExtensions)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    setTransferExtensions([]);
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    const handleCatch = (error) => {
        if(!AxiosIsCancelled(error.message)){
            console.error(error.message)
            fail({
                msg: 'misc.error',
                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                skipInfoTranslate: true,
            })
            props.setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true)
        Promise.all([
            getTransferTypes(),
            getTransferDurations(),
            getTransferStatus(),
            getTransferExtensions(),
        ])
            .finally(() => { // don't really care if the calls failed or succeded, they manage their own promises
                setLoading(false)
            })
    }, [])

    const transferAttachmentsOnSubmit = async(files) => {
        let newVal = { attachments: [] };
        const toDelete = [];
        const toKeep = [];
        const toUpdate = files.reduce((newArr, f) => {
            if(!f.id){
                newArr.push({
                    attachment: f,
                    active: 1,
                })
            }
            return newArr;
        }, []);

        props.selectedRows[0].attachments?.forEach((a) => {
            if(!files?.some((f) => f.id == a.attachment.attachement_id)){
                toDelete.push(a)
            }else{
                toKeep.push(a)
            }
        })

        if(toDelete?.length > 0){
            await Promise.all(
                toDelete.map((att) => (
                    membersContext.deleteMemberTransferAttachment(membersContext.currentMember.member_id, props.getCommonValue('member_transfer_id'), att.transfer_attachment_id)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                )),
            )
            props.syncRows(props.createNewValues({ attachments: toKeep })); // necessary in case of error later
        }

        if(toUpdate?.length > 0){
            await membersContext.createMemberTransferAttachment(
                membersContext.currentMember.member_id,
                props.getCommonValue('member_transfer_id'),
                toUpdate,
            ).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });

            newVal = {
                attachments: await membersContext.getMemberTransferAttachments(membersContext.currentMember.member_id, props.getCommonValue('member_transfer_id'))
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    }),
            };

            props.syncRows(props.createNewValues(newVal));
        }
    }

    return (
        <div className="p-3">
            <Row>
                {/* EXTENSION */}
                <Col sm="6">
                    <FormGroup>
                        <Label for='ext' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.extension.label' /></Label>
                        <FormikEditable
                            id='extension'
                            noConfirmation
                            initialValues={{
                                extension: props.selectedRows[0].transfer_extension?.transfer_extension_id || '',
                            }}
                            validationSchema={object().shape({
                                extension: string().required(<Translate id='members.profile.transfers.add.fields.extension.validation.required' />),
                            })}
                            onSubmit={(values) => {
                                if(values.extension !== props.selectedRows[0].transfer_extension?.transfer_extension_id){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ transfer_extension: transferExtensions.find((extension) => extension.transfer_extension_id === values.extension) });
                                    membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                        transfer_extension_id: values.extension,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                            props.setLoading(false)
                                        })
                                        .catch(handleCatch)
                                }
                            }}
                        >
                            {(isEditing, options) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={props.selectedRows[0].transfer_extension?.name}
                                                i18n={props.selectedRows[0].transfer_extension?.i18n}
                                            />
                                        </div>
                                    )
                                }
                                return (
                                    <FormikSelect
                                        name='extension'
                                        id='extension'
                                        isLoading={loading}
                                        renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                        onOptionSelected={() => options.stopEditing()}
                                        menuIsDefaultOpen
                                        autoFocus
                                        searchKeys={[
                                                    `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        options={transferExtensions ?
                                            transferExtensions
                                                .sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))
                                                .reduce((keptExt, ext) => {
                                                    if(ext.active == 1){
                                                        keptExt.push({
                                                            label: ext.name,
                                                            i18n: ext.i18n,
                                                            value: ext.transfer_extension_id,
                                                        })
                                                    }
                                                    return keptExt;
                                                }, [])
                                            :
                                            []
                                        }
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>

                {/* IIHF */}
                <Col sm="6">
                    <FormGroup>
                        <Label for='iifhCardNumber' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.iihfCard.label' /></Label>
                        <FormikEditable
                            id='iifhCardNumber'
                            initialValues={{
                                iifhCardNumber: props.selectedRows[0].iihf_card_number,
                            }}
                            validationSchema={object().shape({
                                iifhCardNumber: string().required(<Translate id='members.profile.transfers.add.fields.iihfCard.validation.required' />),
                            })}
                            onSubmit={(values) => {
                                if(values.iifhCardNumber !== props.selectedRows[0].iihf_card_number){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ iihf_card_number: values.iifhCardNumber });
                                    membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                        iihf_card_number: values.iifhCardNumber,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                            props.setLoading(false);
                                        })
                                        .catch(handleCatch);
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark text-truncate'>{props.selectedRows[0].iihf_card_number}</div>
                                    )
                                }
                                return (
                                    <FormikInputText id='iifhCardNumber' name='iifhCardNumber' trim />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                {/* DURATION */}
                {props.selectedRows[0].transfer_duration &&
                <Col sm="6">
                    <FormGroup>
                        <Label for='duration' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.duration.label' /></Label>
                        <FormikEditable
                            id='duration'
                            noConfirmation
                            initialValues={{
                                duration: props.selectedRows[0].transfer_duration.transfer_duration_id,
                            }}
                            validationSchema={object().shape({
                                duration: string().required(<Translate id='members.profile.transfers.add.fields.duration.validation.required' />),
                            })}
                            onSubmit={(values) => {
                                if(values.duration !== props.selectedRows[0].transfer_duration.transfer_duration_id){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ transfer_duration: transferDurations.find((duration) => duration.transfer_duration_id === values.duration) });
                                    membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                        transfer_duration_id: values.duration,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                            props.setLoading(false);
                                        })
                                        .catch(handleCatch)
                                }
                            }}
                        >
                            {(isEditing, options) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={props.selectedRows[0].transfer_duration.name}
                                                i18n={props.selectedRows[0].transfer_duration.i18n}
                                            />
                                        </div>
                                    )
                                }
                                return (
                                    <FormikSelect
                                        name='duration'
                                        id='duration'
                                        isLoading={loading}
                                        renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                        onOptionSelected={() => options.stopEditing()}
                                        menuIsDefaultOpen autoFocus
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        options={transferDurations ?
                                            transferDurations
                                                .sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))
                                                .reduce((keptDur, dur) => {
                                                    if(dur.active == 1){
                                                        keptDur.push({
                                                            label: dur.name,
                                                            i18n: dur.i18n,
                                                            value: dur.transfer_duration_id,
                                                        })
                                                    }
                                                    return keptDur;
                                                }, [])
                                            :
                                            []
                                        }
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                }

                {/* STATUS */}
                {props.selectedRows[0].transfer_status &&
                <Col sm="6">
                    <FormGroup>
                        <Label for='status' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.status.label' /></Label>
                        <FormikEditable
                            id='status'
                            noConfirmation
                            initialValues={{
                                status: props.selectedRows[0].transfer_status.transfer_status_id,
                            }}
                            validationSchema={object().shape({
                                status: string().required(<Translate id='members.profile.transfers.add.fields.status.validation.required' />),
                            })}
                            onSubmit={(values) => {
                                if(values.status !== props.selectedRows[0].transfer_status.transfer_status_id){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ transfer_status: transferStatus.find((status) => status.transfer_status_id === values.status) });
                                    membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                        transfer_status_id: values.status,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                            props.setLoading(false);
                                        })
                                        .catch(handleCatch);
                                }
                            }}
                        >
                            {(isEditing, options) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={props.selectedRows[0].transfer_status.i18n}
                                                i18n={props.selectedRows[0].transfer_status.i18n}
                                            />
                                        </div>
                                    )
                                }
                                return (
                                    <FormikSelect
                                        name='status' id='status'
                                        isLoading={loading}
                                        renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                        onOptionSelected={() => options.stopEditing()}
                                        menuIsDefaultOpen autoFocus
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        options={transferStatus ?
                                            transferStatus
                                                .sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))
                                                .reduce((keptStatus, status) => {
                                                    if(status.active == 1){
                                                        keptStatus.push({
                                                            label: status.name,
                                                            i18n: status.i18n,
                                                            value: status.transfer_status_id,
                                                        })
                                                    }
                                                    return keptStatus;
                                                }, [])
                                            : []
                                        }
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                }

                {/* SENT DATE */}
                <Col sm="6">
                    <FormGroup>
                        <Label for='sentDate' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.sentDate.label' /></Label>
                        <FormikEditable
                            id='sentDate'
                            initialValues={{
                                sentDate: moment(props.selectedRows[0].sent_date, 'YYYY-MM-DD'),
                            }}
                            validationSchema={object().shape({
                                sentDate: mixed().required(<Translate id='members.profile.transfers.add.fields.sentDate.validation.required' />)
                                    .test({
                                        name: 'sentDateFormat',
                                        message: <Translate id='form.validation.date.format' />,
                                        test: moment.isMoment,
                                    }),
                            })}
                            onSubmit={(values) => {
                                const newDate = values.sentDate.format('YYYY-MM-DD');
                                if(newDate !== props.selectedRows[0].sent_date){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ sent_date: newDate });
                                    membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                        sent_date: newDate,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                            props.setLoading(false);
                                        })
                                        .catch(handleCatch);
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'><DateFormat value={props.selectedRows[0].sent_date} utc /></div>
                                    )
                                }
                                return (
                                    <FormikDateTime
                                        name='sentDate' id='sentDate'
                                        timeFormat={false}
                                        dateFormat='YYYY-MM-DD'
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>

                {/* TRYOUT */}
                <Col sm="6">
                    <FormGroup>
                        <Label for='tryoutDate' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.tryOut.label' /></Label>
                        <FormikEditable
                            id='tryoutDate'
                            initialValues={{
                                tryoutDate: props.selectedRows[0].tryout_date ? moment(props.selectedRows[0].tryout_date, 'YYYY-MM-DD') : '',
                            }}
                            validationSchema={object().shape({
                                tryoutDate: mixed()
                                    .test({
                                        name: 'tryoutDateFormat',
                                        message: <Translate id='form.validation.date.format' />,
                                        test: (date) => {
                                            if(date){
                                                return moment.isMoment(date)
                                            }
                                            return true // not required
                                        },
                                    }),
                            })}
                            onSubmit={(values) => {
                                const newDate = values.tryoutDate ? values.tryoutDate.format('YYYY-MM-DD') : null;
                                if(newDate || newDate !== props.selectedRows[0].tryout_date){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ tryout_date: newDate });
                                    membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                        tryout_date: newDate,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                            props.setLoading(false);
                                        })
                                        .catch(handleCatch);
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{props.selectedRows[0].tryout_date ? <DateFormat value={props.selectedRows[0].tryout_date} utc /> : '-'}</div>
                                    )
                                }
                                return (
                                    <FormikDateTime
                                        className='date-picker-left'
                                        name='tryoutDate' id='tryoutDate'
                                        timeFormat={false}
                                        dateFormat='YYYY-MM-DD'
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>

                {/* APPROVEDATE */}
                <Col sm="6">
                    <FormGroup>
                        <Label for='approvedDate' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.approveDate.label' /></Label>
                        <FormikEditable
                            id='approvedDate'
                            initialValues={{
                                approvedDate: props.selectedRows[0].approved_date ? moment(props.selectedRows[0].approved_date, 'YYYY-MM-DD') : '',
                            }}
                            validationSchema={object().shape({
                                approvedDate: mixed()
                                    .test({
                                        name: 'approvedDateFormat',
                                        message: <Translate id='form.validation.date.format' />,
                                        test: (date) => {
                                            if(date){
                                                return moment.isMoment(date)
                                            }
                                            return true // not required
                                        },
                                    }),
                            })}
                            onSubmit={(values) => {
                                const newDate = values.approvedDate ? values.approvedDate.format('YYYY-MM-DD') : null;
                                if(newDate || newDate !== props.selectedRows[0].approved_date){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ approved_date: newDate });
                                    membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                        approved_date: newDate,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                            props.setLoading(false);
                                        })
                                        .catch(handleCatch)
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{props.selectedRows[0].approved_date ? <DateFormat value={props.selectedRows[0].approved_date} utc /> : '-'}</div>
                                    )
                                }
                                return (
                                    <FormikDateTime
                                        name='approvedDate' id='approvedDate'
                                        timeFormat={false}
                                        dateFormat='YYYY-MM-DD'
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup className="mt-2">
                {/* MOVE PARENT */}
                <FormikEditable
                    id='moveWithParent'
                    initialValues={{
                        moveWithParent: props.selectedRows[0].transfer_with_parents === 'YES',
                    }}
                    validationSchema={object().shape({
                        moveWithParent: bool(),
                    })}
                    onSubmit={(values) => {
                        const newVar = values.moveWithParent ? 'YES' : 'NO';
                        if(newVar !== props.selectedRows[0].transfer_with_parents){
                            props.setLoading(true)
                            const newValues = props.createNewValues({ transfer_with_parents: newVar });
                            membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                transfer_with_parents: newVar,
                            })
                                .then(() => {
                                    props.syncRows(newValues);
                                    success();
                                    props.setLoading(false);
                                })
                                .catch(handleCatch)
                        }
                    }}
                >
                    {(isEditing) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'>
                                    {props.selectedRows[0].transfer_with_parents === 'YES' ?
                                        <i className="mdi mdi-check text-success" />
                                        :
                                        <i className="mdi mdi-close text-danger" />
                                    }
                                    <span className="ml-2"><Translate id='members.profile.transfers.add.fields.withParent.label' /></span>
                                </div>
                            )
                        }
                        return (
                            <FormikCheckedButton id='moveWithParent' name='moveWithParent' label='members.profile.transfers.add.fields.withParent.label' translateLabel />
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <FormGroup>
                {/* NOTES */}
                <Label for='notes' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.notes.label' /></Label>
                <FormikEditable
                    id='notes'
                    initialValues={{
                        notes: props.selectedRows[0].notes || '',
                    }}
                    validationSchema={object().shape({
                        notes: string(),
                    })}
                    onSubmit={(values) => {
                        const newNotes = values.notes || null
                        if(newNotes !== props.selectedRows[0].notes){
                            props.setLoading(true)
                            const newValues = props.createNewValues({ notes: newNotes });
                            membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                notes: newNotes,
                            })
                                .then(() => {
                                    props.syncRows(newValues);
                                    success();
                                    props.setLoading(false);
                                })
                                .catch(handleCatch)
                        }
                    }}
                >
                    {(isEditing) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark text-truncate'>{props.selectedRows[0].notes || '-'}</div>
                            )
                        }
                        return (
                            <FormikTextArea id='notes' name='notes' rows='5' trim />
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <FormGroup>
                {/* ATTACHMENTS */}
                <div className="mb-2 text-muted"><Translate id='members.profile.transfers.sidepanel.overview.attachments' /></div>
                <FormikEditableFile
                    id='memberTransferAttachments'
                    // fileIdField='attachment.attachement_id'
                    dropzoneProps={{
                        multiple: true,
                        accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx",
                    }}
                    name="files"
                    initialValues={{
                        files: props.selectedRows[0].attachments,
                    }}
                    validationSchema={object().shape({
                        files: array().of(mixed()),
                    })}
                    onFileClick={(file) => {
                        return membersContext.downloadMemberTransferAttachment(
                            membersContext.currentMember.member_id,
                            props.getCommonValue('member_transfer_id'),
                            props.selectedRows[0].attachments?.find((att) => att.attachment.attachement_id == file.id)?.transfer_attachment_id,
                        ).catch(handleCatch)
                    }}
                    onSubmit={(values) => {
                        // if(values.file !== props.selectedRows[0].attachments?.[0]?.attachment?.file_name) {
                        props.setLoading(true)
                        transferAttachmentsOnSubmit(values.files || [])
                            .then(() => {
                                success();
                                props.setLoading(false);
                            })
                            .catch(handleCatch)
                    }}
                    showCreatedAt
                />
            </FormGroup>
        </div>
    );
}

export default MemberProfileTransferCTISidepanelOverview;