export default{
    "clinics.search.sidePanelClinicSearch.more" : "{more} de plus",

    // options
    "clinics.search.sidePanelClinicSearch.options.duplicate"          : "Dupliquer",
    "clinics.search.sidePanelClinicSearch.duplicate.title"            : "Dupliquer",
    "clinics.search.sidePanelClinicSearch.duplicate.text1"            : "Voulez-vous dupliquer ce stage?",
    "clinics.search.sidePanelClinicSearch.duplicate.text2"            : "Nous vous apporterons à l'étape finale de la création de stage pour que vous puissiez faire des changements finaux à votre stage dupliqué.",
    "clinics.search.sidePanelClinicSearch.duplicate.confirm"          : "Dupliquer",
    "clinics.search.sidePanelClinicSearch.delete.message"             : "Vous êtes sur le point de supprimer",
    "clinics.search.sidePanelClinicSearch.delete.cantDeleteAttendees" : "Vous ne pouvez pas supprimer une clinique avec des participants enregistrés.",

    // details tab
    "clinics.search.sidePanelClinicSearch.details.title"      : "Détails",
    "clinics.search.sidePanelClinicSearch.details.registered" : "Inscrits",
    "clinics.search.sidePanelClinicSearch.details.waitlisted" : "En attente",

    "clinics.search.sidePanelClinicSearch.details.category"      : "Catégorie",
    "clinics.search.sidePanelClinicSearch.details.qualification" : "Qualification",
    "clinics.search.sidePanelClinicSearch.details.clinicStatus"  : "Statut de stage",
    "clinics.search.sidePanelClinicSearch.details.location"      : "Emplacement",
    "clinics.search.sidePanelClinicSearch.details.revenus"       : "Revenus",
    "clinics.search.sidePanelClinicSearch.details.cost"          : "Coût",
    "clinics.search.sidePanelClinicSearch.details.host"          : "Contact",
    "clinics.search.sidePanelClinicSearch.details.instructor"    : "Instructeur",

    "clinics.search.sidePanelClinicSearch.details.sessions"                     : "Séances",
    "clinics.search.sidePanelClinicSearch.details.sessions.none"                : "Aucune séance",
    "clinics.search.sidePanelClinicSearch.details.session"                      : "Séance",
    "clinics.search.sidePanelClinicSearch.details.ONSITE"                       : "En personne",
    "clinics.search.sidePanelClinicSearch.details.ONLINE_LIVE"                  : "En ligne - En direct",
    "clinics.search.sidePanelClinicSearch.details.ONLINE"                       : "En ligne - Sur demande",
    "clinics.search.sidePanelClinicSearch.details.UNDEFINED"                    : "Non défini",
    "clinics.search.sidePanelClinicSearch.details.status.OPEN_PUBLIC_SHOW_LIST" : "Ouvert au public",
    "clinics.search.sidePanelClinicSearch.details.status.OPEN_PUBLIC_SIGNUP"    : "Inscription ouverte",
    "clinics.search.sidePanelClinicSearch.details.status.PRIVATE"               : "Privée",
    "clinics.search.sidePanelClinicSearch.details.status.DRAFT"                 : "Brouillon",
    "clinics.search.sidePanelClinicSearch.details.status.UNDER_CREATION"        : "En création",

    // attendees tab
    "clinics.search.sidePanelClinicSearch.attendees.viewInClinic"          : "Voir dans le stage",
    "clinics.search.sidePanelClinicSearch.attendees.noAttendees"           : "Aucun participant",
    "clinics.search.sidePanelClinicSearch.attendees.noAttendeesFound"      : "Aucun participant n'a été trouvé avec les critères de recherche",
    "clinics.search.sidePanelClinicSearch.attendees.noAttendeesRegistered" : "Aucun participant n'est inscrit à ce stage",
    "clinics.search.sidePanelClinicSearch.waitlist.noAttendees"            : "Aucun membre dans la file d'attente",
    "clinics.search.sidePanelClinicSearch.waitlist.noAttendeesFound"       : "Aucun membre dans liste d'attente n'a été trouvé avec les critères de recherche",
    "clinics.search.sidePanelClinicSearch.waitlist.noAttendeesRegistered"  : "Aucun membre n'est dans la liste d'attente pour cette clinique",
    "clinics.search.sidePanelClinicSearch.attendees.viewList"              : "Voir liste dans stage",
    "clinics.search.sidePanelClinicSearch.attendees.title"                 : "Participants",
    "clinics.search.sidePanelClinicSearch.attendees.attendees"             : "Participants",
    "clinics.search.sidePanelClinicSearch.attendees.waitlisted"            : "En attente",
    "clinics.search.sidePanelClinicSearch.attendees.single.havePaid"       : "1 a payé",
    "clinics.search.sidePanelClinicSearch.attendees.multiple.havePaid"     : "{amount} ont payé",
    "clinics.search.sidePanelClinicSearch.attendees.guest"                 : "de votre organisation",
    "clinics.search.sidePanelClinicSearch.attendees.paid"                  : "Payé",
    "clinics.search.sidePanelClinicSearch.attendees.position"              : "Pos",

    // session collapse
    "clinics.search.sidePanelClinicSearch.sessions.start"    : "Début:",
    "clinics.search.sidePanelClinicSearch.sessions.end"      : "Fin:",
    "clinics.search.sidePanelClinicSearch.sessions.duration" : "Duration:",
    "clinics.search.sidePanelClinicSearch.sessions.url"      : "Lien de la séance",
    "clinics.search.sidePanelClinicSearch.sessions.maps"     : "Voir sur la carte",
}