import { FormikCheckedButton, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect } from '@spordle/formik-elements';
import Translate from "@spordle/intl-elements";
// Formik
import { FieldArray, Form, Formik } from "formik";
import { useContext, useState } from 'react';
import { Button, Card, Col, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { array, bool, object, string } from 'yup';
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import CustomAlert from '../../../../../../components/CustomAlert';
import Required from "../../../../../../components/formik/Required";
import { success } from '@spordle/toasts';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import CrossFade from '../../../../../../components/crossFade/CrossFade';
import ManualRegistrationMemberIdentities from '../../../../../../components/manualRegistration/ManualRegistrationMemberIdentities';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';

const AddContactModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='AddContactModal'>
            <AddContactInner {...props} />
        </AnalyticsModal>
    )
}

const AddContactInner = ({ toggle, contactAlreadyExists, refresh, onAdd, contactTypes, phoneTypes }) => {
    const isRequired = (value, emergency) => (emergency || !value);
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);

    const [ activeStep, setActiveStep ] = useState('contactForm');
    const [ formData, setFormData ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const createContact = (values, setStatus) => {
        const newValues = {
            ...values,
            contactType: {
                i18n: values.contactType?.i18n,
                name: values.contactType?.label,
                memberContactTypeId: values.contactTypeId,
            },
            // check if phones[0] is set, if it is, send phone array, if it is not, send empty array
            phones: values.phones[0].phoneNumber ? values.phones.map((phone) => {
                if(values.phones[0].phoneNumber){
                    return {
                        phoneNumber: phone.phoneExtension ? `${phone.phoneNumber}#${phone.phoneExtension}` : phone.phoneNumber,
                        phoneType: {
                            name: phone.phoneType.label,
                            i18n: phone.phoneType.i18n,
                            phoneTypeId: phone.phoneTypeId,
                        },
                    }
                }
            }) : [],
        }

        if(contactAlreadyExists(newValues)){
            setStatus(<Translate id='members.profile.overview.memberContacts.addContact.alreadyExists' />)
        }else{
            setIsLoading(true);
            return membersContext.createMemberContacts(membersContext.currentMember.member_id, [ newValues ], values.identityId)
                .then(() => {
                    refresh();
                    toggle();
                    setIsLoading(false);
                    onAdd?.();
                    success({ msg: 'members.profile.overview.memberContacts.addContact.success' });
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        setIsLoading(false);
                        if(error.i18n){
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                        }else{
                            setStatus(<Translate id={'members.profile.overview.memberContacts.addContact.' + error.message} defaultMessageId='misc.error' />)
                        }
                    }
                });
        }
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader toggle={toggle}>
                <Translate id='members.profile.overview.memberContacts.addContact.title' />
            </ModalHeader>

            <CrossFade isVisible={activeStep === 'contactForm'}>
                <Formik
                    initialValues={{
                        firstName: formData?.firstName || '',
                        lastName: formData?.lastName || '',
                        active: true,
                        emergency: formData?.emergency || false,
                        tax_receipt_recipient: formData?.tax_receipt_recipient || false,
                        allow_communication: formData?.allow_communication || false,
                        contactTypeId: formData?.contactTypeId || '',
                        contactType: formData?.contactType || null,
                        phones: formData?.phones || [ {
                            phoneNumber: '',
                            phoneExtension: '',
                            phoneTypeId: '',
                            phoneType: null,
                        } ],
                        email: formData?.email || '',
                    }}
                    validationSchema={object().shape({
                        firstName: string().required(<Translate id='form.validation.firstName.required' />),
                        lastName: string().required(<Translate id='form.validation.lastName.required' />),
                        active: bool().required(),
                        emergency: bool().required(),
                        tax_receipt_recipient: bool().required(),
                        allow_communication: bool().required(),
                        contactTypeId: string().required(<Translate id='members.profile.overview.memberContacts.contactTypes.required' />),
                        phones: array().of(object().shape({
                            phoneNumber: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />).test({
                                name: 'email-phone-required',
                                message: <Translate id='form.validation.phone.required' />,
                                test: function(value){
                                    if(this.path === 'phones[0].phoneNumber'){ // index 0
                                        // If is an emergency contact, email and phone is mandatory
                                        // If the contact isn't, the contact need to have either an email OR a phone number
                                        const base = this.from[this.from.length - 1]?.value;
                                        return base?.emergency ? !!value : (!!base?.email || !!value)
                                    }
                                    return !!value

                                },
                            }),
                            phoneExtension: string(),
                            phoneTypeId: string().test({
                                name: 'email-phone-id-required',
                                message: <Translate id='form.validation.phoneType.required' />,
                                test: function(value){
                                    if(this.parent.phoneNumber)return !!value;

                                    if(this.path === 'phones[0].phoneTypeId'){ // index 0
                                        const base = this.from[this.from.length - 1]?.value;
                                        // Phone is required if emergency
                                        // Else check if there is an email, if there isn't, check if this has a value
                                        return base?.emergency ? !!value : (!!base?.email || !!value);
                                    }
                                    return !!value

                                },
                            }),
                        })),
                        email: string().email(<Translate id='form.validation.email.valid' />)
                            .test({
                                name: 'isRequired',
                                message: <Translate id='form.validation.email.required' />,
                                test: function(email){
                                    return !(!this.parent.phones[0].phoneNumber || this.parent.emergency || this.parent.allow_communication) || !!email
                                },
                            }),
                    })}
                    onSubmit={(values, { setSubmitting, setStatus }) => {
                        setFormData(values);
                        setActiveStep('identity');
                        setSubmitting(false);
                    }}
                >
                    {(formik) => (
                        <Form className='position-relative'>
                            <ModalBody>
                                <FormGroup>
                                    <Label for='contactTypeId' className="text-muted">
                                        <Translate id='members.profile.overview.memberContacts.contactTypes' /> <Required />
                                    </Label>
                                    <FormikSelect
                                        id='contactTypeId'
                                        name='contactTypeId'
                                        onOptionSelected={([ value ], table) => {
                                            const contactType = table.getSpordleTable().getData().find((type) => type.value === value);

                                            // because we also want the object to be sent to the table
                                            formik.setFieldValue('contactType', contactType);
                                        }}
                                        renderOption={({ option }) => (
                                            <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                        )}
                                        searchKeys={[
                                            `i18n.${i18nContext.getGenericLocale()}.name`,
                                        ]}
                                        options={contactTypes ?
                                            contactTypes.map((type) => ({
                                                ...type,
                                                value: type.member_contact_type_id,
                                                label: type.name,
                                            }))
                                            :
                                            []
                                        }
                                    />
                                </FormGroup>

                                <Row className="mb-3">
                                    <Col md='6'>
                                        <Label for='firstName' className="text-muted"><Translate id='form.fields.firstName' /> <Required /></Label>
                                        <FormikInputText id='firstName' name='firstName' trim />
                                    </Col>
                                    <Col md='6'>
                                        <Label for='lastName' className="text-muted"><Translate id='form.fields.lastName' /> <Required /></Label>
                                        <FormikInputText id='lastName' name='lastName' trim />
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for='email' className="text-muted"><Translate id='form.fields.email' /> {(!formik.values.phones[0].phoneNumber || formik.values.emergency || formik.values.allow_communication) && <Required />}</Label>
                                    <FormikInputText id='email' name='email' trim />
                                </FormGroup>
                                <Collapse isOpen>
                                    <div className="pb-1">
                                        <FieldArray name='phones'>
                                            {(arrayHelpers) => (
                                                <div className='mb-3'>
                                                    {formik.values.phones.map((phone, index) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <Collapse appear isOpen key={index}>
                                                            <Card body className='card-shadow mb-3'>
                                                                {index > 0 &&
                                                                    <div className="position-absolute top-0 right-0 z-index-1">
                                                                        <button type="button" onClick={() => arrayHelpers.remove(index)} className="btn btn-link text-danger fas fa-trash" />
                                                                    </div>
                                                                }
                                                                <FormGroup>
                                                                    <Label for={`phones.${index}.phoneNumber`} className='text-muted'><Translate id='form.fields.phone' /> {((isRequired(formik.values.email, formik.values.emergency) && index === 0) || index !== 0) && <Required />}</Label>
                                                                    <FormikPhoneInput name={`phones.${index}.phoneNumber`} />
                                                                </FormGroup>
                                                                <Row>
                                                                    <Col md='6'>
                                                                        <Label for={`phones.${index}.phoneExtension`} className='text-muted'><Translate id='form.fields.extension' /></Label>
                                                                        <FormikInputNumber id={`phones.${index}.phoneExtension`} name={`phones.${index}.phoneExtension`} allowNegative={false} decimalSeparator={false} />
                                                                    </Col>
                                                                    <Col md='6'>
                                                                        <Label for={`phones.${index}.phoneTypeId`} className='text-muted'><Translate id='form.fields.phoneType' /> {((isRequired(formik.values.email, formik.values.emergency) && index === 0) || index !== 0) && <Required />}</Label>
                                                                        <FormikSelect
                                                                            id={`phones.${index}.phoneTypeId`}
                                                                            name={`phones.${index}.phoneTypeId`}
                                                                            onOptionSelected={([ value ], table) => {
                                                                                const phoneType = table.getSpordleTable().getData().find((type) => type.value === value);
                                                                                // because we also want the object to be sent to the table
                                                                                formik.setFieldValue(`phones.${index}.phoneType`, phoneType);
                                                                            }}
                                                                            renderOption={({ option }) => (
                                                                                <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                                            )}
                                                                            searchKeys={[
                                                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                                                            ]}
                                                                            options={phoneTypes ?
                                                                                phoneTypes.map((type) => ({
                                                                                    ...type,
                                                                                    label: type.name,
                                                                                    value: type.phone_type_id,
                                                                                }))
                                                                                :
                                                                                []
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </Collapse>
                                                    ))}
                                                    <button className='reset-btn text-primary' type='button' onClick={() => arrayHelpers.push({ phoneNumber: '', phoneExtension: '', phoneTypeId: '', phoneType: null })}><i className='mdi mdi-plus' /> <Translate id='members.profile.overview.memberContacts.addPhone' /></button>
                                                </div>
                                            )}
                                        </FieldArray>
                                    </div>
                                </Collapse>

                                <FormGroup>
                                    <FormikCheckedButton id='allow_communication' name='allow_communication' label='members.profile.overview.memberContacts.allow_communication' translateLabel checked={formik.values.allow_communication} />
                                </FormGroup>

                                <FormGroup>
                                    <FormikCheckedButton id='emergency' name='emergency' label='members.profile.overview.memberContacts.emergencyContact' translateLabel checked={formik.values.emergency} />
                                </FormGroup>

                                <FormGroup>
                                    <FormikCheckedButton id='tax_receipt_recipient' name='tax_receipt_recipient' label='members.profile.overview.memberContacts.taxReceiptRecipient' translateLabel checked={formik.values.tax_receipt_recipient} />
                                </FormGroup>


                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    {formik.status &&
                                        <CustomAlert color='danger' text={formik.status} translateText={false} withTitle toggle={() => formik.setStatus(null)} />
                                    }
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.next' /></Button>
                                <Button className="ml-2" color='primary' type='button' outline onClick={toggle} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </CrossFade>
            <CrossFade isVisible={activeStep === 'identity'}>
                <ManualRegistrationMemberIdentities
                    from='instructor' // to show cancel button
                    memberIdentities={membersContext.currentMember.linked_identities || []}
                    member={membersContext.currentMember}
                    showSkip
                    toggle={toggle}
                    submitLabel="misc.create"
                    goToPrevious={() => setActiveStep('contactForm')}
                    onSubmit={(identityId, setStatus) => {
                        return createContact({ ...formData, identityId: identityId }, setStatus)
                    }}
                />
            </CrossFade>
        </OverlayLoader>
    )
}

export default AddContactModal;