export default{
    "clinics.createClinic.title" : "Clinic Creation",

    // wizard titles
    "clinics.createClinic.steps.clinicInfo"     : "Clinic information",
    "clinics.createClinic.steps.sessionInfo"    : "Session(s) information",
    "clinics.createClinic.steps.communications" : "Communications",
    "clinics.createClinic.steps.paymentOptions" : "Payment option(s)",
    "clinics.createClinic.steps.review"         : "Review",

    // under creation
    "clinics.createClinic.underCreation.topText1" : "You have a clinic under creation!",
    "clinics.createClinic.underCreation.topText2" : "Do you want to continue this clinic's creation where you left off or start a new one?",
    "clinics.createClinic.underCreation.continue" : "Continue clinic",
    "clinics.createClinic.underCreation.new"      : "Create a new clinic",

    // duplicate
    "clinics.createClinic.duplication.simple.title"        : "Duplicate clinic",
    "clinics.createClinic.duplication.simple.desc"         : "Click here to duplicate the selected clinic. You will duplicate the clinic profile.",
    "clinics.createClinic.duplication.withAttendees.title" : "Duplicate clinic with attendees",
    "clinics.createClinic.duplication.withAttendees.desc"  : "Click here to duplicate the selected clinic with it's current attendees. The attendees will copied over as new attendees.",
    "clinics.createClinic.duplication.topText1"            : "You are about to duplicate this clinic!",
    "clinics.createClinic.duplication.topText2"            : "You will need to complete the first step to initiate the duplication and unlock the next steps.",
    "clinics.createClinic.duplication.continue"            : "Continue",
}