import React from "react";
import {
    Button,
    Card,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Fade,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledButtonDropdown
} from "reactstrap";
import * as Yup from 'yup';
import Translate from "@spordle/intl-elements";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import I18nHelperContextProvider, { RenderI18nForm, I18nHelperContext, DisplayI18n } from '../../../../helpers/i18nHelper';
import { Form, Formik } from "formik";
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";

// Clinic Colors
import ClinicCategory from "../../../clinics/ClinicCategory";
import ClinicColorPicker from "../../../clinics/ClinicColorPicker";

// Sidepanel
import SpordlePanelTable from "../../../../components/sidePanel/SpordlePanel";
import SidePanelClinicCategories from "./SidePanelClinicCategories";

// Contexts
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { QualificationCategoriesContext } from '../../../../contexts/QualificationCategoriesContext';
import withContexts from "../../../../helpers/withContexts";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import EmptyLayout from "../../../../components/table/EmptyLayout";
import CardSectionTitle from "../../../../components/CardSectionTitle";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { I18nContext } from "../../../../contexts/I18nContext";
import Required from "../../../../components/formik/Required";

class ClinicSettingsCategories extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            addModal: false,
            modalLoading: 'success',
        }
    }

    toggleAddModal = () => {
        this.setState((prev) => ({ addModal: !prev.addModal }));
    }

    render(){
        return (
            <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <>
                            {/* ADD MODAL */}
                            <AnalyticsModal isOpen={this.state.addModal} toggle={this.addModal} analyticsName='ClinicSettingsCategories'>
                                <Formik
                                    initialValues={{
                                        ...i18nHelper.getInitialValues(),
                                        status: 'active',
                                        color: 0,
                                    }}
                                    validationSchema={Yup.object().shape({
                                        ...i18nHelper.getValidationSchema({ name: Yup.string().required() }),
                                        status: Yup.string().required(<Translate id='settings.clinics.categories.add.status.required' />),
                                    })}
                                    onSubmit={(values, { setSubmitting }) => {
                                        this.setState(() => ({ modalLoading: 'lazy' }))
                                        values.active = !!(values.status === 'active')
                                        const newItem = values // data that will be sent back to table

                                        // data to send to api
                                        const apiValues = { ...values, ...i18nHelper.getAPIValues(values) };
                                        delete apiValues.i18n

                                        this.props.QualificationCategoriesContext.createQualificationCategory(this.props.OrganizationContext.organisation_id, apiValues)
                                            .then((id) => {
                                                const returnData = {
                                                    qualification_category_id: id,
                                                    ...newItem,
                                                }
                                                this.qualificationCategoriesTableRef.addRow(returnData).then(() => {
                                                    this.setState(() => ({ modalLoading: 'success' }))
                                                    this.toggleAddModal();
                                                })
                                                    .catch(() => {
                                                        this.setState(() => ({ modalLoading: 'error' }))
                                                    })
                                            })
                                            .catch((error) => {
                                                this.errorCode = <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />;
                                                console.error(error.message);
                                            })
                                            .finally(() => {
                                                setSubmitting(false)
                                            })
                                    }}
                                >
                                    {(formik) => {
                                        return (
                                            <Form>
                                                <OverlayLoader isLoading={this.state.modalLoading === 'lazy'}>
                                                    <Fade>
                                                        <ModalHeader toggle={this.toggleAddModal}><Translate id='settings.clinics.categories.add.title' /></ModalHeader>
                                                        <ModalBody>
                                                            <Row>
                                                                <Col md='8'>
                                                                    <FormGroup>
                                                                        <Label for='status' className='text-muted'><Translate id='settings.clinics.categories.add.status' /></Label>
                                                                        <FormikSelect
                                                                            name="status" id="status"
                                                                            defaultData={[
                                                                                { value: 'active', label: 'settings.clinics.categories.add.status.active', translateLabel: true },
                                                                                { value: 'inactive', label: 'settings.clinics.categories.add.status.inactive', translateLabel: true },
                                                                            ]}
                                                                            loadingStatus='success'
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md='4'>
                                                                    <FormGroup>
                                                                        <div className="text-muted"><Translate id='settings.clinics.categories.add.color' /></div>
                                                                        <ClinicColorPicker
                                                                            color={0}
                                                                            onChangeColor={(value) => {
                                                                                return new Promise((resolve, reject) => {
                                                                                    formik.setFieldValue('color', value)
                                                                                    resolve(true)
                                                                                })
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <RenderI18nForm field="name">
                                                                {({ fieldName, fieldLabel }) => {
                                                                    return (
                                                                        <Row key={fieldName}>
                                                                            <Col md='12'>
                                                                                <FormGroup>
                                                                                    <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                }}
                                                            </RenderI18nForm>

                                                            <RenderI18nForm field="description">
                                                                {({ fieldName, fieldLabel }) => {
                                                                    return (
                                                                        <Row key={fieldName}>
                                                                            <Col md='12'>
                                                                                <FormGroup>
                                                                                    <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                }}
                                                            </RenderI18nForm>

                                                            {this.state.modalLoading === 'error' ?
                                                                <div className='alert alert-danger'>{this.errorCode}</div>
                                                                : null}
                                                        </ModalBody>
                                                        <ModalFooter>
                                                            <Button color='primary' type='button' outline onClick={this.toggleAddModal} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                                            <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                                        </ModalFooter>
                                                    </Fade>
                                                </OverlayLoader>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </AnalyticsModal>

                            <SpordlePanelTable
                                allowOutsideClick
                                sidePanel={(props) => <SidePanelClinicCategories {...props} tableRef={this.qualificationCategoriesTableRef} />}
                                dataIndex='qualification_category_id'
                                table={(panelProps) => {
                                    return (
                                        <Card body className="card-shadow">
                                            <CardSectionTitle title='settings.clinics.tab.categories' />
                                            <SpordleTableProvider
                                                id='ClinicSettingsCategoriesTable'
                                                tableHover clickable striped
                                                bordered
                                                ref={(r) => { this.qualificationCategoriesTableRef = r; panelProps.spordleTableRef(r); }}
                                                searchKeys={[
                                                    `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                ]}
                                                dataIndex='qualification_category_id'
                                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                                desktopWhen='md'
                                                pagination={20}
                                                emptyLayout={<EmptyLayout />}
                                                onColumnClick={(e, category) => {
                                                    switch (e.button){
                                                        case 0: // Left mouse button
                                                            panelProps.onSingleSelectChange(category);
                                                            break;
                                                    }
                                                }}
                                                columns={[
                                                    {
                                                        label: 'Color',
                                                        key: "color",
                                                        dataClassName: 'text-center',
                                                        mobile: true,
                                                        sortable: true,
                                                    },
                                                    ...i18nHelper.getTableColumns('name'),
                                                    {
                                                        label: 'Active',
                                                        key: "active",
                                                        dataClassName: 'text-center',
                                                        mobile: true,
                                                        sortable: true,
                                                    },
                                                ]}
                                                rowIsHighlighted={(category) => !!category.checked}
                                                renderRow={(columnKey, category, spordleTable) => {
                                                    if(columnKey.includes('i18n')){
                                                        const lang = columnKey.substring(0, columnKey.lastIndexOf('.')); // Get column key lang for i18n helper
                                                        return (
                                                            <div>
                                                                <DisplayI18n
                                                                    field={columnKey}
                                                                    defaultValue={category.name}
                                                                    i18n={category.i18n}
                                                                />
                                                                <div className="text-muted">
                                                                    <DisplayI18n
                                                                        field={`${lang}.description`}
                                                                        defaultValue={category.description}
                                                                        i18n={category.i18n}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    switch (columnKey){
                                                        case 'color':
                                                            return (
                                                                <div className="font-20"><ClinicCategory color={isNaN(parseInt(category.color)) ? 0 : parseInt(category.color)} /></div>
                                                            );
                                                        case 'active':
                                                            return (
                                                                <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                    {category.active == '1' ?
                                                                        <i className="text-primary mdi mdi-check" />
                                                                        :
                                                                        <i className="text-danger mdi mdi-close" />
                                                                    }
                                                                </div>
                                                            );
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                loadData={(from, filterData, spordleTable) => {
                                                    switch (from){
                                                        case 'REFRESH':
                                                            spordleTable.setLoading(true);
                                                        case 'CDM':
                                                            return this.props.QualificationCategoriesContext.getQualificationCategories(true); // Second parameter to force from api instead of cache
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            >
                                                <div className='mb-2 d-flex flex-wrap justify-content-between'>
                                                    <SpordleTableProvider.SearchInput />
                                                    <div className='text-right nowrap'>
                                                        <SpordleTableProvider.Refresh />
                                                        <UncontrolledButtonDropdown className='ml-2'>
                                                            <DropdownToggle color='primary' outline caret disabled>
                                                                <i className='fas fa-download' /> <Translate id='misc.export' />
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem disabled><i className='fas fa-file-excel' /> <Translate id='misc.excel' /></DropdownItem>
                                                                <DropdownItem disabled><i className='fas fa-file-pdf' /> <Translate id='misc.pdf' /></DropdownItem>
                                                                <DropdownItem disabled><i className='fas fa-print' /> <Translate id='misc.print' /></DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>

                                                        <CanDoAction action='ADD' componentCode='clinics' componentPermissionCode='clinics_settings'>
                                                            <Button className='ml-2' color='primary' onClick={() => this.setState((prev) => ({ addModal: !prev.addModal }))}><i className='mdi mdi-plus' /> <Translate id='misc.add' /></Button>
                                                        </CanDoAction>
                                                    </div>
                                                </div>

                                                <SpordleTableView />
                                            </SpordleTableProvider>
                                        </Card>
                                    )
                                }}
                            />
                        </>
                    )}
                </I18nHelperContext.Consumer>
            </I18nHelperContextProvider>
        )
    }
}

export default withContexts(OrganizationContext, QualificationCategoriesContext, I18nContext)(ClinicSettingsCategories);