import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import { FormikCheckedButton, FormikError, FormikGroup, FormikInputNumber, FormikInputText, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { FieldArray, Form, Formik } from "formik";
import { useContext, useEffect, useRef, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Collapse,
    Container,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner, Table, UncontrolledAlert
} from "reactstrap";
import { array, bool, number, object, string } from 'yup';
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import Required from '../../../../components/formik/Required';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import CanDoAction from '../../../../components/permissions/CanDoAction';
import { fail } from "@spordle/toasts";
import { ClinicFeesContext } from '../../../../contexts/ClinicFeesContext';
import { ClinicsContext } from '../../../../contexts/ClinicsContext';
// context
import { I18nContext } from '../../../../contexts/I18nContext';
import { UtilsContext } from '../../../../contexts/UtilsContext';
import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';
import { RolesContext } from '../../../../contexts/RolesContext';


const FormClinicPaymentOptions = (props) => {

    const clinicsContext = useContext(ClinicsContext);
    const clinicFeesContext = useContext(ClinicFeesContext);
    const rolesContext = useContext(RolesContext);
    // const merchantAccountContext = useContext(MerchantAccountContext)

    const formikRef = useRef(null);

    const [ feesModal, setFeesModal ] = useState(false);
    const toggleFeesModal = () => {
        setFeesModal(!feesModal);
    }

    ////////////////////////////////////////////////////////////////////////
    // Clinic Fees section
    ////////////////////////////////////////////////////////////////////////
    const clinicFeesTableRef = useRef(null);
    const [ clinicFees, setClinicsFees ] = useState([]);
    const [ clinicFeesToDelete, setClinicFeesToDelete ] = useState([]);

    // used for the spordle table mock up in the clinic fees section
    const [ nameHover, setNameHover ] = useState(false)
    const [ descriptionHover, setDescriptionHover ] = useState(false)
    const [ organizationHover, setOrganizationHover ] = useState(false)
    const [ removeHover, setRemoveHover ] = useState(false)

    const removeClinicFee = (clinicFeeId, feeId) => {
        if(clinicFeeId){
            const temp = clinicFeesToDelete;
            temp.push(clinicFeeId);
            setClinicFeesToDelete(temp);
        }

        formikRef.current?.setFieldValue('clinicFees', formikRef.current?.values.clinicFees.filter((clinicFee) => clinicFee.fee_id !== feeId))
        clinicFeesTableRef.current.deleteRow(feeId);
    }

    const detectChanges = (oldClinicFee, newClinicFee) => {
        // just this for now, nothing else can be updated
        return (oldClinicFee.mandatory === '1') !== newClinicFee.mandatory
    }

    const getExtraColumns = () => {
        if(rolesContext.canDoAction('DELETE', 'clinics', 'clinics_settings')){
            return [ {
                label: '-',
                key: "remove",
                dataClassName: 'text-center',
                className: 'text-center',
                mobile: true,
            } ]
        }
        return []
    }

    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    /**  @type {React.MutableRefObject<SpordleSelect>} */
    const merchantAccoundSelectRef = useRef(null);

    useEffect(() => {
        clinicsContext.getClinicItems({ organisation_id: clinicsContext.currentClinic?.organisation?.organisation_id, type: 'CLINIC' })
            .then(setClinicsFees)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, [])

    return (
        <Container>
            <Formik
                innerRef={(r) => formikRef.current = r}
                initialValues={{
                    clinicFees: clinicsContext.currentClinic?.clinic_fees?.map((clinicFee) => ({
                        ...clinicFee,
                        fee_id: clinicFee.fee.fee_id,
                        mandatory: clinicFee.mandatory === '1',
                    })) || [],
                    merchantAccount: clinicsContext.currentClinic?.merchant_account?.merchant_account_id || '',
                    paymentMethods: clinicsContext.currentClinic?.accepted_payment_methods?.map((pM) => pM.code) || [],
                }}
                validationSchema={object().shape({
                    clinicFees: array().test({
                        name: 'mandatoryFeeTest',
                        message: <Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.validation.mandatoryFee' />,
                        test: function(value){
                            return value?.find((fee) => fee.mandatory)
                        },
                    }),
                    merchantAccount: string()
                        .test({
                            name: 'noPaymentMethods',
                            message: <Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.validation.online' />,
                            test: function(merchantAccount){
                                return !(this.parent.paymentMethods.length === 0 && merchantAccount === undefined)
                            },
                        }),
                    paymentMethods: array()
                        .test({
                            name: 'noMerchantAccount',
                            message: <Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.validation.prepaid' />,
                            test: function(paymentMethods){
                                return !(this.parent.merchantAccount === undefined && paymentMethods.length === 0)
                            },
                        }),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    Promise.all([
                        new Promise((resolve) => {
                            clinicsContext.updateClinicPartial(clinicsContext.currentClinic?.clinic_id, { merchantAccountId: values.merchantAccount })
                                .then(() => resolve({ section: 'merchantAccount', status: 'fulfilled', reason: clinicsContext.currentClinic?.clinic_id }))
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                        resolve({ section: 'merchantAccount', status: 'failed', reason: error })
                                    }
                                })
                        }),

                        new Promise((resolve) => {
                            if(values.paymentMethods.length === 0){
                                clinicsContext.deletePaymentMethods(clinicsContext.currentClinic?.clinic_id)
                                    .then(() => resolve({ section: 'paymentMethods', status: 'fulfilled', reason: clinicsContext.currentClinic?.clinic_id }))
                                    .catch((errors) => resolve({ section: 'paymentMethods', status: 'failed', reason: errors }))
                            }else{
                                clinicsContext.updatePaymentMethods(clinicsContext.currentClinic?.clinic_id, values.paymentMethods)
                                    .then(() => resolve({ section: 'paymentMethods', status: 'fulfilled', reason: clinicsContext.currentClinic?.clinic_id }))
                                    .catch((errors) => resolve({ section: 'paymentMethods', status: 'failed', reason: errors }))
                            }
                        }),

                        ...values.clinicFees.map((clinicFee) => {
                            if(clinicFee.clinic_fee_id){ // already exists in the database
                                if(detectChanges(clinicsContext.currentClinic?.clinic_fees?.find((_clinicFee) => _clinicFee.fee.fee_id === clinicFee.fee_id), clinicFee)){
                                    return new Promise((resolve) => {
                                        clinicFeesContext.updateClinicFee(clinicFee.clinic_fee_id, clinicFee)
                                            .then(() => resolve({ section: 'clinicFees', status: 'fulfilled', reason: clinicFee.clinic_fee_id }))
                                            .catch((error) => {
                                                resolve({ section: 'clinicFees', status: 'failed', reason: error.code })
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    })
                                }
                            }else{
                                return new Promise((resolve) => {
                                    clinicFeesContext.createClinicFee(clinicsContext?.currentClinic?.clinic_id, clinicFee.fee_id, clinicFee.mandatory)
                                        .then((clinicFeeId) => resolve({ section: 'clinicFees', status: 'fulfilled', reason: clinicFeeId }))
                                        .catch((error) => {
                                            resolve({ section: 'clinicFees', status: 'failed', reason: error.code })
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                        .catch((errors) => resolve({ section: 'clinicFees', status: 'failed', reason: errors }))
                                })
                            }
                        }),

                        ...clinicFeesToDelete?.map((clinicFeeId) => {
                            return new Promise((resolve) => {
                                clinicFeesContext.deleteClinicFee(clinicFeeId)
                                    .then(() => resolve({ section: 'clinicFeesDelete', status: 'fulfilled', reason: clinicFeeId }))
                                    .catch((error) => {
                                        if(error.i18n){
                                            props.setPaymentOptionError?.(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                        }else{
                                            props.setPaymentOptionError?.(<Translate id={`clinics.profile.payment.error.${error.message}`} />);
                                        }
                                        resolve({ section: 'clinicFeesDelete', status: 'failed', reason: error.message })
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                            })
                        }) || [],
                    ])
                        .then((promises) => {
                            switch (promises.section){
                                case "clinicFees":
                                    break;
                                default:
                                    break;
                            }
                            clinicsContext.getAllClinicInfos(clinicsContext.currentClinic?.clinic_id)
                                .then(() => {
                                    props.exited();
                                })
                                .catch((error) => {
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                    console.error(error)
                                })
                                .finally(() => {
                                    setSubmitting(false)
                                })
                        })
                        .catch((error) => console.error(error))
                }}
            >
                {(formik) => (
                    <Form id='clinicsCreationStep4'>
                        <Card className={'card-shadow ' + (!props.wizard ? 'shadow-primary' : '')}>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                {!props.wizard &&
                                    <CardHeader className="d-flex align-items-center">
                                        <div className="h4 mb-0 font-bold"><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.review.title' /></div>
                                    </CardHeader>
                                }
                                <CardBody>
                                    <div className="mb-5">
                                        <div className='mb-2'>
                                            <div className='d-flex justify-content-between'>
                                                <div className={`font-bold mb-0 align-self-end ${props.wizard ? 'h3 card-title' : 'h5'}`}><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.title' /> <Required /></div>
                                                <CanDoAction action='ADD' componentCode='clinics' componentPermissionCode='clinics_settings'>
                                                    <button className='reset-btn ml-auto text-primary align-self-end' type='button' onClick={toggleFeesModal}><i className="mdi mdi-plus" /> <Translate id='misc.add' /></button>
                                                </CanDoAction>
                                            </div>
                                        </div>

                                        <SpordleTableProvider
                                            id='payment-options'
                                            ref={(r) => clinicFeesTableRef.current = r}
                                            tableHover striped bordered
                                            dataIndex='fee_id'
                                            desktopWhen='md'
                                            columns={[
                                                {
                                                    label: <Translate id='form.fields.name' />,
                                                    key: "name",
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.optional' />,
                                                    key: "mandatory",
                                                    dataClassName: 'text-center',
                                                    className: 'text-center',
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.amount' />,
                                                    key: "amount",
                                                    mobile: true,
                                                    sortable: true,
                                                    sortKey: 'fee.amount',
                                                    className: 'td-shrink',
                                                },
                                                {
                                                    label: <Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.taxes' />,
                                                    key: "taxes",
                                                    mobile: true,
                                                    sortable: true,
                                                    sortKey: 'fee.amount_tax',
                                                    className: 'td-shrink',
                                                },
                                                {
                                                    label: <Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.total' />,
                                                    key: "total",
                                                    mobile: true,
                                                    sortable: true,
                                                    sortKey: 'fee.amount_with_tax',
                                                    className: 'td-shrink',
                                                },
                                                ...getExtraColumns(),
                                            ]}
                                            renderRow={(key, clinicFee, spordleTable) => {
                                                switch (key){
                                                    case "name":
                                                        return (
                                                            <div>
                                                                <div>
                                                                    <DisplayI18n
                                                                        field='name'
                                                                        defaultValue={clinicFee.fee.name}
                                                                        i18n={clinicFee.fee.i18n}
                                                                    />
                                                                </div>
                                                                <div className='text-muted small'>
                                                                    <DisplayI18n
                                                                        field='description'
                                                                        defaultValue={clinicFee.fee.description}
                                                                        i18n={clinicFee.fee.i18n}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    case "mandatory":
                                                        if(rolesContext.canDoAction('EDIT', 'clinics', 'clinics_settings')){
                                                            return (
                                                                <label className='my-auto' htmlFor={clinicFee.fee_id} onClick={(e) => { e.stopPropagation() }}>
                                                                    <input
                                                                        id={clinicFee.fee_id}
                                                                        type="checkbox"
                                                                        defaultChecked={clinicFee.mandatory}
                                                                        onChange={(e) => {
                                                                            const temp = formik.values.clinicFees;
                                                                            const clinicFeeIndex = temp.findIndex((_clinicFee) => _clinicFee.fee_id === clinicFee.fee_id);
                                                                            if(clinicFeeIndex !== -1){
                                                                                temp[clinicFeeIndex].mandatory = e.target.checked;
                                                                            }
                                                                            formik.setFieldValue('clinicFees', temp)
                                                                            spordleTable.updateDataWith(clinicFee.fee_id, { mandatory: e.target.checked })
                                                                        }}
                                                                    />
                                                                    <span className="table-checkbox" />
                                                                </label>
                                                            )
                                                        }
                                                        if(clinicFee.mandatory){
                                                            return <i className='mdi mdi-check text-primary' />
                                                        }
                                                        return null
                                                    case "amount":
                                                        return <CurrencyFormat value={clinicFee.fee.amount / 100} />;
                                                    case "taxes":
                                                        return clinicFee.fee.amount_tax ? <CurrencyFormat value={clinicFee.fee.amount_tax / 100} /> : '-';
                                                    case "total":
                                                        return <CurrencyFormat value={clinicFee.fee.amount_with_tax / 100} />;
                                                    case "remove":
                                                        return (
                                                            <CanDoAction action='DELETE' componentCode='clinics' componentPermissionCode='clinics_settings'>
                                                                <button className='reset-btn' type='button' onClick={() => { removeClinicFee(clinicFee.clinic_fee_id, clinicFee.fee_id) }}><i className='text-danger fas fa-trash-alt' /></button>
                                                            </CanDoAction>
                                                        )
                                                    default:
                                                        break;
                                                }
                                            }}
                                            emptyLayout={<div className='text-center'><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.empty' /></div>}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return Promise.resolve([ ...formik.values.clinicFees ]);
                                                    default:
                                                        break;
                                                }
                                            }}
                                        >
                                            {(spordleTable) => (
                                                <>
                                                    <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
                                                        <ModalFee
                                                            onSuccess={(newObj) => {
                                                                const temp = [ ...formik.values.clinicFees ];
                                                                temp.push(newObj);
                                                                formik.setFieldValue('clinicFees', temp);
                                                            }}
                                                            addRow={spordleTable.addRow}
                                                            getData={spordleTable.getData}
                                                            isOpen={feesModal}
                                                            toggle={toggleFeesModal}
                                                            clinicFees={clinicFees}
                                                        />
                                                    </I18nHelperContextProvider>
                                                    {spordleTable.getData().length === 0 &&
                                                        <Table bordered striped hover>
                                                            <thead>
                                                                <tr>
                                                                    <th onMouseEnter={() => setNameHover(true)} onMouseLeave={() => setNameHover(false)} className={stringBuilder({ 'bg-light': nameHover })}><Translate id='form.fields.name' /></th>
                                                                    <th onMouseEnter={() => setDescriptionHover(true)} onMouseLeave={() => setDescriptionHover(false)} className={stringBuilder({ 'bg-light': descriptionHover })}><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.optional' /></th>
                                                                    <th onMouseEnter={() => setOrganizationHover(true)} onMouseLeave={() => setOrganizationHover(false)} className={stringBuilder({ 'bg-light': organizationHover })}><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.amount' /></th>
                                                                    <th onMouseEnter={() => setRemoveHover(true)} onMouseLeave={() => setRemoveHover(false)} className={stringBuilder('text-center', { 'bg-light': removeHover })}>-</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={4} className='text-center'>
                                                                        <div><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.hint.none1' /></div>
                                                                        <button
                                                                            className='font-medium reset-btn text-primary'
                                                                            type='button'
                                                                            onClick={toggleFeesModal}
                                                                            disabled={!rolesContext.canDoAction('ADD', 'clinics', 'clinics_settings')}
                                                                        >
                                                                            <i className='mdi mdi-plus' /><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.table.hint.none2' />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    }
                                                    {spordleTable.getData().length > 0 &&
                                                        <SpordleTableView />
                                                    }
                                                    <FormikError name='clinicFees' />
                                                </>
                                            )}
                                        </SpordleTableProvider>
                                    </div>

                                    <div className="mb-5">
                                        <div className={`font-bold mb-3 ${props.wizard ? 'h3 card-title border-bottom pb-1' : 'h5'}`}><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.title' /></div>

                                        <div className='mb-5'>
                                            <div className={`font-bold mb-3 ${props.wizard ? 'h4' : 'h6'}`}><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.online.title' /> <Required /><span className='ml-1 text-muted small'>(<Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.online.title.helper' />)</span></div>
                                            <FieldArray name='paymentMethods'>
                                                {(paymentOptionsHelper) => (
                                                    <FormikSelect
                                                        id='FormClinicPaymentOptionsMerchantAccountSelect'
                                                        name='merchantAccount'
                                                        manualError
                                                        ref={merchantAccoundSelectRef}
                                                        clearable
                                                        noOptionLayout={<Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.merchant.noOptions' />}
                                                        placeholder='clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.merchant.placeholder'
                                                        onOptionSelected={(values, select) => {
                                                            const merchantAccount = select.getSpordleTable().getData().find((option) => option.value === values[0]);
                                                            if(merchantAccount){ // Has a merchant account selected
                                                                if(formik.values.paymentMethods.every((pM) => pM !== 'CREDITCARD'))// Verifying CREDITCARD is not already in the payment options
                                                                    paymentOptionsHelper.push('CREDITCARD');
                                                            }else{ // Does not have a merchant account selected
                                                                paymentOptionsHelper.remove(formik.values.paymentMethods.findIndex((pM) => pM === 'CREDITCARD'));
                                                            }
                                                        }}
                                                        options={clinicsContext.currentClinic?.merchantAccounts?.reduce((options, merchantAccount, key) => {
                                                            if(merchantAccount.active === '1'){
                                                                options.push({
                                                                    value: merchantAccount.merchant_account_id,
                                                                    label: merchantAccount.name,
                                                                })
                                                            }
                                                            return options;
                                                        }, []) || []}
                                                    />
                                                )}
                                            </FieldArray>
                                            {merchantAccoundSelectRef.current?.getSpordleTable().getData().length === 0 ?
                                                <span className='text-danger small'><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.merchant.none' /></span>
                                                :
                                                <FormikError name='merchantAccount' />
                                            }
                                        </div>

                                        <div className={`font-bold mb-3 ${props.wizard ? 'h4' : 'h6'}`}><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.paymentMethods.other.title' /></div>
                                        {!props.paymentMethods ?
                                            <div className='text-center'>
                                                <Spinner color='primary' type='grow' />
                                            </div>
                                            :
                                            <FormikGroup name='paymentMethods'>
                                                {props.paymentMethods.filter((_m) => _m.category === "MANUAL").map((method) => (
                                                    <Col md='12' className='my-1 d-flex align-items-center' key={method.code}>
                                                        {/* FormikCheckedButton doesn't work because of some obscure bullsh*t happening in the review, nothing makes sense, please help */}
                                                        <input
                                                            id={method.code}
                                                            name='paymentMethods'
                                                            value={method.code}
                                                            type="checkbox"
                                                            defaultChecked={!!formik.initialValues.paymentMethods.find((pM) => pM === method.code)}
                                                            // basically the table-checkbox class, but hard coded because there's no table
                                                            style={{ width: '1rem', height: '1rem', borderRadius: '2px', border: '1px solid #adb5bd', cursor: 'pointer' }}
                                                            onChange={(e) => {
                                                                if(e.target.checked){
                                                                    formik.setFieldValue('paymentMethods', [ ...formik.values.paymentMethods, method.code ])
                                                                }else{
                                                                    formik.setFieldValue('paymentMethods', formik.values.paymentMethods.filter((pM) => pM !== method.code))
                                                                }
                                                            }}
                                                        />
                                                        <span className='font-medium text-dark ml-2'>
                                                            <DisplayI18n
                                                                field="name"
                                                                i18n={method.i18n}
                                                                defaultValue={method.name}
                                                            />
                                                        </span>
                                                    </Col>
                                                ))}
                                            </FormikGroup>
                                        }
                                    </div>
                                </CardBody>
                                {!props.wizard &&
                                    <CardFooter className="bg-white text-right">
                                        <Button className='mr-2' color="primary" type='submit' disabled={formik.isSubmitting}><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.review.saveChanges' /></Button>
                                        <Button color="primary" outline type='button' onClick={props.exited} disabled={formik.isSubmitting}><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.review.cancelChanges' /></Button>
                                    </CardFooter>
                                }
                            </OverlayLoader>
                        </Card>
                        {props.wizard &&
                            <div className="d-flex mb-5">
                                <Button color="primary" outline type='button' onClick={props.previousStep} disabled={formik.isSubmitting}><Translate id='misc.previous' /></Button>
                                <Button color="primary" className="ml-auto" type='submit' disabled={formik.isSubmitting}><Translate id='misc.next' /></Button>
                            </div>
                        }
                    </Form>
                )}
            </Formik>
        </Container>
    );
}

const ModalFee = ({ addRow, getData, isOpen, toggle, clinicFees, onSuccess }) => {
    const utilsContext = useContext(UtilsContext);
    const { createClinicItems, getClinicItem } = useContext(ClinicsContext);
    const [ isCreation, setIsCreation ] = useState(false);

    const canAdd = (fee) => {
        if(getData().length === 0){
            return true
        }
        return !getData().find((clinicFee) => clinicFee.fee_id === fee.fee_id)

    }

    return (
        <AnalyticsModal analyticsName='ModalFee' isOpen={isOpen}>
            <I18nHelperContext.Consumer>
                {(i18nHelper) => (
                    <Formik
                        initialValues={{
                            ...i18nHelper.getInitialValues(),
                            feeId: '',
                            fee: null,
                            amount: 0,
                            mandatory: false,
                            taxable: false,
                        }}
                        validationSchema={object().shape({
                            ...i18nHelper.getValidationSchema({
                                name: string().test({
                                    name: 'nameIsRequired',
                                    message: <Translate id='catalog.clinicItems.add.form.validation.name.required' />,
                                    test: function(name){
                                        return !isCreation || !!name;
                                    },
                                }),
                            }),
                            feeId: string().test({
                                name: 'feeIsRequired',
                                message: <Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.fee.required' />,
                                test: function(feeId){
                                    return isCreation || !!feeId;
                                },
                            }),
                            mandatory: bool().required(),
                            amount: number().test({
                                name: 'amountIsRequired',
                                message: <Translate id='catalog.clinicItems.add.form.validation.amout.required' />,
                                test: function(amount){
                                    return !isCreation || amount >= 0;
                                },
                            }),
                            taxable: bool(),
                        })}
                        onSubmit={async({ taxable, ...values }, { setStatus }) => {
                            try{
                                const newValues = { ...values };

                                if(isCreation){
                                    const taxClasses = taxable
                                        ?
                                        await utilsContext.getTaxClasses()
                                        :
                                        [];
                                    const taxClass = taxClasses.filter((taxClass) => taxClass.active == '1')[0];

                                    // Clinic item will always be a type CLINIC
                                    newValues.type = 'CLINIC';
                                    newValues.active = '1';
                                    // Amount must be the total cents -> 10.00$ will be 1000
                                    newValues.amount = Math.round(values.amount * 100);
                                    // Data to send to api
                                    const apiValues = { ...newValues, ...i18nHelper.getAPIValues(newValues) };
                                    delete apiValues.i18n;
                                    delete apiValues.feeId;
                                    delete apiValues.fee;
                                    if(taxable){
                                        apiValues.tax_class_id = taxClass.tax_class_id;
                                    }

                                    newValues.fee = { ...newValues };
                                    delete newValues.fee.fee;
                                    newValues.tax_class = taxClass;

                                    newValues.feeId = await createClinicItems(apiValues)

                                    if(newValues.feeId){
                                        newValues.fee = await getClinicItem(newValues.feeId)
                                    }
                                }

                                if((isCreation && !!newValues.feeId) || canAdd(newValues.fee)){
                                    const formattedValues = {
                                        fee: newValues.fee,
                                        fee_id: newValues.feeId,
                                        mandatory: newValues.mandatory,
                                    }

                                    await addRow(formattedValues)
                                    toggle();
                                    onSuccess(formattedValues);
                                }else{
                                    setStatus(<Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.duplicate' />);
                                }
                            }catch(error){
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                }
                            }
                        }}
                    >
                        {(formik) => (
                            <Form id='clinicsCreationStep4FeesModal'>
                                <OverlayLoader isLoading={formik.isSubmitting}>
                                    <ModalHeader toggle={toggle}>
                                        <Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.title' />
                                    </ModalHeader>
                                    <ModalBody>
                                        <Collapse isOpen={!isCreation}>
                                            <FormGroup>
                                                <Label className="text-muted" for="fee"><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.fee' /></Label>
                                                <FormikSelect
                                                    id='FormClinicPaymentOptionsFeeSelect' name='feeId'
                                                    noOptionLayout={<Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.fee.noOptions' />}
                                                    placeholder='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.fee.placeholder'
                                                    renderOption={(option, fromSelected) => {
                                                        const data = option.option;
                                                        if(fromSelected){
                                                            return (
                                                                <>
                                                                    <div>
                                                                        <DisplayI18n field='name' defaultValue={data.label} i18n={data.fee.i18n} />
                                                                        <span className='mx-1'>|</span><CurrencyFormat value={data.amount / 100} />
                                                                    </div>
                                                                    <div className='text-nowrap small text-muted'>
                                                                        <DisplayI18n field='description' defaultValue={data.fee.description} i18n={data.fee.i18n} />
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        return (
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <div>
                                                                    <div>
                                                                        <DisplayI18n field='name' defaultValue={data.label} i18n={data.fee.i18n} />
                                                                    </div>
                                                                    <div className='small text-muted'>
                                                                        <DisplayI18n field='description' defaultValue={data.fee.description} i18n={data.fee.i18n} />
                                                                    </div>
                                                                </div>
                                                                <div className="text-nowrap">
                                                                    <CurrencyFormat value={data.amount / 100} />
                                                                </div>
                                                            </div>
                                                        )

                                                    }}
                                                    onOptionSelected={(values, select) => {
                                                        const fee = select.getSpordleTable().getData().find((option) => option.value === values[0]).fee;
                                                        formik.setFieldValue('fee', fee)
                                                    }}
                                                    options={clinicFees?.reduce((newArray, fee) => {
                                                        if(fee.active == 1){
                                                            newArray.push({
                                                                value: fee.fee_id,
                                                                label: fee.name,
                                                                fee: fee,
                                                                amount: fee.amount,
                                                            })
                                                        }
                                                        return newArray
                                                    }, [])}
                                                />
                                            </FormGroup>
                                        </Collapse>
                                        <CanDoAction action='ADD' componentCode='catalog' componentPermissionCode='qualification_fees'>
                                            { isCreation ?
                                                <button type="button" onClick={() => setIsCreation(false)} className="reset-btn text-primary mb-3">
                                                    <i className="mdi mdi-minus mr-1" /><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.cancelCreate' />
                                                </button>
                                                :
                                                <button type="button" onClick={() => setIsCreation(true)} className="reset-btn text-primary mb-3">
                                                    <i className="mdi mdi-plus mr-1" /><Translate id='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.create' />
                                                </button>
                                            }
                                        </CanDoAction>
                                        <FormGroup>
                                            <FormikCheckedButton
                                                name='mandatory'
                                                id='mandatory'
                                                label='clinics.clinicPaymentOptions.formClinicPaymentOptions.fees.add.optional'
                                                translateLabel
                                            />
                                        </FormGroup>
                                        <CanDoAction action='ADD' componentCode='catalog' componentPermissionCode='qualification_fees'>
                                            <Collapse isOpen={isCreation}>
                                                <RenderI18nForm field="name">
                                                    {({ fieldName, fieldLabel }) => (
                                                        <FormGroup key={fieldName}>
                                                            <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                            <FormikInputText id={fieldName} name={fieldName} trim />
                                                        </FormGroup>
                                                    )}
                                                </RenderI18nForm>
                                                <RenderI18nForm field="description">
                                                    {({ fieldName, fieldLabel }) => (
                                                        <FormGroup key={fieldName}>
                                                            <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                            <FormikTextArea id={fieldName} name={fieldName} rows="5" trim />
                                                        </FormGroup>
                                                    )}
                                                </RenderI18nForm>
                                                <FormGroup>
                                                    <Label for="amount" className="text-muted"><Translate id='catalog.clinicItems.add.form.price' /> <Required /></Label>
                                                    <div className="d-flex align-items-center">
                                                        <I18nContext.Consumer>
                                                            {({ getGenericLocale }) => (
                                                                <FormikInputNumber
                                                                    manualError
                                                                    id='amount'
                                                                    name='amount'
                                                                    className="w-25"
                                                                    decimalScale={2}
                                                                    allowNegative={false}
                                                                    allowLeadingZeros
                                                                    fixedDecimalScale
                                                                    thousandSeparator=' '
                                                                    decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                                />
                                                            )}
                                                        </I18nContext.Consumer>
                                                        <span className="ml-3">CAD</span>
                                                    </div>
                                                    <FormikError name='amount' />
                                                    <FormikCheckedButton id='taxable' name='taxable' label='catalog.clinicItems.add.form.taxable' className='mt-2' />
                                                </FormGroup>
                                            </Collapse>
                                        </CanDoAction>

                                        <Collapse isOpen={!!formik.status}>
                                            <UncontrolledAlert color='danger'>
                                                {formik.status}
                                            </UncontrolledAlert>
                                        </Collapse>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                                            <Translate id='misc.add' />
                                        </Button>
                                        <Button color='primary' outline type='button' onClick={toggle} disabled={formik.isSubmitting}>
                                            <Translate id='misc.cancel' />
                                        </Button>
                                    </ModalFooter>
                                </OverlayLoader>
                            </Form>
                        )}
                    </Formik>
                )}
            </I18nHelperContext.Consumer>
        </AnalyticsModal>
    )
}

export default FormClinicPaymentOptions;