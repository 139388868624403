import { stringBuilder } from "@spordle/helpers";
import PropTypes from 'prop-types';

/**
 * Header of the sidepanel
 * @param {Object} props
 * @param {string|import("react").ReactElement} [props.tag=div]
 * @param {boolean} [props.noBorder=false]
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
const SidePanelHeader = ({ tag: Tag = "div", noBorder = false, className, ...props }) => {
    return (
        <Tag {...props} className={stringBuilder("p-3", className, { "border-bottom": !noBorder })} />
    )
}

export default SidePanelHeader;

SidePanelHeader.propTypes = {
    tag: PropTypes.oneOfType([ PropTypes.string, PropTypes.elementType ]),
    noBorder: PropTypes.bool,
    className: PropTypes.string,
}

SidePanelHeader.defaultProps = {
    tag: "div",
    noBorder: false,
}