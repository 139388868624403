import Translate from "@spordle/intl-elements";
import React from "react";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import FormikEditable from "../../../../../components/formik/FormikEditable";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
import { AuthContext } from "../../../../../contexts/contexts";
import { WaiversContext } from "../../../../../contexts/WaiversContext";
import { displayI18n, DisplayI18n } from "../../../../../helpers/i18nHelper";
import withContexts from "../../../../../helpers/withContexts";
import { WaiverFields, waiverNotSigned, waiverPhysical, WaiverSignature, WaiverSignatureDisplay } from "../../../../../helpers/waiversHelper";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { object, string } from "yup";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import { MembersContext } from "../../../../../contexts/MembersContext";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";

class MemberProfileWaiverSidepanel extends React.Component{

    state = {
        isLoading: false,
        parents: [],
    }

    getNewValues = (answer, signatureType) => {
        return this.props.createNewValues({
            answer: answer,
            signature_type: signatureType,
            updated_at: new Date().toISOString(),
            identity: {
                family_name: this.props.AuthContext.account.family_name,
                identity_id: this.props.AuthContext.account.userName,
                name: this.props.AuthContext.account.name,
            },
        });
    }

    componentDidMount(){
        this.props.OrganizationContext.getOrganizationPublic(this.props.MembersContext.currentMember?.organisation?.organisation_id)
            .then((organization) => {
                this.setState(() => ({ parents: organization.breadcrumbs }));
            })
            .catch((error) => { console.error(error.message) });
    }

    render(){
        const isCurrentOrganizationOrParent = this.props.MembersContext.currentMember?.organisation?.organisation_id === this.props.OrganizationContext.organisation_id || !!this.state.parents?.some((parent) => parent.organisation_id === this.props.OrganizationContext.organisation_id);

        // Identity === null OR same identity as signee
        const canUnsign = this.props.selectedRows[0].identity?.identity_id == null || this.props.selectedRows[0].identity?.identity_id === this.props.AuthContext.account.userName;

        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <SidePanel.Header>
                    <div className='d-flex mb-3'>
                        <SidePanel.ToggleButton />
                        {this.props.selectedRows[0].answer !== waiverNotSigned &&
                            // CanDoAction placed around Actions menu because there is only one action
                            <CanDoAction action="EDIT" componentCode="members" componentPermissionCode="members_registrations">
                                <SidePanel.ActionsMenu>
                                    <ConfirmModal
                                        color='danger'
                                        modalTitle='components.manualRegistration.waivers.unsign.confirm'
                                        translateModalTitle
                                        actionButtonText='misc.confirm'
                                        translateActionButtonText
                                        onConfirm={() => {
                                            this.setState(() => ({ isLoading: true }));
                                            return this.props.WaiversContext.signWaiver(
                                                this.props.selectedRows[0].invoice_number,
                                                this.props.selectedRows[0].invoice_item_id,
                                                this.props.selectedRows[0].waiver_id,
                                                {
                                                    answer: waiverNotSigned,
                                                    language_code: this.props.I18nContext.getGenericLocale(),
                                                    signature_type: waiverPhysical,
                                                },
                                            )
                                                .then(() => {
                                                    const newValues = this.getNewValues(waiverNotSigned, waiverPhysical)
                                                    this.props.syncRows(newValues);
                                                    this.props.refreshMemberProfile();
                                                    this.setState(() => ({ isLoading: false }));
                                                    success();
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        this.setState(() => ({ isLoading: false }));
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                        }}
                                        toggler={(onClick) => (
                                            <>
                                                <SidePanel.MenuAction onClick={onClick} disabled={!canUnsign}>
                                                    <Translate id='components.manualRegistration.waivers.unsign' />
                                                    {this.props.selectedRows[0].identity?.identity_id !== this.props.AuthContext.account.userName &&
                                                    <div className="small text-wrap"><Translate id='components.manualRegistration.waivers.unsign.disabled' /></div>
                                                    }
                                                </SidePanel.MenuAction>
                                            </>
                                        )}
                                    />
                                </SidePanel.ActionsMenu>
                            </CanDoAction>
                        }
                    </div>
                    {this.props.selectedRows[0].period &&
                        <div>
                            <DisplayI18n
                                field='name'
                                defaultValue={this.props.selectedRows[0].period.name}
                                i18n={this.props.selectedRows[0].period.i18n}
                            />
                        </div>
                    }
                    <SidePanel.Title>
                        <DisplayI18n
                            field='name'
                            defaultValue={this.props.selectedRows[0].name}
                            i18n={this.props.selectedRows[0].i18n}
                        />
                    </SidePanel.Title>
                    <SidePanel.Subtitle>
                        <DisplayI18n
                            field='name'
                            defaultValue={this.props.selectedRows[0].organisation.organisation_name}
                            i18n={this.props.selectedRows[0].organisation.i18n}
                        />
                    </SidePanel.Subtitle>
                </SidePanel.Header>
                <div className="p-3">
                    <div
                        className="border rounded p-2 max-vh-50 overflow-auto mb-3"
                        dangerouslySetInnerHTML={{ __html: displayI18n("description", this.props.selectedRows[0].i18n, this.props.selectedRows[0].description, this.props.I18nContext.getGenericLocale()) }}
                    />
                    <FormikEditable
                        id='sign-waiver'
                        readOnly={
                            this.props.selectedRows[0].answer !== waiverNotSigned || // readOnly if anything else then not signed yet
                            this.props.selectedRows[0].invoice_organisation?.organisation_id !== this.props.MembersContext.currentMember?.organisation?.organisation_id || // readOnly if not invoice org of current member
                            this.props.MembersContext.currentMember?.share_organisations.some((org) => org.organisation_id === this.props.selectedRows[0].invoice_organisation?.organisation_id) ||
                            !isCurrentOrganizationOrParent
                        }
                        initialValues={{
                            sign: this.props.selectedRows[0].answer === waiverNotSigned ? '' : this.props.selectedRows[0].answer,
                            signatureType: '',
                        }}
                        validationSchema={object().shape({
                            sign: string().test({
                                name: 'requiredTest',
                                message: <Translate id='form.fields.waiver.required' />,
                                test: (value) => {
                                    return !!value || this.props.selectedRows[0].mandatory == 0
                                },
                            }),
                            signatureType: string().test({
                                name: 'signatureRequiredTest',
                                message: <Translate id='form.fields.waiver.signature.required' />,
                                test: function(value){
                                    if(this.parent.sign){
                                        return !!value
                                    }
                                    return true
                                },
                            }),
                        })}
                        onSubmit={({ sign, signatureType }) => {
                            this.setState(() => ({ isLoading: true }));

                            const newValues = this.getNewValues(sign, signatureType)

                            this.props.WaiversContext.signWaiver(this.props.selectedRows[0].invoice_number, this.props.selectedRows[0].invoice_item_id, this.props.selectedRows[0].waiver_id, {
                                answer: sign,
                                language_code: this.props.I18nContext.getGenericLocale(),
                                signature_type: signatureType,
                            })
                                .then(() => {
                                    this.props.syncRows(newValues);
                                    success({ msg: 'memberProfile.registration.form.waiver.signed' });
                                    this.props.refreshMemberProfile();
                                    this.setState(() => ({ isLoading: false }));
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                        this.setState(() => ({ isLoading: false }));
                                    }
                                })
                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return (
                                    <WaiverSignatureDisplay waiver={this.props.selectedRows[0]} minifiedView />
                                )
                            }
                            return (
                                <>
                                    <WaiverFields waiver={this.props.selectedRows[0]} fieldName='sign' />
                                    <WaiverSignature fieldName='signatureType' answer={formik.values.sign} />
                                </>
                            )

                        }}
                    </FormikEditable>
                    <div className="mt-3">
                        {this.props.selectedRows[0].invoice_type === 'WAIVER' && this.props.selectedRows[0].invoice_origin === 'ADMIN' ?
                            <Translate id='memberProfile.registration.form.registration.manual' />
                            : this.props.selectedRows[0].invoice_type === 'WAIVER' && this.props.selectedRows[0].invoice_origin === 'ONLINE' &&
                            <Translate id='components.manualRegistration.waivers.myAccount' />
                        }
                    </div>
                </div>
            </OverlayLoader>
        )
    }
}

export default withContexts(WaiversContext, AuthContext, I18nContext, MembersContext, OrganizationContext)(MemberProfileWaiverSidepanel);