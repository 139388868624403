import { Container } from "reactstrap";
import settingsTravelPermitsRoutes from "../../../../../routes/tabRoutes/organizationSettings/settingsTravelPermitsRoutes";
import SubRouteSettingWrapper from "../../../components/SubRouteSettingWrapper";

const OrganizationSettingsTravelPermits = () => {
    return (
        <Container>
            <SubRouteSettingWrapper
                back="organization"
                routes={settingsTravelPermitsRoutes}
            />
        </Container>
    );
}

export default OrganizationSettingsTravelPermits