import { FormikSelect } from "@spordle/formik-elements"
import Translate from "@spordle/intl-elements"
import { success } from "@spordle/toasts"
import { Form, Formik } from "formik"
import { useState, useRef, useContext } from "react"
import { Button, Col, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { object, string } from "yup"
import AnalyticsModal from "../../analytics/AnalyticsModal"
import { AxiosIsCancelled } from "../../api/CancellableAPI"
import { I18nContext } from "../../contexts/I18nContext"
import { OrganizationContext } from "../../contexts/OrganizationContext"
import { PositionsContext } from "../../contexts/PositionsContext"
import { DisplayI18n } from "../../helpers/i18nHelper"
import CustomAlert from "../CustomAlert"
import Required from "../formik/Required"
import OverlayLoader from "../loading/OverlayLoader"

const UpdateInvoicePositionModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='updateInvoicePositionModal'>
            <UpdateInvoicePositionModalInner {...props} />
        </AnalyticsModal>
    )
}

const UpdateInvoicePositionModalInner = ({ toggle, invoiceItemPosition, onSuccess, ...props }) => {
    const i18nContext = useContext(I18nContext);
    const positionsContext = useContext(PositionsContext);
    const orgContext = useContext(OrganizationContext);

    const [ positions, setPositions ] = useState(null);

    /**  @type {React.MutableRefObject<import('@spordle/spordle-select/dist/npm/types/components/SpordleSelect').default>} */
    const positionsSelectRef = useRef();

    return (
        <Formik
            initialValues={{
                positionId: invoiceItemPosition.position?.position_id || '',
                positionGroupId: invoiceItemPosition.position_group?.position_group_id || '',
            }}
            validationSchema={object().shape({
                positionId: string(),
                positionGroupId: string().required(<Translate id='components.updateInvoicePositionModal.positionGroup.required' />).test({
                    name: 'positionDifferent',
                    message: <Translate id='components.updateInvoicePositionModal.diff' />,
                    test: function(value){
                        return invoiceItemPosition.position?.position_id !== this.parent.positionId || value !== invoiceItemPosition.position_group?.position_group_id
                    },
                }),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                positionsContext.updateInvoicePosition(invoiceItemPosition.invoice_item_position_id, values)
                    .then(() => {
                        success();
                        onSuccess?.();
                        toggle();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            setSubmitting(false);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggle}>
                            <Translate id='components.updateInvoicePositionModal.header' />
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Label for='positionId' className='text-muted'>
                                            <Translate id='components.updateInvoicePositionModal.positionGroup' /> <Required />
                                        </Label>
                                        <FormikSelect
                                            id='positionGroupId' name='positionGroupId'
                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                            onOptionSelected={(values) => {
                                                positionsSelectRef.current?.getSpordleTable().filterChange('positionGroupId', values[0])
                                            }}
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                            ]}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return positionsContext.getPositionGroups(orgContext.organisation_id)
                                                            .then((positions) => positions.map((positionGroup) => ({
                                                                value: positionGroup.position_group_id,
                                                                label: positionGroup.name,
                                                                i18n: positionGroup.i18n,
                                                            })))
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Label for='positionId' className='text-muted'>
                                            <Translate id='components.updateInvoicePositionModal.position' />
                                        </Label>
                                        <FormikSelect
                                            id='positionId' name='positionId'
                                            ref={positionsSelectRef} clearable
                                            disabled={!formik.values.positionGroupId}
                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                            ]}
                                            initFilter={{
                                                positionGroupId: formik.values.positionGroupId,
                                            }}
                                            loadData={(from, { filters }, spordleTable) => {
                                                switch (from){
                                                    case 'FILTER':
                                                        spordleTable.setLoading();
                                                        return Promise.resolve(positions.filter((position) => position.positionGroupId === filters.positionGroupId))
                                                    case 'CDM':
                                                        return positionsContext.getPositions(orgContext.organisation_id)
                                                            .then((positions) => {
                                                                const formattedPositions = positions.map((position) => ({
                                                                    value: position.position_id,
                                                                    label: position.name,
                                                                    i18n: position.i18n,
                                                                    positionGroupId: position.position_group?.position_group_id,
                                                                }))
                                                                setPositions(formattedPositions)

                                                                if(filters.positionGroupId){
                                                                    return formattedPositions.filter((position) => position.positionGroupId === filters.positionGroupId)
                                                                }

                                                                return formattedPositions
                                                            })
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {formik.status &&
                                <CustomAlert color='danger' className='mt-2' toggle={() => formik.setStatus()} translateText={false} text={formik.status} />
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button type='submit' color='primary' disabled={formik.isSubmitting}><Translate id='misc.confirm' /></Button>
                            <Button color='primary' outline onClick={toggle} type='button' disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default UpdateInvoicePositionModal