import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SidePanel from '../../../../../components/sidePanel/SidePanel';
import UserImg from '../../../../../components/UserImg';
import { MembersContext } from "../../../../../contexts/MembersContext";
import { getTeamMemberStatus } from '../../../TeamHelpers';
import DeleteRosterAction from "../../DeleteRosterAction";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { RolesContext } from "../../../../../contexts/RolesContext";
import { Alert } from "reactstrap";
import { fail } from "@spordle/toasts";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import QuickViewButton from "../../../../../components/quickView/QuickViewButton";
import MemberStatusIcons from "../../../../members/search/components/MemberStatusIcons";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import TeamRosterReleaseModal from "./TeamRosterReleaseModal";
import { useDisclosure } from "@mantine/hooks";
import SendCommModal from "../../../../../components/communications/SendCommModal";
import QuickViewActionButton from "../../../../../components/quickView/QuickViewActionButton";
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy";

/**
 * @param {object} props
 * @param {object} props.teamMember
 * @param {function} props.releaseMember
 * @param {'STAFF'|'INACTIVE'|'AFFILATE'|'ACTIVE'} props.from
 */
const TeamRosterSidepanelHeader = ({ teamMember, releaseMember, from, lockedRoster, rosterType, toggle, tableRef }) => {
    const membersContext = useContext(MembersContext);
    const rolesContext = useContext(RolesContext);
    const identityRolesContext = useContext(IdentityRolesContext)
    const [ memberAddress, setMemberAddress ] = useState(false);
    const [ sendCommIsOpen, sendCommIsOpenHandlers ] = useDisclosure(false);

    const [ isOpen, isOpenHandlers ] = useDisclosure(false);

    useEffect(() => {
        membersContext.getMemberAddresses(teamMember.member.member_id)
            .then((address) => {
                const addr = address.find((addr) => addr.default_address == 1)
                if(addr)
                    setMemberAddress(addr);//`${addr.street_number} ${addr.street} ${addr.city} ${addr.province_id} ${addr.unit_number ? addr.unit_number : ''}`);
                else setMemberAddress(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setMemberAddress(false);
                }
            })
    }, [ teamMember ])

    const getRemoveModalMsg = () => {
        switch (from){
            case 'ACTIVE':
                return 'teams.profile.roster.sidePanel.actions.remove.activeRoster';
            case 'STAFF':
                return 'teams.profile.roster.sidePanel.actions.remove.staffRoster';
            case 'INACTIVE':
                return 'teams.profile.roster.sidePanel.actions.remove.inactiveRoster';
            default:
                break;
        }
    }

    const getAddress = (address) => {
        if(address){
            const formattedAddress = `${address.street_number} ${address.street}, ${address.city}, ${address.province_id} ${address.zip_code}, ${address.country_id}`;
            const url = address.map_url || `https://maps.google.com/?q=${formattedAddress}`;
            return (
                <address className="d-flex">
                    {/* <i className="mdi mdi-map-marker mr-1" /> */}
                    <span>
                        <span>{address.street_number} {address.street}, {address.city}</span>
                        <span className="d-block">{address.province_id}, {address.zip_code}, {address.country_id}</span>
                        <a className="small" href={url} target='_blank' rel='noopener noreferrer'>
                            <Translate id='misc.googlePlaces.googleMaps' /><i className="mdi mdi-launch ml-1" />
                        </a>
                    </span>
                </address>
            )
        }
        return null;
    }

    const affiliate = teamMember.affiliate == 1
    const releaseAffiliatedPlayers = !affiliate || (affiliate && rolesContext.canDoAction('EDIT', 'teams', 'release_affiliate_players')) || identityRolesContext.isGod()

    return (
        <div className="p-3">
            <TeamRosterReleaseModal
                isOpen={isOpen}
                toggle={isOpenHandlers.toggle}
                onConfirm={() => {
                    tableRef.refreshTable();
                }}
                teamMember={teamMember}
                releaseMember={releaseMember}
            />

            <div className='d-flex mb-2'>
                <SidePanel.ToggleButton />
                {
                    (rolesContext.canDoAction('ADD', 'communications', 'communications_manage') ||
                    identityRolesContext.isGod() ||
                    ((!lockedRoster || rolesContext.canDoAction('DELETE', 'teams', 'bypass_lock_roster_date')) &&
                    (rolesContext.canDoAction('DELETE', 'teams', 'team_members') || rolesContext.canDoAction('EDIT', 'teams', 'manage_release_players')))) &&
                        <SidePanel.ActionsMenu>
                            <SidePanel.MenuAction
                                action="ADD"
                                componentCode='communications'
                                componentPermissionCode='communications_manage'
                                onClick={sendCommIsOpenHandlers.open}
                            >
                                <SendCommModal
                                    isOpen={!!sendCommIsOpen}
                                    toggle={sendCommIsOpenHandlers.close}
                                    recipients={[ teamMember.member ]}
                                />
                                <Translate id="communications.sendMessage" />
                            </SidePanel.MenuAction>
                            {releaseAffiliatedPlayers && from !== 'INACTIVE' &&
                                <SidePanel.MenuAction
                                    action='EDIT' componentCode='teams' componentPermissionCode='manage_release_players'
                                    onClick={() => isOpenHandlers.toggle()}
                                >
                                    <Translate id='teams.profile.roster.sidePanel.actions.release' />
                                </SidePanel.MenuAction>
                            }
                            {/* Can't remove a player that has been released */}
                            {/* If a player is released, it means they have been part of the team and have played, so they need to stay released */}
                            {(from !== 'INACTIVE' && from !== 'AFFILATE') &&
                                <DeleteRosterAction msg={getRemoveModalMsg()} teamMember={teamMember} />
                            }
                            <QuickViewActionButton member={teamMember.member} />
                        </SidePanel.ActionsMenu>
                }
            </div>

            {/*--------------------------------------------------------------------------------*/}
            {/* Team Member Header                                                                */}
            {/*--------------------------------------------------------------------------------*/}
            <div className="d-flex align-items-center">
                <UserImg
                    alt={teamMember.member.first_name + ' ' + teamMember.member.last_name}
                    abbr={teamMember.member.first_name.charAt(0) + teamMember.member.last_name.charAt(0)}
                    src={teamMember.member.picture?.full_path}
                    filePos={teamMember.member.picture?.file_position}
                    width={60}
                    className="mr-2"
                />
                <div className="w-100">
                    <div className="d-flex align-items-center text-break">
                        <span className='h4 font-bold mb-0'>
                            {from == 'INACTIVE' &&
                                <div className="text-muted small font-medium"><i className="mr-1 mdi mdi-alert-circle-outline" /><Translate id={`teams.profile.roster.sidePanel.rosterType.${rosterType}`} /></div>
                            }
                            <QuickViewButton member={teamMember.member}>
                                {teamMember.member.first_name} {teamMember.member.last_name}
                            </QuickViewButton>
                        </span>
                        <MemberStatusIcons member={{ ...teamMember.member, crc_is_expired: from == 'STAFF' ? teamMember.crc_is_expired : 0 }} />
                    </div>

                    <div>
                        <InlineCopy toCopy={teamMember.member.unique_identifier}>
                            <Link className="text-nowrap" to={`/members/profile/${teamMember.member.member_id}`}>
                                {teamMember.member.unique_identifier ?
                                    <>#{teamMember.member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                    :
                                    '-'
                                }
                            </Link>
                        </InlineCopy>
                        {teamMember.status &&
                            <div>{getTeamMemberStatus(teamMember.status)}</div>
                        }
                    </div>
                </div>
            </div>
            <div className="mt-3 mb-2">
                {getAddress(memberAddress)}
            </div>

            {teamMember.api_partner &&
                <Alert color="info">
                    <i className="mdi mdi-information-outline mr-2" />
                    <Translate id='misc.apiPartner' />: {teamMember.api_partner.name}
                </Alert>
            }
            {lockedRoster &&
                <Alert color="secondary"><i className="w-100 mr-1 mdi mdi-lock-outline" /><Translate id="teams.profile.roster.locked" /></Alert>
            }
        </div>
    )
}

export default TeamRosterSidepanelHeader