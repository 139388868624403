import { Button, ModalBody, ModalFooter } from 'reactstrap';
import Translate from '@spordle/intl-elements';
import UserDisplay from '../../../../../../../components/userDisplay/UserDisplay';
import { stringBuilder } from '@spordle/helpers';
import Svg from '../../../../../../../components/Svg';
import { useContext } from 'react';
import { MembersContext } from '../../../../../../../contexts/MembersContext';

const MemberProfileRegistrationAddManualSelectIdentity = ({ registrationContext }) => {
    const membersContext = useContext(MembersContext)

    return (
        <>
            <ModalBody>
                <div className="h4 font-bold mb-0"><Translate id='memberProfile.registration.manual.select.title' /></div>
                <div className='mb-3'>
                    <Translate
                        id='memberProfile.registration.manual.select.subtitle'
                        values={{
                            member: <b>{membersContext.currentMember.first_name} {membersContext.currentMember.last_name}</b>,
                        }}
                    />
                </div>

                <OptionsButton
                    icon="members"
                    title={<Translate id='memberProfile.registration.manual.select.manual' />}
                    onClick={() => registrationContext.manualSelectIdentityOnSubmit(true)}
                >
                    <Translate id='memberProfile.registration.manual.select.manual.desc' />
                </OptionsButton>
                <OptionsButton
                    icon="addMember"
                    svgProps={{ viewBox: "0 0 45 45" }}
                    title={<Translate id='memberProfile.registration.manual.select.identity' />}
                    onClick={() => registrationContext.manualSelectIdentityOnSubmit(false)}
                >
                    <Translate id='memberProfile.registration.manual.select.identity.desc' />
                </OptionsButton>
            </ModalBody>
            <ModalFooter className='justify-content-start'>
                <Button color='primary' outline type='button' onClick={() => registrationContext.goToView(registrationContext.views.registrationInfo, true)}><Translate id='misc.previous' /></Button>
            </ModalFooter>
        </>
    )
}

const OptionsButton = ({ icon, className, title, children, svgProps = {}, ...props }) => {
    return (
        <UserDisplay hover card className={stringBuilder('d-flex mb-2', className)} {...props}>
            <UserDisplay.Container className="mr-3">
                <div style={{ height: 45, width: 45 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                    <Svg icon={icon || "members"} {...svgProps} />
                </div>
            </UserDisplay.Container>
            <UserDisplay.Container>
                <div className="h5 mb-0 font-bold">{title}</div>
                {children}
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default MemberProfileRegistrationAddManualSelectIdentity