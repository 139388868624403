import { useContext, useEffect, useState } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { QuickViewContext } from '../../contexts/QuickViewContext';

import { stringBuilder } from '@spordle/helpers';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Translate from '@spordle/intl-elements';
import { Tooltip } from '@mantine/core';
import * as Sentry from "@sentry/react";
import { setTag } from '@sentry/react';

const QuickViewInnerWrapper = (props) => {
    const history = useHistory();

    const {
        inIframe,
        activeTabState,
        quickViewTabs,
        toggle,
        setCurrentTabindex,
        closeQuickViewTab,
        reorderQuickViewTabs,
        closeAllQuickTabs,
        refreshIframeContent,
    } = useContext(QuickViewContext);

    /**
     * Default when not n iframe
    */
    if(!inIframe)
        return props.children;

    /**
     * State & Functions managing quickview tabs drag and drop
    */
    const [ _, setIsDraging ] = useState(false);
    const onDragTabStart = (_) => setIsDraging(true);
    const onDragTabEnd = (data) => {
        setIsDraging(false);
        if(!!data?.source && !!data?.destination){
            const tabIndex = data.source.index;
            const destinationIndex = data.destination.index;
            reorderQuickViewTabs(tabIndex, destinationIndex)
        }
    }

    useEffect(() => setTag('quickview', 1), [])

    /**
     * Will prevent showing the actual /404 in the quickview
     * Quickview will only show a white page in this case - less layout shift and glitchy visuals
    */
    if(history.location.pathname === "/404")
        return <div />

    return (
        <>
            <div id="quickview-tab-header" className="p-0">
                <header id="quickview-tab-container" className='quick-view-header d-flex justify-content-start bg-light'>

                    {/*--------------------------------------------------------------------------------*/}
                    {/* Dropdown for all the opened tabs                                               */}
                    {/*--------------------------------------------------------------------------------*/}
                    <UncontrolledDropdown id="quickview-tab-dropdown-list" tag="div">
                        <DropdownToggle
                            style={{ zIndex: 11 }}
                            tag="div"
                            className={stringBuilder('quick-view-tab-dropdown my-1 ml-1 py-2 px-3 clickable position-relative font-medium')}
                        >
                            <div className="d-flex align-items-start line-height-1">
                                <div>{quickViewTabs.length}</div>
                                <div className="clickable"><i className="mdi mdi-chevron-down font-14" /></div>
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className="quick-view-tab-dropdown-menu shadow-lg px-0 pt-1">
                            <DropdownItem header>
                                <Translate id="misc.quickview.allTabs" />
                            </DropdownItem>
                            {quickViewTabs.map((tab, index) => (
                                <DropdownItem
                                    key={tab.member_id + '-' + activeTabState.tabIndex}
                                    className={stringBuilder({ 'is-active': activeTabState.tabIndex === index }, "quick-view-tab-dropdown-item")}
                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); setCurrentTabindex(index) }}
                                >
                                    <div className="quick-view-tab-dropdown-item-inner d-flex justify-content-between align-items-center line-height-1">
                                        <div>{tab.first_name} {tab.last_name}</div>
                                        <div className="ml-3 clickable" onClick={(e) => { e.preventDefault(); e.stopPropagation(); closeQuickViewTab(index) }}><i className="mdi mdi-close font-14" /></div>
                                    </div>
                                </DropdownItem>
                            ))}

                            <DropdownItem divider className="mt-0" />
                            <DropdownItem onClick={closeAllQuickTabs}>
                                <Translate id="misc.quickview.closeAllTabs" />
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>


                    {/*--------------------------------------------------------------------------------*/}
                    {/* Loop through and show each tab                                                 */}
                    {/*--------------------------------------------------------------------------------*/}
                    <DragDropContext onDragStart={onDragTabStart} onDragEnd={onDragTabEnd}>
                        <Droppable droppableId="quickview-tab-droppable" direction="horizontal">
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="d-flex justify-content-start min-w-0 w-100 quick-view-tabs-container"
                                >
                                    {quickViewTabs.map((tab, index) => (
                                        <Draggable key={tab.member_id + '-' + activeTabState.tabIndex} draggableId={tab.member_id + '-' + activeTabState.tabIndex} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    key={tab.member_id + '-' + activeTabState.tabIndex}
                                                    className={stringBuilder('quick-view-tab mt-1 ml-1 py-2 pl-2 pr-1 clickable', { 'is-active': activeTabState.tabIndex === index, 'is-dragging': snapshot.isDragging })}
                                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); setCurrentTabindex(index) }}
                                                >
                                                    <div className="quick-view-tab-inner d-flex align-items-center line-height-1">
                                                        <div className='quick-view-tab-name overflow-hidden position-relative'>
                                                            <div className='text-nowrap pr-2'>{tab.first_name} {tab.last_name}</div>
                                                        </div>
                                                        <div className="clickable" onClick={(e) => { e.preventDefault(); e.stopPropagation(); closeQuickViewTab(index) }}><i className="mdi mdi-close font-14" /></div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {/*--------------------------------------------------------------------------------*/}
                    {/* Add button that opens a quick search                                           */}
                    {/*--------------------------------------------------------------------------------*/}
                    {/* <div id="quickview-tab-add-button" className={stringBuilder('transition my-1 ml-1 py-2 px-3 clickable font-medium ', { 'card shadow': addHovered })} onMouseEnter={() => setAddHovered(true)} onMouseLeave={() => setAddHovered(false)}>
                        <div className="d-flex align-items-start line-height-1">
                            <div className="clickable"><i className="mdi mdi-plus font-14" /></div>
                        </div>
                    </div> */}

                    <div className='ml-auto'>
                        <div className='d-flex'>
                            <div className="p-1">
                                <Tooltip zIndex={3000} withArrow label={<Translate id='misc.refresh' />} position='left'>
                                    <button type="button" className="py-1 px-2 close quick-view-tab-dropdown" onClick={refreshIframeContent}><span aria-hidden="true"><i className='mdi mdi-refresh font-18' /></span></button>
                                </Tooltip>
                            </div>

                            <div className="p-1">
                                <Tooltip zIndex={3000} withArrow label={<Translate id='misc.quickview.collapseQuickView' />} position='left'>
                                    <button
                                        type="button"
                                        className="py-1 px-2 close quick-view-tab-dropdown"
                                        data-tip="misc.quickview.collapseQuickView"
                                        data-for="quick-view-header-tips"
                                        onClick={() => {
                                            Sentry.addBreadcrumb({
                                                type: 'info',
                                                message: 'Collapse quickview window ',
                                                level: Sentry.Severity.Log,
                                                category: 'QuickView',
                                            })
                                            toggle();
                                        }}
                                    >
                                        <span aria-hidden="true">
                                            <i className='mdi mdi-window-minimize font-18' />
                                        </span>
                                    </button>
                                </Tooltip>
                            </div>

                            {/* <div className="p-1">
                                <ConfirmModal
                                    translateModalTitle
                                    translateActionButtonText
                                    modalTitle={"misc.quickview.closeAllTabs.confirm"}
                                    onConfirm={closeAllQuickTabs}
                                    actionButtonText={"misc.confirm"}
                                />
                            </div> */}
                        </div>
                    </div>

                </header>
            </div>
            <Card body className="m-0 p-0">
                {props.children}
            </Card>
        </>
    )
}

export default withRouter(QuickViewInnerWrapper);