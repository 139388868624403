import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";

const MemberTeamStatus = ({ tip, ...props }) => {
    return (
        <Tooltip withArrow label={<Translate id={tip} />} zIndex="9999">
            <span {...props} />
        </Tooltip>
    )
}

export default MemberTeamStatus;