import React from "react";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import { Badge, Button, FormGroup, Label } from "reactstrap";
import { object, string } from 'yup';

// Contexts
import { MembersContext } from '../../../../../../contexts/MembersContext';
import withContexts from '../../../../../../helpers/withContexts';

// Language
import Translate, { DateFormat } from "@spordle/intl-elements";
import FormikEditable from "../../../../../../components/formik/FormikEditable";
import { FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { formatFileName, getIcon } from "../../../../../../components/uploader/uploadHelpers";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
import FileViewer from "../../../../../../components/fileViewer/FileViewer";
import MemberAttachmentApprobationModal from "../../../../../tasks/memberAttachmentApprobation/sidePanel/MemberAttachmentApprobationModal";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import { OTHER_VALUE } from "./ModalAddMemberDocuments";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import CrossFade from "../../../../../../components/crossFade/CrossFade";

class SidePanelMemberDocuments extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,

            fileViewerOpen: false,
            manageApprovalOpen: false,
        }
    }

    getDownloadableFile(){
        this.setState({ isLoading: true });
        return this.props.MembersContext.getMemberAttachmentDownloadLink(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_attachement_id)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
            .finally(() => this.setState({ isLoading: false }))
    }

    handleDelete = () => {
        return this.props.MembersContext.deleteMemberAttachment(this.props.MembersContext.currentMember.member_id, this.props.selectedRows[0].member_attachement_id)
            .then(() => {
                this.props.MembersContext.getAllMemberInfos(this.props.MembersContext.currentMember.member_id)
                this.props.tableRef.deleteRow(this.props.selectedRows[0].member_attachement_id)
                success();
                this.props.toggle();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }


    render(){
        const documentTypeNeedsApproval = this.props.documentTypesNeedingApproval.includes(this.props.selectedRows[0].document_type?.system)
        return (
            <RolesContext.Consumer>
                {({ canDoAction }) => (
                    <OverlayLoader isLoading={this.state.isLoading}>
                        <SidePanel.Header>
                            {(documentTypeNeedsApproval && this.props.selectedRows[0].review_status === 'PENDING' && this.state.manageApprovalOpen) &&
                                <MemberAttachmentApprobationModal
                                    toggle={() => this.setState((prevState) => ({ manageApprovalOpen: !prevState.manageApprovalOpen }))}
                                    isOpen={this.state.manageApprovalOpen}
                                    selectedRows={this.props.selectedRows || []}
                                    getBadgeColour={this.props.getBadgeColour}
                                    spordleTable={this.props.tableRef}
                                    forceCloseSidePanel={this.props.forceCloseSidePanel}
                                    memberProfileMode
                                />
                            }
                            <div className='d-flex mb-3 align-items-center'>
                                <SidePanel.ToggleButton />
                                {(canDoAction('DELETE', 'members', 'members_documents') || (documentTypeNeedsApproval && this.props.selectedRows[0].review_status === 'PENDING')) &&
                                    <SidePanel.ActionsMenu label="misc.action">
                                        {(documentTypeNeedsApproval && this.props.selectedRows[0].review_status === 'PENDING') &&
                                            <SidePanel.MenuAction
                                                action="EDIT" componentCode="members" componentPermissionCode="system_document_type_need_approbation"
                                                onClick={() => { this.setState(() => ({ manageApprovalOpen: true })); }}
                                            >
                                                <Translate id='task.document.approval.manage.approval' />
                                            </SidePanel.MenuAction>
                                        }
                                        <SidePanel.MenuDelete
                                            modalTitle={this.props.selectedRows[0].attachment.file_name}
                                            modalMsg='members.profile.overview.memberDocuments.sidePanel.delete.msg'
                                            translateModalMsg
                                            onConfirm={this.handleDelete}
                                            action='DELETE' componentCode='members' componentPermissionCode='members_documents'
                                        />
                                    </SidePanel.ActionsMenu>
                                }
                            </div>
                            <SidePanel.Title><Translate id='members.profile.overview.memberDocuments.form.label.document' /></SidePanel.Title>
                            <SidePanel.Subtitle>{this.props.selectedRows[0].attachment.file_name}</SidePanel.Subtitle>
                            {documentTypeNeedsApproval &&
                                <SidePanel.Subtitle>
                                    <Badge
                                        color={this.props.getBadgeColour(this.props.selectedRows[0].review_status)}
                                    >
                                        <Translate id={`task.document.approval.status.${this.props.selectedRows[0].review_status}`} />
                                    </Badge>
                                </SidePanel.Subtitle>
                            }
                        </SidePanel.Header>
                        <div className="p-3">
                            <div
                                onClick={() => !this.state.isLoading && this.setState({ fileViewerOpen: true })}
                                style={{ height: 150, width: 150 }}
                                className="mb-3 rounded-lg overflow-hidden border shadow-sm clickable card-hover d-flex align-items-center justify-content-center position-relative"
                            >
                                <FileViewer
                                    key={this.props.selectedRows[0].attachment.attachement_id}
                                    fullPath={() => this.getDownloadableFile()}
                                    createdBy={this.props.selectedRows[0].identity}
                                    creationDate={<DateFormat value={this.props.selectedRows[0].created_at} format={DateFormat.formats.longMonthDayYearTime} />}
                                    fileName={this.props.selectedRows[0].attachment.file_name}
                                    type={this.props.selectedRows[0].attachment.file_ext}
                                    isOpen={this.state.fileViewerOpen}
                                    close={() => this.setState({ fileViewerOpen: false })}
                                />
                                <i style={{ fontSize: '40px' }} className={getIcon(this.props.selectedRows[0].attachment.file_ext)} />
                                <i className="mdi mdi-download position-absolute text-primary top-0 right-0 font-20 p-1" />
                                <span className="small font-medium text-dark bg-light-inverse position-absolute bottom-0 left-0 right-0 text-center p-1">
                                    <span className="d-flex justify-content-center">{formatFileName(this.props.selectedRows[0].attachment.file_name)}</span>
                                    <span className="text-muted"><DateFormat value={this.props.selectedRows[0].created_at} utc /></span>
                                </span>
                            </div>
                            <FormGroup>
                                <Label className="text-muted mb-0"><Translate id='members.profile.overview.memberDocuments.form.label.documentName' /></Label>
                                <div className="font-medium text-dark">{this.props.selectedRows[0].attachment.file_name}</div>
                            </FormGroup>
                            {this.props.selectedRows[0].expiration_date &&
                                <FormGroup>
                                    <Label tag="div" className="text-muted"><Translate id='members.profile.overview.memberDocuments.form.label.expiration_date' /></Label>
                                    <div className="font-medium text-dark"><DateFormat value={this.props.selectedRows[0].expiration_date} /></div>
                                </FormGroup>
                            }
                            <FormGroup>
                                <FormikEditable
                                    id='form-document_type_and_note'
                                    disabled={!canDoAction('EDIT', 'members', 'members_documents') || !!this.props.selectedRows[0].document_type?.system}
                                    readOnly={!!this.props.selectedRows[0].document_type?.system}
                                    initialValues={{
                                        document_type_id: this.props.selectedRows[0].document_type ? this.props.selectedRows[0].document_type?.document_type_id : OTHER_VALUE,
                                        note: this.props.selectedRows[0].note || '',
                                    }}
                                    validationSchema={object().shape({
                                        document_type_id: string().required(<Translate id='members.profile.overview.memberDocuments.form.validation.documentType.required' />),
                                        note: string()
                                            .when('document_type_id', {
                                                is: (val) => val === OTHER_VALUE,
                                                then: string().required(<Translate id='members.profile.overview.memberDocuments.form.document_type.note.required' />),
                                                otherwise: string(),
                                            }),
                                    })}
                                    onSubmit={(values) => {
                                        if(values.document_type_id !== this.props.selectedRows[0].document_type?.document_type_id || values.note !== this.props.selectedRows[0].note){
                                            this.setState({ isLoading: true });
                                            const newValues = this.props.createNewValues({
                                                document_type: this.props.documentTypes.find(({ document_type_id }) => document_type_id === values.document_type_id),
                                                note: values.note,
                                            });
                                            this.props.MembersContext.partiallyUpdateMemberAttachment(
                                                this.props.MembersContext.currentMember.member_id,
                                                this.props.selectedRows[0].member_attachement_id,
                                                { document_type_id: values.document_type_id === OTHER_VALUE ? '' : values.document_type_id, note: values.note }, // other document type is actually no document type, send null in this case
                                            )
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    success();
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error);
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                                .finally(() => this.setState({ isLoading: false }))
                                        }
                                    }}
                                >
                                    {(isEditing, _stopEditing, formik) => {
                                        if(!isEditing){
                                            return (
                                                <div className="text-dark">
                                                    <div><Label for="document_type_id" className='text-muted mb-0'><Translate id='members.profile.overview.memberDocuments.form.label.documentType' /></Label></div>
                                                    <span className="font-medium">
                                                        {this.props.selectedRows[0].document_type ?
                                                            <>
                                                                <DisplayI18n
                                                                    field="name"
                                                                    defaultValue={this.props.selectedRows[0].document_type?.name}
                                                                    i18n={this.props.selectedRows[0].document_type?.i18n}
                                                                />
                                                                <div className="small text-muted font-normal" dangerouslySetInnerHTML={{ __html: displayI18n("description", this.props.selectedRows[0].document_type?.i18n, this.props.selectedRows[0].document_type?.description, this.props.I18nContext.getGenericLocale()) }} />
                                                            </>
                                                            :
                                                            <Translate id='misc.other' />
                                                        }
                                                    </span>
                                                    {this.props.selectedRows[0].note &&
                                                        <>
                                                            <div className="mt-3"><Label for="document_note" className='text-muted mb-0'><Translate id='members.profile.overview.memberDocuments.form.label.note' /></Label></div>
                                                            <span className="font-medium">{this.props.selectedRows[0].note}</span>
                                                        </>
                                                    }
                                                </div>
                                            )
                                        }
                                        return (
                                            <>
                                                <div>
                                                    <Label for="document_type_id" className='text-muted mb-0'><Translate id='members.profile.overview.memberDocuments.form.label.documentType' /></Label>
                                                    <FormikSelect
                                                        name="document_type_id"
                                                        id="document_type_id"
                                                        searchKeys={[
                                                            `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                            'label',
                                                        ]}
                                                        renderOption={({ option }) => option.value !== OTHER_VALUE && <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />}
                                                        options={this.props.documentTypes ?
                                                            [
                                                                ...this.props.documentTypes
                                                                    .filter((docType) => !docType.system)
                                                                    .map((type) => ({
                                                                        value: type.document_type_id,
                                                                        label: type.name,
                                                                        i18n: type.i18n,
                                                                    })),
                                                                { value: OTHER_VALUE, label: 'misc.other', translateLabel: true },
                                                            ]
                                                            :
                                                            []
                                                        }
                                                        onOptionSelected={(values, spordleSelect) => {
                                                            if(values[0] !== OTHER_VALUE){
                                                                formik.setFieldValue('note', '')
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <CrossFade isVisible={formik.values.document_type_id === OTHER_VALUE}>
                                                    <Label for="document_note" className='text-muted mb-0 pt-3'><Translate id='members.profile.overview.memberDocuments.form.label.note' /></Label>
                                                    <FormikTextArea id='document_note' name='note' trim autoFocus />
                                                </CrossFade>
                                            </>
                                        )

                                    }}
                                </FormikEditable>
                            </FormGroup>
                            {(documentTypeNeedsApproval && this.props.selectedRows[0].review_status === 'PENDING') &&
                                <CanDoAction
                                    tag={Button}
                                    outline color="primary"
                                    className="w-100 mt-4"
                                    action="EDIT" componentCode="members" componentPermissionCode="system_document_type_need_approbation"
                                    onClick={() => { this.setState(() => ({ manageApprovalOpen: true })); }}
                                >
                                    <Translate id='task.document.approval.manage.approval' />
                                </CanDoAction>
                            }
                        </div>
                    </OverlayLoader>
                )}
            </RolesContext.Consumer>
        )
    }
}

export default withContexts(MembersContext, I18nContext)(SidePanelMemberDocuments);