import React, { createContext } from 'react';
import { OrganizationContext } from './OrganizationContext';
import { PeriodsContext } from './contexts';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';

/** @type {React.Context<Omit<OtherFeesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & OtherFeesContextProvider['state']>} */
export const OtherFeesContext = createContext(); OtherFeesContext.displayName = 'OtherFeesContext';

class OtherFeesContextProvider extends React.PureComponent{
    state = {};

    /**
     * Gets all the organization's other items
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Other%20Fees/Apicontroller%5CPricing%5COtherfees%3A%3AgetAllOtherFees|documentation}
     * @returns {Promise.<Array>}
     */
    getOtherItems = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/other-fees',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.other_fees;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get specific other item
     * @param {string} otherFeeId
     * @param {object} queryParams
     * @returns {Promise.<object>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Other%20Fees/Apicontroller%5CPricing%5COtherfees%3A%3AgetSpecificOtherFee|Documentation}
     */
    getOtherItem = (otherFeeId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/other-fees/${otherFeeId}`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.other_fees[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates an other item
     * @param {object} [formData] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Other%20Fees/Apicontroller%5CPricing%5COtherfees%3A%3AcreateOtherFees|documentation}
     * @param {object} [i18n] The query params for that call
     * @returns {Promise.<string>} Returns the fee_id created
     */
    createOtherItems = (formData) => {
        const validData = new FormData();

        validData.append('organisation_id', this.props.OrganizationContext.organisation_id)

        for(const key in formData){
            switch (key){
                default: // name, descritpion, etc
                    validData.append(key, formData[key]);
                    break;
            }
        }

        return API_SPORDLE.post('/other-fees', validData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.other_fee_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes an other item
     * @param {string} otherFeeId The fee id to delete - Refer to the {@link https://api.id.spordle.dev/documentations/#/Other%20Fees/Apicontroller%5CPricing%5COtherfees%3A%3AdeleteOtherFee|documentation}
     * @returns {Promise}
     */
    deleteOtherItems = (otherFeeId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/other-fees/${otherFeeId}` }))
            .then((response) => {
                if(response.data.status){
                    return;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Creates an img attachment for an other fee
     * @param {string} otherFeeId
     * @param {object} values
     * @returns {Promise.<string>} Returns the id of the created img
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Other%20Fees/Apicontroller%5CPricing%5COtherfees%3A%3AupdateOtherFeeImage|Documentation}
     */
    createOtherItemImg = (otherFeeId, values) => {
        const params = new FormData();

        params.append('image', values.image);
        if(values.file_position) params.append('file_position', values.file_position);

        return API_SPORDLE.post(`/other-fees/${otherFeeId}/images`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.image;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Img of other item
     * @param {string} otherFeeId
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Other%20Fees/Apicontroller%5CPricing%5COtherfees%3A%3AdeleteOtherFeeImage|Documentation}
     * @returns {Promise.<boolean>}
     */
    deleteOtherItemImg = (otherFeeId) => (
        API_SPORDLE.delete(queryString.stringifyUrl({ url: `/other-fees/${otherFeeId}/images` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * partially updates an other item
     * @param {string} otherFeeId The fee id to update - Refer to the {@link https://api.id.spordle.dev/documentations/#/Other%20Fees/Apicontroller%5CPricing%5COtherfees%3A%3AupdateOtherFee|documentation}
     * @param {object} [values] The values to update
     * @param {string} [lang] Language variable corresponding to the values - Used for the I18N logic
     * @param {object} [i18n] Object containing an array of all the languages available and an object of the initialValues for language variables - Used for the I18N logic
     * @returns {Promise.<any>}
     */
    updateOtherItems = (otherFeeId, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'status':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                case 'price':
                    params.append('amount', values[key]);
                    break;
                default: // name, descritpion, etc
                    params.append(key, values[key]);
                    break;
            }
        }
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/other-fees/${otherFeeId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <OtherFeesContext.Provider value={{ ...this.state, ...this }}>
                {this.props.children}
            </OtherFeesContext.Provider>
        )
    }
}

export default withContexts(PeriodsContext, OrganizationContext)(OtherFeesContextProvider);