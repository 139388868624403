import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card,
    Col, Row } from 'reactstrap';
import ColumnDefineButton from '../../../components/reports/ColumnDefineButton';
import ReportLink, { accesses } from '../../../components/reports/ReportLink';
import TablePagination from "../../../components/table/TablePagination";
import { ReportsContext } from '../../../contexts/ReportsContext';
import ReportsEngineFiltersCard from './ReportsEngineFiltersCard';
import { AppContext } from '../../../contexts/contexts';
import ExportDropdown from '../../../components/reports/ExportDropdown';
import Skeleton from 'react-loading-skeleton';
import { buildDataClassName, removeNullValues } from '../../../helpers/reportsHelper';
import ReportHeader from '../../../components/reports/ReportHeader';
import queryString from 'query-string';
import { getReportQueryParamsFromFilters } from './reportsEngineHelpers';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import generatePassword from '../../../helpers/passwordGenerator';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import SaveReports from '../../../components/reports/SaveReports';
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import moment from 'moment';
import ReportEngineError from '../../../components/reports/ReportEngineError';
import { useReportsSWR } from '../../../components/reports/useReportsSWR';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { stringBuilder } from '@spordle/helpers';
import ReportEngineContactSupport from '../../../components/reports/ReportEngineContactSupport';
import { Tooltip } from '@mantine/core';
import StatusBadge from '../../../components/badges/StatusBadge';

const ReportsEngine = () => {
    const { currentReport, ...reportsContext } = useContext(ReportsContext);
    const { updateRecentlyViewed } = useContext(AppContext);
    const { reportPath } = useParams();
    const { pathname } = useLocation();
    const { data: reportsList } = useReportsSWR();
    const history = useHistory();

    const formikRef = useRef();
    const spordleTableRef = useRef(null);

    const [ filtersHaveChanged, setFiltersHaveChanged ] = useState(true);
    const [ hasMoreRows, setHasMoreRows ] = useState(false);
    const [ customFormId, setCustomFormId ] = useState('')
    const [ filtersCardLoaded, setFiltersCardLoaded ] = useState(false);
    const [ columnsLoaded, setColumnsLoaded ] = useState(false);
    const [ isOpen, isOpenHandlers ] = useDisclosure(false);

    //query params for getting the custom report id
    const queryParams = queryString.parse(window.location.search);

    const sortAndFilterColumns = (cols) => {
        return cols
            .sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))
            .reduce((allCols, column) => {
                const isCustomAndActive = queryParams.custom_report_id && column.active == 1;
                const canBeDisplayed = !queryParams.custom_report && column.display_on_screen == 1 && column.active == 1 && column.deprecated != 1;
                const isDeprecated = column.deprecated == 1;

                if(isCustomAndActive || canBeDisplayed){
                    allCols.push({
                        label: isDeprecated ?
                            <div>
                                <DisplayI18n field='name' defaultValue={column.name} i18n={column.i18n} />
                                <div className="text-muted small">
                                    <Translate id='misc.deprecated' />
                                </div>
                            </div>
                            : <DisplayI18n field='name' defaultValue={column.name} i18n={column.i18n} />,
                        key: column.code,
                        mobile: true,
                        sortable: true,
                        dataClassName: stringBuilder({ "bg-light": isDeprecated }, buildDataClassName(column.type)),
                        className: stringBuilder({ 'bg-light': isDeprecated }),
                        deprecated: column.deprecated,
                        hasTotal: column.footer_method ? (data) => (data[column.code] || 0) : false,
                    })
                }

                return allCols;
            }, [])
    }

    const parseJSON = (jsonString) => {
        try{
            const parsed = JSON.parse(jsonString);
            return parsed;
        }catch(e){
            return {};
        }
    }

    const handleGetReportHelper = async(skipLoading) => {
        spordleTableRef.current?.setLoading();
        if(!skipLoading){
            reportsContext.setCurrentReport({});
        }
        setColumnsLoaded(false);

        const report = reportsList.find((report) => parseJSON(report.parameter)?.route == reportPath);

        if(!report){
            history.push('/reports/404');
        }else{
            return reportsContext.getSpecificReport({
                report_id: report.report_id,
                custom_report_id: queryParams.custom_report_id,
                custom_form_id: customFormId,
            })
                .then(async(specificReport) => {
                    await reportsContext.setCurrentReport(specificReport);
                    spordleTableRef.current?.setColumns(sortAndFilterColumns(specificReport.headers));
                    spordleTableRef.current?.setSuccess();
                    setColumnsLoaded(true);

                    updateRecentlyViewed({
                        label: specificReport.name,
                        path: pathname,
                        breadcrumbs: [ { path: '/reports', name: 'sidebar.reports' } ],
                        i18n: specificReport.i18n,
                    });
                })
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        history.push('/reports/404');
                        console.error(e);
                    }
                })
        }

    }

    const renderTableRow = (columnKey, data, spordleTable) => {
        return (
            <ReportLink columnKey={columnKey} rowData={data} spordleTable={spordleTable}>
                {
                    (() => {
                        const header = currentReport.headers?.find((header) => header.code == columnKey);

                        switch (header?.type){
                            case 'MONEY':
                                return !data[columnKey] ? <div className='text-right'><CurrencyFormat classname='text-right' value={0} /></div> : <div className='text-right'><CurrencyFormat classname='text-right' value={data[columnKey] / 100} /></div>
                            case 'STRING':
                                return data[columnKey];
                            case 'DATE':
                                return data[columnKey] ? moment(data[columnKey]).format('YYYY-MM-DD') : '';
                            case 'TIME':
                                return data[columnKey] ? moment(data[columnKey]).format('HH:mm:ss') : '';
                            case 'DATETIME':
                                return data[columnKey] ? moment(data[columnKey]).format('YYYY-MM-DD HH:mm') : '';
                            case 'NUMBER':
                                return (data[columnKey] == 0 ? '0' : data[columnKey]);
                            case 'BOOLEAN':
                                switch (data[columnKey]){
                                    case 1:
                                    case '1':
                                        return <i className='mdi mdi-check text-primary' />;
                                    case 0:
                                    case '0':
                                        return <i className='mdi mdi-close text-danger' />;
                                    default:
                                        return '-'
                                }
                            case 'BADGE':
                                return (
                                    <StatusBadge
                                        color={data[columnKey + '_color']}
                                        status={{ i18n: {
                                            en: { name: data[columnKey] },
                                            fr: { name: data[columnKey] },
                                        } }}
                                    />
                                )
                            case 'STRING_SPLIT':
                                return (
                                    data[columnKey]
                                        ?
                                        <ul>
                                            {data[columnKey]?.split('|').map((value, index) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                                <li key={index}>{value}</li>
                                            ))}
                                        </ul>
                                        :
                                        null
                                )
                            default:
                                return data[columnKey];
                        }
                    })()
                }
            </ReportLink>
        )
    }

    // this useEffect handles the loading of the column headers and the current report object
    useLayoutEffect(() => {
        if(Array.isArray(reportsList) && reportsList.length > 0){
            handleGetReportHelper(false);
        }
    }, [ queryParams.custom_report_id, reportsList ]);

    useEffect(() => {
        if(Array.isArray(reportsList) && reportsList.length > 0){
            handleGetReportHelper(true);
            spordleTableRef.current?.setData([], 'success');
        }
    }, [ customFormId ]);

    // this useEffect handles the loading  state and whether or not we want to load the data onMount of the page or only once the search button is clicked
    useEffect(() => {
        if((columnsLoaded && filtersCardLoaded && Array.isArray(currentReport.filters))){

            // shouldSubmit checks if all required filters have an initialValue in formik
            const shouldSubmit = (currentReport.filters)
                .filter((filter) => filter.required == '1')
                .every((filter) => {
                    switch (filter.type){
                        case 'ORGANIZATION_SELECT':
                        case 'PERIOD_SELECT':
                        case 'SELECT':
                        case 'NUMBER':
                            return !!formikRef.current?.initialValues?.[filter.code];
                        case 'TIME':
                        case 'DATE':
                            return Object.keys(formikRef.current?.initialValues?.[filter.code])?.length > 0;
                        case 'MULTI_ORGANIZATION_SELECT':
                        case 'MULTI_SELECT':
                            return formikRef.current?.initialValues?.[filter.code]?.length > 0;
                        case 'NUMBER_BETWEEN':
                            return (formikRef.current?.initialValues?.[`start_${filter.code}`] && formikRef.current?.initialValues?.[`end_${filter.code}`])
                        case 'DATE_BETWEEN':
                            return (formikRef.current?.initialValues?.[`start_${filter.code}`] && formikRef.current?.initialValues?.[`end_${filter.code}`])
                        case 'CHECKBOX':
                        case 'QUESTIONNAIRE_FORM':
                        case 'PERIOD_AND_REFERENCE_PERIOD':
                            return false;

                    }
                })
            // submitting the form triggers validation and submits the form for the report
            if(shouldSubmit)
                formikRef.current?.submitForm()
        }
    }, [ columnsLoaded, filtersCardLoaded ])

    // important for permission handling in reportLink
    useEffect(() => () => accesses.clear(), []);

    const reportLoading = (!Array.isArray(reportsList) || reportsList.length <= 0) || !currentReport.report_id;

    return (
        <>
            {reportLoading ?
                <div>
                    {/* Title */}
                    <div>
                        <Skeleton count={1} height={25} width={250} />
                        <Skeleton className="mb-3" count={1} height={19} width={250} />
                    </div>

                    <div>
                        {/* Filters Card */}
                        <Skeleton className="mb-4" count={1} height={212} />

                        {/* Table card */}
                        <Skeleton count={1} height={230} />
                    </div>
                </div>
                :
                ((!currentReport.headers || !currentReport.filters) && currentReport.code) ?
                    <ReportEngineContactSupport currentReport={currentReport} />
                    :
                    <>
                        <SpordleTableProvider
                            ref={spordleTableRef}
                            id='reportsEngine'
                            errorMessage={ReportEngineError}
                            pagination={50}
                            dataIndex='key'
                            paginationMessage='reports.spordleTable.pagination.message'
                            hasFooter={currentReport.headers.some((header) => header.footer_method == 'SUM')}
                            renderFooter={(columnKey, total) => {
                                const header = currentReport?.headers.find((header) => header.code == columnKey);
                                const isNumber = !isNaN(total);

                                if(header && isNumber){
                                    if(header.type == 'MONEY')
                                        return <div className='text-right'><CurrencyFormat value={total / 100} /></div>;
                                    else if(header.type == 'NUMBER')
                                        return <div>{total}</div>;
                                }

                                return null;
                            }}
                            renderRow={renderTableRow}
                            loadData={(from, { filters }, spordleTable) => { // TODO: DO THIS :O
                                switch (from){
                                    case 'REFRESH':
                                    case 'FILTER':
                                        spordleTable.setLoading();

                                        return reportsContext.getReportEngineData({
                                            report_id: currentReport.report_id,
                                            request_parameter: JSON.stringify(removeNullValues(filters.reportsFilter)),
                                        })
                                            .then(({ report_results, more_rows }) => {
                                                setHasMoreRows(more_rows);

                                                return report_results.map((item) => {
                                                    item.key = generatePassword();
                                                    return item;
                                                });
                                            })
                                            .catch((error) => {
                                                if(AxiosIsCancelled(error.message))
                                                    return true;
                                                return error;
                                            });
                                    default:
                                        break;
                                }
                            }}
                            searchKeys={currentReport.headers?.map((header) => (header.code))}
                            initFilter={{
                                reportsFilter: getReportQueryParamsFromFilters(formikRef.current?.values, currentReport.filters),
                            }}
                        >

                            <ReportHeader />

                            <div>
                                <Card body className="card-shadow pb-0">
                                    <ReportsEngineFiltersCard
                                        formikRef={formikRef}
                                        setFiltersHaveChanged={setFiltersHaveChanged}
                                        setFiltersCardLoaded={setFiltersCardLoaded}
                                        hasMoreRows={hasMoreRows}
                                        isOpenHandlers={isOpenHandlers}
                                        customReportId={currentReport.custom_report_id}
                                        setCustomFormId={setCustomFormId}
                                        customFormId={customFormId}
                                    />
                                </Card>
                                <Card body className="card-shadow">
                                    <Row className='mb-2'>
                                        <Col md='3'>
                                            <SpordleTableProvider.SearchInput />
                                        </Col>
                                        <Col md='9'>
                                            <div className='text-right nowrap'>
                                                <SaveReports
                                                    isOpen={isOpen}
                                                    isOpenHandlers={isOpenHandlers}
                                                    onEdit={() => {
                                                        isOpenHandlers.close();
                                                        handleGetReportHelper();
                                                    }}
                                                    formikRef={formikRef}
                                                />
                                                <Tooltip withArrow label={<Translate id={'reports.customize.tooltip.pagination'} />}>
                                                    <TablePagination className="mr-2" />
                                                </Tooltip>
                                                <ColumnDefineButton />
                                                <ExportDropdown
                                                    getData={() => reportsContext.getReportEngineData({
                                                        report_id: currentReport.report_id,
                                                        request_parameter: JSON.stringify(removeNullValues(spordleTableRef.current?.getFilters()?.reportsFilter)),
                                                    })
                                                    }
                                                    formikRef={formikRef}
                                                    filtersHaveChanged={filtersHaveChanged}
                                                    formikFilters={{ ...getReportQueryParamsFromFilters(formikRef.current?.values, currentReport.filters), custom_report_id: queryParams.custom_report_id }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <SpordleTableView scrollButtons />
                                    {/* scrollButtons */}
                                </Card>
                            </div>
                        </SpordleTableProvider>
                    </>
            }


        </>
    );
}

export default ReportsEngine;