import { Tooltip } from "@mantine/core";
import { stringBuilder } from "@spordle/helpers";
import { Button } from "reactstrap";

const ActionBtn = ({ label, className, icon, ...props }) => {
    return (
        <Tooltip
            withArrow
            label={label}
        >
            <Button
                {...props}
                className={stringBuilder("rounded-lg ml-2 mdi", icon, className)}
                color="light"
                size="sm"
                type="button"
            />
        </Tooltip>
    );
}

export default ActionBtn;