import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';
import { PeriodsContext } from './contexts';

/** @type {React.Context<Omit<RegistrationFeeContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const RegistrationFeeContext = createContext();
RegistrationFeeContext.displayName = 'RegistrationFeeContext';

class RegistrationFeeContextProvider extends React.Component{
    /**
     * [GET] Gets specific registration fee
     * @param {object} [registrationFeeId] ID of the registration fee to get
     * @returns {Promise.<Array>}
     */
    getRegistrationFee = (registrationFeeId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `registration-fees/${registrationFeeId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.registration_fees[0];
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * [GET] Gets all registration fees
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Registration%20Fees/GetAllRegistrationFees|documentation}
     * @returns {Promise.<Array>}
     */
    getRegistrationFees = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/registration-fees',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.registration_fees;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * [CREATE] Creates a registration fee
     * @param {object} [formData] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Registration%20Fees/Apicontroller%5CPricing%5CRegistrationfees%3A%3AcreateRegistrationFee|documentation}
     * @returns {Promise.<string>} Returns the registration_fee_id created
     */
    createRegistrationFee = (formData) => {
        const params = new URLSearchParams({
            organisation_id: this.props.OrganizationContext.organisation_id,
            period_id: this.props.PeriodsContext.selectedPeriod.period_id,
        })

        for(const key in formData){
            if(key.includes('i18n'))
                params.append(key, formData[key]);
            else{
                switch (key){
                    case "min_age":
                    case "max_age":
                        if(formData[key])
                            params.append(key, parseInt(formData[key]));
                        break;
                    case "amount":
                        if(parseInt(formData[key]) >= 0)
                            params.append(key, formData[key]);
                        break;
                    case 'fields':
                        formData.fields.forEach((field, index) => {
                            params.append(`fields[${index}][custom_form_field_id]`, field.custom_form_field_id);

                            // array.toString() formats it to a string with a comma separating the values
                            if(field.custom_form_field_option_id !== undefined && field.custom_form_field_option_id !== null)
                                params.append(`fields[${index}][custom_form_field_option_id]`, Array.isArray(field.custom_form_field_option_id) ? field.custom_form_field_option_id.toString() : field.custom_form_field_option_id);

                            params.append(`fields[${index}][answer]`, Array.isArray(field.answer) ? field.answer.toString() : field.answer);
                        })
                        break;
                    default:
                        if(formData[key])
                            params.append(key, formData[key]);
                        break;
                }
            }
        }

        return API_SPORDLE.post('/registration-fees', params)
            .then((response) => {
                if(response.data.status){
                    return response.data.registration_fee_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PUT] Updates the waivers list associated with the registration-fee
     * @param {string} registrationFeeId ID of the registration fee to update
     * @param {object} values Object containing the values to update
     * @returns {Promise}
     */
    updateRegistrationFeeWaiver = (registrationFeeId, waivers) => {
        const params = {};

        if(Array.isArray(waivers)){
            waivers.map((value, i) => {
                params[`waivers[${i}]`] = value;
            });
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `registration-fees/${registrationFeeId}/waivers` }), new URLSearchParams(params))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * [PATCH] Updates a registration fee
     * @param {sting} registrationFeeId ID of the registration fee to update
     * @param {Object} values The values to update - Refer to the {@link https://api.id.spordle.dev/documentations/#/Registration%20Fees/Apicontroller%5CPricing%5CRegistrationfees%3A%3ApatchRegistrationFee|documentation}
     * @returns {Promise}
     */
    updateRegistrationFee = (registrationFeeId, values) => {
        const params = new URLSearchParams({})

        for(const key in values){
            switch (key){
                case "min_age":
                case "max_age":
                    if(values[key])
                        params.append(key, parseInt(values[key]));
                    else
                        params.append(key, "");
                    break;
                // these keys will be sent as empty
                // they skip the if below in the default case
                case "early_amount":
                case "early_amount_until":
                case "late_amount":
                case "late_amount_after":
                case "activity_period_id":
                case "tax_class_id":
                case "custom_form_id":
                    params.append(key, values[key]);
                    break;
                case "amount":
                    if(parseInt(values[key]) >= 0)
                        params.append(key, values[key]);
                    break;
                case 'fields':
                    values.fields.forEach((field, index) => {
                        params.append(`fields[${index}][custom_form_field_id]`, field.custom_form_field_id);

                        // array.toString() formats it to a string with a comma separating the values
                        if(field.custom_form_field_option_id !== undefined && field.custom_form_field_option_id !== null)
                            params.append(`fields[${index}][custom_form_field_option_id]`, Array.isArray(field.custom_form_field_option_id) ? field.custom_form_field_option_id.toString() : field.custom_form_field_option_id);

                        params.append(`fields[${index}][answer]`, Array.isArray(field.answer) ? field.answer.toString() : field.answer);
                    })
                    break;
                default:
                    if(values[key])
                        params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/registration-fees/${registrationFeeId}`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes a specific registration fee
     * @param {string} registrationFeeId ID of the Category to delete
     * @returns {Promise}
     */
    deleteRegistrationFee = (registrationFeeId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `registration-fees/${registrationFeeId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <RegistrationFeeContext.Provider value={this}>
                {this.props.children}
            </RegistrationFeeContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext, PeriodsContext)(RegistrationFeeContextProvider);