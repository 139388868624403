import Translate from '@spordle/intl-elements';
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    Button,
    FormGroup,
    Label
} from "reactstrap";
import { Form, Formik } from 'formik';
import { FormikCheckedButton, FormikInputText, FormikSwitch } from '@spordle/formik-elements';
import FormikTiny from '../../../../components/formik/FormikTiny';
import I18nContextProvider, { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';
import * as Yup from 'yup';
import OverlayLoader from '../../../../components/loading/OverlayLoader';

// contexts
import { WaiversContext } from '../../../../contexts/WaiversContext';
import withContexts from '../../../../helpers/withContexts';
import { fail, success } from "@spordle/toasts";
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import WaiverPreviewModal from './WaiverPreviewModal';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import MandatoryLabel from './MandatoryLabel';
import CriticalLabel from './CriticalLabel';
import Required from '../../../../components/formik/Required';

class WaiverEdit extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            loading: false,
            previewModalIsOpen: false,
            previewWaiver: null,
        }
    }

    togglePreviewModal = () => {
        this.setState((prev) => ({
            previewModalIsOpen: !prev.previewModalIsOpen,
        }))
    }

    componentDidMount(){
        if(this.props.mode === 'edit' && !this.props.waiverData?.waiver_id){
            this.props.history.push('/catalog/waivers');
        }
    }

    render(){
        return (
            <>
                <WaiverPreviewModal isOpen={this.state.previewModalIsOpen} toggle={this.togglePreviewModal} waiver={this.state.previewWaiver} />

                <div className='mb-2'>
                    <Link to="/catalog/waivers" className="font-medium">
                        <i className="mdi mdi-arrow-left mr-1" />
                        <Translate id='catalog.waivers.back' />
                    </Link>
                </div>

                <I18nContextProvider fields={[ 'name', 'description', 'confirm_label', 'decline_label' ]} labels={{ name: 'title' }}>
                    <I18nHelperContext.Consumer>
                        {(i18nHelper) => (
                            <Formik
                                initialValues={{
                                    ...i18nHelper.getInitialValues(this.props.mode === 'edit' ? this.props.waiverData : {}),
                                    active: this.props.mode === 'edit' ? this.props.waiverData?.active === '1' : true,
                                    mandatory: this.props.mode === 'edit' ? this.props.waiverData?.mandatory === '1' : true,
                                    critical: this.props.mode === 'edit' ? this.props.waiverData?.critical == 1 : false,
                                    waiver_type_id: '09ce9989-827f-11eb-a431-0205722c772a', // TODO: hardcoded
                                }}
                                validationSchema={Yup.object().shape(
                                    i18nHelper.getValidationSchema({
                                        name: Yup.string().required(<Translate id='form.validation.name.required' />),
                                        description: Yup.string().required(<Translate id='form.validation.description.required' />),
                                    }),
                                )}
                                onSubmit={(values, { setSubmitting }) => {
                                    if(this.props.mode === 'edit'){
                                        this.props.WaiversContext.updateWaiver(this.props.waiverData?.waiver_id, {
                                            ...i18nHelper.getAPIValues(values),
                                            waiver_type_id: values.waiver_type_id,
                                            mandatory: values.mandatory,
                                            critical: values.critical,
                                            active: values.active,
                                        })
                                            .then(() => {
                                                this.props.history.push('/catalog/waivers');
                                                success({ msg: 'misc.updated' });
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => {
                                                setSubmitting(false)
                                            })
                                    }else{
                                        this.props.WaiversContext.createWaiver({
                                            ...i18nHelper.getAPIValues(values),
                                            waiver_type_id: values.waiver_type_id,
                                            mandatory: values.mandatory,
                                            critical: values.critical,
                                            active: values.active,
                                        })
                                            .then(() => {
                                                this.props.history.push('/catalog/waivers');
                                                success({ msg: 'misc.created' });
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => {
                                                setSubmitting(false)
                                            })
                                    }
                                }}
                            >
                                {(formik) => (
                                    <Form>
                                        <Card className="card-shadow">
                                            <OverlayLoader isLoading={formik.isSubmitting || this.state.loading}>
                                                <CardBody>
                                                    <div className="d-flex mb-3 border-bottom pb-1">
                                                        <div className="h4 font-bold mb-0 card-title">
                                                            {this.props.mode === 'edit' ?
                                                                <DisplayI18n field='name' defaultValue={formik.values.name} i18n={formik.values.i18n} />
                                                                :
                                                                <Translate id='catalog.waivers.add.title' />
                                                            }
                                                        </div>
                                                        <div className="ml-auto d-flex align-items-center">
                                                            <Label for='active' className="mr-2 mb-0"><Translate id={`misc.${formik.values.active ? 'active' : 'inactive'}`} /></Label>
                                                            <FormikSwitch id='active' name='active' />
                                                        </div>
                                                    </div>
                                                    <FormGroup>
                                                        <FormikCheckedButton
                                                            id='mandatory'
                                                            name='mandatory'
                                                            label={<MandatoryLabel />}
                                                            translateLabel={false}
                                                            disabled={formik.values.critical}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <FormikCheckedButton
                                                            id='critical'
                                                            name='critical'
                                                            label={<CriticalLabel />}
                                                            translateLabel={false}
                                                            onChange={(e) => {
                                                                formik.setFieldValue('critical', e.target.checked);
                                                                if(e.target.checked){
                                                                    formik.setFieldValue('mandatory', true);
                                                                }
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    <RenderI18nForm field="name">
                                                        {({ fieldName, fieldLabel }) => {
                                                            return (
                                                                <FormGroup key={fieldName}>
                                                                    <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                                </FormGroup>
                                                            )
                                                        }}
                                                    </RenderI18nForm>

                                                    <RenderI18nForm field="description">
                                                        {({ fieldName, fieldLabel }) => {
                                                            return (
                                                                <FormGroup key={fieldName}>
                                                                    <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                                    {/* check if headings can be manually saved as <div class='h1'></div> */}
                                                                    {/* if not, remove the heading option */}
                                                                    {/* it could also be cool if we could sent the "height" prop to make it bigger only for this page (because waivers can be long) */}
                                                                    <FormikTiny name={fieldName} height="500" />
                                                                </FormGroup>
                                                            )
                                                        }}
                                                    </RenderI18nForm>

                                                    <RenderI18nForm field="confirm_label">
                                                        {({ fieldName, lang }) => {
                                                            return (
                                                                <FormGroup key={fieldName}>
                                                                    <Label for={fieldName} className='text-muted'>
                                                                        <Translate
                                                                            id='catalog.waivers.add.confirmLabel'
                                                                            values={{
                                                                                lang: lang.toUpperCase(),
                                                                            }}
                                                                        />
                                                                    </Label>
                                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                                </FormGroup>
                                                            )
                                                        }}
                                                    </RenderI18nForm>

                                                    <RenderI18nForm field="decline_label">
                                                        {({ fieldName, lang }) => {
                                                            return (
                                                                <FormGroup key={fieldName}>
                                                                    <Label for={fieldName} className='text-muted'>
                                                                        <Translate
                                                                            id='catalog.waivers.add.declineLabel'
                                                                            values={{
                                                                                lang: lang.toUpperCase(),
                                                                            }}
                                                                        />
                                                                    </Label>
                                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                                </FormGroup>
                                                            )
                                                        }}
                                                    </RenderI18nForm>

                                                    <div className="d-flex">
                                                        {this.props.mode === 'edit' &&
                                                            <Button
                                                                color="danger" outline type='button'
                                                                disabled={formik.isSubmitting}
                                                                onClick={() => {
                                                                    this.setState(() => ({ loading: true }))
                                                                    this.props.WaiversContext.deleteWaiver(this.props.waiverData?.waiver_id)
                                                                        .then(() => {
                                                                            this.props.history.push({ hash: '#waivers' });
                                                                            success({ msg: 'misc.deleted' });
                                                                        })
                                                                        .catch((error) => {
                                                                            if(!AxiosIsCancelled(error.message)){
                                                                                console.error(error.message)
                                                                                fail({
                                                                                    msg: 'misc.error',
                                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                    skipInfoTranslate: true,
                                                                                })
                                                                            }
                                                                        })
                                                                        .finally(() => {
                                                                            this.setState(() => ({ loading: false }))
                                                                        })
                                                                }}
                                                            >
                                                                <Translate id='misc.delete' />
                                                            </Button>
                                                        }
                                                        <div className='ml-auto'>
                                                            <Button
                                                                color='primary' outline
                                                                type='button'
                                                                className='ml-auto mr-2'
                                                                disabled={formik.isSubmitting}
                                                                onClick={() => {
                                                                    this.setState(() => ({
                                                                        previewModalIsOpen: true,
                                                                        previewWaiver: {
                                                                            invoiceItem: {
                                                                                organisation: {
                                                                                    organisation_name: this.props.OrganizationContext.organisation_name,
                                                                                    i18n: this.props.OrganizationContext.i18n,
                                                                                },
                                                                            },
                                                                            waiver: {
                                                                                ...i18nHelper.appendI18nForTable(formik.values),
                                                                                mandatory: formik.values.mandatory >>> 0,
                                                                            },
                                                                        },
                                                                    }))
                                                                }}
                                                            >
                                                                <Translate id='catalog.waivers.edit.preview' />
                                                            </Button>
                                                            <Button color="primary" className='ml-auto' type='submit' disabled={formik.isSubmitting}>
                                                                {this.props.mode === 'edit' ?
                                                                    <Translate id='misc.save' />
                                                                    :
                                                                    <Translate id='misc.add' />
                                                                }
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </OverlayLoader>
                                        </Card>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </I18nHelperContext.Consumer>
                </I18nContextProvider>
            </>
        )
    }
}

export default withRouter(withContexts(WaiversContext, OrganizationContext)(WaiverEdit));