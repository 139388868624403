import { useContext, useState } from "react";
import { Row,
    Col,
    DropdownItem } from "reactstrap";
import FormikEditable from '../../../../../components/formik/FormikEditable';
import { FormikDateTime, FormikInputText, FormikTextArea } from '@spordle/formik-elements';
import Translate, { DateFormat } from "@spordle/intl-elements";
import ClinicCategory from "../../../../clinics/ClinicCategory";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { object, mixed, string } from 'yup';
import moment from 'moment';
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
import StatusBadge from "../../../../../components/badges/StatusBadge";
import useSWR from "swr";

// contexts
import { MembersContext } from "../../../../../contexts/MembersContext";
import { UtilsContext } from "../../../../../contexts/UtilsContext";
import { MemberQualificationsDeleteModal } from "./MemberQualificationsDeleteModal";
import MemberQualificationsAttendees from "./MemberQualificationsAttendees";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import { QualificationsContext } from "../../../../../contexts/QualificationsContext";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import MemberQualificationTransferShare from "./shareTransfer/MemberQualificationTransferShare";
import MemberQualificationCertify from "./MemberQualificationCertify";
import { RolesContext } from "../../../../../contexts/RolesContext";
import DisplayClinicAttendeeGrade from "../../../../../components/display/DisplayClinicAttendeeGrade";

const MemberQualificationSidepanel = (props) => {

    const [ loading, setLoading ] = useState(false)

    const { refreshTable } = props.tableRef;

    const membersContext = useContext(MembersContext);
    const qualificationContext = useContext(QualificationsContext);
    const utilsContext = useContext(UtilsContext);
    const { canDoAction } = useContext(RolesContext);
    const { settings } = useContext(OrganizationContext);

    const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
    const [ shareTransfer, setShareTransfer ] = useState(false);

    const countryCode = 'CA';
    // the only thing that the logic depends on is the country code ('CA') and it is not in the qualification in the props
    // so this call will only be done once when the sidepanel is mounted
    const { data: canada, isValidating } = useSWR(
        [ 'getCountries', countryCode ],
        () => utilsContext.getCountries()
            .then((countries) => countries.find((country) => country.code === countryCode))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        ,
    );

    const displayProvince = () => {
        const province = canada?.sub_divisions?.find((province) => province.code === props.selectedRows[0].province);

        if(province){
            return <DisplayI18n field='name' defaultValue={province.code} i18n={province.i18n} />
        }
        return '-'
    }

    const canEditQualification = canDoAction('EDIT', 'members', 'members_qualifications');

    return (
        <OverlayLoader isLoading={isValidating || loading}>
            <SidePanel.Header>
                <div className='d-flex align-items-center mb-2'>
                    <SidePanel.ToggleButton />
                    {!props.selectedRows[0].isAttendee &&
                        <SidePanel.ActionsMenu>
                            {props.selectedRows[0].qualification_status?.status_code === 'CERTIFIED' ?
                                <ConfirmModal
                                    color='danger'
                                    modalTitle='members.profile.qualifications.sidePanel.actions.removeCertification.text'
                                    translateModalTitle
                                    actionButtonText='misc.confirm'
                                    translateActionButtonText
                                    onConfirm={() => {
                                        return qualificationContext.deleteMemberCertification(props.selectedRows[0].member.member_id, props.selectedRows[0].member_qualification_id)
                                            .then(() => {
                                                props.tableRef.refreshTable();
                                                props.toggle();
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    }}
                                    toggler={(onClick) => (
                                        <>
                                            <SidePanel.MenuAction onClick={onClick} action='DELETE' componentCode='teams' componentPermissionCode='member_qualification_certify'>
                                                <Translate id='members.profile.qualifications.sidePanel.actions.removeCertification' />
                                            </SidePanel.MenuAction>
                                            <DropdownItem divider />
                                        </>
                                    )}
                                />
                                : props.selectedRows[0].qualification.certified == '1' &&
                                    <>
                                        <MemberQualificationCertify
                                            memberId={props.selectedRows[0].member.member_id}
                                            memberQualificationId={props.selectedRows[0].member_qualification_id}
                                            refreshTable={props.tableRef.refreshTable}
                                            closeSidePanel={props.forceCloseSidePanel}
                                        />
                                        <DropdownItem divider />
                                    </>
                            }

                            {settings.allow_transfer_share_qualification.value == 1 &&
                                <>
                                    <SidePanel.MenuAction
                                        onClick={() => setShareTransfer(true)}
                                        action='EDIT' componentCode='members' componentPermissionCode='member_transfer_share_qualification'
                                    >
                                        <Translate id='members.profile.qualifications.sidePanel.actions.trasnferShare' />
                                    </SidePanel.MenuAction>
                                    <MemberQualificationTransferShare
                                        spordleTable={props.tableRef}
                                        closeSidePanel={props.forceCloseSidePanel}
                                        isOpen={shareTransfer}
                                        memberQualification={props.selectedRows[0]}
                                        toggle={() => setShareTransfer(!shareTransfer)}
                                    />
                                </>
                            }

                            <SidePanel.MenuAction onClick={() => setDeleteModalOpen(!deleteModalOpen)}><Translate id='misc.delete' /></SidePanel.MenuAction>
                            <MemberQualificationsDeleteModal
                                deleteModalOpen={deleteModalOpen}
                                setDeleteModalOpen={setDeleteModalOpen}
                                closeSidePanel={props.forceCloseSidePanel}
                                refreshTable={refreshTable}
                                member_id={props.selectedRows[0].member.member_id}
                                member_qualification_id={props.selectedRows[0].member_qualification_id}
                                qualification={props.selectedRows[0].qualification}
                            />
                        </SidePanel.ActionsMenu>
                    }
                </div>
                <div className="d-flex align-items-center">
                    <div>
                        <SidePanel.Title>
                            <DisplayI18n field='name' defaultValue={props.selectedRows[0].qualification.name} i18n={props.selectedRows[0].qualification.i18n} />
                            {props.selectedRows[0].qualification.qualification_level &&
                                <>
                                    {' '}
                                    <DisplayI18n field='name' defaultValue={props.selectedRows[0].qualification.qualification_level.name} i18n={props.selectedRows[0].qualification.qualification_level.i18n} />
                                </>
                            }
                        </SidePanel.Title>
                        <SidePanel.Subtitle>
                            <ClinicCategory color={isNaN(parseInt(props.selectedRows[0].qualification.qualification_category.color)) ? 0 : props.selectedRows[0].qualification.qualification_category.color}>
                                <DisplayI18n field='name' defaultValue={props.selectedRows[0].qualification.qualification_category.name} i18n={props.selectedRows[0].qualification.qualification_category.i18n} />
                            </ClinicCategory>
                        </SidePanel.Subtitle>
                    </div>
                    <div className="ml-3">
                        <StatusBadge
                            icon={null}
                            status={props.selectedRows[0].qualification_status ? props.selectedRows[0].qualification_status : props.selectedRows[0].member_qualification_status}
                            color={props.selectedRows[0].qualification_status ? props.selectedRows[0].qualification_status.color_code : props.selectedRows[0].member_qualification_status?.color}
                        />
                    </div>
                </div>
            </SidePanel.Header>
            <SidePanel.Body>
                {/*
                    IMPORTANT INFORMATION: To know whether or not a members' qualification can be certified, look at the flag in the qualification sub-resource of the selected row. certified: '1' (in the qualification
                    sub-resource) means it CAN be certified. certified: '1' in the selected row indicates whether or not the members' qualification IS certified.
                */}
                {props.selectedRows[0].isAttendee ?
                    <MemberQualificationsAttendees {...props} />
                    :
                    <>
                        <Row form>
                            <Col sm="6">
                                <div className="mb-3">
                                    <div className="text-muted"><Translate id='members.profile.qualifications.season' /></div>
                                    <div className="font-medium text-dark"><DisplayI18n field='name' defaultValue={props.selectedRows[0].period.name} i18n={props.selectedRows[0].period.i18n} /></div>
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className='mb-3'>
                                    <div className="text-muted"><Translate id={props.selectedRows[0].qualification.certified == 1 ? 'members.profile.qualifications.certifiedDate' : 'members.profile.qualifications.validationDate'} /></div>
                                    <FormikEditable
                                        id='validationDate'
                                        disabled={!canEditQualification}
                                        initialValues={{
                                            validationDate: props.selectedRows[0].taken_date ? moment(props.selectedRows[0].taken_date) : '',
                                        }}
                                        validationSchema={object().shape({
                                            validationDate: mixed().required(<Translate id={props.selectedRows[0].qualification.certified == 1 ? 'members.profile.qualifications.certifiedDate.required' : 'members.profile.qualifications.validationDate.required'} />)
                                                .test({
                                                    name: 'validationDateFormat',
                                                    message: <Translate id='form.validation.date.format' />,
                                                    test: moment.isMoment,
                                                }).test({
                                                    name: 'valiationDateExpirationDate',
                                                    message: <Translate id={props.selectedRows[0].qualification.certified == 1 ? 'members.profile.qualifications.certifiedDate.expirationDate' : 'members.profile.qualifications.validationDate.expirationDate'} />,
                                                    test: (validationDate) => {
                                                        if(props.selectedRows[0].expiration_date){
                                                            return moment(validationDate).isBefore(moment(props.selectedRows[0].expiration_date))
                                                        }
                                                        return true
                                                    },
                                                }),
                                        })}
                                        onSubmit={(values) => {
                                            const newDate = moment(values.validationDate).format('YYYY-MM-DD');
                                            if(newDate !== props.selectedRows[0].taken_date){
                                                setLoading(true)
                                                const newValues = props.createNewValues({
                                                    taken_date: newDate,
                                                });
                                                membersContext.updateMemberQualificationPartial(props.selectedRows[0].member.member_id, props.selectedRows[0].member_qualification_id, {
                                                    validationDate: newDate,
                                                })
                                                    .then(() => {
                                                        props.syncRows(newValues);
                                                        success();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                    .finally(() => {
                                                        setLoading(false)
                                                    })
                                            }
                                        }}
                                    >
                                        {(isEditing) => {
                                            if(!isEditing){
                                                return (
                                                    <span className="font-medium text-dark"><DateFormat value={props.selectedRows[0].taken_date} utc /></span>
                                                )
                                            }
                                            return (
                                                <FormikDateTime
                                                    className='date-picker-left'
                                                    id='validationDate' name='validationDate'
                                                    timeFormat={false}
                                                    dateFormat='YYYY-MM-DD'
                                                    isValidDate={(current) => props.selectedRows[0].expiration_date ? moment(current).isBefore(moment(props.selectedRows[0].expiration_date)) : true}
                                                />
                                            )

                                        }}
                                    </FormikEditable>
                                </div>
                            </Col>
                        </Row>
                        <Row form>
                            <Col sm="6">
                                <div className='mb-3'>
                                    <div className="text-muted"><Translate id='members.profile.qualifications.expirationDate' /></div>
                                    <FormikEditable
                                        id='expirationDate'
                                        disabled={!canEditQualification || props.selectedRows[0].qualification?.qualification_category?.can_expire == 0}
                                        initialValues={{
                                            expirationDate: props.selectedRows[0].expiration_date ? moment(props.selectedRows[0].expiration_date) : '',
                                        }}
                                        validationSchema={object().shape({
                                            expirationDate: mixed()
                                                .test({
                                                    name: 'expirationDateFormat',
                                                    message: <Translate id='form.validation.date.format' />,
                                                    test: (value) => {
                                                        if(value !== undefined){
                                                            return moment.isMoment(value)
                                                        }
                                                        return true // return true because the field is not required

                                                    },
                                                }).test({
                                                    name: 'expirationDateValidationDate',
                                                    message: <Translate id='members.profile.qualifications.expirationDate.validationDate' />,
                                                    test: (expirationDate) => {
                                                        return moment(props.selectedRows[0].taken_date).isBefore(moment(expirationDate))
                                                    },
                                                }),
                                        })}
                                        onSubmit={(values) => {
                                            const newDate = values.expirationDate ? moment(values.expirationDate).format('YYYY-MM-DD') : ''
                                            if(newDate !== props.selectedRows[0].expiration_date){
                                                setLoading(true)
                                                const newValues = props.createNewValues({
                                                    expiration_date: newDate,
                                                });
                                                membersContext.updateMemberQualificationPartial(props.selectedRows[0].member.member_id, props.selectedRows[0].member_qualification_id, {
                                                    expirationDate: newDate,
                                                })
                                                    .then(() => {
                                                        props.syncRows(newValues);
                                                        success();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                    .finally(() => {
                                                        setLoading(false)
                                                    })
                                            }
                                        }}
                                    >
                                        {(isEditing) => {
                                            if(!isEditing){
                                                return (
                                                    <span className="font-medium text-dark">
                                                        {props.selectedRows[0].expiration_date ? <DateFormat value={props.selectedRows[0].expiration_date} utc /> : '-'}
                                                    </span>
                                                )
                                            }
                                            return (
                                                <FormikDateTime
                                                    id='expirationDate' name='expirationDate'
                                                    timeFormat={false}
                                                    dateFormat='YYYY-MM-DD'
                                                    isValidDate={(current) => moment(current).isAfter(moment(props.selectedRows[0].taken_date))}
                                                />
                                            )

                                        }}
                                    </FormikEditable>
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className='mb-3'>
                                    <div className="text-muted"><Translate id='members.profile.qualifications.certificationNumber' /></div>
                                    <FormikEditable
                                        id='certificationNumber'
                                        disabled={!canEditQualification}
                                        initialValues={{
                                            certificationNumber: props.selectedRows[0].certification_number || '',
                                        }}
                                        validationSchema={object().shape({
                                            certificationNumber: string(),
                                        })}
                                        onSubmit={(values) => {
                                            if(values.certificationNumber !== (props.selectedRows[0].certification_number || '')){ // || '' because it can be null
                                                setLoading(true)
                                                const newValues = props.createNewValues({ certification_number: values.certificationNumber });
                                                membersContext.updateMemberQualificationPartial(props.selectedRows[0].member.member_id, props.selectedRows[0].member_qualification_id, {
                                                    certificationNumber: values.certificationNumber,
                                                })
                                                    .then(() => {
                                                        props.syncRows(newValues);
                                                        success();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                    .finally(() => {
                                                        setLoading(false)
                                                    })
                                            }
                                        }}
                                    >
                                        {(isEditing) => {
                                            if(!isEditing){
                                                return (
                                                    <span className="font-medium text-dark">{props.selectedRows[0].certification_number || '-'}</span>
                                                )
                                            }
                                            return (
                                                <FormikInputText id='certificationNumber' name='certificationNumber' trim />
                                            )

                                        }}
                                    </FormikEditable>
                                </div>
                            </Col>
                            <Col sm='6'>
                                <div className='mb-3'>
                                    <div className="text-muted"><Translate id='members.profile.qualifications.location' /></div>
                                    <div className="font-medium text-dark">
                                        {
                                            props.selectedRows[0].location ?
                                                props.selectedRows[0].location
                                                :
                                                props.selectedRows[0].clinic_first_session?.location_name || '-'
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col sm='6'>
                                <div className='mb-3'>
                                    <div className="text-muted"><Translate id='members.profile.qualifications.city' /></div>
                                    <div className="font-medium text-dark">
                                        {props.selectedRows[0].city || '-'}
                                    </div>
                                </div>
                            </Col>
                            <Col sm='6'>
                                <div className='mb-3'>
                                    <div className="text-muted"><Translate id='form.fields.province' /></div>
                                    <div className="font-medium text-dark">
                                        {displayProvince()}
                                    </div>
                                </div>
                            </Col>
                            <Col sm='6'>
                                <div className='mb-3'>
                                    <div className="text-muted"><Translate id='members.profile.qualifications.clinic' /></div>
                                    <div className="font-medium text-dark">
                                        {props.selectedRows[0].clinic_attendee ?
                                            <DisplayI18n field='name' defaultValue={props.selectedRows[0].clinic_attendee.clinic.name} i18n={props.selectedRows[0].clinic_attendee.clinic.i18n} />
                                            : '-'}
                                    </div>
                                </div>
                            </Col>
                            {props.selectedRows[0].clinic_attendee?.grade &&
                                <Col sm={12}>
                                    <div className='mb-3'>
                                        <div className="text-muted"><Translate id="clinics.profile.attendees.table.grade" /></div>
                                        <div className="font-medium text-dark">
                                            <DisplayClinicAttendeeGrade attendee={props.selectedRows[0].clinic_attendee} />
                                        </div>
                                    </div>
                                </Col>
                            }
                        </Row>
                        <div className='mb-3'>
                            <div className="text-muted"><Translate id='members.profile.qualifications.memo' /></div>
                            <FormikEditable
                                id='memo'
                                disabled={!canEditQualification}
                                initialValues={{
                                    memo: props.selectedRows[0].memo || '',
                                }}
                                validationSchema={object().shape({
                                    memo: string(),
                                })}
                                onSubmit={(values) => {
                                    if(values.memo !== (props.selectedRows[0].memo || '')){ // || '' because it can be null
                                        setLoading(true)
                                        const newValues = props.createNewValues(values);
                                        membersContext.updateMemberQualificationPartial(props.selectedRows[0].member.member_id, props.selectedRows[0].member_qualification_id, values)
                                            .then(() => {
                                                props.syncRows(newValues);
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => {
                                                setLoading(false)
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <span className="font-medium text-dark">{props.selectedRows[0].memo || '-'}</span>
                                        )
                                    }
                                    return (
                                        <FormikTextArea id='memo' name='memo' rows={2} trim />
                                    )

                                }}
                            </FormikEditable>
                        </div>
                    </>
                }
            </SidePanel.Body>
        </OverlayLoader>
    )
}

export default MemberQualificationSidepanel;