import clinicItems from "./clinicItems";
import clinicItemsAdd from "./clinicItemsAdd";
import clinicItemsSidepanel from "./clinicItemsSidepanel";

export default{
    "catalog.clinicItems.title" : "Clinic Items",

    ...clinicItemsAdd,
    ...clinicItemsSidepanel,
    ...clinicItems,
}