import { useEffect, useState } from "react";
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import I18nHelperContextProvider from "../../../helpers/i18nHelper";
import NewsForm, { MODE_CREATE, MODE_EDIT } from "../components/NewsForm";
import { useHistory } from "react-router-dom";

const ManageSingleNews = () => {
    const history = useHistory();
    const [ init, setInit ] = useState(history.location.state);

    useEffect(() => {
        if(history.location.pathname.includes('edit') && history.location.state?.news_id){
            setInit({
                ...history.location.state,
                organizations: history.location.state.news_for_organisation?.map((newsFor) => newsFor.organisation_id) || [],
            });
            history.replace({ state: null });
        }
    }, []);

    return (
        <>
            <ViewHeaderV2 title="sidebar.news" />
            <div className="page-content px-4 pt-0">
                <I18nHelperContextProvider fields={[ "description", "title" ]}>
                    <NewsForm
                        mode={history.location.pathname.includes('edit') ? MODE_EDIT : MODE_CREATE}
                        initialValues={init}
                    />
                </I18nHelperContextProvider>
            </div>
        </>
    );
}

export default ManageSingleNews;