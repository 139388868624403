import { SpordleTableContext } from '@spordle/datatables';
import { FormikInputNumber, FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import {
    Button, Col, FormGroup,
    Label, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { number, object, string } from 'yup';
import { success, fail } from '@spordle/toasts';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { CrcContext } from '../../../../../../contexts/CrcContext';
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../../../../helpers/i18nHelper";

const BackgroundCheckTypesAdd = ({ isOpen, toggle }) => {

    const i18nHelper = useContext(I18nHelperContext);
    const spordleTable = useContext(SpordleTableContext);
    const crcContext = useContext(CrcContext);
    const organizationContext = useContext(OrganizationContext);

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='BackgroundCheckTypesAdd'>
            <Formik
                initialValues={{
                    ...i18nHelper.getInitialValues(),
                    status: '1',
                    display_order: (spordleTable?.getData()?.length + 1) || 1,
                }}
                validationSchema={object().shape({
                    ...i18nHelper.getValidationSchema({ name: string().required(<Translate id='form.validation.name.required' />), description: string().required(<Translate id='form.validation.description.required' />) }),
                    status: string().required(),
                    display_order: number().min(0).required(<Translate id='form.validation.displayOrder.required' />),
                })}
                onSubmit={(values, { setSubmitting }) => {

                    const apiValues = { ...values, ...i18nHelper.getAPIValues(values), active: values.status };
                    apiValues.organisation_id = organizationContext.organisation_id;
                    delete apiValues.i18n;

                    crcContext.createBackgroundCheckType(apiValues)
                        .then(async(data) => {
                            const tableData = {
                                background_check_type_id: data,
                                ...values,
                                active: values.status,
                                display_order: values.display_order,
                            }
                            await spordleTable?.addRow(tableData);
                            toggle(data);
                            success();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                setSubmitting(false);
                            }
                        })
                }}
            >
                {(formik) => {
                    return (
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <Form>
                                <ModalHeader toggle={() => toggle()}><Translate id='settings.backgroundCheckType.add' /></ModalHeader>
                                <ModalBody>

                                    <Row form>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for='status' className='text-muted'><Translate id='form.fields.status' /></Label>
                                                <FormikSelect
                                                    id='status' name='status'
                                                    defaultData={[
                                                        { value: '1', label: 'misc.active', translateLabel: true },
                                                        { value: '0', label: 'misc.inactive', translateLabel: true },
                                                    ]}
                                                    loadingStatus='success'
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label for='display_order' className='text-muted'><Translate id='form.fields.displayOrder' /></Label>
                                                <FormikInputNumber
                                                    autoFocus
                                                    name='display_order'
                                                    id='display_order_BackgroundCheckTypesAdd'
                                                    allowNegative={false}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <RenderI18nForm field="name">
                                        {({ fieldName, fieldLabel }) => {
                                            return (
                                                <FormGroup key={fieldName}>
                                                    <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                </FormGroup>
                                            )
                                        }}
                                    </RenderI18nForm>

                                    <RenderI18nForm field="description">
                                        {({ fieldName, fieldLabel }) => {
                                            return (
                                                <FormGroup key={fieldName}>
                                                    <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                </FormGroup>
                                            )
                                        }}
                                    </RenderI18nForm>

                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type='button' outline onClick={() => toggle()} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </Form>
                        </OverlayLoader>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    )
}

export default BackgroundCheckTypesAdd;