import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useState } from "react";
import { Collapse } from "reactstrap";
import QualificationsAttendeesListItem from "./QualificationsAttendeesListItem";

/**
 * @description List that handles the view more, view less
 * @param {Object} props
 * @param {number} [props.max=4] The maximum number of elements shown before there's a view more btn
 * @param {import("./QualificationsAttendeesListItem").Qualification[]} props.list The list of items to render
 * @param {JSX.Element|string} props.emptyMsg Will not render anything if no emptyMsg is specified and the list is empty
 * @returns {JSX.Element|null}
 */
const QualificationsAttendeesList = ({ max: MAX_SHOWN = 4, list, emptyMsg }) => {
    const [ collapseIsOpen, setCollapseIsOpen ] = useState(false);

    const qualifications = list.reduce((obj, qual, index) => {
        if(index >= MAX_SHOWN){
            obj.inCollapse.push(qual);
        }else{
            obj.shown.push(qual);
        }
        return obj;
    }, { shown: [], inCollapse: [] });

    return (
        qualifications.shown.length > 0 ?
            <div className="font-medium text-dark rounded-lg border">
                <div
                    className={
                        stringBuilder(
                            'roster-staff-qualifications',
                            { 'isExpanded': collapseIsOpen || qualifications.inCollapse.length <= 0 },
                        )
                    }
                >
                    {qualifications.shown.map((qualification, index) => (
                        <QualificationsAttendeesListItem index={index} qualification={qualification} key={qualification.member_qualification_id} />
                    ))}
                    {qualifications.inCollapse.length > 0 && qualifications.shown.length > 0 &&
                        <Collapse isOpen={collapseIsOpen}>
                            {qualifications.inCollapse.map((qualification, index) => (
                                <QualificationsAttendeesListItem index={index + (qualifications.shown.length - 1)} qualification={qualification} key={qualification.member_qualification_id} />
                            ))}
                        </Collapse>
                    }
                </div>
                {list?.length > MAX_SHOWN &&
                    <div className="p-2 border-top d-flex justify-content-center">
                        <button className="reset-btn text-link" onClick={() => setCollapseIsOpen(!collapseIsOpen)} type='button'>
                            {collapseIsOpen ?
                                <><Translate id="misc.viewLess" /> <i className="mdi mdi-chevron-up" /></>
                                :
                                <><Translate id="misc.viewMore" /> <i className="mdi mdi-chevron-down" /></>
                            }
                        </button>
                    </div>
                }
            </div>
            :
            emptyMsg ?
                <div className="font-medium text-dark rounded-lg border">
                    <div style={{ height: 50 }} className="bg-light-inverse font-weight-normal d-flex align-items-center justify-content-center text-muted p-2">
                        {emptyMsg}
                    </div>
                </div>
                :
                null
    )
}

export default QualificationsAttendeesList;