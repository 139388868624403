import React from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { appendI18nForCreate, appendI18nForUpdate, displayI18n } from '../helpers/i18nHelper';
import withContexts from '../helpers/withContexts';
import { I18nContext } from './I18nContext';

import * as Sentry from "@sentry/react";
import { PeriodsContext } from './contexts';
import { getLocalStorageItem, setLocalStorageItem } from '../helpers/browserStorage';

class PeriodsContextProvider extends React.Component{
    constructor(props){
        super(props);
        this.state = window.frameElement && window.frameElement?.getAttribute('periodscontext') ? JSON.parse(window.frameElement?.getAttribute('periodscontext')) : {}
    }
    /*
    State exemple:
        state = {
            selectedPeriod: {
                "period_id":"1eb6a483-7a42-6186-82db-02c510e3b1a6",
                "name":"Season 2020-2021",
                "active":"1",
                "start_date":"2020-06-01",
                "end_date":"2021-05-01",
                "shared_date":null,
                "calculation_date":null,
                "organisation":{
                    "organisation_id":"1eb1f80b-0885-62e2-87c6-e454e80c8447",
                    "organisation_name":"Spordle ID"},
                    "current":1,
                    "i18n":{"fr":{"name":"Saison 2020-2021"},"en":{"name":"Season 2020-2021"}
                }
            },
            activePeriods: []
            periods: [
                {
                    "period_id":"1eb5a60a-8e99-68fa-ba7f-a8a1592b6e57",
                    "name":"Season 2018-2019",
                    "active":"1",
                    "start_date":"2018-06-01",
                    "end_date":"2019-05-01",
                    "shared_date":null,
                    "calculation_date":"2020-12-08",
                    "organisation":{"organisation_id":"1eb1f80b-0885-62e2-87c6-e454e80c8447","organisation_name":"Spordle ID"},
                    "current":0,
                    "i18n":{"fr":{"name":"Saison 2018-2019"},"en":{"name":"Season 2018-2019"}}
                }
            ]
        }
    */

    componentDidUpdate(){
        Sentry.setContext('period', this.state.selectedPeriod ? {
            periodId: this.state.selectedPeriod.period_id,
            periodName: this.state.selectedPeriod.name,
            startDate: this.state.selectedPeriod.start_date,
            endDate: this.state.selectedPeriod.end_date,
            current: this.state.selectedPeriod.current,
            active: this.state.selectedPeriod.active,
        } : null);
        Sentry.setTag('periodId', this.state.selectedPeriod?.period_id);
    }

    /**
     * Will be used inside the QuickView Iframe
     * @param {orgId} orgId
     * @returns {Promise}
     */
    setCurrentState = (state) => this.setState(() => ({ ...state }));

    /**
     * Set the selected period (header view dropdown)
     * @param {object} period The selected period
     * @returns {void}
     */
    setSelectedPeriod = (period, callback) => {
        this.setState(() => ({ selectedPeriod: period }), () => {
            setLocalStorageItem('periodId', period.period_id);
            callback?.();
        });
    }

    /**
     * Get all the periods within an organization
     * @param {string} organizationId The organization id we want to get the periods from
     * @returns {Promise<Array>}
     */
    getOrganizationPeriods = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `periods`, query: { organisation_id: organizationId } }))
            .then((response) => {
                if(response.data.status){
                    const collator = new Intl.Collator(this.props.I18nContext.getGenericLocale(), { sensitivity: 'base', numeric: true });

                    const periods = response.data.periods?.sort((a, b) => {
                        if(a.start_date !== b.start_date){
                            const dateA = new Date(a.start_date);
                            const dateB = new Date(b.start_date);

                            return dateB - dateA;
                        }

                        const valueA = (displayI18n("name", a.i18n, a.name, this.props.I18nContext.getGenericLocale())).toLowerCase();
                        const valueB = (displayI18n("name", b.i18n, b.name, this.props.I18nContext.getGenericLocale())).toLowerCase();

                        return collator.compare(valueB, valueA);
                    });

                    const storedPeriodId = getLocalStorageItem('periodId');
                    const activePeriods = periods.filter((period) => (
                        period.active === "1"
                        && !!period.start_date
                        && !!period.end_date
                        && period.start_date !== "0000-00-00"
                        && period.end_date !== "0000-00-00"
                    ));
                    const selectedPeriod = activePeriods.find((period) => period.period_id === storedPeriodId) ?? // Finding the stored period
                                        activePeriods.find((period) => period.active == 1 && period.current == 1) ?? // If stored period not found -> Find the current
                                        activePeriods[0] ??// If current period not found -> Take the first active period (this should never happen)
                                        periods[0]; // If no active periods -> Take the first period (this should never happen)
                    if(selectedPeriod)
                        setLocalStorageItem('periodId', selectedPeriod.period_id)
                    this.setState(() => ({
                        activePeriods: activePeriods,
                        periods: periods,
                        selectedPeriod: selectedPeriod,
                    }));
                    return periods;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get all the periods within an organization
     * @param {string} queryParams organisation_id and active
     * @returns {Promise<Array>}
     */
    getOrganizationActivityPeriods = (queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `activity-periods`, query: queryParams }))
            .then((response) => {
                if(response.data.status){
                    return response.data.activity_periods.map((activityPeriod) => ({
                        ...activityPeriod,
                        organisation: response.data.organisations[activityPeriod.organisation_id],
                        created_by: response.data.identities[activityPeriod.created_by],
                    }));
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get period information
     * @param {string} periodId
     * @returns {Promise}
     */
    getPeriod = (periodId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `periods/${periodId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.periods[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a specified Period with new values
     * @param {string} periodId ID of the Period to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @param {string} [lang] Language variable corresponding to the values - Used for the I18N logic
     * @param {object} [i18n] Object containing an array of all the languages available and an object of the initialValues for language variables - Used for the I18N logic
     * @returns {Promise}
     */
    updatePeriod = (periodId, values, lang, i18n) => {
        const params = new URLSearchParams();

        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                case 'name':
                    appendI18nForUpdate(params, 'name', lang, values, i18n)
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `periods/${periodId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a specified Period with new values
     * @param {string} periodId ID of the Period to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @param {string} [lang] Language variable corresponding to the values - Used for the I18N logic
     * @param {object} [i18n] Object containing an array of all the languages available and an object of the initialValues for language variables - Used for the I18N logic
     * @returns {Promise}
     */
    updateActivityPeriod = (activityPeriodId, values) => {
        const params = new URLSearchParams();

        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                case 'display_order':
                    params.append('display_order', values[key]);
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `activity-periods/${activityPeriodId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a Period under an Organization with specified values
     * @param {string} organizationId ID of the Organization to add a Period to
     * @param {object} values Values to create a Period with
     * @param {object} [i18n] I18n object containing the languages array
     * @returns {Promise}
     */
    createPeriod = (organizationId, values, i18n) => {
        const params = new URLSearchParams();

        appendI18nForCreate(params, 'name', values, i18n)

        params.append('organisation_id', organizationId);
        params.append('start_date', values.startDate);
        params.append('end_date', values.endDate);
        !!values.sharedDate && params.append('shared_date', values.sharedDate);
        !!values.calculationDate && params.append('calculation_date', values.calculationDate);
        params.append('active', values.active ? 1 : 0);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `periods` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.period_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a Period under an Organization with specified values
     * @param {string} organizationId ID of the Organization to add a Period to
     * @param {object} values Values to create a Period with
     * @returns {Promise}
     */
    createActivityPeriod = (organizationId, values) => {
        const params = new URLSearchParams();

        params.append('organisation_id', organizationId);

        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;
                default:
                    params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `activity-periods` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.period_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific Period
     * @param {string} periodId ID of the Period to delete
     * @returns {Promise}
     */
    deleteActivityPeriod = (activityPeriodId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `activity-periods/${activityPeriodId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific Period
     * @param {string} periodId ID of the Period to delete
     * @returns {Promise}
     */
    deletePeriod = (periodId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `periods/${periodId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <PeriodsContext.Provider value={{
                ...this.state,
                ...this,
            }}
            >
                {this.props.children}
            </PeriodsContext.Provider>
        )
    }
}

export default withContexts(I18nContext)(PeriodsContextProvider);