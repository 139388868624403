import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import {
    Button,
    Card,
    Collapse
} from "reactstrap";


import FormikEditable from '../../../../components/formik/FormikEditable';
import { FormikInputText } from '@spordle/formik-elements';
import { FieldArray, Form, Formik } from 'formik';
import { object, string, array, mixed, bool, number, ref } from 'yup';

import moment from 'moment';

import Translate from '@spordle/intl-elements';

import GroupSection from './formEditor/GroupSection';
import { FormsContext } from '../../../../contexts/FormsContext';

import SpordleDragDropContext from '../../../../components/DragNDrop/SpordleDragDropContext';
import SpordleDroppable from '../../../../components/DragNDrop/SpordleDroppable';
import SpordleDraggable from '../../../../components/DragNDrop/SpordleDraggable';
import withContexts from '../../../../helpers/withContexts';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { success, fail } from "@spordle/toasts";
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { Link } from 'react-router-dom';

/** @type {React.Context<Omit<CustomForms, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const CustomFormContext = createContext();
CustomFormContext.displayName = 'CustomFormContext';

/*--------------------------------------------------------------------------------*/
/* CSS                                                                  */
/*--------------------------------------------------------------------------------*/
const groupOptions = { overflow: "hidden", Transition: "all 0.2s ease", WebkitTransition: "all 0.2s ease" }
export const groupOptionsOpened = { ...groupOptions, width: "48px" }
export const groupOptionsClosed = { ...groupOptions, width: "0" }


/*--------------------------------------------------------------------------------*/
/* Test Data                                                                  */
/*--------------------------------------------------------------------------------*/

/**
 * @typedef {nakedOption} CustomFormOption
 */
export const nakedOption = {
    fieldOptionId: '',
    fieldOption: '',
    default: false,
    i18n: {
        fr: {
            fieldOption: '',
        },
        en: {
            fieldOption: '',
        },
    },
};

/**
 * @typedef {nakedQuestion} CustomFormQuestion
 */
export const nakedQuestion = {
    /** @type {import('../../../../contexts/FormsContext').FormField['code']} */
    formFieldId: '',
    formFieldCode: 'TEXT',
    title: '',
    placeholder: '',
    is_admin: false,
    mandatory: false,
    max: null,
    min: null,
    i18n: {
        fr: {
            title: '',
            placeholder: '',
        },
        en: {
            title: '',
            placeholder: '',
        },
    },
    /** @type {CustomFormOption[]} */
    options: [], // No options in 'TEXT'
};

/**
 * @typedef {nakedSection} CustomFormSection
 */
export const nakedSection = {
    formGroupId: '',
    name: '',
    i18n: {
        fr: {
            name: '',
        },
        en: {
            name: '',
        },
    },
    /** @type {CustomFormQuestion[]} */
    questions: [],
    // questions: [{
    //     formFieldCode: 'TEXT',
    //     title: '',
    //     placeholder: '',
    //     mandatory: false,
    //     max: null,
    //     min: null,
    //     i18n: {
    //         fr: {
    //             title: '',
    //             placeholder: ''
    //         },
    //         en: {
    //             title: '',
    //             placeholder: ''
    //         }
    //     },
    //     /** @type {CustomFormOption[]} */
    //     options: []
    // }]
};

/**
 * @typedef {object} State
 * @property {Array} customFormsData
 * @property {string} formName
 * @property {number} sectionCount
 */

/**
 * @typedef {object} Props
 * @property {boolean} [newForm]
 * @property {State['customFormsData']} [customFormsData]
 */

/** @type {React.Component<Props, State>} */
class CustomForms extends React.Component{
    static propTypes = {
        newForm: PropTypes.bool,
        formName: PropTypes.string,
        formId: PropTypes.string,
        duplicate: PropTypes.bool,
    }

    static defaultProps = {
        customFormsData: [ nakedSection ],
        formName: 'New form',
    }

    constructor(props){
        super(props);
        this.state = {
            formName: `${props.formName} ${props.duplicate ? '(' + props.intl.formatMessage({ id: 'catalog.forms.components.formList.table.duplicateTitle' }) + ')' : ''}`,
        }
    }

    /*--------------------------------------------------------------------------------*/
    /* React DND Functions                                                            */
    /*--------------------------------------------------------------------------------*/

    onDragEnd = (result) => {
        // dropped outside the list
        if(!result.destination)return;

        if(result.type === 'SECTION'){ // Section drag n drop
            result.source.payload.move(result.source.index, result.destination.index);
        }else if(result.source.droppableId === result.destination.droppableId){ // In the same section
            result.source.payload.move(result.source.index, result.destination.index);
        }else{ // Inter section
            result.source.payload.remove(result.source.index);
            result.destination.payload.insert(result.destination.index, result.payload);
        }
    }

    render(){
        return (
            <div>
                <div className='mb-2'>
                    <Link to="/catalog/forms" className="font-medium">
                        <i className="mdi mdi-arrow-left mr-1" />
                        <Translate id='catalog.forms.forms.back' />
                    </Link>
                </div>

                {/*--------------------------------------------------------------------------------*/}
                {/* Custom Forms Section                                                           */}
                {/*--------------------------------------------------------------------------------*/}
                <Card body className='card-shadow' style={{ zIndex: 5 }}>
                    <div className="bg-white">
                        <div className="d-flex align-items-center">
                            <div className="font-medium w-50">
                                <FormikEditable
                                    id='customFormName'
                                    noConfirmation
                                    initialValues={{
                                        name: this.state.formName,
                                    }}
                                    validationSchema={object().shape({
                                        name: string().required(<Translate id='catalog.forms.components.customFormEditor.formName.required' />),
                                    })}
                                    onSubmit={({ name }) => {
                                        this.setState(() => ({ formName: name }));
                                    }}
                                >
                                    {(isEditing) => {
                                        if(isEditing){
                                            return <FormikInputText autoFocus name='name' trim />
                                        }

                                        return (
                                            <div className='h3 mb-0'>
                                                {this.state.formName}
                                            </div>
                                        );

                                    }}
                                </FormikEditable>
                            </div>
                            <div className="ml-auto">
                                <Button color='primary' type='button' onClick={() => { this.formikRef?.handleSubmit() }}><Translate id={this.props.newForm ? 'misc.create' : 'misc.save'} /></Button>
                            </div>
                        </div>
                        <div className='small text-primary'>
                            <i className='mdi mdi-information-outline mr-1' /><Translate id='catalog.forms.components.customFormEditor.formName.description' />
                        </div>
                    </div>
                </Card>

                <Formik
                    initialValues={{
                        /* To fit this api data structure
                        {
                            // Sections
                            "group[]['form_group_id']": "string",
                            "group[]['name']": "string",
                            "group[]['sort']": 0, // Sent to api when saving
                            "group[]['i18n']['fr']['name']": "string",

                            // Questions
                            "group[]['fields'][]['form_field_id']": "string",
                            "group[]['fields'][]['form_field_code']": "string",
                            "group[]['fields'][]['title']": "string",
                            "group[]['fields'][]['placeholder']": "string",
                            "group[]['fields'][]['mandatory']": "0",
                            "group[]['fields'][]['max']": 0,
                            "group[]['fields'][]['min']": 0,
                            "group[]['fields'][]['sort']": 0, // Sent to api when saving
                            "group[]['fields'][]['i18n'][fr]['title']": "string",
                            "group[]['fields'][]['i18n'][fr]['placeholder']": "string",

                            // Options
                            "group[]['fields'][]['option'][]['field_option']": "string",
                            "group[]['fields'][]['option'][]['default']": 0,
                            "group[]['fields'][]['option'][]['sort']": 0,
                            "group[]['fields'][]['option'][]['i18n']['fr']['field_option']": 0
                        }
                        */
                        /** @type {CustomFormSection[]} */
                        sections: this.props.customFormsData,
                    }}
                    validationSchema={object().shape({
                        sections: array().of(object().shape({
                            formGroupId: string(),
                            name: string(),
                            i18n: object().shape({
                                fr: object().shape({
                                    name: this.props.OrganizationContext.hasFrench() ? string().required(<Translate id='catalog.forms.components.customFormEditor.form.validation.section.name' />) : string(),
                                }),
                                en: object().shape({
                                    name: this.props.OrganizationContext.hasEnglish() ? string().required(<Translate id='catalog.forms.components.customFormEditor.form.validation.section.name' />) : string(),
                                }),
                            }),
                            questions: array().min(1, <Translate id='catalog.forms.components.customFormEditor.form.validation.question.minLength' />).of(object().shape({
                                formFieldId: string(),
                                formFieldCode: string().required(),
                                title: string(),
                                placeholder: string(),
                                mandatory: bool(),
                                max: mixed().nullable().when('formFieldCode', {
                                    is: (formFieldCode) => formFieldCode === 'DATE' || formFieldCode === 'DATETIME',
                                    otherwise: number().nullable().when('min', {
                                        is: (min) => !!min,
                                        then: number().nullable().moreThan(ref('min'), <Translate id='catalog.forms.components.customFormEditor.form.validation.question.max.moreThan' />),
                                    }),
                                    then: mixed()
                                        .test({
                                            name: 'maxDateFormat',
                                            message: <Translate id='catalog.forms.components.customFormEditor.form.validation.question.max.dateFormat' />,
                                            test: function(value){
                                                if(value !== undefined && value !== null){
                                                    return moment.isMoment(value);
                                                }
                                                return true; // because not required

                                            },
                                        })
                                        .test({ // Min date validation
                                            name: 'maxDateMore',
                                            message: <Translate id='catalog.forms.components.customFormEditor.form.validation.question.max.dateFormat.more' />,
                                            test: function(maxDate){
                                                if(moment.isMoment(maxDate) && moment.isMoment(this.parent.min)){
                                                    return maxDate.isAfter(this.parent.min);
                                                }
                                                return true; // because not required

                                            },
                                        }),
                                }),
                                min: mixed().nullable().when('formFieldCode', {
                                    is: (formFieldCode) => formFieldCode === 'DATE' || formFieldCode === 'DATETIME',
                                    otherwise: number().nullable().when('max', {
                                        is: (max) => !!max,
                                        then: number().nullable().lessThan(ref('max'), <Translate id='catalog.forms.components.customFormEditor.form.validation.question.min.lessThan' />),
                                    }),
                                    then: mixed()
                                        .test({ // Format validation
                                            name: 'minDateFormat',
                                            message: <Translate id='catalog.forms.components.customFormEditor.form.validation.question.min.dateFormat' />,
                                            test: function(value){
                                                if(value !== undefined && value !== null){
                                                    return moment.isMoment(value);
                                                }
                                                return true; // because not required

                                            },
                                        }).test({ // Min date validation
                                            name: 'minDateLess',
                                            message: <Translate id='catalog.forms.components.customFormEditor.form.validation.question.min.dateFormat.less' />,
                                            test: function(minDate){
                                                if(moment.isMoment(minDate) && moment.isMoment(this.parent.max)){
                                                    return minDate.isBefore(this.parent.max);
                                                }
                                                return true; // because not required

                                            },
                                        }), // FormikDateTime with string or a moment object
                                }),
                                i18n: object().shape({
                                    fr: object().shape({
                                        title: this.props.OrganizationContext.hasFrench() ? string().required(<Translate id='catalog.forms.components.customFormEditor.form.validation.question.title.required' />) : string(),
                                    }),
                                    en: object().shape({
                                        title: this.props.OrganizationContext.hasEnglish() ? string().required(<Translate id='catalog.forms.components.customFormEditor.form.validation.question.title.required' />) : string(),
                                    }),
                                }),
                                options: array().of(object().shape({
                                    fieldOptionId: string(),
                                    fieldOption: string(),
                                    default: bool(),
                                    i18n: object().shape({
                                        fr: object().shape({
                                            fieldOption: this.props.OrganizationContext.hasFrench() ? string().required(<Translate id='catalog.forms.components.customFormEditor.form.validation.option.fieldOption.required' />) : string(),
                                        }),
                                        en: object().shape({
                                            fieldOption: this.props.OrganizationContext.hasEnglish() ? string().required(<Translate id='catalog.forms.components.customFormEditor.form.validation.option.fieldOption.required' />) : string(),
                                        }),
                                    }),
                                })),
                            })),
                        })),
                    })}
                    onSubmit={(values) => {
                        if(this.props.newForm){ // Create mode
                            return this.props.FormsContext.createForm(values.sections, this.state.formName)
                                .then(() => {
                                    success({ msg: 'catalog.forms.components.customFormEditor.create.success' });
                                    this.props.history.push('/catalog/forms');
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        if(error.i18n){
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }else{
                                            fail({ msg: 'catalog.forms.components.customFormEditor.create.fail' });
                                        }
                                    }
                                })
                        }else if(this.props.duplicate){ // duplicate mode
                            return this.props.FormsContext.createForm(values.sections, `${this.state.formName}`)
                                .then(() => {
                                    success({ msg: 'catalog.forms.components.customFormEditor.create.success' });
                                    this.props.history.push('/catalog/forms');
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        if(error.i18n){
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }else{
                                            fail({ msg: 'catalog.forms.components.customFormEditor.create.fail' });
                                        }
                                    }
                                })
                        }
                        // Update mode
                        return this.props.FormsContext.updateForm(this.props.formId, values.sections, this.state.formName)
                            .then(() => {
                                success({ msg: 'catalog.forms.components.customFormEditor.update.success' });
                                this.props.history.push('/catalog/forms');
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    if(error.i18n){
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                    }else{
                                        fail({ msg: 'catalog.forms.components.customFormEditor.update.fail' });
                                    }
                                }
                            })

                    }}
                    innerRef={(r) => this.formikRef = r}
                >
                    {(formik) => (
                        <CustomFormContext.Provider value={this}>
                            <Form className='mb-5'>
                                <SpordleDragDropContext onDragEnd={this.onDragEnd}>
                                    <FieldArray name='sections'>
                                        {(sectionsArrayHelper) => (
                                            <SpordleDroppable droppableId="sections" type='SECTION' direction='vertical' payload={sectionsArrayHelper}>
                                                {(provided) => {
                                                    return (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {formik.values.sections.map((section, index) => (
                                                                // eslint-disable-next-line react/no-array-index-key
                                                                <SpordleDraggable key={index} draggableId={`sections.${index}`} index={index}>
                                                                    {(provided) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            {/* eslint-disable-next-line react/no-array-index-key */}
                                                                            <Collapse appear isOpen key={index}>
                                                                                <GroupSection
                                                                                    id={`sections.${index}`}
                                                                                    sectionIndex={index}
                                                                                    getSectionIndex={this.getSectionIndex}

                                                                                    addSection={sectionsArrayHelper.handlePush(nakedSection)}
                                                                                    cloneSection={sectionsArrayHelper.handlePush(section)}
                                                                                    deleteSection={sectionsArrayHelper.handleRemove(index)}
                                                                                />
                                                                            </Collapse>
                                                                        </div>
                                                                    )}
                                                                </SpordleDraggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )
                                                }}
                                            </SpordleDroppable>
                                        )}
                                    </FieldArray>
                                </SpordleDragDropContext>
                                <div className='d-flex justify-content-end position-relative' style={{ zIndex: 5 }}>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id={this.props.newForm ? 'misc.create' : 'misc.save'} /></Button>
                                </div>
                            </Form>
                        </CustomFormContext.Provider>
                    )}
                </Formik>
            </div>
        )
    }
}

export default injectIntl(withRouter(withContexts(FormsContext, OrganizationContext)(CustomForms)));