import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Button, Collapse, ModalBody, ModalFooter } from 'reactstrap';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { CartsContext } from '../../contexts/CartsContext';
import { DisplayI18n } from '../../helpers/i18nHelper';
import CustomAlert from '../CustomAlert';
import OverlayLoader from '../loading/OverlayLoader';

const ManualRegistrationCheckout = ({ memberProfileRegistrationAddContext }) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ cartInfo, setCartInfo ] = useState(null);
    const cartsContext = useContext(CartsContext);

    const hasPlatformFees = (cartInfo?.cart_summary?.fee_total || 0) != 0 && memberProfileRegistrationAddContext.viewsInfo.selectedPaymentMethod === 'CREDITCARD'

    const showSpecialPrice = (fees) => {
        let specialFee = false
        fees.forEach((fee) => {
            const _myFee = fee?.fee || fee
            if(_myFee?.early_amount_until && moment().isBefore(_myFee.early_amount_until))
                specialFee = 'earlyFee'
            else if(_myFee?.late_amount && moment().isAfter(_myFee.late_amount_after))
                specialFee = 'lateFee'
        })
        if(specialFee)
            return <div className="text-muted small d-inline-block ml-2">(<Translate id={`catalog.registrationFees.add.form.${specialFee}`} />)</div>
    }

    const checkout = () => {
        setIsLoading(true);
        memberProfileRegistrationAddContext.checkout()
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e.message);
                    setIsLoading(false);
                    setError(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />)
                }
            })
    }

    useEffect(() => {
        setIsLoading(true);
        cartsContext.getCart(cartsContext.state.cachedCart.shopping_cart_id)
            .then((cart) => {
                setCartInfo(cart);
                setIsLoading(false);
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e.message);
                    setIsLoading(false);
                    setError(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />)
                }
            })
    }, [])

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalBody>
                <div className='mb-3'>
                    <div className="h5 font-bold pt-2">
                        <Translate id='components.manualRegistration.manualRegistrationConfirmation' />
                    </div>
                    <div className="border rounded p-3">
                        {memberProfileRegistrationAddContext.viewsInfo.selectedRegistration?.fee &&
                            <div className="mb-3">
                                <div className="font-bold text-dark mb-2">
                                    <DisplayI18n
                                        field='name'
                                        i18n={memberProfileRegistrationAddContext.viewsInfo.selectedRegistration.i18n}
                                        defaultValue={memberProfileRegistrationAddContext.viewsInfo.selectedRegistration.name}
                                    />
                                    {memberProfileRegistrationAddContext.viewsInfo.selectedPeriod &&
                                        <div className="d-inline-block ml-1">
                                            <DisplayI18n
                                                field="name"
                                                defaultValue={memberProfileRegistrationAddContext.viewsInfo.selectedPeriod.name}
                                                i18n={memberProfileRegistrationAddContext.viewsInfo.selectedPeriod.i18n}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="d-flex">
                                    <div className="d-flex align-items-center">
                                        <Translate id='memberProfile.registration.transaction.registrationFee' />
                                        {showSpecialPrice([ memberProfileRegistrationAddContext.viewsInfo.selectedRegistration.fee ])}
                                    </div>
                                    <div className="ml-auto">
                                        <CurrencyFormat value={(cartInfo?.cart_detail?.find((row) => row.item_type === 'REGISTRATION')?.amount || 0) / 100} />
                                    </div>
                                </div>
                                <Collapse isOpen={!!memberProfileRegistrationAddContext.viewsInfo.selectedRegistration.affiliation_fees} className="d-flex">
                                    <div className="d-flex align-items-center">
                                        <Translate id='memberProfile.registration.transaction.annualMembershipFee' />
                                        {showSpecialPrice(memberProfileRegistrationAddContext.viewsInfo.selectedRegistration.affiliation_fees)}
                                    </div>
                                    <div className="ml-auto">
                                        {/* <CurrencyFormat value={calculateFeeSum(memberProfileRegistrationAddContext.viewsInfo.selectedRegistration.affiliation_fees) / 100} /> */}
                                        <CurrencyFormat value={(cartInfo?.cart_detail?.find((row) => row.item_type === 'REGISTRATION')?.affiliation_fee || 0) / 100} />
                                    </div>
                                </Collapse>
                            </div>
                        }
                        { memberProfileRegistrationAddContext.viewsInfo.selectedOtherItems?.length > 0 &&
                            <>
                                {memberProfileRegistrationAddContext.viewsInfo.selectedOtherItems.map((otherItem) => {
                                    const item = memberProfileRegistrationAddContext.viewsInfo.otherItems.find((oFee) => oFee.other_fee_id === otherItem.itemId)

                                    return (
                                        <div key={otherItem.itemId} className='mb-3'>
                                            <div className="font-bold text-dark mb-1">
                                                <DisplayI18n
                                                    field='name'
                                                    i18n={item.i18n}
                                                    defaultValue={item.name}
                                                />
                                            </div>
                                            <div className="d-flex">
                                                <div className="d-flex align-items-center">
                                                    <span><Translate id='memberProfile.registration.transaction.otherItemFee' /><i className="mdi mdi-close mx-1" />{otherItem.quantity}</span>
                                                    {showSpecialPrice([ item.fee ])}
                                                </div>
                                                <div className="ml-auto">
                                                    <CurrencyFormat value={(cartInfo?.cart_detail?.find((row) => row.item_type === 'OTHER' && row.item_id === otherItem.itemId)?.amount || 0) * otherItem.quantity / 100} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        }
                        <hr />
                        {(cartInfo?.cart_summary?.credit_total || 0) != 0 &&
                            <>
                                <div className="d-flex align-items-center">
                                    <div className="font-bold text-dark">
                                        <Translate id='memberProfile.registration.transaction.creditAmount' />
                                    </div>
                                    <div className="ml-auto text-success">
                                        <CurrencyFormat
                                            value={cartInfo?.cart_summary?.credit_total / 100 * -1}
                                        />
                                    </div>
                                </div>
                                <hr />
                            </>
                        }
                        {(hasPlatformFees || (cartInfo?.cart_summary?.tax_total || 0) != 0) &&
                            <>
                                {(cartInfo?.cart_summary?.tax_total || 0) != 0 &&
                                    <>
                                        <div className="d-flex align-items-center">
                                            <div className="font-bold text-dark">
                                                <Translate id='memberProfile.registration.transaction.tax' />
                                            </div>
                                            <div className="ml-auto">
                                                <CurrencyFormat
                                                    value={cartInfo?.cart_summary?.tax_total / 100}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                {hasPlatformFees &&
                                    <>
                                        <div className="d-flex align-items-center">
                                            <div className="font-bold text-dark">
                                                <Translate id='memberProfile.registration.transaction.platformFees' />
                                            </div>
                                            <div className="ml-auto">
                                                <CurrencyFormat
                                                    value={cartInfo?.cart_summary?.fee_total / 100}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                <hr />
                            </>
                        }
                        <div className="d-flex align-items-center">
                            <div className="font-bold text-dark">
                                <Translate id='memberProfile.registration.transaction.totalPrice' />
                            </div>
                            <div className="ml-auto">
                                <CurrencyFormat
                                    value={(hasPlatformFees ? (cartInfo?.cart_summary?.grand_total || 0) : (cartInfo?.cart_summary?.grand_total_without_fee || 0)) / 100}
                                />
                            </div>
                        </div>
                    </div>

                    <Collapse isOpen={!!error} appear mountOnEnter unmountOnExit>
                        {error &&
                            <CustomAlert className='mt-3' color='danger' withTitle text={error} translateText={false} toggle={() => setError(null)} />
                        }
                    </Collapse>
                </div>
            </ModalBody>
            <ModalFooter className='justify-content-between'>
                {memberProfileRegistrationAddContext.canGoBack(memberProfileRegistrationAddContext.views.identities) &&
                    <Button color='primary' outline type='button' onClick={() => memberProfileRegistrationAddContext.goToView(memberProfileRegistrationAddContext.views.identities, true)}><Translate id='misc.previous' /></Button>
                }

                <div className='text-right w-100'>
                    <Button color='primary' type='button' onClick={checkout}><Translate id='misc.confirm' /></Button>
                    {!memberProfileRegistrationAddContext.exchangeMode &&
                        <Button color='primary' type='button' outline onClick={memberProfileRegistrationAddContext.toggle} className='ml-2'><Translate id='misc.cancel' /></Button>
                    }
                </div>
            </ModalFooter>
        </OverlayLoader>
    )
}

export default ManualRegistrationCheckout