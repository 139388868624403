import Translate from '@spordle/intl-elements';
import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
    Alert,
    Col,
    Fade,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import OverlayLoader from '../../../components/loading/OverlayLoader';
import TransferRequestModal from '../../../components/transferRequest/TransferRequestModal';
import AppealsAddContext from '../../../components/appeals/AppealsAddContext';
import { MembersContext } from '../../../contexts/MembersContext';
import { withMountedState } from '../../../helpers/mountedState';
import withContexts from '../../../helpers/withContexts';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { QuickViewContext } from '../../../contexts/QuickViewContext';
import SidePanel from '../../../components/sidePanel/SidePanel';
import MemberMerge from './memberMerge/MemberMerge';
import MemberActionsMenu from '../components/MemberActionsMenu';
import Skeleton from 'react-loading-skeleton';
import UserImg from '../../../components/UserImg';
import UserDisplay from '../../../components/userDisplay/UserDisplay';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import DisplayOrganization from '../../../components/organization/DisplayOrganization';
import { RolesContext } from '../../../contexts/RolesContext';
import { stringBuilder } from '@spordle/helpers';
import { DisplayPhoneNumber, TelHref } from '@spordle/formik-elements';
import { fail } from "@spordle/toasts";
import StatusBadge from '../../../components/badges/StatusBadge';
import BlockMemberModal from '../profile/profileHeader/components/BlockMemberModal';
import UnblockMemberModal from '../profile/profileHeader/components/UnblockMemberModal';
import MakeIneligibleModal from '../profile/profileHeader/components/MakeIneligibleModal';
import RemoveMemberIneligibilityModal from '../profile/profileHeader/components/RemoveMemberIneligibilityModal';
import MemberConfirmModal from '../profile/profileHeader/components/MemberConfirmModal';
import ReactivateMemberModal from '../profile/profileHeader/components/ReactivateMemberModal';
import moment from 'moment';
import QuickViewButton from '../../../components/quickView/QuickViewButton';
import SidePanelMemberSearchMulti from './SidePanelMemberSearchMulti';
import MemberStatusIcons from './components/MemberStatusIcons';
import ValidateMemberModal from '../../../components/validateMember/ValidateMemberModal';
import SendCommModal from '../../../components/communications/SendCommModal';
import TeamHistorySidepanel from '../../teams/profile/TeamRoster/components/TeamHistorySidepanel';
import CanDoAction from '../../../components/permissions/CanDoAction';
import generatePassword from '../../../helpers/passwordGenerator';
import { Tooltip } from "@mantine/core";
import InlineCopy from '../../../components/inlineCopy/InlineCopy';
import ChangeMemberStatusModal from '../profile/profileHeader/components/ChangeMemberStatusModal';
import MemberFieldLabel from '../../../components/memberRegistration/MemberFieldLabel';
import DisplayBirthdateAge from '../../../components/DisplayBirthdateAge';
import SidePanelMemberSearchMemberType from './SidePanelMemberSearchMemberType';
import GenderLabel from '../../../components/GenderLabel';
import ManageMemberTypesModal from '../profile/profileHeader/components/memberTypes/ManageMemberTypesModal';

class SidePanelMemberSearch extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            activeTab: '1',
            movementRequest: false, // modal
            manualTransfer: false, // modal
            appeals: false, // modal
            memberMerge: false, // modal
            block: false,
            unblock: false,
            ineligible: false,
            removeIneligible: false,
            confirm: false,
            reactivate: false,
            validateMember: false,
            sendCommIsOpen: false,
            manageMemberTypes: false,

            changeStatusModal: false,
            statusModalInitialValue: '',

            isLoading: true,


            hasTransferRequest: true,
            emergencyContact: null,
            member: {},
        }
    }

    getAddress = () => {
        if(!this.state.isLoading && this.state.member.addresses){
            const address = this.state.member.addresses.find((address) => address.default_address == '1' && address.active == '1') || this.state.member.addresses.find((address) => address.active == '1') || this.state.member.addresses[0];// Check for the first active default_address, then the first active address, then the first address
            if(address){
                const formattedAddress = `${address.street_number} ${address.street}, ${address.city}, ${address.province_id} ${address.zip_code}, ${address.country_id}`;
                const url = address.map_url || `https://maps.google.com/?q=${formattedAddress}`;
                return (
                    <address className="d-flex">
                        {/* <i className="mdi mdi-map-marker mr-1" /> */}
                        <span>
                            <span>{address.street_number} {address.street}, {address.city}</span>
                            <span className="d-block">{address.province_id}, {address.zip_code}, {address.country_id}</span>
                            <a className="small" href={url} target='_blank' rel='noopener noreferrer'>
                                <Translate id='misc.googlePlaces.googleMaps' /><i className="mdi mdi-launch ml-1" />
                            </a>
                        </span>
                    </address>
                )
            }
            return null;
        }
        return <Skeleton />
    }

    toggleMovementRequest = () => {
        this.setState((prevState) => ({ movementRequest: !prevState.movementRequest }))
    }

    toggleManualTransfer = () => {
        this.setState((prevState) => ({ manualTransfer: !prevState.manualTransfer }))
    }

    toggleAppeals = () => {
        this.setState((prevState) => ({ appeals: !prevState.appeals }))
    }

    toggleMemberMerge = () => {
        this.setState((prevState) => ({ memberMerge: !prevState.memberMerge }))
    }

    toggleMemberBlock = () => {
        this.setState((prevState) => ({ block: !prevState.block }))
    }

    toggleMemberUnblock = () => {
        this.setState((prevState) => ({ unblock: !prevState.unblock }))
    }

    toggleMemberIneligible = () => {
        this.setState((prevState) => ({ ineligible: !prevState.ineligible }))
    }

    toggleMemberRemoveIneligible = () => {
        this.setState((prevState) => ({ removeIneligible: !prevState.removeIneligible }))
    }

    toggleMemberConfirm = () => {
        this.setState((prevState) => ({ confirm: !prevState.confirm }))
    }

    toggleMemberReactivate = () => {
        this.setState((prevState) => ({ reactivate: !prevState.reactivate }))
    }

    toggleValidateMember = () => {
        this.setState((prevState) => ({ validateMember: !prevState.validateMember }))
    }

    toggleSendComm = () => {
        this.setState((prevState) => ({ sendCommIsOpen: !prevState.sendCommIsOpen }))
    }

    toggleChangeStatusModal = () => {
        this.setState((prevState) => ({ changeStatusModal: !prevState.changeStatusModal }))
    }

    setStatusModalInitialValue = (statusCode) => {
        this.setState(() => ({ statusModalInitialValue: statusCode }))
    }

    toggleManageMemberTypesModal = () => {
        this.setState((prevState) => ({ manageMemberTypes: !prevState.manageMemberTypes }))
    }

    getMemberInfos = () => {
        return this.props.MembersContext.getMember(this.props.selectedRows[0].member_id)
            .then((member) => {
                if(this.props.isMounted){
                    this.setState(() => ({
                        member: member,
                        emergencyContact: member.contacts.find((contact) => contact.emergency == 1),
                        hasTransferRequest: member.active_transfer == '1',
                        isLoading: false,
                    }));
                }
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({
                        member: {},
                        emergencyContact: null,
                        hasTransferRequest: true,
                        isLoading: false,
                    }));
                }
            })
    }

    refreshMember(){
        this.props.tableRef.refreshTable();
        this.getMemberInfos();
    }


    componentDidMount(){
        this.getMemberInfos();
    }

    componentDidUpdate(prevProps){
        if(this.props.selectedRows[0]){
            if(this.props.selectedRows[0].member_id !== prevProps.selectedRows[0].member_id){
                this.setState(() => ({ isLoading: true }));
                this.getMemberInfos();
            }
        }
    }

    render(){
        const member = this.props.selectedRows[0];
        const lastLog = member.member_status_logs ? member.member_status_logs?.[member.member_status_logs.length - 1] || null : null;
        const canSeeNoteAndReason = member?.last_member_status_note && (member?.member_status.name !== "INELIGIBLE" ||
        (member?.member_status.name === "INELIGIBLE" && this.props.RolesContext.hasAccessTo('members', 'manage_ineligible_members')))

        return (
            <Fade>
                <OverlayLoader isLoading={this.state.isLoading && this.props.selectedRows.length <= 1}>
                    {(this.props.selectedRows.length <= 1) ?
                        <>
                            <div className="sticky-top bg-white z-index-1">
                                <SidePanel.Header noBorder className={this.props.hideHeader && 'd-none'}>
                                    <div className='d-flex mb-3 align-items-center'>
                                        <SidePanel.ToggleButton />
                                        <div className="ml-auto d-flex align-items-center">
                                            <Link to={`/members/profile/${this.props.selectedRows[0].member_id}`} className='mr-2'>
                                                <Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" />
                                            </Link>
                                            <ActionButton
                                                // SET STATUS
                                                confirmOnClick={() => { this.setStatusModalInitialValue('CONFIRMED'); this.toggleChangeStatusModal(); }}
                                                blockMemberOnClick={() => { this.setStatusModalInitialValue('BLOCKED'); this.toggleChangeStatusModal(); }}
                                                ineligibleMemberOnClick={() => { this.setStatusModalInitialValue('INELIGIBLE'); this.toggleChangeStatusModal(); }}
                                                unconfirmOnClick={() => { this.setStatusModalInitialValue('UNCONFIRMED'); this.toggleChangeStatusModal(); }}

                                                // REVERT
                                                removeIneligibleMemberOnClick={() => { this.setStatusModalInitialValue(this.props.selectedRows[0].member_status_logs[this.props.selectedRows[0].member_status_logs.length - 2].member_status.code); this.toggleChangeStatusModal(); }}
                                                unblockMemberOnClick={() => { this.setStatusModalInitialValue(this.props.selectedRows[0].member_status_logs[this.props.selectedRows[0].member_status_logs.length - 2].member_status.code); this.toggleChangeStatusModal(); }}


                                                tableRef={this.props.tableRef}
                                                transferOnClick={this.toggleMovementRequest}
                                                transferManualOnClick={this.toggleManualTransfer}
                                                appealsOnClick={this.toggleAppeals}
                                                mergeMembersOnClick={this.toggleMemberMerge}
                                                reactivateOnClick={this.toggleMemberReactivate}
                                                validateMemberOnClick={this.toggleValidateMember}
                                                sendCommOnClick={this.toggleSendComm}
                                                manageMemberTypesOnClick={this.toggleManageMemberTypesModal}

                                                member={{ ...this.props.selectedRows[0], ...this.state.member, hasTransferRequest: this.state.hasTransferRequest }}
                                                minimalisticView={this.state.member.minimalistic_view == 1}
                                                toggle={this.props.toggle}
                                            />

                                            {/* STATUS CHANGE MODALS  */}
                                            <ChangeMemberStatusModal
                                                isOpen={this.state.changeStatusModal}
                                                toggle={() => {
                                                    this.toggleChangeStatusModal();
                                                    this.setState(() => ({ statusModalInitialValue: '' }))
                                                }}
                                                member={member}
                                                refreshMemberProfile={() => {
                                                    this.refreshMember();
                                                }}
                                                statusModalInitialValue={this.state.statusModalInitialValue}
                                                // statusModalInitialValue={'INELIGIBLE'}
                                            />


                                            {/* MODALS */}
                                            <ReactivateMemberModal
                                                isOpen={this.state.reactivate}
                                                toggle={() => this.toggleMemberReactivate()}
                                                member={this.props.selectedRows[0]}
                                                refreshMemberProfile={() => {
                                                    this.props.tableRef.refreshTable();
                                                }}
                                                forceCloseSidepanel={this.props.forceCloseSidePanel}
                                            />
                                            <MemberConfirmModal
                                                isOpen={this.state.confirm}
                                                toggle={() => this.toggleMemberConfirm()}
                                                member={this.props.selectedRows[0]}
                                                refreshMemberProfile={() => {
                                                    this.props.tableRef.refreshTable();
                                                }}
                                                forceCloseSidepanel={this.props.forceCloseSidePanel}
                                            />
                                            <BlockMemberModal
                                                isOpen={this.state.block}
                                                toggle={() => this.toggleMemberBlock()}
                                                member={this.props.selectedRows[0]}
                                                refreshMemberProfile={() => {
                                                    this.props.tableRef.refreshTable();
                                                }}
                                                forceCloseSidepanel={this.props.forceCloseSidePanel}
                                            />
                                            <UnblockMemberModal
                                                isOpen={this.state.unblock}
                                                toggle={() => this.toggleMemberUnblock()}
                                                member={this.props.selectedRows[0]}
                                                refreshMemberProfile={() => {
                                                    this.props.tableRef.refreshTable();
                                                }}
                                                forceCloseSidepanel={this.props.forceCloseSidePanel}
                                            />
                                            <MakeIneligibleModal
                                                isOpen={this.state.ineligible}
                                                toggle={() => this.toggleMemberIneligible()}
                                                member={this.props.selectedRows[0]}
                                                refreshMemberProfile={() => {
                                                    this.props.tableRef.refreshTable();
                                                }}
                                                forceCloseSidepanel={this.props.forceCloseSidePanel}
                                            />
                                            <RemoveMemberIneligibilityModal
                                                isOpen={this.state.removeIneligible}
                                                toggle={() => this.toggleMemberRemoveIneligible()}
                                                member={this.props.selectedRows[0]}
                                                refreshMemberProfile={() => {
                                                    this.props.tableRef.refreshTable();
                                                }}
                                                forceCloseSidepanel={this.props.forceCloseSidePanel}
                                            />
                                            <MemberMerge
                                                refreshTable={() => {
                                                    this.props.tableRef.refreshTable();
                                                    this.props.forceCloseSidePanel();
                                                }}
                                                memberMerge={this.props.selectedRows[0]}
                                                toggle={this.toggleMemberMerge}
                                                isOpen={this.state.memberMerge}
                                            />
                                            <TransferRequestModal
                                                isOpen={this.state.movementRequest}
                                                toggle={this.toggleMovementRequest}
                                                memberId={this.props.selectedRows[0].member_id}
                                                memberOrgId={this.props.selectedRows[0].organisation?.organisation_id}
                                                updateSidePanel={this.getMemberInfos}
                                                memberAge={this.props.selectedRows[0].age}
                                            />
                                            <TransferRequestModal
                                                isOpen={this.state.manualTransfer}
                                                toggle={this.toggleManualTransfer}
                                                memberId={this.props.selectedRows[0].member_id}
                                                memberOrgId={this.props.selectedRows[0].organisation?.organisation_id}
                                                updateSidePanel={this.getMemberInfos}
                                                manualTransferMode
                                            />
                                            <AppealsAddContext
                                                isOpen={this.state.appeals}
                                                toggle={this.toggleAppeals}
                                                memberId={this.props.selectedRows[0].member_id}
                                            />
                                            <ValidateMemberModal
                                                isOpen={this.state.validateMember}
                                                toggle={this.toggleValidateMember}
                                                member={this.props.selectedRows[0]}
                                            />
                                            <SendCommModal
                                                id="memberSearchSingleSelection"
                                                isOpen={!!this.state.sendCommIsOpen}
                                                toggle={this.toggleSendComm}
                                                recipients={this.props.selectedRows || []}
                                            />
                                            <ManageMemberTypesModal
                                                isOpen={this.state.manageMemberTypes}
                                                toggle={(success) => {
                                                    if(success){
                                                        this.refreshMember();
                                                    }
                                                    this.toggleManageMemberTypesModal()
                                                }}
                                                memberTypes={this.state.member.member_types}
                                                memberId={this.props.selectedRows[0].member_id}
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center">
                                        <UserImg
                                            alt={this.props.selectedRows[0].first_name + ' ' + this.props.selectedRows[0].last_name}
                                            abbr={this.props.selectedRows[0].first_name.charAt(0) + this.props.selectedRows[0].last_name.charAt(0)}
                                            src={this.props.selectedRows[0].picture?.full_path}
                                            filePos={this.props.selectedRows[0].picture?.file_position}
                                            width={60}
                                            className="mr-2"
                                        />
                                        <div className="w-100">
                                            <div className="d-flex align-items-center text-break">
                                                <span className='h4 font-bold mb-0'>
                                                    <QuickViewButton member={this.props.selectedRows[0]}>
                                                        {this.props.selectedRows[0].first_name} {this.props.selectedRows[0].last_name}
                                                    </QuickViewButton>
                                                </span>
                                                <MemberStatusIcons member={this.props.selectedRows[0]} tooltipPosition='end' />
                                            </div>
                                            <div>
                                                <InlineCopy tag="span" toCopy={this.props.selectedRows[0].unique_identifier}>
                                                    <Link className="text-nowrap" to={`/members/profile/${this.props.selectedRows[0].member_id}`}>
                                                        {this.props.selectedRows[0].unique_identifier ?
                                                            <>#{this.props.selectedRows[0].unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                            :
                                                            '-'
                                                        }
                                                    </Link>
                                                </InlineCopy>
                                                {this.props.selectedRows[0].member_status && <div><StatusBadge color={this.props.selectedRows[0].member_status.color_code} status={this.props.selectedRows[0].member_status} icon={this.props.selectedRows[0].has_been_merged == 1 ? <i className='mdi mdi-call-merge' /> : null} /></div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 mb-1">
                                        {this.getAddress()}
                                    </div>
                                    {this.props.selectedRows[0].with_unconfirmed_address != 0 &&
                                        <div className="mt-3 clickable" onClick={() => { this.props.history.push({ pathname: `/members/profile/${this.props.selectedRows[0].member_id}`, search: `?goto=addressComponentMemberProfile` }) }}>
                                            <Alert color='danger' className='border shadow-sm d-flex mb-1'>
                                                <i className='mdi mdi-information-outline font-18 align-self-center' />
                                                <div className='ml-2'>
                                                    <div>
                                                        <Translate id='members.search.sidePanelMemberSearch.contact.unconfirmedAddress' />
                                                    </div>
                                                    <div className="text-primary">
                                                        <Translate id='members.search.sidePanelMemberSearch.contact.clickToVerifyAddress' />
                                                        <i className="ml-1 mdi mdi-chevron-right" />
                                                    </div>
                                                </div>
                                            </Alert>
                                        </div>
                                    }
                                </SidePanel.Header>
                            </div>
                            {!this.props.hideTeamsTab &&
                                <CanDoAction componentCode='teams' componentPermissionCode='teams_profile' action='READ'>
                                    <Nav tabs>
                                        <NavItem className="text-center" style={{ width: '100%' }}>
                                            <NavLink
                                                className={stringBuilder({ active: this.state.activeTab === '1' })}
                                                onClick={() => { this.setState(() => ({ activeTab: '1' })) }}
                                            >
                                                <Translate id='members.search.sidePanelMemberSearch.section.profileInfo' />
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="text-center" style={{ width: '100%' }}>
                                            <NavLink
                                                className={stringBuilder({ active: this.state.activeTab === '2' })}
                                                onClick={() => { this.setState(() => ({ activeTab: '2' })) }}
                                            >
                                                <Translate id='teams.profile.roster.sidePanel.tabs.teamHistory' />
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CanDoAction>
                            }
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Fade in={this.state.activeTab === '1'}>
                                        <div className="p-3 border-bottom">
                                            <div className="h4 font-bold mb-3"><Translate id='members.search.sidePanelMemberSearch.section.profileInfo' /></div>
                                            {this.props.selectedRows[0].member_status_logs?.[this.props.selectedRows[0].member_status_logs?.length - 1]?.note &&
                                                <Alert color='light' className='border shadow-sm mb-3'>
                                                    <div className='font-bold'><Translate id={`members.profile.memberProfile.header.info`} values={{ status: <DisplayI18n field='name' defaultValue={this.props.selectedRows[0].member_status.name} i18n={this.props.selectedRows[0].member_status.i18n} /> }} /></div>
                                                    <div>
                                                        <span className='font-medium'>
                                                            <Translate id='members.profile.memberProfile.header.updated_by' />
                                                        </span>
                                                        {this.props.selectedRows[0].member_status_logs?.[this.props.selectedRows[0].member_status_logs?.length - 1]?.created_by?.name
                                                            ?
                                                            this.props.selectedRows[0].member_status_logs?.[this.props.selectedRows[0].member_status_logs?.length - 1]?.created_by?.name + ' ' + this.props.selectedRows[0].member_status_logs?.[this.props.selectedRows[0].member_status_logs?.length - 1]?.created_by?.family_name
                                                            :
                                                            <Translate id='members.profile.memberProfile.header.systemUpdate' />}
                                                    </div>
                                                    <div>
                                                        <span className='font-medium'>
                                                            <Translate id='members.profile.memberProfile.header.updated_at' />
                                                        </span>
                                                        {moment(this.props.selectedRows[0].member_status_logs?.[this.props.selectedRows[0].member_status_logs?.length - 1]?.created_at) ? moment(this.props.selectedRows[0].member_status_logs?.[this.props.selectedRows[0].member_status_logs?.length - 1]?.created_at).format('YYYY-MM-DD') : '-'}
                                                    </div>
                                                    {canSeeNoteAndReason &&
                                                        <>
                                                            {(lastLog && lastLog.member_status_reason) &&
                                                                <div className='mb-1'>
                                                                    <div className="font-medium"><Translate id='form.fields.reason' />: </div>
                                                                    <DisplayI18n field='name' defaultValue={lastLog.member_status_reason.name} i18n={lastLog.member_status_reason.i18n} />
                                                                </div>
                                                            }
                                                            {this.props.selectedRows[0].last_member_status_note && (this.props.selectedRows[0].member_status.name !== "INELIGIBLE" || (this.props.selectedRows[0].member_status.name === "INELIGIBLE" && this.props.RolesContext.hasAccessTo('members', 'manage_ineligible_members'))) &&
                                                                <div className="mb-2">
                                                                    <div className="font-medium"><Translate id='members.profile.blockModal.field.note' />:</div>
                                                                    <div>
                                                                        {this.props.selectedRows[0].last_member_status_note.split('|').length > 1 ?
                                                                            <ul className='pl-4'>
                                                                                {
                                                                                    this.props.selectedRows[0].last_member_status_note.split('|').map((note) => {
                                                                                        const id = generatePassword();
                                                                                        if(note === 'NEW_PROFILE' || note === 'ADDRESS_CHNAGE' || note === 'ADDRESS_CHANGE' || note === 'BASIC_FIELDS_CHANGE' || note === 'HISTORICAL_SANCTION') // added ADDRESS_CHANGE in case someone fixes the spelling of the enum
                                                                                            return <li key={id}><Translate id={`members.profile.header.note.${note}`} /></li>
                                                                                        return <li key={id}>{note}</li>;
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                            :
                                                                            <div>
                                                                                {
                                                                                    (this.props.selectedRows[0].last_member_status_note === 'NEW_PROFILE' || this.props.selectedRows[0].last_member_status_note === 'ADDRESS_CHNAGE' || this.props.selectedRows[0].last_member_status_note === 'ADDRESS_CHANGE' || this.props.selectedRows[0].last_member_status_note === 'BASIC_FIELDS_CHANGE' || this.props.selectedRows[0].last_member_status_note === 'HISTORICAL_SANCTION') ? // added ADDRESS_CHANGE in case someone fixes the spelling of the enum
                                                                                        <Translate id={`members.profile.header.note.${this.props.selectedRows[0].last_member_status_note}`} />
                                                                                        :
                                                                                        this.props.selectedRows[0].last_member_status_note
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </Alert>
                                            }
                                            <Row form>
                                                <Col sm="6" className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="gender"
                                                        fallbackLabel={<Translate id='members.search.sidePanelMemberSearch.profileInfo.gender' />}
                                                    />
                                                    {member.gender_identity ?
                                                        <div>{member.gender_identity}</div>
                                                        :
                                                        <GenderLabel gender={member.gender} />
                                                    }
                                                </Col>
                                                <Col sm="6" className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="birthdate"
                                                        fallbackLabel={<Translate id='members.search.sidePanelMemberSearch.profileInfo.birthDate' />}
                                                    />
                                                    <DisplayBirthdateAge birthdate={this.props.selectedRows[0].birthdate || this.state.member.birthdate} age={this.props.selectedRows[0].age || this.state.member.age} defaultValue="-" />
                                                </Col>
                                                <Col sm="6" className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="primary_language"
                                                        fallbackLabel={<Translate id='form.fields.primaryLanguage' />}
                                                    />
                                                    <div>
                                                        {this.state.member.primary_language ?
                                                            <Translate id={`members.search.sidePanelMemberSearch.profileInfo.language.${this.state.member.primary_language.toUpperCase()}`} />
                                                            :
                                                            <Translate id='misc.none' />
                                                        }
                                                    </div>
                                                </Col>
                                                <Col sm="6" className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="secondary_languages"
                                                        fallbackLabel={<Translate id='form.fields.secondaryLanguages' />}
                                                    />
                                                    {this.state.member.secondary_languages?.length === 0 ?
                                                        <Translate id='misc.none' />
                                                        :
                                                        <div>{this.state.member.secondary_languages?.map((language, index) => <React.Fragment key={language}><Translate id={`members.search.sidePanelMemberSearch.profileInfo.language.${language.toUpperCase()}`} />{this.state.member.secondary_languages.length - 1 !== index && ', '}</React.Fragment>)}</div>
                                                    }
                                                </Col>
                                                <Col sm="12" className="mb-3">
                                                    <div className='text-muted'>
                                                        <Translate id='misc.organization' />
                                                    </div>
                                                    <div>
                                                        <DisplayOrganization organisation={this.props.selectedRows[0].organisation || this.state.member.organisation}>
                                                            <DisplayI18n
                                                                field="name"
                                                                defaultValue={this.props.selectedRows[0].organisation?.organisation_name || this.state.member.organisation?.organisation_name}
                                                                i18n={this.props.selectedRows[0].organisation?.i18n || this.state.member.organisation?.i18n}
                                                            />
                                                        </DisplayOrganization>
                                                    </div>
                                                </Col>
                                                <Col sm="12" className="mb-3">
                                                    <div className='text-muted'>
                                                        <Translate id='members.search.sidePanelMemberSearch.profileInfo.branch' />
                                                    </div>
                                                    {this.props.selectedRows[0].branch ?
                                                        <div>
                                                            <DisplayI18n
                                                                field="name"
                                                                defaultValue={this.props.selectedRows[0].branch?.organisation_name}
                                                                i18n={this.props.selectedRows[0].branch?.i18n}
                                                            />
                                                        </div>
                                                        : '-'}
                                                </Col>
                                                {(this.props.selectedRows[0].member_types || []).length > 0 &&
                                                    <Col sm={12}>
                                                        <div className="text-muted">
                                                            <Translate id='members.search.memberSearch.table.memberTypes' />
                                                        </div>
                                                        {this.props.selectedRows[0].member_types?.map((type) => (
                                                            <div key={type.member_type?.member_type_id}>
                                                                <SidePanelMemberSearchMemberType
                                                                    memberType={type}
                                                                    member={this.props.selectedRows[0]}
                                                                    onSuccess={this.props.tableRef.refreshTable}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Col>
                                                }
                                            </Row>
                                            {/* <Row form>
                                                <Col sm="6">
                                                    <div className="mb-3">
                                                        <div className="text-muted">NCCP</div>
                                                        <div className="font-medium">#435345345</div>
                                                    </div>
                                                </Col>
                                                <Col sm="6">
                                                    <div className="mb-3">
                                                        <div className="text-muted">Respect in Sports</div>
                                                        <div className="font-medium">#435345345</div>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                        </div>
                                        <div className="p-3">
                                            <div className="h4 font-bold mb-3"><Translate id='members.search.sidePanelMemberSearch.section.contact' /></div>
                                            {this.state.member?.contacts?.length > 0 ?
                                                <div>
                                                    <div className="mb-3">
                                                        <div className="h6 text-muted mb-2"><Translate id='members.search.sidePanelMemberSearch.contact.primary' /></div>
                                                        {this.state.member.contacts?.[0] ?
                                                            <UserDisplay className="mb-4">
                                                                <UserDisplay.Container>
                                                                    <UserImg
                                                                        className="text-uppercase"
                                                                        abbr={this.state.member.contacts[0].first_name.charAt(0) + this.state.member.contacts[0].last_name.charAt(0)}
                                                                        alt={this.state.member.contacts[0].first_name + ' ' + this.state.member.contacts[0].last_name}
                                                                        src={this.state.member.contacts[0].picture?.full_path}
                                                                        filePos={this.state.member.contacts[0].picture?.file_position}
                                                                        width={45}
                                                                    />
                                                                </UserDisplay.Container>
                                                                <UserDisplay.Container>
                                                                    <UserDisplay.Title>{this.state.member.contacts[0].first_name} {this.state.member.contacts[0].last_name}</UserDisplay.Title>
                                                                    {this.state.member.contacts[0].phones[0] &&
                                                                        <UserDisplay.Subtitle>
                                                                            <TelHref phoneString={this.state.member.contacts[0].phones[0].phone_number}>
                                                                                <DisplayPhoneNumber phoneString={this.state.member.contacts[0].phones[0].phone_number} format='INTERNATIONAL' />
                                                                            </TelHref>
                                                                        </UserDisplay.Subtitle>
                                                                    }
                                                                    <UserDisplay.Subtitle className="text-truncate">
                                                                        <a title={this.state.member.contacts[0].email} rel="noopener noreferrer" href={`mailto:${this.state.member.contacts[0].email}`} target='_blank'>{this.state.member.contacts[0].email}</a>
                                                                    </UserDisplay.Subtitle>
                                                                </UserDisplay.Container>
                                                            </UserDisplay>
                                                            :
                                                            <div> - </div>
                                                        }
                                                    </div>
                                                    <div className="h6 text-muted mb-2"><Translate id='members.search.sidePanelMemberSearch.contact.emergency' /></div>
                                                    {this.state.emergencyContact ?
                                                        <UserDisplay>
                                                            <UserDisplay.Container>
                                                                <UserImg
                                                                    className="text-uppercase"
                                                                    abbr={this.state.emergencyContact.first_name.charAt(0) + this.state.emergencyContact.last_name.charAt(0)}
                                                                    alt={this.state.emergencyContact.first_name + ' ' + this.state.emergencyContact.last_name}
                                                                    src={this.state.emergencyContact.picture?.full_path}
                                                                    filePos={this.state.emergencyContact.picture?.file_position}
                                                                    width={45}
                                                                />
                                                            </UserDisplay.Container>
                                                            <UserDisplay.Container>
                                                                <UserDisplay.Title>{this.state.emergencyContact.first_name} {this.state.emergencyContact.last_name}</UserDisplay.Title>
                                                                {this.state.emergencyContact.phones[0] &&
                                                                    <UserDisplay.Subtitle>
                                                                        <TelHref phoneString={this.state.emergencyContact.phones[0].phone_number}>
                                                                            <DisplayPhoneNumber phoneString={this.state.emergencyContact.phones[0].phone_number} format='INTERNATIONAL' />
                                                                        </TelHref>
                                                                    </UserDisplay.Subtitle>
                                                                }
                                                                <UserDisplay.Subtitle className="text-truncate">
                                                                    <a title={this.state.emergencyContact.email} rel="noopener noreferrer" href={`mailto:${this.state.emergencyContact.email}`} target='_blank'>{this.state.emergencyContact.email}</a>
                                                                </UserDisplay.Subtitle>
                                                            </UserDisplay.Container>
                                                        </UserDisplay>
                                                        :
                                                        <div> - </div>
                                                    }
                                                </div>
                                                :
                                                <div className="h6 text-body mb-2"><Translate id='members.search.sidePanelMemberSearch.contact.noContacts' /></div>
                                            }
                                        </div>
                                    </Fade>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Fade in={this.state.activeTab === '2'}>
                                        <div className="p-3">
                                            {(Object.keys(this.state.member)?.length > 0 && this.props.RolesContext.canDoAction("READ", "teams", "teams_profile")) &&
                                                <TeamHistorySidepanel isMemberSearch teamMember={{ member: this.state.member }} sidePanelProps={this.props} />
                                            }
                                        </div>
                                    </Fade>
                                </TabPane>
                            </TabContent>
                        </>
                        :
                        <SidePanelMemberSearchMulti
                            members={this.props.selectedRows}
                            {...this.props}
                        />
                    }
                </OverlayLoader>
            </Fade>
        );
    }
}


export const ActionButton = ({
    tableRef,
    transferOnClick,
    transferManualOnClick,
    appealsOnClick,
    mergeMembersOnClick,
    blockMemberOnClick,
    unblockMemberOnClick,
    removeIneligibleMemberOnClick,
    ineligibleMemberOnClick,
    confirmOnClick,
    unconfirmOnClick,
    reactivateOnClick,
    validateMemberOnClick,
    sendCommOnClick,
    manageMemberTypesOnClick,

    member,
    minimalisticView,
    toggle,
}) => {
    const rolesContext = useContext(RolesContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const organizationContext = useContext(OrganizationContext);

    const actionTooltipId = member.member_status?.name === 'INELIGIBLE'
        ?
        `members.search.sidePanelMemberSearch.disabled.actions.${member.member_status?.name}`
        : (member.member_status == null || member.member_status_logs == null)
            ? `members.search.sidePanelMemberSearch.disabled.actions.noStatus`
            : '';

    const createdAsPermanentlyIneligible = member.member_status_logs && member.member_status_logs.length === 1 && member.member_status_logs?.[0]?.member_status?.code == "INELIGIBLE"

    return (
        <div>
            <Tooltip
                position='left'
                withArrow
                disabled={!actionTooltipId}
                label={actionTooltipId ? <Translate id={actionTooltipId} /> : ""}
                zIndex={3000}
            >
                {(
                    identityRolesContext.federation.organisation_id !== organizationContext.organisation_id ||
                rolesContext.canDoAction('ADD', 'members', 'members_request_transfer_usa', true) ||
                rolesContext.canDoAction('ADD', 'members', 'members_request_transfer_international', true) ||
                rolesContext.canDoAction('ADD', 'members', 'participant_transfer_bypass', true) ||
                rolesContext.canDoAction('ADD', 'members', 'member_appeal_request', true) ||
                rolesContext.canDoAction('ADD', 'members', 'members_merge', true) ||
                rolesContext.canDoAction('ADD', 'members', 'manage_ineligible_members', true) ||
                rolesContext.canDoAction('ADD', 'communications', 'communications_manage', true) ||
                rolesContext.canDoAction('DELETE', 'members', 'manage_ineligible_members', true) ||
                rolesContext.canDoAction('EDIT', 'members', 'member_types')

                ) &&
                <SidePanel.ActionsMenu id='sidepanel-member-search-actions-menu' disabled={(!rolesContext.canDoAction('DELETE', 'members', 'manage_ineligible_members') && member.member_status?.name === 'INELIGIBLE') || createdAsPermanentlyIneligible}>
                    <MemberActionsMenu
                        tableRef={tableRef}
                        transferOnClick={transferOnClick}
                        transferManualOnClick={transferManualOnClick}
                        appealsOnClick={appealsOnClick}
                        mergeMembersOnClick={mergeMembersOnClick}
                        manageMemberTypesOnClick={manageMemberTypesOnClick}

                        // Status
                        blockMemberOnClick={blockMemberOnClick}
                        unblockMemberOnClick={unblockMemberOnClick}
                        removeIneligibleMemberOnClick={removeIneligibleMemberOnClick}
                        ineligibleMemberOnClick={ineligibleMemberOnClick}
                        confirmOnClick={confirmOnClick}
                        unconfirmOnClick={unconfirmOnClick}

                        reactivateOnClick={reactivateOnClick}
                        validateMemberOnClick={validateMemberOnClick}
                        sendCommOnClick={sendCommOnClick}
                        member={member}
                        minimalisticView={minimalisticView}
                        toggle={toggle}
                    />
                </SidePanel.ActionsMenu>
                }
            </Tooltip>
        </div>
    );
};


export default withMountedState(withRouter(withContexts(MembersContext, OrganizationContext, IdentityRolesContext, RolesContext, QuickViewContext)(SidePanelMemberSearch)));

