import editModal from "./editModal";
import submitTicket from "./submitTicket";
import userGuide from "./userGuide";

export default{
    ...submitTicket,
    ...userGuide,
    ...editModal,

    "helpCenter.resources"    : "Resources",
    "helpCenter.title"        : "Help Center",
    "helpCenter.userGuide"    : "User Guide",
    "helpCenter.submitTicket" : "Support request",
    "helpCenter.chat"         : "Chat",
    "helpCenter.chat.offline" : "Offline",
}