import { getSidMultiSportType } from "./getReferer";

/**
 * Get the URL to Spordle Account platform
 * @param {string} authAs DEFAULT: 'HCR' - Where we went to connect. Pass 'SPORDLE' to go to account
 * @return {string} The url to Spordle Account platform
 */
export default function getAccountUrl(authAs = 'HCR'){
    switch (process.env.REACT_APP_ENVIRONMENT){
        case 'prod':
            return process.env.REACT_APP_VERSION_CLIENT === 'EIHA' ? `https://account.eiha.spordle.com/auth/${authAs}` : `https://account.spordle.com/auth/${authAs}`;
        case 'training':
            return `https://account.training.hcr.spordle.com/auth/${authAs}`;
        case 'uat':
            return `https://account.uat.spordle.dev/auth/${authAs}`;
        case 'hcstage':
            return `https://account.hcstage.spordle.dev/auth/${authAs}`;
        case 'stage':
            if(getSidMultiSportType() === 'https://id.demo.spordle.dev')
                return `https://account.demo.spordle.dev/auth/${authAs}`;
            return `https://account.stage.spordle.dev/auth/${authAs}`;
        case 'int':
            return `https://account.int.spordle.dev/auth/${authAs}`;
        case 'dev':
        default:
            return `https://account.dev.spordle.dev/auth/${authAs}`;
    }
}