import SpordleTableProvider, { SpordleTableView, useSpordleTable } from '@spordle/datatables';
import Translate from '@spordle/intl-elements';
import moment from 'moment';
import { useContext } from 'react';
import { CSVLink } from 'react-csv';
import { useIntl } from 'react-intl';
import {
    Card,
    CardBody,
    CustomInput,
    DropdownItem, DropdownMenu, DropdownToggle,
    Label,
    UncontrolledButtonDropdown
} from "reactstrap";
import SpordlePanelTable from '../../../../components/sidePanel/SpordlePanel';
import EmptyLayout from '../../../../components/table/EmptyLayout';
import CardSectionTitle from '../../../../components/CardSectionTitle';
import { I18nContext } from '../../../../contexts/I18nContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { displayI18n, DisplayI18n } from '../../../../helpers/i18nHelper';
import MemberProfileWaiverSidepanel from './components/MemberProfileWaiverSidepanel';
import { Link } from 'react-router-dom';
import SpordleSelect from '@spordle/spordle-select';
import { PeriodsContext } from '../../../../contexts/contexts';
import { WaiverSignatureDisplay } from '../../../../helpers/waiversHelper';
import MemberAlertsIcon from '../../../../components/memberBadges/MemberAlertsIcon';
import DisplayOrganization from '../../../../components/organization/DisplayOrganization';

const MemberProfileWaivers = ({ memberId, forceWaiversRefresh, refreshMemberProfile }) => {
    const i18nContext = useContext(I18nContext);
    const memberContext = useContext(MembersContext);
    const periodsContext = useContext(PeriodsContext);

    return (
        <Card className="card-shadow">
            <CardBody>
                <CardSectionTitle title='members.profile.memberProfile.tabs.waivers' />
                <SpordlePanelTable
                    allowOutsideClick
                    dataIndex='id'
                    sidePanel={(props) => <MemberProfileWaiverSidepanel {...props} refreshMemberProfile={refreshMemberProfile} />}
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                key={forceWaiversRefresh}
                                id='waivers'
                                tableHover bordered striped
                                clickable
                                ref={panelProps.spordleTableRef}
                                defaultSorting={'-season'}
                                desktopWhen
                                pagination={20}
                                dataIndex='id'
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                loadData={(from, filter, spordleTable) => {
                                    switch (from){
                                        case 'FILTER':
                                            return memberContext.getWaivers(memberId, { period_id: filter.filters.period_id === 'ALL' ? '' : filter.filters.period_id })
                                                .then((waivers) => waivers.filter((waiver) => !filter.filters.onlyCritical || (filter.filters.onlyCritical === true && waiver.critical == 1)).map((waiver) => ({
                                                    ...waiver,
                                                    id: `${waiver.waiver_id}-${waiver.invoice_number}`,
                                                })));
                                        case 'REFRESH':
                                            spordleTable.setLoading();
                                        case 'CDM':
                                            return memberContext.getWaivers(memberId)
                                                .then((waivers) => waivers.map((waiver) => ({
                                                    ...waiver,
                                                    id: `${waiver.waiver_id}-${waiver.invoice_number}`,
                                                })));
                                        default:
                                            break;
                                    }
                                }}
                                columns={[
                                    {
                                        label: <Translate id='members.profile.waiver.table.column.season' />,
                                        key: 'season',
                                        mobile: true,
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                        sortable: true,
                                        sortKey: 'period.name',
                                    },
                                    {
                                        label: <Translate id='members.profile.waiver.table.column.reference' />,
                                        key: 'invoice_number',
                                        mobile: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.waiver.table.column.waiver' />,
                                        key: 'waiver',
                                        sortKey: `i18n.${i18nContext.getGenericLocale()}.name`,
                                        fallbackSortKey: 'name',
                                        sortable: true,
                                        mobile: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.waiver.table.column.signed' />,
                                        key: 'signed',
                                        sortKey: 'answer',
                                        fallbackSortKey: 'updated_at',
                                        sortable: true,
                                        mobile: true,
                                    },
                                ]}
                                searchKeys={[
                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                    'name',
                                    `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                    'organisation.name',
                                    'invoice_number',
                                    'identity.family_name',
                                    'identity.name',
                                    `period.i18n.${i18nContext.getGenericLocale()}.name`,
                                    'period.name',
                                    'updated_at',
                                ]}
                                renderRow={(columnKey, waiver) => {
                                    switch (columnKey){
                                        case 'season':
                                            return (
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={waiver.period?.name}
                                                    i18n={waiver.period?.i18n}
                                                />
                                            )
                                        case 'invoice_number':
                                            return (
                                                <div>
                                                    {waiver.invoice_type === 'WAIVER' ? <div>#{waiver.invoice_number}</div> : <div><Link to={'invoice?invoiceNumber=' + waiver.invoice_number}>#{waiver.invoice_number}</Link></div>}
                                                    <div className='small'>
                                                        <DisplayOrganization organisation={{ organisation_id: waiver.invoice_organisation?.organisation_id }}>
                                                            <DisplayI18n field='organisation_name' defaultValue={waiver.invoice_organisation?.organisation_name} i18n={waiver.invoice_organisation?.i18n} />
                                                        </DisplayOrganization>
                                                    </div>
                                                </div>
                                            )
                                        case 'waiver':
                                            return (
                                                <div style={{ maxWidth: '250px' }}>
                                                    {waiver.critical == 1 &&
                                                        <div className='text-danger small d-flex align-items-center'>
                                                            <MemberAlertsIcon icon="waivers" className="pr-1" />
                                                            <span><Translate id='misc.critical' /></span>
                                                        </div>
                                                    }
                                                    <div className="font-medium text-dark">
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={waiver.name}
                                                            i18n={waiver.i18n}
                                                        />
                                                    </div>
                                                    <div className="small">
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={waiver.organisation?.name}
                                                            i18n={waiver.organisation?.i18n}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        case 'signed':
                                            return (
                                                <WaiverSignatureDisplay waiver={waiver} />
                                            )
                                        default:
                                            break;
                                    }
                                }}
                                onColumnClick={(e, data) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            panelProps.onSingleSelectChange(data);
                                            break;
                                    }
                                }}
                                rowIsHighlighted={(waiver) => !!waiver.checked}
                                emptyLayout={<EmptyLayout translateMsg msg='members.profile.waiver.table.empty' />}
                            >
                                {(spordleTable) => (
                                    <>
                                        <div className='mb-2 d-flex flex-wrap justify-content-between'>
                                            <SpordleSelect
                                                className='mr-2 flex-grow-1 min-w-lg-25 min-w-md-25 min-w-sm-100'
                                                name='period_id'
                                                id='period_id'
                                                search={false}
                                                loadingStatus='success'
                                                defaultValues={[ 'ALL' ]}
                                                onOptionSelected={(option) => {
                                                    spordleTable.filterChange('period_id', option[0])
                                                }}
                                                renderSelectedOption={(option) => {
                                                    return (
                                                        <>
                                                            {option.value !== 'ALL' ?
                                                                <>
                                                                    <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />
                                                                </>
                                                                :
                                                                <Translate id={option.label} />
                                                            }
                                                        </>
                                                    )
                                                }}
                                                renderOption={({ option }) => (
                                                    <>
                                                        {option.value !== 'ALL' ?
                                                            <>
                                                                <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />
                                                                {(option.current == 1) && <div className='small text-muted'><Translate id='misc.current' /></div>}
                                                            </>
                                                            :
                                                            <Translate id={option.label} />
                                                        }
                                                    </>
                                                )}
                                                defaultData={
                                                    [
                                                        {
                                                            label: "settings.general.periodHint.all",
                                                            i18n: null,
                                                            value: 'ALL',
                                                            current: 0,
                                                        },
                                                    ].concat(periodsContext.activePeriods.map((period) => ({
                                                        label: period.name,
                                                        i18n: period.i18n,
                                                        value: period.period_id,
                                                        current: period.current,
                                                    })))
                                                }
                                            />

                                            <div className='min-w-lg-25 min-w-md-25 min-w-sm-100'>
                                                <SpordleTableProvider.SearchInput />
                                            </div>

                                            <div className='d-flex align-items-center px-0 px-md-3'>
                                                <CustomInput defaultChecked={false} id="filter-only-critical" name="filter-only-critical" type="checkbox" onChange={(e) => spordleTable.filterChange('onlyCritical', e.target.checked)} />
                                                <Label for="filter-only-critical" className="mb-0"> <Translate id="members.profile.waiver.table.filter.onlyCritical" /></Label>
                                            </div>

                                            <div className='d-flex ml-auto text-right'>
                                                <SpordleTableProvider.Refresh />
                                                <UncontrolledButtonDropdown className='ml-2'>
                                                    <DropdownToggle color='primary' outline caret>
                                                        <i className='fas fa-download mr-1' /><Translate id='misc.export' />
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <ToCSV />
                                                        <DropdownItem disabled><i className='fas fa-file-excel mr-1' /><Translate id='misc.excel' /></DropdownItem>
                                                        <DropdownItem disabled><i className='fas fa-file-pdf mr-1' /><Translate id='misc.pdf' /></DropdownItem>
                                                        <DropdownItem disabled><i className='fas fa-print mr-1' /><Translate id='misc.print' /></DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                {/* <Button className='ml-2' color='primary'><i className='ti-plus'/> <Translate id='misc.add'/></Button> */}
                                            </div>
                                        </div>
                                        <SpordleTableView />
                                    </>
                                )}
                            </SpordleTableProvider>
                        )
                    }}
                />
            </CardBody>
        </Card>
    );
}

const ToCSV = () => {
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);
    const spordleTable = useSpordleTable();
    const intl = useIntl();

    const formatData = () => {
        return spordleTable.getData().map((waiver) => ({
            season: displayI18n('name', waiver.period?.i18n, waiver.period?.name, i18nContext.getGenericLocale()),
            waiver: displayI18n('name', waiver.i18n, waiver.name, i18nContext.getGenericLocale()),
            organisation: displayI18n('name', waiver.organisation?.i18n, waiver.organisation?.organisation_name, i18nContext.getGenericLocale()),
            signed: waiver.answer ? intl.formatMessage({ id: `members.profile.waiver.csv.header.answer.${waiver.answer}` }) : '-',
            signedBy: waiver.answer === 'YES' ? `${waiver.identity?.name} ${waiver.identity?.family_name}` : '-',
            signedOn: waiver.answer === 'YES' ? moment(waiver.updated_at).format('LLL') : '-',
        }));
    }

    return (
        <CSVLink
            className="dropdown-item" separator=','
            filename={`${membersContext.currentMember.first_name + ' ' + membersContext.currentMember.last_name}-Waivers-${i18nContext.getGenericLocale()}-${moment().format('LLL')}.csv`}
            headers={[
                {
                    label: intl.formatMessage({ id: 'members.profile.waiver.table.column.season' }),
                    key: 'season',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.waiver.table.column.waiver' }),
                    key: 'waiver',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.waiver.csv.header.organisation' }),
                    key: 'organisation',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.waiver.table.column.signed' }),
                    key: 'signed',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.waiver.signed.by' }),
                    key: 'signedBy',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.waiver.csv.header.signedOn' }),
                    key: 'signedOn',
                },
            ]}
            data={formatData()}
        >
            <i className='fas fa-file-excel mr-1' />CSV
        </CSVLink>
    )
}

export default MemberProfileWaivers;