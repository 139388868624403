import { Tooltip } from "@mantine/core";
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Badge, Col, Label, Row } from "reactstrap";
import { string } from 'yup';
import Required from "../../../../components/formik/Required";
import { DisplayI18n } from "../../../../helpers/i18nHelper";

export const DISCRIMINATION_TYPE_OFFICIAL = "OFFICIAL";
export const DISCRIMINATION_TYPE_OTHER = "OTHER";
export const DISCRIMINATION_TYPE_ANONYMOUS = "ANONYMOUS";

export const DISCRIMINATION_REASONS = [
    "RACE",
    "RELIGION",
    "AGE",
    "GENDER_IDENTITY",
    "MARITAL_STATUS",
    "GENETIC",
    "DISABILITY",
];

export const FINAL_STATUSES = [ "OUTCOMES_COMPLETED" ];

export const MEMBER_TYPE = [
    "PLAYER",
    "PARENT",
    "BENCH_STAFF",
    "EMPLOYEE",
    "OFFICIAL",
    "VOLUNTEER",
    "SPECTATOR",
    "OFF_ICE_OFFICIAL",
    "OTHER",
];

export const BELONGS_TO = [
    "HOME",
    "AWAY",
];

export const CLOSED_REASONS = [
    "WRITTEN_WARNING",
    "EDUCATION",
    "PROBATION",
    "ELIGIBILITY_RESTRICTIONS",
    "OTHER_SANCTIONS",
    "COMPLAINT_DISMISSED",
];

/**
 * @description Init values for a discrimination member
 **/
export const formikComplaineeInit = {
    first_name: '',
    last_name: '',
    role: '',
}

/**
 * @description Validation Schema for a discrimination member
 **/
export const formikComplaineeValidation = {
    first_name: string().required(<Translate id='form.validation.firstName.required' />),
    last_name: string().required(<Translate id='form.validation.lastName.required' />),
    role: string().required(<Translate id='discrimination.label.validation.role.required' />),
}

/**
 * @description Repetitive complainee fields (create discrimination, edit member, create member)
 * @returns {React.ReactNode}
 */
export const ComplaineeFormikFields = () => {
    const types = MEMBER_TYPE.reduce((types, type) => {
        if(type !== "EMPLOYEE"){
            types.push({
                label: 'discrimination.global.roles.' + type,
                value: type,
                translateLabel: true,
            })
        }
        return types;
    }, []);

    return (
        <Row form>
            <Col className="form-group" sm="6">
                <Label className="text-muted" for="first_name"><Translate id="form.fields.firstName" /> <Required /></Label>
                <FormikInputText trim name="first_name" />
            </Col>
            <Col className="form-group" sm="6">
                <Label className="text-muted" for="first_name"><Translate id="form.fields.lastName" /> <Required /></Label>
                <FormikInputText trim name="last_name" />
            </Col>
            <Col className="form-group" sm="6">
                <Label className="text-muted" for="role"><Translate id='discrimination.label.role' /> <Required /></Label>
                <FormikSelect
                    id="role"
                    name="role"
                    search={false}
                    defaultData={types}
                    loadingStatus='success'
                />
            </Col>
        </Row>
    )
}

/**
 * @description Renders the badge of a claim status
 * @param {Object} props
 * @param {object} props.discriminationStatus Object containing discrimination_status ressource
 * @returns {JSX.Element}
 */
export const ClaimStatus = ({ discriminationStatus, ...props }) => {
    if(!discriminationStatus)return '-';

    const getColor = () => {
        switch ((discriminationStatus.system || "").toUpperCase()){
            case 'SUSPENDED':
                return 'danger';
            case 'PENDING':
                return 'warning';
            case "OUTCOMES_COMPLETED":
                return 'success';
            case 'CLOSED':
            default:
                return 'info';
        }
    }


    return (
        <Badge color={getColor()} {...props}>
            <DisplayI18n
                field="name"
                i18n={discriminationStatus.i18n}
                defaultValue={discriminationStatus.name}
            />
        </Badge>
    )

}

/**
 * @description Renders a list of translated discriminatory grounds with a tooltip
 * @param {Object} props
 * @param {string[]} props.reasons
 * @returns {JSX.Element}
 */
export const DiscriminationReasons = ({ reasons }) => {
    const shownReasons = reasons.length >= 3 ? reasons.slice(0, 2) : reasons;
    const moreReasons = reasons.slice(2, reasons.length);

    return (
        <Tooltip
            withArrow
            disabled={moreReasons.length <= 0}
            label={
                <ul className="p-2 mb-0 ml-2">
                    {moreReasons.map((reason, index) => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={reason + index}>
                                <Translate id={'discrimination.global.discriminatoryGrounds.' + reason} />
                            </li>
                        )
                    })}
                </ul>
            }
        >
            <div>
                <ul className="pl-3 mb-3">
                    {
                        shownReasons?.map((reason) => (
                            <li key={reason}>
                                <Translate id={'discrimination.global.discriminatoryGrounds.' + reason} />
                            </li>
                        ))
                    }
                </ul>
                {moreReasons.length > 0 &&
                    <div className="small">
                        <Translate id='discrimination.spordleTable.discriminatoryGrounds.more' values={{ count: moreReasons.length }} />
                    </div>
                }
            </div>
        </Tooltip>
    )
}

/**
 * @description Renders a list of translated discriminatory grounds with a tooltip
 * @param {Object} props
 * @param {string[]} props.outcomes
 * @returns {JSX.Element}
 */
export const DiscriminationOutcomes = ({ outcomes }) => {
    const shownOutcomes = outcomes.length >= 3 ? outcomes.slice(0, 2) : outcomes;
    const moreOutcomes = outcomes.slice(2, outcomes.length);

    return (
        <Tooltip
            withArrow
            disabled={moreOutcomes.length <= 0}
            label={
                <ul className="p-2 mb-0 ml-2">
                    {moreOutcomes.map((outcome, index) => {
                        return (
                            <li key={outcome.maltreatment_outcome_id}>
                                <DisplayI18n field='name' defaultValue={outcome.outcome.name} i18n={outcome.outcome.i18n} />
                            </li>
                        )
                    })}
                </ul>
            }
        >
            <div>
                <ul className="pl-3 mb-3">
                    {
                        shownOutcomes?.map((outcome) => (
                            <li key={outcome.maltreatment_outcome_id}>
                                <DisplayI18n field='name' defaultValue={outcome.outcome.name} i18n={outcome.outcome.i18n} />
                            </li>
                        ))
                    }
                </ul>
                {moreOutcomes.length > 0 &&
                    <div className="small">
                        <Translate id='discrimination.spordleTable.outcomes.more' values={{ count: moreOutcomes.length }} />
                    </div>
                }
            </div>
        </Tooltip>
    )
}

export const isAnonymousComplaint = (complaint) => complaint.complaint_by === DISCRIMINATION_TYPE_ANONYMOUS;