import API_SPORDLE from "../API-Spordle";
import queryString from "query-string";
import { jsObjectToApi } from "@spordle/helpers";
import { serverError } from "../CancellableAPI";

/**
 * Creates a meta member for the given identity
 * @returns {Promise<string>}
 */
export function getRequestChangesStatus() {
    return API_SPORDLE.get(queryString.stringifyUrl({
        url: `/request-changes-status`,
    }))
        .then((response) => {
            if (response.data.status) {
                return response.data.request_change_status;
            }
            throw response.data.errors[0];
        }, serverError)
}