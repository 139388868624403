import React from "react";


// Contexts
import withContexts from '../../../../../../helpers/withContexts';

// Language
import Translate, { DateFormat } from "@spordle/intl-elements";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
import { TeamsContext } from "../../../../../../contexts/TeamsContext";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { getStatus } from "../../../../TeamHelpers";

class SidePanelTeamComments extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
        }
    }

    render(){
        return (
            <div className='position-relative'>
                <OverlayLoader isLoading={this.state.loading}>
                    <SidePanel.Header>
                        <div className='d-flex mb-3 align-items-center'>
                            <SidePanel.ToggleButton />
                            <SidePanel.ActionsMenu label="misc.action" action='DELETE' componentCode='members' componentPermissionCode='members_memo'>
                                <ConfirmModal
                                    translateModalTitle
                                    modalTitle='teams.profile.roster.sidePanel.teamComments.deleteMsg'
                                    toggler={(onClick) => (
                                        <SidePanel.MenuAction
                                            onClick={onClick}
                                        >
                                            <Translate id='members.profile.overview.memberMemos.remove' />
                                        </SidePanel.MenuAction>
                                    )
                                    }
                                    onConfirm={() => {
                                        return this.props.TeamsContext.deleteTeamComment(this.props.selectedRows[0]?.team_id, this.props.selectedRows[0]?.team_comment_id)
                                            .then(() => {
                                                this.props.mutate();
                                                success();
                                            })
                                            .catch((e) => {
                                                if(!AxiosIsCancelled(e.message)){
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    }}
                                />
                            </SidePanel.ActionsMenu>
                        </div>
                        <SidePanel.Title><Translate id='teams.profile.comments.sidepanel.title' /></SidePanel.Title>
                    </SidePanel.Header>
                    <div className="p-3">
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='teams.profile.comments.date' /></div>
                            <div className="font-medium">
                                <DateFormat value={this.props.getCommonValue("updated_at")} format="YYYY-MM-DD H:mm" />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='form.fields.team_status' /></div>
                            <div className="font-medium">
                                {
                                    this.props.getCommonValue("team_status")
                                        ?
                                        <div className='text-nowrap'>
                                            {this.props.getCommonValue("previous_team_status") &&
                                                <>
                                                    {getStatus(this.props.getCommonValue("previous_team_status"))}
                                                    <i className='mdi mdi-arrow-right mx-2' />
                                                </>
                                            }
                                            {getStatus(this.props.getCommonValue("team_status"))}
                                        </div>
                                        :
                                        '-'
                                }
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='teams.profile.comments.sidepanel.name' /></div>
                            <div className="font-medium">
                                {this.props.selectedRows[0].identity.name} {this.props.selectedRows[0].identity.family_name}
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='teams.profile.comments.sidepanel.email' /></div>
                            <div className="font-medium">
                                <a href={`mailto:${this.props.selectedRows[0].identity.email}`}>{this.props.selectedRows[0].identity.email}</a>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="text-muted"><Translate id='form.fields.comment' /></div>
                            <div className="font-medium">
                                {this.props.getCommonValue("comment")}
                            </div>
                        </div>
                    </div>
                </OverlayLoader>
            </div>
        )
    }
}

export default withContexts(TeamsContext)(SidePanelTeamComments);