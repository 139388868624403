export default{
    "sidebar.favorites"             : "Favorites",
    "sidebar.favorites.empty.title" : "No favorites",
    "sidebar.favorites.empty.btn"   : "Add current page",
    "sidebar.favorites.empty.text"  : "Click on {icon} to add favorites.",
    "sidebar.history"               : "History",
    "sidebar.changeorganization"    : "Change Organization",
    "sidebar.dashboard"             : "Dashboard",
    "sidebar.organization"          : "Organization",
    "sidebar.organizationProfile"   : "Profile",
    "sidebar.myOrganization"        : "Organization",
    "sidebar.myTournament"          : "My Tournament",
    "sidebar.myLeague"              : "My league",
    "sidebar.members"               : "Members",

    "sidebar.teams"          : "Teams",
    "sidebar.teams.profile"  : "Team Profile",
    "sidebar.teams.rollover" : "Team Rollover",
    "sidebar.teams.create"   : "Team Creation",

    "sidebar.clinics"         : "Clinics",
    "sidebar.clinics.profile" : "Clinic Profile",
    "sidebar.clinics.create"  : "Clinic Creation",

    "sidebar.onlineStore"         : "Online Registration",
    "sidebar.onlineStore.profile" : "Online Registration Profile",
    "sidebar.onlineStore.create"  : "Create Online Store",

    "sidebar.leagues"        : "Leagues",
    "sidebar.tournaments"    : "Tournaments",
    "sidebar.tasks"          : "Tasks",
    "sidebar.requests"       : "Requests",
    "sidebar.finance"        : "Finances",
    "sidebar.finances"       : "Finances",
    "sidebar.communications" : "Communication",
    "sidebar.news"           : "News",
    "sidebar.catalog"        : "Catalog",
    "sidebar.reports"        : "Reports",
    "sidebar.settings"       : "Settings",
    "sidebar.tracking_items" : "Tracking Items",
    "sidebar.page"           : "Page",
    "sidebar.platform"       : "Platform",
    "sidebar.find"           : "Find",
    "sidebar.manage"         : "Manage",
    "sidebar.users"          : "Users",
    "sidebar.collapse"       : "Collapse",
    "sidebar.expand"         : "Expand",
    "sidebar.manageProfile"  : "Manage Profile",
    "sidebar.supportRequest" : "Support requests",

    "sidebar.checklists"                    : "Checklists",
    "sidebar.checklists.seasonRollover"     : "Season Rollover",
    "sidebar.checklists.onlineRegistration" : "Online Registration Checklist",
    "sidebar.checklists.clinic"             : "Clinics Checklist",

    "sidebar.terms"   : "Terms & conditions",
    "sidebar.privacy" : "Privacy",
    "sidebar.about"   : "About us",

    "sidebar.title.multi" : "{count} Selected",

    // Sub sidebars
    "sidebar.search.stores"               : "Stores",
    "sidebar.search.profiles"             : "Profiles",
    "sidebar.catalog.general"             : "General",
    "sidebar.catalog.productsAndServices" : "Products and services",
    "sidebar.catalog.legalAndForms"       : "Legal and forms",
    "sidebar.catalog.finances"            : "Finances",
    "sidebar.catalog.forms"               : "Questionnaires",
    "sidebar.catalog.clinicItems"         : "Clinic Items",
    "sidebar.catalog.otherItems"          : "Other Items",
    "sidebar.catalog.waivers"             : "Waivers",
    "sidebar.catalog.terms"               : "Terms & Conditions",
    "sidebar.catalog.rebates"             : "Discounts",
    "sidebar.catalog.seasonal"            : "Seasonal",
    "sidebar.catalog.annual"              : "Annual",
    "sidebar.catalog.membershipFees"      : "Membership Fees",
    "sidebar.catalog.registrationFees"    : "Registration Fees",
    "sidebar.catalog.installments"        : "Installments",

    "sidebar.settings.menu"                           : "Menu",
    "sidebar.settings.general"                        : "General",
    "sidebar.settings.organization"                   : "Organization",
    "sidebar.settings.registrationSettings"           : "Categories",
    "sidebar.settings.clinicSettings"                 : "Clinics",
    "sidebar.settings.otherSettings"                  : "Other Settings",
    "sidebar.settings.platformSettings"               : "Platform",
    "sidebar.settings.organizationSettings"           : "Organization",
    "sidebar.settings.teams"                          : "Teams",
    "sidebar.settings.teamsRestrictions"              : "Teams Restrictions",
    "sidebar.settings.teamsRestrictions.restrictions" : "Restrictions",
    "sidebar.settings.teamsRestrictions.create"       : "Creation",
    "sidebar.settings.teamsRestrictions.edit"         : "Edit",
    "sidebar.settings.teamsRestrictions.back"         : "Back to list",
    "sidebar.reports.registration"                    : "Registration",
    "sidebar.reports.credits"                         : "Credits",
    "sidebar.reports.multibranch"                     : "Branch",
    "sidebar.reports.dashboard"                       : "Dashboard",
    "sidebar.reports.team_list"                       : "Team List",
    "sidebar.reports.team_list_summary"               : "Team List",
    "sidebar.reports.questionnaires"                  : "Questionnaires",
    "sidebar.reports.qualifications"                  : "Qualifications",
    "sidebar.reports.deficiencies"                    : "Deficiencies",
    "sidebar.reports.waiver"                          : "Waivers",
    "sidebar.settings.payees"                         : "Payees",
    "sidebar.settings.users"                          : "Users",
    "sidebar.reports.crc"                             : "Background Check",
    "sidebar.reports.refunds"                         : "Refunds",
    "sidebar.reports.membership_fees"                 : "Membership Fees",
    "sidebar.reports.membershipfees_summary"          : "Membership Fees",
    "sidebar.reports.injury"                          : "Injury",
    "sidebar.reports.branch_summary"                  : "Branch",
    "sidebar.reports.registration_count"              : "Registration Count",
    "sidebar.reports.clinic_attendees"                : "Clinic Attendees",
    "sidebar.reports.not_on_team"                     : "Participants without team",
    "sidebar.reports.team_roster"                     : "Rostered Participants",
    "sidebar.reports.transfers"                       : "{transfers_title}",
    "sidebar.reports.game_incident"                   : "Game Incidents",
    "sidebar.reports.discrimination"                  : "Discrimination",
    "sidebar.reports.suspensions"                     : "Suspensions",
    "sidebar.reports.appeals"                         : "Appeals",
    "sidebar.reports.engine"                          : "Reports Engine",
    "sidebar.reports.double.carding"                  : "Participants on Multiple Teams",
    "sidebar.reports.previously_on_team"              : "Previously on team",
    "sidebar.reports.transactions"                    : "Transactions",
    "sidebar.reports.outstanding-balances"            : "Outstanding Balances",

    "sidebar.reports.library" : "Library",
    "sidebar.reports.exports" : "Exports",

}