export default{
    "news.title"                         : "News",
    "news.delete.modal.msg"              : "You are about to delete this news",
    "news.delete.toast.success.title"    : "News deleted!",
    "news.delete.toast.success.subtitle" : "The news was successfully deleted.",

    "news.table.sortBy"          : "Sort by",
    "news.table.sortBy.asc"      : "Switch to ascending",
    "news.table.sortBy.desc"     : "Switch to descending",
    "news.table.filter.liveOnly" : "Live Only",
    "news.table.status.live"     : "Live",

    "news.nav.manage" : "Manage News",
    "news.nav.view"   : "View News",

    "news.form.create.success.msg"        : "The news was successfully created!",
    "news.form.create.confirm.msg"        : "You are about to publish this news to <b>{count, plural, =0 {this organization and all its children} one {# organization} other {# organizations}}</b>.",
    "news.form.create.confirm.date.from"  : "This news will be visible from <b>{date}</b> at <b>{time}</b>",
    "news.form.create.confirm.date.until" : "until <b>{date}</b> at <b>{time}</b>.",
    "news.form.create.confirm.notActive"  : "This news is not active and won't be visible.",
    "news.form.create.confirm.visible"    : "This news will be visible once it's published.",

    "news.form.title.create"                   : "Add news",
    "news.form.title.edit"                     : "Edit news",
    "news.form.title.content"                  : "Content",
    "news.form.title.confirm"                  : "Confirm publication",
    "news.form.title.confirm.edit"             : "Confirmation modifications",
    "news.form.field.startDate"                : "Start Date",
    "news.form.field.endDate"                  : "End Date",
    "news.form.field.from"                     : "From",
    "news.form.field.to"                       : "To",
    "news.form.field.to.placeholder"           : "All organizations",
    "news.form.field.to.helper"                : "If no organization is selected, the news will be shown to all child organizations.",
    "news.form.field.active"                   : "News is active",
    "news.form.field.active.helper"            : "Inactive news won't be shown.",
    "news.form.field.endDate.isAfterStart"     : "End date should be after start date",
    "news.form.field.banner.required"          : "Please select a banner for your news",
    "news.form.field.startDate.required"       : "Start date is required",
    "news.form.field.type"                     : "Type",
    "news.form.field.type.critical"            : "Critical",
    "news.form.field.type.warning"             : "Warning",
    "news.form.field.type.information"         : "Information",
    "news.form.field.type.required"            : "The news type is required.",
    "news.form.field.banner"                   : "Main Banner",
    "news.form.field.banner.none"              : "No Banner",
    "news.form.field.banner.none.msg"          : "Select the news main banner.",
    "news.form.field.banner.modal.title"       : "Select Banner",
    "news.form.field.banner.modal.preview.msg" : "Select an image to see its preview.",
    "news.form.field.org.required"             : "Please select at least one organization.",
    "news.form.helper"                         : "To learn more about the news system, you can <a>read more here.</a>",
    "news.form.btn.preview"                    : "Preview",
    "news.form.btn.preview.tip"                : "See a preview",
    "news.form.btn.back"                       : "Back to news list",
}