import { OrganizationContext } from "../../contexts/OrganizationContext";
import { getMemberTypeIcon } from "../../views/settings/organization/components/memberTypes/types/components/memberTypesIcons";
import DisplayMemberTypeStatusIcon from "./DisplayMemberTypeStatusIcon";
import { useContext } from "react";

const DisplayMemberTypeIcon = ({ memberType }) => {
    const { settings } = useContext(OrganizationContext);
    const canShowStatus = settings.manage_member_type_approbation?.value == 1;

    if(!memberType){
        return null;
    }

    if(memberType.member_type_status && canShowStatus){
        return (
            <DisplayMemberTypeStatusIcon
                status={memberType.member_type_status}
                className="mr-2"
            />
        )
    }

    if(!memberType.member_type){
        return null;
    }

    return getMemberTypeIcon(memberType.member_type);
}

export default DisplayMemberTypeIcon;