import registrationFeesAdd from "./registrationFeesAdd";
import registrationFeesSidepanel from "./registrationFeesSidepanel";
import registrationFeesRollover from "./registrationFeesRollover";

export default{
    "catalog.registrationFees.title"            : "Registration Fees",
    "catalog.registrationFees.help.1"           : "The registration fee represents the amount required from the member for registration to specific minor hockey activities (season, skills camp, clinics, etc.)  and is valid for the amount of time of said activity (this can range from one year for a full hockey season to a few hours in the case of clinics). This amount DOES NOT include the affiliation fee*.",
    "catalog.registrationFees.help.2"           : "*To registrars:  please note that the registration fee should be entered WITHOUT the affiliation fee.",
    "catalog.registrationFees.help.3"           : "The system will automatically add the affiliation fee to the member's total registration amount if the annual affiliation renewal has not been paid or is past due.",
    "catalog.registrationFees.unlimited"        : "Unlimited",
    "catalog.registrationFees.active"           : "Active",
    "catalog.registrationFees.inactive"         : "Inactive",
    "catalog.registrationFees.category"         : "Category",
    "catalog.registrationFees.member_type"      : "Member Type",
    "catalog.registrationFees.registration"     : "Registration",
    "catalog.registrationFees.availablePlaces"  : "Available Places",
    "catalog.registrationFees.total_sold"       : "Total Sold",
    "catalog.registrationFees.status"           : "Status",
    "catalog.registrationFees.inactiveCategory" : "This category is inactive.",
    "catalog.registrationFees.registrationFees" : "Registration Fees",

    ...registrationFeesAdd,
    ...registrationFeesSidepanel,
    ...registrationFeesRollover,
}