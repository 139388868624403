export default{
    "header.home"                   : "Accueil",
    "header.account"                : "My Account",
    "header.change"                 : "Change",
    "header.settings"               : "Settings",
    "header.myProfile"              : "My Profile",
    "header.signout"                : "Sign Out",
    "header.signout.confirm"        : "Are you sure you want to sign out?",
    "header.supportRequest"         : "My Support Requests",
    "header.oldNavigation"          : "Old Navigation",
    "header.oldNavigation.confirm"  : "We strongly recommend using the new navigation with a mobile device.",
    "header.oldNavigation.confirm2" : "Are you sure you want to go back to the old navigation?",
    "header.newNavigation"          : "New Navigation",
    "header.more"                   : "More",

    // Breacrumbs
    "header.breadcrumbs.changePeriod.tip" : "View different period",
    "header.breadcrumbs.profile"          : "Profile",

    // Mobile
    "header.mobile.menu.back" : "Back to main menu",

    // Notifications
    "header.notifications.title"               : "Notifications",
    "header.communications.title"              : "Communications",
    "header.communications.empty.title"        : "Up to date",
    "header.account.title"                     : "Account",
    "header.helpCenter.title"                  : "Help",
    "header.langSwitch.title"                  : "Change language",
    "header.notifications.goBack"              : "Back",
    "header.notifications.empty.title"         : "Up to date",
    "header.notifications.jira.goTo"           : "See all support requests",
    "header.notifications.comms.goTo"          : "Go to inbox",
    "header.communications.empty.msg"          : "You currently have no new communication.",
    "header.notifications.empty.text"          : "You have currently no new notification.",
    "header.notifications.tabs.supportRequest" : "Support requests",
    "header.notifications.tabs.communication"  : "Communications",
    "header.notifications.tabs.news"           : "News",
    "header.notifications.tabs.organization"   : "Organization",
    "header.notifications.btn.clearAll"        : "Mark all as read",
    "header.notifications.btn.deleteAll"       : "Clear all",

    "header.notifications.item.title"     : "Support request",
    "header.notifications.item.tip.clear" : "Clear notification",
    "header.notifications.item.tip.read"  : "Mark as read",
    "header.notifications.item.unread"    : "{count, plural, =0 {# unread message} one {# unread message} other {# unread messages}}",

    "header.notifications.settings.title"      : "Settings",
    "header.notifications.settings.subtitle"   : "Enable or disabled notifications you wish to receive.",
    "header.notifications.settings.btn.save"   : "Save changes",
    "header.notifications.settings.toast.msg"  : "Updated!",
    "header.notifications.settings.toast.info" : "Notifications settings have been updated.",
    "header.notifications.settings.error"      : "<b>Oops!</b> Something went wrong. Please try again or come back later.",
}