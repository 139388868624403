import { useSpordleTable } from '@spordle/datatables';
import { FormikCheckedButton, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { Button, Col, FormGroup, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import useSavedSearch from '../../../../components/customHooks/useSavedSearch';
import HasAccessTo from '../../../../components/permissions/HasAccessTo';
import SavedSearchCue from '../../../../components/savedSearchCue/SavedSearchCue';
import { RolesContext } from '../../../../contexts/RolesContext';
import { TASK_PREFIX } from '../../SupportRequestHelpers';

const SupportRequestAdvancedSearch = (props) => {
    const { filterChange } = useSpordleTable();
    const { hasAccessTo } = useContext(RolesContext);
    const showOnlyMyTicketsFilter = hasAccessTo('settings', 'setting_child_support_tickets');
    const { savedSearch, clearSavedSearch, saveSearch } = useSavedSearch(`support-request-advanced-search`);

    return (
        <div>
            <Formik
                initialValues={{
                    priority: savedSearch?.priority || '',
                    status: savedSearch?.status || '',
                    idSearch: savedSearch?.idSearch || '',
                    nameSearch: savedSearch?.nameSearch || '',
                    showOnlyMyIssues: savedSearch?.showOnlyMyIssues || true,
                }}
                onSubmit={async(values) => {
                    saveSearch(values)
                    filterChange('form', {
                        ...values,
                        idSearch: values.idSearch ? `${TASK_PREFIX}${values.idSearch}` : '',
                    });
                }}
            >
                {(formik) => (
                    <Form>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className='text-muted'><Translate id='supportRequests.search.table.priority' /></Label>
                                    <FormikSelect
                                        id='priority'
                                        name='priority'
                                        textWhenSetting={{
                                            count: 2,
                                            label: 'misc.select.valueContainer.plural',
                                        }}
                                        clearable
                                        search={false}
                                        defaultData={[
                                            {
                                                value: '5',
                                                label: 'supportRequests.search.table.priority.low',
                                                translateLabel: true,
                                            },
                                            {
                                                value: '3',
                                                label: 'supportRequests.search.table.priority.medium',
                                                translateLabel: true,
                                            },
                                            {
                                                value: '1',
                                                label: 'supportRequests.search.table.priority.high',
                                                translateLabel: true,
                                            },
                                        ]}
                                        loadingStatus='success'
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className='text-muted'><Translate id='misc.status' /></Label>
                                    <FormikSelect
                                        name="status"
                                        id="status"
                                        clearable
                                        search={false}
                                        defaultData={[
                                            {
                                                value: '2',
                                                label: 'supportRequests.search.table.status.2',
                                                translateLabel: true,
                                            },
                                            {
                                                value: '4',
                                                label: 'supportRequests.search.table.status.4',
                                                translateLabel: true,
                                            },
                                            {
                                                value: '3',
                                                label: 'supportRequests.search.table.status.3',
                                                translateLabel: true,
                                            },
                                        ]}
                                        loadingStatus='success'
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className='text-muted'><Translate id='supportRequests.search.table.idSearch' /></Label>
                                    <InputGroup className="flex-nowrap">
                                        <InputGroupAddon addonType='prepend'>
                                            {TASK_PREFIX}
                                        </InputGroupAddon>
                                        <InputGroupAddon addonType='append' className='flex-grow-1'>
                                            <FormikInputText
                                                id='idSearch'
                                                name='idSearch'
                                                trim
                                                onChange={(e) => {
                                                    if(e.target.value.startsWith(process.env.REACT_APP_ENVIRONMENT == 'prod' ? 'HCR30-' : 'UAT-')){
                                                        formik.setFieldValue('idSearch', e.target.value.split(process.env.REACT_APP_ENVIRONMENT == 'prod' ? 'HCR30-' : 'UAT-')[1]);
                                                    }else{
                                                        formik.setFieldValue('idSearch', e.target.value);
                                                    }
                                                }}
                                            />
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md={showOnlyMyTicketsFilter ? 7 : 8}>
                                <FormGroup>
                                    <Label className='text-muted'><Translate id='supportRequests.search.table.nameSearch' /></Label>
                                    <FormikInputText
                                        id='nameSearch'
                                        name='nameSearch'
                                        trim
                                    />
                                </FormGroup>
                            </Col>
                            <HasAccessTo tag={Col} md={3} componentCode='settings' componentPermissionCode='setting_child_support_tickets'>
                                <FormGroup>
                                    <Label>&nbsp;</Label>
                                    <FormikCheckedButton
                                        translateLabel
                                        label="supportRequests.search.table.onlyMyIssues"
                                        name="showOnlyMyIssues"
                                        // className="mb-1 mt-2"
                                    />
                                </FormGroup>
                            </HasAccessTo>
                            <Col md={showOnlyMyTicketsFilter ? 2 : 4} className="align-self-end">
                                <FormGroup>
                                    <Button
                                        block
                                        type="submit"
                                        color='primary'
                                        className='text-nowrap w-100'
                                        disabled={formik.isSubmitting}
                                    >
                                        <i className='fas fa-search mr-1' /><Translate id='misc.search' />
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <SavedSearchCue
                            className="w-100 order-1 order-md-0 my-2 my-md-0 mr-md-2"
                            savedSearch={savedSearch}
                            clearSavedSearch={() => {
                                clearSavedSearch();
                                formik.setValues({
                                    priority: '',
                                    status: '',
                                    idSearch: '',
                                    nameSearch: '',
                                    showOnlyMyIssues: true,
                                });
                            }}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default SupportRequestAdvancedSearch;