export default{
    "sidebar.favorites"             : "Favoris",
    "sidebar.favorites.empty.title" : "Aucun favori",
    "sidebar.favorites.empty.btn"   : "Ajouter page actuelle",
    "sidebar.favorites.empty.text"  : "Cliquez sur {icon} pour ajouter un favori.",
    "sidebar.history"               : "Historique",
    "sidebar.changeorganization"    : "Changer l'Organisation",
    "sidebar.dashboard"             : "Tableau de bord",
    "sidebar.organization"          : "Organisation",
    "sidebar.organizationProfile"   : "Profil",
    "sidebar.myOrganization"        : "Organisation",
    "sidebar.myTournament"          : "Mon tournoi",
    "sidebar.myLeague"              : "Ma ligue",
    "sidebar.members"               : "Membres",

    "sidebar.teams"          : "Équipes",
    "sidebar.teams.profile"  : "Profil d'équipe",
    "sidebar.teams.rollover" : "Équipe Rollover",
    "sidebar.teams.create"   : "Création d'équipe",

    "sidebar.clinics"         : "Stages",
    "sidebar.clinics.profile" : "Profil de stage",
    "sidebar.clinics.create"  : "Création de stage",

    "sidebar.onlineStore"         : "Inscription en ligne",
    "sidebar.onlineStore.profile" : "Inscription en ligne",
    "sidebar.onlineStore.create"  : "Création d'une boutique",

    "sidebar.leagues"        : "Ligues",
    "sidebar.tournaments"    : "Tournois",
    "sidebar.tasks"          : "Tâches",
    "sidebar.requests"       : "Demandes",
    "sidebar.finance"        : "Finances",
    "sidebar.finances"       : "Finances",
    "sidebar.communications" : "Communications",
    "sidebar.news"           : "Nouvelles",
    "sidebar.catalog"        : "Catalogue",
    "sidebar.reports"        : "Rapports",
    "sidebar.settings"       : "Paramètres",
    "sidebar.tracking_items" : "Tracking Items",
    "sidebar.page"           : "Page",
    "sidebar.platform"       : "Plateforme",
    "sidebar.find"           : "Trouver",
    "sidebar.manage"         : "Gestion",
    "sidebar.collapse"       : "Fermer",
    "sidebar.expand"         : "Ouvrir",
    "sidebar.users"          : "Utilisateurs",
    "sidebar.manageProfile"  : "Gérer le profil",

    "sidebar.checklists"                    : "Guides",
    "sidebar.checklists.seasonRollover"     : "Roulement de saison",
    "sidebar.checklists.onlineRegistration" : "Guide pour d'inscription en ligne",
    "sidebar.checklists.clinic"             : "Guide pour l'inscription aux cliniques",

    "sidebar.supportRequest" : "Demandes d'aide",

    "sidebar.terms"   : "Termes & conditions",
    "sidebar.privacy" : "Confidentialité",
    "sidebar.about"   : "À propos de nous",

    "sidebar.title.multi" : "{count} sélectionnés",

    // Sub sidebars
    "sidebar.search.stores"               : "Inscriptions",
    "sidebar.search.profiles"             : "Profils",
    "sidebar.catalog.general"             : "Général",
    "sidebar.catalog.productsAndServices" : "Produits et services",
    "sidebar.catalog.legalAndForms"       : "Formulaires",
    "sidebar.catalog.finances"            : "Finances",
    "sidebar.catalog.forms"               : "Questionnaires",
    "sidebar.catalog.clinicItems"         : "Articles de stage",
    "sidebar.catalog.otherItems"          : "Autres frais",
    "sidebar.catalog.waivers"             : "Dispenses",
    "sidebar.catalog.terms"               : "Conditions de paiement",
    "sidebar.catalog.rebates"             : "Rabais",
    "sidebar.catalog.seasonal"            : "Saisonnier",
    "sidebar.catalog.annual"              : "Annuel",
    "sidebar.catalog.membershipFees"      : "Frais d'affiliation",
    "sidebar.catalog.registrationFees"    : "Frais d'inscription",
    "sidebar.catalog.installments"        : "Versements",

    "sidebar.settings.menu"                           : "Menu",
    "sidebar.settings.general"                        : "Général",
    "sidebar.settings.organization"                   : "Organisation",
    "sidebar.settings.registrationSettings"           : "Catégories", //'Catégorie, Division, Classe',
    "sidebar.settings.clinicSettings"                 : "Stages",
    "sidebar.settings.otherSettings"                  : "Autres paramètres",
    "sidebar.settings.platformSettings"               : "Plateforme",
    "sidebar.settings.organizationSettings"           : "Organisation",
    "sidebar.settings.teams"                          : "Équipes",
    "sidebar.settings.teamsRestrictions"              : "Restrictions d'équipes",
    "sidebar.settings.teamsRestrictions.restrictions" : "Restrictions",
    "sidebar.settings.teamsRestrictions.create"       : "Création",
    "sidebar.settings.teamsRestrictions.edit"         : "Modification",
    "sidebar.settings.teamsRestrictions.back"         : "Retour à la liste",
    "sidebar.reports.registration"                    : "Inscriptions",
    "sidebar.reports.credits"                         : "Crédits",
    "sidebar.reports.multibranch"                     : "Branches",
    "sidebar.reports.dashboard"                       : "Tableau de bord",
    "sidebar.reports.team_list"                       : "Liste d'équipes",
    "sidebar.reports.team_list_summary"               : "Liste d'équipes",
    "sidebar.reports.questionnaires"                  : "Questionnaires",
    "sidebar.reports.qualifications"                  : "Qualifications",
    "sidebar.reports.deficiencies"                    : "Déficiences",
    "sidebar.reports.waiver"                          : "Dispenses",
    "sidebar.settings.payees"                         : "Bénéficiaires",
    "sidebar.settings.users"                          : "Utilisateurs",
    "sidebar.reports.crc"                             : "Vérification d'antécédents",
    "sidebar.reports.refunds"                         : "Remboursement",
    "sidebar.reports.membership_fees"                 : "Frais d'affiliation",
    "sidebar.reports.membershipfees_summary"          : "Frais d'affiliation",
    "sidebar.reports.injury"                          : "Blessures",
    "sidebar.reports.branch_summary"                  : "Branches",
    "sidebar.reports.registration_count"              : "Compte d'inscriptions",
    "sidebar.reports.clinic_attendees"                : "Participants au stage",
    "sidebar.reports.not_on_team"                     : "Participants sans équipe",
    "sidebar.reports.team_roster"                     : "Cahier d'équipe",
    "sidebar.reports.transfers"                       : "{transfers_title}",
    "sidebar.reports.game_incident"                   : "Incidents de jeu",
    "sidebar.reports.discrimination"                  : "Discrimination",
    "sidebar.reports.suspensions"                     : "Suspensions",
    "sidebar.reports.appeals"                         : "Appels",
    "sidebar.reports.engine"                          : "Moteur de rapport",

    "sidebar.reports.double.carding"       : "Joueur sur plusieurs équipes",
    "sidebar.reports.previously_on_team"   : "Précédemment sur équipe",
    "sidebar.reports.transactions"         : "Transactions",
    "sidebar.reports.outstanding-balances" : "Soldes impayés",

    "sidebar.reports.library" : "Librairie",
    "sidebar.reports.exports" : "Exportations",
}