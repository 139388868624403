import Translate from "@spordle/intl-elements"
import { Form, Formik } from "formik"
import { useState, useEffect, useRef } from "react"
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import CollapsibleCard from "../../../../components/collapsibleCard/CollapsibleCard"
import CrossFade from "../../../../components/crossFade/CrossFade"
import MemberCard from "./MemberCard";
import PerfectScrollbar from 'react-perfect-scrollbar';

const ValidMembers = ({ validMembers, unconfirmedMemberAddresses, views, setView, toggle, action, toggleAfterSuccess, memberStatus, ...props }) => {
    const [ hasFinished, setHasFinished ] = useState(false);

    const [ promises, setPromises ] = useState(validMembers.reduce((promisesObject, member) => {
        if(member.member.member_id)
            promisesObject[member.member.member_id] = false;
        return promisesObject
    }, {}))

    const formikRef = useRef();

    useEffect(() => {
        if(Object.keys(promises).every((key) => promises[key])){ // every promise is resolved
            setHasFinished(true);
            formikRef.current?.setSubmitting(false);
        }
    }, [ promises ])

    // Submit when we load this step (logic comes from team batch update that uses a comment box so the extra confirm was needed)
    useEffect(() => formikRef.current?.handleSubmit(), [])

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
            }}
        >
            {(formik) => (
                <Form>
                    <ModalHeader toggle={hasFinished ? () => { toggle(); toggleAfterSuccess(); } : toggle}>
                        <Translate id='members.search.sidePanelMemberSearch.multi.title.bulkUpdate' /> - <Translate id='members.search.sidePanelMemberSearch.multi.title.confirmMembers' />
                    </ModalHeader>
                    <ModalBody>
                        <CollapsibleCard
                            title={<Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validTitle' values={{ members: validMembers.length }} />}
                            subtitle={<Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validText' />}
                            className='mb-3'
                            initOpen={formik.isSubmitting || hasFinished || validMembers.length <= 5}
                        >
                            <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }} className='px-3 mx-n3'>
                                {validMembers.length ?
                                    validMembers.map((member) => (
                                        <MemberCard
                                            member={member.member}
                                            isSubmitting={formik.isSubmitting}
                                            formikStatus={formik.status}
                                            action={action}
                                            unconfirmedAddresses={unconfirmedMemberAddresses[member.member.member_id]}
                                            updatePromises={(memberId) => setPromises((prev) => ({ ...prev, [memberId]: true }))}
                                            key={member.member.member_id}
                                            memberStatus={memberStatus}
                                        />
                                    ))
                                    :
                                    <Translate id='members.search.sidePanelMemberSearch.bulkUpdate.allMembers.validNone' />
                                }
                            </PerfectScrollbar>
                        </CollapsibleCard>
                    </ModalBody>
                    <CrossFade isVisible={!hasFinished}>
                        <ModalFooter>
                            <Button color='primary' type='submit' disabled={!validMembers.length || formik.isSubmitting}><Translate id='misc.confirm' /></Button>
                            <Button color='primary' type='button' outline onClick={toggle} className='ml-2' disabled={!validMembers.length || formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </CrossFade>
                    <CrossFade isVisible={hasFinished}>
                        <ModalFooter>
                            <Button
                                color='primary'
                                type='button'
                                disabled={formik.isSubmitting}
                                onClick={() => {
                                    toggle();
                                    toggleAfterSuccess();
                                }}
                            >
                                <Translate id='misc.close' />
                            </Button>
                        </ModalFooter>
                    </CrossFade>
                </Form>
            )}
        </Formik>
    )
}

export default ValidMembers