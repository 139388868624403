export default{
    // Filters, Select, Search, Table, etc.
    "misc.select.placeholder"                   : "Sélectionner...",
    "misc.select.max.toast"                     : "Vous pouvez sélectionner un maximum de 100 options",
    "misc.search"                               : "Rechercher",
    "misc.link"                                 : "Lier",
    "misc.unlink"                               : "Enlever",
    "misc.SimpleSearchTable.search.placeholder" : "Rechercher",
    "misc.noSelection"                          : "Aucune sélection possible",
    "misc.noResults"                            : "Aucun résultat.",
    "misc.results"                              : "Résultat(s)",
    "misc.result"                               : "Résultat",
    "misc.countMore"                            : "{count} de plus",
    "misc.moreFilter"                           : "Afficher les filtres",
    "misc.lessFilter"                           : "Masquer les filtres",
    "misc.view"                                 : "Voir",
    "misc.viewMore"                             : "Voir plus",
    "misc.viewCountMore"                        : "Voir {count} de plus",
    "misc.viewLess"                             : "Voir moins",
    "misc.noTranslation"                        : "Aucune traduction disponible",
    "misc.templateUi.basicSpordleTable"         : "Données {itemStartPos} à {itemEndPos} sur un total de {totalItems} données.",
    "misc.select"                               : "Sélectionner",
    "misc.alreadySelected"                      : "Déjà sélectionné",
    "misc.unselect"                             : "Déselectionner",
    "misc.select.valueContainer.plural"         : "{count} sélectionné(s)",
    "misc.selected.plural"                      : "{count}/{max} sélectionné(s)",
    "misc.select.none"                          : "Aucun",
    "misc.select.all"                           : "Tout sélectionner",
    "misc.select.suggested"                     : "Suggéré",
    "misc.select.currentPage"                   : "Sélectionnez la page actuelle",
    "misc.unselect.all"                         : "Tout déselectionner",
    "misc.show"                                 : "Afficher",
    "misc.skip"                                 : "Passer",
    "misc.all"                                  : "Tous",
    "misc.continue"                             : "Poursuivre",
    "misc.submit"                               : "Soumettre",
    "misc.new"                                  : "Nouveau",
    "misc.deprecated"                           : "Obsolète",
    "misc.from"                                 : "De",
    "misc.to"                                   : "À",
    "misc.duplicate"                            : "Dupliquer",

    "misc.copy.1" : "Copier",
    "misc.copy.2" : "Contenu copié !",
    "misc.copy.3" : "Impossible de copier",

    "misc.pending"       : "En attente",
    "misc.inProgress"    : "En cours",
    "misc.priceValue"    : "{price} $",
    "misc.price"         : "Prix",
    "misc.load"          : "Chargement",
    "misc.loading"       : "Chargement...",
    "misc.error"         : "Une erreur s'est produite",
    "misc.oops"          : "Oups !",
    "misc.error.apology" : "Nous nous excusons pour les inconvénients.",
    "misc.success"       : "Succès",
    "misc.warning"       : "Avertissement",
    "misc.danger"        : "Erreur",
    "misc.info"          : "Information",
    "misc.created"       : "Créé avec succès!",
    "misc.updated"       : "Mis à jour avec succès!",
    "misc.deleted"       : "Supprimé avec succès!",
    "misc.undefined"     : "Non défini",
    "misc.status"        : "Statut",
    "misc.free"          : "Gratuit",
    "misc.goToProfile"   : "Voir profil",
    "misc.organization"  : "Organisation",
    "misc.member"        : "Membre",
    "misc.refresh"       : "Actualiser",
    "misc.retry"         : "Réessayer",

    "misc.googlePlaces.open"                    : "Ouvrir dans Google Maps",
    "misc.googlePlaces.googleMaps"              : "Google Maps",
    "misc.googlePlaces.placeholder"             : "Indiquez un lieu",
    "misc.googlePlaces.manualAddress.toggleOn"  : "Entrer l'adresse manuellement",
    "misc.googlePlaces.manualAddress.toggleOff" : "Indiquer l'adresse avec l'assistant Google",

    "misc.quickview"                         : "Aperçu rapide",
    "misc.quickview.open"                    : "Ouvrir dans l'aperçu rapide",
    "misc.quickview.navigation.blocked"      : "Navigation bloquée",
    "misc.quickview.navigation.blocked.info" : "La navigation à l'intérieur du aperçu rapide est limitée au profil du membre sélectionné.",
    "misc.quickview.allTabs"                 : "Tous les onglets",
    "misc.quickview.collapseQuickView"       : "Minimiser",
    "misc.quickview.closeAllTabs"            : "Fermer tous les onglets",
    "misc.quickview.closeAllTabs.confirm"    : "Fermer tous les onglets de l'aperçu rapide?",

    "misc.comingSoon" : "Prochainement",

    "misc.maxMinAge" : "Âge minimale: {min} - Âge maximale: {max}",
    "misc.yearsOld"  : "{age} ans",
    "misc.ageRange"  : "{from} à {to} ans",

    "misc.daysAgo"  : "Il y a {days, plural, =0 {# jour} one {# jour} other {# jours}}",
    "misc.hoursAgo" : "Il y a {hours, plural, =0 {# heure} one {# heure} other {# heures}}",

    "misc.fatalError.goback" : "Revenir sur la plateforme",


    "misc.notFound"            : "Introuvable",
    "misc.notSupported.title"  : "Ce fureteur n'est pas supporté",
    "misc.notSupported.text.1" : "La version de votre fureteur n'est pas supportée par notre plateforme",
    "misc.notSupported.text.2" : "SVP, veuillez le mettre à jour ou essayez un différent fureteur",

    "misc.invalid.date" : "Date invalide",

    "misc.apiPartner" : "Partenaire API",

    // Actions
    "misc.yes"                     : "Oui",
    "misc.no"                      : "Non",
    "misc.yes.no.1"                : "Oui",
    "misc.yes.no.0"                : "Non",
    "misc.yes.no.true"             : "Oui",
    "misc.yes.no.false"            : "Non",
    "misc.prefer_not_to_say"       : "Préfère ne pas répondre",
    "misc.prefer_to_self_describe" : "Préfère se décrire eux-même",
    "misc.noOptions"               : "Aucune option",
    "misc.create.option"           : "Créer l'option: {label}",
    "misc.noInfo"                  : "Aucune Info",
    "misc.no_info"                 : "Aucune Info",
    "misc.other"                   : "Autre",
    "misc.ok"                      : "Ok",
    "misc.edit"                    : "Modifier",
    "misc.save"                    : "Enregistrer",
    "misc.replace"                 : "Remplacer",
    "misc.actions"                 : "Actions",
    "misc.action"                  : "Action",
    "misc.back"                    : "Retour",
    "misc.next"                    : "Suivant",
    "misc.previous"                : "Précédent",
    "misc.cancel"                  : "Annuler",
    "misc.confirm"                 : "Confirmer",
    "misc.close"                   : "Fermer",
    "misc.finish"                  : "Terminer",
    "misc.export"                  : "Exporter",
    "misc.excel"                   : "Excel",
    "misc.pdf"                     : "PDF",
    "misc.print"                   : "Imprimer",
    "misc.add"                     : "Ajouter",
    "misc.addMultiple"             : "Ajout multiple",
    "misc.delete"                  : "Supprimer",
    "misc.remove"                  : "Enlever",
    "misc.clear"                   : "Effacer",
    "misc.create"                  : "Créer",
    "misc.none"                    : "Aucun",
    "misc.active"                  : "Actif",
    "misc.inactive"                : "Inactif",
    "misc.disable"                 : "Désactiver",
    "misc.enable"                  : "Activer",
    "misc.optional"                : "Optionnel",
    "misc.mandatory"               : "Obligatoire",
    "misc.critical"                : "Critique",
    "misc.status.1"                : "Actif",
    "misc.status.0"                : "Inactif",
    "misc.goTo"                    : "Aller à",
    "misc.copy"                    : "Copier",
    "misc.approve"                 : "Approuver",
    "misc.decline"                 : "Refuser",
    "misc.nonExistant"             : "*Information manquante*",

    "misc.minimum" : "Minimum: {value}",
    "misc.maximum" : "Maximum: {value}",

    // Lang
    "misc.en"                : "EN",
    "misc.en.text"           : "Anglais",
    "misc.en.text.noCapital" : "anglais",
    "misc.en.long"           : "Anglais",
    "misc.english"           : "Anglais",
    "misc.fr"                : "FR",
    "misc.fr.text"           : "Français",
    "misc.fr.text.noCapital" : "français",
    "misc.fr.long"           : "Français",
    "misc.french"            : "Français",

    "misc.current"   : "Courant",
    "misc.bilingual" : "Bilingue",

    // Favorites
    "misc.favorite"    : "Favori",
    "misc.favorited"   : "Favori",
    "misc.unfavorited" : "N'est plus favori",

    // Search
    "misc.search.empty.title"         : "Aucun résultat...",
    "misc.search.empty.message"       : "Vous pouvez effectuer une recherche ou utiliser la recherche avancée en haut de cette page.",
    "misc.search.empty.message.short" : "Vous pouvez utilisez la recherche en haut de cette page.",
    "misc.paginationMessage"          : "Rangées {itemStartPos} à {itemEndPos} sur un total de {totalItems} rangées.",
    "misc.select.all.tooltip"         : "Seules les 500 premières rangées seront sélectionnées",

    // Clipboard helper
    "misc.clipboard.success"         : "Copié dans le presse-papier!",
    "misc.clipboard.address.success" : "Adresse copiée!",
    "misc.addedToFavorites"          : "Page ajoutée aux favoris avec succès!",
    "misc.removedFromFavorites"      : "Page retirée des favoris avec succès!",

    "misc.clipboard.error" : "Impossible de copier dans le presse-papiers",

    // select
    "misc.download"          : "Télécharger",
    "misc.select.nbSelected" : "{count} sélectionnées",
    "misc.selected"          : "Sélectionné",

    // file upload
    "misc.fileUpload.text.1"  : "Ajouter un document",
    "misc.fileUpload.text.2"  : "ou glissez le ici",
    "misc.fileUpload.maxSize" : "Taille maximale du fichier",

    // wizard
    "misc.wizard.review.almostDone" : "Vous avez presque terminé!",
    "misc.wizard.review.reviewInfo" : "Vous pouvez réviser les informations du formulaire et faire des derniers changements ci-dessous.",

    "misc.baseData" : "Informations de base",
}
