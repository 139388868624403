import SpordleTableProvider from "@spordle/datatables";
import { FormikInputText } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Formik } from "formik";
import { useContext } from "react";
import { Button, Col, Collapse, FormGroup, Label } from "reactstrap";
import { object, string } from "yup";
import TeamRosterSearchView from "../components/TeamRosterSearchView";
import { TeamsContext } from "../../../../../../../../contexts/TeamsContext";
import { OrganizationContext } from "../../../../../../../../contexts/OrganizationContext";

const TeamRosterSearchBranch = ({ getTableProps, preformatSubmit }) => {
    const { getAvailableBranchMembers } = useContext(TeamsContext);
    const { organisation_id } = useContext(OrganizationContext);

    return (
        <SpordleTableProvider {...getTableProps(getAvailableBranchMembers, true)} id='roster-search-name'>
            {({ filterChange }) => (
                <Formik
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        unique_identifier: '',
                        organisation_id: organisation_id,
                    }}
                    validationSchema={object().shape({
                        first_name: string(),
                        last_name: string(),
                        unique_identifier: string(),
                    })}
                    onSubmit={(values, { setStatus }) => {
                        // Need at least one to tricker search
                        if(!values.first_name && !values.last_name && !values.unique_identifier)
                            setStatus('error-form.validation.atLeastOneField')
                        else{
                            setStatus()
                            const newVal = preformatSubmit(values);
                            filterChange('search', newVal);
                        }
                    }}
                >
                    {(formik) => (
                        <TeamRosterSearchView>
                            <Col sm="3">
                                <FormGroup>
                                    <Label for='first_name' className='text-muted'><Translate id='form.fields.firstName' /></Label>
                                    <FormikInputText id='first_name' name='first_name' />
                                </FormGroup>
                            </Col>
                            <Col sm="3">
                                <FormGroup>
                                    <Label for='last_name' className='text-muted'><Translate id='form.fields.lastName' /></Label>
                                    <FormikInputText id='last_name' name='last_name' />
                                </FormGroup>
                            </Col>
                            <Col sm="3">
                                <FormGroup>
                                    <Label for='unique_identifier' className='text-muted'><Translate id="form.fields.uniqueIdentifier" /></Label>
                                    <FormikInputText id='unique_identifier' name='unique_identifier' />
                                </FormGroup>
                            </Col>
                            <Col sm="3" className="align-self-end">
                                <FormGroup>
                                    <Button type="submit" block color="primary"><i className="ti-search" /> <Translate id='misc.search' /></Button>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <Collapse isOpen={formik.status?.includes('error')}>
                                    {formik.status && // to prevent error message in console when status is undefined
                                        <div className="text-danger small"><i className="mdi mdi-alert-outline mr-1" /><Translate id={formik.status?.split('-')?.[1]} defaultMessageId='misc.error' /></div>
                                    }
                                </Collapse>
                            </Col>
                        </TeamRosterSearchView>
                    )}
                </Formik>
            )}
        </SpordleTableProvider>
    )
}

export default TeamRosterSearchBranch;