import { stringBuilder } from "@spordle/helpers";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { success, fail } from "@spordle/toasts";
import { useContext, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import { SupervisionsContext } from "../../../../../contexts/SupervisionsContext";
import { HoverableCopy } from "../../../../../helpers/clipboardHelper";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import SupervisionSidePanelNotes from "./tabs/SupervisionSidePanelNotes";
import SupervisionSidePanelInfo from "./tabs/SupervisionSidePanelInfo";
import SupervisionSidePanelSupervisionType from "./tabs/SupervisionSidePanelSupervisionType";
import AllOfficialReportsModal from "../allOfficialReportsModal/AllOfficialReportsModal";
import moment from "moment";
import CanDoAction from "../../../../../components/permissions/CanDoAction";

const OfficialSupervisionSidePanel = ({ tableRef, forceCloseSidePanel, selectedRows, syncRows, createNewValues, isReadOnly }) => {
    const supervision = selectedRows[0];
    const [ currentTab, setCurrentTab ] = useState(1);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ allReportsModalIsOpen, setAllReportsModalIsOpen ] = useState(false);
    const { deleteSupervision, partiallyUpdateSupervision, sendSupervisionEmail } = useContext(SupervisionsContext);

    const handleCatch = (e) => {
        if(!AxiosIsCancelled(e.message)){
            setIsLoading(false);
            fail({ info: <DisplayI18n field="message" defaultValue={e.message} i18n={e.i18n} />, skipInfoTranslate: true });
        }
    }

    const updateSupervisionReport = (apiVal, rowVal) => {
        setIsLoading(true);
        partiallyUpdateSupervision(supervision.supervision_id, apiVal)
            .then(() => {
                setIsLoading(false);
                success();
                syncRows(createNewValues(rowVal || apiVal))
            })
            .catch(handleCatch)
    }

    const handleOnSendEmail = () => {
        setIsLoading(true);
        sendSupervisionEmail(supervision.supervision_id)
            .then(() => {
                setIsLoading(false);
                success();
                syncRows(createNewValues({ sent_date: moment().toISOString() }))
            })
            .catch(handleCatch)
    }

    const deleteSupervisionReport = () => {
        return deleteSupervision(supervision.supervision_id)
            .then(() => {
                setIsLoading(false);
                success();
                tableRef.deleteRow(supervision.supervision_id).then(forceCloseSidePanel);
            })
            .catch(handleCatch)
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <AllOfficialReportsModal member={supervision.official} isOpen={allReportsModalIsOpen} toggle={() => setAllReportsModalIsOpen(false)} />
            <SidePanel.Header noBorder>
                <div className="mb-3 d-flex align-items-center">
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu className="ml-auto">
                        <SidePanel.MenuAction onClick={() => setAllReportsModalIsOpen(true)}>
                            <Translate id='officialSupervision.allReportsBtn.long' />
                        </SidePanel.MenuAction>
                        <SidePanel.MenuAction action="ADD" componentCode="members" componentPermissionCode="manage_official_supervisions" onClick={handleOnSendEmail}>
                            <Translate id='officialSupervision.sendEmail' />
                        </SidePanel.MenuAction>
                        <CanDoAction action="DELETE" componentCode="members" componentPermissionCode="manage_official_supervisions">
                            {(godAccess) => (
                                <>
                                    <SidePanel.MenuAction divider />
                                    <SidePanel.MenuDelete
                                        modalMsg='officialSupervision.sidePanel.delete.msg'
                                        translateModalMsg
                                        buttonProps={{
                                            className: stringBuilder({ 'text-purple': godAccess }),
                                        }}
                                        onConfirm={deleteSupervisionReport}
                                        modalContent={
                                            <>
                                                <div className="text-muted">({supervision.supervision_date})</div>
                                                <div className="font-bold h4">
                                                    <DisplayI18n field="name" defaultValue={supervision.supervision_type.name} i18n={supervision.supervision_type.i18n} />
                                                </div>
                                                <UserDisplay card block className="mb-0">
                                                    <UserDisplay.Container>
                                                        <UserImg
                                                            width={45}
                                                            className="text-uppercase"
                                                            alt={`${supervision.official.first_name} ${supervision.official.last_name}`}
                                                            abbr={`${supervision.official.first_name.charAt(0)}${supervision.official.last_name.charAt(0)}`}
                                                        />
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container>
                                                        <UserDisplay.Title>{supervision.official.first_name} {supervision.official.last_name}</UserDisplay.Title>
                                                        <UserDisplay.Subtitle>
                                                            <HoverableCopy toCopy={supervision.official.unique_identifier}>
                                                                #{supervision.official.unique_identifier}
                                                            </HoverableCopy>
                                                        </UserDisplay.Subtitle>
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            </>
                                        }
                                    />
                                </>
                            )}
                        </CanDoAction>
                    </SidePanel.ActionsMenu>
                </div>
                <SidePanel.Subtitle>
                    <Translate id='officialSupervision.supervisionReport.label' /> ({supervision.supervision_date})
                </SidePanel.Subtitle>
                <SidePanel.Title><DisplayI18n field="name" defaultValue={supervision.supervision_type.name} i18n={supervision.supervision_type.i18n} /></SidePanel.Title>
                {supervision.sent_date && <SidePanel.Subtitle>Last sent: <DateFormat format="YYYY-MM-DD HH:mm" value={supervision.sent_date} /></SidePanel.Subtitle>}
            </SidePanel.Header>
            <Nav className="is-full text-center" tabs>
                <NavItem className="flex-grow-1">
                    <NavLink onClick={() => setCurrentTab(1)} className={stringBuilder({ "active": currentTab === 1 })}>
                        <Translate id="officialSupervision.sidePanel.tab.info" />
                    </NavLink>
                </NavItem>
                <NavItem className="flex-grow-1">
                    <NavLink onClick={() => setCurrentTab(2)} className={stringBuilder({ "active": currentTab === 2 })}>
                        <Translate id="officialSupervision.sidePanel.tab.type" />
                    </NavLink>
                </NavItem>
                <NavItem className="flex-grow-1">
                    <NavLink onClick={() => setCurrentTab(3)} className={stringBuilder({ "active": currentTab === 3 })}>
                        <Translate id="officialSupervision.sidePanel.tab.notes" />
                    </NavLink>
                </NavItem>
            </Nav>
            <SidePanel.Body>
                <TabContent activeTab={currentTab}>
                    <TabPane tabId={1}>
                        <SupervisionSidePanelInfo showAllOfficialSupervisions={() => setAllReportsModalIsOpen(true)} isReadOnly={isReadOnly} updateSupervisionReport={updateSupervisionReport} supervision={supervision} />
                    </TabPane>
                    <TabPane tabId={2}>
                        <SupervisionSidePanelSupervisionType isReadOnly={isReadOnly} updateSupervisionReport={updateSupervisionReport} supervision={supervision} />
                    </TabPane>
                    <TabPane tabId={3}>
                        <SupervisionSidePanelNotes isReadOnly={isReadOnly} updateSupervisionReport={updateSupervisionReport} supervision={supervision} />
                    </TabPane>
                </TabContent>
            </SidePanel.Body>
        </OverlayLoader>
    );
}

export default OfficialSupervisionSidePanel;