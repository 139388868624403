const tasksSidebar = [
    {
        navlabel: true,
        name: 'sidebar.requests',
        subPermissionsRoutes: [
            // Requests
            {
                //tasksManageRequestsTabsRoutes
                path: '/tasks/manage-requests',
                name: 'task.manageRequests.title',
                permissionsLogic: "OR",
                permissions: [
                    { code: 'members', permissionCode: 'approve_request_mandatory_fields' }, // path: '/member-change-requests'
                    { code: 'members', permissionCode: 'manage_international_transfer_dates' }, // path: '/member-cti-requests'
                    { code: 'organization', permissionCode: 'review_organisation_status' }, // path: '/unconfirmed-organizations'
                    { code: 'members', permissionCode: 'system_document_type_need_approbation' }, // path: '/member-attachment-approval'
                    { code: 'members', permissionCode: 'manage_classification_request' }, // path: '/classification-change-requests'
                    { code: 'members', permissionCode: 'approve_permanent_release' }, // path: '/member-permanent-release-requests'
                ],
            },
        ],
    },
    {
        navlabel: true,
        name: 'sidebar.tasks',
        subPermissionsRoutes: [
            {
                path: '/tasks/transfer',
                name: 'transfer.title',
                permissions: { code: 'members', permissionCode: 'members_request_transfer' },
            },
            {
                path: '/tasks/travelpermit',
                name: 'travelPermit.title',
                permissions: { code: 'teams', permissionCode: 'team_travel_permits' },
                showWhenSetting: 'enable_travel_permit',
            },
            {
                path: '/tasks/discrimination',
                name: 'discrimination.title',
                permissions: { code: 'organization', permissionCode: 'discrimination_claims' },
            },
            {
                path: '/tasks/insurance',
                name: 'insurance.title',
                permissions: [ { code: 'members', permissionCode: 'members_injuries' }, { code: 'settings', permissionCode: 'injury_configurations' } ],
            },
            {
                path: '/tasks/gameincident',
                name: 'gameIncident.title',
                permissions: [ { code: 'organization', permissionCode: 'game_incidents' } ],
            },
            {
                path: '/tasks/officialsupervision',
                name: 'officialSupervision.title',
                permissions: [ { code: 'members', permissionCode: 'manage_official_supervisions' } ],
            },
            {
                path: '/tasks/waiting-list',
                name: 'task.waitingList.title',
                permissions: [ { code: 'members', permissionCode: 'manage_registration_waiting_list' } ],
            },
            {
                path: '/tasks/potential-ineligible-members',
                name: 'task.ineligible.title',
                permissions: [ { code: 'members', permissionCode: 'manage_potential_ineligible_members' } ],
            },
            {
                path: '/tasks/potential-member-duplicates',
                name: "tasks.memberDuplicates.title",
                permissions: [ { code: 'members', permissionCode: 'manage_potential_duplicate_members' } ],
            },
            {
                path: '/tasks/renew-members',
                name: "task.renewMembers.title",
                permissions: [ { code: 'members', permissionCode: 'members_profile' } ],
                showWhenSetting: 'manage_affiliation_invoice',
            },
        ],
    },
    {
        sortAlphabetical: true,
        api: true,
        skeletonsAmount: 1,
        context: 'checklists',
        name: 'sidebar.checklists',
        navlabel: true,
        subPermissionsRoutes: [
            {
                path: '/tasks/checklists/',
                name: 'sidebar.checklists',
                permissions: { code: 'organization', permissionCode: 'organization_profile' },
                hidden: true,
            },
        ],
    },
    // {
    //     navlabel: true,
    //     subPermissionsRoutes: [

    //         {
    //             path: '/tasks/checklist-season-rollover',
    //             name: 'sidebar.checklists.seasonRollover',
    //             permissions: [ { code: 'organization', permissionCode: "season_rollover" } ],
    //             envFeatureFlag: [ "int" ],
    //         },
    //         {
    //             path: '/tasks/checklist-onlineregistration',
    //             name: 'sidebar.checklists.onlineRegistration',
    //             permissions: [ { code: 'organization', permissionCode: "season_rollover" } ],
    //             envFeatureFlag: [ "int" ],
    //         },
    //         {
    //             path: '/tasks/checklist-clinics',
    //             name: 'sidebar.checklists.clinic',
    //             permissions: [ { code: 'organization', permissionCode: "season_rollover" } ],
    //             envFeatureFlag: [ "int" ],
    //         },

    //     ],
    // },

];
export default tasksSidebar;
