export default{
    "gameIncident.title"                     : "Game Incident Report",
    "gameIncident.label.anonymous"           : "Anonymous",
    "gameIncident.search.alreadyAdded"       : "Already Added",
    "gameIncident.sidePanel.delete.incident" : "You are about to delete this player incident",
    "gameIncident.sidePanel.delete.official" : "You are about to delete this official from the game incident report",
    "gameIncident.sidePanel.submit.btn"      : "Submit the report",
    "gameIncident.sidePanel.resubmit.btn"    : "Resubmit the report",
    "gameIncident.sidePanel.submit.msg"      : "You are about to submit this game incident report",
    "gameIncident.sidePanel.resubmit.msg"    : "You are about to submit this game incident report again",
    "gameIncident.sidePanel.lastUpdated"     : "This report was last updated on {date}",

    "gameIncident.addModal.title"                       : "Add Game Incident Report (Step {step} of {stepNb})",
    "gameIncident.addModal.steps.officials.title"       : "Officials",
    "gameIncident.addModal.steps.officials.add.title"   : "Adding official",
    "gameIncident.addModal.steps.teams.title"           : "Teams",
    "gameIncident.addModal.steps.teams.other"           : "Other",
    "gameIncident.addModal.steps.teams.existing"        : "Existing",
    "gameIncident.addModal.steps.teams.nonExisting"     : "Non existing",
    "gameIncident.addModal.steps.match.title"           : "Game Info",
    "gameIncident.addModal.steps.incident.title"        : "Incident",
    "gameIncident.addModal.steps.status.title"          : "Do you want to submit this game incident report now?",
    "gameIncident.addModal.steps.status.btn.yes"        : "Yes",
    "gameIncident.addModal.steps.status.btn.yes.helper" : "I want to submit this game incident report",
    "gameIncident.addModal.steps.status.btn.no"         : "No",
    "gameIncident.addModal.steps.status.btn.no.helper"  : "I do not want to submit this report yet",

    "gameIncident.addModal.label.season"               : "Season",
    "gameIncident.addModal.label.class"                : "Class",
    "gameIncident.addModal.label.division"             : "Division",
    "gameIncident.addModal.label.branch"               : "Branch",
    "gameIncident.addModal.label.org"                  : "Organization",
    "gameIncident.addModal.label.category"             : "Category",
    "gameIncident.addModal.label.league"               : "League",
    "gameIncident.addModal.label.location"             : "Location",
    "gameIncident.addModal.label.game"                 : "Game #",
    "gameIncident.addModal.label.coach"                : "Coach",
    "gameIncident.addModal.label.coachList"            : "Coach List",
    "gameIncident.addModal.label.coach.option.other"   : "Non listed coach",
    "gameIncident.addModal.label.coach.other"          : "Other Coach Name",
    "gameIncident.addModal.label.manager"              : "Manager",
    "gameIncident.addModal.label.managerList"          : "Staff List",
    "gameIncident.addModal.label.manager.option.other" : "Non listed manager",
    "gameIncident.addModal.label.manager.other"        : "Other Manager Name",
    "gameIncident.addModal.label.team"                 : "Team",
    "gameIncident.addModal.label.officials"            : "Officials",
    "gameIncident.addModal.label.incident"             : "Incident",
    "gameIncident.addModal.label.player"               : "Player",
    "gameIncident.addModal.label.period"               : "Period",
    "gameIncident.addModal.label.time"                 : "Time",
    "gameIncident.addModal.label.minutes"              : "Minutes",
    "gameIncident.addModal.label.seconds"              : "Seconds",
    "gameIncident.addModal.label.infractionType"       : "Penalty Type",
    "gameIncident.addModal.label.infraction"           : "Penalty",
    "gameIncident.addModal.label.injury"               : "Sustained an injury",
    "gameIncident.addModal.label.returned"             : "Returned to game",
    "gameIncident.addModal.label.notReturned"          : "Didn't return to game",
    "gameIncident.addModal.label.note"                 : "Note",
    "gameIncident.addModal.label.jersey"               : "Jersey",
    "gameIncident.addModal.label.date"                 : "Incident date",
    "gameIncident.addModal.label.officialType"         : "Official Type",

    "gameIncident.addModal.label.gamePeriod.FIRST"          : "First",
    "gameIncident.addModal.label.gamePeriod.SECOND"         : "Second",
    "gameIncident.addModal.label.gamePeriod.THIRD"          : "Third",
    "gameIncident.addModal.label.gamePeriod.OVERTIME"       : "Overtime",
    "gameIncident.addModal.label.gamePeriod.SHOOTOUT"       : "Shootout",
    "gameIncident.addModal.label.gamePeriod.PRE_POST_GAME"  : "Pre-game / Post-game",
    "gameIncident.addModal.steps.officials.type.REFEREE"    : "Referee",
    "gameIncident.addModal.steps.officials.type.LINESMAN"   : "Linesperson",
    "gameIncident.addModal.steps.officials.type.SUPERVISOR" : "Supervisor",

    "gameIncident.addModal.validation.player"        : "Please select a player",
    "gameIncident.addModal.validation.penaltyType"   : "Please select a penalty type",
    "gameIncident.addModal.validation.penalty"       : "Please select a penalty",
    "gameIncident.addModal.validation.team"          : "Please select a team",
    "gameIncident.addModal.validation.season"        : "Please select a season",
    "gameIncident.addModal.validation.division"      : "Please select a division",
    "gameIncident.addModal.validation.category"      : "Please select a category",
    "gameIncident.addModal.validation.phone"         : "The phone number is required for this official",
    "gameIncident.addModal.validation.email"         : "The email is required for this official",
    "gameIncident.addModal.validation.date"          : "Please enter an incident date",
    "gameIncident.addModal.validation.date.valid"    : "Please enter a valid date",
    "gameIncident.addModal.validation.officialType"  : "Please specify the official type",
    "gameIncident.addModal.validation.date.future"   : "Date cannot be set in the future",
    "gameIncident.addModal.validation.jersey.max"    : "Please enter a jersey number that is under 100",
    "gameIncident.addModal.validation.time.min"      : "Please enter a number that is 0 or over",
    "gameIncident.addModal.validation.time.max"      : "Please enter a number under {max}",
    "gameIncident.addModal.validation.teamWarning"   : "Warning",
    "gameIncident.addModal.validation.twoOtherTeams" : "One of the two teams must be an existing one.",
    "gameIncident.addModal.validation.twoSameTeams"  : "Please select two different teams",
    "gameIncident.addModal.validation.status"        : "Please select an game incident status",
    "gameIncident.addModal.validation.organisation"  : "Please select an organization",
    "gameIncident.addModal.validation.teamName"      : "Please specify the team name",
    "gameIncident.addModal.validation.jerseyNb"      : "The jersey number must be a number",
    "gameIncident.addModal.validation.coach.other"   : "Please specify the coach's name or clear the coach select.",
    "gameIncident.addModal.validation.manager.other" : "Please specify the manager's name or clear the manager select.",
    "gameIncident.addModal.validation.officials.min" : "Please add at least one official.",

    "gameIncident.sidePanel.official.filledRequest"       : "Filled request",
    "gameIncident.sidePanel.export.pdf"                   : "Export to PDF",
    "gameIncident.sidePanel.export.error.3382"            : "Something happened while generating the pdf",
    "gameIncident.sidePanel.export.toast.info"            : "If your file wasn't automatically downloaded, try again by <a>clicking here.</a>",
    "gameIncident.sidePanel.export.toast.generating"      : "Generating...",
    "gameIncident.sidePanel.export.toast.generating.info" : "Your pdf is being generated",
    "gameIncident.sidePanel.export.toast.download"        : "Download!",

    "gameIncident.addModal.teams.home.label" : "Home team",
    "gameIncident.addModal.teams.away.label" : "Away team",

    "gameIncident.addModal.officials.title"        : "Add Official",
    "gameIncident.addModal.officials.btn.subtitle" : "Click here to add an official",

    "gameIncident.addModal.incident.title"        : "Add Incident",
    "gameIncident.addModal.incident.btn.subtitle" : "Click here to add an incident",
}