import { DisplayI18n, displayI18n } from '../../helpers/i18nHelper';

export const SESSION_ONLINE = "ONLINE";
export const SESSION_ONLINE_LIVE = "ONLINE_LIVE";
export const SESSION_ONSITE = "ONSITE";
export const SESSION_ALL = "ALL";

const ClinicQualificationBuilder = ({ qualification, ...props }) => {
    return (
        <>
            <DisplayI18n
                field='name'
                i18n={qualification?.i18n}
                defaultValue={qualification?.name}
            />
            {!!qualification?.qualification_level &&
                <>
                    <span className='mx-1'>-</span>
                    <DisplayI18n
                        field='name'
                        i18n={qualification?.qualification_level?.i18n}
                        defaultValue={qualification?.qualification_level?.name}
                    />
                </>
            }
        </>
    );
};

function clinicCategoryBuilder(qualification, locale){
    const strs = [];

    if(qualification){
        if(qualification.qualification_category){
            strs.push(displayI18n('name', qualification.qualification_category.i18n, qualification.qualification_category.name, locale));
        }

        strs.push(displayI18n('name', qualification.i18n, qualification.name, locale));

        if(qualification.qualification_level){
            strs.push(displayI18n('name', qualification.qualification_level.i18n, qualification.qualification_level.name, locale));
        }
    }

    return strs.join(' - ');
}

function clinicQualificationBuilder(qualification, locale){
    let returnString = '';

    if(qualification){
        returnString = returnString + displayI18n('name', qualification.i18n, qualification.name, locale);

        if(qualification.qualification_level){
            returnString = returnString = ' - ' + displayI18n('name', qualification.qualification_level.i18n, qualification.qualification_level.name, locale);
        }
    }
    return returnString
}

export{ ClinicQualificationBuilder, clinicQualificationBuilder, clinicCategoryBuilder }