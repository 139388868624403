import addQualifications from "./addQualifications";
import categories from "./categories";
import qualifications from "./qualifications";
import clinicSettings from "./clinicSettings";

export default{
    ...addQualifications,
    ...categories,
    ...qualifications,
    ...clinicSettings,
}