import { useContext, useEffect } from 'react';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import TabRoutesPanes from '../../../components/subSidebar/TabRoutesPanes';
import { fail } from "@spordle/toasts";
import { FormsContext } from '../../../contexts/FormsContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import catalogFormsTabsRoutes from '../../../routes/tabRoutes/catalogFormsTabsRoutes';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';

const Forms = () => {
    const formsContext = useContext(FormsContext);

    useEffect(() => {
        formsContext.getFormFields()
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    return (
        <>
            <ViewHeaderV2
                title='catalog.forms.forms.navTitle.forms'
                breadcrumbsProps={{
                    disablePeriodSelect: true,
                }}
            />

            <div className={"px-4"}>
                <TabRoutesPanes routes={catalogFormsTabsRoutes} />
            </div>
        </>
    );
}

export default Forms;