export default{
    "onlineStore.components.onlineStoreGeneralInformation.optional.male"   : "Optional",
    "onlineStore.components.onlineStoreGeneralInformation.optional.female" : "Optional",

    "onlineStore.components.onlineStoreGeneralInformation.section.generalInfo"        : "General Info",
    "onlineStore.components.onlineStoreGeneralInformation.section.registrationPeriod" : "Registration period",
    "onlineStore.components.onlineStoreGeneralInformation.section.message"            : "Message",

    "onlineStore.components.onlineStoreGeneralInformation.field.period"                : "Season",
    "onlineStore.components.onlineStoreGeneralInformation.field.period.season"         : "season",
    "onlineStore.components.onlineStoreGeneralInformation.field.period.text"           : "Please ensure the correct {season} is selected for your online store creation!",
    "onlineStore.components.onlineStoreGeneralInformation.field.languages"             : "Languages",
    "onlineStore.components.onlineStoreGeneralInformation.field.languages.placeholder" : "Choose a language",
    "onlineStore.components.onlineStoreGeneralInformation.field.organisation"          : "Organization",
    "onlineStore.components.onlineStoreGeneralInformation.field.formName"              : "Online Registration Name",
    "onlineStore.components.onlineStoreGeneralInformation.field.registrarEmail"        : "Registrar Email Address",
    "onlineStore.components.onlineStoreGeneralInformation.field.registrarEmail.sendTo" : "Send an email with each registration",
    "onlineStore.components.onlineStoreGeneralInformation.field.startDate"             : "Start Date",
    "onlineStore.components.onlineStoreGeneralInformation.field.startTime"             : "Start Time",
    "onlineStore.components.onlineStoreGeneralInformation.field.endDate"               : "End Date",
    "onlineStore.components.onlineStoreGeneralInformation.field.endTime"               : "End Time",
    "onlineStore.components.onlineStoreGeneralInformation.field.confirmMessage"        : "Confirmation Message",
    "onlineStore.components.onlineStoreGeneralInformation.field.bypassPostalCode"      : "Postal Code Verification",

    "onlineStore.components.onlineStoreGeneralInformation.validation.bypassPostalCode"       : "Please specify whether or not this store bypasses the organization's postal codes.",
    "onlineStore.components.onlineStoreGeneralInformation.validation.organisation.required"  : "Please choose an organization",
    "onlineStore.components.onlineStoreGeneralInformation.validation.registrar.required"     : "You must enter the email address of a registrar",
    "onlineStore.components.onlineStoreGeneralInformation.validation.registrar.emailFormat"  : "Must be an email address format",
    "onlineStore.components.onlineStoreGeneralInformation.validation.language.min"           : "You must select at least one language",
    "onlineStore.components.onlineStoreGeneralInformation.validation.formName.required"      : "Online registration nme name is required",
    "onlineStore.components.onlineStoreGeneralInformation.validation.date.required"          : "Date required",
    "onlineStore.components.onlineStoreGeneralInformation.validation.date.invalidFormat"     : "Invalid date",
    "onlineStore.components.onlineStoreGeneralInformation.validation.time.required"          : "Time required",
    "onlineStore.components.onlineStoreGeneralInformation.validation.time.invalidFormat"     : "Invalid time",
    "onlineStore.components.onlineStoreGeneralInformation.validation.endDate.afterStartDate" : "The end date must be after the start date",

    "onlineStore.components.onlineStoreGeneralInformation.hint.pickLanguage" : "Choose a language",
}