import { useContext } from 'react';
import {
    Container,
    Card,
    Button,
    CardBody,
    CardHeader,
    CardFooter,
    FormGroup,
    Label,
    Row,
    Col,
    Collapse,
    Alert
} from "reactstrap";

import { Formik, Form } from "formik";
import { object, string } from 'yup';
import FormikTiny from '../../../../components/formik/FormikTiny';
import { FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import Required from '../../../../components/formik/Required'
// contexts
import { FormsContext } from '../../../../contexts/FormsContext';
import { ClinicsContext } from '../../../../contexts/ClinicsContext';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { TermsConditionsContext } from '../../../../contexts/TermsConditionsContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { WaiversContext } from '../../../../contexts/WaiversContext';
import { fail } from "@spordle/toasts";
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import FileUpload from '../../../../components/uploader/fileUpload/FileUpload';
import { UtilsContext } from '../../../../contexts/UtilsContext';
import { isNewFile } from '../../../../components/uploader/uploadHelpers';

const FormClinicCommunications = (props) => {
    const clinicsContext = useContext(ClinicsContext);
    const formsContext = useContext(FormsContext);
    const termsContext = useContext(TermsConditionsContext);
    const waiversContext = useContext(WaiversContext);
    const utilsContext = useContext(UtilsContext);

    /**
     * This function will handle the selection of a form or removing the selection "none"
     * @param {string} customFormId
     * @param {string} clinicId
     */
    const formsLink = (customFormId, clinicId) => {
        if(customFormId){ // Update form id for given clinic
            return formsContext.formClinicLink(customFormId, clinicId)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        } // None is selected: Delete form id for given clinic
        return formsContext.deleteFormClinicLink(clinicId)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

    }

    const formatFile = (file) => ({
        id: file.attachement_id,
        name: file.file_name,
        url_path: file.full_path,
        type: file.file_ext,
        canRemove: true, // Future update
        ...file,
    });

    const compareAndFormatFiles = (initFiles, newFiles) => {
        const obj = initFiles.reduce((newObj, initFile) => {
            const string = !newFiles?.some((f) => f.id == initFile.attachement_id) ? 'toDelete' : 'toKeep';
            newObj[string]?.push(initFile);

            return newObj;
        }, { toKeep: [], toDelete: [] });

        const toCreate = [];
        for(let i = 0; i < newFiles.length; i++){
            const file = newFiles[i];
            if(isNewFile(file)){
                toCreate.push(file);
            }
        }
        obj.toCreate = toCreate;

        return obj;
    }

    const getAfterRegistrationInitAttachments = () => clinicsContext.currentClinic?.message_after_registration?.attachments?.map(formatFile) || [];
    const getConfirmationInitAttachments = () => clinicsContext.currentClinic?.message_confirmation_email?.attachments?.map(formatFile) || [];

    const attachmentsOnSubmit = async(files, isAfterRegistration, clinic) => {
        const initialFiles = isAfterRegistration ? getAfterRegistrationInitAttachments() : getConfirmationInitAttachments();
        const messageId = isAfterRegistration ? clinic.message_after_registration.message_id : clinic.message_confirmation_email.message_id;
        const { toDelete, toCreate } = compareAndFormatFiles(initialFiles, files);

        if(toDelete?.length > 0){
            await Promise.all(
                toDelete.map((att) => (
                    utilsContext.deleteMessageAttachment(messageId, att.message_attachment_id)
                )),
            ).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        }

        if(toCreate?.length > 0){
            await utilsContext.createMessageAttachment(
                messageId,
                toCreate,
            ).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        }
    }

    return (
        <Container>
            <Formik
                initialValues={{
                    tinyAfterRegistration: clinicsContext.currentClinic?.message_after_registration?.message || '',
                    afterRegistrationAttachments: getAfterRegistrationInitAttachments(),
                    tinyConfirmation: clinicsContext.currentClinic?.message_confirmation_email?.message || '',
                    confirmationAttachments: getConfirmationInitAttachments(),
                    customFormId: (clinicsContext.currentClinic?.custom_form || [])[0]?.custom_form_id || '',
                    waivers: clinicsContext.currentClinic?.waivers?.map((waiver) => waiver.waiver_id) || [],
                    terms: clinicsContext.currentClinic?.term_and_condition?.term_and_condition_id || '',
                }}
                validationSchema={object().shape({
                    tinyAfterRegistration: string().required(<Translate id='clinics.clinicCommunications.formClinicCommunications.registration.message.required' />),
                    tinyConfirmation: string().required(<Translate id='clinics.clinicCommunications.formClinicCommunications.confirmation.message.required' />),
                    terms: string().required(<Translate id='clinics.clinicCommunications.formClinicCommunications.terms.required' />),
                })}
                onSubmit={(values, { setStatus }) => {
                    setStatus();
                    return Promise.all([
                        clinicsContext.updateClinicPartial(clinicsContext.currentClinic?.clinic_id, {
                            afterRegistration: values.tinyAfterRegistration,
                            confirmation: values.tinyConfirmation,
                            termAndConditionId: values.terms,
                        }),
                        clinicsContext.updateWaivers(clinicsContext.currentClinic?.clinic_id, values.waivers),
                    ])
                        .then(async() => {
                            const clinic = await clinicsContext.getClinic(clinicsContext.currentClinic.clinic_id);
                            await Promise.all([
                                attachmentsOnSubmit(values.afterRegistrationAttachments, true, clinic),
                                attachmentsOnSubmit(values.confirmationAttachments, false, clinic),
                            ])

                            // Create forms link if any was selected

                            await Promise.all([
                                formsLink(values.customFormId, clinicsContext.currentClinic?.clinic_id),
                                clinicsContext.getAllClinicInfos(clinicsContext.currentClinic?.clinic_id),
                            ])
                                .catch((error) => {
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                    console.error(error.message)
                                })

                            props.exited();
                        }).catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                if(typeof error !== 'string'){
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                    console.error(error);
                                }else{ // Old format
                                    console.error(error);
                                }
                            }
                        })
                }}
            >
                {(formik) => {
                    return (
                        <Form id='clinicsCreationStep3'>
                            <Card className={'card-shadow' + (!props.wizard ? ' shadow-primary' : '')}>
                                <OverlayLoader isLoading={formik.isSubmitting}>
                                    {!props.wizard &&
                                        <CardHeader className="d-flex align-items-center">
                                            <div className="h4 mb-0 font-bold"><Translate id='clinics.clinicCommunications.formClinicCommunications.review.title' /></div>
                                        </CardHeader>
                                    }
                                    <CardBody>

                                        <div className={`font-bold mb-3 ${props.wizard ? 'h3 card-title border-bottom pb-1' : 'h5 '}`}><Translate id='clinics.clinicCommunications.formClinicCommunications.custom_forms.title' /></div>
                                        <Row className='mb-5'>
                                            <Col md='6'>
                                                <FormGroup>
                                                    <Label for="select-terms" className="h5 font-bold mb-2"><Translate id='clinics.clinicCommunications.formClinicCommunications.terms.title' /> <Required /></Label>
                                                    {/* Forms Select */}
                                                    <div className="mr-2" style={{ minWidth: "220px" }}>
                                                        <FormikSelect
                                                            id='FormClinicCommunicationsTerms' name="terms"
                                                            renderOption={(option) => <DisplayI18n field='title' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                            loadData={(from, extra, spordleTable) => {
                                                                switch (from){
                                                                    case 'CDM':
                                                                        return termsContext.getTermsConditions({ active: 1, organisation_id: clinicsContext.currentClinic.organisation.organisation_id })
                                                                            .then((terms) => {
                                                                                return terms.map((term) => ({
                                                                                    value: term.term_and_condition_id,
                                                                                    label: term.title,
                                                                                    i18n: term.i18n,
                                                                                }))
                                                                            });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <Label for="customFormId" className="h5 font-bold mb-2"><Translate id='clinics.clinicCommunications.formClinicCommunications.custom_forms.message' /></Label>
                                                    {/* Forms Select */}
                                                    <div className="mr-2" style={{ minWidth: "220px" }}>
                                                        <FormikSelect
                                                            id='FormClinicCommunicationsForms' name="customFormId" clearable
                                                            loadData={(from, extra, spordleTable) => {
                                                                switch (from){
                                                                    case 'CDM':
                                                                        return formsContext.getForms(clinicsContext.currentClinic.organisation.organisation_id, { active: 1 })
                                                                            .then((forms) => {
                                                                                return forms.map((form) => ({
                                                                                    value: form.custom_form_id,
                                                                                    label: form.name,
                                                                                }))
                                                                            })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md='6'>
                                                <FormGroup>
                                                    <Label for="select-waivers" className="h5 font-bold mb-2"><Translate id='clinics.clinicCommunications.formClinicCommunications.waivers.title' /></Label>
                                                    {/* Waivers Select */}
                                                    <div className="mr-2" style={{ minWidth: "220px" }}>
                                                        <FormikSelect
                                                            id='FormClinicCommunicationsWaivers' name="waivers" multi
                                                            clearable
                                                            renderOption={({ option }) => (
                                                                <>
                                                                    <div>
                                                                        <DisplayI18n
                                                                            field='name'
                                                                            defaultValue={option.label}
                                                                            i18n={option.i18n}
                                                                        />
                                                                    </div>
                                                                    {option.critical == 1 &&
                                                                        <div className='text-muted small'><Translate id='misc.critical' /></div>
                                                                    }
                                                                </>
                                                            )}
                                                            textWhenSetting={{
                                                                count: 2,
                                                                label: 'clinics.clinicCommunications.formClinicCommunications.waivers.textWhen',
                                                            }}
                                                            loadData={(from, extra, spordleTable) => {
                                                                switch (from){
                                                                    case 'CDM':
                                                                        return waiversContext.getWaivers({ active: 1 })
                                                                            .then((waivers) => {
                                                                                return waivers.map((waiver) => ({
                                                                                    value: waiver.waiver_id,
                                                                                    label: waiver.name,
                                                                                    i18n: waiver.i18n,
                                                                                    critical: waiver.critical,
                                                                                }))
                                                                            })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <div className='mb-5'>
                                            <div className={`font-bold mb-3 ${props.wizard ? 'h3 card-title border-bottom pb-1' : 'h5 '}`}><Translate id='clinics.clinicCommunications.formClinicCommunications.registration.title' /></div>
                                            <FormGroup>
                                                <Label for="tinyAfterRegistration" className="h5 font-bold mb-2"><Translate id='clinics.clinicCommunications.formClinicCommunications.registration.message' /> <Required /></Label>
                                                <FormikTiny name='tinyAfterRegistration' />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="afterRegistrationAttachments" className="h5 font-bold mb-2"><Translate id='clinics.clinicCommunications.formClinicCommunications.registration.attachments' /></Label>
                                                <FileUpload
                                                    defaultFiles={getAfterRegistrationInitAttachments()}
                                                    onFileSelect={(files) => {
                                                        formik.setFieldValue('afterRegistrationAttachments', files);
                                                    }}
                                                    dropzoneProps={{
                                                        multiple: true,
                                                        accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                                    }}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div>
                                            <div className={`font-bold mb-3 ${props.wizard ? 'h3 card-title border-bottom pb-1' : 'h5 '}`}><Translate id='clinics.clinicCommunications.formClinicCommunications.confirmation.title' /></div>
                                            <FormGroup>
                                                <Label for="tinyConfirmation" className="h5 font-bold mb-2"><Translate id='clinics.clinicCommunications.formClinicCommunications.confirmation.message' /> <Required /></Label>
                                                <FormikTiny name='tinyConfirmation' />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="confirmationAttachments" className="h5 font-bold mb-2"><Translate id='clinics.clinicCommunications.formClinicCommunications.confirmation.attachments' /></Label>

                                                <FileUpload
                                                    defaultFiles={getConfirmationInitAttachments()}
                                                    onFileSelect={(files) => {
                                                        formik.setFieldValue('confirmationAttachments', files);
                                                    }}
                                                    dropzoneProps={{
                                                        multiple: true,
                                                        accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                                    }}
                                                />
                                            </FormGroup>
                                        </div>

                                        <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                            <Alert color='danger'>
                                                {formik.status}
                                            </Alert>
                                        </Collapse>
                                    </CardBody>
                                    {!props.wizard &&
                                        <CardFooter className="bg-white text-right">
                                            <Button className='mr-2' color="primary" disabled={formik.isSubmitting} type='submit'><Translate id='clinics.clinicCommunications.formClinicCommunications.review.saveChanges' /></Button>
                                            <Button color="primary" outline type='button' disabled={formik.isSubmitting} onClick={props.exited}><Translate id='clinics.clinicCommunications.formClinicCommunications.review.cancelChanges' /></Button>
                                        </CardFooter>
                                    }
                                </OverlayLoader>
                            </Card>
                            {props.wizard &&
                                <div className="d-flex mb-5">
                                    <Button color="primary" outline type='button' onClick={props.previousStep} disabled={formik.isSubmitting}><Translate id='misc.previous' /></Button>
                                    <Button color="primary" className="ml-auto" type='submit' disabled={formik.isSubmitting}><Translate id='misc.next' /></Button>
                                </div>
                            }
                        </Form>
                    )
                }}
            </Formik>
        </Container>
    );
}

export default FormClinicCommunications;