import { FormikTextArea } from '@spordle/formik-elements';
import Translate, { DateFormat } from "@spordle/intl-elements";
import React from "react";
import {
    Col, Row
} from "reactstrap";
import { object, string } from 'yup';
import FormikEditable from '../../../../../../../components/formik/FormikEditable';
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../../../components/sidePanel/SidePanel";
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { MembersContext } from "../../../../../../../contexts/MembersContext";
// contexts
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import withContexts from '../../../../../../../helpers/withContexts';
import { RolesContext } from '../../../../../../../contexts/RolesContext';
import StatusBadge from '../../../../../../../components/badges/StatusBadge';
import DisplayOrganization from '../../../../../../../components/organization/DisplayOrganization';
import { fail, success } from '@spordle/toasts';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import { updateMemberStatusLogNote } from '../../../../../../../api/client/memberStatusLogs';


class MemberStatusLogSidepanel extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
        }
    }

    render(){
        const canEditNote = this.props.RolesContext.canDoAction('EDIT', 'members', 'member_status_log_note', true)
        const previousStatus = this.props.tableRef.getData()[this.props.tableRef.getData().findIndex((row) => row.member_status_log_id === this.props.selectedRows[0].member_status_log_id) - 1]?.member_status
        const canSeeNoteAndReason = (this.props.selectedRows[0].member_status?.code === 'INELIGIBLE' || previousStatus?.code === 'INELIGIBLE') ? this.props.RolesContext.hasAccessTo('members', 'manage_ineligible_members') : true;

        return (

            <OverlayLoader isLoading={this.state.isLoading}>
                <SidePanel.Header>
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                        <div>
                            <SidePanel.Title><Translate id='members.profile.status.logs.sidepanel.title' /></SidePanel.Title>
                            <div className='d-flex'>
                                <div>
                                    <StatusBadge
                                        color={previousStatus?.color_code}
                                        status={previousStatus}
                                    />
                                </div>
                                <i className='mdi mdi-arrow-right mx-2' />
                                <div>
                                    <StatusBadge
                                        color={this.props.selectedRows[0].member_status.color_code}
                                        status={this.props.selectedRows[0].member_status}
                                    />
                                </div>
                            </div>
                            <div className="font-medium text-dark">
                                <DateFormat value={this.props.selectedRows[0].created_at} format='YYYY-MM-DD' utc />
                            </div>
                        </div>
                    </div>
                    <div className="font-medium text-dark">
                        <DisplayOrganization organisation={{ organisation_id: this.props.selectedRows[0].organisation.organisation_id }}>
                            <DisplayI18n field='name' defaultValue={this.props.selectedRows[0].organisation?.name} i18n={this.props.selectedRows[0].organisation?.i18n} />
                        </DisplayOrganization>
                    </div>
                </SidePanel.Header>
                <div className="p-3">
                    <Row>
                        {this.props.selectedRows[0].created_by &&
                            <Col sm='12'>
                                <div className="text-muted"><Translate id='reports.column.header.created_by' /></div>
                                <div> {this.props.selectedRows[0].created_by?.name} {this.props.selectedRows[0].created_by?.family_name}</div>
                                <div><a href={`mailto:${this.props.selectedRows[0].created_by?.email}`}>{this.props.selectedRows[0].created_by?.email}</a></div>
                            </Col>
                        }
                        {canSeeNoteAndReason &&
                            <>
                                {this.props.selectedRows[0].member_status_reason &&
                                <Col sm='12'>
                                    <div className="text-muted"><Translate id='members.profile.statusChange.reason.short' /></div>
                                    <div className="font-medium text-dark">
                                        <DisplayI18n field='name' defaultValue={this.props.selectedRows[0].member_status_reason?.name} i18n={this.props.selectedRows[0].member_status_reason?.i18n} />
                                    </div>
                                </Col>
                                }
                                <Col sm="12">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='members.profile.suspensions.notes' /></div>
                                        <FormikEditable
                                            id='note'
                                            disabled={!canEditNote}
                                            initialValues={{
                                                note: this.props.selectedRows[0].note,
                                            }}
                                            validationSchema={object().shape({
                                                note: string(),
                                            })}
                                            onSubmit={(values) => {
                                                if(values.note !== this.props.selectedRows[0].note){
                                                    this.setState(() => ({ isLoading: true }))
                                                    const newValues = this.props.createNewValues({ note: values.note })
                                                    updateMemberStatusLogNote(this.props.selectedRows[0].member_status_log_id, { note: values.note })
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                        .finally(() => this.setState(() => ({ isLoading: false })))
                                                }
                                            }}
                                        >
                                            {(isEditing) => {
                                                if(!isEditing){
                                                    return (
                                                        <div className="font-medium text-dark">{this.props.selectedRows[0].note || '-'}</div>
                                                    )
                                                }
                                                return (
                                                    <FormikTextArea id='note' name='note' rows={3} trim />
                                                )

                                            }}
                                        </FormikEditable>
                                    </div>
                                </Col>
                            </>
                        }

                    </Row>
                </div>
            </OverlayLoader>
        )
    }
}

export default withContexts(MembersContext, I18nContext, RolesContext)(MemberStatusLogSidepanel);