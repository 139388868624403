import ReportsDashboard from '../../views/reports/reportsDashboard/ReportsDashboard';
import ReportsEngine from '../../views/reports/reportEngine/ReportsEngine';
import ReportExports from '../../views/reports/exports/ReportExports';
import InvalidReportId from '../../views/reports/reportEngine/InvalidReportId';

/*
 * @typedef {object} SubRoutes
 * @property {React.ReactNode} [name]
 * @property {boolean} [redirect]
 */
/** @type {Array.<import('react-router-dom').RouteProps & SubRoutes>} */
const reportsRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        title: 'sidebar.reports.library',
        name: 'sidebar.reports.library',
        navlabel: true,
    },
    {
        exact: true,
        path: '/dashboard',
        noBreadcrumb: true,
        name: 'sidebar.reports.dashboard',
        component: ReportsDashboard,
    },
    {
        exact: true,
        path: '/dashboard?category=exports',
        name: 'sidebar.reports.exports',
        permissions: { code: 'reports' },
        component: ReportExports,
    },
    {
        title: 'sidebar.reports',
        name: 'sidebar.reports',
        navlabel: true,
    },
    {
        exact: true,
        skipRecents: true,
        hidden: true,
        path: '/404',
        component: InvalidReportId,
    },
    {
        path: '/:reportPath',
        component: (props) => <ReportsEngine {...props} key={props.match.params.reportPath} />,
        hidden: true,
        exact: true,
    },
    {
        redirect: true,
        dynamicRedirect: true,
        from: '/reports',
    },
];
export default reportsRoutes;
