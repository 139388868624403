import React from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import Translate from '@spordle/intl-elements';

class ClinicProfileRegistrationAddExchangeWarning extends React.Component{

    render(){
        return (
            <>
                <ModalBody className='text-center'>
                    <i style={{ fontSize: 75 }} className='mdi mdi-alert-outline text-warning' />
                    <div className="h4"><Translate id='memberProfile.registration.exchange.warning.text' /></div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color='primary' type='button'
                        onClick={this.props.onSubmit}
                    ><Translate id='misc.next' />
                    </Button>
                    <Button color='primary' type='button' outline onClick={this.props.toggle}><Translate id='misc.cancel' /></Button>
                </ModalFooter>
            </>
        )
    }
}

export default ClinicProfileRegistrationAddExchangeWarning