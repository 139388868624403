import axios from 'axios';
import { activeCalls, activeReportCalls, activeMembersCalls, activeTeamsCalls, activeClinicsCalls, activeTransferCalls, activeDiscriminationCalls, activeInsuranceCalls, activeGIRCalls } from './CancellableAPI';
import queryString, { parseUrl } from 'query-string';
import createApiTesterUrl from '../helpers/createApiTester';
import { history } from '../helpers/history';
import getAccountUrl from '../helpers/getExternalUrl';
import { addBreadcrumb, captureException, Severity, withScope } from '@sentry/react';
import { fire } from '@spordle/toasts';
import { getVersionHeaders } from '../helpers/helper';
import { getSessionStorageItem, removeLocalStorageItem } from '../helpers/browserStorage';
import { getApiBaseUrlMultiSport } from '../helpers/getReferer';

const DEV_URL = "https://proxy.id.dev.spordle.dev/v1";
const INT_URL = "https://proxy.id.int.spordle.dev/v1";
const STAGE_URL = getApiBaseUrlMultiSport() || "https://proxy.id.stage.spordle.dev/v1";
const HCSTAGE_URL = "https://proxy.id.hcstage.spordle.dev/v1";
const UAT_URL = "https://proxy.id.uat.spordle.dev/v1";
const TRAINING_URL = "https://proxy.training.hcr.spordle.com/v1";
const PREPROD_URL = `https://proxy.preprod.hcr.spordle.com/v1`;
const PROD_URL = getApiBaseUrlMultiSport() || `https://proxy.hcr.spordle.com/v1`;
const LOCAL = `http://spordleapi.com/v1`;

const queryParams = queryString.parse(window.location.search);

export var currentMaintenanceUrl = '';

// Can be changed only in int
export var apiUrlEnv = process.env.REACT_APP_ENVIRONMENT;

if(process.env.REACT_APP_ENVIRONMENT === 'int' && queryParams.db)
    apiUrlEnv = queryParams.db;
else if(process.env.REACT_APP_ENVIRONMENT === 'dev' && process.env.REACT_APP_LOCAL_ENVIRONMENT == 'true')
    apiUrlEnv = 'local';

export var current_url = DEV_URL;
if(apiUrlEnv === 'int')
    current_url = INT_URL
else if(apiUrlEnv === 'stage')
    current_url = STAGE_URL;
else if(apiUrlEnv === 'hcstage')
    current_url = HCSTAGE_URL;
else if(apiUrlEnv === 'uat')
    current_url = UAT_URL;
else if(apiUrlEnv === 'training')
    current_url = TRAINING_URL;
else if(process.env.REACT_APP_ENVIRONMENT === 'preprod')
    current_url = PREPROD_URL;
else if(apiUrlEnv === 'prod')
    current_url = PROD_URL;
else if(apiUrlEnv === 'local')
    current_url = LOCAL;

const CancelToken = axios.CancelToken;
const API_SPORDLE = axios.create({
    baseURL: current_url,
    //withCredentials: true,
    headers: {
        common: {
            'X-Access-Token': queryParams.accessToken || getSessionStorageItem('demo-accessToken'),
            ...getVersionHeaders(),
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Language': 'fr;en', // All supported languages
        },
        patch: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        put: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    },
    validateStatus: (status) => {
        if([ 401, 403, 418, 503 ].indexOf(status) !== -1){
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            switch (status){
                case 418:// I'm a teapot
                    addBreadcrumb({
                        type: 'info',
                        category: "version",
                        message: "New version detected",
                    });
                    fire({ theme: 'warning', msg: 'warning.versionOverlapping', timeout: 60, horizontalLoad: true, id: "I'm a teapot", showCloseButton: false });
                case 503: // Maintenance
                    if(!currentMaintenanceUrl)
                        currentMaintenanceUrl = location.href;
                    history.push('/maintenance');
                    break;
                case 403: // Forbidden
                    withScope(function(scope){
                        scope.setTag('api-status', 403);
                        scope.setLevel(Severity.Warning);
                        captureException(new Error("403 -> Forbidden"));
                    });
                    fire({ theme: 'warning', msg: 'warning.403', id: '403' });
                    history.replace('/dashboard');
                    break;
                case 401: // Unauthorized -> AccessToken is invalid
                    removeLocalStorageItem('periodId');
                default:
                    addBreadcrumb({
                        type: 'navigation',
                        category: "navigation",
                        message: `Redirecting to ACCOUNT because of ${status}`,
                        data: {
                            from: window.location.href,
                            to: getAccountUrl('SPORDLE'),
                        },
                    });
                    window.location.href = getAccountUrl('SPORDLE');
                    break;
            }
        }
        return (status >= 200 && status < 300) || status === 400; // default
    },
});

API_SPORDLE.interceptors.request.use(function(config){
    if(config.method == "get" && config.url.indexOf("undefined") !== -1){
        withScope(function(scope){
            scope.setTag('get', 'undefined');
            scope.setLevel(Severity.Warning);
            captureException(new Error("GET - undefined parameter"));
        });
    }
    const pathUrl = parseUrl(config.url).url;
    if(/%/.test(pathUrl)){ // % means that there is a querystring in the url
        withScope(function(scope){
            scope.setExtra('query-url', pathUrl);
            scope.setLevel(Severity.Debug);
            captureException(new Error("Query string url"));
        });
    }
    config.cancelToken = new CancelToken(function executor(c){
        // An executor function receives a cancel function as a parameter

        const splitOnQuestionMark = config.url.split('?');
        const splitOnSlash = config.url.split('/');
        const splitOnQuestionThenSlash = splitOnQuestionMark[0].split('/');

        // check if the call is a call for a report, if it is, we cancel it when another report search is made
        // so we don't attempt to make multiple large report calls at once (ex if someone spam clicks search)
        if(splitOnSlash[1] === 'reports' && (config.method === 'get' || config.method === 'post')){
            activeReportCalls.push(c);
            // this one is for member search, be careful as this catches all calls starting with /members/
            // it works in the member search because we don't make any other calls that start with /members/,
            // however be VERY VERY careful if you try using this somewhere else, as you may cancel calls that you didn't intend on cancelling
        }else if(splitOnQuestionMark[0] === '/members' && config.method === 'get'){
            activeMembersCalls.push(c);
        }else if(splitOnQuestionMark[0] === '/teams' && config.method === 'get'){
            activeTeamsCalls.push(c);
        }else if(splitOnQuestionMark[0] === '/clinics' && config.method === 'get'){
            activeClinicsCalls.push(c);
        }else if(splitOnSlash[1] === 'organisations' && splitOnQuestionThenSlash[3] === 'transfers' && config.method === 'get'){
            activeTransferCalls.push(c);
        }else if(splitOnQuestionThenSlash[1] === 'organisations' && splitOnQuestionThenSlash[3] === 'discriminations' && config.method === 'get'){
            activeDiscriminationCalls.push(c);
        }else if(splitOnQuestionThenSlash[1] === 'injuries' && config.method === 'get'){
            activeInsuranceCalls.push(c);
        }else if(splitOnQuestionThenSlash[1] === 'organisations' && splitOnQuestionThenSlash[3] === 'game-incidents' && config.method === 'get'){
            activeGIRCalls.push(c);
        }else{
            activeCalls.push(c);
        }
    });
    return config;
});

API_SPORDLE.interceptors.response.use(null, (error) => {
    if((process.env.REACT_APP_ENVIRONMENT === 'int' || process.env.REACT_APP_ENVIRONMENT === 'dev' || process.env.REACT_APP_ENVIRONMENT === 'stage') && !axios.isCancel(error)){
        const jsonSpacing = 4;
        const parsedUrl = queryString.parseUrl(error.response.config.url);
        console.error(createApiTesterUrl(error.response.config.baseURL, error.response.config.method, parsedUrl.url, JSON.stringify(parsedUrl.query, undefined, jsonSpacing), JSON.stringify(error.response.config.headers, undefined, jsonSpacing), JSON.stringify(error.response.config.body, undefined, jsonSpacing)));
    }

    switch (error.response?.status){
        case 403:
            return Promise.reject(new Error("forbidden"));
        case 401:
            return Promise.reject(new Error("unauthorized"));
        case 418:
            return Promise.reject(new Error("teapot"));
        default:
            break;
    }

    return Promise.reject(error);
});

export default API_SPORDLE;