import { DisplayPhoneNumber } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState, useRef } from 'react';
import { Card, CardBody, ModalHeader } from "reactstrap";
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';
import { fail } from "@spordle/toasts";
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { PhoneTypesContext } from '../../../../../../contexts/PhoneTypesContext';
import { RolesContext } from '../../../../../../contexts/RolesContext';
import { TeamsContext } from '../../../../../../contexts/TeamsContext';
import { displayI18n, DisplayI18n } from '../../../../../../helpers/i18nHelper';
import TeamContactEdit from './TeamContactEdit';
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import { stringBuilder } from '@spordle/helpers';
import UserImg from '../../../../../../components/UserImg';
import SpordlePanelTable from '../../../../../../components/sidePanel/SpordlePanel';
import TeamContactSidepanel from './TeamContactSidepanel';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';

const TeamContact = ({ teamId }) => {
    const [ edit, setEdit ] = useState(false)
    const toggleEdit = () => setEdit(!edit)
    const [ teamContactTypes, setTeamContactTypes ] = useState(false);
    const [ phoneTypes, setPhoneTypes ] = useState(false);
    const [ currentEditingContact, setCurrentEditingContact ] = useState(null);

    const teamsContext = useContext(TeamsContext)
    const organizationContext = useContext(OrganizationContext);
    const phoneTypesContext = useContext(PhoneTypesContext);
    const roleContext = useContext(RolesContext);
    const { getGenericLocale } = useContext(I18nContext);

    const spordleTableRef = useRef()

    // to prevent making that call everytime the user toggles edit mode
    useEffect(() => {
        if(roleContext.canDoAction('READ', 'teams', 'team_contact_types')){
            teamsContext.getTeamContactTypes({ organisation_id: organizationContext.organisation_id })
                .then(setTeamContactTypes)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        setTeamContactTypes(null);
                    }
                })
        }else{
            setTeamContactTypes([])
        }

        phoneTypesContext.getPhoneTypes({ organisation_id: organizationContext.organisation_id })
            .then(setPhoneTypes)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    setPhoneTypes(null);
                }
            })
    }, [])

    return (
        <>
            <AnalyticsModal analyticsName='addTeamContact' isOpen={edit}>
                <ModalHeader toggle={toggleEdit}><Translate id='teams.profile.teamInfos.contact.title' /></ModalHeader>
                <TeamContactEdit onCancel={toggleEdit} onSubmit={() => { toggleEdit(); spordleTableRef.current?.tableRef?.refreshTable(); }} teamContactTypes={teamContactTypes} phoneTypes={phoneTypes} currentEditingContact={currentEditingContact} />
            </AnalyticsModal>
            <Card className="card-shadow">
                <OverlayLoader isLoading={spordleTableRef.current?.tableRef?.state?.loadingState === 'lazy'}>
                    <CardBody>
                        <CardSectionTitle title="teams.profile.teamInfos.contact.title" className="d-flex" />
                        <SpordlePanelTable
                            ref={spordleTableRef}
                            allowOutsideClick
                            sidePanel={(props) => <TeamContactSidepanel teamContactTypes={teamContactTypes} phoneTypes={phoneTypes} {...props} />}
                            table={(panelProps) => (
                                <SpordleTableProvider
                                    key={teamId}
                                    id='formMemberContact'
                                    tableHover clickable
                                    viewMode='GRID'
                                    dataIndex='team_contact_id_public'
                                    ref={panelProps.spordleTableRef}
                                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                    onColumnClick={(e, contact) => {
                                        switch (e.button){
                                            case 0: // Left mouse button
                                                if(contact.team_contact_id_public === 'template'){
                                                    setCurrentEditingContact(null);
                                                    toggleEdit();
                                                }else{
                                                    panelProps.onSingleSelectChange(contact);
                                                }
                                                break;
                                        }
                                    }}
                                    defaultSorting={'-lastUpdated'} // plug useSavedSearch
                                    columnSorting={(contactA, contactB, spordleTable) => {
                                        switch (spordleTable.state.orderingBy.column){
                                            case 'type':
                                                if(contactA.team_contact_id_public === 'template') // sort the add button last
                                                    return 1;
                                                if(contactB.team_contact_id_public === 'template')
                                                    return -1;
                                                const contactATranslated = displayI18n('name', contactA.team_contact_type?.i18n, contactA.team_contact_type?.name, getGenericLocale())
                                                const contactBTranslated = displayI18n('name', contactB.team_contact_type?.i18n, contactB.team_contact_type?.name, getGenericLocale())
                                                return new Intl.Collator(getGenericLocale(), { sensitivity: 'base' }).compare(contactATranslated, contactBTranslated) * (spordleTable.state.orderingBy.order === 'ASC' ? 1 : -1);
                                        }
                                    }}
                                    gridConfig={{
                                        col: {
                                            sm: 12,
                                            md: 6,
                                            lg: 4,
                                            className: 'mb-3 member-contacts-col',
                                        },
                                        gridRender: (contact) => {
                                            if(contact.team_contact_id_public === 'template'){
                                                return (
                                                    <CanDoAction className='ml-auto text-link h-100' action='ADD' componentCode='teams' componentPermissionCode='team_contacts'>
                                                        <UserDisplay card hover className={stringBuilder('w-100 mb-0 border-primary h-100', { 'shadow-danger': contact.errors })} style={{ minWidth: 'unset', borderStyle: 'dashed', borderWidth: '1px' }}>
                                                            <UserDisplay.Container>
                                                                <UserImg
                                                                    src={contact.logo || ''}
                                                                    width={50}
                                                                    alt={`${contact.first_name} ${contact.last_name}`}
                                                                    className="p-2"
                                                                />
                                                            </UserDisplay.Container>
                                                            <UserDisplay.Container>
                                                                <UserDisplay.Title tag="div" className='text-link'><i className='mdi mdi-plus' /> <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact' /></UserDisplay.Title>
                                                            </UserDisplay.Container>
                                                        </UserDisplay>
                                                    </CanDoAction>
                                                )
                                            }
                                            return (
                                                <UserDisplay card hover className={`w-100 mb-0 h-100`} style={{ minWidth: 'unset' }}>
                                                    <UserDisplay.Container>
                                                        <UserImg
                                                            abbr={`${contact.first_name.charAt(0)}${contact.last_name.charAt(0)}`}
                                                            src={contact.logo}
                                                            width={50}
                                                            alt={`${contact.first_name} ${contact.last_name}`}
                                                            className="p-2"
                                                        />
                                                        {/* <img className="object-fit-contain" src={contact.logo ?? noProfileLogo} width="40" height="40" alt={`${contact.first_name} ${contact.last_name}`}/> */}
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container className='min-w-0'>
                                                        <UserDisplay.Title className='text-truncate'>{contact.first_name} {contact.last_name}</UserDisplay.Title>
                                                        <UserDisplay.Subtitle className='text-truncate'><DisplayI18n field='name' defaultValue={contact.team_contact_type?.name} i18n={contact.team_contact_type?.i18n} /></UserDisplay.Subtitle>
                                                        <UserDisplay.Subtitle className='text-truncate'>{contact.email}</UserDisplay.Subtitle>
                                                        <UserDisplay.Subtitle>
                                                            <DisplayPhoneNumber phoneString={contact.phone} format='INTERNATIONAL' />
                                                        </UserDisplay.Subtitle>
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            )

                                        },
                                    }}
                                    desktopWhen='md'
                                    rowIsHighlighted={(contact) => !!contact.checked}
                                    loadData={(from, _, spordleTable) => {
                                        spordleTable.setLoading()
                                        switch (from){
                                            case 'CDM':
                                            case 'REFRESH':
                                                return teamsContext.getTeamContacts(teamId)
                                                    .then((contacts) => {
                                                        return [ ...contacts, { team_contact_id_public: 'template' } ]
                                                    })
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    <SpordleTableView />
                                </SpordleTableProvider>
                            )}
                        />
                    </CardBody>
                </OverlayLoader>
            </Card>
        </>
    );
}

export default TeamContact;