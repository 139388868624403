import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { Button, Col, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { object, string } from 'yup';
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { EMPLOYED_OPTIONS, useInjurySelect, YES_NO_DATA } from "../../InsuranceHelper";

const StepInsuranceInfo = ({ changeStep, injuryDataLists, previous, next, updateData }) => {
    const { payTos, provinces } = injuryDataLists;
    const { getGenericLocale } = useContext(I18nContext);
    const getSelectInjuryProps = useInjurySelect();

    return (
        <Formik
            initialValues={{
                employed: '',
                employer_name: '',
                injury_pay_to_id: '',
                injury_pay_to_other: '',
                provincial_coverage: '',
                submitted: '',
                health_insurance_province_id: '',
            }}
            validationSchema={object().shape({
                employed: string(),
                employer_name: string(),
                injury_pay_to_id: string(),
                injury_pay_to_other: string(), //.test(formikOtherTest('injury_pay_to_id', payTos_w_other)),
                provincial_coverage: string(),
                health_insurance_province_id: string(),
                submitted: string(),
            })}
            onSubmit={(values) => {
                const keptValues = { ...values };
                if(![ "EMPLOYED", "EMPLOYED_PART_TIME" ].includes(values.employed)){
                    delete keptValues.employer_name;
                }

                updateData('insurance', keptValues);
                next();
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <div className="font-bold h4">
                            <Translate id='insurance.label.insuranceInfo' />
                        </div>
                        <Row form>
                            <Col sm="6" className="form-group">
                                <Label for="employed" className="text-muted">
                                    <Translate id='insurance.label.employed' />
                                </Label>
                                <FormikSelect
                                    clearable
                                    name="employed"
                                    id="employed"
                                    search
                                    loadingStatus="success"
                                    onOptionSelected={([ value ]) => {
                                        if(![ "EMPLOYED", "EMPLOYED_PART_TIME" ].includes(value)){
                                            formik.setFieldValue('employer_name', "");
                                        }
                                    }}
                                    defaultData={EMPLOYED_OPTIONS.map((option) => ({
                                        label: 'insurance.label.employedOptions.' + option,
                                        value: option,
                                        translateLabel: true,
                                    }))}
                                />
                            </Col>
                            <Col sm="6" className="form-group">
                                <Label for="employer_name" className="text-muted">
                                    <Translate id='insurance.label.employer' />
                                </Label>
                                <FormikInputText trim disabled={![ "EMPLOYED", "EMPLOYED_PART_TIME" ].includes(formik.values.employed)} name="employer_name" id="employer_name" />
                            </Col>
                            <Col sm="6" className="form-group">
                                <Label for="provincial_coverage" className="text-muted">
                                    <Translate id='insurance.label.provincialCoverage' />
                                </Label>
                                <FormikSelect
                                    clearable
                                    search
                                    name="provincial_coverage"
                                    id="provincial_coverage"
                                    defaultData={YES_NO_DATA}
                                    loadingStatus="success"
                                />
                            </Col>
                            <Col sm="6" className="form-group">
                                <Label for="health_insurance_province_id" className="text-muted">
                                    <Translate id='insurance.label.healthProvince' />
                                </Label>
                                <FormikSelect
                                    clearable
                                    search
                                    name="health_insurance_province_id"
                                    id="health_insurance_province_id"
                                    renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    options={
                                        provinces ?
                                            provinces.map((p) => ({
                                                label: p.code,
                                                value: p.code,
                                                i18n: p.i18n,
                                            }))
                                            :
                                            []
                                    }
                                />
                            </Col>
                            <Col sm="6" className="form-group">
                                <Label for="other_insurance" className="text-muted">
                                    <Translate id='insurance.label.otherInsurance' />
                                </Label>
                                <FormikSelect
                                    clearable
                                    search
                                    name="other_insurance"
                                    id="other_insurance"
                                    defaultData={YES_NO_DATA}
                                    loadingStatus="success"
                                />
                            </Col>
                            <Col sm="6" className="form-group">
                                <Label for="submitted" className="text-muted">
                                    <Translate id='insurance.label.submitted' />
                                </Label>
                                <FormikSelect
                                    clearable
                                    search
                                    name="submitted"
                                    id="submitted"
                                    defaultData={YES_NO_DATA}
                                    loadingStatus="success"
                                />
                            </Col>
                            <Col sm="6" className="form-group">
                                <Label for="injury_pay_to_id" className="text-muted"><Translate id='insurance.label.payTo' /></Label>
                                <FormikSelect
                                    clearable
                                    {...getSelectInjuryProps("injury_pay_to_id", payTos)}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="button" onClick={previous} outline className="mr-auto"><Translate id="misc.previous" /></Button>
                        <Button color="primary" type="submit"><Translate id="misc.next" /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

export default StepInsuranceInfo;