import { useDisclosure } from "@mantine/hooks";
import Translate from "@spordle/intl-elements";
import RecipientsModal from "./recipientsModal/RecipientsModal";

const Recipients = ({ message }) => {
    const [ modalIsOpen, modalIsOpenHandlers ] = useDisclosure(false);
    const sentToEmailsCount = message.email_to.split(',')?.length;

    return (
        <div className="small font-medium d-flex w-100 min-w-0 align-items-center">
            <RecipientsModal
                message={message}
                isOpen={modalIsOpen}
                toggle={modalIsOpenHandlers.close}
            />
            <div className="d-flex min-w-0">
                <span className="text-muted font-normal"><Translate id="communications.inbox.to" />:</span><span className="ml-1 min-w-0 w-100 text-truncate">{message.email_to}</span>
            </div>
            {sentToEmailsCount > 1 &&
                <span className="text-nowrap ml-2">
                    ({sentToEmailsCount})
                </span>
            }
            <button type="button" onClick={modalIsOpenHandlers.open} className="reset-btn ml-2 clickable d-inline-block mdi mdi-download bg-light text-dark p-1 rounded-circle line-height-1" />
        </div>
    );
}

export default Recipients;