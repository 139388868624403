import editModal from "./editModal";
import submitTicket from "./submitTicket";
import userGuide from "./userGuide";

export default{
    ...submitTicket,
    ...userGuide,
    ...editModal,

    "helpCenter.resources"    : "Ressources",
    "helpCenter.title"        : "Centre d'aide",
    "helpCenter.userGuide"    : "Guide Utilisateur",
    "helpCenter.submitTicket" : "Demande de soutien",
    "helpCenter.chat"         : "Chat",
    "helpCenter.chat.offline" : "Hors ligne",
}