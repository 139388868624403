import SpordleTableProvider from "@spordle/datatables";
import { useContext } from "react";
import { IdentityRolesContext } from "../../contexts/IdentityRolesContext";
import { MessagesContext } from "../../contexts/MessagesContext";
import { InboxContext } from "./components/inboxContext/InboxContext";
import SpordleListView from "./components/inboxTableComponents/SpordleListView";
import { useCheckMsg } from "./components/useCheckMsg";
import { PAGINATION } from "./helpers/communicationsHelper";

const SentMessages = () => {
    const { getSentMessages } = useContext(MessagesContext);
    const { identity } = useContext(IdentityRolesContext);
    const checkMsgHandlers = useCheckMsg();
    const { selectMsg } = useContext(InboxContext);

    const handleOnSelectMsg = (msg) => {
        return () => {
            selectMsg({
                ...msg,
                hideTools: true,
                sent_by: identity,
            });
        }
    }

    const handleLoadData = (from, filterData, spordleTable) => {
        const currentPage = filterData.filters.currentPage;
        const pagin = spordleTable.getPagination();
        const offset = (currentPage - 1) * pagin;

        switch (from){
            case 'REFRESH':
            case 'FILTER':
                if(spordleTable.getData().length === 0){
                    spordleTable.setLoading();
                }
            case 'CDM':
                return getSentMessages({
                    identity_id: identity.identity_id,
                    offset: offset,
                    sort: "-created_at",
                    limit: pagin,
                })
                    .then(({ total, communication_messages }) => {
                        spordleTable.filterChange('totalResults', total, false);

                        return communication_messages || [];
                    })
                    .catch((e) => {
                        console.error(e);
                        return [];
                    })
            default:
                break;
        }
    }

    return (
        <SpordleTableProvider
            id="communicationsSentMessages"
            dataIndex="message_id"
            loadData={handleLoadData}
            initFilter={{
                currentPage: 1,
                totalResults: null,
            }}
            pagination={PAGINATION}
            paginationMessage={() => null}
            viewMode='GRID'
        >
            <SpordleListView
                hideTools
                handleOnSelectMsg={handleOnSelectMsg}
                {...checkMsgHandlers}
            />
        </SpordleTableProvider>
    );
}

export default SentMessages;