const IncompleteProfile = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 12"
            width={width}
            height={height}
        >
            <path
                style={{ fillRule: "evenodd" }}
                className="fill-current"
                d="M6,6c1.66,0,3-1.34,3-3S7.66,0,6,0,3,1.34,3,3s1.34,3,3,3Zm0,1.5c-2,0-6,1-6,3v.75c0,.41,.34,.75,.75,.75H11.25c.41,0,.75-.34,.75-.75v-.75c0-1.99-4-3-6-3Z"
            />
        </svg>
    );
}

export default IncompleteProfile;