export default{
    "contexts.membersContext.3160" : "This medical information already exists for this member",
    "contexts.membersContext.3269" : "This postal code is not part of the organization",
    "contexts.membersContext.3332" : "Invalid file extension",
    "contexts.membersContext.3179" : "Forbidden access",
    "contexts.membersContext.3191" : "Insufficient permissions",
    "contexts.membersContext.3331" : "File size exceeds the limit",
    "contexts.membersContext.2000" : "Invalid parameters",
    "contexts.membersContext.1000" : "Missing parameters",
    "contexts.membersContext.3229" : "Organization doesn't match",
    "contexts.membersContext.3060" : "Resource could not be found",
    "contexts.membersContext.3045" : "Members to merge must be different members",
}