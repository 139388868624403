const IncompleteAddress = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 13.53 11.48"
            width={width}
            height={height}
        >
            <path
                className="fill-current"
                d="M13.53,4.77c0,.18-.14,.32-.32,.32h-1.81v6.07c0,.18-.14,.32-.32,.32h-2.87s-.08-.04-.08-.08v-3.49c0-.15-.12-.26-.26-.26h-2.17c-.15,0-.26,.12-.26,.26v3.49s-.04,.08-.08,.08H2.45c-.18,0-.32-.14-.32-.32V5.09H.32c-.18,0-.32-.14-.32-.32,0-.1,.05-.2,.14-.26L6.43,.11c.2-.14,.47-.14,.67,0l6.3,4.4c.08,.06,.13,.16,.13,.26Z"
            />
        </svg>
    );
}

export default IncompleteAddress;