import Translate from "@spordle/intl-elements";
import { useState } from "react";
import CanDoAction from "../../permissions/CanDoAction";
import HasAccessTo from "../../permissions/HasAccessTo";
import InvoiceEditModal from "./InvoiceEditModal";

/**
 * Button that toggles the edit invoice modal.
 * Is a component to prevent rerender of the whole invoice, which made weird visual glitches.
 *
 * @param {Object} props
 * @param {object} props.invoice
 * @param {function} props.getInvoice
 * @returns {JSX.Element}
 */
const InvoiceEditModalToggleBtn = ({ invoice, getInvoice }) => {
    const [ changeAddressModalIsOpen, setChangeAddressModalIsOpen ] = useState(false);
    const toggleChangeAddressModal = () => setChangeAddressModalIsOpen(!changeAddressModalIsOpen);

    return (
        <HasAccessTo componentCode='members' componentPermissionCode='members_address' className='d-print-none'>
            <CanDoAction action='EDIT' componentCode='members' componentPermissionCode='member_transactions'>
                <span className='mx-2'>|</span>
                <button type='button' className='reset-btn text-primary' onClick={() => setChangeAddressModalIsOpen(true)}>
                    <Translate id='misc.edit' />
                </button>
                <InvoiceEditModal
                    isOpen={changeAddressModalIsOpen}
                    toggle={toggleChangeAddressModal}
                    invoice={invoice}
                    getInvoice={getInvoice}
                />
            </CanDoAction>
        </HasAccessTo>
    );
}

export default InvoiceEditModalToggleBtn;