export default{
    "catalog.clinicItems.add.title"            : "Ajouter un article",
    "catalog.clinicItems.add.form.name"        : "Nom",
    "catalog.clinicItems.add.form.description" : "Description",
    "catalog.clinicItems.add.form.price"       : "Prix",
    "catalog.clinicItems.add.form.taxable"     : "Taxable",
    "catalog.clinicItems.add.form.sku"         : "SKU",

    "catalog.clinicItems.add.form.validation.name.required"  : "Le nom est obligatoire",
    "catalog.clinicItems.add.form.validation.amout.required" : "Le prix est obligatoire",

    "catalog.clinicItems.add.form.error.3045" : "Impossible d'appliquer les taxes lorsque l'organisation n'a pas de province.",
}