import React, { useContext, useState } from "react";
import {
    Row,
    Col,
    Badge,
    CardBody,
    Card,
    Collapse,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import moment from 'moment';
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import OnlineStoreTransactionRefundModal from "./modals/refundModal/OnlineStoreTransactionRefundModal";
import { withRouter, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { stringBuilder } from '@spordle/helpers';
import { FormattedPlural } from "react-intl";
import OnlineStoreTransactionPaymentReceptionModal from "./modals/OnlineStoreTransactionPaymentReceptionModal";
import MemberProfileItemCancel from "../../../../members/profile/registration/components/CancelItems/MemberProfileItemCancel";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import EditInstallmentModal from "../../../../../components/transactions/EditInstallmentModal";

// contexts
import { I18nContext } from '../../../../../contexts/I18nContext';
import { OnlineStoreContext } from '../../../../../contexts/OnlineStoreContext';
import withContexts from '../../../../../helpers/withContexts';
import { MembersContext } from "../../../../../contexts/MembersContext";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { fail, success } from '@spordle/toasts';
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import TransactionRegistration from "../../../../../components/transactions/TransactionRegistration";
import EditPaymentReceptionModal from "../../../../../components/transactions/EditPaymentReceptionModal";
import { WatermarkWrapper } from "../../../../../components/Watermark";
import CreditInvoiceModal from "../../../../../components/CreditInvoiceModal";
import generatePassword from "../../../../../helpers/passwordGenerator";
import { CartsContext } from "../../../../../contexts/CartsContext";
import { Tooltip } from "@mantine/core";
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy";

class OnlineStoreTransactionsSidepanelInvoice extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            refundModalOpen: false,
            //onlyCancelInstallments: false,
            cancelModalOpen: false,
            paymentReceptionModalOpen: false,

            mode: '',

            editInstallmentModalIsOpen: false,
            invoicePayment: null,
            invoiceItemId: '',
            field: '',

            currentPaymentReceptionId: '',
            editPaymentReceptionModalOpen: false,

            isVoid: props.selectedRows[0].status === 'VOID',

            creditInvoiceModalIsOpen: false,
            refundAmounts: false,

            memberFunding: [],
            memberFundingLoading: false,
        }

        this.modes = {
            refundToCancel: 'refundToCancel',
            cancelToRefund: 'cancelToRefund',
        }
    }

    componentDidMount(){
        if(this.props.selectedRows[0].invoice_number){
            this.getRefundAmounts();
            this.getFundingAmounts(this.props.selectedRows[0]);
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0].status !== this.props.selectedRows[0].status){
            this.setState(() => ({
                isVoid: this.props.selectedRows[0].status === 'VOID',
            }))

            if(this.props.selectedRows[0].invoice_number){
                this.getRefundAmounts();
            }
        }
    }

    refreshTable = () => {
        this.props.tableRef.refreshTable();
        this.props.forceCloseSidePanel();
    }

    toggleRefundModal = () => this.setState((prevState) => ({ refundModalOpen: !prevState.refundModalOpen }))

    togglePaymentReceptionModal = () => this.setState((prevState) => ({ paymentReceptionModalOpen: !prevState.paymentReceptionModalOpen }))

    getFundingAmounts = (invoice) => {
        if(invoice.status === 'COMPLETED'){
            const members = [];
            invoice.invoice_items.forEach((item) => {
                if(!members.find((m) => m.member_id === item.member.member_id))
                    members.push(item.member)
            })

            if(members.length > 0){
                this.setState(() => ({ memberFundingLoading: true }))
                Promise.all(members.map((m) => {
                    return this.props.MembersContext.getAvailableToBeFunded(invoice.invoice_number, { memberId: m.member_id })
                        .then((fundingAmount) => fundingAmount || 0)
                }))
                    .then((funding) => {
                        this.setState(() => ({
                            memberFunding: funding.map((fundVal, index) => {
                                return ({
                                    fundingValue: fundVal,
                                    label: `${members[index].first_name} ${members[index].last_name}`,
                                    value: generatePassword(),
                                    memberId: members[index].member_id,
                                });
                            }),
                        }))
                        this.setState(() => ({ memberFundingLoading: false }))
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            this.setState(() => ({ memberFundingLoading: false }))
                        }
                    })
            }
        }
    }

    getRefundAmounts = () => {
        this.setState(() => ({ refundAmounts: false }));
        this.props.MembersContext.getRefundAmounts(this.props.selectedRows[0].invoice_number)
            .then((refundAmounts) => {
                this.setState(() => ({ refundAmounts: refundAmounts }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    // onCancelInstallments = () => {
    //     this.props.toggle();
    //     this.props.tableRef.refreshTable();
    // }

    onCancelRegistration = () => {
        this.setState(() => ({ cancelModalOpen: true }));
    }

    onPaymentReception = (paymentReceptionId, values) => {
        this.refreshTable();
        // this.props.syncRows(this.props.createNewValues({
        //     payment_receptions:[
        //         ...this.props.selectedRows[0].payment_receptions,
        //         {
        //             payment_reception_id: paymentReceptionId,
        //             payment_method: values.payment_method,
        //             member_credit_id: values.member_credit_id,
        //             amount: Math.round(values.amount * 100).toString(),
        //             received_at: values.received_at.toISOString(),
        //             note: values.note
        //         }
        //     ]
        // }));
    }

    onDeletePaymentReception = () => {
        this.props.toggle();
        this.props.tableRef.refreshTable();
    }

    onDeleteRefund = () => {
        this.props.toggle();
        this.props.tableRef.refreshTable();
    }

    sortPaymentReception = (paymentA, paymentB) => {
        return new Intl.Collator(this.props.I18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(paymentA.received_at, paymentB.received_at) ||
               new Intl.Collator(this.props.I18nContext.getGenericLocale(), { numeric: true }).compare(paymentA.amount, paymentB.amount)
    }

    handleResendInvoice = (withReceipt = false) => {
        this.props.MembersContext.resendInvoice([ this.props.selectedRows[0].invoice_number ], withReceipt)
            .then(() => {
                success();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    if(error.i18n){
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }else{
                        fail({ msg: "members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice.error" });
                    }
                }
            })
    }

    toggleEditPaymentModal = (paymentReceptionId) => {
        this.setState((prevState) => ({
            currentPaymentReceptionId: paymentReceptionId,
            editPaymentReceptionModalOpen: !prevState.editPaymentReceptionModalOpen,
        }))
    }

    toggleEditModal = () => {
        this.setState((prevState) => ({
            editInstallmentModalIsOpen: !prevState.editInstallmentModalIsOpen,
        }))
    }

    // this works, the data is changed to completed
    onEditInstallment = (values, paymentId, invoiceItemId) => {
        this.props.syncRows(this.props.createNewValues({
            registrations: [
                ...this.props.selectedRows[0].registrations.reduce((newArray, invoiceItem) => {
                    if(invoiceItem.invoice_item_id === invoiceItemId){
                        newArray.push({
                            ...invoiceItem,
                            payments_to_display: invoiceItem.payments_to_display.reduce((newArrayPayments, payment) => {
                                if(payment.invoice_payment_id === paymentId){
                                    newArrayPayments.push({
                                        ...payment,
                                        ...values,
                                    })
                                }else{
                                    newArrayPayments.push(payment)
                                }
                                return newArrayPayments
                            }, []),

                            payments: invoiceItem.payments.reduce((newArrayPayments, payment) => {
                                if(payment.invoice_payment_id === paymentId){
                                    newArrayPayments.push({
                                        ...payment,
                                        ...values,
                                    })
                                }else{
                                    newArrayPayments.push(payment)
                                }
                                return newArrayPayments
                            }, []),
                        })
                    }else{
                        newArray.push(invoiceItem)
                    }
                    return newArray
                }, []),
            ],
        }))
    }

    toggleCreditInvoiceModal = () => {
        this.setState((prevState) => ({
            creditInvoiceModalIsOpen: !prevState.creditInvoiceModalIsOpen,
        }))
    }

    onCreditInvoice = () => {
        this.props.tableRef.refreshTable();
    }

    render(){
        const totalCreditAmount = this.props.selectedRows?.[0] ? parseInt(this.props.selectedRows[0].credits.reduce((sum, credit) => sum += parseInt(credit.amount), 0)) : 0;

        return (
            <>
                {this.state.editPaymentReceptionModalOpen &&
                    <EditPaymentReceptionModal
                        isOpen={this.state.editPaymentReceptionModalOpen}
                        toggle={this.toggleEditPaymentModal}
                        payment={// finds the correct payment reception object from the paymentReceptionId set in the toggle function
                            this.props.selectedRows[0].payment_receptions.find(
                                (paymentReception) => paymentReception.payment_reception_id === this.state.currentPaymentReceptionId,
                            )
                        }
                        invoiceNumber={this.props.selectedRows[0].invoice_number}
                        paymentReceptionId={this.state.currentPaymentReceptionId}
                        onEdit={(values) => {
                            const targetedReception = this.props.selectedRows[0].payment_receptions.findIndex(
                                (paymentReception) => paymentReception.payment_reception_id === this.state.currentPaymentReceptionId,
                            )

                            const newReceptions = this.props.selectedRows[0].payment_receptions;
                            newReceptions[targetedReception].note = values.note;
                            newReceptions[targetedReception].received_at = values.received_at;

                            const newValues = this.props.createNewValues({ payment_receptions: newReceptions });
                            this.props.syncRows(newValues);
                        }}
                    />
                }

                <EditInstallmentModal
                    isOpen={this.state.editInstallmentModalIsOpen}
                    toggle={this.toggleEditModal}
                    toggleSidepanel={this.props.toggle}
                    invoicePayment={this.state.invoicePayment}
                    invoiceItemId={this.state.invoiceItemId}
                    onEditInstallment={this.onEditInstallment}
                    field={this.state.field}
                />

                <CreditInvoiceModal
                    isOpen={this.state.creditInvoiceModalIsOpen}
                    toggle={() => {
                        this.toggleCreditInvoiceModal();
                        this.props.toggle();
                    }}
                    invoiceNumber={this.props.selectedRows[0].invoice_number}
                    onCreditInvoice={this.onCreditInvoice}
                />

                <WatermarkWrapper
                    showWatermark={this.state.isVoid}
                    watermarkProps={{
                        text: 'members.profile.transactions.memberProfileTransactions.sidePanel.status.VOID',
                    }}
                >
                    <SidePanel.Header>
                        {(!this.props.hideHeader || !this.props.hideHeader) &&
                            <div className='d-flex mb-2'>
                                {this.state.paymentReceptionModalOpen &&
                                    <OnlineStoreTransactionPaymentReceptionModal
                                        {...this.props}
                                        onPaymentReception={this.onPaymentReception}
                                        transaction={this.props.selectedRows[0]}
                                        isOpen={this.state.paymentReceptionModalOpen}
                                        toggle={this.togglePaymentReceptionModal}
                                        memberFunding={this.state.memberFunding}
                                        memberFundingLoading={this.state.memberFundingLoading}
                                        getFundingAmounts={() => this.getFundingAmounts(this.props.selectedRows[0])}
                                    />
                                }

                                {/* REFUND THEN CANCEL WORKFLOW MODALS */}
                                {this.state.refundModalOpen &&
                                    <OnlineStoreTransactionRefundModal
                                        {...this.props}
                                        chainWithCancel={this.state.mode === this.modes.refundToCancel}
                                        // onRefund={() => this.refreshTable()}
                                        // onCancelInstallments={this.onCancelInstallments}
                                        // onlyCancelInstallments={this.state.onlyCancelInstallments}
                                        toggle={() => {
                                            //this.setState(() => ({ onlyCancelInstallments: false }))
                                            this.toggleRefundModal();
                                            this.props.tableRef.refreshTable();
                                            this.props.toggle();
                                        }}
                                        refreshTable={() => { this.props.tableRef.refreshTable(); this.props.getRegistrationFeesAndOtherFees(); }}
                                        isOpen={this.state.refundModalOpen}
                                        transaction={this.props.selectedRows[0]}
                                        onCancelRegistration={() => {
                                            this.toggleRefundModal();
                                            //this.setState(() => ({ onlyCancelInstallments: false }))
                                            this.onCancelRegistration();
                                        }}

                                        // Will popup the cancel modal if user has permissions
                                        cancelPermissions={{ action: "EDIT", componentCode: "page", componentPermissionCode: "online_store_manage" }}
                                    />
                                }
                                <MemberProfileItemCancel
                                    chainWithRefund={this.state.mode === this.modes.cancelToRefund}
                                    availableRefund={this.state.refundAmounts}
                                    invoiceItems={this.props.selectedRows[0].invoice_items.map((item) => ({
                                        ...item,
                                        registration_fee: item.registration_fee,
                                        other_fee: item.other_fee,
                                    }))}
                                    isOpen={this.state.cancelModalOpen}
                                    onCancel={() => {
                                        this.props.tableRef.refreshTable
                                    }}
                                    toggleRefund={() => this.toggleRefundModal()}

                                    // Refresh because when is open, it means transaction
                                    // was refunded, therefor toggle will refresh the table
                                    refreshTable={() => {
                                        this.props.tableRef.refreshTable();
                                        this.props.getRegistrationFeesAndOtherFees()
                                        this.props.toggle();
                                    }}
                                    toggle={() => this.setState(() => ({ cancelModalOpen: false }))}
                                />
                                {/* END REFUND THEN CANCEL WORKFLOW MODALS */}

                                <SidePanel.ToggleButton />
                                <SidePanel.ActionsMenu componentCode='members' componentPermissionCode='member_transactions' action='EDIT'>

                                    {/* VIEW INVOICE  */}
                                    <SidePanel.MenuAction onClick={() => this.props.history.push(`invoice?invoiceNumber=${this.props.selectedRows[0].invoice_number}`)}>
                                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.viewInvoice' />
                                    </SidePanel.MenuAction>

                                    {this.props.selectedRows[0].need_confirmation == 1 &&
                                        <ConfirmModal
                                            color='primary'
                                            icon='mdi-check-circle-outline'
                                            translateActionButtonText
                                            actionButtonText='memberProfile.registration.confirmRegistration.confirm.btn'
                                            onConfirm={async() => {
                                                await this.props.CartsContext.confirmInvoicePayment(this.props.selectedRows[0].invoice_number)
                                                    .then(() => {
                                                        this.props.tableRef.refreshTable();
                                                        this.props.toggle();
                                                        success({ msg: 'memberProfile.registration.sidepanel.confirmPayment.success' });
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            if(error.i18n){
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }else{
                                                                fail({ msg: 'memberProfile.registration.sidepanel.confirmPayment.error' });
                                                            }
                                                        }
                                                    })
                                            }}
                                            modalTitle={<Translate id='memberProfile.registration.sidepanel.confirmPayment.confirm' />}
                                            toggler={(fn) => (
                                                <SidePanel.MenuAction onClick={fn}>
                                                    <Translate id='memberProfile.registration.sidepanel.confirmPayment' />
                                                </SidePanel.MenuAction>
                                            )}
                                        />
                                    }

                                    {/* PAYMENT RECEPTION */}
                                    {(this.props.selectedRows[0].invoice_items.find((item) => item.payments_to_display?.find((payment) => payment.payment_method?.code !== 'CREDITCARD')) || this.state.memberFunding?.some((funding) => funding.fundingValue > 0)) && // only show for manual payment methods or funding
                                        <SidePanel.MenuAction onClick={this.togglePaymentReceptionModal} disabled={this.state.isVoid || (this.props.selectedRows[0].due <= 0 && this.state.memberFunding.every((fund) => fund.fundingValue == 0))}>
                                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.paymentReception' />
                                        </SidePanel.MenuAction>
                                    }

                                    <SidePanel.MenuAction divider />

                                    {/* CANCEL ITEMS  */}
                                    <SidePanel.MenuAction
                                        onClick={() => {
                                            this.setState(() => ({ cancelModalOpen: true, mode: this.modes.cancelToRefund }))
                                        }}
                                        disabled={this.props.selectedRows[0].invoice_items.map((item) => ({
                                            ...item,
                                            registration_fee: item.registration_fee,
                                            other_fee: item.other_fee,
                                        })).every((item) => item.cancelled_at) || this.state.isVoid}
                                    >
                                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelItems' />
                                    </SidePanel.MenuAction>

                                    {/* REFUND  */}
                                    <SidePanel.MenuAction
                                        onClick={() => {
                                            this.setState(() => ({ mode: this.modes.refundToCancel }))
                                            this.toggleRefundModal()
                                        }}
                                        disabled={parseInt(this.state.refundAmounts.payment_gateway) <= 0 && parseInt(this.state.refundAmounts.manual) <= 0 || this.state.isVoid}
                                    >
                                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.refund' />
                                        {this.props.selectedRows[0].invoice_payment_method?.code !== 'CREDITCARD' && this.props.selectedRows[0].status !== 'COMPLETED' &&
                                            <div className='small'>
                                                <div><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.refund.disabled.1' /></div>
                                                <div><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.refund.disabled.2' /></div>
                                            </div>
                                        }
                                    </SidePanel.MenuAction>

                                    {/* CANCEL INSTALLMENTS */}
                                    {/* {hasInstallments &&
                                        <SidePanel.MenuAction
                                            onClick={() => {
                                                this.setState(() => ({ onlyCancelInstallments: true }))
                                                this.toggleRefundModal();
                                            }}
                                        >
                                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelInstallments'/>
                                            {this.props.selectedRows[0].invoice_payment_method !== 'CREDITCARD' &&
                                                <div className='small'>
                                                    <div><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelInstallments.disabled.1'/></div>
                                                    <div><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelInstallments.disabled.2'/></div>
                                                </div>
                                            }
                                        </SidePanel.MenuAction>
                                    } */}

                                    {/* VOID INVOICE */}
                                    <ConfirmModal
                                        modalContent={
                                            <>
                                                <div className='font-bold h4'>
                                                    <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.void.title' />
                                                </div>
                                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.void.text' />
                                            </>
                                        }
                                        actionButtonText='misc.confirm'
                                        translateActionButtonText
                                        onConfirm={async() => {
                                            return this.props.MembersContext.voidInvoice(this.props.selectedRows[0].invoice_number)
                                                .then(() => {
                                                    this.props.tableRef.refreshTable();
                                                    this.props.toggle();
                                                    success();
                                                })
                                                .catch((e) => {
                                                    if(!AxiosIsCancelled(e.message)){
                                                        console.error(e.message)
                                                        fail({
                                                            msg: <Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.actions.void.errors.' + e.message} defaultMessageId='misc.error' />,
                                                            skipMsgTranslate: true,
                                                        });
                                                    }
                                                })
                                        }}
                                        toggler={(fn) => (
                                            <SidePanel.MenuAction
                                                onClick={fn}
                                                disabled={parseInt(this.props.selectedRows[0].paid) !== 0 || this.state.isVoid}
                                                action='ADD'
                                                componentCode='members'
                                                componentPermissionCode='member_invoice_void'
                                            >
                                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.void' />
                                            </SidePanel.MenuAction>
                                        )
                                        }
                                    />

                                    {/* CREDIT INVOICE */}
                                    <SidePanel.MenuAction
                                        onClick={this.toggleCreditInvoiceModal}
                                        disabled={this.state.isVoid || this.props.selectedRows[0].due == 0}
                                    >
                                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.credit' />
                                    </SidePanel.MenuAction>

                                    <SidePanel.MenuAction divider />

                                    {/* RESEND INVOICE */}
                                    <SidePanel.MenuAction onClick={() => this.handleResendInvoice(false)} disabled={this.state.isVoid}>
                                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice' />
                                    </SidePanel.MenuAction>

                                    {/* RESEND INVOICE */}
                                    <SidePanel.MenuAction onClick={() => this.handleResendInvoice(true)} disabled={this.state.isVoid}>
                                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoiceWithReceipt' />
                                    </SidePanel.MenuAction>

                                </SidePanel.ActionsMenu>
                            </div>
                        }
                        <div className="d-flex">
                            <SidePanel.Title className="mr-1">
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.invoice' />
                            </SidePanel.Title>
                            <Badge color={(this.props.selectedRows[0].status === 'COMPLETED') ? 'success' : (this.props.selectedRows[0].status === 'PENDING' || this.props.selectedRows[0].status === 'NEED_REVIEW' || this.props.selectedRows[0].status === 'PARTIALLY_PAID') ? 'warning' : 'danger'} size="lg" pill className="px-3 align-self-start">
                                <Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.status.' + this.props.selectedRows[0].status} />
                            </Badge>
                        </div>
                        <InlineCopy tag='div' toCopy={this.props.selectedRows[0].invoice_number}>
                            <Link to={`invoice?invoiceNumber=${this.props.selectedRows[0].invoice_number}`}>#{this.props.selectedRows[0].invoice_number}</Link>
                        </InlineCopy>

                        <div className="text-muted">
                            {(this.props.selectedRows[0].identity?.identity_id && this.props.selectedRows[0].identity) &&
                                <div className="mr-1 d-inline-block"><Translate id='members.profile.transactions.memberProfileTransactions.paidBy' /> {this.props.selectedRows[0].identity.name + ' ' + this.props.selectedRows[0].identity.family_name}</div>
                            }
                            (<DisplayI18n field='name' defaultValue={this.props.selectedRows[0].invoice_payment_method?.name} i18n={this.props.selectedRows[0].invoice_payment_method?.i18n} />)
                        </div>
                        {/* Show who initiated a pre-init */}
                        {this.props.selectedRows[0].is_pre_init == 1 &&
                            <div>
                                <Tooltip
                                    zIndex={3000}
                                    withArrow label={
                                        <>
                                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_init.tooltip' />
                                            <span className='ml-1'>{this.props.selectedRows[0].initiated_by?.name} {this.props.selectedRows[0].initiated_by?.family_name}:</span>
                                            <span className='ml-1'><DateFormat value={this.props.selectedRows[0].created_at} /></span>
                                        </>
                                    }
                                >

                                    <div className="text-muted">
                                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_init' />
                                        <i className='mdi mdi-information-outline text-primary ml-1' />
                                    </div>
                                </Tooltip>
                            </div>
                        }
                        {/* Show who initiated a pre-authorized  */}
                        {this.props.selectedRows[0].need_confirmation == 1 &&
                            <div>
                                <Tooltip
                                    zIndex={3000}
                                    withArrow label={
                                        <>
                                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_auth.tooltip' />
                                            <span className='ml-1'>{this.props.selectedRows[0].initiated_by?.name} {this.props.selectedRows[0].initiated_by?.family_name}:</span>
                                            <span className='ml-1'><DateFormat value={this.props.selectedRows[0].created_at} /></span>
                                        </>
                                    }
                                >

                                    <div className="text-muted">
                                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_auth' />
                                        <i className='mdi mdi-information-outline text-primary ml-1' />
                                    </div>
                                </Tooltip>
                            </div>
                        }
                        <div className="text-muted">
                            <div>
                                <Translate id={`members.profile.transactions.memberProfileTransactions.sidePanel.header.transactionType.${this.props.selectedRows[0].origin == 'ADMIN' ? 'manual' : 'online'}`} />
                            </div>
                            <div>
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.initialPaymentDate' />: {moment(this.props.selectedRows[0].invoice_date).format('YYYY-MM-DD')}
                            </div>
                        </div>
                    </SidePanel.Header>
                    <div className="p-3">
                        {/* Total / Due */}
                        <div className="mb-3">
                            <Row form>
                                <Col xs="6">
                                    <Card className="card-shadow text-center mb-0">
                                        <CardBody>
                                            <div className="h4 mb-0 font-bold"><CurrencyFormat value={this.props.selectedRows[0].total / 100} /></div>
                                            <div className="small"><Translate id='members.profile.transactions.memberProfileTransactions.total' /></div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xs="6">
                                    <Card className="card-shadow text-center mb-0">
                                        <CardBody>
                                            <div className="h4 mb-0 font-bold"><CurrencyFormat value={this.props.selectedRows[0].due / 100} /></div>
                                            <div className="small"><Translate id='members.profile.transactions.memberProfileTransactions.due' /></div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                        {/* Rceived payments */}
                        {(this.props.selectedRows[0].payment_receptions && this.props.selectedRows[0].payment_receptions.length > 0) &&
                            <div className='mb-3'>
                                <div className="h4 font-bold">
                                    <FormattedPlural
                                        value={this.props.selectedRows[0].payment_receptions.length}
                                        one={<Translate id='members.profile.transactions.memberProfileTransactions.paymentReception' />}
                                        other={<Translate id='members.profile.transactions.memberProfileTransactions.paymentReceptions' />}
                                    />
                                </div>
                                <Card className='card-shadow mb-3 p-3'>
                                    {this.props.selectedRows[0].payment_receptions.sort(this.sortPaymentReception).map((paymentReception) => (
                                        <PaymentReceptionItem
                                            key={paymentReception.payment_reception_id}
                                            paymentReception={paymentReception}
                                            invoiceNumber={this.props.selectedRows[0].invoice_number}
                                            onDeletePaymentReception={this.onDeletePaymentReception}
                                            toggleEdit={this.toggleEditPaymentModal}
                                            disableActions={this.state.isVoid}
                                        />
                                    ))}
                                    {/* to be aligned perfectly we would need to add a border-right because the payment reception cards have p-2 and border */}
                                    {/* but that would mean adding a border-right and styling the border so it is white or transparent :( */}
                                    {/* my OCD is going nuts, but it do be that way sometimes */}
                                    <div className='text-right pr-2'>
                                        <span className='font-bold text-dark'>
                                            <Translate id='members.profile.transactions.memberProfileTransactions.total' />
                                            <span className='mx-1'>:</span>
                                        </span>
                                        <CurrencyFormat value={this.props.selectedRows[0].payment_receptions.reduce((totalAmount, payment) => totalAmount + parseInt(payment.amount), 0) / 100} />
                                    </div>
                                </Card>
                            </div>
                        }

                        {/* Refunds */}
                        {(this.props.selectedRows[0].refunds && this.props.selectedRows[0].refunds.length > 0) &&
                            <div className='mb-3'>
                                <div className="h4 font-bold">
                                    <FormattedPlural
                                        value={this.props.selectedRows[0].refunds.length}
                                        one={<Translate id='members.profile.transactions.memberProfileTransactions.refund' />}
                                        other={<Translate id='members.profile.transactions.memberProfileTransactions.refunds' />}
                                    />
                                </div>
                                <Card className='card-shadow mb-3 p-3'>
                                    {this.props.selectedRows[0].refunds.map((refund) => (
                                        <RefundItem
                                            key={refund.invoice_payment_id}
                                            refund={refund}
                                            invoiceNumber={this.props.selectedRows[0].invoice_number}
                                            onDeleteRefund={this.onDeleteRefund}
                                            disableActions={this.state.isVoid}
                                        />
                                    ))}
                                    <div className='text-right pr-2'>
                                        <span className='font-bold text-dark'>
                                            <Translate id='members.profile.transactions.memberProfileTransactions.total' />
                                            <span className='mx-1'>:</span>
                                        </span>
                                        <CurrencyFormat value={this.props.selectedRows[0].total_refunded_amount / 100} />
                                    </div>
                                </Card>
                            </div>
                        }

                        {/* Credits */}
                        {(this.props.selectedRows[0].credits && this.props.selectedRows[0].credits.length > 0) &&
                            <div className='mb-3'>
                                <div className="h4 font-bold"><Translate id='members.profile.transactions.memberProfileTransactions.credit' /></div>
                                <Card className='card-shadow mb-3 p-3'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='h6 mb-0 font-bold'><Translate id='members.profile.transactions.memberProfileTransactions.appliedCredit' /></div>
                                        <div className="ml-3 font-medium text-success"><CurrencyFormat value={parseInt(totalCreditAmount) / 100} /></div>
                                    </div>
                                </Card>
                            </div>
                        }
                        {/* REGISTRATIONS */}
                        {this.props.selectedRows[0].registrations && this.props.selectedRows[0].registrations.length > 0 &&
                            <div className='mb-3'>
                                <div className="h4 font-bold"><Translate id='members.profile.transactions.memberProfileTransactions.registrations' /></div>
                                {this.props.selectedRows[0].registrations.map((item) => (
                                    <TransactionRegistration
                                        {...this.props}
                                        key={item.invoice_item_id}
                                        registration={{ ...item, registration_fee: item.registration_fee }}
                                        disableActions={this.state.isVoid}
                                    />
                                ))}
                            </div>
                        }

                        {/* ITEMS */}
                        {(this.props.selectedRows[0].otherItems && this.props.selectedRows[0].otherItems.length > 0) &&
                            <div className='mb-3'>
                                <div className="h4 font-bold"><Translate id='members.profile.transactions.memberProfileTransactions.items' /></div>
                                {this.props.selectedRows[0].otherItems.map((item) => {
                                    return (
                                        <Card className={stringBuilder('card-shadow mb-3', { 'bg-light-inverse': item.cancelled_at })} key={item.invoice_item_id}>
                                            <CardBody>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='text-truncate'>
                                                        <div className='h6 mb-0 text-truncate'>
                                                            <span className='font-bold'>
                                                                <DisplayI18n
                                                                    field='name'
                                                                    i18n={item.other_fee?.fee.i18n}
                                                                    defaultValue={item.other_fee?.fee.name}
                                                                />
                                                            </span>
                                                        </div>
                                                        {item.cancelled_at &&
                                                            <div className='small text-danger'><Translate id='memberProfile.registration.sidepanel.item.cancelled' /></div>
                                                        }
                                                    </div>
                                                    <div className='ml-3'><CurrencyFormat value={parseInt(item.new_amount) / 100} /></div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </WatermarkWrapper>
            </>
        )
    }
}

const RefundItem = ({ refund, invoiceNumber, onDeleteRefund, disableActions }) => {
    const [ noteIsOpen, setNoteOpen ] = useState(false);
    const membersContext = useContext(MembersContext);

    return (
        <>
            <Card className='border p-2 mb-2 card-shadow'>
                <div onClick={refund.internal_note ? () => { setNoteOpen(!noteIsOpen) } : undefined} className={stringBuilder({ 'clickable': !!refund.internal_note })}>
                    <div className='d-flex justify-content-between'>
                        <DateFormat value={refund.refund_date} />
                        <UncontrolledDropdown inNavbar>
                            <DropdownToggle tag='button' className='reset-btn text-link' onClick={(e) => e.stopPropagation()} disabled={disableActions || refund.refund_method?.code === "CREDITCARD"}><i className='mdi mdi-dots-horizontal font-20' /></DropdownToggle>
                            <DropdownMenu right>
                                <ConfirmModal
                                    toggler={(toggle) => (
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggle();
                                            }}
                                        >
                                            <Translate id='misc.delete' />
                                        </DropdownItem>
                                    )}
                                    modalTitle={<Translate id='members.profile.transactions.memberProfileTransactions.refunds.deleteText' values={{ date: <DateFormat value={refund.refund_date} /> }} />}
                                    actionButtonText='misc.confirm'
                                    translateActionButtonText
                                    color='danger'
                                    onConfirm={() => {
                                        return membersContext.deleteRefund(invoiceNumber, refund.invoice_payment_id)
                                            .then(() => {
                                                success();
                                                onDeleteRefund();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message);
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    }}
                                />
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <div className='d-flex justify-content-between mb-1'>
                        <Badge color={refund.status === 'COMPLETED' ? 'success' : (refund.status === 'PENDING' || refund.status === 'NEED_REVIEW' || refund.status === 'PARTIALLY_PAID') ? 'warning' : 'danger'} size="lg" pill>
                            <Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.status.' + refund.status} />
                        </Badge>
                        <div>
                            <DisplayI18n field='name' defaultValue={refund.refund_method?.name} i18n={refund.refund_method?.i18n} />
                        </div>
                        <CurrencyFormat value={refund.amount / 100} />
                    </div>
                    {refund.internal_note &&
                        <>
                            <Collapse isOpen={noteIsOpen}>
                                <div className='bg-light p-1 rounded'>
                                    {refund.internal_note}
                                </div>
                            </Collapse>
                            <div className='d-flex justify-content-center mb-n3 mt-n2'>
                                <i className='font-20 mdi mdi-chevron-down d-block' style={{ transition: 'transform 0.15s ease-in-out', transform: `rotate(${noteIsOpen ? '180' : '0'}deg)` }} />
                            </div>
                        </>
                    }
                </div>
            </Card>
        </>
    )
}

const PaymentReceptionItem = ({ paymentReception, invoiceNumber, onDeletePaymentReception, toggleEdit }) => {
    const [ noteIsOpen, setNoteOpen ] = useState(false);
    const membersContext = useContext(MembersContext);
    const history = useHistory();

    return (
        <Card className='border p-2 mb-2 card-shadow'>
            <div onClick={paymentReception.note ? () => { setNoteOpen(!noteIsOpen) } : undefined} className={stringBuilder({ 'clickable': !!paymentReception.note })}>
                <div className='d-flex justify-content-between'>
                    <DateFormat value={paymentReception.received_at} utc />
                    <UncontrolledDropdown inNavbar>
                        <DropdownToggle tag='button' className='reset-btn text-link' onClick={(e) => e.stopPropagation()}><i className='mdi mdi-dots-horizontal font-20' /></DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={() => history.push('receipt?invoiceNumber=' + invoiceNumber + '&paymentReceptionId=' + paymentReception.payment_reception_id)}>
                                <Translate id='members.profile.transactions.memberProfileTransactions.paymentReceptions.viewReceipt' />
                            </DropdownItem>
                            <DropdownItem onClick={() => toggleEdit(paymentReception.payment_reception_id)}>
                                <Translate id='misc.edit' />
                            </DropdownItem>
                            <ConfirmModal
                                toggler={(toggle) => (
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggle();
                                        }}
                                    ><Translate id='misc.delete' />
                                    </DropdownItem>
                                )}
                                modalTitle={<Translate id='members.profile.transactions.memberProfileTransactions.paymentReceptions.deleteText' values={{ date: <DateFormat value={paymentReception.received_at} utc /> }} />}
                                actionButtonText='misc.confirm'
                                translateActionButtonText
                                color='warning'
                                onConfirm={() => {
                                    return membersContext.deletePaymentReception(invoiceNumber, paymentReception.payment_reception_id)
                                        .then(() => {
                                            success();
                                            onDeletePaymentReception();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                }}
                            />
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <div className='d-flex justify-content-between'>
                    <div>
                        {paymentReception.payment_method ?
                            <DisplayI18n field='name' defaultValue={paymentReception.payment_method.name} i18n={paymentReception.payment_method.i18n} />
                            :
                            <Translate id={`members.profile.transactions.memberProfileTransactions.paymentMethods.MEMBER_CREDIT`} />
                        }
                    </div>
                    <CurrencyFormat value={paymentReception.amount / 100} />
                </div>
                {paymentReception.note &&
                    <>
                        <Collapse isOpen={noteIsOpen}>
                            <div className='bg-light p-1 rounded'>
                                {paymentReception.note}
                            </div>
                        </Collapse>
                        <div className='d-flex justify-content-center mb-n3 mt-n2'>
                            <i className='font-20 mdi mdi-chevron-down d-block' style={{ transition: 'transform 0.15s ease-in-out', transform: `rotate(${noteIsOpen ? '180' : '0'}deg)` }} />
                        </div>
                    </>
                }
            </div>
        </Card>
    )
}

export default withRouter(withContexts(I18nContext, OnlineStoreContext, MembersContext, CartsContext)(OnlineStoreTransactionsSidepanelInvoice));