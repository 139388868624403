import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

/** @type {React.Context<Omit<InstallmentModesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & InstallmentModesContextProvider['state']>} */
export const InstallmentModesContext = createContext();
InstallmentModesContext.displayName = 'InstallmentModesContext';

class InstallmentModesContextProvider extends React.Component{

    /**
     * Get installment modes by organisation id
     * @param {string} organisation_id
     * @param {object} [queryParams] The query params for that call
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Installment%20Modes/Apicontroller%5CFinances%5CInstallmentmodes%3A%3Aindex|documentation}
     * @returns {Promise.<Array>}
     */
    getInstallmentModes = (organisation_id, queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/installment-modes`,
            query: Object.assign({
                organisation_id: organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.installment_modes;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a specific installment modes
     * @param {string} installment_mode_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Installment%20Modes/Apicontroller%5CFinances%5CInstallmentmodes%3A%3AgetInstallmentModesDetail|documentation}
     * @returns {Promise.<Array>}
     */
    getInstallmentMode = (installment_mode_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/installment-modes/${installment_mode_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.installment_modes[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates an installment mode
     * @param {array} values
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Installment%20Modes/Apicontroller%5CFinances%5CInstallmentmodes%3A%3AcreateInstallmentModes|documentation}
     * @returns {Promise}
     */
    createInstallmentMode = (values) => {
        const params = new URLSearchParams();

        for(const key in values){
            switch (key){
                case 'organisation_id':
                    params.append('organisation_id', values.organisation_id);
                    break;
                case 'period_id':
                    params.append('period_id', values.period_id);
                    break;
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'initial_installment_percentage':
                    params.append('initial_installment_percentage', values.initial_installment_percentage);
                    break;
                case 'number_of_payment':
                    params.append('number_of_payment', values.number_of_payment);
                    break;
                case 'installment_options': // API sometimes must receive an array to create sub ressources
                    if(Array.isArray(values[key])){
                        values[key].forEach((installment, index) => {
                            params.append(`installment_options[${index}][installment_option_percentage]`, installment.installment_option_percentage);
                            params.append(`installment_options[${index}][installment_option_percentage_date]`, installment.installment_option_percentage_date);
                        });
                    }
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/installment-modes` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.installment_mode_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Update an installment mode
    * @param {string} installment_mode_id
    * @param {object} values Object containing the values to update
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Installment%20Modes/Apicontroller%5CFinances%5CInstallmentmodes%3A%3AupdateInstallmentModes|documentation}
    * @returns {Promise}
    */
    updateInstallmentMode = (installment_mode_id, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'initial_installment_percentage':
                    params.append('initial_installment_percentage', values.initial_installment_percentage);
                    break;
                case 'number_of_payment':
                    params.append('number_of_payment', values.number_of_payment);
                    break;
                case 'installment_options':
                    if(Array.isArray(values[key])){
                        values[key].forEach((installment, index) => {
                            params.append(`installment_options[${index}][installment_option_percentage]`, installment.installment_option_percentage);
                            params.append(`installment_options[${index}][installment_option_percentage_date]`, installment.installment_option_percentage_date);
                        });
                    }
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `installment-modes/${installment_mode_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Patch an installment mode
    * @param {string} installment_mode_id
    * @param {object} values Object containing the values to update
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Installment%20Modes/Apicontroller%5CFinances%5CInstallmentmodes%3A%3ApatchInstallmentModes|documentation}
    * @returns {Promise}
    */
    updateInstallmentModePartial = (installment_mode_id, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'initial_installment_percentage':
                    params.append('initial_installment_percentage', values.initial_installment_percentage);
                    break;
                case 'installment_options':
                    if(Array.isArray(values[key])){
                        values[key].forEach((installment, index) => {
                            params.append(`installment_options[${index}][installment_option_percentage]`, installment.installment_option_percentage);
                            params.append(`installment_options[${index}][installment_option_percentage_date]`, installment.installment_option_percentage_date);
                        });
                    }
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `installment-modes/${installment_mode_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
    * Delete an Installment Mode and its options
    * @param {string} installment_mode_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Installment%20Modes/Apicontroller%5CFinances%5CInstallmentmodes%3A%3AdeleteInstallmentModes|documentation}
    * @returns {Promise}
    */
    deleteInstallmentMode = (installment_mode_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `installment-modes/${installment_mode_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET - Get the fees that the installment is linked to
     * @param {string} installmentModeId ID of the installment to get the links
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/index.php#/Installment%20Modes/325445ff78e1f2b3e878f595d8624539|documentation}
     * @returns {Promise}
     */
    getInstallmentLinks = (installmentModeId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/installment-modes/${installmentModeId}/fees`,
        }))
            .then((response) => {
                if(response.data.status){
                    return {
                        registration_fees: response.data.registration_fees,
                        other_fees: response.data.other_fees,
                        clinic_fees: response.data.clinic_fees,
                    }
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * PUT - Unlinks all fees from installment
     * @param {string} installmentModeId Id of the installment to unlink fees from
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/index.php#/Installment%20Modes/845a22f58f48077c75f3140d4ba458a5|documentation}
     * @returns {Promise}
     */
    unlinkAllInstallmentLinks = (installmentModeId) => {
        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/installment-modes/${installmentModeId}/unlink-fees`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <InstallmentModesContext.Provider value={{ ...this, ...this.state }}>
                {this.props.children}
            </InstallmentModesContext.Provider>
        )
    }
}

export default InstallmentModesContextProvider