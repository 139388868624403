import React from "react";
import {
    Card,
    CardBody
} from "reactstrap";
import FormClinicCommunications from './FormClinicCommunications';
import Translate from "@spordle/intl-elements";
import { ClinicsContext } from '../../../../contexts/ClinicsContext';
import withContexts from '../../../../helpers/withContexts';
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import FileUpload from "../../../../components/uploader/fileUpload/FileUpload";
import { fail } from "@spordle/toasts";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";

class ClinicCommunications extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            edit: false,
        };
    }

    toggleEdit = () => { this.setState((prevState) => ({ edit: !prevState.edit })) }

    isLoading = () => {
        return !(this.props.ClinicsContext.currentClinic?.custom_form)
        //&& this.props.ClinicsContext.currentClinic?.message_after_registration && this.props.ClinicsContext.currentClinic?.message_confirmation_email
    }

    handleFileClick = (file) => {
        return this.props.ClinicsContext.downloadMessageAttachments(file.messageId, file.message_attachment_id)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    };

    render(){
        return (
            <>
                {!this.state.edit ?
                    <Card className="card-shadow">
                        <OverlayLoader isLoading={this.isLoading()}>
                            <CardBody>
                                <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                                    <div className="card-title font-bold h4 mb-0"><Translate id='clinics.clinicCommunications.clinicCommunications.title' /></div>
                                    <CanDoAction className='ml-auto text-link' action='EDIT' componentCode='clinics' componentPermissionCode='clinics_manage'>
                                        <button className="reset-btn" type="button" onClick={this.toggleEdit}><Translate id='misc.edit' /></button>
                                    </CanDoAction>
                                </div>
                                <div className="mb-3">
                                    <div className="h5 font-bold"><Translate id='clinics.clinicCommunications.formClinicCommunications.terms.title' /></div>
                                    <div>{this.props.ClinicsContext.currentClinic?.term_and_condition?.term_and_condition_id ? <DisplayI18n field='title' i18n={this.props.ClinicsContext.currentClinic.term_and_condition.i18n} defaultValue={this.props.ClinicsContext.currentClinic.term_and_condition.title} /> : <Translate id='clinics.clinicCommunications.clinicCommunications.terms.noTerms' />}</div>
                                </div>
                                <div className="mb-3">
                                    <div className="h5 font-bold"><Translate id='clinics.clinicCommunications.clinicCommunications.forms.title' /></div>
                                    <div>{((this.props.ClinicsContext.currentClinic?.custom_form || [])[0]?.name) ? (this.props.ClinicsContext.currentClinic?.custom_form || [])[0]?.name : <Translate id='clinics.clinicCommunications.clinicCommunications.forms.noForms' />}</div>
                                </div>
                                <div className="mb-3">
                                    <div className="h5 font-bold"><Translate id='clinics.clinicCommunications.formClinicCommunications.waivers.title' /></div>
                                    {(this.props.ClinicsContext.currentClinic?.waivers?.length ?? 0) !== 0 ?
                                        <ul className='list-inside pl-3'>
                                            {this.props.ClinicsContext.currentClinic.waivers.map((waiver) => (
                                                <li key={waiver.waiver_id}><DisplayI18n field='name' defaultValue={waiver.name} i18n={waiver.i18n} /></li>
                                            ))}
                                        </ul>
                                        :
                                        <Translate id='clinics.clinicCommunications.clinicCommunications.waivers.noWaivers' />
                                    }
                                </div>
                                <div className="mb-3">
                                    <div className="h5 font-bold"><Translate id='clinics.clinicCommunications.clinicCommunications.registration.title' /></div>
                                    {this.props.ClinicsContext.currentClinic?.message_after_registration ?
                                        <div className="mb-2 font-medium border rounded p-2" dangerouslySetInnerHTML={{ __html: this.props.ClinicsContext.currentClinic?.message_after_registration.message }} />
                                        :
                                        <Translate id='clinics.clinicCommunications.clinicCommunications.waivers.noConfirmation' />
                                    }
                                    {this.props.ClinicsContext.currentClinic?.message_after_registration?.attachments?.length > 0 &&
                                        <FileUpload
                                            disabled
                                            defaultFiles={this.props.ClinicsContext.currentClinic?.message_after_registration.attachments.map((file) => ({
                                                id: file.attachement_id,
                                                name: file.file_name,
                                                url_path: file.full_path,
                                                type: file.file_ext,
                                                canRemove: false, // Future update
                                                messageId: this.props.ClinicsContext.currentClinic?.message_after_registration.message_id,
                                                ...file,
                                            }))}
                                            dropzoneProps={{
                                                multiple: true,
                                                accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                            }}
                                            onFileClick={this.handleFileClick} // what happens when user clicks on a single file
                                            preventRemoveExistant
                                        />
                                    }
                                </div>
                                <div className="mb-3">
                                    <div className="h5 font-bold "><Translate id='clinics.clinicCommunications.clinicCommunications.confirmation.title' /></div>
                                    {this.props.ClinicsContext.currentClinic?.message_confirmation_email ?
                                        <div className="mb-2 font-medium border rounded p-2" dangerouslySetInnerHTML={{ __html: this.props.ClinicsContext.currentClinic?.message_confirmation_email.message }} />
                                        :
                                        <Translate id='clinics.clinicCommunications.clinicCommunications.waivers.noConfirmationEmail' />
                                    }
                                    {this.props.ClinicsContext.currentClinic?.message_confirmation_email?.attachments?.length > 0 &&
                                        <FileUpload
                                            disabled
                                            defaultFiles={this.props.ClinicsContext.currentClinic?.message_confirmation_email.attachments.map((file) => ({
                                                id: file.attachement_id,
                                                name: file.file_name,
                                                url_path: file.full_path,
                                                type: file.file_ext,
                                                canRemove: false, // Future update
                                                messageId: this.props.ClinicsContext.currentClinic?.message_confirmation_email.message_id,
                                                ...file,
                                            }))}
                                            dropzoneProps={{
                                                multiple: true,
                                                accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                            }}
                                            onFileClick={this.handleFileClick} // what happens when user clicks on a single file
                                            preventRemoveExistant
                                        />
                                    }
                                </div>
                                {/* <div>
                                    <button className="mr-3 reset-btn text-link"><i className="fas fa-file-pdf mr-2"></i>file-123.pdf</button>
                                </div> */}
                            </CardBody>
                        </OverlayLoader>
                    </Card>
                    :
                    <FormClinicCommunications exited={this.toggleEdit} />
                }
            </>
        )
    }
}

export default withContexts(ClinicsContext)(ClinicCommunications);