import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { FormGroup } from "reactstrap";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import AddComplaineeModal from '../modals/AddComplaineeModal';
import ComplaineeMember from "./ComplaineeMember";

const ComplainedAgainst = ({
    complainees,
    complaint,
    handleCatch,
    setIsLoading,
    isAgainstTeam,
    readOnly,
    syncNewVal,
    refreshData,
}) => {
    const { deleteDiscriminationMember } = useContext(OrganizationContext);
    const [ addIsOpen, setAddIsOpen ] = useState(false);

    /**
     * @description Deletes a discrimination member from the discrimination claim
     * @param {string} id Discrimination member to delete
     */
    const handleDeleteComplainee = (id) => {
        setIsLoading(true);
        deleteDiscriminationMember(complaint.discrimination_id, id)
            .then(() => {
                syncNewVal({ discrimination_member: complaint.discrimination_member.filter((m) => m.discrimination_member_id !== id) });
            })
            .catch(handleCatch);
    }

    return (
        <>
            <FormGroup>
                <div className="h5 font-bold my-3 d-flex align-items-center justify-content-between">
                    {isAgainstTeam ?
                        <Translate
                            id='discrimination.sidePanel.people.complaineeTeam'
                        />
                        :
                        <Translate
                            id='discrimination.sidePanel.people.complainees'
                            values={{ count: complaint.discrimination_member.length }}
                        />
                    }
                    {/* {!isAgainstTeam && !readOnly &&
                        <CanDoAction action="ADD" componentCode="organization" componentPermissionCode="discrimination_claims">
                            <small onClick={() => setAddIsOpen(true)} className="ml-1 clickable text-link">
                                <i className="mdi mdi-plus" /> <Translate id="misc.add" />
                            </small>
                        </CanDoAction>
                    } */}
                </div>
                {(complainees || []).map((member) => (
                    <ComplaineeMember
                        isTeam={isAgainstTeam}
                        key={member.discrimination_member_id}
                        handleDelete={handleDeleteComplainee}
                        refreshTable={refreshData}
                        complaint={complaint}
                        member={member}
                        readOnly={readOnly}
                    />
                ))}
            </FormGroup>
            <AddComplaineeModal
                isOpen={addIsOpen}
                toggle={() => setAddIsOpen(false)}
                complaint={complaint}
                forceRefresh={refreshData}
            />
        </>
    );
}

export default ComplainedAgainst;