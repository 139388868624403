import { FormikSelect } from '@spordle/formik-elements';
import { useContext } from 'react';
import useSWR from 'swr';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { getGenericLocale } from '../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { TransferContext } from '../../../../../contexts/TransferContext';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import FormikEditable from '../../../../formik/FormikEditable';
import { fail } from '@spordle/toasts';
import { SpordletablePanelContext } from '../../../SpordlePanel';

const TransferReasonType = ({ canEditTransfer, transfer, setLoading, updateData }) => {
    const memberContext = useContext(MembersContext);
    const transferContext = useContext(TransferContext);
    const spordletablePanelContext = useContext(SpordletablePanelContext);

    const { data: reasonTypes, isValidating } = useSWR(
        [ 'getActiveReasonTypes' ],
        () => transferContext.getTransferReasons({ active: 1, organisation_id: transfer.organisation.organisation_id, to_organisation_id: transfer.target_organisation.organisation_id })
            .then((transferReasons) => transferReasons.sort((reasonA, reasonB) => parseInt(reasonA.display_order) - parseInt(reasonB.display_order)))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        },
    );

    const { data: USAreasonTypes, isValidatingUSA } = useSWR(
        [ 'getActiveUSAReasonTypes' ],
        () => transferContext.getUSATransferTypes(transfer.organisation.organisation_id, { active: 1 })
            .then((transferReasons) => {
                return transferReasons.sort((reasonA, reasonB) => parseInt(reasonA.display_order) - parseInt(reasonB.display_order))
            }),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        },
    );

    return (
        <FormikEditable
            id='transfer-reason-type'
            noConfirmation
            disabled={!canEditTransfer && !isValidating && !isValidatingUSA && Array.isArray(reasonTypes)}
            readOnly={transfer.transfer_request_state === 'CLOSED'}
            initialValues={{
                reasonTypeId: transfer.transfer_type == 'USA' ?
                    transfer.usa_transfer_type?.usa_transfer_type_id || ''
                    :
                    transfer.transfer_reason_type?.transfer_reason_type_id || '',
            }}
            onSubmit={(values) => {
                const canUpdate = values.reasonTypeId != (transfer.transfer_type == 'USA' ? (transfer.usa_transfer_type?.usa_transfer_type_id || '') : (transfer.transfer_reason_type?.transfer_reason_type_id || ''))

                if(canUpdate){
                    setLoading(true);
                    return memberContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, transfer.transfer_type == 'USA' ?
                        {
                            usa_transfer_type_id: values.reasonTypeId,
                        }
                        :
                        {
                            transfer_reason_type_id: values.reasonTypeId,
                        })
                        .then(async() => {
                            if(transfer.transfer_type == 'USA'){
                                const reasonType = USAreasonTypes.find((reasonType) => reasonType.usa_transfer_type_id === values.reasonTypeId)
                                await Promise.all([
                                    spordletablePanelContext.tableRef.updateDataWith(transfer.member_transfer_id, {
                                        usa_transfer_type: reasonType,
                                    }),
                                    updateData({ // Updating data without having to make the api call
                                        ...transfer,
                                        usa_transfer_type: reasonType,
                                    }, false),
                                ])
                            }else{
                                const reasonType = reasonTypes.find((reasonType) => reasonType.transfer_reason_type_id === values.reasonTypeId)
                                await Promise.all([
                                    spordletablePanelContext.tableRef.updateDataWith(transfer.member_transfer_id, {
                                        transfer_reason_type: reasonType,
                                    }),
                                    updateData({ // Updating data without having to make the api call
                                        ...transfer,
                                        transfer_reason_type: reasonType,
                                    }, false),
                                ])
                            }

                            setLoading(false);
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                setLoading(false);
                            }
                        })
                }
            }}
        >
            {(isEditing, options) => {
                if(!isEditing){
                    return (
                        transfer.transfer_type == 'USA' ?
                            transfer.usa_transfer_type ?
                                <DisplayI18n
                                    field='name'
                                    defaultValue={transfer.usa_transfer_type.name}
                                    i18n={transfer.usa_transfer_type.i18n}
                                />
                                :
                                '-'
                            :
                            transfer.transfer_reason_type ?
                                <DisplayI18n
                                    field='name'
                                    defaultValue={transfer.transfer_reason_type.name}
                                    i18n={transfer.transfer_reason_type.i18n}
                                />
                                :
                                '-'
                    );
                }
                return (
                    <FormikSelect
                        name='reasonTypeId'
                        id='reasonTypeId'
                        clearable
                        renderOption={({ option: transferStatus }) => <DisplayI18n field='name' defaultValue={transferStatus.label} i18n={transferStatus.i18n} />}
                        onOptionSelected={() => {
                            options.stopEditing();
                        }}
                        searchKeys={[
                            `i18n.${getGenericLocale()}.name`,
                        ]}
                        menuIsDefaultOpen autoFocus
                        options={transfer.transfer_type == 'USA' ?
                            USAreasonTypes.map((type) => ({
                                value: type.usa_transfer_type_id,
                                label: type.name,
                                i18n: type.i18n,
                            }))
                            :
                            reasonTypes.map((type) => ({
                                value: type.transfer_reason_type_id,
                                label: type.name,
                                i18n: type.i18n,
                            }))
                        }
                    />
                )

            }}
        </FormikEditable>
    )
}

export default TransferReasonType
