export default{
    "supportRequests.search.title"           : "Mes demandes de soutien",
    "supportRequests.search.filter.priority" : "Filtrer par priorité",

    "supportRequests.search.table.idSearch"     : "Recherche par référence",
    "supportRequests.search.table.nameSearch"   : "Recherche par sujet",
    "supportRequests.search.table.onlyMyIssues" : "Afficher seulement mes demandes",
    "supportRequests.search.table.priority"     : "Priorité",
    "supportRequests.search.table.reference"    : "Référence",
    "supportRequests.search.table.subject"      : "Sujet",

    "supportRequests.search.table.priority.placeholder" : "Filtrer par priorité",
    "supportRequests.search.table.status.placeholder"   : "Filtrer par statut",
    "supportRequests.search.table.priority.low"         : "Bas",
    "supportRequests.search.table.priority.medium"      : "Médium",
    "supportRequests.search.table.priority.high"        : "Élevé",

    "supportRequests.search.table.status.2" : "À faire",
    "supportRequests.search.table.status.4" : "En cours",
    "supportRequests.search.table.status.3" : "Terminé",
}