export default{
    "onlineStore.profile.overview.tabs.overview"     : "Overview",
    "onlineStore.profile.overview.tabs.items"        : "Items & Fees",
    "onlineStore.profile.overview.tabs.transaction"  : "Transactions",
    "onlineStore.profile.overview.tabs.registration" : "Registrations",
    "onlineStore.profile.overview.tabs.otherItems"   : "Other items",
    "onlineStore.profile.overview.tabs.invoice"      : "Invoice",
    "onlineStore.profile.overview.tabs.receipt"      : "Receipt",

    "onlineStore.profile.overview.tooltip.status"   : "Store's status",
    "onlineStore.profile.overview.tooltip.open"     : "Open store",
    "onlineStore.profile.overview.tooltip.copyUrl"  : "Copy the store url",
    "onlineStore.profile.overview.tooltip.settings" : "Advanced settings",
    "onlineStore.profile.overview.tooltip.share"    : "Share online store",

    "onlineStore.profile.overview.singular.shared"                           : "shared organization",
    "onlineStore.profile.overview.plural.shared"                             : "shared organizations",
    "onlineStore.profile.button.share"                                       : "Share",
    "onlineStore.profile.share.modal.title"                                  : "Share Online Store",
    "onlineStore.profile.share.modal.organization.label"                     : "Organization",
    "onlineStore.profile.share.modal.organization.add"                       : "Add a shared organization",
    "onlineStore.profile.share.modal.organization.duplicate.error"           : "Make sure there are no duplicate organizations.",
    "onlineStore.profile.share.modal.organization.empty.error"               : "Make sure there are no empty organizations.",
    "onlineStore.profile.share.modal.organization.info.bubble"               : "Sharing an online store will allow another organization's members to make purchases from it.",
    "onlineStore.profile.share.modal.organization.info.bubble.validInvoices" : "An online stores' shared organizations cannot change once it has invoices.",


    "onlineStore.profile.overview.status.ACTIVE"          : "Active",
    "onlineStore.profile.overview.status.DRAFT"           : "Draft",
    "onlineStore.profile.overview.status.CLOSE"           : "Close",
    "onlineStore.profile.overview.status.UNDER_CREATION"  : "under creation",
    "onlineStore.profile.overview.status.updated.success" : "Updated status",
    "onlineStore.profile.overview.status.updated.error"   : "Unable to update status",

    "onlineStore.profile.overview.notFound.title"       : "Online registration not found",
    "onlineStore.profile.overview.notFound.description" : "The online registration you are looking for was not found, it may have been moved or removed.",
    "onlineStore.profile.overview.notFound.backButton"  : "Go back",

    "onlineStore.profile.header.back" : "Back to online registration list",
}