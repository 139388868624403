import HasAccessTo from '../../../../components/permissions/HasAccessTo';
import OrganizationGeneralInfo from './components/OrganizationGeneralInfo';
import OrganizationRegionalSettings from './components/regionalSettings/OrganizationRegionalSettings';
import PaymentSettings from './components/paymentSettings/PaymentSettings';
import OrganizationMerchantAccounts from './components/OrganizationMerchantAccounts';
import OrgProfileTour from './OrgProfileTour';
import useMissingMandatoryContactTypes from '../../../../helpers/useMissingMandatoryContactTypes';
import { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';
import OrganizationProfileTaxes from './components/taxes/OrganizationProfileTaxes';

const OrganizationProfileOverview = (props) => {
    const organizationContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const { isValidating } = useMissingMandatoryContactTypes();
    const [ fedSettings, setFedSettings ] = useState([]);

    const [ showPaymentSetting, setShowPaymentSetting ] = useState(false)

    useEffect(() => {
        if(identityRolesContext.federation?.organisation_id){
            organizationContext.getOrganizationSettings(identityRolesContext.federation.organisation_id)
                .then((settings) => {
                    setFedSettings(settings)
                })
                .catch((error) => { console.error(error.message) });
        }
    }, [ identityRolesContext.federation?.organisation_id ])

    return (
        <>
            {!isValidating &&
                <HasAccessTo componentCode="settings">
                    <OrgProfileTour />
                </HasAccessTo>
            }
            {/* Waiting for API to implement this, and maybe others like Terms and Settings */}
            {/* <OrganizationContactInfo /> */}
            <OrganizationGeneralInfo {...props} />

            <HasAccessTo componentCode='settings' componentPermissionCode='settings_regional'>
                <div id="org-profile-settings">
                    <OrganizationRegionalSettings {...props} />
                </div>
            </HasAccessTo>

            {fedSettings.activate_fees?.value == "1" && showPaymentSetting &&
                <HasAccessTo componentCode='settings' componentPermissionCode='settings_merchant_account'>
                    <div id="org-profile-payment-settings">
                        <PaymentSettings {...props} />
                    </div>
                </HasAccessTo>
            }

            <div id="org-profile-taxes">
                <OrganizationProfileTaxes />
            </div>

            <HasAccessTo componentCode='settings' componentPermissionCode='settings_merchant_account'>
                <div id="org-profile-merchant-accounts">
                    <OrganizationMerchantAccounts {...props} setShowPaymentSetting={setShowPaymentSetting} />
                </div>
            </HasAccessTo>

        </>
    )

}

export default OrganizationProfileOverview