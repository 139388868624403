import React from "react";
import {
    Row,
    Col,
    Label
} from "reactstrap";
import { FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate from "@spordle/intl-elements";
import FormikEditable from "../../../../components/formik/FormikEditable";
import * as Yup from 'yup';
import { success, fail } from '@spordle/toasts';
import OverlayLoader from "../../../../components/loading/OverlayLoader";

// Contexts
import { RegistrationClassesContext } from '../../../../contexts/RegistrationClassesContext';
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';
import withContexts from "../../../../helpers/withContexts";
import SidePanel from "../../../../components/sidePanel/SidePanel";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";

class SettingsRegistrationClassSidepanel extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
        }
    }

    componentDidMount(){
        this.setState({ isLoading: false });
    }

    toggleState = (bool) => this.setState((prevState) => ({ isLoading: bool ?? !prevState.isLoading }));

    render(){
        return (
            <I18nHelperContext.Consumer>
                { (i18nHelper) => (
                    <OverlayLoader isLoading={this.state.isLoading}>
                        <SidePanel.Header>
                            <div className='d-flex align-items-center mb-2'>
                                <SidePanel.ToggleButton />
                                <SidePanel.ActionsMenu action='DELETE' componentCode='teams' componentPermissionCode='team_classes'>
                                    <SidePanel.MenuDelete
                                        translateModalMsg
                                        modalMsg='registration.settings.class.delete'
                                        modalTitle={
                                            <DisplayI18n
                                                field="name"
                                                i18n={this.props.getCommonValue('i18n')}
                                                defaultValue={this.props.getCommonValue('name')}
                                            />
                                        }
                                        onConfirm={() => {
                                            return this.props.RegistrationClassesContext.deleteRegistrationClass(this.props.getCommonValue('class_id'))
                                                .then(() => {
                                                    this.props.tableRef.deleteRow(this.props.getCommonValue('class_id'))
                                                    this.props.toggle();
                                                    success();
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                        }}
                                    />
                                </SidePanel.ActionsMenu>
                            </div>
                            <SidePanel.Title>
                                <DisplayI18n
                                    field={'short_name'}
                                    defaultValue={this.props.selectedRows[0].short_name}
                                    i18n={this.props.selectedRows[0].i18n}
                                />
                            </SidePanel.Title>
                            <SidePanel.Subtitle>
                                <DisplayI18n
                                    field={'name'}
                                    defaultValue={this.props.selectedRows[0].name}
                                    i18n={this.props.selectedRows[0].i18n}
                                />
                            </SidePanel.Subtitle>
                        </SidePanel.Header>
                        <div className="p-3">
                            <Row form>
                                <RenderI18nForm field='short_name'>
                                    {({ fieldName, fieldLabel }) => (
                                        <Col sm="6" key={fieldName}>
                                            <div className="mb-3">
                                                <Label for={fieldName} className="text-muted">{fieldLabel}</Label>
                                                <FormikEditable
                                                    id={fieldName}
                                                    initialValues={
                                                        i18nHelper.getInitialValues(this.props.selectedRows[0])
                                                    }
                                                    validationSchema={Yup.object().shape(
                                                        i18nHelper.getValidationSchema({ short_name: Yup.string().required(<Translate id='catalog.otherItems.otherItemsAdd.form.validation.name' />) }),
                                                    )}
                                                    onSubmit={(values) => {
                                                        const newValues = this.props.createNewValues(values);
                                                        this.toggleState(true);
                                                        this.props.RegistrationClassesContext.updateRegistrationClass(this.props.getCommonValue('class_id'), i18nHelper.getAPIValues(values))
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                }
                                                            })
                                                            .finally(() => this.toggleState(false));
                                                    }}
                                                >
                                                    {(isEditing) => {
                                                        if(!isEditing){
                                                            return (
                                                                <div className='font-medium text-dark'>
                                                                    <DisplayI18n
                                                                        field={fieldName}
                                                                        defaultValue={this.props.selectedRows[0].short_name || '-'}
                                                                        i18n={this.props.selectedRows[0].i18n}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        return <FormikInputText id={fieldName} name={fieldName} autoFocus />

                                                    }}
                                                </FormikEditable>
                                            </div>
                                        </Col>
                                    )}
                                </RenderI18nForm>
                                <RenderI18nForm field='name'>
                                    {({ fieldName, fieldLabel }) => {
                                        const lang = fieldName.substring(0, fieldName.lastIndexOf('.'));

                                        return (
                                            <Col sm="6" key={fieldName}>
                                                <div className="mb-3">
                                                    <Label for={fieldName} className="text-muted">
                                                        <Translate id='form.fields.description.lang' values={{ lang: lang.substring(lang.indexOf('.') + 1, lang.length).toUpperCase() }} />
                                                    </Label>
                                                    <FormikEditable
                                                        id={fieldName}
                                                        initialValues={
                                                            i18nHelper.getInitialValues(this.props.selectedRows[0])
                                                        }
                                                        validationSchema={Yup.object().shape(
                                                            i18nHelper.getValidationSchema({ name: Yup.string().required(<Translate id='catalog.otherItems.otherItemsAdd.form.validation.description' />) }),
                                                        )}
                                                        onSubmit={(values) => {
                                                            const newValues = this.props.createNewValues(values);
                                                            this.toggleState(true);

                                                            this.props.RegistrationClassesContext.updateRegistrationClass(this.props.getCommonValue('class_id'), i18nHelper.getAPIValues(values))
                                                                .then(() => {
                                                                    this.props.syncRows(newValues);
                                                                    success();
                                                                })
                                                                .catch((error) => {
                                                                    if(!AxiosIsCancelled(error.message)){
                                                                        console.error(error.message)
                                                                        fail({
                                                                            msg: 'misc.error',
                                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                            skipInfoTranslate: true,
                                                                        })
                                                                    }
                                                                })
                                                                .finally(() => this.toggleState(false));
                                                        }}
                                                    >
                                                        {(isEditing) => {
                                                            if(!isEditing){
                                                                return (
                                                                    <div className='font-medium text-dark'>
                                                                        <DisplayI18n
                                                                            field={fieldName}
                                                                            defaultValue={this.props.selectedRows[0].name || '-'}
                                                                            i18n={this.props.selectedRows[0].i18n}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                            return (
                                                                <FormikInputText id={fieldName} name={fieldName} autoFocus />
                                                            )

                                                        }}
                                                    </FormikEditable>
                                                </div>
                                            </Col>
                                        )
                                    }}
                                </RenderI18nForm>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='registration.settings.class.form.label.status' /></div>
                                        <FormikEditable
                                            noConfirmation
                                            id='class_status'
                                            initialValues={{
                                                active: this.props.getCommonValue('active'),
                                            }}
                                            onSubmit={(values) => {
                                                if(values.active !== this.props.getCommonValue("active")){
                                                    this.toggleState(true);
                                                    const newValues = this.props.createNewValues(values);
                                                    this.props.RegistrationClassesContext.updateRegistrationClass(this.props.getCommonValue('class_id'), values)
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                        .finally(() => this.toggleState(false))
                                                }
                                            }}
                                        >
                                            {(isEditing, options) => {
                                                if(!isEditing){
                                                    return (
                                                        <div className='font-medium text-dark'>{this.props.getCommonValue('active') === '1' ? 'Active' : 'Inactive'}</div>
                                                    )
                                                }
                                                return (
                                                    <FormikSelect
                                                        search={false}
                                                        name='active'
                                                        id='active'
                                                        autoFocus
                                                        menuIsDefaultOpen
                                                        onOptionSelected={options.stopEditing}
                                                        loadingStatus='success'
                                                        defaultData={[
                                                            { value: '1', label: 'misc.active', translateLabel: true },
                                                            { value: '0', label: 'misc.inactive', translateLabel: true },
                                                        ]}
                                                    />
                                                )

                                            }}
                                        </FormikEditable>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='registration.settings.class.form.label.order' /></div>
                                        <FormikEditable
                                            id='class_display_order'
                                            initialValues={{
                                                display_order: this.props.getCommonValue("display_order"),
                                            }}
                                            validationSchema={Yup.object().shape({
                                                display_order: Yup.number()
                                                    .integer(<Translate id='registration.settings.class.form.validation.order.integer' />)
                                                    .moreThan(0, <Translate id='registration.settings.class.form.validation.order.moreThan0' />)
                                                    .required(<Translate id='registration.settings.class.form.validation.order.required' />),
                                            })}
                                            onSubmit={(values) => {
                                                if(values.display_order !== this.props.getCommonValue("display_order")){
                                                    this.toggleState(true);
                                                    const newValues = this.props.createNewValues(values);
                                                    this.props.RegistrationClassesContext.updateRegistrationClass(this.props.getCommonValue('class_id'), values)
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                        .finally(() => this.toggleState(false))
                                                }
                                            }}
                                        >
                                            {(isEditing) => {
                                                if(!isEditing){
                                                    return <div className='font-medium text-dark'>{this.props.getCommonValue("display_order") || '-'}</div>
                                                }
                                                return (
                                                    <FormikInputNumber
                                                        id='display_order'
                                                        name='display_order'
                                                        allowNegative={false}
                                                    />
                                                )

                                            }}
                                        </FormikEditable>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </OverlayLoader>
                )}
            </I18nHelperContext.Consumer>
        )
    }
}

export default withContexts(RegistrationClassesContext)(SettingsRegistrationClassSidepanel);