import catalogSidebar from './navigationRoutes/catalogSidebar';
import searchSidebar from './navigationRoutes/searchSidebar';
import settingsSidebar from './navigationRoutes/settingsSidebar';
import tasksSidebar from './navigationRoutes/tasksSidebar';
import reportsSidebar from './navigationRoutes/reportsSidebar';
import newsSidebar from './navigationRoutes/newsSidebar';
import communicationsSidebar from './navigationRoutes/communicationsSidebar';
import MobileManage from '../assets/images/icons/navigation/MobileManage';
import MobileTask from '../assets/images/icons/navigation/MobileTask';
import Svg from '../components/Svg';

// ===========================================
// All routes used in UI for navigation purposes
// ===========================================
const navigationRoutes = [
    {
        name: 'sidebar.manage',
        subPermissionsRoutes: searchSidebar,
        tourCSSClass: 'header-tour-link-manage',
        icon: <MobileManage />,
    },
    {
        name: 'sidebar.tasks',
        subPermissionsRoutes: tasksSidebar,
        tourCSSClass: 'header-tour-link-tasks',
        icon: <MobileTask />,
    },
    {
        name: 'sidebar.catalog',
        permissions: { code: 'catalog' },
        subPermissionsRoutes: catalogSidebar,
        tourCSSClass: 'header-tour-link-catalog',
        icon: <Svg icon='catalog' />,
    },
    {
        name: 'sidebar.reports',
        path: '/reports',
        permissions: { code: 'reports' },
        subPermissionsRoutes: reportsSidebar,
        tourCSSClass: 'header-tour-link-reports',
        icon: <Svg icon='reports' />,
    },
    {
        name: 'sidebar.settings',
        permissions: { code: 'settings' },
        subPermissionsRoutes: settingsSidebar,
        tourCSSClass: 'header-tour-link-settings',
        icon: <Svg icon='settings' />,
    },
    {
        hiddenRouteDesktop: true,
        name: 'sidebar.communications',
        path: '/communications',
        permissions: { code: 'communications' },
        subPermissionsRoutes: communicationsSidebar,
        icon: <Svg icon='communications' />,
    },
    {
        hiddenRouteDesktop: true,
        permissions: { code: "communications", permissionCode: 'communication_news_manage' },
        name: "sidebar.news",
        path: '/news',
        skipSubPermissionsRoutes: true,
        subPermissionsRoutes: newsSidebar,
        icon: <i style={{ height: 25, width: 35 }} className="line-height-1 text-center ti font-20 ti-announcement" />,
    },
];
export default navigationRoutes;
