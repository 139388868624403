import Translate from '@spordle/intl-elements';
import {
    Button,
    Card,
    Col,
    Collapse,
    Fade,
    FormGroup,
    ModalBody,
    ModalFooter, ModalHeader, Row
} from "reactstrap";
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { Form, Formik } from 'formik';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { useContext, useEffect, useState } from 'react';
import CustomAnimatedIcon from '../../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import InlineCopy from '../../../../../components/inlineCopy/InlineCopy';
import { object } from 'yup';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import CartProvider, { getRequiredFieldsInitialValues, getRequiredFieldsValidationSchema, getRequiredField } from '@spordle/cart-engine';
import { useIntl } from 'react-intl';
import { AuthContext } from '../../../../../contexts/contexts';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import API_SPORDLE from '../../../../../api/API-Public';
import moment from 'moment';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import CustomAlert from '../../../../../components/CustomAlert';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import { Tooltip } from '@mantine/core';
import { fail } from '@spordle/toasts';
import { createMemberRequestChange, updateMemberRequestChangeStatus } from '../../../../../api/client/memberRequestChanges';
import GenderLabel from '../../../../../components/GenderLabel';
import DisplayBirthdateAge from '../../../../../components/DisplayBirthdateAge';

const RequestMandatoryChangesModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName='RequestMandatoryChangesModal'>
            <RequestMandatoryChangesModalInner {...props} />
        </AnalyticsModal>
    )
}

const RequestMandatoryChangesModalInner = ({ isOpen, toggle, member, refreshMemberProfile, forceCloseSidepanel, fieldChangeRequestStatuses }) => {
    const organizationContext = useContext(OrganizationContext)
    const membersContext = useContext(MembersContext)
    const authContext = useContext(AuthContext)
    const identityRolesContext = useContext(IdentityRolesContext)
    const { formatMessage } = useIntl();

    const requestMandatoryFields = organizationContext.settings?.request_mandatory_change_fields?.value || []

    const [ isSuccess, setIsSuccess ] = useState(false);

    const [ isCancelSuccess, setIsCancelSuccess ] = useState(false);
    const [ cancellationPending, setCancellationPending ] = useState(false);

    const triggerAPI = (method, endpoint, body) => {
        return API_SPORDLE[method.toLowerCase()](endpoint, body ? new URLSearchParams(body) : undefined)
            .then((response) => response.data)
    }

    const getMemberRequestedFields = (fields) => {
        return fields.reduce((mandatoryMemberFields, requestField) => {
            mandatoryMemberFields[requestField] = membersContext.currentMember[requestField] || ""
            return mandatoryMemberFields;
        }, {})
    }

    useEffect(() => {
        // set success state to false when opening the modal
        if(isOpen === true)
            setIsSuccess(false)
    }, [ isOpen ])

    const hasChangeRequest = !!membersContext.currentMember?.member_request_changes?.[0]?.member_request_change_id

    return (
        <CartProvider
            accessToken={authContext.accessToken}
            environment={process.env.REACT_APP_ENVIRONMENT}
            lexiconValues={{
                organisation_name: <DisplayI18n field='name' defaultValue={organizationContext.organisation_name} i18n={organizationContext.i18n} />,
            }}
            triggerAPI={triggerAPI}
        >
            <Formik
                initialValues={{
                    ...getRequiredFieldsInitialValues(requestMandatoryFields),
                    ...getMemberRequestedFields(requestMandatoryFields),
                    // Special formatting
                    birthdate: moment(membersContext.currentMember.birthdate),
                }}
                validationSchema={object().shape({
                    ...getRequiredFieldsValidationSchema(requestMandatoryFields || [], {
                        requiredField: formatMessage({ id: 'form.cart.required' }),
                        dateFormat: formatMessage({ id: 'form.validation.date.format' }),
                        dateBeforeToday: formatMessage({ id: 'form.cart.date.before.today' }),
                    }),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    const apiValues = Object.keys(values)
                        .filter((key) => {
                            if(key === 'birthdate')
                                return membersContext.currentMember[key] !== moment(values[key]).format('YYYY-MM-DD') && requestMandatoryFields.some((field) => field === key);
                            return membersContext.currentMember[key] !== values[key] && requestMandatoryFields.some((field) => field === key);
                        })
                        .map((key) => ({
                            field: key,
                            value: key === 'birthdate' ? moment(values[key]).format('YYYY-MM-DD') : values[key],
                        }))

                    return createMemberRequestChange(membersContext.currentMember.member_id, { data_to_change: apiValues })
                        .then(() => {
                            setStatus("success");
                            setSubmitting(false);
                            setIsSuccess(true);
                            refreshMemberProfile();
                        }).catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            }
                            setSubmitting(false);
                        });
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader toggle={toggle}>
                                <Translate id='members.profile.action.requestMandatoryChanges' />
                            </ModalHeader>
                            {/* CREATE CHANGE REQUEST */}
                            {(!isSuccess && formik.status !== 'success' && !hasChangeRequest && !isCancelSuccess) &&
                                    <>
                                        <ModalBody>
                                            <UserDisplay card className='w-100'>
                                                <UserDisplay.Container>
                                                    <UserImg
                                                        abbr={member.first_name[0] + member.last_name[0]}
                                                        src={null}
                                                        width={50}
                                                        height={50}
                                                        alt={`${member.first_name} ${member.last_name}`}
                                                    />
                                                </UserDisplay.Container>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title>{member.first_name} {member.last_name}</UserDisplay.Title>
                                                    <UserDisplay.Subtitle className="font-medium text-dark"><InlineCopy tag='div' toCopy={member.unique_identifier} className="font-medium">{'#' + member.unique_identifier || '-'}</InlineCopy></UserDisplay.Subtitle>
                                                    <UserDisplay.Subtitle><DisplayBirthdateAge birthdate={member.birthdate} age={member.age} /></UserDisplay.Subtitle>
                                                    <UserDisplay.Subtitle><GenderLabel gender={member.gender} /></UserDisplay.Subtitle>
                                                </UserDisplay.Container>
                                            </UserDisplay>

                                            <Row form className='text-left'>
                                                {requestMandatoryFields.map((field, index) => (getRequiredField(field, index, formik)))}
                                            </Row>

                                            {formik.status &&
                                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                                    <CustomAlert className='mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                                </Collapse>
                                            }
                                        </ModalBody>
                                        <ModalFooter>
                                            <Tooltip
                                                zIndex={3000}
                                                disabled={requestMandatoryFields.some((field) => formik.values[field] !== formik.initialValues[field])}
                                                withArrow label={<Translate id={'members.profile.mandatory.change.make.changes'} />}
                                            >
                                                <Button
                                                    color='primary'
                                                    type='submit'
                                                    disabled={formik.isSubmitting || !requestMandatoryFields.some((field) => formik.values[field] !== formik.initialValues[field])}
                                                >
                                                    <Translate id='misc.submit' />
                                                </Button>
                                            </Tooltip>
                                            <Button
                                                color='primary' type='button' onClick={toggle}
                                                outline disabled={formik.isSubmitting}
                                            >
                                                <Translate id='misc.cancel' />
                                            </Button>
                                        </ModalFooter>
                                    </>
                            }
                            {/* CHANGE REQUEST CREATED SUCCESSFULLY */}
                            {(isSuccess && formik.status == 'success') &&
                                    <>
                                        <ModalBody className="text-center text-dark">
                                            <Fade in>
                                                <div className='position-relative d-inline-block'>
                                                    <CustomAnimatedIcon
                                                        className='text-success'
                                                        strokeWidth="10px"
                                                        size={50}
                                                        id='confirmModalCheckmark'
                                                        icon='checkmark'
                                                        withCircle
                                                    />
                                                </div>

                                                <div className="text-center h5 font-bold pt-2">
                                                    <Translate id="misc.success" />
                                                </div>
                                                <div><Translate id='members.profile.action.requestMandatoryChanges.success' /></div>
                                            </Fade>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color='primary' type='button' onClick={() => { toggle(); }} outline disabled={formik.isSubmitting}><Translate id='misc.close' /></Button>
                                        </ModalFooter>
                                    </>
                            }
                            {/* VIEW/CANCEL CHANGE REQUEST */}
                            {(hasChangeRequest && !isSuccess && !isCancelSuccess) &&
                                    <OverlayLoader isLoading={cancellationPending}>
                                        <ModalBody>
                                            <div className='h5 font-bold'><Translate id='task.mandatoryChangesRequests.sidepanel.request.requested_by' /></div>
                                            <div>
                                                {membersContext.currentMember.member_request_changes[0].created_by.name} {membersContext.currentMember.member_request_changes[0].created_by.family_name} - <a href={"mailto:" + membersContext.currentMember.member_request_changes[0].created_by.email}>{membersContext.currentMember.member_request_changes[0].created_by.email}</a>
                                            </div>
                                            <div className='mt-2 h5 font-bold'><Translate id='task.mandatoryChangesRequests.sidepanel.request.data' /></div>
                                            <Card body className='card-shadow'>
                                                <Row>
                                                    {membersContext.currentMember?.member_request_changes?.[0]?.data_to_change.map((data) => (
                                                        <Col key={data.code} md='12'>
                                                            <FormGroup>
                                                                <div className='text-muted d-flex'>
                                                                    <DisplayI18n field='name' defaultValue={data.memberField.name} i18n={data.memberField.i18n} />
                                                                </div>
                                                                <div className='d-flex flex-wrap'>
                                                                    <div>
                                                                        {data.code === 'gender' ?
                                                                            <GenderLabel gender={membersContext.currentMember[data.code]} />

                                                                            :
                                                                            <>{membersContext.currentMember[data.code]}</>
                                                                        }
                                                                    </div>
                                                                    <div className='mx-2'>
                                                                    →
                                                                    </div>
                                                                    <div className='font-bold'>
                                                                        {data.code === 'gender' ?
                                                                            <GenderLabel gender={data.value} />

                                                                            :
                                                                            <>{data.value}</>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    ))}

                                                </Row>
                                            </Card>
                                        </ModalBody>
                                        <ModalFooter>
                                            {identityRolesContext.identity.identity_id == membersContext.currentMember?.member_request_changes?.[0]?.created_by.identity_id &&
                                                <Button
                                                    disabled={identityRolesContext.identity.identity_id !== membersContext.currentMember?.member_request_changes?.[0]?.created_by.identity_id}
                                                    color='danger'
                                                    onClick={() => {
                                                        setCancellationPending(true);
                                                        return updateMemberRequestChangeStatus(membersContext.currentMember?.member_request_changes?.[0]?.member_request_change_id, { member_request_change_status_id: fieldChangeRequestStatuses.find((status) => status.code === 'CANCELLED')?.member_request_change_status_id })
                                                            .then(() => {
                                                                refreshMemberProfile();
                                                                setCancellationPending(false);
                                                                setIsCancelSuccess(true)
                                                            }).catch((error) => {
                                                                formik.setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                    setCancellationPending(false);
                                                                    console.error(error.message);
                                                                    formik.setSubmitting(false);
                                                                }
                                                            })
                                                    }}
                                                >
                                                    <Translate id='task.mandatoryChangesRequests.modal.cancel.btn' />
                                                </Button>
                                            }
                                            <Button color='primary' type='button' onClick={() => { toggle(); }} outline disabled={formik.isSubmitting}><Translate id='misc.close' /></Button>
                                        </ModalFooter>
                                    </OverlayLoader>
                            }
                            {/* CHANGE REQUEST CANCELLATION SUCCESS */}
                            {(isCancelSuccess) &&
                                    <>
                                        <ModalBody className="text-center text-dark">
                                            <Fade in>
                                                <div className='position-relative d-inline-block'>
                                                    <CustomAnimatedIcon
                                                        className='text-success'
                                                        strokeWidth="10px"
                                                        size={50}
                                                        id='confirmModalCheckmark'
                                                        icon='checkmark'
                                                        withCircle
                                                    />
                                                </div>

                                                <div className="text-center h5 font-bold pt-2">
                                                    <Translate id="misc.success" />
                                                </div>
                                                <div><Translate id='members.profile.mandatory.change.cancel.success' /></div>
                                            </Fade>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color='primary' type='button' onClick={() => { setIsCancelSuccess(false); }} disabled={formik.isSubmitting}><Translate id='members.profile.mandatory.change.new.request' /></Button>
                                            <Button color='primary' type='button' onClick={() => { toggle(); }} outline disabled={formik.isSubmitting}><Translate id='misc.close' /></Button>
                                        </ModalFooter>
                                    </>
                            }
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </CartProvider>
    );
}

export default RequestMandatoryChangesModal;