import { FieldArray, useFormikContext } from "formik";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { Button, Col, Row } from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { FormikInputNumber, FormikSelect } from "@spordle/formik-elements";
import Required from "../../../../../../components/formik/Required";
import generatePassword from "../../../../../../helpers/passwordGenerator";
import SettingLabel from "../../../../components/SettingLabel";

const ConfigurationCountPerResource = ({ options, name, setting, label }) => {
    const formik = useFormikContext();
    const values = formik.values[name] || [];

    return (
        <>
            <SettingLabel setting={setting} code={name} />
            <FieldArray name={name}>
                {(arrayHelper) => (
                    <>
                        {values.map((posCount, i) => (
                            <Row form key={posCount.key} className="mb-2">
                                <Col sm={8}>
                                    <div className={stringBuilder({ "d-sm-none": i > 0 }, "text-muted mb-1")}>
                                        {label} <Required />
                                    </div>
                                    <FormikSelect
                                        id={`${name}-${i}`}
                                        name={`${name}.${i}.id`}
                                        isLoading={!options}
                                        isOptionDisabled={(option) => values.some(({ id }) => id === option.value)}
                                        renderSelectedOption={(option) => (
                                            <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.name} />
                                        )}
                                        renderOption={({ option, isDisabled }) => (
                                            <>
                                                <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.name} />
                                                {isDisabled && posCount.id !== option.value && <div className="small"><Translate id="misc.alreadySelected" /></div>}
                                            </>
                                        )}
                                        options={options || []}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <div className={stringBuilder({ "d-sm-none": i > 0 }, "text-muted mb-1")}>
                                        <Translate id="settings.teamsSettings.configuration.count" /> <Required />
                                    </div>
                                    <div className="d-flex align-items-start w-100">
                                        <div className="flex-grow-1">
                                            <FormikInputNumber
                                                name={`${name}.${i}.count`}
                                                id={`${name}-${i}`}
                                            />
                                        </div>
                                        <Button
                                            type="button"
                                            color="link"
                                            onClick={() => arrayHelper.remove(i)}
                                            className="fa fa-trash ml-2 text-danger"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        ))}
                        {(values.length < (options || []).length) &&
                            <Button
                                block
                                type="button"
                                className="shadow-sm border border-primary border-dashed"
                                color="link"
                                onClick={() => arrayHelper.push({ id: "", count: 0, key: generatePassword() })}
                            >
                                <i className="mdi mdi-plus" /><Translate id="settings.teamsSettings.configuration.addLimit" />
                            </Button>
                        }
                    </>
                )}
            </FieldArray>
        </>
    );
}

export default ConfigurationCountPerResource;