export default{
    "onlineStore.search.title" : "Inscription en ligne",

    "onlineStore.search.btn.create" : "Créer une nouvelle boutique",

    "onlineStore.search.delete.confirmMessage" : "Êtes-vous sûr de vouloir supprimer:",

    "onlineStore.search.status"                : "Statut",
    "onlineStore.search.status.UNDER_CREATION" : "En création",
    "onlineStore.search.status.ACTIVE"         : "Actif",
    "onlineStore.search.status.DRAFT"          : "Brouillon",
    "onlineStore.search.status.CLOSE"          : "Fermé",

    // Table
    "onlineStore.search.table.column.title"        : "Nom",
    "onlineStore.search.table.column.organization" : "Organisation",
    "onlineStore.search.table.column.startDate"    : "Date de début",
    "onlineStore.search.table.column.endDate"      : "Date de fin",

    "onlineStore.search.table.emptyLayout.noResult"        : "Aucun résultat...",
    "onlineStore.search.table.emptyLayout.noResultMessage" : "Vous pouvez créer une nouvelle boutique d'inscription en ligne en haut de la page.",
    "onlineStore.search.table.paginationMessage"           : "Boutique {itemStartPos} à {itemEndPos} sur un total de {totalItems} boutiques",

    // Sidepanel
    "onlineStore.search.sidepanel.tab.general"      : "Général",
    "onlineStore.search.sidepanel.tab.registration" : "Inscriptions",

    "onlineStore.search.sidepanel.title.registrations"        : "Inscriptions",
    "onlineStore.search.sidepanel.title.registrations.sold"   : "Inscriptions vendues",
    "onlineStore.search.sidepanel.title.registrations.listed" : "Inscriptions affichées",
    "onlineStore.search.sidepanel.registrations.number"       : "{num} inscriptions",
    "onlineStore.search.sidepanel.registrations.none"         : "Aucune inscription",
    "onlineStore.search.sidepanel.title.otherItems"           : "Autres frais",
    "onlineStore.search.sidepanel.title.otherItems.sold"      : "Autres frais vendus",
    "onlineStore.search.sidepanel.title.otherItems.listed"    : "Autres frais affichés",
    "onlineStore.search.sidepanel.otherItems.number"          : "{num} autres frais",
    "onlineStore.search.sidepanel.otherItems.none"            : "Aucun autre frais",
    "onlineStore.search.sidepanel.items.sold"                 : "Vendus",
    "onlineStore.search.sidepanel.items.listed"               : "Affichés",

    "onlineStore.search.sidepanel.status.SOLD_OUT"  : "Vendu",
    "onlineStore.search.sidepanel.status.ACTIVE"    : "Actif",
    "onlineStore.search.sidepanel.status.SUSPENDED" : "Suspendu",

    "onlineStore.search.sidepanel.languages"          : "Langues",
    "onlineStore.search.sidepanel.languages.min"      : "Veuillez sélectionner au moins une langue",
    "onlineStore.search.sidepanel.email"              : "Adresse courriel du registraire",
    "onlineStore.search.sidepanel.registration.start" : "Période d'inscription (Début)",
    "onlineStore.search.sidepanel.registration.end"   : "Période d'inscription (Fin)",
    "onlineStore.search.sidepanel.registration.date"  : "Date",
    "onlineStore.search.sidepanel.registration.time"  : "Heure",

}