import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { DropdownItem } from "reactstrap";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { fail } from '@spordle/toasts';
import { triggerDownload } from "../../../../components/uploader/uploadHelpers";
import { I18nContext } from "../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { TeamsContext } from "../../../../contexts/TeamsContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { ReportsContext } from './../../../../contexts/ReportsContext';

const TeamHeaderExport = ({ team }) => {
    const teamsContext = useContext(TeamsContext);
    const reportsContext = useContext(ReportsContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);

    // const { getGenericLocale } = useContext(I18nContext);
    // const { formatMessage } = useIntl();
    // COMMENTED CODE IS TEAM CSV EXPORT, DISABLED FOR NOW WAITING TO SEE IF THIS WILL CAUSE BACKLASH

    // const getJerseyNumber = (numberHome, numberAway) => {
    //     // if away is null, it means we have only 1 jersey number for both away and home
    //     if(!numberAway) {
    //         return numberHome || '';
    //     } else {
    //         return `Home: ${numberHome} - Away: ${numberAway || numberHome}`
    //     }
    // }

    // const getMemberType = (teamMember) => {
    //     // ACTIVE ROSTER
    //     if(teamMember.status !== 'PENDING_DEFICIENT' && teamMember.status !== 'INELIGIBLE' && teamMember.position?.position_group?.position_type === 'PLAYER'){
    //         return 'active';

    //     // TEAM OFFICIAL ROSTER
    //     }else if(teamMember.status !== 'PENDING_DEFICIENT' && teamMember.status !== 'INELIGIBLE' && (teamMember.position?.position_group?.position_type === 'STAFF' || teamMember.position?.position_group?.position_type === 'COACH' || teamMember.position?.position_group?.position_type === 'BENCH_STAFF')){
    //         return 'official';

    //     // INACTIVE ROSTER
    //     }else if(teamMember.status == 'PENDING_DEFICIENT' || teamMember.status == 'INELIGIBLE'){
    //         return 'inactive';
    //     }

    //     // FALLBACK INACTIVE ROSTER
    //     return 'inactive';
    // }

    // const filterTeamMember = (rosterData) => {
    //     return rosterData.reduce((newObj, teamMember) => {
    //         const memberType = getMemberType(teamMember);

    //         newObj[memberType]?.push(teamMember);

    //         return newObj
    //     }, {
    //         active: [],
    //         official: [],
    //         inactive: [],

    //     });
    // }

    // const getPlayerHeaders = (title) => {
    //     return [
    //         [ formatMessage({ id: title }) ],
    //         [
    //             formatMessage({ id: 'form.fields.firstName' }),
    //             formatMessage({ id: 'form.fields.lastName' }),
    //             '#',
    //             formatMessage({ id: 'form.fields.dateOfBirth' }),
    //             formatMessage({ id: 'teams.profile.roster.table.position' }),
    //             formatMessage({ id: 'teams.profile.roster.table.jerseyNumber.home' }) + ' ' + formatMessage({ id: 'teams.profile.roster.table.jerseyNumber' }),
    //             formatMessage({ id: 'teams.profile.roster.table.jerseyNumber.away' }) + ' ' + formatMessage({ id: 'teams.profile.roster.table.jerseyNumber' }),
    //             formatMessage({ id: 'teams.profile.roster.table.status' }),
    //             formatMessage({ id: 'teams.profile.roster.table.addedDate' }),
    //         ],
    //     ]
    // }

    // const getPlayerData = (member) => {
    //     return [
    //         member.member.first_name || '',
    //         member.member.last_name || '',
    //         member.member.unique_identifier ? `="${member.member.unique_identifier}"` : '',
    //         member.member.birthdate || '',
    //         member.position ? displayI18n('name', member.position.i18n, member.position.name, getGenericLocale()) : '',
    //         member.jersey_number || '',
    //         member.jersey_number_alternate || member.jersey_number || '',
    //         formatMessage({ id: 'teams.profile.roster.table.status.' + member.status }),
    //         member.registration_date ? member.registration_date : '',
    //     ];
    // }

    /**
     * @description Export to CSV cachedTeam
     */
    // const handleExport = (rosterData) => {
    //     const { active, official, inactive } = filterTeamMember(rosterData);
    //     const SEPARATOR = [ [], [] ];

    //     exportToCsv(`SpordleID-${teamsContext.cachedTeam.name}-profile-${moment().format('YYYY-MM-DD H:mm')}.csv`,
    //                 [
    //                     [ teamsContext.cachedTeam.name + ' #' + teamsContext.cachedTeam.unique_identifier ],
    //                     ...SEPARATOR,

    //                     // Active Roster
    //                     ...getPlayerHeaders('teams.profile.roster.table.activeRoster.title'),
    //                     ...active.map(getPlayerData),

    //                     ...SEPARATOR,

    //                     // TEAM OFFICIAL
    //                     [ formatMessage({ id: "teams.profile.roster.table.benchStaff.title" }) ],
    //                     [
    //                         formatMessage({ id: 'form.fields.firstName' }),
    //                         formatMessage({ id: 'form.fields.lastName' }),
    //                         '#',
    //                         formatMessage({ id: 'form.fields.dateOfBirth' }),
    //                         formatMessage({ id: 'teams.profile.roster.table.position' }),
    //                         formatMessage({ id: 'teams.profile.roster.table.jerseyNumber.home' }) + ' ' + formatMessage({ id: 'teams.profile.roster.table.jerseyNumber' }),
    //                         formatMessage({ id: 'teams.profile.roster.table.jerseyNumber.away' }) + ' ' + formatMessage({ id: 'teams.profile.roster.table.jerseyNumber' }),
    //                         formatMessage({ id: 'teams.profile.roster.table.status' }),
    //                         formatMessage({ id: 'teams.profile.roster.table.addedDate' }),
    //                     ],
    //                     ...official.map((member) => {
    //                         return [
    //                             member.member.first_name || '',
    //                             member.member.last_name || '',
    //                             member.member.unique_identifier ? `="${member.member.unique_identifier}"` : '',
    //                             member.member.birthdate || '',
    //                             member.position ? displayI18n('name', member.position.i18n, member.position.name, getGenericLocale()) : '',
    //                             '',
    //                             '',
    //                             formatMessage({ id: 'teams.profile.roster.table.status.' + member.status }),
    //                             member.registration_date ? member.registration_date : '',
    //                         ]
    //                     }),

    //                     ...SEPARATOR,

    //                     // Inactive Roster
    //                     ...getPlayerHeaders("teams.profile.roster.table.inactiveRoster.title"),
    //                     ...inactive.map(getPlayerData),
    //                 ]);

    // }

    /**
     * @description Export to PDF
     */
    const handleRosterExport = () => {
        const reportType = 'team_rosters';
        // gets all report types
        reportsContext.getReports({ identity_role_id: identityRolesContext.identity_role_id })
            .then(async(report_type) => {
                // grabbing the correct report type ID with the find
                const reportTypeId = report_type.find((type) => type.code === reportType).report_id;
                // getting the report itself for the line count
                const reports = await reportsContext.getTeamRosterReport({ team_id: team.team_id });
                // creates the exportable report (adds to library and creates the download link as well as generating the report)

                if(reports.report_count > 0){
                    const data = await reportsContext.createExportableReport({
                        organisation_id: organizationContext.organisation_id,
                        total_count: 1,
                        language_code: i18nContext.getGenericLocale(),
                        report_id: reportTypeId,
                        requested_by: identityRolesContext.identity.identity_id,
                        request_parameter: JSON.stringify({ team_id: team.team_id, report_type: 'PDF' }),
                    }, true).catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })

                    if(data.download_link){
                        triggerDownload(data.download_link)
                    }
                }else{
                    fail({ msg: 'teams.teamSearch.export.emptyTeams' });
                }
            }).catch((error) => {
                if(error.message === '3382'){
                    fail({ msg: 'teams.teamSearch.export.onlyPrintApproved', info: 'teams.teamSearch.export.onlyPrintApproved2' });
                }
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    return (
        <>
            <DropdownItem divider />
            {/* <DropdownItem
                id='team-header-export-csv'
                disabled={teamsContext.rosterTablesData?.length == 0}
                onClick={() => {
                    teamsContext.getRosterTablesData(team.team_id, null, true)
                        .then((data) => {
                            handleExport(data);
                        })
                        .catch((error) => { console.error(error.message) });
                }}
            ><i className='fas fa-file-excel mr-1' /><Translate id='teams.profile.roster.button.export.csv' />
            </DropdownItem> */}
            <DropdownItem
                id='team-header-export-pdf'
                disabled={teamsContext.rosterTablesData?.length == 0}
                onClick={handleRosterExport}
            >
                <i className='fas fa-file-pdf mr-1' /><Translate id='teams.profile.roster.button.export.pdf' />
                <div className="small">
                    {teamsContext.rosterTablesData?.length == 0 &&
                        <div className="text-wrap">
                            <Translate id="teams.profile.roster.button.export.pdf.disabled" />
                        </div>
                    }
                </div>
            </DropdownItem>
        </>
    )
}


export default TeamHeaderExport;