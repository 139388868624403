import DisplayMemberTypeStatusIcon from "../../../../../../../components/display/DisplayMemberTypeStatusIcon"
import { memberTypeSpecial } from "./memberTypesConstants"

export const getMemberTypeIcon = (memberType) => {
    if(!memberType){
        return null;
    }

    if(memberType.member_status_type){
        return <DisplayMemberTypeStatusIcon status={memberType.member_status_type} />
    }


    if(memberType.member_type_id === '1ec4d34a-9552-66b6-ad75-06bf14840f98' || memberType.member_type_id === '9fc36077-9003-11ec-b007-022fbfd56a42'){
        //'1ec4d34a-9552-66b6-ad75-06bf14840f98' : int - dreams comes true
        //'9fc36077-9003-11ec-b007-022fbfd56a42' : prod - dreams comes true
        return <i style={{ width: '1.5ch' }} className='d-inline-block mdi mdi-weather-night text-primary mr-2' />
    }else if(memberType.system_type === memberTypeSpecial){ // special member types
        return <i style={{ width: '1.5ch' }} className='d-inline-block mdi mdi-creation text-primary mr-2' />
    }

    return (
        <span style={{ width: '1.5ch', height: '1em' }} className='d-inline-flex flex-center text-center mr-2'>
            <span style={{ fontSize: '1.2em', lineHeight: 0 }} className="d-inline-block">&bull;</span>
        </span>
    )// standard member types
}