import { FormikSelect } from "@spordle/formik-elements"
import { useFormikContext } from "formik"
import { useContext } from "react"
import { FormGroup } from "reactstrap"
import { I18nContext } from "../../../../../contexts/I18nContext"
import { OrganizationContext } from "../../../../../contexts/OrganizationContext"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import { formikCodesHaveValues, handleSelectFilterChange, filterHasValue, useGenderSettingsSWR } from "../../reportsEngineHelpers"
import FilterLabel from "../FilterLabel"

const EngineGenderMultiSelect = ({ filter, filterRefs }) => {
    const formik = useFormikContext();
    const i18nContext = useContext(I18nContext);
    const organizationContext = useContext(OrganizationContext);

    const orgGenders = organizationContext.settings.gender_selection?.value; // ARRAY
    const { data: allGenders, isValidating } = useGenderSettingsSWR(); // OBJECT

    return (
        <FormGroup name={filter.code}>
            <FilterLabel filter={filter} />
            <FormikSelect
                {...filter.parsedParams?.componentProps}
                multi clearable
                id={filter.code}
                name={filter.code}
                ref={(r) => { filterRefs.current[filter.code] = r }}
                search={filter.parsedParams?.componentProps?.searchKeys?.length > 0}
                searchKeys={filter.parsedParams?.componentProps?.searchKeys?.map((search) => search.replace('{{LOCALE}}', i18nContext.getGenericLocale()))}
                options={orgGenders?.map((value) => {
                    const genderObject = allGenders?.[value];
                    return ({
                        value: value,
                        label: genderObject?.i18n?.en?.name || genderObject?.i18n?.fr?.name,
                        ...genderObject,
                    })
                })}
                loadingStatus={'success'}
                isLoading={isValidating}
                renderOption={({ option }) => (<DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />)}
                onOptionSelected={(period, spordleSelect) => handleSelectFilterChange(filter, formik, period, spordleSelect, filterRefs)}
                className={filter.parsedParams?.componentProps?.className}
                disabled={!formikCodesHaveValues(filter.parsedParams?.enabledWhen, formik) || !filterHasValue(filter.parsedParams?.enabledWhenValue?.filter, filter.parsedParams?.enabledWhenValue?.value, formik)}
            />
        </FormGroup>
    )
}

export default EngineGenderMultiSelect