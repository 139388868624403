import SubRouteRenderer from '../../components/subSidebar/SubRouteRenderer';
import tasksRoutes from '../../routes/routerRoutes/tasksRoutes';

const Tasks = () => {
    return (
        <div className='page-content ml-0 px-0 pt-0'>
            <SubRouteRenderer routes={tasksRoutes} />
        </div>
    );
}

export default Tasks;