import Translate from "@spordle/intl-elements";
import { FieldArray, Form, Formik } from "formik";
import { Button, Col, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { array, object, string } from "yup";
import AnalyticsModal from "../../../../../../../analytics/AnalyticsModal";
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import { FormikAddress, FormikDateTime, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect, FormikSwitch, FormikTextArea, getFormikAddressInitialValues } from "@spordle/formik-elements";
import Required from "../../../../../../../components/formik/Required";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { useContext } from "react";
import { I18nContext } from "../../../../../../../contexts/I18nContext";
import { AddressTypesContext } from "../../../../../../../contexts/AddressTypesContext";
import { MembersContext } from "../../../../../../../contexts/MembersContext";
import moment from "moment";
import { PhoneTypesContext } from "../../../../../../../contexts/PhoneTypesContext";
import generatePassword from "../../../../../../../helpers/passwordGenerator";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import CustomAlert from "../../../../../../../components/CustomAlert";


const MemberAddressPhoneModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal analyticsName="memberAddressPhoneModal" isOpen={isOpen} size='lg'>
            <MemberAddressPhoneModalInner {...props} />
        </AnalyticsModal>
    )
}

const MemberAddressPhoneModalInner = ({ toggle, address, getAddresses, isPrimary, ...props }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { getAddressTypes } = useContext(AddressTypesContext);
    const { currentMember, updateMemberAddressPartial, createMemberAddress, getAllMemberInfos, deleteMemberAddressPhone } = useContext(MembersContext);

    const editMode = Object.keys(address).length > 0;

    /**
     * @param {[object]} [phones]
     * @returns {[]} formatted phones for formik
     */
    const getInitPhones = (phones) => {
        return (phones && phones.length > 0) ?
            phones.map((phone) => ({
                phoneKey: phone.member_address_phone_id,
                memberAddressPhoneId: phone.member_address_phone_id || '',
                phoneNumber: phone.phone_number.split('#')[0],
                phoneExtension: phone.phone_number.split('#')[1],
                phoneTypeId: phone.phone_type.phone_type_id,
            }))
            : isPrimary ?
                [ {
                    phoneKey: "",
                    memberAddressPhoneId: "",
                    phoneNumber: "",
                    phoneExtension: "",
                    phoneTypeId: "",
                } ]
                : [];
    }

    const getPhonesToDelete = (initPhones, newPhones) => {
        return initPhones.reduce((toDelete, phone) => {
            if(!!phone.memberAddressPhoneId && !newPhones.some(({ member_address_phone_id }) => member_address_phone_id === phone.memberAddressPhoneId)){
                toDelete.push(phone.memberAddressPhoneId);
            }

            return toDelete;
        }, []);
    }

    return (
        <Formik
            initialValues={{
                memberAddressId: address.member_address_id,
                address: getFormikAddressInitialValues(address),
                address2: address.unit_number || '',
                defaultAddress: address.default_address == 1,
                status: address.status !== 'CONFIRMED' ? (address.status || 'CONFIRMED') : '',
                addressTypeId: address.address_type?.address_type_id || '',
                years_same_address: address.years_same_address ? moment(address.years_same_address, 'YYYY') : '',
                note: address.note || "",
                phones: getInitPhones(address?.phones),
            }}
            validationSchema={object().shape({
                address: object().address(true, {
                    POBox: <Translate id='form.validation.POBox.required' />,
                    streetNumber: <Translate id='form.validation.streetNumber.required' />,
                    address: <Translate id='form.validation.address.required' />,
                    city: <Translate id='form.validation.city.required' />,
                    zipCode: <Translate id='form.validation.zip.required' />,
                    state: <Translate id='form.validation.province.required' />,
                    country: <Translate id='form.validation.country.required' />,
                }),
                address2: string(),
                addressTypeId: string().required(<Translate id='form.validation.addressType.required' />),
                years_same_address: string().when('doesNotMatter', {
                    is: () => !editMode,
                    then: string().required(<Translate id='form.validation.years_same_address.required' />),
                }),
                note: string().max(255, <Translate id="form.validation.string.max" values={{ count: 255 }} />),
                phones: array().of(object().shape({
                    phoneNumber: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />).required(<Translate id='form.validation.phone.required' />),
                    memberAddressPhoneId: string(),
                    phoneExtension: string(),
                    phoneTypeId: string().required(<Translate id='form.validation.phoneType.required' />),
                })),
            })}
            onSubmit={async(values, { setSubmitting, setStatus }) => {
                const APIValues = {
                    address_type_id: values.addressTypeId,
                    unit_number: values.address2,
                    po_box: values.address.POBox,
                    street_number: values.address.streetNumber,
                    street: values.address.address,
                    map_url: values.address.mapsUrl,
                    country_code: values.address.country,
                    country_division_code: values.address.state,
                    city: values.address.city,
                    zip_code: values.address.zipCode,
                    years_same_address: values.years_same_address ? values.years_same_address.format('YYYY') : '',
                    default_address: values.defaultAddress,
                    status: values.status,
                    origin_address: values.address.origin,
                    address_mode: values.address.addressMode,
                    note: values.note,
                    phones: values.phones.map((phone) => {
                        if(phone.memberAddressPhoneId){
                            return {
                                phone_number: phone.phoneExtension ? (phone.phoneNumber) + '#' + (phone.phoneExtension) : phone.phoneNumber,
                                phone_type_id: phone.phoneTypeId,
                                member_address_phone_id: phone.memberAddressPhoneId,
                            }
                        }
                        return {
                            phone_number: phone.phoneExtension ? (phone.phoneNumber) + '#' + (phone.phoneExtension) : phone.phoneNumber,
                            phone_type_id: phone.phoneTypeId,
                        }
                    }),
                }

                if(!APIValues.status){
                    delete APIValues.status
                }

                const getApiCall = () => {
                    if(editMode){
                        const phonesToDelete = getPhonesToDelete(getInitPhones(address?.phones), APIValues.phones);

                        return Promise.all([
                            ...phonesToDelete.map((toDeleteId) => deleteMemberAddressPhone(currentMember.member_id, values.memberAddressId, toDeleteId).catch((e) => { console.error(e) })),
                            updateMemberAddressPartial(currentMember.member_id, values.memberAddressId, APIValues),
                        ])
                    }

                    return createMemberAddress(currentMember.member_id, APIValues);
                }

                await getApiCall()
                    .then(async() => {
                        await Promise.all([ getAllMemberInfos(currentMember.member_id), getAddresses() ])
                            .then(() => toggle())
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    setSubmitting(false);
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            })
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            setSubmitting(false);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            console.error(error.message);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={() => toggle()}>
                            <Translate id={editMode ? 'members.profile.overview.memberAddressPhone.editModal.title' : 'members.profile.overview.memberAddressPhone.createModal.title'} />
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for='defaultAddress' className="text-muted"><Translate id='members.profile.overview.memberAddressPhone.address.primary' /></Label>
                                <FormikSwitch id='defaultAddress' name='defaultAddress' disabled={formik.initialValues.defaultAddress} />
                            </FormGroup>
                            <FormGroup>
                                <FormikAddress
                                    id='address'
                                    name='address'
                                    required
                                    allowCopyAddress
                                    allowManualPlace
                                    allowOpenInMaps
                                    allowPOBox
                                    label='form.fields.address'
                                    labelClassName='text-muted'
                                />
                            </FormGroup>
                            <Row className='border-bottom mb-3' form>
                                <Col className="mb-3" sm="4">
                                    <Label className="text-muted"><Translate id='form.fields.address' /> 2</Label>
                                    <FormikInputText name='address2' trim />
                                </Col>
                                <Col className="mb-3" sm="4">
                                    <Label className="text-muted"><Translate id='form.fields.addressType' /> <Required /></Label>
                                    <FormikSelect
                                        id='addressTypeId'
                                        name='addressTypeId'
                                        renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return getAddressTypes({ organisation_id: currentMember.organisation?.organisation_id })
                                                        .then((addressTypes) => addressTypes.map((type) => ({
                                                            value: type.address_type_id,
                                                            i18n: type.i18n,
                                                            label: type.name,
                                                        })))
                                            }
                                        }}
                                    />
                                </Col>
                                <Col className="mb-3" sm="4">
                                    <Label className="text-muted"><Translate id='form.fields.years_same_address' /> {!editMode && <Required />}</Label>
                                    <FormikDateTime
                                        dateFormat={'YYYY'}
                                        timeFormat={false}
                                        id='years_same_address'
                                        name='years_same_address'
                                        isValidDate={(current) => {
                                            return moment(current).isSameOrBefore(new Date())
                                        }}
                                    />
                                </Col>
                                <Col sm={12} className="mb-3">
                                    <Label for="note" className="text-muted"><Translate id="form.fields.note" /></Label>
                                    <FormikTextArea
                                        name="note"
                                        id="note"
                                        maxLength={255}
                                    />
                                </Col>
                            </Row>

                            {/* PHONE NUMBERS ASSOCIATED WITH ADDRESS */}
                            <FieldArray name='phones'>
                                {(arrayHelpers) => (
                                    <div>
                                        {formik.values.phones?.map((phone, iPhone) => (
                                            <PhoneSection
                                                key={phone.phoneKey}
                                                helper={arrayHelpers}
                                                index={iPhone}
                                                isPrimary={isPrimary}
                                            />
                                        ))}

                                        {/* ADD PHONE NUMBER */}
                                        <button
                                            className='reset-btn text-link mt-2'
                                            type='button'
                                            onClick={arrayHelpers.handlePush({
                                                phoneKey: generatePassword(),
                                                phoneNumber: '',
                                                phoneExtension: '',
                                                phoneTypeId: '',
                                                phoneType: null,
                                            })}
                                        >
                                            <i className='mdi mdi-plus' /> <Translate id='members.profile.overview.memberAddressPhone.addNumber' />
                                        </button>
                                    </div>
                                )}
                            </FieldArray>

                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                {formik.status &&
                                    <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                }
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' type='submit'><Translate id={address ? 'misc.save' : 'misc.create'} /></Button>
                            <Button color='primary' outline type='button' onClick={() => toggle()}><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

const PhoneSection = ({ index, helper, isPrimary }) => {

    const { getGenericLocale } = useContext(I18nContext);
    const { getPhoneTypes } = useContext(PhoneTypesContext);
    const { currentMember } = useContext(MembersContext);

    const namePrefix = 'phones.' + index;

    return (
        <Collapse appear isOpen>
            <Row form className='align-items-center mb-1'>
                <Col xs='5'>
                    {index === 0 &&
                        <Label className='text-muted' for={`${namePrefix}.phoneNumber`}><Translate id='form.fields.phone' /> <Required /></Label>
                    }
                    {/* PHONE NUMBER */}
                    <FormikPhoneInput name={`${namePrefix}.phoneNumber`} id={`${namePrefix}.phoneNumber`} />
                </Col>
                <Col xs='3'>
                    {/* PHONE EXTENSION */}
                    {index === 0 &&
                        <Label className='text-muted' for={`${namePrefix}.phoneExtension`}>
                            <Translate id='form.fields.extension' />
                        </Label>
                    }
                    <FormikInputNumber name={`${namePrefix}.phoneExtension`} />
                </Col>
                <Col xs='3'>
                    {/* PHONE TYPE */}
                    {index === 0 &&
                        <Label className='text-muted' for={`${namePrefix}.phoneTypeId`}>
                            <Translate id='form.fields.phoneType' /> <Required />
                        </Label>
                    }
                    <FormikSelect
                        id={`${namePrefix}.phoneTypeId`}
                        name={`${namePrefix}.phoneTypeId`}
                        renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                        searchKeys={[
                            `i18n.${getGenericLocale()}.name`,
                        ]}
                        loadData={(from) => {
                            switch (from){
                                case 'CDM':
                                    return getPhoneTypes({ organisation_id: currentMember.organisation?.organisation_id })
                                        .then((phoneTypes) => phoneTypes.map((type) => ({
                                            value: type.phone_type_id,
                                            i18n: type.i18n,
                                            label: type.name,
                                        })))
                            }
                        }}
                    />
                </Col>
                <Col className={index === 0 ? "mt-auto" : ""} xs='1'>
                    {/* REMOVE PHONE  */}
                    <div className={`text-center${index === 0 ? " pb-2" : ""}`}>
                        <button
                            type='button'
                            className='reset-btn'
                            onClick={helper.handleRemove(index)}
                        >
                            <i className='fas fa-trash text-danger' />
                        </button>
                    </div>
                </Col>
            </Row>
        </Collapse>
    )
}

export default MemberAddressPhoneModal