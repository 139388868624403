import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import React from "react";
import {
    Alert,
    Card,
    CardBody,
    Collapse,
    Table
} from "reactstrap";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import { fail } from "@spordle/toasts";
import { ClinicsContext } from "../../../../contexts/ClinicsContext";
import { UtilsContext } from "../../../../contexts/UtilsContext";
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import withContexts from '../../../../helpers/withContexts';
import FormClinicPaymentOptions from './FormClinicPaymentOptions';

class ClinicPaymentOptions extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            edit: false,
            paymentMethods: false,
            paymentOptionError: '',
        };
    }

    toggleEdit = () => { this.setState((prevState) => ({ edit: !prevState.edit })) }

    isLoading = () => {
        return !(!!this.props.ClinicsContext.currentClinic?.clinic_fees && (this.props.ClinicsContext.currentClinic?.merchant_account || !!this.props.ClinicsContext.currentClinic?.accepted_payment_methods))
    }

    setPaymentOptionError = (code) => {
        this.setState({ paymentOptionError: code })
    }

    componentDidMount(){
        this.props.UtilsContext.getPaymentMethods()
            .then((pM) => this.setState({ paymentMethods: pM }))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    render(){
        // Get clinic payment methods that are manual
        let filterPaymentMethods = [];
        if(this.state.paymentMethods){
            filterPaymentMethods = this.state.paymentMethods.filter((pM) => {
                return this.props.ClinicsContext.currentClinic?.accepted_payment_methods.findIndex((paymentMethod) => paymentMethod.code === pM.code) !== -1 && pM.category === "MANUAL";
            })
        }

        return (
            <>
                {!this.state.edit ?
                    <Card className="card-shadow">
                        <OverlayLoader isLoading={this.isLoading()}>
                            <CardBody>
                                <div className="border-bottom pb-1 mb-4 d-flex align-items-end">
                                    <div className="card-title font-bold h4 mb-0"><Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.title' /></div>
                                    {this.props.isHost &&
                                        <CanDoAction className='ml-auto text-link' action='EDIT' componentCode='clinics' componentPermissionCode='clinics_manage'>
                                            <button className="reset-btn" type="button" onClick={this.toggleEdit}><Translate id='misc.edit' /></button>
                                        </CanDoAction>
                                    }
                                </div>

                                <div className="mb-4">
                                    <div className="h5 font-bold mb-3"><Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.fees.title' /></div>
                                    {this.props.ClinicsContext.currentClinic?.clinic_fees?.length === 0 ?
                                        <span className='font-medium'><Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.fees.none' /></span>
                                        :
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th scope='col'><Translate id='form.fields.name' /></th>
                                                    <th scope='col' className='text-center'><Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.fees.table.mandatory' /></th>
                                                    <th className="text-center" scope='col'><Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.fees.table.amount' /></th>
                                                    <th className="text-center" scope='col'><Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.fees.table.taxes' /></th>
                                                    <th className="text-center" scope='col'><Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.fees.table.total' /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.ClinicsContext.currentClinic?.clinic_fees?.map((clinicFee, index) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <tr key={index}>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <DisplayI18n
                                                                        field='name'
                                                                        defaultValue={clinicFee.fee.name}
                                                                        i18n={clinicFee.fee.i18n}
                                                                    />
                                                                </div>
                                                                <div className='text-muted small'>
                                                                    <DisplayI18n
                                                                        field='description'
                                                                        defaultValue={clinicFee.fee.description}
                                                                        i18n={clinicFee.fee.i18n}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='text-center'>{clinicFee.mandatory === '1' && <i className='text-primary mdi mdi-check' />}</td>
                                                        <td className='text-right td-shrink'><CurrencyFormat value={clinicFee.fee.amount / 100} /></td>
                                                        <td className='text-right td-shrink'>{clinicFee.fee.amount_tax ? <CurrencyFormat value={clinicFee.fee.amount_tax / 100} /> : '-' }</td>
                                                        <td className='text-right td-shrink'><CurrencyFormat value={clinicFee.fee.amount_with_tax / 100} /></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    }
                                    <Collapse isOpen={!!this.state.paymentOptionError}>
                                        <Alert color='danger'>{this.state.paymentOptionError}</Alert>
                                    </Collapse>
                                </div>

                                <div className="mb-4">
                                    <div className="h5 font-bold mb-3"><Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.title' /></div>

                                    <div className="h6 font-bold mb-3"><Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.online.title' /><span className='ml-1 text-muted small'>(<Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.online.title.helper' />)</span></div>
                                    <div className='mb-5'>
                                        <span className='font-medium'>
                                            {this.props.ClinicsContext.currentClinic?.merchant_account ?
                                                this.props.ClinicsContext.currentClinic?.merchant_account?.name
                                                :
                                                <Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.online.none' />
                                            }
                                        </span>
                                    </div>

                                    <div className='h6 font-bold mb-3'><Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.other.title' /></div>

                                    {filterPaymentMethods?.length > 0 ?
                                        this.state.paymentMethods &&
                                            <ul className="list-style-none">
                                                {this.state.paymentMethods.filter((pM) => {
                                                    return filterPaymentMethods.findIndex((paymentMethod) => paymentMethod.code === pM.code) !== -1 && pM.category === "MANUAL";
                                                })
                                                    .map((method) => (
                                                        <li className="my-2" key={method.code}>
                                                            <i className="text-primary mdi mdi-check mr-2" />
                                                            <span className="font-medium text-dark">
                                                                <DisplayI18n
                                                                    field="name"
                                                                    defaultValue={method.name}
                                                                    i18n={method.i18n}
                                                                />
                                                            </span>
                                                        </li>
                                                    ))}
                                            </ul>
                                        :
                                        <span className='font-medium'><Translate id='clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.other.none' /></span>
                                    }
                                </div>
                            </CardBody>
                        </OverlayLoader>
                    </Card>
                    :
                    <FormClinicPaymentOptions paymentMethods={this.state.paymentMethods} exited={this.toggleEdit} setPaymentOptionError={this.setPaymentOptionError} />
                }
            </>
        )
    }
}

export default withContexts(ClinicsContext, UtilsContext)(ClinicPaymentOptions);