const MissingDocBadge = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 13.45 16.94"
            width={width}
            height={height}
        >
            <path
                className="fill-current"
                d="M8.37,0H1.49C.68,0,.01,.67,.01,1.49V15.44c-.01,.83,.66,1.5,1.48,1.5H11.96c.82,0,1.49-.67,1.49-1.5V5.08L8.37,0Zm3.84,15.69H1.24V1.24H7.85v3.78c0,.32,.26,.58,.58,.58h3.78V15.69Z"
            />
            <path
                className="fill-current"
                d="M6.33,12.07s-.09-.02-.13-.05c-.04-.03-.06-.08-.06-.13v-.3c.02-.24,.09-.46,.2-.65,.11-.19,.23-.36,.38-.52s.29-.31,.43-.45,.27-.28,.37-.42,.16-.28,.18-.43c.02-.18-.02-.33-.11-.46-.09-.12-.21-.22-.36-.28-.15-.06-.31-.1-.47-.1-.29,0-.52,.07-.71,.21s-.31,.37-.38,.69c-.02,.07-.05,.13-.09,.16-.04,.03-.1,.05-.18,.05h-.78c-.06,0-.1-.02-.14-.06-.04-.04-.06-.09-.06-.16,0-.25,.06-.49,.17-.72,.11-.23,.26-.44,.47-.63,.21-.19,.46-.33,.75-.44,.29-.11,.63-.16,1.01-.16,.42,0,.77,.05,1.05,.16,.28,.11,.51,.25,.68,.42,.17,.17,.29,.36,.36,.56,.07,.2,.11,.4,.1,.59-.01,.24-.06,.45-.16,.63-.09,.19-.21,.36-.34,.52-.14,.16-.27,.31-.41,.46-.14,.15-.27,.31-.37,.46-.11,.15-.18,.32-.22,.49-.01,.06-.02,.11-.03,.16,0,.05-.01,.1-.02,.15-.02,.07-.06,.13-.09,.17-.04,.04-.09,.06-.16,.06h-.9Zm-.03,1.75c-.06,0-.11-.02-.16-.07s-.06-.1-.06-.16v-.81c0-.07,.02-.12,.06-.17,.04-.04,.09-.06,.16-.06h.89c.07,0,.12,.02,.17,.06,.04,.04,.06,.1,.06,.17v.81c0,.06-.02,.11-.06,.16-.04,.04-.1,.07-.17,.07h-.89Z"
            />
        </svg>
    );
}

export default MissingDocBadge;