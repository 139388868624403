export default{
    "members.search.memberAdd.title"            : "Ajouter un membre",
    "members.search.memberAddInleligible.title" : "Ajouter un membre inéligible",

    "members.search.memberAdd.foundSearchResults"     : "Nous avons trouvé {amount, plural, one {un membre similaire} other {des membres similaires}}",
    "members.search.memberAdd.createMember"           : "Créer un nouveau membre",
    "members.search.memberAdd.createWithName"         : "Créer {firstName} {lastName}",
    "members.search.memberAdd.primaryOrganization"    : "Organisation Primaire",
    "members.search.memberAdd.dateOfBirth.range"      : "La date doit représenter un âge entre 4 et 100 ans",
    "members.search.memberAdd.dateOfBirth.minAge"     : "Un membre doit avoir au moins 4 ans pour participer. Cependant, vous pouvez toujours créer ce membre.",
    "members.search.memberAdd.dateOfBirth.minDate"    : "La date de naissance du membre doit être avant aujourd'hui",
    "members.search.memberAdd.firstName.regex"        : "Le prénom doit être d'un bon format",
    "members.search.memberAdd.lastName.regex"         : "Le nom doit être d'un bon format",
    "members.search.memberAdd.reviewPreviousStep"     : "Revoir l'étape précédente",
    "members.search.memberAdd.confirmUser"            : "Confirmer le membre",
    "members.search.memberAdd.confirmUser.subtitle"   : "Ce membre sera marqué comme confirmé",
    "members.search.memberAdd.unconfirmUser"          : "Rendre le membre non-confirmé",
    "members.search.memberAdd.unconfirmUser.subtitle" : "Ce membre ne sera pas confirmé. Il peut être confirmé sur la page de profil du membre",

    "members.search.memberAdd.potentially.ineligible"   : "Ce membre sera signalé comme « Potentiellement Inéligible »",
    "members.search.memberAdd.perfect.match.ineligible" : "Le membre que vous créez contient des informations identiques à un membre inéligible. Ce membre sera créé avec le statut « {status} ».",

    "members.search.memberAdd.primaryLanguage.helper" : "Indiquer dans laquelle des langues officielles du Canada, français ou anglais, vous préférez recevoir vos correspondances.",

    "members.search.memberAdd.btn.profile"   : "Profil",
    "members.search.memberAdd.btn.transfer"  : "{transfer_title}",
    "members.search.memberAdd.btn.createNew" : "Créer un nouveau membre",

    "members.search.memberAdd.success"        : "Membre ajouté avec succès",
    "members.search.memberAdd.error"          : "Une erreur s'est produite lors de l'ajout de ce membre",
    "members.search.memberAdd.error.2000.btn" : "Cliquez ici pour éditer le prénom et le nom de ce membre.",

    // Member Import
    "members.search.memberImport.title" : "Importer en lot",
}