import { FormikDateTime, FormikInputText, FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, Button, Col, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { object, mixed, string } from 'yup';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import Required from '../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { ClinicsContext } from '../../../../../../contexts/ClinicsContext';
import { success, fail } from '@spordle/toasts';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

const AttendeeModalCertify = (props) => {
    const clinicsContext = useContext(ClinicsContext);

    return (
        <AnalyticsModal isOpen={props.isOpen} toggle={props.toggle} analyticsName='AttendeeModalCertify'>
            <ModalHeader toggle={props.toggle}>
                <Translate id='clinics.profile.attendees.sidePanelActions.certify.title' />
            </ModalHeader>

            <Formik
                initialValues={{
                    certifiedDate: moment(),
                    certificationNumber: '',
                    expirationDate: '',
                    memo: '',
                }}
                validationSchema={object().shape({
                    certifiedDate: mixed().required(<Translate id='clinics.profile.attendees.sidePanelActions.certify.certification.required' />)
                        .test({
                            name: 'certificationDateFormat',
                            message: <Translate id='form.validation.date.format' />,
                            test: moment.isMoment,
                        }).test({
                            name: 'beforeExpiration',
                            message: <Translate id='clinics.profile.attendees.sidePanelActions.certify.certification.before' />,
                            test: function(certificationDate){
                                if(this.parent.expirationDate){
                                    return moment(certificationDate).isBefore(moment(this.parent.expirationDate))
                                }
                                return true
                            },
                        }).test({
                            name: 'afterPassed',
                            message: <Translate id='clinics.profile.attendees.sidePanelActions.certify.certification.after' />,
                            test: (certificationDate) => {
                                return moment(certificationDate).isAfter(moment(props.panelProps.getCommonValue('passed_date')).subtract(1, 'day'))
                            },
                        }),
                    certificationNumber: string(),
                    memo: string(),
                    expirationDate: mixed()
                        .test({
                            name: 'expirationDateFormat',
                            message: <Translate id='form.validation.date.format' />,
                            test: (date) => {
                                if(date){
                                    return moment.isMoment(date)
                                }
                                return true // not required
                            },
                        }).test({
                            name: 'afterCertification',
                            message: <Translate id='clinics.profile.attendees.sidePanelActions.certify.expiration.after' />,
                            test: function(expirationDate){
                                if(this.parent.certificationDate){
                                    return moment(this.parent.certificationDate).isBefore(moment(expirationDate))
                                }
                                return true
                            },
                        }),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    clinicsContext.certifyClinicAttendee(clinicsContext.currentClinic.clinic_id, props.panelProps.getCommonValue('clinic_attendee_id'), {
                        ...values,
                        certificationDate: values.certifiedDate.format('YYYY-MM-DD'),
                        expirationDate: values.expirationDate ? values.expirationDate.format('YYYY-MM-DD') : '',
                    })
                        .then(() => {
                            props.toggle();
                            props.panelProps.syncRows(props.panelProps.createNewValues({ certified: '1', certified_date: values.certifiedDate.format('YYYY-MM-DD') }))
                            setSubmitting(false);
                            success()
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                setSubmitting(false);
                                setStatus('error')
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form id='attendee-invoice-form'>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalBody>
                                <Row form>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label for='certifiedDate' className='text-muted'><Translate id='clinics.profile.attendees.sidePanelActions.certify.certification.label' /> <Required /></Label>
                                            <FormikDateTime
                                                id='certifiedDate'
                                                name='certifiedDate'
                                                timeFormat={false}
                                                dateFormat='YYYY-MM-DD'
                                                renderInput={(props) => (
                                                    <FormattedMessage id='form.fields.date.helper'>
                                                        {(message) => (
                                                            <div className='search-wrapper'>
                                                                <input {...props} placeholder={message} />
                                                                <button type='button' className='reset-btn search-clear' disabled><i className='mdi mdi-calendar' /></button>
                                                            </div>
                                                        )}
                                                    </FormattedMessage>
                                                )}
                                                isValidDate={(current) => {
                                                    const expirationValid = formik.values.expirationDate ? moment(current).isBefore(moment(formik.values.expirationDate)) : true;
                                                    const passedDateValid = moment(current).isAfter(moment(props.panelProps.getCommonValue('passed_date')).subtract(1, 'day'));
                                                    return expirationValid && passedDateValid
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label for='expirationDate' className='text-muted'><Translate id='clinics.profile.attendees.sidePanelActions.certify.expiration.label' /></Label>
                                            <FormikDateTime
                                                id='expirationDate'
                                                name='expirationDate'
                                                className='date-picker-left'
                                                timeFormat={false}
                                                dateFormat='YYYY-MM-DD'
                                                renderInput={(props) => (
                                                    <FormattedMessage id='form.fields.date.helper'>
                                                        {(message) => (
                                                            <div className='search-wrapper'>
                                                                <input {...props} placeholder={message} />
                                                                <button type='button' className='reset-btn search-clear' disabled><i className='mdi mdi-calendar' /></button>
                                                            </div>
                                                        )}
                                                    </FormattedMessage>
                                                )}
                                                isValidDate={(current) => {
                                                    const certificationValid = formik.values.certifiedDate ? moment(formik.values.certifiedDate).isBefore(moment(current)) : true;
                                                    const passedDateValid = moment(current).isAfter(moment(props.panelProps.getCommonValue('passed_date')).subtract(1, 'day'));
                                                    return certificationValid && passedDateValid
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form className='mb-3'>
                                    <Col sm='6'>
                                        <Label for='certificationNumber' className='text-muted'><Translate id='clinics.profile.attendees.sidePanelActions.certify.certificationNumber' /></Label>
                                        <FormikInputText id='certificationNumber' name='certificationNumber' trim />
                                    </Col>
                                    <Col sm='6'>
                                        <Label for='memo' className='text-muted'><Translate id='clinics.profile.attendees.sidePanelActions.certify.memo' /></Label>
                                        <FormikTextArea id='memo' name='memo' rows={3} trim />
                                    </Col>
                                </Row>
                                <Collapse isOpen={formik.status === 'error'}>
                                    <Alert color='danger' toggle={() => formik.setStatus('')}><Translate id='misc.error' /></Alert>
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.confirm' /></Button>
                                <Button color='primary' type='button' outline onClick={props.toggle} disabled={formik.isSubmitting} className='ml-2'><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    )
}

export default AttendeeModalCertify;