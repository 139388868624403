import Translate from '@spordle/intl-elements';
import {
    Button,
    ModalBody
} from "reactstrap";
import icon from '../../../../../../assets/images/PlayersAddedToRoster.png'

const TeamRosterAddConfirmation = ({ from, toggle }) => {
    return (
        <ModalBody className="text-center">
            <img className="mb-3 mt-4" src={icon} alt="Player Added" width="150" />
            <div className="h4 font-bold mb-5">
                {from === 'bench' ?
                    <Translate id='teams.profile.teamRoster.activeRoster.add.confirmation.staff' />
                    :
                    <Translate id='teams.profile.teamRoster.activeRoster.add.confirmation.players' />
                }
            </div>
            <Button color='primary' onClick={toggle}><Translate id='teams.profile.teamRoster.activeRoster.add.confirmation.button' /></Button>
        </ModalBody>
    );
}

export default TeamRosterAddConfirmation;