import Translate from "@spordle/intl-elements";
import ConfigurationCountPerResource from "./ConfigurationCountPerResource";
import { useTeamPositionsSWR } from "../../../../../../api/useSWRHelpers/positionsSWR";

const ConfigurationSettingsFormPositions = ({ positionSetting }) => {
    const { data: positions } = useTeamPositionsSWR();

    return (
        <ConfigurationCountPerResource
            name="count_per_position"
            setting={positionSetting}
            label={<Translate id="settings.teamsSettings.position" />}
            options={positions?.map((pos) => ({
                ...pos,
                value: pos.position_id,
                label: pos.name,
            }))}
        />
    )
}

export default ConfigurationSettingsFormPositions;