import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { useContext } from "react";
import { DisplayI18n, displayI18n } from "../../../../../../helpers/i18nHelper";
import { Skeleton } from "@mantine/core";
import { getSettingVal } from "../teamSettingsConfigurationHelper";
import Translate from "@spordle/intl-elements";
import SettingLabel from "../../../../components/SettingLabel";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { useTeamPositionsSWR } from "../../../../../../api/useSWRHelpers/positionsSWR";

const ConfigurationSettingsViewPosition = () => {
    const { settings } = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);
    const countPerPosition = settings.count_per_position;
    const { data, isValidating } = useTeamPositionsSWR();
    const isLoading = !data && isValidating;
    const positions = (data || []).reduce((formatted, position) => {
        formatted[position.position_id] = position;
        return formatted;
    }, {})

    const values = getSettingVal(countPerPosition?.value)
        .sort(([ idA ], [ idB ]) => {
            const a = positions[idA];
            const b = positions[idB];

            const nameA = displayI18n("name", a?.i18n, a?.name, getGenericLocale()) || "";
            const nameB = displayI18n("name", b?.i18n, b?.name, getGenericLocale()) || "";

            return nameA.localeCompare(nameB);
        });

    if(!countPerPosition){
        return null;
    }

    return (
        <div className="mb-3">
            <SettingLabel setting={countPerPosition} code="count_per_position" />
            {isLoading ?
                <ul className="mt-1 pl-3 mb-0"><li className="line-height-1"><Skeleton height="1em" width="15ch" /></li></ul>
                :
                (values || []).length === 0 ?
                    <div className="mt-1 text-muted"><Translate id="settings.teamsSettings.configuration.noLimit" /></div>
                    :
                    positions && Array.isArray(values) &&
                        <ul className="mt-1 pl-3 text-dark mb-0">
                            {values.map(([ posId, count ]) => {
                                const position = positions[posId];

                                if(!position){ // No need to show positions that aren't used in the current org.
                                    return null;
                                }

                                return (
                                    <li key={posId}>
                                        <span className="mr-1">
                                            <DisplayI18n field="name" i18n={position.i18n} defaultValue={position.name} />:
                                        </span>
                                        <b>{count}</b>
                                    </li>
                                )
                            })}
                        </ul>
            }
        </div>
    );
}

export default ConfigurationSettingsViewPosition;