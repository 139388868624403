import { FormikDateTime, FormikInputText } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Button, Col, Collapse, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import Required from "../../../../../../components/formik/Required";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { object, string, mixed } from 'yup';
import moment from "moment";
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserImg from '../../../../../../components/UserImg';
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { fail } from "@spordle/toasts";
import { getLocation } from "../../../../../teams/TeamHelpers";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";

const AddInsuranceModalMemberSearch = ({ onMemberSelect, setIsLoading, cancel, children, previous }) => {
    const { getMembers } = useContext(MembersContext);
    const [ results, setResults ] = useState(null);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const canManageIneligible = canDoAction('READ', 'members', 'manage_ineligible_members') || isGod();

    return (
        <Formik
            initialValues={{
                first_name: '',
                last_name: '',
                birthdate: '',
                unique_identifier: '',
            }}
            validationSchema={object().shape({
                first_name: string().test({
                    name: 'isRequired',
                    message: <Translate id='form.validation.firstName.required' />,
                    test: function(input){
                        return !!this.parent.unique_identifier || !!input;
                    },

                }),
                last_name: string().test({
                    name: 'isRequired',
                    message: <Translate id='form.validation.lastName.required' />,
                    test: function(input){
                        return !!this.parent.unique_identifier || !!input;
                    },
                }),
                birthdate: mixed().test({
                    name: 'isValid',
                    message: <Translate id='form.validation.dateOfBirth.valid' />,
                    test: function(date){
                        return !date || (moment.isMoment(date) && moment(date).isSameOrBefore(moment()));
                    },
                }),
                unique_identifier: string(),
            })}
            onSubmit={(values) => {
                setIsLoading(true);
                let apiVal = {
                    all: 1,
                    with_address: 1,
                };


                if(values.unique_identifier){
                    apiVal.unique_identifier = values.unique_identifier;
                }else{
                    apiVal = { ...values, all: 1, with_address: 1 };
                    if(values.birthdate && moment.isMoment(values.birthdate)){
                        apiVal.birthdate = moment(values.birthdate).format('YYYY-MM-DD')
                    }
                    delete apiVal.birthdate;
                }

                getMembers(apiVal)
                    .then((members) => {
                        setIsLoading(false);
                        setResults(members.filter((member) => {
                            const lowerStatusName = (member.member_status?.name || "").toLowerCase();

                            return !!lowerStatusName && lowerStatusName !== "draft" && (lowerStatusName !== "ineligible" || canManageIneligible) && member.unique_identifier
                        }));
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setIsLoading(false);
                            setResults([]);
                        }
                    });
            }}
        >
            {(formik) => (
                <Form id="searchMemberInfo">
                    <ModalBody>
                        {children}
                        <Row form>
                            <Col className="form-group" sm="6">
                                <Label className="text-muted" for="first_name"><Translate id="form.fields.firstName" /> {!formik.values.unique_identifier && <Required />}</Label>
                                <FormikInputText disabled={!!formik.values.unique_identifier} name="first_name" trim />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label className="text-muted" for="last_name"><Translate id="form.fields.lastName" /> {!formik.values.unique_identifier && <Required />}</Label>
                                <FormikInputText disabled={!!formik.values.unique_identifier} name="last_name" trim />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label className="text-muted" for="birthdate"><Translate id="form.fields.dateOfBirth" /></Label>
                                <FormikDateTime
                                    id='birthdate'
                                    name='birthdate'
                                    timeFormat={false}
                                    dateFormat='YYYY-MM-DD'
                                    inputProps={{ disabled: !!formik.values.unique_identifier }}
                                    isValidDate={(current) => moment(current).isBefore(moment())}
                                />
                            </Col>
                            <Col sm="12">
                                <div className="d-flex align-items-center mb-3">
                                    <div className="border-top flex-grow-1" />
                                    <div className="px-3 font-medium small"><Translate id='clinics.profile.attendees.addModal.memberSelect.or' /></div>
                                    <div className="border-top flex-grow-1" />
                                </div>
                            </Col>
                            <Col sm="12">
                                <Label className="text-muted" for="unique_identifier"><Translate id='clinics.profile.attendees.addModal.memberSelect.memberNumber' /></Label>
                                <FormikInputText
                                    helper={
                                        <small className="text-muted">
                                            <i className="text-primary mdi mdi-information-outline" /> <Translate id='clinics.profile.attendees.addModal.memberSelect.memberNumber.hint' />
                                        </small>
                                    }
                                    translateHelper={false}
                                    name="unique_identifier"
                                    trim
                                />
                            </Col>
                        </Row>
                        <Collapse className="mt-3" isOpen={results !== null}>
                            {results && results.length > 0 ?
                                <div className="border-top">
                                    <div className="text-muted my-2"><Translate id='misc.results' /></div>
                                    <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }}>
                                        {results?.map((member) => (
                                            <div key={member.member_id}>
                                                <UserDisplay className="mb-2 w-100" card>
                                                    <UserDisplay.Container>
                                                        <UserImg
                                                            width={45}
                                                            abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                                            alt={member.first_name + ' ' + member.last_name}
                                                            src={member.picture?.full_path}
                                                            filePos={member.picture?.file_position}
                                                        />
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container>
                                                        <UserDisplay.Title>{member.first_name + ' ' + member.last_name}</UserDisplay.Title>
                                                        <UserDisplay.Subtitle>#{member.unique_identifier}</UserDisplay.Subtitle>
                                                        <UserDisplay.Subtitle>{member.birthdate} (<Translate id='misc.yearsOld' values={{ age: member.age }} />)</UserDisplay.Subtitle>
                                                        <UserDisplay.Subtitle>
                                                            <DisplayI18n
                                                                field="name"
                                                                defaultValue={member.organisation?.organisation_name}
                                                                i18n={member.organisation?.i18n}
                                                            />
                                                        </UserDisplay.Subtitle>
                                                        <UserDisplay.Subtitle>{getLocation(member.address || {})}</UserDisplay.Subtitle>
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container className="ml-auto">
                                                        <Button
                                                            size="sm"
                                                            onClick={() => {
                                                                onMemberSelect(member)
                                                                setResults(null);
                                                            }}
                                                            color="primary"
                                                            type="button"
                                                        >
                                                            <Translate id='misc.select' />
                                                        </Button>
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            </div>
                                        ))}
                                    </PerfectScrollbar>
                                </div>
                                :
                                <div className="text-center">
                                    <Translate id='misc.search.empty.title' />
                                </div>
                            }
                        </Collapse>
                    </ModalBody>
                    <ModalFooter>
                        {!!previous &&
                            <Button className="mr-auto" color="primary" outline onClick={previous}><Translate id="misc.previous" /></Button>
                        }
                        <Button color="primary" type="submit"><Translate id="misc.search" /></Button>
                        {!!cancel &&
                            <Button color="primary" type="button" outline onClick={cancel}><Translate id="misc.cancel" /></Button>
                        }
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

export default AddInsuranceModalMemberSearch;