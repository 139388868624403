import { stringBuilder } from "@spordle/helpers";
import { useContext, useEffect, useRef, useState } from "react";
import { CardBody, CustomInput } from "reactstrap";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import styles from "./MessagePreview.module.scss";
import { InboxContext } from "../inboxContext/InboxContext";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { Tooltip } from "@mantine/core";
import { MessagesContext } from "../../../../contexts/MessagesContext";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import BlinkDot from "../../../../components/blinkDot/BlinkDot";
import { useSpordleTable } from "@spordle/datatables";
import { RolesContext } from "../../../../contexts/RolesContext";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import useLongPress from "../../../../components/customHooks/useLongPress";
import { AppContext } from "../../../../contexts/contexts";
import MobileCheckbox from "./MobileCheckbox";
import { useHistory } from "react-router-dom";
import { removeHTML } from "../../../../helpers/helper";

const MessagePreview = ({ onClick, hideTools, msg, isChecked, checkMsg, uncheckMsg }) => {
    const wasSelected = useRef(false);
    const [ isUpdating, setIsUpdating ] = useState(false);
    const { updateDataWith, generateId } = useSpordleTable();
    const { partiallyUpdateMessage } = useContext(MessagesContext);
    const { selectedMsg, setShowTools, showTools } = useContext(InboxContext);
    const { isMobileDevice } = useContext(AppContext);
    const isSelected = selectedMsg?.message_id === msg.message_id;
    const tableId = generateId(`checked-${msg.message_id}-${msg.communication_message_id}`);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const canMarkAsRead = canDoAction("EDIT", "communications", "communications_manage") || isGod();
    const history = useHistory();

    const toggleCheckMsg = () => {
        if(isChecked){
            uncheckMsg();
        }else{
            checkMsg();
        }
    }

    const handleOnClick = () => {
        if((!isMobileDevice || !showTools)){
            onClick();

            if(msg.is_read == 0 && !isUpdating && canMarkAsRead && !hideTools){ // hideTools is used when no actions can be done on the comm, therefor we can't read/unread message
                setIsUpdating(true);

                partiallyUpdateMessage(msg.communication_message_id, { is_read: 1 })
                    .then(() => {
                        updateDataWith(msg.message_id, { is_read: "1" })
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                        }
                    })
                    .finally(() => {
                        setIsUpdating(false);
                    })
            }
        }else{
            toggleCheckMsg();
        }
    }

    const { isLongPressing: _, ...longPressProps } = useLongPress(
        () => {
            if(!showTools && isMobileDevice && !hideTools){
                checkMsg();
                setShowTools(true);

                history.push({
                    hash: "manage",
                });
            }
        },
        handleOnClick,
        {
            shouldPreventDefault: true,
            delay: 500,
        },
    );

    useEffect(() => {
        if(wasSelected.current && isSelected){
            updateDataWith(msg.message_id, selectedMsg);
        }

        wasSelected.current = selectedMsg?.message_id === msg.message_id;
    }, [ selectedMsg ]);

    return (
        <CardBody
            {...longPressProps}
            tag="section"
            className={stringBuilder(
                "border-bottom px-3 d-flex align-items-center",
                styles.Container,
                isMobileDevice ? "py-3" : "py-2",
                { [styles.Active]: isSelected },
                { [styles.Unread]: msg.is_read == 0 },
            )}
        >
            {canMarkAsRead && !hideTools && (
                isMobileDevice ?
                    <MobileCheckbox
                        id={tableId}
                        name={tableId}
                        checked={isChecked}
                        onChange={toggleCheckMsg}
                    />
                    :
                    <div className="mr-1 position-relative">
                        <Tooltip withArrow={false} position="bottom" label={<small><Translate id="misc.select" /></small>}>
                            <CustomInput
                                id={tableId}
                                name={tableId}
                                checked={isChecked}
                                onChange={toggleCheckMsg}
                                type="checkbox"
                            />
                        </Tooltip>
                    </div>
            )}
            <div className="w-100 min-w-0">
                <UserDisplay.Title className="align-items-center d-flex mb-1 w-100">
                    {msg.is_read == 0 && <BlinkDot className="mr-2" />}
                    <div className={stringBuilder("text-truncate mr-2", styles.Title)}>
                        {msg.favorite == 1 && <i className="mdi mdi-star mr-1 text-warning" />}{msg.title}
                    </div>
                    <time className="ml-auto text-body text-nowrap small"><DateFormat value={msg.created_at} /></time>
                </UserDisplay.Title>
                <UserDisplay.Subtitle className="d-flex">
                    <div className={stringBuilder("min-w-0 text-truncate", styles.Content)}>
                        {removeHTML(msg.body)}
                    </div>
                </UserDisplay.Subtitle>
            </div>
        </CardBody>
    );
}

export default MessagePreview;