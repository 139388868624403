import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    ModalHeader
} from "reactstrap";
import TeamRosterAddSearch from './components/teamRosterAddSearch/TeamRosterAddSearch';
import TeamRosterAddReview from './components/TeamRosterAddReview';
import TeamRosterAddConfirmation from './components/TeamRosterAddConfirmation';
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';

export const SEARCH_NAME = 1;
export const SEARCH_TEAM = 2;
export const SEARCH_BIRTHDATE = 3;
export const SEARCH_BRANCH = 4;

/**
 * @param {Object} props
 * @param {boolean} props.isOpen Modal is open or not
 * @param {function} props.toggle Toggle modal
 * @param {'affiliated'|'active'|'bench'} [props.from="active"] // Where the modal is from
 */
const TeamRosterAdd = ({ isOpen, from = "active", toggle }) => {
    const { cachedTeam } = useContext(TeamsContext);
    const [ selectedMembers, setSelectedMembers ] = useState([]);

    /**
     * Adds a member to selected members list
     * @param {object} member
     * @returns {void}
     */
    const addMember = (member) => {
        if(!selectedMembers.find((m) => m.member_id == member.member_id)){
            setSelectedMembers((prev) => {
                const newArr = [ ...prev, member ];
                return newArr;
            });
        }
    }

    /**
     * Remove a selected member from list
     * @param {string} memberId Member id to remove
     * @returns {void}
     */
    const removeMember = (memberId) => {
        setSelectedMembers((prev) => {
            const newArr = prev.filter((m) => m.member_id !== memberId);
            return newArr;
        });
    }

    /**
     * Empties selected members list
     * @returns {void}
     */
    const emptyMembers = () => {
        setSelectedMembers([]);
    }

    // 0 = search, 1 = approval, 2 = confirmation
    const [ steps, setSteps ] = useState(0);

    const nextStep = () => {
        if(steps < 2) setSteps(steps + 1);
    }

    const previousStep = () => {
        if(steps > 0) setSteps(steps - 1);
    }

    return (
        <AnalyticsModal
            analyticsName="TeamRosterAdd"
            isOpen={isOpen}
            // only confirmation step needs to be normal size
            size={steps != 2 ? "xl" : null}
            onClosed={() => {
                setSteps(0);
                emptyMembers();
            }}
        >
            <ModalHeader tag='div' toggle={toggle}>
                <Translate id='teams.profile.teamRoster.activeRoster.add.title' /> {cachedTeam.name}
            </ModalHeader>

            {/*--------------------------------------------------------------------------------*/}
            {/* Search Result (table)*/}
            {/*--------------------------------------------------------------------------------*/}
            {steps === 0 &&
                <TeamRosterAddSearch
                    from={from}
                    selected={selectedMembers}
                    removeMember={removeMember}
                    addMember={addMember}
                    toggle={toggle}
                    nextStep={nextStep}
                />
            }
            {steps === 1 &&
                <TeamRosterAddReview
                    from={from}
                    emptyMembers={emptyMembers}
                    removeMember={removeMember}
                    setSelectedMembers={setSelectedMembers}
                    selected={selectedMembers}
                    toggle={toggle}
                    previousStep={previousStep}
                    nextStep={nextStep}
                />
            }
            {steps === 2 &&
                <TeamRosterAddConfirmation
                    from={from}
                    toggle={toggle}
                />
            }
        </AnalyticsModal>
    );
}

export default TeamRosterAdd;