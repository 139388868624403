import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Badge, Button, Card, DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledButtonDropdown } from "reactstrap";
import SpordlePanelTable from "../../../../../../components/sidePanel/SpordlePanel";
import { getIcon } from "../../../../../../components/uploader/uploadHelpers";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { DocumentTypesContext } from "../../../../../../contexts/DocumentTypesContext";
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper";
import ModalAddMemberDocuments from "./ModalAddMemberDocuments";
import SidePanelMemberDocuments from "./SidePanelMemberDocuments";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import { stringBuilder } from "@spordle/helpers";
import MemberDocumentsTitle from "./MemberDocumentsTitle";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import SpordleSelect from "@spordle/spordle-select";
import { parseJSON, removeHTML } from "../../../../../../helpers/helper";

const MemberDocuments = ({ refreshMemberProfile }) => {
    const { getMemberAttachments, currentMember, shouldAttemptUploadProfilePicDocument } = useContext(MembersContext);
    const { getDocumentTypes } = useContext(DocumentTypesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const organizationContext = useContext(OrganizationContext);

    const [ isOpen, setIsOpen ] = useState(false);
    const [ documentTypes, setDocumentTypes ] = useState(null);

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        getDocumentTypes()
            .then(setDocumentTypes)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    const getBadgeColour = (status) => {
        switch (status){
            case 'PENDING':
                return 'warning';
            case 'REJECTED':
                return 'danger';
            case 'APPROVED':
                return 'success';
            case 'NO_VALIDATION':
                return 'info';
            default:
                return 'danger';
        }
    }

    const documentTypesNeedingApproval = organizationContext.settings.system_document_type_need_approbation.value ? Object.keys(parseJSON(organizationContext.settings.system_document_type_need_approbation.value)) : [];

    const getDocumentColumns = (typesNeedingApproval) => {
        const baseColumns = [
            {
                key: 'attachment',
                label: <Translate id='members.profile.overview.memberDocuments.form.label.document' />,
                className: 'w-50',
                sortKey: 'created_at',
                sortable: true,
                mobile: true,
            },
            {
                key: 'document_type',
                label: <Translate id='members.profile.overview.memberDocuments.form.label.documentType' />,
                className: 'w-50',
                sortKey: 'document_type.name',
                sortable: true,
                mobile: true,
            },
        ];
        if(typesNeedingApproval.length > 0)
            baseColumns.push({
                key: 'review_status',
                label: <Translate id='members.profile.overview.memberDocuments.form.label.review_status' />,
                className: 'w-50',
                sortable: true,
                mobile: true,
            })
        return baseColumns;
    }

    useEffect(() => {
        if(shouldAttemptUploadProfilePicDocument)
            setIsOpen(true)
    }, [ shouldAttemptUploadProfilePicDocument ])

    return (
        <Card id="profileComponentMemberDocuments" body className={stringBuilder("card-shadow", { "card-error shadow-danger": (currentMember?.need_address_document == 1) })}>
            <SpordlePanelTable
                id='member_documents_panel_table'
                allowOutsideClick
                sidePanel={(props) => <SidePanelMemberDocuments documentTypesNeedingApproval={documentTypesNeedingApproval} getBadgeColour={getBadgeColour} documentTypes={documentTypes} {...props} />}
                dataIndex='member_attachement_id'
                table={(panelProps) => (
                    <SpordleTableProvider
                        id='member_documents_table'
                        ref={panelProps.spordleTableRef}
                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                        tableHover clickable striped
                        bordered
                        dataIndex='member_attachement_id'
                        columns={getDocumentColumns(documentTypesNeedingApproval)}
                        searchKeys={[
                            { name: 'attachment.file_name', weight: 2 },
                            { name: 'attachment.file_ext', weight: 1.5 },
                            `document_type.i18n.${getGenericLocale()}.name`,
                            'document_type.name',
                            `document_type.i18n.${getGenericLocale()}.description`,
                            'document_type.description',
                            'created_at',
                        ]}
                        desktopWhen='md'
                        pagination={5}
                        renderRow={(key, doc) => {
                            switch (key){
                                case 'attachment':
                                    return (
                                        <>
                                            <div className="font-medium"><i className={getIcon(doc.attachment.file_ext)} /> {doc.attachment.file_name}</div>
                                            <div className="small d-flex">
                                                <DateFormat value={doc.created_at} utc />
                                            </div>
                                        </>
                                    )
                                case 'document_type':
                                    const desc = doc.document_type ? displayI18n('description', doc.document_type?.i18n, doc.document_type?.description, getGenericLocale()) : null;
                                    return (doc.document_type ?
                                        <>
                                            <div className="font-medium">
                                                <DisplayI18n field='name' defaultValue={doc.document_type.name} i18n={doc.document_type.i18n} />
                                            </div>
                                            {desc &&
                                                <div
                                                    style={{ maxWidth: 300 }}
                                                    className="text-truncate"
                                                >
                                                    {removeHTML(desc)}
                                                </div>
                                            }
                                        </>
                                        :
                                        <Translate id='misc.other' />
                                    )
                                case 'review_status':
                                    return (
                                        <div className="text-center">
                                            {doc.document_type && documentTypesNeedingApproval.includes(doc.document_type?.system) ?
                                                <Badge color={getBadgeColour(doc.review_status)}>
                                                    <Translate id={`task.document.approval.status.${doc.review_status}`} />
                                                </Badge>
                                                :
                                                "-"
                                            }
                                        </div>
                                    )
                                case 'expiration_date':
                                    return (
                                        <div className="text-center">
                                            {doc.expiration_date ?
                                                <DateFormat value={doc.expiration_date} />
                                                :
                                                "-"
                                            }
                                        </div>
                                    )
                                default:
                                    break;
                            }
                        }}
                        onColumnClick={(e, doc) => {
                            switch (e.button){
                                case 0: // Left mouse button
                                    panelProps.onSingleSelectChange(doc)
                                    break;
                            }
                        }}
                        rowIsHighlighted={(doc) => !!doc.checked}
                        loadData={(from, { filters }, spordleTable) => {
                            switch (from){
                                case 'REFRESH':
                                    spordleTable.setLoading();
                                case 'CDM':
                                case 'FILTER':
                                    return getMemberAttachments()
                                        .then((attachments) => {
                                            // checks if any of the documents have expiration dates
                                            // if they do and the column is not already present, insert an expiration date column
                                            // othewise do not insert or display expiration date column
                                            if(attachments.some((document) => !!document.expiration_date) && !spordleTable.getColumns().some((column) => column.key === 'expiration_date')){
                                                spordleTable.insertColumnsAt({
                                                    key: 'expiration_date',
                                                    label: <Translate id='members.profile.overview.memberDocuments.form.label.expiration_date' />,
                                                    sortable: true,
                                                    mobile: true,
                                                }, 2)
                                            }

                                            return (attachments
                                                .sort((a, b) => a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0)
                                                .filter((doc) => filters.reviewStatus ? doc.review_status === filters.reviewStatus : true))
                                        })
                                default:
                                    break;
                            }
                        }}
                        emptyLayout={
                            <Table bordered striped hover>
                                <thead>
                                    <tr>
                                        <th className="w-50"><Translate id='form.fields.name' /></th>
                                        <th className="w-50"><Translate id='form.fields.description' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={2} className='text-center w-50'>
                                            <div><Translate id='members.profile.overview.memberDocuments.empty.helper' /></div>
                                            <button className='font-medium reset-btn text-primary' type='button' onClick={toggle}><i className='mdi mdi-plus' /><Translate id='members.profile.overview.memberDocuments.add.btn' /></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        }
                    >{(spordleTable) => (
                            <>
                                <MemberDocumentsTitle refreshMemberProfile={refreshMemberProfile} />
                                <div className='mb-2'>
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <SearchInput />
                                        <div className="w-25 ml-2">
                                            <SpordleSelect
                                                id='documentApprovalStatusFilterSelect'
                                                loadingStatus="success"
                                                placeholder="members.profile.overview.memberDocuments.form.label.review_status"
                                                clearable
                                                onOptionSelected={(option) => {
                                                    spordleTable.filterChange('reviewStatus', option[0] || '')
                                                }}
                                                defaultData={[
                                                    {
                                                        value: 'APPROVED',
                                                        label: "task.document.approval.status.APPROVED",
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        value: 'REJECTED',
                                                        label: "task.document.approval.status.REJECTED",
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        value: 'PENDING',
                                                        label: "task.document.approval.status.PENDING",
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        value: 'NO_VALIDATION',
                                                        label: "task.document.approval.status.NO_VALIDATION",
                                                        translateLabel: true,
                                                    },
                                                ]}
                                            />
                                        </div>
                                        <div className='d-flex ml-auto text-right'>
                                            <ModalAddMemberDocuments
                                                documentTypes={documentTypes}
                                                isOpen={isOpen}
                                                toggle={toggle}
                                                onSuccess={refreshMemberProfile}
                                                shouldAttemptUploadProfilePicDocument={shouldAttemptUploadProfilePicDocument}
                                            />
                                            <Refresh />
                                            <UncontrolledButtonDropdown className='ml-2'>
                                                <DropdownToggle disabled color='primary' outline caret>
                                                    <i className='fas fa-download' /> <Translate id='misc.export' />
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem><i className='fas fa-file-excel' /> <Translate id='misc.excel' /></DropdownItem>
                                                    <DropdownItem><i className='fas fa-file-pdf' /> <Translate id='misc.pdf' /></DropdownItem>
                                                    <DropdownItem><i className='fas fa-print' /> <Translate id='misc.print' /></DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <CanDoAction action="ADD" componentCode="members" componentPermissionCode="members_documents">
                                                <Button onClick={toggle} className='ml-2' color='primary'><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                            </CanDoAction>
                                        </div>
                                    </div>
                                </div>
                                <SpordleTableView />
                            </>

                        )}
                    </SpordleTableProvider>
                )}
            />
        </Card>
    )
}

export default MemberDocuments;