import { Tooltip } from '@mantine/core';
import { FormikSelect, FormikSwitch } from '@spordle/formik-elements';
import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { useFormikContext } from 'formik';
import { useContext, useRef } from 'react';
import { Col, Collapse, Label, Row } from 'reactstrap';
import Required from '../../../components/formik/Required';
import { I18nContext } from '../../../contexts/I18nContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';

const DisplayQualificationsCard = ({ group, index, qualifications, qualificationIsValidating, positions, positionIsValidating, handleRemove, ...props }) => {
    const formik = useFormikContext();
    const tooltipRef = useRef(null);
    const i18nContext = useContext(I18nContext);

    return (
        <Collapse isOpen appear className='mb-3'>
            <div className="p-3 border rounded">
                <Row form className='mb-0'>
                    <Col sm='3'>
                        <Label className='text-muted'><Translate id='settings.teamsSettings.qualification' /> <Required /></Label>
                    </Col>
                    <Col sm='3'>
                        <Label className='text-muted'><Translate id='settings.teamsSettings.position' /> <Required /></Label>
                    </Col>
                    <Col sm='3'>
                        <Label className='text-muted'><Translate id='settings.teamsSettings.restriction' /> <Required /></Label>
                    </Col>
                    <Col sm='2'>
                        <Tooltip withArrow label={<div className='text-wrap' style={{ maxWidth: '300px' }}><Translate id={'settings.teamsSettings.enforced.tooltip'} /></div>}>
                            <i
                                ref={tooltipRef} className='mdi mdi-information-outline text-primary mr-1'
                            />
                        </Tooltip>
                        <Label className='text-muted'><Translate id='settings.teamsSettings.enforced' /></Label>
                    </Col>
                </Row>
                <Row form className='mb-3'>
                    <Col sm='3'>
                        <FormikSelect
                            name={`qualifications.${index}.values`}
                            id={`qualifications.${index}.values`}
                            renderOption={(option) => (
                                <div className='d-flex'>
                                    <div>
                                        <DisplayI18n
                                            field='name'
                                            defaultValue={option.option.label}
                                            i18n={option.option.i18n}
                                        />
                                        {option.option.qualification_level &&
                                            <> - <DisplayI18n field='name' defaultValue={option.option.qualification_level.name} i18n={option.option.qualification_level.i18n} /></>
                                        }
                                        {option.option.category &&
                                            <div className={stringBuilder('small', { 'text-muted': !option.isSelected })}>
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={option.option.category.name}
                                                    i18n={option.option.category.i18n}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {!option.option.isActive &&
                                        <div className='ml-auto d-flex align-items-center text-warning'>
                                            <Tooltip
                                                label={<Translate id='misc.inactive' />}
                                            >
                                                <i className='mdi mdi-alert-outline' />
                                            </Tooltip>
                                        </div>
                                    }
                                </div>
                            )}
                            onOptionSelected={(values, select) => {
                                formik.setFieldValue(`qualifications.${index}.values`, values.map((value) => ({
                                    teamSettingId: '',
                                    qualificationId: value,
                                })))
                                return true
                            }}
                            multi
                            textWhenSetting={{
                                count: 2,
                                label: 'settings.teamsSettings.qualification.textWhen',
                            }}
                            searchKeys={[
                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                `category.name`,
                                `category.i18n.${i18nContext.getGenericLocale()}.name`,
                                `qualification_level.name`,
                                `qualification_level.i18n.${i18nContext.getGenericLocale()}.name`,
                            ]}
                            values={group.values.map((q) => q.qualificationId)}
                            options={qualifications.map((qual) => ({
                                value: qual.qualification_id,
                                label: qual.name,
                                i18n: qual.i18n,
                                category: qual.qualification_category,
                                qualification_level: qual.qualification_level,
                                isActive: qual.active == 1,
                            }))}
                        />
                    </Col>
                    <Col sm='3'>
                        <FormikSelect
                            name={`qualifications.${index}.positionId`}
                            id={`qualifications.${index}.positionId`}
                            renderOption={(option) => (
                                <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                            )}
                            searchKeys={[
                                `i18n.${i18nContext.getGenericLocale()}.name`,
                            ]}
                            options={positions.map((pos) => ({
                                value: pos.position_id,
                                label: pos.name,
                                i18n: pos.i18n,
                            }))}
                        />
                    </Col>
                    <Col sm='3'>
                        <FormikSelect
                            name={`qualifications.${index}.mandatory`} id={`qualifications.${index}.mandatory`}
                            search={false}
                            loadingStatus='success'
                            defaultData={[
                                { value: 'all', label: 'settings.teamsSettings.restriction.all', translateLabel: true },
                                { value: 'one', label: 'settings.teamsSettings.restriction.one', translateLabel: true },
                            ]}
                        />
                    </Col>
                    <Col className='d-flex align-items-center' sm='2'>
                        <FormikSwitch
                            id={`qualifications.${index}.enforced`}
                            name={`qualifications.${index}.enforced`}
                            checked={group.enforced}
                        />
                    </Col>
                    <Col className='d-flex align-items-center justify-content-end' sm='1'>
                        <button className='reset-btn' type='button' onClick={handleRemove}>
                            <i className='mdi mdi-delete text-danger font-16' />
                        </button>
                    </Col>
                </Row>
            </div>
        </Collapse>
    )
}

export default DisplayQualificationsCard;
