import Translate, { DateFormat } from '@spordle/intl-elements';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, Col, Fade, FormGroup, ListGroup, ListGroupItem, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import InlineCopy from '../../../../components/inlineCopy/InlineCopy';
import DisplayOrganization from '../../../../components/organization/DisplayOrganization';
import CanDoAction from '../../../../components/permissions/CanDoAction';
import QuickViewButton from '../../../../components/quickView/QuickViewButton';
import SidePanel from '../../../../components/sidePanel/SidePanel';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import MemberPermanentReleaseRequestsModal from './MemberPermanentReleaseRequestsModal';

const MemberPermanentReleaseRequestsSidePanel = (props) => {

    return (
        <Fade in>
            <MemberPermanentReleaseRequestsModal
                toggle={props.isModalOpenHandlers?.toggle}
                isOpen={props.isModalOpen}
                selectedRows={props.selectedRows}
                spordleTable={props.tableRef}
                forceCloseSidePanel={props.forceCloseSidePanel}
                changeRequestStatuses={props.changeRequestStatuses}
            />
            <SidePanel.Header noBorder className='sticky-top bg-white z-index-1'>
                <div className='d-flex mb-3 align-items-center'>
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu>
                        <SidePanel.MenuAction
                            action="EDIT" componentCode="members" componentPermissionCode="approve_request_mandatory_fields"
                            onClick={props.isModalOpenHandlers?.toggle}
                        >
                            <Translate id='task.document.approval.manage.approval' />
                        </SidePanel.MenuAction>
                    </SidePanel.ActionsMenu>
                </div>
            </SidePanel.Header>
            {(props.selectedRows.length <= 1) ?
                <>
                    <div className="d-flex align-items-center px-3 pb-3">
                        <UserImg
                            alt={props.selectedRows[0].first_name + ' ' + props.selectedRows[0].last_name}
                            abbr={props.selectedRows[0].member.first_name?.charAt(0) + props.selectedRows[0].member.last_name?.charAt(0)}
                            src={props.selectedRows[0].picture?.full_path}
                            filePos={props.selectedRows[0].picture?.file_position}
                            width={60}
                            className="mr-2"
                        />
                        <div className="w-100">
                            <div className="d-flex align-items-center text-break">
                                <span className='h4 font-bold mb-0'>
                                    <QuickViewButton member={props.selectedRows[0]}>
                                        {props.selectedRows[0].member.first_name} {props.selectedRows[0].member.last_name}
                                    </QuickViewButton>
                                </span>
                            </div>
                            <div>
                                <InlineCopy tag="span" toCopy={props.selectedRows[0].member.unique_identifier}>
                                    <Link className="text-nowrap" to={`/members/profile/${props.selectedRows[0].member_id}`}>
                                        {props.selectedRows[0].member.unique_identifier ?
                                            <>#{props.selectedRows[0].member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                            :
                                            '-'
                                        }
                                    </Link>
                                </InlineCopy>
                            </div>
                        </div>
                    </div>


                    <Nav tabs>
                        <NavItem className="text-center" style={{ width: '100%' }}>
                            <NavLink
                                className={"active"}
                            >
                                <Translate id='task.mandatoryChangesRequests.sidepanel.request' />
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={"1"}>
                        <TabPane tabId="1">
                            <Fade in>
                                <div className="p-3">
                                    <Row form>
                                        <Col md='6'>
                                            <FormGroup>
                                                <div className='text-muted d-flex'>
                                                    <Translate id='task.mandatoryChangesRequests.sidepanel.request.status' />
                                                </div>
                                                {/* hardcoded because this API call only returns pending member change requests */}
                                                <Badge color={"warning"}><Translate id='reports.column.status.PENDING' /></Badge>
                                            </FormGroup>
                                        </Col>
                                        <Col md='6'>
                                            <FormGroup>
                                                <div className='text-muted d-flex'>
                                                    <Translate id='task.mandatoryChangesRequests.sidepanel.request.requested_by' />
                                                </div>
                                                {props.selectedRows[0].created_by ?
                                                    <div className="">
                                                        <div className="mb-0">{props.selectedRows[0].created_by.name + ' ' + props.selectedRows[0].created_by.family_name}</div>
                                                        <a href={`mailto:${props.selectedRows[0].created_by.email}`}>{props.selectedRows[0].created_by.email}</a>
                                                    </div>
                                                    :
                                                    ' - '
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className='h5 font-bold'><Translate id='task.mandatoryChangesRequests.sidepanel.request.data' /></div>
                                    <Card body className='card-shadow'>
                                        <Translate id='members.profile.has.RequestRelease.from' />:
                                        <div className='font-bold text-dark'>
                                            <DisplayOrganization organisation={{ organisation_id: props.selectedRows[0].organisation_id }}>
                                                <DisplayI18n i18n={props.selectedRows[0].organisation.i18n} defaultValue={props.selectedRows[0].organisation.name} field='name' />
                                            </DisplayOrganization>
                                        </div>
                                    </Card>
                                    <CanDoAction
                                        tag={Button}
                                        outline color="primary"
                                        className="w-100"
                                        action="EDIT" componentCode="members" componentPermissionCode="approve_request_mandatory_fields"
                                        onClick={props.isModalOpenHandlers?.toggle}
                                    >
                                        <Translate id='task.document.approval.manage.approval' />
                                    </CanDoAction>
                                </div>
                            </Fade>
                        </TabPane>
                    </TabContent>
                </>
                :
                <>
                    <div className="d-flex align-items-center px-3 pb-3">
                        <div className='w-100 h4 font-bold mb-0 text-break'>
                            <Translate values={{ count: props.selectedRows.length }} id="task.mandatoryChangesRequests.sidepanel.number" />
                        </div>
                    </div>
                    <ListGroup>
                        {props.selectedRows.map((changeRequest) => (
                            <ListGroupItem className='px-3' key={changeRequest.permanent_release_request_id}>
                                <UserDisplay>
                                    <UserDisplay.Container>
                                        <UserImg
                                            alt={changeRequest.member.first_name + ' ' + changeRequest.member.last_name}
                                            abbr={changeRequest.member.first_name.charAt(0) + changeRequest.member.last_name.charAt(0)}
                                            src={changeRequest.member.picture?.full_path}
                                            filePos={changeRequest.member.picture?.file_position}
                                            width={60}
                                            className="mr-2"
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container className="w-100">
                                        <UserDisplay.Title className="d-flex justify-content-between">
                                            <div className='d-flex justify-content-start'>
                                                <div>{changeRequest.member.first_name} {changeRequest.member.last_name}</div>
                                                {/* <MemberStatusIcons member={member} className="ml-1 d-flex" /> */}
                                            </div>
                                        </UserDisplay.Title>
                                        <UserDisplay.Subtitle>
                                            <Link className="text-nowrap" to={`/members/profile/${document.member_id}`}>
                                                {changeRequest.member.unique_identifier ?
                                                    <>#{changeRequest.member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                    :
                                                    '-'
                                                }
                                            </Link>
                                            <div className='text-muted'><Translate id='task.unconfirmedOrg.sidepanel.submission.date' /> : <span className='text-dark'><DateFormat value={changeRequest.created_at} /></span></div>
                                            <div>
                                                <Translate id='misc.status' /> :
                                                <Badge className='ml-1' color={"warning"}><Translate id='reports.column.status.PENDING' /></Badge>
                                            </div>
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                    <div className='px-3 mt-4'>
                        <CanDoAction
                            tag={Button}
                            outline color="primary"
                            className="w-100"
                            action="EDIT" componentCode="members" componentPermissionCode="approve_request_mandatory_fields"
                            onClick={props.isModalOpenHandlers?.toggle}
                        >
                            <Translate id='task.document.approval.manage.approval' />
                        </CanDoAction>
                    </div>
                </>
            }

        </Fade>
    );
};

export default MemberPermanentReleaseRequestsSidePanel;
