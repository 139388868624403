export default{
    // Review
    "clinics.clinicSessionInformation.formClinicSessionInformation.review.title"         : "Edit session(s) information",
    "clinics.clinicSessionInformation.formClinicSessionInformation.review.saveChanges"   : "Save changes",
    "clinics.clinicSessionInformation.formClinicSessionInformation.review.cancelChanges" : "Cancel changes",

    // Host contact info
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.title"                      : "{count, plural, =0 {Contact} one {Contact} other {Contacts}}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.myself"                     : "Add myself as contact",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.myself.text"                : "You are about to add yourself as a contact.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact"                 : "Add a contact",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.exists"          : "A contact already exists with this information",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.asInstructor"    : "An instructor already exists with this information",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.findContact"                : "Find contact",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.asInstructor"               : "Also add as instructor",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus"                 : "Visibility",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus.SHOW"            : "Show all the information",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus.SHOW_PARTIAL"    : "Hide email and phone number",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus.HIDE"            : "Hide all the information",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.notify"                     : "Notify contact of new registrations",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.minimum"                    : "Add at least 1 contact",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.new.title"       : "Create new contact",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.new.description" : "Create {firstName} {lastName}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.link.title"                 : "Link an account",

    // Host instructor info
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.title"                                          : "{count, plural, =0 {Instructor} one {Instructor} other {Instructors}}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.myself"                                         : "Add myself as instructor",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.myself.text"                                    : "You are about to add yourself as an instructor.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor"                                  : "Add an instructor",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.exists"                           : "An instructor already exists with this information",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.findContact"                                    : "Find contact",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.notify"                                         : "Notify instructor of new registrations",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.new.title"                        : "Create new instructor",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.new.description"                  : "Create {firstName} {lastName}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission"                : "Manage Access",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.title"                    : "Instructor created",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.subtitle"                 : "Instructor has successfully been created.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.instructorIdentityMember" : "The account linked to the instructor has a {permission} permission.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.instructorMember"         : "A member has been linked to the instructor.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.instructorNoLink"         : "No member and no spordle account have been linked to this instructor.",

    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.NO_ACCESS"  : "No Access",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.READ_ONLY"  : "Read Only",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.READ_WRITE" : "Read and Edit",

    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.desc.NO_ACCESS"  : "The instructor <bold>won't have access</bold> to view this clinic or it's attendees in his/her <bold>{spordle_account}</bold>.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.desc.READ_ONLY"  : "The instructor <bold>will have access to view</bold> this clinic and it's attendees in his/her <bold>{spordle_account}</bold>.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.desc.READ_WRITE" : "The instructor <bold>will have access to view and edit</bold> this clinic and it's attendees in his/her <bold>{spordle_account}</bold>.",


    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.sidepanel.member"           : "{unique_identifier}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.sidepanel.spordleMyAccount" : "{spordle_account}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.sidepanel.desc.NO_ACCESS"   : "The instructor <bold>doesn't have access</bold> to view this clinic or it's attendees in his/her <bold>{spordle_account}</bold>.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.sidepanel.desc.READ_ONLY"   : "The instructor <bold>has access to view</bold> this clinic and it's attendees in his/her <bold>{spordle_account}</bold>.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.sidepanel.desc.READ_WRITE"  : "The instructor <bold>has access to view and edit</bold> this clinic and it's attendees in his/her <bold>{spordle_account}</bold>.",

    // Sessions
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.title"            : "{count, plural, =0 {Session} one {Session} other {Sessions}}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.add"              : "Add a session",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.none"             : "No sessions",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.minimum"          : "Add at least 1 session",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.number"           : "Session #",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.delete.text"      : "You are about to delete this session",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.format"           : "Format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.language"         : "Language",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.location"         : "Location",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.location.example" : "Rink 2, Conference Room 3",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.source"           : "Source",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.url"              : "URL link",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.notes"            : "Notes",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.duplicate"        : "Duplicate",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.duplicate.msg"    : "You are about to duplicate this session",

    "clinics.clinicSessionInformation.formClinicSessionInformation.ruleForQualifications"        : "Required Session Type",
    "clinics.clinicSessionInformation.formClinicSessionInformation.ruleForQualifications.helper" : "Mandatory session type to obtain qualification",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.ONSITE"              : "In Person",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.ONLINE_LIVE"         : "Online - Live",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.ALL"                 : "All",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.ONLINE"              : "Online - Recorded/On-Demand",

    // Add Session
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.title"           : "Add session",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.note"            : "Add note",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.title"    : "Source & link",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.source"   : "Source",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.external" : "External link",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.huc"      : "Hockey University course",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.coach"    : "Coach.ca",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.respect"  : "Respect and Sport",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.address.tooltip" : "Open in Google Maps",

    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.title" : "Schedule",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text1" : "The session will be held on ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text2" : " at ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text3" : "and will be ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text4" : " hour(s) and ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text5" : " minute(s) long",

    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text1" : "The session will be available from ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text2" : " at ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text3" : " to ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text4" : " at ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text5" : "and will be ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text6" : " hour(s) and ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text7" : " minute(s) long",

    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.language.required"  : "Language is required",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.format.required"    : "Format is required",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.source.required"    : "Source is required",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.url.required"       : "URL is required",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.url.valid"          : "URL must be of a valid format (ex: https://www.spordle.com)",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.date.format"                   : "YYYY-MM-DD",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.date.required"      : "Date is required",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.date.valid"         : "Date must be of a valid format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.time.required"      : "Time is required",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.time.valid"         : "Time must be of a valid format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.startDate.required" : "Start date is required",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.startDate.valid"    : "Start date must be of a valid format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.startTime.required" : "Start time is required",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.startTime.valid"    : "Start time must be of a valid format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endDate.required"   : "End date is required",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endDate.after"      : "End date must be after the start date",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endDate.valid"      : "End date must be of a valid format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endTime.required"   : "End time is required",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endTime.after"      : "End time must be after the start time",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endTime.valid"      : "End time must be of a valid format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.hours.required"     : "Hours are required",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.hours.max"          : "The amount of hours must be smaller or equal to 24",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.minutes.required"   : "Minutes are required",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.minutes.max"        : "The amount of minutes must be smaller than 60",
}