export default{
    "members.profile.teams.title" : "Teams",

    // table
    "members.profile.teams.season"             : "Season",
    "members.profile.teams.previousSeason"     : "Previous Seasons",
    "members.profile.teams.rostered"           : "Rostered on",
    "members.profile.teams.organizations"      : "Organizations",
    "members.profile.teams.category"           : "Category",
    "members.profile.teams.teamName"           : "Team name",
    "members.profile.teams.leagueName"         : "League name",
    "members.profile.teams.division"           : "Division",
    "members.profile.teams.position"           : "Position",
    "members.profile.teams.affiliate"          : "Affiliate",
    "members.profile.teams.tryout"             : "Tryout",
    "members.profile.teams.released"           : "Released",
    "members.profile.teams.primaryTeam"        : "Primary Team",
    "members.profile.teams.currentPrimaryTeam" : "Current Primary Team",

    // sidepanel
    "members.profile.teams.actionsMenu.setPrimary"            : "Set as primary team",
    "members.profile.teams.actionsMenu.setPrimary.primary"    : "This team is already the primary team",
    "members.profile.teams.actionsMenu.setPrimary.noAccess"   : "You do not have access to this team",
    "members.profile.teams.actionsMenu.setPrimary.affiliated" : "Can't be primary as affiliated player",
}