import { useContext } from 'react';
import {
    Row,
    Col,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    FormGroup,
    Collapse,
    Alert,
    FormText
} from "reactstrap";
import { Formik, Form as FormikForm } from "formik";
import { FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate from "@spordle/intl-elements";

// Contexts
import { DisplayI18n, I18nHelperContext, RenderI18nForm, displayI18n } from '../../../../helpers/i18nHelper';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { RegistrationCategoriesContext } from '../../../../contexts/RegistrationCategoriesContext';
import { SpordleTableContext } from '@spordle/datatables';
import { I18nContext } from '../../../../contexts/I18nContext';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { success } from '@spordle/toasts';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import Required from '../../../../components/formik/Required';
import { number, object, string } from 'yup';

const ModalFormFederation = ({ toggleModal, classDivision }) => {
    const orgContext = useContext(OrganizationContext);
    const categoryContext = useContext(RegistrationCategoriesContext);
    const spordleTable = useContext(SpordleTableContext);
    const i18n = useContext(I18nContext);
    const i18nHelper = useContext(I18nHelperContext);

    const createSuggestedName = (divisionId, classId, lang) => {
        if(divisionId){
            const division = (classDivision.divisions || []).find((division) => division.division_id === divisionId);

            if(division){
                let suggestedName = displayI18n('name', division.i18n, division.name, lang);
                if(classId){
                    const classFound = classDivision.classes.find((_class) => _class.class_id === classId);
                    if(classFound){
                        suggestedName += ` - ${displayI18n('name', classFound.i18n, classFound.name, lang)}`;
                    }
                }
                return suggestedName;
            }
        }
        return '';
    }

    return (
        <Formik
            initialValues={{
                ...i18nHelper.getInitialValues(),
                gender: '',
                min_age: '',
                max_age: '',
                division_id: '',
                class_id: '',
                display_order: spordleTable.getData().length + 1,
            }}
            validationSchema={object().shape({
                ...i18nHelper.getValidationSchema({ 'name': string() }),
                division_id: string().required(<Translate id='registration.settings.category.form.division.required' />),
                class_id: string(),
                gender: string().required(<Translate id='form.validation.gender.required' />),
                min_age: string().required(<Translate id='registration.settings.category.form.minAge.required' />),
                max_age: string().required(<Translate id='registration.settings.category.form.maxAge.required' />),
                display_order: number()
                    .integer(<Translate id='registration.settings.class.form.validation.order.integer' />)
                    .moreThan(0, <Translate id='registration.settings.class.form.validation.order.moreThan0' />),
            })}
            onSubmit={(values, { setStatus }) => {
                setStatus();
                values.active = '1';

                // logic to apply suggested value when name fields are empty
                for(const lang in values.i18n){
                    if(!values.i18n[lang].name)
                        values.i18n[lang].name = createSuggestedName(values.division_id, values.class_id, lang)
                }

                const newItem = values; // data that will be sent back to table

                const apiValues = {
                    ...newItem,
                    division_id: values.division_id,
                    class_id: values.class_id || '',
                    ...i18nHelper.getAPIValues(values),
                }
                delete apiValues.i18n // remove i18n object

                return categoryContext.createRegistrationCategory(orgContext.organisation_id, apiValues)
                    .then((team_category_id) => {
                        spordleTable.refreshTable();
                        toggleModal();
                        success();
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            setStatus(<DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />);
                        }
                    })
            }}
        >
            {(formik) => (
                <FormikForm id="createFederationCategory">
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggleModal}>
                            <Translate id='registration.settings.category.modal.title' />
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="division_id" className="text-muted"><Translate id='registration.settings.category.label.division' /> <Required /></Label>
                                <FormikSelect
                                    name="division_id" id='division_id'
                                    searchKeys={[
                                        `i18n.${i18n.getGenericLocale()}.short_name`,
                                        `i18n.${i18n.getGenericLocale()}.name`,
                                    ]}
                                    renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                    options={classDivision?.divisions?.sort((a, b) => a.display_order - b.display_order)?.map((division) => ({
                                        value: division.division_id,
                                        label: division.short_name,
                                        i18n: division.i18n,
                                    }))}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="class_id" className="text-muted"><Translate id='registration.settings.category.label.class' /></Label>
                                <FormikSelect
                                    name="class_id" id='class_id'
                                    searchKeys={[
                                        `i18n.${i18n.getGenericLocale()}.short_name`,
                                    ]}
                                    renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                    options={classDivision?.classes?.sort((a, b) => a.display_order - b.display_order)?.map((_class) => ({
                                        value: _class.class_id,
                                        label: _class.short_name,
                                        i18n: _class.i18n,
                                    }))}
                                />
                            </FormGroup>

                            <FormGroup>
                                <div className="mb-2"><Translate id='registration.settings.category.label.name' /></div>
                                <div className="border p-3 rounded">
                                    <RenderI18nForm field='name'>
                                        {({ fieldName, fieldLabel, lang, index }) => {
                                            const suggestedName = createSuggestedName(formik.values.division_id, formik.values.class_id, lang);
                                            return (
                                                <FormGroup key={fieldName} className={index == 1 ? 'mb-0' : ''}>
                                                    <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                    <FormikInputText
                                                        placeholder={suggestedName}
                                                        translatePlaceholder={false}
                                                        id={fieldName} name={fieldName}
                                                        trim disabled={!formik.values.division_id}
                                                    />
                                                    {suggestedName &&
                                                        <FormText>
                                                            <Translate id='registration.settings.category.label.name.suggestion' />:
                                                            <button
                                                                className='reset-btn text-link ml-1' type='button'
                                                                onClick={() => {
                                                                    formik.setFieldValue(fieldName, suggestedName);
                                                                }}
                                                            >
                                                                {suggestedName}
                                                            </button>
                                                        </FormText>
                                                    }
                                                </FormGroup>
                                            )
                                        }}
                                    </RenderI18nForm>
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label for="display_order" className="text-muted"><Translate id='registration.settings.class.form.label.order' /></Label>
                                <FormikInputNumber
                                    id='display_order'
                                    name='display_order'
                                    allowNegative={false}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="gender" className="text-muted"><Translate id='registration.settings.category.label.gender' /> <Required /></Label>
                                <FormikSelect
                                    name='gender' id='gender'
                                    defaultData={[
                                        { value: 'MALE', label: 'registration.settings.category.table.gender.male', translateLabel: true },
                                        { value: 'FEMALE', label: 'registration.settings.category.table.gender.female', translateLabel: true },
                                        { value: 'MIXED', label: 'registration.settings.category.table.gender.mixed', translateLabel: true },
                                    ]}
                                    loadingStatus='success'
                                />
                            </FormGroup>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="min_age" className="text-muted"><Translate id='registration.settings.category.label.minAge' /> <Required /></Label>
                                        <FormikInputText id='min_age' name='min_age' />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="max_age" className="text-muted"><Translate id='registration.settings.category.label.maxAge' /> <Required /></Label>
                                        <FormikInputText id='max_age' name='max_age' />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                <Alert color='danger' className='mb-0'>
                                    {formik.status}
                                </Alert>
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type="submit"><Translate id='misc.save' /></Button>
                            <Button type="button" color="primary" onClick={toggleModal} outline><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </FormikForm>
            )}
        </Formik>
    )
}

export default ModalFormFederation;