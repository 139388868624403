import { Button, Col, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal";
import FormikModalForm from "../../../../../../components/formik/FormikModalForm";
import Translate from "@spordle/intl-elements";
import FormikApiError from "../../../../../../components/formik/FormikApiError";
import { useTaxClassRatesSWR, useTaxClassesSWR } from "../../../../../../api/useSWRHelpers/taxesSWR";
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import FormikSelectProvinces from "../../../../../../components/formik/FormikSelectProvinces";
import FormikSelectCountries from "../../../../../../components/formik/FormikSelectCountries";
import Required from "../../../../../../components/formik/Required";
import { object, string } from "yup";
import { createOrganizationTaxNumber } from "../../../../../../api/client/organization";
import { useContext } from "react";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { TAX_RATE_SPLITTER, buildTaxRateId, getTaxRateUnit } from "./taxHelper";

const AddOrgTaxNumberModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal analyticsName="addOrgTaxNumberModal" isOpen={isOpen}>
            <AddOrgTaxNumberModalInner {...props} />
        </AnalyticsModal>
    );
}

const AddOrgTaxNumberModalInner = ({ toggle, onSuccess, taxNumberList = [], initialValues }) => {
    const orgContext = useContext(OrganizationContext);
    const { data: taxClasses } = useTaxClassesSWR();
    const taxClassId = taxClasses?.[0]?.tax_class_id;

    const { data: taxRates } = useTaxClassRatesSWR(taxClassId);

    return (
        <FormikModalForm
            initialValues={{
                province_code: orgContext.province_code || '',
                country_code: orgContext.country_code || '',
                country_id: '', // Needed for provinces select
                tax_number: '',
                tax_rate: '',
                ...initialValues,
            }}
            toggle={toggle}
            isLoading={!taxClassId}
            validationSchema={object().shape({
                country_code: string().required(<Translate id="form.validation.country.required" />),
                province_code: string().required(<Translate id="form.validation.province.required" />),
                tax_number: string()
                    .max(255, <Translate id="form.validation.string.max" values={{ count: 255 }} />)
                    .required(<Translate id="organisation.profile.tabs.overview.number.required" />),
                tax_rate: string().required(<Translate id="organisation.profile.tabs.overview.tax.required" />),
            })}
            onSubmit={(values, { showError, showSuccess }) => {
                const getTaxNumberMethod = () => {

                    return createOrganizationTaxNumber(orgContext.organisation_id, {
                        tax_class_id: taxClassId,
                        tax_name: values.tax_rate.split(TAX_RATE_SPLITTER)[0],
                        province_code: values.province_code,
                        country_code: values.country_code,
                        tax_number: values.tax_number,
                    })
                }

                return getTaxNumberMethod()
                    .then(() => {
                        onSuccess?.();
                        showSuccess();
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            showError(e);
                        }
                    })
            }}
        >
            {(formik) => (
                <>
                    <ModalHeader toggle={toggle}><Translate id="organisation.profile.tabs.overview.addTax" /></ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm={6} className="mb-3">
                                <Label for="country_code" className="text-muted"><Translate id="form.fields.country" /> <Required /></Label>
                                <FormikSelectCountries
                                    onOptionSelected={() => {
                                        formik.setFieldValue('tax_rate', '');
                                    }}
                                />
                            </Col>
                            <Col sm={6} className="mb-3">
                                <Label for="province_code" className="text-muted"><Translate id="form.fields.province" /> <Required /></Label>
                                <FormikSelectProvinces
                                    countryId={formik.values.country_id}
                                    onOptionSelected={() => {
                                        formik.setFieldValue('tax_rate', '');
                                    }}
                                />
                            </Col>
                            <Col xs={3}>
                                <Label for="tax_rate" className="text-muted"><Translate id="organisation.profile.tabs.overview.tax" /> <Required /></Label>
                                <FormikSelect
                                    id="tax_rate"
                                    name="tax_rate"
                                    disabled={(!formik.values.province_code && !formik.values.country_code)}
                                    isLoading={!taxRates}
                                    search={false}
                                    renderOption={({ option, isSelected, isDisabled }) => (
                                        <>
                                            <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />
                                            <div className={`small text-${isSelected ? 'light' : 'muted'}`}>{option.rate}{getTaxRateUnit(option.type)}</div>
                                            {isDisabled &&
                                                <div className="small"><Translate id="organization.grouping.add.selectOrg.alreadyAdded" /></div>
                                            }
                                        </>
                                    )}
                                    isOptionDisabled={(option) => {
                                        const combinationExists = taxNumberList.some(({ tax_rate }) => {
                                            return `${tax_rate.name}${TAX_RATE_SPLITTER}${tax_rate.province.code}` === option.value;
                                        })

                                        return combinationExists;
                                    }}
                                    renderSelectedOption={(option) => <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />}
                                    options={(taxRates || [])
                                        .filter((taxRate) => {
                                            const isActive = taxRate.active == 1;
                                            const isInCountry = !formik.values.country_code || formik.values.country_code == taxRate.country.code;
                                            const isInProvince = !formik.values.province_code || formik.values.province_code == taxRate.province.code;

                                            return isActive && isInCountry && isInProvince;
                                        })
                                        .map((taxRate) => ({
                                            ...taxRate,
                                            label: taxRate.name,
                                            value: buildTaxRateId(taxRate), // resource has no ID
                                        }))
                                    }
                                />
                            </Col>
                            <Col xs={9}>
                                <Label for="tax_number" className="text-muted"><Translate id="form.fields.number" /> <Required /></Label>
                                <FormikInputText name="tax_number" id="tax_number" />
                            </Col>
                        </Row>
                        <FormikApiError />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            disabled={formik.isSubmitting || !taxClassId}
                            color="primary"
                            type="submit"
                        >
                            <Translate id="misc.add" />
                        </Button>
                        <Button
                            disabled={formik.isSubmitting}
                            type="button"
                            color="primary"
                            outline
                            onClick={toggle}
                        >
                            <Translate id="misc.cancel" />
                        </Button>
                    </ModalFooter>
                </>
            )}
        </FormikModalForm>
    )
}

export default AddOrgTaxNumberModal;