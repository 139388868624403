import SpordleTableProvider, { Refresh, SearchInput, SpordleTableContext, SpordleTableView } from '@spordle/datatables';
import { stringBuilder } from '@spordle/helpers';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    Table
} from "reactstrap";
import AppealsAddContext from '../../../../../../components/appeals/AppealsAddContext';
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';
import SpordlePanelTable from '../../../../../../components/sidePanel/SpordlePanel';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { IdentityRolesContext } from '../../../../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { RolesContext } from '../../../../../../contexts/RolesContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import AppealsSidepanel from './component/appealsSidepanel/AppealsSidepanel';

const Appeals = ({ godAccess }) => {
    const { getGenericLocale } = useContext(I18nContext)
    const [ appeals, setAppeals ] = useState(false)
    const toggleAppeals = () => setAppeals(!appeals)

    const membersContext = useContext(MembersContext)
    const { canDoAction } = useContext(RolesContext)
    const { isGod } = useContext(IdentityRolesContext)

    const displayStatusBadge = (status) => {
        switch (status.name){
            case 'PENDING':
                return (
                    <Badge color='warning'><DisplayI18n field='name' defaultValue={status.name} i18n={status.i18n} /></Badge>
                )
            default:
                return <Badge color='info'><DisplayI18n field='name' defaultValue={status.name} i18n={status.i18n} /></Badge>
        }
    }

    const displayApproved = (approved) => {
        switch (approved?.name){
            case 'Approved':
                return (
                    <i className="mdi mdi-check text-primary" />
                )
            default:
                return (
                    <i className="mdi mdi-close text-danger" />
                )
        }
    }

    return (
        <Card id="appealsCard" className="card-shadow">
            <CardBody>
                <CardSectionTitle title='members.profile.appeals.title' titleClassname={godAccess ? "text-purple" : undefined} />
                <SpordlePanelTable
                    allowOutsideClick
                    sidePanel={(props) => <AppealsSidepanel {...props} />}
                    dataIndex='member_appeal_id'
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='appeals'
                                tableHover bordered striped
                                clickable
                                dataIndex='member_appeal_id'
                                ref={panelProps.spordleTableRef}
                                desktopWhen
                                pagination={5}
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                defaultSorting='-appeal_date'
                                searchKeys={[
                                    'appeal_to_team_name',
                                    'appeal_to_team.name',
                                    'appeal_from_team_name',
                                    'appeal_from_team.name',
                                    'appeal_date',
                                    `appeal_from_org.i18n.${getGenericLocale()}.name`,
                                    'appeal_from_org.organisation_name',
                                    `appeal_to_org.i18n.${getGenericLocale()}.name`,
                                    'appeal_to_org.organisation_name',
                                    `appeal_status.i18n.${getGenericLocale()}.name`,
                                    'appeal_status.name',
                                ]}
                                loadData={(from, _data, spordleTable) => {
                                    switch (from){
                                        case 'REFRESH':
                                            spordleTable.setLoading()
                                        case 'CDM':
                                            return membersContext.getMemberAppeals(membersContext.currentMember.member_id);
                                        default:
                                            break;
                                    }
                                }}
                                columns={[
                                    {
                                        label: <Translate id='members.profile.appeals.date' />,
                                        key: 'appeal_date',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.appeals.from' />,
                                        key: 'from',
                                        sortable: true,
                                        sortKey: 'appeal_from_team_name',
                                    },
                                    {
                                        label: <Translate id='members.profile.appeals.to' />,
                                        key: 'to',
                                        sortable: true,
                                        sortKey: 'appeal_to_team_name',
                                    },
                                    {
                                        label: <Translate id='members.profile.appeals.status' />,
                                        key: 'status',
                                        dataClassName: 'text-center',
                                        sortable: true,
                                        sortKey: 'appeal_status.name',
                                    },
                                    {
                                        label: <Translate id='members.profile.appeals.approved' />,
                                        key: 'approved',
                                        className: 'th-shrink',
                                        dataClassName: 'text-center td-shrink',
                                        sortable: true,
                                        sortKey: 'appeal_approved.name',
                                    },
                                ]}
                                renderRow={(columnKey, appeal) => {
                                    switch (columnKey){
                                        case 'appeal_date':
                                            return (
                                                <DateFormat value={appeal.appeal_date} />
                                            )
                                        case 'from':
                                            return (
                                                <>
                                                    <div className="small"><DisplayI18n field='name' defaultValue={appeal.appeal_from_org.organisation_name} i18n={appeal.appeal_from_org.i18n} /></div>
                                                    <div>
                                                        {appeal.appeal_from_team ?
                                                            appeal.appeal_from_team.name
                                                            :
                                                            appeal.appeal_from_team_name
                                                        }
                                                    </div>
                                                </>
                                            )
                                        case 'to':
                                            return (
                                                <>
                                                    <div className="small"><DisplayI18n field='name' defaultValue={appeal.appeal_to_org.organisation_name} i18n={appeal.appeal_to_org.i18n} /></div>
                                                    <div>
                                                        {appeal.appeal_to_team ?
                                                            appeal.appeal_to_team.name
                                                            :
                                                            appeal.appeal_to_team_name
                                                        }
                                                    </div>
                                                </>
                                            )
                                        case 'status':
                                            return displayStatusBadge(appeal.appeal_status)
                                        case 'approved':
                                            return displayApproved(appeal.appeal_approved)
                                        default:
                                            break;
                                    }
                                }}
                                onColumnClick={(e, data) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            panelProps.onSingleSelectChange(data);
                                            break;
                                    }
                                }}
                                rowIsHighlighted={(data) => data.checked}
                                emptyLayout={
                                    <Table bordered striped hover>
                                        <tbody>
                                            <tr>
                                                <td className='text-center'>
                                                    {(canDoAction('ADD', 'members', 'member_appeal_request') || isGod()) ?
                                                        <button className={stringBuilder('font-medium reset-btn text-primary', { 'text-purple': !canDoAction('ADD', 'members', 'member_appeal_request') && isGod() })} type='button' onClick={toggleAppeals}><i className='mdi mdi-plus' /><Translate id='misc.add' /> <Translate id="members.profile.appeals.title" /></button>
                                                        :
                                                        <Translate id="misc.search.empty.title" />
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                }
                            >
                                <div className='mb-2'>
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <SearchInput />
                                        <div className='d-flex ml-auto text-right'>
                                            <Refresh />
                                            <CanDoAction action='ADD' componentCode='members' componentPermissionCode='member_appeal_request'>
                                                <Button className='ml-2' color='primary' onClick={toggleAppeals}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                <SpordleTableContext.Consumer>
                                                    {(spordleTable) => (
                                                        <AppealsAddContext toggle={toggleAppeals} isOpen={appeals} memberId={membersContext.currentMember.member_id} refreshTable={() => spordleTable.refreshTable()} />
                                                    )}
                                                </SpordleTableContext.Consumer>
                                            </CanDoAction>
                                        </div>
                                    </div>
                                </div>
                                <SpordleTableView />
                            </SpordleTableProvider>
                        )
                    }}
                />
            </CardBody>
        </Card>
    );
}

export default Appeals;