export default{
    "onlineStore.components.onlineStoreRebatesTerms.label" : "Conditions de paiement, rabais",

    // Rebates select
    "onlineStore.components.onlineStoreRebatesTerms.rebates.label"       : "Rabais",
    "onlineStore.components.onlineStoreRebatesTerms.rebates.empty"       : "Pas de rabais",
    "onlineStore.components.onlineStoreRebatesTerms.rebates.placeholder" : "Sélectionnez un rabais",
    "onlineStore.components.onlineStoreRebatesTerms.rebates.textWhen"    : "{count} rabais sélectionnés",

    // Terms & Conditions select
    "onlineStore.components.onlineStoreRebatesTerms.terms.label"               : "Conditions de paiement",
    "onlineStore.components.onlineStoreRebatesTerms.terms.empty"               : "Pas de conditions de paiement",
    "onlineStore.components.onlineStoreRebatesTerms.terms.placeholder"         : "Sélectionnez des conditions de paiement",
    "onlineStore.components.onlineStoreRebatesTerms.terms.textWhen"            : "{count} conditions de paiement sélectionnés",
    "onlineStore.components.onlineStoreRebatesTerms.terms.validation.required" : "Vous devez avoir des conditions de paiement",
}