// import OrganizationSuspensions from '../../../views/settings/teams/ressources/suspensions/OrganizationSuspensions';
// import OrganizationTeamAddressTypes from '../../../views/settings/teams/ressources/teamAddressTypes/OrganizationTeamAddressTypes';
// import OrganizationPositionGroups from '../../../views/settings/teams/ressources/positionGroups/OrganizationPositionGroups';
// import OrganizationPositions from '../../../views/settings/teams/ressources/positions/OrganizationPositions';
// import OrganizationTeamCategoryTypes from '../../../views/settings/teams/ressources/teamCategoryTypes/OrganizationTeamCategoryTypes';
// import OrganizationTeamTravelTypes from '../../../views/settings/teams/ressources/teamTravelTypes/OrganizationTeamTravelTypes';
// import OrganizationTeamContactTypes from '../../../views/settings/teams/ressources/teamContactTypes/OrganizationTeamContactTypes';

import TeamSettingsConfigurationQualificationRestrictions from "../../../views/settings/teams/configuration/qualificationRestrictions/TeamSettingsConfigurationQualificationRestrictions";
import TeamSettingsConfigurationSettings from "../../../views/settings/teams/configuration/settings/TeamSettingsConfigurationSettings";

export default[
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        path: '/settings',
        name: 'settings.tabs.organization.settings',
        component: TeamSettingsConfigurationSettings,
        // permissions: { code: 'teams', permissionCode: 'team_settings' },
        disabled: false,
    },
    {
        path: '/qualifications',
        name: "settings.tabs.teams.configuration.qualifications",
        component: TeamSettingsConfigurationQualificationRestrictions,
        permissions: { code: 'teams', permissionCode: 'team_settings', action: 'EDIT' },
    },
    {
        redirect: true,
        dynamicRedirect: true,
        from: '/settings/teams',
        to: '/general',
    },
];