import { useSpordleTable } from '@spordle/datatables';
import { FormikCheckedButton, FormikDateTime, FormikGroup, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import {
    Button, Card, Col,
    Collapse, Fade, FormText, Label, ModalBody,
    ModalFooter, Row
} from "reactstrap";
import { mutate } from 'swr';
import { array, bool, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import CustomAlert from '../../../../../../components/CustomAlert';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { fail } from "@spordle/toasts";
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../../components/UserImg';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { PositionsContext } from '../../../../../../contexts/PositionsContext';
import { RolesContext } from '../../../../../../contexts/RolesContext';
import { TeamsContext } from '../../../../../../contexts/TeamsContext';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { displayI18n, DisplayI18n } from '../../../../../../helpers/i18nHelper';
import QuickViewButton from '../../../../../../components/quickView/QuickViewButton';
import InlineCopy from '../../../../../../components/inlineCopy/InlineCopy';

const TeamRosterAddReview = ({ from, toggle, selected, removeMember, emptyMembers, previousStep, nextStep, setSelectedMembers }) => {
    const { getPositions } = useContext(PositionsContext);
    const { getTeam, createTeamMember, createTeamMemberBranch, cachedTeam } = useContext(TeamsContext);
    const { organisation_id } = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { canDoAction } = useContext(RolesContext);
    const { setMemberPrimaryTeam } = useContext(MembersContext);

    const { refreshTable } = useSpordleTable();
    const [ restrictrionsErrors, setRestrictrionsErrors ] = useState({});
    const [ positions, setPositions ] = useState([]);

    const [ teamMembersAdded, setTeamMembersAdded ] = useState(false)
    const [ branchMembersAdded, setBranchMembersAdded ] = useState(false)

    const catchAddTeamMember = (errors, setStatus, setRestrictrionsErrors, teamMembers) => {
        if(!AxiosIsCancelled(errors[0].message)){
            const statusError = [];
            const restrictrionsErrors = {};

            for(let index = 0; index < errors.length; index++){
                const error = errors[index];
                switch (error.code){
                    case "3379": // Warnings
                        const splittedOrigin = error.origin.split('.');
                        const name = `${/'(.*?)'/.exec(splittedOrigin[1])[1]}`.trim();// Stringify before trim

                        // Get the member id according to the index
                        const memberIndex = splittedOrigin[0].substring(splittedOrigin[0].indexOf('[') + 1, splittedOrigin[0].indexOf(']'));
                        const memberId = teamMembers[memberIndex]?.member_id;

                        if(memberId){
                            if(!Array.isArray(restrictrionsErrors[memberId])){
                                restrictrionsErrors[memberId] = [];
                            }
                            restrictrionsErrors[memberId].push(
                                <Translate id={`teams.profile.settings.error.${error.code}`} values={{ data: name }} />,
                            );
                        }
                        break;
                    default:
                        if(error?.origin?.index){ // When an index is present, we are specifying a specific member index in the submission (errors related to a specific member)
                            statusError.push({
                                error: `${displayI18n("message", error.i18n, error.message, getGenericLocale())}`,
                                member: teamMembers[error?.origin?.index],
                            })
                        }else{ // Default way to show errors using i18n and origin for specific values. Origin can be null (Team settings related errors)
                            statusError.push({
                                error: `${displayI18n("message", error.i18n, error.message, getGenericLocale())} ${error.origin ? `(${error.origin})` : ''}`,
                            })
                        }
                        break;
                }
            }

            setRestrictrionsErrors(restrictrionsErrors);
            setStatus(statusError);
            console.error(errors);
        }
    }

    useEffect(() => {
        if(selected.length <= 0) previousStep();
    }, [ selected ]);

    useEffect(() => {
        setPositions(null);
        getPositions(organisation_id, { active: "1" })
            .then((positions) => {
                setPositions(
                    positions.reduce((newArray, pos) => {
                        if(pos.active == 1){
                            if(from === 'bench'){
                                if(pos.position_group?.position_type === 'STAFF' || pos.position_group?.position_type === 'COACH'){
                                    newArray.push(pos)
                                }
                            }else if(pos.position_group?.position_type === 'PLAYER'){
                                newArray.push(pos)
                            }
                        }
                        return newArray
                    }, [])
                        .sort((a, b) => {
                            const aName = displayI18n("name", a.i18n, a.name, getGenericLocale());
                            const bName = displayI18n("name", b.i18n, b.name, getGenericLocale());
                            return aName > bName ? 1 : aName < bName ? -1 : 0;
                        }),
                );
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setPositions([]);
                }
            })
    }, []);

    const getBranchMemberCall = (branchTeamMembers) => {
        if(canDoAction("ADD", "teams", "teams_approbation") && branchTeamMembers && branchTeamMembers.length > 0 && !branchMembersAdded){
            return createTeamMemberBranch(cachedTeam.team_id, branchTeamMembers, true)
                .then(() => setBranchMembersAdded(true))
        }
        return Promise.resolve()
    }

    const getTeamMemberCall = (teamMembers) => {
        if(teamMembers && teamMembers.length > 0 && !teamMembersAdded){
            return createTeamMember(cachedTeam.team_id, teamMembers, true)
                .then(async({ status, team_member_ids }) => {
                    setTeamMembersAdded(true);
                    await Promise.all(team_member_ids.map((id, index) => {
                        if(teamMembers[index].is_primary_team && !teamMembers[index].already_primary_team){
                            return setMemberPrimaryTeam(teamMembers[index].member_id, cachedTeam.team_id)
                                .catch((e) => {
                                    return Promise.resolve();
                                })
                        }
                        return Promise.resolve();
                    }))
                })
        }
        return Promise.resolve();
    }
    return (
        <Formik
            initialValues={selected.map((t) => ({ ...t, is_primary_team: t.primary_team?.team_id === cachedTeam.team_id, already_primary_team: t.primary_team?.team_id === cachedTeam.team_id }))}
            validationSchema={array().of(
                object().shape({
                    member_id: string(),
                    type: array(),
                    position_id: string().required(<Translate id='teams.profile.teamRoster.activeRoster.add.validation.position' />),
                    transferType: string()
                        .when('member_id', {
                            is: (val) => !!selected.find((m) => m.member_id === val && !!m.branchSearch),
                            then: string().required(<Translate id='teams.profile.teamRoster.activeRoster.add.validation.transferType' />),
                        }),
                    is_primary_team: bool(),
                }),
            )}
            onSubmit={(values, { setStatus }) => {
                const teamMembers = [ ...values.filter((m) => !m.branchSearch).map(({ type, ...value }) => {
                    return {
                        affiliate: from === 'affiliated' ? '1' : '0',
                        tryout: type?.indexOf('tryout') >= 0 ? '1' : '0',
                        import: type?.indexOf('import') >= 0 ? '1' : '0',
                        ...value,
                    }
                }) ];

                const branchTeamMembers = [ ...values.filter((m) => !!m.branchSearch).map(({ type, ...value }) => {
                    return {
                        ...value,
                        affiliate: from === 'affiliated' ? '1' : '0',
                        tryout: type?.indexOf('tryout') >= 0 ? '1' : '0',
                        import: type?.indexOf('import') >= 0 ? '1' : '0',
                        transfer: (value.transferType === 'TRANSFER' || value.transferType === 'SHARE') ? '1' : '0',
                        share: value.transferType === 'SHARE' ? '1' : '0',
                        shared_expiration_date: (value.transferType === 'SHARE' && value.shareExpiration) ? moment(value.shareExpiration).format('YYYY-MM-DD') : null,
                    }
                }) ];

                setStatus();
                setRestrictrionsErrors({});

                return Promise.all([
                    getTeamMemberCall(teamMembers),
                    getBranchMemberCall(branchTeamMembers),
                ])
                    .then(() => {
                        emptyMembers();
                        refreshTable();
                        nextStep();
                        mutate([ 'getTeamsSettings', cachedTeam.team_id ]);
                        getTeam(cachedTeam.team_id, true, { components: 'contact,address,competition' })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            })
                    }).catch((error) => {
                        catchAddTeamMember(error, setStatus, setRestrictrionsErrors, teamMembers)
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalBody>
                            {formik.values.some((member) => !!member.position_invoice) &&
                                <div className='d-flex justify-content-end mb-1'>
                                    <button
                                        className='reset-btn text-link'
                                        type='button'
                                        onClick={() => {
                                            formik.setValues(formik.values.map((member) => ({
                                                ...member,
                                                position_id: member.position_invoice?.position_id || '',
                                            })))
                                        }}
                                    >
                                        <Translate id='teams.profile.teamRoster.activeRoster.add.applyAll' />
                                    </button>
                                </div>
                            }
                            {formik.values.map((member, index) => {
                                const autoTransferMember = member.branchSearch;
                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Card body key={member.member_id + index} className={stringBuilder("card-shadow mb-2", { 'shadow-danger': restrictrionsErrors[member.member_id]?.length })}>
                                        <Row className="align-items-center">
                                            <Col sm="4">
                                                <UserDisplay>
                                                    <UserDisplay.Container>
                                                        <UserImg
                                                            abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                                            src={member.picture?.full_path}
                                                            filePos={member.picture?.file_position}
                                                            width={40}
                                                            alt={member.first_name + ' ' + member.last_name}
                                                        />
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container>
                                                        <UserDisplay.Title><QuickViewButton member={{ member_id: member.member_id }}>{member.first_name} {member.last_name}</QuickViewButton></UserDisplay.Title>
                                                        <UserDisplay.Subtitle><InlineCopy toCopy={member.unique_identifier}>#{member.unique_identifier}</InlineCopy></UserDisplay.Subtitle>
                                                        <UserDisplay.Subtitle><Translate id='members.profile.memberProfile.header.yearsOld' values={{ age: member.age }} /></UserDisplay.Subtitle>
                                                        {autoTransferMember &&
                                                            <UserDisplay.Subtitle><i className="text-primary mdi mdi-information-outline mr-1" /><DisplayI18n field='name' defaultValue={member.organisation.organisation_name} i18n={member.organisation.i18n} /> {member.organisation.abbreviation && '(' + member.organisation.abbreviation + ')'}</UserDisplay.Subtitle>
                                                        }
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            </Col>
                                            <Col sm="3" className="pl-0">
                                                <FormikSelect
                                                    manualError
                                                    id={index + '.position_id'}
                                                    name={index + '.position_id'}
                                                    renderOption={({ option }) => {
                                                        return (
                                                            <>
                                                                <DisplayI18n
                                                                    defaultValue={option.label}
                                                                    i18n={option.i18n}
                                                                    field="name"
                                                                />
                                                                <div className='small text-muted'>
                                                                    <DisplayI18n
                                                                        defaultValue={option.position_group?.name}
                                                                        i18n={option.position_group?.i18n}
                                                                        field="name"
                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                    }}
                                                    searchKeys={[
                                                        `i18n.${getGenericLocale()}.name`,
                                                        `position_group.i18n.${getGenericLocale()}.name`,
                                                    ]}
                                                    onOptionSelected={() => {
                                                        setRestrictrionsErrors((prevState) => {
                                                            delete prevState[`members[${index}]`]
                                                            return prevState;
                                                        })
                                                    }}
                                                    isLoading={!positions}
                                                    options={positions
                                                        ?.sort((posA, posB) => displayI18n('name', posA.position_group.i18n, posA.position_group.name, getGenericLocale()).localeCompare(displayI18n('name', posB.position_group.i18n, posB.position_group.name, getGenericLocale())))
                                                        ?.map((pos) => ({
                                                            value: pos.position_id,
                                                            label: pos.name,
                                                            i18n: pos.i18n,
                                                            position_group: pos.position_group,
                                                        }))}
                                                />
                                                {member.position_invoice &&
                                                    <FormText>
                                                        <button
                                                            className='reset-btn text-link' type='button'
                                                            onClick={() => {
                                                                formik.setFieldValue(`${index}.position_id`, member.position_invoice.position_id);
                                                            }}
                                                        >
                                                            <DisplayI18n field='name' defaultValue={member.position_invoice.name} i18n={member.position_invoice.i18n} />
                                                        </button>
                                                    </FormText>
                                                }
                                                {formik.errors[index]?.position_id && <div className="small text-danger">{formik.errors[index]?.position_id}</div>}
                                            </Col>
                                            <Col sm="5">
                                                <div className="d-flex align-items-center">
                                                    {/* defaulted to position from previous team */}
                                                    <div className='flex-grow-1 pl-3 pr-3 border-right mr-3'>
                                                        {from !== 'bench' &&
                                                            <>
                                                                <FormikGroup name={index + '.type'}>
                                                                    <FormikCheckedButton id={index + '.tryout'} value='tryout' name={index + '.type'} label='teams.profile.teamRoster.activeRoster.add.tryout' translateLabel className='mr-2' />
                                                                    <FormikCheckedButton id={index + '.import'} value='import' name={index + '.type'} label='teams.profile.teamRoster.activeRoster.add.import' translateLabel />
                                                                </FormikGroup>
                                                                {formik.errors[index]?.type && <div className="small text-danger">{formik.errors[index]?.type}</div>}
                                                            </>
                                                        }
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="reset-btn text-danger"
                                                            onClick={() => {
                                                                removeMember(member.member_id);
                                                                formik.setValues(formik.values.filter((formikMember) => member.member_id !== formikMember.member_id))
                                                            }}
                                                        >
                                                            <Translate id="misc.remove" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        {autoTransferMember &&
                                            <Row className="align-items-center">
                                                <Col sm="4" />
                                                <Col sm="3" className="pl-0 pt-3 border-top">
                                                    <Label><Translate id="teams.profile.teamRoster.activeRoster.add.selectTransfer" /></Label>
                                                    <FormikSelect
                                                        manualError
                                                        id={index + '.transferType'}
                                                        name={index + '.transferType'}
                                                        defaultData={[
                                                            {
                                                                value: 'TRANSFER',
                                                                label: 'teams.profile.teamRoster.activeRoster.add.select.transfer',
                                                                translateLabel: true,
                                                            },
                                                            {
                                                                value: 'SHARE',
                                                                label: 'teams.profile.teamRoster.activeRoster.add.select.share',
                                                                translateLabel: true,
                                                            },
                                                        ]}
                                                        loadingStatus='success'
                                                    />
                                                    {formik.errors[index]?.transferType && <div className="small text-danger">{formik.errors[index]?.transferType}</div>}
                                                </Col>
                                                <Col sm="5" className={formik.values[index].transferType === 'SHARE' && "border-top pt-3"}>
                                                    <Collapse isOpen={formik.values[index].transferType === 'SHARE'} mountOnEnter unmountOnExit>
                                                        <Fade>
                                                            <Label><Translate id="teams.profile.teamRoster.activeRoster.add.shareExpiration" /></Label>
                                                            <FormikDateTime name={index + '.shareExpiration'} id={index + '.shareExpiration'} timeFormat={false} />
                                                        </Fade>
                                                    </Collapse>
                                                </Col>

                                            </Row>
                                        }
                                        {(from !== 'affiliated' && member.already_in_another_team == 1) &&
                                            <>
                                                <div className='my-3 border-bottom' />
                                                <FormikCheckedButton
                                                    id={index + '.is_primary_team'}
                                                    name={index + '.is_primary_team'}
                                                    label='teams.profile.teamRoster.activeRoster.add.primaryTeam'
                                                    translateLabel
                                                    disabled={!!member.already_primary_team}
                                                />
                                                {member.primary_team && <InlineCopy toCopy={member.primary_team.unique_identifier} tag='div' className='text-muted small'><Translate id="members.profile.teams.currentPrimaryTeam" />: {member.primary_team.name} (#{member.primary_team.unique_identifier})</InlineCopy>}
                                                {member.already_primary_team && <div className='text-muted small'><Translate id="teams.profile.teamRoster.activeRoster.add.alreadyPrimaryTeam" /></div>}
                                            </>
                                        }

                                        {Array.isArray(restrictrionsErrors[member.member_id]) &&
                                            <Collapse appear isOpen className='small mt-1 text-danger'>
                                                <Fade>
                                                    <hr />
                                                    <ul className="ml-n4 mb-0">
                                                        {restrictrionsErrors[member.member_id].map((error, index) => (
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            <li key={member.member_id + index} className="">{ error }</li>
                                                        ))}
                                                    </ul>
                                                </Fade>
                                            </Collapse>
                                        }
                                    </Card>
                                )
                            })}
                            <Collapse isOpen={!!formik.status || Object.values(restrictrionsErrors).filter((error) => !Array.isArray(error)).length > 0} mountOnEnter unmountOnExit>
                                <Fade>
                                    <CustomAlert
                                        color='danger' withTitle translateText={false}
                                        translateTitle={false}
                                        title={
                                            <Translate
                                                id='teams.profile.settings.errors' values={{
                                                    errorCount: Object.values(restrictrionsErrors).filter((error) => !Array.isArray(error)).length + (formik.status ? 1 : 0),
                                                }}
                                            />
                                        }
                                        text={
                                            <ul className='pl-3 mb-0'>
                                                {Object.keys(restrictrionsErrors).map((errorKey) => {
                                                    const error = restrictrionsErrors[errorKey];
                                                    return !Array.isArray(error) && <li key={errorKey}>{error}</li>
                                                })}
                                                {(formik.status && Array.isArray(formik.status)) &&
                                                    formik.status.map((error, key) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <li key={error.error + '-' + error.member?.member_id + '-' + key}>
                                                            {error.error}
                                                            {error.member ? <span className='d-block'><QuickViewButton member={{ member_id: error.member?.member_id }}>{error.member.first_name + ' ' + error.member.last_name}</QuickViewButton></span> : null}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                    />
                                </Fade>
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' type="button" onClick={previousStep} outline className="mr-auto" disabled={formik.isSubmitting}><Translate id='misc.previous' /></Button>
                            <Button color='primary' type="submit" disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                            <Button color='primary' type="button" onClick={toggle} outline disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default TeamRosterAddReview;
