export default{
    "tour.step.1.title"   : "New UI & Navigation",
    "tour.step.1.message" : "We've updated {spordle_id} to make things easier for you!",

    "tour.step.2.title"   : "Dashboard",
    "tour.step.2.message" : "A brand new dashboard that will centralize important information for your day to day task.",

    "tour.step.3.title.old"   : "Manage",
    "tour.step.3.message.old" : "Manage everything in your organization.",

    "tour.step.3.title"   : "Main Navigation",
    "tour.step.3.message" : "Everything you may be looking for can be found through the header navigation menus.",

    "tour.step.4.title"   : "Tasks",
    "tour.step.4.message" : "Complete tasks for your organization.",

    "tour.step.5.title"   : "Catalog",
    "tour.step.5.message" : "Configure your registration season in the Catalog menu.",

    "tour.step.6.title"   : "Settings",
    "tour.step.6.message" : "Configure your organization and registrations.",

    "tour.step.7.title"   : "Sidebar",
    "tour.step.7.message" : "The sidebar is here to make your navigation easier.<break></break><break></break>History & Favorites: Two features that can help you get around quicker.",

    "tour.step.helpCenter.title"   : "Help Center",
    "tour.step.helpCenter.message" : "Our new Help Center can now be kept open and moved around the screen to keep reading while you navigate.<break></break><break></break>Content will be shown and will adapt as you navigate through different pages.",

    "tour.step.8.title"   : "Your Profile",
    "tour.step.8.message" : "Here you can quickly access your account and your support requests as well as sign out.",

    "tour.step.9.title"   : "Navigation Toggle",
    "tour.step.9.message" : "Switch back to the old navigation at anytime. Please leave us a comment so we can improve your experience.",

    "tour.step.10.title"   : "You're Good to Go!",
    "tour.step.10.message" : "We hope this new navigation will improve your day to day work with our software. Feel free to send us any suggestions in our help center.",
    "tour.step.10.optin"   : "Don't show me again",
    "tour.step.gotIt.btn"  : "Got it",

    "tour.step.reports.title"   : "Reports",
    "tour.step.reports.message" : "Navigate in our new report dashboard to quickly access your favorite reports. The reports have been reorganized to improve the access.",

    "tour.step.quickViewBtn.title"   : "New Quick Views!",
    "tour.step.quickViewBtn.message" : "You can click on this icon to open one or multiple participant profiles in the quickview window to avoid navigating from different pages. This icon will be placed next to each participant's name throughout the platform.",

    "tour.step.quickViewToggler.title"   : "View your Saved Members",
    "tour.step.quickViewToggler.message" : "You can click on this icon to open all of your saved quick views. As long as you keep them open, those profiles will be available in the Quickview for you to review.",

    "tour.step.newOrgSettings.title"   : "New Organization Settings!",
    "tour.step.newOrgSettings.message" : "We have merged the organization information and the organization settings in one single place.",

    "tour.step.newOrgSettings.general.title"   : "General",
    "tour.step.newOrgSettings.general.message" : "The general tab gives you access to the basic information of your organization and the merchant account setup.",

    "tour.step.newOrgSettings.documents.title"   : "Documents",
    "tour.step.newOrgSettings.documents.message" : "Setup the documents that you want a participant to upload while doing the registration.  You can create your own document type.",

    "tour.step.newOrgSettings.registrationSettings.title"   : "Registration Settings",
    "tour.step.newOrgSettings.registrationSettings.message" : "Provide us the date you are expecting to launch the registration and with what platform. This will help us in scheduling training.",

    "tour.step.newOrgSettings.regionalSettings.title"   : "Regional and Registration Settings",
    "tour.step.newOrgSettings.regionalSettings.message" : "Here are placed the organization's regional and registration settings.",

    "tour.step.newOrgSettings.crc.title"   : "Background Check",
    "tour.step.newOrgSettings.crc.message" : "Setup the way you want to manage the Background Check validation.",

    "tour.step.newOrgSettings.payments.title"   : "Merchant Accounts",
    "tour.step.newOrgSettings.payments.message" : "Validate the information of the merchant account or open a merchant account to process transaction.",

    "tour.step.saveReports.title"   : "New Custom Reports!",
    "tour.step.saveReports.message" : "You can now save a custom report, with custom columns with this button.",

    "tour.step.reportsDashboard.title"           : "Reports are under construction.",
    "tour.step.reportsDashboard.subtitle"        : "New Groups and Better Usability",
    "tour.step.reportsDashboard.message"         : "Please be aware some report filters, columns and/or data may not be fully functional. A fully functional report module will be released next week. A new pop-up window will notify you when it's ready.",
    "tour.step.reportsDashboard.groups.title"    : "Groups",
    "tour.step.reportsDashboard.groups.message"  : "The reports list have been split into different groups for quicker access.",
    "tour.step.reportsDashboard.custom.title"    : "Custom Reports",
    "tour.step.reportsDashboard.custom.message"  : "You can consult your custom reports here.",
    "tour.step.reportsDashboard.exports.title"   : "Exported Reports",
    "tour.step.reportsDashboard.exports.message" : "Your exported reports are listed in this category.",
    "tour.step.reportsDashboard.search.title"    : "Search Bar",
    "tour.step.reportsDashboard.search.message"  : "You can search a specific report here.",

    "tour.step.rollover.title"                  : "Rollover your registration fees for the next period!",
    "tour.step.rollover.message"                : "This new feature will let you rollover your registration fees all at once from a selected period. Please be careful not to rollover twice!",
    "tour.step.rollover.season.title"           : "Season",
    "tour.step.rollover.season.message"         : "The fees will be created in this season. Make sure you select the proper one.",
    "tour.step.rollover.previousSeason.title"   : "Previous Season",
    "tour.step.rollover.previousSeason.message" : "Pick the season where the fees are currently created and select to launch the process.",

}