import React from "react";
import {
    Row,
    Col,
    Spinner,
    Fade,
    Label
} from "reactstrap";
import { FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate from "@spordle/intl-elements";
import FormikEditable from "../../../../components/formik/FormikEditable";
import withContexts from "../../../../helpers/withContexts";
import { success, fail } from '@spordle/toasts';
import { string, number, object } from 'yup';
import OverlayLoader from "../../../../components/loading/OverlayLoader";

// Contexts
import { RegistrationCategoriesContext } from '../../../../contexts/RegistrationCategoriesContext';
import { DisplayI18n, displayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';
import { RegistrationClassesContext } from "../../../../contexts/RegistrationClassesContext";
import { RegistrationDivisionsContext } from "../../../../contexts/RegistrationDivisionsContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { I18nContext } from "../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import SidePanel from "../../../../components/sidePanel/SidePanel";
import { DisplayCategory } from "../../../teams/TeamHelpers";
import { RolesContext } from "../../../../contexts/RolesContext";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";

const SideMenuModalContent = ({ category }) => (
    <div>
        <DisplayI18n
            field="short_name"
            i18n={category.division.i18n}
            defaultValue={category.division.short_name}
        />
        { category.class &&
            <span> <DisplayI18n field="short_name" i18n={category.class?.i18n} defaultValue={category.class?.short_name} /></span>
        }
    </div>
)

/**
* this file is kind of a mess because the editable input depends a lot on viewAs
* we should probably refactor this to make it clearer
*/
class SettingsRegistrationCategorySidepanel extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isLoading: true,
            parentCat: null,
        }
    }

    componentDidMount(){
        this.setState({ isLoading: false });

        if(this.props.viewAs == 1 && this.props.selectedRows[0].team_parent_category?.team_category_id){
            this.findParentCat(this.props.selectedRows[0].team_parent_category?.team_category_id);
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.viewAs == 1 && this.props.selectedRows[0].team_parent_category?.team_category_id !== prevProps.selectedRows[0].team_parent_category?.team_category_id){
            this.findParentCat(this.props.selectedRows[0].team_parent_category?.team_category_id);
        }
    }

    findParentCat = (catId) => {
        this.setState(() => ({ parentCat: this.props.tableRef.getData().find((cat) => cat.team_category_id === catId) || null }))
    }

    toggleState = (bool) => this.setState((prevState) => ({ isLoading: bool ?? !prevState.isLoading }));

    createSuggestedName = (divisionId, classId, lang) => {
        if(divisionId){
            const division = (this.props.classDivision.divisions || []).find((division) => division.division_id === divisionId);

            if(division){
                let suggestedName = displayI18n('name', division.i18n, division.name, lang);
                if(classId){
                    const classFound = this.props.classDivision.classes.find((_class) => _class.class_id === classId);
                    if(classFound){
                        suggestedName += ` - ${displayI18n('name', classFound.i18n, classFound.name, lang)}`;
                    }
                }
                return suggestedName;
            }
        }
        return '';
    }

    render(){
        const canEditAgeAction = this.props.RolesContext.canDoAction('EDIT', 'teams', 'team_categories');
        const canEditAge = canEditAgeAction || this.props.IdentityRolesContext.isGod();
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <SidePanel.Header>
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />
                        {this.props.viewAs !== 2 && // not MHA
                            this.props.OrganizationContext.organisation_id === this.props.selectedRows[0].organisation.organisation_id &&
                                <SidePanel.ActionsMenu>
                                    <CanDoAction action='EDIT' componentCode='teams' componentPermissionCode='team_categories'>
                                        {/* Disable category */}
                                        <SidePanel.MenuDelete
                                            color="warning"
                                            label={`misc.${this.props.selectedRows[0].active == 1 ? 'disable' : 'enable'}`}
                                            translateModalMsg
                                            translateActionButtonText
                                            actionButtonText='misc.disable'
                                            modalMsg={`registration.settings.category.sidePanel.${this.props.selectedRows[0].active == 1 ? 'disable' : 'enable'}All`}
                                            modalTitle={<SideMenuModalContent category={this.props.selectedRows[0]} />}
                                            onConfirm={() => {
                                                return this.props.RegistrationCategoriesContext.updateRegistrationCategory(this.props.selectedRows[0].team_category_id, { active: this.props.selectedRows[0].active == 1 ? '0' : '1' })
                                                    .then(() => {
                                                        success();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                            }}
                                        />
                                    </CanDoAction>
                                    <CanDoAction action='DELETE' componentCode='teams' componentPermissionCode='team_categories'>
                                        {/* Delete category  */}
                                        <SidePanel.MenuDelete
                                            modalTitle={<SideMenuModalContent category={this.props.selectedRows[0]} />}
                                            translateModalMsg
                                            modalMsg='registration.settings.category.sidePanel.delete'
                                            onConfirm={() => {
                                                return this.props.RegistrationCategoriesContext.deleteRegistrationCategory(this.props.selectedRows[0].team_category_id)
                                                    .then(() => {
                                                        this.props.tableRef.deleteRow(this.props.selectedRows[0].team_category_id)
                                                        this.props.toggle();
                                                        success();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                            }}
                                        />
                                    </CanDoAction>
                                </SidePanel.ActionsMenu>
                        }
                    </div>
                    <SidePanel.Title>
                        <DisplayI18n
                            field='short_name'
                            defaultValue={this.props.selectedRows[0].division.short_name}
                            i18n={this.props.selectedRows[0].division.i18n}
                        />
                        { this.props.selectedRows[0].class &&
                            <>
                                {' - '}
                                <DisplayI18n
                                    field='short_name'
                                    defaultValue={this.props.selectedRows[0].class?.short_name}
                                    i18n={this.props.selectedRows[0].class?.i18n}
                                />
                            </>
                        }
                    </SidePanel.Title>
                    <SidePanel.Subtitle>
                        <DisplayI18n
                            field='name'
                            defaultValue={this.props.selectedRows[0].division.name}
                            i18n={this.props.selectedRows[0].division.i18n}
                        />
                        { this.props.selectedRows[0].class &&
                            <>
                                {' - '}
                                <DisplayI18n
                                    field='name'
                                    defaultValue={this.props.selectedRows[0].class?.name}
                                    i18n={this.props.selectedRows[0].class?.i18n}
                                />
                            </>
                        }
                    </SidePanel.Subtitle>
                </SidePanel.Header>
                <div className="p-3">
                    <RenderI18nForm field='name'>
                        {({ fieldName, fieldLabel, lang }) => (
                            <div className="mb-3" key={fieldName}>
                                <Label for={fieldName} className="text-muted mb-0">{fieldLabel}</Label>
                                <FormikEditable
                                    id={fieldName}
                                    initialValues={this.props.I18nHelperContext.getInitialValues(this.props.selectedRows[0])}
                                    validationSchema={object().shape(
                                        this.props.I18nHelperContext.getValidationSchema({ name: string() }),
                                    )}
                                    onSubmit={(values) => {
                                        this.toggleState(true);

                                        // logic to apply suggested value when name fields are empty
                                        for(const lang in values.i18n){
                                            if(!values.i18n[lang].name)
                                                values.i18n[lang].name = this.createSuggestedName(this.props.selectedRows[0].division.division_id, this.props.selectedRows[0].class.class_id, lang)
                                        }

                                        const apiValues = {
                                            ...this.props.I18nHelperContext.getAPIValues(values),
                                        }
                                        delete apiValues.i18n // remove i18n object
                                        const newValues = this.props.createNewValues(values)

                                        this.props.RegistrationCategoriesContext.updateRegistrationCategory(this.props.selectedRows[0].team_category_id, apiValues)
                                            .then(() => {
                                                this.props.syncRows(newValues);
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => this.toggleState(false))
                                    }}
                                >
                                    {(isEditing) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    <DisplayI18n lang={lang} field={fieldName} i18n={this.props.selectedRows[0].i18n} defaultValue={this.props.selectedRows[0].name || '-'} />
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikInputText name={fieldName} id={fieldName} trim autoFocus />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                        )}
                    </RenderI18nForm>
                    <Row form>
                        {this.props.viewAs !== 2 && // not MHA
                            <>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='registration.settings.category.label.division' /></div>
                                        <FormikEditable
                                            noConfirmation
                                            disabled={this.props.OrganizationContext.organisation_id !== this.props.selectedRows[0].organisation.organisation_id}
                                            id='category_division'
                                            initialValues={{
                                                division_id: this.props.selectedRows[0].division?.division_id || '',
                                            }}
                                            validationSchema={object().shape({
                                                division_id: string().required(<Translate id='registration.settings.category.form.division.required' />),
                                            })}
                                            onSubmit={(values) => {
                                                if(values.division_id){
                                                    const selectedDivision = this.props.classDivision?.divisions?.find((div) => div.division_id == values.division_id);

                                                    if(selectedDivision && selectedDivision.division_id !== this.props.selectedRows[0].division?.division_id){
                                                        this.toggleState(true);
                                                        const newValues = this.props.createNewValues({ division: selectedDivision });
                                                        this.props.RegistrationCategoriesContext.updateRegistrationCategory(this.props.selectedRows[0].team_category_id, { division_id: selectedDivision.division_id })
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                }
                                                            })
                                                            .finally(() => this.toggleState(false))
                                                    }
                                                }
                                            }}
                                        >
                                            {(isEditing, options, formik) => {
                                                if(!isEditing){
                                                    return (
                                                        <div className='font-medium text-dark'>
                                                            <DisplayI18n
                                                                field='short_name'
                                                                defaultValue={this.props.selectedRows[0].division?.short_name || '-'}
                                                                i18n={this.props.selectedRows[0].division?.i18n}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                return (
                                                    <FormikSelect
                                                        name="division_id" id="division_id"
                                                        autoFocus menuIsDefaultOpen
                                                        onOptionSelected={options.stopEditing}
                                                        searchKeys={[
                                                            `i18n.${this.props.I18nContext.getGenericLocale()}.short_name`,
                                                        ]}
                                                        renderOption={(option) => <DisplayI18n field='short_name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                        options={this.props.classDivision?.divisions?.map((division) => ({
                                                            label: division.short_name,
                                                            value: division.division_id,
                                                            i18n: division.i18n,
                                                        }))}
                                                    />
                                                )

                                            }}
                                        </FormikEditable>
                                        { this.props.selectedRows[0].division?.active == "0" &&
                                            <div className="text-warning small">
                                                <i className="mdi mdi-alert-outline" /> <Translate id='registration.settings.category.sidePanel.divisionDisabled' />
                                            </div>
                                        }
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='registration.settings.category.label.class' /></div>
                                        <FormikEditable
                                            noConfirmation
                                            disabled={this.props.OrganizationContext.organisation_id !== this.props.selectedRows[0].organisation.organisation_id}
                                            id='category_class'
                                            initialValues={{
                                                class_id: this.props.selectedRows[0].class?.class_id || '',
                                            }}
                                            onSubmit={(values) => {
                                                const selectedClass = this.props.classDivision?.classes?.find((_class) => _class.class_id == values.class_id);

                                                if(selectedClass?.class_id !== this.props.selectedRows[0].class?.class_id){
                                                    this.toggleState(true);
                                                    const newValues = this.props.createNewValues({ class: selectedClass });
                                                    this.props.RegistrationCategoriesContext.updateRegistrationCategory(this.props.selectedRows[0].team_category_id, { class_id: selectedClass?.class_id ?? null })
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                        .finally(() => this.toggleState(false))
                                                }
                                            }}
                                        >
                                            {(isEditing, options) => {
                                                if(!isEditing){
                                                    return (
                                                        <div className='font-medium text-dark'>
                                                            <DisplayI18n
                                                                field='short_name'
                                                                defaultValue={this.props.selectedRows[0].class?.short_name || '-'}
                                                                i18n={this.props.selectedRows[0].class?.i18n}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                return (
                                                    <FormikSelect
                                                        name="class_id" id="class_id"
                                                        autoFocus menuIsDefaultOpen clearable
                                                        onOptionSelected={options.stopEditing}
                                                        searchKeys={[
                                                            `i18n.${this.props.I18nContext.getGenericLocale()}.short_name`,
                                                        ]}
                                                        renderOption={(option) => <DisplayI18n field='short_name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                        options={this.props.classDivision?.classes?.map((singleClass) => ({
                                                            label: singleClass.short_name,
                                                            value: singleClass.class_id,
                                                            i18n: singleClass.i18n,
                                                        }))}
                                                    />
                                                )

                                            }}
                                        </FormikEditable>
                                        { this.props.selectedRows[0].class?.active == "0" &&
                                            <div className="text-warning small">
                                                <i className="mdi mdi-alert-outline" /> <Translate id='registration.settings.category.sidePanel.classDisabled' />
                                            </div>
                                        }
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='registration.settings.class.form.label.order' /></div>
                                        <FormikEditable
                                            disabled={this.props.OrganizationContext.organisation_id !== this.props.selectedRows[0].organisation.organisation_id}
                                            id='category_display_order'
                                            initialValues={{
                                                display_order: this.props.getCommonValue("display_order"),
                                            }}
                                            validationSchema={object().shape({
                                                display_order: number()
                                                    .integer(<Translate id='registration.settings.class.form.validation.order.integer' />)
                                                    .moreThan(0, <Translate id='registration.settings.class.form.validation.order.moreThan0' />),
                                            })}
                                            onSubmit={(values) => {
                                                if(values.display_order !== this.props.getCommonValue("display_order") && values.display_order){
                                                    this.toggleState(true);
                                                    const newValues = this.props.createNewValues(values);
                                                    this.props.RegistrationCategoriesContext.updateRegistrationCategory(this.props.getCommonValue('team_category_id'), values)
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                        .finally(() => this.toggleState(false))
                                                }
                                            }}
                                        >
                                            {(isEditing) => {
                                                if(!isEditing){
                                                    return <div className='font-medium text-dark'>{this.props.getCommonValue("display_order") || '-'}</div>
                                                }
                                                return (
                                                    <FormikInputNumber
                                                        id='display_order'
                                                        name='display_order'
                                                        allowNegative={false}
                                                    />
                                                )

                                            }}
                                        </FormikEditable>
                                    </div>
                                </Col>
                            </>
                        }
                        <Col sm="6">
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='misc.status' /></div>
                                <FormikEditable
                                    noConfirmation
                                    disabled={this.props.OrganizationContext.organisation_id !== this.props.selectedRows[0].organisation.organisation_id}
                                    id='category_active_in_this_period'
                                    initialValues={{
                                        active: `${this.props.selectedRows[0].active_in_this_period}`,
                                    }}
                                    onSubmit={(values) => {
                                        if(this.props.selectedRows[0].active_in_this_period != values.active){
                                            this.toggleState(true);
                                            const newValues = this.props.createNewValues({ active_in_this_period: values.active });
                                            this.props.RegistrationCategoriesContext.updateRegistrationCategoryPeriodStatus(this.props.selectedRows[0].team_category_id, { active: values.active })
                                                .then(() => {
                                                    this.props.syncRows({
                                                        ...newValues,
                                                    });
                                                    success();
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                                .finally(() => this.toggleState(false))
                                        }
                                    }}
                                >
                                    {(isEditing, options, formik) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>{this.props.selectedRows[0].active_in_this_period == 1 ? <Translate id='misc.active' /> : <Translate id='misc.inactive' />}</div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                search={false} name='active' id='active'
                                                autoFocus menuIsDefaultOpen
                                                onOptionSelected={options.stopEditing}
                                                loadingStatus='success'
                                                defaultData={[
                                                    { value: '1', label: 'misc.active', translateLabel: true },
                                                    { value: '0', label: 'misc.inactive', translateLabel: true },
                                                ]}
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                        </Col>
                        {canEditAge &&
                            <>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='registration.settings.category.label.minAge' /></div>
                                        <FormikEditable
                                            id='category_min_age'
                                            disabled={!canEditAgeAction || this.props.OrganizationContext.organisation_id !== this.props.selectedRows[0].organisation.organisation_id}
                                            initialValues={{
                                                min_age: this.props.selectedRows[0].min_age || '',
                                            }}
                                            validationSchema={object().shape({
                                                min_age: number()
                                                    .required(<Translate id='registration.settings.category.form.minAge.required' />)
                                                    .integer(<Translate id='registration.settings.class.form.validation.order.integer' />)
                                                    .test({
                                                        name: "smallerOrEqualToMax",
                                                        message: <Translate id='registration.settings.category.form.minAge.smaller' />,
                                                        test: (minAge) => {
                                                            return parseInt(minAge) <= parseInt(this.props.selectedRows[0].max_age)
                                                        },
                                                    })
                                                    .test({
                                                        name: 'min-age-requirement',
                                                        message: () => <Translate id='registration.settings.category.form.minAge.requirements' values={{ age: this.props.selectedRows[0].team_parent_category.min_age }} />,
                                                        test: (value) => {
                                                            if(this.props.selectedRows[0].team_parent_category?.min_age){
                                                                return value >= this.props.selectedRows[0].team_parent_category.min_age
                                                            }
                                                            return true; // because max age not specified
                                                        },
                                                    }),
                                            })}
                                            onSubmit={(values) => {
                                                if(values.min_age !== this.props.selectedRows[0].min_age){
                                                    this.toggleState(true);
                                                    values.min_age = values.min_age || null; // Has to send null if empty
                                                    const newValues = this.props.createNewValues(values);
                                                    this.props.RegistrationCategoriesContext.updateRegistrationCategory(this.props.selectedRows[0].team_category_id, values)
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                            this.props.tableRef.refreshTable();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                        .finally(() => this.toggleState(false))
                                                }
                                            }}
                                        >
                                            {(isEditing) => {
                                                if(!isEditing){
                                                    return <div className="font-medium text-dark">{this.props.selectedRows[0].min_age || '-'}</div>
                                                }
                                                return (
                                                    <FormikInputNumber allowNegative={false} id="min_age" name="min_age" autoFocus />
                                                )

                                            }}
                                        </FormikEditable>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='registration.settings.category.label.maxAge' /></div>
                                        <FormikEditable
                                            id='category_max_age'
                                            disabled={!canEditAgeAction || this.props.OrganizationContext.organisation_id !== this.props.selectedRows[0].organisation.organisation_id}
                                            initialValues={{
                                                max_age: this.props.selectedRows[0].max_age || "",
                                            }}
                                            validationSchema={object().shape({
                                                max_age: number()
                                                    .required(<Translate id='registration.settings.category.form.maxAge.required' />)
                                                    .integer(<Translate id='registration.settings.class.form.validation.order.integer' />)
                                                    .test({
                                                        name: "greaterOrEqualToMin",
                                                        message: <Translate id='registration.settings.category.form.maxAge.bigger' />,
                                                        test: (maxAge) => {
                                                            return parseInt(maxAge) >= parseInt(this.props.selectedRows[0].min_age)
                                                        },
                                                    })
                                                    .test({
                                                        name: 'max-age-requirement',
                                                        message: () => <Translate id='registration.settings.category.form.maxAge.requirements' values={{ age: this.props.selectedRows[0].team_parent_category.max_age }} />,
                                                        test: (value) => {
                                                            if(this.props.selectedRows[0].team_parent_category?.max_age){
                                                                return value <= this.props.selectedRows[0].team_parent_category.max_age
                                                            }
                                                            return true; // because max age not specified
                                                        },
                                                    }),
                                            })}
                                            onSubmit={(values) => {
                                                if(values.max_age !== this.props.selectedRows[0].max_age){
                                                    this.toggleState(true);
                                                    values.max_age = values.max_age || null; // Has to send null if empty
                                                    const newValues = this.props.createNewValues(values);
                                                    this.props.RegistrationCategoriesContext.updateRegistrationCategory(this.props.selectedRows[0].team_category_id, values)
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                            this.props.tableRef.refreshTable();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                        .finally(() => this.toggleState(false))
                                                }
                                            }}
                                        >
                                            {(isEditing) => {
                                                if(!isEditing){
                                                    return <div className="font-medium text-dark">{this.props.selectedRows[0].max_age || '-'}</div>
                                                }
                                                return (
                                                    <FormikInputNumber allowNegative={false} id="max_age" name="max_age" autoFocus />
                                                )

                                            }}
                                        </FormikEditable>
                                    </div>
                                </Col>
                            </>
                        }
                        {this.props.viewAs === 0 && // viewAs HC
                            <>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='registration.settings.category.label.gender' /></div>
                                        {this.props.viewAs === 2 ?
                                            this.props.selectedRows[0].gender ?? '-'
                                            :
                                            <FormikEditable
                                                noConfirmation
                                                id='category_min_age'
                                                initialValues={{
                                                    gender: this.props.selectedRows[0].gender,
                                                }}
                                                validationSchema={object().shape({
                                                    gender: string().required(<Translate id='registration.settings.category.form.gender.required' />),
                                                })}
                                                onSubmit={(values) => {
                                                    if(values.gender !== this.props.selectedRows[0].gender){
                                                        this.toggleState(true);
                                                        const newValues = this.props.createNewValues(values);
                                                        this.props.RegistrationCategoriesContext.updateRegistrationCategory(this.props.selectedRows[0].team_category_id, values)
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                }
                                                            })
                                                            .finally(() => this.toggleState(false))
                                                    }
                                                }}
                                            >
                                                {(isEditing, options, formik) => {
                                                    if(!isEditing){
                                                        return this.props.selectedRows[0].gender ? <Translate id={`registration.settings.category.table.gender.${this.props.selectedRows[0].gender.toLowerCase()}`} /> : '-'
                                                    }
                                                    return (
                                                        <FormikSelect
                                                            search={false} name='gender' id='gender'
                                                            autoFocus menuIsDefaultOpen
                                                            onOptionSelected={options.stopEditing}
                                                            loadingStatus='success'
                                                            defaultData={[
                                                                { value: 'MALE', label: 'registration.settings.category.table.gender.male', translateLabel: true },
                                                                { value: 'FEMALE', label: 'registration.settings.category.table.gender.female', translateLabel: true },
                                                                { value: 'MIXED', label: 'registration.settings.category.table.gender.mixed', translateLabel: true },
                                                            ]}
                                                        />
                                                    )

                                                }}
                                            </FormikEditable>
                                        }
                                    </div>
                                </Col>
                            </>
                        } {/* End if (this.props.viewAs === 0) && // viewAs HC */}

                        {this.props.viewAs === 1 && this.props.IdentityRolesContext.federation.organisation_id !== this.props.selectedRows[0].organisation.organisation_id && // viewAs Branch
                            <>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='registration.settings.category.label.type' /></div>
                                        <FormikEditable
                                            noConfirmation
                                            disabled={this.props.OrganizationContext.organisation_id !== this.props.selectedRows[0].organisation.organisation_id}
                                            id='category_type'
                                            initialValues={{
                                                team_category_type_id: this.props.selectedRows[0].team_category_type?.team_category_type_id || '',
                                            }}
                                            onSubmit={(values) => {
                                                if(values.team_category_type_id !== this.props.selectedRows[0].team_category_type?.team_category_type_id || ''){
                                                    this.toggleState(true);
                                                    const newValues = this.props.createNewValues({ ...values, team_category_type: this.props.types.find((type) => type.team_category_type_id === values.team_category_type_id) });

                                                    this.props.RegistrationCategoriesContext.updateRegistrationCategory(this.props.selectedRows[0].team_category_id, values)
                                                        .then(() => {
                                                            this.props.syncRows({
                                                                ...newValues,
                                                                team_parent_category: this.props.tableRef?.getData().find((cat) => cat.team_category_id == values.team_category_id_parent),
                                                            });
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                        .finally(() => this.toggleState(false))
                                                }
                                            }}
                                        >
                                            {(isEditing, options, formik) => {
                                                if(!isEditing){
                                                    return (
                                                        <div className='font-medium text-dark'>
                                                            {this.props.selectedRows[0].team_category_type ?
                                                                <DisplayI18n
                                                                    field='name'
                                                                    defaultValue={this.props.selectedRows[0].team_category_type.name}
                                                                    i18n={this.props.selectedRows[0].team_category_type.i18n}
                                                                />
                                                                : '-'}
                                                        </div>
                                                    )
                                                }
                                                return (
                                                    <FormikSelect
                                                        search={false} name='team_category_type_id' id='team_category_type_id'
                                                        autoFocus menuIsDefaultOpen
                                                        onOptionSelected={options.stopEditing}
                                                        renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                        options={this.props.types?.map((type) => ({
                                                            value: type.team_category_type_id,
                                                            label: type.name,
                                                            i18n: type.i18n,
                                                        }))}
                                                    />
                                                )

                                            }}
                                        </FormikEditable>
                                    </div>
                                </Col>
                                { this.props.IdentityRolesContext.federation.organisation_id !== this.props.selectedRows[0].organisation.organisation_id &&
                                    <>
                                        <Col sm="12">
                                            <div className="mb-4">
                                                <div className="text-muted">
                                                    <Translate
                                                        id='registration.settings.category.label.federation.equivalent'
                                                        values={{ name: displayI18n('name', this.props.IdentityRolesContext.federation.i18n, this.props.IdentityRolesContext.federation.organisation_name, this.props.I18nContext.getGenericLocale()) }}
                                                    />
                                                </div>
                                                <FormikEditable
                                                    id='category_parent_cat'
                                                    noConfirmation
                                                    initialValues={{
                                                        team_category_id_parent: this.props.selectedRows[0].team_parent_category?.team_category_id || '',
                                                    }}
                                                    validationSchema={object().shape({
                                                        team_category_id_parent: string().required(<Translate id='registration.settings.category.form.parentCategory.required' />),
                                                    })}
                                                    onSubmit={(values) => {
                                                        if(values.team_category_id_parent !== this.props.selectedRows[0].team_parent_category?.team_category_id || ''){
                                                            this.toggleState(true);
                                                            const newValues = this.props.createNewValues(values);

                                                            this.props.RegistrationCategoriesContext.updateRegistrationCategory(this.props.selectedRows[0].team_category_id, values)
                                                                .then(() => {
                                                                    this.findParentCat(values.team_category_id_parent)
                                                                    this.props.syncRows({
                                                                        ...newValues,
                                                                        team_parent_category: this.state.parentCat,
                                                                    });
                                                                    success();
                                                                })
                                                                .catch((error) => {
                                                                    if(!AxiosIsCancelled(error.message)){
                                                                        console.error(error.message)
                                                                        fail({
                                                                            msg: 'misc.error',
                                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                            skipInfoTranslate: true,
                                                                        })
                                                                    }
                                                                })
                                                                .finally(() => this.toggleState(false))
                                                        }
                                                    }}
                                                >
                                                    {(isEditing, options, formik) => {
                                                        if(!isEditing){
                                                            return (
                                                                <div className='font-medium text-dark'>
                                                                    <DisplayI18n
                                                                        field='short_name'
                                                                        i18n={this.state.parentCat?.division?.i18n}
                                                                        defaultValue={this.state.parentCat?.division?.short_name || '-'}
                                                                    />
                                                                    { this.state.parentCat?.class &&
                                                                        <>
                                                                            {` - `}
                                                                            <DisplayI18n
                                                                                field='short_name'
                                                                                i18n={this.state.parentCat?.class?.i18n}
                                                                                defaultValue={this.state.parentCat?.class?.short_name || '-'}
                                                                            />
                                                                        </>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        return (
                                                            <FormikSelect
                                                                name='team_category_id_parent' id='team_category_id_parent'
                                                                autoFocus menuIsDefaultOpen
                                                                renderOption={(option) => <DisplayCategory category={option.option} short />}
                                                                onOptionSelected={options.stopEditing}
                                                                options={this.props.tableRef?.getData()
                                                                    .reduce((newArray, cat) => {
                                                                        if(cat.organisation.organisation_id.includes(this.props.IdentityRolesContext.federation.organisation_id) && cat.active == 1){
                                                                            newArray.push({
                                                                                value: cat.team_category_id,
                                                                                label: cat.name,
                                                                                division: cat.division,
                                                                                class: cat.class,
                                                                            })
                                                                        }
                                                                        return newArray
                                                                    }, [])
                                                                }
                                                            />
                                                        )

                                                    }}
                                                </FormikEditable>
                                                { this.state.parentCat?.active == 0 || this.state.parentCat?.active_in_this_period == 0 &&
                                                    <div className="text-warning small">
                                                        <i className="mdi mdi-alert-outline" /> <Translate id='registration.settings.category.sidePanel.parentCategoryDisabled' />
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                        <Col xs="12">
                                            <div className="border bg-light p-2">
                                                <div className="font-bold mb-3 text-dark">
                                                    <Translate
                                                        id='registration.settings.category.label.federation.equivalent'
                                                        values={{ name: displayI18n('name', this.props.IdentityRolesContext.federation.i18n, this.props.IdentityRolesContext.federation.organisation_name, this.props.I18nContext.getGenericLocale()) }}
                                                    />
                                                </div>
                                                { this.state.parentCat ?
                                                    <Fade className="row">
                                                        <Col xs="6">
                                                            <div className="mb-3">
                                                                <div className=""><Translate id='registration.settings.category.label.division' /></div>
                                                                <div className="font-medium text-dark">
                                                                    <DisplayI18n
                                                                        field='short_name'
                                                                        i18n={this.state.parentCat.division?.i18n}
                                                                        defaultValue={this.state.parentCat.division?.short_name || '-'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs="6">
                                                            <div className="mb-3">
                                                                <div className=""><Translate id='registration.settings.category.label.class' /></div>
                                                                <div className="font-medium text-dark">
                                                                    <DisplayI18n
                                                                        field='short_name'
                                                                        i18n={this.state.parentCat.class?.i18n}
                                                                        defaultValue={this.state.parentCat.class?.short_name || '-'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs="6">
                                                            <div className="mb-3">
                                                                <div className=""><Translate id='registration.settings.category.label.minAge' /></div>
                                                                <div className="font-medium text-dark">{this.props.selectedRows[0].team_parent_category?.min_age}</div>
                                                            </div>
                                                        </Col>
                                                        <Col xs="6">
                                                            <div className="mb-3">
                                                                <div className=""><Translate id='registration.settings.category.label.maxAge' /></div>
                                                                <div className="font-medium text-dark">{this.props.selectedRows[0].team_parent_category?.max_age}</div>
                                                            </div>
                                                        </Col>
                                                        <Col xs="6">
                                                            <div className="mb-3">
                                                                <div className=""><Translate id='registration.settings.category.label.gender' /></div>
                                                                <div className="font-medium text-dark">
                                                                    {this.props.selectedRows[0].team_parent_category?.gender ?
                                                                        <Translate id={`registration.settings.category.table.gender.${this.props.selectedRows[0].team_parent_category?.gender.toLowerCase()}`} />
                                                                        :
                                                                        '-'
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {/* <Col xs="6">
                                                            <div className="mb-3">
                                                                <div className=""><Translate id='registration.settings.category.label.startDate' /></div>
                                                                <div className="font-medium text-dark"><Translate id='registration.settings.category.label.startDate.value' /></div>
                                                            </div>
                                                        </Col> */}
                                                    </Fade>
                                                    :
                                                    <Spinner type="grow" color="primary" />
                                                }
                                            </div>
                                        </Col>
                                    </>
                                }
                            </>
                        } {/* end if (this.propsviewAs === 1) && // viewAs Branch */}
                    </Row>
                </div>
            </OverlayLoader>
        )
    }
}

export default withContexts(RegistrationCategoriesContext, RegistrationClassesContext, RegistrationDivisionsContext, OrganizationContext, I18nContext, IdentityRolesContext, RolesContext, I18nHelperContext)(SettingsRegistrationCategorySidepanel);