import React from "react";
import { Link } from "react-router-dom";
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import TeamSearchSidepanelInfo from "../../../../teams/search/sidepanel/TeamSearchSidepanelInfo";
import SidePanel from "../../../../../components/sidePanel/SidePanel";

// Contexts
import withContexts from "../../../../../helpers/withContexts";
import { TeamsContext } from "../../../../../contexts/TeamsContext";
import { DisplayCategory } from "../../../../teams/TeamHelpers";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { ReportsContext } from "../../../../../contexts/ReportsContext";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import MemberPrimaryTeamModal from "../components/MemberPrimaryTeamModal";
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy";

class MemberTeamsSidepanel extends React.Component{

    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
            isLoading: false,
            setPrimaryTeamModal: false,
        };
    }

    toggle(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    render(){
        const data = this.props.selectedRows[0]?.team;
        const hasAccess = this.props.selectedRows[0]?.hasAccess;
        const affiliated = this.props.selectedRows[0].affiliate == 1;
        const setPrimaryTeamDisabled = this.props.selectedRows[0].member_primary_teams?.find((primaryTeam) => primaryTeam.active == 1) || !hasAccess || affiliated

        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <>
                    <MemberPrimaryTeamModal
                        isOpen={this.state.setPrimaryTeamModal}
                        toggle={() => this.setState(() => ({ setPrimaryTeamModal: false }))}
                        onChangePrimaryTeam={() => {
                            this.props.tableRef.refreshTable(); // refresh table
                            this.props.toggle(); // close sidepanel
                        }}
                        team={data}
                        memberId={this.props.memberId}
                    />

                    <SidePanel.Header noBorder>
                        <div className='align-items-center d-flex mb-3'>
                            <SidePanel.ToggleButton />
                            {hasAccess &&
                                <Link className="mr-2" to={{ pathname: `/teams/profile/${data.team_id}`, data: data }}>
                                    <Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" />
                                </Link>
                            }

                            <SidePanel.ActionsMenu>
                                <SidePanel.MenuAction
                                    disabled={setPrimaryTeamDisabled}
                                    action="EDIT"
                                    componentCode="teams"
                                    componentPermissionCode="change_member_primary_team"
                                    onClick={() => this.setState(() => ({ setPrimaryTeamModal: true }))}
                                >
                                    <Translate id='members.profile.teams.actionsMenu.setPrimary' />
                                    {setPrimaryTeamDisabled &&
                                        <div className='small'>
                                            {!hasAccess ?
                                                // disabled because does not have access
                                                <Translate id='members.profile.teams.actionsMenu.setPrimary.noAccess' />
                                                :
                                                affiliated ?
                                                // disabled because it's an affiliated team
                                                    <Translate id='members.profile.teams.actionsMenu.setPrimary.affiliated' />
                                                    :
                                                // disabled because team is primary
                                                    <Translate id='members.profile.teams.actionsMenu.setPrimary.primary' />
                                            }
                                        </div>
                                    }
                                </SidePanel.MenuAction>
                            </SidePanel.ActionsMenu>
                        </div>
                        <div className='mb-3'>
                            <UserDisplay>
                                <UserDisplay.Container>
                                    <UserImg
                                        abbr={data.abbreviation || data.short_name || data.name}
                                        alt={data.name}
                                        filePos={data.logo?.file_position}
                                        src={data.logo?.full_path}
                                        width={60}
                                    />
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Title className="h4 mb-0">{data.name}</UserDisplay.Title>
                                    {(data.unique_identifier && hasAccess) ?
                                        <InlineCopy tag='div' toCopy={data.unique_identifier} className="font-medium text-nowrap">
                                            <Link className="text-nowrap" to={{ pathname: `/teams/profile/${data.team_id}`, data: data }}>#{data.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></Link>
                                        </InlineCopy>
                                        :
                                        <div className="d-inline-flex">-</div>
                                    }
                                    <div className="font-medium text-dark">
                                        <DisplayCategory short category={data.team_category} />
                                    </div>
                                </UserDisplay.Container>
                            </UserDisplay>
                        </div>
                    </SidePanel.Header>
                    <Nav tabs>
                        <NavItem className="text-center w-100">
                            <NavLink
                                className={stringBuilder({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                            >
                                <Translate id='form.fields.general' />
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1" className="p-3">
                            <TeamSearchSidepanelInfo team={data} memberPrimaryTeams={this.props.selectedRows[0].member_primary_teams} from='members' />
                        </TabPane>
                    </TabContent>
                </>
            </OverlayLoader>
        )
    }
}

export default withContexts(TeamsContext, ReportsContext, I18nContext, IdentityRolesContext, OrganizationContext)(MemberTeamsSidepanel);