export default{
    "organization.settings.registrationSettings.setting.title"       : "Paramètres d'inscription",
    "organization.settings.registrationSettings.setting.name"        : "Message de confirmation d'inscription",
    "organization.settings.registrationSettings.setting.desc"        : "Ce paramètre permet de modifier le message affiché aux parents à fin du processus d’achat.",
    "organization.settings.registrationSettings.setting.no.selected" : "Aucun message de confirmation d'inscription. ",

    "organization.settings.registrationSettings.confirmation.title" : "Message de confirmation",
    "organization.settings.registrationSettings.demographic.title"  : "Message des données collectées",
    "organization.settings.registrationSettings.memberFields.title" : "Champs de création de membre",
    "organization.settings.registrationSettings.memberFields.desc"  : "Ce paramètre vous permet d'ajouter des explications à chaque champ du formulaire de création de membre.",

    "organization.settings.registrationSettings.memberFields.empty"     : "Aucun champ.",
    "organization.settings.registrationSettings.memberFields.empty.txt" : "Il n'y a aucun champ disponible pour ajouter une description.",

    "organization.settings.registrationSettings.clinic.setting.name" : "Message de confirmation d'inscription à un stage",
    "organization.settings.registrationSettings.clinic.setting.desc" : "Ce paramètre permet de modifier le message affiché à la fin du processus d’inscription à un stage.",

    "organization.settings.registrationSettings.demographic.setting.delete" : "Réinitialiser le message des données collectées",
    "organization.settings.registrationSettings.demographic.setting.name"   : "Explication pour les données collectées",

    "organization.settings.registrationSettings.demographic.setting.desc" : "Ce paramètre vous permet de configurer le message concernant la politique de collecte de données dans le formulaire de création du participant.",

}