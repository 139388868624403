import { Tooltip } from '@mantine/core'
import { FormikDateTime } from '@spordle/formik-elements'
import Translate, { DateFormat } from '@spordle/intl-elements'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { mixed, object } from 'yup'
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI'
import FormikEditable from '../../../../../components/formik/FormikEditable'
import { TeamsContext } from '../../../../../contexts/TeamsContext'

const TeamRosterRegistrationDate = ({ teamMember, hasPermissionToEdit, updateRowAndSidepanel }) => {
    const teamsContext = useContext(TeamsContext);
    const teamId = useRouteMatch("/teams/profile/:teamId").params.teamId;
    const [ canEdit, setCanEdit ] = useState(false);
    const [ tip, setTip ] = useState("");

    useEffect(() => {
        teamsContext.getTeamSettings(teamId)
            .then((settings) => {
                const filteredSettings = {}
                for(const key in settings){
                    if(Object.hasOwnProperty.call(settings, key)){
                        filteredSettings[key] = settings[key];
                    }
                }
                return filteredSettings
            })
            .then((settings) => {
                if(hasPermissionToEdit){
                    if(Object.keys(settings || {}).length > 0 && settings.protect_registration_date?.active == "1" && settings.protect_registration_date?.value === 'yes'){
                        setTip('teams.profile.roster.sidePanel.registrationDate.disabled');
                    }else{
                        setCanEdit(true);
                    }
                }
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error);
                }
            });
    }, []);

    return (
        <div>
            <Tooltip disabled={canEdit} withArrow label={canEdit && tip && <Translate id={tip} />}>
                <FormikEditable
                    id='playerRegistrationDate'
                    disabled={!canEdit}
                    initialValues={{
                        registrationDate: teamMember.registration_date ? moment(teamMember.registration_date) : '',
                    }}
                    validationSchema={object().shape({
                        registrationDate: mixed().test({
                            name: 'formatTest',
                            message: <Translate id='form.validation.date.format' />,
                            test: moment.isMoment,
                        }),
                    })}
                    onSubmit={({ registrationDate }) => {
                        const newDate = registrationDate ? registrationDate.toISOString() : null;
                        if(newDate !== teamMember.registration_date){
                            updateRowAndSidepanel(teamMember, {
                                registration_date: newDate,
                            })
                        }
                    }}
                >
                    {(isEditing) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'>
                                    {teamMember.registration_date ?
                                        <DateFormat value={teamMember.registration_date} />
                                        : '-'}
                                </div>
                            )
                        }
                        return <FormikDateTime id='registrationDate' name='registrationDate' timeFormat={false} className='date-picker-left' />

                    }}
                </FormikEditable>
            </Tooltip>
        </div>
    )
}

export default TeamRosterRegistrationDate
