/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React, { createContext } from 'react';
import queryString from 'query-string';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';

/** @type {React.Context<Omit<NewsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const NewsContext = createContext();
NewsContext.displayName = 'NewsContext';

class NewsContextProvider extends React.Component{

    /**
     * [GET] Get all news list
     * @param {object} queryParams
     * @param {1|0} queryParams.system
     * @param {string} queryParams.organisation_id
     * @param {string} queryParams.identity_role_id
     * @param {INFORMATION|WARNING|CRITICAL} [queryParams.type]
     * @param {string} [queryParams.news_for] organisation_id that news are targeted at.
     * @param {string} [queryParams.published_at] Comma separated date range of news requested
     * @param {1|0} [queryParams.published_only] Get published news only
     * @param {1|0} [queryParams.active]
     * @param {string} [queryParams.created_at] Single date to get news that were created that day. Y-m-d format.
     * @return {Promise<Array<Object>>}
     */
    getNewsList = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/news`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {
                if(res.data.status){
                    const formattedNews = res.data.news.map((news) => {
                        return ({
                            ...news,
                            organisation: res.data.organisations[news.organisation_id],
                        })
                    });

                    return { ...res.data, news: formattedNews };
                }
                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [GET] Get Specific news
     * @param {object} queryParams
     * @param {string} queryParams.news_id
     * @param {string} queryParams.identity_role_id ID of the role requesting the news. Cannot be multiple. Must belong to connected user.
     */
    getSingleNews = (newsId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/news/${newsId}`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {
                if(res.data.status){
                    return res.data.news[0];
                }
                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [POST] Creates a news
     * @param {object} params
     * @refer https://api.id.dev.spordle.dev/documentations/index.php#/News/27a8ab8e743352d905b5ee9bf1a91f02|documentation
     * @returns {Promise.<Object>}
     */
    createNews = (values = {}) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/news` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PUT] Update a news
     * @param {string} newsId
     * @param {object} values
     * @refer https://api.id.dev.spordle.dev/documentations/index.php#/News/27a8ab8e743352d905b5ee9bf1a91f02|documentation
     * @returns {Promise.<Object>}
     */
    updateNews = (newsId, values = {}) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/news/${newsId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PUT] Marks a news as read
     * @param {string} newsId
     * @param {object} values
     * @param {0|1} values.read
     * @param {string} values.identity_role_id
     * @returns {Promise.<boolean>}
     */
    markNewsAsRead = (newsId, values = {}) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/news/${newsId}/mark_as_read` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)

    }

    /**
     * [PATCH] Creates a news
     * @param {string} newsId
     * @param {object} params
     * @refer https://api.id.dev.spordle.dev/documentations/index.php#/News/b9fa25cc6ab0944044a0a4591eef5aa5
     * @returns {Promise.<boolean>}
     */
    partiallyUpdateNews = (newsId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/news/${newsId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes a news
     * @param {string} newsId
     * @returns {Promise.<boolean>}
     */
    deleteNews = (newsId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/news/${newsId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)

    }

    /**
     * @typedef {object} DownloadData
     * @property {string} full_path
     * @property {string} original_file_mime_type
     * @property {string} preview_full_path
     * @property {array} warnings
     */

    /**
     * [GET] Downloads a news attachment
     * @param {string} newsId
     * @param {string} newsAttachmentId
     * @returns {Promise.<DownloadData>}
     */
    downloadNewsAttachment = (newsId, newsAttachmentId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/news/${newsId}/attachements/${newsAttachmentId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {
                if(res.data.status){
                    return res.data;
                }
                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [GET] Get available news banners
     * @param {object} [queryParams]
     * @param {string} [queryParams.code]
     * @param {string} [queryParams.path_url]
     * @param {string} [queryParams.name]
     * @param {1|0} [queryParams.active]
     * @returns {Promise.<Array<object>>}
     */
    getNewsBanners = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/banners`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {
                if(res.data.status){
                    return res.data.banners;
                }
                throw res.data.errors[0]
            }, serverError)
    }

    render(){
        return (
            <NewsContext.Provider value={{ ...this }}>
                {this.props.children}
            </NewsContext.Provider>
        )
    }
}

export default NewsContextProvider