import Translate, { DateFormat } from '@spordle/intl-elements';
import { Link } from 'react-router-dom';
import { Fade, ListGroup, ListGroupItem } from 'reactstrap';
import QuickViewButton from '../../../../components/quickView/QuickViewButton';
import SidePanel from '../../../../components/sidePanel/SidePanel';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import PotentialMemberDuplicatesModal from '../modal/PotentialMemberDuplicatesModal';
import SinglePotentialMemberDuplicatesSidePanel from './SinglePotentialMemberDuplicatesSidePanel';
import { useRef } from 'react';

const PotentialMemberDuplicatesSidePanel = (props) => {
    const potentialDuplicates = props.selectedRows;
    const hasBeenManaged = useRef(false);

    const handleOnSuccess = () => {
        // Used to close sidepanel if a merge has been done, because this potentialMemberDuplicate might not exist anymore in the data.
        hasBeenManaged.current = true;

        props.tableRef.refreshTable();
    }

    return (
        <Fade in>
            <PotentialMemberDuplicatesModal
                toggle={() => {
                    props.isModalOpenHandlers?.toggle();

                    // This potentialMemberDuplicate might not exist anymore
                    if(hasBeenManaged.current){
                        props.forceCloseSidePanel();
                    }
                }}
                isOpen={props.isModalOpen}
                potentialDuplicates={potentialDuplicates}
                spordleTable={props.tableRef}
                changeRequestStatuses={props.changeRequestStatuses}
                onSuccess={handleOnSuccess}
            />
            <SidePanel.Header
                noBorder
                className='sticky-top bg-white z-index-1'
            >
                <div className='d-flex mb-3 align-items-center'>
                    <SidePanel.ToggleButton />
                    {potentialDuplicates.length === 1 &&
                        <SidePanel.ActionsMenu
                            action="EDIT"
                            componentCode="members"
                            componentPermissionCode="manage_potential_duplicate_members"
                        >
                            <SidePanel.MenuAction onClick={props.isModalOpenHandlers?.toggle}>
                                <Translate id="tasks.memberDuplicates.manage" />
                            </SidePanel.MenuAction>
                        </SidePanel.ActionsMenu>
                    }
                </div>
            </SidePanel.Header>
            {(potentialDuplicates.length <= 1) ?
                <SinglePotentialMemberDuplicatesSidePanel potentialDuplicate={potentialDuplicates[0]} />
                :
                <>
                    <div className="d-flex align-items-center px-3 pb-3 w-100 h4 font-bold mb-0 text-break">
                        <QuickViewButton member={potentialDuplicates.map(({ member }) => member)}>
                            <Translate values={{ count: potentialDuplicates.length }} id="task.mandatoryChangesRequests.sidepanel.members.number" />
                        </QuickViewButton>
                    </div>
                    <ListGroup>
                        {potentialDuplicates.map((potentialDuplicate) => (
                            <ListGroupItem className='px-3' key={potentialDuplicate.member.member_id}>
                                <UserDisplay>
                                    <UserDisplay.Container>
                                        <UserImg
                                            alt={potentialDuplicate.member.first_name + ' ' + potentialDuplicate.member.last_name}
                                            abbr={potentialDuplicate.member.first_name.charAt(0) + potentialDuplicate.member.last_name.charAt(0)}
                                            src={potentialDuplicate.member.picture?.full_path}
                                            filePos={potentialDuplicate.member.picture?.file_position}
                                            width={45}
                                            className="mr-2"
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container className="w-100">
                                        <UserDisplay.Title className="d-flex justify-content-between">
                                            <QuickViewButton member={potentialDuplicate.member} className='d-flex justify-content-start'>
                                                <div>{potentialDuplicate.member.first_name} {potentialDuplicate.member.last_name}</div>
                                            </QuickViewButton>
                                        </UserDisplay.Title>
                                        <UserDisplay.Subtitle>
                                            <Link className="text-nowrap" to={`/members/profile/${potentialDuplicate.member.member_id}`}>
                                                {potentialDuplicate.member.unique_identifier ?
                                                    <>#{potentialDuplicate.member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                    :
                                                    '-'
                                                }
                                            </Link>
                                            <div className='text-muted'><Translate id='task.unconfirmedOrg.sidepanel.submission.date' /> : <span className='text-dark'><DateFormat value={potentialDuplicate.created_at} /></span></div>
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </>
            }

        </Fade>
    );
};

export default PotentialMemberDuplicatesSidePanel;
