import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import I18nHelperContextProvider from "../../../../../helpers/i18nHelper";
import FilterForm from "./FilterForm";

const EditFilterModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal size="lg" isOpen={isOpen} analyticsName="editReportsFilters">
            <I18nHelperContextProvider languages={[ "en", "fr" ]} fields={[ 'name' ]}>
                <FilterForm {...props} />
            </I18nHelperContextProvider>
        </AnalyticsModal>
    );
}

export default EditFilterModal;