import Translate from "@spordle/intl-elements";
import Skeleton from "react-loading-skeleton";
import { Button } from "reactstrap";
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy";
import QuickViewButton from "../../../../../components/quickView/QuickViewButton";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import { ClaimStatus } from "../DiscriminationHelper";
import { useContext } from 'react';
import { DiscriminationContext } from "../DiscriminationContext";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { stringBuilder } from "@spordle/helpers";

const MaltreatmentMemberCard = ({ discrimination, toggle }) => {
    const discriminationContext = useContext(DiscriminationContext)
    const { isGod } = useContext(IdentityRolesContext);

    return (
        <UserDisplay card block>
            <UserDisplay.Container>
                <UserImg
                    alt={discrimination?.discrimination_member?.[0]?.first_name + ' ' + discrimination?.discrimination_member?.[0]?.last_name}
                    abbr={discrimination?.discrimination_member?.[0]?.first_name || discrimination?.discrimination_member?.[0]?.team?.name || 'A'}
                    className="text-uppercase"
                    width={50}
                />
            </UserDisplay.Container>
            <UserDisplay.Container className="mr-auto">
                <UserDisplay.Title>
                    {discrimination?.discrimination_member?.[0]?.member_id ?
                        <QuickViewButton member={discrimination.discrimination_member[0]}>
                            {discrimination.discrimination_member[0].first_name + ' ' + discrimination.discrimination_member[0].last_name}
                        </QuickViewButton>
                        :
                        discrimination?.discrimination_member?.[0]?.team?.name
                    }
                </UserDisplay.Title>
                <UserDisplay.Subtitle>
                    {discrimination?.discrimination_member?.[0]?.member?.unique_identifier &&
                        <>
                            <InlineCopy toCopy={discrimination.discrimination_member[0].member.unique_identifier}>
                                #{discrimination.discrimination_member[0].member.unique_identifier}
                            </InlineCopy>
                        </>
                    }
                </UserDisplay.Subtitle>
                <UserDisplay.Subtitle>
                    {discrimination?.discrimination_status &&
                        <ClaimStatus discriminationStatus={discrimination.discrimination_status} />
                    }
                </UserDisplay.Subtitle>
            </UserDisplay.Container>
            <UserDisplay.Container className="ml-auto text-muted">
                <div className="d-flex justify-content-end align-items-center">
                    <Translate id='discrimination.overview.outcomes' values={{ number: discrimination?.maltreatment_outcomes?.length || 0 }} />
                    {(discrimination.discrimination_status.system !== 'OUTCOMES_COMPLETED') &&
                        <CanDoAction action="ADD" componentCode="organization" componentPermissionCode="discrimination_claims">
                            <Button
                                size='sm' type="button"
                                color='link'
                                className="text-nowrap ml-1"
                                onClick={() => {
                                    discriminationContext.setCurrentAPIComplaint(discrimination)
                                    toggle();
                                    discriminationContext.toggleAddOutcomeModal(true);
                                }}
                            >
                                <i className='mdi mdi-plus' /><Translate id='misc.add' />
                            </Button>
                        </CanDoAction>
                    }
                </div>
                <div className="d-flex justify-content-end align-items-center">
                    <Translate id='discrimination.overview.suspensions' values={{ number: discrimination?.maltreatment_member_suspensions?.length || 0 }} />
                    {(((discriminationContext.state.canCreateSuspension || isGod()) && discrimination.discrimination_status.system !== 'OUTCOMES_COMPLETED')) &&
                        <CanDoAction action="ADD" componentCode="organization" componentPermissionCode="link_suspension_maltreatment_claim" skipAccess>
                            <Button
                                size='sm' type="button"
                                color='link'
                                className={stringBuilder("text-nowrap ml-1", { 'text-purple': !discriminationContext.state.canCreateSuspension && isGod() })}
                                onClick={() => {
                                    discriminationContext.setCurrentAPIComplaint(discrimination)
                                    toggle();
                                    discriminationContext.toggleAddSuspensionModal(true);
                                }}
                            >
                                <i className='mdi mdi-plus' /><Translate id='misc.add' />
                            </Button>
                        </CanDoAction>
                    }
                </div>
            </UserDisplay.Container>
        </UserDisplay>
    )
}

const MaltreatmentMemberCardLoading = () => {
    return (
        <UserDisplay card block>
            <UserDisplay.Container>
                <Skeleton circle width={50} height={50} style={{ lineHeight: 'unset' }} />
            </UserDisplay.Container>
            <UserDisplay.Container className="mr-auto">
                <UserDisplay.Title>
                    <Skeleton width={150} />
                </UserDisplay.Title>
                <UserDisplay.Subtitle>
                    <Skeleton width={115} />
                </UserDisplay.Subtitle>
                <UserDisplay.Subtitle>
                    <Skeleton width={40} />
                </UserDisplay.Subtitle>
            </UserDisplay.Container>
            <UserDisplay.Container className="pl-3">
                <div className="py-1"><Skeleton width={150} /></div>
                <div className="py-1"><Skeleton width={150} /></div>
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default MaltreatmentMemberCard
export{ MaltreatmentMemberCardLoading }