import PropTypes from 'prop-types';

/**
* You can manage the dot's size with the font-size
* Make sure it is vertical aligned with the text associated with it (align-self-center or align-middle)
* @param {string} [className]
* @param {string} [color='primary'] This generates bg-{color}
* @example <Dot color="clinic-1"/>
* @example <Dot color="primary"/>
* @example <Dot color="orange"/> // not all "raw" colors are available. yes it is easely fixable
*/
const Dot = ({
    color = "primary",
    className,
}) => (
    <span className={"dot bg-" + color + " " + className} />
);

export default Dot;

Dot.propTypes = {
    className: PropTypes.string,
};