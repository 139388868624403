// Components
import MemberProfile from '../../views/members/profile/MemberProfile';
import InvalidMemberProfileId from '../../views/members/profile/InvalidMemberProfileId';
import memberProfileTabsRoutes from '../tabRoutes/memberProfileTabsRoutes';
import MemberSearch from '../../views/members/search/MemberSearch';

// Header

const membersRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        exact: true,
        name: 'sidebar.members',
        path: '/members',
        permissions: { code: 'members' },
        component: MemberSearch,
    },
    {
        path: '/members/profile/:memberId',
        permissions: { code: 'members', permissionCode: 'members_profile' },
        subPermissionsRoutes: memberProfileTabsRoutes,
        skipRecents: true,
        component: MemberProfile,
    },
    {
        exact: true,
        skipRecents: true,
        path: '/members/404',
        permissions: { code: 'members' },
        component: InvalidMemberProfileId,
    },
];
export default membersRoutes;
