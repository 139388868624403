import { useContext, useState } from "react";
import {
    Button,
    Card,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledButtonDropdown
} from "reactstrap";

import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate from "@spordle/intl-elements";

import AddAccountModal from "./AddAccountModal";
import SidePanelOrganizationAccountingLedger from "./SidePanelOrganizationAccountingLedger";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import EmptyLayout from "../../../../../components/table/EmptyLayout";
import CardSectionTitle from "../../../../../components/CardSectionTitle";
import CanDoAction from "../../../../../components/permissions/CanDoAction";

const OrganizationAccountingLedger = () => {
    const [ addAccountModalOpen, setAddAccountModalOpen ] = useState(false);
    const { getOrganisationLedgers } = useContext(OrganizationContext);
    const toggleAddAccountModal = () => setAddAccountModalOpen(!addAccountModalOpen);

    return (
        <Card body className="card-shadow">
            <CardSectionTitle title='organization.profile.accounting.ledger.organizationAccountingLedger.title' />
            <SpordlePanelTable
                allowOutsideClick
                sidePanel={(props) => <SidePanelOrganizationAccountingLedger {...props} />}
                dataIndex='organisation_ledger_id'
                table={(panelProps) => (
                    <SpordleTableProvider
                        id='org-ledger'
                        tableHover bordered striped
                        clickable
                        ref={panelProps.spordleTableRef}
                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                        dataIndex='organisation_ledger_id'
                        defaultSorting='+name'
                        emptyLayout={<EmptyLayout />}
                        searchKeys={[
                            "number",
                            "name",
                        ]}
                        columns={[
                            {
                                label: <Translate id='organization.profile.accounting.ledger.organizationAccountingLedger.accountName' />,
                                key: "name",
                                dataClassName: "w-50",
                                mobile: true,
                                sortable: true,
                            },
                            {
                                label: <Translate id='organization.profile.accounting.ledger.organizationAccountingLedger.accountNumber' />,
                                key: "number",
                                dataClassName: "w-50",
                                mobile: true,
                                sortable: true,
                            },
                            {
                                label: <Translate id="misc.status" />,
                                key: "active",
                                dataClassName: 'text-center',
                                mobile: true,
                                sortable: true,
                            },
                        ]}
                        renderRow={(columnKey, data) => {
                            switch (columnKey){
                                case 'active':
                                    return <i className={`mdi mdi-${data.active == "1" ? "check text-primary" : "close text-danger"}`} />
                                default:
                                    break;
                            }
                        }}
                        desktopWhen
                        pagination={10}
                        onColumnClick={(e, location) => {
                            switch (e.button){
                                case 0: // Left mouse button
                                    panelProps.onSingleSelectChange(location);
                                    break;
                            }
                        }}
                        rowIsHighlighted={(location) => !!location.checked}
                        loadData={(from, _, spordleTable) => {
                            switch (from){
                                case 'REFRESH':
                                    spordleTable.setLoading();
                                case 'CDM':
                                    return getOrganisationLedgers();
                                default:
                                    break;
                            }
                        }}
                    >
                        <Row className='mb-2'>
                            <Col md='6'>
                                <SpordleTableProvider.SearchInput />
                            </Col>
                            <Col md='6' className="d-flex justify-content-end align-items-center">
                                <SpordleTableProvider.Refresh />
                                <UncontrolledButtonDropdown className='ml-2'>
                                    <DropdownToggle color='primary' outline caret disabled>
                                        <i className='mdi mdi-download mr-1' /><Translate id='misc.export' />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem><i className='fas fa-file-excel mr-1' /><Translate id='misc.excel' /></DropdownItem>
                                        <DropdownItem><i className='fas fa-file-pdf mr-1' /><Translate id='misc.pdf' /></DropdownItem>
                                        <DropdownItem><i className='fas fa-print mr-1' /><Translate id='misc.print' /></DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                                <CanDoAction className='ml-2' action='ADD' componentCode='organization' componentPermissionCode='organization_profile'>
                                    <Button color='primary' onClick={toggleAddAccountModal}>
                                        <i className='mdi mdi-plus mr-1' /><Translate id='misc.add' />
                                    </Button>
                                    <AddAccountModal isOpen={addAccountModalOpen} toggle={toggleAddAccountModal} />
                                </CanDoAction>
                            </Col>
                        </Row>
                        <SpordleTableView />
                    </SpordleTableProvider>
                )}
            />
        </Card>
    )
}

export default OrganizationAccountingLedger;