export default{
    "catalog.forms.components.customFormEditor.formName.required"    : "Le nom est requis",
    "catalog.forms.components.customFormEditor.formName.description" : "Le nom du questionnaire ne sera pas visible pour le public",

    "catalog.forms.components.customFormEditor.form.validation.section.name"                 : "Vous devez entrer un titre de la section",
    "catalog.forms.components.customFormEditor.form.validation.question.minLength"           : "Une section doit avoir au moins une question",
    "catalog.forms.components.customFormEditor.form.validation.question.max.moreThan"        : "Doit être plus que le minimum",
    "catalog.forms.components.customFormEditor.form.validation.question.max.dateFormat"      : "Format de date non valide",
    "catalog.forms.components.customFormEditor.form.validation.question.max.dateFormat.more" : "Doit être après la date minimale",
    "catalog.forms.components.customFormEditor.form.validation.question.min.lessThan"        : "Doit être moins que le maximum",
    "catalog.forms.components.customFormEditor.form.validation.question.min.dateFormat"      : "Format de date non valide",
    "catalog.forms.components.customFormEditor.form.validation.question.min.dateFormat.less" : "Doit être avant la date maximal",
    "catalog.forms.components.customFormEditor.form.validation.question.title.required"      : "Veuillez saisir une question",
    "catalog.forms.components.customFormEditor.form.validation.option.fieldOption.required"  : "Vous devez saisir une option",

    "catalog.forms.components.customFormEditor.create.fail"    : "Erreur lors de la création du questionnaire",
    "catalog.forms.components.customFormEditor.create.success" : "Questionnaire créé avec succès",
    "catalog.forms.components.customFormEditor.update.fail"    : "Erreur lors de la mise à jour du questionnaire",
    "catalog.forms.components.customFormEditor.update.success" : "Questionnaire mis à jour avec succès",
}