import { useContext, useState } from 'react';
import {
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Collapse,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    NavLink,
    Button
} from "reactstrap";
import { NotificationsContext } from '../../../../contexts/NotificationsContext';
import { NotificationsDataManager } from './components/NotificationsDataManager';
import Translate from '@spordle/intl-elements';
import OrganizationNotificationList from './components/OrganizationNotificationList';
import { stringBuilder } from '@spordle/helpers';
import JiraNotificationList from './components/JiraNotificationList';
import NotificationsSettingsDataManagerProvider, { NotificationsSettingsDataManager } from './components/NotificationsSettingsDataManager';
import { Form, Formik } from 'formik';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { FormikSwitch } from '@spordle/formik-elements';
import BtnState from '../../../../components/input/BtnState';
import NotifBadge from '../notifBadge/NotifBadge';
import { useDisclosure } from '@mantine/hooks';
import { Tooltip } from '@mantine/core';

const Notifications = () => {
    const notificationsContext = useContext(NotificationsContext);

    const [ isOpen, isOpenHandlers ] = useDisclosure(false);
    const [ settingsIsOpen, settingsIsOpenHandlers ] = useDisclosure(false);
    const [ tab, setTab ] = useState('1');

    // -- Functions
    const toggleTab = (tab) => setTab(tab);

    return (
        <>
            <Dropdown isOpen={isOpen} toggle={isOpenHandlers.toggle} nav inNavbar>
                <DropdownToggle className="position-relative" nav caret>
                    <div>
                        <Tooltip
                            withArrow
                            className='w-100'
                            label={<Translate id='header.notifications.title' />}
                            zIndex={3000}
                        >
                            <i className={`mdi mdi-bell-outline font-18`} />
                        </Tooltip>
                        { (notificationsContext.state.notificationsCount > 0) &&
                            <NotifBadge>
                                {notificationsContext.state.notificationsCount > 9 ? '9+' : notificationsContext.state.notificationsCount}
                            </NotifBadge>
                        }
                    </div>
                </DropdownToggle>
                <DropdownMenu right className="mailbox overflow-hidden pb-0">
                    <div className="d-flex align-items-center px-3 pt-3 pb-1">
                        {/* <div className="h4 font-medium mb-0">Notifications{(jiraNotifs?.length + notifsCount) > 0 && ` (${(jiraNotifs?.length + notifsCount)})`}</div> */}
                        <div className="h4 font-medium mb-0"><Translate id="header.notifications.title" /></div>
                        <div className='ml-auto'>
                            <Tooltip zIndex={2000} withArrow label={<Translate id='header.notifications.settings.title' />}>
                                <button
                                    type='button'
                                    onClick={settingsIsOpenHandlers.toggle}
                                    className={stringBuilder("notification-settings-btn", { 'is-open': settingsIsOpen })}
                                >
                                    <i className="notification-settings-btn-inner" />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    <Collapse className={stringBuilder(`notification-settings-collapse`)} isOpen={settingsIsOpen}>
                        <NotificationsSettingsDataManagerProvider>
                            <NotificationsSettingsDataManager.Consumer>
                                {(helper) => (
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            subscriptionsGroups: helper.initFormik,
                                        }}
                                        onSubmit={({ subscriptionsGroups }, { setSubmitting, setStatus }) => {
                                            helper.onSubmit(subscriptionsGroups, setSubmitting, setStatus)
                                        }}
                                    >
                                        {(formik) => (
                                            <Form>
                                                <div className="notification-settings-container">
                                                    <div className="mb-3">
                                                        <div className="h5 mb-1 font-medium">
                                                            <Translate id='header.notifications.settings.title' />
                                                        </div>
                                                        <small>
                                                            <Translate id='header.notifications.settings.subtitle' />
                                                        </small>
                                                    </div>
                                                    <ul style={{ height: 300, overflowY: "scroll" }} className="list-unstyled mb-3 p-2">
                                                        {formik.values.subscriptionsGroups?.map((group, groupI) => (
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            <li key={group.component.component_id + groupI} className="pr-2">
                                                                <div className="py-2 font-bold">
                                                                    <DisplayI18n
                                                                        field="title"
                                                                        defaultValue={group.component.title}
                                                                        i18n={group.component.i18n}
                                                                    />
                                                                </div>
                                                                <ul className="list-unstyled px-0">
                                                                    {group.subscriptions.map((sub, subI) => (
                                                                        // eslint-disable-next-line react/no-array-index-key
                                                                        <li key={sub.notification_type_id + groupI + subI} className="mb-2 d-flex align-items-center justify-content-end">
                                                                            <span>
                                                                                <DisplayI18n
                                                                                    field="name"
                                                                                    defaultValue={sub.name}
                                                                                    i18n={sub.i18n}
                                                                                />
                                                                            </span>
                                                                            <div className="small ml-auto">
                                                                                <FormikSwitch
                                                                                    disabled={formik.isSubmitting}
                                                                                    id={`subscriptionsGroups.${groupI}.subscriptions.${subI}.subscribed`}
                                                                                    name={`subscriptionsGroups.${groupI}.subscriptions.${subI}.subscribed`}
                                                                                />
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <Collapse isOpen={!!formik.status}>
                                                        <small className="text-danger">
                                                            <Translate id='header.notifications.settings.error' values={{ b: (chunks) => <b>{chunks}</b> }} />
                                                        </small>
                                                    </Collapse>
                                                    <div className="d-flex align-items-center justify-content-end mt-3">
                                                        <BtnState
                                                            disabled={formik.isSubmitting || helper.isValidating}
                                                            loadState={helper.updateState}
                                                            className="mr-2"
                                                            size="sm"
                                                            color="primary"
                                                            type="submit"
                                                        >
                                                            <Translate id='header.notifications.settings.btn.save' />
                                                        </BtnState>
                                                        <Button
                                                            disabled={formik.isSubmitting}
                                                            size="sm"
                                                            color="primary"
                                                            outline
                                                            type="button"
                                                            onClick={() => {
                                                                settingsIsOpenHandlers.close();
                                                                formik.setStatus(null);
                                                                formik.resetForm();
                                                            }}
                                                        >
                                                            <Translate id="misc.close" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            </NotificationsSettingsDataManager.Consumer>
                        </NotificationsSettingsDataManagerProvider>
                        {/* <NotificationsSettings toggle={() => setSettingsIsOpen(false)} /> */}
                    </Collapse>
                    <Collapse isOpen={!settingsIsOpen}>
                        <NotificationsDataManager.Consumer>
                            {(helper) => (
                                <>
                                    <Nav tabs className="notification-tabs">
                                        <NavItem>
                                            <NavLink tag="button" className={stringBuilder('px-3 bg-white', { 'active': tab === '1' })} onClick={() => toggleTab('1')}>
                                                <Translate id='header.notifications.tabs.organization' />{helper.notifsCount > 0 && ` (${helper.notifsCount})`}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag="button" className={stringBuilder('px-3 bg-white', { 'active': tab === '2' })} onClick={() => toggleTab('2')}>
                                                <Translate id='header.notifications.tabs.supportRequest' />{helper.jiraNotifs?.length > 0 && ` (${helper.jiraNotifs?.length})`}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={tab} className="bg-transparent">
                                        {/* Organization Notifs */}
                                        <TabPane tabId="1">
                                            <OrganizationNotificationList {...helper} toggle={isOpenHandlers.close} />
                                        </TabPane>

                                        {/* Jira Notifs */}
                                        <TabPane tabId="2">
                                            <JiraNotificationList {...helper} toggle={isOpenHandlers.close} isOpen={isOpen} />
                                        </TabPane>
                                    </TabContent>
                                </>
                            )}
                        </NotificationsDataManager.Consumer>
                    </Collapse>
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

export default Notifications;