export default{
    "members.profile.overview.memberPrimaryInformation.title"                 : "Informations générales",
    "members.profile.overview.memberPrimaryInformation.edit.title"            : "Modifier les informations générales",
    "members.profile.overview.memberPrimaryInformation.edit.disabledFields"   : "Les champs désactivés peuvent être modifiés une {request}. La requête devra être approuvée pour que les changements soient acceptés.",
    "members.profile.overview.memberPrimaryInformation.edit.enableFields"     : "Activer les champs obligatoires",
    "members.profile.overview.memberPrimaryInformation.edit.disableFields"    : "désactiver les champs obligatoires",
    "members.profile.overview.memberPrimaryInformation.edit.saveConfirmTitle" : "Êtes vous certain de sauvegarder?",
    "members.profile.overview.memberPrimaryInformation.edit.saveConfirmDesc"  : "Des changements ont été apportés au type de membre.",
    "members.profile.overview.memberPrimaryInformation.edit.save"             : "Sauvegarder les changements",
    "members.profile.overview.memberPrimaryInformation.edit.cancel"           : "Annuler les changements",

    "members.profile.overview.memberPrimaryInformation.memberTypes"               : "Types de membre",
    "members.profile.overview.memberPrimaryInformation.memberTypes.group.special" : "Spécial",
    "members.profile.overview.memberPrimaryInformation.memberTypes.group.normal"  : "Normal",


    "members.profile.overview.memberPrimaryInformation.dateOfBirth.range" : "La date doit représenter un âge entre 4 et 100 ans",

    "members.profile.overview.demographicInfo.title"      : "Informations démographiques",
    "members.profile.overview.demographicInfo.edit.title" : "Modifier les informations démographiques",
}