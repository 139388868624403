import PropTypes from 'prop-types';

/**
* @param {string} name // name of the step
* @param {number} number // number of the step
* @param {func} onClick // function used when we click on the name / number to navigate
* @param {bool} [isActive=false] // used to style the number/name when it is active
* @param {bool} [isValid=false] // used to enable the click/styling on number/name to navigate back/foward. Once this is true, it should never go false
* @example
<Steps activeStepName={'Session Information'} className="mb-4">
    <Steps.Item name={'Clinic Information'} number={1} setActiveStep={setActiveStep} isValid />
    <Steps.Item name={'Session Information'} number={2} setActiveStep={setActiveStep} isActive />
    <Steps.Item name={'Communication'} number={3} setActiveStep={setActiveStep} />
</Steps>
*/
const StepsItem = ({
    name,
    number,
    isActive,
    onClick,
    isAccessible,
}) => {

    const getStatus = () => {
        if(isActive){
            return ' current '

        }else if(isAccessible){
            return ' done ' // will eventually change the className because it is not representative enough

        }
        return ' disabled '

    }

    return (
        <div className={"step-item " + getStatus()}>
            <button type='button' className="reset-btn step-link font-medium" onClick={onClick}>
                <span className="step-number">{number}</span>
                <span className="step-text d-none d-sm-block">{name}</span>
            </button>
        </div>
    );
};

export default StepsItem;

StepsItem.propTypes = {
    name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.node,
    ]).isRequired,
    number: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    isValid: PropTypes.bool,

};
StepsItem.defaultProps = {
    isActive: false,
    isValid: false,
};