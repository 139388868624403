import MerchantAccontProviderSettings from '../../../views/settings/organization/components/merchantAccountProviders/settings/MerchantAccontProviderSettings';
/**
 * @typedef {object} TabRoutes
 * @property {React.ReactNode} [name]
 * @property {boolean} [redirect]
 */

/** @type {Array.<import('react-router-dom').RouteProps & TabRoutes>} */
const settingsMerchantAccountRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        path: '/accounts',
        name: 'settings.tabs.organization.merchantAccounts.settings',
        component: MerchantAccontProviderSettings,
        permissions: { code: 'settings', permissionCode: 'settings_merchant_account' },
    },
    {
        redirect: true,
        from: '/merchant-accounts',
        to: '/accounts',
    },
];
export default settingsMerchantAccountRoutes;