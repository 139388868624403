import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import { DropdownItem } from 'reactstrap';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { I18nContext } from '../../../../contexts/I18nContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { RolesContext } from '../../../../contexts/RolesContext';
import { DisplayI18n, displayI18n } from '../../../../helpers/i18nHelper';
import ConfirmModal from '../../../confirmModal/ConfirmModal';
import { fail, fire, success } from '@spordle/toasts';
import SidePanel from '../../SidePanel';

const RemoveShared = ({ transfer, onTransfer }) => {
    const roleContext = useContext(RolesContext);
    const i18nContext = useContext(I18nContext);
    const membersContext = useContext(MembersContext);
    const orgContext = useContext(OrganizationContext);

    const orgName = displayI18n('name', transfer.target_organisation.i18n, transfer.target_organisation.organisation_name, i18nContext.getGenericLocale());
    return (
        <>
            {roleContext.canDoAction('EDIT', 'members', 'members_request_transfer') &&
                <DropdownItem divider />
            }
            <ConfirmModal
                toggler={(toggle) => (
                    <SidePanel.MenuAction onClick={toggle} action='DELETE' componentCode='members' componentPermissionCode='members_request_transfer'>
                        <Translate id='transfer.sidepanel.actions.removeShare' />
                    </SidePanel.MenuAction>
                )}
                modalTitle={orgName}
                actionButtonText='misc.remove'
                translateActionButtonText
                modalMsg="transfer.sidepanel.actions.removeShare.msg"
                translateModalMsg
                color='warning'
                onConfirm={() => {
                    return membersContext.deleteMemberTransferShare(transfer.member.member_id, transfer.member_transfer_id)
                        .then(async() => {
                            await membersContext.getAllMemberInfos(transfer.member.member_id, {
                                medicalInformation: true,
                                memos: roleContext.hasAccessTo('members', 'members_memo'),
                                invoices: roleContext.hasAccessTo('members', 'members_registrations') || roleContext.hasAccessTo('members', 'member_transactions'),
                                credits: roleContext.hasAccessTo('members', 'member_credit'),
                            }, orgContext.organisation_id)
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        if(error.i18n){
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }else{
                                            fire({ theme: 'warning', msg: 'transfer.sidepanel.actions.removeShare.refresh.fail.title', info: 'transfer.sidepanel.actions.removeShare.refresh.fail.msg', timeout: 10 });
                                        }
                                    }
                                })
                        })
                        .then(() => {
                            success({ msg: 'transfer.sidepanel.actions.removeShare.success' });
                        })
                        .then(onTransfer)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message);
                                switch (error.message){
                                    case '3404':
                                    case '3405':
                                        fail({ msg: `transfer.sidepanel.actions.removeShare.error.${error.message}` });
                                        break;
                                    default:
                                        fail();
                                        break;
                                }
                            }
                        })
                }}
            />
        </>
    )
}

export default RemoveShared
