import React from "react";
import {
    Button, Col, Collapse, FormGroup, Label, ModalBody, ModalFooter, Row
} from 'reactstrap';
import { Form, Formik } from 'formik';
import { FormikInputText, FormikDateTime, FormikSelect } from '@spordle/formik-elements';
import { object, string, mixed } from 'yup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';

// Contexts
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { IdentityRolesContext } from '../../../../../../contexts/IdentityRolesContext';
import withContexts from '../../../../../../helpers/withContexts';

// Language
import Translate from '@spordle/intl-elements';

// image
import UserImg from "../../../../../../components/UserImg";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import GenderLabel from "../../../../../../components/GenderLabel";
import MemberFieldLabel from "../../../../../../components/memberRegistration/MemberFieldLabel";

class AddFamilyRelationSearch extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            members: null,
        };
    }

    render(){
        return (
            <Formik
                initialValues={this.props.values || {
                    firstName: '',
                    lastName: '',
                    dateOfBirth: '',
                    gender: '',
                    memberNumber: '',
                }}
                validationSchema={object().shape({
                    firstName: string().when('memberNumber', {
                        is: undefined,
                        then: string().required(<Translate id='form.validation.firstName.required' />),
                    }),
                    lastName: string().when('memberNumber', {
                        is: undefined,
                        then: string().required(<Translate id='form.validation.lastName.required' />),
                    }),
                    dateOfBirth: mixed().when('memberNumber', {
                        is: undefined,
                        then: mixed().required(<Translate id='form.validation.dateOfBirth.required' />).test({
                            name: 'dateOfBirthFormat',
                            message: <Translate id='form.validation.dateOfBirth.valid' />,
                            test: (dateOfBirth) => {
                                return moment.isMoment(dateOfBirth)
                            },
                        }),
                    }),
                    gender: string().when('memberNumber', {
                        is: undefined,
                        then: string().required(<Translate id='form.validation.gender.required' />),
                    }),
                    memberNumber: string(),
                })}
                onSubmit={(values) => {
                    this.props.setLoading(true)

                    const searchParams = {
                        organisation_id: this.props.IdentityRolesContext.federation.organisation_id,
                        all: '1',
                        first_name: values.firstName,
                        last_name: values.lastName,
                        birthdate: moment.isMoment(values.dateOfBirth) ? values.dateOfBirth.format('YYYY-MM-DD') : moment(values.dateOfBirth).format('YYYY-MM-DD'),
                        gender: values.gender,
                    }

                    if(values.memberNumber){
                        searchParams.unique_identifier = values.memberNumber
                    }else{
                        searchParams.first_name = values.firstName;
                        searchParams.last_name = values.lastName;
                        searchParams.birthdate = moment.isMoment(values.dateOfBirth) ? values.dateOfBirth.format('YYYY-MM-DD') : moment(values.dateOfBirth).format('YYYY-MM-DD');
                        searchParams.gender = values.gender;
                    }

                    // Search through system members
                    this.props.MembersContext.getMembers(searchParams)
                        .then((members) => {
                            if(Array.isArray(members)){
                                this.setState(() => ({ members: members }))
                                this.props.setLoading(false)
                                this.props.setSearchValues(values)
                            }
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                            this.props.setLoading(false)
                        })
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <ModalBody>
                                <Row form>
                                    <Col md='6'>
                                        <FormGroup>
                                            <MemberFieldLabel
                                                className="text-muted"
                                                hideDescription
                                                field="first_name"
                                                fallbackLabel={<Translate id='form.fields.firstName' />}
                                                isRequired={!formik.values.memberNumber}
                                                for='firstName'
                                            />
                                            <FormikInputText disabled={!!formik.values.memberNumber} id='firstName' name='firstName' trim />
                                        </FormGroup>
                                    </Col>
                                    <Col md='6'>
                                        <FormGroup>
                                            <MemberFieldLabel
                                                className="text-muted"
                                                hideDescription
                                                field="last_name"
                                                fallbackLabel={<Translate id='form.fields.lastName' />}
                                                isRequired={!formik.values.memberNumber}
                                                for='lastName'
                                            />
                                            <FormikInputText disabled={!!formik.values.memberNumber} id='lastName' name='lastName' trim />
                                        </FormGroup>
                                    </Col>
                                    <Col md='6'>
                                        <FormGroup>
                                            <MemberFieldLabel
                                                className="text-muted"
                                                hideDescription
                                                field="birthdate"
                                                fallbackLabel={<Translate id='form.fields.dateOfBirth' />}
                                                isRequired={!formik.values.memberNumber}
                                                for='dateOfBirth'
                                            />
                                            <FormikDateTime
                                                inputProps={{ disabled: !!formik.values.memberNumber }}
                                                id='dateOfBirth'
                                                name='dateOfBirth'
                                                timeFormat={false}
                                                dateFormat='YYYY-MM-DD'
                                                isValidDate={(current) => moment(current).isBefore(moment())}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md='6'>
                                        <MemberFieldLabel
                                            className="text-muted"
                                            hideDescription
                                            field="gender"
                                            fallbackLabel={<Translate id='form.fields.gender' />}
                                            isRequired={!formik.values.memberNumber}
                                            for='gender'
                                        />
                                        <FormikSelect
                                            disabled={!!formik.values.memberNumber}
                                            name="gender"
                                            id="gender"
                                            placeholder='form.fields.gender.placeholder'
                                            loadingStatus='success'
                                            defaultData={this.props.OrganizationContext.settings.gender_selection?.value?.map((gender) => ({
                                                value: gender,
                                                label: <GenderLabel gender={gender} />,
                                                translateLabel: false,
                                            })) || []}
                                        />
                                    </Col>
                                </Row>
                                <div className='d-flex align-items-center mb-3'>
                                    <div className='border-top flex-grow-1' />
                                    <div className='px-3 font-medium small'><Translate id='members.profile.overview.memberFamilyRelations.add.or' /></div>
                                    <div className='border-top flex-grow-1' />
                                </div>
                                <FormGroup>
                                    <Label for='memberNumber' className='text-muted'><Translate id='members.profile.overview.memberFamilyRelations.add.memberNumber' /></Label>
                                    <FormikInputText id='memberNumber' name='memberNumber' trim />
                                    <div className='small'><i className='text-primary mdi mdi-information-outline' /> <Translate id='members.profile.overview.memberFamilyRelations.add.memberNumber.hint' /></div>
                                </FormGroup>

                                <Collapse className="mt-3" isOpen={this.state.members !== null}>
                                    {(this.state.members && this.state.members?.length > 0) ?
                                        <div className="border-top">
                                            <div className="text-muted my-2"><Translate id='misc.results' /></div>
                                            <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }}>
                                                {this.state.members.map((member) => (
                                                    <div key={member.member_id}>
                                                        <UserDisplay className="mb-2 w-100" card>
                                                            <UserDisplay.Container>
                                                                <UserImg
                                                                    width={40}
                                                                    abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                                                    alt={member.first_name + ' ' + member.last_name}
                                                                    src={member.picture?.full_path}
                                                                    filePos={member.picture?.file_position}
                                                                />
                                                            </UserDisplay.Container>
                                                            <UserDisplay.Container>
                                                                <UserDisplay.Title>{member.first_name + ' ' + member.last_name}</UserDisplay.Title>
                                                                <UserDisplay.Subtitle>
                                                                    <DisplayI18n
                                                                        field='name'
                                                                        defaultValue={member.organisation.organisation_name}
                                                                        i18n={member.organisation.i18n}
                                                                    />
                                                                </UserDisplay.Subtitle>
                                                                <UserDisplay.Subtitle>#{member.unique_identifier}</UserDisplay.Subtitle>
                                                                <UserDisplay.Subtitle>{member.birthdate} (<Translate id='misc.yearsOld' values={{ age: member.age }} />)</UserDisplay.Subtitle>
                                                            </UserDisplay.Container>
                                                            <UserDisplay.Container className="ml-auto">
                                                                <Button onClick={() => this.props.selectMember(member)} disabled={member.member_id === this.props.MembersContext.currentMember.member_id} color="primary" type="button">
                                                                    <Translate id='misc.select' />
                                                                </Button>
                                                            </UserDisplay.Container>
                                                        </UserDisplay>
                                                    </div>
                                                ))}
                                            </PerfectScrollbar>
                                        </div>
                                        :
                                        <div className="text-center">
                                            <Translate id='misc.search.empty.title' />
                                        </div>
                                    }
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit'><Translate id='members.profile.overview.memberFamilyRelations.add.search' /></Button>
                            </ModalFooter>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}

export default withContexts(MembersContext, IdentityRolesContext, OrganizationContext)(AddFamilyRelationSearch);