export default{
    "task.mandatoryChangesRequests.title"       : "Member Change Requests",
    "task.mandatoryChangesRequests.modal.title" : "Manage Member Change Requests",

    "task.mandatoryChangesRequests.row.request.requested_by" : "Requested by",
    "task.mandatoryChangesRequests.row.request.dateTime"     : "Date / Time",

    "task.mandatoryChangesRequests.modal.cancel.btn" : "Cancel Request",

    "task.mandatoryChangesRequests.sidepanel.request"              : "Request",
    "task.mandatoryChangesRequests.sidepanel.request.status"       : "Request Status",
    "task.mandatoryChangesRequests.sidepanel.request.requested_by" : "Requested by",
    "task.mandatoryChangesRequests.sidepanel.request.data"         : "Requested Changes",
    "task.mandatoryChangesRequests.sidepanel.number"               : "{count} requests selected",
    "task.mandatoryChangesRequests.sidepanel.members.number"       : "{count} members selected",

    "task.mandatoryChangesRequests.manage.all"    : "Manage All",
    "task.mandatoryChangesRequests.filter.status" : "Filter by Status",

    "task.mandatoryChangesRequests.sidepanel.request.success" : "Successfully updated the requests' status.",
    "task.mandatoryChangesRequests.sidepanel.request.multi"   : "There are no change requests left in queue to be approved.",

}