export default{
    "members.profile.overview.memberAddressPhone.title"            : "Address(es) and phone number(s)",
    "members.profile.overview.memberAddressPhone.title.incomplete" : "Please verify all addresses for this member.",
    "members.profile.overview.memberAddressPhone.edit.title"       : "Edit - Address and phone number(s)",
    "members.profile.overview.memberAddressPhone.edit.save"        : "Save changes",
    "members.profile.overview.memberAddressPhone.edit.cancel"      : "Cancel changes",

    "members.profile.overview.memberAddressPhone.address"         : "Address(es)",
    "members.profile.overview.memberAddressPhone.address.primary" : "Primary",
    "members.profile.overview.memberAddressPhone.phoneNumbers"    : "Phone number(s)",
    "members.profile.overview.memberAddressPhone.addNumber"       : "Add a phone number",
    "members.profile.overview.memberAddressPhone.addAddress"      : "Add an address",
    "members.profile.overview.memberAddressPhone.verifyLink"      : "Verify address",
    "members.profile.overview.memberAddressPhone.primaryAddress"  : "Primary address",
    "members.profile.overview.memberAddressPhone.primaryVerify"   : "Please verify the member's primary address",
    "members.profile.overview.memberAddressPhone.noAdditional"    : "This member does not have any additional addresses",
    "members.profile.overview.memberAddressPhone.noPrimary"       : "This member does not have a primary address<break></break>Click to add an address",

    // address confirmation modal
    "members.profile.overview.memberAddressPhone.modal.addressToChange" : "Address to validate:",
    "members.profile.overview.memberAddressPhone.modal.title"           : "Address Confirmation",

    "members.profile.overview.memberAddressPhone.modal.accept.primary"      : "Accept and set this address as primary.",
    "members.profile.overview.memberAddressPhone.modal.accept.primary.desc" : "This option will validate the address and set it as this member's primary address.",

    "members.profile.overview.memberAddressPhone.modal.accept.additional"      : "Accept the address as an additional address.",
    "members.profile.overview.memberAddressPhone.modal.accept.additional.desc" : "This option will validate the address and set it as an additional address.",

    "members.profile.overview.memberAddressPhone.modal.checkbox.documentsValidated" : "The required documents have been validated.",

    "members.profile.overview.memberAddressPhone.modal.reject"      : "Reject the address.",
    "members.profile.overview.memberAddressPhone.modal.reject.desc" : "This option will reject the address, .",

    "members.profile.overview.memberAddressPhone.modal.reject.noteLabel" : "Rejection Note",
    "members.profile.overview.memberAddressPhone.modal.reject.required"  : "A note is required when rejecting a member's address.",

    "members.profile.overview.memberAddressPhone.editModal.title"            : "Edit address",
    "members.profile.overview.memberAddressPhone.createModal.title"          : "Create address",
    "members.profile.overview.memberAddressPhone.deleteModal.title"          : "You are about to delete this address",
    "members.profile.overview.memberAddressPhone.status.note"                : "Rejection Status Note:",
    "members.profile.overview.memberAddressPhone.years_same_address"         : "Move in year:",
    "members.profile.overview.memberAddressPhone.years_same_address.tooltip" : "Year since participant has resided at current address.",
    "members.profile.overview.memberAddressPhone.created_at"                 : "Created at:",
    "members.profile.overview.memberAddressPhone.updated_at"                 : "Updated at:",
    "members.profile.overview.memberAddressPhone.open.reject"                : "Click here to view rejected addresses",
    "members.profile.overview.memberAddressPhone.close.reject"               : "Click here to close rejected addresses",
    "members.profile.overview.button.validate"                               : "Validate",

    "members.profile.overview.memberAddressPhone.status.CONFIRMED"   : "Confirmed",
    "members.profile.overview.memberAddressPhone.status.REJECTED"    : "Rejected",
    "members.profile.overview.memberAddressPhone.status.UNCONFIRMED" : "Unconfirmed",

    "members.profile.overview.memberAddressPhone.modal.confirm.0" : "Successfully validated the member's address and set it as primary.",
    "members.profile.overview.memberAddressPhone.modal.confirm.1" : "Successfully validated the member's address.",
    "members.profile.overview.memberAddressPhone.modal.confirm.2" : "Successfully rejected the member's address.",
}