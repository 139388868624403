export default{
    "header.home"                         : "Accueil",
    "header.account"                      : "Mon Compte",
    "header.change"                       : "Changer",
    "header.settings"                     : "Paramètres",
    "header.myProfile"                    : "Mon Profil",
    "header.signout"                      : "Déconnexion",
    "header.signout.confirm"              : "Voulez-vous vraiment vous déconnecter?",
    "header.supportRequest"               : "Mes Demandes de Soutien",
    "header.oldNavigation"                : "Ancienne Navigation",
    "header.oldNavigation.confirm"        : "Nous vous recommandons fortement d'utiliser la nouvelle navigation avec un appareil mobile.",
    "header.oldNavigation.confirm2"       : "Voulez-vous tout de même revenir à l'ancienne navigation?",
    "header.newNavigation"                : "Nouvelle Navigation",
    "header.more"                         : "Plus",
    // Breacrumbs
    "header.breadcrumbs.changePeriod.tip" : "Voir une différente période",
    "header.breadcrumbs.profile"          : "Profil",

    // Mobile
    "header.mobile.menu.back" : "Retour au menu principal",

    // Notifications
    "header.notifications.title"               : "Notifications",
    "header.communications.title"              : "Communications",
    "header.communications.empty.title"        : "Communications à jour",
    "header.account.title"                     : "Compte",
    "header.notifications.goBack"              : "Retour",
    "header.langSwitch.title"                  : "Changer la langue",
    "header.helpCenter.title"                  : "Aide",
    "header.notifications.empty.title"         : "Notifications à jour",
    "header.notifications.jira.goTo"           : "Voir toutes les demandes",
    "header.notifications.comms.goTo"          : "Voir boîte de réception",
    "header.communications.empty.msg"          : "Vous n'avez aucune nouvelle communication.",
    "header.notifications.empty.text"          : "Vous n'avez aucune nouvelle notification.",
    "header.notifications.tabs.supportRequest" : "Support",
    "header.notifications.tabs.communication"  : "Communications",
    "header.notifications.tabs.news"           : "Nouvelles",
    "header.notifications.tabs.organization"   : "Organisation",
    "header.notifications.btn.clearAll"        : "Tout marquer comme lu",
    "header.notifications.btn.deleteAll"       : "Tout supprimer",

    "header.notifications.item.title"     : "Demande de support",
    "header.notifications.item.tip.clear" : "Supprimer la notification",
    "header.notifications.item.tip.read"  : "Marquer comme vue",
    "header.notifications.item.unread"    : "{count, plural, =0 {# message non lu} one {# message non lu} other {# messages non lus}}",

    "header.notifications.settings.title"      : "Paramètres",
    "header.notifications.settings.subtitle"   : "Activez ou désactivez les notifications que vous souhaitez recevoir.",
    "header.notifications.settings.btn.save"   : "Enregistrer",
    "header.notifications.settings.toast.msg"  : "Paramètres à jour !",
    "header.notifications.settings.toast.info" : "Les paramètres de notifications ont été mis à jour.",
    "header.notifications.settings.error"      : "<b>Oups !</b> Une erreur s'est produite. Veuillez réessayer ou revenir plus tard.",
}