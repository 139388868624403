import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import moment from 'moment';
import { DisplayI18n } from '../../../../helpers/i18nHelper';

const WorkflowStep = ({ resquestState, isLast, organization, identity, date, isLastAction }) => {
    return (
        <div className="d-table-row">
            <div className="d-table-cell py-2 requestBar-container">
                <div className={stringBuilder('requestBar', {
                    'requestBar-bottom': isLast, // last request has rounded bottom
                    'requestBar-active': resquestState === 'APPROVED', // if its approved, add a blue bar over
                    'requestBar-bottom-active': isLastAction || resquestState === 'REFUSED', // if its pending (the next approval)
                })}
                >
                    <div className="requestBar-dot" />
                </div>
            </div>
            <div className="d-table-cell py-2">
                <div className={"font-medium " + (resquestState === 'CLOSED' ? 'text-muted' : 'text-dark')}>
                    <Status is={resquestState === 'APPROVED' ? 'APPROVED' : resquestState === 'REFUSED' ? 'REFUSED' : 'PENDING'} />
                </div>
                {organization &&
                    <div className="text-muted">
                        <DisplayI18n
                            field='name'
                            defaultValue={organization.organisation_name}
                            i18n={organization.i18n}
                        />
                        {identity &&
                            <>
                                <span className='mx-1'>-</span>
                                <>{identity.name} {identity.family_name}</>
                            </>
                        }
                        {date &&
                            <>
                                <span className='mx-1'>-</span>
                                {moment(date).format('lll')}
                            </>
                        }
                        {/* TODO: Add date here */}
                    </div>
                }
            </div>
        </div>
    );
}

const Status = ({ is }) => {
    switch (is){
        case 'APPROVED':
            return (<><Translate id={`transfer.sidepanel.workflow.step.${is}`} /><i className="mdi mdi-check text-success ml-1" /></>);
        case 'REFUSED':
            return (<><Translate id={`transfer.sidepanel.workflow.step.${is}`} /><i className="mdi mdi-close text-danger ml-1" /></>);
        case 'PENDING':
            return <Translate id={`transfer.sidepanel.workflow.step.${is}`} />;
        default:
            return null;
    }
}

export default WorkflowStep;