import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useEffect, useRef } from 'react';
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import {
    Card,
    CardBody
} from "reactstrap";
import { TeamsContext } from './../../../../../contexts/TeamsContext';
import generatePassword from './../../../../../helpers/passwordGenerator';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import DisplayOrganization from '../../../../../components/organization/DisplayOrganization';
import { getStatus } from './../../../TeamHelpers';

const TeamLog = ({ teamId }) => {

    const teamsContext = useContext(TeamsContext);

    const tableRef = useRef(null);

    useEffect(() => {
        tableRef.current?.refreshTable();
    }, [ teamsContext.state.cachedTeam?.team_status?.team_status_id ])

    return (
        <Card className="card-shadow">
            <CardBody>
                <SpordleTableProvider
                    id='teamApprovalLog'
                    clickable={false}
                    columns={[
                        {
                            label: <Translate id='team.profile.teamInfos.logs.created_at' />,
                            key: "created_at",
                            mobile: true,
                            sortable: true,
                            dataClassName: 'text-nowrap',
                        },
                        {
                            label: <Translate id='team.profile.teamInfos.logs.team_status' />,
                            key: "team_status.name",
                            mobile: true,
                            sortable: true,
                            dataClassName: 'text-nowrap',
                        },
                        {
                            label: <Translate id='team.profile.teamInfos.logs.identity.name' />,
                            key: "identity.name",
                            mobile: true,
                            sortable: true,
                            dataClassName: 'text-nowrap',
                        },
                        {
                            label: <Translate id='team.profile.teamInfos.logs.identity.family_name' />,
                            key: "identity.family_name",
                            mobile: true,
                            sortable: true,
                            dataClassName: 'text-nowrap',
                        },
                        {
                            label: <Translate id='team.profile.teamInfos.logs.identity.email' />,
                            key: "identity.email",
                            mobile: true,
                            sortable: true,
                            dataClassName: 'text-nowrap',
                        },
                        {
                            label: <Translate id='team.profile.teamInfos.logs.organisation_name' />,
                            key: "organisation.organisation_name",
                            mobile: true,
                            sortable: true,
                            dataClassName: 'text-nowrap',
                        },
                    ]}
                    ref={tableRef}
                    pagination={50}
                    dataIndex='key'
                    paginationMessage='reports.spordleTable.pagination.message'
                    defaultSorting='-created_at'
                    renderRow={(columnKey, data) => {
                        switch (columnKey){
                            case 'created_at':
                                return <DateFormat value={data.created_at} format='YYYY-MM-DD HH:mm' />
                            case 'identity.name':
                            case 'identity.family_name':
                                return data.identity?.[columnKey] || "-";
                            case 'identity.email':
                                if(data.identity){
                                    return <a href={`mailto:${data.identity.email}`}>{data.identity.email}</a>;
                                }
                                return "-";
                            case 'organisation.organisation_name':
                                return (
                                    <DisplayOrganization organisation={{ organisation_id: data.organisation.organisation_id }}>
                                        <DisplayI18n defaultValue={data.organisation.organisation_name} field='name' i18n={data.organisation.i18n} />
                                    </DisplayOrganization>
                                );
                            case 'team_status.name':
                                return getStatus(data.team_status);
                            default:
                                break;
                        }
                    }}
                    loadData={(from, data, spordleTable) => {
                        switch (from){
                            case 'REFRESH':
                            case 'FILTER':
                                spordleTable.setLoading()
                            case 'CDM':
                                return teamsContext.getTeamsStatusLogs(teamId).then((logs) => {
                                    return (logs.map((log) => ({
                                        ...log,
                                        key: generatePassword(),
                                    })));
                                });
                            default:
                                break;
                        }
                    }}
                    searchKeys={[
                        { name: 'invoice_number', weight: 3 },
                        { name: 'unique_identifier', weight: 2 },
                        'last_name',
                        'first_name',
                    ]}
                >
                    <div className="pb-2 d-flex align-items-end">
                        <div className="card-title font-bold h4 mb-1"><Translate id='teams.profile.teamInfos.logs.title' /></div>
                        <div className='d-flex ml-auto text-right mr-1'>
                            <SpordleTableProvider.Refresh />
                        </div>
                    </div>
                    <SpordleTableView />
                </SpordleTableProvider>
            </CardBody>
        </Card>
    );
}

export default TeamLog;