export default{
    "members.profile.memberProfile.title"                          : "Member",
    "members.profile.memberProfile.header.yearsOld"                : "{age} years old",
    "members.profile.memberProfile.header.age"                     : "Age",
    "members.profile.memberProfile.header.status"                  : "Status",
    "members.profile.memberProfile.header.primaryOrg"              : "Primary organization",
    "members.profile.memberProfile.header.sharedOrg"               : "Shared organization(s)",
    "members.profile.memberProfile.header.sharedOrg.nrp"           : "Shared organization(s) - Non-Residential Player",
    "members.profile.memberProfile.header.memberType"              : "Member type",
    "members.profile.memberProfile.header.memberTypes"             : "Member types",
    "members.profile.memberProfile.header.position"                : "Position",
    "members.profile.memberProfile.header.positions"               : "Positions",
    "members.profile.memberProfile.header.positions.currentPeriod" : "Current Season",
    "members.profile.memberProfile.header.positions.lastPeriod"    : "Last Season",
    "members.profile.memberProfile.header.coachLevel"              : "Coach level",
    "members.profile.memberProfile.header.refereeLevel"            : "Official level",
    "members.profile.memberProfile.header.playerDivision"          : "Player division",
    "members.profile.memberProfile.header.statusReason"            : "Status Reason",
    "members.profile.memberProfile.header.statusNote"              : "Status Note",
    "members.profile.memberProfile.header.statusImposedBy"         : "Status Imposed by",
    "members.profile.memberProfile.header.officiatingCoach"        : "Officiating Coach",

    "members.profile.memberProfile.header.updated_at"            : "Date: ",
    "members.profile.memberProfile.header.updated_by"            : "Updated by: ",
    "members.profile.memberProfile.header.systemUpdate"          : "System",
    "members.profile.memberProfile.header.merged"                : "Merged",
    "members.profile.memberProfile.header.organisation_deciding" : "Imposed by: ",

    "members.profile.memberProfile.header.info"             : "{status} Status Information: ",
    "members.profile.memberProfile.header.info.BLOCKED"     : "Blocked Status Information: ",
    "members.profile.memberProfile.header.info.INELIGIBLE"  : "Ineligible Status Information: ",
    "members.profile.memberProfile.header.info.UNCONFIRMED" : "Unconfirmed Status Information: ",
    "members.profile.memberProfile.header.info.CONFIRMED"   : "Confirmed Status Information: ",
    "members.profile.memberProfile.header.info.DRAFT"       : "Draft Status Information: ",

    "members.profile.memberProfile.header.btns.requestTransfer" : "Request {transfer}",
    "members.profile.memberProfile.header.btns.releaseMember"   : "Release member",
    "members.profile.memberProfile.header.btns.copyUrl"         : "Copy url",
    "members.profile.memberProfile.header.btns.sendMessage"     : "Send message",

    "members.profile.memberProfile.header.tags.medicalAttention" : "Medical Attention",
    "members.profile.memberProfile.header.tags.suspendedMember"  : "Suspended Member",

    "members.profile.memberProfile.header.card.transactions"                : "Transactions",
    "members.profile.memberProfile.header.card.amountPaid"                  : "Amount paid",
    "members.profile.memberProfile.header.card.amountDue"                   : "Amount due",
    "members.profile.memberProfile.header.card.amountScheduled"             : "Scheduled",
    "members.profile.memberProfile.header.card.creditAmount"                : "Remaining credits",
    "members.profile.memberProfile.header.card.outstandingBalance"          : "Outstanding Balance",
    "members.profile.memberProfile.header.card.outstandingBalance.required" : "Outstanding Balance is required",
    "members.profile.memberProfile.header.card.outstandingBalance.title"    : "Edit outstanding balance",
    "members.profile.memberProfile.header.card.outstandingBalance.success"  : "Outstanding Balance was updated!",
    "members.profile.memberProfile.header.picture.upload.need.approval"     : "You can upload a picture through Member Documents. The profile picture will be updated once it is approved.",
    "members.profile.memberProfile.header.picture.expiry"                   : "Expires:",

    "members.profile.memberProfile.header.card.quitour.title"       : "Confirm this member",
    "members.profile.memberProfile.header.card.quitour.message"     : "This member is ready to be confirmed. Once a member profile is complete and doesn't have any remaining deficiencies, you can click on this button to confirm the member's status. ",
    "members.profile.memberProfile.header.badges.quicktour.title"   : "Member Status Badges",
    "members.profile.memberProfile.header.badges.quicktour.message" : "These badges represent certain deficiencies a member profile can have. You can click on a badge as a shortcut to manage the deficiency. All the badges must be cleared to be able to confirm the member's status.",

    "members.profile.memberProfile.tabs.overview"       : "Overview",
    "members.profile.memberProfile.tabs.registration"   : "Registrations",
    "members.profile.memberProfile.tabs.transactions"   : "Transactions",
    "members.profile.memberProfile.tabs.team"           : "Teams",
    "members.profile.memberProfile.tabs.qualifications" : "Qualifications",
    "members.profile.memberProfile.tabs.history"        : "History",
    "members.profile.memberProfile.tabs.waivers"        : "Waivers",
    "members.profile.memberProfile.tabs.invoice"        : "Invoice",
    "members.profile.memberProfile.tabs.receipt"        : "Receipt",
    "members.profile.memberProfile.tabs.usa"            : "USA",
    "members.profile.memberProfile.tabs.international"  : "International",
    "members.profile.memberProfile.tabs.cti"            : "CTI Request",

    "members.profile.memberProfile.notFound.title"       : "Profile not found",
    "members.profile.memberProfile.notFound.description" : "The profile you are looking for was not found, it may have been moved or removed.",
    "members.profile.memberProfile.notFound.backButton"  : "Go back",

    "members.profile.header.back" : "Back to members list",

    "members.profile.confirmModal.title"                        : "Confirm Member",
    "members.profile.unconfirmModal.title"                      : "Make Member Unconfirmed",
    "members.profile.unconfirmModal.message"                    : "This will set the members status to Unconfirmed.",
    "members.profile.preConfirmModal.message"                   : "This member is ready to be confirmed.",
    "members.profile.confirmModal.message"                      : "This will set the members status to Confirmed.",
    "members.profile.action.confirmModal.successText"           : "Successfully confirmed this member.",
    "members.profile.action.unconfirmModal.successText"         : "Successfully made this member this member unconfirmed.",
    "members.profile.ineligibleModal.title"                     : "Make a member ineligible",
    "members.profile.changeStatus.title"                        : "Change Member Status",
    "members.profile.statusChange.reason"                       : "Reason For Status Change",
    "members.profile.statusChange.reason.short"                 : "Reason",
    "members.profile.statusChange.organization"                 : "Imposing Organization",
    "members.profile.ineligibleModal.message"                   : "This will set the members status to Ineligible, blocking all activity on the platform.",
    "members.profile.changeStatus.notification.message"         : "Submitting this status change with trigger a notification.",
    "members.profile.action.remove.ineligibility.btn"           : "Remove Ineligibility",
    "members.profile.action.unconfirm"                          : "Make Member Unconfirmed",
    "members.profile.blockModal.title"                          : "Block Member",
    "members.profile.unblockModal.title"                        : "Unblock Member",
    "members.profile.blockModal.field.note"                     : "Note",
    "members.profile.memberType.suspendedUntil"                 : "Suspended until {date}",
    "members.profile.memberType.pendingRemovalRequest"          : "Pending removal request",
    "members.profile.memberType.manage.title"                   : "Manage Member Types",
    "members.profile.memberType.manage.success"                 : "The member types were updated!",
    "members.profile.memberType.status.decidedBy"               : "Decided By",
    "members.profile.memberType.status.edit"                    : "Edit Status",
    "members.profile.memberType.changeStatus"                   : "Update Member Type Status",
    "members.profile.memberType.toApprove"                      : "Types to change",
    "members.profile.blockModal.field.note.required"            : "A note is required to block a member.",
    "members.profile.ineligibleModal.field.note.required"       : "A note is required to make a member ineligible.",
    "members.profile.removeIneligibleModal.field.note.required" : "A note is required to remove a member's ineligibility.",
    "members.profile.action.unblock.btn"                        : "Unblock",
    "members.profile.action.unblock.modalText"                  : "You are about to unblock this member.",
    "members.profile.action.remove.ineligible.modalText"        : "You are about to remove this members ineligibility.",
    "members.profile.action.remove.unblock.status.info"         : "This will return the member to their previous status: ",
    "members.profile.action.unblock.successText"                : "Successfully unblocked the member.",
    "members.profile.action.remove.ineligibility.successText"   : "Successfully removed this members ineligibility.",
    "members.profile.action.block.successText"                  : "Successfully blocked the member.",
    "members.profile.action.ineligibile.successText"            : "Successfully made this member ineligible.",
    "members.profile.action.changestatus.successText"           : "Successfully changed members' status.",

    "members.profile.action.changeStatus"                     : "Change Status",
    "members.profile.action.status.required"                  : "Status is required.",
    "members.profile.action.note.required"                    : "Note is required.",
    "members.profile.action.reason.required"                  : "Reason is required.",
    "members.profile.action.org.required"                     : "Organization is required.",
    // Requests
    "members.profile.action.requestMandatoryChanges"          : "Request Mandatory Changes",
    "members.profile.action.requestClassificationChanges"     : "Request Classification Changes",
    "members.profile.action.view.requestMandatoryChanges"     : "View Mandatory Field Changes",
    "members.profile.action.requestClassificationChange"      : "Request Classification Changes",
    "members.profile.action.view.requestClassificationChange" : "View Classification Changes",
    "members.profile.action.classificationChange"             : "Classification Changes",
    "members.profile.action.requestRelease"                   : "Request Permanent Release",
    "members.profile.action.viewRequestRelease"               : "View Request Permanent Release",
    "members.profile.action.ctiRequest"                       : "CTI Request",

    "members.profile.action.requestMandatoryChanges.success"      : "Mandatory changes have been requested",
    "members.profile.action.requestClassificationChanges.success" : "Classification change has been requested",
    "members.profile.action.requestRelease.success"               : "Permanent release has been requested",
    "members.profile.action.ctiRequest.success"                   : "CTI request submitted",

    "members.profile.create.RequestRelease"   : "This will create a request to permanently release the member.",
    "members.profile.has.RequestRelease"      : "Permanent release",
    "members.profile.has.RequestRelease.from" : "Permanent release from",

    "members.profile.badge.mutatedYear"            : "{mutated} {count}",
    "members.profile.badge.mutatedYear.expiration" : "Expiration date",

    "members.profile.badge.pendingRequestMandatoryChanges"      : "Pending Mandatory Changes Request",
    "members.profile.badge.pendingRequestClassificationChanges" : "Pending Classification Changes Request",
    "members.profile.badge.pendingRequestRelease"               : "Pending Permanent Release Request",
    "members.profile.badge.pendingCtiRequest"                   : "Pending CTI Request",


    "members.profile.header.note.NEW_PROFILE"         : "New profile.",
    "members.profile.header.note.ADDRESS_CHNAGE"      : "An address has changed.",
    "members.profile.header.note.ADDRESS_CHANGE"      : "An address has changed.",
    "members.profile.header.note.BASIC_FIELDS_CHANGE" : "A basic field has changed.",
    "members.profile.header.note.HISTORICAL_SANCTION" : "Member has a historical sanction.",

    "members.profile.action.reactivate.successText"        : "Successfully reactivated this member.",
    "members.profile.action.remove.reactivate.btn"         : "Reactivate Member",
    "members.profile.action.remove.reactivate.modalText"   : "Reactivate this member",
    "members.profile.action.remove.reactivate.successText" : "Successfully reactivated this member.",

    // mandatory field translations
    "members.profile.mandatory.change.fields.first_name"             : "First name",
    "members.profile.mandatory.change.fields.last_name"              : "Last name",
    "members.profile.mandatory.change.fields.birthdate"              : "Birthdate",
    "members.profile.mandatory.change.fields.relation"               : "Relation",
    "members.profile.mandatory.change.fields.gender"                 : "Gender",
    "members.profile.mandatory.change.fields.primary_language"       : "Primary Language",
    "members.profile.mandatory.change.fields.secondary_language"     : "Secondary Language",
    "members.profile.mandatory.change.fields.nationality"            : "Citizenship",
    "members.profile.mandatory.change.fields.birth_country"          : "Birth country",
    "members.profile.mandatory.change.fields.email"                  : "Email",
    "members.profile.mandatory.change.fields.identity_as_indigenous" : "Identify as Indigenous",
    "members.profile.mandatory.change.fields.indigenous_group"       : "Indigenous group",
    "members.profile.mandatory.change.fields.ethnicity"              : "Ethnicity",
    "members.profile.mandatory.change.fields.phone"                  : "Phone number",
    "members.profile.mandatory.change.fields.extension"              : "Extension",
    "members.profile.mandatory.change.fields.phone_type"             : "Phone Type",
    "members.profile.mandatory.change.fields.address"                : "Address",
    "members.profile.mandatory.change.fields.address_type"           : "Address Type",
    "members.profile.mandatory.change.fields.move_in_year"           : "Move-in year",
    "members.profile.mandatory.change.fields.address2"               : "Apartment, suite, unit, floor, building, etc.",

    "members.profile.mandatory.change.new.request"    : "New change request",
    "members.profile.new.request"                     : "New request",
    "members.profile.mandatory.change.cancel.success" : "Successfully cancelled the change request.",
    "members.profile.request.cancel.success"          : "Successfully cancelled the request.",
    "members.profile.mandatory.change.make.changes"   : "Changes to at least one field must be made to submit a change request. ",

    "members.profile.classification.change.fields.type.label"               : "Request Type",
    "members.profile.classification.change.fields.type.classification"      : "Classification",
    "members.profile.classification.change.fields.type.overage"             : "Over-age",
    "members.profile.classification.change.fields.type.underage"            : "Under-age",
    "members.profile.classification.change.fields.from.division.label"      : "From Division",
    "members.profile.classification.change.fields.to.division.label"        : "To Division",
    "members.profile.classification.change.fields.from.team.category.label" : "From Team Category",
    "members.profile.classification.change.fields.to.team.category.label"   : "To Team Category",
    "members.profile.classification.change.fields.division.required"        : "Division is required.",
    "members.profile.classification.change.fields.team.category.required"   : "Team category is required.",
    "members.profile.classification.change.fields.type.required"            : "Please select a type",
    "members.profile.classification.change.noAvailableDivisions"            : "There are no available divisions for this request type.",
    "members.profile.classification.view.fields.division"                   : "Division",
    "members.profile.classification.view.fields.team.category"              : "Team Category",
    "members.profile.classification.view.fields.division.birth.years"       : "Born between {minYear} and {maxYear}.",

    "members.profile.status.logs.sidepanel.title" : "Status change",

    "members.setting.organisation_imposing_ineligibility.missing" : "Missing 'organisation_imposing_ineligibility' setting to filter the imposing organization select",

    "members.profile.multipleorganization.title" : "2<sup>nd</sup> Club",
}