import { useContext } from "react";
import { FormGroup, Input, Label, ModalBody } from "reactstrap";
import { AuthContext } from "../../../../../contexts/contexts";
import Translate from "@spordle/intl-elements";
import { SendCommModalContext } from "../../../context/SendCommModalContext";
import ReviewMembers from "./addMembers/ReviewMembers";
import { MODE_MEMBER, MODE_ORG } from "../../../sendCommHelper";
import ReviewOrgs from "./addOrg/ReviewOrgs";

const ReviewRecipients = () => {
    const { mode } = useContext(SendCommModalContext);
    const { account } = useContext(AuthContext);

    return (
        <>
            <ModalBody className="pb-0 mb-n3">
                <FormGroup>
                    <Label className="text-muted" for="from">
                        <Translate id="communications.inbox.from" />
                    </Label>
                    <Input readOnly disabled value={`${account.email} (Me)`} />
                </FormGroup>
            </ModalBody>
            {mode === MODE_MEMBER &&
                <ReviewMembers />
            }
            {mode === MODE_ORG &&
                <ReviewOrgs />
            }
        </>
    );
}

export default ReviewRecipients;