// import { displayI18n, DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import Translate from '@spordle/intl-elements';
import { Fragment, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import useSWR from 'swr';
import { FormsContext } from '../../../../../../contexts/FormsContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { CustomFormGroup } from '../../../../../members/profile/registration/components/Sidepanel/components/SidepanelCustomFormGroup';

const ClinicAttendeesSidepanelForm = ({ invoiceItem, selectedRows, updateState, invoiceNumber, ...props }) => {
    const { getInvoiceForms } = useContext(FormsContext);

    const { data: customForms, isValidating, mutate } = useSWR(
        invoiceNumber ? [ 'getInvoiceForms-ClinicAttendeesSidepanelForm', invoiceNumber, selectedRows.member.member_id ] : null,
        () => getInvoiceForms(invoiceNumber, { member_id: selectedRows.member.member_id })
            .then((forms) => forms.filter((form) => form.active == '1')),
    );

    return (
        <div className="p-3">
            <div className="h4 font-bold mb-3">
                <Translate id='memberProfile.registration.sidepanel.form' />
            </div>
            {isValidating && !customForms ?
                <>
                    <Skeleton count={1} height={20} width="75%" />
                    <Skeleton count={1} height={19} width="50%" className="mb-2" />
                    <Skeleton count={1} height={70} width="100%" />
                </>
                : Array.isArray(customForms) && customForms.length > 0 ?
                    customForms.map((customForm) => (
                        <Fragment key={customForm.custom_form_id}>
                            <div className="mb-2">
                                <div className="h5 font-medium mb-1">{customForm.name}</div>
                                {invoiceItem?.registration_fee &&
                                    <div>
                                        <DisplayI18n
                                            field="name"
                                            defaultValue={invoiceItem.registration_fee.fee.name}
                                            i18n={invoiceItem.registration_fee.fee.i18n}
                                        />
                                    </div>
                                }
                            </div>
                            {customForm.groups?.map((group, index) => (
                                <CustomFormGroup
                                    key={group.custom_form_group_id}
                                    index={index}
                                    registration={invoiceItem}
                                    group={group}
                                    selectedRows={selectedRows}
                                    onSuccess={mutate}
                                    formId={customForm.custom_form_id}
                                    customForm={customForm}
                                    {...props}
                                />
                            ))}
                        </Fragment>
                    ))
                    :
                    <>
                        <div className="h5 font-medium mb-1">
                            <Translate id='memberProfile.registration.questionnaires.noForm.title' />
                        </div>
                        <p>
                            <Translate id='memberProfile.registration.questionnaires.noForm' />
                        </p>
                    </>
            }
        </div>
    );
}

export default ClinicAttendeesSidepanelForm;