import { FormikCheckedButton, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { Button, Col, Collapse, Fade, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { boolean, number, object, string } from "yup";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import CustomAlert from "../../../../../../components/CustomAlert";
import Required from "../../../../../../components/formik/Required";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import { fail, success } from '@spordle/toasts';
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";

const FormClinicAddContact = ({ isInstructor, isOpen, toggle, member, onCreate, alreadyExists, clinicId, previous, selectMember, apiCall }) => {
    return (
        <Formik
            enableReinitialize
            initialValues={{
                email: member?.email || '',
                phone: '',
                extension: '',
                asInstructor: false,
                notify: false,
                showStatus: 'SHOW',
            }}
            validationSchema={object().shape({
                email: string().required(<Translate id='form.validation.email.required' />).email(<Translate id='form.validation.email.valid' />),
                phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />),
                extension: number(),
                asInstructor: boolean(),
                notify: boolean(),
                showStatus: string(),
            })}
            onSubmit={async(values, { setStatus }) => {
                const contact = { ...member, ...values }
                const result = await alreadyExists?.(contact)
                if(result){
                    setStatus(<Translate id={result === 'instructor' ? 'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.asInstructor' : 'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.exists'} />);
                }else if(member?.unique_identifier && isInstructor){ // Is existing member AND instructor only -> Cannot link for contacts(API)
                    selectMember(contact);
                }else{
                    return apiCall(contact)
                        .then(() => {
                            onCreate?.(contact);
                            success();
                            toggle();
                        })
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                fail();
                                console.error(e.message);
                                setStatus()
                            }
                        })
                }

                // addContact({...member, ...values})
                //     .then(response => {
                //         if (!response) {
                //             setStatus('error')
                //         } else if (response === 'instructor') {
                //             setStatus('error-instructor')
                //         } else {
                //             toggle();
                //         }
                //     })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <Collapse isOpen={isOpen}>
                            <Fade in={isOpen}>
                                <ModalHeader toggle={toggle}><Translate id={`clinics.clinicSessionInformation.formClinicSessionInformation.${isInstructor ? 'instructorContactInfo.addInstructor' : 'hostContactInfo.addContact'}`} /></ModalHeader>
                                <ModalBody>
                                    {member &&
                                        <UserDisplay className="p-3 w-100" card>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    width={50}
                                                    abbr={member.firstName.charAt(0) + member.lastName.charAt(0)}
                                                    alt={member.firstName + ' ' + member.lastName}
                                                    src={member.picture?.full_path}
                                                    filePos={member.picture?.file_position}
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>{member.firstName + ' ' + member.lastName}</UserDisplay.Title>
                                                {member.unique_identifier &&
                                                    <UserDisplay.Subtitle>#{member.unique_identifier}</UserDisplay.Subtitle>
                                                }
                                                {member.birthdate &&
                                                    <UserDisplay.Subtitle>{member.birthdate} (<Translate id='misc.yearsOld' values={{ age: member.age }} />)</UserDisplay.Subtitle>
                                                }
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    }
                                    <FormGroup>
                                        <Label for="email" className="text-muted"><Translate id='form.fields.email' /> <Required /></Label>
                                        <FormikInputText id="email" name='email' trim type='email' autoComplete='email' />
                                    </FormGroup>
                                    <Row>
                                        <Col md='8'>
                                            <FormGroup>
                                                <Label for="phone" className="text-muted"><Translate id='form.fields.phone' /></Label>
                                                <FormikPhoneInput id='phone' name='phone' />
                                            </FormGroup>
                                        </Col>
                                        <Col md='4'>
                                            <FormGroup>
                                                <Label for='extension' className='text-muted'><Translate id='form.fields.extension' /></Label>
                                                <FormikInputNumber id='extension' name='extension' autoComplete='tel-extension' />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup>
                                        <Label for='showStatus' className='text-muted'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus' /></Label>
                                        <FormikSelect
                                            id='showStatus'
                                            name='showStatus'
                                            search={false}
                                            loadingStatus='success'
                                            defaultData={[
                                                { value: 'SHOW', label: 'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus.SHOW', translateLabel: true },
                                                { value: 'SHOW_PARTIAL', label: 'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus.SHOW_PARTIAL', translateLabel: true },
                                                { value: 'HIDE', label: 'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus.HIDE', translateLabel: true },
                                            ]}
                                        />
                                    </FormGroup>
                                    {!isInstructor &&
                                        <>
                                            <FormGroup>
                                                <FormikCheckedButton id='asInstructor' name='asInstructor' label='clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.asInstructor' translateLabel />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormikCheckedButton id='notify' name='notify' label='clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.notify' translateLabel />
                                            </FormGroup>
                                        </>
                                    }

                                    <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                        <CustomAlert color='danger' text={formik.status} translateText={false} withTitle toggle={() => formik.setStatus(null)} />
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button className="mr-auto" color="primary" type="button" outline onClick={previous}><Translate id='misc.previous' /></Button>
                                    <Button color="primary" type="submit"><Translate id='misc.add' /></Button>
                                </ModalFooter>
                            </Fade>
                        </Collapse>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default FormClinicAddContact;