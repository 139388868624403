import { FormikCheckedButton, FormikDateTime, FormikGroup, FormikInputNumber, FormikInputText, FormikRadioButton, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import moment from "moment";
import { Col, FormGroup, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import Required from "../components/formik/Required";
import { displayI18n, DisplayI18n } from "./i18nHelper";

// eslint-disable-next-line unused-imports/no-unused-imports
import { ReactNode } from 'react';
import FormikEditable from "../components/formik/FormikEditable";

/**
 * @typedef {object} AdditionalField
 * @property {string} fieldId
 * @property {string} answer
 * @property {string} optionId
 */

/**
 * Returns an object containing the initial values
 * @param {object[]} formGroups The form groups
 * @param {AdditionalField[]} [additionalFields] The additional fields answers
 * @returns {object}
 * @example
 * initialValues={{
 *     name: '',
 *     email: '',
 *     fields: getAdditionalFieldsInitialValues(this.getFormGroups(), this.getAdditionalFields()),
 * }}
 */
export function getAdditionalFieldsInitialValues(formGroups, additionalFields = null){

    const temp = {};
    formGroups.forEach((group, indexGroup) => {
        group.fields.forEach((field, indexField) => {
            switch (field.form_field_code){
                // simple input fields
                case 'TEXT':
                case 'TEXT_AREA':
                case 'NUMBER':
                    temp[`group_${indexGroup}_fields_${indexField}`] = additionalFields?.find((f) => f?.fieldId === field.custom_form_field_id)?.answer || '';
                    break;

                // dates
                case 'DATE':
                case 'DATETIME':
                    temp[`group_${indexGroup}_fields_${indexField}`] = additionalFields?.find((f) => f?.fieldId === field.custom_form_field_id)?.answer ? moment(additionalFields?.find((f) => f?.fieldId === field.custom_form_field_id)?.answer) : '';
                    break;

                // arrays
                case 'CHECKBOX':
                case 'SELECTBOX_MULTI':
                    const optionIds = additionalFields?.find((f) => f?.fieldId === field.custom_form_field_id)?.optionId
                    temp[`group_${indexGroup}_fields_${indexField}`] = optionIds ? optionIds?.split(',') : [];
                    break;

                // single options
                case 'RADIO':
                    // radio should always have a value
                    temp[`group_${indexGroup}_fields_${indexField}`] = additionalFields?.find((f) => f?.fieldId === field.custom_form_field_id)?.optionId || field.options[0].custom_form_field_option_id;
                    break;

                case 'SELECTBOX':
                    temp[`group_${indexGroup}_fields_${indexField}`] = additionalFields?.find((f) => f?.fieldId === field.custom_form_field_id)?.optionId || '';
                    break;
            }
        })
    })
    return temp;
}

/**
 * Formats a JSON config to a validation schema
 * @see Found on this {@link https://github.com/jquense/yup/issues/559#issuecomment-518953000|post}
 */
function createYupSchema(schema, config){
    const { id, validationType, validations = [] } = config;
    if(!Yup[validationType]){
        return schema;
    }
    let validator = Yup[validationType]();
    validations.forEach((validation) => {
        const { params, type } = validation;
        if(!validator[type]){
            return;
        }
        validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
}

// fieldKey is used for formikEditable
function buildConfig(formGroups, fieldKey = null){
    const schemaConfig = [];
    formGroups.forEach((group, indexGroup) => {
        group.fields.forEach((field, indexField) => {
            switch (field.form_field_code){
                // array
                case 'CHECKBOX':
                case 'SELECTBOX_MULTI':
                    schemaConfig.push({
                        id: fieldKey || `group_${indexGroup}_fields_${indexField}`,
                        validationType: 'array',
                        validations: [
                            // conditionnal array building :)
                            // eslint-disable-next-line react/jsx-key
                            ...field.mandatory === '1' ? [ { type: 'min', params: [ 1, <Translate id='form.required' /> ] } ] : [],
                        ],
                    })
                    break;

                    // number
                case 'NUMBER':
                    schemaConfig.push({
                        id: fieldKey || `group_${indexGroup}_fields_${indexField}`,
                        validationType: 'number',
                        validations: [
                            // conditionnal array building :)
                            // eslint-disable-next-line react/jsx-key
                            ...field.mandatory === '1' ? [ { type: 'required', params: [ <Translate id='form.required' /> ] } ] : [],
                            // eslint-disable-next-line react/jsx-key
                            ...field.min ? [ { type: 'min', params: [ field.min, <Translate id='form.min' /> ] } ] : [],
                            // eslint-disable-next-line react/jsx-key
                            ...field.max ? [ { type: 'max', params: [ field.max, <Translate id='form.max' /> ] } ] : [],
                        ],
                    })
                    break;

                    // date / time
                case 'DATE':
                case 'DATETIME':
                    schemaConfig.push({
                        id: fieldKey || `group_${indexGroup}_fields_${indexField}`,
                        validationType: 'mixed',
                        validations: [
                            // conditionnal array building :)
                            ...field.mandatory !== '1' ? [ { type: 'test', params: [ {
                                name: 'dateTimeNotRequired',
                                message: <Translate id='form.validation.date.format' />,
                                test: (value) => {
                                    if(!value)
                                        return true;
                                    return moment.isMoment(value);
                                },
                            } ] } ] : [],
                            // eslint-disable-next-line react/jsx-key
                            ...field.mandatory === '1' ? [ { type: 'required', params: [ <Translate id='form.required' /> ] }, { type: 'test', params: [ {
                                name: 'dateTimeRequired',
                                message: <Translate id='form.validation.date.format' />,
                                test: (value) => {
                                    return moment.isMoment(value);
                                },
                            } ] } ] : [],
                            ...field.min ? [ { type: 'test', params: [ {
                                name: 'dateTimeMin',
                                message: <Translate id='form.min' />,
                                test: (value) => {
                                    if(!value)
                                        return true;
                                    return field.form_field_code === 'DATETIME' ? moment(value).format('HH:mm') >= moment(field.min).format('HH:mm') : moment(value).isAfter(moment(field.min));
                                },
                            } ] } ] : [],
                            ...field.max ? [ { type: 'test', params: [ {
                                name: 'dateTimeMax',
                                message: <Translate id='form.max' />,
                                test: (value) => {
                                    if(!value)
                                        return true;
                                    return field.form_field_code === 'DATETIME' ? moment(value).format('HH:mm') <= moment(field.max).format('HH:mm') : moment(value).isBefore(moment(field.max));
                                },
                            } ] } ] : [],
                        ],
                    })
                    break;

                    // string
                case 'TEXT':
                case 'TEXT_AREA':
                case 'RADIO':
                case 'SELECTBOX':
                    schemaConfig.push({
                        id: fieldKey || `group_${indexGroup}_fields_${indexField}`,
                        validationType: 'string',
                        validations: [
                            // conditionnal array building :)
                            // eslint-disable-next-line react/jsx-key
                            ...field.mandatory === '1' ? [ { type: 'required', params: [ <Translate id='form.required' /> ] } ] : [],
                        ],
                    })
                    break;
            }
        })
    })
    return schemaConfig;
}

/**
 * Returns the validation object shape
 * @param {object[]} formGroups The form groups
 * @returns {object}
 * @example
 * validationSchema={object().shape({
 *     name: string(),
 *     email: string(),
 *     fields: getAdditionalFieldsValidationSchema(this.getFormGroups()),
 * }}
 */
export function getAdditionalFieldsValidationSchema(formGroups, fieldKey = null){
    return Yup.object().shape(buildConfig(formGroups, fieldKey).reduce(createYupSchema, {}))
}

function getLabelHelper(field){
    if(field.min && field.max){
        if(field.form_field_code === 'DATETIME'){
            return <span className='text-muted small'> (<Translate id='components.manualRegistration.forms.label.between' /> {moment(field.min).format('HH:mm')} <Translate id='components.manualRegistration.forms.label.and' /> {moment(field.max).format('HH:mm')})</span>
        }
        return <span className='text-muted small'> (<Translate id='components.manualRegistration.forms.label.between' /> {field.min} <Translate id='components.manualRegistration.forms.label.and' /> {field.max})</span>

    }else if(field.min){
        if(field.form_field_code === 'DATETIME'){
            return <span className='text-muted small'> (<Translate id='components.manualRegistration.forms.label.min' />: {moment(field.min).format('HH:mm')})</span>
        }
        return <span className='text-muted small'> (<Translate id='components.manualRegistration.forms.label.min' />: {field.min})</span>

    }else if(field.max){
        if(field.form_field_code === 'DATETIME'){
            return <span className='text-muted small'> (<Translate id='components.manualRegistration.forms.label.max' />: {moment(field.max).format('HH:mm')})</span>
        }
        return <span className='text-muted small'> (<Translate id='components.manualRegistration.forms.label.max' />: {field.max})</span>

    }
    return ''
}

// TODO: we might want to add some logic to display the fields differently (example: short answers can be displayed with col-6 instead of col-12)
function displayField(field, fieldName){
    switch (field.form_field_code){
        case 'TEXT':
            return <FormikInputText id={fieldName} name={fieldName} trim />
        case 'TEXT_AREA':
            return <FormikTextArea id={fieldName} name={fieldName} trim />
        case 'CHECKBOX':
            return (
                <FormikGroup name={fieldName} rowProps={{ className: 'mx-0 mb-0' }}>
                    {field.options.map((option) => (
                        <FormikCheckedButton
                            key={option.custom_form_field_option_id}
                            className='mr-3 mb-3'
                            id={option.custom_form_field_option_id}
                            name={fieldName}
                            value={option.custom_form_field_option_id}
                            label={<DisplayI18n field={'field_option'} defaultValue={option.field_option} i18n={option.i18n} />}
                            translateLabel={false}
                        />
                    ))}
                </FormikGroup>
            );
        case 'RADIO':
            return (
                <FormikGroup name={fieldName} rowProps={{ className: 'mx-0 mb-0' }}>
                    {field.options.map((option, index) => (
                        <FormikRadioButton
                            key={option.custom_form_field_option_id}
                            className='mr-3 mb-3'
                            id={option.custom_form_field_option_id}
                            name={fieldName}
                            value={option.custom_form_field_option_id}
                            label={<DisplayI18n field={'field_option'} defaultValue={option.field_option} i18n={option.i18n} />}
                            translateLabel={false}
                        />
                    ))}
                </FormikGroup>
            )
        case 'SELECTBOX_MULTI':
            return (
                <FormikSelect
                    name={fieldName} id={fieldName}
                    multi search={false}
                    renderOption={(option) => <DisplayI18n field='field_option' defaultValue={option.option.label} i18n={option.option.i18n} />}
                    loadingStatus='success'
                    defaultData={field.options.map((option) => ({
                        value: option.custom_form_field_option_id,
                        label: option.field_option,
                        i18n: option.i18n,
                    }))}
                />
            )
        case 'SELECTBOX':
            return (
                <FormikSelect
                    name={fieldName} id={fieldName} search={false}
                    renderOption={(option) => <DisplayI18n field={'field_option'} defaultValue={option.option.label} i18n={option.option.i18n} />}
                    loadingStatus='success'
                    defaultData={field.options.map((option) => ({
                        value: option.custom_form_field_option_id,
                        label: option.field_option,
                        i18n: option.i18n,
                    }))}
                />
            )
        case 'DATE':
            return (
                <FormikDateTime
                    id={fieldName}
                    name={fieldName}
                    timeFormat={false}
                    dateFormat={'YYYY-MM-DD'}
                    isValidDate={(current) => {
                        if(field.min && field.max){
                            return moment(current).isAfter(moment(field.min)) && moment(current).isBefore(moment(field.max))
                        }else if(field.min){
                            return moment(current).isAfter(moment(field.min))
                        }else if(field.max){
                            return moment(current).isBefore(moment(field.max))
                        }
                        return true

                    }}
                    renderInput={(props) => (
                        <div className='search-wrapper'>
                            <input {...props} placeholder='' />
                        </div>
                    )}
                />
            )
        case 'DATETIME':
            return (
                <FormikDateTime
                    id={fieldName}
                    name={fieldName}
                    dateFormat={false}
                    renderInput={(props) => (
                        <div className='search-wrapper'>
                            <input {...props} placeholder='' />
                        </div>
                    )}
                />
            )
        case 'NUMBER':
            return <FormikInputNumber id={fieldName} name={fieldName} />
    }
}

/**
 * Returns the fields or the answers for the form groups
 * @param {object[]} formGroups The form groups
 * @param {string} fieldName The formik index containing the additional fields values
 * @param {AdditionalField[]} [additionalFields] The additional fields answers
 * @param {boolean} [readOnly] To display either the fields or just the answers
 * @returns {ReactNode}
 */
export function getAdditionalFieldsRender(formGroups, fieldName, additionalFields, readOnly, groupTitleClassName){
    return (
        formGroups.map((formGroup, groupIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={groupIndex}>
                <div className={groupTitleClassName || "font-bold text-dark mb-2"}><DisplayI18n field='name' defaultValue={formGroup.name} i18n={formGroup.i18n} /></div>
                <Row>
                    {formGroup.fields.map((field, fieldIndex) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Col sm='12' key={fieldIndex}>
                            <FormGroup>
                                {readOnly ?
                                    <>
                                        <div className='text-muted'><DisplayI18n field={'title'} defaultValue={field.title} i18n={field.i18n} /></div>
                                        <div className='font-medium'>{getAdditionalFieldAnswer(field, additionalFields)}</div>
                                    </>
                                    :
                                    <>
                                        <Label className='text-muted' for={`${fieldName ? fieldName + '.' : ''}group_${groupIndex}_fields_${fieldIndex}`}>
                                            <DisplayI18n field={'title'} defaultValue={field.title} i18n={field.i18n} />
                                            {getLabelHelper(field)}
                                            {field.mandatory === '1' &&
                                                <> <Required /></>
                                            }
                                        </Label>
                                        {displayField(field, `${fieldName ? fieldName + '.' : ''}group_${groupIndex}_fields_${fieldIndex}`)}
                                    </>
                                }
                            </FormGroup>
                        </Col>
                    ))}
                </Row>
            </div>
        ))
    )
}

/**
 * Returns the fields or the answers for the form groups in formik editables
 * @param {object[]} formGroups The form groups
 * @param {string} fieldName The formik index containing the additional fields values
 * @param {AdditionalField[]} [additionalFields] The additional fields answers
 * @param {boolean} [readOnly] To display either the fields or just the answers
 * @param {string} [groupTitleClassName] classnames for the title of a group
 * @returns {ReactNode}
 */
export function getAdditionalFieldsFormikEditableRender(formGroups, fieldName, additionalFields, readOnly, groupTitleClassName, onSubmit){
    return (
        formGroups.map((formGroup, groupIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={groupIndex}>
                <div className={groupTitleClassName || "font-bold text-dark mb-2"}><DisplayI18n field='name' defaultValue={formGroup.name} i18n={formGroup.i18n} /></div>
                <Row>
                    {formGroup.fields.map((field, fieldIndex) => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <Col sm='12' key={fieldIndex}>
                                <FormGroup>
                                    <FormikEditable
                                        readOnly={readOnly}
                                        id={`group_${groupIndex}_fields_${fieldIndex}`}
                                        initialValues={getAdditionalFieldsInitialValues(formGroups, additionalFields)} // get all initial values to more easily syncRows
                                        validationSchema={getAdditionalFieldsValidationSchema([ { fields: [ field ] } ], `group_${groupIndex}_fields_${fieldIndex}`)} // we only send "field" so we only get the validation schema of the current field
                                        onSubmit={onSubmit} // submission is handled case-by-case
                                    >
                                        {(isEditing) => {
                                            if(!isEditing){
                                                return (
                                                    <div>
                                                        <div className='text-muted'><DisplayI18n field={'title'} defaultValue={field.title} i18n={field.i18n} /></div>
                                                        <div className='font-medium'>{getAdditionalFieldAnswer(field, additionalFields)}</div>
                                                    </div>
                                                );
                                            }
                                            return (
                                                <div>
                                                    <Label className='text-muted' for={`${fieldName ? fieldName + '.' : ''}group_0_fields_0`}>
                                                        <DisplayI18n field={'title'} defaultValue={field.title} i18n={field.i18n} />
                                                        {getLabelHelper(field)}
                                                        {field.mandatory === '1' &&
                                                            <> <Required /></>}
                                                    </Label>
                                                    {displayField(field, `group_${groupIndex}_fields_${fieldIndex}`)}
                                                </div>
                                            );

                                        } }
                                    </FormikEditable>
                                </FormGroup>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        ))
    )
}

/**
 * Returns the answer in either JSX or string
 * @param {object} field Field
 * @param {AdditionalField[]} additionalFields The additional fields answers
 * @returns {string|ReactNode}
 */
function getAdditionalFieldAnswer(field, additionalFields){
    if(!additionalFields?.find((f) => f.fieldId === field.custom_form_field_id)?.answer)
        return '-'
    switch (field.form_field_code){
        case 'TEXT':
        case 'TEXT_AREA':
        case 'DATE':
        case 'DATETIME':
        case 'NUMBER':
            return additionalFields?.find((f) => f.fieldId === field.custom_form_field_id)?.answer || '-'
        case 'CHECKBOX':
        case 'RADIO':
        case 'SELECTBOX_MULTI':
        case 'SELECTBOX':
            const selectedOption = additionalFields?.find((f) => f.fieldId === field.custom_form_field_id)
            if(selectedOption){
                const selectedOptionIds = selectedOption.optionId.split(',')
                return selectedOptionIds.map((selectedOptionId, index) => {
                    const option = field.options.find((option) => option.custom_form_field_option_id === selectedOptionId)
                    return (
                        <>
                            {index !== 0 && ', '}
                            <DisplayI18n key={selectedOptionId} field={'field_option'} defaultValue={option?.field_option} i18n={option?.i18n} />
                        </>
                    )
                })
            }
            return '-'
    }
}

/**
 * Returns the formatted additional fields for the API, use jsObjectToApi or see orgContext.updateOrganizationPartial for an example
 * @param {object} values The formik additional fields values
 * @param {object[]} formGroups The form groups
 * @param {string} locale The locale from getGenericLocale
 * @returns {object[]}
 */
export function getFormattedAdditionalFields(values, formGroups, locale){
    return Object.keys(values).reduce((newArray, key) => {
        // keys look like this `group_${indexGroup}_fields_${indexField}`
        const [ _temp1, groupIndex, _temp2, fieldIndex ] = key.split('_');
        let answer, option = null;

        // has options -> checkbox, radio, select or multi select
        if(formGroups[groupIndex].fields[fieldIndex].options && formGroups[groupIndex].fields[fieldIndex].options.length > 0){
            option = values[key]

            // multi options -> checkbox or multi select
            if(Array.isArray(values[key])){
                // answer = array of translated option names
                answer = values[key].map((optionId) => {
                    return displayI18n(
                        'field_option',
                        formGroups[groupIndex].fields[fieldIndex].options.find((o) => o.custom_form_field_option_id === optionId)?.i18n,
                        formGroups[groupIndex].fields[fieldIndex].options.find((o) => o.custom_form_field_option_id === optionId)?.field_option,
                        locale,
                    )
                })
            }else{ // single option -> radio, select
                answer = displayI18n(
                    'field_option',
                    formGroups[groupIndex].fields[fieldIndex].options.find((o) => o.custom_form_field_option_id === values[key])?.i18n,
                    formGroups[groupIndex].fields[fieldIndex].options.find((o) => o.custom_form_field_option_id === values[key])?.field_option,
                    locale,
                ) || '';
            }

        // no options -> short answer, text area, date, time or number
        }else if(formGroups[groupIndex].fields[fieldIndex].form_field_code === 'DATE' && values[key]){ // DATE, YYYY-MM-DD format
            answer = moment.isMoment(values[key]) ? values[key].format('YYYY-MM-DD') : moment(values[key]).format('YYYY-MM-DD')
        }else if(formGroups[groupIndex].fields[fieldIndex].form_field_code === 'DATETIME' && values[key]){ // DATETIME, ISO format
            answer = moment.isMoment(values[key]) ? values[key].toISOString() : moment(values[key]).toISOString()
        }else{
            // simple string or number
            answer = values[key]
        }

        newArray.push({
            custom_form_field_id: formGroups[groupIndex].fields[fieldIndex].custom_form_field_id,
            custom_form_field_option_id: option,
            answer: answer,
        })
        return newArray
    }, [])
}