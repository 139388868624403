export default{
    "teams.profile.teamInfos.tab.title" : "Team information",

    "teams.profile.teamInfos.general.title"                : "General Infos",
    "teams.profile.teamInfos.general.teamName"             : "Team name",
    "teams.profile.teamInfos.general.teamName.required"    : "Team name is required",
    "teams.profile.teamInfos.general.shortName"            : "Short name",
    "teams.profile.teamInfos.general.shortName.required"   : "Short name is required",
    "teams.profile.teamInfos.general.category"             : "Category",
    "teams.profile.teamInfos.general.colorHome"            : "Home color",
    "teams.profile.teamInfos.general.colorHome.required"   : "Home color is required",
    "teams.profile.teamInfos.general.colorAway"            : "Away color",
    "teams.profile.teamInfos.general.colorAway.required"   : "Away color is required",
    "teams.profile.teamInfos.general.league"               : "League",
    "teams.profile.teamInfos.general.sponsor"              : "Sponsor",
    "teams.profile.teamInfos.general.tournamentNumber"     : "Tournament #",
    "teams.profile.teamInfos.general.address.required"     : "Address is required if a second address is specified",
    "teams.profile.teamInfos.general.addressType"          : "Address type",
    "teams.profile.teamInfos.general.addressType.required" : "Address type is required if an address is specified",
    "teams.profile.teamInfos.general.teamFee"              : "Team fee",

    "teams.profile.teamInfos.general.lock_roster_date"           : "Active Roster Lock Date",
    "teams.profile.teamInfos.general.lock_affiliate_roster_date" : "Affiliate Roster Lock Date",

    "teams.profile.teamInfos.general.lock_roster_hour.required"           : "The hour is required if a date is entered",
    "teams.profile.teamInfos.general.lock_affiliate_roster_hour.required" : "The hour is required if a date is entered",

    "teams.profile.teamInfos.contact.title"                  : "Team Contacts",
    "teams.profile.teamInfos.contact.confirmDelete"          : "Confirm that you want to delete this team contact.",
    "teams.profile.teamInfos.contact.reset"                  : "Reset",
    "teams.profile.teamInfos.contact.reset.title"            : "Reset",
    "teams.profile.teamInfos.contact.reset.confirm"          : "You are about to reset this team's contact information",
    "teams.profile.teamInfos.contact.role"                   : "Role",
    "teams.profile.teamInfos.contact.role.required"          : "Role is required",
    "teams.profile.teamInfos.contact.skip"                   : "Skip this step",
    "teams.profile.teamInfos.contact.display.on.team.roster" : "Display on Team Roster PDF",
    "teams.profile.teamInfos.contact.display.sidepanel"      : "Display",

    "teams.profile.teamInfos.logs.title" : "Team Approval Logs",
    "teams.profile.teamInfos.logs.show"  : "Show",
    "teams.profile.teamInfos.logs.hide"  : "Hide",

    "team.profile.teamInfos.logs.identity.name"        : "First Name",
    "team.profile.teamInfos.logs.identity.family_name" : "Last Name",
    "team.profile.teamInfos.logs.identity.email"       : "Email",
    "team.profile.teamInfos.logs.created_at"           : "Modification Date",
    "team.profile.teamInfos.logs.organisation_name"    : "Organization Name",
    "team.profile.teamInfos.logs.team_status"          : "Team Status",
}