import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { useContext } from "react";
import Translate from "@spordle/intl-elements";
import { FormikSelect } from "@spordle/formik-elements";
import SettingLabel from "../../../../components/SettingLabel";

const ConfigurationSettingsViewMemberType = ({ isEdit }) => {
    const { settings } = useContext(OrganizationContext);
    const needMemberType = settings.require_member_type_for_rostering;

    if(!needMemberType){
        return null;
    }

    const isOn = needMemberType.value == 1;

    return (
        <div className="mb-3">
            <SettingLabel setting={needMemberType} code="require_member_type_for_rostering" />
            {isEdit ?
                <FormikSelect
                    className="mt-1"
                    name="require_member_type_for_rostering"
                    id="setting-member-type"
                    search={false}
                    options={[
                        {
                            value: "1",
                            label: "misc.yes",
                            translateLabel: true,
                        },
                        {
                            value: "0",
                            label: "misc.no",
                            translateLabel: true,
                        },
                    ]}
                />
                :
                <div className="mt-1 text-dark">
                    <i className={`mdi mdi-${isOn ? "check text-primary" : "close text-danger"} mr-1`} /><Translate id={`misc.${isOn ? "yes" : "no"}`} />
                </div>
            }
        </div>
    );
}

export default ConfigurationSettingsViewMemberType;