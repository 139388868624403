import Translate from "@spordle/intl-elements";
import { Button, Input, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useState } from "react";
import { exportToCsv } from "../../../../../../helpers/export";
import moment from "moment";
import { useIntl } from "react-intl";
import { useSteps } from "../../../../../../components/customHooks/useSteps";
import CrossFade from "../../../../../../components/crossFade/CrossFade";

const RecipientsModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName="CommunicationsAllRecipientsModal">
            <RecipientsModalInner {...props} />
        </AnalyticsModal>
    );
}

const RecipientsModalInner = ({ toggle, message = {} }) => {
    const [ step, { next } ] = useSteps(2, 0);
    const [ val, setVal ] = useState("");
    const { formatMessage } = useIntl();
    const recipients = message.email_to.split(',');
    const filteredRecipients = recipients.filter((email) => email.includes(val));

    const handleOnChange = (e) => {
        setVal(e.currentTarget.value);
    }

    const handleOnEmpty = () => {
        setVal("");
    }

    const handleOnDownload = () => {
        // exportToCsv(
        //     `SpordleID-Communication_${message.title}-recipients__${moment().format('YYYY-MM-DD_H:mm')}.csv`,
        //     [
        //         [// Headers
        //             formatMessage({ id: "form.fields.firstName" }),
        //             formatMessage({ id: "form.fields.lastName" }),
        //             formatMessage({ id: "form.fields.email" }),
        //             formatMessage({ id: "members.search.memberSearch.filters.memberNumber" }),
        //             "ID",
        //         ],
        //         ...recipients.map((recipient) => ([
        //             recipient.first_name,
        //             recipient.last_name,
        //             recipient.email,
        //             recipient.unique_identifier || "-",
        //             recipient.member_id,
        //         ])),
        //     ],
        // )
        exportToCsv(
            `SpordleID-Communication_${message.title}-recipients__${moment().format('YYYY-MM-DD_H:mm')}.csv`,
            [
                [// Headers
                    formatMessage({ id: "form.fields.email" }),
                ],
                ...recipients.map((email) => ([
                    email.trim(),
                ])),
            ],
        )
    }

    return (
        <>
            <ModalHeader toggle={toggle}>
                <Translate id="communications.inbox.recipients.modal.title" values={{ title: message.title }} /> ({recipients.length})
            </ModalHeader>
            <CrossFade isVisible={step === 1}>
                <ModalBody>
                    <div className="text-center">
                        <div className="mdi mdi-download text-primary" style={{ fontSize: 60 }} />
                        <div className="font-medium h4"><Translate id="communications.message.recipients.downloaded.title" /></div>
                        <p>
                            <Translate
                                id="communications.message.recipients.downloaded.msg"
                                values={{
                                    a: (chunk) => (
                                        <button onClick={handleOnDownload} type="button" className="reset-btn text-primary">
                                            {chunk}
                                        </button>
                                    ),
                                }}
                            />
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="primary" type="button" onClick={toggle}><Translate id="misc.close" /></Button>
                </ModalFooter>
            </CrossFade>
            <CrossFade isVisible={step === 0}>
                <ModalBody>
                    <div className="position-relative">
                        <Input onChange={handleOnChange} value={val} className="mb-3" placeholder="Search..." type="search" />
                        {val && <button type="button" onClick={handleOnEmpty} className="mdi mdi-close text-primary reset-btn position-absolute top-0 bottom-0 right-0 p-2" />}
                    </div>
                    <PerfectScrollbar className="max-vh-50" options={{ suppressScrollX: true }}>
                        {filteredRecipients.length > 0 ?
                            filteredRecipients.map((email) => (
                                <div className="w-100 p-1 bg-white border rounded mb-2" key={email}>
                                    {email}
                                </div>
                                // <UserDisplay card block className="mb-2" key={`all-recipients-${member.member_id}`}>
                                //     <UserDisplay.Container>
                                //         <UserImg
                                //             src={null}
                                //             className="text-uppercase"
                                //             width={50}
                                //             abbr={member.first_name}
                                //             alt={`${member.first_name} ${member.last_name}`}
                                //         />
                                //     </UserDisplay.Container>
                                //     <UserDisplay.Container>
                                //         <UserDisplay.Title>
                                //             {member.first_name} {member.last_name}
                                //         </UserDisplay.Title>
                                //         <UserDisplay.Subtitle>
                                //             <InlineCopy toCopy={member.email}>
                                //                 {member.email}
                                //             </InlineCopy>
                                //         </UserDisplay.Subtitle>
                                //         {member.unique_identifier &&
                                //             <UserDisplay.Subtitle>
                                //                 <HoverableCopy toCopy={member.unique_identifier}>
                                //                     #{member.unique_identifier}
                                //                 </HoverableCopy>
                                //             </UserDisplay.Subtitle>
                                //         }
                                //     </UserDisplay.Container>
                                // </UserDisplay>
                            ))
                            :
                            <div className="text-center text-muted py-3">
                                <div className="mdi mdi-account-search line-height-1 fa-5x" />
                                <div><Translate id="communications.inbox.recipients.search.empty" /></div>
                            </div>

                        }
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                            handleOnDownload();
                            next();
                        }}
                    >
                        <i className="mdi mdi-download mr-1" /><Translate id="misc.download" />
                    </Button>
                    <Button color="primary" outline type="button" onClick={toggle}><Translate id="misc.close" /></Button>
                </ModalFooter>
            </CrossFade>
        </>
    )
}

export default RecipientsModal;