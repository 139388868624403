export default{
    // general
    "settings.payees.serviceType"          : "Type de service",
    "settings.payees.businessName"         : "Nom d'entreprise",
    "settings.payees.serviceType.required" : "Le type de service est requis",
    "settings.payees.prefix"               : "Préfixe",
    "settings.payees.prefix.DR."           : "Dr.",
    "settings.payees.prefix.MR."           : "M.",
    "settings.payees.prefix.MRS."          : "Mme.",


    // add
    "settings.payees.add.title" : "Ajouter un bénéficiaire",

    // sidepanel
    "settings.payees.sidepanel.delete.text" : "Vous êtes sur le point de supprimer ce bénéficiaire: {name}",

}