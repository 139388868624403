import Translate from "@spordle/intl-elements";
import PropTypes from 'prop-types';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import CanDoAction from "../../permissions/CanDoAction";

/**
 * Top right actions menu of the sidepanel
 * @param {Object} props
 * @param {string} props.children
 * @param {string} [props.label=misc.actions]
 * @param {string} [props.skipTranslateLabel=false]
 * @returns {JSX.Element}
 * @example
 * <SidePanelActionsMenu action='DELETE' componentCode='members' componentPermissionCode='members_request_transfer_usa'>
 *      <SidePanelMenuAction>Delete stuff</SidePanelMenuAction>
 * </SidePanelActionsMenu>
 */
const SidePanelActionsMenu = ({ children, label = 'misc.actions', skipTranslateLabel = false, ...props }) => {
    const Wrapper = (wrapProps) => {
        if(wrapProps.action && wrapProps.componentCode && wrapProps.componentPermissionCode)
            return <CanDoAction {...wrapProps} tag={UncontrolledDropdown} />
        return <UncontrolledDropdown {...wrapProps} />
    }

    return (
        // inNavbar to disable popper (not useful in sidepanel)
        <Wrapper inNavbar {...props}>
            <DropdownToggle data-name="sidepanel-actions-menu" caret color="primary">
                {skipTranslateLabel ? label : <Translate id={label} />}
            </DropdownToggle>
            <DropdownMenu right>{children}</DropdownMenu>
        </Wrapper>
    )
}

export default SidePanelActionsMenu;

SidePanelActionsMenu.propTypes = {
    action: PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
    componentCode: PropTypes.string,
    componentPermissionCode: PropTypes.string,
    label: PropTypes.string,
    skipTranslateLabel: PropTypes.bool,
}

SidePanelActionsMenu.defaultProps = {
    skipTranslateLabel: false,
    label: 'misc.actions',
}