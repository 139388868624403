import React from 'react';
import { stringBuilder } from "@spordle/helpers";
import Svg from "../../../components/Svg";
import { NavLink } from "react-router-dom";

// Language
import Translate from '@spordle/intl-elements';

// Context Providers
import withContexts from '../../../helpers/withContexts';
import { RolesContext } from '../../../contexts/RolesContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';

import Footer from '../footer/footer';
import navigationRoutes from '../../../routes/navigationRoutes';
import GroupedSidebarLinks from '../sidebar/components/GroupedSidebarLinks';
import { getSessionStorageItem, setSessionStorageItem } from '../../../helpers/browserStorage';
import SidebarLinks from '../sidebar/components/SidebarLinks';
import { AuthContext } from '../../../contexts/contexts';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';

import Swiper from '../../../components/swipeViews/Swiper';
import SwipeSlide from '../../../components/swipeViews/SwipeSlide';

class MobileMenu extends React.Component{
    state = {
        sidebarSubRoutes: [],
        openedCollapse: {},
    }

    /*--------------------------------------------------------------------------------*/
    /* Grouped Routes & Collapse in sidebar                                           */
    /*--------------------------------------------------------------------------------*/
    hasGroupedRoutes = (routes) => {
        return routes?.some((r) => r.navlabel)
    }

    toggleCollapse = (collapseName, isOpen) => {
        this.setState((prevState) => ({ openedCollapse: { ...prevState.openedCollapse, [collapseName]: isOpen } }), () => {
            setSessionStorageItem('sidebarOpenedCollapse', JSON.stringify(this.state.openedCollapse));
        })

    }

    isRenderableLink = (route) => route.hiddenMobile || route.hiddenRoute || route.redirect || ((route.devOnly && process.env.REACT_APP_ENVIRONMENT === 'prod'))

    checkCollapseIsOpen = (name) => {
        return this.state.openedCollapse?.[name] === undefined || this.state.openedCollapse[name] === true
    }

    /*--------------------------------------------------------------------------------*/
    /*Component Lifecycle                                                             */
    /*--------------------------------------------------------------------------------*/
    componentDidMount(){
        new Promise((resolve) => {
            resolve(JSON.parse(getSessionStorageItem('sidebarOpenedCollapse')));
        })
            .then((openedCollapse) => {
                this.setState(() => ({ openedCollapse: openedCollapse }));
            })
            .catch((error) => { console.error(error.message) });
    }

    render(){
        return (
            <RolesContext.Consumer>
                {({ validateRouteAccess, hasAccessTo }) => (
                    <>
                        <Swiper className="sid-mobile-menu-inner h-100">
                            <SwipeSlide nb={1} className="d-flex flex-column">
                                {({ next, previous }) => (
                                    <>
                                        <div className="scroll-sidebar">
                                            <ul id="sidebarnav" className="sid-sidebar-list">
                                                {navigationRoutes.map((prop, key) => {
                                                    if(this.isRenderableLink(prop)){
                                                        return null;
                                                    }
                                                    const showFor = validateRouteAccess(prop);

                                                    return showFor && (
                                                        <React.Fragment key={prop.name}>
                                                            {prop.subPermissionsRoutes && !prop.skipSubPermissionsRoutes && Array.isArray(prop.subPermissionsRoutes) && prop.subPermissionsRoutes.length > 0 ?
                                                                <li
                                                                    onClick={() => {
                                                                        this.setState(() => ({ sidebarSubRoutes: prop.subPermissionsRoutes }));
                                                                        next();
                                                                    }}
                                                                    className={stringBuilder("sidebar-item sidebar-item-header p-3 sid-mobile-menu-item", showFor === 'ADMIN' ? 'text-purple' : 'text-secondary')}
                                                                >
                                                                    {prop.icon} {prop.skipTranslate ? prop.name : <Translate id={prop.name} />} <i className="ml-auto mdi mdi-chevron-right" />
                                                                </li>
                                                                :
                                                                <NavLink onClick={this.props.closeMenu} to={prop.path} className={stringBuilder("sidebar-item sidebar-item-header p-3 sid-mobile-menu-item", showFor === 'ADMIN' ? 'text-purple' : 'text-secondary')}>
                                                                    {prop.icon} {prop.skipTranslate ? prop.name : <Translate id={prop.name} />}
                                                                </NavLink>
                                                            }
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        <ul className="d-flex ml-3 list-unstyled">
                                            <li style={{ flexBasis: "33%" }} className="text-center pr-3 flex-shrink-0">
                                                <div className="square-element">
                                                    <NavLink onClick={this.props.closeMenu} to="/organization" className="square-element-inner p-2 sid-mobile-menu-square-item">
                                                        <Svg width="50%" height="80%" className="mx-auto d-block" icon="organisation" />
                                                        <small className="d-block text-truncate w-100">
                                                            <Translate id="misc.organization" />
                                                        </small>
                                                    </NavLink>
                                                </div>
                                            </li>
                                            {(hasAccessTo("reports") || this.props.IdentityRolesContext.isGod()) &&
                                                <li style={{ flexBasis: "33%" }} className="text-center pr-3 flex-shrink-0">
                                                    <div className="square-element">
                                                        <NavLink onClick={this.props.closeMenu} to="/reports" className={stringBuilder("square-element-inner p-2 sid-mobile-menu-square-item", { 'text-purple': !hasAccessTo("reports") && this.props.IdentityRolesContext.isGod() })}>
                                                            <Svg width="50%" height="80%" className="mx-auto d-block" icon="reports" />
                                                            <small className="d-block text-truncate w-100">
                                                                <Translate id="sidebar.reports" />
                                                            </small>
                                                        </NavLink>
                                                    </div>
                                                </li>
                                            }
                                            {(hasAccessTo("communications") || this.props.IdentityRolesContext.isGod()) &&
                                                <li style={{ flexBasis: "33%" }} className="text-center pr-3 flex-shrink-0">
                                                    <div className="square-element">
                                                        <NavLink onClick={this.props.closeMenu} to="/communications" className={stringBuilder("square-element-inner p-2 sid-mobile-menu-square-item", { 'text-purple': !hasAccessTo("communications") && this.props.IdentityRolesContext.isGod() })}>
                                                            <Svg width="50%" height="80%" className="mx-auto d-block" icon="communications" />
                                                            <small className="d-block text-truncate w-100">
                                                                <Translate id="sidebar.communications" />
                                                            </small>
                                                        </NavLink>
                                                    </div>
                                                </li>
                                            }
                                        </ul>
                                        <Footer />
                                    </>
                                )}
                            </SwipeSlide>
                            <SwipeSlide
                                onExited={() => this.setState(() => ({ sidebarSubRoutes: [] }))}
                                nb={2}
                                className="d-flex flex-column"
                            >
                                {({ previous }) => (
                                    <>
                                        <div
                                            onClick={previous}
                                            className="py-3 pl-2 border-bottom text-secondary bg-inherit font-bold"
                                        >
                                            <span className=""><i className='mr-1 mdi mdi-arrow-left' /><Translate id="header.mobile.menu.back" /></span>
                                        </div>
                                        <div className="min-h-0 d-flex flex-column sidebar-nav scroll-sidebar">
                                            {this.hasGroupedRoutes(this.state.sidebarSubRoutes) ?
                                                <div id="sidebarnav" className='sid-sidebar-list'>
                                                    <GroupedSidebarLinks
                                                        closeMenu={this.props.closeMenu}
                                                        routes={this.state.sidebarSubRoutes}
                                                        toggleCollapse={this.toggleCollapse}
                                                        checkCollapseIsOpen={this.checkCollapseIsOpen}
                                                    />
                                                </div>
                                                :
                                                <ul id="sidebarnav" className="sid-sidebar-list">
                                                    <SidebarLinks
                                                        closeMenu={this.props.closeMenu}
                                                        routes={this.state.sidebarSubRoutes}
                                                    />
                                                </ul>
                                            }
                                        </div>
                                    </>
                                )}
                            </SwipeSlide>
                        </Swiper>
                    </>
                )}
            </RolesContext.Consumer>
        );
    }
}

export default withContexts(AuthContext, OrganizationContext, IdentityRolesContext)(MobileMenu);