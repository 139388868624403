export default{
    // Review
    "clinics.clinicSessionInformation.clinicSessionInformation.title" : "Session(s) information",

    // Host contact info
    "clinics.clinicSessionInformation.clinicSessionInformation.hostContactInfo.title" : "Contact(s)",

    // Host instructor info
    "clinics.clinicSessionInformation.clinicSessionInformation.instructorContactInfo.title" : "Instructor(s)",

    // Sessions
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.title"           : "Session(s)",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.number"          : "Session #",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.format"          : "Format",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.language"        : "Language",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.location"        : "Location",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.source"          : "Source",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.url"             : "URL link",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.online.source"   : "Source",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.online.external" : "External link",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.online.huc"      : "Hockey University course",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.online.coach"    : "Coach.ca",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.online.respect"  : "Respect in Sport",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.notes"           : "Notes",

    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.ONSITE"      : "In Person",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.ONLINE_LIVE" : "Online - Live",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.ONLINE"      : "Online - Recorded/On-Demand",

    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.title" : "Schedule",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.text1" : "The session will be held on ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.text2" : " at ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.text3" : "and will be ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.text4" : " hour(s) and ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.text5" : " minute(s) long",

    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text1" : "The session will be available from ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text2" : " at ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text3" : " to ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text4" : " at ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text5" : "and will be ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text6" : " hour(s) and ",
    "clinics.clinicSessionInformation.clinicSessionInformation.sessions.schedule.VODs.text7" : " minute(s) long",
}