import Translate from '@spordle/intl-elements';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CollapsibleCard from '../../../../../components/collapsibleCard/CollapsibleCard';
import CustomAlert from '../../../../../components/CustomAlert';
import TeamCard from './TeamCard';

const AllTeams = ({ validTeams, invalidTeams, views, setView, toggle, action, getHeaderText, ...props }) => {

    return (
        <>
            <ModalHeader toggle={toggle}>
                {getHeaderText()}
            </ModalHeader>
            <ModalBody>
                <div className='mb-3'>
                    <Translate id='teams.teamSearch.sideTable.bulkUpdate.allTeams.topMessage' />
                </div>
                <CollapsibleCard
                    title={<Translate id='teams.teamSearch.sideTable.bulkUpdate.allTeams.validTitle' values={{ teams: validTeams.length }} />}
                    subtitle={<Translate id='teams.teamSearch.sideTable.bulkUpdate.allTeams.validText' />}
                >
                    <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }} className='px-3 mx-n3'>
                        {validTeams.length ?
                            validTeams.map((team) => (
                                <TeamCard team={team} withStatus key={team.team.team_id} />
                            ))
                            :
                            <Translate id='teams.teamSearch.sideTable.bulkUpdate.allTeams.validNone' />
                        }
                    </PerfectScrollbar>
                </CollapsibleCard>

                {!!invalidTeams.length &&
                    <CollapsibleCard
                        error
                        title={<Translate id='teams.teamSearch.sideTable.bulkUpdate.allTeams.invalidTitle' values={{ teams: invalidTeams.length }} />}
                        subtitle={<Translate id='teams.teamSearch.sideTable.bulkUpdate.allTeams.invalidText' />}
                        className='mt-3'
                    >
                        <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }} className='px-3 mx-n3'>
                            {invalidTeams.map((team) => (
                                <TeamCard team={team} withStatus key={team.team.team_id} />
                            ))}
                        </PerfectScrollbar>
                    </CollapsibleCard>
                }

                {!validTeams.length &&
                    <CustomAlert
                        color='danger'
                        className='mt-3 mb-0'
                        text='teams.teamSearch.sideTable.bulkUpdate.allTeams.actionNone'
                        translateText
                    />
                }
            </ModalBody>
            <ModalFooter>
                <Button color='primary' type='button' onClick={() => setView(views.validTeams)} disabled={!validTeams.length}><Translate id='misc.confirm' /></Button>
                <Button color='primary' type='button' outline onClick={toggle} className='ml-2'><Translate id='misc.cancel' /></Button>
            </ModalFooter>
        </>
    )
}

export default AllTeams