import { FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    FormGroup,
    Label
} from "reactstrap";
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import FormikEditable from '../../../../../../components/formik/FormikEditable';
import { fail, success } from '@spordle/toasts';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { RegistrationFeeContext } from '../../../../../../contexts/RegistrationFeeContext';
import { RolesContext } from '../../../../../../contexts/RolesContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import WaiverCard from '../../../../../members/profile/registration/components/Sidepanel/components/waivers/WaiverCard';
import { waiverNotSigned } from '../../../../../../helpers/waiversHelper';
import RegistrationFeesWaiverModal from './RegistrationFeesWaiverModal';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';

const RegistrationFeesSidepanelCatalog = ({
    readOnly,
    spordleTable,
    createNewValues,
    syncRows,
    fee,
    forms,
    waivers,
    installmentModes,
    toggleState,
    toggle,
    registrationFeeData,
    getRegistrationFee,
    ...props
}) => {
    const registrationFeeContext = useContext(RegistrationFeeContext);
    const orgContext = useContext(OrganizationContext)
    const i18n = useContext(I18nContext);
    const canDoAction = useContext(RolesContext).canDoAction('EDIT', 'catalog', 'registration_fees');

    const filteredFeeWaivers = fee?.waivers?.filter((waiver) => waiver.organisation.organisation_id === orgContext.organisation_id)

    const [ isOpen, setIsOpen ] = useState(false);
    const toggleModal = () => setIsOpen(!isOpen)
    const [ waiver, setWaiver ] = useState(null)

    return (
        <>

            <RegistrationFeesWaiverModal isOpen={isOpen} toggle={toggleModal} waiver={waiver} />

            <FormGroup>
                <Label for='custom_form_id' className='text-muted mb-0'><Translate id='catalog.registrationFees.sidePanel.form.customForms' /></Label>
                <FormikEditable
                    readOnly={readOnly}
                    noConfirmation
                    id='custom_form_id'
                    disabled={!canDoAction}
                    initialValues={{
                        custom_form_id: fee.custom_form?.custom_form_id || '',
                    }}
                    onSubmit={(values) => {
                        if(fee.custom_form?.custom_form_id !== values.custom_form_id){
                            toggleState(true)
                            registrationFeeContext.updateRegistrationFee(fee.registration_fee_id, values)
                                .then(() => {
                                    const newValues = createNewValues({ custom_form: forms.find((_form) => _form.custom_form_id === values.custom_form_id) });
                                    syncRows(newValues);
                                    success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                    toggleState(false)
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        toggleState(false)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                    }
                                })
                        }
                    }}
                >
                    {(isEditing, options) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'>{fee.custom_form?.name || '-'}</div>
                            )
                        }
                        return (
                            <FormikSelect
                                name='custom_form_id'
                                id='custom_form_id'
                                autoFocus
                                menuIsDefaultOpen
                                clearable
                                onOptionSelected={options.stopEditing}
                                isLoading={!forms}
                                renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                options={forms ?
                                    forms.map((form) => ({
                                        label: form.name,
                                        i18n: form.i18n,
                                        value: form.custom_form_id,
                                    }))
                                    :
                                    []
                                }
                            />
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <FormGroup>
                <Label for='installment_mode_id' className='text-muted mb-0'><Translate id='catalog.registrationFees.sidePanel.form.installments' /></Label>
                <FormikEditable
                    readOnly={readOnly}
                    noConfirmation
                    id='installment_mode_id'
                    disabled={!canDoAction}
                    initialValues={{
                        installment_mode_id: fee?.installment_mode?.installment_mode_id || '',
                    }}
                    onSubmit={(values) => {
                        if(fee?.installment_mode?.installment_mode_id !== values.installment_mode_id){
                            toggleState(true);
                            registrationFeeContext.updateRegistrationFee(fee.registration_fee_id, values)
                                .then(() => {
                                    const newValues = createNewValues({ installment_mode: installmentModes?.find((_mode) => _mode.installment_mode_id === values.installment_mode_id) });
                                    syncRows(newValues);
                                    success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        toggleState(false)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                    }
                                })
                                .finally(() => toggleState(false));
                        }
                    }}
                >
                    {(isEditing, options) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'>
                                    {fee?.installment_mode ?
                                        <span>
                                            {fee.installment_mode.name}
                                            <span className="ml-1 text-muted small">
                                                (<Translate id='catalog.registrationFees.add.form.installment.number' values={{ installmentNb: installmentModes?.find((_mode) => _mode.installment_mode_id === fee.installment_mode.installment_mode_id)?.installment_options?.length || 1 }} />)
                                            </span>
                                        </span>
                                        :
                                        '-'
                                    }
                                </div>
                            )
                        }
                        return (
                            <FormikSelect
                                name='installment_mode_id'
                                id='installment_mode_id'
                                autoFocus
                                menuIsDefaultOpen
                                clearable
                                onOptionSelected={options.stopEditing}
                                searchKeys={[
                                    `i18n.${i18n.getGenericLocale()}.name`,
                                ]}
                                renderOption={({ option: installment }) => (
                                    <>
                                        <DisplayI18n
                                            field="name"
                                            i18n={installment.i18n}
                                            defaultValue={installment.label}
                                        />
                                        <span className="d-block small text-muted">
                                            <Translate id='catalog.registrationFees.add.form.installment.number' values={{ installmentNb: installment.installment_options?.length }} />
                                        </span>
                                    </>
                                )}
                                isLoading={!installmentModes}
                                options={
                                    installmentModes ?
                                        installmentModes.map((mode) => ({
                                            label: mode.name,
                                            value: mode.installment_mode_id,
                                            i18n: mode.i18n,
                                            installment_options: mode.installment_options,
                                        }))
                                        :
                                        []
                                }
                            />
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <FormGroup>
                <Label for='waivers' className='text-muted mb-0'><Translate id='catalog.registrationFees.sidePanel.form.waivers' /></Label>
                <FormikEditable
                    readOnly={readOnly}
                    noConfirmation
                    id='waivers'
                    disabled={!canDoAction}
                    initialValues={{
                        waivers: filteredFeeWaivers?.map((waiver) => waiver.waiver_id) || [],
                    }}
                    onSubmit={(values) => {
                        if(values.waivers.toString() === fee?.waivers?.map((waiver) => waiver.waiver_id).toString()){
                            return;// Don't update if the waivers didn't change
                        }

                        toggleState(true)
                        const apiValues = [ ...values.waivers ]

                        const _myWaivers = []
                        values.waivers.forEach((waiver_id) => {
                            const foundWaiver = waivers?.filter((waiver) => waiver.waiver_id === waiver_id)
                            if(Array.isArray(foundWaiver) && foundWaiver.length > 0)
                                _myWaivers.push(foundWaiver[0])
                        });
                        const newValues = createNewValues({
                            waivers: _myWaivers,
                        });

                        registrationFeeContext.updateRegistrationFeeWaiver(fee.registration_fee_id, apiValues)
                            .then(async() => {
                                await getRegistrationFee()
                                //spordleTable.refreshTable();
                                syncRows(newValues);
                                //toggle();
                                success({ msg: 'catalog.registrationFees.sidePanel.form.updated' });
                                toggleState(false)
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error);
                                    toggleState(false)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            })
                    }}
                >
                    {(isEditing, _, formik) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'><Translate id='catalog.registrationFees.sidePanel.form.waiversSelected' values={{ count: filteredFeeWaivers.length || 0 }} /></div>
                            )
                        }
                        return (
                            <FormikSelect
                                name='waivers'
                                id='waivers'
                                clearable
                                multi
                                autoFocus
                                menuIsDefaultOpen
                                renderOption={({ option }) => (
                                    <>
                                        <div>
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={option.label}
                                                i18n={option.i18n}
                                            />
                                        </div>
                                        {option.critical == 1 &&
                                            <div className='text-muted small'><Translate id='misc.critical' /></div>
                                        }
                                    </>
                                )}
                                searchKeys={[
                                    `i18n.${i18n.getGenericLocale()}.name`,
                                ]}
                                isLoading={!waivers}
                                options={waivers ? waivers.map((waiver) => ({
                                    label: waiver.name,
                                    i18n: waiver.i18n,
                                    value: waiver.waiver_id,
                                    critical: waiver.critical,
                                })) : []}
                            />
                        )

                    }}
                </FormikEditable>
            </FormGroup>

            {registrationFeeData?.waivers?.map((waiver) => {
                const formattedWaiver = { ...waiver, waiver: waiver, invoiceItem: { organisation: waiver.organisation } }
                return (
                    <WaiverCard
                        key={waiver.waiver_id}
                        hideIcon
                        waiver={formattedWaiver}
                        answer={waiverNotSigned}
                        openModal={() => {
                            setWaiver(formattedWaiver)
                            setIsOpen(true)
                        }}
                    />
                )
            })}
        </>
    );
}

export default RegistrationFeesSidepanelCatalog;