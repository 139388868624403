import { FormikCheckedButton, FormikInputText } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { Alert, Button, Collapse, FormGroup, Label, ModalBody, ModalFooter } from "reactstrap";
import FormikTiny from "../../../../formik/FormikTiny";
import Required from "../../../../formik/Required";
import ReactDOMServer from 'react-dom/server';
import { SendCommModalContext } from "../../../context/SendCommModalContext";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { bool, object, string } from "yup";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { MODE_MEMBER, MODE_ORG } from "../../../sendCommHelper";
import { stringBuilder } from "@spordle/helpers";

/**
 * @param {Object} props
 * @param {number} props.stepNb
 * @returns {React.ReactElement}
 */
const FormView = ({ stepNb }) => {
    const modalContext = useContext(SendCommModalContext);
    // https://reactjs.org/docs/react-dom-server.html
    const initialMessage = modalContext.initValues?.msg ? ReactDOMServer.renderToStaticMarkup(modalContext.initValues.msg) : "";
    const { sendCommunication } = useContext(OrganizationContext);
    const isDisabled = modalContext.isLoading || stepNb !== modalContext.step;

    return (
        <Formik
            initialStatus={{
                isVisible: false,
                msg: null,
            }}
            initialValues={{
                subject: modalContext.initValues?.subject || "",
                message: initialMessage,
                confirm: false,
            }}
            validationSchema={object().shape({
                subject: string().required(<Translate id='components.communications.modal.validation.subject' />),
                message: string().required(<Translate id='components.communications.modal.validation.message' />),
                confirm: bool().required(<Translate id="components.communications.modal.warning.validation" />).oneOf([ true ], <Translate id="components.communications.modal.warning.validation" />),
            })}
            onSubmit={(values, { setStatus }) => {
                modalContext.setIsLoading(true);

                const apiValues = { subject: values.subject, message: values.message };

                if(modalContext.mode === MODE_MEMBER){
                    apiValues.members = modalContext.recipients.valid;
                }else if(modalContext.mode === MODE_ORG){
                    const splittedRecipients = modalContext.recipients.valid.reduce((splitted, rec) => {
                        // prioritize email sending
                        if(rec.email){
                            splitted.emails.push(rec.email);
                        }else if(rec.identity_id){
                            splitted.identities.push(rec.identity_id);
                        }else if(rec.member_id){
                            splitted.members.push(rec);
                        }else if(rec.organisation_id){
                            splitted.organisations.push(rec.organisation_id);
                        }
                        return splitted;
                    }, { organisations: [], identities: [], members: [], emails: [] });

                    apiValues.organisations = splittedRecipients.organisations;
                    apiValues.identities = splittedRecipients.identities;
                    apiValues.members = splittedRecipients.members;
                    apiValues.emails = splittedRecipients.emails;
                }

                return sendCommunication(null, apiValues)
                    .then((feedback) => {
                        const formattedWarnings = feedback.warnings.reduce((formatted, warning) => {
                            const origin = warning.origin.split(',');
                            const email = (origin[1] || "").replace(']', "");

                            formatted[email] = warning;

                            return formatted;
                        }, {});
                        const fails = feedback.email_fail_send_to.map((email) => ({
                            email: email,
                            warning: formattedWarnings[email],
                        }));

                        modalContext.setConfirmInfo({
                            failed: fails,
                            success: feedback.email_sent_to || [],
                        });
                        modalContext.setIsLoading(false);
                        modalContext.next();
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            setStatus({
                                msg: <DisplayI18n field="message" defaultValue={e.message} i18n={e.i18n} />,
                                isVisible: true,
                            });
                            modalContext.setIsLoading(false);
                        }
                    });
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <FormGroup>
                            <Label className="text-muted" for="subject">
                                <Translate id='components.communications.modal.label.subject' /> <Required />
                            </Label>
                            <FormikInputText name="subject" id="subject" />
                        </FormGroup>
                        <FormGroup>
                            <Label className="text-muted" for="message">
                                <Translate id='components.communications.modal.label.message' /> <Required />
                            </Label>
                            <FormikTiny
                                name="message"
                                id="message"
                            />
                        </FormGroup>
                        <div className={stringBuilder("small transition bg-light-inverse border rounded-lg p-3 d-block", { "border-danger": formik.errors.confirm && formik.touched.confirm })}>
                            <p className="mb-2">
                                <Translate id="components.communications.modal.warning.text" />
                            </p>
                            <div className="d-flex align-items-center">
                                <FormikCheckedButton
                                    name="confirm"
                                    translateLabel={false}
                                />
                                <Label for="confirm" className="mb-0 font-medium line-height-0">
                                    <Translate id="components.communications.modal.warning.label" /> <Required />
                                </Label>
                            </div>
                            {formik.errors.confirm && formik.touched.confirm && <div className="text-danger d-block">{formik.errors.confirm}</div>}
                        </div>
                        <Collapse isOpen={formik.status.isVisible && formik.status.msg}>
                            <div className="pt-3">
                                <Alert toggle={() => formik.setStatus({ ...formik.status, isVisible: false })} className="mb-0" color="danger">
                                    {formik.status.msg}
                                </Alert>
                            </div>
                        </Collapse>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={formik.isSubmitting || isDisabled} onClick={modalContext.previous} outline color="primary" type="button" className="mr-auto"><Translate id="misc.previous" /></Button>
                        <Button disabled={formik.isSubmitting || isDisabled} color="primary" type="submit"><Translate id="misc.confirm" /></Button>
                        <Button disabled={formik.isSubmitting || isDisabled} color="primary" type="button" outline onClick={modalContext.toggle}><Translate id="misc.cancel" /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default FormView;