export default{
    "members.profile.qualifications.title" : "Qualifications",

    // general
    "members.profile.qualifications.status.certified" : "Certifié",
    "members.profile.qualifications.status.valid"     : "Valide",
    "members.profile.qualifications.status.expired"   : "Expiré",
    "members.profile.qualifications.status.attendee"  : "Participant(e)",

    "members.profile.qualifications.season"              : "Saison",
    "members.profile.qualifications.category"            : "Catégorie",
    "members.profile.qualifications.qualification"       : "Qualification",
    "members.profile.qualifications.level"               : "Niveau",
    "members.profile.qualifications.validationDate"      : "Date de validation",
    "members.profile.qualifications.certifiedDate"       : "Date de certification",
    "members.profile.qualifications.expirationDate"      : "Date d'expiration / de renouvellement",
    "members.profile.qualifications.certificationNumber" : "Numéro de certification",
    "members.profile.qualifications.location"            : "Emplacement",
    "members.profile.qualifications.city"                : "Ville",
    "members.profile.qualifications.clinic"              : "Stage",
    "members.profile.qualifications.memo"                : "Memo",
    "members.profile.qualifications.deleteMessage"       : "Êtes-vous sûr de vouloir supprimer cette qualification?",

    "members.profile.qualifications.validationDate.required"       : "La date de validation est requise",
    "members.profile.qualifications.certifiedDate.required"        : "La date de certification est requise",
    "members.profile.qualifications.validationDate.expirationDate" : "La date de validation doit être avant la date d'expiration",
    "members.profile.qualifications.certifiedDate.expirationDate"  : "La date de certification doit être avant la date d'expiration",

    "members.profile.qualifications.expirationDate.validationDate" : "La date d'expiration / de renouvellement doit être après la date de validation",

    // table
    "members.profile.qualifications.table.status" : "Statut",

    // add modal
    "members.profile.qualifications.addModal.title"                  : "Ajouter une qualification",
    "members.profile.qualifications.addModal.category.required"      : "La catégorie est requise",
    "members.profile.qualifications.addModal.qualification.required" : "La qualification est requise",
    "members.profile.qualifications.addModal.season.required"        : "La saison est requise",

    // sidepanel
    "members.profile.qualifications.sidePanel.actions.removeCertification"      : "Supprimer la certification",
    "members.profile.qualifications.sidePanel.actions.removeCertification.text" : "Vous êtes sur le point de supprimer la certification.",
    "members.profile.qualifications.sidePanel.actions.certify"                  : "Certifier",
    "members.profile.qualifications.sidePanel.actions.trasnferShare"            : "Transferer / Partager",
    "members.profile.qualifications.sidePanel.registeredAs"                     : "Inscrit(e) en tant que participant(e)",
    "contexts.qualifications.3191"                                              : "Permissions insuffisantes",
    "contexts.qualifications.1000"                                              : "Paramètres manquants",
    "contexts.qualifications.3179"                                              : "Accès interdit",
    "contexts.qualifications.999"                                               : "Erreur interne",

    // Transfer / Share modal
    "members.profile.qualifications.sidePanel.trasnferShareModal.title"                    : "Transférer / partager la qualification",
    "members.profile.qualifications.sidePanel.trasnferShareModal.choice.transfer.title"    : "Transfert",
    "members.profile.qualifications.sidePanel.trasnferShareModal.choice.transfer.subtitle" : "Le transfert déplacera la qualification au nouveau membre",
    "members.profile.qualifications.sidePanel.trasnferShareModal.choice.share.title"       : "Partager",
    "members.profile.qualifications.sidePanel.trasnferShareModal.choice.share.subtitle"    : "La partage dupliquera la qualification pour le nouveau membre",
    "members.profile.qualifications.sidePanel.trasnferShareModal.choice.required"          : "Sélectionnez une option",
    "members.profile.qualifications.sidePanel.trasnferShareModal.confirm"                  : "La qualification a été {mode, select, TRANSFER {transféré} SHARE {partagé} other {mis à jour}} avec succès",

    // Certify modal
    "members.profile.qualifications.sidePanel.certifyModal.title"                                : "Certifier la qualification",
    "members.profile.qualifications.sidePanel.certifyModal.certification.label"                  : "Date de certification",
    "members.profile.qualifications.sidePanel.certifyModal.certification.validation.beforeToday" : "La date de certification doit être avant aujourd'hui",
}