import API_SPORDLE from "../API-Spordle";
import queryString from "query-string";
import { jsObjectToApi } from "@spordle/helpers";
import { serverError } from "../CancellableAPI";

/**
 * Delete an international transfer
 * @param {string} memberId Member ID to create a meta member for
 * @param {string} memberTransferId The ID of the member transfer
 * @returns {Promise<string>}
 */
export function deleteMemberTransfer(memberId, memberTransferId){
    return API_SPORDLE.delete(`/members/${memberId}/transfers/${memberTransferId}`)
        .then((response) => {
            if(response.data.status){
                return true;
            }
            throw response.data.errors[0];
        }, serverError)
}