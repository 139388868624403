import { displayI18n } from "../../../../../helpers/i18nHelper";

function displayOrgI18n(org, locale){
    return displayI18n("name", org?.i18n, org?.organisation_name, locale) || ""
}

export function sortRessource(a, b, orgId, locale){
    const aOrg = a.organisation;
    const bOrg = b.organisation;

    if(aOrg.organisation_id !== bOrg.organisation_id){ // This makes sure to put the current org first, then orders alphabetically organisations
        if(aOrg?.organisation_id === orgId){
            return -1;
        }else if(bOrg.organisation_id === orgId){
            return 1;
        }

        const aName = displayOrgI18n(aOrg, locale);
        const bName = displayOrgI18n(bOrg, locale);
        const orgOrder = aName.localeCompare(bName);

        return orgOrder;
    }

    return 0;
}