import onlineStoreGeneralInformation from "./onlineStoreGeneralInformation";
import onlineStoreItemsFees from "./onlineStoreItemsFees";
import onlineStorePaymentOptions from "./onlineStorePaymentOptions";
import onlineStoreRebatesTerms from "./onlineStoreRebatesTerms";
import onlineStoreReview from "./onlineStoreReview";

export default{
    ...onlineStoreGeneralInformation,
    ...onlineStoreItemsFees,
    ...onlineStoreRebatesTerms,
    ...onlineStoreReview,
    ...onlineStorePaymentOptions,
}