import DisplayMemberTypeIcon from "./DisplayMemberTypeIcon";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { useContext } from "react";
import { DisplayI18n } from "../../helpers/i18nHelper";
import Translate from "@spordle/intl-elements";
import { Tooltip } from "@mantine/core";

const DisplayMemberType = ({ member, memberType, hideTooltip, ...props }) => {
    const { settings } = useContext(OrganizationContext);
    const canShowStatus = settings.manage_member_type_approbation?.value == 1;
    const tooltipIsDisabled = !canShowStatus || hideTooltip || !memberType.member_type_status;

    if(!memberType){
        return null;
    }

    return (
        <Tooltip
            disabled={tooltipIsDisabled}
            zIndex={1040}
            label={
                <>
                    {(!tooltipIsDisabled) &&
                        <div className="text-wrap" style={{ maxWidth: 350 }}>
                            <b><Translate id="misc.status" /></b>: <DisplayI18n field="name" i18n={memberType.member_type_status?.i18n} defaultValue={memberType.member_type_status?.name} />
                            {memberType.decision_made_by &&
                                <>
                                    <div>
                                        <b><Translate id="members.profile.memberType.status.decidedBy" /></b>: {memberType.decision_made_by.name} {memberType.decision_made_by.family_name}
                                    </div>
                                    {memberType.decision_note &&
                                        <div>
                                            <b><Translate id="form.fields.note" /></b>: {memberType.decision_note}
                                        </div>
                                    }
                                </>

                            }
                        </div>
                    }
                </>
            }
        >
            <div {...props}>
                <DisplayMemberTypeIcon memberType={memberType} />
                <DisplayI18n field='name' defaultValue={memberType.member_type.name} i18n={memberType.member_type.i18n} />
            </div>
        </Tooltip>
    );
}

export default DisplayMemberType;