import { FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Alert, Button, Collapse } from "reactstrap";
import { object, string } from "yup";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import OverlayLoader from "../../../../../loading/OverlayLoader";
import UserDisplay from "../../../../../userDisplay/UserDisplay";
import DisplayRecipient from "../../../displayRecipient/DisplayRecipient";

const InvalidRecipient = ({ recipient, validateInvalid }) => {
    const { getMemberContacts, updateMemberPartial, getMemberAccounts } = useContext(MembersContext);
    const [ isDisabled, setIsDisabled ] = useState(false);
    const inputName = `${recipient.member_id}-email`;
    const SPLITTER = "----";

    const handleLoadData = (from) => {
        switch (from){
            case 'CDM':
                return Promise.all([
                    getMemberContacts(recipient.member_id),
                    getMemberAccounts(recipient.member_id),
                ])
                    .then(([ contacts, identities ]) => {
                        setIsDisabled((contacts.length + identities.length) <= 0);

                        const formattedIdentities = identities.map((identity) => ({
                            label: `${identity.name} ${identity.family_name}`,
                            value: `${identity.email}${SPLITTER}${identity.identity_id}`,
                            key: identity.identity_id,
                            email: identity.email || "NONE",
                        }));

                        const formattedList = contacts
                            .sort((contact) => contact.email ? -1 : 0)
                            .reduce((list, contact) => {
                                list.push({
                                    key: contact.member_contact_id,
                                    label: contact.member.first_name + ' ' + contact.member.last_name,
                                    email: contact.email || "NONE",
                                    value: `${contact.email}${SPLITTER}${contact.member_contact_id}`,
                                });

                                return list;
                            }, formattedIdentities || []);

                        return formattedList;
                    })
            default:
                break;
        }
    }

    return (
        <Formik
            initialStatus={{
                isVisible: false,
                msg: null,
            }}
            initialValues={{
                [inputName]: "",
            }}
            validationSchema={object().shape({
                [inputName]: string().required(),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                const newEmail = values[inputName].split(SPLITTER)[0];

                updateMemberPartial(recipient.member_id, { email: newEmail })
                    .then(() => {
                        validateInvalid({ ...recipient, email: newEmail });
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            setSubmitting(false);
                            setStatus({
                                isVisible: true,
                                msg: <DisplayI18n field="message" defaultValue={e.message} i18n={e.i18n} />,
                            });
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <DisplayRecipient recipient={recipient} className="mb-2 flex-wrap">
                            <UserDisplay.Container className="w-100 border-top pt-2 mt-2">
                                <div className="d-flex">
                                    <div className="w-100">
                                        <FormikSelect
                                            id={inputName}
                                            name={inputName}
                                            placeholder={isDisabled ? "components.communications.modal.contact.empty" : "components.communications.modal.contact.choose"}
                                            renderOption={({ option }) => (
                                                <>
                                                    <small>{option.label}</small>
                                                    <div>{option.email === "NONE" ? <Translate id="components.communications.modal.email.none" /> : option.email}</div>
                                                </>
                                            )}
                                            disabled={isDisabled}
                                            renderSelectedOption={(option) => <>{option.email} ({option.label})</>}
                                            isOptionDisabled={(option) => option.email === "NONE"}
                                            loadData={handleLoadData}
                                        />
                                    </div>
                                    <Button
                                        disabled={isDisabled || formik.isSubmitting}
                                        className="ml-2"
                                        color="primary"
                                        type="submit"
                                    >
                                        <Translate id="components.communications.modal.contact.apply" />
                                    </Button>
                                </div>
                                <div className="text-muted small mt-1">
                                    <Translate id="components.communications.modal.contact.helper" />
                                </div>
                                <Collapse isOpen={formik.status.isVisible}>
                                    <Alert className="p-2 mt-2 d-flex mb-0" color="danger">
                                        <span className="mr-auto"><i className="mdi mdi-alert-outline mr-1" />{formik.status.msg}</span> <i className="mdi mdi-close ml-2" onClick={() => formik.setStatus({ ...formik.status, isVisible: false })} />
                                    </Alert>
                                </Collapse>
                            </UserDisplay.Container>
                        </DisplayRecipient>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default InvalidRecipient;