export default{
    "tasks.memberTypeRemoval.title" : "Member Type Removal",

    "tasks.memberTypeRemoval.columns.member"            : "Member",
    "tasks.memberTypeRemoval.columns.memberType"        : "Member type(s)",
    "tasks.memberTypeRemoval.columns.currentMemberType" : "Current Member type(s)",
    "tasks.memberTypeRemoval.columns.createdBy"         : "Created by",
    "tasks.memberTypeRemoval.columns.createdAt"         : "Date",
    "tasks.memberTypeRemoval.columns.status"            : "Request status",

    "tasks.memberTypeRemoval.sidepanel.manage" : "Manage Removal",
    "tasks.memberTypeRemoval.sidepanel.count"  : "{count, plural, one {# Selected Member} other {# Selected Members}}",
    "tasks.memberTypeRemoval.sidepanel.none"   : "No member types",

    "tasks.memberTypeRemoval.modal.title"         : "Manage Member Type Removal",
    "tasks.memberTypeRemoval.modal.remove"        : "Remove",
    "tasks.memberTypeRemoval.modal.removeConfirm" : "Confirm Removal",
    "tasks.memberTypeRemoval.modal.keep"          : "Keep",
    "tasks.memberTypeRemoval.modal.keepConfirm"   : "Confirm",
}