import Translate from "@spordle/intl-elements";
import { Link } from "react-router-dom";
import { Button, ModalBody, ModalFooter } from "reactstrap";

const SubmitTicketSuccess = ({ back, toggle, issueId }) => (
    <>
        <ModalBody>
            <div className="h3 font-bold"><Translate id='helpCenter.submitTicket.success.title' /></div>
            <Link to={'/supportRequest/' + issueId} onClick={toggle} className="mb-2 d-block"><Translate id='helpCenter.submitTicket.success.ticket' /> #{issueId}</Link>
            <p><Translate id='helpCenter.submitTicket.success.text' /></p>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" type="button" onClick={back}><Translate id='helpCenter.submitTicket.success.btn' /></Button>
            <Button type="button" outline color="primary" onClick={toggle}><Translate id='misc.close' /></Button>
        </ModalFooter>
    </>
)

export default SubmitTicketSuccess;