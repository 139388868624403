import Translate from "@spordle/intl-elements";
import React, { createRef } from "react";
import { Fade } from 'reactstrap';
import { I18nContext } from '../../contexts/I18nContext';
import { MembersContext } from '../../contexts/MembersContext';
import { TeamsContext } from '../../contexts/TeamsContext';
import { ClinicsContext } from '../../contexts/ClinicsContext';
import { OnlineStoreContext } from '../../contexts/OnlineStoreContext';
import withContexts from "../../helpers/withContexts";
import { Responsive, WidthProvider } from "react-grid-layout";
import ResizeObserver from 'resize-observer-polyfill';
import { debounce } from "@spordle/helpers";
import { isMobileDevice } from "../../helpers/constants";
import DashboardWidget from "./DashboardWidget";
import DashboardAddModal from "./components/addModal/DashboardAddModal";
import dashboardData from "./dashboardData";
import { getLocalStorageItem, setLocalStorageItem } from "../../helpers/browserStorage";
import { breakpoints, initPositioning, typeDimensions } from "./dashboardConstants";
import HoverableComponent from "../../components/HoverableComponent";
import { RefreshButton } from "../../components/viewHeader/ViewHeaderV2";
import { DashboardContext } from "../../contexts/DashboardContext";
import WelcomeCard from "./widgets/custom/welcomeCard/WelcomeCard";
import NotificationsWidget from "./widgets/custom/notificationsWidget/NotificationsWidget";
import RecentsWidget from "./widgets/custom/recentsWidget/RecentsWidget";

const ResponsiveGridLayout = WidthProvider(Responsive);

class Dashboard extends React.Component{

    constructor(props){
        super(props)

        this.toggleFullGuide = this.toggleFullGuide.bind(this)
        this.gridColumns = { xxs: 1, xs: 2, sm: 4, md: 4, lg: 4 };

        this.reactGridRef = createRef();

        this.responsiveGridLayoutRef = createRef();

        this.reziseObserver = new ResizeObserver(this.deboucedForceUpdate);

        /* lg-md
        (0, 0), (1, 0), (2, 0), (3, 0)
        (0, 1), (1, 1), (2, 1), (3, 1)
        (0, 2), (1, 2), (2, 2), (3, 2)
        */
        /* sm
        (0, 0), (1, 0), (2, 0)
        (0, 1), (1, 1), (2, 1)
        (0, 2), (1, 2), (2, 2)
        */
        /* xs
        (0, 0), (1, 0)
        (0, 1), (1, 1)
        (0, 2), (1, 2)
        */
        /* xxs
        (0, 0)
        (0, 1)
        (0, 2)
        */

        this.state = {
            addModalIsOpen: false,

            fullGuide: false,
            // eslint-disable-next-line react/no-unused-state
            isLoading: true,
            isEditing: false,


            layouts: this.formatLayouts(this.initWidgets()),
            dashboardWidgets: this.initWidgets(),

            currentBreakpoint: '',
        }
    }

    deboucedForceUpdate = debounce(() => { window.dispatchEvent(new Event('resize')) }, 200)

    toggleFullGuide = () => {
        this.setState((prevState) => ({ fullGuide: !prevState.fullGuide }));
    }

    toggleAddModal = () => {
        this.setState((prev) => ({
            addModalIsOpen: !prev.addModalIsOpen,
        }))
    }

    // loadQuickCards = () => {
    //     Promise.all([
    //         this.props.MembersContext.getMembers(),
    //         this.props.TeamsContext.getTeams(),
    //         this.props.OnlineStoreContext.getOnlineStores(),
    //         this.props.ClinicsContext.getClinics({
    //             only_hosted_by_my_organisations: 1,
    //         }),
    //     ])
    //         .then((promises) => {
    //             counterAnim('membersCount', 0, promises[0].length); // Quick cards counter animation
    //             counterAnim('teamsCount', 0, promises[1].length); // Quick cards counter animation
    //             counterAnim('onlineStoresCount', 0, promises[2].length); // Quick cards counter animation
    //             counterAnim('clinicsCount', 0, promises[3].length); // Quick cards counter animation
    //         })
    //         .catch((error) => { console.error(error.message) })
    //         .finally(() => { this.setState(() => ({ isLoading: false })) });
    // }

    componentDidMount(){
        // Quick Cards
        this.reziseObserver.observe(this.reactGridRef.current)
        if(this.responsiveGridLayoutRef.current){
            this.setState(() => ({
                // yup, that complicated to get the fucking breakpoint
                // https://github.com/react-grid-layout/react-grid-layout/issues/186#issuecomment-731131909
                currentBreakpoint: Responsive.utils.getBreakpointFromWidth(breakpoints, this.responsiveGridLayoutRef.current?.state.width),
            }))
        }
    }

    componentWillUnmount(){
        this.reziseObserver.disconnect();
    }

    initWidgets = () => {
        const storageWidgets = JSON.parse(getLocalStorageItem('dashboardWidgets') || 'null');

        // if(this.props.DashboardContext.state.addWidgetFeature){
        //     return storageWidgets?.map((sWidget) => {
        //         const fullWidget = dashboardData.availableWidgets.find((w) => w.widgetId === sWidget.widgetId);

        //         return {
        //             ...fullWidget,
        //             ...sWidget, // override default positioning that way
        //         }
        //     }) || []
        // }

        // take all the default information for the widgets but take the stored positioning
        return dashboardData.dashboardWidgets.map((dWidget) => {
            const storageWidget = storageWidgets?.find((w) => w.widgetId === dWidget.widgetId);

            if(storageWidget){
                dWidget.positioning = storageWidget.positioning
            }
            return {
                ...dWidget,
            }
        })
    }

    formatLayouts = (widgets, layouts) => {
        return widgets.reduce((layouts, widget, index) => {
            layouts.lg.push({ i: widget.widgetId, ...widget.positioning.lg, ...typeDimensions[widget.type].lg })
            layouts.md.push({ i: widget.widgetId, ...widget.positioning.md, ...typeDimensions[widget.type].md })
            layouts.sm.push({ i: widget.widgetId, ...widget.positioning.sm, ...typeDimensions[widget.type].sm })
            layouts.xs.push({ i: widget.widgetId, ...widget.positioning.xs, ...typeDimensions[widget.type].xs })
            layouts.xxs.push({ i: widget.widgetId, ...widget.positioning.xxs, ...typeDimensions[widget.type].xxs })

            if(index === 0){
                layouts.lg.push({ i: 'welcome', y: 0, x: 0, isDraggable: false, isResizable: false, ...typeDimensions.medium.lg });
                layouts.md.push({ i: 'welcome', y: 0, x: 0, isDraggable: false, isResizable: false, ...typeDimensions.medium.lg });
                layouts.sm.push({ i: 'welcome', y: 0, x: 0, isDraggable: false, isResizable: false, ...typeDimensions.medium.md });
                layouts.xs.push({ i: 'welcome', y: 0, x: 0, isDraggable: false, isResizable: false, ...typeDimensions.medium.md });
                layouts.xxs.push({ i: 'welcome', y: 0, x: 0, isDraggable: false, isResizable: false, ...typeDimensions.medium.xxs, h: 3 });

                layouts.lg.push({ i: 'recently-viewed', y: 0, x: 2, isResizable: false, ...typeDimensions.medium.lg });
                layouts.md.push({ i: 'recently-viewed', y: 0, x: 2, isResizable: false, ...typeDimensions.medium.lg });
                layouts.sm.push({ i: 'recently-viewed', y: 0, x: 2, isResizable: false, ...typeDimensions.medium.md });
                layouts.xs.push({ i: 'recently-viewed', y: 0, x: 0, isResizable: false, ...typeDimensions.medium.md });
                layouts.xxs.push({ i: 'recently-viewed', y: 0, x: 0, isResizable: false, ...typeDimensions.medium.xxs, h: 3 });

                layouts.lg.push({ i: 'notifications', y: 0, x: 2, isResizable: false, ...typeDimensions.tall.lg });
                layouts.md.push({ i: 'notifications', y: 0, x: 2, isResizable: false, ...typeDimensions.tall.lg });
                layouts.sm.push({ i: 'notifications', y: 0, x: 2, isResizable: false, ...typeDimensions.tall.md });
                layouts.xs.push({ i: 'notifications', y: 0, x: 0, isResizable: false, ...typeDimensions.tall.xs });
                layouts.xxs.push({ i: 'notifications', y: 0, x: 0, isResizable: false, ...typeDimensions.tall.xxs });
            }

            if(widgets.length - 1 === index && this.props.DashboardContext.state.addWidgetFeature){ // On the last item, we add the Add button
                layouts.lg.push({ i: 'add', w: this.gridColumns.lg, h: 1, y: Infinity, x: 0, isDraggable: false, isResizable: false })
                layouts.md.push({ i: 'add', w: this.gridColumns.md, h: 1, y: Infinity, x: 0, isDraggable: false, isResizable: false })
                layouts.sm.push({ i: 'add', w: this.gridColumns.sm, h: 1, y: Infinity, x: 0, isDraggable: false, isResizable: false })
                layouts.xs.push({ i: 'add', w: this.gridColumns.xs, h: 1, y: Infinity, x: 0, isDraggable: false, isResizable: false })
                layouts.xxs.push({ i: 'add', w: this.gridColumns.xxs, h: 1, y: Infinity, x: 0, isDraggable: false, isResizable: false })
            }

            return layouts;
        }, {
            xxs: [ ...layouts?.xxs.filter(this.filterOutAdd) || [] ],
            xs: [ ...layouts?.xs.filter(this.filterOutAdd) || [] ],
            sm: [ ...layouts?.sm.filter(this.filterOutAdd) || [] ],
            md: [ ...layouts?.md.filter(this.filterOutAdd) || [] ],
            lg: [ ...layouts?.lg.filter(this.filterOutAdd) || [] ],
        })
    }

    filterOutAdd = (widget) => {
        return widget.i !== 'add'
    }

    handleLayoutChange = (layout, layouts) => {
        this.updateState(this.formatLayouts(this.state.dashboardWidgets, layouts), this.state.dashboardWidgets)
    }

    updateState = (layouts, widgets) => {
        if(this.state.currentBreakpoint){
            const layout = layouts[this.state.currentBreakpoint]
            const newWidgets = widgets.map((widget) => {
                const layoutWidget = layout.find((w) => w.i === widget.widgetId);
                return {
                    ...widget,
                    positioning: {
                        ...widget.positioning,
                        [this.state.currentBreakpoint]: {
                            x: layoutWidget.x,
                            y: layoutWidget.y,
                        },
                    },
                }
            })
            this.setState(() => ({
                dashboardWidgets: newWidgets,
                layouts: layouts,
            }))

            // just store the widgetId and the positioning, everything else doesn't need to be in local storage
            setLocalStorageItem('dashboardWidgets', JSON.stringify(newWidgets.map((w) => ({ widgetId: w.widgetId, positioning: w.positioning }))))
        }
    }

    handleOnBreakpointChange = (breakpoint) => {
        this.setState(() => ({
            currentBreakpoint: breakpoint,
        }))
    }

    removeWidget = (widgetId) => {
        const newLayouts = {
            lg: this.state.layouts.lg.filter((w) => w.i !== widgetId),
            md: this.state.layouts.md.filter((w) => w.i !== widgetId),
            sm: this.state.layouts.sm.filter((w) => w.i !== widgetId),
            xs: this.state.layouts.xs.filter((w) => w.i !== widgetId),
            xxs: this.state.layouts.xxs.filter((w) => w.i !== widgetId),
        }
        const newWidgets = this.state.dashboardWidgets.filter((w) => w.widgetId !== widgetId)

        this.updateState(newLayouts, newWidgets)
    }

    render(){
        return (
            <>
                <DashboardAddModal
                    isOpen={this.state.addModalIsOpen}
                    toggle={this.toggleAddModal}
                    dashboardWidgets={this.state.dashboardWidgets}
                    addWidget={(widget) => {
                        const newWidget = {
                            ...widget,
                            positioning: initPositioning,
                        }
                        this.updateState(this.formatLayouts([ newWidget ], this.state.layouts), [
                            ...this.state.dashboardWidgets,
                            newWidget,
                        ])
                    }}
                />

                <div className="page-content container-fluid p-4" ref={this.reactGridRef}>
                    <HoverableComponent>
                        {(isHovering) => (
                            <div className="mb-3 d-flex align-items-center">
                                <h2 className="font-medium mb-0 mr-1"><Translate id='sidebar.dashboard' /></h2>
                                {isMobileDevice ?
                                    <>
                                        {/* // <Button
                                        //     size="sm"
                                        //     color={this.state.isEditing ? "primary" : "link"}
                                        //     className={stringBuilder('ml-auto text-nowrap', { "text-primary": !this.state.isEditing })}
                                        //     onClick={() => {
                                        //         this.setState((prevState) => ({ isEditing: !prevState.isEditing }))
                                        //     }}
                                        // >
                                        //     {this.state.isEditing ?
                                        //         <>
                                        //             <Translate id='misc.save' />
                                        //             <i className="ml-1 mdi mdi-check" />
                                        //         </>
                                        //         :
                                        //         <>
                                        //             <Translate id="dashboard.btn.changeLayout" /><i className="ml-1 mdi mdi-cursor-move" />
                                        //         </>
                                        //     }
                                        // </Button> */}
                                    </>
                                    :
                                    <Fade in={isHovering}>
                                        <RefreshButton buttonClassName='font-20' />
                                    </Fade>
                                }
                            </div>
                        )}
                    </HoverableComponent>

                    <ResponsiveGridLayout
                        ref={this.responsiveGridLayoutRef}
                        breakpoints={breakpoints}
                        layouts={this.state.layouts}
                        cols={this.gridColumns}
                        isResizable={false}
                        isDraggable={isMobileDevice ? this.state.isEditing : true}
                        containerPadding={[ 0, 0 ]}
                        draggableHandle={isMobileDevice ? '.dashboard-grid-overlay-mobile' : '.dashboard-grid-drag-handle'}
                        useCSSTransforms
                        autoSize
                        onLayoutChange={this.handleLayoutChange}
                        onBreakpointChange={this.handleOnBreakpointChange}
                    >
                        <div key="welcome">
                            <WelcomeCard />
                        </div>
                        <div key="recently-viewed">
                            <RecentsWidget />
                        </div>
                        <div key="notifications">
                            <NotificationsWidget />
                        </div>
                        {this.state.dashboardWidgets?.map((widget, index) => (
                            <div key={widget.widgetId}>
                                <DashboardWidget widget={widget} index={index} isEditing={this.state.isEditing} removeWidget={this.removeWidget} />
                            </div>
                        ))}
                        {this.props.DashboardContext.state.addWidgetFeature &&
                            <div key='add' onClick={this.toggleAddModal}>
                                <div className='rounded-lg dashboard-grid-add p-3 d-flex flex-center h3 mb-0 userDisplay-card userDisplay-hover bg-transparent h-100'>
                                    <i className="mdi mdi-plus mr-1" /><Translate id='misc.add' />
                                </div>
                            </div>
                        }
                    </ResponsiveGridLayout>
                </div>
            </>
        );
    }
}

export default withContexts(I18nContext, MembersContext, TeamsContext, ClinicsContext, OnlineStoreContext, DashboardContext)(Dashboard);