import { stringBuilder } from "@spordle/helpers";
import styles from "./FileViewer.module.scss";

const ImageFile = ({ fileData, onLoad, onPreviewFail, fileName, setPreviewInfo, containerRef }) => {
    return (
        <img
            src={fileData}
            className={stringBuilder(styles.Preview, styles.PreviewImg)}
            alt={fileName}
            onError={onPreviewFail}
            onLoad={(e) => {
                onLoad(Math.min(1, containerRef.current.clientWidth / e.target.naturalWidth, containerRef.current.clientHeight / e.target.naturalHeight))
                setPreviewInfo({ isVisible: true, naturalWidth: e.target.naturalWidth, naturalHeight: e.target.naturalHeight });
            }}
        />
    )
}

export default ImageFile
