import React, { createContext } from 'react';
import API_SPORDLE, { apiUrlEnv } from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { setContext, setTag } from '@sentry/react';
import { Axios } from 'axios';
import { getMultiSportCode } from '../helpers/getReferer';
import { getOrganizationPublic } from '../api/client/organization';

/** @type {React.Context<Omit<IdentityRolesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>> & IdentityRolesContextProvider['state']} */
export const IdentityRolesContext = createContext();
IdentityRolesContext.displayName = 'IdentityRolesContext';

export const localStorageIdentityRoleId = `${apiUrlEnv}-identityRoleId`;

//const topLevel = process.env.REACT_APP_ENVIRONMENT === 'stage' ? '1eb5d92c-30a2-6e40-b31f-a8a1592cf2a9' : '1eb1f80b-0885-62e2-87c6-e454e80c8447';

export function getFederationId(){
    switch (getMultiSportCode()){
        case "SQ":
            return '68bcf4a5-0f63-11ed-bed9-023f3d3ef136';
        case "BQ":
            return 'c2f98c67-0dbf-11ed-bed9-023f3d3ef136';
        case "UK":
            return 'bf2ef31c-14ce-11ed-bed9-023f3d3ef136';
        default:
            break;
    }
    return '1eb5d92c-30a2-6e40-b31f-a8a1592cf2a9';// HC
}

export const topLevel = getFederationId(); // TODO: Hardcoded FED

const DEV_AUTHORIZED_EMAILS = [
    "gmichaud@spordle.com",
    "scooper@spordle.com",
    "frousselmorin@spordle.com",
    "atouillet@spordle.com",
    "mlabelle@spordle.com",
    "lessiass@spordle.com",
    "yguerni@spordle.com",
    "sbouchard@spordle.com",
];

class IdentityRolesContextProvider extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = window.frameElement && window.frameElement?.getAttribute('identityrolescontext') ? JSON.parse(window.frameElement?.getAttribute('identityrolescontext')) : {}

        if(window.frameElement && window.frameElement?.getAttribute('identityrolescontext'))
            API_SPORDLE.defaults.headers.common['X-Identity-Role'] = this.state.identity_role_id;
    }
    /*
    State exemple:
    state = {
        active: "1",
        end_date: null,
        identity: {
            email: "atouillet@spordle.com",
            family_name: "Touillet",
            identity_id: "7e2224f0-3bd7-49ae-bf8b-706f8e27ce87",
            name: "Arnaud",
        },
        identity_role_id: "1eb3eef3-fa26-6a70-abe5-02c510e3b1a6",
        organisation: {
            logo: null,
            organisation_id: "041ed146-8a8a-40f5-8d82-bb9967cfeb42",
            organisation_name: "Spordle ID",
        },
        role: {
            description: "Testing update Role",
            role_id: "1eb2077f-d042-64e6-a219-0242a74b9ff7",
            title: "Test update",
        },
        start_date: null,
        federation: { // System config (top level organization)
            organisation_id: "1eb1f80b-0885-62e2-87c6-e454e80c8447",
            organisation_name: "Spordle ID",
            abbreviation: "SID"
        }
    }*/

    wasGod = false;

    componentDidUpdate(){
        setContext('identityRole', this.state ? {
            identityRoleId: this.state.identity_role_id,
            organizationId: this.state.organisation?.organisation_id,
            organizationName: this.state.organisation?.organisation_name,
            federationId: this.state.federation?.organisation_id,
            federationName: this.state.federation?.organisation_name,
            isPrimary: this.state.is_primary,
            isAdmin: this.state.is_admin,
            powerUser: this.state.identity.power_user || '0',
            isActive: this.state.active,
            startDate: this.state.start_date,
            endDate: this.state.end_date,
        } : null);
        setTag('identityRoleId', this.state.identity_role_id);
    }

    /**
     * Will be used inside the QuickView Iframe
     * @param {orgId} orgId
     * @returns {Promise}
     */
    setCurrentState = (state) => this.setState(() => ({ ...state }), () => { API_SPORDLE.defaults.headers.common['X-Identity-Role'] = this.state.identity_role_id; });

    /**
     * Determines if the current user should see admin only UI
     * "is_admin" is for users that can overwrite permissions that are "System".
     * This feature is only used for Settings->Platform->Roles.
     * @returns {boolean}
     */
    isAdmin = () => process.env.REACT_APP_ENVIRONMENT === 'int' || process.env.REACT_APP_ENVIRONMENT === 'dev' || this.state.is_admin == '1' || this.isGod();

    /**
     * Determines if the current user is a god user (power_user)
     * @returns {boolean}
     */
    isGod = () => this.state.identity.power_user == '1';

    /**
     * Determines if the current user is considered a dev user
     * @returns {boolean}
     */
    isDev = () => DEV_AUTHORIZED_EMAILS.includes(this.state.identity.email);

    /**
     * Activate/Desactivate god view
     */
    toggleGodMode = () => {
        this.setState((prevState) => ({
            identity: {
                ...prevState.identity,
                power_user: prevState.identity.power_user == '1' ? '0' : '1',
            },
        }))
    }

    /**
     * Updates current identity Role
     * @param {string} field Field to update
     * @param {object} value Value
     */
    updateCurrentIdentityRole = (field, value) => {
        this.setState((prev) => ({ ...prev, [field]: value }));
    }

    /**
     * Sets the identityRole with what is passed in param
     * @param {object} identityRole The entire identityRole - Like in the state of this context
     * @returns {Promise}
     */
    setCurrentIdentityRole = (identityRole) => {
        API_SPORDLE.defaults.headers.common['X-Identity-Role'] = identityRole.identity_role_id;

        const federation = {
            organisation_id: topLevel,
        }

        switch (getMultiSportCode()){
            case 'BQ':
                federation.organisation_name = 'Baseball Canada';
                federation.abbreviation = 'BC';
                break;
            case 'SQ':
                federation.organisation_name = 'Soccer Canada';
                federation.abbreviation = 'SC';
                break;
            case 'UK':
                federation.organisation_name = 'Hockey United Kingdom';
                federation.abbreviation = 'EIHA';
                break;
            default:
                federation.organisation_name = 'Hockey Canada';
                federation.abbreviation = 'HCR';
                break;
        }

        this.setState(() => ({ ...identityRole, federation: federation }));

        return getOrganizationPublic(topLevel)
            .then((organization) => {
                this.setState(() => ({ federation: organization }));
            }).catch(console.error)
    }

    /**
     * Gets an identityRole by it's id
     * @param {string} identityRoleId
     * @returns {Promise}
     */
    getIdentityRole = (identityRoleId) => {
        return API_SPORDLE.get(`/rights/identity-roles/${identityRoleId}`)
            .then((response) => {
                if(response.data.status){
                    this.wasGod = response.data.identity_roles[0].identity.power_user == '1';
                    return response.data.identity_roles[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Link an identity (Cognito User) to a role under an organization
     * @param {string} identityId
     * @param {string} organizationId
     * @param {string} roleId
     * @param {string|null} startDate
     * @param {string|null} endDate
     * @returns {Promise}
     */
    linkIdentityToRole = (identityId, organizationId, roleId, startDate, endDate) => {
        const params = new URLSearchParams();

        params.append('identity_id', identityId);
        params.append('role_id', roleId);
        params.append('organisation_id', organizationId);
        params.append('active', 1);
        if(startDate) params.append('start_date', startDate);
        if(endDate) params.append('end_date', endDate);
        return API_SPORDLE.post(queryString.stringifyUrl({ url: `rights/identity-roles` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.identity_role_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a list of all the users linked to a role under an organization
     * @param {string} organizationId The ID of the organization we want a list of
     * @returns {Promise}
     */
    getIdentityRoleList = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/rights/identity-roles`, query: { organisation_id: organizationId } }))
            .then((response) => {
                if(response.data.status){
                    return response.data.identity_roles;
                }
                throw response.data.errors[0];
            })
    }

    /**
     * [GET] Gets all identities
     * @param {object} [queryParams] The query params for that call
     * @returns {Promise<[object]>}
     */
    getAccounts = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/accounts',
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.identities;
                }
                throw response.data.errors[0];
            })
    }

    /**
     * Updates a specific Identity-Role with the given values
     * @param {string|Array<string>} identityRoleId One or multiple Identity-Role IDs to update
     * @param {Object} values Every value for an Identity-Role
     * @returns {Promise}
     */
    updateIdentityRole = (identityRoleId, values) => {
        const buildParams = (rowValues) => {
            const data = new URLSearchParams();

            for(const key in rowValues){
                // eslint-disable-next-line no-prototype-builtins
                if(rowValues.hasOwnProperty(key)){
                    switch (key){
                        case 'identity_id':
                            data.append('identity_id', rowValues[key]);
                            break;
                        case 'role_id':
                            data.append('role_id', rowValues[key]);
                            break;
                        case 'role_info':
                            data.append('role_id', rowValues[key].role_id);
                            break;
                        case 'organisation_id':
                            data.append('organisation_id', rowValues[key]);
                            break;
                        case 'start_date':
                            data.append('start_date', rowValues[key]);
                            break;
                        case 'end_date':
                            data.append('end_date', rowValues[key]);
                            break;
                        case 'active':
                            data.append('active', rowValues[key]);
                            break;
                        case 'additional_role': // must be array format
                            if(Array.isArray(rowValues[key]) && rowValues[key].length > 0){
                                rowValues[key].forEach((addRole, index) => {
                                    data.append(`additional_roles[${index}]`, addRole.role.role_id);
                                });
                            }else if(rowValues[key] && rowValues[key]?.role?.role_id){
                                data.append('additional_roles[0]', rowValues[key].role.role_id);
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
            return data;
        }

        if(Array.isArray(identityRoleId)){ // Batch update
            return Axios.all(identityRoleId.map((id) => {
                return API_SPORDLE.put(queryString.stringifyUrl({ url: `rights/identity-roles/${id}` }), buildParams(values.find((dataValue) => dataValue.identity_id === id)));
            }))
                .then((Axios.spread((...responses) => {
                    if(responses.every((response) => response.data.status)){
                        return;
                    }
                    throw responses[0].data.errors[0];
                }, serverError)));
        }
        // Single update
        return API_SPORDLE.put(queryString.stringifyUrl({ url: `rights/identity-roles/${identityRoleId}` }), buildParams(values))
            .then((response) => {
                if(response.data.status){
                    return response.data.result;
                }
                throw response.data.errors[0];
            }, serverError)

    }

    /**
     * Sets an Identity Role as Primary
     * @param {string} identityId ID of the Identity Role to set as Primary
     * @param {string} identityRoleId ID of the Identity Role to set Primary
     */
    enablePrimaryRole = (identityId, identityRoleId) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/accounts/${identityId}/identity-roles/${identityRoleId}/enable-primary-role` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw new Error(response.data.errors.code);
            }, serverError)
    }

    /**
     * Deletes an Identity-Role from an Organization (Unlinks a user)
     * @param {string} identityRoleId Id of the Identity-Role to delete
     * @returns {Promise}
     */
    deleteIdentityRole = (identityRoleId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/rights/identity-roles/${identityRoleId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.result;
                }
                throw response.data.errors[0];
            })
    }

    render(){
        return (
            <IdentityRolesContext.Provider value={{
                ...this.state,
                ...this,
            }}
            >
                {this.props.children}
            </IdentityRolesContext.Provider>
        );
    }
}

export default IdentityRolesContextProvider;
