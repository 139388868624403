export default{
    "contexts.membersContext.3160" : "Cette information médicale existe déjà pour ce membre",
    "contexts.membersContext.3269" : "Ce code postal ne fait pas partie de l'organisation",
    "contexts.membersContext.3332" : "L'extension de fichier est invalide",
    "contexts.membersContext.3179" : "Accès interdit",
    "contexts.membersContext.3191" : "Permissions insuffisantes",
    "contexts.membersContext.3331" : "La taille du fichier dépasse la limite",
    "contexts.membersContext.2000" : "Paramètres invalides",
    "contexts.membersContext.1000" : "Paramètres manquants",
    "contexts.membersContext.3229" : "L'organisation ne correspond pas",
    "contexts.membersContext.3060" : "La ressource n'a pas pu être trouvée",
    "contexts.membersContext.3045" : "Les membres à fusionner doivent être différents",
}