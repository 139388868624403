import { useSpordleTable } from "@spordle/datatables";
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext } from "react";
import {
    Alert,
    Button,
    Col,
    Collapse,
    FormGroup,
    Label, ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import { object, string } from 'yup';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { success, fail } from '@spordle/toasts';
// Context
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { SuspensionsContext } from "../../../../../contexts/SuspensionsContext";
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../../helpers/i18nHelper'; // these are deprecated, look at OrganizationPhoneTypes i18nhelper
import { I18nContext } from "../../../../../contexts/I18nContext";
import { getInfractions } from "../../../../../api/client/infractions";
import { getSuspensionTypes } from "../../../../../api/client/suspensionTypes";

const SuspensionsAddModal = (props) => {
    const i18nHelper = useContext(I18nHelperContext);
    const suspensionsContext = useContext(SuspensionsContext);
    const organizationContext = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);
    const spordleTable = useSpordleTable();

    return (
        <AnalyticsModal isOpen={props.addSuspensionModalOpen} toggle={props.toggleAddSuspension} analyticsName='SuspensionsAddModal'>
            <Formik
                initialValues={{
                    ...i18nHelper.getInitialValues(), // look at phone types these are not used (deprecated)
                    code: '',
                    infraction_id: '',
                    suspension_type_id: '',
                    status: 'active',
                }}
                validationSchema={object().shape({
                    ...i18nHelper.getValidationSchema({ name: string().required(<Translate id='form.validation.name.required' />) }),
                    code: string().required(<Translate id='organization.settings.organizationSuspensions.add.code.required' />).test({
                        name: "code-is-unique",
                        message: <Translate id="organization.settings.organizationSuspensions.add.code.duplicate" />,
                        test: (code) => (!spordleTable.getData().some((row) => row.code == code)),
                    }),
                    status: string().required(<Translate id='organization.settings.organizationSuspensions.add.status.required' />),
                    infraction_id: string().required(<Translate id='organization.settings.organizationSuspensions.add.infraction.required' />),
                    suspension_type_id: string().required(<Translate id='organization.settings.organizationSuspensions.add.suspension.type.required' />),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    values.active = !!(values.status === 'active')
                    const newItem = values // data that will be sent back to table

                    // data to send to api
                    const apiValues = { ...values, ...i18nHelper.getAPIValues(values) };
                    delete apiValues.i18n

                    suspensionsContext.createSuspension(organizationContext.organisation_id, apiValues)
                        .then((id) => {
                            const returnData = {
                                suspension_id: id,
                                ...newItem,
                            }
                            spordleTable.addRow(returnData).then(() => {
                                props.toggleAddSuspension();
                            })
                            success();
                        }).catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                            setStatus(error.message);
                        })
                        .finally(() => {
                            setSubmitting(false)
                        })
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalHeader toggle={props.toggleAddSuspension}><Translate id='organization.settings.organizationSuspensions.add.title' /></ModalHeader>
                                <ModalBody>
                                    <RenderI18nForm field={'name'}>
                                        {({ fieldName, fieldLabel }) => {
                                            return (
                                                <Row key={fieldName}>
                                                    <Col md='12'>
                                                        <FormGroup>
                                                            <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                            <FormikInputText id={fieldName} name={fieldName} trim />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            )
                                        }}
                                    </RenderI18nForm>
                                    <Row>
                                        <Col md='12'>
                                            <FormGroup>
                                                <Label for='code' className='text-muted'><Translate id='organization.settings.organizationSuspensions.add.code' /></Label>
                                                <FormikInputText id='code' name='code' trim />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='12'>
                                            <FormGroup>
                                                <Label for='infraction_id' className='text-muted'><Translate id='organization.settings.organizationSuspensions.add.infraction' /></Label>
                                                <FormikSelect
                                                    name='infraction_id'
                                                    id='infraction_id'
                                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                    searchKeys={[
                                                        `i18n.${getGenericLocale()}.name`,
                                                        `name`,
                                                    ]}
                                                    loadData={(from) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return getInfractions({ organisation_id: organizationContext.organisation_id, active: 1 })
                                                                    .then((infractions) => {
                                                                        return infractions.map((infraction) => ({
                                                                            value: infraction.infraction_id,
                                                                            label: infraction.name,
                                                                            i18n: infraction.i18n,
                                                                        }))
                                                                    })
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='12'>
                                            <FormGroup>
                                                <Label for='suspension_type_id' className='text-muted'><Translate id='organization.settings.organizationSuspensions.add.suspension.type' /></Label>
                                                <FormikSelect
                                                    name='suspension_type_id'
                                                    id='suspension_type_id'
                                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                    searchKeys={[
                                                        `i18n.${getGenericLocale()}.name`,
                                                        `name`,
                                                    ]}
                                                    loadData={(from) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return getSuspensionTypes({ organisation_id: organizationContext.organisation_id, active: 1 })
                                                                    .then((suspensionTypes) => {
                                                                        return suspensionTypes.map((suspensionType) => ({
                                                                            value: suspensionType.suspension_type_id,
                                                                            label: suspensionType.name,
                                                                            i18n: suspensionType.i18n,
                                                                        }))
                                                                    })
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                />
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='12'>
                                            <FormGroup>
                                                <Label for='status' className='text-muted'><Translate id='organization.settings.organizationSuspensions.add.status' /></Label>

                                                <FormikSelect
                                                    name="status"
                                                    id="active_SuspensionsAddModal"
                                                    search={false}
                                                    loadingStatus='success'
                                                    defaultData={[
                                                        { id: 'active', value: 'active', label: 'organization.settings.organizationSuspensions.add.status.active', translateLabel: true },
                                                        { id: 'inactive', value: 'inactive', label: 'organization.settings.organizationSuspensions.add.status.inactive', translateLabel: true },
                                                    ]}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                        <Alert color={'danger'}><Translate id={`contexts.SuspensionsContext.errors.${formik.status}`} /></Alert>
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type='button' outline onClick={props.toggleAddSuspension} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    )
}

export default SuspensionsAddModal;