export default{
    "catalog.registrationFees.sidePanel.tab.fee"     : "Frais",
    "catalog.registrationFees.sidePanel.tab.catalog" : "Catalogue",

    "catalog.registrationFees.sidePanel.fees.count"            : "{count} frais sélectionnés",
    "catalog.registrationFees.sidePanel.fees.feeNeededMessage" : "Un ou plusieurs items sélectionnés n'ont pas de frais pour l'organisation parente. Ceux-ci ne seront pas mis à jour.",

    "catalog.registrationFees.sidePanel.form.memberType"                 : "Type de membre",
    "catalog.registrationFees.sidePanel.form.sku"                        : "UGS",
    "catalog.registrationFees.sidePanel.form.category"                   : "Catégorie",
    "catalog.registrationFees.sidePanel.form.division"                   : "Division",
    "catalog.registrationFees.sidePanel.form.class"                      : "Classe",
    "catalog.registrationFees.sidePanel.form.optional"                   : "Optionnel",
    "catalog.registrationFees.sidePanel.form.status"                     : "Statut",
    "catalog.registrationFees.sidePanel.form.active"                     : "Actif",
    "catalog.registrationFees.sidePanel.form.inactive"                   : "Inactif",
    "catalog.registrationFees.sidePanel.form.availablePlaces"            : "Places disponibles",
    "catalog.registrationFees.sidePanel.form.totalPlaces"                : "Nombres de places",
    "catalog.registrationFees.sidePanel.form.unlimited"                  : "Illimité",
    "catalog.registrationFees.sidePanel.form.registrationFee"            : "Frais d'inscription",
    "catalog.registrationFees.sidePanel.form.registrationFees"           : "Frais d'inscription",
    "catalog.registrationFees.sidePanel.form.regularFee"                 : "Frais régulier",
    "catalog.registrationFees.sidePanel.form.platformFees"               : "Frais de plateforme",
    "catalog.registrationFees.sidePanel.form.tax"                        : "+Taxe",
    "catalog.registrationFees.sidePanel.form.earlyFee"                   : "Frais hâtif",
    "catalog.registrationFees.sidePanel.form.earlyFee.hint"              : "Avant",
    "catalog.registrationFees.sidePanel.form.earlyFee.placeholder"       : "Frais",
    "catalog.registrationFees.sidePanel.form.earlyDate.validation"       : "La date est requise",
    "catalog.registrationFees.sidePanel.form.earlyDate.validation.valid" : "La date doit être avant la date de frais tardif",
    "catalog.registrationFees.sidePanel.form.lateFee"                    : "Frais tardif",
    "catalog.registrationFees.sidePanel.form.lateFee.hint"               : "Après",
    "catalog.registrationFees.sidePanel.form.lateFee.placeholder"        : "Frais",
    "catalog.registrationFees.sidePanel.form.lateDate.validation"        : "La date est requise",
    "catalog.registrationFees.sidePanel.form.lateDate.validation.valid"  : "La date doit être après la date de frais hâtif",
    "catalog.registrationFees.sidePanel.form.applicableTax"              : "Taxes applicables",
    "catalog.registrationFees.sidePanel.form.applicableTax.none"         : "Pas taxable",
    "catalog.registrationFees.sidePanel.form.taxReceipt"                 : "Reçu fiscal",
    "catalog.registrationFees.sidePanel.form.taxReceipt.no"              : "Pas de reçu",

    "catalog.registrationFees.sidePanel.form.updated" : "Frais d'inscription mis à jour!",

    "catalog.registrationFees.sidePanel.form.waivers"         : "Dispenses",
    "catalog.registrationFees.sidePanel.form.selectedWaivers" : "Dispenses sélectionnées",
    "catalog.registrationFees.sidePanel.form.waiversSelected" : "{count, plural, =0 {Aucune dispense sélectionnée} one {# dispense sélectionnée} other {# dispenses sélectionnées}}",

    "catalog.registrationFees.sidePanel.form.customForms"  : "Questionnaires",
    "catalog.registrationFees.sidePanel.form.installments" : "Versements",
}