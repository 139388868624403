import { Popover } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useRef } from "react";
import styles from "./HoverBlock.module.scss";
import { stringBuilder } from "@spordle/helpers";

const HoverBlock = ({ children, disabled, dropdownProps, dropdownChildren, className, popoverProps = {}, ...props }) => {
    const [ isOpen, { open, close } ] = useDisclosure(false);
    const timeout = useRef(null);

    const delayedClose = () => {
        timeout.current = setTimeout(() => {
            close();
        }, 120);
    }

    const handleOpen = () => {
        clearTimeout(timeout.current);
        open();
    }

    useEffect(() => {
        return () => clearTimeout(timeout.current);
    }, []);

    return (
        <Popover
            arrowSize={0}
            gutter={0}
            opened={!disabled && isOpen}
            placement="center"
            position="top"
            trapFocus={false}
            width={320}
            inlist={false}
            className="d-block"
            zIndex={1040}
            onClose={() => { // Is trigged by click outside
                close();
            }}
            target={
                <div
                    onMouseEnter={handleOpen}
                    onMouseLeave={delayedClose}
                    className={stringBuilder(className, styles.target)}
                    {...props}
                >
                    {children}
                </div>
            }
            {...popoverProps}
        >
            <div onMouseEnter={handleOpen} onMouseLeave={delayedClose} {...dropdownProps}>
                {dropdownChildren}
            </div>
        </Popover>
    );
}

export default HoverBlock;