export default{
    "members.search.addCompetitionTeam.modal"                : "Add team(s)",
    "members.search.addCompetitionTeam.team.required"        : "Please add at least one team.",
    "members.search.addCompetitionTeam.competition.required" : "Please select the competition.",
    "members.search.addCompetitionTeam.availableTeams"       : "Available Teams",
    "members.search.addCompetitionTeam.filterTeams"          : "Filter teams with competition's division",
    "members.search.addCompetitionTeam.filterDiv"            : "Only <b>{division}</b> teams",
    "members.search.addCompetitionTeam.add.success"          : "Team(s) successfully added!",

    "members.search.memberAdvanceSearch.filters.organization"            : "Organization",
    "members.search.memberAdvanceSearch.filters.type"                    : "Type",
    "members.search.memberAdvanceSearch.filters.type.placeholder"        : "Choose a type",
    "members.search.memberAdvanceSearch.filters.status"                  : "Status",
    "members.search.memberAdvanceSearch.filters.status.placeholder"      : "Choose status",
    "members.search.memberAdvanceSearch.filters.gender"                  : "Gender Identity",
    "members.search.memberAdvanceSearch.filters.gender.placeholder"      : "Choose a gender identity",
    "members.search.memberAdvanceSearch.filters.from"                    : "From",
    "members.search.memberAdvanceSearch.filters.to"                      : "To",
    "members.search.memberAdvanceSearch.filters.minAge"                  : "Minimum age",
    "members.search.memberAdvanceSearch.filters.maxAge"                  : "Maximum age",
    "members.search.memberAdvanceSearch.filters.or"                      : "Or",
    "members.search.memberAdvanceSearch.filters.currentOrg"              : "Include members of the selected organization",
    "members.search.memberAdvanceSearch.filters.all"                     : "Include members of all organizations",
    "members.search.memberAdvanceSearch.filters.allSubOrgs"              : "Include members of all sub-organizations",
    "members.search.memberAdvanceSearch.filters.onlyCrc"                 : "Invalid background checks",
    "members.search.memberAdvanceSearch.filters.onlyUnconfirmedAddress"  : "Unconfirmed addresses",
    "members.search.memberAdvanceSearch.filters.onlyNeedAddressDocument" : "Missing documents",
    "members.search.memberAdvanceSearch.filters.withoutProfilePicture"   : "Missing profile picture",
    "members.search.memberAdvanceSearch.filters.hasOutstandingBalance"   : "With outstanding balance",
    "members.search.memberAdvanceSearch.filters.onlyUnconfirmed"         : "Only unconfirmed members",
    "members.search.memberAdvanceSearch.filters.memberNumber"            : "{unique_identifier}",
    "members.search.memberAdvanceSearch.filters.memberStatus"            : "Status",
    "members.search.memberAdvanceSearch.filters.noMemberType"            : "No member type / Unregistered members",
    "members.search.memberAdvanceSearch.filters.missingRequiredFields"   : "Missing required fields",
    "members.search.memberAdvanceSearch.filters.noMemberType.tip"        : "Use this option to search for unregistered members. A member with no member type has no activity yet with any organization in the current period.",

    "members.search.memberAdvanceSearch.memberNumber.ignoredFilters" : "The other filters will be ignored",

    "members.search.memberAdvanceSearch.moreFilter"   : "More filters",
    "members.search.memberAdvanceSearch.lessFilter"   : "Less filters",
    "members.search.memberAdvanceSearch.resetFilters" : "Reset filters",

    "members.search.memberAdvanceSearch.csv.confirmed"      : "Confirmed",
    "members.search.memberAdvanceSearch.csv.confirmed_date" : "Confirmed Date",
    "members.search.memberAdvanceSearch.csv.member_status"  : "Status",

    "members.search.memberAdvanceSearch.validation.startDate.required"        : "Date is required",
    "members.search.memberAdvanceSearch.validation.startDate.invalidFormat"   : "Invalid date format",
    "members.search.memberAdvanceSearch.validation.startDate.beforeToday"     : "The date must be before today",
    "members.search.memberAdvanceSearch.validation.endDate.required"          : "Date is required",
    "members.search.memberAdvanceSearch.validation.endDate.invalidFormat"     : "Invalid date format",
    "members.search.memberAdvanceSearch.validation.endDate.beforeStartDate"   : "The date cannot be earlier than the start date",
    "members.search.memberAdvanceSearch.validation.firstName.requiredFilters" : "First name is required",
    "members.search.memberAdvanceSearch.validation.lastName.requiredFilters"  : "Last name is required",
    "members.search.memberAdvanceSearch.validation.email.valid"               : "Email must be valid",
    "members.search.memberAdvanceSearch.validation.minAge.required"           : "Please enter a minimum age",
    "members.search.memberAdvanceSearch.validation.minAge.1.required"         : "Please enter 1 or greater as a minimum age",
    "members.search.memberAdvanceSearch.validation.minAge.max"                : "Cannot be more then the maximum age",
    "members.search.memberAdvanceSearch.validation.maxAge.required"           : "Please enter a maximum age",
    "members.search.memberAdvanceSearch.validation.maxAge.min"                : "Cannot be less then the minimum age",
}