import { Card, CardBody, Col } from 'reactstrap';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';

const MemberOverviewCardItem = ({ cardsLoading, value, label, customRender }) => {
    return (
        <Col xs="6" md="3" className="mb-2">
            <Card className="card-shadow mb-0">
                <OverlayLoader isLoading={cardsLoading}>
                    <CardBody>
                        {customRender ?
                            customRender()
                            :
                            <>
                                <div className="h4 font-bold">{value}</div>
                                {label}
                            </>
                        }
                    </CardBody>
                </OverlayLoader>
            </Card>
        </Col>
    );
}

export default MemberOverviewCardItem;