import Translate from '@spordle/intl-elements';
import { useLayoutEffect, useState } from 'react';
import { ModalHeader } from 'reactstrap';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import { AxiosCancelAll } from '../../../../../api/CancellableAPI';
import CrossFade from '../../../../../components/crossFade/CrossFade';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { BC_ID } from '../gameIncidentHelper';
import AddGameIncidentStepIncident from './steps/stepIncident/AddGameIncidentStepIncident';
import AddGameIncidentStepMatch from './steps/stepMatch/AddGameIncidentStepMatch';
import AddGameIncidentStepOfficials from './steps/stepOfficials/AddGameIncidentStepOfficials';
import AddGameIncidentStepStatus from './steps/stepStatus/AddGameIncidentStepStatus';
import AddGameIncidentStepTeam from './steps/stepTeam/AddMemberGameIncidentStepTeam';

/**
 * @description Form to add a game incident report
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {function} props.toggle
 * @param {object} props.member
 * @returns {JSX.Element}
 */
const AddGameIncidentModal = (props) => {
    return (
        <AnalyticsModal modalClassName="mobile" size="lg" analyticsName="AddGameIncidentModal" isOpen={props.isOpen}>
            <AddGameIncidentModalInner {...props} />
        </AnalyticsModal>
    )
}

const AddGameIncidentModalInner = (props) => {
    const { toggle } = props;
    const [ step, setStep ] = useState(1);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ girData, setGirData ] = useState({
        organisation_id: BC_ID,
    });

    const Steps = [
        AddGameIncidentStepMatch,
        AddGameIncidentStepTeam,
        AddGameIncidentStepOfficials,
        AddGameIncidentStepIncident,
        AddGameIncidentStepStatus,
    ];

    const next = () => setStep(step + 1 >= Steps.length ? Steps.length : step + 1);
    const previous = () => setStep(step - 1 <= 1 ? 1 : step - 1);

    /**
     * @description Function that updates the global data for the creation of the game incident
     * @param {object} data
     */
    const updateGirData = (data) => {
        setGirData((prev) => ({
            ...prev,
            ...data,
        }))
    };

    useLayoutEffect(() => AxiosCancelAll, []);

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader toggle={toggle}>
                <Translate id='gameIncident.addModal.title' values={{ step: step, stepNb: Steps.length }} />
            </ModalHeader>
            {Steps.map((View, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <CrossFade key={index + 1} isVisible={index + 1 === step}>
                    <View
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        updateGirData={updateGirData}
                        girData={girData}
                        next={next}
                        previous={previous}
                        {...props}
                    />
                </CrossFade>
            ))}
        </OverlayLoader>
    )
}

export default AddGameIncidentModal;