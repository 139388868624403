export default{
    "organization.settings.organizationContactTypes.table.empty"                            : "No Contact Types",
    "organization.settings.organizationContactTypes.table.column.status"                    : "Status",
    "organization.settings.organizationContactTypes.table.column.mandatory_in_organisation" : "Mandatory Staff Role",
    "organization.settings.organizationContactTypes.add.title"                              : "Add Contact Type",
    "organization.settings.organizationContactTypes.add.status"                             : "Status",
    "organization.settings.organizationContactTypes.add.status.active"                      : "Active",
    "organization.settings.organizationContactTypes.add.status.inactive"                    : "Inactive",
    "organization.settings.organizationContactTypes.add.name.required"                      : "The name is required",
    "organization.settings.organizationContactTypes.add.status.required"                    : "The status is required",
    "organization.settings.organizationContactTypes.add.mandatory_in_organisation"          : "This role is mandatory in an organization",

    "organization.settings.contactStaffSettings.table.column.mandatory_in_organisation.plural" : "Mandatory Staff Roles",
    "organization.settings.contactStaffSettings.table.column.mandatory_in_organisation.desc"   : "Staff roles that are mandatory for an organization.",

    "organization.settings.contactStaffSettings.table.column.contactMustBeMember.desc" : "This setting determines if an organization contact must be a member in order to be added.",


    "organization.settings.organizationContactTypes.tab.title" : "Contact Types",

    "organization.settings.organizationContactTypes.mandatory_in_organisation.yes" : "Mandatory",
    "organization.settings.organizationContactTypes.mandatory_in_organisation.no"  : "Optional",

    "organization.settings.contactStaffSettings.card.title" : "Contacts and Staff settings",

}