export default{
    // general
    "settings.payees.serviceType"          : "Service Type",
    "settings.payees.businessName"         : "Business Name",
    "settings.payees.serviceType.required" : "Service type is required",
    "settings.payees.prefix"               : "Prefix",
    "settings.payees.prefix.DR."           : "Dr.",
    "settings.payees.prefix.MR."           : "Mr.",
    "settings.payees.prefix.MRS."          : "Mrs.",


    // add
    "settings.payees.add.title" : "Add a payee",


    // sidepanel
    "settings.payees.sidepanel.delete.text" : "You are about to delete this payee: {name}",

}