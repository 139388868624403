import { FormikError, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import { useState } from "react";
import { Col, Collapse, Label, Row } from "reactstrap";
import CollapsibleCard from "../../../../../../../components/collapsibleCard/CollapsibleCard";
import { usePrevious } from "../../../../../../../components/customHooks/usePrevious";
import Required from "../../../../../../../components/formik/Required";
import UserImg from "../../../../../../../components/UserImg";
import { OFFICIAL_TYPES } from "../../../gameIncidentHelper";

const OfficialListItem = ({ official, index, emailPhoneRequired }) => {
    // Contexts
    const { errors, touched, setFieldValue, values } = useFormikContext();

    // States
    const [ remove, setRemove ] = useState(false);
    const [ isOpen, setIsOpen ] = useState(true);

    // Other
    const hasError = !remove && !!errors.officials?.[index] && !!touched.officials?.[index];

    // Functions
    const handleRemove = () => setFieldValue('officials', values.officials.filter((_, i) => i !== index));

    // Hooks
    usePrevious((prev) => {
        if(prev < values.officials.length && index !== values.officials.length - 1){
            setIsOpen(false);
        }
    }, values.officials?.length);

    return (
        <Collapse onExited={handleRemove} isOpen={!remove}>
            <CollapsibleCard
                className="mb-3"
                error={hasError}
                img={
                    <UserImg
                        width={50}
                        className="text-uppercase"
                        src={official.attachment?.full_path}
                        filePos={official.attachment?.file_position}
                        alt={official.first_name + ' ' + official.last_name}
                        abbr={official.first_name.charAt(0) + official.last_name.charAt(0)}
                    />
                }
                initOpen={isOpen}
                title={official.first_name + ' ' + official.last_name}
                subtitle={'#' + official.unique_identifier}
                extraInfo={index !== 0 &&
                    <button onClick={() => setRemove(true)} type="button" className="align-self-start reset-btn text-danger">
                        <i className="mdi mdi-delete" />
                    </button>
                }
            >
                <Row form>
                    <Col className="mb-3 mb-lg-0" lg="3">
                        <Label for={'officials.' + index + '.official_type'}><Translate id='gameIncident.addModal.label.officialType' /> <Required /></Label>
                        <FormikSelect
                            className="w-100"
                            name={'officials.' + index + '.official_type'}
                            id={'officials.' + index + '.official_type'}
                            defaultData={OFFICIAL_TYPES.map((type) => ({
                                label: 'gameIncident.addModal.steps.officials.type.' + type,
                                value: type,
                                translateLabel: true,
                            }))}
                            loadingStatus='success'
                        />
                    </Col>
                    <Col className="mb-3 mb-lg-0" lg="3">
                        <Label for={'officials.' + index + '.email'}>
                            <Translate id='form.fields.email' /> {emailPhoneRequired && <Required />}
                        </Label>
                        <FormikInputText placeholder="example@email.ca" translatePlaceholder={false} trim name={'officials.' + index + '.email'} />
                    </Col>
                    <Col lg="6">
                        <Label for={'officials.' + index + '.phone_number'}>
                            <Translate id='form.fields.phone' /> {emailPhoneRequired && <Required />}
                        </Label>
                        <div className="d-flex">
                            {/* PHONE NUMBER */}
                            <div>
                                <FormikPhoneInput
                                    name={'officials.' + index + '.phone_number'}
                                    id={'officials.' + index + '.phone_number'}
                                    manualError
                                />
                            </div>
                            <FormikInputNumber
                                style={{
                                    flexBasis: '33%',
                                }}
                                prefix="#"
                                placeholder="Ext"
                                translatePlaceholder={false}
                                name={'officials.' + index + '.phoneExt'}
                            />
                        </div>
                        <FormikError name={'officials.' + index + '.phone_number'} />
                    </Col>
                </Row>
            </CollapsibleCard>
        </Collapse>
    );
}

export default OfficialListItem;