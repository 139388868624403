import Translate from "@spordle/intl-elements";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import CollapsibleCard from "../../../components/collapsibleCard/CollapsibleCard";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import UserImg from "../../../components/UserImg";
import { HoverableCopy } from "../../../helpers/clipboardHelper";
import { stringBuilder } from "@spordle/helpers";


const SidepanelWaitlist = ({ clinic, waitlist, waitlistLoading }) => {
    const isLoading = !Array.isArray(waitlist);

    return (
        <>
            <CollapsibleCard
                className="rounded-0 border-top-0"
                innerClassName="rounded-0"
                isDisabled={clinic.manage_waiting_list == '0'}
                key={clinic.clinic_id}
                initOpen={waitlist.length > 0 && !waitlistLoading}
                title={
                    <div className="d-flex align-items-center">
                        <span className="mr-1">
                            <Translate id='clinics.search.sidePanelClinicSearch.attendees.waitlisted' />
                        </span>
                        ({waitlist.length || 0})
                    </div>
                }
                mountChildrenOnEnter
            >
                {!isLoading &&
                    <WaitlistCardList waitlist={waitlist} clinic={clinic} />
                }
            </CollapsibleCard>
        </>
    );
}

const WaitlistCardList = ({ waitlist, clinic }) => {
    return (
        <>
            <SpordleTableProvider
                id="clinicSearchSidePanelWaitlist"
                searchKeys={[
                    { name: 'member.unique_identifier', weight: 2 },
                    'member.last_name',
                    'member.first_name',
                ]}
                viewMode='GRID'
                dataIndex="clinic_attendee_id"
                loadData={(from) => {
                    switch (from){
                        case 'CDM':
                            return Promise.resolve(waitlist || []);
                        default:
                            break;
                    }
                }}
                loadingMessage={
                    Array.from({ length: 3 }, (_, i) => i).map((key) => (
                        <UserDisplay className="mb-2" block card key={key}>
                            <UserDisplay.Container>
                                <Skeleton circle height={40} width={40} />
                            </UserDisplay.Container>
                            <UserDisplay.Container>
                                <Skeleton containerClassName="line-height-1 d-block" className="mb-0" height={10} width={87} />
                                <Skeleton className="mb-1" height={16} width={150} />
                                <Skeleton className="mb-0" height={12} width={125} />
                            </UserDisplay.Container>
                        </UserDisplay>
                    ))
                }
                pagination={50}
                gridConfig={{
                    row: {
                        form: true,
                    },
                    col: {
                        sm: '12',
                    },
                    gridRender: (waitlistedMember, spordleTable) => (
                        <WaitlistCard waitlistedMember={waitlistedMember} />
                    ),
                }}
                paginationMessage={() => null}
                emptyLayout={
                    <div className="max-w-75 mx-auto text-center text-muted">
                        <div className={`${waitlist.length > 0 ? "mdi-account-search" : "mdi-account-circle"} mdi line-height-1`} style={{ fontSize: 50 }} />
                        <div className="font-bold"><Translate id='clinics.search.sidePanelClinicSearch.waitlist.noAttendees' /></div>
                        <div className="small">
                            <Translate id={`clinics.search.sidePanelClinicSearch.waitlist.noAttendees${waitlist.length > 0 ? "Found" : "Registered"}`} />
                        </div>
                        <Link
                            to={`/clinics/profile/${clinic?.clinic_id}/waitlist`}
                            className="text-link small"
                        >
                            <Translate id='clinics.search.sidePanelClinicSearch.attendees.viewList' /> <i className="mdi mdi-chevron-right" />
                        </Link>
                    </div>
                }
            >
                {(spordleTable) => (
                    <>
                        {waitlist.length > 0 &&
                            <>
                                <SpordleTableProvider.SearchInput className="mb-3 w-100" />
                                <div
                                    className={stringBuilder(
                                        "d-flex justify-content-end",
                                        { "mb-3": spordleTable.filteredData.length > spordleTable.getPagination() },
                                    )}
                                >
                                    <SpordleTableProvider.Paginator.Pagination />
                                </div>
                            </>
                        }
                        <SpordleTableView />
                        {spordleTable.getDisplayedData().length > 0 &&
                            <Link to={`/clinics/profile/${clinic?.clinic_id}/waitlist`} className="text-right btn btn-sm btn-link mt-2 w-100">
                                <Translate id='clinics.search.sidePanelClinicSearch.attendees.viewList' /> <i className="mdi mdi-chevron-right" />
                            </Link>
                        }
                    </>
                )}
            </SpordleTableProvider>
        </>
    )
}

const WaitlistCard = ({ waitlistedMember }) => {
    return (
        <UserDisplay className="mb-2" card block>
            <UserDisplay.Container>
                <UserImg
                    className="text-uppercase"
                    width={40}
                    alt={waitlistedMember.member.first_name + " " + waitlistedMember.member.last_name}
                    abbr={waitlistedMember.member.first_name.charAt(0) + waitlistedMember.member.last_name.charAt(0)}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Subtitle>
                    <Link
                        to={{
                            pathname: `/clinics/profile/${waitlistedMember.clinic?.clinic_id}/waitlist`,
                            search: waitlistedMember.member.unique_identifier,
                        }}
                    >
                        <Translate id='clinics.search.sidePanelClinicSearch.attendees.viewInClinic' /> <i className="mdi mdi-chevron-right" />
                    </Link>
                </UserDisplay.Subtitle>
                <UserDisplay.Title>
                    {waitlistedMember.member.first_name} {waitlistedMember.member.last_name}
                </UserDisplay.Title>
                <UserDisplay.Subtitle>
                    <HoverableCopy toCopy={waitlistedMember.waiting_list_item_sequential_number}>
                        {waitlistedMember.waiting_list_item_sequential_number}
                    </HoverableCopy>
                </UserDisplay.Subtitle>
                <UserDisplay.Subtitle>
                    <HoverableCopy toCopy={waitlistedMember.member.unique_identifier}>
                        <Link to={'/members/profile/' + waitlistedMember.member.member_id}>
                            #{waitlistedMember.member.unique_identifier} <i className="mdi mdi-chevron-right" />
                        </Link>
                    </HoverableCopy>
                </UserDisplay.Subtitle>
            </UserDisplay.Container>
            <UserDisplay.Container className="ml-auto text-center">
                {(waitlistedMember.invoice?.status === 'COMPLETED' || waitlistedMember.invoice?.status === 'PAID') &&
                    <>
                        <i className="line-height-1 font-22 mdi mdi-check-circle text-primary" />
                        <div className="text-uppercase small">
                            <Translate id='clinics.profile.attendees.table.paid' />
                        </div>
                    </>
                }
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default SidepanelWaitlist;