import forms from "./forms";
import clinicItems from "./clinicItems";
import otherItems from "./otherItems";
import registrationFees from "./registrationFees";
import affiliationFees from "./affiliationFees";
import rebates from "./rebates";
import waivers from "./waivers";
import terms from "./terms";
import installment from "./installment";

export default{
    ...clinicItems,
    ...otherItems,
    ...forms,
    ...installment,
    ...registrationFees,
    ...affiliationFees,
    ...rebates,
    ...waivers,
    ...terms,
}