import React from "react";
import {
    Button,
    Card
} from "reactstrap";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';

import SidePanelOrganizationTeamCategoryTypes from './SidePanelOrganizationTeamCategoryTypes';
import Translate from "@spordle/intl-elements";
import SpordlePanelTable from "../../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../../components/table/EmptyLayout";
import TeamCategoryTypesAddModal from "./TeamCategoryTypesAddModal";
import CardSectionTitle from "../../../../../components/CardSectionTitle";

// Contexts
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import I18nHelperContextProvider, { I18nHelperContext } from '../../../../../helpers/i18nHelper';
import withContexts from "../../../../../helpers/withContexts";
import { I18nContext } from "../../../../../contexts/I18nContext";

class OrganizationTeamCategoryTypes extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            addTeamCategoryTypeModalOpen: false,
            isFederation: this.props.OrganizationContext.organisation_id === this.props.IdentityRolesContext.federation.organisation_id,
        }
    }

    toggleAddTeamCategoryType = () => {
        this.setState((prevState) => ({ addTeamCategoryTypeModalOpen: !prevState.addTeamCategoryTypeModalOpen }));
    }

    render(){
        return (
            <I18nHelperContextProvider fields={[ 'name' ]}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <SpordlePanelTable
                            allowOutsideClick
                            sidePanel={(props) => <SidePanelOrganizationTeamCategoryTypes {...props} i18n={i18nHelper} isFederation={this.state.isFederation} />}
                            dataIndex='team_category_type_id'
                            table={(panelProps) => {
                                return (
                                    <Card body className='card-shadow'>
                                        <CardSectionTitle title='settings.tabs.suspensions' />
                                        <SpordleTableProvider
                                            id='OrganizationTeamCategoryTypesTable'
                                            tableHover clickable striped
                                            bordered
                                            ref={panelProps.spordleTableRef}
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                            dataIndex='team_category_type_id'
                                            searchKeys={[
                                                `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                'name',
                                            ]}
                                            columns={[
                                                ...i18nHelper.getTableColumns('name'),
                                                {
                                                    label: <Translate id='misc.active' />,
                                                    key: "active",
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                            ]}
                                            onColumnClick={(e, teamCategoryType) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        panelProps.onSingleSelectChange(teamCategoryType);
                                                        break;
                                                }
                                            }}
                                            desktopWhen='md'
                                            emptyLayout={<EmptyLayout />}
                                            renderRow={(columnKey, teamCategoryType, spordleTable) => {
                                                switch (columnKey){
                                                    case 'active':
                                                        return (
                                                            <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                {teamCategoryType.active == '1' ?
                                                                    <i className="text-primary mdi mdi-check" />
                                                                    :
                                                                    <i className="text-danger mdi mdi-close" />
                                                                }
                                                            </div>
                                                        )
                                                    default:
                                                        break;
                                                }
                                            }}
                                            rowIsHighlighted={(teamCategoryType) => !!teamCategoryType.checked}
                                            loadData={(from, data, spordleTable) => {
                                                switch (from){
                                                    case 'REFRESH':
                                                        spordleTable.setLoading()
                                                    case 'CDM':
                                                        return this.props.TeamsContext.getTeamCategoryTypes();
                                                    default:
                                                        break;
                                                }
                                            }}
                                        >
                                            <TopSection toggleAddTeamCategoryType={this.toggleAddTeamCategoryType} panelProps={panelProps} addTeamCategoryTypeModalOpen={this.state.addTeamCategoryTypeModalOpen} />
                                            <SpordleTableView />
                                        </SpordleTableProvider>
                                    </Card>
                                )
                            }}
                        />
                    )}
                </I18nHelperContext.Consumer>
            </I18nHelperContextProvider>
        )
    }
}

const TopSection = (props) => {
    return (
        <div className="mb-2">
            <div className='d-flex justify-content-between'>
                <SpordleTableProvider.SearchInput />
                <div className='d-flex ml-auto text-right'>
                    <SpordleTableProvider.Refresh />
                    <Button color='primary' className='ml-2' onClick={props.toggleAddTeamCategoryType}><i className='mdi mdi-plus' /> <Translate id='misc.add' /></Button>
                    <TeamCategoryTypesAddModal addTeamCategoryTypeModalOpen={props.addTeamCategoryTypeModalOpen} toggleAddTeamCategoryType={props.toggleAddTeamCategoryType} />
                </div>
            </div>
        </div>
    )
}

export default withContexts(TeamsContext, OrganizationContext, IdentityRolesContext, I18nContext)(OrganizationTeamCategoryTypes);