import { FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import { stringBuilder } from '@spordle/helpers';
// Language
import Translate from '@spordle/intl-elements';
import { formatSelectData } from "@spordle/spordle-select";
import { Form, Formik } from 'formik';
import React from "react";
import {
    Button, Card, CardBody, CardFooter, CardHeader, Col, Collapse, Fade, FormGroup, Row
} from 'reactstrap';
import { string, object, array, boolean } from 'yup';
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import CardSectionTitle from "../../../../../components/CardSectionTitle";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import { fail } from "@spordle/toasts";
import { I18nContext } from "../../../../../contexts/I18nContext";
// Contexts
import { MembersContext } from '../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { UtilsContext } from "../../../../../contexts/UtilsContext";
import { RolesContext } from "../../../../../contexts/RolesContext";
import { displayI18n, DisplayI18n } from "../../../../../helpers/i18nHelper";
import withContexts from '../../../../../helpers/withContexts';
import { PeriodsContext } from '../../../../../contexts/contexts';
import CrossFade from '../../../../../components/crossFade/CrossFade';
import CustomAlert from '../../../../../components/CustomAlert';
import { isARequiredSpecialField } from '@spordle/cart-engine';
import MemberGeneralEditTopSection from './MemberGeneralEditTopSection';
import IsGod from '../../../../../components/permissions/IsGod';
import MemberFieldLabel from '../../../../../components/memberRegistration/MemberFieldLabel';

class MemberDemographicInfo extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            edit: false,
            countries: [],
            selectedEthnicity: null,

        };
    }

    toggleEdit = () => {
        this.setState((prevState) => ({ edit: !prevState.edit, selectedEthnicity: null }))
    }

    componentDidMount(){
        this.props.UtilsContext.getCountries()
            .then((countries) => {
                const collator = new Intl.Collator(this.props.I18nContext.getGenericLocale(), { sensitivity: 'base' });

                const formattedCountries = formatSelectData(
                    countries.sort((a, b) => {
                        if(a.code === 'ZZ')return 1
                        if(b.code === 'ZZ')return -1
                        const countryA = displayI18n('name', a.i18n, a.code, this.props.I18nContext.getGenericLocale());
                        const countryB = displayI18n('name', b.i18n, b.code, this.props.I18nContext.getGenericLocale());

                        return collator.compare(countryA, countryB);
                    }),
                    {
                        getGroupId: (c) => [ 'CA', 'US', 'ZZ' ].includes(c.code) ? 'suggested' : undefined,
                        getValue: (c) => c.code,
                        getLabel: (c) => c.code,
                        newGroupIndexes: {
                            0: {
                                groupId: 'suggested',
                                label: 'misc.select.suggested',
                            },
                        },
                    },
                );

                this.setState((prevState) => ({ countries: formattedCountries }))
            })
            .catch((error) => {
                console.error(error)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            })
    }

    canModifyMemberField = (code) => {
        return !this.props.OrganizationContext.settings?.request_mandatory_change_fields?.value?.some((field) => (field === code))
    }

    render(){
        const currentBirthCountry = this.state.countries.find((country) => country.value === this.props.MembersContext.currentMember.birth_country);
        return (
            <>
                {!this.state.edit ?
                    <Fade>
                        <Card id='profileComponentMemberPrimaryInfo' body className={stringBuilder({ 'shadow-danger card-error': this.props.showRedBorder }, 'card-shadow')}>
                            <CardSectionTitle className="d-flex" title='members.profile.overview.demographicInfo.title'>
                                {!this.props.MembersContext.currentMember.minimalistic_view &&
                                    <CanDoAction className='ml-auto text-link' action='EDIT' componentCode='members' componentPermissionCode='members_profile'>
                                        <button className="reset-btn" type="button" onClick={this.toggleEdit}><Translate id='misc.edit' /></button>
                                    </CanDoAction>
                                }
                            </CardSectionTitle>
                            <Row>
                                <Col md='6' className="mb-3">
                                    <MemberFieldLabel
                                        className="text-muted mb-0"
                                        hideDescription
                                        field="birth_country"
                                        fallbackLabel={<Translate id='form.fields.birth_country' />}
                                        withSpecialRequired={!this.props.MembersContext.currentMember.birth_country}
                                    />
                                    {this.props.MembersContext.currentMember.birth_country ?
                                        <div className='font-medium' id='memberInfo-birthCountry'><DisplayI18n field='name' defaultValue={currentBirthCountry?.label} i18n={currentBirthCountry?.i18n} /></div>
                                        :
                                        "-"
                                    }
                                </Col>
                                <Col md='6' className="mb-3">
                                    <MemberFieldLabel
                                        className="text-muted mb-0"
                                        hideDescription
                                        field="citizenship"
                                        fallbackLabel={<Translate id='form.fields.nationality' />}
                                        withSpecialRequired={this.props.MembersContext.currentMember.citizenships?.length === 0}
                                    />
                                    <span id='memberInfo-citizenships'>
                                        {this.props.MembersContext.currentMember.citizenships?.length === 0 ?
                                            <span className='font-medium'>-</span>
                                            :
                                            this.props.MembersContext.currentMember.citizenships?.map((citizenship, key) => {
                                                const nationality = this.state.countries.find((country) => country.code === citizenship)
                                                return (
                                                // eslint-disable-next-line react/no-array-index-key
                                                    <span key={key}>
                                                        <span className='font-medium' data-citizenshipcode={nationality?.code}><DisplayI18n field='citizenship_name' defaultValue={nationality?.label} i18n={nationality?.i18n} /></span>
                                                        {key !== this.props.MembersContext.currentMember.citizenships.length - 1 ? ', ' : ''}
                                                    </span>
                                                )
                                            })
                                        }
                                    </span>
                                </Col>
                                <Col sm='6' className="mb-3">
                                    <MemberFieldLabel
                                        className="text-muted mb-0"
                                        hideDescription
                                        field="ethnicity_id"
                                        fallbackLabel={<Translate id='form.fields.ethnicity' />}
                                        withSpecialRequired={!this.props.MembersContext.currentMember.ethnicity}
                                    />
                                    <div className='font-medium' id='memberInfo-ethnicity'>
                                        {this.props.MembersContext.currentMember.ethnicity ?
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={this.props.MembersContext.currentMember.ethnicity.name}
                                                i18n={this.props.MembersContext.currentMember.ethnicity.i18n}
                                            />
                                            :
                                            '-'
                                        }
                                    </div>
                                </Col>
                                {this.props.MembersContext.currentMember.ethnicity?.with_note == 1 && this.props.MembersContext.currentMember.ethnicity_note &&
                                    <Col sm='6' className="mb-3">
                                        <MemberFieldLabel
                                            className="text-muted mb-0"
                                            hideDescription
                                            field="ethnicity_note"
                                            fallbackLabel={<Translate id='form.fields.ethnicityNote' />}
                                            withSpecialRequired={!this.props.MembersContext.currentMember.ethnicity_note}
                                        />
                                        <div className='font-medium' id='memberInfo-ethnicityNote'>{this.props.MembersContext.currentMember.ethnicity_note}</div>
                                    </Col>
                                }
                                <Col sm='6'>
                                    <div className='mb-3'>
                                        <MemberFieldLabel
                                            className="text-muted mb-0"
                                            hideDescription
                                            field="identify_as_indigenous"
                                            fallbackLabel={<Translate id='form.fields.identifyAsIndigenous' />}
                                            withSpecialRequired={!this.props.MembersContext.currentMember.identify_as_indigenous}
                                        />
                                        <div className='font-medium' id='memberInfo-identifyAsIndigenous'>
                                            {this.props.MembersContext.currentMember.identify_as_indigenous ?
                                                <Translate id={'misc.' + this.props.MembersContext.currentMember.identify_as_indigenous.toLowerCase()} />
                                                :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                </Col>
                                {this.props.MembersContext.currentMember.identify_as_indigenous === 'YES' &&
                                    <Col sm='6' className="mb-3">
                                        <MemberFieldLabel
                                            className="text-muted mb-0"
                                            hideDescription
                                            field="indigenous_group_id"
                                            fallbackLabel={<Translate id='form.fields.indigenousGroup' />}
                                            withSpecialRequired={!this.props.MembersContext.currentMember.indigenous_group}
                                        />
                                        <div className='font-medium' id='memberInfo-indigenousGroup'>
                                            {this.props.MembersContext.currentMember.indigenous_group ?
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={this.props.MembersContext.currentMember.indigenous_group.name}
                                                    i18n={this.props.MembersContext.currentMember.indigenous_group.i18n}
                                                />
                                                :
                                                '-'
                                            }
                                        </div>
                                    </Col>
                                }
                                {this.props.MembersContext.currentMember.identify_as_indigenous === 'YES' && this.props.MembersContext.currentMember.indigenous_group_note &&
                                    <Col sm={6}>
                                        <MemberFieldLabel
                                            className="text-muted mb-0"
                                            hideDescription
                                            field="indigenous_group_note"
                                            fallbackLabel={<Translate id="form.fields.identityGroupIndigenous" />}
                                            withSpecialRequired={!this.props.MembersContext.currentMember.indigenous_group_note}
                                        />
                                        <div className='font-medium' id='memberInfo-indigenousGroupNote'>
                                            {this.props.MembersContext.currentMember.indigenous_group_note || '-'}
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </Card>
                    </Fade>
                    :
                    <Formik
                        initialValues={{
                            nationality: this.props.MembersContext.currentMember.citizenships || [],
                            birth_country: this.props.MembersContext.currentMember.birth_country || '',
                            identifyAsIndigenous_with_note: this.props.MembersContext.currentMember.indigenous_group?.with_note == 1,
                            identifyAsIndigenous: this.props.MembersContext.currentMember.identify_as_indigenous || '',
                            indigenousGroupId: this.props.MembersContext.currentMember.indigenous_group?.indigenous_group_id || '',
                            indigenous_group_note: this.props.MembersContext.currentMember.indigenous_group_note || "",
                            ethnicityId: this.props.MembersContext.currentMember.ethnicity?.ethnicity_id || '',
                            ethnicityNote: this.props.MembersContext.currentMember.ethnicity_note || '',
                            enableRequestChangeFields: false, // state here because it is easier to manage inside formik
                        }}
                        validationSchema={object().shape({
                            nationality: array().when('does_not_matter', {
                                is: () => isARequiredSpecialField('citizenship', this.props.OrganizationContext.settings),
                                then: array().min(1, <Translate id='form.validation.nationality.required' />),
                            }),
                            birth_country: string().isRequiredSpecialField(isARequiredSpecialField('birth_country', this.props.OrganizationContext.settings), <Translate id='form.validation.birth_country.required' />),

                            identifyAsIndigenous: string().isRequiredSpecialField(isARequiredSpecialField('identify_as_indigenous', this.props.OrganizationContext.settings), <Translate id='form.fields.identifyAsIndigenous.required' />),
                            indigenousGroupId: string().when('identifyAsIndigenous', {
                                is: 'YES',
                                then: string().required(<Translate id='form.fields.indigenousGroup.required' />),
                            }),
                            identifyAsIndigenous_with_note: boolean(),
                            indigenous_group_note: string(),

                            ethnicityId: string().isRequiredSpecialField(isARequiredSpecialField('ethnicity_id', this.props.OrganizationContext.settings), <Translate id='form.validation.ethnicity.required' />),
                            ethnicityNote: string(),
                        })}
                        onSubmit={({ member_types, ...values }, { setStatus, setSubmitting }) => {
                            const currentMember = this.props.MembersContext.currentMember
                            const apiValues = {
                                citizenship: (this.canModifyMemberField("citizenship") || values.enableRequestChangeFields) && values.nationality?.join() !== currentMember.citizenships?.join() ? values.nationality?.join() : null,
                                birth_country: (this.canModifyMemberField("birth_country") || values.enableRequestChangeFields) && values.birth_country !== currentMember.birth_country ? values.birth_country : null,
                                identify_as_indigenous: (this.canModifyMemberField("identify_as_indigenous") || values.enableRequestChangeFields) && values.identifyAsIndigenous !== (currentMember.identify_as_indigenous || '') ? values.identifyAsIndigenous : null,
                                indigenous_group_id: (this.canModifyMemberField("indigenous_group_id") || values.enableRequestChangeFields) && values.identifyAsIndigenous === 'YES' && values.indigenousGroupId !== (currentMember.indigenous_group?.indigenous_group_id || '') ? values.indigenousGroupId : null,
                                indigenous_group_note: (this.canModifyMemberField("indigenous_group_note") || values.enableRequestChangeFields) && values.identifyAsIndigenous === 'YES' && values.indigenous_group_note !== (currentMember.indigenous_group_note || '') ? values.indigenous_group_note : null,
                                ethnicity_id: (this.canModifyMemberField("ethnicity_id") || values.enableRequestChangeFields) && values.ethnicityId !== (currentMember.ethnicity?.ethnicity_id || '') ? values.ethnicityId : null,
                                ethnicity_note: (this.canModifyMemberField("ethnicity_note") || values.enableRequestChangeFields) && values.ethnicityNote !== (currentMember.ethnicity_note || '') ? values.ethnicityNote : null,
                            }

                            this.props.MembersContext.partiallyUpdateMember(this.props.MembersContext.currentMember.member_id, apiValues)
                                .then(async() => {
                                    await this.props.MembersContext.getAllMemberInfos(this.props.MembersContext.currentMember.member_id)
                                    this.setState(() => ({ edit: false }))
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message);

                                        if(error.i18n){
                                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                        }else{
                                            setStatus(error.message);
                                        }

                                        setSubmitting(false);
                                    }
                                })
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Card className='card-shadow shadow-primary'>
                                    <OverlayLoader isLoading={formik.isSubmitting}>
                                        <CardHeader>
                                            <div className="d-flex align-items-center">
                                                <div className="h4 font-bold mb-0"><Translate id='members.profile.overview.demographicInfo.edit.title' /></div>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <CanDoAction action='EDIT' componentCode='members' componentPermissionCode='require_mandatory_fields'>
                                                {this.props.OrganizationContext.settings?.request_mandatory_change_fields?.value?.length > 0 && this.props.showRequestChange &&
                                                    <MemberGeneralEditTopSection refreshMemberProfile={this.props.refreshMemberProfile} />
                                                }
                                            </CanDoAction>

                                            <Row form>
                                                <Col md='6' className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="birth_country"
                                                        fallbackLabel={<Translate id='form.fields.birth_country' />}
                                                        withSpecialRequired
                                                        for='birth_country'
                                                    />
                                                    <FormikSelect
                                                        name="birth_country"
                                                        id="birth_country"
                                                        clearable
                                                        placeholder='form.fields.birth_country.placeholder'
                                                        disabled={!this.canModifyMemberField("birth_country") && !formik.values.enableRequestChangeFields}
                                                        loadingStatus='success'
                                                        renderOption={(option) => option.option.isGroup ? <Translate id={option.option.label} /> : <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                        searchKeys={[
                                                            `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                        ]}
                                                        options={this.state.countries}
                                                    />
                                                </Col>
                                                <Col md='6' className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="citizenship"
                                                        fallbackLabel={<Translate id='form.fields.nationality' />}
                                                        withSpecialRequired
                                                        for='nationality'
                                                    />
                                                    <FormikSelect
                                                        multi
                                                        hideSelectAll
                                                        clearable
                                                        name="nationality"
                                                        id="nationality"
                                                        placeholder='form.fields.nationality.placeholder'
                                                        disabled={!this.canModifyMemberField("citizenship") && !formik.values.enableRequestChangeFields}
                                                        renderOption={(option) => option.option.isGroup ? <Translate id={option.option.label} /> : <DisplayI18n field='citizenship_name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                        searchKeys={[
                                                            `i18n.${this.props.I18nContext.getGenericLocale()}.citizenship_name`,
                                                        ]}
                                                        options={this.state.countries}
                                                    />
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row form>
                                                <Col sm='6' className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="ethnicity_id"
                                                        fallbackLabel={<Translate id='form.fields.ethnicity' />}
                                                        withSpecialRequired
                                                        for="ethnicityId"
                                                    />
                                                    <FormikSelect
                                                        id='ethnicityId' name='ethnicityId'
                                                        clearable
                                                        disabled={!this.canModifyMemberField("ethnicity_id") && !formik.values.enableRequestChangeFields}
                                                        loadData={(from) => {
                                                            switch (from){
                                                                case 'CDM':
                                                                    return this.props.UtilsContext.getEthnicities()
                                                                        .then((ethnicities) => {
                                                                            return ethnicities.sort((a, b) => a.display_order - b.display_order).reduce((newArray, ethnicity) => {
                                                                                const formattedEthnicity = {
                                                                                    value: ethnicity.ethnicity_id,
                                                                                    label: ethnicity.name,
                                                                                    i18n: ethnicity.i18n,
                                                                                    withNote: ethnicity.with_note == 1,
                                                                                }
                                                                                if(formattedEthnicity.value === formik.initialValues.ethnicityId){
                                                                                    this.setState(() => ({ selectedEthnicity: formattedEthnicity }))
                                                                                }
                                                                                newArray.push(formattedEthnicity)
                                                                                return newArray
                                                                            }, [])
                                                                        })
                                                                default:
                                                                    break;
                                                            }
                                                        }}
                                                        renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                        onOptionSelected={(values, spordleSelect) => {
                                                            this.setState(() => ({
                                                                selectedEthnicity: spordleSelect.getSpordleTable().getData().find((data) => data.value === values[0]),
                                                            }))
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm={6}>
                                                    <CrossFade isVisible={!!this.state.selectedEthnicity?.withNote}>
                                                        <MemberFieldLabel
                                                            className="text-muted"
                                                            hideDescription
                                                            field="ethnicity_note"
                                                            fallbackLabel={<Translate id='form.fields.ethnicityNote' />}
                                                            withSpecialRequired
                                                            for="ethnicityNote"
                                                        />
                                                        <FormikTextArea id='ethnicityNote' name='ethnicityNote' rows={1} trim />
                                                    </CrossFade>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row form>
                                                <Col sm='6' className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="identify_as_indigenous"
                                                        fallbackLabel={<Translate id='form.fields.identifyAsIndigenous' />}
                                                        withSpecialRequired
                                                        for="identifyAsIndigenous"
                                                    />
                                                    <FormikSelect
                                                        id='identifyAsIndigenous'
                                                        name='identifyAsIndigenous'
                                                        clearable
                                                        disabled={!this.canModifyMemberField("identify_as_indigenous") && !formik.values.enableRequestChangeFields}
                                                        search={false}
                                                        defaultData={[
                                                            { label: 'misc.yes', value: 'YES' },
                                                            { label: 'misc.no', value: 'NO' },
                                                            { label: 'misc.prefer_not_to_say', value: 'PREFER_NOT_TO_SAY' },
                                                        ]}
                                                        onOptionSelected={([ val ]) => {
                                                            if(val !== "YES"){
                                                                formik.setFieldValue("indigenousGroupId", "");
                                                                formik.setFieldValue("indigenous_group_note", "");
                                                                formik.setFieldValue("identifyAsIndigenous_with_note", false);
                                                            }
                                                        }}
                                                        renderOption={(option) => <Translate id={option.option.label} />}
                                                        loadingStatus="success"
                                                    />
                                                </Col>
                                                <Col sm='6'>
                                                    <CrossFade isVisible={formik.values.identifyAsIndigenous === 'YES'}>
                                                        <FormGroup>
                                                            <MemberFieldLabel
                                                                className="text-muted"
                                                                hideDescription
                                                                field="indigenous_group_id"
                                                                fallbackLabel={<Translate id='form.fields.indigenousGroup' />}
                                                                withSpecialRequired
                                                                isRequired
                                                                for="indigenousGroupId"
                                                            />
                                                            <FormikSelect
                                                                id='indigenousGroupId' name='indigenousGroupId'
                                                                clearable
                                                                disabled={!this.canModifyMemberField("indigenous_group_id") && !formik.values.enableRequestChangeFields}
                                                                loadData={(from) => {
                                                                    switch (from){
                                                                        case 'CDM':
                                                                            return this.props.UtilsContext.getIndigenousGroups()
                                                                                .then((groups) => {
                                                                                    return groups.sort((a, b) => a.display_order - b.display_order).map((group) => ({
                                                                                        ...group,
                                                                                        value: group.indigenous_group_id,
                                                                                        label: group.name,
                                                                                        i18n: group.i18n,
                                                                                    }))
                                                                                })
                                                                        default:
                                                                            break;
                                                                    }
                                                                }}
                                                                onOptionSelected={([ val ], select) => {
                                                                    const indigenousGroup = select.getSpordleTable().getData().find(({ value }) => value === val);
                                                                    const withNote = indigenousGroup?.with_note == 1;

                                                                    formik.setFieldValue("identifyAsIndigenous_with_note", withNote);

                                                                    if(!withNote){
                                                                        formik.setFieldValue('indigenous_group_note', "");
                                                                    }

                                                                    formik.setFieldValue("indigenousGroupId", val || "");
                                                                    return true;
                                                                }}
                                                                renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                            />
                                                        </FormGroup>
                                                    </CrossFade>
                                                </Col>
                                                <Col sm={6}>
                                                    <CrossFade isVisible={formik.values.identifyAsIndigenous_with_note}>
                                                        <MemberFieldLabel
                                                            id='indigenous_group_note'
                                                            field='indigenous_group_note'
                                                            for='indigenous_group_note-input'
                                                            className="text-muted"
                                                            fallbackLabel={<Translate id="form.fields.identityGroupIndigenous" />}
                                                        />
                                                        <FormikTextArea
                                                            id='indigenous_group_note-input'
                                                            name='indigenous_group_note'
                                                            trim
                                                            rows={1}
                                                            disabled={!this.canModifyMemberField("indigenous_group_note") && !formik.values.enableRequestChangeFields}
                                                        />
                                                    </CrossFade>
                                                </Col>
                                            </Row>
                                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                                {formik.status &&
                                                    <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                                }
                                            </Collapse>
                                        </CardBody>
                                        <CardFooter className='bg-white d-flex justify-content-between'>
                                            {this.props.OrganizationContext.settings?.request_mandatory_change_fields?.value?.length > 0 && this.props.showRequestChange &&
                                                <IsGod>
                                                    <Button type='button' color="primary" disabled={formik.isSubmitting} onClick={() => formik.setFieldValue('enableRequestChangeFields', !formik.values.enableRequestChangeFields)}>
                                                        <Translate id={formik.values.enableRequestChangeFields ? 'members.profile.overview.memberPrimaryInformation.edit.disableFields' : 'members.profile.overview.memberPrimaryInformation.edit.enableFields'} />
                                                    </Button>
                                                </IsGod>
                                            }
                                            <div className='ml-auto'>
                                                <Button type='submit' color="primary" disabled={formik.isSubmitting}>
                                                    <Translate id='members.profile.overview.memberPrimaryInformation.edit.save' />
                                                </Button>
                                                <Button type='button' className='ml-2' color="primary" outline onClick={this.toggleEdit} disabled={formik.isSubmitting}>
                                                    <Translate id='members.profile.overview.memberPrimaryInformation.edit.cancel' />
                                                </Button>
                                            </div>
                                        </CardFooter>
                                    </OverlayLoader>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                }
            </>
        )
    }
}

export default withContexts(MembersContext, UtilsContext, I18nContext, UtilsContext, OrganizationContext, PeriodsContext, RolesContext)(MemberDemographicInfo);