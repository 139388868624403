import { useSpordleTable } from '@spordle/datatables';
import { FormikDateTime, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { formatSelectData } from '@spordle/spordle-select';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Card, Col, Collapse, FormGroup, Label, Row } from 'reactstrap';
import { mixed, object, string } from 'yup';
import { PeriodsContext } from '../../../contexts/contexts';
import { I18nContext } from '../../../contexts/I18nContext';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { displayI18n, DisplayI18n } from '../../../helpers/i18nHelper';
import queryString from 'query-string';
import useSavedSearch from '../../../components/customHooks/useSavedSearch';
import CrossFade from '../../../components/crossFade/CrossFade';
import SavedSearchCue from '../../../components/savedSearchCue/SavedSearchCue';

const InsuranceSearchFilter = ({ status, canSeeSensitive }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { federation, isGod, identity_role_id } = useContext(IdentityRolesContext);
    const { getOrganizationBySettings, organisation_id } = useContext(OrganizationContext);
    const { activePeriods, selectedPeriod } = useContext(PeriodsContext);
    const formikRef = useRef();
    const spordleTable = useSpordleTable();
    const filters = spordleTable.getFilters();
    const canSeeSensitiveFilters = canSeeSensitive || isGod();
    const location = useLocation();
    const history = useHistory();
    const [ formikKey, setFormikKey ] = useState(0);

    const { savedSearch, clearSavedSearch, saveSearch } = useSavedSearch(`insurance-search-filters-${identity_role_id}-${organisation_id}-${selectedPeriod.period_id}`);

    const getOrgs = async() => {
        const orgs = await getOrganizationBySettings(federation.organisation_id, { setting_code: "office_list_insurance" });
        const sortedOrgs = orgs.sort((a, b) => {
            const aOrgName = displayI18n('name', a.i18n, a.organisation_name, getGenericLocale());
            const bOrgName = displayI18n('name', b.i18n, b.organisation_name, getGenericLocale());

            return aOrgName.localeCompare(bOrgName);
        });

        return formatSelectData(
            sortedOrgs,
            {
                getValue: (org) => org.organisation_id,
                getLabel: (org) => org.organisation_name,
                getGroupId: (org) => org.organisation_category.organisation_category_id,
                createGroupWithId: (org) => ({
                    ...org.organisation_category,
                    label: org.name,
                }),
            },
        );

    }

    useEffect(() => {
        const hcrNb = filters.unique_identifier;

        if(hcrNb && formikRef.current && hcrNb !== formikRef.current.values.unique_identifier){
            formikRef.current.setFieldValue('unique_identifier', hcrNb);
        }

    }, [ filters?.unique_identifier ]);

    useEffect(() => {
        const hcrNb = queryString.parse(location.search)?.search;

        if(hcrNb && formikRef.current && hcrNb !== formikRef.current.values.unique_identifier){
            spordleTable.filterChange('unique_identifier', hcrNb);
            const parsedUrl = queryString.parseUrl(`${location.pathname}${location.search}${location.hash}`, { parseFragmentIdentifier: true/* parse hash(#) */ })
            // cleaning the URL
            delete parsedUrl.query.search;
            history.replace(queryString.stringifyUrl(parsedUrl));
        }

    }, []);

    const rerenderFormik = () => {
        clearSavedSearch();
        setFormikKey(formikKey + 1);
    }

    const originalValues = {
        first_name: '',
        last_name: '',
        unique_identifier: '',
        office_id: '',
        period_id: '',
        injury_status_id: '',
        date_range_start: '',
        date_range_end: '',
        created_date_from: '',
        created_date_to: '',
    }

    const getInitFilters = () => {
        const filters = savedSearch;
        if(filters){
            return {
                ...originalValues,
                ...filters,
                office_id: (canSeeSensitiveFilters && filters.office_id) ? filters.office_id : '',
                injury_status_id: (canSeeSensitiveFilters && filters.injury_status_id) ? filters.injury_status_id : '',
                date_range_start: !filters.date_range_start ? '' : moment(filters.date_range_start),
                date_range_end: !filters.date_range_end ? '' : moment(filters.date_range_end),
                created_date_from: !filters.created_date_from ? '' : moment(filters.created_date_from),
                created_date_to: !filters.created_date_to ? '' : moment(filters.created_date_to),
                approved: 'ALL',
            };
        }

        return originalValues;
    }

    return (
        <Card body className='card-shadow'>
            <Formik
                key={formikKey}
                innerRef={formikRef}
                initialValues={getInitFilters()}
                validationSchema={object().shape({
                    first_name: string().when([ 'unique_identifier', 'created_date_to', 'created_date_from', 'injury_status_id', 'date_range_start', 'date_range_end', 'office_id' ], {
                        is: (val, to, from, status, accidentStart, accidentEnd, officeId) => !val && !to && !from && !status && !accidentStart && !accidentEnd && !officeId,
                        then: (schema) => schema.required(<Translate id='form.validation.firstName.required' />),
                    }),
                    last_name: string().when([ 'unique_identifier', 'created_date_to', 'created_date_from', 'injury_status_id', 'date_range_start', 'date_range_end', 'office_id' ], {
                        is: (val, to, from, status, accidentStart, accidentEnd, officeId) => !val && !to && !from && !status && !accidentStart && !accidentEnd && !officeId,
                        then: (schema) => schema.required(<Translate id='form.validation.lastName.required' />),
                    }),
                    injury_status_i: string(),
                    office_id: string(),
                    unique_identifier: string(),
                    period_id: string(),
                    date_range_end: mixed().isDate().when('date_range_start', {
                        is: (startDate) => startDate, // if a searchType is specified AND if member number is empty
                        then: mixed().required(<Translate id='form.required' />).test({
                            name: 'date_range_end-is_after',
                            message: <Translate id='insurance.search.accidentDate.end.validation.after' />,
                            test: function(endDate){
                                return !moment.isMoment(endDate) || endDate.isAfter(this.parent.date_range_start);
                            },
                        }),
                    }),
                    date_range_start: mixed().isDate().when('date_range_end', {
                        is: (endDate) => endDate, // if a searchType is specified AND if member number is empty
                        then: mixed().required(<Translate id='form.required' />).test({
                            name: 'date_range_start-is_before',
                            message: <Translate id='insurance.search.accidentDate.start.validation.before' />,
                            test: function(startDate){
                                return !moment.isMoment(startDate) || startDate.isBefore(this.parent.date_range_end);
                            },
                        }),
                    }),
                    created_date_from: mixed().isDate().when('created_date_to', {
                        is: (dateTo) => dateTo,
                        then: (schema) => schema.required(<Translate id='form.required' />).test({
                            name: 'isBefore',
                            message: <Translate id='insurance.search.accidentDate.start.validation.before' />,
                            test: function(date){
                                return !moment.isMoment(date) || date.isSameOrBefore(this.parent.created_date_to, "days");
                            },
                        }),
                    }),
                    created_date_to: mixed().isDate().when('created_date_from', {
                        is: (dateFrom) => dateFrom, // if a searchType is specified AND if member number is empty
                        then: (schema) => schema.required(<Translate id='form.required' />).test({
                            name: 'date_to-is_after',
                            message: <Translate id='insurance.search.accidentDate.end.validation.after' />,
                            test: function(endDate){
                                return !moment.isMoment(endDate) || endDate.isSameOrAfter(this.parent.created_date_from, "days");
                            },
                        }),
                    }),
                }, [ [ 'date_range_end', 'date_range_start' ], [ 'created_date_to', 'created_date_from' ] ])}
                onSubmit={async(values) => {
                    spordleTable.filterChange('injury_status_id', values.injury_status_id, false);
                    spordleTable.filterChange('office_id', values.office_id, false);
                    spordleTable.filterChange('date_range', [ values.date_range_start.format?.('YYYY-MM-DD'), values.date_range_end.format?.('YYYY-MM-DD') ], false);
                    if(values.approved === 'ALL'){
                        spordleTable.filterChange('approved', '', false);
                    }else{
                        spordleTable.filterChange('approved', values.approved, false);
                    }

                    if(moment.isMoment(values.created_date_from) && moment.isMoment(values.created_date_to)){
                        spordleTable.filterChange('created_date_from', values.created_date_from.format('YYYY-MM-DD'), false);
                        spordleTable.filterChange('created_date_to', values.created_date_to.format('YYYY-MM-DD'), false);
                    }

                    if(values.unique_identifier){
                        spordleTable.filterChange('unique_identifier', values.unique_identifier, false);
                        spordleTable.filterChange('first_name', "", false);
                        spordleTable.filterChange('last_name', "", false);
                    }else{
                        spordleTable.filterChange('unique_identifier', "", false);
                        spordleTable.filterChange('first_name', values.first_name, false);
                        spordleTable.filterChange('last_name', values.last_name, false);
                    }

                    spordleTable.filterChange('period_id', values.period_id);

                    saveSearch(values)
                }}
            >
                {(formik) => (
                    <Form>
                        <Row form>
                            {canSeeSensitiveFilters &&
                                <Col sm={6} lg={3}>
                                    <FormGroup>
                                        <Label className={!canSeeSensitive ? "text-purple" : "text-muted"} for="office_id">Office</Label>
                                        <FormikSelect
                                            name="office_id"
                                            id="office_id"
                                            clearable
                                            renderSelectedOption={(opt) => (
                                                <DisplayI18n
                                                    field="name"
                                                    defaultValue={opt.label}
                                                    i18n={opt.i18n}
                                                />
                                            )}
                                            renderOption={(opt) => (
                                                <>
                                                    {opt.option.isGroup && <span><Translate id='insurance.label.category' />: </span>}
                                                    <span className={stringBuilder({ "font-medium pt-2": opt.option.isGroup })}>
                                                        <DisplayI18n
                                                            field="name"
                                                            defaultValue={opt.option.label}
                                                            i18n={opt.option.i18n}
                                                        />
                                                    </span>
                                                    {!opt.option.isGroup && (
                                                        <small className="d-block text-muted">
                                                            {opt.option.organisation_parent?.abbreviation}
                                                        </small>
                                                    )}
                                                </>
                                            )}
                                            searchKeys={[
                                                `i18n.${getGenericLocale()}.name`,
                                                `organisation_category.i18n.${getGenericLocale()}.name`,
                                                `organisation_parent.abbreviation`,
                                            ]}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return getOrgs();
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            }
                            <Col sm="6" lg={3}>
                                <FormGroup>
                                    <Label className="text-muted" for="period_id"><Translate id='form.fields.period' /></Label>
                                    <FormikSelect
                                        id="insurance-period-id"
                                        name="period_id"
                                        clearable
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        renderOption={({ option }) => (
                                            <DisplayI18n
                                                field="name"
                                                i18n={option.i18n}
                                                defaultValue={option.label}
                                            />
                                        )}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return Promise.resolve((activePeriods || []).map((period) => ({ value: period.period_id, label: period.name, i18n: period.i18n })))
                                                default:
                                                    break;
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            {canSeeSensitiveFilters &&
                                <Col sm="6" lg={3}>
                                    <FormGroup>
                                        <Label className={!canSeeSensitive ? "text-purple" : "text-muted"} for='insurance-statuses'><Translate id='misc.status' /></Label>
                                        <FormikSelect
                                            name='injury_status_id'
                                            id='insurance-statuses'
                                            search={false}
                                            clearable
                                            isLoading={!status}
                                            options={status ? status.map((stat) => ({ value: stat.injury_status_id, label: stat.name, i18n: stat.i18n })) : []}
                                            renderOption={({ option }) => <DisplayI18n field='name' i18n={option.i18n} defaultValue={option.label} />}
                                        />
                                    </FormGroup>
                                </Col>
                            }
                            <Col sm="6" lg={3}>
                                <FormGroup>
                                    <Label className={"text-muted"} for='insurance-approved'><Translate id='insurance.search.accidentDate.approved.label' /></Label>
                                    <FormikSelect
                                        name='approved'
                                        id='insurance-approved'
                                        search={false}
                                        options={[
                                            { label: <Translate id='misc.yes' />, value: '1' },
                                            { label: <Translate id='misc.no' />, value: '0' },
                                            { label: <Translate id='misc.all' />, value: 'ALL' },
                                        ]}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6" lg={3}>
                                <FormGroup>
                                    <Label className="text-muted" for='insurance-date_range-start'><Translate id='insurance.search.accidentDate.start.label' /></Label>
                                    <FormikDateTime placeholder='form.validation.date.placeholder' name='date_range_start' inputProps={{ id: 'insurance-date_range-start', autoComplete: "none" }} timeFormat={false} />
                                </FormGroup>
                            </Col>
                            <Col sm="6" lg="3">
                                <FormGroup>
                                    <Label className="text-muted" for='insurance-date_range-end'><Translate id='insurance.search.accidentDate.end.label' /></Label>
                                    <FormikDateTime placeholder='form.validation.date.placeholder' name='date_range_end' inputProps={{ id: 'insurance-date_range-end', autoComplete: "none" }} timeFormat={false} />
                                </FormGroup>
                            </Col>
                            <Col sm="6" lg="3">
                                <FormGroup>
                                    <Label className="text-muted" for='created_date_from'><Translate id="insurance.search.createdDate.start.label" /></Label>
                                    <FormikDateTime placeholder='form.validation.date.placeholder' name='created_date_from' inputProps={{ id: 'insurance-created_date_from', autoComplete: "none" }} timeFormat={false} />
                                </FormGroup>
                            </Col>
                            <Col sm="6" lg="3">
                                <FormGroup>
                                    <Label className="text-muted" for='created_date_to'><Translate id="insurance.search.createdDate.end.label" /></Label>
                                    <FormikDateTime placeholder='form.validation.date.placeholder' name='created_date_to' inputProps={{ id: 'insurance-created_date_to', autoComplete: "none" }} timeFormat={false} />
                                </FormGroup>
                            </Col>
                            {canSeeSensitiveFilters &&
                                <>
                                    <Col sm={6} lg={3}>
                                        <FormGroup>
                                            <Label className="text-muted" for="first_name"><Translate id='form.fields.firstName' /></Label>
                                            <FormikInputText disabled={!!formik.values.unique_identifier} name="first_name" id="insurance-first-name" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6} lg={3}>
                                        <FormGroup>
                                            <Label className="text-muted" for="last_name"><Translate id='form.fields.lastName' /></Label>
                                            <FormikInputText disabled={!!formik.values.unique_identifier} name="last_name" id="insurance-last-name" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6} lg={3}>
                                        <FormGroup>
                                            <Label className="text-muted" for="unique_identifier"><Translate id='members.search.memberAdvanceSearch.filters.memberNumber' /></Label>
                                            <FormikInputText name="unique_identifier" id="insurance-unique-identifier" />
                                            <Collapse isOpen={!!formik.values.unique_identifier}>
                                                <div className="small text-muted pt-1">
                                                    <i className="mdi mdi-information-outline text-primary" /> <Translate id='insurance.search.uniqueIdentifier.firstLastNameIgnored' />
                                                </div>
                                            </Collapse>
                                        </FormGroup>
                                    </Col>
                                </>
                            }
                            {!canSeeSensitiveFilters &&
                                <>
                                    <Col sm={6} lg={3}>
                                        <FormGroup>
                                            <Label className="text-muted" for="first_name"><Translate id='form.fields.firstName' /></Label>
                                            <FormikInputText disabled={!!formik.values.unique_identifier} name="first_name" id="insurance-first-name" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6} lg={3}>
                                        <FormGroup>
                                            <Label className="text-muted" for="last_name"><Translate id='form.fields.lastName' /></Label>
                                            <FormikInputText disabled={!!formik.values.unique_identifier} name="last_name" id="insurance-last-name" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6} lg={3}>
                                        <FormGroup>
                                            <Label className="text-muted" for="unique_identifier"><Translate id='members.search.memberAdvanceSearch.filters.memberNumber' /></Label>
                                            <FormikInputText name="unique_identifier" id="insurance-unique-identifier" />
                                            <Collapse isOpen={!!formik.values.unique_identifier}>
                                                <div className="small text-muted pt-1">
                                                    <i className="mdi mdi-information-outline text-primary" /> <Translate id='insurance.search.uniqueIdentifier.firstLastNameIgnored' />
                                                </div>
                                            </Collapse>
                                        </FormGroup>
                                    </Col>
                                </>
                            }
                            <Col lg={3} className='ml-auto'>
                                <div className='mb-0 mb-md-3'>
                                    <Label className="text-muted d-none d-md-block">&nbsp;</Label>
                                    <Button id='insuranceSearch' color='primary' block disabled={spordleTable.state.loadingState === 'loading' || spordleTable.state.loadingState === 'lazy'} type='submit'>
                                        <i className='fas fa-search mr-1' /><Translate id='misc.search' />
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <div className="w-100 d-flex flex-wrap flex-md-nowrap justify-content-end">
                            <SavedSearchCue className="w-100 w-md-auto order-1 order-md-0 mt-3 mt-md-0 mr-auto" clearSavedSearch={rerenderFormik} savedSearch={savedSearch} />
                            <CrossFade isVisible={JSON.stringify(originalValues) !== JSON.stringify(formik.values)}>
                                <button type='button' color='primary' className='reset-btn ml-2 text-danger font-12' onClick={rerenderFormik}>
                                    <Translate id='members.search.memberAdvanceSearch.resetFilters' />
                                </button>
                            </CrossFade>
                        </div>
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default InsuranceSearchFilter