export default{
    "members.profile.overview.memberPrimaryInformation.title"                 : "Primary information",
    "members.profile.overview.memberPrimaryInformation.edit.title"            : "Edit primary information",
    "members.profile.overview.memberPrimaryInformation.edit.disabledFields"   : "The disabled fields can be edited with a {request}. The request will need to be approved for the changes to be accepted.",
    "members.profile.overview.memberPrimaryInformation.edit.enableFields"     : "Enable mandatory fields",
    "members.profile.overview.memberPrimaryInformation.edit.disableFields"    : "Disable mandatory fields",
    "members.profile.overview.memberPrimaryInformation.edit.save"             : "Save changes",
    "members.profile.overview.memberPrimaryInformation.edit.saveConfirmTitle" : "Are you sure you want to save?",
    "members.profile.overview.memberPrimaryInformation.edit.saveConfirmDesc"  : "Changes were made to the members' types.",
    "members.profile.overview.memberPrimaryInformation.edit.cancel"           : "Cancel changes",

    "members.profile.overview.memberPrimaryInformation.memberTypes"               : "Member Types",
    "members.profile.overview.memberPrimaryInformation.memberTypes.group.special" : "Special",
    "members.profile.overview.memberPrimaryInformation.memberTypes.group.normal"  : "Normal",

    "members.profile.overview.memberPrimaryInformation.dateOfBirth.range" : "Date must represent an age between 4 and 100 years old",

    "members.profile.overview.demographicInfo.title"      : "Demographic information",
    "members.profile.overview.demographicInfo.edit.title" : "Edit demographic information",
}