import { object } from "yup"
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal"
import FormikModalForm from "../../../../../../components/formik/FormikModalForm"
import ApiErrorMsg from "../../../../../../components/formik/ApiErrorMsg"
import { MembersContext } from "../../../../../../contexts/MembersContext"
import { useContext } from 'react'
import { PeriodsContext } from "../../../../../../contexts/contexts"
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext"
import { useMemberTypeRemovalRequestsSWR } from "../../../../../../api/useSWRHelpers/memberTypeRemovalRequestsSWR"
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI"
import { Button, Card, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Translate from "@spordle/intl-elements"
import FormikApiError from "../../../../../../components/formik/FormikApiError"
import { getMemberTypeKey } from "../../../../../../helpers/memberTypeHelper"
import DisplayMemberTypeIcon from "../../../../../../components/display/DisplayMemberTypeIcon"
import { DisplayI18n } from "../../../../../../helpers/i18nHelper"
import DisplayOrg from "../../../../../../components/DisplayOrg"
import { stringBuilder } from "@spordle/helpers"
import { useFormikContext } from "formik"

const ManageMemberTypesModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal analyticsName="manageMemberTypesModal" isOpen={isOpen}>
            <ManageMemberTypesModalInner {...props} />
        </AnalyticsModal>
    )
}

const ManageMemberTypesModalInner = ({ toggle, memberTypes, defaultMemberMemberTypeId, memberId }) => {
    const membersContext = useContext(MembersContext)
    const { selectedPeriod } = useContext(PeriodsContext);
    const orgContext = useContext(OrganizationContext);

    const { data: memberTypeRemovalRequests, mutate } = useMemberTypeRemovalRequestsSWR(memberId)

    return (
        <FormikModalForm
            toggle={toggle}
            initialValues={{
                member_types: memberTypes.map((type) => ({
                    member_type_id: type.member_type.member_type_id,
                    delete: !!(defaultMemberMemberTypeId) && type.member_member_type_id === defaultMemberMemberTypeId,
                })),
            }}
            validationSchema={object().shape({})}
            onSubmit={(values, { setStatus }) => {
                const memberTypeIds = values.member_types.reduce((newArray, type) => {
                    if(!type.delete){
                        newArray.push(type.member_type_id)
                    }
                    return newArray
                }, [])

                return membersContext.linkMemberTypesToMember(memberId, {
                    member_type_id: memberTypeIds.length > 0 ? memberTypeIds : '',
                    period_id: selectedPeriod.period_id,
                    organisation_id: orgContext.organisation_id,
                })
                    .then(() => {
                        mutate();
                        setStatus({ isSuccess: true });
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            setStatus({
                                isSuccess: false,
                                showError: true,
                                error: Array.isArray(e) ? e : <ApiErrorMsg error={e} />,
                            });
                        }
                    })
            }}
            successText={(formik) => (
                <p>
                    <Translate id='members.profile.memberType.manage.success' />
                </p>
            )}
        >
            {(formik) => (
                <>
                    <ModalHeader toggle={() => toggle()}><Translate id="members.profile.memberType.manage.title" /></ModalHeader>
                    <ModalBody>
                        {memberTypes.map((memberType, index) => {
                            const hasPendingRemovalRequest = memberTypeRemovalRequests?.find((request) => getMemberTypeKey(request) === getMemberTypeKey(memberType))
                            return (
                                <MemberTypeCard
                                    key={getMemberTypeKey(memberType)}
                                    hasPendingRemovalRequest={hasPendingRemovalRequest}
                                    index={index}
                                    memberType={memberType}
                                />
                            )
                        })}
                        <FormikApiError />
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={formik.isSubmitting} type="submit" color="primary">
                            <Translate id="misc.submit" />
                        </Button>
                        <Button disabled={formik.isSubmitting} outline type="button" onClick={() => toggle()} color="primary">
                            <Translate id="misc.cancel" />
                        </Button>
                    </ModalFooter>
                </>
            )}
        </FormikModalForm>
    )
}

const MemberTypeCard = ({ hasPendingRemovalRequest, index, memberType }) => {
    const formik = useFormikContext();

    return (
        <Card
            body
            className={stringBuilder({ 'opacity-50': formik.values.member_types[index].delete, 'mt-3': index > 0 }, "border mb-0 d-flex flex-row card-shadow font-medium text-dark align-items-center")}
        >
            <DisplayMemberTypeIcon memberType={memberType} />
            <div className="d-flex align-items-center flex-wrap">
                <span style={formik.values.member_types[index].delete ? { textDecoration: 'line-through' } : void 0}>
                    <DisplayI18n defaultValue={memberType.member_type.name} i18n={memberType.member_type.i18n} field='name' />
                </span>
                {memberType.organisation &&
                    <div className="text-muted ml-1 small d-flex align-items-center">
                        (<DisplayOrg org={memberType.organisation} />)
                    </div>
                }
                {hasPendingRemovalRequest &&
                    <div className="text-danger small w-100">
                        <Translate id='members.profile.memberType.pendingRemovalRequest' />
                    </div>
                }
            </div>
            {!hasPendingRemovalRequest &&
                <button
                    onClick={() => formik.setFieldValue(`member_types.${index}.delete`, !formik.values.member_types[index].delete)}
                    type="button"
                    className="reset-btn py-0 pl-2 ml-auto"
                >
                    {formik.values.member_types[index].delete ?
                        <i className="text-primary mdi mdi-undo" />
                        :
                        <i className="text-danger mdi mdi-close" />
                    }
                </button>
            }
        </Card>
    )
}

export default ManageMemberTypesModal