export default{
    "components.communications.modal.title"                    : "Send Communication",
    "components.communications.modal.label.subject"            : "Subject",
    "components.communications.modal.validation.subject"       : "Please specify the subject",
    "components.communications.modal.validation.message"       : "Please specify the message",
    "components.communications.modal.label.message"            : "Message",
    "components.communications.modal.label.recipients"         : "Recipient(s)",
    "components.communications.modal.label.no.contacts"        : "No Organization Contacts",
    "components.communications.modal.label.no.contacts.please" : "Please select one or many organizations.",
    "components.communications.modal.label.contacts"           : "Organization Contact(s)",
    "components.communications.modal.warning.text"             : "The email function within {spordle_id} is intended for the purposes of sports management. It is not intended for emailing participants notices of additional services, promotions, third parties promotions, and/or your sport's specific research. Use with discretion and if you are in doubt, contact your branch.",
    "components.communications.modal.warning.label"            : "I understand",
    "components.communications.modal.warning.validation"       : "Please confirm you understand.",

    "components.communications.modal.search.members.toggleAdvanced" : "Advanced Search",
    "components.communications.modal.empty.recipients"              : "No recipients",
    "components.communications.modal.orgs.min"                      : "Please select at least one (1) organization.",
    "components.communications.modal.orgs.contacts.min"             : "You need to select at least one (1) contact.",
    "components.communications.modal.orgs.max"                      : "The number of selected organizations should not exceed 50.",

    "components.communications.modal.label.searchAndAdd" : "Search & Add",
    "components.communications.modal.btn.search"         : "Search & Add",
    "components.communications.modal.btn.error"          : "Error",
    "components.communications.modal.btn.notFound"       : "Not found",
    "components.communications.modal.btn.exists"         : "Already added",
    "components.communications.modal.btn.success"        : "Search and add",

    "components.communications.modal.btn.1" : "Not found",
    "components.communications.modal.btn.2" : "Already added",

    "components.communications.modal.label.invalidRecipients"  : "Invalid Recipient(s)",
    "components.communications.modal.helper.invalidRecipients" : "Some members are invalid and have not configured an email in their account yet. This communication cannot be sent to them. Click on the download button to download the list of invalid recipients.",
    "components.communications.modal.contact.empty"            : "No contact",
    "components.communications.modal.email.none"               : "No email",
    "components.communications.modal.contact.helper"           : "By choosing a contact's or an account's email, you can configure a primary email for this member.",
    "components.communications.modal.contact.choose"           : "Choose a contact's email...",
    "components.communications.modal.contact.apply"            : "Apply",

    "components.communications.modal.confirm.title"               : "Communication Confirm",
    "components.communications.modal.confirm.singleEmail.success" : "Message successfully sent to {email}",
    "components.communications.modal.confirm.singleEmail.fail"    : "Message failed to be sent to {email}",

    "components.communications.modal.confirm.multiEmails.msg"     : "Status of sent emails:",
    "components.communications.modal.confirm.multiEmails.success" : "Message was sent successfully to <b>{count, plural, one {this email} other {these # emails}}</b>",
    "components.communications.modal.confirm.multiEmails.fail"    : "Message couldn't be sent to <b>{count, plural, one {this email} other {these # emails}}</b>",

    "components.communications.modal.confirm.btn" : "Got it",
}