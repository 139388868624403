import { useEffect, useRef } from "react";
import styles from "./DragHandle.module.scss";

/**
 * @param {object} props
 * @param {function} props.handleToggle
 * @returns {JSX.IntrinsicElements.button}
 */
const DragHandle = ({ handleToggle }) => {
    const btnRef = useRef(null);

    /**
     * @param {number} deltaY
     * @param {number} parentHeight
     * @returns {number}
     */
    const getDeltaInPercent = (deltaY, parentHeight) => {
        return (deltaY / parentHeight) * 100;
    }

    const applyParentStyle = (nb) => {
        btnRef.current.parentNode.style.transform = nb ? `translateY(${nb}px)` : "";
    }


    useEffect(() => {
        if(btnRef.current){
            const parentNode = btnRef.current.parentNode;
            let isDragging = false;
            let initY = 0;

            const reset = () => {
                initY = 0;
                parentNode.style.transition = "";
                btnRef.current.classList.remove("is-dragging");
                applyParentStyle();
            }

            const handleOnTouchStart = (e) => {
                e.stopPropagation();

                initY = e.targetTouches[0].clientY;
                isDragging = true;
                parentNode.style.transition = "none";
                btnRef.current.classList.add("is-dragging");
            }

            const handleOnTouchEnd = (e) => {
                e.stopPropagation();

                isDragging = false;

                const deltaY = initY - e.changedTouches[0].clientY;
                const parentPadding = window.getComputedStyle(parentNode, null).getPropertyValue('padding-bottom');
                const parentNbPad = parentPadding.substring(0, parentPadding.length - 2);
                const deltaYPercent = 100 - getDeltaInPercent(parentNode.offsetHeight - parentNbPad + deltaY, parentNode.offsetHeight - parentNbPad);

                if(deltaYPercent > 33){
                    handleToggle();
                }

                reset();
            }

            const handleOnTouchMove = (e) => {
                e.preventDefault();
                e.stopPropagation();

                if(btnRef.current?.parentNode && isDragging){
                    const deltaY = initY - e.targetTouches[0].clientY;

                    applyParentStyle(Math.max(Math.min(parentNode.offsetHeight, deltaY * -1), 0));
                }
            }

            btnRef.current.addEventListener('touchstart', handleOnTouchStart);
            btnRef.current.addEventListener('touchend', handleOnTouchEnd);
            btnRef.current.addEventListener('touchmove', handleOnTouchMove);

            return () => {
                btnRef.current?.removeEventListener('touchstart', handleOnTouchStart);
                btnRef.current?.removeEventListener('touchend', handleOnTouchEnd);
                btnRef.current?.removeEventListener('touchmove', handleOnTouchMove);
            }
        }

    }, [ ]);

    return (
        <button
            ref={btnRef}
            type="button"
            className={styles.Btn}
        />
    )
}

export default DragHandle;