import { useContext } from 'react';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import moment from 'moment';
import {
    Card,
    CardBody,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledButtonDropdown,
    Spinner
} from "reactstrap";
import SpordlePanelTable from '../../../../../components/sidePanel/SpordlePanel';

import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView, useSpordleTable } from '@spordle/datatables';
import OnlineStoreTransactionsSidepanelInvoice from './OnlineStoreTransactionsSidepanel'
import { CSVLink } from "react-csv";

// contexts
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { OnlineStoreContext } from '../../../../../contexts/OnlineStoreContext';
import { DisplayI18n, displayI18n } from '../../../../../helpers/i18nHelper';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import EmptyLayout from '../../../../../components/table/EmptyLayout';
import { ReportsContext } from '../../../../../contexts/ReportsContext';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import { PeriodsContext } from '../../../../../contexts/contexts';
import { handleExcelExport } from '../../../../../helpers/reportsHelper';

const OnlineStoreTransactionList = (props) => {
    const organizationContext = useContext(OrganizationContext);
    const onlineStoreContext = useContext(OnlineStoreContext);

    const formatData = (invoices, selectedInvoiceNumber) => {
        // invoices
        const formattedInvoices = invoices.map((invoice) => {
            const creditAmount = getCreditAmount(invoice) + getPaymentReceptionsCreditAmount(invoice)
            const refundAmount = invoice.total_refunded_amount;
            const paidAmount = invoice.invoice_items.reduce((sum, item) => sum += parseInt(item.paid_amount), 0) + getCreditAmount(invoice);

            return {
                ...invoice,
                affiliation_fees: invoice.invoice_items.filter((item) => item.affiliation_fee !== null),
                registrations: invoice.invoice_items.filter((item) => item.registration_fee !== null),
                otherItems: invoice.invoice_items.filter((item) => item.other_fee !== null),

                creditAmount: creditAmount,
                refundAmount: refundAmount,
                total: parseInt(invoice.total_amount),
                paid: paidAmount,
                due: invoice.total_amount - paidAmount,
                checked: invoice.invoice_number === selectedInvoiceNumber,
            }
        })
        return formattedInvoices;
    }

    const getPaymentReceptionsCreditAmount = (invoice) => {
        let paymentReceptionsCreditsTotal = 0;

        if(invoice.payment_receptions && Array.isArray(invoice.payment_receptions) && invoice.payment_receptions.length > 0){
            paymentReceptionsCreditsTotal += invoice.payment_receptions.reduce((total, pM) => {
                if(pM.member_credit_id){
                    total += parseInt(pM.amount)
                }
                return total
            }, 0)
        }

        return paymentReceptionsCreditsTotal
    }

    const getCreditAmount = (invoice) => {
        return invoice.credits.reduce((sum, credit) => sum += parseInt(credit.amount), 0);
    }

    // const getTotalCreditAmount = (invoice) => {
    //     return getCreditAmount(invoice) + getPaymentReceptionsCreditAmount(invoice);
    // }

    /**
     * Calculated the paid amount
     * @param {object} invoice
     * @returns {number}
     */
    // const getInvoiceItemPaymentPaid = (invoice) => {
    //     const getInvoiceItemsPaymentsTotal = () => {
    //         return parseInt(invoice.invoice_items.reduce((sum, item) => {
    //             return sum += parseInt(item.payments.reduce((paymentSum, payment) => {
    //                 return paymentSum += parseInt(payment.status === "COMPLETED" ? payment.amount : 0)
    //             }, 0))
    //         }, 0))
    //     }

    //     // if we find payment_receptions (manual payment), we change the way to calculate the paid amount
    //     // we get the total of all the payment_receptions
    //     if(invoice.payment_receptions && Array.isArray(invoice.payment_receptions) && invoice.payment_receptions.length > 0){
    //         let totalReceptions = invoice.payment_receptions.reduce((total, pM) => {
    //             if(!pM.member_credit_id){
    //                 total += parseInt(pM.amount)
    //             }
    //             return total
    //         }, 0)

    //         if(invoice.invoice_payment_method === 'CREDITCARD' && getTotalCreditAmount(invoice) !== parseInt(invoice.total_amount)){
    //             totalReceptions += getInvoiceItemsPaymentsTotal();
    //         }

    //         return totalReceptions;
    //     } else {
    //         // if we dont find any payment_receptions, that means that the payment method is either online (CREDITCARD), and we do the old way
    //         // or that the manual payment is manual, but that no payments have been done
    //         return getInvoiceItemsPaymentsTotal();
    //     }

    //     // return parseInt(invoice.invoice_items.reduce((sum, item) => sum += parseInt(item.payments.reduce((paymentSum, payment) => paymentSum += parseInt(payment.status === "COMPLETED" ? payment.amount : 0), 0)), 0))
    // }

    /**
     * Calculates the amount left to pay
     * @param {object} invoice
     * @returns {number}
     */
    // const getInvoiceItemPaymentDue = (invoice) => {
    //     const getInvoiceItemsPaymentsTotal = () => {
    //         return invoice.invoice_items.reduce((sum, item) => {
    //             return sum += parseInt(item.payments.reduce((paymentSum, payment) => {
    //                 return paymentSum += parseInt((payment.status !== "COMPLETED") ? payment.amount : 0)
    //             }, 0))
    //         }, 0)
    //     }

    //     // if we find payment_receptions (manual payment), we change the way to calculate the due amount
    //     // we get the total amount and subtract the total of every payment_reception
    //     if(invoice.payment_receptions && Array.isArray(invoice.payment_receptions) && invoice.payment_receptions.length > 0){
    //         const totalReceived = invoice.payment_receptions.reduce((total, pM) => {
    //             return total += parseInt(pM.amount)
    //         }, 0)

    //         // there are 2 types of credits
    //         // applied credits (from the credits array), and the credits from payment_receptions
    //         // the applied credits are never subtracted from the dueAmount when there are payment_receptions
    //         // they are subtracted from the invoice item payments, so we only want to do that here
    //         let dueTotal = parseInt(invoice.total_amount) - totalReceived - getCreditAmount(invoice)

    //         if(invoice.invoice_payment_method === 'CREDITCARD' && getTotalCreditAmount(invoice) !== parseInt(invoice.total_amount)){
    //             dueTotal -= getInvoiceItemsPaymentsTotal();
    //         }

    //         return dueTotal;
    //     } else {
    //         // if we dont find any payment_receptions, that means that the payment method is either online (CREDITCARD), and we do the old way
    //         // or that the manual payment is manual, but that no payments have been done
    //         // Sum of everything that is not payed
    //         return invoice.invoice_items.reduce((sum, item) => {
    //             return sum += parseInt(item.payments.reduce((paymentSum, payment) => {
    //                 return paymentSum += parseInt((payment.status !== "CANCELED" && payment.status !== "CANCELLED" && payment.status !== "REFUNDED" && payment.status !== "COMPLETED") ? payment.amount : 0)
    //             }, 0))
    //         }, 0);
    //     }

    //     // return invoice.invoice_items.reduce((sum, item) => sum += parseInt(item.payments.reduce((paymentSum, payment) => paymentSum += parseInt((payment.status !== "REFUNDED" && payment.status !== "COMPLETED") ? payment.amount : 0), 0)), 0);
    // }

    return (
        <Card className="card-shadow mt-4">
            <CardBody>
                <div className="h4 font-bold pb-1 border-bottom mb-3 card-title">
                    <Translate id='onlineStore.profile.overview.tabs.transaction' />
                </div>
                {!onlineStoreContext.state.cachedStore.invoices &&
                        <div className='text-center'>
                            <Spinner color='primary' type='grow' />
                            <div className='h4'><Translate id='misc.loading' /></div>
                        </div>
                }
                {!!onlineStoreContext.state.cachedStore.invoices &&
                        <SpordlePanelTable
                            allowOutsideClick
                            dataIndex='invoice_number'
                            sidePanel={(sProps) => <OnlineStoreTransactionsSidepanelInvoice {...props} {...sProps} onlineStoreView />}
                            table={(panelProps) => {
                                return (
                                    <SpordleTableProvider
                                        id='OnlineStoreTransactionListTable'
                                        key={props.forceRefresh}
                                        tableHover bordered striped
                                        clickable hasFooter
                                        ref={panelProps.spordleTableRef}
                                        desktopWhen
                                        dataIndex='invoice_number'
                                        defaultSorting='-invoice_date'
                                        pagination={20}
                                        initFilters={{ invoiceNb: '' }}
                                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                        searchKeys={[
                                            'invoice_number',
                                            'invoice_date',
                                            'identity.name',
                                            'identity.family_name',
                                        ]}
                                        emptyLayout={<EmptyLayout hideArrow hideMsg />}
                                        loadData={(from, _, spordleTable) => {
                                            switch (from){
                                                case 'REFRESH':
                                                    spordleTable.setLoading()
                                                case 'FILTER':
                                                case 'CDM':
                                                    return onlineStoreContext.getOnlineStoreInvoices({
                                                        organisation_id: organizationContext.organisation_id,
                                                        period_id: onlineStoreContext.state.cachedStore.period.period_id,
                                                        online_store_id: onlineStoreContext.state.cachedStore.online_store_id,
                                                    }, from === 'REFRESH') // Get the invoices loaded in the initial profile load unless we are refreshing the table, then get "fromApi"
                                                        .then((invoices) => {
                                                            from === 'REFRESH' && props.setForceRefresh(); // For registrations tab
                                                            return formatData(invoices, spordleTable.getFilters().invoiceNb || panelProps.selectedRows[0]?.invoice_number)
                                                        })
                                                default:
                                                    break;
                                            }
                                        }}
                                        columns={[
                                            {
                                                label: <Translate id='members.profile.transactions.memberProfileTransactions.tableView.header.transaction' />,
                                                key: 'invoice_date',
                                                fallbackSortKey: 'invoice_number',
                                                sortable: true,
                                            },
                                            {
                                                label: <Translate id='members.profile.transactions.memberProfileTransactions.paidBy' />,
                                                key: 'paidBy',
                                                sortable: true,
                                                sortKey: 'identity.family_name',
                                                fallbackSortKey: 'identity.name',
                                            },
                                            {
                                                label: <Translate id='members.profile.transactions.memberProfileTransactions.tableView.header.items' />,
                                                key: 'items',
                                            },
                                            {
                                                label: <Translate id='members.profile.transactions.memberProfileTransactions.total' />,
                                                key: 'total',
                                                sortable: true,
                                                hasTotal: true,
                                            },
                                            {
                                                label: <Translate id='members.profile.transactions.memberProfileTransactions.tableView.header.paid' />,
                                                key: 'paid',
                                                sortable: true,
                                                hasTotal: true,
                                            },
                                            {
                                                label: <Translate id='members.profile.transactions.memberProfileTransactions.tableView.header.due' />,
                                                key: 'due',
                                                sortable: true,
                                                hasTotal: true,
                                            },
                                        ]}
                                        renderRow={(columnKey, invoice) => {
                                            switch (columnKey){
                                                case 'invoice_date':
                                                    return (
                                                        <>
                                                            <div className="small">{moment(invoice.invoice_date).format('YYYY-MM-DD H:mm')}</div>
                                                            <Link className="text-nowrap" to={`invoice?invoiceNumber=${invoice.invoice_number}`}>#{invoice.invoice_number}<i className='mdi mdi-chevron-right' /></Link>
                                                        </>
                                                    )
                                                case 'paidBy':
                                                    return (
                                                        <>
                                                            {invoice.identity && <div className="font-medium">{invoice.identity?.name + ' ' + invoice.identity?.family_name}</div>}
                                                            <div className="text-muted"><DisplayI18n field='name' defaultValue={invoice.invoice_payment_method?.name} i18n={invoice.invoice_payment_method?.i18n} /></div>
                                                        </>
                                                    )
                                                case 'items':
                                                    return (
                                                        <>
                                                            {(invoice.registrations && invoice.registrations.length > 0) &&
                                                                <div>{invoice.registrations.length} <Translate id='members.profile.transactions.memberProfileTransactions.registrations' /></div>
                                                            }
                                                            {(invoice.otherItems && invoice.otherItems.length > 0) &&
                                                                <div>{invoice.otherItems.length} <Translate id='members.profile.transactions.memberProfileTransactions.items' /></div>
                                                            }
                                                        </>
                                                    )
                                                case 'total':
                                                    return (
                                                        <div className={stringBuilder("text-right font-medium", { 'text-muted': invoice.active == '0' })}>
                                                            <CurrencyFormat value={parseInt(invoice.total) / 100} />
                                                        </div>
                                                    )
                                                case 'paid':
                                                    if(invoice.paid > 0){
                                                        return (
                                                            <div className={stringBuilder("text-right font-medium", { 'text-muted': invoice.active == '0' })}>
                                                                <CurrencyFormat value={(parseInt(invoice.paid) - parseInt(invoice.creditAmount)) / 100} />
                                                                {!!invoice.creditAmount &&
                                                                    <div className="d-block text-success font-medium small">(+<CurrencyFormat value={invoice.creditAmount / 100} />)</div>
                                                                }
                                                                {invoice.total_refunded_amount > 0 &&
                                                                    <div className='text-danger font-medium small'>(<Translate id='members.profile.transactions.memberProfileTransactions.refunded' />: {<CurrencyFormat value={invoice.total_refunded_amount / 100} />})</div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    return '-'

                                                case 'due':
                                                    // because it can be < 0 in case of a credit
                                                    if(invoice.due !== 0){
                                                        return (
                                                            <div className='text-right'>
                                                                <span className={stringBuilder("font-medium", { 'text-muted': invoice.active === '0' })}><CurrencyFormat value={invoice.due / 100} /></span>
                                                            </div>
                                                        )
                                                    }
                                                    return '-'

                                                default:
                                                    break;
                                            }
                                        }}
                                        renderFooter={(columnKey, columnTotal) => {
                                            switch (columnKey){
                                                case 'total':
                                                case 'paid':
                                                case 'due':
                                                    return <div className='text-right font-medium'><CurrencyFormat value={columnTotal / 100} /></div>
                                                default:
                                                    break;
                                            }
                                        }}
                                        onColumnClick={(e, invoice) => {
                                            switch (e.button){
                                                case 0: // Left mouse button
                                                    panelProps.onSingleSelectChange(invoice);
                                                    break;
                                            }
                                        }}
                                        rowIsHighlighted={(invoice) => !!invoice.checked}
                                    >
                                        {({ filterChange }) => (
                                            <>
                                                <InnerTable filterChange={filterChange} {...props} />
                                                <SpordleTableView />
                                            </>
                                        )}
                                    </SpordleTableProvider>
                                )
                            }}
                        />
                }
            </CardBody>
        </Card>
    );
}

const InnerTable = ({ filterChange, history }) => {
    const organizationContext = useContext(OrganizationContext);
    const onlineStoreContext = useContext(OnlineStoreContext);
    const reportsContext = useContext(ReportsContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const periodsContext = useContext(PeriodsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { getData } = useSpordleTable();

    const reportType = 'registration_v2';

    return (
        <div className='mb-2'>
            <div className='d-flex flex-wrap justify-content-between'>
                <SearchInput />
                <div className='d-flex ml-auto text-right'>
                    <Refresh />
                    <UncontrolledButtonDropdown className='ml-2'>
                        <DropdownToggle color='primary' outline caret>
                            <i className='fas fa-download' /> <Translate id='misc.export' />
                        </DropdownToggle>
                        <DropdownMenu right>
                            <ToCSV />
                            <DropdownItem
                                onClick={() => handleExcelExport(
                                    () => {},
                                    reportType,
                                    { organisation_id: organizationContext.organisation_id, period_id: periodsContext.selectedPeriod.period_id, online_store_id: onlineStoreContext.state.cachedStore.online_store_id },
                                    getData().length,
                                    reportsContext,
                                    identityRolesContext.identity_role_id,
                                    identityRolesContext.identity.identity_id,
                                    getGenericLocale(),
                                    organizationContext.organisation_id,
                                )}
                            >
                                <i className='fas fa-file-excel mr-1' /><Translate id='misc.excel' />
                            </DropdownItem>
                            <DropdownItem disabled><i className='fas fa-file-pdf mr-1' /><Translate id='misc.pdf' /></DropdownItem>
                            <DropdownItem disabled><i className='fas fa-print mr-1' /><Translate id='misc.print' /></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </div>
        </div>
    )
}


const ToCSV = () => {
    const onlineStoreContext = useContext(OnlineStoreContext);
    const i18nContext = useContext(I18nContext);
    const spordleTable = useSpordleTable();
    const intl = useIntl();

    const formatData = () => {
        return spordleTable.getData().map((invoice) => ({
            invoice_date: moment(invoice.invoice_date).format('YYYY-MM-DD H:mm'),
            invoice_number: invoice.invoice_number,
            invoice_payment_method: displayI18n("name",
                                                invoice.invoice_payment_method?.i18n,
                                                invoice.invoice_payment_method?.name,
                                                i18nContext.getGenericLocale()) || '',
            total: (invoice.total / 100).toFixed(2),
            paid: ((parseInt(invoice.paid) - parseInt(invoice.creditAmount)) / 100).toFixed(2),
            due: (invoice.due / 100).toFixed(2),
        }));
    }

    return (
        <CSVLink
            className={"dropdown-item"} separator=','
            filename={`${displayI18n("name", onlineStoreContext.state.cachedStore.i18n, onlineStoreContext.state.cachedStore.name, i18nContext.getGenericLocale())}-Transactions-${moment().format('YYYY-MM-DD H:mm')}.csv`}
            headers={[
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.invoiceDate' }),
                    key: 'invoice_date',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.invoiceNumber' }),
                    key: 'invoice_number',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.paymentMethod' }),
                    key: 'invoice_payment_method',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.total' }),
                    key: 'total',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.paid' }),
                    key: 'paid',
                },
                {
                    label: intl.formatMessage({ id: 'members.profile.transactions.memberProfileTransactions.csv.due' }),
                    key: 'due',
                },
            ]}
            data={formatData()}
        >
            <i className='fas fa-file-excel mr-1' />CSV
        </CSVLink>
    )
}

export default OnlineStoreTransactionList;