import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';
import Translate from "@spordle/intl-elements";
import React from "react";
import {
    Button,
    Card
} from "reactstrap";
import CardSectionTitle from "../../../../components/CardSectionTitle";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import SpordlePanelTable from "../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../components/table/EmptyLayout";
import { PeriodsContext } from '../../../../contexts/contexts';
import { I18nContext } from '../../../../contexts/I18nContext';
// Contexts
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import I18nHelperContextProvider, { I18nHelperContext } from '../../../../helpers/i18nHelper';
import withContexts from "../../../../helpers/withContexts";
import AddOrganizationActivityPeriods from './AddOrganizationActivityPeriods';
import SidePanelOrganizationActivityPeriods from './SidePanelOrganizationActivityPeriods';

class OrganizationActivityPeriods extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            addPeriodModalOpen: false,
        }
    }

    spordleTableRef = React.createRef()


    toggleAddPeriod = () => {
        this.setState((prevState) => ({ addPeriodModalOpen: !prevState.addPeriodModalOpen }));
    }

    render(){
        return (
            <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <div>
                            {/* ADD ROLE MODAL */}
                            <AddOrganizationActivityPeriods i18nHelper={i18nHelper} addPeriodModalOpen={this.state.addPeriodModalOpen} toggleAddPeriod={this.toggleAddPeriod} spordleTableRef={this.spordleTableRef} />
                            <SpordlePanelTable
                                ref={this.spordleTableRef}
                                allowOutsideClick
                                sidePanel={(props) => <SidePanelOrganizationActivityPeriods {...props} tableRef={this.periodsTableRef} />}
                                dataIndex='activity_period_id'
                                table={(panelProps) => {
                                    return (
                                        <Card body className="card-shadow">
                                            <CardSectionTitle title='settings.tabs.activityPeriods' />
                                            <SpordleTableProvider
                                                id='OrganizationPeriodsTable'
                                                tableHover clickable striped
                                                bordered
                                                defaultSorting='+display_order'
                                                ref={(r) => { this.periodsTableRef = r; panelProps.spordleTableRef(r); }}
                                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                                dataIndex='activity_period_id'
                                                searchKeys={[
                                                    `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                    `i18n.${this.props.I18nContext.getGenericLocale()}.description`,
                                                    `display_order`,
                                                    'name',
                                                    'description',
                                                    'member_number_prefix',
                                                ]}
                                                columns={[
                                                    {
                                                        label: <Translate id='organization.settings.organizationPeriods.table.display_order' />,
                                                        key: "display_order",
                                                        mobile: true,
                                                        sortable: true,
                                                        dataClassName: "text-center",
                                                    },
                                                    ...i18nHelper.getTableColumns('name'),
                                                    ...i18nHelper.getTableColumns('description'),
                                                    {
                                                        label: <Translate id='organization.settings.organizationPeriods.table.active' />,
                                                        key: "active",
                                                        mobile: true,
                                                        sortable: true,
                                                    },
                                                ]}
                                                onColumnClick={(e, period) => {
                                                    switch (e.button){
                                                        case 0: // Left mouse button
                                                            panelProps.onSingleSelectChange(period);
                                                            break;
                                                    }
                                                }}
                                                desktopWhen='md'
                                                emptyLayout={<EmptyLayout translateTitle title='organization.settings.organizationPeriods.table.empty' />}
                                                renderRow={(columnKey, period, spordleTable) => {
                                                    switch (columnKey){
                                                        case 'active':
                                                            return (
                                                                <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                    {period.active == '1' ?
                                                                        <i className="text-primary mdi mdi-check" />
                                                                        :
                                                                        <i className="text-danger mdi mdi-close" />
                                                                    }
                                                                </div>
                                                            )
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                rowIsHighlighted={(period) => !!period.checked}
                                                loadData={(from, filterData, spordleTable) => {
                                                    switch (from){
                                                        case 'REFRESH':
                                                            spordleTable.setLoading();
                                                        case 'CDM':
                                                            return this.props.PeriodsContext.getOrganizationActivityPeriods({ organisation_id: this.props.OrganizationContext.organisation_id });
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            >
                                                <TopSection toggleAddPeriod={this.toggleAddPeriod} panelProps={panelProps} />
                                                <SpordleTableView />
                                            </SpordleTableProvider>
                                        </Card>
                                    )
                                }}
                            />
                        </div>
                    )}
                </I18nHelperContext.Consumer>
            </I18nHelperContextProvider>
        )
    }
}

const TopSection = (props) => {
    return (
        <div className="mb-2">
            <div className='d-flex justify-content-between'>
                <SearchInput />
                <div className='d-flex ml-auto text-right'>
                    <Refresh />
                    <CanDoAction action='ADD' componentCode='organization' componentPermissionCode='organization_activity_periods'>
                        <Button color='primary' className='ml-2' onClick={props.toggleAddPeriod}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                    </CanDoAction>
                </div>
            </div>
        </div>
    )
}

export default withContexts(PeriodsContext, OrganizationContext, I18nContext)(OrganizationActivityPeriods);