export default{
    "members.search.memberMerge.btn.label"                : "Fusionner le membre",
    "members.search.block.btn.label"                      : "Bloquer le membre",
    "members.search.reactivate.btn.label"                 : "Réactiver le membre",
    "members.search.confirm.btn.label"                    : "Confirmer le membre",
    "members.search.blockMembers.btn.label"               : "Bloquer les membres",
    "members.search.ineligible.btn.label"                 : "Rendre le membre permanent inéligible",
    "members.search.remove.ineligible.btn.label"          : "Supprimer l'inéligibilité",
    "members.search.memberMerge.merge.action"             : "Fusionner",
    "members.search.memberMerge.merge.success"            : "Les membres ont été fusionnés!",
    "members.search.memberMerge.merge.selected"           : "Sélectionné",
    "members.search.memberMerge.merge.toKeep"             : "Membre à garder",
    "members.search.memberMerge.merge.alreadySelect"      : "Déjà sélectionné",
    "members.search.memberMerge.merge.toDelete"           : "Membre à supprimer",
    "members.search.memberMerge.merge.missingPermissions" : "Permission manquante pour fusionner ces membres.",
    "members.search.register.btn.label"                   : "Inscription",

    "members.search.memberMerge.modal.title"       : "Fusionner les membres",
    "members.search.memberMerge.modal.keep"        : "Garder",
    "members.search.memberMerge.modal.confirm"     : "Veuillez confirmer",
    "members.search.memberMerge.modal.final"       : "Résultat final",
    "members.search.memberMerge.modal.deleted"     : "Nous retirerons {name} du système et vous n'aurez plus accès à ce profil.",
    "members.search.memberMerge.modal.picture"     : "Le système choisira automatiquement la photo de profil la plus récente parmis les deux profils pour l'appliquer à celui qui est gardé.",
    "members.search.memberMerge.modal.mergeFromTo" : "Les informations de {from} <span>#{fromNb}</span> vont <span>se fusionner</span> avec les informations existantes de {to} <span>#{toNb}</span>.",
}