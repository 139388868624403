import { fail } from "@spordle/toasts";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { WaiversContext } from "../../../../contexts/WaiversContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import WaiverEdit from "./WaiverEdit";
import queryString from 'query-string';

export const WaiversEditWrapper = () => {
    const [ waiverData, setWaiverData ] = useState(null);

    const { getWaiver } = useContext(WaiversContext);

    const { search } = useLocation();
    const { waiverId } = queryString.parse(search)

    useEffect(() => {
        getWaiver(waiverId)
            .then((waiver) => {
                setWaiverData(waiver)
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, [])

    return (
        <div>
            {!waiverData ?
                <div className='text-center'>
                    <Spinner type='grow' color='primary' />
                </div>
                :
                <WaiverEdit waiverData={waiverData} mode='edit' />
            }
        </div>
    )
}
