import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { Alert, Button, Collapse, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../../../components/loading/OverlayLoader';
import { I18nContext } from '../../../../../../../../contexts/I18nContext';
import { WaiversContext } from '../../../../../../../../contexts/WaiversContext';
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';
import AnalyticsModal from '../../../../../../../../analytics/AnalyticsModal';
import { success } from '@spordle/toasts';
import { WaiverFields, WaiverHeader, waiverNotSigned, WaiverContent, WaiverMandatoryHelper, WaiverSignature, WaiverSignatureDisplay, waiverPhysical } from '../../../../../../../../helpers/waiversHelper';
import CrossFade from '../../../../../../../../components/crossFade/CrossFade';
import { Tooltip } from '@mantine/core';
import { AuthContext } from '../../../../../../../../contexts/contexts';

const WaiversModal = ({ waiver, item, signedBy, apiPartner, closeModal, answer, invoiceNumber, onSubmit }) => {
    const i18nContext = useContext(I18nContext);
    const waiverContext = useContext(WaiversContext);
    const authContext = useContext(AuthContext);

    const [ unsignMenuIsOpen, setUnsignMenuIsOpen ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    const unsignWaiver = (formik) => {
        setIsLoading(true);
        setUnsignMenuIsOpen(false);
        waiverContext.signWaiver(invoiceNumber, waiver.invoiceItem.invoice_item_id, waiver.waiver.waiver_id, { answer: waiverNotSigned, language_code: i18nContext.getGenericLocale(), signature_type: waiverPhysical })
            .then(() => {
                success();
                setIsLoading(false);
            })
            .then(closeModal)
            .then(onSubmit)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                    setIsLoading(false);
                    formik.setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                }
            })
    }

    return (
        <AnalyticsModal isOpen={!!waiver} analyticsName='WaiversModal'>
            <Formik
                initialValues={{
                    sign: answer === waiverNotSigned ? '' : answer,
                    signatureType: '',
                }}
                validationSchema={object().shape({
                    sign: string().test({
                        name: 'requiredTest',
                        message: <Translate id='form.fields.waiver.required' />,
                        test: (value) => {
                            return !!value || waiver?.waiver.mandatory == 0
                        },
                    }),
                    signatureType: string().test({
                        name: 'signatureRequiredTest',
                        message: <Translate id='form.fields.waiver.signature.required' />,
                        test: function(value){
                            if(this.parent.sign){
                                return !!value
                            }
                            return true
                        },
                    }),
                })}
                onSubmit={({ sign, signatureType }, { setSubmitting, setStatus }) => {
                    if(!sign){
                        Promise.resolve()
                            .then(() => {
                                success();
                                closeModal();
                                // onSubmit just refreshes table, no need to do that
                            })
                    }else{
                        waiverContext.signWaiver(invoiceNumber, waiver.invoiceItem.invoice_item_id, waiver.waiver.waiver_id, { answer: sign, language_code: i18nContext.getGenericLocale(), signature_type: signatureType })
                            .then(() => {
                                success();
                            })
                            .then(closeModal)
                            .then(onSubmit)
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message);
                                    setSubmitting(false);
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                }
                            })
                    }
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting || isLoading}>
                            <ModalHeader tag='div' toggle={closeModal}>
                                <WaiverHeader waiver={waiver} />
                            </ModalHeader>
                            <ModalBody>
                                <WaiverContent waiver={waiver} item={item} />

                                <WaiverMandatoryHelper waiver={waiver?.waiver} />

                                {answer !== waiverNotSigned ?
                                    <WaiverSignatureDisplay waiver={waiver} />
                                    :
                                    <>
                                        <WaiverFields waiver={waiver?.waiver} fieldName='sign' />
                                        <WaiverSignature fieldName='signatureType' answer={formik.values.sign} />
                                    </>
                                }

                                <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                    <Alert color='danger' className='mt-3 mb-0'>
                                        {formik.status}
                                    </Alert>
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                {answer !== waiverNotSigned ?
                                    <div className='d-flex justify-content-between w-100'>
                                        {waiver?.identity?.identity_id === authContext.account.userName &&
                                            <div>
                                                <Tooltip
                                                    zIndex={3000}
                                                    opened={unsignMenuIsOpen}
                                                    label={
                                                        <div className="text-center">
                                                            <div className="font-medium h5 mb-0 text-white"><Translate id='misc.confirm' /></div>
                                                            <div>
                                                                <Translate id='components.manualRegistration.waivers.unsign.confirm' />
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <CrossFade isVisible={!unsignMenuIsOpen}>
                                                        <Button color='danger' outline type='button' onClick={() => setUnsignMenuIsOpen(true)}><Translate id='components.manualRegistration.waivers.unsign' /></Button>
                                                    </CrossFade>
                                                    <CrossFade isVisible={unsignMenuIsOpen}>
                                                        <div>
                                                            <Button color='danger' type='button' onClick={() => unsignWaiver(formik)}><Translate id='components.manualRegistration.waivers.unsign' /></Button>
                                                            <Button color='danger' outline type='button' className='ml-2' onClick={() => setUnsignMenuIsOpen(false)}><Translate id='misc.cancel' /></Button>
                                                        </div>
                                                    </CrossFade>
                                                </Tooltip>
                                            </div>
                                        }
                                        {/* Using normal button because of some weird thing hapenning with the reactsrap button submitting even if it was a type='button' */}
                                        <button className='btn btn-primary ml-auto' type='button' onClick={closeModal}><Translate id='misc.ok' /></button>
                                    </div>
                                    :
                                    <>
                                        <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                        <Button color='primary' type='button' onClick={closeModal} outline disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                    </>
                                }
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

export default WaiversModal;
