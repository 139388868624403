import Translate from '@spordle/intl-elements';
import { Link } from "react-router-dom";
import { useContext, useState } from 'react';
import {
    ListGroup,
    ListGroupItem
} from "reactstrap";
import UserDisplay from '../../../components/userDisplay/UserDisplay';
import UserImg from '../../../components/UserImg';
import SidePanel from '../../../components/sidePanel/SidePanel';
import StatusBadge from '../../../components/badges/StatusBadge';
import MemberBulkConfirmModal from './MemberBulkConfirm/MemberBulkConfirmModal';
import MemberStatusIcons from './components/MemberStatusIcons';
import QuickViewButton from '../../../components/quickView/QuickViewButton';
import SendCommModal from '../../../components/communications/SendCommModal';
import { RolesContext } from '../../../contexts/RolesContext';
import QuickViewActionButton from '../../../components/quickView/QuickViewActionButton';
import EnvFeatureFlag from '../../../components/featureFlag/EnvFeatureFlag';
import MemberProfileRegistrationAddContext from '../profile/registration/components/AddModal/MemberProfileRegistrationAddContext';
import MemberBulkBlock from './MemberBulkBlock/MemberBulkBlock';

const SidePanelMemberSearchMulti = ({ spordleTable, members, ...props }) => {
    const [ multiBlockIsOpen, openMultiBlock ] = useState(false);
    const [ multiConfirmIsOpen, openMultiConfirm ] = useState(false);
    const [ multiRegistration, openMultiRegistration ] = useState(false);
    const [ sendCommIsOpen, setSendCommIsOpen ] = useState(false);
    const rolesContext = useContext(RolesContext);

    // This will let us manage each action seperatly, it makes it easier to manage conditions & dividers
    const actionConditions = {
        confirmMember: true, //!minimalisticView && member.member_status?.name !== 'INELIGIBLE' && member.member_status?.name !== 'INACTIVE' && member.member_status?.name !== 'BLOCKED' && member.member_status?.name !== 'CONFIRMED',
        blockMember: true,
    }

    return (
        <>
            <MemberBulkBlock
                isOpen={multiBlockIsOpen}
                toggle={() => openMultiBlock(!multiBlockIsOpen)}
                members={members}
                toggleAfterSuccess={() => {
                    props.tableRef.refreshTable();
                    props.toggle();
                }}
            />

            <MemberBulkConfirmModal
                isOpen={multiConfirmIsOpen}
                toggle={() => openMultiConfirm(!multiConfirmIsOpen)}
                members={members}
                toggleAfterSuccess={() => {
                    props.tableRef.refreshTable();
                    props.toggle();
                }}
                canConfirmAddress={rolesContext.canDoAction('EDIT', 'members', 'members_address')}
            />

            <SendCommModal
                id="memberSearchMultiSelection"
                isOpen={!!sendCommIsOpen}
                toggle={() => setSendCommIsOpen(false)}
                recipients={members}
            />

            <EnvFeatureFlag env={[ "stage" ]}>
                <MemberProfileRegistrationAddContext
                    isOpen={multiRegistration}
                    toggle={openMultiRegistration}
                    refreshTable={() => {}}
                    isMulti={members}
                    wireFrame
                />
            </EnvFeatureFlag>

            <SidePanel.Header>
                <div className='d-flex mb-2'>
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu>

                        <EnvFeatureFlag env={[ "stage" ]}>
                            <SidePanel.MenuAction action='ADD' componentCode='members' componentPermissionCode='members_registrations' onClick={() => openMultiRegistration(true)} disabled={false}>
                                <Translate id='members.search.register.btn.label' />
                            </SidePanel.MenuAction>
                            <SidePanel.MenuAction divider />
                        </EnvFeatureFlag>

                        {/* CONFIRM MEMBER */}
                        {(actionConditions.confirmMember) &&
                            <SidePanel.MenuAction action='ADD' componentCode="members" componentPermissionCode="confirm_members" onClick={() => openMultiConfirm(true)} disabled={false}>
                                <Translate id='members.search.confirm.btn.label' />
                            </SidePanel.MenuAction>
                        }

                        {/* BLOCK MEMBER */}
                        {(actionConditions.blockMember) &&
                            <SidePanel.MenuAction onClick={() => openMultiBlock(true)} disabled={false}>
                                <Translate id='members.search.blockMembers.btn.label' />
                            </SidePanel.MenuAction>
                        }
                        <SidePanel.MenuAction
                            action='ADD'
                            componentCode='communications'
                            componentPermissionCode='communications_manage'
                            onClick={() => setSendCommIsOpen(true)}
                        >
                            <Translate id="communications.sendMessage" />
                        </SidePanel.MenuAction>
                        <QuickViewActionButton member={members} />
                    </SidePanel.ActionsMenu>
                </div>
                <SidePanel.Title className='d-flex'>
                    <QuickViewButton member={members}>{members.length} <Translate id='members.search.sidePanelMemberSearch.title.selectedMembers' /></QuickViewButton>
                </SidePanel.Title>
            </SidePanel.Header>
            <ListGroup>
                {members.map((member) => (
                    <ListGroupItem key={member.member_id}>
                        <UserDisplay>
                            <UserDisplay.Container>
                                <UserImg
                                    alt={member.first_name + ' ' + member.last_name}
                                    abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                    src={member.picture?.full_path}
                                    filePos={member.picture?.file_position}
                                    width={60}
                                    className="mr-2"
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container className="w-100">
                                <UserDisplay.Title className="d-flex justify-content-between">
                                    <div className='d-flex justify-content-start'>
                                        <div>{member.first_name} {member.last_name}</div>
                                        <MemberStatusIcons member={member} className="ml-1 d-flex" />
                                    </div>
                                    <div>{member.member_status && <StatusBadge color={member.member_status.color_code} status={member.member_status} icon={member.has_been_merged == 1 ? <i className='mdi mdi-call-merge' /> : null} />}</div>
                                </UserDisplay.Title>
                                <UserDisplay.Subtitle>
                                    <Link className="text-nowrap" to={`/members/profile/${member.member_id}`}>
                                        {member.unique_identifier ?
                                            <>#{member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                            :
                                            '-'
                                        }
                                    </Link>
                                </UserDisplay.Subtitle>
                            </UserDisplay.Container>
                        </UserDisplay>
                    </ListGroupItem>
                ))}
            </ListGroup>

        </>
    );
}

export default SidePanelMemberSearchMulti;