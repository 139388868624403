import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { Button, Col, Fade, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import * as Yup from 'yup';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { FormikInputText } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import Required from "../../../../../components/formik/Required";
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import EmptyLayout from "../../../../../components/table/EmptyLayout";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import GenderLabel from "../../../../../components/GenderLabel";

const Step1 = ({ setInfo, setLoading, changeStep }) => {
    const [ searchResults, setSearchResults ] = useState(null);
    const membersContext = useContext(MembersContext);
    const organizationContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext)

    return (
        <Fade>
            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                }}
                validationSchema={Yup.object().shape({
                    first_name: Yup.string().required(<Translate id='form.validation.firstName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='members.search.memberAdd.firstName.regex' /> }),
                    last_name: Yup.string().required(<Translate id='form.validation.lastName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='members.search.memberAdd.lastName.regex' /> }),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setInfo(values);
                    setLoading(true);

                    membersContext.getMembers({ ...values, all: 1, organisation_id: identityRolesContext.federation?.organisation_id })
                        .then((res) => {
                            if(res.length > 0){
                                setSearchResults(res);
                            }else if(organizationContext.settings.organisation_contact_must_be_member.value == '0'){
                                changeStep(2);
                            }else{
                                setSearchResults([]);
                            }
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                        .finally(() => {
                            setLoading(false);
                            setSubmitting(false);
                        })
                }}
            >
                { (formik) => (
                    <Form>
                        {/* Avoids visual glitch with collapse */}
                        <ModalBody>
                            <Row form>
                                <Col className="form-group" md="6">
                                    <Label for="first_name"><Translate id='form.fields.firstName' /> <Required /></Label>
                                    <FormikInputText name="first_name" id="first_name" />
                                </Col>
                                <Col className="form-group" md="6">
                                    <Label for="last_name"><Translate id='form.fields.lastName' /> <Required /></Label>
                                    <FormikInputText name="last_name" id="last_name" />
                                </Col>
                            </Row>

                            <CrossFade isVisible={searchResults?.length === 0}>
                                <EmptyLayout
                                    title='organization.profile.staff.modals.addStaff.no.members.found'
                                    hideArrow
                                    translateTitle
                                    hideMsg
                                />
                            </CrossFade>

                            {/* Search results */}
                            { searchResults && searchResults.length > 0 &&
                                <CrossFade isVisible={searchResults.length > 0}>
                                    <>
                                        <div className="h4 mb-3"><Translate id='organization.profile.staff.modals.addStaff.members' /></div>
                                        {searchResults.map((res) => (
                                            <UserDisplay key={res.member_id} card className="w-100 mb-2">
                                                <UserDisplay.Container>
                                                    <UserImg alt={res.first_name + ' ' + res.last_name} height={40} width={40} abbr={res.first_name.charAt(0) + res.last_name.charAt(0)} />
                                                </UserDisplay.Container>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title>{res.first_name} {res.last_name}</UserDisplay.Title>
                                                    <UserDisplay.Subtitle><GenderLabel gender={res.gender} /> - {res.birthdate} (<Translate id='misc.yearsOld' values={{ age: res.age }} />)</UserDisplay.Subtitle>
                                                    <UserDisplay.Subtitle>#{res.unique_identifier}</UserDisplay.Subtitle>
                                                </UserDisplay.Container>
                                                <UserDisplay.Container className="ml-auto">
                                                    <Button
                                                        size="sm" color="primary" outline
                                                        type="button"
                                                        onClick={() => {
                                                            setInfo(res);
                                                            changeStep(2);
                                                        }}
                                                    >
                                                        <Translate id='organization.profile.staff.modals.addStaff.btn.selectMember' />
                                                    </Button>
                                                </UserDisplay.Container>
                                            </UserDisplay>
                                        ))}
                                        {organizationContext.settings.organisation_contact_must_be_member.value == '0' &&
                                                <div className="text-right pt-2">
                                                    <Button type="button" onClick={() => { changeStep(2) }} className="w-100" color="primary"><Translate id='organization.profile.staff.modals.addStaff.btn.createMember' /></Button>
                                                </div>
                                        }
                                    </>
                                </CrossFade>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type="sumbit" disabled={formik.isSubmitting}><i className="ti ti-search" /> <Translate id="misc.search" /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Fade>
    )
}

export default Step1;