import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
    Badge,
    Card,
    CardBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Table,
    UncontrolledDropdown
} from "reactstrap";
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import SpordlePanelTable from '../../../../../../components/sidePanel/SpordlePanel';
import MemberProfileTransferSidepanel from './component/sidepanel/MemberProfileTransferSidepanel';
import { displayI18n, DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { getTransferStatusBadge, getActualStatus } from '../../../../../../helpers/transferHelper'
import moment from "moment";

// contexts
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { RolesContext } from '../../../../../../contexts/RolesContext';
import { IdentityRolesContext } from '../../../../../../contexts/IdentityRolesContext';
import { stringBuilder } from '@spordle/helpers';

const MemberProfileTransfer = ({ canTransfer, transferKey }) => {
    const organizationContext = useContext(OrganizationContext);
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const spordleTableRef = useRef(null);

    useEffect(() => {
        spordleTableRef.current?.refreshTable();
    }, [ transferKey ]);

    const hasOutstandingBalance = membersContext.currentMember.outstanding_balance ? parseInt(membersContext.currentMember.outstanding_balance) > 0 : false;
    const getHasOutstandingBalanceMessage = () => {
        return (
            hasOutstandingBalance &&
                <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.outstandingBalance' /></div>
        )
    }

    const canCtiRequest = !!organizationContext.settings.international_transfer_fields?.value

    return (
        <Card id="memberProfileTransferCard" className="card-shadow">
            <CardBody>
                <CardSectionTitle title='members.profile.transfers.title' />
                <SpordlePanelTable
                    allowOutsideClick
                    sidePanel={(props) => <MemberProfileTransferSidepanel {...props} />}
                    dataIndex='member_transfer_id'
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='transfers'
                                tableHover bordered striped
                                clickable
                                ref={(r) => { spordleTableRef.current = r; panelProps.spordleTableRef(r) }}
                                desktopWhen
                                dataIndex='member_transfer_id'
                                pagination={20}
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                searchKeys={[
                                    'transfer_type',
                                    `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                    'organisation.organisation_name',
                                    `target_organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                    'target_organisation.organisation_name',
                                    `transfer_duration.i18n.${i18nContext.getGenericLocale()}.name`,
                                    'transfer_duration.name',
                                ]}
                                loadData={(from, data, spordleTable) => {
                                    switch (from){
                                        case 'REFRESH':
                                            spordleTable.setLoading();
                                        case 'CDM':
                                            return membersContext.getMemberTransfers(membersContext.currentMember.member_id)
                                                .then((tranfers) => tranfers.map((transfer) => ({ ...transfer, actualStatus: getActualStatus(transfer) })));
                                        default:
                                            break;
                                    }
                                }}
                                columnSorting={(transferA, transferB, _spordleTable, order) => {
                                    switch (order.column){
                                        case 'approvedDate':
                                            // the unix() function returns a date in unix format (number)
                                            const unixA = moment(transferA.updated_at).unix();
                                            const unixB = moment(transferB.updated_at).unix();
                                            return order.order === 'ASC' ? unixA - unixB : unixB - unixA;
                                        case 'period':
                                            const valueAPeriod = displayI18n('name', transferA.period.i18n, transferA.period.name, i18nContext.getGenericLocale())
                                            const valueBPeriod = displayI18n('name', transferB.period.i18n, transferB.period.name, i18nContext.getGenericLocale())
                                            return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueAPeriod, valueBPeriod) * (order.order === 'ASC' ? 1 : -1);
                                        case 'from':
                                            const valueAFrom = displayI18n('name', transferA.organisation.i18n, transferA.organisation.organisation_name, i18nContext.getGenericLocale())
                                            const valueBFrom = displayI18n('name', transferB.organisation.i18n, transferB.organisation.organisation_name, i18nContext.getGenericLocale())
                                            return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueAFrom, valueBFrom) * (order.order === 'ASC' ? 1 : -1);
                                        case 'to':
                                            const valueATo = displayI18n('name', transferA.target_organisation.i18n, transferA.target_organisation.organisation_name, i18nContext.getGenericLocale())
                                            const valueBTo = displayI18n('name', transferB.target_organisation.i18n, transferB.target_organisation.organisation_name, i18nContext.getGenericLocale())
                                            return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueATo, valueBTo) * (order.order === 'ASC' ? 1 : -1);
                                        case 'status':
                                            const valueAStatus = displayI18n('name', transferA.transfer_status.i18n, transferA.transfer_status.name, i18nContext.getGenericLocale())
                                            const valueBStatus = displayI18n('name', transferB.transfer_status.i18n, transferB.transfer_status.name, i18nContext.getGenericLocale())
                                            return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueAStatus, valueBStatus) * (order.order === 'ASC' ? 1 : -1);
                                        case 'duration':
                                            const valueADuration = displayI18n('name', transferA.transfer_duration.i18n, transferA.transfer_duration.name, i18nContext.getGenericLocale())
                                            const valueBDuration = displayI18n('name', transferB.transfer_duration.i18n, transferB.transfer_duration.name, i18nContext.getGenericLocale())
                                            return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueADuration, valueBDuration) * (order.order === 'ASC' ? 1 : -1);
                                    }
                                }}
                                defaultSorting='-period'
                                columns={[
                                    {
                                        label: <Translate id='members.profile.transfers.period' />,
                                        key: 'period',
                                        sortable: true,
                                        mobile: true,
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                    },
                                    {
                                        label: <Translate id='members.profile.transfers.permanent.share' />,
                                        key: 'duration',
                                        sortable: true,
                                        mobile: true,
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                    },
                                    {
                                        label: <Translate id='members.profile.transfers.transferType' />,
                                        key: 'transfer',
                                        sortKey: 'transfer_type',
                                        sortable: true,
                                        mobile: true,
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                    },
                                    {
                                        label: <Translate id='members.profile.transfers.from' />,
                                        key: 'from',
                                        sortable: true,
                                        mobile: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.transfers.to' />,
                                        key: 'to',
                                        sortable: true,
                                        mobile: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.transfers.approvedOn' />,
                                        key: 'approvedDate',
                                        sortKey: 'approved_date',
                                        sortable: true,
                                        mobile: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.transfers.status' />,
                                        key: 'status',
                                        sortable: true,
                                        mobile: true,
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                    },
                                ]}
                                renderRow={(columnKey, transfer) => {
                                    switch (columnKey){
                                        case 'period':
                                            return <DisplayI18n field='name' defaultValue={transfer.period.name} i18n={transfer.period.i18n} />
                                        case 'transfer':
                                            return <Translate id={'members.profile.transfers.type.' + transfer.transfer_type} />
                                        case 'from':
                                            if(transfer.team){
                                                return (
                                                    <>
                                                        <div className="small">
                                                            {transfer.organisation ?
                                                                <DisplayI18n field='name' defaultValue={transfer.organisation.organisation_name} i18n={transfer.organisation.i18n} />
                                                                :
                                                                '-'
                                                            }
                                                        </div>
                                                        {/* TODO: display team name */}
                                                        {/* <div className="text-dark">
                                                                Team name
                                                            </div> */}
                                                    </>
                                                )
                                            }
                                            return (
                                                <div className="text-dark">
                                                    {transfer.organisation ?
                                                        <DisplayI18n field='name' defaultValue={transfer.organisation.organisation_name} i18n={transfer.organisation.i18n} />
                                                        :
                                                        '-'
                                                    }
                                                </div>
                                            )

                                        case 'to':
                                            return (
                                                <div className="text-dark">
                                                    {transfer.target_organisation ?
                                                        <DisplayI18n field='name' defaultValue={transfer.target_organisation.organisation_name} i18n={transfer.target_organisation.i18n} />
                                                        :
                                                        '-'
                                                    }
                                                </div>
                                            )
                                        case 'approvedDate':
                                            return transfer.approved_date ?
                                                <DateFormat format={'YYYY-MM-DD h:mm A'} value={transfer.approved_date} utc />
                                                : transfer.approved_date === null && transfer.transfer_request_state === 'CLOSED' ?
                                                    <DateFormat format={'YYYY-MM-DD h:mm A'} value={transfer.updated_at} />
                                                    : '-'
                                        case 'status':
                                            if(transfer.expired_share)
                                                return <Badge color='light'><Translate id="transfer.table.header.status.EXPIRED_SHARE" /></Badge>
                                            return getTransferStatusBadge(transfer.transfer_status, transfer.previous_action_done)
                                        case 'duration':
                                            if(transfer.transfer_duration)
                                                return <DisplayI18n field='name' i18n={transfer.transfer_duration.i18n} defaultValue={transfer.transfer_duration.name} />
                                            return '-';
                                        default:
                                            break;
                                    }
                                }}
                                onColumnClick={(e, data) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            panelProps.onSingleSelectChange(data);
                                            break;
                                    }
                                }}
                                rowIsHighlighted={(data) => data.checked}
                                emptyLayout={
                                    <Table bordered striped hover>
                                        <tbody>
                                            {!canCtiRequest && (canDoAction('ADD', 'members', 'members_request_transfer_usa') || isGod()) &&
                                            <tr>
                                                <td className='text-center'>
                                                    <Link to={(hasOutstandingBalance || membersContext.currentMember.hasTransferRequest) ? '#' : 'usa'} className={stringBuilder('font-medium reset-btn text-primary', { 'text-muted': (hasOutstandingBalance || membersContext.currentMember.hasTransferRequest), 'text-purple': !canDoAction('ADD', 'members', 'members_request_transfer_usa') && isGod() })}><i className='mdi mdi-plus' /><Translate id="members.profile.transfers.form.title.usa" /></Link>
                                                    {membersContext.currentMember.hasTransferRequest &&
                                                        <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.hasActive' /></div>
                                                    }
                                                    {getHasOutstandingBalanceMessage()}
                                                </td>
                                            </tr>
                                            }
                                            {!canCtiRequest && (canDoAction('ADD', 'members', 'members_request_transfer_international') || isGod()) &&
                                            <tr>
                                                <td className='text-center'>
                                                    <Link to={(hasOutstandingBalance || membersContext.currentMember.hasTransferRequest) ? '#' : 'international'} className={stringBuilder('font-medium reset-btn text-primary', { 'text-muted': (hasOutstandingBalance || membersContext.currentMember.hasTransferRequest), 'text-purple': !canDoAction('ADD', 'members', 'members_request_transfer_international') && isGod() })}><i className='mdi mdi-plus' /><Translate id="members.profile.transfers.form.title.international" /></Link>
                                                    {membersContext.currentMember.hasTransferRequest &&
                                                        <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.hasActive' /></div>
                                                    }
                                                    {getHasOutstandingBalanceMessage()}
                                                </td>
                                            </tr>
                                            }

                                            {canCtiRequest && (canDoAction('ADD', 'members', 'members_request_transfer_international') || isGod()) &&
                                            <tr>
                                                <td className='text-center'>
                                                    <Link to={membersContext.currentMember.hasTransferRequest ? '#' : 'cti'} className={stringBuilder('font-medium reset-btn text-primary', { 'text-muted': membersContext.currentMember.hasTransferRequest, 'text-purple': !canDoAction('ADD', 'members', 'members_request_transfer_international') && isGod() })}><i className='mdi mdi-plus' /><Translate id="members.profile.transfers.form.title.cti" /></Link>
                                                    {membersContext.currentMember.hasTransferRequest &&
                                                        <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.hasActive' /></div>
                                                    }
                                                </td>
                                            </tr>
                                            }
                                            {(!canDoAction('ADD', 'members', 'members_request_transfer_usa') && !canDoAction('ADD', 'members', 'members_request_transfer_international') && !isGod()) &&
                                                <tr>
                                                    <td className='text-center'>
                                                        <Translate id="misc.search.empty.title" />
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                }
                            >
                                <div className='mb-2'>
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <SearchInput />
                                        <div className='d-flex ml-auto text-right'>
                                            <Refresh />
                                            {canCtiRequest ?
                                                <UncontrolledDropdown tag="span" disabled={!canTransfer} className='ml-2'>
                                                    <DropdownToggle color={(!canDoAction('ADD', 'members', 'members_request_transfer_international') && isGod()) ? 'purple' : 'primary'} disabled={!canTransfer}>
                                                        <i className='mdi mdi-plus mr-1' /><Translate id='misc.add' />
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <CanDoAction action='ADD' componentCode='members' componentPermissionCode='members_request_transfer_international'>
                                                            <DropdownItem tag={Link} to='cti' disabled={membersContext.currentMember.hasTransferRequest}><Translate id='members.profile.transfers.form.title.cti' /></DropdownItem>
                                                            {membersContext.currentMember.hasTransferRequest &&
                                                                <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.hasActive' /></div>
                                                            }
                                                        </CanDoAction>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                :
                                                (canDoAction('ADD', 'members', 'members_request_transfer_usa') || canDoAction('ADD', 'members', 'members_request_transfer_international') || isGod()) &&
                                                <UncontrolledDropdown tag="span" disabled={!canTransfer} className='ml-2'>
                                                    <DropdownToggle color={(!canDoAction('ADD', 'members', 'members_request_transfer_usa') && !canDoAction('ADD', 'members', 'members_request_transfer_international') && isGod()) ? 'purple' : 'primary'} disabled={!canTransfer}>
                                                        <i className='mdi mdi-plus mr-1' /><Translate id='misc.add' />
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <CanDoAction action='ADD' componentCode='members' componentPermissionCode='members_request_transfer_usa'>
                                                            <DropdownItem tag={Link} to='usa' disabled={hasOutstandingBalance || membersContext.currentMember.hasTransferRequest}>
                                                                <Translate id='members.profile.transfers.form.title.usa' />
                                                                {membersContext.currentMember.hasTransferRequest &&
                                                                    <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.hasActive' /></div>
                                                                }
                                                                {getHasOutstandingBalanceMessage()}
                                                            </DropdownItem>
                                                        </CanDoAction>
                                                        <CanDoAction action='ADD' componentCode='members' componentPermissionCode='members_request_transfer_international'>
                                                            <DropdownItem tag={Link} to='international' disabled={hasOutstandingBalance || membersContext.currentMember.hasTransferRequest}>
                                                                <Translate id='members.profile.transfers.form.title.international' />
                                                                {membersContext.currentMember.hasTransferRequest &&
                                                                    <div className='text-muted small'><Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.hasActive' /></div>
                                                                }
                                                                {getHasOutstandingBalanceMessage()}
                                                            </DropdownItem>
                                                        </CanDoAction>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <SpordleTableView />
                            </SpordleTableProvider>
                        )
                    }}
                />
            </CardBody>
        </Card>
    );
}

export default MemberProfileTransfer;