import { FormikDateTime, FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useContext, useState } from "react";
import { Button, Col, Collapse, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { mixed, object, string } from "yup";
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AddSupervisionFormContext } from "./AddSupervisionFormContext";
import Required from "../../../../../../components/formik/Required";

const SearchMembers = ({ renderMember, previous, withOrg }) => {
    const { isLoading, setLoading, toggle } = useContext(AddSupervisionFormContext);
    const { getMembers } = useContext(MembersContext);
    const { getOrganizationCategory, getOrganizationByCategories } = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { federation } = useContext(IdentityRolesContext);
    const [ results, setResults ] = useState(null);

    const getBranches = async() => {
        const categories = await getOrganizationCategory(federation.organisation_id);
        const branchCatId = categories?.find((c) => (c.default_name || '').toLowerCase() === "member branches")?.category_id;
        const branches = await getOrganizationByCategories(federation.organisation_id, { organisation_category_id: branchCatId });

        return (branches || []).sort((a, b) => {
            const aName = displayI18n('name', a.i18n, a.organisation_name, getGenericLocale());
            const bName = displayI18n('name', b.i18n, b.organisation_name, getGenericLocale());
            return aName.localeCompare(bName);
        })
    }

    return (
        <Formik
            initialValues={{
                first_name: '',
                last_name: '',
                birthdate: '',
                organisation_id: '',
                unique_identifier: '',
            }}
            validationSchema={object().shape({
                first_name: string().test({
                    name: 'isRequired',
                    message: <Translate id='form.validation.firstName.required' />,
                    test: function(input){
                        return !!this.parent.unique_identifier || !!input;
                    },

                }),
                organisation_id: string(),
                last_name: string().test({
                    name: 'isRequired',
                    message: <Translate id='form.validation.lastName.required' />,
                    test: function(input){
                        return !!this.parent.unique_identifier || !!input;
                    },
                }),
                birthdate: mixed().test({
                    name: 'isValid',
                    message: <Translate id='form.validation.dateOfBirth.valid' />,
                    test: function(date){
                        return !date || (moment.isMoment(date) && moment(date).isSameOrBefore(moment()));
                    },
                }),
                unique_identifier: string(),
            })}
            onSubmit={(values) => {
                setLoading(true);
                const apiValues = {
                    all: 1,
                    with_official_qualification: 1,
                };

                if(values.unique_identifier){
                    apiValues.unique_identifier = values.unique_identifier;
                }else{
                    apiValues.first_name = values.first_name;
                    apiValues.last_name = values.last_name;

                    if(values.birthdate && moment.isMoment(values.birthdate)){
                        apiValues.birthdate = values.birthdate.format('YYYY-MM-DD');
                    }

                    if(values.organisation_id){
                        apiValues.organisation_id = values.organisation_id;
                    }
                }

                return getMembers(apiValues)
                    .then((members) => {
                        setResults(members);
                        setLoading(false);
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            setLoading(false);
                        }
                    });
            }}
        >
            {(formik) => (
                <Form id="searchMembers">
                    <ModalBody>
                        <Row form>
                            {withOrg &&
                                <Col className="form-group" sm="12">
                                    <Label className="text-muted">Organisation</Label>
                                    <FormikSelect
                                        name="organisation_id"
                                        id="organisation_id"
                                        clearable
                                        disabled={!!formik.values.unique_identifier}
                                        searchKeys={[
                                            `organisation_name`,
                                            `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        renderOption={({ option }) => (
                                            <DisplayI18n
                                                field="name"
                                                i18n={option.i18n}
                                                defaultValue={option.label}
                                            />
                                        )}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return getBranches()
                                                        .then((orgs) => orgs.map((org) => ({ value: org.organisation_id, i18n: org.i18n, label: org.organisation_name })));
                                                default:
                                                    break;
                                            }
                                        }}
                                    />
                                </Col>
                            }
                            <Col className="form-group" sm="6">
                                <Label className="text-muted" for="first_name"><Translate id="form.fields.firstName" /> {!formik.values.unique_identifier && <Required />}</Label>
                                <FormikInputText disabled={!!formik.values.unique_identifier} name="first_name" />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label className="text-muted" for="last_name"><Translate id="form.fields.lastName" /> {!formik.values.unique_identifier && <Required />}</Label>
                                <FormikInputText disabled={!!formik.values.unique_identifier} name="last_name" />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label className="text-muted" for="birthdate"><Translate id="form.fields.dateOfBirth" /></Label>
                                <FormikDateTime
                                    id='birthdate'
                                    name='birthdate'
                                    timeFormat={false}
                                    dateFormat='YYYY-MM-DD'
                                    inputProps={{ disabled: !!formik.values.unique_identifier }}
                                    isValidDate={(current) => moment(current).isBefore(moment())}
                                />
                            </Col>
                            <Col sm="12">
                                <div className="d-flex align-items-center mb-3">
                                    <div className="border-top flex-grow-1" />
                                    <div className="px-3 font-medium small"><Translate id='members.search.memberSearch.filters.or' /></div>
                                    <div className="border-top flex-grow-1" />
                                </div>
                            </Col>
                            <Col sm="12">
                                <Label className="text-muted" for="unique_identifier"><Translate id='members.search.memberSearch.filters.memberNumber' /></Label>
                                <FormikInputText
                                    helper={
                                        <small className="text-muted">
                                            <i className="text-primary mdi mdi-information-outline" /> <Translate id='members.search.memberAdvanceSearch.memberNumber.ignoredFilters' />
                                        </small>
                                    }
                                    translateHelper={false}
                                    name="unique_identifier"
                                    trim
                                />
                            </Col>
                        </Row>
                        <Collapse className="mt-3" isOpen={results !== null}>
                            {results && results.length > 0 ?
                                <div className="border-top">
                                    <div className="text-muted my-2"><Translate id='misc.results' /></div>
                                    <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }}>
                                        {results?.map((member) => (
                                            <React.Fragment key={member.member_id + formik.submitCount}>
                                                {renderMember(member)}
                                            </React.Fragment>
                                        ))}
                                    </PerfectScrollbar>
                                </div>
                                :
                                <div className="text-center">
                                    <i className="d-block mdi mdi-account-search fa-3x line-height-1" />
                                    <div className="font-bold">
                                        <Translate id='misc.search.empty.title' />
                                    </div>
                                    <div className="small text-muted">
                                        <Translate id='officialSupervision.addSupervision.searchMember.noResults' />
                                    </div>
                                </div>
                            }
                        </Collapse>
                    </ModalBody>
                    <ModalFooter>
                        {previous &&
                            <Button onClick={previous} className="w-50 w-md-auto mr-auto" disabled={isLoading || formik.isSubmitting} color="primary" type="button" outline>
                                <Translate id="misc.previous" />
                            </Button>
                        }
                        <Button className="w-50 w-md-auto" disabled={isLoading || formik.isSubmitting} color="primary" type="submit">
                            <Translate id="misc.search" />
                        </Button>
                        <Button className="w-50 w-md-auto" disabled={isLoading || formik.isSubmitting} color="primary" type="button" outline onClick={toggle}>
                            <Translate id="misc.cancel" />
                        </Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default SearchMembers;