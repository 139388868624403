export default{
    "travelPermit.title"      : "Travel Permit",
    "travelPermit.modalTitle" : "Add travel permit",

    // Global
    "travelPermit.noTeam" : "No team on travel permit",
    "travelPermit.fee"    : "Travel Permit Fee",
    "travelPermit.print"  : "Print (PDF)",

    // Columns
    "travelPermit.column.team"      : "Team",
    "travelPermit.column.event"     : "Event",
    "travelPermit.column.location"  : "Location",
    "travelPermit.column.startDate" : "Start date",
    "travelPermit.column.endDate"   : "End date",
    "travelPermit.column.status"    : "Status",

    // Add Modal Step 1
    "travelPermit.addModal.teamLabel"                 : "Team",
    "travelPermit.addModal.travelId"                  : "Reference Number",
    "travelPermit.addModal.titleLabel"                : "Event Name",
    "travelPermit.addModal.titleLabel.tip"            : "Please enter the name of the event or a description of the reason for the travel permit",
    "travelPermit.addModal.eventTypeLabel"            : "Event Type",
    "travelPermit.addModal.eventTypeLabel.home"       : "Home",
    "travelPermit.addModal.eventTypeLabel.away"       : "Away",
    "travelPermit.addModal.travelIdLabel"             : "Travel Type",
    "travelPermit.addModal.hostWebsiteLabel"          : "Host Website",
    "travelPermit.addModal.hostNameLabel"             : "Host Team Name",
    "travelPermit.addModal.nbGamesLabel"              : "Number of games",
    "travelPermit.addModal.hostDivision"              : "Host Division",
    "travelPermit.addModal.hostClass"                 : "Host Class",
    "travelPermit.addModal.hostCategory"              : "Host Category",
    "travelPermit.addModal.hostTeamCategory"          : "Host Team Category",
    "travelPermit.addModal.hostCountry"               : "Host Country",
    "travelPermit.addModal.hostCity"                  : "Host City",
    "travelPermit.addModal.hostOrganization"          : "Host Organization",
    "travelPermit.addModal.hostArena"                 : "Host Arena",
    "travelPermit.addModal.hostProvinceState"         : "Host Province / State",
    "travelPermit.addModal.category.notListed"        : "Not Listed",
    "travelPermit.addModal.category.notListed.helper" : "The category I am looking for is not listed.",

    // Modal step 1 validation
    "travelPermit.addModal.teamRequired"         : "Team is required.",
    "travelPermit.addModal.titleRequired"        : "Title is required.",
    "travelPermit.addModal.eventTypeRequired"    : "Event type is required.",
    "travelPermit.addModal.travelIdRequired"     : "Travel ID is required.",
    "travelPermit.addModal.hostNameRequired"     : "Host arena is required.",
    "travelPermit.addModal.hostCountryRequired"  : "Host country is required.",
    "travelPermit.addModal.hostProvinceRequired" : "Host province is required.",
    "travelPermit.addModal.hostCityRequired"     : "Host city is required.",
    "travelPermit.addModal.hostDivisionRequired" : "Host division is required.",
    "travelPermit.addModal.hostCategoryRequired" : "Host team category is required.",

    // Modal step 2
    "travelPermit.addModal.startDate"             : "Start Date",
    "travelPermit.addModal.endDate"               : "End Date",
    "travelPermit.addModal.notes"                 : "Notes",
    "travelPermit.addModal.attachments"           : "Attachments",
    "travelPermit.addModal.additionalAttachments" : "Additional Attachments",
    "travelPermit.addModal.startDateRequired"     : "Start date is required.",
    "travelPermit.addModal.endDateRequired"       : "End date is required.",

    "travelPermit.lineup.title"                : "Lineup",
    "travelPermit.lineup.editLineup"           : "Edit Lineup",
    "travelPermit.lineup.emptyTeam"            : "No team members available.",
    "travelPermit.lineup.teamMembers"          : "Team Members",
    "travelPermit.lineup.availableTeamMembers" : "Available Members",
    "travelPermit.lineup.addAll"               : "Add all",
    "travelPermit.lineup.removeAll"            : "Remove all",
    "travelPermit.lineup.searchMembers"        : "Search Members",
    "travelPermit.lineup.emptyLineup"          : "No members in this lineup.",
    "travelPermit.lineup.helper"               : "Specify which members will be in your lineup.",
    "travelPermit.lineup.minimum.validation"   : "Please add at least one member.",
    "travelPermit.lineup.position.validation"  : "Please select the member's position.",

    // Sidepanel Header
    "travelPermit.panelHeader.travelId" : "Travel Id",

    // Sidepanel General
    "travelPermit.sidePanelGeneral.tabTitle"        : "General",
    "travelPermit.sidePanelGeneral.TeamNumber"      : "Team Number",
    "travelPermit.sidePanelGeneral.status"          : "Status",
    "travelPermit.sidePanelGeneral.location"        : "Location",
    "travelPermit.sidePanelGeneral.arena"           : "Arena",
    "travelPermit.sidePanelGeneral.city"            : "City",
    "travelPermit.sidePanelGeneral.countryProvince" : "Country and Province/State",
    "travelPermit.sidePanelGeneral.dates"           : "Dates",
    "travelPermit.sidePanelGeneral.startDate"       : "Start date",
    "travelPermit.sidePanelGeneral.endDate"         : "End date",
    "travelPermit.sidePanelGeneral.note"            : "Note",
    "travelPermit.sidePanelGeneral.noOptions"       : "No options",
    "travelPermit.sidePanelGeneral.attachments"     : "Additional Attachments",

    // Sidepanel Host
    "travelPermit.sidePanelHost.tabTitle"     : "Host",
    "travelPermit.sidePanelHost.travel_id"    : "Reference Number",
    "travelPermit.sidePanelHost.name"         : "Event Name",
    "travelPermit.sidePanelHost.eventType"    : "Event type",
    "travelPermit.sidePanelHost.division"     : "Division",
    "travelPermit.sidePanelHost.category"     : "Category",
    "travelPermit.sidePanelHost.class"        : "Class",
    "travelPermit.sidePanelHost.website"      : "Website",
    "travelPermit.sidePanelHost.hostTeamName" : "Host team name",
    "travelPermit.sidePanelHost.nbGames"      : "Number of games",

    // Toasts
    "travelPermit.toast.success" : "Travel permit updated.",
    "travelPermit.toast.fail"    : "An error occurred during the Travel Permit update, please refresh the page et try again.",

    // delete modal
    "travelPermit.deleteModal.message" : "You are about to remove this travel permit.",
    "travelPermit.approve.message"     : "You are about to approve this travel permit.",
    "travelPermit.approve"             : "Approve",
    "travelPermit.decline.message"     : "You are about to decline this travel permit.",
    "travelPermit.decline"             : "Decline",
    "travelPermit.submit.message"      : "You are about to submit this travel permit.",
    "travelPermit.submit"              : "Submit",

    // step 3
    "travelPermit.addModal.submit"             : "Submit permit",
    "travelPermit.addModal.submit.subtitle"    : "The permit will be available for approbation",
    "travelPermit.addModal.notSubmit"          : "Submit later",
    "travelPermit.addModal.notSubmit.subtitle" : "You can bring changes to the permit and submit it later",
}