import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import { FormikInputText, FormikTextArea } from '@spordle/formik-elements';
// Language
import Translate, { DateFormat } from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { Form, Formik } from 'formik';
import React from "react";
import { Alert, Button, Card, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import * as Yup from 'yup';
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import Required from "../../../../../../components/formik/Required";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import SpordlePanelTable from '../../../../../../components/sidePanel/SpordlePanel';
// contexts
import { MembersContext } from '../../../../../../contexts/MembersContext';
import withContexts from '../../../../../../helpers/withContexts';
import SidePanelMemberMemos from './SidePanelMemberMemos';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { AuthContext } from '../../../../../../contexts/contexts';

class MemberMemos extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            addMemo: false,

            // used for the spordle table mock up
            editHover: false,
        };
    }

    toggleAddMemo = () => {
        this.setState((prevState) => ({ addMemo: !prevState.addMemo }))
    }

    render(){
        return (
            <>
                <AnalyticsModal
                    analyticsName='MemberMemos'
                    isOpen={this.state.addMemo}
                    toggle={this.toggleAddMemo}
                    onOpened={() => {
                        // this.getTypes()
                        //     .then(types => {
                        //         this.setOptionsForTypeModalSelect(types)
                        //             .then(() => {
                        //                 this.setState(() => ({medicalTypes: types, medicalTypesLoading: false}))
                        //             })
                        //             .catch((errors) => {
                        //                 console.error(errors)
                        //             })
                        //     })
                        //     .catch((errors) => {
                        //         console.error(errors)
                        //     })
                    }}
                >
                    <Formik
                        initialValues={{
                            name: '',
                            description: '',
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required(<Translate id='form.validation.name.required' />),
                            description: Yup.string().required(<Translate id='form.validation.description.required' />),
                        })}
                        onSubmit={(values, { setSubmitting, setStatus }) => {
                            this.props.MembersContext.createMemberMemo(this.props.MembersContext.currentMember.member_id, this.props.MembersContext.currentMember.organisation.organisation_id, values)
                                .then((memoId) => {
                                    this.memosTableRef.addRow({
                                        created_by: this.props.AuthContext.account,
                                        member_memo_id: memoId,
                                        name: values.name,
                                        description: values.description,
                                    })
                                        .then(() => {
                                            setSubmitting(false);
                                            this.toggleAddMemo();
                                        })
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        if(error.i18n){
                                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                        }else{
                                            setStatus(error.message)
                                        }
                                        setSubmitting(false)
                                    }
                                })
                        }}
                    >
                        {(formik) => {
                            return (
                                <Form>
                                    <OverlayLoader isLoading={formik.isSubmitting}>
                                        <ModalHeader toggle={this.toggleAddMemo}>
                                            <Translate id='members.profile.overview.memberMemos.title' />
                                        </ModalHeader>
                                        <ModalBody>
                                            <FormGroup>
                                                <Label for='name' className='text-muted'><Translate id='form.fields.name' /> <Required /></Label>
                                                <FormikInputText id='name' name='name' trim />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for='description' className='text-muted'><Translate id='form.fields.description' /> <Required /></Label>
                                                <FormikTextArea id='description' name='description' trim rows={5} />
                                            </FormGroup>

                                            {formik.status &&
                                                <Alert color='danger'><Translate id={'contexts.membersContext.' + formik.status} defaultMessageId='misc.error' /></Alert>
                                            }
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                            <Button color='primary' type='button' outline onClick={this.toggleAddMemo} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                        </ModalFooter>
                                    </OverlayLoader>
                                </Form>
                            )
                        }}
                    </Formik>
                </AnalyticsModal>
                <Card body className="card-shadow">
                    <CardSectionTitle title='members.profile.overview.memberMemos.title' />
                    <SpordlePanelTable
                        allowOutsideClick
                        sidePanel={(props) => <SidePanelMemberMemos {...props} />}
                        dataIndex='member_memo_id'
                        table={(panelProps) => {
                            return (
                                <SpordleTableProvider
                                    key={this.props.MembersContext.currentMember?.memos}
                                    id='memos'
                                    ref={(r) => { this.memosTableRef = r; panelProps.spordleTableRef(r); }}
                                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                    tableHover clickable striped
                                    bordered
                                    dataIndex='member_memo_id'
                                    columns={[
                                        {
                                            key: 'createdBy',
                                            label: <Translate id='members.profile.overview.memberMemos.createdBy' />,
                                            sortable: true,
                                            mobile: true,
                                        },
                                        {
                                            key: 'name',
                                            label: <Translate id='form.fields.name' />,
                                            sortable: true,
                                            mobile: true,
                                        },
                                        {
                                            key: 'description',
                                            label: <Translate id='form.fields.description' />,
                                            sortable: true,
                                            mobile: true,
                                        },
                                        {
                                            key: "edit",
                                            label: '-',
                                            className: 'text-center',
                                            dataClassName: 'text-center',
                                            mobile: true,
                                        },
                                    ]}
                                    searchKeys={[
                                        'name',
                                        'description',
                                    ]}
                                    desktopWhen='md'
                                    pagination={5}
                                    renderRow={(key, row) => {
                                        switch (key){
                                            case 'edit':
                                                return <i className="mdi mdi-lead-pencil text-primary" />;
                                            case 'createdBy':
                                                return (
                                                    <div>
                                                        {row.created_by &&
                                                            <>{row.created_by.name} {row.created_by.family_name}</>
                                                        }
                                                        <div className='small'><DateFormat value={row.updated_at ? row.updated_at : row.created_at} format='YYYY-MM-DD HH:MM' /></div>
                                                    </div>
                                                );
                                            default:
                                                break;
                                        }
                                    }}
                                    onColumnClick={(e, memo) => {
                                        switch (e.button){
                                            case 0: // Left mouse button
                                                panelProps.onSingleSelectChange(memo)
                                                break;
                                        }
                                    }}
                                    rowIsHighlighted={(memo) => !!memo.checked}
                                    loadData={(from) => {
                                        switch (from){
                                            case 'REFRESH':
                                            case 'CDM':
                                                return Promise.resolve(this.props.MembersContext.currentMember?.memos || [])
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    {(spordleTable) => (
                                        <>
                                            <div className='mb-2'>
                                                <div className='d-flex flex-wrap justify-content-between'>
                                                    <SpordleTableProvider.SearchInput />
                                                    <div className='d-flex ml-auto text-right'>
                                                        <CanDoAction action='ADD' componentCode='members' componentPermissionCode='members_memo'>
                                                            <Button onClick={this.toggleAddMemo} className='ml-2' color='primary'><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                        </CanDoAction>
                                                    </div>
                                                </div>
                                            </div>
                                            {spordleTable.getData().length === 0 ?
                                                <CanDoAction action='ADD' componentCode='members' componentPermissionCode='members_memo'>
                                                    <Table bordered striped hover>
                                                        <thead>
                                                            <tr>
                                                                <th onMouseEnter={() => this.setState(() => ({ typeHover: true }))} onMouseLeave={() => this.setState(() => ({ typeHover: false }))} className={stringBuilder({ 'bg-light': this.state.typeHover })}><Translate id='form.fields.name' /></th>
                                                                <th onMouseEnter={() => this.setState(() => ({ severityHover: true }))} onMouseLeave={() => this.setState(() => ({ severityHover: false }))} className={stringBuilder({ 'bg-light': this.state.severityHover })}><Translate id='form.fields.description' /></th>
                                                                <th onMouseEnter={() => this.setState(() => ({ editHover: true }))} onMouseLeave={() => this.setState(() => ({ editHover: false }))} className={stringBuilder('text-center', { 'bg-light': this.state.editHover })}>-</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={3} className='text-center'>
                                                                    <div><Translate id='members.profile.overview.memberMemos.table.hint.none1' /></div>
                                                                    <button className='font-medium reset-btn text-primary' type='button' onClick={this.toggleAddMemo}><i className='mdi mdi-plus' /><Translate id='members.profile.overview.memberMemos.table.hint.none2' /></button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </CanDoAction>
                                                :
                                                <SpordleTableView />
                                            }
                                        </>
                                    )}
                                </SpordleTableProvider>
                            )
                        }}
                    />
                </Card>
            </>
        )
    }
}

export default withContexts(MembersContext, AuthContext)(MemberMemos);