import { DateFormat } from '@spordle/intl-elements'
import { useContext } from 'react'
import { useSWRConfig } from 'swr'
import { AxiosIsCancelled } from '../../../api/CancellableAPI'
import SidePanel from '../../../components/sidePanel/SidePanel'
import { SpordletablePanelContext } from '../../../components/sidePanel/SpordlePanel'
import { fail } from '@spordle/toasts'
import UserDisplay from '../../../components/userDisplay/UserDisplay'
import UserImg from '../../../components/UserImg'
import { TeamsContext } from '../../../contexts/TeamsContext'
import { DisplayI18n } from '../../../helpers/i18nHelper'

const DeleteRosterAction = ({ teamMember, msg }) => {
    const teamsContext = useContext(TeamsContext);
    const sidePanelContext = useContext(SpordletablePanelContext);
    const { mutate } = useSWRConfig();

    return (
        <SidePanel.MenuDelete
            action='DELETE' componentCode='teams' componentPermissionCode='team_members'
            onConfirm={() => {
                return teamsContext.deleteTeamMember(teamsContext.cachedTeam.team_id, teamMember.team_member_id)
                    .then(async() => {
                        mutate([ 'getTeamsSettings', teamsContext.cachedTeam.team_id ]);
                        teamsContext.presetCurrentTeam({ ...teamsContext.cachedTeam, team_status: null });
                        sidePanelContext.forceCloseSidePanel();
                        await teamsContext.getTeam(teamsContext.cachedTeam.team_id, true)
                        await sidePanelContext.tableRef.deleteRow(teamMember.team_member_id);
                    }).catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })
            }}
            label='misc.delete'
            translateModalMsg
            modalMsg={msg}
            translateActionButtonText
            actionButtonText='misc.delete'
            modalContent={
                <UserDisplay className='justify-content-center'>
                    <UserDisplay.Container>
                        <UserImg
                            abbr={teamMember.member.first_name.charAt(0) + teamMember.member.last_name.charAt(0)}
                            src={teamMember.member.picture?.full_path}
                            filePos={teamMember.member.picture?.file_position}
                            width={40}
                            height={40}
                            alt={teamMember.member.first_name + ' ' + teamMember.member.last_name}
                        />
                    </UserDisplay.Container>
                    <UserDisplay.Container>
                        <UserDisplay.Title>{teamMember.member.first_name + ' ' + teamMember.member.last_name}</UserDisplay.Title>
                            #{teamMember.member.unique_identifier}
                        <UserDisplay.Subtitle><DateFormat value={teamMember.member.birthdate} /></UserDisplay.Subtitle>
                    </UserDisplay.Container>
                </UserDisplay>
            }
        />
    )
}

export default DeleteRosterAction
