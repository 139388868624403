import { Tooltip } from '@mantine/core';
import { useEffect, useRef, useState } from 'react'

const TruncateWithTooltip = ({ text, maxWidth = 350 }) => {
    const maxWidthText = maxWidth + 100 // 400

    const widthRef = useRef();

    const [ toolTipEnabled, enableToolTip ] = useState(false);

    useEffect(() => {
        if(widthRef?.current){
            enableToolTip(widthRef.current.offsetWidth === maxWidth);
        }
    }, [ text ])

    return (
        <Tooltip
            withArrow
            position='top'
            label={<div style={{ maxWidth: 300 }} className='text-wrap'>{text}</div>}
            disabled={!toolTipEnabled || !text}
        >
            <div
                className='text-truncate'
                style={{ maxWidth: maxWidthText }}
            >
                <div className='d-flex mr-1'><div ref={widthRef} className='text-truncate' style={{ maxWidth: maxWidth }}>{text}</div></div>
            </div>
        </Tooltip>
    )
}

export default TruncateWithTooltip;