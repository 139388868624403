export default{
    "gameIncident.title"                     : "Rapport d'incident",
    "gameIncident.label.anonymous"           : "Anonyme",
    "gameIncident.search.alreadyAdded"       : "Déjà ajouté",
    "gameIncident.sidePanel.delete.incident" : "Vous êtes sur le point de supprimer cet incident de joueur",
    "gameIncident.sidePanel.delete.official" : "Vous êtes sur le point de supprimer cet officiel du rapport d'incident",
    "gameIncident.sidePanel.submit.btn"      : "Soumettre le rapport",
    "gameIncident.sidePanel.resubmit.btn"    : "Re-soumettre le rapport",
    "gameIncident.sidePanel.submit.msg"      : "Vous êtes sur le point de soumettre le rapport d'incident",
    "gameIncident.sidePanel.resubmit.msg"    : "Vous êtes sur le point de soumettre ce rapport d'incident à nouveau",
    "gameIncident.sidePanel.lastUpdated"     : "Ce rapport a été mis à jour le {date}",

    "gameIncident.addModal.title"                       : "Ajouter rapport d'incident (Étape {step} de {stepNb})",
    "gameIncident.addModal.steps.officials.title"       : "Officiels",
    "gameIncident.addModal.steps.officials.add.title"   : "Ajouter un officiel",
    "gameIncident.addModal.steps.teams.title"           : "Équipes",
    "gameIncident.addModal.steps.teams.other"           : "Autre",
    "gameIncident.addModal.steps.teams.existing"        : "Existante",
    "gameIncident.addModal.steps.teams.nonExisting"     : "Non existante",
    "gameIncident.addModal.steps.match.title"           : "Info de la partie",
    "gameIncident.addModal.steps.incident.title"        : "Incident",
    "gameIncident.addModal.steps.status.title"          : "Souhaitez-vous soumettre le rapport d'incident maintenant ?",
    "gameIncident.addModal.steps.status.btn.yes"        : "Oui",
    "gameIncident.addModal.steps.status.btn.yes.helper" : "Je souhaite soumettre le rapport",
    "gameIncident.addModal.steps.status.btn.no"         : "Non",
    "gameIncident.addModal.steps.status.btn.no.helper"  : "Je ne veux pas soumettre le rapport",

    "gameIncident.addModal.label.season"               : "Saison",
    "gameIncident.addModal.label.class"                : "Classe",
    "gameIncident.addModal.label.division"             : "Division",
    "gameIncident.addModal.label.branch"               : "Branche",
    "gameIncident.addModal.label.org"                  : "Organisation",
    "gameIncident.addModal.label.category"             : "Catégorie",
    "gameIncident.addModal.label.league"               : "Ligue",
    "gameIncident.addModal.label.location"             : "Location",
    "gameIncident.addModal.label.game"                 : "Partie #",
    "gameIncident.addModal.label.coach"                : "Entraîneur",
    "gameIncident.addModal.label.coachList"            : "Liste des entraîneurs",
    "gameIncident.addModal.label.coach.option.other"   : "Entraîneur non-listé",
    "gameIncident.addModal.label.coach.other"          : "Nom entraîneur",
    "gameIncident.addModal.label.manager"              : "Gérant",
    "gameIncident.addModal.label.managerList"          : "Liste du personnel",
    "gameIncident.addModal.label.manager.option.other" : "Gérant non listé",
    "gameIncident.addModal.label.manager.other"        : "Nom gérant",
    "gameIncident.addModal.label.team"                 : "Équipe",
    "gameIncident.addModal.label.officials"            : "Officiels",
    "gameIncident.addModal.label.incident"             : "Incident",
    "gameIncident.addModal.label.player"               : "Joueur",
    "gameIncident.addModal.label.period"               : "Période",
    "gameIncident.addModal.label.time"                 : "Temps",
    "gameIncident.addModal.label.minutes"              : "Minutes",
    "gameIncident.addModal.label.seconds"              : "Secondes",
    "gameIncident.addModal.label.infractionType"       : "Type de pénalité",
    "gameIncident.addModal.label.infraction"           : "Pénalité",
    "gameIncident.addModal.label.injury"               : "A eu une blessure",
    "gameIncident.addModal.label.returned"             : "Retourné au jeu",
    "gameIncident.addModal.label.notReturned"          : "N'est pas retourné au jeu",
    "gameIncident.addModal.label.note"                 : "Note",
    "gameIncident.addModal.label.jersey"               : "Numéro de chandail",
    "gameIncident.addModal.label.date"                 : "Date",
    "gameIncident.addModal.label.officialType"         : "Type d'officiel",

    "gameIncident.addModal.label.gamePeriod.FIRST"          : "Première",
    "gameIncident.addModal.label.gamePeriod.SECOND"         : "Seconde",
    "gameIncident.addModal.label.gamePeriod.THIRD"          : "Troisième",
    "gameIncident.addModal.label.gamePeriod.OVERTIME"       : "Prolongation",
    "gameIncident.addModal.label.gamePeriod.SHOOTOUT"       : "Tirs au but",
    "gameIncident.addModal.label.gamePeriod.PRE_POST_GAME"  : "Avant le jeu / Après le jeu",
    "gameIncident.addModal.steps.officials.type.REFEREE"    : "Arbitre",
    "gameIncident.addModal.steps.officials.type.LINESMAN"   : "Juge de ligne",
    "gameIncident.addModal.steps.officials.type.SUPERVISOR" : "Superviseur",

    "gameIncident.addModal.validation.player"        : "Veuillez sélectionner un joueur",
    "gameIncident.addModal.validation.penaltyType"   : "Veuillez sélectionner un type de pénalité",
    "gameIncident.addModal.validation.penalty"       : "Veuillez sélectionner une pénalité",
    "gameIncident.addModal.validation.team"          : "Veuillez sélectionner une équipe",
    "gameIncident.addModal.validation.season"        : "Veuillez sélectionner une saison",
    "gameIncident.addModal.validation.division"      : "Veuillez sélectionner une division",
    "gameIncident.addModal.validation.category"      : "Veuillez sélectionner une catégorie",
    "gameIncident.addModal.validation.phone"         : "Le numéro de téléphone est requis pour cet officiel",
    "gameIncident.addModal.validation.email"         : "L'adresse courriel est requise pour cet official",
    "gameIncident.addModal.validation.date"          : "Veuillez saisir une date",
    "gameIncident.addModal.validation.officialType"  : "Veuillez le type d'officiel",
    "gameIncident.addModal.validation.date.valid"    : "Veuillez saisir une date valide",
    "gameIncident.addModal.validation.date.future"   : "La date de l'incident ne peut être dans le futur",
    "gameIncident.addModal.validation.jersey.max"    : "Veuillez choisir un numéro de chandail en dessous de 100",
    "gameIncident.addModal.validation.time.min"      : "Veuillez entrer un nombre qui est pareil ou dessus de 0",
    "gameIncident.addModal.validation.time.max"      : "Veuillez entrer un nombre en dessous de {max}",
    "gameIncident.addModal.validation.teamWarning"   : "Attention",
    "gameIncident.addModal.validation.twoOtherTeams" : "Une des deux équipes doit être une équipe existante",
    "gameIncident.addModal.validation.twoSameTeams"  : "Veuillez choisir deux équipes différentes",
    "gameIncident.addModal.validation.status"        : "Veuillez choisir un statut d'incident",
    "gameIncident.addModal.validation.organisation"  : "Veuillez sélectionner une organisation",
    "gameIncident.addModal.validation.teamName"      : "Veuillez indiquer le nom de l'équipe",
    "gameIncident.addModal.validation.jerseyNb"      : "Le numéro de chandail doit être un chiffre",
    "gameIncident.addModal.validation.coach.other"   : "Veuillez spécifier le nom du coach ou supprimer la valeur du menu déroulant",
    "gameIncident.addModal.validation.manager.other" : "Veuillez spécifier le nom du manager ou supprimer la valeur du menu déroulant",
    "gameIncident.addModal.validation.officials.min" : "Veuillez ajouer au moins un officiel.",

    "gameIncident.sidePanel.official.filledRequest"       : "A fait la requête",
    "gameIncident.sidePanel.export.pdf"                   : "Exporter en PDF",
    "gameIncident.sidePanel.export.error.3382"            : "Une erreur s'est produite pendant la génération du PDF",
    "gameIncident.sidePanel.export.toast.info"            : "Si votre pdf n'a pas téléchargé automatiquement, essayez en <a>cliquant ici.</a>",
    "gameIncident.sidePanel.export.toast.generating"      : "Génère le pdf...",
    "gameIncident.sidePanel.export.toast.generating.info" : "Votre pdf est présentement en train d'être généré",
    "gameIncident.sidePanel.export.toast.download"        : "Téchargement !",

    "gameIncident.addModal.teams.home.label" : "Équipe locale",
    "gameIncident.addModal.teams.away.label" : "Équipe visite",

    "gameIncident.addModal.officials.title"        : "Ajouter officiel",
    "gameIncident.addModal.officials.btn.subtitle" : "Cliquez ici pour ajouter un officiel",

    "gameIncident.addModal.incident.title"        : "Ajouter incident",
    "gameIncident.addModal.incident.btn.subtitle" : "Cliquez ici pour ajouter un incident",
}