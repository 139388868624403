import SpordleTableProvider, { Refresh, SearchInput, SpordleTableContext, SpordleTableView } from '@spordle/datatables';
import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { useFormikContext } from 'formik';
import { useContext, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    Fade, Table
} from "reactstrap";
import SearchImage from '../../../../../assets/images/Search_Image.png';
import HoverableComponent from '../../../../../components/HoverableComponent';
import SpordlePanelTable from '../../../../../components/sidePanel/SpordlePanel';
import { OnlineStoreContext } from '../../../../../contexts/OnlineStoreContext';
import { PeriodsContext } from '../../../../../contexts/contexts';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import OnlineStoreRegistrationAdd from './components/OnlineStoreRegistrationAdd';
import OnlineStoreRegistrationSidepanel from './components/OnlineStoreRegistrationSidepanel';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { ReportsContext } from '../../../../../contexts/ReportsContext';

/**
this manages the registration table + it's sidepanel and Add modal
*/
const OnlineStoreRegistrations = (props) => {
    const [ addModal, setAddModal ] = useState(false);
    const toggleAddModal = () => setAddModal(!addModal);
    const onlineStoreContext = useContext(OnlineStoreContext);
    const reportsContext = useContext(ReportsContext);
    const i18nContext = useContext(I18nContext);
    const formik = useFormikContext();
    const periodsContext = useContext(PeriodsContext);

    const FROM = props.from;
    return (
        <Card className="card-shadow">
            <CardBody>
                <div className="d-flex align-items-end pb-1 border-bottom mb-3">
                    <div className="h4 font-bold card-title mb-0"><Translate id='onlineStore.components.onlineStoreItemsFees.registration.title' /></div>
                </div>
                <SpordlePanelTable
                    ref={props.registrationRef}
                    allowOutsideClick
                    dataIndex='registration_fee_id'
                    sidePanel={(props) => <OnlineStoreRegistrationSidepanel {...props} saveItems={() => FROM !== 'CREATE' ? formik.handleSubmit() : undefined} />}
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='OnlineStoreItemsFeesRegistrationTable'
                                tableHover bordered striped
                                ref={panelProps.spordleTableRef}
                                desktopWhen
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                dataIndex='registration_fee_id'
                                searchKeys={[
                                    `fee.i18n.${i18nContext.getGenericLocale()}.name`,
                                    'fee.name',
                                ]}
                                defaultSorting={'+display_order'}
                                loadData={(from, _, spordleTable) => {
                                    switch (from){
                                        case 'REFRESH':
                                            spordleTable.setLoading(true);
                                        case 'CDM':
                                            return onlineStoreContext.getOnlineStoreRegistrationFee(props.onlineStoreId, { period_id: periodsContext.selectedPeriod.period_id })
                                                .then((registrations) => {
                                                    return reportsContext.getRegistrationCountReport({ online_store_id: props.onlineStoreId, type: 'REGISTRATION' }).then((count) => {
                                                        const mappedRegistrations = registrations.map((registration) => {
                                                            return ({
                                                                ...registration,
                                                                ...registration.registration_fee,
                                                                display_order: registration.display_order,
                                                                items_sold: count.find((item) => (item.item_id === registration.registration_fee.registration_fee_id)).item_sold,
                                                            })
                                                        });
                                                        formik.setFieldValue('registrationFees', mappedRegistrations);
                                                        return mappedRegistrations;
                                                    })
                                                });
                                        default:
                                            break;
                                    }
                                }}
                                columns={[
                                    {
                                        label: <Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.display_order' />,
                                        key: 'display_order',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink text-center',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.registration' />,
                                        key: 'registration',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.price' />,
                                        key: 'price',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                    },
                                    {
                                        label: <Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.items_sold' />,
                                        key: 'items_sold',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink text-right',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.status' />,
                                        key: 'status',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                        sortable: true,
                                    },
                                ]}
                                renderRow={(columnKey, registrationFee) => {
                                    switch (columnKey){
                                        case 'price':
                                            const affiliationFees = registrationFee.affiliation_fees?.total?.[registrationFee.member_type?.member_type_id];
                                            const affiliation_fees = registrationFee.exclude_affiliation_fee == '1' ?
                                                {
                                                    amount: 0,
                                                    early_amount: 0,
                                                    late_amount: 0,
                                                }
                                                :
                                                {
                                                    amount: affiliationFees?.amount || 0, //only amount is used on affiliation fees (no early or late amount)
                                                    early_amount: affiliationFees?.early_amount || 0, // unused ^^
                                                    late_amount: affiliationFees?.late_amount || 0, // unused ^^
                                                }

                                            return (
                                                <div>
                                                    <div className="font-medium">
                                                        <CurrencyFormat value={(parseInt(registrationFee.fee?.amount) + parseInt(affiliation_fees.amount)) / 100} />
                                                        {registrationFee.fee.tax_class &&
                                                            <sup className='ml-1'>(<Translate id='catalog.registrationFees.add.form.tax' />)</sup>
                                                        }
                                                    </div>
                                                    { !!registrationFee.fee.early_amount &&
                                                        <div className={`small mt-1 d-inline-block${registrationFee.fee.late_amount ? ' mr-2 pr-2 border-right' : ''}`}>
                                                            <div className="font-medium"><Translate id='catalog.registrationFees.add.form.earlyFee' /> : <CurrencyFormat value={(parseInt(registrationFee.fee.early_amount) + parseInt(affiliation_fees.amount)) / 100} /></div>
                                                            <span><Translate id='catalog.registrationFees.add.form.before' /> <DateFormat value={registrationFee.fee.early_amount_until} /> <DateFormat value={registrationFee.fee.early_amount_until} format={'h:mm a'} /></span>
                                                        </div>
                                                    }
                                                    { !!registrationFee.fee.late_amount &&
                                                        <div className="small mt-1 d-inline-block">
                                                            <div className="font-medium"><Translate id='catalog.registrationFees.add.form.lateFee' /> : <CurrencyFormat value={(parseInt(registrationFee.fee.late_amount) + parseInt(affiliation_fees.amount)) / 100} /></div>
                                                            <span><Translate id='catalog.registrationFees.add.form.after' /> <DateFormat value={registrationFee.fee.late_amount_after} /> <DateFormat value={registrationFee.fee.late_amount_after} format={'h:mm a'} /></span>
                                                        </div>
                                                    }
                                                </div>
                                                // <div className="font-medium"><CurrencyFormat value={(registrationFee.affiliation_fees ? parseInt(registrationFee.affiliation_fees.total?.[registrationFee.member_type?.member_type_id]?.amount || 0) + parseInt(registrationFee.fee.amount) : registrationFee.fee.amount) / 100} /></div>
                                            )
                                        case 'items_sold':
                                            return (
                                                <div>{registrationFee.items_sold || 0}</div>
                                            )
                                        case 'display_order':
                                            return (
                                                <div>{registrationFee.display_order || 0}</div>
                                            )
                                        case 'registration':
                                            return (
                                                <>
                                                    <div className='font-medium'>
                                                        <DisplayI18n
                                                            field='short_name'
                                                            i18n={registrationFee.division?.i18n}
                                                            defaultValue={registrationFee.division?.short_name}
                                                        />
                                                        {registrationFee.team_category?.class &&
                                                            <>
                                                                <span className='mx-1'>-</span>
                                                                <DisplayI18n
                                                                    field='short_name'
                                                                    i18n={registrationFee.team_category?.class?.i18n}
                                                                    defaultValue={registrationFee.team_category?.class?.short_name}
                                                                />
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="font-bold h6 mb-0">
                                                        <DisplayI18n field='name' i18n={registrationFee.fee.i18n} defaultValue={registrationFee.fee.name} />
                                                    </div>


                                                    {/* Available Place Label */}
                                                    {registrationFee.available_place != null ?
                                                        <span className="small"><Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.registration.available' values={{ amount: registrationFee.available_place }} /> - <DisplayI18n field='name' defaultValue={registrationFee.member_type.name} i18n={registrationFee.member_type.i18n} /></span>
                                                        :
                                                        <span className="small"><Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.registration.unlimited' /> - <DisplayI18n field='name' defaultValue={registrationFee.member_type.name} i18n={registrationFee.member_type.i18n} /></span>
                                                    }

                                                    {/* Warning for inactive category */}
                                                    {
                                                        (registrationFee.division?.active === "0"
                                                        || registrationFee.team_category?.active === "0"
                                                        || registrationFee.team_category?.active_in_this_period === "0"
                                                        || registrationFee.team_category?.class?.active === "0") &&
                                                        <div className="small text-warning">
                                                            <i className="mdi mdi-alert-outline" /> <Translate id='catalog.registrationFees.inactiveCategory' />
                                                        </div>
                                                    }
                                                </>
                                            );

                                        case 'status':
                                            let myStatus = registrationFee.status;
                                            if(registrationFee.registration_fee.active == 0) // overwrite with inactive if fee is active=0
                                                myStatus = 'INACTIVE';
                                            switch (myStatus){
                                                case 'ACTIVE':
                                                    return <span className="text-success"><Translate id={`onlineStore.components.onlineStoreItemsFees.status.${myStatus}`} /></span>;
                                                case 'SUSPENDED':
                                                    return <Translate id={`onlineStore.components.onlineStoreItemsFees.status.${myStatus}`} />;
                                                case 'SOLD_OUT':
                                                    return <span className="text-danger"><Translate id={`onlineStore.components.onlineStoreItemsFees.status.${myStatus}`} /></span>;
                                                case 'INACTIVE':
                                                    return <span className="text-danger"><i className="mdi mdi-alert-outline mr-1" /><Translate id={`onlineStore.components.onlineStoreItemsFees.status.${myStatus}`} /></span>;
                                                default:
                                                    break;
                                            }

                                        default:
                                            break;
                                    }
                                }}
                                onColumnClick={(e, registration) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            panelProps.onSingleSelectChange(registration);
                                            break;
                                    }
                                }}
                                rowIsHighlighted={(registration) => registration.checked}
                                emptyLayout={
                                    <div className='text-center'>
                                        <div>
                                            <img src={SearchImage} alt="Search icon" height='150px' />
                                        </div>
                                        <div>
                                            <div className="h3"><Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.empty' /></div>
                                        </div>
                                    </div>
                                }
                            >
                                <div className='mb-2'>
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <SearchInput />
                                        <div className='d-flex ml-auto text-right nowrap'>
                                            {FROM === "PROFILE" && <Refresh />}
                                            <OnlineStoreRegistrationAdd
                                                isOpen={addModal}
                                                toggle={toggleAddModal}
                                                submit={() => {
                                                    if(FROM !== "CREATE")
                                                        formik.handleSubmit();
                                                }}
                                            />
                                            <Button className='ml-2' color='primary' onClick={toggleAddModal}><i className='mdi mdi-plus' /> <Translate id='misc.add' /></Button>
                                        </div>
                                    </div>
                                </div>
                                <SpordleTableContext.Consumer>
                                    {(spordleTable) => (
                                        spordleTable.getData().length === 0 && spordleTable.state.loadingState === 'success' ? // loadingState check prevents some visual glitch
                                            <Fade>
                                                <Table bordered striped hover>
                                                    <thead>
                                                        <tr>
                                                            <HoverableComponent>
                                                                {(isHovering) => <th className={stringBuilder({ 'bg-light': isHovering })}><Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.registration' /></th>}
                                                            </HoverableComponent>
                                                            <HoverableComponent>
                                                                {(isHovering) => <th className={stringBuilder('th-shrink', { 'bg-light': isHovering })}><Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.status' /></th>}
                                                            </HoverableComponent>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={2} className='text-center'>
                                                                <div><Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.hint1' /></div>
                                                                <button className='font-medium reset-btn text-primary' type='button' onClick={toggleAddModal}><i className='mdi mdi-plus' /><Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.hint2' /></button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Fade>
                                            :
                                            <SpordleTableView />
                                    )}
                                </SpordleTableContext.Consumer>
                            </SpordleTableProvider>
                        )
                    }}
                />
            </CardBody>
        </Card>
    );
}

export default OnlineStoreRegistrations;
