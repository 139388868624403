/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */
import Translate from '@spordle/intl-elements';
import React, { createContext } from 'react';
import {
    ModalHeader
} from "reactstrap";
import AnalyticsModal from '../../../../../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../../../components/loading/OverlayLoader';
import { fail } from "@spordle/toasts";
import { separateFilesPerSize } from '../../../../../../../../components/uploader/uploadHelpers';
import { PeriodsContext } from '../../../../../../../../contexts/contexts';
import { MembersContext } from '../../../../../../../../contexts/MembersContext';
// contexts
import { OrganizationContext } from '../../../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';
import withContexts from '../../../../../../../../helpers/withContexts';
import MemberSuspensionAdd1 from './MemberSuspensionAdd1';
import MemberSuspensionAdd2 from './MemberSuspensionAdd2';

/** @type {React.Context<Omit<MemberSuspensionAddContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const MemberSuspensionAddContext = createContext()
MemberSuspensionAddContext.displayName = 'MemberSuspensionAddContext';

class MemberSuspensionAddContextProvider extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            isLoading: false,
            currentView: 'suspensionInfo',
            formData: this.props.initFormData || {},

            // the contexts for these doesn't handle caching, so we do it here
            // cache to prevent recalling the API everytime the user clicks previous or next to switch steps
            suspensionTypes: null,
            suspensions: [],
            memberTeams: null,
            positions: null,
        }

        this.views = {
            suspensionInfo: 'suspensionInfo',
            suspensionNotes: 'suspensionNotes',
        }
        this.memberId = this.props.memberId;
    }

    componentDidMount(){
        if(this.props.linkToMaltreatment && this.props.team_id){
            this.props.MembersContext.getMemberTeams(this.props.memberId)
                .then((memberTeams) => {
                    const memberTeam = memberTeams.find(((team) => team.team_id === this.props.team_id))
                    if(memberTeam)
                        this.setState(() => ({ memberTeam: memberTeam }))
                }).catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
    }

    onSuccess = () => {
        this.props.onSuccess?.();
        this.props.refreshTable?.();
        this.toggleModal()
    }

    toggleModal = () => this.props.toggle()

    setLoading = (_isLoading) => this.setState(() => ({ isLoading: _isLoading }))

    setData = (_data) => this.setState((prevState) => ({ formData: { ...prevState.formData, ..._data } }))

    // caching
    setSuspensionTypes = (_suspensionTypes) => this.setState(() => ({ suspensionTypes: _suspensionTypes }));

    setSuspensions = (_suspensions) => this.setState(() => ({ suspensions: _suspensions }));

    setMemberTeams = (_memberTeams) => this.setState(() => ({ memberTeams: _memberTeams }));

    setPositions = (_positions) => this.setState(() => ({ positions: _positions }));

    modalOnClosed = () => {
        this.goToView(this.views.suspensionInfo);
        this.setLoading(false);
        this.setState(() => ({
            formData: {},

            suspensionTypes: null,
            suspensions: null,
            memberTeams: null,
            positions: null,
        }))
    }

    goToView = (newView) => {
        // try to keep the calls to this function static for each view, if a skip is to be managed, do it from here, check HCR-site RegistrationContext for an example
        switch (newView){
            case this.views.suspensionInfo:
                this.setState(() => ({ currentView: newView }))
                break;
            case this.views.suspensionNotes:
                this.setState(() => ({ currentView: newView }))
                break;
        }
    }

    submitMemberSuspensionCreation = (currentMemberId, formattedValues, filteredAttachments) => {
        return this.props.MembersContext.createMemberSuspension(currentMemberId, formattedValues)
            .then(async(id) => {
                // FilteredAttachments have subarray of files in which the total size does not exceend 14MB.
                // Avoid php errors for the api
                if(filteredAttachments.length > 0){
                    await Promise.all(
                        filteredAttachments.map((filesArr) => {
                            return this.props.MembersContext.createMemberSuspensionAttachment(
                                currentMemberId,
                                id,
                                filesArr,
                            ).catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                }
                            })
                        }),
                    );
                }

                if(this.props.linkToMaltreatment){
                    const newValues = { member_suspensions: [ { member_suspension_id: id } ] };
                    await this.props.linkSuspension(newValues)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                }
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    createMemberSuspension = (formData) => {
        const filteredAttachments = separateFilesPerSize(formData.attachments);

        const formattedValues = {
            organisation_id: this.props.OrganizationContext.organisation_id,
            period_id: this.props.PeriodsContext.selectedPeriod.period_id,

            suspension_id: formData.infraction === 'SUSPENSION' ? formData.suspension_id : '',
            other_infraction: formData.infraction === 'OTHER' ? formData.other_suspension : '',

            suspension_type_id: formData.suspension_type_id,

            duration: formData.duration,
            game_suspended: formData.duration === 'DEFINITE' ? formData.game_suspended : '',
            end_date: formData.duration === 'DEFINITE' ? formData.end_date : '',

            incident_date: formData.incident_date,
            received_date: formData.received_date,
            location: formData.location,

            member_id: formData.member_id,
            team_id: formData.team_id,
            position_id: formData.position_id,
            opposing_team_name: formData.opposing_team_name,
            comment: formData.comment,
            attachments: filteredAttachments.splice(0, 1)?.[0],
        }

        if(formData.discrimination_reason?.length > 0) formattedValues.discrimination_reason = formData.discrimination_reason.join(',');

        // In the case it comes from maltreament componaints
        let currentMemberId = this.props.memberId || formData.member_id;
        if(!Array.isArray(currentMemberId)){
            currentMemberId = [ this.props.memberId || formData.member_id ]
        }

        const promises = [];
        for(let i = 0; i < currentMemberId.length; i++){
            const memberId = currentMemberId[i];

            if(formData.memberTypes.length > 0){
                for(let j = 0; j < formData.memberTypes.length; j++){
                    const memberType = formData.memberTypes[j];
                    const optionnalData = {};
                    if(memberType.fine)
                        optionnalData.fine = (memberType.fine * 100).toFixed(0)
                    if(memberType.fee)
                        optionnalData.fee = (memberType.fee * 100).toFixed(0)

                    promises.push(this.submitMemberSuspensionCreation(
                        memberId,
                        {
                            ...formattedValues,
                            member_id: memberId,
                            member_type_id: memberType.memberTypeId,
                            ...optionnalData,
                        },
                        filteredAttachments,
                    ))
                }
            }else{
                promises.push(this.submitMemberSuspensionCreation(memberId, { ...formattedValues, member_id: memberId }, filteredAttachments))
            }
        }

        return Promise.all(promises)
            .then(() => {
                this.onSuccess();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    render(){
        const Wrapper = this.props.skipModal ? React.Fragment : AnalyticsModal

        return (
            <MemberSuspensionAddContext.Provider value={{ ...this }}>
                <Wrapper isOpen={this.props.isOpen} onClosed={this.modalOnClosed} analyticsName='MemberSuspensionAddContextProvider'>
                    <OverlayLoader isLoading={this.state.isLoading}>
                        <ModalHeader tag='div' toggle={this.toggleModal}>
                            <Translate id='members.profile.suspensions.add.title' />
                        </ModalHeader>
                        {this.state.currentView === this.views.suspensionInfo &&
                            <MemberSuspensionAdd1 />
                        }
                        {this.state.currentView === this.views.suspensionNotes &&
                            <MemberSuspensionAdd2 />
                        }
                    </OverlayLoader>
                </Wrapper>
            </MemberSuspensionAddContext.Provider>
        );
    }
}

export default withContexts(OrganizationContext, PeriodsContext, MembersContext)(MemberSuspensionAddContextProvider);