import React, { useContext, useState } from "react";
import {
    Table,
    Spinner,
    Card,
    CardBody,
    Badge,
    Collapse,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { FormattedPlural } from "react-intl";

// contexts
import { OnlineStoreContext } from '../../../../../../contexts/OnlineStoreContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { PeriodsContext } from '../../../../../../contexts/contexts';
import withContexts from '../../../../../../helpers/withContexts';
import { Link, useHistory, withRouter } from "react-router-dom";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import { fail, success } from '@spordle/toasts';
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { stringBuilder } from "@spordle/helpers";
import { Tooltip } from "@mantine/core";

class SidePanelPaymentInfo extends React.Component{

    state = {
        isOpen: false,
    }

    onDeletePaymentReception = () => {
        this.props.toggle();
        this.props.tableRef.refreshTable();
    }

    onDeleteRefund = () => {
        this.props.toggle();
        this.props.tableRef.refreshTable();
    }

    render(){
        return (
            <div className='p-3 border-bottom'>
                <div
                    className='clickable d-flex justify-content-between' onClick={() => {
                        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
                    }}
                >
                    <div>
                        <div className='h4 font-bold mb-1'><Translate id='clinics.profile.attendees.sidepanel.paymentInfo' /></div>
                        <div className='d-flex align-items-center'>
                            {!this.props.invoice ?
                                <div>#{this.props.getCommonValue('invoice').invoice_number}</div>
                                :
                                <Link to={`invoice?invoiceNumber=${this.props.getCommonValue('invoice').invoice_number}`}>#{this.props.getCommonValue('invoice').invoice_number}</Link>
                            }
                            <Badge
                                color={
                                    (this.props.getCommonValue('invoice').status === 'COMPLETED') ?
                                        'success'
                                        : (this.props.getCommonValue('invoice').status === 'PENDING' ||
                                        this.props.getCommonValue('invoice').status === 'NEED_REVIEW' ||
                                        this.props.getCommonValue('invoice').status === 'PARTIALLY_PAID') ?
                                            'warning'
                                            : 'danger'
                                }
                                size="lg" pill className="ml-2 px-3"
                            >
                                <Translate id={`clinics.profile.attendees.sidepanel.status.${this.props.getCommonValue('invoice').status}`} />
                            </Badge>
                        </div>
                        {/* Show who initiated a pre-init */}
                        {(this.props.invoice && this.props.invoice?.is_pre_init == 1) &&
                                <div>
                                    <Tooltip
                                        withArrow
                                        zIndex={3000}
                                        label={
                                            <>
                                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_init.tooltip' />
                                                <span className='ml-1'>{this.props.invoice.initiated_by?.name} {this.props.invoice.initiated_by?.family_name}:</span>
                                                <span className='ml-1'><DateFormat value={this.props.invoice.created_at} /></span>
                                            </>
                                        }
                                    >
                                        <div className="text-muted">
                                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_init' />
                                            <i className='mdi mdi-information-outline text-primary ml-1' />
                                        </div>
                                    </Tooltip>
                                </div>
                        }
                        {/* Show who initiated a pre-authorized  */}
                        {(this.props.invoice && this.props.invoice.need_confirmation == 1) &&
                                <div>
                                    <Tooltip
                                        withArrow label={
                                            <>
                                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_auth.tooltip' />
                                                <span className='ml-1'>{this.props.invoice.initiated_by?.name} {this.props.invoice.initiated_by?.family_name}:</span>
                                                <span className='ml-1'><DateFormat value={this.props.invoice.created_at} /></span>
                                            </>
                                        }
                                    >

                                        <div className="text-muted">
                                            <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_auth' />
                                            <i className='mdi mdi-information-outline text-primary ml-1' />
                                        </div>
                                    </Tooltip>
                                </div>
                        }
                    </div>

                    <i className={`${this.state.isOpen ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'} ml-auto font-22 align-self-center`} />
                </div>

                <Collapse isOpen={this.state.isOpen}>
                    <div className='mt-3'>
                        {this.props.invoice === false ?
                            <div className='d-flex justify-content-center align-items-center' style={{ height: '250px' }}>
                                <Spinner color='primary' type='grow' />
                            </div>
                            : this.props.invoice === null ?
                                <div className='text-center'>
                                    <Translate id='clinics.profile.attendees.sidepanel.invoice.not.found' />
                                </div>
                                :
                                <>
                                    {/* REFUNDS */}
                                    {(this.props.invoice.refunds && this.props.invoice.refunds.length > 0) &&
                                    <div className='mb-3'>
                                        <div className="h5 font-bold">
                                            <FormattedPlural
                                                value={this.props.invoice.refunds.length}
                                                one={<Translate id='clinics.profile.attendees.sidepanel.paymentInfo.refund' />}
                                                other={<Translate id='clinics.profile.attendees.sidepanel.paymentInfo.refunds' />}
                                            />
                                        </div>
                                        <Card className='card-shadow mb-3 p-3'>
                                            {this.props.invoice.refunds.map((refund) => (
                                                <RefundItem
                                                    key={refund.invoice_payment_id}
                                                    refund={refund}
                                                    invoiceNumber={this.props.invoice.invoice_number}
                                                    onDeleteRefund={this.onDeleteRefund}
                                                />
                                            ))}
                                            <div className='text-right pr-2'>
                                                <span className='font-bold text-dark'>
                                                    <Translate id='members.profile.transactions.memberProfileTransactions.total' />
                                                    <span className='mx-1'>:</span>
                                                </span>
                                                <CurrencyFormat value={this.props.invoice.total_refunded_amount / 100} />
                                            </div>
                                        </Card>
                                    </div>
                                    }

                                    {/* PAYMENTS */}
                                    {(this.props.invoice.payment_receptions && this.props.invoice.payment_receptions.length > 0) &&
                                    <div className='mb-3'>
                                        <div className="h5 font-bold">
                                            <FormattedPlural
                                                value={this.props.invoice.payment_receptions.length}
                                                one={<Translate id='clinics.profile.attendees.sidepanel.paymentInfo.paymentReception' />}
                                                other={<Translate id='clinics.profile.attendees.sidepanel.paymentInfo.paymentReceptions' />}
                                            />
                                        </div>
                                        <Card className='card-shadow mb-3 p-3'>
                                            {this.props.invoice.payment_receptions.sort(this.sortPaymentReception).map((paymentReception) => (
                                                <PaymentReceptionItem
                                                    key={paymentReception.payment_reception_id}
                                                    paymentReception={paymentReception}
                                                    invoiceNumber={this.props.invoice.invoice_number}
                                                    onDeletePaymentReception={this.onDeletePaymentReception}
                                                    toggleEdit={this.props.toggleEdit}
                                                />
                                            ))}
                                            {/* to be aligned perfectly we would need to add a border-right because the payment reception cards have p-2 and border */}
                                            {/* but that would mean adding a border-right and styling the border so it is white or transparent :( */}
                                            {/* my OCD is going nuts, but it do be that way sometimes */}
                                            <div className='text-right pr-2'>
                                                <span className='font-bold text-dark'>
                                                    <Translate id='members.profile.transactions.memberProfileTransactions.total' />
                                                    <span className='mx-1'>:</span>
                                                </span>
                                                <CurrencyFormat value={this.props.invoice.payment_receptions.reduce((totalAmount, payment) => totalAmount + parseInt(payment.amount), 0) / 100} />
                                            </div>
                                        </Card>
                                    </div>
                                    }

                                    {/* CLINIC */}
                                    <div className="h5 font-bold"><Translate id='clinics.profile.attendees.sidepanel.paymentInfo.clinic' /></div>
                                    <Card className='card-shadow mb-3'>
                                        <CardBody>
                                            <Table size="sm" responsive className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <td className='td-shrink border-top-0'><Translate id='clinics.profile.attendees.sidepanel.paymentInfo.date' /></td>
                                                        <td className='text-center border-top-0'><Translate id='misc.status' /></td>
                                                        <td className='border-top-0'><Translate id='clinics.profile.attendees.sidepanel.paymentInfo.amount' /></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.props.invoice.invoiceClinicFeesPayments.map((payment) => (
                                                        <tr key={payment.invoice_payment_id}>
                                                            <td className='td-shrink'><DateFormat value={payment.due_date} utc /></td>
                                                            <td className="text-center">
                                                                <Badge color={payment.status === 'COMPLETED' ? 'success' : (payment.status === 'PENDING' || payment.status === 'NEED_REVIEW' || payment.status === 'PARTIALLY_PAID') ? 'warning' : 'danger'} size="lg" pill className="px-3 align-self-start">
                                                                    <Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.status.' + payment.status} />
                                                                </Badge>
                                                            </td>
                                                            <td className="text-right"><CurrencyFormat value={parseInt(payment.amount) / 100} /></td>

                                                            {payment.status === 'COMPLETED' && payment.payment_method?.code === 'CREDITCARD' &&
                                                                <td>
                                                                    <UncontrolledDropdown inNavbar>
                                                                        <DropdownToggle tag='button' className='reset-btn text-link' onClick={(e) => e.stopPropagation()}><i className='mdi mdi-dots-horizontal font-20' /></DropdownToggle>
                                                                        <DropdownMenu right>
                                                                            <DropdownItem onClick={() => this.props.history.push('receipt?invoiceNumber=' + this.props.invoice.invoice_number + '&paymentId=' + payment.invoice_payment_id)}>
                                                                                <Translate id='members.profile.transactions.memberProfileTransactions.paymentReceptions.viewReceipt' />
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            }
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="2" className="text-right">
                                                            <div className="font-bold"><Translate id='clinics.profile.attendees.sidepanel.paymentInfo.total' /> : </div>
                                                            {/* <div className="font-medium"><Translate id='clinics.profile.attendees.sidepanel.paymentInfo.due'/> : </div> */}
                                                        </td>
                                                        <td className="text-right td-shrink">
                                                            <div className="text-nowrap"><CurrencyFormat value={parseInt(this.props.invoice.total_amount) / 100} /></div>
                                                            {/* <div className="text-nowrap"><CurrencyFormat value={(this.props.invoice.invoiceClinicFeesPayments.reduce((amountDue, payment) => amountDue += (payment.status !== 'COMPLETED' && payment.status !== 'REFUNDED') ? parseInt(payment.amount) : 0, 0)) / 100}/></div> */}
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </CardBody>
                                    </Card>

                                    {/* CLINIC FEES */}
                                    <div className='mb-3'>
                                        <div className="h5 font-bold"><Translate id='clinics.profile.attendees.sidepanel.paymentInfo.clinicFees' /></div>
                                        {this.props.invoice.invoiceClinicFees?.map((item) => {
                                            return (
                                                <Card className='card-shadow mb-3' key={item.invoice_item_id}>
                                                    <CardBody>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div className='h6 mb-0 text-truncate'>
                                                                <span className='font-bold'>
                                                                    <DisplayI18n
                                                                        field='name'
                                                                        i18n={item.clinic_fee.fee.i18n}
                                                                        defaultValue={item.clinic_fee.fee.name}
                                                                    />
                                                                </span>
                                                            </div>
                                                            <div className='ml-3'><CurrencyFormat value={parseInt(item.new_amount) / 100} /></div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            )
                                        })}
                                    </div>
                                </>
                        }
                    </div>
                </Collapse>
            </div>
        )
    }
}

const RefundItem = ({ refund, invoiceNumber, onDeleteRefund }) => {
    const [ noteIsOpen, setNoteOpen ] = useState(false);
    const membersContext = useContext(MembersContext);
    return (
        <>
            <Card className='border p-2 mb-2 card-shadow'>
                <div onClick={refund.internal_note ? () => { setNoteOpen(!noteIsOpen) } : undefined} className={stringBuilder({ 'clickable': !!refund.internal_note })}>
                    <div className='d-flex justify-content-between'>
                        <DateFormat value={refund.refund_date} />
                        <UncontrolledDropdown inNavbar>
                            <DropdownToggle tag='button' className='reset-btn text-link' onClick={(e) => e.stopPropagation()} disabled={refund.refund_method?.code === "CREDITCARD"}><i className='mdi mdi-dots-horizontal font-20' /></DropdownToggle>
                            <DropdownMenu right>
                                <ConfirmModal
                                    toggler={(toggle) => (
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggle();
                                            }}
                                        >
                                            <Translate id='misc.delete' />
                                        </DropdownItem>
                                    )}
                                    modalTitle={<Translate id='members.profile.transactions.memberProfileTransactions.refunds.deleteText' values={{ date: <DateFormat value={refund.refund_date} /> }} />}
                                    actionButtonText='misc.confirm'
                                    translateActionButtonText
                                    color='danger'
                                    onConfirm={() => {
                                        return membersContext.deleteRefund(invoiceNumber, refund.invoice_payment_id)
                                            .then(() => {
                                                success();
                                                onDeleteRefund();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message);
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    }}
                                />
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <div className='d-flex justify-content-between mb-1'>
                        <Badge color={refund.status === 'COMPLETED' ? 'success' : (refund.status === 'PENDING' || refund.status === 'NEED_REVIEW' || refund.status === 'PARTIALLY_PAID') ? 'warning' : 'danger'} size="lg" pill>
                            <Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.status.' + refund.status} />
                        </Badge>
                        <div>
                            <DisplayI18n field='name' defaultValue={refund.refund_method.name} i18n={refund.refund_method.i18n} />
                        </div>
                        <CurrencyFormat value={refund.amount / 100} />
                    </div>
                    {refund.internal_note &&
                        <>
                            <Collapse isOpen={noteIsOpen}>
                                <div className='bg-light p-1 rounded'>
                                    {refund.internal_note}
                                </div>
                            </Collapse>
                            <div className='d-flex justify-content-center mb-n3 mt-n2'>
                                <i className='font-20 mdi mdi-chevron-down d-block' style={{ transition: 'transform 0.15s ease-in-out', transform: `rotate(${noteIsOpen ? '180' : '0'}deg)` }} />
                            </div>
                        </>
                    }
                </div>
            </Card>
        </>
    )
}

const PaymentReceptionItem = ({ paymentReception, invoiceNumber, onDeletePaymentReception, toggleEdit }) => {
    const [ noteIsOpen, setNoteOpen ] = useState(false);
    const membersContext = useContext(MembersContext);
    const history = useHistory();

    return (
        <Card className='border p-2 mb-2 card-shadow'>
            <div onClick={paymentReception.note ? () => { setNoteOpen(!noteIsOpen) } : undefined} className={stringBuilder({ 'clickable': !!paymentReception.note })}>
                <div className='d-flex justify-content-between'>
                    <DateFormat value={paymentReception.received_at} utc />
                    <UncontrolledDropdown inNavbar>
                        <DropdownToggle tag='button' className='reset-btn text-link' onClick={(e) => e.stopPropagation()}><i className='mdi mdi-dots-horizontal font-20' /></DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={() => history.push('receipt?invoiceNumber=' + invoiceNumber + '&paymentReceptionId=' + paymentReception.payment_reception_id)}>
                                <Translate id='members.profile.transactions.memberProfileTransactions.paymentReceptions.viewReceipt' />
                            </DropdownItem>
                            <DropdownItem onClick={() => toggleEdit(paymentReception.payment_reception_id)}>
                                <Translate id='misc.edit' />
                            </DropdownItem>
                            <ConfirmModal
                                toggler={(toggle) => (
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggle();
                                        }}
                                    ><Translate id='misc.delete' />
                                    </DropdownItem>
                                )}
                                modalTitle={<Translate id='members.profile.transactions.memberProfileTransactions.paymentReceptions.deleteText' values={{ date: <DateFormat value={paymentReception.received_at} utc /> }} />}
                                actionButtonText='misc.confirm'
                                translateActionButtonText
                                color='warning'
                                onConfirm={() => {
                                    return membersContext.deletePaymentReception(invoiceNumber, paymentReception.payment_reception_id)
                                        .then(() => {
                                            success();
                                            onDeletePaymentReception();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                }}
                            />
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <div className='d-flex justify-content-between'>
                    <div>
                        {paymentReception.payment_method ?
                            <DisplayI18n field='name' defaultValue={paymentReception.payment_method?.name} i18n={paymentReception.payment_method?.i18n} />
                            :
                            <Translate id={`members.profile.transactions.memberProfileTransactions.paymentMethods.MEMBER_CREDIT`} />
                        }
                    </div>
                    <CurrencyFormat value={paymentReception.amount / 100} />
                </div>
                {paymentReception.note &&
                    <>
                        <Collapse isOpen={noteIsOpen}>
                            <div className='bg-light p-1 rounded'>
                                {paymentReception.note}
                            </div>
                        </Collapse>
                        <div className='d-flex justify-content-center mb-n3 mt-n2'>
                            <i className='font-20 mdi mdi-chevron-down d-block' style={{ transition: 'transform 0.15s ease-in-out', transform: `rotate(${noteIsOpen ? '180' : '0'}deg)` }} />
                        </div>
                    </>
                }
            </div>
        </Card>
    )
}

export default withRouter(withContexts(OnlineStoreContext, OrganizationContext, PeriodsContext)(SidePanelPaymentInfo))