import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    Button,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { Form, Formik } from 'formik';

import { TravelPermitAddContext } from './TravelPermitAddContext';
import { useSpordleTable } from '@spordle/datatables';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { AuthContext } from '../../../../../contexts/contexts';
import { stringBuilder } from '@spordle/helpers';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import { TeamsContext } from '../../../../../contexts/TeamsContext';

const TravelPermitAdd3 = ({ toggle }) => {

    const travelPermitAddContext = useContext(TravelPermitAddContext);
    const spordleTable = useSpordleTable();
    const teamsContext = useContext(TeamsContext);
    const authContext = useContext(AuthContext);

    const [ isLoading, setIsLoading ] = useState(false);

    return (
        <OverlayLoader isLoading={isLoading}>
            <Formik
                initialValues={{
                    submitted: true,
                }}
                onSubmit={(submitData) => {
                    setIsLoading(true);

                    Promise.all([
                        submitData.submitted ? teamsContext.submitTravelPermit(authContext.account.userName, travelPermitAddContext.state.formData.travelPermitId) : Promise.resolve(),
                    ])
                        .then(() => {
                            travelPermitAddContext.clearData();
                            spordleTable.refreshTable();
                            setIsLoading(false);
                            toggle();
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalBody>
                            <UserDisplay
                                block card hover
                                className={stringBuilder({ "shadow-primary border-primary": formik.values.submitted }, 'mb-2')}
                                onClick={() => {
                                    formik.setFieldValue('submitted', true);
                                }}
                            >
                                <UserDisplay.Container>
                                    <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                                        <i style={{ fontSize: '30px' }} className="mdi mdi-file-outline" />
                                        <i style={{ height: '17px', width: '17px' }} className='d-flex flex-center mdi mdi-check-circle-outline line-height-1 bg-light rounded-circle position-absolute ml-3 mb-n3 z-index-1 font-16 text-success' />
                                    </div>
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Title><Translate id='travelPermit.addModal.submit' /></UserDisplay.Title>
                                    <UserDisplay.Subtitle>
                                        <Translate id='travelPermit.addModal.submit.subtitle' />
                                    </UserDisplay.Subtitle>
                                </UserDisplay.Container>
                            </UserDisplay>
                            <UserDisplay
                                block card hover
                                className={stringBuilder({ "shadow-primary border-primary": !formik.values.submitted }, 'mb-0')}
                                onClick={() => {
                                    formik.setFieldValue('submitted', false);
                                }}
                            >
                                <UserDisplay.Container>
                                    <div style={{ height: 50, width: 50 }} className="border bg-light rounded-circle d-flex align-items-center justify-content-center">
                                        <i style={{ fontSize: '30px' }} className="mdi mdi-file-outline" />
                                        <i style={{ height: '17px', width: '17px' }} className='d-flex flex-center mdi mdi-clock-outline line-height-1 bg-light rounded-circle position-absolute ml-3 mb-n3 z-index-1 font-16 text-warning' />
                                    </div>
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Title><Translate id='travelPermit.addModal.notSubmit' /></UserDisplay.Title>
                                    <UserDisplay.Subtitle>
                                        <Translate id='travelPermit.addModal.notSubmit.subtitle' />
                                    </UserDisplay.Subtitle>
                                </UserDisplay.Container>
                            </UserDisplay>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' type='submit' disabled={isLoading}>
                                <Translate id='misc.confirm' />
                            </Button>
                            <Button color='primary' onClick={toggle} outline><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </OverlayLoader>
    );
}

export default TravelPermitAdd3;