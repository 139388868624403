import React from "react";
import {
    Label
} from "reactstrap";
import FormikEditable from '../../../../../../components/formik/FormikEditable';
import { FormikInputText, FormikSelect } from '@spordle/formik-elements';
import * as Yup from 'yup'
import { success, fail } from '@spordle/toasts';
import PropTypes from 'prop-types';

// Context
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { PhoneTypesContext } from '../../../../../../contexts/PhoneTypesContext';
import withContexts from '../../../../../../helpers/withContexts';

// Language
import Translate from "@spordle/intl-elements";
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../../../../helpers/i18nHelper";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";

class SidePanelOrganizationPhoneTypes extends React.Component{
    state = {
        isLoading: false,
    };

    toggleLoading = (bool) => {
        this.setState((prevState) => ({ isLoading: bool ?? !prevState.isLoading }))
    }

    render(){
        return (
            <I18nHelperContext.Consumer>
                { (i18nHelper) => (
                    <OverlayLoader isLoading={this.state.isLoading}>
                        <SidePanel.Header>
                            <div className='d-flex mb-2 align-items-center'>
                                <SidePanel.ToggleButton />
                                <SidePanel.ActionsMenu action='DELETE' componentCode='settings' componentPermissionCode='phone_type'>
                                    <SidePanel.MenuDelete
                                        translateModalMsg
                                        modalMsg='organization.settings.organizationPhoneTypes.sidepanel.delete.message'
                                        modalTitle={<DisplayI18n field="name" i18n={this.props.getCommonValue('i18n')} defaultValue={this.props.getCommonValue('name')} />}
                                        onConfirm={() => {
                                            return this.props.PhoneTypesContext.deletePhoneType(this.props.getCommonValue('phone_type_id'))
                                                .then(() => {
                                                    this.props.tableRef.deleteRow(this.props.getCommonValue('phone_type_id'))
                                                        .then(() => {
                                                            this.props.toggle;
                                                            success();
                                                        });
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                        }}
                                    />
                                </SidePanel.ActionsMenu>
                            </div>
                            <SidePanel.Title>
                                <DisplayI18n
                                    field='name'
                                    i18n={this.props.selectedRows[0].i18n}
                                    defaultValue={this.props.selectedRows[0].name}
                                />
                            </SidePanel.Title>
                        </SidePanel.Header>
                        <div className='p-3'>
                            <RenderI18nForm field="name">
                                {({ fieldName, fieldLabel }) => {
                                    return (
                                        <div className="mb-3" key={fieldName}>
                                            <Label for={fieldName} className="text-muted">{fieldLabel}</Label>
                                            <FormikEditable
                                                id={fieldName}
                                                initialValues={
                                                    i18nHelper.getInitialValues(this.props.selectedRows[0])
                                                }
                                                validationSchema={Yup.object().shape(
                                                    i18nHelper.getValidationSchema({ name: Yup.string().required() }),
                                                )}
                                                onSubmit={(values) => {

                                                    this.toggleLoading(true);
                                                    this.props.PhoneTypesContext.updatePhoneType(this.props.getCommonValue('phone_type_id'), this.props.createNewValues(i18nHelper.getAPIValues(values)))
                                                        .then(() => {
                                                            this.props.syncRows(this.props.createNewValues(values));
                                                            success();
                                                            this.toggleLoading(false);
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                                this.toggleLoading(false);
                                                            }
                                                        })
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark'>
                                                                <DisplayI18n
                                                                    field={fieldName}
                                                                    defaultValue={this.props.selectedRows[0].name || '-'}
                                                                    i18n={this.props.selectedRows[0].i18n}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikInputText id={fieldName} name={fieldName} autoFocus />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    )
                                }}
                            </RenderI18nForm>

                            <div className="mb-3">
                                <Label for="status" className="text-muted"><Translate id='organization.settings.organizationPhoneTypes.add.status' /></Label>
                                <FormikEditable
                                    id="status"
                                    noConfirmation
                                    initialValues={{
                                        status: this.props.selectedRows[0].active,
                                    }}
                                    onSubmit={(values, actions) => {
                                        if(values.status !== this.props.selectedRows[0].active){
                                            this.toggleLoading(true);
                                            const newValues = this.props.createNewValues({ active: values.status });
                                            this.props.PhoneTypesContext.partialUpdatePhoneType(this.props.getCommonValue('phone_type_id'), { active: values.status })
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    success();
                                                    this.toggleLoading(false);
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                        this.toggleLoading(false);
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    {this.props.selectedRows[0].active === '1' ? <Translate id='misc.active' /> : <Translate id='misc.inactive' />}
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                id='status' name='status'
                                                autoFocus menuIsDefaultOpen
                                                onOptionSelected={() => {
                                                    options.stopEditing()
                                                }}
                                                defaultData={[
                                                    { value: '1', label: 'misc.active', translateLabel: true },
                                                    { value: '0', label: 'misc.inactive', translateLabel: true },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                        </div>

                    </OverlayLoader>
                )}
            </I18nHelperContext.Consumer>
        )
    }
}

SidePanelOrganizationPhoneTypes.propTypes = {
    tableRef: PropTypes.object.isRequired,
}

export default withContexts(PhoneTypesContext, OrganizationContext)(SidePanelOrganizationPhoneTypes);