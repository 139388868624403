import { useDisclosure } from "@mantine/hooks";
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables";
import Translate, { DateFormat } from "@spordle/intl-elements";
import SpordleSelect from "@spordle/spordle-select";
import moment from "moment";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card } from "reactstrap";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import { getPotentialIneligibleMembers } from "../../../api/client/potentialIneligibleMembers";
import StatusBadge from "../../../components/badges/StatusBadge";
import CanDoAction from "../../../components/permissions/CanDoAction";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import SpordlePanelControl from "../../../components/sidePanel/SpordlepanelControl";
import TablePagination from "../../../components/table/TablePagination";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";
import { I18nContext } from "../../../contexts/I18nContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import PotentialIneligibleMembersSidePanel from "./sidePanel/PotentialIneligibleMembersSidePanel";

const PotentialIneligibleMembers = ({ changeRequestStatuses, ...props }) => {
    const i18nContext = useContext(I18nContext);
    const organizationContext = useContext(OrganizationContext)

    const [ isModalOpen, isModalOpenHandlers ] = useDisclosure(false);
    const [ memberStatus, setMemberStatus ] = useState([]);

    return (
        <>
            <ViewHeaderV2 title='task.ineligible.title' />
            <div className="px-4">
                <SpordlePanelTable
                    allowOutsideClick
                    sidePanel={(props) => (
                        <PotentialIneligibleMembersSidePanel
                            {...props} isModalOpen={isModalOpen} isModalOpenHandlers={isModalOpenHandlers}
                            changeRequestStatuses={changeRequestStatuses}
                        />
                    )}
                    dataIndex='member_potential_ineligible_id'
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='memberChangeRequestList'
                                tableHover bordered striped
                                clickable
                                ref={panelProps.spordleTableRef}
                                desktopWhen
                                dataIndex='member_potential_ineligible_id'
                                pagination={25}
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                defaultSorting='+created_at'
                                onColumnClick={(e, data, _spordleTable, columnKey) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            if(columnKey === 'checkbox'){
                                                panelProps.onMultiSelectChange(data);
                                            }else{
                                                panelProps.onSingleSelectChange(data);
                                            }
                                            break;
                                    }
                                }}
                                initFilter={{
                                    memberStatusId: '',
                                }}
                                filterJSCallback={(data, filters, spordleTable) => {
                                    if(filters.memberStatusId){
                                        return data.member_status_id === filters.memberStatusId
                                    }
                                    return true
                                }}
                                searchKeys={[
                                    `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                    `member.last_name`,
                                    `member.first_name`,
                                    `member.birthdate`,
                                    `member.unique_identifier`,
                                    `member.email`,
                                    'created_at',
                                    'submission_date',
                                    `organisation_status.i18n.${i18nContext.getGenericLocale()}.name`,
                                ]}
                                loadData={(from, { filters }, spordleTable) => {
                                    switch (from){
                                        case 'REFRESH':
                                        case 'CDM':
                                            spordleTable.setLoading();
                                            return getPotentialIneligibleMembers({ organisation_id: organizationContext.organisation_id, status: 'PENDING' })
                                                .then(({ data: potentialIneligibleMember, member_status }) => {
                                                    const memberStatusSet = new Set();
                                                    if(member_status){
                                                        Object.keys(member_status).forEach((memberStatusId) => {
                                                            memberStatusSet.add(memberStatusId)
                                                        })
                                                    }

                                                    setMemberStatus(Array.from(memberStatusSet).map((memberStatusId) => ({
                                                        value: memberStatusId,
                                                        i18n: member_status[memberStatusId].i18n,
                                                        label: member_status[memberStatusId].name,
                                                    })))
                                                    return potentialIneligibleMember;
                                                })
                                                .catch((error) => {
                                                    if(AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        // return true to let the table know that the call is cancelled and not put hte table in an error state
                                                        return true;
                                                    }
                                                })
                                        default:
                                            break;
                                    }
                                }}
                                columns={[
                                    {
                                    // yes keep this
                                        label: '-',
                                        key: 'checkbox',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                    },
                                    {
                                        label: <Translate id='task.ineligible.member.created.submitted' />,
                                        key: "created_at",
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.search.memberSearch.table.organization' />,
                                        key: "organization",
                                        mobile: true,
                                        sortable: true,
                                        sortKey: `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                        fallbackSortKey: 'organisation.organisation_name',
                                    },
                                    {
                                        label: <Translate id='members.search.memberSearch.table.member' />,
                                        key: "last_name",
                                        mobile: true,
                                        sortable: true,
                                        sortKey: 'member.last_name',
                                    },
                                    {
                                        label: <Translate id='form.fields.dateOfBirth' />,
                                        key: "birthdate",
                                        mobile: true,
                                        sortable: true,
                                        sortKey: 'member.birthdate',
                                    },
                                ]}
                                renderRow={(key, member, spordleTable) => {
                                    switch (key){
                                        case 'checkbox':
                                            return (
                                                <label className='my-auto' htmlFor={'checked-' + member.member_potential_ineligible_id} onClick={(e) => { e.stopPropagation() }}>
                                                    <input
                                                        id={'checked-' + member.member_potential_ineligible_id} type="checkbox" checked={!!member.checked}
                                                        onClick={() => { panelProps.onMultiSelectChange(member); }}
                                                        onChange={function(){}}// This needs to be here to remove the console error
                                                    />
                                                    <span className="table-checkbox" />
                                                </label>
                                            )
                                        case 'created_at':
                                            return (
                                                <div>
                                                    <div>
                                                        {moment(member.created_at).format("YYYY-MM-DD HH:mm")}
                                                    </div>
                                                    <div className="small text-muted">
                                                        <Translate id="task.ineligible.member.source.label" />: <Translate id={`task.ineligible.member.created.from.${member.member?.source}`} />
                                                    </div>
                                                </div>
                                            )
                                        case 'organization':
                                            return (member.organisation ?
                                                <div>
                                                    <DisplayI18n
                                                        field='name'
                                                        defaultValue={member.organisation.organisation_name}
                                                        i18n={member.organisation.i18n}
                                                    />
                                                </div>

                                                : '-'
                                            )
                                        case 'last_name':
                                            return member.member ? <MemberLastNameRender member={member.member} row={member} /> : '-'
                                        case 'birthdate':
                                            return (
                                                member.member?.birthdate ?
                                                    <div>
                                                        {member.member.birthdate && <div><DateFormat value={member.member?.birthdate} utc /></div>}
                                                        {member.member.age &&
                                                            <div className="text-muted small"><Translate id='misc.yearsOld' values={{ age: member.member.age || 0 }} /></div>
                                                        }
                                                    </div>
                                                    : '-'
                                            )
                                        default:
                                            break;
                                    }
                                }}
                            >
                                {(spordleTable) => (
                                    <Card body className="card-shadow">
                                        <div className="mb-2">
                                            <div className='d-flex mb-2'>
                                                <SpordlePanelControl />
                                                <SearchInput />
                                                {memberStatus.length > 1 &&
                                                    <div style={{ minWidth: '219px' }} className='ml-2'>
                                                        <SpordleSelect
                                                            options={memberStatus}
                                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                            clearable
                                                            search={false}
                                                            placeholder="task.mandatoryChangesRequests.filter.status"
                                                            onOptionSelected={(values) => {
                                                                spordleTable.filterChange('memberStatusId', values[0], false)
                                                            }}
                                                        />
                                                    </div>
                                                }

                                                <CanDoAction
                                                    componentCode="members"
                                                    componentPermissionCode="manage_potential_ineligible_members"
                                                    action="EDIT"
                                                    className='d-flex ml-auto'
                                                >
                                                    {spordleTable.getData().length > 0 &&
                                                        <Button
                                                            color='primary'
                                                            onClick={async() => {
                                                                await panelProps.checkAllPages()
                                                                isModalOpenHandlers.toggle()
                                                            }}
                                                        >
                                                            <Translate id='task.mandatoryChangesRequests.manage.all' />
                                                        </Button>
                                                    }
                                                    <TablePagination className='ml-2' paginationArray={[ 25, 50, 100 ]} />
                                                    <Refresh className='ml-2' />
                                                </CanDoAction>
                                            </div>
                                        </div>
                                        <SpordleTableView />
                                    </Card>
                                )}
                            </SpordleTableProvider>
                        )
                    }}
                />
            </div>
        </>
    );
};

const MemberLastNameRender = ({ member, row }) => {
    return (
        <UserDisplay>
            <UserDisplay.Container className='w-100'>
                <UserDisplay.Title className='d-flex align-items-start justify-content-between'>
                    {member.last_name} {member.first_name}
                </UserDisplay.Title>
                {member.unique_identifier ?
                    // send data without member types because the member types from search and GET specific are different formats
                    <Link className="text-nowrap" to={{ pathname: `/members/profile/${row.member_id}`, data: { ...member, member_types: [] } }}>
                        #{member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" />
                    </Link>
                    :
                    <Link to={`/members/profile/${row.member_id}`} className='mr-2'>
                        <Translate id='misc.goToProfile' /><i className="mdi mdi-chevron-right ml-1" />
                    </Link>
                }
                {member.gender &&
                    <UserDisplay.Subtitle>
                        <Translate id={'members.search.memberSearch.table.gender.' + member.gender?.toUpperCase() || 'PREFER_NOT_TO_SAY'} />
                    </UserDisplay.Subtitle>
                }
                <UserDisplay.Subtitle><StatusBadge color={member.member_status?.color_code} status={member.member_status} /></UserDisplay.Subtitle>
            </UserDisplay.Container>
        </UserDisplay>
    )
}


export default PotentialIneligibleMembers;
