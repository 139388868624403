import { useDisclosure } from "@mantine/hooks";
import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Card } from "reactstrap";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import SpordlePanelControl from "../../../components/sidePanel/SpordlepanelControl";
import TablePagination from "../../../components/table/TablePagination";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import { I18nContext } from "../../../contexts/I18nContext";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import MemberAttachmentApprobationSidepanel from "./sidePanel/MemberAttachmentApprobationSidepanel";

const MemberAttachmentApprobationList = (props) => {
    const i18nContext = useContext(I18nContext)

    const getBadgeColour = (status) => {
        switch (status){
            case 'PENDING':
                return 'warning';
            case 'REJECTED':
                return 'danger';
            case 'APPROVED':
                return 'success';
            case 'NO_VALIDATION':
                return 'info';
            default:
                return 'danger';
        }
    }

    const [ isModalOpen, isModalOpenHandlers ] = useDisclosure(false);

    return (
        <SpordlePanelTable
            allowOutsideClick
            sidePanel={(props) => <MemberAttachmentApprobationSidepanel {...props} getBadgeColour={getBadgeColour} isModalOpen={isModalOpen} isModalOpenHandlers={isModalOpenHandlers} />}
            dataIndex='member_attachement_id'
            table={(panelProps) => {
                return (
                    <SpordleTableProvider
                        id='unsactionedList'
                        tableHover bordered striped
                        clickable
                        ref={panelProps.spordleTableRef}
                        desktopWhen
                        dataIndex='member_attachement_id'
                        pagination={25}
                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                        defaultSorting='+created_at'
                        onColumnClick={(e, data, _spordleTable, columnKey) => {
                            switch (e.button){
                                case 0: // Left mouse button
                                    if(columnKey === 'checkbox'){
                                        panelProps.onMultiSelectChange(data);
                                    }else{
                                        panelProps.onSingleSelectChange(data);
                                    }
                                    break;
                            }
                        }}
                        searchKeys={[
                            'unique_identifier',
                            'first_name',
                            'last_name',
                            'submitted_by.email',
                            'submitted_by.family_name',
                            'submitted_by.name',
                                    `document_type.i18n.${i18nContext.getGenericLocale()}.name`,
                                    `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                    `organisation.organisation_name`,
                            'created_at',
                                    `review_status`,
                        ]}
                        loadData={(from, _, spordleTable) => {
                            switch (from){
                                case 'FILTER':
                                case 'REFRESH':
                                    spordleTable.setLoading();
                                case 'CDM':
                                    return props.getData();
                                default:
                                    break;
                            }
                        }}
                        columns={[
                            {
                                // yes keep this
                                label: '-',
                                key: 'checkbox',
                                className: 'th-shrink',
                                dataClassName: 'td-shrink',
                            },
                            {
                                label: <Translate id='task.document.approval.member' />,
                                key: 'member',
                                sortable: true,
                                sortKey: 'last_name',
                            },
                            {
                                label: <Translate id='task.document.approval.organization' />,
                                key: 'organization',
                                sortable: true,
                                sortKey: `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                fallbackSortKey: `organisation.organisation_name`,
                            },
                            {
                                label: <Translate id='task.document.approval.submitted_by' />,
                                key: 'submitted_by',
                                sortable: true,
                                sortKey: 'submitted_by.family_name',
                            },
                            {
                                label: <Translate id='task.unconfirmedOrg.sidepanel.submission.date' />,
                                key: 'created_at',
                                sortable: true,
                            },
                            {
                                label: <Translate id='form.fields.status' />,
                                key: "status",
                                mobile: true,
                                sortable: true,
                            },
                        ]}
                        renderRow={(columnKey, row) => {
                            switch (columnKey){
                                case 'checkbox':
                                    return (
                                        <label className='my-auto' htmlFor={'attachment-checked-' + row.member_attachement_id} onClick={(e) => { e.stopPropagation() }}>
                                            <input
                                                id={'attachment-checked-' + row.member_attachement_id} type="checkbox" checked={!!row.checked}
                                                onClick={() => { panelProps.onMultiSelectChange(row); }}
                                                onChange={function(){}}// This needs to be here to remove the console error
                                            />
                                            <span className="table-checkbox" />
                                        </label>
                                    )
                                case 'member':
                                    return (
                                        <div className='flex-grow-1'>
                                            <div className="font-medium">
                                                {row.first_name + ' ' + row.last_name}
                                            </div>
                                            <Link className="text-nowrap d-inline-block" to={`/members/profile/${row.member_id}`}><div className="small">#{row.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></div></Link>
                                        </div>
                                    )
                                case 'organization':
                                    return (
                                        <UserDisplay.Title><DisplayI18n field='name' defaultValue={row.organisation.organisation_name} i18n={row.organisation.i18n} /></UserDisplay.Title>
                                    );
                                case 'submitted_by':
                                    if(row.submitted_by)
                                        return (
                                            <div>
                                                <div>{row.submitted_by.name} {row.submitted_by.family_name}</div>
                                                <div><a href={`mailto:${row.submitted_by.email}`}>{row.submitted_by.email}</a></div>
                                            </div>
                                        )
                                    return ' - '
                                case 'created_at':
                                    return (
                                        <DateFormat value={row?.submission_date} />
                                    )
                                case 'status':
                                    return (
                                        <Badge
                                            color={getBadgeColour(row.review_status || 'PENDING')}
                                        >
                                            {/* Added Pending as default status cause API returns litteraly nothing */}
                                            <Translate id={row.review_status ? `task.document.approval.status.${row.review_status}` : 'task.document.approval.status.PENDING'} />
                                        </Badge>
                                    )
                                default:
                                    break;
                            }
                        }}
                    >
                        {(spordleTable) => (
                            <Card body className="card-shadow">
                                <div className="mb-2">
                                    <div className='d-flex flex-wrap justify-content-between mb-2'>
                                        <SpordlePanelControl />
                                        <SpordleTableProvider.SearchInput />
                                        <div className='d-flex ml-auto'>
                                            {spordleTable.getData().length > 0 &&
                                                            <Button
                                                                color='primary'
                                                                onClick={async() => {
                                                                    await panelProps.checkAllPages()
                                                                    isModalOpenHandlers.toggle()
                                                                }}
                                                            >
                                                                <Translate id='task.document.approval.list.approve.all' />
                                                            </Button>
                                            }
                                            <TablePagination className='ml-2' paginationArray={[ 25, 50, 100 ]} />
                                            <SpordleTableProvider.Refresh className='ml-2' />
                                        </div>
                                    </div>
                                </div>
                                <SpordleTableView />
                            </Card>
                        )}
                    </SpordleTableProvider>
                )
            }}
        />
    );
};


export default MemberAttachmentApprobationList;
