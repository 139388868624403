const RADIO_CHOICES = [
    "BEGINNING",
    "DEVELOPING",
    "MEETING",
    "EXCEEDING",
];

const FORM_CATEGORIES = [
    "skating",
    "positioning",
    "procedures",
    "presence",
    "communication",
];

const EXTRA_TYPE_CATEGORIES = {
    "grassroots": [
        "judgement",
    ],
    "development": [
        "rule_application",
        "penalty_standards",
    ],
    "high performance": [
        "mental_skills",
        "rule_application",
        "penalty_standards",
    ],
};

export const ALL_TYPE_CATEGORIES = [ ...FORM_CATEGORIES, ...EXTRA_TYPE_CATEGORIES.grassroots, ...EXTRA_TYPE_CATEGORIES["high performance"] ];

export const OTHER_TEAM = "OTHER";
export const getCategoryTypes = (formType) => [ ...FORM_CATEGORIES, ...(EXTRA_TYPE_CATEGORIES[(formType || "").toLowerCase()] || []) ];
export const getCategoryTypeChoices = (formType) => (formType || "").toLowerCase() === "grassroots" ? RADIO_CHOICES.filter((c) => c !== "BEGINNING") : RADIO_CHOICES