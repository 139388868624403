import useSWR from "swr";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { ReportsContext } from "../../../../contexts/ReportsContext";
import { useContext } from "react";
import { PeriodsContext } from "../../../../contexts/contexts";
import { getChecklists } from "../../../../api/client/checklists";
import NewWindowOpener from "../../../../views/reports/registrationForm/NewWindowOpener";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { useLocation } from 'react-router-dom';
import { DropdownItem } from "reactstrap";
import { parse } from "query-string";
import { parseJSON } from "../../../../helpers/helper";
import { ACCEPTED_SYSTEM_TYPES } from "../../../../views/tasks/checklists/checklistsHelper";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";

export function useRoutesSWR(navGroup){
    const identityRolesContext = useContext(IdentityRolesContext);
    const reportsContext = useContext(ReportsContext);
    const periodsContext = useContext(PeriodsContext);
    const location = useLocation();
    const noneSWR = { key: null, get: Promise.resolve };

    const getSWR = (theContext) => {
        switch (theContext){
            case 'reports':
                return {
                    key: [ 'getAllReportsList', identityRolesContext.identity_role_id ],
                    get: () => reportsContext.getReportsList(),
                    renderSidebar: (report) => {
                        if(report.code === 'print-manual-registration-form'){
                            return (
                                <li className="sidebar-item" key={report.custom_report_id || report.report_id}>
                                    <NewWindowOpener className='sidebar-link'>
                                        <div className="w-100 min-w-0 mr-2">
                                            <div className="text-truncate py-1 my-n1">
                                                <DisplayI18n field='name' defaultValue={report.name} i18n={report.i18n} />
                                            </div>
                                        </div>
                                    </NewWindowOpener>
                                </li>
                            )
                        }

                        if(report.deprecated == 1){
                            return null;
                        }
                    },
                    renderHeader: (report) => {
                        if(report.code === 'print-manual-registration-form'){
                            return (
                                <NewWindowOpener key={report.custom_report_id || report.report_id}>
                                    <DropdownItem>
                                        <DisplayI18n field='name' defaultValue={report.name} i18n={report.i18n} />
                                    </DropdownItem>
                                </NewWindowOpener>
                            )
                        }

                        if(report.deprecated == 1){
                            return null;
                        }
                    },
                    getRouteInfo: (report) => {
                        const parameter = parseJSON(report.parameter, {});
                        const urlCustomReportId = parse(location.search).custom_report_id;

                        return ({
                            key: report.custom_report_id || report.report_id,
                            favoriteData: {
                                label: report.name,
                                i18n: report.i18n,
                                breadcrumbs: [ { path: "/report", name: 'sidebar.reports' } ],
                                translate: false,
                            },
                            sidebarData: {
                                isActive: report.detailedRoute ? (match, location) => {
                                    return `/reports/${report.detailedRoute}` === location.pathname || !!match;
                                } : void 0,
                                path: report.custom_report_id ? '/reports/' + parameter.route + `?custom_report_id=${report.custom_report_id}` : '/reports/' + parameter.route,
                            },
                            headerData: {
                                path: {
                                    pathname: `/reports/${parameter.route}`,
                                    search: report.is_custom == 1 ? `?custom_report_id=${report.custom_report_id}` : null,
                                    state: { report: report },
                                },
                                isActive: urlCustomReportId ? report.custom_report_id === urlCustomReportId : report.custom_report_id ? false : location.pathname === `/reports/${parameter.route}`,
                            },
                            routeData: {
                                id: report.custom_report_id || report.report_id,
                                name: <DisplayI18n field='name' defaultValue={report.name} i18n={report.i18n} />,
                                exact: true,
                                skipTranslate: true,
                                customReportId: report.custom_report_id,
                                componentPermissionRule: report.component_permission_rule,
                                permissions: report.component_permissions.map((permission) => ({
                                    code: permission.component_code,
                                    permissionCode: permission.code,
                                })),
                            },
                        })
                    },
                };
            case 'checklists':
                return {
                    key: periodsContext.selectedPeriod?.period_id ? [ 'getAllChecklistsListRoutes', periodsContext.selectedPeriod?.period_id ] : null,
                    get: () => getChecklists({ active: 1, period_id: periodsContext.selectedPeriod?.period_id })
                        .then((checklists) => checklists.filter((checklist) => ACCEPTED_SYSTEM_TYPES.includes(checklist.system_type)))
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                console.error(e);
                            }

                            return [];
                        }),
                    getRouteInfo: (checklist) => {
                        if(!checklist.system_type){
                            return null;
                        }

                        return ({
                            key: checklist.checklist_id,
                            favoriteData: {
                                label: checklist.name,
                                i18n: checklist.i18n,
                                breadcrumbs: [ { path: "/tasks/checklists", name: 'sidebar.checklists' } ],
                                translate: false,
                            },
                            routeData: {
                                path: `/tasks/checklists/${checklist.checklist_id}`,
                                id: checklist.checklist_id,
                                name: <DisplayI18n field='name' defaultValue={checklist.name} i18n={checklist.i18n} />,
                                exact: true,
                                skipTranslate: true,
                                permissions: [
                                    {
                                        code: 'organization',
                                        permissionCode: 'organization_profile',
                                    },
                                ],
                                // componentPermissionRule: checklist.component_permission_rule,
                                // permissions: checklist.component_permissions.map((permission) => ({
                                //     code: permission.component_code,
                                //     permissionCode: permission.code,
                                // })),
                            },
                        })
                    },
                };
            default:
                return noneSWR;
        }
    }

    const { key: swrKey, get: swrGet, ...rest } = navGroup && navGroup.api && navGroup.context ? getSWR(navGroup.context) : noneSWR;

    const swr = useSWR(
        swrKey,
        () => swrGet(),
        {
            dedupingInterval: 60000 * 2, // 2 minutes
            revalidateOnReconnect: false,
        },
    );

    return {
        ...swr,
        ...rest,
    }
}