import { useIntl } from "react-intl";
import HasAccessTo from "../../../../components/permissions/HasAccessTo";
import MemberAlertsIcon from "../../../../components/memberBadges/MemberAlertsIcon";
import { useContext } from "react";
import { OrganizationContext } from '../../../../contexts/OrganizationContext'
import { Tooltip } from "@mantine/core";
import { DisplayI18n } from "../../../../helpers/i18nHelper";

const MemberStatusIcons = ({ className, member, tooltipPosition }) => {
    const { formatMessage } = useIntl();

    if(!member){
        return null;
    }

    return (
        <div className={className || 'ml-auto d-flex'}>
            {(member.need_address_document == 1) && // if member has missing adress documents
                <HasAccessTo componentCode='members' componentPermissionCode='members_address'>
                    <Tooltip
                        label={formatMessage({ id: "members.search.memberSearch.missingDocument" })}
                        withinPortal={false}
                        placement={tooltipPosition}
                    >
                        <MemberAlertsIcon icon="documents" className="pl-1" />
                    </Tooltip>
                </HasAccessTo>
            }
            {(member.potential_duplicate == 1) && // if member has missing adress documents
                <HasAccessTo componentCode="members" componentPermissionCode="manage_potential_duplicate_members">
                    <Tooltip
                        label={formatMessage({ id: "tasks.memberDuplicate" })}
                        withinPortal={false}
                        placement={tooltipPosition}
                    >
                        <i className="ml-1 text-danger mdi mdi-account-multiple" />
                    </Tooltip>
                </HasAccessTo>
            }
            {(member.incomplete_profile == 1) && // if member has an unconfirmed address
                <HasAccessTo componentCode='members' componentPermissionCode='members_address'>
                    <Tooltip
                        label={formatMessage({ id: 'members.search.memberSearch.incomplete_profile.tooltip' })}
                        withinPortal={false}
                        placement={tooltipPosition}
                    >
                        <MemberAlertsIcon icon="profile" className="pl-1" />
                    </Tooltip>
                </HasAccessTo>
            }
            {member.potential_ineligible == 1 &&
                <Tooltip
                    label={
                        <>{formatMessage({ id: 'members.profile.potential.ineligible.title' })}</>}
                    withinPortal={false}
                    placement={tooltipPosition}
                >
                    <i className='ml-1 text-danger mdi mdi-account-remove' />
                </Tooltip>
            }
            {(member.missing_required_fields?.length > 0) && // if member is missing required fields
                <HasAccessTo componentCode='members' componentPermissionCode='members_profile'>
                    <MissingFieldsIcon tooltipPosition={tooltipPosition} />
                </HasAccessTo>
            }
            {(member.unsigned_critical_waivers == 1) && // if member needs to sign critical waivers
                <HasAccessTo componentCode='members' componentPermissionCode='members_profile'>
                    <Tooltip
                        label={formatMessage({ id: 'members.search.memberSearch.unsignedCriticalWaiver.tooltip' })}
                        withinPortal={false}
                        placement={tooltipPosition}
                    >
                        <MemberAlertsIcon icon="waivers" className="pl-1" />
                    </Tooltip>
                </HasAccessTo>
            }
            {(member.with_unconfirmed_address == 1) && // if member has an unconfirmed address
                <HasAccessTo componentCode='members' componentPermissionCode='members_address'>
                    <Tooltip
                        label={formatMessage({ id: 'members.search.memberSearch.unconfirmed.address.tooltip' })}
                        withinPortal={false}
                        placement={tooltipPosition}
                    >
                        <MemberAlertsIcon icon="address" className="pl-1" />
                    </Tooltip>
                </HasAccessTo>
            }
            {(member.crc_is_expired == 1 || member.currentMember?.missing_background_check == '1') && // if criminal record check is expired or non existant
                <HasAccessTo componentCode='members' componentPermissionCode='member_background_check'>
                    <Tooltip
                        label={formatMessage({ id: 'members.search.memberSearch.crc.tooltip' })}
                        withinPortal={false}
                        placement={tooltipPosition}
                    >
                        <MemberAlertsIcon icon="crc" className="pl-1" />
                    </Tooltip>
                </HasAccessTo>
            }
            {member.member_status?.name !== 'CONFIRMED' &&
                <Tooltip
                    label={
                        <>
                            {formatMessage({ id: 'members.search.memberSearch.unconfirmed.tooltip' })}
                            <div className="d-block">{formatMessage({ id: 'misc.status' })}: <DisplayI18n field='name' defaultValue={member.member_status?.name} i18n={member.member_status?.i18n} /> </div>
                        </>}
                    withinPortal={false}
                    placement={tooltipPosition}
                >
                    <MemberAlertsIcon icon="unconfirmed" className="pl-1" />
                </Tooltip>
            }
        </div>
    )
}

const MissingFieldsIcon = ({ className, skipTopMessage, tooltipPosition }) => {
    const orgContext = useContext(OrganizationContext);
    const { formatMessage } = useIntl();

    const getMissingFieldString = () => {
        const topTranslate = formatMessage({ id: 'members.search.memberSearch.missingFields.tooltip' })
        const topMessage = skipTopMessage ? '' : <div className="mb-2">{topTranslate}</div>
        const orgFieldsTranslate = formatMessage({ id: 'members.search.memberSearch.missingFields.organization' })
        const missingFieldsList = orgContext.settings?.member_mandatory_special_fields_for_registration?.value?.map((field) => {
            switch (field){
                case 'first_name':
                    const stringFirstName = formatMessage({ id: 'form.fields.firstName' })
                    return <li key={field}>{stringFirstName}</li>
                case 'last_name':
                    const stringLastName = formatMessage({ id: 'form.fields.lastName' })
                    return <li key={field}>{stringLastName}</li>
                case 'identify_as_indigenous':
                    const stringIndigenous = formatMessage({ id: 'form.fields.identifyAsIndigenous' })
                    return <li key={field}>{stringIndigenous}</li>
                case 'ethnicity_id':
                    const stringEthnicity = formatMessage({ id: 'form.fields.ethnicity' })
                    return <li key={field}>{stringEthnicity}</li>
                case 'birth_country':
                    const stringBirthCountry = formatMessage({ id: 'form.fields.birth_country' })
                    return <li key={field}>{stringBirthCountry}</li>
                case 'email':
                    const stringEmail = formatMessage({ id: 'form.fields.email' })
                    return <li key={field}>{stringEmail}</li>
                case 'citizenship':
                    const stringCitizenship = formatMessage({ id: 'form.fields.nationality' })
                    return <li key={field}>{stringCitizenship}</li>
                case 'gender':
                    const stringGender = formatMessage({ id: 'form.fields.gender' })
                    return <li key={field}>{stringGender}</li>
                case 'primary_language':
                    const stringPrimaryLanguage = formatMessage({ id: 'form.fields.primaryLanguage' })
                    return <li key={field}>{stringPrimaryLanguage}</li>
                case 'secondary_languages':
                    const stringSecondaryLanguages = formatMessage({ id: 'form.fields.secondaryLanguages' })
                    return <li key={field}>{stringSecondaryLanguages}</li>
            }
        });

        if(!Array.isArray(missingFieldsList) || missingFieldsList.length <= 0){
            return "";
        }

        return (
            <div>
                {topMessage}
                {orgFieldsTranslate}
                <ul className="mb-0 pl-1" style={{ listStylePosition: 'inside' }}>
                    {missingFieldsList}
                </ul>
            </div>
        )
    }

    const missingFieldStr = getMissingFieldString();

    return (
        missingFieldStr ?
            <Tooltip
                className={className}
                label={missingFieldStr}
                withinPortal={false}
                placement={tooltipPosition}
            >
                <MemberAlertsIcon icon="fields" className="pl-1" />
            </Tooltip>
            :
            null
    )
}

export{ MissingFieldsIcon }
export default MemberStatusIcons;