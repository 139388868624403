import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { jsObjectToApi } from '@spordle/helpers';

/** @type {React.Context<Omit<SuspensionsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const SuspensionsContext = createContext();
SuspensionsContext.displayName = 'SuspensionsContext';
class SuspensionsContextProvider extends React.Component{

    ////////////////////////////////////////////////////////////////
    // CTRL + F
    // - Suspensions
    // - Suspension Types
    ////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////
    // #region Suspensions
    ////////////////////////////////////////////////////////////////

    /**
     * Get all the Suspensions within an organization
     * @param {object} values The organization id we want to get the Suspensions from
     * @returns {Promise<Array>}
     */
    getSuspensions = (organizationId, values) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `suspensions`, query: {
            ...values,
            organisation_id: organizationId,
        } }, {
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.suspensions;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get Suspension information
     * @param {string} suspensionId
     * @returns {Promise}
     */
    getSuspension = (suspensionId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `suspensions/${suspensionId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.suspensions[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a specified Suspension with new values
     * @param {string} suspensionId ID of the Suspension to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @returns {Promise}
     */
    updateSuspension = (suspensionId, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `suspensions/${suspensionId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a Suspension under an Organization with specified values
     * @param {string} organizationId ID of the Organization to add a Suspension to
     * @param {object} values Values to create a Suspension with
     * @returns {Promise}
     */
    createSuspension = (organizationId, values) => {
        const params = new URLSearchParams({
            organisation_id: organizationId,
        });

        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;
                default: // name, descritpion, etc
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `suspensions` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.suspension_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific Suspension
     * @param {string} suspensionId ID of the Suspension to delete
     * @returns {Promise}
     */
    deleteSuspension = (suspensionId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `suspensions/${suspensionId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Suspensions

    ////////////////////////////////////////////////////////////////
    // #region Suspension Types
    ////////////////////////////////////////////////////////////////

    /**
     * Get all the Suspension types within an organization
     * @param {string} organizationId The organization id we want to get the Suspension types from
     * @param {object} queryParams
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Suspension%20Type/Apicontroller%5COrganisations%5CSuspensiontypes%3A%3AgetAllSuspensionTypes|documentation}
     * @returns {Promise<Array>}
     */
    getSuspensionTypes = (organizationId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/suspension-types`,
            query: Object.assign({
                organisation_id: organizationId,
            }, queryParams),
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.suspension_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get Suspension type information
     * @param {string} suspensionTypeId
     * @returns {Promise<Object>}
     */
    getSuspensionType = (suspensionTypeId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `suspension-types/${suspensionTypeId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.suspension_type[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a specified Suspension Type with new values
     * @param {string} suspensionTypeId ID of the Suspension Type to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Suspension%20Type/Apicontroller%5COrganisations%5CSuspensiontypes%3A%3AupdatePatchSuspensionType|documentation}
     * @returns {Promise}
     */
    updateSuspensionType = (suspensionTypeId, values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `suspension-types/${suspensionTypeId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a Suspension type with specified values
     * @param {object} values Values to create a Suspension type with
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Suspension%20Type/Apicontroller%5COrganisations%5CSuspensiontypes%3A%3AcreateSuspensionType|documentation}
     * @returns {Promise}
     */
    createSuspensionType = (values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `suspension-types` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.suspension_type_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific Suspension type
     * @param {string} suspensionTypeId ID of the Suspension type to delete
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Suspension%20Type/Apicontroller%5COrganisations%5CSuspensiontypes%3A%3AdeleteSuspensionType|documentation}
     * @returns {Promise}
     */
    deleteSuspensionType = (suspensionTypeId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `suspension-types/${suspensionTypeId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Suspension Types

    render(){
        return (
            <SuspensionsContext.Provider value={{ ...this }}>
                {this.props.children}
            </SuspensionsContext.Provider>
        )
    }
}

export default SuspensionsContextProvider