import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { AxiosIsCancelled, serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { PeriodsContext } from './contexts';
import { OrganizationContext } from './OrganizationContext';
import withContexts from '../helpers/withContexts';
import { jsObjectToApi } from '@spordle/helpers';
import { appendI18nForUpdate, DisplayI18n } from '../helpers/i18nHelper';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { fail } from '@spordle/toasts';
import { getRecursiveKeyVal } from '../views/reports/reportEngine/reportsEngineHelpers';


/** @type {React.Context<Omit<TeamsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & TeamsContextProvider['state']>} */
export const TeamsContext = createContext();
TeamsContext.displayName = 'TeamsContext';

////////////////////////////////////////////////////////////////////////////////////
// Sections (CTRL + F)
// - Teams
// - Team Members
// - Team Contacts
// - Team Contact Types
// - Team Category Types
// - Team Addresses
// - Team Address Types
// - Team Memos
// - Team Travel Types
// - Team Travel Permits
// - Team Categories
// - Team Settings
// - Team Comments
////////////////////////////////////////////////////////////////////////////////////

class TeamsContextProvider extends React.Component{

    state = {
        cachedTeam: {},
        restrictions: null,
        cachedTeamStatus: [],

        rosterTablesLoading: false,
        rosterTablesData: [],
        rosterTablesDataError: false,
        filters: null,
        resetFiltersKey: false, // boolean to trigger a formik reset of the filters
        completeRoster: [],
    }

    clearCachedTeam = () => {
        this.setState(() => ({ cachedTeam: {} }));
    }

    presetCurrentTeam = (teamData) => {
        return new Promise((resolve) => {
            this.setState(() => ({ cachedTeam: teamData }), resolve(true))
        })
    }

    ////////////////////////////////////////////////////////////////////////////////////
    // Teams
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Gets all teams
     * @param {object} [queryParams] The query params for that call
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Teams/GetAllTeams documentation}
     * @returns {Promise.<Array>}
     */
    getTeams = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/teams',
            query: {
                ...queryParams,
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lite: 1,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    const {
                        categories,
                        classes,
                        divisions,
                        organisations,
                        periods,
                        team_status,
                        teams,
                    } = response.data;
                    return teams.map((team) => {
                        team.organisation = organisations[team.organisation_id];
                        delete team.organisation_id;

                        team.period = periods[team.period_id];
                        delete team.period_id;

                        team.team_category = JSON.parse(JSON.stringify(categories[team.team_category_id]));// Need to deep copy because we delete some properties
                        delete team.team_category_id;
                        team.team_category.division = divisions[team.team_category.division_id];
                        delete team.team_category.division_id;
                        team.team_category.class = classes[team.team_category.class_id];
                        delete team.team_category.class_id;
                        team.team_category.organisation = organisations[team.team_category.organisation_id];
                        delete team.team_category.organisation_id;

                        team.team_status = team_status[team.team_status_id];
                        delete team.team_status_id;

                        return team;
                    });
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Gets all teams (Same as getTeams but taking into consideration the pagination)
     * @param {object} [queryParams] The query params for that call
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Teams/GetAllTeams documentation}
     * @returns {Promise.<Array>}
     */
    getTeamsWithPagination = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/teams',
            query: {
                ...queryParams,
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lite: 1,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    const {
                        categories,
                        classes,
                        divisions,
                        organisations,
                        periods,
                        team_status,
                        teams,
                        limit,
                        count,
                        total,
                    } = response.data;
                    const myTeams = teams.map((team) => {
                        team.organisation = organisations[team.organisation_id];
                        delete team.organisation_id;

                        team.period = periods[team.period_id];
                        delete team.period_id;

                        team.team_category = JSON.parse(JSON.stringify(categories[team.team_category_id]));// Need to deep copy because we delete some properties
                        delete team.team_category_id;
                        team.team_category.division = divisions[team.team_category.division_id];
                        delete team.team_category.division_id;
                        team.team_category.class = classes[team.team_category.class_id];
                        delete team.team_category.class_id;
                        team.team_category.organisation = organisations[team.team_category.organisation_id];
                        delete team.team_category.organisation_id;

                        team.team_status = team_status[team.team_status_id];
                        delete team.team_status_id;

                        return team;
                    });

                    return {
                        teams: myTeams,
                        limit: limit,
                        count: count,
                        total: total,
                    }
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[SPECIFIC] - Team
     * @param {string} team_id
     * @param {bool} fromAPI
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Teams/Apicontroller%5CTeams%5CTeams%3A%3AgetTeamDetails|documentation}
     * @returns {Promise}
     */
    getTeam = (team_id, fromAPI = false, queryParams = {}) => {
        if(!fromAPI && this.state.cachedTeam.team_id === team_id){
            return Promise.resolve(this.state.cachedTeam)
        }
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${team_id}`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this.setState(() => ({
                        cachedTeam: response.data.teams[0],
                    }));
                    return response.data.teams[0];
                }
                this.setState(() => ({
                    cachedTeam: {},
                }));
                throw response.data.errors[0];
            }, serverError)

    }

    /**
     * PATCH teams
     * @param {string} teamId Id of the team to update
     * @param {object} values Valuse to update
     * @returns {Promise}
     */
    partiallyUpdateTeam = (teamId, values) => {
        const params = new URLSearchParams(values)

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/teams/${teamId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates Team
     * @param {object} values Values to create a Team
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Teams/Apicontroller%5CTeams%5CTeams%3A%3AcreateTeam|documentation}
     * @returns {Promise}
     */
    createTeam = (values) => {
        const params = new URLSearchParams({
            organisation_id: this.props.OrganizationContext.organisation_id,
            period_id: this.props.PeriodsContext.selectedPeriod.period_id,
        })

        for(const key in values){
            switch (key){
                case 'team_category_id':
                    params.append('team_category_id', values.team_category_id);
                    break;
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'short_name':
                    params.append('short_name', values.short_name);
                    break;
                case 'abbreviation':
                    params.append('abbreviation', values.abbreviation);
                    break;
                case 'sponsor_name':
                    params.append('sponsor_name', values.sponsor_name);
                    break;
                case 'home_color':
                    params.append('home_color', values.home_color);
                    break;
                case 'away_color':
                    params.append('away_color', values.away_color);
                    break;
                case 'email':
                    params.append('email', values.email);
                    break;
                case 'phone':
                    params.append('phone', values.phone);
                    break;
                case 'tournament_number':
                    params.append('tournament_number', values.tournament_number);
                    break;
                case 'logo':
                    params.append('logo', values.logo);
                    break;
                case 'status':
                    params.append('status', values.status);
                    break;
                case 'team_fee':
                    params.append('team_fee', values.team_fee);
                    break;
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;
                case 'activity_period_id':
                    params.append('activity_period_id', values.activity_period_id);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/teams` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.team_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Rollover of teams
     * @param {[{team_id: string, category_id: string}]} teams
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Teams/Apicontroller%5CTeams%5CTeams%3A%3AcreateRollover|documentation}
     * @returns {Promise<[string]>} Returns an array of team ids
     */
    importTeams = (teams) => {
        const params = new URLSearchParams();

        params.append(`period_id`, this.props.PeriodsContext.selectedPeriod.period_id);
        teams.forEach((team, index) => {
            params.append(`teams[${index}][team_id]`, team.team_id);
            params.append(`teams[${index}][category_id]`, team.category_id);
            if(team.team_fee)
                params.append(`teams[${index}][team_fee]`, team.team_fee);
        });

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/teams/rollover` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.team_ids;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Adds a logo to a team
     * @param {string} teamId Id of the team
     * @param {object} values Values of the logo to post
     * @param {string} [dataUrl] temp file for immediate change in state
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Teams/Apicontroller%5CTeams%5CTeams%3A%3AupdateTeamLogo|documentation}
     * @returns {Promise.<Array>}
     */
    createTeamLogo = (teamId, values, dataUrl) => {
        // https://developer.mozilla.org/en-US/docs/Web/API/FormData/FormData
        const params = new FormData();

        params.append('attachment', values.attachment);
        params.append('file_position', values.file_position);

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `teams/${teamId}/logos`,
        }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    this.setState((prev) => ({ cachedTeam: { ...prev.cachedTeam, logo: { ...response.data.logo, full_path: dataUrl || response.data.logo?.full_path, file_position: values.file_position } } }));

                    return response.data.logo;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Updates a team
    * @param {string} team_id
    * @param {object} values Object containing the values to update
    * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Teams/Apicontroller%5CTeams%5CTeams%3A%3AupdateTeamPartially|documentation}
    * @returns {Promise}
    */
    updateTeamPartial = (team_id, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'short_name':
                    params.append('short_name', values.short_name);
                    break;
                case 'home_color':
                    params.append('home_color', values.home_color);
                    break;
                case 'away_color':
                    params.append('away_color', values.away_color);
                    break;
                case 'sponsor_name':
                    params.append('sponsor_name', values.sponsor_name);
                    break;
                case 'phone':
                    params.append('phone', values.phone);
                    break;
                case 'team_category_id':
                    params.append('team_category_id', values.team_category_id);
                    break;
                case 'tournament_number':
                    params.append('tournament_number', values.tournament_number);
                    break;
                case 'lock_roster_date':
                    params.append('lock_roster_date', values.lock_roster_date);
                    break;
                case 'lock_affiliate_roster_date':
                    params.append('lock_affiliate_roster_date', values.lock_affiliate_roster_date);
                    break;
                case 'team_fee':
                    params.append('team_fee', values.team_fee);
                    break;
                case 'activity_period_id':
                    params.append('activity_period_id', values.activity_period_id);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `teams/${team_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Approve or reject a team
     * @param {string} teamId Id of the team to update
     * @param {'APPROVED'|'REJECTED'} team_status Team Status
     * @param {string} comment Comment
     * @returns {Promise}
     */
    updateTeamApprobation = (teamId, team_status, comment, withInvalidReasons, skipValidation) => {
        const params = new URLSearchParams()
        params.append('team_status', team_status)
        if(comment)
            params.append('comment', comment)
        if(skipValidation)
            params.append('overwrite_validation', 1)

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/teams/${teamId}/approbations` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                if(withInvalidReasons){
                    throw{
                        error: response.data.errors[0],
                        invalid_reasons: response.data.invalid_reasons,
                        overwrite_approval: response.data.overwrite_approval,
                    }
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * DELETE - Deletes a team
     * @param {string} teamId
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Teams/Apicontroller%5CTeams%5CTeams%3A%3AdeleteTeam%7Cdocumentation%7D|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteTeam = (teamId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `teams/${teamId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    // Team Status
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Gets all team status
     * @param {object} [queryParams] The query params for that call
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Status/Apicontroller%5CTeams%5CTeamstatus%3A%3AgetAllTeamStatus|documentation}
     * @returns {Promise.<Array>}
     */
    getTeamStatus = (queryParams = {}, fromAPI = false) => {
        if(!fromAPI){
            return Promise.resolve(this.state.cachedTeamStatus);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `team-status`,
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this.setState(() => ({
                        cachedTeamStatus: response.data.status,
                    }))
                    return response.data.team_status;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    ////////////////////////////////////////////////////////////////////////////////////
    // Team Members
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Gets all the available members for the team
     * @param {string} teamId Team id
     * @param {object} [queryParams] the query params for that call
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Teams/Apicontroller%5CTeams%5CTeams%3A%3AgetAvailableMembersForTeam|documentation}
     * @returns {Promise.<Array>}
     */
    getAvailableTeamMembers = (teamId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${teamId}/search-members`,
            query: Object.assign({
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return { members: response.data.members, categories: response.data.team_category };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Gets all the available members for the team by search by teams
     * @param {string} teamId Team id
     * @param {object} [queryParams] the query params for that call
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Teams/Apicontroller%5CTeams%5CTeams%3A%3AgetAvailableMembersForTeamByTeam|documentation}
     * @returns {Promise.<Array>}
     */
    getAvailableMembersByTeams = (teamId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${teamId}/search-teams`,
            query: Object.assign({
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return { members: response.data.members, categories: response.data.team_category };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Gets all the available members for the team from the branch
     * @param {string} teamId Team id
     * @param {object} [queryParams] the query params for that call
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Teams/Apicontroller%5CTeams%5CTeams%3A%3AgetAvailableMembersForTeam|documentation}
     * @returns {Promise.<Array>}
     */
    getAvailableBranchMembers = (teamId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${teamId}/search-branch`,
            query: Object.assign({
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return { members: response.data.members, categories: response.data.team_category };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Validate member against the team restrictions
     * @param {string} teamId ID of the team to validate against member
     * @param {string} uniqueIdentifier Unique identifier of the member to validate against the team
     * @param {'PLAYER'|'STAFF'|'COACH'} memberType Member type of the member
     * @param {bool} asAffiliateParticipant Validate member as affiliated
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Teams/f8e9b3415494ad6cd23024b3d3408d05|documentation}
     * @returns {Promise}
     */
    validateMember = (teamId, uniqueIdentifier, memberType, asAffiliateParticipant) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${teamId}/validate-member`,
            query: {
                unique_identifier: uniqueIdentifier,
                member_type: memberType,
                as_affiliate_participant: asAffiliateParticipant >>> 0,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return { members: response.data.members, invalid_reasons: response.data.invalid_reasons };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Gets all team members of team
     * @param {string} team_id Team ID
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Members/Apicontroller%5CTeams%5CTeams%3A%3AgetAllTeamMembers|documentation}
     * @returns {Promise.<Array>}
     */
    getTeamMembers = (team_id, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${team_id}/members`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.members;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    // logic:
    // every table in the team roster tab use the same call (team members)
    // we do the API call here instead of in the team roster file because the TeamHeader also needs it
    // when we approve or reject a team, all of the members in the roster also get their status changed to either approved or rejected
    // so when we approve a team, we need to refresh all the tables

    getRosterTablesData = (teamId, paramfilters, resetFilters = false) => {
        this.setState((prev) => ({
            ...prev,
            rosterTablesLoading: true,
            rosterTablesDataError: false,
        }))
        // return the promise so the table views can do cool and fun and cool things if needed
        return Promise.all([
            this.getTeamMembers(teamId),
            this.getTeam(teamId, true, { components: 'contact,address,competition' }),
        ])
            .then(([ teamMembers, team ]) => {

                let roster = teamMembers
                const filters = paramfilters || this.state.filters
                if(filters && !resetFilters){
                    let data = teamMembers;
                    if(filters.crc_is_expired){
                        data = this.formatDataStaff(teamMembers)
                    }
                    roster = [ ...data.filter((teamMember) => {
                        return Object.keys(filters).every((key) => {
                            const value = filters[key]
                            if(key === 'missing_required_fields'){
                                return teamMember.member.missing_required_fields?.length > 0
                            }
                            return getRecursiveKeyVal(teamMember, key) == value
                        })
                    }) ]
                }

                this.setState((prev) => ({
                    ...prev,
                    rosterTablesLoading: false,
                    rosterTablesData: roster,
                    completeRoster: teamMembers,
                    filters: resetFilters ? null : filters,
                    resetFiltersKey: resetFilters ? !prev.resetFiltersKey : prev.resetFiltersKey,
                }))
                return teamMembers;
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    this.setState((prev) => ({
                        ...prev,
                        rosterTablesDataError: true,
                    }))
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    formatDataActive = (teamMembers, intl) => teamMembers.reduce((newArray, teamMember) => {
        // inactive members are in the inactive roster table
        // affiliate members are in the affiliate players table
        // only members with player position group here
        if(teamMember.active == 1 && teamMember.affiliate == 0 && teamMember.position?.position_group?.position_type === 'PLAYER'){
            // we want the user to be able to search "imported" in the search bar and see the imported members
            const option = [];
            if(teamMember.tryout == 1)
                option.push(intl.formatMessage({ id: 'teams.profile.roster.table.tryout' }))
            if(teamMember.import == 1)
                option.push(intl.formatMessage({ id: 'teams.profile.roster.table.imported' }))

            newArray.push({
                ...teamMember,
                option: option,
                name: teamMember.member.first_name + ' ' + teamMember.member.last_name, // for sort
            })
        }
        return newArray
    }, [])

    formatDataAffiliated = (teamMembers) => teamMembers.reduce((newArray, teamMember) => {
        // inactive members are in the inactive roster table
        // all active affiliate members here
        if(teamMember.active == 1 && teamMember.affiliate == 1 && teamMember.position?.position_group?.position_type === 'PLAYER'){
            newArray.push({ ...teamMember, name: teamMember.member.first_name + ' ' + teamMember.member.last_name }) // for sort
        }
        return newArray
    }, [])

    formatDataStaff = (teamMembers) => teamMembers.reduce((newArray, teamMember) => {
        // inactive members are in the inactive roster table
        // all the members with a staff or coach position group here
        if(teamMember?.member && teamMember.active == 1 && (teamMember.position?.position_group?.position_type === 'STAFF' || teamMember.position?.position_group?.position_type === 'COACH')){
            newArray.push({ ...teamMember, name: teamMember.member?.first_name + ' ' + teamMember.member?.last_name }) // for sort
        }
        return newArray
    }, [])

    formatDataInactive = (teamMembers, intl) => teamMembers.reduce((newArray, teamMember) => {
        // all inactive members here
        if(teamMember.active == 0){
            // we want the user to be able to search "imported" in the search bar and see the imported members
            const option = [];
            if(teamMember.tryout == 1)
                option.push(intl.formatMessage({ id: 'teams.profile.roster.table.tryout' }))
            if(teamMember.import == 1)
                option.push(intl.formatMessage({ id: 'teams.profile.roster.table.imported' }))
            if(teamMember.affiliate == 1)
                option.push(intl.formatMessage({ id: 'teams.profile.roster.table.affiliated' }))

            newArray.push({
                ...teamMember,
                option: option,
                name: teamMember.member.first_name + ' ' + teamMember.member.last_name, // for sort
            })
        }
        return newArray
    }, [])

    /**
     * GET[ALL] - Gets all team member status
     * @param {object} [queryParams] The query params for that call
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Member%20Status/Apicontroller%5CTeams%5CTeammemberstatus%3A%3AgetAllTeamMemberStatus|documentation}
     * @returns {Promise.<Array>}
     */
    getTeamMemberStatus = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/team-member-status`,
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_member_status;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * /teams/{team_id}/members-branch
     * @param {string} team_id
     * @param {array} values Values to create a Team member
     * @param {boolean} [multiErrors=false] If we want to have all errors returned on the call or not
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Members/Apicontroller%5CTeams%5CTeams%3A%3AcreateTeamMembersBranch documentation}
     * @returns {Promise}
     */
    createTeamMemberBranch = (team_id, values, multiErrors = false) => {
        const params = new URLSearchParams();

        values.forEach((value, index) => {
            params.append(`members[${index}][member_id]`, value.member_id);
            params.append(`members[${index}][position_id]`, value.position_id);

            if(value.jersey_number) params.append(`members[${index}][jersey_number]`, value.jersey_number);
            if(value.captaincy) params.append(`members[${index}][captaincy]`, value.captaincy);
            if(value.status) params.append(`members[${index}][status]`, value.status);
            if(value.affiliate == 1) params.append(`members[${index}][affiliate]`, value.affiliate);
            if(value.tryout == 1) params.append(`members[${index}][tryout]`, value.tryout);
            if(value.import == 1) params.append(`members[${index}][import]`, value.import);
            if(value.transfer == 1) params.append(`members[${index}][transfer]`, value.transfer);
            if(value.share == 1) params.append(`members[${index}][share]`, value.share);
            if(value.shared_expiration_date) params.append(`members[${index}][shared_expiration_date]`, value.shared_expiration_date);

            params.append(`members[${index}][active]`, "1");
            params.append(`members[${index}][team_member_status_id]`, "dbb52e5d-d5f7-11eb-b6f2-06b680167fa2") // TODO: HARDCODED (team_member_status_id)
        })

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/teams/${team_id}/members-branch` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.status;
                }
                if(multiErrors){
                    return Promise.reject(response.data.errors);
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates Team member
     * @param {string} team_id
     * @param {array} values Values to create a Team member
     * @param {boolean} [multiErrors=false] If we want to have all errors returned on the call or not
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Members/LinkMemberTeam documentation}
     * @returns {Promise}
     */
    createTeamMember = (team_id, values, multiErrors = false) => {
        const params = new URLSearchParams();

        values.forEach((value, index) => {
            params.append(`members[${index}][member_id]`, value.member_id);
            params.append(`members[${index}][position_id]`, value.position_id);

            if(value.jersey_number) params.append(`members[${index}][jersey_number]`, value.jersey_number);
            if(value.captaincy) params.append(`members[${index}][captaincy]`, value.captaincy);
            if(value.status) params.append(`members[${index}][status]`, value.status);
            if(value.affiliate == 1) params.append(`members[${index}][affiliate]`, value.affiliate);
            if(value.tryout == 1) params.append(`members[${index}][tryout]`, value.tryout);
            if(value.import == 1) params.append(`members[${index}][import]`, value.import);

            params.append(`members[${index}][active]`, "1");
            params.append(`members[${index}][team_member_status_id]`, "dbb52e5d-d5f7-11eb-b6f2-06b680167fa2")
        })

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/teams/${team_id}/members` }), params)
            .then((response) => {
                if(response.data.status){
                    return {
                        team_member_ids: response.data.team_member_ids,
                        status: response.data.status,
                    }
                }
                if(multiErrors){
                    return Promise.reject(response.data.errors);
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Updates a specified Team member with new values
    * @param {string} team_id
    * @param {string} team_member_id
    * @param {object} values Object containing the values to update
    * @param {boolean} [multiErrors=false]
    * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Members/cfb2c480d992774db55c769419bf758f documentation}
    * @returns {Promise}
    */
    updateTeamMember = (team_id, team_member_id, values, multiErrors = false) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'jersey_number':
                    params.append('jersey_number', values.jersey_number);
                    break;
                case 'jersey_number_alternate':
                    params.append('jersey_number_alternate', values.jersey_number_alternate);
                    break;
                case 'position_id':
                    params.append('position_id', values.position_id);
                    break;
                case 'captaincy':
                    params.append('captaincy', values.captaincy);
                    break;
                case 'status':
                    params.append('status', values.status);
                    break;
                case 'registration_date':
                    params.append('registration_date', values.registration_date);
                    break;
                case 'team_member_status_id':
                    params.append('team_member_status_id', values.team_member_status_id);
                    break;
                case 'tryout':
                    params.append('tryout', values.tryout);
                    break;
                case 'import':
                    params.append('import', values.import);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `teams/${team_id}/members/${team_member_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                if(multiErrors){
                    return Promise.reject(response.data.errors);
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Updates an array of team members in batch
    * @param {string} team_id
    * @param {array} values Array of the team members to update
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Members/Apicontroller%5CTeams%5CTeams%3A%3AupdateTeamMember|documentation}
    * @returns {Promise}
    */
    updateTeamMembersBatch = (team_id, values) => {
        const params = new URLSearchParams();

        values.forEach((teamMember, index) => {
            params.append(`team_member[${index}][team_member_id]`, teamMember.team_member_id);
            params.append(`team_member[${index}][position_id]`, teamMember.position_id);
            params.append(`team_member[${index}][jersey_number]`, teamMember.jersey_number);
            params.append(`team_member[${index}][jersey_number_alternate]`, teamMember.jersey_number_alternate);
        })

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `teams/${team_id}/members` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Release a team member
    * @param {string} team_id
    * @param {string} team_member_id
    * @param {boolean} [multiErrors=false]
    * @param {string?} [releaseDate]
    * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Members/0caa19ac4a6615b4d5e7a99634025148 documentation}
    * @returns {Promise}
    */
    releaseTeamMember = (team_id, team_member_id, multiErrors = false, releaseDate = null) => {
        const params = new URLSearchParams();
        if(releaseDate)
            params.append('release_date', releaseDate);

        return API_SPORDLE.patch(`teams/${team_id}/members/${team_member_id}/releases`, params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                if(multiErrors){
                    return Promise.reject(response.data.errors);
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Deletes a specific Team Member
    * @param {string} team_id
    * @param {string} team_member_id
    * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Members/8e1d425bd6f5b4ee16d7419a62bd9c2f documentation}
    * @returns {Promise}
    */
    deleteTeamMember = (team_id, team_member_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `teams/${team_id}/members/${team_member_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    ////////////////////////////////////////////////////////////////////////////////////
    // Team Contacts
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Get Team contacts
     * @param {string} team_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contacts/Apicontroller%5CTeams%5CTeamcontacts%3A%3AgetAllTeamContact|documentation}
     * @returns {Promise.<Array>}
     */
    getTeamContacts = (team_id, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${team_id}/contacts`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.contacts;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
   * GET[SPECIFIC] - Team contact
   * @param {string} team_id
   * @param {string} team_contact_id
   * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contacts/Apicontroller%5CTeams%5CTeamcontacts%3A%3AgetTeamContactDetail|documentation}
   * @returns {Promise}
   */
    getTeamContact = (team_id, team_contact_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/teams/${team_id}/contacts/${team_contact_id}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.contacts[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create Team contact
     * @param {object} values to create Team contact
     * @param {string} team_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contacts/Apicontroller%5CTeams%5CTeamcontacts%3A%3AcreateTeamContact|documentation}
     * @returns {Promise}
     */
    createTeamContact = (team_id, values) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'team_contact_type_id':
                    params.append('team_contact_type_id', values.team_contact_type_id);
                    break;
                case 'member_id':
                    params.append('member_id', values.member_id);
                    break;
                case 'first_name':
                    params.append('first_name', values.first_name);
                    break;
                case 'last_name':
                    params.append('last_name', values.last_name);
                    break;
                case 'email':
                    params.append('email', values.email);
                    break;
                case 'phone':
                    params.append('phone', values.phone);
                    break;
                case 'phone_type_id':
                    params.append('phone_type_id', values.phone_type_id);
                    break;
                case 'display_on':
                    params.append('display_on', values.display_on);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/teams/${team_id}/contacts` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update a Team contact
     * @param {string} team_id
     * @param {string} team_contact_id
     * @param {object} [values] The query params for that call -
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contacts/Apicontroller%5CTeams%5CTeamcontacts%3A%3AupdateTeamContact|documentation}
     * @returns {Promise.<boolean>}
     */
    updateTeamContact = (team_id, team_contact_id, values) => {
        const data = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'team_contact_type_id':
                    data.append('team_contact_type_id', values.team_contact_type_id);
                    break;
                case 'member_id':
                    data.append('member_id', values.member_id);
                    break;
                case 'first_name':
                    data.append('first_name', values.first_name);
                    break;
                case 'last_name':
                    data.append('last_name', values.last_name);
                    break;
                case 'email':
                    data.append('email', values.email);
                    break;
                case 'phone':
                    data.append('phone', values.phone);
                    break;
                case 'phone_type_id':
                    data.append('phone_type_id', values.phone_type_id);
                    break;
                case 'display_on':
                    data.append('display_on', values.display_on);
                    break;

                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/teams/${team_id}/contacts/${team_contact_id}`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
    * Updates a specified Team contact with new values
    * @param {string} team_id
    * @param {string} team_contact_id
    * @param {object} values Object containing the values to update
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contacts/Apicontroller%5CTeams%5CTeamcontacts%3A%3ApatchTeamContact|documentation}
    * @returns {Promise}
    */
    updateTeamContactPartial = (team_id, team_contact_id, values) => {
        const params = new URLSearchParams();

        for(const key in values){
            switch (key){
                case 'team_contact_type_id':
                    params.append('team_contact_type_id', values.team_contact_type_id);
                    break;
                case 'member_id':
                    params.append('member_id', values.member_id);
                    break;
                case 'first_name':
                    params.append('first_name', values.first_name);
                    break;
                case 'last_name':
                    params.append('last_name', values.last_name);
                    break;
                case 'email':
                    params.append('email', values.email);
                    break;
                case 'phone':
                    params.append('phone', values.phone);
                    break;
                case 'phone_type_id':
                    params.append('phone_type_id', values.phone_type_id);
                    break;
                case 'display_on':
                    params.append('display_on', values.display_on);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `teams/${team_id}/contacts/${team_contact_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Deletes all Team contacts for given team
    * @param {string} team_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contacts/Apicontroller%5CTeams%5CTeamcontacts%3A%3AdeleteAllTeamContact|documentation}
    * @returns {Promise}
    */
    deleteAllTeamContacts = (team_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `teams/${team_id}/contacts` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Deletes a specific Team contact
    * @param {string} team_id
    * @param {string} team_contact_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contacts/Apicontroller%5CTeams%5CTeamcontacts%3A%3AdeleteTeamContact|documentation}
    * @returns {Promise}
    */
    deleteTeamContact = (team_id, team_contact_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `teams/${team_id}/contacts/${team_contact_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    ////////////////////////////////////////////////////////////////////////////////////
    // Team Contacts Types
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Get Team contact types
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3Aindex|documentation}
     * @returns {Promise.<Array>}
     */
    getTeamContactTypes = (queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/team-contact-types`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_contact_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
   * GET[SPECIFIC] - Team contact type
   * @param {string} team_contact_type_id
   * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3AgetTeamContacttypeDetail|documentation}
   * @returns {Promise}
   */
    getTeamContactType = (team_contact_type_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/team-contact-type/${team_contact_type_id}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_contact_types[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create Team contact type
     * @param {object} values to create Team contact type
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3AcreateTeamContacttype|documentation}
     * @returns {Promise}
     */
    createTeamContactType = (values) => {
        const params = new URLSearchParams({
            organisation_id: this.props.OrganizationContext.organisation_id,
        });

        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;
                default: // name, description, etc
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/team-contact-types` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.team_contact_type_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update a Team contact type
     * @param {string} team_contact_type_id
     * @param {object} [values] The query params for that call -
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3AupdateTeamContacttype|documentation}
     * @returns {Promise.<boolean>}
     */
    updateTeamContactType = (team_contact_type_id, values) => {
        const data = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'name':
                    data.append('name', values.name);
                    break;
                case 'active':
                    data.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/team-contact-types/${team_contact_type_id}`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
    * Updates a specified Team contact type with new values
    * @param {string} team_contact_type_id
    * @param {object} values Object containing the values to update
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3ApatchTeamContacttype|documentation}
    * @returns {Promise}
    */
    updateTeamContactTypePartial = (team_contact_type_id, values) => {
        const params = new URLSearchParams();

        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `team-contact-types/${team_contact_type_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Delete specific team contact type
    * @param {string} team_contact_type_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3AdeleteTeamContacttypeDetail|documentation}
    * @returns {Promise}
    */
    deleteTeamContactType = (team_contact_type_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `team-contact-types/${team_contact_type_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    ////////////////////////////////////////////////////////////////////////////////////
    // Team Category Types
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Gets all Team Category Type
     * @param {object} [queryParams] The query params for that call
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Category%20Types/Apicontroller%5CTeams%5CTeamcategorytypes%3A%3AgetAllTeamCategoryType|documentation}
     * @returns {Promise.<Array>}
     */
    getTeamCategoryTypes = (queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/team-category-types`,
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_category_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
   * GET[SPECIFIC] - Team Category Type
   * @param {string} team_category_type_id
   * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Category%20Types/Apicontroller%5CTeams%5CTeamcategorytypes%3A%3AgetTeamCategoryTypeDetail|documentation}
   * @returns {Promise}
   */
    getTeamCategoryType = (team_category_type_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/team-category-types/${team_category_type_id}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_category_types[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create Team Category Type
     * @param {array} values to create a Team Category Type
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Category%20Types/Apicontroller%5CTeams%5CTeamcategorytypes%3A%3AcreateTeamCategoryType|documentation}
     * @returns {Promise}
     */
    createTeamCategoryType = (organisationId, values) => {
        const params = new URLSearchParams({
            organisation_id: organisationId,
        });

        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'active':
                    params.append('active', values.active == 1 ? 1 : 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/team-category-types` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.status;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update a Team Category Type
     * @param {string} team_category_type_id
     * @param {object} [values] The query params for that call -
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Category%20Types/Apicontroller%5CTeams%5CTeamcategorytypes%3A%3AupdateTeamCategoryType|documentation}
     * @returns {Promise.<boolean>}
     */
    updateTeamCategoryType = (team_category_type_id, values) => {
        const data = new URLSearchParams({
            organisation_id: this.props.OrganizationContext.organisation_id,
        })

        for(const key in values){
            switch (key){
                case 'name':
                    data.append('name', values.name);
                    break;
                case 'active':
                    data.append('active', values.active ? 1 : 0);
                    break;
                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/team-category-types/${team_category_type_id}`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
    * Updates a specified Team Category Type with new values
    * @param {string} team_category_type_id
    * @param {object} values Object containing the values to update
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Category%20Types/Apicontroller%5CTeams%5CTeamcategorytypes%3A%3ApatchTeamCategoryType|documentation}
    * @returns {Promise}
    */
    updateTeamCategoryTypePartial = (team_category_type_id, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'active':
                    params.append('active', values.active == 1 ? 1 : 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `team-category-types/${team_category_type_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Deletes a specific Team Category Type
    * @param {string} team_category_type_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Category%20Types/Apicontroller%5CTeams%5CTeamcategorytypes%3A%3AdeleteTeamCategoryType|documentation}
    * @returns {Promise}
    */
    deleteTeamCategoryType = (team_category_type_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `team-category-types/${team_category_type_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    ////////////////////////////////////////////////////////////////////////////////////
    // Team Addresses
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Get Team Address
     * @param {string} team_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Addresses/Apicontroller%5CTeams%5CTeamaddresses%3A%3AgetAllTeamAddress|documentation}
     * @returns {Promise.<Array>}
     */
    getTeamAddresses = (team_id, queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${team_id}/addresses`,
            query: Object.assign(queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.addresses;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[SPECIFIC] - Team Address
     * @param {string} team_id
     * @param {string} team_address_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Addresses/Apicontroller%5CTeams%5CTeamaddresses%3A%3AgetTeamAddressDetail|documentation}
     * @returns {Promise}
     */
    getTeamAddress = (team_id, team_address_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/teams/${team_id}/addresses/${team_address_id}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.addresses[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create Team Address
     * @param {object} values to create Team Address
     * @param {string} team_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Addresses/Apicontroller%5CTeams%5CTeamaddresses%3A%3AcreateTeamAddress|documentation}
     * @returns {Promise}
     */
    createTeamAddress = (team_id, values) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'team_address_type_id':
                    params.append('team_address_type_id', values.team_address_type_id);
                    break;
                case 'unit_number':
                    params.append('unit_number', values.unit_number);
                    break;
                case 'street_number':
                    params.append('street_number', values.street_number);
                    break;
                case 'street':
                    params.append('street', values.street);
                    break;
                case 'origin_address':
                    params.append('origin_address', values.origin_address);
                    break;
                case 'country_id':
                    params.append('country_id', values.country_id);
                    break;
                case 'province_id':
                    params.append('province_id', values.province_id);
                    break;
                case 'city':
                    params.append('city', values.city);
                    break;
                case 'zip_code':
                    params.append('zip_code', values.zip_code);
                    break;
                case 'default_address':
                    params.append('default_address', values.default_address);
                    break;
                case 'map_url':
                    params.append('map_url', values.map_url);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/teams/${team_id}/addresses` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update a Team Address
     * @param {string} team_id
     * @param {string} team_address_id
     * @param {object} [values] The query params for that call -
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Addresses/Apicontroller%5CTeams%5CTeamaddresses%3A%3AupdateTeamAddress|documentation}
     * @returns {Promise.<boolean>}
     */
    updateTeamAddress = (team_id, team_address_id, values) => {
        const data = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'team_address_type_id':
                    data.append('team_address_type_id', values.team_address_type_id);
                    break;
                case 'unit_number':
                    data.append('unit_number', values.unit_number);
                    break;
                case 'street_number':
                    data.append('street_number', values.street_number);
                    break;
                case 'street':
                    data.append('street', values.street);
                    break;
                case 'origin_address':
                    data.append('origin_address', values.origin_address);
                    break;
                case 'country_id':
                    data.append('country_id', values.country_id);
                    break;
                case 'province_id':
                    data.append('province_id', values.province_id);
                    break;
                case 'city':
                    data.append('city', values.city);
                    break;
                case 'zip_code':
                    data.append('zip_code', values.zip_code);
                    break;
                case 'default_address':
                    data.append('default_address', values.default_address);
                    break;
                case 'map_url':
                    data.append('map_url', values.map_url);
                    break;
                case 'active':
                    data.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/teams/${team_id}/addresses/${team_address_id}`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
    * Updates a specified Team Address with new values
    * @param {string} team_id
    * @param {string} team_address_id
    * @param {object} values Object containing the values to update
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Addresses/Apicontroller%5CTeams%5CTeamaddresses%3A%3ApatchTeamAddress|documentation}
    * @returns {Promise}
    */
    updateTeamAddressPartial = (team_id, team_address_id, values) => {
        const params = new URLSearchParams();

        for(const key in values){
            switch (key){
                case 'team_address_type_id':
                    params.append('team_address_type_id', values.team_address_type_id);
                    break;
                case 'unit_number':
                    params.append('unit_number', values.unit_number);
                    break;
                case 'street_number':
                    params.append('street_number', values.street_number);
                    break;
                case 'street':
                    params.append('street', values.street);
                    break;
                case 'origin_address':
                    params.append('origin_address', values.origin_address);
                    break;
                case 'country_id':
                    params.append('country_id', values.country_id);
                    break;
                case 'province_id':
                    params.append('province_id', values.province_id);
                    break;
                case 'city':
                    params.append('city', values.city);
                    break;
                case 'zip_code':
                    params.append('zip_code', values.zip_code);
                    break;
                case 'default_address':
                    params.append('default_address', values.default_address);
                    break;
                case 'map_url':
                    params.append('map_url', values.map_url);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `teams/${team_id}/addresses/${team_address_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Deletes all Team Address for given team
    * @param {string} team_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Addresses/Apicontroller%5CTeams%5CTeamaddresses%3A%3AdeleteAllTeamAddress|documentation}
    * @returns {Promise}
    */
    deleteAllTeamAddress = (team_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `teams/${team_id}/addresses` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Deletes a specific Team Address
    * @param {string} team_id
    * @param {string} team_address_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Addresses/Apicontroller%5CTeams%5CTeamaddresses%3A%3AdeleteTeamAddress|documentation}
    * @returns {Promise}
    */
    deleteTeamAddress = (team_id, team_address_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `teams/${team_id}/addresses/${team_address_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    ////////////////////////////////////////////////////////////////////////////////////
    // Team Addresses Types
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * Get all the Address Types within an organization
     * @param {string} organizationId The organization id we want to get the Classes from
     * @returns {Promise<Array>}
     */
    getAddressTypes = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `team-address-types`, query: { organisation_id: organizationId } }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_address_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get Address Type information
     * @param {string} addressTypeId
     * @returns {Promise}
     */
    getAddressType = (addressTypeId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `team-address-types/${addressTypeId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_address_types[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a specified Address Type with new values
     * @param {string} addressTypeId ID of the Address Type to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @param {string} [lang] Language variable corresponding to the values - Used for the I18N logic
     * @param {object} [i18n] Object containing an array of all the languages available and an object of the initialValues for language variables - Used for the I18N logic
     * @returns {Promise}
     */
    partialUpdateAddressType = (addressTypeId, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `team-address-types/${addressTypeId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * Updates a Address Type
     * @param {string} addressTypeId ID of the Address Type to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @param {string} [lang] Language variable corresponding to the values - Used for the I18N logic
     * @param {object} [i18n] Object containing an array of all the languages available and an object of the initialValues for language variables - Used for the I18N logic
     * @returns {Promise}
     */
    updateAddressType = (addressTypeId, values, lang, i18n) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                case 'name':
                    appendI18nForUpdate(params, 'name', lang, values, i18n)
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `team-address-types/${addressTypeId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates an Address Type under an Organization with specified values
     * @param {string} organizationId ID of the Organization to add an Address Type to
     * @param {object} values Values to create a Team Address Type with
     * @param {object} [i18n] I18n object containing the languages array
     * @returns {Promise}
     */
    createAddressType = (organizationId, values) => {
        const params = new URLSearchParams({
            organisation_id: organizationId,
        });

        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;
                default: // name, descritpion, etc
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `team-address-types` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.team_address_type_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific Address Type
     * @param {string} addressTypeId ID of the Address Type to delete
     * @returns {Promise}
     */
    deleteAddressType = (addressTypeId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `team-address-types/${addressTypeId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    ////////////////////////////////////////////////////////////////////////////////////
    // Team Memos
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Gets all Team memo
     * @param {string} team_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Memos/Apicontroller%5CTeams%5CTeammemos%3A%3AgetAllTeamMemo|documentation}
     * @returns {Promise.<Array>}
     */
    getTeamMemos = (team_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${team_id}/memos`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.memos;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
   * GET[SPECIFIC] - Team memo
   * @param {string} team_id
   * @param {string} team_memo_id
   * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Memos/Apicontroller%5CTeams%5CTeammemos%3A%3AgetSpecificTeamMemo|documentation}
   * @returns {Promise}
   */
    getTeamMemo = (team_id, team_memo_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/teams/${team_id}/memos/${team_memo_id}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.memos;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create Team memo
     * @param {array} values to create a team memo
     * @param {string} team_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Memos/Apicontroller%5CTeams%5CTeammemos%3A%3AcreateTeamMemos|documentation}
     * @returns {Promise}
     */
    createTeamMemo = (team_id, values) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'description':
                    params.append('description', values.description);
                    break;
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/teams/${team_id}/memos` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.status;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update a Team memo
     * @param {string} team_id
     * @param {string} team_memo_id
     * @param {object} [values] The query params for that call -
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Memos/Apicontroller%5CTeams%5CTeammemos%3A%3AupdateTeamMemos|documentation}
     * @returns {Promise.<boolean>}
     */
    updateTeamMemo = (team_id, team_memo_id, values) => {
        const data = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'name':
                    data.append('name', values.name);
                    break;
                case 'description':
                    data.append('description', values.description);
                    break;
                case 'active':
                    data.append('active', values.active ? 1 : 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/teams/${team_id}/memos/${team_memo_id}`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
    * Updates a specified Team memo with new values
    * @param {string} team_id
    * @param {string} team_memo_id
    * @param {object} values Object containing the values to update
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Memos/Apicontroller%5CTeams%5CTeammemos%3A%3ApatchTeamMemos|documentation}
    * @returns {Promise}
    */
    updateTeamMemoPartial = (team_id, team_memo_id, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'description':
                    params.append('description', values.description);
                    break;
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }
        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `teams/${team_id}/memos/${team_memo_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Deletes a specific Team memo
    * @param {string} team_id
    * @param {string} team_memo_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Memos/Apicontroller%5CTeams%5CTeammemos%3A%3AdeleteSpecificTeamMemos|documentation}
    * @returns {Promise}
    */
    deleteTeamMemo = (team_id, team_memo_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `teams/${team_id}/memos/${team_memo_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    // Team Travel Types
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Get travel types
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Travel%20Types/b0472f82965609d82e2425db87a7a20e|documentation}
     * @returns {Promise.<Array>}
     */
    getTravelTypes = (queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/travel-types`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.travel_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
   * GET[SPECIFIC] - Travel type
   * @param {string} travek_type_id
   * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Travel%20Types/1f247df53360d14573ee95908a2af37a|documentation}
   * @returns {Promise}
   */
    getTravelType = (travek_type_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/travel-types/${travek_type_id}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.travel_types[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create travel type
     * @param {object} values to create travel type
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Travel%20Types/69b2d6ab1c033e791f330913d6842494|documentation}
     * @returns {Promise}
     */
    createTravelType = (values) => {
        const params = new URLSearchParams({
            organisation_id: this.props.OrganizationContext.organisation_id,
        });

        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', values.active ? 1 : 0);
                    break;
                default: // name, descritpion, etc
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/travel-types` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.travel_type_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update a Team travel type
     * @param {string} travel_type_id
     * @param {object} [values] The query params for that call -
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Travel%20Types/3f2135b49e695b44637c07c8cc15aa00|documentation}
     * @returns {Promise.<boolean>}
     */
    updateTravelType = (travel_type_id, values) => {
        const data = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'name':
                    data.append('name', values.name);
                    break;
                case 'active':
                    data.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/travel-types/${travel_type_id}`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
    * Updates a specified travel type with new values
    * @param {string} travel_type_id
    * @param {object} values Object containing the values to update
    * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Travel%20Types/a79b2c15175860be4716662c367e80e6|documentation}
    * @returns {Promise}
    */
    updateTravelTypePartial = (travel_type_id, values) => {
        const params = new URLSearchParams();

        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `travel-types/${travel_type_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Delete specific travel type
    * @param {string} travel_type_id
    * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Travel%20Types/dda29f8596c62896c4d99b6ad7a953c0|documentation}
    * @returns {Promise}
    */
    deleteTravelType = (travel_type_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `travel-types/${travel_type_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    ////////////////////////////////////////////////////////////////////////////////////
    // Team Travel Permits
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * GET - Get a specific travel permit
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Travel%20Permits/c6e336509e3f9165553f52374916263d|documentation}
     * @returns {Promise}
     */
    getTravelPermit = (team_id, travel_permit_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${team_id}/travel-permits/${travel_permit_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.travel_permits;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Get Team travel permits
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3Aindex|documentation}
     * @returns {Promise.<Array>}
     */
    getTravelPermits = (team_id, queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${team_id}/travel-permits`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.travel_permits;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Get organization travel permits
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3Aindex|documentation}
     * @returns {Promise.<Array>}
     */
    getOrgTravelPermits = (org_id, queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/${org_id}/travel-permits`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    // Lite Format
                    const travel_permits = response.data.travel_permits || [];
                    const host_divisions = response.data.host_divisions || {};
                    const host_classes = response.data.host_classes || {};
                    const host_team_categories = response.data.host_team_categories || {};

                    // Build Resources
                    return travel_permits.map((travelPermit) => {
                        if(host_team_categories[travelPermit.host_team_category_id]){
                            travelPermit.host_team_category = host_team_categories[travelPermit.host_team_category_id];
                            travelPermit.host_team_category.host_team_category_id = travelPermit.host_team_category_id;

                            if(host_divisions[travelPermit.host_team_category.division_id]){
                                travelPermit.host_team_category.division = host_divisions[travelPermit.host_team_category.division_id];
                                travelPermit.host_team_category.division.division_id = travelPermit.host_team_category.division_id;
                            }

                            if(host_classes[travelPermit.host_team_category.class_id]){
                                travelPermit.host_team_category.class = host_classes[travelPermit.host_team_category.class_id];
                                travelPermit.host_team_category.class.class_id = travelPermit.host_team_category.class_id;
                            }
                        }

                        // Cleanup Resources IDs
                        delete travelPermit.host_team_category_id;
                        delete travelPermit.host_team_category?.class_id;
                        delete travelPermit.host_team_category?.division_id;

                        return travelPermit;
                    });
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create Team travel permit
     * @param {object} values to create Team contact type
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3AcreateTeamContacttype|documentation}
     * @returns {Promise}
     */
    createTeamTravelPermit = ({ attachments, ...values }, org_id) => {
        const params = new FormData();
        params.append('organisation_id', org_id)
        params.append('period_id', this.props.PeriodsContext.selectedPeriod.period_id)

        jsObjectToApi(values, params, {
            skipEmptyString: true,
        })

        if(attachments){
            let index = 0;
            Object.keys(attachments).forEach((documentTypeId) => {
                attachments[documentTypeId].forEach((document) => {
                    params.append(`attachments[${index}][attachment]`, document)
                    params.append(`attachments[${index}][active]`, 1)
                    if(documentTypeId != 'null')
                        params.append(`attachments[${index}][document_type_id]`, documentTypeId)
                    index++
                })
            })
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/teams/${values.team_id}/travel-permits` }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.travel_permit_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update Team travel permit lineup
     * @param {object} values to create Team contact type
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Travel%20Permit%20Lineups/361563417232702ece0649b3b1ddb99f|documentation}
     * @returns {Promise}
     */
    updateTeamTravelPermitLineup = (teamId, travelPermitId, values) => {
        const params = new URLSearchParams();

        if(values.members){
            values.members.forEach((member, i) => {
                if(member.travel_permit_lineup_id){
                    params.append(`travel_permit_lineups[${i}][travel_permit_lineup_id]`, member.travel_permit_lineup_id)
                }

                params.append(`travel_permit_lineups[${i}][member_id]`, member.member_id);
                params.append(`travel_permit_lineups[${i}][position_id]`, member.position_id);
            });
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/teams/${teamId}/travel-permits/${travelPermitId}/lineup` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.travel_permit_id;
                }
                throw response.data.errors;
            }, serverError)
    }

    /**
     * Get Team travel permit lineup
     * @param {object} values to create Team contact type
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Travel%20Permit%20Lineups/361563417232702ece0649b3b1ddb99f|documentation}
     * @returns {Promise}
     */
    getTeamTravelPermitLineup = (teamId, travelPermitId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/teams/${teamId}/travel-permits/${travelPermitId}/lineup` }))
            .then((response) => {
                if(response.data.status){
                    // Lite Format
                    const travel_permit_lineups = response.data.travel_permit_lineups || [];
                    const members = response.data.members || {};
                    const positions = response.data.positions || {};
                    const identities = response.data.identities || {};

                    // Build resources
                    return travel_permit_lineups.map((lineup) => {
                        if(identities[lineup.created_by]){
                            lineup.created_by = {
                                identity_id: lineup.created_by,
                                ...identities[lineup.created_by],
                            }
                        }

                        if(members[lineup.member_id]){
                            lineup.member = members[lineup.member_id];
                            lineup.member.member_id = lineup.member_id;
                        }

                        if(positions[lineup.position_id]){
                            lineup.position = positions[lineup.position_id];
                            lineup.position.position_id = lineup.position_id;
                        }

                        // Cleanup resource IDs
                        delete lineup.member_id;
                        delete lineup.position_id;

                        return lineup;
                    });
                }
                throw response.data.errors;
            }, serverError)
    }

    /**
     * Update a Team travel permit
     * @param {string} travel_permit_id
     * @param {string} team_id
     * @param {object} [values] The query params for that call -
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3AupdateTeamContacttype|documentation}
     * @returns {Promise.<boolean>}
     */
    updateTeamTravelPermitPartial = (travel_permit_id, team_id, values) => {
        const params = new URLSearchParams();

        for(const key in values){
            switch (key){
            // case 'active':
            //     params.append('active', (values[key] == '1') >>> 0);
            //     break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/teams/${team_id}/travel-permits/${travel_permit_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] - Submit travel permit
     * @param {string} identityId Identity Id
     * @param {string} travelPermitId Travel permit Id
     * @returns {Promise}
     */
    submitTravelPermit = (identityId, travelPermitId) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/accounts/${identityId}/travel-permits/${travelPermitId}/submit`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] - Approve travel permit
     * @param {string} teamId Team Id
     * @param {string} travelPermitId Travel permit Id
     * @returns {Promise}
     */
    approveTravelPermit = (teamId, travelPermitId) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/teams/${teamId}/travel-permits/${travelPermitId}/approve`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] - Decline travel permit
     * @param {string} teamId Team Id
     * @param {string} travelPermitId Travel permit Id
     * @returns {Promise}
     */
    declineTravelPermit = (teamId, travelPermitId) => {
        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/teams/${teamId}/travel-permits/${travelPermitId}/decline`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Delete specific travel permit
    * @param {string} team_id
    *  @param {string} travel_permit_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3AdeleteTeamContacttypeDetail|documentation}
    * @returns {Promise}
    */
    deleteTravelPermit = (team_id, travel_permit_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/teams/${team_id}/travel-permits/${travel_permit_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Get organization travel permit statuses
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Contact%20Types/Apicontroller%5CTeams%5CTeamcontacttypes%3A%3Aindex|documentation}
     * @returns {Promise.<Array>}
     */
    getOrgTravelPermitStatuses = () => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/travel-permit-status`,
            query: { organisation_id: this.props.OrganizationContext.organisation_id },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.travel_permit_status;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the downloadable link for the attachment
     * @param {string} travelPermitId
     * @param {string} travelPermitAttachmentId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Travel%20Permit%20Attachments/0ef80ff6e534b0915965e6501d79980a|documentation}
     * @returns {Promise.<string>}
     */
    downloadTravelPermitAttachment = (travelPermitId, travelPermitAttachmentId) => {
        return API_SPORDLE.get(`/travel-permits/${travelPermitId}/attachments/${travelPermitAttachmentId}`)
            .then((response) => {
                if(response.data.status){
                    return {
                        full_path: response.data.full_path,
                        preview_full_path: response.data.preview_full_path,
                        original_file_mime_type: response.data.original_file_mime_type,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create a travel permit attachment
     * @param {string} travelPermitId
     * @param {Array} attachments
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Travel%20Permit%20Attachments/b492f1fe291d6b90e88242b35e1765c7|documentation}
     * @returns {Promise.<Array>}
     */
    createTravelPermitAttachment = (travelPermitId, attachments, documentTypeId) => {
        const params = new FormData();
        attachments.forEach((file, index) => {
            params.append(`attachments[${index}][attachment]`, file.attachment);
            params.append(`attachments[${index}][active]`, file.active);
            if(documentTypeId)
                params.append(`attachments[${index}][document_type_id]`, documentTypeId);
        })

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/travel-permits/${travelPermitId}/attachments` }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.travel_permit_attachment_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete a travel permit attachment
     * @param {string} travelPermitId
     * @param {string} travelPermitAttachmentId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Travel%20Permit%20Attachments/b9f4e179637580a68ae9c597327037aa|documentation}
     * @returns {Promise.<Array>}
     */
    deleteTravelPermitAttachment = (travelPermitId, travelPermitAttachmentId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/travel-permits/${travelPermitId}/attachments/${travelPermitAttachmentId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    // Team Comments
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * GET[ALL] - Get team comments
     * @param {string} team_id
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Comments/d012b3edbaaa825438ab479dfc89c82d|documentation}
     * @returns {Promise.<Array>}
     */
    getTeamComments = (team_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${team_id}/comments`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_comments;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a team comment
     * @param {string} team_id ID of the team
     * @param {Object} values Values - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Comments/d2d4fa3c80cbffe2bb9c0c7236967e8c|documentation}
     * @returns {Promise<string>}
     */
    createTeamComment = (team_id, values) => {
        const params = new URLSearchParams({
            comment: values.comment,
            // team_status: values.team_status,
        });

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/teams/${team_id}/comments` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.team_comment_id;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * DELETE - Deletes a team comment
     * @param {string} teamId
     * @param {string} team_comment_id
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Comments/5f68211041f124f46b374879ae33b6d0|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteTeamComment = (teamId, team_comment_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `teams/${teamId}/comments/${team_comment_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Get team member comments
     * @param {string} team_id
     * @param {string} team_member_id
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Member%20Comments/848eada45011214115de7dc79f2f077b|documentation}
     * @returns {Promise.<Array>}
     */
    getTeamMemberComments = (team_id, team_member_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${team_id}/members/${team_member_id}/comments`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_member_comments;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * POST - Create a team member comment
     * @param {string} team_id
     * @param {string} team_member_id
     * @param {Object} values
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Member%20Comments/981593835bffcfacdcbffa7f5fd14249|documentation}
     * @returns {Promise.<string>}
     */
    createTeamMemberComment = (team_id, team_member_id, values) => {
        const params = new URLSearchParams({
            comment: values.comment,
        });

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/teams/${team_id}/members/${team_member_id}/comments` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.team_member_comment_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * DELETE - delete a team member comment
     * @param {string} team_id
     * @param {string} team_member_id
     * @param {string} team_member_comment_id
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Member%20Comments/8ace9bec84dca31b5b998213489fe3d4|documentation}
     * @returns {Promise.<string>}
     */
    deleteTeamMemberComment = (team_id, team_member_id, team_member_comment_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/teams/${team_id}/members/${team_member_id}/comments/${team_member_comment_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    // Team Categories
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * GET[ALL] - Gets all Team Categories
     * @param {object} [queryParams] The query params for that call
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Team%20Category%20Types/Apicontroller%5CTeams%5CTeamcategorytypes%3A%3AgetAllTeamCategoryType|documentation}
     * @returns {Promise.<Array>}
     */
    getTeamCategories = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/team-categories`,
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_categories;
                }
                throw response.data.errors[0];
            }, serverError)
    }
    ////////////////////////////////////////////////////////////////////////////////////
    // Team Settings
    ////////////////////////////////////////////////////////////////////////////////////

    _formatTeamSettings = (teamsSettings) => {
        return teamsSettings.reduce((newArray, teamSetting) => {
            let value = '';
            let initialValue = '';
            const options = teamSetting.setting?.response ? JSON.parse(teamSetting.setting.response) : null;
            const teamSettingRequirement = teamSetting.team_setting_requirement;
            const isBool = teamSettingRequirement.value === "yes" || teamSettingRequirement.value === "no"
            if(isBool){ // yes / no
                if(teamSettingRequirement.value === "yes"){
                    value = <i className='mdi mdi-check text-primary' />
                    initialValue = true;
                }else{
                    value = <i className='mdi mdi-close text-danger' />
                    initialValue = false;
                }
            }else if(teamSetting.is_qualification_rule == 1){ // qualification
                value = <DisplayI18n field='name' defaultValue={teamSettingRequirement.qualification.name} i18n={teamSettingRequirement.qualification.i18n} />
                initialValue = teamSettingRequirement.qualification.qualification_id;
            }else if(teamSetting.setting?.code === 'team_fee'){ // price
                value = <CurrencyFormat value={teamSettingRequirement.value} />
                initialValue = teamSettingRequirement.value;
            }else if(options){
                value = <DisplayI18n field='name' defaultValue={teamSettingRequirement.value} i18n={options[teamSettingRequirement.value]?.i18n} />
                initialValue = teamSettingRequirement.value;
            }else{ // text / number
                value = teamSettingRequirement.value;
                initialValue = teamSettingRequirement.value;
            }

            newArray.push({
                name: teamSetting.is_qualification_rule == 1 ? <Translate id='settings.teamsSettings.qualification' /> : <DisplayI18n field='name' defaultValue={teamSetting.setting.name} i18n={teamSetting.setting.i18n} />,
                value: value,
                options: options,
                enforced: teamSetting.enforced == 1 ? <i className="mdi mdi-check text-primary" /> : <i className='mdi mdi-close text-danger' />,
                setting: teamSetting,
                teamSettingId: teamSetting.team_setting_id,
                type: teamSetting.setting?.type || 'QUALIFICATION',
                isBool: isBool,
                initialValue: initialValue,
            })
            return newArray
        }, [])
    }

    /**
     * GET[ALL] - Get teams settings
     * @param {string} teamId
     * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Teams/ab73152039490ed043d658c8598af845 documentation}
     * @returns {Promise<Array>}
     */
    getTeamSettings = (teamId) => {
        return API_SPORDLE.get(`/teams/${teamId}/team-settings`)
            .then((response) => {
                if(response.data.status){
                    return response.data.team_settings;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Get teams settings
     * @param {Object} queryParams Query params - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Settings/4f7242ed8009c4d77f59d012b70dc59c documentation}
     * @param {boolean} [format] If the returned data should be formatted or not
     * @returns {Promise<Array>}
     */
    getTeamsSettings = (queryParams = {}, format = true) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/team-settings',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            }, queryParams),
        }))
            .then((response) => {
                if(response.data.status){
                    return format ? this._formatTeamSettings(response.data.teams) : response.data.teams;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * DELETE - Delete a team setting by it's ID
     * @param {Object} queryParams Query params - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Settings/4f7242ed8009c4d77f59d012b70dc59c documentation}
     * @returns {Promise<Array>}
     */
    deleteTeamSettings = (values) => {
        const params = new URLSearchParams();

        params.append('organisation_id', values.organisation_id);
        params.append('period_id', values.period_id);

        // May only contain a team_category_id or division_id
        if(values.team_category_id)
            params.append('team_category_id', values.team_category_id);
        if(values.division_id)
            params.append('division_id', values.division_id);

        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/team-settings` }), { data: params })
            .then((response) => {
                if(response.data.status){
                    return response;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getTeamsSettingsRestrictions = () => {
        if(this.state.restrictions){
            return Promise.resolve(this.state.restrictions);
        }
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/team-settings/list`,
        }))
            .then((response) => {
                if(response.data.status){
                    this.setState(() => ({
                        restrictions: response.data.settings,
                    }))
                    return response.data.settings
                }
                throw response.data.errors[0];
            }, serverError)

    }

    /**
     * PUT - Updates the teams settings
     * @param {Array} teamsSettings Array of the teams settings to update
     * @param {Array} idsToDelete Array of the setting IDs to delete
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Settings/1f9ccc2f61a8aa6b0f63cf8be76680de|documentation}
     * @returns {Promise<boolean>}
     */
    updateTeamsSettings = (teamsSettings, idsToDelete) => {
        const params = new URLSearchParams();
        jsObjectToApi({ team_settings: teamsSettings, settings_to_delete: idsToDelete.join(',') }, params, {
            skipNull: false,
        });

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: '/team-settings',
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET - Gets team status modification logs
     * @param {Array} teamsSettings Array of the teams settings to update
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Settings/1f9ccc2f61a8aa6b0f63cf8be76680de|documentation}
     * @returns {Promise<boolean>}
     */
    getTeamsStatusLogs = (team_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/teams/${team_id}/status-history`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.team_status_logs
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET - Gets organization tree for team settings
     * @param {string} organisation_id Organization id to get the tree from
     * @param {string} period_id Period id
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Settings/6d49f860267e594d0ddbcbb7b56337b9|documentation}
     * @returns {Promise<Array>}
     */
    getTeamSettingsTree = (organisation_id, period_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/team-settings/tree`,
            query: {
                organisation_id: organisation_id,
                period_id: period_id,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisations
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Change a teams organization
    * @param {string} team_id
    * @param {string} organizationId
    * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Team%20Members/0caa19ac4a6615b4d5e7a99634025148 documentation}
    * @returns {Promise}
    */
    transferTeamOrganization = (team_id, organizationId) => {
        const params = new URLSearchParams();
        params.append('organisation_id', organizationId);

        return API_SPORDLE.patch(`teams/${team_id}/transfer`, params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <TeamsContext.Provider value={{ ...this, ...this.state }}>
                {this.props.children}
            </TeamsContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext, PeriodsContext)(TeamsContextProvider)