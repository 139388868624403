import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { Button, Collapse } from 'reactstrap';
import useSWR from 'swr';
import { array, mixed, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import CustomAlert from '../../../components/CustomAlert';
import OverlayLoader from '../../../components/loading/OverlayLoader';
import { fail, success } from '@spordle/toasts';
import { PeriodsContext } from '../../../contexts/contexts';
import { TeamsContext } from '../../../contexts/TeamsContext';
import generatePassword from '../../../helpers/passwordGenerator';
import DisplayHeaderCard from './DisplayHeaderCard';
import DisplayQualifications from './DisplayQualifications';
import DisplayRestrictions from './DisplayRestrictions';
import { sortRestrictionKeys } from './helpers';
import DisplayOrgNameCard from './DisplayOrgNameCard';
import { displayI18n, DisplayI18n } from '../../../helpers/i18nHelper';
import { I18nContext } from '../../../contexts/I18nContext';
import moment from 'moment';

const TeamsSettingsForm = ({ teamSettings, duplicate, organization }) => {
    const teamsContext = useContext(TeamsContext);
    const periodsContext = useContext(PeriodsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const history = useHistory();
    const createMode = !teamSettings;

    let initialValues = null; // can't use a state here because this variable is affected in a function called in the render

    const { data: settings, error: settingsError, isValidating: settingsIsValidating } = useSWR(
        [ 'getTeamsSettingsRestrictions' ],
        () => teamsContext.getTeamsSettingsRestrictions()
            .then((settings) => {
                return settings.reduce((newArray, setting) => {
                    if(setting.active == 1){
                        newArray.push({
                            ...setting,
                            options: JSON.parse(setting.response),
                            setting_group_id: setting.setting_group?.setting_group_id || '',
                        })
                    }
                    return newArray
                }, []).sort((settingA, settingB) => sortRestrictionKeys(settingA.code, settingB.code))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        ,
        {
            fallbackData: [],
            revalidateOnMount: true,
        },
    )

    const getInitialValues = (settings) => {
        const temp = {};

        temp.teamCategoryDivision = [
            {
                id: generatePassword(),
                teamCategoryId: createMode || duplicate ? [] : [ teamSettings[0].setting.team_setting_category.team_category?.team_category_id ],
                divisionId: createMode || duplicate ? '' : teamSettings[0].setting.team_setting_category.division?.division_id,
            },
        ];

        for(let i = 0; i < settings.length; i++){
            const setting = settings[i];
            const teamSetting = teamSettings?.find((teamSetting) => teamSetting.setting.setting?.code === setting.code)
            switch (setting.type){
                case 'TEXT':
                case 'RADIO':
                case 'DATETIME':
                    let initialValue = teamSetting?.initialValue
                    if(initialValue === true || initialValue === false){
                        if(initialValue){
                            initialValue = "yes";
                        }else{
                            initialValue = "no";
                        }
                    }
                    temp[setting.code] = {
                        teamSettingId: createMode || duplicate ? '' : teamSetting?.teamSettingId,
                        value: createMode ? '' : setting.code === 'team_fee' ? initialValue / 100 : (setting.type === 'DATETIME' && initialValue) ? moment(initialValue, 'YYYY-MM-DD HH:mm:ss') : initialValue,
                        enforced: createMode ? false : teamSetting?.setting.enforced == 1,
                        active: createMode ? true : teamSetting?.setting.active == 1,
                        checked: !!initialValue,
                    };
                    break;
                case 'MULTIPLE':
                    temp[setting.code] = {
                        teamSettingId: createMode || duplicate ? '' : teamSetting?.teamSettingId,
                        value: createMode ? [] : teamSetting?.initialValue?.split(',') || [],
                        enforced: createMode ? false : teamSetting?.setting.enforced == 1,
                        active: createMode ? true : teamSetting?.setting.active == 1,
                        checked: !!teamSetting?.initialValue,
                    };
                    break;
            }
        }

        const onlyQualificationsSettings = teamSettings?.filter((teamSetting) => teamSetting.setting.is_qualification_rule == 1).map((t) => ({
            teamSettingId: createMode || duplicate ? '' : t.teamSettingId,
            positionId: t.setting.position.position_id,
            qualificationId: t.setting.team_setting_requirement.qualification.qualification_id,
            enforced: t.setting.enforced == 1,
            mandatory: t.setting.applies_to?.toLowerCase(),
            equivalences: t.setting.team_setting_qualification_equivalence,
        })) || []

        const isValidForGroup = (group, settingToAdd) => {
            // both the position and the enforced should be the same for every element in a group, so we only need to check [0]
            const positionValid = settingToAdd.positionId === group.positionId;
            const enforcedValid = settingToAdd.enforced === group.enforced;
            const mandatoryValid = settingToAdd.mandatory === group.mandatory;
            const noEquivalences = !settingToAdd.equivalences || settingToAdd.equivalences.length === 0

            return positionValid && enforcedValid && noEquivalences && mandatoryValid
        }

        const formattedQualifications = onlyQualificationsSettings.reduce((newArray, setting) => {
            let isAdded = false;

            // check if the setting if valid for a group
            newArray.forEach((group) => {
                if(isValidForGroup(group, setting)){
                    isAdded = true;
                    group.values.push({
                        teamSettingId: setting.teamSettingId,
                        qualificationId: setting.qualificationId,
                    })
                }
            })

            if(!isAdded){
                if(setting.equivalences && setting.equivalences.length > 0){
                    newArray.push({
                        id: generatePassword(),
                        positionId: setting.positionId,
                        enforced: setting.enforced,
                        mandatory: 'one',
                        values: [
                            {
                                teamSettingId: setting.teamSettingId,
                                qualificationId: setting.qualificationId,
                            },
                            ...setting.equivalences.map((eq) => ({
                                teamSettingId: '',
                                qualificationId: eq.qualification.qualification_id,
                            })),
                        ],
                    })
                }else{
                    newArray.push({
                        id: generatePassword(),
                        positionId: setting.positionId,
                        enforced: setting.enforced,
                        mandatory: 'all',
                        values: [ {
                            teamSettingId: setting.teamSettingId,
                            qualificationId: setting.qualificationId,
                        } ],
                    })
                }
            }

            return newArray
        }, [])

        temp.qualifications = createMode ? [] : formattedQualifications;

        initialValues = temp;
        return temp
    }

    const getValidationSchema = (settings) => {
        const temp = {}

        temp.teamCategoryDivision = array().min(1, <Translate id='settings.teamsSettings.categoryDivision.required' />).of(object().shape({
            divisionId: string().test({
                name: 'divisionOrCategoryTest',
                message: <Translate id='settings.teamsSettings.division.required' />,
                test: function(divisionId){
                    return this.parent.teamCategoryId.length > 0 || !!divisionId
                },
            }),
            teamCategoryId: array(),
        }))

        settings.forEach((setting) => {
            switch (setting.type){
                case 'TEXT':
                case 'RADIO':
                    temp[setting.code] = object().shape({
                        value: string(), //.required(<Translate id='settings.teamsSettings.required'/>)
                    });
                    break;
                case 'MULTIPLE':
                    temp[setting.code] = object().shape({
                        value: array(), //.min(1, <Translate id='settings.teamsSettings.required'/>)
                    });
                    break;
                case 'DATETIME':
                    temp[setting.code] = object().shape({
                        value: mixed().test({
                            name: 'requiredTest',
                            message: <Translate id='settings.teamsSettings.required' />,
                            test: function(value){
                                if(this.parent.checked){
                                    return moment.isMoment(value)
                                }
                                return true
                            },
                        }),
                    })
                    break;
            }
        })
        temp.qualifications = array().of(object().shape({
            values: array().min(1, <Translate id='settings.teamsSettings.qualification.required' />)
                .test({
                    name: 'atLeastTwoWhenOneMandatory',
                    message: <Translate id='settings.teamsSettings.qualification.required.one' />,
                    test: function(values){
                        if(this.parent.mandatory === 'one'){
                            return values.length > 1
                        }
                        return true
                    },
                }),
            positionId: string().required(<Translate id='settings.teamsSettings.position.required' />),
        }))
        return object().shape(temp)
    }

    const findQualificationsToDelete = (values) => {
        return initialValues.qualifications.reduce((idsToDelete, initialGroup) => {
            for(let initialGroupIndex = 0; initialGroupIndex < initialGroup.values.length; initialGroupIndex++){
                const qual = initialGroup.values[initialGroupIndex];

                if(qual.teamSettingId){
                    let found = false;

                    for(let i = 0; i < values.qualifications.length; i++){
                        const group = values.qualifications[i];
                        for(let j = 0; j < group.values.length; j++){
                            const qualification = group.values[j];
                            if(!(group.mandatory === 'one' && j !== 0)){
                                if(qualification.teamSettingId === qual.teamSettingId)
                                    found = true
                            }
                        }
                    }

                    if(!found){
                        idsToDelete.push(qual.teamSettingId)
                    }
                }
            }
            return idsToDelete
        }, [])
    }

    const formatQualifications = (values) => {
        return values.qualifications.reduce((newArray, group) => {
            if(group.mandatory === 'one'){
                const newObj = {
                    organisation_id: organization.organisation_id,
                    period_id: periodsContext.selectedPeriod.period_id,
                    is_qualification_rule: true,
                    position_id: group.positionId,
                    qualification_id: group.values[0].qualificationId,
                    qualification_equivalence: group.values.filter((qual, index) => index !== 0).map((qual) => qual.qualificationId).join(),
                    enforced: group.enforced,
                    active: true,
                }

                if(group.values[0].teamSettingId)
                    newObj.team_setting_id = group.values[0].teamSettingId

                if(values.teamCategoryId)
                    newObj.team_category_id = values.teamCategoryId;
                else if(values.divisionId)
                    newObj.division_id = values.divisionId;

                newArray.push(newObj);
            }else{
                group.values.forEach((qual) => {
                    const newObj = {
                        organisation_id: organization.organisation_id,
                        period_id: periodsContext.selectedPeriod.period_id,
                        is_qualification_rule: true,
                        position_id: group.positionId,
                        qualification_id: qual.qualificationId,
                        enforced: group.enforced,
                        active: true,
                    }

                    if(qual.teamSettingId)
                        newObj.team_setting_id = qual.teamSettingId

                    if(values.teamCategoryId)
                        newObj.team_category_id = values.teamCategoryId;
                    else if(values.divisionId)
                        newObj.division_id = values.divisionId;

                    newArray.push(newObj)
                })
            }

            return newArray;
        }, [])
    }

    const findRestrictionsToDelete = (values) => {
        return Object.keys(values).reduce((idsToDelete, key) => {
            if(key !== 'qualifications' && key !== 'teamCategoryDivision'){
                const setting = values[key]
                if(setting.teamSettingId && !setting.checked)
                    idsToDelete.push(setting.teamSettingId)
            }
            return idsToDelete
        }, [])
    }

    const formatRestrictions = (values) => {
        return Object.keys(values).reduce((newArray, key) => {
            if(key !== 'qualifications' && key !== 'teamCategoryId' && key !== 'divisionId'){
                const setting = values[key]

                if(setting.checked){
                    const newObj = {
                        // team_setting_id: setting.teamSettingId,
                        organisation_id: organization.organisation_id,
                        period_id: periodsContext.selectedPeriod.period_id,
                        setting_code: key,
                        // team_category_id: values.teamCategoryId || null,
                        // division_id: values.teamCategoryId ? null : values.divisionId || null,
                        is_qualification_rule: false,
                        // value: key === 'team_fee' ? Math.round(setting.value * 100) : (Array.isArray(setting.value) && setting.value.length > 0) ? setting.value.join(',') : (Array.isArray(setting.value) && setting.value.length === 0) ? '' : setting.value,
                        enforced: setting.enforced,
                        active: setting.active,
                    }

                    if(setting.teamSettingId)
                        newObj.team_setting_id = setting.teamSettingId

                    if(values.teamCategoryId)
                        newObj.team_category_id = values.teamCategoryId;
                    else if(values.divisionId)
                        newObj.division_id = values.divisionId;

                    if(key === 'team_fee'){
                        newObj.value = Math.round(setting.value * 100)
                    }else if(Array.isArray(setting.value) && setting.value.length > 0){
                        newObj.value = setting.value.join(',')
                    }else if(Array.isArray(setting.value) && setting.value.length === 0){
                        newObj.value = ''
                    }else if(moment.isMoment(setting.value)){
                        newObj.value = setting.value.format('YYYY-MM-DD HH:mm:ss');
                    }else{
                        newObj.value = setting.value
                    }

                    newArray.push(newObj)
                }
            }
            return newArray
        }, [])
    }

    return (
        <Formik
            key={settingsIsValidating} // because we need the settings for the initial values & validation schema
            initialValues={getInitialValues(settings)}
            validationSchema={getValidationSchema(settings)}
            onSubmit={({ teamCategoryDivision, ...values }, { setStatus, setSubmitting }) => {
                let skipCall = false;

                const settingIdsToDelete = findQualificationsToDelete(values)
                settingIdsToDelete.pushArray(findRestrictionsToDelete(values))

                const formattedRestrictions = [];
                for(let index = 0; index < teamCategoryDivision.length; index++){
                    const categoryDivision = { ...teamCategoryDivision[index] }; // Spreading because we want to create a new object
                    delete categoryDivision.id; // Deleting a value on the copied object not on the formik's object

                    const atLeastOneSetting = Object.keys(values).some((key) => {
                        const setting = values[key];
                        if(key === 'qualifications'){
                            return setting.length > 0;
                        }
                        return setting.checked

                    })
                    if(atLeastOneSetting){
                        if(categoryDivision.teamCategoryId.length === 0){ // no category selected, only division
                            const newValue = {
                                ...values,
                                divisionId: categoryDivision.divisionId,
                                teamCategoryId: '',
                            }
                            formattedRestrictions.pushArray(formatQualifications(newValue));
                            formattedRestrictions.pushArray(formatRestrictions(newValue));
                        }else{
                            for(let teamCategoryIndex = 0; teamCategoryIndex < categoryDivision.teamCategoryId.length; teamCategoryIndex++){
                                const teamCategory = categoryDivision.teamCategoryId[teamCategoryIndex];
                                const newValue = {
                                    ...values,
                                    divisionId: categoryDivision.divisionId,
                                    teamCategoryId: teamCategory,
                                }
                                formattedRestrictions.pushArray(formatQualifications(newValue));
                                formattedRestrictions.pushArray(formatRestrictions(newValue));
                            }
                        }
                    }else{
                        skipCall = true;
                        setStatus(
                            <>
                                <div><Translate id='settings.teamsSettings.list.none' /></div>
                                <Translate id='settings.teamsSettings.list.none2' />
                            </>,
                        )
                        setSubmitting(false)
                    }
                }

                if(!skipCall){
                    setStatus()
                    return teamsContext.updateTeamsSettings(formattedRestrictions, settingIdsToDelete)
                        .then(() => {
                            success();
                            history.push({ pathname: '/settings/teams/restrictions', orgId: organization.organisation_id });
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                if(error.i18n){
                                    setStatus(displayI18n('message', error.i18n, error.message, getGenericLocale()));
                                }else{
                                    switch (error.message){
                                        case '3366':
                                            setStatus(<Translate id='settings.teamsSettings.errors.3366' />);
                                            break;
                                        default:
                                            setStatus(<Translate id='misc.error' />);
                                            break;
                                    }
                                }

                            }
                        })
                }
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <DisplayOrgNameCard createMode={createMode} duplicate={duplicate} teamSettings={teamSettings} organization={organization} />

                        {(createMode || duplicate) &&
                            <DisplayHeaderCard createMode={createMode} duplicate={duplicate} />
                        }

                        <DisplayRestrictions settings={settings} settingsError={settingsError} settingsIsValidating={settingsIsValidating} />

                        <DisplayQualifications />

                        <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                            {formik.status &&
                                <CustomAlert color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus(null)} />
                            }
                        </Collapse>

                        <div className='d-flex justify-content-end position-relative mb-phat'>
                            <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id={createMode || duplicate ? 'misc.create' : 'misc.save'} /></Button>
                        </div>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default TeamsSettingsForm;