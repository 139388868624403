import { SpordleTableContext } from '@spordle/datatables';
import { FormikCheckedButton, FormikDateTime, FormikGroup, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { formatSelectData } from '@spordle/spordle-select';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Collapse, Fade, FormGroup, Label, Row } from 'reactstrap';
import { AxiosCancelClinicsCalls, AxiosIsCancelled } from '../../../api/CancellableAPI';
import OrganizationSearch from '../../../components/organization/OrganizationSearch';
import { ClinicsContext } from '../../../contexts/ClinicsContext';
import { PeriodsContext } from '../../../contexts/contexts';
import { I18nContext } from '../../../contexts/I18nContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { QualificationCategoriesContext } from '../../../contexts/QualificationCategoriesContext';
import { QualificationsContext } from '../../../contexts/QualificationsContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import ClinicCategory from '../ClinicCategory';
import { fail } from "@spordle/toasts";
import useSavedSearch from '../../../components/customHooks/useSavedSearch';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import SavedSearchCue from '../../../components/savedSearchCue/SavedSearchCue';

const AdvancedSearchSection = (props) => {
    // Contexts
    const spordleTable = useContext(SpordleTableContext);
    const organizationContext = useContext(OrganizationContext);
    const qualificationContext = useContext(QualificationsContext);
    const qualificationCategoriesContext = useContext(QualificationCategoriesContext);
    const periodContext = useContext(PeriodsContext);
    const i18nContext = useContext(I18nContext);
    const clinicsContext = useContext(ClinicsContext);
    const identityRolesContext = useContext(IdentityRolesContext);

    //States
    const { savedSearch, clearSavedSearch, saveSearch } = useSavedSearch(`clinics-search-filters-${organizationContext.organisation_id}-${identityRolesContext.identity_role_id}-${periodContext.selectedPeriod.period_id}`);

    const [ formikKey, setFormikKey ] = useState(0); // This is used to force a rerender of formik to empty FormikDateTime fields
    const [ moreFiltersOpen, setMoreFiltersOpen ] = useState(savedSearch?.moreFiltersOpen || false);

    /**  @type {React.MutableRefObject<SpordleSelect>} */
    const qualificationSelect = useRef(null);

    // This is used to force a rerender of formik to empty FormikDateTime fields
    const rerenderFormik = () => {
        clearSavedSearch();
        setFormikKey(formikKey + 1);
    }

    const formikRef = useRef();
    const originalValues = useRef({
        organization: [ organizationContext.organisation_id ],
        category: [],
        qualification: [],
        level: [],
        sessionFormat: [], // Checkboxes give arrays
        status: [], // Multi select
        startDate: '',
        endDate: '',
        onlyHosted: true,
        moreFiltersOpen: moreFiltersOpen,
    });

    const [ clinicStates, setClinicStates ] = useState([]);
    useEffect(() => {
        clinicsContext.getClinicStates().then((states) => {
            setClinicStates(states);
            originalValues.current.status = getInitStatesFilter(states)
            formikRef.current?.submitForm();
        }).catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            }
        })
    }, [])

    const toggleMoreFilters = () => {
        const newOpen = !moreFiltersOpen;
        setMoreFiltersOpen(newOpen);
        saveSearch({ ...formikRef.current?.values, moreFiltersOpen: newOpen });
    }

    const getInitFilters = () => {
        const clinicFilters = savedSearch;
        if(clinicFilters){
            return {
                ...originalValues.current,
                ...clinicFilters,
                startDate: !clinicFilters.startDate ? '' : moment(clinicFilters.startDate),
                endDate: !clinicFilters.endDate ? '' : moment(clinicFilters.endDate),
            };
        }

        return originalValues.current;
    }

    const getInitStatesFilter = (states) => {
        return states?.filter((state) => (state.state_code !== 'CLOSE' && state.state_code !== 'CANCEL'))?.map((state) => (state.clinic_state_id));
    }

    useEffect(() => {
        qualificationSelect.current?.getSpordleTable().filterChange('periodId', periodContext.selectedPeriod.period_id)
    }, [ periodContext.selectedPeriod.period_id ])

    return (
        <Formik
            key={formikKey}
            innerRef={formikRef}
            initialValues={getInitFilters()}
            onSubmit={(values) => {
                AxiosCancelClinicsCalls();
                if(values.status.length <= 0){
                    values.status = getInitStatesFilter(clinicStates)//clinicStates?.filter((state) => (state.state_code !== 'CLOSE' && state.state_code !== 'CANCEL'))?.map((state) => (state.clinic_state_id));
                }
                spordleTable.filterChange('advancedSearch', { ...values, moreFiltersOpen: moreFiltersOpen });
                saveSearch({ ...values, moreFiltersOpen: moreFiltersOpen });

                // This will open the "more filters" collapse when we have something saved that is different then the saved search
                if(JSON.stringify(originalValues.current) !== JSON.stringify(values))
                    setMoreFiltersOpen(true)
            }}
        >
            {(formik) => (
                <Form>
                    <Row form>
                        <Col md='6'>
                            <FormGroup>
                                <Label for="organization" className="text-muted"><Translate id='clinics.search.advancedSearch.organization.label' /></Label>
                                <OrganizationSearch
                                    name='organization' id='organization'
                                    multi fromIdentityRole withFormik
                                    fromApi
                                    selectedDefault={formik.initialValues.organization}
                                    onMenuClose={() => {
                                        // Using onMenuClose instead of onChange to prevent excessive api calls
                                        // This is more or less the equivalent of onBlur, but better
                                        qualificationSelect.current?.getSpordleTable().filterChange('orgIds', formik.values.organization)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='4'>
                            <FormGroup>
                                <Label for="status" className="text-muted"><Translate id='clinics.search.advancedSearch.status.label' /></Label>
                                <FormikSelect
                                    multi search={false} clearable
                                    id='ClinicSearchStatusSelect' name='status'
                                    textWhenSetting={{
                                        count: 1,
                                    }}
                                    loadingStatus='loading'
                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                    options={clinicStates?.map((state) => ({
                                        value: state.clinic_state_id,
                                        label: state.name,
                                        i18n: state.i18n,
                                        color: state.color,
                                    }))}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='2' className='d-flex align-items-end mb-3 order-last order-md-5'>
                            <Button onClick={() => { props.setActiveTab('results') }} color='primary' type='submit' className='w-100'><i className='fas fa-search mr-1' /><Translate id='misc.search' /></Button>
                        </Col>
                        {/* removed to allow the cancel call concept to work disabled={spordleTable.state.loadingState === 'loading' || spordleTable.state.loadingState === 'lazy'} */}
                        <Col className='order-md-last d-none'>
                            <FormikGroup name='onlyHosted' rowProps={{ className: 'mx-0' }}>
                                <FormGroup className='mb-md-0 mb-3'>
                                    <FormikCheckedButton name='onlyHosted' id='onlyHosted' label='clinics.search.advancedSearch.onlySelectedOrgs.label' />
                                </FormGroup>
                            </FormikGroup>
                        </Col>
                    </Row>

                    <Collapse isOpen={moreFiltersOpen}>
                        <Row className='mt-3'>
                            <Col md='4'>
                                <FormGroup>
                                    <Label for="category" className="text-muted"><Translate id='clinics.search.advancedSearch.category.label' /></Label>
                                    <FormikSelect
                                        name='category' id='ClinicAdvancedSearchCategorySelect'
                                        multi clearable
                                        textWhenSetting={{
                                            count: 1,
                                            label: 'clinics.search.advancedSearch.category.textCountLabel',
                                        }}
                                        placeholder='clinics.search.advancedSearch.select.placeholder.search'
                                        noOptionLayout={<Translate id='clinics.search.advancedSearch.category.noOptions' />}
                                        renderOption={(option) => (
                                            <ClinicCategory color={option.option.color}>
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={option.option.label}
                                                    i18n={option.option.i18n}
                                                />
                                            </ClinicCategory>
                                        )}
                                        loadData={(from, extra, spordleTable) => {
                                            switch (from){
                                                case 'CDM':
                                                    return qualificationCategoriesContext.getQualificationCategories()
                                                        .then((categories) => {
                                                            return categories.reduce((newArray, cat) => {
                                                                if(cat.active == 1){
                                                                    newArray.push({
                                                                        ...cat,
                                                                        value: cat.qualification_category_id,
                                                                        label: cat.name,
                                                                        color: isNaN(parseInt(cat.color)) ? 0 : cat.color,
                                                                    })
                                                                }
                                                                return newArray;
                                                            }, [])
                                                        })
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup>
                                    <Label for="qualification" className="text-muted"><Translate id='clinics.search.advancedSearch.qualification.label' /></Label>
                                    <FormikSelect
                                        name='qualification' id='ClinicAdvancedSearchQualificationSelect' ref={qualificationSelect}
                                        multi
                                        textWhenSetting={{
                                            count: 1,
                                            label: 'clinics.search.advancedSearch.qualification.textCountLabel',
                                        }}
                                        placeholder='clinics.search.advancedSearch.select.placeholder.search'
                                        noOptionLayout={<Translate id='clinics.search.advancedSearch.qualification.noOptions' />}
                                        renderOption={(option) => {
                                            return (
                                                <>
                                                    <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                                    {option.option.qualification_level &&
                                                        <>
                                                            <span className='mx-1'>-</span>
                                                            <DisplayI18n field='name' defaultValue={option.option.qualification_level.name} i18n={option.option.qualification_level.i18n} />
                                                        </>
                                                    }
                                                </>
                                            )
                                        }}
                                        searchKeys={[
                                            'label',
                                            `i18n.${i18nContext.getGenericLocale()}.name`,
                                            'qualification_level.name',
                                            `qualification_level.i18n.${i18nContext.getGenericLocale()}.name`,
                                        ]}
                                        initFilter={{
                                            orgIds: formik.values.organization,
                                            periodId: '',
                                        }}
                                        loadData={(from, extra, spordleTable) => {
                                            switch (from){
                                                case 'FILTER':
                                                    spordleTable.setLoading();
                                                case 'CDM':
                                                    if(extra.filters.orgIds?.length > 0 && extra.filters.periodId){
                                                        return qualificationContext.getQualifications({ organisation_id: extra.filters.orgIds, period_id: extra.filters.periodId })
                                                            .then(async(qualifications) => {
                                                                return formatSelectData(qualifications, {
                                                                    getLabel: (qual) => qual.isGroup() ? qual.label : qual.name,
                                                                    getValue: (qual) => qual.qualification_id,
                                                                    getGroupId: (qual) => qual.organisation.organisation_id,
                                                                    createGroupWithId: (qual, groupId) => ({
                                                                        i18n: qual.organisation.i18n,
                                                                    }),
                                                                })
                                                            })
                                                    }
                                                    return Promise.resolve([]);

                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md='2'>
                                <FormGroup>
                                    <Label for="startDate" className="text-muted"><Translate id='clinics.search.advancedSearch.startDate.label' /></Label>
                                    <FormikDateTime name='startDate' timeFormat={false} />
                                </FormGroup>
                            </Col>
                            <Col md='2'>
                                <FormGroup>
                                    <Label for="endDate" className="text-muted"><Translate id='clinics.search.advancedSearch.endDate.label' /></Label>
                                    <FormikDateTime name='endDate' timeFormat={false} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <div className="text-muted mb-2"><Translate id='clinics.search.advancedSearch.clinicFormat.label' /></div>
                                    <FormikGroup name='sessionFormat' rowProps={{ className: 'mx-0' }}>
                                        <FormikCheckedButton className="mr-4 mb-2" name='sessionFormat' id='sessionFormat-ONLINE' value='ONLINE' translateLabel={false} label={<><span className="mr-1"><Translate id='clinics.search.advancedSearch.clinicFormat.label.ONLINE' /></span><i className='mdi mdi-access-point' /></>} />
                                        <FormikCheckedButton className="mr-4 mb-2" name='sessionFormat' id='sessionFormat-ONLINE_LIVE' value='ONLINE_LIVE' translateLabel={false} label={<><span className="mr-1"><Translate id='clinics.search.advancedSearch.clinicFormat.label.ONLINE_LIVE' /></span><i className='mdi mdi-play' /></>} />
                                        <FormikCheckedButton className="mr-4 mb-2" name='sessionFormat' id='sessionFormat-ONSITE' value='ONSITE' translateLabel={false} label={<><span className="mr-1"><Translate id='clinics.search.advancedSearch.clinicFormat.label.ONSITE' /></span><i className='mdi mdi-map-marker' /></>} />
                                    </FormikGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Collapse>

                    <div className="w-100 d-flex flex-wrap flex-md-nowrap justify-content-end">
                        <SavedSearchCue
                            className="order-1 order-md-0 mr-auto w-100 w-md-auto pt-3 pt-md-0"
                            savedSearch={savedSearch}
                            clearSavedSearch={() => {
                                formik.setValues(originalValues.current);
                                rerenderFormik();
                            }}
                        />
                        <Fade in={JSON.stringify(originalValues.current) !== JSON.stringify(formik.values)}>
                            <button
                                type='button'
                                className='reset-btn ml-2 mr-3 text-danger font-12'
                                onClick={() => {
                                    formik.setValues(originalValues.current);
                                    rerenderFormik();
                                }}
                            >
                                <Translate id='members.search.memberAdvanceSearch.resetFilters' />
                            </button>
                        </Fade>
                        <button type='button' onClick={toggleMoreFilters} color='primary' className='reset-btn ml-2 text-link font-12'>
                            <Translate id={`clinics.search.advancedSearch.${moreFiltersOpen ? 'lessFilters' : 'moreFilters'}`} /><i className={`ml-1 mdi ${moreFiltersOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'}`} />
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default AdvancedSearchSection;