export default{
    // Filters, Select, Search, Table, etc.
    "misc.select.placeholder"                   : "Select...",
    "misc.select.max.toast"                     : "You can select a maximum of 100 options",
    "misc.search"                               : "Search",
    "misc.link"                                 : "Link",
    "misc.unlink"                               : "Unlink",
    "misc.SimpleSearchTable.search.placeholder" : "Search",
    "misc.noSelection"                          : "No selection possible",
    "misc.noResults"                            : "No results.",
    "misc.results"                              : "Result(s)",
    "misc.result"                               : "Result",
    "misc.countMore"                            : "{count} more",
    "misc.moreFilter"                           : "Show filters",
    "misc.view"                                 : "View",
    "misc.viewMore"                             : "View more",
    "misc.viewCountMore"                        : "View {count} more",
    "misc.viewLess"                             : "View less",
    "misc.lessFilter"                           : "Hide filters",
    "misc.noTranslation"                        : "No translation available",
    "misc.templateUi.basicSpordleTable"         : "Data {itemStartPos} to {itemEndPos} out of a total of {totalItems} data.",
    "misc.select"                               : "Select",
    "misc.alreadySelected"                      : "Already Selected",
    "misc.unselect"                             : "Unselect",
    "misc.select.valueContainer.plural"         : "{count} selected",
    "misc.selected.plural"                      : "{count}/{max} selected",
    "misc.select.none"                          : "None",
    "misc.select.all"                           : "Select All",
    "misc.select.suggested"                     : "Suggested",
    "misc.select.currentPage"                   : "Select Current Page",
    "misc.unselect.all"                         : "Unselect All",
    "misc.free"                                 : "Free",
    "misc.show"                                 : "Show",
    "misc.submit"                               : "Submit",
    "misc.continue"                             : "Continue",
    "misc.skip"                                 : "Skip",
    "misc.goToProfile"                          : "Go to profile",
    "misc.organization"                         : "Organization",
    "misc.member"                               : "Member",
    "misc.new"                                  : "New",
    "misc.deprecated"                           : "Deprecated",
    "misc.duplicate"                            : "Duplicate",

    "misc.pending"       : "Pending",
    "misc.inProgress"    : "In Progress",
    "misc.priceValue"    : "$ {price}",
    "misc.price"         : "Price",
    "misc.load"          : "Loading",
    "misc.loading"       : "Loading...",
    "misc.error"         : "An error occurred",
    "misc.oops"          : "Oops!",
    "misc.error.apology" : "We apologize for the inconvenience.",
    "misc.success"       : "Success",
    "misc.warning"       : "Warning",
    "misc.danger"        : "Error",
    "misc.info"          : "Info",
    "misc.created"       : "Created!",
    "misc.updated"       : "Updated!",
    "misc.deleted"       : "Deleted!",
    "misc.undefined"     : "Undefined",
    "misc.status"        : "Status",
    "misc.refresh"       : "Refresh",
    "misc.retry"         : "Retry",

    "misc.googlePlaces.open"                    : "Open in Google Maps",
    "misc.googlePlaces.googleMaps"              : "Google Maps",
    "misc.googlePlaces.placeholder"             : "Indicate a location",
    "misc.googlePlaces.manualAddress.toggleOn"  : "Enter address manually",
    "misc.googlePlaces.manualAddress.toggleOff" : "Indicate address with Google helper",

    "misc.quickview"                         : "Quick View",
    "misc.quickview.open"                    : "Open in Quick View",
    "misc.quickview.navigation.blocked"      : "Navigation Blocked",
    "misc.quickview.navigation.blocked.info" : "Navigation inside the quickview is restricted to the selected member profile.",
    "misc.quickview.allTabs"                 : "All tabs",
    "misc.quickview.collapseQuickView"       : "Minimize",
    "misc.quickview.closeAllTabs"            : "Close All Tabs",
    "misc.quickview.closeAllTabs.confirm"    : "Close all QuickView tabs?",

    "misc.comingSoon" : "Coming soon",

    "misc.maxMinAge" : "Min Age: {min} - Max Age: {max}",
    "misc.yearsOld"  : "{age} years old",
    "misc.ageRange"  : "{from} to {to} years old",
    "misc.from"      : "From",
    "misc.to"        : "To",

    "misc.daysAgo"  : "{days, plural, =0 {# day} one {# day} other {# days}} ago",
    "misc.hoursAgo" : "{hours, plural, =0 {# hour} one {# hour} other {# hours}} ago",

    "misc.fatalError.goback" : "Return to the platform",

    "misc.notFound"            : "Not Found",
    "misc.notSupported.title"  : "Browser not supported",
    "misc.notSupported.text.1" : "The version of this browser is not supported by our platform.",
    "misc.notSupported.text.2" : "Please check for updates or try another browser",

    "misc.invalid.date" : "Invalid date",

    "misc.apiPartner" : "API partner",

    "misc.copy.1" : "Copy",
    "misc.copy.2" : "Copied!",
    "misc.copy.3" : "Unable to copy",

    // Actions
    "misc.yes"                     : "Yes",
    "misc.no"                      : "No",
    "misc.yes.no.1"                : "Yes",
    "misc.yes.no.0"                : "No",
    "misc.yes.no.true"             : "Yes",
    "misc.yes.no.false"            : "No",
    "misc.download"                : "Download",
    "misc.prefer_not_to_say"       : "Prefer not to say",
    "misc.prefer_to_self_describe" : "Prefer to self-describe",
    "misc.noOptions"               : "No options",
    "misc.create.option"           : "Create option: {label}",
    "misc.noInfo"                  : "No Info",
    "misc.no_info"                 : "No Info",
    "misc.other"                   : "Other",
    "misc.ok"                      : "Ok",
    "misc.edit"                    : "Edit",
    "misc.save"                    : "Save",
    "misc.replace"                 : "Replace",
    "misc.actions"                 : "Actions",
    "misc.action"                  : "Action",
    "misc.back"                    : "Back",
    "misc.next"                    : "Next",
    "misc.previous"                : "Previous",
    "misc.cancel"                  : "Cancel",
    "misc.confirm"                 : "Confirm",
    "misc.close"                   : "Close",
    "misc.finish"                  : "Finish",
    "misc.export"                  : "Export",
    "misc.excel"                   : "Excel",
    "misc.pdf"                     : "PDF",
    "misc.print"                   : "Print",
    "misc.add"                     : "Add",
    "misc.addMultiple"             : "Multiple add",
    "misc.delete"                  : "Delete",
    "misc.remove"                  : "Remove",
    "misc.clear"                   : "Clear",
    "misc.create"                  : "Create",
    "misc.all"                     : "All",
    "misc.none"                    : "None",
    "misc.active"                  : "Active",
    "misc.inactive"                : "Inactive",
    "misc.disable"                 : "Disable",
    "misc.enable"                  : "Enable",
    "misc.optional"                : "Optional",
    "misc.mandatory"               : "Mandatory",
    "misc.critical"                : "Critical",
    "misc.status.1"                : "Active",
    "misc.status.0"                : "Inactive",
    "misc.goTo"                    : "Go to",
    "misc.copy"                    : "Copy",
    "misc.approve"                 : "Approve",
    "misc.decline"                 : "Decline",
    "misc.nonExistant"             : "*Missing Label*",

    "misc.minimum" : "Minimum: {value}",
    "misc.maximum" : "Maximum: {value}",

    // Lang
    "misc.en"                : "EN",
    "misc.en.text"           : "English",
    "misc.en.text.noCapital" : "english",
    "misc.en.long"           : "English",
    "misc.english"           : "English",
    "misc.fr"                : "FR",
    "misc.fr.text"           : "French",
    "misc.fr.text.noCapital" : "french",
    "misc.fr.long"           : "French",
    "misc.french"            : "French",

    "misc.current"   : "Current",
    "misc.bilingual" : "Bilingual",

    // Favorites
    "misc.favorite"    : "Favorite",
    "misc.favorited"   : "Favorited",
    "misc.unfavorited" : "Unfavorited",

    "misc.addedToFavorites"     : "Successfully added to favorites!",
    "misc.removedFromFavorites" : "Successfully removed from favorites!",

    // Search
    "misc.search.empty.title"         : "No results...",
    "misc.search.empty.message"       : "You can search or use the advanced search at the top of this page.",
    "misc.search.empty.message.short" : "You can use the search at the top of this page.",
    "misc.paginationMessage"          : "Rows {itemStartPos} to {itemEndPos} out of a total of {totalItems} rows.",
    "misc.select.all.tooltip"         : "Only the first 500 rows will be selected",

    // Clipboard helper
    "misc.clipboard.success"         : "Copied to clipboard!",
    "misc.clipboard.address.success" : "Address copied!",

    "misc.clipboard.error" : "Could not copy to clipboard",

    // select
    "misc.select.nbSelected" : "{count} selected",
    "misc.selected"          : "Selected",

    // file upload
    "misc.fileUpload.text.1"  : "Add document",
    "misc.fileUpload.text.2"  : "or drop it here",
    "misc.fileUpload.maxSize" : "Maximum upload file size",

    // wizard
    "misc.wizard.review.almostDone" : "You are almost done!",
    "misc.wizard.review.reviewInfo" : "You can review your information and do some last changes below.",

    "misc.baseData" : "Base information",
}