import { FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import {
    Col,
    FormGroup,
    Label, Row
} from "reactstrap";
import { object, mixed, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../../../api/CancellableAPI';
import FormikEditable from '../../../../../../../../components/formik/FormikEditable';
import OrganizationSearch from '../../../../../../../../components/organization/OrganizationSearch';
import { fail, success } from '@spordle/toasts';
import { I18nContext } from '../../../../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../../../helpers/i18nHelper';

const MemberProfileTransferSidepanelFinances = (props) => {
    const membersContext = useContext(MembersContext);
    const organizationContext = useContext(OrganizationContext);

    const [ orgCategories, setOrgCategories ] = useState([])

    useEffect(() => {
        props.setLoading(true);
        organizationContext.getOrganizationCategory(organizationContext.organisation_id)
            .then((categories) => {
                setOrgCategories(categories.filter((c) => c.default_name === 'Member partner' || c.default_name === 'Member branches').map((c) => c.category_id))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
            .finally(() => props.setLoading(false))
    }, [])

    return (
        <div className="p-3">
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Label for='teamReleaseFees' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.releaseFee.label' /></Label>
                        <FormikEditable
                            id='teamReleaseFees'
                            initialValues={{
                                teamReleaseFees: props.selectedRows[0].team_release_fee ? props.selectedRows[0].team_release_fee / 100 : '',
                            }}
                            validationSchema={object().shape({
                                teamReleaseFees: mixed(),
                            })}
                            onSubmit={(values) => {
                                const newFees = values.teamReleaseFees ? Math.round(values.teamReleaseFees * 100) : null
                                if(newFees !== props.selectedRows[0].team_release_fee){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ team_release_fee: newFees });
                                    membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                        team_release_fee: newFees,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                        .finally(() => props.setLoading(false))
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{props.selectedRows[0].team_release_fee ? <CurrencyFormat value={props.selectedRows[0].team_release_fee / 100} /> : '-'}</div>
                                    )
                                }
                                return (
                                    <I18nContext.Consumer>
                                        {({ getGenericLocale }) => (
                                            <FormikInputNumber
                                                autoFocus
                                                allowLeadingZeros
                                                fixedDecimalScale
                                                thousandSeparator=' '
                                                allowNegative={false}
                                                decimalScale={2}
                                                name="teamReleaseFees"
                                                id="teamReleaseFees"
                                                decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                suffix={getGenericLocale() === 'fr' ? ' $' : ''}
                                                prefix={getGenericLocale() !== 'fr' ? '$ ' : ''}
                                            />
                                        )}
                                    </I18nContext.Consumer>
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label for='teamNameReleaseFees' className='text-muted mb-0'><Translate id='members.profile.transfers.add.fields.releaseFeeTeam.label' /></Label>
                        <FormikEditable
                            id='teamNameReleaseFees'
                            initialValues={{
                                teamNameReleaseFees: props.selectedRows[0].release_fee_team_name || '',
                            }}
                            validationSchema={object().shape({
                                teamNameReleaseFees: string(),
                            })}
                            onSubmit={(values) => {
                                const newName = values.teamNameReleaseFees || null
                                if(newName !== props.selectedRows[0].release_fee_team_name){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ release_fee_team_name: newName });
                                    membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                        release_fee_team_name: newName,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                        .finally(() => props.setLoading(false))
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{props.selectedRows[0].release_fee_team_name || '-'}</div>
                                    )
                                }
                                return (
                                    <FormikInputText id='teamNameReleaseFees' name='teamNameReleaseFees' trim autoFocus />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label for='owes' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.owes' /></Label>
                        <FormikEditable
                            id='owes'
                            noConfirmation
                            initialValues={{
                                owes: props.selectedRows[0].owes ? props.selectedRows[0].owes / 100 : '',
                            }}
                            validationSchema={object().shape({
                                owes: mixed(),
                            })}
                            onSubmit={(values) => {
                                const newOwes = values.owes ? Math.round(values.owes * 100) : null
                                if(newOwes != props.selectedRows[0].owes){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ owes: newOwes });
                                    membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                        owes: newOwes,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                        .finally(() => props.setLoading(false))
                                }
                            }}
                        >
                            {(isEditing, options) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{props.selectedRows[0].owes ? <CurrencyFormat value={props.selectedRows[0].owes / 100} /> : '-'}</div>
                                    )
                                }
                                return (
                                    <FormikSelect
                                        name="owes"
                                        id="owes"
                                        search={false}
                                        renderOption={({ option }) => <CurrencyFormat value={option.value} />}
                                        onOptionSelected={options.stopEditing}
                                        menuIsDefaultOpen
                                        autoFocus
                                        loadingStatus='success'
                                        defaultData={[
                                            {
                                                value: 0,
                                                label: '0',
                                            },
                                            {
                                                value: 50,
                                                label: '50',
                                            },
                                            {
                                                value: 100,
                                                label: '100',
                                            },
                                            {
                                                value: 150,
                                                label: '150',
                                            },
                                        ]}
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label for='paid' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.paid' /></Label>
                        <FormikEditable
                            id='paid'
                            noConfirmation
                            initialValues={{
                                paid: props.selectedRows[0].paid ? props.selectedRows[0].paid / 100 : '',
                            }}
                            validationSchema={object().shape({
                                paid: mixed(),
                            })}
                            onSubmit={(values) => {
                                const newPaid = values.paid ? Math.round(values.paid * 100) : null
                                if(newPaid != props.selectedRows[0].paid){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ paid: newPaid });
                                    membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                        paid: newPaid,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                        .finally(() => props.setLoading(false))
                                }
                            }}
                        >
                            {(isEditing, options) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{props.selectedRows[0].paid ? <CurrencyFormat value={props.selectedRows[0].paid / 100} /> : '-'}</div>
                                    )
                                }
                                return (
                                    <FormikSelect
                                        name="paid"
                                        id="paid"
                                        search={false}
                                        renderOption={({ option }) => <CurrencyFormat value={option.value} />}
                                        onOptionSelected={options.stopEditing}
                                        menuIsDefaultOpen
                                        autoFocus
                                        loadingStatus='success'
                                        defaultData={[
                                            {
                                                value: 0,
                                                label: '0',
                                            },
                                            {
                                                value: 50,
                                                label: '50',
                                            },
                                            {
                                                value: 100,
                                                label: '100',
                                            },
                                            {
                                                value: 150,
                                                label: '150',
                                            },
                                        ]}
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup>
                        <Label for='client' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.clientOrganization' /></Label>
                        <FormikEditable
                            id='client'
                            initialValues={{
                                clientId: props.selectedRows[0].client_organisation?.organisation_id || '',
                                client: props.selectedRows[0].client_organisation || null,
                            }}
                            validationSchema={object().shape({
                                clientId: string(),
                                client: object().shape({
                                    abbreviation: string().nullable(),
                                    logo: object().nullable(),
                                    organisation_id: string(),
                                    organisation_name: string(),
                                }).nullable(),
                            })}
                            onSubmit={(values) => {
                                if(values.client?.organisation_id !== props.selectedRows[0].client_organisation?.organisation_id){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ client_organisation: values.client });
                                    membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                        client_organisation_id: values.clientId || null,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                        .finally(() => props.setLoading(false))
                                }
                            }}
                        >
                            {(isEditing, options, formik) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark text-truncate'>
                                            {props.selectedRows[0].client_organisation ?
                                                <DisplayI18n field='name' defaultValue={props.selectedRows[0].client_organisation.organisation_name} i18n={props.selectedRows[0].client_organisation.i18n} />
                                                : '-'}
                                        </div>
                                    )
                                }
                                return (
                                    <OrganizationSearch
                                        id="clientId" name="clientId" withFormik
                                        isClearable autoFocus categories={orgCategories}
                                        selectedDefault={formik.initialValues.clientId || ''}
                                        fromIdentityRole
                                        onConfirm={(org) => {
                                            return new Promise((resolve) => {
                                                if(org){
                                                    formik.setFieldValue('client', {
                                                        abbreviation: org.abbreviation,
                                                        logo: org.logo,
                                                        organisation_id: org.value,
                                                        organisation_name: org.label,
                                                    })
                                                }else{
                                                    formik.setFieldValue('client', null)
                                                }
                                                resolve(true)
                                            })
                                        }}
                                    />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label for='invoiceNo' className='text-muted mb-0'><Translate id='members.profile.transfers.sidepanel.finances.invoice' /></Label>
                        <FormikEditable
                            id='invoiceNo'
                            initialValues={{
                                invoiceNo: props.selectedRows[0].hc_invoice_number || '',
                            }}
                            validationSchema={object().shape({
                                invoiceNo: string(),
                            })}
                            onSubmit={(values) => {
                                const newInvoice = values.invoiceNo || null
                                if(newInvoice !== props.selectedRows[0].hc_invoice_number){
                                    props.setLoading(true)
                                    const newValues = props.createNewValues({ hc_invoice_number: newInvoice });
                                    membersContext.updateMemberTransferPartial(membersContext.currentMember.member_id, props.selectedRows[0].member_transfer_id, {
                                        hc_invoice_number: newInvoice,
                                    })
                                        .then(() => {
                                            props.syncRows(newValues);
                                            success();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                        .finally(() => props.setLoading(false))
                                }
                            }}
                        >
                            {(isEditing) => {
                                if(!isEditing){
                                    return (
                                        <div className='font-medium text-dark'>{props.selectedRows[0].hc_invoice_number || '-'}</div>
                                    )
                                }
                                return (
                                    <FormikInputText id='invoiceNo' name='invoiceNo' trim autoFocus />
                                )

                            }}
                        </FormikEditable>
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

export default MemberProfileTransferSidepanelFinances;