import API_SPORDLE from "../API-Spordle";
import queryString from 'query-string';
import { serverError } from "../CancellableAPI";
import { jsObjectToApi } from "@spordle/helpers";

export function getMemberTypeStatuses(query){
    return API_SPORDLE.get(queryString.stringifyUrl({ url: `member-type-statuses`, query: query }))
        .then((response) => {
            if(response.data.status){
                return response.data.member_type_statuses;
            }
            throw response.data.errors[0];
        }, serverError)
}

export function getMemberTypeApprobations(query){
    return API_SPORDLE.get(queryString.stringifyUrl({ url: `member-type-approbations`, query: query }))
        .then((response) => {
            if(response.data.status){
                // Lite format
                const member_type_approbations = response.data.member_type_approbations || [];
                const attachments: Record<string, Record<string, any>> = response.data.attachments || {};
                const member_type_statuses: Record<string, Record<string, any>> = response.data.member_type_statuses || {};
                const member_types: Record<string, Record<string, any>> = response.data.member_types || {};
                const organisations: Record<string, Record<string, any>> = response.data.organisations || {};
                const periods: Record<string, Record<string, any>> = response.data.periods || {};
                const identities: Record<string, Record<string, any>> = response.data.identities || {};
                const criminal_record_check_statuses: Record<string, Record<string, any>> = response.data.criminal_record_check_statuses || {};

                // Build resources
                const fullMemberTypeApprobations = member_type_approbations.map((approbation) => {
                    if(attachments[approbation.picture]){
                        // store picture in temp variable to prevent recursive data crash
                        const attachmentId = approbation.picture
                        approbation.picture = attachments[attachmentId];
                        approbation.picture.attachment_id = attachmentId;
                    }

                    if(member_type_statuses[approbation.member_type_status_id]){
                        approbation.member_type_status = member_type_statuses[approbation.member_type_status_id];
                        approbation.member_type_status.member_type_status_id = approbation.member_type_status_id;
                    }

                    if(member_types[approbation.member_type_id]){
                        approbation.member_type = member_types[approbation.member_type_id];
                        approbation.member_type.member_type_id = approbation.member_type_id;
                    }

                    if(organisations[approbation.organisation_id]){
                        approbation.organisation = organisations[approbation.organisation_id];
                        approbation.organisation.organisation_id = approbation.organisation_id;
                    }

                    if(periods[approbation.period_id]){
                        approbation.period = periods[approbation.period_id];
                        approbation.period.period_id = approbation.period_id;
                    }

                    if(identities[approbation.decision_made_by]){
                        const identityId = approbation.decision_made_by
                        approbation.decision_made_by = identities[identityId];
                        approbation.decision_made_by.identity_id = identityId;
                    }

                    if(criminal_record_check_statuses[approbation.criminal_record_check_status_id]){
                        approbation.criminal_record_check_status = criminal_record_check_statuses[approbation.criminal_record_check_status_id];
                        approbation.criminal_record_check_status.criminal_record_check_status_id = approbation.criminal_record_check_status_id;
                    }

                    // valid_crc can be 1, 0 or null, this makes sure we only have 1 or 0
                    approbation.valid_crc = approbation.valid_crc ? 1 : 0

                    // Cleanup resource IDs
                    delete approbation.member_type_status_id;
                    delete approbation.member_type_id;
                    delete approbation.organisation_id;
                    delete approbation.period_id;
                    delete approbation.criminal_record_check_status_id;

                    return approbation;
                });

                return fullMemberTypeApprobations;
            }
            throw response.data.errors[0];
        }, serverError)
}

export function updateMemberTypeApprobations(values){
    const apiValues = new URLSearchParams();
    jsObjectToApi(values, apiValues);

    return API_SPORDLE.put(queryString.stringifyUrl({ url: `member-type-approbations` }), apiValues)
        .then((response) => {
            if(response.data.status){
                return true;
            }
            throw response.data.errors;
        }, serverError)
}