import { FormikSelect } from '@spordle/formik-elements';
import { useContext, useRef } from 'react';
import { I18nContext } from '../../../contexts/I18nContext';
import { TransferContext } from '../../../contexts/TransferContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';

const AddTransferTypesSelect = ({ setFieldValue }) => {
    const transferContext = useContext(TransferContext);
    const { getGenericLocale } = useContext(I18nContext);

    const durations = useRef();

    return (
        <FormikSelect
            name='usa_transfer_type_id'
            id='usa_transfer_type_id'
            searchKeys={[
                `i18n.${getGenericLocale()}.name`,
            ]}
            renderOption={({ option: transferType }) => <DisplayI18n field='name' defaultValue={transferType.label} i18n={transferType.i18n} />}
            loadData={(from) => {
                switch (from){
                    case 'CDM':
                        return Promise.all([
                            transferContext.getUSATransferTypes(undefined, { active: 1 }, false, true), // Get only active but don't cache in the results
                            transferContext.getTransferDurations({ active: 1 }, false, true),
                        ])
                            .then(([ transferTypes, transferDurations ]) => {
                                durations.current = transferDurations
                                return transferTypes.sort((transferA, transferB) => parseInt(transferA.display_order) - parseInt(transferB.display_order))
                                    .map((transferType) => ({
                                        value: transferType.usa_transfer_type_id,
                                        label: transferType.name,
                                        i18n: transferType.i18n,
                                    }))
                            })
                    default:
                        break;
                }
            }}
            onOptionSelected={(values, spordleSelect) => {
                const option = spordleSelect.getSpordleTable().getData().find((data) => data.value === values[0])
                // TODO: HARDCODED
                if(option.label === 'Try out'){
                    setFieldValue('transfer_duration_id', durations.current.find((duration) => duration.name === 'Try out')?.transfer_duration_id)
                }
            }}
        />
    );
}

export default AddTransferTypesSelect;