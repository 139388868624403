import PropTypes from 'prop-types';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { DisplayI18n } from '../../helpers/i18nHelper';

const InvoiceItemsClinic = ({ item, isVoid, number }) => {
    const ClinicItem = item.clinic_fee ? ClinicItemClinicFee : item.clinic ? ClinicItemClinic : null; // Verify so we don't show quelification fees, these aren't used right now
    return ClinicItem ? <ClinicItem item={item} isVoid={isVoid} number={number} /> : null;
}

const ClinicItemClinic = ({ item, isVoid }) => {
    return (
        <tr>
            <td className="border-top-0 pt-0 ">
                <span className="font-medium"><Translate id='invoice.table.clinic' /></span>: {item.clinic?.clinic_reference && `#${item.clinic.clinic_reference} - `}<DisplayI18n field='name' defaultValue={item.clinic?.name} i18n={item.clinic?.i18n} />
                {/* <div className="small"><ClinicCategory.Builder hideColor qualification={item.clinic?.qualification}/></div> */}
            </td>
            <td className="border-top-0 pt-0 text-right">
                <CurrencyFormat value={parseInt(item.amount) / 100} />
            </td>
            <td className="border-top-0 pt-0">X</td>
            <td className="border-top-0 pt-0">1</td>
            <td className="border-top-0 pt-0 text-right">
                <CurrencyFormat value={isVoid ? 0 : parseInt(item.amount) / 100} />
            </td>
        </tr>
    )
}

const ClinicItemClinicFee = ({ item, number }) => {
    return (
        <tr>
            <td className="border-top-0 pt-0 ">
                <span className="font-medium"><Translate id='invoice.table.item' /></span>: <DisplayI18n field="name" i18n={item.clinic_fee.fee.i18n} defaultValue={item.clinic_fee.fee.name} />
            </td>
            <td className="border-top-0 pt-0 text-right">
                <CurrencyFormat value={parseInt(item.amount) / 100} />
            </td>
            <td className="border-top-0 pt-0">X</td>
            <td className="border-top-0 pt-0">{number}</td>
            <td className="border-top-0 pt-0 text-right">
                <CurrencyFormat value={parseInt(item.amount) / 100} />
            </td>
        </tr>
    )
}

InvoiceItemsClinic.propTypes = {
    invoice: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
};

export default InvoiceItemsClinic;