import { FormikSelect } from "@spordle/formik-elements"
import Translate from "@spordle/intl-elements"
import { useContext } from "react"
import { Col, FormGroup, Label, Row } from "reactstrap"
import Required from "../../../../../components/formik/Required"
import { PeriodsContext } from "../../../../../contexts/contexts"

const EnginePeriodReferencePeriodSelect = ({ filter, filterRefs }) => {
    const periodsContext = useContext(PeriodsContext)
    return (
        <Row form>
            {/* REFERENCE_PERIOD_ID FILTER */}
            <Col md={6}>
                <FormGroup>
                    <Label><Translate id='reports.filters.reference_period_id' /> <Required /></Label>
                    <FormikSelect
                        search={false}
                        clearable
                        id='reference_period_id_no_team_reports'
                        name='reference_period_id'
                        defaultData={periodsContext.activePeriods.map((period) => ({
                            value: period.period_id,
                            label: period.name,
                        }))}
                        loadingStatus='success'
                    />
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label><Translate id='reports.filters.period' /> <Required /></Label>
                    <FormikSelect
                        search={false}
                        id='period_id_no_team_report'
                        name='period_id'
                        defaultData={periodsContext.activePeriods.map((period) => ({
                            value: period.period_id,
                            label: period.name,
                        }))}
                        loadingStatus='success'
                    />
                </FormGroup>
            </Col>
        </Row>
    )
}

export default EnginePeriodReferencePeriodSelect