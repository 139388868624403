import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";

export const RoleCardAdditionalRoles = ({ identityRole, small }) => {
    const tip = identityRole.additional_role.map((addRole) => addRole.role.title);

    return (
        <Tooltip
            zIndex={3000}
            label={
                tip ?
                    <div>
                        <label className="mb-0"><u><Translate id="organization.profile.users.sidePanelUsers.userInfo.additional_roles" />:</u></label>
                        <ul className='mb-0' style={{ 'paddingInlineStart': "15px" }}>
                            {tip.map((addRole) => (
                                <li key={addRole}>{addRole}</li>
                            ))}
                        </ul>
                    </div>
                    : null
            }
        >
            <i
                style={{ fontSize: `${small ? '14px' : '20px'}` }}
                className="text-primary mdi mdi-cards-outline"
            />
        </Tooltip>
    )
}