import { useDisclosure } from "@mantine/hooks";
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import moment from "moment";
import { useContext } from "react";
import { Card } from "reactstrap";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import { getPotentialMemberDuplicates } from "../../../api/client/potentialMemberDuplicates";
import StatusBadge from "../../../components/badges/StatusBadge";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import TablePagination from "../../../components/table/TablePagination";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";
import { I18nContext } from "../../../contexts/I18nContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import PotentialMemberDuplicatesSidePanel from "./sidePanel/PotentialMemberDuplicatesSidePanel";
import DisplayMember from "../../../components/display/DisplayMember";
import GenderLabel from "../../../components/GenderLabel";
import DisplayBirthdateAge from "../../../components/DisplayBirthdateAge";
import DisplayOrg from "../../../components/DisplayOrg";

const PotentialMemberDuplicates = ({ changeRequestStatuses, ...props }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const organizationContext = useContext(OrganizationContext);
    const [ isModalOpen, isModalOpenHandlers ] = useDisclosure(false);

    return (
        <>
            <ViewHeaderV2 title='tasks.memberDuplicates.title' />
            <div className="px-4">
                <SpordlePanelTable
                    allowOutsideClick
                    sidePanel={(props) => (
                        <PotentialMemberDuplicatesSidePanel
                            {...props}
                            isModalOpen={isModalOpen}
                            isModalOpenHandlers={isModalOpenHandlers}
                            changeRequestStatuses={changeRequestStatuses}
                        />
                    )}
                    dataIndex='member_id'
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='potentialMemberDuplicate'
                                tableHover bordered striped
                                clickable
                                ref={panelProps.spordleTableRef}
                                desktopWhen
                                dataIndex='member_id'
                                pagination={25}
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : void 0}
                                defaultSorting='+created_at'
                                onColumnClick={(e, data, _) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            // if(columnKey === 'checkbox'){
                                            //     panelProps.onMultiSelectChange(data);
                                            // }else{
                                            //     panelProps.onSingleSelectChange(data);
                                            // }
                                            panelProps.onSingleSelectChange(data);
                                            break;
                                    }
                                }}
                                searchKeys={[
                                    `organisation.i18n.${getGenericLocale()}.name`,
                                    `member.last_name`,
                                    `member.first_name`,
                                    `member.birthdate`,
                                    `member.unique_identifier`,
                                    `member.email`,
                                    'created_at',
                                    'submission_date',
                                    `organisation_status.i18n.${getGenericLocale()}.name`,
                                ]}
                                loadData={(from, _, spordleTable) => {
                                    switch (from){
                                        case 'REFRESH':
                                        case 'CDM':
                                            spordleTable.setLoading();

                                            return getPotentialMemberDuplicates({ organisation_id: organizationContext.organisation_id, status: 'PENDING' })
                                                .then((memberDuplicates) => {
                                                    return memberDuplicates.map((memberDuplicate) => ({
                                                        ...memberDuplicate,
                                                        updated_at: (memberDuplicate.duplicate_members || []).reduce((updated, duplicateMember) => {
                                                            if(!updated || moment(updated).isAfter(moment(duplicateMember.created_at))){
                                                                return duplicateMember.created_at;
                                                            }

                                                            return updated;
                                                        }, ""),
                                                        count: (memberDuplicate.duplicate_members || []).length,
                                                    }))
                                                })
                                                .catch((error) => {
                                                    if(AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        // return true to let the table know that the call is cancelled and not put hte table in an error state
                                                        return true;
                                                    }
                                                })
                                        default:
                                            break;
                                    }
                                }}
                                columns={[
                                    // {
                                    // // yes keep this
                                    //     label: '-',
                                    //     key: 'checkbox',
                                    //     className: 'th-shrink',
                                    //     dataClassName: 'td-shrink',
                                    // },
                                    {
                                        label: <Translate id="tasks.memberDuplicates.lastSubmitted" />,
                                        key: "updated_at",
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.search.memberSearch.table.organization' />,
                                        key: "organization",
                                        mobile: true,
                                        sortable: true,
                                        sortKey: `organisation.i18n.${getGenericLocale()}.name`,
                                        fallbackSortKey: 'organisation.organisation_name',
                                    },
                                    {
                                        label: <Translate id='members.search.memberSearch.table.member' />,
                                        key: "last_name",
                                        mobile: true,
                                        sortable: true,
                                        sortKey: 'member.last_name',
                                    },
                                    {
                                        label: <Translate id='form.fields.dateOfBirth' />,
                                        key: "birthdate",
                                        mobile: true,
                                        sortable: true,
                                        sortKey: 'member.birthdate',
                                    },
                                    {
                                        label: <Translate id="tasks.memberDuplicates.duplicates" />,
                                        key: "count",
                                        mobile: true,
                                        sortable: true,
                                        sortKey: 'count',
                                    },
                                ]}
                                renderRow={(key, potentialDuplicate) => {
                                    switch (key){
                                        case 'checkbox':
                                            return (
                                                <label
                                                    className='my-auto'
                                                    htmlFor={'checked-' + potentialDuplicate.member_id}
                                                    onClick={(e) => { e.stopPropagation() }}
                                                >
                                                    <input
                                                        id={'checked-' + potentialDuplicate.member_id}
                                                        type="checkbox"
                                                        checked={!!potentialDuplicate.checked}
                                                        onClick={() => { panelProps.onMultiSelectChange(potentialDuplicate); }}
                                                        onChange={function(){}}// This needs to be here to remove the console error
                                                    />
                                                    <span className="table-checkbox" />
                                                </label>
                                            )
                                        case 'updated_at':
                                            return moment(potentialDuplicate.updated_at).format("YYYY-MM-DD HH:mm");
                                        case 'organization':
                                            return (potentialDuplicate.organisation ?
                                                <DisplayOrg org={potentialDuplicate.organisation} />
                                                :
                                                '-'
                                            )
                                        case 'last_name':
                                            return (
                                                <DisplayMember
                                                    member={potentialDuplicate.member}
                                                    withLink
                                                    extra={{
                                                        infoBottom: (
                                                            <>
                                                                {potentialDuplicate.member.gender &&
                                                                    <UserDisplay.Subtitle>
                                                                        <GenderLabel gender={potentialDuplicate.member.gender} />
                                                                    </UserDisplay.Subtitle>
                                                                }
                                                                <UserDisplay.Subtitle>
                                                                    <StatusBadge color={potentialDuplicate.member.member_status?.color_code} status={potentialDuplicate.member.member_status} />
                                                                </UserDisplay.Subtitle>
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )
                                        case 'birthdate':
                                            return (
                                                potentialDuplicate.member?.birthdate ?
                                                    <div>
                                                        <DisplayBirthdateAge
                                                            birthdate={potentialDuplicate.member.birthdate}
                                                            age={potentialDuplicate.member.age}
                                                            splitter={<br />}
                                                        />
                                                    </div>
                                                    :
                                                    '-'
                                            )
                                        default:
                                            break;
                                    }
                                }}
                            >
                                {() => (
                                    <Card body className="card-shadow">
                                        <div className="mb-2">
                                            <div className='d-flex flex-wrap mb-2'>
                                                {/* <SpordlePanelControl /> */}
                                                <SearchInput />
                                                {/* <CanDoAction
                                                    componentCode="members"
                                                    componentPermissionCode="manage_potential_ineligible_members"
                                                    action="EDIT"
                                                    className='d-flex ml-auto'
                                                >
                                                    {spordleTable.getData().length > 0 &&
                                                        <Button
                                                            color='primary'
                                                            onClick={async() => {
                                                                await panelProps.checkAllPages()
                                                                isModalOpenHandlers.toggle()
                                                            }}
                                                        >
                                                            <Translate id='task.mandatoryChangesRequests.manage.all' />
                                                        </Button>
                                                    }
                                                </CanDoAction> */}
                                                <TablePagination className='ml-auto' paginationArray={[ 25, 50, 100 ]} />
                                                <Refresh className='ml-2' />
                                            </div>
                                        </div>
                                        <SpordleTableView />
                                    </Card>
                                )}
                            </SpordleTableProvider>
                        )
                    }}
                />
            </div>
        </>
    );
};

export default PotentialMemberDuplicates;
