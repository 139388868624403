export default{
    "catalog.otherItems.otherItemsAdd.title"            : "Add an item",
    "catalog.otherItems.otherItemsAdd.form.name"        : "Nom",
    "catalog.otherItems.otherItemsAdd.form.description" : "Description",
    "catalog.otherItems.otherItemsAdd.form.price"       : "Price",
    "catalog.otherItems.otherItemsAdd.form.tax"         : "Tax",
    "catalog.otherItems.otherItemsAdd.form.sku"         : "SKU",

    "catalog.otherItems.otherItemsAdd.form.validation.amount"      : "The price is required.",
    "catalog.otherItems.otherItemsAdd.form.validation.minAmount"   : "Minimum amount should be $0.01 or higher",
    "catalog.otherItems.otherItemsAdd.form.validation.name"        : "The name is required.",
    "catalog.otherItems.otherItemsAdd.form.validation.description" : "The description is required.",
}