import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useEffect, useReducer, useState } from "react";
import { Button } from "reactstrap";
import { SpordletablePanelContext } from "../sidePanel/SpordlePanel";
import PropTypes from 'prop-types';
import FileUpload from "../uploader/fileUpload/FileUpload";

/**
 * @description Handles editing of existing attachments or adding attachments with formik : to be used with SpordlePanel
 * @param {object & import("../uploader/fileUpload/FileUpload").FileUploadProps & import("formik").FormikConfig} props
 * @param {string} [props.id] Id formik
 * @param {string} prop.initialValues needs attachment: {...attachment ressource}{@see https://api.id.dev.spordle.dev/documentations/#/Transfer%20Attachments/Apicontroller%5CMembers%5CTransferattachments%3A%3AgetAllTransferAttachments|Documentation}
 * @param {string} props.fileIdField The field id of the file. Must be set
 * @param {boolean} [props.showCreatedAt] Wether or not to show the created date and the cerated by next to the file names
 * @param {string} props.name Name of the formik field && input
 * @param {bool} props.skipPreventClose Will not ask to stop editing when closing sidepanel
 * @param {import("react-dropzone").DropzoneProps & React.RefAttributes<DropzoneRef>} [props.dropzoneProps] Dropzone Props
 * @returns {import('formik').FormikConfig & React.ReactNode}
 * @example
 * <FormikEditableFile
 *      name="file"
 *      initialValues={{file: {
 *          attachment: {
 *              attachement_id: 'id',
 *              full_path: 'path',
 *              file_name: 'file_name',
 *              file_ext: '.png',
 *          }
 *      }}}
 *      onSubmit={(values) => {
 *          createAttachment(ressourceId, values.file);
 *      }}
 * />
 * @example
 * <FormikEditableFile
 *      dropzoneProps={{
            multiple: false,
            accept:"image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx",
        }}
        onFileClick={(file) => {
            return getFileDownload(apiCall, file, file.name)
                .catch(console.error)
        }}
        initialValues={{
            file: this.props.selectedRows[0].attachments?.[0] || null,
        }}
        onSubmit={(values) => {
            apiCall()
        }}
 * />
 */
const FormikEditableFile = ({
    id,
    disabled = false,
    dropzoneProps,
    fileIdField,
    onFileClick,
    preventRemoveExistant,
    showCreatedAt,
    name = 'file',
    skipPreventClose = false,
    children,
    ...props
}) => {
    // forces a rerender for fileUpload defaultVal https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
    // might try a cleaner solution
    const [ k, forceUpdate ] = useReducer((x) => x + 1, 0);
    const initStatus = { ...props.initialStatus, isEditing: false };

    function issueAttWarning(){
        console.warn(
            'Please provide an array of files with an attachment ressource.',
            'Example : [{prop: any, attachment: {attachement_id: "id", file_name: "name"}}]',
        );
    }

    /**
     * @description Handles what is sent to FileUpload as default values
     * @param {object[]|object} initVal
     * @returns {object[]}
     */
    const initFiles = (initVal) => {
        if(Array.isArray(initVal)){
            return initVal.reduce((newArr, file) => {
                if(!file.attachment?.file_name){
                    issueAttWarning();
                }else{
                    !!file.attachment?.file_name && newArr.push(formatFile(file));
                }
                return newArr;
            }, []);
        }
        if(initVal){
            if(!initVal.attachment?.file_name){
                issueAttWarning();
            }else{
                return [ formatFile(initVal) ];
            }
        }

        return [];

    };

    /**
     * @description handles keys with dots to fetch an object property
     * @param {string} keyName property OR property.property
     * @param {object} obj
     * @returns {any} property value
     */
    const recursiveKey = (keyName, obj) => {
        let prop = obj;

        keyName.split('.')?.forEach((k) => {
            if(prop[k]) prop = prop[k];
        });

        return prop;
    }

    /**
     * @typedef {Object} Formatted
     * @property {string} id
     * @property {string} name
     * @property {string} url_path
     * @property {string} [file_ext]
     */

    /**
     * @description Formats the file for the FileUpload
        Will use obj.attachment.any since the subressource always look like this
     * @param {File} file
     * @returns {Formatted}
     */
    const formatFile = (file) => ({
        id: fileIdField ? recursiveKey(fileIdField, file) : file?.attachment.attachement_id,
        name: file?.attachment?.file_name,
        url_path: file?.attachment?.full_path,
        type: file?.attachment?.file_ext,
        canRemove: true, // Future update
        ...file,
    });

    /**
     * @description Handles submitting
     * @param {object} values
     * @param {import("formik").FormikBag} formikBag
     */
    const handleSubmit = async(values, formikBag) => {
        const checkIfEmpty = (val) => Array.isArray(val) ? val.length <= 0 : !val;
        formikBag.setStatus(initStatus); // Stop editing

        // Checks if there wasn't any initial values to begin with and if there's no new values, no need for a submit call
        if(!(checkIfEmpty(props.initialValues[name]) && checkIfEmpty(values[name]))){
            if(props.onSubmit){
                await props.onSubmit(values, formikBag); // Submit
            }
        }
    }

    return (
        <Formik
            {...props}
            key={k}
            enableReinitialize
            id={'formik' + (id || name)}
            initialStatus={initStatus}
            onSubmit={(values, formikBag) => handleSubmit(values, formikBag)} // Please leave as it is, need to return the function to formik (do not onSubmit={handleSubmit})
        >
            { (formik) => (
                <Form className="position-relative">
                    <FileUpload
                        name={name} // name of file input
                        id={id} // id of file input
                        disabled={disabled} // if disabled, hides upload design
                        defaultFiles={initFiles(formik.initialValues[name])}
                        dropzoneProps={dropzoneProps}
                        onFileClick={onFileClick} // what happens when user clicks on a single file
                        onFileSelect={(f) => { // when file is added
                            // If file is multiple, send all files to the field,
                            // else send only one
                            formik.setFieldValue(name, dropzoneProps?.multiple ? f : f?.[0]);

                            // Edit mode
                            formik.setStatus({ ...formik.status, isEditing: true });
                        }}
                        preventRemoveExistant={preventRemoveExistant}
                        showCreatedAt={showCreatedAt}
                    />

                    {/* Render formik errors  */}
                    {formik.errors?.[name] && <div className="text-danger small">{formik.errors?.[name]}</div>}

                    {/* Show close error (when closing sidepanel but still editing) */}
                    {!skipPreventClose &&
                        <EditableFileCloseError isEditing={formik.status.isEditing} name={id || name} />
                    }

                    {/* Render children (sends formik) */}
                    {children && children(formik)}

                    {/* Editing buttons */}
                    {formik.status.isEditing &&
                        <div className='position-absolute top-100 right-0 z-index-1 pt-2'>
                            <Button // Confirm button
                                disabled={formik.isSubmitting}
                                size="sm"
                                color='primary'
                                type='submit'
                                className="mr-1"
                            >
                                <i className="fas fa-check" />
                            </Button>
                            <Button // Cancel button
                                disabled={formik.isSubmitting}
                                size="sm"
                                color='white'
                                className='border bg-white'
                                type='button'
                                onClick={forceUpdate}
                            >
                                <i className="fas fa-times" />
                            </Button>
                        </div>
                    }
                </Form>
            )}
        </Formik>
    )
}

/**
 * @description Handles preventing close of sidepanel when editing
 * @param {bool} isEditing
 * @param {string} name name of input
 * @returns {React.ReactNode|null}
 */
const EditableFileCloseError = ({ isEditing, name }) => {
    const [ showError, setShowError ] = useState(false);
    const spordlePanel = useContext(SpordletablePanelContext);

    useEffect(() => {

        // Will pop a warning toast if is editing
        spordlePanel?.askBeforeClose(name, isEditing, () => setShowError(true));

    }, [ isEditing ])

    return (
        showError ? <div className='small text-danger'><Translate id='sidePanel.spordlePanel.preventClose.title' /></div> : null
    )
}

FormikEditableFile.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    fileIdField: PropTypes.string,
    name: PropTypes.string.isRequired,
    dropzoneProps: PropTypes.object,
    skipPreventClose: PropTypes.bool,
    preventRemoveExistant: PropTypes.bool,
    children: PropTypes.func,
}

FormikEditableFile.defaultProps = {
    disabled: false,
    name: 'file',
    skipPreventClose: false,
}

export default FormikEditableFile;