export default{
    // general
    "members.profile.transactions.memberProfileTransactions.paymentMethods.CHEQUE"              : "Chèque",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.CASH"                : "Argent comptant",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.CREDITCARD"          : "Carte de crédit",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.PAYMENTPROXY"        : "Carte de crédit (paiement proxy)",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.INTERAC"             : "Transfert Interac",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.undefined"           : "N/A",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.ORGANISATION_CREDIT" : "Crédité (Gratuit)",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.MEMBER_CREDIT"       : "Crédit de membre",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.EXTERNALPAYMENT"     : "Paiement externe",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.FREE"                : "Gratuit",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.FUNDING"             : "Financement",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.OFFLINECREDIT"       : "Crédit hors ligne",

    "members.profile.transactions.memberProfileTransactions.total"                         : "Total",
    "members.profile.transactions.memberProfileTransactions.due"                           : "Dû",
    "members.profile.transactions.memberProfileTransactions.refunded"                      : "Remboursé",
    "members.profile.transactions.memberProfileTransactions.refund"                        : "Remboursement",
    "members.profile.transactions.memberProfileTransactions.refunds"                       : "Remboursements",
    "members.profile.transactions.memberProfileTransactions.paymentReception"              : "Paiement reçu",
    "members.profile.transactions.memberProfileTransactions.paymentReceptions"             : "Paiements reçus",
    "members.profile.transactions.memberProfileTransactions.paymentReceptions.viewReceipt" : "Voir le reçu",
    "members.profile.transactions.memberProfileTransactions.paymentReceptions.deleteText"  : "Vous êtes sur le point du supprimer le paiement du {date}.",
    "members.profile.transactions.memberProfileTransactions.refunds.deleteText"            : "Vous êtes sur le point du supprimer le remboursement du {date}.",
    "members.profile.transactions.memberProfileTransactions.registrations"                 : "Inscription(s)",
    "members.profile.transactions.memberProfileTransactions.items"                         : "Article(s)",
    "members.profile.transactions.memberProfileTransactions.paidBy"                        : "Payé par",
    "members.profile.transactions.memberProfileTransactions.add"                           : "Ajouter un crédit",
    "members.profile.transactions.memberProfileTransactions.credit"                        : "Crédit",
    "members.profile.transactions.memberProfileTransactions.appliedCredit"                 : "Crédit appliqué",

    // CSV
    "members.profile.transactions.memberProfileTransactions.csv.invoiceDate"   : "Date de facturation",
    "members.profile.transactions.memberProfileTransactions.csv.invoiceNumber" : "Numéro de facture",
    "members.profile.transactions.memberProfileTransactions.csv.paymentMethod" : "Mode de paiement",
    "members.profile.transactions.memberProfileTransactions.csv.total"         : "Total ($) ",
    "members.profile.transactions.memberProfileTransactions.csv.paid"          : "Payé ($) ",
    "members.profile.transactions.memberProfileTransactions.csv.due"           : "Dû ($) ",

    // table view
    "members.profile.transactions.memberProfileTransactions.tableView.header.transaction"       : "Transaction",
    "members.profile.transactions.memberProfileTransactions.tableView.header.items"             : "Articles",
    "members.profile.transactions.memberProfileTransactions.tableView.header.paid"              : "Payé",
    "members.profile.transactions.memberProfileTransactions.tableView.header.due"               : "Dû",
    "members.profile.transactions.memberProfileTransactions.tableView.header.clinic"            : "Stage",
    "members.profile.transactions.memberProfileTransactions.tableView.header.clinic.fee"        : "Articles de stage",
    "members.profile.transactions.memberProfileTransactions.tableView.header.clinic.fee.single" : "Article de stage",


    // sidepanel
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.PENDING"        : "En attente",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.PAID"           : "Payé",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.COMPLETED"      : "Complété",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.NEED_REVIEW"    : "En révision",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.SUSPENDED"      : "Suspendu",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.DECLINED"       : "Refusé",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.PARTIALLY_PAID" : "Partiellement payé",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.CANCELED"       : "Annulé",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.VOID"           : "Annulé",

    "members.profile.transactions.memberProfileTransactions.sidePanel.header.invoice"                : "Facture",
    "members.profile.transactions.memberProfileTransactions.sidePanel.header.initialPaymentDate"     : "Date du paiement initial",
    "members.profile.transactions.memberProfileTransactions.sidePanel.header.transactionType.online" : "Transaction en ligne",
    "members.profile.transactions.memberProfileTransactions.sidePanel.header.transactionType.manual" : "Transaction manuelle",

    "members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_init"         : "Transaction préparée",
    "members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_auth"         : "Transaction préautorisée",
    "members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_init.tooltip" : "Transaction préparée par",
    "members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_auth.tooltip" : "Transaction préautorisée par",


    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.refund"                        : "Rembourser",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.refund.disabled.1"             : "Les transactions manuelles doivent être",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.refund.disabled.2"             : "complètement payées pour être remboursées",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelItems"                   : "Annuler des articles",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.paymentReception"              : "Appliquer un paiement",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice"                 : "Renvoyer la facture",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoices"                : "Renvoyer les factures",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoiceWithReceipt"      : "Renvoyer la facture avec reçu",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.sendCommunication"             : "Envoyer un message",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoicesWithReceipts"    : "Renvoyer les factures avec reçus",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.bulkUpdate.result"             : "Mettre à jour les résultats",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.bulkUpdate.presence"           : "Mettre à jour les présences",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice.success"         : "{count, plural, =0 {La facture a été renvoyée.} one {La facture a été renvoyée.} other {Les factures ont été renvoyées.}}",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice.error"           : "Nous n'avons pas réussi à renvoyer le(s) facture(s). Veuillez réessayer plus tard.",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.viewInvoice"                   : "Voir la facture",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelInstallments"            : "Annuler les versements",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelInstallments.disabled.1" : "Seuls les versements payés par carte de crédit",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelInstallments.disabled.2" : "peuvent être annulés",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.convertInvoice"                : "Convertir en transactionnel",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.convertInvoiceManual"          : "Convertir en manuel",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.void"                          : "Annuler la facture",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.void.title"                    : "Vous êtes sur le point d'annuler cette facture.",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.void.text"                     : "Cette action est irréversible.",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.void.errors.3021"              : "Facture non-trouvée",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.credit"                        : "Créditer la facture",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.credit.errors.3021"            : "Facture non-trouvée",

    "members.profile.transactions.memberProfileTransactions.sidePanel.items.payments" : "Paiements",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.payment"  : "Paiement",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.date"     : "Date",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.status"   : "Statut",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.amount"   : "Montant",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.price"    : "Prix",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.due"      : "Dû",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.credit"   : "Crédit",

    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.paymentMethod"      : "Changer la méthode de paiement",
    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.date"               : "Changer la date de paiement",
    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.retry"              : "Réessayer le paiement",
    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.retry.modal.text"   : "Vous êtes sur le point de réessayer le paiement du {date}",
    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.cancel"             : "Annuler le paiement",
    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.cancel.modal.title" : "Annulation de versement",
    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.cancel.modal.text"  : "Vous êtes sur le point d'annuler le versement du {date}. Cette action est irréversible.",

    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.delete.message" : "Vous êtes sur le point de supprimer ce crédit.",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.updated"        : "Crédit mis à jour!",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.creationDate"   : "Date de création",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.balance"        : "Balance",

    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.title"         : "Informations de remboursement",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.by"            : "Remboursé par",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.paymentMethod" : "Méthode de paiement",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.amount"        : "Montant",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.date"          : "Date",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.note"          : "Note",

    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit"                        : "Rembourser",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.title"                  : "Rembourser un crédit",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.paymentMethod"          : "Méthode de paiement",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.paymentMethod.required" : "La méthode de paiement est requise",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.internalNote"           : "Note interne",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.internalNote.hint"      : "Cette note sera visible par les administrateurs uniquement.",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.externalNote"           : "Note externe",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.externalNote.hint"      : "Cette note sera visible au client.",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.success"                : "Crédit remboursé!",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.error.3431"             : "La balance du crédit ne peut pas être 0.",

    // add modal
    "members.profile.transactions.memberProfileTransactions.addModal.creditType"     : "Type de crédit",
    "members.profile.transactions.memberProfileTransactions.addModal.amount"         : "Montant",
    "members.profile.transactions.memberProfileTransactions.addModal.expirationDate" : "Date d'expiration",
    "members.profile.transactions.memberProfileTransactions.addModal.status"         : "Statut",
    "members.profile.transactions.memberProfileTransactions.addModal.note"           : "Note",

    "members.profile.transactions.memberProfileTransactions.addModal.error.3045" : "Ce membre n'appartient pas à cette organisation.",

    "members.profile.transactions.memberProfileTransactions.addModal.validation.creditType.required"       : "Le type de crédit est requis",
    "members.profile.transactions.memberProfileTransactions.addModal.validation.amount.required"           : "Le montant est requis",
    "members.profile.transactions.memberProfileTransactions.addModal.validation.expirationDate.required"   : "La date d'expiration est requise",
    "members.profile.transactions.memberProfileTransactions.addModal.validation.expirationDate.valid"      : "La date d'expiration doit être d'un bon format",
    "members.profile.transactions.memberProfileTransactions.addModal.validation.expirationDate.validAfter" : "La date d'expiration ne peut pas être avant aujourd'hui",
}