import { Button, Col, Label, Row } from 'reactstrap';

import Translate from '@spordle/intl-elements';
import { Formik, Form } from 'formik';
import { Fragment, useContext } from 'react';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../../../helpers/i18nHelper';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import FormikTiny from '../../../../../../components/formik/FormikTiny';
import { object, string } from 'yup';
import OrgSettingCardPreview from '../../OrgSettingCardPreview';
import { stringBuilder } from '@spordle/helpers';
import { CLINIC_CONFIRM_SETTING, REGISTRATION_CONFIRM_SETTING } from './MessagesSettingsConfirmation';
import { MessagesContext } from '../../../../../../contexts/MessagesContext';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import { useIntl } from 'react-intl';
import FormikApiError from '../../../../../../components/formik/FormikApiError';

const ConfirmationMsgForm = ({ stopEditing, registrationMsg, clinicMsg, onSuccess, canEditClinic, canEditRegistration }) => {
    const msgContext = useContext(MessagesContext);
    const i18nHelper = useContext(I18nHelperContext);
    const orgContext = useContext(OrganizationContext);
    const { formatMessage } = useIntl();

    return (
        <Formik
            initialStatus={{
                showError: false,
                error: null,
            }}
            initialValues={{
                [REGISTRATION_CONFIRM_SETTING]: i18nHelper.getInitialValues(registrationMsg),
                [CLINIC_CONFIRM_SETTING]: i18nHelper.getInitialValues(clinicMsg),
            }}
            validationSchema={object().shape({
                [REGISTRATION_CONFIRM_SETTING]: object().shape(i18nHelper.getValidationSchema({ body: string() })),
                [CLINIC_CONFIRM_SETTING]: object().shape(i18nHelper.getValidationSchema({ body: string() })),
            })}
            onSubmit={(values, { setStatus }) => {
                const registrationApiValues = i18nHelper.getAPIValues(values[REGISTRATION_CONFIRM_SETTING]);
                const clinicApiValues = i18nHelper.getAPIValues(values[CLINIC_CONFIRM_SETTING]);

                const createAndLinkMsg = (setting, values) => {
                    return msgContext.createSettingMessage({
                        active: 1,
                        title: setting,
                        ...values,
                    })
                        .then((messageId) => {
                            return orgContext.updateSettings(orgContext.organisation_id, { [setting]: messageId })
                                .then(() => messageId)
                                .catch(async(e) => {
                                    await msgContext.deleteSettingMessage(messageId);
                                    throw e;
                                });
                        })
                }

                const updateOrCreate = (setting, values) => {
                    const val = orgContext.settings[setting]?.value;

                    if(val){
                        return msgContext.partialUpdateMessage(val, values).then(() => val);
                    }

                    return createAndLinkMsg(setting, values);
                }

                return Promise.all([
                    canEditRegistration ? updateOrCreate(REGISTRATION_CONFIRM_SETTING, registrationApiValues) : Promise.resolve(),
                    canEditClinic ? updateOrCreate(CLINIC_CONFIRM_SETTING, clinicApiValues) : Promise.resolve(),
                ])
                    .then((messageIds) => {
                        return onSuccess(messageIds)
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            const error = <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message || formatMessage({ id: "misc.error" })} />;
                            setStatus({ showError: true, error });
                        }
                    })

            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <Row form>
                            <Col sm="6" className='mb-3'>
                                <OrgSettingCardPreview
                                    title={<Translate id='organization.settings.registrationSettings.setting.name' />}
                                    desc={<Translate id="organization.settings.registrationSettings.setting.desc" />}
                                    code={REGISTRATION_CONFIRM_SETTING}
                                >
                                    <RenderI18nForm field="body">
                                        {({ fieldName, lang, index }) => (
                                            <Fragment key={lang + fieldName + REGISTRATION_CONFIRM_SETTING}>
                                                {canEditRegistration ?
                                                    <>
                                                        {i18nHelper.state.languages.length > 1 && <Label for={fieldName} className={stringBuilder({ "mt-3": index > 0 })}><Translate id={`misc.${lang}.long`} /></Label>}
                                                        <FormikTiny
                                                            id={`${REGISTRATION_CONFIRM_SETTING}.${fieldName}`}
                                                            name={`${REGISTRATION_CONFIRM_SETTING}.${fieldName}`}
                                                        />
                                                    </>
                                                    :
                                                    <div className="text-dark">
                                                        {i18nHelper.state.languages.length > 1 && <div className={stringBuilder("text-uppercase small text-muted", { "mt-3": index > 0 })}><Translate id={`misc.${lang}.long`} /></div>}
                                                        {registrationMsg?.i18n?.[lang]?.body || "-"}
                                                    </div>
                                                }
                                            </Fragment>
                                        )}
                                    </RenderI18nForm>
                                </OrgSettingCardPreview>
                            </Col>
                            <Col sm={6} className="mb-3">
                                <OrgSettingCardPreview
                                    title={<Translate id='organization.settings.registrationSettings.clinic.setting.name' />}
                                    desc={<Translate id="organization.settings.registrationSettings.clinic.setting.desc" />}
                                    code={CLINIC_CONFIRM_SETTING}
                                >
                                    <RenderI18nForm field="body">
                                        {({ fieldName, lang, index }) => (
                                            <Fragment key={lang + fieldName + CLINIC_CONFIRM_SETTING}>
                                                {(canEditClinic) ?
                                                    <>
                                                        {i18nHelper.state.languages.length > 1 && <Label for={fieldName} className={stringBuilder({ "mt-3": index })}><Translate id={`misc.${lang}.long`} /></Label>}
                                                        <FormikTiny
                                                            id={`${CLINIC_CONFIRM_SETTING}.${fieldName}`}
                                                            name={`${CLINIC_CONFIRM_SETTING}.${fieldName}`}
                                                        />
                                                    </>
                                                    :
                                                    <div className="text-dark">
                                                        {i18nHelper.state.languages.length > 1 && <div className={stringBuilder("text-uppercase small text-muted", { "mt-3": index > 0 })}><Translate id={`misc.${lang}.long`} /></div>}
                                                        {clinicMsg?.i18n?.[lang]?.body || "-"}
                                                    </div>
                                                }
                                            </Fragment>
                                        )}
                                    </RenderI18nForm>
                                </OrgSettingCardPreview>
                            </Col>
                        </Row>
                        <FormikApiError />
                        <div className="text-right">
                            <Button color="primary" className="mx-2" type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                            <Button color="primary" outline onClick={stopEditing} type='button' disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                        </div>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default ConfirmationMsgForm;