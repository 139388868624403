export default{
    // general
    "members.profile.transactions.memberProfileTransactions.paymentMethods.CHEQUE"              : "Cheque",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.CASH"                : "Cash",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.CREDITCARD"          : "Credit card",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.PAYMENTPROXY"        : "Credit card (proxy payment)",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.INTERAC"             : "Interac transfer",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.undefined"           : "N/A",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.ORGANISATION_CREDIT" : "Credited (Free)",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.MEMBER_CREDIT"       : "Member credit",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.EXTERNALPAYMENT"     : "External payment",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.FREE"                : "Free",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.FUNDING"             : "Funding",
    "members.profile.transactions.memberProfileTransactions.paymentMethods.OFFLINECREDIT"       : "Offline Credit",

    "members.profile.transactions.memberProfileTransactions.total"                         : "Total",
    "members.profile.transactions.memberProfileTransactions.due"                           : "Due",
    "members.profile.transactions.memberProfileTransactions.refunded"                      : "Refunded",
    "members.profile.transactions.memberProfileTransactions.refund"                        : "Refund",
    "members.profile.transactions.memberProfileTransactions.refunds"                       : "Refunds",
    "members.profile.transactions.memberProfileTransactions.paymentReception"              : "Received payment",
    "members.profile.transactions.memberProfileTransactions.paymentReceptions"             : "Received payments",
    "members.profile.transactions.memberProfileTransactions.paymentReceptions.viewReceipt" : "View receipt",
    "members.profile.transactions.memberProfileTransactions.paymentReceptions.deleteText"  : "You are about to delete the {date} payment.",
    "members.profile.transactions.memberProfileTransactions.refunds.deleteText"            : "You are about to delete the {date} refund.",
    "members.profile.transactions.memberProfileTransactions.registrations"                 : "Registration(s)",
    "members.profile.transactions.memberProfileTransactions.items"                         : "Item(s)",
    "members.profile.transactions.memberProfileTransactions.paidBy"                        : "Paid by",
    "members.profile.transactions.memberProfileTransactions.add"                           : "Add a credit",
    "members.profile.transactions.memberProfileTransactions.credit"                        : "Credit",
    "members.profile.transactions.memberProfileTransactions.appliedCredit"                 : "Applied Credit",

    // CSV
    "members.profile.transactions.memberProfileTransactions.csv.invoiceDate"   : "Invoice Date",
    "members.profile.transactions.memberProfileTransactions.csv.invoiceNumber" : "Invoice Number",
    "members.profile.transactions.memberProfileTransactions.csv.paymentMethod" : "Payment Method",
    "members.profile.transactions.memberProfileTransactions.csv.total"         : "Total ($)",
    "members.profile.transactions.memberProfileTransactions.csv.paid"          : "Paid ($)",
    "members.profile.transactions.memberProfileTransactions.csv.due"           : "Due ($)",

    // table view
    "members.profile.transactions.memberProfileTransactions.tableView.header.transaction"       : "Transaction",
    "members.profile.transactions.memberProfileTransactions.tableView.header.items"             : "Items",
    "members.profile.transactions.memberProfileTransactions.tableView.header.paid"              : "Paid",
    "members.profile.transactions.memberProfileTransactions.tableView.header.due"               : "Due",
    "members.profile.transactions.memberProfileTransactions.tableView.header.clinic"            : "Clinic",
    "members.profile.transactions.memberProfileTransactions.tableView.header.clinic.fee"        : "Clinic fee(s)",
    "members.profile.transactions.memberProfileTransactions.tableView.header.clinic.fee.single" : "Clinic fee",


    // sidepanel
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.PENDING"        : "Pending",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.PAID"           : "Paid",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.COMPLETED"      : "Completed",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.NEED_REVIEW"    : "In review",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.SUSPENDED"      : "Suspended",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.DECLINED"       : "Declined",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.PARTIALLY_PAID" : "Partially Paid",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.CANCELED"       : "Cancelled",
    "members.profile.transactions.memberProfileTransactions.sidePanel.status.VOID"           : "Void",

    "members.profile.transactions.memberProfileTransactions.sidePanel.header.invoice"                : "Invoice",
    "members.profile.transactions.memberProfileTransactions.sidePanel.header.initialPaymentDate"     : "Initial payment date",
    "members.profile.transactions.memberProfileTransactions.sidePanel.header.transactionType.online" : "Online Transaction",
    "members.profile.transactions.memberProfileTransactions.sidePanel.header.transactionType.manual" : "Manual Transaction",

    "members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_init"         : "Prepared transaction",
    "members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_auth"         : "Pre-authorized transaction",
    "members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_init.tooltip" : "Prepared transaction by",
    "members.profile.transactions.memberProfileTransactions.sidePanel.header.pre_auth.tooltip" : "Pre-authorized transaction by",

    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.refund"                        : "Refund",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.refund.disabled.1"             : "The manual transactions have to be",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.refund.disabled.2"             : "fully paid to be refunded",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelItems"                   : "Cancel items",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.paymentReception"              : "Apply Payment",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice"                 : "Resend Invoice",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoices"                : "Resend Invoices",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoiceWithReceipt"      : "Resend Invoice With Receipt",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.sendCommunication"             : "Send Communication",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoicesWithReceipts"    : "Resend Invoices with Receipts",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.bulkUpdate.result"             : "Update results",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.bulkUpdate.presence"           : "Update presence status",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice.success"         : "{count, plural, =0 {The invoice has been sent.} one {The invoice has been sent.} other {The invoices have been sent.}}",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice.error"           : "We couldn't send the invoice(s). Please try again later.",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.viewInvoice"                   : "View Invoice",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelInstallments"            : "Cancel installments",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelInstallments.disabled.1" : "Only the installments paid by credit card",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.cancelInstallments.disabled.2" : "can be cancelled",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.convertInvoice"                : "Convert to transactional",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.convertInvoiceManual"          : "Convert to manual",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.void"                          : "Void invoice",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.void.title"                    : "You are about to void this invoice.",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.void.text"                     : "This action is irreversible.",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.void.errors.3021"              : "Invoice not found",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.credit"                        : "Credit Invoice",
    "members.profile.transactions.memberProfileTransactions.sidePanel.actions.credit.errors.3021"            : "Invoice not found",

    "members.profile.transactions.memberProfileTransactions.sidePanel.items.payments" : "Payments",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.payment"  : "Payment",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.date"     : "Date",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.status"   : "Status",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.amount"   : "Amount",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.price"    : "Price",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.due"      : "Due",
    "members.profile.transactions.memberProfileTransactions.sidePanel.items.credit"   : "Credit",

    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.paymentMethod"      : "Change payment method",
    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.date"               : "Change payment date",
    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.retry"              : "Retry payment",
    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.retry.modal.text"   : "You are about to retry the {date} payment",
    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.cancel"             : "Cancel payment",
    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.cancel.modal.title" : "Installment cancellation",
    "members.profile.transactions.memberProfileTransactions.sidePanel.installments.actions.cancel.modal.text"  : "You are about to cancel the {date} installment. This action is irreversible.",

    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.delete.message" : "You are about to delete this credit.",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.updated"        : "Credit updated!",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.creationDate"   : "Creation date",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.balance"        : "Balance",

    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.title"         : "Refund information",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.by"            : "Refunded by",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.paymentMethod" : "Payment method",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.amount"        : "Amount",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.date"          : "Date",
    "members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.note"          : "Note",

    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit"                        : "Refund",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.title"                  : "Refund a credit",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.paymentMethod"          : "Payment method",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.paymentMethod.required" : "Payment method is required",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.internalNote"           : "Internal note",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.internalNote.hint"      : "This note will be visible to administrators only..",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.externalNote"           : "External note",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.externalNote.hint"      : "This note will be visible to the client.",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.success"                : "Credit refunded!",
    "members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit.error.3431"             : "Credit balance cannot be 0.",

    // add modal
    "members.profile.transactions.memberProfileTransactions.addModal.creditType"     : "Credit categories",
    "members.profile.transactions.memberProfileTransactions.addModal.amount"         : "Amount",
    "members.profile.transactions.memberProfileTransactions.addModal.expirationDate" : "Expiration date",
    "members.profile.transactions.memberProfileTransactions.addModal.status"         : "Status",
    "members.profile.transactions.memberProfileTransactions.addModal.note"           : "Note",

    "members.profile.transactions.memberProfileTransactions.addModal.error.3045" : "This member doesn't belong to this organisation.",

    "members.profile.transactions.memberProfileTransactions.addModal.validation.creditType.required"       : "Credit category is required",
    "members.profile.transactions.memberProfileTransactions.addModal.validation.amount.required"           : "Amount is required",
    "members.profile.transactions.memberProfileTransactions.addModal.validation.expirationDate.required"   : "Expiration date is required",
    "members.profile.transactions.memberProfileTransactions.addModal.validation.expirationDate.valid"      : "Expiration date must be of a valid format",
    "members.profile.transactions.memberProfileTransactions.addModal.validation.expirationDate.validAfter" : "Expiration date can't be before today",
}