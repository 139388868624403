export default{
    "communications.title"       : "Communications",
    "communications.sendMessage" : "Envoyer message",

    "communications.inbox"                         : "Boîte",
    "communications.inbox.recipients.modal.title"  : "Tous les destinataires: {title}",
    "communications.inbox.recipients.search.empty" : "Aucun destinaire trouvé.",
    "communications.inbox.from"                    : "De",
    "communications.inbox.to"                      : "À",
    "communications.inbox.sent"                    : "Envoi",
    "communications.inbox.favorites"               : "Favoris",
    "communications.inbox.archived"                : "Archivés",
    "communications.inbox.btn.back"                : "Retour à la liste de messages",
    "communications.inbox.sent.empty"              : "Aucun message envoyé",
    "communications.inbox.received"                : "Réception",
    "communications.inbox.received.empty"          : "Aucun message reçu",
    "communications.inbox.empty"                   : "Aucun message",
    "communications.inbox.search.empty"            : "Aucun message trouvé",
    "communications.inbox.selected.empty"          : "Aucun message sélectionné",
    "communications.inbox.deletedSent"             : "Usager supprimé",

    "communications.inbox.tools.favorite"   : "Favori",
    "communications.inbox.tools.unfavorite" : "Retirer des favoris",
    "communications.inbox.tools.archive"    : "Archive",
    "communications.inbox.tools.unarchive"  : "Retirer des archives",
    "communications.inbox.tools.markRead"   : "Marquer comme lu",
    "communications.inbox.tools.markUnread" : "Marquer comme non lu",

    "communications.inbox.action.toast.markUnread" : "Marqué comme non lu",
    "communications.inbox.action.toast.markRead"   : "Marqué comme lu",
    "communications.inbox.action.toast.archive"    : "Ajouté aux archives",
    "communications.inbox.action.toast.unarchive"  : "Retiré des archives",
    "communications.inbox.action.toast.favorite"   : "Ajouté aux favoris",
    "communications.inbox.action.toast.unfavorite" : "Retiré des favoris",

    "communications.btn.compose"         : "Écrire",
    "communications.messages.list.title" : "{count, plural, one {Message (#)} other {Messages (#)}}",

    "communications.sendComm.selectType.org"             : "Organisation",
    "communications.sendComm.selectType.org.subtitle"    : "Envoyer une communication à une ou plusieurs organisations.",
    "communications.sendComm.selectType.member"          : "Participant",
    "communications.sendComm.selectType.member.subtitle" : "Envoyer une communication à un ou plusieurs participants.",
    "communications.sendComm.recipients.delete.tooltip"  : "Ceci va enlever cette adresse courriel de l’envoi mais non de la fiche du membre.",
    "communications.sendComm.recipients.add.plus"        : "Ajouter un participant",

    "communications.message.recipients.download.selected" : "Télécharger {count, plural, one {le contact sélectionné} other {les contacts sélectionnés}}",
    "communications.message.recipients.download.valid"    : "Télécharger {count, plural, one {le destinataire valide} other {les destinataires valides}}",
    "communications.message.recipients.download.invalid"  : "Télécharger {count, plural, one {le destinataire non valide} other {les destinataires non valides}",
    "communications.message.recipients.downloaded.title"  : "Liste téléchargée !",
    "communications.message.recipients.downloaded.msg"    : "Le téléchargement n'a pas fonctionné ? Essayez de nouveau en <a>cliquant ici</a>.",
}