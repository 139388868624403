import assignOfficiatingPermissionsModal from "./assignOfficiatingPermissionsModal";
import authRolePicker from "./authRolePicker/authRolePicker";
import calendar from "./calendar";
import communications from "./communications";
import fileViewer from "./fileViewer";
import formik from "./formik";
import helpCenter from "./helpCenter";
import invoice from "./invoice";
import manualRegistration from "./manualRegistration";
import mobileNotice from "./mobileNotice";
import organization from "./organization";
import organizationTree from "./organizationTree";
import sidePanel from "./sidePanel";
import tours from "./tours";
import transaction from "./transaction";
import uploader from "./uploader";
import validateMember from "./validateMember";
import viewHeader from "./viewHeader";
import savedSearchCue from "./savedSearchCue";
import updateInvoicePositionModal from "./updateInvoicePositionModal";
import checklists from "./checklists";

export default{
    ...assignOfficiatingPermissionsModal,
    ...checklists,
    ...authRolePicker,
    ...calendar,
    ...formik,
    ...invoice,
    ...savedSearchCue,
    ...organization,
    ...organizationTree,
    ...sidePanel,
    ...uploader,
    ...helpCenter,
    ...manualRegistration,
    ...mobileNotice,
    ...communications,
    ...transaction,
    ...viewHeader,
    ...fileViewer,
    ...validateMember,
    ...tours,
    ...updateInvoicePositionModal,
}