const UnconfirmedMember = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 12"
            width={width}
            height={height}
        >
            <path className="fill-current" d="M6,1.5c1.2,0,2.33,.47,3.18,1.32,1.75,1.75,1.75,4.61,0,6.36-.85,.85-1.98,1.32-3.18,1.32s-2.33-.47-3.18-1.32c-1.75-1.75-1.75-4.61,0-6.36,.85-.85,1.98-1.32,3.18-1.32M6,0C4.46,0,2.93,.58,1.76,1.75-.59,4.1-.59,7.9,1.76,10.24c1.17,1.17,2.71,1.76,4.24,1.76s3.07-.59,4.24-1.76c2.34-2.34,2.34-6.14,0-8.49C9.07,.58,7.54,0,6,0h0Z" />
            <line className="stroke-current" strokeMiterlimit={10} strokeWidth="1.5px" x1="9.98" y1="2.02" x2="2.02" y2="9.98" />
        </svg>
    );
}

export default UnconfirmedMember;