import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState } from "react"
import Skeleton from "react-loading-skeleton";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { bool, object, string } from "yup";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import { I18nContext } from "../../contexts/I18nContext";
import { TeamsContext } from "../../contexts/TeamsContext"
import { DisplayI18n } from "../../helpers/i18nHelper";
import { DisplayCategory } from "../../views/teams/TeamHelpers";
import CustomAnimatedIcon from "../customAnimatedIcon/CustomAnimatedIcon";
import Required from "../formik/Required";
import GenderLabel from "../GenderLabel";
import QuickViewButton from "../quickView/QuickViewButton";
import UserDisplay from "../userDisplay/UserDisplay";
import UserImg from "../UserImg";

const ValidateMemberInner = ({ member, team, selected, addMember }) => {
    const teamsContext = useContext(TeamsContext);
    const i18nContext = useContext(I18nContext);

    const memberTypes = {
        PLAYER: 'PLAYER',
        COACH: 'COACH',
        STAFF: 'STAFF',
    }

    const [ data, setData ] = useState(null);

    return (
        <Formik
            initialValues={{
                teamId: team?.team_id || '',
                uniqueIdentifier: member?.unique_identifier || '',
                memberType: memberTypes.PLAYER,
                asAffiliateParticipant: "0",
            }}
            validationSchema={object().shape({
                teamId: string().required(<Translate id='components.validateMember.team.required' />),
                uniqueIdentifier: string().required(<Translate id='components.validateMember.uniqueIdentifier.required' />),
                memberType: string().required(<Translate id='components.validateMember.memberType.required' />),
                asAffiliateParticipant: bool(),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                setData(null);
                teamsContext.validateMember(values.teamId, values.uniqueIdentifier, values.memberType, values.asAffiliateParticipant === "1")
                    .then((response) => {
                        setData(response);
                        setSubmitting(false);
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            setSubmitting(false);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <Row form className="mb-3">
                        <Col sm='6'>
                            <FormGroup>
                                <Label className='text-muted' for='uniqueIdentifier'><Translate id='components.validateMember.uniqueIdentifier' /> <Required /></Label>
                                <FormikInputText id='uniqueIdentifier' name='uniqueIdentifier' trim disabled={!!member} />
                            </FormGroup>
                        </Col>
                        <Col sm='6'>
                            <FormGroup>
                                <Label className='text-muted' for='teamId'><Translate id='components.validateMember.team' /> <Required /></Label>
                                <FormikSelect
                                    id='teamId' name='teamId'
                                    disabled={!!team}
                                    renderOption={({ option }, fromSelectedOption) => (
                                        <div className='d-flex align-items-center'>
                                            <div className="d-flex justify-content-center align-items-center mr-2">
                                                <UserImg
                                                    abbr={option.short_name || option.label || ''}
                                                    src={option.logo?.full_path}
                                                    filePos={option.logo?.file_position}
                                                    width={!fromSelectedOption ? '40' : '20'}
                                                    height={!fromSelectedOption ? '40' : '20'}
                                                    alt={option.label + ' logo'}
                                                    className="p-1"
                                                />
                                            </div>
                                            <div>
                                                <span className='font-medium'>{option.label}</span>
                                                {option.short_name &&
                                                    <span className='text-muted ml-1'>({option.short_name})</span>
                                                }
                                                {option.team_category && !fromSelectedOption &&
                                                    <div className='small text-muted text-truncate'>
                                                        <DisplayCategory category={option.team_category} short />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )}
                                    searchKeys={[
                                        'short_name',
                                        'team_category.class.name',
                                        'team_category.class.short_name',
                                        `team_category.class.i18n.${i18nContext.getGenericLocale()}.name`,
                                        `team_category.class.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                        'team_category.division.name',
                                        'team_category.division.short_name',
                                        `team_category.division.i18n.${i18nContext.getGenericLocale()}.name`,
                                        `team_category.division.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                    ]}
                                    loadData={(from, extra, spordleTable) => {
                                        switch (from){
                                            case 'CDM':
                                                return teamsContext.getTeams({ limit: 25000 })
                                                    .then((teams) => teams.map((team) => ({
                                                        value: team.team_id,
                                                        label: team.name,
                                                        short_name: team.short_name,
                                                        logo: team.logo,
                                                        team_category: team.team_category,
                                                    })))
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm='6'>
                            <FormGroup>
                                <Label className='text-muted' for='memberType'><Translate id='components.validateMember.memberType' /> <Required /></Label>
                                <FormikSelect
                                    id='memberType' name='memberType'
                                    defaultData={[
                                        { id: memberTypes.PLAYER, label: 'components.validateMember.' + memberTypes.PLAYER, value: memberTypes.PLAYER, translateLabel: true },
                                        { id: memberTypes.COACH, label: 'components.validateMember.' + memberTypes.COACH, value: memberTypes.COACH, translateLabel: true },
                                        { id: memberTypes.STAFF, label: 'components.validateMember.' + memberTypes.STAFF, value: memberTypes.STAFF, translateLabel: true },
                                    ]}
                                    loadingStatus='success'
                                    search={false}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm='6'>
                            <FormGroup>
                                <Label className='text-muted' for='asAffiliateParticipant'><Translate id='components.validateMember.affiliate' /> <Required /></Label>
                                <FormikSelect
                                    id='id-asAffiliateParticipant' name='asAffiliateParticipant'
                                    defaultData={[
                                        { id: "option-yes", label: 'misc.yes', value: "1", translateLabel: true },
                                        { id: "option-no", label: 'misc.no', value: "0", translateLabel: true },
                                    ]}
                                    loadingStatus='success'
                                    search={false}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm='6' />
                        <Col sm='6'>
                            <Button color='primary' type='submit' block><Translate id='components.validateMember.validate' /></Button>
                        </Col>
                    </Row>

                    {(formik.isSubmitting || !!data) &&
                        <div className="border-top mx-n3 px-3">
                            <Card className="card-shadow mt-3 mb-0">
                                {formik.isSubmitting &&
                                    <CardSkeleton />
                                }
                                {!!data &&
                                    <>
                                        <CardBody className="border-bottom">
                                            <UserDisplay block>
                                                <UserDisplay.Container>
                                                    <UserImg
                                                        abbr={`${data.members[0].first_name[0]}${data.members[0].last_name[0]}`}
                                                        src={data.members[0].picture?.full_path}
                                                        filePos={data.members[0].picture?.file_position}
                                                        width={50}
                                                        height={50}
                                                        alt={`${data.members[0].first_name} ${data.members[0].last_name}`}
                                                    />
                                                </UserDisplay.Container>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title className="d-inline-block">
                                                        <QuickViewButton member={data.members[0]}>
                                                            {data.members[0].first_name} {data.members[0].last_name}
                                                        </QuickViewButton>
                                                    </UserDisplay.Title>
                                                    <UserDisplay.Subtitle><DisplayI18n field='name' defaultValue={data.members[0].organisation.organisation_name} i18n={data.members[0].organisation.i18n} /></UserDisplay.Subtitle>
                                                    <UserDisplay.Subtitle><GenderLabel gender={data.members[0].gender} /></UserDisplay.Subtitle>
                                                    <UserDisplay.Subtitle><DateFormat value={data.members[0].birthdate} utc /> (<Translate id='misc.yearsOld' values={{ age: data.members[0].age }} />)</UserDisplay.Subtitle>
                                                </UserDisplay.Container>
                                                <UserDisplay.Container className='ml-auto'>
                                                    <CustomAnimatedIcon
                                                        id='customStatusIcon'
                                                        size='25px'
                                                        icon={data.invalid_reasons.length > 0 ? 'error' : 'checkmark'}
                                                        withCircle
                                                        className={data.invalid_reasons.length > 0 ? 'text-danger' : 'text-success'}
                                                    />
                                                </UserDisplay.Container>
                                            </UserDisplay>
                                        </CardBody>
                                        <CardBody>
                                            {data.invalid_reasons.length > 0 ?
                                                <ul className="mb-0">
                                                    {data.invalid_reasons.map((reason, index) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <li key={index}>
                                                            <span className='text-danger'>
                                                                <DisplayI18n field='message' defaultValue={reason.message} i18n={reason.i18n} />
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                                :
                                                <div className="w-100 text-center">
                                                    <div className="text-dark font-bold"><Translate id='components.validateMember.valid' /></div>

                                                    {/* props received from the team roster add modal */}
                                                    {(selected && addMember) &&
                                                        <Button
                                                            className="mt-2"
                                                            color='primary'
                                                            type='button'
                                                            disabled={selected.find((s) => s.member_id === data.members[0].member_id)}
                                                            onClick={() => {
                                                                addMember({ ...data.members[0], branchSearch: false })
                                                            }}
                                                        >
                                                            <i className="mdi mdi-plus mr-1" /><Translate id='components.validateMember.addMember' />
                                                        </Button>
                                                    }
                                                </div>
                                            }
                                        </CardBody>
                                    </>
                                }
                            </Card>
                        </div>
                    }
                </Form>
            )}
        </Formik>
    )
}

const CardSkeleton = () => {
    return (
        <>
            <CardBody className="border-bottom">
                <UserDisplay block>
                    <UserDisplay.Container>
                        <Skeleton circle width={50} height={50} />
                    </UserDisplay.Container>
                    <UserDisplay.Container>
                        <UserDisplay.Title><Skeleton width={150} height={17} /></UserDisplay.Title>
                        <UserDisplay.Subtitle><Skeleton width={150} height={13} /></UserDisplay.Subtitle>
                        <UserDisplay.Subtitle><Skeleton width={50} height={13} /></UserDisplay.Subtitle>
                        <UserDisplay.Subtitle><Skeleton width={150} height={13} /></UserDisplay.Subtitle>
                    </UserDisplay.Container>
                    <UserDisplay.Container className='ml-auto'>
                        <Skeleton circle width={25} height={25} />
                    </UserDisplay.Container>
                </UserDisplay>
            </CardBody>
            <CardBody>
                <div className="w-100 text-center">
                    <Skeleton width='90%' height={13} />
                </div>
            </CardBody>
        </>
    )
}

export default ValidateMemberInner