// Language
import Translate from '@spordle/intl-elements';
// image
import React from "react";
import { ModalHeader } from 'reactstrap';
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import { IdentityRolesContext } from '../../../../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import withContexts from '../../../../../../helpers/withContexts';
import AddFamilyRelationForm from './AddFamilyRelationForm';
// views
import AddFamilyRelationSearch from './AddFamilyRelationSearch';

class AddFamilyRelation extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            activeView: 'search',
            searchValues: null,
            selectedMember: null,
            loading: false,
        };
    }

    selectMember = (member) => {
        this.setState(() => ({ selectedMember: member, activeView: 'form' }))
    }

    setSearchValues = (values) => {
        this.setState(() => ({ searchValues: values }))
    }

    setLoading = (loading) => {
        this.setState(() => ({ loading: loading }))
    }

    back = () => {
        this.setState(() => ({ activeView: 'search' }))
    }

    render(){
        return (
            <AnalyticsModal analyticsName='AddFamilyRelation' isOpen={this.props.isOpen} onClosed={() => { this.setState(() => ({ selectedMember: null, activeView: 'search', searchValues: null })) }}>
                <OverlayLoader isLoading={this.state.loading}>
                    <ModalHeader toggle={this.props.toggle}>
                        <Translate id='members.profile.overview.memberFamilyRelations.add.title' />
                    </ModalHeader>
                    {this.state.activeView === 'search' &&
                        <AddFamilyRelationSearch
                            selectMember={this.selectMember}
                            setSearchValues={this.setSearchValues}
                            setLoading={this.setLoading}
                            values={this.state.searchValues}
                        />
                    }
                    {this.state.activeView === 'form' &&
                        <AddFamilyRelationForm
                            member={this.state.selectedMember}
                            back={this.back}
                            setLoading={this.setLoading}
                            addRelation={this.props.addRelation}
                            toggle={this.props.toggle}
                            relationAlreadyExists={this.props.relationAlreadyExists}
                            refreshTable={this.props.refreshTable}
                            relations={this.props.relations}
                        />
                    }
                </OverlayLoader>
            </AnalyticsModal>
        )
    }
}

export default withContexts(MembersContext, IdentityRolesContext)(AddFamilyRelation);