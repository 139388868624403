import Translate from '@spordle/intl-elements';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { useContext } from 'react';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';


const MerchantAccontProviderSettingsView = () => {
    const organizationContext = useContext(OrganizationContext)
    return (
        <Row form>
            <Col sm='6' className='mb-3 px-1'>
                <Card className="card-shadow h-100 mb-0 p-1">
                    <CardHeader className="bg-white">
                        <div className="h5 font-bold mb-1"><Translate id="organization.settings.merchantAccounts.setting.name" /></div>
                        <div className="small font-medium mb-0"><Translate id="organization.settings.merchantAccounts.setting.desc" /></div>
                    </CardHeader>
                    <CardBody>
                        {/*  LIST OF MERCHANT ACCOUNTS  */}
                        {(organizationContext.settings.organisation_merchant_account_provider && organizationContext.settings.organisation_merchant_account_provider.value.length > 0) ?
                            <ul className="pl-4">
                                {organizationContext.settings.organisation_merchant_account_provider.value.map((provider) => (
                                    <li key={provider}>
                                        <Translate id={`memberProfile.registration.merchantAccounts.provider.${provider}`} />
                                    </li>
                                ))}
                            </ul>
                            :
                            <Translate id='organization.settings.merchantAccounts.setting.no.selected' />
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default MerchantAccontProviderSettingsView;