import { FormikCurrencyInput, FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { fail } from '@spordle/toasts';
import { Form, Formik } from 'formik';
import { useContext, useState, useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    Label,
    FormGroup,
    Button
} from "reactstrap";
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import CrossFade from '../../../../../../components/crossFade/CrossFade';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

const TravelPermitsSettings = () => {
    const { settings, getOrganizationSettings } = useContext(OrganizationContext);

    const [ isLoading ] = useState(false);
    const [ isEditing, setIsEditing ] = useState(false);
    const toggleIsEditing = () => setIsEditing(!isEditing);

    useEffect(() => {
        getOrganizationSettings()
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, [])

    return (
        <Card body className="card-shadow">
            <OverlayLoader isLoading={!settings || isLoading}>

                <CardSectionTitle title='settings.general.tabs.travelPermits' className='d-flex'>
                    {!isEditing &&
                        <CanDoAction tag={"div"} className="ml-auto text-link" action='EDIT' componentCode='settings' componentPermissionCode='settings_regional'>
                            <button onClick={toggleIsEditing} className="reset-btn align-self-end" type='button'><Translate id='misc.edit' /></button>
                        </CanDoAction>
                    }
                </CardSectionTitle>

                <CrossFade isVisible={!isEditing}>
                    <Row form>
                        <Col sm='12'>
                            <FormGroup>
                                <Label for='travelPermits' className='switch'>
                                    <DisplayI18n field='name' defaultValue={settings?.display_travel_permit?.name} i18n={settings?.display_travel_permit?.i18n} />
                                    <div className='font-medium text-dark'><Translate id={`misc.yes.no.${settings?.display_travel_permit?.value}`} /></div>
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm='12'>
                            <FormGroup>
                                <DisplayI18n field='name' defaultValue={settings?.organisation_travel_permit_fee?.name} i18n={settings?.organisation_travel_permit_fee?.i18n} />
                                <div className='font-medium text-dark'>
                                    {settings?.organisation_travel_permit_fee?.value ?
                                        <CurrencyFormat value={(parseInt(settings?.organisation_travel_permit_fee?.value)) / 100} />
                                        :
                                        '-'}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </CrossFade>

                <CrossFade isVisible={isEditing}>
                    <TravelPermitsSettingsForm isLoading={isLoading} toggleIsEditing={toggleIsEditing} />
                </CrossFade>

            </OverlayLoader>
        </Card>
    )
};


const TravelPermitsSettingsForm = ({ isLoading, toggleIsEditing }) => {
    const { settings, updateSettings, organisation_id } = useContext(OrganizationContext);

    return (
        <Formik
            initialValues={{
                display_travel_permit: settings?.display_travel_permit?.value,
                organisation_travel_permit_fee: settings?.organisation_travel_permit_fee?.value ? ((parseInt(settings?.organisation_travel_permit_fee?.value)) / 100) : '',
            }}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                setSubmitting(true);
                const submitData = { ...values };
                if(submitData.organisation_travel_permit_fee){
                    submitData.organisation_travel_permit_fee = (submitData.organisation_travel_permit_fee * 100).toFixed(0);
                }
                updateSettings(organisation_id, {
                    display_travel_permit: submitData.display_travel_permit,
                    organisation_travel_permit_fee: submitData.organisation_travel_permit_fee,
                })
                    .then(async() => {
                        setSubmitting(false);
                        toggleIsEditing();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                        setSubmitting(false);
                        toggleIsEditing();
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting || isLoading}>
                        <Row>
                            <Col sm='6'>
                                <FormGroup>
                                    <Label for='travelPermits' className='switch'>
                                        <DisplayI18n field='name' defaultValue={settings?.display_travel_permit?.name} i18n={settings?.display_travel_permit?.i18n} />
                                    </Label>
                                    <FormikSelect
                                        id='id-display_travel_permit' name='display_travel_permit'
                                        defaultData={[
                                            { id: "option-yes", label: 'misc.yes', value: "1", translateLabel: true },
                                            { id: "option-no", label: 'misc.no', value: "0", translateLabel: true },
                                        ]}
                                        loadingStatus='success'
                                        search={false}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='6'>
                                <FormGroup>
                                    <DisplayI18n field='name' defaultValue={settings?.organisation_travel_permit_fee?.name} i18n={settings?.organisation_travel_permit_fee?.i18n} />
                                    <FormikCurrencyInput
                                        id='organisation_travel_permit_fee'
                                        name='organisation_travel_permit_fee'
                                        className="mb-1"
                                        allowNegative={false}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <div className="text-right">
                            <Button color="primary" className="mx-2" type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                            <Button color="primary" outline onClick={toggleIsEditing} type='button' disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                        </div>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default TravelPermitsSettings;