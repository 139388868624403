export default{
    "catalog.forms.components.formEditor.questionType.questionTypePicker.TEXT"            : "Short answer",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.TEXT_AREA"       : "Long answer",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.RADIO"           : "Radio button",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.CHECKBOX"        : "Checkboxes",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.SELECTBOX"       : "Simple selection",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.SELECTBOX_MULTI" : "Multiple selection",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.DATE"            : "Date",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.DATETIME"        : "Time",
    "catalog.forms.components.formEditor.questionType.questionTypePicker.NUMBER"          : "Number",
}