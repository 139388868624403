import { useSpordleTable } from "@spordle/datatables";
import { FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { Badge, Button, Card, Col, Label, Row, Spinner } from "reactstrap";
import CrossFade from "../../../components/crossFade/CrossFade";
import useSavedSearch from "../../../components/customHooks/useSavedSearch";
import SavedSearchCue from "../../../components/savedSearchCue/SavedSearchCue";
import { PeriodsContext } from "../../../contexts/contexts";
import { I18nContext } from "../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { stringifyJSON } from "../../../helpers/helper";
import { DisplayI18n, displayI18n } from "../../../helpers/i18nHelper";
import { DisplayTeamCategory } from "../TeamHelpers";
import TeamsSearchFiltersCard from "./TeamsSearchFiltersCard";

const CompetitionTeamsSearchFilters = () => {
    const INIT_FILTER_VALUES = {
        organisation_id: "",
        competition_id: "",
        competition_team_status_id: "",
    }
    const periodsContext = useContext(PeriodsContext);
    const organizationsContext = useContext(OrganizationContext);
    const { identity_role_id } = useContext(IdentityRolesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const table = useSpordleTable();
    const { savedSearch, clearSavedSearch, saveSearch } = useSavedSearch(`affiliated-team-search-filters-${organizationsContext.organisation_id}-${identity_role_id}-${periodsContext.selectedPeriod.period_id}`);
    const { status, competitions } = table.getAdditionalData();
    const filtersAreDifferent = (values) => stringifyJSON(INIT_FILTER_VALUES) !== stringifyJSON(values);

    return (
        <Formik
            initialValues={{
                ...INIT_FILTER_VALUES,
                ...savedSearch,
            }}
            onSubmit={(values) => {
                table.filterChange('search', values);

                if(filtersAreDifferent(values)){
                    saveSearch(values);
                }else{
                    clearSavedSearch();
                }
            }}
        >
            {(formik) => (
                <Form>
                    <Row form>
                        {(!status && table.state.loadingState === "loading") ?
                            Array.from({ length: 3 }).map((_, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Col md={3} key={i + 'status-loading'}>
                                    <Card className="card-shadow mb-3 p-3 text-center" body>
                                        <div className="font-weight-bold h2 mb-2">
                                            <Spinner type="grow" color="primary" style={{ height: "1em", width: "1em" }} />
                                        </div>
                                        <div>
                                            <Badge color="light">
                                                <Translate id="misc.loading" />
                                            </Badge>
                                        </div>
                                    </Card>
                                </Col>
                            ))
                            :
                            (status || []).map((stat) => (
                                <Col md={3} key={stat.competition_team_status_id}>
                                    <TeamsSearchFiltersCard status={stat} />
                                </Col>
                            ))
                        }
                    </Row>
                    <Card className="card-shadow" body>
                        <div className="mb-n3">
                            <Row form>
                                <Col sm={3} className="mb-3">
                                    <Label for="affiliated-team-org" className="text-muted">
                                        <Translate id='members.search.memberAdvanceSearch.filters.organization' />
                                    </Label>
                                    <FormikSelect
                                        id="affiliated-team-org"
                                        name="organisation_id"
                                        clearable
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return organizationsContext.getAffiliatedOrganizations(organizationsContext.organisation_id)
                                                        .then((affiliatedOrgs) => {
                                                            const collator = new Intl.Collator(getGenericLocale(), { sensitivity: 'base' });

                                                            const allorgs = affiliatedOrgs.reduce((all, affiliatedOrg) => {
                                                                const org = affiliatedOrg.linked_organisation;

                                                                if(!!org && org.organisation_id !== organizationsContext.organisation_id && !(all.some(({ value }) => value == org.organisation_id))){
                                                                    all.push({
                                                                        ...org,
                                                                        label: org.organisation_name,
                                                                        value: org.organisation_id,
                                                                    });
                                                                }
                                                                return all;
                                                            }, [])
                                                                .sort((a, b) => {
                                                                    const aName = displayI18n("name", a.i18n, a.label, getGenericLocale()) || "";
                                                                    const bName = displayI18n("name", b.i18n, b.label, getGenericLocale()) || "";

                                                                    return collator.compare(aName, bName);

                                                                });

                                                            return allorgs;
                                                        })
                                                default:
                                                    break;
                                            }
                                        }}
                                    />
                                </Col>
                                <Col sm={3} className="mb-3">
                                    <Label for="affiliated-team-competition" className="text-muted"><Translate id="teams.teamSearch.competition" /></Label>
                                    <FormikSelect
                                        id="affiliated-team-competition"
                                        name="competition_id"
                                        clearable
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                            `division.i18n.${getGenericLocale()}.name`,
                                            `team_category.i18n.${getGenericLocale()}.name`,
                                        ]}
                                        renderOption={({ option, isSelected }) => (
                                            <>
                                                <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />
                                                <div className={`small text-${isSelected ? 'light' : 'muted'}`}><DisplayTeamCategory ressource={option} /></div>
                                            </>
                                        )}
                                        renderSelectedOption={(option) => (
                                            <>
                                                <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />
                                                <small className="ml-1 text-muted">(<DisplayTeamCategory ressource={option} />)</small>
                                            </>
                                        )}
                                        isLoading={!competitions}
                                        options={competitions ? competitions.map((competition) => ({
                                            ...competition,
                                            label: competition.name,
                                            value: competition.competition_id,
                                        })) : null}
                                    />
                                </Col>
                                <Col sm={3} className="mb-3">
                                    <Label for="affiliated-team-competition" className="text-muted"><Translate id="misc.status" /></Label>
                                    <FormikSelect
                                        id="affiliated-team-status"
                                        clearable
                                        name="competition_team_status_id"
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        isLoading={!status}
                                        options={status ? status.map((stat) => ({
                                            ...stat,
                                            value: stat.competition_team_status_id,
                                            label: stat.name,
                                        })) : null}
                                    />
                                </Col>
                                <Col sm={3} className="ml-auto align-self-end mb-3">
                                    <Button
                                        block
                                        type="submit"
                                        color="primary"
                                    >
                                        <i className="fas fa-search mr-1" /><Translate id="misc.search" />
                                    </Button>
                                </Col>
                            </Row>
                            <div className="d-flex flex-wrap flex-md-nowrap">
                                <SavedSearchCue
                                    className="order-1 order-md-0 mt-3 pb-3 mt-md-0 w-100 w-md-auto mr-auto"
                                    savedSearch={savedSearch}
                                    clearSavedSearch={() => {
                                        clearSavedSearch();
                                        formik.setValues(INIT_FILTER_VALUES);
                                    }}
                                />
                                <CrossFade className="w-100 w-md-auto text-right ml-auto" isVisible={filtersAreDifferent(formik.values)}>
                                    <button
                                        type='button'
                                        className='reset-btn text-danger font-12 pb-3'
                                        onClick={() => {
                                            clearSavedSearch();
                                            formik.setValues(INIT_FILTER_VALUES);
                                        }}
                                    >
                                        <Translate id='members.search.memberAdvanceSearch.resetFilters' />
                                    </button>
                                </CrossFade>
                            </div>
                        </div>
                    </Card>
                </Form>
            )}
        </Formik>
    );
}

export default CompetitionTeamsSearchFilters;