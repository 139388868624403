import Translate from '@spordle/intl-elements';
import { Form, Formik, useFormikContext } from 'formik';
import { useContext } from 'react';
import { Button, InputGroup, InputGroupAddon, Label, ModalBody, ModalFooter } from 'reactstrap';
import { number, object, string } from 'yup';
import { CartsContext } from '../../contexts/CartsContext';
import { DisplayI18n } from '../../helpers/i18nHelper';
import Required from '../formik/Required';
import UserDisplay from '../userDisplay/UserDisplay';
import UserImg from '../UserImg';
import { stringBuilder } from "@spordle/helpers";
import { FormikCurrencyInput, FormikError } from '@spordle/formik-elements';
import OverlayLoader from '../loading/OverlayLoader';
import NoImgItem from '../../assets/images/NoImage_Item.svg';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { Tooltip } from '@mantine/core';

const ManualRegistrationOtherItems = ({ memberProfileRegistrationAddContext, member }) => {

    const cartsContext = useContext(CartsContext)

    const getInitialValues = () => {
        const temp = {};
        memberProfileRegistrationAddContext.viewsInfo.otherItems?.forEach((item, index) => {
            temp[index] = {
                amount: parseInt(memberProfileRegistrationAddContext.viewsInfo.selectedOtherItems.find((otherItem) => otherItem.itemId === item.other_fee_id)?.quantity) || 0,
                itemId: item.other_fee_id,
                price: parseInt(item.fee.amount) / 100,
            }
        })
        return temp
    }

    const getValidationSchema = () => {
        const temp = {};
        memberProfileRegistrationAddContext.viewsInfo.otherItems?.forEach((item, index) => {
            if(!memberProfileRegistrationAddContext.viewsInfo.selectedRegistration && index === 0){
                temp[index] = object().shape({
                    amount: number().min(0, '').test({
                        name: 'atLeastOneTest',
                        message: <Translate id='components.manualRegistration.manualRegistrationOtherItems.required' />,
                        test: function(){
                            return !Object.keys(this.from[this.from.length - 1].value).every((key) => this.from[this.from.length - 1].value[key].amount === 0)
                        },
                    }),
                    price: number().required(<Translate id='form.validation.price.required' />),
                    itemId: string().required(),
                })
            }else{
                temp[index] = object().shape({
                    amount: number().min(0, ''),
                    price: number().required(<Translate id='form.validation.price.required' />),
                    itemId: string().required(),
                })
            }
        })
        return object().shape(temp)
    }

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={getValidationSchema()}
            onSubmit={async(values, { setSubmitting }) => {
                //create the items in the cart
                let cartId = cartsContext.state.cachedCart.shopping_cart_id;
                for(const createKey in values){
                    if(values[createKey].amount > 0 && !memberProfileRegistrationAddContext.viewsInfo.selectedOtherItems.find((otherItem) => otherItem.itemId === values[createKey].itemId)){
                        cartId = await cartsContext.createCart({
                            shopping_cart_id: cartId,
                            item: {
                                item_id: values[createKey].itemId,
                                type: 'OTHER',
                                member_id: member.member_id,
                                custom_price: values[createKey].price * 100,
                            },
                        })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            })

                        memberProfileRegistrationAddContext.viewsInfo.selectedOtherItems.push({
                            itemId: values[createKey].itemId,
                            quantity: 1,
                        })
                    }
                }

                await cartsContext.getCart(cartId)
                    .then(async(cart) => {
                        // check if we need to patch the quantity
                        for(const updateKey in values){
                            const cartItem = cart.cart_detail.find((r) => r.item_id === values[updateKey].itemId && r.member.member_id === member.member_id);
                            if(cartItem){
                                if(values[updateKey].amount !== parseInt(cartItem.quantity)){ // other items
                                    if(values[updateKey].amount > 0){
                                        await cartsContext.updateCart(cartId, cartItem.row_id, {
                                            quantity: values[updateKey].amount,
                                        })
                                            .then(() => {
                                                // because findIndex is not supported on all browsers
                                                const index = memberProfileRegistrationAddContext.viewsInfo.selectedOtherItems
                                                    .map((otherItem) => otherItem.itemId)
                                                    .indexOf(values[updateKey].itemId);

                                                memberProfileRegistrationAddContext.viewsInfo.selectedOtherItems[index] = {
                                                    itemId: values[updateKey].itemId,
                                                    quantity: values[updateKey].amount,
                                                }
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                }
                                            })
                                    }else if(values[updateKey].amount === 0){
                                        await cartsContext.deleteCartItem(cartId, cartItem.row_id)
                                            .then(() => {
                                                memberProfileRegistrationAddContext.viewsInfo.selectedOtherItems.filter((otherItem) => otherItem.itemId !== values[updateKey].itemId)
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                }
                                            })
                                    }
                                }
                            }
                        }
                    })
                    .then(async() => {
                        await cartsContext.getCart(cartId)
                            .then(async(cart) => {
                                setSubmitting(false)
                                memberProfileRegistrationAddContext.viewsInfo.cartPrice = parseInt(cart.cart_summary.grand_total)
                                memberProfileRegistrationAddContext.goToView(memberProfileRegistrationAddContext.views.paymentMethods)
                            })
                    })
                    .catch((error) => {
                        console.error(error.message)
                        if(!AxiosIsCancelled(error.message)){
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalBody>
                            <Label>
                                <Translate id='components.manualRegistration.manualRegistrationOtherItems' />
                                {!memberProfileRegistrationAddContext.viewsInfo.selectedRegistration &&
                                    <span className='ml-1'><Required /></span>
                                }
                            </Label>
                            <div className="sid-item-list-container">
                                <ul className="list-unstyled sid-item-list mb-3">
                                    {memberProfileRegistrationAddContext.viewsInfo.otherItems?.map((otherItem, index) => (
                                        <OtherItemCard key={otherItem.other_fee_id} item={otherItem} index={index} />
                                    ))}
                                </ul>
                            </div>
                            <FormikError name={`0.amount`} />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color='primary' outline
                                type='button'
                                onClick={() => memberProfileRegistrationAddContext.goToView(memberProfileRegistrationAddContext.views.registrationInfo)}
                                className="mr-auto"
                            >
                                <Translate id='misc.previous' />
                            </Button>
                            <Button color='primary' type='submit'><Translate id='misc.next' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

const OtherItemCard = ({ item, index }) => {
    const formik = useFormikContext();

    return (
        <li className='mb-2'>
            <UserDisplay card block>
                <UserDisplay.Container>
                    <UserImg
                        className="rounded-lg border shadow-none mr-2"
                        width={80}
                        src={item.image?.full_path || NoImgItem}
                        filePos={item.image?.file_position}
                        alt={item?.fee?.name}
                    />
                </UserDisplay.Container>
                <UserDisplay.Container className='w-100'>
                    <div className='mb-2'>
                        <DisplayI18n field='name' defaultValue={item.fee.name || item.name} i18n={item.fee.i18n} />
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='mr-1'>
                            <InputGroup>
                                <FormikCurrencyInput name={`${index}.price`} id={`${index}.price`} placeholder='misc.price' manualError />
                                <InputGroupAddon addonType='append'>
                                    <Tooltip
                                        withArrow
                                        position='top'
                                        label={<Translate id='components.manualRegistration.manualRegistrationOtherItems.revertprice' />}
                                        className='h-100'
                                    >
                                        <Button className='h-100' color='light' size='sm' type='button' onClick={() => { formik.setFieldValue(`${index}.price`, item.fee.amount / 100) }}>
                                            <i className='mdi mdi-replay' />
                                        </Button>
                                    </Tooltip>
                                </InputGroupAddon>
                            </InputGroup>
                            <FormikError name={`${index}.price`} />
                            {!!item.sold_out &&
                                <div className='small text-danger text-center'><Translate id='components.manualRegistration.manualRegistrationOtherItems.soldOut' /></div>
                            }
                        </div>
                        <div className='flex-shrink-0'>
                            <button
                                id={'registrationSelectItemsOptionnalItemsReduceQuantity-' + item.other_fee_id}
                                className="btn btn-primary sid-item-number-btn" type='button'
                                onClick={() => formik.setFieldValue(`${index}[amount]`, formik.values[index].amount - 1)}
                                disabled={formik.values[index].amount <= 0 || item.sold_out}
                            >
                                <i className="mdi mdi-minus" />
                            </button>
                            <span className={stringBuilder("sid-item-number", { 'text-muted': formik.values[index].amount <= 0 })}>
                                {formik.values[index].amount.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
                            </span>
                            <button
                                id={'registrationSelectItemsOptionnalItemsIncreaseQuantity-' + item.other_fee_id}
                                className="btn btn-primary sid-item-number-btn" type='button'
                                onClick={() => formik.setFieldValue(`${index}[amount]`, formik.values[index].amount + 1)}
                                disabled={formik.values[index].amount === item.available_place || item.sold_out}
                            >
                                <i className="mdi mdi-plus" />
                            </button>
                        </div>
                    </div>
                </UserDisplay.Container>
            </UserDisplay>
        </li>
    )
}

export default ManualRegistrationOtherItems