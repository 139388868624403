import { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';

import Translate, { CurrencyFormat } from '@spordle/intl-elements';

import { DisplayI18n } from '../../../../helpers/i18nHelper';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';

import { stringBuilder } from "@spordle/helpers";
import UserImg from '../../../../components/UserImg';
import NoImgItem from '../../../../assets/images/NoImage_Item.svg'

const SidePanelOnlineStoreRegistrationsTab = (props) => {

    const [ registrationFeesCollapse, setRegistrationFeesCollapse ] = useState(props.openedRegistrations)
    const toggleRegistrationFees = () => setRegistrationFeesCollapse(!registrationFeesCollapse)

    const [ otherFeesCollapse, setOtherFeesCollapse ] = useState(props.openedOtherItems)
    const toggleOtherFees = () => setOtherFeesCollapse(!otherFeesCollapse)

    useEffect(() => {
        setRegistrationFeesCollapse(props.openedRegistrations)
        setOtherFeesCollapse(props.openedOtherItems)
    }, [ props.openedRegistrations, props.openedOtherItems ])

    return (
        <>
            <div className="border-bottom">
                <div className="p-3 d-flex clickable" onClick={toggleRegistrationFees}>
                    <div>
                        <div className="h4 font-bold mb-0"><Translate id='onlineStore.search.sidepanel.title.registrations.listed' /></div>
                        <div className="small">
                            {props.registrationFees.length > 0 ?
                                <span><Translate id='onlineStore.search.sidepanel.registrations.number' values={{ num: props.registrationFees.length }} /></span>
                                :
                                <span><Translate id='onlineStore.search.sidepanel.registrations.none' /></span>
                            }
                        </div>
                    </div>
                    <i className={`mdi ml-auto font-22 ${registrationFeesCollapse ? 'mdi-chevron-down' : 'mdi-chevron-up'}`} />
                </div>
                <Collapse tag="ul" className="list-unstyled mb-0 px-3" isOpen={registrationFeesCollapse}>
                    {props.registrationFees.map((registrationFee) => (
                        <li key={registrationFee.registration_fee.registration_fee_id} className='mb-3'>
                            <div className="d-flex align-items-center">
                                <div className="font-bold h6 mb-0">
                                    {/* <DisplayI18n field='name' i18n={registrationFee.registration_fee.i18n} defaultValue={registrationFee.registration_fee.name}/> */}
                                    <DisplayI18n
                                        field='short_name'
                                        defaultValue={registrationFee.registration_fee?.division?.short_name}
                                        i18n={registrationFee.registration_fee?.division?.i18n}
                                    />
                                    {registrationFee.registration_fee?.team_category?.class &&
                                        <>
                                            <span className='mx-1'>-</span>
                                            <DisplayI18n
                                                field='short_name'
                                                defaultValue={registrationFee.registration_fee?.team_category?.class?.short_name}
                                                i18n={registrationFee.registration_fee?.team_category?.class?.i18n}
                                            />
                                        </>
                                    }
                                </div>
                                {registrationFee.item_sold > 0 &&
                                    <>
                                        <div className="mx-2">|</div>
                                        <div className="font-medium text-dark text-nowrap"><Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.registration.sold' values={{ amount: registrationFee.item_sold }} /></div>
                                    </>
                                }
                            </div>
                            <span className='small'>
                                {registrationFee.registration_fee.available_place != null ?
                                    <Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.registration.available' values={{ amount: registrationFee.registration_fee.available_place }} />
                                    :
                                    <Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.registration.unlimited' />
                                } - <span className={stringBuilder({ 'text-success': registrationFee.status === 'ACTIVE', 'text-danger': registrationFee.status === 'SOLD_OUT' })}><Translate id={`onlineStore.search.sidepanel.status.${registrationFee.status}`} /></span>
                            </span>
                            <div className="small">
                                <CurrencyFormat value={registrationFee.registration_fee.fee.amount / 100} />
                            </div>
                        </li>
                    ))}
                </Collapse>
            </div>
            <div className="border-bottom">
                <div className="p-3 d-flex clickable" onClick={toggleOtherFees}>
                    <div>
                        <div className="h4 font-bold mb-0"><Translate id='onlineStore.search.sidepanel.title.otherItems.listed' /></div>
                        <div className="small">
                            {props.otherFees.length > 0 ?
                                <span><Translate id='onlineStore.search.sidepanel.otherItems.number' values={{ num: props.otherFees.length }} /></span>
                                :
                                <span><Translate id='onlineStore.search.sidepanel.otherItems.none' /></span>
                            }
                        </div>
                    </div>
                    <i className={`mdi ml-auto font-22 ${otherFeesCollapse ? 'mdi-chevron-down' : 'mdi-chevron-up'}`} />
                </div>
                <Collapse tag="ul" className="list-unstyled mb-0 px-3" isOpen={otherFeesCollapse}>
                    {props.otherFees.map((otherItem) => (
                        <li key={otherItem.other_fee.other_fee_id} className='mb-3'>
                            <UserDisplay>
                                <UserDisplay.Container>
                                    <UserImg
                                        className="rounded-lg border shadow-none"
                                        width={60}
                                        src={otherItem.other_fee.image?.full_path || NoImgItem}
                                        filePos={otherItem.other_fee.image?.file_position}
                                        alt={otherItem.other_fee.name}
                                    />
                                </UserDisplay.Container>
                                <UserDisplay.Container className="min-w-0">
                                    <div className="d-flex align-items-center">
                                        <div className="font-bold h6 mb-0"><DisplayI18n i18n={otherItem.other_fee.fee.i18n} field='name' defaultValue={otherItem.other_fee.fee.name} /></div>
                                        {otherItem.item_sold > 0 &&
                                            <>
                                                <div className="mx-2">|</div>
                                                <div className="font-medium text-dark text-nowrap"><Translate id='onlineStore.components.onlineStoreItemsFees.registration.table.registration.sold' values={{ amount: otherItem.item_sold }} /></div>
                                            </>
                                        }
                                    </div>
                                    <UserDisplay.Subtitle><DisplayI18n i18n={otherItem.other_fee.fee.i18n} field='description' defaultValue={otherItem.other_fee.fee.description} /></UserDisplay.Subtitle>
                                    <UserDisplay.Subtitle>
                                        <span className={stringBuilder('text-success')}><Translate id={`onlineStore.search.sidepanel.status.ACTIVE`} /></span>
                                        {/* <span className={stringBuilder({'text-success': otherItem.other_fee.status === 'ACTIVE', 'text-danger': otherItem.other_fee.status === 'SOLD_OUT'})}><Translate id={`onlineStore.search.sidepanel.status.${otherItem.other_fee.status}`}/></span> */}
                                    </UserDisplay.Subtitle>
                                    <div className="small">
                                        <CurrencyFormat value={otherItem.other_fee.fee.amount / 100} />
                                    </div>
                                </UserDisplay.Container>
                            </UserDisplay>
                        </li>
                    ))}
                </Collapse>
            </div>
        </>
    )
}

export default SidePanelOnlineStoreRegistrationsTab;