import { createContext, useContext, useState } from "react";
import useSWR from "swr";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { NotificationsContext } from "../../../../../contexts/NotificationsContext";


/** @type {React.Context<Omit<NotificationsSettingsDataManager, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const NotificationsSettingsDataManager = createContext();
NotificationsSettingsDataManager.displayName = 'NotificationsSettingsDataManager';

const NotificationsSettingsDataManagerProvider = (props) => {
    const { getNotificationSubscriptions, updateNotificationSubscriptions } = useContext(NotificationsContext);
    const { identity_role_id } = useContext(IdentityRolesContext);
    const { data: subscriptionsGroups, isValidating, mutate } = useSWR(
        [ 'getNotificationSubscriptions', identity_role_id ],
        () => getNotificationSubscriptions(),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            onError: console.error,
        },
    );
    const [ updateState, setUpdateState ] = useState('init');
    const initFormik = splitInGroups(subscriptionsGroups || []);

    /**
     * @description Function that regroups notifs with same component
     * @param {object[]} subs
     * @returns {array}
     */
    function splitInGroups(subs){
        return subs.reduce((subsGroups, sub) => {
            if(sub.is_mandatory != "1"){ // Cannot update mandatory

                const groupIndex = subsGroups.findIndex((group) => group?.component?.component_id === sub.component.component_id);
                sub.subscribed = sub.subscribed == 1;

                if(groupIndex !== -1){
                    subsGroups[groupIndex].subscriptions.push(sub);
                }else{
                    subsGroups.push({ subscriptions: [ sub ], component: sub.component })
                }
            }

            return subsGroups;
        }, []);
    }

    /**
     * @description Compares the initial array with the new array to see which values changed
     * @param {object[]} initVal
     * @param {object[]} newVal
     * @return {object[]} diffVal
     */
    const getAndFormatDiffValues = (initValues, newValues) => {
        const diffVal = [];

        for(let g = 0; g < newValues.length; g++){ // Each group
            const newGroup = newValues[g];
            const initGroup = initValues[g];

            for(let n = 0; n < newGroup.subscriptions.length; n++){ // Each notif
                const newVal = newGroup.subscriptions[n];
                const initVal = initGroup.subscriptions[n];

                if(initVal.subscribed != newVal.subscribed){
                    diffVal.push(newVal);
                }
            }
        }

        return diffVal;
    }

    const onSubmit = (subscriptionsGroups, setSubmitting, setStatus) => {
        const changedValues = getAndFormatDiffValues(initFormik, subscriptionsGroups);
        setUpdateState('loading');
        setStatus(null);
        if(changedValues.length > 0){
            updateNotificationSubscriptions(changedValues)
                .then(() => {
                    setSubmitting(false);
                    mutate();
                    setUpdateState('success');
                })
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e);
                        setUpdateState('error');
                        setSubmitting(false);
                        setStatus(e);
                    }
                });
        }else{
            setUpdateState('success');
            setSubmitting(false);
        }
    }

    return (
        <NotificationsSettingsDataManager.Provider
            value={{
                initFormik: initFormik,
                onSubmit: onSubmit,
                updateState: updateState,
                isValidating: isValidating,
            }}
        >
            {props.children}
        </NotificationsSettingsDataManager.Provider>
    )
}

export default NotificationsSettingsDataManagerProvider