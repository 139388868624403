import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Button, FormGroup } from "reactstrap";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import OverlayLoader from "../../../components/loading/OverlayLoader";
import SidePanel from "../../../components/sidePanel/SidePanel"
import { fail, success } from '@spordle/toasts';
import { AuthContext } from "../../../contexts/contexts";
import UsersSettingsReactivateModal from "./modals/UsersSettingsReactivateModal";
import UsersSettingsUnlockModal from "./modals/UsersSettingsUnlockModal";
import UsersSettingsConfirmModal from "./modals/UsersSettingsConfirmModal";
import { DisplayI18n } from "../../../helpers/i18nHelper";

const UsersSettingsSidePanel = ({ selectedRows, getCommonValue, syncRows, createNewValues, ...props }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ modalOpen, setModalOpen ] = useState({
        reactivate: false,
        unlock: false,
        confirm: false,
    })
    const [ userData, setUserData ] = useState({});
    const { getUser, createUser, unlockUser, adminConfirmSignup } = useContext(AuthContext);

    const toggleModal = (modal) => setModalOpen({ [modal]: !modalOpen[modal] })

    const getUserInfo = () => {
        return getUser(selectedRows[0].identity_id)
            .then((data) => {
                data.reactivate = data.UserStatus === 'FORCE_CHANGE_PASSWORD';
                data.unconfirmed = data.UserStatus === 'UNCONFIRMED';
                setUserData(data);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    const getUserValue = (name) => {
        if(userData[name]){
            return userData[name];
        }
        return null;
    }

    /////////////////////////////////////////////////////////////////////////////
    // UNLOCK ACCOUNT LOGIC /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    const handleReactivate = () => {
        return createUser({
            ...userData,
            action: 'RESEND',
        })
            .then(() => {
                setUserData((prev) => ({ ...prev, reactivate: false }));
                success();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    if(error.i18n){
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }else{
                        switch (error.message){
                            case 'UnsupportedUserStateException':
                                fail({ msg: 'organization.profile.users.sidePanelUsers.reactivate.error.activeAccount' });
                                break;
                            default:
                                fail({ msg: 'organization.profile.users.sidePanelUsers.reactivate.error' });
                                break;
                        }
                    }
                }
            })
    }

    const handleUnlock = () => {
        return unlockUser(userData.Username)
            .then(() => {
                syncRows(createNewValues({ signin_lock_date: null }));
                setUserData((prev) => ({ ...prev, signin_lock_date: null }));
                success();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    switch (error.message){
                        default:
                            fail();
                            break;
                    }
                }
            })
    }

    const handleAdminConfirmSignup = () => {

        return adminConfirmSignup(userData.Username)
            .then(() => {
                syncRows(createNewValues({ unconfirmed: null }));
                setUserData((prev) => ({ ...prev, unconfirmed: null }));
                success()
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    switch (error.message){
                        default:
                            fail()
                            break;
                    }
                }
            });
    }

    useEffect(() => {
        setIsLoading(true)
        getUserInfo().then(() => setIsLoading(false));

    }, [ selectedRows[0].identity_id ]);

    return (
        <OverlayLoader isLoading={isLoading}>
            <SidePanel.Header>
                <div className="d-flex mb-2">
                    <SidePanel.ToggleButton />
                    {(getUserValue("reactivate") || getUserValue("signin_lock_date")) &&
                        <SidePanel.ActionsMenu>
                            {getUserValue("reactivate") &&
                                <SidePanel.MenuAction onClick={() => toggleModal('reactivate')}>
                                    <Translate id='organization.profile.users.sidePanelUsers.header.reactivate' />
                                </SidePanel.MenuAction>
                            }
                            {getUserValue("signin_lock_date") !== null &&
                                <SidePanel.MenuAction onClick={() => toggleModal('unlock')}>
                                    <Translate id='organization.profile.users.sidePanelUsers.header.unlock' />
                                </SidePanel.MenuAction>
                            }
                            {getUserValue("signin_lock_date") !== null &&
                                <SidePanel.MenuAction onClick={() => toggleModal('unlock')}>
                                    <Translate id='organization.profile.users.sidePanelUsers.header.unlock' />
                                </SidePanel.MenuAction>
                            }
                        </SidePanel.ActionsMenu>
                    }
                </div>
                <SidePanel.Title>{selectedRows[0].name} {selectedRows[0].family_name}</SidePanel.Title>
                <SidePanel.Subtitle>{selectedRows[0].email}</SidePanel.Subtitle>
            </SidePanel.Header>
            <SidePanel.Body>
                <UsersSettingsReactivateModal user={selectedRows[0].name + ' ' + selectedRows[0].family_name} onConfirm={handleReactivate} isOpen={modalOpen.reactivate} toggle={() => toggleModal('reactivate')} />
                <UsersSettingsUnlockModal user={selectedRows[0].name + ' ' + selectedRows[0].family_name} onConfirm={handleUnlock} isOpen={modalOpen.unlock} toggle={() => toggleModal('unlock')} />
                <UsersSettingsConfirmModal user={selectedRows[0].name + ' ' + selectedRows[0].family_name} onConfirm={handleAdminConfirmSignup} isOpen={modalOpen.confirm} toggle={() => toggleModal('confirm')} />

                {/* UNLOCK USER */}
                {getUserValue("reactivate") &&
                    <Button className='mb-3 w-100' outline color='success' type='button' onClick={() => toggleModal('reactivate')}>
                        <Translate id='organization.profile.users.sidePanelUsers.header.reactivate' />
                    </Button>
                }
                {getUserValue("signin_lock_date") !== null &&
                    <Button className='mb-3 w-100' outline color='success' type='button' onClick={() => toggleModal('unlock')}>
                        <Translate id='organization.profile.users.sidePanelUsers.header.unlock' />
                    </Button>
                }
                {getUserValue("unconfirmed") &&
                    <Button className='mb-3 w-100' outline color='success' type='button' onClick={() => toggleModal('confirm')}>
                        <Translate id='organization.profile.users.sidePanelUsers.header.confirm' />
                    </Button>
                }

                {/* STATUS */}
                <FormGroup>
                    <div className="text-muted"><Translate id='misc.status' /></div>
                    <div className="font-medium">
                        {(getCommonValue("active") === "-") ?
                            <span className="mx-2">-</span>
                            :
                            <>
                                <i className={`mdi ${getCommonValue("active") == '1' ? 'mdi-check text-primary' : 'mdi-close text-danger'} mr-2`} />
                                <span><Translate id={`organization.profile.users.sidePanelUsers.userInfo.${getCommonValue("active") == '1' ? 'active' : 'inactive'}`} /></span>
                            </>
                        }
                    </div>
                </FormGroup>

                {/* Last Login */}
                <FormGroup>
                    <div className="text-muted"><Translate id='form.fields.lastConnection' /></div>
                    <div className="font-medium">
                        {selectedRows[0].last_connection ?
                            <DateFormat value={selectedRows[0].last_connection} format='YYYY-MM-DD hh:mm a' />
                            :
                            <Translate id='organization.profile.users.sidePanelUsers.userInfo.lastConnection.none' />
                        }
                    </div>
                </FormGroup>

                {/* NAME */}
                <FormGroup>
                    <div className="text-muted"><Translate id='form.fields.name' /></div>
                    <div className="font-medium">
                        {selectedRows[0].name} {selectedRows[0].family_name}
                    </div>
                </FormGroup>

                {/* EMAIL */}
                <FormGroup>
                    <div className="text-muted"><Translate id='form.fields.email' /></div>
                    <div className="font-medium">
                        {selectedRows[0].email}
                    </div>
                </FormGroup>
            </SidePanel.Body>
        </OverlayLoader>
    )
}

export default UsersSettingsSidePanel;