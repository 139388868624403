import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { init as sentryInit, reactRouterV5Instrumentation } from '@sentry/react';
import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
import { history } from './helpers';
import { externalTranslate, getI18nPolyfills } from '@spordle/intl-elements';
import { initializeGA } from '@spordle/ga4';
import App from './app';
import '@spordle/helpers'; // This line imports the flattenArray function for the entire project
import './assets/scss/style.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import 'moment/locale/en-ca';
import 'moment/locale/fr-ca';
import { setupCustomValidation } from '@spordle/formik-elements';
import 'react-datasheet-grid/dist/style.css'

export const sentryDsn = "https://a51315fda29049b1bc84f3ccc4616e14@o566818.ingest.sentry.io/5711909";

sentryInit({
    dsn: sentryDsn,
    integrations: [
        new Integrations.BrowserTracing({ routingInstrumentation: reactRouterV5Instrumentation(history) }),
        new ExtraErrorDataIntegration(),
    ],

    allowUrls: [// Will block errors from external scripts from being logged into Sentry
        'http://localhost',
        /https?:\/\/.*spordle\.(com|dev)/,
        'https://hcr3.hockeycanada.ca',
    ],

    ignoreErrors: [
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:', // This error is caused by Microsoft Outlook SafeLink and is specific to Microsoft Outlook SafeLink
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 0.1 : 0, // Disable transaction logging when NOT on prod
    environment: `${process.env.REACT_APP_ENVIRONMENT} - ${window.location.hostname}`,
    enabled: process.env.NODE_ENV !== 'development', // Enabled when not on localhost
    autoSessionTracking: true,
    attachStacktrace: true,
    release: `${process.env.REACT_APP_VERSION} - ${process.env.REACT_APP_ENVIRONMENT}`, // Matches the release from Sentry
    dist: `Sid-${process.env.REACT_APP_GIT_BRANCH}`, // Matches the dist from the released artifacts
    beforeSend: function(event, hint){
        // Checking for CSS and js file load fail
        if(event.exception && event.exception.values.findIndex((exception) => exception.type === 'ChunkLoadError') !== -1){
            if(process.env.REACT_APP_ENVIRONMENT !== 'prod'){
                alert(externalTranslate('warning.versionOverlapping'));
            }
            window.location.reload(true);
            return null;// Prevent logging this specific error to Sentry
        }
        return event;
    },
    beforeBreadcrumb: function(breadcrumb, hint){
        if(breadcrumb.category === 'xhr' && hint.xhr){ // Adding reason
            if(!(hint.xhr.status >= 200 && hint.xhr.status < 300)){ // Error
                try{
                    const result = JSON.parse(hint.xhr.responseText);
                    if(result.errors)
                        breadcrumb.data.reason = result.errors;
                }catch{
                    if(hint.xhr.statusText)
                        breadcrumb.data.reason = hint.xhr.statusText;
                }
            }
        }
        return breadcrumb;
    },
});

const GA4MesurementId = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_GA_MESUREMENT_ID : process.env.REACT_APP_SID_GA_MESUREMENT_ID;

Promise.all([
    initializeGA(GA4MesurementId, { enable: process.env.REACT_APP_ENVIRONMENT === 'prod' }),
    getI18nPolyfills(),
])
    .catch(console.error)
    .finally(() => {
        // Load app only once google-maps is done loading(fail or success)
        // Is equivalent to have a script tag with async and defer like we did before
        ReactDOM.render(
            <App />, document.getElementById('root'),
        );
    });

setupCustomValidation();