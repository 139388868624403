import { captureMessage, Severity, withScope } from "@sentry/react";
import Translate from "@spordle/intl-elements";
import { fire } from "@spordle/toasts";
import { useContext, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import GlobalSettingsDrawer from "../../../../components/globalSettings/GlobalSettingsDrawer";
import OrganizationCreationModal from "../../../../components/organization/OrganizationCreationModal";
import OrganizationParentChangeModal from "../../../../components/organization/OrganizationParentChangeModal";
import SidePanel from "../../../../components/sidePanel/SidePanel";
import ManageRolePermissions from "../../../../components/viewHeader/ManageRolePermissions";
import ViewStateSidePanel from "../../../../components/ViewStateSidePanel";
import { AppContext } from "../../../../contexts/contexts";
import { QuickViewContext } from "../../../../contexts/QuickViewContext";

const GodToolsNav = () => {
    const [ adminSidepanel, setAdminSidepanel ] = useState(false);
    const { resetFrontEndParams } = useContext(AppContext);
    const { setIframeIsLoaded } = useContext(QuickViewContext);

    const [ settingsDrawerOpen, setSettingsDrawerOpen ] = useState(false);

    const callCount = useRef(0);

    const toggleIsOpen = (view, bool) => {
        setAdminSidepanel((prev) => ({ ...prev, [view]: bool }));
    };

    const createSentryIssue = (count = 0) => {
        // recursive logic to send the events to sentry as different events (not duplicates)
        // a sentry duplicate has the same fingerprint and stacktrace
        // by having the same fingerprint but a different stacktrace (cause recursive function), they are all different
        if(count < callCount.current){
            createSentryIssue(count + 1)
            return;
        }
        withScope((scope) => {
            scope.setLevel(Severity.Info);
            scope.setFingerprint('Manual Sentry Trigger')
            const eventId = captureMessage('Manual Sentry Trigger');
            fire({
                msg: <a href={`https://sentry.io/organizations/spordle/issues/3498415973/events/${eventId}/?project=5711909`} target='_blank' rel="noreferrer">View on Sentry</a>,
            })
            callCount.current++
        });
    }

    return (
        <>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav className="text-purple"><i className="mdi mdi-settings font-18" /></DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem className="text-purple" onClick={() => toggleIsOpen('permissions', true)}>
                        Role permissions
                    </DropdownItem>
                    <DropdownItem className="text-purple" onClick={() => toggleIsOpen('create', true)}>
                        <Translate id='components.createOrganizationModal.title' />
                    </DropdownItem>
                    <DropdownItem className="text-purple" onClick={() => toggleIsOpen('parent', true)}>
                        Organization Parent Change
                    </DropdownItem>
                    <DropdownItem className="text-purple" onClick={() => toggleIsOpen('viewState', true)}>
                        View State
                    </DropdownItem>
                    <DropdownItem
                        className="text-purple"
                        onClick={async() => {
                            await resetFrontEndParams();
                            setIframeIsLoaded(false);
                        }}
                    >
                        Reset Front End Params
                    </DropdownItem>
                    <DropdownItem
                        className="text-purple"
                        onClick={() => {
                            createSentryIssue()
                        }}
                    >
                        Trigger Sentry Error
                    </DropdownItem>
                    <DropdownItem
                        className="text-purple"
                        onClick={() => setSettingsDrawerOpen(true)}
                    >
                        Open Global Settings
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <OrganizationParentChangeModal isOpen={adminSidepanel?.parent || false} closeModal={() => toggleIsOpen('parent', false)} />
            <OrganizationCreationModal isOpen={adminSidepanel?.create} toggle={() => toggleIsOpen('create', false)} />
            <GlobalSettingsDrawer setSettingsDrawerOpen={setSettingsDrawerOpen} settingsDrawerOpen={settingsDrawerOpen} />

            {createPortal(
                <SidePanel isOpen={adminSidepanel?.viewState}>
                    <ViewStateSidePanel toggle={() => toggleIsOpen('viewState', false)} />
                </SidePanel>
                , document.getElementById('page-wrapper') || document.body,
            )}
            {createPortal(
                <SidePanel isOpen={adminSidepanel?.permissions}>
                    <ManageRolePermissions closeSidePanel={() => toggleIsOpen('permissions', false)} />
                </SidePanel>
                , document.getElementById('page-wrapper') || document.body,
            )}
        </>
    );
}

export default GodToolsNav;