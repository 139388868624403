import authRoutes from './authRoutes';
import fullLayoutRoutes from './fullLayoutRoutes';
import PageNotFound404 from '../views/custom/PageNotFound404';

const Routes = [
    ...authRoutes,
    ...fullLayoutRoutes,

    // fallback to page not found
    {
        path: '/',
        component: PageNotFound404,
    },
]

export default Routes;