import { FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { useFormikContext } from 'formik';
import { useContext, useRef } from 'react';
import { Col, Collapse, Fade, FormGroup, Label, Row } from 'reactstrap';
import { I18nContext } from '../../../contexts/I18nContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { RegistrationDivisionsContext } from '../../../contexts/RegistrationDivisionsContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { RegistrationCategoriesContext } from '../../../contexts/RegistrationCategoriesContext';
import { DisplayCategory } from '../../teams/TeamHelpers';

const DisplayHeaderCardRow = ({ fieldArrayHelper, teamCategoryDivision, index }) => {
    const i18nContext = useContext(I18nContext);
    const formikContext = useFormikContext();
    const divisionsContext = useContext(RegistrationDivisionsContext);
    const teamCategoryContext = useContext(RegistrationCategoriesContext);
    const orgContext = useContext(OrganizationContext)

    /**  @type {React.MutableRefObject<import('@spordle/spordle-select/dist/components/SpordleSelect').default>} */
    const categoriesSelectRef = useRef();

    return (
        <Collapse isOpen appear className='mb-3 p-3 border rounded' key={teamCategoryDivision.id}>
            <Row form>
                <Col sm='5'>
                    <FormGroup>
                        <Label className='text-muted'><Translate id='settings.teamsSettings.division' /></Label>
                        <FormikSelect
                            id={`teamCategoryDivision.${index}.divisionId`}
                            name={`teamCategoryDivision.${index}.divisionId`}
                            renderOption={(option) => (
                                <DisplayI18n field='short_name' defaultValue={option.option.label} i18n={option.option.i18n} />
                            )}
                            clearable
                            searchKeys={[
                                `i18n.${i18nContext.getGenericLocale()}.short_name`,
                            ]}
                            onOptionSelected={(values) => {
                                categoriesSelectRef.current?.getSpordleTable().filterChange('divisionId', values[0])
                                formikContext.setFieldValue(`teamCategoryDivision.${index}.teamCategoryId`, [])
                            }}
                            loadData={(from, extra, spordleTable) => {
                                switch (from){
                                    case 'CDM':
                                        return divisionsContext.getRegistrationDivisions(orgContext.organisation_id)
                                            .then((divisions) => {
                                                return divisions.reduce((newArray, div) => {
                                                    if(div.active == 1){
                                                        newArray.push({
                                                            value: div.division_id,
                                                            label: div.short_name,
                                                            i18n: div.i18n,
                                                        })
                                                    }
                                                    return newArray
                                                }, [])
                                            })
                                }
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col sm='5'>
                    <FormGroup>
                        <Label className='text-muted'><Translate id='settings.teamsSettings.teamCategory' /></Label>
                        <FormikSelect
                            id={`teamCategoryDivision.${index}.teamCategoryId`}
                            name={`teamCategoryDivision.${index}.teamCategoryId`}
                            ref={categoriesSelectRef}
                            searchKeys={[
                                `category.i18n.${i18nContext.getGenericLocale()}.name`,
                                `category.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                `category.division.i18n.${i18nContext.getGenericLocale()}.name`,
                                `category.class.i18n.${i18nContext.getGenericLocale()}.name`,
                                `category.division.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                `category.class.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                `category.gender`,
                            ]}
                            renderOption={({ option }) => (
                                <>
                                    <div className="mb-0">
                                        <DisplayCategory category={option.category} short />{option.category?.gender && <> - <Translate id={`form.fields.gender.${option.category?.gender.toLowerCase()}`} /></>}
                                    </div>
                                    <div className="small text-muted">{<DisplayCategory category={option.category} />}</div>
                                </>
                            )}
                            renderSelectedOption={(option) => <><DisplayCategory category={option.category} short />{option.category?.gender && <> - <Translate id={`form.fields.gender.${option.category?.gender.toLowerCase()}`} /></>}</>}
                            clearable multi
                            textWhenSetting={{
                                count: 1,
                                label: 'settings.teamsSettings.teamCategory.textWhen',
                            }}
                            initFilter={{
                                divisionId: teamCategoryDivision.divisionId,
                            }}
                            loadData={(from, extra, spordleTable) => {
                                switch (from){
                                    case 'FILTER':
                                        spordleTable.setLoading();
                                    case 'CDM':
                                        return teamCategoryContext.getRegistrationCategories(orgContext.organisation_id)
                                            .then((categories) => {
                                                const activeCategories = categories.reduce((newArray, cat) => { // meow
                                                    if(cat.active == 1 && (cat.division?.division_id === extra.filters.divisionId || !extra.filters.divisionId)){
                                                        newArray.push({
                                                            value: cat.team_category_id,
                                                            label: cat.name,
                                                            category: cat,
                                                        })
                                                    }
                                                    return newArray
                                                }, [])

                                                return activeCategories
                                            })
                                }
                            }}
                        />
                    </FormGroup>
                </Col>
                {formikContext.values.teamCategoryDivision.length > 1 &&
                    <Fade tag={Col} sm='2' className='d-flex align-items-center justify-content-end'>
                        <button className='reset-btn' type='button' onClick={fieldArrayHelper.handleRemove(index)}>
                            <i className='mdi mdi-delete text-danger font-16' />
                        </button>
                    </Fade>
                }
            </Row>
        </Collapse>
    )
}

export default DisplayHeaderCardRow