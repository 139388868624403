export default{
    "catalog.otherItems.otherItemsAdd.title" : "Ajouter un article",

    "catalog.otherItems.otherItemsAdd.form.name"        : "Nom",
    "catalog.otherItems.otherItemsAdd.form.description" : "Description",
    "catalog.otherItems.otherItemsAdd.form.price"       : "Prix",
    "catalog.otherItems.otherItemsAdd.form.tax"         : "Taxe",
    "catalog.otherItems.otherItemsAdd.form.sku"         : "SKU",

    "catalog.otherItems.otherItemsAdd.form.validation.amount"      : "Le prix est requis.",
    "catalog.otherItems.otherItemsAdd.form.validation.minAmount"   : "Le prix doit être de 0.01$ ou plus",
    "catalog.otherItems.otherItemsAdd.form.validation.name"        : "Le nom est requis.",
    "catalog.otherItems.otherItemsAdd.form.validation.description" : "La description est requise.",
}