import { FormikSelect } from "@spordle/formik-elements"
import Translate from "@spordle/intl-elements"
import { useFormikContext } from "formik"
import { useContext } from "react"
import { FormGroup } from "reactstrap"
import { PeriodsContext } from "../../../../../contexts/contexts"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import { filterHasValue, formikCodesHaveValues, handleSelectFilterChange } from "../../reportsEngineHelpers"
import FilterLabel from "../FilterLabel"

const EnginePeriodSelect = ({ filter, filterRefs }) => {
    const periodsContext = useContext(PeriodsContext)
    const formik = useFormikContext();

    return (
        <FormGroup name={filter.code}>
            <FilterLabel filter={filter} />
            <FormikSelect
                {...filter.parsedParams?.componentProps}
                id={filter.code}
                name={filter.code}
                ref={(r) => { filterRefs.current[filter.code] = r }}
                search={filter.parsedParams?.componentProps?.searchKeys?.length > 0}
                searchKeys={filter.parsedParams?.componentProps?.searchKeys}
                options={periodsContext.activePeriods.map((period) => ({
                    value: period.period_id,
                    label: period.name,
                    option: period,
                    current: period.current,
                }))}
                loadingStatus='success'
                onOptionSelected={(period, spordleSelect) => handleSelectFilterChange(filter, formik, period, spordleSelect, filterRefs)}
                renderOption={({ option }) => (
                    <>
                        <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />
                        {(option.current == 1) && <div className='small text-muted'><Translate id='misc.current' /></div>}
                    </>
                )}
                className={filter.parsedParams?.componentProps?.className}
                disabled={!formikCodesHaveValues(filter.parsedParams?.enabledWhen, formik) || !filterHasValue(filter.parsedParams?.enabledWhenValue?.filter, filter.parsedParams?.enabledWhenValue?.value, formik)}
            />
        </FormGroup>
    )
}

export default EnginePeriodSelect