import Translate from "@spordle/intl-elements";
import UserImg from "../UserImg";
import InlineCopy from "../inlineCopy/InlineCopy";
import QuickViewButton from "../quickView/QuickViewButton";
import UserDisplay from "../userDisplay/UserDisplay";
import { Link } from "react-router-dom";

const DisplayMember = ({ member, children, extra, quickViewProps, imgProps, titleProps, withLink, ...props }) => {

    return (
        <UserDisplay block {...props}>
            <div className="d-flex align-items-center w-100">
                {extra?.right}
                <UserDisplay.Container>
                    <UserImg
                        src={member.picture?.full_path}
                        filePos={member.picture?.file_position}
                        abbr={member.first_name}
                        alt={member.first_name + ' ' + member.last_name}
                        width={40}
                        {...imgProps}
                    />
                </UserDisplay.Container>
                <UserDisplay.Container>
                    {extra?.infoTop}
                    <UserDisplay.Title {...titleProps}>
                        <QuickViewButton member={member} {...quickViewProps}>
                            {member.first_name} {member.last_name}
                        </QuickViewButton>
                    </UserDisplay.Title>
                    {(member.unique_identifier || withLink) &&
                        <UserDisplay.Subtitle>
                            {member.unique_identifier ?
                                <InlineCopy toCopy={member.unique_identifier}>
                                    {withLink ?
                                        <Link to={`/members/profile/${member.member_id}`}>
                                            #{member.unique_identifier}<i className="mdi mdi-chevron-right ml-1" />
                                        </Link>
                                        :
                                        `#${member.unique_identifier}`
                                    }
                                </InlineCopy>
                                :
                                <Link to={`/members/profile/${member.member_id}`}>
                                    <Translate id='misc.goToProfile' /><i className="mdi mdi-chevron-right ml-1" />
                                </Link>

                            }
                        </UserDisplay.Subtitle>
                    }
                    {extra?.infoBottom}
                </UserDisplay.Container>
                {extra?.left}
            </div>
            {children}
        </UserDisplay>
    );
}

export default DisplayMember;