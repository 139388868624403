import { addExtension, DisplayPhoneNumber, FormikCheckedButton, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { fail, success } from "@spordle/toasts";
import { useState, useContext } from "react";
import { Col, Label, Row } from "reactstrap";
import { boolean, object, string } from "yup";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import FormikEditable from "../../../../../../components/formik/FormikEditable";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import { TeamsContext } from "../../../../../../contexts/TeamsContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";

const TeamContactSidepanel = ({ selectedRows, teamContactTypes, phoneTypes, ...props }) => {
    const teamsContext = useContext(TeamsContext)
    const rolesContext = useContext(RolesContext)
    const [ isLoading, setIsLoading ] = useState(false);

    const getPhone = (phone) => {
        if(phone.includes('#')){
            return phone.split('#')[0]
        }
        return phone
    }

    const getExtension = (phone) => {
        if(phone.includes('#')){
            return phone.split('#')[1]
        }
        return ''

    }

    const canEdit = rolesContext.canDoAction("EDIT", "teams", "team_contacts");

    return (
        <OverlayLoader isLoading={isLoading}>

            <SidePanel.Header>
                {/* {console.log(selectedRows[0])} */}
                {/* {console.log(props)} */}
                <div className='d-flex mb-3 align-items-center'>
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu action="DELETE" componentCode="members" componentPermissionCode="member_contacts" label="misc.action">
                        <ConfirmModal
                            toggler={(toggle) => (
                                <SidePanel.MenuAction onClick={toggle}>
                                    <Translate id='misc.delete' />
                                </SidePanel.MenuAction>
                            )}
                            modalTitle={<Translate id='members.profile.overview.memberContacts.sidePanel.remove.text' />}
                            actionButtonText='misc.confirm'
                            translateActionButtonText
                            color='danger'
                            onConfirm={() => {
                                teamsContext.deleteTeamContact(teamsContext.cachedTeam.team_id, selectedRows[0].team_contact_id_public)
                                    .then(() => {
                                        teamsContext.getTeam(teamsContext.cachedTeam.team_id, true, { components: 'contact,address,competitions' })
                                            .then(() => {
                                                props.forceCloseSidePanel();
                                                props.tableRef.refreshTable();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    })
                                    .catch((e) => {
                                        if(!AxiosIsCancelled(e.message)){
                                            console.error(e.message)
                                        }
                                    })
                            }}
                        />
                    </SidePanel.ActionsMenu>
                </div>
                <SidePanel.Title>
                    {props.getCommonValue('first_name')} {props.getCommonValue('last_name')}
                </SidePanel.Title>
                <SidePanel.Subtitle>
                    <DisplayI18n i18n={selectedRows[0].team_contact_type?.i18n} defaultValue={selectedRows[0].team_contact_type?.name} field='name' />
                </SidePanel.Subtitle>
            </SidePanel.Header>
            <div className="mb-phat">
                <div className="p-3 border-bottom">
                    <Row className='mb-3'>
                        <Col className="mb-3" md='6'>
                            {/* FIRST NAME */}
                            <div className="text-muted"><Translate id='form.fields.firstName' /></div>
                            <FormikEditable
                                id='first_nameFormikEditable'
                                readOnly={!canEdit}
                                initialValues={{
                                    first_name: props.getCommonValue('first_name'),
                                }}
                                validationSchema={object().shape({
                                    first_name: string().required(<Translate id='form.validation.firstName.required' />),
                                })}
                                onSubmit={(values) => {
                                    if(values.first_name !== props.getCommonValue('first_name')){
                                        setIsLoading(true)
                                        const newValues = props.createNewValues(values);
                                        teamsContext.updateTeamContactPartial(teamsContext.cachedTeam.team_id, props.getCommonValue('team_contact_id_public'), newValues)
                                            .then(() => {
                                                props.syncRows(newValues)
                                                props.tableRef.refreshTable()
                                                success({ msg: 'members.profile.overview.memberContacts.sidePanel.edit.success' })
                                                setIsLoading(false)
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                    setIsLoading(false)
                                                }
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium'>{props.getCommonValue('first_name')}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='first_name' name='first_name' trim />
                                    )

                                }}
                            </FormikEditable>
                        </Col>
                        <Col className="mb-3" md='6'>
                            {/* LAST NAME */}
                            <div className="text-muted"><Translate id='form.fields.lastName' /></div>
                            <FormikEditable
                                id='last_nameFormikEditable'
                                readOnly={!canEdit}
                                initialValues={{
                                    last_name: props.getCommonValue('last_name'),
                                }}
                                validationSchema={object().shape({
                                    last_name: string().required(<Translate id='form.validation.lastName.required' />),
                                })}
                                onSubmit={(values) => {
                                    if(values.last_name !== props.getCommonValue('last_name')){
                                        setIsLoading(true)
                                        const newValues = props.createNewValues(values);
                                        teamsContext.updateTeamContactPartial(teamsContext.cachedTeam.team_id, props.getCommonValue('team_contact_id_public'), newValues)
                                            .then(() => {
                                                props.syncRows(newValues)
                                                props.tableRef.refreshTable()
                                                success({ msg: 'members.profile.overview.memberContacts.sidePanel.edit.success' })
                                                setIsLoading(false)
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                    setIsLoading(false)
                                                }
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium'>{props.getCommonValue('last_name')}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='last_name' name='last_name' trim />
                                    )

                                }}
                            </FormikEditable>
                        </Col>
                        <Col className="mb-3" md='12'>
                            {/* EMAIL */}
                            <div className="text-muted"><Translate id='form.fields.email' /></div>
                            <FormikEditable
                                id='emailFormikEditable'
                                readOnly={!canEdit}
                                initialValues={{
                                    email: props.getCommonValue('email'),
                                }}
                                validationSchema={object().shape({
                                    email: string().required(<Translate id='form.validation.lastName.required' />),
                                })}
                                onSubmit={(values) => {
                                    if(values.email !== props.getCommonValue('email')){
                                        setIsLoading(true)
                                        const newValues = props.createNewValues(values);
                                        teamsContext.updateTeamContactPartial(teamsContext.cachedTeam.team_id, props.getCommonValue('team_contact_id_public'), newValues)
                                            .then(() => {
                                                props.syncRows(newValues)
                                                props.tableRef.refreshTable()
                                                success({ msg: 'members.profile.overview.memberContacts.sidePanel.edit.success' })
                                                setIsLoading(false)
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                    setIsLoading(false)
                                                }
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium'>{props.getCommonValue('email')}</div>
                                        )
                                    }
                                    return (
                                        <FormikInputText id='email' name='email' type='email' />
                                    )

                                }}
                            </FormikEditable>
                        </Col>
                        <Col className="mb-3" md='12'>
                            {/* team_contact_type_id */}
                            <div className="text-muted"><Translate id='teams.profile.teamInfos.contact.role' /></div>
                            <FormikEditable
                                id='team_contact_type_idFormikEditable'
                                readOnly={!canEdit}
                                initialValues={{
                                    team_contact_type_id: props.getCommonValue('team_contact_type').team_contact_type_id,
                                }}
                                validationSchema={object().shape({
                                    team_contact_type_id: string().required(<Translate id='teams.profile.teamInfos.contact.role.required' />),
                                })}
                                onSubmit={(values) => {
                                    if(values.team_contact_type_id !== props.getCommonValue('team_contact_type').team_contact_type_id){
                                        setIsLoading(true)

                                        const newValues = props.createNewValues({
                                            team_contact_type: {
                                                ...teamContactTypes.find((contactType) => contactType.team_contact_type_id === values.team_contact_type_id),
                                            },
                                        });
                                        teamsContext.updateTeamContactPartial(teamsContext.cachedTeam.team_id, props.getCommonValue('team_contact_id_public'), { team_contact_type_id: values.team_contact_type_id })
                                            .then(() => {
                                                props.syncRows(newValues)
                                                props.tableRef.refreshTable()
                                                success({ msg: 'members.profile.overview.memberContacts.sidePanel.edit.success' })
                                                setIsLoading(false)
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                    setIsLoading(false)
                                                }
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium'><DisplayI18n field="name" defaultValue={selectedRows[0].team_contact_type.name} i18n={selectedRows[0].team_contact_type.i18n} /></div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            name='team_contact_type_id' id='team_contact_type_id'
                                            menuIsDefaultOpen
                                            isLoading={!teamContactTypes}
                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                            options={teamContactTypes?.reduce((newArray, type) => {
                                                if(type.active == 1){
                                                    newArray.push({
                                                        value: type.team_contact_type_id,
                                                        label: type.name,
                                                        i18n: type.i18n,
                                                    })
                                                }
                                                return newArray
                                            }, [])}
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </Col>
                        <Col className="mb-3" md='12'>
                            {/* phone and extension */}
                            <div className="text-muted"><Translate id='form.fields.phone' /></div>
                            <FormikEditable
                                id='phoneFormikEditable'
                                readOnly={!canEdit}
                                initialValues={{
                                    phone: getPhone(props.getCommonValue('phone')),
                                    extension: getExtension(props.getCommonValue('phone')),
                                }}
                                validationSchema={object().shape({
                                    phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />).required(<Translate id='form.validation.phone.required' />),
                                })}
                                onSubmit={async(values) => {
                                    if(values.team_contact_type_id !== props.getCommonValue('team_contact_type').team_contact_type_id){
                                        setIsLoading(true)

                                        const newValues = props.createNewValues({
                                            phone: await addExtension(values.phone, values.extension),
                                        });
                                        teamsContext.updateTeamContactPartial(teamsContext.cachedTeam.team_id, props.getCommonValue('team_contact_id_public'), newValues)
                                            .then(() => {
                                                props.syncRows(newValues)
                                                props.tableRef.refreshTable()
                                                success({ msg: 'members.profile.overview.memberContacts.sidePanel.edit.success' })
                                                setIsLoading(false)
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                    setIsLoading(false)
                                                }
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium'><DisplayPhoneNumber phoneString={selectedRows[0].phone} format='INTERNATIONAL' />                                            </div>
                                        )
                                    }
                                    return (
                                        <Row form>
                                            <Col sm='8'>
                                                <Label for='phone' className='small text-uppercase text-muted'><Translate id='organization.profile.staff.sidePanel.formik.label.number' /></Label>
                                                <FormikPhoneInput name="phone" id="phone" />
                                            </Col>
                                            <Col sm='4'>
                                                <Label for='extension' className='small text-uppercase text-muted'><Translate id='form.fields.extension' /></Label>
                                                <FormikInputNumber id='extension' name='extension' />
                                            </Col>
                                        </Row>
                                    )

                                }}
                            </FormikEditable>
                        </Col>
                        <Col className="mb-3" md='12'>
                            {/* phone_type_id */}
                            <div className="text-muted"><Translate id='form.fields.phoneType' /></div>
                            <FormikEditable
                                id='phone_type_idFormikEditable'
                                readOnly={!canEdit}
                                initialValues={{
                                    phone_type_id: props.getCommonValue('phone_type')?.phone_type_id,
                                }}
                                validationSchema={object().shape({
                                    phone_type_id: string().required(<Translate id='form.validation.phoneType.required' />),
                                })}
                                onSubmit={(values) => {
                                    if(values.phone_type_id !== props.getCommonValue('phone_type')?.phone_type_id){
                                        setIsLoading(true)

                                        // console.log(teamContactTypes.find((contactType) => contactType.team_contact_type_id === values.team_contact_type_id))

                                        const newValues = props.createNewValues({
                                            phone_type: {
                                                ...phoneTypes.find((phoneType) => phoneType.phone_type_id === values.phone_type_id),
                                            },
                                        });
                                        teamsContext.updateTeamContactPartial(teamsContext.cachedTeam.team_id, props.getCommonValue('team_contact_id_public'), { phone_type_id: values.phone_type_id })
                                            .then(() => {
                                                props.syncRows(newValues)
                                                props.tableRef.refreshTable()
                                                success({ msg: 'members.profile.overview.memberContacts.sidePanel.edit.success' })
                                                setIsLoading(false)
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                    setIsLoading(false)
                                                }
                                            })
                                    }
                                }}
                            >
                                {(isEditing) => {
                                    if(!isEditing){
                                        return (
                                            <div className='font-medium'><DisplayI18n field="name" defaultValue={selectedRows[0].phone_type?.name} i18n={selectedRows[0].phone_type?.i18n} /></div>
                                        )
                                    }
                                    return (
                                        <FormikSelect
                                            name='phone_type_id' id='phone_type_id'
                                            menuIsDefaultOpen
                                            isLoading={!phoneTypes}
                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                            options={phoneTypes?.reduce((newArray, type) => {
                                                if(type.active == 1){
                                                    newArray.push({
                                                        value: type.phone_type_id,
                                                        label: type.name,
                                                        i18n: type.i18n,
                                                    })
                                                }
                                                return newArray
                                            }, [])}
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </Col>
                        <Col className="mb-3" md='12'>
                            {/* display_on */}
                            <FormikEditable
                                id='display_onFormikEditable'
                                readOnly={!canEdit}
                                initialValues={{
                                    display_on: props.getCommonValue('display_on') === 'TEAM_ROSTER_PDF',
                                }}
                                validationSchema={object().shape({
                                    display_on: boolean(),
                                })}
                                onSubmit={(values) => {
                                    if(values.display_on !== (props.getCommonValue('display_on') === 'TEAM_ROSTER_PDF')){
                                        setIsLoading(true)

                                        // console.log(teamContactTypes.find((contactType) => contactType.team_contact_type_id === values.team_contact_type_id))

                                        const newValues = props.createNewValues({
                                            display_on: values.display_on ? 'TEAM_ROSTER_PDF' : '',
                                        });
                                        teamsContext.updateTeamContactPartial(teamsContext.cachedTeam.team_id, props.getCommonValue('team_contact_id_public'), { display_on: values.display_on ? 'TEAM_ROSTER_PDF' : '' })
                                            .then(() => {
                                                props.syncRows(newValues)
                                                props.tableRef.refreshTable()
                                                success({ msg: 'members.profile.overview.memberContacts.sidePanel.edit.success' })
                                                setIsLoading(false)
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                    setIsLoading(false)
                                                }
                                            })
                                    }
                                }}
                            >
                                {(isEditing, _, formik) => {
                                    if(!isEditing){
                                        return (
                                            <>
                                                {props.getCommonValue('display_on') === 'TEAM_ROSTER_PDF' ?
                                                    <><i className="mdi mdi-check text-primary mr-1" /><Translate id="teams.profile.teamInfos.contact.display.on.team.roster" /></>
                                                    :
                                                    <><i className="mdi mdi-close text-danger mr-1" /><Translate id="teams.profile.teamInfos.contact.display.on.team.roster" /></>
                                                }
                                            </>
                                        )
                                    }
                                    return (
                                        <FormikCheckedButton
                                            id={'display_on'}
                                            name={'display_on'}
                                            label={<div className='text-muted'><Translate id='teams.profile.teamInfos.contact.display.on.team.roster' /></div>}
                                            translateLabel={false}
                                            onChange={(e) => {
                                                formik.setFieldValue('display_on', e.target.checked);
                                            }}
                                            className={`w-100`}
                                            checked={formik.values.display_on}
                                        />
                                    )

                                }}
                            </FormikEditable>
                        </Col>
                    </Row>
                </div>
            </div>
        </OverlayLoader>
    )
}

export default TeamContactSidepanel