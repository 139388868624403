import Translate from '@spordle/intl-elements';
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    Button,
    FormGroup,
    Label
} from "reactstrap";
import { Form, Formik } from 'formik';
import { FormikInputText, FormikSwitch } from '@spordle/formik-elements';
import FormikTiny from '../../../../components/formik/FormikTiny';
import I18nContextProvider, { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';
import { object, string } from 'yup';
import OverlayLoader from '../../../../components/loading/OverlayLoader';

// contexts
import { TermsConditionsContext } from '../../../../contexts/TermsConditionsContext';
import withContexts from '../../../../helpers/withContexts';
import { fail, success } from "@spordle/toasts";
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import CanDoAction from '../../../../components/permissions/CanDoAction';

class TermsEdit extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            loading: false,
        }
    }

    componentDidMount(){
        if(this.props.mode === 'edit' && !this.props.termsData?.term_and_condition_id){
            this.props.history.push('/catalog/terms');
        }
    }

    render(){
        return (
            <>
                <div className='mb-2'>
                    <Link to="/catalog/terms" className="font-medium">
                        <i className="mdi mdi-arrow-left mr-1" />
                        <Translate id='catalog.waivers.back' />
                    </Link>
                </div>

                <I18nContextProvider fields={[ 'title', 'description' ]}>
                    <I18nHelperContext.Consumer>
                        {(i18nHelper) => (
                            <Formik
                                initialValues={{
                                    ...i18nHelper.getInitialValues(this.props.mode === 'edit' ? this.props.termsData : {}),
                                    active: this.props.mode === 'edit' ? this.props.termsData?.active === '1' : true,
                                }}
                                validationSchema={object().shape(
                                    i18nHelper.getValidationSchema({ title: string().required(<Translate id='form.validation.title.required' />), description: string().required(<Translate id='form.validation.description.required' />) }),
                                )}
                                onSubmit={(values, { setSubmitting }) => {
                                    if(this.props.mode === 'edit'){
                                        this.props.TermsConditionsContext.updateTermsCondition(this.props.termsData?.term_and_condition_id, {
                                            ...i18nHelper.getAPIValues(values),
                                            active: values.active,
                                        })
                                            .then(() => {
                                                this.props.history.push('/catalog/terms');
                                                success({ msg: 'misc.updated' });
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => {
                                                setSubmitting(false)
                                            })
                                    }else{
                                        this.props.TermsConditionsContext.createTermsCondition({
                                            ...i18nHelper.getAPIValues(values),
                                            active: values.active,
                                        })
                                            .then(() => {
                                                this.props.history.push('/catalog/terms');
                                                success({ msg: 'misc.created' });
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                            .finally(() => {
                                                setSubmitting(false)
                                            })
                                    }
                                }}
                            >
                                {(formik) => (
                                    <Form>
                                        <Card className="card-shadow">
                                            <OverlayLoader isLoading={formik.isSubmitting || this.state.loading}>
                                                <CardBody className="card-shadow">
                                                    <div className="d-flex mb-3 border-bottom pb-1">
                                                        <div className="h4 font-bold mb-0 card-title">
                                                            {this.props.mode === 'edit' ?
                                                                <DisplayI18n field='name' defaultValue={formik.values.title} i18n={formik.values.i18n} />
                                                                :
                                                                <Translate id='catalog.terms.add.title' />
                                                            }
                                                        </div>
                                                        <div className="ml-auto d-flex align-items-center">
                                                            <Label for='active' className="mr-2 mb-0"><Translate id={`misc.${formik.values.active ? 'active' : 'inactive'}`} /></Label>
                                                            <FormikSwitch id='active' name='active' />
                                                        </div>
                                                    </div>

                                                    <RenderI18nForm field="title">
                                                        {({ fieldName, fieldLabel }) => {
                                                            return (
                                                                <FormGroup key={fieldName}>
                                                                    <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                                </FormGroup>
                                                            )
                                                        }}
                                                    </RenderI18nForm>

                                                    <RenderI18nForm field="description">
                                                        {({ fieldName, fieldLabel }) => {
                                                            return (
                                                                <FormGroup key={fieldName}>
                                                                    <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                                    {/* check if headings can be manually saved as <div class='h1'></div> */}
                                                                    {/* if not, remove the heading option */}
                                                                    {/* it could also be cool if we could sent the "height" prop to make it bigger only for this page (because waivers can be long) */}
                                                                    <FormikTiny name={fieldName} height="500" />
                                                                </FormGroup>
                                                            )
                                                        }}
                                                    </RenderI18nForm>

                                                    <div className="d-flex">
                                                        {this.props.mode === 'edit' &&
                                                            <CanDoAction action='DELETE' componentCode='catalog' componentPermissionCode='terms_and_conditions'>
                                                                <Button
                                                                    color="danger" outline type='button'
                                                                    disabled={formik.isSubmitting}
                                                                    onClick={() => {
                                                                        this.setState(() => ({ loading: true }))
                                                                        this.props.TermsConditionsContext.deleteTermsCondition(this.props.termsData?.term_and_condition_id)
                                                                            .then(() => {
                                                                                this.props.history.push('/catalog/terms');
                                                                                success({ msg: 'misc.deleted' });
                                                                            })
                                                                            .catch((error) => {
                                                                                if(!AxiosIsCancelled(error.message)){
                                                                                    console.error(error.message)
                                                                                    fail({
                                                                                        msg: 'misc.error',
                                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                        skipInfoTranslate: true,
                                                                                    })
                                                                                }
                                                                            })
                                                                            .finally(() => {
                                                                                this.setState(() => ({ loading: false }))
                                                                            })
                                                                    }}
                                                                >
                                                                    <Translate id='misc.delete' />
                                                                </Button>
                                                            </CanDoAction>
                                                        }
                                                        <Button color="primary" className='ml-auto' type='submit' disabled={formik.isSubmitting}>
                                                            {this.props.mode === 'edit' ?
                                                                <Translate id='misc.save' />
                                                                :
                                                                <Translate id='misc.add' />
                                                            }
                                                        </Button>
                                                    </div>
                                                </CardBody>
                                            </OverlayLoader>
                                        </Card>
                                    </Form>
                                )}
                            </Formik>
                        )}
                    </I18nHelperContext.Consumer>
                </I18nContextProvider>
            </>
        )
    }
}

export default withRouter(withContexts(TermsConditionsContext)(TermsEdit));