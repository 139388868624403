import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useCallback, memo } from "react";
import { Col, Row } from "reactstrap";
import CollapsibleCard from "../../../../../components/collapsibleCard/CollapsibleCard";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import PostalCodeItem from "./PostalCodeItem";

// IMPORTANT.
// Do not use formik context here. We want to avoid useless renders.
// If you add a prop, make sure to check the 'areEqual' function below.
const PostalCodesCard = ({
    postalCodes = [],
    prefix,
    isEdit,
    canEdit,
    search,
    selectedCodes,
    setSelectedCodes,
    ...props
}) => {
    const hideExtra = !canEdit;
    const filterBySearch = useCallback(({ postal_code }) => postal_code.toLowerCase().includes(search.toLowerCase()), [ search ]);

    function getCount(codes){
        if(selectedCodes && selectedCodes.size > 0){
            return codes.reduce((count, code) => {
                return count + (selectedCodes.has(code.postal_code) ? 1 : 0)
            }, 0);
        }

        return 0;
    }

    const filteredPostalCodes = search ? postalCodes.filter((p) => filterBySearch(p)) : postalCodes;
    const filteredSelectedCount = getCount(filteredPostalCodes);
    const hasSelectedAll = filteredSelectedCount === postalCodes.length;

    const addCode = (val) => {
        setSelectedCodes((prev) => {
            const newVal = new Set(prev);
            newVal.add(val);
            return newVal;
        })
    }

    const removeCode = (val) => {
        setSelectedCodes((prev) => {
            const newVal = new Set(prev);
            newVal.delete(val);
            return newVal;
        })
    }

    const addAllCodes = () => {
        setSelectedCodes((prev) => {
            const newValues = new Set(prev);

            filteredPostalCodes.forEach((code) => {
                newValues.add(code.postal_code)
            });

            return newValues;
        });
    }

    const removeAllCodes = () => {
        setSelectedCodes(new Set());
    }

    return (
        <CollapsibleCard
            withPreload
            mountChildrenOnEnter
            subtitle={filteredSelectedCount + '/' + filteredPostalCodes.length}
            className='mb-3'
            {...props}
            extraInfo={hideExtra ? void 0 :
                <UserDisplay.Container className="ml-auto">
                    <button
                        type='button'
                        className='reset-btn text-link'
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent opening/closing the CollapsibleCard

                            if(hasSelectedAll){
                                removeAllCodes();
                            }else{
                                addAllCodes();
                            }
                        }}
                    >
                        <Translate id={`misc.${stringBuilder({ 'un': hasSelectedAll })}select.all`} />
                    </button>
                </UserDisplay.Container>
            }
        >
            {filteredPostalCodes.length > 0 ?
                <div className='p-2 m-n2 pretty-scrollbar overflow-auto' style={{ maxHeight: '50vh' }}>
                    <Row form tag='ul' className="list-unstyled mb-0" role='listbox' aria-multiselectable>
                        {filteredPostalCodes.map((postalCode) => (
                            <PostalCodeCol
                                key={postalCode.postal_code}
                                postalCode={postalCode}
                                canEdit={canEdit}
                                prefix={prefix}
                                selectedCodes={selectedCodes}
                                removeCode={removeCode}
                                addCode={addCode}
                            />
                        ))}
                    </Row>
                </div>
                :
                <Translate id='organization.profile.postalCodes.modals.step2.empty.postalCodes' />
            }
        </CollapsibleCard>
    )
}

const PostalCodeCol = ({ postalCode, canEdit, selectedCodes, removeCode, addCode }) => {
    const isSelected = selectedCodes ? selectedCodes.has(postalCode.postal_code) : false;
    const code = postalCode.postal_code;

    return (
        <Col
            xs='6'
            sm='4'
            tag='li'
            aria-label={code}
            className="mb-2"
            role='option'
            onClick={() => {
                if(isSelected){
                    removeCode(code);
                }else{
                    addCode(code);
                }
            }}
            aria-selected={isSelected}
        >
            <PostalCodeItem
                isSelected={isSelected}
                canEdit={canEdit}
                postalCode={postalCode}
                // isOrgPostalCode={selectedPostalCodes ? false : postalCodesData[`dataValue___${keyValue}`].indexOf(postalCode.postal_code) !== -1}
            />
        </Col>
    )
}

function areEqual(prevProps, nextProps){
    const searchHasChanged = prevProps.search !== nextProps.search;
    const canEditHasChanged = prevProps.canEdit !== nextProps.canEdit;
    const isEditHasChanged = prevProps.isEdit !== nextProps.isEdit;
    const seletedCodesHaveChanged = (prevProps.selectedCodes && prevProps.selectedCodes.size) !== (nextProps.selectedCodes && nextProps.selectedCodes.size);

    return !(searchHasChanged || canEditHasChanged || isEditHasChanged || seletedCodesHaveChanged);

}

export default memo(PostalCodesCard, areEqual);