// Table
import SpordleTableProvider from '@spordle/datatables';
import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import { Container } from "reactstrap";
import Steps from '../../../../components/steps/Steps';

// Visual
import EmptyLayout from '../../../../components/table/EmptyLayout';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import ViewHeaderV2 from '../../../../components/viewHeader/ViewHeaderV2';
import { I18nContext } from '../../../../contexts/I18nContext';

// Contexts
import { TeamsContext } from '../../../../contexts/TeamsContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { DisplayCategory } from '../../TeamHelpers';
import TeamRolloverReview from './components/TeamRolloverReview';
import TeamRolloverSearch from './components/TeamRolloverSearch';

/**
 *
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {function} props.toggle
 * @returns {JSX}
 */
const TeamRollover = () => {
    const STEPS = {
        search: 0,
        approval: 1,
        confirmation: 2,
    }

    // -- Contexts
    const { getTeamsWithPagination } = useContext(TeamsContext);
    const { getGenericLocale } = useContext(I18nContext);

    const genericLocale = getGenericLocale();

    // -- States
    const [ selectedTeams, setSelectedTeams ] = useState([]);
    const [ steps, setSteps ] = useState(STEPS.search);

    // -- Functions
    /**
     * Adds a team to the array of selected teams
     * @param {object} team Team to add
     */
    const addTeam = (team) => {
        if(!selectedTeams.find((m) => m.team_id == team.team_id)){
            setSelectedTeams((prev) => {
                const newArr = [ ...prev, team ];
                return newArr;
            });
        }
    }

    /**
     * Removes a team from the selected teams array
     * @param {string} teamId Team id of the team to remove
     */
    const removeTeam = (teamId) => {
        setSelectedTeams((prev) => {
            const newArr = prev.filter((m) => m.team_id !== teamId);
            return newArr;
        });
    }

    const nextStep = () => {
        if(steps < STEPS.confirmation) setSteps(steps + 1);
    }

    const previousStep = () => {
        if(steps > STEPS.search) setSteps(steps - 1);
    }

    const getAllTeams = (search) => {
        return new Promise(async(resolve, reject) => {
            await getTeamsWithPagination(search)
                .then(async(data) => {
                    const teams = data.teams;
                    if(data.count < data.total){
                        // data was truncated to limit
                        for(let offset = data.count; offset < data.total; offset += data.count){
                            await getTeamsWithPagination({ ...search, offset: offset })
                                .then((additionalData) => {
                                    teams.pushArray(additionalData.teams)
                                })
                        }
                    }
                    resolve(teams)
                })
                .catch(reject)
        })
    }

    return (
        <>
            {/*--------------------------------------------------------------------------------*/}
            {/* View Header                                                                  */}
            {/*--------------------------------------------------------------------------------*/}
            <ViewHeaderV2
                title='teams.teamSearch.rollover.wizard.title'
                breadcrumbsProps={{
                    disablePeriodSelect: true,
                }}
            />

            <div className="page-content px-4 pt-0">
                <Container className="mb-5 pb-5">
                    <Steps activeStepName={'Select Teams'} className="mb-4">
                        <Steps.Item
                            key={STEPS.search}
                            number={1}
                            name={<Translate id='teams.teamSearch.rollover.wizard.step1' />}
                            onClick={() => setSteps(STEPS.search)}
                            isActive={steps === 0}
                            isAccessible
                        />
                        <Steps.Item
                            key={STEPS.approval}
                            number={2}
                            name={<Translate id='teams.teamSearch.rollover.wizard.step2' />}
                            onClick={() => setSteps(STEPS.approval)}
                            isActive={steps === 1}
                            isAccessible={selectedTeams.length > 0}
                        />
                    </Steps>
                    <SpordleTableProvider
                        id='team-rollover'
                        striped
                        bordered
                        clickable
                        tableHover
                        desktopWhen
                        dataIndex="team_id"
                        searchKeys={[
                            { name: "name", weight: 2 },
                            { name: "short_name", weight: 2 },
                            { name: "unique_identifier", weight: 2 },
                            "abbreviation",
                            "division",

                            `team_category.division.i18n.${genericLocale}.name`,
                            `team_category.division.i18n.${genericLocale}.short_name`,
                            'team_category.division.name',
                            'team_category.division.short_name',
                            `team_category.class.i18n.${genericLocale}.name`,
                            `team_category.class.i18n.${genericLocale}.short_name`,
                            'team_category.class.name',
                            'team_category.class.short_name',

                            `organisation.i18n.${genericLocale}.name`,
                            'organisation.organisation_name',
                        ]}
                        emptyLayout={<EmptyLayout />}
                        initFilter={{
                            search: {
                                period_id: '',
                                class_id: '',
                                division_id: '',
                                team_category_id: '',
                            },
                        }}
                        loadData={(from, { filters }, { setLoading }) => {
                            const formatData = (teams) => {
                                return teams.map((team) => ({
                                    checked: selectedTeams.some((sT) => sT.team_id == team.team_id),
                                    ...team,
                                }))
                            };

                            switch (from){
                                case 'FILTER':
                                    setLoading();
                                    return getAllTeams(filters.search).then(formatData);
                                case 'CDM':
                                    return Promise.resolve([]);
                                default:
                                    break;
                            }
                        }}
                        onColumnClick={(e, team) => {
                            // Needed for checkbox glitch
                            e.preventDefault();
                            e.stopPropagation();

                            switch (e.button){
                                case 0: // Left mouse button
                                    if(team.already_on_roster !== "1"){
                                        if(!team.checked){
                                            team.checked = true;
                                            addTeam(team);
                                        }else{
                                            team.checked = false;
                                            removeTeam(team.team_id);
                                        }
                                    }

                                    break;
                            }
                        }}
                        columns={[
                            {
                                label: '-',
                                key: 'checkbox',
                                className: 'th-shrink',
                                dataClassName: 'td-shrink',
                            },
                            {
                                label: <Translate id='teams.teamSearch.table.label.team' />,
                                key: 'team',
                                sortKey: `i18n.${genericLocale}.name`,
                                fallbackSortKey: 'name',
                                sortable: true,
                            },
                            {
                                label: <Translate id='teams.teamSearch.table.label.org' />,
                                key: 'organisation',
                                sortKey: `organisation.i18n.${genericLocale}.name`,
                                fallbackSortKey: 'organisation.organisation_name',
                                sortable: true,
                            },
                            {
                                label: <Translate id='teams.teamSearch.table.label.category' />,
                                key: 'category',
                                sortKey: `team_category.division.i18n.${genericLocale}.short_name`,
                                fallbackSortKey: 'team_category.division.short_name',
                                dataClassName: 'font-medium',
                                sortable: true,
                            },
                        ]}
                        renderRow={(columnKey, team) => {
                            switch (columnKey){
                                case 'checkbox':
                                    return (
                                        <label className='my-auto' htmlFor={'checkbox-' + team.team_id}>
                                            <input
                                                type='checkbox'
                                                checked={!!team.checked}
                                                id={'checkbox-' + team.team_id}
                                                onChange={() => {}}
                                            />
                                            <span className='table-checkbox' />
                                        </label>
                                    );
                                case 'team':
                                    return (
                                        <UserDisplay>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    abbr={team.abbreviation || team.short_name || team.name}
                                                    src={team.logo?.full_path}
                                                    filePos={team.logo?.file_position}
                                                    width={40}
                                                    alt={team.name}
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>{team.name}</UserDisplay.Title>
                                                {team.unique_identifier && <UserDisplay.Subtitle>#{team.unique_identifier}</UserDisplay.Subtitle>}
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    );
                                case 'organisation':
                                    return (
                                        <div>
                                            <UserDisplay>
                                                <UserDisplay.Container>
                                                    <UserImg
                                                        abbr={team.organisation.abbreviation || team.organisation.name}
                                                        src={team.organisation.logo?.full_path}
                                                        filePos={team.organisation.logo?.file_position}
                                                        width={40}
                                                        alt={team.organisation.organisation_name}
                                                    />
                                                </UserDisplay.Container>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title>
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={team.organisation.organisation_name}
                                                            i18n={team.organisation.i18n}
                                                        />
                                                    </UserDisplay.Title>
                                                </UserDisplay.Container>
                                            </UserDisplay>
                                        </div>
                                    );
                                case 'category':
                                    return team.division ? team.division : <DisplayCategory category={team.team_category} short />;
                                default:
                                    break;
                            }
                        }}

                    >
                        {({ updateDataWith, getDisplayedData }) => (
                            <>
                                {steps === STEPS.search &&
                                    <TeamRolloverSearch
                                        selected={selectedTeams}
                                        uncheckAll={() => {
                                            selectedTeams.map((team) => {
                                                updateDataWith(team.team_id, { checked: false });
                                            });
                                            setSelectedTeams([]);
                                        }}
                                        checkAll={() => {
                                            getDisplayedData().map((team) => {
                                                team.checked = true;
                                                addTeam(team);
                                                return team;
                                            })
                                        }}
                                        nextStep={nextStep}
                                        removeTeam={(teamId) => {
                                            removeTeam(teamId);
                                            updateDataWith(teamId, { checked: false })
                                        }}
                                    />
                                }
                                {steps === STEPS.approval &&
                                    <TeamRolloverReview
                                        selected={selectedTeams}
                                        removeTeam={(teamId) => {
                                            removeTeam(teamId);
                                            updateDataWith(teamId, { checked: false })
                                        }}
                                        previousStep={previousStep}
                                        nextStep={nextStep}
                                    />
                                }
                                {/* {steps === STEPS.confirmation &&
                                    <TeamRolloverConfirmation selected={selectedTeams} />
                                } */}
                            </>
                        )}
                    </SpordleTableProvider>
                </Container>
            </div>
            {/* <AnalyticsModal
                analyticsName='TeamRollover'
                size={steps != STEPS.confirmation ? 'lg' : null}
                isOpen={isOpen}
                onClosed={() => {
                    setSteps(STEPS.search);
                    setSelectedTeams([]);
                }}
            >
                <ModalHeader tag='div' toggle={steps !== STEPS.confirmation ? toggle : handleToggle}>
                    <Translate id='teams.teamSearch.addTeam.rollover.modal.title' />
                </ModalHeader>
                <SpordleTableProvider
                    striped
                    bordered
                    clickable
                    tableHover
                    desktopWhen
                    dataIndex="team_id"
                    pagination={3}
                    searchKeys={[
                        "name",
                        "unique_identifier",
                        "i18n.*.name",
                        {"organisation": [
                            "organisation_name",
                            "i18n.*.name",
                        ]},
                        {"team_category" : [
                            {"class" : [
                                "short_name",
                                "i18n.*.short_name"
                            ]},
                            {"division" : [
                                "short_name",
                                "i18n.*.short_name"
                            ]},
                        ]}
                    ]}
                    emptyLayout={<EmptyLayout />}
                    initFilter={{
                        search: {
                            period_id: '',
                            class_id: '',
                            division_id: '',
                        },
                    }}
                    loadData={(from, { filters }, { setLoading }) => {
                        const formatData = teams => {
                            return teams.map((team) => ({
                                checked: selectedTeams.some((sT) => sT.team_id == team.team_id),
                                ...team,
                            }))
                        };

                        switch (from) {
                            case 'FILTER':
                                setLoading();
                                return getTeams(filters.search).then(formatData)
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                    }
                                });
                            case 'CDM':
                                return Promise.resolve([]);
                            default:
                                break;
                        }
                    }}
                    onColumnClick={(e, team) => {
                        // Needed for checkbox glitch
                        e.preventDefault();
                        e.stopPropagation();

                        switch(e.button){
                            case 0: // Left mouse button
                                if(team.already_on_roster !== "1") {
                                    if(!team.checked) {
                                        team.checked = true;
                                        addTeam(team);
                                    } else {
                                        team.checked = false;
                                        removeTeam(team.team_id);
                                    }
                                }

                                break;
                        }
                    }}
                    columns={[
                        {
                            label: '-',
                            key: 'checkbox',
                            className: 'th-shrink',
                            dataClassName: 'td-shrink',
                        },
                        {
                            label: <Translate id='teams.teamSearch.table.label.team' />,
                            key: 'team',
                            sortKey: `i18n.${getGenericLocale()}.name`,
                            fallbackSortKey: 'name',
                            sortable: true
                        },
                        {
                            label: <Translate id='teams.teamSearch.table.label.org' />,
                            key: 'organisation',
                            sortKey: `organisation.i18n.${getGenericLocale()}.name`,
                            fallbackSortKey: 'organisation.organisation_name',
                            sortable: true,
                        },
                        {
                            label: <Translate id='teams.teamSearch.table.label.category' />,
                            key: 'category',
                            sortKey: `team_category.division.i18n.${getGenericLocale()}.short_name`,
                            fallbackSortKey: 'team_category.division.short_name',
                            dataClassName: 'font-medium',
                            sortable: true
                        },
                    ]}
                    renderRow={(columnKey, team) => {
                        switch (columnKey) {
                            case 'checkbox':
                                return (
                                    <label className='my-auto' htmlFor={'checkbox-'+team.team_id}>
                                        <input
                                            type='checkbox'
                                            checked={!!team.checked}
                                            id={'checkbox-' + team.team_id}
                                            onChange={()=>{}}
                                        />
                                        <span className='table-checkbox' />
                                    </label>
                                );
                            case 'team':
                                return (
                                    <UserDisplay>
                                        <UserDisplay.Container>
                                            <UserImg
                                                abbr={team.abbreviation || team.short_name || team.name}
                                                src={team.logo?.full_path}
                                                filePos={team.logo?.file_position}
                                                width={40}
                                                alt={team.name}
                                            />
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title>{team.name}</UserDisplay.Title>
                                            {team.unique_identifier && <UserDisplay.Subtitle>#{team.unique_identifier}</UserDisplay.Subtitle>}
                                        </UserDisplay.Container>
                                    </UserDisplay>
                                );
                            case 'organisation':
                                return (
                                    <div>
                                        <UserDisplay>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    abbr={team.organisation.abbreviation||team.organisation.name}
                                                    src={team.organisation.logo?.full_path}
                                                    filePos={team.organisation.logo?.file_position}
                                                    width={40}
                                                    alt={team.organisation.organisation_name}
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>
                                                    <DisplayI18n
                                                        field='name'
                                                        defaultValue={team.organisation.organisation_name}
                                                        i18n={team.organisation.i18n}
                                                    />
                                                </UserDisplay.Title>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    </div>
                                );
                            case 'category':
                                return team.team_category ? <DisplayCategory category={team.team_category} short /> : '-';
                            default:
                                break;
                        }
                    } }

                >
                    {({ updateDataWith }) => (
                        <>
                            {steps === STEPS.search &&
                                <TeamRolloverSearch
                                    selected={selectedTeams}
                                    toggle={toggle}
                                    nextStep={nextStep}
                                    removeTeam={(teamId) => {
                                        removeTeam(teamId);
                                        updateDataWith(teamId, { checked: false })
                                    }}
                                />
                            }
                            {steps === STEPS.approval &&
                                <TeamRolloverReview
                                    selected={selectedTeams}
                                    removeTeam={(teamId) => {
                                        removeTeam(teamId);
                                        updateDataWith(teamId, { checked: false })
                                    }}
                                    toggle={toggle}
                                    previousStep={previousStep}
                                    nextStep={nextStep}
                                />
                            }
                            {steps === STEPS.confirmation &&
                                <TeamRolloverConfirmation selected={selectedTeams} toggle={handleToggle}/>
                            }
                        </>
                    )}
                </SpordleTableProvider>
            </AnalyticsModal> */}
        </>
    );
}

export default TeamRollover;