import { FormikSelect } from '@spordle/formik-elements';
import { useContext } from 'react';
import { I18nContext } from '../../../contexts/I18nContext';
import { TransferContext } from '../../../contexts/TransferContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';

const AddTransferStatusSelect = ({ international, id = "transfer_status_id", name = "transfer_status_id" }) => {
    const transferContext = useContext(TransferContext);
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <FormikSelect
            id={id} name={name}
            renderOption={({ option: transferStatus }) => <DisplayI18n field='name' defaultValue={transferStatus.label} i18n={transferStatus.i18n} />}
            searchKeys={[
                `i18n.${getGenericLocale()}.name`,
            ]}
            loadData={(from) => {
                switch (from){
                    case 'CDM':
                        return transferContext.getTransferStatus({ active: 1, is_international: international ? 1 : null }, international ? false : undefined, international ? true : undefined)
                            .then((transferStatus) => {
                                return transferStatus.sort((statusA, statusB) => parseInt(statusA.display_order) - parseInt(statusB.display_order))
                                    .map((transferStatus) => ({
                                        value: transferStatus.transfer_status_id,
                                        label: transferStatus.name,
                                        i18n: transferStatus.i18n,
                                    }))
                            })
                    default:
                        break;
                }
            }}
        />
    );
}

export default AddTransferStatusSelect;