export default{
    "supportRequests.profile.title"                       : "Support Request",
    "supportRequests.profile.raisedOn"                    : "raised this issue on",
    "supportRequests.profile.status"                      : "Status",
    "supportRequests.profile.status.2"                    : "To do",
    "supportRequests.profile.status.3"                    : "Done",
    "supportRequests.profile.status.4"                    : "Pending",
    "supportRequests.profile.notification"                : "Notification",
    "supportRequests.profile.requestType"                 : "Request type",
    "supportRequests.profile.sharedWidth"                 : "Shared width",
    "supportRequests.profile.share"                       : "Share",
    "supportRequests.profile.comment.placeholder"         : "Add a comment...",
    "supportRequests.profile.comment.validation.required" : "Please write your comment",
    "supportRequests.profile.back"                        : "Back to list",
    "supportRequests.profile.priority"                    : "Priority",
    "supportRequests.profile.filter.newest"               : "Newest first",
    "supportRequests.profile.filter.oldest"               : "Oldest first",
    "supportRequests.profile.btn.seeAll"                  : "See all my issues",

    "supportRequests.profile.attachments"                       : "Attachments",
    "supportRequests.profile.attachments.none"                  : "No attachments",
    "supportRequests.profile.attachment.addModal.title"         : "Add an attachment",
    "supportRequests.profile.attachment.addModal.file"          : "File",
    "supportRequests.profile.attachment.addModal.file.required" : "A file is required",

    "supportRequests.profile.error.title" : "Oops! We can't find this issue.",
    "supportRequests.profile.error.text"  : "Looks like this issue may not exist or has been moved.",
    "supportRequests.profile.noComments"  : "No comments available",
    "supportRequests.profile.issueClosed" : "This issue is closed",
}