export default{
    "teams.teamSearch.competition"           : "Competition",
    "teams.teamSearch.submittedBy"           : "Submitted By",
    "teams.teamSearch.submittedDate"         : "Submitted Date",
    "teams.teamSearch.teamSubmission"        : "Team Submission",
    "teams.teamSearch.teamApproval"          : "Team Approval",
    "teams.teamSearch.approvedBy"            : "Approved by",
    "teams.teamSearch.approvalDate"          : "Approbation Date",
    "teams.teamSearch.approvalNote"          : "Approbation Note",
    "teams.teamSearch.reviewRequest"         : "Review Team Participation",
    "teams.teamSearch.reviewRequest.success" : "The {count, plural, =0 {team has been} =1 {team has been} other {# teams have been}} been successfully reviewed!",
    "teams.teamSearch.submissionNote"        : "Submission Note",
    "teams.teamSearch.alreadyParticipating"  : "Already applied",

    "teams.teamSearch.advancedSearch.label.league"                 : "League",
    "teams.teamSearch.advancedSearch.label.searchLimit"            : "Search limit:",
    "teams.teamSearch.advancedSearch.label.searchTotal"            : "Total results:",
    "teams.teamSearch.advancedSearch.label.searchLimitDescription" : "Use Division, Category or Gender Identity filters to unlock search limit.",


    "teams.teamSearch.table.label.roster"      : "Roster",
    "teams.teamSearch.table.label.statusDate"  : "Status Date",
    "teams.teamSearch.table.label.team"        : "Team",
    "teams.teamSearch.table.label.category"    : "Category",
    "teams.teamSearch.table.label.org"         : "Organization",
    "teams.teamSearch.table.paginationMessage" : "Teams {itemStartPos} to {itemEndPos} out of a total of {totalItems}",
    "teams.teamSearch.table.pendingDeficiency" : "Pending Deficiency",

    "teams.teamSearch.select.status.DRAFT"     : "Draft",
    "teams.teamSearch.select.status.PENDING"   : "Pending",
    "teams.teamSearch.select.status.APPROVED"  : "Approved",
    "teams.teamSearch.select.status.SUBMITTED" : "Submitted",

    "teams.teamSearch.sideTable.label.staff"           : "Staff",
    "teams.teamSearch.sideTable.label.comments"        : "Comments",
    "teams.teamSearch.sideTable.label.contactInfo"     : "Contact",
    "teams.teamSearch.sideTable.label.teamInfo"        : "Team Informations",
    "teams.teamSearch.sideTable.label.teamContact"     : "Team Contact",
    "teams.teamSearch.sideTable.label.orgContact"      : "Organization Contact",
    "teams.teamSearch.sideTable.delete.msg"            : "You are about to delete this team",
    "teams.teamSearch.sideTable.delete.multiple.msg"   : "You are about to delete these teams",
    "teams.teamSearch.sideTable.actionsMenu.submit"    : "Submit for approval",
    "teams.teamSearch.sideTable.actionsMenu.resubmit"  : "Resubmit for approval",
    "teams.teamSearch.sideTable.actionsMenu.approve"   : "Approve team",
    "teams.teamSearch.sideTable.actionsMenu.reject"    : "Reject team",
    "teams.teamSearch.sideTable.actionsMenu.cancel"    : "Cancel approval",
    "teams.teamSearch.sideTable.actionsMenu.delete"    : "Delete Team",
    "teams.teamSearch.sideTable.actionsMenu.PDFExport" : "PDF Export",

    "teams.teamSearch.sideTable.bulkUpdate"                         : "Bulk update",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.topMessage"     : "You are about to update the approbation status of all eligible teams below.",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.validTitle"     : "Teams ({teams})",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.validText"      : "The following teams will be updated",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.validNone"      : "No teams",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.actionNone"     : "No teams are eligible for this action.",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.invalidTitle"   : "Ineligible teams ({teams})",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.invalidText"    : "The following teams will not be updated because of their status",
    "teams.teamSearch.sideTable.bulkUpdate.validTeams.comment"      : "Comment",
    "teams.teamSearch.sideTable.bulkUpdate.validTeams.comment.hint" : "This comment is for every team.",

    "teams.teamSearch.sideTable.noTeamsContact"  : "This team doesn't have any contacts.",
    "teams.teamSearch.sideTable.selectedTeams"   : "selected teams",
    "teams.teamSearch.export.emptyTeams"         : "You cannot export teams with no players.",
    "teams.teamSearch.export.onlyPrintApproved"  : "Error: Unapproved Team",
    "teams.teamSearch.export.onlyPrintApproved2" : "Cannot export team book of an unapproved team.",
    "teams.teamSearch.export.maxTenTeams"        : "Please select 10 teams or less.",
}
