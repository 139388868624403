import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Alert, Button, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useContext } from "react";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { useFormikContext } from "formik";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { UtilsContext } from "../../../../../contexts/UtilsContext";
import Required from "../../../../../components/formik/Required";
import { Tooltip } from "@mantine/core";

const PostalCodesSearch = ({ toggle, goToPrevious }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const formik = useFormikContext();
    const { country_code, province_code } = useContext(OrganizationContext);
    const { getCountries } = useContext(UtilsContext);

    return (
        <>
            <ModalHeader toggle={toggle}><Translate id='organization.profile.postalCodes.modals.add.step1.title' /></ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for='province'><Translate id='form.fields.province' /> <Required /></Label>
                    <FormikSelect
                        id='province'
                        name='province'
                        searchKeys={[ `i18n.${getGenericLocale()}.name` ]}
                        loadData={(from) => {
                            switch (from){
                                case 'CDM':
                                    return getCountries({ get_all_subdivisions: 1, country_code: country_code || 'CA' })
                                        .then(([ country ]) => {
                                            return country.sub_divisions.reduce((states, province) => {
                                                if(province.active == '1'){
                                                    states.push({
                                                        value: province.code,
                                                        i18n: province.i18n,
                                                    });
                                                    if(province_code === province.code){
                                                        formik.setFieldValue('province', province_code);
                                                    }
                                                }
                                                return states;
                                            }, [])
                                        })
                                default:
                                    break;
                            }
                        }}
                        renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.value} i18n={option.i18n} />}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for='city'><Translate id='form.fields.city' /></Label>
                    <FormikInputText name='city' id='city' autoComplete='address-level2' trim />
                </FormGroup>
                <FormGroup>
                    <Label for='partialPostalCode'>
                        <Translate id='organization.profile.postalCodes.modals.add.step1.fields.partialZipCode.label' />
                        <Tooltip
                            withArrow
                            label={<Translate id='organization.profile.postalCodes.modals.add.step1.fields.partialZipCode.hint' />}
                            zIndex={3000}
                        >
                            <i className='mdi mdi-information-outline text-primary ml-1' />
                        </Tooltip>
                    </Label>
                    <FormikInputText name='partialPostalCode' id='partialPostalCode' autoComplete='postal-code' trim maxLength={3} />
                </FormGroup>
                <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                    <Alert color='danger' className='mb-0'>
                        {formik.status}
                    </Alert>
                </Collapse>
            </ModalBody>
            <ModalFooter>
                {goToPrevious &&
                    <Button color="primary" outline type='button' onClick={goToPrevious}><Translate id='misc.previous' /></Button>
                }
                <div className='ml-auto'>
                    <Button color="primary" type='submit' className='mr-2'><Translate id='misc.search' /></Button>
                    <Button color="primary" outline type='button' onClick={toggle}><Translate id='misc.close' /></Button>
                </div>
            </ModalFooter>
        </>
    )
}

export default PostalCodesSearch;