export default{
    // Member Add
    "members.search.memberAdd.title"            : "Add Member",
    "members.search.memberAddInleligible.title" : "Add an Ineligible Member",

    "members.search.memberAdd.foundSearchResults"     : "We found {amount, plural, one {a matching member} other {matching members}}",
    "members.search.memberAdd.createMember"           : "Create new member",
    "members.search.memberAdd.createWithName"         : "Create {firstName} {lastName}",
    "members.search.memberAdd.primaryOrganization"    : "Primary Organization",
    "members.search.memberAdd.dateOfBirth.range"      : "Date must represent an age between 4 and 100 years old",
    "members.search.memberAdd.dateOfBirth.minAge"     : "A member must be at least 4 years old to participate. However, you may still create this member.",
    "members.search.memberAdd.dateOfBirth.minDate"    : "Date of birth must be before today.",
    "members.search.memberAdd.firstName.regex"        : "First name must be of a valid format",
    "members.search.memberAdd.lastName.regex"         : "Last name must be of a valid format",
    "members.search.memberAdd.reviewPreviousStep"     : "Review previous step",
    "members.search.memberAdd.confirmUser"            : "Confirm Member",
    "members.search.memberAdd.confirmUser.subtitle"   : "This member will be marked as confirmed",
    "members.search.memberAdd.unconfirmUser"          : "Unconfirm Member",
    "members.search.memberAdd.unconfirmUser.subtitle" : "This member will not be confirmed. It can be confirmed on the member's profile page",

    "members.search.memberAdd.potentially.ineligible"   : "This member will be flagged as “Potentially Ineligible”",
    "members.search.memberAdd.perfect.match.ineligible" : "The member you are creating is a perfect match with an ineligible member. This member will be created with the status {status}",

    "members.search.memberAdd.primaryLanguage.helper" : "Please indicate in which official language - English or French - you prefer to receive correspondences.",

    "members.search.memberAdd.btn.profile"   : "Profile",
    "members.search.memberAdd.btn.transfer"  : "{transfer_title}",
    "members.search.memberAdd.btn.createNew" : "Create new member",

    "members.search.memberAdd.success"        : "Member added with success",
    "members.search.memberAdd.error"          : "An error occurred while adding the member",
    "members.search.memberAdd.error.2000.btn" : "Click here to edit this member's first name and last name.",

    // Member Import
    "members.search.memberImport.title" : "Import Members",
}