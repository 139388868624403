// eslint-disable-next-line unused-imports/no-unused-imports
import { stringBuilder } from "@spordle/helpers";
import React, { createContext, useEffect, useState } from "react";

/** @type {React.Context<Omit<Swiper, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const SwiperContext = createContext();
SwiperContext.displayName = 'SwiperContext';

/**
 * @param {object} props
 * @param {JSX.Element|string} props.containerTag
 * @param {string} props.className
 * @returns {React.ReactNode}
 */
const Swiper = ({
    containerTag: Tag = "div",
    className,
    initSlide = 0,
    children,
}) => {
    const [ currentSlide, setCurrentSlide ] = useState(initSlide);
    const [ isTransitioning, setIsTransitioning ] = useState(false);
    const slides = React.Children.toArray(children).filter((child) => String(child.type.displayName) === "SwipeSlide");

    const swipeTo = (viewNb) => {
        if(!isTransitioning){
            setIsTransitioning(true);
            setCurrentSlide(viewNb);
        }
    }

    const next = () => {
        swipeTo(Math.min(currentSlide + 1, slides.length - 1));
    }

    const previous = () => {
        swipeTo(Math.max(currentSlide - 1, 0));
    }


    useEffect(() => {
        if(slides.length !== React.Children.toArray(children).length){
            console.error("Children of Swiper should only be SwipeSlide");
        }
    }, []);

    return (
        <SwiperContext.Provider
            value={{
                activeSlide: currentSlide,
                next,
                previous,
                setIsTransitioning,
            }}
        >
            <Tag className={stringBuilder(className, "overflow-hidden position-relative")}>
                {slides}
            </Tag>
        </SwiperContext.Provider>
    )
}

export default Swiper;