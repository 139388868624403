import Translate from '@spordle/intl-elements';
import moment from 'moment';
import { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, CardBody } from 'reactstrap';
import useSWR from 'swr';
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import TeamCommentsAddModal from './TeamCommentsAddModal';
import { success, fail } from '@spordle/toasts';
import ConfirmModal from '../../../../../components/confirmModal/ConfirmModal';
import CanDoAction from '../../../../../components/permissions/CanDoAction';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';

const TeamCommentsSidepanel = ({ teamMember }) => {
    const teamsContext = useContext(TeamsContext);

    const { data: comments, error, isValidating, mutate } = useSWR(
        [ 'getTeamMemberComments', teamsContext.cachedTeam.team_id, teamMember.team_member_id ],
        () => teamsContext.getTeamMemberComments(teamsContext.cachedTeam.team_id, teamMember.team_member_id)
            .then((comments) => {
                return comments.sort((commentA, commentB) => {
                    if(commentB.created_at){
                        return commentA.created_at ? commentB.created_at.localeCompare(commentA.created_at) : 1;
                    }
                    return commentA.created_at ? -1 : 0;

                })
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        ,
        {
            revalidateOnFocus: false,
        },
    );

    const deleteComment = (team_member_comment_id) => {
        teamsContext.deleteTeamMemberComment(teamsContext.cachedTeam.team_id, teamMember.team_member_id, team_member_comment_id)
            .then(() => {
                success();
                mutate('getTeamMemberComments');
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    return (
        <>
            {error ?
                <div className='text-dark text-center font-bold mb-0'>
                    <Translate id='misc.error' />
                </div>
                :
                <>
                    <AddCommentButton teamMember={teamMember} mutate={mutate} />
                    {!isValidating && comments ?
                        <>
                            {(!Array.isArray(comments) || comments.length == 0) ?
                                <div className='text-muted text-center mb-0'>
                                    <Translate id='teams.profile.roster.sidePanel.teamMemberComments.none' />
                                </div>
                                :
                                comments.map((comment) => (
                                    <Card key={comment.team_member_comment_id} className="card-shadow mb-3">
                                        <CardBody className="p-3">
                                            <div className="border-bottom pb-1 mb-3">
                                                <div className='d-flex justify-content-between'>
                                                    <div className='text-dark font-bold mb-0 text-break'>
                                                        {comment.identity.name} {comment.identity.family_name}
                                                    </div>
                                                    <div className='small text-right flex-shrink-0 ml-1'>
                                                        {comment.created_at && moment(comment.created_at).fromNow()}
                                                        <span className="mx-2">|</span>
                                                        <ConfirmModal
                                                            modalTitle='teams.profile.roster.sidePanel.teamMemberComments.delete'
                                                            translateModalTitle
                                                            toggler={(fn) => (
                                                                <CanDoAction
                                                                    className="p-3 m-n3 align-self-start"
                                                                    componentCode="teams"
                                                                    componentPermissionCode="team_comments_manage"
                                                                    action="DELETE"
                                                                >
                                                                    <div className="clickable d-inline" onClick={fn}>
                                                                        <i className="fas fa-trash text-danger" />
                                                                    </div>
                                                                </CanDoAction>
                                                            )}
                                                            onConfirm={() => deleteComment(comment.team_member_comment_id)}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {comment.comment}
                                            </div>
                                        </CardBody>
                                    </Card>
                                ))
                            }
                        </>
                        :
                        <Skeleton height={120} count={3} className='mb-4' />
                    }
                </>
            }
        </>
    );
}

const AddCommentButton = ({ teamMember, mutate }) => {
    const [ modalIsOpen, setModalOpen ] = useState(false);
    const toggleModal = () => { setModalOpen(!modalIsOpen) };

    return (
        <div className="border-bottom mb-3">
            <UserDisplay card hover className="border-primary p-0 text-center w-100 mb-3" style={{ borderStyle: 'dashed' }}>
                <button
                    onClick={toggleModal}
                    type="button"
                    className="reset-btn text-link p-3 w-100"
                >
                    <i className="mdi mdi-plus mr-1" /><Translate id='teams.profile.roster.sidePanel.teamMemberComments.add' />
                    <TeamCommentsAddModal isOpen={modalIsOpen} toggle={toggleModal} teamMember={teamMember} mutate={mutate} />
                </button>
            </UserDisplay>
        </div>
    )
}

export default TeamCommentsSidepanel;