import { jsObjectToApi } from "@spordle/helpers";
import API_PUBLIC from "../API-Public";
import { serverError } from "../CancellableAPI";
import queryString from "query-string";
import API_SPORDLE from "../API-Spordle";

/**
 * Get organization info
 * @param {string} orgId
 * @returns {Promise}
 */
 export function getOrganizationPublic(orgId: string){
    return API_PUBLIC.get(queryString.stringifyUrl({ url: `organisations/${orgId}` }))
        .then((response) => {
            if(response.data.status){
                return response.data.organisation[0];
            }
            throw response.data.errors[0];
        }, serverError)
}

export function getOrganizationCourses(orgId: string, query){
    return API_PUBLIC.get(queryString.stringifyUrl({ url: `organisations/${orgId}/external-courses`, query: query }))
        .then((response) => {
            if(response.data.status){
                return response.data.organisation_external_courses;
            }
            throw response.data.errors[0];
        }, serverError)
}

export function getOrganizationTaxNumbers(orgId: string){
    return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${orgId}/tax-numbers` }))
        .then((response) => {
            if(response.data.status){
                return response.data.tax_numbers;
            }
            throw response.data.errors;
        }, serverError)
}

export function getOrganizationSpecificTaxNumber(orgId: string, taxNbId: string){
    return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${orgId}/tax-numbers/${taxNbId}` }))
        .then((response) => {
            if(response.data.status){
                return response.data.tax_numbers[0];
            }
            throw response.data.errors;
        }, serverError)
}

export function createOrganizationTaxNumber(orgId: string, values: {
    tax_class_id: string,
    country_code: string,
    province_code: string,
    tax_number: string,
    tax_name: string
}){
    const apiParams = new URLSearchParams();

    jsObjectToApi(values, apiParams);

    return API_SPORDLE.post(queryString.stringifyUrl({
        url: `organisations/${orgId}/tax-numbers`,
    }), apiParams)
        .then((response) => {
            if(response.data.status){
                return response.data.tax_number_id;
            }
            throw response.data.errors;
        }, serverError);
}

export function partiallyUpdateOrganizationTaxNumber(orgId: string, taxNbId: string, values: { tax_number: string }){
    const apiParams = new URLSearchParams();

    jsObjectToApi(values, apiParams);

    return API_SPORDLE.patch(queryString.stringifyUrl({
        url: `organisations/${orgId}/tax-numbers/${taxNbId}`,
    }), apiParams)
        .then((response) => {
            if(response.data.status){
                return true;
            }

            throw response.data.errors;
        }, serverError);
}

export function deleteOrganizationTaxNumber(orgId: string, taxNbId: string, values: { tax_number: string }){

    return API_SPORDLE.delete(queryString.stringifyUrl({
        url: `organisations/${orgId}/tax-numbers/${taxNbId}`,
    }))
        .then((response) => {
            if(response.data.status){
                return true;
            }

            throw response.data.errors;
        }, serverError);
}

