export default{
    "organization.settings.organizationTeamContactTypes.tab.title" : "Types de contact d'équipe",

    "organization.settings.organizationTeamContactTypes.table.active" : "Actif",
    "organization.settings.organizationTeamContactTypes.table.empty"  : "Aucun type de contact",

    // add
    "organization.settings.organizationTeamContactTypes.add.title"           : "Ajouter un type de contact",
    "organization.settings.organizationTeamContactTypes.add.status"          : "Statut",
    "organization.settings.organizationTeamContactTypes.add.status.active"   : "Actif",
    "organization.settings.organizationTeamContactTypes.add.status.inactive" : "Inactif",
    "organization.settings.organizationTeamContactTypes.add.status.required" : "Le statut est requis",
}