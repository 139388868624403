export default{
    "task.waitingList.title"                                             : "Waiting List",
    "task.waitingList.column.waiting_list_item_sequential_number"        : "Reference #",
    "task.waitingList.column.created_at"                                 : "Added date",
    "task.waitingList.column.waiting_list_item_sequential_number.online" : "Online registration",
    "task.waitingList.column.waiting_list_item_sequential_number.admin"  : "Admin registration",
    "task.waitlist.registration"                                         : "Registration",
    "task.waitlist.registration.required"                                : "Please select a registration",
    "task.waitlist.status"                                               : "Status",
    "task.waitlist.status.PENDING"                                       : "Pending",
    "task.waitlist.status.WAITING_FOR_CLIENT"                            : "Waiting For Client",
    "task.waitlist.status.CANCELLED"                                     : "Cancelled",
    "task.waitlist.status.CANCELED"                                      : "Cancelled",
    "task.waitlist.status.CONVERTED"                                     : "Converted",
    "task.waitlist.status.alert.title"                                   : "This member has been converted.",
    "task.waitlist.status.alert.text"                                    : "Invoice date: {date}",
    "task.waitlist.notConfirmed"                                         : "Unconfirmed",
    "task.waitlist.accept"                                               : "Accept",
    "task.waitlist.accept.title"                                         : "Accept member",
    "task.waitlist.reset"                                                : "Reset Status",
    "task.waitlist.reset.text"                                           : "Reset the status to pending.",
    "task.waitlist.cancel"                                               : "Cancel",
    "task.waitlist.cancelReason.label"                                   : "Cancellation Reason",
    "task.unsactioned.club.title"                                        : "Unconfirmed Organization",
    "task.member.attachment.approval.title"                              : "Member Document Approval",
}