import { JsonInput, Tooltip } from "@mantine/core";
import { FormikCheckedButton, FormikInputNumber, FormikInputText, FormikSelect } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import { FieldArray, Formik, Form, useFormikContext } from "formik";
import { useContext, useRef, useState } from "react";
import { Button, Col, Collapse, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { array, bool, mixed, number, object, string } from "yup";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import Required from "../../../../../components/formik/Required";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import { AppContext } from "../../../../../contexts/contexts";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import { ReportsContext } from "../../../../../contexts/ReportsContext";
import { copyToClipBoard, HoverableCopy } from "../../../../../helpers/clipboardHelper";
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../../../helpers/i18nHelper";

/**
 * @param {object} props
 * @param {EDIT|CREATE} [props.mode="EDIT"]
 * @param {object} props.currentReport
 * @param {string} props.currentReport.report_id
 * @param {{ filter_id: string }[]} props.currentReport.filters
 * @param {object} [props.currentFilter]
 * @param {string} [props.currentFilter.report_filter_id]
 * @param {function} props.toggle
 * @returns
 */
const FilterForm = ({ toggle, mode = "EDIT", currentFilter = {} }) => {
    const { getInitialValues, getValidationSchema } = useContext(I18nHelperContext);
    const { updateRouteKey } = useContext(AppContext);
    const { currentReport, createReportFilter, partiallyUpdateReportFilter, getFilterList, createFilter } = useContext(ReportsContext);
    const { isDev } = useContext(IdentityRolesContext);

    const [ newFilterId, setNewFilterId ] = useState('');

    const formikRef = useRef();

    const formatJSON = (jsonString) => {
        try{
            const parsed = JSON.parse(jsonString);
            return JSON.stringify(parsed, null, 2);
        }catch(e){
            return jsonString;
        }
    }

    const getOptions = (jsonOptions) => {
        if(jsonOptions){
            try{
                const options = JSON.parse(jsonOptions);

                if(Array.isArray(options)){
                    return options;
                }
            }catch(e){
                return [];
            }
        }

        return [];
    }

    const handleSubmit = (values, formikBag) => {
        const apiValues = {
            display_order: values.display_order,
            default_value: values.default_value,
            required: values.required ? 1 : 0,
            type: values.type,
            active: 1,
        };

        if(values.option.length > 0){
            try{
                const stringified = JSON.stringify(values.option.map((option) => ({ ...option, label: option.name })));
                apiValues.option = stringified;
            }catch(e){
                console.error(e);
            }
        }

        try{
            const reformatted = JSON.stringify(JSON.parse(values.param));
            apiValues.param = reformatted;
        }catch(e){
            console.error(e);
        }

        if(mode === "EDIT"){
            formikBag.setSubmitting(true);

            if(newFilterId){
                apiValues.filter_id = newFilterId;
            }else if(values.filter_id !== currentFilter.filter_id){
                apiValues.filter_id = values.filter_id;
            }

            partiallyUpdateReportFilter(currentReport.report_id, currentFilter.report_filter_id, apiValues)
                .then(updateRouteKey)
                .catch((e) => {
                    console.error(e);
                    formikBag.setSubmitting(false);
                });
        }else if(mode === "CREATE"){
            if(newFilterId)
                apiValues.filter_id = newFilterId;
            else
                apiValues.filter_id = values.filter_id;

            createReportFilter(currentReport.report_id, apiValues)
                .then(updateRouteKey)
                .catch((e) => {
                    console.error(e);
                    formikBag.setSubmitting(false);
                });
        }else{
            toggle();
        }
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                display_order: currentFilter.display_order ? parseInt(currentFilter.display_order) : currentReport.filters?.length || 0,
                filter_id: currentFilter.filter_id || "",
                code: currentFilter.code || "",
                type: currentFilter.type || "",
                required: currentFilter.required == 1,
                default_value: (currentFilter.type === 'MULTI_SELECT' && Array.isArray(currentFilter.default_value)) ? currentFilter.default_value.split(',') : currentFilter.default_value || "",
                param: currentFilter.param ? formatJSON(currentFilter.param) : formatJSON("{\"col\":{\"md\":6,\"lg\":4}}"),
                option: getOptions(currentFilter.option),
                filterCode: '',
                filterName: '',
                i18n: {
                    en: { name: '' },
                    fr: { name: '' },
                },
            }}
            validationSchema={object().shape({
                filter_id: string().required(),
                display_order: number().min(0).required(),
                type: string().required(),
                required: bool(),
                value: mixed().when('type', (type) => {
                    switch (type){
                        case 'MULTI_SELECT':
                            return array();
                        default:
                            return string();
                    }
                }),
                params: string(),
                option: array().of(object().shape({
                    value: string(),
                    ...getValidationSchema({ name: string().required() }),
                })),
                filterCode: string().test({
                    name: 'filterCodeRequired',
                    message: 'Filter code is required for creating a new filter.',
                    test: function(val){
                        if(this.parent.filter_id === 'NEW_FILTER')
                            return !!val;
                        return true;
                    },
                }),
                filterName: string().test({
                    name: 'filterNameRequired',
                    message: 'Filter name is required for creating a new filter.',
                    test: function(val){
                        if(this.parent.filter_id === 'NEW_FILTER')
                            return !!val;
                        return true;
                    },
                }),
                ...getValidationSchema({ 'name': string().test({
                    name: 'filterTranslatedNameRequired',
                    message: 'Translated filter names are required for creating a new filter.',
                    test: function(val){
                        if(formikRef.current?.values?.filter_id === 'NEW_FILTER')
                            return (!!val);
                        return true;
                    },
                }) }),
            })}
            onSubmit={(values, formikBag) => {
                if(values.filter_id == 'NEW_FILTER'){
                    createFilter({
                        code: values.filterCode,
                        name: values.filterName,
                        i18n: values.i18n, // i18n name
                        type: values.type,
                    }).then((filter_id) => {
                        setNewFilterId(filter_id);

                        const apiValues = { ...values }

                        delete apiValues.name;
                        delete apiValues.i18n;
                        delete apiValues.code;
                        delete apiValues.filter_id;

                        handleSubmit(values, formikBag);
                    }).catch((e) => {
                        formikBag.setSubmitting(false);
                        console.error(e)
                    })
                }else{
                    // submit without post
                    handleSubmit(values, formikBag);
                }
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader className="text-capitalize" toggle={toggle}>{mode} Filter</ModalHeader>
                        <ModalBody>
                            <div className="h4 font-medium mb-1"><span className="font-bold">{currentReport.name}</span> Report</div>
                            <p>{currentReport.description}</p>
                            <HoverableCopy toCopy={currentReport.code}>
                                <Label className="mr-2 text-muted">Current Report Code:</Label>
                                <code className="bg-light rounded-lg p-1">{currentReport.code}</code>
                            </HoverableCopy>
                            {mode === "EDIT" &&
                                <>
                                    <HoverableCopy toCopy={currentFilter.code}>
                                        <Label className="mr-2 text-muted">Current Filter Code:</Label>
                                        <code className="bg-light rounded-lg p-1">{currentFilter.code}</code>
                                    </HoverableCopy>
                                    <HoverableCopy toCopy={currentFilter.filter_id}>
                                        <Label className="mr-2 text-muted">Current Filter ID:</Label>
                                        <code className="bg-light rounded-lg p-1">{currentFilter.filter_id}</code>
                                    </HoverableCopy>
                                </>
                            }
                            <hr />
                            {formik.values.code ?
                                <HoverableCopy toCopy={formik.values.code}>
                                    <Label className="text-muted">Filter Code</Label>
                                </HoverableCopy>
                                :
                                <Label className="text-muted">Filter Code</Label>
                            }
                            <FormikSelect
                                name="filter_id"
                                id="filter_id"
                                renderSelectedOption={(option) => (
                                    <>
                                        {option.value == 'NEW_FILTER' && <i className="mdi mdi-plus-circle text-danger mr-2" />}
                                        <code className="p-1 bg-light rounded-lg">{option.label}</code> {option.filter.type && <span className="text-muted">({option.filter.type})</span>}
                                    </>
                                )}
                                isOptionDisabled={(option) => currentReport.filters?.some((filter) => filter.filter_id === option.value)}
                                renderOption={(option) => (
                                    <>
                                        <div> {option.option.value == 'NEW_FILTER' && <i className="mdi mdi-plus-circle text-danger mr-2" />}<DisplayI18n field="name" defaultValue={option.option.filter.name} i18n={option.option.filter.i18n} /></div>
                                        <code className={stringBuilder({ "text-white font-bold": option.isSelected })}>
                                            {option.option.label}
                                        </code>
                                        <div className={stringBuilder("small", option.isSelected ? "text-light" : "text-muted")}>
                                            {option.isDisabled ? "Already in report" : option.option.filter.type}
                                        </div>
                                    </>
                                )}
                                onOptionSelected={([ value ], { getSpordleTable }) => {
                                    const option = getSpordleTable().getData().find((filter) => filter.value === value);

                                    if(option?.filter && value !== 'NEW_FILTER'){
                                        formik.setFieldValue("type", option.filter.type);
                                        formik.setFieldValue("name", option.filter.name);
                                        formik.setFieldValue("i18n", option.filter.i18n);
                                        formik.setFieldValue("default_value", option.filter.default_value || "");
                                    }
                                }}
                                loadData={(from) => {
                                    switch (from){
                                        case 'CDM':
                                            return getFilterList()
                                                .then((filters) => {
                                                    const allFilters = filters
                                                        .sort((a) => currentReport.filters?.some((filter) => filter.filter_id === a.filter_id) ? 1 : -1)
                                                        .map((filter) => ({
                                                            label: filter.code,
                                                            value: filter.filter_id,
                                                            filter: filter,
                                                        }));


                                                    if(isDev())
                                                        allFilters.unshift({
                                                            label: 'NEW_FILTER',
                                                            value: 'NEW_FILTER',
                                                            filter: {
                                                                "code": "NEW FILTER",
                                                                "name": "NEW FILTER",
                                                                "description": "NEW FILTER",
                                                                "active": "1",
                                                                "i18n": {
                                                                    "fr": {
                                                                        "name": "AJOUTER NOUVEAU FILTRE",
                                                                        "active": "1",
                                                                    },
                                                                    "en": {
                                                                        "name": "ADD NEW FILTER",
                                                                        "active": "1",
                                                                    },
                                                                },

                                                            },
                                                        })
                                                    return allFilters;
                                                })
                                        default:
                                            break;
                                    }
                                }}
                            />
                            <CrossFade isVisible={formik.values.filter_id == 'NEW_FILTER'}>
                                <div className="border border-danger border p-3 mt-3">
                                    <div className="font-bold h5">Create a new filter</div>
                                    <div className="h6 text-muted">This section allows you to create a new filter. Please make sure the all fields are accurate.</div>
                                    <Row>
                                        <Col md={6}>
                                            <Label>Filter Code <Required /></Label>
                                            <FormikInputText
                                                name='filterCode'
                                                id='filterCode'
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Label>Filter Name <Required /></Label>
                                            <FormikInputText
                                                name='filterName'
                                                id='filterName'
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <RenderI18nForm field="name">
                                            {({ fieldName, fieldLabel }) => (
                                                <Col className="form-group" md="6" key={fieldName}>
                                                    <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                </Col>
                                            )}
                                        </RenderI18nForm>
                                    </Row>
                                </div>
                            </CrossFade>
                            {/* <small className="d-block text-muted mt-1"><i className="mdi mdi-alert-outline text-warning mr-1" />If you need a new code that isn&apos;t listed here, ask someone from the backend team to add it.</small> */}
                            <Row className="mt-3 pt-3 border-top" form>
                                {formik.values.name &&
                                    <Col sm="12" className="mb-3">
                                        <div className="h4 mb-1 font-medium">
                                            <span className="font-bold">
                                                <DisplayI18n field="name" defaultValue={formik.values.name} i18n={formik.values.i18n} />
                                            </span> Filter
                                        </div>
                                        <div>
                                            <span className="mr-2 border-right pr-2"><span className="font-medium">EN</span>: {formik.values.i18n?.en?.name || "-"}</span>
                                            <span><span className="font-medium">FR</span>: {formik.values.i18n?.fr?.name || "-"}</span>
                                        </div>
                                    </Col>
                                }
                                <Col sm="12">
                                    <Collapse isOpen={mode === "EDIT" || !!formik.values.filter_id}>
                                        <Row form>
                                            <Col sm="6" className="form-group">
                                                <Label className="text-muted" for="type">Filter Type</Label>
                                                <FormikSelect
                                                    name="type"
                                                    id="type"
                                                    loadingStatus="success"
                                                    renderOption={({ option }) => (
                                                        <>
                                                            {option.label}
                                                            <div className="small text-muted">{option.desc}</div>
                                                        </>
                                                    )}
                                                    renderSelectedOption={(option) => option.label}
                                                    defaultData={[
                                                        {
                                                            value: "SELECT",
                                                            label: "SELECT",
                                                            desc: "These select filters have a set or dynamic number or options.",
                                                        },
                                                        {
                                                            value: "MULTI_SELECT",
                                                            label: "MULTI_SELECT",
                                                            desc: "You can select multiple options, it outputs an array",
                                                        },
                                                        {
                                                            value: "ORGANIZATION_SELECT",
                                                            label: "ORGANIZATION_SELECT",
                                                            desc: "Allow a user to select one organization in the filters",
                                                        },
                                                        {
                                                            value: "MULTI_ORGANIZATION_SELECT",
                                                            label: "MULTI_ORGANIZATION_SELECT",
                                                            desc: "Allow a user to select multiple organizations in the filters",
                                                        },
                                                        {
                                                            value: "PERIOD_SELECT",
                                                            label: "PERIOD_SELECT",
                                                        },
                                                        {
                                                            value: "GENDER_SELECT",
                                                            label: "GENDER_SELECT",
                                                            desc: "Allow a user to select gender identities. Options pumped from org settings.",
                                                        },
                                                        {
                                                            value: "GENDER_MULTI_SELECT",
                                                            label: "GENDER_MULTI_SELECT",
                                                            desc: "Allow a user to select multiple gender identities. Options pumped from org settings.",
                                                        },
                                                        {
                                                            value: "DATE",
                                                            label: "DATE",
                                                            desc: "Allows a user to input a date",
                                                        },
                                                        {
                                                            value: "TIME",
                                                            label: "TIME",
                                                        },
                                                        {
                                                            value: "DATE_BETWEEN",
                                                            label: "DATE_BETWEEN",
                                                            desc: "Will have validation to make sure the first date is before the other",
                                                        },
                                                        {
                                                            value: "SEARCH",
                                                            label: "SEARCH",
                                                            desc: "This places the search button",
                                                        },
                                                        {
                                                            value: "CHECKBOX",
                                                            label: "CHECKBOX",
                                                            desc: "The check box allows users to check boxes",
                                                        },
                                                        {
                                                            value: "NUMBER",
                                                            label: "NUMBER",
                                                            desc: "Number input, can be used for any numbers, validation to be determined, most likely will accept positive numbers",
                                                        },
                                                        {
                                                            value: "NUMBER_BETWEEN",
                                                            label: "NUMBER_BETWEEN",
                                                            desc: "The name for the formik elements on a NUMBER_BETWEEN will be start_{{code}} and end_{{code}}",
                                                        },
                                                        {
                                                            value: "QUESTIONNAIRE_FORM",
                                                            label: "QUESTIONNAIRE_FORM",
                                                            desc: "Special filter for the questionnaire report that will load new headers depending on the selected form",
                                                        },
                                                        {
                                                            value: "PERIOD_AND_REFERENCE_PERIOD",
                                                            label: "PERIOD_AND_REFERENCE_PERIOD",
                                                        },
                                                    ]}
                                                />
                                                <small className="d-block text-muted mt-1">This determines what type of filter we display</small>
                                            </Col>
                                            <Col className="form-group" sm="6">
                                                <Label>&nbsp;</Label>
                                                <FormikCheckedButton
                                                    translateLabel={false}
                                                    label="Is Required"
                                                    name="required"
                                                    className="mb-1 mt-2"
                                                />
                                                <small className="d-block text-muted pt-1">Determines whether or not this filter is required, activates the other types of validation depending on the filter type</small>
                                            </Col>
                                            <Col sm="6" className="form-group">
                                                <Label className="text-muted" for="display_order">Display order</Label>
                                                <FormikInputNumber type="number" name="display_order" id="display_order" />
                                                <small className="mt-1 d-block text-muted">The sequential position at which this filter is displayed</small>
                                            </Col>
                                            <Col sm="6" className="form-group">
                                                <Label className="text-muted" for="default_value">Default value</Label>
                                                <DefaultValueInput />
                                                <small className="mt-1 d-block text-muted">Filter dependant type - the value that is displayed and in the filter at first render</small>
                                            </Col>
                                            <Col sm="12">
                                                <Collapse isOpen={(formik.values.type || "").includes("SELECT")}>
                                                    <Label className="text-muted mb-0">Options</Label>
                                                    <small className="d-block text-muted">Used to serve data to the select if using ‘STATIC’ mode for a spordle select.</small>
                                                    <FieldArray
                                                        name="option"
                                                        render={(helpers) => (
                                                            <>
                                                                {formik.values.option.map((option, index) => (
                                                                    // eslint-disable-next-line react/no-array-index-key
                                                                    <div key={index} className="bg-light border rounded-lg p-2 position-relative mt-2">
                                                                        <div className="h6 font-medium">Option #{index + 1}</div>
                                                                        <button className="position-absolute top-0 right-0 p-2 reset-btn ti ti-trash text-danger" type="button" onClick={() => helpers.remove(index)} />
                                                                        <Row form>
                                                                            <Col sm="4">
                                                                                <Label className='text-muted' for={`option.${index}.value`}>Value</Label>
                                                                                <FormikInputText name={`option.${index}.value`} />
                                                                            </Col>
                                                                            <RenderI18nForm field="name">
                                                                                {({ fieldName, fieldLabel }) => (
                                                                                    // eslint-disable-next-line react/no-array-index-key
                                                                                    <Col sm="4" key={`option.${index}.${fieldName}`}>
                                                                                        <Label for={`option.${index}.${fieldName}`} className='text-muted'>{fieldLabel} <Required /></Label>
                                                                                        <FormikInputText id={`option.${index}.${fieldName}`} name={`option.${index}.${fieldName}`} trim />
                                                                                    </Col>
                                                                                )}
                                                                            </RenderI18nForm>
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                                <Button
                                                                    type="button"
                                                                    block
                                                                    color="link"
                                                                    onClick={() => helpers.push({ value: "", ...getInitialValues() })}
                                                                >
                                                                    + Add option
                                                                </Button>
                                                            </>
                                                        )}
                                                    />
                                                </Collapse>
                                            </Col>
                                            <Col sm="12">
                                                <Label className="d-flex align-items-center text-muted" for="param">
                                                    <span className="mr-auto">
                                                        Params (JSON)
                                                    </span>
                                                    <Tooltip zIndex="5000" position="top" withArrow label="Copy">
                                                        <button type="button" onClick={() => copyToClipBoard(formik.values.param)} className="clickable reset-btn mdi mdi-content-copy" />
                                                    </Tooltip>
                                                </Label>
                                                <JsonInput
                                                    spellCheck={false}
                                                    minRows={10}
                                                    autosize
                                                    validationError="Invalid json"
                                                    value={formik.values.param}
                                                    formatOnBlur
                                                    error={formik.errors.param}
                                                    onChange={(val) => {
                                                        formik.setFieldValue("param", val)
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Collapse>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            {mode === "EDIT" &&
                                <DeleteBtn filter={currentFilter} report={currentReport} />
                            }
                            <Button disabled={formik.isSubmitting} type="submit" color="primary">Submit</Button>
                            <Button disabled={formik.isSubmitting} type="button" onClick={toggle} outline color="primary">Cancel</Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

const DeleteBtn = ({ report, filter }) => {
    const [ isConfirming, setIsConfirming ] = useState(false);
    const { setSubmitting, isSubmitting } = useFormikContext();
    const { deleteReportFilter } = useContext(ReportsContext);
    const { updateRouteKey } = useContext(AppContext);

    const handleOnClick = () => {
        if(isConfirming){
            setSubmitting(true);
            deleteReportFilter(report.report_id, filter.report_filter_id)
                .then(updateRouteKey)
                .catch((e) => {
                    console.error(e);
                    setSubmitting(false);
                })
        }else{
            setIsConfirming(true);
        }
    }

    return (
        <Tooltip
            className="mr-auto"
            opened={isConfirming}
            label={
                <div className="text-center">
                    <div className="font-medium h5 mb-0 text-white">Confirm Deletion</div>
                    <div>
                        Warning, this can&apos;t be undone!
                    </div>
                </div>
            }
            withArrow
        >
            <Button className="mr-2" disabled={isSubmitting} onClick={handleOnClick} type="button" color="danger" outline={!isConfirming}>
                {isConfirming ? "Confirm" : "Delete"}
            </Button>
            {isConfirming &&
                <Button outline color="danger" type="button" onClick={() => setIsConfirming(false)}>Cancel</Button>
            }
        </Tooltip>
    )
}

const DefaultValueInput = () => {
    const { values } = useFormikContext();

    const buildOptions = (options) => {
        const init = [];

        if([ "ORGANIZATION_SELECT", "MULTI_ORGANIZATION_SELECT" ].includes(values.type)){
            init.push({
                value: "{{FEDERATION}}",
                label: "{{FEDERATION}}",
            });
        }

        if([ "ORGANIZATION_SELECT", "MULTI_ORGANIZATION_SELECT", "PERIOD_SELECT" ].includes(values.type)){
            init.push({
                value: "{{CURRENT}}",
                label: "{{CURRENT}}",
            });
        }

        return options.reduce((keptOptions, option) => {
            if(option.value){
                keptOptions.push({
                    ...option,
                    label: option.value,
                });
            }

            return keptOptions;
        }, init);

    }

    if(values.type === "MULTI_SELECT"){
        return (
            <FormikSelect
                multi
                clearable
                name="default_value"
                id="default_value"
                options={buildOptions(values.option)}
            />
        )
    }else if(values.type?.includes("SELECT")){
        return (
            <FormikSelect
                clearable
                name="default_value"
                id="default_value"
                options={buildOptions(values.option)}
            />
        )
    }
    return (
        <FormikInputText name="default_value" id="default_value" />
    )

}

export default FilterForm;