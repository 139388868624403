import { FormikSelect } from '@spordle/formik-elements';
import { useContext } from 'react';
import { I18nContext } from '../../../contexts/I18nContext';
import { TransferContext } from '../../../contexts/TransferContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';

const AddTransferDurationsSelect = ({ id = "transfer_duration_id", name = "transfer_duration_id" }) => {
    const transferContext = useContext(TransferContext);
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <FormikSelect
            id={id} name={name}
            renderOption={({ option: transferDuration }) => <DisplayI18n field='name' defaultValue={transferDuration.label} i18n={transferDuration.i18n} />}
            searchKeys={[
                `i18.${getGenericLocale()}.name`,
            ]}
            loadData={(from) => {
                switch (from){
                    case 'CDM':
                        return transferContext.getTransferDurations({ active: 1 }, false, true)
                            .then((transferDurations) => {
                                transferDurations = transferDurations.filter((duration) => duration.scope === 'USA/INTERNATIONAL');

                                return transferDurations.sort((durationA, durationB) => parseInt(durationA.display_order) - parseInt(durationB.display_order))
                                    .map((transferDuration) => ({
                                        value: transferDuration.transfer_duration_id,
                                        label: transferDuration.name,
                                        i18n: transferDuration.i18n,
                                    }))
                            })
                    default:
                        break;
                }
            }}
        />
    );
}

export default AddTransferDurationsSelect;