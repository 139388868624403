export default{
    "settings.clinics.sidePanelClinicCategories.title" : "Informations sur la catégorie",

    "settings.clinics.sidePanelClinicCategories.color"           : "Couleur",
    "settings.clinics.sidePanelClinicCategories.status"          : "Statut",
    "settings.clinics.sidePanelClinicCategories.status.active"   : "Actif",
    "settings.clinics.sidePanelClinicCategories.status.inactive" : "Inactif",
    "settings.clinics.sidePanelClinicCategories.status.required" : "Le statut est requis",

    "settings.clinics.sidePanelClinicCategories.update.success.text" : "Catégorie mise à jour",
    "settings.clinics.sidePanelClinicCategories.update.fail.text"    : "Une erreur est survenue lors de la mise à jour de la catégorie, veuillez rafraichir la page et réessayer",

    // actions
    "settings.clinics.sidePanelClinicCategories.actions.remove"         : "Retirer",
    "settings.clinics.sidePanelClinicCategories.actions.remove.topText" : "Vous êtes sur le point de retirer cette catégorie de votre organisation",

    // tabs
    "settings.clinics.sidePanelClinicCategories.tabs.details" : "Détails",
}