import { FormikError } from '@spordle/formik-elements';
import SpordleSelect from '@spordle/spordle-select';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef } from 'react';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { I18nContext } from '../../../contexts/I18nContext';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { organisationCategoryIntl, organisationCategoryUSA } from '../../../helpers/constants';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { fail } from "@spordle/toasts";

const AddTransferFederationInput = ({ international, direction, id = "federationSelect" }) => {
    const organizationContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const selectRef = useRef(null);

    const [ targetField, _targetMeta, targetHelpers ] = useField({ name: 'target_organisation_id' });
    const [ fromField, _fromMeta, fromHelpers ] = useField({ name: 'from_organisation_id' });

    useEffect(() => {
        if(selectRef.current){
            const [ currentOrgId ] = selectRef.current.state.selectedOptions;
            if(direction === 'outgoing'){
                targetHelpers.setValue(currentOrgId || '');
            }else{
                fromHelpers.setValue(currentOrgId || '');
            }
        }
    }, [ direction ]);

    /**
     * Filters categories depending on if is international or USA
     * @param {string[]} catArr
     * @param {object} category
     * @returns {string[]} Returns an array of category ids
     */
    const filterCategory = (catArr, category) => {
        if((international ? organisationCategoryIntl : organisationCategoryUSA).includes(category.default_name)){
            catArr.push(category.category_id);
        }

        return catArr;
    }

    const getOrganizations = async() => {
        const categories = await organizationContext.getOrganizationCategory(organizationContext.organisation_id)
            .then((cats) => cats.reduce(filterCategory, []))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });

        const orgs = await organizationContext.getOrganizationByCategories(identityRolesContext.federation.organisation_id, { organisation_category_id: categories })

        if(direction === 'outgoing'){ // outgoing
            targetHelpers.setValue(orgs[0].organisation_id);
        }else{ // incoming, this should never happen, because the default value of the Direction select is always outgoing, but in case that changes
            fromHelpers.setValue(orgs[0].organisation_id)
        }

        return formatSelectOptions(orgs);
    }

    function formatSelectOptions(options){
        return options.reduce((newArray, org) => {
            if(org.active == 1){
                newArray.push({
                    value: org.organisation_id,
                    label: org.organisation_name,
                    i18n: org.i18n,
                })
            }
            return newArray
        }, [])
    }

    return (
        <>
            <SpordleSelect
                id={id}
                ref={selectRef}
                values={[ direction === 'outgoing' ? targetField.value : fromField.value ]}
                renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                searchKeys={[
                    `i18n.${getGenericLocale()}.name`,
                ]}
                onOptionSelected={([ value ]) => {
                    if(direction === 'outgoing'){
                        targetHelpers.setValue(value)
                    }else{
                        fromHelpers.setValue(value)
                    }
                }}
                loadData={(from) => {
                    switch (from){
                        case 'CDM':
                            return getOrganizations();
                        default:
                            break;
                    }
                }}
            />
            <FormikError name={direction === 'outgoing' ? 'target_organisation_id' : 'from_organisation_id'} />
        </>
    );
}

AddTransferFederationInput.propTypes = {
    international: PropTypes.bool,
    direction: PropTypes.oneOf([ 'outgoing', 'incoming' ]),
};

export default AddTransferFederationInput;