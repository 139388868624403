import { fail, success } from "@spordle/toasts";
import { useContext } from "react";
import { FormGroup } from "reactstrap";
import { array, object } from "yup";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import FormikEditableFile from "../../../../../../../components/formik/FormikEditableFile";
import { compareAndFormatFiles, mbToBytes } from "../../../../../../../components/uploader/uploadHelpers";
import { I18nContext } from "../../../../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../../../../contexts/IdentityRolesContext";
import { InjuriesContext } from "../../../../../../../contexts/InjuriesContext";
import { RolesContext } from "../../../../../../../contexts/RolesContext";
import { displayI18n, DisplayI18n } from "../../../../../../../helpers/i18nHelper";

const DocumentUpload = ({ injury, docType, setIsLoading, refreshTableWithApi }) => {
    const { canDoAction } = useContext(RolesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { isGod } = useContext(IdentityRolesContext);
    const { downloadInjuryAttachment, deleteInjuryAttachment, createInjuryAttachments } = useContext(InjuriesContext);
    const initDocuments = injury.attachments.filter((doc) => doc.document_type?.document_type_id === docType.document_type_id);
    const canEditDocuments = canDoAction("ADD", "settings", "manage_injury_attachments") || isGod();
    const canRemoveDocuments = canDoAction("DELETE", "settings", "manage_injury_attachments") || isGod();

    const handleOnFileClick = (file) => {
        return downloadInjuryAttachment(
            injury.injury_id,
            file.injury_attachment_id,
        )
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    const updateInjuryAttachments = async(files, docTypeId) => {
        const { toDelete, toCreate } = compareAndFormatFiles(initDocuments || [], files);

        if(toDelete?.length > 0){
            await Promise.all(
                toDelete.map((att) => (
                    deleteInjuryAttachment(injury.injury_id, att.injury_attachment_id)
                )),
            )
        }

        if(toCreate?.length > 0 && toCreate?.[0]?.length > 0){
            // separateFilesPerSize returns an array of subarray of files in which the total size does not exceed 14MB.
            // Avoid php errors for the api
            await Promise.all(
                toCreate.map((subArr) => {
                    return createInjuryAttachments(
                        injury.injury_id,
                        subArr.map((doc) => ({ ...doc, document_type_id: docTypeId })),
                    );
                }),
            );

        }

        refreshTableWithApi(true);
    }

    return (
        <FormGroup>
            <div className="text-muted"><DisplayI18n field='name' defaultValue={docType.name} i18n={docType.i18n} /></div>
            {docType.description &&
                <div
                    className='small text-muted'
                    dangerouslySetInnerHTML={{
                        __html: displayI18n("description", docType.i18n, docType.description, getGenericLocale()),
                    }}
                />
            }
            <FormikEditableFile
                showCreatedAt
                disabled={!canEditDocuments}
                preventRemoveExistant={!canRemoveDocuments}
                dropzoneProps={{
                    multiple: true,
                    maxSize: docType.file_size ? mbToBytes(parseFloat(docType.file_size)) : void 0,
                    accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                }}
                onFileClick={handleOnFileClick}
                initialValues={{
                    file: initDocuments || [],
                }}
                validationSchema={object().shape({
                    file: array(),
                })}
                onSubmit={(values) => {
                    setIsLoading(true);

                    return updateInjuryAttachments(values.file, docType.document_type_id)
                        .then(() => {
                            success({ info: 'components.uploader.fileUpload.toast.success.info' });
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message);
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                setIsLoading(false);
                            }
                        })
                }}
            />
        </FormGroup>
    );
}

export default DocumentUpload;