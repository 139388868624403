import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import CanDoAction from "../../../../../../../components/permissions/CanDoAction";
import { fail } from '@spordle/toasts';
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { OrganizationContext } from "../../../../../../../contexts/OrganizationContext";
import { useIntl } from "react-intl";
import { Tooltip } from "@mantine/core";
import QuickViewButton from "../../../../../../../components/quickView/QuickViewButton";
import InlineCopy from "../../../../../../../components/inlineCopy/InlineCopy";

const SuspensionCard = ({ maltreatment, maltreatment_id, maltreatment_member_suspension, readOnly, refreshData }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const { deleteDiscriminationSuspension } = useContext(OrganizationContext);
    const { formatMessage } = useIntl();

    const handleUnlink = () => {
        setIsLoading(true);
        deleteDiscriminationSuspension(maltreatment_id, maltreatment_member_suspension.maltreatment_member_suspension_id)
            .then(() => {
                setIsLoading(false);
                refreshData()
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    fail({ info: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, skipInfoTranslate: true });
                    setIsLoading(false);
                }
            });
    }

    const member_suspension = maltreatment_member_suspension.member_suspension;
    return (
        <OverlayLoader isLoading={isLoading}>
            <UserDisplay className="mb-3" card block>
                <UserDisplay.Container className="align-items-center d-flex flex-wrap w-100">
                    <div className="border-bottom mb-2 d-flex justify-content-between w-100 text-right pb-2">
                        <UserDisplay.Title className="text-left">
                            <DisplayI18n field='name' defaultValue={member_suspension.suspension?.name} i18n={maltreatment_member_suspension.member_suspension.suspension?.i18n} />
                            {member_suspension.member &&
                                <>
                                    <div className="h6 mb-0 mt-1 d-flex align-items-center"><QuickViewButton member={member_suspension.member}>{member_suspension.member.first_name} {member_suspension.member.last_name}</QuickViewButton></div>
                                    <InlineCopy className="small text-muted" tag="div" toCopy={member_suspension.member.unique_identifier}>#{member_suspension.member.unique_identifier}</InlineCopy>
                                </>
                            }
                        </UserDisplay.Title>
                        {!readOnly &&
                            <CanDoAction componentCode="organization" componentPermissionCode="discrimination_claims" action="DELETE">
                                <Tooltip
                                    label={<Translate id="misc.unlink" />}
                                    withArrow
                                    zIndex={1040}
                                >
                                    <button title={formatMessage({ id: "misc.unlink" })} type="button" className="reset-btn fa fa-trash text-danger" onClick={handleUnlink} />
                                </Tooltip>
                            </CanDoAction>
                        }
                    </div>
                    <div className="d-flex align-items-start w-100">
                        <div className="pr-2">
                            {member_suspension.organisation && <UserDisplay.Subtitle className="text-dark"><b><Translate id="misc.organization" />:</b> <DisplayI18n field='name' defaultValue={member_suspension.organisation.name} i18n={member_suspension.organisation.i18n} /></UserDisplay.Subtitle>}
                            {maltreatment_member_suspension.linked_by && <UserDisplay.Subtitle className="text-dark"><b><Translate id="members.profile.suspensions.submitted_by" />:</b> {maltreatment_member_suspension.linked_by.name} {maltreatment_member_suspension.linked_by.family_name}</UserDisplay.Subtitle>}
                            {member_suspension.duration && <UserDisplay.Subtitle className="text-dark"><b><Translate id="members.profile.suspensions.suspensionType" />:</b> {member_suspension.duration}</UserDisplay.Subtitle>}
                            {member_suspension.game_suspended && <UserDisplay.Subtitle className="text-dark"><b><Translate id="members.profile.suspensions.duration.numberOfGames" />:</b> {member_suspension.game_suspended}</UserDisplay.Subtitle>}
                            {member_suspension.start_date && <UserDisplay.Subtitle className="text-dark"><b><Translate id="members.profile.suspensions.startDate" />:</b> {member_suspension.start_date}</UserDisplay.Subtitle>}
                            {member_suspension.end_date && <UserDisplay.Subtitle className="text-dark"><b><Translate id="members.profile.suspensions.endDate" />:</b> {member_suspension.end_date}</UserDisplay.Subtitle>}
                        </div>
                    </div>
                </UserDisplay.Container>
            </UserDisplay>
        </OverlayLoader>
    );
}

export default SuspensionCard;