import { FieldArray, Form, Formik } from "formik";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import Translate from "@spordle/intl-elements";
import FormikApiError from "../../../../../components/formik/FormikApiError";
import { success } from "@spordle/toasts";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import ApiErrorMsg from "../../../../../components/formik/ApiErrorMsg";
import { FormikSelect } from "@spordle/formik-elements";
import generatePassword from "../../../../../helpers/passwordGenerator";
import { array, number, object, string } from "yup";
import ConfigurationQualificationException from "./ConfigurationQualificationException";
import Required from "../../../../../components/formik/Required";
import useSWR from "swr";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { useContext, useMemo } from "react";
import { RegistrationCategoriesContext } from "../../../../../contexts/RegistrationCategoriesContext";
import { PeriodsContext } from "../../../../../contexts/contexts";
import { DisplayI18n, displayI18n } from "../../../../../helpers/i18nHelper";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { getTeamCategoryQualificationRules, updateTeamCategoryQualificationRules } from "../../../../../api/client/teams";
import { useTeamConfigPosGroupsSWR, useTeamConfigQualificationsSWR } from "../settings/teamSettingsConfigurationHelper";
import { groupByAsArray, stringBuilder } from "@spordle/helpers";
import { isNullish } from "../../../../../helpers/helper";
import CardSectionTitle from "../../../../../components/CardSectionTitle";
import { useTeamPositionsSWR } from "../../../../../api/useSWRHelpers/positionsSWR";

const ConfigurationQualificationForm = ({ stopEditing }) => {
    const { organisation_id } = useContext(OrganizationContext);
    const { getRegistrationCategories } = useContext(RegistrationCategoriesContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const { getGenericLocale } = useContext(I18nContext);

    const { data: teamCategoryQualificationRules, isValidating, mutate } = useSWR(
        [ 'getTeamCategoryQualificationRules', selectedPeriod.period_id ],
        () => getTeamCategoryQualificationRules({ period_id: selectedPeriod.period_id }),
    )

    const { data: teamCategories } = useSWR([
        'getTeamCategories', organisation_id, selectedPeriod.period_id,
    ], () => getRegistrationCategories(organisation_id));

    const { data: positions } = useTeamPositionsSWR();
    const { data: positionGroups } = useTeamConfigPosGroupsSWR();
    const { data: qualifications } = useTeamConfigQualificationsSWR();

    const sortNames = (a, b) => {
        const nameA = displayI18n("name", a.i18n, a.name, getGenericLocale()) || "";
        const nameB = displayI18n("name", b.i18n, b.name, getGenericLocale()) || "";

        return nameA.localeCompare(nameB);
    }

    const formattedQualifications = useMemo(() => {
        return qualifications?.sort(sortNames)
            .reduce((newArray, qualification) => {
                if(qualification.active == 1 || teamCategoryQualificationRules.find((rule) => rule.qualification.qualification_id === qualification.qualification_id)){
                    newArray.push({
                        ...qualification,
                        value: qualification.qualification_id,
                        label: qualification.name,
                    })
                }
                return newArray
            }, [])
    }, [
        qualifications?.length,
        teamCategoryQualificationRules?.length,
    ])

    const formattedPositionGroups = useMemo(() => {
        return positionGroups?.sort(sortNames)
            .map((pos) => ({
                ...pos,
                value: pos.position_group_id,
                label: pos.name,
            }));
    }, [ positionGroups?.length ])

    const formattedPositions = useMemo(() => {
        return positions?.sort(sortNames)
            .map((pos) => ({
                ...pos,
                value: pos.position_id,
                label: pos.name,
            }));
    }, [ positions?.length ]);

    const getInitSubRule = () => ({
        position_id: "",
        qualification_id: "",
        count: 1,
        key: generatePassword(),
        delete: 0,
    });

    const formatTeamCategoryQualificationRules = (teamCategoryQualificationRules) => {
        const groupedRules = groupByAsArray(teamCategoryQualificationRules.map((data) => ({ ...data, categoryId: data.team_category.team_category_id })), 'categoryId')
        return groupedRules.reduce((formattedRestrictions, group) => {
            const rules = group.values.map((rule) => ({
                position_id: rule.position?.position_id,
                position_group_id: rule.position_group?.position_group_id,
                qualification_id: rule.qualification.qualification_id,
                count: isNullish(rule.applies_to) ? 1 : parseInt(rule.applies_to),
                key: generatePassword(),
                id: rule.team_category_qualification_rule_id,
                delete: 0,
            }));

            formattedRestrictions.push({
                category_id: group.key,
                isEditable: false,
                rules: rules,
                deleteAll: 0,
            })
            return formattedRestrictions
        }, [])
    }

    return (
        <Formik
            enableReinitialize
            initialStatus={{
                error: null,
                showError: false,
            }}
            initialValues={{
                restrictions: formatTeamCategoryQualificationRules(teamCategoryQualificationRules || []),
            }}
            validationSchema={object().shape({
                restrictions: array().of(object().shape({
                    category_id: string().required(<Translate id="settings.teamsSettings.configuration.category.validation" />),
                    rules: array().of(object().shape({
                        position_id: string().test({
                            name: 'shouldBeRequired',
                            message: <Translate id="settings.teamsSettings.configuration.position.validation" />,
                            test: function(posId){
                                if(this.parent.del == 0 && !this.parent.position_group_id){
                                    return !!posId;
                                }

                                return true;
                            },
                        }),
                        position_group_id: string().test({
                            name: 'shouldBeRequired',
                            message: <Translate id="settings.teamsSettings.configuration.position.validation" />,
                            test: function(posGroupId){
                                if(this.parent.del == 0 && !this.parent.position_id){
                                    return !!posGroupId;
                                }

                                return true;
                            },
                        }),
                        qualification_id: string().when('delete', {
                            is: 0,
                            then: string().required(<Translate id="settings.teamsSettings.configuration.qualification.validation" />),
                        }),
                        count: number().when('delete', {
                            is: 0,
                            then: number().min(1, <Translate id="settings.teamsSettings.configuration.count.validation" />),
                        }),
                        key: string(),
                    })),
                })),
            })}
            onSubmit={(values, { setStatus }) => {
                const formattedRestrictions = values.restrictions.reduce((newArray, restriction) => {
                    for(let i = 0; i < restriction.rules.length; i++){
                        const rule = restriction.rules[i];

                        // if the rule was just added and has the delete flag we simply don't create it 4head
                        if(!(rule.delete == 1 && !rule.id)){
                            newArray.push({
                                team_category_id: restriction.category_id,
                                period_id: selectedPeriod.period_id,
                                position_id: rule.position_id,
                                position_group_id: rule.position_group_id,
                                qualification_id: rule.qualification_id,
                                applies_to: rule.count,
                                active: '1',
                                team_category_qualification_rule_id: rule.id || '',
                                delete: rule.delete,
                            })
                        }
                    }
                    return newArray
                }, [])

                return updateTeamCategoryQualificationRules({ team_category_qualification_rules: JSON.stringify(formattedRestrictions) })
                    .then(async() => {
                        success();
                        await mutate();
                        stopEditing();
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            setStatus({
                                error: Array.isArray(e) ? e : <ApiErrorMsg error={e} />,
                                showError: true,
                            })
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <Card body className="card-shadow">
                        <CardSectionTitle title="settings.tabs.teams.configuration.qualifications" className='d-flex'>
                            {formik.dirty &&
                                <Button disabled={formik.isSubmitting} type="submit" color="primary" className="ml-auto"><Translate id="misc.save" /></Button>
                            }
                        </CardSectionTitle>
                        <OverlayLoader isLoading={formik.isSubmitting || isValidating}>
                            <FieldArray name="restrictions">
                                {(arrayHelper) => (
                                    <>
                                        {(formik.values.restrictions).map((rule, i) => (
                                            <Card key={rule.key} className="card-shadow mb-3">
                                                <CardHeader className="d-flex align-items-center">
                                                    <b>
                                                        <Translate id="settings.teamsSettings.configuration.restriction" values={{ nb: i + 1 }} />
                                                    </b>
                                                    {/* <Button type="button" color="link" className="ml-auto mdi mdi-content-copy" /> */}
                                                    <Button
                                                        type="button"
                                                        color="link"
                                                        onClick={() => {
                                                            formik.setFieldValue(`restrictions.${i}.deleteAll`, rule.deleteAll == 1 ? 0 : 1)
                                                            rule.rules.forEach((_rule, index) => {
                                                                formik.setFieldValue(`restrictions.${i}.rules.${index}.delete`, rule.deleteAll == 1 ? 0 : 1)
                                                            })
                                                        }}
                                                        className={stringBuilder("pr-3 mr-n3 ml-auto line-height-0", { "text-danger fa fa-trash": rule.deleteAll != 1, "text-primary mdi mdi-undo": rule.deleteAll == 1 })}
                                                    />
                                                </CardHeader>
                                                <CardBody>
                                                    <div className="mb-2">
                                                        <div className="text-muted mb-1">
                                                            <Translate id="organization.grouping.add.selectOrg.categories" /> <Required />
                                                        </div>
                                                        <FormikSelect
                                                            id={`restrictions.${i}.category_id`}
                                                            name={`restrictions.${i}.category_id`}
                                                            searchKeys={[
                                                                `i18n.${getGenericLocale()}.name`,
                                                            ]}
                                                            className={rule.deleteAll == 1 ? "opacity-50" : undefined}
                                                            disabled={!rule.isEditable || rule.deleteAll == 1}
                                                            renderSelectedOption={(option) => (
                                                                <>
                                                                    <DisplayI18n
                                                                        field="name"
                                                                        i18n={option.i18n}
                                                                        defaultValue={option.label}
                                                                    />
                                                                    {option.gender &&
                                                                        <small className="text-muted ml-1">
                                                                            (<Translate id={`form.fields.gender.${(option.gender || "").toLowerCase()}`} />)
                                                                        </small>
                                                                    }
                                                                </>
                                                            )}
                                                            renderOption={({ option, isSelected }) => (
                                                                <>
                                                                    <DisplayI18n
                                                                        field="name"
                                                                        i18n={option.i18n}
                                                                        defaultValue={option.label}
                                                                    />
                                                                    {option.gender &&
                                                                        <div className={`small text-${isSelected ? "light" : "muted"}`}>
                                                                            <Translate id={`form.fields.gender.${(option.gender || "").toLowerCase()}`} />
                                                                        </div>
                                                                    }
                                                                </>
                                                            )}
                                                            options={(teamCategories || [])
                                                                .sort((a, b) => {
                                                                    const sort = parseInt(a.display_order) - parseInt(b.display_order);

                                                                    if(sort !== 0){
                                                                        return sort;
                                                                    }

                                                                    const nameA = displayI18n("name", a.i18n, a.name, getGenericLocale()) || "";
                                                                    const nameB = displayI18n("name", b.i18n, b.name, getGenericLocale()) || "";

                                                                    return nameA.localeCompare(nameB);
                                                                })
                                                                .map((category) => ({
                                                                    ...category,
                                                                    value: category.team_category_id,
                                                                    label: category.name,
                                                                }))
                                                            }
                                                        />
                                                    </div>
                                                    <ConfigurationQualificationException
                                                        name={`restrictions.${i}.rules`}
                                                        getInitSubRule={getInitSubRule}
                                                        positions={formattedPositions}
                                                        positionGroups={formattedPositionGroups}
                                                        qualifications={formattedQualifications}
                                                    />
                                                </CardBody>
                                            </Card>
                                        ))}
                                        <Button
                                            type="button"
                                            color="link"
                                            block
                                            size="lg"
                                            onClick={() => arrayHelper.push({
                                                category_id: '',
                                                isEditable: true,
                                                rules: [ getInitSubRule() ],
                                                key: generatePassword(),
                                            })}
                                            className="border border-primary border-dashed mdi mdi-plus with-icon"
                                        >
                                            <Translate id="settings.teamsSettings.configuration.addRestriction" />
                                        </Button>
                                    </>
                                )}
                            </FieldArray>
                            <FormikApiError className="pb-0" />
                            <div className="d-flex justify-content-end pt-3">
                                <Button disabled={!formik.dirty || formik.isSubmitting} type="submit" color="primary" className="mr-2"><Translate id="misc.save" /></Button>
                                <Button disabled={formik.isSubmitting} type="button" color="primary" outline onClick={stopEditing}><Translate id="misc.cancel" /></Button>
                            </div>
                        </OverlayLoader>
                    </Card>
                </Form>
            )}
        </Formik>
    );
}

export default ConfigurationQualificationForm;