export default{
    "members.profile.overview.sidePanelMemberMedicalNotes.title"               : "Notes médicales",
    "members.profile.overview.sidePanelMemberMedicalNotes.remove"              : "Retirer",
    "members.profile.overview.sidePanelMemberMedicalNotes.remove.swal.success" : "Note médicale retirée!",

    "members.profile.overview.sidePanelMemberMedicalNotes.type"                   : "Type",
    "members.profile.overview.sidePanelMemberMedicalNotes.type.swal.success"      : "Type mis à jour!",
    "members.profile.overview.sidePanelMemberMedicalNotes.severity"               : "Sévérité",
    "members.profile.overview.sidePanelMemberMedicalNotes.severity.swal.success"  : "Sévérité mise à jour!",
    "members.profile.overview.sidePanelMemberMedicalNotes.emergency"              : "Action d'urgence",
    "members.profile.overview.sidePanelMemberMedicalNotes.emergency.swal.success" : "Action d'urgence mise à jour!",
    "members.profile.overview.sidePanelMemberMedicalNotes.notes"                  : "Notes",
    "members.profile.overview.sidePanelMemberMedicalNotes.notes.swal.success"     : "Notes mises à jour!",

    // validation
    "members.profile.overview.sidePanelMemberMedicalNotes.type.validation"      : "Le type est requis",
    "members.profile.overview.sidePanelMemberMedicalNotes.severity.validation"  : "La sévérité est requise",
    "members.profile.overview.sidePanelMemberMedicalNotes.emergency.validation" : "L'action d'urgence est requise",
}