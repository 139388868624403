import React from 'react';
import { Button, Collapse, ModalBody, ModalFooter } from 'reactstrap';
import Translate from '@spordle/intl-elements';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import CustomAlert from '../CustomAlert';
import { DisplayI18n } from '../../helpers/i18nHelper';

class ManualRegistrationWaitingListInfo extends React.Component{

    state = {
        errorMessage: null,
    }

    render(){
        return (
            <>
                <ModalBody className='text-center'>
                    <i style={{ fontSize: 75 }} className='mdi mdi-information-outline text-primary' />
                    <div className="h4"><Translate id='components.manualRegistration.waitingList.title' /></div>
                    <Translate id='components.manualRegistration.waitingList.text' values={{ confirm: <b><Translate id='misc.confirm' /></b> }} />

                    <Collapse isOpen={!!this.state.errorMessage} appear mountOnEnter unmountOnExit className='text-left'>
                        {this.state.errorMessage &&
                            <CustomAlert className='mt-3' color='danger' withTitle text={this.state.errorMessage} translateText={false} toggle={() => this.setState(() => ({ errorMessage: null }))} />
                        }
                    </Collapse>
                </ModalBody>
                <ModalFooter className='justify-content-between'>
                    <Button
                        color='primary' outline type='button'
                        onClick={this.props.goToPrevious}
                    >
                        <Translate id='misc.back' />
                    </Button>

                    <div>
                        <Button
                            color='primary' type='button'
                            onClick={() => {
                                this.props.setModalLoading(true)
                                this.props.onSubmit()
                                    .then(() => {
                                        this.props.setModalLoading(false)
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            this.setState(() => ({
                                                errorMessage: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            }))
                                            this.props.setModalLoading(false)
                                        }
                                    })
                            }}
                            className='mr-2'
                        ><Translate id='misc.confirm' />
                        </Button>
                        <Button color='primary' type='button' outline onClick={this.props.toggle}><Translate id='misc.cancel' /></Button>
                    </div>
                </ModalFooter>
            </>
        )
    }
}

export default ManualRegistrationWaitingListInfo