import { useState } from 'react';

const SearchResult = ({ checked, add, remove, id, dataId, ...props }) => {
    const [ isChecked, setIsChecked ] = useState(checked ?? false);

    const toggleCheck = (e) => {
        const key = dataId ?? id;

        isChecked ? remove(key) : add(key);

        setIsChecked(e.target.checked);
    }

    return (
        <li className="d-flex mb-3">
            <div className="custom-checkbox custom-control">
                <input {...props} onChange={(e) => toggleCheck(e)} checked={isChecked} id={id} name="checkbox-location" type='checkbox' className="custom-control-input" />
                <label htmlFor={id} className="custom-control-label" />
            </div>
            <div className="pl-2">
                <div className="h5 mb-0">Aréna Blainville</div>
                <span className="small">1009 Marie Rue, Blainville</span>
            </div>
        </li>
    )
}

export default SearchResult;