export default{
    "clinics.clinicPaymentOptions.clinicPaymentOptions.title" : "Option(s) de paiement",

    // registration
    "clinics.clinicPaymentOptions.clinicPaymentOptions.fees.title"           : "Articles de stage",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.fees.none"            : "Aucun article de stage",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.fees.table.amount"    : "Montant",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.fees.table.taxes"     : "Taxes",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.fees.table.total"     : "Total",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.fees.table.mandatory" : "Obligatoire",

    // confirmation
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.title"                : "Méthode(s) de paiement",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.online.title"         : "Paiement en ligne",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.online.title.helper"  : "Permet les paiements par carte de crédit",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.online.none"          : "Aucune méthode de paiement en ligne",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.merchant.validation"  : "Veuillez choisir une option de paiement en ligne",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.merchant.none"        : "Aucune option de paiement en ligne disponible, veuillez contacter le support.",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.merchant.placeholder" : "Sélectionnez une option de paiement en ligne",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.merchant.noOptions"   : "Aucune option de paiement en ligne disponible",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.other.title"          : "Méthode(s) de prépaiement",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.other.none"           : "Aucune méthode de prépaiement",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.CHEQUE"               : "Chèque",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.CASH"                 : "Argent comptant",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.CREDITCARD"           : "Carte de crédit",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.PAYMENTPROXY"         : "Carte de crédit (paiement proxy)",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.INTERAC"              : "Transfert Interac",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.FUNDING"              : "Financement",
    "clinics.clinicPaymentOptions.clinicPaymentOptions.paymentMethods.minimum"              : "Veuillez cocher au moins une méthode de prépaiement",
}