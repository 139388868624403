import { useSpordleTable } from '@spordle/datatables'
import { SpordletablePanelContext } from './SpordlePanel';
import { useContext, useEffect } from 'react'
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { fail } from '@spordle/toasts';


const OpenSidePanelOnSearch = () => {
    // could possibly be made easier to implement in new places by using [] for the foundObj and receiving whatever key we need to access as a parameter (string)
    // in this case we would also need to receive the sort key as a string, we would probably still need a condition to accomodate the crc table since it has a different ?search

    const spordleTable = useSpordleTable();
    const panelTable = useContext(SpordletablePanelContext);
    const history = useHistory();

    const defaultSearch = queryString.parse(window.location.search).search

    const tableId = spordleTable.props.id;

    useEffect(() => {

        // CRC table uses ?crc-search=, therefore for when linking to the crc table we must use the crc-search from the query params
        const searchQuery = tableId == 'memberCrc' ? queryString.parse(window.location.search)?.["crc-search"] : queryString.parse(window.location.search).search;

        if(searchQuery && spordleTable.state.loadingState === 'success'){

            var foundObj = null;

            switch (tableId){
                case 'org-users':
                    spordleTable.setColumnSorting(defaultSearch ? undefined : '-name');
                    foundObj = spordleTable.getData().find((user) => {
                        return user.identity_id === searchQuery
                    })
                    break;
                case 'transaction':
                    spordleTable.setColumnSorting(defaultSearch ? undefined : '-invoice_date');
                    foundObj = spordleTable.getData().find((transaction) => {
                        return transaction.invoice_number === searchQuery
                    })
                    break;
                case 'memberCrc':
                    spordleTable.setColumnSorting(defaultSearch ? undefined : '-expiration_date');
                    foundObj = spordleTable.getData().find((crc) => {
                        return crc.crc_id === searchQuery
                    })
                    break;
                case 'transfers':
                    spordleTable.setColumnSorting(defaultSearch ? undefined : '-date');
                    foundObj = spordleTable.getData().find((transfer) => {
                        return transfer.member_transfer_id === searchQuery
                    })
                case 'CatalogRegistrationFeesTable':
                    spordleTable.setColumnSorting(defaultSearch ? undefined : '-name');
                    foundObj = spordleTable.getData().find((fee) => {
                        return fee.registration_fee_id === searchQuery
                    })
                    break;
                case 'discrimination_table':
                    spordleTable.setColumnSorting(defaultSearch ? undefined : '-created_at');
                    foundObj = spordleTable.getData().find((maltreat) => {
                        return maltreat[spordleTable.props.dataIndex] === searchQuery;
                    })
                    break;
                default:
                    break;
            }

            if(foundObj){
                spordleTable.setInputValue(searchQuery);
                panelTable.onSingleSelectChange(foundObj);
                removeSearchFromURL();
            }else{
                removeSearchFromURL();
                fail({ msg: 'sidePanel.spordlePanel.openedSidepanel.wrongSearch' });
            }
        }
    }, [ queryString.parse(window.location.search).search, spordleTable.state.loadingState ])

    // remove invoice number from URL
    const removeSearchFromURL = () => {
        const parsedUrl = queryString.parseUrl(`${location.pathname}${location.search}${location.hash}`, { parseFragmentIdentifier: true/* parse hash(#) */ })
        delete parsedUrl.query.search;

        if(parsedUrl.query?.['crc-search'])
            delete parsedUrl.query?.['crc-search'];

        history.replace(queryString.stringifyUrl(parsedUrl))
    }


    return (null);
}

export default OpenSidePanelOnSearch
