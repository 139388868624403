import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables"
import { Form } from "formik";
import { Row } from "reactstrap";

/**
 * Wraps repetitive JSX
 * @param {Object} props
 * @param {[Col]} props.children Children should be <Col>
 * @returns {React.ReactNode}
 */
const TeamRosterSearchView = ({ children }) => {
    return (
        <Form>
            <Row form>
                {children}
            </Row>
            <hr />
            <div className='mb-2'>
                <div className='d-flex flex-wrap'>
                    {/* <UncontrolledDropdown>
                        <DropdownToggle color='primary' className='d-flex align-items-center mr-2' caret>
                            <div className='align-self-center custom-checkbox custom-control'>
                                <input type='checkbox' className='custom-control-input d-none' />
                                <label className='custom-control-label' htmlFor='topCheckID'></label>
                            </div>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem><Translate id='misc.all' /></DropdownItem>
                            <DropdownItem><Translate id='misc.none' /></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown> */}
                    <SpordleTableProvider.SearchInput />
                </div>
            </div>
            <SpordleTableView />
        </Form>
    )
}

export default TeamRosterSearchView;