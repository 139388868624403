import React from "react";


import * as Yup from 'yup';
import { FormikInputText, FormikTextArea } from "@spordle/formik-elements";
import FormikEditable from '../../../../../../components/formik/FormikEditable';

// Contexts
import { MembersContext } from '../../../../../../contexts/MembersContext';
import withContexts from '../../../../../../helpers/withContexts';

// Language
import Translate from "@spordle/intl-elements";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { fail, success } from "@spordle/toasts";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";

class SidePanelMemberMemos extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
        }
    }

    render(){
        return (
            <RolesContext.Consumer>
                {({ canDoAction }) => {
                    const canEdit = canDoAction('EDIT', 'members', 'members_memo');

                    return (
                        <div className='position-relative'>
                            <OverlayLoader isLoading={this.state.loading}>
                                <SidePanel.Header>
                                    <div className='d-flex mb-3 align-items-center'>
                                        <SidePanel.ToggleButton />
                                        <SidePanel.ActionsMenu label="misc.action" action='DELETE' componentCode='members' componentPermissionCode='members_memo'>
                                            <SidePanel.MenuAction
                                                onClick={() => {
                                                    this.setState(() => ({ loading: true }))
                                                    this.props.MembersContext.deleteMemberMemo(this.props.MembersContext.currentMember.member_id, this.props.getCommonValue('member_memo_id'))
                                                        .then(() => {
                                                            success({ msg: 'members.profile.overview.memberMemos.remove.swal.success' });
                                                            this.props.tableRef.deleteRow(this.props.getCommonValue('member_memo_id'))
                                                            this.props.toggle();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                        .finally(() => {
                                                            this.setState(() => ({ loading: false }))
                                                        })
                                                }}
                                            >
                                                <Translate id='members.profile.overview.memberMemos.remove' />
                                            </SidePanel.MenuAction>
                                        </SidePanel.ActionsMenu>
                                    </div>
                                    <SidePanel.Title><Translate id='members.profile.overview.memberMemos.title' /></SidePanel.Title>
                                </SidePanel.Header>
                                <div className="p-3">
                                    {this.props.selectedRows[0].created_by &&
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='members.profile.overview.memberMemos.createdBy' /></div>
                                            <div className="font-medium">{this.props.selectedRows[0].created_by.name} {this.props.selectedRows[0].created_by.family_name}</div>
                                        </div>
                                    }

                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='form.fields.name' /></div>
                                        <FormikEditable
                                            id='formikEditableName'
                                            disabled={!canEdit}
                                            initialValues={{
                                                name: this.props.getCommonValue('name'),
                                            }}
                                            validationSchema={Yup.object().shape({
                                                name: Yup.string().required(<Translate id='form.validation.name.required' />),
                                            })}
                                            onSubmit={(values) => {
                                                this.setState(() => ({ loading: true }))
                                                const newValues = this.props.createNewValues(values);

                                                this.props.MembersContext.updateMemberMemoPartial(this.props.MembersContext.currentMember.member_id, this.props.getCommonValue('member_memo_id'), values)
                                                    .then(() => {
                                                        this.props.syncRows(newValues);
                                                        success({ msg: 'members.profile.overview.memberMemos.update.name.success' });
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                    .finally(() => {
                                                        this.setState(() => ({ loading: false }))
                                                    })
                                            }}
                                        >
                                            {(isEditing) => {
                                                if(!isEditing){
                                                    return (
                                                        <div className="font-medium">
                                                            {this.props.getCommonValue("name")}
                                                        </div>
                                                    )
                                                }
                                                return (
                                                    <FormikInputText id='name' name='name' trim />
                                                )

                                            }}
                                        </FormikEditable>
                                    </div>
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='form.fields.description' /></div>
                                        <FormikEditable
                                            id='formikEditableDescription'
                                            disabled={!canEdit}
                                            initialValues={{
                                                description: this.props.getCommonValue('description'),
                                            }}
                                            validationSchema={Yup.object().shape({
                                                description: Yup.string().required(<Translate id='form.validation.description.required' />),
                                            })}
                                            onSubmit={(values) => {
                                                this.setState(() => ({ loading: true }))
                                                const newValues = this.props.createNewValues(values);

                                                this.props.MembersContext.updateMemberMemoPartial(this.props.MembersContext.currentMember.member_id, this.props.getCommonValue('member_memo_id'), values)
                                                    .then(() => {
                                                        this.props.syncRows(newValues);
                                                        success({ msg: 'members.profile.overview.memberMemos.update.desc.success' });
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                    .finally(() => {
                                                        this.setState(() => ({ loading: false }))
                                                    })
                                            }}
                                        >
                                            {(isEditing) => {
                                                if(!isEditing){
                                                    return (
                                                        <div className="font-medium">
                                                            {this.props.getCommonValue("description")}
                                                        </div>
                                                    )
                                                }
                                                return (
                                                    <FormikTextArea id='description' name='description' trim rows={5} />
                                                )

                                            }}
                                        </FormikEditable>
                                    </div>
                                </div>
                            </OverlayLoader>
                        </div>
                    )
                }}
            </RolesContext.Consumer>
        )
    }
}

export default withContexts(MembersContext)(SidePanelMemberMemos);