export default{
    "organization.settings.sidePanelOrganizationTeamContactTypes.title" : "Contact Type Information",

    "organization.settings.sidePanelOrganizationTeamContactTypes.status"          : "Status",
    "organization.settings.sidePanelOrganizationTeamContactTypes.status.active"   : "Active",
    "organization.settings.sidePanelOrganizationTeamContactTypes.status.inactive" : "Inactive",
    "organization.settings.sidePanelOrganizationTeamContactTypes.status.required" : "Status is required",

    "organization.settings.sidePanelOrganizationTeamContactTypes.update.success.text" : "Contact Type updated",
    "organization.settings.sidePanelOrganizationTeamContactTypes.update.fail.text"    : "An error occurred during the Contact Type update, please refresh the page and try again",

    // actions
    "organization.settings.sidePanelOrganizationTeamContactTypes.actions.remove"         : "Remove",
    "organization.settings.sidePanelOrganizationTeamContactTypes.actions.remove.topText" : "You are about to remove this Contact Type from your Organization",

    // tabs
    "organization.settings.sidePanelOrganizationTeamContactTypes.tabs.details" : "Details",
}