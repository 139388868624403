import { Form, Formik } from "formik";
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { useState, useEffect, useContext } from 'react';
import { success } from "@spordle/toasts";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { object, string } from "yup";
import { Button, Card, Col, Label, ModalBody, ModalHeader, Row } from "reactstrap";
import Translate from "@spordle/intl-elements";
import CrossFade from "../../../../components/crossFade/CrossFade";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import CustomAnimatedIcon from "../../../../components/customAnimatedIcon/CustomAnimatedIcon";
import DisplayMember from "../../../../components/display/DisplayMember";
import DisplayRequestChangeStatus from "../../../../components/display/DisplayRequestChangeStatus";
import MemberTypesList from "../sidePanel/MemberTypesList";
import { FormikTextArea } from "@spordle/formik-elements";
import { updateMemberTypeRemovalRequest } from "../../../../api/client/memberTypeRemoval";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import ApiErrorMsg from "../../../../components/formik/ApiErrorMsg";
import FormikApiError from "../../../../components/formik/FormikApiError";
import { mutate } from "swr";

const MemberTypeRemovalModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal analyticsName="memberTypeRemovalModal" isOpen={isOpen}>
            <MemberTypeRemovalModalInner {...props} />
        </AnalyticsModal>
    )
}

const MemberTypeRemovalModalInner = ({ selectedRows, spordleTable, toggle, forceCloseSidePanel, requestChangesStatuses, ...props }) => {
    const orgContext = useContext(OrganizationContext);

    // the attachment currently being approved, this is made this way to support approval of multiple attachments in a row
    const [ currentlyApprovingRow, setCurrentlyApprovingRow ] = useState(selectedRows[0]);
    // for chaining multiple documents one after one another
    const [ currentRowIndex, setCurrentRowIndex ] = useState(0);

    // state of buttons at bottom of page
    // possible state: 'INITIAL', 'DECLINED', 'APPROVED'
    const [ buttonState, setButtonState ] = useState('INITIAL');

    // hasApprovedAtLeastOne
    const [ hasApprovedAtLeastOne, setHasApprovedAtLeastOne ] = useState(false);


    // for single approval and so that the first attachment is in state at first open
    useEffect(() => {
        setCurrentlyApprovingRow(selectedRows[0])
    }, [ selectedRows[0].member_request_change_id ])


    const nextApproval = async(setStatus) => {
        const nextRowIndex = currentRowIndex + 1;
        if(nextRowIndex !== selectedRows.length){
            await setCurrentlyApprovingRow(selectedRows[nextRowIndex])
            setCurrentRowIndex(nextRowIndex)
        }else{
            // show success dialog
            setStatus({
                showError: false,
                isSuccess: true,
                error: null,
            })
        }
    }

    return (
        <Formik
            initialValues={{
                comment: '',
            }}
            initialStatus={{
                showError: false,
                isSuccess: false,
                error: null,
            }}
            validationSchema={object().shape({
                comment: string().max(255, <Translate id='form.validation.string.max' values={{ count: 255 }} />),
            })}
            onSubmit={(values, { setSubmitting, setStatus, setValues }) => {
                setSubmitting(true)

                const newStatus = requestChangesStatuses.find((status) => status.system === buttonState)

                return updateMemberTypeRemovalRequest(currentlyApprovingRow.member_type_removal_request_id, {
                    request_change_status_id: newStatus.request_change_status_id,
                    organisation_id: orgContext.organisation_id,
                    comment: values.comment,
                })
                    .then(async() => {
                        await mutate([ "getMemberMemberTypesSWR", currentlyApprovingRow.member_id ])
                        setHasApprovedAtLeastOne(true);
                        if(selectedRows.length === 1){
                            setStatus({
                                showError: false,
                                isSuccess: true,
                                error: null,
                            })
                        }else if(selectedRows.length > 1){
                            setButtonState('INITIAL');
                            success();
                            nextApproval(setStatus);
                            setValues({
                                comment: '',
                            });
                        }
                    })
                    .catch((error) => {
                        setStatus({
                            showError: true,
                            isSuccess: false,
                            error: Array.isArray(error) ? error : <ApiErrorMsg error={error} />,
                        })
                        if(AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            setSubmitting(false);
                        }
                    })

            }}
        >
            {(formik) => (
                <>
                    <ModalHeader toggle={() => {
                        if(hasApprovedAtLeastOne){
                            spordleTable.refreshTable();
                            forceCloseSidePanel();
                        }
                        setButtonState("INITIAL")
                        toggle();
                    }}
                    >
                        <Translate id='tasks.memberTypeRemoval.modal.title' />
                    </ModalHeader>
                    <Form>
                        <CrossFade isVisible={!formik.status.isSuccess}>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalBody>
                                    <DisplayMember card member={currentlyApprovingRow.member} />
                                    <Row form>
                                        <Col md='6' className="mb-3">
                                            <div className='text-muted d-flex'>
                                                <Translate id='tasks.memberTypeRemoval.columns.status' />
                                            </div>
                                            <DisplayRequestChangeStatus status={currentlyApprovingRow.request_change_status} />
                                        </Col>
                                        <Col md='6' className="mb-3">
                                            <div className='text-muted d-flex'>
                                                <Translate id='tasks.memberTypeRemoval.columns.createdBy' />
                                            </div>
                                            {currentlyApprovingRow.created_by ?
                                                <div>
                                                    <div className="mb-0">{currentlyApprovingRow.created_by.name} {currentlyApprovingRow.created_by.family_name}</div>
                                                    <a className="text-truncate d-block w-100" href={`mailto:${currentlyApprovingRow.created_by.email}`}>{currentlyApprovingRow.created_by.email}</a>
                                                </div>
                                                :
                                                ' - '
                                            }
                                        </Col>
                                    </Row>
                                    <div className='text-muted'><Translate id='tasks.memberTypeRemoval.columns.memberType' /></div>
                                    <Card body className='card-shadow mb-3'>
                                        <MemberTypesList requestMemberTypes={[ currentlyApprovingRow ]} memberId={currentlyApprovingRow.member_id} />
                                    </Card>
                                    <div className="mb-3">
                                        <Label htmlFor='comment' className="text-muted">
                                            <Translate id='form.fields.note' />
                                        </Label>
                                        <FormikTextArea id='comment' name='comment' rows={1} maxLength={255} />
                                    </div>

                                    <FormikApiError />

                                    <div className='pt-2'>
                                        {/* INITIAL STATE */}
                                        <CrossFade isVisible={buttonState === 'INITIAL'}>
                                            <Row>
                                                <Col className='pr-1'>
                                                    <Button
                                                        outline
                                                        className='w-100'
                                                        color='danger'
                                                        onClick={() => setButtonState('DECLINED')}
                                                    >
                                                        <Translate id='tasks.memberTypeRemoval.modal.keep' />
                                                    </Button>
                                                </Col>
                                                <Col className='pl-1'>
                                                    <Button
                                                        outline
                                                        className='w-100'
                                                        color='success'
                                                        onClick={() => setButtonState('APPROVED')}
                                                    >
                                                        <Translate id='tasks.memberTypeRemoval.modal.remove' />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            {selectedRows.length > 1 &&
                                                <div className='w-100 mt-2'>
                                                    <Button
                                                        className='w-100'
                                                        color='link'
                                                        onClick={() => nextApproval(formik)}
                                                    >
                                                        <Translate id='task.document.approval.modal.skip' />
                                                    </Button>
                                                </div>
                                            }
                                        </CrossFade>
                                        {/* APPROVAL STATE: display approval request */}
                                        <CrossFade isVisible={buttonState === 'APPROVED'}>
                                            <Row>
                                                <Col className='pr-2'>
                                                    <Button
                                                        outline
                                                        className='w-100'
                                                        color='primary'
                                                        onClick={() => setButtonState('INITIAL')}
                                                    >
                                                        <Translate id='misc.cancel' />
                                                    </Button>
                                                </Col>
                                                <Col className='pl-2'>
                                                    <Button
                                                        className='w-100'
                                                        color='success'
                                                        onClick={() => formik.submitForm()}
                                                    >
                                                        <Translate id='tasks.memberTypeRemoval.modal.removeConfirm' />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CrossFade>
                                        {/* REFUSAL STATE: display refusal request  */}
                                        <CrossFade isVisible={buttonState === 'DECLINED'}>
                                            <Row>
                                                <Col className='pr-2'>
                                                    <Button
                                                        outline
                                                        className='w-100'
                                                        color='primary'
                                                        onClick={() => setButtonState('INITIAL')}
                                                    >
                                                        <Translate id='misc.cancel' />
                                                    </Button>
                                                </Col>
                                                <Col className='pl-2'>
                                                    <Button
                                                        className='w-100'
                                                        color='danger'
                                                        onClick={() => formik.submitForm()}
                                                    >
                                                        <Translate id='tasks.memberTypeRemoval.modal.keepConfirm' />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CrossFade>
                                    </div>

                                </ModalBody>
                            </OverlayLoader>
                        </CrossFade>
                        {/* SUCCESS STEP ONLY ACTIVE IF SINGLE APPROVAL */}
                        <CrossFade isVisible={formik.status.isSuccess}>
                            <ModalBody className="text-center">
                                <CustomAnimatedIcon withCircle className="text-success" size={50} icon="checkmark" />
                                <div className="h4 font-bold mt-2">
                                    <Translate id="misc.success" />
                                </div>
                                <p>
                                    <Translate id={selectedRows.length > 1 ? 'task.mandatoryChangesRequests.sidepanel.request.multi' : 'task.mandatoryChangesRequests.sidepanel.request.success'} />
                                </p>

                                <Button
                                    color="primary" type="button" onClick={() => {
                                        setButtonState("INITIAL")
                                        toggle(); spordleTable.refreshTable(); forceCloseSidePanel();
                                    }}
                                >
                                    <Translate id="misc.close" />
                                </Button>
                            </ModalBody>
                        </CrossFade>
                    </Form>
                </>
            )}
        </Formik>
    );
}

export default MemberTypeRemovalModal