// contexts
import { FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { Button, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { object, string } from 'yup';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import CustomAlert from '../../../../../../components/CustomAlert';
import Required from '../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { success } from '@spordle/toasts';
import { ClinicsContext } from '../../../../../../contexts/ClinicsContext';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

const CancelWaitlistMemberModal = ({ isOpen, toggle, waiting_list_item_sequential_number, onSuccess }) => {
    const clinicsContext = useContext(ClinicsContext);
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='editPaymentReceptionModal' size='md'>
            <ModalHeader toggle={toggle}>
                <Translate id='clinics.profile.waitlist.actions.cancel' />
            </ModalHeader>
            <ModalBody className='text-center position-relative'>
                <i className='mdi mdi-delete text-danger' style={{ fontSize: 75 }} />
                <div className="mb-1">
                    <Translate id='clinics.profile.waitlist.actions.cancel.msg' />
                </div>
            </ModalBody>

            <Formik
                initialValues={{
                    cancellation_reason: '',
                }}
                validationSchema={object().shape({
                    cancellation_reason: string().required(<Translate id='form.required' />),
                })}
                onSubmit={(values, { setStatus }) => {
                    setStatus();
                    return clinicsContext.cancelWaitlistMember(waiting_list_item_sequential_number, values.cancellation_reason)
                        .then(() => {
                            success();
                            onSuccess();
                            toggle();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message);
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalBody>
                                <FormGroup>
                                    <Label for='cancellation_reason' className='text-muted'><Translate id='clinics.profile.waitlist.cancelModal.label' /> <Required /></Label>
                                    <FormikTextArea id='cancellation_reason' name='cancellation_reason' rows='3' trim />
                                </FormGroup>
                                <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                                    <CustomAlert className='mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button type='submit' color='danger'><Translate id='misc.confirm' /></Button>
                                <Button type='button' color='primary' outline onClick={toggle}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    )
}

export default CancelWaitlistMemberModal