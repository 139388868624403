import Translate from '@spordle/intl-elements';
import {
    Alert,
    Button,
    Collapse,
    Fade,
    ModalBody,
    ModalFooter, ModalHeader
} from "reactstrap";
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { Form, Formik } from 'formik';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { useContext, useState } from 'react';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import CustomAnimatedIcon from '../../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import InlineCopy from '../../../../../components/inlineCopy/InlineCopy';
import GenderLabel from '../../../../../components/GenderLabel';
import DisplayBirthdateAge from '../../../../../components/DisplayBirthdateAge';

const MemberConfirmModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName='MemberConfirmModal'>
            <MemberConfirmModalInner {...props} />
        </AnalyticsModal>
    )
}

const MemberConfirmModalInner = ({ isOpen, toggle, member, refreshMemberProfile, forceCloseSidepanel }) => {
    const membersContext = useContext(MembersContext)
    const [ isSuccess, setIsSuccess ] = useState(false);

    return (
        <Formik
            initialValues={{}}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                setStatus();

                return membersContext.confirmMembers([ member.member_id ])
                    .then(() => {
                        setStatus('success')
                        setSubmitting(false);
                        setIsSuccess(true);
                        membersContext.setShouldAttemptAutoConfirm(false);
                        if(refreshMemberProfile)
                            refreshMemberProfile()

                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            membersContext.setShouldAttemptAutoConfirm(false);
                            console.error(error.message);
                            setSubmitting(false);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader
                            toggle={() => {
                                membersContext.setShouldAttemptAutoConfirm(false);
                                toggle();
                            }}
                        >
                            <Translate id='members.profile.confirmModal.title' />
                        </ModalHeader>
                        {(!isSuccess && formik.status !== 'success') &&
                                    <div>
                                        <ModalBody className="text-center">
                                            <UserDisplay card className='w-100'>
                                                <UserDisplay.Container>
                                                    <UserImg
                                                        abbr={member.first_name[0] + member.last_name[0]}
                                                        src={null}
                                                        width={50}
                                                        height={50}
                                                        alt={`${member.first_name} ${member.last_name}`}
                                                    />
                                                </UserDisplay.Container>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title>{member.first_name} {member.last_name}</UserDisplay.Title>
                                                    <UserDisplay.Subtitle className="font-medium text-dark"><InlineCopy tag='div' toCopy={member.unique_identifier} className="font-medium">{'#' + member.unique_identifier || '-'}</InlineCopy></UserDisplay.Subtitle>
                                                    <UserDisplay.Subtitle><DisplayBirthdateAge birthdate={member.birthdate} age={member.age} /></UserDisplay.Subtitle>
                                                    <UserDisplay.Subtitle><GenderLabel gender={member.gender} /></UserDisplay.Subtitle>
                                                </UserDisplay.Container>
                                                <UserDisplay.Container className='ml-auto text-body'>
                                                    <CustomAnimatedIcon
                                                        className='text-success'
                                                        strokeWidth="10px"
                                                        size={50}
                                                        id='confirmModalCheckmark'
                                                        icon='checkmark'
                                                        withCircle
                                                    />
                                                </UserDisplay.Container>
                                            </UserDisplay>


                                            {membersContext.state.shouldAttemptAutoConfirm ?
                                                <div className='font-bold text-dark'><Translate id='members.profile.preConfirmModal.message' /></div>
                                                :
                                                <div className='font-bold text-dark'><Translate id='members.profile.confirmModal.message' /></div>
                                            }


                                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                                <Alert color='danger' className='text-left mt-2'>{formik.status}</Alert>
                                            </Collapse>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                                                <Translate id='misc.submit' />
                                            </Button>
                                            <Button
                                                color='primary' type='button' onClick={() => {
                                                    membersContext.setShouldAttemptAutoConfirm(false);
                                                    toggle();
                                                }}
                                                outline disabled={formik.isSubmitting}
                                            ><Translate id='misc.cancel' />
                                            </Button>
                                        </ModalFooter>
                                    </div>
                        }
                        {(isSuccess && formik.status == 'success') &&
                                    <div>
                                        <ModalBody className="text-center text-dark">
                                            <Fade in>
                                                <div className='position-relative d-inline-block'>
                                                    <CustomAnimatedIcon
                                                        className='text-success'
                                                        strokeWidth="10px"
                                                        size={50}
                                                        id='confirmModalCheckmark'
                                                        icon='checkmark'
                                                        withCircle
                                                    />
                                                </div>

                                                <div className="text-center h5 font-bold pt-2">
                                                    <Translate id="misc.success" />
                                                </div>
                                                <div><Translate id='members.profile.action.confirmModal.successText' /></div>
                                            </Fade>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color='primary' type='button' onClick={() => { toggle(); setIsSuccess(false); forceCloseSidepanel && forceCloseSidepanel(); }} outline disabled={formik.isSubmitting}><Translate id='misc.close' /></Button>
                                        </ModalFooter>
                                    </div>
                        }
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default MemberConfirmModal;