import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import CollapsibleCard from "../../../../components/collapsibleCard/CollapsibleCard";
import HasAccessTo from "../../../../components/permissions/HasAccessTo";
import QuickViewButton from "../../../../components/quickView/QuickViewButton";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../components/UserImg";
import { RolesContext } from "../../../../contexts/RolesContext";
import { TeamsContext } from "../../../../contexts/TeamsContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import MemberTeamStatus from "../../../members/profile/teams/MemberTeamStatus";
import MemberStatusIcons from "../../../members/search/components/MemberStatusIcons";
import TeamMemberAge from "../../profile/TeamRoster/components/TeamMemberAge";
import { getTeamMemberStatus } from "../../TeamHelpers";
import { Link } from 'react-router-dom';
import { useIntl } from "react-intl";
import { ScrollArea } from "@mantine/core";
import moment from 'moment';

const TeamSearchRosterCards = ({ team, getLockedRosterDate, getLockedRoster }) => {
    const rolesContext = useContext(RolesContext);
    const teamsContext = useContext(TeamsContext);
    const intl = useIntl();

    const lockedRosterDateActive = getLockedRosterDate('ACTIVE')
    const lockedRosterActive = getLockedRoster('ACTIVE')
    const lockedRosterDateAffiliated = getLockedRosterDate('AFFILIATE')
    const lockedRosterAffiliated = getLockedRoster('AFFILIATE')
    const lockedRosterDateStaff = getLockedRosterDate('STAFF')
    const lockedRosterStaff = getLockedRoster('STAFF')

    const [ activeRoster, setActiveRoster ] = useState([]);
    const [ affiliatedRoster, setAffiliatedRoster ] = useState([]);
    const [ staffRoster, setStaffRoster ] = useState([]);
    const [ inactiveRoster, setInactiveRoster ] = useState([]);

    useEffect(() => {
        if(rolesContext.hasAccessTo('teams', 'team_members')){
            teamsContext.getRosterTablesData(team.team_id, null, true)
                .then((teamMembers) => {
                    setActiveRoster(teamsContext.formatDataActive(teamMembers || [], intl))
                    setAffiliatedRoster(teamsContext.formatDataAffiliated(teamMembers || []))
                    setStaffRoster(teamsContext.formatDataStaff(teamMembers || []))
                    setInactiveRoster(teamsContext.formatDataInactive(teamMembers || [], intl))
                })
        }
    }, [ team.team_id ])

    return (
        <>
            <div className="h5 font-bold mt-2"><Translate id='teams.profile.roster.tab.title' /></div>

            <CollapsibleCard
                title={
                    <>
                        <Translate id='teams.profile.roster.table.activeRoster.title' />
                        <span className='ml-2'>
                            ({activeRoster.length})
                        </span>
                        <QuickViewButton alwaysShowIcon className="d-inline-block" member={activeRoster.map((teamMember) => teamMember.member)}>
                            <span>&nbsp;</span>
                        </QuickViewButton>

                        {(lockedRosterDateActive && !lockedRosterActive) &&
                            <div className='font-medium small text-muted'><i className="mr-1 mdi mdi-lock-open-outline" /><Translate id="teams.profile.roster.lockdate" />: {moment(lockedRosterDateActive).format('lll')}</div>
                        }
                        {lockedRosterActive &&
                            <div className='font-medium small text-muted'><i className="mr-1 mdi mdi-lock-open-outline" /><Translate id="teams.profile.roster.locked" /></div>
                        }
                    </>
                }
                className='mb-3'
            >
                <ScrollArea style={{ maxHeight: '30vh' }} className='d-flex flex-column min-h-0' offsetScrollbars>
                    {activeRoster.length == 0 &&
                        <div className="max-w-75 mx-auto text-center text-muted">
                            <div className="mdi-account-circle mdi line-height-1" style={{ fontSize: 50 }} />
                            <div className="font-bold"><Translate id='teams.profile.roster.table.activeRoster.empty' /></div>
                        </div>
                    }
                    {activeRoster.map((teamMember) => (
                        <MemberCard key={teamMember.team_member_id} teamMember={teamMember} />
                    ))}
                </ScrollArea>
            </CollapsibleCard>

            <CollapsibleCard
                title={
                    <>
                        <Translate id='teams.profile.roster.table.affiliated.title' />
                        <span className='ml-2'>
                            ({affiliatedRoster.length})
                        </span>
                        <QuickViewButton alwaysShowIcon className="d-inline-block" member={affiliatedRoster.map((teamMember) => teamMember.member)}>
                            <span>&nbsp;</span>
                        </QuickViewButton>

                        {(lockedRosterDateAffiliated && !lockedRosterAffiliated) &&
                            <div className='font-medium small text-muted'><i className="mr-1 mdi mdi-lock-open-outline" /><Translate id="teams.profile.roster.lockdate" />: {moment(lockedRosterDateAffiliated).format('lll')}</div>
                        }
                        {lockedRosterAffiliated &&
                            <div className='font-medium small text-muted'><i className="mr-1 mdi mdi-lock-open-outline" /><Translate id="teams.profile.roster.locked" /></div>
                        }
                    </>
                }
                className='mb-3'
            >
                <ScrollArea style={{ maxHeight: '30vh' }} className='d-flex flex-column min-h-0' offsetScrollbars>
                    {affiliatedRoster.length == 0 &&
                        <div className="max-w-75 mx-auto text-center text-muted">
                            <div className="mdi-account-circle mdi line-height-1" style={{ fontSize: 50 }} />
                            <div className="font-bold"><Translate id='teams.profile.roster.table.affiliated.empty' /></div>
                        </div>
                    }
                    {affiliatedRoster.map((teamMember) => (
                        <MemberCard key={teamMember.team_member_id} teamMember={teamMember} />
                    ))}
                </ScrollArea>
            </CollapsibleCard>

            <CollapsibleCard
                title={
                    <>
                        <Translate id='teams.profile.roster.table.benchStaff.title' />
                        <span className='ml-2'>
                            ({staffRoster.length})
                        </span>
                        <QuickViewButton alwaysShowIcon className="d-inline-block" member={staffRoster.map((teamMember) => teamMember.member)}>
                            <span>&nbsp;</span>
                        </QuickViewButton>

                        {(lockedRosterDateStaff && !lockedRosterStaff) &&
                            <div className='font-medium small text-muted'><i className="mr-1 mdi mdi-lock-open-outline" /><Translate id="teams.profile.roster.lockdate" />: {moment(lockedRosterDateStaff).format('lll')}</div>
                        }
                        {lockedRosterStaff &&
                            <div className='font-medium small text-muted'><i className="mr-1 mdi mdi-lock-open-outline" /><Translate id="teams.profile.roster.locked" /></div>
                        }
                    </>
                }
                className='mb-3'
            >
                <ScrollArea style={{ maxHeight: '30vh' }} className='d-flex flex-column min-h-0' offsetScrollbars>
                    {staffRoster.length == 0 &&
                        <div className="max-w-75 mx-auto text-center text-muted">
                            <div className="mdi-account-circle mdi line-height-1" style={{ fontSize: 50 }} />
                            <div className="font-bold"><Translate id='teams.profile.roster.table.benchStaff.empty' /></div>
                        </div>
                    }
                    {staffRoster.map((teamMember) => (
                        <MemberCard key={teamMember.team_member_id} teamMember={teamMember} />
                    ))}
                </ScrollArea>
            </CollapsibleCard>

            <CollapsibleCard
                title={
                    <>
                        <Translate id='teams.profile.roster.table.inactiveRoster.title' />
                        <span className='ml-2'>
                            ({inactiveRoster.length})
                        </span>
                        <QuickViewButton alwaysShowIcon className="d-inline-block" member={inactiveRoster.map((teamMember) => teamMember.member)}>
                            <span>&nbsp;</span>
                        </QuickViewButton>
                    </>
                }
                className='mb-3'
            >
                <ScrollArea style={{ maxHeight: '30vh' }} className='d-flex flex-column min-h-0' offsetScrollbars>
                    {inactiveRoster.length == 0 &&
                        <div className="max-w-75 mx-auto text-center text-muted">
                            <div className="mdi-account-circle mdi line-height-1" style={{ fontSize: 50 }} />
                            <div className="font-bold"><Translate id='teams.profile.roster.table.inactiveRoster.empty' /></div>
                        </div>
                    }
                    {inactiveRoster.map((teamMember) => (
                        <MemberCard key={teamMember.team_member_id} teamMember={teamMember} />
                    ))}
                </ScrollArea>
            </CollapsibleCard>
        </>
    )
}

const MemberCard = ({ teamMember }) => {

    return (
        <UserDisplay card block>
            <UserDisplay.Container>
                <UserImg
                    abbr={teamMember.member.first_name.charAt(0) + teamMember.member.last_name.charAt(0)}
                    src={teamMember.member.picture?.full_path}
                    filePos={teamMember.member.picture?.file_position}
                    width={40}
                    alt={teamMember.member.first_name + ' ' + teamMember.member.last_name}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title>
                    <QuickViewButton className="d-flex" member={teamMember.member}>
                        {teamMember.member.first_name + ' ' + teamMember.member.last_name}
                    </QuickViewButton>
                </UserDisplay.Title>
                <UserDisplay.Subtitle>
                    <HasAccessTo componentCode='members' componentPermissionCode='members_profile'>
                        <Link className="text-nowrap" to={"/members/profile/" + teamMember.member.member_id}>#{teamMember.member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></Link>
                    </HasAccessTo>
                </UserDisplay.Subtitle>
                <TeamMemberAge member={teamMember} />
                {teamMember.position &&
                    <UserDisplay.Subtitle>
                        <DisplayI18n field='name' defaultValue={teamMember.position.name} i18n={teamMember.position.i18n} />
                    </UserDisplay.Subtitle>
                }
                {(teamMember.status || teamMember.import == 1 || teamMember.tryout == 1) &&
                    <UserDisplay.Subtitle>
                        {teamMember.status &&
                            <div className="mb-2">
                                {getTeamMemberStatus(teamMember.status)}
                            </div>
                        }
                        {teamMember.tryout == 1 &&
                            <MemberTeamStatus
                                className="teamRoster-status mr-2"
                                tip="teams.profile.roster.table.tryout"
                            >
                                <Translate id='teams.profile.roster.table.tryout.short' />
                            </MemberTeamStatus>
                        }
                        {teamMember.import == 1 &&
                            <MemberTeamStatus
                                className="teamRoster-status mr-2"
                                tip="teams.profile.roster.table.imported"
                            >
                                <Translate id='teams.profile.roster.table.imported.short' />
                            </MemberTeamStatus>
                        }
                    </UserDisplay.Subtitle>
                }
            </UserDisplay.Container>
            <UserDisplay.Container className="ml-auto">
                {/* crc_is_expired 0 because we don't want to show that here */}
                <MemberStatusIcons member={{ ...teamMember.member, crc_is_expired: 0 }} />
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default TeamSearchRosterCards