export default{
    "onlineStore.components.onlineStoreRebatesTerms.label" : "Terms and conditions, Discounts",

    // Rebates select
    "onlineStore.components.onlineStoreRebatesTerms.rebates.label"       : "Discount",
    "onlineStore.components.onlineStoreRebatesTerms.rebates.empty"       : "No discount",
    "onlineStore.components.onlineStoreRebatesTerms.rebates.placeholder" : "Select a discount",
    "onlineStore.components.onlineStoreRebatesTerms.rebates.textWhen"    : "{count} selected discounts",

    // Terms & Conditions select
    "onlineStore.components.onlineStoreRebatesTerms.terms.label"               : "Terms & conditions",
    "onlineStore.components.onlineStoreRebatesTerms.terms.empty"               : "No Terms & conditions",
    "onlineStore.components.onlineStoreRebatesTerms.terms.placeholder"         : "Select Terms & conditions",
    "onlineStore.components.onlineStoreRebatesTerms.terms.textWhen"            : "{count} selected Terms & conditions",
    "onlineStore.components.onlineStoreRebatesTerms.terms.validation.required" : "You must have Terms & conditions",
}