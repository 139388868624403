import React from "react";
import {
    Col,
    Row
} from 'reactstrap';

import Translate from "@spordle/intl-elements";
import { Link } from "react-router-dom";

import comingSoonImage from '../../../assets/images/ComingSoon.png';

class InvalidClinicProfileId extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            fullGuide: false,
        }

        this.toggleFullGuide = this.toggleFullGuide.bind(this)
    }

    toggleFullGuide = () => {
        this.setState((prevState) => ({ fullGuide: !prevState.fullGuide }));
    }

    render(){
        return (
            <>
                <div className="page-content container-fluid">
                    <Row>
                        <Col sm="12" md="4" lg="4">
                            <div className="h1 font-bold dashboard-title mb-4"><Translate id="teams.profile.notFound.title" /></div>
                            <p className="font-16">
                                <Translate id="teams.profile.notFound.description" />
                            </p>
                            <Link to='/teams' className="btn btn-primary mr-2 mb-2"><i className="mdi mdi-arrow-left mr-1" /><Translate id="teams.profile.notFound.btn" /></Link>
                        </Col>
                        <Col sm="12" md="8" lg="8">
                            <img className="w-100" src={comingSoonImage} />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default InvalidClinicProfileId;