import { useSpordleTable } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { success } from "@spordle/toasts";
import { useContext, useState } from "react";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { MessagesContext } from "../../../../../contexts/MessagesContext";
import { InboxContext } from "../../inboxContext/InboxContext";
import ToolBtn from "../ToolBtn";

const FavoriteTool = ({ checked, newVal, updateCheckedMsgs, disabled }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const { partiallyUpdateMessage } = useContext(MessagesContext);
    const { updateDataWith, refreshTable } = useSpordleTable();
    const { setShowTools } = useContext(InboxContext);

    const addMessagesToFavorites = (e) => {
        e.stopPropagation();
        setIsLoading(true);

        updateDataWith(checked.map((msg) => msg.message_id), { favorite: newVal ?? 1 });
        updateCheckedMsgs({ favorite: newVal ?? 1 });
        setShowTools(false);

        Promise.all(
            checked.map((msg) => partiallyUpdateMessage(msg.communication_message_id, { favorite: newVal ?? 1 })),
        )
            .then(() => {
                refreshTable();
                success({ msg: `communications.inbox.action.toast.${newVal === 0 ? "unfavorite" : "favorite"}` });
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <ToolBtn
            label={<Translate id={`communications.inbox.tools.${newVal && newVal == 0 ? "unfavorite" : "favorite"}`} />}
            isLoading={isLoading}
            disabled={disabled}
            onClick={addMessagesToFavorites}
            icon="star"
        />
    );
}

export default FavoriteTool;