export default{
    "task.document.approval.member"                        : "Membre",
    "task.document.approval.organization"                  : "Organisation",
    "task.document.approval.submitted_by"                  : "Soumis par",
    "task.document.approval.created_at"                    : "Créé le",
    "task.document.approval.manage.approval"               : "Gérer l'approbation",
    "task.document.approval.manage.openAll"                : "Tout ouvrir dans l'aperçu rapide",
    "task.document.approval.document.type"                 : "Type de document",
    "task.document.approval.filename"                      : "Nom de fichier",
    "task.document.approval.modal.title.type"              : "Gérer l'approbation des documents",
    "task.document.approval.modal.current.picture.preview" : "Cet aperçu est de la photo de profil actuelle.",

    "task.document.approval.modal.approve"         : "Approuver",
    "task.document.approval.modal.approve.confirm" : "Confirmer l'approbation",
    "task.document.approval.modal.refuse"          : "Refuser",
    "task.document.approval.modal.refuse.confirm"  : "Confirmer le refus",
    "task.document.approval.modal.cancel.confirm"  : "Confirmer l'annulation de la requête",
    "task.document.approval.modal.skip"            : "Sauter",

    "task.document.approval.list.approve.all" : "Approuver tous les documents",


    "task.document.approval.modal.refuse.validation"  : "Une note est nécessaire pour refuser un document.",
    "task.document.approval.modal.refuse.placeholder" : "Note",
    "task.document.approval.modal.success"            : "Le statut des documents mis à jour avec succès.",
    "task.document.approval.modal.success.multi"      : "Il ne reste plus de documents à approuver.",

    "task.document.sidepanel.count" : "{count} documents sélectionnées",

    "task.document.approval.decide.if.appropriate.resize" : "Veuillez décider si cette image est appropriée ou non et l'approuver ou la refuser. Vous devrez peut-être recentrer ou redimensionner l'image.",
    "task.document.approval.decide.if.appropriate"        : "Veuillez décider si ce document est appropriée ou non et l'approuver ou la refuser.",


    "task.document.approval.status.NO_VALIDATION" : "Aucune validation requise",
    "task.document.approval.status.PENDING"       : "En attente d'approbation",
    "task.document.approval.status.APPROVED"      : "Approuvé",
    "task.document.approval.status.REJECTED"      : "Rejeté",
}