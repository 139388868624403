import { Button, Col, Fade, Row } from 'reactstrap';
import SidePanel from '../../../../components/sidePanel/SidePanel';
import DisplayMember from '../../../../components/display/DisplayMember';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import DisplayMemberType from '../../../../components/display/DisplayMemberType';
import Translate from '@spordle/intl-elements';
import { useDisclosure } from '@mantine/hooks';
import UpdateMemberTypeStatusModal from '../../../members/components/UpdateMemberTypeStatusModal';
import { groupByAsArray } from '@spordle/helpers';
import UserImg from '../../../../components/UserImg';
import QuickViewButton from '../../../../components/quickView/QuickViewButton';
import InlineCopy from '../../../../components/inlineCopy/InlineCopy';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import DisplayMemberTypeStatus from '../../../../components/display/DisplayMemberTypeStatus';
import { Link } from 'react-router-dom';

const MemberTypeValidationSidepanel = (props) => {

    const formattedMembers = groupByAsArray(props.selectedRows, 'member_id')
        .map(({ values, key }) => ({
            ...values[0], // to have member info
            member_types: values,
        }))

    const [ isOpen, { open, close } ] = useDisclosure(false);
    // const [ formattedMembers, setFormattedMembers ] = useState([]);

    return (
        <Fade in>
            <UpdateMemberTypeStatusModal
                toggle={(isSuccess) => {
                    close();
                    if(isSuccess)
                        props.toggle();
                }}
                isOpen={isOpen}
                onSuccess={() => {
                    props.tableRef.refreshTable();
                }}
                members={formattedMembers}
            />

            {props.selectedRows.length == 1 ?
                <>
                    <SidePanel.Header className='sticky-top bg-white z-index-1 mb-3'>
                        <div className='d-flex mb-2 align-items-center'>
                            <SidePanel.ToggleButton />
                            <div className="ml-auto d-flex align-items-center">
                                <SidePanel.ActionsMenu>
                                    <SidePanel.MenuAction
                                        onClick={() => {
                                            open()
                                        }}
                                    >
                                        <Translate id='tasks.memberTypeValidation.sidepanel.manage' />
                                    </SidePanel.MenuAction>
                                </SidePanel.ActionsMenu>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <UserImg
                                alt={formattedMembers[0].first_name + ' ' + formattedMembers[0].last_name}
                                abbr={formattedMembers[0].first_name.charAt(0) + formattedMembers[0].last_name.charAt(0)}
                                src={formattedMembers[0].picture?.full_path}
                                filePos={formattedMembers[0].picture?.file_position}
                                width={60}
                                className="mr-2"
                            />
                            <div className="w-100">
                                <div className="d-flex align-items-center text-break">
                                    <span className='h4 font-bold mb-0'>
                                        <QuickViewButton member={formattedMembers[0]}>
                                            {formattedMembers[0].first_name} {formattedMembers[0].last_name}
                                        </QuickViewButton>
                                    </span>
                                </div>
                                <div>
                                    <InlineCopy tag="span" toCopy={formattedMembers[0].unique_identifier}>
                                        <Link className="text-nowrap" to={`/members/profile/${formattedMembers[0].member_id}`}>
                                            {formattedMembers[0].unique_identifier ?
                                                <>#{formattedMembers[0].unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                :
                                                '-'
                                            }
                                        </Link>
                                    </InlineCopy>
                                </div>
                            </div>
                        </div>
                    </SidePanel.Header>
                    <div className='px-3'>
                        <Row form>
                            <Col sm='6' className='mb-3'>
                                <div className='text-muted'>
                                    <Translate id='tasks.memberTypeValidation.sidepanel.memberType' />
                                </div>
                                <div className='font-medium text-dark'>
                                    <DisplayI18n field='name' defaultValue={formattedMembers[0].member_types[0].member_type.name} i18n={formattedMembers[0].member_types[0].member_type.i18n} />
                                </div>
                            </Col>
                            <Col sm='6' className='mb-3'>
                                <div className='text-muted'>
                                    <Translate id='tasks.memberTypeValidation.sidepanel.status' />
                                </div>
                                {formattedMembers[0].member_type_status ?
                                    <DisplayMemberTypeStatus status={formattedMembers[0].member_type_status} />
                                    :
                                    '-'
                                }
                            </Col>
                            <Col sm='6' className='mb-3'>
                                <div className='text-muted'>
                                    <Translate id='tasks.memberTypeValidation.sidepanel.decidedBy' />
                                </div>
                                <div className='font-medium text-dark'>
                                    {formattedMembers[0].decision_made_by ?
                                        <>{formattedMembers[0].decision_made_by.name} {formattedMembers[0].decision_made_by.family_name}</>
                                        :
                                        '-'
                                    }
                                </div>
                            </Col>
                            <Col sm='6' className='mb-3'>
                                <div className='text-muted'>
                                    <Translate id='tasks.memberTypeValidation.sidepanel.note' />
                                </div>
                                <div className='font-medium text-dark'>
                                    {formattedMembers[0].decision_note || "-"}
                                </div>
                            </Col>
                        </Row>
                        <Button
                            color='primary' outline
                            type='button' block
                            onClick={() => open()}
                        >
                            <Translate id='tasks.memberTypeValidation.sidepanel.manage' />
                        </Button>
                    </div>
                </>
                :
                <>
                    <SidePanel.Header className='sticky-top bg-white z-index-1'>
                        <div className='d-flex mb-2 align-items-center'>
                            <SidePanel.ToggleButton />
                            <div className="ml-auto d-flex align-items-center">
                                <SidePanel.ActionsMenu>
                                    <SidePanel.MenuAction
                                        onClick={() => {
                                            open()
                                        }}
                                    >
                                        <Translate id='tasks.memberTypeValidation.sidepanel.manage' />
                                    </SidePanel.MenuAction>
                                </SidePanel.ActionsMenu>
                            </div>
                        </div>
                        <SidePanel.Title>
                            <QuickViewButton member={formattedMembers}>
                                <Translate id='tasks.memberTypeValidation.sidepanel.count' values={{ count: formattedMembers.length }} />
                            </QuickViewButton>
                        </SidePanel.Title>
                    </SidePanel.Header>
                    <div className='px-3 pb-3'>
                        {formattedMembers.map((member) => (
                            <DisplayMember
                                key={member.member_id}
                                member={member}
                                card={false}
                                className="py-3 border-bottom"
                                extra={{
                                    infoBottom: (
                                        member.member_types.map((type) => (
                                            <UserDisplay.Subtitle key={type.member_member_type_id} className='text-body'>
                                                <DisplayMemberType
                                                    member={member}
                                                    memberType={type}
                                                />
                                            </UserDisplay.Subtitle>
                                        ))
                                    ),
                                }}
                            />
                        ))}
                    </div>
                </>
            }
        </Fade>
    )
};

export default MemberTypeValidationSidepanel;
