import moment from "moment";
import { AxiosIsCancelled } from "../api/CancellableAPI";
import { fail } from '@spordle/toasts';
import { triggerDownload } from "../components/uploader/uploadHelpers";
import { copyToClipBoard } from "./clipboardHelper";
import { exportToCsv } from "./export";
import { roundForMoney } from "./helper";
import { DisplayI18n, displayI18n } from "./i18nHelper";

export const MONEY_FIELDS = [
    'affiliation_paid',
    'affiliation_due',
    'affiliation_to_paid',
    'amount_paid',
    'amount_to_paid',
    'amount_due',
    'tax_amount',
    'rebate_amount',
    'registration_amount',
    'registration_early_amount',
    'registration_late_amount',
    'amount_owed',
    'late_amount',
    'scheduled_installment',
    'balance',
    'credit_amount',
    'clinic_additional_fee',
    'clinic_amount',
    'due_amount',
    'owed_amount',
    'paid_amount',
    'rebate_amount',
    'total_amount',
    'refund_amount',
    'invoice_amount',
    'amount',
]

export const controlClickCopy = (e, rowData, columnName) => {
    if(e.nativeEvent.ctrlKey && rowData[columnName]){
        if(!isNaN(rowData[columnName]) && MONEY_FIELDS.includes(columnName)){
            //for money
            copyToClipBoard(roundForMoney(rowData[columnName].toString()))
        }else{
            // for everything else
            copyToClipBoard(rowData[columnName].toString());
        }
        return false;
    }
    return true;
}

export const exportReportsToCSV = (reports, columns, intl, reportType) => {
    const columnHeaders = columns.map((column) => (intl.formatMessage({ id: `reports.column.header.${column.key}` })));
    const data = reports.map((report) => columns.map((column) => {
        if(report[column.key] == '-' || report[column.key] == null || report[column.key] == undefined){
            return '';
        }else if(!isNaN(report[column.key]) && MONEY_FIELDS.includes(column.key)){
            return roundForMoney(report[column.key]) || 0;
        }else if(column.key === 'unique_identifier' || column.key === 'invoice_number' || column.key === 'street_number'){
            return `="${report[column.key]}"`;
        }else if(column.key === 'invoice_date'){
            return report[column.key] ? moment(report[column.key]).format('YYYY-MM-DD') : '';
        }else if(column.key === 'status' && reportType !== 'member_positions'){
            return intl.formatMessage({ id: `reports.column.status.${report[column.key]}` });
        }else if(column.key === 'origin' && reportType !== 'transactional_report'){
            return intl.formatMessage({ id: `reports.column.origin.${report[column.key]}` });
        }else if(column.key === 'fee_type'){
            return intl.formatMessage({ id: `reports.registration.type.${report[column.key]}` });
        }else if(column.key === 'team_comments'){
            let builtComment = '';
            JSON.parse(report[column.key])?.map((comment) => {
                builtComment += comment.comment + '\n';
                builtComment += comment.comment_date + '\n';
            })
            return builtComment;
        }
        return report[column.key] || '';
    }));

    exportToCsv(`SpordleID-Reports_${moment().format('YYYY-MM-DD H:mm')}.csv`, [ columnHeaders, ...data ]);
};

export const exportQuestionnaireReportsToCSV = (reports, formatMessage, columns, formGroups, locale) => {
    const columnHeaders = columns.map((column) => {
        if(column.isCustomField){
            let formGroup = false
            let formField = false
            for(let groupIndex = 0; groupIndex < formGroups.length; groupIndex++){
                if(!formField){
                    formField = formGroups[groupIndex].fields.find((e) => e.custom_form_field_id === column.key);
                    formGroup = formGroups[groupIndex]
                }
            }
            return displayI18n('title', formField?.i18n, formField?.title, locale) + ' (' + displayI18n('name', formGroup?.i18n, formGroup?.name, locale) + ')'
        }
        return formatMessage({ id: `reports.column.header.${column.key}` });
    });

    const data = reports.map((report) => columns.map((column) => {
        if(report[column.key] == '-' || report[column.key] == null || report[column.key] == undefined){
            return '';
        }else if(!isNaN(report[column.key]) && MONEY_FIELDS.includes(column.key)){
            return roundForMoney(report[column.key]) || 0;
        }else if(column.key === 'unique_identifier' || column.key === 'invoice_number' || column.key === 'street_number'){
            // forces string formatting in excel when opening a csv
            return `="${report[column.key]}"`;
        }
        return report[column.key] || '';
    }));

    exportToCsv(`SpordleID-QuestionnaireReports_${moment().format('YYYY-MM-DD H:mm')}.csv`, [ columnHeaders, ...data ]);
};


/**
 * The helper that handles exporting excel reports
 * @param {function} setIsLoading the function to set loading when using an isLoading for checking if export is currently available (sets to false or true)
 * @param {string} reportType the string representing the type of report
 * @param {object} filters the filters used on the call that gets the data, must be pre formatted in the way they would be used for the original call
 * @param {number} totalCount total count
 * @param {object} reportsContext reportsContext
 * @param {string} identity_role_id identity_role_id
 * @param {string} identity_id identity_id
 * @param {string} genericLocale genericLocale
 * @param {string} organizationId organizationId from the organization context, the current org.
 */
export const handleExcelExport = (setIsLoading, reportType, filters, totalCount, reportsContext, identity_role_id, identity_id, genericLocale, organizationId) => {
    setIsLoading(true);
    reportsContext.getReports({ identity_role_id: identity_role_id })
        .then((report_type) => {
            const reportTypeId = report_type.find((type) => type.code === reportType).report_id;

            reportsContext.createExportableReport({ organisation_id: organizationId, total_count: totalCount, language_code: genericLocale, report_id: reportTypeId, requested_by: identity_id, request_parameter: JSON.stringify(filters) })
                .then((data) => {
                    if(data.download_link){
                        triggerDownload(data.download_link)
                    }
                }).catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e.message);
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }).catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            }
        })
    setIsLoading(false);

}

export const removeNullValues = (object = {}) => Object.entries(object).reduce((a, [ k, v ]) => (v ? (a[k] = v, a) : a), {})

export const buildDataClassName = (columnType) => {
    switch (columnType){
        case 'STRING':
            return 'text-nowrap';
        case 'MONEY':
            return 'text-nowrap text-right';
        case 'DATE':
        case 'TIME':
        case 'DATETIME':
            return 'text-nowrap';
        case 'NUMBER':
            return 'text-nowrap';
        case 'BOOLEAN':
            return 'text-nowrap';
        case 'STRING_SPLIT':
            return 'text-nowrap';
        default:
            return 'text-nowrap';
    }
}