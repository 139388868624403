import { stringBuilder } from "@spordle/helpers";
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import CanDoAction from "../permissions/CanDoAction";
import RetryInstallmentBadge from "./RetryInstallmentBadge";
import UserDisplay from "../userDisplay/UserDisplay";
import UserImg from "../UserImg";
import { DisplayI18n } from "../../helpers/i18nHelper";
import EditTransactionIntallmentsModal from "./EditTransactionInstallmentsModal";
import { PaymentStatus } from "./TransactionHelper";
import { Link, useHistory } from "react-router-dom";
import HasAccessTo from "../permissions/HasAccessTo";
import QuickViewButton from "../quickView/QuickViewButton";
import InlineCopy from "../inlineCopy/InlineCopy";

const TransactionRegistration = ({ registration, syncRows, selectedRows, createNewValues, tableRef, retrySuccess, disableActions, ...props }) => {
    const history = useHistory();

    const [ editInstallmentsIsOpen, setEditInstallmentsIsOpen ] = useState(false);

    const [ sortedPayments, setSortedPayments ] = useState(registration.payments_to_display || []);

    let initialPayment = registration.payments_to_display?.find((_payment) => _payment.payment_type === 'INITIAL_PAYMENT');
    useEffect(() => {
        setSortedPayments(registration.payments_to_display?.sort((a, b) => {
            const aDueDate = moment(a.due_date);
            const bDueDate = moment(b.due_date);

            return aDueDate.isBefore(bDueDate) ? -1 : bDueDate.isBefore(aDueDate) ? 1 : 0;
        }) || []);
        initialPayment = sortedPayments.find((_payment) => _payment.payment_type === 'INITIAL_PAYMENT');
    }, [ JSON.stringify(registration.payments_to_display) ])


    const toggleEditInstallments = (bool) => {
        setEditInstallmentsIsOpen(typeof bool == "boolean" ? bool : !editInstallmentsIsOpen);
    }

    const onRetryInstallment = (values, paymentId, invoiceItemId) => {
        syncRows(createNewValues({
            registrations: [
                ...selectedRows[0].registrations.reduce((newArray, invoiceItem) => {
                    if(invoiceItem.invoice_item_id === invoiceItemId){
                        newArray.push({
                            ...invoiceItem,

                            payments_to_display: invoiceItem.payments_to_display.reduce((newArrayPayments, payment) => {
                                if(payment.invoice_payment_id === paymentId){
                                    newArrayPayments.push({
                                        ...payment,
                                        ...values,
                                    })
                                }else{
                                    newArrayPayments.push(payment)
                                }
                                return newArrayPayments
                            }, []),

                            payments: invoiceItem.payments.reduce((newArrayPayments, payment) => {
                                if(payment.invoice_payment_id === paymentId){
                                    newArrayPayments.push({
                                        ...payment,
                                        ...values,
                                    })
                                }else{
                                    newArrayPayments.push(payment)
                                }
                                return newArrayPayments
                            }, []),
                        })
                    }else{
                        newArray.push(invoiceItem)
                    }
                    return newArray
                }, []),
            ],
        }))
    }

    return (
        <Card className={stringBuilder('card-shadow mb-3', { 'bg-light-inverse': registration.cancelled_at })}>
            <CardBody>
                {/* At least one INSTALLMENT that's not CANCELLED or COMPLETED */}
                {sortedPayments.some((p) => p.payment_type !== 'INITIAL_PAYMENT' && p.status !== "COMPLETED" && p.status !== "CANCELLED") &&
                    <CanDoAction componentCode='members' componentPermissionCode='member_transactions' action='EDIT'>
                        <Button onClick={toggleEditInstallments} size="sm" color="link" className='position-absolute top-0 right-0 m-2' type='button' disabled={disableActions}>
                            <Translate id="misc.edit" />
                        </Button>
                        <EditTransactionIntallmentsModal
                            refreshTable={() => { props.formatAndSyncRows?.(); props.setForceRegistrationRefresh?.(); }}
                            invoiceNb={selectedRows[0].invoice_number}
                            registration={registration}
                            sortedPayments={sortedPayments}
                            isOpen={editInstallmentsIsOpen}
                            toggle={() => toggleEditInstallments(false)}
                            toggleSidepanel={() => null} // this was only used to clsoe the sidepanel on refresh, with formatAndSyncRows we no longer need to do this
                        />
                    </CanDoAction>
                }
                <UserDisplay className="mb-3">
                    <UserDisplay.Container>
                        <UserImg
                            className="text-uppercase shadow-sm"
                            abbr={registration?.member.first_name.substring(0, 1) + registration?.member.last_name.substring(0, 1)}
                            src={registration?.member.picture?.full_path}
                            filePos={registration?.member.picture?.file_position}
                            width={40}
                            alt={registration?.member.first_name + ' ' + registration?.member.last_name}
                        />
                    </UserDisplay.Container>
                    <UserDisplay.Container>
                        <UserDisplay.Title>
                            <QuickViewButton member={registration?.member}>
                                {registration?.member.first_name + ' ' + registration?.member.last_name}
                            </QuickViewButton>
                        </UserDisplay.Title>
                        <UserDisplay.Subtitle className="font-medium">
                            {
                                registration?.member.unique_identifier ?
                                    <InlineCopy toCopy={registration?.member.unique_identifier}>
                                        #{registration?.member.unique_identifier}
                                    </InlineCopy>
                                    :
                                    '-'
                            }
                            <HasAccessTo componentCode='members' componentPermissionCode='members_profile'>
                                <div>
                                    <Link to={"/members/profile/" + registration?.member.member_id} className="mr-2">
                                        <Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" />
                                    </Link>
                                </div>
                            </HasAccessTo>
                        </UserDisplay.Subtitle>
                    </UserDisplay.Container>
                </UserDisplay>
                <div className='font-medium mb-2'>
                    <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.items.payments' />
                    <span className="mx-1">:</span>
                    <DisplayI18n
                        field='name'
                        i18n={registration.registration_fee.fee?.i18n}
                        defaultValue={registration.registration_fee.fee?.name}
                    />
                    {registration.cancelled_at &&
                        <div className='text-danger small'><i className='mdi mdi-alert-outline mr-1' /><Translate id='memberProfile.registration.sidepanel.item.cancelled' /></div>
                    }
                </div>
                {sortedPayments.map((payment) => (
                    <Card className='mx-n2 p-2 border mb-2 card-shadow' key={payment.invoice_payment_id}>
                        <div className='d-flex justify-content-between align-items-center'>
                            {(
                                payment.status === 'DECLINED' ||
                                payment.status === 'NEED_REVIEW' ||
                                (
                                    payment.status === 'PENDING' &&
                                    moment(payment.due_date).isBefore() &&
                                    payment.payment_method?.code === 'CREDITCARD'
                                )
                            ) &&
                            initialPayment?.status === 'COMPLETED' &&
                            !disableActions ?

                                <RetryInstallmentBadge payment={payment} onRetryInstallment={onRetryInstallment} invoiceItemId={registration.invoice_item_id} retrySuccess={retrySuccess} />
                                :
                                <PaymentStatus payment={payment} />
                            }
                            <div><DisplayI18n field='name' defaultValue={payment.payment_method?.name} i18n={payment.payment_method?.i18n} /></div>
                            <CurrencyFormat value={parseInt(payment.amount) / 100} />

                            {payment.status === 'COMPLETED' && payment.payment_method?.code === 'CREDITCARD' &&
                                <UncontrolledDropdown inNavbar>
                                    <DropdownToggle tag='button' className='reset-btn text-link' onClick={(e) => e.stopPropagation()} disabled={disableActions}><i className='mdi mdi-dots-horizontal font-20' /></DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={() => history.push('receipt?invoiceNumber=' + selectedRows[0].invoice_number + '&paymentId=' + payment.invoice_payment_id)}>
                                            <Translate id='members.profile.transactions.memberProfileTransactions.paymentReceptions.viewReceipt' />
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }
                        </div>

                        <div className="mt-1 p-1 bg-light-inverse">
                            <div className='small d-flex'>
                                <div className="font-bold mr-1" style={{ width: "60px" }}><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.items.due' />:</div>
                                <div><DateFormat value={payment.due_date} utc /></div>
                            </div>
                            {payment.payment_date &&
                                <div className='small d-flex'>
                                    <div className="font-bold mr-1" style={{ width: "60px" }}><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.items.payment' />:</div>
                                    <div><DateFormat value={payment.payment_date} utc /></div>
                                </div>
                            }
                        </div>
                    </Card>
                ))}
                <div className="text-right w-100">
                    <span className="font-bold text-dark mr-1"><Translate id='members.profile.transactions.memberProfileTransactions.total' /> :</span>
                    <CurrencyFormat value={(registration.payments_to_display || []).reduce((total, payment) => total + parseInt(payment.amount), 0) / 100} />
                </div>
            </CardBody>
        </Card>
    )
}

export default TransactionRegistration;