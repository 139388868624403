import { FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import useSWR from 'swr';
import { object, string } from 'yup';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import { DisplayCategory } from '../../../../../views/teams/TeamHelpers';
import FormikEditable from '../../../../formik/FormikEditable';
import { success } from '@spordle/toasts';

const TransferTargetTeam = ({ canEditTransfer, transfer, setLoading, updateData, handleError, transferLoading }) => {
    const memberContext = useContext(MembersContext);
    const teamsContext = useContext(TeamsContext);
    const i18nContext = useContext(I18nContext);

    const useTeamObject = transfer.target_team || (transfer.transfer_type !== 'USA' && transfer.transfer_type !== 'INTERNATIONAL')

    const { data: teams, isValidating: teamsIsValidating } = useSWR(
        [ 'getTeams', transfer.target_organisation.organisation_id ],
        () => canEditTransfer ? teamsContext.getTeams({ active: 1, age: transfer.member.age, organisation_id: transfer.target_organisation.organisation_id, period_id: transfer.period.period_id }) : null,
        {
            onError: handleError,
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        },
    );

    return (
        <FormikEditable
            noConfirmation={useTeamObject}
            id='editTransferTargetTeam'
            disabled={!canEditTransfer || transfer.transfer_request_state === 'CLOSED' || transferLoading || teamsIsValidating || !Array.isArray(teams)}
            readOnly={transfer.transfer_request_state === 'CLOSED'}
            initialValues={{
                targetTeamId: transfer.target_team?.team_id || '',
                targetTeamName: transfer.target_team_name || transfer.team_name || '',
            }}
            validationSchema={!useTeamObject ? object().shape({
                targetTeamName: string().required(<Translate id='form.required' />),
            }) : undefined}
            onSubmit={({ targetTeamId, targetTeamName }) => {
                if(useTeamObject && targetTeamId !== (transfer.target_team?.team_id || '')){
                    setLoading(true);
                    return memberContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                        target_team_id: targetTeamId,
                    })
                        .then(async() => {
                            const team = targetTeamId ? teams.find((team) => team.team_id === targetTeamId) : null
                            await updateData({ // Updating data without having to make the api call
                                ...transfer,
                                target_team: team,
                            }, false);
                        })
                        .then(success)
                        .catch(handleError)
                        .finally(() => setLoading(false))
                }else if((transfer.target_team_name && targetTeamName !== (transfer.target_team_name || '')) || (transfer.team_name && targetTeamName !== (transfer.team_name || ''))){ // funky
                    setLoading(true);

                    const apiValues = {}
                    // TODO: HARDCODED
                    if(transfer.transfer_direction.name == 'Outgoing'){
                        apiValues.target_team_name = targetTeamName;
                    }else{
                        apiValues.team_name = targetTeamName;
                    }

                    return memberContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, apiValues)
                        .then(async() => {
                            await updateData({ // Updating data without having to make the api call
                                ...transfer,
                                ...apiValues,
                            }, false);
                        })
                        .then(success)
                        .catch(handleError)
                        .finally(() => setLoading(false))
                }
            }}
        >
            {(isEditing, options) => {
                if(!isEditing){
                    return transfer.target_team ? `${transfer.target_team.name} ${transfer.target_team.short_name ? '(' + transfer.target_team.short_name + ')' : ''}` : transfer.target_team_name || transfer.team_name || '-'
                }
                return (!useTeamObject ?
                    <FormikInputText
                        name='targetTeamName'
                        id='targetTeamName'
                        trim
                        autoFocus
                    />
                    :
                    <FormikSelect
                        name='targetTeamId'
                        id='targetTeamId'
                        onOptionSelected={() => {
                            options.stopEditing();
                        }}
                        clearable
                        isLoading={teamsIsValidating}
                        autoFocus
                        menuIsDefaultOpen
                        searchKeys={[
                            `category.class.i18n.${i18nContext.getGenericLocale()}.short_name`,
                            `division.i18n.${i18nContext.getGenericLocale()}.short_name`,
                        ]}
                        renderOption={({ option: team }) => (
                            <div>
                                <div>{team.label}</div>
                                <div className='text-muted small'>
                                    {team.division ? team.division : <DisplayCategory category={{ ...team?.category }} short /> }
                                    {team.category?.gender && <> - <Translate id={`form.fields.gender.${team.category.gender.toLowerCase()}`} /></>}
                                </div>
                            </div>
                        )
                        }
                        options={teams ?
                            teams.map((team) => ({
                                value: team.team_id,
                                label: `${team.name} ${team.short_name ? '(' + team.short_name + ')' : ''}`,
                                category: team.team_category,
                                division: team.division,
                            }))
                            :
                            []
                        }
                    />
                )

            }}
        </FormikEditable>
    )
}

export default TransferTargetTeam
