import { SpordleTableContext } from '@spordle/datatables';
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import {
    Button, FormGroup,
    Label, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { object, string } from 'yup';
import { success, fail } from '@spordle/toasts';
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { PhoneTypesContext } from '../../../../../../contexts/PhoneTypesContext';
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../../../../helpers/i18nHelper";

const OrganizationPhoneTypesAdd = ({ isOpen, toggle }) => {
    const i18nHelper = useContext(I18nHelperContext);
    const spordleTable = useContext(SpordleTableContext);
    const phoneTypesContext = useContext(PhoneTypesContext);
    const organizationContext = useContext(OrganizationContext);

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='OrganizationPhoneTypesAdd'>
            <Formik
                initialValues={{
                    ...i18nHelper.getInitialValues(),
                    status: '1',
                }}
                validationSchema={object().shape({
                    ...i18nHelper.getValidationSchema({ name: string().required(<Translate id='organization.settings.organizationPhoneTypes.add.name.required' />) }),
                    status: string().required(<Translate id='organization.settings.organizationPhoneTypes.add.status.required' />),
                })}
                onSubmit={(values, { setSubmitting }) => {

                    const apiValues = { ...values, ...i18nHelper.getAPIValues(values), active: values.status };
                    apiValues.organisation_id = organizationContext.organisation_id;
                    delete apiValues.i18n;

                    phoneTypesContext.createPhoneType(apiValues)
                        .then((data) => {
                            const tableData = {
                                phone_type_id: data,
                                ...values,
                                active: values.status,
                            }
                            spordleTable.addRow(tableData).then(toggle);
                            success();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                                setSubmitting(false);
                            }
                        })
                }}
            >
                {(formik) => {
                    return (
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <Form>
                                <ModalHeader toggle={toggle}><Translate id='organization.settings.organizationPhoneTypes.add.title' /></ModalHeader>
                                <ModalBody>
                                    <RenderI18nForm field="name">
                                        {({ fieldName, fieldLabel }) => {
                                            return (
                                                <FormGroup key={fieldName}>
                                                    <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                </FormGroup>
                                            )
                                        }}
                                    </RenderI18nForm>

                                    <FormGroup>
                                        <Label for='status' className='text-muted'><Translate id='organization.settings.organizationPhoneTypes.add.status' /></Label>
                                        <FormikSelect
                                            id='status' name='status'
                                            defaultData={[
                                                { value: '1', label: 'misc.active', translateLabel: true },
                                                { value: '0', label: 'misc.inactive', translateLabel: true },
                                            ]}
                                            loadingStatus='success'
                                        />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type='button' outline onClick={toggle} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </Form>
                        </OverlayLoader>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    )
}

export default OrganizationPhoneTypesAdd;