import SpordleTableProvider, { SpordleTableView, useSpordleTable } from "@spordle/datatables"
import { FormikSelect } from "@spordle/formik-elements"
import { stringBuilder } from "@spordle/helpers"
import Translate from "@spordle/intl-elements"
import { Form, Formik } from "formik"
import { useContext, useState } from "react"
import { Button, Col, CustomInput, Fade, Label, ModalBody, ModalFooter, Row } from "reactstrap"
import { object, string } from "yup"
import Required from "../../../../../components/formik/Required"
import OverlayLoader from "../../../../../components/loading/OverlayLoader"
import EmptyLayout from "../../../../../components/table/EmptyLayout"
import UserDisplay from "../../../../../components/userDisplay/UserDisplay"
import { PeriodsContext } from "../../../../../contexts/contexts"
import { I18nContext } from "../../../../../contexts/I18nContext"
import { OrganizationContext } from "../../../../../contexts/OrganizationContext"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import PerfectScrollbar from 'react-perfect-scrollbar'
import { InstallmentModesContext } from "../../../../../contexts/InstallmentModesContext"

const RolloverModalSelection = ({ onSubmit, goToPrevious }) => {

    const installmentsContext = useContext(InstallmentModesContext)
    const orgContext = useContext(OrganizationContext)
    const i18nContext = useContext(I18nContext)

    const [ error, setError ] = useState(null);

    return (
        <SpordleTableProvider
            id='installemntsRollover'
            clickable
            desktopWhen
            dataIndex="installment_mode_id"
            viewMode="GRID"
            searchKeys={[
                `name`,
                `i18n.${i18nContext.getGenericLocale()}.name`,
            ]}
            emptyLayout={<EmptyLayout hideArrow hideImg hideMsg />}
            initFilter={{
                periodId: '',
            }}
            loadData={(from, { filters }, { setLoading }) => {
                switch (from){
                    case 'FILTER':
                        setLoading();
                        return installmentsContext.getInstallmentModes(orgContext.organisation_id, {
                            period_id: filters.periodId,
                        })
                    case 'CDM':
                        return Promise.resolve([]);
                    default:
                        break;
                }
            }}
            onColumnClick={(e, installment, spordleTable) => {
                switch (e.button){
                    case 0: // Left mouse button
                        if(!installment.checked)
                            setError()
                        spordleTable.updateDataWith(installment.installment_mode_id, { checked: !installment.checked })
                        break;
                }
            }}
            gridConfig={{
                col: { sm: 12 },
                gridRender: (row) => {
                    const id = row.installment_mode_id + '_checkbox';

                    return (
                        <UserDisplay card block hover htmlFor={id} className={stringBuilder('mb-2')}>
                            <UserDisplay.Container>
                                <CustomInput id={id} type='checkbox' checked={!!row.checked} onChange={() => {}} />
                            </UserDisplay.Container>
                            <UserDisplay.Container>
                                <UserDisplay.Title>
                                    <DisplayI18n field='name' defaultValue={row.name} i18n={row.i18n} />
                                </UserDisplay.Title>
                            </UserDisplay.Container>
                        </UserDisplay>
                    )
                },
            }}
        >
            {(spordleTable) => {
                const allSelected = spordleTable.getDisplayedData().every((r) => r.checked)

                return (
                    <OverlayLoader isLoading={false}>
                        <ModalBody className="border-bottom">
                            <SearchSection />
                        </ModalBody>
                        <ModalBody className="max-vh-50 overflow-hidden d-flex flex-column">
                            <Row form className="mb-3">
                                <Col xs='6'>
                                    <SpordleTableProvider.SearchInput />
                                </Col>
                                <Col xs='6'>
                                    <Fade in={spordleTable.getDisplayedData().length > 0} className='d-flex w-100 h-100' mountOnEnter unmountOnExit>
                                        <button
                                            className="reset-btn text-link ml-auto align-self-end"
                                            type='button'
                                            onClick={() => {
                                                const displayedData = spordleTable.getDisplayedData().map((r) => r.installment_mode_id)
                                                spordleTable.setData(spordleTable.getData().map((r) => ({ ...r, checked: displayedData.includes(r.installment_mode_id) ? !allSelected : r.checked })), 'success')
                                            }}
                                        >
                                            <Translate id={allSelected ? 'catalog.installment.rollover.selectNone' : 'catalog.installment.rollover.selectAll'} />
                                        </button>
                                    </Fade>
                                </Col>
                            </Row>
                            <PerfectScrollbar options={{ suppressScrollX: true }} className="px-3 mx-n3 min-h-0">
                                <SpordleTableView />
                            </PerfectScrollbar>

                            {error &&
                                <span className="text-danger small">{error}</span>
                            }
                        </ModalBody>
                        <ModalFooter className="justify-content-between">
                            {goToPrevious &&
                                <Button color='primary' outline type='button' onClick={goToPrevious}><Translate id='misc.back' /></Button>
                            }
                            <Button
                                color='primary' type='button'
                                className="ml-auto"
                                onClick={() => {
                                    const selectedRows = spordleTable.getDisplayedData().filter((r) => r.checked)
                                    if(selectedRows.length > 0){
                                        onSubmit(selectedRows)
                                    }else{
                                        setError(<Translate id='catalog.installment.rollover.required' />)
                                    }
                                }}
                            >
                                <Translate id='misc.next' />
                            </Button>
                        </ModalFooter>
                    </OverlayLoader>
                )
            }}
        </SpordleTableProvider>
    )
}

const SearchSection = () => {
    const { activePeriods, selectedPeriod } = useContext(PeriodsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const spordleTable = useSpordleTable();

    return (
        <Formik
            initialValues={{
                selectedPeriodId: '',
            }}
            validationSchema={object().shape({
                selectedPeriodId: string().required(),
            })}
            onSubmit={(values) => {
                spordleTable.filterChange('periodId', values.selectedPeriodId)
            }}
        >
            {(formik) => (
                <Form>
                    <Row form>
                        <Col xs='6'>
                            <Label className='text-muted' for='selectedPeriodId'><Translate id='catalog.installment.rollover.prevSeasons' /> <Required /></Label>
                        </Col>
                    </Row>
                    <Row form>
                        <Col xs='6' className="mb-3 mb-sm-0">
                            <FormikSelect
                                id="selectedPeriodId"
                                name="selectedPeriodId"
                                placeholder="catalog.installment.rollover.prevSeasons"
                                renderOption={({ option }) => <DisplayI18n defaultValue={option.label} i18n={option.i18n} field="name" />}
                                searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                ]}
                                options={activePeriods.reduce((shownP, period) => {
                                    if(period.end_date < selectedPeriod.start_date){
                                        shownP.push({
                                            value: period.period_id,
                                            i18n: period.i18n,
                                            label: period.name,
                                        })
                                    }
                                    return shownP;
                                }, [])}
                            />
                        </Col>
                        <Col xs='6'>
                            <Button color='primary' type='submit' block><i className="ti-search mr-1" /><Translate id='misc.search' /></Button>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}

export default RolloverModalSelection