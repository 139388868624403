import { Button, Col, Fade, Row } from 'reactstrap';
import SidePanel from '../../../../components/sidePanel/SidePanel';
import DisplayMember from '../../../../components/display/DisplayMember';
import Translate from '@spordle/intl-elements';
import QuickViewButton from '../../../../components/quickView/QuickViewButton';
import { groupByAsArray } from '@spordle/helpers';
import DisplayRequestChangeStatus from '../../../../components/display/DisplayRequestChangeStatus';
import CanDoAction from '../../../../components/permissions/CanDoAction';
import MemberTypesList from './MemberTypesList';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import MemberTypeRemovalModal from '../MemberTypeRemovalModal/MemberTypeRemovalModal';
import DisplayMemberTypeIcon from '../../../../components/display/DisplayMemberTypeIcon';
import DisplayI18nName from '../../../../components/display/DisplayI18nName';
import DisplayOrg from '../../../../components/DisplayOrg';

const MemberTypeRemovalSidepanel = (props) => {

    const formattedMembers = groupByAsArray(props.selectedRows, 'member_id')
        .map(({ values, key }) => ({
            member_id: key, // avoid another map to expose member_id for QuickViewButton
            member: values[0].member,
            member_types: values,
        }))

    const disableManage = formattedMembers.some((group) => group.member_types.some((type) => type.request_change_status.system !== 'PENDING'));

    return (
        <Fade in>
            <MemberTypeRemovalModal
                toggle={props.modalToggle}
                isOpen={props.modalIsOpen}
                selectedRows={props.selectedRows}
                spordleTable={props.tableRef}
                forceCloseSidePanel={props.forceCloseSidePanel}
                requestChangesStatuses={props.requestChangesStatuses}
            />

            {props.selectedRows.length == 1 ?
                <>
                    <SidePanel.Header className='sticky-top bg-white z-index-1 mb-3'>
                        <div className='d-flex mb-2 align-items-center'>
                            <SidePanel.ToggleButton />
                            {!disableManage &&
                                <div className="ml-auto d-flex align-items-center">
                                    <SidePanel.ActionsMenu>
                                        <SidePanel.MenuAction
                                            onClick={() => {
                                                props.modalToggle();
                                            }}
                                            action='DELETE'
                                            componentCode='members'
                                            componentPermissionCode='member_member_types'
                                        >
                                            <Translate id='tasks.memberTypeRemoval.sidepanel.manage' />
                                        </SidePanel.MenuAction>
                                    </SidePanel.ActionsMenu>
                                </div>
                            }
                        </div>
                        <DisplayMember
                            member={formattedMembers[0].member}
                            imgProps={{ width: 60 }}
                            titleProps={{
                                className: 'h4 font-bold',
                            }}
                        />
                    </SidePanel.Header>
                    <div className='px-3'>
                        <Row form>
                            <Col sm='12' className='mb-3'>
                                <div className='text-muted'>
                                    <Translate id='tasks.memberTypeRemoval.columns.memberType' />
                                </div>
                                {props.selectedRows[0].request_change_status.system !== "PENDING" &&
                                    <>
                                        <div className="d-flex align-items-center font-medium mb-3 text-dark">
                                            <DisplayMemberTypeIcon memberType={props.selectedRows[0].member_type} />
                                            <div className="d-flex align-items-center flex-wrap">
                                                <DisplayI18nName obj={props.selectedRows[0].member_type} />
                                                {props.selectedRows[0].member_type.organisation &&
                                                    <div className="text-muted ml-1 small d-flex align-items-center">
                                                        (<DisplayOrg org={props.selectedRows[0].member_type.organisation} />)
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='text-muted'>
                                            <Translate id="tasks.memberTypeRemoval.columns.currentMemberType" />
                                        </div>
                                    </>
                                }
                                <div className='font-medium text-dark'>
                                    <MemberTypesList
                                        requestMemberTypes={formattedMembers[0].member_types}

                                        memberId={formattedMembers[0].member_id}
                                        skipStrikeThrough={disableManage}
                                    />
                                </div>
                            </Col>
                            <Col sm='6' className='mb-3'>
                                <div className='text-muted'>
                                    <Translate id='tasks.memberTypeRemoval.columns.status' />
                                </div>
                                <DisplayRequestChangeStatus status={formattedMembers[0].member_types[0].request_change_status} />
                            </Col>
                            <Col sm='6' className='mb-3'>
                                <div className='text-muted'>
                                    <Translate id='tasks.memberTypeRemoval.columns.createdBy' />
                                </div>
                                <div className='font-medium text-dark'>
                                    {formattedMembers[0].member_types[0].created_by ?
                                        <>{formattedMembers[0].member_types[0].created_by.name} {formattedMembers[0].member_types[0].created_by.family_name}</>
                                        :
                                        '-'
                                    }
                                </div>
                            </Col>
                            <Col sm='12' className='mb-3'>
                                <div className='text-muted'>
                                    <Translate id='form.fields.note' />
                                </div>
                                <div className='font-medium text-dark'>
                                    {formattedMembers[0].member_types[0].comment || '-'}
                                </div>
                            </Col>
                        </Row>
                        {!disableManage &&
                            <CanDoAction action='DELETE' componentCode='members' componentPermissionCode='member_member_types'>
                                <Button
                                    color='primary' outline
                                    type='button' block
                                    onClick={() => props.modalToggle()}
                                >
                                    <Translate id='tasks.memberTypeRemoval.sidepanel.manage' />
                                </Button>
                            </CanDoAction>
                        }
                    </div>
                </>
                :
                <>
                    <SidePanel.Header className='sticky-top bg-white z-index-1'>
                        <div className='d-flex mb-2 align-items-center'>
                            <SidePanel.ToggleButton />
                            {!disableManage &&
                                <div className="ml-auto d-flex align-items-center">
                                    <SidePanel.ActionsMenu>
                                        <SidePanel.MenuAction
                                            onClick={() => {
                                                props.modalToggle()
                                            }}
                                        >
                                            <Translate id='tasks.memberTypeRemoval.sidepanel.manage' />
                                        </SidePanel.MenuAction>
                                    </SidePanel.ActionsMenu>
                                </div>
                            }
                        </div>
                        <SidePanel.Title>
                            <QuickViewButton member={formattedMembers}>
                                <Translate id='tasks.memberTypeRemoval.sidepanel.count' values={{ count: formattedMembers.length }} />
                            </QuickViewButton>
                        </SidePanel.Title>
                    </SidePanel.Header>
                    <div className='px-3 pb-3'>
                        {formattedMembers.map((group) => (
                            <DisplayMember
                                key={group.member.member_id}
                                member={group.member}
                                card={false}
                                className="py-3 border-bottom"
                                extra={{
                                    infoBottom: (
                                        <UserDisplay.Subtitle className='text-body'>
                                            <MemberTypesList
                                                requestMemberTypes={group.member_types}
                                                memberId={group.member_id}
                                                skipStrikeThrough={disableManage}
                                            />
                                        </UserDisplay.Subtitle>
                                    ),
                                }}
                            />
                        ))}
                    </div>
                </>
            }
        </Fade>
    )
};

export default MemberTypeRemovalSidepanel;
