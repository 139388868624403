import Translate from '@spordle/intl-elements'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Card, ListGroup } from 'reactstrap'
import CardSectionTitle from '../../../../components/CardSectionTitle'
import EmptyLayout from '../../../../components/table/EmptyLayout'
import { DisplayI18n } from '../../../../helpers/i18nHelper'
import TeamSettingsQualificationsRow from './TeamSettingsQualificationsRow'

const TeamSettingsQualifications = ({ teamsSettings, error, isValidating, canSeeMember }) => {
    return (
        <Card className='card-shadow' body>
            <CardSectionTitle title='teams.profile.teamSetting.settings.qualifications.title' />
            {isValidating ?
                <Skeleton height={150} count={(teamsSettings?.qualification_rules || []).length || 1} />
                : error ?
                    <Translate id='misc.error' />
                    : (teamsSettings?.qualification_rules || []).length == 0 ?
                        <EmptyLayout hideArrow hideMsg title='teams.profile.teamSetting.settings.qualifications.none' translateTitle />
                        :
                        teamsSettings.qualification_rules.map((position) => (
                            <React.Fragment key={position.position_id}>
                                <div className='h5 font-bold mb-2'>
                                    <DisplayI18n field='name' defaultValue={position.name} i18n={position.i18n} />
                                </div>
                                <div className="p-3 border rounded mb-3">
                                    <ListGroup flush>
                                        {position.allMandatory?.length > 0 &&
                                    <div className='mb-3'>
                                        <div className='h6 font-bold mb-2'>
                                            <Translate id='teams.profile.teamSetting.settings.qualifications.all' />
                                        </div>
                                        {position.allMandatory?.map((mandatoryQual, mandatoryIndex) => (
                                            <TeamSettingsQualificationsRow
                                                index={mandatoryIndex} qualification={mandatoryQual}
                                                key={mandatoryQual.qualification_id} position={position}
                                                canSeeMember={canSeeMember}
                                            />
                                        ))}
                                    </div>
                                        }

                                        {position.oneMandatory?.map((group, groupIndex) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <React.Fragment key={groupIndex}>
                                                <div className='h6 font-bold mb-2'>
                                                    <Translate id='teams.profile.teamSetting.settings.qualifications.one' />
                                                </div>

                                                {group?.map((oneMandatoryQual, oneMandatoryIndex) => (
                                                    <TeamSettingsQualificationsRow
                                                        index={oneMandatoryIndex} qualification={oneMandatoryQual}
                                                        key={oneMandatoryQual.qualification_id} position={position}
                                                        canSeeMember={canSeeMember}
                                                    />
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </ListGroup>
                                </div>
                            </React.Fragment>
                        ))
            }
        </Card>
    )
}

export default TeamSettingsQualifications
