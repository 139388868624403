import PropTypes from 'prop-types';

import {
    Fade,
    Spinner
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";

/**
 * Loading overlay that will wrap children and add an overlay with a centered spinner
 * @param {object} props
 * @param {boolean} props.isLoading
 * @param {string} props.className
 * @param {string} [props.spinnerColor='primary']
 * @return {React.ReactNode}
 */
const OverlayLoader = ({ spinnerColor, isLoading, children, ...props }) => (
    <div {...props} className={stringBuilder('position-relative', props.className)}>
        {isLoading &&
            <div className='overlay-loader'>
                <Fade appear in>
                    <Spinner color={spinnerColor} type='grow' />
                </Fade>
            </div>
        }
        {children}
    </div>
)

OverlayLoader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    spinnerColor: PropTypes.string,
}

OverlayLoader.defaultProps = {
    spinnerColor: 'primary',
}

export default OverlayLoader;