import { useContext, useState } from "react";
import { Button, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal";
import FormikModalForm from "../../../../../../components/formik/FormikModalForm";
import Translate from "@spordle/intl-elements";
import { FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { shareInOrganization } from "../../../../../../api/client/members";
import FormikApiError from "../../../../../../components/formik/FormikApiError";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { PeriodsContext } from "../../../../../../contexts/contexts";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import { stringBuilder } from "@spordle/helpers";
import UserImg from "../../../../../../components/UserImg";

const MultiOrganizationModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName="multiOrganizationModal">
            <MultiOrganizationModalInner {...props} />
        </AnalyticsModal>
    );
}

const MultiOrganizationModalInner = ({ toggle, member, memberShareableTypes, onSuccess }) => {
    const organisationContext = useContext(OrganizationContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const { getGenericLocale } = useContext(I18nContext);

    const formatMemberTypes = () => {
        return memberShareableTypes.reduce((newTypes, type) => {
            if(!newTypes.some((t) => t.member_type.member_type_id === type.member_type.member_type_id)){
                newTypes.push({
                    label: type.member_type.name,
                    i18n: type.member_type.i18n,
                    value: type.member_type.member_type_id,
                    member_type: type.member_type,
                })
            }
            return newTypes;
        }, [])
    }
    const [ memberTypes, _ ] = useState(formatMemberTypes());

    return (
        <FormikModalForm
            initialValues={{
                organisation_id: organisationContext.organisation_id,
                period_id: selectedPeriod.period_id,
                member_type_id: memberTypes[0].member_type.member_type_id,
                notes: "",
            }}
            toggle={toggle}
            onSubmit={(values, { showSuccess, showError }) => {
                return shareInOrganization(member.member_id, values)
                    .then(() => {
                        showSuccess();
                        onSuccess?.();
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            showError(e);
                        }
                    });
            }}
        >
            {() => (
                <>
                    <ModalHeader toggle={toggle}><Translate id="members.profile.multipleorganization.title" values={{ sup: (t) => <sup>{t}</sup> }} /></ModalHeader>
                    <ModalBody>
                        <UserDisplay
                            card block
                            className={stringBuilder("w-100 min-w-0 mb-0 h-100 mb-3")}
                        >
                            <UserDisplay.Container className="mr-3">
                                <UserImg
                                    abbr={organisationContext.abbreviation}
                                    src={organisationContext.logo?.full_path}
                                    filePos={organisationContext.logo?.file_position}
                                    width="60"
                                    alt={organisationContext.organisation_name + ' logo'}
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container>
                                <UserDisplay.Title><DisplayI18n field='name' defaultValue={organisationContext.organisation_name} i18n={organisationContext.i18n} /></UserDisplay.Title>
                            </UserDisplay.Container>
                        </UserDisplay>

                        <FormGroup className={stringBuilder({ "d-none": memberTypes.length <= 1 })}>
                            <Label htmlFor="member_type_id"><Translate id="members.profile.memberProfile.header.memberType" /></Label>
                            <FormikSelect
                                name="member_type_id"
                                id="member_type_id"
                                disabled={memberTypes.length == 1}
                                renderOption={(option) => <DisplayI18n field="name" defaultValue={option.option.label} i18n={option.option.i18n} />}
                                searchKeys={[
                                    `i18n.${getGenericLocale()}.name`,
                                ]}
                                options={memberTypes}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="multiOrgNote"><Translate id="form.fields.note" /></Label>
                            <FormikTextArea
                                id="multiOrgNote"
                                name="notes"
                            />
                            <FormikApiError />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="primary"><Translate id="misc.submit" /></Button>
                        <Button onClick={toggle} type="button" color="primary" outline><Translate id="misc.cancel" /></Button>
                    </ModalFooter>
                </>
            )}
        </FormikModalForm>
    )
}

export default MultiOrganizationModal;