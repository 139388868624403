import { useContext, useState } from "react";
import {
    Button,
    Card
} from "reactstrap";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';

import Translate from "@spordle/intl-elements";
import SpordlePanelTable from "../../../../../../components/sidePanel/SpordlePanel";

// Contexts
import { PhoneTypesContext } from '../../../../../../contexts/PhoneTypesContext';
import I18nHelperContextProvider, { I18nHelperContext } from "../../../../../../helpers/i18nHelper";
import SidePanelOrganizationPhoneTypes from "./SidePanelOrganizationPhoneTypes";
import OrganizationPhoneTypesAdd from "./OrganizationPhoneTypesAdd";
import EmptyLayout from "../../../../../../components/table/EmptyLayout";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import { I18nContext } from "../../../../../../contexts/I18nContext";


const OrganizationPhoneTypes = () => {

    const phoneTypesContext = useContext(PhoneTypesContext);
    const i18nContext = useContext(I18nContext);

    const [ modalAdd, setModalAdd ] = useState(false);
    const toggleModalAdd = () => setModalAdd(!modalAdd);

    return (
        <I18nHelperContextProvider fields={[ 'name' ]}>
            <I18nHelperContext.Consumer>
                {(i18nHelper) => (
                    <div>
                        <SpordlePanelTable
                            allowOutsideClick
                            sidePanel={(props) => <SidePanelOrganizationPhoneTypes {...props} />}
                            dataIndex='phone_type_id'
                            table={(panelProps) => {
                                return (
                                    <Card body className="card-shadow">
                                        <CardSectionTitle title='settings.tabs.phoneTypes' />
                                        <SpordleTableProvider
                                            id='OrganizationPhoneTypesTable'
                                            tableHover clickable striped
                                            bordered
                                            ref={panelProps.spordleTableRef}
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                            dataIndex='phone_type_id'
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                                'name',
                                            ]}
                                            columns={[
                                                ...i18nHelper.getTableColumns('name'),
                                                {
                                                    label: <Translate id='organization.settings.organizationPhoneTypes.table.column.status' />,
                                                    key: "active",
                                                    mobile: true,
                                                    sortable: true,
                                                },
                                            ]}
                                            onColumnClick={(e, phoneType) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        panelProps.onSingleSelectChange(phoneType);
                                                        break;
                                                }
                                            }}
                                            desktopWhen='md'
                                            emptyLayout={<EmptyLayout translateTitle title='organization.settings.organizationPhoneTypes.table.empty' />}
                                            renderRow={(columnKey, phoneType, spordleTable) => {
                                                switch (columnKey){
                                                    case 'active':
                                                        return (
                                                            <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                {phoneType.active == '1' ?
                                                                    <i className="text-primary mdi mdi-check" />
                                                                    :
                                                                    <i className="text-danger mdi mdi-close" />
                                                                }
                                                            </div>
                                                        )
                                                    default:
                                                        break;
                                                }
                                            }}
                                            rowIsHighlighted={(phoneType) => !!phoneType.checked}
                                            loadData={(from, filterData, spordleTable) => {
                                                switch (from){
                                                    case 'REFRESH':
                                                        spordleTable.setLoading();
                                                    case 'CDM':
                                                        return phoneTypesContext.getPhoneTypes();
                                                    default:
                                                        break;
                                                }
                                            }}
                                        >
                                            <div className="mb-2">
                                                <div className='d-flex justify-content-between'>
                                                    <SpordleTableProvider.SearchInput />
                                                    <div className='d-flex ml-auto text-right'>
                                                        <SpordleTableProvider.Refresh />

                                                        <CanDoAction action='ADD' componentCode='settings' componentPermissionCode='phone_type'>
                                                            <OrganizationPhoneTypesAdd isOpen={modalAdd} toggle={toggleModalAdd} />
                                                            <Button className='ml-2' color='primary' onClick={toggleModalAdd}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                        </CanDoAction>
                                                    </div>
                                                </div>
                                            </div>
                                            <SpordleTableView />
                                        </SpordleTableProvider>
                                    </Card>
                                )
                            }}
                        />
                    </div>
                )}
            </I18nHelperContext.Consumer>
        </I18nHelperContextProvider>

    )

}

export default OrganizationPhoneTypes;