import React from 'react';
import {
    Button,
    Card,
    Col,
    Fade,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from 'reactstrap';
import moment from 'moment';
import { FormikInputText } from '@spordle/formik-elements';
import { Form, Formik } from 'formik';
import { string, object } from 'yup';

import Required from '../../../../components/formik/Required';

// contexts
import { RolesContext } from '../../../../contexts/RolesContext';
import { AuthContext } from '../../../../contexts/contexts';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import withContexts from '../../../../helpers/withContexts';
import { AxiosCancelAll, AxiosIsCancelled } from '../../../../api/CancellableAPI';

// Language
import Translate from '@spordle/intl-elements';
import UserImg from '../../../../components/UserImg';
import { fail, success } from "@spordle/toasts";
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import AddUserModalForm from './AddUserModalForm';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { I18nContext } from '../../../../contexts/I18nContext';

class AddUserModal extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            // state variable in charge of which component is displayed
            emailExists: null,

            // loading state
            loading: 'lazy',
        }

        this.organizationId = '';
        this.organization = null;
        this.role = null;
        this.roleId = '';
        this.email = '';
        this.startDate = '';
        this.endDate = '';

        // data sent to UserExists component
        this.user = null;
    }

    componentDidMount(){
        this.setState(() => ({ loading: 'success' }));
    }

    componentWillUnmount(){
        AxiosCancelAll();
    }

    verifyEmail = (email, organizationId, roleId, startDate, endDate) => {
        this.email = email;
        this.startDate = startDate ? moment(startDate).toISOString() : null;
        this.endDate = endDate ? moment(endDate).toISOString() : null;

        Promise.all([
            this.props.AuthContext.getUsersList('email', '=', email),
            this.props.OrganizationContext.getOrganization(organizationId),
            this.props.RolesContext.getRole(roleId),
        ])
            .then(([ dataUser, dataOrganization, dataRole ]) => {
                this.organization = dataOrganization;
                this.user = dataUser.Users?.[0] ? dataUser.Users[0] : null;
                this.role = dataRole;
                this.setState(() => ({ emailExists: dataUser.Users.length !== 0, loading: 'success' }))
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    this.setState(() => ({ loading: 'error' }))
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });
    }

    reset = () => {
        this.setState(() => ({ emailExists: null }));
        this.email = '';
        this.organization = null;
        this.role = null;
        this.user = null;
    }

    back = () => {
        this.setState(() => ({ emailExists: null }));
    }

    setIsLoading = (loadingState) => {
        this.setState(() => ({ loading: loadingState }));
    }

    render(){
        return (
            <AnalyticsModal isOpen={this.props.isOpen} toggle={this.props.toggle} onClosed={this.reset} backdrop="static" analyticsName='AddUserModal'>
                {/* this.state.emailExists determines which component is displayed, null for the initial form (organization, role, email) and a bool for the two other components */}
                {this.state.emailExists === null ?
                    <Fade in={this.state.emailExists === null}>
                        {/* LOADING */}
                        {this.state.loading === 'lazy' ?
                            <Fade in={this.state.loading === 'lazy'}>
                                <div className='d-flex justify-content-center flex-wrap p-5'>
                                    <div className='w-100 text-center'><Spinner type='grow' color='primary' /></div>
                                    <div className='font-medium font-20'><Translate id='misc.loading' /></div>
                                </div>
                            </Fade>
                            :
                            <Fade in={this.state.loading !== 'lazy'}>
                                {/* INITIAL FORM */}
                                <AddUserModalForm
                                    organizationId={this.organizationId}
                                    organization={this.organization}
                                    role={this.role}
                                    roleId={this.roleId}
                                    email={this.email}
                                    startDate={this.startDate}
                                    endDate={this.end}
                                    toggle={this.props.toggle}
                                    verifyEmail={this.verifyEmail}
                                    setIsLoading={this.setIsLoading}
                                    isLoading={this.state.loading}
                                />
                            </Fade>
                        }
                    </Fade>
                    : null}

                {/* IF EMAIL ALREADY EXISTS */}
                {this.state.emailExists !== null && this.state.emailExists === true ?
                    <Fade in={this.state.emailExists !== null && this.state.emailExists === true}>
                        <UserExists
                            IdentityRolesContext={this.props.IdentityRolesContext}
                            OrganizationContext={this.props.OrganizationContext}
                            RolesContext={this.props.RolesContext}
                            tableRef={this.props.tableRef}
                            user={this.user}
                            role={this.role}
                            organization={this.organization}
                            startDate={this.startDate}
                            endDate={this.endDate}
                            back={this.back}
                            cancel={this.props.toggle}
                        />
                    </Fade>
                    : null}

                {/* IF EMAIL DOESN'T EXIST */}
                {this.state.emailExists !== null && this.state.emailExists === false ?
                    <Fade in={this.state.emailExists !== null && this.state.emailExists === false}>
                        <UserDoesNotExist
                            AuthContext={this.props.AuthContext}
                            OrganizationContext={this.props.OrganizationContext}
                            IdentityRolesContext={this.props.IdentityRolesContext}
                            tableRef={this.props.tableRef}
                            email={this.email}
                            role={this.role}
                            organization={this.organization}
                            startDate={this.startDate}
                            endDate={this.endDate}
                            back={this.back}
                            cancel={this.props.toggle}
                        />
                    </Fade>
                    : null}
            </AnalyticsModal>
        )
    }
}

class UserExists extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading: 'success',
            error: null,
        }

        this.errorCode = '';
    }

    linkUser = () => {
        this.setState(() => ({ loading: 'lazy' }))
        this.props.IdentityRolesContext.linkIdentityToRole(this.props.user?.Username, this.props.organization.organisation_id, this.props.role.role_id, this.props.startDate, this.props.endDate)
            .then((identityRoleId) => {
                // added a user to the current organization
                if(this.props.organization.organisation_id === this.props.OrganizationContext.organisation_id){
                    // so we don't have to refresh the table and make another API call
                    this.props.tableRef.current.addRow({
                        // user info
                        name: this.props.user.name,
                        family_name: this.props.user.family_name,
                        email: this.props.user.email,

                        // identity-role info
                        role_info: this.props.role,
                        role: this.props.role.title,
                        active: '1',
                        start_date: this.props.startDate,
                        end_date: this.props.endDate,
                        signin_lock_date: null,

                        // IDs
                        identity_id: this.props.user.Username,
                        role_id: this.props.role.role_id,
                        identity_role_id: identityRoleId,
                        organisation_id: this.props.organization.organisation_id,
                    })
                }

                success({ msg: 'organization.profile.users.addUser.linked' })
                // toggle modal
                this.props.cancel();
            }, (error) => { // this second parameter in the "then" represents a "catch" that can only catch errors from the function before
                if(!AxiosIsCancelled(error.message)){
                    this.errorCode = `contexts.identityRolesContext.errors.${error.message}`;
                    this.setState(() => ({
                        loading: 'error',
                        error: error || null,
                    }))
                }
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({ loading: 'error' }))
                }
            })
    }

    render(){
        return (
            <>
                {this.state.loading === 'lazy' ?
                    <div className='d-flex justify-content-center flex-wrap p-5'>
                        <div className='w-100 text-center'><Spinner type='grow' color='primary' /></div>
                        <div className='font-medium font-20'><Translate id='misc.loading' /></div>
                    </div>
                    : null}
                {this.state.loading !== 'lazy' ?
                    <>
                        <ModalHeader toggle={this.props.cancel}>
                            <Translate id='organization.profile.users.addUser.title' />
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-center font-medium mb-3'>
                                <Translate id='organization.profile.users.addUser.exists.topText' /><br /><Translate id='organization.profile.users.addUser.exists.topText2' />
                            </div>
                            <Card className='mb-0'>
                                <Row className='mb-3'>
                                    <Col md='6' className='d-flex align-items-center justify-content-center flex-column border-right'>
                                        <UserImg
                                            abbr={this.props.user?.name.charAt(0) + this.props.user?.family_name.charAt(0)}
                                            src={this.props.user?.picture?.full_path}
                                            alt={this.props.user?.name + ' ' + this.props.user?.family_name}
                                            filePos={this.props.user?.picture?.file_position}
                                            width={40}
                                        />
                                        <div className='font-medium'>{this.props.user?.name} {this.props.user?.family_name}</div>
                                        <div className='small'>{this.props.user?.email}</div>
                                    </Col>
                                    <Col md='6' className='d-flex align-items-center justify-content-center flex-column'>
                                        <div className='mr-2'>
                                            <UserImg
                                                abbr={this.props.organization.abbreviation}
                                                src={this.props.organization.logo?.full_path}
                                                alt={this.props.organization.organisation_name}
                                                width={40}
                                            />
                                        </div>
                                        <div className='font-medium'>{this.props.organization.organisation_name}</div>
                                        <div className='font-medium'>{this.props.role.title}</div>
                                    </Col>
                                </Row>
                                {this.state.loading === 'error' ?
                                    <div className='alert alert-danger'>
                                        {this.state.error ?
                                            <DisplayI18n field='message' defaultValue={this.state.error.message} i18n={this.state.error.i18n} />
                                            :
                                            <Translate id={this.errorCode} defaultMessageId='misc.error' />
                                        }
                                    </div>
                                    :
                                    null
                                }
                            </Card>
                        </ModalBody>
                        <ModalFooter className='d-flex justify-content-between'>
                            <Button outline type='button' color='primary' onClick={this.props.back}><Translate id='misc.back' /></Button>
                            <div>
                                <Button color='primary' onClick={this.linkUser} disabled={this.state.loading !== 'success'}><Translate id='misc.confirm' /></Button>
                                <Button className='ml-2' color='primary' type='button' outline onClick={this.props.cancel}><Translate id='misc.cancel' /></Button>
                            </div>
                        </ModalFooter>
                    </>
                    : null}
            </>
        )
    }
}

class UserDoesNotExist extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            loading: 'lazy',
        }
    }

    componentDidMount(){
        //...
        this.setState(() => ({ loading: 'sucess' }))
    }

    createAndLinkUser = (firstName, lastName, email, phone) => {
        this.props.AuthContext.createUser({ name: firstName, familyName: lastName, email: email, phoneNumber: phone })
            .then(async(user) => {
                const identityRoleId = await this.props.IdentityRolesContext.linkIdentityToRole(user.Username, this.props.organization.organisation_id, this.props.role.role_id, this.props.startDate, this.props.endDate)
                // added a user to the current organization
                if(this.props.organization.organisation_id === this.props.OrganizationContext.organisation_id){
                    // so we don't have to refresh the table and make another API call
                    this.props.tableRef.current.addRow({
                        // user info
                        name: user.name,
                        family_name: user.family_name,
                        email: user.email,

                        // identity-role info
                        role_info: this.props.role,
                        role: this.props.role.title,
                        active: '1',
                        start_date: this.props.startDate,
                        end_date: this.props.endDate,
                        signin_lock_date: null,

                        // IDs
                        identity_id: user.Username,
                        role_id: this.props.role.role_id,
                        identity_role_id: identityRoleId,
                        organisation_id: this.props.organization.organisation_id,
                    })
                }

                success({ msg: 'organization.profile.users.addUser.createdAndLinked' })

                // toggle modal
                this.props.cancel();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({ loading: 'error' }));
                }
            })
    }

    render(){
        return (
            <>
                {/* LOADING */}
                {this.state.loading === 'lazy' ?
                    <div className='d-flex justify-content-center flex-wrap p-5'>
                        <div className='w-100 text-center'><Spinner type='grow' color='primary' /></div>
                        <div className='font-medium font-20'><Translate id='misc.loading' /></div>
                    </div>
                    :
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: this.props.email,
                        }}
                        validationSchema={object().shape({
                            firstName: string().required(<Translate id='form.validation.firstName.required' />),
                            lastName: string().required(<Translate id='form.validation.lastName.required' />),
                            email: string().required(<Translate id='form.validation.email.required' />).email(<Translate id='form.validation.email.valid' />),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            this.setState(() => ({ loading: 'lazy' }))
                            setSubmitting(false)
                            this.createAndLinkUser(values.firstName, values.lastName, values.email, values.phone)
                        }}
                    >
                        {(formik) => {
                            return (
                                <Form>
                                    <ModalHeader toggle={this.props.cancel}>
                                        <Translate id='organization.profile.users.addUser.create.title' />
                                    </ModalHeader>
                                    <ModalBody>
                                        <div className='mb-3'>
                                            <Translate id='organization.profile.users.addUser.doesntExist.topText' /><br />
                                            <Translate id='organization.profile.users.addUser.doesntExist.topText2' />
                                        </div>
                                        <FormGroup>
                                            <Label for='email' className='text-muted'><Translate id='form.fields.email' /> <Required /></Label>
                                            <FormikInputText id='email' name='email' label='' translateLabel={false} disabled trim />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for='firstName' className='text-muted'><Translate id='form.fields.firstName' /> <Required /></Label>
                                            <FormikInputText id='firstName' name='firstName' label='' translateLabel={false} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for='lastName' className='text-muted'><Translate id='form.fields.lastName' /> <Required /></Label>
                                            <FormikInputText id='lastName' name='lastName' label='' translateLabel={false} />
                                        </FormGroup>
                                        {/* <FormGroup>
                                            <Label for='phone' className='text-muted'><Translate id='form.fields.phone'/></Label>
                                            <Field name="phone">
                                                {({ field, meta }) => (
                                                    <>
                                                        <I18nContext.Consumer>
                                                            {({getGenericLocale}) => (
                                                                <PhoneInput {...field} key={getGenericLocale()} localization={getGenericLocale() === 'fr' ? fr : undefined}
                                                                    inputProps={{name: field.name}}
                                                                    onChange={(value) => {
                                                                        formik.setFieldValue('phone', value ? '+'+value : '');
                                                                    }}
                                                                    country='ca'
                                                                    preferredCountries={['ca', 'us']}
                                                                    enableSearch
                                                                    disableSearchIcon
                                                                    // regions={['north-america']}
                                                                />
                                                            )}
                                                        </I18nContext.Consumer>
                                                        {meta.touched && meta.error &&
                                                            <div className="error small mt-1">{meta.error}</div>
                                                        }
                                                    </>
                                                )}
                                            </Field>
                                        </FormGroup> */}
                                        {this.state.loading === 'error' &&
                                            <div className='alert alert-danger'><Translate id='misc.error' /></div>
                                        }
                                    </ModalBody>
                                    <ModalFooter className='d-flex justify-content-between'>
                                        <Button outline type='button' color='primary' onClick={this.props.back} disabled={formik.isSubmitting}><Translate id='misc.back' /></Button>
                                        <div>
                                            <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.confirm' /></Button>
                                            <Button className='ml-2' color='primary' type='button' outline onClick={this.props.cancel} disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                                        </div>
                                    </ModalFooter>
                                </Form>
                            )
                        }}
                    </Formik>
                }
            </>
        )
    }
}

export default withContexts(RolesContext, AuthContext, IdentityRolesContext, OrganizationContext, I18nContext)(AddUserModal)