// eslint-disable-next-line unused-imports/no-unused-imports
import { TourProvider } from "@reactour/tour";
import { createContext, useContext, useState } from "react";
import { AppContext } from "../../contexts/contexts";
import { lockBodyScroll, unlockBodyScroll } from "../../helpers/uiHelper";
import ArrowBtn from "./buttons/ArrowBtn";
import TourUpdater from "./TourUpdater";

/** @type {React.Context<Omit<TourManagerProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const TourManagerContext = createContext();
TourManagerContext.displayName = 'TourManagerContext';

/**
 * @typedef {Object & import("@reactour/tour").ProviderProps} TourInfo
 * @prop {string} name
 * @prop {import("@reactour/tour").StepType[]} steps
 */

const TourManagerProvider = (props) => {
    const { isMobileDevice, updateParamsTours } = useContext(AppContext);
    const [ queue, setQueue ] = useState([]);
    const [ quickTourQueue, setQuickTourQueue ] = useState([]);

    /**
     * Adds tour to queue. Will open if queue is empty. Duplicates will be merged.
     * @param {string} tourName
     */
    const triggerQuickTour = (tourName) => {
        if(!isMobileDevice){
            if(quickTourQueue.some((tour) => tour === tourName))
                return -1; // Quicktour is already showing in the screen

            setQuickTourQueue((tourList) => {
                return tourList.some((tour) => tour === tourName) ? tourList : [ ...tourList, tourName ];
            });

            return quickTourQueue.findIndex((tour) => tour === tourName) + 1
        }
    }

    /**
     * Removes tour from queue
     * @param {string} tourName
     */
    const endQuickTour = (tourName) => {
        setQuickTourQueue((tourList) => tourList.filter((tour) => tour !== tourName));
    }

    /**
     * @param {string} name // The tour name.
     */
    const isInQuickTourQueue = (name) => {
        return quickTourQueue.some((tour) => tour.name === name);
    }


    /**
     * @param {string} name // The tour name.
     */
    const isInQueue = (name) => {
        return queue.some((tour) => tour.name === name);
    }

    /**
     * Adds tour to queue. Will open if queue is empty. Duplicates will be merged.
     * @param {TourInfo} tourInfo
     */
    const triggerTour = (tourInfo) => {
        if(!isMobileDevice){
            setQueue((tourList) => {
                return tourList.some((tour) => tour.name === tourInfo.name) ? tourList : [ ...tourList, tourInfo ];
            });
        }
    }

    /**
     * Removes tour from queue
     * @param {TourInfo} tourInfo
     */
    const endTour = (tourInfo) => {
        setQueue((tourList) => tourList.filter((tour) => tour.name !== tourInfo.name));
    }

    /**
     * Updates a queued tour.
     * @param {TourInfo} tourInfo
     */
    const updateTour = (tourInfo) => {
        setQueue((tourList) => {
            return tourList.reduce((tours, tour) => {

                if(tour.name === tourInfo.name){
                    tours.push({ ...tour, ...tourInfo });
                }else{
                    tours.push(tour);
                }

                return tours;
            }, []);
        })
    }

    /**
     * @async
     * Called when tour is closed. Will handle front end params, unlocking body scroll and ending the tour.
     */
    const handleOnClose = async() => {
        const activeTour = queue[0];
        await Promise.resolve(unlockBodyScroll());
        if(activeTour && activeTour.name){
            await updateParamsTours({ [activeTour.name]: 0 }).catch(console.error);
            activeTour.beforeClose?.();
            endTour(activeTour);
        }


    }

    /**
     * Function called when tour is opened. Will lock body scroll.
     */
    const handleOnOpen = () => {
        const activeTour = queue[0];

        if(activeTour && activeTour.name){
            lockBodyScroll();
            activeTour.afterOpen?.();
        }

        window.dispatchEvent(new Event('resize'));
    }

    return (
        <TourManagerContext.Provider
            value={{
                queue: queue,
                updateTour: updateTour,
                isInQueue: isInQueue,
                triggerTour: triggerTour,
                endTour: endTour,
                handleOnClose: handleOnClose,
                quickTourQueue: quickTourQueue,
                triggerQuickTour: triggerQuickTour,
                endQuickTour: endQuickTour,
                isInQuickTourQueue: isInQuickTourQueue,
            }}
        >
            <TourProvider
                disableInteraction
                scrollSmooth
                showBadge={false}
                afterOpen={handleOnOpen}
                className="p-4 rounded-xl"
                maskClassName='transition'
                showNavigation={queue[0]?.steps?.length > 1}
                styles={{
                    maskRect: (styles) => ({ ...styles, rx: "10px" }),
                    maskArea: (styles) => ({ ...styles, rx: "10px" }),
                    dot: ({ background, ...styles }, { current }) => {
                        const newStyles = {
                            ...styles,
                            ["&:focus"]: { outline: "none" },
                        }

                        if(!current){
                            newStyles.background = "#f4f6f9";
                            newStyles.border = "1px solid #f4f6f9";
                        }else{
                            newStyles.background = styles.color;
                        }

                        return newStyles;
                    },
                }}
                components={{
                    Close: (props) => (
                        <button
                            aria-label={props["aria-label"]}
                            onClick={props.onClick}
                            disabled={props.disabled}
                            type="button"
                            className="reset-btn mdi mdi-close mt-n2 mr-n2 p-3 position-absolute top-0 right-0"
                        />
                    ),
                }}
                nextButton={(props) => {
                    const CustomBtn = queue[0]?.nextButton;
                    const defaultBtn = <ArrowBtn direction="right" {...props} />;

                    return (
                        CustomBtn ?
                            <CustomBtn btn={defaultBtn} {...props} />
                            :
                            props.currentStep !== props.stepsLength - 1 && defaultBtn
                    )
                }}
                prevButton={(props) => {
                    const CustomBtn = queue[0]?.prevButton;
                    const defaultBtn = <ArrowBtn direction="left" {...props} />;

                    return (
                        CustomBtn ?
                            <CustomBtn btn={defaultBtn} {...props} />
                            :
                            props.currentStep !== 0 && defaultBtn
                    )
                }}
                disableFocusLock
                steps={[]}
                beforeClose={handleOnClose}
                onClickMask={() => {}}
            >
                <TourUpdater queue={queue} />
                {props.children}
            </TourProvider>
        </TourManagerContext.Provider>
    )
}
export default TourManagerProvider;