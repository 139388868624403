// Language
import Translate from "@spordle/intl-elements";
import images from '@spordle/ui-kit';
import React from "react";
import {
    Col, Row
} from "reactstrap";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";

const noProfileLogo = images.noprofile;

class SidePanelMemberLogs extends React.Component{

    render(){
        const by = this.props.getCommonValue('by')
        return (
            <>
                <div className="p-3 border-bottom">
                    <div className='d-flex mb-3'>
                        <button type='button' className='reset-btn p-2 text-dark' onClick={this.props.toggle}><i className='mdi mdi-arrow-right font-20' /></button>
                    </div>
                    <div className='font-bold h4 mb-3'><Translate id='members.profile.overview.sidePanelMembersLogs.title' /></div>
                    <Row>
                        <Col xs="6">
                            <div className="text-muted">Modified by</div>
                            <UserDisplay>
                                <UserDisplay.Container>
                                    <div className="border rounded-circle shadow-sm p-2">
                                        <img className="object-fit-contain" src={by.image ?? noProfileLogo} width="20" height="20" alt={`${by.firstName} ${by.lastName}`} />
                                    </div>
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Title>{by.firstName} {by.lastName}</UserDisplay.Title>
                                    <UserDisplay.Subtitle>#{by.memberNumber}</UserDisplay.Subtitle>
                                </UserDisplay.Container>
                            </UserDisplay>
                            <div className="d-flex align-items-center">
                                <div />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="text-muted">On</div>
                            <div className="font-medium text-dark">{this.props.getCommonValue('date')}, {this.props.getCommonValue('time')}</div>
                        </Col>
                    </Row>
                </div>
                <div className="p-3">
                    <div className="h4 font-bold mb-3">{this.props.getCommonValue('section')}</div>
                    <div className="mb-3">
                        <div className="text-muted">City</div>
                        <div className="font-medium text-dark">
                            <span>Blainville</span>
                            <i className="mdi mdi-arrow-right mx-2" />
                            <span className="font-bold">Montréal</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="text-muted">Address</div>
                        <div className="font-medium text-dark">
                            <span>12 street name</span>
                            <i className="mdi mdi-arrow-right mx-2" />
                            <span className="font-bold">123 the longest street name in the Entire Canada</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="text-muted">Nationality</div>
                        <div className="font-medium text-dark">
                            <span>(empty)</span>
                            <i className="mdi mdi-arrow-right mx-2" />
                            <span className="font-bold">Canadian</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="text-muted">Ethnicity</div>
                        <div className="font-medium text-dark">
                            <span>Canadian</span>
                            <i className="mdi mdi-arrow-right mx-2" />
                            <span className="font-bold">(empty)</span>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default SidePanelMemberLogs;