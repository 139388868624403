import TeamsSettingsAdd from "../../../views/settings/teamsRestrictions/TeamsSettingsAdd";
import TeamsSettingsEdit from "../../../views/settings/teamsRestrictions/TeamsSettingsEdit";
import TeamsSettingsList from "../../../views/settings/teamsRestrictions/TeamsSettingsList";

export default[
    {
        path: '/settings',
        exact: true,
        name: 'settings.tabs.organization.settings',
        component: TeamsSettingsList,
        permissions: { code: 'teams', permissionCode: 'team_settings' },
    },
    {
        path: '/create',
        name: 'sidebar.settings.teamsRestrictions.create',
        permissions: { code: 'teams', permissionCode: 'team_settings', actions: [ 'ADD' ] },
        component: TeamsSettingsAdd,
        tempTab: true,
        noLink: true,
    },
    {
        path: '/edit',
        name: 'sidebar.settings.teamsRestrictions.edit',
        permissions: { code: 'teams', permissionCode: 'team_settings', actions: [ 'EDIT' ] },
        component: TeamsSettingsEdit,
        tempTab: true,
        noLink: true,
    },
    {
        redirect: true,
        dynamicRedirect: true,
        from: '/settings/teams',
        to: '/general',
    },
];