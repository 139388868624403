import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext } from 'react';
import {
    Alert,
    Button,
    Collapse,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter, ModalHeader
} from "reactstrap";
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { FormikTextArea } from '@spordle/formik-elements';
import Required from '../../../../../components/formik/Required';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { TeamsContext } from '../../../../../contexts/TeamsContext';
import { displayI18n } from '../../../../../helpers/i18nHelper';
import { I18nContext } from '../../../../../contexts/I18nContext';
import InlineCopy from '../../../../../components/inlineCopy/InlineCopy';
import GenderLabel from '../../../../../components/GenderLabel';

const TeamCommentsAddModal = ({ isOpen, toggle, teamMember, mutate }) => {
    const teamsContext = useContext(TeamsContext);
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <AnalyticsModal analyticsName='TeamCommentsAddModal' isOpen={isOpen}>
            <Formik
                initialValues={{
                    comment: '',
                }}
                validationSchema={object().shape({
                    comment: string().trim().required(<Translate id='teams.profile.roster.sidePanel.teamMemberComments.required' />),
                })}
                onSubmit={({ comment }, { setSubmitting, setStatus }) => {
                    setStatus();
                    teamsContext.createTeamMemberComment(teamsContext.cachedTeam.team_id, teamMember.team_member_id, { comment: comment })
                        .then(() => {
                            setSubmitting(false);
                        })
                        .then(() => {
                            mutate()
                        })
                        .then(toggle)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setSubmitting(false);
                                setStatus(displayI18n('message', error.i18n, error.message, getGenericLocale()));
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader toggle={toggle}>
                                <Translate id='transfer.sidepanel.actions.addComment' />
                            </ModalHeader>
                            <ModalBody className="text-center">
                                <UserDisplay card>
                                    <UserDisplay.Container>
                                        <UserImg
                                            abbr={teamMember.member.first_name[0] + teamMember.member.last_name[0]}
                                            src={null}
                                            width={50}
                                            height={50}
                                            alt={`${teamMember.member.first_name} ${teamMember.member.last_name}`}
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title>{teamMember.member.first_name} {teamMember.member.last_name}</UserDisplay.Title>
                                        <InlineCopy tag='div' toCopy={teamMember.member.unique_identifier} className="font-medium">{'#' + teamMember.member.unique_identifier || '-'}</InlineCopy>
                                        <UserDisplay.Subtitle>
                                            <GenderLabel gender={teamMember.member.gender} />
                                            <span className='mx-1'>-</span>
                                            <DateFormat value={teamMember.member.birthdate} utc />
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>

                                <FormGroup className="text-left">
                                    <Label for='comment' className='text-muted'><Translate id='transfer.sidepanel.modal.addComment.comment' /> <Required /></Label>
                                    <FormikTextArea id='comment' name='comment' rows='5' trim />
                                </FormGroup>

                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    <Alert color='danger' className='text-left'>{formik.status}</Alert>
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={formik.isSubmitting}>
                                    <Translate id='transfer.submit' />
                                </Button>
                                <Button color='primary' type='button' onClick={toggle} outline disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

export default TeamCommentsAddModal;