import { stringBuilder } from "@spordle/helpers";
import { useState, useContext, useEffect, useMemo } from "react";
import { Button, CustomInput, ModalBody, ModalFooter } from "reactstrap";
import CrossFade from "../../../../../../components/crossFade/CrossFade";
import { TeamsContext } from "../../../../../../contexts/TeamsContext";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import DisplayMember from "../../../../../../components/display/DisplayMember";
import { DisplayI18n, displayI18n } from "../../../../../../helpers/i18nHelper";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import StatusBadge from "../../../../../../components/badges/StatusBadge";
import { Skeleton } from "@mantine/core";
import Translate from "@spordle/intl-elements";
import CollapsibleCard from "../../../../../../components/collapsibleCard/CollapsibleCard";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import SearchMemberForm from "../../../../../../components/searchMemberForm/SearchMemberForm";
import DisplayMemberLoading from "../../../../../../components/loading/DisplayMemberLoading";

const TravelPermitAddLineupSearch = ({ previous, addMembers, addedMembers, memberIsSelected, teamId }) => {
    const [ tab, setTab ] = useState(0);

    return (
        <>
            <ModalBody className="d-flex align-items-center pb-0">
                <Button
                    size="lg"
                    onClick={() => setTab(1)}
                    color="white"
                    className={stringBuilder("mr-1 flex-grow-1 select-request", tab === 1 ? `border-primary bg-primary-light text-primary` : 'border')}
                >
                    <Translate id="travelPermit.lineup.searchMembers" />
                </Button>
                <Button
                    size="lg"
                    onClick={() => setTab(0)}
                    color="white"
                    className={stringBuilder("ml-1 flex-grow-1 select-request", tab === 0 ? `border-primary bg-primary-light text-primary` : 'border')}
                >
                    <Translate id="travelPermit.lineup.teamMembers" />
                </Button>
            </ModalBody>
            <CrossFade isVisible={tab === 1}>
                <SearchMembersList
                    addMembers={addMembers}
                    memberIsSelected={memberIsSelected}
                    previous={previous}
                />
            </CrossFade>
            <CrossFade isVisible={tab === 0}>
                <TeamMembersList
                    addMembers={addMembers}
                    memberIsSelected={memberIsSelected}
                    previous={previous}
                    teamId={teamId}
                    addedMembers={addedMembers}
                />
            </CrossFade>
        </>
    );
}

const SearchMembersList = ({ previous, addMembers, memberIsSelected }) => {
    const handleOnAdd = (member) => {
        addMembers([ { ...member, position_id: "" } ]);
        previous();
    }

    return (
        <SearchMemberForm
            onPrevious={previous}
            memberComponent={({ member }) => {
                const isAdded = memberIsSelected(member);
                return (
                    <DisplayMember
                        member={member}
                        card
                        className="mb-2"
                        extra={{
                            infoBottom: (
                                <UserDisplay.Subtitle>
                                    <StatusBadge status={member.member_status} color={member.member_status?.color_code} />
                                </UserDisplay.Subtitle>
                            ),
                            left: (
                                <Button
                                    onClick={() => handleOnAdd(member)}
                                    type="button"
                                    disabled={!!isAdded}
                                    className={stringBuilder(`ml-auto`, { "with-icon mdi mdi-plus": !isAdded })}
                                    color="primary"
                                >
                                    <Translate id={isAdded ? "gameIncident.search.alreadyAdded" : `misc.add`} />
                                </Button>
                            ),
                        }}
                    />
                )
            }}
        />
    )
}

const TeamMembersList = ({
    previous,
    addMembers,
    memberIsSelected,
    addedMembers = [],
    teamId,
}) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { getTeamMembers } = useContext(TeamsContext);
    const [ teamMembers, setTeamMembers ] = useState(null);
    const [ selectedMembers, setSelectedMembers ] = useState({});
    const selectedArray = Object.values(selectedMembers);

    const [ sortedTeamMembers, allTeamMembers ] = useMemo(() => {
        const collator = new Intl.Collator(getGenericLocale());
        const [ splitByPosGroup, allMembers ] = (teamMembers || []).reduce((formatted, teamMember) => {
            const posGroup = teamMember.position?.position_group;
            const isSelected = memberIsSelected(teamMember.member);

            if(!isSelected){
                formatted[1].push(teamMember);

                if(formatted[0][posGroup?.position_group_id]){
                    const exists = formatted[0][posGroup?.position_group_id].teamMembers.some(({ member }) => member.member_id === teamMember.member.member_id);

                    if(!exists){
                        formatted[0][posGroup?.position_group_id].teamMembers.push(teamMember);
                    }

                }else{
                    formatted[0][posGroup?.position_group_id] = {
                        positionGroup: posGroup,
                        teamMembers: [ teamMember ],
                    }
                }
            }

            return formatted;
        }, [ {}, [] ]);

        const sorted = Object.values(splitByPosGroup)

        sorted.forEach((membersByGroup) => {
            membersByGroup.teamMembers.sort((a, b) => {
                const posA = displayI18n("name", a.position?.i18n, a.position?.name, getGenericLocale()) || "";
                const posB = displayI18n("name", b.position?.i18n, b.position?.name, getGenericLocale()) || "";
                const scorePos = collator.compare(posA, posB);

                if(scorePos !== 0){
                    return scorePos;
                }

                return collator.compare(a.member.first_name + a.member.last_name, b.member.first_name + b.member.last_name);
            });
        });

        return [ sorted, allMembers ];
    }, [ teamId, (teamMembers || []).length, getGenericLocale(), addedMembers.length ])

    useEffect(() => {
        if(!teamId){
            setTeamMembers(null);
            setSelectedMembers({});
        }else{
            getTeamMembers(teamId)
                .then(setTeamMembers)
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e);
                        setTeamMembers(null);
                    }
                })
        }
    }, [ teamId ]);

    const toggleSelectedMember = ({ member, position }) => {
        setSelectedMembers((prev) => {
            const newPrev = { ...prev };

            if(prev[member.member_id]){
                delete newPrev[member.member_id];
            }else{
                newPrev[member.member_id] = { ...member, position_id: position?.position_id || "" };
            }

            return newPrev;
        });
    }

    const selectAllMembers = () => {
        setSelectedMembers(sortedTeamMembers.reduce((selected, { teamMembers }) => {
            teamMembers.forEach((teamMember) => {
                selected[teamMember.member.member_id] = {
                    ...teamMember.member,
                    position_id: teamMember.position?.position_id,
                }
            })
            return selected;
        }, {}))
    }

    const emptySelectedMembers = () => {
        setSelectedMembers({});
    }

    return (
        <>
            <ModalBody>
                {Array.isArray(teamMembers) ?
                    ((sortedTeamMembers || []).length === 0) ?
                        <div className="bg-light-inverse rounded border p-3 text-center">
                            <i className="d-block mdi mdi-account-multiple h1 mb-0 text-body" />
                            <Translate id="travelPermit.lineup.emptyTeam" />
                        </div>
                        :
                        <>
                            <div className="h5 font-bold d-flex align-items-center justify-content-between mb-2">
                                <span>
                                    <Translate id="travelPermit.lineup.availableTeamMembers" /> <span className="font-normal">(<Translate id="misc.selected.plural" values={{ count: selectedArray.length, max: allTeamMembers.length }} />)</span>
                                </span>
                                <Button type="button" color="link" onClick={selectedArray.length >= allTeamMembers.length ? emptySelectedMembers : selectAllMembers}>
                                    <Translate id={`travelPermit.lineup.${selectedArray.length >= allTeamMembers.length ? "removeAll" : "addAll"}`} />
                                </Button>
                            </div>
                            {sortedTeamMembers.map((posGroup, i) => (
                                <div className={stringBuilder({ "pt-3": i > 0 })} key={posGroup.positionGroup?.position_group_id}>
                                    <CollapsibleCard
                                        title={
                                            <div className="h5 font-medium mb-0">
                                                <DisplayI18n field="name" i18n={posGroup.positionGroup?.i18n} defaultValue={posGroup.positionGroup?.name} />
                                            </div>
                                        }
                                    >
                                        <div className="pretty-scrollbar overflow-auto" style={{ maxHeight: 300 }}>
                                            {posGroup.teamMembers.map((teamMember) => {
                                                return (
                                                    <DisplayMember
                                                        card
                                                        onClick={() => toggleSelectedMember(teamMember)}
                                                        className={stringBuilder(
                                                            "mb-2",
                                                            { "border-primary": !!selectedMembers[teamMember.member.member_id] },
                                                        )}
                                                        member={teamMember.member}
                                                        key={teamMember.team_member_id}
                                                        extra={{
                                                            infoBottom: (
                                                                <UserDisplay.Subtitle>
                                                                    <StatusBadge status={teamMember.member.member_status} color={teamMember.member.member_status?.color_code} />
                                                                </UserDisplay.Subtitle>
                                                            ),
                                                            right: (
                                                                <UserDisplay.Container>
                                                                    <CustomInput
                                                                        onChange={() => {}}
                                                                        checked={!!selectedMembers[teamMember.member.member_id]}
                                                                        type="checkbox"
                                                                    />
                                                                </UserDisplay.Container>
                                                            ),
                                                            left: (
                                                                <UserDisplay.Container className="ml-auto text-right">
                                                                    <DisplayI18n
                                                                        field="name"
                                                                        i18n={teamMember.position?.i18n}
                                                                        defaultValue={teamMember.position?.name}
                                                                    />
                                                                </UserDisplay.Container>
                                                            ),
                                                        }}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </CollapsibleCard>
                                </div>
                            ))}
                        </>
                    :
                    <>
                        <div className="h5 font-medium mb-3">
                            <Skeleton height="1em" width="6ch" />
                        </div>
                        <DisplayMemberLoading />
                    </>
                }
            </ModalBody>
            <ModalFooter>
                <Button type="button" onClick={previous} outline color="primary" className="mr-auto">
                    <Translate id="misc.previous" />
                </Button>
                <Button
                    disabled={selectedArray.length == 0}
                    onClick={() => {
                        addMembers(selectedArray);
                        setSelectedMembers({});
                        previous();
                    }}
                    type="button"
                    color="primary"
                >
                    <Translate id="misc.add" /> {selectedArray.length > 0 && `(${selectedArray.length})`}
                </Button>
            </ModalFooter>
        </>
    )
}

export default TravelPermitAddLineupSearch;