import { FormikDateTime, FormikInputNumber, FormikSelect } from '@spordle/formik-elements';
import { useContext } from 'react';
import { I18nContext } from '../../../contexts/I18nContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';

const DisplayInput = ({ teamSetting, fieldName, value, disabled, onFieldValueChange }) => {
    const i18nContext = useContext(I18nContext);

    switch (teamSetting.type){
        case 'TEXT':
            if(teamSetting.code === 'team_fee'){
                return (
                    <FormikInputNumber
                        allowLeadingZeros
                        fixedDecimalScale
                        thousandSeparator=' '
                        allowNegative={false}
                        decimalScale={2}
                        name={fieldName}
                        id={fieldName}
                        decimalSeparator={i18nContext.getGenericLocale() === 'fr' ? ',' : '.'}
                        suffix={i18nContext.getGenericLocale() === 'fr' ? ' $' : ''}
                        prefix={i18nContext.getGenericLocale() !== 'fr' ? '$ ' : ''}
                        disabled={disabled}
                        onChange={onFieldValueChange}
                    />
                )
            }
            return (
                <FormikInputNumber
                    allowNegative={false}
                    decimalSeparator={false}
                    name={fieldName}
                    id={fieldName}
                    disabled={disabled}
                    onChange={onFieldValueChange}
                />
            )

        case 'RADIO':
            return (
                <FormikSelect
                    id={fieldName} name={fieldName} search={false}
                    renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                    disabled={disabled}
                    values={[ value ]}
                    loadingStatus='success'
                    defaultData={Object.keys(teamSetting.options).map((key) => {
                        const option = teamSetting.options[key];
                        return ({
                            value: key,
                            label: '',
                            i18n: option.i18n,
                        })
                    })}
                    onOptionSelected={onFieldValueChange}
                />
            )
        case 'MULTIPLE':
            return (
                <FormikSelect
                    id={fieldName} name={fieldName} search={false}
                    multi
                    renderOption={(option) => <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                    disabled={disabled}
                    values={value}
                    loadingStatus='success'
                    defaultData={Object.keys(teamSetting.options).map((key) => {
                        const option = teamSetting.options[key];
                        return ({
                            value: key,
                            label: '',
                            i18n: option.i18n,
                        })
                    })}
                    onOptionSelected={onFieldValueChange}
                />
            )
        case 'DATETIME':
            return (
                <FormikDateTime
                    id={fieldName} name={fieldName}
                    inputProps={{
                        disabled: disabled,
                    }}
                    onChange={onFieldValueChange}
                />
            )
        default: return null
    }
}

export default DisplayInput