import React from "react";
import {
    Fade,
    Spinner
} from "reactstrap";
import withContexts from "../helpers/withContexts";

//Contexts
import { AuthContext, PeriodsContext } from "../contexts/contexts";
import { IdentityRolesContext } from "../contexts/IdentityRolesContext";
import { MembersContext } from "../contexts/MembersContext";
import { OrganizationContext } from "../contexts/OrganizationContext";
import { RolesContext } from "../contexts/RolesContext";
import { ClinicsContext } from "../contexts/ClinicsContext";
import { OnlineStoreContext } from "../contexts/OnlineStoreContext";
import { JasonViewer } from "@spordle/jason";

class ViewStateSidePanel extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loadingState: 'success',
        }
    }

    componentDidMount(){
        // API call with this.props.memberId
        // ...
        setTimeout(() => {
            this.setState({
                loadingState: 'success',

            })
        }, 1000);
    }

    render(){
        return (
            <>
                {this.state.loadingState === 'lazy' ?
                    <Fade in={this.state.loadingState === 'lazy'}>
                        <div className='d-flex justify-content-center p-5'>
                            <Spinner type='grow' color='primary' />
                        </div>
                    </Fade>
                    : this.state.loadingState === 'success' ?
                        <Fade in={this.state.loadingState === 'success'}>
                            <div className="">
                                <div className='d-flex'>
                                    <button type='button' className='reset-btn p-2 text-dark' onClick={this.props.toggle}><i className='mdi mdi-arrow-right font-20' /></button>
                                    <div className="h4 font-bold mb-3 mt-3">State List</div>
                                </div>
                            </div>

                            <div className="px-3">
                                {/* Important States */}
                                <div className="h5 font-bold m-2">Important State Info</div>
                                <JasonViewer
                                    style={{ backgroundColor: '#272822', color: 'white' }}
                                    json={{
                                        'organisation_id': this.props.OrganizationContext.organisation_id,
                                        'identity_role_id': this.props.IdentityRolesContext.identity_role_id,
                                        'identity_id': this.props.IdentityRolesContext.identity.identity_id,
                                        'role_id': this.props.IdentityRolesContext.role.role_id,
                                        'period_id': this.props.PeriodsContext.selectedPeriod.period_id,
                                    }}
                                />
                            </div>
                            {/* Contexts */}
                            {/* <div className="px-3">
                            <div className="h5 font-bold m-2">Context States</div>
                            <JasonViewer style={{backgroundColor: '#272822', color: 'white'}}
                                json={{
                                    'AuthContext': this.props.AuthContext.state,
                                    'IdentityRolesContext': this.props.IdentityRolesContext.state,
                                    'RolesContext': this.props.RolesContext.state,
                                    'OrganizationContext': this.props.OrganizationContext.state,
                                    'PeriodsContext': this.props.PeriodsContext.state,
                                    'MembersContext': this.props.MembersContext.state,
                                    'ClinicsContext': this.props.ClinicsContext.state,
                                    'OnlineStoreContext': this.props.OnlineStoreContext.state,
                                }}
                            />
                        </div> */}
                        </Fade>
                        : null}
            </>
        )
    }
}

export default withContexts(AuthContext, IdentityRolesContext, RolesContext, OrganizationContext, PeriodsContext, MembersContext, ClinicsContext, OnlineStoreContext)(ViewStateSidePanel);