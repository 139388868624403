export default{
    "organization.profile.venues.organizationVenues.title" : "Locations & venues",

    "organization.profile.venues.organizationVenues.table.venue"          : "Emplacement",
    "organization.profile.venues.organizationVenues.table.location"       : "Terrain Principale",
    "organization.profile.venues.organizationVenues.table.playingSurface" : "Terrain",
    "organization.profile.venues.organizationVenues.table.details"        : "Détails",
    "organization.profile.venues.organizationVenues.table.activity"       : "Activité",
    "organization.profile.venues.organizationVenues.table.size"           : "Type",

    "organization.profile.venues.organizationVenues.table.city"      : "Ville",
    "organization.profile.venues.organizationVenues.table.province"  : "Province",
    "organization.profile.venues.organizationVenues.table.country"   : "Pays",
    "organization.profile.venues.organizationVenues.table.noresults" : "Aucun emplacement trouvé.",
}