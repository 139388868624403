import Translate from '@spordle/intl-elements';
import { useState } from 'react';
import SidePanel from '../../SidePanel';
import TransferRequestRefuse from '../TransferRequestRefuse';
import TransferRequestRefuseOverride from '../TransferRequestRefuseOverride';

const DeclineAction = ({ transfer, onTransfer, override = false }) => {
    const [ modalIsOpen, setModalOpen ] = useState(false);
    const toggleModal = () => { setModalOpen(!modalIsOpen) };

    const Tag = !override ? TransferRequestRefuse : TransferRequestRefuseOverride
    const tagPermissionProps = override ? { action: 'ADD', componentCode: 'members', componentPermissionCode: 'member_transfer_override' } : { action: 'EDIT', componentCode: 'members', componentPermissionCode: 'members_request_transfer' }

    return (
        <SidePanel.MenuAction {...tagPermissionProps} onClick={toggleModal}>
            <Translate id={override ? 'transfer.sidepanel.refuseRequest.override' : 'transfer.sidepanel.refuseRequest'} />
            <Tag isOpen={modalIsOpen} toggle={toggleModal} transfer={transfer} onTransfer={onTransfer} />
        </SidePanel.MenuAction>
    );
}

export default DeclineAction;