import { FormikCheckedButton, FormikGroup, FormikInputNumber, FormikInputText, FormikRadioButton, FormikSelect } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
// Lang
import Translate from '@spordle/intl-elements';
import { FieldArray, Form, Formik } from "formik";
import moment from 'moment';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
    Alert,
    Button, Card, CardBody, CardFooter, CardHeader, Col, Collapse, Container, Fade, FormGroup, Label, Row, Spinner
} from "reactstrap";
import { array, bool, number, object, string } from 'yup';
import CustomAlert from "../../../../components/CustomAlert";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import FormikTiny from '../../../../components/formik/FormikTiny';
import Required from '../../../../components/formik/Required';
// Helpers
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import OrganizationSearch from '../../../../components/organization/OrganizationSearch';
import { fail } from '@spordle/toasts';
import { ClinicFeesContext } from '../../../../contexts/ClinicFeesContext';
import { ClinicsContext } from '../../../../contexts/ClinicsContext';
import { PeriodsContext } from '../../../../contexts/contexts';
import { FormsContext } from '../../../../contexts/FormsContext';
import { I18nContext } from '../../../../contexts/I18nContext';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { QualificationCategoriesContext } from '../../../../contexts/QualificationCategoriesContext';
import { QualificationsContext } from '../../../../contexts/QualificationsContext';
import { displayI18n, DisplayI18n } from '../../../../helpers/i18nHelper';
import generatePassword from "../../../../helpers/passwordGenerator";
import ClinicCategory from '../../ClinicCategory';
import EditableExpirationSection from './expiration/EditableExpirationSection';
import EditablePrerequisitesSetCard from './prerequisites/EditablePrerequisitesSetCard';
import PrerequisitesSetCard from './prerequisites/PrerequisitesSetCard';
import CrossFade from "../../../../components/crossFade/CrossFade";
import { SESSION_ONLINE, SESSION_ONLINE_LIVE, SESSION_ONSITE } from "../../clinicHelpers";

const FormClinicInformation = (props) => {
    const formikRef = useRef(null);
    ////////////////////////////////////////////////////////////////////////////
    // Contexts
    ////////////////////////////////////////////////////////////////////////////
    const orgContext = useContext(OrganizationContext);
    const clinicsContext = useContext(ClinicsContext);
    const membersContext = useContext(MembersContext);
    const qualificationCategoriesContext = useContext(QualificationCategoriesContext);
    const qualificationsContext = useContext(QualificationsContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const periodsContext = useContext(PeriodsContext);
    const i18nContext = useContext(I18nContext);
    const formsContext = useContext(FormsContext);
    const clinicFeesContext = useContext(ClinicFeesContext);

    ////////////////////////////////////////////////////////////////////////////
    // I18N
    ////////////////////////////////////////////////////////////////////////////
    const initialValuesClinicName = () => {
        const initialValues = {};
        orgContext.settings.language.value.forEach((lang) => {
            initialValues[`clinicName_${lang}`] = displayI18n('name', clinicsContext.currentClinic.i18n, clinicsContext.currentClinic.name, lang) || '';
            initialValues[`clinicCategoryName_${lang}`] = clinicsContext.currentClinic?.qualification ? getCurrentClinicCategoryName(lang) : '';
            initialValues[`clinicQualificationName_${lang}`] = clinicsContext.currentClinic?.qualification ? getCurrentClinicQualificationName(lang) : '';
        })
        return initialValues;
    }

    const getClinicNamePlaceholder = (lang) => {
        let placeholder = formikRef.current?.values?.['clinicCategoryName_' + lang] ?? ''
        if(placeholder && formikRef.current?.values?.['clinicQualificationName_' + lang])
            placeholder += ' - ' + formikRef.current.values['clinicQualificationName_' + lang]
        return placeholder
    }

    const getCurrentClinicCategoryName = (lang) => {
        return displayI18n('name', clinicsContext.currentClinic.qualification.qualification_category.i18n, clinicsContext.currentClinic.qualification.qualification_category.name, lang)
    }

    const getCurrentClinicQualificationName = (lang) => {
        const qualName = [ displayI18n('name', clinicsContext.currentClinic.qualification.i18n, clinicsContext.currentClinic.qualification.name, lang) ];

        if(clinicsContext.currentClinic.qualification.qualification_level){
            qualName.push(displayI18n('name', clinicsContext.currentClinic.qualification.qualification_level.i18n, clinicsContext.currentClinic.qualification.qualification_level.name, lang))
        }

        return qualName.join(' - ')
    }
    ////////////////////////////////////////////////////////////////////////////
    // Prerequisites
    ////////////////////////////////////////////////////////////////////////////

    const [ qualificationPrerequisites, setQualificationPrerequisites ] = useState([]);
    const [ qualificationsForPrerequisites, setQualificationsForPrerequisites ] = useState([]);

    const getFormattedQualificationsForPrerequisites = (qualificationsForPrerequisites, prerequisiteGroupIndex) => {
        return qualificationsForPrerequisites.map((qual) => ({
            ...qual,
            isDisabled: qualificationPrerequisites.map((r) => r.qualification_id).indexOf(qual.value) !== -1 || // default prerequisites
                        !!formikRef.current.values.prerequisites.find((group, index) => { // if the other prerequisite groups contain the qualification
                            if(index !== prerequisiteGroupIndex){
                                return group.qualifications.indexOf(qual.value) !== -1
                            }
                            return false

                        }),
            selected: formikRef.current.values.prerequisites[prerequisiteGroupIndex]?.qualifications.includes(qual.value),
        }))
    }

    ////////////////////////////////////////////////////////////////////////////

    /**  @type {React.MutableRefObject<SpordleSelect>} */
    const clinicQualificationSelectRef = useRef(null);

    ////////////////////////////////////////////////////////////////////////////
    // Expiration
    ////////////////////////////////////////////////////////////////////////////

    const [ categoryCanExpire, setCategoryCanExpire ] = useState(clinicsContext.currentClinic?.qualification?.qualification_category?.can_expire == 1 || false);
    const [ qualificationExpiration, setQualificationExpiration ] = useState(null);

    const updateQualificationExpiration = (type, duration, date) => {
        if(type && type !== 'NO_EXPIRATION'){
            setQualificationExpiration({
                expirationType: type,
                expirationDuration: duration,
                expirationDate: date,
            })
        }
    }
    ////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////
    // Attendees
    ////////////////////////////////////////////////////////////////////////////
    const memberTypesRef = useRef(null)

    ////////////////////////////////////////////////////////////////////////////
    // Attendees & age restrictions
    ////////////////////////////////////////////////////////////////////////////
    const [ minAgeOfAttendees, setMinAgeOfAttendees ] = useState(null);
    const [ maxAgeOfAttendees, setMaxAgeOfAttendees ] = useState(null);
    const [ attendeesRestrictionsLoading, setAttendeesRestrictionsLoading ] = useState(false);

    useLayoutEffect(() => {
        if(clinicsContext.currentClinic?.clinic_id || props.duplicateWithAttendees){
            setAttendeesRestrictionsLoading(true);
            clinicsContext.getClinicAttendees(props.duplicateWithAttendees || clinicsContext.currentClinic?.clinic_id, true)
                .then((attendees) => {
                    setMinAgeOfAttendees(attendees.reduce((minAge, attendee) => {
                        if(parseInt(attendee.member.age) < minAge || minAge === 0){
                            minAge = attendee.member.age
                        }
                        return minAge
                    }, 0))
                    setMaxAgeOfAttendees(attendees.reduce((maxAge, attendee) => {
                        if(parseInt(attendee.member.age) > maxAge || maxAge === 0){
                            maxAge = attendee.member.age
                        }
                        return maxAge
                    }, 0))
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
                .finally(() => setAttendeesRestrictionsLoading(false))
        }
    }, [])

    const hostOrganizationChange = (org) => {
        return new Promise((resolve) => {
            resolve(true) // to let the Organization Search do its thing without depending on this
            const id = org.organisation_id || org.value
            formikRef.current.setFieldValue('hostOrganizationName', org.organisation_name || org.label)
            formikRef.current.setFieldValue('hostOrganizationI18n', org.i18n || null)
            if(id === identityRolesContext.federation?.organisation_id){
                formikRef.current.setFieldValue('clinicType', 'qualification')
            }
            memberTypesRef.current?.getSpordleTable().filterChange('orgId', id, true);
        })
    }

    useEffect(() => {
        // to trigger an Org change to trigger the member types call
        hostOrganizationChange({
            organisation_id: clinicsContext.currentClinic?.organisation?.organisation_id || orgContext?.organisation_id,
            organisation_name: clinicsContext.currentClinic?.organisation?.organisation_name || orgContext?.organisation_name,
            i18n: clinicsContext.currentClinic?.organisation?.i18n || orgContext?.i18n,
        })
    }, []);

    const isInProfileOrDuplicate = !!clinicsContext.currentClinic?.clinic_id || !!props.duplicateWithAttendees;
    const hasAttendees = clinicsContext.currentClinic.clinic_attendees?.length > 0

    return (
        <Container>
            <Formik
                innerRef={(r) => formikRef.current = r}
                initialValues={{
                    // host
                    hostOrganization: clinicsContext.currentClinic?.organisation?.organisation_id || orgContext.organisation_id,
                    hostOrganizationName: clinicsContext.currentClinic?.organisation?.organisation_name || orgContext.organisation_name,
                    hostOrganizationI18n: clinicsContext.currentClinic?.organisation?.i18n || orgContext.i18n,

                    // clinic info
                    clinicType: clinicsContext.currentClinic?.clinic_type?.toLowerCase() || 'qualification',
                    clinicCategory: clinicsContext.currentClinic?.qualification?.qualification_category?.qualification_category_id || '',
                    clinicQualification: clinicsContext.currentClinic?.qualification?.qualification_id || '',
                    moodleExternalId: clinicsContext.currentClinic?.clinic_external_identifier?.[0]?.external_id || '',
                    expirationType: clinicsContext.currentClinic?.expiration_type || 'NO_EXPIRATION',
                    expirationDuration: clinicsContext.currentClinic?.expiration_duration || '',
                    expirationDate: clinicsContext.currentClinic?.expiration_date || '',
                    prerequisites: clinicsContext.currentClinic?.prerequisites ?
                        clinicsContext.currentClinic.prerequisites.map((group) => ({ groupId: generatePassword(),
                                                                                     restriction: group.restriction_condition,
                                                                                     qualifications: group.requirements.map((qual) => qual.qualification_id) }))
                        : [],
                    ...initialValuesClinicName(),

                    // attendees
                    criminalRecordCheck: clinicsContext.currentClinic?.crc === '1',
                    memberTypes: clinicsContext.currentClinic?.member_types || [],
                    minAttendees: clinicsContext.currentClinic?.min_attendee || '',
                    maxAttendees: clinicsContext.currentClinic?.max_attendee || '',
                    minAgeRegistration: clinicsContext.currentClinic?.min_age || '',
                    maxAgeRegistration: clinicsContext.currentClinic?.max_age || '',
                    passingGrade: clinicsContext.currentClinic?.clinic_external_identifier?.[0]?.passing_grade || "",
                    ruleForQualifications: clinicsContext.currentClinic?.rule_to_obtain_qualification ? clinicsContext.currentClinic?.rule_to_obtain_qualification.split(',') : [],
                    waitingListCheck: clinicsContext.currentClinic?.manage_waiting_list == '1',

                    // description
                    description: clinicsContext.currentClinic?.description || '',
                }}
                validationSchema={object().shape({
                    // host
                    hostOrganization: string().required(<Translate id='clinics.clinicInformation.formClinicInformation.host.validation' />),

                    // clinic info
                    clinicType: string().required(<Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.type.validation' />),
                    clinicCategory: string().required(<Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.category.validation' />),
                    clinicQualification: string().required(<Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.qualification.validation' />),
                    moodleExternalId: string(),
                    expirationType: string().required(),
                    expirationDuration: number().when('expirationType', {
                        is: (expirationType) => expirationType === 'FIXED_PERIOD' || expirationType === 'FIXED_DATE',
                        then: number().required(<Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.duration.required' />),
                    }),
                    expirationDate: string().when('expirationType', {
                        is: 'FIXED_DATE',
                        then: string().required(<Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.date.required' />).test({
                            name: 'expirationDateTest',
                            message: <Translate id='form.validation.date.format' />,
                            test: (date) => {
                                if(date){
                                    return moment(date, 'MM-DD').isValid()
                                }
                                return false
                            },
                        }),
                    }),
                    prerequisites: array().of(object().shape({
                        restriction: string().min(1).required(),
                        qualifications: array().min(1, <Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.prerequisites.required' values={{ break: (_) => <br /> }} />),
                    })),

                    // attendees
                    memberTypes: array(),
                    minAttendees: number().min(1, <Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.min.min' />)
                        .test({
                            name: 'minimumMax',
                            message: <Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.min.max' />,
                            test: function(value){
                                const { maxAttendees } = this.parent;
                                if(!maxAttendees || !value)
                                    return true

                                return value <= maxAttendees
                            },
                        }),
                    maxAttendees: number()
                        .test({
                            name: 'maximumMin',
                            message: <Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.max.min' />,
                            test: function(value){
                                const { minAttendees } = this.parent;
                                if(!minAttendees || !value)
                                    return true

                                return value >= minAttendees
                            },
                        })
                        .test({
                            name: 'waitingListMaxAttendees',
                            message: <Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.waitingList' />,
                            test: function(value){
                                const { waitingListCheck } = this.parent;
                                if(waitingListCheck == '1')
                                    return !!value

                                return true
                            },
                        }),
                    minAgeRegistration: number().min(1, <Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.age.min.min' />)
                        .test({
                            name: 'minAgeAttendees',
                            message: <Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.age.min.max.already' values={{ age: minAgeOfAttendees }} />,
                            test: (value) => {
                                if(minAgeOfAttendees && value !== undefined){
                                    return value <= minAgeOfAttendees
                                }
                                return true
                            },
                        })
                        .test({
                            name: 'ageMinimumMax',
                            message: <Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.age.min.max' />,
                            test: function(value){
                                const { maxAgeRegistration } = this.parent;
                                if(!maxAgeRegistration || !value)
                                    return true

                                return value <= maxAgeRegistration
                            },
                        }),
                    maxAgeRegistration: number()
                        .test({
                            name: 'maxAgeAttendees',
                            message: <Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.age.min.max.already' values={{ age: maxAgeOfAttendees }} />,
                            test: (value) => {
                                if(maxAgeOfAttendees && value !== undefined){
                                    return value >= maxAgeOfAttendees
                                }
                                return true
                            },
                        })
                        .test({
                            name: 'ageMaximumMin',
                            message: <Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.age.max.min' />,
                            test: function(value){
                                const { minAgeRegistration } = this.parent;
                                if(!minAgeRegistration || !value)
                                    return true

                                return value >= minAgeRegistration
                            },
                        }),
                    passingGrade: string().max(64, <Translate id="form.validation.string.max" values={{ max: 64 }} />),
                    criminalRecordCheck: bool(),
                    waitingListCheck: bool(),

                    // description
                    description: string(),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    if(!props.duplicate){
                        if(clinicsContext.currentClinic?.clinic_id){
                            clinicsContext.updateClinic(clinicsContext.currentClinic.clinic_id, {
                                // have to send infos like terms and condition id, merchant account id, etc. from steps 3 and 4 because the call is a PUT, anything that is not sent will be deleted
                                ...values,
                                qualificationId: values.clinicQualification,
                                prerequisites: values.prerequisites,
                                periodId: periodsContext.selectedPeriod.period_id,
                                termAndConditionId: clinicsContext.currentClinic.term_and_condition?.term_and_condition_id,
                                merchantAccountId: clinicsContext.currentClinic.merchant_account?.merchant_account_id,
                                afterRegistration: clinicsContext.currentClinic.message_after_registration?.message,
                                confirmation: clinicsContext.currentClinic.message_confirmation_email?.message,
                                expirationDate: values.expirationDate && values.expirationType === 'FIXED_DATE' ? values.expirationDate : '',
                                expirationDuration: values.expirationType !== 'NO_EXPIRATION' ? values.expirationDuration : '',
                            }, orgContext.settings.language.value)
                                .then(() => {
                                    clinicsContext.getAllClinicInfos(clinicsContext.currentClinic.clinic_id, { memberTypes: true })
                                        .then(() => {
                                            props.exited();
                                        })
                                        .catch((error) => {
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                            console.error(error)
                                        })
                                        .finally(() => {
                                            setSubmitting(false)
                                        })
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        setSubmitting(false)
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                    }
                                })
                        }else{
                            clinicsContext.createClinic({
                                ...values,
                                qualificationId: values.clinicQualification,
                                prerequisites: values.prerequisites,
                                periodId: periodsContext.selectedPeriod.period_id,
                                expirationDate: values.expirationDate && values.expirationType === 'FIXED_DATE' ? values.expirationDate : '',
                                expirationDuration: values.expirationType !== 'NO_EXPIRATION' ? values.expirationDuration : '',
                                createdFrom: identityRolesContext.organisation.organisation_id,
                            }, orgContext.settings.language.value)
                                .then((clinicId) => {
                                    clinicsContext.getAllClinicInfos(clinicId, { memberTypes: true, merchantAccounts: true })
                                        .then(() => {
                                            props.exited();
                                        })
                                        .catch((error) => {
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                            console.error(error)
                                        })
                                        .finally(() => {
                                            setSubmitting(false)
                                        })
                                })
                                .catch((error) => {
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                    setSubmitting(false);
                                    console.error(error)
                                })
                        }
                    }else{
                        clinicsContext.createClinic({
                            ...values,
                            qualificationId: values.clinicQualification,
                            prerequisites: values.prerequisites,
                            // prerequisites: values.clinicPrerequisites,
                            // prerequisiteRestriction: values.clinicPrerequisitesRestriction,
                            periodId: periodsContext.selectedPeriod.period_id,
                            merchantAccountId: clinicsContext.currentClinic?.merchant_account?.merchant_account_id,
                            afterRegistration: clinicsContext.currentClinic?.message_after_registration?.message,
                            confirmation: clinicsContext.currentClinic?.message_confirmation_email?.message,
                            termAndConditionId: clinicsContext.currentClinic?.term_and_condition?.term_and_condition_id,
                            expirationDate: values.expirationDate && values.expirationType === 'FIXED_DATE' ? values.expirationDate : '',
                            expirationDuration: values.expirationType !== 'NO_EXPIRATION' ? values.expirationDuration : '',
                            duplicateWithAttendees: props.duplicateWithAttendees,
                            createdFrom: identityRolesContext.organisation.organisation_id,
                        }, orgContext.settings.language.value)
                            .then((clinicId) => {
                                Promise.all([
                                    ...clinicsContext.currentClinic?.contacts?.map((contact) => {
                                        return new Promise((resolve) => {
                                            clinicsContext.createContact(clinicId, {
                                                firstName: contact.first_name,
                                                lastName: contact.last_name,
                                                email: contact.email,
                                                phone: contact.phone || null,
                                                notify: !!contact.notifications?.find((notif) => notif.code === 'after_clinic_registration'),
                                            })
                                                .finally(resolve)
                                        })
                                    }) || [],

                                    ...clinicsContext.currentClinic?.instructors?.map((instructor) => {
                                        return new Promise((resolve) => {
                                            clinicsContext.createInstructor(clinicId, {
                                                firstName: instructor.first_name,
                                                lastName: instructor.last_name,
                                                email: instructor.email,
                                                phone: instructor.phone || null,
                                            })
                                                .finally(resolve)
                                        })
                                    }) || [],

                                    ...clinicsContext.currentClinic?.sessions?.map((session) => {
                                        return new Promise((resolve) => {
                                            clinicsContext.createSession(clinicId, {
                                                format: session.session_format,
                                                note: session.note,
                                                urlLink: session.url_link,
                                                startDate: session.start_date,
                                                endDate: session.end_date,
                                                duration: session.duration,
                                                mandatory: session.mandatory === '1',
                                                locationName: session.location_name,
                                                origin: session.origin_address || '',
                                                address: session.street,
                                                streetNumber: session.street_number,
                                                city: session.city,
                                                zipCode: session.postal_code,
                                                country: session.country_id,
                                                state: session.province_id,
                                                active: true,
                                                languages: session.languages.toString(),
                                            })
                                                .finally(resolve)
                                        })
                                    }) || [],

                                    new Promise((resolve) => {
                                        return formsContext.formClinicLink(clinicsContext.currentClinic.custom_form?.[0]?.custom_form_id, clinicId)
                                            .then(() => resolve())
                                            .catch((error) => {
                                                resolve()
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    }),

                                    ...clinicsContext.currentClinic?.clinic_fees?.map((clinicFee) => {
                                        return new Promise((resolve) => {
                                            clinicFeesContext.createClinicFee(clinicId, clinicFee?.fee?.fee_id, clinicFee?.mandatory === '1')
                                                .catch((error) => {
                                                    resolve();
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                                .finally(resolve)
                                        })
                                    }) || [],

                                    ...clinicsContext.currentClinic.accepted_payment_methods?.length > 0 ?
                                        [ new Promise((resolve) => {
                                            return clinicsContext.updatePaymentMethods(clinicId, clinicsContext.currentClinic.accepted_payment_methods.map((paymentMethod) => paymentMethod.code))
                                                .then(() => resolve())
                                                .catch(() => resolve())
                                        }) ]
                                        : [],

                                    ...clinicsContext.currentClinic.waivers?.length > 0 ?
                                        [ new Promise((resolve) => {
                                            return clinicsContext.updateWaivers(clinicId, clinicsContext.currentClinic.waivers.map((waiver) => waiver.waiver_id))
                                                .then(() => resolve())
                                                .catch(() => { resolve() })
                                        }) ]
                                        : [],
                                ])
                                    .then((promises) => {
                                        clinicsContext.getAllClinicInfos(clinicId, { sessions: true, contacts: true, instructors: true, memberTypes: true, merchantAccounts: true })
                                            .then(() => {
                                                props.exited();
                                            })
                                            .catch((error) => {
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                                console.error(error)
                                            })
                                            .finally(() => {
                                                setSubmitting(false)
                                            })
                                    })
                            })
                            .catch((error) => {
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                setSubmitting(false);
                                console.error(error)
                            })
                    }
                }}
            >
                {(formik) => {
                    return (
                        <Form id='clinicsCreationStep1'>
                            <Card className={'card-shadow ' + (!props.wizard ? 'shadow-primary' : '')}>
                                <OverlayLoader isLoading={formik.isSubmitting}>
                                    {!props.wizard &&
                                        <CardHeader className="d-flex align-items-center">
                                            <div className="h4 mb-0 font-bold"><Translate id='clinics.clinicInformation.formClinicInformation.review.title' /></div>
                                        </CardHeader>
                                    }
                                    <CardBody>
                                        {hasAttendees &&
                                            <Alert color="primary" className="d-flex align-items-center">
                                                <i className="mdi mdi-information-outline mr-1 font-18" /><Translate id="clinics.clinicInformation.formClinicInformation.review.hasAttendees" />
                                            </Alert>
                                        }
                                        <div className={props.wizard ? 'mb-5' : 'mb-4'}>
                                            <div className={`font-bold mb-3 ${props.wizard ? 'h3 card-title border-bottom pb-1' : 'h5 '}`}><Translate id='clinics.clinicInformation.formClinicInformation.host.title' /></div>
                                            <Row>
                                                <Col md='8'>
                                                    <OrganizationSearch
                                                        id="hostOrganization"
                                                        name="hostOrganization"
                                                        withFormik withTree={!isInProfileOrDuplicate} fromIdentityRole
                                                        selectedDefault={clinicsContext.currentClinic?.organisation?.organisation_id || orgContext.organisation_id}
                                                        onConfirm={hostOrganizationChange} treeOrganizationClick={hostOrganizationChange}
                                                        disabled={isInProfileOrDuplicate}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className={props.wizard ? 'mb-5' : 'mb-4'}>
                                            <div className={`font-bold mb-3 ${props.wizard ? 'h3 card-title border-bottom pb-1' : 'h5 '}`}><Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.title' /></div>
                                            <FormikGroup name='clinicType' rowProps={{ className: 'mx-0' }}>
                                                <FormikRadioButton
                                                    className="mr-3 mb-3"
                                                    id='clinicTypeQualification'
                                                    name='clinicType'
                                                    value='qualification'
                                                    label={<Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.type.qualification' values={{ federationName: identityRolesContext.federation.abbreviation }} />}
                                                    translateLabel={false}
                                                    checked={formik.values.clinicType === 'qualification'}
                                                    disabled={isInProfileOrDuplicate}
                                                    onChange={() => {
                                                        formik.setFieldValue('clinicQualification', '')
                                                        if(formik.values.clinicCategory !== ''){
                                                            clinicQualificationSelectRef.current.getSpordleTable().filterChange('orgId', identityRolesContext.federation.organisation_id, true)
                                                            // getQualifications(formik.values.clinicCategory, identityRolesContext.federation.organisation_id)
                                                        }
                                                        formik.setFieldValue('clinicType', 'qualification')
                                                    }}
                                                />
                                                {identityRolesContext.federation?.organisation_id !== formik.values.hostOrganization &&
                                                    <FormikRadioButton
                                                        className="mr-3 mb-3"
                                                        id='clinicTypeOther'
                                                        name='clinicType'
                                                        value='other'
                                                        label='clinics.clinicInformation.formClinicInformation.clinicInfo.type.other'
                                                        translateLabel
                                                        defaultChecked={formik.values.clinicType === 'other'}
                                                        disabled={isInProfileOrDuplicate}
                                                        onChange={() => {
                                                            formik.setFieldValue('clinicQualification', '')
                                                            if(formik.values.clinicCategory !== ''){
                                                                clinicQualificationSelectRef.current.getSpordleTable().filterChange('orgId', formik.values.hostOrganization, true)
                                                                // getQualifications(formik.values.clinicCategory, formik.values.hostOrganization)
                                                            }
                                                            formik.setFieldValue('clinicType', 'other')
                                                        }}
                                                    />
                                                }
                                            </FormikGroup>

                                            <FormGroup>
                                                <Label for="clinicCategory" className="text-muted">
                                                    <Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.category' /> <Required />
                                                </Label>
                                                <FormikSelect
                                                    name='clinicCategory'
                                                    id='FormClinicInformationCategorySelect'
                                                    disabled={hasAttendees}
                                                    onOptionSelected={(options, select) => {
                                                        const selectedOption = select.getSpordleTable().getData().find((option) => option.value === options[0]);
                                                        setCategoryCanExpire(selectedOption.canExpire);
                                                        formik.setFieldValue('clinicQualification', '');
                                                        setQualificationPrerequisites([]);
                                                        clinicQualificationSelectRef.current.getSpordleTable().filterChange('orgId', formik.values.clinicType === 'qualification' ? identityRolesContext.federation.organisation_id : formik.values.hostOrganization, false)
                                                        clinicQualificationSelectRef.current.getSpordleTable().filterChange('categoryId', selectedOption.value, true)
                                                        // getQualifications(selectedOption.value, formik.values.clinicType === 'qualification' ? identityRolesContext.federation.organisation_id : formik.values.hostOrganization);

                                                        orgContext.settings.language.value.forEach((lang) => {
                                                            formik.setFieldValue(`clinicCategoryName_${lang}`, displayI18n("name", selectedOption.i18n, selectedOption.label, lang))
                                                            formik.setFieldValue(`clinicQualificationName_${lang}`, '')
                                                        })
                                                    }}
                                                    noOptionLayout={<Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.category.noOptions' />}
                                                    placeholder='clinics.clinicInformation.formClinicInformation.clinicInfo.category.placeholder'
                                                    renderOption={(option) => (
                                                        <ClinicCategory color={option.option.color}>
                                                            <DisplayI18n
                                                                field='name'
                                                                defaultValue={option.option.label}
                                                                i18n={option.option.i18n}
                                                            />
                                                        </ClinicCategory>
                                                    )}
                                                    loadData={(from, _, __) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return qualificationCategoriesContext.getQualificationCategories()
                                                                    .then((categories) => {
                                                                        return categories.reduce((newArray, category) => {
                                                                            if(category.active == 1){
                                                                                newArray.push({
                                                                                    label: category.name,
                                                                                    value: category.qualification_category_id,
                                                                                    i18n: category.i18n,
                                                                                    color: isNaN(parseInt(category.color)) ? 0 : category.color,
                                                                                    canExpire: category.can_expire == 1,
                                                                                })
                                                                            }
                                                                            return newArray
                                                                        }, [])
                                                                    })
                                                        }
                                                    }}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="clinicQualification" className="text-muted">
                                                    <Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.qualification' /> <Required />
                                                </Label>
                                                <FormikSelect
                                                    ref={clinicQualificationSelectRef}
                                                    name='clinicQualification'
                                                    id='FormClinicInformationQualificationSelect'
                                                    onOptionSelected={(options, select) => {
                                                        const selectedOption = select.getSpordleTable().getData().find((option) => option.value === options[0]);
                                                        const qualPrerequisites = selectedOption.prerequisites?.qualification_prerequisites ?? []
                                                        setQualificationPrerequisites(qualPrerequisites)

                                                        updateQualificationExpiration(selectedOption.expirationType, selectedOption.expirationDuration, selectedOption.expirationDate);

                                                        // the user could select a qualification, then add a prerequisite, then change qualification, and we could have a duplicate
                                                        // we need to remove the duplicate from the formik, and that will update the selects
                                                        formik.values.prerequisites.forEach((group, groupIndex) => {
                                                            group.qualifications.forEach((qual, qualIndex) => {
                                                                if(qualPrerequisites.find((q) => q.qualification_id === qual)){
                                                                    formik.setFieldValue('prerequisites', formik.values.prerequisites.map((group, index) => ({
                                                                        ...group,
                                                                        qualifications: formik.values.prerequisites[index].qualifications.reduce((newQuals, reduceQual) => {
                                                                            if(reduceQual !== qual){
                                                                                newQuals.push(reduceQual)
                                                                            }
                                                                            return newQuals
                                                                        }, []),
                                                                    })))
                                                                }
                                                            })
                                                        })

                                                        orgContext.settings.language.value.forEach((lang) => {
                                                            if(selectedOption.levelName){
                                                                formik.setFieldValue(`clinicQualificationName_${lang}`, `${displayI18n("name", selectedOption.i18nQualification, selectedOption.qualificationName, lang)} - ${displayI18n("name", selectedOption.i18nLevel, selectedOption.levelName, lang)}`)
                                                            }else{
                                                                formik.setFieldValue(`clinicQualificationName_${lang}`, displayI18n("name", selectedOption.i18nQualification, selectedOption.label, lang))
                                                            }
                                                        })
                                                    }}
                                                    noOptionLayout={<Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.qualification.noOptions' />}
                                                    placeholder='clinics.clinicInformation.formClinicInformation.clinicInfo.qualification.placeholder'
                                                    disabled={formik.values.clinicCategory === '' || hasAttendees}
                                                    search
                                                    searchKeys={[
                                                        'label',
                                                        'qualificationName',
                                                        `i18nQualification.${i18nContext.getGenericLocale()}.name`,
                                                        'levelName',
                                                        `i18nLevel.${i18nContext.getGenericLocale()}.name`,
                                                    ]}
                                                    renderOption={(option) => {
                                                        if(option.option.levelName){
                                                            return (
                                                                <>
                                                                    <DisplayI18n field='name' defaultValue={option.option.qualificationName} i18n={option.option.i18nQualification} /> - <DisplayI18n field='name' defaultValue={option.option.levelName} i18n={option.option.i18nLevel} />
                                                                </>
                                                            )
                                                        }
                                                        return (
                                                            <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18nQualification} />
                                                        )

                                                    }}
                                                    initFilter={{
                                                        orgId: clinicsContext.currentClinic?.organisation?.organisation_id || '',
                                                        categoryId: clinicsContext.currentClinic?.qualification?.qualification_category?.qualification_category_id || '',
                                                    }}
                                                    loadData={(from, extra, spordleTable) => {
                                                        switch (from){
                                                            case 'FILTER':
                                                                spordleTable.setLoading();
                                                            case 'CDM':
                                                                if(extra.filters.orgId){ // dont want to get qualifications if we dont need to (when there's no category selected)
                                                                    return qualificationsContext.getQualifications({ organisation_id: extra.filters.orgId })
                                                                        .then((data) => {
                                                                            const formattedData = data.reduce((newArray, qualification) => {
                                                                                if(qualification.active == 1){
                                                                                    if(qualification.qualification_level){
                                                                                        newArray.push({
                                                                                            // basic select values
                                                                                            label: `${qualification.name} - ${qualification.qualification_level.name}`,
                                                                                            value: qualification.qualification_id,
                                                                                            id: qualification.qualification_id,

                                                                                            // qualification
                                                                                            qualificationName: qualification.name,
                                                                                            i18nQualification: qualification.i18n,
                                                                                            // level
                                                                                            levelName: qualification.qualification_level.name,
                                                                                            i18nLevel: qualification.qualification_level.i18n,

                                                                                            prerequisites: qualification.prerequisites,
                                                                                            category: { ...qualification.qualification_category, color: isNaN(parseInt(qualification.qualification_category?.color)) ? 0 : qualification.qualification_category.color },
                                                                                            description: qualification.description,
                                                                                            organization: qualification.organisation,
                                                                                            expirationType: qualification.expiration_type,
                                                                                            expirationDuration: qualification.expiration_duration,
                                                                                            expirationDate: qualification.expiration_date,
                                                                                        })
                                                                                    }else{
                                                                                        newArray.push({
                                                                                            // basic select values
                                                                                            label: qualification.name,
                                                                                            value: qualification.qualification_id,
                                                                                            id: qualification.qualification_id,

                                                                                            // qualification
                                                                                            i18nQualification: qualification.i18n,

                                                                                            prerequisites: qualification.prerequisites,
                                                                                            category: { ...qualification.qualification_category, color: isNaN(parseInt(qualification.qualification_category.color)) ? 0 : qualification.qualification_category.color },
                                                                                            description: qualification.description,
                                                                                            organization: qualification.organisation,
                                                                                            expirationType: qualification.expiration_type,
                                                                                            expirationDuration: qualification.expiration_duration,
                                                                                            expirationDate: qualification.expiration_date,
                                                                                        })
                                                                                    }
                                                                                }
                                                                                return newArray
                                                                            }, []).sort((itemA, itemB) => {
                                                                                return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' })
                                                                                    .compare(
                                                                                        (displayI18n("name", itemA.i18nQualification, itemA.qualificationName, i18nContext.getGenericLocale()) || "") + ' - ' + (displayI18n("name", itemA.i18nLevel, itemA.levelName, i18nContext.getGenericLocale()) || ""),
                                                                                        (displayI18n("name", itemB.i18nQualification, itemB.qualificationName, i18nContext.getGenericLocale()) || "") + ' - ' + (displayI18n("name", itemB.i18nLevel, itemB.levelName, i18nContext.getGenericLocale()) || ""),
                                                                                    );
                                                                            })

                                                                            setQualificationsForPrerequisites(formattedData);
                                                                            // set the default prerequisites
                                                                            if(clinicsContext.currentClinic?.qualification?.qualification_id){
                                                                                updateQualificationExpiration(clinicsContext.currentClinic.qualification.expiration_type, clinicsContext.currentClinic.qualification.expiration_duration, clinicsContext.currentClinic.qualification.expiration_date);
                                                                                setQualificationPrerequisites(clinicsContext.currentClinic.qualification.prerequisites.length > 0 ? clinicsContext.currentClinic.qualification.prerequisites[0].requirements : [])
                                                                            }
                                                                            return formattedData.filter((qual) => qual.category.qualification_category_id === extra.filters.categoryId)
                                                                        })
                                                                }
                                                                return Promise.resolve([]);

                                                        }
                                                    }}
                                                />
                                            </FormGroup>

                                            {orgContext.settings.language.value.map((lang) => (
                                                <FormGroup key={lang}>
                                                    <Label for={`clinicName_${lang}`} className="text-muted">
                                                        <Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.name' /> (<Translate id={`misc.${lang}`} />)
                                                    </Label>
                                                    <FormikInputText id={`clinicName_${lang}`} name={`clinicName_${lang}`} placeholder={getClinicNamePlaceholder(lang)} translatePlaceholder={false} trim lang={lang} />
                                                </FormGroup>
                                            ))}

                                            {!!props.moodleCourses &&
                                                <Row form className="mb-3">
                                                    <Col sm={6} className="mb-3 mb-sm-0">
                                                        <Label for="moodleExternalId" className="text-muted">
                                                            <Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.moodle' />
                                                        </Label>
                                                        <FormikSelect
                                                            name='moodleExternalId'
                                                            id='moodleExternalId'
                                                            disabled={!!clinicsContext.currentClinic?.clinic_external_identifier?.[0]?.external_id}
                                                            searchKeys={[
                                                                'label',
                                                            ]}
                                                            onOptionSelected={([ val ]) => {
                                                                if(!val){
                                                                    formik.setFieldValue('passingGrade', "");
                                                                }
                                                            }}
                                                            loadData={(from, _, spordleTable) => {
                                                                switch (from){
                                                                    case 'FILTER':
                                                                        spordleTable.setLoading();
                                                                    case 'CDM':
                                                                        return Promise.resolve(props.moodleCourses)
                                                                            .then((data) => {
                                                                                const formattedData = data.reduce((newArray, course) => {
                                                                                    newArray.push({
                                                                                        label: course.course_name,
                                                                                        value: course.course_external_id.toString(),
                                                                                        id: course.course_external_id,
                                                                                    })
                                                                                    return newArray
                                                                                }, [])
                                                                                return formattedData;
                                                                            })
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col sm={6} className="mb-3">
                                                        <Label for="passingGrade" className="text-muted"><Translate id="clinics.clinicInformation.formClinicInformation.clinicInfo.passingGrade" /></Label>
                                                        <FormikInputText
                                                            name="passingGrade"
                                                            id="passingGrade"
                                                            disabled={!formik.values.moodleExternalId}
                                                        />
                                                    </Col>
                                                    <Col sm={6} className="mb-3 mb-sm-0">
                                                        <Label for="ruleForQualifications" className="text-muted"><Translate id="clinics.clinicSessionInformation.formClinicSessionInformation.ruleForQualifications" /></Label>
                                                        <FormikSelect
                                                            search={false}
                                                            isLoading={false}
                                                            helper="clinics.clinicSessionInformation.formClinicSessionInformation.ruleForQualifications.helper"
                                                            clearable
                                                            name="ruleForQualifications"
                                                            onOptionSelected={(val) => {
                                                                // Set as array for future development.
                                                                formik.setFieldValue("ruleForQualifications", val);
                                                                return true;
                                                            }}
                                                            renderOption={({ option }) => <Translate id={`clinics.clinicSessionInformation.formClinicSessionInformation.sessions.${option.value}`} />}
                                                            defaultData={
                                                                [ SESSION_ONLINE, SESSION_ONLINE_LIVE, SESSION_ONSITE ]
                                                                    .map((session) => ({
                                                                        label: `clinics.clinicSessionInformation.formClinicSessionInformation.sessions.${session}`,
                                                                        value: session,
                                                                    }))
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            }
                                        </div>

                                        <CrossFade isVisible={categoryCanExpire}>
                                            <EditableExpirationSection
                                                wizard={props.wizard}
                                                qualificationExpiration={qualificationExpiration}
                                                disabled={(hasAttendees && !props.fromDuplication) || props.duplicateWithAttendees}
                                            />
                                        </CrossFade>

                                        <div className={`mb-${props.wizard ? '5' : '4'}`}>
                                            <div className={`font-bold mb-3 ${props.wizard ? 'h3 card-title border-bottom pb-1' : 'h5'}`}>
                                                <Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.prerequisites' />
                                            </div>


                                            {qualificationPrerequisites.length > 0 &&
                                                <>
                                                    <div className="h6 font-bold">
                                                        <Translate
                                                            id='clinics.clinicInformation.clinicInformation.qualification.prerequisites.title'
                                                            values={{ qualName: formik.values['clinicQualificationName_' + i18nContext.getGenericLocale()] }}
                                                        />
                                                    </div>

                                                    <div className='mb-3'>
                                                        {qualificationPrerequisites.map((qual, index) => (
                                                            <PrerequisitesSetCard
                                                                key={qual.qualification_id}
                                                                title={<Translate id='clinics.clinicInformation.clinicInformation.qualification.groups.title' values={{ index: index + 1 }} />}
                                                                requirements={qualificationPrerequisites}
                                                                restriction="all"
                                                            />
                                                        ))}
                                                    </div>
                                                </>
                                            }

                                            <FieldArray name='prerequisites'>
                                                {(helpers) => (
                                                    <>
                                                        <div className="h6 font-bold"><Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites' /></div>
                                                        {formik.values.prerequisites.map((group, index) => (
                                                            <Collapse isOpen key={group.groupId} appear>
                                                                <EditablePrerequisitesSetCard
                                                                    index={index}
                                                                    title={<Translate id='clinics.clinicInformation.clinicInformation.qualification.groups.title' values={{ index: index + 1 }} />}
                                                                    restriction={group.restriction}
                                                                    handleRemove={helpers.handleRemove}
                                                                    qualificationsDisabled={!formik.values.clinicQualification}
                                                                    qualifications={getFormattedQualificationsForPrerequisites(qualificationsForPrerequisites, index)}
                                                                />
                                                            </Collapse>
                                                        ))}
                                                        <div className='d-flex justify-content-between'>
                                                            <div>
                                                                {formik.values.prerequisites.length === 0 &&
                                                                    <Translate id='clinics.clinicInformation.clinicInformation.qualification.prerequisites.none' />
                                                                }
                                                            </div>
                                                            {!((hasAttendees && !props.fromDuplication) || props.duplicateWithAttendees) &&
                                                                <button
                                                                    onClick={helpers.handlePush({
                                                                        groupId: generatePassword(),
                                                                        restriction: "all",
                                                                        qualifications: [],
                                                                    })}
                                                                    type='button'
                                                                    className={stringBuilder('reset-btn', { 'text-muted': !!props.duplicateWithAttendees, 'text-primary': !props.duplicateWithAttendees })}
                                                                >
                                                                    <i className='mdi mdi-plus mr-1' /><Translate id='clinics.clinicInformation.formClinicInformation.clinicInfo.qualPrerequisites.addGroup' />
                                                                </button>
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </div>

                                        {/* ATTENDEES */}
                                        <div className={`mb-${props.wizard ? '5' : '4'}`}>
                                            <div className={`font-bold mb-3 ${props.wizard ? 'h3 card-title border-bottom pb-1' : 'h5 '}`}><Translate id='clinics.clinicInformation.formClinicInformation.attendees.title' /></div>
                                            <div className='mb-3'>
                                                <Label className="text-muted mb-1" for="memberTypes"><Translate id='clinics.clinicInformation.formClinicInformation.attendees.types' /></Label>
                                                <FormikSelect
                                                    id='FormClinicInformationMemberTypesSelect'
                                                    name='memberTypes'
                                                    ref={memberTypesRef} multi
                                                    //disabled={(hasAttendees && !props.fromDuplication) || props.duplicateWithAttendees}
                                                    renderOption={(option) => (
                                                        <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                                    )}
                                                    noOptionLayout={<Translate id='clinics.clinicInformation.formClinicInformation.attendees.types.none' />}
                                                    placeholder='clinics.clinicInformation.formClinicInformation.attendees.types.placeholder'
                                                    initFilter={{
                                                        orgId: '',
                                                    }}
                                                    loadData={(from, extra, spordleTable) => {
                                                        switch (from){
                                                            case 'FILTER':
                                                                spordleTable.setLoading();
                                                            case 'CDM':
                                                                if(extra.filters.orgId){
                                                                    return membersContext.getMemberTypes(extra.filters.orgId)
                                                                        .then((data) => {
                                                                            /**
                                                                             * @type {array}
                                                                             */
                                                                            const result = data.reduce((newArray, memberType) => {
                                                                                if(memberType.active == 1){
                                                                                    newArray.push({
                                                                                        value: memberType.member_type_id,
                                                                                        i18n: memberType.i18n,
                                                                                        label: memberType.name,
                                                                                    })
                                                                                }
                                                                                return newArray
                                                                            }, [])
                                                                            result.sort((a, b) => {
                                                                                const valueA = (displayI18n("name", a.i18n, a.label, i18nContext.getGenericLocale())).toLowerCase()
                                                                                const valueB = (displayI18n("name", b.i18n, b.label, i18nContext.getGenericLocale())).toLowerCase()
                                                                                return valueA.localeCompare(valueB);
                                                                            })

                                                                            return result
                                                                        })
                                                                }
                                                                return Promise.resolve([]);

                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <Row>
                                                    <Col sm="6" md={{ size: 5 }}>
                                                        <div className={`mb-3 h6 font-bold`}><Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees' /></div>
                                                        <Row form>
                                                            <Col xs="6">
                                                                <FormGroup>
                                                                    <Label className="text-muted mb-1" for="minAttendees"><Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.min' /></Label>
                                                                    <FormikInputNumber allowNegative={false} name='minAttendees' id='minAttendees' />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs="6">
                                                                <FormGroup>
                                                                    <Label className="text-muted mb-1" for="maxAttendees"><Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.max' /></Label>
                                                                    <FormikInputNumber allowNegative={false} name='maxAttendees' id='maxAttendees' />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm="6" md={{ size: 5, offset: 1 }}>
                                                        <div className={`mb-3 h6 font-bold`}><Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.age' /></div>
                                                        <Row form>
                                                            <Col xs="6">
                                                                <FormGroup>
                                                                    <Label className="text-muted mb-1" for="minAgeRegistration"><Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.age.min' /></Label>
                                                                    <FormikInputNumber allowNegative={false} name='minAgeRegistration' id='minAgeRegistration' />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs="6">
                                                                <FormGroup>
                                                                    <Label className="text-muted mb-1" for="maxAgeRegistration"><Translate id='clinics.clinicInformation.formClinicInformation.attendees.requirements.age.max' /></Label>
                                                                    <FormikInputNumber allowNegative={false} name='maxAgeRegistration' id='maxAgeRegistration' />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <FormikCheckedButton
                                                                id='waitingListCheck'
                                                                name='waitingListCheck'
                                                                label='clinics.clinicInformation.formClinicInformation.attendees.waitingList'
                                                                translateLabel
                                                                disabled={props.waitListCount > 0}
                                                            />
                                                            <div className="small text-muted"><i className="mdi mdi-information-outline" /> <Translate id='clinics.clinicInformation.formClinicInformation.attendees.waitingList.helper' /></div>
                                                        </FormGroup>
                                                    </Col>
                                                    {orgContext.settings.enable_background_check?.value == 1 &&
                                                        <Col sm="12">
                                                            <FormGroup>
                                                                <FormikCheckedButton
                                                                    id='criminalRecordCheck'
                                                                    name='criminalRecordCheck'
                                                                    label='clinics.clinicInformation.formClinicInformation.attendees.criminalRecord'
                                                                    translateLabel
                                                                    disabled={isInProfileOrDuplicate}
                                                                />
                                                                <div className="small text-muted"><i className="mdi mdi-information-outline" /> <Translate id='clinics.clinicInformation.formClinicInformation.attendees.criminalRecord.helper' /></div>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                </Row>
                                            </div>
                                        </div>

                                        <div className="position-relative">
                                            <div className={`font-bold mb-3 ${props.wizard ? 'h3 card-title border-bottom pb-1' : 'h5 '}`}><Translate id='clinics.clinicInformation.formClinicInformation.description.title' /></div>
                                            <FormikTiny name='description' />
                                        </div>

                                        <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                            {formik.status &&
                                                <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                            }
                                        </Collapse>

                                    </CardBody>

                                    {!props.wizard &&
                                        <CardFooter className="bg-white text-right">
                                            <Button
                                                className='mr-2'
                                                color="primary"
                                                type='submit'
                                                disabled={formik.isSubmitting || attendeesRestrictionsLoading}
                                            >
                                                <span style={{ opacity: attendeesRestrictionsLoading ? 0 : 1 }}>
                                                    <Translate id='clinics.clinicInformation.formClinicInformation.review.saveChanges' />
                                                </span>
                                                <Fade in={attendeesRestrictionsLoading} className='position-absolute left-0 right-0 top-0 bottom-0 d-flex flex-center'>
                                                    <Spinner size='sm' type='grow' />
                                                </Fade>
                                            </Button>
                                            <Button color="primary" outline type='button' onClick={props.exited} disabled={formik.isSubmitting}><Translate id='clinics.clinicInformation.formClinicInformation.review.cancelChanges' /></Button>
                                        </CardFooter>
                                    }
                                </OverlayLoader>
                            </Card>
                            {props.wizard &&
                                <div className="d-flex mb-5">
                                    <Button
                                        color="primary"
                                        className="ml-auto"
                                        type='submit'
                                        disabled={formik.isSubmitting || attendeesRestrictionsLoading}
                                    >
                                        <span style={{ opacity: attendeesRestrictionsLoading ? 0 : 1 }}>
                                            <Translate id='misc.next' />
                                        </span>
                                        <Fade in={attendeesRestrictionsLoading} className='position-absolute left-0 right-0 top-0 bottom-0 d-flex flex-center'>
                                            <Spinner size='sm' type='grow' />
                                        </Fade>
                                    </Button>
                                </div>
                            }
                        </Form>
                    )
                }}
            </Formik>
        </Container>
    );
}

export default FormClinicInformation;