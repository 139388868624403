export default{
    // review step
    "clinics.clinicInformation.formClinicInformation.review.title"         : "Modifier les informations de stage",
    "clinics.clinicInformation.formClinicInformation.review.hasAttendees"  : "La clinique a déjà des participants. Certains champs peuvent être désactivés.",
    "clinics.clinicInformation.formClinicInformation.review.saveChanges"   : "Sauvegarder les changements",
    "clinics.clinicInformation.formClinicInformation.review.cancelChanges" : "Annuler les changements",

    // host organization
    "clinics.clinicInformation.formClinicInformation.host.title"       : "Organisation hôte",
    "clinics.clinicInformation.formClinicInformation.host.placeholder" : "Sélectionnez une organisation",
    "clinics.clinicInformation.formClinicInformation.host.validation"  : "L'organisation est requise",

    // clinic info
    "clinics.clinicInformation.formClinicInformation.clinicInfo.title"                                : "Informations sur le stage",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.name"                                 : "Nom",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.type.qualification"                   : "Qualification officielle ({federationName})",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.type.other"                           : "Qualification ou cours de l'organisation hôte",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.type.validation"                      : "Le type est requis",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.category"                             : "Catégorie",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.category.placeholder"                 : "Sélectionnez une catégorie",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.category.noOptions"                   : "Aucune catégorie disponible",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.category.validation"                  : "La catégorie est requise",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualification"                        : "Qualification",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualification.placeholder"            : "Sélectionnez une qualification",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualification.noOptions"              : "Aucune qualification disponible",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualification.validation"             : "La qualification est requise",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration"                           : "Expiration",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.qualification"             : "Remplir avec l'expiration de la qualification",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.type"                      : "Type",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.duration"                  : "Durée",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.duration.required"         : "La durée est requise.",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.duration.years"            : "{number, plural, one {# année} other {# années}}",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.NO_EXPIRATION"             : "Pas d'expiration",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.FIXED_PERIOD"              : "Période déterminée",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.FIXED_DATE"                : "À date fixe",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.date"                      : "Date",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.date.required"             : "La date est requise.",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.expiration.placeholder"               : "MM-JJ",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.prerequisites"                        : "Préalables",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.prerequisites.required"               : "Au moins un préalable est requise pour un groupe.<break></break>Vous pouvez supprimer la rangée si vous souhaitez n'avoir aucun préalable requis.",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualPrerequisites.selected"           : "{count, plural, one {{count} qualification sélectionnée} other {{count} qualifications sélectionnées}}",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualPrerequisites.addGroup"           : "Ajouter un groupe",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualPrerequisites"                    : "Parce que vous avez choisi la qualification: {qualificationName}.",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualPrerequisites.hint.all"           : "Les préalables ci-dessous sont les préalables de base pour la qualification. Ceux-ci sont tous obligatoires pour l'inscription d'un participant.",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.qualPrerequisites.hint.one"           : "Les préalables ci-dessous sont les préalables de base pour la qualification. Au moins un parmis ceux-ci est obligatoire pour l'inscription d'un participant.",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites"                  : "Préalables de votre organisation",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.hint"             : "Nombre minimum de préalables obligatoire pour l'inscription d'un participant",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.hint.none1"       : "Vous pouvez ajouter des préalables provenant de votre organisation.",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.hint.none2"       : "Cliquez ici pour ajouter un préalable",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.hint.selectFirst" : "Veuillez d'abord sélectionner une qualification",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.select.all"       : "Tous obligatoires",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.clinicPrerequisites.select.one"       : "Au moins un obligatoire",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.prerequisites.description"            : "Description",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.prerequisites.organization"           : "Organisation",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.prerequisites.type"                   : "Type",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.moodle"                               : "Cours Moodle",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.passingGrade"                         : "Note de passage",

    // Add Prerequisite modal
    "clinics.clinicInformation.formClinicInformation.clinicInfo.addPrerequisites.title"       : "Ajouter un préalable",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.addPrerequisites.label"       : "Préalables",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.addPrerequisites.placeholder" : "Sélectionnez un préalable",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.addPrerequisites.required"    : "Le préalable est requis",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.addPrerequisites.noOptions"   : "Aucun un préalable",
    "clinics.clinicInformation.formClinicInformation.clinicInfo.addPrerequisites.helper"      : "Les préalables sont simplement des qualifications pouvant provenir de d'autres catégories.",

    // attendees
    "clinics.clinicInformation.formClinicInformation.attendees.title"                              : "Participants",
    "clinics.clinicInformation.formClinicInformation.attendees.types"                              : "Types de participants",
    "clinics.clinicInformation.formClinicInformation.attendees.types.placeholder"                  : "Sélectionnez un ou plusieurs types de participants",
    "clinics.clinicInformation.formClinicInformation.attendees.types.none"                         : "Aucun type de participant disponible. Veuillez ajouter un type de participant à votre organisation pour continuer.",
    "clinics.clinicInformation.formClinicInformation.attendees.types.validation.min"               : "Sélectionnez au moins un type de participant",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees"             : "Restrictions de participants",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.min"         : "Nombre minimum",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.min.max"     : "Le nombre minimum doit être inférieur ou égal au nombre maximum",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.min.min"     : "Le nombre minimum doit être d'au moins 1",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.max.already" : "Ce stage contient déjà {amount} participants",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.max"         : "Nombre maximum",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.max.min"     : "Le nombre maximum doit être supérieur ou égal au nombre minimum",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.attendees.waitingList" : "Le nombre maximum est requis si le stage possède une liste d'attente",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age"                   : "Restrictions d'âges",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age.min"               : "Âge minimum",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age.min.max.already"   : "Ce stage contient déjà un participant ayant {age} ans",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age.min.max"           : "L'âge minimum doit être inférieur ou égal à l'âge maximum",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age.min.min"           : "L'âge minimum doit être d'au moins 1",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age.max"               : "Âge maximum",
    "clinics.clinicInformation.formClinicInformation.attendees.requirements.age.max.min"           : "L'âge maximum doit être supérieur ou égal à l'âge minimum",
    "clinics.clinicInformation.formClinicInformation.attendees.waitingList"                        : "Liste d'attente",
    "clinics.clinicInformation.formClinicInformation.attendees.waitingList.helper"                 : "Les membres voulant participer à ce stage alors qu'il est déjà plein seront inscrits à une liste d'attente pour que vous puissiez les ajouter en tant que participant en cas de besoin.",
    "clinics.clinicInformation.formClinicInformation.attendees.criminalRecord"                     : "Vérification d'antécédents",
    "clinics.clinicInformation.formClinicInformation.attendees.criminalRecord.helper"              : "Vérification d'antédécents requis - Tous les participants doivent avoir une vérification d'antédécents valide avant de s'inscrire a une clinique. (Note : ceci s'applique pour les membres de 18 ans et plus)",

    // description
    "clinics.clinicInformation.formClinicInformation.description.title" : "Description",
}