import { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useParams } from 'react-router-dom';
import {
    Container,
    Spinner
} from "reactstrap";
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import TabRoutesNavs from '../../../components/subSidebar/TabRoutesNavs';
import TabRoutesPanes from '../../../components/subSidebar/TabRoutesPanes';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import MemberHeader from './profileHeader/MemberHeader';
import memberProfileMinimalTabsRoutes from '../../../routes/tabRoutes/memberProfileMinimalTabsRoutes';
import memberProfileTabsRoutes from '../../../routes/tabRoutes/memberProfileTabsRoutes';

// contexts
import { MembersContext } from '../../../contexts/MembersContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { QuickViewContext } from '../../../contexts/QuickViewContext';
import { AppContext } from '../../../contexts/contexts';
import { RolesContext } from './../../../contexts/RolesContext';


const MemberProfile = (props) => {

    // contexts
    const { updateRecentlyViewed } = useContext(AppContext);
    const quickViewContext = useContext(QuickViewContext);
    const membersContext = useContext(MembersContext);
    const orgContext = useContext(OrganizationContext)
    const rolesContext = useContext(RolesContext);
    const routeMatch = useRouteMatch();

    // Quick View ID will contain member id
    const { memberId } = useParams();

    // loading
    const [ overviewLoading, setOverviewLoading ] = useState(true);
    const [ headerLoading, setHeaderLoading ] = useState(false);

    const [ headerCardsState, updateHeaderCardsState ] = useState(false);
    const setHeaderCardsRefresh = () => {
        updateHeaderCardsState(!headerCardsState)
    }

    // To be used as a key, force refresh to trigger refresh on next table load in different tab. Used for "add regsitration" to update transactions tab
    const [ forceRegistrationRefresh, updateForceRegistrationRefresh ] = useState(false);
    const setForceRegistrationRefresh = () => {
        updateForceRegistrationRefresh(!forceRegistrationRefresh)
    }

    const [ forceTransactionRefresh, updateForceTransactionRefresh ] = useState(false);
    const setForceTransactionRefresh = () => {
        updateForceTransactionRefresh(!forceTransactionRefresh)
    }

    const [ forceWaiversRefresh, updateForceWaiversRefresh ] = useState(false);
    const setForceWaiversRefresh = () => {
        updateForceWaiversRefresh(!forceWaiversRefresh)
    }

    const canTransfer = membersContext.currentMember.active_transfer != '1';

    // used to refresh the transfer table after creating a usa / international transfer
    const [ transferKey, setTransferKey ] = useState(false);
    const refreshTransfers = () => setTransferKey(!transferKey)

    const getMemberProfileData = () => {
        return Promise.all([
            membersContext.emptyCachedInvoiceItems(),
            membersContext.getAllMemberInfos(memberId, {
                medicalInformation: true,
                memos: rolesContext.hasAccessTo('members', 'members_memo'),
                invoices: rolesContext.hasAccessTo('members', 'members_registrations') || rolesContext.hasAccessTo('members', 'member_transactions'),
                credits: rolesContext.hasAccessTo('members', 'member_credit'),
                externalIdentifiers: rolesContext.hasAccessTo('members', 'members_profile'),
            }, orgContext.organisation_id)
                .then((member) => {
                    quickViewContext.setTabCurrentMemberState(member);
                    updateRecentlyViewed({
                        label: `${member.first_name} ${member.last_name}`,
                        subtitle: member.unique_identifier ? `#${member.unique_identifier}` : false,
                        path: props.location.pathname,
                    });
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        // 3074 should be member not found
                        if(quickViewContext.inIframe && error.code == 3074){
                            quickViewContext.closeQuickViewTab(quickViewContext.activeTabState.tabIndex)
                        }else if(!quickViewContext.inIframe){
                            props.history.replace('/members/404');
                        }
                    }
                }),
        ])
    }

    const refreshMemberProfile = () => {
        getMemberProfileData();
        setForceRegistrationRefresh();
        setForceTransactionRefresh();
    }

    useEffect(() => {
        const shouldLoad = membersContext.currentMember?.member_id !== memberId;
        setOverviewLoading(shouldLoad)

        if(props.location?.data){ // if comes from search or another module that sends basic member info
            membersContext.presetCurrentMember(props.location.data);
        }else{
            setHeaderLoading(shouldLoad);
        }

        getMemberProfileData()
            .then(() => {
                setHeaderLoading(false);
                setOverviewLoading(false);
            })
    }, [ routeMatch.url, quickViewContext.iframeIsOpen ]);

    // If current url memberID matches current state member_id and we have a current member, then use the minimalistic_view or else force minimal view to prevent 403
    const minimalView = (memberId === membersContext.currentMember?.member_id) ? membersContext.currentMember.minimalistic_view : true;

    return (
        <div>
            {/*--------------------------------------------------------------------------------*/}
            {/* View Header                                                                  */}
            {/*--------------------------------------------------------------------------------*/}
            {(!quickViewContext.inIframe) &&
                <ViewHeaderV2
                    breadcrumbsProps={{
                        favoriteData: membersContext.currentMember ? {
                            label: `${membersContext.currentMember.first_name} ${membersContext.currentMember.last_name}`,
                            subtitle: membersContext.currentMember.unique_identifier ? `#${membersContext.currentMember.unique_identifier}` : false,
                            path: props.location.pathname,
                            translate: false,
                        } : {},
                    }}
                />
            }

            <div className="page-content container-fluid p-4">
                <Container>
                    {!quickViewContext.inIframe &&
                        <div className='p-1' />
                    }
                    <MemberHeader
                        inQuickView={quickViewContext.inIframe}
                        headerLoading={headerLoading}
                        minimalView={minimalView}
                        headerCardsState={headerCardsState}
                        setForceRegistrationRefresh={() => { setForceRegistrationRefresh(); setForceTransactionRefresh(); }}
                        refreshMemberProfile={refreshMemberProfile}
                    />

                    {/*--------------------------------------------------------------------------------*/}
                    {/* Nav Tabs                                                                  */}
                    {/*--------------------------------------------------------------------------------*/}

                    <TabRoutesNavs routes={minimalView ? memberProfileMinimalTabsRoutes : memberProfileTabsRoutes} />

                    {overviewLoading ?
                        <div className='w-100 text-center'><Spinner color='primary' type='grow' /></div>
                        :
                        <TabRoutesPanes
                            routes={minimalView ? memberProfileMinimalTabsRoutes : memberProfileTabsRoutes}
                            getComponentProps={(route) => {
                                switch (route.path){
                                    case '/overview':
                                        return { key: routeMatch.url, minimalView: minimalView, refreshMemberProfile: refreshMemberProfile };
                                    case '/registrations':
                                        return { key: routeMatch.url, setForceTransactionRefresh: () => setForceTransactionRefresh(), forceRefresh: forceRegistrationRefresh, setHeaderCardsRefresh: () => setHeaderCardsRefresh(), setForceWaiversRefresh: setForceWaiversRefresh };
                                    case '/transactions':
                                        return { key: routeMatch.url, forceRefresh: forceTransactionRefresh, setForceRegistrationRefresh: () => setForceRegistrationRefresh(), setHeaderCardsRefresh: () => setHeaderCardsRefresh() };
                                    case '/teams':
                                        return { key: routeMatch.url, memberId: memberId };
                                    case '/qualifications':
                                        return { key: routeMatch.url };
                                    case '/history':
                                        return { key: routeMatch.url, canTransfer: canTransfer, transferKey: transferKey };
                                    case '/usa':
                                        return { key: routeMatch.url, memberId: memberId, refreshTransfers: refreshTransfers };
                                    case '/international':
                                        return { key: routeMatch.url, international: true, memberId: memberId, refreshTransfers: refreshTransfers };
                                    case '/cti':
                                        return { key: routeMatch.url, memberId: memberId, refreshTransfers: refreshTransfers };
                                    case '/invoice':
                                        return { key: routeMatch.url, invoiceNb: props.location.state?.invoice_number };
                                    case '/receipt':
                                        return { key: routeMatch.url, invoiceNb: props.location.state?.invoice_number };
                                    case '/waivers':
                                        return { key: routeMatch.url, memberId: memberId, forceWaiversRefresh: forceWaiversRefresh, refreshMemberProfile: refreshMemberProfile };
                                    default:
                                        break;
                                }
                            }}
                        />
                    }
                </Container>
            </div>
        </div>
    );
}

export default MemberProfile;