export default{
    "tasks.memberDuplicates.title"                             : "Membres potentiellement dupliqués",
    "tasks.memberDuplicate"                                    : "Potentiellement dupliqué",
    "tasks.memberDuplicates.manage.title"                      : "Gérer les membres potentiellement dupliqués",
    "tasks.memberDuplicates.potentialDuplicates"               : "Duplications potentielles",
    "tasks.memberDuplicates.potentialDuplicates.count"         : "{count, plural, =1 {Potentiellement dupliqué (#)} other {Potentiellement dupliqués (#)}}",
    "tasks.memberDuplicates.potentialDuplicates.match"         : "Match",
    "tasks.memberDuplicates.potentialDuplicates.match.EXACT"   : "Exact",
    "tasks.memberDuplicates.potentialDuplicates.match.PARTIAL" : "Partiel",
    "tasks.memberDuplicates.potentialDuplicates.match.FUZZY"   : "Semblable",
    "tasks.memberDuplicates.lastSubmitted"                     : "Dernier ajout",
    "tasks.memberDuplicates.duplicates"                        : "Duplications",

    "tasks.memberDuplicates.manage"               : "Gérer",
    "tasks.memberDuplicates.manage.step1"         : "Sélectionnez le profil qui sera conservé.",
    "tasks.memberDuplicates.manage.step2"         : "Sélectionnez quels membres seront fusionnés et lesquels seront ignorés.",
    "tasks.memberDuplicates.manage.missingKeep"   : "Veuillez spécifier si quel profil doit être conservé.",
    "tasks.memberDuplicates.manage.missingChoice" : "Veuillez spécifier si ce profil est dupliqué ou si ce profil sera ignoré.",

    "tasks.memberDuplicates.manage.mergedMember"       : "Membre(s) fusionné(s)",
    "tasks.memberDuplicates.manage.mergedMember.error" : "Fusion impossible",
    "tasks.memberDuplicates.manage.noMergedMember"     : "Aucun membre n'a été fusionné avec ce profil.",

    "tasks.memberDuplicates.manage.ignoredMember"       : "Membre(s) ignoré(s)",
    "tasks.memberDuplicates.manage.ignoredMember.error" : "N'a pas pu être ignoré",

    "tasks.memberDuplicates.manage.keep"   : "Garder",
    "tasks.memberDuplicates.manage.merge"  : "Fusionner",
    "tasks.memberDuplicates.manage.ignore" : "Ignorer",
}