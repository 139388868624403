import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate from "@spordle/intl-elements";
import React from "react";
import {
    Badge,
    Button,
    Card
} from "reactstrap";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import SpordlePanelTable from "../../../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../../../components/table/EmptyLayout";
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import MemberTypesAddModal from './components/MemberTypesAddModal';
import { memberTypeSpecial } from './components/memberTypesConstants';
// Contexts
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import I18nHelperContextProvider, { I18nHelperContext, DisplayI18n } from '../../../../../../helpers/i18nHelper';
import withContexts from "../../../../../../helpers/withContexts";
import SidePanelOrganizationMemberTypes from './SidePanelOrganizationMemberTypes';

class OrganizationMemberTypes extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            addMemberTypeModalOpen: false,
        }
    }

    toggleAddMemberType = () => {
        this.setState((prevState) => ({ addMemberTypeModalOpen: !prevState.addMemberTypeModalOpen }));
    }

    // addMemberType = (values) => {
    //     return this.props.MembersContext.createMemberType(this.props.OrganizationContext.organisation_id, { ...values, active: values.status === 'active' }, this.i18n)
    //         .then((data) => {
    //             const returnData = {
    //                 member_type_id: data,
    //                 ...formatI18nForTable(this.i18n.languages, 'name', values),
    //                 active: values.status === 'active' ? '1' : '0',
    //             }
    //             this.memberTypesTableRef.addRow(returnData)
    //         })
    //         .catch((error) => {
    //             if(!AxiosIsCancelled(error.message)){
    //                 console.error(error.message)
    //                 fail({
    //                     msg: 'misc.error',
    //                     info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
    //                     skipInfoTranslate: true,
    //                 })
    //             }
    //         })
    // }

    render(){
        return (
            <div>
                <I18nHelperContextProvider fields={[ 'name' ]}>
                    <I18nHelperContext.Consumer>
                        {(i18nHelper) => (
                            <SpordlePanelTable
                                allowOutsideClick
                                sidePanel={(props) => <SidePanelOrganizationMemberTypes {...props} tableRef={this.memberTypesTableRef} />}
                                dataIndex='member_type_id'
                                table={(panelProps) => {
                                    return (
                                        <Card body className="card-shadow">
                                            <CardSectionTitle title='settings.tabs.memberTypes' />
                                            <SpordleTableProvider
                                                id='OrganizationMemberTypesTable'
                                                tableHover clickable striped
                                                bordered
                                                ref={(r) => { this.memberTypesTableRef = r; panelProps.spordleTableRef(r); }}
                                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                                dataIndex='member_type_id'
                                                searchKeys={[
                                                    `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                    'name',
                                                ]}
                                                columns={[
                                                    ...i18nHelper.getTableColumns('name'),
                                                    {
                                                        label: <Translate id='organization.settings.organizationMemberTypes.table.type' />,
                                                        key: "type",
                                                        mobile: true,
                                                        sortable: true,
                                                        dataClassName: 'td-shrink text-center',
                                                    },
                                                    {
                                                        label: <Translate id='organization.settings.organizationMemberTypes.table.active' />,
                                                        key: "active",
                                                        mobile: true,
                                                        sortable: true,
                                                    },
                                                ]}
                                                onColumnClick={(e, memberType) => {
                                                    switch (e.button){
                                                        case 0: // Left mouse button
                                                            panelProps.onSingleSelectChange(memberType);
                                                            break;
                                                    }
                                                }}
                                                desktopWhen='md'
                                                emptyLayout={<EmptyLayout translateTitle title='organization.settings.organizationMemberTypes.table.empty' />}
                                                renderRow={(columnKey, memberType, spordleTable) => {
                                                    if(columnKey.includes('i18n')){
                                                        return (
                                                            <DisplayI18n
                                                                field={columnKey}
                                                                defaultValue={memberType.name}
                                                                i18n={memberType.i18n}
                                                            />
                                                        )
                                                    }
                                                    switch (columnKey){
                                                        case 'type':
                                                            if(memberType.system_type === memberTypeSpecial){
                                                                return (
                                                                    <Badge color='info'><Translate id={'organization.settings.organizationMemberTypes.table.type.' + memberTypeSpecial} /></Badge>
                                                                )
                                                            }
                                                            return '-'
                                                        case 'active':
                                                            return (
                                                                <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                                    {memberType.active == '1' ?
                                                                        <i className="text-primary mdi mdi-check" />
                                                                        :
                                                                        <i className="text-danger mdi mdi-close" />
                                                                    }
                                                                </div>
                                                            )
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                rowIsHighlighted={(memberType) => !!memberType.checked}
                                                loadData={(from, filterData, spordleTable) => {
                                                    switch (from){
                                                        case 'REFRESH':
                                                            spordleTable.setLoading();
                                                        case 'CDM':
                                                            return this.props.MembersContext.getMemberTypes(this.props.OrganizationContext.organisation_id)
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            >
                                                {(spordleTable) => (
                                                    <>
                                                        <MemberTypesAddModal
                                                            isOpen={this.state.addMemberTypeModalOpen}
                                                            toggle={this.toggleAddMemberType}
                                                            refreshTable={spordleTable.refreshTable}
                                                        />
                                                        <TopSection toggleAddMemberType={this.toggleAddMemberType} panelProps={panelProps} />
                                                        <SpordleTableView />
                                                    </>
                                                )}
                                            </SpordleTableProvider>
                                        </Card>
                                    )
                                }}
                            />
                        )}
                    </I18nHelperContext.Consumer>
                </I18nHelperContextProvider>
            </div>
        )
    }
}

const TopSection = (props) => {
    return (
        <div className="mb-2">
            <div className='d-flex justify-content-between'>
                <SpordleTableProvider.SearchInput />
                <div className='d-flex ml-auto text-right'>
                    <SpordleTableProvider.Refresh />
                    <CanDoAction action='ADD' componentCode='members' componentPermissionCode='member_types'>
                        <Button color='primary' className='ml-2' onClick={props.toggleAddMemberType}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                    </CanDoAction>
                </div>
            </div>
        </div>
    )
}

export default withContexts(MembersContext, OrganizationContext, I18nContext)(OrganizationMemberTypes);