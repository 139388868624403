import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { fail } from "@spordle/toasts";
import { useContext, useState } from "react";
import { Badge, Button, Fade, Collapse } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import MainPhoto from "../../../../../components/MainPhoto";
import { AuthContext } from "../../../../../contexts/contexts";
import { NotificationsContext } from "../../../../../contexts/NotificationsContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { LOCALES } from "../../../../../i18n";
import { I18nContext } from "../../../../../contexts/I18nContext";
import LinksAndSignout from "./LinksAndSignout";
import MobileNotifications from "./MobileNotifications";
import MobileNotificationsSettings from "./MobileNotificationsSettings";
import MobileFooterMenu from "../menu/MobileFooterMenu";

const MobileBottomProfileMenu = ({ handleToggle, isOpened }) => {
    const authContext = useContext(AuthContext);
    const notificationsContext = useContext(NotificationsContext);

    const [ activeStep, setActiveStep ] = useState('menu');

    const handleOnToggle = () => {
        handleToggle();
        setActiveStep('menu')
    }

    const isInSubStep = activeStep !== "menu";

    return (
        <div className={stringBuilder("sid-mobile-footer-item-inner", { "is-open": isOpened })} tabIndex="-1">
            {/* icon inside bottom menu (toggler) */}
            <div
                className="position-relative"
                onClick={() => handleOnToggle()}
            >
                <MainPhoto width={30} />
                { (notificationsContext.state.notificationsCount > 0) &&
                    <Badge className="sid-mobile-footer-notif" pill>
                        {notificationsContext.state.notificationsCount > 9 ? '9+' : notificationsContext.state.notificationsCount}
                    </Badge>
                }
            </div>

            {/* profile menu */}
            <MobileFooterMenu
                isOpened={isOpened}
                handleToggle={handleOnToggle}
            >
                <div className="d-flex flex-column h-100">
                    {/* account info section */}
                    <div className={stringBuilder("sid-mobile-footer-menu-subitem position-relative transition flex-wrap", isInSubStep ? "mt-2 py-3" : "mt-5")}>
                        <div
                            className={stringBuilder("sid-mobile-footer-menu-photo-container", { "is-smaller": isInSubStep })}
                        >
                            <MainPhoto
                                width={76}
                                className='sid-mobile-footer-menu-photo transition'
                            />
                        </div>
                        <div className="position-absolute top-0 right-0 p-3 d-flex align-items-center">
                            <I18nContext.Consumer>
                                {(i18n) => {
                                    const { locale, setLocale } = i18n;

                                    return (
                                        <div
                                            className="text-body"
                                            onClick={() => {
                                                if(locale === 'fr-ca'){
                                                    setLocale(LOCALES.ENGLISH);
                                                    authContext.updateUserInfo({
                                                        ...authContext.account,
                                                        locale: 'en_US',
                                                        language_code: 'en',
                                                    }).catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                }else{
                                                    setLocale(LOCALES.FRENCH)
                                                    authContext.updateUserInfo({
                                                        ...authContext.account,
                                                        locale: 'fr_CA',
                                                        language_code: 'fr',
                                                    }).catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                }
                                            }}
                                        >
                                            <span className="font-medium font-16">{locale === 'fr-ca' ? 'EN' : 'FR'}</span>
                                        </div>
                                    )
                                }}
                            </I18nContext.Consumer>

                            <ConfirmModal
                                toggler={(toggle) => (
                                    <Button
                                        color='none' className="reset-btn text-danger"
                                        type='button'
                                        onClick={toggle}
                                    >
                                        <i className="mdi mdi-logout font-20 ml-4" />
                                    </Button>
                                )}
                                modalTitle='header.signout.confirm'
                                translateModalTitle
                                actionButtonText='header.signout'
                                translateActionButtonText
                                onConfirm={() => authContext.logout().catch(console.error)}
                            />
                        </div>
                        <div style={{ marginLeft: isInSubStep ? 76 / 2 : 0 }} className={stringBuilder("font-bold text-dark h5 mb-0 w-100 transition", isInSubStep ? "mt-0 pl-2" : "mt-4")}>
                            {authContext.account.name} {authContext.account.family_name}
                        </div>
                        <div style={{ marginLeft: isInSubStep ? 76 / 2 : 0 }} className={stringBuilder("transition small text-muted w-100", { "pl-2": isInSubStep })}>
                            {authContext.account.email}
                        </div>

                        {/* Go back button */}
                        <Collapse isOpen={isInSubStep}>
                            <Fade in={isInSubStep} style={{ marginLeft: 76 / 2 }}>
                                <Button
                                    className='reset-btn ml-2'
                                    color='none'
                                    onClick={() => {
                                        if(activeStep === 'notifications')
                                            setActiveStep('menu')
                                        else if(activeStep === 'settings')
                                            setActiveStep('notifications')
                                    }}
                                >
                                    <span className="d-flex align-items-center small text-primary">
                                        <i className="mr-1 mdi mdi-arrow-left" />
                                        <Translate id='header.notifications.goBack' />
                                    </span>
                                </Button>
                            </Fade>
                        </Collapse>
                    </div>

                    {/* Main menu */}
                    <Fade in={activeStep === 'menu'} unmountOnExit className="d-flex flex-column h-100">
                        <LinksAndSignout setActiveStep={setActiveStep} handleOnToggle={handleOnToggle} />
                    </Fade>

                    {/* Notifications */}
                    <Fade
                        in={activeStep === 'notifications'}
                        className='d-flex flex-column flex-grow-1 text-body sid-mobile-footer-menu-subitem min-h-0 mb-0 px-0'
                        unmountOnExit
                    >
                        <MobileNotifications setActiveStep={setActiveStep} handleOnToggle={handleOnToggle} menuIsOpen={isOpened} />
                    </Fade>

                    {/* Notifications settings */}
                    <Fade
                        in={activeStep === 'settings'}
                        className='d-flex flex-column h-100 text-body sid-mobile-footer-menu-subitem min-h-0 mb-0'
                        unmountOnExit
                    >
                        <MobileNotificationsSettings />
                    </Fade>
                </div>
            </MobileFooterMenu>
        </div>
    );
}

export default MobileBottomProfileMenu;