export default{
    "members.search.memberMerge.btn.label"                : "Merge Member",
    "members.search.block.btn.label"                      : "Block Member",
    "members.search.reactivate.btn.label"                 : "Reactivate Member",
    "members.search.confirm.btn.label"                    : "Confirm Member",
    "members.search.blockMembers.btn.label"               : "Block members",
    "members.search.ineligible.btn.label"                 : "Make Member Permanently Ineligible",
    "members.search.remove.ineligible.btn.label"          : "Remove Ineligibility",
    "members.search.memberMerge.merge.action"             : "Merge",
    "members.search.memberMerge.merge.success"            : "Members have been successfully merged!",
    "members.search.memberMerge.merge.selected"           : "Selected",
    "members.search.memberMerge.merge.toKeep"             : "Member to keep",
    "members.search.memberMerge.merge.toDelete"           : "Member to delete",
    "members.search.memberMerge.merge.alreadySelect"      : "Already Selected",
    "members.search.memberMerge.merge.missingPermissions" : "Missing permission to merge this member.",
    "members.search.register.btn.label"                   : "Register",

    "members.search.memberMerge.modal.title"       : "Merge members",
    "members.search.memberMerge.modal.keep"        : "Keep",
    "members.search.memberMerge.modal.confirm"     : "Please confirm",
    "members.search.memberMerge.modal.final"       : "Final result",
    "members.search.memberMerge.modal.deleted"     : "We will remove {name} from the system and you will no longer have access to the profile.",
    "members.search.memberMerge.modal.picture"     : "The system will automatically choose the most recent profile picture out of the two and apply it to the kept profile.",
    "members.search.memberMerge.modal.mergeFromTo" : "{from} <span>#{fromNb}</span> infos will <span>merge</span> with {to} <span>#{toNb}</span> existing infos.",
}