// /* eslint-disable react/no-unused-class-component-methods */
import queryString from 'query-string';
import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';

/** @type {React.Context<Omit<OfficiatingCoachContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & OfficiatingCoachContextProvider['state']>} */
export const OfficiatingCoachContext = createContext();
OfficiatingCoachContext.displayName = 'OfficiatingCoachContext';

class OfficiatingCoachContextProvider extends React.PureComponent{
    /**
     * [GET] Get all member officiating coach for a member
     * @param {string} memberId
     * @param {object} [queryParams={}]
     * @returns {Promise<object[]>}
     * @see Refer to the {@see https://api.id.dev.spordle.dev/documentations/#/Member%20Officiating%20Coach/d39c6bc0e367cca567d3efbe8e74bfc9|Documentation}
     * @throws {object}
     */
    getMemberOfficiatingCoachList = (memberId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/officiating-coach`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {

                if(res.data.status){
                    return res.data.member_officiating_coaches
                }

                throw res.data.errors[0]
            }, serverError)
    }

    getAllOfficiatingCoaches = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/member-officiating-coaches`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {

                if(res.data.status){
                    return res.data.officiating_coaches;
                }

                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [GET] Get specific member officiating coach
     * @param {string} memberId
     * @param {string} officiatingCoachId
     * @returns {Promise}
     * @see Refer to the {@see https://api.id.dev.spordle.dev/documentations/#/Member%20Officiating%20Coach/d39c6bc0e367cca567d3efbe8e74bfc9|Documentation}
     */
    getMemberOfficiatingCoach = (memberId, officiatingCoachId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/members/${memberId}/officiating-coach/${officiatingCoachId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {

                if(res.data.status){
                    return res.data.member_officiating_coaches
                }

                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [POST] Update a member officiating coach
     * @param {string} memberId
     * @param {object} queryParams
     * @param {string} queryParams.period_id
     * @param {1|0} [queryParams.national_officiating_coach]
     * @param {1|0} [queryParams.active]
     * @param {string} queryParams.supervision_type_ids
     * @returns {Promise}
     * @see Refer to the {@see https://api.id.dev.spordle.dev/documentations/#/Member%20Officiating%20Coach/8533ec5b038801c491f4698edddeb834|Documentation}
     */
    createMemberOfficiatingCoach = (memberId, queryParams = {}) => {
        const params = new URLSearchParams();

        params.append('period_id', queryParams.period_id);

        if(queryParams.national_officiating_coach){
            params.append('national_officiating_coach', queryParams.national_officiating_coach);
        }

        if(queryParams.active){
            params.append('active', queryParams.active)
        }

        for(let i = 0; i < queryParams.supervision_type_ids.length; i++){
            params.append(`supervision_type_ids[${i}]`, queryParams.supervision_type_ids[i])
        }


        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/members/${memberId}/officiating-coach`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }), params)
            .then((res) => {

                if(res.data.status){
                    return res.data.member_officiating_coach_id
                }

                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [PUT] Update a member officiating coach
     * @param {string} memberId
     * @param {string} officiatingCoachId
     * @param {object} queryParams
     * @param {1|0} [queryParams.national_officiating_coach]
     * @param {1|0} [queryParams.active]
     * @param {string} queryParams.supervision_type_ids
     * @returns {Promise}
     * @see Refer to the {@see https://api.id.dev.spordle.dev/documentations/#/Member%20Officiating%20Coach/3a54521833513d58d765d1852284b9a5|Documentation}
     */
    updateMemberOfficiatingCoach = (memberId, officiatingCoachId, queryParams = {}) => {
        const params = new URLSearchParams();

        if(queryParams.national_officiating_coach){
            params.append('national_officiating_coach', queryParams.national_officiating_coach);
        }

        if(queryParams.active){
            params.append('active', queryParams.active)
        }

        for(let i = 0; i < queryParams.supervision_type_ids.length; i++){
            params.append(`supervision_type_ids[${i}]`, queryParams.supervision_type_ids[i])
        }


        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/members/${memberId}/officiating-coach/${officiatingCoachId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }), params)
            .then((res) => {

                if(res.data.status){
                    return true
                }

                throw res.data.errors[0]
            }, serverError)
    }

    /**
     * [DELETE] Delete a member officiating coach
     * @param {string} memberId
     * @param {string} officiatingCoachId
     * @returns {Promise}
     * @see Refer to the {@see https://api.id.dev.spordle.dev/documentations/#/Member%20Officiating%20Coach/078bd645dbc0b2295e6366bb03ea3b95|Documentation}
     */
    deleteMemberOfficiatingCoach = (memberId, officiatingCoachId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/members/${memberId}/officiating-coach/${officiatingCoachId}`,
        }))
            .then((res) => {

                if(res.data.status){
                    return true
                }

                throw res.data.errors[0]
            }, serverError)
    }

    render(){
        return (
            <OfficiatingCoachContext.Provider value={{ ...this.state, ...this }}>
                {this.props.children}
            </OfficiatingCoachContext.Provider>
        );
    }
}

export default OfficiatingCoachContextProvider;
