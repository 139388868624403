import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Button, Label } from "reactstrap";
import { SendCommModalContext } from "../../../../../context/SendCommModalContext";
import { HoverableCopy } from "../../../../../../../helpers/clipboardHelper";
import SearchMemberForm from "../../../../../../searchMemberForm/SearchMemberForm";
import UserDisplay from "../../../../../../userDisplay/UserDisplay";
import UserImg from "../../../../../../UserImg";

const SearchRecipients = ({ onPrevious, addRecipient }) => {
    const { recipients: recipientsData, isLoading, setIsLoading } = useContext(SendCommModalContext);
    const recipients = recipientsData.all;

    const handleOnAdd = (member) => {
        return () => {
            addRecipient(member);
        }
    }

    return (
        <>
            <Label className="h5 mx-3 mt-3 pt-3 border-top w-100"><Translate id="components.communications.modal.label.searchAndAdd" /></Label>
            <SearchMemberForm
                onPrevious={onPrevious}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                memberComponent={({ member }) => {
                    const isAlreadyAdded = recipients.some((recipient) => recipient.member_id === member.member_id);

                    return (
                        <UserDisplay className="mb-2" card block>
                            <UserDisplay.Container>
                                <UserImg
                                    width={40}
                                    src={null}
                                    alt={`${member.first_name} ${member.last_name}`}
                                    abbr={`${member.first_name.charAt(0)}${member.last_name.charAt(0)}`}
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container className="mr-2 min-w-0">
                                <UserDisplay.Title>{member.first_name} {member.last_name}</UserDisplay.Title>
                                {member.unique_identifier &&
                                    <UserDisplay.Subtitle>
                                        <HoverableCopy toCopy={member.unique_identifier}>
                                            #{member.unique_identifier}
                                        </HoverableCopy>
                                    </UserDisplay.Subtitle>
                                }
                                <UserDisplay.Subtitle className="text-truncate">{member.email}</UserDisplay.Subtitle>
                            </UserDisplay.Container>
                            <UserDisplay.Container className="ml-auto text-nowrap">
                                <Button disabled={isAlreadyAdded} color="primary" type="button" onClick={handleOnAdd(member)}>
                                    {isAlreadyAdded ? <Translate id="components.communications.modal.btn.exists" /> : <Translate id="misc.add" />}
                                </Button>
                            </UserDisplay.Container>
                        </UserDisplay>
                    )
                }}
            />
        </>
    );
}

export default SearchRecipients;