export default{
    "clinics.clinicCommunications.clinicCommunications.title" : "Communications",

    // registration
    "clinics.clinicCommunications.clinicCommunications.registration.title"                  : "Confirmation d'inscription",
    "clinics.clinicCommunications.clinicCommunications.registration.attachments"            : "Pièce(s) jointe(s)",
    "clinics.clinicCommunications.clinicCommunications.registration.attachments.comingSoon" : "Module en développement",

    // confirmation
    "clinics.clinicCommunications.clinicCommunications.confirmation.title"       : "Courriel de confirmation",
    "clinics.clinicCommunications.clinicCommunications.confirmation.attachments" : "Pièce(s) jointe(s)",

    // forms
    "clinics.clinicCommunications.clinicCommunications.forms.title"                 : "Questionnaire",
    "clinics.clinicCommunications.clinicCommunications.forms.noForms"               : "Aucun questionnaire associer avec ce stage",
    "clinics.clinicCommunications.clinicCommunications.terms.noTerms"               : "Il n'y a pas de conditions de paiement associés à ce stage",
    "clinics.clinicCommunications.clinicCommunications.waivers.noWaivers"           : "Il n'y a pas de dispense associée à ce stage",
    "clinics.clinicCommunications.clinicCommunications.waivers.noConfirmation"      : "Il n'y a pas de confirmation d'inscription associée à cette clinique",
    "clinics.clinicCommunications.clinicCommunications.waivers.noConfirmationEmail" : "Il n'y a pas de courriel de confirmation associé à cette clinique",
}