import { Button, Col, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AnalyticsModal from "../../../analytics/AnalyticsModal";
import FormikModalForm from "../../../components/formik/FormikModalForm";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { DisplayI18n, displayI18n } from "../../../helpers/i18nHelper";
import DisplayMemberTypeStatus from "../../../components/display/DisplayMemberTypeStatus";
import { FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import { getMemberTypeStatuses, updateMemberTypeApprobations } from "../../../api/client/memberTypeValidation";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import { object, string } from "yup";
import { I18nContext } from "../../../contexts/I18nContext";
import { useContext } from "react";
import Required from "../../../components/formik/Required";
import FormikApiError from "../../../components/formik/FormikApiError";
import ApiErrorMsg from "../../../components/formik/ApiErrorMsg";
import DisplayMember from "../../../components/display/DisplayMember";
import CollapsibleCard from "../../../components/collapsibleCard/CollapsibleCard";
import DisplayMemberTypeStatusIcon from "../../../components/display/DisplayMemberTypeStatusIcon";
import DisplayMemberType from "../../../components/display/DisplayMemberType";
import CRCStatus from "../profile/criminalRecordCheck/components/CRCStatus";

const UpdateMemberTypeStatusModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName="updateMemberTypeStatusModal">
            <UpdateMemberTypeModalInner {...props} />
        </AnalyticsModal>
    );
}

const UpdateMemberTypeModalInner = ({ toggle, members = [], onSuccess }) => {
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <FormikModalForm
            toggle={toggle}
            initialValues={{
                member_type_status_id: '',
                member_type_status: null,
                decision_note: '',
                members: members,
            }}
            validationSchema={object().shape({
                member_type_status_id: string().required(<Translate id="form.validation.status.required" />),
                decision_note: string().max(255, <Translate id="form.validation.string.max" values={{ count: 255 }} />),
            })}
            onSubmit={(values, { setStatus }) => {
                const approbations = members.reduce((newArray, { member_types }) => {
                    member_types.map(({ member_member_type_id }) => {
                        newArray.push({
                            member_member_type_id,
                            decision_note: values.decision_note,
                            member_type_status_id: values.member_type_status_id,
                        })
                    })
                    return newArray
                }, [])

                return updateMemberTypeApprobations({
                    member_member_type_statuses: approbations,
                })
                    .then(() => {
                        setStatus({ isSuccess: true });
                        onSuccess?.();
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            setStatus({
                                isSuccess: false,
                                showError: true,
                                error: Array.isArray(e) ? e : <ApiErrorMsg error={e} />,
                            });
                        }
                    })
            }}
            successText={(formik) => (
                <>
                    {formik.values.members.map((member) => (
                        <DisplayMember card member={member} key={member.member_id} className="flex-wrap">
                            <div className="mt-2 pt-2 border-top w-100">
                                <ul className="pl-3 mb-0">
                                    {member.member_types.map((memberType) => (
                                        <li key={memberType.member_type_id}>
                                            <DisplayI18n field='name' defaultValue={memberType.member_type.name} i18n={memberType.member_type.i18n} />
                                            <DisplayMemberTypeStatus className="ml-2" status={memberType.member_type_status} />
                                            <span className="px-2">→</span>
                                            <DisplayMemberTypeStatus status={formik.values.member_type_status} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </DisplayMember>
                    ))}
                    {formik.values.decision_note &&
                        <div className="bg-light-inverse p-2 border rounded mb-3">{formik.values.decision_note}</div>
                    }
                </>
            )}
        >
            {(formik) => (
                <>
                    <ModalHeader toggle={toggle}><Translate id="members.profile.memberType.changeStatus" /></ModalHeader>
                    <ModalBody>
                        {members.map((member) => (
                            <CollapsibleCard
                                key={member.member_id}
                                arrowIsRight
                                initOpen={members.length == 1}
                                title={
                                    <DisplayMember member={member} className="flex-wrap" card={false} />
                                }
                                className="mb-3"
                            >
                                <Row form>
                                    <Col sm='6'>
                                        <div className="text-muted mb-2"><Translate id="members.profile.memberType.toApprove" /></div>
                                        {(member.member_types || []).map((memberType) => (
                                            <div key={memberType.member_type_id}>
                                                <DisplayMemberType
                                                    member={member}
                                                    memberType={memberType}
                                                    hideTooltip
                                                />
                                            </div>
                                        ))}
                                    </Col>
                                    {member.criminal_record_check_status &&
                                        <Col sm='6'>
                                            <div className="text-muted mb-2"><Translate id="tasks.memberTypeValidation.columns.crc" /></div>
                                            <CRCStatus crc={member} className='mb-1' />
                                            <div className="small">
                                                <span className="text-muted"><Translate id='tasks.memberTypeValidation.columns.crc.date' />:</span>
                                                <span className="font-medium text-dark ml-1"><DateFormat value={member.issue_date} /></span>
                                            </div>
                                            <div className="small">
                                                <span className="text-muted"><Translate id='tasks.memberTypeValidation.columns.crc.expiry' />:</span>
                                                <span className="font-medium text-dark ml-1"><DateFormat value={member.expiration_date} /></span>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </CollapsibleCard>
                        ))}
                        <Label for="member-type-status" className="text-muted"><Translate id="misc.status" /> <Required /></Label>
                        <FormikSelect
                            id="member-type-status"
                            name="member_type_status_id"
                            renderOption={({ option }) => (
                                <>
                                    <DisplayMemberTypeStatusIcon status={option} className="mr-2" />
                                    <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />
                                </>
                            )}
                            searchKeys={[
                                `i18n.${getGenericLocale()}.name`,
                            ]}
                            onOptionSelected={([ val ], select) => {
                                const data = select.getSpordleTable().getData() || [];
                                const status = data.find(({ value }) => value === val);
                                formik.setFieldValue('member_type_status', status);
                            }}
                            loadData={(from) => {
                                switch (from){
                                    case 'CDM':
                                        return getMemberTypeStatuses()
                                            .then((statuses) => {
                                                const collator = new Intl.Collator(getGenericLocale());

                                                return statuses.map((status) => ({
                                                    ...status,
                                                    value: status.member_type_status_id,
                                                    label: status.name,
                                                }))
                                                    .sort((a, b) => {
                                                        const nameA = displayI18n('name', a.i18n, a.name, getGenericLocale());
                                                        const nameB = displayI18n('name', b.i18n, b.name, getGenericLocale());

                                                        return collator.compare(nameA, nameB);
                                                    })
                                            });
                                    default:
                                        break;
                                }
                            }}
                        />
                        <Label for="decision_note" className="text-muted mt-3">Note</Label>
                        <FormikTextArea id="member-type-approve" name="decision_note" maxLength={255} />
                        <FormikApiError />
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={formik.isSubmitting} type="submit" color="primary">
                            <Translate id="misc.submit" />
                        </Button>
                        <Button disabled={formik.isSubmitting} outline type="button" onClick={toggle} color="primary">
                            <Translate id="misc.cancel" />
                        </Button>
                    </ModalFooter>
                </>
            )}
        </FormikModalForm>
    )
}

export default UpdateMemberTypeStatusModal;