import { stringBuilder } from "@spordle/helpers";
import styles from "./Popover.module.scss";

const Popover = ({ children, tip, toggle, isOpen }) => {
    return (
        <div className={styles.Container}>
            {children}
            <div className={stringBuilder(styles.PopContainer, { [styles.isOpen]: isOpen })}>
                {toggle && <button type="button" onClick={toggle} className={stringBuilder("mdi mdi-close font-16 p-3 reset-btn", styles.Close)} />}
                {tip}
            </div>
        </div>
    );
}

export default Popover;