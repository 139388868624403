export default{
    "organization.changeOrganization.title"             : "Select an Organization",
    "organization.changeOrganization.header.title"      : "Change Organization",
    "organization.changeOrganization.manageOrg"         : "Manage Organization",
    "organization.changeOrganization.swal.confirmation" : "Would you like to manage",
    "organization.changeOrganization.swal.Ok"           : "Manage",
    "organization.changeOrganization.swal.Cancel"       : "Cancel",
    "organization.changeOrganization.toast.text"        : "You are now managing {name}",
    "organization.changeOrganization.recent"            : "Recent Organizations",
    "organization.changeOrganization.lastManaged"       : "Last Managed",

    "organization.changeOrganization.selectRole"       : "Select Role",
    "organization.changeOrganization.changeRole"       : "Change Role",
    "organization.changeOrganization.changeRole.toast" : "{from} → {name}",
}