import { useSpordleTable } from '@spordle/datatables';
import { FormikCheckedButton, FormikCurrencyInput, FormikDateTime, FormikError, FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { Form, Formik, useFormikContext } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import {
    Alert,
    Button, Col, Collapse, FormGroup, FormText, Label, ModalBody, ModalFooter, ModalHeader, Row, Spinner
} from "reactstrap";
import { array, bool, boolean, mixed, number, object, string } from 'yup';
import AnalyticsModal from '../../../../../analytics/AnalyticsModal';
import Required from '../../../../../components/formik/Required';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { fail, success } from '@spordle/toasts';
import { I18nContext } from '../../../../../contexts/I18nContext';
// Context
import { RegistrationFeeContext } from '../../../../../contexts/RegistrationFeeContext';
import { DisplayI18n, displayI18n, I18nHelperContext, RenderI18nForm } from '../../../../../helpers/i18nHelper';
import CustomAlert from '../../../../../components/CustomAlert';
import { Tooltip } from '@mantine/core';
import { PeriodsContext } from '../../../../../contexts/contexts';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { getAdditionalFieldsInitialValues, getAdditionalFieldsRender, getAdditionalFieldsValidationSchema, getFormattedAdditionalFields } from '../../../../../helpers/additionalFieldsHelpers';
import { debounce } from '@spordle/helpers';
import { UtilsContext } from '../../../../../contexts/UtilsContext';
import { RolesContext } from '../../../../../contexts/RolesContext';
import { RegistrationDivisionsContext } from '../../../../../contexts/RegistrationDivisionsContext';
import { MerchantAccountContext } from '../../../../../contexts/MerchantAccountContext';
import RegistrationFeeCategorySelect from './RegistrationFeeCategorySelect';
import RegistrationFeeDivisionSelect from './RegistrationFeeDivisionSelect';
import Optional from '../../../../../components/formik/Optional';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';

const RegistrationFeesAdd = ({ duplicateRegistration, waivers, installments, forms, categories, memberTypes, isOpen, toggle, setDuplicateRegistration }) => {
    const [ isDuplicateMode, setDuplicateMode ] = useState(false);
    const [ memberTypeId, setMemberTypeId ] = useState(duplicateRegistration.member_type?.member_type_id || memberTypes?.[0]?.member_type_id);
    const [ divisions, setDivisions ] = useState(false);
    const [ selectedDivision, setSelectedDivision ] = useState(null);
    const [ selectedDivisionFees, setSelectedDivisionFees ] = useState({
        amount: 0,
        early_amount: 0,
        late_amount: 0,
    });
    const [ excludeAffilitationFeeIsOpen, setExcludeAffilitationFeeIsOpen ] = useState(false);
    const [ selectedTeamCategory, setSelectedTeamCategory ] = useState(null);
    const [ alreadySetDivision, setAlreadySetDivision ] = useState(false);

    const i18n = useContext(I18nContext);
    const registrationFeeContext = useContext(RegistrationFeeContext);
    const i18nHelper = useContext(I18nHelperContext);
    const periodsContext = useContext(PeriodsContext);
    const organizationContext = useContext(OrganizationContext);
    const merchantAccountContext = useContext(MerchantAccountContext);
    const divisionContext = useContext(RegistrationDivisionsContext)
    const utilsContext = useContext(UtilsContext)
    const spordleTable = useSpordleTable();
    const formikRef = useRef();
    const canManageAge = organizationContext.settings.manage_min_max_age_registration_fee?.value == 1;

    const activityPeriodSelectRef = useRef()

    const hasCreditCard = merchantAccountContext.merchantAccounts ? merchantAccountContext.merchantAccounts?.some((m) => m.active == 1) : false
    const hasPlatformFees = organizationContext.settings.fee_for_registration?.value === 'CLIENT' && hasCreditCard

    const manageExcludeAffiliation = useContext(RolesContext).canDoAction('EDIT', 'organization', 'manage_exclude_affiliation', true);

    const findDivisionFees = (divisionId, memberTypeId) => ((divisions || []).find((_div) => _div.division_id === divisionId)?.affiliation_fees?.total?.[memberTypeId])

    // this function is used to calculate the amount that is displayed in the currency inputs for the fees in DUPLICATION MODE
    // these fees are affected by whether or not we exclude membership fees
    // when we do not exclude the membership fees, we must add them to the initial value of the normal fees (seen in the second return)
    const calculateAmounts = (field) => {
        if(duplicateRegistration.exclude_affiliation_fee == 1){
            return (duplicateRegistration?.fee?.[field] / 100)
        }
        return (
            (parseInt(duplicateRegistration?.fee?.[field])
            +
            parseInt(findDivisionFees(duplicateRegistration.division?.division_id, duplicateRegistration.member_type?.member_type_id)?.amount))
            / 100
        )
    }

    // Set division & set affiliation fee totals for this division + memberTypeId
    const selectDivision = (divisionId, memberTypeId) => {
        const affiliationFees = findDivisionFees(divisionId, memberTypeId)
        const amount = affiliationFees?.amount || 0
        const early_amount = affiliationFees?.early_amount || 0
        const late_amount = affiliationFees?.late_amount || 0

        setSelectedDivision(divisionId)
        setSelectedDivisionFees({
            amount: amount,
            early_amount: early_amount,
            late_amount: late_amount,
        })
    }

    const createSuggestedName = (divisionId, categoryId, lang) => {
        if(divisionId){
            const division = (divisions || []).find((division) => division.division_id === divisionId);

            if(division){
                let suggestedName = displayI18n('short_name', division.i18n, division.short_name, lang);
                if(categoryId){
                    const category = categories.find((category) => category.team_category_id === categoryId);
                    if(category && category.class){
                        suggestedName += ` - ${displayI18n('short_name', category.class.i18n, category.class.short_name, lang)}`;
                    }
                }
                return suggestedName;
            }
        }
        return '';
    }

    const resetModal = () => {
        setSelectedDivision(null);
        setSelectedTeamCategory(null);
        setSelectedDivisionFees({
            amount: 0,
            early_amount: 0,
            late_amount: 0,
        });
        setDuplicateRegistration({});
        setAlreadySetDivision(false);
    }

    useEffect(() => {
        const duplicateMode = Object.keys(duplicateRegistration).length > 0;
        setDuplicateMode(duplicateMode)

        if(duplicateMode && !alreadySetDivision){
            setAlreadySetDivision(true);
            selectDivision(duplicateRegistration?.division?.division_id, duplicateRegistration?.member_type?.member_type_id);
        }
    })

    const getFormGroups = () => {
        return organizationContext.additionalFieldsComponents?.find((a) => a.component === "REGISTRATION_FEE")?.groups || []
    }

    const getAdditionalFields = () => {
        return (duplicateRegistration?.additional_fields?.map((field) => ({
            fieldId: field.custom_form_field.custom_form_field_id,
            answer: field.answer,
            optionId: field.custom_form_field_option_selected,
        })) || null)
    }

    const debounceGetFees = debounce((amount, resolve) => {
        return calculatePlatformFees(amount)
            .then(resolve)
    }, 200)

    const updateFeeOnChange = (amount) => {
        return new Promise((resolve) => {
            debounceGetFees(amount, resolve)
        });
    }

    const calculatePlatformFees = (amount) => {
        return utilsContext.calculateFees(organizationContext.organisation_id, amount, 'REGISTRATION')
            .then((fee) => fee.fee_spordle)
    }

    const getDivisions = (value) => {
        return divisionContext.getRegistrationDivisions(organizationContext.organisation_id, { active: '1', period_id: periodsContext.selectedPeriod.period_id, only_defined_affiliation_price: '1', member_type_id: value || memberTypeId })
            .then((divs) => setDivisions(divs || []))
            .catch(console.error)
    }

    useEffect(() => {
        getDivisions()
    }, [])

    useEffect(() => {
        if(formikRef.current && isOpen && isDuplicateMode){
            formikRef.current.setValues({
                ...formikRef.current.values,
                platformFeeLoading: true,
                earlyPlatformFeeLoading: true,
                latePlatformFeeLoading: true,
            })

            Promise.all([
                calculatePlatformFees((formikRef.current.values.amount * 100).toFixed(0)),
                calculatePlatformFees((formikRef.current.values.early_amount * 100).toFixed(0)),
                calculatePlatformFees((formikRef.current.values.late_amount * 100).toFixed(0)),
            ])
                .then((fees) => {
                    // Double checkup on formik ref because of duplicate onsubmit console error
                    if(formikRef.current){
                        formikRef.current.setValues({
                            ...formikRef.current.values,
                            platformFee: fees[0],
                            platformFeeLoading: false,
                            earlyPlatformFee: fees[1],
                            earlyPlatformFeeLoading: false,
                            latePlatformFee: fees[2],
                            latePlatformFeeLoading: false,
                        })
                    }
                })
        }
    }, [ formikRef.current, isOpen, isDuplicateMode ])

    return (
        <AnalyticsModal isOpen={isOpen} onClosed={resetModal} analyticsName='RegistrationFeesAdd'>
            <Formik
                innerRef={formikRef}
                initialValues={
                    isDuplicateMode ?
                        {
                            ...i18nHelper.getInitialValues(),
                            member_type_id: memberTypeId,
                            activity_period_id: duplicateRegistration?.activity_period_id || '',
                            sku: duplicateRegistration?.fee?.sku || '',
                            division_id: duplicateRegistration?.division?.division_id || '',
                            team_category_id: duplicateRegistration?.team_category?.team_category_id || '',
                            active: duplicateRegistration?.active || '1',
                            available_place: duplicateRegistration?.available_place || '',
                            amount: duplicateRegistration?.fee?.amount ? calculateAmounts('amount') : null,
                            early_amount: duplicateRegistration?.fee?.early_amount ? calculateAmounts('early_amount') : null,
                            early_amount_until: duplicateRegistration?.fee?.early_amount_until ? moment(duplicateRegistration?.fee?.early_amount_until) : null,
                            early_amount_until_time: duplicateRegistration?.fee?.early_amount_until ? moment(duplicateRegistration?.fee?.early_amount_until) : null,
                            late_amount: duplicateRegistration?.fee?.late_amount ? calculateAmounts('late_amount') : null,
                            late_amount_after: duplicateRegistration?.fee?.late_amount_after ? moment(duplicateRegistration?.fee?.late_amount_after) : null,
                            late_amount_after_time: duplicateRegistration?.fee?.late_amount_after ? moment(duplicateRegistration?.fee?.late_amount_after) : null,
                            taxable: duplicateRegistration?.fee?.tax_class || false,
                            waivers: duplicateRegistration?.waivers?.filter((waiver) => waiver.organisation.organisation_id === organizationContext.organisation_id)?.map((waiver) => waiver.waiver_id) || [],
                            installment_mode_id: duplicateRegistration?.installment_mode?.installment_mode_id || '',
                            exclude_affiliation_fee: duplicateRegistration?.exclude_affiliation_fee == 1 || false,
                            custom_form_id: duplicateRegistration?.custom_form?.custom_form_id || false,
                            manage_waiting_list: duplicateRegistration?.manage_waiting_list == 1 || false,
                            show_registration_count: duplicateRegistration?.show_registration_count == 1 || false,
                            with_position: duplicateRegistration?.with_position == 1 || false,
                            fields: getAdditionalFieldsInitialValues(getFormGroups(), getAdditionalFields()),
                            platformFee: 0,
                            platformFeeLoading: false,
                            earlyPlatformFee: 0,
                            earlyPlatformFeeLoading: false,
                            latePlatformFee: 0,
                            latePlatformFeeLoading: false,
                            min_age: "",
                            max_age: "",
                        }
                        :
                        {
                            ...i18nHelper.getInitialValues(),
                            member_type_id: memberTypeId,
                            activity_period_id: '',
                            sku: '',
                            division_id: '',
                            team_category_id: '',
                            active: '1',
                            available_place: '',
                            amount: '',
                            early_amount: '',
                            early_amount_until: '',
                            early_amount_until_time: '',
                            late_amount: '',
                            late_amount_after: '',
                            late_amount_after_time: '',
                            taxable: false,
                            waivers: '',
                            installment_mode_id: '',
                            exclude_affiliation_fee: false,
                            custom_form_id: '',
                            manage_waiting_list: false,
                            show_registration_count: false,
                            with_position: false,
                            fields: getAdditionalFieldsInitialValues(getFormGroups()),
                            platformFee: 0,
                            platformFeeLoading: false,
                            earlyPlatformFee: 0,
                            earlyPlatformFeeLoading: false,
                            latePlatformFee: 0,
                            latePlatformFeeLoading: false,
                            min_age: "",
                            max_age: "",
                        }
                }
                validationSchema={object().shape({
                    ...i18nHelper.getValidationSchema({ 'name': string().required(<Translate id='catalog.registrationFees.add.form.validation.name' />) }),
                    member_type_id: string().required(<Translate id='catalog.registrationFees.add.form.validation.memberType' />),
                    division_id: string().required(<Translate id='catalog.registrationFees.add.form.validation.division' />),
                    team_category_id: string(),
                    sku: string(),
                    activity_period_id: string(),
                    active: string(),
                    available_place: number().integer().positive()
                        .test({
                            name: 'waitingListTest',
                            message: <Translate id='catalog.registrationFees.add.form.waitingListRequired' />,
                            test: function(value){
                                if(this.parent.manage_waiting_list){
                                    return !!value
                                }
                                return true
                            },
                        })
                        .test({
                            name: 'showPlacesTest',
                            message: <Translate id='catalog.registrationFees.add.form.showPlacesRequired' />,
                            test: function(value){
                                if(this.parent.show_registration_count){
                                    return !!value
                                }
                                return true
                            },
                        }),
                    amount: number()
                        .required(<Translate id='catalog.registrationFees.add.form.validation.registrationFee' />)
                        .min(selectedDivisionFees.amount / 100, <Translate id='catalog.registrationFees.add.form.validation.registrationFee.min' />),
                    early_amount: number().nullable()
                        .min(selectedDivisionFees.amount / 100, <Translate id='catalog.registrationFees.add.form.validation.registrationFee.min' />),
                    early_amount_until: mixed().when('early_amount', {
                        is: (early_amount) => !!early_amount,
                        then: mixed().required(<Translate id='catalog.registrationFees.add.form.validation.earlyDate' />)
                            .test({
                                name: 'earlyIsValid',
                                message: <Translate id='form.validation.date.format' />,
                                test: function(date){
                                    return date ? moment.isMoment(date) : true
                                },
                            }),
                        otherwise: mixed().nullable(),
                    }),
                    early_amount_until_time: mixed().when('early_amount', {
                        is: (early_amount) => !!early_amount,
                        then: mixed().required(<Translate id='catalog.registrationFees.add.form.validation.earlyDate' />)
                            .test({
                                name: 'earlyIsValid',
                                message: <Translate id='form.validation.time.format' />,
                                test: function(date){
                                    return date ? moment.isMoment(date) : true
                                },
                            }),
                        otherwise: mixed().nullable(),
                    }),
                    late_amount: number().nullable()
                        .min(selectedDivisionFees.amount / 100, <Translate id='catalog.registrationFees.add.form.validation.registrationFee.min' />),
                    late_amount_after: mixed().when('late_amount', {
                        is: (late_amount) => !!late_amount,
                        then: mixed().required(<Translate id='catalog.registrationFees.add.form.validation.lateDate' />)
                            .test({
                                name: 'lateIsValid',
                                message: <Translate id='form.validation.date.format' />,
                                test: function(date){
                                    return date ? moment.isMoment(date) : true
                                },
                            }).test({
                                name: 'greaterThanEarly',
                                message: <Translate id='catalog.registrationFees.add.form.validation.lateDateGreater' />,
                                test: function(maxDate){
                                    if(moment.isMoment(maxDate) && moment.isMoment(this.parent.early_amount_until)){
                                        return maxDate.isAfter(this.parent.early_amount_until);
                                    }
                                    return true; // because not required

                                },
                            }),
                        otherwise: mixed().nullable(),
                    }),
                    late_amount_after_time: mixed().when('late_amount', {
                        is: (late_amount) => !!late_amount,
                        then: mixed().required(<Translate id='catalog.registrationFees.add.form.validation.lateDate' />)
                            .test({
                                name: 'lateTimeIsValid',
                                message: <Translate id='form.validation.date.format' />,
                                test: function(date){
                                    return date ? moment.isMoment(date) : true
                                },
                            }).test({
                                name: 'lateFeeTimeTestBefore',
                                message: <Translate id='catalog.registrationFees.add.form.validation.lateDateGreater' />,
                                test: function(late_amount_after_time){
                                    if(moment.isMoment(late_amount_after_time) && moment.isMoment(this.parent.late_amount_after) && moment.isMoment(this.parent.early_amount_until) && moment.isMoment(this.parent.early_amount_until_time)
                                            && this.parent.late_amount_after.set({ 'hour': late_amount_after_time.hour(), 'minute': late_amount_after_time.minute() }).isAfter(this.parent.early_amount_until.set({ 'hour': this.parent.early_amount_until_time.hour(), 'minute': this.parent.early_amount_until_time.minute() }))
                                    ){
                                        return true
                                    }
                                    return moment.isMoment(late_amount_after_time);

                                },
                            }),
                        otherwise: mixed().nullable(),
                    }),
                    tax_class_id: boolean(),
                    installment_mode_id: string(),
                    waivers: array(),
                    exclude_affiliation_fee: bool(),
                    manage_waiting_list: bool(),
                    show_registration_count: bool(),
                    with_position: bool(),
                    fields: getAdditionalFieldsValidationSchema(getFormGroups()),
                    ...(
                        canManageAge ?
                            {
                                min_age: number().min(1, <Translate id="misc.minimum" values={{ value: 1 }} />)
                                    .integer(<Translate id='registration.settings.class.form.validation.order.integer' />)
                                    .test({
                                        name: "greaterOrEqualToMin",
                                        message: <Translate id='registration.settings.category.form.minAge.smaller' />,
                                        test: function(min_age){
                                            return this.parent.max_age ? parseInt(min_age) <= parseInt(this.parent.max_age) : true
                                        },
                                    }),
                                max_age: number().min(1, <Translate id="misc.minimum" values={{ value: 1 }} />)
                                    .integer(<Translate id='registration.settings.class.form.validation.order.integer' />)
                                    .test({
                                        name: "greaterOrEqualToMin",
                                        message: <Translate id='registration.settings.category.form.maxAge.bigger' />,
                                        test: function(max_age){
                                            return this.parent.min_age ? parseInt(max_age) >= parseInt(this.parent.min_age) : true
                                        },
                                    }),
                            }
                            :
                            {}
                    ),
                })}
                onSubmit={async(values, { setSubmitting, setStatus }) => {
                    const taxClasses = values.taxable
                        ?
                        await utilsContext.getTaxClasses()
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            })
                        :
                        [];
                    const taxClass = taxClasses.filter((taxClass) => taxClass.active == '1')[0];

                    const newValues = {
                        ...values,
                        ...i18nHelper.getAPIValues(values),
                        amount: (values.amount * 100).toFixed(0) - selectedDivisionFees.amount,
                        exclude_affiliation_fee: values.exclude_affiliation_fee >>> 0, // Transform true/false to 0/1
                        manage_waiting_list: values.manage_waiting_list >>> 0, // Transform true/false to 0/1
                        show_registration_count: values.show_registration_count >>> 0, // Transform true/false to 0/1
                        with_position: values.with_position >>> 0, // Transform true/false to 0/1
                        fields: getFormattedAdditionalFields(values.fields, getFormGroups(), i18n.getGenericLocale()),
                        tax_class_id: values.taxable ? taxClass.tax_class_id : '',
                    }
                    delete newValues.waivers;
                    delete newValues.i18n;
                    delete newValues.taxable;

                    if(!canManageAge){
                        delete newValues.min_age;
                        delete newValues.max_age;
                    }

                    if(values.early_amount){
                        newValues.early_amount = (values.early_amount * 100).toFixed(0) - selectedDivisionFees.amount;
                        newValues.early_amount_until = values.early_amount_until.set({ 'hour': values.early_amount_until_time.hour(), 'minute': values.early_amount_until_time.minute() }).toISOString()
                    }

                    if(values.late_amount){
                        newValues.late_amount = (values.late_amount * 100).toFixed(0) - selectedDivisionFees.amount;
                        newValues.late_amount_after = values.late_amount_after.set({ 'hour': values.late_amount_after_time.hour(), 'minute': values.late_amount_after_time.minute() }).toISOString()
                    }

                    await registrationFeeContext.createRegistrationFee(newValues)
                        .then(async(registration_fee_id) => {
                            const tableData = {
                                registration_fee_id: registration_fee_id,
                                fee: {
                                    amount: newValues.amount,
                                    early_amount: newValues.early_amount,
                                    early_amount_until: newValues.early_amount_until,
                                    late_amount: newValues.late_amount,
                                    late_amount_after: newValues.late_amount_after,
                                },
                                member_type: {
                                    member_type_id: values.member_type_id,
                                },
                                affiliation_fees: { // remake affiliation_fees api object from get (with total)
                                    total: {
                                        [values.member_type_id]: {
                                            amount: selectedDivisionFees.amount,
                                        },
                                    },
                                },
                                is_carding_fee: !!(selectedTeamCategory && selectedTeamCategory.max_card), // carding logic
                                number_of_cards_used: 0,
                                installment_mode: installments?.find((_i) => _i.installment_mode_id === newValues.installment_mode_id),
                                waivers: [ ...values.waivers ],
                                division: divisions.find((division) => division.division_id.includes(newValues.division_id)),
                                team_category: selectedTeamCategory,
                                ...newValues,
                                i18n: values.i18n,
                            }
                            await spordleTable.addRow(tableData);
                            if(values.waivers)
                                await registrationFeeContext.updateRegistrationFeeWaiver(registration_fee_id, values.waivers)
                                    .catch((error) => {
                                        console.error(error.message);
                                        setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                        setSubmitting(false);
                                    });

                            spordleTable.refreshTable();
                            success();
                            toggle();
                        })
                        .catch((error) => {
                            console.error(error.message);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            setSubmitting(false);
                        })
                }}
            >
                {(formik) => (
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <Form id='addRegistrationFee' name='addRegistrationFee'>
                            <ModalHeader toggle={toggle}>
                                <Translate id='catalog.registrationFees.add.title' />
                            </ModalHeader>
                            <ModalBody>
                                {isDuplicateMode &&
                                    <Alert color={'warning'} className='d-flex align-items-center'>
                                        <i className='mdi mdi-information-outline mr-2 font-22' />
                                        <div>
                                            <Translate id='catalog.registrationFees.duplicate.information' />
                                            <div className='font-bold font-16'>{duplicateRegistration.name}</div>
                                        </div>
                                    </Alert>
                                }
                                {memberTypes &&
                                    <FormGroup>
                                        <Label for="member_type_id" className="text-muted"><Translate id='catalog.registrationFees.add.form.memberType' /></Label>
                                        <FormikSelect
                                            name='member_type_id'
                                            id='member_type_id'
                                            onOptionSelected={async([ value ]) => {
                                                formik.setFieldValue('member_type_id', value);
                                                formik.setFieldValue('division_id', "");

                                                setMemberTypeId(value)
                                                setDivisions(false) // trigger loading
                                                await getDivisions(value);
                                                selectDivision(formik.values.division_id, value);
                                            }}
                                            renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                            options={memberTypes.map((type) => ({
                                                label: type.name,
                                                i18n: type.i18n,
                                                value: type.member_type_id,
                                            }))}
                                        />
                                    </FormGroup>
                                }
                                <Row form>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="active" className="text-muted"><Translate id='catalog.registrationFees.add.form.active' /></Label>
                                            <FormikSelect
                                                search={false}
                                                name='active'
                                                id='active'
                                                loadingStatus='success'
                                                defaultData={[
                                                    {
                                                        label: 'misc.active',
                                                        value: '1',
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        label: 'misc.inactive',
                                                        value: '0',
                                                        translateLabel: true,
                                                    },
                                                ]}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" className="mb-3">
                                        <Label for="available_place" className="text-muted"><Translate id='catalog.registrationFees.add.form.availablePlaces' /></Label>
                                        <FormikInputNumber allowNegative={false} id='available_place' name='available_place' placeholder="catalog.registrationFees.add.form.unlimited" disabled={!!(selectedTeamCategory && selectedTeamCategory.max_card)} />
                                    </Col>
                                    <Col sm='6' className="mb-3">
                                        <FormikCheckedButton
                                            disabled={!formik.values.available_place}
                                            id='manage_waiting_list'
                                            name='manage_waiting_list'
                                            label='catalog.registrationFees.add.form.waitingList'
                                        />
                                    </Col>
                                    <Col sm='6' className="mb-3">
                                        <FormikCheckedButton id='with_position' name='with_position' label='catalog.registrationFees.add.form.showPosition' />
                                    </Col>
                                    <Col sm='12' className="mb-3">
                                        <FormikCheckedButton
                                            disabled={!formik.values.available_place}
                                            id='show_registration_count'
                                            name='show_registration_count'
                                            label='catalog.registrationFees.add.form.showPlaces'
                                        />
                                    </Col>
                                    <Col sm="12" className="small">
                                        <Collapse isOpen={!formik.values.available_place}>
                                            <div className="p-2 mb-3 bg-light-inverse rounded">
                                                <i className="mdi mdi-information-outline text-primary" /> <Translate id='catalog.registrationFees.add.form.availablePlaces.help' />
                                            </div>
                                        </Collapse>
                                    </Col>
                                </Row>
                                <div className="mb-2"><Translate id='catalog.affiliationFees.add.category' /></div>
                                <div className="border p-3 mb-3">
                                    <Row form>
                                        <Col sm="12">
                                            <RegistrationFeeDivisionSelect
                                                divisions={divisions}
                                                selectDivision={selectDivision}
                                                selectedDivisionFees={selectedDivisionFees}
                                            />
                                        </Col>
                                        {categories &&
                                            <Col sm="12">
                                                <RegistrationFeeCategorySelect
                                                    categories={categories}
                                                    setSelectedTeamCategory={setSelectedTeamCategory}
                                                    selectedTeamCategory={selectedTeamCategory}
                                                    selectedDivision={selectedDivision}
                                                />
                                            </Col>
                                        }
                                        {canManageAge &&
                                            <>
                                                <Col sm="6">
                                                    <Label for="team_category_id" className="text-muted"><Translate id='registration.settings.category.label.minAge' /> <Optional /></Label>
                                                    <FormikInputNumber allowNegative={false} id="min_age" name="min_age" min={1} max={99} />
                                                </Col>
                                                <Col sm="6">
                                                    <Label for="team_category_id" className="text-muted"><Translate id='registration.settings.category.label.maxAge' /> <Optional /></Label>
                                                    <FormikInputNumber allowNegative={false} id="max_age" name="max_age" min={1} max={99} disabled={!!selectedTeamCategory} />
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </div>

                                {/* Registration name */}
                                <FormGroup>
                                    <div className="mb-2"><Translate id='catalog.registrationFees.add.form.name' /></div>
                                    <div className="border p-3">
                                        <RenderI18nForm field='name'>
                                            {({ fieldName, fieldLabel, lang }) => {
                                                const suggestedName = createSuggestedName(formik.values.division_id, formik.values.team_category_id, lang);
                                                return (
                                                    <FormGroup key={fieldName}>
                                                        <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                        <FormikInputText id={fieldName} name={fieldName} trim disabled={!formik.values.division_id} />
                                                        {suggestedName &&
                                                            <FormText>
                                                                <Translate id='catalog.registrationFees.add.form.name.suggestion' />:
                                                                <button
                                                                    className='reset-btn text-link ml-1' type='button'
                                                                    onClick={() => {
                                                                        formik.setFieldValue(fieldName, suggestedName);
                                                                    }}
                                                                >
                                                                    {suggestedName}
                                                                </button>
                                                            </FormText>
                                                        }
                                                    </FormGroup>
                                                )
                                            }}
                                        </RenderI18nForm>
                                    </div>
                                </FormGroup>

                                {organizationContext.settings.enable_organization_activity_periods?.value == '1' &&
                                    <FormGroup>
                                        <Label for='activity_period_id' className='text-muted'><Translate id='form.fields.activityPeriod' /></Label>
                                        <FormikSelect
                                            name="activity_period_id"
                                            id="activity_period_id"
                                            ref={activityPeriodSelectRef}
                                            clearable
                                            renderOption={({ option }) => (
                                                <>
                                                    <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                    <div className="small text-muted"><DisplayI18n field='description' defaultValue={option.description} i18n={option.i18n} /></div>
                                                    {option.exclude_affiliation_fee &&
                                                        <div className='text-warning small'><i className='mdi mdi-information-outline' /><Translate id='catalog.registrationFees.add.activityPeriod.excludes.membership.fees' /></div>
                                                    }
                                                </>
                                            )}
                                            renderSelectedOption={(option) => (
                                                <>
                                                    <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                    <div className="small text-muted"><DisplayI18n field='description' defaultValue={option.description} i18n={option.i18n} /></div>
                                                </>
                                            )}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return periodsContext.getOrganizationActivityPeriods({ organisation_id: organizationContext.organisation_id, active: '1' })
                                                            .then((activityPeriods) => (activityPeriods.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order)).map((activityPeriod) => ({
                                                                value: activityPeriod.activity_period_id,
                                                                label: activityPeriod.name,
                                                                description: activityPeriod.description,
                                                                i18n: activityPeriod.i18n,
                                                                exclude_affiliation_fee: activityPeriod.exclude_affiliation_fee == 1,
                                                            }))));
                                                    default:
                                                        break;
                                                }
                                            }}
                                            onOptionSelected={([ value ], select) => {
                                                if(select.getSpordleTable().getData().find((aPeriod) => aPeriod.value === value)?.exclude_affiliation_fee){
                                                    formik.setFieldValue('exclude_affiliation_fee', true);
                                                    setSelectedDivisionFees({
                                                        amount: 0,
                                                        early_amount: 0,
                                                        late_amount: 0,
                                                    });
                                                }else{
                                                    formik.setFieldValue('exclude_affiliation_fee', false);
                                                    selectDivision(formik.values.division_id, formik.values.member_type_id);
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                }

                                {/* fees */}
                                <FormGroup>
                                    {/* // justify-content-between */}
                                    <div className="d-flex mb-2 justify-content-between">
                                        <Translate id='form.fields.fees' data-tip="catalog.registrationFees.add.excludeAffiliationFee" />
                                        <div className='d-flex'>
                                            <Tooltip
                                                disabled={!(activityPeriodSelectRef.current?.getSpordleTable()?.getData()?.find((aPeriod) => aPeriod.value === activityPeriodSelectRef.current?.state?.selectedOptions?.[0])?.exclude_affiliation_fee)}
                                                zIndex={3000} position='top'
                                                withArrow
                                                label={<Translate id='catalog.registrationFees.add.disabledExcludeAffiliationFeeTip' />}
                                            >
                                                <FormikCheckedButton
                                                    disabled={!manageExcludeAffiliation || activityPeriodSelectRef.current?.getSpordleTable()?.getData()?.find((aPeriod) => aPeriod.value === activityPeriodSelectRef.current?.state?.selectedOptions?.[0])?.exclude_affiliation_fee}
                                                    name='exclude_affiliation_fee'
                                                    id='exclude_affiliation_fee_checkbox'
                                                    label='catalog.registrationFees.add.form.excludeAffiliationFee'
                                                    onChange={(e) => {
                                                        formik.setFieldValue('exclude_affiliation_fee', e.target.checked);
                                                        if(e.target.checked){
                                                            setSelectedDivisionFees({
                                                                amount: 0,
                                                                early_amount: 0,
                                                                late_amount: 0,
                                                            });
                                                        }else{
                                                            selectDivision(formik.values.division_id, formik.values.member_type_id);
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip zIndex={3000} position='bottom' withArrow label={<Translate id={'catalog.registrationFees.add.excludeAffiliationFeeTip'} values={{ b: (chunk) => <b>{chunk}</b> }} />}>
                                                <i className='mdi mdi-information-outline ml-1 text-primary clickable' onClick={() => { setExcludeAffilitationFeeIsOpen(!excludeAffilitationFeeIsOpen) }} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <Collapse isOpen={excludeAffilitationFeeIsOpen} className='mb-2 bg-light-inverse rounded p-1 small'>
                                        <div className='d-flex'>
                                            <i className='mdi mdi-information-outline mr-1 text-primary' />

                                            <div>
                                                <div className='mb-2'><Translate id='catalog.registrationFees.add.excludeAffiliationFee' /></div>
                                                <div className='mb-2'><Translate id='catalog.registrationFees.add.excludeAffiliationFee2' /></div>
                                                <div><Translate id='catalog.registrationFees.add.excludeAffiliationFee3' values={{ b: (chunk) => <b>{chunk}</b> }} /></div>
                                            </div>
                                        </div>
                                    </Collapse>
                                    <div className="border p-3">
                                        {formik.values.exclude_affiliation_fee &&
                                            <CustomAlert className='mt-0 mb-2' color='info' text={'catalog.registrationFees.add.activityPeriod.warning.feesRecalculate'} translateText />
                                        }
                                        <Label for="amount" className="text-muted">
                                            <Translate id='catalog.registrationFees.add.form.registrationFee' /> <Required />
                                        </Label>
                                        <Row form className="form-group align-items-center">
                                            <Col xs="4">
                                                {/* fees */}
                                                <FormikCurrencyInput
                                                    id='amount'
                                                    name='amount'
                                                    className="mb-1"
                                                    manualError
                                                    allowNegative={false}
                                                    onValueChange={(e) => {
                                                        if(hasPlatformFees){
                                                            formik.setFieldValue('platformFeeLoading', true)
                                                            updateFeeOnChange((e.value * 100).toFixed(0))
                                                                .then((fee) => {
                                                                    formik.setFieldValue('platformFee', fee)
                                                                    formik.setFieldValue('platformFeeLoading', false)
                                                                })
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col sm="8">
                                                <Collapse isOpen={!!formik.values.amount}>
                                                    <Row form>
                                                        {/* <Col xs="3" className="text-center d-sm-none">${formik.values.amount}</Col> */}
                                                        <Col xs="1" className="text-center">-</Col>
                                                        <Col xs="3" className="text-center"><CurrencyFormat value={selectedDivisionFees.amount / 100} /></Col>
                                                        <Col xs="1" className="text-center">=</Col>
                                                        <Col xs="3" className="text-center"><CurrencyFormat value={formik.values.amount - (selectedDivisionFees.amount / 100)} /></Col>
                                                        <Col xs="1">
                                                            <Tooltip zIndex={3000} withArrow label={<Translate id='catalog.registrationFees.add.form.registrationFee.help' />}>
                                                                <i className="mdi mdi-help-circle-outline text-primary mx-1" />
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                            </Col>

                                            {hasPlatformFees &&
                                                <Collapse isOpen={!!formik.values.amount}>
                                                    <Col sm='12' className='small mb-1'>
                                                        +<Translate id='catalog.registrationFees.sidePanel.form.platformFees' />
                                                        <span className='ml-1'>
                                                            ({formik.values.platformFeeLoading ?
                                                                <Spinner className='mx-1' style={{ width: '0.8rem', height: '0.8rem' }} size='sm' color='primary' />
                                                                :
                                                                <CurrencyFormat value={formik.values.platformFee / 100} />
                                                            })
                                                        </span>
                                                    </Col>
                                                </Collapse>
                                            }

                                            <Col sm="12">
                                                <div className="mb-2">
                                                    <FormikError name="amount" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <Label for="early_amount" className="text-muted">
                                                <Translate id='catalog.registrationFees.add.form.earlyFee' />
                                            </Label>
                                            <Row form className="align-items-center">
                                                <Col sm="4">
                                                    <FormikCurrencyInput
                                                        manualError
                                                        id='early_amount'
                                                        name='early_amount'
                                                        className="mb-1"
                                                        allowNegative={false}
                                                        onValueChange={(e) => {
                                                            if(hasPlatformFees){
                                                                formik.setFieldValue('earlyPlatformFeeLoading', true)
                                                                updateFeeOnChange((e.value * 100).toFixed(0))
                                                                    .then((fee) => {
                                                                        formik.setFieldValue('earlyPlatformFee', fee)
                                                                        formik.setFieldValue('earlyPlatformFeeLoading', false)
                                                                    })
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm="8">
                                                    <Collapse isOpen={!!formik.values.early_amount}>
                                                        <Row form>
                                                            {/* <Col xs="3" className="text-center d-sm-none">${formik.values.early_amount}</Col> */}
                                                            <Col xs="1" className="text-center">-</Col>
                                                            <Col xs="3" className="text-center"><CurrencyFormat value={selectedDivisionFees.amount / 100} /></Col>
                                                            <Col xs="1" className="text-center">=</Col>
                                                            <Col xs="3" className="text-center"><CurrencyFormat value={formik.values.early_amount - (selectedDivisionFees.amount / 100)} /></Col>
                                                            <Col xs="1">
                                                                <Tooltip withArrow label={<Translate id='catalog.registrationFees.add.form.registrationFee.help' />}>
                                                                    <i className="mdi mdi-help-circle-outline text-primary mx-1" />
                                                                </Tooltip>

                                                            </Col>
                                                        </Row>
                                                    </Collapse>
                                                </Col>
                                                {hasPlatformFees &&
                                                    <Collapse isOpen={!!formik.values.early_amount}>
                                                        <Col sm='12' className='small mb-1'>
                                                            +<Translate id='catalog.registrationFees.sidePanel.form.platformFees' />
                                                            <span className='ml-1'>
                                                                ({formik.values.earlyPlatformFeeLoading ?
                                                                    <Spinner className='mx-1' style={{ width: '0.8rem', height: '0.8rem' }} size='sm' color='primary' />
                                                                    :
                                                                    <CurrencyFormat value={formik.values.earlyPlatformFee / 100} />
                                                                })
                                                            </span>
                                                        </Col>
                                                    </Collapse>
                                                }
                                                <Col sm="12">
                                                    <div className="mb-2">
                                                        <FormikError name="early_amount" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Collapse isOpen={!!formik.values.early_amount}>
                                                <span className="small">
                                                    <Translate id='catalog.registrationFees.add.form.before' /> <Required />
                                                </span>
                                                <Row form>
                                                    <Col xs="4">
                                                        <FormikDateTime
                                                            manualError
                                                            className="mb-1"
                                                            inputProps={{ disabled: !formik.values.early_amount }}
                                                            name='early_amount_until'
                                                            id='early_amount_until'
                                                            timeFormat={false}
                                                        />
                                                    </Col>
                                                    <Col xs="4">
                                                        <FormikDateTime
                                                            manualError
                                                            inputProps={{ disabled: !formik.values.early_amount }}
                                                            name='early_amount_until_time'
                                                            id='early_amount_until_time'
                                                            className="mb-1"
                                                            dateFormat={false}
                                                            timeFormat={'h:mm A'}
                                                            onClose={(input) => {
                                                                if(input === ''){ // empty input
                                                                    formik.setFieldValue('early_amount_until_time', '')
                                                                }else if(!moment.isMoment(formik.values.time)){ // was not selected from picker
                                                                    if(moment(input, [ "H:mm" ]).isValid()){
                                                                        formik.setFieldValue('early_amount_until_time', moment(moment(input, [ "H:mm" ]).format("h:mm A"), [ "h:mm A" ]));
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Collapse>
                                            <div className="mb-2">
                                                <FormikError name="early_amount_until" />
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="late_amount" className="text-muted">
                                                <Translate id='catalog.registrationFees.add.form.lateFee' />
                                            </Label>
                                            <Row form className="align-items-center">
                                                <Col sm="4">
                                                    <FormikCurrencyInput
                                                        manualError
                                                        id='late_amount'
                                                        name='late_amount'
                                                        className="mb-1"
                                                        allowNegative={false}
                                                        onValueChange={(e) => {
                                                            if(hasPlatformFees){
                                                                formik.setFieldValue('latePlatformFeeLoading', true)
                                                                updateFeeOnChange((e.value * 100).toFixed(0))
                                                                    .then((fee) => {
                                                                        formik.setFieldValue('latePlatformFee', fee)
                                                                        formik.setFieldValue('latePlatformFeeLoading', false)
                                                                    })
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm="8">
                                                    <Collapse isOpen={!!formik.values.late_amount}>
                                                        <Row form>
                                                            {/* <Col xs="3" className="text-center d-sm-none">${formik.values.late_amount}</Col> */}
                                                            <Col xs="1" className="text-center">-</Col>
                                                            <Col xs="3" className="text-center"><CurrencyFormat value={selectedDivisionFees.amount / 100} /></Col>
                                                            <Col xs="1" className="text-center">=</Col>
                                                            <Col xs="3" className="text-center"><CurrencyFormat value={formik.values.late_amount - (selectedDivisionFees.amount / 100)} /></Col>
                                                            <Col xs="1">
                                                                <Tooltip withArrow label={<Translate id='catalog.registrationFees.add.form.registrationFee.help' />}>
                                                                    <i className="mdi mdi-help-circle-outline text-primary mx-1" />
                                                                </Tooltip>
                                                            </Col>
                                                        </Row>
                                                    </Collapse>
                                                </Col>
                                                {hasPlatformFees &&
                                                    <Collapse isOpen={!!formik.values.late_amount}>
                                                        <Col sm='12' className='small mb-1'>
                                                            +<Translate id='catalog.registrationFees.sidePanel.form.platformFees' />
                                                            <span className='ml-1'>
                                                                ({formik.values.latePlatformFeeLoading ?
                                                                    <Spinner className='mx-1' style={{ width: '0.8rem', height: '0.8rem' }} size='sm' color='primary' />
                                                                    :
                                                                    <CurrencyFormat value={formik.values.latePlatformFee / 100} />
                                                                })
                                                            </span>
                                                        </Col>
                                                    </Collapse>
                                                }
                                                <Col sm="12">
                                                    <div className="mb-2">
                                                        <FormikError name="late_amount" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Collapse isOpen={!!formik.values.late_amount}>
                                                <span className="small">
                                                    <Translate id='catalog.registrationFees.add.form.after' /> <Required />
                                                </span>
                                                <Row form>
                                                    <Col xs="4">
                                                        <FormikDateTime
                                                            manualError
                                                            inputProps={{ disabled: !formik.values.late_amount }}
                                                            name='late_amount_after'
                                                            id='late_amount_after'
                                                            className="mb-1"
                                                            timeFormat={false}
                                                        />
                                                    </Col>
                                                    <Col xs="4">
                                                        <FormikDateTime
                                                            manualError
                                                            inputProps={{ disabled: !formik.values.late_amount }}
                                                            name='late_amount_after_time'
                                                            id='late_amount_after_time'
                                                            className="mb-1"
                                                            dateFormat={false}
                                                            timeFormat={'h:mm A'}
                                                            onClose={(input) => {
                                                                if(input === ''){ // empty input
                                                                    formik.setFieldValue('late_amount_after_time', '')
                                                                }else if(!moment.isMoment(formik.values.time)){ // was not selected from picker
                                                                    if(moment(input, [ "H:mm" ]).isValid()){
                                                                        formik.setFieldValue('late_amount_after_time', moment(moment(input, [ "H:mm" ]).format("h:mm A"), [ "h:mm A" ]));
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Collapse>
                                            <div className="mb-2">
                                                <FormikError name="late_amount_after" />
                                            </div>
                                        </FormGroup>
                                    </div>
                                </FormGroup>

                                {/* Forms */}
                                <FormGroup>
                                    <Label for='custom_form_id' className='text-muted'>
                                        <Translate id='form.fields.forms' />
                                    </Label>
                                    { forms &&
                                        <FormikSelect
                                            name='custom_form_id'
                                            id='custom_form_id'
                                            clearable
                                            options={forms.map((form) => ({
                                                label: form.name,
                                                value: form.custom_form_id,
                                            }))}
                                        />
                                    }
                                </FormGroup>

                                {/* Waivers  */}
                                <FormGroup>
                                    <Label for='waivers' className='text-muted'><Translate id='catalog.registrationFees.add.form.waivers' /></Label>
                                    { waivers ?
                                        <FormikSelect
                                            name='waivers'
                                            id='waivers'
                                            clearable
                                            multi
                                            searchKeys={[
                                                    `i18n.${i18n.getGenericLocale()}.name`,
                                            ]}
                                            renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                            options={waivers.map((waiver) => ({
                                                value: waiver.waiver_id,
                                                i18n: waiver.i18n,
                                                label: waiver.name,
                                            }))}
                                        />
                                        :
                                        <small className="text-muted"><Translate id='catalog.registrationFees.add.form.applicableTax' /></small>
                                    }
                                </FormGroup>
                                <FormGroup>
                                    <Label for="installment_mode_id" className='text-muted'><Translate id='catalog.registrationFees.sidePanel.form.installments' /></Label>
                                    {installments &&
                                        <FormikSelect
                                            name="installment_mode_id"
                                            id="installment_mode_id"
                                            renderOption={({ option: installment }) => (
                                                installment !== 'none' ?
                                                    <span>
                                                        <DisplayI18n field='name' i18n={installment.i18n} defaultValue={installment.label} />
                                                        <span className="d-block small text-muted">
                                                            <Translate id='catalog.registrationFees.add.form.installment.number' values={{ installmentNb: installment.installment_options?.length }} />
                                                        </span>
                                                    </span>
                                                    :
                                                    <Translate id="misc.none" />
                                            )}
                                            searchKeys={[
                                                `i18n.${i18n.getGenericLocale()}.name`,
                                            ]}
                                            clearable
                                            options={installments.map((inst) => ({
                                                value: inst.installment_mode_id,
                                                i18n: inst.i18n,
                                                label: inst.name,
                                                installment_options: inst.installment_options,
                                            }))}
                                        />
                                    }
                                </FormGroup>
                                <Row>
                                    {/* taxes */}
                                    <Col sm="6">
                                        <FormGroup>
                                            <FormikCheckedButton id='taxable' name='taxable' label='catalog.registrationFees.add.form.taxable' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <FormikCheckedButton disabled id='taxReceipt' name='taxReceipt' label='catalog.registrationFees.add.form.taxReceipt' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="sku" className="text-muted">
                                                <Translate id='catalog.registrationFees.add.form.sku' />
                                            </Label>
                                            <FormikSelect
                                                disabled
                                                name='sku'
                                                id='sku'
                                                defaultValues={[ '1' ]}
                                                defaultData={[
                                                    {
                                                        label: '4152 - Registration',
                                                        value: '1',
                                                    },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                {getAdditionalFieldsRender(getFormGroups(), 'fields', getAdditionalFields(), false, 'mb-2')}
                                <ScrollToError />
                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    {formik.status &&
                                        <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                    }
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button form='addRegistrationFee' color="primary" type="submit"><Translate id='misc.add' /></Button>
                                <Button color="primary" onClick={toggle} outline><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </Form>
                    </OverlayLoader>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

const ScrollToError = () => {
    const formik = useFormikContext();
    const count = useRef(formik.submitCount);

    useEffect(() => {
        const errorsArray = Object.keys(formik.errors)
        if(errorsArray.length > 0 && formik.submitCount !== count.current){
            const fieldId = errorsArray[0]
            if(formik.errors[fieldId]){
                const modal = document.querySelector('.modal.show');
                modal.scrollTo({
                    behavior: 'smooth',
                    top: 0,
                });
            }

            count.current = formik.submitCount;
        }
    }, [ formik.errors, formik.submitCount ])

    return null
}

export default RegistrationFeesAdd;