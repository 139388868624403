import Translate from '@spordle/intl-elements';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    FormGroup,
    Label
} from "reactstrap";
import { Formik, Form } from 'formik';
import { object, string, mixed, boolean, array } from 'yup';
import { FormikCheckedButton, FormikDateTime, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import FormikTiny from '../../../../components/formik/FormikTiny';
import { OnlineStoreContext } from '../../../../contexts/OnlineStoreContext';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import moment from 'moment';
import Required from '../../../../components/formik/Required';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { PeriodsContext } from '../../../../contexts/contexts';
import { I18nContext } from '../../../../contexts/I18nContext';
import CustomAlert from '../../../../components/CustomAlert';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import CanDoAction from '../../../../components/permissions/CanDoAction';

/**
 * @param {object} props
 * @param {function} props.save function called when the form has been submitted/validated (save or next)
 * @param {function} [props.cancel] function called when the form has been canceled (toggleEdit or previous), if null -> there is no previous/cancel btn
 * @param {boolean} [props.wizard] if form is in wizard, the btns cancel/save becomes previous/next
 * @param {string} [props.onlineStoreId]
*/
const FormOnlineStoreGeneralInformation = ({ cancel, save, wizard, defaultData, season, updateStoreSeason, ...props }) => {
    const onlineStoreContext = useContext(OnlineStoreContext);
    const { activePeriods, setSelectedPeriod, getOrganizationActivityPeriods } = useContext(PeriodsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const orgContext = useContext(OrganizationContext);

    const createI18nObjectFromApi = ({ i18n, description, name, confirmation_message_email, ...values }) => {
        const newValues = {};
        const hasEnglish = values.languages.findIndex((lang) => lang === 'en') !== -1;
        values.languages.forEach((lang) => {
            for(const key in i18n[lang]){
                if(Object.hasOwnProperty.call(i18n[lang], key)){
                    const i18nValue = i18n[lang][key];
                    newValues[`i18n[${lang}][${key}]`] = i18nValue;
                    if(hasEnglish){
                        if(lang === 'en'){
                            newValues[key] = i18nValue;
                        }
                    }else{
                        // No english
                        newValues[key] = i18nValue;
                    }
                }
            }
        })

        return { ...values, ...newValues };
    }

    return (
        <Card className="card-shadow">
            <CardBody>
                <div className="h4 font-bold card-title border-bottom pb-1 mb-3"><Translate id='onlineStore.create.step1.label' /></div>
                <Formik
                    initialValues={{
                        // Note that these variable fit the api so there is a better conversion between
                        languages: [],
                        email: '',
                        period_id: defaultData?.period?.period_id || season || '',
                        // registrarEmail2: '',
                        // sendEmail2: false,

                        ...defaultData,

                        send_email_cc: defaultData?.send_email_cc != 0,

                        i18n: {
                            fr: {
                                name: defaultData?.i18n?.fr?.name || '',
                                description: defaultData?.i18n?.fr?.description || '',
                                confirmation_message_email: defaultData?.i18n?.fr?.confirmation_message_email || '',
                            },
                            en: {
                                name: defaultData?.i18n?.en?.name || '',
                                description: defaultData?.i18n?.en?.description || '',
                                confirmation_message_email: defaultData?.i18n?.en?.confirmation_message_email || '',
                            },
                        },
                        skip_postal_code_validation: `${defaultData?.skip_postal_code_validation || "0"}`, // **needed double negative to "enable". inverted the value from the meaning of the label to make it more simple
                        registrationDateStart: defaultData?.start_date ? moment(defaultData.start_date) : '',
                        registrationTimeStart: defaultData?.start_date ? moment(defaultData.start_date) : '',
                        registrationDateEnd: defaultData?.end_date ? moment(defaultData.end_date) : '',
                        registrationTimeEnd: defaultData?.end_date ? moment(defaultData.end_date) : '',
                        activity_period_id: defaultData?.activity_period?.activity_period_id || '',
                    }}
                    validationSchema={object().shape({
                        email: string().trim()
                            .required(<Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.registrar.required' />)
                            .email(<Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.registrar.emailFormat' />),
                        skip_postal_code_validation: string().oneOf([ "1", "0" ]),
                        ...(wizard ? { period_id: string().required(<Translate id="form.fields.period.required" />) } : {}),
                        send_email_cc: boolean(),
                        languages: array().of(string()).min(1, <Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.language.min' />),
                        i18n: object().shape({
                            fr: object().shape({
                                name: string().test({
                                    name: 'required-fr-name',
                                    message: <Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.formName.required' />,
                                    test: function(value){
                                        if(this.from[this.from.length - 1].value.languages.includes('fr')){
                                            return !!value;
                                        }
                                        return true;
                                    },
                                }),
                                description: string().nullable(),
                                confirmation_message_email: string().nullable().trim(),
                            }),
                            en: object().shape({
                                name: string().test({
                                    name: 'required-en-name',
                                    message: <Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.formName.required' />,
                                    test: function(value){
                                        if(this.from[this.from.length - 1].value.languages.includes('en')){
                                            return !!value;
                                        }
                                        return true;
                                    },
                                }),
                                description: string().nullable(),
                                confirmation_message_email: string().nullable().trim(),
                            }),
                        }),
                        registrationDateStart: mixed().required(<Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.date.required' />)
                            .test({
                                name: 'registrationDateStart-format',
                                message: <Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.date.invalidFormat' />,
                                test: moment.isMoment,
                            }),
                        registrationTimeStart: mixed().required(<Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.time.required' />)
                            .test({
                                name: 'registrationTimeStart-format',
                                message: <Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.time.invalidFormat' />,
                                test: moment.isMoment,
                            }),
                        registrationDateEnd: mixed().required(<Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.date.required' />)
                            .test({
                                name: 'registrationDateEnd-format',
                                message: <Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.date.invalidFormat' />,
                                test: moment.isMoment,
                            }).test({
                                name: 'registrationDateEnd-afterStartDate',
                                message: <Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.endDate.afterStartDate' />,
                                test: function(endDate){
                                    // Validating that the combinaison of endDate and endTime is after the combinaison of startDate and startTime
                                    if(moment.isMoment(endDate) && moment.isMoment(this.parent.registrationDateStart) && moment.isMoment(this.parent.registrationTimeStart)){
                                        if(!moment.isMoment(this.parent.registrationTimeEnd)){
                                            return true;
                                        }
                                        const startDate = moment(this.parent.registrationDateStart).set({ 'hour': this.parent.registrationTimeStart.get('hour'), 'minute': this.parent.registrationTimeStart.get('minute') })
                                        const formattedEndDate = moment(endDate).set({ 'hour': this.parent.registrationTimeEnd.get('hour'), 'minute': this.parent.registrationTimeEnd.get('minute') })
                                        return formattedEndDate.isAfter(startDate);
                                    }
                                    return false;
                                },
                            }),
                        registrationTimeEnd: mixed().required(<Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.time.required' />)
                            .test({
                                name: 'registrationTimeEnd-format',
                                message: <Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.time.invalidFormat' />,
                                test: moment.isMoment,
                            })
                            .test({
                                name: 'registrationDateEnd-afterStartDate',
                                message: <Translate id='onlineStore.components.onlineStoreGeneralInformation.validation.endDate.afterStartDate' />,
                                test: function(endTime){
                                    // Validating that the combinaison of endDate and endTime is after the combinaison of startDate and startTime
                                    if(moment.isMoment(endTime) && moment.isMoment(this.parent.registrationDateStart) && moment.isMoment(this.parent.registrationDateStart) && moment.isMoment(this.parent.registrationTimeStart)){
                                        const startDate = moment(this.parent.registrationDateStart).set({ 'hour': this.parent.registrationTimeStart.get('hour'), 'minute': this.parent.registrationTimeStart.get('minute') })
                                        const endDate = moment(this.parent.registrationDateEnd).set({ 'hour': endTime.get('hour'), 'minute': endTime.get('minute') })
                                        return endDate.isAfter(startDate);
                                    }
                                    return false;
                                },
                            }),
                        activity_period_id: string(),
                    })}
                    onSubmit={(val, { setSubmitting }) => {
                        const values = { ...val };
                        delete values.period_id;

                        const builtOnlineStoreData = {
                            ...createI18nObjectFromApi(values),
                            // Combining the date and it's corresponding time to send to the api as one ISO dateTime string
                            registrationDateStart: moment(values.registrationDateStart).set({ 'hour': values.registrationTimeStart.get('hour'), 'minute': values.registrationTimeStart.get('minute') }).toISOString(),
                            registrationDateEnd: moment(values.registrationDateEnd).set({ 'hour': values.registrationTimeEnd.get('hour'), 'minute': values.registrationTimeEnd.get('minute') }).toISOString(),
                        }

                        if(wizard){
                            onlineStoreContext.createOnlineStore(builtOnlineStoreData)
                                .then((onlineStoreId) => {
                                    setSubmitting(false);
                                    onlineStoreContext.updateCachedStore({
                                        ...values,
                                        online_store_id: onlineStoreId,
                                        start_date: moment(values.registrationDateStart).set({ 'hour': values.registrationTimeStart.get('hour'), 'minute': values.registrationTimeStart.get('minute') }).toISOString(),
                                        end_date: moment(values.registrationDateEnd).set({ 'hour': values.registrationTimeEnd.get('hour'), 'minute': values.registrationTimeEnd.get('minute') }).toISOString(),
                                    })
                                    save?.({
                                        ...values,
                                        online_store_id: onlineStoreId,
                                        start_date: moment(values.registrationDateStart).set({ 'hour': values.registrationTimeStart.get('hour'), 'minute': values.registrationTimeStart.get('minute') }).toISOString(),
                                        end_date: moment(values.registrationDateEnd).set({ 'hour': values.registrationTimeEnd.get('hour'), 'minute': values.registrationTimeEnd.get('minute') }).toISOString(),
                                    })
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message);
                                        setSubmitting(false);
                                    }
                                })
                        }else{
                            onlineStoreContext.updateOnlineStore(props.onlineStoreId, builtOnlineStoreData)
                                .then(() => {
                                    setSubmitting(false);
                                    onlineStoreContext.getOnlineStore(props.onlineStoreId, {}, true)
                                    save?.({
                                        ...values,
                                        online_store_id: props.onlineStoreId,
                                        start_date: moment(values.registrationDateStart).set({ 'hour': values.registrationTimeStart.get('hour'), 'minute': values.registrationTimeStart.get('minute') }).toISOString(),
                                        end_date: moment(values.registrationDateEnd).set({ 'hour': values.registrationTimeEnd.get('hour'), 'minute': values.registrationTimeEnd.get('minute') }).toISOString(),
                                    })
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                        setSubmitting(false);
                                    }
                                })
                        }
                    }}
                >
                    {(formik) => (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                {wizard &&
                                    <>
                                        <CustomAlert
                                            color='info'
                                            withTitle
                                            text={
                                                <Translate
                                                    id='onlineStore.components.onlineStoreGeneralInformation.field.period.text'
                                                    values={{
                                                        season: <span className='font-bold'><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.period.season' /></span>,
                                                    }}
                                                />
                                            }
                                            translateText={false}
                                        />
                                        <Row form>
                                            <Col xs={6} className="mb-3">
                                                <Label className='text-muted'>
                                                    <Translate id='onlineStore.components.onlineStoreGeneralInformation.field.period' /> <Required />
                                                </Label>
                                                <FormikSelect
                                                    name="period_id"
                                                    renderSelectedOption={(option) => <DisplayI18n defaultValue={option.label} i18n={option.i18n} field="name" />}
                                                    renderOption={({ option, isSelected }) => (
                                                        <>
                                                            <DisplayI18n defaultValue={option.label} i18n={option.i18n} field="name" />
                                                            {option.current == 1 && <small className={`d-block text-${isSelected ? "light" : "muted"}`}><Translate id="members.profile.memberProfile.header.positions.currentPeriod" /></small>}
                                                        </>
                                                    )}
                                                    searchKeys={[ `i18n.${getGenericLocale()}.name` ]}
                                                    options={activePeriods.reduce((shownP, period) => {
                                                        shownP.push({
                                                            ...period,
                                                            value: period.period_id,
                                                            i18n: period.i18n,
                                                            label: period.name,
                                                        })
                                                        return shownP;
                                                    }, [])}
                                                    defaultValues={[ ]}
                                                    onOptionSelected={(values) => {
                                                        setSelectedPeriod(activePeriods.find((p) => p.period_id == values[0]), () => {
                                                            updateStoreSeason(values[0]);
                                                        });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <Row form>
                                    <Col sm={6} className="mb-3">
                                        <Label for='languages' className='text-muted'><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.languages' /> <Required /></Label>
                                        <FormikSelect
                                            multi search={false}
                                            name='languages'
                                            id='languages_FormOnlineStoreGeneralInformation'
                                            options={orgContext.settings.language.value.map((lang) => ({
                                                key: lang,
                                                id: lang,
                                                value: lang,
                                                label: `misc.${lang}.text`,
                                                translateLabel: true,
                                                selected: formik.initialValues.languages.includes(lang),
                                            }))}
                                        />
                                    </Col>
                                    <CanDoAction tag={Col} sm="6" action='EDIT' componentCode='catalog' componentPermissionCode='online_store_manage_skip_postal_code' skipAccess>
                                        <Label for="skip_postal_code_validation_FormOnlineStoreGeneralInformation" className="text-muted">
                                            <Translate id="onlineStore.components.onlineStoreGeneralInformation.field.bypassPostalCode" /> <Required />
                                        </Label>
                                        <FormikSelect
                                            search={false}
                                            name="skip_postal_code_validation"
                                            id='skip_postal_code_validation_FormOnlineStoreGeneralInformation'
                                            options={[
                                                {
                                                    label: "misc.yes",
                                                    translateLabel: true,
                                                    value: '0', // **needed double negative to "enable". inverted the value from the meaning of the label to make it more simple
                                                },
                                                {
                                                    label: "misc.no",
                                                    translateLabel: true,
                                                    value: '1', // **needed double negative to "enable". inverted the value from the meaning of the label to make it more simple
                                                },
                                            ]}
                                        />
                                    </CanDoAction>
                                </Row>

                                {/* form Names */}
                                <Row form>
                                    {formik.values.languages.map((lang) => (
                                        <Col sm="6" key={`i18n.${lang}.name`}>
                                            <FormGroup>
                                                <Label for={`i18n.${lang}.name`} className='text-muted'><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.formName' /> (<Translate id={`misc.${lang}.text.noCapital`} />) <Required /> </Label>
                                                <FormikInputText id={`i18n.${lang}.name`} name={`i18n.${lang}.name`} />
                                            </FormGroup>
                                        </Col>
                                    ))}
                                </Row>

                                {/* registrar emails */}
                                <Row form>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label for='email' className='text-muted'><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.registrarEmail' /> <Required /></Label>
                                            <FormikInputText id='email' name='email' trim type='email' />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikCheckedButton id='send_email_cc' name='send_email_cc' label='onlineStore.components.onlineStoreGeneralInformation.field.registrarEmail.sendTo' />
                                        </FormGroup>
                                    </Col>
                                    {/* <Col md="6">
                                        <FormGroup>
                                            <Label for='registrarEmail2' className='text-muted'><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.registrarEmail'/> 2 (<Translate id='onlineStore.components.onlineStoreGeneralInformation.optional.male'/>)</Label>
                                            <FormikInputText id='registrarEmail2' name='registrarEmail2'/>
                                        </FormGroup>
                                        <FormGroup>
                                            <FormikCheckedButton id='sendEmail2' name='sendEmail2' label='onlineStore.components.onlineStoreGeneralInformation.field.registrarEmail.sendTo' />
                                        </FormGroup>
                                    </Col> */}
                                </Row>

                                {/* Registration Periods */}
                                <div className="h4 font-bold mb-3 mt-5"><Translate id='onlineStore.components.onlineStoreGeneralInformation.section.registrationPeriod' /></div>
                                <Row form>
                                    <Col sm="6" lg="3">
                                        <FormGroup>
                                            <Label for='registrationDateStart' className='text-muted'><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.startDate' /> <Required /></Label>
                                            <FormikDateTime name='registrationDateStart' id='registrationDateStart' timeFormat={false} dateFormat='YYYY-MM-DD' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" lg="3">
                                        <FormGroup>
                                            <Label for='registrationTimeStart' className='text-muted'><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.startTime' /> <Required /></Label>
                                            <FormikDateTime
                                                name='registrationTimeStart' id='registrationTimeStart' dateFormat={false}
                                                timeFormat='h:mm A'
                                                onClose={(input) => {
                                                    if(input && !moment.isMoment(formik.values.registrationTimeStart) && moment(input, [ "H:mm" ]).isValid()){
                                                        formik.setFieldValue('registrationTimeStart', moment(input, [ "H:mm" ]));
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" lg="3">
                                        <FormGroup>
                                            <Label for='registrationDateEnd' className='text-muted'><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.endDate' /><Required /></Label>
                                            <FormikDateTime
                                                name='registrationDateEnd' id='registrationDateEnd' timeFormat={false}
                                                dateFormat='YYYY-MM-DD'
                                                // Can select a date when start date is defined AND
                                                isValidDate={(currentValue) => moment.isMoment(formik.values.registrationDateStart) && moment(currentValue).isAfter(moment(formik.values.registrationDateStart).subtract(1, 'days'))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" lg="3">
                                        <FormGroup>
                                            <Label for='registrationTimeEnd' className='text-muted'><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.endTime' /><Required /></Label>
                                            <FormikDateTime
                                                name='registrationTimeEnd' id='registrationTimeEnd' dateFormat={false}
                                                timeFormat='h:mm A'
                                                onClose={(input) => {
                                                    if(input && !moment.isMoment(formik.values.registrationTimeEnd) && moment(input, [ "H:mm" ]).isValid()){
                                                        formik.setFieldValue('registrationTimeEnd', moment(input, [ "H:mm" ]));
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {orgContext.settings.enable_organization_activity_periods?.value == '1' &&
                                        <Col sm="12" lg="6">
                                            <FormGroup>
                                                <Label for='activity_period_id' className='text-muted'><Translate id='form.fields.activityPeriod' /></Label>
                                                <FormikSelect
                                                    clearable
                                                    name="activity_period_id"
                                                    id="activity_period_id"
                                                    renderOption={(option) => (
                                                        <>
                                                            <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                                            <div className="small text-muted"><DisplayI18n field='description' defaultValue={option.option.description} i18n={option.option.i18n} /></div>
                                                        </>
                                                    )}
                                                    loadData={(from) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return getOrganizationActivityPeriods({ organisation_id: orgContext.organisation_id, active: '1' })
                                                                    .then((activityPeriods) => (activityPeriods.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order)).map((activityPeriod) => ({
                                                                        value: activityPeriod.activity_period_id,
                                                                        label: activityPeriod.name,
                                                                        description: activityPeriod.description,
                                                                        i18n: activityPeriod.i18n,
                                                                    }))));
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <div className="h4 font-bold mb-3 mt-5"><Translate id='onlineStore.components.onlineStoreGeneralInformation.section.message' /></div>
                                {formik.values.languages.length === 0 && // No language means that there won't be any confirmation message displayed
                                    <Translate id='onlineStore.components.onlineStoreGeneralInformation.hint.pickLanguage' />
                                }
                                {formik.values.languages.map((lang) => (
                                    <FormGroup key={`i18n.${lang}.confirmation_message_email`}>
                                        <Label for={`i18n.${lang}.confirmation_message_email`} className='text-muted'><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.confirmMessage' /> (<Translate id={`misc.${lang}.text`} />)</Label>
                                        <FormikTiny id={`i18n.${lang}.confirmation_message_email`} name={`i18n.${lang}.confirmation_message_email`} />
                                    </FormGroup>
                                ))}
                                <div className="text-right">
                                    <Button color="primary" type='submit' disabled={formik.isSubmitting}>
                                        {wizard ?
                                            <Translate id='misc.next' />
                                            :
                                            <Translate id='misc.save' />
                                        }
                                    </Button>
                                    {cancel && // in first step of wizard, we have no previous
                                        <Button color="primary" onClick={cancel} type='button' outline className="ml-2" disabled={formik.isSubmitting}>
                                            {wizard ?
                                                <Translate id='misc.previous' />
                                                :
                                                <Translate id='misc.cancel' />
                                            }
                                        </Button>
                                    }
                                </div>
                            </OverlayLoader>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
}

export default FormOnlineStoreGeneralInformation;

FormOnlineStoreGeneralInformation.propTypes = {
    cancel: PropTypes.func,
    save: PropTypes.func.isRequired,
    wizard: PropTypes.bool,
};

FormOnlineStoreGeneralInformation.defaultProps = {
    wizard: false,
};