import React from "react";
import {
    Label
} from "reactstrap";
import FormikEditable from "../../../../../../components/formik/FormikEditable";
import { FormikInputText, FormikSelect } from '@spordle/formik-elements';
import { success, fail } from '@spordle/toasts';
import PropTypes from 'prop-types';

// Context
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import withContexts from "../../../../../../helpers/withContexts";

// Language
import Translate from "@spordle/intl-elements";
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../../../../helpers/i18nHelper";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import FormikTiny from "../../../../../../components/formik/FormikTiny";
import { object, string } from "yup";

class SidePanelOrganizationDocumentTypes extends React.Component{
    state = {
        isLoading: false,
        canEdit: this.props.RolesContext.canDoAction('EDIT', 'settings', 'document_types'),
        readOnly: this.props.selectedRows[0].organisation.organisation_id !== this.props.OrganizationContext.organisation_id,
    };

    toggleLoading = (bool) => {
        this.setState((prevState) => ({ isLoading: bool ?? !prevState.isLoading }))
    }

    render(){
        return (
            <I18nHelperContext.Consumer>
                { (i18nHelper) => (
                    <OverlayLoader isLoading={this.state.isLoading}>
                        <SidePanel.Header>
                            <div className='d-flex mb-2 align-items-center'>
                                <SidePanel.ToggleButton />
                                <SidePanel.ActionsMenu action='DELETE' componentCode='settings' componentPermissionCode='contact_type'>
                                    <SidePanel.MenuDelete
                                        disabled={this.state.readOnly}
                                        translateModalMsg
                                        modalMsg='settings.documentTypes.delete.text'
                                        modalTitle={<DisplayI18n field="name" i18n={this.props.getCommonValue('i18n')} defaultValue={this.props.getCommonValue('name')} />}
                                        onConfirm={() => {
                                            return this.props.OrganizationContext.deleteOrganizationDocumentType(this.props.getCommonValue('document_type_id'))
                                                .then(() => {
                                                    this.props.tableRef.deleteRow(this.props.getCommonValue('document_type_id'))
                                                        .then(() => {
                                                            success();
                                                            this.props.forceCloseSidePanel();
                                                        })
                                                })
                                                .catch((e) => {
                                                    if(!AxiosIsCancelled(e.message)){
                                                        console.error(e);
                                                        fail();
                                                    }
                                                })
                                        }}
                                    />
                                </SidePanel.ActionsMenu>
                            </div>
                            <SidePanel.Title>
                                <DisplayI18n
                                    field='name'
                                    i18n={this.props.selectedRows[0].i18n}
                                    defaultValue={this.props.selectedRows[0].name}
                                />
                            </SidePanel.Title>
                            <div className="small">
                                <DisplayI18n
                                    field='name'
                                    i18n={this.props.selectedRows[0].organisation.i18n}
                                    defaultValue={this.props.selectedRows[0].organisation.organisation_name}
                                />
                            </div>
                        </SidePanel.Header>
                        <div className='p-3'>
                            <RenderI18nForm field="name">
                                {({ fieldName, fieldLabel }) => {
                                    return (
                                        <div className="mb-3" key={fieldName}>
                                            <Label for={fieldName} className="text-muted">{fieldLabel}</Label>
                                            <FormikEditable
                                                disabled={!this.state.canEdit}
                                                readOnly={this.state.readOnly}
                                                id={fieldName}
                                                initialValues={
                                                    i18nHelper.getInitialValues(this.props.selectedRows[0])
                                                }
                                                validationSchema={object().shape(
                                                    i18nHelper.getValidationSchema({ name: string().required() }),
                                                )}
                                                onSubmit={(values) => {
                                                    const newValues = this.props.createNewValues(values);

                                                    this.toggleLoading(true);
                                                    this.props.OrganizationContext.updateOrganizationDocumentType(this.props.getCommonValue('document_type_id'), i18nHelper.getAPIValues(newValues))
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                            this.toggleLoading(false);
                                                        })
                                                        .catch((error) => {
                                                            console.error(error.message)
                                                            this.toggleLoading(false);
                                                            fail();
                                                        })
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark'>
                                                                <DisplayI18n
                                                                    field={fieldName}
                                                                    defaultValue={this.props.selectedRows[0].name || '-'}
                                                                    i18n={this.props.selectedRows[0].i18n}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikInputText id={fieldName} name={fieldName} autoFocus />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    )
                                }}
                            </RenderI18nForm>

                            <RenderI18nForm field="description">
                                {({ fieldName, fieldLabel, lang }) => {
                                    return (
                                        <div className="mb-3" key={fieldName}>
                                            <Label for={fieldName} className="text-muted">{fieldLabel}</Label>
                                            <FormikEditable
                                                disabled={!this.state.canEdit}
                                                readOnly={this.state.readOnly}
                                                id={fieldName}
                                                initialValues={i18nHelper.getInitialValues(this.props.selectedRows[0])}
                                                validationSchema={object().shape(
                                                    i18nHelper.getValidationSchema({ name: string().required() }),
                                                )}
                                                onSubmit={(values) => {
                                                    const newValues = this.props.createNewValues(values);

                                                    this.toggleLoading(true);
                                                    this.props.OrganizationContext.updateOrganizationDocumentType(this.props.getCommonValue('document_type_id'), i18nHelper.getAPIValues(newValues))
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            success();
                                                            this.toggleLoading(false);
                                                        })
                                                        .catch((error) => {
                                                            console.error(error.message)
                                                            this.toggleLoading(false);
                                                            fail();
                                                        })
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div
                                                                className='font-medium text-dark'
                                                                dangerouslySetInnerHTML={{
                                                                    __html: this.props.selectedRows[0].i18n?.[lang]?.description || "-",
                                                                }}
                                                            />
                                                        )
                                                    }

                                                    return (
                                                        <FormikTiny id={fieldName} name={fieldName} rows="6" autoFocus />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    )
                                }}
                            </RenderI18nForm>

                            <div className="mb-3">
                                <Label for="status" className="text-muted"><Translate id='settings.documentTypes.status' /></Label>
                                <FormikEditable
                                    disabled={!this.state.canEdit}
                                    readOnly={this.state.readOnly}
                                    id="status"
                                    noConfirmation
                                    initialValues={{
                                        status: this.props.selectedRows[0].active,
                                    }}
                                    onSubmit={(values, actions) => {
                                        if(values.status !== this.props.selectedRows[0].active){
                                            this.toggleLoading(true);
                                            const newValues = this.props.createNewValues({ active: values.status });
                                            this.props.OrganizationContext.updateOrganizationDocumentType(this.props.getCommonValue('document_type_id'), { active: values.status })
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    success();
                                                    this.toggleLoading(false);
                                                })
                                                .catch((error) => {
                                                    console.error(error.message)
                                                    this.toggleLoading(false);
                                                    fail();
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>{this.props.selectedRows[0].active === '1' ? 'Active' : 'Inactive'}</div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                id='status' name='status'
                                                autoFocus menuIsDefaultOpen
                                                onOptionSelected={() => {
                                                    options.stopEditing()
                                                }}
                                                defaultData={[
                                                    { value: '1', label: 'misc.active', translateLabel: true },
                                                    { value: '0', label: 'misc.inactive', translateLabel: true },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                        </div>

                    </OverlayLoader>
                )}
            </I18nHelperContext.Consumer>
        )
    }
}

SidePanelOrganizationDocumentTypes.propTypes = {
    tableRef: PropTypes.object.isRequired,
}

export default withContexts(OrganizationContext, RolesContext)(SidePanelOrganizationDocumentTypes);
