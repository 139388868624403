import Translate from "@spordle/intl-elements";
import { Card, Col, Row } from "reactstrap";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import ClinicCategoryBuilder from "../../../../clinics/ClinicCategoryBuilder";
import { Link } from 'react-router-dom';
import InlineCopy from "../../../../../components/inlineCopy/InlineCopy";

const MemberQualificationsAttendees = ({ selectedRows }) => {
    const attendee = selectedRows[0];

    return (
        <>
            <Card className="border bg-light-inverse p-2 mb-3">
                <Translate id='members.profile.qualifications.sidePanel.registeredAs' />
            </Card>
            <Row form>
                <Col sm="6" className="form-group">
                    <div className="text-muted"><Translate id='members.profile.qualifications.season' /></div>
                    <div className="font-medium text-dark">
                        <DisplayI18n
                            field='name'
                            defaultValue={attendee.period.name}
                            i18n={attendee.period.i18n}
                        />
                    </div>
                </Col>
                <Col sm="12" className="form-group">
                    <div className="text-muted">Clinic</div>
                    <div className="font-medium text-dark">
                        <ClinicCategoryBuilder hideColor qualification={attendee.qualification} />
                        {attendee.clinic.clinic_reference ?
                            <InlineCopy toCopy={attendee.clinic.clinic_reference} className="d-flex align-items-center">
                                <Link className="d-block small" to={`/clinics/profile/${attendee.clinic.clinic_id}/attendees`}>
                                            #{attendee.clinic.clinic_reference} <i className="mdi mdi-chevron-right" />
                                </Link>
                            </InlineCopy>
                            :
                            <Link className="d-block small" to={`/clinics/profile/${attendee.clinic.clinic_id}/attendees`}>
                                <Translate id='teams.profile.roster.sidePanel.qualification.goTo' /> <i className="mdi mdi-chevron-right" />
                            </Link>
                        }
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default MemberQualificationsAttendees;