import components from "./components";
import create from "./create";
import profile from "./profile";
import search from "./search";

export default{
    ...components,
    ...create,
    ...search,
    ...profile,
}