import ManageSingleNews from "../../views/news/manageSingleNews/ManageSingleNews";

const newsSidebar = [
    {
        exact: true,
        name: "misc.add",
        path: '/news/create',
        permissions: { code: 'communications', permissionCode: 'communication_news_manage', actions: [ 'ADD' ] },
        component: ManageSingleNews,
        tempTab: true,
        noLink: true,
    },
    {
        exact: true,
        name: "misc.edit",
        path: '/news/edit',
        skipRecents: true,
        permissions: { code: 'communications', permissionCode: 'communication_news_manage', actions: [ 'EDIT' ] },
        component: ManageSingleNews,
        tempTab: true,
        noLink: true,
    },
];

export default newsSidebar;