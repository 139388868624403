import { FormikDateTime, FormikTextArea } from '@spordle/formik-elements';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { fail, success } from '@spordle/toasts';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useContext, useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { Link } from 'react-router-dom';
import {
    Button, Card, Col, Collapse, FormGroup, Input, Label, ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import { object, string } from 'yup';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import CrossFade from '../../../../components/crossFade/CrossFade';
import CustomAlert from '../../../../components/CustomAlert';
import CustomAnimatedIcon from '../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import FileViewer from '../../../../components/fileViewer/FileViewer';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import QuickViewButton from '../../../../components/quickView/QuickViewButton';
import { formatFileName, getIcon } from '../../../../components/uploader/uploadHelpers';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { MembersContext } from '../../../../contexts/MembersContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';

const defaultCrop = {
    restrictPosition: false,
    showGrid: false,
    cropSize: {
        width: 150,
        height: 150,
    },
    zoom: 1,
    aspect: 1,
    cropShape: 'round',
}

//IMPORTANT NOTE: CHAINING MULTIPLE DOCUMENTS IS NOT CURRENTLY COMPATIBLE WITH memberProfileMode
const MemberAttachmentApprobationModal = ({ toggle, isOpen, selectedRows, spordleTable, forceCloseSidePanel, memberProfileMode }) => {
    const membersContext = useContext(MembersContext)

    // fileviewer for non Profile picture document types
    const [ isFileViewerOpen, setFileViewerOpen ] = useState(false);

    // the attachment currently being approved, this is made this way to support approval of multiple attachments in a row
    const [ currentlyApprovingRow, setCurrentlyApprovingRow ] = useState(selectedRows[0]);

    // file management
    const [ selectedFile, setSelectedFile ] = useState({ crop: { x: 0, y: 0 }, cropSize: defaultCrop.cropSize, zoom: 1 });
    const [ isLoadingFile, setIsLoadingFile ] = useState(false);

    // state of buttons at bottom of page
    // possible state: 'INITIAL', 'REFUSE', 'APPROVE'
    const [ buttonState, setButtonState ] = useState('INITIAL');

    // for chaining multiple documents one after one another
    const [ currentRowIndex, setCurrentRowIndex ] = useState(0);

    // hasApprovedAtLeastOne
    const [ hasApprovedAtLeastOne, setHasApprovedAtLeastOne ] = useState(false);

    const getAttachmentFile = ({ member_id, member_attachement_id }) => {
        setIsLoadingFile(true)
        return membersContext.getMemberAttachmentDownloadLink(member_id, member_attachement_id)
            .then((file) => {
                setSelectedFile((prev) => ({ ...prev, src: file.full_path }))
                setIsLoadingFile(false)
                return file;
            }).catch((error) => {
                setIsLoadingFile(false)
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    // for single approval and so that the first attachment is in state at first open
    useEffect(async() => {
        await setCurrentlyApprovingRow(selectedRows[0])
        if(selectedRows[0].document_type?.system === 'MEMBER_PROFILE_PICTURE'){
            // this part is only used for MEMBER_PROFILE_PICTURE
            getAttachmentFile({
                member_id: memberProfileMode ? membersContext.currentMember.member_id : selectedRows[0].member_id,
                member_attachement_id: selectedRows[0].member_attachement_id,
            });
        }
    }, [ selectedRows[0].member_attachement_id ])

    const nextRowIndex = currentRowIndex + 1;

    const nextApproval = async(formik) => {
        if(nextRowIndex !== selectedRows.length){
            await setCurrentlyApprovingRow(selectedRows[nextRowIndex])
            setCurrentRowIndex(nextRowIndex)
            getAttachmentFile({
                member_attachement_id: selectedRows[nextRowIndex]?.member_attachement_id,
                member_id: selectedRows[nextRowIndex]?.member_id,
            })
        }else{
            // show success dialog
            formik.setStatus('success')
        }
    }

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='ApproveUnconfirmedOrganizationsModal'>
            <Formik
                initialValues={{
                    note: '',
                    expiration_date: selectedRows[0].expiration_date ? moment(selectedRows[0].expiration_date) : '',
                }}
                onSubmit={(data, formik) => {
                    formik.setSubmitting(true)
                    return membersContext.updateMemberDocumentStatus([
                        {
                            member_attachement_id: currentlyApprovingRow.member_attachement_id,
                            review_status: buttonState === 'APPROVE' ? 'APPROVED' : buttonState === 'REFUSE' ? 'REJECTED' : 'PENDING',
                            note: !!data.note && buttonState === 'REFUSE' ? data.note : '',
                            expiration_date: data.expiration_date ? moment(data.expiration_date).format('YYYY-MM-DD') : '',
                            file_position: JSON.stringify({
                                x: selectedFile.crop.x,
                                y: selectedFile.crop.y,
                                cropSize: selectedFile.cropSize,
                                zoom: selectedFile.zoom,
                                ...selectedFile.dimensions,
                            }),
                        },
                    ])
                        .then(() => {
                            setHasApprovedAtLeastOne(true);
                            formik.resetForm()
                            if(selectedRows.length === 1){
                                formik.setStatus('success');
                            }else if(selectedRows.length > 1){
                                setButtonState('INITIAL');
                                success();
                                nextApproval(formik);
                            }

                            if(memberProfileMode)
                                membersContext.getAllMemberInfos(membersContext.currentMember.member_id)
                        }).catch((error) => {
                            formik.setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            if(AxiosIsCancelled(error.message)){
                                console.error(error.message);
                                formik.setSubmitting(false);
                            }
                        })

                }}
                validationSchema={object().shape({
                    note: string().test({
                        name: 'noteRequiredToRefuse',
                        message: <Translate id='task.document.approval.modal.refuse.validation' />,
                        test: (note) => {
                            if(buttonState === 'REFUSE'){
                                return !!note;
                            }
                            return true // not required
                        },
                    }),
                })}
            >
                {(formik) => (
                    <>
                        <ModalHeader toggle={() => {
                            if(hasApprovedAtLeastOne){
                                spordleTable.refreshTable();
                                forceCloseSidePanel();
                            }
                            toggle();
                        }}
                        >
                            <Translate id='task.document.approval.modal.title.type' />
                        </ModalHeader>
                        <OverlayLoader isLoading={isLoadingFile}>
                            <Form>
                                <CrossFade isVisible={formik.status !== 'success'}>
                                    <OverlayLoader isLoading={formik.isSubmitting}>
                                        <ModalBody>
                                            <Card className='card-shadow p-3'>
                                                <UserDisplay className='w-100'>
                                                    <UserDisplay.Container>
                                                        <UserImg
                                                            alt={memberProfileMode ? membersContext.currentMember.first_name + ' ' + membersContext.currentMember.last_name : currentlyApprovingRow.first_name + ' ' + currentlyApprovingRow.last_name}
                                                            abbr={memberProfileMode ? membersContext.currentMember.first_name.charAt(0) + membersContext.currentMember.last_name.charAt(0) : currentlyApprovingRow.first_name.charAt(0) + currentlyApprovingRow.last_name.charAt(0)}
                                                            src={memberProfileMode ? membersContext.currentMember.picture?.full_path : currentlyApprovingRow.picture?.full_path}
                                                            filePos={memberProfileMode ? membersContext.currentMember.picture?.file_position : currentlyApprovingRow.picture?.file_position}
                                                            width={60}
                                                            className="mr-2"
                                                        />
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container className="w-100">
                                                        <UserDisplay.Title className="d-flex justify-content-between">
                                                            <div className='d-flex justify-content-start'>
                                                                <QuickViewButton member={{ member_id: memberProfileMode ? membersContext.currentMember.member_id : currentlyApprovingRow.member_id }}>
                                                                    {memberProfileMode ? membersContext.currentMember.first_name + ' ' + membersContext.currentMember.last_name : currentlyApprovingRow.first_name + ' ' + currentlyApprovingRow.last_name}
                                                                </QuickViewButton>
                                                            </div>
                                                        </UserDisplay.Title>
                                                        <UserDisplay.Subtitle>
                                                            <Link className="text-nowrap" to={`/members/profile/${memberProfileMode ? membersContext.currentMember.member_id : currentlyApprovingRow.member_id}`}>
                                                                {(memberProfileMode ? membersContext.currentMember.unique_identifier : currentlyApprovingRow.unique_identifier) ?
                                                                    <>#{memberProfileMode ? membersContext.currentMember.unique_identifier : currentlyApprovingRow.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                                    :
                                                                    '-'
                                                                }
                                                            </Link>
                                                        </UserDisplay.Subtitle>
                                                        {currentlyApprovingRow.document_type?.system === 'MEMBER_PROFILE_PICTURE' &&
                                                                    <UserDisplay.Subtitle><i className='mdi mdi-information-outline text-primary mr-1' /><Translate id='task.document.approval.modal.current.picture.preview' /></UserDisplay.Subtitle>
                                                        }
                                                    </UserDisplay.Container>
                                                </UserDisplay>
                                            </Card>
                                            <h6 className='my-2'>
                                                <span className='text-muted'><Translate id='task.document.approval.document.type' />:</span>
                                                <span className='text-dark ml-1'>
                                                    <DisplayI18n
                                                        field="name"
                                                        defaultValue={currentlyApprovingRow.document_type?.name}
                                                        i18n={currentlyApprovingRow.document_type?.i18n}
                                                    />
                                                </span>
                                            </h6>
                                            <h6 className='mb-2'>
                                                <span className='text-muted'><Translate id='task.document.approval.filename' />:</span>
                                                <span className='text-dark ml-1'>
                                                    {currentlyApprovingRow.attachment.file_name}
                                                </span>
                                            </h6>
                                            {currentlyApprovingRow.document_type?.system === 'MEMBER_PROFILE_PICTURE'
                                                        && (currentlyApprovingRow.attachment.file_ext === 'jpeg' || currentlyApprovingRow.attachment.file_ext === 'jpg' || currentlyApprovingRow.attachment.file_ext === 'png') ?
                                                <div>
                                                    <div style={{ height: '35%' }} className='text-center py-3'>
                                                        <div className='mb-2'>
                                                            {/* CROPPER CROPPING */}
                                                            <CrossFade isVisible={buttonState === 'INITIAL'}>
                                                                <div className="position-relative rounded-lg overflow-hidden" style={{ height: selectedFile.cropSize.height + 50 }}>
                                                                    <Cropper
                                                                        {...{ ...defaultCrop }}
                                                                        zoomSpeed={0.1}
                                                                        image={selectedFile.src}
                                                                        minZoom={0.1}
                                                                        maxZoom={3}
                                                                        crop={selectedFile.crop}
                                                                        zoom={selectedFile.zoom}
                                                                        onCropChange={(crop) => setSelectedFile((prev) => ({ ...prev, crop: crop }))}
                                                                        onZoomChange={(zoom) => setSelectedFile((prev) => ({ ...prev, zoom: zoom }))}
                                                                        onMediaLoaded={(mediaSize) => {
                                                                            // fixed zoom, it was set super zoomed out initially
                                                                            setSelectedFile((prev) => ({ ...prev, zoom: 1, dimensions: { width: mediaSize.width, height: mediaSize.height } }));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </CrossFade>
                                                            {/* CROPPED IMAGE PREVIEW WHEN CONFIRMING */}
                                                            <CrossFade isVisible={buttonState !== 'INITIAL'}>
                                                                <div
                                                                    className='w-100 d-flex align-items-center justify-content-center position-relative rounded-lg'
                                                                    style={{ height: selectedFile.cropSize.height + 50, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                                                                >
                                                                    <UserImg
                                                                        abbr={memberProfileMode ? `${membersContext.currentMember.first_name.charAt(0)}${membersContext.currentMember.last_name.charAt(0)}` : `${currentlyApprovingRow.first_name.charAt(0)}${currentlyApprovingRow.last_name.charAt(0)}`}
                                                                        src={selectedFile?.src}
                                                                        width="150"
                                                                        filePos={JSON.stringify({
                                                                            x: selectedFile.crop.x,
                                                                            y: selectedFile.crop.y,
                                                                            cropSize: selectedFile.cropSize,
                                                                            zoom: selectedFile.zoom,
                                                                            ...selectedFile.dimensions,
                                                                        })}
                                                                        alt={memberProfileMode ? `${membersContext.currentMember.first_name.charAt(0)}${membersContext.currentMember.last_name.charAt(0)}` : `${currentlyApprovingRow.first_name.charAt(0)}${currentlyApprovingRow.last_name.charAt(0)}`}
                                                                        className="p-4"
                                                                    />
                                                                </div>
                                                            </CrossFade>
                                                        </div>

                                                        <div className="d-flex">
                                                            <button disabled={buttonState !== 'INITIAL'} className="reset-btn" type="button" onClick={() => (selectedFile.zoom - 0.1) >= 0.1 && setSelectedFile((prev) => ({ ...prev, zoom: prev.zoom - 0.1 }))}>
                                                                <i className={`${buttonState === 'INITIAL' ? 'text-primary' : 'text-muted'} font-20 mdi mdi-magnify-minus-outline`} />
                                                            </button>
                                                            <Input
                                                                disabled={buttonState !== 'INITIAL'}
                                                                value={selectedFile.zoom}
                                                                onChange={(e) => {
                                                                    setSelectedFile((prev) => ({ ...prev, zoom: parseFloat(e.target.value) }))
                                                                }}
                                                                type="range"
                                                                min={0.1}
                                                                max={3}
                                                                step={0.1}
                                                            />
                                                            <button disabled={buttonState !== 'INITIAL'} className="reset-btn" type="button" onClick={() => (selectedFile.zoom + 0.1) <= 3 && setSelectedFile((prev) => ({ ...prev, zoom: prev.zoom + 0.1 }))}>
                                                                <i className={`${buttonState === 'INITIAL' ? 'text-primary' : 'text-muted'} font-20 mdi mdi-magnify-plus-outline`} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <CustomAlert
                                                        className='mt-2 mb-0'
                                                        color='info'
                                                        translateText
                                                        text={'task.document.approval.decide.if.appropriate.resize'}
                                                    />
                                                </div>
                                                :
                                                <div>
                                                    <div className='d-flex w-100 align-items-center justify-content-center'>
                                                        <div
                                                            onClick={() => !isLoadingFile && setFileViewerOpen(true)}
                                                            style={{ height: 150, width: 150 }}
                                                            className="mb-3 rounded-lg overflow-hidden border shadow-sm clickable card-hover d-flex align-items-center justify-content-center position-relative"
                                                        >
                                                            <FileViewer
                                                                key={currentlyApprovingRow.member_attachement_id}
                                                                fullPath={() => getAttachmentFile({
                                                                    member_attachement_id: currentlyApprovingRow.member_attachement_id,
                                                                    member_id: memberProfileMode ? membersContext.currentMember.member_id : currentlyApprovingRow.member_id,
                                                                })}
                                                                createdBy={currentlyApprovingRow.identity}
                                                                creationDate={<DateFormat value={currentlyApprovingRow.created_at} format={DateFormat.formats.longMonthDayYearTime} />}
                                                                fileName={currentlyApprovingRow.attachment.file_name}
                                                                type={currentlyApprovingRow.attachment.file_ext}
                                                                isOpen={isFileViewerOpen}
                                                                close={() => setFileViewerOpen(false)}
                                                            />
                                                            <i style={{ fontSize: '40px' }} className={getIcon(currentlyApprovingRow.attachment.file_ext)} />
                                                            <i className="mdi mdi-download position-absolute text-primary top-0 right-0 font-20 p-1" />
                                                            <span className="small font-medium text-dark bg-light-inverse position-absolute bottom-0 left-0 right-0 text-center p-1">
                                                                <span className="d-flex justify-content-center">{formatFileName(currentlyApprovingRow.attachment.file_name)}</span>
                                                                <span className="text-muted"><DateFormat value={currentlyApprovingRow.created_at} utc /></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <CustomAlert
                                                        className='mb-0'
                                                        color='info'
                                                        translateText
                                                        text={'task.document.approval.decide.if.appropriate'}
                                                    />
                                                </div>
                                            }
                                            <FormGroup className='pt-2'>
                                                <Label className='text-muted'><Translate id='reports.filters.expiration_date' /></Label>
                                                <FormikDateTime
                                                    id='expiration_date'
                                                    name='expiration_date'
                                                    timeFormat={false}
                                                    isValidDate={(current) => current.isSameOrAfter(moment(), 'days')}
                                                    disabled={buttonState !== 'INITIAL'}
                                                />
                                            </FormGroup>
                                            {/* ERROR MANAGEMENT */}
                                            {formik.status &&
                                                <Collapse className='pt-2' isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                                    <CustomAlert className='mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                                </Collapse>
                                            }
                                            <div className='pt-2'>
                                                {/* INITIAL STATE */}
                                                <CrossFade isVisible={buttonState === 'INITIAL'}>
                                                    <Row>
                                                        <Col className='pr-2'>
                                                            <Button
                                                                outline
                                                                className='w-100'
                                                                color='danger'
                                                                onClick={() => setButtonState('REFUSE')}
                                                            >
                                                                <Translate id='task.document.approval.modal.refuse' />
                                                            </Button>
                                                        </Col>
                                                        {selectedRows.length > 1 &&
                                                                    <Col className='px-0'>
                                                                        <Button
                                                                            outline
                                                                            className='w-100'
                                                                            color='primary'
                                                                            onClick={() => nextApproval(formik)}
                                                                        >
                                                                            <Translate id='task.document.approval.modal.skip' />
                                                                        </Button>
                                                                    </Col>
                                                        }
                                                        <Col className='pl-2'>
                                                            <Button
                                                                outline
                                                                className='w-100'
                                                                color='success'
                                                                onClick={() => setButtonState('APPROVE')}
                                                            >
                                                                <Translate id='task.document.approval.modal.approve' />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </CrossFade>
                                                {/* APPROVAL STATE: display approval confirmation */}
                                                <CrossFade isVisible={buttonState === 'APPROVE'}>
                                                    <Row>
                                                        <Col className='pr-2'>
                                                            <Button
                                                                outline
                                                                className='w-100'
                                                                color='primary'
                                                                onClick={() => setButtonState('INITIAL')}
                                                            >
                                                                <Translate id='misc.cancel' />
                                                            </Button>
                                                        </Col>
                                                        <Col className='pl-2'>
                                                            <Button
                                                                className='w-100'
                                                                color='success'
                                                                onClick={() => formik.submitForm()}
                                                            >
                                                                <Translate id='task.document.approval.modal.approve.confirm' />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </CrossFade>
                                                {/* REFUSAL STATE: display refusal confirmation and note */}
                                                <CrossFade isVisible={buttonState === 'REFUSE'}>
                                                    <FormikTextArea
                                                        trim
                                                        translatePlaceholder
                                                        placeholder={'task.document.approval.modal.refuse.placeholder'}
                                                        rows={2}
                                                        name='note'
                                                        id='documentRefusalNote'
                                                    />
                                                    <Row className='pt-2'>
                                                        <Col className='pr-2'>
                                                            <Button
                                                                outline
                                                                className='w-100'
                                                                color='primary'
                                                                onClick={() => setButtonState('INITIAL')}
                                                            >
                                                                <Translate id='misc.cancel' />
                                                            </Button>
                                                        </Col>
                                                        <Col className='pl-2'>
                                                            <Button
                                                                className='w-100'
                                                                color='danger'
                                                                onClick={() => formik.submitForm()}
                                                            >
                                                                <Translate id='task.document.approval.modal.refuse.confirm' />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </CrossFade>
                                            </div>

                                        </ModalBody>
                                    </OverlayLoader>
                                </CrossFade>
                                {/* SUCCESS STEP ONLY ACTIVE IF SINGLE APPROVAL */}
                                <CrossFade isVisible={formik.status === 'success'}>
                                    <ModalBody className="text-center">
                                        <CustomAnimatedIcon withCircle className="text-success" size={50} icon="checkmark" />
                                        <div className="h4 font-bold mt-2">
                                            <Translate id="misc.success" />
                                        </div>
                                        <p>
                                            <Translate id={selectedRows.length > 1 ? 'task.document.approval.modal.success.multi' : 'task.document.approval.modal.success'} />
                                        </p>

                                        <Button color="primary" type="button" onClick={() => { toggle(); spordleTable.refreshTable(); forceCloseSidePanel(); }}>
                                            <Translate id="misc.close" />
                                        </Button>
                                    </ModalBody>
                                </CrossFade>
                            </Form>
                        </OverlayLoader>
                    </>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

export default MemberAttachmentApprobationModal;
