import Translate from '@spordle/intl-elements';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';

const NewUserCard = ({ user }) => {

    return (
        <UserDisplay card className={`w-100 mb-3 d-flex${user.error ? ' border-danger' : ''}`}>
            <UserDisplay.Container>
                <UserImg
                    abbr={user.organization?.abbreviation}
                    src={user.organization?.logo?.full_path}
                    filePos={user.organization?.logo?.file_position}
                    alt={user.organization?.organisation_name}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title><b>{user.role.title}</b>: {user.firstName} {user.lastName}</UserDisplay.Title>
                <UserDisplay.Subtitle className="text-dark">{user.email}</UserDisplay.Subtitle>
                { user.error ?
                    <UserDisplay.Subtitle className="text-danger">
                        <Translate id={user.error} />
                    </UserDisplay.Subtitle>
                    :
                    <UserDisplay.Subtitle className="text-dark">
                        {/* <i className="mdi mdi-check-circle text-success mr-1" /> */}
                        {user.isNew ?
                            <Translate id='organization.profile.users.multiple.addUser.createdAndLinked' />
                            :
                            <Translate id='organization.profile.users.multiple.addUser.linked' />
                        }
                    </UserDisplay.Subtitle>
                }
            </UserDisplay.Container>
            <UserDisplay.Container className="ml-auto">
                <i style={{ fontSize: '40px', lineHeight: 1 }} className={`mdi ${user.error ? 'mdi-close-circle text-danger' : 'mdi-check-circle text-success'}`} />
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default NewUserCard;