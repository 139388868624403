import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Button, Card } from "reactstrap";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import SpordlePanelTable from "../../../../components/sidePanel/SpordlePanel";
import { PeriodsContext } from "../../../../contexts/contexts";
import { I18nContext } from "../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { RolesContext } from "../../../../contexts/RolesContext";
import { SupervisionsContext } from "../../../../contexts/SupervisionsContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { PeriodChangeHandler } from "../../../../helpers/periodHelper";
import { DisplayCategory } from "../../../teams/TeamHelpers";
import AddSupervisionForm from "./addModal/AddSupervisionForm";
import ExportDropdown from "./ExportDropdown";
import OfficialSupervisionSidePanel from "./sidePanel/OfficialSupervisionSidePanel";

const OfficiatingReportsList = ({ supervisionTypes }) => {
    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const { organisation_id } = useContext(OrganizationContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const { getSupervisionsList } = useContext(SupervisionsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { canDoAction } = useContext(RolesContext);
    const canEdit = canDoAction("EDIT", "members", "member_national_officiating_coach");

    return (
        <SpordlePanelTable
            id='TasksDiscriminationTable'
            dataIndex='supervision_id'
            allowOutsideClick
            sidePanel={(sProps) => <OfficialSupervisionSidePanel isReadOnly={!canEdit} {...sProps} />}
            table={(panelProps) => (
                <SpordleTableProvider
                    id='officialSupervision_table'
                    tableHover bordered striped
                    clickable
                    ref={panelProps.spordleTableRef}
                    dataIndex='supervision_id'
                    searchKeys={[
                        'supervision_date',
                        'officiating_coach.first_name',
                        'officiating_coach.last_name',
                        'officiating_coach.unique_identifier',
                        'official.first_name',
                        'official.last_name',
                        'official.unique_identifier',
                        'home_team.short_name',
                        'away_team.short_name',
                        `team_category.division.i18n.${getGenericLocale()}.short_name`,
                        `team_category.class.i18n.${getGenericLocale()}.short_name`,
                        'home_team_name',
                        'away_team_name',
                    ]}
                    desktopWhen
                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                    loadData={(from, _data, spordleTable) => {
                        switch (from){
                            case 'REFRESH':
                                spordleTable.setLoading()
                            case 'CDM':
                                return getSupervisionsList({ organisation_id, period_id: selectedPeriod.period_id });
                            default:
                                break;
                        }
                    }}
                    columns={[
                        {
                            label: <Translate id='officialSupervision.supervisionDate.label' />,
                            key: 'supervision_date',
                            sortable: true,
                        },
                        {
                            label: <Translate id='officialSupervision.supervisionType.label' />,
                            key: 'supervision_type',
                            sortable: true,
                            sortKey: `supervision_type.i18n.${getGenericLocale()}.name`,
                            fallbackSortKey: 'supervision_type.name',
                        },
                        {
                            label: <Translate id='officialSupervision.officiatingCoach.label' />,
                            key: 'officiating_coach',
                            sortKey: 'officiating_coach.first_name',
                            fallbackSortKey: 'officiating_coach.last_name',
                            sortable: true,
                        },
                        {
                            label: <Translate id='officialSupervision.official.label' />,
                            key: 'official',
                            sortKey: 'official.first_name',
                            fallbackSortKey: 'official.last_name',
                            sortable: true,
                        },
                        {
                            label: <Translate id='officialSupervision.teamCategory.label' />,
                            key: 'category',
                            sortKey: `team_category.division.i18n.${getGenericLocale()}.short_name`,
                            fallbackSortKey: 'team_category.division.short_name',
                            sortable: true,
                        },
                        {
                            label: <Translate id='officialSupervision.homeTeam.label' />,
                            key: 'home_team',
                            sortKey: 'home_team.short_name',
                            fallbackSortKey: 'home_team_name',
                            sortable: true,
                        },
                        {
                            label: <Translate id='officialSupervision.awayTeam.label' />,
                            key: 'away_team',
                            sortKey: 'away_team.short_name',
                            fallbackSortKey: 'away_team_name',
                            sortable: true,
                        },
                    ]}
                    defaultSorting="-supervision_date"
                    renderRow={(columnKey, supervision) => {
                        switch (columnKey){
                            case 'officiating_coach':
                                return (
                                    <>
                                        <div>{`${supervision.officiating_coach?.first_name} ${supervision.officiating_coach?.last_name}`}</div>
                                        <div className="text-muted small">#{supervision.officiating_coach?.unique_identifier}</div>
                                    </>
                                );
                            case 'supervision_type':
                                return (
                                    <DisplayI18n
                                        field="name"
                                        i18n={supervision.supervision_type.i18n}
                                        defaultValue={supervision.supervision_type.name}
                                    />
                                )
                            case 'official':
                                return (
                                    <>
                                        <div>{`${supervision.official?.first_name} ${supervision.official?.last_name}`}</div>
                                        <div className="text-muted small">#{supervision.official?.unique_identifier}</div>
                                        {supervision.official.member_qualification &&
                                            <div className="text-muted small">
                                                <DisplayI18n field='name' defaultValue={supervision.official.member_qualification.qualification.name} i18n={supervision.official.member_qualification.qualification.i18n} />{(supervision.official.member_qualification.qualification_level) && <> - <DisplayI18n field='name' defaultValue={supervision.official.member_qualification.qualification_level.name} i18n={supervision.official.member_qualification.qualification_level.i18n} /></>}
                                            </div>
                                        }
                                    </>
                                )
                            case 'category':
                                return <DisplayCategory category={supervision.team_category} short />
                            case 'supervision_date':
                                return <div className="text-nowrap">{supervision.supervision_date}</div>
                            case 'away_team':
                                return supervision.away_team?.short_name || supervision.away_team_name || "-";
                            case 'home_team':
                                return supervision.home_team?.short_name || supervision.home_team_name || "-";
                            default:
                                break;
                        }
                    }}
                    onColumnClick={(e, data) => {
                        switch (e.button){
                            case 0: // Left mouse button
                                panelProps.onSingleSelectChange(data);
                                break;
                        }
                    }}
                    loadingMessage={
                        <>
                            <Skeleton height={54} />
                            <Skeleton count={10} height={90} />
                        </>
                    }
                    rowIsHighlighted={(data) => data.checked}
                >
                    <Card body className='card-shadow'>
                        <PeriodChangeHandler useRefreshTable />
                        <div className='mb-2'>
                            <div className='d-flex flex-wrap justify-content-between'>
                                <SpordleTableProvider.SearchInput />
                                <div className='d-flex ml-auto text-right flex-wrap flex-md-nowrap'>
                                    <SpordleTableProvider.Refresh />
                                    <ExportDropdown />
                                    <CanDoAction
                                        action="ADD"
                                        componentCode="members"
                                        componentPermissionCode="manage_official_supervisions"
                                    >
                                        <Button type="button" className='ml-2' color='primary' onClick={() => setModalIsOpen(true)}>
                                            <i className='ti-plus' /> <Translate id='misc.add' />
                                        </Button>
                                        <AddSupervisionForm types={supervisionTypes} isOpen={modalIsOpen} toggle={() => setModalIsOpen(false)} />
                                    </CanDoAction>
                                </div>
                            </div>
                        </div>
                        <SpordleTableView />
                    </Card>
                </SpordleTableProvider>
            )}
        />
    );
}

export default OfficiatingReportsList;