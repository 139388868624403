import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Button } from "reactstrap";
import AnimatedImg from "../../../../components/tour/animatedImg/AnimatedImg";
import TourTriggerer from "../../../../components/tour/TourTriggerer";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { RolesContext } from "../../../../contexts/RolesContext";

const OrgProfileTour = () => {
    const { hasAccessTo } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);

    const STEPS = [
        {
            selector: "#org-profile-nav-tabs",
            content: () => (
                <>
                    <div className="h3 font-medium"><Translate id="tour.step.newOrgSettings.title" /></div>
                    <div className="h5">
                        <Translate id="tour.step.newOrgSettings.message" />
                    </div>
                </>
            ),
        },
        {
            selector: "#tab-organization-overview",
            content: () => (
                <>
                    <div className="h3 font-medium"><Translate id="tour.step.newOrgSettings.general.title" /></div>
                    <div className="h5">
                        <Translate id="tour.step.newOrgSettings.general.message" />
                    </div>
                </>
            ),
        },
        {
            permissions: {
                isGod: true,
                componentCode: "settings",
                componentPermissionCode: "settings_regional",
            },
            selector: "#org-profile-settings",
            content: () => (
                <>
                    <div className="h3 font-medium"><Translate id="tour.step.newOrgSettings.registrationSettings.title" /></div>
                    <div className="h5"><Translate id="tour.step.newOrgSettings.registrationSettings.message" /></div>
                </>
            ),
        },
        {
            permissions: {
                isGod: true,
                componentCode: "settings",
                componentPermissionCode: "settings_merchant_account",
            },
            selector: "#org-profile-merchant-accounts",
            content: () => (
                <>
                    <div className="h3 font-medium"><Translate id="tour.step.newOrgSettings.payments.title" /></div>
                    <div className="h5"><Translate id="tour.step.newOrgSettings.payments.message" /></div>
                </>
            ),
        },
        {
            bypassElem: true,
            highlightedSelectors: [],
            position: 'center',
            content: ({ setIsOpen }) => (
                <div className="bg-light text-center position-relative mt-n4 mx-n4">
                    <AnimatedImg isReverse>
                        <i className="sid-tour-thumbs-up fa fa-thumbs-up" />
                    </AnimatedImg>
                    <button onClick={() => setIsOpen(false)} type="button" className="btn btn-link btn-lg position-absolute bg-transparent top-0 right-0 border-0"><i className="text-body font-18 mdi mdi-close" /></button>
                    <div className="px-4 pt-4 bg-white position-relative">
                        <div className="overflow-hidden px-4 mb-3">
                            <div className='h1 font-medium mx-auto slide-up'><Translate id='tour.step.10.title' /></div>
                        </div>
                        <Button
                            onClick={() => setIsOpen(false)}
                            type="button"
                            color="primary"
                        >
                            <Translate id="tour.step.gotIt.btn" />
                        </Button>
                    </div>
                </div>
            ),
            styles: {
                popover: (styles) => ({ ...styles, width: "600px", maxWidth: "100%", overflow: "hidden" }),
                maskArea: () => ({ opacity: 0 }),
            },
        },
    ];

    const filterSteps = (stepsToFilter) => {
        return stepsToFilter.filter((step) => {
            return !step.permissions || hasAccessTo(step.permissions.componentCode, step.permissions.componentPermissionCode) || (step.permissions.isGod && isGod());
        })
    }

    return (
        <TourTriggerer
            name="newOrgSettings"
            tourProps={{ steps: filterSteps(STEPS) }}
        />
    );
}

export default OrgProfileTour;