import { Component, createContext } from 'react';
import * as Sentry from "@sentry/react";
import withContexts from '../helpers/withContexts';
import { AuthContext, PeriodsContext } from './contexts';
import { createStepLog, getChecklists } from '../api/client/checklists';
import { OrganizationContext } from './OrganizationContext';
import { fail } from '@spordle/toasts';
import { AxiosIsCancelled } from '../api/CancellableAPI';
import { ACCEPTED_SYSTEM_TYPES } from '../views/tasks/checklists/checklistsHelper';

/** @type {React.Context<Omit<ChecklistsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & ClinicFeesContextProvider['state']>} */
export const ChecklistsContext = createContext();
ChecklistsContext.displayName = 'ChecklistsContext';

class ChecklistsContextProvider extends Component{
    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
            activeChecklist: false,
            isLoading: false,
            activeStep: -1,
            checklists: {},
            progress: {
                seasonRollover: [],
            },
        }
    }

    closeAssistant = () => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: 'Checklist Assistant closed',
            level: Sentry.Severity.Log,
            category: 'Checklist/Checklist',
        })


        this.setState((prev) => ({ ...prev, isOpen: false, activeStep: -1 }));
    }

    openAssistant = (step = -1) => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: `Checklist Assistant opened${step === -1 ? "" : `to step ${step + 1}`}`,
            level: Sentry.Severity.Log,
            category: 'Checklist/Checklist',
        })

        this.setState((prev) => ({ ...prev, isOpen: true, activeStep: step }));
    }

    toggleAssistant = () => {
        this.setState((prev) => ({ ...prev, isOpen: !prev.isOpen }));
    }

    startChecklist = (checklist, step = -1) => {
        this.setState((prev) => ({ ...prev, activeChecklist: checklist, isOpen: true, activeStep: step }));
    }

    stopChecklist = () => {
        this.setState((prev) => ({ ...prev, activeChecklist: false }));
    }

    updateCompletedSteps = (checklistId, logs = []) => {
        this.setState((prev) => ({
            ...prev,
            progress: { // Progress is split by checklist system_type for easier access.
                [checklistId]: logs.reduce((formattedLogs, log) => { // Logs are split by step_id for easier access.
                    const stepId = log.checklist_step?.checklist_step_id;

                    if(stepId){
                        if(!formattedLogs[stepId]){
                            formattedLogs[stepId] = [ log ];
                        }else{
                            formattedLogs[stepId].push(log);
                        }
                    }

                    return formattedLogs;
                }, {}),
            },
        }))
    }

    completeStep = (step) => {
        return createStepLog(step.checklist_step_id, { organisation_id: this.props.OrganizationContext.organisation_id })
            .then((logId) => {
                this.setState((prev) => ({
                    ...prev,
                    progress: { // Progress is split by checklist system_type for easier access.
                        ...prev.progress,
                        [prev.activeChecklist]: {
                            ...prev.progress[prev.activeChecklist],
                            [step.checklist_step_id]: [ // Logs are split by step_id for easier access.
                                ...(prev.progress[prev.activeChecklist]?.[logId] || []),
                                {
                                    checklist_step_log_id: logId,
                                    checklist_step: step,
                                    created_at: new Date().toISOString(),
                                    identity: this.props.AuthContext.account,
                                },
                            ],
                        },
                    },
                }))
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    fail();
                    console.error(e);
                }
            })
    }

    getCompletedData = (checklistId, stepId) => {
        return this.state.progress[checklistId]?.[stepId]
    }

    getCompletedSteps = (checklistId) => {
        const checklist = this.state.checklists[checklistId];
        const progress = this.state.progress[checklistId];

        if(!checklist || !progress){
            return [];
        }

        return (checklist?.checklist_steps || []).filter((step) => !!progress[step.checklist_step_id])
    }

    markStepAsIncomplete = (stepId) => {
        this.setState((prev) => ({
            ...prev,
            progress: {
                ...prev.progress,
                [prev.activeChecklist]: (prev.progress[prev.activeChecklist] || []).filter(({ id }) => id !== stepId),
            },
        }))
    }

    componentDidUpdate(nextProps){
        const periodId = this.props.PeriodsContext.selectedPeriod?.period_id;
        if(!!periodId && periodId !== nextProps.PeriodsContext.selectedPeriod?.period_id){
            this.setState((prev) => ({
                ...prev,
                activeChecklist: false,
                activeStep: -1,
                isOpen: false,
                isLoading: true,
            }));

            getChecklists({
                period_id: periodId,
                active: 1,
            })
                .then((checklists) => {
                    this.setState((prev) => ({
                        ...prev,
                        isLoading: false,
                        // checklists are split by id for easier access.
                        checklists: checklists.reduce((lists, checklist) => {
                            if(checklist.system_type && ACCEPTED_SYSTEM_TYPES.includes(checklist.system_type)){
                                lists[checklist.checklist_id] = checklist;
                            }

                            return lists;
                        }, {}),
                    }))
                })
                .catch(console.error)
        }
    }

    render(){
        return (
            <ChecklistsContext.Provider
                value={{
                    ...this.state,
                    ...this,
                }}
            >
                {this.props.children}
            </ChecklistsContext.Provider>
        );
    }
}

export default withContexts(PeriodsContext, OrganizationContext, AuthContext)(ChecklistsContextProvider);