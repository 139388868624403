import { addExtension, FormikAddress, FormikCurrencyInput, FormikDateTime, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect, getFormikAddressInitialValues } from "@spordle/formik-elements";
import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import {
    Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup,
    Label, Row
} from "reactstrap";
import { mutate } from 'swr';
import {
    object,
    string,
    mixed
} from 'yup';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import Required from '../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { fail, success } from '@spordle/toasts';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { IdentityRolesContext } from '../../../../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { RegistrationCategoriesContext } from '../../../../../../contexts/RegistrationCategoriesContext';
import { RolesContext } from '../../../../../../contexts/RolesContext';
import { TeamsContext } from '../../../../../../contexts/TeamsContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { DisplayCategory } from '../../../../TeamHelpers';
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import moment, { isMoment } from "moment";
import { PeriodsContext } from "../../../../../../contexts/contexts";
import ApiErrorMsg from "../../../../../../components/formik/ApiErrorMsg";
import FormikApiError from "../../../../../../components/formik/FormikApiError";

/**
* @param {bool} [wizard=false] fix layout when this is in the wizard creation
*/
const TeamGeneralInfoEdit = ({ onCancel, teamId, onSubmit, teamAddressTypes, wizard = false, ...props }) => {
    const i18n = useContext(I18nContext);
    const organizationContext = useContext(OrganizationContext);
    const registrationCategoriesContext = useContext(RegistrationCategoriesContext);
    const teamsContext = useContext(TeamsContext);
    const periodsContext = useContext(PeriodsContext);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);

    const [ myTeamAddressTypes, setTeamAddressTypes ] = useState(teamAddressTypes);
    const [ categories, setCategories ] = useState(null);

    // Always can edit category now, status will revert back to DRAFT if category is changed
    // team_category_modification ==> equals more something like "team_additional_modification" / will rename in the future
    const canEditCategory = wizard || canDoAction('EDIT', 'teams', 'team_category_modification') || canDoAction('EDIT', 'teams', 'team_category_modification_non_empty_roster', true) || isGod();

    /**
     * @returns {string} The phone number
     */
    const getPhoneNumber = () => teamsContext.cachedTeam.phone?.split('#')[0] || '';

    /**
      * @returns {string} The phone extention
      */
    const getPhoneExtension = () => teamsContext.cachedTeam.phone?.split('#')[1] || '';

    // const hasAddress = () => {
    //     const formik = formikRef.current
    //     if(formik){
    //         const hasAddress = formik.values.address.streetNumber || formik.values.address.address || formik.values.address.city || formik.values.address.zip || formik.values.address.state || formik.values.address.country ? true : false
    //         const hasAddress2 = !!formik.values.address2
    //         return hasAddress || hasAddress2 ? true : false
    //     } else {
    //         return null
    //     }
    // }

    const hasAddress = (address) => {
        return !!address.streetNumber;
    }

    const cachedTeamHasAddress = () => {
        return teamsContext.cachedTeam.addresses && Array.isArray(teamsContext.cachedTeam.addresses) && teamsContext.cachedTeam.addresses[0]
    }

    const getCategories = () => {
        setCategories(null);
        registrationCategoriesContext.getRegistrationCategories(organizationContext.organisation_id, { team_view: 1 })
            .then((allCat) => {
                setCategories(allCat
                    .filter((cat) => cat.active === "1")
                    .sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))
                    .map((category) => {
                        return {
                            value: category.team_category_id,
                            label: `form.fields.gender.${category.gender.toLowerCase()}`,
                            translateLabel: true,
                            category: category,
                        };
                    }));
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setCategories(null);
                }
            })
    }

    useEffect(() => {
        getCategories();

        teamsContext.getAddressTypes(organizationContext.organisation_id)
            .then(setTeamAddressTypes)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setTeamAddressTypes(null);
                }
            })

        if(!wizard){
            teamsContext.getTeamMembers(teamsContext.cachedTeam?.team_id)
                .then((members) => {
                    if(members.length <= 0){
                        getCategories();
                    }
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
    }, []);

    const getInitAddress = (obj) => {
        return {
            address: getFormikAddressInitialValues(obj),
            addressType: obj?.address_type?.team_address_type_id || '',
            address2: obj?.unit_number || '',
        }
    }
    return (
        <Formik
            // innerRef={formikRef}
            initialValues={{
                name: teamsContext.cachedTeam.name || '',
                short_name: teamsContext.cachedTeam.short_name || '',
                team_fee: teamsContext.cachedTeam.team_fee / 100 || '',
                home_color: teamsContext.cachedTeam.home_color || '',
                away_color: teamsContext.cachedTeam.away_color || '',
                sponsor_name: teamsContext.cachedTeam.sponsor_name || '',
                activity_period_id: teamsContext.cachedTeam.activity_period?.activity_period_id || '',
                tournament_number: teamsContext.cachedTeam.tournament_number || '',
                phone: wizard ? '' : getPhoneNumber(),
                extension: getPhoneExtension(),
                team_category_id: teamsContext.cachedTeam.team_category?.team_category_id || '',
                lock_roster_date: moment(teamsContext.cachedTeam.lock_roster_date) || '',
                lock_roster_hour: moment(teamsContext.cachedTeam.lock_roster_date) || '',
                lock_affiliate_roster_date: moment(teamsContext.cachedTeam.lock_affiliate_roster_date) || '',
                lock_affiliate_roster_hour: moment(teamsContext.cachedTeam.lock_affiliate_roster_date) || '',
                // If no team has been created and is in wizard, prefill with organizationContext data by default
                ...getInitAddress(wizard && !teamId ? { ...organizationContext, state: undefined } : teamsContext.cachedTeam.addresses?.[0]),
            }}
            validationSchema={object().shape({
                name: string().required(<Translate id='teams.profile.teamInfos.general.teamName.required' />),
                short_name: string().required(<Translate id='teams.profile.teamInfos.general.shortName.required' />),
                team_fee: mixed(),
                home_color: string(),
                away_color: string(),
                team_category_id: string().test({
                    name: 'isWizard',
                    message: <Translate id='teams.profile.teamRoster.activeRoster.add.validation.category' />,
                    test: (category) => {
                        return canEditCategory ? !!category : true;
                    },
                }),
                phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />),
                address: object().address(),
                addressType: string().test({
                    is: 'requiredTest',
                    message: <Translate id='teams.profile.teamInfos.general.addressType.required' />,
                    test: function(addressType){
                        if(hasAddress(this.parent.address)){
                            return !!addressType
                        }
                        return true
                    },
                }),
                lock_roster_hour: mixed().when('lock_roster_date', {
                    is: (lock_roster_date) => !!lock_roster_date,
                    then: mixed().required(<Translate id='teams.profile.teamInfos.general.lock_roster_hour.required' />)
                        .test({
                            name: 'lock_roster_hour',
                            message: <Translate id='form.validation.time.format' />,
                            test: function(hour){
                                return hour ? moment.isMoment(hour) : true
                            },
                        }),
                }),
                lock_affiliate_roster_hour: mixed().when('lock_affiliate_roster_date', {
                    is: (lock_affiliate_roster_date) => !!lock_affiliate_roster_date,
                    then: mixed().required(<Translate id='teams.profile.teamInfos.general.lock_affiliate_roster_hour.required' />)
                        .test({
                            name: 'lock_affiliate_roster_hour',
                            message: <Translate id='form.validation.time.format' />,
                            test: function(hour){
                                return hour ? moment.isMoment(hour) : true
                            },
                        }),
                }),
            })}
            onSubmit={async(values, { setSubmitting, setStatus }) => {
                const phone = values.phone ? await addExtension(values.phone, values.extension) : '';

                let lock_roster_date = null;
                if(values.lock_roster_date){
                    lock_roster_date = isMoment(values.lock_roster_date) ? values.lock_roster_date.set({ 'hour': moment(values.lock_roster_hour).hour(), 'minute': moment(values.lock_roster_hour).minute() }).toISOString() : teamsContext.cachedTeam.lock_roster_date;
                }
                let lock_affiliate_roster_date = null;
                if(values.lock_affiliate_roster_date){
                    lock_affiliate_roster_date = isMoment(values.lock_affiliate_roster_date) ? values.lock_affiliate_roster_date.set({ 'hour': values.lock_affiliate_roster_hour.hour(), 'minute': values.lock_affiliate_roster_hour.minute() }).toISOString() : teamsContext.cachedTeam.lock_affiliate_roster_date;
                }

                const newAddressValues = {
                    team_address_type_id: values.addressType || '',
                    unit_number: values.address2 || '',
                    street_number: values.address.streetNumber || '',
                    street: values.address.address || '',
                    origin_address: values.address.origin,
                    country_id: values.address.country || '',
                    province_id: values.address.state || '',
                    city: values.address.city || '',
                    zip_code: values.address.zipCode || '',
                    default_address: 1,
                    map_url: values.address.mapsUrl || '',
                    active: 1,
                };

                const newValues = { ...values, lock_roster_date: lock_roster_date, lock_affiliate_roster_date: lock_affiliate_roster_date };
                newValues.team_fee = Math.round(newValues.team_fee * 100);

                // Remove locked roster params to prevent 403. Needs to be null in the case we have access and we want to clear the field.
                if(!canDoAction('EDIT', 'teams', 'team_category_modification')){
                    delete newValues.lock_roster_date;
                    delete newValues.lock_affiliate_roster_date;
                }

                // Create Team
                if(wizard && !teamsContext.cachedTeam.team_id){
                    return teamsContext.createTeam({ ...newValues, phone: phone })
                        .then(async(teamId) => {
                            if(hasAddress(values.address)){
                                await teamsContext.createTeamAddress(teamId, newAddressValues)
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message);
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })
                            }
                            await teamsContext.getTeam(teamId, true, { components: 'address' })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message);
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                    }
                                })

                            setSubmitting(false);
                            onSubmit(teamId);
                        })
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                console.error(e);
                                setStatus({ error: <ApiErrorMsg error={e} />, showError: true });
                                setSubmitting(false);
                            }
                        })
                }

                // Update Existing Team
                if(newValues.team_category_id === teamsContext.cachedTeam?.team_category?.team_category_id){
                    delete newValues.team_category_id;
                }
                return Promise.all([
                    cachedTeamHasAddress() && hasAddress(values.address) ? // wether to create or update
                        teamsContext.updateTeamAddressPartial(teamsContext.cachedTeam.team_id, teamsContext.cachedTeam.addresses[0].team_address_id, newAddressValues)
                        : !cachedTeamHasAddress() && hasAddress(values.address) ?
                            teamsContext.createTeamAddress(teamsContext.cachedTeam.team_id, newAddressValues)
                            : cachedTeamHasAddress() && !hasAddress(values.address) ?
                                teamsContext.deleteTeamAddress(teamsContext.cachedTeam.team_id, teamsContext.cachedTeam.addresses[0].team_address_id)
                                : Promise.resolve(),
                    // newAddressValues ? // new address
                    //     teamsContext.cachedTeam.addresses && Array.isArray(teamsContext.cachedTeam.addresses) && teamsContext.cachedTeam.addresses[0] ? // wether to create or update
                    //         teamsContext.updateTeamAddressPartial(teamsContext.cachedTeam.team_id, teamsContext.cachedTeam.addresses[0].team_address_id, newAddressValues)
                    //     :
                    //         teamsContext.createTeamAddress(teamsContext.cachedTeam.team_id, newAddressValues)
                    // : teamsContext.cachedTeam.addresses ? // delete addresses
                    //     teamsContext.deleteAllTeamAddress(teamsContext.cachedTeam.team_id)
                    // : // no existing address, no new address, nothing to do
                    //     Promise.resolve(),
                    teamsContext.updateTeamPartial(teamsContext.cachedTeam.team_id, { ...newValues, phone: phone }),
                ])
                    .then(async() => {
                        // Force a get team refresh to revalidate the status after changing team category
                        await teamsContext.getTeam(teamsContext.cachedTeam.team_id, true, { components: [ 'address', 'contact' ] })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            });
                        setSubmitting(false);

                        if(wizard){
                            onSubmit(teamsContext.cachedTeam?.team_id);
                        }else{
                            mutate([ 'getTeamsSettings', teamsContext.cachedTeam.team_id ]);
                            onSubmit();
                            success();
                        }

                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setSubmitting(false);
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <Card className={stringBuilder('card-shadow', { 'shadow-primary': !wizard })}>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            {!wizard &&
                                <CardHeader>
                                    <div className="h4 mb-0 font-bold"><Translate id='misc.edit' /> - <Translate id='teams.profile.teamInfos.general.title' /></div>
                                </CardHeader>
                            }
                            <CardBody>
                                {wizard &&
                                    <CardSectionTitle title="teams.profile.teamInfos.general.title" />
                                }
                                <Row form>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='name' className='text-muted'><Translate id='teams.profile.teamInfos.general.teamName' /> <Required /></Label>
                                            <FormikInputText id='name' name='name' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Label for='short_name' className='text-muted'><Translate id='teams.profile.teamInfos.general.shortName' /> <Required /></Label>
                                            <FormikInputText id='short_name' name='short_name' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Label for='team_fee' className='text-muted'><Translate id='teams.profile.teamInfos.general.teamFee' /></Label>
                                            <FormikCurrencyInput id='team_fee' name='team_fee' allowNegative={false} />
                                        </FormGroup>
                                    </Col>
                                    {canEditCategory ?
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label for='team_category_id' className={`${(!canDoAction('EDIT', 'teams', 'team_category_modification') && !canDoAction('EDIT', 'teams', 'team_category_modification_non_empty_roster', true) && isGod()) ? 'text-purple' : 'text-muted'}`}><Translate id='teams.profile.teamInfos.general.category' /> <Required /></Label>
                                                <FormikSelect
                                                    id='team_category_id'
                                                    name='team_category_id'
                                                    clearable
                                                    searchKeys={[
                                                        `category.i18n.${i18n.getGenericLocale()}.name`,
                                                        `category.i18n.${i18n.getGenericLocale()}.short_name`,
                                                        `category.division.i18n.${i18n.getGenericLocale()}.name`,
                                                        `category.class.i18n.${i18n.getGenericLocale()}.name`,
                                                        `category.division.i18n.${i18n.getGenericLocale()}.short_name`,
                                                        `category.class.i18n.${i18n.getGenericLocale()}.short_name`,
                                                        `category.gender`,
                                                    ]}
                                                    renderOption={({ option }) => (
                                                        <>
                                                            <div className="mb-0">
                                                                <DisplayCategory category={option.category} short />{option.category?.gender && <> - <Translate id={`form.fields.gender.${option.category?.gender.toLowerCase()}`} /></>}
                                                            </div>
                                                            <div className="small text-muted">{<DisplayCategory category={option.category} />}</div>
                                                        </>
                                                    )}
                                                    renderSelectedOption={(option) => <><DisplayCategory category={option.category} short />{option.category?.gender && <> - <Translate id={`form.fields.gender.${option.category?.gender.toLowerCase()}`} /></>}</>}
                                                    isLoading={!categories}
                                                    options={categories}
                                                />
                                            </FormGroup>
                                        </Col>
                                        :
                                        <Col sm="6">
                                            <div className="mb-3">
                                                <div className="text-muted"><Translate id='teams.profile.teamInfos.general.category' /></div>
                                                <div className="font-medium text-dark">
                                                    <DisplayCategory category={teamsContext.cachedTeam.team_category} />
                                                    {teamsContext.cachedTeam.team_category?.gender &&
                                                        <> - <Translate id={`form.fields.gender.${teamsContext.cachedTeam.team_category.gender.toLowerCase()}`} /></>
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                    <Col sm='6'>
                                        <Row form>
                                            <Col sm='6'>
                                                <FormGroup>
                                                    <Label for='home_color' className='text-muted'><Translate id='teams.profile.teamInfos.general.colorHome' /></Label>
                                                    <FormikInputText id='home_color' name='home_color' />
                                                </FormGroup>
                                            </Col>
                                            <Col sm='6'>
                                                <FormGroup>
                                                    <Label for='away_color' className='text-muted'><Translate id='teams.profile.teamInfos.general.colorAway' /></Label>
                                                    <FormikInputText id='away_color' name='away_color' />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* <Col sm="6">
                                        <FormGroup>
                                            <Label for='league' className='text-muted'>League</Label>
                                            <SpordleSelect name='league' id='league'>
                                                <SpordleSelect.Option id='league-0' label='option' value='0' />
                                            </SpordleSelect>
                                        </FormGroup>
                                    </Col> */}
                                    {/* <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='teams.profile.teamInfos.general.league'/></div>
                                            <div className="font-medium text-dark">{teamsContext.cachedTeam.league || '-'}</div>
                                        </div>
                                    </Col> */}
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='sponsor_name' className='text-muted'><Translate id='teams.profile.teamInfos.general.sponsor' /></Label>
                                            <FormikInputText id='sponsor_name' name='sponsor_name' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='tournament_number' className='text-muted'><Translate id='teams.profile.teamInfos.general.tournamentNumber' /></Label>
                                            <FormikInputNumber id='tournament_number' name='tournament_number' decimalSeparator={false} allowNegative={false} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <Row form>
                                            <Col sm='8'>
                                                <FormGroup>
                                                    <Label for='phone' className='text-muted'><Translate id='form.fields.phone' /></Label>
                                                    <FormikPhoneInput name="phone" id="phone" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm='4'>
                                                <Label for='extension' className='text-muted'><Translate id='form.fields.extension' /></Label>
                                                <FormikInputNumber id='extension' name='extension' />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm='12'>
                                        <FormikAddress
                                            id='address' name='address'
                                            allowCopyAddress
                                            allowManualPlace
                                            allowOpenInMaps
                                            label='form.fields.address'
                                            labelClassName='text-muted'
                                        />
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label className='text-muted' for='addressType'><Translate id='teams.profile.teamInfos.general.addressType' /></Label>
                                            <FormikSelect
                                                id='addressType'
                                                name='addressType'
                                                clearable disabled={!hasAddress(formik.values.address)}
                                                searchKeys={[
                                                    `i18n.${i18n.getGenericLocale()}.name`,
                                                ]}
                                                renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                isLoading={!myTeamAddressTypes}
                                                options={myTeamAddressTypes?.reduce((newArray, type) => {
                                                    if(type.active == 1){
                                                        newArray.push({
                                                            value: type.team_address_type_id,
                                                            label: type.name,
                                                            i18n: type.i18n,
                                                        })
                                                    }
                                                    return newArray
                                                }, [])}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label className='text-muted' for='address2'><Translate id='form.fields.address' /> 2 (<Translate id='form.fields.address2.placeholder' />)</Label>
                                            <FormikInputText id='address2' name='address2' trim disabled={!hasAddress(formik.values.address)} />
                                        </FormGroup>
                                    </Col>

                                    {!wizard &&
                                        <CanDoAction tag={Col} sm="12" action='EDIT' componentCode='teams' componentPermissionCode='team_category_modification'>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <Label for='lock_roster_date' className='text-muted'><Translate id="teams.profile.teamInfos.general.lock_roster_date" /></Label>
                                                        <Row>
                                                            <Col sm="6">
                                                                <FormikDateTime
                                                                    name='lock_roster_date' id='lock_roster_date'
                                                                    timeFormat={false}
                                                                />
                                                            </Col>
                                                            <Col sm="6">
                                                                <FormikDateTime
                                                                    name='lock_roster_hour' id='lock_roster_hour'
                                                                    dateFormat={false}
                                                                    timeFormat={'h:mm A'}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <div className="mb-3">
                                                        <Label for='lock_affiliate_roster_date' className='text-muted'><Translate id="teams.profile.teamInfos.general.lock_affiliate_roster_date" /></Label>
                                                        <Row>
                                                            <Col sm="6">
                                                                <FormikDateTime
                                                                    name='lock_affiliate_roster_date' id='lock_affiliate_roster_date'
                                                                    timeFormat={false}
                                                                />
                                                            </Col>
                                                            <Col sm="6">
                                                                <FormikDateTime
                                                                    name='lock_affiliate_roster_hour' id='lock_affiliate_roster_hour'
                                                                    dateFormat={false}
                                                                    timeFormat={'h:mm A'}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CanDoAction>
                                    }
                                    {organizationContext.settings.enable_organization_activity_periods?.value == '1' &&
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label for='activity_period_id' className='text-muted'><Translate id='form.fields.activityPeriod' /></Label>
                                                <FormikSelect
                                                    name="activity_period_id"
                                                    id="activity_period_id"
                                                    clearable
                                                    renderOption={(option) => (
                                                        <>
                                                            <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                                            <div className="small text-muted"><DisplayI18n field='description' defaultValue={option.option.description} i18n={option.option.i18n} /></div>
                                                        </>
                                                    )}
                                                    loadData={(from) => {
                                                        switch (from){
                                                            case 'CDM':
                                                                return periodsContext.getOrganizationActivityPeriods({ organisation_id: organizationContext.organisation_id, active: '1' })
                                                                    .then((activityPeriods) => (activityPeriods.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order)).map((activityPeriod) => ({
                                                                        value: activityPeriod.activity_period_id,
                                                                        label: activityPeriod.name,
                                                                        description: activityPeriod.description,
                                                                        i18n: activityPeriod.i18n,
                                                                    }))));
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <FormikApiError />
                            </CardBody>
                            {!wizard &&
                                <CardFooter className="text-right">
                                    <Button type='submit' color="primary" disabled={formik.isSubmitting}>
                                        <Translate id='misc.save' />
                                    </Button>
                                    <Button type='button' className='ml-2' color="primary" outline onClick={onCancel} disabled={formik.isSubmitting}>
                                        <Translate id='misc.cancel' />
                                    </Button>
                                </CardFooter>
                            }
                        </OverlayLoader>
                    </Card>
                    {wizard &&
                        <div className={"mt-3 mb-5 d-flex"}>
                            <Button type="submit" color="primary" className="ml-auto"><Translate id='misc.next' /></Button>
                        </div>
                    }
                </Form>
            )}
        </Formik>
    );
}

export default TeamGeneralInfoEdit;