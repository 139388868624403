export default{
    "catalog.registrationFees.sidePanel.tab.fee"     : "Fee",
    "catalog.registrationFees.sidePanel.tab.catalog" : "Catalog",

    "catalog.registrationFees.sidePanel.fees.count"            : "{count} fees selected",
    "catalog.registrationFees.sidePanel.fees.feeNeededMessage" : "One or more items selected don't have fees set for the parent organization. These will not be updated.",

    "catalog.registrationFees.sidePanel.form.memberType"                 : "Member Type",
    "catalog.registrationFees.sidePanel.form.sku"                        : "SKU",
    "catalog.registrationFees.sidePanel.form.category"                   : "Category",
    "catalog.registrationFees.sidePanel.form.division"                   : "Division",
    "catalog.registrationFees.sidePanel.form.class"                      : "Class",
    "catalog.registrationFees.sidePanel.form.optional"                   : "Optional",
    "catalog.registrationFees.sidePanel.form.status"                     : "Status",
    "catalog.registrationFees.sidePanel.form.active"                     : "Active",
    "catalog.registrationFees.sidePanel.form.inactive"                   : "Inactive",
    "catalog.registrationFees.sidePanel.form.availablePlaces"            : "Available Places",
    "catalog.registrationFees.sidePanel.form.totalPlaces"                : "Total Places",
    "catalog.registrationFees.sidePanel.form.unlimited"                  : "Unlimited",
    "catalog.registrationFees.sidePanel.form.registrationFee"            : "Registration Fee",
    "catalog.registrationFees.sidePanel.form.registrationFees"           : "Registration Fees",
    "catalog.registrationFees.sidePanel.form.regularFee"                 : "Regular fee",
    "catalog.registrationFees.sidePanel.form.platformFees"               : "Platform fees",
    "catalog.registrationFees.sidePanel.form.tax"                        : "+Taxe",
    "catalog.registrationFees.sidePanel.form.earlyFee"                   : "Early Fee",
    "catalog.registrationFees.sidePanel.form.earlyFee.hint"              : "Before",
    "catalog.registrationFees.sidePanel.form.earlyFee.placeholder"       : "Fee",
    "catalog.registrationFees.sidePanel.form.earlyDate.validation"       : "Date is required",
    "catalog.registrationFees.sidePanel.form.earlyDate.validation.valid" : "Date must be before late fee's date",
    "catalog.registrationFees.sidePanel.form.lateFee"                    : "Late Fee",
    "catalog.registrationFees.sidePanel.form.lateFee.hint"               : "After",
    "catalog.registrationFees.sidePanel.form.lateFee.placeholder"        : "Fee",
    "catalog.registrationFees.sidePanel.form.lateDate.validation"        : "Date is required",
    "catalog.registrationFees.sidePanel.form.lateDate.validation.valid"  : "Date must be after early fee's date",
    "catalog.registrationFees.sidePanel.form.applicableTax"              : "Applicable Tax",
    "catalog.registrationFees.sidePanel.form.applicableTax.none"         : "Not taxable",
    "catalog.registrationFees.sidePanel.form.taxReceipt"                 : "Tax Receipt",
    "catalog.registrationFees.sidePanel.form.taxReceipt.no"              : "No receipt",

    "catalog.registrationFees.sidePanel.form.updated" : "Registration fee updated!",

    "catalog.registrationFees.sidePanel.form.waivers"         : "Waivers",
    "catalog.registrationFees.sidePanel.form.selectedWaivers" : "Selected Waivers",
    "catalog.registrationFees.sidePanel.form.waiversSelected" : "{count} selected waivers",

    "catalog.registrationFees.sidePanel.form.customForms"  : "Questionnaires",
    "catalog.registrationFees.sidePanel.form.installments" : "Installments",
}