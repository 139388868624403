import { useState, useEffect, useRef } from "react";
import { stringBuilder } from "@spordle/helpers";
import { copyContent } from "../../helpers/clipboardHelper";
import styles from "./InlineCopy.module.scss";
import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";

const STATUS_IDLE = 1;
const STATUS_SUCCESS = 2;
const STATUS_ERROR = 3;

const InlineCopy = ({ className, tag: Tag = "span", children, toCopy }) => {
    const [ status, setStatus ] = useState(STATUS_IDLE);
    const timeout = useRef(null);

    const handleOnCopy = (content) => {
        return () => {
            copyContent(content)
                .then((val) => setStatus(STATUS_SUCCESS))
                .catch((e) => {
                    console.error(e);
                    setStatus(STATUS_ERROR);
                })
        }
    }

    useEffect(() => {
        if(status !== STATUS_IDLE){
            if(timeout.current){
                clearTimeout(timeout.current);
            }

            timeout.current = setTimeout(() => {
                setStatus(STATUS_IDLE);
                timeout.current = null;
            }, 1500);
        }

        return () => {
            clearTimeout(timeout.current);
        }
    }, [ status ])

    return (
        <Tag className={stringBuilder(className, styles.wrapper)}>
            {children}
            {toCopy &&
                <Tooltip
                    position="top"
                    zIndex={2000}
                    placement="center"
                    withArrow={false}
                    positionDependencies={[ status ]}
                    label={<><Translate id={`misc.copy.${status}`} /> {status !== STATUS_IDLE && <i className={`mdi mdi-${status === STATUS_ERROR ? "close-circle text-danger" : "check-circle text-success"}`} />}</>}
                >
                    <i
                        className={stringBuilder('mdi mdi-content-copy clickable ml-1', styles.icon)}
                        onClick={handleOnCopy(toCopy)}
                    />
                </Tooltip>
            }
        </Tag>
    )
}

export default InlineCopy;