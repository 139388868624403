import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import { DisplayPhoneNumber } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { useContext, useRef, useState } from 'react';
import Required from '../../../../../../components/formik/Required';
import SpordlePanelTable from '../../../../../../components/sidePanel/SpordlePanel';
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import { ClinicsContext } from '../../../../../../contexts/ClinicsContext';
import FormClinicAddContactModal from '../AddContactModal/FormClinicAddContactModal';
import images from '@spordle/ui-kit';
import { stringBuilder } from '@spordle/helpers';
import ContactsInstructorsSidepanel from '../ContactsInstructorsSidepanel';
import { AuthContext } from '../../../../../../contexts/contexts';
import { fail, success } from '@spordle/toasts';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';
const noProfileLogo = images.noprofile;

const ContactsTable = (props) => {

    const clinicsContext = useContext(ClinicsContext);
    const authContext = useContext(AuthContext);
    const authContextContact = {
        firstName: authContext.account.name,
        lastName: authContext.account.family_name,
        email: authContext.account.email,
        phone: authContext.account.phone_number || '',
        notify: false,
    }
    const tableRef = useRef();

    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const toggleModal = () => setModalIsOpen(!modalIsOpen);

    const [ addMyselfDisabled, setAddMyselfDisabled ] = useState(false);

    const getTableLength = () => {
        return tableRef.current?.getData().filter((row) => row.clinicContactId !== 'template').length
    }

    const alreadyExists = async(contact, data) => {
        const newCompare = contact.firstName + '-' + contact.lastName + '-' + contact.email + '-' + contact.phone;
        const compare = (row) => newCompare === row.firstName + '-' + row.lastName + '-' + row.email + '-' + row.phone;
        const contactArray = data || tableRef.current?.getData();
        const formatInstructor = (instructor) => {
            return {
                firstName: instructor.first_name,
                lastName: instructor.last_name,
                email: instructor.email,
                phone: instructor.phone || '',
            }
        }

        let found = false;
        if(contact.asInstructor){
            const instructors = await clinicsContext.getClinicInstructors(props.clinicId)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                });
            if(instructors.find((row) => compare(formatInstructor(row))))
                found = 'instructor'
        }

        if(contactArray.find((row) => compare(row)))
            found = 'contact'

        return found;
    }

    const onUpdate = async() => {
        const response = await alreadyExists(authContextContact)
        setAddMyselfDisabled(response === 'contact')
    }

    return (
        <>
            {/* CONTACT MODAL */}
            <FormClinicAddContactModal
                isOpen={modalIsOpen}
                toggle={toggleModal}
                onCreate={(contact) => {
                    props.setNumberOfContacts(getTableLength() + 1)
                    tableRef.current.refreshTable();

                    if(contact.asInstructor){
                        props.updateInstructorsKey();
                    }
                }}
                alreadyExists={alreadyExists}
                clinicId={props.clinicId}
            />

            <SpordlePanelTable
                allowOutsideClick
                sidePanel={(panelProps) => (
                    <ContactsInstructorsSidepanel
                        {...panelProps}
                        isContact
                        clinicId={props.clinicId}
                        onUpdate={onUpdate}
                        setNumberOfContacts={props.setNumberOfContacts}
                    />
                )}
                dataIndex='clinicContactId'
                table={(panelProps) => {
                    return (
                        <SpordleTableProvider
                            id='contacts'
                            tableHover clickable
                            viewMode='GRID'
                            ref={(r) => {
                                tableRef.current = r;
                                panelProps.spordleTableRef(r);
                            }}
                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                            dataIndex='clinicContactId'
                            onColumnClick={(e, contact) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        if(contact.clinicContactId === 'template'){
                                            toggleModal();
                                        }else{
                                            panelProps.onSingleSelectChange(contact);
                                        }
                                        break;
                                }
                            }}
                            gridConfig={{
                                col: {
                                    sm: 12,
                                    md: 6,
                                    lg: 4,
                                    className: 'mb-3',
                                },
                                gridRender: (contact) => {
                                    if(contact.clinicContactId === 'template'){
                                        return (
                                            <CanDoAction action='ADD' componentCode='clinics' componentPermissionCode='clinics_manage'>
                                                <UserDisplay
                                                    card
                                                    hover
                                                    className={stringBuilder('w-100 mb-0 border-primary', { 'shadow-danger': contact.errors })}
                                                    style={{ minWidth: 'unset', borderStyle: 'dashed', borderWidth: '1px' }}
                                                >
                                                    <UserDisplay.Container>
                                                        <div className="border rounded-circle shadow-sm p-2">
                                                            <img className="object-fit-contain" src={noProfileLogo} width="40" height="40" alt='templateCard' />
                                                        </div>
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container>
                                                        <UserDisplay.Title tag="span" className='text-link'><i className='mdi mdi-plus' /> <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact' /></UserDisplay.Title>
                                                    </UserDisplay.Container>
                                                </UserDisplay>

                                                {contact.errors &&
                                                    <div className='small text-danger mt-2'><Translate id={'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.' + contact.errors[0]} /></div>
                                                }
                                            </CanDoAction>
                                        )
                                    }
                                    return (
                                        <UserDisplay card hover className='w-100 mb-0' style={{ minWidth: 'unset' }}>
                                            <UserDisplay.Container>
                                                <div className="border rounded-circle shadow-sm p-2">
                                                    <img className="object-fit-contain" src={contact.logo ?? noProfileLogo} width="40" height="40" alt={`${contact.firstName} ${contact.lastName}`} />
                                                </div>
                                            </UserDisplay.Container>
                                            <UserDisplay.Container style={{ minWidth: 0 }}>
                                                <UserDisplay.Title tag="span">{contact.firstName} {contact.lastName}</UserDisplay.Title>
                                                <UserDisplay.Subtitle className="text-truncate">{contact.email}</UserDisplay.Subtitle>
                                                <UserDisplay.Subtitle><DisplayPhoneNumber phoneString={contact.phone} withExtension format='INTERNATIONAL' /></UserDisplay.Subtitle>
                                            </UserDisplay.Container>
                                            {contact.notify &&
                                                    <UserDisplay.Container className='align-self-start w-100'>
                                                        <div className='text-right'>
                                                            <i className='mdi mdi-bell text-primary font-16' />
                                                        </div>
                                                    </UserDisplay.Container>
                                            }
                                        </UserDisplay>
                                    )

                                },
                            }}
                            desktopWhen='md'
                            rowIsHighlighted={(contact) => !!contact.checked}
                            loadData={(from, extra, spordleTable) => {
                                switch (from){
                                    case 'REFRESH':
                                        spordleTable.setLoading();
                                    case 'CDM':
                                        return clinicsContext.getClinicContacts(props.clinicId)
                                            .then(async(contacts) => {
                                                const formattedContacts = contacts.map((contact) => ({
                                                    firstName: contact.first_name,
                                                    lastName: contact.last_name,
                                                    email: contact.email,
                                                    phone: contact.phone || '',
                                                    notify: !!contact.notification_types?.find((notification) => notification.code === 'clinic_registration'),
                                                    clinicContactId: contact.clinic_contact_id,
                                                    showStatus: contact.visibility_on_website,
                                                }))
                                                formattedContacts.push({ clinicContactId: 'template' })

                                                const response = await alreadyExists(authContextContact, formattedContacts)
                                                setAddMyselfDisabled(response === 'contact')

                                                return formattedContacts
                                            })
                                }
                            }}
                        >
                            {(spordleTable) => (
                                <>
                                    <div className={stringBuilder('d-flex mb-2', { 'border-bottom mb-3 pb-1': props.wizard })}>
                                        <div className={`font-bold mb-0 ${props.wizard ? 'h3 card-title' : 'h5 '}`}>
                                            <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.title' values={{ count: getTableLength() }} />
                                        </div>
                                        <Required className="ml-1" />

                                        <CanDoAction action='ADD' componentCode='clinics' componentPermissionCode='clinics_manage' className='ml-auto'>
                                            <button
                                                className='btn btn-link p-0'
                                                disabled={addMyselfDisabled}
                                                type='button'
                                                onClick={async() => {
                                                    return clinicsContext.createContact(props.clinicId, authContextContact)
                                                        .then(() => {
                                                            success();
                                                            props.setNumberOfContacts(getTableLength() + 1)
                                                            spordleTable.refreshTable();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                }}
                                            >
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.myself' />
                                            </button>
                                        </CanDoAction>
                                    </div>
                                    <SpordleTableView />
                                </>
                            )}
                        </SpordleTableProvider>
                    )
                }}
            />
        </>
    )
}

export default ContactsTable