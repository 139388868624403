import { useFormikContext } from 'formik';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import Translate from '@spordle/intl-elements';
import { Button, Card } from 'reactstrap';
import { displayName } from './helpers';

const DisplayOrgNameCard = ({ createMode, duplicate, teamSettings, organization }) => {
    const formikContext = useFormikContext();

    return (
        <Card body className='card-shadow'>
            <div className='d-flex justify-content-between align-items-start'>
                <div>
                    <div className='h3 font-bold mb-0'><DisplayI18n field='name' defaultValue={organization.organisation_name} i18n={organization.i18n} /></div>
                    {!createMode && !duplicate &&
                        <div className='h4 mb-0 mt-1'>
                            {displayName(teamSettings)}
                        </div>
                    }
                </div>
                <Button color='primary' type='submit' disabled={formikContext.isSubmitting}><Translate id={createMode || duplicate ? 'misc.create' : 'misc.save'} /></Button>
            </div>
        </Card>
    )
}

export default DisplayOrgNameCard