import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from 'reactstrap';
import Translate from '@spordle/intl-elements';

import withContexts from '../../../helpers/withContexts';
import { OnlineStoreContext } from '../../../contexts/OnlineStoreContext';
import { ReportsContext } from '../../../contexts/ReportsContext';
import { I18nContext } from '../../../contexts/I18nContext';

// components
import SidePanelOnlineStoreGeneralTab from './components/SidePanelOnlineStoreGeneralTab';
import SidePanelOnlineStoreRegistrationsTab from './components/SidePanelOnlineStoreRegistrationsTab';
import OverlayLoader from '../../../components/loading/OverlayLoader';
import SidePanel from '../../../components/sidePanel/SidePanel';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { fail } from '@spordle/toasts';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { counterAnim } from '../../../helpers/uiHelper';


class SidePanelOnlineStoreSearch extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: false, // used for updates
            activeTab: '1',

            registrationsTabLoading: true, // used for a loading for the second tab, makes it invisible to the user if he doesn't toggle the second tab
            registrationFees: [],
            otherFees: [],
            registrationFeesCount: 0,
            otherFeesCount: 0,

            openedRegistrations: false,
            openedOtherItems: false,

            feesCountLoading: false,
        }
    }

    getRegistrationFeesAndOtherFees = (onlineStoreId) => {
        this.setState(() => ({ registrationsTabLoading: true }));
        this.setState(() => ({ feesCountLoading: true }));
        Promise.all([
            this.props.OnlineStoreContext.getOnlineStoreRegistrationFee(onlineStoreId),
            this.props.OnlineStoreContext.getOnlineStoreOtherFee(onlineStoreId),
            this.props.ReportsContext.getRegistrationCountReport({ online_store_id: onlineStoreId, type: 'REGISTRATION' }), // Quick cards in general tab
            this.props.ReportsContext.getRegistrationCountReport({ online_store_id: onlineStoreId, type: 'OTHER' }), // Quick cards in general tab
        ])
            .then((promises) => {
                this.setState((prevState) => {
                    const registrationFeesCount = promises[2].reduce((count, row) => (count + row.item_sold), 0);
                    const otherFeesCount = promises[3].reduce((count, row) => (count + row.item_sold), 0);
                    counterAnim('registrationFeesCount', prevState.registrationFeesCount, registrationFeesCount); // Quick cards counter animation in general tab
                    counterAnim('otherFeesCount', prevState.otherFeesCount, otherFeesCount); // Quick cards counter animation  in general tab

                    //Map report count to each listed item
                    const registrationFees = promises[0].map((fee) => {
                        return ({
                            ...fee,
                            item_sold: promises[2].filter((row) => (fee.registration_fee?.team_category?.team_category_id === row.team_category_id))?.[0]?.item_sold ?? 0,
                        })
                    })

                    const otherFees = promises[1].map((fee) => {
                        return ({
                            ...fee,
                            item_sold: promises[3].filter((row) => (fee.other_fee?.other_fee_id === row.item_id))?.[0]?.item_sold ?? 0,
                        })
                    })


                    this.setState(() => ({ feesCountLoading: false }));

                    return {
                        registrationFees: registrationFees,
                        otherFees: otherFees,
                        registrationFeesCount: registrationFeesCount || 0,
                        otherFeesCount: otherFeesCount || 0,
                        registrationFeesCountData: promises[2] || [],
                        otherFeesCountData: promises[3] || [],
                        registrationsTabLoading: false,
                    }
                })

            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    // when side panel opens
    componentDidMount(){
        this.getRegistrationFeesAndOtherFees(this.props.selectedRows[0].online_store_id)
    }

    // when side panel opens AND when another row is selected with the side panel opened
    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0] !== this.props.selectedRows[0]){
            this.getRegistrationFeesAndOtherFees(this.props.selectedRows[0].online_store_id)
        }
    }

    setIsLoading = (_isLoading) => {
        this.setState(() => ({ isLoading: _isLoading }))
    }

    /*--------------------------------------------------------------------------------*/
    /* Tabs toggles                                                                   */
    /*--------------------------------------------------------------------------------*/
    toggleTab = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState({ activeTab: tab });
        }
    }

    deleteStore = () => {
        return this.props.OnlineStoreContext.deleteOnlineStore(this.props.selectedRows[0].online_store_id)
            .then(() => {
                this.props.tableRef.deleteRow(this.props.selectedRows[0].online_store_id)
                    .then(this.props.toggle)
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    openRegistrations = () => {
        this.setState({ activeTab: "2", openedRegistrations: true, openedOtherItems: false });
    }

    openOtherItems = () => {
        this.setState({ activeTab: "2", openedRegistrations: false, openedOtherItems: true });
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <div className="sticky-top bg-white z-index-1">
                    <div className="p-3">
                        <div className='d-flex mb-3'>
                            <SidePanel.ToggleButton />
                            <div className="d-flex align-items-center">
                                {this.props.selectedRows[0].status !== 'UNDER_CREATION' &&
                                    <Link className='mr-2' to={{ pathname: `/onlineregistration/profile/${this.props.selectedRows[0]?.online_store_id}`, data: this.props.selectedRows[0] }}>
                                        <Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" />
                                    </Link>
                                }
                                {/* <button className="reset-btn p-2 text-warning font-24"><i className="mdi mdi-star-outline"></i></button> */}
                                <SidePanel.ActionsMenu id='online-store-search-action-btn' label='misc.action' action='DELETE' componentCode='page' componentPermissionCode='online_store_manage'>
                                    <SidePanel.MenuDelete
                                        id='online-store-search-delete-action'
                                        modalTitle={<DisplayI18n field="name" i18n={this.props.selectedRows[0].i18n} defaultValue={this.props.selectedRows[0].name} />}
                                        translateModalMsg
                                        modalMsg='onlineStore.search.delete.confirmMessage'
                                        onConfirm={this.deleteStore}
                                    />
                                </SidePanel.ActionsMenu>
                            </div>
                        </div>
                        {/* <div className='mb-3 d-inline-flex align-items-center text-dark'> */}
                        {/* <div className="mr-2">
                                <img className="rounded-circle" src="https://via.placeholder.com/100" alt="" width="50"/>
                            </div> */}
                        <SidePanel.Title>
                            <DisplayI18n field='name' i18n={this.props.selectedRows[0].i18n} defaultValue={this.props.selectedRows[0].name} />
                        </SidePanel.Title>
                        {/* </div> */}
                    </div>

                    <Nav tabs className='w-100'>
                        <NavItem className="w-50 text-center">
                            <NavLink
                                active={this.state.activeTab === '1'}
                                onClick={() => { this.toggleTab('1'); }}
                            >
                                <Translate id='onlineStore.search.sidepanel.tab.general' />
                            </NavLink>
                        </NavItem>
                        <NavItem className="w-50 text-center">
                            <NavLink
                                active={this.state.activeTab === '2'}
                                onClick={() => { this.toggleTab('2'); }}
                            >
                                <Translate id='onlineStore.search.sidepanel.tab.registration' />
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <SidePanelOnlineStoreGeneralTab
                            onlineStore={this.props.selectedRows[0]}
                            panelProps={this.props}
                            setIsLoading={this.setIsLoading}
                            openRegistrations={this.openRegistrations}
                            openOtherItems={this.openOtherItems}
                            feesCountLoading={this.state.feesCountLoading}
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        {this.state.registrationsTabLoading ?
                            <div className='text center'>
                                <Spinner color='primary' type='grow' />
                            </div>
                            :
                            <SidePanelOnlineStoreRegistrationsTab
                                openedRegistrations={this.state.openedRegistrations}
                                openedOtherItems={this.state.openedOtherItems}
                                registrationFees={this.state.registrationFees}
                                otherFees={this.state.otherFees}
                                registrationFeesCountData={this.state.registrationFeesCountData}
                                otherFeesCountData={this.state.otherFeesCountData}
                            />
                        }
                    </TabPane>
                </TabContent>
            </OverlayLoader>
        );
    }
}

export default withContexts(OnlineStoreContext, I18nContext, ReportsContext)(SidePanelOnlineStoreSearch)