import { Component, createContext } from "react";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { RolesContext } from "../../../../contexts/RolesContext";
import withContexts from "../../../../helpers/withContexts";
import MaltreatmentOverviewModal from "./maltreatmentOverview/MaltreatmentOverviewModal";
import AddOutcomeModal from "./sidePanel/components/modals/addOutcome/AddOutcomeModal";
import AddSuspensionModal from "./sidePanel/components/modals/AddSuspensionModal";

/** @type {React.Context<Omit<Schedule, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const DiscriminationContext = createContext();
DiscriminationContext.displayName = 'DiscriminationContext';

class DiscriminationContextProvider extends Component{

    state = {
        // the object stored in the front end params that the user clicked on
        recentMaltreatmentComplaint: undefined,

        currentAPIComplaint: undefined,

        maltreatmentOverviewIsOpen: false,
        addOutcomeIsOpen: false,
        addSuspensionIsOpen: false,

        canCreateSuspension: this.props.RolesContext.canDoAction('ADD', 'members', 'members_suspensions'),
    }

    toggleMaltreatmentOverviewModal = (isOpen) => {
        this.setState((prev) => ({
            ...prev,
            maltreatmentOverviewIsOpen: isOpen,
        }))
    }

    toggleAddOutcomeModal = (isOpen) => {
        this.setState((prev) => ({
            ...prev,
            addOutcomeIsOpen: isOpen,
        }))
    }

    toggleAddSuspensionModal = (isOpen) => {
        this.setState((prev) => ({
            ...prev,
            addSuspensionIsOpen: isOpen,
        }))
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    setRecentMaltreatmentComplaint = (complaint) => {
        this.setState((prev) => ({
            ...prev,
            recentMaltreatmentComplaint: complaint,
        }))
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    setCurrentAPIComplaint = (complaint) => {
        this.setState((prev) => ({
            ...prev,
            currentAPIComplaint: complaint,
        }))
    }

    render(){
        return (
            <DiscriminationContext.Provider
                value={{
                    ...this,
                }}
            >
                {/* Maltreatment overview modal */}
                <MaltreatmentOverviewModal
                    isOpen={this.state.maltreatmentOverviewIsOpen}
                    toggle={() => {
                        this.toggleMaltreatmentOverviewModal(false)
                    }}
                    referenceNumber={this.state.recentMaltreatmentComplaint?.referenceNumber}
                    numberOfMembers={this.state.recentMaltreatmentComplaint?.numberOfMembers}
                />

                {/* Add Outcome Modal */}
                <AddOutcomeModal
                    maltreatment={this.state.currentAPIComplaint}
                    isOpen={this.state.addOutcomeIsOpen}
                    toggle={() => {
                        this.toggleAddOutcomeModal(false)
                    }}
                    editOutcomeData={{ isEdit: false }}
                    refreshData={(values) => {
                        this.props.refreshTable()

                        const canOpen = this.state.canCreateSuspension || this.props.IdentityRolesContext.isGod();
                        const shouldOpen = (this.state.currentAPIComplaint?.discrimination_member?.[0]?.member?.member_id || this.state.currentAPIComplaint?.discrimination_member?.[0]?.team?.team_id) && values.outcome?.causes_suspension == 1;

                        if(shouldOpen && canOpen){
                            this.toggleAddSuspensionModal(true);
                        }else{
                            this.toggleMaltreatmentOverviewModal(true);
                        }
                    }}
                />

                {/* Add Suspension Modal */}
                <AddSuspensionModal
                    maltreatment={this.state.currentAPIComplaint}
                    isOpen={this.state.addSuspensionIsOpen}
                    toggle={() => this.toggleAddSuspensionModal(false)}
                    onSuccess={() => {
                        this.toggleMaltreatmentOverviewModal(true);
                    }}
                    refreshTable={this.props.refreshTable}
                    initFormData={{
                        location: this.state.currentAPIComplaint?.arena || "",
                        incident_date: this.state.currentAPIComplaint?.incident_date || "",
                        team_id: this.state.currentAPIComplaint?.discrimination_member?.[0]?.team_id || "",
                    }}
                />

                {this.props.children}
            </DiscriminationContext.Provider>
        )
    }
}

export default withContexts(IdentityRolesContext, RolesContext)(DiscriminationContextProvider);