import React from "react";
import {
    Label,
    FormGroup
} from "reactstrap";
import { FormikCheckedButton, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import * as Yup from 'yup'
import { success, fail } from '@spordle/toasts';
import PropTypes from 'prop-types';
import Translate from "@spordle/intl-elements";
import withContexts from "../../../../../helpers/withContexts";
import { PositionsContext } from "../../../../../contexts/PositionsContext";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from "../../../../../helpers/i18nHelper";
import FormikEditable from "../../../../../components/formik/FormikEditable";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../components/sidePanel/SidePanel";

class SidePanelOrganizationPositions extends React.Component{
    state = {
        isLoading: false,

        isBranch: this.props.OrganizationContext.organisation_category?.name === 'Member branches', // TODO: HARDCODED
    };

    toggleLoading = (bool) => {
        this.setState((prevState) => ({ isLoading: bool ?? !prevState.isLoading }))
    }

    render(){
        return (
            <I18nHelperContext.Consumer>
                { (i18nHelper) => (
                    <OverlayLoader isLoading={this.state.isLoading}>
                        <SidePanel.Header>
                            <div className='d-flex mb-2 align-items-center'>
                                <SidePanel.ToggleButton />
                                <SidePanel.ActionsMenu action='DELETE' componentCode='teams' componentPermissionCode='team_positions'>
                                    <SidePanel.MenuDelete
                                        translateModalMsg
                                        modalMsg='organization.settings.organizationPositions.sidepanel.delete.message'
                                        modalTitle={<DisplayI18n field="name" i18n={this.props.getCommonValue('i18n')} defaultValue={this.props.getCommonValue('name')} />}
                                        onConfirm={() => {
                                            return this.props.PositionsContext.deletePosition(this.props.getCommonValue('position_id'))
                                                .then(() => {
                                                    this.props.tableRef.deleteRow(this.props.getCommonValue('position_id')).then(this.props.toggle);
                                                    success();
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                        }}
                                    />
                                </SidePanel.ActionsMenu>
                            </div>
                            <SidePanel.Title>
                                <DisplayI18n
                                    field='name'
                                    i18n={this.props.selectedRows[0].i18n}
                                    defaultValue={this.props.selectedRows[0].name}
                                />
                            </SidePanel.Title>
                        </SidePanel.Header>
                        <div className='p-3'>

                            {this.state.isBranch &&
                                <div className="mb-3">
                                    <FormikEditable
                                        id="managePosition"
                                        initialValues={{
                                            managePosition: this.props.selectedRows[0].managed,
                                        }}
                                        onSubmit={(values) => {
                                            this.toggleLoading(true);
                                            const initialManagePosition = this.props.selectedRows[0].managed;

                                            if(initialManagePosition !== values.managePosition){
                                                const newValues = {};
                                                if(values.managePosition){
                                                    newValues.allowed_positions = [
                                                        ...this.props.OrganizationContext.settings.allowed_positions?.value || [],
                                                        this.props.selectedRows[0].position_id,
                                                    ]
                                                }else{
                                                    newValues.allowed_positions = this.props.OrganizationContext.settings.allowed_positions?.value?.filter((pId) => pId !== this.props.selectedRows[0].position_id)
                                                }

                                                this.props.OrganizationContext.updateSettings(this.props.OrganizationContext.organisation_id, newValues)
                                                    .then(() => {
                                                        this.props.syncRows(this.props.createNewValues({ managed: values.managePosition }))
                                                        success();
                                                        this.toggleLoading(false);
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                            this.toggleLoading(false);
                                                        }
                                                    })
                                            }
                                        }}
                                    >
                                        {(isEditing, options) => {
                                            if(!isEditing){
                                                return (
                                                    <div className='font-medium text-dark'>
                                                        <i
                                                            className={
                                                                this.props.selectedRows[0].managed ?
                                                                    'mdi mdi-check text-primary mr-1'
                                                                    :
                                                                    'mdi mdi-close text-danger mr-1'
                                                            }
                                                        />
                                                        <Translate id='organization.settings.organizationPositions.add.managePosition' />
                                                    </div>
                                                )
                                            }
                                            return (
                                                <FormikCheckedButton id='managePosition' name='managePosition' label='organization.settings.organizationPositions.add.managePosition' />
                                            )

                                        }}
                                    </FormikEditable>
                                </div>
                            }

                            <FormGroup>
                                <Label for='positionGroup' className='text-muted'><Translate id='organization.settings.organizationPositions.sidepanel.field.positionGroup' /></Label>
                                <div className='font-medium text-dark'>
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={this.props.selectedRows[0].position_group?.name || '-'}
                                        i18n={this.props.selectedRows[0].position_group?.i18n}
                                    />
                                </div>
                            </FormGroup>

                            <div className="mb-3">
                                <Label for="status" className="text-muted"><Translate id='organization.settings.organizationPositions.add.status' /></Label>
                                <FormikEditable
                                    id="status"
                                    noConfirmation
                                    initialValues={{
                                        status: this.props.selectedRows[0].active,
                                    }}
                                    onSubmit={(values, actions) => {
                                        if(values.status !== this.props.selectedRows[0].active){
                                            this.toggleLoading(true);
                                            const newValues = this.props.createNewValues({ active: values.status });
                                            this.props.PositionsContext.updatePositionPartial(this.props.getCommonValue('position_id'), { active: values.status })
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    success();
                                                    this.toggleLoading(false);
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                        this.toggleLoading(false);
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    {this.props.selectedRows[0].active === '1' ? <><i className='mdi-check mdi text-primary mr-2' /><Translate id='misc.active' /></> : <><i className='mdi-close mdi text-danger mr-2' /><Translate id='misc.inactive' /></>}
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                name='status'
                                                id='status_SidepanelOrganizationPositions'
                                                search={false}
                                                autoFocus
                                                menuIsDefaultOpen
                                                onOptionSelected={options.stopEditing}
                                                loadingStatus='success'
                                                defaultData={[
                                                    { id: 'status-active', label: 'misc.active', translateLabel: true, value: '1', selected: this.props.selectedRows[0].active === '1' },
                                                    { id: 'status-inactive', label: 'misc.inactive', translateLabel: true, value: '0', selected: this.props.selectedRows[0].active === '0' },
                                                ]}
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                            <RenderI18nForm field="name">
                                {({ fieldName, fieldLabel }) => {
                                    return (
                                        <div className="mb-3" key={fieldName}>
                                            <Label for={fieldName} className="text-muted">{fieldLabel}</Label>
                                            <FormikEditable
                                                id={fieldName}
                                                initialValues={
                                                    i18nHelper.getInitialValues(this.props.selectedRows[0])
                                                }
                                                validationSchema={Yup.object().shape(
                                                    i18nHelper.getValidationSchema({ name: Yup.string().required(<Translate id='form.validation.name.required' />) }),
                                                )}
                                                onSubmit={(values) => {
                                                    if(values.i18n.en.name !== this.props.selectedRows[0].i18n.en.name || values.i18n.fr.name !== this.props.selectedRows[0].i18n.fr.name){
                                                        const newValues = this.props.createNewValues({ ...this.props.selectedRows[0], ...values });

                                                        this.toggleLoading(true);
                                                        this.props.PositionsContext.updatePositionPartial(this.props.getCommonValue('position_id'), i18nHelper.getAPIValues(values))
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                                this.toggleLoading(false);
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                    this.toggleLoading(false);
                                                                }
                                                            })
                                                    }
                                                }}
                                            >
                                                {(isEditing) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark'>
                                                                <DisplayI18n
                                                                    field={fieldName}
                                                                    defaultValue={this.props.selectedRows[0].name || '-'}
                                                                    i18n={this.props.selectedRows[0].i18n}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <FormikInputText id={fieldName} name={fieldName} autoFocus />
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    )
                                }}
                            </RenderI18nForm>
                            <div className="mb-3">
                                <Label for="abbreviation" className="text-muted"><Translate id='organization.settings.organizationPositions.add.abbreviation' /></Label>
                                <FormikEditable
                                    id="abbreviation"
                                    initialValues={{
                                        abbreviation: this.props.selectedRows[0].abbreviation,
                                    }}
                                    validationSchema={Yup.object().shape({
                                        abbreviation: Yup.string().required(<Translate id='organization.settings.organizationPositionGroups.sidepanel.abbreviation.required' />),
                                    })}
                                    onSubmit={(values, actions) => {
                                        if(values.abbreviation !== this.props.selectedRows[0].abbreviation){

                                            this.toggleLoading(true);
                                            const newValues = this.props.createNewValues({ ...this.props.selectedRows[0], ...values });
                                            this.props.PositionsContext.updatePositionPartial(this.props.getCommonValue('position_id'), values)
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    success();
                                                    this.toggleLoading(false);
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                        this.toggleLoading(false);
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium text-dark'>
                                                    <DisplayI18n
                                                        field="abbreviation"
                                                        defaultValue={this.props.selectedRows[0].abbreviation || '-'}
                                                        i18n={this.props.selectedRows[0].i18n}
                                                    />
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikInputText id="abbreviation" name="abbreviation" autoFocus />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                        </div>

                    </OverlayLoader>
                )}
            </I18nHelperContext.Consumer>
        )
    }
}

SidePanelOrganizationPositions.propTypes = {
    tableRef: PropTypes.object.isRequired,
}

export default withContexts(PositionsContext, OrganizationContext)(SidePanelOrganizationPositions);
