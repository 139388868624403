import { FormikError } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { Button, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { array, object } from "yup";
import AnalyticsModal from "../../../analytics/AnalyticsModal";
import OverlayLoader from "../../../components/loading/OverlayLoader";
import FileUpload from "../../../components/uploader/fileUpload/FileUpload";
import CrossFade from "../../../components/crossFade/CrossFade";
import CustomAlert from "../../../components/CustomAlert";
import { useContext } from "react";
import { JiraContext } from "../../../contexts/JiraContext";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import { DisplayI18n } from "../../../helpers/i18nHelper";


const AddAttachmentModal = ({ isOpen, ...props }) => {

    return (
        <AnalyticsModal analyticsName="addAttachmentModal" isOpen={isOpen}>
            <AddAttachmentModalInner {...props} />
        </AnalyticsModal>
    )
}

const AddAttachmentModalInner = ({ toggle, issueKey, getAttachments, ...props }) => {
    const jiraContext = useContext(JiraContext);

    return (
        <Formik
            initialValues={{
                attachments: [],
            }}
            validationSchema={object().shape({
                attachments: array().min(1, <Translate id='supportRequests.profile.attachment.addModal.file.required' />),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                setSubmitting(true);
                jiraContext.createTemporaryFile(values.attachments[0])
                    .then(async(data) => {
                        const tempId = data.temporaryAttachments[0].temporaryAttachmentId;
                        await jiraContext.createAttachment(issueKey, {
                            attachment_ids: [ tempId ],
                        })
                        await getAttachments();
                        setSubmitting(false);
                        toggle();
                    })
                    .catch((error) => {
                        if(AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            setSubmitting(false);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggle}>
                            <Translate id='supportRequests.profile.attachment.addModal.title' />
                        </ModalHeader>
                        <ModalBody>
                            <Label className='text-muted'><Translate id='supportRequests.profile.attachment.addModal.file' /></Label>
                            <FileUpload
                                onFileSelect={(files) => {
                                    formik.setFieldValue('attachments', files);
                                }}
                                dropzoneProps={{
                                    multiple: false,
                                    accept: "image/jpeg, image/png, image/jpg",
                                    maxSize: 20971520,
                                }}
                            />
                            <FormikError name='attachments' />

                            <CrossFade isVisible={!!formik.status} appear mountOnEnter unmountOnExit>
                                {formik.status &&
                                    <CustomAlert className='mt-3' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                }
                            </CrossFade>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color='primary'
                                type='submit'
                                disabled={formik.isSubmitting}
                            >
                                <Translate id='misc.add' />
                            </Button>
                            <Button
                                color='primary'
                                type='button'
                                outline onClick={() => toggle()}
                                disabled={formik.isSubmitting}
                            >
                                <Translate id='misc.cancel' />
                            </Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default AddAttachmentModal;