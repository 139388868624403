import Translate from '@spordle/intl-elements';
import moment from 'moment';
import Waivers from './waivers/Waivers';

const MemberProfileRegistrationSidepanelRegistration = (props) => {
    const refreshTable = () => {
        props.toggle();
        props.tableRef.refreshTable();
        props.setForceWaiversRefresh();
    }

    return (
        <>
            <div className="p-3">
                <div className="h4 font-bold"><Translate id='memberProfile.registration.sidepanel.registration' /></div>
                <div className="mb-3">
                    {props.selectedRows[0].identity &&
                        <>
                            <div className="text-muted"><Translate id='memberProfile.registration.sidepanel.confirmedBy' /></div>
                            <div className="font-medium text-dark">{props.selectedRows[0].identity.name + ' ' + props.selectedRows[0].identity.family_name}</div>
                        </>
                    }
                    <div className="font-medium text-dark">{moment(props.selectedRows[0].invoice_date).format("LLL")}</div>
                    <div className="font-medium text-dark"><Translate id={`memberProfile.registration.form.status.${props.selectedRows[0].origin.toUpperCase()}.registration`} /></div>
                </div>
            </div>
            {!!props.selectedRows[0]?.waivers && props.selectedRows[0]?.waivers.length > 0 &&
            <div className="border-top p-3">
                <Waivers registration={props.selectedRows[0]} refreshTable={refreshTable} />

                {/* <MemberProfileRegistrationWaiver signed/>
                <MemberProfileRegistrationWaiver signed/>
                <MemberProfileRegistrationWaiver /> */}
            </div>
            }
        </>
    );
}

export default MemberProfileRegistrationSidepanelRegistration;