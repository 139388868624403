import OnlineStoreItemsAndFeesComponent from '../../components/itemsAndFees/OnlineStoreItemsAndFeesComponent';

const OnlineStoreItemsAndFees = ({ onlineStoreId }) => {

    return (
        <>
            <OnlineStoreItemsAndFeesComponent from="PROFILE" onlineStoreId={onlineStoreId} />
        </>
    )
}

export default OnlineStoreItemsAndFees;