import { useContext } from 'react';
import {
    Row,
    Col,
    Button,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    ModalFooter
} from "reactstrap";
import { Form, Formik } from 'formik';
import { FormikInputNumber, FormikInputText } from '@spordle/formik-elements';
import Translate from "@spordle/intl-elements";
import { object, string, number } from 'yup';

// Contexts
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';
import { RegistrationClassesContext } from "../../../../contexts/RegistrationClassesContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { SpordleTableContext } from '@spordle/datatables';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import Required from '../../../../components/formik/Required';
import { success, fail } from '@spordle/toasts';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';

const SettingsRegistrationClassAddModal = ({ orderSelectOptions, isOpen, toggle, props }) => {
    const classesContext = useContext(RegistrationClassesContext);
    const orgContext = useContext(OrganizationContext);
    const spordleTable = useContext(SpordleTableContext);

    return (
        <I18nHelperContext.Consumer>
            { (i18nHelper) => (
                <AnalyticsModal isOpen={isOpen} analyticsName='SettingsRegistrationClassAddModal'>
                    <Formik
                        initialValues={{
                            ...i18nHelper.getInitialValues(),
                            display_order: spordleTable.getData().length + 1,
                        }}
                        validationSchema={object().shape({
                            ...i18nHelper.getValidationSchema({
                                name: string().required(<Translate id='catalog.otherItems.otherItemsAdd.form.validation.description' />),
                                short_name: string().required(<Translate id='catalog.otherItems.otherItemsAdd.form.validation.name' />),
                            }),
                            display_order: number()
                                .integer(<Translate id='registration.settings.class.form.validation.order.integer' />)
                                .moreThan(0, <Translate id='registration.settings.class.form.validation.order.moreThan0' />)
                                .required(<Translate id='registration.settings.class.form.validation.order.required' />),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            values.active = '1';

                            // data to send to api
                            const apiValues = { ...values, ...i18nHelper.getAPIValues(values) };
                            delete apiValues.i18n;

                            classesContext.createRegistrationClass(orgContext.organisation_id, apiValues)
                                .then((class_id) => {
                                    spordleTable.refreshTable();
                                    toggle();
                                    success();
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                    }
                                })
                                .finally(() => setSubmitting(false))
                        }}
                    >
                        {(formik) => (
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <Form>
                                    <ModalHeader toggle={toggle}>
                                        <Translate id='registration.settings.class.modal.title' />
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <RenderI18nForm field="short_name">
                                                {({ fieldName, fieldLabel }) => (
                                                    <Col sm='6' key={fieldName}>
                                                        <FormGroup>
                                                            <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                                            <FormikInputText id={fieldName} name={fieldName} trim />
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                            </RenderI18nForm>
                                            <RenderI18nForm field="name">
                                                {({ fieldName, fieldLabel }) => {
                                                    const lang = fieldName.substring(0, fieldName.lastIndexOf('.'));

                                                    return (
                                                        <Col sm='6' key={fieldName}>
                                                            <FormGroup>
                                                                <Label for={fieldName} className='text-muted'>
                                                                    <Translate id='form.fields.description.lang' values={{ lang: lang.substring(lang.indexOf('.') + 1, lang.length).toUpperCase() }} /> <Required />
                                                                </Label>
                                                                <FormikInputText id={fieldName} name={fieldName} trim />
                                                            </FormGroup>
                                                        </Col>
                                                    )
                                                }}
                                            </RenderI18nForm>
                                            <Col sm='6'>
                                                <FormGroup>
                                                    <Label for="display_order" className="text-muted"><Translate id='registration.settings.class.form.label.order' /></Label>
                                                    <FormikInputNumber
                                                        id='display_order'
                                                        name='display_order'
                                                        allowNegative={false}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" type="submit"><Translate id='misc.save' /></Button>
                                        <Button color="primary" onClick={toggle} outline><Translate id='misc.cancel' /></Button>
                                    </ModalFooter>
                                </Form>
                            </OverlayLoader>
                        )}
                    </Formik>
                </AnalyticsModal>
            )}
        </I18nHelperContext.Consumer>
    )
}

export default SettingsRegistrationClassAddModal;