import { createContext, useContext, useEffect, useState } from 'react';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { JiraContext } from '../../../../../contexts/JiraContext';
import { NotificationsContext } from '../../../../../contexts/NotificationsContext';
import { ReportsContext } from '../../../../../contexts/ReportsContext';
import moment from 'moment';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';

/** @type {React.Context<Omit<NotificationsDataManagerProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const NotificationsDataManager = createContext();
NotificationsDataManager.displayName = 'NotificationsDataManager';

// not using a react component because we need useEffect to change the notifs count
const NotificationsDataManagerProvider = (props) => {

    // -- Contexts
    const organizationContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const jiraContext = useContext(JiraContext);
    const notificationsContext = useContext(NotificationsContext);
    const reportsContext = useContext(ReportsContext);

    // -- States
    const [ notifsLoading, setNotifsLoading ] = useState(true);
    const [ notifs, setNotifs ] = useState(null);
    const [ notifsCount, setNotifsCount ] = useState(0);
    const [ jiraNotifs, setJiraNotifs ] = useState(null);
    const [ isClearing, setIsClearing ] = useState(false);

    /**
    * **********************************************************************************************************************
    * Account Notifications (Organization Notifications)
    * **********************************************************************************************************************
    */
    // Gets all notifications
    const getNotifications = () => {
        return notificationsContext.getNotifications({
            to_organisation_id: organizationContext.organisation_id,
            sort: '-created_at',
        })
            .then((notifs) => {
                // manual filter on identity_role_id waiting for api
                const allNotifs = notifs.filter((n) => n.identity_role_id === identityRolesContext.identity_role_id)
                    .sort((nA, nB) => {
                        if(nA.created_at > nB.created_at)
                            return -1;
                        else if(nA.created_at < nB.created_at)
                            return 1;
                        return 0;
                    });

                setNotifs(allNotifs);
                notifCount(allNotifs);

                return true;
            })
            .catch(console.error)
            .finally(() => setNotifsLoading(false));
    }

    // Set all notifications as read
    const markAllAsRead = () => {
        setIsClearing(true);
        notificationsContext.updateMarkAllAsRead()
            .then(() => {
                getNotifications()
                    .then(() => {
                        setIsClearing(false)
                    })
                    .catch(console.error)
            })
            .catch(console.error)
    }

    // Set a notification as read
    const setRead = (notif) => {
        const myNotifs = notifs
        myNotifs.forEach((myNotif) => {
            if(myNotif.notification_id === notif.notification_id){
                setNotifs({ ...notifs })
                myNotif.read_by = "me"; // Simple fill out to make recount work
                myNotif.read_at = moment();
            }
        });
        setNotifs(myNotifs);
        notifCount(myNotifs);
    }

    // Run through the notifications array and counts all non read notifs
    const notifCount = (myNotifs) => {
        const newNotifsCount = myNotifs.filter((n) => n.read_by == null).length
        setNotifsCount(newNotifsCount)
    }

    // Deletes all notifications
    const deleteAllNotifs = () => {
        setIsClearing(true);

        notificationsContext.deleteAllNotifications()
            .then(() => {
                setNotifs(null);
                setNotifsCount(0);
                setIsClearing(false);
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    setIsClearing(false);
                }
            })
    }

    /**
    * **********************************************************************************************************************
    * Jira Notifications (support requests)
    * **********************************************************************************************************************
    */
    const getJiraNotifications = () => {
        jiraContext.getNotifications()
            .then(setJiraNotifs)
            .catch(console.error);
    }

    const deleteJiraNotif = (key) => {
        const newArr = jiraNotifs?.filter((n) => n.issue_key !== key);
        setJiraNotifs(newArr);
    }

    const markAllJiraAsRead = () => {
        setIsClearing(true);
        Promise.all([
            ...jiraNotifs.map((n) => (
                new Promise((resolve) => {
                    jiraContext.deleteNotification(n.issue_key)
                        .catch(console.error)
                        .finally(resolve)
                })
            )),
        ])
            .then(() => setJiraNotifs([]))
            .catch(console.error)
            .finally(() => setIsClearing(false))
    }

    useEffect(() => {
        getNotifications();
        getJiraNotifications();

        const interval = setInterval(() => {
            if(document.hasFocus() && navigator.onLine){
                getNotifications()
                getJiraNotifications()
            }
        }, reportsContext.state.isGenerating ? 60000 : 300000) // every 1 minutes or 5 minutes

        return () => clearInterval(interval);
    }, [ organizationContext.organisation_id, identityRolesContext.identity_role_id, reportsContext.state.isGenerating ]);

    // update context's state whenever jiraNotifs or notifsCounts changes
    useEffect(() => {
        notificationsContext.setNotificationsCount((jiraNotifs?.length || 0) + notifsCount)
    }, [ jiraNotifs?.length, notifsCount ])


    return (
        <NotificationsDataManager.Provider
            value={{
                notifs: notifs,
                notifsCount: notifsCount,
                notifsLoading: notifsLoading,
                isClearing: isClearing,
                jiraNotifs: jiraNotifs,
                markAllAsRead: markAllAsRead,
                deleteAllNotifs: deleteAllNotifs,
                setRead: setRead,
                setNotifs: setNotifs,
                setNotifsCount: setNotifsCount,
                markAllJiraAsRead: markAllJiraAsRead,
                deleteJiraNotif: deleteJiraNotif,
            }}
        >
            {props.children}
        </NotificationsDataManager.Provider>
    )
}

export default NotificationsDataManagerProvider;