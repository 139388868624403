import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import {
    Button, Card,
    CardBody,
    Collapse,
    Spinner,
    UncontrolledDropdown
} from "reactstrap";
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { ClinicsContext } from '../../../../contexts/ClinicsContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { copyToClipBoard } from '../../../../helpers/clipboardHelper';
import getReferrer from '../../../../helpers/getReferer';
import { displayI18n, DisplayI18n } from '../../../../helpers/i18nHelper';
import AdvancedSettings from './AdvancedSettings';
import queryString from 'query-string';
import { AuthContext } from '../../../../contexts/contexts';
import { stringBuilder } from "@spordle/helpers";
import CanDoAction from '../../../../components/permissions/CanDoAction';
import ConfirmModal from '../../../../components/confirmModal/ConfirmModal';
import ClinicCategory from '../../ClinicCategory';
import { success, fail } from '@spordle/toasts';
import generatePassword from './../../../../helpers/passwordGenerator';
import { counterAnim } from '../../../../helpers/uiHelper';
import ClinicHeaderStatus from './ClinicHeaderStatus';
import { I18nContext } from '../../../../contexts/I18nContext';
import { RolesContext } from '../../../../contexts/RolesContext';
import { Tooltip } from '@mantine/core';

const ClinicHeader = ({ isHost, toggleHostView, attendeeTable, waitlistTable, waitListCount }) => {
    const clinicsContext = useContext(ClinicsContext);
    const orgsContext = useContext(OrganizationContext);
    const authContext = useContext(AuthContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { clinicId } = useParams();
    const history = useHistory();
    const rolesContext = useContext(RolesContext);

    const [ selectedClinicState, setSelectedClinicState ] = useState(false);

    const [ cardsLoading, setCardsLoading ] = useState(true);
    const [ cardValues, setCardValues ] = useState({
        attendeesCard: 0,
        unconfirmedCard: 0,
        waitingListCard: 0,
    });

    const [ visibilitySettings, setVisibilitySettings ] = useState(false); // set to true if at least 1 setting has ben set
    const toggleVisibilitySettings = () => setVisibilitySettings(!visibilitySettings);

    const updateCurrentVisibility = (status, active_clinic_state_id, { name, i18n }) => {
        clinicsContext.createStatus(clinicsContext.currentClinic?.clinic_id, { status: status, startDate: new Date().toISOString() }, active_clinic_state_id)
            .then(() => {
                success({ msg: 'clinics.profile.clinicProfile.success.statusUpdate', info: displayI18n('name', i18n, name, getGenericLocale()), skipInfoTranslate: true });
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    useLayoutEffect(() => {
        if(!rolesContext.hasAccessTo('clinics', 'clinics_manage')){
            clinicsContext.getClinicAttendees(clinicId)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
    }, [])

    useEffect(() => {
        // current clinic hasn't been changed yet
        if(clinicsContext.currentClinic.clinic_id === clinicId){
            // logic is based on the attendees tab, we wait for the attendees table to load the data
            // but when the user doesn't have access to attendees, the data is never loaded, so we do the call here in the useLayoutEffect above
            if(clinicsContext.currentClinic.clinic_attendees && Array.isArray(clinicsContext.currentClinic.clinic_attendees)){
                setCardsLoading(true);
                setCardValues({
                    attendeesCard: parseInt(clinicsContext.currentClinic.clinic_attendee_count || 0),
                    unconfirmedCard: clinicsContext.currentClinic.clinic_attendees.filter((attendee) => attendee.member.confirmed == '0').length,
                    waitingListCard: waitListCount || 0,
                });

                setCardsLoading(false)
            }
        }
    }, [ clinicsContext.currentClinic.clinic_attendees, clinicsContext.currentClinic.clinic_id, waitlistTable.current?.getData().length ]);

    return (
        <>
            <Card className={`clinic-header-${(!isNaN(parseInt(clinicsContext.currentClinic?.category?.color))) ? clinicsContext.currentClinic?.category?.color : ' card-shadow bg-light'}`}>
                <OverlayLoader isLoading={!(clinicsContext.currentClinic?.category?.color)}>
                    <CardBody className="p-3">
                        <div className="d-flex flex-wrap text-white mb-3">
                            <div className="font-medium">
                                <div className="h3 mb-0 text-reset font-bold">
                                    <span>
                                        <DisplayI18n
                                            field='name'
                                            defaultValue={clinicsContext.currentClinic?.qualification?.qualification_category.name}
                                            i18n={clinicsContext.currentClinic?.qualification?.qualification_category.i18n}
                                        />
                                    </span>
                                </div>
                                <div>
                                    <span>{clinicsContext.currentClinic?.clinic_reference}</span>
                                    <span className="mx-2">|</span>
                                    <span>
                                        <DisplayI18n field='name' i18n={clinicsContext.currentClinic?.qualification?.i18n} defaultValue={clinicsContext.currentClinic?.qualification?.name} />{clinicsContext.currentClinic?.qualification?.qualification_level && <> - <DisplayI18n field='name' i18n={clinicsContext.currentClinic?.qualification?.qualification_level?.i18n} defaultValue={clinicsContext.currentClinic?.qualification?.qualification_level?.name} /></>}
                                    </span>
                                </div>
                            </div>
                            <div className="ml-auto">
                                <div className="d-flex flex-wrap align-items-center">
                                    {clinicsContext.currentClinic?.organisation?.organisation_id === orgsContext.organisation_id &&
                                        <Button id='colinic-header-toggle-guest-host-view' color='light' className='mr-2' outline onClick={toggleHostView}>
                                            <Translate id={`clinics.profile.clinicProfile.showAs.${isHost ? 'GUEST' : 'HOST'}`} />
                                        </Button>
                                    }

                                    {isHost &&
                                        <CanDoAction tag={UncontrolledDropdown} action='EDIT' componentCode='clinics' componentPermissionCode='clinics_manage'>
                                            <ClinicHeaderStatus updateCurrentVisibility={updateCurrentVisibility} selectedClinicState={selectedClinicState} setSelectedClinicState={setSelectedClinicState} />
                                        </CanDoAction>
                                    }
                                    <Tooltip withArrow label={<Translate id='clinics.profile.clinicProfile.tooltip.store' />}>
                                        <Button
                                            tag='a'
                                            href={queryString.stringifyUrl({
                                                url: `${getReferrer('HCR-SITE')}/clinic-details/${clinicsContext.currentClinic.clinic_id}`,
                                                query: {
                                                    accessToken: authContext.accessToken,
                                                },
                                            })}
                                            rel='noopener noreferrer' target='_blank'
                                            className="mr-2" color="light" outline
                                        >
                                            <i className="mdi mdi-cart-outline" />
                                        </Button>
                                    </Tooltip>

                                    <Tooltip withArrow label={<Translate id='clinics.profile.clinicProfile.tooltip.copyUrl' />}>
                                        <Button
                                            id='clinic-header-copy-URL'
                                            className="mr-2" color="light" outline
                                            onClick={() => {
                                                copyToClipBoard(`${getReferrer('HCR-SITE')}/clinic-details/${clinicsContext.currentClinic.clinic_id}`);
                                            }}
                                        >
                                            <i className="mdi mdi-content-copy" />
                                        </Button>
                                    </Tooltip>

                                    {isHost &&
                                        <CanDoAction action='EDIT' componentCode='clinics' componentPermissionCode='clinics_manage'>
                                            <Tooltip withArrow label={<Translate id='clinics.profile.clinicProfile.tooltip.advancedSettings' />}>

                                                <Button id='clinic-header-advanced-settings' color="light" className="mr-2" outline onClick={toggleVisibilitySettings}>
                                                    <i className="fa fa-cog" />
                                                </Button>
                                            </Tooltip>
                                        </CanDoAction>
                                    }

                                    <CanDoAction action='DELETE' componentCode='clinics' componentPermissionCode='clinics_manage'>
                                        <ConfirmModal
                                            translateModalMsg
                                            modalMsg={clinicsContext.currentClinic?.clinic_attendee_count > 0 ? 'clinics.search.sidePanelClinicSearch.delete.cantDeleteAttendees' : 'clinics.search.sidePanelClinicSearch.delete.message'}
                                            disableConfirm={clinicsContext.currentClinic?.clinic_attendee_count > 0}
                                            modalTitle={
                                                <ClinicCategory color={isNaN(parseInt(clinicsContext.currentClinic?.qualification?.qualification_category?.color)) ? 0 : clinicsContext.currentClinic?.qualification.qualification_category?.color}>
                                                    <DisplayI18n
                                                        field='name'
                                                        defaultValue={clinicsContext.currentClinic?.qualification?.qualification_category?.name}
                                                        i18n={clinicsContext.currentClinic?.qualification?.qualification_category?.i18n}
                                                    />
                                                    <span className='mx-1'>-</span>
                                                    <DisplayI18n
                                                        field='name'
                                                        defaultValue={clinicsContext.currentClinic?.qualification?.name}
                                                        i18n={clinicsContext.currentClinic?.qualification?.i18n}
                                                    />
                                                    {clinicsContext.currentClinic?.qualification?.qualification_level &&
                                                        <>
                                                            <span className='mx-1'>-</span>
                                                            <DisplayI18n
                                                                field='name'
                                                                defaultValue={clinicsContext.currentClinic?.qualification?.qualification_level?.name}
                                                                i18n={clinicsContext.currentClinic?.qualification?.qualification_level?.i18n}
                                                            />
                                                        </>
                                                    }
                                                </ClinicCategory>
                                            }
                                            onConfirm={() => {
                                                return clinicsContext.deleteClinic(clinicsContext.currentClinic.clinic_id)
                                                    .then(() => {
                                                        history.push('/clinics')
                                                        success();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            if(error.i18n){
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }else{
                                                                fail({ msg: `context.clinicsContext.errors.${error.message}` });
                                                            }
                                                        }
                                                    })
                                            }}
                                            toggler={(fn) => (
                                                <Button id='clinic-header-delete-clinic' color="light" className="mr-2" outline onClick={fn}>
                                                    <i className="fas fa-trash" />
                                                </Button>
                                            )}
                                        />
                                    </CanDoAction>

                                </div>
                            </div>
                        </div>
                        <div className="clinic-btn-container">
                            <ClinicLink
                                isShown
                                label={<Translate id='clinics.profile.clinicProfile.tabs.attendees' />}
                                value={cardValues.attendeesCard}
                                loading={cardsLoading}
                                decimalValue={clinicsContext.currentClinic.max_attendee}
                                to='attendees'
                            />
                            {/* TODO: use CanDoAction/HasAccessTo instead of prop isShown */}
                            <ClinicLink
                                isShown
                                label={<Translate id='clinics.profile.clinicProfile.tabs.unconfirmed' />}
                                value={cardValues.unconfirmedCard}
                                loading={cardsLoading}
                                onClick={() => {
                                    attendeeTable.current?.filterChange('unconfirmed', true);
                                }}
                                to='attendees'
                            />
                            {clinicsContext.currentClinic.manage_waiting_list == '1' &&
                                <ClinicLink
                                    isShown
                                    label={<Translate id='clinics.profile.clinicProfile.tabs.waitinglist' />}
                                    value={cardValues.waitingListCard}
                                    loading={cardsLoading}
                                    to='waitlist'
                                />
                            }
                        </div>
                    </CardBody>
                </OverlayLoader>
            </Card>

            <Collapse isOpen={isHost && visibilitySettings} mountOnEnter>
                <AdvancedSettings toggleVisibilitySettings={toggleVisibilitySettings} />
            </Collapse>
        </>
    );
}

// example
// - value: 2
// - label: 'Session'
// - onclick: () => setActiveTab('overview')
// - info: true
const ClinicLink = ({ value, label, isShown, to, comingSoon, loading, decimalValue, onClick }) => (
    isShown ?
        comingSoon ?
            <div className='clinic-btn'>
                <QuickBoxCard value={value} label={label} loading={loading} disabled decimalValue={decimalValue} />
            </div>
            :
            <Link to={to} className='clinic-btn' onClick={onClick}>
                <QuickBoxCard value={value} label={label} loading={loading} decimalValue={decimalValue} />
            </Link>
        : null
)

const QuickBoxCard = ({ value, label, disabled = false, loading, decimalValue }) => {
    const id = generatePassword();
    useEffect(() => {
        counterAnim(id, 0, value, 400)
    }, [ value ])
    return (
        <Card className={stringBuilder("position-relative text-center card-shadow mb-0", { 'card-hover': !disabled })}>
            <CardBody className='text-center'>
                {decimalValue ?
                    <div className='d-block mb-3'>
                        {loading &&
                            <Spinner className='d-inline-block' style={{ height: '25px', width: '25px' }} color="primary" type='grow' />
                        }
                        <span className={loading ? 'd-none' : 'd-block'}>
                            <span id={id} className={stringBuilder("mb-3 font-bold h3", { 'text-muted': disabled })}>{0}</span>
                            <span className='font-bold h3 px-1'>/</span>
                            <span className={stringBuilder("mb-3 font-bold h3")}>{decimalValue}</span>
                        </span>
                    </div>

                    :
                    <>
                        {loading &&
                            <Spinner className='mb-3' style={{ height: '25px', width: '25px' }} color="primary" type='grow' />
                        }
                        <div id={id} className={stringBuilder("mb-3 font-bold h3", { 'text-muted': disabled }, { 'd-none': loading })}>{0}</div>
                    </>
                }
                <div className={stringBuilder("font-medium", { 'text-muted': disabled })}>{label}</div>
            </CardBody>
        </Card>
    )
}

export default ClinicHeader;