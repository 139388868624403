import API_SPORDLE from "../API-Spordle";
import queryString from "query-string";
import { jsObjectToApi } from "@spordle/helpers";
import { serverError } from "../CancellableAPI";

/**
 * Creates a meta member for the given identity
 * @param {string} memberId Member ID to create a meta member for
 * @param {string} identityId Identity ID to create a meta member for
 * @returns {Promise<string>}
 */
export function getPendingMemberRequestChanges() {
    return API_SPORDLE.get(queryString.stringifyUrl({
        url: `/member-request-changes/approbations`,
    }))
        .then((response) => {
            if (response.data.status) {
                return response.data.member_request_changes.map((request) => ({
                    ...request,
                    data_to_change: request.data_to_change.map((field) => ({
                        ...field,
                        memberField: response.data.member_fields[field.code],
                    })),
                    member: response.data.members[request.member_id],
                    organisation: response.data.organisations[request.organisation_id],
                    created_by: response.data.identities[request.created_by],
                }))
            }
            throw response.data.errors[0];
        }, serverError)
}

/**
 * gets statuses for mandatory field change requests
 * @returns {Promise<string>}
 */
export function getMemberRequestChangesStatus() {
    return API_SPORDLE.get(queryString.stringifyUrl({
        url: `/member-request-changes-status`,
    }))
        .then((response) => {
            if (response.data.status) {
                return response.data.member_request_change_status;
            }
            throw response.data.errors[0];
        }, serverError)
}

export function updateMemberRequestChangeStatus(memberRequestChangeIdId, values) {
    const params = new URLSearchParams()
    jsObjectToApi(values, params)

    return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/member-request-changes/${memberRequestChangeIdId}` }), params)
        .then((response) => {
            if (response.data.status) {
                return true
            }
            throw response.data.errors[0];
        }, serverError)
}

/**
 * Create a member suspension attachment
 * @param {string} memberId
 * @param {Array} data_to_change
 * @see The {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Suspension%20Attachments/Apicontroller%5CMembers%5CMembersuspensionattachments%3A%3AcreateMemberSuspensionAttachments|documentation}
 * @returns {Promise.<Array>}
 */
export function createMemberRequestChange(memberId, data_to_change) {
    const params = new URLSearchParams();
    jsObjectToApi(data_to_change, params);
    return API_SPORDLE.post(queryString.stringifyUrl({ url: `/members/${memberId}/member-request-changes` }), params)
        .then((response) => {
            if (response.data.status) {
                return response.data.member_request_id;
            }
            throw response.data.errors[0];
        }, serverError)
}