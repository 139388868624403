import Translate from "@spordle/intl-elements";
import { Badge } from "reactstrap";

export const TASK_PREFIX = (process.env.REACT_APP_ENVIRONMENT == 'prod' || process.env.NEXT_PUBLIC_ENVIRONMENT == 'prod') ? 'HD-' : 'UAT-';

export const statusList = [
    {
        id: 2,
        name: "To Do",
    },
    {
        id: 4,
        name: "In Progress",
    },
    {
        id: 3,
        name: "Done",
    },
];

export const Status = ({ status }) => {
    const getProps = () => {
        let color;
        switch (status.id){
            case 3:
                color = "success";
                break;
            case 4:
                color = "warning";
                break;
            case 2:
                color = "info";
            default:
                break;
        }

        return { color: color ?? "light", children: color ? <Translate id={`supportRequests.profile.status.${status.id}`} /> : status.name };
    }

    return <Badge {...getProps()} />
}