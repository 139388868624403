import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';
import Translate from "@spordle/intl-elements";
import React from "react";
import {
    Button,
    Card
} from "reactstrap";
import { getPlatformLexicon } from '../../../../api/client/lexicon';
import CardSectionTitle from "../../../../components/CardSectionTitle";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import SpordlePanelTable from "../../../../components/sidePanel/SpordlePanel";
import { PeriodsContext } from '../../../../contexts/contexts';
import { I18nContext } from '../../../../contexts/I18nContext';
// Contexts
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import I18nHelperContextProvider, { I18nHelperContext } from '../../../../helpers/i18nHelper';
import withContexts from "../../../../helpers/withContexts";
import SidePanelPlatformLexicon from './SidePanelPlatformLexicon';
import PlatformLexiconAdd from './PlatformLexiconAdd';

class PlatformLexicon extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            addModalOpen: false,
        }
    }

    toggleAddModal = () => {
        this.setState((prevState) => ({ addModalOpen: !prevState.addModalOpen }));
    }

    render(){
        return (
            <I18nHelperContextProvider fields={[ 'term' ]}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <SpordlePanelTable
                            allowOutsideClick
                            sidePanel={(props) => <SidePanelPlatformLexicon {...props} />}
                            dataIndex='platform_lexicon_id'
                            table={(panelProps) => {
                                return (
                                    <SpordleTableProvider
                                        id='PlatformLexicon'
                                        tableHover clickable striped
                                        bordered
                                        ref={panelProps.spordleTableRef}
                                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                        dataIndex='platform_lexicon_id'
                                        searchKeys={[
                                            'code',
                                            `i18n.${this.props.I18nContext.getGenericLocale()}.term`,
                                            'term',
                                        ]}
                                        onColumnClick={(e, data) => {
                                            switch (e.button){
                                                case 0: // Left mouse button
                                                    panelProps.onSingleSelectChange(data);
                                                    break;
                                            }
                                        }}
                                        columns={[
                                            {
                                                label: "Code",
                                                translateLabel: false,
                                                key: "code",
                                                className: 'text-left',
                                                dataClassName: 'text-left',
                                                mobile: true,
                                                sortable: true,
                                            },
                                            ...i18nHelper.getTableColumns('term'),
                                        ]}
                                        desktopWhen='md'
                                        renderRow={(columnKey, period, spordleTable) => {
                                            switch (columnKey){
                                                case 'active':
                                                    return (
                                                        <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                            {period.active == '1' ?
                                                                <i className="text-primary mdi mdi-check" />
                                                                :
                                                                <i className="text-danger mdi mdi-close" />
                                                            }
                                                        </div>
                                                    )
                                                default:
                                                    break;
                                            }
                                        }}
                                        rowIsHighlighted={(period) => !!period.checked}
                                        loadData={(from, filterData, spordleTable) => {
                                            switch (from){
                                                case 'REFRESH':
                                                    spordleTable.setLoading();
                                                case 'CDM':
                                                    return getPlatformLexicon()
                                                        .then((lexicon) => {
                                                            this.props.I18nContext.setLexicon(lexicon);
                                                            return lexicon;
                                                        });
                                                default:
                                                    break;
                                            }
                                        }}
                                    >
                                        <Card body className="card-shadow">
                                            <CardSectionTitle title='settings.tabs.platformLexicon' />
                                            <TopSection modalAdd={this.state.addModalOpen} toggleAddModal={this.toggleAddModal} panelProps={panelProps} />
                                            <SpordleTableView />
                                        </Card>
                                    </SpordleTableProvider>
                                )
                            }}
                        />
                    )}
                </I18nHelperContext.Consumer>
            </I18nHelperContextProvider>
        )
    }
}

const TopSection = (props) => {
    return (
        <div className="mb-2">
            <div className='d-flex justify-content-between'>
                <SearchInput />
                <div className='d-flex ml-auto text-right'>
                    <Refresh />
                    <CanDoAction action='ADD' componentCode='organization' componentPermissionCode='platform_lexicon'>
                        <PlatformLexiconAdd isOpen={props.modalAdd} toggle={props.toggleAddModal} />
                        <Button color='primary' className='ml-2' onClick={props.toggleAddModal}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                    </CanDoAction>
                </div>
            </div>
        </div>
    )
}

export default withContexts(PeriodsContext, OrganizationContext, I18nContext)(PlatformLexicon);