export default{
    "settings.documentSettings.tab"   : "Documents",
    "settings.documentSettings.title" : "Paramètres des documents",

    "settings.documentSettings.member.registration.title" : "Documents d'inscriptions pour les membres",
    "settings.documentSettings.member.registration.desc"  : "Ces types de documents devront être fournis lors du processus d'inscription des membres.",

    "settings.documentSettings.clinic.registration.title" : "Documents d'inscriptions pour les cliniques",
    "settings.documentSettings.clinic.registration.desc"  : "Ces types de documents devront être fournis lors du processus d'inscription des cliniques.",

    "settings.documentSettings.insurance.title" : "Documents d'assurance",
    "settings.documentSettings.insurance.desc"  : "Ces documents peuvent être téléversés lors du processus de création d'une réclamation d'assurance.",

    "settings.documentSettings.travelPermit.title" : "Documents de permis de voyage ",
    "settings.documentSettings.travelPermit.desc"  : "Ces types de documents peuvent être fournis pour un permis de voyage.",

    "settings.documentSettings.documents.notDefined" : "Aucun document requis actuellement.",

    "settings.documentTypes.title"       : "Types de document",
    "settings.documentTypes.status"      : "Statut",
    "settings.documentTypes.empty"       : "Aucun type de document",
    "settings.documentTypes.add.title"   : "Ajouter un type de document",
    "settings.documentTypes.delete.text" : "Voulez-vous vraiment supprimer",

    "settings.documentExpirations.title"                : "Expirations des documents",
    "settings.documentExpirations.add.title"            : "Ajouter une expiration de document",
    "settings.documentExpirations.add.docType.required" : "Le type de document est requis. ",
    "settings.documentExpirations.add.months.required"  : "Mois jusqu'à l'expiration est un champ requis. ",
    "settings.documentExpirations.add.min.smaller"      : "L'âge minimum doit être plus petit que l'âge maximum.",
    "settings.documentExpirations.add.max.larger"       : "L'âge maximum doit être supérieur à l'âge minimum.",

    "settings.documentTypeExpiration.document_types.row"                     : "Type de document",
    "settings.documentTypeExpiration.delete_after_expiration.row"            : "Supprimer après l'expiration",
    "settings.documentTypeExpiration.organization.row"                       : "Organisation",
    "settings.documentTypeExpiration.expiration_duration.row"                : "Mois jusqu'à l'expiration",
    "settings.documentTypeExpiration.expiration_duration.row.months"         : "Mois",
    "settings.documentTypeExpiration.expiration_duration.row.month"          : "Mois",
    "settings.documentTypeExpiration.expiration_duration.validation.minimum" : "Le nombre minimum de mois jusqu'à l'expiration est de 1.",
    "settings.documentTypeExpiration.min_age.row"                            : "Âge minimum",
    "settings.documentTypeExpiration.max_age.row"                            : "Âge maximum",
    "settings.documentTypeExpiration.delete"                                 : "Êtes-vous sûr de vouloir supprimer la règle d'expiration du document ",


}