export default{
    "teams.teamSearch.addTeam.btn"              : "Ajouter équipe",
    "teams.teamSearch.addTeam.btn.rollover"     : "Roulement",
    "teams.teamSearch.addTeam.btn.newTeam"      : "Créer une équipe",
    "teams.teamSearch.addTeam.btn.batchCreate"  : "Créer plusieurs équipes",
    "teams.teamSearch.addTeam.btn.restrictions" : "Restrictions d'équipe",

    "teams.teamSearch.addTeam.search.season.required"       : "Veuillez sélectionner une saison.",
    "teams.teamSearch.addTeam.search.division.required"     : "Veuillez sélectionner au moins une division.",
    "teams.teamSearch.addTeam.search.data.warning"          : "Sélectionner multiples options peut entrainer un chargement plus long que d'habitude.",
    "teams.teamSearch.addTeam.search.organization.required" : "Veuillez sélectionner au moins une organisation.",
    "teams.teamSearch.addTeam.search.organization.max"      : "Veuillez sélectionner un maximum de 25 organisations.",

    "teams.teamSearch.addTeam.rollover.modal.title"       : "Importer une ou des équipe(s) des saisons précédentes",
    "teams.teamSearch.addTeam.rollover.selectedTeams"     : "Équipe(s) sélectionnée(s)",
    "teams.teamSearch.addTeam.rollover.suggestedCategory" : "Catégorie suggérée",
    "teams.teamSearch.addTeam.rollover.inactiveCategory"  : "Cette catégorie est inactive pour la période sélectionnée",
    "teams.teamSearch.addTeam.rollover.goToCategories"    : "Accéder aux catégories",
    "teams.teamSearch.addTeam.rollover.success"           : "{count, plural, one {L'équipe a bien été ajoutée.} other {Les équipes ont bien été ajoutées.}}",
    "teams.teamSearch.addTeam.rollover.success.btn"       : "Compris !",

    "teams.teamSearch.addTeam.title"        : "Création d'équipe",
    "teams.teamSearch.addTeam.wizard.step1" : "Info générale",
    "teams.teamSearch.addTeam.wizard.step2" : "Contact d'équipe",
    "teams.teamSearch.addTeam.wizard.step3" : "Sommaire",

    "teams.teamSearch.rollover.wizard.title"        : "Roulement des équipes",
    "teams.teamSearch.rollover.wizard.step1"        : "Sélection des équipes",
    "teams.teamSearch.rollover.wizard.step2"        : "Sommaire",
    "teams.teamSearch.rollover.step2.title"         : "Sommaire des équipes",
    "teams.teamSearch.rollover.step2.editAllMode"   : "Modifier toutes les équipes",
    "teams.teamSearch.rollover.step2.category"      : "Catégorie",
    "teams.teamSearch.rollover.step2.selectedTeams" : "Équipes sélectionnées",

    "teams.teamSearch.rollover.step2.review.almostDone" : "Vous pouvez réviser les équipes choisies et leur associer leur bonne catégorie d'équipe ci-dessous.",
    "teams.teamSearch.rollover.toast.success"           : "Équipe(s) importée(s) avec succès !",

    "teams.teamSearch.rollover.step2.form.categoryForAll.required" : "Veuillez choisir une catégorie pour les équipes.",
    "teams.teamSearch.rollover.step2.form.category.required"       : "Veuillez choisir une catégorie pour cette équipe.",
    "teams.teamSearch.rollover.step2.form.category.missing"        : "Veuillez vérifier votre liste, il semble qu'une ou des équipe(s) n'a(ient) pas de catégorie.",
}