export default{
    "catalog.forms.components.formEditor.questionType.questionTypeField.min.number"     : "Valeur minimale",
    "catalog.forms.components.formEditor.questionType.questionTypeField.min.number.tip" : "Valider la valeur de la réponse soit <underline>plus grand ou égale</underline> à la «valeur minimale»",
    "catalog.forms.components.formEditor.questionType.questionTypeField.min.date"       : "Date minimum",
    "catalog.forms.components.formEditor.questionType.questionTypeField.min.time"       : "Temps minimum",
    "catalog.forms.components.formEditor.questionType.questionTypeField.max.number"     : "Valeur maximale",
    "catalog.forms.components.formEditor.questionType.questionTypeField.max.number.tip" : "Valider la valeur de la réponse soit <underline>plus petit ou égale</underline> à la «valeur maximale»",
    "catalog.forms.components.formEditor.questionType.questionTypeField.max.date"       : "Date maximale",
    "catalog.forms.components.formEditor.questionType.questionTypeField.max.time"       : "Temps maximal",

    "catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.date"   : "Champ de date",
    "catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.time"   : "Champ de temps",
    "catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.number" : "Entrée numérique",
    "catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.short"  : "Réponse textuelle courte",
    "catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.long"   : "Réponse textuelle longue",
}