import { ScrollArea } from "@mantine/core"
import Translate from "@spordle/intl-elements"
import { useContext, useState } from "react"
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import AnalyticsModal from "../../../../analytics/AnalyticsModal"
import { AxiosIsCancelled } from "../../../../api/CancellableAPI"
import CrossFade from "../../../../components/crossFade/CrossFade"
import CustomAlert from "../../../../components/CustomAlert"
import CustomAnimatedIcon from "../../../../components/customAnimatedIcon/CustomAnimatedIcon"
import { useSteps } from "../../../../components/customHooks/useSteps"
import OverlayLoader from "../../../../components/loading/OverlayLoader"
import { InstallmentModesContext } from "../../../../contexts/InstallmentModesContext"
import { DisplayI18n } from "../../../../helpers/i18nHelper"

const InstallmentLinkedFees = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal analyticsName="InstallmentLinkedFees" isOpen={isOpen}>
            <InstallmentLinkedFeesInner {...props} />
        </AnalyticsModal>
    )
}

const InstallmentLinkedFeesInner = ({ linkedFees, toggle, installmentModeId, ...props }) => {

    const installmentModesContext = useContext(InstallmentModesContext)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ error, setError ] = useState(null);
    const [ activeStep, { next } ] = useSteps(2, 0)

    const unlinkAll = () => {
        setIsLoading(true);
        installmentModesContext.unlinkAllInstallmentLinks(installmentModeId)
            .then(() => {
                setIsLoading(false);
                next()
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                    setIsLoading(false);
                }
            })
    }

    const deactivate = () => {
        setIsLoading(true);
        const newValues = props.createNewValues({ active: 0 });
        installmentModesContext.updateInstallmentModePartial(installmentModeId, { active: 0 })
            .then(() => {
                props.syncRows(newValues);
                setIsLoading(false);
                toggle();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                    setIsLoading(false);
                }
            })
    }

    return (
        <>
            <ModalHeader toggle={toggle}>
                <Translate id='catalog.installment.linkedFees.title' />
            </ModalHeader>
            <OverlayLoader isLoading={isLoading}>
                <CrossFade isVisible={activeStep == 0}>
                    <ModalBody>
                        <p className="text-center font-bold text-dark">
                            <Translate id='catalog.installment.linkedFees.text' />
                        </p>
                        <ScrollArea style={{ maxHeight: '40vh' }} className='d-flex flex-column min-h-0' offsetScrollbars>
                            <ul>
                                {/* As of now we only manage the installments with registration_fees */}
                                {/* If we ever decide to add them to other_fees or clinics we will need to change this a bit */}
                                {linkedFees?.registration_fees?.map((registrationFee) => (
                                    <li key={registrationFee.registration_fee_id}>
                                        <DisplayI18n field='name' defaultValue={registrationFee.fee.name} i18n={registrationFee.fee.i18n} />
                                    </li>
                                ))}
                            </ul>
                        </ScrollArea>

                        <CrossFade isVisible={!!error} appear mountOnEnter unmountOnExit>
                            {error &&
                                <CustomAlert className='mt-3' color='danger' withTitle text={error} translateText={false} toggle={() => setError()} />
                            }
                        </CrossFade>
                    </ModalBody>
                    <ModalFooter>
                        <Button type='button' color='primary' onClick={unlinkAll}><Translate id='catalog.installment.linkedFees.submit' /></Button>
                        <Button type='button' color='primary' outline onClick={toggle}><Translate id='misc.cancel' /></Button>
                    </ModalFooter>
                </CrossFade>
                <CrossFade isVisible={activeStep == 1}>
                    <ModalBody className="text-center">
                        <CustomAnimatedIcon
                            icon='checkmark'
                            size={75}
                            withCircle
                            className='text-success mb-3'
                        />
                        <div className="h4 font-bold mb-2"><Translate id='catalog.installment.linkedFees.success' /></div>
                        <Translate id='catalog.installment.linkedFees.successText' />

                        <CrossFade isVisible={!!error} appear mountOnEnter unmountOnExit>
                            {error &&
                                <CustomAlert className='mt-3' color='danger' withTitle text={error} translateText={false} toggle={() => setError()} />
                            }
                        </CrossFade>
                    </ModalBody>
                    <ModalFooter>
                        <Button type='button' color='primary' onClick={deactivate}><Translate id='catalog.installment.linkedFees.deactivate' /></Button>
                        <Button type='button' color='primary' outline onClick={toggle}><Translate id='misc.cancel' /></Button>
                    </ModalFooter>
                </CrossFade>
            </OverlayLoader>
        </>
    )
}

export default InstallmentLinkedFees