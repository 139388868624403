import Translate from '@spordle/intl-elements';
import { useState } from 'react';
import SidePanel from '../../SidePanel';
import TransferRequestComment from '../TransferRequestComment';

const CommentAction = ({ transfer }) => {
    const [ modalIsOpen, setModalOpen ] = useState(false);
    const toggleModal = () => { setModalOpen(!modalIsOpen) };

    return (
        <SidePanel.MenuAction onClick={toggleModal} action='EDIT' componentCode='members' componentPermissionCode='members_request_transfer'>
            <Translate id='transfer.sidepanel.actions.addComment' />
            <TransferRequestComment isOpen={modalIsOpen} toggle={toggleModal} transfer={transfer} />
        </SidePanel.MenuAction>
    );
}

export default CommentAction;