export default{
    "organization.settings.registrationSettings.setting.title"       : "Registration Settings",
    "organization.settings.registrationSettings.setting.name"        : "Registration Confirmation Message",
    "organization.settings.registrationSettings.setting.desc"        : "This setting allows you to change the message displayed to parents at the end of the registration process.",
    "organization.settings.registrationSettings.setting.no.selected" : "No confirmation message set.",

    "organization.settings.registrationSettings.confirmation.title" : "Confirmation Message",
    "organization.settings.registrationSettings.demographic.title"  : "Data Collection Message",
    "organization.settings.registrationSettings.memberFields.title" : "Member Creation Fields",
    "organization.settings.registrationSettings.memberFields.desc"  : "This setting allows you to add explanations to each field in the member creation form.",

    "organization.settings.registrationSettings.memberFields.empty"     : "No Fields Available",
    "organization.settings.registrationSettings.memberFields.empty.txt" : "No member fields are available to add a description to.",

    "organization.settings.registrationSettings.clinic.setting.name" : "Clinic Confirmation Message",
    "organization.settings.registrationSettings.clinic.setting.desc" : "This setting allows you to change the message displayed at the end of the clinic registration process.",

    "organization.settings.registrationSettings.demographic.setting.delete" : "Clear Member Collected Data Message",
    "organization.settings.registrationSettings.demographic.setting.name"   : "Explanation for Member Collected Data",

    "organization.settings.registrationSettings.demographic.setting.desc" : "This setting allows you to configure the data collection policy message in the participant creation form.",
}