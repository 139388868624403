import { FormikInputText } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { useFormikContext } from 'formik';
import { Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from 'reactstrap';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { groupOptionsOpened } from '../CustomFormEditor';
import QuestionOptions from './QuestionOptions';
import QuestionTypePicker from './questionType/QuestionTypePicker';
import QuestionTypeRenderer from './questionType/QuestionTypeRenderer';
import VerticalOptions from './VerticalOptions';

/**
 * @description Renders the question body
 * @param {object} props
 */
const GroupQuestion = (props) => {
    const formik = useFormikContext();

    return (
        <Card className={stringBuilder('border mb-3 card-shadow')}>
            <CardHeader className="bg-white font-medium d-flex align-items-center">
                <i className="mr-1 mdi mdi-drag-vertical font-20 position-absolute left-0" style={{ top: "8px", cursor: "grab" }} />
                <div><Translate id='catalog.forms.components.formEditor.groupQuestion.countOf' values={{ count: props.questionIndex + 1, total: formik.values.sections[props.sectionIndex].questions.length }} /></div>
                <div className='ml-auto'>
                    <QuestionOptions hasIsAdmin hasRequired id={props.id} />
                </div>
            </CardHeader>
            <CardBody>
                <div className="d-flex">
                    <div className="leftSide flex-grow-1">
                        <Row>
                            <OrganizationContext.Consumer>
                                {({ hasFrench, hasEnglish }) => (
                                    <Col md={8} lg={8}>
                                        {hasEnglish() &&
                                                <FormGroup>
                                                    <FormikInputText name={`${props.id}.i18n.en.title`} trim label='catalog.forms.components.formEditor.groupQuestion.title.en' />
                                                </FormGroup>
                                        }
                                        {hasFrench() &&
                                                <FormGroup>
                                                    <FormikInputText name={`${props.id}.i18n.fr.title`} trim label='catalog.forms.components.formEditor.groupQuestion.title.fr' />
                                                </FormGroup>
                                        }
                                    </Col>
                                )}
                            </OrganizationContext.Consumer>
                            <Col md={4} lg={4}>
                                <Label><Translate id='catalog.forms.components.formEditor.groupQuestion.type' /></Label>
                                <QuestionTypePicker
                                    id={props.id}
                                    questionIndex={props.questionIndex}
                                    sectionIndex={props.sectionIndex}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                            <Row>
                                <Col md={8} lg={8}>
                                    <QuestionTypeRenderer
                                        questionType={props.question.formFieldCode}
                                        questionIndex={props.questionIndex}
                                        sectionIndex={props.sectionIndex}
                                        id={props.id}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </div>
                    <div className='rightSide' style={groupOptionsOpened}>
                        <VerticalOptions
                            addQuestion={props.addQuestion}
                            addSection={props.addSection}
                            clone={props.clone}
                            delete={props.delete}
                        />
                    </div>
                </div>
            </CardBody>
            {/* <Collapse isOpen={isActive}>
                    <CardFooter className="p-1 bg-white">
                        <FooterOptions hasVisible hasRequired id={props.id}/>
                    </CardFooter>
                </Collapse> */}
        </Card>
    );
}

export default GroupQuestion;