export default{
    "components.manualRegistration.waivers.title"              : "Dispenses",
    "components.manualRegistration.waivers.of"                 : "de",
    "components.manualRegistration.waivers.accept"             : "J'ai lu et j'accepte la dispense",
    "components.manualRegistration.waivers.unsign"             : "Retirer la signature",
    "components.manualRegistration.waivers.unsign.confirm"     : "Vous êtes sur le point de retirer la signature pour cette dispense",
    "components.manualRegistration.waivers.unsign.disabled"    : "Vous pouvez retirer la signature seulement si vous êtes le ou la signataire",
    "components.manualRegistration.waivers.myAccount"          : "Dispense de {spordle_account}",
    "components.manualRegistration.waivers.required"           : "Cette dispense est obligatoire",
    "components.manualRegistration.waivers.notMandatoryHelper" : "Cette dispense n'est pas obligatoire",
    "components.manualRegistration.waivers.skipAll"            : "Sauter toutes les dispenses",
    "components.manualRegistration.waivers.VERBAL"             : "Accord verbal",
    "components.manualRegistration.waivers.PHYSICAL"           : "Accord physique",
    "components.manualRegistration.waivers.ONLINE"             : "Accord en ligne",
}