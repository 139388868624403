import { stringBuilder } from "@spordle/helpers";
import { useContext } from "react";
import { SpordletablePanelContext } from "../SpordlePanel";

/**
 * Component used for the toggle btn of the SidePanel
 * @param {Object} props
 * @param {function} [props.onClick]
 * @returns {JSX.Element}
 */
const SidePanelToggleButton = ({ onClick, ...props }) => {
    const { toggleSidePanel } = useContext(SpordletablePanelContext);

    return (
        <button
            {...props}
            type='button'
            onClick={() => {
                toggleSidePanel();
                if(onClick) onClick();
            }}
            className={stringBuilder('mdi mdi-arrow-right font-20 reset-btn p-2 mr-auto text-dark', props.className)}
        />
    )
};

export default SidePanelToggleButton;