import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import Breadcrumbs from '../../layouts/layout-components/breadcrumbs/Breadcrumbs';
import { Fade } from 'reactstrap';
import { AppContext } from '../../contexts/contexts';
import { Tooltip } from "@mantine/core";
import { QuickViewContext } from '../../contexts/QuickViewContext';
import { isMobileDevice } from "../../helpers/constants";

const ViewHeaderV2 = ({ tag: Tag, className, children, title, skipTranslate, breadcrumbsProps = {}, ...attributes }) => {
    const [ visibleRefreshKey, showRefreshKey ] = useState(false);
    const quickViewContext = useContext(QuickViewContext);

    return quickViewContext.inIframe ? null : (
        <Tag {...attributes} className={stringBuilder('d-flex align-items-center w-100', className)}>
            <div className='px-4 pt-3 w-100'>
                {!isMobileDevice &&
                    <Breadcrumbs {...breadcrumbsProps} className='mb-2' withRefresh />
                }
                {title &&
                    <div className="mb-3 d-flex align-items-center" onMouseEnter={() => showRefreshKey(true)} onMouseLeave={() => showRefreshKey(false)}>
                        <div className="h3 mb-0 font-medium">{skipTranslate ? title : <Translate id={title} />}</div>
                        <Fade in={visibleRefreshKey}>
                            <RefreshButton buttonClassName='ml-1 font-20' />
                        </Fade>
                    </div>
                }
            </div>
        </Tag>
    );
};

const RefreshButton = ({ buttonClassName }) => {
    const { updateRouteKey } = useContext(AppContext);

    return (
        <Tooltip
            withArrow
            label={<Translate id='misc.refresh' />}
            zIndex={3000}
        >
            <button
                className={stringBuilder("reset-btn mdi mdi-refresh btn btn-link", buttonClassName)}
                type="button"
                onClick={updateRouteKey}
            />
        </Tooltip>
    )
}

export default ViewHeaderV2;
export{ RefreshButton };

ViewHeaderV2.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
};
ViewHeaderV2.defaultProps = {
    tag: 'header',
};
