// Language
import Translate from '@spordle/intl-elements';
import React from "react";
import Skeleton from 'react-loading-skeleton';
import {
    Badge,
    Button,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import ProfileHeader from "../../../components/profileHeader/ProfileHeader";
import ProfileHeaderImage from "../../../components/profileHeader/ProfileHeaderImage";
import TabRoutesNavs from '../../../components/subSidebar/TabRoutesNavs';
import TabRoutesPanes from '../../../components/subSidebar/TabRoutesPanes';
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";
// Contexts
import { I18nContext } from '../../../contexts/I18nContext';
import { AppContext, AuthContext } from '../../../contexts/contexts';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { RolesContext } from '../../../contexts/RolesContext';
import { copyToClipBoard } from "../../../helpers/clipboardHelper";
import getReferrer from "../../../helpers/getReferer";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import withContexts from '../../../helpers/withContexts';
import organizationTabsRoutes from '../../../routes/tabRoutes/organizationTabsRoutes';
import { fail } from "@spordle/toasts"
import MemberAlertsIcon from '../../../components/memberBadges/MemberAlertsIcon';
import HasAccessTo from '../../../components/permissions/HasAccessTo';
import { MerchantAccountContext } from '../../../contexts/MerchantAccountContext';
import { history } from '../../../helpers';
import { stringifyUrl } from 'query-string';
import { leaguesParentRoute, leaguesViewRoutes, LEAGUES_CATEGORY_NAME, tournamentsParentRoute, tournamentsViewRoutes, TOURNAMENTS_CATEGORY_NAME, venuesRoute, zonesParentRoute, zonesViewRoutes, ZONES_CATEGORY_NAME } from './grouping/groupingsConstants';
import MissingMandatoryRolesBadge from './components/MissingMandatoryRolesBadge';
import OrganizationConfirmModal from './components/OrganizationConfirmModal';
import { Tooltip } from '@mantine/core';
import { ReportsContext } from '../../../contexts/ReportsContext';
import { triggerDownload } from '../../../components/uploader/uploadHelpers';

class OrganizationProfile extends React.Component{
    state = {
        tags: null,

        missingMerchantAccountBadge: false,
        missingCRCSettings: false,

        confirm: false,
    }

    toggleConfirm = () => {
        this.setState((prevState) => ({ confirm: !prevState.confirm }))
    }

    fetchOrgTags = () => {
        return this.props.OrganizationContext.getOrganizationTags(undefined, { active: 1 })
            .then((tags) => {
                this.setState({ tags: tags })
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    this.setState({ tags: [] })
                    console.error(error.message);
                }
            })
    }

    // component code is 'addressComponentMemberProfile' to scroll to address and phones component
    // component code is 'profileComponentMemberPrimaryInfo' to scroll to general info
    // component code is 'criminalRecordCheckCard' to scroll to CRC in it's tab
    scrollToComponent = (componentCode) => {
        const card = document.getElementById(componentCode);
        if(card){
            window.scrollTo({
                behavior: 'smooth', // not supported on mac?
                top: card.getBoundingClientRect().top - 150, // 2 * header(70 px high) + 10px(give some edge)
            })
        }
    }

    getOrganization = () => {
        this.props.OrganizationContext.setCurrentOrg(this.props.OrganizationContext.organisation_id, { with_venues: 1 })
        if(this.props.RolesContext.canDoAction([ 'ADD', 'EDIT' ], 'organization', 'organization_tags') || this.props.IdentityRolesContext.isGod()){
            this.fetchOrgTags()
        }else{
            this.setState({ tags: [] })
        }
    }

    componentDidMount(){
        this.getOrganization()
    }

    componentDidUpdate(){
        if(Array.isArray(this.props.MerchantAccountContext.merchantAccounts) && this.props.MerchantAccountContext.merchantAccounts.length <= 0 && this.state.missingMerchantAccountBadge === false)
            this.setState(() => ({ missingMerchantAccountBadge: true }));
        else if((Array.isArray(this.props.MerchantAccountContext.merchantAccounts) && this.props.MerchantAccountContext.merchantAccounts.length > 0 && this.state.missingMerchantAccountBadge === true) || (this.props.MerchantAccountContext.merchantAccounts === false && this.state.missingMerchantAccountBadge === true))
            this.setState(() => ({ missingMerchantAccountBadge: false }));

        if(!!this.props.OrganizationContext.settings && (this.props.OrganizationContext.settings.vsc_enable?.value == '0' && this.props.OrganizationContext.settings.background_check_enable?.value == '0' && this.props.OrganizationContext.settings.declaration_enable?.value == '0') && this.state.missingCRCSettings === false)
            this.setState(() => ({ missingCRCSettings: true }));
        else if(!this.props.OrganizationContext.settings || (this.props.OrganizationContext.settings.vsc_enable?.value == '1' || this.props.OrganizationContext.settings.background_check_enable?.value == '1' || this.props.OrganizationContext.settings.declaration_enable?.value == '1') && this.state.missingCRCSettings === true)
            this.setState(() => ({ missingCRCSettings: false }));
    }

    getOrgProfileRoutes(){
        const routes = [ ...organizationTabsRoutes ]

        if(this.props.OrganizationContext.venues && Array.isArray(this.props.OrganizationContext.venues) && this.props.OrganizationContext.venues.length > 0)
            routes.push(venuesRoute)

        if(this.props.OrganizationContext.organisation_category.name === LEAGUES_CATEGORY_NAME){
            routes.pushArray(leaguesViewRoutes)
        }else if(this.props.OrganizationContext.organisation_category.name === ZONES_CATEGORY_NAME){
            routes.pushArray(zonesViewRoutes)
        }else if(this.props.OrganizationContext.organisation_category.name === TOURNAMENTS_CATEGORY_NAME){
            routes.pushArray(tournamentsViewRoutes)
        }else{
            routes.push(zonesParentRoute, leaguesParentRoute, tournamentsParentRoute)
        }
        return routes
    }

    getPageReferrer = (withAuth = false) => {
        return stringifyUrl({
            url: this.props.OrganizationContext.deploy_on == 'PAGE' ?
            `${getReferrer('PAGE-VERCEL-2')}/${this.props.OrganizationContext.short_name}`
                : this.props.OrganizationContext.deploy_on == 'VERCEL' ?
                `${getReferrer('PAGE-VERCEL')}/${this.props.OrganizationContext.short_name}`
                    :
                `${getReferrer('PAGE')}/page/${this.props.OrganizationContext.short_url}`,
            query: {
                accessToken: withAuth ? this.props.AuthContext.accessToken : undefined,
            },
        }, {
            skipEmptyString: true,
            skipNull: true,
        })
    }


    getBadgeColour = (status) => {
        switch (status){
            case 'PENDING':
            case 'DRAFT':
            case 'INACTIVE':
                return 'warning';
            case 'DECLINED':
                return 'danger';
            case 'APPROVED':
                return 'success';
            default:
                return 'danger';
        }
    }

    clubMemorandumExportPDF = () => {
        const reportType = 'organisation_approbation_pdf';
        // gets all report types
        this.props.ReportsContext.getReports({ identity_role_id: this.props.IdentityRolesContext.identity_role_id })
            .then(async(report_type) => {
                // grabbing the correct report type ID with the find
                const reportTypeId = report_type.find((type) => type.code === reportType).report_id;

                const data = await this.props.ReportsContext.createExportableReport({ // Total count limit at 10 for team books (default 300 for reports)
                    organisation_id: this.props.OrganizationContext.organisation_id,
                    total_count: 0,
                    language_code: this.props.I18nContext.getGenericLocale(),
                    report_id: reportTypeId,
                    requested_by: this.props.IdentityRolesContext.identity.identity_id,
                    request_parameter: JSON.stringify({ report_type: 'PDF' }),
                }, true).catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })

                if(data.download_link){
                    triggerDownload(data.download_link);
                }

            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    render(){
        const showOrgApprovalAction = (this.props.OrganizationContext.settings.enable_organisation_status_management?.value == 1 && this.props.OrganizationContext.organisation_status?.system !== 'PENDING' && this.props.OrganizationContext.organisation_status?.system !== 'APPROVED');
        return (
            <>
                {/*--------------------------------------------------------------------------------*/}
                {/* View Header                                                                  */}
                {/*--------------------------------------------------------------------------------*/}
                <ViewHeaderV2 />

                <div className="page-content container-fluid p-4">
                    <Container>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Profile Header                                                                  */}
                        {/*--------------------------------------------------------------------------------*/}
                        <div className='d-flex justify-content-end mb-3 float-md-right'>
                            {(this.props.OrganizationContext.settings.enable_organisation_status_management?.value == 1) && <OrganizationConfirmModal isOpen={this.state.confirm} toggle={this.toggleConfirm} getOrganization={this.getOrganization} />}
                            {showOrgApprovalAction &&
                                <HasAccessTo componentCode='organization' componentPermissionCode='organization_module'>
                                    <Button
                                        color='success'
                                        className='mr-2'
                                        onClick={() => {
                                            this.setState(() => ({ confirm: true }));
                                        }}
                                    >
                                        <Translate id='organisation.profile.actions.submitApproval' />
                                    </Button>
                                </HasAccessTo>
                            }


                            <Tooltip
                                withArrow
                                label={<Translate id='organisation.profile.actions.copy' />}
                            >
                                <Button
                                    outline color='primary' className='mr-2'
                                    onClick={() => {
                                        copyToClipBoard(this.getPageReferrer())
                                    }}
                                >
                                    <i className='mdi mdi-content-copy' />
                                </Button>
                            </Tooltip>
                            <Button
                                outline color='primary'
                                target='_blank' rel="noreferrer noopener"
                                tag='a'
                                href={this.getPageReferrer(true)}
                            >
                                <Translate id='organisation.profile.actions.goToPage' /> <i className='mdi mdi-launch' />
                            </Button>
                            {(this.props.OrganizationContext.settings.enable_organisation_status_management?.value == 1 && this.props.OrganizationContext.settings.insurance_certificate?.value) &&
                                <UncontrolledDropdown className='ml-2'>
                                    <DropdownToggle
                                        caret color="primary" outline
                                        id={'organization-profile-header-action-btn'}
                                    >
                                        <Translate id="misc.actions" />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {this.props.OrganizationContext.settings.enable_organisation_status_management?.value == 1 &&
                                            <DropdownItem onClick={this.clubMemorandumExportPDF} id="organization-profile-memorandum-export">
                                                <i className='fas fa-file-pdf mr-1' /><Translate id="organisation.profile.action.print.memorandum" />
                                            </DropdownItem>
                                        }
                                        {this.props.OrganizationContext.settings.insurance_certificate?.value &&
                                            <DropdownItem
                                                href={this.props.OrganizationContext.settings.insurance_certificate?.value}
                                                target={'_blank'}
                                                id="organization-profile-insurance-document-link"
                                            >
                                                <i className='mdi mdi-launch mr-1' /><Translate id="organisation.profile.action.print.insurace" />
                                            </DropdownItem>
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }
                        </div>
                        <ProfileHeader>
                            <ProfileHeaderImage
                                hint={<Translate id='organisation.profile.header.image.upload.hint' />}
                                file={this.props.OrganizationContext.logo}
                                canEdit={this.props.RolesContext.canDoAction([ 'EDIT' ], 'organization', 'organization_profile')}
                                onSave={(logo, filePos, dataUrl) => (
                                    this.props.OrganizationContext.createOrganisationLogo(
                                        this.props.OrganizationContext.organisation_id,
                                        {
                                            logo: logo,
                                            file_position: filePos,
                                        },
                                        dataUrl,
                                    )
                                        .then(async(org) => {
                                            await this.props.AppContext.updateRecentOrg(this.props.OrganizationContext.organisation_id, (data) => ({
                                                ...data,
                                                organisation: {
                                                    ...data.organisation,
                                                    logo: org.logo,
                                                },
                                            }))
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                )}
                            />
                            <div className="profileHeader-info-container">
                                <div className="h3 font-medium font-bold">
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={this.props.OrganizationContext.organisation_name}
                                        i18n={this.props.OrganizationContext.i18n}
                                    />
                                </div>
                                <div className='d-flex font-medium'>
                                    <div className='d-inline-block '>
                                        <div className='text-muted'><Translate id='components.createOrganizationModal.form.orgCategory.label' /></div>
                                        <div>
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={this.props.OrganizationContext.organisation_category?.name}
                                                i18n={this.props.OrganizationContext.organisation_category?.i18n}
                                            />
                                        </div>
                                    </div>
                                    {this.props.OrganizationContext.settings.enable_organisation_status_management?.value == 1 &&
                                        <div className='d-inline-block ml-3'>
                                            <div className='text-muted'><Translate id='misc.status' /></div>
                                            <Badge
                                                color={this.getBadgeColour(this.props.OrganizationContext.organisation_status?.system || 'DRAFT')}
                                            >
                                                {this.props.OrganizationContext.organisation_status ?
                                                    <DisplayI18n
                                                        field='name'
                                                        defaultValue={this.props.OrganizationContext.organisation_status.name}
                                                        i18n={this.props.OrganizationContext.organisation_status.i18n}
                                                    />
                                                    :
                                                    <Translate id="reports.column.status.DRAFT" />
                                                }

                                            </Badge>
                                        </div>
                                    }
                                </div>
                                {(this.props.OrganizationContext.last_organisation_status_note && this.props.OrganizationContext.organisation_status?.system === 'DECLINED') &&
                                    <div className='font-medium mt-2'>
                                        <div className='text-muted'><Translate id='organisation.profile.header.note' /></div>
                                        <div>{this.props.OrganizationContext.last_organisation_status_note}</div>
                                    </div>
                                }
                                <div className='mt-2'>
                                    {Array.isArray(this.state.tags) ? this.state.tags.map(({ tag, organisation_tag_id }) => (
                                        <Badge key={organisation_tag_id} className='mr-1'>
                                            {tag.name}
                                        </Badge>
                                    )) : <Skeleton />}
                                </div>
                            </div>
                        </ProfileHeader>


                        <div className='d-flex'>

                            {this.state.missingMerchantAccountBadge &&
                                <HasAccessTo componentCode='settings' componentPermissionCode='settings_merchant_account'>
                                    <div className='d-flex'>
                                        <div className={`text-center text-md-left mb-4 mr-2`}>
                                            <div
                                                className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                                                onClick={() => {
                                                    history.push(`/organization/overview`)
                                                    setTimeout(() => { this.scrollToComponent('profileComponentMerchantAccounts') }, 0)
                                                }}
                                            >
                                                <i className='mdi mdi-credit-card-plus text-danger' />
                                                <span className="align-middle ml-2"><Translate id='organisation.profile.badge.missingMerchantAccount' /></span>
                                            </div>
                                        </div>
                                    </div>
                                </HasAccessTo>
                            }

                            <MissingMandatoryRolesBadge />

                            {this.state.missingCRCSettings &&
                                <HasAccessTo componentCode='settings' componentPermissionCode='organization_background_checks'>
                                    <div className='d-flex'>
                                        <div className={`text-center text-md-left mb-4 mr-2`}>
                                            <div
                                                className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                                                onClick={() => {
                                                    history.push(`/organization/overview`)
                                                    setTimeout(() => { this.scrollToComponent('profileComponentCRCSettings') }, 0)
                                                }}
                                            >
                                                <MemberAlertsIcon icon="crc" />
                                                <span className="align-middle ml-2"><Translate id='organisation.profile.badge.missingCRCSettings' /></span>
                                            </div>
                                        </div>
                                    </div>
                                </HasAccessTo>
                            }

                            {showOrgApprovalAction &&
                                <HasAccessTo
                                    tag='div'
                                    componentCode='organization'
                                    componentPermissionCode='organization_module'
                                    className="d-flex text-center text-md-left mb-4 mr-2 btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                                    onClick={() => {
                                        this.setState(() => ({ confirm: true }));
                                    }}
                                >
                                    <MemberAlertsIcon icon="unconfirmed" />
                                    <span className="align-middle ml-2"><Translate id='organisation.profile.badge.unsanctioned' /></span>

                                </HasAccessTo>
                            }
                        </div>

                        {/*--------------------------------------------------------------------------------*/}
                        {/* Nav Tabs                                                                  */}
                        {/*--------------------------------------------------------------------------------*/}

                        <div id="org-profile-nav-tabs">
                            <TabRoutesNavs routes={this.getOrgProfileRoutes()} />
                        </div>
                        <TabRoutesPanes
                            routes={this.getOrgProfileRoutes()}
                            getComponentProps={(route) => {
                                switch (route.path){
                                    case '/overview':
                                        return { isLoadingTags: !Array.isArray(this.state.tags), tags: this.state.tags, fetchOrgTags: this.fetchOrgTags };
                                    default:
                                        break;
                                }
                            }}
                        />
                    </Container>
                </div>
            </>
        );
    }
}

export default withContexts(I18nContext, OrganizationContext, AppContext, RolesContext, IdentityRolesContext, MerchantAccountContext, AuthContext, ReportsContext)(OrganizationProfile);
