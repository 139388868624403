import { addBreadcrumb, Severity } from "@sentry/react";
import { DisplayPhoneNumber } from "@spordle/formik-elements";
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import ToPrint from '@spordle/print';
import queryString from 'query-string';
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { Button, Card, CardBody, Col, Collapse, Container, Fade, Row, Spinner, Table } from "reactstrap";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import SpordleLogo from '../../assets/images/spordleSuite/GraySpordle.png';
import { PeriodsContext } from "../../contexts/contexts";
import { OnlineStoreContext } from "../../contexts/OnlineStoreContext";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { UtilsContext } from "../../contexts/UtilsContext";
import { DisplayI18n } from "../../helpers/i18nHelper";
import { fail } from "@spordle/toasts";
import { WatermarkWrapper } from "../Watermark";
import InvoiceEditModalToggleBtn from "./editModal/InvoiceEditModalToggleBtn";
import InvoiceItemsClinic from "./InvoiceItemsClinic";
import InvoiceItemsOther from "./InvoiceItemsOther";
import InvoiceItemsRegistration from "./InvoiceItemsRegistration";
import { groupByAsArray } from "@spordle/helpers";
import UserImg from "../UserImg";

const Invoice = ({ ...props }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ invoice, setInvoice ] = useState(false);
    const [ org, setOrg ] = useState(false);

    const utilsContext = useContext(UtilsContext);
    const storeContext = useContext(OnlineStoreContext);
    const periodContext = useContext(PeriodsContext);
    const orgContext = useContext(OrganizationContext);

    const [ isVoid, setIsVoid ] = useState(false);

    // print
    const printContainer = useRef(null);

    const location = useLocation();
    const invoiceNb = queryString.parse(location.search).invoiceNumber;

    const formatInvoice = (inv) => ({
        ...inv,
        affiliationFees: inv.invoice_items.filter((item) => item.affiliation_fee !== null),
        creditAmount: calcAmount(inv.credits, 'amount'),
        rebateAmount: calcAmount(inv.invoice_items, 'rebate_amount'),
        rebateData: inv.invoice_items.find((item) => item.rebate !== null)?.rebate,
        members: groupByAsArray(inv.invoice_items.map((item) => ({ ...item, memberId: item.member.member_id })), 'memberId').map((group) => (
            group.values.reduce(({ values, counts }, item) => {
                if(item.other_fee || item.clinic_fee){
                    if(item.other_fee && !counts[item.other_fee?.other_fee_id]){
                        counts[item.other_fee?.other_fee_id] = 1
                        values.push(item)
                    }else{
                        counts[item.other_fee?.other_fee_id] += 1
                    }
                    if(item.clinic_fee && !counts[item.clinic_fee?.clinic_fee_id]){
                        counts[item.clinic_fee?.clinic_fee_id] = 1
                        values.push(item)
                    }else{
                        counts[item.clinic_fee?.clinic_fee_id] += 1
                    }
                }else{
                    values.push(item)
                }
                return { values, counts }
            }, { values: [], counts: {} })
        )),
    });

    /**
     * Returns the sum of a field in an array
     * @param {array} arr
     * @param {string} field
     * @returns {number}
     */
    const calcAmount = (arr, field) => (
        arr.reduce((sum, item) => sum += parseInt(item[field]), 0)
    );

    useEffect(() => {
        if(invoiceNb){
            getInvoice()
        }
    }, [ invoiceNb ]);

    const getInvoice = () => {
        setIsLoading(true);
        return storeContext.getInvoice(invoiceNb, {
            organisation_id: orgContext.organisation_id,
            period_id: periodContext.selectedPeriod.period_id,
        })
            .then(async(invoice) => {
                if(invoice){
                    setInvoice(formatInvoice(invoice));
                    setIsVoid(invoice.status === 'VOID');

                    const promises = await Promise.all([
                        //orgContext.getOrganization(invoice.invoice_items.find(item => item.registration_fee != null || item.clinic != null || item.other_fee != null)?.organisation?.organisation_id),
                        invoice.country_id
                            ?
                            utilsContext.getProvinces(invoice.country_id)
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        });
                                    }
                                })
                            :
                            Promise.resolve(),
                        utilsContext.getPaymentMethods().catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        }),
                    ]);

                    setOrg(orgContext.state);

                    setInvoice((prev) => ({
                        ...prev,
                        ...(invoice.country_id && { country: promises[0]?.[0]?.code }),
                        paymentMethod: promises[1].find((m) => m.code === invoice.invoice_payment_method?.code),
                    }));

                }else{
                    setInvoice('none');
                }
                setIsLoading(false);
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e.message);
                    setInvoice('none');
                    setIsLoading(false);
                }
            })
    }

    const hasTaxNumbers = () => {
        return invoice.tax_number_info && invoice.tax_number_info.some((info) => !!info.tax_number)
    }

    return (
        <div className="page-content" ref={printContainer}>
            <WatermarkWrapper
                showWatermark={isVoid}
                watermarkProps={{
                    text: 'members.profile.transactions.memberProfileTransactions.sidePanel.status.VOID',
                    rotation: 20,
                }}
            >
                {/* start invoice */}
                <Container>
                    <Card className="card-shadow">
                        <CardBody>
                            {!isLoading ?
                                <Collapse isOpen={!isLoading}>
                                    { invoice && invoice !== "none" &&
                                            <Fade>
                                                <div className="clearfix">
                                                    {org.logo && org.abbreviation !== "HC" &&
                                                        <UserImg
                                                            className="float-left shadow-none"
                                                            width={60}
                                                            abbr={org.abbreviation}
                                                            src={org.logo?.full_path}
                                                            filePos={org.logo?.file_position}
                                                            alt={org.organisation_name + ' logo'}
                                                        />
                                                    }
                                                    <ToPrint
                                                        contentRef={printContainer.current}
                                                        documentTitle={invoice.invoice_number}
                                                        onBeforeGetContent={() => {
                                                            addBreadcrumb({ message: 'Before invoice print', level: Severity.Info, category: 'info', type: 'info' })
                                                        }}
                                                    >
                                                        {(handlePrint) => (
                                                            <Button onClick={handlePrint} color="primary" className='ml-2 float-right d-print-none'><Translate id='misc.print' /></Button>
                                                        )}
                                                    </ToPrint>
                                                </div>
                                                <div className="border-top border-bottom py-3 my-3">
                                                    <div className='clearfix'>
                                                        <div className='float-left'>
                                                            <span className="font-bold text-dark"><Translate id='invoice.date' />: </span>
                                                            <span>
                                                                <DateFormat value={invoice.invoice_date} utc />
                                                            </span>
                                                        </div>
                                                        <div className='invoice-info-right'>
                                                            <span className="font-bold text-dark"><Translate id='invoice.invoiceNo' />: </span>
                                                            <span>{invoice.invoice_number}</span>
                                                            {invoice.paymentMethod &&
                                                                <div>
                                                                    <span className="font-bold text-dark"><Translate id='invoice.paymentMethod' />: </span>
                                                                    <DisplayI18n field="name" i18n={invoice.paymentMethod.i18n} defaultValue={invoice.paymentMethod.name} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='float-left mb-3'>
                                                    <div className="mb-2">
                                                        <span className='font-bold text-dark'>
                                                            <Translate id='invoice.payedBy' />
                                                        </span>
                                                        {!isVoid &&
                                                            <InvoiceEditModalToggleBtn
                                                                invoice={invoice}
                                                                getInvoice={getInvoice}
                                                            />
                                                        }
                                                    </div>
                                                    {invoice.identity ?
                                                        <div>{invoice.identity.name} {invoice.identity.family_name}</div>
                                                        :
                                                        invoice.invoice_items[0]?.member && <div>{invoice.invoice_items[0]?.member.first_name} {invoice.invoice_items[0]?.member.last_name}</div>
                                                    }
                                                    <div>
                                                        {invoice.street_number && <span>{invoice.street_number}, </span>}
                                                        {invoice.street && <span>{invoice.street}</span>}
                                                    </div>
                                                    {invoice.unit_number && <div><span>{invoice.unit_number}</span></div>}
                                                    <div>
                                                        {invoice.city && <span>{invoice.city}, </span>}
                                                        {invoice.province_id && <span>{invoice.province_id}, </span>}
                                                        {invoice.country && <span>{invoice.country}</span>}
                                                    </div>
                                                    {invoice.zip_code && <div>{invoice.zip_code}</div>}
                                                    <DisplayPhoneNumber phoneString={invoice.phone_number} format='INTERNATIONAL' />
                                                    {invoice.invoice_items[0]?.member && <div><a href={`mailto:${invoice.invoice_items[0]?.member.email}`}>{invoice.invoice_items[0]?.member.email}</a></div>}
                                                </div>
                                                <div className='invoice-info-right mb-3'>
                                                    <div className="font-bold text-dark mb-2"><Translate id='invoice.organization' /></div>
                                                    <div>
                                                        <DisplayI18n field="name" i18n={org.i18n} defaultValue={org.organisation_name} />
                                                    </div>
                                                    {org.street && <div>{org.street_number} {org.street}</div>}
                                                    {org.unit_number && <div><span>{org.unit_number}</span></div>}
                                                    {org.city && <div>{org.city}, {org.province_code}, {org.country_code} </div>}
                                                    {org.zip_code && <div>{org.zip_code}</div>}
                                                    {org.organisation_email && <div><a href={`mailto:${org.organisation_email}`}>{org.organisation_email}</a></div>}

                                                    <DisplayPhoneNumber phoneString={org.phone} format='INTERNATIONAL' emptyValue='-' />
                                                </div>

                                                <Table size="sm" className="mb-5">
                                                    <thead>
                                                        <tr>
                                                            <th className="font-bold w-100"><Translate id='invoice.table.item' /></th>
                                                            <th className="font-bold text-right"><Translate id='invoice.table.price' /></th>
                                                            <th />
                                                            <th className="font-bold "><Translate id='invoice.table.qty' /></th>
                                                            <th className="font-bold text-right"><Translate id='invoice.table.total' /></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {invoice.members?.map((member) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td colSpan={5} className="w-100 font-bold">
                                                                            {member.values[0].member.first_name} {member.values[0].member.last_name} #{member.values[0].member.unique_identifier}
                                                                        </td>
                                                                    </tr>
                                                                    {invoice.type === 'AFFILIATION' ?
                                                                        <tr>
                                                                            <td className="border-top-0 pt-0 ">
                                                                                <span className="font-medium"><Translate id='invoice.table.affiliation' /></span>
                                                                                <small className="d-block">
                                                                                    <span className="font-medium"><Translate id='invoice.table.season' /></span>: <DisplayI18n field='name' defaultValue={invoice.period.name} i18n={invoice.period.i18n} />
                                                                                </small>
                                                                            </td>
                                                                            <td className="border-top-0 pt-0 text-right">
                                                                                <CurrencyFormat value={isVoid ? 0 : invoice.sub_total_amount / 100} />
                                                                            </td>
                                                                            <td className="border-top-0 pt-0">X</td>
                                                                            <td className="border-top-0 pt-0">1</td>
                                                                            <td className="border-top-0 pt-0 text-right">
                                                                                <CurrencyFormat value={isVoid ? 0 : invoice.sub_total_amount / 100} />
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        member.values.map((item) => {
                                                                            // we display the other fees and the clinic fees differently because an invoice can contain only other fees, but cannot contain only clinic fees
                                                                            if(item.registration_fee){
                                                                                return <InvoiceItemsRegistration key={item.invoice_item_id} invoice={invoice} item={item} isVoid={isVoid} />
                                                                            }else if(item.other_fee){
                                                                                return <InvoiceItemsOther key={item.invoice_item_id} item={item} number={member.counts[item.other_fee.other_fee_id]} isVoid={isVoid} />
                                                                            }else if(item.clinic || item.clinic_fee){
                                                                                return <InvoiceItemsClinic key={item.invoice_item_id} invoice={invoice} number={member.counts[item.clinic_fee?.clinic_fee_id]} item={item} isVoid={isVoid} />
                                                                            }
                                                                            return null
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                        })}
                                                        { invoice.rebateAmount > 0 &&
                                                            <>
                                                                <tr>
                                                                    <td colSpan={5} className="font-bold">
                                                                        <Translate id='invoice.table.discount' />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="border-top-0 pt-0"><DisplayI18n field="title" i18n={invoice.rebateData?.i18n} defaultValue={invoice.rebateData?.title} /></td>
                                                                    <td className="border-top-0 pt-0 text-right">-<CurrencyFormat value={invoice.rebateAmount / 100} /></td>
                                                                    <td className="border-top-0 pt-0">X</td>
                                                                    <td className="border-top-0 pt-0">1</td>
                                                                    <td className="border-top-0 pt-0 text-right">-<CurrencyFormat value={isVoid ? 0 : invoice.rebateAmount / 100} /></td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td className="text-right" colSpan={4}>
                                                                <span className="font-bold"><Translate id='invoice.table.subTotal' />:</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <CurrencyFormat value={isVoid ? 0 : invoice.sub_total_amount / 100} />
                                                            </td>
                                                        </tr>
                                                        {/* foreach tax */}
                                                        <tr>
                                                            <td className="text-right" colSpan="4">
                                                                <Translate id='invoice.table.tax' values={{ count: invoice.tax_number_info?.length || 1 }} />
                                                                {invoice.tax_number_info &&
                                                                    <span className="ml-1">
                                                                        ({invoice.tax_number_info?.map((info, index) => (
                                                                            <>
                                                                                <DisplayI18n key={info.name} field='name' defaultValue={info.name} i18n={info.i18n} />
                                                                                {index + 1 !== invoice.tax_number_info.length &&
                                                                                    <span className="mr-1">,</span>
                                                                                }
                                                                            </>
                                                                        ))})
                                                                    </span>
                                                                }
                                                                :
                                                            </td>
                                                            <td className="text-right">
                                                                <CurrencyFormat value={isVoid ? 0 : invoice.total_tax_amount / 100 || 0} />
                                                            </td>
                                                        </tr>
                                                        {/* end foreach tax */}
                                                        { invoice.creditAmount > 0 &&
                                                            <tr className="border-top">
                                                                <td colSpan="4" className="border-top-0 text-right">
                                                                    <Translate id='invoice.table.credits' />:
                                                                </td>
                                                                <td className="border-top-0 text-right text-success">
                                                                    -<CurrencyFormat value={isVoid ? 0 : invoice.creditAmount / 100} />
                                                                </td>
                                                            </tr>
                                                        }
                                                        { invoice.total_fee_paid_by_client > 0 &&
                                                            <tr className="border-top">
                                                                <td colSpan="4" className="border-top-0 text-right">
                                                                    <Translate id='invoice.table.fees' />:
                                                                </td>
                                                                <td className="border-top-0 text-right">
                                                                    <CurrencyFormat value={isVoid ? 0 : invoice.total_fee_paid_by_client / 100} />
                                                                </td>
                                                            </tr>
                                                        }
                                                        <tr className="border-top">
                                                            <td colSpan="2">
                                                                {invoice.tax_number_info && hasTaxNumbers() &&
                                                                    <div>
                                                                        <span className="font-bold text-dark"><Translate id='invoice.taxNumber' values={{ count: invoice.tax_number_info.length }} /></span>
                                                                        {invoice.tax_number_info?.map((info) => (
                                                                            <div key={info.tax_number_id} className="small">
                                                                                <span className="font-bold text-dark mr-1"><DisplayI18n key={info.name} field='name' defaultValue={info.name} i18n={info.i18n} />:</span>
                                                                                {info.tax_number}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td className="text-right font-bold" colSpan="2">
                                                                <Translate id='invoice.table.total' />:
                                                            </td>
                                                            <td className="text-right">
                                                                <CurrencyFormat value={isVoid ? 0 : (parseInt(invoice.total_amount) - parseInt(invoice.creditAmount)) / 100} />
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </Fade>
                                    }
                                    { invoice && invoice === "none" &&
                                            <div>
                                                <div className="font-bold h4 mb-2"><Translate id='invoice.error.title' /></div>
                                                <p><Translate id='invoice.error.message' /></p>
                                            </div>
                                    }
                                    <Row className="align-items-center">
                                        <Col sm="6" className="mb-2">
                                            <span className="small"><Translate id='invoice.table.poweredBy' values={{ year: new Date().getFullYear() }} /></span>
                                        </Col>
                                        <Col sm="6" className="mb-2 text-right">
                                            <img src={SpordleLogo} alt="Spordle" />
                                        </Col>
                                    </Row>
                                </Collapse>
                                :
                                <div className="text-center py-4"><Spinner type="grow" color="primary" /></div>
                            }
                        </CardBody>
                    </Card>
                </Container>
                {/* end invoice */}
            </WatermarkWrapper>
        </div>
    )
}

export default Invoice;