import { stringBuilder } from "@spordle/helpers";
import { fire, success } from "@spordle/toasts";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/contexts";
import { useMountedState } from "../../helpers/mountedState";
import { findRouteExactMatch } from "../../helpers/navigationHelper";
import { getBreadcrumbs } from "../../layouts/layout-components/breadcrumbs/Breadcrumbs";
import CheckboxStar from "../checkboxStar/CheckboxStar";

const FavoriteToggler = ({
    breadcrumbs,
    isFavorite,
    favoriteData = {},
    to,
    toggler: Toggler,
    className,
}) => {
    const isMounted = useMountedState();
    const [ isAdding, setIsAdding ] = useState(false);
    const [ foundMatch, setFoundMatch ] = useState(null);
    const { toggleRecentInFavorites, getFavoriteLinks } = useContext(AppContext);
    const isCurrentlyFav = isFavorite || getFavoriteLinks().some((fav) => fav.path === to);
    const location = { pathname: to }; // Simulate the location for the sidebar favorite links. The favorite toggle in the breadcrumbs will send the complete breadcrumb

    const buildBreadcrumbsI18n = (crumbs = []) => {
        return crumbs.map((crumb) => ({ name: crumb.name, skipTranslate: crumb.skipTranslate }));
    }

    const handleOnFavorite = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsAdding(true);
        const theLabel = favoriteData.label || foundMatch.name;

        if(foundMatch && (theLabel || isFavorite)){
            if(isCurrentlyFav){
                fire({
                    msg: "misc.unfavorited",
                    info: "misc.removedFromFavorites",
                });
            }else{
                success({
                    msg: "misc.favorited",
                    info: "misc.addedToFavorites",
                });
            }

            // The favorite toggle in the breadcrumbs will send the complete breadcrumb
            // The favorite toggle in the sidebar need to detect according to the path
            const theBreadcrumbs = buildBreadcrumbsI18n(breadcrumbs || getBreadcrumbs(location));

            toggleRecentInFavorites({
                label: theLabel || "",
                translate: favoriteData.translate !== undefined ? favoriteData.translate : !foundMatch.skipTranslate,
                breadcrumbs: theBreadcrumbs?.length === 1 && theBreadcrumbs[0].path === foundMatch.match.url ? [] : theBreadcrumbs,
                path: foundMatch.match.url,
                ...favoriteData,
            })
                .then(() => {
                    if(isMounted){
                        setIsAdding(false);
                    }
                });
        }
    }

    useEffect(() => {
        findRouteExactMatch(to, (theMatch) => !!theMatch.path && !theMatch.redirect)
            .then(setFoundMatch);
    }, [ to ]);

    if(!foundMatch){
        return null;
    }

    return (
        Toggler ?
            <Toggler
                onClick={handleOnFavorite}
                disabled={isAdding}
                isFav={isCurrentlyFav}
                match={foundMatch}
            />
            :
            <div className={stringBuilder(className, { "is-favorite": isCurrentlyFav })}>
                <CheckboxStar
                    disabled={isAdding}
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleOnFavorite}
                    checked={isCurrentlyFav}
                    id={to + 'recent' + isCurrentlyFav}
                    size="sm"
                />
            </div>
    );
}

export default FavoriteToggler;