import Translate, { DateFormat } from "@spordle/intl-elements";
import { isNullish } from "../helpers/helper";

const DisplayBirthdateAge = ({ birthdate, age, defaultValue = "", splitter = <span>&nbsp;&bull;&nbsp;</span>, ...props }) => {
    return (
        (birthdate || age) ?
            <div {...props}>
                {birthdate && <DateFormat value={birthdate ?? ''} utc />}
                {(birthdate && !isNullish(age)) && splitter}
                {!isNullish(age) && <Translate id='misc.yearsOld' values={{ age: age }} />}
            </div>
            : defaultValue
    )
}
export default DisplayBirthdateAge;