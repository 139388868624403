import Translate from '@spordle/intl-elements';
import { Badge } from 'reactstrap';
import { transferStatuses } from './constants'
import { DisplayI18n } from './i18nHelper';

export function getTransferStatusBadge(transferStatus, previousActionDone){
    // waiting for a fix from the API, they couldn't do an easy fix for this because they would need to run a DB script
    // and its friday 4pm so let's do this for now and we'll fix it later
    if(previousActionDone === 'DECLINE' && transferStatus.transfer_status_id === transferStatuses.closed){
        return <Badge color='danger'><Translate id='transfer.table.header.status.REJECTED' /></Badge>
    }

    switch (transferStatus.transfer_status_id){
        case transferStatuses.closed:
        case transferStatuses.approvedOverride:
            return <Badge color='success'><DisplayI18n field='name' defaultValue={transferStatus.name} i18n={transferStatus.i18n} /></Badge>

        case transferStatuses.open:
        case transferStatuses.pending:
        case transferStatuses.pendingHCReview:
        case transferStatuses.pendingHCApproval:
        case transferStatuses.pendingUSAApproval:
        case transferStatuses.pendingHCBranchReview:
            return <Badge color='warning'><DisplayI18n field='name' defaultValue={transferStatus.name} i18n={transferStatus.i18n} /></Badge>

        case transferStatuses.rejected:
        case transferStatuses.rejectedOverride:
        case transferStatuses.expired:
            return <Badge color='danger'><DisplayI18n field='name' defaultValue={transferStatus.name} i18n={transferStatus.i18n} /></Badge>
        default:
            return null;
    }
}

export function getActualStatus(transfer){
    switch (transfer.transfer_status.transfer_status_id){
        case transferStatuses.closed:
            if(transfer.previous_action_done === 'DECLINE'){
                return 'REFUSED'
            }
            return 'COMPLETED'

        case transferStatuses.open:
        case transferStatuses.pending:
        case transferStatuses.pendingHCReview:
        case transferStatuses.pendingHCApproval:
        case transferStatuses.pendingUSAApproval:
        case transferStatuses.pendingHCBranchReview:
            return 'OPEN'

        case transferStatuses.rejected:
        case transferStatuses.expired:
            return 'REFUSED'
        default:
            return null;
    }
}