import profile from "./profile";
import teamSettings from "./profile/teamSettings";
import teamAdd from "./teamAdd";
import teamSearch from "./teamSearch";
import teamBatchCreate from "./teamBatchCreate";

export default{
    ...profile,
    ...teamAdd,
    ...teamSearch,
    ...teamSettings,
    ...teamBatchCreate,
}