import { stringBuilder } from '@spordle/helpers'
import { parse, stringifyUrl } from 'query-string'
import { useCallback } from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { apiUrlEnv } from '../../api/API-Spordle'

const DBSelector = () => {
    return (process.env.REACT_APP_ENVIRONMENT === 'int' || process.env.REACT_APP_ENVIRONMENT === 'local' &&
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav className="text-purple">
                <span className={stringBuilder('font-medium')}>DB: {apiUrlEnv}</span>
            </DropdownToggle>
            <DropdownMenu right>
                <Item env='int' />
                <Item env='stage' />
                <Item env='hcstage' />
                <Item env='dev' />
                <Item env='local' />
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

const Item = ({ env }) => {
    const getDBUrl = useCallback(() => {
        return stringifyUrl({
            url: window.location.origin + window.location.pathname,
            query: {
                ...parse(window.location.search),
                db: env,
            },
        })
    }, [ env ]);

    const isCurrent = apiUrlEnv === env;

    return (
        <DropdownItem tag='a' className={isCurrent ? 'text-primary' : undefined} href={getDBUrl()} rel='noopener noreferrer' disabled={isCurrent}>
            {isCurrent && <i className='mdi mdi-check mr-1' />}{env}
        </DropdownItem>
    )
}

export default DBSelector
