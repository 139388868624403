import Translate from "@spordle/intl-elements"
import { Alert } from "reactstrap"
import { DisplayI18n } from "../../helpers/i18nHelper"

const ReportEngineError = ({ error }) => {
    return (
        <Alert color="danger" className="text-center mb-0 mt-2">
            <div className="font-medium h3 mb-1 text-inherit">
                <Translate id="misc.oops" />
            </div>
            <div className="h4 text-inherit"><Translate id="misc.error" />.</div>
            <p className="mb-0"><Translate id="misc.error.apology" /></p>
            <p className="mb-0"><Translate id="misc.danger" />: <DisplayI18n field="message" i18n={error?.i18n} defaultValue={error?.message} /></p>
        </Alert>
    )
}

export default ReportEngineError
