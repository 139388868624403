import { useDisclosure } from '@mantine/hooks';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import { Badge, Fade, ListGroup, ListGroupItem } from 'reactstrap';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import DisplayOrganization from '../../../../components/organization/DisplayOrganization';
import HasAccessTo from '../../../../components/permissions/HasAccessTo';
import QuickViewButton from '../../../../components/quickView/QuickViewButton';
import SidePanel from '../../../../components/sidePanel/SidePanel';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import ApproveUnconfirmedOrganizationModal from './ApproveUnconfirmedOrganizationModal';
import { Link } from 'react-router-dom';
import InlineCopy from '../../../../components/inlineCopy/InlineCopy';
import { DisplayPhoneNumber } from '@spordle/formik-elements';
import OverlayLoader from '../../../../components/loading/OverlayLoader';

const UnconfirmedOrganizationSidePanel = (props) => {
    const organizationContext = useContext(OrganizationContext);
    const [ isModalOpen, isModalOpenHandlers ] = useDisclosure(false);
    const [ contacts, setContacts ] = useState([]);
    const [ contactsLoading, setContactsLoading ] = useState(false);

    useEffect(() => {
        if(props.selectedRows.length <= 1){
            setContactsLoading(true)
            organizationContext.getOrganizationContacts(props.selectedRows[0].organisation_id)
                .then((orgContacts) => {
                    setContacts(orgContacts)
                    setContactsLoading(false)
                })
                .catch((error) => {
                    setContacts([])
                    setContactsLoading(false)
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                    }
                })
        }
    }, [ props.selectedRows.length <= 1, props.selectedRows[0].organisation_id ])

    const buildAddressTop = () => {
        const top = [];

        if(props.selectedRows[0].street_number){
            top.push(props.selectedRows[0].street_number);
        }

        if(props.selectedRows[0].street){
            top.push(props.selectedRows[0].street);
        }

        if(props.selectedRows[0].city){
            top.push(props.selectedRows[0].city)
        }

        return top.join(', ');
    }

    const buildAddressBottom = () => {
        const bottom = [];

        if(props.selectedRows[0].province_id || props.selectedRows[0].province_code){
            bottom.push(props.selectedRows[0].province_id || props.selectedRows[0].province_code);
        }

        if(props.selectedRows[0].zip_code){
            bottom.push(props.selectedRows[0].zip_code)
        }

        if(props.selectedRows[0].country_id || props.selectedRows[0].country_code){
            bottom.push(props.selectedRows[0].country_id || props.selectedRows[0].country_code)
        }

        return bottom.join(', ');
    }

    return (
        <Fade in>
            <ApproveUnconfirmedOrganizationModal
                toggle={isModalOpenHandlers.toggle}
                isOpen={isModalOpen}
                selectedRows={props.selectedRows}
                getBadgeColour={props.getBadgeColour}
                spordleTable={props.tableRef}
                forceCloseSidePanel={props.forceCloseSidePanel}
            />
            {(props.selectedRows.length <= 1) ?
                <SidePanel.Header noBorder className='sticky-top bg-white z-index-1'>
                    <div className='d-flex mb-2 align-items-center'>
                        <SidePanel.ToggleButton />
                        <div className="ml-auto d-flex align-items-center">
                            <SidePanel.ActionsMenu>
                                <SidePanel.MenuAction onClick={isModalOpenHandlers.toggle}>
                                    <Translate id='task.unconfirmedOrg.sidepanel.manage.approval' />
                                </SidePanel.MenuAction>
                            </SidePanel.ActionsMenu>
                        </div>
                    </div>
                    <div className="border-bottom mb-2 pb-2">
                        <div className='d-flex align-items-center '>
                            <UserImg
                                abbr={props.selectedRows[0].abbreviation}
                                src={props.selectedRows[0].logo?.full_path}
                                filePos={props.selectedRows[0].logo?.file_position}
                                alt={props.selectedRows[0].organisation_name}
                                width="40"
                                className={'mr-2'}
                            />
                            <div className='w-100 h4 font-bold mb-0 text-break'>
                                <DisplayOrganization organisation={{ organisation_id: props.selectedRows[0].organisation_id }}>
                                    <DisplayI18n field='name' defaultValue={props.selectedRows[0].organisation_name} i18n={props.selectedRows[0].i18n} />
                                </DisplayOrganization>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <address className="d-flex">
                                <span>
                                    {buildAddressTop() && <span>{buildAddressTop()}</span>}
                                    {buildAddressBottom() && <span className="d-block">{buildAddressBottom()}</span>}
                                    {props.selectedRows[0].map_url &&
                                        <a className="small" href={props.selectedRows[0].map_url} target='_blank' rel='noopener noreferrer'>
                                            <Translate id='misc.googlePlaces.googleMaps' /><i className="mdi mdi-launch ml-1" />
                                        </a>
                                    }
                                </span>
                            </address>
                        </div>
                    </div>

                    <div className='align-content-center text-muted mt-3'>
                        <Translate id='misc.status' /> :
                        <Badge className='ml-1' color={props.getBadgeColour(props.selectedRows[0].organisation_status?.system || 'DRAFT')}>
                            {props.selectedRows[0].organisation_status ?
                                <DisplayI18n
                                    field='name'
                                    defaultValue={props.selectedRows[0].organisation_status.name}
                                    i18n={props.selectedRows[0].organisation_status.i18n}
                                />
                                :
                                <Translate id="reports.column.status.DRAFT" />
                            }
                        </Badge>
                    </div>
                    <div className='text-muted mt-1'>
                        <Translate id='task.unconfirmedOrg.sidepanel.submission.date' /> :
                        <span className='ml-1 text-dark font-bold'><DateFormat value={props.selectedRows[0].submission_date} /></span>
                    </div>

                    <div className="h5 font-bold mb-3 mt-3"><Translate id='organisation.profile.tabs.staff' /></div>
                    <OverlayLoader isLoading={contactsLoading}>
                        {contacts.length > 0 ?
                            contacts.map((contact) => (
                                <UserDisplay className="mb-2 w-100" card key={contact.organisation_contact_id}>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title><QuickViewButton member={contact.member}>{contact.first_name} {contact.last_name}</QuickViewButton></UserDisplay.Title>
                                        {contact.email && <UserDisplay.Subtitle>{contact.email}</UserDisplay.Subtitle>}
                                        {contact.phone && <UserDisplay.Subtitle><DisplayPhoneNumber phoneString={contact.phone} format='INTERNATIONAL' /></UserDisplay.Subtitle>}
                                        {contact.organisation_contact_functions && contact.organisation_contact_functions?.length > 1 ?
                                            <UserDisplay.Subtitle>
                                                <div className='font-medium'>
                                                    <ul className="ml-n4 mb-0">
                                                        {contact.organisation_contact_functions.map((contactFunction) => (
                                                            <li key={contactFunction.organisation_contact_function_id}>
                                                                <DisplayI18n field="name" i18n={contactFunction.organisation_contact_type.i18n} defaultValue={contactFunction.organisation_contact_type.name} />
                                                                {contactFunction.is_elected == 1 && !contactFunction.elected_until &&
                                                                    <span className="ml-1 text-muted small"><Translate id="organization.profile.staff.formik.elected" /></span>
                                                                }
                                                                {contactFunction.elected_until &&
                                                                    <span className="text-muted small ml-1">
                                                                        <Translate id='organization.profile.staff.formik.electedUntil' /> <DateFormat className='ml-1' value={contactFunction.elected_until} />
                                                                    </span>
                                                                }
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </UserDisplay.Subtitle>
                                            :
                                            <UserDisplay.Subtitle>
                                                <div className='font-medium text-muted'>
                                                    <DisplayI18n field="name" i18n={contact.organisation_contact_functions?.[0]?.organisation_contact_type?.i18n} defaultValue={contact.organisation_contact_functions?.[0]?.organisation_contact_type?.name} />
                                                    {contact.organisation_contact_functions?.[0]?.is_elected == 1 && !contact.organisation_contact_functions?.[0]?.elected_until &&
                                                        <span className=" ml-1 text-muted small"><Translate id="organization.profile.staff.formik.elected" /></span>
                                                    }
                                                    {contact.organisation_contact_functions?.[0]?.elected_until &&
                                                        <span className="text-muted small ml-1">
                                                            <Translate id='organization.profile.staff.formik.electedUntil' /> <DateFormat className='ml-1' value={contact.organisation_contact_functions?.[0]?.elected_until} />
                                                        </span>
                                                    }
                                                </div>
                                            </UserDisplay.Subtitle>
                                        }
                                        {contact.member?.unique_idenitifer && <UserDisplay.Subtitle><InlineCopy toCopy={contact.member.unique_idenitifer}>#{contact.member.unique_idenitifer}</InlineCopy></UserDisplay.Subtitle>}
                                        {contact.member && <UserDisplay.Subtitle><Link to={"/members/profile/" + contact.member?.member_id}><Translate id="misc.goToProfile" /><i className='mdi mdi-chevron-right' /></Link></UserDisplay.Subtitle>}
                                    </UserDisplay.Container>
                                </UserDisplay>
                            ))
                            :
                            <div className="bg-light-inverse rounded border text-center p-4 mb-3">
                                <Translate id="organization.profile.staff.empty" />
                            </div>
                        }
                    </OverlayLoader>
                </SidePanel.Header>
                :
                <>
                    <SidePanel.Header noBorder className='sticky-top bg-white z-index-1'>
                        <div className='d-flex mb-2 align-items-center'>
                            <SidePanel.ToggleButton />
                            <div className="ml-auto d-flex align-items-center">
                                <HasAccessTo componentPermissionCode='review_organisation_status' componentCode='organization'>
                                    <SidePanel.ActionsMenu>
                                        <SidePanel.MenuAction onClick={isModalOpenHandlers.toggle}>
                                            <Translate id='task.unconfirmedOrg.sidepanel.manage.approval' />
                                        </SidePanel.MenuAction>
                                    </SidePanel.ActionsMenu>
                                </HasAccessTo>
                            </div>
                        </div>
                        <div className='w-100 h4 font-bold mb-0 text-break'>
                            <Translate values={{ count: props.selectedRows.length }} id="task.unconfirmedOrg.modal.multi.collapse" />
                        </div>
                    </SidePanel.Header>
                    <ListGroup>
                        {props.selectedRows.map((org) => (
                            <ListGroupItem className='px-3' key={org.organisation_id}>
                                <UserDisplay>
                                    <UserDisplay.Container>
                                        <UserImg
                                            abbr={org?.abbreviation}
                                            src={org?.logo?.full_path}
                                            filePos={org?.logo?.file_position}
                                            alt={org?.organisation_name}
                                            width="40"
                                            className={'mr-2'}
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container className="w-100">
                                        <UserDisplay.Title className="d-flex justify-content-between">
                                            <div className='justify-content-start'>
                                                <DisplayOrganization organisation={{ organisation_id: org?.organisation_id }}><DisplayI18n field='name' defaultValue={org?.organisation_name} i18n={org?.i18n} /></DisplayOrganization>
                                                <Badge
                                                    color={props.getBadgeColour(org?.organisation_status?.system || 'DRAFT')}
                                                >
                                                    {props.selectedRows[0].organisation_status ?
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={props.selectedRows[0].organisation_status.name}
                                                            i18n={props.selectedRows[0].organisation_status.i18n}
                                                        />
                                                        :
                                                        <Translate id="reports.column.status.DRAFT" />
                                                    }
                                                </Badge>
                                            </div>
                                        </UserDisplay.Title>
                                        <UserDisplay.Subtitle>
                                            <div className='text-muted mt-1'><Translate id='task.unconfirmedOrg.sidepanel.submission.date' /> : <DateFormat value={org?.submission_date} /></div>
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </>
            }

        </Fade>
    );
};

export default UnconfirmedOrganizationSidePanel;
