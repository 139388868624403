import { createContext, useState } from "react";
import { useSteps } from "../../customHooks/useSteps";
import { MODE_MEMBER, sortAndFilterRecipients } from "../sendCommHelper";

/** @type {React.Context<Omit<Schedule, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const SendCommModalContext = createContext();
SendCommModalContext.displayName = 'SendCommModalContext';

function SendCommModalContextProvider({ recipients = [], mode: theMode, children, ...props }){
    const withInitRecipients = recipients.length > 0;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ step, stepHandlers ] = useSteps(3, withInitRecipients ? 1 : 0);
    const [ mode, setMode ] = useState(theMode || MODE_MEMBER);
    const [ confirmInfo, setConfirmInfo ] = useState({ failed: [], success: [] });
    const [ theRecipients, setTheRecipients ] = useState(sortAndFilterRecipients(recipients));

    const resetTheRecipients = () => {
        setTheRecipients(sortAndFilterRecipients(recipients));
    }

    return (
        <SendCommModalContext.Provider
            value={{
                step,
                confirmInfo,
                setConfirmInfo,
                isLoading,
                setIsLoading,
                mode,
                resetTheRecipients,
                setMode,
                initialRecipients: recipients,
                recipients: theRecipients,
                setTheRecipients,
                withInitRecipients,
                ...stepHandlers,
                ...props,
            }}
        >
            {children}
        </SendCommModalContext.Provider>
    )
}

export default SendCommModalContextProvider;