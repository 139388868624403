import Translate from "@spordle/intl-elements"
import { displayI18n, DisplayI18n } from "../helpers/i18nHelper"
import { useGenderSettingsSWR } from "../views/reports/reportEngine/reportsEngineHelpers"

const GenderLabel = ({ gender }) => {
    const { data } = useGenderSettingsSWR();

    if(Object.keys(data).length > 0 && gender){
        return (
            <DisplayI18n
                field='name'
                defaultValue={
                    <Translate
                        id={`form.fields.gender.${(gender || "").toLowerCase()}`}
                        defaultMessage={gender}
                    />
                }
                i18n={data[gender]?.i18n}
            />
        )
    }
    return null
}

export const genderLabel = (gender, data, formatMessage, locale) => {
    if(Object.keys(data).length > 0 && gender){
        return displayI18n('name', data[gender]?.i18n, formatMessage({ id: `form.fields.gender.${(gender || "").toLowerCase()}` }), locale)
    }
    return ""
}

export default GenderLabel