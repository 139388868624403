import { useCallback } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { FormikDateTime, FormikInputNumber } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Tooltip } from '@mantine/core';

/**
 * @description Component that handles questions with field as answer i.e. text, text area, any date/time picker, etc.
 * @param {object} props
 * @param {"TEXT"|"TEXT_AREA"|"DATE"|"DATETIME"|"NUMBER"} props.type
 * @param {string} props.id
 * @param {number} props.questionIndex
 * @param {number} props.sectionIndex
 */
const QuestionTypeField = ({ type, ...props }) => {
    const getFieldUiElement = useCallback(() => {
        switch (type){
            case 'DATE':
                return (
                    <div>
                        <FormGroup key='DATE'>
                            <Row>
                                <Col md='6'>
                                    <Label className='text-muted'><Translate id='catalog.forms.components.formEditor.questionType.questionTypeField.min.date' /></Label>
                                    <FormikDateTime name={`${props.id}.min`} timeFormat={false} />
                                </Col>
                                <Col md='6'>
                                    <Label className='text-muted'><Translate id='catalog.forms.components.formEditor.questionType.questionTypeField.max.date' /></Label>
                                    <FormikDateTime name={`${props.id}.max`} timeFormat={false} />
                                </Col>
                            </Row>
                        </FormGroup>
                        {/* <div className="border-bottom">
                            <Label className="text-muted"><i className="mdi mdi-calendar-check mr-1"/><Translate id='catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.date'/></Label>
                        </div> */}
                    </div>
                );
            case 'DATETIME':
                return (
                    <div>
                        <FormGroup key='DATETIME'>
                            <Row>
                                <Col md='6'>
                                    <Label className='text-muted'><Translate id='catalog.forms.components.formEditor.questionType.questionTypeField.min.time' /></Label>
                                    <FormikDateTime name={`${props.id}.min`} dateFormat={false} />
                                </Col>
                                <Col md='6'>
                                    <Label className='text-muted'><Translate id='catalog.forms.components.formEditor.questionType.questionTypeField.max.time' /></Label>
                                    <FormikDateTime name={`${props.id}.max`} dateFormat={false} />
                                </Col>
                            </Row>
                        </FormGroup>
                        {/* <div className="border-bottom">
                            <Label className="text-muted"><i className="mdi mdi-calendar-check mr-1"/><Translate id='catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.time'/></Label>
                        </div> */}
                    </div>
                );
            case 'NUMBER':
                return (
                    <div>
                        <FormGroup>
                            <Row>
                                <Col md='6'>
                                    <Label className='text-muted'><Translate id='catalog.forms.components.formEditor.questionType.questionTypeField.min.number' /> <Tooltip withArrow wrapLines width={240} position="top" zIndex={2000} label={<Translate id="catalog.forms.components.formEditor.questionType.questionTypeField.min.number.tip" values={{ underline: (word) => <u>{word}</u> }} />}><i className='mdi mdi-information-outline' /></Tooltip></Label>
                                    <FormikInputNumber name={`${props.id}.min`} />
                                </Col>
                                <Col md='6'>
                                    <Label className='text-muted'><Translate id='catalog.forms.components.formEditor.questionType.questionTypeField.max.number' /> <Tooltip withArrow wrapLines width={240} position="top" zIndex={2000} label={<Translate id="catalog.forms.components.formEditor.questionType.questionTypeField.max.number.tip" values={{ underline: (word) => <u>{word}</u> }} />}><i className='mdi mdi-information-outline' /></Tooltip></Label>
                                    <FormikInputNumber name={`${props.id}.max`} />
                                </Col>
                            </Row>
                        </FormGroup>
                        {/* <div className="border-bottom">
                            <Label className="text-muted"><Translate id='catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.number'/></Label>
                        </div> */}
                    </div>
                );
            case 'TEXT':
                return null;
                // return (
                //     <div className="border-bottom">
                //         <Label className="text-muted"><Translate id='catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.short'/></Label>
                //     </div>
                // );
            case 'TEXT_AREA':
                return null;
                // return (
                //     <>
                //         <div className="border-bottom">
                //             <Label className="text-muted"><Translate id='catalog.forms.components.formEditor.questionType.questionTypeField.field.placeholder.long'/></Label>
                //         </div>
                //         <div className="border-bottom">
                //             <Label></Label>
                //         </div>
                //         <div className="border-bottom">
                //             <Label></Label>
                //         </div>
                //     </>
                // );
            default:
                return <div />
        }
    }, [ type ])

    return getFieldUiElement();
}

export default QuestionTypeField;