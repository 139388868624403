import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';
import Translate, { DateFormat } from '@spordle/intl-elements';
import queryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { Button, Card, Table } from "reactstrap";
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import CardSectionTitle from '../../../../components/CardSectionTitle';
import CanDoAction from '../../../../components/permissions/CanDoAction';
import OpenSidePanelOnSearch from '../../../../components/sidePanel/OpenSidePanelOnSearch';
import SpordlePanelTable from '../../../../components/sidePanel/SpordlePanel';
import { fail } from "@spordle/toasts";
import { CrcContext } from '../../../../contexts/CrcContext';
import { I18nContext } from '../../../../contexts/I18nContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { RolesContext } from '../../../../contexts/RolesContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import CRCStatus from './components/CRCStatus';
import CriminalCriminalCheckModal from './components/CriminalRecordCheckAdd';
import CriminalRecordCheckSidepanel from './components/CriminalRecordCheckSidepanel';
import { Tooltip } from '@mantine/core';
import { stringBuilder } from '@spordle/helpers';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';

const CriminalRecordCheck = ({ godAccess }) => {
    const membersContext = useContext(MembersContext);
    const crcContext = useContext(CrcContext);
    const { isGod } = useContext(IdentityRolesContext);
    const rolesContext = useContext(RolesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { organisation_id, settings, getOrganizationBackgroundChecks } = useContext(OrganizationContext);

    const [ isOpen, setIsOpen ] = useState(false);
    const [ crcStatus, setCrcStatus ] = useState(null);
    const [ backgroundChecks, setBackgroundChecks ] = useState([]);
    const toggleIsOpen = () => setIsOpen(!isOpen);

    // Permissions
    const canCreate = rolesContext.canDoAction('ADD', 'members', 'member_background_check');
    const canEdit = rolesContext.canDoAction('EDIT', 'members', 'member_background_check');
    const canDelete = rolesContext.canDoAction('DELETE', 'members', 'member_background_check');

    const checkIfCantAdd = () => {
        return settings.background_check_enable?.value == "0" && settings.declaration_enable?.value == "0" && settings.vsc_enable?.value == "0";
    }

    useEffect(() => {
        crcContext.getAllCrcStatus()
            .then((theStatus) => setCrcStatus(theStatus.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

        getOrganizationBackgroundChecks(organisation_id)
            .then(setBackgroundChecks)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    return (
        <Card id='criminalRecordCheckCard' body className="card-shadow">
            <CardSectionTitle title='members.profile.crc.title' titleClassname={godAccess ? "text-purple" : undefined} />
            <SpordlePanelTable
                allowOutsideClick
                dataIndex='crc_id'
                sidePanel={(props) => <CriminalRecordCheckSidepanel canDelete={canDelete} canEdit={canEdit} allStatus={crcStatus} backgroundChecks={backgroundChecks} {...props} />}
                table={(panelProps) => (
                    <SpordleTableProvider
                        id='memberCrc'
                        tableHover bordered striped
                        clickable
                        ref={panelProps.spordleTableRef}
                        pagination={5}
                        desktopWhen
                        dataIndex='crc_id'
                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                        defaultSorting="-issue_date"
                        defaultSearchValue={queryString.parse(window.location.search)['crc-search']}
                        loadData={(from, _, spordleTable) => {
                            switch (from){
                                case 'REFRESH':
                                    spordleTable.setLoading();
                                case 'CDM':
                                    return membersContext.getMemberCrcInfo(membersContext.currentMember.member_id);
                                default:
                                    break;
                            }
                        }}
                        searchKeys={[
                            'crc_id',
                            `background_check_type_id.i18n.${getGenericLocale()}.name`,
                            'background_check_type_id.name',
                            `criminal_record_check_status.i18n.${getGenericLocale()}.name`,
                            'criminal_record_check_status.name',
                            'created_by.family_name',
                            'created_by.name',
                            `organisation.i18n.${getGenericLocale()}.name`,
                            'organisation.organisation_name',
                            'created_at',
                            'updated_at',
                            'expiration_date',
                        ]}
                        columns={[
                            {
                                label: <Translate id='members.profile.crc.createdBy' />,
                                key: 'createdBy',
                                sortKey: 'created_by.name',
                                fallbackSortKey: 'created_at',
                                sortable: true,
                            },
                            {
                                label: <Translate id='members.profile.crc.type' />,
                                key: 'background_check_type',
                                sortKey: 'background_check_type.background_check_type_id',
                                sortable: true,
                            },
                            {
                                label: <Translate id='members.profile.crc.validOn' />,
                                key: 'issue_date',
                                sortable: true,
                            },
                            {
                                label: <Translate id='members.profile.crc.expiresAt' />,
                                key: 'expiration_date',
                                sortable: true,
                            },
                            {
                                label: <Translate id="misc.status" />,
                                key: 'status',
                                sortKey: `criminal_record_check_status.${getGenericLocale()}.name`,
                                fallbackSortKey: 'criminal_record_check_status.name',
                                sortable: true,
                            },
                        ]}
                        renderRow={(columnKey, crc) => {
                            switch (columnKey){
                                case 'createdBy':
                                    return (
                                        <>
                                            <div className="font-medium line-height-1">{crc.created_by?.name} {crc.created_by?.family_name}</div>
                                            {crc.organisation &&
                                                <div className='small mb-1'>
                                                    <DisplayI18n field='name' defaultValue={crc.organisation.organisation_name} i18n={crc.organisation.i18n} />
                                                </div>
                                            }
                                            <div className="small"><span className="font-medium mr-1"><Translate id='members.profile.crc.on' /></span>{crc.created_at && <DateFormat value={crc.created_at} utc />}</div>
                                            {crc.updated_at &&
                                                <div className="small"><span className="font-medium mr-1"><Translate id='members.profile.crc.lastUpdated' /></span><DateFormat value={crc.updated_at} utc /></div>
                                            }
                                        </>
                                    )
                                case 'background_check_type':
                                    return (
                                        <div>
                                            <DisplayI18n field='name' defaultValue={crc.background_check_type.name} i18n={crc.background_check_type.i18n} />
                                        </div>
                                    )
                                case 'expiration_date':
                                    return crc.expiration_date && <DateFormat value={crc.expiration_date} utc />
                                case 'status':
                                    return <CRCStatus crc={crc} />
                                default:
                                    break;
                            }
                        }}
                        onColumnClick={(e, data) => {
                            switch (e.button){
                                case 0: // Left mouse button
                                    panelProps.onSingleSelectChange(data);
                                    break;
                            }
                        }}
                        rowIsHighlighted={(data) => data.checked}
                        emptyLayout={
                            <Table bordered striped hover>
                                <tbody>
                                    <tr>
                                        <td className='text-center'>
                                            {(canCreate || isGod()) ?
                                                <button className={stringBuilder('font-medium reset-btn text-primary', { 'text-purple': !canCreate })} type='button' onClick={toggleIsOpen}><i className='mdi mdi-plus' /><Translate id='misc.add' /> <Translate id="members.profile.crc.title" /></button>
                                                :
                                                <Translate id="misc.search.empty.title" />
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        }
                    >
                        <div className='mb-2'>
                            <div className='d-flex flex-wrap justify-content-between'>
                                <SearchInput />
                                <div className='d-flex ml-auto text-right'>
                                    <Refresh />
                                    {(canCreate || isGod()) &&
                                        <div className="ml-2">
                                            <AnalyticsModal backdrop="static" unmountOnClose isOpen={isOpen} analyticsName='CriminalRecordCheck'>
                                                <CriminalCriminalCheckModal allStatus={crcStatus} isOpen={isOpen} toggle={toggleIsOpen} backgroundChecks={backgroundChecks} />
                                            </AnalyticsModal>
                                            {backgroundChecks.length > 0 &&
                                                <CanDoAction action='ADD' componentCode='members' componentPermissionCode='member_background_check'>
                                                    <Tooltip withArrow disabled={!checkIfCantAdd()} label={<Translate id='members.profile.crc.cannotAdd' />}>
                                                        <Button disabled={checkIfCantAdd()} onClick={toggleIsOpen} color='primary'><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                    </Tooltip>
                                                </CanDoAction>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <SpordleTableView />
                        <OpenSidePanelOnSearch />
                    </SpordleTableProvider>
                )}
            />
        </Card>
    );
}

export default CriminalRecordCheck;