import SpordleTableProvider, { SpordleTableContext, SpordleTableView } from '@spordle/datatables';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    Button, Card,
    CardBody,
    DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown
} from "reactstrap";
import NoImgItem from '../../../assets/images/NoImage_Item.svg';
import CanDoAction from '../../../components/permissions/CanDoAction';
import SpordlePanelTable from '../../../components/sidePanel/SpordlePanel';
import EmptyLayout from '../../../components/table/EmptyLayout';
import UserDisplay from '../../../components/userDisplay/UserDisplay';
import UserImg from '../../../components/UserImg';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { I18nContext } from '../../../contexts/I18nContext';
import { OtherFeesContext } from '../../../contexts/OtherFeesContext';
import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext } from '../../../helpers/i18nHelper';
import OtherItemsAdd from './components/OtherItemsAdd';
import OtherItemsSidepanel from './components/OtherItemsSidepanel';

const OtherItems = () => {
    const otherFeesContext = useContext(OtherFeesContext);
    const i18nContext = useContext(I18nContext);

    const [ modalAdd, setModalAdd ] = useState(false);
    const toggleModalAdd = () => setModalAdd(!modalAdd);

    return (
        <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
            <I18nHelperContext.Consumer>
                {(i18nHelper) => (
                    <>
                        {/* Catalog View Header */}
                        <ViewHeaderV2
                            title='catalog.otherItems.title'
                            breadcrumbsProps={{
                                disablePeriodSelect: true,
                            }}
                        />

                        <div className="px-4">
                            <Card className="card-shadow">
                                <CardBody>
                                    <SpordlePanelTable
                                        allowOutsideClick
                                        sidePanel={(props) => <OtherItemsSidepanel {...props} />}
                                        dataIndex='other_fee_id'
                                        table={(panelProps) => (
                                            <SpordleTableProvider
                                                id='CatalogOtherItemsTable'
                                                tableHover bordered striped
                                                clickable
                                                ref={panelProps.spordleTableRef}
                                                desktopWhen
                                                dataIndex='other_fee_id'
                                                pagination={10}
                                                emptyLayout={<EmptyLayout />}
                                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                                searchKeys={[
                                                    `fee.i18n.${i18nContext.getGenericLocale()}.name`,
                                                    `fee.i18n.${i18nContext.getGenericLocale()}.description`,
                                                    'fee.name',
                                                    'fee.description',
                                                    'fee.amount',
                                                    'fee.sku',
                                                ]}
                                                loadData={(from, _, spordleTable) => {
                                                    switch (from){
                                                        case 'REFRESH':
                                                            spordleTable.setLoading(true);
                                                        case 'CDM':
                                                            return otherFeesContext.getOtherItems().catch(console.error);
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                columns={[
                                                    {
                                                        label: '-',
                                                        key: 'image',
                                                        sortable: false,
                                                        dataClassName: 'text-center',
                                                    },
                                                    ...i18nHelper.getTableColumns('name'),
                                                    // {
                                                    //     label: 'SKU',
                                                    //     key: 'sku',
                                                    //     sortable: true
                                                    // },
                                                    {
                                                        label: <Translate id='catalog.otherItems.table.column.status' />,
                                                        key: 'active',
                                                        sortable: true,
                                                        dataClassName: 'text-center',
                                                    },
                                                    {
                                                        label: <Translate id='catalog.otherItems.table.column.total_sold' />,
                                                        key: 'total_sold',
                                                        sortable: true,
                                                    },
                                                    {
                                                        label: <Translate id='catalog.otherItems.table.column.price' />,
                                                        key: 'fee',
                                                        sortable: true,
                                                    },
                                                ]}
                                                renderRow={(columnKey, data) => {
                                                    if(columnKey.includes('i18n')){
                                                        const lang = columnKey.substring(0, columnKey.lastIndexOf('.')); // Get column key lang for i18n helper
                                                        return (
                                                            <UserDisplay>
                                                                <UserDisplay.Container>
                                                                    <UserDisplay.Title>
                                                                        <DisplayI18n
                                                                            field={columnKey}
                                                                            defaultValue={data.fee.name}
                                                                            i18n={data.fee.i18n}
                                                                        />
                                                                    </UserDisplay.Title>
                                                                    <UserDisplay.Title className='text-muted'>
                                                                        <DisplayI18n
                                                                            field={`${lang}.description`}
                                                                            defaultValue={data.fee.description}
                                                                            i18n={data.fee.i18n}
                                                                        />
                                                                    </UserDisplay.Title>
                                                                </UserDisplay.Container>
                                                            </UserDisplay>
                                                        )
                                                    }
                                                    switch (columnKey){
                                                    // case 'sku':
                                                    //     return(
                                                    //         <>{data.fee.sku || '-'}</>
                                                    //     )
                                                        case 'image':
                                                            return (
                                                                <UserDisplay.Container>
                                                                    <UserImg
                                                                        className="rounded-lg border shadow-none"
                                                                        width={60}
                                                                        src={data.image?.full_path || NoImgItem}
                                                                        filePos={data.image?.file_position}
                                                                        alt={data?.fee?.name}

                                                                    />
                                                                </UserDisplay.Container>
                                                            )
                                                        case 'active':
                                                            return data.active === "1" ? <i className="text-primary mdi mdi-check" /> : <i className="text-danger mdi mdi-close" />;
                                                        case 'fee':
                                                            return (
                                                                <div className='font-medium'>
                                                                    <CurrencyFormat value={data.fee.amount / 100} currencyDisplay='narrowSymbol' />
                                                                    {data.fee.tax_class &&
                                                                        <sup className='ml-1'>(<Translate id='catalog.registrationFees.add.form.tax' />)</sup>
                                                                    }
                                                                </div>
                                                            )
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                onColumnClick={(e, data) => {
                                                    switch (e.button){
                                                        case 0: // Left mouse button
                                                            panelProps.onSingleSelectChange(data);
                                                            break;
                                                    }
                                                }}
                                                rowIsHighlighted={(data) => panelProps.selectedRows[0]?.other_fee_id === data.other_fee_id}
                                            >
                                                <div className='mb-2'>
                                                    <div className='d-flex flex-wrap justify-content-between'>
                                                        <SpordleTableProvider.SearchInput />
                                                        <div className='d-flex ml-auto text-right nowrap'>
                                                            <SpordleTableContext.Consumer>
                                                                {(spordleTable) => <Button className='mdi mdi-refresh' outline color='primary' onClick={spordleTable.refreshTable} />}
                                                            </SpordleTableContext.Consumer>
                                                            <UncontrolledButtonDropdown className='ml-2'>
                                                                <DropdownToggle color='primary' outline caret disabled>
                                                                    <i className='mdi mdi-download mr-1' /><Translate id='misc.export' />
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem><i className='fas fa-file-excel' /> <Translate id='misc.excel' /></DropdownItem>
                                                                    <DropdownItem><i className='fas fa-file-pdf' /> <Translate id='misc.pdf' /></DropdownItem>
                                                                    <DropdownItem><i className='fas fa-print' /> <Translate id='misc.print' /></DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                            <CanDoAction action='ADD' componentCode='catalog' componentPermissionCode='other_fees'>
                                                                <OtherItemsAdd isOpen={modalAdd} toggle={toggleModalAdd} />
                                                                <Button className='ml-2' color='primary' onClick={toggleModalAdd}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                            </CanDoAction>
                                                        </div>
                                                    </div>
                                                </div>
                                                <SpordleTableView />
                                            </SpordleTableProvider>
                                        )}
                                    />
                                </CardBody>
                            </Card>
                        </div>
                    </>
                )}
            </I18nHelperContext.Consumer>
        </I18nHelperContextProvider>
    );
}

export default OtherItems;