import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import {
    Alert,
    Button,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter, ModalHeader
} from "reactstrap";
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { success } from "@spordle/toasts";
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { TeamsContext } from '../../../../contexts/TeamsContext';
import { APPROVED, DRAFT, PENDING, REJECTED } from '../../TeamHelpers';
import { object, string } from 'yup';
import { FormikTextArea } from '@spordle/formik-elements';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import CrossFade from '../../../../components/crossFade/CrossFade';
import CollapsibleCard from '../../../../components/collapsibleCard/CollapsibleCard';

const TeamApprobationModal = ({ isOpen, toggle, team, action, onAction }) => {
    const teamsContext = useContext(TeamsContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ overwriteApproval, setOverwriteApproval ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ errorType, setErrorType ] = useState('simple');

    const getButtonColor = () => {
        switch (action){
            case PENDING:
                return 'primary';
            case APPROVED:
                return 'success';
            case REJECTED:
                return 'danger';
            case DRAFT:
                return 'danger';
            default:
                break;
        }
    }

    const hasInvalidReasons = (invalidReasons) => {
        return Object.keys(invalidReasons || {}).some((key) => {
            return invalidReasons[key]?.length > 0;
        })
    }

    const handleClose = () => {
        setOverwriteApproval(false)
        setError()
        setErrorType('simple')
    }

    const updateApprobation = (values, skipValidation) => {
        setIsLoading(true)
        return teamsContext.updateTeamApprobation(team.team_id, action, values.comment || null, true, skipValidation)
            .then(() => {
                setOverwriteApproval(false)
                setIsLoading(false);
                toggle()
                onAction(action) // syncrows when in table
                success();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.error.message)
                    setIsLoading(false);
                    if(error.overwrite_approval)
                        setOverwriteApproval(true)
                    else
                        setOverwriteApproval(false)

                    if(error.error.code == '3556' && hasInvalidReasons(error.invalid_reasons)){
                        setErrorType('reasons')
                        setError(
                            <>
                                {Object.keys(error.invalid_reasons).reduce((newArray, key) => {
                                    if(error.invalid_reasons[key].length > 0){
                                        newArray.push(
                                            <CollapsibleCard key={key} error arrowIsRight title={<Translate id={'teams.profile.header.approbation.reasons.' + key} />} className='mb-3'>
                                                <ul className='mb-0'>
                                                    {error.invalid_reasons[key].map((message, index) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <li key={index} className='text-left'>
                                                            {message}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </CollapsibleCard>,
                                        )
                                    }
                                    return newArray
                                }, [])}
                                {error.overwrite_approval == 1 &&
                                    <Button
                                        color='primary'
                                        type='button'
                                        block
                                        onClick={() => {
                                            updateApprobation(values, true)
                                        }}
                                    >
                                        <div>
                                            <Translate
                                                id='teams.profile.header.approbation.bypass.title'
                                                values={{ action: <Translate id={`teams.profile.header.approbation.confirm.${action.toString().toLowerCase()}`} /> }}
                                            />
                                        </div>
                                        <Translate id='teams.profile.header.approbation.bypass.text' />
                                    </Button>
                                }
                            </>,
                        )
                    }else{
                        setErrorType('simple')
                        setError(<DisplayI18n field='message' defaultValue={error.error.message} i18n={error.error.i18n} />)
                    }
                }
            })
    }

    return (
        <AnalyticsModal isOpen={isOpen} unmountOnClose analyticsName='TeamApprobationModal' onClosed={handleClose}>
            <Formik
                initialValues={{
                    comment: '',
                }}
                validationSchema={object().shape({
                    comment: string(),
                })}
                onSubmit={(values) => {
                    updateApprobation(values)
                }}
            >
                <Form>
                    <OverlayLoader isLoading={isLoading}>
                        <ModalHeader toggle={toggle}>
                            <Translate id='teams.profile.header.approbation.title' />
                        </ModalHeader>
                        <ModalBody className="text-center">
                            {action &&
                                <div className="h4 font-bold mb-3">
                                    <Translate id={`teams.profile.header.approbation.text.${action.toString().toLowerCase()}`} />
                                </div>
                            }
                            <UserDisplay className='d-flex justify-content-center'>
                                <UserDisplay.Container>
                                    {team.name && // prevent error in console
                                        <UserImg
                                            abbr={team.abbreviation || team.short_name || team.name}
                                            alt={team.name}
                                            filePos={team.logo?.file_position}
                                            src={team.logo?.full_path}
                                            width={40}
                                        />
                                    }
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Title>{team.name}</UserDisplay.Title>
                                    {team.unique_identifier}
                                </UserDisplay.Container>
                                {/*<UserDisplay.Container className="text-nowrap ml-auto">
                                        This section should contain 1 icons per type
                                    Ex: if 1 coach has an invalid CRC and another has a CRC in progress, we should only show the red icon

                                    if crc is invalid
                                    {true &&
                                        <img src={CRC_Red} data-tip="Criminal Record Check Invalid" alt="Criminal record check invalid" width="25"/>
                                    }
                                    {/* if CRC is about to expire (do this after release?)
                                    {false &&
                                        <img src={CRC_Yellow} data-tip="Criminal Record check will soon expire" alt="Certification required" width="25"/>
                                    }

                                    {/* if at least 1 qualification is missing
                                    {false &&
                                        <img src={Certifications_Red} data-tip="Qualification missing" alt="Signed waivers missing" width="25"/>
                                    }

                                    {/* if at least 1 qualification is in progress
                                    {true &&
                                        <img src={Certifications_Yellow} data-tip="Qualification in progress" alt="Signed waivers missing" width="25"/>
                                    }

                                    {/* if at least 1 waiver has not been signed
                                    {true &&
                                        <img src={Waivers_Red} data-tip="Waivers not signed" alt="Signed waivers missing" width="25"/>
                                    }
                                </UserDisplay.Container>*/}
                            </UserDisplay>

                            <FormGroup className='text-left mt-2'>
                                <Label for='comment' className='text-muted'><Translate id='teams.profile.header.approbation.comment' /></Label>
                                <FormikTextArea id='comment' name='comment' rows='3' trim />
                            </FormGroup>

                            <CrossFade isVisible={!!error} appear mountOnEnter unmountOnExit>
                                {errorType === 'simple' ?
                                    <Alert color='danger' className='text-left'>{error}</Alert>
                                    :
                                    error
                                }
                            </CrossFade>
                        </ModalBody>
                        <ModalFooter>
                            {(action && !overwriteApproval) &&
                                <Button color={getButtonColor()} type='submit' disabled={isLoading}>
                                    <Translate id={`teams.profile.header.approbation.confirm.${action.toString().toLowerCase()}`} />
                                </Button>
                            }
                            <Button color='primary' onClick={toggle} outline disabled={isLoading}><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            </Formik>
        </AnalyticsModal>
    );
}

export default TeamApprobationModal;