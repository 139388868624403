export default{
    "teams.teamSearch.competition"           : "Compétition",
    "teams.teamSearch.submittedBy"           : "Soumis par",
    "teams.teamSearch.submittedDate"         : "Date de soumission",
    "teams.teamSearch.teamSubmission"        : "Soumission de l'équipe",
    "teams.teamSearch.teamApproval"          : "Approbation de l'équipe",
    "teams.teamSearch.approvedBy"            : "Approuvée par",
    "teams.teamSearch.approvalDate"          : "Date d'approbation",
    "teams.teamSearch.approvalNote"          : "Note d'approbation",
    "teams.teamSearch.reviewRequest"         : "Révision des requêtes de participation",
    "teams.teamSearch.reviewRequest.success" : "{count, plural, =0 {L'équipe a été mise} =1 {L'équipe a été mise} other {Les # équipes ont été mises}} à jour avec succès",
    "teams.teamSearch.submissionNote"        : "Note de soumission",
    "teams.teamSearch.alreadyParticipating"  : "A déjà appliqué",


    "teams.teamSearch.advancedSearch.label.league"                 : "Ligue",
    "teams.teamSearch.advancedSearch.label.searchLimit"            : "Résultats limités:",
    "teams.teamSearch.advancedSearch.label.searchTotal"            : "Résultats totaux:",
    "teams.teamSearch.advancedSearch.label.searchLimitDescription" : "Utilisez les filtres de division, de catégorie ou de genre pour déverrouiller la limite de recherche.",

    "teams.teamSearch.table.label.roster"      : "Liste des membres",
    "teams.teamSearch.table.label.statusDate"  : "Date de status",
    "teams.teamSearch.table.label.team"        : "Équipe",
    "teams.teamSearch.table.label.category"    : "Catégorie",
    "teams.teamSearch.table.label.org"         : "Organisation",
    "teams.teamSearch.table.paginationMessage" : "Teams {itemStartPos} à {itemEndPos} sur un total de {totalItems}",
    "teams.teamSearch.table.pendingDeficiency" : "En attente de validation",

    "teams.teamSearch.select.status.DRAFT"     : "Brouillon",
    "teams.teamSearch.select.status.PENDING"   : "En cours",
    "teams.teamSearch.select.status.APPROVED"  : "Approuvé",
    "teams.teamSearch.select.status.SUBMITTED" : "Envoyé",

    "teams.teamSearch.sideTable.label.staff"         : "Personnel",
    "teams.teamSearch.sideTable.label.comments"      : "Commentaires",
    "teams.teamSearch.sideTable.label.contactInfo"   : "Contact",
    "teams.teamSearch.sideTable.label.teamInfo"      : "Informations de l'équipe",
    "teams.teamSearch.sideTable.label.teamContact"   : "Contact de l'équipe",
    "teams.teamSearch.sideTable.label.orgContact"    : "Contact de l'organisation",
    "teams.teamSearch.sideTable.delete.msg"          : "Vous êtes sur le point de supprimer cette équipe",
    "teams.teamSearch.sideTable.delete.multiple.msg" : "Vous êtes sur le point de supprimer les équipes suivantes",

    "teams.teamSearch.sideTable.actionsMenu.submit"    : "Soumettre pour approbation",
    "teams.teamSearch.sideTable.actionsMenu.resubmit"  : "Resoumettre pour approbation",
    "teams.teamSearch.sideTable.actionsMenu.approve"   : "Approuver l'équipe",
    "teams.teamSearch.sideTable.actionsMenu.reject"    : "Rejeter l'équipe",
    "teams.teamSearch.sideTable.actionsMenu.cancel"    : "Annuler l'approbation",
    "teams.teamSearch.sideTable.actionsMenu.delete"    : "Supprimer l'équipe",
    "teams.teamSearch.sideTable.actionsMenu.PDFExport" : "Exportation PDF",

    "teams.teamSearch.sideTable.bulkUpdate"                         : "Mise à jour groupée",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.topMessage"     : "Vous êtes sur le point de mettre à jour le statut d'approbation de toutes les équipes éligibles ci-dessous.",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.validTitle"     : "Équipes ({teams})",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.validText"      : "Les équipes suivantes seront mises à jour",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.validNone"      : "Aucune équipe",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.actionNone"     : "Aucune équipe est éligible pour cette action.",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.invalidTitle"   : "Équipes inéligibles ({teams})",
    "teams.teamSearch.sideTable.bulkUpdate.allTeams.invalidText"    : "Les équipes suivantes ne seront pas mises à jour à cause de leur status actuel",
    "teams.teamSearch.sideTable.bulkUpdate.validTeams.comment"      : "Commentaire",
    "teams.teamSearch.sideTable.bulkUpdate.validTeams.comment.hint" : "Ce commentaire est pour toutes les équipes.",

    "teams.teamSearch.sideTable.noTeamsContact" : "Cette équipe n'a pas de contact",

    "teams.teamSearch.sideTable.selectedTeams"   : "équipes sélectionnées",
    "teams.teamSearch.export.emptyTeams"         : "Vous ne pouvez pas exporter des équipes sans joueurs.",
    "teams.teamSearch.export.onlyPrintApproved"  : "Erreur: Équipe non approuvée",
    "teams.teamSearch.export.onlyPrintApproved2" : "Impossible d'exporter un cahier d'équipe pour une équipe non approuvée.",
    "teams.teamSearch.export.maxTenTeams"        : "Veuillez sélectionner 10 équipes ou moins.",

}