import Translate from '@spordle/intl-elements';
import PropTypes from 'prop-types';
import { useContext, useRef } from 'react';
import {
    Alert,
    Button
} from "reactstrap";
import OnlineStoreRegistrations from './registration/OnlineStoreRegistrations';
import OnlineStoreOtherItems from './otherItems/OnlineStoreOtherItems';
import { OnlineStoreContext } from '../../../../contexts/OnlineStoreContext';
import * as Yup from 'yup';
import { Formik } from 'formik';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { fail } from '@spordle/toasts';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import CrossFade from '../../../../components/crossFade/CrossFade';

/**
* @param {object} props
* @param {string} props.onlineStoreId
* @param {boolean} [props.wizard]
* @param {boolean} [props.isEditing]
* @param {boolean} [props.toggle]
* @param {string} [props.from] CREATE | REVIEW | PROFILE
* @param {function} [props.onSubmit] used only in creation wizard to navigate between steps
*/
const OnlineStoreItemsAndFeesComponent = ({ onSubmit, ...props }) => {
    const onlineStoreContext = useContext(OnlineStoreContext);
    const registrationRef = useRef(null);
    const otherItemsRef = useRef(null);
    return (
        <Formik
            initialValues={{
                registrationFees: [],
                otherItems: [],
            }}
            initialStatus={{
                isVisible: false,
                msg: null,
            }}
            validationSchema={Yup.object().shape({
                registrationFees: Yup.mixed().test({
                    name: 'atLeastOneRequired',
                    message: <Translate id='onlineStore.components.onlineStoreItemsFees.validation.oneRequired' />,
                    test: function(){
                        // Must have at least one of them required
                        return this.parent.registrationFees.length !== 0 || this.parent.otherItems.length !== 0;
                    },
                }),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                Promise.all([
                    onlineStoreContext.updateOnlineStoreRegistrationFee(props.onlineStoreId, values.registrationFees),
                    onlineStoreContext.updateOnlineStoreOtherFee(props.onlineStoreId, values.otherItems),
                ]).then(() => {
                    setSubmitting(false);
                    onSubmit?.(values);
                }).catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        setStatus({
                            isVisible: true,
                            msg: <DisplayI18n field="message" defaultValue={error.message} i18n={error.i18n} />,
                        });
                        // refresh to make sure the list is accurate
                        otherItemsRef.current?.tableRef?.refreshTable();
                        registrationRef.current?.tableRef?.refreshTable();
                    }
                    setSubmitting(false);
                })
            }}
        >
            {(formik) => (
                <div className='position-relative mb-phat'>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <CrossFade isVisible={formik.status.isVisible}>
                            <Alert
                                color="danger"
                                toggle={() => formik.setStatus({ ...formik.status, isVisible: false })}
                            >
                                <i className="mdi font-18 mdi-alert-outline mr-2" />
                                {formik.status?.msg}
                            </Alert>
                        </CrossFade>
                        <OnlineStoreRegistrations onlineStoreId={props.onlineStoreId} from={props.from} registrationRef={registrationRef} />
                        <OnlineStoreOtherItems onlineStoreId={props.onlineStoreId} from={props.from} otherItemsRef={otherItemsRef} />

                        <div className="d-flex justify-content-end mt-3">
                            {(props.wizard && props.from === 'CREATE') &&
                                <>
                                    <Button color="primary" outline onClick={props.toggle} className="mr-auto"><Translate id='misc.previous' /></Button>
                                    <Button color="primary" onClick={formik.handleSubmit}><Translate id='misc.next' /></Button>
                                </>
                            }
                        </div>
                    </OverlayLoader>
                </div>
            )}
        </Formik>
    );
}

export default OnlineStoreItemsAndFeesComponent;

OnlineStoreItemsAndFeesComponent.propTypes = {
    previousStep: PropTypes.func,
    onSubmit: PropTypes.func,
};

OnlineStoreItemsAndFeesComponent.defaultProps = {
};