import Translate from '@spordle/intl-elements';
import {
    Row,
    Col,
    Button,
    ModalBody,
    FormGroup,
    Label,
    ModalFooter,
    Collapse,
    ModalHeader
} from "reactstrap";
import { Form, Formik } from 'formik';
import { FormikDateTime, FormikInputText } from '@spordle/formik-elements';
import Required from '../../../../../components/formik/Required'
import { SpordleTableView, useSpordleTable } from '@spordle/datatables';
import { mixed, object, string } from 'yup';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import moment from 'moment';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';

const MemberMergeSearch = ({ toggle, memberMerge, selected, next, isLoading }) => {
    const { filterChange, getData } = useSpordleTable();

    return (
        <Formik
            initialValues={{
                first_name: '',
                last_name: '',
                birthdate: '',
                unique_identifier: '',
            }}
            initialStatus={{
                showResults: false,
            }}
            validationSchema={object().shape({
                first_name: string().test({
                    name: 'isRequired',
                    message: <Translate id='form.validation.firstName.required' />,
                    test: function(firstName){
                        return !!this.parent.unique_identifier || !!firstName;
                    },
                }),
                last_name: string().test({
                    name: 'isRequired',
                    message: <Translate id='form.validation.lastName.required' />,
                    test: function(lastName){
                        return !!this.parent.unique_identifier || !!lastName;
                    },
                }),
                birthdate: mixed().test({
                    name: 'isValid',
                    message: <Translate id='form.validation.date.format' />,
                    test: function(date){
                        return !!this.parent.unique_identifier || !date || moment.isMoment(date);
                    },
                }),
                unique_identifier: string(),
            })}
            onSubmit={(values, { setStatus }) => {
                setStatus({ showResults: true });
                const searchValues = {};

                if(values.unique_identifier){
                    searchValues.unique_identifier = values.unique_identifier;
                }else{
                    searchValues.first_name = values.first_name;
                    searchValues.last_name = values.last_name;

                    if(moment.isMoment(values.birthdate)){
                        searchValues.birthdate = values.birthdate.clone().format('YYYY-MM-DD');
                    }

                }

                filterChange('search', searchValues);
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={isLoading}>
                        <ModalHeader tag='div' toggle={toggle}><Translate id='members.search.memberMerge.modal.title' /></ModalHeader>
                        <ModalBody>
                            {memberMerge &&
                                <UserDisplay card className="w-100">
                                    <UserDisplay.Container>
                                        <UserImg
                                            abbr={memberMerge.first_name.charAt(0) + memberMerge.last_name.charAt(0)}
                                            src={memberMerge.picture?.full_path}
                                            filePos={memberMerge.picture?.file_position}
                                            width={50}
                                            alt={memberMerge.first_name + ' ' + memberMerge.last_name}
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title>
                                            {memberMerge.first_name} {memberMerge.last_name}
                                            <span className="small text-muted ml-1">
                                                (<Translate id='misc.yearsOld' values={{ age: memberMerge.age }} />)
                                            </span>
                                        </UserDisplay.Title>
                                        <UserDisplay.Subtitle>#{memberMerge.unique_identifier}</UserDisplay.Subtitle>
                                        <UserDisplay.Subtitle>
                                            <DisplayI18n
                                                field="name"
                                                defaultValue={memberMerge.organisation?.organisation_name}
                                                i18n={memberMerge.organisation?.i18n}
                                            />
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                </UserDisplay>
                            }
                            <div>
                                <Row form>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='first_name' className='text-muted'><Translate id="form.fields.firstName" /> {!formik.values.unique_identifier && <Required />}</Label>
                                            <FormikInputText trim disabled={!!formik.values.unique_identifier} id='first_name' name='first_name' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='last_name' className='text-muted'><Translate id="form.fields.lastName" /> {!formik.values.unique_identifier && <Required />}</Label>
                                            <FormikInputText trim disabled={!!formik.values.unique_identifier} id='last_name' name='last_name' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='birthdate' className='text-muted'><Translate id='form.fields.dateOfBirth' /> </Label>
                                            <FormikDateTime
                                                id='birthdate'
                                                name='birthdate'
                                                timeFormat={false}
                                                dateFormat='YYYY-MM-DD'
                                                inputProps={{ disabled: !!formik.values.unique_identifier }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="border-top flex-grow-1" />
                                    <div className="px-3 font-medium small">
                                        <Translate id='members.profile.overview.memberFamilyRelations.add.or' />
                                    </div>
                                    <div className="border-top flex-grow-1" />
                                </div>
                                <FormGroup>
                                    <Label for="unique_identifier" className="text-muted"><Translate id='members.search.memberAdvanceSearch.filters.memberNumber' /> </Label>
                                    <FormikInputText
                                        trim
                                        helper={
                                            <div className='small'>
                                                <i className='text-primary mdi mdi-information-outline' /> <Translate id='members.profile.overview.memberFamilyRelations.add.memberNumber.hint' />
                                            </div>
                                        }
                                        name="unique_identifier"
                                        id="unique_identifier"
                                    />
                                </FormGroup>
                                <Collapse isOpen={formik.status.showResults}>
                                    <div className="border-top mt-4 pt-3">
                                        {getData().length > 0 && <div className="text-muted mb-2"><Translate id='misc.results' /></div>}
                                        <SpordleTableView />
                                    </div>
                                </Collapse>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" color="primary"><i className="mdi mdi-magnify" /> <Translate id='misc.search' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default MemberMergeSearch;