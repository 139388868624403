import { useContext, useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { Link } from 'react-router-dom';
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import StatusBadge from "../../../../components/badges/StatusBadge";
import CustomAnimatedIcon from "../../../../components/customAnimatedIcon/CustomAnimatedIcon";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../components/UserImg";
import { MembersContext } from "../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import MemberStatusIcons from "../components/MemberStatusIcons";
import { useFormikContext } from "formik";
import QuickViewButton from "../../../../components/quickView/QuickViewButton";

const MemberCard = ({ member, isSubmitting, memberStatus, updatePromises, ...props }) => {
    const formik = useFormikContext();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ status, setStatus ] = useState(null);
    const [ errorStatus, setErrorStatus ] = useState(null);

    // we have a blocked status state (fetched in MemberBulkConfirmModal) to display it at the end instead of refreshing the menmbers
    const [ blockedStatus, setBlockedStatus ] = useState(null);

    const membersContext = useContext(MembersContext);
    useEffect(() => {
        if(memberStatus)
            setBlockedStatus(memberStatus?.find((type) => type.name === 'BLOCKED') || false)
    }, [ memberStatus ])

    useEffect(() => {
        if(isSubmitting){
            setIsLoading(true);
            membersContext.patchMemberStatus(member.member_id, 'BLOCKED', formik.values.note)
                .then(() => {
                    setIsLoading(false);
                    setStatus('success');
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        setIsLoading(false);
                        setErrorStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                    }
                })
                .finally(() => {
                    updatePromises?.(member.member_id)
                })
        }
    }, [ isSubmitting ])

    return (
        <UserDisplay card className="d-flex w-100 mb-1 flex-wrap">
            <div className="align-items-center d-flex w-100">
                <UserDisplay.Container>
                    <UserImg
                        alt={member.first_name + ' ' + member.last_name}
                        abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                        src={member.picture?.full_path}
                        filePos={member.picture?.file_position}
                        width={60}
                    />
                </UserDisplay.Container>
                <UserDisplay.Container className="flex-grow-1">
                    <UserDisplay.Title className="d-flex">
                        <QuickViewButton member={member}>{member.first_name} {member.last_name}</QuickViewButton>
                        <MemberStatusIcons
                            member={{
                                ...member,
                                with_unconfirmed_address: blockedStatus && status === 'success' ? 0 : member.with_unconfirmed_address,
                            }}
                            className="d-flex justify-content-start ml-1"
                        />
                    </UserDisplay.Title>
                    <UserDisplay.Subtitle>
                        <Link to={`/members/profile/${member.member_id}`} className='mr-2'>
                            {member.unique_identifier &&
                                <>#{member.unique_identifier}<i className="mdi mdi-chevron-right ml-1" /></>
                            }
                        </Link>
                    </UserDisplay.Subtitle>


                    {errorStatus &&
                        <UserDisplay.Subtitle className="text-danger">
                            {errorStatus}
                        </UserDisplay.Subtitle>
                    }
                </UserDisplay.Container>
                <UserDisplay.Container>
                    <div>
                        {member.member_status &&
                            <div>
                                <StatusBadge color={blockedStatus && status === 'success' ? blockedStatus.color_code : member.member_status.color_code} status={status === 'success' ? blockedStatus : member.member_status} />
                            </div>
                        }
                    </div>
                </UserDisplay.Container>
                <UserDisplay.Container>
                    {isLoading &&
                        <Spinner color='primary' style={{ width: '25px', height: '25px', borderWidth: '1px' }} />
                    }
                    {((!isLoading && status)) &&
                        <CustomAnimatedIcon
                            id={'customStatusIcon-' + member.member_id}
                            size='25px'
                            icon={status === 'success' ? 'checkmark' : 'error'}
                            withCircle
                            className={status === 'success' ? 'text-success' : 'text-danger'}
                        />
                    }
                </UserDisplay.Container>
            </div>
        </UserDisplay>
    )
}

export default MemberCard