export default{
    "organization.settings.sidePanelOrganizationTeamTravelTypes.title" : "Informations de type de voyage",

    "organization.settings.sidePanelOrganizationTeamTravelTypes.status"          : "Statut",
    "organization.settings.sidePanelOrganizationTeamTravelTypes.status.active"   : "Actif",
    "organization.settings.sidePanelOrganizationTeamTravelTypes.status.inactive" : "Inactif",
    "organization.settings.sidePanelOrganizationTeamTravelTypes.status.required" : "Le statut est requis",

    "organization.settings.sidePanelOrganizationTeamTravelTypes.update.success.text" : "Type de voyage mis à jour",
    "organization.settings.sidePanelOrganizationTeamTravelTypes.update.fail.text"    : "Une erreur est survenue lors de la mise à jour du type de voyage, veuillez rafraichir la page et réessayer",

    // actions
    "organization.settings.sidePanelOrganizationTeamTravelTypes.actions.remove"         : "Retirer",
    "organization.settings.sidePanelOrganizationTeamTravelTypes.actions.remove.topText" : "Vous êtes sur le point de retirer ce type de voyage de votre organisation",

    // tabs
    "organization.settings.sidePanelOrganizationTeamTravelTypes.tabs.details" : "Détails",
}