/* eslint-disable react/no-array-index-key */
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import ApiErrorMsg from "../../../../components/formik/ApiErrorMsg";
import DisplayMember from "../../../../components/display/DisplayMember";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";

const ManagePotentialDuplicateSuccess = ({ getSplitProfiles }) => {
    const formik = useFormikContext();
    const { toKeep, toMergeList, toIgnoreList } = getSplitProfiles(formik.values);
    const fails = formik.status?.fails || [];

    if(!toKeep){
        return null;
    }

    return (
        <>
            <div className="mb-2"><Translate id="tasks.memberDuplicates.manage.mergedMember" /></div>
            <div className={stringBuilder({ "shadow-solid-success border-success": fails.length === 0 && toMergeList.length > 0 }, "mb-0 rounded-lg border overflow-hidden mb-3")}>
                <DisplayMember card withLink member={toKeep} className="mb-0 position-relative rounded-bottom-0 border-0">
                    <i className="h4 mb-0 mdi mdi-call-merge text-muted pl-2 ml-auto" />
                </DisplayMember>
                <ul className="text-left list-unstyled bg-light-inverse p-3 mb-0 border-top">
                    {toMergeList.length === 0 && (
                        <li className="text-muted text-center">
                            <Translate id="tasks.memberDuplicates.manage.noMergedMember" />
                        </li>
                    )}
                    {toMergeList.map((merged, i) => {
                        const fail = fails.find(({ member }) => member.member_id === merged.member_id)?.error;
                        const error = Array.isArray(fail) && fail.length === 1 ? fail[0] : fail;

                        return (
                            <li key={merged.member_id} className={stringBuilder({ "mt-1": i > 0, "border-danger": !!error }, "p-2 bg-white rounded border")}>
                                <div className="d-flex align-items-center">
                                    <span className="font-medium">
                                        {merged.first_name} {merged.last_name}
                                    </span>
                                    <small className="ml-1 text-muted">(#{merged.unique_identifier})</small>
                                </div>
                                {!!error &&
                                    <div className="small text-danger mt-1 bg-light-danger p-1 rounded">
                                        <span className="font-medium mr-1"><i className="mdi mdi-alert-outline text-danger mr-1" /><Translate id="tasks.memberDuplicates.manage.mergedMember.error" />:</span>
                                        {Array.isArray(error) ?
                                            <ul className="mb-0 pl-3">
                                                {error.map((err, i) => <li key={i}><ApiErrorMsg error={err} /></li>)}
                                            </ul>
                                            :
                                            <ApiErrorMsg error={error} />
                                        }
                                    </div>
                                }
                            </li>
                        )
                    })}
                </ul>
            </div>
            {toIgnoreList.length > 0 &&
                <div className="mb-3">
                    <div className="mb-2"><Translate id="tasks.memberDuplicates.manage.ignoredMember" /></div>
                    {toIgnoreList.map((ignored) => {
                        const fail = fails.find(({ member }) => member.member_id === ignored.member_id)?.error;
                        const error = Array.isArray(fail) && fail.length === 1 ? fail[0] : fail;

                        return (
                            <DisplayMember
                                key={ignored.member_id}
                                card withLink
                                member={ignored}
                                className={stringBuilder("mb-2 flex-wrap", { "border-danger": !!error })}
                                extra={{
                                    left: (
                                        <UserDisplay.Container className="ml-auto">
                                            <i className="mdi-call-split mdi h4 text-muted mb-0" />
                                        </UserDisplay.Container>
                                    ),
                                }}
                            >
                                {!!error &&
                                    <div className="mt-3 bg-light-danger text-danger flex-grow-1 rounded mb-n1 p-1 mx-n1 small">
                                        <span className="font-medium mr-1">
                                            <i className="mdi mdi-alert-outline mr-1" /><Translate id="tasks.memberDuplicates.manage.ignoredMember.error" />:
                                        </span>
                                        {Array.isArray(error) ?
                                            <ul className="mb-0 pl-2">
                                                {error.map((err, i) => <li key={i}><ApiErrorMsg error={err} /></li>)}
                                            </ul>
                                            :
                                            <ApiErrorMsg error={error} />
                                        }
                                    </div>
                                }
                            </DisplayMember>
                        )
                    })}
                </div>
            }
        </>
    )
}

export default ManagePotentialDuplicateSuccess;