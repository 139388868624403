import PropTypes from 'prop-types';
import ClinicCategory from './ClinicCategory';
import { DisplayI18n } from '../../helpers/i18nHelper';

const ClinicCategoryBuilder = ({ qualification, defaultColor, ...props }) => {
    return (
        <ClinicCategory {...props} color={isNaN(parseInt(qualification?.qualification_category?.color)) ? defaultColor : qualification.qualification_category?.color}>
            <DisplayI18n
                field='name'
                i18n={qualification?.qualification_category?.i18n}
                defaultValue={qualification?.qualification_category?.name}
            />
            <span className='mx-1'>-</span>
            <DisplayI18n
                field='name'
                i18n={qualification?.i18n}
                defaultValue={qualification?.name}
            />
            {!!qualification?.qualification_level &&
                <>
                    <span className='mx-1'>-</span>
                    <DisplayI18n
                        field='name'
                        i18n={qualification?.qualification_level?.i18n}
                        defaultValue={qualification?.qualification_level?.name}
                    />
                </>
            }
        </ClinicCategory>
    );
};

ClinicCategoryBuilder.propTypes = {
    qualification: PropTypes.object,
    defaultColor: PropTypes.oneOfType([
        PropTypes.string, // string must be parseIntable "3" is good. "hello" is not
        PropTypes.number,
    ]),
};

ClinicCategoryBuilder.defaultProps = {
    defaultColor: 0,
};

export default ClinicCategoryBuilder;