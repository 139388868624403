import SpordleTableProvider from "@spordle/datatables";
import { FormikInputText } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Formik } from "formik";
import { useContext } from "react";
import { Button, Col, FormGroup, Label } from "reactstrap";
import { TeamsContext } from "../../../../../../../../contexts/TeamsContext";
import { object, string } from "yup";
import TeamRosterSearchView from "../components/TeamRosterSearchView";

const TeamRosterSearchName = ({ getTableProps, preformatSubmit }) => {
    const { getAvailableTeamMembers } = useContext(TeamsContext);

    return (
        <SpordleTableProvider {...getTableProps(getAvailableTeamMembers)} id='roster-search-name'>
            {({ filterChange }) => (
                <Formik
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        unique_identifier: '',
                    }}
                    validationSchema={object().shape({
                        first_name: string(),
                        last_name: string(),
                        unique_identifier: string(),
                    })}
                    onSubmit={(values) => {
                        const newVal = preformatSubmit(values);

                        filterChange('search', newVal);
                    }}
                >
                    {() => (
                        <TeamRosterSearchView>
                            <Col sm="3">
                                <FormGroup>
                                    <Label for='first_name' className='text-muted'><Translate id='form.fields.firstName' /></Label>
                                    <FormikInputText id='first_name' name='first_name' />
                                </FormGroup>
                            </Col>
                            <Col sm="3">
                                <FormGroup>
                                    <Label for='last_name' className='text-muted'><Translate id='form.fields.lastName' /></Label>
                                    <FormikInputText id='last_name' name='last_name' />
                                </FormGroup>
                            </Col>
                            <Col sm="3">
                                <FormGroup>
                                    <Label for='unique_identifier' className='text-muted'><Translate id="form.fields.uniqueIdentifier" /></Label>
                                    <FormikInputText id='unique_identifier' name='unique_identifier' />
                                </FormGroup>
                            </Col>
                            <Col sm="3" className="align-self-end">
                                <FormGroup>
                                    <Button type="submit" block color="primary"><i className="ti-search" /> <Translate id='misc.search' /></Button>
                                </FormGroup>
                            </Col>
                        </TeamRosterSearchView>
                    )}
                </Formik>
            )}
        </SpordleTableProvider>
    )
}

export default TeamRosterSearchName;