import Translate from "@spordle/intl-elements";
import CollapsibleCard from "../../../../../../../components/collapsibleCard/CollapsibleCard"
import CancelAttendeeCard from "./CancelAttendeeCard"

const MultiCancelledAttendees = ({ valid, invalid }) => {

    return (
        <>
            {valid.length > 0 &&
                <CollapsibleCard
                    className="mb-3"
                    initOpen={valid.length <= 3}
                    title={<Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee.valid' values={{ count: valid.length }} />}
                    subtitle={<Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee.valid.subtitle' values={{ count: valid.length }} />}
                >
                    {valid.map((attendee, index) => (
                        <CancelAttendeeCard
                            attendee={attendee}
                            key={`valid-cancel-${attendee.clinic_attendee_id}`}
                            className={index === valid.length - 1 ? "mb-0" : "mb-2"}
                        />
                    ))}
                </CollapsibleCard>
            }
            {invalid.length > 0 &&
                <CollapsibleCard
                    error
                    title={<Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee.invalid' values={{ count: invalid.length }} />}
                    subtitle={<Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee.invalid.subtitle' values={{ count: invalid.length }} />}
                >
                    {invalid.map((attendee, index) => (
                        <CancelAttendeeCard
                            attendee={attendee}
                            key={`invalid-cancel-${attendee.clinic_attendee_id}`}
                            error={<span><Translate id={attendee.cancelled_at ? 'clinics.profile.attendees.sidepanel.actions.cancelAttendee.error.alreadyCancelled' : 'clinics.profile.attendees.sidepanel.actions.cancelAttendee.error.alreadyPassed'} /></span>}
                            className={index === invalid.length - 1 ? "mb-0" : "mb-2"}
                        />
                    ))}
                </CollapsibleCard>
            }
        </>
    )
}

export default MultiCancelledAttendees;