import TeamActiveRoster from './TeamActiveRoster/TeamActiveRoster';
import TeamStaff from './TeamStaff/TeamStaff';
import TeamInactiveRoster from './TeamInactiveRoster/TeamInactiveRoster';
import TeamAffiliatedPlayers from './TeamAffiliatedPlayers/TeamAffiliatedPlayers';
import TeamRosterFilters from './components/TeamRosterFilters';

const TeamRoster = ({ teamId, getLockedRoster, getLockedRosterDate, teamsSettings }) => {
    return (
        <>
            <TeamRosterFilters teamId={teamId} />
            <TeamActiveRoster
                teamId={teamId}
                playerLimit={teamsSettings?.maximum_number_players?.value || null}
                uniqueJerseySetting={teamsSettings?.unique_jersey_numbers?.value === "yes" && teamsSettings?.unique_jersey_numbers?.active == "1" && teamsSettings?.unique_jersey_numbers?.enforced == "1"}
                lockedRoster={getLockedRoster('ACTIVE')}
                lockedRosterDate={getLockedRosterDate('ACTIVE')}
            />
            <TeamAffiliatedPlayers
                teamId={teamId}
                playerLimit={teamsSettings?.maximum_affiliate_registrations?.value || null}
                uniqueJerseySetting={teamsSettings?.unique_jersey_numbers?.value === "yes" && teamsSettings?.unique_jersey_numbers?.active == "1" && teamsSettings?.unique_jersey_numbers?.enforced == "1"}
                lockedRoster={getLockedRoster('AFFILIATE')}
                lockedRosterDate={getLockedRosterDate('AFFILIATE')}
            />
            <TeamStaff
                teamId={teamId}
                lockedRoster={getLockedRoster('STAFF')}
                lockedRosterDate={getLockedRosterDate('STAFF')}
            />
            <TeamInactiveRoster
                teamId={teamId}
                playerLimit={teamsSettings?.maximum_released_registrations?.value || null}
                uniqueJerseySetting={teamsSettings?.unique_jersey_numbers?.value === "yes" && teamsSettings?.unique_jersey_numbers?.active == "1" && teamsSettings?.unique_jersey_numbers?.enforced == "1"}
                getLockedRoster={getLockedRoster}
                getLockedRosterDate={getLockedRosterDate}
            />
        </>
    );
}

export default TeamRoster;