import SubRouteRenderer from '../../components/subSidebar/SubRouteRenderer';
import catalogRoutes from '../../routes/routerRoutes/catalogRoutes';

const Catalog = () => {
    return (
        <div className={'page-content ml-0 px-0 pt-0'}>
            <SubRouteRenderer routes={catalogRoutes} />
        </div>
    );
}

export default Catalog;