import { stringBuilder } from "@spordle/helpers";
import { Col } from "reactstrap"
import IsGod from "../../../../../components/permissions/IsGod"
import EditFilter from "../manageFilters/EditFilter";
import EngineFilterSwitchInner from "./EngineFilterSwitchInner";
import styles from "../../../../../components/reports/ReportsStyle.module.scss";
import React from "react";

// this part of the filter checks for GOD PERMISSIONS if necessary, otherwise it's only function is to wrap the filter with it's Col
// this section also handles conditional rendering of the fiters
const EngineFilterSwitch = ({ formikRef, currentFilter, filterRefs, isOpenHandlers, customReportId, setCustomFormId, customFormId }) => {
    const col = currentFilter.parsedParams.col;
    const isBtn = currentFilter.type === "SEARCH";
    const shouldRender = (currentFilter.parsedParams.renderConditionally && currentFilter.parsedParams.renderConditionally?.every((code) => formikRef.current?.values[code])) || !currentFilter.parsedParams.renderConditionally

    const Tag = currentFilter.code === 'custom_form_id' ? React.Fragment : Col;

    if(currentFilter.parsedParams.componentProps.powerUserOnly && shouldRender){
        return (
            <IsGod
                tag={Tag}
                xs={col.xs || null} sm={col.sm || null}
                md={col.md || null} lg={col.lg || null}
                xl={col.xl || null}
                className={
                    stringBuilder(
                        currentFilter.parsedParams.componentProps.colClassName,
                        "position-relative",
                        { [styles.submitButtonsCol]: isBtn },
                    )
                }
            >
                {currentFilter.code !== 'custom_form_id' &&
                    <EditFilter currentFilter={currentFilter} />
                }
                <EngineFilterSwitchInner
                    formikRef={formikRef}
                    currentFilter={currentFilter}
                    filterRefs={filterRefs}
                    isOpenHandlers={isOpenHandlers}
                    customReportId={customReportId}
                    setCustomFormId={setCustomFormId}
                    customFormId={customFormId}
                />
            </IsGod>
        )
    }if(shouldRender){
        return (
            <Tag
                xs={col.xs || null} sm={col.sm || null}
                md={col.md || null} lg={col.lg || null}
                xl={col.xl || null}
                className={
                    stringBuilder(
                        currentFilter.parsedParams.componentProps.colClassName,
                        "position-relative",
                        { [styles.submitButtonsCol]: isBtn },
                    )
                }
            >
                {currentFilter.code !== 'custom_form_id' &&
                    <EditFilter currentFilter={currentFilter} />
                }
                <EngineFilterSwitchInner
                    formikRef={formikRef}
                    currentFilter={currentFilter}
                    filterRefs={filterRefs}
                    isOpenHandlers={isOpenHandlers}
                    customReportId={customReportId}
                    setCustomFormId={setCustomFormId}
                    customFormId={customFormId}
                />
            </Tag>
        )
    }
    return null

}

export default EngineFilterSwitch