export default{
    "settings.clinics.sidePanelClinicCategories.title" : "Category Information",

    "settings.clinics.sidePanelClinicCategories.color"           : "Color",
    "settings.clinics.sidePanelClinicCategories.status"          : "Status",
    "settings.clinics.sidePanelClinicCategories.status.active"   : "Active",
    "settings.clinics.sidePanelClinicCategories.status.inactive" : "Inactive",
    "settings.clinics.sidePanelClinicCategories.status.required" : "Status is required",

    "settings.clinics.sidePanelClinicCategories.update.success.text" : "Category has been updated",
    "settings.clinics.sidePanelClinicCategories.update.fail.text"    : "An error occurred updating the category, please refresh the page et try again",

    // actions
    "settings.clinics.sidePanelClinicCategories.actions.remove"         : "Remove",
    "settings.clinics.sidePanelClinicCategories.actions.remove.topText" : "You are about to remove this category from your organization",

    // tabs
    "settings.clinics.sidePanelClinicCategories.tabs.details" : "Details",
}