import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/contexts";
import { I18nContext } from "../../../../contexts/I18nContext";
import { LOCALES } from '../../../../i18n';

const LangSwitcherNav = () => {
    const { updateUserInfo, account } = useContext(AuthContext);
    const { locale, setLocale } = useContext(I18nContext);

    return (
        <li className="dropdown nav-item">
            <Tooltip
                withArrow
                label={<Translate id='header.langSwitch.title' />}
                zIndex={3000}
            >
                <div
                    className="nav-link clickable"
                    onClick={() => {
                        if(locale === 'fr-ca'){
                            setLocale(LOCALES.ENGLISH);
                            updateUserInfo({
                                ...account,
                                locale: 'en_US',
                                language_code: 'en',
                            })
                                .catch(console.error)
                        }else{
                            setLocale(LOCALES.FRENCH);
                            updateUserInfo({
                                ...account,
                                locale: 'fr_CA',
                                language_code: 'fr',
                            })
                                .catch(console.error)
                        }
                    }}
                >
                    <span className="font-medium">{locale === 'fr-ca' ? 'EN' : 'FR'}</span>
                </div>
            </Tooltip>
        </li>
    );
}

export default LangSwitcherNav;