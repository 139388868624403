import React from "react";
import { Link } from "react-router-dom";
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../components/UserImg";
import TeamSearchSidepanelInfo from "./TeamSearchSidepanelInfo";
import TeamSearchSidepanelContact from "./TeamSearchSidepanelContact";
import TeamSearchSidepanelMulti from "./TeamSearchSidepanelMulti";
import SidePanel from "../../../../components/sidePanel/SidePanel";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";

// Contexts
import withContexts from "../../../../helpers/withContexts";
import { TeamsContext } from "../../../../contexts/TeamsContext";
import { DisplayCategory, exportTeamsToCSV, APPROVED, PENDING, REJECTED, DRAFT, CANCEL } from "../../TeamHelpers";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import TeamApprobationModal from "../../profile/TeamHeader/TeamApprobationModal";
import { fail } from '@spordle/toasts';
import TeamSearchSidepanelComments from "./TeamSearchSidepanelComments";
import { ReportsContext } from "../../../../contexts/ReportsContext";
import { I18nContext } from "../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { triggerDownload } from "../../../../components/uploader/uploadHelpers";
import { RolesContext } from "../../../../contexts/RolesContext";
import moment from "moment";
import ValidateMemberModal from "../../../../components/validateMember/ValidateMemberModal";
import { PeriodsContext } from "../../../../contexts/contexts";
import InlineCopy from "../../../../components/inlineCopy/InlineCopy";

class TeamSearchSidepanel extends React.Component{

    constructor(props){
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
            isLoading: false,
            approbationModal: false, // PENDING, APPROVED, REJECTED, CANCEL, DRAFT
            validateMember: false,

            team: props.selectedRows[0],
        };
    }

    toggleValidateMember = () => {
        this.setState((prevState) => ({ validateMember: !prevState.validateMember }))
    }

    toggle(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    /**
     * Deletes selected team(s)
     * @returns {Promise}
     */
    handleDelete = () => {
        return Promise.all([
            ...this.props.selectedRows.map((team) => (
                this.props.TeamsContext.deleteTeam(team.team_id)
                    .then(() => {
                        this.props.tableRef.deleteRow(team.team_id)
                    }).catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })
            )),
        ])
            .then(() => {
                this.props.toggle();
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e)){
                    console.error(e);
                    fail();
                }
            });
    }

    /**
     * Export to CSV selected team(s)
     */
    handleExport = () => {
        exportTeamsToCSV(this.props.selectedRows, this.props.formatMessage);
    }

    // handles PDF exporting
    handleRosterExportPDF = () => {
        const row = this.state.team;
        const reportType = 'team_rosters';
        // gets all report types
        this.props.ReportsContext.getReports({ identity_role_id: this.props.IdentityRolesContext.identity_role_id })
            .then(async(report_type) => {
                // grabbing the correct report type ID with the find
                const reportTypeId = report_type.find((type) => type.code === reportType).report_id;
                // getting the report itself for the line count
                const reports = await this.props.ReportsContext.getTeamRosterReport({ team_id: row.team_id });
                // creates the exportable report (adds to library and creates the download link as well as generating the report)

                if(reports.report_count > 0){
                    const data = await this.props.ReportsContext.createExportableReport({
                        organisation_id: this.props.OrganizationContext.organisation_id,
                        total_count: 1,
                        language_code: this.props.I18nContext.getGenericLocale(),
                        report_id: reportTypeId,
                        requested_by: this.props.IdentityRolesContext.identity.identity_id,
                        request_parameter: JSON.stringify({ team_id: row.team_id, report_type: 'PDF' }),
                    }, true).catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })

                    if(data.download_link){
                        triggerDownload(data.download_link);
                    }
                }else{
                    fail({ msg: 'teams.teamSearch.export.emptyTeams' });
                }

            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    /*--------------------------------------------------------------------------------*/
    /* Locked Roster                                                                  */
    /* The roster may be locked by roster section.                                    */
    /* The organization settings decide the roster lock dates. Usually set by Federation */
    /* There could also be lock dates set in the teams settings. Ignore org lock dates if team settings lock dates are set. */
    /* The teams may have specific lock dates. Ignore org lock dates and teams settings lock dates if team lock dates are set. */
    /* There is also a component permission bypass_lock_roster_date ADD EDIT DELETE. */
    /*--------------------------------------------------------------------------------*/
    getLockedRoster = (rosterType) => {
        const team = this.state.team;
        const dateIsValid = (date) => !!date && moment(date).isSameOrBefore()
        const orgSettingIsValid = (orgSetting) => !orgSetting.period || orgSetting.period?.period_id === this.props.PeriodsContext.selectedPeriod.period_id

        // One ore the other, if team is set, ignore organization
        switch (rosterType){
            case 'ACTIVE':
                if(dateIsValid(team.lock_roster_date))
                    return true
                else if(!team.lock_roster_date && dateIsValid(team.settings?.lock_team_active_roster_date?.value))
                    return true
                else if(!team.lock_roster_date && !team.settings?.lock_roster_date?.value && dateIsValid(this.props.OrganizationContext.settings.lock_roster_date.value) && orgSettingIsValid(this.props.OrganizationContext.settings.lock_roster_date))
                    return true
                break;
            case 'AFFILIATE':
                if(dateIsValid(team.lock_affiliate_roster_date))
                    return true
                else if(!team.lock_affiliate_roster_date && dateIsValid(team.settings?.lock_team_affiliate_roster_date?.value))
                    return true
                else if(!team.lock_affiliate_roster_date && !team.settings?.lock_team_affiliate_roster_date?.value && dateIsValid(this.props.OrganizationContext.settings.lock_affiliate_roster_date.value) && orgSettingIsValid(this.props.OrganizationContext.settings.lock_affiliate_roster_date))
                    return true
                break;
            case 'STAFF':
                if(dateIsValid(team.lock_staff_roster_date))
                    return true
                else if(!team.lock_staff_roster_date && dateIsValid(this.props.OrganizationContext.settings.lock_staff_roster_date.value) && orgSettingIsValid(this.props.OrganizationContext.settings.lock_staff_roster_date))
                    return true
                break;
            default:
                return false
        }
    }

    getLockedRosterDate = (rosterType) => {
        const team = this.state.team;

        switch (rosterType){
            case 'ACTIVE':
                return team.lock_roster_date ? team.lock_roster_date : team.settings?.lock_team_active_roster_date?.value ? team.settings?.lock_team_active_roster_date?.value : this.props.OrganizationContext.settings.lock_roster_date.value ? this.props.OrganizationContext.settings.lock_roster_date.value : null
            case 'AFFILIATE':
                return team.lock_affiliate_roster_date ? team.lock_affiliate_roster_date : team.settings?.lock_team_affiliate_roster_date?.value ? team.settings?.lock_team_affiliate_roster_date?.value : this.props.OrganizationContext.settings.lock_affiliate_roster_date.value ? this.props.OrganizationContext.settings.lock_affiliate_roster_date.value : null
            case 'STAFF':
                return team.lock_staff_roster_date ? team.lock_staff_roster_date : this.props.OrganizationContext.settings.lock_staff_roster_date.value ? this.props.OrganizationContext.settings.lock_staff_roster_date.value : null
            default:
                return null;
        }
    }

    componentDidMount(){
        this.getTeam()
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0].team_id !== this.props.selectedRows[0].team_id){
            this.setState((prev) => ({
                ...prev,
                isLoading: true,
                team: this.props.selectedRows[0],
            }))
            this.getTeam()
        }
    }

    getTeam = () => {
        // additional data required for the team quick cards
        Promise.all([
            this.props.TeamsContext.getTeam(this.props.selectedRows[0].team_id, true, { components: 'contact,address' }),
            this.props.TeamsContext.getTeamSettings(this.props.selectedRows[0].team_id),
        ])
            .then(([ teamInfo, settings ]) => {
                let filteredSettings = {}
                for(const key in settings){
                    if(Object.hasOwnProperty.call(settings, key)){
                        if(settings[key].active == '1' || key === 'qualification_rules'){
                            filteredSettings[key] = settings[key];
                        }
                    }
                }
                if(filteredSettings.qualification_rules && filteredSettings.qualification_rules.length > 0){
                    filteredSettings = {
                        ...filteredSettings,
                        qualification_rules: filteredSettings.qualification_rules?.map((position) => ({
                            ...position,
                            ...position.qualifications.reduce((object, qualification) => {
                                if(qualification.equivalence && qualification.equivalence.length > 0){
                                    object.oneMandatory.push([
                                        qualification,
                                        ...qualification.equivalence.map((eq) => ({
                                            ...qualification,
                                            ...eq,
                                        })),
                                    ]) // we push an array in an array because there could be multiple "one mandatory" sets for 1 position
                                }else{
                                    object.allMandatory.push(qualification)
                                }
                                return object
                            }, { allMandatory: [], oneMandatory: [] }),
                        })),
                    }
                }

                this.setState((prev) => ({
                    ...prev,
                    isLoading: false,
                    team: {
                        ...prev.team,
                        ...teamInfo,
                        settings: filteredSettings,
                    },
                }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    fail({ msg: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />, skipMsgTranslate: true })
                    console.error(error)
                }
            })
    }

    render(){
        const data = this.state.team;
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                {this.props.selectedRows.length <= 1 ?
                    <>
                        <TeamApprobationModal
                            isOpen={!!this.state.approbationModal}
                            toggle={() => {
                                this.setState(() => ({ approbationModal: false }));
                            }}
                            action={this.state.approbationModal}
                            onAction={(action) => {
                                //this.props.syncRows(this.props.createNewValues({ team_status: this.props.status.find((s) => (s.name.toLowerCase() == action.toLowerCase() || s.name.toLowerCase().includes(action.toLowerCase()))) }));
                                this.getTeam()
                            }}
                            team={this.state.team}
                        />
                        <ValidateMemberModal
                            isOpen={this.state.validateMember}
                            toggle={this.toggleValidateMember}
                            team={this.state.team}
                        />
                        <SidePanel.Header noBorder>
                            <div className='align-items-center d-flex mb-3'>
                                <SidePanel.ToggleButton />
                                <Link className="mr-2" to={{ pathname: `/teams/profile/${data.team_id}`, data: data }}>
                                    <Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" />
                                </Link>
                                <SidePanel.ActionsMenu id={'teamSearch-actions-btn'}>
                                    <SidePanel.MenuAction
                                        id={'teamSearch-actions-validate'}
                                        onClick={this.toggleValidateMember}
                                    >
                                        <Translate id='components.validateMember.title' />
                                    </SidePanel.MenuAction>
                                    <SidePanel.MenuAction divider />
                                    {(!this.getLockedRoster('ACTIVE') || this.props.RolesContext.canDoAction('EDIT', 'teams', 'bypass_lock_roster_date')) &&
                                    <>
                                        {/* Submit for approval */}
                                        {(!data.team_status?.name || data.team_status?.name?.toUpperCase().includes(DRAFT)) && // If status is null (draft)
                                            <SidePanel.MenuAction
                                                action="ADD"
                                                componentCode="teams"
                                                componentPermissionCode="submit_team_approval"
                                                skipAccess
                                                id={'teamSearch-actions-submit-approval'}
                                                onClick={() => this.setState(() => ({ approbationModal: PENDING }))}
                                            >
                                                <Translate id='teams.teamSearch.sideTable.actionsMenu.submit' />
                                            </SidePanel.MenuAction>
                                        }
                                        {/* Approve team */}
                                        { data.team_status?.name?.toUpperCase().includes(PENDING) && // Pending: Waiting for approval
                                            <SidePanel.MenuAction
                                                action="EDIT"
                                                componentCode="teams"
                                                componentPermissionCode="teams_approbation"
                                                skipAccess
                                                id={'teamSearch-actions-approve'}
                                                onClick={() => this.setState(() => ({ approbationModal: APPROVED }))}
                                            >
                                                <Translate id='teams.teamSearch.sideTable.actionsMenu.approve' />
                                            </SidePanel.MenuAction>
                                        }
                                        {/* Reject */}
                                        { (data.team_status?.name?.toUpperCase().includes(PENDING) || data.team_status?.name?.toUpperCase() === APPROVED) && // Reject only if has been submitted for approval or is approved
                                            <SidePanel.MenuAction
                                                action="EDIT"
                                                componentCode="teams"
                                                componentPermissionCode="teams_approbation"
                                                skipAccess
                                                id={'teamSearch-actions-reject'}
                                                onClick={() => this.setState(() => ({ approbationModal: REJECTED }))}
                                            >
                                                <Translate id='teams.teamSearch.sideTable.actionsMenu.reject' />
                                            </SidePanel.MenuAction>
                                        }
                                        {/* Cancel Approbation */}
                                        {(data.team_status?.name && !data.team_status?.name?.toUpperCase().includes(DRAFT)) && // Cancel only if has been submitted for approval or is approved
                                            <SidePanel.MenuAction
                                                action="ADD"
                                                componentCode="teams"
                                                componentPermissionCode="submit_team_approval"
                                                skipAccess
                                                id={'teamSearch-actions-cancel-approval'}
                                                onClick={() => this.setState(() => ({ approbationModal: CANCEL }))}
                                            >
                                                <Translate id='teams.teamSearch.sideTable.actionsMenu.cancel' />
                                            </SidePanel.MenuAction>
                                        }

                                        <SidePanel.MenuDelete
                                            id={'teamSearch-actions-delete-team'}
                                            action="DELETE"
                                            componentCode="teams"
                                            componentPermissionCode="teams_profile"
                                            modalTitle={
                                                <DisplayI18n
                                                    field="name"
                                                    i18n={data.i18n}
                                                    defaultValue={data.name}
                                                />
                                            }
                                            translateModalMsg
                                            modalMsg='teams.teamSearch.sideTable.delete.msg'
                                            onConfirm={this.handleDelete}
                                        />
                                        <SidePanel.MenuAction divider />
                                    </>
                                    }

                                    <SidePanel.MenuAction
                                        id={'teamSearch-actions-pdf-export'}
                                        onClick={this.handleRosterExportPDF}
                                    >
                                        <i className='fas fa-file-pdf mr-1' /><Translate id='teams.profile.roster.button.export.pdf' />
                                    </SidePanel.MenuAction>
                                </SidePanel.ActionsMenu>
                            </div>
                            <div className='mb-3'>
                                <UserDisplay>
                                    <UserDisplay.Container>
                                        <UserImg
                                            abbr={data.abbreviation || data.short_name || data.name}
                                            alt={data.name}
                                            filePos={data.logo?.file_position}
                                            src={data.logo?.full_path}
                                            width={60}
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title className="h4 mb-0">{data.name}</UserDisplay.Title>
                                        {data.unique_identifier &&
                                            <div className="mb-2 d-inline-flex">
                                                <div className="mr-4 d-flex align-items-center">
                                                    <div className="font-medium">
                                                        {data.unique_identifier ?
                                                            <InlineCopy tag='div' toCopy={data.unique_identifier}>
                                                                <Link className="text-nowrap" to={{ pathname: `/teams/profile/${data.team_id}`, data: data }}>#{data.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></Link>
                                                            </InlineCopy>
                                                            :
                                                            '-'
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="font-medium text-dark">
                                            <DisplayCategory category={data.team_category} />
                                        </div>
                                        <div className="font-medium text-dark">
                                            {data.team_category?.gender &&
                                                <Translate id={`form.fields.gender.${data.team_category.gender.toLowerCase()}`} />
                                            }
                                        </div>
                                    </UserDisplay.Container>
                                </UserDisplay>
                            </div>
                        </SidePanel.Header>
                        <Nav tabs>
                            <NavItem className="text-center" style={{ width: '33%' }}>
                                <NavLink
                                    className={stringBuilder({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggle('1'); }}
                                >
                                    <Translate id='form.fields.general' />
                                </NavLink>
                            </NavItem>
                            <NavItem className="text-center" style={{ width: '33%' }}>
                                <NavLink
                                    className={stringBuilder({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggle('2'); }}
                                >
                                    <Translate id='teams.teamSearch.sideTable.label.contactInfo' />
                                </NavLink>
                            </NavItem>
                            <NavItem className="text-center" style={{ width: '34%' }}>
                                <NavLink
                                    className={stringBuilder({ active: this.state.activeTab === '3' })}
                                    onClick={() => { this.toggle('3'); }}
                                >
                                    <Translate id='teams.teamSearch.sideTable.label.comments' />
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1" className="p-3">
                                <TeamSearchSidepanelInfo team={data} from='teams' getLockedRosterDate={this.getLockedRosterDate} getLockedRoster={this.getLockedRoster} />
                            </TabPane>
                            <TabPane tabId="2" className="p-3">
                                { this.state.activeTab == "2" &&
                                    // Avoids useless api calls
                                    <TeamSearchSidepanelContact team={data} />
                                }
                            </TabPane>
                            <TabPane tabId="3" className="p-3">
                                { this.state.activeTab == "3" &&
                                    <TeamSearchSidepanelComments team={data} />
                                }
                            </TabPane>
                        </TabContent>
                    </>
                    :
                    <TeamSearchSidepanelMulti
                        handleExport={this.handleExport}
                        handleDelete={this.handleDelete}
                        teams={this.props.selectedRows}
                        {...this.props}
                    />
                }
            </OverlayLoader>
        )
    }
}

export default withContexts(TeamsContext, ReportsContext, I18nContext, RolesContext, IdentityRolesContext, OrganizationContext, PeriodsContext)(TeamSearchSidepanel);
