import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Badge,
    Collapse
} from "reactstrap";
import Translate from '@spordle/intl-elements';

import Svg from '../../../../../components/Svg';
import { displayI18n, DisplayI18n } from '../../../../../helpers/i18nHelper';
import moment from 'moment';

import { NotificationsContext } from '../../../../../contexts/NotificationsContext';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { removeHTML } from '../../../../../helpers/helper';
import isValidUUID from '../../../../../helpers/isValidUUID';
import { Tooltip } from '@mantine/core';

const OrganizationNotification = ({ notif, isClearing, isLoading, toggle, hideNotif, setRead }) => {
    const history = useHistory();

    const { getGenericLocale } = useContext(I18nContext)
    const notificationsContext = useContext(NotificationsContext)

    const [ isRead, setIsRead ] = useState(!!notif?.read_by);
    const [ isDeleted, setIsDeleted ] = useState(false);

    const markAsRead = (notif) => {
        if(!isRead){
            notificationsContext.updateMarkAsRead(notif.notification_id)
                .then(() => {
                    setIsRead(true)
                    setRead()
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
    }

    const deleteNotif = (notif) => {
        setIsDeleted(true)
        if(!isDeleted){
            notificationsContext.deleteNotification(notif.notification_id)
                .then(() => {
                    hideNotif()
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
        }
    }

    const getNotifDate = (notif) => {
        const days = moment().diff(moment(notif.created_at), 'days')
        const hours = moment().diff(moment(notif.created_at), 'hours')
        if(days > 0)
            return <Translate id="misc.daysAgo" values={{ days: days }} />
        return <Translate id="misc.hoursAgo" values={{ hours: hours }} />
    }

    const getNotificationIcon = (notif) => {
        switch (notif.notification_type?.code){
        // Organisation
            case 'organisation-user-expired':
                return 'organisation'

                // Clinics
            case 'clinic-open':
            case 'clinic-close':
            case 'clinic-full':
                return 'clinics';

            // TEAMS
            case 'team_status_changed':
                return 'teams'

                // Members
            case 'member_address_change':
            case 'member-declined-payment':
            case 'member-crc-expired':
            case 'member_transfer_comment':
            case 'member-transfer':
            case 'member_transfer_update_mail':
            case 'create_member_address':
            case 'new_unconfirmed_member_created':
            case 'delete_member_address':
            case 'installment-failed':
            case 'installment-cancelled':
            case 'installment-new-date':
            case 'qualification-expiration':
            case 'new_unconfirmed_member_address':
            case 'member_with_other_birth_country':
                return 'members'

                // Online stores
            case 'online-store-open':
            case 'online-store-close':
                return 'onlineStore'; //

                // Registrations
            case 'missing_registration_organisation_setting':
            case 'registration-full':
                return 'catalog';

            case 'report_declined':
            case 'report_ready':
                return 'reports';

            default:
                return 'communications';
        }
    }

    const redirectToNotification = (notif) => {
        const parameters = JSON.parse(notif.url_parameter);

        if(parameters.params.member_id && parameters.params.invoice_number && isValidUUID(parameters.params.member_id)) // installment-failed, member-declined-payment, installment-cancelled, installment-new-date
            history.push(`/members/profile/${parameters.params.member_id}/transactions?search=${parameters.params.invoice_number}`);

        else if(parameters.params.member_id && parameters.params.crc_id && isValidUUID(parameters.params.member_id)) // member-crc-expired
            history.push(`/members/profile/${parameters.params.member_id}/history?crc-search=${parameters.params.crc_id}`);

        else if(parameters.params.qualification_id && parameters.params.member_id && isValidUUID(parameters.params.member_id)) // qualification-expiration
            history.push(`/members/profile/${parameters.params.member_id}/qualifications?search=${parameters.params.qualification_id}`);

        else if(parameters.params.member_transfer_id) // member-transfer
            history.push(`/tasks/transfer?search=${parameters.params.member_transfer_id}`);

        else if(parameters.params.clinic_id)
            history.push(`/clinics/profile/${parameters.params.clinic_id}`); // 'clinic-open' 'clinic-close': 'clinic-full':

        else if(parameters.params.team_id)
            history.push(`/teams/profile/${parameters.params.team_id}`); // 'team_status_changed,

        else if(parameters.params.identity_id) // organisation-user-expired
            history.push(`/organization/users?search=${parameters.params.identity_id}`);

        else if(parameters.params.organisation_id) // organisation-user-expired, missing_registration_organisation_setting
            history.push(`/organization/overview`);

        else if(parameters.params.online_store_id) // online-store-open, online-store-close
            history.push(`/onlineregistration/profile/${parameters.params.online_store_id}`);

        else if(parameters.params.registration_fee_id) //registration-full
            history.push(`/catalog/registrationfees?search=${parameters.params.registration_fee_id}`);

        else if(parameters.params.request_report_id) // report_ready, report_declined
            history.push(`/reports/dashboard?category=exports&request_report_id=${parameters.params.request_report_id}`);

        else if(parameters.params.member_id && isValidUUID(parameters.params.member_id)) // new_unconfirmed_member_created, new_unconfirmed_member_address, member_with_other_birth_country
            history.push(`/members/profile/${parameters.params.member_id}`);

        else if( // these cases don't receive any url_parameter therefore we redirect using the notification code
            notif.notification_type?.code === 'supervision_grassroot'
            || notif.notification_type?.code === 'supervision_development'
            || notif.notification_type?.code === 'supervision_high_performance'
        ) // supervision_grassroot, supervision_development, supervision_high_performance
            history.push(`/tasks/officialsupervision`);

        else console.error('Can\'t redirect. Missing url_parameter.')

        toggle?.();

    }

    return (
        <>
            {!isLoading ?
                <Collapse isOpen={!isDeleted} className="w-100">
                    <div
                        className="list-group-item list-group-item-action position-static notification-item w-100 is-new p-3 overflow-hidden"
                        onClick={(e) => {
                            if(e.target.nodeName !== "BUTTON"){
                                markAsRead(notif);
                                redirectToNotification(notif);
                            }
                        }}
                    >
                        <Badge color="light" className="round border bg-primary-light text-primary d-flex align-items-center">
                            <Svg icon={getNotificationIcon(notif)} />
                        </Badge>
                        <div className="pl-3 flex-grow-1">
                            <div className="h5 font-bold mb-0"><DisplayI18n field='title' defaultValue={notif.title} i18n={notif.i18n} /></div>
                            <p className="small mb-0 text-dark text-wrap notification-description">
                                {removeHTML(displayI18n('description', notif.i18n, notif.description, getGenericLocale())) }
                            </p>
                            <span className="text-muted small">{getNotifDate(notif)}</span>
                        </div>
                        <div className="align-self-start d-flex align-items-center">
                            <Tooltip zIndex={3000} withArrow label={<Translate id='header.notifications.item.tip.clear' />}>
                                <button
                                    type='button'
                                    disabled={isClearing}
                                    className="notification-delete mr-1"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        deleteNotif(notif);
                                    }}
                                >
                                    <i className="mdi mdi-close" />
                                </button>
                            </Tooltip>
                            <Tooltip zIndex={3000} withArrow label={<Translate id='header.notifications.item.tip.read' />}>
                                <button
                                    type='button'
                                    disabled={isClearing}
                                    className={`notification-read ${isRead ? 'invisible' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        markAsRead(notif);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </Collapse>
                :
                <div className="list-group-item notification-item p-3">
                    <Badge color="primary" className="notification-loading-info round d-flex align-items-center" />
                    <div className="pl-3 flex-grow-1">
                        <div style={{ height: 40 }} className="notification-loading-info mb-1" />
                        <div style={{ height: 15 }} className="notification-loading-info small" />
                    </div>
                </div>
            }
        </>

    )
}

export default OrganizationNotification;