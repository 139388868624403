import { useDisclosure } from "@mantine/hooks";
import Translate from "@spordle/intl-elements";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import NotifBadge from "../notifBadge/NotifBadge";
import { Link } from "react-router-dom";
import CommunicationsList from "./CommunicationsList";
import { useContext } from "react";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { MessagesContext } from "../../../../contexts/MessagesContext";
import useSWR from "swr";
import { RolesContext } from "../../../../contexts/RolesContext";
import { stringBuilder } from "@spordle/helpers";
import { Tooltip } from "@mantine/core";

const MessagesNav = () => {
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const canSeeCommuncations = canDoAction("READ", "communications", "communications_manage");

    return canSeeCommuncations || isGod() ? <MessagesNavInner onlyGod={isGod() && !canSeeCommuncations} /> : null
}

const MessagesNavInner = ({ onlyGod }) => {
    const { identity } = useContext(IdentityRolesContext);
    const [ isOpen, isOpenHandlers ] = useDisclosure(false);
    const { getReceivedMessages } = useContext(MessagesContext);
    const { data: messages, mutate } = useSWR(
        [ "getReceivedCommunications", identity.identity_id ],
        () => getReceivedMessages({
            identity_id: identity.identity_id,
            offset: 0,
            limit: 10,
            sort: "-created_at",
        })
            .then((data) => data.communication_messages),
    )
    const count = (messages || []).filter((message) => message.is_read == 0).length;

    return (
        <Dropdown toggle={isOpenHandlers.toggle} isOpen={isOpen} nav inNavbar>
            <DropdownToggle className="position-relative" onClick={isOpenHandlers.toggle} nav caret>
                <div>
                    <Tooltip
                        withArrow
                        label={<Translate id='header.notifications.tabs.communication' />}
                        zIndex={3000}
                    >
                        <i className={stringBuilder(`font-18 mdi mdi-email-outline`, { "text-purple": onlyGod })} />
                    </Tooltip>
                </div>
                {count > 0 && <NotifBadge>{count > 9 ? "9+" : count}</NotifBadge>}
            </DropdownToggle>
            <DropdownMenu right className="mailbox">
                <div className="h4 font-medium mb-0 px-3 py-3 border-bottom"><Translate id="header.communications.title" /> {count > 0 && <span className="font-normal">({count})</span>}</div>
                <CommunicationsList close={isOpenHandlers.close} mutate={mutate} messages={messages} />
                <div className="nav-link text-center ">
                    <Link onClick={isOpenHandlers.close} to="/communications">
                        <Translate id="header.notifications.comms.goTo" />
                    </Link>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
}

export default MessagesNav;