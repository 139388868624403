import Translate from '@spordle/intl-elements'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import QuickViewButton from '../../../../components/quickView/QuickViewButton'
import UserDisplay from '../../../../components/userDisplay/UserDisplay'
import * as Sentry from "@sentry/react";
import { useContext } from 'react'
import { QuickViewContext } from '../../../../contexts/QuickViewContext'

const TeamSettingWarningsMembers = ({ warnings, canSeeMember }) => {

    const quickViewContext = useContext(QuickViewContext)

    const openQuickView = (e, theMember) => {
        if(quickViewContext.iframeIsLoaded){
            Sentry.addBreadcrumb({
                type: 'info',
                message: 'Quickview member opened',
                level: Sentry.Severity.Log,
                category: 'QuickView',
                data: {
                    member: theMember,
                },
            })
            e.stopPropagation();
            quickViewContext.openMemberQuickView(theMember);
        }
    }


    return (
        <Row form className='py-2'>
            {warnings.map((warning) => (
                <Col key={warning.code + warning.resource?.member_id} md='6' lg='4' className='mb-2'>
                    <UserDisplay card className='d-flex h-100 mb-0' style={{ minWidth: '100%' }}>
                        {warning.resource &&
                            <UserDisplay.Container>
                                <UserDisplay.Title className='text-break d-flex'>
                                    <QuickViewButton member={warning.resource}>
                                        {warning.resource.first_name} {warning.resource.last_name}
                                    </QuickViewButton>
                                </UserDisplay.Title>
                                <UserDisplay.Subtitle>
                                    <div>
                                        {canSeeMember ?
                                            <div className='d-flex'>
                                                <Link to={`/members/profile/${warning.resource.member_id}`}>
                                                #{warning.resource.unique_identifier}<i className='mdi mdi-chevron-right ml-1' />
                                                </Link>
                                            </div>
                                            :
                                            <>#{warning.resource.unique_identifier}</>
                                        }
                                    </div>
                                    <div><Translate id={`teams.profile.settings.error.${warning.code}.raw`} /></div>
                                </UserDisplay.Subtitle>
                            </UserDisplay.Container>
                        }
                    </UserDisplay>
                </Col>
            ))}
            {canSeeMember && warnings.length > 1 &&
                <Col key={'quickViewCustomCard'} md='6' lg='4' className='mb-2' onClick={(e) => openQuickView(e, warnings.map((w) => (w.resource)))}>
                    <UserDisplay card className='d-flex h-100 mb-0 clickable' style={{ minWidth: '100%' }}>
                        <UserDisplay.Container>
                            <UserDisplay.Title className='text-break d-flex'>
                                <Translate id='teams.profile.settings.open.all.warning.quickview' />
                                <i className='mdi mdi-loupe ml-1' />
                            </UserDisplay.Title>
                            <UserDisplay.Subtitle>
                                <div><Translate id='teams.profile.settings.open.all.warning.quickview.tooltip' /></div>
                                <div>{warnings.length} <Translate id='teams.profile.settings.open.all.warning.number' /></div>
                            </UserDisplay.Subtitle>
                        </UserDisplay.Container>
                    </UserDisplay>
                </Col>
            }
        </Row>
    )
}

export default TeamSettingWarningsMembers
