import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { Button, Card } from "reactstrap";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import SpordlePanelTable from "../../../../components/sidePanel/SpordlePanel";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import { PeriodsContext } from "../../../../contexts/contexts";
import { I18nContext } from "../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { OfficiatingCoachContext } from "../../../../contexts/OfficiatingCoachContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { RolesContext } from "../../../../contexts/RolesContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { PeriodChangeHandler } from "../../../../helpers/periodHelper";
import AddOfficiatingCoachModal from "./addModal/AddOfficiatingCoachModal";
import OfficiatingCoachesFilter from "./OfficiatingCoachesFilters";
import OfficialCoachesSidePanel from "./sidePanel/OfficiatingCoachesSidePanel";

const OfficiatingCoachesList = ({ supervisionTypes }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { organisation_id } = useContext(OrganizationContext);
    const { selectedPeriod } = useContext(PeriodsContext);
    const { getAllOfficiatingCoaches } = useContext(OfficiatingCoachContext);
    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const canEdit = canDoAction("EDIT", "members", "member_national_officiating_coach");
    const canSeeNational = canDoAction("READ", "members", "member_national_officiating_coach") || isGod();

    const getColumns = () => {
        const theColumns = [
            {
                label: <Translate id="officialSupervision.member.label" />,
                key: 'name',
                sortKey: 'first_name',
                fallbackSortKey: 'last_name',
                sortable: true,
            },
            {
                label: <Translate id="officialSupervision.addSupervision.org.label" />,
                key: 'organisation',
                sortable: true,
                sortKey: `organisation.i18n.${getGenericLocale()}.name`,
                fallbackSortKey: 'organisation.organisation_name',
            },
            {
                label: <Translate id="components.assignOfficiatingPermissionsModal.supervisionTypes" />,
                key: 'supervision_type',
            },
        ];

        if(canSeeNational){
            theColumns.push({
                label: <Translate id="officialSupervision.nationalCoach.label" />,
                key: 'is_national',
                sortable: true,
                sortKey: 'member_officiating_coach.national_officiating_coach',
            })
        }

        return theColumns;
    }

    return (
        <SpordlePanelTable
            id='TasksDiscriminationTable'
            dataIndex='member_id'
            allowOutsideClick
            sidePanel={(sProps) => <OfficialCoachesSidePanel isReadOnly={!canEdit} supervisionTypes={supervisionTypes} {...sProps} />}
            table={(panelProps) => (
                <SpordleTableProvider
                    id='officialSupervision_table'
                    tableHover
                    ref={panelProps.spordleTableRef}
                    bordered striped
                    clickable
                    pagination={10}
                    dataIndex='member_id'
                    initFilter={{
                        types: [],
                    }}
                    searchKeys={[
                        'first_name',
                        'last_name',
                        'unique_identifier',
                        `organisation.organisation_name`,
                        `organisation.i18n.${getGenericLocale()}.name`,
                        `member_officiating_coach.supervision_types.i18n.${getGenericLocale()}.name`,
                    ]}
                    desktopWhen
                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                    loadData={(from, { filters }, spordleTable) => {
                        switch (from){
                            case 'REFRESH':
                            case 'FILTER':
                                spordleTable.setLoading()
                                if(filters.types.length > 0){
                                    return getAllOfficiatingCoaches({ organisation_id, period_id: selectedPeriod.period_id, supervision_type_ids: filters.types.join(',') })
                                }
                            case 'CDM':
                                return getAllOfficiatingCoaches({ organisation_id, period_id: selectedPeriod.period_id })
                            default:
                                break;
                        }
                    }}
                    columns={getColumns()}
                    defaultSorting="-name"
                    renderRow={(columnKey, officiatingCoach) => {
                        switch (columnKey){
                            case 'name':
                                return (
                                    <UserDisplay>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title>
                                                {officiatingCoach.first_name} {officiatingCoach.last_name}
                                            </UserDisplay.Title>
                                            <UserDisplay.Subtitle>
                                                <Link to={`/members/profile/${officiatingCoach.member_id}`}>
                                                    #{officiatingCoach.unique_identifier} <i className="mdi mdi-chevron-right" />
                                                </Link>
                                            </UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                    </UserDisplay>
                                )
                            case 'organisation':
                                return (
                                    <DisplayI18n
                                        field="name"
                                        i18n={officiatingCoach.organisation?.i18n}
                                        defaultValue={officiatingCoach.organisation?.organisation_name}
                                    />
                                )
                            case 'supervision_type':
                                return (
                                    <ul>
                                        {officiatingCoach.member_officiating_coach.supervision_types.map((type) => (
                                            <li key={type.supervision_type_id}>
                                                <DisplayI18n
                                                    field="name"
                                                    i18n={type.i18n}
                                                    defaultValue={type.name}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                );
                            case 'is_national':
                                return (
                                    <div className="font-medium">
                                        {officiatingCoach.member_officiating_coach.national_officiating_coach == "1" ?
                                            <>
                                                <i className="mdi mdi-check text-success" /> <Translate id='components.assignOfficiatingPermissionsModal.isNational' />
                                            </>
                                            :
                                            <>
                                                <i className="mdi mdi-close text-danger" /> <Translate id="components.assignOfficiatingPermissionsModal.isNotNational" />
                                            </>
                                        }
                                    </div>
                                )
                            default:
                                break;
                        }
                    }}
                    onColumnClick={(e, data) => {
                        switch (e.button){
                            case 0: // Left mouse button
                                panelProps.onSingleSelectChange(data);
                                break;
                        }
                    }}
                    loadingMessage={
                        <>
                            <Skeleton height={54} />
                            <Skeleton count={10} height={90} />
                        </>
                    }
                    rowIsHighlighted={(data) => data.checked}
                >
                    <OfficiatingCoachesFilter supervisionTypes={supervisionTypes} />
                    <Card body className='card-shadow'>
                        <PeriodChangeHandler useRefreshTable />
                        <div className='mb-2'>
                            <div className='d-flex flex-wrap justify-content-between'>
                                <SpordleTableProvider.SearchInput />
                                <div className='d-flex ml-auto text-right flex-wrap flex-md-nowrap'>
                                    <SpordleTableProvider.Refresh />
                                    <CanDoAction
                                        action="ADD"
                                        componentCode="members"
                                        componentPermissionCode="manage_official_supervisions"
                                    >
                                        <Button type="button" className='ml-2' color='primary' onClick={() => setModalIsOpen(true)}>
                                            <i className='ti-plus' /> <Translate id='misc.add' />
                                        </Button>
                                        <AddOfficiatingCoachModal supervisionTypes={supervisionTypes} isOpen={modalIsOpen} toggle={() => setModalIsOpen(false)} />
                                    </CanDoAction>
                                </div>
                            </div>
                        </div>
                        <SpordleTableView />
                    </Card>
                </SpordleTableProvider>
            )}
        />
    );
}

export default OfficiatingCoachesList;