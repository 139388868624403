export default{
    "organizationTree.organizationSearch.modal.org.tree.title"       : "Arbre d'organisations",
    "organizationTree.organizationSearch.modal.org.tree.searchLabel" : "Rechercher une organisation",
    "organizationTree.organizationSearch.modal.org.tree.selectAll"   : "{selectAll, select, true {Sélectionner} other {Désélectionné}} toutes les sous-organisations",
    "organizationTree.organizationSearch.modal.org.tree.exactMatch"  : "Correspondance exacte",
    "organizationTree.organizationSearch.action.seeTree"             : "Voir l'arbre",

    "organizationTree.organizationSearch.select.noOrg"                 : "Aucune organisation",
    "organizationTree.organizationSearch.select.placeholder"           : "Rechercher...",
    "organizationTree.organizationSearch.select.valueContainer.plural" : "{count} organisations sélectionnées",

    "organizationTree.organizationSearch.display_organization.tooltip" : "Cliquez pour changer d'organisation",

}