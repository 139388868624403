export default{
    "insurance.title"                 : "Insurance",
    "insurance.export.cheques.pdf"    : "Cheques",
    "insurance.export.worksheet.pdf"  : "Worksheet",
    "insurance.export.printCheques"   : "Print cheque(s)",
    "insurance.export.printWorksheet" : "Print worksheet",

    "insurance.label.case"                   : "Case",
    "insurance.label.branch"                 : "Branch",
    "insurance.label.season"                 : "Season",
    "insurance.label.service"                : "Service",
    "insurance.label.documents"              : "Documents",
    "insurance.label.insuranceClaim"         : "Insurance Claim",
    "insurance.label.updatedBy"              : "Updated by",
    "insurance.label.updatedOn"              : "Updated at",
    "insurance.label.association"            : "Association",
    "insurance.label.claim"                  : "Claim",
    "insurance.label.claimStatus"            : "Claim Status",
    "insurance.label.previousClaimStatus"    : "Previous Claim Status",
    "insurance.label.printed"                : "Printed:",
    "insurance.label.printedBy"              : "by",
    "insurance.label.accident"               : "Accident",
    "insurance.label.injury"                 : "Injury",
    "insurance.label.insuranceInfo"          : "Insurance Info",
    "insurance.label.claimStatus.helper"     : "The reserve amount is sent as 0 when the status is filed or closed.",
    "insurance.label.member"                 : "Member",
    "insurance.label.type"                   : "Insurance Claim Type",
    "insurance.label.reserve"                : "Reserve",
    "insurance.label.participantType"        : "Participant Type",
    "insurance.label.injuryNature"           : "Injury Nature",
    "insurance.label.bodyPart"               : "Body Part",
    "insurance.label.onSiteCare"             : "On Site Care",
    "insurance.label.equipment"              : "Wearing when injured",
    "insurance.label.equipment.count"        : "{count, plural, =0 {No piece} one {# piece} other {# pieces}} of equipment selected",
    "insurance.label.sanctionned"            : "Sanctionned Event",
    "insurance.label.previousInjury"         : "Sustained this injury before",
    "insurance.label.previousInjury.longAgo" : "Please specify how long ago",
    "insurance.label.absence"                : "Absence",
    "insurance.label.condition"              : "Event Time",
    "insurance.label.description"            : "Comments / Notes / Description",
    "insurance.label.provincialCoverage"     : "Provincial Coverage",
    "insurance.label.otherInsurance"         : "Other Insurance",
    "insurance.label.payTo"                  : "Pay To",
    "insurance.label.employed"               : "Employed",
    "insurance.label.employer"               : "Employer",
    "insurance.label.submitted"              : "Submitted",
    "insurance.label.office"                 : "Office",
    "insurance.label.category"               : "Category",
    "insurance.label.guardian"               : "Guardian",
    "insurance.label.arena"                  : "Arena",
    "insurance.label.eventType"              : "Event Type",
    "insurance.label.environment"            : "Location/Spot",
    "insurance.label.penalty"                : "Penalty Involved",
    "insurance.label.cause"                  : "Accident Cause",
    "insurance.label.teamInfo"               : "Team Info",
    "insurance.label.teamNonListed"          : "Non Listed Team",
    "insurance.label.team"                   : "Team",
    "insurance.label.teamDivision"           : "Team Division",
    "insurance.label.teamClass"              : "Team Class",
    "insurance.label.teamCategory"           : "Team Category",
    "insurance.label.teamCategory.helper"    : "Please specify at least the division",
    "insurance.label.teamName"               : "Team Name",
    "insurance.label.teamOfficePos"          : "Team Office Position",
    "insurance.label.correctAge"             : "Correct league/level for age",
    "insurance.label.healthInsuranceNb"      : "Health Insurance #",
    "insurance.label.healthProvince"         : "Health Province",
    "insurance.label.accidentDate"           : "Accident Date",
    "insurance.label.deliveredDate"          : "Delivered Date",
    "insurance.label.teamOfficial"           : "Team Official",
    "insurance.label.teamOfficial.name"      : "Official Name",
    "insurance.label.teamOfficial.position"  : "Official Position",
    "insurance.label.official"               : "Official",
    "insurance.label.official.empty"         : "No official linked",

    "insurance.label.injuryTypes.INFO"  : "Info Only",
    "insurance.label.injuryTypes.CLAIM" : "{name} Claim",

    "insurance.label.participantType.PLAYER"        : "Player",
    "insurance.label.participantType.TEAM_OFFICIAL" : "Team Official",
    "insurance.label.participantType.GAME_OFFICIAL" : "Game Official",
    "insurance.label.participantType.SPECTATOR"     : "Spectator",

    "insurance.label.employedOptions.NO"                 : "No",
    "insurance.label.employedOptions.NO_INFO"            : "No Info",
    "insurance.label.employedOptions.EMPLOYED"           : "Employed Full-Time",
    "insurance.label.employedOptions.EMPLOYED_PART_TIME" : "Employed Part-Time",
    "insurance.label.employedOptions.UNEMPLOYED"         : "Unemployed",
    "insurance.label.employedOptions.STUDENT"            : "Full-Time Student",

    "insurance.label.eventTypes.NO_INFO"        : "No Info",
    "insurance.label.eventTypes.EXHIBITION"     : "Exhibition",
    "insurance.label.eventTypes.REGULAR_SEASON" : "Regular Season",
    "insurance.label.eventTypes.PLAYOFF"        : "Playoff",
    "insurance.label.eventTypes.TOURNAMENT"     : "Tournament",
    "insurance.label.eventTypes.PRACTICE"       : "Practice",
    "insurance.label.eventTypes.TRYOUT"         : "Tryout",
    "insurance.label.eventTypes.OTHER"          : "Other",

    "insurance.validation.noTeam.teamName"              : "Please specify the team name",
    "insurance.validation.noTeam.teamDivision"          : "Please specify the team's division",
    "insurance.validation.noTeam.teamClass"             : "Please specify the team's class",
    "insurance.validation.other.pleaseSpecify"          : "Please specify",
    "insurance.validation.incidentDate.required"        : "Incident date is required",
    "insurance.validation.incidentDate.cannotBeFuture"  : "Incident date cannot be set in the future",
    "insurance.validation.deliveredDate.cannotBeFuture" : "The delivered date cannot be before the incident date and cannot be set in the future",
    "insurance.validation.injuryType.required"          : "Please specify the injury type",
    "insurance.validation.injuryCause.required"         : "Please specify the injury cause",
    "insurance.validation.injuryEnv.required"           : "Please specify where it happened",
    "insurance.validation.documentType.required"        : "Please specify at least one {name}",
    "insurance.validation.eventType.required"           : "Please specify the event type",
    "insurance.validation.startCheque.required"         : "Please specify the start check number",
    "insurance.validation.payTo.required"               : "Please the pay to",
    "insurance.validation.absence.required"             : "Please specify the absence",
    "insurance.validation.bodyPart.required"            : "Please specify the body part",
    "insurance.validation.nature.required"              : "Please specify the nature of the injury",
    "insurance.validation.condition.required"           : "Please specify the event time",
    "insurance.validation.equipment.required"           : "Please specify the equipment weared when the injury happened",
    "insurance.validation.onSiteCare.required"          : "Please specify the on site care received",
    "insurance.validation.participantType.required"     : "Please specify the participant type",
    "insurance.validation.office.required"              : "The office is required",
    "insurance.validation.status.required"              : "The claim's status is required",

    "insurance.sidePanel.deleteModal.msg" : "You are about to delete this insurance claim",
    "insurance.sidePanel.delete.success"  : "Insurance claim deleted !",

    "insurance.sidePanel.cheque.editModal.title"                      : "Edit cheque",
    "insurance.sidePanel.cheque.addModal.label.presetNotes"           : "Preset Notes",
    "insurance.sidePanel.cheque.addModal.title"                       : "Create cheque",
    "insurance.sidePanel.cheque.addModal.label.issued"                : "Cheque issued to",
    "insurance.sidePanel.cheque.addModal.label.issued.CLAIMANT"       : "Claimant",
    "insurance.sidePanel.cheque.addModal.label.issued.OTHER"          : "Other",
    "insurance.sidePanel.cheque.addModal.label.issued.GUARDIAN"       : "Guardian",
    "insurance.sidePanel.cheque.addModal.label.notlisted"             : "Not Listed",
    "insurance.sidePanel.cheque.addModal.label.contactType"           : "Contact Type",
    "insurance.sidePanel.cheque.addModal.label.payee"                 : "Payee",
    "insurance.sidePanel.cheque.addModal.label.payees"                : "Payees",
    "insurance.sidePanel.cheque.addModal.label.createPayee"           : "Create Payee",
    "insurance.sidePanel.cheque.addModal.validation.issued"           : "Please specify to whom this cheque is sent",
    "insurance.sidePanel.cheque.addModal.validation.date"             : "The date cannot be set before the incident date",
    "insurance.sidePanel.cheque.addModal.validation.amount"           : "Please specify an amount",
    "insurance.sidePanel.cheque.addModal.validation.reserve.whenOpen" : "An amount is required when the status of the claim is open",
    "insurance.sidePanel.cheque.addModal.validation.number"           : "Please specify a cheque number",
    "insurance.sidePanel.cheque.addModal.validation.payee"            : "Please specify a payee",
    "insurance.sidePanel.cheque.addModal.validation.guardian"         : "Please specify a guardian",
    "insurance.sidePanel.cheque.addModal.validation.service"          : "Please specify a service",
    "insurance.sidePanel.cheque.addModal.validation.firstName"        : "Please specify the guardian's first name",
    "insurance.sidePanel.cheque.addModal.validation.lastName"         : "Please specify the guardian's last name",
    "insurance.sidePanel.cheque.addModal.validation.address"          : "Please select an address",
    "insurance.sidePanel.cheque.addModal.helper.service"              : "Select a service",
    "insurance.sidePanel.cheque.addModal.helper.guardianNotListed"    : "Specify the first and last name of the guardian",
    "insurance.sidePanel.cheque.addModal.success.msg"                 : "Cheque created",
    "insurance.sidePanel.cheque.addModal.success.info"                : "The cheque was successfully created",
    "insurance.sidePanel.cheque.editModal.success.info"               : "The cheque was successfully updated",
    "insurance.sidePanel.cheque.download.msg"                         : "Generating...",
    "insurance.sidePanel.cheque.download.info"                        : "Your pdf is being generated",
    "insurance.sidePanel.cheque.addBtn"                               : "Create Cheque",
    "insurance.sidePanel.cheque.printAll"                             : "Print all",
    "insurance.sidePanel.cheque.label"                                : "Cheques",
    "insurance.sidePanel.cheque.addBtn.subtitle"                      : "Click here to add a cheque",
    "insurance.sidePanel.cheque.for"                                  : "For",
    "insurance.sidePanel.cheque.toVoid"                               : "Void",
    "insurance.sidePanel.cheque.void"                                 : "Void",
    "insurance.sidePanel.cheque.voidedAt"                             : "Voided at",
    "insurance.sidePanel.cheque.print.modal.title"                    : "Print Settings",
    "insurance.sidePanel.cheque.print.lastPrinted"                    : "Last printed",
    "insurance.sidePanel.cheque.print.btn.unprinted"                  : "Unprinted",
    "insurance.sidePanel.cheque.print.btn.unprinted.helper"           : "Cheques non printed",
    "insurance.sidePanel.cheque.print.unprinted.text"                 : "All the cheques that haven't been exported before will be exported",
    "insurance.sidePanel.cheque.print.unprinted.start"                : "Start cheque number",
    "insurance.sidePanel.cheque.print.btn.chequeNb"                   : "Numbers",
    "insurance.sidePanel.cheque.print.btn.chequeNb.helper"            : "Insurance number range",
    "insurance.sidePanel.cheque.print.btn.chequeDate"                 : "Date",
    "insurance.sidePanel.cheque.print.btn.chequeDate.helper"          : "Cheque date range",
    "insurance.sidePanel.cheque.print.from"                           : "From",
    "insurance.sidePanel.cheque.print.to"                             : "To",
    "insurance.sidePanel.cheque.print.noCheques.msg"                  : "No Cheques",
    "insurance.sidePanel.cheque.print.noCheques.info"                 : "No cheques were found with these parameters",
    "insurance.sidePanel.cheque.print.mode.required"                  : "Please select a print setting",
    "insurance.sidePanel.cheque.print.number.to.required"             : "Please provide a cheque number to end the range with",
    "insurance.sidePanel.cheque.print.number.from.required"           : "Please provide a cheque number to start the range from",
    "insurance.sidePanel.cheque.print.date.from.required"             : "Please provide a cheque date to start the range from",
    "insurance.sidePanel.cheque.print.date.to.required"               : "Please provide a cheque date to end the range with",
    "insurance.sidePanel.cheque.print.date.to.afterFrom"              : "The date to end the range with has to be after the start date",
    "insurance.sidePanel.cheque.print.chequeNb.identity"              : "Created by me",
    "insurance.sidePanel.cheque.print.chequeNb.identity.helper.title" : "Claims created by me",
    "insurance.sidePanel.cheque.print.chequeNb.identity.helper"       : "Prints all the unprinted claims that have been created by me.",
    "insurance.sidePanel.cheque.print.chequeNb.range"                 : "Number range",
    "insurance.sidePanel.cheque.print.chequeNb.printedOnly"           : "Already Printed",

    "insurance.addModal.noTeams"                       : "No teams are available for this member",
    "insurance.addModal.title"                         : "Add Insurance Claim",
    "insurance.addModal.stepTeam.member.search"        : "Please specify who is making this insurance claim",
    "insurance.addModal.stepTeam.addOfficial.search"   : "Search an official",
    "insurance.addModal.stepTeam.addOfficial.title"    : "Add an Official",
    "insurance.addModal.stepTeam.addOfficial.change"   : "Change",
    "insurance.addModal.stepTeam.addOfficial.subtitle" : "There's no official linked to this insurance claim. Click here if you wish to add an official.",

    "insurance.search.branch.label"                          : "Branch",
    "insurance.search.accidentDate.start.label"              : "Accident start date",
    "insurance.search.accidentDate.approved.label"           : "Approved by branch",
    "insurance.search.accidentDate.start.validation.before"  : "The start date must be before the end date",
    "insurance.search.accidentDate.end.label"                : "Accident end date",
    "insurance.search.accidentDate.end.validation.after"     : "The end date must be after the start date",
    "insurance.search.createdDate.start.label"               : "Creation start date",
    "insurance.search.createdDate.end.label"                 : "Creation end date",
    "insurance.search.uniqueIdentifier.firstLastNameIgnored" : "First name and last name will be ignored",

    "insurance.modal.approved.tooltip"     : "Check this box if this insurance claim has been approved by the branch.",
    "insurance.sidepanel.approved.tooltip" : "Whether or not the insurance claim has been approved by the branch.",
    "insurance.modal.approved.title"       : "Branch Approval",

}