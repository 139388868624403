export default{
    "members.profile.overview.memberFamilyRelations.title"       : "Family relations",
    "members.profile.overview.memberFamilyRelations.edit.title"  : "Edit family relations",
    "members.profile.overview.memberFamilyRelations.edit.save"   : "Save changes",
    "members.profile.overview.memberFamilyRelations.edit.cancel" : "Cancel changes",

    // general
    "members.profile.overview.memberFamilyRelations.uniqueIdentifier"  : "{unique_identifier}",
    "members.profile.overview.memberFamilyRelations.age"               : "years old",
    "members.profile.overview.memberFamilyRelations.relation"          : "Relation",
    "members.profile.overview.memberFamilyRelations.relation.required" : "Relation is required",

    // table view
    "members.profile.overview.memberFamilyRelations.table.noData" : "No family relation",

    // add modal view
    "members.profile.overview.memberFamilyRelations.add.title"              : "Add a family relation",
    "members.profile.overview.memberFamilyRelations.add.or"                 : "Or",
    "members.profile.overview.memberFamilyRelations.add.memberNumber"       : "{unique_identifier}",
    "members.profile.overview.memberFamilyRelations.add.memberNumber.hint"  : "The validation will ignore the other fields if this one is specified",
    "members.profile.overview.memberFamilyRelations.add.search"             : "Search",
    "members.profile.overview.memberFamilyRelations.add.noData"             : "No results",
    "members.profile.overview.memberFamilyRelations.add.select"             : "Select",
    "members.profile.overview.memberFamilyRelations.add.select.placeholder" : "Select...",
    "members.profile.overview.memberFamilyRelations.add.select.noOptions"   : "No available relation",
    "members.profile.overview.memberFamilyRelations.add.alreadyExists"      : "This member is already in a family relation",
    "members.profile.overview.memberFamilyRelations.add.3230"               : "Members have to be different",
    "members.profile.overview.memberFamilyRelations.add.3232"               : "Relation could not be found",
    "members.profile.overview.memberFamilyRelations.add.3231"               : "The members are already linked",
    "members.profile.overview.memberFamilyRelations.add.success"            : "Family relation created!",

    // side panel view
    "members.profile.overview.memberFamilyRelations.sidePanel.remove"         : "Remove",
    "members.profile.overview.memberFamilyRelations.sidePanel.delete.text"    : "You are about to delete this family relation.",
    "members.profile.overview.memberFamilyRelations.sidePanel.delete.success" : "Family relation deleted!",
    "members.profile.overview.memberFamilyRelations.sidePanel.update.success" : "Family relation updated!",
    "members.profile.overview.memberFamilyRelations.sidePanel.relation"       : "This member added you as",

    // linked accounts
    "members.profile.overview.linkedAccounts.title"     : "Linked accounts",
    "members.profile.overview.linkedAccounts.noLinked"  : "No linked accounts...",
    "members.profile.overview.linkedAccounts.showRoles" : "Show roles",
    "members.profile.overview.linkedAccounts.hideRoles" : "Hide roles",
}