import { useContext } from "react";
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import { Form, Formik } from "formik";
import { Button, Col, Row, FormGroup, Label } from "reactstrap";
import { object, string } from "yup";

// Helpers
import Translate from "@spordle/intl-elements";

// Contexts
import { useSpordleTable } from "@spordle/datatables";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { I18nContext } from "../../../contexts/I18nContext";
import { DisplayI18n } from "../../../helpers/i18nHelper";

const PayeesSearch = () => {
    const spordleTable = useSpordleTable();
    const orgContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);

    return (
        <Formik
            initialValues={spordleTable.getFilters()}
            validationSchema={object().shape({
                first_name: string(),
                last_name: string(),
                service_type_id: string(),
            })}
            onSubmit={async(values) => {
                if(values.first_name !== spordleTable.getFilters().first_name || ''){
                    spordleTable.filterChange('first_name', values.first_name, false);
                }
                if(values.last_name !== spordleTable.getFilters().last_name || ''){
                    spordleTable.filterChange('last_name', values.last_name, false);
                }
                spordleTable.filterChange('service_type_id', values.service_type_id, true);
            }}
        >
            {(formik) => (
                <Form>
                    <Row form>
                        <Col md="3" lg="3">
                            <FormGroup>
                                <Label className='text-muted'><Translate id='form.fields.firstName' /></Label>
                                <FormikInputText trim name="first_name" id="first_name" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md="3" lg="3">
                            <FormGroup>
                                <Label className='text-muted'><Translate id='form.fields.lastName' /></Label>
                                <FormikInputText trim name="last_name" id="last_name" autoComplete="new-password" />
                            </FormGroup>
                        </Col>
                        <Col md="3" lg="3">
                            <FormGroup>
                                <Label className='text-muted'><Translate id='settings.payees.serviceType' /></Label>
                                <FormikSelect
                                    id='service_type_id' name='service_type_id'
                                    searchKeys={[
                                        `i18n.${i18nContext.getGenericLocale()}.name`,
                                    ]}
                                    clearable
                                    renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                    loadData={(from, extra, spordleTable) => {
                                        switch (from){
                                            case 'CDM':
                                                return orgContext.getServiceTypes()
                                                    .then((types) => types.sort((aT, bT) => aT.display_order - bT.display_order).map((type) => ({
                                                        value: type.service_type_id,
                                                        label: type.name,
                                                        i18n: type.i18n,
                                                    })))
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" lg="3">
                            <FormGroup>
                                <Label>&nbsp;</Label>
                                <Button
                                    block
                                    type="submit"
                                    color='primary'
                                    className='text-nowrap'
                                    disabled={spordleTable.state.loadingState === 'loading' || spordleTable.state.loadingState === 'lazy'}
                                >
                                    <i className='fas fa-search mr-1' /><Translate id='misc.search' />
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}

export default PayeesSearch;