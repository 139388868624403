import invoice from "./attendeeModalInvoice";
import notification from "./attendeeModalNotification";
import payment from "./attendeeModalPaymentReceived";
import receipt from "./attendeeModalReceipt";
import transfer from "./attendeeModalTransferClinic";
import certify from "./attendeeModalCertify";
import bulkUpdate from "./attendeeModalBulkUpdate";

export default{
    ...invoice,
    ...notification,
    ...payment,
    ...receipt,
    ...transfer,
    ...certify,
    ...bulkUpdate,
}