import Translate, { DateFormat } from "@spordle/intl-elements";
import React from "react";
import {
    Col, FormGroup,
    Label, Row
} from "reactstrap";
import { array, mixed, object } from 'yup';
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import FormikEditableFile from "../../../../../components/formik/FormikEditableFile";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
import { CrcContext } from "../../../../../contexts/CrcContext";
import { MembersContext } from "../../../../../contexts/MembersContext";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { RolesContext } from "../../../../../contexts/RolesContext";
import withContexts from "../../../../../helpers/withContexts";
import CRCStatus from "./CRCStatus";
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import InlineCopy from '../../../../../components/inlineCopy/InlineCopy';
import { compareAndFormatFiles } from '../../../../../components/uploader/uploadHelpers';
import CanDoAction from '../../../../../components/permissions/CanDoAction';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import BackgroundCheckEditModal from './BackgroundCheckEditModal';
import { stringBuilder } from '@spordle/helpers';

class CriminalRecordCheckSidepanel extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            isOpen: false,
        }

        this.canEdit = props.canEdit && props.selectedRows[0].crc_is_expired != '1'
        this.canEditIssueDate = this.props.RolesContext.canDoAction('EDIT', 'members', 'manage_background_check_issue_date', true)
    }

    toggle = () => {
        this.setState((prev) => ({ isOpen: !prev.isOpen }))
    }

    /**
     * Creates member attachments. Deletes one if there is one present.
     * Syncs new values on the spordle table as well
     * @param {File} file
     */
    updateMemberAttachment = async(files) => {
        const { toDelete, toCreate } = compareAndFormatFiles(this.props.selectedRows[0].attachments || [], files);
        let newVal = { attachments: [] };

        if(toCreate && toCreate.length > 0){
            await Promise.all(toCreate.map((attachments) => {
                if(attachments.length > 0){
                    return this.props.CrcContext.createMemberCrcAttachment(
                        this.props.selectedRows[0].crc_id,
                        attachments.map(({ attachment }) => attachment),
                    )
                        .catch(console.error)
                }

                return Promise.resolve()
            }))
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                });

        }

        if(toDelete.length > 0){ // DElete needs to be after creation because if document is required
            await Promise.all(toDelete.map((attachment) => (
                this.props.CrcContext.deleteMemberCrcAttachment(this.props.selectedRows[0].crc_id, attachment?.member_crc_attachment_id).catch(console.error)
            )))
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                });

            this.props.syncRows(this.props.createNewValues(newVal)); // necessary in case of error later
        }

        newVal = {
            attachments: await this.props.CrcContext.getCrcAttachments(this.props.selectedRows[0].crc_id)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                }),
        };

        this.props.syncRows(this.props.createNewValues(newVal));
    }

    handleDelete = () => {
        return this.props.MembersContext.deleteMemberCrc(this.props.selectedRows[0].member.member_id, this.props.selectedRows[0].crc_id)
            .then(() => {
                this.props.tableRef.deleteRow(this.props.selectedRows[0].crc_id)
                success();
                this.props.toggle();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    handleOnFileClick = (file) => {
        if(this.props.RolesContext.canDoAction('READ', 'members', 'manage_background_check_attachments')){
            return this.props.CrcContext.getCrcDownloadLink(
                this.props.selectedRows[0].crc_id,
                file.member_crc_attachment_id,
            ).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        }
        return Promise.resolve()
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>

                <BackgroundCheckEditModal
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    crc={this.props.selectedRows[0]}
                    backgroundChecks={this.props.backgroundChecks}
                    panelProps={this.props}
                    allStatus={this.props.allStatus}
                />

                <SidePanel.Header>
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />
                        {this.props.canDelete &&
                            <SidePanel.ActionsMenu action='DELETE' componentCode='members' componentPermissionCode='member_background_check'>
                                <SidePanel.MenuDelete
                                    translateModalMsg
                                    modalMsg='members.profile.crc.sidepanel.delete'
                                    onConfirm={this.handleDelete}
                                />
                            </SidePanel.ActionsMenu>
                        }
                    </div>
                    <SidePanel.Title><DisplayI18n field='name' defaultValue={this.props.selectedRows[0].background_check_type.name} i18n={this.props.selectedRows[0].background_check_type.i18n} /></SidePanel.Title>
                    <SidePanel.Subtitle>
                        <div>{this.props.selectedRows[0].member.first_name} {this.props.selectedRows[0].member.last_name}</div>
                        <div className="mb-2 d-inline-flex">
                            <div className="mr-4 d-flex align-items-center">
                                <InlineCopy toCopy={this.props.selectedRows[0].member.unique_identifier}>
                                    <span className="font-medium">
                                        #{this.props.selectedRows[0].member.unique_identifier || '-'}
                                    </span>
                                </InlineCopy>
                            </div>
                        </div>
                    </SidePanel.Subtitle>
                </SidePanel.Header>
                <div className="p-3">
                    <FormGroup>
                        <Label for='crc-status' className='text-muted mb-0'><Translate id="misc.status" /></Label>
                        <div
                            className={stringBuilder('d-flex align-items-center', { 'editable': this.canEdit })}
                            onClick={() => {
                                if(this.canEdit){
                                    this.toggle()
                                }
                            }}
                        >
                            <CRCStatus crc={this.props.selectedRows[0]} />
                            {this.canEdit &&
                                <i className='text-primary mdi mdi-pencil ml-2' />
                            }
                        </div>
                        {/* <FormikEditable
                            noConfirmation
                            id='form-crc-status'
                            disabled={!this.props.canEdit || this.props.selectedRows[0].crc_is_expired == '1'}
                            initialValues={{
                                criminal_record_check_status_id: this.props.selectedRows[0].criminal_record_check_status?.criminal_record_check_status_id,
                            }}
                            validationSchema={object().shape({
                                criminal_record_check_status_id: string().required(<Translate id='members.profile.crc.form.validation.status.required' />),
                            })}
                            onSubmit={(values) => {
                                if(values.criminal_record_check_status_id !== this.props.selectedRows[0].criminal_record_check_status?.criminal_record_check_status_id){
                                    this.setState({ isLoading: true });
                                    const newValues = this.props.createNewValues({
                                        criminal_record_check_status: this.props.allStatus?.find((stat) => stat.criminal_record_check_status_id === values.criminal_record_check_status_id),
                                    });
                                    this.props.MembersContext.partiallyUpdateMemberCrc(
                                        this.props.selectedRows[0].member.member_id,
                                        this.props.selectedRows[0].crc_id,
                                        values,
                                    )
                                        .then(() => {
                                            this.props.syncRows(newValues);
                                            this.setState({ isLoading: false })
                                            success();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                                this.setState({ isLoading: false })
                                            }
                                        })
                                }
                            }}
                        >
                            {(isEditing, options) => {
                                if(!isEditing){
                                    return <CRCStatus crc={this.props.selectedRows[0]} />
                                }
                                return (
                                    <I18nContext.Consumer>
                                        {({ getGenericLocale }) => (
                                            <FormikSelect
                                                autoFocus
                                                menuIsDefaultOpen
                                                search={false}
                                                name='criminal_record_check_status_id'
                                                id='crc-status'
                                                isLoading={!this.props.allStatus}
                                                renderOption={(option) => (<DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />)}
                                                searchKeys={[
                                                    `i18n.${getGenericLocale()}.name`,
                                                ]}
                                                onOptionSelected={options.stopEditing}
                                                options={this.props.allStatus ?
                                                    this.props.allStatus.map((status) => ({
                                                        label: status.name,
                                                        value: status.criminal_record_check_status_id,
                                                        i18n: status.i18n,
                                                    }))
                                                    :
                                                    []
                                                }
                                            />
                                        )}
                                    </I18nContext.Consumer>
                                )

                            }}
                        </FormikEditable> */}
                    </FormGroup>
                    <Row>
                        <Col sm="6" className="mb-3">
                            <div className="text-muted"><Translate id='members.profile.crc.validOn' /></div>
                            <div
                                className={stringBuilder('d-flex align-items-center', { 'editable': this.canEditIssueDate })}
                                onClick={() => {
                                    if(this.canEditIssueDate){
                                        this.toggle()
                                    }
                                }}
                            >
                                <span className='font-medium text-dark'><DateFormat value={this.props.selectedRows[0].issue_date} /></span>
                                {this.canEditIssueDate &&
                                    <i className='text-primary mdi mdi-pencil ml-2' />
                                }
                            </div>
                            {/* <FormikEditable
                                id='crc-issue-date'
                                disabled={!this.props.canEdit || this.props.selectedRows[0].crc_is_expired == '1'}
                                initialValues={{
                                    issueDate: this.props.selectedRows[0].issue_date,
                                }}
                                validationSchema={object().shape({
                                    issueDate: mixed()
                                        .required(<Translate id='members.profile.crc.form.validation.date.required' />)
                                        .test({
                                            name: 'dateIsValid',
                                            message: <Translate id='form.validation.date.format' />,
                                            test: moment.isMoment,
                                        }),
                                })}
                                onSubmit={(values) => {
                                    const newDate = values.issueDate.format('YYYY-MM-DD')
                                    if(newDate !== this.props.selectedRows[0].issue_date){
                                        this.setState({ isLoading: true });
                                        const newValues = this.props.createNewValues({
                                            issue_date: newDate,
                                        });
                                        this.props.MembersContext.partiallyUpdateMemberCrc(
                                            this.props.selectedRows[0].member.member_id,
                                            this.props.selectedRows[0].crc_id,
                                            {
                                                issue_date: newDate,
                                            },
                                        )
                                            .then(() => {
                                                this.props.syncRows(newValues);
                                                this.setState({ isLoading: false })
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                    this.setState({ isLoading: false })
                                                }
                                            })
                                    }
                                }}
                            >
                                {(isEditing, options) => {
                                    if(!isEditing){
                                        return <span className='font-medium text-dark'><DateFormat value={this.props.selectedRows[0].issue_date} /></span>
                                    }
                                    return (
                                        <FormikDateTime
                                            id='issueDate' name='issueDate'
                                            timeFormat={false}
                                        />
                                    )

                                }}
                            </FormikEditable> */}
                        </Col>
                        <Col sm="6" className="mb-3">
                            <div className="text-muted"><Translate id='members.profile.crc.expiresAt' /></div>
                            <div className="font-medium text-dark"><DateFormat value={this.props.selectedRows[0].expiration_date} utc /></div>
                        </Col>
                    </Row>
                    <CanDoAction action='READ' componentCode='members' componentPermissionCode='manage_background_check_attachments'>
                        <FormGroup>
                            <Label for='document' className='text-muted mb-0'><Translate id='members.profile.crc.supportiveDocument' /></Label>
                            <FormikEditableFile
                                id='crc-document'
                                disabled={(!this.props.canEdit || !this.props.RolesContext.canDoAction('ADD', 'members', 'manage_background_check_attachments')) && !this.props.IdentityRolesContext.isGod()}
                                preventRemoveExistant={!this.props.RolesContext.canDoAction('DELETE', 'members', 'manage_background_check_attachments') && !this.props.IdentityRolesContext.isGod()}
                                dropzoneProps={{
                                    multiple: true,
                                    accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .psd",
                                }}
                                onFileClick={this.handleOnFileClick}
                                initialValues={{
                                    file: this.props.selectedRows[0].attachments,
                                }}
                                validationSchema={object().shape({
                                    file: array().of(mixed())
                                        .test({
                                            name: 'any',
                                            message: <Translate id='members.profile.crc.form.validation.file.required' />,
                                            test: (val) => {
                                                return this.props.selectedRows[0].need_document != 1 || (Array.isArray(val) && val.length > 0);
                                            },
                                        }),
                                })}
                                onSubmit={(values) => {
                                    this.setState({ isLoading: true });

                                    this.updateMemberAttachment(values.file)
                                        .then(() => {
                                            success();
                                            this.setState({ isLoading: false });
                                        })
                                        .catch((e) => {
                                            if(!AxiosIsCancelled(e.message)){
                                                const getMsg = (m) => {
                                                    switch (m){
                                                        case '3331':
                                                        case '3332':
                                                            return `components.uploader.imgUpload.error.${e.message}`;
                                                        default:
                                                            return 'misc.error';
                                                    }
                                                }
                                                console.error(e);
                                                fail({ msg: getMsg(e.message) });
                                                this.setState({ isLoading: false });
                                            }
                                        })
                                }}
                            >
                                {(formik) => (
                                    !formik.values.file && this.props.canEdit &&
                                        <p className="small mb-0 mt-2">
                                            <strong><Translate id='members.profile.crc.supportiveDocument.helper.bold' /></strong> <Translate id='members.profile.crc.supportiveDocument.helper' />
                                        </p>
                                )}
                            </FormikEditableFile>
                        </FormGroup>
                    </CanDoAction>
                </div>
            </OverlayLoader>
        )
    }
}

export default withContexts(MembersContext, CrcContext, OrganizationContext, RolesContext, IdentityRolesContext)(CriminalRecordCheckSidepanel);