import { useDisclosure } from "@mantine/hooks";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { success } from "@spordle/toasts";
import { useContext, useState, useEffect } from "react";
import { Card } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import CrossFade from "../../../../../../components/crossFade/CrossFade";
import InlineCopy from "../../../../../../components/inlineCopy/InlineCopy";
import IsGod from "../../../../../../components/permissions/IsGod";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";
import { MessagesContext } from "../../../../../../contexts/MessagesContext";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import I18nHelperContextProvider from '../../../../../../helpers/i18nHelper';
import SettingsError from "../SettingsError";
import DemographicMsgForm from "./DemographicMsgForm";
import DemographicMsgView from "./DemographicMsgView";

export const DEMOGRAPHIC_SETTING_NAME = "explanation_text_for_member_collected_data";

const MessagesSettingsDemographic = () => {
    const [ data, setData ] = useState({ message: null, isLoading: true });
    const [ isEditing, { open: startEditing, close: stopEditing } ] = useDisclosure(false);

    const identityRolesContext = useContext(IdentityRolesContext);
    const { getSettingMessage, deleteSettingMessage } = useContext(MessagesContext);
    const { settings } = useContext(OrganizationContext);
    const canEdit = settings[DEMOGRAPHIC_SETTING_NAME]?.can_manage == 1;

    const getMessage = (msgId) => {
        if(msgId){
            return getSettingMessage(msgId)
                .then((messages) => {
                    setData((prev) => ({ ...prev, message: Array.isArray(messages) ? messages[0] : null, isLoading: false, error: null }));
                })
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e);
                        setData((prev) => ({ ...prev, message: null, isLoading: false, error: e }));
                    }
                })
        }

        return Promise.resolve()
            .then(() => {
                setData((prev) => ({ ...prev, message: null, isLoading: false, error: null }));
            })

    }

    const handleOnSuccess = async(msgId) => {
        await getMessage(msgId);
        success();
        stopEditing();
    }

    const handleOnDelete = (msgId) => {
        return deleteSettingMessage(msgId)
            .then(() => handleOnSuccess())
    }

    useEffect(() => {
        getMessage(settings[DEMOGRAPHIC_SETTING_NAME]?.value);
    }, []);

    return (
        <Card body className="card-shadow">
            <CardSectionTitle title="organization.settings.registrationSettings.demographic.title" className='d-flex'>
                {!isEditing && !data.error &&
                    <div className={stringBuilder('ml-auto', { 'text-link': canEdit })}>
                        <button
                            disabled={(!canEdit && !identityRolesContext.isGod()) || data.isLoading}
                            onClick={startEditing}
                            className={stringBuilder("reset-btn align-self-end", { "text-purple": (identityRolesContext.isGod() && !canEdit) })}
                            type='button'
                        >
                            <Translate id='misc.edit' />
                        </button>
                    </div>
                }
            </CardSectionTitle>
            <div className="mb-3">
                <p className="mb-0"><Translate id="organization.settings.registrationSettings.demographic.setting.desc" /></p>
                <IsGod tag="code">
                    <InlineCopy toCopy={DEMOGRAPHIC_SETTING_NAME}>
                        setting: {DEMOGRAPHIC_SETTING_NAME}
                    </InlineCopy>
                </IsGod>
            </div>
            {data.error ?
                <SettingsError error={data.error} />
                :
                <I18nHelperContextProvider fields={[ 'body' ]}>
                    <CrossFade unmountOnExit isVisible={isEditing}>
                        <DemographicMsgForm
                            message={data.message}
                            stopEditing={stopEditing}
                            onDelete={handleOnDelete}
                            onSuccess={handleOnSuccess}
                        />
                    </CrossFade>
                    <CrossFade isVisible={!isEditing}>
                        <DemographicMsgView
                            isLoading={data.isLoading}
                            message={data.message}
                        />
                    </CrossFade>
                </I18nHelperContextProvider>
            }
        </Card>
    );
}

export default MessagesSettingsDemographic;
