import { FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate from "@spordle/intl-elements";
import { stringBuilder } from "@spordle/helpers";
import React from "react";
import {
    Col, Fade,
    Nav,
    NavItem,
    NavLink,
    Row, Spinner, TabContent, TabPane
} from "reactstrap";
import * as Yup from 'yup';
import FormikEditable from '../../../../components/formik/FormikEditable';
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import SidePanel from '../../../../components/sidePanel/SidePanel';
import UserImg from '../../../../components/UserImg';
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { RolesContext } from "../../../../contexts/RolesContext";
import withContexts from '../../../../helpers/withContexts';
import { success, fail } from '@spordle/toasts';

// Modals
import PermissionsModal from './modals/RolePermissions';
import SidePanelOrganizationRolesPermission from "./SidePanelOrganizationRolesPermission";
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import SidePanelOrganizationRolesUsers from './SidePanelOrganizationRolesUsers';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';
import ConfirmModal from '../../../../components/confirmModal/ConfirmModal';

class SidePanelOrganizationRoles extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingState: 'success',
            activeTab: '1',
            loadingUsers: 'lazy',
            roleUsers: [],
            isLoading: false,

            // for role duplication duplication: we get the permissions from the role
            role: null,

            // Modals
            showPermissionsModal: false,
            searchQuery: '',
        }
    }

    /*--------------------------------------------------------------------------------*/
    /* Modals                                                                  */
    /*--------------------------------------------------------------------------------*/
    openPermissionsModal = (permission, searchQuery) => {
        this.setState(() => ({ showPermissionsModal: permission, searchQuery: searchQuery }));
    }

    toggleTab = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState(() => ({ activeTab: tab }));
        }
    }

    getRoleIdsForApi = (asArray = false) => {
        if(asArray || this.props.selectedRows.length > 1){ // Multi
            return this.props.selectedRows.map((sRow) => sRow.role_id);
        }// Single
        return this.props.selectedRows[0].role_id;

    }

    getRoleUsers = (roleId) => {
        return this.props.RolesContext.getRole(roleId)
            .then((data) => {
                this.setState(() => ({ roleUsers: data.users }))
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    deleteRole = () => {
        return this.props.RolesContext.deleteRole(this.props.getCommonValue("role_id"))
            .then(() => {
                this.props.tableRef.deleteRow(this.props.getCommonValue("role_id")).then(this.props.toggle)
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    setRole = (role) => this.setState(() => ({ role: role }));

    /**
     * @returns {boolean}
     */
    canEditRole = () => !this.props.selectedRows.some((sRow) => sRow.is_system === '1' || sRow.is_shared === '1');

    componentDidMount(){
        const commonValue = this.props.getCommonValue('role_id');
        if(commonValue && commonValue !== '-')
            this.getRoleUsers(commonValue)
                .then(() => {
                    this.setState(() => ({ loadingUsers: 'success' }))
                })
                .catch((error) => {
                    this.setState(() => ({ loadingUsers: 'error' }));
                    console.error(error.message)
                })
    }

    componentDidUpdate(prevProps){
        const commonValue = this.props.getCommonValue('role_id');
        if(commonValue && commonValue !== '-' && this.props.selectedRows.length === 1 && prevProps.selectedRows[0].role_id !== this.props.selectedRows[0].role_id){
            this.setState(() => ({ loadingUsers: 'lazy' }))
            this.getRoleUsers(commonValue)
                .then(() => {
                    this.setState(() => ({ loadingUsers: 'success' }))
                })
                .catch((error) => {
                    this.setState(() => ({ loadingUsers: 'error' }));
                    console.error(error.message)
                })
        }

        if(this.props.selectedRows.length > 1) // Select Details tab when more then 1 row is selected because other tabs don't support multi select
            this.toggleTab('1');
    }

    render(){
        return (
            <>
                {this.state.loadingState === 'lazy' ?
                    <Fade in={this.state.loadingState === 'lazy'}>
                        <div className='d-flex justify-content-center p-5'>
                            <Spinner type='grow' color='primary' />
                        </div>
                    </Fade>
                    : this.state.loadingState === 'success' ?
                        <Fade in={this.state.loadingState === 'success'}>
                            <OverlayLoader isLoading={this.state.isLoading}>
                                {/*--------------------------------------------------------------------------------*/}
                                {/* Modals                                                                         */}
                                {/*--------------------------------------------------------------------------------*/}
                                <PermissionsModal
                                    permission={this.state.showPermissionsModal}
                                    roleId={this.props.selectedRows[0].role_id}
                                    toggle={() => {
                                        this.setState(() => {
                                            return { showPermissionsModal: false }
                                        })
                                    }}
                                    rolesList={this.props.tableRef.getData()}
                                    updateComponents={() => {
                                        this.rolePermissionRef?.fetchRolesPermissions(true);
                                    }}
                                    search={this.state.searchQuery}
                                />

                                <SidePanel.Header noBorder>
                                    <div className='d-flex mb-2 align-items-center'>
                                        <SidePanel.ToggleButton />
                                        <SidePanel.ActionsMenu>
                                            <SidePanel.MenuDelete
                                                action='DELETE' componentCode='settings' componentPermissionCode='identity_roles'
                                                translateModalMsg
                                                modalMsg='organization.profile.roles.sidePanelOrganizationRoles.deleteRole.topText'
                                                onConfirm={this.deleteRole}
                                                modalContent={
                                                    <Row className='mt-3'>
                                                        <Col md='6' className='d-flex align-items-center justify-content-center flex-column border-right'>
                                                            <div className='font-medium'>{this.props.getCommonValue("title")}</div>
                                                            <div className='small'>{this.props.getCommonValue("description")}</div>
                                                        </Col>
                                                        <Col md='6' className='d-flex align-items-center justify-content-center flex-column'>
                                                            <div className='mr-2'>
                                                                <UserImg
                                                                    src={this.props.OrganizationContext.logo?.full_path}
                                                                    filePos={this.props.OrganizationContext.logo?.file_position}
                                                                    abbr={this.props.OrganizationContext.abbreviation}
                                                                    alt={this.props.OrganizationContext.organisation_name}
                                                                />
                                                            </div>
                                                            <div className='font-medium'>{this.props.OrganizationContext.organisation_name}</div>
                                                        </Col>
                                                    </Row>
                                                }
                                            />
                                            <ConfirmModal
                                                toggler={(toggle) => (
                                                    <SidePanel.MenuAction
                                                        action='ADD' componentCode='settings' componentPermissionCode='identity_roles'
                                                        onClick={toggle} disabled={!this.state.role}
                                                    >
                                                        <Translate id='misc.duplicate' />
                                                    </SidePanel.MenuAction>
                                                )}
                                                modalTitle='organization.profile.roles.sidePanelOrganizationRoles.duplicate.title'
                                                translateModalTitle
                                                color='primary'
                                                icon='mdi-content-duplicate'
                                                actionButtonText='misc.confirm'
                                                translateActionButtonText
                                                onConfirm={() => {
                                                    this.props.RolesContext.createRole(
                                                        this.state.role.organisation_id,
                                                        this.state.role.title + ' - copy',
                                                        this.state.role.description,
                                                        this.state.role.active == 1,
                                                        '',
                                                        '',
                                                        this.state.role.is_system,
                                                    ).then((roleId) => {

                                                        const permissions = this.state.role.components.reduce((newArray, component) => {
                                                            //components: if a component is active & has perms, loop through it's permissions
                                                            if(component.active == 1 && component.component_permissions?.length > 0){
                                                                newArray.pushArray(component.component_permissions.reduce((componentPermissionArray, componentPermission) => {
                                                                    //component permissions: if a component permission is active & has actions, loop through it's actions and add to array
                                                                    if(componentPermission.active == 1 && componentPermission.role_component_permissions?.length > 0)
                                                                        componentPermissionArray.pushArray(componentPermission.role_component_permissions)
                                                                    return componentPermissionArray;
                                                                }, []))
                                                            }
                                                            return newArray
                                                        }, [])

                                                        if(permissions.length > 0)
                                                            return this.props.RolesContext.duplicateComponentsPermissions(roleId, permissions).then(() => {
                                                                this.props.tableRef.refreshTable();
                                                                this.props.forceCloseSidePanel();
                                                            })
                                                        this.props.tableRef.refreshTable();
                                                        this.props.forceCloseSidePanel();
                                                    }).catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                }}
                                            />
                                        </SidePanel.ActionsMenu>
                                    </div>
                                    <div className='mb-3'>
                                        {this.props.selectedRows.length === 1 ?
                                            <div className="d-inline-flex align-items-center text-dark">
                                                {/* <div className="mr-2">
                                                <img className="rounded-circle" src="https://via.placeholder.com/100" alt="" width="50"/>
                                            </div> */}
                                                <div>
                                                    <SidePanel.Title>{this.props.selectedRows[0].title}</SidePanel.Title>
                                                    <SidePanel.Subtitle>{this.props.selectedRows[0].description}</SidePanel.Subtitle>
                                                    {this.props.selectedRows[0].is_system === '1' && <div className='small text-warning'><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.isSytem' /></div>}
                                                    {this.props.selectedRows[0].is_shared === '1' && <div className='small text-warning'><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.isShared' /></div>}
                                                    {this.props.selectedRows[0].active === '0' && <div className="small text-warning"><i className='mdi mdi-alert-outline' /> <Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.isInactive' /></div>}
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <SidePanel.Title><Translate id='sidebar.title.multi' values={{ count: this.props.selectedRows.length }} /></SidePanel.Title>
                                                {!this.canEditRole() && <SidePanel.Subtitle className='text-warning'><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.multi.cannot.warning' /></SidePanel.Subtitle>}
                                            </div>
                                        }
                                    </div>
                                </SidePanel.Header>

                                <Nav tabs>
                                    <NavItem className="flex-grow-1 text-center w-50">
                                        <NavLink
                                            className={stringBuilder({ active: this.state.activeTab === '1' })}
                                            onClick={() => { this.toggleTab('1'); }}
                                        >
                                            <Translate id='organization.profile.roles.sidePanelOrganizationRoles.tabs.details.title' />
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="flex-grow-1 text-center w-50">
                                        <NavLink
                                            disabled={this.props.selectedRows.length > 1}
                                            className={stringBuilder({ active: this.state.activeTab === '2' })}
                                            onClick={() => { this.toggleTab('2'); }}
                                        >
                                            <Translate id='organization.profile.roles.sidePanelOrganizationRoles.tabs.users.title' />
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        {/*--------------------------------------------------------------------------------*/}
                                        {/* Role Info Editable                                                             */}
                                        {/*--------------------------------------------------------------------------------*/}
                                        <RolesContext.Consumer>
                                            {(roles) => (
                                                <div className="p-3 border-bottom">
                                                    <div className="h4 font-bold mb-3"><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo' /></div>

                                                    <div className="mb-3">
                                                        <div className="text-muted"><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.title.title' /></div>
                                                        <FormikEditable
                                                            disabled={!this.canEditRole()}
                                                            id='title'
                                                            initialValues={{
                                                                title: this.props.getCommonValue("title"),
                                                            }}
                                                            validationSchema={Yup.object().shape({
                                                                title: Yup.string().required(<Translate id='organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.title.required' />),
                                                            })}
                                                            onSubmit={(values) => {
                                                                this.setState(() => ({ isLoading: true }));
                                                                const newValues = this.props.createNewValues(values);
                                                                roles.updateRole(this.getRoleIdsForApi(), newValues, this.props.OrganizationContext.organisation_id)
                                                                    .then(() => {
                                                                        this.props.syncRows(newValues);
                                                                        success();
                                                                    }).catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                        }
                                                                    })
                                                                    .finally(() => this.setState(() => ({ isLoading: false })))
                                                            }}
                                                        >
                                                            {(isEditing) => {
                                                                if(!isEditing){
                                                                    return (
                                                                        <div className="font-medium">
                                                                            {this.props.getCommonValue("title")}
                                                                        </div>
                                                                    )
                                                                }
                                                                return (
                                                                    <FormikInputText name="title" id="title" type='text' trim />
                                                                )

                                                            }}
                                                        </FormikEditable>
                                                    </div>

                                                    <div className="mb-3">
                                                        <div className="text-muted"><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.description.title' /></div>
                                                        <FormikEditable
                                                            disabled={!this.canEditRole()}
                                                            id='description'
                                                            initialValues={{
                                                                description: this.props.getCommonValue("description"),
                                                            }}
                                                            validationSchema={Yup.object().shape({
                                                                description: Yup.string().required(<Translate id='organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.description.required' />),
                                                            })}
                                                            onSubmit={(values) => {
                                                                this.setState(() => ({ isLoading: true }));
                                                                const newValues = this.props.createNewValues(values);
                                                                roles.updateRole(this.getRoleIdsForApi(), newValues, this.props.OrganizationContext.organisation_id)
                                                                    .then(() => {
                                                                        this.props.syncRows(newValues);
                                                                        success();
                                                                    }).catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                        }
                                                                    })
                                                                    .finally(() => this.setState(() => ({ isLoading: false })))
                                                            }}
                                                        >
                                                            {(isEditing) => {
                                                                if(!isEditing){
                                                                    return (
                                                                        <div className="font-medium">
                                                                            {this.props.getCommonValue("description")}
                                                                        </div>
                                                                    )
                                                                }
                                                                return (
                                                                    <FormikInputText name="description" id="description" type='text' trim />
                                                                )

                                                            }}
                                                        </FormikEditable>
                                                    </div>


                                                    <div className="mb-3">
                                                        <div className="text-muted"><Translate id='organization.profile.roles.addRole.isSystem' /></div>
                                                        <FormikEditable
                                                            id='is_system'
                                                            noConfirmation
                                                            disabled={!this.canEditRole() || !this.props.IdentityRolesContext.isGod()}
                                                            initialValues={{
                                                                is_system: this.props.getCommonValue("is_system"),
                                                            }}
                                                            validationSchema={Yup.object().shape({
                                                                is_system: Yup.string().required(<Translate id='organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.systemRole.required' />),
                                                            })}
                                                            onSubmit={(values) => {
                                                                if(values.active !== '-'){
                                                                    this.setState(() => ({ isLoading: true }));
                                                                    const newValues = this.props.createNewValues(values);
                                                                    roles.updateRole(this.getRoleIdsForApi(), newValues, this.props.OrganizationContext.organisation_id)
                                                                        .then(() => {
                                                                            this.props.syncRows(newValues);
                                                                            success();
                                                                        }).catch((error) => {
                                                                            if(!AxiosIsCancelled(error.message)){
                                                                                console.error(error.message)
                                                                                fail({
                                                                                    msg: 'misc.error',
                                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                    skipInfoTranslate: true,
                                                                                })
                                                                            }
                                                                        })
                                                                        .finally(() => this.setState(() => ({ isLoading: false })))
                                                                }
                                                            }}
                                                        >
                                                            {(isEditing, options) => {
                                                                if(!isEditing){
                                                                    return (
                                                                        <div className="font-medium">
                                                                            {(this.props.getCommonValue("is_system") === "-") ?
                                                                                '-'
                                                                                :
                                                                                <>
                                                                                    <i className={`mdi ${this.props.getCommonValue("is_system") == '1' ? 'mdi-check text-primary' : 'mdi-close text-danger'} mr-2`} />
                                                                                    <span><Translate id={`misc.${this.props.getCommonValue("is_system") === '1' ? 'yes' : 'no'}`} /></span>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                                return (
                                                                    <FormikSelect
                                                                        autoFocus menuIsDefaultOpen
                                                                        name="is_system" id="is_system"
                                                                        onOptionSelected={() => {
                                                                            options.stopEditing();
                                                                        }}
                                                                        search={false}
                                                                        defaultData={[
                                                                            { value: '1', label: 'misc.yes', translateLabel: true },
                                                                            { value: '0', label: 'misc.no', translateLabel: true },
                                                                        ]}
                                                                        loadingStatus='success'
                                                                    />
                                                                )

                                                            }}
                                                        </FormikEditable>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="text-muted"><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.status.title' /></div>
                                                        <FormikEditable
                                                            id='active'
                                                            noConfirmation
                                                            disabled={!this.canEditRole()}
                                                            initialValues={{
                                                                active: this.props.getCommonValue("active"),
                                                            }}
                                                            validationSchema={Yup.object().shape({
                                                                active: Yup.string().required(<Translate id='organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.active.required' />),
                                                            })}
                                                            onSubmit={(values) => {
                                                                if(values.active !== '-'){
                                                                    this.setState(() => ({ isLoading: true }));
                                                                    const newValues = this.props.createNewValues(values);
                                                                    roles.updateRole(this.getRoleIdsForApi(), newValues, this.props.OrganizationContext.organisation_id)
                                                                        .then(() => {
                                                                            this.props.syncRows(newValues);
                                                                            success();
                                                                        }).catch((error) => {
                                                                            if(!AxiosIsCancelled(error.message)){
                                                                                console.error(error.message)
                                                                                fail({
                                                                                    msg: 'misc.error',
                                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                    skipInfoTranslate: true,
                                                                                })
                                                                            }
                                                                        })
                                                                        .finally(() => this.setState(() => ({ isLoading: false })))
                                                                }
                                                            }}
                                                        >
                                                            {(isEditing, options) => {
                                                                if(!isEditing){
                                                                    return (
                                                                        <div className="font-medium">
                                                                            {(this.props.getCommonValue("active") === "-") ?
                                                                                '-'
                                                                                :
                                                                                <>
                                                                                    <i className={`mdi ${this.props.getCommonValue("active") == '1' ? 'mdi-check text-primary' : 'mdi-close text-danger'} mr-2`} />
                                                                                    <span><Translate id={`organization.profile.roles.sidePanelOrganizationRoles.roleInfo.status.value.${this.props.getCommonValue("active") === '1' ? 'active' : 'inactive'}`} /></span>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                                return (
                                                                    <FormikSelect
                                                                        autoFocus menuIsDefaultOpen
                                                                        name="active" id="active"
                                                                        onOptionSelected={() => {
                                                                            options.stopEditing();
                                                                        }}
                                                                        search={false}
                                                                        defaultData={[
                                                                            { value: '1', label: 'organization.profile.roles.sidePanelOrganizationRoles.roleInfo.status.value.active', translateLabel: true },
                                                                            { value: '0', label: 'organization.profile.roles.sidePanelOrganizationRoles.roleInfo.status.value.inactive', translateLabel: true },
                                                                        ]}
                                                                        loadingStatus='success'
                                                                    />
                                                                )

                                                            }}
                                                        </FormikEditable>
                                                    </div>
                                                    {/* {this.props.selectedRows.length === 1 && this.props.selectedRows[0].is_system === '1' &&
                                                    <div className="mb-3">
                                                        <div className="text-muted"><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.isSytem.title'/></div>
                                                        <FormikEditable
                                                            noConfirmation
                                                            disabled={this.canEditRole()}
                                                            initialValues={{
                                                                system: this.props.selectedRows[0].is_system
                                                            }}
                                                            validationSchema={Yup.object().shape({
                                                                system: Yup.string().required(<Translate id='organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.active.required'/>)
                                                            })}
                                                            onSubmit={(values) => {
                                                                if(values.active !== '-'){
                                                                    const newValues = this.props.createNewValues(values);
                                                                    roles.updateRole(this.getRoleIdsForApi(), newValues, this.props.OrganizationContext.organisation_id)
                                                                        .then(() => {
                                                                            this.props.syncRows(newValues)
                                                                        }).catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                                }
                                                            }}
                                                        >
                                                            {(isEditing, options) => {
                                                                if(!isEditing){
                                                                    return (
                                                                        <div className="font-medium">
                                                                            {(this.props.getCommonValue("active") === "-") ?
                                                                                '-'
                                                                            :
                                                                                <>
                                                                                    <i className={`mdi ${this.props.getCommonValue("active") == '1' ? 'mdi-check text-primary' : 'mdi-close text-danger'} mr-2`}/>
                                                                                    <span><Translate id={`organization.profile.roles.sidePanelOrganizationRoles.roleInfo.status.value.${this.props.getCommonValue("active") === '1' ? 'active' : 'inactive'}`}/></span>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <FormikSelect autoFocus defaultMenuIsOpen name="system" id="system"
                                                                            onChange={() => {
                                                                                options.stopEditing();
                                                                            }}
                                                                        >
                                                                            <SpordleSelect.Option id='active' value='1' label='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.status.value.active' translateLabel selected={this.props.getCommonValue("active") == '1'}/>
                                                                            <SpordleSelect.Option id='failed' value='0' label='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.status.value.inactive' translateLabel selected={this.props.getCommonValue("active") == '0'}/>
                                                                        </FormikSelect>
                                                                    )
                                                                }
                                                            }}
                                                        </FormikEditable>
                                                    </div>
                                                } */}

                                                    {/* START DATE / END DATE */}
                                                    {/* <div className='mb-3'>
                                                    <div className='text-muted'><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.dates'/></div>
                                                    <I18nContext.Consumer>
                                                        {({getGenericLocale}) => (
                                                            <FormikEditable
                                                                manualIcon
                                                                initialValues={{
                                                                    start_date: !!this.props.getCommonValue('start_date') ? moment(this.props.getCommonValue('start_date')): '',
                                                                    expiration_date: !!this.props.getCommonValue('expiration_date') ? moment(this.props.getCommonValue('expiration_date')): '',
                                                                }}
                                                                validationSchema={Yup.object().shape({
                                                                    start_date: Yup.mixed()
                                                                        .test({
                                                                            name: 'start_date_format',
                                                                            message: <Translate id='organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.startDate.format'/>,
                                                                            test: (value) => {
                                                                                if(value !== undefined){ // undefined means empty field
                                                                                    return moment.isMoment(value);
                                                                                } else {
                                                                                    return true; // because not required
                                                                                }
                                                                            }
                                                                        })
                                                                        .when('expiration_date', {
                                                                            is: (expiration_date) => expiration_date !== undefined && moment.isMoment(expiration_date),
                                                                            then: Yup.mixed()
                                                                                .test({
                                                                                    name: 'start_date_expiration_date_check',
                                                                                    message: <Translate id='organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.dates'/>,
                                                                                    test: function(value) { return moment(value).isBefore(this.parent.expiration_date) }
                                                                                })
                                                                        }),
                                                                    expiration_date: Yup.mixed()
                                                                        .test({
                                                                            name: 'expiration_date_format',
                                                                            message: <Translate id='organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.endDate.format'/>,
                                                                            test: (value) => {
                                                                                if(value !== undefined){ // undefined means empty field
                                                                                    return moment.isMoment(value);
                                                                                } else {
                                                                                    return true; // because not required
                                                                                }
                                                                            }
                                                                        }),
                                                                })}
                                                                onSubmit={(values) => {
                                                                    let APIReadyValues = {
                                                                        start_date: !!values.start_date ? moment(values.start_date).toISOString() : null,
                                                                        expiration_date: !!values.expiration_date ? moment(values.expiration_date).toISOString() : null
                                                                    }

                                                                    const newValues = this.props.createNewValues(APIReadyValues);
                                                                    roles.updateRole(this.getRoleIdsForApi(), newValues)
                                                                        .then(() => {
                                                                            this.props.syncRows(newValues);
                                                                        }).catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                                                }}
                                                            >
                                                                {(isEditing, options, formik) => {
                                                                    if(!isEditing){
                                                                        return(
                                                                            <div className='ml-3'>
                                                                                <div>
                                                                                    <span className='text-muted'><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.from'/> - </span>
                                                                                    {!!this.props.getCommonValue('start_date') ?
                                                                                        moment(this.props.getCommonValue('start_date')).locale(getGenericLocale()).format('LL')
                                                                                    :
                                                                                        <Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.dates.none'/>
                                                                                    }
                                                                                    <i className='mdi mdi-pencil text-primary ml-2' />
                                                                                </div>
                                                                                <div>
                                                                                    <span className='text-muted'><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.until'/> - </span>
                                                                                    {!!this.props.getCommonValue('expiration_date') ?
                                                                                        moment(this.props.getCommonValue('expiration_date')).locale(getGenericLocale()).format('LL')
                                                                                    :
                                                                                        <Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.dates.none'/>
                                                                                    }
                                                                                    <i className='mdi mdi-pencil text-primary ml-2' />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    } else {
                                                                        return(
                                                                            <div className='ml-3'>
                                                                                <FormGroup className='flex-column mb-2'>
                                                                                    <div className='d-flex align-items-center'>
                                                                                        <Label for='start_date' className='text-muted mb-0 mr-2'><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.from'/></Label>
                                                                                        <FormikDateTime
                                                                                            id='start_date'
                                                                                            name='start_date'
                                                                                            timeFormat={false}
                                                                                            //isValidDate={(current) => moment(current).isAfter(moment().subtract(1, "days"))}
                                                                                            locale={getGenericLocale()}
                                                                                            onChange={(value) => {
                                                                                                formik.setFieldTouched('start_date', true, false);
                                                                                            }}
                                                                                            manualError
                                                                                        />
                                                                                    </div>
                                                                                    <FormikError name='start_date'/>
                                                                                </FormGroup>
                                                                                <FormGroup className='flex-column'>
                                                                                    <div className='d-flex align-items-center'>
                                                                                        <Label for='expiration_date' className='text-muted mb-0 mr-2'><Translate id='organization.profile.roles.sidePanelOrganizationRoles.roleInfo.until'/></Label>
                                                                                        <FormikDateTime
                                                                                            id='expiration_date'
                                                                                            name='expiration_date'
                                                                                            timeFormat={false}
                                                                                            //isValidDate={(current) => moment(current).isAfter(moment())}
                                                                                            locale={getGenericLocale()}
                                                                                            onChange={(value) => {
                                                                                                formik.setFieldTouched('expiration_date', true, false);
                                                                                                formik.setFieldTouched('start_date', true, false); // to trigger the validation for start_date < end_date
                                                                                            }}
                                                                                            manualError
                                                                                        />
                                                                                    </div>
                                                                                    <FormikError name='expiration_date'/>
                                                                                </FormGroup>
                                                                            </div>
                                                                        )
                                                                    }
                                                                }}
                                                            </FormikEditable>
                                                        )}
                                                    </I18nContext.Consumer>
                                                </div> */}
                                                </div>
                                            )}
                                        </RolesContext.Consumer>

                                        {/*--------------------------------------------------------------------------------*/}
                                        {/* Component Permissions                                                          */}
                                        {/*--------------------------------------------------------------------------------*/}
                                        <SidePanelOrganizationRolesPermission
                                            key={JSON.stringify(this.getRoleIdsForApi(true))}
                                            ref={(r) => this.rolePermissionRef = r}
                                            openPermissionsModal={this.openPermissionsModal}
                                            roleIds={this.getRoleIdsForApi(true)}
                                            setRole={this.setRole}
                                        />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="border-bottom">
                                            <SidePanelOrganizationRolesUsers
                                                loadingStatus={this.state.loadingUsers}
                                                usersList={this.state.roleUsers}
                                            />
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </OverlayLoader>
                        </Fade>
                        : null}
            </>
        )
    }
}

export default withContexts(RolesContext, OrganizationContext, IdentityRolesContext)(SidePanelOrganizationRoles);
