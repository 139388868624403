export default{
    "members.profile.overview.memberDocuments.title"                : "Documents",
    "members.profile.overview.memberDocuments.modal.title"          : "Add Documents",
    "members.profile.overview.memberDocuments.sidePanel.delete.msg" : "You are about to delete this document",

    "members.profile.overview.memberDocuments.form.label.document"                   : "Document",
    "members.profile.overview.memberDocuments.form.label.documentName"               : "Document name",
    "members.profile.overview.memberDocuments.form.label.documentType"               : "Document type",
    "members.profile.overview.memberDocuments.form.label.review_status"              : "Approval Status",
    "members.profile.overview.memberDocuments.form.label.expiration_date"            : "Expiration Date",
    "members.profile.overview.memberDocuments.form.label.note"                       : "Note",
    "members.profile.overview.memberDocuments.form.document_type.note.required"      : "A note explaining the document is required to set a document type of 'Other'.",
    "members.profile.overview.memberDocuments.form.validation.documentType.required" : "Document type is required",
    "members.profile.overview.memberDocuments.form.validation.note.required"         : "Please enter a note",

    "members.profile.overview.memberDocuments.empty.helper" : "You can add a document",
    "members.profile.overview.memberDocuments.add.btn"      : "Add a document",
}