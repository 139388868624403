import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import { DisplayPhoneNumber } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Button, Card, Container } from "reactstrap";
import { AxiosIsCancelled } from "../../../api/CancellableAPI";
import CanDoAction from "../../../components/permissions/CanDoAction";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { getLocation } from "../../teams/TeamHelpers";
import PayeesAddModal from "./PayeesAddModal";
import PayeesSearch from "./PayeesSearch";
import PayeesSidepanel from "./PayeesSidepanel";

const Payees = () => {
    const orgContext = useContext(OrganizationContext);

    const [ payeeAddModalIsOpen, setPayeeAddModalIsOpen ] = useState(false);
    const togglePayeeAddModal = () => setPayeeAddModalIsOpen(!payeeAddModalIsOpen);

    return (
        <>
            {/* Settings View Header */}
            <ViewHeaderV2
                title='sidebar.settings.payees'
                breadcrumbsProps={{
                    disablePeriodSelect: true,
                }}
            />

            <div className="px-4">
                <Container>
                    <SpordlePanelTable
                        allowOutsideClick
                        sidePanel={(sProps) => <PayeesSidepanel {...sProps} />}
                        dataIndex='payee_id'
                        table={(panelProps) => {
                            return (
                                <SpordleTableProvider
                                    id='PayeesTable'
                                    ref={(r) => { panelProps.spordleTableRef(r); }}
                                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                    dataIndex='payee_id'
                                    columns={[
                                        {
                                            label: <Translate id='form.fields.name' />,
                                            key: "first_name",
                                            sortKey: "first_name",
                                            fallbackSortKey: "last_name",
                                            mobile: true,
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='settings.payees.businessName' />,
                                            key: "business_name",
                                            sortKey: "business_name",
                                            mobile: true,
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='form.fields.address' />,
                                            key: "street_number",
                                            mobile: true,
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='form.fields.phone' />,
                                            key: "phone",
                                            mobile: true,
                                            sortable: true,
                                        },
                                    ]}
                                    searchKeys={[
                                        'first_name',
                                        'last_name',
                                        'business_name',
                                        'phone',
                                    ]}
                                    desktopWhen
                                    pagination={10}
                                    onColumnClick={(e, user) => {
                                        switch (e.button){
                                            case 0: // Left mouse button
                                                panelProps.onSingleSelectChange(user);
                                                break;
                                        }
                                    }}
                                    renderRow={(columnKey, payee, spordleTable) => {
                                        switch (columnKey){
                                            case 'first_name':
                                                if(payee.first_name){
                                                    return (
                                                        <>
                                                            {payee.prefix &&
                                                                <Translate id={`settings.payees.prefix.${payee.prefix.toUpperCase()}`} defaultMessage={payee.prefix} />
                                                            } {`${payee.first_name} ${payee.last_name}`}
                                                        </>
                                                    )
                                                }

                                                return '-';
                                            case 'street_number':
                                                if(payee.street_number){
                                                    return getLocation(payee);
                                                }
                                                return '-';
                                            case 'business_name':
                                                return payee.business_name || '-';
                                            case 'phone':
                                                if(payee.phone){
                                                    return <DisplayPhoneNumber phoneString={payee.phone} format='INTERNATIONAL' withExtension />
                                                }
                                                return '-';
                                            default:
                                                break;
                                        }
                                    }}
                                    rowIsHighlighted={(user) => !!user.checked}
                                    initFilter={{
                                        first_name: '',
                                        last_name: '',
                                        service_type_id: '',
                                    }}
                                    loadData={(from, extra, spordleTable) => {
                                        switch (from){
                                            case 'FILTER':
                                            case 'REFRESH':
                                                spordleTable.setLoading();
                                                return orgContext.getPayees(extra.filters)
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                        }
                                                    })
                                            case 'CDM':
                                                return Promise.resolve([]);

                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    <>
                                        <Card body className="card-shadow">
                                            <PayeesSearch />
                                        </Card>

                                        <Card body className="card-shadow">
                                            <div className="d-flex align-items-center mb-3">
                                                <SpordleTableProvider.SearchInput />

                                                <div className='ml-auto'>
                                                    <CanDoAction action='ADD' componentCode='organization' componentPermissionCode='payee_management'>
                                                        <Button type='button' color='primary' onClick={togglePayeeAddModal}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                        <PayeesAddModal isOpen={payeeAddModalIsOpen} toggle={togglePayeeAddModal} />
                                                    </CanDoAction>
                                                </div>
                                            </div>
                                            <SpordleTableView />
                                        </Card>
                                    </>
                                </SpordleTableProvider>
                            )
                        }}
                    />
                </Container>
            </div>
        </>
    )
}

export default Payees;