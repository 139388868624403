import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';
import { PeriodsContext } from './contexts';
import { jsObjectToApi } from '@spordle/helpers';


/** @type {React.Context<Omit<InjuriesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const InjuriesContext = createContext();
InjuriesContext.displayName = 'InjuriesContext';

class InjuriesContextProvider extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            attachments: null,
            causes: null,
            conditions: null,
            equipments: null,
            natures: null,
            onSiteCares: null,
            payTos: null,
            bodyParts: null,
            absences: null,
            environments: null,
            status: null,
        };
    }

    _updateState = (val) => {
        this.setState(val);
    }

    /**
     * [GET] Gets all injuries informations by parameters.
     * @param {object} queryParams
     * @param {string} queryParams.all
     * @param {string} queryParams.period_id
     * @param {string} queryParams.organisation_id
     * @param {string} queryParams.member_id
     * @param {string} queryParams.team_id
     * @returns {Promise.<object[]>} Returns the list of injuries
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injuries/a5d8ed8f38a739a2892f6f5464f23607|documentation}
     */
    getInjuryList = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injuries`,
            query: {
                ...queryParams,
                organisation_id: queryParams.organisation_id,
                period_id: queryParams.period_id,
                team_id: queryParams.team_id,
                member_id: queryParams.member_id,
                all: queryParams.all,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.injuries;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Gets a specific injury info
     * @param {string} injury_id ID of the injury.
     * @returns {Promise.<object>} An object containing the injury's info
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injuries/82fe13e7357b5236b46d8b2c3bb845ee|documentation}
     */
    getInjury = (injury_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injuries/${injury_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.injuries;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Creates a new injury
     * @param {object} values
     * @returns {Promise.<object>} The newly created injury id and it's attachments
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injuries/ee0c7c14f2b7b88578caa05a32b2629a|documentation}
     */
    createInjury = (values) => {
        const params = new FormData();

        for(const key in values){
            if(key === "attachments"){
                for(let a = 0; a < values[key].length; a++){
                    const att = values[key][a];
                    params.append(`${key}[${a}][attachment]`, att.attachment);
                    params.append(`${key}[${a}][active]`, att.active);
                }
            }else if(values[key]){
                params.append(key, values[key]);
            }
        }

        params.append("ignore_injury_status", 1);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/injuries` }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.injury_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PUT] Updates an injury
     * @param {string} injury_id ID of the injury.
     * @param {object} values
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injuries/8f38b3b901cfed08f499e0c6a6d99e15|documentation}
     */
    updateInjury = (injury_id, values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params, { skipNull: true });

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/injuries/${injury_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes an injury
     * @param {string} injury_id ID of the injury.
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injuries/8f38b3b901cfed08f499e0c6a6d99e15|documentation}
     */
    deleteInjury = (injury_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/injuries/${injury_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Injury Cheques
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * [GET] Get injury absences by organisation id in parameter
     * @param {string} organisation_id
     * @param {object} params Refer to documentation
     * @param {boolean} fromApi
     * @returns {Promise.<object[]>} Returns the list of injuries
     * @throws {Error}
     * @see Refer to the {@link |documentation}
     */
    getInsuranceChequeList = (organisation_id, params, fromApi = false) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/insurance/cheques`,
            query: {
                organisation_id: organisation_id || this.props.OrganizationContext.organisation_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.injury_cheques;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Creates a new injury cheque
     * @param {string} injury_id
     * @param {object} values
     * @returns {Promise.<object>} The newly created injury id and it's attachments
     * @throws {Error}
     * @see Refer to the {@link |documentation}
     */
    createInsuranceCheque = (injury_id, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.post(
            queryString.stringifyUrl({
                url: `/insurance/${injury_id}/cheques`,
            }),
            params,
        )
            .then((response) => {
                if(response.data.status){
                    return response.data.injury_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PUT] Prints injury cheques
     * @param {object} values
     * @returns {Promise.<object>} The newly created injury id and it's attachments
     * @throws {Error}
     * @see Refer to the {@link |documentation}
     */
    printInsuranceCheques = (values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.put(
            queryString.stringifyUrl({
                url: `/insurance/cheques/print`,
            }, {
                arrayFormat: 'comma',
                skipEmptyString: true,
                skipNull: true,
            }),
            params,
        )
            .then((response) => {
                if(response.data.status){
                    return response.data.download_link;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PUT] Prints injury cheques worksheet
     * @param {obj} values
     * @returns {Promise.<object>} The newly created injury id and it's attachments
     * @throws {Error}
     * @see Refer to the {@link |documentation}
     */
    printInsuranceWorksheet = (values) => {
        return API_SPORDLE.get(
            queryString.stringifyUrl({
                url: `/insurance/worksheet`,
                query: {
                    ...values,
                },
            }, {
                arrayFormat: 'comma',
                skipEmptyString: true,
                skipNull: true,
            }),
        )
            .then((response) => {
                if(response.data.status){
                    return response.data.download_link;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] Partially updates an existing insurance cheque
     * @param {string} injury_id
     * @param {string} injury_cheque_id
     * @param {object} values
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link |documentation}
     */
    partiallyUpdateInsuranceCheque = (injury_id, injury_cheque_id, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(
            queryString.stringifyUrl({
                url: `/insurance/${injury_id}/cheques/${injury_cheque_id}`,
            }),
            params,
        )
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] Voids a cheque
     * @param {string} injury_id
     * @param {string} injury_cheque_id
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link |documentation}
     */
    voidInsuranceCheque = (injury_id, injury_cheque_id) => {
        return API_SPORDLE.patch(
            queryString.stringifyUrl({
                url: `/insurance/${injury_id}/cheques/${injury_cheque_id}/void`,
            }),
        )
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes an insurance cheque without a cheque number
     * @param {string} injury_id
     * @param {string} injury_cheque_id
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link |documentation}
     */
    deleteInsuranceCheque = (injury_id, injury_cheque_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/insurance/${injury_id}/cheques/${injury_cheque_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((res) => {
                if(res.data.status){
                    return true;
                }
                throw res.data.errors[0];
            }, serverError)
    }

    //#endregion Injury Cheques


    ////////////////////////////////////////////////////////////////////////////////////
    //#region Injury Absences
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * [GET] Get injury absences by organisation id in parameter
     * @param {string} organisation_id
     * @param {object} params Refer to documentation
     * @param {boolean} fromApi
     * @returns {Promise.<object[]>} Returns the list of injuries
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injuries/a5d8ed8f38a739a2892f6f5464f23607|documentation}
     */
    getInjuryAbsenceList = (organisation_id, params, fromApi = false) => {
        if(!fromApi && Array.isArray(this.state.absences)){
            return this.state.absences;
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-absences`,
            query: {
                organisation_id: organisation_id || this.props.OrganizationContext.organisation_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this._updateState({ absences: response.data.injury_absences });
                    return response.data.injury_absences;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get specific injury absence
     * @param {string} injury_absence_id
     * @returns {Promise.<object>} An object containing the injury's info
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Absences/c31aae782a1e1b379eb30d2f7ded2047|documentation}
     */
    getInjuryAbsence = (injury_absence_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-absences/${injury_absence_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.injury_absences;
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Injury Absences

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Injury Attachments
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * [GET] Get all injury attachements
     * @param {string} injury_id ID of the injury.
     * @param {object} params Refer to documentation
     * @param {boolean} fromApi
     * @returns {Promise.<object[]>} Returns the list of injury causes
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Attachments/e05aa701c379af53a129f96e99970050|documentation}
     */
    getInjuryAttachmentList = (injury_id, params, fromApi = false) => {
        if(!fromApi && Array.isArray(this.state.attachments)){
            return this.state.attachments;
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injuries/${injury_id}/attachments`,
            query: params,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this._updateState({ attachments: response.data.attachments });
                    return response.data.attachments;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get the temporary full path of an attachment
     * @param {string} injury_id
     * @param {string} injury_attachment_id
     * @returns {Promise.<string>} Returns a temporary full path of an attachment
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Attachments/d8524e22e4122e99df4ccaf2e067dfb2|documentation}
     */
    downloadInjuryAttachment = (injury_id, injury_attachment_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injuries/${injury_id}/attachments/${injury_attachment_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return {
                        full_path: response.data.full_path,
                        preview_full_path: response.data.preview_full_path,
                        original_file_mime_type: response.data.original_file_mime_type,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Creates injury attachments
     * @param {string} injury_id
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Attachments/d942765b2fde357d3234324dc5dd765d|documentation}
     * @returns {Promise.<object[]>}
     */
    createInjuryAttachments = (injury_id, files, queryParams = {}) => {
        const params = new FormData();

        for(let f = 0; f < files.length; f++){
            const file = files[f];
            params.append(`attachments[${f}][document_type_id]`, file.document_type_id);
            params.append(`attachments[${f}][attachment]`, file.attachment);
            params.append(`attachments[${f}][active]`, file.active);
        }

        if(queryParams.ignore_injury_status){
            params.append('ignore_injury_status', queryParams.ignore_injury_status);
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/injuries/${injury_id}/attachments` }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((res) => {
                if(res.data.status){
                    return res.data.injury_attachments;
                }
                throw res.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes a specific injury attachment
     * @param {string} injury_id
     * @param {string} injury_attachment_id
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Attachments/9fab4261ebc7679bc91837ffc926cc6d|documentation}
     */
    deleteInjuryAttachment = (injury_id, injury_attachment_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/injuries/${injury_id}/attachments/${injury_attachment_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Injury Attachments

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Injury Causes
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * [GET] Get injury causes by organisation id in parameter
     * @param {string} organisation_id
     * @param {object} params Refer to documentation
     * @param {boolean} fromApi
     * @returns {Promise.<object[]>} Returns the list of injury causes
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Causes/9bfc16be9687b42a33c2e7dca58879c3|documentation}
     */
    getInjuryCauseList = (organisation_id, params, fromApi = false) => {
        if(!fromApi && Array.isArray(this.state.causes)){
            return this.state.causes;
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-causes`,
            query: {
                organisation_id: organisation_id || this.props.OrganizationContext.organisation_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this._updateState({ causes: response.data.injury_causes });
                    return response.data.injury_causes;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get specific injury cause
     * @param {string} injury_cause_id
     * @returns {Promise.<object>} An object containing the injury cause's info
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Causes/857f996a8259fee2793444ac65e9df32|documentation}
     */
    getInjuryCause = (injury_cause_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-causes/${injury_cause_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.injury_causes;
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Injury Causes

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Injury Conditions
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * [GET] Get injury conditions
     * @param {string} organisation_id
     * @param {object} params Refer to documentation
     * @param {boolean} fromApi
     * @returns {Promise.<object[]>} Returns the list of injury causes
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Conditions/69ff67e78c18719d432bde6003c97122|documentation}
     */
    getInjuryConditionList = (organisation_id, params, fromApi = false) => {
        if(!fromApi && Array.isArray(this.state.conditions)){
            return this.state.conditions;
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-conditions`,
            query: {
                organisation_id: organisation_id || this.props.OrganizationContext.organisation_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this._updateState({ conditions: response.data.injury_conditions });
                    return response.data.injury_conditions;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get a specific injury condition
     * @param {string} injury_condition_id
     * @returns {Promise.<object>} An object containing the injury condition's info
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Conditions/a7fd3a1f7746377d4d9f5021dc5f95cf|documentation}
     */
    getInjuryCondition = (injury_condition_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-conditions/${injury_condition_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.injury_conditions;
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Injury Conditions

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Injury Equipments
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * [GET] Get injury equipments by organisation id in parameter
     * @param {string} organisation_id
     * @param {object} params Refer to documentation
     * @param {boolean} fromApi
     * @returns {Promise.<object[]>} Returns the list of injury equipments
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Equipments/5830f14456865d541a33de99a6b3045e|documentation}
     */
    getInjuryEquipmentList = (organisation_id, params, fromApi = false) => {
        if(!fromApi && Array.isArray(this.state.equipments)){
            return this.state.equipments;
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-equipments`,
            query: {
                organisation_id: organisation_id || this.props.OrganizationContext.organisation_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this._updateState({ equipments: response.data.injury_equipments });
                    return response.data.injury_equipments;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get a specific injury equipment
     * @param {string} injury_equipment_id
     * @returns {Promise.<object>} An object containing the injury equipment's info
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Equipments/f4f6d9915f64950ce0af2fd7c3ab9fcd|documentation}
     */
    getInjuryEquipment = (injury_equipment_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-equipments/${injury_equipment_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.injury_equipments;
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Injury Equipments

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Injury Natures
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * [GET] Get injury natures by organisation id in parameter
     * @param {string} organisation_id
     * @param {object} params Refer to documentation
     * @param {boolean} fromApi
     * @returns {Promise.<object[]>} Returns the list of injury natures
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Natures/f739b5926377f1bfd1cee977a5081c06|documentation}
     */
    getInjuryNatureList = (organisation_id, params, fromApi = false) => {
        if(!fromApi && Array.isArray(this.state.natures)){
            return this.state.natures;
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-natures`,
            query: {
                organisation_id: organisation_id || this.props.OrganizationContext.organisation_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this._updateState({ natures: response.data.injury_natures });
                    return response.data.injury_natures;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get a specific injury nature
     * @param {string} injury_nature_id
     * @returns {Promise.<object>} An object containing the injury nature's info
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Natures/9adab6ab46da05c95974146ff90bd839|documentation}
     */
    getInjuryNature = (injury_nature_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-natures/${injury_nature_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.injury_natures;
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Injury Natures

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Injury On Site Cares
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * [GET] Get injury on site cares by organisation id in parameter
     * @param {string} organisation_id
     * @param {object} params Refer to documentation
     * @param {boolean} fromApi
     * @returns {Promise.<object[]>} Returns the list of injury on site cares
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20On%20Site%20Cares/5aee81075c1feb0bed907c0786ad7bd0|documentation}
     */
    getInjuryOnSiteCareList = (organisation_id, params, fromApi = false) => {
        if(!fromApi && Array.isArray(this.state.onSiteCares)){
            return this.state.onSiteCares;
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-on-site-cares`,
            query: {
                organisation_id: organisation_id || this.props.OrganizationContext.organisation_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this._updateState({ onSiteCares: response.data.injury_on_site_cares });
                    return response.data.injury_on_site_cares;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get a specific injury on site care
     * @param {string} injury_on_site_care_id
     * @returns {Promise.<object>} An object containing the injury on site care's info
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20On%20Site%20Cares/5296327a2ec4ce1ea54cf7a2eaf8fa20|documentation}
     */
    getInjuryOnSiteCare = (injury_on_site_care_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-on-site-cares/${injury_on_site_care_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.injury_on_site_care;
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Injury On Site Cares

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Injury Pay To
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * [GET] Get Injury Pay To informations by parameters
     * @param {string} organisation_id
     * @param {object} params Refer to documentation
     * @param {boolean} fromApi
     * @returns {Promise.<object[]>} Returns the list of injury Pay To
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Pay%20To/fff9a07b272a525e1768d32487aced0d|documentation}
     */
    getInjuryPayToList = (organisation_id, params, fromApi = false) => {
        if(!fromApi && Array.isArray(this.state.payTos)){
            return this.state.payTos;
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-pay-to`,
            query: {
                organisation_id: organisation_id || this.props.OrganizationContext.organisation_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this._updateState({ payTos: response.data.injury_pay_to });
                    return response.data.injury_pay_to;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get a specific injury on site care
     * @param {string} injury_pay_to_id
     * @returns {Promise.<object>} An object containing the injury on site care's info
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Pay%20To/cc4816add9c0ea680edcec724842a0c4|documentation}
     */
    getInjuryPayTo = (injury_pay_to_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-pay-to/${injury_pay_to_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.injury_pay_to;
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Injury Pay To

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Injury Environments
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * [GET] Get injury environments by organisation id in parameter
     * @param {string} organisation_id
     * @param {object} params Refer to documentation
     * @param {boolean} fromApi
     * @returns {Promise.<object[]>} Returns the list of injury on site cares
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Environments/4467fda25f9660059e0008167a6a5228|documentation}
     */
    getInjuryEnvironmentList = (organisation_id, params, fromApi = false) => {
        if(!fromApi && Array.isArray(this.state.environments)){
            return this.state.environments;
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-environments`,
            query: {
                organisation_id: organisation_id || this.props.OrganizationContext.organisation_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this._updateState({ environments: response.data.injury_environments });
                    return response.data.injury_environments;
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Injury Environments

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Injury Status
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * [GET] Get injury status by organisation id in parameter
     * @param {string} organisation_id
     * @param {object} params Refer to documentation
     * @param {boolean} fromApi
     * @returns {Promise.<object[]>} Returns the list of injury status
     * @throws {Error}
     * @see Refer to the {@link documentation}
     */
    getInjuryStatus = (organisation_id, params, fromApi = false) => {
        if(!fromApi && Array.isArray(this.state.status)){
            return Promise.resolve(this.state.status);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-status`,
            query: {
                organisation_id: organisation_id || this.props.OrganizationContext.organisation_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this._updateState({ status: response.data.injury_status });
                    return response.data.injury_status;
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Injury Status

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Body parts
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * [GET] Get body parts informations by parameters
     * @param {string} organisation_id
     * @param {object} params Refer to documentation
     * @param {boolean} fromApi
     * @returns {Promise.<object[]>} Returns the list of body parts
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Body%20Parts/488a5b5d1f0e380919ff882c170318e1|documentation}
     */
    getBodyPartsList = (organisation_id, params, fromApi = false) => {
        if(!fromApi && Array.isArray(this.state.bodyParts)){
            return this.state.bodyParts;
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/body-parts`,
            query: {
                organisation_id: organisation_id || this.props.OrganizationContext.organisation_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    this._updateState({ bodyParts: response.data.body_parts });
                    return response.data.body_parts;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get a specific body part informations
     * @param {string} body_part_id
     * @returns {Promise.<object>} An object containing the body part info
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Body%20Parts/381d09571a0352ab08eb87228074c06e|documentation}
     */
    getBodyPart = (body_part_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/body-parts/${body_part_id}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.body_parts;
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion Body Parts

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Predermined Notes
    ////////////////////////////////////////////////////////////////////////////////////
    /**
     * [GET] Get all injury cheque predetermined notes by organisation id in parameter
     * @param {string} organisation_id
     * @param {object} params Refer to documentation
     * @returns {Promise.<object[]>} Returns the list of notes
     * @throws {object}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injury%20Cheque%20Predetermined%20Notes/6c10b2c93b6eae5222c089a08612f66e|documentation}
     */
    getPredeterminedNotes = (organisation_id, params) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/injury-cheque-predetermined-notes`,
            query: {
                organisation_id: organisation_id || this.props.OrganizationContext.organisation_id,
                ...params,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.injury_cheque_predetermined_notes;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    //#endregion Predermined Notes

    render(){
        return (
            <InjuriesContext.Provider
                value={{
                    ...this.state,
                    ...this,
                }}
            >
                {this.props.children}
            </InjuriesContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext, PeriodsContext)(InjuriesContextProvider);