import { DisplayPhoneNumber, FormikCheckedButton, FormikError, FormikInputText, FormikPhoneInput, FormikSelect } from "@spordle/formik-elements";
// Language
import Translate from "@spordle/intl-elements";
import { FieldArray } from "formik";
import { useContext } from "react";
import {
    Card,
    Col,
    Collapse,
    FormGroup,
    Label,
    Row
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import { array, bool, object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import FormikEditable from '../../../../../../../components/formik/FormikEditable';
import { fail, success } from '@spordle/toasts';
// Contexts
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { RolesContext } from '../../../../../../../contexts/RolesContext';
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";


const SidePanelMemberContactsInfo = ({
    getCommonValue,
    syncRows,
    createNewValues,
    setLoading,
    ...props
}) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { canDoAction } = useContext(RolesContext);

    return (
        <div className="mb-3 pb-4 border-bottom">
            <div className="h5 font-medium">
                <Translate id='members.profile.overview.memberContacts.sidePanel.contactInfo' />
            </div>
            <FormikEditable
                id='contactInfoFormikEditable'
                className='mr-2'
                initialValues={{
                    emergency: getCommonValue('emergency'),
                    tax_receipt_recipient: getCommonValue('tax_receipt_recipient'),
                    allow_communication: getCommonValue('allow_communication'),
                    email: getCommonValue('email') || '',
                    phones: getCommonValue('phones')?.length > 0 ? getCommonValue('phones')?.map((phone) => ({
                        phoneNumber: phone.phoneNumber.split('#')[0],
                        phoneExtension: phone.phoneNumber.split('#')[1],
                        phoneTypeId: phone.phoneType.phoneTypeId,
                        phoneType: phone.phoneType,
                    })) : [ {
                        phoneNumber: '',
                        phoneExtension: '',
                        phoneTypeId: '',
                        phoneType: '',
                    } ],
                }}
                validationSchema={object().shape({
                    emergency: bool().required(),
                    tax_receipt_recipient: bool().required(),
                    allow_communication: bool().required(),
                    email: string()
                        .email(<Translate id='form.validation.email.valid' />)
                        .test({
                            name: 'emailRequiredTest',
                            message: <Translate id='form.validation.email.required' />,
                            test: function(email){
                                return !(!this.parent.phones[0].phoneNumber || this.parent.emergency || this.parent.allow_communication) || !!email
                            },
                        }),
                    phones: array().of(object().shape({
                        phoneNumber: string()
                            .isValidPhoneNumber(<Translate id='form.validation.phone.valid' />)
                            .test({
                                name: 'isRequired',
                                message: <Translate id="form.validation.phone.required" />,
                                test: function(phone){
                                    if(this.path === 'phones[0].phoneNumber'){ // index 0
                                        // If is an emergency contact, email and phone is mandatory
                                        // If the contact isn't, the contact need to have either an email OR a phone number
                                        const base = this.from[this.from.length - 1]?.value;
                                        return base?.emergency ? !!phone : (!!base.email || !!phone)
                                    }
                                    return !!phone

                                },
                            }),
                        phoneExtension: string(),
                        phoneTypeId: string().test({
                            name: 'isRequired',
                            message: <Translate id='form.validation.phoneType.required' />,
                            test: function(phoneType){
                                if(this.parent.phoneNumber)return !!phoneType;

                                if(this.path === 'phones[0].phoneTypeId'){ // index 0

                                    const base = this.from[this.from.length - 1]?.value;
                                    // Phone is required if emergency
                                    // Else check if there is an email, if there isn't, check if this has a value
                                    return base?.emergency ? !!phoneType : (!!base?.email || !!phoneType);
                                }
                                return !!phoneType

                            },
                        }),
                    })),
                })}
                onSubmit={(values) => {
                    if(!getCommonValue('contactType')){
                        fail({
                            msg: 'misc.error',
                            info: <Translate id='members.profile.overview.memberContacts.sidePanel.contactType.required' />,
                            skipInfoTranslate: true,
                        })
                    }else{
                        setLoading(true)
                        const newValues = createNewValues({
                            ...values,
                            phones: values.phones.reduce((newArr, phone) => {
                                if(phone.phoneNumber){
                                    newArr.push({
                                        phoneNumber: phone.phoneExtension ? `${phone.phoneNumber}#${phone.phoneExtension}` : phone.phoneNumber,
                                        phoneType: phone.phoneType,
                                    })
                                }
                                return newArr;
                            }, []),
                        });
                        props.MembersContext.updateMemberContact(props.MembersContext.currentMember.member_id, getCommonValue('id'), newValues, canDoAction("DELETE", "members", "member_contacts"))
                            .then(async() => {
                                success({ msg: 'members.profile.overview.memberContacts.sidePanel.edit.success' })
                                setLoading(false)
                                syncRows(newValues)
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                    setLoading(false)
                                }
                            })
                    }
                }}
            >
                {(isEditing, _options, formik) => {
                    if(!isEditing){
                        return (
                            <div className="w-100 pr-2">
                                <div className="w-100">
                                    <div className='mb-2 d-flex align-items-center'>
                                        <i className={stringBuilder('mdi mdi-email font-16 mr-1', { 'text-primary': getCommonValue('allow_communication'), 'text-muted': !getCommonValue('allow_communication') })} /><div className="text-muted"><Translate id='members.profile.overview.memberContacts.allow_communication' /></div>:<div className="font-medium ml-1"><Translate id={`misc.yes.no.${!!getCommonValue('allow_communication')}`} /></div>
                                    </div>
                                    <div className='mb-2 d-flex align-items-center'>
                                        <i className={stringBuilder('mdi mdi-ambulance font-16 mr-1', { 'text-danger': getCommonValue('emergency'), 'text-muted': !getCommonValue('emergency') })} /><div className="text-muted"><Translate id='members.profile.overview.memberContacts.emergencyContact' /></div>:<div className="font-medium ml-1"><Translate id={`misc.yes.no.${!!getCommonValue('emergency')}`} /></div>
                                    </div>
                                    <div className='mb-3 d-flex align-items-center'>
                                        <i className={stringBuilder('mdi mdi-cash-usd font-16 mr-1', { 'text-success': getCommonValue('tax_receipt_recipient'), 'text-muted': !getCommonValue('tax_receipt_recipient') })} /><div className="text-muted"><Translate id='members.profile.overview.memberContacts.taxReceiptRecipient' /></div>:<div className="font-medium ml-1"><Translate id={`misc.yes.no.${!!getCommonValue('tax_receipt_recipient')}`} /></div>
                                    </div>
                                    <FormGroup className="">
                                        <div className="text-muted"><Translate id='form.fields.email' /></div>
                                        <div className='font-medium text-truncate'>{getCommonValue('email') || '-'}</div>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className='d-flex flex-column'>
                                            <div className="text-muted"><Translate id='members.profile.overview.memberContacts.sidePanel.phoneNumbers' /></div>
                                            {getCommonValue('phones')?.length > 0 ?
                                                <ul className='list-unstyled mb-0'>
                                                    {getCommonValue('phones').map((phone, index) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <li key={index}>
                                                            <DisplayPhoneNumber format="INTERNATIONAL" phoneString={phone.phoneNumber} /><span className='text-muted'> (<DisplayI18n field='name' defaultValue={phone.phoneType.name} i18n={phone.phoneType.i18n} />)</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                                :
                                                <div className='font-medium'><Translate id='members.profile.overview.memberContacts.sidePanel.phoneNumbers.none' /></div>
                                            }
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div>
                            <FormGroup>
                                <FormikCheckedButton id='allow_communication' name='allow_communication' label='members.profile.overview.memberContacts.allow_communication' translateLabel />
                                <FormikError name='allow_communication' />
                            </FormGroup>
                            <FormGroup>
                                <FormikCheckedButton id='emergency' name='emergency' label='members.profile.overview.memberContacts.emergencyContact' translateLabel />
                                <FormikError name='emergency' />
                            </FormGroup>
                            <FormGroup>
                                <FormikCheckedButton id='tax_receipt_recipient' name='tax_receipt_recipient' label='members.profile.overview.memberContacts.taxReceiptRecipient' translateLabel />
                                <FormikError name='tax_receipt_recipient' />
                            </FormGroup>
                            <FormGroup>
                                <div className="text-muted"><Translate id='form.fields.email' /></div>
                                <FormikInputText id='email' name='email' trim />
                            </FormGroup>
                            <FieldArray name='phones'>
                                {(arrayHelpers) => (
                                    <div className='mb-3'>
                                        {formik.values.phones.map((phone, index) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <Collapse appear isOpen key={index}>
                                                <Card body className='card-shadow mb-3'>
                                                    {index > 0 &&
                                                        <div className="position-absolute top-0 right-0 z-index-1">
                                                            <button type="button" onClick={() => { arrayHelpers.remove(index) }} className="btn btn-link text-danger"><i className="fas fa-trash" /></button>
                                                        </div>
                                                    }
                                                    <FormGroup>
                                                        <Label for={`phones.${index}.phoneNumber`} className='text-muted'><Translate id='form.fields.phone' /></Label>
                                                        <FormikPhoneInput name={`phones.${index}.phoneNumber`} />
                                                    </FormGroup>
                                                    <Row>
                                                        <Col md='6'>
                                                            <Label for={`phones.${index}.phoneExtension`} className='text-muted'><Translate id='form.fields.extension' /></Label>
                                                            <FormikInputText name={`phones.${index}.phoneExtension`} id={`phones.${index}.phoneExtension`} trim />
                                                        </Col>
                                                        <Col md='6'>
                                                            <Label for={`phones.${index}.phoneTypeId`} className='text-muted'><Translate id='form.fields.phoneType' /></Label>
                                                            <FormikSelect
                                                                clearable
                                                                id={`phones.${index}.phoneTypeId`}
                                                                name={`phones.${index}.phoneTypeId`}
                                                                onOptionSelected={([ value ], table) => {
                                                                    const select = table.getSpordleTable().getData().find((type) => type.value === value);
                                                                    // because we also want the object to be sent to the table
                                                                    formik.setFieldValue(
                                                                        `phones.${index}.phoneType`,
                                                                        select ? { i18n: select.i18n, phoneTypeId: select.value, name: select.label } : { i18n: null, phoneTypeId: '', name: null },
                                                                    )
                                                                }}
                                                                renderOption={({ option }) => (
                                                                    <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                                )}
                                                                searchKeys={[
                                                                    `i18n.${getGenericLocale()}.name`,
                                                                ]}
                                                                options={props.phoneTypes ?
                                                                    props.phoneTypes.map((type) => ({
                                                                        ...type,
                                                                        label: type.name,
                                                                        value: type.phone_type_id,
                                                                    }))
                                                                    :
                                                                    []
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Collapse>
                                        ))}
                                        <button className='reset-btn text-primary' type='button' onClick={() => { arrayHelpers.push({ phoneNumber: '', phoneExtension: '', phoneTypeId: '', phoneType: null }) }}><i className='mdi mdi-plus' /> <Translate id='members.profile.overview.memberContacts.addPhone' /></button>
                                    </div>
                                )}
                            </FieldArray>
                        </div>
                    )

                }}
            </FormikEditable>
        </div>
    )
}

export default SidePanelMemberContactsInfo;