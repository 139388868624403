import { DisplayI18n } from "../../../helpers/i18nHelper";
import SettingCode from "./SettingCode";

const SettingLabel = ({ setting, code }) => {
    if(!setting){
        return null;
    }

    return (
        <>
            <div><DisplayI18n field="name" i18n={setting.i18n} defaultValue={setting.name} /></div>
            <SettingCode code={code} />
        </>
    );
}

export default SettingLabel;