export default{
    "tasks.memberTypeRemoval.title" : "Retrait des types de membre",

    "tasks.memberTypeRemoval.columns.member"            : "Membre",
    "tasks.memberTypeRemoval.columns.memberType"        : "Type(s) de membre",
    "tasks.memberTypeRemoval.columns.currentMemberType" : "Type(s) de membre actuel(s)",
    "tasks.memberTypeRemoval.columns.createdBy"         : "Créée par",
    "tasks.memberTypeRemoval.columns.createdAt"         : "Date",
    "tasks.memberTypeRemoval.columns.status"            : "Statut de la requête",

    "tasks.memberTypeRemoval.sidepanel.manage" : "Gérer le retrait",
    "tasks.memberTypeRemoval.sidepanel.count"  : "{count, plural, one {# membre sélectionné} other {# membres sélectionnés}}",
    "tasks.memberTypeRemoval.sidepanel.none"   : "Aucun type de membre",

    "tasks.memberTypeRemoval.modal.title"         : "Gérer le retrait de types de membre",
    "tasks.memberTypeRemoval.modal.remove"        : "Retirer",
    "tasks.memberTypeRemoval.modal.removeConfirm" : "Confirmer le retrait",
    "tasks.memberTypeRemoval.modal.keep"          : "Garder",
    "tasks.memberTypeRemoval.modal.keepConfirm"   : "Confirmer",
}