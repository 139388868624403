export default{
    "organization.settings.organizationPositionGroups.table.empty"               : "Aucun groupe de position",
    "organization.settings.organizationPositionGroups.table.column.status"       : "Statut",
    "organization.settings.organizationPositionGroups.add.title"                 : "Ajouter un groupe de postes",
    "organization.settings.organizationPositionGroups.add.status"                : "Statut",
    "organization.settings.organizationPositionGroups.add.status.active"         : "actif",
    "organization.settings.organizationPositionGroups.add.status.inactive"       : "inactif",
    "organization.settings.organizationPositionGroups.add.name.required"         : "Le nom est obligatoire",
    "organization.settings.organizationPositionGroups.add.status.required"       : "Le statut est obligatoire",
    "organization.settings.organizationPositionGroups.table.column.abbreviation" : "Abréviation",
}