export default{
    "clinics.search.sidePanelClinicSearch.more" : "{more} more",

    // options
    "clinics.search.sidePanelClinicSearch.options.duplicate"          : "Duplicate",
    "clinics.search.sidePanelClinicSearch.duplicate.title"            : "Duplicate",
    "clinics.search.sidePanelClinicSearch.duplicate.text1"            : "Would you like to duplicate this clinic?",
    "clinics.search.sidePanelClinicSearch.duplicate.text2"            : "We will bring you to the final step of the clinic creation so you can make some final changes to your duplicated clinic.",
    "clinics.search.sidePanelClinicSearch.duplicate.confirm"          : "Duplicate",
    "clinics.search.sidePanelClinicSearch.delete.message"             : "You are about to delete",
    "clinics.search.sidePanelClinicSearch.delete.cantDeleteAttendees" : "You cannot delete a clinic with registered attendees.",

    // details tab
    "clinics.search.sidePanelClinicSearch.details.title"      : "Details",
    "clinics.search.sidePanelClinicSearch.details.registered" : "Registered",
    "clinics.search.sidePanelClinicSearch.details.waitlisted" : "Waitlisted",

    "clinics.search.sidePanelClinicSearch.details.category"      : "Category",
    "clinics.search.sidePanelClinicSearch.details.qualification" : "Qualification",
    "clinics.search.sidePanelClinicSearch.details.clinicStatus"  : "Clinic status",
    "clinics.search.sidePanelClinicSearch.details.location"      : "Location",
    "clinics.search.sidePanelClinicSearch.details.revenus"       : "Revenus",
    "clinics.search.sidePanelClinicSearch.details.cost"          : "Cost",
    "clinics.search.sidePanelClinicSearch.details.host"          : "Contact",
    "clinics.search.sidePanelClinicSearch.details.instructor"    : "Instructor",

    "clinics.search.sidePanelClinicSearch.details.sessions"                     : "Sessions",
    "clinics.search.sidePanelClinicSearch.details.sessions.none"                : "No session",
    "clinics.search.sidePanelClinicSearch.details.session"                      : "Session",
    "clinics.search.sidePanelClinicSearch.details.ONSITE"                       : "In Person",
    "clinics.search.sidePanelClinicSearch.details.ONLINE_LIVE"                  : "Online - Live",
    "clinics.search.sidePanelClinicSearch.details.ONLINE"                       : "Online - Recorded/On-Demand",
    "clinics.search.sidePanelClinicSearch.details.UNDEFINED"                    : "Non défini",
    "clinics.search.sidePanelClinicSearch.details.status.OPEN_PUBLIC_SHOW_LIST" : "Open to the public",
    "clinics.search.sidePanelClinicSearch.details.status.OPEN_PUBLIC_SIGNUP"    : "Open registration",
    "clinics.search.sidePanelClinicSearch.details.status.PRIVATE"               : "Private",
    "clinics.search.sidePanelClinicSearch.details.status.DRAFT"                 : "Draft",
    "clinics.search.sidePanelClinicSearch.details.status.UNDER_CREATION"        : "In creation",

    // attendees tab
    "clinics.search.sidePanelClinicSearch.attendees.viewInClinic"          : "View in clinic",
    "clinics.search.sidePanelClinicSearch.attendees.noAttendees"           : "No attendees",
    "clinics.search.sidePanelClinicSearch.attendees.noAttendeesFound"      : "No attendees were found with the current search criteria",
    "clinics.search.sidePanelClinicSearch.attendees.noAttendeesRegistered" : "No attendees are registered in this clinic",
    "clinics.search.sidePanelClinicSearch.waitlist.noAttendees"            : "No waitlisted members",
    "clinics.search.sidePanelClinicSearch.waitlist.noAttendeesFound"       : "No waitlisted members were found with the current search criteria",
    "clinics.search.sidePanelClinicSearch.waitlist.noAttendeesRegistered"  : "No members are in the waitlist for this clinic",
    "clinics.search.sidePanelClinicSearch.attendees.viewList"              : "View list in clinic",
    "clinics.search.sidePanelClinicSearch.attendees.title"                 : "Attendees",
    "clinics.search.sidePanelClinicSearch.attendees.attendees"             : "Attendees",
    "clinics.search.sidePanelClinicSearch.attendees.waitlisted"            : "Waitlisted",
    "clinics.search.sidePanelClinicSearch.attendees.single.havePaid"       : "1 has paid",
    "clinics.search.sidePanelClinicSearch.attendees.multiple.havePaid"     : "{amount} have paid",
    "clinics.search.sidePanelClinicSearch.attendees.guest"                 : "from your organization",
    "clinics.search.sidePanelClinicSearch.attendees.paid"                  : "Paid",
    "clinics.search.sidePanelClinicSearch.attendees.position"              : "Pos",

    // session collapse
    "clinics.search.sidePanelClinicSearch.sessions.start"    : "Start:",
    "clinics.search.sidePanelClinicSearch.sessions.end"      : "End:",
    "clinics.search.sidePanelClinicSearch.sessions.duration" : "Duration:",
    "clinics.search.sidePanelClinicSearch.sessions.url"      : "Session's link",
    "clinics.search.sidePanelClinicSearch.sessions.maps"     : "See on the map",
}