import Translate from '@spordle/intl-elements';
import {
    Button,
    Card,
    Col,
    Collapse,
    Fade,
    FormGroup,
    Label,
    ModalBody,
    ModalFooter, ModalHeader, Row
} from "reactstrap";
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { Form, Formik } from 'formik';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import { useContext, useEffect, useRef, useState } from 'react';
import CustomAnimatedIcon from '../../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import InlineCopy from '../../../../../components/inlineCopy/InlineCopy';
import { object, string } from 'yup';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { PeriodsContext } from '../../../../../contexts/contexts';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { MembersContext } from '../../../../../contexts/MembersContext';
import CustomAlert from '../../../../../components/CustomAlert';
import { FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import { I18nContext } from '../../../../../contexts/I18nContext';
import Required from '../../../../../components/formik/Required';
import { DisplayCategory } from '../../../../teams/TeamHelpers';
import { RegistrationCategoriesContext } from '../../../../../contexts/RegistrationCategoriesContext';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import { fail } from '@spordle/toasts';
import { createMemberClassificationChange, updateClassificationRequestChangeStatus } from '../../../../../api/client/classificationRequestChanges';
import GenderLabel from '../../../../../components/GenderLabel';
import DisplayBirthdateAge from '../../../../../components/DisplayBirthdateAge';
import { getMemberDivisions } from '../../../../../api/client/members';

const RequestClassificationChangeModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName='RequestClassificationChangesModal'>
            <RequestClassificationChangeModalInner {...props} />
        </AnalyticsModal>
    )
}

const RequestClassificationChangeModalInner = ({ isOpen, toggle, member, refreshMemberProfile, forceCloseSidepanel, classificationChangeRequestStatuses }) => {
    const organizationContext = useContext(OrganizationContext);
    const membersContext = useContext(MembersContext);
    const periodsContext = useContext(PeriodsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const categoriesContext = useContext(RegistrationCategoriesContext);
    const identityRolesContext = useContext(IdentityRolesContext);

    // Hide classification if not set or empty.
    // Doesn't matter what the value is
    const showClassification = organizationContext.settings.classification_approval_process_organisation?.value && organizationContext.settings.classification_approval_process_organisation.value.length > 0;

    const fromCatSelectRef = useRef(null);
    const toCatSelectRef = useRef(null);

    const formikRef = useRef(null);

    const [ currentDivision, setCurrentDivision ] = useState(null);
    const [ divOptions, setDivOptions ] = useState({ // Keys need to respact the form's "type" values
        "CLASSIFICATION": [],
        "OVERAGE": [],
        "UNDERAGE": [],
    });
    const [ divOptionsLoading, setDivOptionsLoading ] = useState(false);
    const [ hasDefaultFrom, setHasDefaultFrom ] = useState(false);
    const [ hasDefaultTo, setHasDefaultTo ] = useState(false);
    const [ overageUnderageError, setOverageUnderageError ] = useState(false);

    const [ isSuccess, setIsSuccess ] = useState(false);
    const [ isCancelSuccess, setIsCancelSuccess ] = useState(false);

    // loading status for cancellation API call
    const [ cancellationPending, setCancellationPending ] = useState(false);

    useEffect(() => {
        // set success state to false when opening the modal
        if(isOpen === true){
            setIsSuccess(false);
            getDivisions();
        }
    }, [ isOpen ])

    const pendingClassChangeRequests = membersContext.currentMember.classification_request_changes?.filter((request) => request.request_change_status?.system === "PENDING")
    const classChangeRequest = (pendingClassChangeRequests && pendingClassChangeRequests.length > 0) ? pendingClassChangeRequests[pendingClassChangeRequests.length - 1] : null; // Take last change request in array to get the most recent

    // Get all member divisions for the form on load
    const getDivisions = (periodId) => {
        setDivOptionsLoading(true)
        getMemberDivisions(membersContext.currentMember.member_id, { period_id: periodId || formikRef.current?.values?.period_id || periodsContext.selectedPeriod.period_id, organisation_id: organizationContext.organisation_id })
            .then(({ current_division, overage_divisions, underage_divisions }) => {
                const formatDivOptions = (div) => ({
                    value: div.division_id,
                    label: div.short_name,
                    i18n: div.i18n,
                    birth_year_from: div.birth_year_from,
                    birth_year_to: div.birth_year_to,
                    division_id: div.division_id,
                });
                // Set States
                setCurrentDivision(current_division);
                const newDivOptions = {
                    "CLASSIFICATION": current_division ? [ formatDivOptions(current_division) ] : [],
                    "OVERAGE": overage_divisions.map((div) => (formatDivOptions(div))),
                    "UNDERAGE": underage_divisions.map((div) => (formatDivOptions(div))),
                }
                setDivOptions(newDivOptions);
                setDivOptionsLoading(false);

                // Call function to set options & default values
                setDivisionsSelectOptions(newDivOptions, current_division, null);
            })
    }


    // Set the correct divisions depending on the "Request Type"
    // CLASSIFICATION: use the current division only and disable the select
    // OVERAGE: use overage_divisions
    // UNDERAGE: use underage_divisions
    const setDivisionsSelectOptions = (newDivOptions, current_division, type) => {
        // Placed this logic because this function is called in the inital useEffect and the set states haven't been done yet so the values are undefined or null
        // - For the setDivisions called in the useEffect, the data is passed through the parameters
        // - Otherwise, we use the state
        const myType = type || formikRef.current?.values?.type;
        const myDivOptions = newDivOptions?.[myType] || divOptions[myType];
        const myCurrentDiv = current_division || currentDivision;

        setHasDefaultFrom(false)
        setHasDefaultTo(false)

        if(myDivOptions?.length > 0){

            formikRef.current?.setFieldValue('to_division_id', "")
            formikRef.current?.setFieldValue('from_division_id', "")

            setOverageUnderageError(false)

            if(myCurrentDiv){
                formikRef.current?.setFieldValue('from_division_id', myCurrentDiv.division_id)
                fromCatSelectRef.current?.getSpordleTable().filterChange('division_id', myCurrentDiv.division_id) // trigger category select reload with new default division
                setHasDefaultFrom(true)

                if(myType === 'CLASSIFICATION'){
                    formikRef.current?.setFieldValue('to_division_id', myCurrentDiv.division_id)
                    toCatSelectRef.current?.getSpordleTable().filterChange('division_id', myCurrentDiv.division_id) // trigger category select reload with new default division
                    setHasDefaultTo(true)
                }
            }
        }else{
            // If no divisions are retunred for the member divisions, we must let the user know that no options are available.
            setHasDefaultFrom(true)
            setHasDefaultTo(true)
            formikRef.current?.setFieldValue('to_division_id', "")
            formikRef.current?.setFieldValue('from_division_id', "")
            setOverageUnderageError(<Translate id="members.profile.classification.change.noAvailableDivisions" />)
        }
    }

    return (

        <Formik
            innerRef={formikRef}
            initialValues={{
                type: showClassification ? 'CLASSIFICATION' : '',
                period_id: periodsContext.selectedPeriod.period_id || '',
                from_division_id: '',
                to_division_id: '',
                from_team_category_id: '',
                to_team_category_id: '',
                request_note: '',
            }}
            validationSchema={object().shape({
                type: string().required(<Translate id="members.profile.classification.change.fields.type.required" />),
                period_id: string().required(),
                from_division_id: string().required(<Translate id='members.profile.classification.change.fields.division.required' />),
                to_division_id: string().required(<Translate id='members.profile.classification.change.fields.division.required' />),
                from_team_category_id: string().when('type', {
                    is: (val) => val === 'CLASSIFICATION',
                    then: string().required(<Translate id='members.profile.classification.change.fields.team.category.required' />),
                    otherwise: string(),
                }).test({
                    name: 'fromTeamCategoryIfTo',
                    message: <Translate id='members.profile.classification.change.fields.team.category.required' />,
                    test: function(value){
                        if(this.parent.to_team_category_id){
                            return !!value
                        }
                        return true
                    },
                }),
                to_team_category_id: string().when('type', {
                    is: (val) => val === 'CLASSIFICATION',
                    then: string().required(<Translate id='members.profile.classification.change.fields.team.category.required' />),
                    otherwise: string(),
                }).test({
                    name: 'TeamCategoryIfFrom',
                    message: <Translate id='members.profile.classification.change.fields.team.category.required' />,
                    test: function(value){
                        if(this.parent.from_team_category_id){
                            return !!value
                        }
                        return true
                    },
                }),
                request_note: string(),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                return createMemberClassificationChange({ ...values, member_id: membersContext.currentMember.member_id })
                    .then(() => {
                        setStatus("success");
                        setSubmitting(false);
                        setIsSuccess(true);
                        refreshMemberProfile();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                        }
                        setSubmitting(false);
                    });
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggle}>
                            <Translate id='members.profile.action.requestClassificationChanges' />
                        </ModalHeader>
                        {/* CREATE CHANGE REQUEST */}
                        {(!isSuccess && formik.status !== 'success' && !classChangeRequest && !isCancelSuccess) &&
                            <>
                                <ModalBody>
                                    <UserDisplay card className='w-100'>
                                        <UserDisplay.Container>
                                            <UserImg
                                                abbr={member.first_name[0] + member.last_name[0]}
                                                src={null}
                                                width={50}
                                                height={50}
                                                alt={`${member.first_name} ${member.last_name}`}
                                            />
                                        </UserDisplay.Container>
                                        <UserDisplay.Container>
                                            <UserDisplay.Title>{member.first_name} {member.last_name}</UserDisplay.Title>
                                            <UserDisplay.Subtitle className="font-medium text-dark"><InlineCopy tag='div' toCopy={member.unique_identifier} className="font-medium">{'#' + member.unique_identifier || '-'}</InlineCopy></UserDisplay.Subtitle>
                                            <UserDisplay.Subtitle><DisplayBirthdateAge birthdate={member.birthdate} age={member.age} /></UserDisplay.Subtitle>
                                            <UserDisplay.Subtitle><GenderLabel gender={member.gender} /></UserDisplay.Subtitle>
                                        </UserDisplay.Container>
                                    </UserDisplay>

                                    <Row form className='text-left'>
                                        <Col md='6'>
                                            <FormGroup>
                                                <Label className='text-muted'><Translate id='members.profile.classification.change.fields.type.label' /> <Required /></Label>
                                                <FormikSelect
                                                    name='type'
                                                    id='type'
                                                    search={false}
                                                    loadingStatus='success'
                                                    defaultData={[
                                                        ...(showClassification ? [ {
                                                            label: 'members.profile.classification.change.fields.type.classification',
                                                            value: 'CLASSIFICATION',
                                                            translateLabel: true,
                                                        } ] : []),
                                                        {
                                                            label: 'members.profile.classification.change.fields.type.overage',
                                                            value: 'OVERAGE',
                                                            translateLabel: true,
                                                        },
                                                        {
                                                            label: 'members.profile.classification.change.fields.type.underage',
                                                            value: 'UNDERAGE',
                                                            translateLabel: true,
                                                        },
                                                    ]}
                                                    onOptionSelected={(values) => {
                                                        setDivisionsSelectOptions(null, null, values[0]); // Please forgive me
                                                    }}
                                                />
                                                {overageUnderageError && <div className="text-danger small">{overageUnderageError}</div>}
                                            </FormGroup>
                                        </Col>
                                        <Col md='6'>
                                            <FormGroup>

                                                <Label for='period_id' className='text-muted'><Translate id='form.fields.period' /> <Required /></Label>
                                                <FormikSelect
                                                    id="period_id"
                                                    name="period_id"
                                                    renderOption={({ option }) => <DisplayI18n defaultValue={option.label} i18n={option.i18n} field="name" />}
                                                    searchKeys={[ `i18n.${getGenericLocale()}.name` ]}
                                                    options={periodsContext.activePeriods.map((period) => ({
                                                        value: period.period_id,
                                                        i18n: period.i18n,
                                                        label: period.name,
                                                    }))}
                                                    onOptionSelected={(values) => {
                                                        getDivisions(values[0]);
                                                        fromCatSelectRef.current?.getSpordleTable().filterChange('periodId', values[0])
                                                        toCatSelectRef.current?.getSpordleTable().filterChange('periodId', values[0])
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md='6'>
                                            <FormGroup>
                                                <Label className='text-muted'><Translate id='members.profile.classification.change.fields.from.division.label' /> <Required /></Label>
                                                <FormikSelect
                                                    disabled={!formik.values.period_id || hasDefaultFrom || divOptions[formik.values.type]?.length <= 0}
                                                    id="from_division_id"
                                                    name="from_division_id"
                                                    searchKeys={[ `i18n.${getGenericLocale()}.short_name` ]}
                                                    initFilter={{
                                                        periodId: formik.values.period_id || '',
                                                    }}
                                                    onOptionSelected={(values) => {
                                                        formik.setFieldValue('from_team_category_id', '')
                                                        fromCatSelectRef.current?.getSpordleTable().filterChange('division_id', values)
                                                    }}
                                                    renderSelectedOption={(option) => (
                                                        <DisplayI18n field="short_name" defaultValue={option?.label} i18n={option?.i18n} />
                                                    )}
                                                    renderOption={({ option }) => (
                                                        <div>
                                                            <DisplayI18n field="short_name" defaultValue={option.label} i18n={option.i18n} />
                                                            <div className='small text-muted'>
                                                                {(option.birth_year_from && option.birth_year_to) &&
                                                                    <Translate id='members.profile.classification.view.fields.division.birth.years' values={{ minYear: option.birth_year_from, maxYear: option.birth_year_to }} />
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                    isLoading={divOptionsLoading}
                                                    options={divOptions[formik.values.type] || []}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md='6'>
                                            <FormGroup>
                                                <Label className='text-muted'><Translate id='members.profile.classification.change.fields.to.division.label' /> <Required /></Label>
                                                <FormikSelect
                                                    disabled={!formik.values.period_id || hasDefaultTo || divOptions[formik.values.type]?.length <= 0}
                                                    id="to_division_id"
                                                    name="to_division_id"
                                                    searchKeys={[ `i18n.${getGenericLocale()}.short_name` ]}
                                                    initFilter={{
                                                        periodId: formik.values.period_id || '',
                                                    }}
                                                    onOptionSelected={(values) => {
                                                        formik.setFieldValue('to_team_category_id', '')
                                                        toCatSelectRef.current.getSpordleTable().filterChange('division_id', values)
                                                    }}
                                                    renderOption={({ option }) => <DisplayI18n field="short_name" defaultValue={option.label} i18n={option.i18n} />}
                                                    isLoading={divOptionsLoading}
                                                    options={divOptions[formik.values.type]}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md='6'>
                                            <FormGroup>
                                                <Label className='text-muted'><Translate id='members.profile.classification.change.fields.from.team.category.label' /> {formik.values.type === 'CLASSIFICATION' && <Required />}</Label>
                                                <FormikSelect
                                                    clearable
                                                    ref={fromCatSelectRef}
                                                    disabled={!formik.values.period_id || !formik.values.from_division_id}
                                                    id="from_team_category_id"
                                                    name="from_team_category_id"
                                                    searchKeys={[
                                                        `category.division.i18n.${getGenericLocale()}.short_name`,
                                                        `category.class.i18n.${getGenericLocale()}.short_name`,
                                                    ]}
                                                    initFilter={{
                                                        periodId: formik.values.period_id || '',
                                                        division_id: formik.values.from_division_id || '',
                                                    }}
                                                    renderOption={({ option }) => (
                                                        <>
                                                            <div className="mb-0">
                                                                <DisplayCategory category={option.category} short />
                                                            </div>
                                                            {option.category?.gender && <div className="small">{<Translate id={`form.fields.gender.${option.category?.gender.toLowerCase()}`} />}</div>}
                                                        </>
                                                    )}
                                                    loadData={(from, extra, spordleTable) => {
                                                        switch (from){
                                                            case 'FILTER':
                                                                spordleTable.setLoading();
                                                            case 'CDM':
                                                                if(extra.filters.periodId && extra.filters.division_id){
                                                                    return categoriesContext.getRegistrationCategories(organizationContext.organisation_id, { period_id: extra.filters.periodId, division_id: extra.filters.division_id })
                                                                        .then((cats) => cats.map((cat) => ({
                                                                            value: cat.team_category_id,
                                                                            label: cat.name,
                                                                            category: cat,
                                                                        })))
                                                                }
                                                                return Promise.resolve([])
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md='6'>
                                            <FormGroup>
                                                <Label className='text-muted'><Translate id='members.profile.classification.change.fields.to.team.category.label' /> {formik.values.type === 'CLASSIFICATION' && <Required />}</Label>
                                                <FormikSelect
                                                    clearable
                                                    ref={toCatSelectRef}
                                                    disabled={!formik.values.period_id || !formik.values.to_division_id}
                                                    id="to_team_category_id"
                                                    name="to_team_category_id"
                                                    searchKeys={[
                                                        `category.division.i18n.${getGenericLocale()}.short_name`,
                                                        `category.class.i18n.${getGenericLocale()}.short_name`,
                                                    ]}
                                                    initFilter={{
                                                        periodId: formik.values.period_id || '',
                                                        division_id: formik.values.to_division_id || '',
                                                    }}
                                                    renderOption={({ option }) => (
                                                        <>
                                                            <div className="mb-0">
                                                                <DisplayCategory category={option.category} short />
                                                            </div>
                                                            {option.category?.gender && <div className="small">{<Translate id={`form.fields.gender.${option.category?.gender.toLowerCase()}`} />}</div>}
                                                        </>
                                                    )}
                                                    loadData={(from, extra, spordleTable) => {
                                                        switch (from){
                                                            case 'FILTER':
                                                                spordleTable.setLoading();
                                                            case 'CDM':
                                                                if(extra.filters.periodId && extra.filters.division_id){
                                                                    return categoriesContext.getRegistrationCategories(organizationContext.organisation_id, { period_id: extra.filters.periodId, division_id: extra.filters.division_id })
                                                                        .then((cats) => cats.map((cat) => ({
                                                                            value: cat.team_category_id,
                                                                            label: cat.name,
                                                                            category: cat,
                                                                        })))
                                                                }
                                                                return Promise.resolve([])
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md='12'>
                                            <FormGroup>
                                                <Label className='text-muted '><Translate id='form.fields.note' /></Label>
                                                <FormikTextArea id='request_note' name='request_note' rows={2} trim maxLength={255} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {formik.status &&
                                        <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                            <CustomAlert className='mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                        </Collapse>
                                    }
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color='primary'
                                        type='submit'
                                        disabled={formik.isSubmitting}
                                    >
                                        <Translate id='misc.submit' />
                                    </Button>
                                    <Button
                                        color='primary' type='button' onClick={toggle}
                                        outline disabled={formik.isSubmitting}
                                    >
                                        <Translate id='misc.cancel' />
                                    </Button>
                                </ModalFooter>
                            </>
                        }
                        {/* CHANGE REQUEST CREATED SUCCESSFULLY */}
                        {(isSuccess && formik.status == 'success') &&
                            <>
                                <ModalBody className="text-center text-dark">
                                    <Fade in>
                                        <div className='position-relative d-inline-block'>
                                            <CustomAnimatedIcon
                                                className='text-success'
                                                strokeWidth="10px"
                                                size={50}
                                                id='confirmModalCheckmark'
                                                icon='checkmark'
                                                withCircle
                                            />
                                        </div>

                                        <div className="text-center h5 font-bold pt-2">
                                            <Translate id="misc.success" />
                                        </div>
                                        <div><Translate id='members.profile.action.requestClassificationChanges.success' /></div>
                                    </Fade>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='button' onClick={() => { toggle(); }} outline disabled={formik.isSubmitting}><Translate id='misc.close' /></Button>
                                </ModalFooter>
                            </>
                        }
                        {/* VIEW/CANCEL CHANGE REQUEST */}
                        {(classChangeRequest && !isSuccess && !isCancelSuccess) &&
                            <OverlayLoader isLoading={cancellationPending}>
                                <ModalBody>
                                    <div className='h5 font-bold'><Translate id='task.mandatoryChangesRequests.sidepanel.request.requested_by' /></div>
                                    <div>
                                        {classChangeRequest.created_by.name} {classChangeRequest.created_by.family_name} - <a href={"mailto:" + classChangeRequest.created_by.email}>{classChangeRequest.created_by.email}</a>
                                    </div>
                                    <div className='mt-2 h5 font-bold'><Translate id='task.mandatoryChangesRequests.sidepanel.request.data' /></div>
                                    <Card body className='card-shadow'>
                                        <div>
                                            <div className='text-muted d-flex'>
                                                <Translate id='members.profile.classification.change.fields.type.label' />
                                            </div>
                                            <div>
                                                <Translate id={`members.profile.classification.change.fields.type.${classChangeRequest.type.toLowerCase()}`} />
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='text-muted d-flex'>
                                                <Translate id='members.profile.classification.view.fields.division' />
                                            </div>
                                            <div className='d-flex flex-wrap'>
                                                <div>
                                                    <DisplayI18n field='name' defaultValue={classChangeRequest.from_division.name} i18n={classChangeRequest.from_division.i18n} />
                                                </div>
                                                <div className='mx-2'>
                                                    →
                                                </div>
                                                <div className='font-bold'>
                                                    <DisplayI18n field='name' defaultValue={classChangeRequest.to_division.name} i18n={classChangeRequest.to_division.i18n} />
                                                </div>
                                            </div>
                                        </div>
                                        {(!!classChangeRequest.from_team_category && classChangeRequest.to_team_category) &&
                                            <div className='mt-2'>
                                                <div className='text-muted d-flex '>
                                                    <Translate id='members.profile.classification.view.fields.team.category' />
                                                </div>
                                                <div className='d-flex flex-wrap'>
                                                    <div>
                                                        <DisplayI18n field='name' defaultValue={classChangeRequest.from_team_category.name} i18n={classChangeRequest.from_team_category.i18n} />
                                                    </div>
                                                    <div className='mx-2'>
                                                        →
                                                    </div>
                                                    <div className='font-bold'>
                                                        <DisplayI18n field='name' defaultValue={classChangeRequest.to_team_category.name} i18n={classChangeRequest.to_team_category.i18n} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {classChangeRequest.request_note &&
                                            <div className='mt-2'>
                                                <div className='text-muted'><Translate id='form.fields.note' /></div>
                                                <div>{classChangeRequest.request_note}</div>
                                            </div>
                                        }
                                    </Card>
                                </ModalBody>
                                <ModalFooter>
                                    {identityRolesContext.identity.identity_id == classChangeRequest?.[0]?.created_by.identity_id &&
                                        <Button
                                            disabled={identityRolesContext.identity.identity_id !== classChangeRequest?.[0]?.created_by.identity_id}
                                            color='danger'
                                            onClick={() => {
                                                setCancellationPending(true);
                                                return updateClassificationRequestChangeStatus(classChangeRequest?.[0]?.classification_request_change_id, { request_change_status_id: classificationChangeRequestStatuses.find((status) => status.system === 'CANCELLED')?.request_change_status_id })
                                                    .then(() => {
                                                        refreshMemberProfile();
                                                        setCancellationPending(false);
                                                        setIsCancelSuccess(true)
                                                    }).catch((error) => {
                                                        formik.setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                                        if(!AxiosIsCancelled(error.message)){
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                            setCancellationPending(false);
                                                            console.error(error.message);
                                                            formik.setSubmitting(false);
                                                        }
                                                    })
                                            }}
                                        >
                                            <Translate id='task.mandatoryChangesRequests.modal.cancel.btn' />
                                        </Button>
                                    }
                                    <Button color='primary' type='button' onClick={() => { toggle(); }} outline disabled={formik.isSubmitting}><Translate id='misc.close' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        }
                        {/* CHANGE REQUEST CANCELLATION SUCCESS */}
                        {(isCancelSuccess) &&
                            <>
                                <ModalBody className="text-center text-dark">
                                    <Fade in>
                                        <div className='position-relative d-inline-block'>
                                            <CustomAnimatedIcon
                                                className='text-success'
                                                strokeWidth="10px"
                                                size={50}
                                                id='confirmModalCheckmark'
                                                icon='checkmark'
                                                withCircle
                                            />
                                        </div>

                                        <div className="text-center h5 font-bold pt-2">
                                            <Translate id="misc.success" />
                                        </div>
                                        <div><Translate id='members.profile.mandatory.change.cancel.success' /></div>
                                    </Fade>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='button' onClick={() => { setIsCancelSuccess(false); }} disabled={formik.isSubmitting}><Translate id='members.profile.mandatory.change.new.request' /></Button>
                                    <Button color='primary' type='button' onClick={() => { toggle(); }} outline disabled={formik.isSubmitting}><Translate id='misc.close' /></Button>
                                </ModalFooter>
                            </>
                        }
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default RequestClassificationChangeModal;
