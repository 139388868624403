import { stringBuilder } from "@spordle/helpers";

const DisplayMemberTypeStatusIcon = ({ status, className, ...props }) => {
    if(!status){
        return null;
    }

    const getValues = (code) => {
        switch (code){
            case 'DECLINED':
                return [ 'close-circle', 'danger' ];
            case 'CONFIRMED':
            case 'APPROVED':
                return [ 'check-circle-outline', 'success' ];
            case 'PENDING':
                return [ 'clock-outline', 'warning' ];
            default:
                return [];
        }
    }

    const [ icon, color ] = getValues(status.code);

    if(!icon){
        return null;
    }

    return (
        <i className={stringBuilder(className, `mdi mdi-${icon} text-${color}`)} {...props} />
    );
}

export default DisplayMemberTypeStatusIcon;