import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate from "@spordle/intl-elements";
import moment from 'moment';
import React from "react";
import {
    Button,
    Card,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap";

// context
import withContexts from "../../../../helpers/withContexts";
import { RolesContext } from "../../../../contexts/RolesContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import CardSectionTitle from "../../../../components/CardSectionTitle";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import SpordlePanelTable from "../../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../../components/table/EmptyLayout";
import SidePanelOrganizationRoles from './SidePanelOrganizationRoles';
import AddRoleModal from './modals/AddRoleModal';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';

class OrganizationRoles extends React.Component{
    state = {
        addRoleModalOpen: false,
    }

    toggleAddRole = () => {
        this.setState((prevState) => ({ addRoleModalOpen: !prevState.addRoleModalOpen }));
    }

    addRole = (values) => {
        const formattedStartDate = values.startDate ? moment(values.startDate).toISOString() : null;
        const formattedEndDate = values.endDate ? moment(values.endDate).toISOString() : null;
        return this.props.RolesContext.createRole(this.props.OrganizationContext.organisation_id, values.title, values.description, values.active, formattedStartDate, formattedEndDate, values.isSystem)
            .then((data) => {
                this.rolesTableRef.addRow({
                    role_id: data,
                    organisation_id: this.props.OrganizationContext.organisation_id,
                    title: values.title,
                    description: values.description,
                    active: values.active >>> 0,
                    start_date: formattedStartDate,
                    expiration_date: formattedEndDate,
                    is_system: values.isSystem,
                })
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    render(){
        return (
            <div>
                <SpordlePanelTable
                    allowOutsideClick
                    sidePanel={(props) => <SidePanelOrganizationRoles {...props} tableRef={this.rolesTableRef} />}
                    dataIndex='role_id'
                    table={(panelProps) => {
                        return (
                            <Card body className='card-shadow'>
                                <CardSectionTitle title='settings.tabs.roles' />
                                <SpordleTableProvider
                                    id='OrganizationRolesTable'
                                    tableHover clickable bordered
                                    striped
                                    ref={(r) => { this.rolesTableRef = r; panelProps.spordleTableRef(r); }}
                                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                    dataIndex='role_id'
                                    defaultSearchValue={parse(this.props.location.search).search}
                                    searchKeys={[
                                        'description',
                                        'role_id',
                                        'title',
                                        'description',
                                    ]}
                                    columns={[
                                        {
                                            label: '-',
                                            key: "checkbox",
                                            className: 'text-center',
                                            dataClassName: 'text-center',
                                            mobile: false,
                                            sortable: false,
                                        },
                                        {
                                            label: <Translate id='organization.profile.roles.table.title' />,
                                            key: "title",
                                            className: 'text-left',
                                            dataClassName: 'text-left',
                                            mobile: true,
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='organization.profile.roles.table.description' />,
                                            key: "description",
                                            className: 'text-left',
                                            dataClassName: 'text-left',
                                            mobile: true,
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='organization.profile.roles.table.myRole' />,
                                            key: "myRole",
                                            sortKey: "is_system",
                                            fallbackSortKey: "is_shared",
                                            mobile: true,
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='organization.profile.roles.addRole.isSystem' />,
                                            key: "systemRole",
                                            sortKey: "is_system",
                                            fallbackSortKey: "is_system",
                                            mobile: true,
                                            sortable: true,
                                        },
                                        {
                                            label: <Translate id='organization.profile.roles.table.active' />,
                                            key: "active",
                                            mobile: true,
                                            sortable: true,
                                        },
                                    ]}
                                    onColumnClick={(e, role) => {
                                        switch (e.button){
                                            case 0: // Left mouse button
                                                panelProps.onSingleSelectChange(role);
                                                break;
                                        }
                                    }}
                                    pagination={20}
                                    desktopWhen='md'
                                    emptyLayout={<EmptyLayout translateTitle title='organization.profile.roles.table.emptyLayout' />}
                                    renderRow={(columnKey, role, spordleTable) => {
                                        switch (columnKey){
                                            case 'checkbox':
                                                return (
                                                    <label className='my-auto' htmlFor={'role-checked-' + role.role_id} onClick={(e) => { e.stopPropagation() }}>
                                                        <input
                                                            id={'role-checked-' + role.role_id} type="checkbox" checked={!!role.checked}
                                                            onClick={() => {
                                                            // Use the onClick event to prevent the row click
                                                            // onChange is triggered after onClick
                                                                panelProps.onMultiSelectChange(role);
                                                            }}
                                                            onChange={function(){ }}// This needs to be here to remove the console error
                                                        />
                                                        <span className="table-checkbox" />
                                                    </label>
                                                );
                                            case 'myRole':
                                                return (
                                                    <div className={spordleTable.isMobile() ? undefined : 'text-center'}>
                                                        {role.is_system === '0' && role.is_shared === '0' ?
                                                            <i className="text-primary mdi mdi-check" />
                                                            :
                                                            <i className="text-danger mdi mdi-close" />
                                                        }
                                                    </div>
                                                );
                                            case 'systemRole':
                                                return (
                                                    <div className={spordleTable.isMobile() ? undefined : 'text-center'}>
                                                        {role.is_system === '1' ?
                                                            <i className="text-primary mdi mdi-check" />
                                                            :
                                                            <i className="text-danger mdi mdi-close" />
                                                        }
                                                    </div>
                                                );
                                            case 'active':
                                                return (
                                                    <div className={spordleTable.isMobile() ? undefined : 'text-center'}>
                                                        {role.active === '1' ?
                                                            <i className="text-primary mdi mdi-check" />
                                                            :
                                                            <i className="text-danger mdi mdi-close" />
                                                        }
                                                    </div>
                                                );
                                            default:
                                                break;
                                        }
                                    }}
                                    defaultSorting='+title'
                                    rowIsHighlighted={(role) => !!role.checked}
                                    loadData={(from, filterData, spordleTable) => {
                                        switch (from){
                                            case 'REFRESH':
                                                spordleTable.setLoading();
                                            case 'CDM':
                                                return this.props.RolesContext.getOrganizationRoles(this.props.OrganizationContext.organisation_id);
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    <TopSection toggleAddRole={this.toggleAddRole} panelProps={panelProps} />
                                    {/* ADD ROLE MODAL */}
                                    <AddRoleModal isOpen={this.state.addRoleModalOpen} toggle={this.toggleAddRole} addRole={this.addRole} />
                                    <SpordleTableView />
                                </SpordleTableProvider>
                            </Card>
                        )
                    }}
                />
            </div>
        )
    }
}

const TopSection = (props) => {
    return (
        <div className="mb-3">
            <div className='d-flex justify-content-between'>
                <UncontrolledDropdown>
                    <DropdownToggle color='primary' className='d-flex align-items-center' caret>
                        {/* This is the rendered dom element from <CustomInput/> -> I use it like so because i need to access the inner elements */}
                        <div className="align-self-center custom-checkbox custom-control">
                            <input id="topCheckID" type="checkbox" className="custom-control-input d-none" checked={props.panelProps.topChecked} onChange={props.panelProps.onTopCheckChange} />
                            <label className="custom-control-label" htmlFor="topCheckID" />
                        </div>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={props.panelProps.checkAll}><Translate id='misc.all' /></DropdownItem>
                        <DropdownItem onClick={props.panelProps.uncheckAll}><Translate id='misc.none' /></DropdownItem>
                        <DropdownItem disabled><Translate id='organization.profile.roles.multiSelect.active' /></DropdownItem>
                        <DropdownItem disabled><Translate id='organization.profile.roles.multiSelect.inactive' /></DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <SpordleTableProvider.SearchInput className='ml-2' />
                <div className='d-flex ml-auto text-right'>
                    <SpordleTableProvider.Refresh />
                    <UncontrolledDropdown className='ml-2'>
                        <DropdownToggle disabled color='primary' outline caret>
                            <i className='mdi mdi-download' /> <Translate id='misc.export' />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem><i className='fas fa-file-excel' /> <Translate id='misc.excel' /></DropdownItem>
                            <DropdownItem><i className='fas fa-file-pdf' /> <Translate id='misc.pdf' /></DropdownItem>
                            <DropdownItem><i className='fas fa-print' /> <Translate id='misc.print' /></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <CanDoAction action='ADD' componentCode='settings' componentPermissionCode='identity_roles'>
                        <Button color='primary' className='ml-2' onClick={props.toggleAddRole}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                    </CanDoAction>
                </div>
            </div>
        </div>
    )
}

export default withContexts(RolesContext, OrganizationContext)(withRouter(OrganizationRoles));