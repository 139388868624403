import { FormikCheckedButton, FormikInputNumber, FormikInputText, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { formatSelectData } from "@spordle/spordle-select";
import { useFormikContext } from "formik";
import { useContext } from "react";
import { Col, Label, Row } from "reactstrap";
import CrossFade from "../../../../components/crossFade/CrossFade";
import Required from "../../../../components/formik/Required";
import { I18nContext } from "../../../../contexts/I18nContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { GAME_PERIODS, useGirSelectProps } from "./gameIncidentHelper";

/**
 * @param {Object} props
 * @param {function} props.previous
 * @returns {JSX.Element}
 */
const IncidentForm = ({ fieldsPrefix = '', incident, suspensions, players, suspensionTypes }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { setFieldValue } = useFormikContext();
    const getGirProps = useGirSelectProps();

    const getMemberSelectLabel = (option) => {
        if(option.option.isGroup){
            return <Translate id={option.option.label} />
        }
        return (
            <>
                {option.option.label}
                <small className="d-block text-muted">
                    <DisplayI18n
                        field="name"
                        i18n={option.option.memberData.position.i18n}
                        defaultValue={option.option.memberData.position.name}
                    />
                </small>
            </>
        )

    }

    const getGroupOptions = (teamMembers, pos) => {
        if(!teamMembers)return [];

        return teamMembers.map((tM) => ({
            teamPosition: pos,
            value: tM.member?.member_id,
            label: tM.member?.first_name + ' ' + tM.member?.last_name,
            memberData: tM,
        }));
    }

    return (
        <Row className="pt-2" form>
            <Col sm="12" lg="6" className="form-group">
                <Label for={fieldsPrefix + "member_id"} className="text-muted">
                    <Translate id='gameIncident.addModal.label.player' /> <Required />
                </Label>
                <FormikSelect
                    name={fieldsPrefix + "member_id"}
                    id={fieldsPrefix + "member_id"}
                    onOptionSelected={([ select ], spordleTable) => {
                        const val = spordleTable.getSpordleTable().getData().find((opt) => opt.value === select)?.memberData;

                        setFieldValue(fieldsPrefix + 'jersey_number', val?.jersey_number);
                        setFieldValue(fieldsPrefix + 'team_id', val?.team_id);
                    }}
                    search
                    searchKeys={[
                        `label`,
                        `memberData.position.i18n.${getGenericLocale()}.name`,
                    ]}
                    renderOption={getMemberSelectLabel}
                    isLoading={!players.away && !players.home}
                    options={(!!players.away || !!players.home) ?
                        formatSelectData(
                            [ ...getGroupOptions(players.away, 'away'), ...getGroupOptions(players.home, 'home') ],
                            {
                                getGroupId: (p) => p.teamPosition === 'away' ? 'away' : 'home',
                                newGroupIndexes: {
                                    0: {
                                        isGroup: true,
                                        groupId: 'home',
                                        label: 'gameIncident.addModal.teams.home.label',
                                    },
                                    1: {
                                        isGroup: true,
                                        groupId: 'away',
                                        label: 'gameIncident.addModal.teams.away.label',
                                    },
                                },

                            },
                        )
                        :
                        []

                    }
                />
            </Col>
            <Col sm="12" lg="6" className="form-group">
                <Label for={fieldsPrefix + "jersey_number"} className="text-muted">
                    <Translate id='gameIncident.addModal.label.jersey' />
                </Label>
                <FormikInputText
                    autoFocus
                    autoComplete="new-password"
                    id={fieldsPrefix + "jersey_number"}
                    name={fieldsPrefix + "jersey_number"}
                    placeholder="#"
                    translatePlaceholder={false}
                />
            </Col>
            <Col xs="6" className="form-group">
                <Label className="text-muted"><Translate id='gameIncident.addModal.label.infractionType' /> <Required /></Label>
                <FormikSelect
                    {...getGirProps("suspension_type_id", suspensionTypes)}
                    name={fieldsPrefix + "suspension_type_id"}
                    id={fieldsPrefix + "suspension_type_id"}
                />
            </Col>
            <Col xs="6" className="form-group">
                <Label className="text-muted"><Translate id='gameIncident.addModal.label.infraction' /> <Required /></Label>
                <FormikSelect
                    {...getGirProps("suspension_id", suspensions)}
                    name={fieldsPrefix + "suspension_id"}
                    id={fieldsPrefix + "suspension_id"}
                />
            </Col>
            <Col xs="12" lg="6" className="form-group">
                <Label for="game_period" className="text-muted"><Translate id='gameIncident.addModal.label.period' /></Label>
                <FormikSelect
                    clearable
                    name={fieldsPrefix + "game_period"}
                    id={fieldsPrefix + "game_period"}
                    defaultData={GAME_PERIODS.map((period) => ({
                        label: `gameIncident.addModal.label.gamePeriod.${period}`,
                        value: period,
                        translateLabel: true,
                    }))}
                    loadingStatus='success'
                />
            </Col>
            <Col xs="6" lg="3" className="form-group">
                <Label className="text-muted">
                    <Translate id='gameIncident.addModal.label.minutes' />
                </Label>
                <FormikInputNumber
                    name={fieldsPrefix + "minutes"}
                    max={59}
                    format='##'
                    min={0}
                    translatePlaceholder={false}
                    placeholder="00"
                />
            </Col>
            <Col xs="6" lg="3" className="form-group">
                <Label className="text-muted">
                    <Translate id='gameIncident.addModal.label.seconds' />
                </Label>
                <FormikInputNumber
                    name={fieldsPrefix + "seconds"}
                    format='##'
                    max={59}
                    min={0}
                    placeholder="00"
                    translatePlaceholder={false}
                />
            </Col>
            <Col sm="12" className="form-group align-self-center">
                <Label className="text-muted">
                    Description
                </Label>
                <FormikTextArea name={fieldsPrefix + "description"} trim />
            </Col>
            <Col sm="12" className="form-group align-self-center">
                <FormikCheckedButton className="text-muted" label='gameIncident.addModal.label.injury' name={fieldsPrefix + "injured"} />
                <CrossFade isVisible={!!incident.injured}>
                    <FormikCheckedButton className="text-muted mt-2" label='gameIncident.addModal.label.returned' name={fieldsPrefix + "returned_to_game"} />
                </CrossFade>
            </Col>
        </Row>
    )
}

export default IncidentForm;