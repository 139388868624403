import { stringifyUrl } from "query-string";
import API_SPORDLE from "../API-Spordle";
import { serverError } from "../CancellableAPI";
import { jsObjectToApi } from "@spordle/helpers";

export function getUnaffiliatedMembers(query = {}) {
    return API_SPORDLE.get(stringifyUrl({
        url: `/members/unaffiliated`,
        query: query,
    }))
        .then((response) => {
            if(response.data.status){
                return response.data.members;
            }
            throw response.data.errors;
        }, serverError)
}

export function createAffiliationInvoice(values){
    const params = new URLSearchParams();

    jsObjectToApi(values, params);

    return API_SPORDLE.post(stringifyUrl({
        url: '/invoices/affiliations',
    }), params)
        .then((response) => {
            if(response.data.status){
                return true;
            }
            throw response.data.errors;
        }, serverError)
}