export default{
    "catalog.registrationFees.add.dropdown.button" : "Créer un frais",

    "catalog.registrationFees.duplicate.title"       : "Dupliquer",
    "catalog.registrationFees.duplicate.information" : "Duplication du frais d'inscription suivant: ",

    "catalog.registrationFees.add.title"                      : "Ajouter un frais",
    "catalog.registrationFees.add.form.waivers"               : "Dispenses",
    "catalog.registrationFees.add.form.memberType"            : "Type de membre",
    "catalog.registrationFees.add.form.sku"                   : "UGS",
    "catalog.registrationFees.add.form.category"              : "Catégorie",
    "catalog.registrationFees.add.form.division"              : "Division",
    "catalog.registrationFees.add.form.division.disabled"     : "Les options désactivées nécessitent une configuration de frais d'affiliation.",
    "catalog.registrationFees.add.form.name"                  : "Nom d'inscription",
    "catalog.registrationFees.add.form.name.suggestion"       : "Nom suggéré",
    "catalog.registrationFees.add.form.class"                 : "Classe",
    "catalog.registrationFees.add.form.optional"              : "Optionnel",
    "catalog.registrationFees.add.form.status"                : "Statut",
    "catalog.registrationFees.add.form.active"                : "Actif",
    "catalog.registrationFees.add.form.inactive"              : "Inactif",
    "catalog.registrationFees.add.form.availablePlaces"       : "Places disponibles",
    "catalog.registrationFees.add.form.availablePlaces.help"  : "Veuillez spécifier le nombre de places disponibles pour avoir une liste d'attente ou afficher un nombre de places restantes.",
    "catalog.registrationFees.add.form.unlimited"             : "Illimitées",
    "catalog.registrationFees.add.form.cannotWaitingList"     : "Vous ne pouvez pas avoir des places illimitées avec une liste d'attente.",
    "catalog.registrationFees.add.form.cannotShowPlaces"      : "Vous ne pouvez pas avoir des places illimitées et afficher les places restantes.",
    "catalog.registrationFees.add.form.positive"              : "Le nombre de places disponibles doit être positif.",
    "catalog.registrationFees.add.form.waitingListRequired"   : "Le nombre de places disponibles est requis si la liste d'attente est activée.",
    "catalog.registrationFees.add.form.showPlacesRequired"    : "Le nombre de places disponibles est requis si vous voulez les afficher.",
    "catalog.registrationFees.add.form.waitingList"           : "Liste d'attente",
    "catalog.registrationFees.add.form.waitingList.tooltip"   : "Ce frais d'inscription peut gérer une liste d'attente",
    "catalog.registrationFees.add.form.showPosition"          : "Demander la position",
    "catalog.registrationFees.add.form.showPlaces"            : "Afficher les places restantes",
    "catalog.registrationFees.add.form.needAvailablePlaces"   : "Vous devez avoir un nombre de places disponibles.",
    "catalog.registrationFees.add.form.registrationFee"       : "Frais d'inscription",
    "catalog.registrationFees.add.form.registrationFee.help"  : "Frais d'inscription - Frais d'affiliation = Revenu",
    "catalog.registrationFees.add.form.excludeAffiliationFee" : "Exclure les Frais d'affiliation",
    "catalog.registrationFees.add.form.membershipFee"         : "Frais d'affiliation",
    "catalog.registrationFees.add.form.tax"                   : "+taxe",
    "catalog.registrationFees.add.form.earlyFee"              : "Frais d'inscription hâtive",
    "catalog.registrationFees.add.form.lateFee"               : "Frais d'inscription tardive",
    "catalog.registrationFees.add.form.applicableTax"         : "Taxes applicables",
    "catalog.registrationFees.add.form.taxable"               : "Taxable",
    "catalog.registrationFees.add.form.applicableTax.none"    : "Pas taxable",
    "catalog.registrationFees.add.form.taxReceipt"            : "Reçu fiscal",
    "catalog.registrationFees.add.form.before"                : "Avant",
    "catalog.registrationFees.add.form.after"                 : "Après",
    "catalog.registrationFees.add.form.installment.number"    : "{installmentNb, plural, =0 {# versement} one {# versement} other {# versements}}",

    "catalog.registrationFees.add.form.noWaivers" : "No waivers available.",

    "catalog.registrationFees.modifiedAges" : "Les âges ont été modifiés et sont différents de l'équivalence sélectionnée",

    // Error msg
    "catalog.registrationFees.add.form.validation.memberType"          : "Veuillez choisir un type de membre.",
    "catalog.registrationFees.add.form.validation.division"            : "Veuillez choisir une division.",
    "catalog.registrationFees.add.form.validation.name"                : "Veuillez entrer un nom.",
    "catalog.registrationFees.add.form.validation.registrationFee"     : "Veuillez entrer un frais d'inscription.",
    "catalog.registrationFees.add.form.validation.registrationFee.min" : "Ce frais ne peut pas être inférieur aux frais d'affiliations.",
    "catalog.registrationFees.add.form.validation.earlyDate"           : "Veuillez choisir la date qui indique la fin des frais hâtifs.",
    "catalog.registrationFees.add.form.validation.lateDate"            : "Veuillez choisir la date qui indique le début des frais tardifs.",
    "catalog.registrationFees.add.form.validation.earlyDateBefore"     : "La date de début des frais tardifs doit être avant la date de la fin des frais hâtifs.",
    "catalog.registrationFees.add.form.validation.lateDateGreater"     : "La date de fin des frais tardifs doit être après la date de la fin des frais hâtifs.",

    // tooltips
    "catalog.registrationFees.add.excludeAffiliationFee"    : "Les frais d’adhésion représentent le montant déboursé par le membre qui est retourné aux différentes instances (Nationale, Provinciale et Régionale) sanctionnant la participation à une saison sanctionnée.",
    "catalog.registrationFees.add.excludeAffiliationFee2"   : "En excluant les frais d'affiliation, vous confirmez que cet inscription spécifique n’est pas assujetti au frais d'affiliation. ",
    "catalog.registrationFees.add.excludeAffiliationFee3"   : "Ce paramètre est généralement utilisé pour <b>les camps d'entraînement</b> et autres activités non liées à l'inscription à une saison.",
    "catalog.registrationFees.add.excludeAffiliationFeeTip" : "Cliquez ici pour plus d'informations",

    "catalog.registrationFees.add.disabledExcludeAffiliationFeeTip"        : "Une période d'activité qui force l'exlusion des frais d'affiliation est sélectionné.",
    "catalog.registrationFees.add.activityPeriod.excludes.membership.fees" : "Cette période d'activité force l'exlusion des frais d'affiliation",
    "catalog.registrationFees.add.activityPeriod.warning.feesRecalculate"  : "Assurez-vous de vouloir exclure les frais d'affiliation.",


}