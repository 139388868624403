export default{
    "organization.settings.organizationTeamContactTypes.tab.title" : "Team Contact Types",

    "organization.settings.organizationTeamContactTypes.table.active" : "Active",
    "organization.settings.organizationTeamContactTypes.table.empty"  : "No Contact Type",

    // add
    "organization.settings.organizationTeamContactTypes.add.title"           : "Add Contact Type",
    "organization.settings.organizationTeamContactTypes.add.status"          : "Status",
    "organization.settings.organizationTeamContactTypes.add.status.active"   : "Active",
    "organization.settings.organizationTeamContactTypes.add.status.inactive" : "Inactive",
    "organization.settings.organizationTeamContactTypes.add.status.required" : "Status is required",
}