import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from '@spordle/datatables';
import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import {
    Button, Card,
    CardBody
} from "reactstrap";
import useSWR from 'swr';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import CardSectionTitle from '../../../../components/CardSectionTitle';
import CanDoAction from '../../../../components/permissions/CanDoAction';
import SpordlePanelTable from '../../../../components/sidePanel/SpordlePanel';
import { I18nContext } from '../../../../contexts/I18nContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { RegistrationCategoriesContext } from '../../../../contexts/RegistrationCategoriesContext';
import { RegistrationClassesContext } from '../../../../contexts/RegistrationClassesContext';
import { RegistrationDivisionsContext } from '../../../../contexts/RegistrationDivisionsContext';
import I18nHelperContextProvider, { DisplayI18n, displayI18n, I18nHelperContext } from '../../../../helpers/i18nHelper';
import SettingsRegistrationCategoryAddModal from './SettingsRegistrationCategoryAddModal';
import SettingsRegistrationCategorySidepanel from './SettingsRegistrationCategorySidepanel';
import { fail } from '@spordle/toasts';

/**
* @param {number} viewAs // 0: HC | 1: Branch | 2: MHA
*/
const SettingsRegistrationCategory = ({ viewAs }) => {
    const [ modalAdd, setModalAdd ] = useState(false);
    const [ classDivision, setClassDivision ] = useState({ classes: null, divisions: null });
    // const [ types, setTypes ] = useState(null);
    const toggleModalAdd = () => setModalAdd(!modalAdd);
    const categoriesContext = useContext(RegistrationCategoriesContext);
    const orgContext = useContext(OrganizationContext);
    const divisionsContext = useContext(RegistrationDivisionsContext);
    const classesContext = useContext(RegistrationClassesContext);
    const i18nContext = useContext(I18nContext);

    const { data: types } = useSWR(
        [ 'SettingsRegistrationCategory', 'getRegistrationCategoryTypes', orgContext.organisation_id ],
        viewAs !== 0 ?
            () => categoriesContext.getRegistrationCategoryTypes(orgContext.organisation_id, { active: '1' })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
            :
            null,
    )

    useEffect(() => {
        // if(viewAs == 1){
        //     categoriesContext.getRegistrationCategoryTypes(orgContext.organisation_id, { active: '1' })
        //         .then((types) => setTypes(types))
        //         .catch((err) => console.error(err))
        // }

        divisionsContext.getRegistrationDivisions(orgContext.organisation_id, { active: '1' })
            .then((divisions) => {
                setClassDivision((prev) => {
                    return { classes: prev.classes, divisions: divisions }
                })
            })
            .then(() => classesContext.getRegistrationClasses(orgContext.organisation_id, { active: '1' })
                .then((classes) => {
                    setClassDivision((prev) => {
                        return { divisions: prev.divisions, classes: classes }
                    })
                }))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    return (
        <I18nHelperContextProvider fields={[ 'name' ]}>
            <I18nHelperContext.Consumer>
                {(i18nHelper) => (
                    <Card className="card-shadow">
                        <CardBody>
                            <CardSectionTitle title='registration.settings.category.label.category' />
                            <SpordlePanelTable
                                allowOutsideClick
                                dataIndex='team_category_id'
                                sidePanel={(props) => <SettingsRegistrationCategorySidepanel {...props} types={types} classDivision={classDivision} viewAs={viewAs} />}
                                table={(panelProps) => {
                                    return (
                                        <SpordleTableProvider
                                            id='SettingsRegistrationCategoryTable'
                                            tableHover bordered striped
                                            clickable
                                            ref={panelProps.spordleTableRef}
                                            desktopWhen='sm'
                                            dataIndex='team_category_id'
                                            defaultSorting='+display_order'
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                            searchKeys={[
                                                `class.i18n.${i18nContext.getGenericLocale()}.name`,
                                                `class.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                                `division.i18n.${i18nContext.getGenericLocale()}.name`,
                                                `division.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                                `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                                'gender',
                                                'min_age',
                                                'max_age',
                                                `class.name`,
                                                `class.short_name`,
                                                `division.name`,
                                                `division.short_name`,
                                                `organisation.organisation_name`,
                                            ]}
                                            loadData={(from, filterData, spordleTable) => {
                                                switch (from){
                                                    case 'REFRESH':
                                                        spordleTable.setLoading();
                                                    case 'CDM':
                                                        return categoriesContext.getRegistrationCategories(orgContext.organisation_id)
                                                    default:
                                                        break;
                                                }
                                            }}
                                            columns={[
                                                {
                                                    label: <Translate id='registration.settings.class.form.label.order' />,
                                                    key: 'display_order',
                                                    className: 'text-center td-shrink',
                                                    dataClassName: 'text-center td-shrink',
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='registration.settings.category.label.organization' />,
                                                    key: 'organisation',
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='registration.settings.category.label.name' />,
                                                    key: 'name',
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='registration.settings.category.label.division' />,
                                                    key: 'division',
                                                    sortable: true,
                                                    sortKey: `division.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                                },
                                                {
                                                    label: <Translate id='registration.settings.category.label.class' />,
                                                    key: 'class',
                                                    sortable: true,
                                                    sortKey: `class.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                                },
                                                {
                                                    label: <Translate id='registration.settings.category.label.gender' />,
                                                    key: 'gender',
                                                    className: 'text-center td-shrink',
                                                    dataClassName: 'text-center td-shrink',
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='registration.settings.category.label.minAge' />,
                                                    key: 'min_age',
                                                    className: 'text-center',
                                                    dataClassName: 'text-center',
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='registration.settings.category.label.maxAge' />,
                                                    key: 'max_age',
                                                    className: 'text-center',
                                                    dataClassName: 'text-center',
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='misc.status' />,
                                                    key: 'active_in_this_period',
                                                    className: 'text-center td-shrink',
                                                    dataClassName: 'text-center td-shrink',
                                                    sortable: true,
                                                },

                                            ]}
                                            renderRow={(columnKey, data) => {
                                                switch (columnKey){
                                                    case 'display_order':
                                                        return data.display_order || '-'
                                                    case 'organisation':
                                                        return (
                                                            <div className={`small${data.organisation.organisation_id === orgContext.organisation_id ? ' text-dark font-medium' : ' text-muted'}`}>
                                                                <DisplayI18n
                                                                    field='name'
                                                                    defaultValue={data.organisation.organisation_name}
                                                                    i18n={data.organisation.i18n}
                                                                />
                                                            </div>
                                                        )
                                                    case 'name':
                                                        return (
                                                            <DisplayI18n
                                                                field='name'
                                                                defaultValue={data.name}
                                                                i18n={data.i18n}
                                                            />
                                                        )
                                                    case 'gender':
                                                        return (
                                                            data.team_parent_category ?
                                                                <span>
                                                                    {data.team_parent_category.gender ? <Translate id={`registration.settings.category.table.gender.${data.team_parent_category.gender.toLowerCase()}`} /> : '-'}
                                                                </span>
                                                                :
                                                                <span>
                                                                    {data.gender ? <Translate id={`registration.settings.category.table.gender.${data.gender.toLowerCase()}`} /> : '-'}
                                                                </span>
                                                        )
                                                    case 'min_age':
                                                        return (
                                                            <>
                                                                <div>
                                                                    {data.min_age || data.team_parent_category?.min_age || '-'}
                                                                </div>
                                                                {data.birth_year_to &&
                                                                    <div className='small text-muted'>
                                                                        <Translate id='registration.settings.category.label.birthYear' /> {data.birth_year_to}
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                    case 'max_age':
                                                        return (
                                                            <>
                                                                <div>
                                                                    {data.max_age || data.team_parent_category?.max_age || '-'}
                                                                </div>
                                                                {data.birth_year_from &&
                                                                    <div className='small text-muted'>
                                                                        <Translate id='registration.settings.category.label.birthYear' /> {data.birth_year_from}
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                    case 'class':
                                                        return (
                                                            <span>
                                                                {data.class?.active == "0" && <i className="mdi mdi-alert-outline text-warning mr-1" />}
                                                                <DisplayI18n
                                                                    field='short_name'
                                                                    defaultValue={displayI18n('name', data.class?.i18n, data.class?.short_name || data.class?.name || '-', i18nContext.getGenericLocale())}
                                                                    i18n={data.class?.i18n}
                                                                />
                                                            </span>
                                                        )
                                                    case 'division':
                                                        return (
                                                            <span>
                                                                {data.division?.active == "0" && <i className="mdi mdi-alert-outline text-warning mr-1" />}
                                                                <DisplayI18n
                                                                    field='short_name'
                                                                    defaultValue={data.division?.short_name || '-'}
                                                                    i18n={data.division?.i18n}
                                                                />
                                                            </span>
                                                        )
                                                    case 'active_in_this_period':
                                                        if(data.active_in_this_period == 1 && data.active == 1){
                                                            return (
                                                                data.team_parent_category?.active == 0 || data.class?.active == 0 || data.division?.active == 0 ? <i className="mdi mdi-alert-outline text-warning" /> : <i className="mdi mdi-check text-primary" />
                                                            )
                                                        }
                                                        return <i className="mdi mdi-close text-danger" />

                                                    default:
                                                        break;
                                                }
                                            }}
                                            onColumnClick={(e, data) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        panelProps.onSingleSelectChange(data);
                                                        break;
                                                }
                                            }}
                                            rowIsHighlighted={(data) => data.checked}
                                        >
                                            <div className='mb-2'>
                                                <div className='d-flex flex-wrap justify-content-between'>
                                                    <SearchInput />
                                                    <div className='d-flex ml-auto text-right nowrap'>
                                                        <Refresh />
                                                        <CanDoAction action='ADD' componentCode='teams' componentPermissionCode='team_categories'>
                                                            <Button className='ml-2' color="primary" onClick={toggleModalAdd}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                            <SettingsRegistrationCategoryAddModal types={types} classDivision={classDivision} modal={modalAdd} toggleModal={toggleModalAdd} viewAs={viewAs} />
                                                        </CanDoAction>
                                                    </div>
                                                </div>
                                            </div>
                                            <SpordleTableView />
                                        </SpordleTableProvider>
                                    )
                                }}
                            />
                        </CardBody>
                    </Card>
                )}
            </I18nHelperContext.Consumer>
        </I18nHelperContextProvider>
    );
}

export default SettingsRegistrationCategory;