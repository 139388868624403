import Translate from "@spordle/intl-elements"
import { success } from "@spordle/toasts"
import { Form, Formik } from "formik"
import { useCallback, useContext } from "react"
import { Button, Collapse, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import AnalyticsModal from "../../analytics/AnalyticsModal"
import { AxiosIsCancelled } from "../../api/CancellableAPI"
import { MembersContext } from "../../contexts/MembersContext"
import { DisplayI18n } from "../../helpers/i18nHelper"
import CollapsibleCard from "../collapsibleCard/CollapsibleCard"
import CustomAlert from "../CustomAlert"
import OverlayLoader from "../loading/OverlayLoader"
import MemberRegistrationCard from "./MemberRegistrationCard"

/**
 * Modal for bulk confirm member registrations
 * @param {Object} param
 * @param {Array} param.registrations
 * @param {Function} param.close
 * @param {boolean} param.isOpen
 * @param {Function} param.onConfirmed Callback function called when the registrations have been confirmed
 */
const BatchConfirmMemberRegistration = ({ isOpen, close, registrations, onConfirmed }) => {
    const { confirmInvoiceItem, deleteInvoiceItemCancellation } = useContext(MembersContext);

    const validateMemberRegistrations = useCallback(() => {
        return registrations.reduce((sortedRegistration, registration) => {
            if(registration.memberRegistration.confirmed_at){
                sortedRegistration[1].push(registration);
            }else{
                sortedRegistration[0].push(registration);
            }
            return sortedRegistration;
        }, [ [], [] ]);
    }, [ registrations ]);
    const [ validMemberRegistrations, invalidMemberRegistrations ] = validateMemberRegistrations();

    return (
        <AnalyticsModal analyticsName='OnlineStoreMultiConfirmRegistration' isOpen={isOpen}>
            <Formik
                initialValues={{}}
                onSubmit={async(_values, { setStatus }) => {
                    return Promise.all(validMemberRegistrations.reduce((apiCalls, registration) => {
                        apiCalls.push(confirmInvoiceItem(registration.memberRegistration.invoice_item_id));
                        if(registration.memberRegistration.cancelled_at){
                            apiCalls.push(deleteInvoiceItemCancellation(registration.memberRegistration.invoice_item_id));
                        }
                        return apiCalls;
                    }, []))
                        .then(() => {
                            success({ msg: 'memberProfile.registration.confirmRegistration.success' });
                        })
                        .then(close)
                        .then(onConfirmed)
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                if(error.i18n){
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                }else{
                                    setStatus(<Translate id='memberProfile.registration.confirmRegistration.error' />)
                                }
                            }
                        })
                }}
            >
                {(formik) => (
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={close}>
                            <Translate id='memberProfile.registration.confirmRegistration.multi.title' />
                        </ModalHeader>
                        <Form>
                            <ModalBody>
                                <div className='mb-3'>
                                    <Translate id='memberProfile.registration.confirmRegistration.multi.description' />
                                </div>
                                <CollapsibleCard
                                    title={<Translate id='memberProfile.registration.confirmRegistration.multi.valid.title' values={{ registrations: validMemberRegistrations.length }} />}
                                    subtitle={<Translate id='memberProfile.registration.confirmRegistration.multi.valid.subtitle' />}
                                    initOpen={validMemberRegistrations.length <= 3}
                                >
                                    {validMemberRegistrations.length ?
                                        validMemberRegistrations.map((registration) => (
                                            <MemberRegistrationCard key={registration.key} registration={registration} />
                                        ))
                                        :
                                        <Translate id='memberProfile.registration.confirmRegistration.multi.valid.none' />
                                    }
                                </CollapsibleCard>
                                {!!invalidMemberRegistrations.length &&
                                    <CollapsibleCard
                                        error
                                        title={<Translate id='memberProfile.registration.confirmRegistration.multi.invalid.title' values={{ registrations: invalidMemberRegistrations.length }} />}
                                        subtitle={<Translate id='memberProfile.registration.confirmRegistration.multi.invalid.subtitle' />}
                                        className='mt-3'
                                    >
                                        {invalidMemberRegistrations.map((registration) => (
                                            <MemberRegistrationCard
                                                key={registration.key}
                                                registration={registration}
                                                errors={[
                                                    <div key='alreadyConfirmed' className="text-danger">
                                                        <Translate id='memberProfile.registration.confirmRegistration.multi.invalid.alreadyConfirmed' />
                                                    </div>,
                                                ]}
                                            />
                                        ))}
                                    </CollapsibleCard>
                                }
                                <Collapse isOpen={isOpen && !!formik.status} mountOnEnter unmountOnExit>
                                    <CustomAlert
                                        color='danger'
                                        className='mt-3 mb-0'
                                        text={formik.status}
                                        translateText={!formik.status}
                                        toggle={validMemberRegistrations.length ? () => formik.setStatus() : undefined}
                                    />
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' type='submit' disabled={validMemberRegistrations.length == 0 || formik.isSubmitting}>
                                    <Translate id='misc.confirm' />
                                </Button>
                                <Button color='primary' outline onClick={close} type='button' disabled={formik.isSubmitting}>
                                    <Translate id='misc.cancel' />
                                </Button>
                            </ModalFooter>
                        </Form>
                    </OverlayLoader>
                )}
            </Formik>
        </AnalyticsModal>
    )
}

export default BatchConfirmMemberRegistration