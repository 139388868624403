import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import { Button, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import AssignOfficiatingPermissionsModal from '../../../../../components/assignOfficiatingPermissionsModal/AssignOfficiatingPermissionsModal';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { RolesContext } from '../../../../../contexts/RolesContext';
import { copyToClipBoard } from '../../../../../helpers/clipboardHelper';
import MemberActionsMenu from '../../../components/MemberActionsMenu';
import AppealsAddContext from './../../../../../components/appeals/AppealsAddContext';
import TransferRequestModal from './../../../../../components/transferRequest/TransferRequestModal';
import MemberMerge from './../../../search/memberMerge/MemberMerge';
import ReactivateMemberModal from './ReactivateMemberModal';
import ValidateMemberModal from '../../../../../components/validateMember/ValidateMemberModal';
import SendCommModal from '../../../../../components/communications/SendCommModal';
import { Tooltip } from "@mantine/core";
import CanDoAction from '../../../../../components/permissions/CanDoAction';
import QuickTour from '../../../../../components/quickTour/QuickTour';
import RequestMandatoryChangesModal from './RequestMandatoryChangesModal';
import RequestClassificationChangeModal from './RequestClassificationChangeModal';
import { getMemberClassificationRequestStatus } from '../../../../../api/client/classificationRequestChanges';
import { getMemberRequestChangesStatus } from '../../../../../api/client/memberRequestChanges';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import RequestPermanentReleaseModal from './RequestPermanentReleaseModal';
import ChangeMemberStatusModal from './ChangeMemberStatusModal';
import PotentialIneligibleMembersModal from '../../../../tasks/potentialIneligibleMembers/sidePanel/PotentialIneligibleMembersModal';
import ManageMemberTypesModal from './memberTypes/ManageMemberTypesModal';
import MemberNumberCard from '../../../../../components/memberNumberCard/MemberNumberCard';

const MemberHeaderActions = ({ isOfficial, minimalView, refreshMemberProfile, officiatingCoach, refreshOfficiatingInfo, inQuickView }) => {
    const {
        currentMember,
        shouldAttemptAutoConfirm,
        setShouldAttemptAutoConfirm,
        shouldOpenChangeRequestModal,
        shouldOpenClassificationChangeRequestModal,
        setShouldOpenChangeRequestModal,
        setShouldOpenClassificationChangeRequestModal,
        shouldOpenPermanentReleaseRequestsModal,
        setShouldOpenPermanentReleaseRequestsModal,
        shouldOpenPotentialIneligibleModal,
        setShouldOpenPotentialIneligibleModal,
    } = useContext(MembersContext);

    const identityRolesContext = useContext(IdentityRolesContext);
    const organizationContext = useContext(OrganizationContext);
    const rolesContext = useContext(RolesContext);

    const hasTransferRequest = currentMember.active_transfer == '1';

    const [ fieldChangeRequestStatuses, setFieldChangeRequestStatuses ] = useState([])
    const [ changeRequestStatuses, setChangeRequestStatuses ] = useState([])

    useEffect(() => {
        getMemberRequestChangesStatus()
            .then(setFieldChangeRequestStatuses)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                }
            })
        getMemberClassificationRequestStatus()
            .then(setChangeRequestStatuses)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                }
            })
    }, [])


    const [ modalsOpen, setModalsOpen ] = useState({
        transfer: false,
        transferManual: false,
        appeals: false,
        merge: false,
        block: false,
        confirm: false,
        unblock: false,
        ineligible: false,
        removeIneligible: false,
        reactivate: false,
        officiating: false,
        validateMember: false,
        sendComm: false,
        unconfirm: false,
        requestMandatoryChanges: false,
        requestRelease: false,
        ctiRequest: false,
        classificationChange: false,
        changeStatusModal: false,
        potentialIneligible: false,
        manageMemberTypes: false,
    });

    const [ statusModalInitialValue, setStatusModalInitialValue ] = useState('');

    const toggleModal = (modal) => setModalsOpen({ [modal]: !modalsOpen[modal] });

    // Disable all actions when no status or status logs are present
    // Disable when status is INELIGIBLE && we don't have access to remove ineligibility
    const disabledActionsMenu = (currentMember.member_status == null || currentMember.member_status_logs == null || currentMember.createdAsPermanentlyIneligible) || (!rolesContext.canDoAction('DELETE', 'members', 'manage_ineligible_members') && currentMember.member_status?.name === 'INELIGIBLE')
    const allAddressesConfirmedOrRejected = currentMember.addresses?.every((addr) => (addr.status === 'CONFIRMED' || addr.status === 'REJECTED'))

    // Conditions That determine which actions can be executed
    const [ isCurrentOrganizationOrParent, setIsCurrentOrganizationOrParent ] = useState(false);
    useEffect(() => {
        if(currentMember.organisation?.organisation_id){
            organizationContext.isCurrentOrParentOrg(currentMember.organisation?.organisation_id)
                .then((isCurrentOrParentOrg) => {
                    setIsCurrentOrganizationOrParent(isCurrentOrParentOrg)
                })
                .catch((error) => { console.error(error.message) });
        }
    }, [ currentMember.organisation?.organisation_id ])
    // This will let us manage each action seperatly, it makes it easier to manage conditions & dividers

    // this logic is also in MemberActionsMenu.js in actionConditions object, if changed please make them match
    const canConfirmMember = rolesContext.canDoAction('EDIT', 'members', 'confirm_members') && !minimalView == 1 && currentMember.member_status?.name !== 'INELIGIBLE' && currentMember.member_status?.name !== 'INACTIVE' && currentMember.member_status?.name !== 'BLOCKED' && currentMember.member_status?.name !== 'CONFIRMED' && isCurrentOrganizationOrParent && allAddressesConfirmedOrRejected;

    useEffect(() => {
        if(shouldAttemptAutoConfirm && canConfirmMember){
            toggleModal('confirm')
        }else if(!canConfirmMember){
            setShouldAttemptAutoConfirm(false);
        }
    }, [ shouldAttemptAutoConfirm, canConfirmMember ])

    useEffect(() => {
        // on change of member set it to false
        setShouldAttemptAutoConfirm(false);
    }, [ currentMember.member_id ])

    useEffect(() => {
        if(shouldOpenChangeRequestModal){
            toggleModal('requestMandatoryChanges');
            setShouldOpenChangeRequestModal(false);
        }
        if(shouldOpenClassificationChangeRequestModal){
            toggleModal('classificationChange');
            setShouldOpenClassificationChangeRequestModal(false);
        }
        if(shouldOpenPermanentReleaseRequestsModal){
            toggleModal('requestRelease');
            setShouldOpenPermanentReleaseRequestsModal(false);
        }
        if(shouldOpenPotentialIneligibleModal){
            toggleModal('potentialIneligible');
            setShouldOpenPotentialIneligibleModal(false);
        }
    }, [ shouldOpenChangeRequestModal, shouldOpenClassificationChangeRequestModal, shouldOpenPermanentReleaseRequestsModal, shouldOpenPotentialIneligibleModal ])


    const actionTooltipId = currentMember.member_status?.name === 'INELIGIBLE'
        ?
        `members.search.sidePanelMemberSearch.disabled.actions.${currentMember.member_status?.name}`
        : (currentMember.member_status == null || currentMember.member_status_logs == null)
            ? `members.search.sidePanelMemberSearch.disabled.actions.noStatus`
            : '';

    return (
        <div className="d-flex justify-content-end mb-3 float-md-right ">
            <CanDoAction action='ADD' componentCode="members" componentPermissionCode="confirm_members">
                {(canConfirmMember && currentMember.incomplete_profile !== '1') &&
                    <QuickTour
                        quickTourName={'quickTourConfirmButtonMemberProfile'}
                        title={<Translate id="members.profile.memberProfile.header.card.quitour.title" />}
                        message={<Translate id="members.profile.memberProfile.header.card.quitour.message" />}
                    >
                        <Button color='success' id={'member-actions-confirm'} onClick={() => { setStatusModalInitialValue('CONFIRMED'); toggleModal('changeStatusModal'); }} disabled={currentMember.incomplete_profile == '1'}>
                            <Translate id='members.search.confirm.btn.label' />
                        </Button>
                    </QuickTour>
                }
            </CanDoAction>
            <MemberNumberCard member={currentMember} className="ml-2" />
            {!inQuickView &&
                <Button className="ml-2" color="primary" outline data-tip="members.profile.memberProfile.header.btns.copyUrl" data-for="memberProfileActionsTooltip" onClick={() => copyToClipBoard(location.protocol + '//' + location.host + location.pathname)}>
                    <i className="mdi mdi-content-copy" />
                </Button>
            }


            {
                // theses conditions determine whether or not we see the actions button, if one of these permissions is true, we see the action menu,
                // this is in place to avoid showing a dropdown action button and then it not containing any options
                (
                    identityRolesContext.federation?.organisation_id !== organizationContext?.organisation_id ||
                    rolesContext.canDoAction('ADD', 'members', 'members_request_transfer_usa', true) ||
                    rolesContext.canDoAction('ADD', 'members', 'members_request_transfer_international', true) ||
                    rolesContext.canDoAction('ADD', 'members', 'participant_transfer_bypass', true) ||
                    rolesContext.canDoAction('ADD', 'members', 'member_appeal_request', true) ||
                    rolesContext.canDoAction('ADD', 'members', 'members_merge', true) ||
                    rolesContext.canDoAction('ADD', 'members', 'manage_ineligible_members', true) ||
                    rolesContext.canDoAction('DELETE', 'members', 'manage_ineligible_members', true) ||
                    rolesContext.canDoAction('EDIT', 'members', 'member_types')
                )
                &&
                    <div>
                        <UncontrolledDropdown inNavbar className="ml-2" disabled={disabledActionsMenu}>
                            <Tooltip
                                position='left'
                                withArrow
                                disabled={!actionTooltipId}
                                label={<Translate id={actionTooltipId} />}
                            >
                                <DropdownToggle caret color="primary" outline>
                                    <Translate id='misc.actions' />
                                </DropdownToggle>
                            </Tooltip>
                            <DropdownMenu right>
                                <MemberActionsMenu
                                    // SET STATUS
                                    confirmOnClick={() => { setStatusModalInitialValue('CONFIRMED'); toggleModal('changeStatusModal'); }}
                                    unconfirmOnClick={() => { setStatusModalInitialValue('UNCONFIRMED'); toggleModal('changeStatusModal'); }}
                                    blockMemberOnClick={() => { setStatusModalInitialValue('BLOCKED'); toggleModal('changeStatusModal'); }}
                                    ineligibleMemberOnClick={() => { setStatusModalInitialValue('INELIGIBLE'); toggleModal('changeStatusModal'); }}

                                    // REVERT
                                    removeIneligibleMemberOnClick={() => { setStatusModalInitialValue(currentMember.member_status_logs[currentMember.member_status_logs.length - 2].member_status.code); toggleModal('changeStatusModal'); }}
                                    unblockMemberOnClick={() => { setStatusModalInitialValue(currentMember.member_status_logs.findLast((log) => log.member_status.code !== 'BLOCKED')?.member_status.code); toggleModal('changeStatusModal'); }}

                                    potentialIneligibleModalOnClick={() => toggleModal('potentialIneligible')}
                                    reactivateOnClick={() => toggleModal('reactivate')}
                                    transferOnClick={() => toggleModal('transfer')}
                                    transferManualOnClick={() => toggleModal('transferManual')}
                                    appealsOnClick={() => toggleModal('appeals')}
                                    mergeMembersOnClick={() => toggleModal('merge')}
                                    member={{ ...currentMember, hasTransferRequest: hasTransferRequest }}
                                    refreshMemberProfile={refreshMemberProfile}
                                    sendCommOnClick={() => toggleModal('sendComm')}
                                    assignOfficiatingPermissionsOnClick={() => toggleModal('officiating')}
                                    validateMemberOnClick={() => toggleModal('validateMember')}
                                    manageMemberTypesOnClick={() => toggleModal('manageMemberTypes')}

                                    //Request Actions
                                    requestMandatoryChanges={() => toggleModal('requestMandatoryChanges')}
                                    requestRelease={() => toggleModal('requestRelease')}
                                    ctiRequest={() => toggleModal('ctiRequest')}
                                    requestClassificationChange={() => toggleModal('classificationChange')}
                                />
                            </DropdownMenu>
                        </UncontrolledDropdown>

                    </div>
            }


            {/* STATUS CHANGE MODALS  */}
            <ChangeMemberStatusModal
                isOpen={modalsOpen.changeStatusModal}
                toggle={() => { toggleModal('changeStatusModal'); setStatusModalInitialValue(''); }}
                member={currentMember}
                refreshMemberProfile={refreshMemberProfile}
                statusModalInitialValue={statusModalInitialValue}
                // statusModalInitialValue={'INELIGIBLE'}
            />
            <PotentialIneligibleMembersModal
                isOpen={modalsOpen.potentialIneligible}
                toggle={() => { refreshMemberProfile(); toggleModal('potentialIneligible'); }}
                selectedRows={[ { member_id: currentMember.member_id, member_potential_ineligible_id: currentMember.member_potential_ineligible_id, member: currentMember, created_at: currentMember.potential_ineligible_created_at, organisation: currentMember.organisation } ]}
                forceCloseSidePanel={() => {}}
            />

            {/* REQUEST MODALS */}
            <RequestMandatoryChangesModal
                isOpen={modalsOpen.requestMandatoryChanges}
                toggle={() => toggleModal('requestMandatoryChanges')}
                member={currentMember}
                refreshMemberProfile={refreshMemberProfile}
                fieldChangeRequestStatuses={fieldChangeRequestStatuses}
            />
            <RequestClassificationChangeModal
                isOpen={modalsOpen.classificationChange}
                toggle={() => toggleModal('classificationChange')}
                member={currentMember}
                refreshMemberProfile={refreshMemberProfile}
                classificationChangeRequestStatuses={changeRequestStatuses}
            />
            <RequestPermanentReleaseModal
                isOpen={modalsOpen.requestRelease}
                toggle={() => toggleModal('requestRelease')}
                member={currentMember}
                refreshMemberProfile={refreshMemberProfile}
                changeRequestStatuses={changeRequestStatuses}
            />

            {/* MODALS */}
            <AssignOfficiatingPermissionsModal
                isOpen={modalsOpen.officiating}
                toggle={() => toggleModal('officiating')}
                member={currentMember}
                officiatingCoach={officiatingCoach}
                refreshMemberProfile={refreshOfficiatingInfo}
            />

            <ReactivateMemberModal
                isOpen={modalsOpen.reactivate}
                toggle={() => toggleModal('reactivate')}
                member={currentMember}
                refreshMemberProfile={refreshMemberProfile}
            />

            <TransferRequestModal
                isOpen={modalsOpen.transfer}
                toggle={() => toggleModal('transfer')}
                memberId={currentMember?.member_id}
                memberOrgId={currentMember?.organisation?.organisation_id}
                updateSidePanel={null}
            />
            <TransferRequestModal
                isOpen={modalsOpen.transferManual}
                toggle={() => toggleModal('transferManual')}
                memberId={currentMember?.member_id}
                memberOrgId={currentMember?.organisation?.organisation_id}
                updateSidePanel={null}
                manualTransferMode
            />
            <MemberMerge
                memberMerge={currentMember}
                toggle={() => toggleModal('merge')}
                isOpen={modalsOpen.merge}
            />
            <AppealsAddContext
                isOpen={modalsOpen.appeals}
                toggle={() => toggleModal('appeals')}
                memberId={currentMember?.member_id}
            />
            <ValidateMemberModal
                isOpen={modalsOpen.validateMember}
                toggle={() => toggleModal('validateMember')}
                member={currentMember}
            />
            <SendCommModal
                id="memberProfileActions"
                isOpen={!!modalsOpen.sendComm}
                toggle={() => toggleModal('sendComm')}
                recipients={[ currentMember ]}
            />
            <ManageMemberTypesModal
                isOpen={modalsOpen.manageMemberTypes}
                toggle={(success) => {
                    if(success){
                        refreshMemberProfile();
                    }
                    toggleModal('manageMemberTypes')
                }}
                memberTypes={currentMember.member_types}
                memberId={currentMember.member_id}
            />

            {/* <MemberMerge memberMerge={currentMember} isOpen={modalsOpen.merge} toggle={() => toggleModal('merge')} /> */}
        </div>
    );
}

export default MemberHeaderActions;