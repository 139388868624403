import { FormikInputNumber, FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import React from "react";
import {
    Col,
    FormGroup,
    Label,
    Nav,
    NavItem,
    Row,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import FormikEditable from "../../../../components/formik/FormikEditable";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import DisplayOrganization from '../../../../components/organization/DisplayOrganization';
import SidePanel from '../../../../components/sidePanel/SidePanel';
import { fail, success } from '@spordle/toasts';
import UserImg from '../../../../components/UserImg';
import { AffiliationFeesContext } from '../../../../contexts/AffiliationFeesContext';
import { PeriodsContext } from '../../../../contexts/contexts';
import { I18nContext } from '../../../../contexts/I18nContext';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { RolesContext } from '../../../../contexts/RolesContext';
import { WaiversContext } from '../../../../contexts/WaiversContext';
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import withContexts from '../../../../helpers/withContexts';
import AffiliationFeeCustomForm from './AffiliationFeeCustomForm';
import CustomAlert from '../../../../components/CustomAlert';

// This sidepanel works with a prop "viewMode" that comes from the view && this.props.selectedRows[0]?.noCurrentParentFee
class AffiliationFeesSidepanel extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            waivers: [],
            activeTab: '1',
        }
    }

    deleteAffiliationFee = () => {
        return this.props.AffiliationFeesContext.deleteAffiliationFee(this.props.selectedRows[0].affiliation_fee_id)
            .then(() => {
                this.props.tableRef.deleteRow(this.props.selectedRows[0].affiliation_fee_id).then(this.props.toggle)
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    updateMultipleAffilationFees = ({ active, amount, custom_form_id }) => {
        return this.props.AffiliationFeesContext.updateAffiliationFees({
            affiliation_fees: this.props.selectedRows
                .filter((aFee) => aFee.noCurrentParentFee !== true)
                .map((aFee) => ({
                    affiliation_fee_id: aFee.affiliation_fee_id,
                    custom_form_id: custom_form_id || aFee.custom_form?.custom_form_id,
                    amount: amount || aFee.fee?.amount,
                    early_amount: aFee.fee?.early_amount,
                    early_amount_until: aFee.fee?.early_amount_until,
                    late_amount: aFee.fee?.late_amount,
                    late_amount_after: aFee.fee?.late_amount_after,
                    sku: aFee.fee?.sku,
                    name: aFee.fee?.name,
                    description: aFee.fee?.description,
                    active: active || aFee.active,
                    i18n: aFee.fee?.i18n || [],
                })),
        }).then(() => {
            success();
            this.setState(() => ({ isLoading: false }));
            this.props.tableRef?.refreshTable();
            this.props.forceCloseSidePanel();
        }).catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
                this.setState(() => ({ isLoading: false }));
            }
        })
    }

    componentDidMount(){
        this.props.WaiversContext.getWaivers()
            .then((promise) => {
                this.setState({ waivers: promise });
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    refreshSidepanel = () => {
        this.setState(() => ({
            isLoading: true,
        }))
        // get fees and format them, then sync the data with the table
        return this.props.AffiliationFeesContext.getAffiliationFees().then((data) => {
            const membershipFee = data.find((fee) => this.props.selectedRows[0].member_type.member_type_id === fee.member_type.member_type_id && this.props.selectedRows[0].division.division_id === fee.division.division_id)
            this.props.syncRows(this.props.formatData(membershipFee));
            this.setState(() => ({
                isLoading: false,
            }))
            this.props.tableRef.refreshTable()
        }).catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            }
            this.setState(() => ({ isLoading: false }));
        })

    }

    render(){
        const canEdit = this.props.RolesContext.canDoAction("EDIT", "catalog", "affiliation_fees");
        const isFederation = this.props.IdentityRolesContext.federation?.organisation_id === this.props.OrganizationContext.organisation_id
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <SidePanel.Header noBorder>
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />
                        {/* we don't allow deleting multiple fees at a time */}
                        {this.props.selectedRows.length <= 1 &&
                            <SidePanel.ActionsMenu action='DELETE' componentCode='catalog' componentPermissionCode='affiliation_fees'>
                                <SidePanel.MenuDelete
                                    onConfirm={this.deleteAffiliationFee}
                                    translateModalMsg
                                    modalMsg='organization.profile.roles.sidePanelOrganizationRoles.deleteRole.topText'
                                    modalContent={
                                        <Row className='mt-3'>
                                            <Col md='6' className='d-flex align-items-center justify-content-center flex-column border-right'>
                                                <div className='font-medium h5'>
                                                    <DisplayI18n
                                                        field="short_name"
                                                        i18n={this.props.selectedRows[0].division?.i18n}
                                                        defaultValue={this.props.selectedRows[0].division?.short_name}
                                                    />
                                                </div>
                                                <div className='small'>
                                                    <DisplayI18n
                                                        field="name"
                                                        i18n={this.props.selectedRows[0].apply_for_organisation?.i18n}
                                                        defaultValue={this.props.selectedRows[0].apply_for_organisation?.organisation_name}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md='6' className='d-flex align-items-center justify-content-center flex-column'>
                                                <div className='mr-2'>
                                                    <UserImg
                                                        abbr={this.props.OrganizationContext.abbreviation}
                                                        src={this.props.OrganizationContext.logo?.full_path}
                                                        alt={this.props.OrganizationContext.organisation_name}
                                                        filePos={this.props.OrganizationContext.logo?.file_position}
                                                    />
                                                </div>
                                                <div className='small'>{this.props.OrganizationContext.organisation_name}</div>
                                            </Col>
                                        </Row>
                                    }
                                />
                            </SidePanel.ActionsMenu>
                        }
                    </div>
                    {this.props.selectedRows.length <= 1 ?
                        <>
                            <SidePanel.Title>
                                <DisplayI18n
                                    field='name'
                                    defaultValue={this.props.selectedRows[0].division?.short_name || '-'}
                                    i18n={this.props.selectedRows[0].division?.i18n}
                                />
                            </SidePanel.Title>
                            <div>
                                <DisplayOrganization organisation={this.props.selectedRows[0].apply_for_organisation}>
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={this.props.selectedRows[0].apply_for_organisation?.organisation_name || '-'}
                                        i18n={this.props.selectedRows[0].apply_for_organisation?.i18n}
                                    />
                                </DisplayOrganization>
                            </div>
                        </>
                        :
                        <div>
                            <SidePanel.Title>
                                <Translate values={{ count: this.props.selectedRows.length }} id="catalog.registrationFees.sidePanel.fees.count" />
                            </SidePanel.Title>
                            {this.props.selectedRows.some((row) => row.noCurrentParentFee) &&
                                <CustomAlert className='mt-2 mb-0' color='warning' translateText text='catalog.registrationFees.sidePanel.fees.feeNeededMessage' />
                            }
                        </div>

                    }
                </SidePanel.Header>
                <Nav tabs>
                    <NavItem className="w-50 text-center">
                        <NavLink
                            onClick={() => { this.setState(() => ({ activeTab: '1' })) }}
                            active={this.state.activeTab === '1'}
                        >
                            <Translate id='catalog.registrationFees.sidePanel.tab.fee' />
                        </NavLink>
                    </NavItem>
                    <NavItem className="w-50 text-center">
                        <NavLink
                            onClick={() => { this.setState(() => ({ activeTab: '2' })) }}
                            active={this.state.activeTab === '2'}
                        >
                            <Translate id='catalog.registrationFees.sidePanel.tab.catalog' />
                        </NavLink>
                    </NavItem>
                </Nav>
                <div className="p-3">
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            {this.props.selectedRows.length <= 1 &&
                                <FormGroup>
                                    <Label for='memberTypeSidepanel' className='text-muted'><Translate id='catalog.affiliationFees.sidePanel.memberType' /></Label>
                                    <div className='font-medium text-dark'>
                                        <DisplayI18n
                                            field='name'
                                            defaultValue={this.props.selectedRows[0].member_type?.name || '-'}
                                            i18n={this.props.selectedRows[0].member_type?.i18n}
                                        />
                                    </div>
                                </FormGroup>
                            }
                            {/* //// STATUS FIELD ////*/}
                            <FormGroup>
                                <Label for='active' className='text-muted'><Translate id='catalog.affiliationFees.sidePanel.status' /></Label>
                                <FormikEditable
                                    id='active'
                                    noConfirmation
                                    readOnly={(this.props.selectedRows[0]?.noCurrentParentFee && this.props.selectedRows.length <= 1 && !isFederation)}
                                    disabled={!canEdit || (this.props.selectedRows[0]?.noCurrentParentFee && this.props.selectedRows.length <= 1 && !isFederation)}
                                    initialValues={{
                                        active: this.props.selectedRows.length > 1 ? null : this.props.selectedRows[0]?.active || 0,
                                    }}
                                    onSubmit={(values) => {
                                        if(this.props.selectedRows.length > 1 && values.active){
                                            this.setState(() => ({ isLoading: true }));
                                            this.updateMultipleAffilationFees(values)
                                        }else if((this.props.selectedRows[0]?.active !== values.active) && values.active){
                                            this.setState(() => ({ isLoading: true }));

                                            // When there is no current affiliation fee, we need to create it
                                            if(!this.props.selectedRows[0].affiliation_fee_id){
                                                const newAffiliationFee = {
                                                    division_id: this.props.selectedRows[0].division.division_id,
                                                    member_type_id: this.props.selectedRows[0].member_type.member_type_id,
                                                    amount: 0,
                                                }
                                                this.props.AffiliationFeesContext.createAffiliationFee(this.props.OrganizationContext.organisation_id, this.props.PeriodsContext.selectedPeriod.period_id, newAffiliationFee)
                                                    .then(() => {
                                                        this.refreshSidepanel();
                                                        success();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                            this.setState(() => ({ isLoading: false }));
                                                        }
                                                    })
                                            }else{
                                                this.props.AffiliationFeesContext.updateAffiliationFeePartial(this.props.selectedRows[0].affiliation_fee_id, values)
                                                    .then(() => {
                                                        this.refreshSidepanel();
                                                        success();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                            this.setState(() => ({ isLoading: false }));
                                                        }
                                                    })
                                            }
                                        }
                                    }}
                                >
                                    {(isEditing, options) => {
                                        if(!isEditing){
                                            if(this.props.selectedRows.length > 1)
                                                return <div className='font-medium text-dark'> - </div>
                                            return (
                                                <div className='font-medium text-dark'><Translate id={`misc.status.${this.props.selectedRows[0]?.active || 0}`} /></div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                search={false}
                                                name='active'
                                                id='active'
                                                autoFocus
                                                menuIsDefaultOpen
                                                onOptionSelected={options.stopEditing}
                                                defaultData={[
                                                    {
                                                        label: 'misc.status.1',
                                                        translateLabel: true,
                                                        value: '1',
                                                    },
                                                    {
                                                        label: 'misc.status.0',
                                                        translateLabel: true,
                                                        value: '0',
                                                    },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                                {(this.props.selectedRows[0]?.noCurrentParentFee && this.props.selectedRows.length <= 1 && !isFederation) &&
                                    <div className="text-warning"><i className="mdi mdi-alert-outline" /> <Translate id='catalog.affiliationFees.feeNeededMessage' /></div>
                                }
                            </FormGroup>
                            {/* //// FEE FIELD ////*/}
                            <FormGroup>
                                <Label for='amount' className='text-muted'><Translate id='catalog.affiliationFees.sidePanel.fee' /> <span className="small">(<Translate id='catalog.affiliationFees.sidePanel.taxIncluded' />)</span></Label>
                                <FormikEditable
                                    id='amount'
                                    readOnly={(this.props.selectedRows[0]?.noCurrentParentFee && this.props.selectedRows.length <= 1 && !isFederation)}
                                    disabled={!canEdit || !this.props.selectedRows[0]?.fee}
                                    initialValues={{
                                        amount: this.props.selectedRows.length > 1 ? null : (this.props.selectedRows[0]?.fee?.amount / 100) || 0,
                                    }}
                                    onSubmit={(values) => {
                                        const apiValues = { amount: (values.amount * 100).toFixed(0) };

                                        if(this.props.selectedRows.length > 1 && values.amount){
                                            this.setState(() => ({ isLoading: true }));
                                            this.updateMultipleAffilationFees(apiValues)
                                        }else if((this.props.selectedRows[0]?.fee?.amount / 100 !== values.amount) && values.amount){
                                            this.setState(() => ({ isLoading: true }));

                                            // If we don't have a fee yet for the organiszation, we must POST instead of update
                                            if(!this.props.selectedRows[0].affiliation_fee_id){
                                                const createApiValues = {
                                                    member_type_id: this.props.selectedRows[0].member_type.member_type_id,
                                                    division_id: [ this.props.selectedRows[0].division.division_id ],
                                                    amount: apiValues.amount,
                                                }
                                                // Federation ID must create for current organisation applied to current organisation "apply_for_organisation"
                                                this.props.AffiliationFeesContext.createAffiliationFee(this.props.OrganizationContext.organisation_id, this.props.PeriodsContext.selectedPeriod.period_id, createApiValues)
                                                    .then(() => {
                                                        this.refreshSidepanel();
                                                        success();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                            this.setState(() => ({ isLoading: false }));
                                                        }
                                                    })
                                            }else{
                                                this.props.AffiliationFeesContext.updateAffiliationFeePartial(this.props.selectedRows[0].affiliation_fee_id, apiValues)
                                                    .then(() => {
                                                        this.refreshSidepanel();
                                                        success();
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                            this.setState(() => ({ isLoading: false }));
                                                        }
                                                    })
                                            }
                                        }
                                    }}
                                >
                                    {(isEditing) => {
                                        if(!isEditing){
                                            if(this.props.selectedRows.length > 1)
                                                return <div className='font-medium text-dark'> - </div>
                                            return (
                                                <div className='font-medium text-dark'>{(this.props.selectedRows[0]?.fee?.amount) ? <CurrencyFormat value={this.props.selectedRows[0]?.fee?.amount / 100} /> : '-'}</div>
                                            )
                                        }
                                        return (
                                            <div className="d-flex align-items-center">
                                                <div className="">
                                                    <FormikInputNumber
                                                        manualError allowLeadingZeros fixedDecimalScale
                                                        id='amount'
                                                        name='amount'
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        thousandSeparator=' '
                                                        decimalSeparator={this.props.I18nContext.getGenericLocale() === 'fr' ? ',' : '.'}
                                                    />
                                                </div>
                                                <div className="ml-2 small text-muted">CAD (<Translate id='catalog.affiliationFees.add.taxIncluded' />)</div>
                                            </div>
                                        )

                                    }}
                                </FormikEditable>
                            </FormGroup>
                        </TabPane>
                        <TabPane tabId="2">
                            {/* //// QUESTIONNAIRES FIELD ////*/}
                            <FormGroup>
                                <Label for='custom_form_id' className='text-muted mb-0'><Translate id='catalog.registrationFees.sidePanel.form.customForms' /></Label>
                                <AffiliationFeeCustomForm
                                    refreshSidepanel={this.refreshSidepanel}
                                    updateMultipleAffilationFees={this.updateMultipleAffilationFees}
                                    canEdit={canEdit}
                                    setLoading={(isLoading) => { this.setState(() => ({ isLoading })) }} {...this.props}
                                />
                            </FormGroup>
                            {/* //// WAIVERS FIELD: only field which cannot be edited in multi-mode ////*/}
                            <FormGroup>
                                <Label for='waivers' className='text-muted mb-0'><Translate id='catalog.registrationFees.sidePanel.form.waivers' /></Label>
                                {this.props.selectedRows.length <= 1 ?
                                    <FormikEditable
                                        noConfirmation
                                        id='waivers'
                                        readOnly={(this.props.selectedRows[0]?.noCurrentParentFee && this.props.selectedRows.length <= 1 && !isFederation)}
                                        disabled={!canEdit || (this.props.selectedRows[0]?.noCurrentParentFee && this.props.selectedRows.length <= 1 && !isFederation)}
                                        initialValues={{
                                            waivers: this.props.selectedRows[0]?.waivers?.map((waiver) => waiver.waiver_id) || [],
                                        }}
                                        onSubmit={(values) => {
                                            if(values.waivers.toString() !== this.props.selectedRows[0].waivers?.map((waiver) => waiver.waiver_id).toString()){
                                                this.setState({ isLoading: true });
                                                const apiValues = [ ...values.waivers ]

                                                const _myWaivers = []
                                                // rebuilds waivers array to then be able to use create new values and syncrows
                                                values.waivers.forEach((waiver_id) => {
                                                    const foundWaiver = this.state.waivers?.filter((waiver) => waiver.waiver_id === waiver_id)
                                                    if(Array.isArray(foundWaiver) && foundWaiver.length > 0)
                                                        _myWaivers.push(foundWaiver[0])
                                                });

                                                this.props.AffiliationFeesContext.updateAffiliationFeeWaiver(this.props.selectedRows[0].affiliation_fee_id, apiValues)
                                                    .then(() => {
                                                        this.refreshSidepanel();
                                                        success({ msg: "catalog.affiliationFees.sidePanel.form.updated" });
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message);
                                                            this.setState({ isLoading: false });
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                            }
                                        }}
                                    >
                                        {(isEditing, _, formik) => {
                                            if(!isEditing){
                                                return (
                                                    <div className='font-medium text-dark'>
                                                        <Translate id='catalog.registrationFees.sidePanel.form.waiversSelected' values={{ count: this.props.selectedRows[0].waivers?.length || 0 }} />
                                                    </div> // {{count: fee.waivers.length || 0}
                                                )
                                            }
                                            return (
                                                <FormikSelect
                                                    name='waivers'
                                                    id='waivers'
                                                    clearable
                                                    multi
                                                    autoFocus
                                                    menuIsDefaultOpen
                                                    renderOption={({ option }) => (
                                                        <>
                                                            <div>
                                                                <DisplayI18n
                                                                    field='name'
                                                                    defaultValue={option.label}
                                                                    i18n={option.i18n}
                                                                />
                                                            </div>
                                                            {option.critical == 1 &&
                                                                <div className='text-muted small'><Translate id='misc.critical' /></div>
                                                            }
                                                        </>
                                                    )}
                                                    searchKeys={[
                                                        `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                    ]}
                                                    isLoading={!this.state.waivers}
                                                    options={this.state.waivers ? this.state.waivers.map((waiver) => ({
                                                        label: waiver.name,
                                                        value: waiver.waiver_id,
                                                        i18n: waiver.i18n,
                                                        critical: waiver.critical,
                                                    })) : []}
                                                />
                                            )

                                        }}
                                    </FormikEditable>
                                    :
                                    <CustomAlert color='info' translateText text='catalog.affiliationFees.waivers.not.multi' />
                                }
                            </FormGroup>
                            {(Array.isArray(this.props.selectedRows[0].waivers) && this.props.selectedRows[0].waivers.length > 0 && this.props.selectedRows.length <= 1) &&
                                <div className="bg-light p-2 border rounded">
                                    <div className="font-medium mb-1 text-dark"><Translate id='catalog.registrationFees.sidePanel.form.selectedWaivers' /></div>
                                    <ul className="mb-0 list-unstyled">
                                        {this.props.selectedRows[0].waivers.map((waiver) => (
                                            <li key={waiver.waiver_id}>
                                                <i className="mdi mdi-check text-primary mr-1" /><DisplayI18n field='name' defaultValue={waiver.name} i18n={waiver.i18n} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }
                        </TabPane>
                    </TabContent>
                </div>
            </OverlayLoader>
        )
    }
}

export default withContexts(I18nContext, IdentityRolesContext, OrganizationContext, PeriodsContext, RolesContext, AffiliationFeesContext, WaiversContext)(AffiliationFeesSidepanel);