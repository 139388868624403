import { stringBuilder } from "@spordle/helpers";
import EnvFeatureFlag from "../../../../components/featureFlag/EnvFeatureFlag";
import SidebarLink from "./sidebarLink/SidebarLink";
import Translate from "@spordle/intl-elements";
import { RolesContext } from "../../../../contexts/RolesContext";
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useContext } from "react";

const SidebarItem = ({ prop, closeMenu, favoriteData }) => {
    const rolesContext = useContext(RolesContext);
    let showFor = rolesContext.validateRouteAccess(prop);
    const location = useLocation();

    // this helps us cover the case of reports where we use the 'ONE_OF' permission mode, where only one permission is needed to have access to the report
    if(prop.componentPermissionRule == 'ONE_OF'){
        const permissionsForReport = (prop.permissions || []).map((perm) => ({ permissions: [ perm ] }));

        showFor = permissionsForReport.some((perm) => rolesContext.validateRouteAccess(perm));
    }

    const query = queryString.parse(location.search)

    if(!showFor || prop.hidden || prop.hiddenRoute || prop.redirect || ((prop.devOnly && process.env.REACT_APP_ENVIRONMENT === 'prod'))){
        return null;
    }else if(prop.divider){
        // eslint-disable-next-line react/no-array-index-key
        return <li className="sidebar-separator" />;
    }

    const isActive = (match, location) => !!prop.customReportId && prop.customReportId === query.custom_report_id;

    return showFor && (
    /*--------------------------------------------------------------------------------*/
    /* Adding Sidebar Item                                                            */
    /*--------------------------------------------------------------------------------*/
        <EnvFeatureFlag env={prop.envFeatureFlag}>
            <SidebarLink
                onClick={closeMenu}
                to={prop.path}
                favoriteData={favoriteData}
                linkClassName={stringBuilder({ 'text-purple': showFor === 'ADMIN' })}
                className={stringBuilder({ 'active active-pro w-100': !!prop.pro })}
                isActive={query.custom_report_id || prop.customReportId ? isActive : prop.isActive}
                secondaryPath={prop.secondaryPath}
            >
                <div className="w-100 min-w-0 mr-2">
                    <div className="text-truncate py-1 my-n1">
                        {prop.skipTranslate ? prop.name : <Translate id={prop.name} />}
                    </div>
                </div>
            </SidebarLink>
        </EnvFeatureFlag>
    );
}

export default SidebarItem;