import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ImgUploadModal from '../uploader/imgUpload/ImgUploadModal';
import { Badge } from 'reactstrap';
import { OrganizationContext } from '../../contexts/OrganizationContext'
import UserImg from '../UserImg';
import { stringBuilder } from "@spordle/helpers";

/**
 * Image uploader in a modal
 * @param {object} props
 * @param {string} [props.abbr] Abbreviation that will be shown if there is no img (default will be organisations)
 * @param {string} [props.alt] Alt of the img (default will be organisation's name)
 * @param {string} [props.placeholder] Placeholder there is no img
 * @param {string} [props.className] Class names
 * @param {object} [props.file] Data of the image, should contain file_position + full_path
 * @param {boolean} [props.canEdit] Enable or disable the edit/upload feature
 * @param {function} props.onSave Function called on save, should return a promise
 */
const ProfileHeaderImage = ({ className, file, alt, abbr, onSave, placeholder, hint, canEdit, ...props }) => {
    const orgContext = useContext(OrganizationContext);

    const [ filePicker, setFilePicker ] = useState(false);
    const toggleFilePicker = () => setFilePicker(!filePicker);

    return (
        <div {...props} className={stringBuilder('profileHeader-image-container', className)}>
            <UserImg
                abbr={abbr || orgContext.abbreviation}
                src={file?.full_path || placeholder}
                width="150"
                filePos={file?.file_position}
                alt={alt || orgContext.organisation_name + ' logo'}
                className="p-4"
            />
            {canEdit &&
                <>
                    <div className='profileHeader-image-picker-tooltip'>
                        <Badge onClick={toggleFilePicker} color="secondary" type='button' className='profileHeader-image-picker-badge' style={{ width: "40px", height: "40px" }}>
                            <i className='mdi mdi-camera' />
                        </Badge>
                    </div>
                    <ImgUploadModal
                        title="organisation.profile.header.image.upload.title"
                        onSave={onSave}
                        image={orgContext.logo}
                        isOpen={filePicker}
                        toggle={toggleFilePicker}
                        hint={hint}
                    />
                </>
            }
            {/* <Button color="secondary" onClick={toggleFilePicker} className='profileHeader-image-picker round' style={{width:"40px", height:"40px"}}>
            <Button data-for='img-logo-picker' data-tip='misc.comingSoon' color="secondary" type='button' className='profileHeader-image-picker round' style={{width:"40px", height:"40px"}}>
                <i className='mdi mdi-camera'></i>
            </Button>

            <FilesPicker
                files={JSON.parse(JSON.stringify(uploadFilesDemo))}
                sharedfiles={JSON.parse(JSON.stringify(uploadSharedFiles))}
                isOpen={filePicker}
                toggle={toggleFilePicker}
            /> */}
        </div>
    );
};

export default ProfileHeaderImage;

ProfileHeaderImage.propTypes = {
    abbr: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    src: PropTypes.string,
    file: PropTypes.object,
    onSave: PropTypes.func.isRequired,
};
ProfileHeaderImage.defaultProps = {
    alt: "profile-image",
    canEdit: false,
};