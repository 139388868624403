import React, { createContext, useContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { AxiosIsCancelled, serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { I18nContext } from './I18nContext';
import { OrganizationContext } from './OrganizationContext';
import { IdentityRolesContext } from './IdentityRolesContext';
import { PeriodsContext } from './contexts';
import { fail, fire, removeToast } from '@spordle/toasts';
import { PendingReportToast, SuccessToastMessage } from '../components/reports/PendingReportToast';
import { Spinner } from 'reactstrap';
import useSWR, { mutate } from 'swr';
import { DisplayI18n } from '../helpers/i18nHelper';
import { jsObjectToApi } from '@spordle/helpers';
import { RolesContext } from './RolesContext';
import { customReports } from '../views/reports/reportsDashboard/reportsGroups';
import { parseJSON } from '../helpers/helper';

/** @type {React.Context<Omit<ReportsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const ReportsContext = createContext();
ReportsContext.displayName = 'ReportsContext';

class ReportsContextProvider extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            pendingReports: [],
            isGenerating: false,
            currentReport: {},
            customFormId: "",
            allReports: null,
        };
    }

    /**
     * @private
     */
    _hasAccessToReport = (report) => {
        if(this.props.IdentityRolesContext.isGod() || report.component_permissions?.length < 1){
            return true;
        }else if(report.component_permission_rule === 'ALL'){
            // if component_permission_rule is ALL we need to make sure we have access to all perms,
            return report.component_permissions.every((perm) => {
                //action, componentCode, componentPermissionCode
                const splitActions = perm.action.includes(',') ? perm.action.split(',') : perm.action;
                return this.props.RolesContext.canDoAction(splitActions, perm.component_code, perm.code);
            })
        }else if(report.component_permission_rule === 'ONE_OF'){
            // if component_permission_rule is ONE_OF then we only need one of the permissions to view the report
            return report.component_permissions.some((perm) => {
                //action, componentCode, componentPermissionCode
                const splitActions = perm.action.includes(',') ? perm.action.split(',') : perm.action;
                return this.props.RolesContext.canDoAction(splitActions, perm.component_code, perm.code);
            })
        }
    }

    getReportsList = ({ skipSummary = true } = {}) => {
        return this.getReports()
            .then((reports) => {
                const canSeeRoute = (report) => this._hasAccessToReport(report) && !report.hidden && parseJSON(report.parameter)?.route && report.active == 1;

                return reports.reduce((keptReports, report) => {
                    const canKeep = canSeeRoute(report) && ((skipSummary && !report.summary_report) || !skipSummary);

                    if(canKeep){
                        const theReport = { ...report };

                        if(theReport.detailed_report){
                            const detailedReport = reports.find((report) => report.report_id === theReport.detailed_report.report_id);

                            if(detailedReport){
                                theReport.detailedRoute = parseJSON(detailedReport.parameter)?.route;
                            }
                        }

                        return [ ...keptReports, theReport ];
                    }

                    return keptReports;
                }, customReports || []);

                // return reports
                //     .filter((report) => {
                //         if(skipSummary)
                //             return this._hasAccessToReport(report) && !report.hidden && parseJSON(report.parameter)?.route && report.active == 1 && !report.summary_report;
                //         return this._hasAccessToReport(report) && !report.hidden && parseJSON(report.parameter)?.route && report.active == 1
                //     })
                //     .concat(customReports)
            })
    }

    setCurrentReport = (report) => {
        return new Promise((resolve) => {
            this.setState((prev) => ({ ...prev, currentReport: report }), resolve)
        })
    }

    emptyCurrentReport = () => { this.setState((prev) => ({ ...prev, currentReport: {} })) }

    setAllReports = (data) => {
        return new Promise((resolve) => {
            this.setState((prev) => ({ ...prev, allReports: data }), resolve)
        })
    }

    setCustomFormId = (id) => { this.setState((prev) => ({ ...prev, customFormId: id })) }

    /**
    * @description state that indicates whether or not we are currently generating reports, affects the refresh timing of notifications (makes it shorter)
    * @param {array} pendingReports reports we are waiting to be generated
    */
    setIsGenerating = (isGenerating) => { this.setState(() => ({ isGenerating: isGenerating })) };

    /**
    * @description reports we're waiting on, set in PendingReportsToast for now, the set should probably be moved
    * @param {array} pendingReports reports we are waiting to be generated
    */
    setPendingReports = (pendingReports) => { this.setState(() => ({ pendingReports: pendingReports })) };

    /**
     * [GET] Gets information for a specific report from a uuid
     * @param {string} queryParams
     * @returns {Promise.<Object>} A report
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/45b76e314571205a6aebe7b04f281b0e |documentation}
     */
    getSpecificReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/reports/${queryParams.report_id}`,
            query: {
                custom_form_id: queryParams.custom_form_id,
                custom_report_id: queryParams.custom_report_id,
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.reports[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * [GET] Gets a report of all registrations matching the inputted parameters.
     * @param {string} queryParams
     * @returns {Promise.<Object>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/45b76e314571205a6aebe7b04f281b0e |documentation}
     */
    getReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/reports/registrations`,
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.report_results;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get injury report
     * @param {string} queryParams
     * @returns {Promise.<string>} Returns a string of the download link
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/45b76e314571205a6aebe7b04f281b0e |documentation}
     */

    getInjuryReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/reports/injuries`,
            query: {
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.report_results;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Gets a report of all registrations matching the inputted parameters.
     * @param {string} queryParams
     * @returns {Promise.<array>} report_results
     * @see {@link https://api.id.dev.spordle.dev/documentations/#/Reports/a7298ff508063dbb4701218c74da19aa|String}
     */
    getRegistrationCountReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/reports/registration-count`,
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.report_results
                }
                throw response.data.errors[0];
            }, serverError)
    }
    /**
     * [GET] Gets a report branch summary
     * @param {string} queryParams
     * @returns {Promise.<string>} Returns a string of the download link
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/18c03a860a1f3e8e708cbf35aba6f2bd|documentation}
     */

    getBranchSummaryReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/reports/organisations/${this.props.OrganizationContext.organisation_id}/registrations/summary`,
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.report_results;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Gets a report of all registrations matching the inputted parameters.
     * @param {string} queryParams
     * @returns {Promise.<object>} Returns waiver reports
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/a74b99ef61f5b63c60155b0f397639b6|documentation}
     */

    getWaiversReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/reports/waivers`,
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.report_results;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Gets a report of all registrations matching the inputted parameters.
     * @param {string} organisation_id
     * @param {string} queryParams
     * @returns {Promise.<string>} Returns a string of the download link
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/af75d6884340aeca9ccdb1172d280d97|documentation}
     */

    getMultiBranchReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/reports/organisations/${this.props.OrganizationContext.organisation_id}/registrations`,
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.report_results;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get all the custom form answers for all members responses
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/570fa2fcc9fbb8901716bb45a3dbebdd|documentation}

     */
    getCustomFormReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/custom-forms',
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.custom_form_answers;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get all the custom form answers for all members responses
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     */
    getCustomFormReportsFlat = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/custom-forms/flat',
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return { report_results: response.data.custom_form_answers, more_rows: response.data?.more_rows };
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Create a new custom report
     * @param {Object} params
     * @returns {Promise.<string>} The newly created `custom_report_id`
     * @see https://api.id.dev.spordle.dev/documentations/index.php#/Custom%20Reports/0ce676269a8951bd56197d12f98d2550
     */
    createCustomReport = (params = {}) => {
        const data = new URLSearchParams();
        jsObjectToApi(params, data, { skipEmptyString: true, skipNull: true });
        return API_SPORDLE.post('/custom-reports', data)
            .then((response) => {
                if(response.data.status){
                    return response.data.custom_report_id;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Update a custom report
     * @param {string} customReportId
     * @param {Object} params
     * @returns {Promise}
     * @see https://api.id.dev.spordle.dev/documentations/index.php#/Custom%20Reports/4a9730e9d924357570ef5960462c2666
     */
    updateCustomReport = (customReportId, params = {}) => {
        const data = new URLSearchParams();
        jsObjectToApi(params, data, { skipEmptyString: true, skipNull: true });
        return API_SPORDLE.put(`/custom-reports/${customReportId}`, data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Delete a new custom report
     * @param {string} customReportId
     * @returns {Promise}
     * @see https://api.id.dev.spordle.dev/documentations/index.php#/Custom%20Reports/71540c295cb9995f4787b802a15b68b1
     */
    deleteCustomReport = (customReportId) => {
        return API_SPORDLE.delete(`/custom-reports/${customReportId}`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/263848d7a45fd122d391bed60bc92076|documentation}
     */
    getCRCReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/member-crc',
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }


    /**
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/263848d7a45fd122d391bed60bc92076|documentation}
     */
    getRefundsReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/refunds',
            query: {
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    getTransactionsReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/transactions',
            query: {
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
                ...queryParams,
                lang: this.props.I18nContext.getGenericLocale(),
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }


    /**
     * Get deficiency report
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/48a7f20de8a87bbf404e75d740aaeb87|documentation}
     */
    getDeficiencyReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/deficiency',
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get all the custom form answers for all members responses
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/48a7f20de8a87bbf404e75d740aaeb87|documentation}
     */
    getQualificationReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/qualifications',
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return { report_results: response.data.report_results, more_rows: response.data?.more_rows };
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get suspensions report
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/23f06b30f50a7e416b8d2cab8494fe65}
     */
    getSuspensionsReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/suspensions',
            query: {
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get membership fees summary report
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/86b9d6158b6cd81e253285676dc0734e}
     */
    getMembershipFeesSummaryReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/membership-fees/summary',
            query: {
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get suspensions report
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/23f06b30f50a7e416b8d2cab8494fe65}
     */
    getAppealsReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/member-appeals',
            query: {
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }


    /**
     * Get all the custom form answers for all members responses
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/48a7f20de8a87bbf404e75d740aaeb87|documentation}
     */
    getGameIncidentReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/game-incidents',
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get all the custom form answers for all members responses
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/48a7f20de8a87bbf404e75d740aaeb87|documentation}
     */
    getTeamListReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/team-list',
            query: {
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * [GET] Get team list summary
     * @param {string} queryParams
     * @returns {Promise.<string>} Returns a string of the download link
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/18c03a860a1f3e8e708cbf35aba6f2bd|documentation}
     */

    getTeamListSummaryReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/reports/team-list/summary`,
            query: {
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.report_results;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a report for players that are not on a team roster
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/48a7f20de8a87bbf404e75d740aaeb87|documentation}
     */
    getNotOnTeamReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/not-on-teams',
            query: {
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get a report for players that were previously on a team roster
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/48a7f20de8a87bbf404e75d740aaeb87|documentation}
     */
    getPreviouslyOnRosterReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/previously-rostered',
            query: {
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get a report for players that are not on a team roster
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/48a7f20de8a87bbf404e75d740aaeb87|documentation}
     */
    getRosterReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/member-positions',
            query: {
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return { report_results: response.data.report_results, more_rows: response.data?.more_rows }
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get all the member credits for the report
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/64ca9ed5f80c429d655f76d842372569|documentation}
     */
    getCreditReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/member-credits',
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get all the member credits for the report
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/64ca9ed5f80c429d655f76d842372569|documentation}
     */
    getOutstandingBalanceReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/outstanding-balances',
            query: {
                organisation_id: this.props.OrganizationContext.organisation_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get all the clinic attendees and their relevant information for the clinic attendees report
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/33918152bb4f0ae362403ddb23c562f3|documentation}
     */
    getClinicAttendeesReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/clinic-attendees',
            query: {
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                language_code: this.props.I18nContext.getGenericLocale(),
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return { report_results: response.data.report_results, more_rows: response.data?.more_rows };
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get all the discrimination reports between two dates
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/33918152bb4f0ae362403ddb23c562f3|documentation}
     */
    getDiscriminationReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/discrimination',
            query: {
                organisation_id: this.props.OrganizationContext?.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get all the discrimination reports between two dates
     * @param {string} queryParams
     * @returns {Promise.<Array>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Reports/33918152bb4f0ae362403ddb23c562f3|documentation}
     */
    getDoubleCardingReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/double-carding',
            query: {
                organisation_id: this.props.OrganizationContext?.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                all_sub_organisations: queryParams.all_sub_organisations,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get all the clinic attendees and their relevant information for the clinic attendees report
     * @param {string} [organisation_id] org id
     * @param {string} [period_id] period in which the report searches in
     * @param {string} [transfer_type] type of transfer
     * @param {string} [transfer_status] status of transfer
     * @param {string} [transfer_reason_type_id]  id of the transfer reason type
     * @returns {Promise.<Array>}
     */
    getTransfersReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/transfers',
            query: {
                organisation_id: this.props.OrganizationContext?.organisation_id,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_results;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * Get all the clinic attendees and their relevant information for the clinic attendees report
     * @param {string} [organisation_id] org id
     * @param {string} [period_id] period id
     * @param {string} [registration_date_range] registration date range, comma seperated
     * @param {string} [gender] gender: MALE, FEMALE, PREFER_NOT_TO_SAY
     * @param {string} [position_group_id] mulitple, comma seperated uuids (string)
     * @param {string} [birthdate] birthdate range, comma seperated
     * @param {string} [specific_date] single date
     * @param {string} [with_team] with_tea: YES, NO, ALL
     * @param {string} [division_id] division_id (uuid)
     * @returns {Promise.<Array>}
     */
    getMembershipFeesReport = (queryParams = {}, hasMoreRows = false) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/membership-fees',
            query: {
                organisation_id: queryParams.organisation_id || this.props.OrganizationContext?.organisation_id,
                period_id: queryParams.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                if(hasMoreRows){
                    return { report_results: response.data.report_results, more_rows: response.data?.more_rows }
                }
                return { report_results: response.data.report_results };

            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * POST - Create an exportable/downloadable report
     * @param {string} organisation_id ID of the Organization
     * @param {string} action READ, EXPORT
     * @param {string} output Required if action == EXPORT: EXCEL, PDF
     * @param {string} language_code language code (example: 'en')
     * @param {string} report_id ID of the report type
     * @param {string} requested_by identity ID of the requester
     * @param {string} request_parameter the filters on the reports sent to the API
     * @returns {Promise}
    */
    getReportEngineData = (queryParams = {}) => { // Often need to create ressource for curretly selected Org & Period
        const params = new URLSearchParams({
            organisation_id: queryParams.organisation_id ? queryParams.organisation_id : this.props.OrganizationContext.organisation_id,
            action: queryParams.action ? queryParams.action : "READ",
            output: queryParams.output ? queryParams.output : '',
            language_code: this.props.I18nContext.getGenericLocale(),
            report_id: queryParams.report_id ? queryParams.report_id : '',
            requested_by: queryParams.requested_by ? queryParams.requested_by : this.props.IdentityRolesContext.identity?.identity_id,
            request_parameter: queryParams.request_parameter ? queryParams.request_parameter : '',
            total_count: queryParams.total_count ? queryParams.total_count : 1000,
            ...queryParams,
        })

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/reports/requests` }), params)
            .then((response) => {
                if(response.data.status){
                    return { report_results: response.data.report_results, more_rows: response.data.more_rows };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * POST - Create an exportable/downloadable report
     * @param {string} organizationId ID of the Organization
     * @param {number} total_count Download link will only be returned if the total_count is lower than the report's threshold
     * @param {string} language_code language code (example: 'en')
     * @param {string} report_id ID of the report type
     * @param {string} requested_by identity ID of the requester
     * @param {string} request_parameter the filters on the reports sent to the API
     * @returns {Promise}
    */
    createExportableReport = (queryParams = {}, outputPDF = false) => { // Often need to create ressource for curretly selected Org & Period
        const params = new URLSearchParams({
            action: 'EXPORT',
            output: outputPDF ? 'PDF' : 'EXCEL',
            organisation_id: this.props.OrganizationContext.organisation_id,
            ...queryParams,
        })

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/reports/requests` }), params)
            .then((response) => {
                if(response.data.status){
                    if(!this.state.isGenerating){
                        this.setState({ isGenerating: true });
                        fire({
                            skipInfoTranslate: true,
                            info: PendingReportToast,
                            permanent: true,
                            msg: 'reports.exports.generating',
                            icon: <Spinner className='mr-2' size='sm' color='primary' />,
                            id: 'reportExportToast',
                        });
                    }
                    mutate('getPendingReports');
                    return response.data; // API usually returns the id of the create ressource
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET - Gets tam roster report pdf
     * @param {string} team_id
     * @param {string} division_id
     * @param {string} team_category_id
     * @param {string} lang
     * @returns {Promise}
    */
    getTeamRosterReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/team-rosters',
            query: {
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                mutate('getPendingReports');
                return response.data;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * GET - Gets travel permit report pdf
     * @param {string} lang
     * @returns {Promise}
    */
    getTravelPermitReport = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/travel-permits',
            query: {
                lang: this.props.I18nContext.getGenericLocale(),
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                mutate('getPendingReports');
                return response.data;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * GET - Gets list of kinds of reports
     * @param {string} identity_role_id ID of the identity role
     * @param {string} type report type REGISTRATION, FINANCIAL, MEMBER, TEAM
     * @returns {Promise}
    */
    getReports = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports',
            query: {
                identity_role_id: this.props.IdentityRolesContext.identity_role_id,
                ...queryParams,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.reports;
            }
            throw response.data.errors[0];
        }, serverError);
    }

    /**
     * GET[SPECIFIC] - Get an excel report download link
     * @param {string} report_request_id
     * @returns {Promise}
    */
    getReportDownload = (report_request_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/reports/requests/${report_request_id}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data; // May return object or array containing ressource
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET[ALL] - Get excel report objects by user (not the files themselves)
     * @param {string} requested_by user identity_role_id
     * @param {string} organisation_id user organisation_id
     * @returns {Promise.<Array>}
    */
    getExportsReportList = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/reports/requests',
            query: Object.assign({
                requested_by: this.props.IdentityRolesContext.identity?.identity_id,
                organisation_id: this.props.OrganizationContext?.organisation_id,
                ...queryParams,
            }),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.report_requests;
            }
            throw response.data.errors[0];
        }, serverError)
    }

    getFilterList = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/filters',
            query: { ...queryParams },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        })).then((response) => {
            if(response.data.status){
                return response.data.filters;
            }
            throw response.data.errors[0];
        }, serverError)

    }

    /**
     * PATCH - Partially update a report filter
     * @param {string} report_id
     * @param {string} report_filter_id user organisation_id
     * @param {object} values
     * @returns {Promise.<Array>}
    */
    partiallyUpdateReportFilter = (report_id, report_filter_id, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/reports/${report_id}/filters/${report_filter_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.filters
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * DELETE - Partially update a report filter
     * @param {string} report_id
     * @param {string} report_filter_id user organisation_id
     * @returns {Promise.<Array>}
    */
    deleteReportFilter = (report_id, report_filter_id, values) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/reports/${report_id}/filters/${report_filter_id}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.filters
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * POST - Creates a report filter
     * @param {string} report_id
     * @param {object} values
     * @returns {Promise.<Array>}
    */
    createReportFilter = (report_id, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/reports/${report_id}/filters` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.filters
                }
                throw response.data.errors[0];
            }, serverError)

    }

    /**
     * POST - Creates a filter
     * @param {object}
     * @returns {string} filter_id
    */
    createFilter = (values) => {
        const params = new URLSearchParams();
        for(const key in values){
            if(key.includes('i18n')){
                params.append('i18n[en][name]', values.i18n.en.name)
                params.append('i18n[fr][name]', values.i18n.fr.name)
            }else params.append(key, values[key])
        }
        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/filters` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.filter_id
                }
                throw response.data.errors[0];
            }, serverError)

    }

    /**
     * @description
     */
    getReportsByStatus = async() => {
        return this.getExportsReportList()
            .then((exportList) => ({
                pending: exportList.filter((e) => e.request_status === 'PENDING' || e.request_status === 'IN_PROGRESS'),
                completed: exportList.filter((e) => e.request_status === 'COMPLETED'),
                declined: exportList.filter((e) => e.request_status === 'DECLINED'),
                noData: exportList.filter((e) => e.request_status === 'NO_DATA_FOUND'),
            })).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    updateReportHeaders = (report_id, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/reports/${report_id}/headers` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            // Speading `this` is very important because it creates a new value object so it rerenders the Consumers
            // If we don't spead, react/javascript sees it as the same object thus, not rerendering the Consumers
            <ReportsContext.Provider value={{ ...this.state, ...this }}>
                {this.state.isGenerating && <HandleGeneratingReports />}
                {this.props.children}
            </ReportsContext.Provider>
        )
    }
}

/**
 * @description Handles the generation of reports as well as the toasts for downloading and waiting for a report
 */
const HandleGeneratingReports = () => {
    const reportsContext = useContext(ReportsContext);
    useSWR(
        'getPendingReports',
        () => reportsContext.state.isGenerating ? reportsContext.getReportsByStatus() : { pending: [], completed: [], declined: [], noData: [] },
        {
            refreshInterval: 5000, // (API refreshes every 5 seconds)
            onSuccess: ({ pending, completed, declined, noData }) => {


                // recentlyCompletedReports contains reports that were in the pending reports context state.
                // These are compared with the list of completed reports and if there are any that are the same,
                // we know they are done being generated and are ready to export, these reports that are ready to export are
                // then mapped over in the Promise.all, where we trigger a 'Download now" toast for each report that is ready to download.

                const recentlyCompletedReports = completed.filter((value) => reportsContext.state.pendingReports.some((o) => {
                    return (o.request_report_id === value.request_report_id);
                })) || [];
                const recentlyDeclinedReports = declined.filter((value) => reportsContext.state.pendingReports.some((o) => {
                    return (o.request_report_id === value.request_report_id);
                })) || [];
                const recentlyNoDataReports = noData.filter((value) => reportsContext.state.pendingReports.some((o) => {
                    return (o.request_report_id === value.request_report_id);
                })) || [];

                reportsContext.setPendingReports(pending);

                if(pending?.length === 0){
                    removeToast('reportExportToast');
                    reportsContext.setIsGenerating(false);
                }

                if(recentlyDeclinedReports?.length > 0){
                    fail({ msg: 'reports.exports.errors.declined', info: 'reports.exports.errors.declinedText', timeout: 15000 });
                }

                if(recentlyNoDataReports?.length > 0){
                    fail({ msg: 'reports.exports.errors.noData', info: 'reports.exports.errors.noDataText', timeout: 15000 });
                }

                if(recentlyCompletedReports?.length > 0){
                    Promise.all(
                        // change for only the ones that are returned
                        recentlyCompletedReports.map((pendingReport) => {
                            return reportsContext.getReportDownload(pendingReport.request_report_id)
                                .then((link) => {
                                    fire({
                                        msg: "reports.exports.generationSuccess",
                                        icon: <i className='mdi mdi-download font-22 text-primary' />,
                                        skipInfoTranslate: true,
                                        info: SuccessToastMessage({ link: link.download_link, id: pendingReport.request_report_id }),
                                        permanent: true,
                                        id: pendingReport.request_report_id,
                                    });
                                }).catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                    }
                                })
                        }),
                    ).then(() => {
                        if(pending?.length === 0){
                            reportsContext.setIsGenerating(false);
                            removeToast('reportExportToast');
                        }
                    }).catch((err) => {
                        if(!AxiosIsCancelled(err.message)){
                            console.error(err);
                        }
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={err.message} i18n={err.i18n} />,
                            skipInfoTranslate: true,
                        })
                    })
                }
            },
        },
    );

    return null;
}

export default withContexts(I18nContext, OrganizationContext, PeriodsContext, IdentityRolesContext, RolesContext)(ReportsContextProvider);
