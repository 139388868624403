export default{
    "task.mandatoryChangesRequests.title"       : "Changements de membres",
    "task.mandatoryChangesRequests.modal.title" : "Gérer les changements de membres",

    "task.mandatoryChangesRequests.row.request.requested_by" : "Créée par",
    "task.mandatoryChangesRequests.row.request.dateTime"     : "Date / Heure",

    "task.mandatoryChangesRequests.modal.cancel.btn" : "Annuler requête",

    "task.mandatoryChangesRequests.sidepanel.request"              : "Requête",
    "task.mandatoryChangesRequests.sidepanel.request.status"       : "Statut de requête",
    "task.mandatoryChangesRequests.sidepanel.request.requested_by" : "Créée par",
    "task.mandatoryChangesRequests.sidepanel.request.data"         : "Demande de changements",
    "task.mandatoryChangesRequests.sidepanel.number"               : "{count} requêtes sélectionnées",
    "task.mandatoryChangesRequests.sidepanel.members.number"       : "{count} membres sélectionnées",


    "task.mandatoryChangesRequests.manage.all"    : "Tout gérer",
    "task.mandatoryChangesRequests.filter.status" : "Filtrer par statut",

    "task.mandatoryChangesRequests.sidepanel.request.success" : "Mis à jour avec succès le statut des demandes de changement.",
    "task.mandatoryChangesRequests.sidepanel.request.multi"   : "Il n'y a plus de demandes de modification dans la file d'attente à approuver. ",
}
