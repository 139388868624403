import PropTypes from 'prop-types';
import ProfileHeaderImage from './ProfileHeaderImage';
import ProfileHeaderInfo from './ProfileHeaderInfo';
import { stringBuilder } from '@spordle/helpers';

const ProfileHeader = (props) => {
    const {
        tag: Tag,
        className,
        ...attributes
    } = props;

    return (
        <Tag {...attributes} className={stringBuilder('profileHeader', className)} />
    );
};

export default ProfileHeader;

ProfileHeader.Image = ProfileHeaderImage
ProfileHeader.Info = ProfileHeaderInfo

ProfileHeader.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
};
ProfileHeader.defaultProps = {
    tag: 'div',
};