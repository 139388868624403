import { useRef } from "react";

/**
 * @param {number} ms number of ms of the transition
 * @param {number} [skipFrames=5] frames to skip in the transition
 * @returns {[ triggerTransition, clearTransition ]}
 */
export function useSwipeTransition(ms, skipFrames = 5){
    const animation = useRef(null);
    const timeout = useRef(null);

    const clearTransition = () => {
        if(timeout.current){
            clearTimeout(timeout.current);
        }
        cancelAnimationFrame(animation.current);
    }

    /**
     * @param {number} from
     * @param {number} to
     * @param {(currentValue: number, isEnding: bool) => {}} callback
     */
    const triggerTransition = (from, to, callback) => {
        let start = null;
        let prev = null;
        const steps = (to - from) / ms;

        animation.current = requestAnimationFrame(function animate(timestamp){
            if(!start){
                start = timestamp;
            }

            if(prev !== timestamp){
                const elapsed = timestamp - start;
                const delta = steps * elapsed;

                if(elapsed < ms){
                    callback(to > from ? Math.min(from + delta, to) : Math.max(from + delta, to));
                    prev = timestamp;

                    timeout.current = setTimeout(() => {
                        requestAnimationFrame(animate);
                    }, skipFrames)
                }else{
                    callback(to, true);
                    clearTransition();
                }
            }

        });
    }

    return [ triggerTransition, clearTransition ];
}