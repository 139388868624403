import { I18nContext } from "../../../contexts/I18nContext";
import { displayI18n } from "../../../helpers/i18nHelper";
import { useRoutesSWR } from "../sidebar/components/useRoutesSWR";
import { Fragment, useContext } from "react";

const HeaderApiRoutes = ({ navGroup, label, ItemComponent }) => {
    const { getGenericLocale } = useContext(I18nContext);

    const { data, renderHeader, getRouteInfo } = useRoutesSWR(navGroup);

    if(!Array.isArray(data) || data.length === 0){
        return null;
    }

    return (
        <Fragment>
            {label}
            <div style={{ maxHeight: 300, overflowY: 'auto' }}>
                {data
                    .sort((a, b) => {
                        const nameA = displayI18n("name", a.i18n, a.name, getGenericLocale());
                        const nameB = displayI18n("name", b.i18n, b.name, getGenericLocale());

                        return new Intl.Collator(getGenericLocale(), { sensitivity: 'base' }).compare(nameA, nameB);
                    })
                    .map((route) => {
                        const customRender = renderHeader?.(route);
                        const itemProps = getRouteInfo?.(route);

                        if(customRender !== void 0){
                            return <Fragment key={itemProps?.key}>{customRender}</Fragment>;
                        }

                        if(itemProps){
                            return (
                                <ItemComponent
                                    key={itemProps.key}
                                    route={{
                                        ...itemProps.routeData,
                                        ...itemProps.headerData,
                                    }}
                                />
                            )
                        }

                        return null;
                    })}
            </div>
        </Fragment>
    );
}

export default HeaderApiRoutes;