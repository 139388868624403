import Translate from '@spordle/intl-elements';
import { stringBuilder } from '@spordle/helpers';
import { useContext } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { RolesContext } from '../../../contexts/RolesContext';
import useSWR from 'swr';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../../../helpers/i18nHelper';

const RolePicker = () => {
    const identityRolesContext = useContext(IdentityRolesContext);
    const rolesContext = useContext(RolesContext);
    const organizationContext = useContext(OrganizationContext);

    const { data, isValidating, error } = useSWR(
        organizationContext.organisation_id,
        () => rolesContext.getOrganizationRoles(organizationContext.organisation_id),
    )

    return (
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav className="text-purple">{identityRolesContext.isGod() ? <i className="mdi mdi-eye font-18" /> : <i className="mdi mdi-eye-off font-18" />}</DropdownToggle>
            <DropdownMenu right>
                <DropdownItem onClick={identityRolesContext.toggleGodMode}>
                    Power user view:{identityRolesContext.isGod() ? <i className='mdi mdi-check text-success ml-1' /> : <i className='mdi mdi-close text-danger ml-1' />}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem header>
                    Roles
                </DropdownItem>
                {isValidating ?
                    <DropdownItem className="text-center">
                        <Spinner color="primary" type="grow" size='sm' />
                    </DropdownItem>
                    : error ?
                        <DropdownItem>
                            <Translate id='misc.error' />
                        </DropdownItem>
                        :
                        <div style={{ maxHeight: "50vh", overflowY: 'auto' }}>
                            {data?.map((role) => (
                                <DropdownItem
                                    key={role.role_id} className={stringBuilder({ 'text-primary': role.role_id === rolesContext.role_id })}
                                    disabled={role.role_id === rolesContext.role_id}
                                    onClick={() => {
                                        if(role.role_id !== rolesContext.role_id){
                                            rolesContext.getRolesPermissions(role.role_id)
                                                .then(rolesContext.setCurrentRole)
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                        }
                                    }}
                                >
                                    {role.role_id === rolesContext.role_id && <i className='mdi mdi-check mr-1' />}{role.title}
                                </DropdownItem>
                            ))}
                        </div>
                }
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default RolePicker;