import { useState, useContext } from "react";
import {
    Button, Card
} from "reactstrap";
import SpordleTableProvider, { SpordleTableView, useSpordleTable } from '@spordle/datatables';
import Translate from "@spordle/intl-elements";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import withContexts from "../../../../../../helpers/withContexts";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import CardSectionTitle from "../../../../../../components/CardSectionTitle";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import SpordlePanelTable from "../../../../../../components/sidePanel/SpordlePanel";
import SidePanelOrganizationDocumentExpirations from "./SidePanelOrganizationDocumentExpirations";
import EmptyLayout from "../../../../../../components/table/EmptyLayout";
import OrganizationDocumentExpirationsAdd from "./OrganizationDocumentExpirationsAdd";
import { DocumentTypesContext } from "../../../../../../contexts/DocumentTypesContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";

const OrganizationDocumentExpirations = () => {
    const [ modalAdd, setModalAdd ] = useState(false);
    const toggleModalAdd = () => setModalAdd(!modalAdd);
    const i18nContext = useContext(I18nContext)
    const documentTypeContext = useContext(DocumentTypesContext)

    const documentTypeSpordleTable = useSpordleTable();

    return (
        <Card body className="card-shadow">
            <CardSectionTitle title='settings.documentExpirations.title' />
            <SpordlePanelTable
                allowOutsideClick
                sidePanel={(props) => <SidePanelOrganizationDocumentExpirations {...props} />}
                dataIndex='document_type_expiration_id'
                table={(panelProps) => {
                    return (
                        <SpordleTableProvider
                            id='OrganizationDocumentTypes'
                            tableHover clickable striped
                            bordered
                            ref={panelProps.spordleTableRef}
                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                            dataIndex='document_type_expiration_id'
                            searchKeys={[
                                `document_type.i18n.${i18nContext.getGenericLocale()}.name`,
                                'document_type.name',
                                `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                'organisation.organisation_name',
                            ]}
                            columns={[
                                {
                                    label: <Translate id='settings.documentTypeExpiration.document_types.row' />,
                                    key: "document_type",
                                    sortKey: `document_type.i18n.${i18nContext.getGenericLocale()}.name`,
                                    mobile: true,
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='settings.documentTypeExpiration.expiration_duration.row' />,
                                    key: "expiration_duration",
                                    mobile: true,
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='settings.documentTypeExpiration.min_age.row' />,
                                    key: "min_age",
                                    mobile: true,
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='settings.documentTypeExpiration.max_age.row' />,
                                    key: "max_age",
                                    mobile: true,
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='settings.documentTypeExpiration.organization.row' />,
                                    key: "organization",
                                    mobile: true,
                                    sortable: true,
                                    fallbackSortKey: "organisation.organisation_name",
                                    sortKey: `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                },
                                {
                                    label: <Translate id='settings.documentTypeExpiration.delete_after_expiration.row' />,
                                    key: "delete_after_expiration",
                                    sortable: true,
                                    mobile: true,
                                },
                            ]}
                            onColumnClick={(e, documentType) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        panelProps.onSingleSelectChange(documentType);
                                        break;
                                }
                            }}
                            desktopWhen='md'
                            emptyLayout={<EmptyLayout translateTitle title='settings.documentTypes.empty' />}
                            renderRow={(columnKey, row, spordleTable) => {
                                switch (columnKey){
                                    case 'document_type':
                                        return (<DisplayI18n field='name' defaultValue={row.document_type.name} i18n={row.document_type.i18n} />)
                                    case 'expiration_duration':
                                        return (<>{row.expiration_duration} <Translate id={row.expiration_duration == 1 ? 'settings.documentTypeExpiration.expiration_duration.row.month' : 'settings.documentTypeExpiration.expiration_duration.row.months'} /></>)
                                    case 'organization':
                                        return (<DisplayI18n field='name' defaultValue={row.organisation.organisation_name} i18n={row.organisation.i18n} />)
                                    case 'delete_after_expiration':
                                        return (
                                            <div className={spordleTable.isMobile() ? '' : 'text-center'}>
                                                {row.delete_after_expiration == '1' ?
                                                    <i className="text-primary mdi mdi-check" />
                                                    :
                                                    <i className="text-danger mdi mdi-close" />
                                                }
                                            </div>
                                        )
                                    default:
                                        break;
                                }
                            }}
                            rowIsHighlighted={(documentType) => !!documentType.checked}
                            loadData={(from, filterData, spordleTable) => {
                                switch (from){
                                    case 'REFRESH':
                                        spordleTable.setLoading();
                                    case 'CDM':
                                        return documentTypeContext.getDocumentTypeExpirations();
                                    default:
                                        break;
                                }
                            }}
                        >
                            <div className='d-flex justify-content-between mb-2'>
                                <SpordleTableProvider.SearchInput />
                                <div className='d-flex ml-auto text-right'>
                                    <SpordleTableProvider.Refresh />
                                    <OrganizationDocumentExpirationsAdd isOpen={modalAdd} toggle={toggleModalAdd} documentTypeSpordleTable={documentTypeSpordleTable} />
                                    <CanDoAction action='ADD' componentCode='settings' componentPermissionCode='document_types'>
                                        <Button className='ml-2' color='primary' onClick={toggleModalAdd}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                    </CanDoAction>
                                </div>
                            </div>
                            <SpordleTableView />
                        </SpordleTableProvider>
                    )
                }}
            />

        </Card>
    )
}

export default withContexts(OrganizationContext)(OrganizationDocumentExpirations);