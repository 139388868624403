import { FormikDateTime, FormikInputText } from '@spordle/formik-elements';
import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Collapse, FormGroup, Label, ModalBody, Row } from 'reactstrap';
import { string, mixed, object } from 'yup';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import Required from '../../../../../../components/formik/Required';
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../../components/UserImg';
import { CartsContext } from '../../../../../../contexts/CartsContext';
import { ClinicsContext } from '../../../../../../contexts/ClinicsContext';
import { IdentityRolesContext } from '../../../../../../contexts/IdentityRolesContext';
// contexts
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import withContexts from '../../../../../../helpers/withContexts';
import ClinicCategory from '../../../../ClinicCategory';
import { fail } from "@spordle/toasts";
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { MerchantAccountContext } from '../../../../../../contexts/MerchantAccountContext';

class ClinicProfileRegistrationAdd1 extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            members: null,
            errors: {},

            prerequisitesIsOpen: false,

            firstSession: null,
        };

        this.hasCreditCard = this.props.MerchantAccountContext.merchantAccounts ? this.props.MerchantAccountContext.merchantAccounts?.some((m) => m.active == 1) : false
    }

    componentDidMount(){
        this.props.CartsContext.clearCarts().catch(console.error);
        this.setFirstSession()
    }

    setFirstSession = () => {
        this.setState(() => ({ firstSession: this.props.ClinicsContext.currentClinic.clinic_sessions.sort((a, b) => {
            if(a.start_date > b.start_date)
                return 1;
            else if(a.start_date < b.start_date)
                return -1;
            return 0;
        })[0] }))
    }

    togglePrerequisites = () => {
        this.setState((prevState) => ({ prerequisitesIsOpen: !prevState.prerequisitesIsOpen }))
    }

    selectMember = (member) => {
        this.props.setModalLoading(true)
        this.props.CartsContext.createCart({ item: {
            item_id: this.props.ClinicsContext.currentClinic.clinic_id,
            type: "CLINIC",
            member_id: member.member_id,
        } })
            .then(async(shopping_cart_id) => {
                const cart = await this.props.CartsContext.getCart(shopping_cart_id)
                this.props.setMember(member);
                this.props.setShoppingCartId(shopping_cart_id, cart.cart_detail[0].waiting_list == 1 ? 'waitingListInfo' : 2);
                this.props.setModalLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    const _errors = this.state.errors;
                    _errors[member.member_id] = error;
                    this.setState(() => ({ errors: _errors }))
                    this.props.setModalLoading(false);
                }
            })
    }

    render(){
        return (
            <ModalBody>
                <div className='font-bold'><DisplayI18n field='name' defaultValue={this.props.ClinicsContext.currentClinic.name} i18n={this.props.ClinicsContext.currentClinic.i18n} /></div>
                <div className='small mb-3'><ClinicCategory.Builder qualification={this.props.ClinicsContext.currentClinic.qualification} /></div>


                <div className="mb-2">
                    <button type='button' className='reset-btn' onClick={this.togglePrerequisites}>
                        <Translate id='clinics.profile.attendees.addModal.prerequisitesRequired' /><i className={`ml-1 ti-angle-right small d-inline-block transition${this.state.prerequisitesIsOpen ? ' rotate-90' : ''}`} />
                    </button>
                    <Collapse isOpen={this.state.prerequisitesIsOpen}>
                        <ul className="small mb-0">
                            {/* QUALIFICATION PREREQUISITES */}
                            <li><Translate id='clinics.profile.attendees.addModal.prerequisites.qualification' /></li>
                            <ul>
                                {/* no data to test with for now */}
                                {/* {false && this.props.ClinicsContext.currentClinic.qualification.prerequisites && this.props.ClinicsContext.currentClinic.qualification.prerequisites.length > 0 ?
                                    <li />
                                    :
                                    <li><Translate id='clinics.profile.attendees.addModal.prerequisites.none' /></li>
                                } */}
                                <li><Translate id='clinics.profile.attendees.addModal.prerequisites.none' /></li>
                            </ul>

                            {/* ORGANIZATION PREREQUISITES */}
                            <li>
                                <Translate id='clinics.profile.attendees.addModal.prerequisites.organization' />
                                {this.props.ClinicsContext.currentClinic.qualification && this.props.ClinicsContext.currentClinic.prerequisites && this.props.ClinicsContext.currentClinic.prerequisites.length > 0 &&
                                    <span> - <Translate id={'clinics.profile.attendees.addModal.prerequisites.restrictions.' + this.props.ClinicsContext.currentClinic.prerequisites[0].restriction_condition} /></span>
                                }
                            </li>
                            <ul>
                                {this.props.ClinicsContext.currentClinic.prerequisites && this.props.ClinicsContext.currentClinic.prerequisites.length > 0 ?
                                    this.props.ClinicsContext.currentClinic.prerequisites.reduce((newArray, prerequisite) => {
                                        if(prerequisite.requirements && prerequisite.requirements.length > 0){
                                            prerequisite.requirements.forEach((requirement) => {
                                                newArray.push(
                                                    <li key={requirement.qualification_id}>
                                                        <DisplayI18n field='name' defaultValue={requirement.qualification_category.name} i18n={requirement.qualification_category.i18n} />
                                                        <span className='mx-1'>-</span>
                                                        <DisplayI18n field='name' defaultValue={requirement.name} i18n={requirement.i18n} />
                                                        {requirement.qualification_level &&
                                                            <>
                                                                <span className='mx-1'>-</span>
                                                                <DisplayI18n field='name' defaultValue={requirement.qualification_level.name} i18n={requirement.qualification_level.i18n} />
                                                            </>
                                                        }
                                                    </li>,
                                                )
                                            })
                                        }
                                        return newArray
                                    }, [])
                                    :
                                    <li><Translate id='clinics.profile.attendees.addModal.prerequisites.none' /></li>
                                }
                            </ul>
                        </ul>
                    </Collapse>
                </div>

                {this.state.firstSession &&
                    <>
                        <div className="mb-2">
                            <i className='mdi mdi-calendar mr-2' />
                            <span className='text-capitalize'><DateFormat value={this.state.firstSession.start_date} format={'dddd ' + DateFormat.formats.longMonthDayYear} /></span>
                        </div>
                        <div className="mb-2">
                            <i className='mdi mdi-clock-outline mr-2' />
                            {this.state.firstSession.session_format === 'ONSITE' || this.state.firstSession.session_format === 'ONLINE_LIVE' ?
                                <><DateFormat value={this.state.firstSession.start_date} format={'h:mm a'} /> - <DateFormat value={this.state.firstSession.end_date} format={'h:mm a'} /></>
                                :
                                <><Translate id='clinics.profile.attendees.addModal.until' /> <DateFormat value={this.state.firstSession.end_date} format={'dddd ' + DateFormat.formats.longMonthDayYear} /></>
                            }
                        </div>
                        <div className="mb-2">
                            <i className='mdi mdi-tag-outline mr-2' />
                            <CurrencyFormat value={this.props.ClinicsContext.currentClinic.total_amount / 100} />
                            {this.props.ClinicsContext.currentClinic.with_tax == '1' &&
                                <sup className="text-muted ml-1">(+<Translate id='clinics.profile.attendees.addModal.tax' />)</sup>
                            }
                            {this.props.OrganizationContext.settings.fee_for_clinic?.value == 'CLIENT' && this.hasCreditCard &&
                                <sup className="text-muted ml-1">(+<Translate id='clinics.profile.attendees.addModal.fees' />)</sup>
                            }
                            <span className="small text-muted ml-2"><Translate id='clinics.profile.attendees.addModal.priceOneParticipant' /></span>
                        </div>
                    </>
                }

                <div className='border-top my-3' />

                <Formik
                    initialValues={this.props.values || {
                        firstName: '',
                        lastName: '',
                        dateOfBirth: '',
                        memberNumber: '',
                    }}
                    validationSchema={object().shape({
                        firstName: string().when('memberNumber', {
                            is: undefined,
                            then: string().required(<Translate id='form.validation.firstName.required' />),
                        }),
                        lastName: string().when('memberNumber', {
                            is: undefined,
                            then: string().required(<Translate id='form.validation.lastName.required' />),
                        }),
                        dateOfBirth: mixed().when('memberNumber', {
                            is: undefined,
                            then: mixed().required(<Translate id='form.validation.dateOfBirth.required' />).test({
                                name: 'dateOfBirthFormat',
                                message: <Translate id='form.validation.dateOfBirth.valid' />,
                                test: (dateOfBirth) => {
                                    return moment.isMoment(dateOfBirth)
                                },
                            }),
                        }),
                        memberNumber: string(),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        this.props.setModalLoading(true)

                        const searchParams = {
                            organisation_id: this.props.IdentityRolesContext.federation.organisation_id,
                            all: '1',
                            first_name: values.firstName,
                            last_name: values.lastName,
                            birthdate: values.dateOfBirth ? (moment.isMoment(values.dateOfBirth) ? values.dateOfBirth.format('YYYY-MM-DD') : moment(values.dateOfBirth).format('YYYY-MM-DD')) : '',
                        }

                        if(values.memberNumber){
                            searchParams.unique_identifier = values.memberNumber
                        }else{
                            searchParams.first_name = values.firstName;
                            searchParams.last_name = values.lastName;
                            searchParams.birthdate = moment.isMoment(values.dateOfBirth) ? values.dateOfBirth.format('YYYY-MM-DD') : moment(values.dateOfBirth).format('YYYY-MM-DD');
                        }

                        // Search through system members
                        this.props.MembersContext.getMembers(searchParams)
                            .then((members) => {
                                if(Array.isArray(members)){
                                    this.setState(() => ({ members: members, errors: {} }))
                                    setSubmitting(false)
                                    this.props.setModalLoading(false)
                                }
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                                this.setState(() => ({ errors: {} }))
                                setSubmitting(false)
                                this.props.setModalLoading(false)
                            })
                    }}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <Row>
                                    <Col md='6'>
                                        <FormGroup>
                                            <Label for='firstName' className='text-muted'><Translate id='form.fields.firstName' /> <Required /></Label>
                                            <FormikInputText id='firstName' name='firstName' disabled={!!formik.values.memberNumber} trim />
                                        </FormGroup>
                                    </Col>
                                    <Col md='6'>
                                        <FormGroup>
                                            <Label for='lastName' className='text-muted'><Translate id='form.fields.lastName' /> <Required /></Label>
                                            <FormikInputText id='lastName' name='lastName' disabled={!!formik.values.memberNumber} trim />
                                        </FormGroup>
                                    </Col>
                                    <Col md='6'>
                                        <FormGroup>
                                            <Label for='dateOfBirth' className='text-muted'><Translate id='form.fields.dateOfBirth' /> <Required /></Label>
                                            <FormikDateTime
                                                id='dateOfBirth'
                                                name='dateOfBirth'
                                                timeFormat={false}
                                                isValidDate={(current) => moment(current).isBefore(new Date())}
                                                disabled={!!formik.values.memberNumber}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className='d-flex align-items-center mb-3'>
                                    <div className='border-top flex-grow-1' />
                                    <div className='px-3 font-medium small'><Translate id='clinics.profile.attendees.addModal.memberSelect.or' /></div>
                                    <div className='border-top flex-grow-1' />
                                </div>
                                <FormGroup>
                                    <Label for='memberNumber' className='text-muted'><Translate id='clinics.profile.attendees.addModal.memberSelect.memberNumber' /></Label>
                                    <FormikInputText id='memberNumber' name='memberNumber' trim />
                                    <div className='small'><i className='text-primary mdi mdi-information-outline' /> <Translate id='clinics.profile.attendees.addModal.memberSelect.memberNumber.hint' /></div>
                                </FormGroup>
                                <div className='text-right'>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.search' /></Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>

                <Collapse className="mt-3" isOpen={this.state.members && Array.isArray(this.state.members)}>
                    {(Array.isArray(this.state.members) && this.state.members?.length) > 0 ?
                        <div className="border-top">
                            <div className="text-muted my-2"><Translate id='misc.results' /></div>
                            <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }}>
                                {this.state.members.map((member) => (
                                    <div key={member.member_id}>
                                        <UserDisplay card className={stringBuilder('w-100 mb-2', { 'border-danger': this.state.errors[member.member_id] })}>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                                    src={member.picture?.full_path}
                                                    filePos={member.picture?.file_position}
                                                    alt={member.first_name + ' ' + member.last_name}
                                                    width="50"
                                                    height="50"
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>
                                                    {member.first_name} {member.last_name}
                                                    {(member.age !== undefined && member.age !== null) && <span className='text-muted'> (<Translate id='misc.yearsOld' values={{ age: member.age }} />)</span>}
                                                </UserDisplay.Title>
                                                <UserDisplay.Subtitle>
                                                    <DisplayI18n
                                                        field='name'
                                                        defaultValue={member.organisation.organisation_name}
                                                        i18n={member.organisation.i18n}
                                                    />
                                                </UserDisplay.Subtitle>
                                                {member.unique_identifier &&
                                                    <UserDisplay.Subtitle>
                                                        <Link to={`/members/profile/${member.member_id}/history`}>
                                                            #{member.unique_identifier}
                                                            <i className='mdi mdi-chevron-right' />
                                                        </Link>
                                                    </UserDisplay.Subtitle>
                                                }
                                                {this.state.errors[member.member_id]?.origin?.includes('member_need_background_check') &&
                                                    <Link className='small' to={`/members/profile/${member.member_id}/history`}><Translate id='clinics.profile.attendees.addModal.warnings.link.member_need_crc' /><i className='mdi mdi-chevron-right' /></Link>
                                                }
                                            </UserDisplay.Container>
                                            <UserDisplay.Container className="ml-auto">
                                                <Button color='primary' type='button' className='mr-2 ml-auto' onClick={() => this.selectMember(member)} disabled={!!this.state.errors[member.member_id]}><Translate id='clinics.profile.attendees.addModal.memberSelect.select' /></Button>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                        <Collapse isOpen={this.state.errors[member.member_id]} mountOnEnter unmountOnExit>
                                            <Alert color='danger' className='mb-0'>
                                                <>
                                                    <div className='text-danger small'><DisplayI18n field='message' defaultValue={this.state.errors[member.member_id]?.message} i18n={this.state.errors[member.member_id]?.i18n} /></div>
                                                    {/* {this.state.errors[member.member_id]?.origin?.split(',')?.length > 0 &&
                                                        this.state.errors[member.member_id]?.origin?.split(',')?.map((origin) => (
                                                            <div key={origin} className='text-danger small'><Translate id={'clinics.profile.attendees.addModal.warnings.' + origin} defaultMessageId='misc.error' /></div>
                                                        ))
                                                    } */}
                                                </>
                                            </Alert>
                                        </Collapse>
                                    </div>
                                ))}
                            </PerfectScrollbar>
                        </div>
                        : (Array.isArray(this.state.members) && this.state.members?.length === 0) &&
                        <div className="border-top">
                            <div className="text-muted my-2"><Translate id='misc.results' /></div>
                            <div className='bg-light p-3 text-center'>
                                <Translate id='clinics.profile.attendees.addModal.memberSelect.noData' />
                            </div>
                        </div>
                    }
                </Collapse>
            </ModalBody>
        )
    }
}

export default withContexts(MembersContext, ClinicsContext, IdentityRolesContext, CartsContext, OrganizationContext, MerchantAccountContext)(ClinicProfileRegistrationAdd1)