import ConfigurationSettingsViewMemberType from "./ConfigurationSettingsViewMemberType";
import ConfigurationSettingsViewPosition from "./ConfigurationSettingsViewPosition";
import ConfigurationSettingsViewPosGroup from "./ConfigurationSettingsViewPosGroup";
import HasAccessTo from "../../../../../../components/permissions/HasAccessTo";

const ConfigurationSettingsView = () => {
    return (
        <>
            <ConfigurationSettingsViewMemberType />
            <HasAccessTo componentCode="teams" componentPermissionCode="team_positions">
                <ConfigurationSettingsViewPosition />
            </HasAccessTo>
            <HasAccessTo componentCode="teams" componentPermissionCode="team_position_groups">
                <ConfigurationSettingsViewPosGroup />
            </HasAccessTo>
        </>
    );
}

export default ConfigurationSettingsView;