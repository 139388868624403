import React from "react";
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import TravelPermitSidepanelGeneral from "./TravelPermitSidepanelGeneral";
import TravelPermitSidepanelHost from "./TravelPermitSidepanelHost";
import { TeamsContext } from "../../../../../contexts/TeamsContext";
import { ReportsContext } from "../../../../../contexts/ReportsContext";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";
import withContexts from "../../../../../helpers/withContexts";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import Translate from "@spordle/intl-elements";
import { fail } from "@spordle/toasts";
import ConfirmModal from "../../../../../components/confirmModal/ConfirmModal";
import SidePanelMenuAction from "../../../../../components/sidePanel/components/SidePanelMenuAction";
import { AuthContext, PeriodsContext } from "../../../../../contexts/contexts";
import { triggerDownload } from "../../../../../components/uploader/uploadHelpers";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { RolesContext } from "../../../../../contexts/RolesContext";
import TravelPermitSidepanelLineup from "./TravelPermitSidepanelLineup";

class TravelPermitSidepanel extends React.Component{

    constructor(props){
        super(props);

        this.toggleTab = this.toggleTab.bind(this);
        this.state = {
            activeTab: '1',
            settings: false,
        };

        this.canSubmit = this.props.RolesContext.canDoAction("ADD", "teams", "team_travel_permits") || this.props.RolesContext.canDoAction("ADD", "teams", "team_travel_permits_approval")
        this.canApprove = this.props.RolesContext.canDoAction("EDIT", "teams", "team_travel_permits_approval")
        this.canDecline = this.props.RolesContext.canDoAction("EDIT", "teams", "team_travel_permits_approval")
        this.canDelete = this.props.RolesContext.canDoAction("DELETE", "teams", "team_travel_permits") || this.props.RolesContext.canDoAction("DELETE", "teams", "team_travel_permits_approval")
    }

    componentDidMount(){
        this.setTeamOrganizationSettings();
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0].team.organisation.organisation_id !== this.props.selectedRows[0].team.organisation.organisation_id){
            this.setTeamOrganizationSettings();
        }
    }

    setTeamOrganizationSettings = () => {
        this.props.OrganizationContext.getOrganizationSettings(this.props.selectedRows[0].team.organisation.organisation_id)
            .then((settings) => {
                this.setState(() => ({ settings: settings }));
            })
            .catch(console.error)
    }

    toggleTab(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    toggleAndRefresh = () => {
        this.props.toggle();
        this.props.tableRef.refreshTable();
    }

    // handles PDF exporting
    printPDF = async() => {
        const reportId = 'c33bcec9-4347-11ed-aaa6-02b0a8cb2ee2';
        const data = await this.props.ReportsContext.createExportableReport({
            output: 'PDF',
            organisation_id: this.props.OrganizationContext.organisation_id,
            total_count: 1,
            language_code: this.props.I18nContext.getGenericLocale(),
            report_id: reportId,
            requested_by: this.props.IdentityRolesContext.identity.identity_id,
            request_parameter: JSON.stringify({ travel_permit_id: this.props.selectedRows[0].travel_permit_id, organisation_id: this.props.OrganizationContext.organisation_id, period_id: this.props.PeriodsContext.selectedPeriod.period_id, report_type: 'PDF' }),
        }, true).catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            }
        })

        if(data.download_link){
            triggerDownload(data.download_link);
        }
    }

    render(){
        const data = this.props.selectedRows[0];

        return (
            <>
                <SidePanel.Header noBorder>
                    <div className='d-flex mb-2 align-items-center'>
                        <SidePanel.ToggleButton />
                        <div className="ml-auto d-flex align-items-center">
                            <SidePanel.ActionsMenu>
                                <SidePanelMenuAction onClick={this.printPDF}>
                                    <i className='fas fa-file-pdf mr-1' /><Translate id='travelPermit.print' />
                                </SidePanelMenuAction>

                                <SidePanelMenuAction divider />

                                {(data.travel_permit_status?.system_travel_permit_status === 'DRAFT' && this.canSubmit) &&
                                    <ConfirmModal
                                        translateModalMsg
                                        modalMsg='travelPermit.submit.message'
                                        modalTitle={data.travel_event_name}
                                        color="primary"
                                        icon='mdi mdi-information-outline'
                                        onConfirm={() => {
                                            return this.props.TeamsContext.submitTravelPermit(this.props.AuthContext.account.userName, data.travel_permit_id)
                                                .then(() => {
                                                    this.props.tableRef.refreshTable();
                                                    this.props.toggle();
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                        }}
                                        toggler={(fn) => (
                                            <SidePanelMenuAction onClick={fn}>
                                                <Translate id='travelPermit.submit' />
                                            </SidePanelMenuAction>
                                        )}
                                        translateActionButtonText
                                        actionButtonText='misc.submit'
                                    />
                                }
                                {(data.travel_permit_status?.system_travel_permit_status === 'SUBMITTED' && data.team) &&
                                    <>
                                        {this.canApprove &&
                                            <ConfirmModal
                                                translateModalMsg
                                                modalMsg='travelPermit.approve.message'
                                                modalTitle={data.travel_event_name}
                                                color="primary"
                                                icon='mdi mdi-information-outline'
                                                onConfirm={() => {
                                                    return this.props.TeamsContext.approveTravelPermit(data.team.team_id, data.travel_permit_id)
                                                        .then(() => {
                                                            this.props.tableRef.refreshTable();
                                                            this.props.toggle();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                }}
                                                toggler={(fn) => (
                                                    <SidePanelMenuAction onClick={fn}>
                                                        <Translate id='travelPermit.approve' />
                                                    </SidePanelMenuAction>
                                                )}
                                                translateActionButtonText
                                                actionButtonText={'travelPermit.approve'}
                                            />
                                        }

                                        {this.canDecline &&
                                            <ConfirmModal
                                                translateModalMsg
                                                modalMsg='travelPermit.decline.message'
                                                modalTitle={data.travel_event_name}
                                                color="warning"
                                                onConfirm={() => {
                                                    return this.props.TeamsContext.declineTravelPermit(data.team.team_id, data.travel_permit_id)
                                                        .then(() => {
                                                            this.props.tableRef.refreshTable();
                                                            this.props.toggle();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                }}
                                                toggler={(fn) => (
                                                    <SidePanelMenuAction onClick={fn}>
                                                        <Translate id='travelPermit.decline' />
                                                    </SidePanelMenuAction>
                                                )}
                                                translateActionButtonText
                                                actionButtonText='travelPermit.decline'
                                            />
                                        }
                                    </>
                                }
                                {(data.team && this.canDelete) &&
                                    <SidePanel.MenuDelete
                                        translateModalMsg
                                        modalMsg='travelPermit.deleteModal.message'
                                        modalTitle={data.travel_event_name}
                                        onConfirm={() => {
                                            return this.props.TeamsContext.deleteTravelPermit(data.team.team_id, data.travel_permit_id)
                                                .then(() => {
                                                    this.props.tableRef.deleteRow(this.props.getCommonValue('travel_permit_id')).then(this.props.toggle);
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                        }}
                                    />
                                }
                            </SidePanel.ActionsMenu>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="text-muted">{<Translate id='travelPermit.title' />}</div>
                        <div className="h3 font-bold">{data.travel_event_name}</div>
                    </div>
                    {data.team &&
                        <div className='mb-3'>
                            <UserDisplay to="/teams" className="d-inline-flex">
                                <UserDisplay.Container>
                                    <UserImg
                                        abbr={data.team.abbreviation || data.team.name}
                                        src={data.team.image}
                                        width={50}
                                        height={50}
                                        alt={data.team.name}
                                    />
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Title className="font-bold h4 mb-0">{data.team.name}</UserDisplay.Title>
                                    <UserDisplay.Subtitle>{data.team.organisation.organisation_name}</UserDisplay.Subtitle>
                                </UserDisplay.Container>
                            </UserDisplay>
                        </div>
                    }
                </SidePanel.Header>

                {data.team ?
                    <>
                        <Nav tabs>
                            <NavItem className="w-50 text-center">
                                <NavLink
                                    className={stringBuilder({ active: this.state.activeTab === '1' })}
                                    onClick={() => { this.toggleTab('1'); }}
                                >
                                    <Translate id='travelPermit.sidePanelGeneral.tabTitle' />
                                </NavLink>
                            </NavItem>
                            <NavItem className="w-50 text-center">
                                <NavLink
                                    className={stringBuilder({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.toggleTab('2'); }}
                                >
                                    <Translate id='travelPermit.sidePanelHost.tabTitle' />
                                </NavLink>
                            </NavItem>
                            {this.props.OrganizationContext.settings.enable_travel_permit_lineup_management?.value == 1 &&
                                <NavItem className="w-50 text-center">
                                    <NavLink
                                        className={stringBuilder({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.toggleTab('3'); }}
                                    >
                                        <Translate id="travelPermit.lineup.title" />
                                    </NavLink>
                                </NavItem>
                            }
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <div className="p-3">
                                    <TravelPermitSidepanelGeneral
                                        data={{ ...data }}
                                        settings={this.state.settings}
                                        syncRows={this.props.syncRows}
                                        createNewValues={this.props.createNewValues}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className="p-3">
                                    <TravelPermitSidepanelHost
                                        data={{ ...data }}
                                        settings={this.state.settings}
                                        syncRows={this.props.syncRows}
                                        createNewValues={this.props.createNewValues}
                                        travelTypes={this.props.travelTypes}
                                        toggleAndRefresh={this.toggleAndRefresh}
                                    />
                                </div>
                            </TabPane>
                            {this.props.OrganizationContext.settings.enable_travel_permit_lineup_management?.value == 1 &&
                                <TabPane tabId="3">
                                    <div className="p-3">
                                        <TravelPermitSidepanelLineup data={{ ...data }} />
                                    </div>
                                </TabPane>
                            }
                        </TabContent>
                    </>
                    :
                    <div className="h5 p-3"><Translate id='travelPermit.noTeam' /></div>
                }
            </>
        )
    }
}

export default withContexts(TeamsContext, AuthContext, PeriodsContext, ReportsContext, OrganizationContext, I18nContext, IdentityRolesContext, RolesContext)(TravelPermitSidepanel);