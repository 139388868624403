import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { Button, Col, ModalBody, ModalFooter, Row } from "reactstrap";
import { object, string } from "yup";
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import dashboardData from "../../dashboardData";
import { FormikError } from "@spordle/formik-elements";

const DataTypeList = ({ onSubmit, toggle }) => {
    const getIcon = (dataTypeCode) => {
        switch (dataTypeCode){
            case 'count':
                return <i className="mdi mdi-counter" />
            case 'currency':
                return <i className="mdi mdi-currency-usd" />
            case 'bar-graph':
                return <i className="mdi mdi-chart-bar" />
            case 'line-graph':
                return <i className="mdi mdi-chart-line" />
            case 'preview':
                return <i className="mdi mdi-eye" />
            case 'caroussel':
                return <i className="mdi mdi-view-carousel" />
            case 'scatter-graph':
                return <i className="mdi mdi-chart-bubble" />
            case 'rangeBar-graph':
                return <i className="mdi mdi-chart-timeline" />
            default:
                return <>&nbsp;</>
        }
    }

    return (
        <Formik
            initialValues={{
                selectedDataTypeCode: '',
            }}
            validationSchema={object().shape({
                selectedDataTypeCode: string().required(<Translate id='dashboard.addModal.dataType.required' />),
            })}
            onSubmit={(values) => {
                onSubmit(values.selectedDataTypeCode)
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>

                        <Row form>
                            {dashboardData.availableWidgets.reduce((dataTypes, widget) => {
                                if(!dataTypes.map((d) => d.code).includes(widget.dataType.code)){
                                    dataTypes.push(widget.dataType);
                                }
                                return dataTypes
                            }, []).map((dataType) => {
                                const isSelected = formik.values.selectedDataTypeCode === dataType.code;

                                return (
                                    <Col sm='12' md='12' lg='6' key={dataType.code} className='mb-2'>
                                        <UserDisplay
                                            card hover block
                                            className={stringBuilder('mb-0 h-100', { 'border-primary': isSelected })}
                                            onClick={() => {
                                                formik.setFieldValue('selectedDataTypeCode', dataType.code)
                                            }}
                                        >
                                            <UserDisplay.Container>
                                                <span className="font-20">{getIcon(dataType.code)}</span>
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>
                                                    <DisplayI18n field='label' defaultValue={dataType.name} i18n={dataType.i18n} />
                                                </UserDisplay.Title>
                                                <UserDisplay.Subtitle>
                                                    <DisplayI18n field='description' defaultValue={dataType.description} i18n={dataType.i18n} />
                                                </UserDisplay.Subtitle>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    </Col>
                                )
                            })}
                        </Row>
                        <FormikError name='selectedDataTypeCode' />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' type='submit'><Translate id='misc.next' /></Button>
                        <Button color='primary' outline onClick={toggle} type='button'><Translate id='misc.cancel' /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

export default DataTypeList