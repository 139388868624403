import { DisplayPhoneNumber, TelHref } from '@spordle/formik-elements';
import Translate, { CurrencyFormat, DateFormat } from '@spordle/intl-elements';
import RequestCall from '@spordle/request-calls';
import moment from 'moment';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { Badge, Col, Collapse, Fade, ListGroup, ListGroupItem, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import StatusBadge from '../../../components/badges/StatusBadge';
import DisplayOrganization from '../../../components/organization/DisplayOrganization';
import HasAccessTo from '../../../components/permissions/HasAccessTo';
import SidePanel from '../../../components/sidePanel/SidePanel';
import { fail, success } from '@spordle/toasts';
import { ClinicsContext } from '../../../contexts/ClinicsContext';
import { I18nContext } from '../../../contexts/I18nContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import withContexts from '../../../helpers/withContexts';
import ClinicCategory from '../ClinicCategory';
import AttendeesList from './AttendeesList';
import DisplayAttendeesCard from './components/DisplayAttendeesCard';
import SidepanelWaitlist from './AttendeesWaitlist';
import DisplayWaitlistCard from './components/DisplayWaitlistCard';
import InlineCopy from '../../../components/inlineCopy/InlineCopy';

class SidePanelClinicSearch extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingState: 'success',
            activeTab: '1',
            sessionsOpened: false,
            hostView: true,
            waitlistLoading: false,

            attendees: false,
            waitlist: false,
        }
    }

    /*--------------------------------------------------------------------------------*/
    /* Tabs toggles                                                                   */
    /*--------------------------------------------------------------------------------*/
    toggleTab = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState({ activeTab: tab });
        }
    }

    toggleSessionsCollapse = () => this.setState((prevState) => ({ sessionsOpened: !prevState.sessionsOpened }));

    componentDidMount(){
        this.clinicState = this.props.selectedRows[0].status?.find((status) => this.props.selectedRows[0].active_clinic_state_id === status.clinic_state?.clinic_state_id)?.clinic_state;
        this.getAttendees()
        this.getWaitlist()
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0].clinic_id !== this.props.selectedRows[0].clinic_id){
            this.clinicState = this.props.selectedRows[0].status?.find((status) => this.props.selectedRows[0].active_clinic_state_id === status.clinic_state?.clinic_state_id)?.clinic_state;
            this.getAttendees()
            this.getWaitlist()
            this.setState(() => ({ activeTab: '1' }))
        }
    }

    getAttendees = () => {
        this.setState(() => ({ attendees: false }));

        this.props.ClinicsContext.getClinicAttendees(this.props.getCommonValue('clinic_id'))
            .then((_attendees) => {
                this.setState(() => ({ attendees: _attendees }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({ attendees: null }))
                }
            })
    }

    getWaitlist = () => {
        this.setState(() => ({ waitlist: false, waitlistLoading: true }));
        return this.props.ClinicsContext.getClinicWaitinglist({
            clinic_id: this.props.selectedRows[0].clinic_id,
            period_id: this.props.selectedRows[0].period.period_id,
            organisation_id: this.props.selectedRows[0].organisation.organisation_id,
            type: 'CLINIC',
        }).then((waitlist) => {
            this.setState(() => ({ waitlist: waitlist, waitlistLoading: false }));
            return waitlist;
        }).catch((error) => {
            if(!AxiosIsCancelled(error.message)){
                console.error(error.message)
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
                this.setState(() => ({ waitlist: null, waitlistLoading: false }))
            }
        })
    }

    render(){
        const isHost = this.props.selectedRows[0].organisation.organisation_id === this.props.OrganizationContext.organisation_id;
        return (
            <PerfectScrollbar>
                {/*-------------- BODY ----------------*/}
                {this.state.loadingState === 'lazy' ?
                    <Fade in={this.state.loadingState === 'lazy'}>
                        <div className='d-flex justify-content-center p-5'>
                            <Spinner type='grow' color='primary' />
                        </div>
                    </Fade>
                    : this.state.loadingState === 'success' ?
                        <Fade in={this.state.loadingState === 'success'}>
                            <div className="sticky-top bg-white z-index-1">
                                <div className="p-3">
                                    <div className='align-items-center d-flex mb-3'>
                                        <SidePanel.ToggleButton />
                                        <div className="ml-auto d-flex align-items-center">
                                            <HasAccessTo componentCode='clinics' componentPermissionCode='clinics_profile'>
                                                <Link to={{ pathname: `/clinics/profile/${this.props.selectedRows[0]?.clinic_id}`, data: this.props.selectedRows[0] }} className="mr-2">
                                                    <Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" />
                                                </Link>
                                            </HasAccessTo>
                                            {this.state.hostView && // only host can duplicate, if there are no options we hide the DropdownToggle
                                                <SidePanel.ActionsMenu id='sidepanel-clinic-search-action-btn' label={isHost ? 'misc.actions' : 'misc.action'}>

                                                    {/* DUPLICATE CLINIC ACTION */}
                                                    <SidePanel.MenuAction
                                                        id='clinic-search-duplicate-action'
                                                        action='ADD' componentCode='clinics' componentPermissionCode='clinics_manage'
                                                        tag={Link} to={{ pathname: `/clinics/create`, data: { duplicate: this.props.selectedRows[0] } }}
                                                    >
                                                        <Translate id='clinics.search.sidePanelClinicSearch.options.duplicate' />
                                                    </SidePanel.MenuAction>

                                                    {/* DELETE CLINIC ACTION */}
                                                    {isHost &&
                                                        <SidePanel.MenuDelete
                                                            id='clinic-search-delete-action'
                                                            action='DELETE' componentCode='clinics' componentPermissionCode='clinics_manage'
                                                            translateModalMsg
                                                            disableConfirm={this.props.selectedRows[0]?.clinic_attendee_count > 0}
                                                            modalMsg={this.props.selectedRows[0]?.clinic_attendee_count == 0 ? 'clinics.search.sidePanelClinicSearch.delete.message' : 'clinics.search.sidePanelClinicSearch.delete.cantDeleteAttendees'}
                                                            modalTitle={
                                                                <ClinicCategory color={isNaN(parseInt(this.props.selectedRows[0]?.qualification?.qualification_category?.color)) ? 0 : this.props.selectedRows[0]?.qualification.qualification_category?.color}>
                                                                    <DisplayI18n field='name' defaultValue={this.props.selectedRows[0]?.name} i18n={this.props.selectedRows[0]?.i18n} />
                                                                </ClinicCategory>
                                                            }
                                                            onConfirm={() => {
                                                                return this.props.ClinicsContext.deleteClinic(this.props.selectedRows[0].clinic_id)
                                                                    .then(() => {
                                                                        this.props.tableRef.deleteRow(this.props.selectedRows[0].clinic_id).then(this.props.toggle)
                                                                        success();
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            if(error.i18n){
                                                                                fail({
                                                                                    msg: 'misc.error',
                                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                    skipInfoTranslate: true,
                                                                                })
                                                                            }else{
                                                                                fail({ msg: `context.clinicsContext.errors.${error.message}` })
                                                                            }
                                                                        }
                                                                    })
                                                            }}
                                                        />
                                                    }
                                                </SidePanel.ActionsMenu>
                                            }
                                        </div>
                                    </div>
                                    <div className='mb-3 d-inline-flex align-items-center text-dark'>
                                        <div>
                                            <div className='font-bold h4 mb-0'>
                                                <ClinicCategory color={isNaN(parseInt(this.props.selectedRows[0]?.qualification?.qualification_category?.color)) ? 0 : this.props.selectedRows[0]?.qualification.qualification_category?.color}>
                                                    <DisplayI18n field='name' defaultValue={this.props.selectedRows[0]?.name} i18n={this.props.selectedRows[0]?.i18n} />
                                                    <div className="font-medium font-14 mt-1">
                                                        <InlineCopy toCopy={this.props.getCommonValue('clinic_reference')}>
                                                            <Link to={`/clinics/profile/${this.props.selectedRows[0]?.clinic_id}/overview`}>
                                                                {this.props.getCommonValue('clinic_reference') ?
                                                                    <>#{this.props.getCommonValue('clinic_reference')}<i className="ml-1 mdi mdi-chevron-right" /></>
                                                                    : '-'}
                                                            </Link>
                                                        </InlineCopy>
                                                    </div>
                                                </ClinicCategory>
                                                <div className='small h6 mt-2 d-flex ml-3'>
                                                    <div className='small'>
                                                        <DisplayOrganization organisation={this.props.selectedRows[0].organisation} smallMode>
                                                            <DisplayI18n
                                                                field='name'
                                                                defaultValue={this.props.selectedRows[0].organisation?.organisation_name || '-'}
                                                                i18n={this.props.selectedRows[0].organisation?.i18n}
                                                            />
                                                        </DisplayOrganization>
                                                    </div>
                                                </div>

                                                {this.props.selectedRows[0].sold_out == 1 &&
                                                <div className='ml-3'>
                                                    <Badge color='danger'><Translate id='clinics.profile.attendees.addModal.exchange.soldOut' /></Badge>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Nav tabs className='w-100'>
                                    <NavItem className="w-50 text-center">
                                        <NavLink
                                            active={this.state.activeTab === '1'}
                                            onClick={() => { this.toggleTab('1'); }}
                                        >
                                            <Translate id='clinics.search.sidePanelClinicSearch.details.title' />
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="w-50 text-center">
                                        <NavLink
                                            disabled={!Array.isArray(this.state.attendees)}
                                            active={this.state.activeTab === '2'}
                                            onClick={() => { this.toggleTab('2'); }}
                                            className="position-relative"
                                        >
                                            <Translate id='clinics.search.sidePanelClinicSearch.attendees.title' />
                                            {!Array.isArray(this.state.attendees) &&
                                                <div className="position-absolute d-flex align-items-center top-0 bottom-0 right-0 mr-3"><Spinner size="sm" type="grow" /></div>
                                            }
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <div className="p-3 border-bottom">
                                        <Row form>
                                            <Col xs="6">
                                                <DisplayAttendeesCard selectedRows={this.props.selectedRows} state={this.state} />
                                            </Col>
                                            <Col xs="6">
                                                <DisplayWaitlistCard selectedRows={this.props.selectedRows} state={this.state} />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="p-3 border-bottom">
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='clinics.search.sidePanelClinicSearch.details.category' /></div>
                                            <ClinicCategory color={isNaN(parseInt(this.props.selectedRows[0]?.qualification?.qualification_category?.color)) ? 0 : this.props.selectedRows[0]?.qualification.qualification_category?.color}>
                                                <DisplayI18n field='name' defaultValue={this.props.selectedRows[0]?.qualification?.qualification_category?.name} i18n={this.props.selectedRows[0]?.qualification?.qualification_category?.i18n} />
                                            </ClinicCategory>
                                        </div>
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='clinics.search.sidePanelClinicSearch.details.qualification' /></div>
                                            <div>
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={this.props.selectedRows[0]?.qualification?.name}
                                                    i18n={this.props.selectedRows[0]?.qualification?.i18n}
                                                />
                                                {this.props.selectedRows[0]?.qualification?.qualification_level &&
                                                <>
                                                    <span className='mx-1'>-</span>
                                                    <DisplayI18n
                                                        field='name'
                                                        defaultValue={this.props.selectedRows[0]?.qualification?.qualification_level?.name}
                                                        i18n={this.props.selectedRows[0]?.qualification?.qualification_level?.i18n}
                                                    />
                                                </>
                                                }
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='clinics.search.sidePanelClinicSearch.details.clinicStatus' /></div>
                                            <div className="font-medium">
                                                <StatusBadge color={this.clinicState?.color_code} status={this.clinicState} />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="text-muted"><Translate id='clinics.search.sidePanelClinicSearch.details.location' /></div>
                                            <div><Translate id={`clinics.search.sidePanelClinicSearch.details.${this.props.selectedRows[0]?.clinic_sessions[0]?.session_format || 'UNDEFINED'}`} /></div>
                                        </div>
                                        <Row form>
                                            {isHost &&
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <div className="text-muted"><Translate id='clinics.search.sidePanelClinicSearch.details.cost' /></div>
                                                    <div className="font-medium">
                                                        {this.props.selectedRows[0].total_amount_with_tax ?
                                                            <CurrencyFormat value={this.props.selectedRows[0].total_amount_with_tax / 100} />
                                                            :
                                                            <Translate id='misc.free' />
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                            }
                                            {this.hostView &&
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <div className="text-muted"><Translate id='clinics.search.sidePanelClinicSearch.details.revenus' /></div>
                                                    <div className="font-medium">$ 4200 / $ 5600</div>
                                                </div>
                                            </Col>
                                            }
                                        </Row>

                                        <Row form>
                                            <Col sm="6">
                                                <RequestCall
                                                    key={this.props.getCommonValue('clinic_id')}
                                                    call={() => this.props.ClinicsContext.getClinicContacts(this.props.getCommonValue('clinic_id'), { active: 1 })}
                                                >
                                                    {(snapshot) => {
                                                        if(snapshot.isLoading){
                                                            return (
                                                                <div className="text-center">
                                                                    <Spinner color="primary" type="grow" />
                                                                    <div className="h4"><Translate id='misc.loading' /></div>
                                                                </div>
                                                            );
                                                        }
                                                        if(snapshot.waitingForResponse){
                                                            return null;
                                                        }
                                                        if(snapshot.error){
                                                            return (
                                                                <div className="text-center">
                                                                    <div className="h4"><Translate id='misc.error' /></div>
                                                                </div>
                                                            );
                                                        }
                                                        if(snapshot.data.length === 0)// No instructor
                                                            return null;
                                                        return (
                                                            <Collapse appear isOpen>
                                                                <div className="mb-3">
                                                                    <div className="text-muted mb-1"><Translate id='clinics.search.sidePanelClinicSearch.details.host' /></div>
                                                                    <div className="text-dark font-medium text-truncate">{snapshot.data[0].first_name} {snapshot.data[0].last_name}</div>
                                                                    <div className='text-truncate'>
                                                                        <TelHref className='text-dark' phoneString={snapshot.data[0].phone}>
                                                                            <DisplayPhoneNumber phoneString={snapshot.data[0].phone} format='INTERNATIONAL' />
                                                                        </TelHref>
                                                                    </div>
                                                                    <div className='text-truncate'><a className="text-dark" href={`mailto:${snapshot.data[0].email}`}>{snapshot.data[0].email}</a></div>
                                                                    {snapshot.data.length > 1 && <div className='text-muted small'><Translate id='clinics.search.sidePanelClinicSearch.more' values={{ more: snapshot.data.length - 1 }} /></div>}
                                                                </div>
                                                            </Collapse>
                                                        )
                                                    }}
                                                </RequestCall>
                                            </Col>
                                            <Col sm="6">
                                                <RequestCall
                                                    key={this.props.getCommonValue('clinic_id')}
                                                    call={() => this.props.ClinicsContext.getClinicInstructors(this.props.getCommonValue('clinic_id'), { active: 1 })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                    }
                                                >
                                                    {(snapshot) => {
                                                        if(snapshot.isLoading){
                                                            return (
                                                                <div className="text-center">
                                                                    <Spinner color="primary" type="grow" />
                                                                    <div className="h4"><Translate id='misc.loading' /></div>
                                                                </div>
                                                            );
                                                        }
                                                        if(snapshot.waitingForResponse){
                                                            return null;
                                                        }
                                                        if(snapshot.error){
                                                            return (
                                                                <div className="text-center">
                                                                    <div className="h4"><Translate id='misc.error' /></div>
                                                                </div>
                                                            );
                                                        }
                                                        if(snapshot.data?.length === 0)// No instructor
                                                            return null;
                                                        return (
                                                            <Collapse appear isOpen>
                                                                <div className="mb-3">
                                                                    <div className="text-muted mb-1"><Translate id='clinics.search.sidePanelClinicSearch.details.instructor' /></div>
                                                                    <div className="text-dark font-medium text-truncate">{snapshot.data[0].first_name} {snapshot.data[0].last_name}</div>
                                                                    <div className='text-truncate'>
                                                                        <TelHref className='text-dark' phoneString={snapshot.data[0].phone}>
                                                                            <DisplayPhoneNumber phoneString={snapshot.data[0].phone} format='INTERNATIONAL' />
                                                                        </TelHref>
                                                                    </div>
                                                                    <div className='text-truncate'><a className="text-dark" href={`mailto:${snapshot.data[0].email}`}>{snapshot.data[0].email}</a></div>
                                                                    {snapshot.data.length > 1 && <div className='text-muted small'><Translate id='clinics.search.sidePanelClinicSearch.more' values={{ more: snapshot.data.length - 1 }} /></div>}
                                                                </div>
                                                            </Collapse>
                                                        )
                                                    }}
                                                </RequestCall>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="border-bottom">
                                        <div className="p-3 d-flex clickable" onClick={this.toggleSessionsCollapse}>
                                            <div className="align-self-center">
                                                <div className="h4 font-bold mb-0"><Translate id='clinics.search.sidePanelClinicSearch.details.sessions' /> ({this.props.selectedRows[0]?.clinic_sessions.length})</div>
                                            </div>
                                            <i className={`mdi ml-auto font-22 ${this.state.sessionsOpened ? 'mdi-chevron-down' : 'mdi-chevron-up'}`} />
                                        </div>

                                        <Collapse isOpen={this.state.sessionsOpened}>
                                            <div className="px-3 pb-3">
                                                <ListGroup flush className="border-top">
                                                    {/* The key here is important because we want to fetch the clinicSessions when the selected clinic changes */}
                                                    <RequestCall
                                                        key={this.props.getCommonValue('clinic_id')}
                                                        call={() => this.props.ClinicsContext.getClinicSessions(this.props.getCommonValue('clinic_id'))
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    >
                                                        {(snapshot) => {
                                                            if(snapshot.isLoading){
                                                                return (
                                                                    <div className="text-center">
                                                                        <Spinner color="primary" type="grow" />
                                                                        <div className="h4"><Translate id='misc.loading' /></div>
                                                                    </div>
                                                                );
                                                            }
                                                            if(snapshot.waitingForResponse){
                                                                return null;
                                                            }
                                                            if(snapshot.error){
                                                                return (
                                                                    <div className="text-center">
                                                                        <div className="h4"><Translate id='misc.error' /></div>
                                                                    </div>
                                                                );
                                                            }
                                                            if(snapshot.data.length === 0)// Is empty
                                                                return (
                                                                    <ListGroupItem className='text-center'>
                                                                        <Translate id='clinics.search.sidePanelClinicSearch.details.sessions.none' />
                                                                    </ListGroupItem>
                                                                )

                                                            return snapshot.data.map((session, index) => (
                                                                <ListGroupItem key={session.clinic_session_id}>
                                                                    <div className="d-flex">
                                                                        <div className="h5 mb-0 font-medium">
                                                                            <Translate id='clinics.search.sidePanelClinicSearch.details.session' /> #{index + 1}
                                                                        </div>
                                                                        <div className="ml-1 small text-muted">{session.languages.length !== 0 && `(${session.languages.toString()})`}</div>
                                                                        <div className="ml-auto small">
                                                                            <span className="align-middle"><Translate id={`clinics.search.sidePanelClinicSearch.details.${session.session_format}`} /></span>
                                                                            {session.session_format === 'ONSITE' ?
                                                                                <i className="mdi mdi-map-marker" />
                                                                                : session.session_format === 'ONLINE' ?
                                                                                    <i className="align-middle mdi mdi-play font-14" />
                                                                                    :// ONLINE_LIVE
                                                                                    <i className="align-middle mdi mdi-access-point font-14 ml-1" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div>{session.session_format === 'ONLINE' && <Translate id='clinics.search.sidePanelClinicSearch.sessions.start' />} <DateFormat value={session.start_date} format={DateFormat.formats.longMonthDayYear} />, <DateFormat value={session.start_date} format={'h:mm a'} /></div>
                                                                    {session.session_format === 'ONLINE' &&
                                                                        <div><Translate id='clinics.search.sidePanelClinicSearch.sessions.end' /> <DateFormat value={session.end_date} format={DateFormat.formats.longMonthDayYear} />, <DateFormat value={session.end_date} format={'h:mm a'} /></div>
                                                                    }
                                                                    {session.duration &&
                                                                        <div><Translate id='clinics.search.sidePanelClinicSearch.sessions.duration' /> <DateFormat value={moment(session.duration, 'hh:mm').toDate()} format={'h:mm a'} /></div>
                                                                    }
                                                                    <div className='text-truncate'>
                                                                        {session.session_format === 'ONSITE' ?
                                                                            <a
                                                                                href={`https://www.google.com/maps/search/${session.street_number || ''} ${session.street}, ${session.city || ''}, ${session.province_id || ''} ${session.postal_code || ''}, ${session.country_id || ''}`}
                                                                                target="_blank"
                                                                                rel='noopener noreferrer external'
                                                                            >
                                                                                <i className="mr-1 mdi mdi-google-maps" />{session.location_name}
                                                                            </a>
                                                                            :
                                                                            <a
                                                                                title={session.url_link}
                                                                                // If starts with http(s):// or not
                                                                                // If case the url is something like www.spordle.com we need to redirect to another page
                                                                                href={/^(https?:\/\/)/.test(session.url_link) ? session.url_link : `//${session.url_link}`}
                                                                                target="_blank"
                                                                                rel='noopener noreferrer'
                                                                            >
                                                                                <i className="mr-1 mdi mdi-link-variant" />{session.url_link}
                                                                            </a>
                                                                        }
                                                                    </div>
                                                                </ListGroupItem>
                                                            ))
                                                        }}
                                                    </RequestCall>
                                                </ListGroup>
                                            </div>
                                        </Collapse>
                                    </div>

                                </TabPane>
                                <TabPane tabId="2">
                                    <AttendeesList
                                        clinic={this.props.selectedRows[0]}
                                        hostView={this.state.hostView}
                                        attendees={this.state.attendees}
                                    />
                                    <SidepanelWaitlist
                                        clinic={this.props.selectedRows[0]}
                                        hostView={this.state.hostView}
                                        waitlist={this.state.waitlist}
                                        waitlistLoading={this.state.waitlistLoading}
                                    />
                                </TabPane>
                            </TabContent>
                        </Fade>
                        : this.state.loadingState === 'error' ?
                            <Fade className='p-3' in={this.state.loadingState === 'error'}>
                                <div className='d-flex justify-content-center'>
                                    <h2><Translate id='misc.error' /></h2>
                                </div>
                            </Fade>
                            : null}
                {/*------------------------------------*/}
            </PerfectScrollbar>
        );
    }
}

export default withContexts(I18nContext, OrganizationContext, ClinicsContext)(SidePanelClinicSearch)
