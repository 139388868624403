import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import SupervisionTypeResults from "../../SupervisionTypeResults";

const SupervisionSidePanelSupervisionType = ({ supervision, isReadOnly, updateSupervisionReport }) => {
    return (
        <>
            <div className="h4 font-bold">
                <DisplayI18n field="name" defaultValue={supervision.supervision_type.name} i18n={supervision.supervision_type.i18n} />
            </div>
            <SupervisionTypeResults
                isReadOnly={isReadOnly}
                updateSupervisionReport={updateSupervisionReport}
                supervision={supervision}
            />
        </>
    );
}

export default SupervisionSidePanelSupervisionType;