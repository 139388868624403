import { Button, ModalBody, ModalFooter } from "reactstrap";
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import Translate from "@spordle/intl-elements";
import { stringBuilder } from "@spordle/helpers";
import InlineCopy from "../../../../../../components/inlineCopy/InlineCopy";
import QuickViewButton from "../../../../../../components/quickView/QuickViewButton";

/**
 * @description Component that renders a list of members searched in a modal
 * @param {Object} props
 * @param {object[]} props.results
 * @param {string|JSX.Element} [props.subtitle]
 * @param {function} [props.next] Function called when next button is clicked and no member is selected
 * @param {function} props.onMemberSelect Function called when a member is selected
 * @param {function} [props.previous] Function called when previous button is clicked
 * @param {function} [props.isDisabled] Function called on each member to check if the member should be disabled
 * @param {string|JSX.Element} [props.disabledLabel] the label of a disabled button of a member
 * @returns {JSX.Element}
 */
const DiscriminationSearchResults = ({ results, subtitle, next, isDisabled, previous, onMemberSelect, disabledLabel }) => {
    return (
        <>
            <ModalBody>
                <div className="text-muted pr-2 mb-3">
                    <div className="font-bold h4 mb-0 text-dark">
                        <Translate id='discrimination.addModal.matchingProfiles.title' values={{ count: results?.length || 0 }} />
                    </div>
                    {subtitle &&
                        <p>{subtitle}</p>
                    }
                </div>
                {!!results &&
                    <PerfectScrollbar options={{ suppressScrollX: true }} className="max-vh-50">
                        {results.map((member) => {
                            const disabled = isDisabled?.(member);

                            return (
                                <UserDisplay className={stringBuilder("d-flex mb-2", { "bg-light-inverse": disabled })} key={member.member_id} card>
                                    <UserDisplay.Container>
                                        <UserImg
                                            src={member.picture?.full_path}
                                            filePos={member.picture?.file_position}
                                            abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                            alt={member.first_name + ' ' + member.last_name}
                                            width={40}
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title>
                                            <QuickViewButton iconBeforeChild member={member}>
                                                {member.first_name + ' ' + member.last_name} <small className="text-muted">(<Translate id='misc.yearsOld' values={{ age: member.age }} />)</small>
                                            </QuickViewButton>
                                        </UserDisplay.Title>
                                        <UserDisplay.Subtitle>
                                            <DisplayI18n field="name" defaultValue={member.organisation.organisation_name} i18n={member.organisation.i18n} />
                                        </UserDisplay.Subtitle>
                                        <UserDisplay.Subtitle>
                                            <InlineCopy toCopy={member.unique_identifier}>
                                                #{member.unique_identifier}
                                            </InlineCopy>
                                        </UserDisplay.Subtitle>
                                    </UserDisplay.Container>
                                    <UserDisplay.Container className="ml-auto">
                                        {disabled ?
                                            <Button disabled size="sm" type="button">
                                                {disabledLabel ?? <Translate id='misc.selected' />}
                                            </Button>
                                            :
                                            <Button onClick={() => onMemberSelect(member)} size="sm" type="button" color="primary">
                                                <Translate id="misc.select" />
                                            </Button>
                                        }
                                    </UserDisplay.Container>
                                </UserDisplay>
                            )
                        })}
                    </PerfectScrollbar>
                }
            </ModalBody>
            {(!!previous || !!next) &&
                <ModalFooter>
                    {!!previous &&
                        <Button className="mr-auto" onClick={previous} type="button" color="primary" outline><Translate id="misc.previous" /></Button>
                    }
                    {!!next &&
                        <Button onClick={next} type="button" color="primary"><Translate id='discrimination.addModal.matchingProfiles.btn.next' /> <i className="mdi mdi-arrow-right" /></Button>
                    }
                </ModalFooter>
            }
        </>
    )
}

export default DiscriminationSearchResults;