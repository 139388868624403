import { ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import TravelPermitAddLineup from "../TravelPermitAdd/lineup/TravelPermitAddLineup";
import { TeamsContext } from "../../../../../contexts/TeamsContext";
import { useContext, useState } from "react";
import CrossFade from "../../../../../components/crossFade/CrossFade";
import ModalSuccess from "../../../../../components/formik/ModalSuccess";
import Translate from "@spordle/intl-elements";

const EditLineupModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal analyticsName="editTravelPermitLineup" isOpen={isOpen}>
            <EditLineupModalInner {...props} />
        </AnalyticsModal>
    );
}

const EditLineupModalInner = ({ toggle, initialValues, data, refresh }) => {
    const { updateTeamTravelPermitLineup } = useContext(TeamsContext);
    const [ isSuccess, setIsSuccess ] = useState(false);

    return (
        <>
            <ModalHeader toggle={toggle}>
                <Translate id="travelPermit.lineup.editLineup" />
            </ModalHeader>
            <CrossFade isVisible={!isSuccess}>
                <TravelPermitAddLineup
                    toggle={toggle}
                    teamId={data.team?.team_id}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        return updateTeamTravelPermitLineup(data.team?.team_id, data.travel_permit_id, values)
                            .then(() => {
                                setIsSuccess(true);
                                refresh();
                            })
                    }}
                />
            </CrossFade>
            <CrossFade isVisible={isSuccess}>
                <ModalSuccess toggle={toggle} />
            </CrossFade>
        </>
    )
}

export default EditLineupModal;