export default{
    // Header
    "organization.profile.users.sidePanelUsers.header.remove"     : "Retirer",
    "organization.profile.users.sidePanelUsers.header.resetPwd"   : "Réinitialiser le mot de passe",
    "organization.profile.users.sidePanelUsers.header.reactivate" : "Réactiver le compte",
    "organization.profile.users.sidePanelUsers.header.unlock"     : "Déverrouiller",
    "organization.profile.users.sidePanelUsers.header.confirm"    : "Confirmer l'inscription",

    // Tabs
    "organization.profile.users.sidePanelUsers.tabs.details" : "Détails",

    // User Info
    "organization.profile.users.sidePanelUsers.userInfo.title"                       : "Informations de l'utilisateur",
    "organization.profile.users.sidePanelUsers.userInfo.role"                        : "Rôle",
    "organization.profile.users.sidePanelUsers.userInfo.role.required"               : "Le rôle est requis",
    "organization.profile.users.sidePanelUsers.userInfo.role.inactive"               : "Ce rôle est inactif",
    "organization.profile.users.sidePanelUsers.userInfo.role.inactive.warning"       : "Ce rôle est inactif. L'usager n'aura pas accès à ce rôle.",
    "organization.profile.users.sidePanelUsers.userInfo.additional_roles"            : "Rôles additionnels",
    "organization.profile.users.sidePanelUsers.userInfo.status"                      : "Statut",
    "organization.profile.users.sidePanelUsers.userInfo.status.required"             : "Le statut est requis",
    "organization.profile.users.sidePanelUsers.userInfo.active"                      : "Actif",
    "organization.profile.users.sidePanelUsers.userInfo.inactive"                    : "Inactif",
    "organization.profile.users.sidePanelUsers.userInfo.dates"                       : "Restrictions de dates",
    "organization.profile.users.sidePanelUsers.userInfo.dates.none"                  : "Aucune date spécifiée",
    "organization.profile.users.sidePanelUsers.userInfo.lastConnection.none"         : "Pas de connexion",
    "organization.profile.users.sidePanelUsers.userInfo.from"                        : "Du",
    "organization.profile.users.sidePanelUsers.userInfo.until"                       : "Jusqu'au",
    "organization.profile.users.sidePanelUsers.userInfo.startDate.format.validation" : "La date de début doit être d'un format valide",
    "organization.profile.users.sidePanelUsers.userInfo.endDate.format.validation"   : "La date de fin doit être d'un format valide",
    "organization.profile.users.sidePanelUsers.userInfo.dates.validation"            : "La date de début doit être avant la date de Fin",
    "organization.profile.users.sidePanelUsers.userInfo.phone.2faOnly"               : "Ceci est utilisé uniquement pour l'authentification à 2 facteurs",
    // name, email, address, birthdate, gender and phone number are from global/form.js

    // Remove user
    "organization.profile.users.sidePanelUsers.removeUser.topText"         : "Vous êtes sur le point de retirer cet utilisateur de votre organisation",
    "organization.profile.users.sidePanelUsers.removeUser.confirm"         : "Retirer",
    "organization.profile.users.sidePanelUsers.removeUser.deletingActive1" : "Oops! Vous ne pouvez pas supprimer votre utilisateur de l'organisation que vous gérez en ce moment même!",
    "organization.profile.users.sidePanelUsers.removeUser.deletingActive2" : "Veuillez contacter le support si vous souhaitez supprimer votre utilisateur de cette organisation.",

    // Reset password
    "organization.profile.users.sidePanelUsers.resetPwd.success"     : "Mot de passe réinitialisé",
    "organization.profile.users.sidePanelUsers.resetPwd.reset"       : "Réinitialiser",
    "organization.profile.users.sidePanelUsers.resetPwd.description" : "Un mot de passe temporaire sera envoyé à l'adresse e-mail de l'utilisateur.",
    "organization.profile.users.sidePanelUsers.resetPwd.usersteps"   : "Étapes de l'utilisateur:",
    "organization.profile.users.sidePanelUsers.resetPwd.usersteps.1" : "L'utilisateur devra utiliser ce mot de passe lorsqu'il est demandé.",
    "organization.profile.users.sidePanelUsers.resetPwd.usersteps.2" : "L'utilisateur devra alors changer son mot de passe pour se connecter.",

    // Re-Activate Account
    "organization.profile.users.sidePanelUsers.reactivate.msg"                 : "Vous êtes sur le point de réactiver ce compte",
    "organization.profile.users.sidePanelUsers.reactivate.ok"                  : "Réactiver",
    "organization.profile.users.sidePanelUsers.reactivate.success"             : "Compte réactivé",
    "organization.profile.users.sidePanelUsers.reactivate.error"               : "Impossible de réactiver le compte",
    "organization.profile.users.sidePanelUsers.reactivate.error.activeAccount" : "Impossible de réactiver un compte actif",

    // Unlock Account
    "organization.profile.users.sidePanelUsers.unlock.msg"     : "Vous êtes sur le point de déverrouiller ce compte",
    "organization.profile.users.sidePanelUsers.unlock.ok"      : "Déverrouiller",
    "organization.profile.users.sidePanelUsers.unlock.success" : "Compte déverrouillé",
    "organization.profile.users.sidePanelUsers.unlock.error"   : "Impossible de déverrouillé le compte",

    // Confirm Account
    "organization.profile.users.sidePanelUsers.confirm.msg"     : "Vous êtes sur le point de confirmer l'inscription de ce compte",
    "organization.profile.users.sidePanelUsers.confirm.ok"      : "Confirmer",
    "organization.profile.users.sidePanelUsers.confirm.success" : "Inscription confirmée",
    "organization.profile.users.sidePanelUsers.confirm.error"   : "Impossible de confirmer l'inscription",
}