import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Container, Spinner } from "reactstrap";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import ViewHeader from "../../components/viewHeader/ViewHeader";
import ViewHeaderTitle from "../../components/viewHeader/ViewHeaderTitle";
import { JiraContext } from "../../contexts/JiraContext";
import { DisplayI18n } from "../../helpers/i18nHelper";

const HcrGuide = ({ match, location, ...props }) => {
    const jiraContext = useContext(JiraContext);
    const [ page, setPage ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        jiraContext.getConfluence(match.params.pageId)
            .then(setPage)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
                setPage('err');
            })
            .finally(() => setIsLoading(false))
    }, [ match.params.pageId ]);

    return (
        <>
            <ViewHeader>
                <ViewHeaderTitle>Guide</ViewHeaderTitle>
            </ViewHeader>
            <div className="page-content">
                <Container>
                    <div className="mb-3">
                        <Link className="font-medium" to='/dashboard'><i className="mdi mdi-arrow-left mr-1" /> <Translate id='hcrGuide.btn.back' /></Link>
                    </div>
                    <Card className="card-shadow">
                        <CardBody>
                            { isLoading ?
                                <div className="text-center"><Spinner type="grow" color="primary" /></div>
                                :
                                page === "err" ?
                                    <div>
                                        <div className="h4 font-bold"><Translate id='hcrGuide.error.title' /></div>
                                        <p><Translate id='hcrGuide.error.text' /></p>
                                        <Link to="/dashboard" className="btn btn-primary"><Translate id='hcrGuide.error.btn' /></Link>
                                    </div>
                                    :
                                    <div>
                                        <div className="h2 mb-3 font-medium text-primary">{page?.title}</div>
                                        <div
                                            className="guide-jira-content"
                                            dangerouslySetInnerHTML={{ __html: page?.body?.view?.value }}
                                        />
                                    </div>
                            }
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    )
}

export default HcrGuide;