import Translate from "@spordle/intl-elements";
import { useIntl } from "react-intl";
import { Alert } from "reactstrap";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";

const SettingsError = ({ error }) => {
    const { formatMessage } = useIntl();
    const e = error?.message ? <DisplayI18n field="message" i18n={error.i18n} defaultValue={error.message || formatMessage({ id: "misc.error " })} /> : null

    return (
        <Alert color="danger" className="mb-0 text-center">
            <div className="font-weight-bold text-inherit h5">
                <Translate id="misc.oops" />
            </div>
            <p className="mb-0">{e || <Translate id="misc.error" />}</p>
        </Alert>
    );
}

export default SettingsError;