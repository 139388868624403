import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import moment from "moment";
import { Link } from "react-router-dom";
import InlineCopy from "../../../../../../components/inlineCopy/InlineCopy";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import ClinicCategoryBuilder from "../../../../../clinics/ClinicCategoryBuilder";

/**
 * @typedef {object} Qualification
 * @prop {null|string} expiration_date
 * @prop {object|undefined} clinic
 * @prop {object} qualification
 * @prop {{name: string, i18n: null|object}} period
 */

/**
 * @description List item of the QualificationAttendeesList
 * @param {Object} props
 * @param {number} props.index current Index of the item in the list, specified for border styling
 * @param {Qualification} props.qualification The qualification to render ; expiration_date will render a different style
 * @returns {JSX.Element}
 */
const QualificationsAttendeesListItem = ({ qualification, index }) => {
    const checkIfExpired = (date) => {
        return !date ? false : moment(date).isBefore(moment().add(1, 'day'));
    }

    const isExpired = checkIfExpired(qualification.expiration_date);

    return (
        <div
            className={
                stringBuilder(
                    `p-2`,
                    { "text-muted bg-light-inverse": isExpired },
                    { 'border-top': index !== 0 },
                )
            }
        >
            <ClinicCategoryBuilder
                hideColor
                qualification={qualification.qualification}
            />
            {!!qualification.clinic &&
                <div>
                    {qualification.clinic.clinic_reference ?
                        <InlineCopy toCopy={qualification.clinic.clinic_reference} className="small d-flex align-items-center">
                            <Link className="d-block" to={`/clinics/profile/${qualification.clinic.clinic_id}/attendees`}>
                                        #{qualification.clinic.clinic_reference} <i className="mdi mdi-chevron-right" />
                            </Link>
                        </InlineCopy>
                        :
                        <Link className="d-block small" to={`/clinics/profile/${qualification.clinic.clinic_id}/attendees`}>
                            <Translate id='teams.profile.roster.sidePanel.qualification.goTo' /> <i className="mdi mdi-chevron-right" />
                        </Link>
                    }
                </div>
            }
            <div className='text-muted small mb-0'>
                <span className="font-medium"><Translate id='teams.profile.teamRoster.activeRoster.add.label.season' /></span>: {qualification.period?.period_id ? <DisplayI18n field="name" defaultValue={qualification.period?.name} i18n={qualification.period?.i18n} /> : '-'}
                {!!qualification.expiration_date &&
                    <>
                        {' | '}
                        <span className="font-medium"><Translate id='teams.profile.roster.sidePanel.qualification.expiration' /></span>: {qualification.expiration_date}
                        {isExpired &&
                            <span className="ml-1">(<Translate id='teams.profile.roster.sidePanel.qualification.expired' />)</span>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default QualificationsAttendeesListItem;