import Translate from "@spordle/intl-elements";
import { useContext, useState } from "react";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { fail, success } from '@spordle/toasts';
import CustomAlert from "../../../../../../components/CustomAlert";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";

const UpdateEmailModal = ({ isOpen, toggle, email, ...props }) => {
    return (
        <AnalyticsModal analyticsName='updateEmailModal' isOpen={isOpen}>
            <UpdateEmailModalInner toggle={toggle} email={email} {...props} />
        </AnalyticsModal>
    )
}

const UpdateEmailModalInner = ({ toggle, email, ...props }) => {
    const membersContext = useContext(MembersContext)
    const [ isLoading, setIsLoading ] = useState(false);
    const [ error, setError ] = useState(null);

    const handleOnCancel = () => {
        toggle();
    }

    const handleOnConfirm = () => {
        setIsLoading(true);

        membersContext.updateMemberPartial(membersContext.currentMember.member_id, {
            email: email,
        })
            .then(async() => {
                success({ msg: 'members.profile.overview.memberContacts.addContact.confirm' })
                await membersContext.getAllMemberInfos(membersContext.currentMember.member_id)
                setIsLoading(false);
                toggle();
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setIsLoading(false);
                    if(error.i18n){
                        setError(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                    }else{
                        setError(<Translate id='misc.error' />)
                    }
                }
            })
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalBody className="text-center position-relative">
                <button onClick={toggle} type="button" aria-label="Close" className="close position-absolute top-0 right-0 p-3">
                    <span aria-hidden="true">×</span>
                </button>
                <i style={{ fontSize: 75 }} className='mdi mdi-information-outline text-primary' />
                {/* {!!modalMsg && <div className="mb-2">{translateModalMsg ? <Translate id={modalMsg} /> : modalMsg}</div>} */}
                <div className="h4"><Translate id='members.profile.overview.memberContacts.addContact.updateEmail' /></div>
                <div className='font-bold'>{email}</div>

                {error &&
                    <CustomAlert color='danger' text={error} translateText={false} toggle={() => setError(null)} withTitle className='mt-3 text-left' />
                }
            </ModalBody>
            <ModalFooter className="border-top-0 justify-content-center">
                <Button disabled={isLoading} color='primary' onClick={handleOnConfirm}>
                    <Translate id="misc.yes" />
                </Button>
                <Button disabled={isLoading} color="primary" outline onClick={handleOnCancel}>
                    <Translate id="misc.no" />
                </Button>
            </ModalFooter>
        </OverlayLoader>
    )
}

export default UpdateEmailModal;