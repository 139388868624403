// Contexts
import { useSpordleTable } from "@spordle/datatables";
import { FormikSelect } from "@spordle/formik-elements";
// Helpers
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Collapse, FormGroup, Label, Row } from "reactstrap";
import { AxiosCancelTeamsCalls, AxiosIsCancelled } from "../../../../api/CancellableAPI";
import OrganizationSearch from '../../../../components/organization/OrganizationSearch';
import { I18nContext } from "../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { RegistrationCategoriesContext } from '../../../../contexts/RegistrationCategoriesContext';
import { RegistrationDivisionsContext } from "../../../../contexts/RegistrationDivisionsContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { DisplayCategory, genders } from '../../TeamHelpers';
import { fail } from "@spordle/toasts"
import useSavedSearch from "../../../../components/customHooks/useSavedSearch";
import CrossFade from "../../../../components/crossFade/CrossFade";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import SavedSearchCue from "../../../../components/savedSearchCue/SavedSearchCue";
import { PeriodsContext } from "../../../../contexts/contexts";

const TeamAdvancedSearch = ({ status, defaultLimit, paginationState, setPaginationState }) => {
    const organizationsContext = useContext(OrganizationContext);
    const { identity_role_id } = useContext(IdentityRolesContext);
    const { getRegistrationDivisions } = useContext(RegistrationDivisionsContext);
    const { getRegistrationCategories } = useContext(RegistrationCategoriesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { filterChange } = useSpordleTable();
    const periodsContext = useContext(PeriodsContext);

    const [ selectedOrganization, setSelectedOrganization ] = useState(organizationsContext.organisation_id);
    const [ children, setChildren ] = useState([]);

    const [ selectedDivision, setSelectedDivision ] = useState('');
    const [ selectedCategory, setSelectedCategory ] = useState('');
    const [ selectedGender, setSelectedGender ] = useState('');
    const [ divisions, setDivisions ] = useState(null);
    const [ categories, setCategories ] = useState(null);

    const { savedSearch, clearSavedSearch, saveSearch } = useSavedSearch(`team-search-filters-${organizationsContext.organisation_id}-${identity_role_id}-${periodsContext.selectedPeriod.period_id}`);

    const initVal = {
        category: '',
        division: '',
        status: '',
        gender: '',
        organization: selectedOrganization,
    }

    const fetchCategories = (id) => {
        setCategories(null);
        return getRegistrationCategories(id)
            .then((allCats) => allCats.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order)))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setCategories([]);
                }
            })
    }

    useEffect(() => {
        organizationsContext.getOrganizationChildren()
            .then(setChildren)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    useEffect(() => {
        setDivisions(null);

        Promise.all([
            getRegistrationDivisions(selectedOrganization),
            fetchCategories(selectedOrganization),
        ])
            .then(([ divisions, allCats ]) => {
                setCategories(allCats);
                setDivisions(divisions.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order)));
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setDivisions([]);
                }
            })
    }, [ selectedOrganization ]);

    useEffect(() => {
        if(!!selectedDivision || !!selectedCategory || !!selectedGender)
            setPaginationState({ ...paginationState, limit: -1 })
        else
            setPaginationState({ ...paginationState, limit: defaultLimit, total: false })
    }, [ selectedDivision, selectedCategory, selectedGender ])

    return (
        <Card className="card-shadow">
            <CardBody>
                <Formik
                    initialValues={{ ...initVal, ...savedSearch }}
                    onSubmit={(values) => {
                        AxiosCancelTeamsCalls();
                        const filterVal = {
                            team_category_id: [ values.category ],
                            division_id: [ values.division ],
                            team_status_id: [ values.status ],
                            gender: [ values.gender ],
                            organisation_id: [ values.organization || selectedOrganization ],
                        };

                        filterChange('advancedSearch', filterVal, true);
                        saveSearch(filterVal);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Row form>
                                <Col md="4" lg="4">
                                    <FormGroup>
                                        <Label for="organization" className="text-muted">
                                            <Translate id='members.search.memberAdvanceSearch.filters.organization' />
                                        </Label>
                                        <OrganizationSearch
                                            disabled={children.length === 0}
                                            withFormik fromApi
                                            name="organization"
                                            id="organization"
                                            onOptionSelected={([ value ]) => {
                                                formik.setFieldValue('division', '');
                                                setSelectedDivision('');
                                                setSelectedOrganization(value);
                                            }}
                                        />
                                    </FormGroup>
                                    {/* <FormGroup>
                                        <Label for='league' className='text-muted'>
                                            <Translate id='teams.teamSearch.advancedSearch.label.league' />
                                        </Label>
                                        <FormikSelect
                                            disabled
                                            id='league'
                                            name='league'
                                            loadingStatus='success'
                                        />
                                    </FormGroup> */}
                                </Col>
                                <Col md="4" lg="4">
                                    <FormGroup>
                                        <Label for='status' className='text-muted'><Translate id="misc.status" /></Label>
                                        <FormikSelect
                                            clearable
                                            id='status'
                                            name='status'
                                            isLoading={!status}
                                            search={false}
                                            renderOption={({ option }) => (option.value === 'DRAFT') ? <Translate id={option.label} /> : <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                            options={status
                                                ?.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))
                                                ?.map((stat) => ({
                                                    i18n: stat.i18n,
                                                    label: stat.name,
                                                    value: stat.team_status_id,
                                                }))
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4" lg="4" className="align-self-end">
                                    <FormGroup>
                                        <Button
                                            block
                                            type="submit"
                                            color='primary'
                                            className='text-nowrap'
                                            // disabled={state.loadingState === 'loading' || state.loadingState === 'lazy'}
                                        >
                                            <i className='fas fa-search mr-1' /><Translate id='misc.search' />
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col md="4" lg="4">
                                    <FormGroup>
                                        <Label for='division' className='text-muted'><Translate id='registration.settings.category.label.division' /></Label>
                                        <FormikSelect
                                            clearable
                                            id='division'
                                            name='division'
                                            searchKeys={[
                                                `i18n.${getGenericLocale()}.short_name`,
                                            ]}
                                            isLoading={!divisions}
                                            renderOption={({ option }) => <DisplayI18n field="short_name" defaultValue={option.label} i18n={option.i18n} />}
                                            onOptionSelected={([ value ]) => {
                                                setSelectedDivision(value || '');
                                                formik.setFieldValue('category', '')
                                            }}
                                            options={divisions?.map((div) => ({
                                                i18n: div.i18n,
                                                label: div.short_name,
                                                value: div.division_id,
                                            }))}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4" lg="4">
                                    <FormGroup>
                                        <Label for='category' className='text-muted'><Translate id='registration.settings.category.label.category' /></Label>
                                        <FormikSelect
                                            clearable
                                            id='category'
                                            name='category'
                                            isLoading={!categories}
                                            disabled={!formik.values.division}
                                            searchKeys={[
                                                `category.division.i18n.${getGenericLocale()}.short_name`,
                                                `category.class.i18n.${getGenericLocale()}.short_name`,

                                            ]}
                                            renderOption={({ option }) => (
                                                <>
                                                    <div className="mb-0">
                                                        <DisplayCategory category={option.category} short />
                                                    </div>
                                                    {option.category?.gender && <div className="small">{<Translate id={`form.fields.gender.${option.category.gender.toLowerCase()}`} />}</div>}
                                                </>
                                            )}
                                            options={categories
                                                ?.filter((cat) => (!formik.values.division || cat.division?.division_id.includes(formik.values.division)))
                                                ?.map((cat) => ({
                                                    category: cat,
                                                    value: cat.team_category_id,
                                                    label: cat.division?.short_name,
                                                    translateLabel: true,
                                                }))
                                            }
                                            onOptionSelected={([ value ]) => {
                                                setSelectedCategory(value || '');
                                                const selectedCat = categories.find((c) => c.team_category_id === value);

                                                if(selectedCat?.gender){
                                                    formik.setFieldValue('gender', selectedCat.gender)
                                                }else{
                                                    formik.setFieldValue('gender', '')
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4" lg="4">
                                    <FormGroup>
                                        <Label for='gender' className='text-muted'><Translate id='form.fields.gender' /></Label>
                                        <FormikSelect
                                            clearable
                                            id='gender'
                                            name='gender'
                                            search={false}
                                            disabled={!!formik.values.category}
                                            onOptionSelected={([ value ]) => {
                                                setSelectedGender(value || '')
                                                formik.setFieldValue('gender', value || '')
                                            }}
                                            defaultData={genders
                                                .map((gender) => ({
                                                    label: `form.fields.gender.${gender.toLowerCase()}`,
                                                    value: gender,
                                                    translateLabel: true,
                                                }))
                                            }
                                            loadingStatus='success'
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="d-flex flex-wrap flex-md-nowrap">
                                <SavedSearchCue
                                    className="order-1 order-md-0 mt-3 mt-md-0 w-100 w-md-auto mr-auto"
                                    savedSearch={savedSearch}
                                    clearSavedSearch={() => {
                                        clearSavedSearch();
                                        formik.setValues(initVal);
                                    }}
                                />
                                <CrossFade className="w-100 w-md-auto text-right ml-auto" isVisible={JSON.stringify(initVal) !== JSON.stringify(formik.values)}>
                                    <button
                                        type='button'
                                        className='reset-btn text-danger font-12'
                                        onClick={() => {
                                            clearSavedSearch();
                                            formik.setValues(initVal);
                                        }}
                                    >
                                        <Translate id='members.search.memberAdvanceSearch.resetFilters' />
                                    </button>
                                </CrossFade>
                            </div>
                        </Form>
                    )}
                </Formik>
                <Collapse isOpen={!!(paginationState?.limit !== -1) && parseInt(paginationState?.limit) <= parseInt(paginationState?.total)}>
                    <div className="bg-light mt-2 p-2 rounded d-flex">
                        <div className="d-flex align-items-center mr-1">
                            <i className="mr-1 mdi mdi-information-outline text-primary" />
                        </div>
                        <div>
                            <div className="font-medium text-dark"><Translate id='teams.teamSearch.advancedSearch.label.searchLimit' /> {paginationState?.limit || "-"}</div>
                            {paginationState?.total && <div className="font-medium text-dark"><Translate id='teams.teamSearch.advancedSearch.label.searchTotal' /> {paginationState?.total || "-"}</div>}
                            <div className="small text-dark"><Translate id='teams.teamSearch.advancedSearch.label.searchLimitDescription' /></div>
                        </div>
                    </div>
                </Collapse>
            </CardBody>
        </Card>
    )
}

export default TeamAdvancedSearch;