import { Button, Col, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AnalyticsModal from "../../../../../../../analytics/AnalyticsModal";
import ApiErrorMsg from "../../../../../../../components/formik/ApiErrorMsg";
import FormikModalForm from "../../../../../../../components/formik/FormikModalForm"
import Translate from "@spordle/intl-elements";
import { mixed, object } from "yup";
import moment from "moment";
import { FormikDateTime, FormikSelect } from "@spordle/formik-elements";
import FormikApiError from "../../../../../../../components/formik/FormikApiError";
import Required from "../../../../../../../components/formik/Required";
import { MembersContext } from "../../../../../../../contexts/MembersContext";
import { useContext } from 'react';

const MemberSuspensionActivateModal = ({ isOpen, ...props }) => {
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName="activateSuspensionModal">
            <MemberSuspensionActivateModalInner {...props} />
        </AnalyticsModal>
    );
}

const MemberSuspensionActivateModalInner = ({ toggle, onSuccess, suspensionId, startDate, ...props }) => {
    const membersContext = useContext(MembersContext);

    return (
        <FormikModalForm
            toggle={toggle}
            successText={<p><Translate id="members.profile.suspensions.activate.confirm" /></p>}
            initialValues={{
                duration: 'DEFINITE',
                endDate: '',
            }}
            validationSchema={object().shape({
                endDate: mixed().when('duration', {
                    is: 'DEFINITE',
                    then: mixed()
                        .test({
                            name: 'shouldBeRequired',
                            message: <Translate id='members.profile.suspensions.endDate.required' />,
                            test: function(date){
                                return !!date;
                            },
                        })
                        .test({
                            name: 'endDateFormatTest',
                            message: <Translate id='form.validation.date.format' />,
                            test: function(date){
                                return !date || moment.isMoment(date);
                            },
                        })
                        .test({
                            name: 'endDateAfterTodayActivate',
                            message: <Translate id='members.profile.suspensions.validation.dateAfterToday' />,
                            test: (date) => {
                                if(moment.isMoment(date)){
                                    return moment(date).isAfter(moment())
                                }
                                return true
                            },
                        })
                        .test({
                            name: 'endDateAfterStartDate',
                            message: <Translate id='members.profile.suspensions.endDate.afterStart' />,
                            test: (date) => {
                                if(moment.isMoment(date) && startDate){
                                    return moment(date).isSameOrAfter(moment(startDate))
                                }
                                return true
                            },
                        }),
                }),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                return membersContext.updateMemberSuspensionPartial(membersContext.currentMember.member_id, suspensionId, {
                    duration: values.duration,
                    end_date: moment.isMoment(values.endDate) ? values.endDate.format('YYYY-MM-DD') : '',
                })
                    .then(() => {
                        setStatus({
                            showError: false,
                            error: null,
                            isSuccess: true,
                        });
                        onSuccess?.(values);
                    })
                    .catch((e) => {
                        setSubmitting(false);
                        setStatus({
                            showError: true,
                            isSuccess: false,
                            error: Array.isArray(e) ? e : <ApiErrorMsg error={e} />,
                        })
                    })
            }}
        >
            {(formik) => (
                <>
                    <ModalHeader toggle={toggle}>
                        <Translate id="members.profile.suspensions.activate.title" />
                    </ModalHeader>
                    <ModalBody>
                        <Row form>
                            <Col sm='6'>
                                <FormGroup>
                                    <Label for='duration' className='text-muted'><Translate id='members.profile.suspensions.suspensionType' /> <Required /></Label>
                                    <FormikSelect
                                        name='duration'
                                        id='duration'
                                        search={false}
                                        loadingStatus='success'
                                        defaultData={[
                                            {
                                                label: 'members.profile.suspensions.duration.definite',
                                                translateLabel: true,
                                                value: 'DEFINITE',
                                            },
                                            {
                                                label: 'members.profile.suspensions.duration.indefinite',
                                                translateLabel: true,
                                                value: 'INDEFINITE',
                                            },
                                        ]}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm='6'>
                                <FormGroup>
                                    <Label for='endDate' className='text-muted'><Translate id='members.profile.suspensions.endDate' /> {formik.values.duration === 'DEFINITE' && <Required />}</Label>
                                    <FormikDateTime
                                        id='endDate' name='endDate'
                                        timeFormat={false}
                                        dateFormat='YYYY-MM-DD'
                                        isValidDate={(current) => moment(current).isAfter(moment())}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormikApiError />
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="primary" disabled={formik.isSubmitting || formik.status.isSuccess}>
                            <Translate id="misc.submit" />
                        </Button>
                        <Button
                            disabled={formik.isSubmitting || formik.status.isSuccess}
                            type="button"
                            color="primary"
                            outline
                            onClick={toggle}
                        >
                            <Translate id="misc.cancel" />
                        </Button>
                    </ModalFooter>
                </>
            )}
        </FormikModalForm>
    );
}

export default MemberSuspensionActivateModal