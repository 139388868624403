import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import moment from 'moment';
import { useContext, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import ConfirmModal from '../../../../../../components/confirmModal/ConfirmModal';
import Required from '../../../../../../components/formik/Required';
import { fail, success } from '@spordle/toasts';
import { ClinicsContext } from '../../../../../../contexts/ClinicsContext';
import { I18nContext } from '../../../../../../contexts/I18nContext';
import AddSessionModal from './AddSessionModal';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';

const SessionsTable = (props) => {
    const clinicsContext = useContext(ClinicsContext);
    const i18nContext = useContext(I18nContext);

    /**  @type {React.MutableRefObject<SpordleTableProvider>} */
    const tableRef = useRef()

    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const toggleModal = () => setModalIsOpen(!modalIsOpen);

    const [ editValues, setEditValues ] = useState(null);

    const buildAddress = ({ streetNumber, address, city, zip, province, country }) => {
        const addressString = [];
        addressString.push(
            streetNumber ? `${streetNumber} ` : '',
            `${address}, `,
            `${city}, `,
            `${province} `,
            `${zip}, `,
            `${country}`,
        )
        return addressString.join('');
    }

    const toggleEdit = (values) => {
        setEditValues(values);
        toggleModal();
    }

    return (
        <div>
            <AddSessionModal
                isOpen={modalIsOpen}
                toggle={toggleEdit}
                onSuccess={() => {
                    props.setNumberOfSessions(tableRef.current.getData().length + 1)
                    tableRef.current?.refreshTable();
                }}
                values={editValues}
            />

            <SpordleTableProvider
                id='sessions'
                viewMode='GRID'
                ref={tableRef}
                dataIndex='clinicSessionId'
                gridConfig={{
                    col: {
                        sm: 12,
                    },
                    gridRender: (session, spordleTable) => {
                        return (
                            <Card className='card-shadow'>
                                <CardHeader className="bg-white">
                                    <div className='d-flex align-items-center'>
                                        <div className={`mb-0 font-bold ${props.wizard ? 'h5' : 'h6'}`}><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.number' values={{ number: <sup>o</sup> }} /> {session.index + 1}</div>
                                        <div className='ml-auto'>
                                            <CanDoAction action='EDIT' componentCode='clinics' componentPermissionCode='clinics_manage'>
                                                <button className='reset-btn text-link' type='button' onClick={() => toggleEdit(session)}><Translate id='misc.edit' /></button>
                                            </CanDoAction>

                                            <>
                                                <CanDoAction action='ADD' componentCode='clinics' componentPermissionCode='clinics_manage'>
                                                    <span className='border-right border-grey mx-3' />
                                                    <ConfirmModal
                                                        toggler={(toggle) => (
                                                            <button className='reset-btn text-link' type='button' onClick={toggle}>
                                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.duplicate' />
                                                            </button>
                                                        )}
                                                        modalTitle={<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.duplicate.msg' />}
                                                        actionButtonText='misc.confirm'
                                                        translateActionButtonText
                                                        color='primary'
                                                        icon='mdi-content-duplicate'
                                                        onConfirm={async() => {
                                                            if(props.wizard){
                                                                return clinicsContext.createSession(clinicsContext.currentClinic?.clinic_id, {
                                                                    languages: session.language,
                                                                    format: session.format,
                                                                    locationName: session.locationName,
                                                                    ...session.address,
                                                                    urlLink: session.urlLink,
                                                                    startDate: session.startDate ? session.startDate.set({ 'hour': session.startTime.hour(), 'minute': session.startTime.minute() }).toISOString() : moment(session.date.set({ 'hour': session.time.hour(), 'minute': session.time.minute() })).toISOString(),
                                                                    endDate: session.endDate ? session.endDate.set({ 'hour': session.endTime.hour(), 'minute': session.endTime.minute() }).toISOString() : moment(session.date.set({ 'hour': session.time.hour(), 'minute': session.time.minute() }).add(session.hours, 'h').add(session.minutes, 'm')).toISOString(),
                                                                    duration: moment().set({ 'hour': session.hours, 'minute': session.minutes }).format('HH:mm'),
                                                                    note: session.note,
                                                                    mandatory: true,
                                                                    active: true,
                                                                }).then(() => {
                                                                    success();
                                                                    spordleTable.refreshTable();
                                                                }).catch((error) => {
                                                                    if(!AxiosIsCancelled(error.message)){
                                                                        console.error(error.message)
                                                                        fail({
                                                                            msg: 'misc.error',
                                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                            skipInfoTranslate: true,
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                            return clinicsContext.createSession(clinicsContext.currentClinic?.clinic_id, {
                                                                languages: session.language === 'bilingual' ? 'en,fr' : session.language,
                                                                format: session.format,
                                                                locationName: session.locationName,
                                                                ...session.address,
                                                                urlLink: session.urlLink,
                                                                startDate: session.startDate ? session.startDate.set({ 'hour': session.startTime.hour(), 'minute': session.startTime.minute() }).toISOString() : moment(session.date.set({ 'hour': session.time.hour(), 'minute': session.time.minute() })).toISOString(),
                                                                endDate: session.endDate ? session.endDate.set({ 'hour': session.endTime.hour(), 'minute': session.endTime.minute() }).toISOString() : moment(session.date.set({ 'hour': session.time.hour(), 'minute': session.time.minute() }).add(session.hours, 'h').add(session.minutes, 'm')).toISOString(),
                                                                duration: moment().set({ 'hour': session.hours, 'minute': session.minutes }).format('HH:mm'),
                                                                note: session.note,
                                                                mandatory: true,
                                                                active: true,
                                                            }).then(() => {
                                                                success();
                                                                spordleTable.refreshTable();
                                                            }).catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                }
                                                            })

                                                        }}
                                                    />
                                                </CanDoAction>
                                            </>

                                            {spordleTable.getData().length > 1 &&
                                                <>
                                                    <CanDoAction action='DELETE' componentCode='clinics' componentPermissionCode='clinics_manage'>
                                                        <span className='border-right border-grey mx-3' />
                                                        <ConfirmModal
                                                            toggler={(toggle) => (
                                                                <button className='reset-btn text-danger' type='button' onClick={toggle}><Translate id='misc.delete' /></button>
                                                            )}
                                                            modalTitle={<Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.delete.text' />}
                                                            actionButtonText='misc.confirm'
                                                            translateActionButtonText
                                                            color='danger'
                                                            onConfirm={async() => {
                                                                return clinicsContext.deleteSession(clinicsContext.currentClinic?.clinic_id, session.clinicSessionId)
                                                                    .then(() => {
                                                                        success();
                                                                        spordleTable.refreshTable();
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            console.error(error.message)
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                        }
                                                                    })
                                                            }}
                                                        />
                                                    </CanDoAction>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md='6'>
                                            <div className="mb-3">
                                                <div className="text-muted mb-1"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.language' /></div>
                                                <div className="font-medium"><Translate id={'misc.' + session.language} /></div>
                                            </div>
                                        </Col>
                                        <Col md='6'>
                                            <div className="mb-3">
                                                <div className="text-muted mb-1"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.format' /></div>
                                                <div className="font-medium">
                                                    {session.format === 'ONSITE' ?
                                                        <i className="mdi mdi-map-marker"> </i>
                                                        :
                                                        session.format === 'ONLINE' ?
                                                            <i className="mdi mdi-access-point"> </i>
                                                            :
                                                            <i className="mdi mdi-play"> </i>}
                                                    <Translate id={'clinics.clinicSessionInformation.formClinicSessionInformation.sessions.' + session.format} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {session.format === 'ONSITE' &&
                                        <div className="mb-3">
                                            <div className="text-muted mb-1"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.location' /></div>
                                            <div className="font-medium">{session.locationName ? session.locationName + ' - ' : ''}{session.address.fullAddress}</div>
                                        </div>
                                    }
                                    {session.format !== 'ONSITE' &&
                                        <Row>
                                            {/* <Col md='6'>
                                                <div className='mb-3'>
                                                    <div className="text-muted mb-1"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.source' /></div>
                                                    <div className="font-medium"><Translate id={'clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.' + session.source} /></div>
                                                </div>
                                            </Col> */}
                                            <Col md='6'>
                                                <div className='mb-3'>
                                                    <div className="text-muted mb-1"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.url' /></div>
                                                    {
                                                        session.urlLink ?
                                                            <div className="font-medium">{session.urlLink}</div>
                                                            :
                                                            <div className="font-medium"> - </div>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    {session.format !== 'ONLINE' &&
                                        <div className='mb-3'>
                                            <div className='text-muted mb-1'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.title' /></div>
                                            <div className='font-medium'>
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text1' />
                                                {session.date.locale(i18nContext.getGenericLocale()).format('LL')}
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text2' />
                                                {session.time.format('LT')}
                                            </div>
                                            <div className='font-medium'>
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text3' />
                                                {session.hours}
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text4' />
                                                {session.minutes}
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text5' />
                                            </div>
                                        </div>
                                    }
                                    {session.format === 'ONLINE' &&
                                        <div className='mb-3'>
                                            <div className='text-muted mb-1'><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.title' /></div>
                                            <div className='font-medium'>
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text1' />
                                                {session.startDate.locale(i18nContext.getGenericLocale()).format('LL')}
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text2' />
                                                {session.startTime.format('LT')}
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text3' />
                                                {session.endDate.locale(i18nContext.getGenericLocale()).format('LL')}
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text4' />
                                                {session.endTime.format('LT')}
                                            </div>
                                            <div className='font-medium'>
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text5' />
                                                {session.hours}
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text6' />
                                                {session.minutes}
                                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text7' />
                                            </div>
                                        </div>
                                    }
                                    {session.note &&
                                        <div className="mb-3">
                                            <div className="text-muted mb-1"><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.notes' /></div>
                                            <div className="font-medium" dangerouslySetInnerHTML={{ __html: session.note }} />
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        )
                    },
                }}
                desktopWhen='md'
                loadData={(from, extra, spordleTable) => {
                    switch (from){
                        case 'REFRESH':
                            spordleTable.setLoading();
                        case 'CDM':
                            return clinicsContext.getClinicSessions(props.clinicId)
                                .then(async(sessions) => {
                                    return sessions.map((session, index) => ({
                                        index: index,
                                        language: session.languages.length > 1 ? 'bilingual' : session.languages[0],
                                        format: session.session_format,

                                        // online live && online
                                        // source: 'external',
                                        urlLink: session.url_link || '',

                                        // on site
                                        locationName: session.location_name || '',
                                        address: {
                                            streetNumber: session.street_number || '',
                                            address: session.street || '',
                                            city: session.city || '',
                                            country: session.country_id || '',
                                            state: session.province_id || '',
                                            zipCode: session.postal_code || '',
                                            origin: session.origin_address || '',
                                            fullAddress: session.session_format === 'ONSITE' ? buildAddress({
                                                streetNumber: session.street_number,
                                                address: session.street,
                                                city: session.city,
                                                zip: session.postal_code,
                                                province: session.province_id,
                                                country: session.country_id,
                                            }) : '',
                                            //mapsUrl: ''
                                        },

                                        // schedule -> on site & online live
                                        date: moment(session.start_date) || '',
                                        time: moment(session.start_date) || '',

                                        // schedule -> online
                                        startDate: moment(session.start_date) || '',
                                        startTime: moment(session.start_date) || '',
                                        endDate: moment(session.end_date) || '',
                                        endTime: moment(session.end_date) || '',

                                        // schedule -> all
                                        hours: session.duration.split(':')[0] || '',
                                        minutes: session.duration.split(':')[1] || '',

                                        note: session.note || '',

                                        clinicSessionId: session.clinic_session_id,
                                    }))

                                    // const response = await alreadyExists(authContextContact, formattedInstructors)
                                    // setAddMyselfDisabled(response === 'instructor')


                                })
                    }
                }}
            >
                {(spordleTable) => (
                    <>
                        <div className={stringBuilder('d-flex mb-2', { 'border-bottom mb-3 pb-1': props.wizard })}>
                            <div className={`font-bold mb-0 ${props.wizard ? 'h3 card-title' : 'h5 '}`}>
                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.title' values={{ count: spordleTable.getData().length }} />
                            </div>
                            <Required className="ml-1" />

                            <CanDoAction action='ADD' componentCode='clinics' componentPermissionCode='clinics_manage' className='ml-auto'>
                                <button className='btn btn-link p-0' type='button' onClick={toggleModal}><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.sessions.add' /></button>
                            </CanDoAction>
                        </div>
                        <SpordleTableView />
                    </>
                )}
            </SpordleTableProvider>
        </div>
    )
}

export default SessionsTable