import { FormikDateTime } from "@spordle/formik-elements"
import { useFormikContext } from "formik"
import { FormGroup } from "reactstrap"
import { filterHasValue, formikCodesHaveValues } from "../reportsEngineHelpers"
import FilterLabel from "./FilterLabel"

const EngineDate = ({ filter, filterRefs }) => {
    const formik = useFormikContext()
    return (
        <FormGroup>
            <FilterLabel filter={filter} />
            <FormikDateTime
                {...filter.parsedParams?.componentProps}
                id={filter.code}
                name={filter.code}
                placeholder='reports.engine.date.placeholder'
                translatePlaceholder
                value={formik.values[filter.code]}
                timeFormat={false}
                inputProps={{ disabled: !formikCodesHaveValues(filter.parsedParams?.enabledWhen, formik) || !filterHasValue(filter.parsedParams?.enabledWhenValue?.filter, filter.parsedParams?.enabledWhenValue?.value, formik) }}
                className={`w-100 ${filter.parsedParams?.componentProps?.className}`}
            />
        </FormGroup>
    )
}

export default EngineDate