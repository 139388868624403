// Components
import TeamSearch from '../../views/teams/search/TeamSearch';
import TeamProfile from '../../views/teams/profile/TeamProfile';
import TeamCreation from '../../views/teams/add/TeamCreation/TeamCreation';
import teamProfileTabsRoutes from '../tabRoutes/teamProfileTabsRoutes';
import TeamRollover from '../../views/teams/add/TeamRollover/TeamRollover';
import InvalidTeamProfileId from '../../views/teams/profile/InvalidTeamProfileId';

// Header

const teamRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        exact: true,
        name: 'sidebar.teams',
        path: '/teams',
        permissionsLogic: "OR",
        permissions: [
            { code: 'organization', permissionCode: 'manage_competition_teams' },
            { code: 'teams' },
        ],
        component: TeamSearch,
    },
    {
        name: 'sidebar.teams.profile',
        path: '/teams/profile/:teamId',
        permissions: { code: 'teams', permissionCode: 'teams_profile' },
        subPermissionsRoutes: teamProfileTabsRoutes,
        skipRecents: true,
        component: TeamProfile,
    },
    {
        name: 'sidebar.teams.rollover',
        path: '/teams/rollover',
        skipRecents: true,
        permissions: { code: 'teams', permissionCode: 'teams_rollover', action: 'ADD' },
        component: TeamRollover,

    },
    {
        name: 'sidebar.teams.create',
        path: '/teams/create',
        permissions: { code: 'teams', permissionCode: 'teams_profile', action: 'ADD' },
        component: TeamCreation,
        skipRecents: true,
    },
    {
        exact: true,
        path: '/teams/404',
        permissions: { code: 'teams' },
        component: InvalidTeamProfileId,
        skipRecents: true,
    },
];
export default teamRoutes;
