import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../components/UserImg";
import { I18nContext } from "../../../../../../../contexts/I18nContext";
import { MembersContext } from "../../../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import { DisplayCategory } from "../../../../../../teams/TeamHelpers";
import { DiscriminationAddContext } from "../../components/DiscriminationAddContext";
import InlineCopy from "../../../../../../../components/inlineCopy/InlineCopy";
import QuickViewButton from "../../../../../../../components/quickView/QuickViewButton";
import { stringBuilder } from "@spordle/helpers";
import { PeriodsContext } from "../../../../../../../contexts/contexts";

const GuiltyMemberCard = ({ member, index }) => {
    const { complaint, setComplaint } = useContext(DiscriminationAddContext);
    const { getMemberTeams } = useContext(MembersContext);
    const [ teams, setTeams ] = useState(null);
    const { getGenericLocale } = useContext(I18nContext);
    const { selectedPeriod } = useContext(PeriodsContext);

    const removeMember = (tempId) => {
        setComplaint('complainees', complaint.complainees.filter((comp) => comp.temp_id !== tempId));
    }

    useEffect(() => {
        if(member.member_id){
            getMemberTeams(member.member_id, { period_id: selectedPeriod.period_id })
                .then((teams) => {
                    setTeams(teams.filter((t) => t.active == "1").reduce((newTeams, team) => {
                        if(!newTeams.find((nT) => nT.team.team_id === team.team.team_id))
                            newTeams.push(team)
                        return newTeams
                    }, []));
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        setTeams([]);
                    }
                });
        }
    }, [])

    return (
        <Card className="card-shadow mb-3">
            <CardBody className="p-3">
                <UserDisplay key={member.temp_id} className="d-flex p-0 mb-3">
                    <UserDisplay.Container>
                        <UserImg
                            className="text-uppercase"
                            width={50}
                            src={member.picture?.full_path}
                            abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                            filePos={member.picture?.file_position}
                            alt={member.first_name + ' ' + member.last_name}
                        />
                    </UserDisplay.Container>
                    <UserDisplay.Container>
                        <UserDisplay.Title>
                            <QuickViewButton member={member}>
                                {member.first_name + ' ' + member.last_name}
                            </QuickViewButton>
                        </UserDisplay.Title>
                        <UserDisplay.Subtitle>
                            <InlineCopy toCopy={member.unique_identifier}>
                                #{member.unique_identifier}
                            </InlineCopy>
                        </UserDisplay.Subtitle>
                        {member.role && <UserDisplay.Subtitle><Translate id={'discrimination.global.roles.' + member.role} /></UserDisplay.Subtitle>}
                    </UserDisplay.Container>
                    {complaint.complainees.length > 1 &&
                        <UserDisplay.Container
                            onClick={() => removeMember(member.temp_id)}
                            className="clickable position-absolute top-0 right-0 p-3 text-danger"
                        >
                            <i className="fa fa-trash" />
                        </UserDisplay.Container>
                    }
                </UserDisplay>
                <Row className="mt-2 pt-2 border-top" form>
                    <Col sm="12">
                        <div className="text-muted mb-2"><Translate id='discrimination.label.team' /></div>
                        {(teams?.length <= 0) ?
                            <FormikInputText type="text" name={`complainees.${index}.team_name`} />
                            :
                            <FormikSelect
                                className="w-100"
                                name={`complainees.${index}.team_id`}
                                id={`complainees.${index}.team_id`}
                                isLoading={teams === null}
                                clearable
                                search
                                searchKeys={[
                                    `category.division.i18n.${getGenericLocale()}.short_name`,
                                    `category.class.i18n.${getGenericLocale()}.short_name`,
                                    `category.gender`,
                                ]}
                                options={teams ? teams.map((mTeam) => ({
                                    label: mTeam.team.name,
                                    value: mTeam.team.team_id,
                                    team_category: mTeam.team.team_category,
                                    division: mTeam.team.division, // For "Historical" team category (year 1 data)
                                })) : []}
                                renderSelectedOption={(option) => <>{option.label} <small className="text-muted">({option.division ? option.division : <DisplayCategory category={{ ...option.team_category }} short />})</small></>}
                                renderOption={({ option, isSelected }) => (
                                    <div>
                                        <div>{option.label}</div>
                                        <div className={stringBuilder(`text-${isSelected ? 'light' : 'muted'}`, 'small')}>
                                            {option.division ? option.division : <DisplayCategory category={{ ...option.team_category }} short /> }
                                            {option.team_category?.category?.gender && <> - <Translate id={`form.fields.gender.${option.category.gender.toLowerCase()}`} /></>}
                                        </div>
                                    </div>
                                )}
                            />
                        }
                    </Col>
                    <Col sm="12">
                        <small className="text-muted">
                            <i className="mdi mdi-information-outline mr-1" />
                            <Translate id='discrimination.form.team.helper.short' />
                        </small>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default GuiltyMemberCard;