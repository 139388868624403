import { Tooltip } from "@mantine/core";
import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables"
import Translate, { DateFormat } from "@spordle/intl-elements"
import { useEffect, useState } from "react";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import CollapsibleCard from "../../../../../collapsibleCard/CollapsibleCard"
import CustomAnimatedIcon from "../../../../../customAnimatedIcon/CustomAnimatedIcon";
import UserDisplay from "../../../../../userDisplay/UserDisplay";
import DisplayRecipient from "../../../displayRecipient/DisplayRecipient"
import styles from "./ReviewOrgs.module.scss";

const DisplayAllSelectedContacts = ({ contacts, tableRef }) => {
    useEffect(() => {
        tableRef.current.refreshTable()
    }, [ contacts.length ])

    const [ selectedContactsCount, setSelectedContactCount ] = useState(contacts.filter((contact) => contact.checked)?.length);
    const updateSelectedContactLength = () => setSelectedContactCount(tableRef.current?.getData().filter((contact) => contact.checked).length)

    return (
        <SpordleTableProvider
            id="communicationOrganizationContacts"
            ref={tableRef}
            dataIndex={'id'}
            pagination={10}
            loadingStatus='success'
            loadData={(from, filterData, spordleTable) => {
                switch (from){
                    case 'REFRESH':
                    case 'FILTER':
                    case 'CDM':
                        spordleTable.setLoading();
                        return Promise.resolve(contacts)
                    default:
                        break;
                }
            }}
            viewMode='GRID'
            gridConfig={{
                col: { xs: 12 },
                gridRender: (contact, spordleTable) => (
                    <DisplayRecipient
                        key={contact.id}
                        hover
                        onClick={() => {
                            spordleTable.updateDataWith(contact.id, { ...contact, checked: !contact.checked })
                                .then(() => {
                                    updateSelectedContactLength();
                                })
                        }}
                        className={`mb-2 ${styles.Contact}`}
                        recipient={contact}
                        subtitle={
                            <>
                                <div className="d-flex">
                                    {contact.organisation[contact.organisation_id] &&
                                        <div className='font-bold text-muted small mr-1'>
                                            <DisplayI18n
                                                field="name"
                                                i18n={contact.organisation[contact.organisation_id].i18n}
                                                defaultValue={contact.organisation[contact.organisation_id].organisation_name}
                                            />
                                        </div>
                                    }
                                    {contact.isUser &&
                                        <div className='font-medium text-muted small'>
                                            {contact.title}
                                        </div>
                                    }
                                </div>
                                {contact.organisation_contact_functions && contact.organisation_contact_functions?.length > 1 ?
                                    <div className='font-medium text-muted small'>
                                        <ul className="ml-n4 mb-0">
                                            {contact.organisation_contact_functions.map((contactFunction) => (
                                                <li key={contactFunction.organisation_contact_function_id}>
                                                    <DisplayI18n field="name" i18n={contactFunction.organisation_contact_type.i18n} defaultValue={contactFunction.organisation_contact_type.name} />
                                                    {contactFunction.is_elected == 1 && !contactFunction.elected_until &&
                                                        <span className="ml-1 text-muted small"><Translate id="organization.profile.staff.formik.elected" /></span>
                                                    }
                                                    {contactFunction.elected_until &&
                                                        <span className="text-muted small ml-1">
                                                            <Translate id='organization.profile.staff.formik.electedUntil' /> <DateFormat className='ml-1' value={contactFunction.elected_until} />
                                                        </span>
                                                    }
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    :
                                    <div className='font-medium text-muted small'>
                                        <DisplayI18n field="name" i18n={contact.organisation_contact_functions?.[0]?.organisation_contact_type?.i18n} defaultValue={contact.organisation_contact_functions?.[0]?.organisation_contact_type?.name} />
                                        {contact.organisation_contact_functions?.[0]?.is_elected == 1 && !contact.organisation_contact_functions?.[0]?.elected_until &&
                                            <span className=" ml-1 text-muted small"><Translate id="organization.profile.staff.formik.elected" /></span>
                                        }
                                        {contact.organisation_contact_functions?.[0]?.elected_until &&
                                            <span className="text-muted small ml-1">
                                                <Translate id='organization.profile.staff.formik.electedUntil' /> <DateFormat className='ml-1' value={contact.organisation_contact_functions?.[0]?.elected_until} />
                                            </span>
                                        }
                                    </div>
                                }
                            </>
                        }
                    >
                        <UserDisplay.Container className="ml-auto text-primary">
                            <Tooltip
                                withArrow
                                zIndex={3000}
                                label={<Translate id={`misc.${contact.isSelected ? "unselect" : "select"}`} />}
                            >
                                <div className="position-relative clickable">
                                    <div
                                        className={`rounded-circle transition ${styles.EmptyCheck}`}
                                        style={{
                                            opacity: contact.isSelected ? 0.1 : 0.25,
                                        }}
                                    />
                                    {contact.checked &&
                                        <div className="position-absolute top-0 bottom-0 left-0 right-0 d-flex flex-center">
                                            <CustomAnimatedIcon
                                                icon="checkmark"
                                                strokeWidth="8px"
                                                withCircle
                                                size="30px"
                                            />
                                        </div>
                                    }
                                </div>
                            </Tooltip>
                        </UserDisplay.Container>
                    </DisplayRecipient>
                ),
            }}
        >
            <CollapsibleCard
                initOpen
                arrowIsRight
                title={
                    <div className="position-relative">
                        <Translate id="components.communications.modal.label.contacts" /> (<span>{selectedContactsCount} <span className="text-muted font-normal">/ {contacts.length}</span></span>)
                    </div>
                }
            >
                <SpordleTableView />
            </CollapsibleCard>
        </SpordleTableProvider>
    )
}

export default DisplayAllSelectedContacts