import ReportsDashboard from '../../views/reports/reportsDashboard/ReportsDashboard';
import ReportExports from '../../views/reports/exports/ReportExports';


const reportsSidebar = [
    {
        name: 'sidebar.reports.library',
        navlabel: true,
        subPermissionsRoutes: [
            {
                path: '/reports/dashboard',
                name: 'sidebar.reports.dashboard',
                permissions: { code: 'reports' },
                noBreadcrumb: true,
                component: ReportsDashboard,
                isActive: (_, location) => {
                    return location.pathname === "/reports/dashboard" && location.search !== "?category=exports"
                },
            },
            {
                path: '/reports/dashboard?category=exports',
                name: 'sidebar.reports.exports',
                permissions: { code: 'reports' },
                component: ReportExports,
                isActive: (_, location) => {
                    return location.pathname === "/reports/dashboard" && location.search === "?category=exports"
                },
            },
        ],
    },
    {
        sortAlphabetical: true,
        api: true,
        context: 'reports',
        skeletonsAmount: 10,
        name: 'sidebar.reports',
        navlabel: true,
        subPermissionsRoutes: [
            {
                path: '/reports/',
                name: 'sidebar.reports',
                hidden: true,
            },
        ],
    },
];
export default reportsSidebar;
