import Translate, { DateFormat } from '@spordle/intl-elements';
import React, { useContext } from 'react';
import { MembersContext } from '../../../../../contexts/MembersContext';
import { displayI18n, DisplayI18n } from '../../../../../helpers/i18nHelper';
import DisplayOrganization from '../../../../../components/organization/DisplayOrganization';
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import { Tooltip } from '@mantine/core';
import { FormattedPlural } from 'react-intl';
import generatePassword from '../../../../../helpers/passwordGenerator';
import { Col, Row } from 'reactstrap';
import MemberOverviewMemberTypeList from './memberTypes/MemberOverviewMemberTypeList';
import moment from 'moment';
import CRCStatus from '../../criminalRecordCheck/components/CRCStatus';

const MemberOverviewInfo = ({ officiatingCoach, refreshMemberProfile }) => {
    const { currentMember, memberCRC } = useContext(MembersContext);

    //const regularMemberTypes = currentMember.member_types?.filter((type) => type.member_type.system_type !== 'SPECIAL').sort((a, b) => (a.organisation && b.organisation) ? (displayI18n('name', a.organisation.i18n, a.organisation.organisation_name).localeCompare(displayI18n('name', b.organisation.i18n, b.organisation.organisation_name))) : false) || [];
    const sharedOrganizations = currentMember.share_organisations?.filter((org) => org.is_nrp == 0)
    const NRPSharedOrganizations = currentMember.share_organisations?.filter((org) => org.is_nrp == 1)

    const approvedClassChangeRequests = currentMember.classification_request_changes?.filter((request) => request.request_change_status?.system === "APPROVED")
    const classChangeRequest = (approvedClassChangeRequests && approvedClassChangeRequests.length > 0) ? approvedClassChangeRequests[approvedClassChangeRequests.length - 1] : null; // Take last change request in array to get the most recent

    const currentSeasonPosition = currentMember.positions?.filter((p) => p.current_season == 1)?.sort((a, b) => (a.organisation && b.organisation) ? (displayI18n('name', a.organisation.i18n, a.organisation.organisation_name).localeCompare(displayI18n('name', b.organisation.i18n, b.organisation.organisation_name))) : false)
    const lastSeasonPosition = currentMember.positions?.filter((p) => p.last_season == 1)?.sort((a, b) => (a.organisation && b.organisation) ? (displayI18n('name', a.organisation.i18n, a.organisation.organisation_name).localeCompare(displayI18n('name', b.organisation.i18n, b.organisation.organisation_name))) : false)

    // take most recent CRC, display the expiry date when valid, or "NAME - Pending - ISSUE_DATE" when pending
    const lastCRC = memberCRC.sort((a, b) => moment(a.created_at).isAfter(moment(b.created_at)) ? -1 : 1)[0]

    return (
        <div className="profileHeader-info-container">
            <Row form>
                <Col sm={6} className="mb-2">
                    <div className="text-muted"><Translate id='members.profile.memberProfile.header.primaryOrg' /></div>
                    <div className="font-medium">
                        <DisplayOrganization organisation={{ organisation_id: currentMember.organisation?.organisation_id }}>
                            <DisplayI18n
                                field='name'
                                i18n={currentMember.organisation?.i18n}
                                defaultValue={currentMember.organisation?.organisation_name}
                            />
                        </DisplayOrganization>
                    </div>
                </Col>
                {/* Shared organizations  */}
                {sharedOrganizations?.length > 0 &&
                    <Col sm={6} className="mb-2">
                        <div className="text-muted"><Translate id='members.profile.memberProfile.header.sharedOrg' /></div>
                        <div className="font-medium text-break">
                            {sharedOrganizations.map((org, i) => (org &&// Some Sentry events has org to be null for some reason...
                                <span className="mr-1 d-flex" key={org.organisation_id}>
                                    <DisplayOrganization organisation={{ organisation_id: org.organisation_id }}>
                                        <DisplayI18n
                                            field="name"
                                            defaultValue={org.organisation_name}
                                            i18n={org.i18n}
                                        />
                                    </DisplayOrganization>
                                    {(i < sharedOrganizations.length - 1) && ','}
                                </span>
                            ))}
                        </div>
                    </Col>
                }
                {/* NRP Shared organizations  */}
                {NRPSharedOrganizations?.length > 0 &&
                    <Col sm={6} className="mb-2">
                        <div className="text-muted"><Translate id='members.profile.memberProfile.header.sharedOrg.nrp' /></div>
                        <div className="font-medium text-break">
                            {NRPSharedOrganizations.map((org, i) => (org &&// Some Sentry events has org to be null for some reason...
                                <span className="mr-1" key={org.organisation_id}>
                                    <DisplayI18n
                                        field="name"
                                        defaultValue={org.organisation_name}
                                        i18n={org.i18n}
                                    />
                                    {(i < NRPSharedOrganizations.length - 1) && ','}
                                </span>
                            ))}
                        </div>
                    </Col>
                }
                {/* Last CRC */}
                {lastCRC &&
                    <Col sm={6} className='mb-2'>
                        <div className="text-muted"><Translate id='members.profile.crc.title' /></div>
                        <div className="font-medium text-break">
                            <DisplayI18n field='name' defaultValue={lastCRC.background_check_type.name} i18n={lastCRC.background_check_type.i18n} />
                            {lastCRC.criminal_record_check_status.name === 'Pending' ?
                                <>
                                    <div className='text-muted font-normal small d-flex align-items-center'>
                                        <CRCStatus crc={lastCRC} />
                                        {/* <span className='mx-1'>|</span> */}
                                        <span className='mx-1'>
                                            <Translate id='tasks.memberTypeValidation.columns.crc.date' />:
                                        </span>
                                        <DateFormat value={lastCRC.issue_date} />
                                    </div>
                                </>
                                : lastCRC.criminal_record_check_status.name === 'Completed' ?
                                    <>
                                        <div className='text-muted font-normal small d-flex align-items-center'>
                                            <CRCStatus crc={lastCRC} />
                                            {/* <span className='mx-1'>|</span> */}
                                            <span className='mx-1'>
                                                <Translate id='tasks.memberTypeValidation.columns.crc.expiry' />:
                                            </span>
                                            <DateFormat value={lastCRC.expiration_date} />
                                        </div>
                                    </>
                                    :
                                    null
                            }
                        </div>
                    </Col>
                }
                <Col sm={12} />

                {/* Member types  */}
                <MemberOverviewMemberTypeList refreshMemberProfile={refreshMemberProfile} />

                {/* Member positions  */}
                {currentMember.positions?.length > 0 &&
                    <Col sm={6} className="mb-2">
                        <div className='d-inline-block'>
                            <div className="text-muted d-flex align-items-end">
                                <FormattedPlural
                                    value={currentSeasonPosition?.length > 0 ? currentSeasonPosition?.length : lastSeasonPosition?.length}
                                    one={<Translate id='members.profile.memberProfile.header.position' />}
                                    other={<Translate id='members.profile.memberProfile.header.positions' />}
                                />
                                {currentSeasonPosition?.length <= 0 && lastSeasonPosition?.length > 0 && <div className='ml-1 small'>(<Translate id='members.profile.memberProfile.header.positions.lastPeriod' />)</div> }
                            </div>
                        </div>
                        {currentSeasonPosition?.length > 0 ?
                            <ul className="font-medium pl-0 mb-0" style={{ listStylePosition: 'inside' }}>
                                {currentSeasonPosition?.map((position) => (
                                    <li key={position.position_id + generatePassword()}>
                                        <DisplayI18n defaultValue={position.name} i18n={position.i18n} field='name' />
                                        <div className='ml-1 text-muted small d-inline-flex align-items-center'>
                                            {position.organisation &&
                                                <DisplayOrganization organisation={{ organisation_id: position.organisation.organisation_id }}>
                                                    (<DisplayI18n defaultValue={position.organisation.organisation_name} i18n={position.organisation.i18n} field='name' />)
                                                </DisplayOrganization>
                                            }
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            : lastSeasonPosition?.length > 0 ?
                                <ul className="font-medium pb-0 pl-0 mb-0" style={{ listStylePosition: 'inside' }}>
                                    {lastSeasonPosition?.map((position) => (
                                        <li key={position.position_id + generatePassword()}>
                                            <DisplayI18n defaultValue={position.name} i18n={position.i18n} field='name' />
                                            <div className='ml-1 text-muted small d-inline-flex align-items-center'>
                                                {position.organisation &&
                                                    <DisplayOrganization organisation={{ organisation_id: position.organisation.organisation_id }}>
                                                        (<DisplayI18n defaultValue={position.organisation.organisation_name} i18n={position.organisation.i18n} field='name' />)
                                                    </DisplayOrganization>
                                                }
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                :
                                null
                        }
                    </Col>
                }
                {classChangeRequest &&
                    <Col sm={12}>
                        <div className="text-muted">
                            <Translate id={`members.profile.classification.change.fields.type.${(classChangeRequest.type || "").toLowerCase()}`} />
                            <Tooltip
                                withArrow
                                position="right"
                                label={<ClassChangeRequestTooltip classChangeRequest={classChangeRequest} />}
                            >
                                <i className='ml-1 mdi mdi-information-outline text-primary' />
                            </Tooltip>
                        </div>
                        {(!!classChangeRequest.from_team_category && classChangeRequest.to_team_category) ?
                            <div>
                                <div className='d-flex flex-wrap'>
                                    <DisplayI18n field='name' defaultValue={classChangeRequest.from_team_category.name} i18n={classChangeRequest.from_team_category.i18n} />
                                    <div className='mx-2'>
                                        →
                                    </div>
                                    <div className='font-bold'>
                                        <DisplayI18n field='name' defaultValue={classChangeRequest.to_team_category.name} i18n={classChangeRequest.to_team_category.i18n} />
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className='d-flex flex-wrap'>
                                    <div>
                                        <DisplayI18n field='name' defaultValue={classChangeRequest.from_division.name || "test"} i18n={classChangeRequest.from_division?.i18n} />
                                    </div>
                                    <div className='mx-2'>
                                            →
                                    </div>
                                    <div className='font-bold'>
                                        <DisplayI18n field='name' defaultValue={classChangeRequest.to_division.name || "test"} i18n={classChangeRequest.to_division?.i18n} />
                                    </div>
                                </div>
                            </div>
                        }
                    </Col>
                }
                {currentMember.is_official == 1 && officiatingCoach?.supervision_types?.length > 0 &&
                    <CanDoAction tag={Col} sm={6} className="mb-2" action="READ" componentCode="members" componentPermissionCode="manage_official_supervisions">
                        <div className="text-muted">
                            <Translate id='members.profile.memberProfile.header.officiatingCoach' />
                        </div>
                        <div className="font-medium">
                            {officiatingCoach.supervision_types.map((type, index) => (
                                <React.Fragment key={type.supervision_type_id}>
                                    <DisplayI18n field="name" i18n={type.i18n} defaultValue={type.name} />{index !== officiatingCoach.supervision_types.length - 1 && ', '}
                                </React.Fragment>
                            ))}
                        </div>
                    </CanDoAction>
                }
            </Row>
        </div>
    );
}

const ClassChangeRequestTooltip = ({ classChangeRequest }) => (
    <>
        <div className='text-decoration-underline'><Translate id='members.profile.action.classificationChange' /></div>

        <div className='text-muted d-flex mt-2'>
            <Translate id='members.profile.classification.change.fields.type.label' />
        </div>
        <div>
            <Translate id={`members.profile.classification.change.fields.type.${classChangeRequest.type.toLowerCase()}`} />
        </div>

        <div className='text-muted d-flex mt-2'>
            <Translate id='members.profile.classification.view.fields.division' />
        </div>
        <div className='d-flex flex-wrap'>
            <div>
                <DisplayI18n field='name' defaultValue={classChangeRequest.from_division.name} i18n={classChangeRequest.from_division.i18n} />
            </div>
            <div className='mx-2'>
                    →
            </div>
            <div className='font-bold'>
                <DisplayI18n field='name' defaultValue={classChangeRequest.to_division.name} i18n={classChangeRequest.to_division.i18n} />
            </div>
        </div>

        {(!!classChangeRequest.from_team_category && classChangeRequest.to_team_category) &&
            <>
                <div className='text-muted d-flex mt-2'>
                    <Translate id='members.profile.classification.view.fields.team.category' />
                </div>
                <div className='d-flex flex-wrap'>
                    <div>
                        <DisplayI18n field='name' defaultValue={classChangeRequest.from_team_category.name} i18n={classChangeRequest.from_team_category.i18n} />
                    </div>
                    <div className='mx-2'>
                        →
                    </div>
                    <div className='font-bold'>
                        <DisplayI18n field='name' defaultValue={classChangeRequest.to_team_category.name} i18n={classChangeRequest.to_team_category.i18n} />
                    </div>
                </div>
            </>
        }

        <div className='mt-2 text-muted'><Translate id='task.mandatoryChangesRequests.row.request.requested_by' /></div>
        <div>{classChangeRequest.created_by.name} {classChangeRequest.created_by.family_name} - {classChangeRequest.created_by.email}</div>

        {classChangeRequest.request_note &&
            <div className='mt-2'>
                <div className='text-muted'><Translate id='form.fields.note' /></div>
                <div>{classChangeRequest.request_note}</div>
            </div>
        }

    </>
)

export default MemberOverviewInfo;