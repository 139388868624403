import { useLocation } from 'react-router-dom';
import {
    Breadcrumb
} from 'reactstrap';

// Language
import { useContext } from 'react';
import navigationRoutes from '../../../routes/navigationRoutes';
import PeriodWarning from '../../../components/viewHeader/PeriodWarning';
import BreadCrumbsItem from './BreadcrumbsItem';
import BreadcrumbPeriodSelect from './breadcrumbPeriodSelect/BreadcrumbPeriodSelect';
import { RefreshButton } from '../../../components/viewHeader/ViewHeaderV2';
import { QuickViewContext } from '../../../contexts/QuickViewContext';
import BreadcrumbsCopyBtn from './buttons/BreadcrumbsCopyBtn';
import BreadcrumbsFavBtn from './buttons/BreadcrumbsFavBtn';

const isActivePath = (routes, location) => {
    return -1 !== routes.findIndex((r) => {
        if(r.subPermissionsRoutes){
            return r.subPermissionsRoutes.findIndex((r) => location.pathname.startsWith(r.path)) != -1
        }
        return location.pathname.startsWith(r.path)
    })
}


export const getBreadcrumbs = (location) => {
    const breadcrumbsArray = [];

    navigationRoutes.forEach((route) => {
        if(route.subPermissionsRoutes && Array.isArray(route.subPermissionsRoutes) && route.subPermissionsRoutes.find((r) => r.navlabel) && isActivePath(route.subPermissionsRoutes, location)){
            // -- With groups --

            // Header Route
            if(!route.noBreadcrumb){
                breadcrumbsArray.push(route);
            }

            const groupedRoute = route.subPermissionsRoutes.find((myRoute) => myRoute.subPermissionsRoutes.find((r) => location.pathname.startsWith(r.path)));

            if(groupedRoute && !groupedRoute.api){
                // Find breadcrumbs inside grouped routes
                if(groupedRoute.subPermissionsRoutes && Array.isArray(groupedRoute.subPermissionsRoutes) && groupedRoute.subPermissionsRoutes.find((r) => location.pathname.startsWith(r.path))){

                    const mainRoute = { ...groupedRoute.subPermissionsRoutes.find((r) => location.pathname.startsWith(r.path)) }

                    if(!mainRoute.noBreadcrumb){
                        breadcrumbsArray.push(mainRoute);
                    }

                    // Find breadcrumbs from "reports routes" (reports)
                    if(mainRoute.subPermissionsRoutes && Array.isArray(mainRoute.subPermissionsRoutes) && mainRoute.subPermissionsRoutes?.find((mR) => mR.subPermissionsRoutes?.find((r) => location.pathname.endsWith(r.path)))){
                        const s = mainRoute.find((mR) => mR.subPermissionsRoutes.find((r) => location.pathname.endsWith(r.path)))
                        s.noLink = true // bug present in reports breadcrumbs

                        if(!s.noBreadcrumb){
                            breadcrumbsArray.push(s);
                        }

                    // Find breadcrumbs from "tab routes" (tabs)
                    }else if(mainRoute.tabRoutes && Array.isArray(mainRoute.tabRoutes) && mainRoute.tabRoutes.find((r) => location.pathname.endsWith(r.path) && r.path !== '')){
                        // Add tabBreadcrumb if present (used for profile breadcrumb title)
                        if(mainRoute.tabBreadcrumb){
                            breadcrumbsArray.push({
                                name: mainRoute.tabBreadcrumb,
                                noLink: true,
                            })
                        }

                        const t = { ...mainRoute.tabRoutes.find((r) => location.pathname.endsWith(r.path) && r.path !== '') }
                        t.fullRoute = true
                        t.path = location.pathname
                        // no link is already on the route (t)

                        if(!t.noBreadcrumb){
                            breadcrumbsArray.push(t);
                        }
                    }
                }
            }
        }else if(route.subPermissionsRoutes && Array.isArray(route.subPermissionsRoutes) && route.subPermissionsRoutes.find((r) => location.pathname.startsWith(r.path))){
            // Header Route
            if(!route.noBreadcrumb)
                breadcrumbsArray.push(route)

            // Header Dropdown route
            const subRoute = route.subPermissionsRoutes.find((r) => location.pathname.startsWith(r.path))
            if(!subRoute.noBreadcrumb)
                breadcrumbsArray.push(subRoute)

            // Find breadcrumbs from "reports routes" (reports)
            if(subRoute.subPermissionsRoutes && Array.isArray(subRoute.subPermissionsRoutes) && subRoute.subPermissionsRoutes.find((r) => location.pathname.endsWith(r.path) && r.path)){
                const t = { ...subRoute.subPermissionsRoutes.find((r) => location.pathname.endsWith(r.path)) }
                t.path = location.pathname
                t.fullRoute = true
                t.noLink = true // bug present in reports breadcrumbs

                if(!t.noBreadcrumb)
                    breadcrumbsArray.push(t)

            // find breadcrumbs from subPermissionsRoutes, using indexOf instead of endsWith (added for setting -> org setting (1 level deeper than usual))
            }else if(subRoute.subPermissionsRoutes && Array.isArray(subRoute.subPermissionsRoutes) && subRoute.subPermissionsRoutes.find((r) => location.pathname.indexOf(r.path) !== -1 && r.path !== '')){
                const t = { ...subRoute.subPermissionsRoutes.find((r) => location.pathname.indexOf(r.path) !== -1 && r.path !== '') }
                t.fullRoute = true;
                t.path = location.pathname;

                if(!t.noBreadcrumb)
                    breadcrumbsArray.push(t)

                const subPermissionRoute = t.subPermissionsRoutes && Array.isArray(t.subPermissionsRoutes) ? t.subPermissionsRoutes.find((r) => location.pathname.indexOf(r.path) !== -1 && r.path !== '') || null : null
                if(subPermissionRoute){
                    const temp = { ...subPermissionRoute }
                    temp.fullRoute = true;
                    temp.path = location.pathname;

                    if(!temp.noBreadcrumb){
                        breadcrumbsArray.push(temp);
                    }
                }
            // Find breadcrumbs from "tab routes" (tabs)
            }else if(subRoute.tabRoutes && Array.isArray(subRoute.tabRoutes) && subRoute.tabRoutes.find((r) => location.pathname.endsWith(r.path) && r.path !== '')){
                const t = { ...subRoute.tabRoutes.find((r) => location.pathname.endsWith(r.path) && r.path !== '') }
                t.fullRoute = true;
                t.path = location.pathname;
                t.noLink = true;

                if(!t.noBreadcrumb)
                    breadcrumbsArray.push(t)
            }
        }else if(route.path && route.path === location.pathname && !route.noBreadcrumb){
            breadcrumbsArray.push(route);
        }
    })

    return breadcrumbsArray;
}

const Breadcrumbs = ({ className, favoriteData, withRefresh, extraBreadcrumbs = [], skipFavorite, disablePeriodSelect = false, ...props }) => {
    const location = useLocation();
    const breadcrumbs = getBreadcrumbs(location);
    const quickViewContext = useContext(QuickViewContext);

    return (
        <div className={className}>
            {/* hide the breadcrumbs and the alerts in quick view */}
            {!quickViewContext.inIframe &&
                <>
                    <PeriodWarning className={"p-0 mb-2"} disablePeriodSelect={disablePeriodSelect} />

                    {/*--------------------------------------------------------------------------------*/}
                    {/* Breadcrumbs mapping                                                          */}
                    {/*--------------------------------------------------------------------------------*/}
                    <div>
                        <Breadcrumb className="sid-breadcrumbs" listClassName='align-items-center'>
                            {/* Season dropdown */}
                            <BreadcrumbPeriodSelect disablePeriodSelect={disablePeriodSelect} />
                            <BreadCrumbsItem
                                breadcrumbs={[ { dummy: true } ]}
                                path="/dashboard"
                                crumb={{ name: 'sidebar.dashboard' }}
                                isLastLink={false}
                            />

                            {breadcrumbs.map((crumb, key) => {
                                const lastLink = key === breadcrumbs.length - 1;
                                let path = crumb.path;

                                if(!crumb.fullRoute && path && key > (breadcrumbs.length - 1)){
                                    path = location.pathname.split('/').slice(0, -1).join('/') + crumb.path;
                                }

                                return (
                                    <BreadCrumbsItem
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={crumb.path + crumb.name + key}
                                        crumbsLength={breadcrumbs.length + extraBreadcrumbs.length}
                                        path={path}
                                        crumb={crumb}
                                        isLastLink={lastLink}
                                    />
                                )
                            })}
                            {extraBreadcrumbs.map((crumb, key) => (
                                <BreadCrumbsItem
                                    key={`extra${crumb.path + crumb.name + key}`}
                                    crumbsLength={breadcrumbs.length + extraBreadcrumbs.length}
                                    path={crumb.path}
                                    crumb={crumb}
                                />
                            ))}
                            <li className="align-items-center d-flex">
                                <BreadcrumbsCopyBtn />
                                {withRefresh &&
                                    <RefreshButton buttonClassName='ml-2' />
                                }
                                {!skipFavorite &&
                                    <BreadcrumbsFavBtn breadcrumbs={breadcrumbs} favoriteData={favoriteData} />
                                }
                            </li>
                        </Breadcrumb>
                    </div>
                </>
            }
        </div>

    )
}

export default Breadcrumbs;