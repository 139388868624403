import { FormikCheckedButton, FormikGroup, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { FieldArray, useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Col, Label } from 'reactstrap';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { UtilsContext } from '../../../../contexts/UtilsContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';

/**
* @param {object} props
* @param {string} props.onlineStoreId
* @param {boolean} [props.isEditing]
* @param {boolean} [props.onlineStore]
* @param {boolean} [props.merchantAccountRef]
* @param {boolean} [props.merchantAccounts]
*/
const OnlineStorePaymentsOptions = (props) => {
    const utilsContext = useContext(UtilsContext);
    const formik = useFormikContext();

    // Lists of all corresponding items
    const [ paymentOptions, setPaymentOptions ] = useState([]);

    useEffect(() => {
        utilsContext.getPaymentMethods()
            .then(setPaymentOptions)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });
    }, [])

    return (
        <>
            <div className={props.isEditing ? undefined : 'd-none'}>
                <Label className='text-muted'><Translate id='onlineStore.components.onlineStorePaymentOptions.merchantAccount.title' /><small className='ml-1'>(<Translate id='onlineStore.components.onlineStorePaymentOptions.merchantAccount.title.helper' />)</small></Label>
                <FieldArray name='paymentOptions'>
                    {(paymentOptionsHelper) => (
                        <FormikSelect
                            clearable
                            name='merchantAccount'
                            id='merchantAccoutns_OnlineStorePaymentOptions'
                            ref={props.merchantAccountRef}
                            options={
                                props.merchantAccounts.map((merchant) => ({
                                    id: merchant.merchant_account_id,
                                    value: merchant.merchant_account_id,
                                    label: merchant.name,
                                }))
                            }
                            onOptionSelected={(merchantAccount) => {
                                if(merchantAccount[0]){ // Has a merchant account selected
                                    if(formik.values.paymentOptions.every((pM) => pM !== 'CREDITCARD'))// Verifying CREDITCARD is not already in the payment options
                                        paymentOptionsHelper.push('CREDITCARD');
                                }else{ // Does not have a merchant account selected
                                    paymentOptionsHelper.remove(formik.values.paymentOptions.findIndex((pM) => pM === 'CREDITCARD'));
                                }
                            }}
                        />
                    )}
                </FieldArray>

                <Label className='text-muted mt-3'><Translate id='onlineStore.components.onlineStorePaymentOptions.paymentMethod.title' /></Label>
                <FormikGroup name='paymentOptions'>
                    {paymentOptions.filter((pM) => pM.category == "MANUAL").map((option) => (
                        <Col sm='12' key={option.code} className='mb-2'>
                            <FormikCheckedButton
                                id={option.code}
                                name='paymentOptions'
                                value={option.code}
                                label={<DisplayI18n field="name" defaultValue={option.name} i18n={option.i18n} />}
                                translateLabel={false}
                            />
                        </Col>
                    ))}
                </FormikGroup>
            </div>
            {!props.isEditing &&
                <>
                    <div className='mb-3'>
                        <Label className='text-muted'><Translate id='onlineStore.components.onlineStorePaymentOptions.merchantAccount.title' /><small className='ml-1'>(<Translate id='onlineStore.components.onlineStorePaymentOptions.merchantAccount.title.helper' />)</small></Label>
                        <div>
                            {props.onlineStore?.merchantAccount?.name || <Translate id='onlineStore.components.onlineStorePaymentOptions.merchantAccount.empty' />}
                        </div>
                    </div>

                    <div>
                        <Label className='text-muted'><Translate id='onlineStore.components.onlineStorePaymentOptions.paymentMethod.title' /></Label>
                        <div>
                            <PaymentMethodLabel onlineStore={props.onlineStore} paymentOptions={paymentOptions} />
                        </div>
                    </div>
                </>
            }
        </>
    )
}

const PaymentMethodLabel = ({ paymentOptions, onlineStore }) => {
    const filteredPaymentMethods = paymentOptions.filter((option) => onlineStore?.paymentMethods?.findIndex((paymentMethod) => paymentMethod === option.code) !== -1 && option.category == "MANUAL");

    if(!filteredPaymentMethods.length){
        return '-'
    }

    return filteredPaymentMethods.map((option) => (
        <div key={option.code}>
            <i className='mdi mdi-check text-primary mr-1' />
            <DisplayI18n
                field="name"
                defaultValue={option.name}
                i18n={option.i18n}
            />
        </div>
    ))
}

export default OnlineStorePaymentsOptions;