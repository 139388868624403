export default{
    "teams.teamSearch.addTeam.btn"              : "Add Team",
    "teams.teamSearch.addTeam.btn.rollover"     : "Rollover",
    "teams.teamSearch.addTeam.btn.newTeam"      : "Create a team",
    "teams.teamSearch.addTeam.btn.batchCreate"  : "Create multiple teams",
    "teams.teamSearch.addTeam.btn.restrictions" : "Team Restrictions",

    "teams.teamSearch.addTeam.search.season.required"       : "Please specify a season.",
    "teams.teamSearch.addTeam.search.division.required"     : "Please select at least one division.",
    "teams.teamSearch.addTeam.search.data.warning"          : "Selecting multiple options can cause a loading longer than usual.",
    "teams.teamSearch.addTeam.search.organization.required" : "Please select at least one organization.",
    "teams.teamSearch.addTeam.search.organization.max"      : "Please select a maximum of 25 organizations.",

    "teams.teamSearch.addTeam.rollover.modal.title"       : "Import Team(s) from previous season",
    "teams.teamSearch.addTeam.rollover.selectedTeams"     : "Selected team(s)",
    "teams.teamSearch.addTeam.rollover.suggestedCategory" : "Suggested category",
    "teams.teamSearch.addTeam.rollover.inactiveCategory"  : "This category is inactive for the selected period",
    "teams.teamSearch.addTeam.rollover.goToCategories"    : "Go to categories",
    "teams.teamSearch.addTeam.rollover.success"           : "The {count, plural, =0 {team have} one {team have} other {teams have}} been successfully added.",
    "teams.teamSearch.addTeam.rollover.success.btn"       : "Got it!",

    "teams.teamSearch.addTeam.title"        : "Team Creation",
    "teams.teamSearch.addTeam.wizard.step1" : "General Info",
    "teams.teamSearch.addTeam.wizard.step2" : "Team Contact",
    "teams.teamSearch.addTeam.wizard.step3" : "Review",

    "teams.teamSearch.rollover.wizard.title"        : "Team Rollover",
    "teams.teamSearch.rollover.wizard.step1"        : "Select Teams",
    "teams.teamSearch.rollover.wizard.step2"        : "Review",
    "teams.teamSearch.rollover.step2.title"         : "Team(s) Review",
    "teams.teamSearch.rollover.step2.editAllMode"   : "Edit all teams",
    "teams.teamSearch.rollover.step2.category"      : "Category",
    "teams.teamSearch.rollover.step2.selectedTeams" : "Selected teams",

    "teams.teamSearch.rollover.step2.review.almostDone" : "You can review your select teams and choose their categories.",
    "teams.teamSearch.rollover.toast.success"           : "Team(s) successfully imported!",

    "teams.teamSearch.rollover.step2.form.categoryForAll.required" : "Please choose a category for these teams.",
    "teams.teamSearch.rollover.step2.form.category.required"       : "Please choose a category for this team.",
    "teams.teamSearch.rollover.step2.form.category.missing"        : "Please review the list, looks like some teams are missing categories.",
}