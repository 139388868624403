import { stringBuilder } from "@spordle/helpers";
import { NavLink } from "react-router-dom";
import FavoriteToggler from "../../../../../components/favoriteToggler/FavoriteToggler";
import { activeRoute } from "../../../../../helpers/navigationHelper";

const SidebarLink = ({
    tag: Tag = "li",
    skipFavorite,
    skipActive = false,
    onClick,
    to,
    isFavorite,
    className,
    linkClassName,
    isActive,
    children,
    secondaryPath,
    id,
    favoriteData,
}) => {
    const handleOnClick = () => {
        onClick?.();
    }

    return (
        <Tag id={id} className={stringBuilder({ "selected": !skipActive && (activeRoute(to) || activeRoute(secondaryPath)) }, 'sidebar-item', className)}>
            <NavLink
                isActive={isActive}
                onClick={handleOnClick}
                to={to}
                className={stringBuilder('sidebar-link', stringBuilder({ 'active': !skipActive && activeRoute(secondaryPath) }), linkClassName)}
                activeClassName={skipActive ? "" : "active"} // Leave empty string, do not put undefined since it's default value is active
            >
                {children}
                {!skipFavorite &&
                    <FavoriteToggler favoriteData={favoriteData} isFavorite={isFavorite} to={to} className="sidebar-link-star ml-auto" />
                }
            </NavLink>
        </Tag>
    );
}

export default SidebarLink;