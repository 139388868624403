import { useSpordleTable } from '@spordle/datatables';
import { FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useRef, useState } from 'react';
import { Button, Card, Col, FormGroup, Row } from 'reactstrap';
import { object, string } from 'yup';
import CrossFade from '../../../components/crossFade/CrossFade';
import useSavedSearch from '../../../components/customHooks/useSavedSearch';
import SavedSearchCue from '../../../components/savedSearchCue/SavedSearchCue';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { PeriodsContext } from '../../../contexts/contexts';
import { MembersContext } from '../../../contexts/MembersContext';
import { RegistrationDivisionsContext } from '../../../contexts/RegistrationDivisionsContext';

const RenewMembersFilters = ({ ...props }) => {
    const spordleTable = useSpordleTable();
    const divisionSelectRef = useRef();
    const membersContext = useContext(MembersContext);
    const registrationDivisionsContext = useContext(RegistrationDivisionsContext);
    const orgContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const periodsContext = useContext(PeriodsContext);
    const [ formikKey, setFormikKey ] = useState(0);

    const { savedSearch, clearSavedSearch, saveSearch } = useSavedSearch(`renew-members-search-filters-${identityRolesContext.identity_role_id}-${orgContext.organisation_id}-${periodsContext.selectedPeriod.period_id}`);

    const rerenderFormik = () => {
        clearSavedSearch();
        setFormikKey(formikKey + 1);
    }

    const originalValues = {
        memberTypeId: '',
        divisionId: '',
    }

    const getInitFilters = () => {
        const filters = savedSearch;
        if(filters){
            return {
                ...originalValues,
                ...filters,
            };
        }

        return originalValues;
    }

    return (
        <Formik
            key={formikKey}
            initialValues={getInitFilters()}
            validationSchema={object().shape({
                memberTypeId: string().required(<Translate id='task.renewMembers.memberType.required' />),
                divisionId: string().required(<Translate id='task.renewMembers.division.required' />),
            })}
            onSubmit={(values) => {
                spordleTable.filterChange('form', {
                    memberTypeId: values.memberTypeId,
                    divisionId: values.divisionId,
                })
                saveSearch(values)
            }}
        >
            {(formik) => (
                <Form>
                    <Card body className='card-shadow'>
                        <Row form className='align-items-start'>
                            <Col md='4'>
                                <FormGroup className='mb-md-0'>
                                    <FormikSelect
                                        name='memberTypeId'
                                        id='memberTypeId'
                                        placeholder="task.renewMembers.memberType"
                                        renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return membersContext.getMemberTypes(orgContext.organisation_id)
                                                        .then((memberTypes) => {
                                                            return memberTypes.reduce((newArray, memberType) => {
                                                                if(memberType.active == 1){
                                                                    newArray.push({
                                                                        value: memberType.member_type_id,
                                                                        label: memberType.name,
                                                                        i18n: memberType.i18n,
                                                                    })
                                                                }
                                                                return newArray
                                                            }, [])
                                                        })
                                            }
                                        }}
                                        onOptionSelected={(values) => {
                                            divisionSelectRef.current?.getSpordleTable().filterChange('memberTypeId', values[0])
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <FormGroup className='mb-md-0'>
                                    <FormikSelect
                                        ref={divisionSelectRef}
                                        name='divisionId'
                                        id='divisionId'
                                        placeholder="task.renewMembers.division"
                                        renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                        disabled={!formik.values.memberTypeId}
                                        initFilter={{
                                            memberTypeId: formik.values.memberTypeId,
                                        }}
                                        loadData={(from, extra) => {
                                            switch (from){
                                                case 'FILTER':
                                                case 'CDM':
                                                    if(extra.filters.memberTypeId){
                                                        return registrationDivisionsContext.getRegistrationDivisions(orgContext.organisation_id, {
                                                            member_type_id: extra.filters.memberTypeId,
                                                        })
                                                            .then((divisions) => {
                                                                return divisions.reduce((newArray, division) => {
                                                                    if(division.active == 1){
                                                                        newArray.push({
                                                                            value: division.division_id,
                                                                            label: division.name,
                                                                            i18n: division.i18n,
                                                                        })
                                                                    }
                                                                    return newArray
                                                                }, [])
                                                            })
                                                    }
                                                    return Promise.resolve([]);
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md='4'>
                                <Button
                                    color='primary'
                                    type='submit'
                                    className='w-100'
                                >
                                    <Translate id='misc.search' />
                                </Button>
                            </Col>
                        </Row>
                        <div className="w-100 d-flex flex-wrap flex-md-nowrap justify-content-end">
                            <SavedSearchCue className="mr-auto order-1 order-md-0 w-100 w-md-auto pt-3" savedSearch={savedSearch} clearSavedSearch={rerenderFormik} />
                            <CrossFade isVisible={JSON.stringify(originalValues) !== JSON.stringify(formik.values)}>
                                <button type='button' color='primary' className='reset-btn ml-2 text-danger font-12 mt-3' onClick={rerenderFormik}>
                                    <Translate id='members.search.memberAdvanceSearch.resetFilters' />
                                </button>
                            </CrossFade>
                        </div>
                    </Card>
                </Form>
            )}
        </Formik>
    )
}

export default RenewMembersFilters