import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Col, FormGroup, Row } from "reactstrap";
import { object, string } from 'yup';
import FormikEditable from "../../../../../../components/formik/FormikEditable";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { EMPLOYED_OPTIONS, YES_NO_DATA } from "../../InsuranceHelper";
import { Tooltip } from "@mantine/core";
const InsuranceSidePanelInfo = ({
    injuryDataLists,
    toggleChequeModal,
    updateInjury,
    ...props
}) => {
    const { payTos, provinces } = injuryDataLists;
    const { getGenericLocale } = useContext(I18nContext);
    const { canEdit, injury } = props;

    const isEmployed = (employed) => {
        return [ "EMPLOYED", "EMPLOYED_PART_TIME" ].includes(employed);
    }

    return (
        <>
            <div className="h5 font-bold">
                <Translate id='insurance.label.insuranceInfo' />
            </div>
            <FormGroup>
                <Row className="w-100" form>
                    <Col sm="6">
                        <div className="text-muted">
                            <Translate id='insurance.label.employed' />
                        </div>
                    </Col>
                    <Col sm="6">
                        <div className="text-muted">
                            <Translate id='insurance.label.employer' />
                        </div>
                    </Col>
                </Row>
                <FormikEditable
                    id="edit-employment"
                    manualIcon
                    disabled={!canEdit}
                    initialValues={{
                        employed: injury.employed || '',
                        employer_name: injury.employer_name || '',
                    }}
                    onSubmit={(values) => {
                        if(values.employed !== injury.employed || values.employer_name !== injury.employer_name){
                            const newVal = {
                                employed: values.employed,
                                employer_name: !isEmployed(values.employed) ? '' : values.employer_name,
                            };

                            updateInjury(newVal, newVal);
                        }
                    }}
                >
                    {(isEditing, _options, formik) => {
                        if(!isEditing){
                            return (
                                <Row className="w-100 position-relative" form>
                                    <Col sm="6">
                                        {injury.employed ? <Translate id={'insurance.label.employedOptions.' + injury.employed} /> : '-'}
                                    </Col>
                                    <Col sm="6">
                                        {(isEmployed(injury.employed) && !!injury.employer_name) ?
                                            injury.employer_name
                                            :
                                            '-'
                                        }
                                        {canEdit && <i className="mdi mdi-pencil text-primary ml-2 position-absolute top-0 right-0" />}
                                    </Col>
                                </Row>
                            );
                        }
                        return (
                            <Row className="w-100" form>
                                <Col sm="6">
                                    <FormikSelect
                                        name="employed"
                                        id="employed"
                                        defaultData={EMPLOYED_OPTIONS.map((option) => ({
                                            label: 'insurance.label.employedOptions.' + option,
                                            value: option,
                                            translateLabel: true,
                                        }))}
                                        loadingStatus="success"
                                    />
                                </Col>
                                <Col sm="6">
                                    <FormikInputText trim disabled={!isEmployed(formik.values.employed)} name="employer_name" id="employer_name" />
                                </Col>
                            </Row>
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <Row form>
                <Col sm="6" className="form-group">
                    <div className="text-muted">
                        <Translate id='insurance.label.provincialCoverage' />
                    </div>
                    <FormikEditable
                        id="edit-provincial_coverage"
                        noConfirmation
                        disabled={!canEdit}
                        initialValues={{
                            provincial_coverage: injury.provincial_coverage || '',
                        }}
                        onSubmit={(values) => {
                            if(values.provincial_coverage !== injury.provincial_coverage){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    injury.provincial_coverage ?
                                        <Translate id={`misc.${injury.provincial_coverage?.toLowerCase()}`} />
                                        :
                                        '-'
                                );
                            }
                            return (
                                <FormikSelect
                                    name="provincial_coverage"
                                    id="provincial_coverage"
                                    onOptionSelected={options.stopEditing}
                                    menuIsDefaultOpen
                                    autoFocus
                                    search={false}
                                    loadingStatus='success'
                                    defaultData={YES_NO_DATA}
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="6" className="form-group">
                    <div className="text-muted">
                        <Translate id='insurance.label.healthProvince' />
                    </div>
                    <FormikEditable
                        id="edit-health_insurance_number"
                        disabled={!canEdit}
                        noConfirmation
                        initialValues={{
                            health_insurance_province_id: injury.health_insurance_province_id || '',
                        }}
                        onSubmit={(values) => {
                            if(values.health_insurance_province_id !== injury.health_insurance_province_id){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                const province = injury.health_insurance_province_id ? provinces.find((p) => p.code == injury.health_insurance_province_id) : null;

                                return (
                                    province ?
                                        <DisplayI18n
                                            field="name"
                                            defaultValue={injury.health_insurance_province_id}
                                            i18n={province.i18n}
                                        />
                                        :
                                        '-'
                                )
                            }
                            return (
                                <FormikSelect
                                    clearable
                                    name="health_insurance_province_id"
                                    id="health_insurance_province_id"
                                    renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                    searchKeys={[
                                        `i18n.${getGenericLocale()}.name`,
                                    ]}
                                    autoFocus
                                    menuIsDefaultOpen
                                    onOptionSelected={options.stopEditing}
                                    options={
                                        provinces ?
                                            provinces.map((p) => ({
                                                label: p.code,
                                                value: p.code,
                                                i18n: p.i18n,
                                            }))
                                            :
                                            []
                                    }
                                />
                            )


                        }}
                    </FormikEditable>
                </Col>
                <Col sm="6" className="form-group">
                    <div className="text-muted"><Translate id='insurance.label.otherInsurance' /></div>
                    <FormikEditable
                        id="edit-other_insurance"
                        noConfirmation
                        disabled={!canEdit}
                        initialValues={{
                            other_insurance: injury.other_insurance || '',
                        }}
                        onSubmit={(values) => {
                            if(values.other_insurance !== injury.other_insurance){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (injury.other_insurance ? <Translate id={`misc.${injury.other_insurance.toLowerCase()}`} /> : '-');
                            }
                            return (
                                <FormikSelect
                                    name="other_insurance"
                                    id="other_insurance"
                                    menuIsDefaultOpen
                                    autoFocus
                                    search={false}
                                    onOptionSelected={options.stopEditing}
                                    defaultData={YES_NO_DATA}
                                    loadingStatus='success'
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="6" className="form-group">
                    <div className="text-muted">
                        <Translate id='insurance.label.submitted' />
                    </div>
                    <FormikEditable
                        id="edit-submitted"
                        disabled={!canEdit}
                        noConfirmation
                        initialValues={{
                            submitted: injury.submitted || '',
                        }}
                        onSubmit={(values) => {
                            if(values.submitted !== injury.submitted){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (injury.submitted ? <Translate id={`misc.${injury.submitted.toLowerCase()}`} /> : '-');
                            }
                            return (
                                <FormikSelect
                                    name="submitted"
                                    id="submitted"
                                    menuIsDefaultOpen
                                    autoFocus
                                    search={false}
                                    onOptionSelected={options.stopEditing}
                                    defaultData={YES_NO_DATA}
                                    loadingStatus='success'
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="6" className="form-group">
                    <div className="text-muted">
                        <Translate id='insurance.label.payTo' />
                    </div>
                    <FormikEditable
                        id="edit-injury_pay_to_id"
                        disabled={!canEdit || !payTos}
                        noConfirmation
                        initialValues={{
                            injury_pay_to_id: injury.injury_pay_to?.injury_pay_to_id || '',
                            injury_pay_to_other: injury.injury_pay_to_other || '',
                        }}
                        validationSchema={object().shape({
                            injury_pay_to_id: string(),
                            injury_pay_to_other: string(),
                        })}
                        onSubmit={(values) => {
                            if(values.injury_pay_to_id !== injury.injury_pay_to?.injury_pay_to_id){
                                updateInjury(values, {
                                    injury_pay_to: payTos.find((pT) => pT.injury_pay_to_id === values.injury_pay_to_id),
                                    injury_pay_to_other: values.injury_pay_to_other,
                                });
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (
                                    injury.injury_pay_to ?
                                        <DisplayI18n
                                            field="name"
                                            i18n={injury.injury_pay_to.i18n}
                                            defaultValue={injury.injury_pay_to.name}
                                        />
                                        :
                                        "-"
                                )
                            }
                            return (
                                <FormikSelect
                                    name="injury_pay_to_id"
                                    id="injury_pay_to_id"
                                    clearable
                                    onOptionSelected={options.stopEditing}
                                    menuIsDefaultOpen
                                    autoFocus
                                    renderOption={(opt) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={opt.option.i18n}
                                            defaultValue={opt.option.label}
                                        />
                                    )}
                                    defaultData={payTos.map((to) => ({
                                        label: to.name,
                                        i18n: to.i18n,
                                        value: to.injury_pay_to_id,
                                    }))}
                                    loadingStatus='success'
                                />
                            )

                        }}
                    </FormikEditable>
                </Col>
                <Col sm="6" className="form-group">
                    <div className="text-muted d-flex">
                        <Translate id='insurance.search.accidentDate.approved.label' />
                        <Tooltip zIndex={3000} withArrow label={<Translate id='insurance.sidepanel.approved.tooltip' />}>
                            <i className="ml-1 mdi mdi-information-outline text-primary" />
                        </Tooltip>
                    </div>
                    <FormikEditable
                        id="edit-approved"
                        disabled={!canEdit}
                        noConfirmation
                        initialValues={{
                            approved: injury.approved,
                        }}
                        onSubmit={(values) => {
                            if(values.approved !== injury.approved){
                                updateInjury(values, values);
                            }
                        }}
                    >
                        {(isEditing, options) => {
                            if(!isEditing){
                                return (injury.approved === '1' ? <i className="mdi mdi-check text-primary" /> : <i className="text-danger mdi mdi-close" />);
                            }
                            return (
                                <FormikSelect
                                    name='approved'
                                    id='approved-sidepanel'
                                    translateLabel
                                    options={[
                                        { id: 'apprrovedOption', label: 'misc.yes', value: '1', translateLabel: true },
                                        { id: 'notApprovedOption', label: 'misc.no', value: '0', translateLabel: true },
                                    ]}
                                />

                            )

                        }}
                    </FormikEditable>
                </Col>
            </Row>
        </>
    )
}

export default InsuranceSidePanelInfo;