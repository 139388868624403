import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    Button, Card,
    CardBody
} from "reactstrap";
import CardSectionTitle from '../../../../components/CardSectionTitle';
import CanDoAction from '../../../../components/permissions/CanDoAction';
import SpordlePanelTable from '../../../../components/sidePanel/SpordlePanel';
import EmptyLayout from '../../../../components/table/EmptyLayout';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import { I18nContext } from '../../../../contexts/I18nContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
// Contexts
import { RegistrationClassesContext } from '../../../../contexts/RegistrationClassesContext';
import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext } from '../../../../helpers/i18nHelper';
import SettingsRegistrationClassAddModal from './SettingsRegistrationClassAddModal';
import SettingsRegistrationClassSidepanel from './SettingsRegistrationClassSidepanel';

const SettingsRegistrationClass = () => {
    const [ modalAdd, setModalAdd ] = useState(false);
    const toggleModalAdd = () => setModalAdd(!modalAdd);
    const classesContext = useContext(RegistrationClassesContext);
    const organizationContext = useContext(OrganizationContext);
    const i18nContext = useContext(I18nContext);

    return (
        <I18nHelperContextProvider fields={[ 'name', 'short_name' ]}>
            <I18nHelperContext.Consumer>
                {(i18nHelper) => (
                    <Card className="card-shadow">
                        <CardBody>
                            <CardSectionTitle title='registration.settings.category.label.class' />
                            <SpordlePanelTable
                                allowOutsideClick
                                dataIndex='class_id'
                                sidePanel={(props) => <SettingsRegistrationClassSidepanel {...props} />}
                                table={(panelProps) => {
                                    return (
                                        <SpordleTableProvider
                                            id='SettingsRegistrationClassTable'
                                            tableHover bordered striped
                                            clickable responsive
                                            ref={panelProps.spordleTableRef}
                                            desktopWhen='sm'
                                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                            emptyLayout={<EmptyLayout />}
                                            dataIndex='class_id'
                                            defaultSorting='+display_order'
                                            searchKeys={[
                                                `i18n.${i18nContext.getGenericLocale()}.name`,
                                                `i18n.${i18nContext.getGenericLocale()}.short_name`,
                                                'name',
                                                'short_name',
                                            ]}
                                            loadData={(from, filterData, spordleTable) => {
                                                switch (from){
                                                    case 'REFRESH':
                                                        spordleTable.setLoading(true);
                                                    case 'CDM':
                                                        return classesContext.getRegistrationClasses(organizationContext.organisation_id)
                                                    default:
                                                        break;
                                                }
                                            }}
                                            columns={[
                                                {
                                                    label: <Translate id='registration.settings.class.form.label.order' />,
                                                    key: 'display_order',
                                                    className: 'text-center',
                                                    dataClassName: 'text-center',
                                                    sortable: true,
                                                },
                                                {
                                                    label: <Translate id='registration.settings.category.label.organization' />,
                                                    key: 'organisation',
                                                    sortable: true,
                                                },
                                                ...i18nHelper.getTableColumns('name'),
                                                {
                                                    label: <Translate id='misc.status' />,
                                                    key: 'active',
                                                    className: 'text-center',
                                                    dataClassName: 'text-center',
                                                    sortable: true,
                                                },
                                            ]}
                                            renderRow={(columnKey, data) => {
                                                if(columnKey?.includes('i18n')){
                                                    const lang = columnKey.substring(0, columnKey.lastIndexOf('.'));

                                                    return (
                                                        <UserDisplay>
                                                            <UserDisplay.Container>
                                                                <UserDisplay.Title>
                                                                    <DisplayI18n
                                                                        field={`${lang}.short_name`}
                                                                        defaultValue={data.short_name}
                                                                        i18n={data.i18n}
                                                                    />
                                                                </UserDisplay.Title>
                                                                <UserDisplay.Title className="text-muted">
                                                                    <DisplayI18n
                                                                        field={columnKey}
                                                                        defaultValue={data.name}
                                                                        i18n={data.i18n}
                                                                    />
                                                                </UserDisplay.Title>
                                                            </UserDisplay.Container>
                                                        </UserDisplay>
                                                    )
                                                }
                                                switch (columnKey){
                                                    case 'organisation':
                                                        return (
                                                            <div className={`small${data.organisation.organisation_id === organizationContext.organisation_id ? ' text-dark font-medium' : ' text-muted'}`}>
                                                                <DisplayI18n
                                                                    field='name'
                                                                    defaultValue={data.organisation.organisation_name}
                                                                    i18n={data.organisation.i18n}
                                                                />
                                                            </div>
                                                        )
                                                    case 'display_order':
                                                        return data.display_order ?? '-'
                                                    case 'active':
                                                        return data.active === "1" ? <i className="text-primary mdi mdi-check" /> : <i className="text-danger mdi mdi-close" />;
                                                    default:
                                                        break;
                                                }
                                            }}
                                            onColumnClick={(e, data) => {
                                                switch (e.button){
                                                    case 0: // Left mouse button
                                                        panelProps.onSingleSelectChange(data);
                                                        break;
                                                }
                                            }}
                                            rowIsHighlighted={(data) => data.checked}
                                        >
                                            <div className='mb-2'>
                                                <div className='d-flex justify-content-between'>
                                                    <SpordleTableProvider.SearchInput />
                                                    <div className='d-flex ml-auto text-right nowrap'>
                                                        <SpordleTableProvider.Refresh />
                                                        <CanDoAction action='ADD' componentCode='teams' componentPermissionCode='team_classes'>
                                                            <Button className='ml-2' color="primary" onClick={toggleModalAdd}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                            <SettingsRegistrationClassAddModal isOpen={modalAdd} toggle={toggleModalAdd} />
                                                        </CanDoAction>
                                                    </div>
                                                </div>
                                            </div>
                                            <SpordleTableView />
                                        </SpordleTableProvider>
                                    )
                                }}
                            />
                        </CardBody>
                    </Card>
                )}
            </I18nHelperContext.Consumer>
        </I18nHelperContextProvider>
    );
}

export default SettingsRegistrationClass;