import Translate from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    ListGroup,
    ListGroupItem,
    Label,
    Collapse,
    Alert
} from "reactstrap";
import { Field, Form, Formik } from 'formik';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { FormikSelect, FormikError, FormikInputText } from '@spordle/formik-elements';
import CardSectionTitle from '../../../../components/CardSectionTitle';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import {
    object,
    string,
    number,
    array
} from 'yup';
import { displayI18n, DisplayI18n } from '../../../../helpers/i18nHelper';
import { PositionsContext } from '../../../../contexts/PositionsContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { TeamsContext } from '../../../../contexts/TeamsContext';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import { fail, success } from '@spordle/toasts';
import useSWR from 'swr';
import { I18nContext } from '../../../../contexts/I18nContext';

const TeamRosterEdit = ({ ...props }) => {

    const positionsContext = useContext(PositionsContext);
    const organizationContext = useContext(OrganizationContext);
    const teamsContext = useContext(TeamsContext);
    const { getGenericLocale } = useContext(I18nContext);

    const history = useHistory();
    const [ formikState, setFormikState ] = useState(false)
    const rerenderFormik = () => setFormikState(!formikState)

    const [ positions, setPositions ] = useState([])
    const [ positionsLoading, setPositionsLoading ] = useState(false);

    const { data: teamsSettings } = useSWR( // Get team settings should be placed once in the team profile. Could be placed with the cachedTeam also to be more optimal. Right now there are too many useSWR for team settings accross child components of the team profile.
        [ 'getTeamsSettings', teamsContext.cachedTeam.team_id ],
        () => !teamsContext.cachedTeam.team_id ? null : teamsContext.getTeamSettings(teamsContext.cachedTeam.team_id)
            .then((settings) => {
                const filteredSettings = {}
                for(const key in settings){
                    if(Object.hasOwnProperty.call(settings, key)){
                        if(settings[key].active == '1' || key === 'qualification_rules'){
                            filteredSettings[key] = settings[key];
                        }
                    }
                }
                return filteredSettings
            })
            .then((settings) => {
                if(settings.qualification_rules && settings.qualification_rules.length > 0){
                    return {
                        ...settings,
                        qualification_rules: settings.qualification_rules?.map((position) => ({
                            ...position,
                            ...position.qualifications.reduce((object, qualification) => {
                                if(qualification.equivalence && qualification.equivalence.length > 0){
                                    object.oneMandatory.push([
                                        qualification,
                                        ...qualification.equivalence.map((eq) => ({
                                            ...qualification,
                                            ...eq,
                                        })),
                                    ]) // we push an array in an array because there could be multiple "one mandatory" sets for 1 position
                                }else{
                                    object.allMandatory.push(qualification)
                                }
                                return object
                            }, { allMandatory: [], oneMandatory: [] }),
                        })),
                    }
                }
                return settings;

            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }),
        {
            revalidateOnFocus: false,
            fallbackData: {},
            revalidateOnMount: true,
        },
    );

    const validateJerseyNumber = (players) => { // players comes from formik values
        // Skip validation when setting is not "yes"
        if(!(teamsSettings?.unique_jersey_numbers?.value === "yes" && teamsSettings?.unique_jersey_numbers?.active == "1" && teamsSettings?.unique_jersey_numbers?.enforced == "1"))
            return false

        let hasDup = false
        players.forEach((player) => {
            if(!hasDup)
                hasDup = players.some((teamMember) => player.team_member_id !== teamMember.team_member_id && teamMember.homeNumber && teamMember.homeNumber === player.homeNumber)
        });
        return hasDup;
    }

    const showError = (code) => {
        switch (code){
            case '3371':// Team setting related error
            case '3372':
            case '3373':
            case '3374':
            case '3375':
            case '3376':
            case '3380':
            case '3438':
            case '3494':
                fail({ info: <Translate id={`teams.profile.settings.error.${code}`} />, skipInfoTranslate: true });
                break;
            default:
                fail();
                break;
        }
    }

    useEffect(() => {
        setPositionsLoading(true)
        positionsContext.getPositions(organizationContext.organisation_id)
            .then((positions) => {
                setPositions(positions.filter((p) => p.active == 1 && p.position_group?.position_type === 'PLAYER'))
                setPositionsLoading(false)
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setPositions([])
                    setPositionsLoading(false)
                }
            })
    }, [])

    useEffect(() => {
        rerenderFormik()
        // use case :
        // user changes player number from sidepanel
        // user clicks edit roster
        // we still display old number here because the data hasn't updated yet
        // so we do a getRosterData when the user clicks the button to come here
        // and when the data changes, we rerender formik to display the proper number
    }, [ teamsContext.rosterTablesData ])


    const displayListItem = (teamMember, key, formik) => {
        return (
            <ListGroupItem key={teamMember.team_member_id}>
                <Row form className="align-items-center">
                    <Col xs="5">
                        <UserDisplay>
                            <UserDisplay.Container>
                                <UserImg
                                    abbr={teamMember.member.first_name.charAt(0) + teamMember.member.last_name.charAt(0)}
                                    src={teamMember.member.picture?.full_path}
                                    filePos={teamMember.member.picture?.file_position}
                                    width={40}
                                    height={40}
                                    alt={teamMember.member.first_name + ' ' + teamMember.member.last_name}
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container>
                                <UserDisplay.Title>{teamMember.member.first_name + ' ' + teamMember.member.last_name}</UserDisplay.Title>
                                <UserDisplay.Subtitle>#{teamMember.member.unique_identifier}</UserDisplay.Subtitle>
                            </UserDisplay.Container>
                        </UserDisplay>
                    </Col>
                    <Col xs='3'>
                        <Label for={`players.${key}.positionId`} className='text-muted'><Translate id='teams.profile.roster.table.position' /></Label>
                        <FormikSelect
                            id={`players.${key}.positionId`}
                            name={`players.${key}.positionId`}
                            isLoading={positionsLoading}
                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                            disabled={teamMember.active == 0}
                            manualError
                            searchKeys={[
                                `i18n.${getGenericLocale()}.name`,
                            ]}
                            options={positions.map((pos) => ({
                                i18n: pos.i18n,
                                label: pos.name,
                                value: pos.position_id,
                            }))}
                        />
                    </Col>
                    <Col xs="2">
                        <Label for={`players.${key}.homeNumber`} className='text-muted'><Translate id='teams.profile.teamRosterEdit.homeNumber' /></Label>
                        <FormikInputText
                            autoComplete="new-password"
                            id={`players.${key}.homeNumber`} name={`players.${key}.homeNumber`}
                            manualError
                        />
                    </Col>
                    <Col xs="2">
                        <Label for={`players.${key}.awayNumber`} className='text-muted'><Translate id='teams.profile.teamRosterEdit.awayNumber' /></Label>
                        <FormikInputText
                            autoComplete="new-password"
                            id={`players.${key}.awayNumber`} name={`players.${key}.awayNumber`}
                            placeholder={formik.values.players[key].homeNumber}
                            translatePlaceholder={false}
                            manualError
                        />
                    </Col>
                </Row>
                {/* so if there is an error in the fields it doesn't shift the input up and break the layout */}
                {!formik.isValid &&
                    <Row form className="align-items-center">
                        <Col xs="5" />
                        <Col xs='3'>
                            <FormikError name={`players.${key}.positionId`} />
                        </Col>
                        <Col xs="2">
                            <FormikError name={`players.${key}.homeNumber`} />
                        </Col>
                        <Col xs="2">
                            <FormikError name={`players.${key}.awayNumber`} />
                        </Col>
                    </Row>
                }
            </ListGroupItem>
        )
    }

    return (
        <div className='pb-5'>
            <Card className="card-shadow mb-5">
                <Formik
                    key={formikState}
                    initialValues={{
                        players: teamsContext.rosterTablesData.filter((player) => player.position?.position_group?.position_type === 'PLAYER').map((player) => ({
                            positionId: player.position.position_id,
                            homeNumber: player.jersey_number,
                            awayNumber: player.jersey_number_alternate,
                            ...player,
                        })),
                    }}
                    validationSchema={object().shape({
                        players: array().of(object().shape({
                            positionId: string().required(<Translate id='teams.profile.roster.table.position.required' />),
                            homeNumber: number().typeError(<Translate id="form.validation.number" />).
                                test({
                                    name: 'requirePositive',
                                    message: <Translate id="form.validation.number.positive" />,
                                    test: (number) => {
                                        const isMinusZero = (value) => (1 / value === -Infinity)
                                        if(number === '' || number === undefined)return true // let empty value pass
                                        return parseInt(number) >= 0 && !isMinusZero(number) && Math.sign(number).toString() !== "-1" // prevent -0
                                    },
                                }),
                            awayNumber: number().typeError(<Translate id="form.validation.number" />).
                                test({
                                    name: 'requirePositive',
                                    message: <Translate id="form.validation.number.positive" />,
                                    test: (number) => {
                                        const isMinusZero = (value) => (1 / value === -Infinity)
                                        if(number === '' || number === undefined)return true // let empty value pass
                                        return parseInt(number) >= 0 && !isMinusZero(number) && Math.sign(number).toString() !== "-1" // prevent -0
                                    },
                                }),
                        }))
                            .test({
                                name: 'uniqueJerseyNumber',
                                message: 'teams.profile.roster.sidePanel.jerseyNumbers.error.unique',
                                test: (players) => {
                                    return !validateJerseyNumber(players)
                                },
                            }),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        const newValues = values.players.reduce((newArray, player) => {
                            newArray.push({
                                team_member_id: player.team_member_id,
                                position_id: player.positionId,
                                jersey_number: player.homeNumber,
                                jersey_number_alternate: player.awayNumber,
                            })
                            return newArray
                        }, [])
                        teamsContext.updateTeamMembersBatch(teamsContext.cachedTeam.team_id, newValues)
                            .then(async() => {
                                await teamsContext.getRosterTablesData(teamsContext.cachedTeam.team_id, null, true); // refresh the roster
                                history.push(`/teams/profile/${teamsContext.cachedTeam.team_id}/roster`)
                                success();
                            }).catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    showError(displayI18n('message', error.i18n, error.message, getGenericLocale()));
                                    setSubmitting(false);
                                }
                            })
                    }}
                >
                    {(formik) => (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting || teamsContext.rosterTablesLoading}>
                                {/* ACTIVE ROSTER */}
                                <CardBody>
                                    <CardSectionTitle title="teams.profile.roster.table.activeRoster.title" />

                                    <ListGroup>
                                        {(formik.values.players && Array.isArray(formik.values.players)) &&
                                            <>
                                                {formik.values.players.reduce((newArray, teamMember, key) => {
                                                    if(teamMember.active == 1 && teamMember.affiliate == 0){
                                                        newArray.push(displayListItem(teamMember, key, formik))
                                                    }
                                                    return newArray
                                                }, [])}
                                            </>
                                        }
                                    </ListGroup>
                                </CardBody>

                                {/* AFFILIATED ROSTER */}
                                <CardBody>
                                    <CardSectionTitle title="teams.profile.roster.table.affiliated.title" />

                                    <ListGroup>
                                        {(formik.values.players && Array.isArray(formik.values.players)) &&
                                            <>
                                                {formik.values.players.reduce((newArray, teamMember, key) => {
                                                    if(teamMember.active == 1 && teamMember.affiliate == 1){
                                                        newArray.push(displayListItem(teamMember, key, formik))
                                                    }
                                                    return newArray
                                                }, [])}
                                            </>
                                        }
                                    </ListGroup>
                                </CardBody>

                                {/* INACTIVE ROSTER */}
                                <CardBody>
                                    <CardSectionTitle title="teams.profile.roster.table.inactiveRoster.title" />

                                    <ListGroup>
                                        {(formik.values.players && Array.isArray(formik.values.players)) &&
                                            <>
                                                {formik.values.players.reduce((newArray, teamMember, key) => {
                                                    if(teamMember.active == 0){
                                                        newArray.push(displayListItem(teamMember, key, formik))
                                                    }
                                                    return newArray
                                                }, [])}
                                            </>
                                        }
                                    </ListGroup>

                                    <Field
                                        name='players'
                                        component={({ field, form: { touched, errors } }) => (
                                            <Collapse isOpen={!!(touched[field.name] && errors[field.name] && typeof errors[field.name] === 'string')} className="mt-3">
                                                {(touched[field.name] && errors[field.name] && typeof errors[field.name] === 'string') &&
                                                    <Alert color='danger' toggle={() => formik.setFieldError("players", "")}>
                                                        <Translate id={errors[field.name]} />
                                                    </Alert>
                                                }
                                            </Collapse>
                                        )}
                                    />

                                    <div className='d-flex justify-content-end mt-3'>
                                        <Button color='primary' type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                    </div>
                                </CardBody>
                            </OverlayLoader>
                        </Form>
                    )}
                </Formik>
            </Card>
        </div>
    );
}

export default TeamRosterEdit;