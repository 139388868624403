export default{
    "members.profile.overview.memberDocuments.title"                : "Documents",
    "members.profile.overview.memberDocuments.modal.title"          : "Ajouter des documents",
    "members.profile.overview.memberDocuments.sidePanel.delete.msg" : "Vous êtes sur le point de supprimer ce document",

    "members.profile.overview.memberDocuments.form.label.document"                   : "Document",
    "members.profile.overview.memberDocuments.form.label.documentName"               : "Nom du document",
    "members.profile.overview.memberDocuments.form.label.documentType"               : "Type de document",
    "members.profile.overview.memberDocuments.form.label.review_status"              : "Statut d'approbation",
    "members.profile.overview.memberDocuments.form.label.expiration_date"            : "Date d'expiration",
    "members.profile.overview.memberDocuments.form.label.note"                       : "Note",
    "members.profile.overview.memberDocuments.form.document_type.note.required"      : "Une note expliquant le document est nécessaire pour définir un document de type « Autre ».",
    "members.profile.overview.memberDocuments.form.validation.documentType.required" : "Le type de document est requis",
    "members.profile.overview.memberDocuments.form.validation.note.required"         : "Veuillez mettre une note",

    "members.profile.overview.memberDocuments.empty.helper" : "Vous pouvez ajouter un document",
    "members.profile.overview.memberDocuments.add.btn"      : "Ajouter un document",
}