import { FormikCheckedButton, FormikError, FormikInputNumber, FormikInputText, FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import {
    Button,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    ModalFooter,
    Row,
    Col,
    Alert,
    Collapse
} from "reactstrap";
import * as Yup from 'yup';
import OverlayLoader from '../../../../components/loading/OverlayLoader';

// CONTEXTS
import { SpordleTableContext } from '@spordle/datatables';
import { I18nContext } from '../../../../contexts/I18nContext';
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';
import { OtherFeesContext } from '../../../../contexts/OtherFeesContext';
import ImgUpload, { defaultDrop } from '../../../../components/uploader/imgUpload/ImgUpload';
import Required from '../../../../components/formik/Required';
import { bytesToMb } from '../../../../components/uploader/uploadHelpers';
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { fail, success } from '@spordle/toasts';
import { UtilsContext } from '../../../../contexts/UtilsContext';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';

const OtherItemsAdd = ({ isOpen, toggle }) => (
    <AnalyticsModal analyticsName='OtherItemsAdd' isOpen={isOpen} unmountOnClose>
        <OtherItemsAddInner toggle={toggle} />
    </AnalyticsModal>
)

const OtherItemsAddInner = ({ toggle }) => {
    const i18nHelper = useContext(I18nHelperContext);
    const spordleTable = useContext(SpordleTableContext);
    const otherFeesContext = useContext(OtherFeesContext);
    const utilsContext = useContext(UtilsContext);
    const [ file, setFile ] = useState(null);

    const handleError = (err, setStatus) => {
        switch (err[0].errors[0].code){
            case "too-many-files":
                setStatus({ error: { msg: 'components.uploader.imgUpload.error.tooMany', show: true } });
                break;
            case 'file-too-large':
                setStatus({ error: {
                    msg: 'components.uploader.imgUpload.error.tooLarge',
                    values: { maxSize: bytesToMb(defaultDrop.maxSize) },
                    show: true,
                } });
                break;
            case 'file-invalid-type':
                setStatus({ error: {
                    msg: 'components.uploader.imgUpload.error.fileType',
                    values: { accepted: `${defaultDrop.accept}`?.replace(/image\//gi, ".") },
                    show: true,
                } });
                break;
            default:
                setStatus({ error: { msg: 'misc.error', show: true } });
                break;
        }
    }

    return (
        <Formik
            initialValues={{
                ...i18nHelper.getInitialValues(),
                amount: 0,
                taxable: false,
                sku: '',
            }}
            validationSchema={Yup.object().shape({
                ...i18nHelper.getValidationSchema({ name: Yup.string().required(<Translate id='catalog.otherItems.otherItemsAdd.form.validation.name' />) }),
                amount: Yup.number().required(<Translate id='catalog.otherItems.otherItemsAdd.form.validation.amount' />),
                taxable: Yup.bool(),
                sku: Yup.string(),
            })}
            onSubmit={async(values, { setSubmitting, setStatus }) => {
                const taxClasses = values.taxable
                    ?
                    await utilsContext.getTaxClasses()
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                    :
                    [];
                const taxClass = taxClasses.filter((taxClass) => taxClass.active == '1')[0];

                // to prevent the values from getting mutated
                const newValues = {
                    ...values,
                }

                newValues.active = '1';
                newValues.amount = Math.round(newValues.amount * 100);
                newValues.available_place = '';
                if(newValues.taxable)
                    newValues.tax_class_id = taxClass.tax_class_id

                if(file?.data){
                    newValues.image = file.data;
                    newValues.file_position = JSON.stringify({ ...file.crop, cropSize: file.cropSize, ...file.dimensions, zoom: file.zoom });
                }

                // const newItem = newValues; // data that will be sent back to table

                // data to send to api
                const apiValues = {
                    ...newValues,
                    ...i18nHelper.getAPIValues(newValues),
                };
                delete apiValues.i18n;
                delete apiValues.taxable;

                await otherFeesContext.createOtherItems(apiValues)
                    .then(async(other_fee_id) => {
                        const tableData = await otherFeesContext.getOtherItem(other_fee_id);

                        spordleTable.addRow(tableData).then(toggle);
                        success();
                    })
                    .catch((e) => {
                        setSubmitting(false);
                        console.error(e);
                        setStatus({ error: { msg: 'misc.error', show: true } })
                    })
            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={formik.isSubmitting}>
                    <Form>
                        <ModalHeader toggle={toggle}>
                            <Translate id='catalog.otherItems.otherItemsAdd.title' />
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label className="text-muted">Image</Label>
                                <ImgUpload cropperProps={{ cropShape: 'rect' }} dropzoneProps={{ onDropRejected: (errs) => handleError(errs, formik.setStatus) }} onChange={setFile} />
                            </FormGroup>
                            <Row form>
                                <RenderI18nForm field="name">
                                    {({ fieldName, fieldLabel }) => (
                                        <Col sm="12" className="form-group" key={fieldName}>
                                            <Label for={fieldName} className='text-muted'>{fieldLabel} <Required /></Label>
                                            <FormikInputText id={fieldName} name={fieldName} trim rows={1} />
                                        </Col>
                                    )}
                                </RenderI18nForm>
                                <RenderI18nForm field="description">
                                    {({ fieldName, fieldLabel }) => (
                                        <Col sm="12" className="form-group" key={fieldName}>
                                            <Label for={fieldName} className='text-muted'>{fieldLabel}</Label>
                                            <FormikTextArea id={fieldName} name={fieldName} rows={1} trim />
                                        </Col>
                                    )}
                                </RenderI18nForm>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Label for="amount" className="text-muted"><Translate id='catalog.otherItems.otherItemsAdd.form.price' /></Label>
                                        <div className="d-flex align-items-center">
                                            <I18nContext.Consumer>
                                                {({ getGenericLocale }) => (
                                                    <FormikInputNumber
                                                        manualError allowLeadingZeros fixedDecimalScale
                                                        id='amount'
                                                        name='amount'
                                                        className="w-50"
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        thousandSeparator=' '
                                                        decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                    />
                                                )}
                                            </I18nContext.Consumer>
                                            <span className="ml-3">CAD</span>
                                        </div>
                                        <FormikError name="amount" />
                                    </FormGroup>
                                </Col>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Label>&nbsp;</Label>
                                        <FormikCheckedButton id='taxable' name='taxable' label='catalog.registrationFees.add.form.taxable' />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/* <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="taxes" className="text-muted"><Translate id='catalog.otherItems.otherItemsAdd.form.tax'/></Label>
                                    <FormikSelect name='taxes' id='taxes'>
                                        i have no idea what to put here
                                    </FormikSelect>
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="sku" className="text-muted"><Translate id='catalog.otherItems.otherItemsAdd.form.sku'/></Label>
                                    <FormikSelect name='sku' id='sku'>
                                        <SpordleSelect.Option id='sku-4152' label='4152 - Registration' value='4152' />
                                    </FormikSelect>
                                </FormGroup>
                            </Col>
                        </Row> */}
                            { formik.status?.error &&
                            <Collapse isOpen={formik.status?.error?.show} appear>
                                <Alert isOpen={formik.status.error?.show} color='danger' className="my-2" toggle={() => formik.setStatus(({ error: { ...formik.status?.error, show: false } }))}>
                                    {formik.status?.error?.msg && <Translate id={formik.status?.error?.msg} {...formik.status?.error?.values && { values: formik.status.error?.values }} defaultMessageId='misc.error' />}
                                </Alert>
                            </Collapse>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type="submit" disabled={formik.isSubmitting}><Translate id='misc.add' /></Button>
                            <Button color="primary" type="button" onClick={toggle} outline><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </Form>
                </OverlayLoader>
            )}
        </Formik>
    );
}

export default OtherItemsAdd;