export default{
    "members.profile.transfers.title" : "{transfer_title}",

    "members.profile.transfers.form.title.usa"           : "Créer un {transfer} USA",
    "members.profile.transfers.form.title.international" : "Créer un {transfer} international",
    "members.profile.transfers.form.title.cti"           : "Créer un CTI",

    "members.profile.transfers.type.ASSOCIATION"   : "Association",
    "members.profile.transfers.type.BRANCH"        : "Branche",
    "members.profile.transfers.type.USA"           : "États-Unis",
    "members.profile.transfers.type.INTERNATIONAL" : "International",
    "members.profile.transfers.status.OPEN"        : "Ouvert",
    "members.profile.transfers.status.CLOSED"      : "Fermé",
    "members.profile.transfers.status.REFUSED"     : "Refusé",
    "members.profile.transfers.status.COMPLETED"   : "Complété",

    "members.profile.transfers.period"          : "Saison",
    "members.profile.transfers.transferType"    : "Type",
    "members.profile.transfers.from"            : "De",
    "members.profile.transfers.to"              : "À",
    "members.profile.transfers.approvedOn"      : "Approuvé le",
    "members.profile.transfers.status"          : "Statut",
    "members.profile.transfers.permanent.share" : "Permanent/Partage",
    // table


    // sidepanel
    "members.profile.transfers.sidepanel.transfer.USA"           : "{transfer_title} États-Unis",
    "members.profile.transfers.sidepanel.transfer.INTERNATIONAL" : "{transfer_title} international",
    "members.profile.transfers.sidepanel.transfer.CTI"           : "CTI",
    "members.profile.transfers.sidepanel.from"                   : "De",
    "members.profile.transfers.sidepanel.to"                     : "À",
    "members.profile.transfers.sidepanel.tabs.overview"          : "Aperçu",
    "members.profile.transfers.sidepanel.tabs.finances"          : "Finances",

    "members.profile.transfers.sidepanel.action.delete" : "Vous êtes sur le point de supprimer ce {transfer}",

    "members.profile.transfers.sidepanel.overview.attachments" : "Pièces jointes",

    "members.profile.transfers.sidepanel.finances.receivable"        : "Comptes débiteurs",
    "members.profile.transfers.sidepanel.finances.iihfCard"          : "Carte IIHF",
    "members.profile.transfers.sidepanel.finances.HCApp"             : "App HC",
    "members.profile.transfers.sidepanel.finances.formerFederation"  : "Ancienne fédération",
    "members.profile.transfers.sidepanel.finances.federationId"      : "ID de fédération",
    "members.profile.transfers.sidepanel.finances.HCInvoice"         : "Numéro de facture HC",
    "members.profile.transfers.sidepanel.finances.payable"           : "Comptes à payer",
    "members.profile.transfers.sidepanel.finances.federationAmount"  : "Montant de fédération",
    "members.profile.transfers.sidepanel.finances.federationInvoice" : "Numéro de facture de la fédération",
    "members.profile.transfers.sidepanel.finances.paid"              : "Payé",

    "members.profile.transfers.sidepanel.finances.owes"               : "Doit",
    "members.profile.transfers.sidepanel.finances.clientOrganization" : "Organisation cliente",
    "members.profile.transfers.sidepanel.finances.invoice"            : "Numéro de facture HC",

    // add (the sidepanel also uses these)
    "members.profile.transfers.add.section.details" : "Détails",
    "members.profile.transfers.add.section.to"      : "À",
    "members.profile.transfers.add.section.from"    : "De",
    "members.profile.transfers.add.section.status"  : "Statut",

    "members.profile.transfers.add.fields.transferTypes.label"                : "Type de {transfer}",
    "members.profile.transfers.add.fields.transferTypes.validation.required"  : "Veuillez sélectionner un type de {transfer}",
    "members.profile.transfers.add.fields.duration.label"                     : "Durée",
    "members.profile.transfers.add.fields.duration.validation.required"       : "Veuillez sélectionner une durée pour le {transfer}",
    "members.profile.transfers.add.fields.reason.label"                       : "Raison",
    "members.profile.transfers.add.fields.reason.validation.required"         : "Veuillez sélectionner une raison du {transfer}",
    "members.profile.transfers.add.fields.iihfCard.label"                     : "Numéro de carte IIHF",
    "members.profile.transfers.add.fields.iihfCard.validation.required"       : "Veuillez entrer un numéro de carte IIHF",
    "members.profile.transfers.add.fields.extension.label"                    : "Ext",
    "members.profile.transfers.add.fields.extension.validation.required"      : "Veuillez sélectionner une extension",
    "members.profile.transfers.add.fields.direction.label"                    : "Direction",
    "members.profile.transfers.add.fields.direction.validation.required"      : "Veuillez sélectionner une direction",
    "members.profile.transfers.add.fields.federation.label"                   : "Fédération",
    "members.profile.transfers.add.fields.federation.validation.required"     : "Veuillez sélectionner une organisation cible",
    "members.profile.transfers.add.fields.team.label"                         : "Équipe",
    "members.profile.transfers.add.fields.team.validation.required"           : "Veuillez entrer un nom d'équipe",
    "members.profile.transfers.add.fields.season.label"                       : "Saison",
    "members.profile.transfers.add.fields.season.usa.label"                   : "Le {transfer} a lieu en",
    "members.profile.transfers.add.fields.season.validation.required"         : "Veuillez sélectionner une saison",
    "members.profile.transfers.add.fields.status.label"                       : "Statut",
    "members.profile.transfers.add.fields.status.validation.required"         : "Veuillez sélectionner un statut",
    "members.profile.transfers.add.fields.tryOut.label"                       : "Date d'essai",
    "members.profile.transfers.add.fields.sentDate.label"                     : "Date d'envoi",
    "members.profile.transfers.add.fields.sentDate.validation.required"       : "Veuillez entrer une date",
    "members.profile.transfers.add.fields.approveDate.label"                  : "Date d'approbation",
    "members.profile.transfers.add.fields.withParent.label"                   : "{transfer_title} avec les parents",
    "members.profile.transfers.add.fields.notes.label"                        : "Notes",
    "members.profile.transfers.add.fields.releaseFee.label"                   : "Frais de libération d'équipe",
    "members.profile.transfers.add.fields.releaseFee.validation.required"     : "Veuillez entrer des frais de libération",
    "members.profile.transfers.add.fields.releaseFeeTeam.label"               : "Nom de l'équipe (Frais de libération)",
    "members.profile.transfers.add.fields.releaseFeeTeam.validation.required" : "Veuillez entrer un nom d'équipe",
    "members.profile.transfers.add.fields.fromTo.validation.same"             : "L'organisation visée ne peut pas être la même que l'organisation d'origine",

    "members.profile.transfers.add.error.3335" : "Le membre a déjà soumis une demande de {transfer}",
    "members.profile.transfers.add.error.3416" : "Il n'y a actuellement aucun flux de travail pour ce {transfer}, nous avons été notifiés de votre action. Vous pouvez contacter notre support pour plus d'informations.",
    "members.profile.transfers.add.error.3417" : "Il n'y a actuellement aucun flux de travail pour ce {transfer}, nous avons été notifiés de votre action. Vous pouvez contacter notre support pour plus d'informations.",
    "members.profile.transfers.add.error.3441" : "Les {transfers} avec l'équipe cible sélectionnée ont été désactivés. Contactez notre support pour plus d'informations.",
}