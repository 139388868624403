import Translate from '@spordle/intl-elements';
import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import SpordleLogo from '../../../components/SpordleLogo';
import { I18nContext } from '../../../contexts/I18nContext';

class Footer extends React.PureComponent{
    state = {
        dropdown: false,
    }

    toggleDropdown = () => this.setState((state) => ({ dropdown: !state.dropdown }));

    render(){
        return (
            <footer className="sid-sidebar-footer footer p-3 mt-auto">
                <div className="d-flex align-items-center">
                    <a className="hide-menu text-body" href="https://spordle.com" target="_blank" rel="noopener noreferrer">
                        <SpordleLogo className="sid-sidebar-footer-logo" width={65} />
                    </a>
                    <div className="ml-auto">
                        <UncontrolledDropdown isOpen={this.state.dropdown} toggle={this.toggleDropdown}>
                            <DropdownToggle color="link" className="mr-n3">
                                <i className="mdi mdi-dots-vertical text-dark" />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <I18nContext.Consumer>
                                    {({ getGenericLocale }) => (
                                        <>
                                            <DropdownItem href={getGenericLocale() === 'en' ? "https://www.spordle.com/terms/" : "https://www.spordle.com/fr/termes/"} target="_blank" rel="noopener noreferrer">
                                                <Translate id='sidebar.terms' />
                                            </DropdownItem>
                                            <DropdownItem href={getGenericLocale() === 'en' ? "https://www.spordle.com/privacy/" : "https://www.spordle.com/fr/confidentialite/"} target="_blank" rel="noopener noreferrer">
                                                <Translate id='sidebar.privacy' />
                                            </DropdownItem>
                                            <DropdownItem href={getGenericLocale() === 'en' ? "https://www.spordle.com/about/" : "https://www.spordle.com/fr/a-propos/"} target="_blank" rel="noopener noreferrer">
                                                <Translate id='sidebar.about' />
                                            </DropdownItem>
                                        </>
                                    )}
                                </I18nContext.Consumer>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
                <Version />
            </footer>
        );
    }
}

const Version = () => (
    (process.env.REACT_APP_ENVIRONMENT !== "prod") &&
        <div className='text-truncate small text-muted'>Version: {process.env.REACT_APP_ENVIRONMENT} {process.env.REACT_APP_BUILD_DATE}</div>
)

export default Footer;
