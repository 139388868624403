export default{
    "settings.clinics.sidePanelClinicQualifications.title" : "Qualification Information",

    "settings.clinics.sidePanelClinicQualifications.prerequisites"                  : "Prerequisites",
    "settings.clinics.sidePanelClinicQualifications.prerequisites.required.all"     : "All prerequisites are required",
    "settings.clinics.sidePanelClinicQualifications.prerequisites.required.partial" : "At least one prerequisit is required",
    "settings.clinics.sidePanelClinicQualifications.description"                    : "Description",
    "settings.clinics.sidePanelClinicQualifications.organization"                   : "Organization",

    "settings.clinics.sidePanelClinicQualifications.price"           : "Price",
    "settings.clinics.sidePanelClinicQualifications.status"          : "Status",
    "settings.clinics.sidePanelClinicQualifications.status.active"   : "Active",
    "settings.clinics.sidePanelClinicQualifications.status.inactive" : "Inactive",
    "settings.clinics.sidePanelClinicQualifications.status.required" : "Status is required",

    "settings.clinics.sidePanelClinicQualifications.update.success.text" : "Qualification has been updated",
    "settings.clinics.sidePanelClinicQualifications.update.fail.text"    : "An error occurred updating the qualification, please refresh the page et try again",

    // actions
    "settings.clinics.sidePanelClinicQualifications.actions.remove"         : "Remove",
    "settings.clinics.sidePanelClinicQualifications.actions.remove.topText" : "You are about to remove this qualification from your organization",

    // tabs
    "settings.clinics.sidePanelClinicQualifications.tabs.details" : "Details",
}