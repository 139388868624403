import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { AuthContext } from './contexts';
import withContexts from '../helpers/withContexts';
import { jsObjectToApi } from '@spordle/helpers';

/** @type {React.Context<Omit<JiraContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const JiraContext = createContext();
JiraContext.displayName = 'JiraContext';

class JiraContextProvider extends React.PureComponent{
    state = { types: false };

    /**
     * [GET] Returns all the request types
     * @param {Boolean} [fromApi] Default false, will return the cached issues if false, will always fetch from api if true
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/JIRA/Apicontroller%5CUtils%5CJiraservicedesk%3A%3AgetRequestTypes|documentation}
     *
     * @returns {Promise<Array<string>>}
     */
    getRequestTypes = (fromApi = false) => {
        if(this.state.types && !fromApi){
            return Promise.resolve(this.state.types);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/jira/request-types` }))
            .then((response) => {
                if(response.data.status){
                    this.setState((prev) => ({ ...prev, types: response.data.results.values }))
                    return response.data.results.values;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Returns all the notifications
     * @param {String} [identityId] Identity id of the user
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Accounts/Apicontroller%5CUtils%5CJiraservicedesk%3A%3AgetAllJiraNotifications|documentation}
     *
     * @returns {Promise<Array<string>>}
     */
    getNotifications = (identityId = this.props.AuthContext.account?.userName) => (
        API_SPORDLE.get(queryString.stringifyUrl({ url: `/accounts/${identityId}/notifications` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.unread_notifications;
                }
                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * [DELETE] Deletes a notification
     * @param {String} issueKey key of the issue to delete the notification from
     * @param {String} [identityId] Identity id of the user
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Accounts/Apicontroller%5CUtils%5CJiraservicedesk%3A%3AdeleteJiraNotification|documentation}
     *
     * @returns {Promise<Boolean>}
     */
    deleteNotification = (issueKey, identityId = this.props.AuthContext.account?.userName) => (
        API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/accounts/${identityId}/notifications/${issueKey}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * [GET] Returns all the requests of a user
     * @param {String} [identityId] Identity id of the user
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/JIRA/Apicontroller%5CUtils%5CJiraservicedesk%3A%3AgetCustomerRequests|documentation}
     *
     * @returns {Promise<Array<string>>}
     */
    getRequests = (identityId = this.props.AuthContext.account?.userName, params) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/jira/requests`,
            query: Object.assign({
                identity_id: identityId,
                ...params,
            }),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return {
                        ...response.data.results,
                        issues: response.data.results.issues.map((issue) => ({
                            ...issue,
                            fields: {
                                ...issue.fields,
                                organisation: response.data.organisations[issue.fields.organisation_id] || null,
                            },
                        })),
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Returns a specific request
     * @param {String} issueKey Key of the request
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/JIRA/Apicontroller%5CUtils%5CJiraservicedesk%3A%3AgetSpecificCustomerRequest|documentation}
     *
     * @returns {Promise<Array<string>>}
     */
    getRequest = (issueKey) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/jira/requests/${issueKey}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.results;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getJiraStories = () => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/jira/stories`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.results;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Returns confluence page data
     * @param {String} pageId The page ID from confluence
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/JIRA/Apicontroller%5CUtils%5CJiraservicedesk%3A%3AgetSpecificConfluencePage|documentation}
     *
     * @returns {Promise<Array<string>>}
     */
    getConfluence = (pageId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `/jira/confluence/${pageId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.results;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Creates a request
     * @param {String} organizationId Id of the org
     * @param {String} identityId Identity Id of the user
     * @param {object} values Values to send - Refer to the {@link https://api.id.spordle.dev/documentations/#/JIRA/Apicontroller%5CUtils%5CJiraservicedesk%3A%3AcreateCustomerRequest|documentation}
     * @returns {Promise.<Array>}
     */
    createRequest = (organizationId, identityId, values) => {
        const params = new URLSearchParams();

        params.append('organisation_id', organizationId)
        params.append('identity_id', identityId)
        params.append('summary', values.summary)
        params.append('description', values.description)
        params.append('priority', values.priority)
        params.append('browser_info', values.browser_info)
        params.append('page_url', values.page_url)
        params.append('request_type_id', values.request_type_id)

        if(values.story)
            params.append('story_issue_key', values.story)

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/jira/requests` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.results;
                }
                throw response.data.errors;
            }, serverError)
    }

    /**
     * [POST] Creates a comment on an issue
     * @param {String} issueKey Issue key of the ticket in JIRA service desk. For example : HCR-12010
     * @param {Object} values Values of the comment - Refer to the {@link https://api.id.spordle.dev/documentations/#/JIRA/Apicontroller%5CUtils%5CJiraservicedesk%3A%3AcreateCustomerRequestComment|documentation}
     * @returns {Promise.<Array>}
     */
    createComment = (issueKey, values) => {
        const params = new URLSearchParams();
        params.append('comment', values.comment);
        params.append('full_name', values.full_name);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/jira/requests/${issueKey}/comments` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.results;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Gets the attachments for an issue
     * @param {string} issueKey Issue key to get attachments from
     * @returns {Promise}
     */
    getAttachments = (issueKey) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/jira/requests/${issueKey}/attachments`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.results
                }
                throw new response.data.errors[0]
            }, serverError)
    }

    /**
     * [POST] Creates a temporary file and returns the ID
     * @param {object} file Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/JIRA/5303bc270d433a292b6f598d5a44f22f}
     * @returns {Promise}
     */
    createTemporaryFile = (file) => {
        const params = new FormData();
        params.append('temporary_file', file)

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/jira/temporary-files` }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return response.data.results;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Creates an attachment for an issue
     * @param {string} issueKey Issue key of the JIRA ticket
     * @param {object} values Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/JIRA/e336d417b4bcec72543e8c0366f47409}
     * @returns {Promise}
     */
    createAttachment = (issueKey, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params)

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/jira/requests/${issueKey}/attachments` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <JiraContext.Provider value={{ ...this.state, ...this }}>
                {this.props.children}
            </JiraContext.Provider>
        );
    }
}

export default withContexts(AuthContext)(JiraContextProvider);
