import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import moment from 'moment';
import {
    Button,
    FormGroup,
    Label, ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import { object, string, mixed } from 'yup';
import { success, fail } from '@spordle/toasts';
import AnalyticsModal from "../../../../../analytics/AnalyticsModal";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import Required from '../../../../../components/formik/Required';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import IsGod from "../../../../../components/permissions/IsGod";
import { useSpordleTable } from "@spordle/datatables";

const AddRoleModal = ({ toggle, isOpen, addRole }) => {
    const { refreshTable } = useSpordleTable()
    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='OrganizationRoles'>
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    status: 'active',
                    startDate: '',
                    endDate: '',

                    // In preparation for Admin interface
                    isSystem: '0',
                }}
                validationSchema={object().shape({
                    title: string().required(<Translate id='organization.profile.roles.addRole.roleTitle.required' />),
                    description: string().required(<Translate id='organization.profile.roles.addRole.description.required' />),
                    status: string().required(<Translate id='organization.profile.roles.addRole.status.required' />),
                    startDate: mixed()
                        .test({
                            name: 'start_date_format',
                            message: <Translate id='organization.profile.roles.addRole.dates.startDate.format.validation' />,
                            test: (value) => {
                                if(value !== undefined){
                                    return moment.isMoment(value)
                                }
                                return true // return true because the field is not required

                            },
                        })
                        .when('endDate', {
                            is: (endDate) => endDate !== undefined,
                            then: mixed()
                                .test({
                                    name: 'start_date_end_date_check',
                                    message: <Translate id='organization.profile.roles.addRole.dates.validation' />,
                                    test: function(value){ return moment(value).isBefore(this.parent.endDate) },
                                }),
                        }),
                    endDate: mixed()
                        .test({
                            name: 'end_date_format',
                            message: <Translate id='organization.profile.roles.addRole.dates.endDate.format.validation' />,
                            test: (value) => {
                                if(value !== undefined){
                                    return moment.isMoment(value)
                                }
                                return true // return true because the field is not required

                            },
                        }),
                    isSystem: string(),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true)
                    addRole({ ...values, active: values.status === 'active' })
                        .then(() => {
                            refreshTable()
                            toggle();
                            success();
                        })
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                console.error(e.message);
                                fail();
                                setSubmitting(false);
                            }
                        })
                }}
            >
                {(formik) => {
                    return (
                        <Form>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalHeader toggle={toggle}><Translate id='organization.profile.roles.addRole.title' /></ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <Label for='title' className='text-muted'><Translate id='organization.profile.roles.addRole.roleTitle' /> <Required /></Label>
                                        <FormikInputText id='title' name='title' trim />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for='description' className='text-muted'><Translate id='organization.profile.roles.addRole.description' /> <Required /></Label>
                                        <FormikInputText id='description' name='description' trim />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for='status' className='text-muted'><Translate id='organization.profile.roles.addRole.status' /></Label>
                                        <FormikSelect
                                            name="status" id="status"
                                            defaultData={[
                                                { value: 'active', label: 'organization.profile.roles.addRole.status.active', translateLabel: true },
                                                { value: 'inactive', label: 'organization.profile.roles.addRole.status.inactive', translateLabel: true },
                                            ]}
                                            loadingStatus='success'
                                        />
                                    </FormGroup>
                                    <IsGod>
                                        <FormGroup>
                                            <Label for='isSystem' className='text-muted'><Translate id='organization.profile.roles.addRole.isSystem' /></Label>
                                            <FormikSelect
                                                name="isSystem" id="isSystem"
                                                defaultData={[
                                                    { value: '0', label: 'misc.no', translateLabel: true },
                                                    { value: '1', label: 'misc.yes', translateLabel: true },
                                                ]}
                                                loadingStatus='success'
                                            />
                                        </FormGroup>
                                    </IsGod>
                                    {/* <Card body className='card-shadow'>
                                        <I18nContext.Consumer>
                                            {({getGenericLocale}) => (
                                                <>
                                                    <FormGroup>
                                                        <Label for='startDate' className='text-muted'><Translate id='organization.profile.roles.addRole.dates.start'/></Label>
                                                        <FormikDateTime
                                                            id='startDate'
                                                            name='startDate'
                                                            isValidDate={(current) => moment(current).isAfter(moment().subtract(1, "days"))}
                                                            timeFormat={false}
                                                            dateFormat='LL'
                                                            locale={getGenericLocale()}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for='endDate' className='text-muted'><Translate id='organization.profile.roles.addRole.dates.end'/></Label>
                                                        <FormikDateTime
                                                            id='endDate'
                                                            name='endDate'
                                                            isValidDate={(current) => moment(current).isAfter(moment())}
                                                            timeFormat={false}
                                                            dateFormat='LL'
                                                            locale={getGenericLocale()}
                                                        />
                                                    </FormGroup>
                                                </>
                                            )}
                                        </I18nContext.Consumer>
                                    </Card> */}
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={formik.isSubmitting || !isOpen}><Translate id='misc.add' /></Button>
                                    <Button color='primary' type='button' outline onClick={toggle} disabled={formik.isSubmitting || !isOpen}><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </Form>
                    )
                }}
            </Formik>
        </AnalyticsModal>
    )
}

export default AddRoleModal;