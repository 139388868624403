import Translate from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import { useState } from "react";
import CollapsibleCard from "../../../../../../../../components/collapsibleCard/CollapsibleCard";
import CrossFade from "../../../../../../../../components/crossFade/CrossFade";
import { usePrevious } from "../../../../../../../../components/customHooks/usePrevious";
import IncidentForm from "../../../../IncidentForm";

/**
 * @param {Object} props
 * @param {function} props.previous
 * @returns {JSX.Element}
 */
const IncidentListItem = ({ helper, index, ...props }) => {
    // States
    const [ remove, setRemove ] = useState(false);
    const [ isOpen, setIsOpen ] = useState(true);

    // Contexts
    const { values, errors, touched } = useFormikContext();

    // Other
    const fieldsPrefix = `${helper.name}.${index}.`;
    const globalError = !!errors.incidents?.[index] && !!touched.incidents?.[index];

    // Functions
    const handleRemove = () => {
        helper.remove(index);
    }

    // Hooks
    usePrevious((prev) => {
        if(prev < values.incidents.length && index !== values.incidents.length - 1){
            setIsOpen(false);
        }
    }, values.incidents?.length);

    return (
        <CrossFade
            onExited={handleRemove}
            isVisible={!remove}
        >
            <CollapsibleCard
                className="mb-3"
                error={globalError}
                title={
                    <span className="font-medium h5 mb-0">
                        <Translate id='gameIncident.addModal.label.incident' /> #{index + 1}
                    </span>
                }
                initOpen={isOpen} // Everytime an incident is added
                extraInfo={
                    index !== 0 &&
                        <button onClick={() => setRemove(true)} type="button" className="align-self-start reset-btn text-danger">
                            <i className="mdi mdi-delete" />
                        </button>
                }
            >
                <IncidentForm
                    fieldsPrefix={fieldsPrefix}
                    incident={values.incidents[index]}
                    {...props}
                />
            </CollapsibleCard>
        </CrossFade>
    )
}

export default IncidentListItem;