export default{
    "settings.clinics.categories.title" : "Categories",

    // add
    "settings.clinics.categories.add.title"           : "Add Category",
    "settings.clinics.categories.add.color"           : "Color",
    "settings.clinics.categories.add.status"          : "Status",
    "settings.clinics.categories.add.status.active"   : "Active",
    "settings.clinics.categories.add.status.inactive" : "Inactive",
    "settings.clinics.categories.add.status.required" : "Status is required",
}