import { groupByAsArray } from '@spordle/helpers'
import Translate from '@spordle/intl-elements'
import { useContext, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap'
import CardSectionTitle from '../../../../components/CardSectionTitle'
import IsGod from '../../../../components/permissions/IsGod'
import EmptyLayout from '../../../../components/table/EmptyLayout'
import { TeamsContext } from '../../../../contexts/TeamsContext'
import { DisplayI18n } from '../../../../helpers/i18nHelper'
import { sortRestrictionKeys } from '../../../settings/teamsRestrictions/helpers'
import TeamSettingsRestrictionsRow from './TeamSettingsRestrictionsRow'

const TeamSettingsRestrictions = ({ teamsSettings, error, isValidating, canSeeMember }) => {
    const teamsContext = useContext(TeamsContext)

    const [ groupedSettings, setGroupedSettings ] = useState([]);

    useEffect(() => {
        setGroupedSettings(groupByAsArray(Object.keys(teamsSettings || {}).reduce((newArray, key) => {
            if(key !== 'qualification_rules'){
                newArray.push({
                    code: key,
                    ...teamsSettings[key],
                    setting_group_id: teamsSettings[key].setting_group?.setting_group_id,
                })
            }
            return newArray
        }, []), 'setting_group_id'))
    }, [ teamsSettings ])

    const isEmptyRestrictions = () => {
        if(isValidating || error){
            return false;
        }
        return Object.keys(teamsSettings || {}).length == 0;
    }

    return (
        <Card className="card-shadow" id='teamRestrictionCard'>{/* id for scrollIntoView */}
            <CardBody>
                <CardSectionTitle title="teams.profile.teamSetting.restrictions.title" className="d-flex flex-wrap">
                    {teamsContext.cachedTeam.team_category &&
                        <IsGod className='d-flex ml-auto'>
                            <Link id='go-to-team-settings' to={"/settings/team-restrictions?search=" + teamsContext.cachedTeam.team_category.name} className="reset-btn"><Translate id="teams.profile.teamSetting.goTo.settings" /> <i className='mdi mdi-chevron-right' /></Link>
                        </IsGod>
                    }
                </CardSectionTitle>

                {isEmptyRestrictions() ?
                    <EmptyLayout hideArrow hideMsg title='teams.profile.teamSetting.restrictions.none' translateTitle />
                    : error ?
                        <Translate id='misc.error' />
                        :
                        <>
                            {/* Header */}
                            <Card className='bg-light-inverse mb-3 px-4'>
                                <Row className="align-items-center py-1">
                                    <Col xs="8" className='text-muted'><Translate id='teams.profile.teamSetting.restrictions.settingName' /></Col>
                                    <Col xs="4" className='text-muted text-center'><Translate id='teams.profile.teamSetting.restrictions.value' /></Col>
                                </Row>
                            </Card>

                            {isValidating ?
                                Array(groupedSettings?.length || 2).fill({
                                    key: '',
                                    values: [],
                                }).map((settingGroup, index) => {
                                    return (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <Card className='mb-3 border' key={index}>
                                            <ListGroup flush>
                                                <ListGroupItem className='py-2 px-4'>
                                                    <Skeleton width='100px' />
                                                </ListGroupItem>

                                                {Array(settingGroup.values.length || 5).fill('').map((setting, index) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <ListGroupItem key={index}>
                                                        <Row>
                                                            <Col sm="8">
                                                                <Skeleton width='50%' />
                                                                <Skeleton className='small' />
                                                            </Col>
                                                            <Col sm="4" className='text-center'><Skeleton width='80%' /></Col>
                                                        </Row>
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        </Card>
                                    )
                                })
                                :
                                groupedSettings?.map((settingGroup) => {
                                    return (
                                        <Card className='mb-3 border' key={settingGroup.key}>
                                            <ListGroup flush>
                                                <ListGroupItem className='text-dark font-bold py-2 px-4'>
                                                    <DisplayI18n field='name' defaultValue={settingGroup.values[0].setting_group?.name} i18n={settingGroup.values[0].setting_group?.i18n} />
                                                </ListGroupItem>

                                                {settingGroup.values.sort((a, b) => sortRestrictionKeys(a.code, b.code)).map((setting) => (
                                                    <TeamSettingsRestrictionsRow key={setting.code} setting={setting} teamSettingCode={setting.code} canSeeMember={canSeeMember} />
                                                ))}
                                            </ListGroup>
                                        </Card>
                                    )
                                })
                            }
                        </>
                }
            </CardBody>
        </Card>
    )
}

export default TeamSettingsRestrictions
