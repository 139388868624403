import { Tooltip } from "@mantine/core";
import SpordleTableProvider from "@spordle/datatables";
import { FormikCurrencyInput, FormikDateTime } from "@spordle/formik-elements";
import { CurrencyFormat } from "@spordle/intl-elements";
import { FormattedTime } from "react-intl";
import { ModalHeader } from "reactstrap";
import EmptyLayout from "../components/table/EmptyLayout";
import { controlClickCopy } from "./clipboardHelper";
import { isProdFishNChips } from "./getReferer";

/**
 * Function used to set default props for the entire platform
 */
function setDefaultComponentProps(){
    ModalHeader.defaultProps = {
        ...ModalHeader.defaultProps,
        tag: 'div', // We want to use a div for modal headers by default
    }

    SpordleTableProvider.defaultProps = {
        ...SpordleTableProvider.defaultProps,
        emptyLayout: ({ emptySearch }) => <EmptyLayout hideArrow={emptySearch} />,
        striped: true,
        bordered: true,
        tableHover: true,
        clickable: true,
        desktopWhen: 'md',
        paginationMessage: 'misc.paginationMessage',
    };
    SpordleTableProvider.globalConfig.onColumnClick = controlClickCopy;

    if(isProdFishNChips()){
        FormikCurrencyInput.defaultProps = {
            ...FormikCurrencyInput.defaultProps,
            currencySign: '£',
        }

        CurrencyFormat.defaultProps = {
            ...CurrencyFormat.defaultProps,
            currency: 'GBP',
        }
    }

    FormikDateTime.defaultProps = {
        ...FormikDateTime.defaultProps,
        closeOnSelect: true,
        autoComplete: 'new-password', // Bypass chrome autocomplete
    }

    Tooltip.defaultProps = {
        ...Tooltip.defaultProps,
        transition: 'fade',
        withArrow: true,
    }

    FormattedTime.defaultProps = {
        ...FormattedTime.defaultProps,
        hour12: false,
    }
}

export default setDefaultComponentProps;