export default{
    "onlineStore.components.onlineStorePaymentOptions.title"                          : "Options de paiement",
    "onlineStore.components.onlineStorePaymentOptions.merchantAccount.title"          : "Compte marchand",
    "onlineStore.components.onlineStorePaymentOptions.merchantAccount.title.helper"   : "Permet les paiements par carte de crédit",
    "onlineStore.components.onlineStorePaymentOptions.merchantAccount.required"       : "Un compte marchand est requis si aucune méthode de paiement est spécifiée.",
    "onlineStore.components.onlineStorePaymentOptions.merchantAccount.empty"          : "Aucun compte marchand",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.title"            : "Méthodes de paiement",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.required"         : "Au moins une méthode de paiement est requise si aucun compte marchand est spécifié.",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.label.CHEQUE"     : "Chèque",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.label.CASH"       : "Espèces",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.label.CREDITCARD" : "Carte de crédit",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.label.INTERAC"    : "Interac",
    "onlineStore.components.onlineStorePaymentOptions.paymentMethod.label.FUNDING"    : "Financement",
}