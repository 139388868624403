/**
 * @param {{ email: string }[]} recipients
 * @returns {{ valid: [], invalid: [], all: [] }}
 */
export const sortAndFilterRecipients = (recipients) => {
    return recipients.reduce((formattedRecipients, recipient) => {
        formattedRecipients[recipient.email ? "valid" : "invalid"].push(recipient);

        return formattedRecipients;
    }, { valid: [], invalid: [], all: recipients });
}

export const MODE_ORG = 1;
export const MODE_IDENTITY = 2;
export const MODE_MEMBER = 3;