import { useDisclosure } from '@mantine/hooks';
import Translate from '@spordle/intl-elements';
import BatchConfirmMemberRegistration from '../../../../../components/memberRegistration/BatchConfirmMemberRegistration';
import SidePanel from '../../../../../components/sidePanel/SidePanel';

const OnlineStoreMultiConfirmRegistration = ({ registrations, refreshTable }) => {
    const [ isOpen, handlers ] = useDisclosure(false);

    return (
        <>
            <SidePanel.MenuAction onClick={handlers.open}>
                <Translate id='memberProfile.registration.sidepanel.confirmRegistrations' />
            </SidePanel.MenuAction>
            <BatchConfirmMemberRegistration close={handlers.close} isOpen={isOpen} registrations={registrations} onConfirmed={refreshTable} />
        </>
    )
}

export default OnlineStoreMultiConfirmRegistration