import { FormikCheckedButton, FormikDateTime, FormikError, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useContext, useState } from 'react';
import {
    Alert,
    Button,
    Col,
    Collapse,
    Fade,
    FormGroup,
    Label, ModalBody,
    ModalFooter, ModalHeader, Row
} from "reactstrap";
import { array, mixed, object, string } from 'yup';
import AnalyticsModal from "../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { PeriodsContext } from '../../contexts/contexts';
import { I18nContext } from '../../contexts/I18nContext';
import { IdentityRolesContext } from '../../contexts/IdentityRolesContext';
import { MembersContext } from '../../contexts/MembersContext';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { PositionsContext } from '../../contexts/PositionsContext';
import { TeamsContext } from '../../contexts/TeamsContext';
import { TransferContext } from '../../contexts/TransferContext';
import { DisplayI18n } from '../../helpers/i18nHelper';
import { DisplayCategory } from '../../views/teams/TeamHelpers';
import Required from '../formik/Required';
import OverlayLoader from '../loading/OverlayLoader';
import OrganizationSearch from '../organization/OrganizationSearch';
import FileUpload from '../uploader/fileUpload/FileUpload';
import { separateFilesPerSize } from '../uploader/uploadHelpers';

const TransferRequestModal = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName='TransferRequestModal'>
            <TransferRequestModalInner {...props} />
        </AnalyticsModal>
    )
}

const TransferRequestModalInner = ({ isOpen, toggle, memberId, memberOrgId, memberAge, updateSidePanel, manualTransferMode = false }) => {
    const transferContext = useContext(TransferContext);
    const membersContext = useContext(MembersContext);
    const organizationContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext);
    const teamsContext = useContext(TeamsContext);
    const positionsContext = useContext(PositionsContext);
    const { isGod } = useContext(IdentityRolesContext);
    const { getGenericLocale } = useContext(I18nContext);

    const [ positionType, setPositionType ] = useState('');

    const allowTransferTargetTeam = organizationContext.settings.allow_transfer_target_team?.value?.indexOf(organizationContext.organisation_category.organisation_category_id) != -1 || false

    const [ isNRP, setIsNRP ] = useState(false);

    const createTransfer = (apiValues) => {
        if(manualTransferMode){
            return membersContext.createMemberManualTransferRequest(memberId, apiValues)
        }
        return membersContext.createMemberTransferRequest(memberId, apiValues)
    }

    return (
        <Formik
            initialValues={{
                durationId: '',
                reason: '',
                targetOrgId: '',
                details: '',
                shared_expiration_date: '',
                attachments: [],
                targetTeamId: '',
                targetPositionId: '',
                target_affiliate: false,
                target_tryout: false,
                target_import: false,
                invoice: 'NO_CHARGE',
                isPermanent: true,
            }}
            validationSchema={object().shape({
                durationId: string().required(<Translate id='transfer.types.required' />),
                reason: string().required(<Translate id='transfer.reason.required' />),
                targetOrgId: string().when('does_not_matter', { // only required when manual transfer and when member org === current org
                    is: () => manualTransferMode,
                    then: string().required(<Translate id='transfer.organization.required' />)
                        .test({
                            name: 'differentOrgsTest',
                            message: <Translate id='transfer.organization.different' />,
                            test: (targetOrgId) => targetOrgId !== memberOrgId,
                        }),
                }),
                shared_expiration_date: mixed().test({
                    name: 'submittedDateFormatTest',
                    message: <Translate id='form.validation.date.format' />,
                    test: (date) => {
                        if(date){
                            return moment.isMoment(date)
                        }
                        return true // not required
                    },
                }).test({
                    name: 'submittedDateRequiredTest',
                    message: <Translate id='transfer.sharedExpiration.required' />,
                    test: (date) => {
                        return (!!date || !isNRP)
                    },
                }).test({
                    name: 'isAfterToday',
                    message: <Translate id='transfer.sharedExpiration.afterToday' />,
                    test: (date) => {
                        if(date){
                            return moment(date).isAfter(moment())
                        }
                        return true;
                    },
                }),
                targetTeamId: string(),
                targetPositionId: string().test({
                    name: 'isPositionSelected',
                    message: <Translate id='transfer.validation.isPositionSelected' />,
                    test: (position, data) => {
                        if(data.parent.targetTeamId && !position){
                            return false;
                        }return true;
                    },
                }),
                details: string(),
                attachments: array().test({
                    name: 'documentsRequiredTest',
                    message: <Translate id='transfer.documents.required' />,
                    test: (files) => {
                        return (!!files?.length > 0 || !isNRP)
                    },
                }),
            })}
            onSubmit={(values, { setStatus }) => {
                setStatus();
                const formattedFiles = separateFilesPerSize(values.attachments);

                const apiValues = {
                    transfer_duration_id: values.durationId,
                    period_id: periodsContext.selectedPeriod.period_id,
                    target_organisation_id: values.targetOrgId || organizationContext.organisation_id,
                    transfer_reason_type_id: values.reason,
                    attachments: formattedFiles.splice(0, 1)[0],
                    invoice: values.invoice,
                }

                if(values.shared_expiration_date){
                    apiValues.shared_expiration_date = values.shared_expiration_date.format('YYYY-MM-DD');
                }

                if(values.details){
                    apiValues.transfer_details = values.details;
                }

                if(!manualTransferMode && values.targetTeamId){ // target_team_id is available only on the normal transfer call
                    apiValues.target_team_id = values.targetTeamId;
                }

                if(!manualTransferMode && values.targetPositionId){ // target_team_id is available only on the normal transfer call
                    apiValues.target_position_id = values.targetPositionId;
                }
                if(values.target_affiliate){
                    apiValues.target_affiliate = values.target_affiliate ? 1 : 0;
                }
                if(values.target_tryout){
                    apiValues.target_tryout = values.target_tryout ? 1 : 0;
                }
                if(values.target_import){
                    apiValues.target_import = values.target_import ? 1 : 0;
                }

                return createTransfer(apiValues)
                    .then(async(transferId) => {
                        if(formattedFiles.length > 0){
                            await Promise.all(
                                formattedFiles.map((subArr) => {
                                    return membersContext.createMemberTransferAttachment(memberId, transferId, subArr);
                                }),
                            )
                        }
                        if(updateSidePanel)
                            await updateSidePanel();
                        else
                            membersContext.getAllMemberInfos(memberId)
                    })
                    .then(() => {
                        toggle();
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            if(error.i18n){
                                setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            }else{
                                switch (error.message){
                                    case '3335':// The member is already in a transfer
                                        setStatus(<Translate id={`members.profile.transfers.add.error.${error.message}`} />);
                                        break;
                                    case '3403':// The transfer couldn’t be initiated because the requested member has an active outstanding balance to settle
                                        setStatus(<Translate id={`transfer.error.${error.message}`} />);
                                        break;
                                    case '3416':// No transfer workflow found
                                    case '3417':// No transfer workflow found
                                        setStatus(<Translate id={`members.profile.transfers.add.error.${error.message}`} />);
                                        break;
                                    case '3441':// Taregt Team Transfers are blocked
                                        setStatus(<Translate id={`members.profile.transfers.add.error.${error.message}`} />);
                                        break;
                                    default:
                                        setStatus(<Translate id='misc.error' />);
                                        break;
                                }
                            }
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggle}>
                            {manualTransferMode ?
                                <Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer.manual' />
                                :
                                <Translate id='members.search.sidePanelMemberSearch.actions.resquestTransfer' />
                            }
                        </ModalHeader>
                        <ModalBody>
                            <Row form>
                                <Col md="6" className="form-group">
                                    <Label for='durationId' className='text-muted'><Translate id='transfer.types' /> <Required /></Label>
                                    <FormikSelect
                                        name='durationId'
                                        id='durationId'
                                        searchKeys={[
                                                `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        renderOption={(option) => (
                                            <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                        )}
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return transferContext.getTransferDurations({ active: 1 }, false, true)
                                                        .then((durations) => {
                                                            // is_nrp only for normal transfer requests, not manual transfer
                                                            return durations.filter((duration) => duration.scope === 'ASSOCIATION/BRANCH' && (!manualTransferMode || duration.is_nrp == 0))
                                                                .sort((durationA, durationB) => parseInt(durationA.display_order) - parseInt(durationB.display_order))
                                                                .map((duration) => ({
                                                                    value: duration.transfer_duration_id,
                                                                    label: duration.name,
                                                                    i18n: duration.i18n,
                                                                    isNRP: duration.is_nrp == 1,
                                                                    isPermanent: duration.system_type === "TRANSFER",
                                                                    isShared: duration.system_type === "SHARE",
                                                                }));
                                                        })
                                                default:
                                                    break;
                                            }
                                        }}
                                        onOptionSelected={(values, spordleSelect) => {
                                            const option = spordleSelect.getSpordleTable().getData().find((option) => option.value === values[0])
                                            formik.setFieldValue('isPermanent', (option && option.isPermanent))
                                            if(option && option.isShared){
                                                if(organizationContext.settings?.default_share_expiration_date?.value && !formik.values.shared_expiration_date){
                                                    formik.setFieldValue('shared_expiration_date', moment(organizationContext.settings?.default_share_expiration_date?.value))
                                                }
                                            }else{
                                                formik.setFieldValue('shared_expiration_date', "")
                                            }
                                            if(option){
                                                setIsNRP(option.isNRP);
                                            }else{
                                                setIsNRP(false);
                                            }
                                        }}
                                    />
                                </Col>
                                {!formik.values.isPermanent &&
                                    <Col md="6" className="form-group">
                                        <Fade in={!formik.values.isPermanent}>
                                            <Label className="text-muted" for="shared_expiration_date">
                                                <Translate id='transfer.sharedExpiration' />
                                                {isNRP && <Required className='ml-1' />}
                                            </Label>
                                            <FormikDateTime
                                                key={formik.values.shared_expiration_date}
                                                isValidDate={(current) => current.isAfter(moment())}
                                                id="shared_expiration_date"
                                                name="shared_expiration_date"
                                                timeFormat={false}
                                            />
                                        </Fade>
                                    </Col>
                                }
                            </Row>
                            <Row form>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Label for='reason' className='text-muted'><Translate id='transfer.reason' /> <Required /></Label>
                                        <FormikSelect
                                            name='reason'
                                            id='reason'
                                            searchKeys={[
                                                    `i18n.${getGenericLocale()}.name`,
                                            ]}
                                            renderOption={(option) => (
                                                <DisplayI18n field='name' defaultValue={option.option.label} i18n={option.option.i18n} />
                                            )}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return transferContext.getTransferReasons({ active: 1, from_organisation_id: memberOrgId, to_organisation_id: organizationContext.organisation_id })
                                                            .then((reasons) => {
                                                                return reasons.sort((reasonA, reasonB) => parseInt(reasonA.display_order) - parseInt(reasonB.display_order))
                                                                    .map((reason) => ({
                                                                        value: reason.transfer_reason_type_id,
                                                                        label: reason.name,
                                                                        i18n: reason.i18n,
                                                                    }))
                                                            })
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Label for='invoice' className='text-muted'><Translate id='transfer.invoice' /></Label>
                                        <FormikSelect
                                            name='invoice'
                                            id='invoice'
                                            search={false}
                                            loadingStatus='success'
                                            defaultData={[
                                                {
                                                    label: 'transfer.invoice.CHARGE',
                                                    translateLabel: true,
                                                    value: 'CHARGE',
                                                },
                                                {
                                                    label: 'transfer.invoice.NO_CHARGE',
                                                    translateLabel: true,
                                                    value: 'NO_CHARGE',
                                                },
                                            ]}
                                            disabled={manualTransferMode}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {manualTransferMode &&
                                    <FormGroup>
                                        <Label for='targetOrgId' className='text-muted'><Translate id='transfer.organization' /> <Required /></Label>
                                        <OrganizationSearch
                                            id="targetOrgId" name="targetOrgId"
                                            withFormik
                                            fromIdentityRole
                                        />
                                    </FormGroup>
                            }
                            {!manualTransferMode &&
                                    <>
                                        {(allowTransferTargetTeam || isGod()) &&
                                            <>
                                                <FormGroup>
                                                    <Label for='targetTeamId' className={`${(!allowTransferTargetTeam && isGod()) ? 'text-purple' : 'text-muted'}`}><Translate id='transfer.targetTeam' /></Label>
                                                    <FormikSelect
                                                        name='targetTeamId'
                                                        id='targetTeamId'
                                                        clearable
                                                        searchKeys={[
                                                            `division.i18n.${getGenericLocale()}.name`,
                                                            `category.class.i18n.${getGenericLocale()}.name`,
                                                        ]}
                                                        renderOption={(option) => (
                                                            <>
                                                                <div>
                                                                    <div>{option.option.label}</div>
                                                                </div>
                                                                <div className='text-muted small'>
                                                                    {option.option.division ? option.option.division : <DisplayCategory category={{ ...option.option?.category }} short /> }
                                                                    {option.option.category?.gender &&
                                                                        <>
                                                                            <span className='mx-1'>-</span>
                                                                            <Translate id={`form.fields.gender.${option.option.category.gender.toLowerCase()}`} />
                                                                        </>
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                        loadData={(from) => {
                                                            switch (from){
                                                                case 'CDM':
                                                                    return teamsContext.getTeams({ active: 1, age: memberAge })
                                                                        .then((teams) => {
                                                                            return teams.map((team) => ({
                                                                                value: team.team_id,
                                                                                label: `${team.name} ${team.short_name ? '(' + team.short_name + ')' : ''}`,
                                                                                category: team.team_category,
                                                                                division: team.division,
                                                                            }));
                                                                        })
                                                                default:
                                                                    break;
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                                <Fade in={!!formik.values.targetTeamId} className={formik.values.targetTeamId ? 'd-block' : 'd-none'}>
                                                    <FormGroup>
                                                        <Label for='targetPositionId' className='text-muted'><Translate id='transfer.targetPosition' /> <Required /></Label>
                                                        <FormikSelect
                                                            name='targetPositionId'
                                                            id='targetPositionId'
                                                            clearable
                                                            renderOption={({ option: pos }) => (
                                                                <DisplayI18n
                                                                    field='name'
                                                                    defaultValue={pos.label}
                                                                    i18n={pos.i18n}
                                                                />
                                                            )}
                                                            searchKeys={[
                                                                `i18n.${getGenericLocale()}.name`,
                                                            ]}
                                                            onOptionSelected={(values, select) => {
                                                                const selectedPos = select.getSpordleTable().getData().find((option) => option.value === values[0]);
                                                                setPositionType(selectedPos?.positionType || '');
                                                            }}
                                                            loadData={(from) => {
                                                                switch (from){
                                                                    case 'CDM':
                                                                        return positionsContext.getPositions(organizationContext.organisation_id)
                                                                            .then((positions) => {
                                                                                return positions.map((position) => ({
                                                                                    value: position.position_id,
                                                                                    label: position.name,
                                                                                    i18n: position.i18n,
                                                                                    positionType: position.position_group.position_type,
                                                                                }))
                                                                            })
                                                                    default:
                                                                        break;
                                                                }
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    {(positionType === 'PLAYER') &&
                                                        <div className='d-flex'>
                                                            <FormGroup>
                                                                <div className='pr-3'>
                                                                    <FormikCheckedButton
                                                                        id='target_affiliate'
                                                                        name='target_affiliate'
                                                                        label='transfer.targetAffiliate'
                                                                        translateLabel

                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <div className='pr-3'>
                                                                    <FormikCheckedButton
                                                                        id='target_tryout'
                                                                        name='target_tryout'
                                                                        label='transfer.targetTryout'
                                                                        translateLabel
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <div>
                                                                    <FormikCheckedButton
                                                                        id='target_import'
                                                                        name='target_import'
                                                                        label='transfer.targetImport'
                                                                        translateLabel
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </div>
                                                    }
                                                </Fade>
                                            </>
                                        }
                                    </>
                            }
                            <FormGroup>
                                <Label for='details' className='text-muted'><Translate id='transfer.detail' /></Label>
                                <FormikTextArea id='details' name='details' rows='3' trim />
                            </FormGroup>
                            <FormGroup>
                                <Label for='attachments' className='text-muted'>
                                    <Translate id='transfer.uploadSupportingDocument' />
                                    {isNRP &&
                                            <Required className='ml-1' />
                                    }
                                </Label>
                                <FileUpload
                                    onFileSelect={(files) => {
                                        formik.setFieldValue('attachments', files);
                                    }}
                                    dropzoneProps={{
                                        multiple: true,
                                        accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                    }}
                                />
                                <FormikError name='attachments' />
                            </FormGroup>

                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                <Alert color='danger'>{formik.status}</Alert>
                            </Collapse>

                        </ModalBody>
                        <ModalFooter>
                            <Button color='primary' type='submit' disabled={formik.isSubmitting || !isOpen}><Translate id='transfer.submit' /></Button>
                            <Button color='primary' type='button' onClick={toggle} outline disabled={formik.isSubmitting || !isOpen}><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default TransferRequestModal;
