import PdfExport from "./PdfExport";

/**
 * @description Component that regroups export logic
 * @param {Object} props
 * @param {number|string} props.girNb
 * @returns {JSX.Element}
 */
const GameIncidentSidePanelExport = ({ gir }) => {
    return (
        <PdfExport gir={gir} />
    );
}

export default GameIncidentSidePanelExport;