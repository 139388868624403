import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';
import useSWR from 'swr';
import { I18nContext } from '../../../../contexts/I18nContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { displayI18n, DisplayI18n } from '../../../../helpers/i18nHelper';
import { DisplayCategory } from '../../../../views/teams/TeamHelpers';
import UserDisplay from '../../../userDisplay/UserDisplay';
import UserImg from '../../../UserImg';
import MemberTeamStatus from './../../../../views/members/profile/teams/MemberTeamStatus';
import moment from 'moment';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { Tooltip } from '@mantine/core';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';

const TeamsTab = ({ transfer, sidePanelProps }) => {
    const memberContext = useContext(MembersContext);
    const organizationContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const i18nContext = useContext(I18nContext);

    const { data: teams, error, isValidating } = useSWR(
        [ 'getMemberTeams', transfer.member.member_id ],
        () => Promise.all([
            organizationContext.getOrganizationsTree(),
            memberContext.getMemberTeams(transfer.member.member_id),
        ])
            .then(([ orgTree, memberTeams ]) => {
                //Flatten
                const orgTreeFlatten = orgTree.flattenArray('sub_organisations', (org) => ({
                    id: org?.organisation_id,
                }))

                return (memberTeams
                    .map((team) => ({
                        ...team,
                        hasAccess: organizationContext.organisation_id === identityRolesContext.federation?.organisation_id || organizationContext.organisation_id === team.team.organisation.organisation_id || orgTreeFlatten.some((org) => org.id == team.team?.organisation?.organisation_id),
                    }))
                    .sort((teamA, teamB) => {
                        const periodA = displayI18n('name', teamA.team.period.i18n, teamA.team.period.name, i18nContext.getGenericLocale())
                        const periodB = displayI18n('name', teamB.team.period.i18n, teamB.team.period.name, i18nContext.getGenericLocale())
                        return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(periodA, periodB) * -1
                    }));

            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            }),
        {
            revalidateOnFocus: false,
        },
    )

    return (
        <div className="p-3">
            {error ?
                <div className='text-dark text-center font-bold mb-0'>
                    <Translate id='misc.error' />
                </div>
                : !isValidating && teams ?
                    teams.length == 0 ?
                        <div className='text-muted text-center mb-0'>
                            <Translate id='transfer.sidepanel.tabs.team.none' />
                        </div>
                        :
                        <ListGroup>
                            {teams.map((team) => (
                                <ListGroupItem key={team.team.team_id + '__' + team.team.period.period_id}>
                                    {team.hasAccess ?
                                        <Link to={`/teams/profile/${team.team.team_id}`} onClick={sidePanelProps.forceCloseSidePanel}>
                                            <TeamDisplay team={team} />
                                        </Link>
                                        :
                                        <TeamDisplay team={team} />
                                    }
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    :
                    <Skeleton height={74} count={2} />
            }
        </div>
    );
}

const TeamDisplay = ({ team }) => {
    return (
        <UserDisplay>
            <UserDisplay.Container>
                <UserImg
                    abbr={team.team.abbreviation || team.team.short_name || team.team.name}
                    src={team.team.logo?.full_path}
                    filePos={team.team.logo?.file_position}
                    alt={team.team.name}
                    width={40}
                />
            </UserDisplay.Container>
            <UserDisplay.Container className="w-100">
                <UserDisplay.Title className="d-flex justify-content-between">
                    <TeamNameDisplay team={team} />
                    <div className='text-nowrap'><DisplayI18n field='name' defaultValue={team.team.period.name} i18n={team.team.period.i18n} /></div>
                </UserDisplay.Title>
                <UserDisplay.Subtitle>
                    <div className='d-flex'>
                        <DisplayCategory category={team.team.team_category} />
                    </div>
                </UserDisplay.Subtitle>
                <div className='d-flex w-100'>
                    <div className='d-block w-100'>
                        {team.release_date &&
                            <UserDisplay.Subtitle>
                                <Translate id='members.profile.teams.released' />: {moment(team.release_date).format('YYYY-MM-DD')}
                            </UserDisplay.Subtitle>
                        }
                    </div>
                    <div className='d-block w-100 text-right'>
                        {team.affiliate == 1 &&
                            <MemberTeamStatus
                                tip="teams.profile.roster.table.affiliated"
                                className="teamRoster-status mr-2"
                            >
                                <Translate id='teams.profile.roster.table.affiliated.short' />
                            </MemberTeamStatus>
                        }
                    </div>
                </div>
            </UserDisplay.Container>
        </UserDisplay>
    )
}

const TeamNameDisplay = ({ team }) => {
    return team.primary_team == 1 ?
        <Tooltip
            withArrow
            position='top'
            zIndex={9999}
            label={<Translate id='members.profile.teams.primaryTeam' />}
        >
            <div>{team.team.name}<i className={`pl-1 mdi mdi-star text-warning`} /></div>
        </Tooltip>
        :
        <div>{team.team.name}</div>;
}

export default TeamsTab;