import { useContext, useState } from "react";
import { DiscriminationAddContext } from "../components/DiscriminationAddContext";
import { CrossFade } from "../DiscriminationAddModal";
import GuiltyStepConfirm from "./substeps/GuiltyStepConfirm";
import GuiltyStepSearch from "./substeps/guiltyStepSearch/GuiltyStepSearch";

const DiscriminationAddModalStepGuilty = () => {
    const STEP_CONFIRM = 3;
    const STEP_SEARCH = 1;
    const { previous, fromMemberView } = useContext(DiscriminationAddContext);
    const [ guiltySubstep, setGuiltySubstep ] = useState(fromMemberView ? STEP_CONFIRM : STEP_SEARCH);

    const showConfirmGuilty = () => {
        setGuiltySubstep(STEP_CONFIRM);
    }

    return (
        <>
            {/* CONFIRM ALL COMPLAINEES STEP */}
            <CrossFade isVisible={guiltySubstep === STEP_CONFIRM}>
                {/* // TO DO : Make this 'has a member ?' instead */}
                <GuiltyStepConfirm
                    previous={fromMemberView ? previous : () => setGuiltySubstep(STEP_SEARCH)}
                    addMember={() => setGuiltySubstep(STEP_SEARCH)}
                />
            </CrossFade>
            <CrossFade isVisible={guiltySubstep === STEP_SEARCH}>
                <GuiltyStepSearch nextGuiltyStep={showConfirmGuilty} />
            </CrossFade>
        </>
    )
}

export default DiscriminationAddModalStepGuilty;