import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { MembersContext } from "../../../../../../../../contexts/MembersContext";
import { ComplaineeFormikFields } from "../../../../DiscriminationHelper";
import { string, object } from 'yup';
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import { Alert, Button, Collapse, Fade, FormGroup, ModalBody, ModalFooter } from "reactstrap";
import DiscriminationSearchResults from "../../../../modal/components/DiscriminationSearchResults";
import UserDisplay from "../../../../../../../../components/userDisplay/UserDisplay";
import ComplaineeTeamStep from "./ComplaineeTeamStep";
import { stringBuilder } from "@spordle/helpers";
import UserImg from "../../../../../../../../components/UserImg";
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import { OrganizationContext } from "../../../../../../../../contexts/OrganizationContext";
import InlineCopy from "../../../../../../../../components/inlineCopy/InlineCopy";

const ComplaineeEditMember = ({ isLoading, setIsLoading, member, updateMember, toggle, isTeam }) => {
    const { getMembers } = useContext(MembersContext);
    const { organisation_id } = useContext(OrganizationContext);
    const [ results, setResults ] = useState(null);
    const [ step, setStep ] = useState(1);

    return (
        <>
            <Formik
                initialValues={{
                    first_name: member?.first_name || "",
                    last_name: member?.last_name || "",
                    team_id: member.team?.team_id || '',
                    team_name: member.team_name || '',
                    role: member.role || '',
                    member: member.member,
                }}
                validationSchema={object().shape({
                    first_name: string().required(<Translate id='form.validation.firstName.required' />),
                    last_name: string().required(<Translate id='form.validation.lastName.required' />),
                    role: string().test({
                        name: 'shouldBeRequired',
                        message: <Translate id='discrimination.label.validation.role.required' />,
                        test: function(role){
                            return step !== 1 || !!role;
                        },
                    }),
                    member: object().nullable(),
                    team_id: string(),
                    team_name: string(),
                })}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    if(step === 1){
                        setIsLoading(true);
                        getMembers({
                            organisation_id: organisation_id,
                            first_name: values.first_name,
                            last_name: values.last_name,
                            all: 1,
                        })
                            .then((members) => {
                                if(members.length <= 0){
                                    setStatus(<Translate id='discrimination.addModal.stepComplainer.title.official.error' />);
                                }else{
                                    setResults(members);
                                    setStep(2);
                                    setIsLoading(false);
                                }
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    setIsLoading(false);
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                }
                            });
                        setIsLoading(false);
                        setSubmitting(false);
                    }else if(step === 3){
                        updateMember(values, setStatus);
                    }
                }}
            >
                {(formik) => (
                    <Form>
                        <Collapse isOpen={step === 2}>
                            <Fade in={step === 2}>
                                <DiscriminationSearchResults
                                    results={results}
                                    onMemberSelect={(member) => {
                                        formik.setFieldValue('member', member);
                                        setStep(3);
                                    }}
                                    previous={() => setStep(1)}
                                    subtitle={<Translate id='discrimination.addModal.matchingProfiles.step3.subtitle' values={{ count: results?.length || 0 }} />}
                                />
                            </Fade>
                        </Collapse>
                        <Collapse isOpen={step === 1}>
                            <Fade in={step === 1}>
                                <ModalBody>
                                    <div className={stringBuilder({ "card card-body card-shadow p-3 mb-0": isTeam })}>
                                        {isTeam &&
                                            <>
                                                <div className="font-bold h5 mb-2">
                                                    <Translate id='discrimination.addModal.stepGuilty.title' />
                                                </div>
                                                <p>
                                                    <Translate id='discrimination.addModal.stepGuilty.subtitle' />
                                                </p>
                                            </>
                                        }
                                        {!!formik.values.member &&
                                            <FormGroup>
                                                <div className="text-muted mb-2"><Translate id='discrimination.sidePanel.people.editModal.linkedMember' /></div>
                                                <UserDisplay card className="d-flex">
                                                    <UserDisplay.Container>
                                                        {member.first_name &&
                                                            <UserImg
                                                                width={50}
                                                                className="text-uppercase"
                                                                src={member.member?.picture?.full_path}
                                                                filePos={member.member?.picture?.file_position}
                                                                alt={member.first_name + ' ' + member.last_name}
                                                                abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                                            />
                                                        }
                                                    </UserDisplay.Container>
                                                    <UserDisplay.Container>
                                                        <UserDisplay.Title>{formik.values.member?.first_name + ' ' + formik.values.member?.last_name}</UserDisplay.Title>
                                                        {formik.values.member?.unique_identifier &&
                                                            <UserDisplay.Subtitle>
                                                                <InlineCopy toCopy={formik.values.member.unique_identifier}>
                                                                    #{formik.values.member.unique_identifier}
                                                                </InlineCopy>
                                                            </UserDisplay.Subtitle>
                                                        }
                                                    </UserDisplay.Container>
                                                    {/* <Button
                                                        onClick={() => setCollapseIsOpen(false)}
                                                        size="sm"
                                                        color="link"
                                                        className="p-2 text-danger position-absolute top-0 right-0"
                                                        type="button"
                                                    >
                                                        <Translate id="discrimination.sidePanel.people.editModal.linkedMember.btn.remove" />
                                                    </Button> */}
                                                </UserDisplay>
                                            </FormGroup>
                                        }
                                        <ComplaineeFormikFields />
                                        {formik.status &&
                                            <Collapse isOpen appear>
                                                <Alert color="danger">
                                                    {formik.status}
                                                </Alert>
                                            </Collapse>
                                        }
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button disabled={isLoading || formik.isSubmitting} color="primary" type="submit">
                                        <Translate id="misc.confirm" />
                                    </Button>
                                    <Button disabled={isLoading || formik.isSubmitting} color="primary" outline type="button" onClick={toggle}>
                                        <Translate id="misc.cancel" />
                                    </Button>
                                </ModalFooter>
                            </Fade>
                        </Collapse>
                        <Collapse isOpen={step === 3}>
                            <Fade in={step === 3}>
                                <ComplaineeTeamStep previous={() => setStep(results?.length > 0 ? 2 : 1)} currSubStep={step} />
                            </Fade>
                        </Collapse>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default ComplaineeEditMember;