import React from 'react';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { stringBuilder } from "@spordle/helpers";
import Svg from '../../../components/Svg';

// Language
import Translate from '@spordle/intl-elements';

// Context Providers
import withContexts from '../../../helpers/withContexts';
import { AuthContext, PeriodsContext, AppContext } from '../../../contexts/contexts';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { RolesContext } from '../../../contexts/RolesContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';

import Footer from '../footer/footer';
import UserImg from '../../../components/UserImg';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { Collapse } from 'reactstrap';
import { getSessionStorageItem, setSessionStorageItem } from '../../../helpers/browserStorage';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { activeRoute, getSidebarRoutes } from '../../../helpers/navigationHelper';

import SidebarLinks from './components/SidebarLinks';
import GroupedSidebarLinks from './components/GroupedSidebarLinks';
import FavoriteLinks from './components/history/FavoriteLinks';
import SidebarLink from './components/sidebarLink/SidebarLink';
import HistoryLinks from './components/history/HistoryLinks';
import { ReportsContext } from '../../../contexts/ReportsContext';
import HasAccessTo from '../../../components/permissions/HasAccessTo';
import { Tooltip } from '@mantine/core';
import { RoleCardAdditionalRoles } from '../../../views/organization/changeOrganization/RoleCardAdditionalRoles';

class Sidebar extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
            hasNavLabels: false,
            openedCollapse: {},
            shouldShowLink: false,
        }
    }

    /*--------------------------------------------------------------------------------*/
    /*Show Change Organization Link                                                   */
    /*--------------------------------------------------------------------------------*/
    shouldShowChangeOrgLink = () => {
        Promise.all([
            this.props.OrganizationContext.getOrganizationChildren(this.props.IdentityRolesContext.organisation.organisation_id),
            this.props.AuthContext.getUserIdentityRole(),
        ])
            .then(([ orgChildren, userIdentities ]) => {
                this.setState(() => ({ shouldShowLink: (orgChildren.length > 0 || userIdentities.length > 1) }));
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message);
                }
            })
    }

    /*--------------------------------------------------------------------------------*/
    /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
    /*--------------------------------------------------------------------------------*/
    expandMenu = () => {
        this.setState({ isOpen: true });
    }

    collapseMenu = () => {
        this.setState({ isOpen: false });
    }

    sidebarHandler = () => {
        this.props.AppContext.toggleSidebar();

        this.collapseMenu();
    };


    /*--------------------------------------------------------------------------------*/
    /* Grouped Routes & Collapse in sidebar                                           */
    /*--------------------------------------------------------------------------------*/
    hasGroupedRoutes = () => {
        return getSidebarRoutes()?.some((r) => r.navlabel)
    }

    toggleCollapse = (collapseName, isOpen) => {
        this.setState((prevState) => ({ openedCollapse: { ...prevState.openedCollapse, [collapseName]: isOpen } }), () => {
            setSessionStorageItem('sidebarOpenedCollapse', JSON.stringify(this.state.openedCollapse));
        })

    }

    checkCollapseIsOpen = (name) => {
        return this.state.openedCollapse?.[name] === undefined || this.state.openedCollapse[name] === true
    }

    /*--------------------------------------------------------------------------------*/
    /*Component Lifecycle                                                             */
    /*--------------------------------------------------------------------------------*/
    componentDidMount(){
        new Promise((resolve) => {
            resolve(JSON.parse(getSessionStorageItem('sidebarOpenedCollapse')));
        }).then((openedCollapse) => {
            this.setState(() => ({ openedCollapse: openedCollapse }));
        }).catch((error) => { console.error(error.message) });

        this.setState(() => ({ hasNavLabels: this.hasGroupedRoutes() }));

        this.shouldShowChangeOrgLink();

        // These need to be defined as follows because we want to use the native DOM event instead of the React event
        // We render a list of widget in the sidebar with the help of createPortal function.
        this.sideBar.addEventListener('mouseleave', this.collapseMenu);
        this.sideBarInner.addEventListener('mouseenter', this.expandMenu);
    }

    componentWillUnmount(){
        this.sideBar.removeEventListener('mouseleave', this.collapseMenu);
        this.sideBarInner.removeEventListener('mouseenter', this.expandMenu);
    }

    componentDidUpdate(prevProps){
        if(prevProps.location.pathname !== this.props.location.pathname){
            this.setState(() => ({ hasNavLabels: this.hasGroupedRoutes() }));
        }

        if(prevProps.IdentityRolesContext.organisation.organisation_id !== this.props.IdentityRolesContext.organisation.organisation_id){
            this.shouldShowChangeOrgLink();
        }
    }

    render(){
        return (
            <>
                <aside
                    ref={(r) => this.sideBar = r}
                    className={stringBuilder("sid-sidebar left-sidebar", { ["is-open"]: this.state.isOpen })}
                    id="sidebarbg"
                    data-sidebarbg="skin6"
                >
                    {!navigator?.maxTouchPoints >= 1 ?
                        <div
                            className="sid-sidebar-collapse-btn-container"
                            onClick={this.sidebarHandler}
                        >
                            <Tooltip withArrow openDelay={10} position='right' label={<Translate id={this.props.AppContext.isMiniSidebar ? "sidebar.expand" : "sidebar.collapse"} />}>
                                <button className="sid-sidebar-collapse-btn" type="button">
                                    <i className="mdi mdi-chevron-left" />
                                </button>
                            </Tooltip>
                        </div>
                        :
                        <div
                            className="sid-sidebar-collapse-btn-container"
                            onClick={this.sidebarHandler}
                        >
                            <button className="sid-sidebar-collapse-btn" type="button">
                                <i className="mdi mdi-chevron-left" />
                            </button>
                        </div>
                    }
                    <div className="sid-sidebar-inner" ref={(r) => this.sideBarInner = r}>
                        <div className="scroll-sidebar">
                            <PerfectScrollbar className="sidebar-nav d-flex flex-column">
                                <div className="border-bottom">
                                    <ul className="sid-sidebar-main-list m-2 py-2 hide-menu bg-light-inverse" id="sidebarnavmain">
                                        <SidebarLink id="sidebar-organization-link" skipFavorite to="/organization">
                                            <UserImg
                                                abbr={this.props.OrganizationContext.abbreviation}
                                                src={this.props.OrganizationContext.logo?.full_path}
                                                filePos={this.props.OrganizationContext.logo?.file_position}
                                                alt={this.props.OrganizationContext.short_url + ' logo'}
                                                width={30}
                                                className="mr-2 p-0"
                                            />
                                            <div className='sid-sidebar-org-name'>
                                                <div className="h5 font-medium text-wrap mb-0">
                                                    <div className='mr-1'>{this.props.RolesContext.title}</div>
                                                    {this.props.IdentityRolesContext.additional_role &&
                                                        <div className="position-absolute" style={{ top: "-5px", right: "-7px" }}>
                                                            <RoleCardAdditionalRoles identityRole={this.props.IdentityRolesContext} small />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="sid-sidebar-org-info">
                                                    <DisplayI18n field='name' defaultValue={this.props.OrganizationContext.organisation_name} i18n={this.props.OrganizationContext.i18n} />
                                                </div>
                                            </div>
                                        </SidebarLink>
                                        <Collapse className={stringBuilder({ 'selected': activeRoute("/changeorganization") }, "sidebar-item")} tag="li" isOpen={this.state.shouldShowLink}>
                                            <NavLink to="/changeorganization" className="w-100 sidebar-link">
                                                <div className={stringBuilder({ 'active': activeRoute("/changeorganization") })}>
                                                    <span className="text-dark">
                                                        <Svg width='25' height='18' icon="changeOrganization" />
                                                        <span><Translate id='sidebar.changeorganization' /></span>
                                                    </span>
                                                </div>
                                            </NavLink>
                                        </Collapse>
                                        <HasAccessTo
                                            className='sidebar-item'
                                            componentCode='communications'
                                            componentPermissionCode='communications_manage'
                                            tag='li'
                                        >
                                            {(isOnlyGod) => (
                                                <NavLink to="/communications" className="w-100 sidebar-link">
                                                    <div className={stringBuilder({ 'active': activeRoute("/communications") })}>
                                                        <span className={isOnlyGod ? "text-purple" : "text-dark"}>
                                                            <Svg width='25' height='18' icon="communications" />
                                                            <span><Translate id='sidebar.communications' /></span>
                                                        </span>
                                                    </div>
                                                </NavLink>
                                            )}
                                        </HasAccessTo>
                                        <HistoryLinks />
                                        <FavoriteLinks
                                            isOpen={this.checkCollapseIsOpen('recents')}
                                            toggle={() => this.toggleCollapse('recents', !this.checkCollapseIsOpen('recents'))}
                                        />
                                    </ul>
                                </div>
                                {this.state.hasNavLabels ?
                                    <div id="sidebarnav" className='sid-sidebar-list'>
                                        <GroupedSidebarLinks
                                            routes={getSidebarRoutes(this.props.ReportsContext.getReports)}
                                            toggleCollapse={this.toggleCollapse}
                                            checkCollapseIsOpen={this.checkCollapseIsOpen}
                                        />
                                    </div>
                                    :
                                    <SidebarLinks routes={getSidebarRoutes()} />
                                }
                                <Footer />
                            </PerfectScrollbar>
                        </div>
                    </div>
                </aside>
            </>
        );
    }
}

export default withContexts(AppContext, OrganizationContext, RolesContext, IdentityRolesContext, PeriodsContext, AuthContext, ReportsContext)(Sidebar);