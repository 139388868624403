import Translate from "@spordle/intl-elements"
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal"
import { WaiverContent, WaiverHeader, WaiverMandatoryHelper } from "../../../../../../helpers/waiversHelper"

const RegistrationFeesWaiverModal = ({ isOpen, waiver, toggle }) => {

    return (
        <AnalyticsModal analyticsName="registrationFeesWaiverModal" isOpen={isOpen}>
            <ModalHeader tag='div' toggle={toggle}>
                <WaiverHeader waiver={waiver} />
            </ModalHeader>
            <ModalBody>
                <WaiverContent waiver={waiver} />

                <WaiverMandatoryHelper waiver={waiver?.waiver} />
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-primary' type='button' onClick={toggle}><Translate id='misc.ok' /></button>
            </ModalFooter>
        </AnalyticsModal>
    )
}

export default RegistrationFeesWaiverModal