import { useContext, useState } from "react";
import {
    Row,
    Col
} from "reactstrap";
import { FormikDateTime, FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat, DateFormat } from "@spordle/intl-elements";
import moment from 'moment';
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import FormikEditable from '../../../../../../components/formik/FormikEditable';
import { mixed, object, string } from 'yup';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { fail, success } from '@spordle/toasts';

// contexts
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { RolesContext } from "../../../../../../contexts/RolesContext";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import RefundCreditModal from "./components/RefundCreditModal";
import useSWR from "swr";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";

const MemberProfileTransactionsSidepanelCredit = (props) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const [ refundCreditModalOpen, setRefundCreditModalOpen ] = useState(false);

    const membersContext = useContext(MembersContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const orgContext = useContext(OrganizationContext);

    const toggleRefundCreditModal = () => {
        setRefundCreditModalOpen(!refundCreditModalOpen)
    }

    const { data: refundInfo } = useSWR(
        [ 'getCreditRefundInfo', props.selectedRows[0].member_credit_id ],
        () => {
            setIsLoading(true);
            return membersContext.getRefundCreditInfo(orgContext.organisation_id, { member_credit_id: props.selectedRows[0].member_credit_id })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
                .finally(() => setIsLoading(false))
        },
        {
            fallbackData: [],
        },
    );

    return (
        <>
            <RefundCreditModal
                isOpen={refundCreditModalOpen}
                toggle={toggleRefundCreditModal}
                credit={props.selectedRows[0]}
                onCreditRefund={() => {
                    props.tableRef.refreshTable();
                    props.toggle();
                }}
            />

            <RolesContext.Consumer>
                {({ canDoAction }) => (
                    <OverlayLoader isLoading={isLoading}>
                        <div className="p-3 border-bottom">
                            <div className='d-flex mb-2'>
                                <button type='button' className='reset-btn p-2 text-dark' onClick={props.toggle}><i className='mdi mdi-arrow-right font-20' /></button>
                                {(canDoAction('EDIT', 'members', 'member_credit_refund') || canDoAction('DELETE', 'members', 'member_transactions') || identityRolesContext.isGod()) &&
                                    <div className="ml-auto d-flex align-items-center">
                                        <SidePanel.ActionsMenu>
                                            <SidePanel.MenuAction
                                                onClick={() => toggleRefundCreditModal()}
                                                disabled={parseInt(props.selectedRows[0].balance) === 0}
                                                action='ADD'
                                                componentCode='members'
                                                componentPermissionCode='member_credit_refund'
                                            >
                                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.refundCredit' />
                                            </SidePanel.MenuAction>
                                            <ConfirmModal
                                                modalTitle='members.profile.transactions.memberProfileTransactions.sidePanel.credit.delete.message'
                                                translateModalTitle
                                                onConfirm={() => {
                                                    setIsLoading(true)
                                                    membersContext.deleteMemberCredit(membersContext.currentMember.member_id, props.getCommonValue('member_credit_id'))
                                                        .then(() => {
                                                            props.tableRef.deleteRow(props.getCommonValue('member_credit_id')).then(props.forceCloseSidePanel)
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                        .finally(() => {
                                                            setIsLoading(false)
                                                        })
                                                }}
                                                toggler={(fn) => (
                                                    <SidePanel.MenuAction
                                                        onClick={fn}
                                                        disabled={parseInt(props.selectedRows[0].amount) !== parseInt(props.selectedRows[0].balance)}
                                                        action='DELETE'
                                                        componentCode='members'
                                                        componentPermissionCode='member_transactions'
                                                    >
                                                        <Translate id='misc.delete' />
                                                    </SidePanel.MenuAction>
                                                )
                                                }
                                            />
                                        </SidePanel.ActionsMenu>
                                    </div>
                                }
                            </div>
                            <div>
                                <div className='font-bold h4 mb-0'><Translate id='members.profile.transactions.memberProfileTransactions.credit' /></div>
                                <div className="text-muted"><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.credit.creationDate' />: {moment(props.selectedRows[0].invoiceDate).format('YYYY-MM-DD')}</div>
                            </div>
                        </div>

                        <div className="p-3">

                            {/* STATUS */}
                            <div className='mb-3'>
                                <div className="text-muted"><Translate id='members.profile.transactions.memberProfileTransactions.addModal.status' /></div>
                                <FormikEditable
                                    id="status"
                                    noConfirmation
                                    disabled={!canDoAction('EDIT', 'members', 'member_transactions')}
                                    initialValues={{
                                        status: props.selectedRows[0].active,
                                    }}
                                    validationSchema={object().shape({
                                        // should never be empty
                                        status: string().required(),
                                    })}
                                    onSubmit={(values) => {
                                        if(values.status !== props.selectedRows[0].active){
                                            setIsLoading(true)
                                            const newValues = props.createNewValues({ active: values.status });
                                            membersContext.updateMemberCredit(membersContext.currentMember.member_id, props.getCommonValue('member_credit_id'), newValues)
                                                .then(() => {
                                                    props.syncRows(newValues);
                                                    success({ msg: 'members.profile.transactions.memberProfileTransactions.sidePanel.credit.updated' });
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                                .finally(() => {
                                                    setIsLoading(false);
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options) => {
                                        if(!isEditing){
                                            return <div className='font-medium'><Translate id={'misc.status.' + props.selectedRows[0].active} /></div>
                                        }
                                        return (
                                            <FormikSelect
                                                name='status'
                                                id='status'
                                                autoFocus
                                                menuIsDefaultOpen
                                                onOptionSelected={options.stopEditing}
                                                loadingStatus='success'
                                                defaultData={[
                                                    {
                                                        label: 'misc.active',
                                                        value: '1',
                                                        translateLabel: true,
                                                    },
                                                    {
                                                        label: 'misc.inactive',
                                                        value: '0',
                                                        translateLabel: true,
                                                    },
                                                ]}
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </div>

                            {/* CREDIT TYPE */}
                            <div className='mb-3'>
                                <div className='text-muted'><Translate id='members.profile.transactions.memberProfileTransactions.addModal.creditType' /></div>
                                <div className='font-medium'><DisplayI18n field='name' defaultValue={props.selectedRows[0].credit_type.name} i18n={props.selectedRows[0].credit_type.i18n} /></div>
                            </div>

                            {/* EXPIRATION DATE */}
                            <div className='mb-3'>
                                <div className="text-muted"><Translate id='members.profile.transactions.memberProfileTransactions.addModal.expirationDate' /></div>
                                <FormikEditable
                                    id="expirationDate"
                                    disabled={!canDoAction('EDIT', 'members', 'member_transactions')}
                                    initialValues={{
                                        expiration_date: props.selectedRows[0].expiration_date ? moment(props.selectedRows[0].expiration_date) : '',
                                    }}
                                    validationSchema={object().shape({
                                        expiration_date: mixed().isDate(),
                                    })}
                                    onSubmit={(values) => {
                                        const newDate = values.expiration_date ? moment(values.expiration_date).format('YYYY-MM-DD') : null;
                                        if(newDate || newDate !== props.selectedRows[0].expiration_date){
                                            setIsLoading(true)
                                            const newValues = props.createNewValues({ expiration_date: newDate });
                                            membersContext.updateMemberCredit(membersContext.currentMember.member_id, props.getCommonValue('member_credit_id'), newValues)
                                                .then(() => {
                                                    props.syncRows(newValues);
                                                    success({ msg: 'members.profile.transactions.memberProfileTransactions.sidePanel.credit.updated' });
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                                .finally(() => {
                                                    setIsLoading(false);
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options) => {
                                        if(!isEditing){
                                            return <div className='font-medium'>{props.selectedRows[0].expiration_date ? <DateFormat value={props.selectedRows[0].expiration_date} /> : '-'}</div>
                                        }
                                        return (
                                            <FormikDateTime
                                                name='expiration_date'
                                                id='expiration_date'
                                                timeFormat={false}
                                                dateFormat='YYYY-MM-DD'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </div>

                            {/* AMOUNT */}
                            <div className='mb-3'>
                                <div className='text-muted'><Translate id='members.profile.transactions.memberProfileTransactions.addModal.amount' /></div>
                                <div className='font-medium'><CurrencyFormat value={props.selectedRows[0].amount / 100} /></div>
                            </div>

                            {/* BALANCE */}
                            <div className='mb-3'>
                                <div className='text-muted'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.credit.balance' /></div>
                                <div className='font-medium text-success'>
                                    <CurrencyFormat value={props.selectedRows[0].balance / 100} />
                                </div>
                            </div>

                            {/* NOTE */}
                            <div className='mb-3'>
                                <div className='text-muted'><Translate id='members.profile.transactions.memberProfileTransactions.addModal.note' /></div>
                                <div className='font-medium'>{props.selectedRows[0]?.note || '-'}</div>
                            </div>

                            {/* ITEMS */}
                            {/* <div className='mb-3'>
                                <div className="h4 font-bold"><Translate id='members.profile.transactions.memberProfileTransactions.items'/></div>
                                {this.props.selectedRows[0].values.map(item => {
                                    if(item.item.type === 'OTHER'){
                                        return(
                                            <Card className='card-shadow mb-3' key={item.invoice_item_id}>
                                                <UncontrolledDropdown tag="span" className='position-absolute top-0 right-0'>
                                                    <DropdownToggle tag="button" className="btn btn-link">
                                                        <i className="mdi mdi-dots-vertical font-22"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <DropdownItem><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.refund'/></DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                <CardBody>
                                                    <div className='h6 mb-3'>
                                                        <span className='font-bold'>
                                                            <DisplayI18n
                                                                field='name'
                                                                i18n={item.item.i18n}
                                                                defaultValue={item.item.name}
                                                            />
                                                        </span>
                                                        {parseInt(item.invoice_item_payments[0].amount) / item.amount > 1 &&
                                                            <>
                                                                <span className="mx-1"><i className="mdi mdi-close"></i></span>
                                                                <span>{parseInt(item.invoice_item_payments[0].amount) / item.amount}</span>
                                                            </>
                                                        }
                                                    </div>
                                                    <Row>
                                                        <Col md='6'>
                                                            <div className='text-muted'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.items.status'/></div>
                                                            <Badge color={item.invoice_item_payments[0].status === 'COMPLETED' ? 'success' : item.invoice_item_payments[0].status === 'PENDING' || item.invoice_item_payments[0].status === 'NEED_REVIEW' ? 'warning' : 'danger'} size="lg" pill className="px-3 align-self-start">
                                                                <Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.status.' + item.invoice_item_payments[0].status}/>
                                                            </Badge>
                                                        </Col>
                                                        <Col md='6'>
                                                            <div className='text-muted'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.items.amount'/></div>
                                                            <div><CurrencyFormat value={parseInt(item.invoice_item_payments[0].amount) / 100}/></div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        )
                                    }
                                })}
                            </div> */}

                            {/* REFUNDED */}
                            {refundInfo[0] &&
                                <div className='mt-3'>
                                    <div className='h5 font-bold'>
                                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.title' />
                                    </div>

                                    <Row>
                                        <Col sm='6'>
                                            <div className='mb-3'>
                                                <div className='text-muted'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.by' /></div>
                                                <div className='font-medium'>{refundInfo[0].identity.name} {refundInfo[0].identity.family_name}</div>
                                            </div>
                                        </Col>
                                        <Col sm='6'>
                                            <div className='mb-3'>
                                                <div className='text-muted'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.paymentMethod' /></div>
                                                <div className='font-medium'><DisplayI18n field='name' defaultValue={refundInfo[0].payment_method.name} i18n={refundInfo[0].payment_method.i18n} /></div>
                                            </div>
                                        </Col>
                                        <Col sm='6'>
                                            <div className='mb-3'>
                                                <div className='text-muted'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.amount' /></div>
                                                <div className='font-medium'><CurrencyFormat value={refundInfo[0].amount / 100} /></div>
                                            </div>
                                        </Col>
                                        <Col sm='6'>
                                            <div className='mb-3'>
                                                <div className='text-muted'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.date' /></div>
                                                <div className='font-medium'><DateFormat value={refundInfo[0].created_at} /></div>
                                            </div>
                                        </Col>
                                    </Row>

                                    {refundInfo[0].internal_note &&
                                        <div className='mb-3'>
                                            <div className='text-muted'><Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.credit.refunded.note' /></div>
                                            <div className='bg-light p-1 rounded'>
                                                {refundInfo[0].internal_note}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </OverlayLoader>
                )}
            </RolesContext.Consumer>
        </>
    )
}

export default MemberProfileTransactionsSidepanelCredit;