import { FormikInputNumber } from "@spordle/formik-elements"
import { useFormikContext } from "formik"
import { Col, FormGroup, Row } from "reactstrap"
import { filterHasValue, formikCodesHaveValues, handleFilterChange } from "../reportsEngineHelpers"
import FilterLabel from "./FilterLabel"
import Translate from "@spordle/intl-elements"

const EngineNumberBetween = ({ filter, filterRefs }) => {
    const formik = useFormikContext()
    return (
        <FormGroup>
            <Row form>
                <Col xs="6">
                    <FilterLabel skipTip filter={filter} suffix={<> - <Translate id="misc.from" /></>} />
                    <FormikInputNumber
                        {...filter.parsedParams?.componentProps}
                        id={`start_${filter.code}`}
                        name={`start_${filter.code}`}
                        className={`w-100 ${filter.parsedParams?.componentProps.className}`}
                        onChange={(value) => handleFilterChange(filter, formik, value, filterRefs)}
                        disabled={!formikCodesHaveValues(filter.parsedParams?.enabledWhen, formik) || !filterHasValue(filter.parsedParams?.enabledWhenValue?.filter, filter.parsedParams?.enabledWhenValue?.value, formik)}
                    />
                </Col>
                <Col xs="6">
                    <FilterLabel filter={filter} suffix={<> - <Translate id="misc.to" /></>} />
                    <FormikInputNumber
                        id={`end_${filter.code}`}
                        name={`end_${filter.code}`}
                        className={`w-100 ${filter.parsedParams?.componentProps.className}`}
                        onChange={(value) => handleFilterChange(filter, formik, value, filterRefs)}
                        disabled={!formikCodesHaveValues(filter.parsedParams?.enabledWhen, formik) || !filterHasValue(filter.parsedParams?.enabledWhenValue?.filter, filter.parsedParams?.enabledWhenValue?.value, formik)}
                    />
                </Col>
            </Row>
        </FormGroup>
    )
}

export default EngineNumberBetween