import { FormikDateTime, FormikError, FormikInputNumber, FormikInputText, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Collapse,
    FormGroup,
    Label,
    Row
} from "reactstrap";
import { array, mixed, number, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import CardSectionTitle from '../../../../../../../components/CardSectionTitle';
import Required from '../../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import AddTransferDurationsSelect from '../../../../../../../components/transferRequest/add/AddTransferDurationsSelect';
import AddTransferExtensionsSelect from '../../../../../../../components/transferRequest/add/AddTransferExtensionsSelect';
import AddTransferFederationInput from '../../../../../../../components/transferRequest/add/AddTransferFederationInput';
import AddTransferStatusSelect from '../../../../../../../components/transferRequest/add/AddTransferStatusSelect';
import AddTransferTypesSelect from '../../../../../../../components/transferRequest/add/AddTransferTypesSelect';
import AddTransferDirectionSelect from '../../../../../../../components/transferRequest/add/AddTransferDirectionSelect';
import { PeriodsContext } from '../../../../../../../contexts/contexts';
import { I18nContext } from '../../../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import FileUpload from '../../../../../../../components/uploader/fileUpload/FileUpload';
import { separateFilesPerSize } from '../../../../../../../components/uploader/uploadHelpers';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';

/**
* @param {bool} [international] Changes the title and 2 inputs (IIHF + EXT)
*/
const MemberProfileTransferAdd = ({ international, memberId, refreshTransfers }) => {
    const periodsContext = useContext(PeriodsContext);
    const membersContext = useContext(MembersContext);
    const { getGenericLocale } = useContext(I18nContext);
    const history = useHistory();

    const [ isOutgoingState, setIsOutgoingState ] = useState(true);

    // TODO: HARDCODED
    const isOutgoing = (name) => name === 'Outgoing';

    const getTransferCall = (apiValues) => {
        return international ?
            membersContext.createMemberInternationalTransferRequest(memberId, apiValues)
            :
            membersContext.createMemberTransferRequest(memberId, apiValues);
    }

    return (
        <Card className="card-shadow">
            <CardBody>
                <Formik
                    initialValues={{ // These values match the values for the api call -> Less/No formatting to do in onSubmit
                        usa_transfer_type_id: '',
                        transfer_duration_id: '',
                        iihf_card_number: '',
                        transfer_extension_id: '',
                        target_organisation_id: '',
                        from_organisation_id: membersContext.currentMember.organisation?.organisation_id,
                        transfer_direction_id: '',
                        target_team_name: '',
                        period_id: periodsContext.activePeriods.find((period) => period.current == 1)?.period_id || '',
                        transfer_status_id: '',
                        tryout_date: '',
                        sent_date: '',
                        approved_date: '',
                        transfer_with_parents: '',
                        notes: '',
                        team_release_fee: '',
                        release_fee_team_name: '',
                        attachments: [],
                    }}
                    validationSchema={object().shape({
                        usa_transfer_type_id: string().when('does_not_matter', { // USA only field -> Disable the validation when international
                            is: () => !international,
                            then: string().required(<Translate id='members.profile.transfers.add.fields.transferTypes.validation.required' />),
                        }),
                        transfer_duration_id: string().required(<Translate id='members.profile.transfers.add.fields.duration.validation.required' />),
                        iihf_card_number: string().when('does_not_matter', { // International only field -> Disable the validation when USA
                            is: () => !!international,
                            then: string().required(<Translate id='members.profile.transfers.add.fields.iihfCard.validation.required' />),
                        }),
                        transfer_extension_id: string().when('does_not_matter', { // International only field -> Disable the validation when USA
                            is: () => !!international,
                            then: string().required(<Translate id='members.profile.transfers.add.fields.extension.validation.required' />),
                        }),
                        target_organisation_id: string().required(<Translate id='members.profile.transfers.add.fields.federation.validation.required' />), // Should never happen
                        from_organisation_id: string().test({
                            name: 'Test',
                            message: <Translate id='members.profile.transfers.add.fields.fromTo.validation.same' />,
                            test: function(from){
                                return (this.parent.target_organisation_id !== from) || !international;
                            },
                        }),
                        transfer_direction_id: string().required(<Translate id='members.profile.transfers.add.fields.direction.validation.required' />), // Should never happen
                        target_team_name: string().required(<Translate id='members.profile.transfers.add.fields.team.validation.required' />),
                        period_id: string().required(<Translate id='members.profile.transfers.add.fields.season.validation.required' />),
                        transfer_status_id: string().when('does_not_matter', { // International only field -> Disable the validation when USA
                            is: () => !!international,
                            then: string().required(<Translate id='members.profile.transfers.add.fields.status.validation.required' />),
                        }),
                        tryout_date: mixed().test({
                            name: 'tryout_date-format',
                            message: <Translate id='form.validation.date.format' />,
                            test: function(date){
                                return date ? moment.isMoment(date) : true
                            },
                        }),
                        sent_date: mixed()
                            .required(<Translate id='members.profile.transfers.add.fields.sentDate.validation.required' />)
                            .test({
                                name: 'sent_date-valid',
                                message: <Translate id='form.validation.date.format' />,
                                test: moment.isMoment,
                            }),
                        approved_date: mixed()
                            .test({
                                name: 'approved_date-valid',
                                message: <Translate id='form.validation.date.format' />,
                                test: function(date){
                                    return date ? moment.isMoment(date) : true
                                },
                            }),
                        transfer_with_parents: string().when('does_not_matter', { // International only field -> Disable the validation when USA
                            is: () => !!international,
                            then: string().required(<Translate id='form.required' />),
                        }),
                        notes: string(),
                        team_release_fee: number(),
                        release_fee_team_name: string(),
                        attachments: array(),
                    })}
                    onSubmit={({
                        tryout_date,
                        approved_date,
                        notes,
                        usa_transfer_type_id,
                        iihf_card_number,
                        transfer_extension_id,
                        transfer_with_parents,
                        release_fee_team_name,
                        team_release_fee,
                        attachments,
                        transfer_status_id,
                        target_team_name,
                        ...values
                    }, { setSubmitting, setStatus }) => {
                        const formattedFiles = separateFilesPerSize(attachments);
                        const apiValues = {
                            ...values,
                            sent_date: values.sent_date.format('YYYY-MM-DD'),
                            transfer_type: international ? 'INTERNATIONAL' : 'USA',
                            attachments: formattedFiles.splice(0, 1)[0],
                        }

                        if(international){ // International only fields
                            apiValues.iihf_card_number = iihf_card_number;
                            apiValues.transfer_extension_id = transfer_extension_id;
                            apiValues.transfer_with_parents = transfer_with_parents;
                            apiValues.transfer_status_id = transfer_status_id;
                        }else{ // USA only fields
                            apiValues.usa_transfer_type_id = usa_transfer_type_id;
                        }

                        if(tryout_date){
                            apiValues.tryout_date = tryout_date.format('YYYY-MM-DD');
                        }
                        if(approved_date){
                            apiValues.approved_date = approved_date.format('YYYY-MM-DD');
                        }
                        if(notes){
                            apiValues.notes = notes;
                        }
                        if(team_release_fee){
                            apiValues.team_release_fee = Math.round(team_release_fee * 100);
                        }
                        if(release_fee_team_name){
                            apiValues.release_fee_team_name = release_fee_team_name;
                        }

                        if(!isOutgoingState){
                            apiValues.team_name = target_team_name;
                        }else{
                            apiValues.target_team_name = target_team_name;
                        }

                        setStatus();
                        getTransferCall(apiValues)
                            .then(async(memberTransferId) => {
                                if(formattedFiles.length > 0){
                                    await Promise.all(
                                        formattedFiles.map((subArr) => {
                                            return membersContext.createMemberTransferAttachment(memberId, memberTransferId, subArr);
                                        }),
                                    )
                                }
                            })
                            .then(() => {
                                refreshTransfers();
                                history.replace('history');
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    if(error.i18n){
                                        setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                    }else{
                                        switch (error.message){
                                            case '3335':// The member is already in a transfer
                                                setStatus(<Translate id={`members.profile.transfers.add.error.${error.message}`} />);
                                                break;
                                            case '3403':// The transfer couldn’t be initiated because the requested member has an active outstanding balance to settle
                                                setStatus(<Translate id={`transfer.error.${error.message}`} />);
                                                break;
                                            case '3416':// No transfer workflow found
                                            case '3417':// No transfer workflow found
                                                setStatus(<Translate id={`members.profile.transfers.add.error.${error.message}`} />);
                                                break;
                                            case '3441':// Taregt Team Transfers are blocked
                                                setStatus(<Translate id={`members.profile.transfers.add.error.${error.message}`} />);
                                                break;
                                            default:
                                                setStatus(<Translate id='misc.error' />);
                                                break;
                                        }
                                    }
                                    setSubmitting(false);
                                }
                            })
                    }}
                >
                    {(formik) => (
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <CardSectionTitle title={`members.profile.transfers.form.title.${international ? 'international' : 'usa'}`} />
                            <Form>
                                <div>
                                    <div className="h5 font-bold mb-3"><Translate id='members.profile.transfers.add.section.details' /></div>
                                    <Row form>
                                        {!international &&
                                            <>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <Label for='usa_transfer_type_id' className='text-muted'><Translate id='members.profile.transfers.add.fields.transferTypes.label' /> <Required /></Label>
                                                        <AddTransferTypesSelect setFieldValue={formik.setFieldValue} />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <Label for='period_id' className='text-muted'><Translate id="members.profile.transfers.add.fields.season.usa.label" /> <Required /></Label>
                                                        <FormikSelect
                                                            name='period_id'
                                                            id='period_id'
                                                            searchKeys={[
                                                                `i18n.${getGenericLocale()}.name`,
                                                            ]}
                                                            renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                                            options={periodsContext.periods.reduce((keptPeriods, period) => {
                                                                if(period.active == 1){
                                                                    keptPeriods.push({
                                                                        label: period.name,
                                                                        i18n: period.i18n,
                                                                        value: period.period_id,
                                                                    })
                                                                }
                                                                return keptPeriods;
                                                            }, [])}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        }
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label for='transfer_duration_id' className='text-muted'><Translate id='members.profile.transfers.add.fields.duration.label' /> <Required /></Label>
                                                <AddTransferDurationsSelect />
                                            </FormGroup>
                                        </Col>
                                        {international ?
                                            <>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <Label for='iihf_card_number' className='text-muted'><Translate id='members.profile.transfers.add.fields.iihfCard.label' /> <Required /></Label>
                                                        <FormikInputText id='iihf_card_number' name='iihf_card_number' trim />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <Label for='transfer_extension_id' className='text-muted'><Translate id='members.profile.transfers.add.fields.extension.label' /> <Required /></Label>
                                                        <AddTransferExtensionsSelect />
                                                    </FormGroup>
                                                </Col>
                                            </>
                                            :
                                            <Col lg='4'>
                                                <FormGroup>
                                                    <Label for='transfer_direction_id' className='text-muted'><Translate id='members.profile.transfers.add.fields.direction.label' /> <Required /></Label>
                                                    <AddTransferDirectionSelect
                                                        isOutgoing={isOutgoing}
                                                        onChange={([ value ], table) => {
                                                            const label = table.getSpordleTable().getData().find((d) => d.value === value)?.label;

                                                            if(isOutgoing(label)){
                                                                formik.setFieldValue('from_organisation_id', membersContext.currentMember.organisation?.organisation_id)
                                                                setIsOutgoingState(true)
                                                            }else{
                                                                formik.setFieldValue('target_organisation_id', membersContext.currentMember.organisation?.organisation_id)
                                                                setIsOutgoingState(false)
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                                <div className="mt-3">
                                    <div className="h5 font-bold mb-3">
                                        {international ?
                                            <Translate id='members.profile.transfers.add.fields.direction.label' />
                                            :
                                            <Translate id={`members.profile.transfers.add.section.${!isOutgoingState ? 'from' : 'to'}`} />
                                        }
                                    </div>
                                    <Row form>
                                        {international ?
                                            <>
                                                <Col lg='4'>
                                                    <FormGroup>
                                                        <Label for='transfer_direction_id' className='text-muted'><Translate id='members.profile.transfers.add.fields.direction.label' /> <Required /></Label>
                                                        <AddTransferDirectionSelect
                                                            isOutgoing={isOutgoing}
                                                            onChange={([ value ], table) => {
                                                                const label = table.getSpordleTable().getData().find((d) => d.value === value)?.label;
                                                                setIsOutgoingState(isOutgoing(label))
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <Label for='toFederation' className='text-muted'>
                                                            <Translate id='members.profile.transfers.add.section.from' /> <Translate id='members.profile.transfers.add.fields.federation.label' /> <Required />
                                                        </Label>
                                                        <AddTransferFederationInput international direction='incoming' />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <Label for='toFederation' className='text-muted'>
                                                            <Translate id='members.profile.transfers.add.section.to' /> <Translate id='members.profile.transfers.add.fields.federation.label' /> <Required />
                                                        </Label>
                                                        <AddTransferFederationInput international direction='outgoing' />
                                                    </FormGroup>
                                                </Col>
                                            </>
                                            :
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for='toFederation' className='text-muted'>
                                                        <Translate id='members.profile.transfers.add.fields.federation.label' /> <Required />
                                                    </Label>
                                                    <AddTransferFederationInput international={false} direction={isOutgoingState ? 'outgoing' : 'incoming'} />
                                                </FormGroup>
                                            </Col>
                                        }
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label for='target_team_name' className='text-muted'><Translate id='members.profile.transfers.add.fields.team.label' /> <Required /></Label>
                                                <FormikInputText id='target_team_name' name='target_team_name' trim />
                                            </FormGroup>
                                        </Col>
                                        {international &&
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for='period_id' className='text-muted'><Translate id='members.profile.transfers.add.fields.season.label' /> <Required /></Label>
                                                    <FormikSelect
                                                        name='period_id'
                                                        id='period_id'
                                                        searchKeys={[
                                                            `i18n.${getGenericLocale()}.name`,
                                                        ]}
                                                        renderOption={({ option }) => <DisplayI18n field="name" defaultValue={option.label} i18n={option.i18n} />}
                                                        options={periodsContext.periods.reduce((keptPeriods, period) => {
                                                            if(period.active == 1){
                                                                keptPeriods.push({
                                                                    label: period.name,
                                                                    i18n: period.i18n,
                                                                    value: period.period_id,
                                                                })
                                                            }
                                                            return keptPeriods;
                                                        }, [])}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                                <div className="mt-3">
                                    <div className="h5 font-bold mb-3"><Translate id='members.profile.transfers.add.section.status' /></div>
                                    <Row form>
                                        {international &&
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for='transfer_status_id' className='text-muted'><Translate id='members.profile.transfers.add.fields.status.label' /> <Required /></Label>
                                                    <AddTransferStatusSelect international={international} />
                                                </FormGroup>
                                            </Col>
                                        }
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label for='tryout_date' className='text-muted'><Translate id='members.profile.transfers.add.fields.tryOut.label' /> </Label>
                                                <FormikDateTime name='tryout_date' id='tryout_date' timeFormat={false} />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label for='sent_date' className='text-muted'><Translate id='members.profile.transfers.add.fields.sentDate.label' /> <Required /></Label>
                                                <FormikDateTime name='sent_date' id='sent_date' timeFormat={false} />
                                            </FormGroup>
                                        </Col>
                                        {international &&
                                            <>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <Label for='approved_date' className='text-muted'><Translate id='members.profile.transfers.add.fields.approveDate.label' /> </Label>
                                                        <FormikDateTime name='approved_date' id='approved_date' timeFormat={false} />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <Label for='transfer_with_parents' className='text-muted'><Translate id='members.profile.transfers.add.fields.withParent.label' /> <Required /></Label>
                                                        <FormikSelect
                                                            name='transfer_with_parents'
                                                            id='transfer_with_parents'
                                                            search={false}
                                                            loadingStatus='success'
                                                            defaultData={[
                                                                {
                                                                    label: 'misc.yes',
                                                                    value: 'YES',
                                                                    translateLabel: true,
                                                                },
                                                                {
                                                                    label: 'misc.no',
                                                                    value: 'NO',
                                                                    translateLabel: true,
                                                                },
                                                            ]}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        }
                                        <Col lg="12">
                                            <FormGroup>
                                                <Label for='attachments' className='text-muted'><Translate id='transfer.uploadSupportingDocument' /></Label>
                                                <FileUpload
                                                    onFileSelect={(files) => {
                                                        formik.setFieldValue('attachments', files);
                                                    }}
                                                    dropzoneProps={{
                                                        multiple: true,
                                                        accept: "image/jpeg, image/png, image/jpg, application/pdf, .doc, .docx, .odt, .xls, .xlsx, .txt, .ods",
                                                    }}
                                                />
                                                <FormikError name='attachments' />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="12">
                                            <FormGroup>
                                                <Label for='notes' className='text-muted'><Translate id='members.profile.transfers.add.fields.notes.label' /></Label>
                                                <FormikTextArea id='notes' name='notes' rows='5' trim />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label for='team_release_fee' className='text-muted'>
                                                    <Translate id='members.profile.transfers.add.fields.releaseFee.label' />
                                                </Label>
                                                <I18nContext.Consumer>
                                                    {(i18n) => (
                                                        <FormikInputNumber
                                                            allowLeadingZeros fixedDecimalScale
                                                            id='team_release_fee'
                                                            name='team_release_fee'
                                                            allowNegative={false}
                                                            decimalScale={2}
                                                            thousandSeparator=' '
                                                            decimalSeparator={i18n.getGenericLocale() === 'fr' ? ',' : '.'}
                                                            suffix={i18n.getGenericLocale() === 'fr' ? '$' : undefined}
                                                            prefix={i18n.getGenericLocale() !== 'fr' ? '$' : undefined}
                                                        />
                                                    )}
                                                </I18nContext.Consumer>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label for='release_fee_team_name' className='text-muted'>
                                                    <Translate id='members.profile.transfers.add.fields.releaseFeeTeam.label' />
                                                </Label>
                                                <FormikInputText id='release_fee_team_name' name='release_fee_team_name' trim />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>

                                <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                    <Alert color='danger'>{formik.status}</Alert>
                                </Collapse>

                                <div className="mt-3 text-right">
                                    <Button color="primary" className="mr-2" type='submit' disabled={formik.isSubmitting}><Translate id='misc.create' /></Button>
                                </div>
                            </Form>
                        </OverlayLoader>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
}

MemberProfileTransferAdd.propTypes = {
    international: PropTypes.bool,
}

MemberProfileTransferAdd.defaultProps = {
    international: false,
}

export default MemberProfileTransferAdd;