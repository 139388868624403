import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Alert, Button, Collapse, ModalBody, ModalFooter } from "reactstrap";
import { object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import { MembersContext } from "../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { CrossFade } from "../../../../../views/tasks/discrimination/components/modal/DiscriminationAddModal";
import { fail, success } from "@spordle/toasts";
import UserDisplay from "../../../../userDisplay/UserDisplay";
import CreateIdentity from "./CreateIdentity";
import IdentityCard from "./IdentityCard";

const InvoiceEditIdentity = ({ step, previous, getInvoice, toggle, invoice, isLoading, setIsLoading }) => {
    const [ memberAccounts, setMemberAccounts ] = useState(null);
    const [ createIsOpen, setCreateIsOpen ] = useState(false);
    const { getMemberAccounts, updateMemberInvoice } = useContext(MembersContext);
    const invoiceMembers = filterToUniqueMembers([ ...invoice.invoice_items ]);

    /**
     * Runs through all invoice_items to get the members and returns alla unique members by making sure they aren't returned twice in the same array
     * @param {object[]} items Invoice Items
     * @returns {object[]} Members
     */
    function filterToUniqueMembers(items){
        return items.reduce((members, item) => {
            if(!!item.member && !members.some((m) => m.member_id === item.member.member_id)) members.push({ ...item.member });
            return members;
        }, []);
    }


    /**
     * Gets the all the identities of all the members of the invoice
     * @returns {Promise}
     */
    const getAccounts = () => {
        setMemberAccounts(null);
        return Promise.all([
            ...invoiceMembers.map((mem) => (
                getMemberAccounts(mem?.member_id)
            )),
        ])
            .then((promises) => {
                // Need to make sure the accounts aren't repeated
                setMemberAccounts(promises.reduce((accounts, promiseAccounts) => {
                    for(let a = 0; a < promiseAccounts.length; a++){
                        const account = promiseAccounts[a];
                        if(!accounts.some((identity) => identity.identity_id === account.identity_id)){
                            accounts.push(account);
                        }
                    }
                    return accounts;
                }, []));
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setIsLoading(false);
                    setMemberAccounts([]);
                }
            })
    }

    /**
     * Links the invoice to the identity Id received as a param
     * Handles the refresh invoice, success && toggle logic
     * @async
     * @param {string} id identityId
     * @throws {Error}
     */
    const handleSubmitIdentity = async(id) => {
        await updateMemberInvoice(invoice.invoice_number, { identity_id: id })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        await getInvoice();

        success({
            msg: 'invoice.editModal.editIdentity.success.msg',
            info: 'invoice.editModal.editIdentity.success.info',
        })

        return toggle();
    }

    /**
     * Gets the accounts
     */
    useEffect(() => {
        if(step === 3 && memberAccounts === null){
            getAccounts();
        }
    }, [ step ]);

    return (
        <Formik
            initialValues={{
                identity_id: invoice.identity?.identity_id || '',
            }}
            validationSchema={object().shape({
                identity_id: string().required(<Translate id='memberProfile.registration.memberIdentities.required' />),
            })}
            onSubmit={(values, { setStatus }) => {
                if(values.identity_id !== invoice.identity?.identity_id){
                    setIsLoading(true);
                    handleSubmitIdentity(values.identity_id)
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                console.error(e);
                                setStatus(<Translate id="misc.error" />)
                                setIsLoading(false);
                            }
                        })
                }else{
                    toggle();
                }

            }}
        >
            {(formik) => (
                <>
                    <CrossFade isVisible={createIsOpen}>
                        <CreateIdentity
                            onSubmit={handleSubmitIdentity}
                            members={invoiceMembers}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            toggleCreate={() => {
                                setCreateIsOpen(false);
                            }}
                        />
                    </CrossFade>
                    <CrossFade isVisible={!createIsOpen}>
                        <Form>
                            <ModalBody>
                                {isLoading && memberAccounts === null ?
                                    <>
                                        <Skeleton count={2} width="100%" height={71} className="mb-1" />
                                        <Skeleton width="100%" height={50} className="mb-1" />
                                    </>
                                    :
                                    <>
                                        {
                                            memberAccounts?.map((identity) => (
                                                <IdentityCard
                                                    key={identity.identity_id}
                                                    identity={identity}
                                                />
                                            ))
                                        }
                                        <UserDisplay
                                            block
                                            hover
                                            card
                                            className="border-primary p-0 mb-2"
                                            style={{ borderStyle: 'dashed' }}
                                        >
                                            <button
                                                className='reset-btn text-link w-100 h-100 p-3'
                                                type='button'
                                                onClick={() => setCreateIsOpen(true)}
                                            >
                                                <i className='mdi mdi-plus' /> <Translate id='memberProfile.registration.memberIdentities.add' />
                                            </button>
                                        </UserDisplay>
                                    </>
                                }
                                {formik.status &&
                                    <Collapse appear isOpen>
                                        <Alert color="danger" toggle={() => formik.setStatus(null)}>
                                            {formik.status}
                                        </Alert>
                                    </Collapse>
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button disabled={isLoading || memberAccounts === null} className="mr-auto" color="primary" type="button" outline onClick={previous}>
                                    <Translate id="misc.previous" />
                                </Button>
                                <Button disabled={isLoading || memberAccounts === null} color='primary' type='submit'>
                                    <Translate id='misc.confirm' />
                                </Button>
                                <Button disabled={isLoading || memberAccounts === null} color='primary' outline type='button' onClick={toggle} className="ml-2">
                                    <Translate id='misc.cancel' />
                                </Button>
                            </ModalFooter>
                        </Form>
                    </CrossFade>
                </>
            )}
        </Formik>
    );
}

export default InvoiceEditIdentity;