import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';

// Contexts
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';

/** @type {React.Context<Omit<TransferContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const TransferContext = createContext();
TransferContext.displayName = 'TransferContext';

class TransferContextProvider extends React.Component{

    /** @private */
    _cachedUsaTransferTypes = null;

    /** @private */
    _cachedTransferDurations = null;

    /** @private */
    _cachedTransferExtensions = null;

    /** @private */
    _cachedTransferStatus = null;

    /**
     * Gets all transfer reasons
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Transfer%20Reason%20Types/e1702004d7b5b6fd19781c012d84ffb5 documentation}
     * @returns {Promise.<Array>}
     */
    getTransferReasons = (queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/transfer-reason-types',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.transfer_reason_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets all transfer reasons
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Transfer%20Status/Apicontroller%5CTransfers%5CTransferstatuses%3A%3AgetAllTransferStatus|documentation}
     * @param {boolean} [cacheResult=true] Cache in the new results from the api call
     * @param {boolean} [fromApi=false] Get the data from the api or not - Use to refresh the cached in results
     * @returns {Promise.<Array>}
     */
    getTransferStatus = (queryParams, cacheResult = true, fromApi = false) => {
        if(this._cachedTransferStatus && !fromApi){
            return Promise.resolve(this._cachedTransferStatus);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/transfer-status',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    if(cacheResult){
                        this._cachedTransferStatus = response.data.transfer_status;
                    }
                    return response.data.transfer_status;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets all transfer directions
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Transfer%20Directions/Apicontroller%5CTransfers%5CTransferdirections%3A%3AgetAllTransferDirections|documentation}
     * @returns {Promise.<Array>}
     */
    getTransferDirections = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/transfer-directions',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.transfer_directions;
                }
                throw response.data.errors[0];

            }, serverError)
    }

    /**
     * Gets all transfer extensions
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Transfer%20Extensions/Apicontroller%5CTransfers%5CTransferextensions%3A%3AgetAllTransferExtensions|documentation}
     * @param {boolean} [cacheResult=true] Cache in the new results from the api call
     * @param {boolean} [fromApi=false] Get the data from the api or not - Use to refresh the cached in results
     * @returns {Promise.<Array>}
     */
    getTransferExtensions = (queryParams = {}, cacheResult = true, fromApi = false) => {
        if(this._cachedTransferExtensions && !fromApi){
            return Promise.resolve(this._cachedTransferExtensions);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/transfer-extensions',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    if(cacheResult){
                        this._cachedTransferExtensions = response.data.transfer_extensions;
                    }
                    return response.data.transfer_extensions;
                }
                throw response.data.errors[0];

            }, serverError)
    }

    /**
     * Gets all transfer durations
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Transfer%20Durations/Apicontroller%5CTransfers%5CTransferdurations%3A%3AgetAllTransferDurations|documentation}
     * @param {boolean} [cacheResult=true] Cache in the new results from the api call
     * @param {boolean} [fromApi=false] Get the data from the api or not - Use to refresh the cached in results
     * @returns {Promise.<Array>}
     */
    getTransferDurations = (queryParams = {}, cacheResult = true, fromApi = false) => {
        if(this._cachedTransferDurations && !fromApi){
            return Promise.resolve(this._cachedTransferDurations);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/transfer-durations',
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    if(cacheResult){
                        this._cachedTransferDurations = response.data.transfer_durations;
                    }
                    return response.data.transfer_durations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets all USA transfer types
     * @param {string} [organisationId] The organisation for the types
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/USA%20Transfer%20Types/Apicontroller%5CTransfers%5CUsatransfertypes%3A%3AgetAllUsaTransferTypes|documentation}
     * @param {boolean} [cacheResult=true] Cache in the new results from the api call
     * @param {boolean} [fromApi=false] Get the data from the api or not - Use to refresh the cached in results
     * @returns {Promise.<Array>}
     */
    getUSATransferTypes = (organisationId = this.props.OrganizationContext.organisation_id, queryParams = {}, cacheResult = true, fromApi = false) => {
        if(this._cachedUsaTransferTypes && !fromApi){
            return Promise.resolve(this._cachedUsaTransferTypes);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/usa-transfer-types',
            query: Object.assign({
                organisation_id: organisationId,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    if(cacheResult){
                        this._cachedUsaTransferTypes = response.data.usa_transfer_types;
                    }
                    return response.data.usa_transfer_types;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    render(){
        return (
            <TransferContext.Provider value={{ ...this }}>
                {this.props.children}
            </TransferContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext)(TransferContextProvider);