// /* eslint-disable react/no-unused-class-component-methods */
import React, { createContext } from 'react';
import withContexts from '../helpers/withContexts';
import { AuthContext, PeriodsContext, AppContext } from './contexts';
import { RolesContext } from './RolesContext';
import { IdentityRolesContext } from './IdentityRolesContext';
import { OrganizationContext } from './OrganizationContext';
import { I18nContext } from './I18nContext';
import { MembersContext } from './MembersContext';
import { history } from '../helpers';
import { fail } from '@spordle/toasts';
import { AxiosCancelAll } from '../api/CancellableAPI';
import * as Sentry from "@sentry/react";

/** @type {React.Context<Omit<QuickViewContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'> & QuickViewContextProvider['state']>} */
export const QuickViewContext = createContext();
QuickViewContext.displayName = 'QuickViewContext';

class QuickViewContextProvider extends React.PureComponent{
    constructor(props){
        super(props);

        this.initActiveTabState = {
            tabIndex: 0,
            member_id: "",
            activeTab: "/overview",
            url: "/404",
        }

        this.state = {
            // Iframe QuickView states
            inIframe: window.self !== window.top,
            iframeIsOpen: false,

            // Parent QuickView Modal
            iframeIsLoaded: false,
            isOpen: false,

            // Used for syncing member profile tab and changing quick view tabs - used inside quickview iframe
            quickViewTabs: [],
            // quickViewTabs: [
            //     {
            //         "member_id": "1ebbaf2c-13a5-6cda-bbdc-026dd9a90ec0",
            //         "unique_identifier": "1500000760906",
            //         "first_name": "SHAUN",
            //         "last_name": "'COOPER",
            //         "currentMember": {}, // Used inside the quickview
            //     },
            // ],

            activeTabState: this.initActiveTabState,

        }

        // Set scroll on iframe document <body></body> tag to prevent layout shifts when changing quickview tabs
        if(this.state.inIframe){
            document.body.classList.add("quick-view-iframe-body");
        }
    }

    componentDidMount(){
        // Set postMessage event listeners
        if(this.state.inIframe){
            window.addEventListener("message", this.childIframeMessage, false);
        }else{
            window.addEventListener("message", this.parentWindowMessage, false);
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.state.inIframe){
            this._loadIframeContexts();
            if(prevState.activeTabState.member_id !== this.state.activeTabState.member_id)
                history.push(this.state.activeTabState.url);

        }else if(this._shouldCloseAllTabs(prevProps)){
            // Close tabs when changing role to prevent "forbidden" api calls in quickview
            this.closeAllQuickTabs();
        }
    }

    _shouldCloseAllTabs(prevProps){
        return prevProps.IdentityRolesContext.identity_role_id !== this.props.IdentityRolesContext.identity_role_id;
    }

    _setQuickViewTab = (member) => {
        return {
            member_id: member.member_id,
            unique_identifier: member.unique_identifier,
            first_name: member.first_name,
            last_name: member.last_name,
            currentMember: member,
        }
    }

    _loadIframeContexts = () => {
        let needsRefresh = false;
        if(window.frameElement && window.frameElement?.getAttribute('appcontext') && window.frameElement?.getAttribute('appcontext') !== JSON.stringify(this.props.AppContext.state))
            this.props.AppContext.setCurrentState({ ...JSON.parse(window.frameElement?.getAttribute('appcontext')) });
        if(window.frameElement && window.frameElement?.getAttribute('authcontext') && window.frameElement?.getAttribute('authcontext') !== JSON.stringify(this.props.AuthContext.state))
            this.props.AuthContext.setCurrentState({ ...JSON.parse(window.frameElement?.getAttribute('authcontext')) });
        if(window.frameElement && window.frameElement?.getAttribute('i18ncontext') && window.frameElement?.getAttribute('i18ncontext') !== JSON.stringify(this.props.I18nContext.state))
            this.props.I18nContext.setCurrentState({ ...JSON.parse(window.frameElement?.getAttribute('i18ncontext')) });

        if(window.frameElement && window.frameElement?.getAttribute('organizationcontext') && window.frameElement?.getAttribute('organizationcontext') !== JSON.stringify(this.props.OrganizationContext.state)){
            needsRefresh = true;
            this.props.OrganizationContext.setCurrentState({ ...JSON.parse(window.frameElement?.getAttribute('organizationcontext')) });
        }
        if(window.frameElement && window.frameElement?.getAttribute('identityrolescontext') && window.frameElement?.getAttribute('identityrolescontext') !== JSON.stringify(this.props.IdentityRolesContext.state)){
            needsRefresh = true;
            this.props.IdentityRolesContext.setCurrentState({ ...JSON.parse(window.frameElement?.getAttribute('identityrolescontext')) });
        }
        if(window.frameElement && window.frameElement?.getAttribute('rolescontext') && window.frameElement?.getAttribute('rolescontext') !== JSON.stringify(this.props.RolesContext.state)){
            needsRefresh = true;
            this.props.RolesContext.setCurrentState({ ...JSON.parse(window.frameElement?.getAttribute('rolescontext')) });
        }
        if(window.frameElement && window.frameElement?.getAttribute('periodscontext') && window.frameElement?.getAttribute('periodscontext') !== JSON.stringify(this.props.PeriodsContext.state)){
            needsRefresh = true;
            this.props.PeriodsContext.setCurrentState({ ...JSON.parse(window.frameElement?.getAttribute('periodscontext')) });
        }

        if(needsRefresh)
            this.props.AppContext.updateRouteKey();
    }

    _animateQuickViewToggleBadge = () => {
        setTimeout(() => {
            document.getElementById('quick-view-header-toggle-badge')?.classList.add("animate");
            setTimeout(() => document.getElementById('quick-view-header-toggle-badge')?.classList.remove("animate"), 2000);
        }, 500);
    }

    /**
     * Parent Window Message Receive Message
     * @param {event} event
     * @returns {Promise}
    */
    parentWindowMessage = (event) => {
        if(process.env.NODE_ENV === 'development' || event.origin === window.location.origin){
            if(typeof (this[event.data.func]) === "function" && event.data.func == 'toggle'){
                this[event.data.func].call(null, event.data.isOpen, event.data.newTabs);
            }
        }else{
            this.closeAllQuickTabs();
        }
    }

    /**
     * Iframe Window Message Receive Message
     * @param {event} event
     * @returns {Promise}
    */
    childIframeMessage = (event) => {
        if(process.env.NODE_ENV === 'development' || event.origin === window.location.origin){
            if(typeof (this[event.data.func]) === "function" && event.data.func == 'receiveOpenMemberQuickView'){
                this[event.data.func].call(null, event.data.member);
            }
            if(typeof (this[event.data.func]) === "function" && event.data.func == 'receiveOpenMultiMemberQuickView'){
                this[event.data.func].call(null, event.data.members);
            }
            if(event.data.func == 'receiveRefreshIframeContent'){
                this.refreshIframeContent();
            }
            if(event.data.func == 'receiveCloseAll'){
                this.setState(() => ({
                    isOpen: false,
                    quickViewTabs: [],
                    activeTabState: this.initActiveTabState,
                }))
            }
        }else{
            this.closeAllQuickTabs();
        }
    }


    // --------

    /**
     * Controls for the Quick View Modal
    */
    toggle = (newIsOpen = undefined, newQuickViewTabs = undefined) => {
        if(this.state.inIframe){
            this.setState(() => {
                return { iframeIsOpen: false }
            }, () => {
                window.parent.postMessage({
                    func: "toggle",
                    isOpen: false,
                    newTabs: this.state.quickViewTabs,
                }, window.location.origin);
            });

        }else{
            this.setState((prevState) => {
                const open = newIsOpen !== undefined ? newIsOpen : prevState.inIframe ? false : !prevState.isOpen;
                const tabs = newQuickViewTabs !== undefined ? newQuickViewTabs : prevState.quickViewTabs;

                // Re-animate badge after modal closes
                if(!open && (Array.isArray(tabs) && tabs.length > 0)){
                    this._animateQuickViewToggleBadge();
                }

                return {
                    isOpen: open, // Don't let the iframe open the quickview itself...
                    quickViewTabs: tabs,
                }
            }, () => {
                document.getElementById('quick-view-iframe')?.contentWindow?.postMessage({
                    func: "receiveRefreshIframeContent",
                }, window.location.origin)
            });
        }
    }

    /**
     * Called when changing QuickView tabs - works by index
     * This is used inside quickview iframe
     * @param {number|string} newTabIndex
    */
    setCurrentTabindex = (newTabIndex) => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: 'Changed quickview tabs',
            level: Sentry.Severity.Log,
            category: 'QuickView',
        })
        this.setState((prevState) => {
            const member_id = prevState.quickViewTabs?.[newTabIndex]?.member_id;
            const newUrl = `/members/profile/${member_id}${prevState.activeTabState.activeTab || ''}`;

            if(!member_id)
                return null

            // If we have an existing tab, preset the currently cached current member - quick view ember profile will make sure to reget and re-validate
            const existingTab = prevState.quickViewTabs.find((tab) => tab.member_id === member_id);
            this.props.MembersContext.presetCurrentMember(existingTab.currentMember);
            return {
                activeTabState: {
                    tabIndex: newTabIndex,
                    member_id: member_id,
                    url: newUrl, // componentDidUpdate will trigger history.push
                    activeTab: prevState.activeTabState.activeTab,
                },
            };
        });
    }

    /**
     * Function called when clicking on a member in the platform to QuickView
     * This will manage if the tab is already opened
     * If not it adds the tab and sets it as the current tab
     * @param {object|Array} member
    */
    openMemberQuickView = (member) => {
        let newTabs = [];
        const hasMembers = member.member_id || (Array.isArray(member) && member.length > 0)
        // Make sure we currently aren't already in the quickView - this would cause looping bugs
        if(!this.state.inIframe && !this.state.isOpen && hasMembers){
            // Default single open - will manage the current tab index
            if(member.member_id){
                document.getElementById('quick-view-iframe')?.contentWindow?.postMessage({
                    func: "receiveOpenMemberQuickView",
                    member: member,
                }, window.location.origin)
                newTabs = [ this._setQuickViewTab(member) ];

            // If we want to open multiple members at once (used in roster & multi member search sidepanel)
            }else if(Array.isArray(member) && member.length > 0){
                document.getElementById('quick-view-iframe')?.contentWindow?.postMessage({
                    func: "receiveOpenMultiMemberQuickView",
                    members: member,
                }, window.location.origin)
                newTabs = member.map((m) => this._setQuickViewTab(m));
            }

            this.setState((prevState) => {
                // Filter out existing tabs
                const filteredTabs = newTabs.reduce((newArray, member) => {
                    if(member.member_id){
                        const existingTab = prevState.quickViewTabs.findIndex((tab) => tab.member_id === member.member_id);
                        if(existingTab == -1){
                            newArray.push(this._setQuickViewTab(member));
                        }
                    }
                    return newArray;
                }, []);

                return {
                    isOpen: true,
                    quickViewTabs: [
                        ...prevState.quickViewTabs,
                        ...filteredTabs,
                    ],
                }
            });
        }
    }

    receiveOpenMemberQuickView = (member) => {
        AxiosCancelAll();
        if(member.member_id){
            // If we have an existing tab, preset the currently cached current member - quick view ember profile will make sure to reget and re-validate
            const existingTab = this.state.quickViewTabs.find((tab) => tab.member_id === member.member_id);
            Promise.resolve(() => {
                if(existingTab && existingTab.currentMember && existingTab.currentMember?.member_id)
                    this.props.MembersContext.presetCurrentMember(existingTab.currentMember);
                else
                    this.props.MembersContext.presetCurrentMember({ ...member })
            })
                .then(() => {

                    this.setState((prevState) => {
                        const getNewQuickViewTabs = () => {
                            if(existingTab){
                                return prevState.quickViewTabs.reduce((newTabs, t) => {
                                    const newCurrentMember = (t.member_id === member?.member_id) ? member : t.currentMember
                                    if(newCurrentMember.member_id){
                                        newTabs.push(this._setQuickViewTab(newCurrentMember))
                                    }
                                    return newTabs
                                }, [])
                            }
                            return [
                                ...prevState.quickViewTabs,
                                this._setQuickViewTab(member),
                            ]
                        }

                        const newUrl = `/members/profile/${member.member_id}${prevState.activeTabState.activeTab || ''}`;
                        const newTabs = getNewQuickViewTabs();
                        return {
                            iframeIsOpen: true,
                            quickViewTabs: newTabs,
                            activeTabState: {
                                ...prevState.activeTabState,
                                tabIndex: newTabs.findIndex((tab) => tab.member_id === member.member_id),
                                member_id: member.member_id,
                                url: newUrl, // componentDidUpdate will trigger history push
                            },
                        };
                    });

                })
                .catch((error) => { console.error(error.message) });
        }
    }

    receiveOpenMultiMemberQuickView = (members) => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: 'opened multi member quickview',
            level: Sentry.Severity.Log,
            category: 'QuickView',
            data: {
                members: members,
            },
        })
        AxiosCancelAll();
        const lastOpenedMember = members[members.length - 1];
        this.setState((prevState) => {
            const newTabs = members.reduce((newArray, member) => {
                if(member.member_id){
                    const existingTab = prevState.quickViewTabs.findIndex((tab) => tab.member_id === member.member_id);
                    if(existingTab == -1){
                        newArray.push(this._setQuickViewTab(member));
                    }
                }
                return newArray;
            }, []);

            const newUrl = `/members/profile/${lastOpenedMember.member_id}${prevState.activeTabState.activeTab || ''}`;
            const newTabsSet = [ ...prevState.quickViewTabs, ...newTabs ];
            return {
                isOpen: true,
                quickViewTabs: newTabsSet,
                activeTabState: {
                    iframeIsOpen: true,
                    tabIndex: newTabsSet.length - 1,
                    member_id: lastOpenedMember.member_id,
                    activeTab: prevState.activeTabState.activeTab,
                    url: newUrl, // url push will be triggered in componentDidUpdate
                },
            };
        });
    }

    /**
     * Used for reordering the quick view tabs with the drag and drop
     * Need to manage active tab index
     * @param {number|string} tabIndex
     * @param {number|string} destinationIndex
    */
    reorderQuickViewTabs = (tabIndex, destinationIndex) => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: 'Quickview tab reordered',
            level: Sentry.Severity.Log,
            category: 'QuickView',
            data: {
                tabIndex: tabIndex,
                destinationIndex: destinationIndex,
            },
        })
        this.setState((prevState) => {
            const newData = [ ...prevState.quickViewTabs ]
            newData.splice(destinationIndex, 0, newData.splice(tabIndex, 1)[0])
            return {
                quickViewTabs: newData,
                activeTabState: {
                    ...prevState.activeTabState,
                    tabIndex: newData.findIndex((tab) => tab.member_id === prevState.activeTabState.member_id),
                },
            }
        });
    }

    onCloseAllTabs = (isInIframe) => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: 'onCloseAllTabs function ran, (this might not be caused by closing all tabs using the close all tabs button)',
            level: Sentry.Severity.Log,
            category: 'QuickView',
        })
        if(isInIframe){
            window.parent.postMessage({
                func: "toggle",
                isOpen: false,
                newTabs: [],
            }, window.location.origin)
        }else if(document.getElementById('quick-view-iframe')){
            document.getElementById('quick-view-iframe')?.contentWindow?.postMessage({
                func: "receiveCloseAll",
            }, window.location.origin)
        }

        return {
            isOpen: false,
            quickViewTabs: [],
            activeTabState: this.initActiveTabState,
        }
    }

    /**
     * Function called when closing a quickview tab
     * This will manage the next tab to set
     * @param {number|string} tabIndex
    */
    closeQuickViewTab = (tabIndex) => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: 'Close quickview tab',
            level: Sentry.Severity.Log,
            category: 'QuickView',
            data: { tabIndex: tabIndex },
        })
        this.setState((prevState) => {
            // const removedMemberId = prevState.quickViewTabs[tabIndex].member_id;
            // Remove quick view tab
            const newTabs = prevState.quickViewTabs.filter((_, key) => key !== tabIndex);
            // Set current tab to next available tab - close when no more active tabs
            let newTabIndex = newTabs.findIndex((tab) => tab.member_id === prevState.activeTabState.member_id)

            // If we're closing the currently active tab
            if(tabIndex === prevState.activeTabState.tabIndex){
                // If the current tab to close is first, stay on first tab. Else we focus the next tab in the list
                if(tabIndex === 0)
                    newTabIndex = 0
                else
                    newTabIndex = tabIndex - 1
            }

            if(newTabs.length <= 0 || newTabIndex <= -1){
                return this.onCloseAllTabs(prevState.inIframe);
            }else if(newTabs[newTabIndex]?.member_id){
                const member_id = newTabs[newTabIndex].member_id;
                const newUrl = `/members/profile/${member_id}${prevState.activeTabState.activeTab || ''}`;

                // If we have an existing tab, preset the currently cached current member - quick view ember profile will make sure to reget and re-validate
                const existingTab = newTabs[newTabIndex];
                if(existingTab && existingTab.currentMember && existingTab.currentMember?.member_id)
                    this.props.MembersContext.presetCurrentMember(existingTab.currentMember);
                else
                    this.props.MembersContext.presetCurrentMember({ member_id: member_id })

                // URL will be triggerend in compoenentDidUpdate
                return {
                    activeTabState: {
                        tabIndex: newTabIndex,
                        member_id: member_id,
                        activeTab: prevState.activeTabState.activeTab,
                        url: newUrl,
                    },
                    quickViewTabs: newTabs,
                };
            }

            return { quickViewTabs: newTabs }
        });
    }

    closeAllQuickTabs = () => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: 'Close all quickview tabs',
            level: Sentry.Severity.Log,
            category: 'QuickView',
        })
        this.setState((prevState) => this.onCloseAllTabs(prevState.inIframe));
    }


    /*--------------------------------------------------------------------------------*/
    /*--------------------------------------------------------------------------------*/
    /*--------------------- QUICKVIEW IFRAME FUNCTIONS -------------------------------*/
    /*--------------------------------------------------------------------------------*/
    /*--------------------------------------------------------------------------------*/
    setIframeIsLoaded = (isLoaded) => this.setState(() => ({ iframeIsLoaded: isLoaded }));

    refreshIframeContent = () => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: 'Refreshed all quickview tabs',
            level: Sentry.Severity.Log,
            category: 'QuickView',
        })
        this._loadIframeContexts();
        this.props.AppContext.updateRouteKey();
    }

    /**
     * Function called by QuickView member profile after it has loaded the current member inside the quickview
    */
    setTabCurrentMemberState = (newLoadedMember) => {
        if(this.state.inIframe){
            this.setState((prevState) => {
                const existingTab = prevState.quickViewTabs.findIndex((tab) => tab.member_id === newLoadedMember.member_id);
                if(existingTab == -1){
                    return {
                        quickViewTabs: [
                            ...prevState.quickViewTabs,
                            this._setQuickViewTab(newLoadedMember),
                        ],
                    }
                }
                return {
                    quickViewTabs: prevState.quickViewTabs.reduce((newTabs, t) => {
                        newTabs.push(this._setQuickViewTab((t.member_id === newLoadedMember?.member_id) ? newLoadedMember : t.currentMember))
                        return newTabs
                    }, []),
                }
            });
        }
    }

    /**
     * Will keep tract of current member profile url in the quick view state
     * This lets us simulate the tabs / tabpanes within the profile
     * @param {string} tabUrl
    */
    setQuickViewMemberProfileUrl = (tabUrl) => {
        if(this.state.inIframe){
            const newUrl = `/members/profile/${this.state.activeTabState.member_id}${tabUrl}`

            this.setState((prevState) => {
                const newData = {
                    ...prevState.activeTabState,
                    activeTab: tabUrl,
                    url: newUrl,
                }
                return { activeTabState: newData }
            })
        }
    };

    /**
     * Validate a link to see if link is compatible with current quick view tab
     * @param {href} href
    */
    validateQuickViewLink = (href) => {
        if(this.state.activeTabState.member_id){
            if(href.indexOf("members/profile/" + this.state.activeTabState.member_id) === -1){
                fail({
                    msg: 'misc.quickview.navigation.blocked',
                    info: 'misc.quickview.navigation.blocked.info',
                })
                return false
            }
        }else if(this.state.activeTabState.team_id){
            if(href.indexOf("teams/profile/" + this.state.activeTabState.team_id) === -1)
                return false
        }
        return true;
    }

    /*--------------------------------------------------------------------------------*/
    /*--------------------------------------------------------------------------------*/
    /*--------------------- OTHER FUNTIONS -------------------------------------------*/
    /*--------------------------------------------------------------------------------*/
    /*--------------------------------------------------------------------------------*/


    render(){
        return (
            <QuickViewContext.Provider value={{ ...this.state, ...this }}>
                {this.props.children}
            </QuickViewContext.Provider>
        );
    }
}

export default withContexts(AuthContext, IdentityRolesContext, OrganizationContext, RolesContext, PeriodsContext, MembersContext, I18nContext, AppContext)(QuickViewContextProvider);
