export default{
    "organization.grouping.category.ZONES"   : "Zone",
    "organization.grouping.category.LEAGUES" : "Ligue",

    "organization.grouping.add.title.ZONES"                     : "Ajouter une zone",
    "organization.grouping.add.title.TOURNAMENTS"               : "Ajouter un tournoi",
    "organization.grouping.add.title.LEAGUES"                   : "Ajouter une ligue",
    "organization.grouping.add.createOrg.orgCategory"           : "Nous n'avons pas pu trouver la catégorie {category} pour l'organisation parente.",
    "organization.grouping.add.selectOrg.text.ZONES"            : "Veuillez sélectionner les organisations qui feront partie de votre zone.",
    "organization.grouping.add.selectOrg.text.TOURNAMENTS"      : "Veuillez sélectionner les organisations qui feront partie de votre tournoi.",
    "organization.grouping.add.selectOrg.text.LEAGUES"          : "Veuillez sélectionner les organisations qui feront partie de votre ligue.",
    "organization.grouping.add.selectOrg.alreadyAdded"          : "Déjà ajoutée",
    "organization.grouping.add.selectOrg.orgs.required"         : "Veuillez sélectionner au moins une organisation.",
    "organization.grouping.add.selectOrg.org"                   : "Organisation",
    "organization.grouping.add.selectOrg.orgs"                  : "Organisations",
    "organization.grouping.add.selectOrg.org.required"          : "L'organisation est requise.",
    "organization.grouping.add.selectOrg.divisions"             : "Divisions",
    "organization.grouping.add.selectOrg.divisions.required"    : "Veuillez sélectionner au moins une division.",
    "organization.grouping.add.selectOrg.categories"            : "Catégories",
    "organization.grouping.add.createUser.text.ZONES"           : "Veuillez sélectionner votre rôle dans la zone et y ajouter d'autres utilisateurs si nécessaire.",
    "organization.grouping.add.createUser.text.TOURNAMENTS"     : "Veuillez sélectionner votre rôle dans le tournoi et y ajouter d'autres utilisateurs si nécessaire.",
    "organization.grouping.add.createUser.text.LEAGUES"         : "Veuillez sélectionner votre rôle dans la ligue et y ajouter d'autres utilisateurs si nécessaire.",
    "organization.grouping.add.createUser.role.required"        : "Le rôle est requis.",
    "organization.grouping.add.confirmation.text.ZONES"         : "Votre zone est créée!",
    "organization.grouping.add.confirmation.text.TOURNAMENTS"   : "Votre tournoi est créé!",
    "organization.grouping.add.confirmation.text.LEAGUES"       : "Votre ligue est créée!",
    "organization.grouping.add.confirmation.manage.ZONES"       : "Gérer la zone",
    "organization.grouping.add.confirmation.manage.TOURNAMENTS" : "Gérer le tournoi",
    "organization.grouping.add.confirmation.manage.LEAGUES"     : "Gérer la ligue",

    "organization.grouping.sidePanel.tabs.orgs"                    : "Organisations",
    "organization.grouping.sidePanel.tabs.competitions"            : "Compétitions",
    "organization.grouping.sidePanel.noTeams"                      : "Aucune équipe",
    "organization.grouping.sidePanel.addTeam"                      : "Ajouter une équipe",
    "organization.grouping.sidePanel.addTeam.subtitle.LEAGUES"     : "Cliquez ici pour ajouter une équipe à votre ligue",
    "organization.grouping.sidePanel.addTeam.subtitle.TOURNAMENTS" : "Cliquez ici pour ajouter une équipe à votre tournoi",
    "organization.grouping.sidePanel.addOrg"                       : "Ajouter une organisation",
    "organization.grouping.sidePanel.addOrg.subtitle.LEAGUES"      : "Cliquez ici pour ajouter une organisation à votre ligue",
    "organization.grouping.sidePanel.addOrg.subtitle.ZONES"        : "Cliquez ici pour ajouter une organisation à votre zone",
    "organization.grouping.sidePanel.addOrg.subtitle.TOURNAMENTS"  : "Cliquez ici pour ajouter une organisation à votre tournoi",
    "organization.grouping.sidePanel.addOrg.comp.subtitle"         : "Ajoutez une organisation pour générer des compétitions",
    "organization.grouping.sidePanel.edit.title"                   : "Modifier la compétition",
    "organization.grouping.sidePanel.edit.suggested"               : "Nom suggéré",
    "organization.grouping.sidePanel.edit.name.required"           : "Le nom est requis",
    "organization.grouping.sidePanel.division"                     : "Division",
    "organization.grouping.sidePanel.active"                       : "Active",
    "organization.grouping.sidePanel.delete.msg"                   : "Vous êtes sur le point de supprimer",

    "organization.grouping.addOrganizationModal.title"             : "Ajouter une organisation / division",
    "organization.grouping.addOrganizationModal.error.TOURNAMENTS" : "Les catégories et les divisions pour les organisations sélectionnées sont déjà ajoutées au tournoi",
    "organization.grouping.addOrganizationModal.error.LEAGUES"     : "Les catégories et les divisions pour les organisations sélectionnées sont déjà ajoutées à la ligue",
    "organization.grouping.addOrganizationModal.error.ZONES"       : "Les catégories et les divisions pour les organisations sélectionnées sont déjà ajoutées à la zone",

    "organization.grouping.table.divisions.column" : "Divisions",
    "organization.grouping.table.teams.column"     : "Équipes",
    "organization.grouping.table.teams.count"      : "{number, plural, =0 {Aucune équipe} one {# équipe} other {# équipes}}",
    "organization.grouping.table.divisions"        : "{number, plural, one {# division} other {# divisions}}",
}