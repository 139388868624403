export default{
    // Component permission
    "organization.profile.roles.sidePanelOrganizationRoles.componentPermission.title"    : "Autorisations des composantes",
    "organization.profile.roles.sidePanelOrganizationRoles.componentPermission.cannotDo" : "Pour mettre à jour les autorisations des composantes, vous devez sélectionner un seul rôle à la fois",
    "organization.profile.roles.sidePanelOrganizationRoles.componentPermission.disabled" : "Ce module est désactivé pour ce rôle",
    "organization.profile.roles.sidePanelOrganizationRoles.componentPermission.enabled"  : "Ce module est activé pour ce rôle",
    "organization.profile.roles.sidePanelOrganizationRoles.componentPermission.single"   : "1 composant a au moins l'autorisation de lecture",
    "organization.profile.roles.sidePanelOrganizationRoles.componentPermission.many"     : "{count} composants ont au moins l'autorisation de lecture",

    // Role info
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.seeSettings"           : "Voir dans les paramètres",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.isShared"              : "Ceci un rôle partagé",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.isSytem"               : "Ceci est un rôle système",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.isInactive"            : "Ce rôle est inactif",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.multi.cannot.warning"  : "L'un des rôles sélectionnés est partagé ou est un rôle système",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.status.value.active"   : "Actif",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.status.value.inactive" : "Inactif",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.status.title"          : "Statut",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.description.title"     : "Description",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.title.title"           : "Titre",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo"                       : "Informations sur le rôle",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.dates"                 : "Restrictions de dates",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.dates.none"            : "Aucune date spécifiée",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.from"                  : "Du",
    "organization.profile.roles.sidePanelOrganizationRoles.roleInfo.until"                 : "Jusqu'au",

    // Tabs
    "organization.profile.roles.sidePanelOrganizationRoles.tabs.details.title" : "Détails",
    "organization.profile.roles.sidePanelOrganizationRoles.tabs.users.title"   : "Utilisateurs",

    // Role more action
    "organization.profile.roles.sidePanelOrganizationRoles.dropdownAction.delete" : "Supprimer",

    // FormikEditable
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.title.required"       : "Titre est requis",
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.description.required" : "Description est requise",
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.active.required"      : "Actif est requis",
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.systemRole.required"  : "Rôle système est requis",
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.startDate.format"     : "La date de début doit être d'un format valide",
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.endDate.format"       : "La date de fin doit être d'un format valide",
    "organization.profile.roles.sidePanelOrganizationRoles.formikEditable.validation.dates"                : "La date de début doit être avant la date de fin",

    // Delete Role
    "organization.profile.roles.sidePanelOrganizationRoles.deleteRole.topText" : "Vous êtes sur le point de supprimer ce rôle de votre organisation",

    // Users Info
    "organization.profile.roles.sidePanelOrganizationRoles.usersInfo.title"             : "Utilisateurs",
    "organization.profile.roles.sidePanelOrganizationRoles.usersInfo.subTitle.plural"   : "{num} utilisateurs ont ce rôle",
    "organization.profile.roles.sidePanelOrganizationRoles.usersInfo.subTitle.singular" : "1 utilisateur a ce rôle",
    "organization.profile.roles.sidePanelOrganizationRoles.usersInfo.subTitle.none"     : "Personne n'a ce rôle",
    "organization.profile.roles.sidePanelOrganizationRoles.usersInfo.table.name"        : "Nom",

    "organization.profile.roles.sidePanelOrganizationRoles.duplicate.title" : "Dupliquer ce rôle",

}