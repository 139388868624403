import authContext from "./authContext";
import i18nContext from "./i18nContext";
import feesContext from "./feesContext";
import teamContext from "./teamContext";
import appContext from "./appContext";
import identityRolesContext from "./identityRolesContext";
import periodsContext from "./periodsContext";
import membersContext from "./membersContext";
import cartsContext from "./cartsContext";
import rebatesContext from "./rebatesContext";
import suspensionsContext from "./suspensionsContext";
import clinicsContext from "./clinicsContext";
import insuranceContext from "./insuranceContext";

export default{
    ...authContext,
    ...i18nContext,
    ...feesContext,
    ...teamContext,
    ...appContext,
    ...identityRolesContext,
    ...insuranceContext,
    ...periodsContext,
    ...membersContext,
    ...cartsContext,
    ...rebatesContext,
    ...suspensionsContext,
    ...clinicsContext,
}