import API_SPORDLE from "../../../api/API-Spordle";
import { LOCALES } from "../../../i18n/locales";
import messages from "../../../i18n/messages";
import queryString from 'query-string';
import { AxiosIsCancelled, serverError } from "../../../api/CancellableAPI";
import moment from 'moment';
import { DisplayI18n, displayI18n } from "../../../helpers/i18nHelper";
import { UtilsContext } from "../../../contexts/UtilsContext";
import { useContext } from "react";
import useSWR from "swr";
import { OrganizationContext } from "../../../contexts/OrganizationContext";

// this is used for getting data for select and multi select filters
export const handleQuery = (filter, formik, organisation_id, period_id, federation_id) => {
    var builtUrl = filter.parsedParams?.query?.endpoint;
    var builtQueryParams = {};

    const addQueryParam = (key, paramObj) => {
        if(paramObj.currentState){
            // this case is for adding a query param from the state (organisation_id or period_id)
            if(key === 'organisation_id'){ // TODO: add a way to send organisation_id: federation_id
                if(paramObj.sendFederation){
                    builtQueryParams[key] = federation_id;
                }else{
                    builtQueryParams[key] = organisation_id;
                }
            }else if(key === 'period_id'){
                builtQueryParams[key] = period_id;
            }
        }else if(paramObj.currentReportFilters){
            const empty = formik.values[key] == "" || formik.values[key] == null || formik.values[key] == undefined;
            const arrayEmpty = Array.isArray(formik.values[key]) && formik.values[key].length == 0;
            const hasValue = (!empty) && (!arrayEmpty);

            if(paramObj.needValue && hasValue){
                builtQueryParams[key] = formik.values[key];
            }else if(!paramObj.needValue)
                builtQueryParams[key] = formik.values[key] || '';
        }else if(paramObj.value){
            builtQueryParams[key] = paramObj.value;
        }
    }

    const addURLParam = (key, paramObj) => {
        if(paramObj.currentState){
            // this case is for adding a URL param from the state (organisation_id or period_id)
            if(key === 'organisation_id'){
                if(paramObj.sendFederation){
                    builtUrl = builtUrl.replace(`{{${key}}}`, federation_id);
                }else{
                    builtUrl = builtUrl.replace(`{{${key}}}`, organisation_id);
                }
            }else if(key === 'period_id'){
                builtUrl = builtUrl.replace(`{{${key}}}`, period_id);
            }
        }else if(paramObj.currentReportFilters){
            builtUrl = builtUrl.replace(`{{${key}}}`, formik.values[key]);
        }else if(paramObj.value){
            builtUrl = builtUrl.replace(`{{${key}}}`, paramObj.value);
        }
    }

    for(const [ key, value ] of Object.entries(filter.parsedParams?.query?.parameters || {})){
        if(value.parameter_mode.includes('query') && value.parameter_mode.includes('in_path')){
            addQueryParam(key, value);
            addURLParam(key, value);
        }else if(value.parameter_mode.includes('query')){
            addQueryParam(key, value)
        }else if(value.parameter_mode.includes('in_path')){
            addURLParam(key, value);
        }
    }

    switch (filter.parsedParams?.query?.method){
        case 'GET':
            return API_SPORDLE.get(queryString.stringifyUrl({
                url: builtUrl,
                query: builtQueryParams,
            }, {
                arrayFormat: 'comma',
                skipEmptyString: true,
                skipNull: true,
            }))
                .then((response) => {
                    if(response.data.status){
                        return response.data[filter.parsedParams?.query?.dataKey];
                    }
                    throw response.data.errors[0];
                }, serverError)
        case 'POST':
            const data = new URLSearchParams({ ...builtQueryParams })
            return API_SPORDLE.post(builtUrl, data)
                .then((response) => {
                    if(response.data.status){
                        return response.data[filter.parsedParams?.query?.dataKey];
                    }
                    throw response.data.errors[0];
                }, serverError)

        default:
            return Promise.reject({ message: 'QUERY METHOD OTHER THAN GET' });
    }
}

export const handleContextQuery = (func) => {
    if(func){
        return (filter, formik, organisation_id, period_id) => {
            const builtQueryParams = {};
            const paramsEntries = Object.entries(filter.parsedParams?.query?.parameters || {});

            const addQueryParam = (key, paramObj) => {
                if(paramObj.currentState){
                    // this case is for adding a query param from the state (organisation_id or period_id)
                    if(key === 'organisation_id'){
                        builtQueryParams[key] = organisation_id;
                    }else if(key === 'period_id'){
                        builtQueryParams[key] = period_id;
                    }
                }else if(paramObj.currentReportFilters){
                    builtQueryParams[key] = formik.values[key] || '';
                }else if(paramObj.value){
                    builtQueryParams[key] = paramObj.value;
                }
            }

            for(const [ key, value ] of paramsEntries){
                if(value.parameter_mode.includes('query') && value.parameter_mode.includes('in_path')){
                    addQueryParam(key, value);
                }else if(value.parameter_mode.includes('query')){
                    addQueryParam(key, value);
                }
            }

            return func(builtQueryParams);
        }

    }

    return () => Promise.resolve([]);
}

const onChangeHandling = (filter, allFilterRefs, formik) => {
    // attempts to use the spordleTable refresh function on all component codes listed in the refresh section of the onChange object entry
    // the refresh object can be placed on any filter, but can only point towards selects (EXCEPTION: organization selects can't be refreshed)
    if(filter.parsedParams?.onChange?.refresh?.length > 0){
        filter.parsedParams?.onChange?.refresh?.map((componentCode) => {
            allFilterRefs.current[componentCode]?.getSpordleTable()?.refreshTable();
        })
    }

    if(filter.parsedParams?.onChange?.clearValues?.length > 0){
        filter.parsedParams?.onChange?.clearValues?.map((componentCode) => {
            formik.setFieldValue(componentCode, Array.isArray(formik.values[componentCode]) ? [] : '');
        })
    }
}

export const handleSelectFilterChange = async(filter, formik, selectedOption, spordleSelect, allFilterRefs) => {
    // SET VALUES - only works for  single selected values for now, not multiple selected values
    // this part of the function function handles filters that affect other filters values
    // we start by looping the onChange which contains the instructions that detail the changes
    // onChange: [{formikName}: {path_to_new_value_in_option.key.key}]
    // formikName represents the filter we are affecting
    const options = selectedOption;
    // if(options.length > 100){
    //     options.splice(100)
    //     fire({
    //         theme: 'info',
    //         msg: 'misc.select.max.toast',
    //     })
    // }

    if(filter.parsedParams?.onChange?.setValue){
        const options = spordleSelect.getSpordleTable().getData();
        const fullOption = options.find((o) => options[0] === o.value); // this is specific to a simple select (NOT MULTI)
        const onChangeEntries = Object.entries(filter.parsedParams?.onChange?.setValue);
        let newValue;


        for(const [ _val, key ] of onChangeEntries){
            key.split('.').forEach((keyPortion, index) => {
                newValue = index == 0 ? fullOption?.[keyPortion] : newValue?.[keyPortion];
            })
        }
    }

    await formik.setFieldValue(filter.code, spordleSelect.props?.multi ? options : options[0]);

    onChangeHandling(filter, allFilterRefs, formik);

    return true;
}

// handles filter changes within the engine
export const handleFilterChange = async(filter, formik, selectedOption, allFilterRefs) => {

    await formik.setFieldValue(filter.code, selectedOption);

    onChangeHandling(filter, allFilterRefs, formik);
}

// checks whther or not the filters who's codes are in the codes array currently have values that are not nullish
export const formikCodesHaveValues = (codes, formik) => {
    if(codes?.length > 0)
        return codes?.every((code) => Array.isArray(formik.values[code]) ? formik.values[code].length > 0 : !!formik.values[code])
    return true
}

// checks that the filter in who's code is sent has the sent value
export const filterHasValue = (filterCode, value, formik) => {
    if(Array.isArray(formik.values[filterCode]))
        return formik.values[filterCode].some((val) => val === value)
    return formik.values[filterCode] === value
}

// this function returns the formatted data that should be sent to the api
export const getReportQueryParamsFromFilters = (formikValues, filters) => {
    let submitValues = {};

    if(formikValues && Array.isArray(filters)){
        for(let i = 0; i < filters.length; i++){
            const filter = filters[i];

            switch (filter.type){
                case 'ORGANIZATION_SELECT':
                case 'PERIOD_SELECT':
                case 'GENDER_SELECT':
                case 'SELECT':
                    submitValues = { ...submitValues, [filter.code]: formikValues[filter.code] }
                    break;
                case 'MULTI_ORGANIZATION_SELECT':
                case 'GENDER_MULTI_SELECT':
                case 'MULTI_SELECT':
                    submitValues = { ...submitValues, [filter.code]: Array.isArray(formikValues[filter.code]) ? formikValues[filter.code].join() : "" }
                    break;
                case 'DATE':
                    submitValues = { ...submitValues, [filter.code]: moment.isMoment(formikValues[filter.code]) ? formikValues[filter.code].format('YYYY-MM-DD') : '' }
                    break;
                case 'TIME':
                    submitValues = { ...submitValues, [filter.code]: moment.isMoment(formikValues[filter.code]) ? formikValues[filter.code].format('HH:mm:ss') : '' } // 24 hour hour:minute:second format ex: '18:53:28'
                    break;
                case 'DATE_BETWEEN':
                    submitValues = { ...submitValues, [filter.code]: (formikValues[`start_${filter.code}`] && formikValues[`end_${filter.code}`]) ? `${moment.isMoment(formikValues[`start_${filter.code}`]) ? formikValues[`start_${filter.code}`].format('YYYY-MM-DD') : ''},${moment.isMoment(formikValues[`end_${filter.code}`]) ? formikValues[`end_${filter.code}`].format('YYYY-MM-DD') : ''}` : '' };
                    break;
                case 'NUMBER':
                    const canHaveDecimals = JSON.parse(filter.param)?.componentProps?.decimalSeparator;
                    submitValues = { ...submitValues, [filter.code]: canHaveDecimals == false ? parseInt(formikValues[filter.code]) : parseFloat(formikValues[filter.code]) }
                    break;
                case 'NUMBER_BETWEEN':
                    submitValues = { ...submitValues, [filter.code]: (formikValues[`start_${filter.code}`] && formikValues[`end_${filter.code}`]) ? `${formikValues[`start_${filter.code}`]},${formikValues[`end_${filter.code}`]}` : '' };
                    break;
                case 'CHECKBOX':
                    submitValues = { ...submitValues, [filter.code]: formikValues[filter.code] ? 1 : null }
                    break;
                case 'QUESTIONNAIRE_FORM':
                    submitValues = { ...submitValues, 'custom_form_id': formikValues.custom_form_id }
                    submitValues = { ...submitValues, 'registration_fee_id': Array.isArray(formikValues.registration_fee_id) ? formikValues.registration_fee_id?.join() : formikValues.registration_fee_id }
                    submitValues = { ...submitValues, 'affiliation_fee_id': Array.isArray(formikValues.affiliation_fee_id) ? formikValues.affiliation_fee_id?.join() : formikValues.affiliation_fee_id }
                    submitValues = { ...submitValues, 'clinic_id': Array.isArray(formikValues.clinic_id) ? formikValues.clinic_id?.join() : formikValues.clinic_id }
                    break;
                case 'PERIOD_AND_REFERENCE_PERIOD':
                    submitValues = { ...submitValues, 'period_id': formikValues.period_id }
                    submitValues = { ...submitValues, 'reference_period_id': formikValues.reference_period_id }
                    break;
                default:
                    break;
            }
        }
    }

    return submitValues;
}

export const getRecursiveKeyVal = (object, keyString) => {
    if(keyString){
        const keys = keyString.split(".");
        let theObject = null;

        if(object){
            for(let k = 0; k < keys.length; k++){
                const theKey = keys[k];

                if(typeof theObject === "object"){
                    if(!theObject && object?.[theKey]){
                        theObject = object[theKey];
                    }else if(theObject?.[theKey]){
                        theObject = theObject[theKey];
                    }
                }
            }
        }

        return theObject;
    }

    return object;
}

const sortI18nLocale = (list, sortParam, lang) => {
    let sortParamKeys = []
    if(Array.isArray(sortParam.key))
        sortParamKeys = sortParam.key
    else
        sortParamKeys[sortParam.key]

    const appendDisplayI18nForKeys = (option) => {
        return sortParamKeys.reduce((newCompare, key) => {
            const keys = key.split(".");
            const rootI18nKey = key.replace(/\.[^.]*$/, "");
            const fieldName = keys[keys.length - 1];

            const getI18nStr = (obj) => {
                const rootObj = getRecursiveKeyVal(obj, rootI18nKey);
                return (displayI18n(fieldName, rootObj?.i18n, rootObj[fieldName], lang) || "").toLowerCase();
            }
            return newCompare + (newCompare ? ' - ' : '') + getI18nStr(option);
        }, "")
    }

    list.sort((a, b) => {
        return new Intl.Collator(lang, { sensitivity: 'base' })
            .compare(
                appendDisplayI18nForKeys(a),
                appendDisplayI18nForKeys(b),
            );
    })

    //     const keys = sortParam.key.split(".");
    //     const rootI18nKey = sortParam.key.replace(/\.[^.]*$/, "");
    //     const fieldName = keys[keys.length - 1];

    //     const getI18nStr = (obj) => {
    //         const rootObj = getRecursiveKeyVal(obj, rootI18nKey);
    //         return (displayI18n(fieldName, rootObj?.i18n, rootObj[fieldName], lang) || "").toLowerCase();
    //     }

    //     list.sort((a, b) => {
    //         const valueA = getI18nStr(a);
    //         const valueB = getI18nStr(b);

    //         return new Intl.Collator(lang, { sensitivity: 'base' })
    //             .compare(
    //                 valueA,
    //                 valueB,
    //             );
    //     });
}

const sortLocaleCompare = (list, sortParam) => {
    list.sort((a, b) => (getRecursiveKeyVal(a, sortParam.key) || "").localeCompare(getRecursiveKeyVal(b, sortParam.key) || ""));
}

const sortDisplayOrder = (list, sortParam) => {
    list.sort((a, b) => {
        const aDisplay = getRecursiveKeyVal(a, sortParam.key || "option.display_order");
        const bDisplay = getRecursiveKeyVal(b, sortParam.key || "option.display_order");

        return parseInt(aDisplay || 0) - parseInt(bDisplay || 0);
    });
}

/**
 * Doesn't return since sort is mutable.
 * @param {[]} list
 * @param {{ key: string, type: string}} sortParam
 * @param {string} lang
 */
export const sortSelectQueryResult = (list, sortParam, lang) => {
    const type = sortParam.type;

    switch (type){ // this switch avoids repetitive if else in loop
        case "localeCompare":
            sortLocaleCompare(list, sortParam);
            break;
        case "i18nLocaleCompare":
            sortI18nLocale(list, sortParam, lang);
            break;
        case "displayOrder":
            sortDisplayOrder(list, sortParam);
            break;
        default:
            break;
    }
}


// was used to convert columns to the new format or columns, was for testing, eventually could be deleted
export const convertColumnToNewFormat = (newColumns) => {
    const parsed = newColumns.map((obj) => ({
        key: obj.key,
        name: messages[LOCALES.ENGLISH][obj.label],
        dataType: "string",
        i18n: {
            en: {
                name: messages[LOCALES.ENGLISH][obj.label],
            },
            fr: {
                name: messages[LOCALES.FRENCH][obj.label],
            },
        },
        columnParams: {
            dataClassName: "text-nowrap",
        },
        link: {
            linkCode: "invoice",
            linkParams: { // the contents of this object depends on the linkCode and what parameters are needed
                member_id: "someMembersIdThatHasAnInvoice",
                invoice_number: "theInvoiceWeAreLookingFor",
            },
        },
    }));

    return parsed;
}

export const getTextWhenSettings = (settings) => {
    if(!settings){
        return undefined;
    }

    return {
        count: settings.count || 1,
        label: settings.label ? settings.label : ({ count }) => <>{count} <DisplayI18n field='name' defaultValue={settings.name} i18n={settings.i18n} /></>,
    }
}


export function useGenderSettingsSWR(){
    const { getSettings } = useContext(UtilsContext);
    const { organisation_id } = useContext(OrganizationContext);

    return useSWR(
        [ 'genderUtilsSettings', organisation_id ],
        () => {
            return getSettings({ active: 1 }).then((allSettings) => allSettings.gender_selection?.value)
        },
        {
            fallbackData: {},
            revalidateOnFocus: false,
            dedupingInterval: 30000,
            onError: (e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                }

                return {};
            },
        },
    );
}
