export default{
    "registration.settings.category.title" : "Paramètres d'inscription", //'Catégories, Divisions, Classes',

    "registration.settings.category.table.gender.male"   : "Masculin",
    "registration.settings.category.table.gender.female" : "Féminin",
    "registration.settings.category.table.gender.mixed"  : "Mixte",

    "registration.settings.category.sidePanel.title"                  : "Catégorie d'inscription",
    "registration.settings.category.sidePanel.disableAll"             : "Vous allez désactiver la catégorie suivante pour toutes les périodes. Êtes-vous sûr ?",
    "registration.settings.category.sidePanel.enableAll"              : "Vous allez activer la catégorie suivante pour toutes les périodes. Êtes-vous sûr ?",
    "registration.settings.category.sidePanel.classDisabled"          : "Cette classe est désactivée. Veuillez choisir une autre classe.",
    "registration.settings.category.sidePanel.divisionDisabled"       : "Cette division est désactivée. Veuillez choisir une autre division.",
    "registration.settings.category.sidePanel.parentCategoryDisabled" : "Cette catégorie a été désactivée par l'organisation. Veuillez choisir une autre catégorie de référence si vous souhaitez que cette catégorie soit accessible.",
    "registration.settings.category.sidePanel.delete"                 : "Vous êtes sur le point de supprimer cette catégorie",

    "registration.settings.category.modal.title" : "Ajouter une catégorie",

    "registration.settings.category.form.division.required"       : "Veuillez choisir une division.",
    "registration.settings.category.form.parentCategory.required" : "Veuillez choisir une catégorie équivalente.",
    "registration.settings.category.form.minAge.required"         : "Veuillez indiquer un âge minimum.",
    "registration.settings.category.form.maxAge.required"         : "Veuillez indiquer un âge maximum.",
    "registration.settings.category.form.gender.required"         : "Veuillez indiquer un identité de genre.",
    "registration.settings.category.form.minAge.smaller"          : "L'âge minimum doit être plus petit ou égal que l'âge maximum.",
    "registration.settings.category.form.maxAge.bigger"           : "L'âge maximum doit être plus grand ou égal que l'âge minimum.",
    "registration.settings.category.form.maxAge.requirements"     : "L'âge maximum doit être plus petit que l'âge maximum de la catégorie parent ({age}).",
    "registration.settings.category.form.minAge.requirements"     : "L'âge minimum doit être plus grand que l'âge minimum de la catégorie parent ({age}).",

    "registration.settings.category.label.gender"                : "Identité de genre",
    "registration.settings.category.label.class"                 : "Classes",
    "registration.settings.category.label.name"                  : "Nom de la catégorie",
    "registration.settings.category.label.name.suggestion"       : "Nom suggéré",
    "registration.settings.category.label.organization"          : "Organisation",
    "registration.settings.category.label.category"              : "Catégories",
    "registration.settings.category.label.division"              : "Divisions",
    "registration.settings.category.label.minAge"                : "Âge min",
    "registration.settings.category.label.birthYear"             : "Année de naissance ",
    "registration.settings.category.label.birthYearTo"           : "Année de naissance à ",
    "registration.settings.category.label.maxAge"                : "Âge max",
    "registration.settings.category.label.birthYearFrom"         : "Année de naissance de ",
    "registration.settings.category.label.type"                  : "Type",
    "registration.settings.category.label.startDate"             : "À partir de",
    "registration.settings.category.label.startDate.value"       : "31 Décembre",
    "registration.settings.category.label.federation.equivalent" : "Équivalence {name}",
    "registration.settings.category.label.settings"              : "Paramètres",

    "registration.settings.category.label.federation.division.equivalent" : "Division équivalente {name}",
    "registration.settings.category.label.federation.linkWith"            : "Lier avec {name}",
}