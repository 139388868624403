/* eslint-disable react/no-unused-class-component-methods */
import React from "react"
import { Draggable } from "react-beautiful-dnd"
import { draggableMap } from './dragNDropMaps';

class SpordleDraggable extends React.Component{
    componentDidMount(){
        draggableMap[this.props.draggableId] = this
    }

    componentWillUnmount(){
        delete draggableMap[this.props.draggableId]
    }

    callHandler = (handler, result) => {
        this.props[handler] && this.props[handler](result)
    }

    getPayload = () => this.props.payload

    render(){
        return <Draggable {...this.props} />
    }
}

export default SpordleDraggable;