import Translate from "@spordle/intl-elements";
import { fail } from "@spordle/toasts";
import { useContext } from "react";
import { Button } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";

const ValidateDocumentsAction = ({ toggler, currentDocuments, onSuccess }) => {
    const { updateMemberPartial, currentMember, setShouldAttemptAutoConfirm } = useContext(MembersContext);

    const handleOnConfirm = () => {
        return updateMemberPartial(
            currentMember.member_id,
            { need_address_document: 0 },
        )
            .then(() => {
                setShouldAttemptAutoConfirm(true);
                onSuccess?.()
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    return (
        <ConfirmModal
            color="primary"
            toggler={toggler || ((fn) => (
                <Button color="link" onClick={fn} className="ml-auto py-0 pr-0 clickable">
                    <Translate id="members.search.memberSearch.missingDocument.validate" />
                </Button>
            ))}
            onConfirm={handleOnConfirm}
            modalTitle="members.search.memberSearch.missingDocument.confirm"
            translateModalTitle
            actionButtonText={<Translate id="misc.confirm" />}
            // modalContent={
            //     <div>
            //         <MissingDocsList currentDocuments={currentDocuments} />
            //     </div>
            // }
        />
    );
}

export default ValidateDocumentsAction;