export default{
    "organization.settings.organizationMemberTypes.table.type"          : "Type",
    "organization.settings.organizationMemberTypes.table.type.SPECIAL"  : "Special",
    "organization.settings.organizationMemberTypes.table.active"        : "Active",
    "organization.settings.organizationMemberTypes.table.empty"         : "No Member Type",
    // add
    "organization.settings.organizationMemberTypes.add.title"           : "Add Member Type",
    "organization.settings.organizationMemberTypes.add.name.required"   : "Name is required",
    "organization.settings.organizationMemberTypes.add.status"          : "Status",
    "organization.settings.organizationMemberTypes.add.status.active"   : "Active",
    "organization.settings.organizationMemberTypes.add.status.inactive" : "Inactive",
    "organization.settings.organizationMemberTypes.add.status.required" : "Status is required",
}