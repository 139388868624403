import teamProfile from "./teamProfile";
import teamInfos from "./teamInfos";
import teamAdd from "./teamAdd";
import teamRosterEdit from "./teamRosterEdit";
import teamSettings from "./teamSettings";

export default{
    ...teamAdd,
    ...teamProfile,
    ...teamInfos,
    ...teamRosterEdit,
    ...teamSettings,
}