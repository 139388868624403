import { FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { formatSelectData } from "@spordle/spordle-select";
import { useFormikContext } from "formik";
import { useContext } from "react";
import { Collapse, FormGroup, Label } from "reactstrap";
import DisplayOrg from "../../../../../components/DisplayOrg";
import Optional from "../../../../../components/formik/Optional";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { displayI18n } from "../../../../../helpers/i18nHelper";
import { DisplayCategory } from "../../../../teams/TeamHelpers";
import { sortRessource } from "./categorySelectsHelper";

const RegistrationFeeCategorySelect = ({ categories, selectedTeamCategory, selectedDivision, setSelectedTeamCategory }) => {
    const formik = useFormikContext();
    const { organisation_id, settings } = useContext(OrganizationContext);
    const canManageAge = settings.manage_min_max_age_registration_fee?.value == 1;
    const { getGenericLocale } = useContext(I18nContext);
    const options = formatSelectData(
        categories
            .sort((a, b) => {
                const order = sortRessource(a, b, organisation_id, getGenericLocale());

                if(order !== 0)return order;

                return parseInt(a.display_order) - parseInt(b.display_order);
            })
            .filter((cat) => cat.division?.division_id.includes(selectedDivision) && cat.active == 1)
        ,
        {
            getGroupId: (data) => data.organisation?.organisation_id,
            getValue: (data) => data.team_category_id,
            getLabel: (data) => `form.fields.gender.${data?.gender.toLowerCase()}`,
            createGroupWithId: (data) => ({
                organisation: data.organisation,
                label: displayI18n("name", data.organisation?.i18n, data.organisation?.organisation_name, getGenericLocale()),
            }),
        },
    );

    const manageAge = (teamCat) => {
        formik.setFieldValue('max_age', teamCat.max_age ? parseInt(teamCat.max_age) : "");

        if(teamCat.min_age){
            formik.setFieldValue('min_age', parseInt(teamCat.min_age))
            formik.setFieldTouched('min_age', false)
        }else{
            formik.setFieldValue('min_age', "")
        }

    }

    return (
        <FormGroup>
            <Label for="team_category_id" className="text-muted"><Translate id='catalog.registrationFees.add.form.category' /> <Optional /></Label>
            {/* this should be disabled if division is empty */}
            <FormikSelect
                disabled={!formik.values.division_id}
                name='team_category_id'
                id='team_category_id'
                clearable
                searchKeys={[
                    { name: `class.i18n.${getGenericLocale()}.short_name`, weight: 2 },
                    { name: `division.i18n.${getGenericLocale()}.short_name`, weight: 2 },
                    `class.short_name`,
                    `division.short_name`,
                    'birth_year_from',
                    'birth_year_to',
                    'label',
                ]}
                renderSelectedOption={(option) => <DisplayCategory short category={option} />}
                renderOption={({ option, isSelected }) => (
                    option.isGroup ?
                        <DisplayOrg org={option.organisation} />
                        :
                        <>
                            {!!option.gender && <div className="small">{<Translate id={`form.fields.gender.${option.gender.toLowerCase()}`} />}</div>}
                            <div className="mb-0 font-medium"><DisplayCategory short category={option} /></div>
                            {option.min_age && option.max_age &&
                                <div className={`small text-${isSelected ? 'light' : 'muted'}`}>
                                    {(option.birth_year_from && option.birth_year_to) &&
                                        <span className="mr-1">
                                            {option.birth_year_from != option.birth_year_to ?
                                                <Translate id='catalog.affiliationFees.division.birthYear' values={{ minYear: option.birth_year_from, maxYear: option.birth_year_to }} />
                                                :
                                                <><Translate id='registration.settings.category.label.birthYear' /> {option.birth_year_from}</>
                                            }
                                        </span>
                                    }
                                    <b className="font-medium">
                                        ({option.min_age != option.max_age ?
                                            <Translate id="misc.ageRange" values={{ from: option.min_age, to: option.max_age }} />
                                            :
                                            <Translate id='misc.yearsOld' values={{ age: option.min_age }} />
                                        })
                                    </b>
                                </div>
                            }
                        </>
                )}
                onOptionSelected={([ value ]) => {
                    if(value){
                        const teamCat = categories.find((cat) => cat.team_category_id?.includes(value))
                        setSelectedTeamCategory(teamCat);

                        if(teamCat.max_card){
                            formik.setFieldValue('available_place', parseInt(teamCat.max_card))
                        }

                        if(canManageAge){
                            manageAge(teamCat);
                        }

                    }else{
                        setSelectedTeamCategory(null);

                        if(!canManageAge)return; // guard clause: cannot and do not manage age

                        formik.setFieldValue('min_age', "")
                        formik.setFieldValue('max_age', "")
                    }
                }}
                options={options}
            />
            <Collapse isOpen={!!(formik.values.team_category_id && selectedTeamCategory && selectedTeamCategory.max_card)}>
                <span className="small"><Translate id='catalog.registrationFees.sidePanel.form.availablePlaces' />: {selectedTeamCategory?.max_card}</span>
            </Collapse>
        </FormGroup>
    );
}

export default RegistrationFeeCategorySelect;