import { useContext } from 'react';
import HasAccessTo from '../../../../components/permissions/HasAccessTo';
import CriminalRecordCheck from '../criminalRecordCheck/CriminalRecordCheck';
import Appeals from './component/appeals/Appeals';
import MemberSuspensions from './component/suspensions/MemberSuspensions';
import MemberProfileTransfer from './component/transfer/MemberProfileTransfer';
import { RolesContext } from '../../../../contexts/RolesContext';
import MemberInsurance from './component/insurance/MemberInsurance';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import MemberDiscrimination from './component/discrimination/MemberDiscrimination';
import MemberStatusLog from './component/memberStatusLog/MemberStatusLog';
import Translate from '@spordle/intl-elements';

const MemberHistory = ({ canTransfer, transferKey }) => {
    const { hasAccessTo } = useContext(RolesContext)
    const orgContext = useContext(OrganizationContext)

    const hasAccessToTransfers = hasAccessTo('members', 'members_request_transfer') || hasAccessTo('members', 'members_request_transfer_usa') || hasAccessTo('members', 'members_request_transfer_international')

    const scrollToComponent = (componentCode) => {
        const card = document.getElementById(componentCode);
        if(card){
            window.scrollTo({
                behavior: 'smooth', // not supported on mac?
                top: card.getBoundingClientRect().top - 150, // 2 * header(70 px high) + 10px(give some edge)
            })
        }
    }

    return (
        <>
            <div className="bg-light rounded-lg shadow-inset border mb-3">
                <div className='p-3 overflow-auto pretty-scrollbar d-flex'>

                    {orgContext.settings.enable_background_check?.value == 1 &&
                        <HasAccessTo componentCode='members' componentPermissionCode='member_background_check' className={`text-center text-nowrap text-md-left mr-2`}>
                            <div
                                className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                                onClick={() => { scrollToComponent('criminalRecordCheckCard') }}
                            >
                                <span className="align-middle mx-1"><Translate id='members.profile.crc.title' /></span>
                            </div>
                        </HasAccessTo>
                    }

                    {hasAccessToTransfers &&
                        <div className={`text-center text-nowrap text-md-left mr-2`}>
                            <div
                                className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                                onClick={() => { scrollToComponent('memberProfileTransferCard') }}
                            >
                                <span className="align-middle mx-1"><Translate id='members.profile.transfers.title' /></span>
                            </div>
                        </div>
                    }

                    <HasAccessTo componentCode='members' componentPermissionCode='members_injuries' className={`text-center text-nowrap text-md-left mr-2`}>
                        <div
                            className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                            onClick={() => { scrollToComponent('memberInsuranceCard') }}
                        >
                            <span className="align-middle mx-1"><Translate id='insurance.title' /></span>
                        </div>
                    </HasAccessTo>

                    <HasAccessTo componentCode='organization' componentPermissionCode='discrimination_claims' className={`text-center text-nowrap text-md-left mr-2`}>
                        <div
                            className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                            onClick={() => { scrollToComponent('memberDiscriminationCard') }}
                        >
                            <span className="align-middle mx-1"><Translate id='discrimination.title' /></span>
                        </div>
                    </HasAccessTo>

                    <HasAccessTo componentCode='members' componentPermissionCode='members_suspensions' className={`text-center text-nowrap text-md-left mr-2`}>
                        <div
                            className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                            onClick={() => { scrollToComponent('memberSuspensionsCard') }}
                        >
                            <span className="align-middle mx-1"><Translate id='members.profile.suspensions.title' /></span>
                        </div>
                    </HasAccessTo>

                    <HasAccessTo componentCode='members' componentPermissionCode='member_appeal_request' className={`text-center text-nowrap text-md-left mr-2`}>
                        <div
                            className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                            onClick={() => { scrollToComponent('appealsCard') }}
                        >
                            <span className="align-middle mx-1"><Translate id='members.profile.appeals.title' /></span>
                        </div>
                    </HasAccessTo>

                    <HasAccessTo componentCode='members' className={`text-center text-nowrap text-md-left mr-2`}>
                        <div
                            className="btn btn-sm rounded-pill bg-white font-medium shadow-sm clickable"
                            onClick={() => { scrollToComponent('memberStatusLogCard') }}
                        >
                            <span className="align-middle mx-1"><Translate id='members.profile.status.log.title' /></span>
                        </div>
                    </HasAccessTo>

                </div>
            </div>

            {orgContext.settings.enable_background_check?.value == 1 &&
                <HasAccessTo componentCode='members' componentPermissionCode='member_background_check'>
                    <CriminalRecordCheck />
                </HasAccessTo>
            }
            {hasAccessToTransfers &&
                <MemberProfileTransfer canTransfer={canTransfer} transferKey={transferKey} />
            }
            <HasAccessTo componentCode='members' componentPermissionCode='members_injuries'>
                <HasAccessTo componentCode='settings' componentPermissionCode='injury_configurations'>
                    <MemberInsurance />
                </HasAccessTo>
            </HasAccessTo>
            <HasAccessTo componentCode='organization' componentPermissionCode='discrimination_claims'>
                <MemberDiscrimination />
            </HasAccessTo>
            <HasAccessTo componentCode='members' componentPermissionCode='members_suspensions'>
                <MemberSuspensions />
            </HasAccessTo>
            <HasAccessTo componentCode='members' componentPermissionCode='member_appeal_request'>
                <Appeals />
            </HasAccessTo>
            <HasAccessTo componentCode='members'>
                <MemberStatusLog />
            </HasAccessTo>
        </>
    );
}

export default MemberHistory;