import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { formatSelectData } from "@spordle/spordle-select";
import { useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { Card, FormGroup, Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import CrossFade from "../../../../../../../../components/crossFade/CrossFade";
import Required from "../../../../../../../../components/formik/Required";
import { fail } from "@spordle/toasts";
import { I18nContext } from "../../../../../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../../../../../contexts/OrganizationContext";
import { TeamsContext } from "../../../../../../../../contexts/TeamsContext";
import { displayI18n, DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import { DisplayCategory } from "../../../../../../../teams/TeamHelpers";
import { isCoach, isManager } from "../../../../gameIncidentHelper";
import { teamInit } from "../AddMemberGameIncidentStepTeam";


/**
 * @param {Object} props
 * @param {'away'|'home'} [props.teamPos='home']
 * @return {JSX.Element}
 */
const TeamCard = ({ branches, categories, girData, teamPos = 'home' }) => {
    // contexts
    const { getOrganizationByCategories } = useContext(OrganizationContext);
    const { getTeams, getTeamMembers } = useContext(TeamsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { values, setFieldValue } = useFormikContext();

    // States
    const [ teams, setTeams ] = useState(null);
    const [ orgs, setOrgs ] = useState(null);
    const [ teamMembers, setTeamMembers ] = useState([]); //null = loading,

    // vars
    const teamField = teamPos + '_team';
    const team = values[teamField];

    // functions
    const getTeamOptionLabel = (option) => {
        const team = option.option.team;

        return (
            <>
                <div className="font-medium">
                    <DisplayI18n
                        field="name"
                        i18n={team.i18n}
                        defaultValue={team.name}
                    />
                </div>
                <div className="font-14">
                    (
                    <DisplayI18n
                        field="short_name"
                        i18n={team.i18n}
                        defaultValue={team.short_name}
                    />
                    )
                </div>
                <div className="text-muted small">
                    #{team.unique_identifier}
                </div>
                <div className="text-muted small">
                    <DisplayCategory short category={team.team_category} /> (<Translate id={'form.fields.gender.' + (team.team_category?.gender || '').toLowerCase()} />)
                </div>
            </>
        )
    }

    const emptyFields = (toKeep) => {
        setFieldValue(teamPos + '_team', {
            ...teamInit,
            ...toKeep,
        })
    }

    const fetchOrganisations = async(id) => {
        emptyFields({ branch_id: id });
        setOrgs(null);
        setTeams([]);
        const mhaCatId = categories.find((c) => (c.default_name || '').toLowerCase() === "mha")?.category_id;
        const regionCatId = categories.find((c) => (c.default_name || '').toLowerCase() === "region")?.category_id;
        const districtCatId = categories.find((c) => (c.default_name || '').toLowerCase() === "district")?.category_id;
        const teamCatId = categories.find((c) => (c.default_name || '').toLowerCase() === "team")?.category_id;
        const orgs = await getOrganizationByCategories(id, { organisation_category_id: [ mhaCatId, regionCatId, districtCatId, teamCatId ] });

        setOrgs(
            (orgs || []).sort((a, b) => {
                const aName = displayI18n('name', a.i18n, a.organisation_name, getGenericLocale());
                const bName = displayI18n('name', b.i18n, b.organisation_name, getGenericLocale());
                return aName.localeCompare(bName);
            }),
        )
    }

    const fetchTeams = async(id) => {
        setTeams(null);
        setTeamMembers([]);
        getTeams({ organisation_id: id, period_id: girData.period_id })
            .then(setTeams)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    useEffect(() => { // Everytime the organisation changes
        if(values[teamField].organisation_id){
            fetchTeams(values[teamField].organisation_id);
        }
    }, [ values[teamField].organisation_id ]);

    useEffect(() => { // Everytime the branch changes
        if(values[teamField].branch_id && !!categories){
            fetchOrganisations(values[teamField].branch_id)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                });
        }

    }, [ values[teamField].branch_id, categories ]);

    useEffect(() => {
        if(team.team_id && team.existing == "1"){
            setTeamMembers(null);
            getTeamMembers(team.team_id)
                .then((members) => {
                    const players = members
                        .sort((a, b) => {
                            const aName = a.member.first_name + ' ' + a.member.last_name;
                            const bName = b.member.first_name + ' ' + b.member.last_name;
                            return aName.localeCompare(bName);
                        })
                        .reduce((data, mem) => {
                            if(mem.position?.position_group?.position_type === "PLAYER" || isManager(mem.position?.position_group) || isCoach(mem.position?.position_group)){
                                data.push({ ...mem, team_id: team.team_id });
                            }
                            return data;
                        }, []);

                    setFieldValue(teamPos + '_team.members', players);
                    setTeamMembers(players);
                }).catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        setTeamMembers([]);
                    }
                })
        }
    }, [ team?.team_id, team?.existing ]);

    return (
        <Card className="card-shadow mb-0 h-md-100">
            <Nav tabs className="is-full mb-2 pt-1">
                <NavItem className="flex-grow-1 text-center">
                    <NavLink
                        className={stringBuilder({ active: values[teamField].existing == "1" })}
                        onClick={() => setFieldValue(teamField + '.existing', '1')}
                    >
                        <Translate id='gameIncident.addModal.steps.teams.existing' />
                    </NavLink>
                </NavItem>
                <NavItem className="flex-grow-1 text-center">
                    <NavLink
                        className={stringBuilder({ active: values[teamField].existing == "0" })}
                        onClick={() => setFieldValue(teamField + '.existing', '0')}
                    >
                        <Translate id='gameIncident.addModal.steps.teams.other' />
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={values[teamField].existing}>
                <TabPane tabId="1">
                    <div className="p-3">
                        <FormGroup>
                            <Label for={`${teamField}.branch_id`} className="text-muted">
                                <Translate id='gameIncident.addModal.label.branch' />
                            </Label>
                            <FormikSelect
                                name={`${teamField}.branch_id`}
                                id={`${teamField}.branch_id`}
                                renderOption={(opt) => (
                                    <DisplayI18n
                                        field="name"
                                        i18n={opt.option.i18n}
                                        defaultValue={opt.option.label}
                                    />
                                )}
                                search
                                searchKeys={[
                                    `i18n.${getGenericLocale()}.name`,
                                ]}
                                isLoading={branches === null}
                                options={branches ? branches.map((org) => ({
                                    value: org.organisation_id,
                                    label: org.organisation_name,
                                    i18n: org.i18n,
                                })) : []}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={`${teamField}.organisation_id`} className="text-muted">
                                <Translate id='gameIncident.addModal.label.org' /> <Required />
                            </Label>
                            <FormikSelect
                                name={`${teamField}.organisation_id`}
                                id={`${teamField}.organisation_id`}
                                search
                                searchKeys={[
                                    `i18n.${getGenericLocale()}.name`,
                                ]}
                                renderOption={(opt) => (
                                    <DisplayI18n
                                        field="name"
                                        i18n={opt.option.i18n}
                                        defaultValue={opt.option.label}
                                    />
                                )}
                                onOptionSelected={() => {
                                    setFieldValue(teamField + '.team_id', "");
                                    if(!!team.coach && team.coach !== "OTHER"){
                                        setFieldValue(teamField + '.coach', "");
                                    }
                                    if(!!team.manager && team.manager !== "OTHER"){
                                        setFieldValue(teamField + '.manager', "");
                                    }
                                }}
                                isLoading={orgs === null}
                                options={orgs ? orgs.map((org) => ({
                                    value: org.organisation_id,
                                    label: org.organisation_name,
                                    i18n: org.i18n,
                                })) : []}
                            />
                        </FormGroup>
                        <div
                            className="transition"
                            style={{
                                pointerEvents: !values[teamField].organisation_id ? 'none' : 'initial',
                                opacity: !values[teamField].organisation_id ? 0.4 : 1,
                            }}
                        >
                            <FormGroup>
                                <Label for={`${teamField}.team_id`} className="text-muted"><Translate id='gameIncident.addModal.label.team' /> <Required /></Label>
                                <FormikSelect
                                    isLoading={teams === null && !!values[teamField].organisation_id}
                                    name={`${teamField}.team_id`}
                                    id={`${teamField}.team_id`}
                                    renderOption={getTeamOptionLabel}
                                    renderSelectedOption={(opt) => (
                                        <>
                                            <div className="font-medium">
                                                <DisplayI18n
                                                    field="name"
                                                    i18n={opt.team.i18n}
                                                    defaultValue={opt.team.name}
                                                />
                                            </div>
                                            <div className="text-muted small">
                                                <DisplayCategory short category={opt.team.team_category} /> (<Translate id={'form.fields.gender.' + (opt.team.team_category?.gender || '').toLowerCase()} />)
                                            </div>
                                        </>
                                    )}
                                    search
                                    searchKeys={[
                                        'team.name',
                                        'team.short_name',
                                        'team.unique_identifier',
                                        'team.team_category.gender',
                                        `team.team_category.division.i18n.${getGenericLocale()}.short_name`,
                                        `team.team_category.class.i18n.${getGenericLocale()}.short_name`,
                                    ]}
                                    onOptionSelected={() => {
                                        if(!!team.coach && team.coach !== "OTHER"){
                                            setFieldValue(teamField + '.coach', "");
                                        }
                                        if(!!team.manager && team.manager !== "OTHER"){
                                            setFieldValue(teamField + '.manager', "");
                                        }
                                    }}
                                    options={teams ? teams.map((team) => ({
                                        value: team.team_id,
                                        label: team.name,
                                        i18n: team.i18n,
                                        team: team,
                                    })) : []}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for={`${teamField}.coach`} className="text-muted"><Translate id='gameIncident.addModal.label.coach' /></Label>
                                <CrossFade isVisible={team?.team_id !== "OTHER"}>
                                    <FormikSelect
                                        name={`${teamField}.coach`}
                                        id={`${teamField}.coach`}
                                        clearable
                                        isLoading={teamMembers === null}
                                        renderOption={(opt) => (
                                            (opt.option.isGroup || opt.option.value == 'OTHER') ?
                                                <Translate id={opt.option.label} />
                                                :
                                                opt.option.label
                                        )}
                                        options={teamMembers ?
                                            formatSelectData(
                                                teamMembers.reduce((staffList, staff) => {
                                                    if(isCoach(staff.position?.position_group)){
                                                        staffList.push({
                                                            label: staff.member.first_name + ' ' + staff.member.last_name,
                                                            value: staff.member.member_id,
                                                        })
                                                    }
                                                    return staffList;
                                                }, [
                                                    {
                                                        value: "OTHER",
                                                        label: 'gameIncident.addModal.label.coach.option.other',
                                                    },
                                                ]),
                                                {
                                                    getGroupId: (opt) => opt.value !== "OTHER" ? 'coachList' : undefined,
                                                    newGroupIndexes: {
                                                        1: {
                                                            label: 'gameIncident.addModal.label.coachList',
                                                            groupId: 'coachList',
                                                        },
                                                    },
                                                },
                                            )
                                            :
                                            [ {
                                                value: "OTHER",
                                                label: 'gameIncident.addModal.label.coach.option.other',
                                            } ]
                                        }
                                    />
                                </CrossFade>
                                <CrossFade isVisible={team?.coach === "OTHER"}>
                                    <FormikInputText
                                        className="mt-2"
                                        name={`${teamField}.coach_name`}
                                        placeholder='gameIncident.addModal.label.coach.other'
                                        trim
                                    />
                                </CrossFade>
                            </FormGroup>
                            <FormGroup className="mb-0">
                                <Label for={`${teamField}.manager`} className="text-muted"><Translate id='gameIncident.addModal.label.manager' /></Label>
                                <FormikSelect
                                    name={`${teamField}.manager`}
                                    id={`${teamField}.manager`}
                                    clearable
                                    isLoading={teamMembers === null}
                                    renderOption={(opt) => (
                                        (opt.option.isGroup || opt.option.value == 'OTHER') ?
                                            <Translate id={opt.option.label} />
                                            :
                                            opt.option.label
                                    )}
                                    options={teamMembers ?
                                        formatSelectData(teamMembers.reduce((staffList, staff) => {
                                            if(isManager(staff.position?.position_group)){
                                                staffList.push({
                                                    value: staff.member.member_id,
                                                    label: staff.member.first_name + ' ' + staff.member.last_name,
                                                })
                                            }

                                            return staffList;
                                        },
                                                                            [ {
                                                                                value: 'OTHER',
                                                                                label: 'gameIncident.addModal.label.manager.option.other',
                                                                            } ]),
                                                         {
                                                             getGroupId: (opt) => opt.value == 'OTHER' ? undefined : 'coachList',
                                                             newGroupIndexes: {
                                                                 1: {
                                                                     groupId: 'coachList',
                                                                     label: 'gameIncident.addModal.label.managerList',
                                                                     isGroup: true,
                                                                 },
                                                             },
                                                         })
                                        :
                                        [ {
                                            value: 'OTHER',
                                            label: 'gameIncident.addModal.label.manager.option.other',
                                        } ]
                                    }
                                />
                                <CrossFade isVisible={team?.manager === "OTHER"}>
                                    <FormikInputText
                                        className="mt-2"
                                        name={`${teamField}.manager_name`}
                                        placeholder='gameIncident.addModal.label.manager.other'
                                        trim
                                    />
                                </CrossFade>
                            </FormGroup>
                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="0">
                    <div className="p-3">
                        <FormGroup>
                            <Label for={`${teamField}.team_name`} className="text-muted"><Translate id='gameIncident.addModal.label.team' /> <Required /></Label>
                            <FormikInputText
                                name={`${teamField}.team_name`}
                                id={`${teamField}.team_name`}
                                trim
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={`${teamField}.coach_name`} className="text-muted"><Translate id='gameIncident.addModal.label.coach' /></Label>
                            <FormikInputText
                                name={`${teamField}.coach_name`}
                                id={`${teamField}.coach_name`}
                                trim
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for={`${teamField}.manager_name`} className="text-muted"><Translate id='gameIncident.addModal.label.manager' /></Label>
                            <FormikInputText
                                name={`${teamField}.manager_name`}
                                id={`${teamField}.manager_name`}
                                trim
                            />
                        </FormGroup>
                    </div>
                </TabPane>
            </TabContent>
        </Card>
    )
}

export default TeamCard;