import Translate from "@spordle/intl-elements";
import Skeleton from "react-loading-skeleton";
import { useRoutesSWR } from "./useRoutesSWR";
import { I18nContext } from "../../../../contexts/I18nContext";
import { useContext, Fragment } from "react";
import { displayI18n } from "../../../../helpers/i18nHelper";
import SidebarItem from "./SidebarItem";

const ApiSidebarLinks = ({ navGroup, closeMenu }) => {
    const { getGenericLocale } = useContext(I18nContext);
    const { data, error, isValidating, renderSidebar, getRouteInfo } = useRoutesSWR(navGroup);
    const isLoading = isValidating && !data;

    return (
        isLoading ?
            <div className="px-4">
                {Array.from({ length: navGroup.skeletonsAmount }).map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className="d-flex align-items-center" style={{ height: 39 }}>
                        <Skeleton height={15} width={140} />
                    </div>
                ))}
            </div>
            : error ?
                <div className="px-4">
                    <Translate id='misc.error' />
                </div>
                :
                (data || [])
                    .sort((a, b) => {
                        const nameA = displayI18n('name', a.i18n, a.name, getGenericLocale());
                        const nameB = displayI18n('name', b.i18n, b.name, getGenericLocale());

                        return new Intl.Collator(getGenericLocale(), { sensitivity: 'base' }).compare(nameA, nameB);
                    })
                    .map((route) => {
                        const customRender = renderSidebar?.(route);
                        const itemProps = getRouteInfo?.(route);

                        if(customRender !== void 0){
                            return <Fragment key={itemProps?.key}>{customRender}</Fragment>;
                        }


                        if(itemProps){
                            return (
                                <SidebarItem
                                    key={itemProps.key}
                                    closeMenu={closeMenu}
                                    favoriteData={itemProps.favoriteData}
                                    prop={{
                                        ...itemProps.routeData,
                                        ...itemProps.sidebarData,
                                    }}
                                />
                            )
                        }

                        return null;
                    })

    );
}

export default ApiSidebarLinks;