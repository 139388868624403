import { Tooltip } from '@mantine/core';
import { FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useEffect, useState, useContext } from 'react';
import {
    Badge,
    Button, Card, Collapse, FormGroup,
    Label, ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import { object, string } from 'yup';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import CollapsibleCard from '../../../../components/collapsibleCard/CollapsibleCard';
import CrossFade from '../../../../components/crossFade/CrossFade';
import CustomAlert from '../../../../components/CustomAlert';
import CustomAnimatedIcon from '../../../../components/customAnimatedIcon/CustomAnimatedIcon';
import Required from '../../../../components/formik/Required';
import OverlayLoader from '../../../../components/loading/OverlayLoader';
import UserImg from '../../../../components/UserImg';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';

const ApproveUnconfirmedOrganizationModal = ({ toggle, isOpen, selectedRows, getBadgeColour, spordleTable, forceCloseSidePanel }) => {
    const organizationContext = useContext(OrganizationContext)

    const [ statuses, setStatuses ] = useState([]);
    const [ declineStatus, setDeclineStatus ] = useState('');

    useEffect(() => {
        organizationContext.getOrganizationsStatuses(organizationContext.organisation_id)
            .then((organisation_statuses) => {
                setDeclineStatus(organisation_statuses.find((organisation_status) => (organisation_status.system === 'DECLINED'))?.organisation_status_id)
                setStatuses(
                    organisation_statuses
                        .filter((organisation_status) => (organisation_status.system === 'DECLINED' || organisation_status.system === 'APPROVED')) // API only accepts these two wi
                        .map((organisation_status) => ({
                            value: organisation_status.organisation_status_id,
                            i18n: organisation_status.i18n,
                            label: organisation_status.name,
                            system: organisation_status.system,
                        })),
                )
            })
    }, [])

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='ApproveUnconfirmedOrganizationsModal'>
            <ModalHeader toggle={toggle}>
                <Translate id='task.unconfirmedOrg.modal.title' />
            </ModalHeader>
            <Formik
                initialValues={{
                    organisation_status_id: '',
                    note: '',
                    mode: "UPDATE", // CREATE / UPDATE
                }}
                onSubmit={(data, { setStatus, setSubmitting }) => {
                    if(data.mode == "UPDATE"){
                        organizationContext.updateOrganizationStatuses(selectedRows.map((org) => org.organisation_id), data.organisation_status_id, data.note)
                            .then(() => {
                                setStatus('success');
                                spordleTable.refreshTable();
                                setSubmitting(false)
                            }).catch((error) => {
                                setSubmitting(false)
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                }
                            })
                    }else{
                        Promise.all(selectedRows.map((org) => {
                            return organizationContext.createApprobationRequest(org.organisation_id)
                        }))
                            .then(() => {
                                setStatus('success');
                                spordleTable.refreshTable();
                                setSubmitting(false)
                            }).catch((error) => {
                                setSubmitting(false)
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                }
                            })
                    }
                }}
                validationSchema={object().shape({
                    organisation_status_id: string().required(<Translate id="form.validation.status.required" />),
                    note: string().when('organisation_status_id', {
                        is: (val) => {
                            return (val === declineStatus)
                        },
                        then: string().required(<Translate id='task.unconfirmedOrg.modal.note.required' />),
                        otherwise: string(),
                    }),
                })}
            >
                {(formik) => (
                    <Form>
                        <CrossFade isVisible={formik.status !== 'success'}>
                            <OverlayLoader isLoading={formik.isSubmitting}>
                                <ModalBody>
                                    {selectedRows.length <= 1 ?
                                        <Card className='card-shadow p-2'>
                                            <div className="d-flex align-items-center">
                                                <UserImg
                                                    abbr={selectedRows[0].abbreviation}
                                                    src={selectedRows[0].logo?.full_path}
                                                    filePos={selectedRows[0].logo?.file_position}
                                                    alt={selectedRows[0].organisation_name}
                                                    width="40"
                                                    className={'mr-2'}
                                                />
                                                <div className="w-100 align-items-center text-break">
                                                    <div className='h4 font-bold mb-0'>
                                                        <DisplayI18n field='name' defaultValue={selectedRows[0].organisation_name} i18n={selectedRows[0].i18n} />
                                                    </div>
                                                    <div>
                                                        <Badge
                                                            color={getBadgeColour(selectedRows[0].organisation_status?.system || 'DRAFT')}
                                                        >
                                                            {selectedRows[0].organisation_status ?
                                                                <DisplayI18n
                                                                    field='name'
                                                                    defaultValue={selectedRows[0].organisation_status.name}
                                                                    i18n={selectedRows[0].organisation_status.i18n}
                                                                />
                                                                :
                                                                <Translate id="reports.column.status.DRAFT" />
                                                            }
                                                        </Badge>
                                                    </div>
                                                    <div className='text-muted'><Translate id='task.unconfirmedOrg.sidepanel.submission.date' /> : <DateFormat value={selectedRows[0].submission_date} /></div>
                                                </div>
                                            </div>
                                        </Card>
                                        :
                                        <div className='mb-2'>
                                            <CustomAlert
                                                color='info'
                                                text={'task.unconfirmedOrg.modal.multi.info'}
                                            />
                                            <CollapsibleCard
                                                arrowIsRight
                                                initOpen={selectedRows.length <= 3}
                                                title={
                                                    <div className="position-relative">
                                                        <Translate values={{ count: selectedRows.length }} id="task.unconfirmedOrg.modal.multi.collapse" />
                                                    </div>
                                                }
                                            >
                                                {selectedRows.map((org) => (
                                                    <li key={org.organisation_id}>
                                                        <DisplayI18n field='name' defaultValue={org.organisation_name} i18n={org.i18n} />
                                                    </li>
                                                ))}
                                            </CollapsibleCard>
                                        </div>
                                    }
                                    <FormGroup>
                                        <Label for='organisation_status_id' className='text-muted'><Translate id='misc.status' /> <Required /></Label>
                                        <FormikSelect
                                            id='organisation_status_id'
                                            name='organisation_status_id'
                                            options={statuses}
                                            search={false}
                                            loadingStatus='success'
                                            renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                            onOptionSelected={(values, spordleSelect) => {
                                                const option = spordleSelect.getSpordleTable().getData().find((option) => option.value === values[0])
                                                formik.setFieldValue('mode', (option && option.system == "PENDING") ? "CREATE" : "UPDATE")
                                            }}
                                        />
                                    </FormGroup>
                                    <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                        {formik.status &&
                                        <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                        }
                                    </Collapse>
                                    {/* ONLY POST NOTE IF DECLINED */}
                                    <CrossFade isVisible={statuses.find((s) => s.value === formik.values.organisation_status_id)?.system === 'DECLINED'}>
                                        <FormGroup>
                                            <Label for='note' className='text-muted'>
                                                <Translate id='form.fields.note' /> <Required />
                                                {selectedRows.length > 1 &&
                                                <Tooltip zIndex={3000} wrapLines withArrow position="top" label={<Translate id='task.unconfirmedOrg.modal.note.multi.info' />}>
                                                    <i className="ml-2 mdi mdi-information-outline text-primary" />
                                                </Tooltip>
                                                }
                                            </Label>
                                            <FormikInputText id='note' name='note' />
                                        </FormGroup>
                                    </CrossFade>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' type='submit'><Translate id='misc.submit' /></Button>
                                    <Button color='primary' onClick={toggle} outline><Translate id='misc.cancel' /></Button>
                                </ModalFooter>
                            </OverlayLoader>
                        </CrossFade>
                        {/* SUCCESS STEP */}
                        <CrossFade isVisible={formik.status === 'success'}>
                            <ModalBody className="text-center">
                                <CustomAnimatedIcon withCircle className="text-success" size={50} icon="checkmark" />
                                <div className="h4 font-bold mt-2">
                                    <Translate id="misc.success" />
                                </div>
                                <p>
                                    <Translate id={`task.unconfirmedOrg.modal.success`} />
                                </p>

                                <Button color="primary" type="button" onClick={() => { toggle(); forceCloseSidePanel(); }}>
                                    <Translate id="misc.close" />
                                </Button>
                            </ModalBody>
                        </CrossFade>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

export default ApproveUnconfirmedOrganizationModal;