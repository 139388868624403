import PropTypes from 'prop-types';
import { stringBuilder } from "@spordle/helpers";

/**
 * @param {React.ComponentType} [tag='div'] The desired htmlElement of the container.
 * @param {string} [className]
*/
const UserDisplayTitle = ({
    tag: Tag,
    className,
    ...attributes
}) => {

    return (
        <Tag {...attributes} className={stringBuilder('userDisplay-title', className)} />
    );
};

export default UserDisplayTitle;

UserDisplayTitle.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
};
UserDisplayTitle.defaultProps = {
    tag: 'div',
};