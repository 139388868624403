export default{
    "organization.settings.organizationTeamAddressTypes.tab.title" : "Team Address Types",

    "organization.settings.organizationTeamAddressTypes.table.active" : "Active",
    "organization.settings.organizationTeamAddressTypes.table.empty"  : "No Address Type",

    // add
    "organization.settings.organizationTeamAddressTypes.add.title"           : "Add Address Type",
    "organization.settings.organizationTeamAddressTypes.add.status"          : "Status",
    "organization.settings.organizationTeamAddressTypes.add.status.active"   : "Active",
    "organization.settings.organizationTeamAddressTypes.add.status.inactive" : "Inactive",
    "organization.settings.organizationTeamAddressTypes.add.status.required" : "Status is required",
}