import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import UserImg from "../../../../components/UserImg";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import Recipients from "./recipients/Recipients";
import { InboxContext } from "../inboxContext/InboxContext";
import InlineCopy from "../../../../components/inlineCopy/InlineCopy";
import MessagesActions from "./messageActions/MessagesActions";
import { AppContext } from "../../../../contexts/contexts";
import { useHistory } from "react-router-dom";

const Message = () => {
    const { selectedMsg: msg, emptySelectedMsg } = useContext(InboxContext);
    const { identity } = useContext(IdentityRolesContext);
    const { isMobileDevice } = useContext(AppContext);
    const history = useHistory();
    const { selectMsg } = useContext(InboxContext);

    useEffect(() => {
        if(history.location.state?.message_id){
            selectMsg(history.location.state);
            history.replace({ state: null });
        }else if(history.action !== "REPLACE" && (!isMobileDevice || !history.location.hash)){
            emptySelectedMsg();
        }
    }, [ history.location.pathname, history.location.state, history.location.hash ]);

    return (
        <Card className="card-shadow h-100">
            {msg ?
                <>
                    <header className="p-3 border-bottom">
                        <div className="border-bottom d-lg-none pb-3 mb-3 px-3 mx-n3 d-flex align-items-center justify-content-end">
                            {isMobileDevice && !!msg &&
                                <button
                                    className="reset-btn text-primary mr-auto"
                                    type="button"
                                    onClick={emptySelectedMsg}
                                >
                                    <i className="mdi mdi-arrow-left mr-1" />
                                    <Translate id="communications.inbox.btn.back" />
                                </button>
                            }
                            <MessagesActions isDropdown />
                        </div>
                        <div className="d-flex align-items-center">
                            <UserImg
                                width={45}
                                alt="GM"
                                abbr="GM"
                            />
                            <div className="ml-2 w-100 min-w-0 d-flex align-items-center">
                                <div className="mr-auto text-dark min-w-0">
                                    {
                                        msg.sent_by ?
                                            <>
                                                <div className="font-bold">{msg.sent_by.name} {msg.sent_by.family_name}</div>
                                                <div className="small font-medium">
                                                    <span className="text-muted font-normal mr-1"><Translate id="communications.inbox.from" />:</span>
                                                    <InlineCopy tag="span" toCopy={msg.sent_by.email}>
                                                        {msg.sent_by.email} {msg.sent_by.identity_id === identity.identity_id && "(me)"}
                                                    </InlineCopy>
                                                </div>
                                            </>
                                            :
                                            <div className="font-bold"><Translate id="communications.inbox.deletedSent" /></div>

                                    }
                                    <Recipients message={msg} />
                                </div>
                                <div className="flex-shrink-0 text-nowrap d-none d-lg-block text-right pl-3">
                                    <MessagesActions />
                                    <time className="text-right text-muted mt-2 d-block"><DateFormat value={msg.created_at} format="YYYY-MM-DD HH:mm" /></time>
                                </div>
                            </div>
                        </div>
                    </header>
                    <CardBody>
                        <time className="text-muted small mb-1 d-block d-lg-none"><DateFormat value={msg.created_at} format="YYYY-MM-DD HH:mm" /></time>
                        <div className="h2 font-bold mb-3">{msg.title}</div>
                        <div dangerouslySetInnerHTML={{ __html: msg.body }} />
                    </CardBody>
                </>
                :
                <CardBody className="h-100 text-muted d-flex align-items-center flex-column justify-content-center">
                    <i className="mdi mdi-email-open fa-6x line-height-1" />
                    <Translate id="communications.inbox.selected.empty" />
                </CardBody>
            }
        </Card>
    );
}

export default Message;