import Translate from "@spordle/intl-elements";
import React, { useContext } from "react";
import { Collapse } from "reactstrap";
import { AppContext } from "../../../../../contexts/contexts";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import SidebarLink from "../sidebarLink/SidebarLink";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { canAddToFav, FAV_TOGGLE_BREADCRUMBS } from "../../../../../helpers/breadcrumbs";
import { useLocation } from "react-router-dom";

const FavoriteLinks = ({ isOpen, toggle }) => {
    const { getFavoriteLinks } = useContext(AppContext);
    const { pathname } = useLocation();

    return (
        <li className="sidebar-favorites">
            <div className="sidebar-item">
                <button onClick={toggle} className="w-100 sidebar-link" type="button">
                    <span style={{ width: 25, fontSize: 18 }} className="text-dark text-center mdi mdi-star-outline" /><Translate id="sidebar.favorites" /> <span className={`mdi mdi-chevron-${isOpen ? "down" : "right"} ml-auto`} />
                </button>
            </div>
            <Collapse isOpen={isOpen}>
                <div className="sidebar-favorites-list border rounded-lg mx-2">
                    {getFavoriteLinks().length > 0 ?
                        <PerfectScrollbar style={{ maxHeight: 210 }} options={{ suppressScrollX: true }}>
                            <ul className="list-unstyled bg-transparent mb-0 p-2">
                                {getFavoriteLinks().map((favorite) => (
                                    <SidebarLink
                                        isFavorite
                                        skipActive
                                        key={favorite.path}
                                        linkClassName="w-100"
                                        to={favorite.path}
                                        className="w-100 p-0"
                                    >
                                        <div className="w-100 min-w-0 mr-2">
                                            <div className="text-truncate">
                                                {
                                                    favorite.translate ?
                                                        <Translate id={favorite.label} defaultMessage={favorite.label} />
                                                        :
                                                        favorite.i18n ?
                                                            <DisplayI18n field="label" defaultValue={favorite.label} i18n={favorite.i18n} />
                                                            :
                                                            favorite.label
                                                }
                                            </div>
                                            {(
                                                !!favorite.subtitle
                                                || (Array.isArray(favorite.breadcrumbs) && favorite.breadcrumbs.length > 0)
                                            ) &&
                                                <div className="w-100 small text-truncate text-muted mt-1">
                                                    {favorite.subtitle ?
                                                        favorite.subtitle
                                                        :
                                                        favorite.breadcrumbs.map((crumb, index) => (
                                                            <React.Fragment key={favorite.path + crumb.name}>
                                                                {index === 0 ? "" : " / "}
                                                                {crumb.skipTranslate ? typeof crumb.name === "string" ? crumb.name : ' - ' : <Translate id={crumb.name} />}
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </SidebarLink>
                                ))}
                            </ul>
                        </PerfectScrollbar>
                        :
                        <div className="p-2 text-center small">
                            <div className="h6 font-medium mb-1 text-body">
                                <Translate id="sidebar.favorites.empty.title" />
                            </div>
                            <p className="mb-0"><Translate id="sidebar.favorites.empty.text" values={{ icon: <i className="mdi mdi-star-outline" /> }} /></p>
                            {canAddToFav(pathname) &&
                                <button
                                    className="reset-btn text-link mt-1"
                                    type="button"
                                    onClick={() => {
                                        const crumbsBtn = document.querySelector(`[data-id="${FAV_TOGGLE_BREADCRUMBS}"]`);

                                        crumbsBtn?.click();
                                    }}
                                >
                                    <Translate id="sidebar.favorites.empty.btn" />
                                </button>
                            }
                        </div>
                    }
                </div>
            </Collapse>
        </li>
    );
}

export default FavoriteLinks;