import { useContext, useState, useEffect, useRef } from 'react';
import Translate from "@spordle/intl-elements";
import { Button, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import FormikEditable from "../../../../../components/formik/FormikEditable";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { object, string } from 'yup';
import { ClaimStatus, FINAL_STATUSES } from "../DiscriminationHelper";
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../components/loading/OverlayLoader';
import { fail, success } from '@spordle/toasts';
import { stringBuilder } from '@spordle/helpers';
import FormikTiny from '../../../../../components/formik/FormikTiny';
import DiscriminationSidePanelPeople from './components/tabs/DiscriminationSidePanelPeople';
import DiscriminationSidePanelIncident from './components/tabs/DiscriminationSidePanelIncident';
import UpdateStatusModal from './components/modals/UpdateStatusModal';
import ConfirmModal from '../../../../../components/confirmModal/ConfirmModal';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import AddOutcomeModal from './components/modals/addOutcome/AddOutcomeModal';
import AddSuspensionModal from './components/modals/AddSuspensionModal';
import { RolesContext } from '../../../../../contexts/RolesContext';
import { IdentityRolesContext } from '../../../../../contexts/IdentityRolesContext';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import QuickViewButton from '../../../../../components/quickView/QuickViewButton';
import { Link } from 'react-router-dom';
import DiscriminationSidePanelOutcomes from './components/tabs/DiscriminationSidePanelOutcomes';

const DiscriminationSidePanel = (props) => {
    const { canEdit, selectedRows, syncRows, createNewValues, status } = props;
    const readOnly = FINAL_STATUSES.includes(selectedRows[0].discrimination_status?.system);
    const canBeClosed = !readOnly && Array.isArray(selectedRows[0].maltreatment_outcomes) && selectedRows[0].maltreatment_outcomes.every(({ status }) => [ "COMPLETED" ].includes(status))
    const history = useHistory();
    const initMount = useRef(true); // Checks if it's the initial mount
    const prevDisc = useRef(null); // Used to check if switching between sidepanels

    const { organisation_id, getDiscriminationSpecific, partiallyUpdateDiscrimination, deleteDiscriminationClaim } = useContext(OrganizationContext);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ statusModal, setStatusModal ] = useState({ isOpen: false });
    const [ tab, setTab ] = useState('info');

    const [ addOutcomeIsOpen, setAddOutcomeIsOpen ] = useState(false);
    const [ addSuspensionIsOpen, setAddSuspensionIsOpen ] = useState(false);

    const [ editOutcomeData, setEditOutcomeData ] = useState(false);

    const editOutcome = (outcome) => {
        setEditOutcomeData({ isEdit: true, ...outcome })
        setAddOutcomeIsOpen(true)
    }

    /**
     * @description Default error catch handler
     * @param {Error} e
     */
    const handleCatch = (e) => {

        if(!AxiosIsCancelled(e.message)){
            console.error(e.message)
            fail({
                msg: 'misc.error',
                info: <DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />,
                skipInfoTranslate: true,
            })
            setIsLoading(false);
        }
    }

    /**
     * @description Will get current specific maltreamtent and do a sync row with the whole new row
     */
    const refreshData = () => {
        setIsLoading(true);
        return getDiscriminationSpecific(organisation_id, null, selectedRows[0].discrimination_id) // null will default in context
            .then((discrimination) => {
                syncRows(discrimination)
                setIsLoading(false);
            })
            .catch(handleCatch)
    }

    /**
     * @description Syncs new values to the spordle table
     * @param {object} newVal New values
     * @param {bool} skipSetLoading=false
     */
    const syncNewVal = (newVal, skipSetLoading = false) => {
        syncRows(createNewValues({ ...newVal }));
        if(!skipSetLoading){
            success();
            setIsLoading(false);
        }
    }

    /**
     * @description Patch of fields to update. Handles catch, syncRows, loading state & toasts
     * @param {string} field field to update (example: 'detailed_summary')
     * @param {any} value Value to assign to the field
     */
    const updateValue = (field, value) => { // TODO: remove this weirdly useless function and use updateValues instead
        setIsLoading(true);
        partiallyUpdateDiscrimination(selectedRows[0].discrimination_id, { [field]: value })
            .then(() => {
                success();
                refreshData();
            })
            .catch(handleCatch)
    }

    const updateValues = (values) => {
        setIsLoading(true);
        partiallyUpdateDiscrimination(selectedRows[0].discrimination_id, values)
            .then(() => {
                success();
                refreshData();
            })
            .catch(handleCatch)
    }

    /**
     * @description All status that need a closed_reason
     */
    const statusWithReasons = status?.reduce((reasonsIds, s) => {
        if(s.is_close_status == 1){
            reasonsIds.push(s.discrimination_status_id);
        }
        return reasonsIds;
    }, []);

    const canCreateSuspension = canDoAction('ADD', 'members', 'members_suspensions');

    const handleOnAddedOutcome = async(values) => {
        await refreshData();

        const canOpen = !editOutcomeData?.isEdit && !statusModal.isOpen && (canCreateSuspension || isGod());
        const shouldOpen = (selectedRows[0]?.discrimination_member?.[0]?.member?.member_id || selectedRows[0]?.discrimination_member?.[0]?.team?.team_id) && values.outcome?.causes_suspension == 1;

        if(shouldOpen && canOpen){
            setAddSuspensionIsOpen(true);
        }

    }

    useEffect(() => {
        const search = queryString.parse(history.location.search);

        if(search?.addOutcome){
            if(!readOnly){
                setEditOutcomeData({ isEdit: false, maltreatmentIds: search.maltreatmentIds ? search.maltreatmentIds.split(',') : [] });
                setAddOutcomeIsOpen(true);
            }

            history.replace({ search: undefined });
        }else if(search?.addSuspension){
            if(!readOnly && !editOutcomeData && !statusModal.isOpen && (canCreateSuspension || isGod())){
                setAddSuspensionIsOpen(true);
            }
            history.replace({ search: undefined });
        }

    }, []);

    const maltreatments = (selectedRows[0].maltreatment_outcomes || []).reduce((joinedStatuses, outcome) => joinedStatuses + outcome.status, '');

    useEffect(() => {
        // Will pop the modal if
        // The complaint can be closed
        // If it's not the initial render (was just opened)
        // And if the previous data wasn't another discrimination's
        if(canBeClosed && !initMount.current && prevDisc.current === selectedRows[0]?.discrimination_id){
            setStatusModal({ isAutomatic: true, isOpen: true });
        }

        initMount.current = false;
        prevDisc.current = selectedRows[0]?.discrimination_id;
    }, [ maltreatments ]);

    return (
        <OverlayLoader isLoading={isLoading}>
            <SidePanel.Header noBorder>
                <div className='d-flex mb-3 align-items-center'>
                    <SidePanel.ToggleButton />
                    {!readOnly &&
                        <>
                            {canBeClosed &&
                                <Button
                                    type="button"
                                    color="success"
                                    className="mr-2"
                                    onClick={() => {
                                        setStatusModal({
                                            isAutomatic: true,
                                            isOpen: true,
                                        });
                                    }}
                                >
                                    <Translate id='discrimination.sidePanel.closeComplaint' />
                                </Button>
                            }
                            <SidePanel.ActionsMenu componentCode="organization" componentPermissionCode="discrimination_claims" action="EDIT">
                                <SidePanel.MenuAction onClick={() => setStatusModal({ isOpen: true })}>
                                    <Translate id='discrimination.sidePanel.editStatusModal.btn' />
                                </SidePanel.MenuAction>

                                <SidePanel.MenuAction divider />
                                <SidePanel.MenuAction action="ADD" componentCode="organization" componentPermissionCode="discrimination_claims" onClick={() => setAddOutcomeIsOpen(true)}>
                                    <Translate id="discrimination.outcomes.addOutcome" />
                                </SidePanel.MenuAction>
                                {(canCreateSuspension || isGod()) &&
                                    <SidePanel.MenuAction className={(!canCreateSuspension && isGod()) ? 'text-purple' : undefined} action="ADD" componentCode="organization" componentPermissionCode="link_suspension_maltreatment_claim" skipAccess onClick={() => setAddSuspensionIsOpen(true)}>
                                        <Translate id="discrimination.suspensions.addSuspension" />
                                    </SidePanel.MenuAction>
                                }
                                <SidePanel.MenuAction divider />
                                <ConfirmModal
                                    toggler={(toggle) => (
                                        <SidePanel.MenuAction onClick={toggle} componentCode="organization" componentPermissionCode="discrimination_claims" action="DELETE">
                                            <Translate id='discrimination.sidePanel.deleteClaim.btn' />
                                        </SidePanel.MenuAction>
                                    )}
                                    modalTitle={<Translate id={'discrimination.sidePanel.deleteClaim.title'} />}
                                    modalContent={
                                        <div>
                                            <Translate id='discrimination.sidePanel.case' /> : #{selectedRows[0].sequential_number}
                                        </div>
                                    }
                                    actionButtonText='misc.confirm'
                                    translateActionButtonText
                                    color='danger'
                                    onConfirm={async() => {
                                        return deleteDiscriminationClaim(selectedRows[0].discrimination_id)
                                            .then(() => {
                                                success({ msg: 'misc.success', info: 'discrimination.sidePanel.deleteClaim.success' });
                                                props.tableRef?.refreshTable();
                                                props.forceCloseSidePanel();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    }}
                                />
                            </SidePanel.ActionsMenu>
                        </>
                    }
                </div>
                <SidePanel.Subtitle><Translate id='discrimination.label.complaintBy' /></SidePanel.Subtitle>
                <SidePanel.Title>
                    {selectedRows[0].complaint_by_first_name ?
                        <QuickViewButton member={selectedRows[0].complaint_by_member}>
                            {selectedRows[0].complaint_by_first_name + " " + selectedRows[0].complaint_by_last_name}
                        </QuickViewButton>
                        :
                        <>
                            <Translate id='gameIncident.label.anonymous' /> <i className="mdi mdi-lock-outline" />
                        </>
                    }
                </SidePanel.Title>
                <SidePanel.Subtitle>
                    {selectedRows[0].complaint_by_first_name && selectedRows[0].complaint_by_member &&
                        <Link className="d-block" to={`/members/profile/${selectedRows[0].complaint_by_member.member_id}`}>
                            #{selectedRows[0].complaint_by_member.unique_identifier} <i className="mdi mdi-chevron-right" />
                        </Link>
                    }
                    <Translate id='discrimination.sidePanel.case' /> : #{selectedRows[0].sequential_number}
                    <ClaimStatus discriminationStatus={selectedRows[0].discrimination_status} className="ml-2" size="sm" />
                </SidePanel.Subtitle>
                <SidePanel.Subtitle>
                    <Translate id='discrimination.global.itpReferenceNumber' /> : #{selectedRows[0].reference_number}
                </SidePanel.Subtitle>
            </SidePanel.Header>

            {/* Add Outcome Modal */}
            <AddOutcomeModal
                maltreatment={selectedRows[0]}
                isOpen={addOutcomeIsOpen}
                toggle={() => {
                    setAddOutcomeIsOpen(false)
                    setEditOutcomeData(false);
                }}
                editOutcomeData={editOutcomeData}
                refreshData={handleOnAddedOutcome}
                refreshTable={props.tableRef?.refreshTable}
            />

            {/* Add Suspension Modal */}
            <AddSuspensionModal
                maltreatment={selectedRows[0]}
                isOpen={addSuspensionIsOpen}
                toggle={() => setAddSuspensionIsOpen(false)}
                onSuccess={refreshData}
                initFormData={{
                    location: selectedRows[0].arena || "",
                    incident_date: selectedRows[0].incident_date || "",
                    team_id: selectedRows[0].discrimination_member?.[0]?.team_id || "",
                }}
            />

            {/* Update Status Modal */}
            <UpdateStatusModal
                {...props}
                statusWithReasons={statusWithReasons}
                refreshData={refreshData}
                isOpen={statusModal.isOpen}
                isAutomatic={statusModal.isAutomatic}
                toggle={() => setStatusModal({ isOpen: false })}
            />

            {/* Nav Tabs */}
            <Nav tabs className="is-full">
                <NavItem className="text-center flex-grow-1">
                    <NavLink
                        className={stringBuilder({ active: tab === 'info' })}
                        onClick={() => { setTab('info'); }}
                    >
                        <Translate id='discrimination.label.info' />
                    </NavLink>
                </NavItem>
                <NavItem className="text-center flex-grow-1">
                    <NavLink
                        className={stringBuilder({ active: tab === 'people' })}
                        onClick={() => { setTab('people'); }}
                    >
                        <Translate id='discrimination.label.people' />
                    </NavLink>
                </NavItem>
                <NavItem className="text-center flex-grow-1">
                    <NavLink
                        className={stringBuilder({ active: tab === 'details' })}
                        onClick={() => { setTab('details'); }}
                    >
                        <Translate id="discrimination.label.outcomes" />
                    </NavLink>
                </NavItem>
            </Nav>
            <SidePanel.Body>
                <TabContent activeTab={tab}>
                    <TabPane tabId="info">
                        <DiscriminationSidePanelIncident
                            setIsLoading={setIsLoading}
                            updateValue={updateValue}
                            syncNewVal={syncNewVal}
                            refreshData={refreshData}
                            readOnly={readOnly}
                            updateValues={updateValues}
                            {...props}
                        />
                        <div className="h5 mt-2 font-bold"><Translate id='discrimination.label.details' /></div>
                        <FormGroup>
                            <div className="text-muted"><Translate id='discrimination.label.status' /></div>
                            <div>
                                <ClaimStatus discriminationStatus={selectedRows[0].discrimination_status} />
                            </div>
                        </FormGroup>
                        {statusWithReasons.includes(selectedRows[0].discrimination_status.discrimination_status_id) && !!selectedRows[0].closed_reason &&
                            <div className="bg-light mt-2 p-2 border rounded mb-3">
                                <div className="font-medium mb-1 text-dark">
                                    <Translate id='discrimination.label.closedReason' />
                                </div>
                                <ul className="mb-0 pl-3">
                                    {
                                        selectedRows[0].closed_reason.split(',').map((reason) => (
                                            <li key={'list' + reason}><Translate id={'discrimination.global.closedReasons.' + reason} /></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        }
                        <FormGroup>
                            <div className="text-muted"><Translate id='discrimination.label.summary' /></div>
                            <div style={{ minHeight: '33vh' }} className="p-2 border shadow-sm rounded-lg">
                                <FormikEditable
                                    id="detailed_summary"
                                    manualIcon
                                    readOnly={readOnly}
                                    disabled={!canEdit}
                                    initialValues={{
                                        detailed_summary: selectedRows[0].detailed_summary,
                                    }}
                                    validationSchema={object().shape({
                                        detailed_summary: string().required(<Translate id='discrimination.form.details.required' />),
                                    })}
                                    onSubmit={({ detailed_summary }) => {
                                        if(detailed_summary !== selectedRows[0].detailed_summary){
                                            updateValue('detailed_summary', detailed_summary);
                                        }
                                    }}
                                >
                                    {(isEditing, options) => {
                                        if(!isEditing){
                                            return (
                                                <div className="w-100 position-relative">
                                                    <div className="w-100 pr-4 text-break" dangerouslySetInnerHTML={{ __html: selectedRows[0].detailed_summary }} />
                                                    {canEdit && !readOnly &&
                                                        <i onClick={() => options.startEditing?.()} className="mdi p-2 mdi-pencil text-primary ml-2 position-absolute top-0 right-0" />
                                                    }
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikTiny init={{ height: 450 }} name="detailed_summary" />
                                        )

                                    }}
                                </FormikEditable>
                            </div>
                        </FormGroup>
                    </TabPane>
                    <TabPane tabId="details">
                        <DiscriminationSidePanelOutcomes
                            {...props}
                            readOnly={readOnly}
                            setAddOutcomeIsOpen={setAddOutcomeIsOpen}
                            setAddSuspensionIsOpen={setAddSuspensionIsOpen}
                            editOutcome={editOutcome}
                            refreshData={refreshData}
                        />
                    </TabPane>
                    <TabPane tabId="people">
                        <DiscriminationSidePanelPeople
                            {...props}
                            readOnly={readOnly}
                            syncNewVal={syncNewVal}
                            handleCatch={handleCatch}
                            setIsLoading={setIsLoading}
                            updateValue={updateValue}
                            refreshData={refreshData}
                        />
                    </TabPane>
                </TabContent>
            </SidePanel.Body>
        </OverlayLoader>
    )
}

export default DiscriminationSidePanel;