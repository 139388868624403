import { addExtension, FormikCurrencyInput, FormikInputText, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { formatSelectData } from "@spordle/spordle-select";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Collapse,
    DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
    Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { number, object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../../../../../api/CancellableAPI";
import CrossFade from "../../../../../../../../../components/crossFade/CrossFade";
import Required from "../../../../../../../../../components/formik/Required";
import OverlayLoader from "../../../../../../../../../components/loading/OverlayLoader";
import { fail } from "@spordle/toasts";
import UserDisplay from "../../../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../../../components/UserImg";
import { I18nContext } from "../../../../../../../../../contexts/I18nContext";
import { IdentityRolesContext } from "../../../../../../../../../contexts/IdentityRolesContext";
import { InjuriesContext } from "../../../../../../../../../contexts/InjuriesContext";
import { MembersContext } from "../../../../../../../../../contexts/MembersContext";
import { OrganizationContext } from "../../../../../../../../../contexts/OrganizationContext";
import { RolesContext } from "../../../../../../../../../contexts/RolesContext";
import { HoverableCopy } from "../../../../../../../../../helpers/clipboardHelper";
import { DisplayI18n } from "../../../../../../../../../helpers/i18nHelper";
import { getLocation } from "../../../../../../../../teams/TeamHelpers";
import { CHEQUE_ISSUED_TO } from "../../../../../InsuranceHelper";
import { ISSUED_CLAIMANT, ISSUED_GUARDIAN, ISSUED_OTHER, MEMBER_NOT_LISTED, PAYEE_CREATE } from "../../ChequeHelper";
import PayeeForm, { payeeInit } from "../PayeeForm";
import ChequeAddressSelect from "./ChequeAddressSelect";
import { useIntl } from "react-intl";

const ChequeModalForm = ({ title, toggle, injury, initValues = {}, onSubmit, isOpen }) => {
    const { getMemberContacts, getMemberContactTypes, createMemberContacts } = useContext(MembersContext);
    const { getPredeterminedNotes } = useContext(InjuriesContext);
    const { canDoAction } = useContext(RolesContext);
    const { isGod, federation } = useContext(IdentityRolesContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { formatMessage } = useIntl();
    const { organisation_id, getServiceTypes, getPayees, createPayee } = useContext(OrganizationContext);
    const [ presetNotes, setPresetNotes ] = useState([]);
    const isPowerUser = isGod();
    const canCreatePayee = canDoAction('ADD', 'organization', 'payee_management');
    const canCreateContact = canDoAction('ADD', 'members', 'member_contacts');

    const handleCreateContact = async(values) => {
        return createMemberContacts(injury.member.member_id, [
            {
                firstName: values.first_name,
                lastName: values.last_name,
                contactType: {
                    memberContactTypeId: values.member_contact_type_id,
                },
            },
        ])
            .then((ids) => ids[0])
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    const handleCreatePayee = async(values) => {
        const { payee } = values;
        const apiValues = {
            ...payee,
            phone: await addExtension(payee.phone, payee.extension),
            street_number: payee.address.streetNumber,
            street: payee.address.address,
            city: payee.address.city,
            zip_code: payee.address.zipCode,
            country_code: payee.address.country,
            service_type_id: values.service_type_id,
            province_code: payee.address.state,
            map_url: payee.address.mapsUrl,
            origin_address: payee.address.origin,
        };

        return createPayee(apiValues)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    const handleOnSubmit = async(values, formik) => {
        const apiValues = {
            ...values,
        };

        try{
            apiValues.amount = Math.round(values.amount * 100);

            if(values.pay_to === ISSUED_OTHER){
                apiValues.payee_id = values.payee_id === PAYEE_CREATE ? await handleCreatePayee(values) : values.payee_id;
                apiValues.member_contact_id = "";
                apiValues.member_address_id = "";
            }else if(values.pay_to === ISSUED_GUARDIAN){

                if(values.member_contact_id === MEMBER_NOT_LISTED){
                    apiValues.member_contact_id = await handleCreateContact(values);
                }else{
                    apiValues.member_contact_id = values.member_contact_id;
                }

            }
        }catch(e){
            if(!AxiosIsCancelled(e.message)){
                console.error(e);
                formik.setStatus({
                    error: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />,
                    isOpen: true,
                });
            }
        }

        await onSubmit?.(apiValues, formik);
    }

    useEffect(() => {
        getPredeterminedNotes()
            .then(setPresetNotes)
            .catch(console.error)
    }, [])

    return (
        <Formik
            initialStatus={{
                isOpen: false,
                error: null,
            }}
            initialValues={{
                amount: '',
                first_name: '',
                pay_to: '',
                last_name: '',
                member_address_id: '',
                member_contact_id: '',
                member_contact_type_id: '',
                memo: '',
                payee_id: '',
                payee: payeeInit(organisation_id),
                service_type_id: '',
                ...initValues,
            }}
            validationSchema={object().shape({
                amount: number(<Translate id='form.validation.number' />)
                    .positive(<Translate id='form.validation.number.positive' />)
                    .required(<Translate id='insurance.sidePanel.cheque.addModal.validation.amount' />),
                pay_to: string().required(<Translate id='insurance.sidePanel.cheque.addModal.validation.issued' />),
                member_address_id: string().when('pay_to', {
                    is: (p) => [ ISSUED_GUARDIAN, ISSUED_CLAIMANT ].includes(p),
                    then: (schema) => schema.required(<Translate id='insurance.sidePanel.cheque.addModal.validation.address' />),
                }),
                member_contact_id: string().when('pay_to', {
                    is: ISSUED_GUARDIAN,
                    then: (schema) => schema.required(<Translate id='insurance.sidePanel.cheque.addModal.validation.guardian' />),
                }),
                memo: string(),
                payee_id: string().when('pay_to', {
                    is: ISSUED_OTHER,
                    then: (schema) => schema.required(<Translate id='insurance.sidePanel.cheque.addModal.validation.payee' />),
                }),
                service_type_id: string().required(<Translate id='insurance.sidePanel.cheque.addModal.validation.service' />),
                first_name: string().when([ 'pay_to', 'member_contact_id' ], {
                    is: (payTo, memberId) => payTo === ISSUED_GUARDIAN && memberId === MEMBER_NOT_LISTED,
                    then: (schema) => schema.required(<Translate id='insurance.sidePanel.cheque.addModal.validation.firstName' />),
                }),
                last_name: string().when([ 'pay_to', 'member_contact_id' ], {
                    is: (payTo, memberId) => payTo === ISSUED_GUARDIAN && memberId === MEMBER_NOT_LISTED,
                    then: (schema) => schema.required(<Translate id='insurance.sidePanel.cheque.addModal.validation.lastName' />),
                }),
                member_contact_type_id: string().when([ 'pay_to', 'member_contact_id' ], {
                    is: (payTo, memberId) => payTo === ISSUED_GUARDIAN && memberId === MEMBER_NOT_LISTED,
                    then: (schema) => schema.required(<Translate id='insurance.sidePanel.cheque.addModal.validation.lastName' />),
                }),
                payee: object().when([ 'pay_to', 'payee_id' ], {
                    is: (payTo, payeeId) => payTo === ISSUED_OTHER && payeeId === PAYEE_CREATE,
                    then: object().shape({
                        first_name: string().when('business_name', {
                            is: (val) => !val,
                            then: (schema) => schema.required(<Translate id='form.validation.firstName.required' />),
                        }),
                        last_name: string().when('business_name', {
                            is: (val) => !val,
                            then: (schema) => schema.required(<Translate id='form.validation.lastName.required' />),
                        }),
                        address: object().address(true, {
                            streetNumber: <Translate id='form.validation.streetNumber.required' />,
                            address: <Translate id='form.validation.address.required' />,
                            city: <Translate id='form.validation.city.required' />,
                            zipCode: <Translate id='form.validation.zip.required' />,
                            state: <Translate id='form.validation.province.required' />,
                            country: <Translate id='form.validation.country.required' />,
                        }),
                        business_name: string(),
                        phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />),
                        email: string().email(<Translate id='form.validation.email.valid' />),
                    }),
                }),
            })}
            onSubmit={handleOnSubmit}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <ModalHeader toggle={toggle}>
                            {title}
                        </ModalHeader>
                        <ModalBody>
                            <div className="mb-2">
                                <UserDisplay.Subtitle>
                                    <Translate id={`insurance.label.injuryTypes.${injury.injury_type}`} values={{ name: federation?.organisation_name }} />
                                </UserDisplay.Subtitle>
                                <UserDisplay.Title>
                                    <Translate id='insurance.label.insuranceClaim' />
                                </UserDisplay.Title>
                            </div>
                            <UserDisplay card block>
                                <UserDisplay.Container>
                                    <UserImg
                                        className="text-uppercase"
                                        width={50}
                                        alt={injury.member.first_name + " " + injury.member.last_name}
                                        abbr={injury.member.first_name.charAt(0) + injury.member.last_name.charAt(0)}
                                        src={injury.member.picture?.full_path}
                                        filePos={injury.member.picture?.file_position}
                                    />
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <span className="font-medium">{injury.member.first_name} {injury.member.last_name}</span>
                                    <HoverableCopy toCopy={injury.member.unique_identifier} className="small">
                                        {'#' + injury.member.unique_identifier || '-'}
                                    </HoverableCopy>
                                </UserDisplay.Container>
                            </UserDisplay>
                            <Row form>
                                <Col className="mb-3" sm="6">
                                    <Label for="amount" className="text-muted"><Translate id='form.fields.amount' /> <Required /></Label>
                                    <FormikCurrencyInput
                                        name="amount"
                                        id="amount"
                                        allowNegative={false}
                                    />
                                </Col>
                                <Col className="mb-3" sm="6">
                                    <Label for="service" className="text-muted"><Translate id='insurance.label.service' /> <Required /></Label>
                                    <FormikSelect
                                        name="service_type_id"
                                        id="service_type_id"
                                        searchKeys={[
                                            `i18n.${getGenericLocale()}.name`,
                                        ]}
                                        renderOption={({ option }) => (
                                            <DisplayI18n
                                                field="name"
                                                i18n={option.i18n}
                                                defaultValue={option.label}
                                            />
                                        )}
                                        loadData={(from) => {
                                            const formatServices = (services) => {
                                                return services
                                                    .sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order))
                                                    .map((serv) => ({
                                                        label: serv.name,
                                                        i18n: serv.i18n,
                                                        value: serv.service_type_id,
                                                    }));
                                            }

                                            switch (from){
                                                case 'CDM':
                                                    return getServiceTypes()
                                                        .then(formatServices)
                                                default:
                                                    break;
                                            }
                                        }}
                                    />
                                </Col>
                                <Col className="mb-3" sm="6">
                                    <Label for="pay_to" className="text-muted"><Translate id='insurance.sidePanel.cheque.addModal.label.issued' /> <Required /></Label>
                                    <FormikSelect
                                        name="pay_to"
                                        id="pay_to"
                                        search={false}
                                        defaultData={CHEQUE_ISSUED_TO.map((to) => ({
                                            label: 'insurance.sidePanel.cheque.addModal.label.issued.' + to,
                                            value: to,
                                            translateLabel: true,
                                        }))}
                                        loadingStatus='success'
                                    />
                                </Col>
                                <Col sm="6">
                                    <CrossFade isVisible={formik.values.pay_to === ISSUED_OTHER}>
                                        <Label className="text-muted"><Translate id='insurance.sidePanel.cheque.addModal.label.payee' /> <Required /></Label>
                                        <FormikSelect
                                            name="payee_id"
                                            id="addchequemodal-payee_id"
                                            renderOption={({ option }) => (
                                                option.isGroup ?
                                                    <Translate id={option.label} />
                                                    :
                                                    option.value === PAYEE_CREATE ?
                                                        <>
                                                            <div className={stringBuilder("d-flex align-items-center", { "text-purple": !canCreatePayee && isPowerUser })}>
                                                                <i className="mdi mdi-plus-circle mr-1" />
                                                                <Translate id={option.label} />
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div>{option.label}</div>
                                                            <div className="text-muted small">{getLocation(option)}</div>
                                                        </>
                                            )}
                                            loadData={(from, _, { setLoading }) => {
                                                const formatPayees = (payees) => {
                                                    const init = [];
                                                    const renderPayeeLabel = (payee) => {
                                                        if(payee.first_name){
                                                            return `${payee.prefix ? formatMessage({ id: `settings.settings.payees.prefix.${(payee.prefix).toUpperCase()}`, defaultMessage: payee.prefix }) + " " : ""}${payee.first_name} ${payee.last_name}${payee.business_name ? ` ${payee.business_name}` : ""}`;
                                                        }
                                                        return payee.business_name || "";

                                                    }

                                                    const sortedPayees = payees.sort((a, b) => (renderPayeeLabel(a).trim()).localeCompare(renderPayeeLabel(b).trim()));

                                                    if(isPowerUser || canCreatePayee){
                                                        init.push({
                                                            value: PAYEE_CREATE,
                                                            label: 'insurance.sidePanel.cheque.addModal.label.createPayee',
                                                            translateLabel: true,
                                                        })
                                                    }

                                                    return formatSelectData(
                                                        [
                                                            ...init,
                                                            ...sortedPayees,
                                                        ],
                                                        {
                                                            getValue: (payee) => payee.value === PAYEE_CREATE ? payee.value : payee.payee_id,
                                                            getLabel: (payee) => payee.value === PAYEE_CREATE || !!payee.groupId ? payee.label : renderPayeeLabel(payee),
                                                            getGroupId: (payee) => payee.value === PAYEE_CREATE ? undefined : 'payee',
                                                            newGroupIndexes: {
                                                                1: {
                                                                    label: 'insurance.sidePanel.cheque.addModal.label.payees',
                                                                    translateLabel: true,
                                                                    groupId: 'payee',
                                                                },
                                                            },
                                                        },
                                                    );
                                                }

                                                switch (from){
                                                    case 'CDM':
                                                        return getPayees({
                                                            province_code: injury.province_code || '',
                                                        })
                                                            .then(formatPayees)

                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                        <Collapse isOpen={!formik.values.service_type_id}>
                                            <i className="mdi mdi-information-outline text-primary" />
                                            <small className="text-muted ml-1">
                                                <Translate id='insurance.sidePanel.cheque.addModal.helper.service' />
                                            </small>
                                        </Collapse>
                                    </CrossFade>
                                    <CrossFade isVisible={formik.values.pay_to === ISSUED_GUARDIAN}>
                                        <Label for="member_contact_id" className="text-muted"><Translate id='insurance.label.guardian' /> <Required /></Label>
                                        <FormikSelect
                                            id="member_contact_id"
                                            name="member_contact_id"
                                            renderOption={({ option }) => (
                                                option.isGroup ?
                                                    <Translate id={option.label} />
                                                    :
                                                    option.value === MEMBER_NOT_LISTED ?
                                                        <>
                                                            <div className={stringBuilder("d-flex align-items-center", { "text-purple": !canCreateContact && isPowerUser })}>
                                                                <i className="mdi mdi-plus-circle mr-1" />
                                                                <Translate id={option.label} />
                                                            </div>
                                                        </>
                                                        :
                                                        <div>{option.label}</div>
                                            )}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                        return getMemberContacts(injury.member.member_id)
                                                            .then((contacts) => {
                                                                const init = []

                                                                if(canCreateContact || isPowerUser){
                                                                    init.push({
                                                                        value: MEMBER_NOT_LISTED,
                                                                        label: 'insurance.sidePanel.cheque.addModal.label.notlisted',
                                                                        translateLabel: true,
                                                                    })
                                                                }

                                                                return formatSelectData(
                                                                    [
                                                                        ...init,
                                                                        ...contacts,
                                                                    ],
                                                                    {
                                                                        getValue: (v) => v.value === MEMBER_NOT_LISTED ? v.value : v.member_contact_id,
                                                                        getLabel: (v) => v.value === MEMBER_NOT_LISTED || !!v.groupId ? v.label : v.first_name + ' ' + v.last_name,
                                                                        getGroupId: (v) => v.value === MEMBER_NOT_LISTED ? undefined : 'contact',
                                                                        newGroupIndexes: {
                                                                            1: {
                                                                                label: 'members.profile.overview.memberContacts.title',
                                                                                translateLabel: true,
                                                                                groupId: 'contact',
                                                                            },
                                                                        },
                                                                    },
                                                                );
                                                            });
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                    </CrossFade>
                                </Col>
                                <Col sm="12">
                                    <CrossFade isVisible={formik.values.pay_to === ISSUED_OTHER && formik.values.payee_id === PAYEE_CREATE}>
                                        <PayeeForm />
                                    </CrossFade>
                                </Col>
                                {(canCreateContact || isPowerUser) &&
                                    <Col sm="12">
                                        <CrossFade isVisible={formik.values.member_contact_id === MEMBER_NOT_LISTED && formik.values.pay_to === ISSUED_GUARDIAN}>
                                            <Row className="pb-3 border-bottom mb-3" form>
                                                <Col sm="4">
                                                    <Label className="text-muted"><Translate id="form.fields.firstName" /> <Required /></Label>
                                                    <FormikInputText id="firstName" name="first_name" />
                                                </Col>
                                                <Col sm="4">
                                                    <Label className="text-muted"><Translate id="form.fields.lastName" /> <Required /></Label>
                                                    <FormikInputText id="lastName" name="last_name" />
                                                </Col>
                                                <Col sm="4">
                                                    <Label className="text-muted"><Translate id='insurance.sidePanel.cheque.addModal.label.contactType' /> <Required /></Label>
                                                    <FormikSelect
                                                        name="member_contact_type_id"
                                                        id="member_contact_type_id"
                                                        renderOption={({ option }) => <DisplayI18n field="name" i18n={option.i18n} label={option.label} />}
                                                        searchKeys={[
                                                            `i18n.${getGenericLocale()}.name`,
                                                        ]}
                                                        loadData={(from) => {
                                                            switch (from){
                                                                case 'CDM':
                                                                    return getMemberContactTypes({ organisation_id: organisation_id })
                                                                        .then((types) => {
                                                                            return types.map((type) => ({ value: type.member_contact_type_id, label: type.name, i18n: type.i18n }))
                                                                        });
                                                                default:
                                                                    break;
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm="12">
                                                    <i className="mdi mdi-information-outline text-primary" />
                                                    <small className="text-muted ml-1">
                                                        <Translate id='insurance.sidePanel.cheque.addModal.helper.guardianNotListed' />
                                                    </small>
                                                </Col>
                                            </Row>
                                        </CrossFade>
                                    </Col>
                                }
                                <Col className="mb-3" sm="12">
                                    <CrossFade isVisible={[ "CLAIMANT", ISSUED_GUARDIAN ].includes(formik.values.pay_to)}>
                                        <ChequeAddressSelect injury={injury} />
                                    </CrossFade>
                                </Col>
                                <Col className="mb-3" sm="12">
                                    <Label for="memo" className="text-muted d-flex align-items-center justify-content-between">
                                        <Translate id='form.fields.memo' />
                                        {presetNotes.length > 0 &&
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle size="sm" color="link" className="px-0 small">
                                                    <Translate id='insurance.sidePanel.cheque.addModal.label.presetNotes' />
                                                </DropdownToggle>
                                                <DropdownMenu style={{ width: 200 }} right>
                                                    {presetNotes.map((note) => (
                                                        <DropdownItem
                                                            key={note.injury_cheque_predetermined_notes_id}
                                                            onClick={() => formik.setFieldValue('memo', note.description)}
                                                            className="text-truncate d-block"
                                                        >
                                                            {note.description}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        }
                                    </Label>
                                    <FormikTextArea
                                        id="memo"
                                        name="memo"
                                        trim
                                        maxLength={255}
                                    />
                                </Col>
                            </Row>
                            <Collapse isOpen={!!formik.status.isOpen}>
                                <Alert color="danger" toggle={() => formik.setStatus({ ...formik.status, isOpen: false })}>
                                    {formik.status.error}
                                </Alert>
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            <Button disabled={!isOpen || formik.isSubmitting} color="primary" type="submit">
                                <Translate id="misc.confirm" />
                            </Button>
                            <Button disabled={!isOpen || formik.isSubmitting} onClick={toggle} type="button" color="primary" outline>
                                <Translate id="misc.cancel" />
                            </Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default ChequeModalForm;