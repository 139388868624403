export default{
    "onlineStore.profile.transactions.modals.refundModal.btn.refund"                             : "Refund",
    "onlineStore.profile.transactions.modals.refundModal.form.refundAmount"                      : "Amount",
    "onlineStore.profile.transactions.modals.refundModal.form.refundAmount.helper"               : "Available amount",
    "onlineStore.profile.transactions.modals.refundModal.form.paymentMethod"                     : "Payment method",
    "onlineStore.profile.transactions.modals.refundModal.form.paymentMethod.CHEQUE"              : "Cheque",
    "onlineStore.profile.transactions.modals.refundModal.form.paymentMethod.CREDITCARD"          : "Credit card",
    "onlineStore.profile.transactions.modals.refundModal.form.paymentMethod.CASH"                : "Cash",
    "onlineStore.profile.transactions.modals.refundModal.form.cancelInstallments.title"          : "Cancel Installments",
    "onlineStore.profile.transactions.modals.refundModal.form.cancelInstallments"                : "Cancel all pending installments for this member",
    "onlineStore.profile.transactions.modals.refundModal.from.cancelInstallments.members"        : "Member {active}/{max}",
    "onlineStore.profile.transactions.modals.refundModal.from.cancelInstallments.cancelled"      : "Installments have been cancelled for this member.",
    "onlineStore.profile.transactions.modals.refundModal.form.internalNote"                      : "Internal note",
    "onlineStore.profile.transactions.modals.refundModal.form.internalNote.helper"               : "This note will be visible to administrators only.",
    "onlineStore.profile.transactions.modals.refundModal.form.externalNote"                      : "External note",
    "onlineStore.profile.transactions.modals.refundModal.form.externalNote.helper"               : "This note will be visible to the client. It will be sent with the refund email and available for consultation in their account’s transaction list.",
    "onlineStore.profile.transactions.modals.refundModal.form.validation.refundAmount.required"  : "Please specify the amount of the refund.",
    "onlineStore.profile.transactions.modals.refundModal.form.validation.refundAmount.positive"  : "Please specify a positive amount.",
    "onlineStore.profile.transactions.modals.refundModal.form.validation.refundAmount.max"       : "Cannot refund more than the paid amount ({paidAmount}).",
    "onlineStore.profile.transactions.modals.refundModal.form.validation.paymentMethod.required" : "Please indicate a payment method.",
    "onlineStore.profile.transactions.modals.refundModal.title"                                  : "Refund transaction",
    "onlineStore.profile.transactions.modals.refundModal.cancel.msg"                             : "The transaction was successfully refunded.",
    "onlineStore.profile.transactions.modals.refundModal.cancel.question"                        : "Do you wish to cancel the item(s) linked to this transaction as well?",
    "onlineStore.profile.transactions.modals.refundModal.cancel.btn"                             : "Cancel item(s)",

    "onlineStore.profile.transactions.modals.refundModal.error.3045" : "The refund payment method does not match the invoice payment method.",
    "onlineStore.profile.transactions.modals.refundModal.error.3433" : "Interpay refund error. Please Try again in a few minutes.",
    "onlineStore.profile.transactions.modals.refundModal.error.3434" : "Interpay returned invalid data. Please try again in a few minutes.",
    "onlineStore.profile.transactions.modals.refundModal.error.3435" : "Installment amount to refund was greater than available amount to refund.",
    "onlineStore.profile.transactions.modals.refundModal.error.3436" : "Transaction has not completed yet or the status is pending.",
    "onlineStore.profile.transactions.modals.refundModal.error.3437" : "Paysafe refund error. Please Try again in a few minutes.",
    "onlineStore.profile.transactions.modals.refundModal.error.3446" : "Payment not found. Please Try again in a few minutes.",
    "onlineStore.profile.transactions.modals.refundModal.error.3447" : "Error refunding this payment. Please Try again in a few minutes.",
    "onlineStore.profile.transactions.modals.refundModal.error.3448" : "Expired or invalid card.",

    "onlineStore.profile.transactions.modals.paymentReceptionModal.title"                     : "Confirm payment reception",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.date"                      : "Reception date",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.date.required"             : "Reception date is required",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.date.after"                : "Reception date cannot be before the transaction date",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.date.before"               : "Reception date cannot be after today",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount"                    : "Amount",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.positive"           : "Amount must be positive",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.max.credit"         : "Cannot pay more than the credit amount",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.max.due.member"     : "Cannot apply this amount as credit because {maxAmount} is left to pay for that member",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.max.funding.member" : "The maximum funding amount for the selected member is {maxAmount}.",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.max.due"            : "Cannot pay more than the due amount ({maxAmount})",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.required"           : "Amount is required",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.amount.applicableAmount"   : "Applicable amount ",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.paymentMethod"             : "Payment method",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.paymentMethod.required"    : "Please indicate a payment method.",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.memberCredit"              : "Member credit",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.memberCredit.required"     : "Please select a member credit",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.memberFunding"             : "Member",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.memberFunding.required"    : "Please select a member",
    "onlineStore.profile.transactions.modals.paymentReceptionModal.note"                      : "Note",

}