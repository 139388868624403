import { GAmodalView } from '@spordle/ga4';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

/**
 * Modal wrapper to link modal views to GA4
 * @param {import('reactstrap').ModalProps & {analyticsName: string}} props
 * @returns {React.ReactNode}
 */
const AnalyticsModal = ({ analyticsName, ...props }) => {
    const onOpened = () => {
        props.onOpened?.();
        GAmodalView(analyticsName, 'OPEN');
    }

    const onClosed = () => {
        props.onClosed?.();
        GAmodalView(analyticsName, 'CLOSE');
    }

    return <Modal {...props} onOpened={onOpened} onClosed={onClosed} />;
}

AnalyticsModal.propTypes = {
    analyticsName: PropTypes.string.isRequired,
};

export default AnalyticsModal;