import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { jsObjectToApi } from '@spordle/helpers';

/** @type {React.Context<Omit<HelpCenterContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const HelpCenterContext = createContext();
HelpCenterContext.displayName = 'HelpCenterContext';

class HelpCenterContextProvider extends React.PureComponent{

    /**
     * [PUT] Creates a help bubble
     * @param {object} values
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injuries/8f38b3b901cfed08f499e0c6a6d99e15|documentation}
     */
    createHelpBubble = (values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params, { skipNull: true });

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/help-bubbles` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] Updates a help bubble
     * @param {string} help_bubble_id
     * @param {object} values
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injuries/8f38b3b901cfed08f499e0c6a6d99e15|documentation}
     */
    udpateHelpBubble = (help_bubble_id, values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params, { skipNull: true });

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/help-bubbles/${help_bubble_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Delete a help bubble
     * @param {string} help_bubble_id
     * @see The {@link https://api.id.dev.spordle.dev/documentations/index.php#/Help%20Bubbles/4efe63439fd3046fcdb31864bc6afe84|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteHelpBubble = (help_bubble_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/help-bubbles/${help_bubble_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PUT] Creates a help bubble alert
     * @param {object} values
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injuries/8f38b3b901cfed08f499e0c6a6d99e15|documentation}
     */
    createHelpBubbleAlert = (values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params, { skipNull: true });

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/help-bubble-additionals` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] Updates a help bubble alert
     * @param {string} help_bubble_id
     * @param {object} values
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Injuries/8f38b3b901cfed08f499e0c6a6d99e15|documentation}
     */
    udpateHelpBubbleAlert = (help_bubble_additional_id, values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params, { skipNull: true });

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/help-bubble-additionals/${help_bubble_additional_id }` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * Delete a help bubble
     * @param {string} help_bubble_additional_id
     * @see The {@link https://api.id.dev.spordle.dev/documentations/index.php#/Help%20Bubbles/e49704dcba5083d5027b745db3da30e3|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteHelpBubbleAlert = (help_bubble_additional_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `/help-bubble-additionals/${help_bubble_additional_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Returns a help bubble of a SID page
     * @param {string} organisation_id
     * @returns {Promise<Array<string>>}
     */
    getHelpBubbleResources = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/help-bubble-resources',
            query: Object.assign({
                organisation_id: queryParams.organisation_id || '',
                active: queryParams.active,
            }),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.help_bubble_resources;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Returns a help bubble of a SID page
     * @param {string} organisation_id
     * @param {string} page_url
     * @param {string} version
     * @param {string} product
     * @param {string} component_id
     * @param {string} active
     * @returns {Promise<Array<string>>}
     */
    getHelpBubble = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/help-bubbles',
            query: Object.assign({
                organisation_id: queryParams.organisation_id || '',
                page_url: queryParams.page_url,
                version: queryParams.version || '1',
                product: queryParams.product,
                component_id: queryParams.component_id,
                active: queryParams.active,
            }),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return { helpBubble: response.data.help_bubbles, helpBubbleResources: response.data.help_bubble_resources };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Returns a help center of a SID page
     * @param {string} pageUrl Page of the help data to receive
     * @returns {Promise<Array<string>>}
     */
    getHelpCenter = (pageUrl, config = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/page-help-centers',
            query: Object.assign({
                page_url: pageUrl,
                version: '1', // temporary
            }),
        }, {
            ...config,
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.page_help_centers;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <HelpCenterContext.Provider value={{ ...this }}>
                {this.props.children}
            </HelpCenterContext.Provider>
        );
    }
}

export default HelpCenterContextProvider;
