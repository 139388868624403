import PropTypes from 'prop-types';
import Dot from '../../components/Dot';
import ClinicCategoryBuilder from './ClinicCategoryBuilder';

/**
* @param {Number} [color=0] number associated with the clinic color
* @example <ClinicCategory color="0">Coach<ClinicCategory>
*/
const ClinicCategory = ({ tag: Tag, className, color = 0, hideColor = false, ...attributes }) => {

    // if the color cannot be a number, fallback to 0
    if(isNaN(parseInt(color))) color = 2

    return (
        <div className="d-inline-flex align-items-center">
            {!hideColor &&
                <Dot color={"clinic-" + color} className="flex-shrink-0 mr-1" />
            }
            <Tag {...attributes} className={className} />
        </div>
    );
};

ClinicCategory.Builder = ClinicCategoryBuilder;

ClinicCategory.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.oneOfType([
        PropTypes.string, // string must be parseIntable "3" is good. "hello" is not
        PropTypes.number,
    ]),
};
ClinicCategory.defaultProps = {
    tag: 'div',
    color: 0,
};

export default ClinicCategory;