/* eslint-disable react/no-unused-class-component-methods */
import React, { createContext, useContext } from 'react'
import Translate from '@spordle/intl-elements';
import { useIntl } from 'react-intl';
import { object, string } from 'yup';
import { OrganizationContext } from '../contexts/OrganizationContext';
import withContexts from './withContexts';

/** @type {React.Context<Omit<I18nHelperContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const I18nHelperContext = createContext();
I18nHelperContext.displayName = 'I18nHelperContext';

class I18nHelperContextProvider extends React.Component{
    constructor(props){
        super(props)

        // single or multiple fields logic
        const fields = Array.isArray(props.fields) ? props.fields : [ props.fields ]

        // set languages
        const languages = props.languages || props.OrganizationContext.settings.language?.value || [ 'en' ];

        this.state = {
            fields: fields,
            languages: languages.sort((a, b) => a - b),
            labels: props.labels ?? {},
        }
    }

    /**
     * Returns an I18N object containing the initial values for all fields in every available language for the organization - Field variables fallback to non i18n field, and then ''
     * @param {Object} data Object containing both the i18n object and the non-i18n field
     * @returns {Object}
     */
    getInitialValues = (data) => {
        const initialValues = {
            i18n: {},
        }
        this.state.languages.forEach((lang) => {
            initialValues.i18n[lang] = {};
            // specified fields logic
            // if(fields?.length > 0){
            //     // append params fields
            //     fields.forEach(field => {
            //         i18n[lang][field] = displayI18n(field, data?.i18n, data?.[field], lang);
            //     })
            // } else {
            //     // append state fields
            // }
            this.state.fields.forEach((field) => {
                // append non-translated fields
                initialValues[field] = data?.[field] || ''
                // data?.[field] || '' -> fallback to empty string for create forms
                initialValues.i18n[lang][field] = displayI18n(field, data?.i18n, data?.[field], lang) || '';
            })
        })
        return initialValues
    }

    /**
     * Returns an I18N object containing the validation schema for all fields in every available language for the organization - Defaults to Yup.string()
     * @param {Object} [fields] Object containing key-values used to override the default validation
     * @example
     * getValidationSchema({'name': Yup.string.required(), 'description': Yup.string().required()})
     * @returns {Object}
     */
    getValidationSchema = (fields) => {
        const i18n = {}
        this.state.languages.forEach((lang) => {
            i18n[lang] = {};
            // if(fields?.length > 0){
            //     // append params fields
            //     fields.forEach(field => {
            //         if(typeof field !== 'string'){
            //             for(const key in field){
            //                 i18n[lang][key] = field[key]
            //             }
            //         } else {
            //             i18n[lang][field] = Yup.string();
            //         }
            //     })
            // } else {
            //     // append state fields
            // }
            const myI18n = {};
            this.state.fields.forEach((field) => {
                if(fields && fields[field]){
                    myI18n[field] = fields[field]
                }else{
                    myI18n[field] = string();
                }
            })

            i18n[lang] = object().shape(myI18n);

        })
        return { i18n: object().shape(i18n) }
    }

    /**
     * Returns an array containing the Spordle Table columns for the specified fields in every available languages for the organization - Defaults to
     * {
     *     label: this.state.languages.length > 1 ? <Translate id={`form.fields.${field}.lang`} values={{lang: lang.toUpperCase()}}/> : <Translate id={`form.fields.${field}`}/>,
     *     key: `i18n.${lang}.${field}`,
     *     className: 'text-left',
     *     dataClassName: 'text-left',
     *     mobile: true,
     *     sortable: true,
     * }
     * @param  {...(string|Object))} [fields] Fields that will be returned in the I18N object - Optional, all fields are sent by default
     * @returns {Array}
     */
    getTableColumns = (...fields) => {
        const columnsArray = []
        this.state.languages.forEach((lang) => {
            if(fields?.length > 0){
                fields.forEach((field) => {
                    // custom labeling that doesn't match the field (example: "Title" label for name)
                    const labelWithLang = this.state.labels[field] ? `form.fields.${this.state.labels[field]}.lang` : `form.fields.${field}.lang`
                    const label = this.state.labels[field] ? `form.fields.${this.state.labels[field]}` : `form.fields.${field}`
                    if(typeof field !== 'string'){
                        for(const key in field){
                            columnsArray.push(field[key])
                        }
                    }else{
                        columnsArray.push({
                            label: this.state.languages.length > 1 ? <Translate id={labelWithLang} values={{ lang: lang.toUpperCase() }} /> : <Translate id={label} />,
                            key: `i18n.${lang}.${field}`,
                            defaultKey: field,
                            fallbackSortKey: field,
                            className: 'text-left',
                            dataClassName: 'text-left',
                            mobile: true,
                            sortable: true,
                        })
                    }
                })
            }else{
                this.state.fields.forEach((field) => {
                    // custom labeling that doesn't match the field (example: "Title" label for name)
                    const labelWithLang = this.state.labels[field] ? `form.fields.${this.state.labels[field]}.lang` : `form.fields.${field}.lang`
                    const label = this.state.labels[field] ? `form.fields.${this.state.labels[field]}` : `form.fields.${field}`
                    columnsArray.push({
                        label: this.state.languages.length > 1 ? <Translate id={labelWithLang} values={{ lang: lang.toUpperCase() }} /> : <Translate id={label} />,
                        key: `i18n.${lang}.${field}`,
                        defaultKey: field,
                        fallbackSortKey: field,
                        className: 'text-left',
                        dataClassName: 'text-left',
                        mobile: true,
                        sortable: true,
                    })
                })
            }
        })
        return columnsArray
    }

    /**
     * Returns an object containing every field in every language in this format: {'i18n[fr][name]': 'french', 'i18n[en][name]': 'english'}
     * @param {Object} values Values object coming directly from formik
     * @returns {Object}
     */
    getAPIValues = (values) => {
        const APIValues = {};
        this.state.languages.forEach((lang) => {
            // if(fields?.length > 0){
            //     fields.forEach(field => {
            //         APIValues[`i18n[${lang}][${field}]`] = values.i18n[lang][field]

            //         // logic to affect the non i18n field -> either current lang is first is array and EN doesn't exists, or lang is EN
            //         if((lang === this.state.languages[0] && this.state.languages.findIndex(_lang => _lang === 'en') === -1) || lang === 'en'){
            //             APIValues[field] = values.i18n[lang][field]
            //         }
            //     })
            // } else {
            //     // append i18n fields
            // }
            for(const field in values.i18n[lang]){
                APIValues[`i18n[${lang}][${field}]`] = values.i18n[lang][field]

                // logic to affect the non i18n field -> either current lang is first is array and EN doesn't exists, or lang is EN
                if((lang === this.state.languages[0] && this.state.languages.findIndex((_lang) => _lang === 'en') === -1) || lang === 'en'){
                    APIValues[field] = values.i18n[lang][field]
                }
            }
        })
        return APIValues
    }

    // Needed to update entity i18n (name, description) from the i18n object using our fallback logic
    appendI18nForTable = (values) => {
        const tableValues = { ...values }
        this.state.languages.forEach((lang) => {
            for(const field in values.i18n[lang]){
                // logic to affect the non i18n field -> either current lang is first is array and EN doesn't exists, or lang is EN
                if((lang === this.state.languages[0] && this.state.languages.findIndex((_lang) => _lang === 'en') === -1) || lang === 'en'){
                    tableValues[field] = values.i18n[lang][field]
                }
            }
        })
        return tableValues
    }

    /**
     * Appends updated language values to the URLSearchParams
     * @param {string} fieldName The fieldname string from the RenderI18nForm
     * @param {object} dataI18n i18n object from the selected rows in the props (example: this.props.selectedRows[0].i18n)
     * @param {object} submitValues the values object from the onSubmit in the field
     */
    compare = (fieldName, dataI18n, submitValues) => {
        const fieldNames = fieldName.split('.');
        return (submitValues?.[fieldNames[0]]?.[fieldNames[1]]?.[fieldNames[2]] === dataI18n?.[fieldNames[1]]?.[fieldNames[2]]);
    }

    render(){
        return (
            <I18nHelperContext.Provider value={this}>
                {this.props.children}
            </I18nHelperContext.Provider>
        );
    }
}

/**
 * Used to render forms and formikEditables.
 * Will map languages in i18nHelper's state and give fieldName, fieldLabel according to the field prop
 * @param {object} props Field wanted for the form
 * @param {string} props.field Field wanted for the form
 * @returns {React.ReactNode}
 */
const RenderI18nForm = ({ field, ...props }) => {
    const i18nHelperContext = useContext(I18nHelperContext)

    // custom labeling that doesn't match the field (example: "Title" label for name)
    const labelWithLang = i18nHelperContext.state.labels[field] ? `form.fields.${i18nHelperContext.state.labels[field]}.lang` : `form.fields.${field}.lang`
    const label = i18nHelperContext.state.labels[field] ? `form.fields.${i18nHelperContext.state.labels[field]}` : `form.fields.${field}`
    return (
        i18nHelperContext.state.languages.map((lang, index) => {
            return props.children({
                lang: lang,
                fieldName: `i18n.${lang}.${field}`,
                fieldLabel: (i18nHelperContext.state.languages.length) > 0 ? <Translate id={labelWithLang} values={{ lang: lang.toUpperCase() }} /> : <Translate id={label} />,
                index: index,
            })
        })
    )
}

/**
 * Checks if a translation is available for the current Locale, returns default value or null if not
 * @param {Object} i18n API Object containing I18N variables
 * @param {string} locale Current Locale
 * @param {string} field Name of the Field
 * @returns {string|null}
 */
function displayI18n(field, i18n, defaultValue, locale){
    if(i18n && i18n[locale] && i18n[locale][field]){
        return i18n[locale][field]
    }
    return defaultValue || null

}

/**
 * Display i18n fields from a i18n object
 * @param {object} props The field we want to show in the i18n object. Field can be 2 formats: "name" or "i18n.en.name" if we want to specify a lang (usually from renderCustom)
 * @param {string} props.field The field we want to show in the i18n object. Field can be 2 formats: "name" or "i18n.en.name" if we want to specify a lang (usually from renderCustom)
 * @param {Object} props.i18n i18n object that should contain fr/en objects ex: i18n[en][name]
 * @param {?string} props.defaultValue default value to use if the i18n is not present (usually the fallback field on the entity)
 * @param {string} [props.lang] A specific language we want to display the field in
 */
const DisplayI18n = ({ field, i18n, defaultValue, lang, ...props }) => {
    const intl = useIntl();

    // Detect field format (i18n.en.name)
    const splitValue = field.split('.')
    const myLang = splitValue.length > 1 ? splitValue[splitValue.length - 2] : intl.locale.split('-')[0]

    return (
        displayI18n(splitValue[splitValue.length - 1], i18n, defaultValue, lang ?? myLang)
    );
}

export{ RenderI18nForm, DisplayI18n, displayI18n }
export default withContexts(OrganizationContext)(I18nHelperContextProvider)

///////////////////////////////////////////////////////////////////////////
// This Helper is to help manage the I18n logic for Spordle Table, Add Modals and Side Panels, basically a helper for I18N CRUD operations
///////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////
// These 4 functions should be used in the SpordleTable View
///////////////////////////////////////////////////////////////////////////

/**
 * Creates and returns an object containing the initialValues & validationSchema for the creation Formik, columns for the Spordle Table and the languages array
 * @param {Array} languages Array of the languages to process into initialValues, validationSchema and columns
 * @param {string|array} fields Field to create variables for (e.g. 'name')
 * @param {string} [fieldLabel] Translate message ID for the label - The ID must render a message containing a 'lang' property in case of multiple languages - Using 'form.fields.${field}' by default
 * @param {string} [validationMessage] Translate message ID for validation - The ID must render a message containing a 'lang' property in case of multiple languages - Using 'form.validation.${field}.required' by default
 * @param {string} [tableClassName] className prop for the table columns - Using 'text-left' by default
 * @param {string} [tableDataClassName] dataClassName prop for the table columns - Using 'text-left' by default
 * @param {string} [tableMobile] mobile prop for the table columns - Using true by default
 * @param {string} [tableSortable] sortable prop for the table columns - Using true by default
 * @returns {Object}
 */
export function initTableViewI18n(languages, fields, fieldLabel = null, validationMessage = null, tableClassName = 'text-left', tableDataClassName = 'text-left', tableMobile = true, tableSortable = true){
    const i18n = {
        // basic languages array (e.g. ['en', 'fr'])
        languages: languages,
        // initialValues and validationSchema both used for the Create
        initialValues: {},
        validationSchema: {},
        // columns for the Spordle Table
        columns: [],
    };

    if(!Array.isArray(fields)){
        fields = [ fields ]
    }

    fields.forEach((field) => {
        languages.forEach((lang, index) => {
            // push in columns here for the already known properties (key, className, etc.)
            // the 'label' property will be added later when we check how many languages there are
            // we want 'Name' when there is a single language, and 'Name (EN)' when there are multiple languages
            // the same logic is applied to the validationSchema, 'Name is required' vs 'Name (EN) is required'
            const newLength = i18n.columns.push({
                key: `${field}_${lang}`, // e.g. 'name_en'
                className: tableClassName,
                dataClassName: tableDataClassName,
                mobile: tableMobile,
                sortable: tableSortable,
            });
            // for the creation Formik
            i18n.initialValues[`${field}_${lang}`] = '';

            if(languages.length > 1){
                i18n.validationSchema[`${field}_${lang}`] = string().required(<Translate id={validationMessage ?? `form.validation.${field}.lang.required`} values={{ lang: lang.toUpperCase() }} />);
                i18n.columns[newLength - 1].label = <Translate id={fieldLabel ?? `form.fields.${field}.lang`} values={{ lang: lang.toUpperCase() }} />;
            }else{
                i18n.validationSchema[`${field}_${lang}`] = string().required(<Translate id={validationMessage ?? `form.validation.${field}.required`} />);
                i18n.columns[newLength - 1].label = <Translate id={fieldLabel ?? `form.fields.${field}`} />
            }
        })
    })

    return i18n;
}

/**
 * Formats and returns an I18N object ready for the Spordle Table (same format as the API's)
 * @param {string} languages Languages Array
 * @param {string|array} fields Field for the I18N object (e.g. 'name')
 * @param {Object} values Object containing the values from the creation Formik
 * @returns {Object}
 */
export function formatI18nForTable(languages, fields, values){
    const i18n = {};
    if(!Array.isArray(fields)){
        fields = [ fields ]
    }

    // field to fallback to in case there isn't any translation for a language
    const noI18nField = [];
    languages.forEach((lang) => {
        i18n[lang] = {}
        fields.forEach((field) => {
            noI18nField[field] = null;
            i18n[lang][field] = values[`${field}_${lang}`]
            if(lang === 'en'){
                noI18nField[field] = values[`${field}_${lang}`]
            }
        });
    });

    // returnObject = {
    //     i18n = {
    //         en = {
    //             name = 'english name'
    //         },
    //         fr = {
    //             name = 'french name'
    //         }
    //     },
    //     name = 'english name'
    // }
    const returnObject = {}
    returnObject.i18n = i18n;
    fields.forEach((field) => {
        returnObject[field] = noI18nField[field] ?? values[`${field}_${languages[0]}`];
    });

    return returnObject;
}

/**
 * Works the same way as a Map or a ForEach - Renders an input for every language by sending the fieldName as a parameter to the callback
 * @param {Array} languages Languages Array
 * @param {string} field Field for the fieldName (e.g. 'name')
 * @param {Function} callback Function to render the Fields - Receives fieldName and index in parameters
 * @returns {JSX}
 */
export function renderI18nFields(languages, field, callback){
    return (
        languages.map((lang, index) => {
            return (
                callback(`${field}_${lang}`, index)
            )
        })
    )
}

/**
 * Renders the translated field or fallbacks on the field directly if no translation is available
 * @param {string} columnKey Column Key from the Spordle Table
 * @param {Object} row Row Object from the Spordle Table
 * @param {Object} [props] Props to apply to the <span> wrapping the text
 * @returns {JSX}
 */
export function renderI18nTableColumn(columnKey, row, props = undefined){
    const field = columnKey.split('_')[0];
    const lang = columnKey.split('_')[1];

    return (
        <span {...props}>{row.i18n && row.i18n[lang] && row.i18n[lang][field] ? row.i18n[lang][field] : row[field]}</span>
    )
}
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////
// These 3 function should be used in the SidePanel view
///////////////////////////////////////////////////////////////////////////

/**
 * Creates and returns an Object containing the initialValues and validationSchema for the Formik Editables, and a language Array
 * @param {Array} languages Languages Array
 * @param {string|array} fields Field to create variables with (e.g. 'name')
 * @param {Object} i18nFromAPI API object containing the I18N variables
 * @param {string} validationMessage Translate message ID for validation - The ID must render a message containing a 'lang' property in case of multiple languages - Using 'form.validation.${field}.required' by default
 * @returns {Object}
 */
export function initSidePanelViewI18n(languages, fields, i18nFromAPI, validationMessage = null){
    // this logic is very similar to the one in initTableViewI18n
    // the main difference is the format in which we store the data
    // the variables were named 'name_en' in initTableViewI18n because a Spordle Table and a Formik were containing all the languages at once, so we needed different names for the variable
    // in the Side Panel, we don't have this problem, because every language has its own FormikEditable, so the format used here is similar to the API's :
    // i18n: {
    //     en: {
    //         name: ''
    //     }
    //     fr: {
    //         name: ''
    //     }
    // }
    const i18n = {
        languages: languages,
        initialValues: {},
        validationSchema: {},
    }

    if(!Array.isArray(fields)){
        fields = [ fields ]
    }
    languages.forEach((lang) => {
        i18n.initialValues[lang] = {} // Init lang

        fields.forEach((field) => {
            i18n.initialValues[lang][field] = i18nFromAPI && i18nFromAPI[lang] ? i18nFromAPI[lang][field] : ''

            // Fallback logic if we only have one language or more
            if(languages.length > 1){
                i18n.validationSchema[lang] = object().shape({})
                i18n.validationSchema[lang][field] = string().required(<Translate id={validationMessage ?? `form.validation.${field}.lang.required`} values={{ lang: lang }} />)
            }else{
                i18n.validationSchema[lang] = object().shape({})
                i18n.validationSchema[lang][field] = string().required(<Translate id={`form.validation.${field}.required`} />)
            }
        })
    });
    return i18n;
}

/**
 * Generates a Label for the Formik Editable
 * @param {bool} multipleLanguages Wether or not there are multiple languages
 * @param {string} lang Language needed for the Label
 * @param {string} field Name of the Field
 * @param {string} [label] Translate message ID for the label - The ID must render a message containing a 'lang' property in case of multiple languages - Using 'form.fields.${field}' by default
 * @returns {JSX}
 */
export function getFormikEditableLabel(multipleLanguages, lang, field, label = null){
    if(multipleLanguages){
        return (<Translate id={label ?? `form.fields.${field}.lang`} values={{ lang: lang }} />)
    }
    return (<Translate id={label ?? `form.fields.${field}`} />)

}

///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////
// These functions should be used in the Contexts
///////////////////////////////////////////////////////////////////////////

/**
 * Appends every language value to the URLSearchParams Object
 * @param {Object} params URLSearchParams object to append new data
 * @param {string} field Name of the field
 * @param {Object} values Object containing the values from the Creation Formik
 * @param {Object} i18n Object containing the I18N information like languages, etc.
 */
export function appendI18nForCreate(params, field, values, i18n){
    // appending the first language to the non-translated field property
    params.append(field, values[`${field}_${i18n.languages[0]}`])
    i18n.languages.forEach((lang) => {
        // appending every language to the i18n object
        params.append(`i18n[${lang}][${field}]`, values[`${field}_${lang}`]);

        // if we find 'en' in the languages, we remove the previously appended non-translated field and append the english field for it
        if(lang === 'en'){
            params.delete(field);
            params.append(field, values[`${field}_${lang}`]);
        }
    })
}

/**
 * Appends updated language values to the URLSearchParams
 * @param {Object} params URLSearchParams object to append new data
 * @param {string} field Name of the Field
 * @param {string} lang Language of the value
 * @param {Object} values Object containing the values from the Formik Editable
 * @param {Object} i18n Object containing the I18N information like languages, etc.
 */
export function appendI18nForUpdate(params, field, lang, values, i18n, fallback){
    params.append(`i18n[${lang}][${field}]`, values[field] || fallback)
    // if lang is the first language in the list and if english is not available, or if the language is english, we append that language as the non-translated field
    if((lang === i18n.languages[0] && i18n.languages.findIndex((_lang) => _lang === 'en') === -1) || lang === 'en'){
        params.append(field, values[field])
    }
    // TODO: remove these 3 lines when API fixes i18n patch (should be able to patch on a single language without affecting the others)
    //i18n.languages.filter(_lang => _lang !== lang).forEach(otherLangs => {
    //    params.append(`i18n[${otherLangs}][${field}]`, i18n.initialValues[otherLangs][field])
    //})
}
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////


/**
* **********************************************************************************************************************
* Display I18n functon & Component
* **********************************************************************************************************************
*/

//Legacy func to maintain functionnality
export function displayField(i18nFromAPI, locale, field){
    if(i18nFromAPI && i18nFromAPI[locale]){
        return i18nFromAPI[locale][field]
    }
    return null

}

