export default{
    "organization.settings.sidePanelOrganizationTeamContactTypes.title" : "Informations de type de contact",

    "organization.settings.sidePanelOrganizationTeamContactTypes.status"          : "Statut",
    "organization.settings.sidePanelOrganizationTeamContactTypes.status.active"   : "Actif",
    "organization.settings.sidePanelOrganizationTeamContactTypes.status.inactive" : "Inactif",
    "organization.settings.sidePanelOrganizationTeamContactTypes.status.required" : "Le statut est requis",

    "organization.settings.sidePanelOrganizationTeamContactTypes.update.success.text" : "Type de contact mis à jour",
    "organization.settings.sidePanelOrganizationTeamContactTypes.update.fail.text"    : "Une erreur est survenue lors de la mise à jour du type de contact, veuillez rafraichir la page et réessayer",

    // actions
    "organization.settings.sidePanelOrganizationTeamContactTypes.actions.remove"         : "Retirer",
    "organization.settings.sidePanelOrganizationTeamContactTypes.actions.remove.topText" : "Vous êtes sur le point de retirer ce type de contact de votre organisation",

    // tabs
    "organization.settings.sidePanelOrganizationTeamContactTypes.tabs.details" : "Détails",
}