import { addExtension, FormikCheckedButton, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import {
    Button, Card, CardBody, Col, FormGroup,
    Label, ModalFooter, Row
} from "reactstrap";
import {
    boolean,
    object,
    string
} from 'yup';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import Required from '../../../../../../components/formik/Required';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { fail } from "@spordle/toasts";
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { PhoneTypesContext } from '../../../../../../contexts/PhoneTypesContext';
import { TeamsContext } from '../../../../../../contexts/TeamsContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';

/**
* @param {bool} [wizard=false] fix layout when this is in the wizard creation
*/
const TeamContactEdit = ({ onCancel, onSubmit, teamContactTypes, phoneTypes, currentEditingContact, wizard = false, ...props }) => {
    const organizationContext = useContext(OrganizationContext)
    const teamsContext = useContext(TeamsContext)
    const phoneTypesContext = useContext(PhoneTypesContext)

    const [ myTeamContactTypes, setTeamContactTypes ] = useState(teamContactTypes)
    const [ myPhoneTypes, setPhoneTypes ] = useState(phoneTypes)

    const getPhone = (phone) => {
        if(phone.includes('#')){
            return phone.split('#')[0]
        }
        return phone
    }

    const getExtension = (phone) => {
        if(phone.includes('#')){
            return phone.split('#')[1]
        }
        return ''

    }


    useEffect(() => {
        if(wizard){
            teamsContext.getTeamContactTypes({ organisation_id: organizationContext.organisation_id })
                .then(setTeamContactTypes)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        setTeamContactTypes(null);
                    }
                })

            phoneTypesContext.getPhoneTypes({ organisation_id: organizationContext.organisation_id })
                .then(setPhoneTypes)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                        setPhoneTypes(null);
                    }
                })
        }
    }, []);

    return (
        <Formik
            initialValues={{
                firstName: currentEditingContact?.first_name || '',
                lastName: currentEditingContact?.last_name || '',
                phone: (currentEditingContact && currentEditingContact.team_contact_id_public !== 'template') ? getPhone(currentEditingContact?.phone) : '',
                phoneTypeId: currentEditingContact?.phone_type?.phone_type_id || '',
                extension: (currentEditingContact && currentEditingContact.team_contact_id_public !== 'template') ? getExtension(currentEditingContact?.phone) : '',
                email: currentEditingContact?.email || '',
                contactType: currentEditingContact?.team_contact_type?.team_contact_type_id || '',
                displayOn: !!currentEditingContact?.display_on || false,
            }}
            validationSchema={object().shape({
                firstName: string().required(<Translate id='form.validation.firstName.required' />),
                lastName: string().required(<Translate id='form.validation.lastName.required' />),
                phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />).required(<Translate id='form.validation.phone.required' />),
                phoneTypeId: string().required(<Translate id='form.validation.phoneType.required' />),
                email: string().email(<Translate id='form.validation.email.valid' />),
                contactType: string().required(<Translate id='teams.profile.teamInfos.contact.role.required' />),
                displayOn: boolean(),
            })}
            onSubmit={async(values) => {
                const phone = await addExtension(values.phone, values.extension);
                const newValues = {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    phone: phone,
                    phone_type_id: values.phoneTypeId,
                    team_contact_type_id: values.contactType,
                    display_on: values.displayOn ? 'TEAM_ROSTER_PDF' : '',
                }
                if(values.email)
                    newValues.email = values.email;

                if(wizard && !currentEditingContact){
                    return teamsContext.createTeamContact(teamsContext.cachedTeam.team_id, newValues)
                        .then(async() => {
                            await teamsContext.getTeam(teamsContext.cachedTeam.team_id, true, { components: 'contact,address,competition' })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                    }
                                })
                            onSubmit()
                        }).catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                }
                return new Promise((resolve, reject) => {
                    if(currentEditingContact && currentEditingContact.team_contact_id_public !== 'template'){ // update
                        teamsContext.updateTeamContactPartial(teamsContext.cachedTeam.team_id, currentEditingContact.team_contact_id_public, newValues)
                            .then(resolve)
                            .catch((error) => {
                                reject();
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            })
                    }else{ // create
                        teamsContext.createTeamContact(teamsContext.cachedTeam.team_id, newValues)
                            .then(resolve)
                            .catch((error) => {
                                reject();
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            })
                    }
                })
                    .then(async() => {
                        await teamsContext.getTeam(teamsContext.cachedTeam.team_id, true, { components: 'contact,address,competition' })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                }
                            })
                        onSubmit()
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e.message)
                        }
                    })

            }}
        >
            {(formik) => (
                <Form>
                    <Card className={wizard && "card-shadow shadow-primary"}>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <CardBody className={!wizard && 'pb-0'}>
                                {wizard &&
                                    <CardSectionTitle title="teams.profile.teamInfos.contact.title" />
                                }
                                <Row form>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='firstName' className='text-muted'><Translate id='form.fields.firstName' /> <Required /></Label>
                                            <FormikInputText id='firstName' name='firstName' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='lastName' className='text-muted'><Translate id='form.fields.lastName' /> <Required /></Label>
                                            <FormikInputText id='lastName' name='lastName' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='contactType' className='text-muted'><Translate id='teams.profile.teamInfos.contact.role' /> <Required /></Label>
                                            <FormikSelect
                                                name='contactType' id='contactType'
                                                isLoading={!myTeamContactTypes}
                                                renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                options={myTeamContactTypes?.reduce((newArray, type) => {
                                                    if(type.active == 1){
                                                        newArray.push({
                                                            value: type.team_contact_type_id,
                                                            label: type.name,
                                                            i18n: type.i18n,
                                                        })
                                                    }
                                                    return newArray
                                                }, [])}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for='email' className='text-muted'><Translate id='form.fields.email' /></Label>
                                            <FormikInputText id='email' name='email' type='email' />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12">
                                        <Row form>
                                            <Col sm='6' md='6'>
                                                <FormGroup>
                                                    <Label for='phone' className='text-muted'><Translate id='form.fields.phone' /> <Required /></Label>
                                                    <FormikPhoneInput name="phone" id="phone" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm='6' md='2'>
                                                <FormGroup>
                                                    <Label for='extension' className='text-muted'><Translate id='form.fields.extension' /></Label>
                                                    <FormikInputNumber id='extension' name='extension' />
                                                </FormGroup>
                                            </Col>
                                            <Col sm='6' md='4'>
                                                <FormGroup>
                                                    <Label for='phoneTypeId' className='text-muted'><Translate id='form.fields.phoneType' /> <Required /></Label>
                                                    <FormikSelect
                                                        name='phoneTypeId' id='phoneTypeId'
                                                        isLoading={!myPhoneTypes}
                                                        renderOption={({ option }) => <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                        options={myPhoneTypes?.reduce((newArray, type) => {
                                                            if(type.active == 1){
                                                                newArray.push({
                                                                    value: type.phone_type_id,
                                                                    label: type.name,
                                                                    i18n: type.i18n,
                                                                })
                                                            }
                                                            return newArray
                                                        }, [])}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm='6'>
                                                <FormGroup>
                                                    <Label for='displayOn' className='text-muted' />
                                                    <FormikCheckedButton
                                                        id={'displayOn'}
                                                        name={'displayOn'}
                                                        label={<div className='text-muted'><Translate id='teams.profile.teamInfos.contact.display.on.team.roster' /></div>}
                                                        translateLabel={false}
                                                        onChange={(e) => {
                                                            formik.setFieldValue('displayOn', e.target.checked);
                                                        }}
                                                        className={`w-100`}
                                                        checked={formik.values.displayOn}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>

                        </OverlayLoader>
                    </Card>
                    {!wizard &&
                        <ModalFooter className="text-right bg-white d-flex">
                            <div className='ml-auto'>
                                <Button type='submit' color="primary">
                                    <Translate id='misc.add' />
                                </Button>
                                <Button type='button' className='ml-2' color="primary" outline onClick={onCancel}>
                                    <Translate id='misc.cancel' />
                                </Button>
                            </div>
                        </ModalFooter>
                    }
                    {wizard &&
                        <div className={"mt-3 d-flex mb-5"}>
                            <Button color="primary" type="button" onClick={onCancel} outline><Translate id='misc.back' /></Button>
                            <div className='ml-auto'>
                                <Button color='primary' outline type='button' onClick={() => onSubmit()} className='mr-2'><Translate id='teams.profile.teamInfos.contact.skip' /></Button>
                                <Button color="primary" type="submit"><Translate id='misc.next' /></Button>
                            </div>
                        </div>
                    }
                </Form>
            )}
        </Formik>
    );
}

export default TeamContactEdit;