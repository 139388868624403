import { addExtension, DisplayPhoneNumber, FormikDateTime, FormikError, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect, FormikSwitch } from "@spordle/formik-elements";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext } from "react";
import { Button, Card, Col, FormGroup, Label, Row } from "reactstrap";
import { array, boolean, object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import FormikEditable from "../../../../../components/formik/FormikEditable";
import { fail, success } from '@spordle/toasts';
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import getReferrer from "../../../../../helpers/getReferer";
import { stringifyUrl } from "query-string";
import { AuthContext } from "../../../../../contexts/contexts";
import { FieldArray } from "formik";
import generatePassword from "../../../../../helpers/passwordGenerator";
import moment from "moment";
import { RolesContext } from "../../../../../contexts/RolesContext";

const SidePanelGeneral = (props) => {
    const orgContext = useContext(OrganizationContext);
    const authContext = useContext(AuthContext)
    const { canDoAction } = useContext(RolesContext)

    const handleCatch = (error) => {
        if(!AxiosIsCancelled(error.message)){
            console.error(error.message);
            fail({
                msg: 'misc.error',
                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                skipInfoTranslate: true,
            })
            props.toggleLoading(false);
        }
    }

    const getPageReferrer = (withAuth = false) => {
        return stringifyUrl({
            url: orgContext.deploy_on == 'PAGE' ?
            `${getReferrer('PAGE-VERCEL-2')}/${orgContext.short_name}`
                : orgContext.deploy_on == 'VERCEL' ?
                    `${getReferrer('PAGE-VERCEL')}/${orgContext.short_name}`
                    :
                    `${getReferrer('PAGE')}/page/${orgContext.short_url}`,
            query: {
                accessToken: withAuth ? authContext.accessToken : undefined,
            },
        }, {
            skipEmptyString: true,
            skipNull: true,
        })
    }

    const canEdit = canDoAction('EDIT', 'organization', 'organization_staff')

    return (
        <div className="p-3">
            <FormGroup>
                <Label for="status" className="text-muted mb-0"><Translate id='catalog.clinicItems.sidepanel.form.status' /></Label>
                <FormikEditable
                    id="status"
                    noConfirmation
                    readOnly={!canEdit}
                    initialValues={{
                        status: props.selectedRows[0].active,
                    }}
                    onSubmit={(values) => {
                        if(values.status !== props.selectedRows[0].active){
                            props.toggleLoading(true);
                            const newValues = props.createNewValues({ active: values.status });
                            orgContext.updateOrganizationContactPartially(props.OrganizationContext.organisation_id, props.selectedRows[0].organisation_contact_id, { active: values.status })
                                .then(() => {
                                    props.syncRows(newValues);
                                    success();
                                    props.toggleLoading(false);
                                })
                                .catch(handleCatch)
                        }
                    }}
                >
                    {(isEditing, options, formik) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'>
                                    {props.selectedRows[0]?.active == "1" ?
                                        <><i className="mdi mdi-check text-primary mr-1" /><Translate id="misc.active" /></>
                                        :
                                        <><i className="mdi mdi-close text-danger mr-1" /><Translate id="misc.inactive" /></>
                                    }
                                </div>
                            )
                        }
                        return (
                            <FormikSelect
                                search={false}
                                name='status'
                                id='status_SidePanelGeneral'
                                autoFocus
                                menuIsDefaultOpen
                                onOptionSelected={options.stopEditing}
                                options={[
                                    { id: 'status-active', label: 'misc.active', value: '1', selected: formik.initialValues.status == '1', translateLabel: true },
                                    { id: 'status-inactive', label: 'misc.inactive', value: '0', selected: formik.initialValues.status == '0', translateLabel: true },
                                ]}
                            />
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <FormGroup>
                <Label for="organisation_contact_types" className="text-muted mb-0">
                    <Translate id='organization.profile.staff.formik.label.contactTypes' />
                </Label>
                <FormikEditable
                    id="organisation_contact_types"
                    readOnly={!canEdit}
                    initialValues={{
                        organisation_contact_types: props.selectedRows[0].organisation_contact_functions?.map((contactFunction) => ({
                            id: contactFunction.organisation_contact_function_id,
                            organisation_contact_type_id: contactFunction.organisation_contact_type.organisation_contact_type_id,
                            is_elected: contactFunction.is_elected,
                            elected_until: contactFunction.elected_until || '',
                        })),
                    }}
                    validationSchema={object().shape({
                        organisation_contact_types: array().of(object().shape({
                            organisation_contact_type_id: string()
                                .required(<Translate id='organization.profile.staff.formik.label.contactTypes.role.required' />)
                                .test({
                                    name: 'noDuplicateRoles',
                                    message: <Translate id='organization.profile.staff.formik.label.contactTypes.role.noDuplicate' />,
                                    test: function(value){
                                        //check for duplicates
                                        return !(this.from[this.from.length - 1].value.organisation_contact_types.filter((role) => role.organisation_contact_type_id === value).length > 1)
                                    },
                                }),
                            is_elected: string(),
                            elected_until: string().test({
                                name: 'validDate',
                                message: <Translate id='organization.profile.staff.formik.electedUntil.valid' />,
                                test: function(value){
                                    if(this.parent.is_elected == 1)
                                        return moment(value).isValid();
                                    return true;
                                },
                            }),
                        })),
                    })}
                    onSubmit={(values) => {
                        props.toggleLoading(true);
                        const newValues = {
                            organisation_contact_types: values.organisation_contact_types.map((role) => ({
                                ...role,
                                elected_until: (role.elected_until && role.is_elected == 1) ? moment(role.elected_until).format('YYYY-MM-DD') : '',
                                active: '1',
                            })),
                            first_name: props.selectedRows[0].first_name,
                            last_name: props.selectedRows[0].last_name,
                            email: props.selectedRows[0].email,
                            phone: props.selectedRows[0].phone,
                            visible_on_website: props.selectedRows[0].visible_on_website,
                            email_visible_on_website: props.selectedRows[0].email_visible_on_website,
                            phone_visible_on_website: props.selectedRows[0].phone_visible_on_website,
                            active: props.selectedRows[0].active,
                        }

                        orgContext.updateOrganizationContact(props.OrganizationContext.organisation_id, props.selectedRows[0].organisation_contact_id, newValues)
                            .then(() => {
                                props.tableRef.refreshTable()
                                props.syncRows(props.createNewValues({
                                    organisation_contact_functions: newValues.organisation_contact_types.map((role) => {
                                        const foundContactType = props.types.find((type) => type.organisation_contact_type_id === role.organisation_contact_type_id)
                                        return ({
                                            organisation_contact_function_id: role.organisation_contact_type_id, // for key in display
                                            active: '1',
                                            // api errors out if we set elected until without is_elected == 1
                                            elected_until: (role.elected_until && role.is_elected == 1) ? moment(role.elected_until) : '',
                                            is_elected: role.is_elected,
                                            organisation_contact_type: {
                                                ...role,
                                                i18n: foundContactType.i18n,
                                                name: foundContactType.name,
                                            },
                                        })
                                    }),
                                }));
                                success();
                                props.toggleLoading(false);
                            })
                            .catch(handleCatch)

                    }}
                >
                    {(isEditing, _options, formik) => {
                        if(!isEditing){
                            return (
                                props.selectedRows[0].organisation_contact_functions && props.selectedRows[0].organisation_contact_functions?.length > 1 ?
                                    <div className='font-medium text-dark'>
                                        <ul className="ml-n4 mb-0">
                                            {props.selectedRows[0].organisation_contact_functions.map((contactFunction) => (
                                                <li key={contactFunction.organisation_contact_function_id}>
                                                    <DisplayI18n field="name" i18n={contactFunction.organisation_contact_type.i18n} defaultValue={contactFunction.organisation_contact_type.name} />
                                                    {contactFunction.is_elected == 1 && !contactFunction.elected_until &&
                                                        <span className="ml-1 text-muted small"><Translate id="organization.profile.staff.formik.elected" /></span>
                                                    }
                                                    {contactFunction.elected_until &&
                                                        <span className="text-muted small ml-1">
                                                            <Translate id='organization.profile.staff.formik.electedUntil' /> <DateFormat className='ml-1' value={contactFunction.elected_until} />
                                                        </span>
                                                    }
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    :
                                    <div className='font-medium text-dark'>
                                        <DisplayI18n field="name" i18n={props.selectedRows[0].organisation_contact_functions?.[0]?.organisation_contact_type?.i18n} defaultValue={props.selectedRows[0].organisation_contact_functions?.[0]?.organisation_contact_type?.name} />
                                        {props.selectedRows[0].organisation_contact_functions?.[0]?.is_elected == 1 && !props.selectedRows[0].organisation_contact_functions?.[0]?.elected_until &&
                                            <span className=" ml-1 text-muted small"><Translate id="organization.profile.staff.formik.elected" /></span>
                                        }
                                        {props.selectedRows[0].organisation_contact_functions?.[0]?.elected_until &&
                                            <span className="text-muted small ml-1">
                                                <Translate id='organization.profile.staff.formik.electedUntil' /> <DateFormat className='ml-1' value={props.selectedRows[0].organisation_contact_functions?.[0]?.elected_until} />
                                            </span>
                                        }
                                    </div>
                            )
                        }
                        return (
                            <FieldArray name="organisation_contact_types">
                                {(arrayHelper) => (
                                    <div className="mb-5">
                                        {formik.values.organisation_contact_types?.map((orgSwitch, index) => (
                                            <Card className="card-shadow mb-2 p-3" key={orgSwitch.id}>
                                                <div className="d-flex w-100">
                                                    <div className="w-100">
                                                        <FormGroup className="mb-2">
                                                            <div className="w-100">
                                                                <FormikSelect
                                                                    search={false}
                                                                    name={`organisation_contact_types.${index}.organisation_contact_type_id`}
                                                                    id={`organisation_contact_types.${index}.organisation_contact_type_id`}
                                                                    placeholder='organization.profile.staff.formik.label.contactTypes.singular'
                                                                    renderOption={({ option }) => <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />}
                                                                    options={props.types.map((type) => ({
                                                                        label: type.name,
                                                                        i18n: type.i18n,
                                                                        value: type.organisation_contact_type_id,
                                                                    }))}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                        <FormGroup className="d-flex mb-0">
                                                            <div className="w-100">
                                                                <Label><Translate id='organization.profile.staff.formik.isElected' /></Label>
                                                                <FormikSelect
                                                                    name={`organisation_contact_types.${index}.is_elected`}
                                                                    id={`organisation_contact_types.${index}.is_elected`}
                                                                    searchable={false}
                                                                    loadingStatus="success"
                                                                    defaultData={[
                                                                        {
                                                                            value: '1',
                                                                            label: 'misc.yes',
                                                                            translateLabel: true,
                                                                        },
                                                                        {
                                                                            value: '0',
                                                                            label: 'misc.no',
                                                                            translateLabel: true,
                                                                        },
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div className="ml-3 w-100">
                                                                <Label><Translate id='organization.profile.staff.formik.electedUntil' /></Label>
                                                                <FormikDateTime
                                                                    name={`organisation_contact_types.${index}.elected_until`}
                                                                    id={`organisation_contact_types.${index}.elected_until`}
                                                                    timeFormat={false} className='date-picker-left'
                                                                    disabled={formik.values.organisation_contact_types[index].is_elected == 0}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </div>
                                                    <div>
                                                        { index !== 0 &&
                                                        <button type="button" onClick={arrayHelper.handleRemove(index)} className="ml-2 btn btn-sm btn-link px-0 text-danger">
                                                            <i className="fas fa-trash" />
                                                        </button>
                                                        }
                                                    </div>
                                                </div>
                                            </Card>
                                        ))}
                                        <Button
                                            color="primary"
                                            outline
                                            type="button"
                                            onClick={arrayHelper.handlePush({
                                                id: generatePassword(),
                                                organisation_contact_type_id: '',
                                                is_elected: '0',
                                                elected_until: '',
                                                active: '1',
                                            })}
                                            className="w-100"
                                        >
                                            <i className={'mdi mdi-plus mr-1'} /><Translate id='organization.profile.staff.formik.label.contactTypes.add' />
                                        </Button>
                                    </div>
                                )}
                            </FieldArray>
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <FormGroup>
                <Label for="organisation_contact_type_id" className="text-muted mb-0">
                    <Translate id='form.fields.email' />
                </Label>
                <FormikEditable
                    id="organisation_contact_type_id"
                    readOnly={!canEdit}
                    initialValues={{
                        email: props.selectedRows[0].email,
                    }}
                    validationSchema={object().shape({
                        email: string().email().required(),
                    })}
                    onSubmit={(values) => {
                        if(values.email !== props.selectedRows[0].email){
                            props.toggleLoading(true);
                            const newValues = props.createNewValues({ email: values.email });
                            orgContext.updateOrganizationContactPartially(props.OrganizationContext.organisation_id, props.selectedRows[0].organisation_contact_id, values)
                                .then(() => {
                                    props.syncRows(newValues);
                                    success();
                                    props.toggleLoading(false);
                                })
                                .catch(handleCatch)
                        }
                    }}
                >
                    {(isEditing, _, formik) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'>{props.selectedRows[0].email}</div>
                            )
                        }
                        return (
                            <FormikInputText name="email" id="email" />
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <FormGroup>
                <Label for="organisation_staff_phone_number" className="text-muted mb-0 d-block">
                    <Translate id='form.fields.phone' />
                </Label>
                <FormikEditable
                    id="organisation_staff_phone_number"
                    readOnly={!canEdit}
                    initialValues={{
                        phone: props.selectedRows[0].phone?.split('#')[0] || '',
                        phoneExtension: props.selectedRows[0].phone?.split('#')[1] || '',
                    }}
                    onSubmit={async(values) => {
                        const newVal = {};
                        if(values.phone){
                            newVal.phone = await addExtension(values.phone, values.phoneExtension);
                        }

                        if(newVal.phone !== props.selectedRows[0].phone && !!newVal.phone){
                            props.toggleLoading(true);
                            const newValues = props.createNewValues(newVal);
                            return orgContext.updateOrganizationContactPartially(props.OrganizationContext.organisation_id, props.selectedRows[0].organisation_contact_id, newVal)
                                .then(() => {
                                    props.syncRows(newValues);
                                    success();
                                    props.toggleLoading(false);
                                })
                                .catch(handleCatch);
                        }
                    }}
                    validationSchema={object().shape({
                        phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />),
                        phoneExtension: string(),
                    })}
                >
                    {(isEditing, _, formik) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'>
                                    <DisplayPhoneNumber phoneString={props.selectedRows[0].phone} format='INTERNATIONAL' emptyValue='-' />
                                </div>
                            )
                        }
                        return (
                            <Row form>
                                <Col md="8">
                                    <Label for="phone" className="text-muted mb-0">
                                        <Translate id='organization.profile.staff.sidePanel.formik.label.number' />
                                    </Label>
                                    <FormikPhoneInput id='phone' name='phone' manualError />
                                </Col>
                                <Col md="4">
                                    <Label for="phoneExtension" className="text-muted mb-0"><Translate id='form.fields.extension' /></Label>
                                    <FormikInputNumber prefix='# ' id='phoneExtension' name='phoneExtension' allowNegative={false} decimalSeparator={false} />
                                </Col>
                                <FormikError name='phone' />
                            </Row>
                        )

                    }}
                </FormikEditable>
            </FormGroup>
            <FormGroup className="border-top pt-3">
                <div className="text-dark font-medium mb-2">
                    <Translate id='organization.profile.staff.show.on' />
                    <a className="ml-1 text-link" href={getPageReferrer(true)} target={'_blank'} rel="noreferrer">
                        <Translate id='organization.profile.staff.website' />
                        <i className="mdi mdi-launch ml-1" />
                    </a>
                </div>
                <FormikEditable
                    id="visible_on_website"
                    readOnly={!canEdit}
                    initialValues={{
                        visible_on_website: props.selectedRows[0].visible_on_website == 1 || false,
                        email_visible_on_website: props.selectedRows[0].email_visible_on_website == 1 || false,
                        phone_visible_on_website: props.selectedRows[0].phone_visible_on_website == 1 || false,
                    }}
                    onSubmit={(values) => {
                        const newValues = {
                            visible_on_website: values.visible_on_website ? '1' : '0',
                            // here we make sure if visible_on_website is false that we prevent the other values from being true
                            email_visible_on_website: !values.visible_on_website ? '0' : values.email_visible_on_website ? '1' : '0',
                            phone_visible_on_website: !values.visible_on_website ? '0' : values.phone_visible_on_website ? '1' : '0',
                        };

                        const valuesChanged = !(newValues.visible_on_website == props.selectedRows[0].visible_on_website && newValues.email_visible_on_website == props.selectedRows[0].email_visible_on_website && newValues.phone_visible_on_website == props.selectedRows[0].phone_visible_on_website)

                        if(valuesChanged){
                            props.toggleLoading(true);
                            return orgContext.updateOrganizationContactPartially(props.OrganizationContext.organisation_id, props.selectedRows[0].organisation_contact_id, newValues)
                                .then(() => {
                                    props.syncRows(props.createNewValues(newValues));
                                    success();
                                    props.toggleLoading(false);
                                })
                                .catch(handleCatch);
                        }
                    }}
                    validationSchema={object().shape({
                        visible_on_website: boolean(),
                        email_visible_on_website: boolean(),
                        phone_visible_on_website: boolean(),
                    })}
                >
                    {(isEditing, _, formik) => {
                        if(!isEditing){
                            return (
                                <div className='font-medium text-dark'>
                                    <div className="mb-1"><Translate id='form.fields.visible_on_website' />: {props.selectedRows[0].visible_on_website == 1 ? <i className="mdi mdi-check text-primary" /> : <i className="mdi mdi-close text-danger" />}</div>
                                    <div className="mb-1"><Translate id='form.fields.email_visible_on_website' />: {props.selectedRows[0].email_visible_on_website == 1 ? <i className="mdi mdi-check text-primary" /> : <i className="mdi mdi-close text-danger" />}</div>
                                    <div className="mb-1"><Translate id='form.fields.phone_visible_on_website' />: {props.selectedRows[0].phone_visible_on_website == 1 ? <i className="mdi mdi-check text-primary" /> : <i className="mdi mdi-close text-danger" />}</div>
                                </div>
                            )
                        }
                        return (
                            <div className='font-medium text-muted'>
                                <div className="d-flex mb-1">
                                    <Label for="visible_on_website" className="align-self-center text-muted mb-0">
                                        <Translate id='form.fields.visible_on_website' />
                                    </Label>
                                    <div className='ml-2'>
                                        <FormikSwitch
                                            id='visible_on_website'
                                            name="visible_on_website"
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mb-1">
                                    <Label for="email_visible_on_website" className="align-self-center text-muted mb-0">
                                        <Translate id='form.fields.email_visible_on_website' />
                                    </Label>
                                    <div className='ml-2'>
                                        <FormikSwitch
                                            id='email_visible_on_website'
                                            name="email_visible_on_website"
                                            disabled={!formik.values.visible_on_website}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mb-1">
                                    <Label for="phone_visible_on_website" className="align-self-center text-muted mb-0">
                                        <Translate id='form.fields.phone_visible_on_website' />
                                    </Label>
                                    <div className='ml-2'>
                                        <FormikSwitch
                                            id='phone_visible_on_website'
                                            name="phone_visible_on_website"
                                            disabled={!formik.values.visible_on_website}
                                        />
                                    </div>
                                </div>
                            </div>
                        )

                    }}
                </FormikEditable>
            </FormGroup>
        </div>
    )
}

export default SidePanelGeneral;