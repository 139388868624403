export default{
    "settings.general.title" : "Réglages généraux",

    "settings.general.tabs.regional"         : "Paramètres régionaux",
    "settings.general.tabs.merchant"         : "Comptes marchands",
    "settings.general.tabs.paymentMethods"   : "Méthodes de payement",
    "settings.general.tabs.paysafeOffer"     : "Offre Paysafe",
    "settings.general.tabs.sportPayOffer"    : "Offre SportsPay",
    "settings.general.tabs.taxSettings"      : "Paramètres de taxes",
    "settings.general.tabs.currencySettings" : "Paramètres de devise",
    "settings.general.tabs.regionalSettings" : "Paramètres d'inscription",
    "settings.general.tabs.travelPermits"    : "Paramètres de permis de voyage",
    "settings.general.tabs.paymentSettings"  : "Paramètres de paiement",

    "settings.general.periodHint.all"    : "Toutes les périodes",
    "settings.general.periodHint.select" : "Période: {periodName}",

    // Currency Settings
    "settings.general.tabs.currencySettings.iso"            : "Code ISO",
    "settings.general.tabs.currencySettings.symbol"         : "Symbole",
    "settings.general.tabs.currencySettings.canadianDollar" : "Dollar canadien",

}