import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody
} from "reactstrap";
import CardSectionTitle from '../../../../components/CardSectionTitle';
import EmptyLayout from '../../../../components/table/EmptyLayout';
import { I18nContext } from '../../../../contexts/I18nContext';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { displayI18n, DisplayI18n } from '../../../../helpers/i18nHelper';
import MemberTeamsSidepanel from './sidepanel/MemberTeamsSidepanel';
import { DisplayCategory, getTeamMemberStatus } from '../../../teams/TeamHelpers';
import MemberTeamStatus from './MemberTeamStatus';
import SpordlePanelTable from '../../../../components/sidePanel/SpordlePanel';
import { Tooltip } from "@mantine/core";

const MemberTeams = ({ memberId }) => {
    const organizationContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const membersContext = useContext(MembersContext);
    const i18nContext = useContext(I18nContext);

    return (
        <Card className="card-shadow">
            <CardBody>
                <CardSectionTitle title='members.profile.teams.title' />
                <SpordlePanelTable
                    allowOutsideClick
                    dataIndex="team_member_id"
                    sidePanel={(props) => <MemberTeamsSidepanel memberId={memberId} {...props} />}
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='teams'
                                tableHover bordered striped
                                dataIndex='team_member_id'
                                desktopWhen
                                ref={panelProps.spordleTableRef}
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                pagination={10}
                                rowIsHighlighted={(data) => data.checked}
                                emptyLayout={<EmptyLayout />}
                                defaultSorting='-season'
                                searchKeys={[
                                    'team.name',
                                    `team.period.i18n.${i18nContext.getGenericLocale()}.name`,
                                    'team.period.name',
                                    `position.i18n.${i18nContext.getGenericLocale()}.name`,
                                    'position.name',
                                    `team.team_category.division.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                    'team.team_category.division.short_name',
                                    `team.team_category.class.i18n.${i18nContext.getGenericLocale()}.short_name`,
                                    'team.team_category.class.short_name',
                                ]}
                                columnSorting={(teamA, teamB, _spordleTable, order) => {
                                    switch (order.column){
                                        case 'season':
                                            const valueASeason = displayI18n('name', teamA.team?.period?.i18n, teamA.team?.period?.name, i18nContext.getGenericLocale())
                                            const valueBSeason = displayI18n('name', teamB.team?.period?.i18n, teamB.team?.period?.name, i18nContext.getGenericLocale())
                                            return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueASeason, valueBSeason) * (order.order === 'ASC' ? 1 : -1);
                                        case 'teamName':
                                            const valueATeamName = displayI18n('name', teamA.team?.i18n, teamA.team?.name, i18nContext.getGenericLocale())
                                            const valueBTeamName = displayI18n('name', teamB.team?.i18n, teamB.team?.name, i18nContext.getGenericLocale())
                                            return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueATeamName, valueBTeamName) * (order.order === 'ASC' ? 1 : -1);
                                        case 'position':
                                            const valueAPosition = displayI18n('name', teamA.position?.i18n, teamA.position?.name, i18nContext.getGenericLocale())
                                            const valueBPosition = displayI18n('name', teamB.position?.i18n, teamB.position?.name, i18nContext.getGenericLocale())
                                            return new Intl.Collator(i18nContext.getGenericLocale(), { sensitivity: 'base' }).compare(valueAPosition, valueBPosition) * (order.order === 'ASC' ? 1 : -1);
                                    }
                                }}
                                loadData={(from, data, spordleTable) => {
                                    switch (from){
                                        case 'REFRESH':
                                            spordleTable.setLoading()
                                        case 'CDM':
                                            // Get org tree to see if user has access to given team org for link
                                            return Promise.all([
                                                organizationContext.getOrganizationsTree(),
                                                membersContext.getMemberTeams(memberId),
                                            ])
                                                .then(([ orgTree, memberTeams ]) => {
                                                    //Flatten
                                                    const orgTreeFlatten = orgTree.flattenArray('sub_organisations', (org) => ({
                                                        id: org?.organisation_id,
                                                    }))
                                                    return memberTeams.map((team) => ({
                                                        ...team,
                                                        hasAccess: organizationContext.organisation_id === identityRolesContext.federation?.organisation_id || organizationContext.organisation_id === team.team.organisation.organisation_id || orgTreeFlatten.some((org) => org.id == team.team?.organisation?.organisation_id),
                                                    }))
                                                })
                                        default:
                                            break;
                                    }
                                }}
                                columns={[
                                    {
                                        label: <Translate id='members.profile.teams.season' />,
                                        key: 'season',
                                        sortable: true,
                                        mobile: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.teams.teamName' />,
                                        key: 'teamName',
                                        sortable: true,
                                        mobile: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.teams.division' />,
                                        key: 'category',
                                        sortKey: 'team.division',
                                        sortable: true,
                                        mobile: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.teams.position' />,
                                        key: 'position',
                                        sortable: true,
                                        mobile: true,
                                    },
                                    {
                                        label: <Translate id='members.profile.teams.released' />,
                                        key: 'released',
                                        mobile: true,
                                    },
                                    {
                                        label: <Translate id='misc.status' />,
                                        key: 'status',
                                        mobile: true,
                                    },
                                ]}
                                renderRow={(columnKey, memberTeam) => {
                                    switch (columnKey){
                                        case 'season':
                                            return (
                                                <>
                                                    <DisplayI18n field='name' defaultValue={memberTeam.team.period.name} i18n={memberTeam.team.period.i18n} />
                                                    <div className='small'>
                                                        <Translate id='members.profile.teams.rostered' /> <DateFormat value={memberTeam.registration_date} />
                                                    </div>
                                                </>
                                            )
                                        case 'teamName':
                                            return (
                                                <>
                                                    <DisplayI18n field='name' defaultValue={memberTeam.team.name} i18n={memberTeam.team.i18n} />
                                                    <PrimaryTeam primaryTeam={!!memberTeam.member_primary_teams?.find((primaryTeam) => primaryTeam.active == 1)} />
                                                    {memberTeam.hasAccess &&
                                                            <div>
                                                                <Link to={`/teams/profile/${memberTeam.team.team_id}`}>
                                                                    <Translate id='misc.goToProfile' /><i className="mdi mdi-chevron-right ml-1" />
                                                                </Link>
                                                            </div>
                                                    }
                                                </>
                                            )
                                        case 'category':
                                            if(memberTeam.team.division){
                                                return memberTeam.team.division;
                                            }
                                            return <DisplayCategory category={memberTeam.team.team_category} short />;

                                        case 'position':
                                            if(memberTeam.position){
                                                return (
                                                    <DisplayI18n field='name' defaultValue={memberTeam.position.name} i18n={memberTeam.position.i18n} />
                                                )
                                            }
                                            return '-'

                                        case 'released':
                                            if(memberTeam.release_date){
                                                return <DateFormat value={memberTeam.release_date} />
                                            }
                                            return '-'

                                        case 'status':
                                            return (
                                                memberTeam.status || memberTeam.import == 1 || memberTeam.tryout == 1 ?
                                                    <>
                                                        {memberTeam.status &&
                                                                <div className="mb-2">
                                                                    {getTeamMemberStatus(memberTeam.status)}
                                                                </div>
                                                        }
                                                        {memberTeam.tryout == 1 &&
                                                                <MemberTeamStatus
                                                                    className="teamRoster-status mr-2"
                                                                    tip="teams.profile.roster.table.tryout"
                                                                >
                                                                    <Translate id='teams.profile.roster.table.tryout.short' />
                                                                </MemberTeamStatus>
                                                        }
                                                        {memberTeam.import == 1 &&
                                                                <MemberTeamStatus
                                                                    className="teamRoster-status mr-2"
                                                                    tip="teams.profile.roster.table.imported"
                                                                >
                                                                    <Translate id='teams.profile.roster.table.imported.short' />
                                                                </MemberTeamStatus>
                                                        }
                                                        {memberTeam.affiliate == 1 &&
                                                                <MemberTeamStatus
                                                                    className="teamRoster-status mr-2"
                                                                    tip="teams.profile.roster.table.affiliated"
                                                                >
                                                                    <Translate id='teams.profile.roster.table.affiliated.short' />
                                                                </MemberTeamStatus>
                                                        }
                                                    </>
                                                    : '-'
                                            )
                                        default:
                                            break;
                                    }
                                }}
                                onColumnClick={(e, team, _spordleTable) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            panelProps.onSingleSelectChange(team);
                                            break;
                                    }
                                }}
                            >
                                <div className='mb-2'>
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <SpordleTableProvider.SearchInput />

                                        <div className='d-flex ml-auto text-right'>
                                            <SpordleTableProvider.Refresh />
                                        </div>
                                    </div>
                                </div>
                                <SpordleTableView />
                            </SpordleTableProvider>
                        )
                    }}
                />
            </CardBody>
        </Card>
    );
}

export default MemberTeams;

const PrimaryTeam = ({ primaryTeam }) => {
    return (
        primaryTeam ?
            <Tooltip withArrow label={<Translate id='members.profile.teams.primaryTeam' />}>
                <i style={{ fontSize: 16 }} className='mdi mdi-star text-warning ml-1' />
            </Tooltip>

            :
            null
    )
}