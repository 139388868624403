import { FormikDateTime, FormikInputText } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Formik, Form } from "formik";
import moment from "moment";
import React, { useContext, useState } from "react";
import { Button, Col, Collapse, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { object, string, mixed } from "yup";
import { AxiosIsCancelled } from "../../api/CancellableAPI";
import { MembersContext } from "../../contexts/MembersContext";
import Required from "../formik/Required";

const SearchMemberForm = ({ isPublic, filterResults, setIsLoading, isLoading, searchParams = {}, memberComponent: MemberComponent, onCancel, onPrevious }) => {
    const [ results, setResults ] = useState(null);
    const { getPublicMembers, getMembers } = useContext(MembersContext);

    return (
        <Formik
            initialValues={{
                first_name: '',
                last_name: '',
                birthdate: '',
                unique_identifier: '',
            }}
            validationSchema={object().shape({
                first_name: string().test({
                    name: 'isRequired',
                    message: <Translate id='form.validation.firstName.required' />,
                    test: function(input){
                        return !!this.parent.unique_identifier || !!input;
                    },

                }),
                last_name: string().test({
                    name: 'isRequired',
                    message: <Translate id='form.validation.lastName.required' />,
                    test: function(input){
                        return !!this.parent.unique_identifier || !!input;
                    },
                }),
                birthdate: mixed().test({
                    name: 'isValid',
                    message: <Translate id='form.validation.dateOfBirth.valid' />,
                    test: function(date){
                        return !date || (moment.isMoment(date) && moment(date).isSameOrBefore(moment()));
                    },
                }),
                unique_identifier: string(),
            })}
            onSubmit={(values) => {
                setIsLoading?.(true);
                const apiValues = { ...searchParams };

                apiValues.all = 1;
                if(values.unique_identifier){
                    apiValues.unique_identifier = values.unique_identifier;
                }else{
                    apiValues.first_name = values.first_name;
                    apiValues.last_name = values.last_name;

                    if(values.birthdate && moment.isMoment(values.birthdate)){
                        apiValues.birthdate = values.birthdate.format('YYYY-MM-DD');
                    }
                }

                return (isPublic ? getPublicMembers : getMembers)(apiValues)
                    .then((members) => {
                        setResults(filterResults ? filterResults(members) : members);
                        setIsLoading?.(false);
                    })
                    .catch((e) => {
                        if(!AxiosIsCancelled(e.message)){
                            console.error(e);
                            setIsLoading?.(false);
                        }
                    });
            }}
        >
            {(formik) => (
                <Form id="searchMembers">
                    <ModalBody>
                        <Row form>
                            <Col className="form-group" sm="6">
                                <Label className="text-muted" for="first_name"><Translate id="form.fields.firstName" /> {!formik.values.unique_identifier && <Required />}</Label>
                                <FormikInputText disabled={!!formik.values.unique_identifier} name="first_name" />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label className="text-muted" for="last_name"><Translate id="form.fields.lastName" /> {!formik.values.unique_identifier && <Required />}</Label>
                                <FormikInputText disabled={!!formik.values.unique_identifier} name="last_name" />
                            </Col>
                            <Col className="form-group" sm="6">
                                <Label className="text-muted" for="birthdate"><Translate id="form.fields.dateOfBirth" /></Label>
                                <FormikDateTime
                                    id='birthdate'
                                    name='birthdate'
                                    timeFormat={false}
                                    dateFormat='YYYY-MM-DD'
                                    inputProps={{ disabled: !!formik.values.unique_identifier }}
                                    isValidDate={(current) => moment(current).isBefore(moment())}
                                />
                            </Col>
                            <Col sm="12">
                                <div className="d-flex align-items-center mb-3">
                                    <div className="border-top flex-grow-1" />
                                    <div className="px-3 font-medium small"><Translate id='members.search.memberSearch.filters.or' /></div>
                                    <div className="border-top flex-grow-1" />
                                </div>
                            </Col>
                            <Col sm="12">
                                <Label className="text-muted" for="unique_identifier"><Translate id='members.search.memberSearch.filters.memberNumber' /></Label>
                                <FormikInputText
                                    helper={
                                        <small className="text-muted">
                                            <i className="text-primary mdi mdi-information-outline" /> <Translate id='members.search.memberAdvanceSearch.memberNumber.ignoredFilters' />
                                        </small>
                                    }
                                    translateHelper={false}
                                    name="unique_identifier"
                                    trim
                                />
                            </Col>
                        </Row>
                        <Collapse className="mt-3" isOpen={results !== null}>
                            {results && results.length > 0 ?
                                <div className="border-top">
                                    <div className="text-muted my-2"><Translate id='misc.results' /></div>
                                    <div style={{ maxHeight: "380px", overflow: "auto" }}>
                                        {results?.map((member) => (
                                            <React.Fragment key={member.member_id + formik.submitCount}>
                                                <MemberComponent member={member} />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                                :
                                <div className="text-center">
                                    <i className="d-block mdi mdi-account-search fa-3x line-height-1" />
                                    <div className="font-bold">
                                        <Translate id='misc.search.empty.title' />
                                    </div>
                                    <div className="small text-muted">
                                        <Translate id='officialSupervision.addSupervision.searchMember.noResults' />
                                    </div>
                                </div>
                            }
                        </Collapse>
                    </ModalBody>
                    <ModalFooter>
                        {onPrevious &&
                            <Button onClick={onPrevious} className="w-50 w-md-auto mr-auto" disabled={isLoading || formik.isSubmitting} color="primary" type="button" outline>
                                <Translate id="misc.previous" />
                            </Button>
                        }
                        <Button className="w-50 w-md-auto" disabled={isLoading || formik.isSubmitting} color="primary" type="submit">
                            <Translate id="misc.search" />
                        </Button>
                        {onCancel &&
                            <Button className="w-50 w-md-auto" disabled={isLoading || formik.isSubmitting} color="primary" type="button" outline onClick={onCancel}>
                                <Translate id="misc.cancel" />
                            </Button>
                        }
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

export default SearchMemberForm;