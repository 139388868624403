import { FormikDateTime } from "@spordle/formik-elements"
import Translate from "@spordle/intl-elements"
import { useFormikContext } from "formik"
import { Col, FormGroup, Row } from "reactstrap"
import { filterHasValue, formikCodesHaveValues } from "../reportsEngineHelpers"
import FilterLabel from "./FilterLabel"

const EngineDateBetween = ({ filter }) => {
    const formik = useFormikContext();

    return (
        <FormGroup>
            <Row form>
                {/* divs are so d-flex don't affect the error placement */}
                <Col xs="6">
                    <FilterLabel skipTip suffix={<> - <Translate id="organization.settings.sidePanelOrganizationPeriods.dates.start" /></>} filter={filter} />
                    <FormikDateTime
                        {...filter.parsedParams?.componentProps}
                        id={`start_${filter.code}`}
                        name={`start_${filter.code}`}
                        placeholder='reports.engine.date.placeholder'
                        translatePlaceholder
                        value={formik.values[`start_${filter.code}`]}
                        timeFormat={false}
                        inputProps={{ disabled: !formikCodesHaveValues(filter.parsedParams?.enabledWhen, formik) || !filterHasValue(filter.parsedParams?.enabledWhenValue?.filter, filter.parsedParams?.enabledWhenValue?.value, formik) }}
                        className={`w-100 ${filter.parsedParams?.componentProps?.className}`}
                    />
                </Col>
                <Col xs="6">
                    <FilterLabel suffix={<> - <Translate id="organization.settings.sidePanelOrganizationPeriods.dates.end" /></>} filter={filter} />
                    <FormikDateTime
                        {...filter.parsedParams?.componentProps}
                        id={`end_${filter.code}`}
                        name={`end_${filter.code}`}
                        placeholder='reports.engine.date.placeholder'
                        translatePlaceholder
                        value={formik.values[`end_${filter.code}`]}
                        timeFormat={false}
                        inputProps={{ disabled: !formikCodesHaveValues(filter.parsedParams?.enabledWhen, formik) || !filterHasValue(filter.parsedParams?.enabledWhenValue?.filter, filter.parsedParams?.enabledWhenValue?.value, formik) }}
                        className={`w-100 ${filter.parsedParams?.componentProps?.className}`}
                    />
                </Col>
            </Row>
        </FormGroup>
    )
}

export default EngineDateBetween