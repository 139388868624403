import Translate from "@spordle/intl-elements";
import { FormattedPlural } from "react-intl";
import { useContext } from "react";
import { Col } from "reactstrap";
import MemberOverviewMemberType from "./MemberOverviewMemberType";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { displayI18n } from "../../../../../../helpers/i18nHelper";
import { useMemberTypeRemovalRequestsSWR } from "../../../../../../api/useSWRHelpers/memberTypeRemovalRequestsSWR";
import { getMemberTypeKey } from "../../../../../../helpers/memberTypeHelper";

const MemberOverviewMemberTypeList = ({ refreshMemberProfile }) => {
    const { currentMember } = useContext(MembersContext);
    const { getGenericLocale } = useContext(I18nContext);

    const { data: memberTypeRemovalRequests } = useMemberTypeRemovalRequestsSWR(currentMember.member_id)

    // Sorted because: if a player has member types from one org and one from another,
    // the types from the same org would appear next to each other
    const memberTypes = (currentMember.member_types || [])
        .sort((a, b) => {
            const orgA = a.organisation;
            const orgB = b.organisation;

            if(orgA && orgB){
                const orgNameA = displayI18n('name', orgA.i18n, orgA.organisation_name, getGenericLocale()) || "";
                const orgNameB = displayI18n('name', orgB.i18n, orgB.organisation_name, getGenericLocale()) || "";

                return orgNameA.localeCompare(orgNameB);
            }

            return 0;
        });

    const currentSeasonTypes = memberTypes.filter((m) => m.current_season == 1);
    const lastSeasonTypes = memberTypes.filter((m) => m.last_season == 1);

    const shownMemberTypes = currentSeasonTypes.length > 0 ? currentSeasonTypes : lastSeasonTypes;
    const isLastPeriod = currentSeasonTypes.length <= 0 && lastSeasonTypes.length > 0;

    if(shownMemberTypes.length > 0){
        return (
            <Col sm={6} className="mb-2">
                <div className='text-muted'>
                    <FormattedPlural
                        value={shownMemberTypes.length}
                        one={<Translate id='members.profile.memberProfile.header.memberType' />}
                        other={<Translate id='members.profile.memberProfile.header.memberTypes' />}
                    />
                    {isLastPeriod && <small className='ml-1'>(<Translate id='members.profile.memberProfile.header.positions.lastPeriod' />)</small> }
                </div>
                {shownMemberTypes.length > 0 &&
                    <ul className="list-unstyled no-style font-medium pl-0 mb-0">
                        {shownMemberTypes.map((memberType) => {
                            const hasPendingRemovalRequest = memberTypeRemovalRequests?.find((request) => getMemberTypeKey(request) === getMemberTypeKey(memberType))
                            return (
                                <li key={memberType.member_type.member_type_id + memberType.organisation?.organisation_id} className="d-flex align-items-start">
                                    <MemberOverviewMemberType
                                        memberType={memberType}
                                        refreshMemberProfile={refreshMemberProfile}
                                        hasPendingRemovalRequest={hasPendingRemovalRequest}
                                        shownMemberTypes={shownMemberTypes}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                }
            </Col>
        );
    }

    return null;

}

export default MemberOverviewMemberTypeList;