import { stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { useState } from 'react';
import { Card, Collapse } from 'reactstrap';
import ClinicCategoryBuilder from '../../../ClinicCategoryBuilder';

const PrerequisitesSetCard = ({ title, requirements, restriction, ...props }) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const toggle = () => setIsOpen(!isOpen)

    return (
        <Card className='border card-shadow mb-1'>
            <div className='clickable px-3 py-2' onClick={toggle}>
                <div className='d-flex justify-content-between align-items-center'>
                    <span className='font-bold text-dark'>{title}</span>
                    <div className='d-flex align-items-center ml-auto'>
                        <span>
                            <Translate id='clinics.clinicInformation.clinicInformation.qualification.prerequisites.number' values={{ number: requirements.length }} />
                            <span className='mx-1'>-</span>
                            <Translate id={'clinics.clinicInformation.clinicInformation.qualification.clinicPrerequisites.' + restriction} />
                        </span>
                        <i className={stringBuilder('ml-3 transition mdi mdi-chevron-right font-20', { 'rotate-90': isOpen })} />
                    </div>
                </div>
            </div>

            <Collapse isOpen={isOpen}>
                <div className='px-3 py-2'>
                    {requirements.map((qualification) => (
                        <div key={qualification.qualification_id}>
                            <ClinicCategoryBuilder qualification={qualification} />
                        </div>
                    ))}
                </div>
            </Collapse>
        </Card>
    )
}

export default PrerequisitesSetCard