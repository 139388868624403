export default{
    // waiting list
    "components.manualRegistration.waitingList.title"                                 : "Le stage est actuellement plein.",
    "components.manualRegistration.waitingList.text"                                  : "Cliquez sur {confirm} pour inscrire le membre à la liste d'attente.",
    // waiting list confirmation
    "components.manualRegistration.waitingListConfirmation.number"                    : "Numéro de liste d'attente",
    "components.manualRegistration.waitingListConfirmation.goToWaitlist"              : "Voir la liste d'attente",
    // waiting list return
    "components.manualRegistration.waitingListReturn.title"                           : "Complétez l'inscription",
    "components.manualRegistration.waitingListReturn.text"                            : "Complétez l'inscription pour {firstName} {lastName}.",
    "components.manualRegistration.waitingListReturn.manual"                          : "Inscription manuelle",
    "components.manualRegistration.waitingListReturn.manual.desc"                     : "Complétez l'inscription manuellement pour ce membre.",
    "components.manualRegistration.waitingListReturn.identity"                        : "Sélectionner une identité",
    "components.manualRegistration.waitingListReturn.identity.desc"                   : "Envoyer un courriel à un utilisateur pour compléter l'inscription.",
    // waiting list return confirmation
    "components.manualRegistration.waitingListReturnConfirmation.title"               : "Vous avez presque terminé!",
    "components.manualRegistration.waitingListReturnConfirmation.text"                : "Cliquez sur {confirm} pour envoyer un courriel à {name}",
    "components.manualRegistration.waitingListReturnConfirmation.confirmation"        : "Félicitations!",
    "components.manualRegistration.waitingListReturnConfirmation.confirmationMessage" : "Le courriel a été envoyé. Le membre est dorénavant en attente du client pour que ce dernier complète l'inscription.",
}