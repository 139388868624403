import { FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { Alert, Button, Collapse, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { object, string } from "yup";
import AnalyticsModal from "../../../../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../../../../components/loading/OverlayLoader";
import { success } from '@spordle/toasts';
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";
import ChequeModalForm from "./form/ChequeModalForm";

const EditChequeModal = (props) => {
    return (
        <AnalyticsModal analyticsName="InsuranceEditChequeModal" isOpen={props.isOpen}>
            {props.isPrinted ?
                <EditPrintedCheque {...props} />
                :
                <EditNotPrintedCheque {...props} />
            }
        </AnalyticsModal>
    );
}

const EditPrintedCheque = ({ toggle, cheque, updateCheque }) => {
    return (
        <Formik
            initialStatus={{
                isOpen: false,
                msg: null,
            }}
            initialValues={{
                memo: cheque.memo || '',
            }}
            validationSchema={object().shape({
                memo: string(),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                if(values.memo === cheque.memo){
                    toggle();
                }else{
                    updateCheque(values)
                        .then(() => {
                            success({ info: 'insurance.sidePanel.cheque.editModal.success.info' });
                            toggle();
                        })
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                setStatus({
                                    isOpen: true,
                                    msg:
                                    <>
                                        <div className="font-bold"><Translate id="misc.error" /></div>
                                        <DisplayI18n field="message" defaultValue={e.message} i18n={e.i18n} />
                                    </>,
                                })
                                setSubmitting(false);
                            }
                        })
                }
            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={formik.isSubmitting}>
                    <Form>
                        <ModalHeader toggle={toggle}>Edit Cheque</ModalHeader>
                        <ModalBody>
                            <Label for="memo"><Translate id="form.fields.memo" /></Label>
                            <FormikTextArea
                                trim
                                name="memo"
                                id="edit-memo"
                            />
                            {!!formik.status.msg &&
                                <Collapse isOpen={formik.status.isOpen}>
                                    <Alert
                                        toggle={() => formik.setStatus({ ...formik.status, isOpen: false })}
                                        color="danger"
                                        className="mt-3"
                                    >
                                        {formik.status.msg}
                                    </Alert>
                                </Collapse>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button disabled={formik.isSubmitting} type="submit" color="primary">
                                <Translate id="misc.submit" />
                            </Button>
                            <Button disabled={formik.isSubmitting} type="button" onClick={toggle} color="primary" outline>
                                <Translate id="misc.cancel" />
                            </Button>
                        </ModalFooter>
                    </Form>
                </OverlayLoader>
            )}
        </Formik>
    )
}

const EditNotPrintedCheque = (props) => {
    const {
        cheque,
        updateCheque,
        toggle,
    } = props;

    const initValues = {
        amount: cheque.amount,
        service_type_id: cheque.service_type?.service_type_id || "",
        pay_to: cheque.pay_to || "",
        memo: cheque.memo || "",
        payee_id: cheque.payee?.payee_id || "",
        member_contact_id: cheque.member_contact?.member_contact_id || "",
        member_address_id: cheque.member_address?.member_address_id || "",
    };

    const formikInit = {
        ...initValues,
        amount: parseInt(initValues.amount) / 100,
    };

    const getDifferentValues = (newValues) => {
        const initIndexes = Object.keys(initValues);
        const apiValues = {};

        for(let i = 0; i < initIndexes.length; i++){
            const theIndex = initIndexes[i];
            const theValue = initValues[theIndex];


            if(newValues[theIndex] != theValue){
                apiValues[theIndex] = newValues[theIndex];
            }
        }

        return apiValues;
    }

    const handleOnSubmit = async(values, { setStatus }) => {

        const diffValues = getDifferentValues(values);

        if(Object.keys(diffValues).length > 0){
            return updateCheque(diffValues)
                .then(() => {
                    toggle();
                    success({ info: 'insurance.sidePanel.cheque.editModal.success.info' });
                })
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e);
                        setStatus({ error: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, isOpen: true });
                    }
                });
        }
        return toggle();

    }

    return (
        <ChequeModalForm
            title={<Translate id='insurance.sidePanel.cheque.editModal.title' />}
            initValues={formikInit}
            onSubmit={handleOnSubmit}
            {...props}
        />
    );
}

export default EditChequeModal;