import { useContext, useEffect } from 'react';
import { Badge, Button } from 'reactstrap';
import { QuickViewContext } from '../../../contexts/QuickViewContext';
import { AppContext } from '../../../contexts/contexts';
import Translate from '@spordle/intl-elements';
import { lockBodyScroll, unlockBodyScroll } from '../../../helpers/uiHelper';
import { TourManagerContext } from '../../../components/tour/TourManager';
import { QUICK_TOUR_NAME } from '../../../components/quickView/QuickViewButton';
import { Tooltip } from '@mantine/core';
import * as Sentry from "@sentry/react";

const QuickViewNav = () => {
    const quickViewContext = useContext(QuickViewContext);
    const hasQuickViews = Array.isArray(quickViewContext.quickViewTabs) && quickViewContext.quickViewTabs.length > 0;

    return (!quickViewContext.quickViewTabs || !hasQuickViews) ? null : <QuickViewNavBtn />;
}

const BTN_TOUR_NAME = "quickViewToggle";

const QuickViewNavBtn = () => {
    const quickViewContext = useContext(QuickViewContext);
    const { triggerTour } = useContext(TourManagerContext);
    const { getFrontEndParams } = useContext(AppContext);

    const openQuickView = () => {
        Sentry.addBreadcrumb({
            type: 'info',
            message: 'Quickview opened from header',
            level: Sentry.Severity.Log,
            category: 'QuickView',
        })
        quickViewContext.toggle(true);
    }

    useEffect(() => {
        const theTours = getFrontEndParams("tours");

        if(theTours[BTN_TOUR_NAME] == 1 && !quickViewContext.isOpen && theTours[QUICK_TOUR_NAME] == 0){
            triggerTour({
                name: BTN_TOUR_NAME,
                afterOpen: lockBodyScroll,
                beforeClose: unlockBodyScroll,
                steps: [
                    {
                        selector: `#${BTN_TOUR_NAME}`,
                        content: ({ setIsOpen }) => (
                            <div>
                                <div className='h3 font-bold'><Translate id="tour.step.quickViewToggler.title" /></div>
                                <div className='h5 mb-3'><Translate id="tour.step.quickViewToggler.message" /></div>
                                <Button
                                    onClick={() => setIsOpen(false)}
                                    type="button"
                                    color="primary"
                                >
                                    <Translate id="tour.step.gotIt.btn" />
                                </Button>
                            </div>
                        ),
                    },
                ],
            })
        }
    }, [ quickViewContext.isOpen ]);

    return (
        <li id={BTN_TOUR_NAME} className="dropdown nav-item">
            <div id="quick-view-header-toggle" className="position-relative nav-link clickable" onClick={openQuickView}>
                <div>
                    <span className='small font-medium'>
                        <Tooltip
                            withArrow
                            label={<Translate id='misc.quickview' />}
                            zIndex={3000}
                        >
                            <i className="mdi mdi-loupe font-18" />
                        </Tooltip>
                    </span>
                    {(Array.isArray(quickViewContext.quickViewTabs) && quickViewContext.quickViewTabs.length > 0) &&
                        <Badge
                            id="quick-view-header-toggle-badge"
                            className="position-absolute top-0 right-0 font-normal rounded-pill"
                            color={"federation"}
                            style={{ fontSize: "0.55rem" }}
                            pill
                        >
                            {quickViewContext.quickViewTabs.length}
                        </Badge>
                    }
                </div>
            </div>
        </li>
    );
}

export default QuickViewNav;