export default{
    "members.profile.waiver.tab" : "Dispenses",

    "members.profile.waiver.table.empty"            : "Aucune dispense effectuée pour ce membre pour la période en cours",
    "members.profile.waiver.table.column.invoice"   : "Facture",
    "members.profile.waiver.table.column.reference" : "Référence",
    "members.profile.waiver.table.column.waiver"    : "Dispense",
    "members.profile.waiver.table.column.season"    : "Saison",
    "members.profile.waiver.table.column.signed"    : "Signée",

    "members.profile.waiver.table.filter.onlyCritical" : "Seulement afficher les dispenses critiques",

    "members.profile.waiver.signed.by"                : "Signée par",
    "members.profile.waiver.signed.by.YES"            : "Acceptée par",
    "members.profile.waiver.signed.by.NO"             : "Refusée par",
    "members.profile.waiver.signed.not"               : "Pas signée",
    "members.profile.waiver.sidePanel.sign.label"     : "Je suis d'accord avec cette dispense",
    "members.profile.waiver.signed.apiPartner"        : "Partenaire API",
    "members.profile.waiver.signed.confirmationLabel" : "Étiquette de confirmation: ",
    "members.profile.waiver.signed.declineLabel"      : "Étiquette de déclin: ",

    "members.profile.waiver.csv.header.organisation"          : "Organisation",
    "members.profile.waiver.csv.header.signedOn"              : "Signé le",
    "members.profile.waiver.csv.header.answer.YES"            : "Oui",
    "members.profile.waiver.csv.header.answer.NO"             : "Non",
    "members.profile.waiver.csv.header.answer.NOT_SIGNED_YET" : "Pas signée",
}