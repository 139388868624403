import React from "react";
import {
    Container
} from 'reactstrap';
import TabRoutesNavs from "../../../components/subSidebar/TabRoutesNavs";
import TabRoutesPanes from "../../../components/subSidebar/TabRoutesPanes";
import settingsPlatfomTabsRoutes from "../../../routes/tabRoutes/settingsPlatfomTabsRoutes";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";

class PlatformSettings extends React.Component{

    render(){
        return (
            <>
                {/* Settings View Header */}
                <ViewHeaderV2 title='settings.title.platformSettings' />

                <div className="px-4">
                    <Container>

                        {/*--------------------------------------------------------------------------------*/}
                        {/* Tabs                                                                  */}
                        {/*--------------------------------------------------------------------------------*/}
                        <TabRoutesNavs routes={settingsPlatfomTabsRoutes} />
                        <TabRoutesPanes routes={settingsPlatfomTabsRoutes} />
                    </Container>
                </div>
            </>
        );
    }
}

export default PlatformSettings;