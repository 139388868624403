import { FormikDateTime, FormikInputText } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import moment from "moment";
import { useContext, useState } from "react";
import { Button, Col, Collapse, Fade, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { mixed, string, object } from "yup";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Required from "../../../../../../components/formik/Required";
import BtnDisplay from "../../../../../../components/input/BtnDisplay";
import { fail } from "@spordle/toasts";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";

const FormClinicSearchContact = ({ isOpen, toggle, toggleSearch, selectMember, isInstructor }) => {
    const [ results, setResults ] = useState(null);
    const [ name, setName ] = useState({
        firstName: '',
        lastName: '',
    });
    const { getMembers } = useContext(MembersContext);

    const handleSelect = (member) => {
        selectMember({
            firstName: member.first_name,
            first_name: member.first_name,
            lastName: member.last_name,
            last_name: member.last_name,
            email: member.email,
            unique_identifier: member.unique_identifier,
            age: member.age,
            birthdate: member.birthdate,
            member_id: member.member_id,
        })
    }

    return (
        <Formik
            initialValues={{
                //contact
                first_name: '',
                last_name: '',
                dob: '',
                unique_identifier: '',
            }}
            validationSchema={object().shape({
                first_name: string().when('unique_identifier', {
                    is: undefined,
                    then: string().required(<Translate id='form.validation.firstName.required' />),
                }),
                last_name: string().when('unique_identifier', {
                    is: undefined,
                    then: string().required(<Translate id='form.validation.lastName.required' />),
                }),
                unique_identifier: string(),
                dob: mixed().when('unique_identifier', {
                    is: undefined,
                    then: mixed().test({
                        name: 'Date of birth',
                        message: <Translate id='form.validation.date.format' />,
                        test: function(date){
                            return !date || !!this.parent.unique_identifier || moment.isMoment(date);
                        },
                    }),
                }),
            })}
            onSubmit={async(values) => {
                let apiVal = {};

                if(values.unique_identifier){
                    apiVal.unique_identifier = values.unique_identifier;
                }else{
                    apiVal = { ...values };
                    if(values.dob && moment.isMoment(values.dob)){
                        apiVal.birthdate = values.dob.clone().format('YYYY-MM-DD')
                    }
                    delete apiVal.dob;
                }

                apiVal.all = 1; // Search all member under the current organization

                return getMembers(apiVal)
                    .then((members) => {
                        setResults(members);
                        if(values.unique_identifier){
                            setName({
                                firstName: '',
                                lastName: '',
                            });
                        }else{
                            setName({
                                firstName: values.first_name,
                                lastName: values.last_name,
                            });
                        }
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setResults([]);
                        }
                    })
            }}
        >
            {(formik) => (
                <Collapse
                    isOpen={isOpen}
                    onExited={() => {
                        setResults(null);
                        setName({
                            firstName: '',
                            lastName: '',
                        });
                        formik.resetForm();
                    }}
                >
                    <Fade in={isOpen}>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <Form>
                                <ModalHeader toggle={toggle}>
                                    <Translate id="misc.search" />
                                </ModalHeader>
                                <ModalBody>
                                    <Row form>
                                        <Col sm="6" className="form-group">
                                            <Label for="first_name" className="text-muted"><Translate id='form.fields.firstName' /> {!formik.values.unique_identifier && <Required />}</Label>
                                            <FormikInputText disabled={!!formik.values.unique_identifier} name="first_name" id="first_name" trim autoComplete='given-name' />
                                        </Col>
                                        <Col sm="6" className="form-group">
                                            <Label for="last_name" className="text-muted"><Translate id='form.fields.lastName' /> {!formik.values.unique_identifier && <Required />}</Label>
                                            <FormikInputText disabled={!!formik.values.unique_identifier} name="last_name" id="last_name" trim autoComplete='family-name' />
                                        </Col>
                                        <Col sm="6" className="form-group">
                                            <Label for="dob" className="text-muted"><Translate id='form.fields.dateOfBirth' /></Label>
                                            <FormikDateTime
                                                id='dob'
                                                name='dob'
                                                timeFormat={false}
                                                dateFormat='YYYY-MM-DD'
                                                inputProps={{ disabled: !!formik.values.unique_identifier, id: 'dob' }}
                                                isValidDate={(current) => moment(current).isBefore(new Date())}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="border-top flex-grow-1" />
                                        <div className="px-3 font-medium small">
                                            <Translate id='members.profile.overview.memberFamilyRelations.add.or' />
                                        </div>
                                        <div className="border-top flex-grow-1" />
                                    </div>
                                    <FormGroup>
                                        <Label for="unique_identifier" className="text-muted"><Translate id='members.profile.overview.memberFamilyRelations.add.memberNumber' /></Label>
                                        <FormikInputText
                                            trim
                                            name="unique_identifier"
                                            id="unique_identifier"
                                            helper={
                                                <div className='small'>
                                                    <i className='text-primary mdi mdi-information-outline' /> <Translate id='members.profile.overview.memberFamilyRelations.add.memberNumber.hint' />
                                                </div>
                                            }
                                        />
                                    </FormGroup>
                                    <Collapse className="mt-3" isOpen={results !== null}>
                                        {results && results.length > 0 ?
                                            <div className="border-top">
                                                <div className="text-muted my-2"><Translate id='misc.results' /></div>
                                                <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: "380px" }} className='px-3 mx-n3'>
                                                    {results?.map((member) => (
                                                        <SearchContactCard key={member.member_id} handleSelect={handleSelect} member={member} />
                                                    ))}
                                                    {name.firstName && name.lastName &&
                                                        <BtnDisplay
                                                            icon='addMember'
                                                            svgProps={{ viewBox: "0 0 50 50" }}
                                                            title={<Translate id={isInstructor ? 'clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.new.title' : 'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.new.title'} />}
                                                            subtitle={(
                                                                <Translate
                                                                    id={isInstructor ? 'clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.new.description' : 'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.new.description'}
                                                                    values={{ lastName: name.lastName, firstName: name.firstName }}
                                                                />
                                                            )}
                                                            onClick={() => { handleSelect({ last_name: name.lastName, first_name: name.firstName }) }}
                                                        />
                                                    }
                                                </PerfectScrollbar>
                                            </div>
                                            :
                                            <div className="text-center">
                                                <Translate id='misc.search.empty.title' />
                                                {name.firstName && name.lastName &&
                                                    <BtnDisplay
                                                        icon='addMember'
                                                        svgProps={{ viewBox: "0 0 50 50" }}
                                                        className='mt-2'
                                                        title={<Translate id={isInstructor ? 'clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.new.title' : 'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.new.title'} />}
                                                        subtitle={(
                                                            <Translate
                                                                id={isInstructor ? 'clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.new.description' : 'clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.new.description'}
                                                                values={{ lastName: name.lastName, firstName: name.firstName }}
                                                            />
                                                        )}
                                                        onClick={() => { handleSelect({ last_name: name.lastName, first_name: name.firstName }) }}
                                                    />
                                                }
                                            </div>
                                        }
                                    </Collapse>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" type="submit" disabled={formik.isSubmitting}><Translate id='misc.search' /></Button>
                                </ModalFooter>
                            </Form>
                        </OverlayLoader>
                    </Fade>
                </Collapse>
            )}
        </Formik>
    )
}

const SearchContactCard = ({ member, handleSelect }) => (
    <UserDisplay className="mb-2 w-100" card>
        <UserDisplay.Container>
            <UserImg
                width={40}
                abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                alt={member.first_name + ' ' + member.last_name}
                src={member.picture?.full_path}
                filePos={member.picture?.file_position}
            />
        </UserDisplay.Container>
        <UserDisplay.Container>
            <UserDisplay.Title>{member.first_name + ' ' + member.last_name}</UserDisplay.Title>
            <UserDisplay.Subtitle>#{member.unique_identifier}</UserDisplay.Subtitle>
            <UserDisplay.Subtitle>{member.birthdate} (<Translate id='misc.yearsOld' values={{ age: member.age }} />)</UserDisplay.Subtitle>
        </UserDisplay.Container>
        <UserDisplay.Container className="ml-auto">
            <Button onClick={() => handleSelect(member)} color="primary" type="button">
                <Translate id='misc.select' />
            </Button>
        </UserDisplay.Container>
    </UserDisplay>
)

export default FormClinicSearchContact;