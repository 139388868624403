import React from 'react';
import {
    Container,
    Button
} from "reactstrap";
import { withRouter } from 'react-router-dom';

import ClinicInformation from '../../components/clinicInformation/ClinicInformation';
import ClinicSessionInformation from '../../components/clinicSessionInformation/ClinicSessionInformation';
import ClinicCommunications from '../../components/clinicCommunications/ClinicCommunications';
import ClinicPaymentOptions from '../../components/clinicPaymentOptions/ClinicPaymentOptions';
import moment from 'moment';

import AlmostDoneImage from '../../../../assets/images/AlmostDone_Image.png'
import DoneImage from '../../../../assets/images/Done_Image.png'

// contexts
import { ClinicsContext } from '../../../../contexts/ClinicsContext';
import withContexts from '../../../../helpers/withContexts';
import Translate from '@spordle/intl-elements';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { fail } from "@spordle/toasts";
import { DisplayI18n } from '../../../../helpers/i18nHelper';
class CreateClinicReview extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            editStep1: false,
            editStep2: false,
            editStep3: false,
            editStep4: false,

            isEditing: false,
        }

    }

    toggleEditStep1 = () => { this.setState((prevState) => ({ editStep1: !prevState.editStep1 }), this.isEditing()); }

    toggleEditStep2 = () => { this.setState((prevState) => ({ editStep2: !prevState.editStep2 }), this.isEditing()); }

    toggleEditStep3 = () => { this.setState((prevState) => ({ editStep3: !prevState.editStep3 }), this.isEditing()); }

    toggleEditStep4 = () => { this.setState((prevState) => ({ editStep4: !prevState.editStep4 }), this.isEditing()); }

    isEditing = () => {
        this.setState((prevState) => ({ isEditing: prevState.editStep1 || prevState.editStep2 || prevState.editStep3 || prevState.editStep4 }))
    }

    createClinic = () => {
        this.props.ClinicsContext.createStatus(this.props.ClinicsContext.currentClinic?.clinic_id, { status: 'DRAFT', startDate: moment().toISOString() })
            .then(() => {
                this.props.history.push({
                    pathname: "/clinics/profile/" + this.props.ClinicsContext.currentClinic.clinic_id,
                    data: this.props.ClinicsContext.currentClinic,
                })
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    render(){
        return (
            <>
                <div className="text-center mx-auto mb-5" style={{ maxWidth: 500 }}>
                    <img className="mb-3" src={AlmostDoneImage} alt="Form not confirmed" width="250" />
                    <div className="h4 div font-medium"><Translate id='clinic.createClinic.createClinicReview.topText.title' /></div>
                    <p className="text-muted"><Translate id='clinic.createClinic.createClinicReview.topText.text' /></p>
                </div>

                <Container>
                    {/* <Button color='primary' onClick={() => {this.props.ClinicsContext.getAllClinicInfos('1eb72aec-5a2d-6c5c-892f-06bf14840f98', {sessions: true, contacts: true, instructors: true, memberTypes: true, merchantAccounts: true})}}>get</Button> */}
                    <ClinicInformation isHost toggleEdit={this.toggleEditStep1} moodleCourses={this.props.moodleCourses} />
                    <ClinicSessionInformation isHost toggleEdit={this.toggleEditStep2} clinicId={this.props.ClinicsContext.currentClinic.clinic_id} />
                    <ClinicCommunications isHost toggleEdit={this.toggleEditStep3} />
                    <ClinicPaymentOptions isHost toggleEdit={this.toggleEditStep4} />

                    <div className="text-center mx-auto mb-5" style={{ maxWidth: 500 }}>
                        <img className="mb-3" src={DoneImage} alt="Form confirmed" width="250" />
                        <div className="mb-2"><Button color="primary" disabled={this.state.isEditing} onClick={this.createClinic}><Translate id='clinic.createClinic.createClinicReview.bottomText.create' /></Button></div>
                        <p className="text-muted"><Translate id='clinic.createClinic.createClinicReview.bottomText.text' /></p>
                    </div>
                </Container>
            </>
        );
    }
}

export default withRouter(withContexts(ClinicsContext)(CreateClinicReview));