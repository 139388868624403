import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import withContexts from '../helpers/withContexts';
import { OrganizationContext } from './OrganizationContext';

/** @type {React.Context<Omit<TermsConditionsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const TermsConditionsContext = createContext();
TermsConditionsContext.displayName = 'TermsConditionsContext';

class TermsConditionsContextProvider extends React.Component{

    /**
     * Gets all terms and conditions
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Terms%20and%20Conditions/Apicontroller%5CPages%5CTermsandconditions%3A%3AgetAllTermsAndConditions|documentation}
     * @returns {Promise.<Array>}
     */
    getTermsConditions = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/terms-and-conditions`,
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.terms_and_conditions
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get specific terms and conditions
     * @param {string} term_and_condition_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Terms%20and%20Conditions/Apicontroller%5CPages%5CTermsandconditions%3A%3AgetSpecificTermsAndConditions|documentation}
     * @returns {Promise.<Array>}
     */
    getTermsCondition = (term_and_condition_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/terms-and-conditions/${term_and_condition_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.terms_and_conditions[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create a terms and conditions
     * @param {object} [values] The values for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Terms%20and%20Conditions/Apicontroller%5CPages%5CTermsandconditions%3A%3AcreateTermAndCondition|documentation}
     * @returns {Promise.<string>}
     */
    createTermsCondition = (values) => {
        const data = new URLSearchParams({
            organisation_id: this.props.OrganizationContext.organisation_id,
        })

        for(const key in values){
            switch (key){
                case 'title':
                    data.append('title', values.title);
                    break;
                case 'description':
                    data.append('description', values.description);
                    break;
                case 'active':
                    data.append('active', !!values.active >>> 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;
            }
        }
        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/terms-and-conditions`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return response.data.term_and_condition_id;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Update a terms and conditions
     * @param {string} term_and_condition_id
     * @param {object} [values] The values for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Terms%20and%20Conditions/Apicontroller%5CPages%5CTermsandconditions%3A%3AupdateTermAndCondition|documentation}
     * @returns {Promise.<boolean>}
     */
    updateTermsCondition = (term_and_condition_id, values) => {
        const data = new URLSearchParams({
            organisation_id: this.props.OrganizationContext.organisation_id,
        })
        for(const key in values){
            switch (key){
                case 'title':
                    data.append('title', values.title);
                    break;
                case 'description':
                    data.append('description', values.description);
                    break;
                case 'active':
                    data.append('active', !!values.active >>> 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;
            }
        }
        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/terms-and-conditions/${term_and_condition_id}`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
    * Update terms and conditions partially
    * @param {string} term_and_condition_id
    * @param {object} [values] The values for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Terms%20and%20Conditions/Apicontroller%5CPages%5CTermsandconditions%3A%3ApatchTermAndCondition|documentation}
    * @returns {Promise.<boolean>}
    */
    partialUpdateTermsCondition = (term_and_condition_id, values) => {
        const data = new URLSearchParams()
        for(const key in values){
            switch (key){
                case 'title':
                    data.append('title', values.title);
                    break;
                case 'description':
                    data.append('description', values.description);
                    break;
                case 'active':
                    data.append('active', !!values.active >>> 0);
                    break;

                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;
            }
        }
        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/terms-and-conditions/${term_and_condition_id}`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Delete terms and conditions
     * @param {string} term_and_condition_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Terms%20and%20Conditions/Apicontroller%5CPages%5CTermsandconditions%3A%3AdeleteTermAndCondition|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteTermsCondition = (term_and_condition_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/terms-and-conditions/${term_and_condition_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }


    render(){
        return (
            <TermsConditionsContext.Provider value={this}>
                {this.props.children}
            </TermsConditionsContext.Provider>
        )
    }
}

export default withContexts(OrganizationContext)(TermsConditionsContextProvider);