export default{
    "components.validateMember.PLAYER" : "Joueur",
    "components.validateMember.COACH"  : "Entraineur",
    "components.validateMember.STAFF"  : "Personnel",

    "components.validateMember.title"                     : "Valider un membre",
    "components.validateMember.uniqueIdentifier"          : "{unique_identifier}",
    "components.validateMember.uniqueIdentifier.required" : "{unique_identifier} est requis",
    "components.validateMember.team"                      : "Équipe",
    "components.validateMember.team.required"             : "L'équipe est requise",
    "components.validateMember.memberType"                : "Valider en tant que",
    "components.validateMember.memberType.required"       : "Le type de membre est requis",
    "components.validateMember.validate"                  : "Valider",
    "components.validateMember.valid"                     : "Ce membre est valide pour l'équipe!",
    "components.validateMember.affiliate"                 : "Affilié",

    // for the team add roster modal
    "components.validateMember.emptyLayout" : "Vous pouvez utiliser la recherche plus haut ou aller dans la section {validate} pour voir pourquoi un membre n'affiche pas ici.",
    "components.validateMember.addMember"   : "Ajouter le membre",

    // for the member part
    "components.validateMember.member.action" : "Valider le membre",
}