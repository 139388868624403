import ClinicSearch from '../../views/clinics/search/ClinicSearch';
import CreateClinic from '../../views/clinics/createClinic/CreateClinic';
import ClinicProfile from '../../views/clinics/profile/ClinicProfile';
import InvalidClinicProfileId from '../../views/clinics/profile/InvalidClinicProfileId';
import clinicsTabsRoutes from '../tabRoutes/clinicsTabsRoutes';

// Header

const clinicRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        exact: true,
        name: 'sidebar.clinics',
        path: '/clinics',
        permissions: { code: 'clinics' },
        component: ClinicSearch,
    },
    {
        name: 'sidebar.clinics.profile',
        path: '/clinics/profile/:clinicId',
        permissions: { code: 'clinics', permissionCode: 'clinics_profile' },
        subPermissionsRoutes: clinicsTabsRoutes,
        component: ClinicProfile,
        skipRecents: true,
    },
    {
        exact: true,
        name: 'sidebar.clinics.create',
        path: '/clinics/create',
        skipRecents: true,
        permissions: { code: 'clinics', permissionCode: 'clinics_manage', actions: [ 'ADD' ] },
        component: CreateClinic,
    },
    {
        exact: true,
        path: '/clinics/404',
        skipRecents: true,
        permissions: { code: 'clinics' },
        component: InvalidClinicProfileId,
    },
];
export default clinicRoutes;
