import { stringBuilder } from "@spordle/helpers";
import DragHandle from "../dragHandle/DragHandle";

const MobileFooterMenu = ({ children, className, innerClassName, innerTag: Tag = "div", handleToggle, isSmall, isOpened }) => {
    return (
        <div className={stringBuilder("sid-mobile-footer-menu", className, { "is-small": isSmall }, { "is-open": isOpened })}>
            <DragHandle handleToggle={handleToggle} />
            <Tag className={stringBuilder("sid-mobile-footer-menu-inner", innerClassName)}>
                {children}
            </Tag>
        </div>
    );
}

export default MobileFooterMenu;