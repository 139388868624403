import { FormikCurrencyInput, FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import { Col, Fade, Label, Row } from 'reactstrap';
import Required from '../../../../../components/formik/Required';
import UserDisplay from '../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../components/UserImg';
import { I18nContext } from '../../../../../contexts/I18nContext';
import { DisplayCategory } from '../../../TeamHelpers';

const TeamRolloverReviewEditAll = ({ categories, categoriesLoading, teams, renderOption, renderSelectedOption, setFieldValue, fieldPrefix }) => {
    const { getGenericLocale } = useContext(I18nContext);

    return (
        <Fade in>
            <Row form className='mb-4'>
                <Col sm='4'>
                    <Label className='text-muted'><Translate id='teams.teamSearch.rollover.step2.category' /> <Required /></Label>
                    <FormikSelect
                        isLoading={categoriesLoading}
                        id={fieldPrefix + 'categoryForAll'}
                        name={fieldPrefix + 'categoryForAll'}
                        renderOption={renderOption}
                        renderSelectedOption={renderSelectedOption}
                        searchKeys={[
                            `division.i18n.${getGenericLocale()}.short_name`,
                            `class.i18n.${getGenericLocale()}.short_name`,
                        ]}
                        onOptionSelected={([ value ]) => {
                            setFieldValue(fieldPrefix + 'categoryForAll', value || '', true)
                        }}
                        options={categories?.map((cat) => ({
                            value: cat.team_category_id,
                            isActive: cat.isActive,
                            class: cat.class,
                            division: cat.division,
                            label: `form.fields.gender.${cat.gender.toLowerCase()}`,
                            translateLabel: true,
                        }))}
                    />
                </Col>
                <Col sm='2'>
                    <Label className='text-muted'><Translate id='teams.profile.teamInfos.general.teamFee' /></Label>
                    <FormikCurrencyInput
                        id='teamFeeForAll'
                        name='teamFeeForAll'
                        allowNegative={false}
                    />
                </Col>
            </Row>

            <div className='font-bold h6'><Translate id='teams.teamSearch.rollover.step2.selectedTeams' /></div>
            <Row form>
                {teams.map((team) => (
                    <Col sm='4' key={team.team_id} className='mb-2'>
                        <UserDisplay card className='d-flex h-100 mb-0' style={{ minWidth: '100%' }}>
                            <UserDisplay.Container className="mr-3">
                                <UserImg
                                    abbr={team.abbreviation || team.short_name || team.name}
                                    src={team.logo?.full_path}
                                    filePos={team.logo?.file_position}
                                    width={60}
                                    alt={team.name}
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container>
                                <UserDisplay.Title className='text-break'>{team.name}</UserDisplay.Title>
                                {team.unique_identifier && <UserDisplay.Subtitle>#{team.unique_identifier}</UserDisplay.Subtitle>}
                                <UserDisplay.Subtitle>
                                    {team.division ?
                                        team.division
                                        :
                                        <DisplayCategory category={{ ...team.team_category }} short />
                                    }
                                </UserDisplay.Subtitle>
                            </UserDisplay.Container>
                        </UserDisplay>
                    </Col>
                ))}
            </Row>
        </Fade>
    )
}

export default TeamRolloverReviewEditAll