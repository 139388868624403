import { useContext, useEffect, useState } from "react";
import {
    Card,
    Row,
    Col,
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';

import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate, { DateFormat } from "@spordle/intl-elements";
import SpordlePanelTable from "../../../../components/sidePanel/SpordlePanel";
import SidePanelOrganizationStaff from "./sidepanel/SidePanelOrganizationStaff";
import AddStaffModal from "./modals/AddStaffModal";

// Contexts
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { RolesContext } from "../../../../contexts/RolesContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import EmptyLayout from "../../../../components/table/EmptyLayout";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import CardSectionTitle from "../../../../components/CardSectionTitle";
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import { DisplayPhoneNumber } from "@spordle/formik-elements";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts"
import { mutate } from "swr";
import { I18nContext } from "../../../../contexts/I18nContext";

const OrganizationStaff = (props) => {
    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const [ contactTypes, setContactTypes ] = useState([]);

    // Contexts
    const orgContext = useContext(OrganizationContext);
    const rolesContext = useContext(RolesContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const i18nContext = useContext(I18nContext);

    const toggleAddStaffModal = () => setModalIsOpen(!modalIsOpen);

    useEffect(() => {
        if(rolesContext.canDoAction('READ', 'settings', 'organization_contact_types') || identityRolesContext.isGod()){
            orgContext.getOrganizationContactTypes(orgContext.organisation_id)
                .then(setContactTypes)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                });
        }
    }, []);

    return (
        <Card body className="card-shadow">
            <CardSectionTitle title='organisation.profile.tabs.staff' />
            <SpordlePanelTable
                allowOutsideClick
                sidePanel={(sPanelProps) => <SidePanelOrganizationStaff types={contactTypes} {...sPanelProps} />}
                dataIndex='organisation_contact_id'
                table={(panelProps) => {
                    return (
                        <SpordleTableProvider
                            id='org-staff'
                            tableHover bordered striped
                            clickable
                            ref={panelProps.spordleTableRef}
                            tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                            searchKeys={[
                                'email',
                                'first_name',
                                'last_name',
                                'phone',
                                `organisation_contact_functions.organisation_contact_type.i18n.${i18nContext.getGenericLocale()}.name`,
                            ]}
                            dataIndex='organisation_contact_id'
                            pagination={20}
                            emptyLayout={<EmptyLayout translateMsg msg='organization.profile.staff.spordleTable.noResult' />}
                            columns={[
                                {
                                    label: <Translate id='form.fields.name' />,
                                    key: "member",
                                    mobile: true,
                                    sortable: true,
                                    sortKey: 'first_name',
                                },
                                {
                                    label: <Translate id='organization.profile.staff.spordleTable.label.contact' />,
                                    key: "contact",
                                    sortKey: "email",
                                    mobile: true,
                                    sortable: true,
                                },
                                {
                                    label: <Translate id='organization.profile.staff.spordleTable.label.role' />,
                                    key: "role",
                                    mobile: true,
                                },
                                // {
                                //     label: 'Criminal Record',
                                //     key: "criminalRecord",
                                //     mobile: true,
                                //     sortable: true
                                // },
                                {
                                    label: <Translate id='misc.status' />,
                                    key: "status",
                                    mobile: true,
                                    sortable: true,
                                    dataClassName: 'text-center',
                                },
                            ]}
                            renderRow={(columnKey, data) => {
                                switch (columnKey){
                                    case 'member':
                                        return (
                                            <>
                                                <div className="text-nowrap">{data.first_name} {data.last_name}</div>
                                                { data.member?.unique_identifier &&
                                                        <div className="small text-muted">
                                                            #{data.member?.unique_identifier}
                                                        </div>
                                                }
                                            </>
                                        )
                                    case 'role':
                                        return (
                                            data.organisation_contact_functions && data.organisation_contact_functions?.length > 1 ?
                                                <div className='font-medium text-dark'>
                                                    <ul className="ml-n4 mb-0">
                                                        {data.organisation_contact_functions.map((contactFunction) => (
                                                            <li key={contactFunction.organisation_contact_function_id}>
                                                                <DisplayI18n field="name" i18n={contactFunction.organisation_contact_type.i18n} defaultValue={contactFunction.organisation_contact_type.name} />
                                                                {contactFunction.is_elected == 1 && !contactFunction.elected_until &&
                                                                    <div className="text-muted small"><Translate id="organization.profile.staff.formik.elected" /></div>
                                                                }
                                                                {contactFunction.elected_until &&
                                                                    <div className="text-muted small">
                                                                        <Translate id='organization.profile.staff.formik.electedUntil' /> <DateFormat className='ml-1' value={contactFunction.elected_until} />
                                                                    </div>
                                                                }
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                :
                                                <div className='font-medium text-dark'>
                                                    <DisplayI18n field="name" i18n={data.organisation_contact_functions?.[0]?.organisation_contact_type?.i18n} defaultValue={data.organisation_contact_functions?.[0]?.organisation_contact_type?.name} />
                                                    {data.organisation_contact_functions?.[0]?.is_elected == 1 && !data.organisation_contact_functions?.[0]?.elected_until &&
                                                        <div className="text-muted small"><Translate id="organization.profile.staff.formik.elected" /></div>
                                                    }
                                                    {data.organisation_contact_functions?.[0]?.elected_until &&
                                                        <div className="text-muted small">
                                                            <Translate id='organization.profile.staff.formik.electedUntil' /> <DateFormat className='ml-1' value={data.organisation_contact_functions?.[0]?.elected_until} />
                                                        </div>
                                                    }
                                                </div>
                                        )
                                        // case 'criminalRecord':
                                        //     return(
                                        //         <>
                                        //             <i className="mdi mdi-check text-primary"></i> Expiration: 2022-08-10
                                        //         </>
                                        //     )
                                    case 'contact':
                                        return (
                                            <>
                                                <div><i className="mdi mdi-email-outline mr-1" />{data.email}</div>
                                                {data.phone && <div><i className="mdi mdi-phone mr-1" /><DisplayPhoneNumber phoneString={data.phone} format='INTERNATIONAL' /></div>}
                                            </>
                                        )
                                    case 'status':
                                        return (
                                            data.active === "1" ? <i className="mdi mdi-check text-primary" /> : <i className="mdi mdi-close text-danger" />
                                        )
                                    default: break;
                                }
                            }}
                            desktopWhen
                            onColumnClick={(e, data) => {
                                switch (e.button){
                                    case 0: // Left mouse button
                                        panelProps.onSingleSelectChange(data);
                                        break;
                                }
                            }}
                            rowIsHighlighted={(data) => !!data.checked}
                            loadData={(from, _, spordleTable) => {
                                switch (from){
                                    case 'REFRESH':
                                        spordleTable.setLoading();
                                    case 'CDM':
                                        return orgContext.getOrganizationContacts(orgContext.organisation_id)
                                            .then((contacts) => {
                                                mutate('getMissingMandatoryRoles');
                                                return contacts;
                                            });
                                    default:
                                        break;
                                }
                            }}
                        >
                            <Row className='mb-3'>
                                <Col md='6'>
                                    <SpordleTableProvider.SearchInput />
                                </Col>
                                <Col md='6'>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <SpordleTableProvider.Refresh />
                                        <UncontrolledDropdown className='ml-2'>
                                            <DropdownToggle disabled color='primary' outline caret>
                                                <i className='mdi mdi-download' /> <Translate id='misc.export' />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem><i className='fa fa-file-excel mr-2' /> <Translate id='misc.excel' /></DropdownItem>
                                                <DropdownItem><i className='fa fa-file-pdf mr-2' /> <Translate id='misc.pdf' /></DropdownItem>
                                                <DropdownItem><i className='mdi mdi-printer mr-2' /> <Translate id='misc.print' /></DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>

                                        <CanDoAction action='ADD' componentCode='organization' componentPermissionCode='organization_staff'>
                                            <Button color='primary' className='ml-2' onClick={toggleAddStaffModal}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                            {/* Modal here so it unmounts its content's state with the prop unmountOnClose */}
                                            <AnalyticsModal className="position-relative" isOpen={modalIsOpen} toggle={toggleAddStaffModal} backdrop='static' unmountOnClose analyticsName='OrganizationStaff'>
                                                <AddStaffModal toggle={toggleAddStaffModal} types={contactTypes} />
                                            </AnalyticsModal>
                                        </CanDoAction>

                                    </div>
                                </Col>
                            </Row>

                            <SpordleTableView />
                        </SpordleTableProvider>
                    )
                }}
            />
        </Card>
    );
}

export default OrganizationStaff;