import { useContext } from "react";
import { useProvincesSWR } from "../../api/useSWRHelpers/utilsSWR";
import { I18nContext } from "../../contexts/I18nContext";
import { DisplayI18n, displayI18n } from "../../helpers/i18nHelper";
import { FormikSelect } from "@spordle/formik-elements";

const FormikSelectProvinces = ({ countryId, filterOptions, ...props }) => {
    const { data, isValidating } = useProvincesSWR(countryId);
    const { getGenericLocale } = useContext(I18nContext);
    const isLoading = !data && isValidating;
    const options = (data || [])
        .sort((a, b) => {
            const nameA = displayI18n("name", a.i18n, a.name, getGenericLocale()) || "";
            const nameB = displayI18n("name", b.i18n, b.name, getGenericLocale()) || "";

            return nameA.localeCompare(nameB);
        })
        .reduce((theOptions, province) => {
            if((!filterOptions || filterOptions(province))){
                theOptions.push({
                    ...province,
                    value: province.code,
                    label: province.code,
                })
            }

            return theOptions;
        }, []);

    return (
        <FormikSelect
            id="province_code"
            isLoading={isLoading}
            name="province_code"
            disabled={!countryId}
            renderOption={({ option }) => <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />}
            {...props}
            options={options}
        />
    );
}

export default FormikSelectProvinces;