import { useSpordleTable } from "@spordle/datatables";
import { addExtension, FormikDateTime, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect, FormikSwitch } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { FieldArray, Form, Formik } from "formik";
import { useContext } from "react";
import { Button, Card, Col, Fade, FormGroup, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { array, boolean, object, string } from 'yup';
import Required from '../../../../../components/formik/Required';
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
// Contexts
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import generatePassword from "../../../../../helpers/passwordGenerator";
import moment from "moment";
import { stringifyUrl } from "query-string";
import getReferrer from "../../../../../helpers/getReferer";
import { AuthContext } from "../../../../../contexts/contexts";
import { fail, success } from "@spordle/toasts";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";

const Step2 = ({ toggle, changeStep, setLoading, info, types, ...props }) => {
    const { refreshTable } = useSpordleTable()

    // Contexts
    const orgContext = useContext(OrganizationContext);
    const authContext = useContext(AuthContext)

    // gets the correct version of page
    const getPageReferrer = (withAuth = false) => {
        return stringifyUrl({
            url: orgContext.deploy_on == 'PAGE' ?
            `${getReferrer('PAGE-VERCEL-2')}/${orgContext.short_name}`
                : orgContext.deploy_on == 'VERCEL' ?
                    `${getReferrer('PAGE-VERCEL')}/${orgContext.short_name}`
                    :
                    `${getReferrer('PAGE')}/page/${orgContext.short_url}`,
            query: {
                accessToken: withAuth ? authContext.accessToken : undefined,
            },
        }, {
            skipEmptyString: true,
            skipNull: true,
        })
    }

    return (
        <Fade>
            <Formik
                initialValues={{
                    member_id: info?.member_id || '',
                    first_name: info?.first_name,
                    last_name: info?.last_name,
                    organisation_contact_types: [
                        {
                            id: generatePassword(),
                            organisation_contact_type_id: '',
                            is_elected: '0',
                            elected_until: '',
                            active: '1',
                        },
                    ],
                    email: info?.email || '',
                    phone: '',
                    phoneExtension: '',
                    active: '1',
                    visible_on_website: false,
                    email_visible_on_website: false,
                    phone_visible_on_website: false,
                }}
                validationSchema={object().shape({
                    organisation_contact_types: array().of(object().shape({
                        organisation_contact_type_id: string()
                            .required(<Translate id='organization.profile.staff.formik.label.contactTypes.role.required' />)
                            .test({
                                name: 'noDuplicateRoles',
                                message: <Translate id='organization.profile.staff.formik.label.contactTypes.role.noDuplicate' />,
                                test: function(value){
                                    //check for duplicates
                                    return !(this.from[this.from.length - 1].value.organisation_contact_types.filter((role) => role.organisation_contact_type_id === value).length > 1)
                                },
                            }),
                        is_elected: string(),
                        elected_until: string().test({
                            name: 'validDate',
                            message: <Translate id='organization.profile.staff.formik.electedUntil.valid' />,
                            test: function(value){
                                if(this.parent.is_elected == 1)
                                    return moment(value).isValid();
                                return true;
                            },
                        }),
                    })), email: string().email(<Translate id='form.validation.email.valid' />).required(<Translate id='form.validation.email.required' />),
                    phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />),
                    phoneExtension: string(),
                    visible_on_website: boolean(),
                    email_visible_on_website: boolean(),
                    phone_visible_on_website: boolean(),
                })}
                onSubmit={async(values, { setSubmitting }) => {
                    const newVal = { ...values };
                    setLoading(true);

                    if(values.phone){
                        newVal.phone = await addExtension(values.phone, values.phoneExtension);
                    }else{
                        delete newVal.phoneExtension;
                        delete newVal.phone;
                    }

                    newVal.organisation_contact_types = values.organisation_contact_types.map((role) => ({
                        organisation_contact_type_id: role.organisation_contact_type_id,
                        is_elected: role.is_elected,
                        elected_until: (role.elected_until && role.is_elected == 1) ? moment(role.elected_until).format('YYYY-MM-DD') : '',
                        active: '1',
                    }))

                    newVal.visible_on_website = values.visible_on_website ? '1' : '0';
                    // here we make sure if visible_on_website is false that we prevent the other values from being true
                    newVal.email_visible_on_website = !values.visible_on_website ? '0' : values.email_visible_on_website ? '1' : '0';
                    newVal.phone_visible_on_website = !values.visible_on_website ? '0' : values.phone_visible_on_website ? '1' : '0';

                    return orgContext.createOrganizationContact(orgContext.organisation_id, newVal)
                        .then(() => {
                            // must refrersh table so the missing mandatory organiztion roles tooltip can be updated
                            refreshTable();
                            toggle()
                            success();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                        .finally(() => {
                            setSubmitting(false);
                            setLoading(false);
                        });
                }}
            >
                { (formik) => (
                    <Form>
                        <ModalBody>
                            <UserDisplay card className="w-100">
                                <UserDisplay.Container>
                                    <UserImg
                                        abbr={formik.values?.first_name?.charAt(0) + formik.values?.last_name?.charAt(0)}
                                        alt={info?.first_name + ' ' + info?.last_name}
                                    />
                                </UserDisplay.Container>
                                <UserDisplay.Container>
                                    <UserDisplay.Title>{info?.first_name} {info?.last_name} {info?.age ? <>(<Translate id='misc.yearsOld' values={{ age: info?.age }} />)</> : ''}</UserDisplay.Title>
                                    { info?.unique_identifier &&
                                        <UserDisplay.Subtitle>#{info.unique_identifier}</UserDisplay.Subtitle>
                                    }
                                </UserDisplay.Container>
                            </UserDisplay>
                            <Row form>
                                <Col className="form-group" md="12">
                                    <Label for="email"><Translate id='form.fields.email' /> <Required /></Label>
                                    <FormikInputText name="email" id="email" />
                                </Col>
                                <Col className="form-group" md="8">
                                    <Label for="phone"><Translate id='form.fields.phone' /></Label>
                                    <FormikPhoneInput id='phone' name='phone' />
                                </Col>
                                <Col className="form-group" md="4">
                                    <Label for="phoneExtension"><Translate id='form.fields.extension' /></Label>
                                    <FormikInputNumber prefix='# ' id='phoneExtension' name='phoneExtension' allowNegative={false} decimalSeparator={false} />
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label><Translate id='organization.profile.staff.formik.label.contactTypes' /> <Required /></Label>
                                <FieldArray name="organisation_contact_types">
                                    {(arrayHelper) => (
                                        <>
                                            {formik.values.organisation_contact_types?.map((orgSwitch, index) => (
                                                <Card className="card-shadow mb-2 p-3" key={orgSwitch.id}>
                                                    <div className="d-flex w-100">
                                                        <div className="w-100">
                                                            <FormGroup className="mb-2">
                                                                <div className="w-100">
                                                                    <FormikSelect
                                                                        search={false}
                                                                        name={`organisation_contact_types.${index}.organisation_contact_type_id`}
                                                                        id={`organisation_contact_types.${index}.organisation_contact_type_id`}
                                                                        placeholder='organization.profile.staff.formik.label.contactTypes.singular'
                                                                        renderOption={({ option }) => <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />}
                                                                        options={types.map((type) => {
                                                                            return {
                                                                                id: type.organisation_contact_type_id,
                                                                                value: type.organisation_contact_type_id,
                                                                                label: type.name,
                                                                                i18n: type.i18n,
                                                                            }
                                                                        })}
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                            <FormGroup className="d-flex mb-0">
                                                                <div className="w-100">
                                                                    <Label><Translate id='organization.profile.staff.formik.isElected' /></Label>
                                                                    <FormikSelect
                                                                        name={`organisation_contact_types.${index}.is_elected`}
                                                                        id={`organisation_contact_types.${index}.is_elected`}
                                                                        searchable={false}
                                                                        loadingStatus="success"
                                                                        defaultData={[
                                                                            {
                                                                                value: '1',
                                                                                label: 'misc.yes',
                                                                                translateLabel: true,
                                                                            },
                                                                            {
                                                                                value: '0',
                                                                                label: 'misc.no',
                                                                                translateLabel: true,
                                                                            },
                                                                        ]}
                                                                    />
                                                                </div>
                                                                <div className="ml-3 w-100">
                                                                    <Label><Translate id='organization.profile.staff.formik.electedUntil' /></Label>
                                                                    <FormikDateTime
                                                                        name={`organisation_contact_types.${index}.elected_until`}
                                                                        id={`organisation_contact_types.${index}.elected_until`}
                                                                        timeFormat={false} className='date-picker-left'
                                                                        disabled={formik.values.organisation_contact_types[index].is_elected == 0}
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </div>
                                                        <div>
                                                            { index !== 0 &&
                                                                <button type="button" onClick={arrayHelper.handleRemove(index)} className="ml-2 btn btn-sm btn-link px-0 text-danger">
                                                                    <i className="fas fa-trash" />
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </Card>
                                            ))}
                                            <Button
                                                color="primary"
                                                outline
                                                type="button"
                                                onClick={arrayHelper.handlePush({
                                                    id: generatePassword(),
                                                    organisation_contact_type_id: '',
                                                    is_elected: '0',
                                                    elected_until: '',
                                                    active: '1',
                                                })}
                                                className="w-100"
                                            >
                                                <i className={'mdi mdi-plus mr-1'} /><Translate id='organization.profile.staff.formik.label.contactTypes.add' />
                                            </Button>
                                        </>
                                    )}
                                </FieldArray>
                            </FormGroup>
                            <FormGroup className="border-top pt-3">
                                <div className="text-dark font-medium mb-2">
                                    <Translate id='organization.profile.staff.show.on' />
                                    <a className="ml-1 text-link" href={getPageReferrer(true)} target={'_blank'} rel="noreferrer">
                                        <Translate id='organization.profile.staff.website' />
                                        <i className="mdi mdi-launch ml-1" />
                                    </a>
                                </div>
                                <div className='font-medium'>
                                    <div className="d-flex mb-1">
                                        <Label for="visible_on_website" className="align-self-center text-dark mb-0">
                                            <Translate id='form.fields.visible_on_website' />
                                        </Label>
                                        <div className='ml-2'>
                                            <FormikSwitch
                                                id='visible_on_website'
                                                name="visible_on_website"
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1">
                                        <Label for="email_visible_on_website" className="align-self-center text-dark mb-0">
                                            <Translate id='form.fields.email_visible_on_website' />
                                        </Label>
                                        <div className='ml-2'>
                                            <FormikSwitch
                                                id='email_visible_on_website'
                                                name="email_visible_on_website"
                                                disabled={!formik.values.visible_on_website}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex mb-1">
                                        <Label for="phone_visible_on_website" className="align-self-center text-dark mb-0">
                                            <Translate id='form.fields.phone_visible_on_website' />
                                        </Label>
                                        <div className='ml-2'>
                                            <FormikSwitch
                                                id='phone_visible_on_website'
                                                name="phone_visible_on_website"
                                                disabled={!formik.values.visible_on_website}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" onClick={() => changeStep(1)} color="primary" outline className="mr-auto"><Translate id='misc.previous' /></Button>
                            <Button color="primary" type="submit" disabled={formik.isSubmitting}><Translate id="misc.add" /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Fade>
    )
}

export default Step2;