import { Container } from "reactstrap";
import settingsMemberTypesRoutes from "../../../../../routes/tabRoutes/organizationSettings/settingsMemberTypesRoutes";
import SubRouteSettingWrapper from "../../../components/SubRouteSettingWrapper";

const OrganizationSettingsMemberTypes = () => {
    return (
        <Container>
            <SubRouteSettingWrapper
                back="organization"
                routes={settingsMemberTypesRoutes}
            />
        </Container>
    );
}

export default OrganizationSettingsMemberTypes