export default{
    "discrimination.title" : "Plaintes pour discrimination",

    "discrimination.label.member"                   : "Individu",
    "discrimination.label.members"                  : "Membre(s)",
    "discrimination.label.complaintBy"              : "Plainte déposée par",
    "discrimination.label.complaintType"            : "Type de plainte",
    "discrimination.label.submittedBy"              : "Déposée par",
    "discrimination.label.onBehalf"                 : "Personne victime de discrimination",
    "discrimination.label.sameAsSumittedBy"         : "La victime est celle qui a porté plainte",
    "discrimination.label.onBehalfSubmitter"        : "La personne est la même que celle qui a déposée la plainte",
    "discrimination.label.role"                     : "Rôle",
    "discrimination.label.validation.role.required" : "Rôle est requis",
    "discrimination.label.validation.team.required" : "L'équipe est requise",
    "discrimination.label.team"                     : "Équipe",
    "discrimination.label.teamName"                 : "Nom d'équipe",
    "discrimination.label.complainees"              : "Personne(s) visée(s)",
    "discrimination.label.details"                  : "Détails",
    "discrimination.label.summary"                  : "Sommaire",
    "discrimination.label.people"                   : "Personnes impliquées",
    "discrimination.label.incident"                 : "Incident",
    "discrimination.label.info"                     : "Info",
    "discrimination.label.overview"                 : "Survol",
    "discrimination.label.arena"                    : "Aréna / Lieu",
    "discrimination.label.status"                   : "Statut de la plainte",
    "discrimination.label.outcomes"                 : "Résultats",
    "discrimination.label.suspensions"              : "Suspensions",
    "discrimination.label.closedReason"             : "Raison(s)",
    "discrimination.label.homeAway"                 : "Locale / Visiteur",
    "discrimination.form.phoneOrEmail.required"     : "Veuillez spécifier le numéro de téléphone ou le courriel",

    "discrimination.form.team.helper"        : "Veuillez spécifier dans quelle équipe le membre jouait lors de l'incident. Vous pouvez poursuivre si cette étape ne s'applique pas à votre plainte.",
    "discrimination.form.team.helper.short"  : "Laissez vide si cela ne s'applique pas à votre plainte pour discrimination.",
    "discrimination.form.team.helper.plural" : "Veuillez spécifier dans {count, plural, =0 {quelle équipe le joueur jouait} one {quelle équipe le joueur jouait} other {quelles équipes les joueurs jouaient}} lors de l'incident. Vous pouvez poursuivre si cette étape ne s'applique pas à votre plainte.",

    "discrimination.form.incident.future"           : "La date de l'incident ne peut pas être dans le futur",
    "discrimination.form.incident.afterCreation"    : "La date de l'incident ne peut pas être après la date de création de la plainte",
    "discrimination.form.incidentTime.required"     : "Veuillez spécifier l'heure à laquel s'est produit l'incident",
    "discrimination.form.discriminatoryGrounds.min" : "Veuillez choisir au moins un motif",
    "discrimination.form.closedReasons.min"         : "Veuillez choisir au moins une raison",
    "discrimination.form.belongsTo.required"        : "Veuillez spécifier si l'équipe était les visiteurs ou pas",
    "discrimination.form.details.required"          : "Les détails de l'incident sont requis",
    "discrimination.form.complaintType.required"    : "Veuillez spécifier le type de plainte",
    "discrimination.form.arena.required"            : "Veuillez spécifier à quel endroit l'événement s'est produit",
    "discrimination.form.referenceNb.required"      : "Veuillez spécifier le numéro de référence",
    "discrimination.form.type.required"             : "Veuillez spécifier le type de discrimination",
    "discrimination.form.onIce.required"            : "Veuillez spécifier l'endroit où s'est produit l'événement",
    "discrimination.form.claimStatus.required"      : "Veuillez spécifier le status de la plainte",
    "discrimination.form.team.required"             : "Veuillez spécifier l'équipe",
    "discrimination.form.teamName.required"         : "Veuillez spécifier le nom d'équipe",
    "discrimination.form.branch.required"           : "Veuillez spécifier la branche",
    "discrimination.form.org.required"              : "Veuillez spécifier l'organisation",
    "discrimination.form.existing.required"         : "Veuillez spécifier si l'équipe est dans notre système",

    "discrimination.overview.title"        : "Sommaire de la plainte",
    "discrimination.overview.itp"          : "ITP: #{number}",
    "discrimination.overview.members"      : "{count, plural, one {# membre} other {# membres}}",
    "discrimination.overview.outcomes"     : "{number, plural, =0 {# résultat} one {# résultat} other {# résultats}}",
    "discrimination.overview.suspensions"  : "{number, plural, =0 {# suspension} one {# suspension} other {# suspensions}}",
    "discrimination.overview.finish.title" : "Veuillez confirmer",
    "discrimination.overview.finish.text"  : "Une fois terminée, cette plainte sera enlevé de vos «plaintes récemment créées» en haut de la page.",

    "discrimination.overview.recentComplaints.title"           : "Plaintes récemment créées",
    "discrimination.overview.recentComplaints.recentlyCreated" : "Plaintes récemment créées",
    "discrimination.overview.recentComplaints.quickTour"       : "Les plaintes récemment créées apparaîtront ici pour un accès rapide.<br></br><br></br>Ils resteront ici jusqu'à ce que vous cliquez sur <bold>«Terminer»</bold> après avoir cliqué sur ces cartes.",

    "discrimination.global.itpReferenceNumber"                    : "Numéro de référence ITP",
    "discrimination.global.incidentLocation"                      : "Où s'est produit la plainte?",
    "discrimination.global.incidentDate"                          : "Date de l'incident",
    "discrimination.global.incidentTime"                          : "Heure de l'incident",
    "discrimination.global.discriminatoryGrounds"                 : "Motif(s) discriminatoire(s)",
    "discrimination.global.discriminatoryGrounds.*"               : "Tous les motifs",
    "discrimination.global.discriminatoryGrounds.RACE"            : "Race, origine ethnique ou nationale, couleur de peau ou langue parlée",
    "discrimination.global.discriminatoryGrounds.RELIGION"        : "Religion ou croyances",
    "discrimination.global.discriminatoryGrounds.AGE"             : "Âge",
    "discrimination.global.discriminatoryGrounds.GENDER_IDENTITY" : "Sexe, orientation sexuelle ou genre/identité sexuelle",
    "discrimination.global.discriminatoryGrounds.MARITAL_STATUS"  : "Situation familiale ou état matrimonial",
    "discrimination.global.discriminatoryGrounds.GENETIC"         : "Caractéristiques génétiques",
    "discrimination.global.discriminatoryGrounds.DISABILITY"      : "Handicap",
    "discrimination.global.suspensions"                           : "Suspensions",
    "discrimination.global.maltreatmentType"                      : "Type de discrimination",

    "discrimination.global.complaintType.ON_ICE"          : "Sur la glace",
    "discrimination.global.complaintType.OUTSIDE_OF_PLAY" : "En dehors du jeu",
    "discrimination.global.complaintType.OTHER"           : "Autre",

    "discrimination.global.teamPosition.HOME" : "Équipe locale",
    "discrimination.global.teamPosition.AWAY" : "Visiteurs",

    "discrimination.global.complaintType.onIce.helper"         : "L'événement s'est produit lors d'un match",
    "discrimination.global.complaintType.outsideOfPlay.helper" : "L'événement s'est produit en dehors d'un match",

    "discrimination.global.closedReasons.WRITTEN_WARNING"          : "Avertissement par écrit",
    "discrimination.global.closedReasons.EDUCATION"                : "Éducation",
    "discrimination.global.closedReasons.PROBATION"                : "Probation",
    "discrimination.global.closedReasons.ELIGIBILITY_RESTRICTIONS" : "Restrictions d'admissibilité",
    "discrimination.global.closedReasons.OTHER_SANCTIONS"          : "Autres sanctions",
    "discrimination.global.closedReasons.COMPLAINT_DISMISSED"      : "Plainte rejetée",
    "discrimination.global.alreadyAdded"                           : "Déjà ajouté",

    "discrimination.global.roles.PLAYER"           : "Joueur",
    "discrimination.global.roles.PARENT"           : "Parent",
    "discrimination.global.roles.BENCH"            : "Personnel / Entraîneur",
    "discrimination.global.roles.BENCH_STAFF"      : "Personnel / Entraîneur",
    "discrimination.global.roles.VOLUNTEER"        : "Bénévole",
    "discrimination.global.roles.OFFICIAL"         : "Officiel",
    "discrimination.global.roles.EMPLOYEE"         : "Employé",
    "discrimination.global.roles.OTHER"            : "Autre",
    "discrimination.global.roles.SPECTATOR"        : "Spectateur",
    "discrimination.global.roles.OFF_ICE_OFFICIAL" : "Arbitre hors glace",
    "discrimination.global.roles.ANONYMOUS"        : "Anonyme",

    // ---------- ADD DISCRIMINATION MODAL
    "discrimination.addModal.title"                           : "Déposer une plainte pour discrimination",
    "discrimination.addModal.matchingProfiles.title"          : "{count, plural, =0 {Profil correspondant trouvé} one {Profil correspondant trouvé} other {Profils correspondants trouvés}}",
    "discrimination.addModal.matchingProfiles.btn.next"       : "Non, poursuivre",
    "discrimination.addModal.matchingProfiles.step2.subtitle" : "Nous avons trouvé {count, plural, =0 {un profil correspondant. Est-ce qu'il s'agit de} one {un profil correspondant. Est-ce qu'il s'agit de} other {plusieurs profils correspondants. Est-ce qu'un de ces profils correspond à}} la personne pour qui cette plainte est déposée ?",
    "discrimination.addModal.matchingProfiles.step3.subtitle" : "{count, plural, =0 {Est-ce que le profil de ce membre appartient à la personne visée par la plainte?} one {Est-ce que le profil de ce membre appartient à la personne visée par la plainte?} other {Est-ce qu'un des profils de membre ci-dessous appartient à la personne visée par la plainte?}}",

    "discrimination.addModal.stepComplaint.title"                  : "Qui fait cette plainte ?",
    "discrimination.addModal.stepComplainer.title.official"        : "Nom de l'officiel qui rapporte cette plainte",
    "discrimination.addModal.stepComplaint.subtitle"               : "Veuillez spécifier si cette plainte est remplie par un officiel.",
    "discrimination.addModal.stepComplaint.btn.official.title"     : "Plainte remplie par un officiel",
    "discrimination.addModal.stepComplaint.btn.official.subtitle"  : "Cette plainte pour discrimination est déposée par un officiel.",
    "discrimination.addModal.stepComplaint.btn.other.title"        : "Plainte remplie par une autre personne",
    "discrimination.addModal.stepComplaint.btn.other.subtitle"     : "Cette plainte pour discrimination est déposée par une personne avec un rôle différent.",
    "discrimination.addModal.stepComplaint.btn.anonymous.title"    : "Plainte anonyme",
    "discrimination.addModal.stepComplaint.btn.anonymous.subtitle" : "Cette plainte pour discrimination est déposée par une personne qui souhaite garder son identité privée.",

    // step 1
    "discrimination.addModal.stepComplainer.subtitle"                 : "Veuillez spécifier qui dépose cette plainte pour discrimination.",
    "discrimination.addModal.stepComplainer.title"                    : "Personne qui dépose la plainte",
    "discrimination.addModal.stepComplainer.title.official.error"     : "Nous n'avons pas trouvé le membre dans votre organisation courante. Si vous pensez que c'est une erreur, veuillez contacter notre équipe d'assistance.",
    "discrimination.addModal.stepComplainer.btn.me.title"             : "Moi",
    "discrimination.addModal.stepComplainer.btn.me.subtitle"          : "Je suis la personne qui souhaite déposer la plainte.",
    "discrimination.addModal.stepComplainer.btn.someoneElse.title"    : "Quelqu'un d'autre",
    "discrimination.addModal.stepComplainer.btn.someoneElse.subtitle" : "Quelqu'un d'autre souhaite déposer la plainte.",

    // step 2
    "discrimination.addModal.stepVictim.title"            : "Est-ce que cette plainte est déposée par la personne visée par l'acte de discrimination ?",
    "discrimination.addModal.stepVictim.subtitle"         : "Veuillez spécifier qui a été visé par le comportement discriminatoire.",
    "discrimination.addModal.stepVictim.btn.yes.subtitle" : "La personne qui dépose la plainte a été visé par le comportement discriminatoire.",
    "discrimination.addModal.stepVictim.btn.no.subtitle"  : "La personne qui dépose la plainte la dépose pour une autre personne qui a été victime du comportement discriminatoire.",

    "discrimination.addModal.stepVictim.substep2.title"    : "Personne qui a été victime du comportement discriminatoire",
    "discrimination.addModal.stepVictim.substep2.subtitle" : "Veuillez spécifier les informations de la personne qui a été victime du comportement discriminatoire",

    // step 3
    "discrimination.addModal.stepGuilty.selected"              : "{count, plural, =0 {<b>#</b> personne sélectionnée} =1 {<b>#</b> personne sélectionnée} other {<b>#</b> personnes sélectionnées}}",
    "discrimination.addModal.stepGuilty.title.teamOrMEmber"    : "Plainte envers",
    "discrimination.addModal.stepGuilty.subtitle.teamOrMEmber" : "Veuillez spécifier si cette plainte vise une personne ou une équipe",
    "discrimination.addModal.stepGuilty.title"                 : "Personne visée par la plainte",
    "discrimination.addModal.stepGuilty.subtitle"              : "Veuillez spécifier les informations de la personne visée par cette plainte.",
    "discrimination.addModal.stepGuilty.review.title"          : "Sommaire {count, plural, =0 {de la personne visée} one {de la personne visée} other {des personnes visées}} par la painte.",
    "discrimination.addModal.stepGuilty.review.subtitle.1"     : "Vous êtes sur le point de remplir un formulaire de plainte pour discrimination envers {count, plural, =0 {cette personne.} one {cette personne.} other {ces personnes.}}",
    "discrimination.addModal.stepGuilty.review.subtitle.2"     : "Veuillez cliquer sur « Remplir le formulaire » pour procéder ou ajouter les autres personnes impliquées.",
    "discrimination.addModal.stepGuilty.review.btn.add"        : "Ajouter une personne",
    "discrimination.addModal.stepGuilty.review.btn.list"       : "Liste des personnes visées",
    "discrimination.addModal.stepGuilty.review.btn.fillForm"   : "Remplir le formulaire",
    "discrimination.addModal.stepGuilty.team.title"            : "Équipe visée par l'équipe",
    "discrimination.addModal.stepGuilty.team.helper"           : "Veuillez spécifier contre quelle équipe cette plainte est déposée.",

    "discrimination.addModal.stepConfirm.text"         : "Vous êtes sur le point de créer une plainte pour discrimination.",
    "discrimination.addModal.stepConfirm.success.text" : "Vous avez créé la plainte avec succès.",
    "discrimination.addModal.stepConfirm.addOutcome"   : "Ajouter résultat",
    "discrimination.addModal.stepConfirm.addOutcomes"  : "Ajouter tous les résultats",
    "discrimination.addModal.stepConfirm.changeTeam"   : "Changer l'équipe",


    // step 4
    "discrimination.addModal.stepIncident.details.helper" : "Veuillez fournir un résumé détaillé et précis décrivant l’incident relié à votre plainte.",

    // ---------- END ADD DISCRIMINATION MODAL

    "discrimination.spordleTable.discriminatoryGrounds.more" : "{count, plural, =0 {+# motif discriminatoire} one {+# motif discriminatoire} other {+# motifs discriminatoires}}",

    "discrimination.sidePanel.case"                                     : "Dossier",
    "discrimination.sidePanel.people.addModal.title"                    : "Ajouter une personne visée",
    "discrimination.sidePanel.people.editModal.title"                   : "Modifier personne visée",
    "discrimination.sidePanel.people.editModal.linkedMember"            : "Membre lié",
    "discrimination.sidePanel.people.editModal.linkedMember.btn.remove" : "Dissocier",
    "discrimination.sidePanel.people.complainees"                       : "{count, plural, =0 {Personne visée} one {Personne visée} other {Personnes visées}} par la plainte",
    "discrimination.sidePanel.people.complaineeTeam"                    : "Équipe visée par la plainte",
    "discrimination.sidePanel.people.onBehalf.allFilled.firstName"      : "Veuillez spécifier le prénom ou vider tous les champs",
    "discrimination.sidePanel.people.onBehalf.allFilled.lastName"       : "Veuillez spécifier le nom ou vider tous les champs",
    "discrimination.sidePanel.people.onBehalf.allFilled.birthdate"      : "Veuillez spécifier la date de naissance ou vider tous les champs",
    "discrimination.sidePanel.list.groundsSelected"                     : "Motif(s) discriminatoire(s) sélectionné(s)",

    "discrimination.sidePanel.closeComplaint" : "Clore la plainte",

    "discrimination.sidePanel.editStatusModal.title"                : "Modifier le statut",
    "discrimination.sidePanel.editStatusModal.btn"                  : "Mettre à jour le statut",
    "discrimination.sidePanel.editStatusModal.final"                : "Il s'agit du statut final. Vous ne serez pas en mesure de modifier ou de supprimer cette plainte une fois ce statut appliqué.",
    "discrimination.sidePanel.editStatusModal.success"              : "Le status de la plainte a été mis à jour avec succès.",
    "discrimination.sidePanel.editStatusModal.allComplete.title"    : "Résultats complets",
    "discrimination.sidePanel.editStatusModal.allComplete.text"     : "Tous les résultats reliés à cette plainte ont été marqués comme complets.",
    "discrimination.sidePanel.editStatusModal.allComplete.question" : "Changer le status de cette plainte à {status}",
    "discrimination.sidePanel.editStatusModal.allComplete.update"   : "Mettre à jour le status",

    "discrimination.sidePanel.deleteClaim.btn"     : "Supprimer la plainte",
    "discrimination.sidePanel.deleteClaim.title"   : "Supprimer cette plainte pour discrimination",
    "discrimination.sidePanel.deleteClaim.success" : "Plainte pour discrimination supprimée",

    "discrimination.addModal.form.select.groundsSelected"       : "{count, plural, =0 {# motif discriminatoire sélectionné} one {# motif discriminatoire sélectionné} other {# motifs discriminatoires sélectionnés}}",
    "discrimination.addModal.form.select.closedReasonsSelected" : "{count, plural, =0 {# raison sélectionnée} one {# raison sélectionné} other {# raisons sélectionnées}}",


    // Discrimination Outcomes
    "discrimination.outcomes.addSuspension"       : "Cliquez ici pour ajouter une suspension",
    "discrimination.outcomes.addSuspension.toast" : "Ajouter une suspension pour {name}",
    "discrimination.outcomes.addOutcomes"         : "Ajouter des résultats",
    "discrimination.outcomes.outcome.empty"       : "Aucun résultat",
    "discrimination.outcomes.outcome"             : "Résultats",
    "discrimination.outcomes.causesSuspension"    : "Cause une suspension",
    "discrimination.outcomes.addOutcome"          : "Ajouter un résultat",
    "discrimination.outcomes.dueDate"             : "Date d'échéance",
    "discrimination.outcomes.createdBy"           : "Créé par",
    "discrimination.outcomes.updatedBy"           : "Mis à jour par",
    "discrimination.outcomes.due.date.validation" : "La date d'échéance doit être après la date de l'incident.",

    "discrimination.outcomes.status.PENDING"   : "En attente",
    "discrimination.outcomes.status.COMPLETED" : "Complété",
    "discrimination.outcomes.selected"         : "Déjà ajouté",

    "discrimination.spordleTable.outcomes.more" : "{count, plural, =0 {+# résultat} one {+# résultat} other {+# résultats}}",

    "discrimination.outcomes.outcome.required" : "Résultat est requis",
    "discrimination.outcomes.comment.required" : "Le résultat sélectionné nécessite un commentaire",
    "discrimination.outcomes.dueDate.format"   : "Format de date non valide",

    // Discrimination Suspensions
    "discrimination.suspensions.addSuspension" : "Ajouter une suspension",
}