import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { Collapse } from "reactstrap";
import { RolesContext } from "../../../../contexts/RolesContext";
import { sortRoutesAlphabetically } from "../../../../helpers/navigationHelper";
import SidebarGroupTitle from "./SidebarGroupTitle";
import ApiSidebarLinks from "./ApiSidebarLinks";
import SidebarItem from "./SidebarItem";
import { useRoutesSWR } from "./useRoutesSWR";

const GroupedSidebarLinks = ({ routes, ...props }) => {
    return (
        // key={navGroup.name} causes a key warning in the console and i dont fucking know why because the routes names should all be different
        // eslint-disable-next-line react/no-array-index-key
        routes?.map((navGroup, i) => <GroupedItem key={i} navGroup={navGroup} {...props} />)
    ) || <></>
}

const GroupedItem = ({ navGroup, toggleCollapse, checkCollapseIsOpen, closeMenu }) => {
    const { formatMessage } = useIntl();
    const rolesContext = useContext(RolesContext);
    const navGroupSubRoutes = navGroup.sortAlphabetical ? sortRoutesAlphabetically(navGroup.subPermissionsRoutes, formatMessage) : navGroup.subPermissionsRoutes;

    const collapseIsOpen = checkCollapseIsOpen(navGroup.name);
    const showGroupFor = rolesContext.validateRouteAccess(navGroup);
    const { data } = useRoutesSWR(navGroup);

    if((navGroup.api && Array.isArray(data) && data.length === 0) || !showGroupFor || navGroup.redirect || ((navGroup.devOnly && process.env.REACT_APP_ENVIRONMENT === 'prod'))){
        return null;
    }

    return (
        <ul className="hide-menu bg-transparent list-unstyled">
            <SidebarGroupTitle
                className={showGroupFor === 'ADMIN' ? 'text-purple font-bold' : 'sid-sidebar-item-title'}
                onClick={() => toggleCollapse(navGroup.name, !collapseIsOpen)}
            >
                <i className={stringBuilder("fa sid-sidebar-item-chevron", { 'fa-chevron-down': collapseIsOpen, 'fa-chevron-right': !collapseIsOpen })} />{navGroup.skipTranslate ? navGroup.name : <Translate id={navGroup.name} />}
            </SidebarGroupTitle>
            <Collapse tag="li" isOpen={collapseIsOpen}>
                <ul className="bg-transparent list-unstyled">
                    {navGroup.api ?
                        <ApiSidebarLinks
                            navGroup={navGroup}
                            closeMenu={closeMenu}
                        />
                        :
                        navGroupSubRoutes?.map((prop, key) => (
                            <SidebarItem
                                key={prop.path + prop.name}
                                index={key}
                                closeMenu={closeMenu}
                                prop={prop}
                            />
                        ))
                    }
                </ul>
            </Collapse>
        </ul>
    )
}

export default GroupedSidebarLinks;