import Translate, { DateFormat } from '@spordle/intl-elements';
import React, { useContext } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
import { displayI18n, DisplayI18n } from '../../../../helpers/i18nHelper';
import CanDoAction from '../../../../components/permissions/CanDoAction';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';

/**
* @param {function} toggleEdit // function used to toggle between FormOnlineStoreGeneralInformation and this view
*/
const ReadOnlineStoreGeneralInformation = ({ toggleEdit, ...props }) => {
    const organizationContext = useContext(OrganizationContext)
    return (
        <Card className="card-shadow">
            <CardBody>
                <div className="d-flex align-items-end pb-1 border-bottom mb-3">
                    <div className="h4 font-bold card-title mb-0"><Translate id='onlineStore.create.step1.label' /></div>
                    <CanDoAction className='ml-auto text-primary' action='EDIT' componentCode='page' componentPermissionCode='online_store_manage'>
                        <button type='button' className="reset-btn" onClick={toggleEdit}><Translate id='misc.edit' /></button>
                    </CanDoAction>
                </div>
                <div>
                    <div id='languages'>
                        <i className="mdi mdi-check text-primary mr-1" />{props.data?.languages.map((lang, index) => <React.Fragment key={lang}><Translate id={`misc.${lang}.long`} />{index !== props.data.languages.length - 1 && ', '}</React.Fragment>) || <Translate id='misc.undefined' />}
                    </div>
                    <CanDoAction action='EDIT' componentCode='catalog' componentPermissionCode='online_store_manage_skip_postal_code' skipAccess>
                        <div className="mt-1" id='bypassPostalCodes'>
                            <i className={`mdi mdi-${props.data?.skip_postal_code_validation == 1 ? "close text-danger" : "check text-primary"} mr-1`} /><Translate id="onlineStore.components.onlineStoreGeneralInformation.field.bypassPostalCode" />
                        </div>
                    </CanDoAction>
                    <Row form className="mt-3">
                        {props.data?.languages.map((lang) => (
                            <Col sm="6" key={`name-${lang}`}>
                                <div className="mb-3">
                                    <div className="text-muted"><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.formName' /> (<Translate id={`misc.${lang}.text`} />)</div>
                                    <div className="font-medium" id={`storeName-${lang}`}><DisplayI18n lang={lang} field='name' i18n={props.data.i18n} defaultValue={props.data.name} /></div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <div className="mb-3">
                        <div className="text-muted"><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.registrarEmail' /></div>
                        <ul className="list-unstyled font-medium" id='registrarEmails'>
                            <li data-registrar='1'>{props.data?.email || '-'}</li>
                        </ul>
                        {/* <p className="small">* <Translate id='onlineStore.components.onlineStoreGeneralInformation.field.registrarEmail.sendTo/></p> */}
                    </div>
                    <div className="h6 font-bold mt-4"><Translate id='onlineStore.components.onlineStoreGeneralInformation.section.registrationPeriod' /></div>
                    <Row>
                        <Col sm="6" md="3">
                            <div className="d-inline-block text-left mb-2">
                                <div className="text-muted"><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.startDate' /></div>
                                <div id='startDate'>{props.data?.start_date ? <DateFormat value={props.data.start_date} /> : '-'}</div>
                            </div>
                        </Col>
                        <Col sm="6" md="3">
                            <div className="d-inline-block text-left mb-2">
                                <div className="text-muted"><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.startTime' /></div>
                                <div id='startTime'>{props.data?.start_date ? <DateFormat value={props.data.start_date} format={'HH:mm a'} /> : '-'}</div>
                            </div>
                        </Col>
                        <Col sm="6" md="3">
                            <div className="d-inline-block text-left mb-2">
                                <div className="text-muted"><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.endDate' /></div>
                                <div id='endDate'>{props.data?.end_date ? <DateFormat value={props.data.end_date} /> : '-'}</div>
                            </div>
                        </Col>
                        <Col sm="6" md="3">
                            <div className="d-inline-block text-left mb-2">
                                <div className="text-muted"><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.endTime' /></div>
                                <div id='endTime'>{props.data?.end_date ? <DateFormat value={props.data.end_date} format={'HH:mm a'} /> : '-'}</div>
                            </div>
                        </Col>
                        {organizationContext.settings.enable_organization_activity_periods?.value == '1' &&
                            <Col sm="6" md="3">
                                <div className="d-inline-block text-left mb-2">
                                    <div className="text-muted"><Translate id='form.fields.activityPeriod' /></div>
                                    <div id='endTime'>{props.data?.activity_period ? <DisplayI18n field='name' defaultValue={props.data?.activity_period?.name} i18n={props.data?.activity_period?.i18n} /> : '-'}</div>
                                </div>
                            </Col>
                        }
                    </Row>
                    <div className="h6 font-bold mt-4"><Translate id='onlineStore.components.onlineStoreGeneralInformation.section.message' /></div>
                    <Row>
                        {props.data?.languages.map((lang) => (
                            <Col sm="6" key={`message-${lang}`}>
                                <div className="mb-3">
                                    <div className="text-muted"><Translate id='onlineStore.components.onlineStoreGeneralInformation.field.confirmMessage' /> (<Translate id={`misc.${lang}.text`} />)</div>
                                    <div id={`confirmationMessage-${lang}`} className="font-medium" dangerouslySetInnerHTML={{ __html: displayI18n('confirmation_message_email', props.data.i18n, props.data.confirmation_message_email, lang) }} />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </CardBody>
        </Card>
    );
}

export default ReadOnlineStoreGeneralInformation;
