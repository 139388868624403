import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { useContext } from 'react';
import { SpecialFieldComponent } from "@spordle/cart-engine";

// export const isARequiredSpecialField = (orgContext, field) => !!orgContext.settings?.member_mandatory_special_fields_for_registration?.value?.find((value) => value === field)
// export const isARequiredSpecialField = (orgContext, field) => !!orgContext.settings?.member_mandatory_special_fields_for_clinic?.value?.find((value) => value === field)

const SpecialFieldComponentWrapper = (props) => {
    const orgContext = useContext(OrganizationContext)

    return <span className="fill-current"><SpecialFieldComponent settings={orgContext.settings} {...props} /></span>
}

export default SpecialFieldComponentWrapper