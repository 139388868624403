import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import {
    ModalHeader
} from "reactstrap";
import { object, string, array } from 'yup';
import AnalyticsModal from '../../../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import { success } from '@spordle/toasts';
import { IdentityRolesContext } from '../../../../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { RolesContext } from '../../../../../../contexts/RolesContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import CancelItemCancelled from './CancelItemCancelled';
import CancelItemReason from './CancelItemReason';
import CancelItemSelection from './CancelItemSelection';

const MemberProfileItemCancel = ({
    availableRefund,
    isOpen,
    toggle,
    toggleRefund,
    refundPermissions,
    invoiceItems,
    defaultItemIds,
    refreshTable,
    onCancel,
    chainWithRefund = false,
}) => {
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const memberContext = useContext(MembersContext);

    const steps = {
        cancelItemSelection: 'cancelItemSelection',
        cancelItemReason: 'cancelItemReason',
        cancelItemCancelled: 'cancelItemCancelled',
    }
    const [ activeStep, setActiveStep ] = useState(steps.cancelItemSelection);

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='MemberProfileItemCancel' onOpened={() => setActiveStep(steps.cancelItemSelection)}>
            <Formik
                initialValues={{
                    reason: '',
                    items: defaultItemIds || [],
                }}
                validationSchema={object().shape({
                    reason: string().trim().required(<Translate id='memberProfile.registration.cancelRegistration.reason.required' />),
                    items: array().min(1, <Translate id='memberProfile.registration.cancelRegistration.selection.required' />),
                })}
                onSubmit={({ reason, items }, { setSubmitting, setStatus }) => {
                    Promise.all([
                        ...invoiceItems.reduce((newA, item) => {
                            if(items.includes(item.invoice_item_id) && (item.registration_fee || item.other_fee)){
                                newA.pushArray([
                                    memberContext.cancelInvoiceItem(item.invoice_item_id, reason),
                                    item.confirmed_at ? memberContext.deleteInvoiceItemConfirm(item.invoice_item_id) : Promise.resolve(),
                                ]);
                            }
                            return newA;
                        }, []),
                    ])
                        .then(() => {
                            success({ msg: 'memberProfile.registration.cancelRegistration.success' });
                            setSubmitting(false);
                            onCancel?.();

                            //****** CANCEL THEN REFUND LOGIC *******/
                            // Check if you have something to refund and if this modal isn't in the transaction tab
                            if((availableRefund.payment_gateway > 0 || availableRefund.manual > 0)
                                && chainWithRefund
                                && (refundPermissions ?
                                    canDoAction(refundPermissions.action, refundPermissions.componentCode, refundPermissions.componentPermissionCode) || isGod()
                                    :
                                    true
                                )
                            ){
                                setActiveStep(steps.cancelItemCancelled);
                            }else{
                                // we now sync the rows when cancelling the registration, we therefore no longer need to refresh here
                                refreshTable?.();
                                toggle();
                            }
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setSubmitting(false);
                                if(error.i18n){
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                }else{
                                    setStatus(<Translate id='memberProfile.registration.cancelRegistration.error' />);
                                }
                            }
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader tag="div" toggle={() => activeStep === steps.cancelItemCancelled ? refreshTable() : toggle()}>
                                <Translate id='memberProfile.registration.cancelRegistration' />
                            </ModalHeader>
                            {activeStep === steps.cancelItemSelection ?
                                <CancelItemSelection
                                    invoiceItems={invoiceItems}
                                    toggle={toggle}
                                    setActiveStep={setActiveStep}
                                    steps={steps}
                                />
                                : activeStep === steps.cancelItemReason ?
                                    <CancelItemReason
                                        invoiceItems={invoiceItems}
                                        toggle={toggle}
                                        setActiveStep={setActiveStep}
                                        steps={steps}
                                    />
                                    : activeStep === steps.cancelItemCancelled ?
                                        <CancelItemCancelled
                                            toggle={toggle}
                                            toggleRefund={toggleRefund}
                                            refreshTable={refreshTable}
                                        />
                                        : null}
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    );
}

export default MemberProfileItemCancel;