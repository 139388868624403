import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import MainPhoto from "../../../../components/MainPhoto";
import HasAccessTo from "../../../../components/permissions/HasAccessTo";
import { AuthContext } from "../../../../contexts/contexts";
import getAccountUrl from "../../../../helpers/getExternalUrl";

const ProfileNav = () => {
    const { account, isAuthenticated, logout } = useContext(AuthContext);
    return (
        <>
            { isAuthenticated ?
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret className="d-flex align-items-center account-picture-navigation rounded-circle">
                        <Tooltip
                            withArrow
                            label={<Translate id='header.account.title' />}
                            zIndex={3000}
                        >
                            <div>
                                <MainPhoto />
                            </div>
                        </Tooltip>
                    </DropdownToggle>
                    <DropdownMenu right className="user-dd account-picture-navigation-dropdown">
                        <div className="d-flex align-items-center border-bottom p-3 mb-2">
                            <MainPhoto width={70} />
                            <div className="ml-2">
                                <div className="mb-0 h4">{account.name + ' ' + account.family_name}</div>
                                <p className="mb-0">{account.email}</p>
                            </div>
                        </div>
                        <DropdownItem className="pl-3" href={getAccountUrl('SPORDLE')}>
                            <i className="mdi mdi-home-outline ml-1 mr-2" /><Translate id='header.account' />
                        </DropdownItem>
                        <DropdownItem className="pl-3" tag={Link} role="menuitem" to="/supportRequest">
                            <i className="mdi mdi-wrench ml-1 mr-2" /><Translate id='header.supportRequest' />
                        </DropdownItem>
                        <HasAccessTo componentCode="communications" componentPermissionCode="communications_manage">
                            <DropdownItem className="pl-3" tag={Link} role="menuitem" to="/communications">
                                <i className="mdi mdi-email-outline ml-1 mr-2" /><Translate id='sidebar.communications' />
                            </DropdownItem>
                        </HasAccessTo>
                        <DropdownItem divider />
                        <DropdownItem className="pl-3" onClick={() => logout().catch(console.error)}>
                            <i className="mdi mdi-logout ml-1 mr-2" /><Translate id='header.signout' />
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                :
                null
            }
        </>
    )
}

export default ProfileNav;