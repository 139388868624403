import { useContext, useState } from 'react';
import { Badge, Spinner } from 'reactstrap';
import { AxiosIsCancelled } from '../../api/CancellableAPI';
import { fail, success } from '@spordle/toasts';
import Translate from '@spordle/intl-elements';
import { Tooltip } from "@mantine/core";

// context
import { MembersContext } from '../../contexts/MembersContext';
import { DisplayI18n } from '../../helpers/i18nHelper';

const RetryInstallmentBadge = ({ payment, onRetryInstallment, invoiceItemId }) => {
    const [ isRetrying, setIsRetrying ] = useState(false);
    const membersContext = useContext(MembersContext);

    return (
        <>
            <Tooltip
                zIndex={3000}
                withArrow
                label={<div className='text-wrap' style={{ maxWidth: '300px' }}><Translate id={'components.transaction.retryInstallmentBadge.tooltip'} /></div>}
            >
                <Badge
                    color='danger'
                    data-tip="components.transaction.retryInstallmentBadge.tooltip"
                    pill
                    className="align-self-start d-inline-flex align-items-center clickable"
                    onClick={() => {
                        if(!isRetrying){ // prevent spam
                            setIsRetrying(true);

                            membersContext.processPayment(payment.invoice_payment_id)
                                .then(() => {
                                    success(payment.invoice_payment_id);
                                    setIsRetrying(false);
                                    onRetryInstallment?.({ status: 'COMPLETED' }, payment.invoice_payment_id, invoiceItemId)
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message)
                                        fail({
                                            msg: 'misc.error',
                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                            skipInfoTranslate: true,
                                        })
                                        setIsRetrying(false);
                                    }
                                })
                        }
                    }}
                >
                    <Translate id={'members.profile.transactions.memberProfileTransactions.sidePanel.status.' + payment.status} />
                    {isRetrying ?
                        <Spinner color='danger' type='grow' size='sm' className='ml-1' />
                        :
                        <i className='ml-1 mdi mdi-refresh font-12' />
                    }
                </Badge>
            </Tooltip>
        </>
    )
}

export default RetryInstallmentBadge