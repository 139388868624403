export default{
    "settings.title"                  : "Settings",
    "settings.title.platformSettings" : "Platform Configuration",

    "settings.tabs.roles"                : "Roles",
    "settings.tabs.assignRoles"          : "Assign Roles",
    "settings.tabs.periods"              : "Periods",
    "settings.tabs.activityPeriods"      : "Activity Periods",
    "settings.tabs.contactTypes"         : "Contacts Types",
    "settings.tabs.memberTypes"          : "Members Types",
    "settings.tabs.general"              : "General",
    "settings.tabs.suspensions"          : "Suspensions",
    "settings.tabs.divisions"            : "Divisions",
    "settings.tabs.classes"              : "Classes",
    "settings.tabs.phoneTypes"           : "Phone Types",
    "settings.tabs.backgroundCheckTypes" : "Background Check Types",
    "settings.tabs.positions"            : "Positions",
    "settings.tabs.positionGroups"       : "Position Groups",
    "settings.tabs.teamAddressTypes"     : "Team Address Types",
    "settings.tabs.teamTravelTypes"      : "Team Travel Types",
    "settings.tabs.teamContactTypes"     : "Team Contact Types",
    "settings.tabs.platformLexicon"      : "Platform Lexicon",

    // organization settings
    "settings.tabs.organization"                               : "Organization",
    "settings.tabs.organizationSettings"                       : "Organization settings",
    "settings.tabs.organization.settings"                      : "Settings",
    "settings.tabs.organization.settings.tooltip"              : "One of these permissions is required to access these settings",
    "settings.tabs.organization.settings.tooltip.none"         : "No permissions are required to access these settings",
    "settings.tabs.organization.settings.tooltip.noPermission" : "No permissions are required",
    "settings.tabs.organization.back"                          : "Back to list",
    "settings.tabs.organization.documents"                     : "Documents",
    "settings.tabs.organization.documents.description"         : "Manage the required documents for registrations, clinics and insurance claims",
    "settings.tabs.organization.documents.types"               : "Document Types",
    "settings.tabs.organization.documents.expirations"         : "Document Expirations",
    "settings.tabs.organization.backgroundChecks"              : "Background checks",
    "settings.tabs.organization.backgroundChecks.description"  : "Manage the available background checks for your organization",
    "settings.tabs.organization.backgroundChecks.types"        : "Background check types",
    "settings.tabs.organization.travelPermits"                 : "Travel permits",
    "settings.tabs.organization.travelPermits.description"     : "Manage your organization's travel permits settings",
    "settings.tabs.organization.transfer"                      : "{transfer_title}",
    "settings.tabs.organization.transfer.description"          : "Manage your organization's {transfers} settings",
    "settings.tabs.organization.phoneTypes"                    : "Phone types",
    "settings.tabs.organization.phoneTypes.description"        : "Manage your organization's phone types",
    "settings.tabs.organization.contactTypes"                  : "Contacts and Staff",
    "settings.tabs.organization.contactTypes.description"      : "Manage your organization's contact and staff settings",
    "settings.tabs.organization.merchantAccounts"              : "Merchant Account Providers",
    "settings.tabs.organization.merchantAccounts.settings"     : "Settings",
    "settings.tabs.organization.merchantAccounts.description"  : "Manage your organization's merchant account provider settings",
    "settings.tabs.organization.messagesSettings"              : "Messages and Descriptions",
    "settings.tabs.organization.messagesSettings.settings"     : "Settings",
    "settings.tabs.organization.messagesSettings.description"  : "Manage your messages and descriptions",
    "settings.tabs.organization.contactSettings"               : "Contacts and Staff settings",
    "settings.tabs.organization.contactSettings.description"   : "Manage your organization's contact and staff settings",
    "settings.tabs.organization.memberTypes"                   : "Member types",
    "settings.tabs.organization.memberTypes.description"       : "Manage your organization's member types",

    "settings.tabs.teams.suspensions"                   : "Suspensions",
    "settings.tabs.teams.suspensions.description"       : "Manage the possible team suspensions.",
    "settings.tabs.teams.positions"                     : "Positions",
    "settings.tabs.teams.positions.description"         : "Manage the available team positions.",
    "settings.tabs.teams.positionGroups"                : "Position Groups",
    "settings.tabs.teams.positionGroups.description"    : "Manage the team position groups.",
    "settings.tabs.teams.teamAddressTypes"              : "Team Address Types",
    "settings.tabs.teams.teamAddressTypes.description"  : "Manage the team address types.",
    "settings.tabs.teams.teamCategoryTypes"             : "Team Category Types",
    "settings.tabs.teams.teamCategoryTypes.description" : "Manage  the team category types.",
    "settings.tabs.teams.teamTravelTypes"               : "Team Travel Types",
    "settings.tabs.teams.teamTravelTypes.description"   : "Manage the team travel types.",
    "settings.tabs.teams.teamContactTypes"              : "Team Contact Types",
    "settings.tabs.teams.teamContactTypes.description"  : "Manage the team contact types.",

    "settings.tabs.teams.ressources"                             : "Resources",
    "settings.tabs.teams.ressources.description"                 : "Manage your teams resources, such as positions, suspensions, travel types and more.",
    "settings.tabs.teams.print"                                  : "Team Book",
    "settings.tabs.teams.print.description"                      : "Manage your team print settings.",
    "settings.tabs.teams.configuration"                          : "Configuration",
    "settings.tabs.teams.configuration.description"              : "Manage your team member types and rules.",
    "settings.tabs.teams.restrictions"                           : "Restrictions",
    "settings.tabs.teams.restrictions.description"               : "Manage your team restrictions.",
    "settings.tabs.teams.qualifications"                         : "Qualifications",
    "settings.tabs.teams.configuration.qualifications"           : "Qualification Restrictions",
    "settings.tabs.teams.configuration.qualifications.empty"     : "No Restrictions",
    "settings.tabs.teams.configuration.qualifications.empty.txt" : "No qualification restrictions have been set yet.",
}