import crc from "./crc";
import memberProfile from "./memberProfile";
import overview from "./overview";
import registration from "./registration";
import transactions from "./transactions";
import waivers from "./waivers";
import qualifications from "./qualifications";
import transfers from "./tranfers";
import appeals from "./appeals";
import teams from "./teams";
import suspensions from "./suspensions";

export default{
    ...registration,
    ...memberProfile,
    ...overview,
    ...transactions,
    ...crc,
    ...waivers,
    ...qualifications,
    ...transfers,
    ...appeals,
    ...teams,
    ...suspensions,
}