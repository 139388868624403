export default{
    "members.profile.overview.memberLogs.title" : "Registre des Modifications",
    "members.profile.overview.memberLogs.show"  : "Afficher",
    "members.profile.overview.memberLogs.hide"  : "Cacher",

    "members.profile.overview.memberLogs.table.date"    : "Date",
    "members.profile.overview.memberLogs.table.time"    : "Heure",
    "members.profile.overview.memberLogs.table.by"      : "Par",
    "members.profile.overview.memberLogs.table.section" : "Section",
    "members.profile.overview.memberLogs.table.detail"  : "Détails",
    "members.profile.overview.memberLogs.table.view"    : "Voir",
}