import Translate from '@spordle/intl-elements';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import IsGod from '../../../../../../components/permissions/IsGod';
import CrossFade from '../../../../../../components/crossFade/CrossFade';
import CanDoAction from '../../../../../../components/permissions/CanDoAction';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { useContext } from 'react';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';

const ContactSettingsView = ({ isLoading, activeTypes }) => {
    const organizationContext = useContext(OrganizationContext)
    return (
        <Row form>
            <CanDoAction tag={Col} sm="6" className='mb-3 px-1' action='READ' componentCode='settings' componentPermissionCode='organization_contact_types'>
                <Card className="card-shadow h-100 mb-0 p-1">
                    <CardHeader className="bg-white">
                        <div className="h5 font-bold mb-1"><Translate id="organization.settings.contactStaffSettings.table.column.mandatory_in_organisation.plural" /></div>
                        <div className="small font-medium mb-0"><Translate id="organization.settings.contactStaffSettings.table.column.mandatory_in_organisation.desc" /></div>
                    </CardHeader>
                    <CardBody>
                        <CrossFade isVisible={!isLoading}>
                            {(activeTypes && activeTypes.length > 0) ?
                                <ul className="pl-4">
                                    {activeTypes.filter((contactType) => contactType.mandatory_in_organisation == '1').map((contactType) => (
                                        <li key={contactType.organisation_contact_type_id}>
                                            <DisplayI18n field='name' defaultValue={contactType.name} i18n={contactType.i18n} />
                                        </li>
                                    ))}
                                </ul>
                                :
                                !isLoading && <Translate id="settings.documentSettings.documents.notDefined" />
                            }
                        </CrossFade>
                    </CardBody>
                </Card>
            </CanDoAction>

            <Col sm="6" className='mb-3 px-1'>
                <Card className="card-shadow h-100 mb-0 p-1">
                    <CardHeader className="bg-white">
                        <div className="h5 font-bold mb-1"><DisplayI18n field='name' i18n={organizationContext.settings.organisation_contact_must_be_member?.i18n} defaultValue={organizationContext.settings.organisation_contact_must_be_member?.name} /></div>
                        <div className="small font-medium mb-0"><Translate id="organization.settings.contactStaffSettings.table.column.contactMustBeMember.desc" /></div>
                        <IsGod><div className="small mt-1 font-medium text-purple">setting: organisation_contact_must_be_member</div></IsGod>
                    </CardHeader>
                    <CardBody>
                        <CrossFade isVisible={!isLoading}>
                            {organizationContext.settings.organisation_contact_must_be_member.value == '1' ?
                                <div className='font-bold'>
                                    <i className='mdi mdi-check text-primary mr-1' /><Translate id='misc.yes' />
                                </div>
                                :
                                <div className='font-bold'>
                                    <i className='mdi mdi-close text-danger mr-1' /><Translate id='misc.no' />
                                </div>
                            }
                        </CrossFade>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default ContactSettingsView;