import Translate from '@spordle/intl-elements'
import { useIntl } from 'react-intl'
import InlineCopy from '../../../../../../components/inlineCopy/InlineCopy'
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay'
import UserImg from '../../../../../../components/UserImg'

const AttendeeCard = ({ onMultiSelectChange, att, withErrors }) => {
    const intl = useIntl();
    const hasPaid = att.invoice?.status === 'COMPLETED' || att.invoice?.status === 'PAID';
    const statuses = [];

    if(hasPaid){ // has paid
        statuses.push(intl.formatMessage({ id: 'clinics.profile.attendees.sidepanel.selected.attendees.hasPaid' }));
    }
    if(att.attended == '1'){ // has attended
        statuses.push(intl.formatMessage({ id: 'clinics.profile.attendees.sidepanel.attended' }, { count: 1 }));
    }
    if(att.passed == '1'){ // has passed
        statuses.push(intl.formatMessage({ id: 'clinics.profile.attendees.sidepanel.passed' }, { count: 1 }));
    }

    return (
        <UserDisplay className="d-flex w-100 mb-1" card>
            <UserDisplay.Container>
                <UserImg
                    width={40}
                    src={att.member.picture?.full_path}
                    filePos={att.member.picture?.file_position}
                    abbr={att.member.first_name.charAt(0) + att.member.last_name.charAt(0)}
                    alt={att.member.first_name + ' ' + att.member.last_name}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title>
                    {att.member.first_name + ' ' + att.member.last_name}
                </UserDisplay.Title>
                <UserDisplay.Subtitle>
                    <div className="font-medium">
                        {att.member.unique_identifier ?
                            <InlineCopy toCopy={att.member.unique_identifier}>
                                #{att.member.unique_identifier}
                            </InlineCopy>
                            : '-'}
                    </div>
                </UserDisplay.Subtitle>
                {withErrors === 'PRESENCE' ?// ATTENDED
                    <>
                        {!hasPaid &&
                            <UserDisplay.Subtitle className='text-danger'>
                                <Translate id='clinics.profile.attendees.sidePanelActions.bulkUpdate.invalidMembers.paid' />
                            </UserDisplay.Subtitle>
                        }
                        {att.passed == '1' &&
                            <UserDisplay.Subtitle className='text-danger'>
                                <Translate id='clinics.profile.attendees.sidePanelActions.bulkUpdate.invalidMembers.passed' />
                            </UserDisplay.Subtitle>
                        }
                    </>
                    : withErrors === 'RESULT' ?// PASSED
                        <>
                            {!hasPaid &&
                            <UserDisplay.Subtitle className='text-danger'>
                                <Translate id='clinics.profile.attendees.sidePanelActions.bulkUpdate.invalidMembers.paid' />
                            </UserDisplay.Subtitle>
                            }
                            {att.attended != '1' &&// Did not attend
                            <UserDisplay.Subtitle className='text-danger'>
                                <Translate id='clinics.profile.attendees.sidePanelActions.bulkUpdate.invalidMembers.attended' />
                            </UserDisplay.Subtitle>
                            }
                        </>
                        : !withErrors &&
                    <UserDisplay.Subtitle>
                        {statuses.join(' | ')}
                    </UserDisplay.Subtitle>
                }
            </UserDisplay.Container>
            {!withErrors && onMultiSelectChange &&
                <UserDisplay.Container className="ml-auto small text-danger">
                    <button onClick={() => onMultiSelectChange(att)} className="reset-btn text-danger" type='button'>
                        <Translate id='misc.remove' />
                    </button>
                </UserDisplay.Container>
            }
        </UserDisplay>
    )
}

export default AttendeeCard
