import { stringBuilder } from "@spordle/helpers";
import { useFormikContext } from "formik";
import { useEffect, useState, useRef } from "react";
import FormikRadioBtn from "../../officialSupervision/components/addModal/components/FormikRadioBtn";
import Translate from "@spordle/intl-elements";

const PotentialMemberDuplicateKeepBtn = ({ isSelected, ...props }) => {
    const [ showError, setShowError ] = useState(false);
    const formik = useFormikContext();
    const hasError = !!(formik.errors.keep && formik.touched.keep);
    const timeout = useRef(false);

    useEffect(() => {
        if(hasError){
            setShowError(true);

            timeout.current = setTimeout(() => {
                setShowError(false);
            }, 900);

        }

        return () => {
            clearTimeout(timeout.current);
        }
    }, [ hasError, formik.submitCount ])

    return (
        <FormikRadioBtn
            label={<Translate id='tasks.memberDuplicates.manage.keep' />}
            name="keep"
            btnClassName={stringBuilder({ "border-danger": showError, "bg-primary text-white": isSelected, "shadow-sm": !formik.values.keep }, "rounded-pill")}
            className="mb-0"
            {...props}
        />
    );
}

export default PotentialMemberDuplicateKeepBtn;