import { DisplayPhoneNumber } from "@spordle/formik-elements";
// Language
import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import React, { useContext } from "react";
import {
    Badge,
    Card, Col, Fade, Row
} from 'reactstrap';
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
// Contexts
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import withContexts from '../../../../../../helpers/withContexts';
import { getLocation as buildFullAddress } from '../../../../../../views/teams/TeamHelpers';
import CrossFade from "../../../../../../components/crossFade/CrossFade";
import moment from "moment";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import { fail, success } from "@spordle/toasts";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { Tooltip } from "@mantine/core";
import InlineCopy from "../../../../../../components/inlineCopy/InlineCopy";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";

class MemberAddressPhone extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            rejectedAddrOpen: false,
        };
    }

    isCompleteAddressProfile = (addresses) => {
        return addresses.every((addr) => addr.status === 'CONFIRMED' || addr.status === "REJECTED");
    }

    render(){
        return (
            <>
                <Fade>
                    {/* <AddressConfirmationModal toggle={this.toggleAddressConfirmationModal} isOpen={this.state.confirmationModalOpen} currentAddress={this.state.currentAddress} allAddresses={this.state.addresses} />
                    <MemberAddressPhoneModal
                        isOpen={this.state.createEditModalOpen}
                        toggle={this.toggleCreateEditModal}
                        getAddresses={this.getAddresses}
                        address={this.state.currentAddress}
                    /> */}

                    <Card id='addressComponentMemberProfile' body className={stringBuilder({ 'shadow-danger card-error': !this.isCompleteAddressProfile(this.props.addresses) }, 'card-shadow')}>
                        <div className="border-bottom pb-1 mb-3 d-flex align-items-end">
                            <div className="card-title mb-0">
                                <span className='font-bold h4'><Translate id='members.profile.overview.memberAddressPhone.title' /></span>
                                {!this.isCompleteAddressProfile(this.props.addresses) &&
                                    <span className='text-danger ml-2'><i className='mdi mdi-map-marker-radius' /> <Translate id='members.profile.overview.memberAddressPhone.title.incomplete' /></span>
                                }
                            </div>
                            {this.props.isCurrentOrganizationOrParent &&
                                <CanDoAction className='ml-auto text-link' action='ADD' componentCode='members' componentPermissionCode='members_address'>
                                    <button className="reset-btn" type="button" onClick={() => this.props.toggleCreateEditModal()}><i className="mdi mdi-plus mr-1" /><Translate id='misc.add' /></button>
                                </CanDoAction>
                            }
                        </div>
                        {/* foreach address */}
                        {/*  enumerates the addresses, each phone number is attached to an address, however an address doesn't necessarily have a phone number */}
                        {/*  we also now filter out addresses with a rejected status to hide them under a crossfade */}
                        { !this.props.addresses || this.props.addresses.length <= 0 ?
                            <div className="text-center font-16 font-bold text-dark">
                                <Translate id='members.profile.overview.memberAddressPhone.noAdditional' />
                            </div>
                            :
                            <>
                                {
                                    this.props.addresses
                                        .filter((addr) => addr.status !== 'REJECTED')
                                        .map((address, index) => (
                                            <MemberAddressPhoneEntry
                                                key={address.member_address_id}
                                                address={address}
                                                index={index + 1}
                                                showDivider={this.props.addresses.length > 1 && index + 1 !== this.props.addresses.length}
                                                toggleAddressConfirmationModal={this.props.toggleAddressConfirmationModal}
                                                toggleCreateEditModal={this.props.toggleCreateEditModal}
                                                getAddresses={this.props.getAddresses}
                                                isCurrentOrganizationOrParent={this.props.isCurrentOrganizationOrParent}
                                            />
                                        ))
                                }
                                {this.props.addresses.filter((addr) => addr.status === 'REJECTED').length > 0 &&
                                    <>
                                        <div className="clickable text-link" onClick={() => { this.setState((prevState) => ({ rejectedAddrOpen: !prevState.rejectedAddrOpen })) }}><Translate id={!this.state.rejectedAddrOpen ? 'members.profile.overview.memberAddressPhone.open.reject' : 'members.profile.overview.memberAddressPhone.close.reject'} /></div>
                                        <CrossFade isVisible={this.state.rejectedAddrOpen}>
                                            <hr />
                                            {
                                                this.props.addresses
                                                    .filter((addr) => addr.status === 'REJECTED')
                                                    .map((address, index) => (
                                                        // index is index + length of non rejected addresses array, that way we keep sequential address numbering in the interface
                                                        <MemberAddressPhoneEntry
                                                            key={address.member_address_id}
                                                            address={address}
                                                            isPrimary
                                                            index={index + this.props.addresses.filter((addr) => addr.status !== 'REJECTED').length + 1}
                                                            showDivider={this.props.addresses.length > 1 && index + 1 !== this.props.addresses.length}
                                                            toggleAddressConfirmationModal={this.props.toggleAddressConfirmationModal}
                                                            isCurrentOrganizationOrParent={this.props.isCurrentOrganizationOrParent}
                                                            getAddresses={this.props.getAddresses}
                                                        />
                                                    ))
                                            }
                                        </CrossFade>
                                    </>
                                }
                            </>
                        }
                        {/* end foreach address */}
                    </Card>
                </Fade>
            </>
        )
    }
}


export const MemberAddressPhoneEntry = ({ isPrimary, address, index, showDivider, toggleAddressConfirmationModal, toggleCreateEditModal, getAddresses, isCurrentOrganizationOrParent }) => {
    const membersContext = useContext(MembersContext);
    return (address ?
        <>
            <div key={address.member_address_id}>
                <div className={stringBuilder("font-16 font-bold text-dark mb-2 d-flex justify-content-between align-items-center", { 'text-muted': address.status === "REJECTED" && address.default_address != 1 })}>
                    <div className="d-flex align-items-center">
                        <div>
                            {address.default_address == 1 ?
                                <>
                                    <Translate id='members.profile.overview.memberAddressPhone.primaryAddress' />
                                    <sup><i className="mdi mdi-star text-warning" /></sup>
                                </>
                                :
                                <><Translate id='form.fields.address' /> #{index + 1}</>
                            }
                        </div>
                        {address.status == 'UNCONFIRMED' &&
                            <Badge className="ml-2" color='danger'><Translate id={`members.profile.overview.memberAddressPhone.status.${address.status}`} /></Badge>
                        }
                        {(address.status == 'REJECTED' && address.default_address == 1) &&
                            <Badge className="ml-2" color='danger'><Translate id='members.profile.overview.memberAddressPhone.status.REJECTED' /></Badge>
                        }
                    </div>
                    {(address.status !== 'REJECTED' && isCurrentOrganizationOrParent) &&
                        <div className="font-14">
                            <CanDoAction action='EDIT' componentCode='members' componentPermissionCode='members_address'>
                                {address.status === 'UNCONFIRMED' &&
                                    <>
                                        <button
                                            type='button'
                                            className='reset-btn text-danger text-decoration-underline'
                                            onClick={() => toggleAddressConfirmationModal(address)}
                                        >
                                            <i className="mdi mdi-map-marker-radius" />
                                            <Translate id='members.profile.overview.memberAddressPhone.verifyLink' />
                                        </button>
                                        <span className="mx-2 text-muted">|</span>
                                    </>
                                }
                            </CanDoAction>
                            <CanDoAction action='EDIT' componentCode='members' componentPermissionCode='members_address'>
                                {isPrimary && isCurrentOrganizationOrParent &&
                                    <CanDoAction className='ml-auto text-link' action='ADD' componentCode='members' componentPermissionCode='members_address'>
                                        <button className="reset-btn" type="button" onClick={() => toggleCreateEditModal()}><i className="mdi mdi-plus mr-1" /><Translate id='misc.add' /></button>
                                        <span className="mx-2 text-muted">|</span>
                                    </CanDoAction>
                                }
                                <button className="reset-btn text-primary" type='button' onClick={() => toggleCreateEditModal(address, isPrimary)}><Translate id='misc.edit' /></button>
                                {address.default_address != 1 &&
                                    <span className="mx-2 text-muted">|</span>
                                }
                            </CanDoAction>
                            {address.default_address != 1 &&
                                <ConfirmModal
                                    color='danger'
                                    modalContent={(
                                        <>
                                            {buildFullAddress(address)}
                                            {address.unit_number &&
                                                <>
                                                    <span className="mx-2">-</span>
                                                    {address.unit_number}
                                                </>
                                            }
                                        </>
                                    )}
                                    modalTitle='members.profile.overview.memberAddressPhone.deleteModal.title'
                                    translateModalTitle
                                    actionButtonText='misc.delete'
                                    translateActionButtonText
                                    onConfirm={() => {
                                        return membersContext.deleteMemberAddress(membersContext.currentMember.member_id, address.member_address_id)
                                            .then(() => {
                                                getAddresses();
                                                success();
                                            })
                                            .catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    }}
                                    toggler={(toggle) => (
                                        <CanDoAction action="DELETE" componentCode="members" componentPermissionCode="members_address">
                                            <button className="reset-btn text-danger" type='button' onClick={() => toggle()}><Translate id='misc.delete' /></button>
                                        </CanDoAction>
                                    )}
                                />
                            }
                        </div>
                    }
                </div>

                <div className='text-muted mb-1 d-flex'>
                    {!address.address_type ?
                        <Translate id='form.fields.address' />
                        :
                        <DisplayI18n field='name' defaultValue={address.address_type.name} i18n={address.address_type.i18n} />
                    }
                </div>
                <InlineCopy tag="div" toCopy={buildFullAddress(address)} className='font-medium'>
                    {address.map_url ?
                        <a href={address.map_url} target='_blank' rel='noopener noreferrer'>
                            {buildFullAddress(address)}
                        </a>
                        :
                        buildFullAddress(address)
                    }
                    {address.unit_number &&
                        <>
                            <span className="mx-2">-</span>
                            {address.unit_number}
                        </>
                    }
                </InlineCopy>
                {address.note &&
                    <div className="mt-2">
                        <div className="text-muted">
                            <Translate id="form.fields.note" />
                        </div>
                        <div className="font-medium">{address.note}</div>
                    </div>
                }
                {/* Address Phones  */}
                {address.phones?.length > 0 &&
                    <>
                        <div className="text-muted mb-1 mt-2">
                            <Translate id='members.profile.overview.memberAddressPhone.phoneNumbers' />
                        </div>
                        <Row>
                            {address.phones.map((phone) => (
                                <Col sm="4" key={phone.member_address_phone_id}>
                                    <div className="mb-1">
                                        <div className='font-medium'>
                                            <DisplayPhoneNumber format="INTERNATIONAL" phoneString={phone?.phone_number} />
                                            <span className='ml-1 text-muted'>
                                                (<DisplayI18n field='name' defaultValue={phone.phone_type?.name} i18n={phone.phone_type?.i18n} />)
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </>
                }
                <div className="d-flex">
                    {(address.years_same_address && (1900 < address.years_same_address <= moment().format('YYYY'))) &&
                        <div>
                            <Tooltip zIndex={3000} withArrow label={<Translate id='members.profile.overview.memberAddressPhone.years_same_address.tooltip' />}>
                                <div className="text-muted mb-1 mt-2">
                                    <Translate id='members.profile.overview.memberAddressPhone.years_same_address' />
                                </div>
                            </Tooltip>
                            <div>
                                {address.years_same_address}
                            </div>
                        </div>
                    }
                    {address.created_at &&
                        <div className={address.years_same_address ? "ml-3" : ''}>
                            <div className="text-muted mb-1 mt-2">
                                <Translate id='members.profile.overview.memberAddressPhone.created_at' />
                            </div>
                            {moment(address.created_at).format('YYYY-MM-DD')}
                        </div>
                    }
                    {address.updated_at &&
                        <div className={(address.years_same_address || address.created_at) ? "ml-3" : ''}>
                            <div className="text-muted mb-1 mt-2">
                                <Translate id='members.profile.overview.memberAddressPhone.updated_at' />
                            </div>
                            {moment(address.updated_at).format('YYYY-MM-DD')}
                        </div>
                    }
                    {address.api_partner &&
                        <div className={(address.years_same_address || address.created_at || address.updated_at) ? "ml-3" : ''}>
                            <div className="text-muted mb-1 mt-2">
                                <Translate id='misc.apiPartner' />:
                            </div>
                            {address.api_partner.name}
                        </div>
                    }
                </div>
                {/* Address Rejection Note */}
                {/* We will use status_note for this, I changed the field in the patch in the modal */}
                {address.member_address_status_logs?.[address.member_address_status_logs?.length - 1]?.note &&
                    <div>
                        <div className="text-muted mb-1 mt-2">
                            <Translate id='members.profile.overview.memberAddressPhone.status.note' />
                        </div>
                        <div className="text-dark">{address.member_address_status_logs?.[address.member_address_status_logs?.length - 1]?.note}</div>
                    </div>
                }
            </div>
            {showDivider &&
                <hr />
            }
        </>
        :
        <div className="text-center">
            <button className="reset-btn text-primary font-medium" type='button' onClick={() => toggleCreateEditModal(null, isPrimary)}><i className="mdi mdi-plus mr-1" /><Translate id='members.profile.overview.memberAddressPhone.noPrimary' values={{ break: (_) => <br /> }} /></button>
        </div>
    );
};


export default withContexts(MembersContext, OrganizationContext)(MemberAddressPhone);