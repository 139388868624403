import Translate from '@spordle/intl-elements'
import { useContext, useState } from 'react'
import { Button, Collapse, Fade, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import useSWR from 'swr'
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI'
import OverlayLoader from '../../../../../../components/loading/OverlayLoader'
import ManualRegistrationMemberIdentities from '../../../../../../components/manualRegistration/ManualRegistrationMemberIdentities'
import { fail } from '@spordle/toasts'
import { MembersContext } from '../../../../../../contexts/MembersContext'
import { DisplayI18n } from '../../../../../../helpers/i18nHelper'
import FormClinicSelectPermission from './FormClinicSelectPermission'

const STEP_SELECT = 1;
const STEP_PERMISSION = 2;
const STEP_CONFIRM = 3;

const FormClinicSelectIdentities = ({ isInstructor, isOpen, toggle, member, onCreate, apiCall, selectMember, ...props }) => {
    const [ selectIdentityStep, setSelectIdentityStep ] = useState(STEP_SELECT);
    const [ isLoading, setIsLoading ] = useState(false);
    const membersContext = useContext(MembersContext);

    const { data } = useSWR(
        [ 'getMemberIdentities', member?.member_id ],
        () => member?.member_id ?
            membersContext.getMemberAccounts(member.member_id)
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message)
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }
                })
            :
            null,
    );

    const isCurrentStep = (step) => step === selectIdentityStep;
    const goToStep = (step) => setSelectIdentityStep(step);
    const showPermissionStep = isCurrentStep(STEP_PERMISSION) && isOpen && isInstructor;
    const showConfirmationStep = isCurrentStep(STEP_CONFIRM) && isOpen;

    const handleOnCreate = (instructor, setStatus) => {
        setIsLoading(true);

        apiCall(instructor)
            .then(() => {
                onCreate?.(instructor);
                goToStep(STEP_CONFIRM);
                setIsLoading(false);
            })
            .catch(([ e ]) => {
                if(!AxiosIsCancelled(e.message)){
                    fail();
                    setIsLoading(true);
                    console.error(e.message);
                    setStatus(
                        <DisplayI18n
                            field='message'
                            defaultValue={e.message}
                            i18n={e.i18n}
                        />,
                    )
                }
            });
    }

    const handleOnPermissionSubmit = (values, { setStatus }) => {
        const instructor = {
            ...member,
            ...values,
        }

        selectMember(instructor);

        return handleOnCreate(instructor, setStatus);
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <Collapse isOpen={isOpen && isCurrentStep(STEP_SELECT)} mountOnEnter unmountOnExit>
                <Fade in={isOpen && isCurrentStep(STEP_SELECT)}>
                    <ModalHeader toggle={toggle}>
                        <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.link.title' />
                    </ModalHeader>
                    <ManualRegistrationMemberIdentities
                        {...props}
                        from='instructor'
                        memberIdentities={data}
                        member={member}
                        showSkip
                        onSubmit={(identityId, setStatus) => {
                            const instructor = {
                                ...member,
                                memberId: member.member_id,
                                identityId: identityId,
                            };

                            if(identityId == null){
                                instructor.clinic_permission = 'NO_ACCESS';
                                handleOnCreate(instructor, setStatus);
                            }else{
                                goToStep(STEP_PERMISSION);
                            }

                            return selectMember(instructor);
                        }}
                    />
                </Fade>
            </Collapse>
            <Collapse isOpen={showPermissionStep}>
                <Fade in={showPermissionStep}>
                    <FormClinicSelectPermission member={member} toggle={toggle} previous={() => goToStep(STEP_SELECT)} onSubmit={handleOnPermissionSubmit} />
                </Fade>
            </Collapse>
            <Collapse isOpen={showConfirmationStep}>
                <Fade in={showConfirmationStep}>
                    <ModalHeader toggle={toggle}>
                        <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.title' />
                    </ModalHeader>
                    <ModalBody className="text-center">
                        <i style={{ fontSize: 80 }} className="line-height-1 text-success mdi mdi-check-circle-outline" />
                        <div className="font-bold h1 text-success">
                            <Translate id="misc.success" />
                        </div>
                        <p><Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.subtitle' /></p>
                        <p>
                            {member?.identityId && member?.clinic_permission ?
                                <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.instructorIdentityMember' values={{ permission: <b><Translate id={'clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.' + member?.clinic_permission} /></b> }} />
                                :
                                member?.member_id ?
                                    <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.instructorMember' />
                                    :
                                    <Translate id='clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.instructorNoLink' />

                            }
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={isLoading} onClick={toggle} color="primary" outline type="button">
                            <Translate id="misc.close" />
                        </Button>
                    </ModalFooter>
                </Fade>
            </Collapse>
        </OverlayLoader>
    )
}

export default FormClinicSelectIdentities
