import Translate from "@spordle/intl-elements";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomAnimatedIcon from "../../../../../components/customAnimatedIcon/CustomAnimatedIcon";

const PostalCodesSuccess = ({ toggle, isEdit }) => {
    return (
        <>
            <ModalHeader toggle={toggle}><Translate id='organization.profile.postalCodes.modals.add.step2.title' /></ModalHeader>
            <ModalBody className='text-center'>
                <CustomAnimatedIcon className='text-success mb-3' icon='checkmark' withCircle size={50} />
                <p className='h5'>
                    <Translate id='organization.profile.postalCodes.modals.add.step3.description' />
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" type='button' onClick={toggle}><Translate id='misc.close' /></Button>
            </ModalFooter>
        </>
    )
};

export default PostalCodesSuccess;
