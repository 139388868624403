import { Skeleton } from "@mantine/core";
import UserDisplay from "../userDisplay/UserDisplay";

const DisplayMemberLoading = (props) => {
    return (
        <UserDisplay block {...props}>
            <UserDisplay.Container>
                <Skeleton
                    circle
                    width={40}
                    height={40}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title className="d-flex align-items-center mb-1">
                    <Skeleton height="1em" width="8ch" className="mr-1" /> <Skeleton height="1em" width="10ch" />
                </UserDisplay.Title>
                <UserDisplay.Subtitle><Skeleton height="1em" width="10ch" /></UserDisplay.Subtitle>
            </UserDisplay.Container>
        </UserDisplay>
    );
}

export default DisplayMemberLoading;