import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import queryString from 'query-string';
import { OrganizationContext } from './OrganizationContext';
import withContexts from '../helpers/withContexts';

/** @type {React.Context<Omit<PhoneTypesContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const PhoneTypesContext = createContext();
PhoneTypesContext.displayName = 'PhoneTypesContext';

class PhoneTypesContextProvider extends React.Component{


    /**
     * GET[ALL] - Get phone types
     * @param {object} [queryParams] The query params for that call
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Phone%20Types/Apicontroller%5COrganisations%5CPhonetypes%3A%3Aindex|documentation}
     * @returns {Promise.<Array>}
     */
    getPhoneTypes = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `phone-types`,
            query: Object.assign({
                organisation_id: this.props.OrganizationContext.organisation_id,
            }, queryParams),
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.phone_types
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * GET Specific phone type
     * @param {string} phone_type_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Phone%20Types/Apicontroller%5COrganisations%5CPhonetypes%3A%3AgetPhonetypeDetail|documentation}
     * @returns {Promise.<Array>}
     */
    getPhoneType = (phone_type_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `phone-types/${phone_type_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.phone_types[0]
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a phone type
     * @param {object} [values] The query params for that call
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Phone%20Types/Apicontroller%5COrganisations%5CPhonetypes%3A%3AcreatePhonetype|documentation}
     * @returns {Promise}
     */
    createPhoneType = (values = {}) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'organisation_id':
                    params.append('organisation_id', values.organisation_id);
                    break;
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/phone-types` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.phone_type_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
    * Updates a Phone Type
    * @param {string} phone_type_id
    * @param {object} values
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Phone%20Types/Apicontroller%5COrganisations%5CPhonetypes%3A%3AupdatePhonetype|documentation}
    * @returns {Promise}
    */
    updatePhoneType = (phone_type_id, values = {}) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;
                default:
                    params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `phone-types/${phone_type_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Partially updates a specified Phone Type
    * @param {string} phone_type_id
    * @param {object} values
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Phone%20Types/Apicontroller%5COrganisations%5CPhonetypes%3A%3ApatchPhonetype|documentation}
    * @returns {Promise}
    */
    partialUpdatePhoneType = (phone_type_id, values = {}) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'name':
                    params.append('name', values.name);
                    break;
                case 'active':
                    params.append('active', values.active);
                    break;

                default:
                    if(key.includes('i18n'))
                        params.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `phone-types/${phone_type_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
    * Deletes a specific Phone Type
    * @param {string} phone_type_id
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Phone%20Types/Apicontroller%5COrganisations%5CPhonetypes%3A%3AdeletePhonetype|documentation}
    * @returns {Promise}
    */
    deletePhoneType = (phone_type_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `phone-types/${phone_type_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <PhoneTypesContext.Provider value={{ ...this }}>
                {this.props.children}
            </PhoneTypesContext.Provider>
        )
    }
}


export default withContexts(OrganizationContext)(PhoneTypesContextProvider);

