import { FormikDateTime, FormikError, FormikSelect } from '@spordle/formik-elements';
// Language
import Translate from "@spordle/intl-elements";
import { stringBuilder } from "@spordle/helpers";
import moment from 'moment';
import PropTypes from 'prop-types';
import React from "react";
import {
    Button, Col, Collapse, FormGroup,
    Label, Nav,
    NavItem,
    NavLink, Row, TabContent,
    TabPane
} from "reactstrap";
import { mixed, object, string } from 'yup';
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import FormikEditable from '../../../../components/formik/FormikEditable';
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import SidePanelMemberProfile from '../../../../components/sidePanel/profiles/SidePanelMemberProfile';
import SidePanel from "../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
// Context
import { AuthContext } from "../../../../contexts/contexts";
import { I18nContext } from '../../../../contexts/I18nContext';
import withContext from '../../../../helpers/withContexts';
import UsersSettingsReactivateModal from "../../../settings/users/modals/UsersSettingsReactivateModal";
import UsersSettingsUnlockModal from "../../../settings/users/modals/UsersSettingsUnlockModal";
import { DisplayI18n } from '../../../../helpers/i18nHelper';

class SidePanelUsers extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            activeTab: '1',
            userData: {},
            unlockModal: false,
            reactivateModal: false,
        }

        this.roles = [];
    }

    toggleTab = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState({ activeTab: tab });
        }
    }

    toggleModal = (modal) => {
        this.setState((prevState) => ({ [modal]: !prevState[modal] }));
    }

    getUserId = () => {
        return this.props.selectedRows[0].identity_id;
    }

    getIdentityRoleId = () => {
        return this.props.selectedRows[0].identity_role_id;
    }

    getRoles = () => {
        return this.props.rolesContext.getOrganizationRoles(this.props.getCommonValue("organisation_id"))
            .then((data) => {
                this.roles = data;
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    /////////////////////////////////////////////////////////////////////////////
    // UNLOCK ACCOUNT LOGIC
    /////////////////////////////////////////////////////////////////////////////
    handleReactivate = () => {
        return this.props.AuthContext.createUser({
            ...this.state.userData,
            action: 'RESEND',
        })
            .then(() => {
                success({ msg: 'organization.profile.users.sidePanelUsers.reactivate.success' })
                this.setState((prevState) => {
                    prevState.userData.reactivate = false;
                    return {
                        userData: prevState.userData,
                    }
                })
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    if(error.i18n){
                        fail({
                            msg: 'misc.error',
                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                            skipInfoTranslate: true,
                        })
                    }else{
                        switch (error.message){
                            case 'UnsupportedUserStateException':
                                fail({ msg: 'organization.profile.users.sidePanelUsers.reactivate.error.activeAccount' });
                                break;
                            default:
                                fail({ msg: 'organization.profile.users.sidePanelUsers.reactivate.error' });
                                break;
                        }
                    }
                }
            });
    }

    handleUnlock = () => {
        return this.props.AuthContext.unlockUser(this.state.userData.Username)
            .then(() => {
                this.props.syncRows(this.props.createNewValues({ signin_lock_date: null }));
                success({ msg: 'organization.profile.users.sidePanelUsers.unlock.success' })
                this.setState((prevState) => {
                    prevState.userData.signin_lock_date = null;
                    return {
                        userData: prevState.userData,
                    }
                })
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    switch (error.message){
                        default:
                            fail({ msg: 'organization.profile.users.sidePanelUsers.unlock.error' })
                            break;
                    }
                }
            });
    }
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////
    // Same logic as the SpordlePanel, but since the user data isn't in selectedRows[0], we need to make another call from here and manage the data
    /////////////////////////////////////////////////////////////////////////////
    getUserInfo = () => {
        return this.props.authContext.getUser(this.getUserId())
            .then((data) => {
                data.reactivate = data.UserStatus === 'FORCE_CHANGE_PASSWORD';
                this.setState(() => ({ userData: data }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    getUserValue = (name) => {
        if(this.state.userData[name]){
            return this.state.userData[name];
        }
        return null;

    }
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////

    componentDidMount(){
        this.getUserInfo()
            .then(() => {
                this.getRoles()
                    .then(() => {
                        this.setState(() => ({ isLoading: false }));
                    })
            })
    }

    componentDidUpdate(prevState){
        if(prevState.selectedRows[0].identity_role_id !== this.props.selectedRows[0].identity_role_id){
            this.setState(() => ({ isLoading: true }));
            this.getUserInfo()
                .then(() => {
                    this.setState(() => ({ isLoading: false }));
                })
        }
    }

    handleDelete = () => {
        return this.props.identityRolesContext.deleteIdentityRole(this.props.getCommonValue("identity_role_id"))
            .then(() => {
                this.props.tableRef.current.deleteRow(this.props.getCommonValue("identity_role_id"))
                // TODO: we need to do something to prevent users to stay in a weird state where they still have the deleted identity role as selected
                this.props.forceCloseSidePanel();// On slower network, it is possible to close the sidepanel before the toggle causing the sidepanel to open without anything selected resulting in a crash
                // React Swal.close();
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({ isLoading: false }))
                }
            })
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <div className="p-3">
                    <div className='d-flex mb-2 align-items-center'>
                        <SidePanel.ToggleButton />
                        <SidePanel.ActionsMenu>
                            <CanDoAction action="DELETE" componentCode='organization' componentPermissionCode='organization_users'>
                                {this.props.identityRolesContext.identity_role_id === this.props.getCommonValue('identity_role_id') ?
                                    <SidePanel.MenuDelete
                                        disableDelete
                                        modalMsg='organization.profile.users.sidePanelUsers.removeUser.deletingActive1'
                                        translateModalMsg
                                        modalContent={
                                            <Translate id='organization.profile.users.sidePanelUsers.removeUser.deletingActive2' />
                                        }
                                    />
                                    :
                                    <SidePanel.MenuDelete
                                        translateModalMsg
                                        modalMsg='organization.profile.users.sidePanelUsers.removeUser.topText'
                                        onConfirm={this.handleDelete}
                                        modalContent={
                                            <Row className='mt-3'>
                                                <Col md='6' className='d-flex align-items-center justify-content-center flex-column border-right'>
                                                    <img
                                                        // TODO
                                                        // replace for user profie picture
                                                        src="https://via.placeholder.com/100"
                                                        alt="user_logo"
                                                        className="mw-100 dark-logo rounded-circle"
                                                        width="40"
                                                    />
                                                    <div className='font-medium'>{this.getUserValue("name")} {this.getUserValue("family_name")}</div>
                                                    <div className='small'>{this.getUserValue("email")}</div>
                                                </Col>
                                                <Col md='6' className='d-flex align-items-center justify-content-center flex-column'>
                                                    <div className='mr-2'>
                                                        <img
                                                            // TODO
                                                            // use this.organization.logo
                                                            src="https://cdn.hockeycanada.ca/hockey-canada/Corporate/Brand/Hockey-Canada-Logo/hc_logo.gif"
                                                            alt={this.props.organizationContext.organizationName}
                                                            className="mw-100 dark-logo"
                                                            width="40"
                                                        />
                                                    </div>
                                                    <div className='font-medium'>{this.props.organizationContext.organizationName}</div>
                                                    <div className='font-medium'>{this.props.getCommonValue("role")}</div>
                                                </Col>
                                            </Row>
                                        }
                                    />
                                }
                            </CanDoAction>
                            {this.getUserValue("reactivate") &&
                                <SidePanel.MenuAction onClick={() => this.toggleModal('reactivateModal')}>
                                    <Translate id='organization.profile.users.sidePanelUsers.header.reactivate' />
                                </SidePanel.MenuAction>
                            }
                            {this.getUserValue("signin_lock_date") !== null &&
                                <SidePanel.MenuAction onClick={() => this.toggleModal('unlockModal')}>
                                    <Translate id='organization.profile.users.sidePanelUsers.header.unlock' />
                                </SidePanel.MenuAction>
                            }
                        </SidePanel.ActionsMenu>
                    </div>
                    <SidePanel.Title>{this.state.userData.name} {this.state.userData.family_name}</SidePanel.Title>
                    <SidePanel.Subtitle>{this.state.userData.email}</SidePanel.Subtitle>
                </div>
                <Nav tabs>
                    <NavItem className="flex-grow-1 text-center w-100">
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggleTab('1'); }}
                        >
                            <Translate id='organization.profile.users.sidePanelUsers.tabs.details' />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <UsersSettingsUnlockModal
                            user={this.state.userData.name + ' ' + this.state.userData.family_name}
                            onConfirm={this.handleUnlock}
                            isOpen={this.state.unlockModal}
                            toggle={() => this.toggleModal('unlockModal')}
                        />
                        <UsersSettingsReactivateModal
                            user={this.state.userData.name + ' ' + this.state.userData.family_name}
                            onConfirm={this.handleReactivate}
                            isOpen={this.state.reactivateModal}
                            toggle={() => this.toggleModal('reactivateModal')}
                        />
                        {/* USER INFO */}
                        <div className='p-3 border-bottom'>
                            {this.getUserValue("reactivate") &&
                                <Button className='mb-3 w-100' outline color='success' type='button' onClick={() => this.toggleModal('reactivateModal')}>
                                    <Translate id='organization.profile.users.sidePanelUsers.header.reactivate' />
                                </Button>
                            }
                            {this.getUserValue("signin_lock_date") !== null &&
                                <Button className='mb-3 w-100' outline color='success' type='button' onClick={() => this.toggleModal('unlockModal')}>
                                    <Translate id='organization.profile.users.sidePanelUsers.header.unlock' />
                                </Button>
                            }

                            <div className="h4 font-bold mb-3"><Translate id='organization.profile.users.sidePanelUsers.userInfo.title' /></div>

                            {/* ROLE */}
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='organization.profile.users.sidePanelUsers.userInfo.role' /></div>
                                <FormikEditable
                                    id='roleId'
                                    disabled={!this.props.rolesContext.canDoAction("EDIT", "organization", "organization_users") || this.props.identityRolesContext.identity_role_id === this.props.getCommonValue('identity_role_id')}
                                    noConfirmation
                                    initialValues={{
                                        role_id: this.props.selectedRows[0].role_info.role_id,
                                    }}
                                    validationSchema={object().shape({
                                        role_id: string().required(<Translate id='organization.profile.users.sidePanelUsers.userInfo.role.required' />),
                                    })}
                                    onSubmit={(values) => {
                                        // To prevent updating everytime we open the select and click outside without changing the value
                                        if(values.role_id !== this.props.selectedRows[0].role_info.role_id){
                                            this.setState(() => ({ isLoading: true }))
                                            // The Select uses the role id as a value, but since we want the role title to change in the table we need to set it this way
                                            values.role = this.roles.find((role) => role.role_id === values.role_id).title
                                            values.description = this.roles.find((role) => role.role_id === values.role_id).description
                                            values.role_info = this.roles.find((role) => role.role_id === values.role_id)
                                            const newValues = this.props.createNewValues(values);
                                            this.props.identityRolesContext.updateIdentityRole(this.getIdentityRoleId(), newValues)
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    success();
                                                    this.setState(() => ({ isLoading: false }))
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                    this.setState(() => ({ isLoading: false }))
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options, formik) => {
                                        if(!isEditing){
                                            const role = this.roles?.find((role) => role.role_id === this.props.selectedRows[0].role_info.role_id)
                                            return (
                                                <div className='font-medium'>
                                                    {role?.active === '0' &&
                                                        <i className='text-warning mdi mdi-alert-outline mr-2' />
                                                    }
                                                    {role?.title}
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                name='role_id' id='role_id'
                                                menuIsDefaultOpen autoFocus
                                                defaultData={this.roles.map((role) => ({
                                                    label: role.title,
                                                    value: role.role_id,
                                                    active: role.active === '1',
                                                }))}
                                                loadingStatus='success'
                                                renderOption={(option) => (
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        {option.option.label}
                                                        {!option.option.active &&
                                                                <i className='text-warning mdi mdi-alert-outline' />
                                                        }
                                                    </div>
                                                )}
                                                renderSelectedOption={(option) => (
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        {!option.active &&
                                                                <i className='text-warning mdi mdi-alert-outline mr-2' />
                                                        }
                                                        {option.label}
                                                    </div>
                                                )}
                                                onOptionSelected={options.stopEditing}
                                            />
                                        );

                                    }}
                                </FormikEditable>
                                <Collapse isOpen={this.roles?.find((role) => role.role_id === this.props.selectedRows[0].role_id)?.active === "0"}>
                                    <div className="font-medium small text-warning"><Translate id="organization.profile.users.sidePanelUsers.userInfo.role.inactive.warning" /></div>
                                </Collapse>
                            </div>

                            <div className="mb-3">
                                <div className="text-muted"><Translate id='organization.profile.users.sidePanelUsers.userInfo.additional_roles' /></div>
                                <FormikEditable
                                    id='additional_role'
                                    clearable
                                    disabled={!this.props.rolesContext.canDoAction("EDIT", "organization", "organization_users") || this.props.identityRolesContext.identity_role_id === this.props.getCommonValue('identity_role_id')}
                                    noConfirmation
                                    initialValues={{
                                        additional_role: this.props.selectedRows[0].additional_role?.map((addRole) => addRole.role.role_id) || [],
                                    }}
                                    onSubmit={(values) => {
                                        // To prevent updating everytime we open the select and click outside without changing the value
                                        if(JSON.stringify(values.additional_role) !== JSON.stringify(this.props.selectedRows[0].additional_role?.map((addRole) => addRole.role.role_id) || [])){
                                            this.setState(() => ({ isLoading: true }))
                                            // The Select uses the role id as a value, but since we want the role title to change in the table we need to set it this way
                                            const newAddRoles = (values.additional_role && values.additional_role.length > 0)
                                                ?
                                                {
                                                    "additional_role": values.additional_role.reduce((newArray, addRole) => {
                                                        if(this.roles.find((role) => role.role_id === addRole)){
                                                            newArray.push({
                                                                new_role: true,
                                                                role: this.roles.find((role) => role.role_id === addRole),
                                                            })
                                                        }
                                                        return newArray
                                                    }, []),
                                                }
                                                :
                                                (values.additional_role && values.additional_role.length === 0)
                                                    ? { "additional_role": [] } // if additional_role exists and has a length of 0 (no additional roles), then send an empty array to the additional role key in the object
                                                    : []

                                            const newValues = this.props.createNewValues(newAddRoles);
                                            this.props.identityRolesContext.updateIdentityRole(this.getIdentityRoleId(), newValues)
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    success();
                                                    this.setState(() => ({ isLoading: false }))
                                                }).catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                    this.setState(() => ({ isLoading: false }))
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options, formik) => {
                                        if(!isEditing){
                                            return (
                                                <div className='font-medium'>
                                                    {this.props.selectedRows[0].additional_role && Array.isArray(this.props.selectedRows[0].additional_role) && this.props.selectedRows[0].additional_role.length > 0 ?
                                                        <ul className='mb-0' style={{ 'paddingInlineStart': "20px" }}>
                                                            {this.props.selectedRows[0].additional_role.map((addRole) => (
                                                                <li key={addRole.role.role_id}>{addRole.role.title}</li>
                                                            ))}
                                                        </ul>
                                                        :
                                                        '-'
                                                    }
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                name='additional_role' id='additional_role'
                                                menuIsDefaultOpen autoFocus multi
                                                clearable
                                                defaultData={this.roles.map((role) => ({
                                                    label: role.title,
                                                    value: role.role_id,
                                                    active: role.active === '1',
                                                }))}
                                                loadingStatus='success'
                                                renderOption={(option) => (
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        {option.option.label}
                                                        {!option.option.active &&
                                                            <i className='text-warning mdi mdi-alert-outline' />
                                                        }
                                                    </div>
                                                )}
                                                renderSelectedOption={(option) => (
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        {!option.active &&
                                                            <i className='text-warning mdi mdi-alert-outline mr-2' />
                                                        }
                                                        {option.label}
                                                    </div>
                                                )}
                                            />
                                        );

                                    }}
                                </FormikEditable>
                            </div>

                            {/* STATUS */}
                            <div className="mb-3">
                                <div className="text-muted"><Translate id='organization.profile.users.sidePanelUsers.userInfo.status' /></div>
                                <FormikEditable
                                    id='active'
                                    disabled={!this.props.rolesContext.canDoAction("EDIT", "organization", "organization_users") || this.props.identityRolesContext.identity_role_id === this.props.getCommonValue('identity_role_id')}
                                    noConfirmation
                                    initialValues={{
                                        active: this.props.getCommonValue("active"),
                                    }}
                                    validationSchema={object().shape({
                                        active: string().required(<Translate id='organization.profile.users.sidePanelUsers.userInfo.status.required' />),
                                    })}
                                    onSubmit={async(values) => {
                                        if(values.active !== '-' && values.active !== this.props.getCommonValue("active")){
                                            this.setState(() => ({ isLoading: true }))
                                            const newValues = this.props.createNewValues(values);
                                            return this.props.identityRolesContext.updateIdentityRole(this.getIdentityRoleId(), newValues)
                                                .then(() => {
                                                    this.props.syncRows(newValues);
                                                    success();
                                                    this.setState(() => ({ isLoading: false }))
                                                }).catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                    this.setState(() => ({ isLoading: false }))
                                                })
                                        }
                                    }}
                                >
                                    {(isEditing, options) => {
                                        if(!isEditing){
                                            return (
                                                <div className="font-medium">
                                                    {(this.props.getCommonValue("active") === "-") ?
                                                        <span className="mx-2">-</span>
                                                        :
                                                        <>
                                                            <i className={`mdi ${this.props.getCommonValue("active") == '1' ? 'mdi-check text-primary' : 'mdi-close text-danger'} mr-2`} />
                                                            <span><Translate id={`organization.profile.users.sidePanelUsers.userInfo.${this.props.getCommonValue("active") == '1' ? 'active' : 'inactive'}`} /></span>
                                                        </>
                                                    }
                                                </div>
                                            )
                                        }
                                        return (
                                            <FormikSelect
                                                autoFocus
                                                menuIsDefaultOpen
                                                name="active"
                                                id="active"
                                                onOptionSelected={() => {
                                                    options.stopEditing();
                                                }}
                                                defaultData={[
                                                    { value: '1', label: 'organization.profile.users.sidePanelUsers.userInfo.active' },
                                                    { value: '0', label: 'organization.profile.users.sidePanelUsers.userInfo.inactive' },
                                                ]}
                                                renderOption={(option) => <Translate id={option.option.label} />}
                                                loadingStatus='success'
                                            />
                                        )

                                    }}
                                </FormikEditable>
                            </div>

                            {/* START DATE / END DATE */}
                            <div className='mb-3'>
                                <div className='text-muted'><Translate id='organization.profile.users.sidePanelUsers.userInfo.dates' /></div>
                                <FormikEditable
                                    id='date'
                                    disabled={!this.props.rolesContext.canDoAction("EDIT", "organization", "organization_users") || this.props.identityRolesContext.identity_role_id === this.props.getCommonValue('identity_role_id')}
                                    manualIcon
                                    initialValues={{
                                        start_date: this.props.getCommonValue('start_date') ? moment(this.props.getCommonValue('start_date')) : '',
                                        end_date: this.props.getCommonValue('end_date') ? moment(this.props.getCommonValue('end_date')) : '',
                                    }}
                                    validationSchema={object().shape({
                                        start_date: mixed()
                                            .test({
                                                name: 'start_date_format',
                                                message: <Translate id='organization.profile.users.sidePanelUsers.userInfo.startDate.format.validation' />,
                                                test: (value) => {
                                                    if(value !== undefined){
                                                        return moment.isMoment(value);
                                                    }
                                                    return true; // because not required

                                                },
                                            })
                                            .when('end_date', {
                                                is: (end_date) => end_date !== undefined && moment.isMoment(end_date),
                                                then: mixed()
                                                    .test({
                                                        name: 'start_date_end_date_check',
                                                        message: <Translate id='organization.profile.users.sidePanelUsers.userInfo.dates.validation' />,
                                                        test: function(value){
                                                            if(value === undefined){
                                                                return true
                                                            }
                                                            return moment(value).isBefore(this.parent.end_date)

                                                        },
                                                    }),
                                            }),
                                        end_date: mixed()
                                            .test({
                                                name: 'end_date_format',
                                                message: <Translate id='organization.profile.users.sidePanelUsers.userInfo.endDate.format.validation' />,
                                                test: (value) => {
                                                    if(value !== undefined){
                                                        return moment.isMoment(value);
                                                    }
                                                    return true; // because not required

                                                },
                                            }),
                                    })}
                                    onSubmit={(values) => {
                                        this.setState(() => ({ isLoading: true }))
                                        // either ISO date values or null
                                        const APIReadyValues = {
                                            start_date: values.start_date ? moment(values.start_date).toISOString() : null,
                                            end_date: values.end_date ? moment(values.end_date).toISOString() : null,
                                        }

                                        const newValues = this.props.createNewValues(APIReadyValues);
                                        this.props.identityRolesContext.updateIdentityRole(this.getIdentityRoleId(), newValues)
                                            .then(() => {
                                                this.props.syncRows(newValues);
                                                success();
                                                this.setState(() => ({ isLoading: false }))
                                            }).catch((error) => {
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                                this.setState(() => ({ isLoading: false }))
                                            })
                                    }}
                                >
                                    {(isEditing, _, formik) => {
                                        if(!isEditing){
                                            return (
                                                <div className='ml-3'>
                                                    <div>
                                                        <span className='text-muted'><Translate id='organization.profile.users.sidePanelUsers.userInfo.from' /> - </span>
                                                        {this.props.getCommonValue('start_date') ?
                                                            moment(this.props.getCommonValue('start_date')).locale(this.props.I18nContext.getGenericLocale()).format('LL')
                                                            :
                                                            <Translate id='organization.profile.users.sidePanelUsers.userInfo.dates.none' />
                                                        }
                                                        {this.props.identityRolesContext.identity_role_id !== this.props.getCommonValue('identity_role_id') &&
                                                            <i className='mdi mdi-pencil text-primary ml-2' />
                                                        }
                                                    </div>
                                                    <div>
                                                        <span className='text-muted'><Translate id='organization.profile.users.sidePanelUsers.userInfo.until' /> - </span>
                                                        {this.props.getCommonValue('end_date') ?
                                                            moment(this.props.getCommonValue('end_date')).locale(this.props.I18nContext.getGenericLocale()).format('LL')
                                                            :
                                                            <Translate id='organization.profile.users.sidePanelUsers.userInfo.dates.none' />
                                                        }
                                                        {this.props.identityRolesContext.identity_role_id !== this.props.getCommonValue('identity_role_id') &&
                                                            <i className='mdi mdi-pencil text-primary ml-2' />
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return (
                                            <div className='ml-3'>
                                                <FormGroup className='flex-column mb-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <Label for='start_date' className='text-muted mb-0 mr-2'><Translate id='organization.profile.users.sidePanelUsers.userInfo.from' /></Label>
                                                        <FormikDateTime
                                                            id='start_date'
                                                            name='start_date'
                                                            timeFormat={false}
                                                            onChange={(value) => {
                                                                formik.setFieldTouched('start_date', true, false);
                                                            }}
                                                            manualError
                                                        />
                                                    </div>
                                                    <FormikError name='start_date' />
                                                </FormGroup>
                                                <FormGroup className='flex-column'>
                                                    <div className='d-flex align-items-center'>
                                                        <Label for='end_date' className='text-muted mb-0 mr-2'><Translate id='organization.profile.users.sidePanelUsers.userInfo.until' /></Label>
                                                        <FormikDateTime
                                                            id='end_date'
                                                            name='end_date'
                                                            timeFormat={false}
                                                            onChange={(value) => {
                                                                formik.setFieldTouched('end_date', true, false);
                                                                formik.setFieldTouched('start_date', true, false); // to trigger the validation for start_date < end_date
                                                            }}
                                                            manualError
                                                        />
                                                    </div>
                                                    <FormikError name='end_date' />
                                                </FormGroup>
                                            </div>
                                        )

                                    }}
                                </FormikEditable>
                            </div>

                            {/* Last Login */}
                            <div className='mb-3'>
                                <div className="text-muted"><Translate id='form.fields.lastConnection' /></div>
                                <div className="font-medium">
                                    {this.getUserValue("last_connection") ?
                                        moment(this.getUserValue("last_connection")).locale(this.props.I18nContext.getGenericLocale()).format('LLL')
                                        :
                                        <Translate id='organization.profile.users.sidePanelUsers.userInfo.lastConnection.none' />
                                    }
                                </div>
                            </div>

                            {/* NAME */}
                            <div className='mb-3'>
                                <div className="text-muted"><Translate id='form.fields.name' /></div>
                                <div className="font-medium">
                                    {this.getUserValue("name")} {this.getUserValue("family_name")}
                                </div>
                            </div>

                            {/* EMAIL */}
                            <div className='mb-3'>
                                <div className="text-muted"><Translate id='form.fields.email' /></div>
                                <div className="font-medium">
                                    {this.getUserValue("email")}
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <SidePanelMemberProfile />
                    </TabPane>
                    <TabPane tabId="3">
                        <div className="p-3">
                            3
                        </div>
                    </TabPane>
                </TabContent>
            </OverlayLoader>
        )
    }
}

SidePanelUsers.propTypes = {
    tableRef: PropTypes.object.isRequired,
    authContext: PropTypes.object.isRequired,
    identityRolesContext: PropTypes.object.isRequired,
    rolesContext: PropTypes.object.isRequired,
    organizationContext: PropTypes.object.isRequired,
}

export default withContext(I18nContext, AuthContext)(SidePanelUsers);
