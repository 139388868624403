import SpordleTableProvider, { Refresh, SearchInput, SpordleTableContext, SpordleTableView } from '@spordle/datatables';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import SpordleSelect from '@spordle/spordle-select';
import React from 'react';
import {
    Button, Card,
    CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown
} from "reactstrap";
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import CanDoAction from '../../../components/permissions/CanDoAction';
import SpordlePanelTable from '../../../components/sidePanel/SpordlePanel';
import EmptyLayout from '../../../components/table/EmptyLayout';
import { fail } from "@spordle/toasts";
import { AffiliationFeesContext } from '../../../contexts/AffiliationFeesContext';
import { I18nContext } from '../../../contexts/I18nContext';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../contexts/MembersContext';
import { AppContext } from '../../../contexts/contexts';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { DisplayI18n, displayI18n } from '../../../helpers/i18nHelper';
import { PeriodChangeHandler } from '../../../helpers/periodHelper';
import withContexts from '../../../helpers/withContexts';
import AffiliationFeesAdd from './components/AffiliationFeesAdd';
import AffiliationFeesSidepanel from './components/AffiliationFeesSidepanel';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { Tooltip } from '@mantine/core';
import { RegistrationDivisionsContext } from '../../../contexts/RegistrationDivisionsContext';
import { exportToCsv } from '../../../helpers/export';
import { injectIntl } from 'react-intl';
import { roundForMoney } from '../../../helpers/helper';
import SpordlePanelControl from '../../../components/sidePanel/SpordlepanelControl';
import OverlayWarning from '../../../components/overlayWarning/OverlayWarning';
import { RolesContext } from '../../../contexts/RolesContext';
import styles from './AffiliationFees.module.scss';


/**
* Membership Fees
*/
class AffiliationFees extends React.Component{
    constructor(props){
        super(props);

        // Detect view mode // TODO: Replace with api "tags". When they implement this we should be able to use enum style verification
        let viewMode = "CHILD";
        if(this.props.OrganizationContext?.organisation_category?.organisation_category_id === "1eb5d97a-ffc7-6bbe-a381-06ba8f7ba4e0")
            viewMode = "FEDERATION"
        else if(this.props.OrganizationContext?.organisation_category?.organisation_category_id === "1eb5d983-c162-660e-b2fb-06ba8f7ba4e0")
            viewMode = "REGION"
        else if(this.props.OrganizationContext?.organisation_category?.organisation_category_id === "1eb5d981-f851-654a-97e0-06ba8f7ba4e0")
            viewMode = "BRANCH"

        this.state = {
            viewMode: viewMode, // FEDERATION, BRANCH, CHILD
            branchInfo: null, // Only used when we are in "CHILD" view
            regionInfo: null, // Only used when we are in "CHILD" view
            parentOrgId: null, // The direct parent - used for verifications

            addIsOpen: false,
            organizationChildrenSelect: null,
            memberTypeSelect: null,
        }

        this.canManage = props.RolesContext.canDoAction([ 'DELETE', 'EDIT' ], 'catalog', 'affiliation_fees') || props.RolesContext.isGod();
    }


    // State toggles
    toggleAdd = () => this.setState((prev) => ({ addIsOpen: !prev.addIsOpen }));


    defaultColumns = [
        {
            // yes keep this
            label: '-',
            key: 'checkbox',
            className: 'th-shrink',
            dataClassName: 'td-shrink',
        },
        {
            label: (_1, _2, isExport) => {
                if(isExport)
                    return this.props.intl.formatMessage({ id: 'catalog.affiliationFees.division' })
                return (<div className="d-flex flex-column"><div><Translate id='catalog.affiliationFees.division' /></div><div className="small">&nbsp;</div></div>)
            },
            key: 'division',
            sortKey: `division.i18n.${this.props.I18nContext.getGenericLocale()}.short_name`,
            sortable: true,
        },
        {
            label: (_1, _2, isExport) => {
                if(isExport)
                    return this.props.intl.formatMessage({ id: 'catalog.affiliationFees.memberType' })
                return (<div className="d-flex flex-column"><div><Translate id='catalog.affiliationFees.memberType' /></div><div className="small">&nbsp;</div></div>)
            },
            key: 'memberType',
            sortable: true,
        },
    ];

    setupParentOrg = (affiliationFees) => {
        // Set to have unique array of organization IDs
        const parentOrganizationIds = new Set()
        const parents = [];

        for(let i = 0; i < affiliationFees.length; i++){
            const affiliationFee = affiliationFees[i];
            for(let j = 0; j < affiliationFee.organisation_parent_fees.length; j++){
                const parent = affiliationFee.organisation_parent_fees[j];
                parents.push(parent.organisation)
                parentOrganizationIds.add(parent.organisation.organisation_id)
            }
        }

        this.setState(() => ({ parentOrgId: Array.from(parentOrganizationIds)[parentOrganizationIds.size - 1] }))
    }

    // Get Table Columns depending on viewMode
    getTableColumns = (affiliationFees) => {
        if(this.state.viewMode === 'FEDERATION'){
            return [
                {
                    label: () => this.props.intl.formatMessage({ id: 'catalog.affiliationFees.organisation' }),
                    key: 'organisation',
                    sortable: true,
                },
                {
                    label: () => this.props.intl.formatMessage({ id: 'catalog.affiliationFees.division' }),
                    key: 'division',
                    sortKey: `division.i18n.${this.props.I18nContext.getGenericLocale()}.short_name`,
                    sortable: true,
                },
                {
                    label: () => this.props.intl.formatMessage({ id: 'catalog.affiliationFees.memberType' }),
                    key: 'memberType',
                    sortable: true,
                },
                {
                    label: () => this.props.intl.formatMessage({ id: 'catalog.affiliationFees.membershipFees' }),
                    key: 'orgFee',
                    dataClassName: 'font-medium',
                    sortable: true,
                },
                {
                    label: () => this.props.intl.formatMessage({ id: 'catalog.affiliationFees.status' }),
                    key: 'active',
                    dataClassName: 'text-center',
                    sortable: true,
                },
            ];
        }

        // Set to have unique array of organization IDs
        const parentOrganizationIds = new Set()
        const parents = [];

        for(let i = 0; i < affiliationFees.length; i++){
            const affiliationFee = affiliationFees[i];
            for(let j = 0; j < affiliationFee.organisation_parent_fees.length; j++){
                const parent = affiliationFee.organisation_parent_fees[j];
                parents.push(parent.organisation)
                parentOrganizationIds.add(parent.organisation.organisation_id)
            }
        }

        const columns = this.defaultColumns;

        // we need to go throught parent orgs
        Array.from(parentOrganizationIds).forEach((orgId, index) => {
            const org = parents.find((org) => org.organisation_id === orgId)
            columns.push({
                label: (_1, _2, isExport) => {
                    if(isExport)
                        return displayI18n('name', org.i18n, org.organisation_name, this.props.I18nContext.getGenericLocale())
                    return <div className="d-flex flex-column"><div><Translate id='catalog.affiliationFees.membershipFees' /></div><div className="text-muted small"><DisplayI18n field='name' defaultValue={org.organisation_name} i18n={org.i18n} /></div></div>
                },
                key: `parent_${orgId}`,
                sortKey: `organisation_parent_fees.${index}.fee.amount`,
                sortable: true,
                hasTotal: true,
            })
        })


        columns.pushArray([
            {
                label: (_1, _2, isExport) => {
                    if(isExport)
                        return displayI18n('name', this.props.OrganizationContext?.i18n, this.props.OrganizationContext.organisation_name, this.props.I18nContext.getGenericLocale())
                    return (
                        <Tooltip withArrow label={<Translate id='catalog.affiliationFees.membershipFees.myOrganisationColumn' />}>
                            <div className="d-flex flex-column">
                                <div>
                                    <Translate id='catalog.affiliationFees.membershipFees' />
                                </div>
                                <div className="small text-primary">
                                    <DisplayI18n field='name' defaultValue={this.props.OrganizationContext.organisation_name} i18n={this.props.OrganizationContext?.i18n} />
                                </div>
                            </div>
                        </Tooltip>
                    )
                },
                key: 'orgFee',
                dataClassName: 'font-medium',
                sortable: true,
                hasTotal: true,
            },
            {
                label: (_1, _2, isExport) => {
                    if(isExport)
                        return this.props.intl.formatMessage({ id: 'catalog.affiliationFees.status' })
                    return <div className="d-flex flex-column"><div><Translate id='catalog.affiliationFees.status' /></div><div className="small">&nbsp;</div></div>
                },
                key: 'active',
                dataClassName: 'text-center',
                sortable: true,
            },
        ])

        return columns;
    }

    getOrganizationParentFee = (id, organisation_parent_fees) => {
        const fee = organisation_parent_fees.filter((fee) => id === fee?.organisation?.organisation_id)
        let myFee = fee?.[0]?.fee?.amount;

        if(isNaN(myFee))
            myFee = 0;
        return (myFee / 100);
    }

    componentDidMount(){
        // Get Branch info if we are are "CHILD" viewMode
        if(this.state.viewMode === "CHILD" || this.state.viewMode === "REGION"){
            this.props.OrganizationContext.getOrganizationBranch(null, true)
                .then((branchInfo) => {
                    this.setState(() => ({ branchInfo: branchInfo }));
                })
                .catch(console.error)
            if(this.state.viewMode === "CHILD")
                this.props.OrganizationContext.getOrganizationRegion(null, true)
                    .then((regionInfo) => {
                        this.setState(() => ({ regionInfo: regionInfo }));
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })
        }

        // Load filters
        this.props.OrganizationContext.getOrganizationChildren(this.props.OrganizationContext.organisation_id)
            .then((organizations) => {
                const myOptions = [ {
                    value: 'ALL',
                    label: 'misc.all',
                } ];
                organizations
                    .sort((a, b) => {
                        const aOrder = displayI18n("name", a.i18n, a.organisation_name, this.props.I18nContext.getGenericLocale())
                        const bOrder = displayI18n("name", b.i18n, b.organisation_name, this.props.I18nContext.getGenericLocale())

                        if(aOrder > bOrder)
                            return 1;
                        else if(aOrder < bOrder)
                            return -1;
                        return 0;
                    })
                    .forEach((organization) => (
                        myOptions.push({
                            value: organization.organisation_id,
                            label: organization.organisation_name,
                            i18n: organization.i18n,
                        })
                    ))

                this.setState(() => ({ organizationChildrenSelect: myOptions }));
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })

        this.props.MembersContext.getMemberTypes(this.props.OrganizationContext.organisation_id)
            .then((memberTypes) => {
                const myOptions = [ {
                    value: 'ALL',
                    label: 'misc.all',
                } ];

                memberTypes.forEach((type) => (
                    myOptions.push({
                        id: type.member_type_id,
                        value: type.member_type_id,
                        label: type.name,
                        i18n: type.i18n,
                    })
                ))
                this.setState(() => ({ memberTypeSelect: myOptions }));
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    formatData = (fees) => {
        if(Array.isArray(fees)){
            return fees.reduce((builtArray, fee, index) => {
                // Depending on view mode, we will need to expose a affiliation_price_id for the table
                if(fee.division){
                    builtArray.push({
                        ...fee,
                        dataIndex: fee.division.division_id + fee.member_type.member_type_id,
                        //affiliation_fee_id: fee?.organisation_parent_fees?.[0]?.affiliation_fee_id || null,
                        mhaParentFee: fee.organisation_parent_fees.reduce((total, fee) => total += (parseInt(fee.fee.amount) / 100), 0),
                        federationFee: this.getOrganizationParentFee(this.props.IdentityRolesContext.federation.organisation_id, fee.organisation_parent_fees) || 0,
                        branchFee: this.getOrganizationParentFee(this.state?.branchInfo?.organisation_id, fee.organisation_parent_fees) || 0,
                        regionFee: this.getOrganizationParentFee(this.state?.regionInfo?.organisation_id, fee.organisation_parent_fees) || 0,
                        orgFee: (fee.fee?.amount) ? (fee.fee.amount / 100) : null,
                        noCurrentParentFee: !fee.organisation_parent_fees.some((pFee) => pFee.organisation.organisation_id === this.state.parentOrgId),
                    })
                }
                return builtArray;
            }, [])
        }else if(fees.division){ // IN THIS CASE FEES IS AN OBJECT NOT ARRAY (FROM SIDEPANEL UPDATE)
            return ({
                ...fees,
                dataIndex: fees.division.division_id + fees.member_type.member_type_id,
                //affiliation_fee_id: fees?.organisation_parent_fees?.[0]?.affiliation_fee_id || null,
                mhaParentFee: fees.organisation_parent_fees.reduce((total, fee) => total += (parseInt(fee.fee.amount) / 100), 0),
                federationFee: this.getOrganizationParentFee(this.props.IdentityRolesContext.federation.organisation_id, fees.organisation_parent_fees) || 0,
                branchFee: this.getOrganizationParentFee(this.state?.branchInfo?.organisation_id, fees.organisation_parent_fees) || 0,
                regionFee: this.getOrganizationParentFee(this.state?.regionInfo?.organisation_id, fees.organisation_parent_fees) || 0,
                orgFee: (fees.fee?.amount) ? (fees.fee.amount / 100) : null,
                noCurrentParentFee: !fees.organisation_parent_fees.some((pFee) => pFee.organisation.organisation_id === this.state.parentOrgId),
            })
        }
    }


    render(){
        return (
            <>
                {/* Catalog View Header */}
                <ViewHeaderV2
                    title={<Translate id='catalog.affiliationFees.title' />}
                    skipTranslate
                />
                <div className={"px-4"}>
                    <Card className="card-shadow">
                        <CardBody>
                            <SpordlePanelTable
                                allowOutsideClick
                                dataIndex="dataIndex"
                                sidePanel={(props) => <AffiliationFeesSidepanel {...props} viewMode={this.state.viewMode} formatData={this.formatData} />}
                                table={(panelProps) => (
                                    <SpordleTableProvider
                                        id='affiliationFeesCatalogTable'
                                        tableHover bordered striped
                                        clickable
                                        dataIndex="dataIndex"
                                        ref={panelProps.spordleTableRef}
                                        desktopWhen
                                        emptyLayout={<EmptyLayout />}
                                        defaultSorting={"+division"}
                                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                        searchKeys={[
                                            `apply_for_organisation.i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                            `apply_for_organisation.organisation_name`,
                                            `division.i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                            `division.name`,
                                            `division.i18n.${this.props.I18nContext.getGenericLocale()}.short_name`,
                                            `division.short_name`,
                                            `member_type.i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                            `fee.i18n.${this.props.I18nContext.getGenericLocale()}.description`,
                                            `fee.description`,
                                            `fee.i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                            `fee.name`,
                                            `member_type.name`,
                                            'orgFee',
                                        ]}
                                        initFilter={{
                                            organisationId: "",
                                            memberTypeId: "",
                                            division_id: "",
                                        }}
                                        loadData={(from, filterData, spordleTable) => {
                                            switch (from){
                                                case 'CDM':
                                                    return this.props.AffiliationFeesContext.getAffiliationFees()
                                                        .then((fees) => {
                                                            this.setupParentOrg(fees);

                                                            const formattedData = this.formatData(fees);

                                                            spordleTable.setColumns(this.getTableColumns(formattedData));

                                                            return formattedData;
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                case 'REFRESH':
                                                case 'FILTER':
                                                    spordleTable.setLoading(true);
                                                    const queryParams = {};
                                                    if(this.props.IdentityRolesContext.federation.organisation_id === this.props.OrganizationContext.organisation_id){
                                                        if(filterData.filters.organisationId && filterData.filters.organisationId !== 'ALL')
                                                            queryParams.apply_for_organisation_id = filterData.filters.organisationId
                                                    }
                                                    if(filterData.filters.memberTypeId && filterData.filters.memberTypeId !== 'ALL')
                                                        queryParams.member_type_id = filterData.filters.memberTypeId
                                                    if(filterData.filters.division_id)
                                                        queryParams.division_id = filterData.filters.division_id

                                                    return this.props.AffiliationFeesContext.getAffiliationFees(queryParams)
                                                        .then((fees) => (this.formatData(fees)))
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                            }
                                                        })
                                                default:
                                                    break;
                                            }
                                        }}
                                        columns={this.defaultColumns}
                                        renderRow={(columnKey, affiliationFee) => {
                                            if(columnKey.indexOf('parent_') !== -1){
                                                const parent = affiliationFee.organisation_parent_fees.find((parent) => parent.organisation.organisation_id === columnKey.split('_')[1])
                                                if(parent?.fee.amount)
                                                    return (
                                                        <CurrencyFormat value={parent?.fee.amount / 100} />
                                                    )
                                                return ' - '
                                            }
                                            switch (columnKey){
                                                case 'checkbox':
                                                    return (
                                                        <label className='my-auto' htmlFor={'checked-' + affiliationFee.dataIndex} onClick={(e) => { e.stopPropagation() }}>
                                                            <input
                                                                id={'checked-' + affiliationFee.dataIndex} type="checkbox" checked={!!affiliationFee.checked}
                                                                onClick={() => { panelProps.onMultiSelectChange(affiliationFee); }}
                                                                onChange={function(){}}// This needs to be here to remove the console error
                                                            />
                                                            <span className="table-checkbox" />
                                                        </label>
                                                    )
                                                case 'organisation':
                                                    return (
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={affiliationFee.apply_for_organisation?.organisation_name}
                                                            i18n={affiliationFee.apply_for_organisation?.i18n}
                                                        />
                                                    )
                                                case 'division':
                                                    return (
                                                        (affiliationFee.division ?
                                                            <div>
                                                                <DisplayI18n
                                                                    field='short_name'
                                                                    defaultValue={affiliationFee.division?.short_name}
                                                                    i18n={affiliationFee.division?.i18n}
                                                                />
                                                                {(affiliationFee.division.birth_year_from && affiliationFee.division.birth_year_to) &&
                                                                    <div className='small text-muted'>
                                                                        {affiliationFee.division.birth_year_from != affiliationFee.division.birth_year_to ?
                                                                            <Translate id='catalog.affiliationFees.division.birthYear' values={{ minYear: affiliationFee.division.birth_year_from, maxYear: affiliationFee.division.birth_year_to }} />
                                                                            :
                                                                            <><Translate id='registration.settings.category.label.birthYear' /> {affiliationFee.division.birth_year_from}</>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            : '-')
                                                    )
                                                case 'mhaParentFee':
                                                    return <CurrencyFormat value={affiliationFee.mhaParentFee} />; // formatted in loadData
                                                //return <CurrencyFormat value={affiliationFee.organisation_parent_fees.reduce((total, fee) => total += (parseInt(fee.fee.amount) / 100), 0)} />
                                                case 'federationFee':
                                                    return <CurrencyFormat value={affiliationFee.federationFee} />; // formatted in loadData
                                                //return <CurrencyFormat value={this.getOrganizationParentFee(this.props.IdentityRolesContext.federation.organisation_id, affiliationFee.organisation_parent_fees) || 0} />
                                                case 'branchFee':
                                                    return <CurrencyFormat value={affiliationFee.branchFee} />; // formatted in loadData
                                                //return <CurrencyFormat value={this.getOrganizationParentFee(this.state?.branchInfo?.organisation_id, affiliationFee.organisation_parent_fees) || 0} /> // TODO: Get organisation branch id for here
                                                case 'regionFee':
                                                    return <CurrencyFormat value={affiliationFee.regionFee} />; // formatted in loadData
                                                //return <CurrencyFormat value={this.getOrganizationParentFee(this.state?.regionInfo?.organisation_id, affiliationFee.organisation_parent_fees) || 0} /> // TODO: Get organisation branch id for here
                                                case 'orgFee':
                                                    return affiliationFee.orgFee === null ? '-' : <CurrencyFormat value={affiliationFee.orgFee} />; // formatted in loadData
                                                //return (affiliationFee.fee?.amount) ? <CurrencyFormat value={affiliationFee.fee.amount / 100} /> : '-';
                                                case 'memberType':
                                                    return (
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={affiliationFee.member_type.name}
                                                            i18n={affiliationFee.member_type.i18n}
                                                        />
                                                    )
                                                case 'active':
                                                    return (
                                                        affiliationFee.active === "1" ?
                                                            <i className="text-primary mdi mdi-check" />
                                                            :
                                                            <i className="text-danger mdi mdi-close" />
                                                    );
                                                // return affiliationFee.active === "1" ? <Translate id='misc.active' /> : <Translate id='misc.inactive' />;
                                                default:
                                                    break;
                                            }
                                        }}
                                        onColumnClick={(e, data, _spordleTable, columnKey) => {
                                            switch (e.button){
                                                case 0: // Left mouse button
                                                    if(columnKey === 'checkbox'){
                                                        panelProps.onMultiSelectChange(data);
                                                    }else{
                                                        panelProps.onSingleSelectChange(data);
                                                    }
                                                    break;
                                            }
                                        }}
                                        rowIsHighlighted={(affilationFee) => !!affilationFee.checked}
                                    >
                                        {(spordleTable) => (

                                            <OverlayWarning
                                                storageKey='affiliationFeesWarning'
                                                text={(
                                                    <>
                                                        <p><Translate id='catalog.affiliationFees.help' /></p>
                                                        <strong className="d-block mb-3"><Translate id="catalog.affiliationFees.warning" /></strong>
                                                        <p className="mb-3"><Translate id="catalog.affiliationFees.warningContact" /></p>
                                                    </>
                                                )}
                                                showWarning={this.canManage && spordleTable.state.loadingState !== 'loading' && spordleTable.getData().length > 0}
                                                className={styles.overlayWarning}
                                            >
                                                <div className='pb-2'>
                                                    <div className='d-flex flex-wrap justify-content-between'>
                                                        <PeriodChangeHandler useRefreshTable />
                                                        <SpordleTableContext.Consumer>
                                                            {(spordleTable) => (
                                                                <>
                                                                    {/* Add modal that will update spordle table */}
                                                                    {this.state.addIsOpen &&
                                                                        <AffiliationFeesAdd
                                                                            isOpen={this.state.addIsOpen}
                                                                            toggle={this.toggleAdd}
                                                                            spordleTable={spordleTable}
                                                                            viewMode={this.state.viewMode}
                                                                            organizationChildrenOptions={this.state.organizationChildrenSelect}
                                                                            memberTypeSelectOptions={this.state.memberTypeSelect}
                                                                        />
                                                                    }
                                                                    <SpordlePanelControl />
                                                                    <div className='mr-2'>
                                                                        <SearchInput />
                                                                    </div>

                                                                    {this.state.viewMode === 'FEDERATION' &&
                                                                    <div className="mr-2" style={{ minWidth: "200px" }}>
                                                                        <SpordleSelect
                                                                            defaultValues={[ 'ALL' ]}
                                                                            isLoading={!this.state.organizationChildrenSelect}
                                                                            name='organizationChildrenSelect'
                                                                            id='organizationChildrenSelect'
                                                                            onOptionSelected={([ value ]) => {
                                                                                spordleTable.filterChange("organisationId", value)
                                                                            }}
                                                                            renderOption={({ option }) => {
                                                                                if(option.value === 'ALL'){
                                                                                    return <Translate id='catalog.affiliationFees.allBranches' />;
                                                                                }
                                                                                return (
                                                                                    <DisplayI18n
                                                                                        field='name'
                                                                                        defaultValue={option.label}
                                                                                        i18n={option.i18n}
                                                                                    />
                                                                                )

                                                                            }}
                                                                            options={this.state.organizationChildrenSelect ?? []}
                                                                        />
                                                                    </div>
                                                                    }
                                                                    <div className="mr-2" style={{ minWidth: "200px" }}>
                                                                        <SpordleSelect
                                                                            defaultValues={[ 'ALL' ]}
                                                                            isLoading={!this.state.memberTypeSelect}
                                                                            name='memberType'
                                                                            id='memberType'
                                                                            onOptionSelected={([ value ]) => {
                                                                                spordleTable.filterChange("memberTypeId", value)
                                                                            }}
                                                                            renderOption={({ option }) => {
                                                                                if(option.value === 'ALL'){
                                                                                    return <Translate id='catalog.affiliationFees.allTypes' />;
                                                                                }
                                                                                return (
                                                                                    <DisplayI18n
                                                                                        field='name'
                                                                                        defaultValue={option.label}
                                                                                        i18n={option.i18n}
                                                                                    />
                                                                                )

                                                                            }}
                                                                            options={this.state.memberTypeSelect ?? []}
                                                                        />
                                                                    </div>
                                                                    <div className="mr-2" style={{ minWidth: "200px" }}>
                                                                        <SpordleSelect
                                                                            name='division_id'
                                                                            id='division_id_aff_fees'
                                                                            placeholder='reports.filters.division'
                                                                            clearable
                                                                            onOptionSelected={([ value ]) => {
                                                                                spordleTable.filterChange("division_id", value)
                                                                            }}
                                                                            loadData={(from) => {
                                                                                switch (from){
                                                                                    case 'CDM':
                                                                                        return this.props.RegistrationDivisionsContext.getRegistrationDivisions(this.props.IdentityRolesContext.federation.organisation_id)
                                                                                            .then((divisions) => divisions.map((div) => ({
                                                                                                id: div.division_id,
                                                                                                value: div.division_id,
                                                                                                label: div.name,
                                                                                                i18n: div.i18n,
                                                                                            }))).catch((error) => {
                                                                                                if(!AxiosIsCancelled(error.message)){
                                                                                                    console.error(error.message)
                                                                                                }
                                                                                            })
                                                                                }
                                                                            }}
                                                                            renderOption={({ option }) => {
                                                                                return (
                                                                                    <DisplayI18n
                                                                                        field='name'
                                                                                        defaultValue={option.label}
                                                                                        i18n={option.i18n}
                                                                                    />
                                                                                )
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='d-flex ml-auto align-items-end text-right nowrap'>
                                                                        <Refresh />
                                                                        <UncontrolledButtonDropdown className='ml-2'>
                                                                            <DropdownToggle color='primary' outline caret>
                                                                                <i className='mdi mdi-download mr-1' /><Translate id='misc.export' />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem onClick={() => {
                                                                                // the empy objects are because otherwise in render we always get 'isExport true' as the first parameter sent for rendering a column header is an empty object
                                                                                    const data = spordleTable.filteredData
                                                                                    exportToCsv(
                                                                                    `filename.csv`,
                                                                                    [
                                                                                        // slice(1) is to remove the checkmark column
                                                                                        spordleTable.getColumns().slice(1).map((column) => (column.label({}, {}, true))), // column header
                                                                                        ...data.map((row) => {
                                                                                            return spordleTable.getColumns().slice(1).map((column) => {
                                                                                                if(column.key.includes('parent_')){
                                                                                                    return roundForMoney(row.organisation_parent_fees.find((parentOrg) => parentOrg.organisation.organisation_id === column.key.split('_')[1])?.fee?.amount) || ' - '
                                                                                                }
                                                                                                switch (column.key){
                                                                                                    case 'division':
                                                                                                        return displayI18n('name', row.division.i18n, row.division.name, this.props.I18nContext.getGenericLocale())
                                                                                                    case 'memberType':
                                                                                                        return displayI18n('name', row.member_type.i18n, row.member_type.name, this.props.I18nContext.getGenericLocale())
                                                                                                    case 'orgFee':
                                                                                                        return row.fee?.amount ? roundForMoney(row.orgFee * 100) : ' - ';
                                                                                                    case 'organisation':
                                                                                                        return displayI18n('name', row.apply_for_organisation.i18n, row.apply_for_organisation.organisation_name, this.props.I18nContext.getGenericLocale())
                                                                                                    case 'active':
                                                                                                        return row.active == '1' ? this.props.intl.formatMessage({ id: 'misc.active' }) : this.props.intl.formatMessage({ id: 'misc.inactive' })
                                                                                                    default:
                                                                                                        return ' - '
                                                                                                }

                                                                                            })
                                                                                        }),
                                                                                    ],
                                                                                    )
                                                                                }}
                                                                                >
                                                                                    <i className='fas fa-file-excel' /> <Translate id='misc.excel' />
                                                                                </DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledButtonDropdown>
                                                                        <CanDoAction action='ADD' componentCode='catalog' componentPermissionCode='affiliation_fees'>
                                                                            {this.state.viewMode === 'FEDERATION' &&
                                                                            <Button className='ml-2' color='primary' onClick={this.toggleAdd}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                                            }
                                                                        </CanDoAction>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </SpordleTableContext.Consumer>
                                                    </div>
                                                </div>
                                                <SpordleTableView />
                                            </OverlayWarning>
                                        )}
                                    </SpordleTableProvider>
                                )}
                            />
                        </CardBody>
                    </Card>
                </div>
            </>
        );
    }
}

export default injectIntl(withContexts(I18nContext, OrganizationContext, IdentityRolesContext, AffiliationFeesContext, MembersContext, AppContext, RegistrationDivisionsContext, RolesContext)(AffiliationFees));
