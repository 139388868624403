import ArchivedMessages from "../../views/communications/ArchivedMessages";
import FavoriteMessages from "../../views/communications/FavoriteMessages";
import ReceivedMessages from "../../views/communications/ReceivedMessages";
import SentMessages from "../../views/communications/SentMessages";

const CommunicationsRoutes = [
    {
        title: 'communications.inbox',
    },
    {
        exact: true,
        path: '/received',
        name: "communications.inbox.received",
        skipRecents: true,
        component: ReceivedMessages,
    },
    {
        exact: true,
        path: '/sent',
        name: "communications.inbox.sent",
        skipRecents: true,
        component: SentMessages,
    },
    {
        exact: true,
        skipRecents: true,
        name: "communications.inbox.favorites",
        path: '/favorites',
        component: FavoriteMessages,
    },
    {
        exact: true,
        skipRecents: true,
        name: "communications.inbox.archived",
        path: '/archived',
        component: ArchivedMessages,
    },
    {
        redirect: true,
        from: '/communications',
        to: '/received',
    },
];

export default CommunicationsRoutes;