import Translate from '@spordle/intl-elements';
import { Link } from 'react-router-dom';
import { Label, ListGroup, ListGroupItem } from 'reactstrap';
import InlineCopy from '../../../../components/inlineCopy/InlineCopy';
import QuickViewActionButton from '../../../../components/quickView/QuickViewActionButton';
import QuickViewButton from '../../../../components/quickView/QuickViewButton';
import SidePanel from '../../../../components/sidePanel/SidePanel';
import UserImg from '../../../../components/UserImg';
import { stringBuilder } from '@spordle/helpers';
import RenewMembersModal from '../RenewMembersModal';
import { useDisclosure } from '@mantine/hooks';
import RenewMemberCard from './RenewMemberCard';

const RenewMembersSidePanel = (props) => {
    const [ isOpen, { toggle } ] = useDisclosure(false);

    return (
        <>
            <RenewMembersModal
                toggle={(isSuccess) => {
                    if(isSuccess){
                        props.toggle();
                    }
                    toggle();
                }}
                isOpen={isOpen}
                members={props.selectedRows}
                refreshTable={props.tableRef.refreshTable}
                memberTypeId={props.tableRef.getFilters().form.memberTypeId}
                divisionId={props.tableRef.getFilters().form.divisionId}
            />
            <SidePanel.Header noBorder className='sticky-top bg-white z-index-1'>
                <div className='d-flex align-items-center'>
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu>
                        <SidePanel.MenuAction
                            action="ADD" componentCode="organization" componentPermissionCode="affiliation_invoice"
                            skipAccess
                            onClick={toggle}
                        >
                            <Translate id='task.renewMembers.renew' />
                        </SidePanel.MenuAction>
                        <QuickViewActionButton member={props.selectedRows} skipDivider />
                    </SidePanel.ActionsMenu>
                </div>

                {props.selectedRows.length > 1 &&
                    <div className="d-flex align-items-center w-100 h4 mt-3 font-bold mb-0 text-break">
                        <QuickViewButton member={props.selectedRows}>
                            <Translate values={{ count: props.selectedRows.length }} id="misc.select.valueContainer.plural" />
                        </QuickViewButton>
                    </div>
                }
            </SidePanel.Header>
            {(props.selectedRows.length <= 1) ?
                <>
                    <div className="d-flex align-items-center px-3 pb-3 border-bottom">
                        <UserImg
                            alt={props.selectedRows[0].first_name + ' ' + props.selectedRows[0].last_name}
                            abbr={props.selectedRows[0].first_name?.charAt(0) + props.selectedRows[0].last_name?.charAt(0)}
                            src={props.selectedRows[0].picture?.full_path}
                            filePos={props.selectedRows[0].picture?.file_position}
                            width={60}
                            className="mr-2"
                        />
                        <div className="w-100">
                            <div className="d-flex align-items-center text-break">
                                <span className='h4 font-bold mb-0'>
                                    <QuickViewButton member={props.selectedRows[0]}>
                                        {props.selectedRows[0].first_name} {props.selectedRows[0].last_name}
                                    </QuickViewButton>
                                </span>
                            </div>
                            <div>
                                <InlineCopy tag="span" toCopy={props.selectedRows[0].unique_identifier}>
                                    <Link className="text-nowrap" to={`/members/profile/${props.selectedRows[0].member_id}`}>
                                        {props.selectedRows[0].unique_identifier ?
                                            <>#{props.selectedRows[0].unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></>
                                            :
                                            '-'
                                        }
                                    </Link>
                                </InlineCopy>
                            </div>
                        </div>
                    </div>

                    <div className="p-3">
                        <div className='mb-3'>
                            <Label className='font-medium text-dark'><Translate id='task.renewMembers.affiliated.has' /></Label>
                            <i className={stringBuilder("ml-2", props.selectedRows[0].affiliated == 1 ? "mdi mdi-check text-success" : "mdi mdi-close text-danger")} />
                        </div>
                        <div className='mb-3'>
                            <Label className='font-medium text-dark'><Translate id='task.renewMembers.memberType.has' /></Label>
                            <i className={stringBuilder("ml-2", props.selectedRows[0].has_member_type == 1 ? "mdi mdi-check text-success" : "mdi mdi-close text-danger")} />
                        </div>
                    </div>
                </>
                :
                <ListGroup>
                    {props.selectedRows.map((member) => (
                        <ListGroupItem className='px-3' key={member.member_id}>
                            <RenewMemberCard member={member} />
                        </ListGroupItem>
                    ))}
                </ListGroup>
            }

        </>
    );
};

export default RenewMembersSidePanel;
