import OrganizationActivityPeriods from '../../views/settings/platform/activityPeriods/OrganizationActivityPeriods';
import OrganizationPeriods from '../../views/settings/platform/periods/OrganizationPeriods';
import OrganizationRoles from '../../views/settings/platform/roles/OrganizationRoles';
import PlatformLexicon from '../../views/settings/platform/platformLexicon/PlatformLexicon';
import AssignRoles from '../../views/settings/platform/assignRoles/AssignRoles';

/**
 * @typedef {object} TabRoutes
 * @property {React.ReactNode} [name]
 * @property {boolean} [redirect]
 */

/** @type {Array.<import('react-router-dom').RouteProps & TabRoutes>} */
const settingsPlatfomTabsRoutes = [
    /*{
		exact: true, // must match exact path (example to let second level paths : /teams vs /teams/profile)
		navlabel: true,
		skipTranslate: true, // Skip translate to input custom text
		name: "Skip Translate", // skipTranslate==true
		name: "sidebar.home", // skipTranslate==false (translate i18n)
		sidebarRoute: true, // render route in sidebar
		childPath: '/invoice', // on Sidebar element, place second level routes that should highlight this sidebar item
		customNavLink: TournamentAdminRoute, // To tell what component to use for a custom build NavLink
		permissions: {code: 'clinics', permissionCode: 'clinics_manage', actions: ['ADD']},
		// code: The component to have access in. These represents the section that are displayed in the sidebar - MANDATORY
		// permissionCode: The permission that needs to have at least one active permission - OPTIONAL
		// actions: An array of actions where there need to be at least one of these granted to the role to be valid - OPTIONAL
		// In this example, the current role(roleContext) needs to have at least the ADD permission in the clinics_manage permission which is under clinics
	},*/
    {
        path: '/roles',
        name: 'settings.tabs.roles',
        component: OrganizationRoles,
        permissions: { code: 'settings', permissionCode: 'identity_roles' },
    },
    {
        path: '/assign-roles',
        name: 'settings.tabs.assignRoles',
        component: AssignRoles,
        permissions: { code: 'settings', permissionCode: 'identity_roles' },
        powerUserOnly: true,
    },
    {
        path: '/periods',
        name: 'settings.tabs.periods',
        component: OrganizationPeriods,
        permissions: { code: 'settings', permissionCode: 'organization_periods' },
    },
    {
        path: '/activity-periods',
        name: 'settings.tabs.activityPeriods',
        component: OrganizationActivityPeriods,
        permissions: { code: 'organization', permissionCode: 'organization_activity_periods' },
    },
    {
        path: '/platform-lexicon',
        name: 'settings.tabs.platformLexicon',
        component: PlatformLexicon,
        permissions: { code: 'settings', permissionCode: 'platform_lexicon' },
    },
    {
        redirect: true,
        dynamicRedirect: true,
        from: '/settings/platform',
        to: '/roles',
    },
];
export default settingsPlatfomTabsRoutes;