import { stringBuilder } from "@spordle/helpers";
import { useState, useContext } from "react";
import { Badge } from "reactstrap";
import ImgUploadModal from "../../../../../components/uploader/imgUpload/ImgUploadModal";
import UserImg from "../../../../../components/UserImg";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import PropTypes from 'prop-types';
import { MembersContext } from "../../../../../contexts/MembersContext";
import { useHistory } from "react-router";
import { Tooltip } from "@mantine/core";
import Translate, { DateFormat } from "@spordle/intl-elements";
import moment from "moment";


/**
 * Image uploader in a modal
 * @param {object} props
 * @param {string} [props.abbr] Abbreviation that will be shown if there is no img (default will be organisations)
 * @param {string} [props.alt] Alt of the img (default will be organisation's name)
 * @param {string} [props.placeholder] Placeholder there is no img
 * @param {string} [props.className] Class names
 * @param {object} [props.file] Data of the image, should contain file_position + full_path
 * @param {boolean} [props.canEdit] Enable or disable the edit/upload feature
 * @param {function} props.onSave Function called on save, should return a promise
 */
const MemberProfileHeaderImage = ({ className, file, alt, abbr, onSave, placeholder, hint, canEdit, hasApprovalPermissions, documentNeedsApproval, ...props }) => {
    const orgContext = useContext(OrganizationContext);
    const membersContext = useContext(MembersContext)

    const [ filePicker, setFilePicker ] = useState(false);
    const toggleFilePicker = () => setFilePicker(!filePicker);

    // stolen from MemberHeaderStatusButtons
    const scrollToComponent = (componentCode) => {
        const card = document.getElementById(componentCode);
        if(card){
            window.scrollTo({
                behavior: 'smooth', // not supported on mac?
                top: card.getBoundingClientRect().top - 150, // 2 * header(70 px high) + 10px(give some edge)
            })
        }
    }

    const history = useHistory();

    const date = moment(membersContext.currentMember.picture_expiration_date)
    const pictureExpiryDate = date.isValid() ? date : null
    const color = date.isBefore(moment()) ? 'danger' : 'secondary'

    const tooltipDisabled = (!documentNeedsApproval || (documentNeedsApproval && hasApprovalPermissions))

    return (
        <div>
            <div {...props} className={stringBuilder('profileHeader-image-container', className)}>
                <UserImg
                    abbr={abbr || orgContext.abbreviation}
                    src={file?.full_path || placeholder}
                    width="150"
                    filePos={file?.file_position}
                    alt={alt || orgContext.organisation_name + ' logo'}
                    className="p-4"
                />
                {canEdit &&
                    <>
                        {/* New logic
                            We merged the old tooltip with the expiry date
                            The tooltip will be disabled when both labels are invalid
                            The icon in the badge will be the camera, except when the expiry date is soon
                            then we show calendar clock
                            The badge color is secondary when the picture doesn't expire soon
                            It will be warning / danger when it expires
                        */}
                        <Tooltip
                            wrapLines
                            label={<div className="mb-2"><Translate id='members.profile.memberProfile.header.picture.upload.need.approval' /></div>}
                            width={!tooltipDisabled ? 400 : undefined}
                            position='bottom'
                            className="profileHeader-image-picker-tooltip"
                            withArrow={false} zIndex={3000}
                            disabled={tooltipDisabled}
                        >
                            <Badge
                                onClick={() => {
                                    if(tooltipDisabled)
                                        toggleFilePicker();
                                    else{
                                        history.push(`/members/profile/${membersContext.currentMember.member_id}/overview`)
                                        setTimeout(() => { scrollToComponent('profileComponentMemberDocuments') }, 0)
                                        membersContext.setShouldAttemptUploadProfilePicDocument(true)
                                    }

                                }}
                                color={color}
                                className='profileHeader-image-picker-badge clickable' style={{ width: "40px", height: "40px" }}
                            >
                                {color !== 'secondary' ?
                                    <i className='mdi mdi-calendar-clock' />
                                    :
                                    <i className='mdi mdi-camera' />
                                }
                            </Badge>
                        </Tooltip>
                        <ImgUploadModal
                            onSave={onSave}
                            image={orgContext.logo}
                            isOpen={filePicker}
                            toggle={toggleFilePicker}
                            hint={hint}
                        />
                    </>
                }
                {pictureExpiryDate &&
                    <div className={stringBuilder("mb-3 font-medium p-1 position-absolute small text-center w-100 text-nowrap", { 'text-medium': color === 'secondary', 'text-danger': color === 'danger' })}>
                        <i className={stringBuilder("mdi mdi-information-outline mr-1", { 'text-primary': color === 'secondary', 'text-danger': color === 'danger' })} /><Translate id='members.profile.memberProfile.header.picture.expiry' /><span className="ml-1"><DateFormat value={pictureExpiryDate} /></span>
                    </div>
                }
            </div>
        </div>
    );
};

export default MemberProfileHeaderImage;

MemberProfileHeaderImage.propTypes = {
    abbr: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    src: PropTypes.string,
    file: PropTypes.object,
    onSave: PropTypes.func.isRequired,
};
MemberProfileHeaderImage.defaultProps = {
    alt: "profile-image",
    canEdit: false,
};