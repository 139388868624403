export default{
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.title.contact"    : "Informations de contact",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.title.instructor" : "Informations d'instructeur",

    // formik editables
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.update.contacts"    : "Un contact existe déjà avec ces informations",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.update.instructors" : "Un instructeur existe déjà avec ces informations",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.settings"           : "Paramètres supplémentaires",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.notify"             : "Notifier de chaque nouvelle inscription",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.formik.accessInfo"         : "Information d'accès",

    // actions
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.giveAccess.btn"                    : "Cliquez ici pour donner accès à cet instructeur",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.giveAccess.modal.title"            : "Donner accès à instructeur",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove"                    : "Retirer",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove.text.contact"       : "Vous êtes sur le point de retirer ce contact.",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove.success.contact"    : "Contact supprimé!",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove.text.instructor"    : "Vous êtes sur le point de retirer cet instructeur.",
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.actions.remove.success.instructor" : "Instructeur supprimé!",

    // tabs
    "clinic.clinicSessionInformation.sidePanelFormClinicSessionInformation.tabs.details" : "Détails",
}