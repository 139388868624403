import Translate from "@spordle/intl-elements"
import { Form, Formik } from "formik"
import { useContext } from "react"
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { object } from "yup"
import AnalyticsModal from "../../../analytics/AnalyticsModal"
import OverlayLoader from "../../../components/loading/OverlayLoader"
import { TeamsContext } from "../../../contexts/TeamsContext"
import { BatchCreateForm, batchCreateInitialValues, batchCreateOnSubmit, BatchCreateTitle, getBatchCreateValidationSchema } from "./BatchCreateForm"

const BatchCreationModal = ({ isOpen, toggle, refreshTable }) => {
    const teamsContext = useContext(TeamsContext)

    return (
        <AnalyticsModal analyticsName="teamBatchCreationModal" isOpen={isOpen} size='lg'>
            <Formik
                initialValues={batchCreateInitialValues}
                validationSchema={object().shape({
                    ...getBatchCreateValidationSchema(true),
                })}
                onSubmit={async(values, { setSubmitting }) => {
                    await batchCreateOnSubmit(values, teamsContext);
                    setSubmitting(false);
                    refreshTable();
                    toggle();
                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader toggle={toggle}>
                                <BatchCreateTitle />
                            </ModalHeader>
                            <ModalBody>
                                <BatchCreateForm />
                            </ModalBody>
                            <ModalFooter>
                                <Button type='submit' color='primary'><Translate id='misc.create' /></Button>
                                <Button type='button' color='primary' outline onClick={toggle}><Translate id='misc.cancel' /></Button>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    )
}

export default BatchCreationModal