export default{
    "clinics.profile.clinicProfile.title" : "Clinic",

    "clinics.profile.clinicProfile.tabs.overview"       : "Overview",
    "clinics.profile.clinicProfile.tabs.attendees"      : "Attendees",
    "clinics.profile.clinicProfile.tabs.multipleAdd"    : "Bulk Signup",
    "clinics.profile.clinicProfile.tabs.refund"         : "Refund",
    "clinics.profile.clinicProfile.tabs.waitinglist"    : "Waiting List",
    "clinics.profile.clinicProfile.tabs.waitlist"       : "Waitlist",
    "clinics.profile.clinicProfile.tabs.unconfirmed"    : "Unconfirmed",
    "clinics.profile.clinicProfile.tabs.communications" : "Communications",
    "clinics.profile.clinicProfile.tabs.finance"        : "Finances",
    "clinics.profile.clinicProfile.showAs.GUEST"        : "See as guest",
    "clinics.profile.clinicProfile.showAs.HOST"         : "See as host",
    "clinics.profile.clinicProfile.viewingAs.GUEST"     : "Viewing as guest",

    // waitlist table
    "clinics.profile.waitlist.table.created_at"                          : "Added date",
    "clinics.profile.waitlist.table.waiting_list_item_sequential_number" : "Reference #",
    "clinics.profile.waitlist.table.goToProfile"                         : "Go to profile",
    "clinics.profile.waitlist.table.admin"                               : "Admin registration",
    "clinics.profile.waitlist.table.online"                              : "Online registration",

    // waitlist status
    "clinics.profile.waitlist.status.PENDING"            : "Pending",
    "clinics.profile.waitlist.status.WAITING_FOR_CLIENT" : "Waiting For Client",
    "clinics.profile.waitlist.status.CANCELLED"          : "Cancelled",
    "clinics.profile.waitlist.status.CANCELED"           : "Cancelled",
    "clinics.profile.waitlist.status.CONVERTED"          : "Converted",

    "clinics.profile.waitlist.status.alert.title"     : "This member has been converted.",
    "clinics.profile.waitlist.status.alert.clickHere" : "Click here to see the participant.",
    "clinics.profile.waitlist.status.alert.text"      : "Invoice date: {date}",

    //waitlist actions
    "clinics.profile.waitlist.actions.add_as_attendee"     : "Add As Attendee",
    "clinics.profile.waitlist.actions.reset"               : "Reset Status",
    "clinics.profile.waitlist.actions.cancel"              : "Cancel",
    "clinics.profile.waitlist.actions.add_as_attendee.msg" : "Add member as an attendee.",
    "clinics.profile.waitlist.actions.reset.msg"           : "Reset the status to pending.",
    "clinics.profile.waitlist.actions.cancel.msg"          : "Remove the member from the waitlist.",

    // cancellation modal
    "clinics.profile.waitlist.cancelModal.label" : "Cancellation Reason",

    // Advanced Settings
    "clinics.profile.clinicProfile.advancedSettings.title"                           : "Advanced Settings",
    "clinics.profile.clinicProfile.advancedSettings.visibility"                      : "Visibility - Schedule status changes",
    "clinics.profile.clinicProfile.advancedSettings.visibility.status.required"      : "You must select a status.",
    "clinics.profile.clinicProfile.advancedSettings.visibility.date.required"        : "The date is required. ",
    "clinics.profile.clinicProfile.advancedSettings.visibility.time.required"        : "The time is required.",
    "clinics.profile.clinicProfile.advancedSettings.changeVisibility"                : "Change the status to",
    "clinics.profile.clinicProfile.advancedSettings.changeVisibility.on"             : "on what date",
    "clinics.profile.clinicProfile.advancedSettings.changeVisibility.at"             : "at what time",
    "clinics.profile.clinicProfile.advancedSettings.changeVisibility.add"            : "Add",
    "clinics.profile.clinicProfile.advancedSettings.sharedOrganizations"             : "Organizations",
    "clinics.profile.clinicProfile.advancedSettings.sharedOrganizations.message"     : "Specify the organizations that should have access to this clinic",
    "clinics.profile.clinicProfile.advancedSettings.sharedOrganizations.not.current" : "You can't share to your currently managed organisation",

    // Status
    "clinics.profile.clinicProfile.status.DRAFT"                 : "Draft",
    "clinics.profile.clinicProfile.status.PRIVATE"               : "Private",
    "clinics.profile.clinicProfile.status.OPEN_PUBLIC_SHOW_LIST" : "Open to the public",
    "clinics.profile.clinicProfile.status.OPEN_PUBLIC_SIGNUP"    : "Open registration",
    "clinics.profile.clinicProfile.status.UNDER_CREATION"        : "In creation",

    // Tooltips
    "clinics.profile.clinicProfile.tooltip.status"           : "Clinic Status",
    "clinics.profile.clinicProfile.tooltip.store"            : "View Clinic",
    "clinics.profile.clinicProfile.tooltip.copyUrl"          : "Click to copy clinic URL!",
    "clinics.profile.clinicProfile.tooltip.advancedSettings" : "Advanced Settings",

    // Success Toast
    "clinics.profile.clinicProfile.success.statusUpdate" : "Clinic status update",

    "clinics.profile.clinicProfile.notFound.title"       : "Clinic not found",
    "clinics.profile.clinicProfile.notFound.description" : "The clinic you are looking for was not found, it may have been moved or removed.",
    "clinics.profile.clinicProfile.notFound.backbutton"  : "Go back",

    "clinics.profile.header.back" : "Back to clinics list",

    "clinics.profile.payment.error.3197" : "You cannot delete a clinic item that has already been purchased.",
}
