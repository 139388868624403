import Translate from "@spordle/intl-elements";
import { useState } from "react";
import { Collapse, Table } from "reactstrap";
import Skeleton from 'react-loading-skeleton';

const SidePanelOrganizationRolesUsers = ({ usersList, loadingStatus = 'lazy' }) => {
    const [ isOpen, setIsOpen ] = useState(true);

    const isLoaded = loadingStatus === 'success';
    const isLoading = loadingStatus === 'lazy';

    return (
        <>
            <div className="p-3 d-flex clickable" onClick={() => setIsOpen((prev) => !prev)}>
                <div>
                    <div className="h4 font-bold mb-0"><Translate id='organization.profile.roles.sidePanelOrganizationRoles.usersInfo.title' /></div>
                    {isLoaded ?
                        <div className="small">
                            {usersList?.length > 1 ?
                                <Translate id='organization.profile.roles.sidePanelOrganizationRoles.usersInfo.subTitle.plural' values={{ num: usersList.length }} />
                                : usersList?.length === 1 ?
                                    <Translate id='organization.profile.roles.sidePanelOrganizationRoles.usersInfo.subTitle.singular' />
                                    :
                                    <Translate id='organization.profile.roles.sidePanelOrganizationRoles.usersInfo.subTitle.none' />
                            }
                        </div>
                        :
                        isLoading ? <Skeleton className="mt-1 line-height-1" containerClassName="d-block line-height-1" height={10} width={125} /> : null
                    }
                </div>
                <i className={`mdi ml-auto font-22 mdi-chevron-${isOpen ? 'down' : 'up'}`} />
            </div>
            <Collapse isOpen={isOpen}>
                {isLoading &&
                    <div className="px-3 pb-3">
                        <Table size="sm" responsive className="v-middle mb-0">
                            <thead>
                                <tr>
                                    <th><Translate id='organization.profile.roles.sidePanelOrganizationRoles.usersInfo.table.name' /></th>
                                    <th className="th-shrink text-center" />
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from({ length: 10 }, (_, i) => (
                                    // eslint-disable-next-line space-infix-ops
                                    <tr key={'roleSkeleton'+i}>
                                        <td>
                                            <div className='font-medium'>
                                                <Skeleton width={Math.floor(Math.random() * (200 - 80 + 1) + 80)} height={18} />
                                            </div>
                                            {/* <div className="small">#<Skeleton width={115} height={10} /></div> */}
                                        </td>
                                        <td className="td-shrink text-center">
                                            {/* <Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" /> */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                }
                {isLoaded && usersList.length !== 0 &&
                    <div className="px-3 pb-3">
                        <Table size="sm" responsive className="v-middle mb-0">
                            <thead>
                                <tr>
                                    <th><Translate id='organization.profile.roles.sidePanelOrganizationRoles.usersInfo.table.name' /></th>
                                    <th className="th-shrink text-center" />
                                </tr>
                            </thead>
                            <tbody>
                                {usersList.map((user, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <tr key={index}>
                                        <td>
                                            <div className='font-medium'>{user.identity?.name} {user.identity?.family_name}</div>
                                            {/* <div className="small">#1500000000077627</div> */}
                                        </td>
                                        <td className="td-shrink text-center">
                                            {/* <Translate id='misc.goToProfile' /> <i className="mdi mdi-chevron-right" /> */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                }
            </Collapse>
        </>
    );
}

export default SidePanelOrganizationRolesUsers;