import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import SpordlePanelControl from "../../../components/sidePanel/SpordlepanelControl";
import TablePagination from "../../../components/table/TablePagination";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import { I18nContext } from "../../../contexts/I18nContext";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import MemberTypeValidationSidepanel from "./sidePanel/MemberTypeValidationSidepanel";
import UserImg from "../../../components/UserImg";
import DisplayMemberTypeStatus from "../../../components/display/DisplayMemberTypeStatus";
import GenderLabel from "../../../components/GenderLabel";
import FiltersCard from "./FiltersCard";
import { PeriodsContext } from "../../../contexts/contexts";
import InlineCopy from "../../../components/inlineCopy/InlineCopy";
import CRCStatus from "../../members/profile/criminalRecordCheck/components/CRCStatus";

const MemberTypeValidation = (props) => {
    const i18nContext = useContext(I18nContext);
    const periodsContext = useContext(PeriodsContext);

    return (
        <SpordlePanelTable
            allowOutsideClick
            sidePanel={(props) => <MemberTypeValidationSidepanel {...props} />}
            dataIndex='member_member_type_id'
            table={(panelProps) => {
                return (
                    <SpordleTableProvider
                        id='memberTypeValidations'
                        tableHover bordered striped
                        clickable
                        ref={panelProps.spordleTableRef}
                        desktopWhen
                        dataIndex='member_member_type_id'
                        pagination={25}
                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                        defaultSorting='+created_at'
                        onColumnClick={(e, data, _spordleTable, columnKey) => {
                            switch (e.button){
                                case 0: // Left mouse button
                                    if(columnKey === 'checkbox'){
                                        panelProps.onMultiSelectChange(data);
                                    }else{
                                        panelProps.onSingleSelectChange(data);
                                    }
                                    break;
                            }
                        }}
                        searchKeys={[
                            'unique_identifier',
                            'first_name',
                            'last_name',
                            `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                            `organisation.organisation_name`,
                            'created_at',
                            `review_status`,
                        ]}
                        initFilter={{
                            form: {
                                organizationIds: [],
                                memberTypeIds: [],
                                gender: '',
                                memberTypeStatuses: [],
                            },
                        }}
                        loadData={(from, { filters }, spordleTable) => {
                            switch (from){
                                case 'FILTER':
                                case 'REFRESH':
                                    spordleTable.setLoading();

                                    const extraFilters = {}
                                    if(filters.form.organizationIds.length > 0)
                                        extraFilters.organisation_id = filters.form.organizationIds.join()
                                    if(filters.form.memberTypeStatuses.length > 0)
                                        extraFilters.member_type_status_id = filters.form.memberTypeStatuses.join()
                                    if(filters.form.gender)
                                        extraFilters.gender = filters.form.gender

                                    return props.getData({
                                        ...extraFilters,
                                        member_type_id: filters.form.memberTypeIds.join(),
                                        period_id: periodsContext.selectedPeriod.period_id,
                                    });
                                default:
                                    break;
                            }
                        }}
                        columns={[
                            {
                                // yes keep this
                                label: '-',
                                key: 'checkbox',
                                className: 'th-shrink',
                                dataClassName: 'td-shrink',
                            },
                            {
                                label: <Translate id='tasks.memberTypeValidation.columns.member' />,
                                key: 'member',
                                sortable: true,
                                sortKey: 'last_name',
                            },
                            {
                                label: <Translate id='tasks.memberTypeValidation.columns.crc' />,
                                key: 'valid_crc',
                                sortable: true,
                                sortKey: 'valid_crc',
                                className: "td-shrink",
                                dataClassName: 'text-center td-shrink',
                            },
                            {
                                label: <Translate id='tasks.memberTypeValidation.columns.expiry' />,
                                key: 'picture_expiration_date',
                                sortable: true,
                                sortKey: "picture_expiration_date",
                                className: "td-shrink",
                                dataClassName: 'text-center td-shrink',
                            },
                            {
                                label: <Translate id='tasks.memberTypeValidation.columns.memberType' />,
                                key: 'member_type',
                                sortable: true,
                                sortKey: `member_type.i18n.${i18nContext.getGenericLocale()}.name`,
                                fallbackSortKey: `member_type.name`,
                                className: "td-shrink",
                                dataClassName: 'text-center font-medium td-shrink',
                            },
                            {
                                label: <Translate id='tasks.memberTypeValidation.columns.memberTypeStatus' />,
                                key: 'member_type_status',
                                sortable: true,
                                sortKey: `member_type_status.i18n.${i18nContext.getGenericLocale()}.name`,
                                fallbackSortKey: `member_type_status.name`,
                                className: "td-shrink",
                                dataClassName: 'text-center td-shrink',
                            },
                        ]}
                        renderRow={(columnKey, row) => {
                            switch (columnKey){
                                case 'checkbox':
                                    return (
                                        <label className='my-auto' htmlFor={'attachment-checked-' + row.member_member_type_id} onClick={(e) => { e.stopPropagation() }}>
                                            <input
                                                id={'attachment-checked-' + row.member_member_type_id} type="checkbox" checked={!!row.checked}
                                                onClick={() => { panelProps.onMultiSelectChange(row); }}
                                                onChange={function(){}}// This needs to be here to remove the console error
                                            />
                                            <span className="table-checkbox" />
                                        </label>
                                    )
                                case 'member':
                                    return (
                                        <div className='flex-grow-1'>
                                            <UserDisplay>
                                                <UserDisplay.Container>
                                                    <UserImg
                                                        abbr={row.first_name[0] + row.last_name[0]}
                                                        src={row.picture?.full_path}
                                                        filePos={row.picture?.file_position}
                                                        width={50}
                                                        height={50}
                                                        alt={`${row.first_name} ${row.last_name}`}
                                                    />
                                                </UserDisplay.Container>
                                                <UserDisplay.Container>
                                                    <UserDisplay.Title>{row.first_name} {row.last_name}</UserDisplay.Title>
                                                    <InlineCopy tag="span" toCopy={row.unique_identifier}>
                                                        <Link className="text-nowrap" to={`/members/profile/${row.member_id}`}>#{row.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></Link>
                                                    </InlineCopy>
                                                    <UserDisplay.Subtitle>
                                                        <div>
                                                            <GenderLabel gender={row.gender} />
                                                        </div>
                                                        <div>
                                                            <DisplayI18n field='name' defaultValue={row.organisation.organisation_name} i18n={row.organisation.i18n} />
                                                        </div>
                                                        <DateFormat value={row.birthdate} utc /> (<Translate id='misc.yearsOld' values={{ age: row.age }} />)
                                                    </UserDisplay.Subtitle>
                                                </UserDisplay.Container>
                                            </UserDisplay>
                                        </div>
                                    )
                                case 'picture_expiration_date':
                                    if(row.picture_expiration_date){
                                        return (
                                            <DateFormat value={row.picture_expiration_date} />
                                        )
                                    }
                                    return '-'
                                case 'valid_crc':
                                    if(row.criminal_record_check_status){
                                        return (
                                            <>
                                                <CRCStatus crc={row} className='mb-1' />
                                                <div className="small">
                                                    <span className="text-muted"><Translate id='tasks.memberTypeValidation.columns.crc.date' />:</span>
                                                    <span className="font-medium text-dark ml-1"><DateFormat value={row.issue_date} /></span>
                                                </div>
                                                <div className="small">
                                                    <span className="text-muted"><Translate id='tasks.memberTypeValidation.columns.crc.expiry' />:</span>
                                                    <span className="font-medium text-dark ml-1"><DateFormat value={row.expiration_date} /></span>
                                                </div>
                                            </>
                                        )
                                    }

                                    return '-'
                                case 'member_type':
                                    return (
                                        <DisplayI18n field='name' defaultValue={row.member_type.name} i18n={row.member_type.i18n} />
                                    )
                                case 'member_type_status':
                                    if(row.member_type_status){
                                        return (
                                            <DisplayMemberTypeStatus
                                                status={row.member_type_status}
                                            />
                                        )
                                    }
                                    return '-'
                                default:
                                    break;
                            }
                        }}
                    >
                        {(spordleTable) => (
                            <>
                                <FiltersCard />
                                <Card body className="card-shadow">
                                    <div className="mb-2">
                                        <div className='d-flex flex-wrap justify-content-between mb-2'>
                                            <SpordlePanelControl />
                                            <SearchInput />
                                            <div className='d-flex ml-auto'>
                                                <TablePagination className='ml-2' paginationArray={[ 25, 50, 100 ]} />
                                                <Refresh className='ml-2' />
                                            </div>
                                        </div>
                                    </div>
                                    <SpordleTableView />
                                </Card>
                            </>
                        )}
                    </SpordleTableProvider>
                )
            }}
        />
    );
};


export default MemberTypeValidation;
