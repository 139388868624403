export default{
    // Review
    "clinics.clinicSessionInformation.formClinicSessionInformation.review.title"         : "Modifier les informations de la ou des séance(s)",
    "clinics.clinicSessionInformation.formClinicSessionInformation.review.saveChanges"   : "Sauvegarder les changements",
    "clinics.clinicSessionInformation.formClinicSessionInformation.review.cancelChanges" : "Annuler les changements",

    // contact info
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.title"                      : "{count, plural, =0 {Contact} one {Contact} other {Contacts}}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.myself"                     : "M'ajouter en tant que contact",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.myself.text"                : "Vous êtes sur le point de vous ajouter en tant que contact.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact"                 : "Ajouter un contact",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.exists"          : "Un contact existe déjà avec ces informations",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.asInstructor"    : "Un instructeur existe déjà avec ces informations",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.findContact"                : "Trouver un contact",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.asInstructor"               : "Ajouter également en tant qu'instructeur",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.notify"                     : "Notifier le contact de chaque nouvelle inscription",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus"                 : "Visibilité",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus.SHOW"            : "Afficher toutes les informations",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus.SHOW_PARTIAL"    : "Cacher le courriel et le numéro de téléphone",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.showStatus.HIDE"            : "Cacher toutes les informations",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.minimum"                    : "Ajoutez au moins 1 contact",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.new.title"       : "Créer un nouveau contact",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.addContact.new.description" : "Créer {firstName} {lastName}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.hostContactInfo.link.title"                 : "Lier un compte",

    // instructor info
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.title"                                          : "{count, plural, =0 {Instructeur} one {Instructeur} other {Instructeurs}}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.myself"                                         : "M'ajouter en tant qu'instructeur",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.myself.text"                                    : "Vous êtes sur le point de vous ajouter en tant qu'instructeur.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor"                                  : "Ajouter un instructeur",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.exists"                           : "Un instructeur existe déjà avec ces informations",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.findContact"                                    : "Trouver un contact",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.notify"                                         : "Notifier l'instructeur de chaque nouvelle inscription",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.new.title"                        : "Créer un nouvel instructeur",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.new.description"                  : "Créer {firstName} {lastName}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission"                : "Gérer l'accès",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.title"                    : "Instructeur créé",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.subtitle"                 : "L'instructeur a été créé avec succès.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.instructorIdentityMember" : "Le {spordle_account} lié à ce membre a un accès {permission}.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.instructorMember"         : "Un membre a été lié à cet instructeur.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.success.instructorNoLink"         : "Aucun membre et aucun Sporlde MonCompte n'a été lié à cet instructeur.",

    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.sidepanel.member"                           : "{unique_identifier}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.sidepanel.spordleMyAccount"                 : "{spordle_account}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.NO_ACCESS"  : "Aucun accès",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.READ_ONLY"  : "Lecteur seulement",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.manage_permission.READ_WRITE" : "Écriture et lecture",

    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.desc.NO_ACCESS"  : "L'instructeur <bold>n'aura pas accès à voir</bold> cette clinique et ses participants dans son compte <bold>{spordle_account}</bold>.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.desc.READ_ONLY"  : "L'instructeur <bold>aura accès à voir</bold> cette clinique et ses participants dans son compte <bold>{spordle_account}</bold>.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.addInstructor.desc.READ_WRITE" : "L'instructeur <bold>aura accès à voir et modifier</bold> cette clinique et ses participants dans son compte <bold>{spordle_account}</bold>.",


    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.sidepanel.desc.NO_ACCESS"  : "L'instructeur <bold>n'a pas accès à voir</bold> cette clinique et ses participants dans son compte <bold>{spordle_account}</bold>.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.sidepanel.desc.READ_ONLY"  : "L'instructeur <bold>a accès à voir</bold> cette clinique et ses participants dans son compte <bold>{spordle_account}</bold>.",
    "clinics.clinicSessionInformation.formClinicSessionInformation.instructorContactInfo.sidepanel.desc.READ_WRITE" : "L'instructeur <bold>a accès à voir et modifier</bold> cette clinique et ses participants dans son compte <bold>{spordle_account}</bold>.",


    // Sessions
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.title"            : "{count, plural, =0 {Séance} one {Séance} other {Séances}}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.add"              : "Ajouter une séance",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.none"             : "Aucune séance",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.minimum"          : "Ajoutez au moins 1 séance",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.number"           : "Séance n{number}",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.delete.text"      : "Vous êtes sur le point de supprimer cette séance",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.format"           : "Format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.language"         : "Langue",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.location"         : "Emplacement",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.location.example" : "2ième patinoire, Salle de conférence 3",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.source"           : "Source",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.url"              : "Lien URL",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.notes"            : "Notes",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.duplicate"        : "Dupliquer",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.duplicate.msg"    : "Vous êtes sur le point de dupliquer cette séance",

    "clinics.clinicSessionInformation.formClinicSessionInformation.ruleForQualifications"        : "Type de session requise",
    "clinics.clinicSessionInformation.formClinicSessionInformation.ruleForQualifications.helper" : "Type de session obligatoire pour obtenir la qualification",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.ONSITE"              : "En personne",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.ONLINE_LIVE"         : "En ligne - En direct",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.ALL"                 : "Tous",
    "clinics.clinicSessionInformation.formClinicSessionInformation.sessions.ONLINE"              : "En ligne - Sur demande",

    // Add Session
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.title"           : "Ajouter une séance",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.note"            : "Ajouter une note",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.title"    : "Source & lien",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.source"   : "Source",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.external" : "Lien externe",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.huc"      : "Cours d'Université du Hockey",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.coach"    : "Coach.ca",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.online.respect"  : "Respect et sport",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.address.tooltip" : "Ouvrir dans Google Maps",

    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.title" : "Horaire",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text1" : "La séance aura lieu le ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text2" : " à ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text3" : "et durera ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text4" : " heure(s) et ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.text5" : " minute(s) ",

    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text1" : "La séance sera disponible du ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text2" : " à ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text3" : " jusqu'au ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text4" : " à ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text5" : "et durera ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text6" : " heure(s) et ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.schedule.VODs.text7" : " minute(s) ",

    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.language.required"  : "La langue est requise",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.format.required"    : "Le format est requis",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.source.required"    : "La source est requise",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.url.required"       : "L'URL est requis",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.url.valid"          : "L'URL doit être d'un bon format (ex: https://www.spordle.com)",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.date.format"                   : "AAAA-MM-JJ",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.date.required"      : "La date est requise",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.date.valid"         : "La date doit être d'un bon format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.time.required"      : "Le temps est requis",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.time.valid"         : "Le temps doit être d'un bon format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.startDate.required" : "La date de début est requise",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.startDate.valid"    : "La date de début doit être d'un bon format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.startTime.required" : "L'heure de début est requise",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.startTime.valid"    : "L'heure de début doit être d'un bon format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endDate.required"   : "La date de fin est requise",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endDate.after"      : "La date de fin doit être après la date de début",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endDate.valid"      : "La date de fin doit être d'un bon format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endTime.required"   : "L'heure de fin est requise",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endTime.after"      : "L'heure de fin doit être après l'heure de début",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.endTime.valid"      : "L'heure de fin doit être d'un bon format",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.hours.required"     : "Les heures sont requises",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.hours.max"          : "Le nombre d'heures doit être inférieur ou égal à 24",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.minutes.required"   : "Les minutes sont requises",
    "clinics.clinicSessionInformation.formClinicSessionInformation.addSession.validation.minutes.max"        : "Le nombre de minutes doit être inférieur à 60",
}