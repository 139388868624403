import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { Card, CardBody, Col, Row } from 'reactstrap';

const TransactionTotalDue = ({ totalAmount, dueAmount }) => {
    return (
        <div className="mb-3">
            <Row form>
                <Col xs="6">
                    <Card className="card-shadow text-center mb-0">
                        <CardBody>
                            <div className="h4 mb-0 font-bold"><CurrencyFormat value={totalAmount / 100} /></div>
                            <div className="small"><Translate id='members.profile.transactions.memberProfileTransactions.total' /></div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs="6">
                    <Card className="card-shadow text-center mb-0">
                        <CardBody>
                            <div className="h4 mb-0 font-bold"><CurrencyFormat value={dueAmount / 100} /></div>
                            <div className="small"><Translate id='members.profile.transactions.memberProfileTransactions.due' /></div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default TransactionTotalDue;