export default{
    "registration.settings.division.table.active" : "Active",
    "registration.settings.division.table.empty"  : "No Division",

    // add
    "registration.settings.division.add.title"                   : "Add Division",
    "registration.settings.division.add.status"                  : "Status",
    "registration.settings.division.add.status.active"           : "Active",
    "registration.settings.division.add.status.inactive"         : "Inactive",
    "registration.settings.division.add.status.required"         : "Status is required",
    "registration.settings.division.add.divisionParent.required" : "Please link a parent division.",
}