import { stringBuilder } from "@spordle/helpers";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { DropdownItem, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
import UserDisplay from "../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../components/UserImg";
import { InjuriesContext } from "../../../../../contexts/InjuriesContext";
import { OrganizationContext } from "../../../../../contexts/OrganizationContext";
import { HoverableCopy } from "../../../../../helpers/clipboardHelper";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import { getLocation } from "../../../../teams/TeamHelpers";
import AddChequeModal from "./tabs/components/chequeModal/AddChequeModal";
import InsuranceSidePanelAccident from "./tabs/InsuranceSidePanelAccident";
import InsuranceSidePanelInfo from "./tabs/InsuranceSidePanelInfo";
import InsuranceSidePanelInjury from "./tabs/InsuranceSidePanelInjury";
import { getStatusIdsPerTypes } from "../InsuranceHelper";
import QuickViewButton from "../../../../../components/quickView/QuickViewButton";
import { IdentityRolesContext } from "../../../../../contexts/IdentityRolesContext";

const InsuranceSidePanel = ({ selectedRows, canReadCheques, printWorksheet, ...props }) => {
    // contexts
    const { updateInjury, deleteInjury, getInsuranceChequeList, getInjury } = useContext(InjuriesContext);
    const { organisation_id } = useContext(OrganizationContext);
    const { federation } = useContext(IdentityRolesContext);
    const statuses = getStatusIdsPerTypes(props.injuryDataLists.status)

    // states
    const [ isLoading, setIsLoading ] = useState(false);
    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const [ cheques, setCheques ] = useState(null); // null === loading
    const [ tab, setTab ] = useState('accident');

    // other vars
    const { tableRef, forceCloseSidePanel, syncRows, createNewValues } = props;
    const injury = selectedRows[0];

    // functions
    const toggleChequeModal = (bool) => {
        setModalIsOpen(typeof bool === 'boolean' ? bool : !modalIsOpen);
    }

    /**
     * @description generic catch function
     * @param {Error} e
     */
    const handleCatch = (e) => {
        if(!AxiosIsCancelled(e.message)){
            console.error(e);
            setIsLoading(false);
            fail({ info: <DisplayI18n field='message' defaultValue={e.message} i18n={e.i18n} />, skipInfoTranslate: true });
        }
    }

    /**
     * @description Syncs new values to the spordle table
     * @param {object} newVal New values
     * @param {bool} skipSetLoading Will not set is loading to false
     */
    const syncNewVal = (newVal, skipSetLoading = false) => {
        syncRows(createNewValues({ ...newVal }))

        if(!skipSetLoading){
            success();
            setIsLoading(false);
        }
    }

    /**
     * @description Helper function that uses injury to construct put data
     * @returns {object} apiValues
     */
    const buildPutData = () => {
        const apiValues = {};
        const rowKeys = Object.keys(injury);
        for(let r = 0; r < rowKeys.length; r++){
            const rKey = rowKeys[r];
            const val = injury[rKey];

            // if val is an array
            if(Array.isArray(val) && rKey === "injury_equipment"){
                for(let i = 0; i < val.length; i++){
                    apiValues[`${rKey}_id[${i}]`] = val[i].injury_equipment_id;
                }
            // if val is an object
            }else if(typeof val === "object" && val !== null){
                // injury_pay_to_id, injury_nature_id, injury_environment_id, etc..
                const ressourceId = rKey + '_id';

                // Fields where this _id trick does not apply
                if([ "official" ].includes(rKey)){
                    apiValues[ressourceId] = val.member_id;
                }else if(val[ressourceId]){
                    apiValues[ressourceId] = val[ressourceId];
                }
            }else if(val){ // If isn't an object
                apiValues[rKey] = val;
            }
        }
        return apiValues;
    }

    const refreshTableWithApi = (skipSetLoading) => {
        getInjury(selectedRows[0].injury_id)
            .then(([ injury ]) => syncNewVal(injury, skipSetLoading))
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    tableRef.refreshTable();
                }
            });
    }


    /**
     * @description Uses the PUT update on injury based on the new values
     * @param {object} newVal
     * @param {object} [rowVal] Will update spordle table row with this object if specified
     * @param {object} [refreshFromApi] Will update spordle table row with this object if specified
     */
    const handleUpdateInjury = (newVal, rowVal, refreshFromApi = false) => {
        if(newVal){
            setIsLoading(true);

            // Construct put data with select rows
            const apiValues = buildPutData();

            const newValKeys = Object.keys(newVal);
            for(let i = 0; i < newValKeys.length; i++){
                const key = newValKeys[i];
                apiValues[key] = newVal[key];
            }

            updateInjury(injury.injury_id, apiValues)
                .then(() => {
                    if(refreshFromApi){
                        refreshTableWithApi();
                    }else{
                        syncNewVal(rowVal);
                    }
                })
                .catch(handleCatch)
        }
    };

    /**
     * @description handles the logic of deleting an insurance claim
     * @returns {Promise}
     */
    const handleDeleteClaim = () => {
        return deleteInjury(injury.injury_id)
            .then(() => {
                tableRef.deleteRow(injury.injury_id)
                    .then(() => {
                        success({ info: 'insurance.sidePanel.delete.success' });
                        forceCloseSidePanel();
                    })
            })
            .catch(handleCatch);
    };

    /**
     * @description Gets the cheques of this insurance claim
     */
    const getCheques = () => {
        setCheques(null);
        getInsuranceChequeList(organisation_id, { injury_id: injury.injury_id })
            .then((theCheques) => setCheques(theCheques.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            })))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setCheques([]);
                }
            })
    };

    /**
     * @description handles the print worksheet logic
     */
    const handlePrintWorksheet = () => {
        printWorksheet({ injury_id: injury.injury_id })
            .then(() => {
                refreshTableWithApi();
            });
    }

    useEffect(() => {
        if(canReadCheques){
            getCheques();
        }

    }, [ injury.injury_id ]);

    return (
        <OverlayLoader isLoading={isLoading}>
            <SidePanel.Header noBorder>
                <div className="d-flex align-items-center mb-3 justify-content-between">

                    <AddChequeModal
                        isOpen={modalIsOpen}
                        toggle={() => toggleChequeModal(false)}
                        injury={injury}
                        getCheques={getCheques}
                    />
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu>
                        <SidePanel.MenuAction
                            componentCode="members"
                            componentPermissionCode="member_checks_generation"
                            action="ADD"
                            onClick={() => toggleChequeModal(true)}
                        >
                            <Translate id='insurance.sidePanel.cheque.addBtn' />
                        </SidePanel.MenuAction>
                        <SidePanel.MenuAction disabled={!props.canPrint} onClick={handlePrintWorksheet}>
                            <Translate id='insurance.export.printWorksheet' />
                        </SidePanel.MenuAction>
                        <CanDoAction
                            componentCode="members"
                            componentPermissionCode="members_injuries"
                            action="DELETE"
                        >
                            <DropdownItem divider />
                            <SidePanel.MenuDelete
                                onConfirm={handleDeleteClaim}
                                translateModalMsg
                                modalMsg='insurance.sidePanel.deleteModal.msg'
                                modalTitle={
                                    <>
                                        <div>{injury.member.first_name} {injury.member.last_name}</div>
                                        <small className="font-medium d-block"><Translate id={`insurance.label.injuryTypes.${injury.injury_type}`} values={{ name: federation?.organisation_name }} /></small>
                                        <small className="text-muted"><Translate id='insurance.label.case' />: #{injury.injury_case_number}</small>
                                    </>
                                }
                            />
                        </CanDoAction>
                    </SidePanel.ActionsMenu>
                </div>
                <SidePanel.Subtitle>
                    <Translate id={`insurance.label.injuryTypes.${injury.injury_type}`} values={{ name: federation?.organisation_name }} />
                </SidePanel.Subtitle>
                <SidePanel.Subtitle>
                    <Translate id='transfer.createdBy' /> <b>{injury.identity.name} {injury.identity.family_name}</b> {injury.created_at && <>(<DateFormat value={injury.created_at} utc />)</>}
                </SidePanel.Subtitle>
                {injury.updated_by &&
                    <SidePanel.Subtitle>
                        <Translate id='insurance.label.updatedBy' /> <b>{injury.updated_by.name} {injury.updated_by.family_name}</b> {injury.updated_at && <>(<DateFormat value={injury.updated_at} utc />)</>}
                    </SidePanel.Subtitle>
                }
                <SidePanel.Title>
                    <Translate id='insurance.label.insuranceClaim' />
                </SidePanel.Title>
                {injury.printed_at && injury.printed_by &&
                    <SidePanel.Subtitle className="mt-2">
                        <Translate id="insurance.label.printed" /> <b><DateFormat format="YYYY-MM-DD HH:mm" value={injury.printed_at} /></b> <Translate id="insurance.label.printedBy" /> <b>{injury.printed_by.name} {injury.printed_by.family_name}</b>
                    </SidePanel.Subtitle>
                }
                <UserDisplay className="mt-3 mb-1">
                    <UserDisplay.Container>
                        <UserImg
                            className="text-uppercase mr-1"
                            width={55}
                            alt={injury.member.first_name + " " + injury.member.last_name}
                            abbr={injury.member.first_name.charAt(0) + injury.member.last_name.charAt(0)}
                            src={injury.member.picture?.full_path}
                            filePos={injury.member.picture?.file_position}
                        />
                    </UserDisplay.Container>
                    <UserDisplay.Container>
                        <UserDisplay.Subtitle>
                            <FormattedMessage id={'insurance.label.participantType.' + injury.participant_type} defaultMessage="noTranslate">
                                {(message) => (message[0] !== 'noTranslate') ? message : injury.participant_type || ''}
                            </FormattedMessage>
                        </UserDisplay.Subtitle>
                        <QuickViewButton member={injury.member}>
                            <span className="font-medium">{injury.member.first_name} {injury.member.last_name}</span>
                        </QuickViewButton>
                        <HoverableCopy toCopy={injury.member.unique_identifier} className="small">
                            <Link to={`/members/profile/${injury.member.member_id}`}>
                                {'#' + injury.member.unique_identifier || '-'} <i className="mdi mdi-chevron-right" />
                            </Link>
                        </HoverableCopy>
                        <UserDisplay.Subtitle>{injury.member.birthdate}</UserDisplay.Subtitle>
                        <UserDisplay.Subtitle>{getLocation(injury.member.address)}</UserDisplay.Subtitle>
                    </UserDisplay.Container>
                </UserDisplay>
            </SidePanel.Header>
            <Nav tabs className="is-full">
                <NavItem className="text-center flex-grow-1">
                    <NavLink
                        className={stringBuilder({ active: tab === 'accident' })}
                        onClick={() => { setTab('accident'); }}
                    >
                        <Translate id='insurance.label.accident' />
                    </NavLink>
                </NavItem>
                <NavItem className="text-center flex-grow-1">
                    <NavLink
                        className={stringBuilder({ active: tab === 'injury' })}
                        onClick={() => { setTab('injury'); }}
                    >
                        <Translate id='insurance.label.injury' />
                    </NavLink>
                </NavItem>
                <NavItem className="text-center flex-grow-1">
                    <NavLink
                        className={stringBuilder({ active: tab === 'insurance' })}
                        onClick={() => { setTab('insurance'); }}
                    >
                        <Translate id='insurance.label.insuranceInfo' />
                    </NavLink>
                </NavItem>
            </Nav>
            <SidePanel.Body>
                <TabContent activeTab={tab}>
                    <TabPane tabId="accident">
                        <InsuranceSidePanelAccident
                            updateInjury={handleUpdateInjury}
                            {...props}
                            statuses={statuses}
                            injury={injury}
                            toggleChequeModal={toggleChequeModal}
                            setCheques={setCheques}
                            canReadCheques={canReadCheques}
                            cheques={cheques}
                            getCheques={getCheques}
                        />
                    </TabPane>
                    <TabPane tabId="injury">
                        <InsuranceSidePanelInjury
                            updateInjury={handleUpdateInjury}
                            syncNewVal={syncNewVal}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            refreshTableWithApi={refreshTableWithApi}
                            {...props}
                            injury={injury}
                        />
                    </TabPane>
                    <TabPane tabId="insurance">
                        <InsuranceSidePanelInfo
                            updateInjury={handleUpdateInjury}
                            {...props}
                            injury={injury}
                            handleCatch={handleCatch}
                        />
                    </TabPane>
                </TabContent>
            </SidePanel.Body>
        </OverlayLoader>
    )
}

export default InsuranceSidePanel;