import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { fail, success } from '@spordle/toasts';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import ConfirmModal from '../../../../components/confirmModal/ConfirmModal';
import CanDoAction from '../../../../components/permissions/CanDoAction';
import EmptyLayout from '../../../../components/table/EmptyLayout';
import { FormsContext } from '../../../../contexts/FormsContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';

const FormsList = (props) => {
    const formsContext = useContext(FormsContext);

    return (
        <Card body className="card-shadow">
            <SpordleTableProvider
                id='CatalogFormsListTable'
                dataIndex='custom_form_id'
                viewMode='GRID'
                searchKeys={[ 'name' ]}
                gridConfig={{
                    col: { xs: 12 },
                    gridRender: (row, spordleTable) => (
                        <div className="border-top p-3">
                            <Row className="align-items-center">
                                <Col xs="10">
                                    <Link to={{ pathname: 'forms/edit', search: '?formId=' + row.custom_form_id + '&formName=' + row.name }}>
                                        {row.name}<i className="mdi mdi-chevron-right ml-1" />
                                    </Link>
                                    <div className="text-muted small"><Translate id='catalog.forms.components.formList.table.column.updated' />: <DateFormat value={row.updated_at} /></div>
                                </Col>
                                <Col xs="2" className="text-right">
                                    <ConfirmModal
                                        color='danger'
                                        modalContent={row.name}
                                        modalTitle='catalog.forms.components.formList.table.delete'
                                        translateModalTitle
                                        actionButtonText='misc.delete'
                                        translateActionButtonText
                                        onConfirm={() => {
                                            return formsContext.deleteForm(row.custom_form_id)
                                                .then(() => {
                                                    spordleTable.refreshTable();
                                                    success();
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                        }}
                                        toggler={(toggle) => (
                                            <UncontrolledDropdown tag="span">
                                                <DropdownToggle color="link">
                                                    <i className="mdi mdi-dots-vertical font-22" />
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <CanDoAction action='EDIT' componentCode='catalog' componentPermissionCode='form_settings'>
                                                        {/* TODO: change these also */}
                                                        <DropdownItem tag={Link} to={{ pathname: 'forms/edit', search: '?formId=' + row.custom_form_id + '&formName=' + row.name }}><Translate id='misc.edit' /></DropdownItem>
                                                        <DropdownItem tag={Link} to={{ pathname: 'forms/edit', search: '?formId=' + row.custom_form_id + '&formName=' + row.name + '&duplicate=true' }}><Translate id='catalog.forms.components.formList.table.duplicate' /></DropdownItem>
                                                    </CanDoAction>
                                                    <CanDoAction action='DELETE' componentCode='catalog' componentPermissionCode='form_settings'>
                                                        <DropdownItem onClick={toggle}>
                                                            <Translate id='misc.delete' />
                                                        </DropdownItem>
                                                    </CanDoAction>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}
                                    />
                                </Col>
                            </Row>
                        </div>
                    ),
                }}
                pagination={20}
                loadData={(from, _filters, spordleTable) => {
                    switch (from){
                        case 'REFRESH':
                            spordleTable.setLoading();
                        case 'CDM':
                            return formsContext.getForms();
                        default:
                            break;
                    }
                }}
                emptyLayout={<EmptyLayout />}
            >
                <div className='mb-2'>
                    <div className='d-flex flex-wrap justify-content-between'>
                        <SpordleTableProvider.SearchInput />
                        <div>
                            <SpordleTableProvider.Refresh />
                            <CanDoAction action='ADD' componentCode='catalog' componentPermissionCode='form_settings'>
                                <Button color="primary" className='ml-2' tag={Link} to='forms/create'><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                            </CanDoAction>
                        </div>
                    </div>
                </div>
                <SpordleTableView />
                <div className="border-top" />
            </SpordleTableProvider>
        </Card>
    );
}

export default FormsList;