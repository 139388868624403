import Translate from "@spordle/intl-elements"
import { fail } from "@spordle/toasts"
import { useContext, useState } from "react"
import { ModalHeader } from "reactstrap"
import AnalyticsModal from "../../../../../../analytics/AnalyticsModal"
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI"
import CrossFade from "../../../../../../components/crossFade/CrossFade"
import { useSteps } from "../../../../../../components/customHooks/useSteps"
import OverlayLoader from "../../../../../../components/loading/OverlayLoader"
import { AuthContext, PeriodsContext } from "../../../../../../contexts/contexts"
import { I18nContext } from "../../../../../../contexts/I18nContext"
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext"
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext"
import { RolesContext } from "../../../../../../contexts/RolesContext"
import { getFormattedAdditionalFields } from "../../../../../../helpers/additionalFieldsHelpers"
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper"
import ConfirmationStep from "./components/ConfirmationStep"
import CreateOrgStep from "./components/CreateOrgStep"
import CreateUsersStep from "./components/CreateUsersStep"
import OrgSelectionStep from "./components/OrgSelectionStep"

const OrganizationGroupingAddModal = ({ isOpen, ...props }) => {
    const [ currentStep, { next, previous, goTo } ] = useSteps(4, 0)

    return (
        <AnalyticsModal isOpen={isOpen} analyticsName='OrganizationGroupingAddModal' size={currentStep === 1 ? 'lg' : undefined} onClosed={() => goTo(0)}>
            <OrganizationGroupingAddModalInner {...props} currentStep={currentStep} next={next} previous={previous} />
        </AnalyticsModal>
    )
}

const OrganizationGroupingAddModalInner = ({ from, toggle, currentStep, next, previous, onSuccess, ...props }) => {
    const [ formData, setFormData ] = useState(null);
    const [ changeOrg, setChangeOrg ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const orgContext = useContext(OrganizationContext);
    const periodContext = useContext(PeriodsContext);
    const authContext = useContext(AuthContext);
    const rolesContext = useContext(RolesContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const { getGenericLocale } = useContext(I18nContext);

    const getFormGroups = () => {
        return orgContext.additionalFieldsComponents?.find((a) => a.component === 'ORGANISATION')?.groups || []
    }

    // first step
    const verifyEmail = async(user) => {
        const dataUser = await authContext.getUsersList('email', '=', user.email);
        const dataOrg = await orgContext.getOrganization(user.organizationId);
        const dataRole = await rolesContext.getRole(user.roleId);
        return { ...user, organization: dataOrg, role: dataRole, existingUser: dataUser.Users?.[0] ? dataUser.Users[0] : null }
    }

    // Checks if only needs to link or create too
    const addUser = (user) => {
        if(user.existingUser){ // If user exists, only link
            return linkUser(user, user.organizationId, user.roleId);
        } // else create user
        return createAndLinkUser(user);

    }

    // links existing user to role
    const linkUser = (user, orgId, roleId, isNew) => (
        identityRolesContext.linkIdentityToRole(user.existingUser.Username, orgId, roleId)
            .then(() => ({ ...user, isNew: isNew }))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    let msg;
                    if(error.i18n){
                        msg = displayI18n('message', error.i18n, error.message, getGenericLocale())
                    }else{
                        switch (error.message){
                            case '3090':
                            case '3096':
                                msg = `contexts.identityRolesContext.errors.${error.message}`;
                                break;
                            default:
                                msg = 'organization.profile.users.multiple.addUser.modal.error.link';
                                break;
                        }
                    }
                    return { ...user, error: msg }
                }
            })
    )

    // Creates user
    const createAndLinkUser = (user) => (
        authContext.createUser({ name: user.firstName, familyName: user.lastName, email: user.email })
            .then((newUser) => linkUser({ ...user, existingUser: newUser }, user.organizationId, user.roleId, true))
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    return { ...user, error: 'organization.profile.users.multiple.addUser.modal.error.create' }
                }
            })
    )

    const createGrouping = (data, setStatus) => {
        setIsLoading(true);
        orgContext.createOrganization({ ...data.newOrg, ...data.newOrg.address, street: data.newOrg.address.address, fields: getFormattedAdditionalFields(data.newOrg.fields, getFormGroups(), getGenericLocale()) })
            .then(async(newOrgId) => {
                await orgContext.createAffiliatedOrganization({
                    organisation_id: newOrgId,
                    period_id: periodContext.selectedPeriod.period_id,
                    affiliated_organisations: data.orgs.reduce((newArray, orgId) => {
                        data.divisions.forEach(({ divisionId, categoryIds }) => {
                            if(categoryIds.length > 0){
                                categoryIds.forEach((categoryId) => {
                                    newArray.push({
                                        linked_organisation_id: orgId,
                                        division_id: divisionId,
                                        team_category_id: categoryId,
                                        active: true,
                                    })
                                })
                            }else{
                                newArray.push({
                                    linked_organisation_id: orgId,
                                    division_id: divisionId,
                                    active: true,
                                })
                            }
                        })
                        return newArray;
                    }, []),
                })

                await Promise.all([
                    ...data.users.map((user) => (
                        new Promise(async(resolve) => {
                            const verifiedUser = await verifyEmail({ ...user, organizationId: newOrgId });
                            const addedUser = await addUser(verifiedUser);
                            resolve(addedUser);
                        })
                    )),
                ])

                setChangeOrg({
                    identityRole: {
                        identity_role_id: identityRolesContext.identity_role_id,
                        organisation: identityRolesContext.organisation,
                        role: identityRolesContext.role,
                    },
                    org: {
                        organisation_id: newOrgId,
                        organisation_name: data.newOrg.name,
                        i18n: null,
                        logo: null,
                        abbreviation: data.newOrg.abbreviation,
                    },
                })

                setIsLoading(false)
                next()
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error)
                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                    setIsLoading(false)
                }
            })
    }

    const toggleWithOnSuccess = () => {
        toggle();
        onSuccess();
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader toggle={changeOrg ? toggleWithOnSuccess : toggle}>
                <Translate id={'organization.grouping.add.title.' + from} />
            </ModalHeader>
            <CrossFade isVisible={currentStep === 0} unmountOnExit mountOnEnter>
                <CreateOrgStep
                    from={from} formData={formData}
                    onSubmit={(values) => {
                        setFormData({
                            ...formData,
                            newOrg: {
                                ...values,
                            },
                        })
                        next();
                    }}
                    toggle={toggle}
                    getFormGroups={getFormGroups}
                />
            </CrossFade>
            <CrossFade isVisible={currentStep === 1} unmountOnExit mountOnEnter>
                <OrgSelectionStep
                    from={from} formData={formData}
                    onSubmit={(values) => {
                        setFormData({
                            ...formData,
                            ...values,
                        })
                        next();
                    }}
                    toggle={toggle} previous={previous}
                    actionButtonText='misc.next'
                />
            </CrossFade>
            <CrossFade isVisible={currentStep === 2} unmountOnExit mountOnEnter>
                <CreateUsersStep
                    from={from} formData={formData}
                    setFormData={setFormData}
                    toggle={toggle} onSubmit={createGrouping}
                    previous={previous}
                />
            </CrossFade>
            <CrossFade isVisible={currentStep === 3} unmountOnExit mountOnEnter>
                <ConfirmationStep from={from} changeOrg={changeOrg} toggleWithOnSuccess={toggleWithOnSuccess} />
            </CrossFade>
        </OverlayLoader>
    )
}

export default OrganizationGroupingAddModal