import Translate, { DateFormat } from "@spordle/intl-elements";
import { UncontrolledCollapse } from "reactstrap";
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import PropTypes from 'prop-types';

/* JSDoc to have to autocomplete */
/**
 * @typedef {Object} InvoiceItem
 * @property {Record<string, any>} invoiceItem
 */
/**
 * Display a Cancelled By or a Confirmed By for an invoice item
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & InvoiceItem} props
 * @returns
 */
const ConfirmCancelledBy = ({ invoiceItem, ...props }) => {
    let index = null;
    switch (getConfirmCancelled(invoiceItem)){
        case 'CONFIRMED':
            index = {
                general: 'CONFIRMED',
                date: 'confirmed_at',
                by: 'confirmed_by',
            };
            break;
        case 'CANCELLED':
            index = {
                general: 'CANCELLED',
                date: 'cancelled_at',
                by: 'cancelled_by',
            };
            break;
        case 'PENDING':
            break;
    }

    return (index &&
        <div {...props}>
            <div className="text-muted">
                <Translate id={`memberProfile.registration.sidepanel.${index.general === 'CANCELLED' ? 'cancelRegistration' : 'confirmRegistration'}.by`} />
            </div>
            {(index.general === 'CANCELLED' || index.general === 'CONFIRMED') &&
                <UserDisplay>
                    <UserDisplay.Container>
                        <UserDisplay.Title id='cancel-reason-toggler' className={index.general === 'CANCELLED' ? 'clickable' : undefined}>
                            {invoiceItem[index.by] ? <span>{invoiceItem[index.by].name} {invoiceItem[index.by].family_name}</span> : <Translate id='memberProfile.registration.cancelledBy.anonynmous' />}
                            {invoiceItem[index.date] && <span className='text-muted small ml-1'>(<Translate id='memberProfile.registration.confirmCancelledBy.on' /> <DateFormat value={invoiceItem[index.date]} />)</span>}
                            {index.general === 'CANCELLED' && <i className='mdi mdi-information-outline text-primary ml-1' />}
                        </UserDisplay.Title>
                    </UserDisplay.Container>
                </UserDisplay>
            }
            {index.general === 'CANCELLED' &&
                <UncontrolledCollapse toggler='#cancel-reason-toggler'>
                    {invoiceItem.cancellation_reason}
                </UncontrolledCollapse>
            }
        </div>
    );
}

ConfirmCancelledBy.propTypes = {
    invoiceItem: PropTypes.object.isRequired,
};

/**
 * Returns the registration status of an invoice item
 * @param {Object} invoiceItem
 * @returns {'CONFIRMED'|'CANCELLED'|'PENDING'}
 */
export const getConfirmCancelled = (invoiceItem) => {
    if(invoiceItem.cancelled_at && invoiceItem.confirmed_at){
        if(invoiceItem.cancelled_at < invoiceItem.confirmed_at){
            return 'CONFIRMED';
        }

        return 'CANCELLED';

    }
    if(invoiceItem.confirmed_at){
        return 'CONFIRMED';
    }

    if(invoiceItem.cancelled_at){
        return 'CANCELLED';
    }

    return 'PENDING';
}

export default ConfirmCancelledBy;