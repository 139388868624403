import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { Button, Col, FormGroup, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { object } from "yup";
import dashboardData from "../../dashboardData";
import DashboardWidget from "../../DashboardWidget";
import { FormikDateTime, FormikSelect } from '@spordle/formik-elements';
import { useLayoutEffect } from "react";

const WidgetConfiguration = ({ goToPrevious, addWidget, toggle, selectedWidgetId }) => {
    const widget = dashboardData.availableWidgets.find((w) => w.widgetId === selectedWidgetId)

    useLayoutEffect(() => {
        window.dispatchEvent(new Event('resize')); // to update graphs width
    }, [])

    return (
        <Formik
            initialValues={{
                // filters here
            }}
            validationSchema={object().shape({
                // filters here
            })}
            onSubmit={(values) => {
                addWidget(widget)
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody className="p-3">
                        <Row className="my-n3 min-vh-50">
                            <Col sm='12' lg='4' className="border-bottom border-lg-right border-lg-bottom-0 py-3">
                                <FormGroup>
                                    <Label for='filter1' className='text-muted'>Filter 1</Label>
                                    <FormikSelect id='filter1' name='filter1' disabled loadingStatus="success" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='filter2' className='text-muted'>Filter 2</Label>
                                    <FormikSelect id='filter2' name='filter2' disabled loadingStatus="success" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='filter3' className='text-muted'>Filter 3</Label>
                                    <FormikSelect id='filter3' name='filter3' disabled loadingStatus="success" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='filter4' className='text-muted'>Filter 4</Label>
                                    <FormikDateTime id="filter4" name='filter4' inputProps={{ disabled: true }} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='filter5' className='text-muted'>Filter 5</Label>
                                    <FormikDateTime id="filter5" name='filter5' inputProps={{ disabled: true }} />
                                </FormGroup>
                            </Col>

                            <Col sm='12' lg='8' className="py-3 d-flex flex-center flex-column">
                                <div className={"d-flex dashboard-addModal-preview-" + widget.type}>
                                    <DashboardWidget isPreview widget={widget} />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' outline onClick={goToPrevious} type='button'><Translate id='misc.previous' /></Button>
                        <div className="ml-auto">
                            <Button color='primary' type='submit'><Translate id='misc.add' /></Button>
                            <Button color='primary' outline onClick={toggle} type='button' className="ml-2"><Translate id='misc.cancel' /></Button>
                        </div>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

export default WidgetConfiguration