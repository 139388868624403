import { FormikSelect, FormikSwitch } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { success, fail } from "@spordle/toasts";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FormGroup, Label } from "reactstrap";
import { array, bool, object, string } from "yup";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import FormikEditable from "../../../../../components/formik/FormikEditable";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { OfficiatingCoachContext } from "../../../../../contexts/OfficiatingCoachContext";
import { HoverableCopy } from "../../../../../helpers/clipboardHelper";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";

const OfficiatingCoachesSidePanel = ({ selectedRows, syncRows, isReadOnly, createNewValues, tableRef, forceCloseSidePanel, supervisionTypes }) => {
    const { updateMemberOfficiatingCoach, deleteMemberOfficiatingCoach } = useContext(OfficiatingCoachContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const officiatingCoach = selectedRows[0];

    const handleOnDelete = () => {
        setIsLoading(true);

        deleteMemberOfficiatingCoach(officiatingCoach.member_id, officiatingCoach?.member_officiating_coach?.member_officiating_coach_id)
            .then(() => {
                setIsLoading(false);
                success();
                tableRef.deleteRow(officiatingCoach.member_id).then(forceCloseSidePanel)
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e);
                    setIsLoading(false);
                    fail({ info: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, skipInfoTranslate: true });
                }
            })
    }

    return (
        <OverlayLoader isLoading={isLoading}>
            <SidePanel.Header>
                <div className="mb-3 d-flex align-items-center">
                    <SidePanel.ToggleButton />
                    <SidePanel.ActionsMenu
                        action="DELETE"
                        componentCode="members"
                        componentPermissionCode="manage_official_supervisions"
                    >
                        <SidePanel.MenuAction onClick={handleOnDelete}>
                            <Translate id='components.assignOfficiatingPermissionsModal.removeAccess.btn' />
                        </SidePanel.MenuAction>
                    </SidePanel.ActionsMenu>
                </div>
                <SidePanel.Subtitle>Officiating Coach</SidePanel.Subtitle>
                <SidePanel.Title>{officiatingCoach.first_name} {officiatingCoach.last_name}</SidePanel.Title>
                <SidePanel.Subtitle>
                    <HoverableCopy toCopy={officiatingCoach.unique_identifier}>
                        <Link to={`/members/profile/${officiatingCoach.member_id}`}>
                            #{officiatingCoach.unique_identifier} <i className="mdi mdi-chevron-right" />
                        </Link>
                    </HoverableCopy>
                </SidePanel.Subtitle>
            </SidePanel.Header>
            <SidePanel.Body>
                <CanDoAction action="ADD" componentCode="members" componentPermissionCode="member_national_officiating_coach">
                    <FormGroup>
                        <FormikEditable
                            id="national_officiating_coach"
                            className="editable-transparent"
                            disabled={isReadOnly}
                            initialValues={{
                                national_officiating_coach: officiatingCoach.member_officiating_coach.national_officiating_coach == "1",
                            }}
                            validationSchema={object().shape({
                                national_officiating_coach: bool(),
                            })}
                            onSubmit={(values) => {
                                const newVal = values.national_officiating_coach ? "1" : "0";

                                if(newVal !== officiatingCoach.member_officiating_coach.national_officiating_coach){
                                    setIsLoading(true);

                                    const apiValues = {
                                        active: officiatingCoach.member_officiating_coach.active,
                                        national_officiating_coach: newVal,
                                        supervision_type_ids: officiatingCoach.member_officiating_coach.supervision_types.map((type) => type.supervision_type_id),
                                    };

                                    return updateMemberOfficiatingCoach(
                                        officiatingCoach.member_id,
                                        officiatingCoach?.member_officiating_coach?.member_officiating_coach_id,
                                        apiValues,
                                    )
                                        .then(() => {
                                            success();
                                            setIsLoading(false);
                                            syncRows(createNewValues({
                                                member_officiating_coach: {
                                                    ...officiatingCoach?.member_officiating_coach,
                                                    national_officiating_coach: newVal,
                                                },
                                            }))
                                        })
                                        .catch((e) => {
                                            if(!AxiosIsCancelled(e.message)){
                                                console.error(e);
                                                setIsLoading(false);
                                                fail();
                                            }
                                        })
                                }


                            }}
                        >
                            {(isEditing, options, formik) => {
                                if(!isEditing){
                                    return (
                                        <div className="font-medium">
                                            {officiatingCoach.member_officiating_coach.national_officiating_coach == "1" ?
                                                <>
                                                    <i className="mdi mdi-check text-success" /> <Translate id='components.assignOfficiatingPermissionsModal.isNational' />
                                                </>
                                                :
                                                <>
                                                    <i className="mdi mdi-close text-danger" /> <Translate id="components.assignOfficiatingPermissionsModal.isNotNational" />
                                                </>
                                            }
                                        </div>
                                    )
                                }
                                return (
                                    <div className="d-flex align-items-center mt-3">
                                        <FormikSwitch id="national_officiating_coach" name="national_officiating_coach" />
                                        <Label check className="ml-2" for="national_officiating_coach">
                                            <Translate id='components.assignOfficiatingPermissionsModal.isNational' />
                                        </Label>
                                    </div>
                                )
                            }}
                        </FormikEditable>
                    </FormGroup>
                </CanDoAction>
                <FormGroup>
                    <div className="text-muted"><Translate id="components.assignOfficiatingPermissionsModal.supervisionTypes" /></div>
                    <FormikEditable
                        id="supervision-date"
                        className="editable-transparent"
                        noConfirmation
                        disabled={isReadOnly}
                        initialValues={{
                            supervision_type_ids: officiatingCoach.member_officiating_coach.supervision_types.map((type) => type.supervision_type_id),
                        }}
                        validationSchema={object().shape({
                            supervision_type_ids: array().of(string()).min(1, <Translate id='components.assignOfficiatingPermissionsModal.supervisionTypes.validation' />),
                        })}
                        onSubmit={(values) => {
                            const isDiff = officiatingCoach.member_officiating_coach.supervision_types.length !== values.supervision_type_ids.length || officiatingCoach.member_officiating_coach.supervision_types.filter((type) => !values.supervision_type_ids.some((id) => id === type.supervision_type_id)).length > 0;

                            if(isDiff){
                                setIsLoading(true);

                                const apiValues = {
                                    active: officiatingCoach.member_officiating_coach.active,
                                    national_officiating_coach: officiatingCoach.member_officiating_coach.national_officiating_coach,
                                    supervision_type_ids: values.supervision_type_ids,
                                };

                                return updateMemberOfficiatingCoach(
                                    officiatingCoach.member_id,
                                    officiatingCoach?.member_officiating_coach?.member_officiating_coach_id,
                                    apiValues,
                                )
                                    .then(() => {
                                        success();
                                        setIsLoading(false);
                                        syncRows(createNewValues({
                                            member_officiating_coach: {
                                                ...officiatingCoach?.member_officiating_coach,
                                                supervision_types: supervisionTypes?.filter((type) => values.supervision_type_ids.some((id) => id === type.supervision_type_id)),
                                            },
                                        }))
                                    })
                                    .catch((e) => {
                                        if(!AxiosIsCancelled(e.message)){
                                            console.error(e);
                                            setIsLoading(false);
                                            fail();
                                        }
                                    })
                            }


                        }}
                    >
                        {(isEditing, options, formik) => {
                            if(!isEditing){
                                return (
                                    <div className="font-medium">
                                        <Translate id="components.assignOfficiatingPermissionsModal.supervisionTypes.count" values={{ count: officiatingCoach.member_officiating_coach.supervision_types.length }} />
                                    </div>
                                )
                            }
                            return (
                                <FormikSelect
                                    multi
                                    clearable
                                    search={false}
                                    id="supervision_type_ids"
                                    menuIsDefaultOpen
                                    autoFocus
                                    name="supervision_type_ids"
                                    renderOption={({ option }) => (
                                        <DisplayI18n
                                            field="name"
                                            i18n={option.i18n}
                                            defaultValue={option.name}
                                        />
                                    )}
                                    loadingStatus='success'
                                    options={supervisionTypes ? supervisionTypes.map((type) => ({ ...type, label: type.name, value: type.supervision_type_id })) : []}
                                />
                            )
                        }}
                    </FormikEditable>
                </FormGroup>
                <div className="p-2 bg-light rounded-lg border">
                    <div className="text-dark font-medium mb-2"><Translate id="components.assignOfficiatingPermissionsModal.supervisionTypes.selected" /></div>
                    <ul className="mb-0 pl-3">
                        {officiatingCoach.member_officiating_coach.supervision_types.map((type) => (
                            <li key={type.supervision_type_id}>
                                <DisplayI18n
                                    field="name"
                                    i18n={type.i18n}
                                    defaultValue={type.name}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </SidePanel.Body>
        </OverlayLoader>
    );
}

export default OfficiatingCoachesSidePanel;