import React from 'react';
import {
    Row,
    Col,
    Fade,
    Spinner
} from 'reactstrap';

export default class SidePanelMemberProfile extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loadingState: 'success',
        }

        this.detailsData = {
            firstName: 'Bob',
            lastName: 'Woods',
            memberNumber: '#1500000000077627',
            organization: 'Spordle ID',
            dateOfBirth: '1987-04-15',
        }
    }

    componentDidMount(){
        // API call with this.props.memberId
        // ...
        /*setTimeout(() => {
            this.setState({
                loadingState: 'success'
            })
        }, 1000);*/
    }

    render(){
        return (
            <>
                {this.state.loadingState === 'lazy' ?
                    <Fade in={this.state.loadingState === 'lazy'}>
                        <div className='d-flex justify-content-center p-5'>
                            <Spinner type='grow' color='primary' />
                        </div>
                    </Fade>
                    : this.state.loadingState === 'success' ?
                        <Fade in={this.state.loadingState === 'success'}>
                            <div className="p-3 border-bottom">
                                <div className="h4 font-bold mb-3">Profile Info</div>
                                <div className="mb-3">
                                Male | English | 29 y/o ({this.detailsData.dateOfBirth})
                                </div>
                                <Row form>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted">NCCP</div>
                                            <div className="font-medium">#435345345</div>
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div className="text-muted">Respect in Sports</div>
                                            <div className="font-medium">#435345345</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="p-3">
                                <div className="h4 font-bold mb-3">Contact</div>
                                <div className="mb-3">
                                    <div className="h6 font-medium mb-2">Primary</div>
                                    <div>602 Boulevard du Curé-Boivin #101, <br />  Boisbriand, Quebec J7G 2A7</div>
                                    <div><a href="tel:+19999999999">(999) 999-9999</a></div>
                                    <div className="text-truncate" title="b.wood@gmail.com">
                                        <a href="mailto:b.wood@gmail.com">b.wood@gmail.com</a>
                                    </div>
                                </div>
                                <div className="h6 font-medium mb-2 text-danger">Emergency Contact(s)</div>
                                <Row form>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div>Johanne Wood</div>
                                            <div><a href="tel:+18888888888">(888) 888-8888</a></div>
                                            <div className="text-truncate" title="j.wood@gmail.com">
                                                <a href="mailto:j.wood@gmail.com">j.wood@gmail.com</a>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <div className="mb-3">
                                            <div>George Wood</div>
                                            <div><a href="tel:+17777777777">(777) 777-7777</a></div>
                                            <div className="text-truncate" title="mistergeorge.wood@gmail.com">
                                                <a href="mailto:mistergeorge.wood@gmail.com">mistergeorge.wood@gmail.com</a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <hr />
                        missing:
                            <ul>
                                <li>Member type</li>
                                <li>Division / Category</li>
                                <li>Association infos
                                    <ul>
                                        <li>Primary org + team</li>
                                        <li>Shared org + team</li>
                                    </ul>
                                </li>
                            </ul>
                        </Fade>
                        : this.state.loadingState === 'error' ?
                            <Fade className='p-3' in={this.state.loadingState === 'error'}>
                                <div className='d-flex justify-content-center'>
                                    <div className="h2">An error occurred</div>
                                </div>
                            </Fade>
                            : null}
            </>
        );
    }
}