import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form } from "formik";

import { Button } from 'reactstrap';

import { SpordletablePanelContext } from '../sidePanel/SpordlePanel';

import { stringBuilder } from "@spordle/helpers";
import Translate from '@spordle/intl-elements';

import { IdentityRolesContext } from '../../contexts/IdentityRolesContext';

/**
 * @typedef {object} FormikEditableProps
 * @property {string} id
 * @property {function} children
 * @property {boolean} [noConfirmation] Will automagically submit form on onBlur
 * @property {boolean} [disabled] Will manage power_user/isGod automagically
 * @property {boolean} [readOnly] Overwrite on the behavior, will stay read only
 * @property {boolean} [manualIcon]
 * @property {function} [clearable] Will add an extra "clear" button. Only works if values can be cleared and submitted.
 * @property {array} [clearableFields] Will specify what fields to clear
 */

/**
 * @type {React.FC<FormikEditableProps & import('formik').FormikConfig>}
 * Component to help with editing data "on the fly"
 */
const FormikEditable = ({ id, ...props }) => {
    const [ canClear, setCanClear ] = useState(false);
    const [ isEditing, setIsEditing ] = useState(false);
    const [ showCloseError, setCloseError ] = useState(false);
    const formikRef = useRef(null);
    const wrapperRef = useRef(null);
    const spordlePanel = useContext(SpordletablePanelContext);

    const identityRolesContext = useContext(IdentityRolesContext);

    const disabled = props.readOnly ? true : (props.disabled && !identityRolesContext.isGod()); // permit edit if we are god

    useLayoutEffect(() => {
        function handleClickOutside(event){
            if(wrapperRef.current && !wrapperRef.current.contains(event.target)){
                setIsEditing(false);
            }
        }

        if(!props.noConfirmation) document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [])

    useEffect(() => {
        spordlePanel?.askBeforeClose(id, isEditing, () => { setCloseError(true) });
        if(isEditing && props.clearable){
            initCanClear();
        }
    }, [ isEditing ])

    useEffect(() => {
        setIsEditing(false);
    }, [ (id || '') + JSON.stringify(props.initialValues) ])// Acts as an id

    const initCanClear = () => {
        if(formikRef.current?.values){
            let initCanClear = false;
            const clearableFields = props.clearableFields || []

            Object.keys(formikRef.current?.values).forEach((key) => {
                if(clearableFields.includes(key) && formikRef.current?.values[key] !== ''){
                    initCanClear = true;
                }
            })
            setCanClear(initCanClear);
        }
    }

    const stopEditing = () => {
        if(props.noConfirmation){
            formikRef.current?.submitForm();
        }
    }

    const startEditing = () => {
        setIsEditing(true);
    }

    const clearAndSubmit = () => {
        if(formikRef.current?.values){
            const clearableFields = props.clearableFields || []
            const myValues = {}
            // Clear all formik values
            Object.keys(formikRef.current?.values).forEach((key) => {
                if(clearableFields.includes(key)){
                    myValues[key] = '';
                }
            })

            // Submit formik
            formOnSubmit(myValues, formikRef.current)
        }
    }

    const formOnSubmit = async(values, formikBag) => {
        if(props.onSubmit)
            await props.onSubmit(values, formikBag);
        setIsEditing(false);
        setCloseError(false);
    }

    return (
        !isEditing ?
            <div id={`${id}-editable`} className={stringBuilder('d-flex align-items-center', props.className, { 'editable': !disabled })} onClick={() => { if(!disabled)setIsEditing(true) }}>
                {props.children(isEditing, { startEditing: startEditing })}
                {!disabled && !props.manualIcon && <i className={`mdi mdi-pencil ml-2 ${(props.disabled && identityRolesContext.isGod()) ? 'text-purple' : 'text-primary'}`} />}
            </div>
            : // isEditing
            <div onBlur={stopEditing} ref={wrapperRef}>
                <Formik
                    {...props}
                    onSubmit={formOnSubmit}
                    innerRef={formikRef}
                >
                    {(formik) => (
                        <Form className="position-relative" onChange={() => setCanClear(true)} id={`${id}-form`}>
                            {props.children(isEditing, { stopEditing: stopEditing }, formik)}
                            {!props.noConfirmation &&
                                <div className={stringBuilder('position-absolute top-100 right-0 z-index-1 pt-1', { 'd-flex w-100': props.clearable })}>
                                    {(props.clearable && canClear) &&
                                        <Button color='danger' size='sm' outline type='button' className={stringBuilder('mr-auto', { 'invisible': !(props.clearable && canClear) })} onClick={(e) => { e.stopPropagation(); clearAndSubmit(); }} disabled={formik.isSubmitting} id={`${id}-clear`}>
                                            <i className="ti-trash mr-1" /><Translate id='misc.clear' />
                                        </Button>
                                    }
                                    <Button size="sm" color='primary' className='mr-1' type='submit' disabled={formik.isSubmitting} id={`${id}-submit`}>
                                        <i className='fas fa-check' />
                                    </Button>
                                    <Button size="sm" color='white' className='border bg-white' type='button' id={`${id}-cancel`} onClick={(e) => { setIsEditing(false); e.stopPropagation(); setCloseError(false); }} disabled={formik.isSubmitting}>
                                        <i className='fas fa-times' />
                                    </Button>
                                </div>
                            }
                        </Form>
                    )}
                </Formik>
                {showCloseError && <div className='small text-danger'><Translate id='sidePanel.spordlePanel.preventClose.title' /></div>}
            </div>
    )
}

FormikEditable.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    noConfirmation: PropTypes.bool,
    disabled: PropTypes.bool,
    manualIcon: PropTypes.bool,
    clearable: PropTypes.bool,
    clearableFields: PropTypes.array,
}

export default FormikEditable;