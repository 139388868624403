import { FormikSelect } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import { MembersContext } from '../../../../../contexts/MembersContext';
import FormikEditable from '../../../../formik/FormikEditable';
import { success } from '@spordle/toasts';

const TransferInvoice = ({ canEditTransfer, transfer, setLoading, updateData, handleError, transferLoading }) => {
    const memberContext = useContext(MembersContext);

    return (
        <FormikEditable
            noConfirmation
            id='editTransferInvoice'
            disabled={!canEditTransfer || transfer.transfer_request_state === 'CLOSED' || transferLoading}
            readOnly={transfer.transfer_request_state === 'CLOSED'}
            initialValues={{
                invoice: transfer.invoice || '',
            }}
            onSubmit={({ invoice }) => {
                if(invoice !== (transfer.invoice || '')){
                    setLoading(true);
                    return memberContext.updateMemberTransferPartial(transfer.member.member_id, transfer.member_transfer_id, {
                        invoice: invoice,
                    })
                        .then(async() => {
                            await updateData({ // Updating data without having to make the api call
                                ...transfer,
                                invoice: invoice,
                            }, false);
                            success();
                        })
                        .catch(handleError)
                        .finally(() => setLoading(false))
                }
            }}
        >
            {(isEditing, options) => {
                if(!isEditing){
                    return transfer.invoice ? <Translate id={'transfer.invoice.' + transfer.invoice} /> : '-'
                }
                return (
                    <FormikSelect
                        name='invoice'
                        id='invoice'
                        onOptionSelected={() => {
                            options.stopEditing();
                        }}
                        search={false}
                        autoFocus
                        menuIsDefaultOpen
                        defaultData={[
                            {
                                value: 'CHARGE',
                                label: 'transfer.invoice.CHARGE',
                                translateLabel: true,
                            },
                            {
                                value: 'NO_CHARGE',
                                label: 'transfer.invoice.NO_CHARGE',
                                translateLabel: true,
                            },
                        ]}
                        loadingStatus='success'
                    />
                )

            }}
        </FormikEditable>
    )
}

export default TransferInvoice
