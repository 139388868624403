import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext, useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Table
} from "reactstrap";
import CardSectionTitle from '../../../../../../components/CardSectionTitle';
import SpordlePanelTable from "../../../../../../components/sidePanel/SpordlePanel";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import DiscriminationSidePanel from "../../../../../tasks/discrimination/components/sidePanel/DiscriminationSidePanel";
import DiscriminationAddModal from "../../../../../tasks/discrimination/components/modal/DiscriminationAddModal";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { Link } from "react-router-dom";
import { ClaimStatus, DiscriminationOutcomes, DiscriminationReasons } from "../../../../../tasks/discrimination/components/DiscriminationHelper";
import { RolesContext } from "../../../../../../contexts/RolesContext";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import CanDoAction from "../../../../../../components/permissions/CanDoAction";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { stringBuilder } from "@spordle/helpers";

// Renamed to "Maltreatment Complaints"
const MemberDiscrimination = ({ godAccess }) => {
    const { currentMember, getMemberDiscriminations } = useContext(MembersContext);
    const [ addModalIsOpen, setAddModalIsOpen ] = useState(false);
    const [ status, setStatus ] = useState([]);
    const { getDiscriminationStatus } = useContext(OrganizationContext);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const canEdit = canDoAction("EDIT", "organization", "discrimination_claims") || isGod();

    useEffect(() => {
        getDiscriminationStatus({ active: 1 })
            .then(setStatus)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    return (
        <Card id="memberDiscriminationCard" className="card-shadow">
            <CardBody>
                <CardSectionTitle title='discrimination.title' titleClassname={godAccess ? "text-purple" : undefined} />
                <SpordlePanelTable
                    dataIndex='discrimination_id'
                    allowOutsideClick
                    sidePanel={(props) => <DiscriminationSidePanel status={status} canEdit={canEdit} {...props} />}
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='discrimination'
                                tableHover bordered striped
                                clickable
                                pagination={5}
                                ref={panelProps.spordleTableRef}
                                dataIndex='discrimination_id'
                                searchKeys={[
                                    { name: "sequential_number", weight: 2 },
                                    { name: "reference_number", weight: 2 },
                                    'complaint.complaint_by_member.unique_identifier',
                                    'team.name',
                                    'travel_event_name',
                                    'location_name',
                                    'travel_permit_status.name',
                                    'start_date',
                                    'end_date',
                                    'organisation.organisation_name',
                                    'organisation.abbreviation',
                                ]}
                                desktopWhen
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                loadData={(from, _data, spordleTable) => {
                                    switch (from){
                                        case 'REFRESH':
                                            spordleTable.setLoading()
                                        case 'CDM':
                                            return getMemberDiscriminations(currentMember.member_id);
                                        default:
                                            break;
                                    }
                                }}
                                columns={[
                                    {
                                        label: '#',
                                        key: 'sequential_number',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='discrimination.global.incidentDate' />,
                                        key: 'incident_date',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='discrimination.label.submittedBy' />,
                                        key: 'created_by',
                                        sortKey: 'complaint_by_first_name',
                                        fallbackSortKey: 'complaint_by_last_name',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='discrimination.global.discriminatoryGrounds' />,
                                        key: 'origin',
                                        sortable: false,
                                    },
                                    {
                                        label: <Translate id='discrimination.label.outcomes' />,
                                        key: 'outcomes',
                                        sortable: false,
                                    },
                                    {
                                        label: <Translate id="misc.status" />,
                                        key: 'status',
                                        sortable: false,
                                    },
                                ]}
                                renderRow={(columnKey, complaint) => {
                                    switch (columnKey){
                                        case 'sequential_number':
                                            return (
                                                <span className="text-nowrap">
                                                    <div className="font-medium">
                                                        {complaint.sequential_number}
                                                    </div>
                                                    {complaint.reference_number &&
                                                        <div className="font-medium text-muted small mt-2">
                                                            <Translate id="discrimination.global.itpReferenceNumber" />:
                                                            <div>#{complaint.reference_number}</div>
                                                        </div>
                                                    }
                                                </span>
                                            )
                                        case 'incident_date':
                                            return (
                                                <span className="text-nowrap">
                                                    <div className="font-medium">
                                                        <DateFormat value={complaint.incident_date} utc />
                                                    </div>
                                                    <div className="font-medium text-muted small">
                                                        <DateFormat format="HH:mm" value={complaint.incident_date} utc />
                                                    </div>
                                                </span>
                                            )
                                        case 'created_by':
                                            return (
                                                <>
                                                    {complaint.complaint_by &&
                                                        <UserDisplay.Subtitle className="text-uppercase"><Translate id={'discrimination.global.roles.' + complaint.complaint_by} /></UserDisplay.Subtitle>
                                                    }
                                                    <UserDisplay.Title>{complaint.complaint_by_first_name} {complaint.complaint_by_last_name}</UserDisplay.Title>
                                                    {complaint.complaint_by_member &&
                                                        <UserDisplay.Subtitle className="text-uppercase">
                                                            <Link to={`/members/profile/${complaint.complaint_by_member.member_id}`}>
                                                                #{complaint.complaint_by_member.unique_identifier} <i className="mdi mdi-chevron-right" />
                                                            </Link>
                                                        </UserDisplay.Subtitle>
                                                    }
                                                </>
                                            )
                                        case 'origin':
                                            return <DiscriminationReasons reasons={complaint.discrimination_reason.split(',')} />
                                        case 'status':
                                            return <ClaimStatus discriminationStatus={complaint.discrimination_status} />
                                        case 'outcomes':
                                            return <DiscriminationOutcomes outcomes={complaint.maltreatment_outcomes || []} />
                                        default:
                                            break;
                                    }
                                }}
                                onColumnClick={(e, data) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            panelProps.onSingleSelectChange(data);
                                            break;
                                    }
                                }}
                                rowIsHighlighted={(data) => data.checked}
                                emptyLayout={
                                    <Table bordered striped hover>
                                        <tbody>
                                            <tr>
                                                <td className='text-center'>
                                                    {(canDoAction('ADD', 'organization', 'discrimination_claims') || isGod()) ?
                                                        <button className={stringBuilder('font-medium reset-btn text-primary', { 'text-purple': !canDoAction('ADD', 'organization', 'discrimination_claims') && isGod() })} type='button' onClick={() => setAddModalIsOpen(true)}><i className='mdi mdi-plus' /><Translate id='misc.add' /> <Translate id="discrimination.title" /></button>
                                                        :
                                                        <Translate id="misc.search.empty.title" />
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                }
                            >
                                <div className='mb-2'>
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <SearchInput />
                                        <div className='d-flex ml-auto text-right'>
                                            <Refresh />
                                            <CanDoAction
                                                action="ADD"
                                                componentCode="organization"
                                                componentPermissionCode="discrimination_claims"
                                            >
                                                <Button type="button" className='ml-2' color='primary' onClick={() => setAddModalIsOpen(true)}>
                                                    <i className='ti-plus' /> <Translate id='misc.add' />
                                                </Button>
                                                <DiscriminationAddModal status={status} fromMemberView isOpen={addModalIsOpen} toggle={() => setAddModalIsOpen(false)} />
                                            </CanDoAction>
                                        </div>
                                    </div>
                                </div>
                                <SpordleTableView />
                            </SpordleTableProvider>
                        )
                    }}
                />
            </CardBody>
        </Card>
    )
}

export default MemberDiscrimination;