export default{
    "organization.profile.users.addUser.title"          : "Add User",
    "organization.profile.users.mutilple.addUser.title" : "Summary",

    // Initial form
    "organization.profile.users.addUser.fields.organization"               : "Organization",
    "organization.profile.users.addUser.fields.role"                       : "Role",
    "organization.profile.users.addUser.fields.noRole"                     : "No Roles",
    "organization.profile.users.addUser.fields.role.placeholder"           : "Select a Role",
    "organization.profile.users.addUser.fields.role.inactive"              : "This Role is inactive",
    "organization.profile.users.addUser.verifyEmail"                       : "Verify Email",
    "organization.profile.users.addUser.validation.role.required"          : "Role is required",
    "organization.profile.users.addUser.validation.organization.required"  : "Organization is required",
    "organization.profile.users.addUser.dates.optional"                    : "Optional",
    "organization.profile.users.addUser.dates.start"                       : "The User will have access to this Role from",
    "organization.profile.users.addUser.dates.end"                         : "Until",
    "organization.profile.users.addUser.dates.startDate.format.validation" : "Start Date must be of a valid format",
    "organization.profile.users.addUser.dates.endDate.format.validation"   : "End Date must be of a valid format",
    "organization.profile.users.addUser.dates.validation"                  : "The Start Date must be before the End Date",

    // User doesn't exist
    "organization.profile.users.addUser.create.title"         : "Create user",
    "organization.profile.users.addUser.doesntExist.topText"  : "No user is associated with that email address",
    "organization.profile.users.addUser.doesntExist.topText2" : "Please fill out the form to create a user and automatically link it to the selected Organization and Role",

    // User exists
    "organization.profile.users.addUser.exists.topText"  : "This email is already registered in our system.",
    "organization.profile.users.addUser.exists.topText2" : "Would you like to link the user to the selected Organization and Role?",
    "organization.profile.users.addUser.exists.topText3" : "The user has been linked to this role and organization.",

    "organization.profile.users.addUser.linked"           : "The user has been linked to the selected Role and Organization",
    "organization.profile.users.addUser.createdAndLinked" : "This user has been created and linked to the selected Role and Organization",

    // Add multiple users
    "organization.profile.users.multiple.addUser.card.title"         : "New user",
    "organization.profile.users.multiple.addUser.modal.btn"          : "Add other users",
    "organization.profile.users.multiple.addUser.modal.msg"          : "Summary of added users.",
    "organization.profile.users.multiple.addUser.modal.error.create" : "Something went wrong when creating this user.",
    "organization.profile.users.multiple.addUser.modal.error.link"   : "Something went wrong when linking this user.",
    "organization.profile.users.multiple.addUser.linked"             : "Linked",
    "organization.profile.users.multiple.addUser.createdAndLinked"   : "Created and linked",
    "organization.profile.users.multiple.addUser.duplicate"          : "The email should be unique",
    "organization.profile.users.multiple.addUser.grid"               : "Add using a grid",
    "organization.profile.users.multiple.addUser.grid.message"       : "You can add multiple users using this grid. The users will be submitted into the main view for validation and confirmation.",
}