/**
 * @param {object} props
 * @param {"right"|"left"} props.direction
 * @returns
 */
const ArrowBtn = ({ direction, setCurrentStep, currentStep }) => {
    return (
        <div onClick={() => setCurrentStep(currentStep + (direction === "left" ? -1 : 1))} className="sid-btn-tour">
            <i className={`font-18 mdi mdi-arrow-${direction}`} />
        </div>
    );
}

export default ArrowBtn;