import questionType from "./customFormEditor";
import formEditor from "./formEditor";
import formsCreate from "./formsCreate";
import formsEdit from "./formsEdit";
import formsList from "./formsList";

export default{
    ...questionType,
    ...formEditor,
    ...formsCreate,
    ...formsEdit,
    ...formsList,
}