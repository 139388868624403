import { Tooltip } from "@mantine/core";
import { stringBuilder } from "@spordle/helpers";
import { useContext } from "react";
import { Button, Spinner } from "reactstrap";
import { AppContext } from "../../../../contexts/contexts";

const ToolBtn = ({ label, className, isLoading, disabled, icon, ...props }) => {
    const { isMobileDevice } = useContext(AppContext);

    return (
        isMobileDevice ?
            <Button
                {...props}
                disabled={disabled || isLoading}
                className={stringBuilder(className, `mr-1 rounded bg-transparent position-relative border-0 text-body font-18 mdi mdi-${icon}`)}
                type="button"
                size="sm"
                color="light"
            >
                {isLoading && <Spinner className="position-absolute top-0 right-0 mt-1 mr-1" style={{ width: "0.5rem", height: "0.5rem" }} type="grow" color="primary" size="sm" />}
            </Button>
            :
            <Tooltip
                label={label}
                withArrow
            >
                <Button
                    {...props}
                    disabled={disabled || isLoading}
                    className={stringBuilder(className, `mr-1 rounded bg-transparent border-0 text-body position-relative font-18 mdi mdi-${icon}`)}
                    type="button"
                    size="sm"
                    color="light"
                >
                    {isLoading && <Spinner className="position-absolute top-0 right-0 mt-1 mr-1" style={{ width: "0.5rem", height: "0.5rem" }} type="grow" color="primary" size="sm" />}
                </Button>
            </Tooltip>
    );
}

export default ToolBtn;