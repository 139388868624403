import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import {
    Button,
    FormGroup,
    Label
} from "reactstrap";
import { Formik, Form } from 'formik';
import { FormikError, FormikInputText, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import Required from '../../../formik/Required';
import SubmitTicketTypeData from './SubmitTicketTypeData';
import { JiraContext } from '../../../../contexts/JiraContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { AuthContext } from '../../../../contexts/contexts';
import { array, object, string } from 'yup';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import FileUpload from '../../../uploader/fileUpload/FileUpload';
import CrossFade from '../../../crossFade/CrossFade';
import CustomAlert from '../../../CustomAlert';

/**
* this is only the creation form
*/
const SubmitTicketCreate = ({ back, type, types, toggleLoading, success }) => {
    const jiraContext = useContext(JiraContext);
    const orgContext = useContext(OrganizationContext);
    const authContext = useContext(AuthContext);

    return (
        <Formik
            initialValues={{
                summary: '',
                description: '',
                priority: '3',
                story: '',
                attachments: [],
            }}
            validationSchema={object().shape({
                summary: string().required(<Translate id='helpCenter.submitTicket.create.subject.required' />),
                description: string().required(<Translate id='form.validation.description.required' />),
                attachments: array(),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                toggleLoading(true);
                const newVal = { ...values, request_type_id: type, page_url: window.location.href };
                const _navigator = {};

                for(const i in navigator) _navigator[i] = navigator[i];

                delete _navigator.plugins;
                delete _navigator.mimeTypes;

                newVal.browser_info = JSON.stringify(_navigator);

                jiraContext.createRequest(orgContext.organisation_id, authContext.account.userName, newVal)
                    .then((newRequest) => {

                        if(values.attachments[0]){
                            jiraContext.createTemporaryFile(values.attachments[0])
                                .then(async(tempFile) => {
                                    const tempId = tempFile.temporaryAttachments[0].temporaryAttachmentId;
                                    await jiraContext.createAttachment(newRequest.issueKey, {
                                        attachment_ids: [ tempId ],
                                    })
                                    toggleLoading(false);
                                    success(newRequest.issueKey);
                                })
                                .catch((error) => {
                                    if(AxiosIsCancelled(error.message)){
                                        console.error(error.message);
                                        setSubmitting(false);
                                        setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                    }
                                })

                        }else{
                            toggleLoading(false);
                            success(newRequest.issueKey);
                        }
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            toggleLoading(false);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                            setSubmitting(false);
                        }
                    });
            }}
        >
            {(formik) => (
                <Form>
                    {process.env.REACT_APP_ENVIRONMENT === 'uat' &&
                        <FormGroup>
                            <Label for='story' className='text-muted'><Translate id='helpCenter.submitTicket.create.story' /></Label>
                            <FormikSelect
                                id='story' name='story'
                                loadData={(from) => {
                                    switch (from){
                                        case 'CDM':
                                            return jiraContext.getJiraStories()
                                                .then((results) => {
                                                    return results.issues?.map((issue) => ({
                                                        value: issue.key,
                                                        label: issue.fields.summary,
                                                        issue: issue,
                                                    }))
                                                })
                                    }
                                }}
                                searchKeys={[
                                    'label',
                                    'value',
                                ]}
                                renderOption={({ option }) => (
                                    <div className='d-flex align-items-center'>
                                        <img src={option.issue.fields.priority.iconUrl} width='16' className='mr-2' />
                                        <div className='ml-1'>
                                            <span className='font-medium mr-1'>{option.value}:</span>
                                            {option.label}
                                        </div>
                                    </div>
                                )}
                            />
                        </FormGroup>
                    }

                    <FormGroup>
                        <Label for='subject' className='text-muted'><Translate id='helpCenter.submitTicket.create.subject' /> <Required /></Label>
                        <FormikInputText id='summary' name='summary' />
                        <div className="mt-3">
                            <Label for='subject' className='text-muted'><Translate id='form.fields.description' /> <Required /></Label>
                            <FormikTextArea rows="4" type="textarea" id="description" name="description" />
                            {/* <FormikInputText rows="4" type="textarea" id="description" name="description" /> */}

                            {/* Can't use TinyMCE because html shows in JIRA description
                            <FormikTiny
                                id="description"
                                name='description'
                                init={{
                                    toolbar: 'undo redo | formatselect | bold italic | removeformat',
                                }}
                            /> */}
                        </div>

                    </FormGroup>

                    <FormGroup>
                        <Label className='text-muted'><Translate id='supportRequests.profile.attachment.addModal.file' /></Label>
                        <FileUpload
                            onFileSelect={(files) => {
                                formik.setFieldValue('attachments', files);
                            }}
                            dropzoneProps={{
                                multiple: false,
                                accept: "image/jpeg, image/png, image/jpg",
                                maxSize: 20971520,
                            }}
                        />
                        <FormikError name='attachments' />
                    </FormGroup>


                    {type == SubmitTicketTypeData.bug &&
                            <FormGroup>
                                <Label for='priority' className='text-muted'><Translate id='helpCenter.submitTicket.create.priority' /></Label>
                                {/* eventually, this select should have the description .text-muted.small under the option text */}
                                <FormikSelect
                                    name='priority'
                                    id='priority'
                                    search={false}
                                    defaultData={[
                                        {
                                            label: 'helpCenter.submitTicket.create.priority.5',
                                            translateLabel: true,
                                            value: '5',
                                        },
                                        {
                                            label: 'helpCenter.submitTicket.create.priority.3',
                                            translateLabel: true,
                                            value: '3',
                                        },
                                        {
                                            label: 'helpCenter.submitTicket.create.priority.1',
                                            translateLabel: true,
                                            value: '1',
                                        },
                                    ]}
                                    loadingStatus='success'
                                />
                            </FormGroup>
                    }

                    <CrossFade isVisible={!!formik.status} appear mountOnEnter unmountOnExit>
                        {formik.status &&
                            <CustomAlert className='mt-3' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                        }
                    </CrossFade>
                    <div className="d-flex justify-content-between mt-3 border-top pt-3">
                        <Button color='primary' type="button" outline onClick={() => back()} disabled={formik.isSubmitting || types.length == 1}><Translate id='misc.previous' /></Button>
                        <Button color='primary' type="submit" disabled={formik.isSubmitting}><Translate id='helpCenter.submitTicket.create.send' /></Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default SubmitTicketCreate;