import Translate from "@spordle/intl-elements";
import { fire, removeToast } from "@spordle/toasts";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ModalBody, ModalHeader } from "reactstrap";
import CrossFade from "../../../../../../../../components/crossFade/CrossFade";
import InlineCopy from "../../../../../../../../components/inlineCopy/InlineCopy";
import QuickViewButton from "../../../../../../../../components/quickView/QuickViewButton";
import UserDisplay from "../../../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../../../components/UserImg";
import { OrganizationContext } from "../../../../../../../../contexts/OrganizationContext";
import { DisplayCategory } from "../../../../../../../teams/TeamHelpers";
import AddOutcomeForm from "./AddOutcomeForm";
import { useHistory } from "react-router";
import { SpordletablePanelContext } from "../../../../../../../../components/sidePanel/SpordlePanel";

const isTeam = (maltreatment) => (maltreatment.discrimination_member || []).filter((member) => member.first_name)?.length <= 0;

const AddMultipleOutcomes = ({ editOutcomeData, ...props }) => {
    const [ step, setStep ] = useState(0);
    const { organisation_id, getDiscriminationSpecific } = useContext(OrganizationContext);
    const [ otherMaltreatments, setOtherMaltreatments ] = useState(null);
    const [ values, setValues ] = useState([]);
    const history = useHistory();
    const { forceCloseSidePanel } = useContext(SpordletablePanelContext);

    useEffect(() => {
        Promise.all(editOutcomeData.maltreatmentIds.map((id) => getDiscriminationSpecific(organisation_id, undefined, id).catch(console.error)))
            .then((maltreatments) => {
                setOtherMaltreatments(maltreatments.reduce((other, mal) => {
                    if(mal){
                        other.push(mal);
                    }

                    return other;
                }, []));
            })
    }, []);

    const handleComplete = (theValues) => {

        theValues.forEach((value) => {
            const member = value.maltreatment.discrimination_member[0];
            const id = value.maltreatment.maltreatment_id || value.maltreatment.discrimination_id
            const isAgainstTeam = isTeam(value.maltreatment);

            fire({
                id: id,
                permanent: true,
                msg: (
                    <span className="font-normal">
                        <Translate
                            id="discrimination.outcomes.addSuspension.toast"
                            values={{ name: <b><br />{isAgainstTeam ? `${member.team?.name || member.team_name}` : `${member.first_name} ${member.last_name}`}</b> }}
                        />
                        <small className="d-block">
                            {isAgainstTeam ? member.team?.unique_identifier ? `#${member.team?.unique_identifier}` : "" : member.member.unique_identifier && `#${member.member.unique_identifier}`}
                        </small>
                    </span>
                ),
                info: (
                    <button
                        type="button"
                        onClick={() => {
                            forceCloseSidePanel();
                            history.push({
                                search: `search=${id}&addSuspension=true`,
                            });
                            removeToast(id);
                        }}
                        className="reset-btn text-primary stretched-link"
                    >
                        <Translate id="discrimination.outcomes.addSuspension" />
                    </button>
                ),
            });
        });

        props.refreshTable();
        props.refreshData({});
        props.toggle();
    }

    return (
        <>
            {[ props.maltreatment, ...(otherMaltreatments || []) ].map((maltreatment, i) => (
                <CrossFade isVisible={step === i} key={maltreatment.maltreatment_id || maltreatment.discrimination_id}>
                    <AddOutcomeForm
                        {...props}
                        maltreatment={maltreatment}
                        toggle={() => {
                            if(values){
                                handleComplete(values);
                            }else{
                                props.toggle();
                            }
                        }}
                        disabled={!otherMaltreatments}
                        editOutcomeData={editOutcomeData}
                        onSuccess={(val) => {

                            if(i !== (otherMaltreatments || []).length){ // Do not remove 1 from length because props.maltreatment
                                setValues((prev) => [ ...prev, { maltreatment: maltreatment, values: val } ]);
                                setStep((prev) => prev + 1)
                            }else{
                                handleComplete([ ...values, { maltreatment: maltreatment, values: val } ])
                            }
                        }}
                        skip={i == (otherMaltreatments || []).length ? void 0 : () => setStep((prev) => prev + 1)}
                        header={(isLoading) => (
                            <>
                                <ModalHeader toggle={isLoading ? void 0 : props.toggle}>
                                    <Translate id='discrimination.outcomes.addOutcomes' /> ({step + 1} / {editOutcomeData.maltreatmentIds.length + 1})
                                </ModalHeader>
                                <ModalBody className="pb-0">
                                    <OutcomeHeaderCard maltreatment={maltreatment} />
                                </ModalBody>
                            </>
                        )}
                    />
                </CrossFade>
            ))}
        </>
    );
}

const OutcomeHeaderCard = ({ maltreatment }) => {
    if(!maltreatment)return null;

    const isAgainstTeam = isTeam(maltreatment);
    const member = maltreatment.discrimination_member[0];

    return (
        <>
            <div className="mb-2">
                {isAgainstTeam ?
                    <Translate
                        id='discrimination.sidePanel.people.complaineeTeam'
                    />
                    :
                    <Translate
                        id='discrimination.sidePanel.people.complainees'
                        values={{ count: maltreatment.discrimination_member.length }}
                    />
                }
            </div>
            <UserDisplay card block className="mb-0">
                <UserDisplay.Container>
                    {isAgainstTeam ?
                        <UserImg
                            className="text-uppercase"
                            width={50}
                            src={null}
                            alt={member.team?.name || member.team_name || "unknown"}
                            abbr={member.team?.name || member.team_name || "unknown"}
                        />
                        :
                        <UserImg
                            className="text-uppercase"
                            width={50}
                            src={member.member?.picture?.full_path}
                            filePos={member.member?.picture?.file_position}
                            abbr={(member.first_name?.charAt(0) + member.last_name?.charAt(0)) || ""}
                            alt={member.first_name + ' ' + member.last_name}
                        />
                    }
                </UserDisplay.Container>
                <UserDisplay.Container>
                    {isAgainstTeam ?
                        <>
                            <UserDisplay.Title>{member.team?.name || member.team_name}</UserDisplay.Title>
                            {member.team?.short_name &&
                                <UserDisplay.Subtitle>{member.team.short_name}</UserDisplay.Subtitle>
                            }
                            {member.team?.unique_identifier &&
                            <UserDisplay.Subtitle>
                                <InlineCopy toCopy={member.team.unique_identifier}>
                                    #{member.team.unique_identifier}
                                </InlineCopy>
                            </UserDisplay.Subtitle>
                            }
                            {member.team?.category &&
                            <UserDisplay.Subtitle>
                                <DisplayCategory category={member.team.category} short />
                            </UserDisplay.Subtitle>
                            }
                        </>
                        :
                        <>
                            <UserDisplay.Title>
                                <QuickViewButton member={{ ...member.member }}>{member.first_name} {member.last_name}</QuickViewButton>
                            </UserDisplay.Title>
                            {member.member?.unique_identifier && (
                                <UserDisplay.Subtitle>
                                #{member.member.unique_identifier}
                                </UserDisplay.Subtitle>
                            )}
                            <UserDisplay.Subtitle>
                                {(!!member.team_name || !!member.team) && (
                                    <span className="d-block">
                                        <span className="font-medium mr-1"><Translate id="discrimination.label.team" /> :</span>
                                        {member.team?.name || member.team_name} {member.team?.category &&
                                        <>(<DisplayCategory category={member.team.category} short />)</>
                                        }
                                    </span>
                                )}
                                {!!member.role &&
                                <FormattedMessage id={`discrimination.global.roles.${member.role}`} defaultMessage="noTranslate">
                                    {(message) => (message[0] !== 'noTranslate') ? message : member.role || ''}
                                </FormattedMessage>
                                }
                            </UserDisplay.Subtitle>
                        </>

                    }
                </UserDisplay.Container>
            </UserDisplay>
        </>
    )
}

export default AddMultipleOutcomes;