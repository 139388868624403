import React from "react";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../components/sidePanel/SidePanel";
import { fail } from '@spordle/toasts';
// Contexts
import { InstallmentModesContext } from "../../../../../contexts/InstallmentModesContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import withContexts from "../../../../../helpers/withContexts";
import InstallmentSidepanelInstallment from "./InstallmentSidepanelInstallment";

class InstallmentSidepanel extends React.Component{

    constructor(props){
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
            isLoading: false,
        };
    }

    toggle(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    toggleIsLoading = (bool) => {
        this.setState((prevState) => ({
            isLoading: bool ?? !prevState.isLoading,
        }))
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <SidePanel.Header>
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />
                        <SidePanel.ActionsMenu action='DELETE' componentCode='catalog' componentPermissionCode='installment_mode'>
                            <SidePanel.MenuDelete
                                translateModalMsg
                                modalMsg='catalog.installment.sidepanel.catalog.delete'
                                modalTitle={this.props.selectedRows[0].name}
                                onConfirm={() => {
                                    return this.props.InstallmentModesContext.deleteInstallmentMode(this.props.getCommonValue('installment_mode_id'))
                                        .then(() => {
                                            this.props.tableRef.deleteRow(this.props.getCommonValue('installment_mode_id')).then(this.props.toggle)
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                if(error.i18n){
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }else{
                                                    switch (error.message){
                                                        case '3197':
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: `catalog.installment.delete.error.${error.message}`,
                                                            })
                                                            break;
                                                        default:
                                                            fail({
                                                                msg: 'misc.error',
                                                            })
                                                            break;
                                                    }
                                                }
                                            }
                                        })
                                }}
                            />
                        </SidePanel.ActionsMenu>
                    </div>
                    <SidePanel.Title>{this.props.selectedRows[0].name}</SidePanel.Title>
                </SidePanel.Header>
                <InstallmentSidepanelInstallment {...this.props} toggleIsLoading={this.toggleIsLoading} data={this.props.selectedRows[0]} />
            </OverlayLoader>
        )
    }
}

export default withContexts(InstallmentModesContext)(InstallmentSidepanel);