export default{
    "organization.settings.sidePanelOrganizationSuspensions.title" : "Suspension Information",

    "organization.settings.sidePanelOrganizationSuspensions.code"            : "Code",
    "organization.settings.sidePanelOrganizationSuspensions.code.required"   : "Code is required",
    "organization.settings.sidePanelOrganizationSuspensions.status"          : "Status",
    "organization.settings.sidePanelOrganizationSuspensions.status.active"   : "Active",
    "organization.settings.sidePanelOrganizationSuspensions.status.inactive" : "Inactive",
    "organization.settings.sidePanelOrganizationSuspensions.status.required" : "Status is required",

    "organization.settings.sidePanelOrganizationSuspensions.update.success.text" : "Suspension updated",
    "organization.settings.sidePanelOrganizationSuspensions.update.fail.text"    : "An error occurred during the Suspension update, please refresh the page et try again",

    // actions
    "organization.settings.sidePanelOrganizationSuspensions.actions.remove"         : "Remove",
    "organization.settings.sidePanelOrganizationSuspensions.actions.remove.topText" : "You are about to remove this Suspension from your Organization",

    // tabs
    "organization.settings.sidePanelOrganizationSuspensions.tabs.details" : "Details",
}