import { FormikDateTime } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { Button, Col, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import * as Yup from 'yup';
import Required from '../../../../../../components/formik/Required';
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../../../components/UserImg';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { fail } from '@spordle/toasts';

// contexts
import { CartsContext } from '../../../../../../contexts/CartsContext';
import { ClinicsContext } from '../../../../../../contexts/ClinicsContext';
import { FormsContext } from '../../../../../../contexts/FormsContext';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import withContexts from '../../../../../../helpers/withContexts';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';

class ClinicProfileRegistrationAdd2 extends React.Component{

    render(){
        return (
            <Formik
                initialValues={{
                    registrationDate: this.props.invoiceDate ? moment(this.props.invoiceDate) : moment(),
                    registrationTime: this.props.invoiceDate ? moment(this.props.invoiceDate) : moment(),
                }}
                validationSchema={Yup.object().shape({
                    registrationDate: Yup.mixed().test({
                        name: 'registrationDate-valid',
                        message: <Translate id='form.validation.date.format' />,
                        test: moment.isMoment,
                    })
                        .test({
                            name: 'registrationDate-before-today',
                            message: <Translate id='clinics.profile.attendees.addModal.form.registration.date.before' />,
                            test: (registrationDate) => moment.isMoment(registrationDate) && registrationDate.isBefore(new Date()),
                        }),
                    registrationTime: Yup.mixed().test({
                        name: 'registrationTime-valid',
                        message: <Translate id='form.validation.time.format' />,
                        test: moment.isMoment,
                    })
                        .when('registrationDate', (registrationDate, schema) => {
                            if(moment.isMoment(registrationDate) && registrationDate.isSame(new Date(), 'day')){ // When registrationDate is today
                                return schema.test({
                                    name: 'registrationTime-before-now',
                                    message: <Translate id='clinics.profile.attendees.addModal.form.registration.time.before' />,
                                    test: (registrationTime) => moment.isMoment(registrationTime) && registrationTime.isBefore(new Date()),
                                });
                            }
                            return schema;
                        }),
                })}
                onSubmit={(values) => {
                    this.props.setModalLoading(true);
                    const mergedMoments = moment(values.registrationDate);
                    mergedMoments.set({
                        hour: values.registrationTime.get('hour'),
                        minute: values.registrationTime.get('minute'),
                    });
                    this.props.setInvoiceDate(mergedMoments.toISOString());

                    this.props.CartsContext.getCart(this.props.shoppingCartId)
                        .then(async(cart) => {

                            if(cart.cart_detail?.[0]?.row_id){
                                // Send forms
                                if(this.props.formsId){
                                    // Get full form
                                    await this.props.FormsContext.getForm(this.props.formsId)
                                        .then((forms) => this.props.setCustomForms({
                                            rowId: cart.cart_detail.find((r) => r.item_type === 'CLINIC')?.row_id,
                                            customFormId: this.props.formsId,
                                            formGroups: forms,
                                        }))
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        })
                                }
                                // set waivers now so we can set the state so we can check if there are any both here and in the custom forms view
                                // Get current registration
                                const memberItems = this.props.CartsContext.state.cachedCart.cart_detail.filter((r) => r.member.member_id === this.props.member.member_id) // Get all items linked to the current member
                                const waivers = []
                                for(let memberItemIndex = 0; memberItemIndex < memberItems.length; memberItemIndex++){
                                    const item = memberItems[memberItemIndex];

                                    if(Array.isArray(item.waivers_to_sign)){
                                        for(let waiverTosignIndex = 0; waiverTosignIndex < item.waivers_to_sign.length; waiverTosignIndex++){
                                            const waiverToSign = item.waivers_to_sign[waiverTosignIndex];
                                            if(Array.isArray(waiverToSign.waivers)){
                                                for(let waiverIndex = 0; waiverIndex < waiverToSign.waivers.length; waiverIndex++){
                                                    waivers.push({
                                                        ...waiverToSign.waivers[waiverIndex],
                                                        organisation: waiverToSign.organisation,
                                                        item_id: waiverToSign.item_id,
                                                        row_id: item.row_id,
                                                    })
                                                }
                                            }
                                        }
                                    }
                                }

                                if(waivers.length > 0){
                                    this.props.setWaivers(waivers);
                                }

                                // Get all member identities for pre-init
                                this.props.MembersContext.getMemberAccounts(this.props.member.member_id)
                                    .then((identities) => {
                                        this.props.setMemberIdentities(identities)
                                    })
                                    .catch((error) => {
                                        if(!AxiosIsCancelled(error.message)){
                                            console.error(error.message)
                                            fail({
                                                msg: 'misc.error',
                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                skipInfoTranslate: true,
                                            })
                                        }
                                    })

                                // Set selected payment method to CASH if clinic is free and skip payment method selection step
                                if(this.props.totalAmount == 0){
                                    this.props.setSelectedPaymentMethod("CASH")
                                }

                                if(this.props.getPaymentMethods() && this.props.totalAmount > 0){
                                    // if we dont go to payment methods we can't select CREDITCARD so there's no need for a logic to go the merchant accounts here
                                    this.props.setActiveTab(3);
                                }else if(this.props.formsId){
                                    // we go to step 4 only if there's a form
                                    this.props.setActiveTab(5);
                                }else if(waivers.length > 0){ // if no form, go to waivers if there are any
                                    this.props.setActiveTab(6);
                                }else{ // no forms, no waivers -> checkout cart
                                    await this.props.checkout(this.props.shoppingCartId, undefined, mergedMoments.toISOString())
                                        .catch((e) => {
                                            console.error(e.message)
                                            fail();
                                        })
                                        .finally(() => this.props.setModalLoading(false))
                                }
                                this.props.setModalLoading(false);

                            }
                        }).catch((error) => {
                            console.error(error.message)
                            if(!AxiosIsCancelled(error.message)){
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                            this.props.setModalLoading(false);
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalBody>
                            <UserDisplay card className='w-100 mb-3 d-flex'>
                                <div className='flex-grow-0 d-inline-flex'>
                                    <UserDisplay.Container>
                                        <UserImg
                                            abbr={this.props.member.first_name.charAt(0) + this.props.member.last_name.charAt(0)}
                                            src={this.props.member.picture?.full_path}
                                            filePos={this.props.member.picture?.file_position}
                                            alt={this.props.member.first_name + ' ' + this.props.member.last_name}
                                            width="50"
                                            height="50"
                                        />
                                    </UserDisplay.Container>
                                    <UserDisplay.Container>
                                        <UserDisplay.Title>
                                            {this.props.member.first_name} {this.props.member.last_name}
                                            {this.props.member.age && <span className='text-muted'> (<Translate id='misc.yearsOld' values={{ age: this.props.member.age }} />)</span>}
                                        </UserDisplay.Title>
                                        {this.props.member.organisation &&
                                            <UserDisplay.Subtitle>
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={this.props.member.organisation.organisation_name}
                                                    i18n={this.props.member.organisation.i18n}
                                                />
                                            </UserDisplay.Subtitle>
                                        }
                                        {this.props.member.unique_identifier &&
                                            <UserDisplay.Subtitle><Translate id='clinics.profile.attendees.addModal.memberSelect.memberNumber' /> {this.props.member.unique_identifier}</UserDisplay.Subtitle>
                                        }
                                    </UserDisplay.Container>
                                </div>
                            </UserDisplay>

                            <Row>
                                <Col md='6'>
                                    <Label for="registrationDate" className="text-muted"><Translate id='clinics.profile.attendees.addModal.form.registration.date' /> <Required /></Label>
                                    <FormikDateTime
                                        name='registrationDate' id='registrationDate' timeFormat={false}
                                        isValidDate={(current) => moment(current).isBefore(new Date())}
                                    />
                                </Col>
                                <Col md='6'>
                                    <Label for="registrationTime" className="text-muted"><Translate id='clinics.profile.attendees.addModal.form.registration.time' /> <Required /></Label>
                                    <FormikDateTime name='registrationTime' id='registrationTime' dateFormat={false} />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            {this.props.goToPrevious &&
                                <Button color='primary' outline type='button' onClick={this.props.goToPrevious} className="mr-auto"><Translate id='misc.previous' /></Button>
                            }
                            <Button color='primary' type='submit'><Translate id='misc.next' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default withContexts(ClinicsContext, FormsContext, CartsContext, MembersContext)(ClinicProfileRegistrationAdd2)