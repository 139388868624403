import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { triggerDownload } from '../../../components/uploader/uploadHelpers';
import { ReportsContext } from '../../../contexts/ReportsContext';
import queryString from 'query-string';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import moment from 'moment';
import Clock from '../../../assets/images/icons/Clock';
import DocumentIcon from '../../../assets/images/icons/Document';
import { fail } from '@spordle/toasts';
import EmptyLayout from '../../../components/table/EmptyLayout';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import { Tooltip } from '@mantine/core';

const ReportExports = () => {
    const reportsContext = useContext(ReportsContext);
    const location = useLocation();

    useEffect(() => {
        const requestReportId = queryString.parse(location.search).request_report_id;
        if(requestReportId){
            reportsContext.getReportDownload(requestReportId).then((report) => {
                triggerDownload(report.download_link)
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });
        }
    }, [ location ])

    return (
        <div>
            <SpordleTableProvider
                id='ReportsExportListTable'
                tableHover clickable
                viewMode='GRID'
                dataIndex='request_report_id'
                onColumnClick={() => {}}
                automaticRefresh={reportsContext.state.isGenerating ? 60000 : 300000}
                emptyLayout={<EmptyLayout hideArrow translateMsg msg='reports.exports.noExports' />}
                gridConfig={{
                    col: {
                        sm: 12,
                        md: 6,
                        lg: 4,
                        className: '',
                    },
                    row: {
                        form: true,
                    },
                    gridRender: (report) => {
                        const isExpiredOrNoData = report.request_status === 'PENDING' || report?.request_status === 'IN_PROGRESS' ? false : new Date(report.expired_at) - new Date() < 0;
                        return (
                            <Card
                                style={{ 'opacity': isExpiredOrNoData && (report?.request_status !== 'PENDING' || report?.request_status !== 'IN_PROGRESS') ? 0.5 : 1, overflow: 'hidden' }}
                                className={report?.request_status === 'COMPLETED' && !isExpiredOrNoData ? 'card-shadow card-hover rounded-lg clickable mb-2 text-dark' : 'card-shadow rounded-lg mb-2 disabled'}
                                onClick={() => {
                                    if(report?.request_status === 'COMPLETED' && !isExpiredOrNoData){
                                        reportsContext.getReportDownload(report?.request_report_id)
                                            .then((reportDownload) => {
                                                triggerDownload(reportDownload?.download_link, 'name');
                                            }).catch((error) => {
                                                fail({ msg: <Translate id={`reports.exports.errors.${error.message}`} defaultMessageId='misc.error' />, skipMsgTranslate: true });
                                                if(!AxiosIsCancelled(error.message)){
                                                    console.error(error.message)
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }
                                            })
                                    }
                                }}
                            >
                                <CardBody className="d-flex align-items-center">
                                    <div className='mr-3'>
                                        {report.request_status !== 'PENDING' && report.request_status !== 'IN_PROGRESS' ?
                                            <div className="position-relative">
                                                <DocumentIcon style={{ width: 60 }} />
                                            </div>
                                            :
                                            <div className="position-relative">
                                                <DocumentIcon style={{ width: 60 }} />
                                                <div className="bg-white rounded-circle p-1 bottom-0 right-0 mr-n2 mb-n2 position-absolute">
                                                    <Clock className="text-warning" style={{ width: 25 }} />
                                                </div>
                                            </div>
                                        }
                                        {/* {report?.request_status === 'COMPLETED' && !isExpiredOrNoData ?
                                                <i style={{fontSize: 60}} className={`mdi mdi-file-document text-dark`} />
                                            :
                                                <i style={{fontSize: 60}} className={`mdi mdi-file-document text-muted`} />
                                        } */}
                                    </div>
                                    <div className="w-100">
                                        <div className='mb-1 d-flex justify-content-between line-height-1 font-bold font-14'>
                                            <span>
                                                <DisplayI18n field='name' defaultValue={report.report.name} i18n={report.report.i18n} />
                                            </span>
                                            {!isExpiredOrNoData && report.request_status === 'COMPLETED' ?
                                                <i style={{ fontSize: 20, lineHeight: 0.7, marginTop: "0.2em" }} className={`ml-auto pl-2 mdi mdi-download text-primary`} />
                                                :
                                                <i style={{ fontSize: 20, lineHeight: 0.7, marginTop: "0.2em" }} className={`ml-auto pl-2 mdi mdi-download text-muted`} />
                                            }
                                        </div>
                                        <div className='small'><Translate id='reports.exports.created' />: <DateFormat format='YYYY-MM-DD HH:MM' value={report.created_at} /></div>
                                        {isExpiredOrNoData ?
                                            <div className='small'>
                                                <i className={`text-danger mdi mdi-alert-outline mr-1`} />
                                                {report.request_status === 'NO_DATA_FOUND' ?
                                                    <Translate id='reports.exports.noData' />
                                                    :
                                                    <Translate id='reports.exports.expired_download' />
                                                }
                                            </div>
                                            : report?.request_status === 'PENDING' || report?.request_status === 'IN_PROGRESS' ?
                                                <div className='small'><Translate id='reports.exports.pending_download' /></div>
                                                :
                                                <div className='small'><Translate id='reports.exports.expires' />: <DateFormat format='YYYY-MM-DD HH:MM' value={report.expired_at} /></div>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        )
                    },
                }}
                desktopWhen='md'
                rowIsHighlighted={(contact) => !!contact.checked}
                loadData={(from, _, { setLoading }) => {
                    setLoading(true);
                    switch (from){
                        case 'REFRESH':
                        case 'CDM':
                            return reportsContext.getExportsReportList({ action: 'EXPORT' })
                                .then((data) => {
                                    const injectedData = data.reduce((acc, report) => {
                                        acc.push(report);
                                        return acc;
                                    }, [])
                                    setLoading(false)
                                    return injectedData
                                        .filter((report) => (new Date(report.expired_at) - new Date() > 0 || (report.request_status === 'PENDING' || report?.request_status === 'IN_PROGRESS')))
                                        .sort((a, b) => moment(b.created_at) - moment(a.created_at));
                                });
                        default:
                            break;
                    }
                }}
            >{(spordleTable) => (
                    <>
                        <div className="mb-3 d-flex align-items-center">
                            <div className="h3 mb-0 font-medium"><Translate id='reports.header.exports' />
                                <Tooltip
                                    withArrow
                                    label={<Translate id='misc.refresh' />}
                                    zIndex={3000}
                                >

                                    <button
                                        className={"reset-btn mdi mdi-refresh btn btn-link ml-2"}
                                        type="button"
                                        onClick={spordleTable.refreshTable}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <SpordleTableView />
                    </>
                )}
            </SpordleTableProvider>
        </div>
    );
}


export default ReportExports
