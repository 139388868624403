import { Button, ModalBody, ModalFooter, Col, FormGroup, Label, Row } from "reactstrap";
import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate, { getTranslation } from "@spordle/intl-elements";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from 'yup';

const CreateVenueStep1 = ({ toggleAddLocationForm, venueData, onSubmit, changeStep }) => {
    return (
        <Formik
            initialValues={{
                address: venueData?.address ?? "",
                locationAbbr: venueData?.locationAbbr ?? "",
                locationName: venueData?.locationName ?? "",
                locationType: venueData?.locationType ?? "",
            }}
            validationSchema={Yup.object().shape({
                address: Yup.string().required(getTranslation('organization.profile.venues.modals.components.createVenueStep1.location.error.address')),
                locationAbbr: Yup.string().required(getTranslation('organization.profile.venues.modals.components.createVenueStep1.location.error.abbr')),
                locationName: Yup.string().required(getTranslation('organization.profile.venues.modals.components.createVenueStep1.location.error.name')),
                locationType: Yup.string().required(getTranslation('organization.profile.venues.modals.components.createVenueStep1.location.error.type')),
            })}
            onSubmit={(values) => {
                changeStep(2);
                onSubmit(values);
            }}
        >
            { (formik) => (
                <FormikForm id="create-location">
                    <ModalBody>
                        <button className="small text-link bg-transparent border-0 p-0 mb-3" onClick={toggleAddLocationForm} type='button'>
                            <i className="mdi mdi-chevron-left" /><Translate id='organization.profile.venues.modals.components.createVenueStep1.btn.back' />
                        </button>
                        <Row form>
                            <Col md="12">
                                <FormGroup>
                                    <Label for="locationName" className="text-muted"><Translate id='organization.profile.venues.modals.components.createVenueStep1.location.name' /></Label>
                                    <FormikInputText name="locationName" type="text" />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="locationAbbr" className="text-muted"><Translate id='organization.profile.venues.modals.components.createVenueStep1.location.abbr' /></Label>
                                    <FormikInputText name="locationAbbr" type="text" />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="locationType" className="text-muted"><Translate id='organization.profile.venues.modals.components.createVenueStep1.location.type' /></Label>
                                    <FormikSelect
                                        id="locationType"
                                        name="locationType_CreateVenueStep1"
                                        placeholder="Location Type"
                                        loadingStatus='success'
                                        defaultData={[
                                            { id: 'locationType1', value: 'all', label: 'All Types' },
                                            { id: 'locationType2', value: 'arena', label: 'Arena' },
                                            { id: 'locationType3', value: 'ice rink', label: 'Ice Rink' },
                                        ]}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup>
                                    <Label for="address" className="text-muted"><Translate id='organization.profile.venues.modals.components.createVenueStep1.location.address' /></Label>
                                    <FormikInputText name="address" type="text" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button form="create-location" type="submit" color='primary'><Translate id='misc.next' /></Button>
                    </ModalFooter>
                </FormikForm>
            )}
        </Formik>
    )
}

export default CreateVenueStep1;