import Translate from "@spordle/intl-elements"
import { useState } from "react"
import { ModalHeader } from "reactstrap"
import AnalyticsModal from "../../../../analytics/AnalyticsModal"
import CrossFade from "../../../../components/crossFade/CrossFade"
import DataTypeList from "./DataTypeList"
import WidgetConfiguration from "./WidgetConfiguration"
import WidgetList from "./WidgetList"

const DashboardAddModal = ({ isOpen, toggle, addWidget, dashboardWidgets }) => {
    const [ activeStep, setActiveStep ] = useState('dataTypeList');
    const [ selectedDataTypeCode, setSelectedDataTypeCode ] = useState('');
    const [ selectedWidgetId, setSelectedWidgetId ] = useState('');

    const add = (widget) => {
        addWidget(widget)
        window.dispatchEvent(new Event('resize')); // trigger a resize to refresh the graphs
        toggle();
    }

    return (
        <AnalyticsModal
            analyticsName="dashboardAddModal" isOpen={isOpen}
            size={activeStep !== 'configureWidget' ? 'lg' : 'xl'}
            onClosed={() => {
                setActiveStep('dataTypeList');
                setSelectedDataTypeCode('');
            }}
            className='transition'
        >
            <ModalHeader toggle={toggle}>
                <Translate id='dashboard.addModal.title' />
            </ModalHeader>
            <CrossFade isVisible={activeStep === 'dataTypeList'}>
                <DataTypeList
                    onSubmit={(dataTypeCode) => {
                        setSelectedDataTypeCode(dataTypeCode)
                        setActiveStep('widgetList')
                    }}
                    toggle={toggle}
                />
            </CrossFade>
            <CrossFade isVisible={activeStep === 'widgetList'} unmountOnExit>
                <WidgetList
                    selectedDataTypeCode={selectedDataTypeCode}
                    dashboardWidgets={dashboardWidgets}
                    goToPrevious={() => setActiveStep('dataTypeList')}
                    goToConfigure={() => setActiveStep('configureWidget')}
                    addWidget={add}
                    toggle={toggle}
                    setSelectedWidgetId={setSelectedWidgetId}
                />
            </CrossFade>
            <CrossFade isVisible={activeStep === 'configureWidget'} unmountOnExit>
                <WidgetConfiguration
                    goToPrevious={() => setActiveStep('widgetList')}
                    addWidget={add}
                    toggle={toggle}
                    selectedWidgetId={selectedWidgetId}
                />
            </CrossFade>
        </AnalyticsModal>
    )
}

export default DashboardAddModal