export default{
    "catalog.waivers.title"             : "Waivers",
    "catalog.waivers.count"             : "{count, plural, =0 {# Waiver} =1 {# Waiver} other {# Waivers}}",
    "catalog.waivers.creation"          : "Creation",
    "catalog.waivers.edit"              : "Edit",
    "catalog.waivers.back"              : "Back to list",
    "catalog.waivers.mandatory.tooltip" : "The waiver needs to be answered during the registration process but won’t prevent adding to a team.",
    "catalog.waivers.critical.tooltip"  : "The waiver needs to be answered AND and will prevent the player to be added to a team.",
    "catalog.waivers.add.title"         : "Add a waiver",
    "catalog.waivers.add.confirmLabel"  : "Confirmation option ({lang})",
    "catalog.waivers.add.declineLabel"  : "Decline option ({lang})",
    "catalog.waivers.edit.preview"      : "Preview",

    "catalog.waivers.list.table.empty.title" : "No waiver",
}