import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Link } from 'react-router-dom';
import { mutate } from "swr";
import Translate from "@spordle/intl-elements";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import { useContext, useState } from "react";
import { TeamsContext } from "../../../../contexts/TeamsContext";
import { APPROVED, CANCEL, PENDING, PENDING_APPROVAL, REJECTED, DRAFT } from "../../TeamHelpers";
import ConfirmModal from "../../../../components/confirmModal/ConfirmModal";
import TeamHeaderExport from './TeamHeaderExport';
import ValidateMemberModal from "../../../../components/validateMember/ValidateMemberModal";
import SendCommModal from "../../../../components/communications/SendCommModal";
import { RolesContext } from "../../../../contexts/RolesContext";
import { useDisclosure } from "@mantine/hooks";
import { useIntl } from "react-intl";
import TeamSignWaiversModal from "./signWaiversModal/TeamSignWaiversModal";
import ChangeTeamOrgModal from "./changeTeamOrgModal/ChangeTeamOrgModal";
import { Tooltip } from "@mantine/core";
import TeamHeaderActionParticipate from "./actions/TeamHeaderActionParticipate";

const TeamHeaderActions = ({ teamId, hasWarnings, goToEditRoster, setApprobationModal, handleDelete, lockedRoster, refreshTeam }) => {
    const teamsContext = useContext(TeamsContext);
    const rolesContext = useContext(RolesContext);
    const intl = useIntl();
    const activeRoster = teamsContext.formatDataActive(teamsContext.rosterTablesData || [], intl);
    const canEditTeam = !lockedRoster || rolesContext.canDoAction('EDIT', 'teams', 'bypass_lock_roster_date');
    const [ sendCommIsOpen, sendCommIsOpenHandlers ] = useDisclosure(false);
    const [ signWaiversIsOpen, signWaiversIsOpenHandlers ] = useDisclosure(false);
    const [ changeTeamOrgModalIsOpen, changeTeamOrgModalIsOpenHandlers ] = useDisclosure(false);
    const [ validateMemberModalIsOpen, setValidateMemberModalIsOpen ] = useState(false);
    const toggleValidateMemberModal = () => setValidateMemberModalIsOpen(!validateMemberModalIsOpen);

    return (
        <div className='d-flex justify-content-end mb-3 float-md-right'>
            <div className='mr-1'>
                <Tooltip withArrow label={<Translate id={'teams.profile.header.verifyRules.hasWarnings'} />}>
                    <Button
                        className="text-nowrap ml-2" tag={Link} to='settings'
                        color={hasWarnings ? 'danger' : 'primary'}
                        id='team-verifyRules'
                        onClick={async() => {
                            await mutate([ 'getTeamsSettings', teamId ]);
                            const card = document.getElementById('teamRestrictionCard');

                            try{
                                window.scrollTo({
                                    behavior: 'smooth',
                                    top: card.getBoundingClientRect().top - 150, // 2 * header(70 px high) + 10px(give some edge)
                                })
                            }catch(error){
                                card?.scrollIntoView?.(true);
                            }
                        }}
                        data-tip-disable={!hasWarnings}
                    >
                        {hasWarnings && <i className='mdi mdi-information-outline mr-1' />}
                        <Translate id='teams.profile.header.verifyRules' />
                    </Button>
                </Tooltip>
            </div>

            {/* validate member modal */}
            <ValidateMemberModal
                isOpen={validateMemberModalIsOpen}
                toggle={toggleValidateMemberModal}
                team={teamsContext.cachedTeam}
            />

            {/* sign all waivers modal */}
            <TeamSignWaiversModal
                isOpen={signWaiversIsOpen}
                toggle={signWaiversIsOpenHandlers.toggle}
                teamId={teamId}
            />

            {/* change team organization modal */}
            <ChangeTeamOrgModal toggle={changeTeamOrgModalIsOpenHandlers.toggle} isOpen={changeTeamOrgModalIsOpen} teamId={teamId} />

            <UncontrolledDropdown inNavbar>
                <DropdownToggle
                    caret color="primary" outline
                    id={'team-profile-header-action-btn'}
                >
                    <Translate id="misc.actions" />
                </DropdownToggle>
                <DropdownMenu right>
                    <CanDoAction
                        action="ADD"
                        componentCode="organization"
                        componentPermissionCode="manage_competition_teams"
                    >
                        <TeamHeaderActionParticipate
                            teamId={teamId}
                            onSuccess={refreshTeam}
                            orgsAppliedTo={(teamsContext.cachedTeam.competition_teams || []).map(({ competition }) => competition.organisation?.organisation_id).removeDuplicates()}
                        />
                    </CanDoAction>
                    <CanDoAction
                        action="ADD"
                        componentCode="communications"
                        componentPermissionCode="communications_manage"
                    >
                        <DropdownItem onClick={sendCommIsOpenHandlers.open} id="team-profile-header-send-comm-roster">
                            <Translate id="communications.sendMessage" />
                        </DropdownItem>
                        <SendCommModal
                            id='commModal-team-header-actions'
                            isOpen={!!sendCommIsOpen}
                            toggle={sendCommIsOpenHandlers.close}
                            recipients={teamsContext.rosterTablesData?.map((teamMember) => teamMember.member)}
                        />
                    </CanDoAction>
                    {canEditTeam && activeRoster.length > 0 &&
                        <CanDoAction action='EDIT' componentCode='teams' componentPermissionCode='team_members' skipAccess>
                            <DropdownItem id='team-profile-header-edit-roster' onClick={goToEditRoster}><Translate id='teams.profile.roster.table.editRoster' /></DropdownItem>
                        </CanDoAction>
                    }
                    <DropdownItem
                        id='team-profile-header-validate-member'
                        disabled={!teamsContext.cachedTeam.team_id}
                        onClick={toggleValidateMemberModal}
                    >
                        <Translate id='components.validateMember.title' />
                    </DropdownItem>
                    <DropdownItem
                        id='team-profile-header-sign-waivers'
                        onClick={() => signWaiversIsOpenHandlers.open()}
                    >
                        <Translate id='teams.profile.header.signWaivers.title' />
                    </DropdownItem>

                    <DropdownItem divider />
                    {/* Submit for approval */}
                    {(!teamsContext.cachedTeam.team_status?.name ||
                        teamsContext.cachedTeam.team_status?.name?.toUpperCase().includes(DRAFT) ||
                        teamsContext.cachedTeam.team_status?.name?.toUpperCase().includes(REJECTED) ||
                        teamsContext.cachedTeam.team_status?.name?.toUpperCase().includes(PENDING_APPROVAL)) &&

                        <CanDoAction
                            id='team-profile-header-submit-approval'
                            tag={DropdownItem}
                            action='ADD'
                            componentCode='teams'
                            componentPermissionCode='submit_team_approval'
                            skipAccess
                            onClick={() => setApprobationModal(PENDING)}
                        >
                            {teamsContext.cachedTeam.team_status?.name?.toUpperCase().includes(PENDING_APPROVAL) ?
                                <Translate id='teams.teamSearch.sideTable.actionsMenu.resubmit' />
                                :
                                <Translate id='teams.teamSearch.sideTable.actionsMenu.submit' />
                            }
                        </CanDoAction>
                    }
                    {/* Approve team */}
                    {teamsContext.cachedTeam.team_status?.name?.toUpperCase().includes(PENDING) &&
                        <CanDoAction
                            id='team-profile-header-approve'
                            tag={DropdownItem}
                            action='EDIT'
                            componentCode='teams'
                            componentPermissionCode='teams_approbation'
                            skipAccess
                            onClick={() => setApprobationModal(APPROVED)}
                        >
                            <Translate id='teams.teamSearch.sideTable.actionsMenu.approve' />
                        </CanDoAction>
                    }
                    {/* Reject */}
                    {(teamsContext.cachedTeam.team_status?.name?.toUpperCase().includes(PENDING) || teamsContext.cachedTeam.team_status?.name?.toUpperCase() === APPROVED) && // Reject only if has been submitted for approval or is approved
                        <CanDoAction
                            id='team-profile-header-reject'
                            tag={DropdownItem}
                            action={'EDIT'}
                            componentCode={'teams'}
                            componentPermissionCode={'teams_approbation'}
                            skipAccess
                            onClick={() => setApprobationModal(REJECTED)}
                        >
                            <Translate id='teams.teamSearch.sideTable.actionsMenu.reject' />
                        </CanDoAction>
                    }
                    {/* Cancel approval  */}
                    {(teamsContext.cachedTeam.team_status?.name &&
                        !teamsContext.cachedTeam.team_status?.name?.toUpperCase().includes(DRAFT) &&
                        !teamsContext.cachedTeam.team_status?.name?.toUpperCase().includes(REJECTED)) && // Cancel only if has been submitted for approval or is approved
                        <CanDoAction
                            id='team-profile-header-cancel-approval'
                            tag={DropdownItem}
                            action={'ADD'}
                            componentCode={'teams'}
                            componentPermissionCode={'submit_team_approval'}
                            skipAccess
                            onClick={() => setApprobationModal(CANCEL)}
                        >
                            <Translate id='teams.teamSearch.sideTable.actionsMenu.cancel' />
                        </CanDoAction>
                    }

                    <CanDoAction componentCode="teams" componentPermissionCode="teams_profile" action="DELETE">
                        <ConfirmModal
                            toggler={(toggle) => (
                                <DropdownItem
                                    id={'team-profile-header-delete-team'} onClick={toggle}
                                >
                                    <Translate id='teams.teamSearch.sideTable.actionsMenu.delete' />
                                </DropdownItem>
                            )}
                            modalTitle={teamsContext.cachedTeam.name}
                            actionButtonText='misc.remove'
                            translateActionButtonText
                            modalMsg="teams.teamSearch.sideTable.delete.msg"
                            translateModalMsg
                            color='danger'
                            onConfirm={handleDelete}
                        />
                    </CanDoAction>

                    <CanDoAction action="EDIT" componentCode="teams" componentPermissionCode="change_team_organisation">
                        <CanDoAction action="READ" componentCode="organization" componentPermissionCode="affiliated_organization">
                            <DropdownItem divider />
                            <DropdownItem
                                id='team-profile-header-transfer-organization'
                                onClick={changeTeamOrgModalIsOpenHandlers.toggle}
                            >
                                <Translate id='teams.profile.actionsMenu.transfer' />
                            </DropdownItem>
                        </CanDoAction>
                    </CanDoAction>

                    <TeamHeaderExport team={teamsContext.cachedTeam} />
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )
}

export default TeamHeaderActions