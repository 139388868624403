import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import {
    Container
} from "reactstrap";
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import TabRoutesNavs from '../../../components/subSidebar/TabRoutesNavs';
import TabRoutesPanes from '../../../components/subSidebar/TabRoutesPanes';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { fail } from "@spordle/toasts";
import { ClinicsContext } from '../../../contexts/ClinicsContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { AppContext } from '../../../contexts/contexts';
import { displayI18n, DisplayI18n } from '../../../helpers/i18nHelper';
import clinicsTabsRoutes from '../../../routes/tabRoutes/clinicsTabsRoutes';
import ClinicHeader from './components/ClinicHeader';
import { clinicQualificationBuilder } from "../clinicHelpers";

const ClinicProfile = (props) => {
    const params = useParams()

    // clinics context
    const clinicsContext = useContext(ClinicsContext);
    const orgsContext = useContext(OrganizationContext);
    const { updateRecentlyViewed } = useContext(AppContext);
    const { clinicId } = useParams();

    useEffect(() => {
        // Preset Data
        if(props.location?.data)
            clinicsContext.presetCurrentClinic(props.location.data)

        // Load clinic with ALL the info to overwrite preset
        setOverviewLoading(true)
        clinicsContext.getAllClinicInfos(props.location.pathname.split('/')[3], { sessions: true, contacts: true, instructors: true, memberTypes: true, merchantAccounts: true })
            .then((clinic) => {
                updateRecentlyViewed({
                    label: clinic.name,
                    path: props.location.pathname,
                    i18n: {
                        en: {
                            label: `${displayI18n("name", clinic.i18n, clinic.name, "en")} - ${clinicQualificationBuilder(clinic.qualification, "en")}`,
                        },
                        fr: {
                            label: `${displayI18n("name", clinic.i18n, clinic.name, "fr")} - ${clinicQualificationBuilder(clinic.qualification, "fr")}`,
                        },
                    },
                });

                setOverviewLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    console.error(error.message);
                    props.history.replace('/clinics/404')
                }
            })
    }, [ params.clinicId ]);

    // temporary var used to seperate the host and guest view
    const [ hostView, setHostView ] = useState(true);
    const toggleHostView = () => { setHostView(!hostView) }

    const isHost = clinicsContext.currentClinic?.organisation?.organisation_id === orgsContext.organisation_id && hostView;

    // loading for overview
    const [ overviewLoading, setOverviewLoading ] = useState(false);

    // To be used as a key, force refresh to trigger refresh on next table load in different tab. Used for "add regsitration" to update transactions tab
    const [ forceRefresh, setForceRefresh ] = useState(false);

    // number of members in the waitlist, set in the loadData of the waitlist table
    const [ waitListCount, setWaitListCount ] = useState(0);

    const attendeeTable = useRef(null);
    const waitlistTable = useRef(null);

    return (
        <>
            {/*--------------------------------------------------------------------------------*/}
            {/* View Header                                                                  */}
            {/*--------------------------------------------------------------------------------*/}
            <ViewHeaderV2
                breadcrumbsProps={{
                    disablePeriodSelect: true,
                    favoriteData: clinicsContext.currentClinic ? {
                        label: clinicsContext.currentClinic.name,
                        path: props.location.pathname,
                        i18n: {
                            en: {
                                label: `${displayI18n("name", clinicsContext.currentClinic.i18n, clinicsContext.currentClinic.name, "en")} - ${clinicQualificationBuilder(clinicsContext.currentClinic.qualification, "en")}`,
                            },
                            fr: {
                                label: `${displayI18n("name", clinicsContext.currentClinic.i18n, clinicsContext.currentClinic.name, "fr")} - ${clinicQualificationBuilder(clinicsContext.currentClinic.qualification, "fr")}`,
                            },
                        },
                        translate: false,
                    } : {},
                }}
            />

            <div className={"page-content container-fluid px-4 pt-0"}>
                <Container>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Profile Header                                                                 */}
                    {/*--------------------------------------------------------------------------------*/}
                    <ClinicHeader isHost={isHost} toggleHostView={toggleHostView} attendeeTable={attendeeTable} waitlistTable={waitlistTable} waitListCount={waitListCount} />

                    {/*--------------------------------------------------------------------------------*/}
                    {/* Nav Tabs                                                                       */}
                    {/*--------------------------------------------------------------------------------*/}
                    <TabRoutesNavs routes={clinicsTabsRoutes} />
                    <TabRoutesPanes
                        routes={clinicsTabsRoutes}
                        getComponentProps={(route) => {
                            switch (route.path){
                                case '/overview':
                                    return { isHost: isHost, isLoading: overviewLoading, clinicId: clinicId, waitListCount: waitListCount };
                                case '/attendees':
                                    return { setForceRefresh: () => setForceRefresh(!forceRefresh), clinicId: clinicId, attendeeTable: attendeeTable, waitlistTable: waitlistTable };
                                case '/waitlist':
                                    return { setForceRefresh: () => setForceRefresh(!forceRefresh), clinicId: clinicId, waitlistTable: waitlistTable, setWaitListCount: setWaitListCount, attendeeTable: attendeeTable };
                                case '/invoice':
                                    return { invoiceNb: props.location.state?.invoice_number };
                                default:
                                    break;
                            }
                        }}
                    />
                </Container>
            </div>
        </>
    );
}

export default ClinicProfile;