import TourDots from "../../../assets/images/tour/dots.png";
import TourDashboard from "../../../assets/images/tour/dashbord.png";
import TourWidget1 from "../../../assets/images/tour/widget01.png";
import TourWidget2 from "../../../assets/images/tour/widget02.png";
import TourReportsDashboard from "../../../assets/images/tour/reports-dashboard.png";
import TourBtn1 from "../../../assets/images/tour/btn01.png";
import TourBtn2 from "../../../assets/images/tour/btn02.png";
import styles from "./AnimatedImg.module.scss";
import { stringBuilder } from "@spordle/helpers";

const IMAGES = {
    "dashboard": {
        "dashboard": TourDashboard,
        "reports": TourReportsDashboard,
    },
    "widget01": {
        "dashboard": TourWidget1,
        "reports": TourBtn1,
    },
    "widget02": {
        "dashboard": TourWidget2,
        "reports": TourBtn2,
    },
};

/**
 *
 * @param {dashboard|reports} props.animation
 * @returns
 */
const AnimatedImg = ({ isSlim = false, dashboardClass, widget02Class, isReverse = false, isAnimated = true, animation = "dashboard", children }) => {
    return (
        <div className={stringBuilder(styles.Container, { [styles.Slim]: isSlim })}>
            <div className={stringBuilder(styles.ContainerInner, { [styles.Slim]: isSlim, [styles.Reverse]: isReverse, [styles.Animated]: isAnimated })}>
                <img className={stringBuilder(styles.Img, styles.Dots)} alt="dots" src={TourDots} />
                <img className={stringBuilder(styles.Img, styles.Dashboard, dashboardClass)} src={IMAGES.dashboard[animation]} />
                <img className={stringBuilder(styles.Img, styles.Widget01)} src={IMAGES.widget01[animation]} />
                <img className={stringBuilder(styles.Img, styles.Widget02, widget02Class)} src={IMAGES.widget02[animation]} />
            </div>
            {children}
        </div>
    );
}

export default AnimatedImg;