import SpordleTableProvider from '@spordle/datatables';
import Translate from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from "reactstrap";
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import UserDisplay from '../../../../components/userDisplay/UserDisplay';
import UserImg from '../../../../components/UserImg';
import { IdentityRolesContext } from '../../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { RolesContext } from '../../../../contexts/RolesContext';
import { DisplayI18n } from '../../../../helpers/i18nHelper';
import MemberSearchConfirmation from './components/MemberMergeConfirmation';
import MemberMergeSearch from './components/MemberMergeSearch';
import MemberSearchValidation from './components/MemberMergeValidation';
import { fail } from '@spordle/toasts';
import { stringBuilder } from '@spordle/helpers';

const MemberMerge = (props) => {
    const [ isLarge, setIsLarge ] = useState(false);

    return (
        <AnalyticsModal
            isOpen={props.isOpen}
            size={isLarge ? 'xl' : 'md'}
            analyticsName='MemberMerge'
            onClosed={() => setIsLarge(false)}
        >
            <MemberMergeInner {...props} setIsLarge={setIsLarge} />
        </AnalyticsModal>
    )
}

/**
 * Modal used to merge members
 * @param {Object} props
 * @param {object} props.memberMerge Member that the modal receives
 * @param {boolean} props.isOpen Modal is open
 * @param {function} props.toggle Toggle Modal
 * @param {function} [props.refreshTable]
 * @returns {JSX.Element}
 */
const MemberMergeInner = ({ memberMerge, refreshTable, isOpen, toggle, setIsLarge }) => {
    const { getMembers, getAllMemberInfos, getMember } = useContext(MembersContext);
    const { canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const { organisation_id } = useContext(OrganizationContext);
    const [ view, setView ] = useState(0);
    const [ propsMember, setPropsMember ] = useState(memberMerge)
    const [ selectedMember, setSelectedMember ] = useState(null);
    const [ keptMember, setKeptMember ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const history = useHistory()

    const handleToggle = () => {
        if(refreshTable && view === 2) // refreshTable is only present when in search
            refreshTable();
        else if(propsMember.member_id == keptMember.member_id) // if the propsMember's member_id is the the same as kept member, refresh profiule  (propsMember = the member who's profile we are on and that initiated the merge)
            getAllMemberInfos(propsMember.member_id)
        else // if the keptMember is not the member on which the merge was initiated, navigate to the kept members profile
            history.push(`/members/profile/${keptMember.member_id}/overview`)

        toggle();
    }

    const isSameOrg = (member) => (member.organisation?.organisation_id === propsMember.organisation?.organisation_id)
    const canMergeDiffOrgs = canDoAction('ADD', 'members', 'merge_members_diff_organisations');

    /**
     * Returns component based on view
     * @param {number} view
     * @returns {React.ReactNode}
     */
    const getView = (view) => {
        switch (view){
            case 0:
                return (
                    <MemberMergeSearch
                        memberMerge={propsMember}
                        selected={selectedMember}
                        toggle={toggle}
                        next={() => {
                            setIsLarge(true)
                            setView(view + 1)
                        }}
                        isLoading={isLoading}
                    />
                )
            case 1:
                return (
                    <MemberSearchValidation
                        setKeptMember={setKeptMember}
                        members={[ propsMember, selectedMember ]}
                        toggle={toggle}
                        previous={() => {
                            setIsLarge(false)
                            setView(view - 1);
                            setSelectedMember(null);
                        }}
                        next={() => {
                            setIsLarge(false)
                            setView(view + 1)
                        }}
                        isSameOrg={() => isSameOrg(selectedMember)}
                    />
                )
            case 2:
                return <MemberSearchConfirmation keptMember={keptMember} toggle={toggle} handleToggle={handleToggle} />
            default:
                break;
        }
    }

    const selectMember = (member) => {
        setIsLoading(true);
        Promise.all([
            getMember(propsMember.member_id),
            getMember(member.member_id),
        ])
            .then((promises) => {
                setPropsMember(promises[0]);
                setSelectedMember(promises[1]);

                setIsLoading(false);
                setIsLarge(true)
                setView(view + 1);
            })
            .catch((error) => {
                setIsLoading(false);
                fail({
                    msg: 'misc.error',
                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                    skipInfoTranslate: true,
                })
            })
    }

    return (
        <>
            <SpordleTableProvider
                id='member-merge'
                bordered striped
                desktopWhen
                dataIndex="member_id"
                pagination={5}
                initFilter={{ search: {} }}
                emptyLayout={
                    <div className="text-center">
                        <Translate id='misc.search.empty.title' />
                    </div>
                }
                viewMode="GRID"
                gridConfig={{
                    col: { xs: 12 },
                    gridRender: (member) => (
                        <UserDisplay card className={stringBuilder({ "bg-light-inverse": (member.member_id === propsMember.member_id) }, "w-100")}>
                            <UserDisplay.Container>
                                <UserImg
                                    abbr={member.first_name.charAt(0) + member.last_name.charAt(0)}
                                    src={member.picture?.full_path}
                                    filePos={member.picture?.file_position}
                                    width={50}
                                    alt={member.first_name + ' ' + member.last_name}
                                />
                            </UserDisplay.Container>
                            <UserDisplay.Container className="flex-shrink-0">
                                <UserDisplay.Title>
                                    {member.first_name} {member.last_name}
                                    <span className="small text-muted ml-1">
                                        (<Translate id='misc.yearsOld' values={{ age: member.age }} />)
                                    </span>
                                </UserDisplay.Title>
                                <UserDisplay.Subtitle>#{member.unique_identifier}</UserDisplay.Subtitle>
                                <UserDisplay.Subtitle>
                                    <DisplayI18n
                                        field="name"
                                        defaultValue={member.organisation?.organisation_name}
                                        i18n={member.organisation?.i18n}
                                    />
                                </UserDisplay.Subtitle>
                                {(!isSameOrg(member) && !canMergeDiffOrgs) &&
                                    <UserDisplay.Subtitle>
                                        <div className='d-flex text-danger'>
                                            <i className='mdi mdi-alert-outline' />
                                            <Translate id='members.search.memberMerge.merge.missingPermissions' />
                                        </div>
                                    </UserDisplay.Subtitle>
                                }
                            </UserDisplay.Container>
                            <UserDisplay.Container className="ml-auto text-right">
                                <Button
                                    type="button"
                                    color={((!isSameOrg(member) && !canMergeDiffOrgs) && isGod()) ? "purple" : "primary"}
                                    disabled={(member.member_id === propsMember.member_id) || ((!isSameOrg(member) && !canMergeDiffOrgs) && !isGod())}
                                    onClick={() => selectMember(member)}
                                >
                                    <Translate id={(member.member_id === propsMember.member_id) ? "members.search.memberMerge.merge.alreadySelect" : "misc.select"} />
                                </Button>
                            </UserDisplay.Container>
                        </UserDisplay>
                    ),
                }}
                loadData={(from, { filters }, { setLoading }) => {
                    const formatMember = (member) => {
                        member.checked = false;
                        return member;
                    };

                    switch (from){
                        case 'FILTER':
                            setLoading();
                            return getMembers({ ...filters.search, organisation_id: organisation_id, all: 1 })
                                .then((members) => members.map(formatMember));
                        case 'CDM':
                            return Promise.resolve([]);
                        default:
                            break;
                    }
                }}
            >
                {getView(view)}
            </SpordleTableProvider>
        </>
    );
}

export default MemberMerge;