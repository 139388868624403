import { stringBuilder } from "@spordle/helpers";
import { CustomInput } from "reactstrap";
import styles from "./BannerPreview.module.scss";

/**
 * @param {object} props
 * @param {string} props.name
 * @param {function} props.onChange
 * @param {boolean} props.isSelected
 * @param {string} props.path_url
 * @param {string} props.banner_id
 */
const BannerPreview = ({ name, onChange, isSelected, path_url, code }) => {
    return (
        <label className={stringBuilder(styles.Label, { [styles.Selected]: isSelected })} htmlFor={code}>
            <CustomInput
                checked={isSelected}
                onChange={onChange}
                className={styles.Radio}
                type="radio"
                name="banner"
                id={code}
            />
            <img className={styles.Img} src={path_url} alt={name} />
        </label>
    );
}

export default BannerPreview;