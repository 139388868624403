import Translate from "@spordle/intl-elements"
import { success } from "@spordle/toasts"
import { useContext, useState } from "react"
import { ModalHeader } from "reactstrap"
import AnalyticsModal from "../../../../../../../analytics/AnalyticsModal"
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI"
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader"
import ManualRegistrationMemberIdentities from "../../../../../../../components/manualRegistration/ManualRegistrationMemberIdentities"
import { MembersContext } from "../../../../../../../contexts/MembersContext"
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper"

const EditIdentityModal = ({ isOpen, ...props }) => {

    return (
        <AnalyticsModal analyticsName="editIdentityModal" isOpen={isOpen}>
            <EditIdentityModalInner {...props} />
        </AnalyticsModal>
    )
}

const EditIdentityModalInner = ({ toggle, memberContact, refresh, onSuccess }) => {
    const membersContext = useContext(MembersContext)
    const [ isLoading, setIsLoading ] = useState(false);

    return (
        <OverlayLoader isLoading={isLoading}>
            <ModalHeader toggle={toggle}>
                <Translate id='members.profile.overview.memberContacts.sidePanel.identity.modal.title' />
            </ModalHeader>
            <ManualRegistrationMemberIdentities
                from='instructor' // to show cancel button
                memberIdentities={membersContext.currentMember.linked_identities || []}
                member={membersContext.currentMember}
                selectedIdentity={memberContact.created_by?.identity_id}
                toggle={toggle}
                submitLabel="members.profile.overview.memberContacts.sidePanel.identity.modal.link"
                hidePreviousButton
                onSubmit={(identityId, setStatus) => {
                    setIsLoading(true);
                    return membersContext.updateMemberContactPartial(membersContext.currentMember.member_id, memberContact.id, { identity_id: identityId })
                        .then(() => {
                            refresh();
                            toggle();
                            setIsLoading(false);
                            onSuccess();
                            success();
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                setIsLoading(false);
                                if(error.i18n){
                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                }else{
                                    setStatus(<Translate id={'members.profile.overview.memberContacts.addContact.' + error.message} defaultMessageId='misc.error' />)
                                }
                            }
                        });
                }}
            />
        </OverlayLoader>
    )
}

export default EditIdentityModal