import Translate from "@spordle/intl-elements";
import { FormattedDate, FormattedTime } from "react-intl";
import { Col, Row } from "reactstrap";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { DisplayTeamCategory } from "../../TeamHelpers";
import CompetitionTeamStatus from "../CompetitionTeamStatus";

const SingleAffiliatedTeamSidePanel = ({ team }) => {
    const competition = team.competition || {};

    return (
        <>
            <div className="h5 font-bold">Info</div>
            <Row form className="text-dark">
                <Col sm={6} className="mb-3">
                    <div className="text-muted"><Translate id="teams.teamSearch.competition" /></div>
                    <DisplayI18n
                        field="name"
                        i18n={competition.i18n}
                        defaultValue={competition.name}
                    />
                    <div className="small text-muted">
                        <DisplayTeamCategory ressource={competition} />
                    </div>
                </Col>
                <Col sm={6} className="mb-3">
                    <div className="text-muted"><Translate id="misc.status" /></div>
                    <CompetitionTeamStatus status={team.competition_team_status} />
                </Col>
            </Row>
            {team.submission_date &&
                <>
                    <div className="h5 font-bold"><Translate id="teams.teamSearch.teamSubmission" /></div>
                    <Row form className="text-dark">
                        <Col sm={6} className="mb-3">
                            <div className="text-muted"><Translate id="teams.teamSearch.submittedBy" /></div>
                            <div>
                                {team.submitted_by ?
                                    `${team.submitted_by?.name} ${team.submitted_by?.family_name}`
                                    :
                                    '-'
                                }
                            </div>
                        </Col>
                        <Col sm={6} className="mb-3">
                            <div className="text-muted"><Translate id="teams.teamSearch.submittedDate" /></div>
                            <FormattedDate value={team.submission_date} /> <FormattedTime value={team.submission_date} />
                        </Col>
                        {team.submission_note &&
                            <Col sm={12} className="mb-3">
                                <div className="text-muted"><Translate id="teams.teamSearch.submissionNote" /></div>
                                {team.submission_note}
                            </Col>
                        }
                    </Row>
                </>
            }
            {team.decision_date &&
                <>
                    <div className="h5 font-bold"><Translate id="teams.teamSearch.teamApproval" /></div>
                    <Row form className="text-dark">
                        <Col sm={6} className="mb-3">
                            <div className="text-muted"><Translate id="teams.teamSearch.approvedBy" /></div>
                            <div>
                                {team.decision_made_by ?
                                    `${team.decision_made_by?.name} ${team.decision_made_by?.family_name}`
                                    :
                                    '-'
                                }
                            </div>
                        </Col>
                        <Col sm={6} className="mb-3">
                            <div className="text-muted"><Translate id="teams.teamSearch.approvalDate" /></div>
                            <FormattedDate value={team.decision_date} /> <FormattedTime value={team.decision_date} />
                        </Col>
                        {team.decision_note &&
                            <Col sm={6} className="mb-3">
                                <div className="text-muted"><Translate id="teams.teamSearch.approvalNote" /></div>
                                <div>{team.decision_note}</div>
                            </Col>
                        }
                    </Row>
                </>
            }
        </>
    );
}

export default SingleAffiliatedTeamSidePanel;