import Translate from '@spordle/intl-elements';
import React from 'react';
import { object, string } from 'yup';
import {
    Row,
    Col,
    Button,
    ModalBody,
    Label,
    Collapse,
    Alert,
    ModalFooter
} from "reactstrap";
import { Form, Formik } from 'formik';
import { FormikInputText, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import { withRouter } from 'react-router-dom';

// Contexts
import withContexts from '../../../../../helpers/withContexts';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import Required from '../../../../../components/formik/Required';
import { AxiosIsCancelled } from '../../../../../api/CancellableAPI';
import { createIneligibleMember } from '../../../../../api/client/members';
import { getMemberStatusChangeReason } from '../../../../../api/client/potentialIneligibleMembers';
import { DisplayI18n } from '../../../../../helpers/i18nHelper';
import { fail, success } from '@spordle/toasts';
import { getGenericLocale } from '../../../../../contexts/I18nContext';
import { MembersContext } from '../../../../../contexts/MembersContext';
import MemberFieldLabel from '../../../../../components/memberRegistration/MemberFieldLabel';
import AddIneligibleOrgSelect from './AddIneligibleOrgSelect';

class MemberAddIneligibleForm1 extends React.Component{
    render(){
        return (
            <Formik
                initialValues={{
                    organisation_id: this.props.OrganizationContext.organisation_id,
                    first_name: '',
                    last_name: '',
                    member_status_reason_id: '',
                    organisation_deciding: '',
                    note: '',
                }}
                validationSchema={object().shape({
                    first_name: string().required(<Translate id='form.validation.firstName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='members.search.memberAdd.firstName.regex' /> }),
                    last_name: string().required(<Translate id='form.validation.lastName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='members.search.memberAdd.lastName.regex' /> }),
                    member_status_reason_id: string().required(<Translate id='members.profile.action.reason.required' />),
                    note: string().required(<Translate id='members.profile.action.note.required' />),
                    organisation_deciding: string().required(<Translate id="members.profile.action.org.required" />),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    this.props.setLoading(true);

                    return createIneligibleMember(values)
                        .then(async(member_id) => {
                            success({ msg: 'members.search.memberAdd.success' });
                            this.props.history.push(`/members/profile/${member_id}`)
                        })
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                        })
                        .finally(() => {
                            this.props.setLoading(false);
                            setSubmitting(false)
                        })
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalBody>
                            <Row form>
                                <Col sm="6" className="mb-3">
                                    <MemberFieldLabel
                                        className='text-muted' for="first_name"
                                        fallbackLabel={<Translate id='form.fields.firstName' />}
                                        isRequired
                                        field='first_name'
                                    />
                                    <FormikInputText id='first_name' name='first_name' trim />
                                </Col>
                                <Col sm="6" className="mb-3">
                                    <MemberFieldLabel
                                        className='text-muted' for="last_name"
                                        fallbackLabel={<Translate id='form.fields.lastName' />}
                                        isRequired
                                        field='last_name'
                                    />
                                    <FormikInputText id='last_name' name='last_name' trim />
                                </Col>
                                <Col sm="12" className="mb-3">
                                    <Label className='text-muted' for="member_status_reason_id"><Translate id='form.fields.reason' /> <Required /></Label>
                                    <FormikSelect
                                        name='member_status_reason_id'
                                        id='member_status_reason_id'
                                        renderOption={({ option }) => (
                                            <>
                                                <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />
                                                <div className='small text-muted'><DisplayI18n field='description' defaultValue={option.description} i18n={option.i18n} /></div>
                                            </>
                                        )}
                                        searchKeys={[ `i18n.${getGenericLocale()}.name` ]}
                                        placeholder='task.ineligible.modal.reason.placeholder'
                                        loadData={(from) => {
                                            switch (from){
                                                case 'CDM':
                                                    return this.props.MembersContext.getStatuses()
                                                        .then((statuses) => {
                                                            const query = {};
                                                            const newStatus = statuses.find((status) => status.code === 'INELIGIBLE');

                                                            if(newStatus){
                                                                query.apply_to_member_status = newStatus.member_status_id
                                                            }

                                                            return getMemberStatusChangeReason(query).then((reasons) => reasons.map((reason) => ({
                                                                value: reason.member_status_reason_id,
                                                                label: reason.name,
                                                                i18n: reason.i18n,
                                                                description: reason.description,
                                                            })))
                                                        });
                                                default:
                                                    break;
                                            }
                                        }}
                                    />
                                </Col>
                                <Col sm={12} className="mb-3">
                                    <AddIneligibleOrgSelect />
                                </Col>
                                <Col sm="12" className="mb-3">
                                    <Label for='note' className='text-muted'><Translate id='form.fields.note' /> <Required /></Label>
                                    <FormikTextArea id='note' name='note' rows='2' trim />
                                </Col>
                            </Row>
                            <Collapse mountOnEnter unmountOnExit isOpen={!!formik.status}>
                                <Alert color='danger'>
                                    {formik.status}
                                </Alert>
                            </Collapse>
                        </ModalBody>
                        <ModalFooter className='bg-white'>
                            <Button type="button" color="primary" outline disabled={formik.isSubmitting} onClick={this.props.toggle}><Translate id='misc.close' /></Button>
                            <Button type="submit" color="primary" disabled={formik.isSubmitting}><Translate id='misc.submit' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        );
    }
}
export default withContexts(OrganizationContext, MembersContext)(withRouter(MemberAddIneligibleForm1));