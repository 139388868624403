import { stringBuilder } from "@spordle/helpers";
import Svg from "../../../../../../components/Svg";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";

/**
 * @description Repetitive visual of a big box button
 * @param {Object} props
 * @param {string} props.icon
 * @param {string} props.className
 * @param {string|JSX.Element} props.title
 * @param {React.ReactChildren} props.children
 * @returns {JSX.Element}
 */
const DiscriminationButton = ({ icon, className, title, children, ...props }) => {
    return (
        <UserDisplay hover card className={stringBuilder('d-flex mb-2', className)} {...props}>
            <UserDisplay.Container className="mr-3">
                <div style={{ height: 45, width: 45 }} className="border bg-light rounded-circle d-flex flex-center">
                    <Svg icon={icon || "members"} />
                </div>
            </UserDisplay.Container>
            <UserDisplay.Container>
                <div className="h5 mb-0 font-bold">{title}</div>
                {children}
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default DiscriminationButton;