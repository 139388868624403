/* eslint-disable react/no-unused-state */
import React, { createContext } from 'react';

/** @type {React.Context<Omit<DashboardContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>} */
export const DashboardContext = createContext();
DashboardContext.displayName = 'DashboardContext';

class DashboardContextProvider extends React.Component{

    state = {
        addWidgetFeature: (process.env.REACT_APP_ENVIRONMENT == "int" || process.env.REACT_APP_ENVIRONMENT == "stage") || false,
        configureWidgetFeature: (process.env.REACT_APP_ENVIRONMENT == "int" || process.env.REACT_APP_ENVIRONMENT == "stage") || false,
    }

    // idk calls eventually

    render(){
        return (
            <DashboardContext.Provider value={{ ...this }}>
                {this.props.children}
            </DashboardContext.Provider>
        )
    }
}

export default DashboardContextProvider