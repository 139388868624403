export default{
    "members.profile.overview.memberLogs.title" : "Modifications Logs",
    "members.profile.overview.memberLogs.show"  : "Show",
    "members.profile.overview.memberLogs.hide"  : "Hide",

    "members.profile.overview.memberLogs.table.date"    : "Date",
    "members.profile.overview.memberLogs.table.time"    : "Time",
    "members.profile.overview.memberLogs.table.by"      : "By",
    "members.profile.overview.memberLogs.table.section" : "Section",
    "members.profile.overview.memberLogs.table.detail"  : "Details",
    "members.profile.overview.memberLogs.table.view"    : "View",
}