import { FormikInputNumber, FormikInputText, FormikSelect, FormikPhoneInput, getFormikAddressInitialValues, FormikAddress } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import Required from "../../../../../../../../components/formik/Required";

export const payeeInit = (orgId) => ({
    organisation_id: orgId,
    first_name: '',
    last_name: '',
    business_name: '',
    service_type_id: '',
    phone: '',
    extension: '',
    email: '',
    prefix: '',
    unit_number: '',
    address: getFormikAddressInitialValues(),
});

// moved the validation to ChequeModalForm.js because having it here causes a crash
// export const payeeValidation = object().shape({
//     first_name: string().when('business_name', {
//         is: (val) => !val,
//         then: (schema) => schema.required(<Translate id='form.validation.firstName.required' />),
//     }),
//     last_name: string().when('business_name', {
//         is: (val) => !val,
//         then: (schema) => schema.required(<Translate id='form.validation.lastName.required' />),
//     }),
//     address: object().address(true, {
//         streetNumber: <Translate id='form.validation.streetNumber.required' />,
//         address: <Translate id='form.validation.address.required' />,
//         city: <Translate id='form.validation.city.required' />,
//         zipCode: <Translate id='form.validation.zip.required' />,
//         state: <Translate id='form.validation.province.required' />,
//         country: <Translate id='form.validation.country.required' />,
//     }),
//     business_name: string(),
//     phone: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />),
//     email: string().email(<Translate id='form.validation.email.valid' />),
// })

const PayeeForm = () => {
    const formik = useFormikContext();

    return (
        <Card className="card-shadow">
            <CardBody>
                <div className="font-medium h5"><Translate id='insurance.sidePanel.cheque.addModal.label.createPayee' /></div>
                <Row form>
                    <Col className="mb-3" sm="6">
                        <Label for="payeeFirstName" className="text-muted">
                            <Translate id="form.fields.firstName" /> {!formik.values.payee?.business_name && <Required />}
                        </Label>
                        <FormikInputText id="payeeFirstName" name="payee.first_name" />
                    </Col>
                    <Col className="mb-3" sm="6">
                        <Label for="payeeLastName" className="text-muted">
                            <Translate id="form.fields.lastName" /> {!formik.values.payee?.business_name && <Required />}
                        </Label>
                        <FormikInputText id="payeeLastName" name="payee.last_name" />
                    </Col>
                    <Col className="mb-3" sm='6'>
                        <Label for='payeePrefix' className='text-muted'>
                            <Translate id='settings.payees.prefix' />
                        </Label>
                        <FormikSelect
                            id='payeePrefix'
                            name='payee.prefix'
                            loadingStatus='success'
                            clearable
                            search={false}
                            defaultData={[
                                { value: 'Dr.', label: 'settings.payees.prefix.DR.', translateLabel: true },
                                { value: 'Mr.', label: 'settings.payees.prefix.MR.', translateLabel: true },
                                { value: 'Mrs.', label: 'settings.payees.prefix.MRS.', translateLabel: true },
                            ]}
                        />
                    </Col>
                    <Col className="mb-3" sm="6">
                        <Label for="payeeBusiness" className="text-muted">
                            <Translate id='settings.payees.businessName' /> {(!formik.values.payee?.first_name && !formik.values.payee?.last_name) && <Required />}
                        </Label>
                        <FormikInputText id="payeeBusiness" name="payee.business_name" />
                    </Col>
                    <Col className="mb-3" sm='12'>
                        <Label for='payeeEmail' className='text-muted'>
                            <Translate id='form.fields.email' />
                        </Label>
                        <FormikInputText id='payeeEmail' name='payee.email' />
                    </Col>
                    <Col className="mb-3" sm='8'>
                        <Label for='phone' className='text-muted'><Translate id='form.fields.phone' /></Label>
                        <FormikPhoneInput id='phone' name='payee.phone' />
                    </Col>
                    <Col className="mb-3" sm='4'>
                        <Label for='extension' className='text-muted'><Translate id='form.fields.extension' /></Label>
                        <FormikInputNumber id='extension' name='payee.extension' allowNegative={false} />
                    </Col>
                    <Col className="mb-3" sm="12">
                        <FormikAddress
                            id='payee.address' name='payee.address'
                            required
                            allowManualPlace
                            allowOpenInMaps
                            label='form.fields.address'
                            labelClassName='text-muted'
                        />
                    </Col>
                    <Col className="mb-3" sm="12">
                        <Label for='payeeUnitNumber' className='text-muted'>
                            <Translate id='form.fields.address' /> 2 (<Translate id='form.fields.address2.placeholder' />)
                        </Label>
                        <FormikInputText id='payeeUnitNumber' name='payee.unit_number' />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default PayeeForm;