import { FormikError, FormikTextArea } from '@spordle/formik-elements';
import Translate, { DateFormat } from '@spordle/intl-elements';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Alert, Button, Card,
    CardBody, Col, Collapse, Container, Row
} from "reactstrap";
import { object, string } from 'yup';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import OverlayLoader from '../../../components/loading/OverlayLoader';
import MainPhoto from '../../../components/MainPhoto';
import { fail } from "@spordle/toasts";
import UserDisplay from '../../../components/userDisplay/UserDisplay';
import UserImg from '../../../components/UserImg';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import { AuthContext, AppContext } from '../../../contexts/contexts';
import { JiraContext } from '../../../contexts/JiraContext';
import { getLocalStorageItem, setLocalStorageItem } from '../../../helpers/browserStorage';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import Priority from '../Priority';
import { Status } from '../SupportRequestHelpers';
import AttachmentsSection from './AttachmentsSection';

const SupportRequestProfile = ({ match, ...props }) => {
    const jiraContext = useContext(JiraContext);
    const authContext = useContext(AuthContext);
    const { updateRecentlyViewed } = useContext(AppContext);

    const [ issue, setIssue ] = useState(false);
    const [ attachments, setAttachments ] = useState(false);
    const [ requestTypes, setRequestTypes ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isFocused, setIsFocused ] = useState(false);
    const [ newFirst, setNewFirst ] = useState(getLocalStorageItem('commentSort') != 0);

    function getRequest(){
        setIsLoading(true);
        Promise.all([
            jiraContext.getRequest(match.params.issueKey),
            getAttachments(),
        ])
            .then(async([ requestIssue, attachmentResults ]) => {
                // avoids doing a .find in requestFieldValues for the used fields (summary, description, etc.)
                // Instead the fieldId becomes the key
                const fields = requestIssue.requestFieldValues.reduce((obj, i) => ({ ...obj, [i.fieldId]: { ...i } }), {});
                const theIssue = {
                    ...requestIssue,
                    ...fields,
                    // filter out the attachments from the comments
                    comments: requestIssue.comments.filter((comment) => !comment.renderedBody.html.includes('jira-attachment-thumbnail')),
                };
                setIssue(theIssue);
                updateRecentlyViewed({
                    label: theIssue.summary?.value,
                    subtitle: theIssue.issueKey,
                    path: props.location.pathname,
                });
                setIsLoading(false);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setIssue('none');
                    setIsLoading(false)
                }
            })
    }

    const getAttachments = () => {
        setAttachments(null)
        return jiraContext.getAttachments(match.params.issueKey)
            .then((attachmentResults) => {
                let attachments = [];
                if(attachmentResults.values && attachmentResults.values.length > 0){
                    attachments = attachmentResults.values?.map((value) => ({
                        fileName: value.filename,
                        fileType: value.mimeType,
                        link: value._links.content,
                        thumbnail: value._links.thumbnail,
                        created: value.created.iso8601,
                    }))
                }
                setAttachments(attachments)
            })
    }

    useEffect(() => {
        jiraContext.getRequestTypes()
            .then(setRequestTypes)
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            });
        getRequest();

    }, [ match.params?.issueKey ]);

    return (
        <>
            {/*--------------------------------------------------------------------------------*/}
            {/* View Header                                                                  */}
            {/*--------------------------------------------------------------------------------*/}
            <ViewHeaderV2 title='supportRequests.profile.title' />

            <div className="page-content">
                <Container>
                    <div className="mb-3 mt-n3">
                        <Link to="/supportRequest" className="font-medium">
                            <i className="mdi mdi-arrow-left mr-1" />
                            <Translate id='supportRequests.profile.back' />
                        </Link>
                    </div>
                    <Card className="card-shadow">
                        <CardBody>
                            { issue == 'none' ?
                                <div>
                                    <div className="h4 font-bold"><Translate id='supportRequests.profile.error.title' /></div>
                                    <p><Translate id='supportRequests.profile.error.text' /></p>
                                    <Link to="/supportRequest" className="btn btn-primary">
                                        <Translate id='supportRequests.profile.btn.seeAll' />
                                    </Link>
                                </div>
                                :
                                <OverlayLoader isLoading={!issue || isLoading}>
                                    <div className="mb-3">
                                        <span className="small">{issue?.issueKey}</span>
                                        {/* <a className="small" href={data.url} target="_blank" rel='noopener noreferrer'>{issue?.issueKey}</a> */}
                                        <div className="h3 font-bold">{issue?.summary?.value}</div>
                                        <UserDisplay>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    abbr={issue?.reporter?.displayName?.match(/\b(\w)/g).join('')}
                                                    src={null}
                                                    width={30}
                                                    alt={issue?.reporter?.displayName || 'is loading'}
                                                    className="p-1"
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>
                                                    {issue?.reporter?.displayName}
                                                    <span className="text-muted font-normal mx-1"><Translate id='supportRequests.profile.raisedOn' /></span>
                                                    {issue?.createdDate?.iso8601 && <DateFormat value={issue.createdDate.iso8601} format={DateFormat.formats.longMonthDayYear} />}
                                                </UserDisplay.Title>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    </div>
                                    <Row>
                                        <Col md="8">
                                            <Card
                                                className="border" body
                                                innerRef={(r) => {
                                                    r?.querySelectorAll('img').forEach((img) => {
                                                        img.style.maxWidth = '100%';
                                                        img.removeAttribute('height');
                                                        img.removeAttribute('width');
                                                    })
                                                }}
                                                dangerouslySetInnerHTML={{ __html: issue?.description?.renderedValue?.html ?? issue?.description?.value }}
                                            />

                                            <AttachmentsSection attachments={attachments} getAttachments={getAttachments} issueKey={match.params.issueKey} />

                                            {/* comments */}
                                            <div className="d-flex align-items-start mb-5">
                                                <MainPhoto className="mr-2" />
                                                <Formik
                                                    validateOnBlur={false}
                                                    initialValues={{
                                                        comment: '',
                                                    }}
                                                    validationSchema={object().shape({
                                                        comment: string().required(<Translate id='supportRequests.profile.comment.validation.required' />),
                                                    })}
                                                    onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
                                                        jiraContext.createComment(issue.issueKey, { comment: values.comment, full_name: authContext.account?.name + ' ' + authContext.account?.family_name })
                                                            .then((comment) => {
                                                                setIssue((prev) => {
                                                                    const newComments = prev.comments || [];
                                                                    newComments.pushArray([ { ...comment, new: true } ]);
                                                                    return { ...prev, comments: newComments }
                                                                })
                                                                setSubmitting(false);
                                                                setIsFocused(false);
                                                                resetForm();
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)

                                                                    setSubmitting(false);
                                                                    setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                                                                }
                                                            });
                                                    }}
                                                >
                                                    {(formik) => (
                                                        <Form
                                                            tabIndex="0"
                                                            onFocus={() => setIsFocused(true)}
                                                            onBlur={(e) => {
                                                                if(!e.currentTarget.contains(e.relatedTarget)){
                                                                    formik.resetForm();
                                                                    setIsFocused(false);
                                                                }
                                                            }}
                                                            className="w-100"
                                                        >
                                                            {/* it could be cool to add tinymce onclick similar to how formikEditable work, but without the styling */}
                                                            <FormikTextArea
                                                                manualError
                                                                rows={isFocused ? '3' : '1'}
                                                                disabled={formik.isSubmitting}
                                                                name="comment"
                                                                id="comment"
                                                                placeholder='supportRequests.profile.comment.placeholder'
                                                                className="mb-1"
                                                            />
                                                            {formik.status && isFocused &&
                                                                <Collapse isOpen appear>
                                                                    <Alert toggle={() => formik.setStatus(null)} color='danger' className="mb-1"><Translate id={'contexts.jiraContext.' + formik.status} defaultMessageId='misc.error' /></Alert>
                                                                </Collapse>
                                                            }
                                                            { isFocused &&
                                                                <div className="d-flex">
                                                                    <FormikError
                                                                        name="comment"
                                                                    />
                                                                    <Button
                                                                        size="sm"
                                                                        disabled={formik.isSubmitting}
                                                                        color="primary"
                                                                        type="submit"
                                                                        className="ml-auto mr-1"
                                                                    >
                                                                        <Translate id="misc.add" />
                                                                    </Button>
                                                                    <Button
                                                                        size="sm"
                                                                        type="button"
                                                                        outline
                                                                        disabled={formik.isSubmitting}
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            formik.resetForm();
                                                                            setIsFocused(false);
                                                                        }}
                                                                    >
                                                                        <Translate id="misc.cancel" />
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                            { issue?.comments?.length > 0 ?
                                                <>
                                                    <div className="text-right">
                                                        <Button
                                                            size="sm"
                                                            color="link"
                                                            onClick={() => {
                                                                setLocalStorageItem('commentSort', newFirst ? 0 : 1)
                                                                setNewFirst(!newFirst);
                                                            }}
                                                        >
                                                            {newFirst ?
                                                                <><Translate id='supportRequests.profile.filter.newest' /><i className="mdi mdi-arrow-down ml-1" /></>
                                                                :
                                                                <><Translate id='supportRequests.profile.filter.oldest' /><i className="mdi mdi-arrow-up ml-1" /></>
                                                            }
                                                        </Button>
                                                        <Button className="mdi mdi-refresh" size="sm" color="link" onClick={() => getRequest()} />
                                                    </div>
                                                    {issue.comments
                                                        .sort((a, b) => {
                                                            if(a.created.iso8601 > b.created.iso8601){
                                                                return newFirst ? -1 : 1;
                                                            }else if(a.created.iso8601 < b.created.iso8601){
                                                                return newFirst ? 1 : -1;
                                                            }
                                                            return 0;
                                                        })
                                                        .map((comment) => (
                                                            <div className={`d-flex align-items-start mb-3${comment.new ? ' new-comment' : ''}`} key={comment.id} id={comment.id}>
                                                                <UserImg
                                                                    abbr={comment.author.displayName.match(/\b(\w)/g).join('')}
                                                                    src={comment.author._links?.avatarUrls?.['32x32']}
                                                                    width={30}
                                                                    alt={comment.author.displayName}
                                                                    className="p-0 mr-2 flex-shrink-0"
                                                                />
                                                                <div>
                                                                    <div className="mb-2 mt-1">
                                                                        <div><span className="font-medium text-dark">{comment.author.displayName}</span>, {comment.created.friendly}</div>
                                                                        <div className="text-muted small">#{comment.id}</div>
                                                                    </div>
                                                                    <div
                                                                        id={`comment-${comment.id}`} ref={(r) => {
                                                                            r?.querySelectorAll('img').forEach((img) => {
                                                                                img.style.maxWidth = '100%';
                                                                                img.removeAttribute('height');
                                                                                img.removeAttribute('width');
                                                                            })
                                                                        }}
                                                                        dangerouslySetInnerHTML={{ __html: comment.renderedBody?.html || `<p>${comment.body}</p>` }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                                :
                                                issue &&
                                                    <div className="text-center">
                                                        <i className="mdi mdi-comment-text-outline" /> <Translate id='supportRequests.profile.noComments' />
                                                    </div>
                                            }
                                        </Col>
                                        <Col md="4" className="order-first order-md-last">
                                            <div className="mb-3">
                                                <div className="text-muted"><Translate id='supportRequests.profile.status' /></div>
                                                <div className="font-medium text-dark">
                                                    {issue?.currentStatus?.statusCategory && <Status status={issue.currentStatus.statusCategory} />}
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="text-muted"><Translate id='supportRequests.profile.requestType' /></div>
                                                <div className="font-medium text-dark">
                                                    {issue && requestTypes && requestTypes?.find((r) => r.id == issue.requestTypeId)?.name}
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="text-muted"><Translate id='supportRequests.profile.priority' /></div>
                                                <div className="font-medium text-dark">
                                                    {issue?.priority && <Priority color={issue.priority.value.id}>{issue.priority.value.name}</Priority> }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </OverlayLoader>
                            }
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    );
}

export default SupportRequestProfile;