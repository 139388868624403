import { Tooltip } from '@mantine/core';
import { FormikCheckedButton, FormikSwitch } from '@spordle/formik-elements';
import { groupByAsArray, stringBuilder } from '@spordle/helpers';
import Translate from '@spordle/intl-elements';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import DisplayInput from './DisplayInput';

const DisplayRestrictions = ({ settings, settingsError, settingsIsValidating }) => {
    const formik = useFormikContext();
    const numberOfSettings = 14;
    const groupedBySettings = settings ? groupByAsArray(settings, 'setting_group_id').sort((a, b) => {
        if(!a.values[0].setting_group){
            return 1
        }
        if(!b.values[0].setting_group){
            return -1
        }
        return 0
    }) : null

    return (
        <Card className={stringBuilder('border mb-3 card-shadow')}>
            <CardHeader className="bg-white font-medium d-flex align-center">
                <div className='h5 mb-0 font-bold'><Translate id='settings.teamsSettings.restrictions' /></div>
            </CardHeader>
            <CardBody>
                {settingsIsValidating ?
                    Array(settings?.length || numberOfSettings).map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Row className='mb-3' form key={index}>
                            <Col sm="4" className='d-flex align-items-center'><Skeleton width='250px' /></Col>
                            <Col sm="4"><Skeleton width='250px' height='35px' /></Col>
                            <Col sm="2" className='justify-content-center d-flex align-items-center'><Skeleton width='40px' /></Col>
                            <Col sm="2" className='justify-content-center d-flex align-items-center'><Skeleton width='40px' /></Col>
                        </Row>
                    ))
                    : settingsError ?
                        <div><Translate id='misc.error' /></div>
                        :
                        <>
                            <Card className='bg-light-inverse mb-3'>
                                <Row form className='py-1'>
                                    <Col sm='1'>
                                        {/* <CustomInput type='checkbox'/> */}
                                    </Col>
                                    <Col sm='4'>
                                        <Label className='text-muted mb-0'><Translate id='settings.teamsSettings.settingName' /></Label>
                                    </Col>
                                    <Col sm='3'>
                                        <Label className='text-muted mb-0'><Translate id='settings.teamsSettings.value' /></Label>
                                    </Col>
                                    <Col sm='2' className='text-center'>
                                        <Tooltip withArrow label={<div className='text-wrap' style={{ maxWidth: '300px' }}><Translate id={'settings.teamsSettings.enforced.tooltip'} /></div>}>
                                            <i className='mdi mdi-information-outline text-primary mr-1' />
                                        </Tooltip>
                                        <Label className='text-muted mb-0'><Translate id='settings.teamsSettings.enforced' /></Label>
                                    </Col>
                                    <Col sm='2' className='text-center'>
                                        <Tooltip withArrow label={<div className='text-wrap' style={{ maxWidth: '300px' }}><Translate id={'settings.teamsSettings.active.tooltip'} /></div>}>
                                            <i className='mdi mdi-information-outline text-primary mr-1' />
                                        </Tooltip>
                                        <Label className='text-muted mb-0'><Translate id='settings.teamsSettings.active' /></Label>
                                    </Col>
                                </Row>
                            </Card>
                            {groupedBySettings?.map(({ key, values }) => {
                                return (
                                    <Card className='mb-3 border py-3' key={key}>
                                        {key &&
                                            <div className='ml-3 mb-3'>
                                                <div className='text-dark font-bold'>
                                                    <DisplayI18n field='name' defaultValue={values[0].setting_group.name} i18n={values[0].setting_group.i18n} />
                                                </div>
                                                <span className='small'><DisplayI18n field='description' defaultValue={values[0].setting_group.description} i18n={values[0].setting_group.i18n} /></span>
                                            </div>
                                        }
                                        {values.map((setting) => {
                                            const rowDisabled = !formik.values[setting.code]?.checked;

                                            return (
                                                <>
                                                    <Row form className='mb-4' key={setting.code}>
                                                        <Col sm='1' className='d-flex align-items-center justify-content-center'>
                                                            <FormikCheckedButton id={`${setting.code}.checked`} name={`${setting.code}.checked`} />
                                                        </Col>
                                                        <Col sm='4' className="d-flex flex-column justify-content-center">
                                                            <div className={stringBuilder('font-medium', { 'text-dark': !rowDisabled, 'text-muted': rowDisabled })}><DisplayI18n field='name' defaultValue={setting.name} i18n={setting.i18n} /></div>
                                                            <FormattedMessage id={`teams.profile.teamSetting.restrictions.description.${setting.code}`} defaultMessage="noTranslate">
                                                                {(message) => (message[0] !== 'noTranslate') ? <div className={stringBuilder("small", { 'text-muted': rowDisabled })}>{message}</div> : ''}
                                                            </FormattedMessage>
                                                        </Col>
                                                        <Col sm='3'>
                                                            <DisplayInput
                                                                teamSetting={setting}
                                                                fieldName={`${setting.code}.value`}
                                                                value={formik.values[setting.code]?.value}
                                                                disabled={rowDisabled}
                                                                onFieldValueChange={() => {
                                                                    if(!formik.initialValues[`${setting.code}`].value)
                                                                        formik.setFieldValue(`${setting.code}.active`, true)
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col sm='2' className='d-flex align-items-center justify-content-center'>
                                                            <FormikSwitch
                                                                id={`${setting.code}.enforced`}
                                                                name={`${setting.code}.enforced`}
                                                                disabled={rowDisabled}
                                                                onChange={(event) => {
                                                                    // set active to true if user sets enforced to true
                                                                    if(event.target.checked && !formik.values[setting.code]?.active)
                                                                        formik.setFieldValue(`${setting.code}.active`, true)

                                                                    // set the value of enforced because using onChange of FormikSwitch
                                                                    // captures the event and prevents formik from changing the value automatically
                                                                    formik.setFieldValue(`${setting.code}.enforced`, event.target.checked)
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col sm='2' className="d-flex align-items-center justify-content-center">
                                                            <FormikSwitch id={`${setting.code}.active`} name={`${setting.code}.active`} disabled={rowDisabled} />
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        })}
                                    </Card>
                                )
                            })}
                        </>
                }
            </CardBody>
        </Card>
    )
}

export default DisplayRestrictions