export default{
    // Header
    "organization.profile.users.sidePanelUsers.header.remove"     : "Remove",
    "organization.profile.users.sidePanelUsers.header.resetPwd"   : "Reset password",
    "organization.profile.users.sidePanelUsers.header.reactivate" : "Reactivate account",
    "organization.profile.users.sidePanelUsers.header.unlock"     : "Unlock account",
    "organization.profile.users.sidePanelUsers.header.confirm"    : "Confirm signup",

    // Tabs
    "organization.profile.users.sidePanelUsers.tabs.details" : "Details",

    // User Info
    "organization.profile.users.sidePanelUsers.userInfo.title"                       : "User information",
    "organization.profile.users.sidePanelUsers.userInfo.role"                        : "Role",
    "organization.profile.users.sidePanelUsers.userInfo.role.required"               : "Role is required",
    "organization.profile.users.sidePanelUsers.userInfo.role.inactive"               : "This role is inactive",
    "organization.profile.users.sidePanelUsers.userInfo.role.inactive.warning"       : "This role is inactive. The user will not have access to this role.",
    "organization.profile.users.sidePanelUsers.userInfo.additional_roles"            : "Additional Roles",
    "organization.profile.users.sidePanelUsers.userInfo.status"                      : "Status",
    "organization.profile.users.sidePanelUsers.userInfo.status.required"             : "Status is required",
    "organization.profile.users.sidePanelUsers.userInfo.active"                      : "Active",
    "organization.profile.users.sidePanelUsers.userInfo.inactive"                    : "Inactive",
    "organization.profile.users.sidePanelUsers.userInfo.dates"                       : "Dates restrictions",
    "organization.profile.users.sidePanelUsers.userInfo.dates.none"                  : "No specified date",
    "organization.profile.users.sidePanelUsers.userInfo.lastConnection.none"         : "No connection",
    "organization.profile.users.sidePanelUsers.userInfo.from"                        : "From",
    "organization.profile.users.sidePanelUsers.userInfo.until"                       : "Until",
    "organization.profile.users.sidePanelUsers.userInfo.startDate.format.validation" : "Start date must be of a valid format",
    "organization.profile.users.sidePanelUsers.userInfo.endDate.format.validation"   : "End date must be of a valid format",
    "organization.profile.users.sidePanelUsers.userInfo.dates.validation"            : "The start date must be before the end date",
    "organization.profile.users.sidePanelUsers.userInfo.phone.2faOnly"               : "This is use only for 2 factor authentication",
    // name, email, address, birthdate, gender and phone number are from global/form.js

    // Remove user
    "organization.profile.users.sidePanelUsers.removeUser.topText"         : "You are about to remove this user from your organization",
    "organization.profile.users.sidePanelUsers.removeUser.confirm"         : "Remove",
    "organization.profile.users.sidePanelUsers.removeUser.deletingActive1" : "Oops! You cannot delete your own user from the organization you are managing at this very moment!",
    "organization.profile.users.sidePanelUsers.removeUser.deletingActive2" : "Please contact support if you wish to delete your user from this organization.",

    // Reset password
    "organization.profile.users.sidePanelUsers.resetPwd.success"     : "Password reset",
    "organization.profile.users.sidePanelUsers.resetPwd.reset"       : "Reset",
    "organization.profile.users.sidePanelUsers.resetPwd.description" : "A temporary password will be sent to the user's email.",
    "organization.profile.users.sidePanelUsers.resetPwd.usersteps"   : "User steps:",
    "organization.profile.users.sidePanelUsers.resetPwd.usersteps.1" : "The user will need to use that password when asked for.",
    "organization.profile.users.sidePanelUsers.resetPwd.usersteps.2" : "The user will then need to change his/her password to login.",

    // Re-Activate Account
    "organization.profile.users.sidePanelUsers.reactivate.msg"                 : "You are about to reactivate this account",
    "organization.profile.users.sidePanelUsers.reactivate.ok"                  : "Reactivate",
    "organization.profile.users.sidePanelUsers.reactivate.success"             : "Account reactivated",
    "organization.profile.users.sidePanelUsers.reactivate.error"               : "Could not reactivate account",
    "organization.profile.users.sidePanelUsers.reactivate.error.activeAccount" : "Cannot reactivate an active account",

    // Unlock Account
    "organization.profile.users.sidePanelUsers.unlock.msg"     : "You are about to unlock this account",
    "organization.profile.users.sidePanelUsers.unlock.ok"      : "Unlock",
    "organization.profile.users.sidePanelUsers.unlock.success" : "Account has been unlocked",
    "organization.profile.users.sidePanelUsers.unlock.error"   : "Cannot unlock the account",

    // Confirm Account
    "organization.profile.users.sidePanelUsers.confirm.msg"     : "You are about to confirm this account's signup",
    "organization.profile.users.sidePanelUsers.confirm.ok"      : "Confirm",
    "organization.profile.users.sidePanelUsers.confirm.success" : "Signup has been confirmed",
    "organization.profile.users.sidePanelUsers.confirm.error"   : "Cannot confirm the account",

}