import Translate from '@spordle/intl-elements';
import { stringBuilder } from "@spordle/helpers";
import { FieldArray, useFormikContext } from 'formik';
import { useContext } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import useSWR from 'swr';
import UserDisplay from '../../../components/userDisplay/UserDisplay';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { PositionsContext } from '../../../contexts/PositionsContext';
import { QualificationsContext } from '../../../contexts/QualificationsContext';
import generatePassword from '../../../helpers/passwordGenerator';
import DisplayQualificationsCard from './DisplayQualificationsCard';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { displayI18n, DisplayI18n } from '../../../helpers/i18nHelper';
import { fail } from '@spordle/toasts';
import { I18nContext } from '../../../contexts/I18nContext';

const DisplayQualifications = (props) => {
    const formik = useFormikContext();

    const qualificationsContext = useContext(QualificationsContext);
    const positionsContext = useContext(PositionsContext);
    const orgContext = useContext(OrganizationContext);
    const { getGenericLocale } = useContext(I18nContext);

    const { data: qualifications, isValidating: qualificationIsValidating } = useSWR(
        [ 'getQualifications' ],
        () => qualificationsContext.getQualifications({ active: 1 })
            .then(async(qualifications) => {
                // the problem is that the team restriction can have an inactive qual as a requirement
                // but we only get the active qualifications, so the inactive ones would be set in the formik values, but won't appear in select
                // we need to check if some qualifications are missing, and specific GET them to add them to the active qualifications
                // HCR-23836

                for(let i = 0; i < formik.values.qualifications.length; i++){
                    const group = formik.values.qualifications[i];
                    for(let j = 0; j < group.values.length; j++){
                        const { qualificationId } = group.values[j];

                        if(!qualifications.find((qual) => qual.qualification_id === qualificationId)){
                            const qual = await qualificationsContext.getQualification(qualificationId)
                            qualifications.push(qual)
                        }
                    }
                }
                return qualifications
            })
            .then((qualifications) => {
                const getI18nStr = (quali) => {
                    const i18nName = displayI18n("name", quali.i18n, quali.name, getGenericLocale());
                    const i18nLevel = displayI18n("name", quali.qualification_level?.i18n, quali.qualification_level?.name, getGenericLocale());
                    const i18nStr = `${i18nName}${i18nLevel ? ` - ${i18nLevel}` : ""}`;

                    return i18nStr;
                }

                return qualifications.sort((a, b) => {
                    return new Intl.Collator(
                        getGenericLocale(),
                        { sensitivity: 'base' },
                    )
                        .compare(
                            getI18nStr(a),
                            getI18nStr(b),
                        );
                })
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        ,
        {
            fallbackData: [],
            revalidateOnMount: true,
        },
    )

    const { data: positions, isValidating: positionIsValidating } = useSWR(
        [ 'getPositions' ],
        () => positionsContext.getPositions(orgContext.organisation_id, { active: 1 })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
        ,
        {
            fallbackData: [],
            revalidateOnMount: true,
        },
    )

    return (
        <Card className={stringBuilder('border mb-3 card-shadow')}>
            <CardHeader className="bg-white font-medium d-flex align-center">
                <div className='h5 mb-0 font-bold'><Translate id='settings.teamsSettings.qualifications' /></div>
            </CardHeader>
            <CardBody>
                <FieldArray name='qualifications'>
                    {(fieldArrayHelper) => (
                        <>
                            {formik.values.qualifications.map((group, index) => (
                                <DisplayQualificationsCard
                                    key={group.id}
                                    group={group}
                                    index={index}
                                    qualifications={qualifications}
                                    qualificationIsValidating={qualificationIsValidating}
                                    positions={positions}
                                    positionIsValidating={positionIsValidating}
                                    handleRemove={fieldArrayHelper.handleRemove(index)}
                                />
                            ))}
                            <UserDisplay card hover className="border-primary p-0 text-center w-100 mb-3" style={{ borderStyle: 'dashed' }}>
                                <button
                                    onClick={fieldArrayHelper.handlePush({
                                        values: [],
                                        id: generatePassword(),
                                        positionId: '',
                                        enforced: false,
                                        mandatory: 'all',
                                    })}
                                    type="button"
                                    className="reset-btn text-link p-3 w-100"
                                >
                                    <i className="mdi mdi-plus mr-1" /><Translate id='misc.add' />
                                </button>
                            </UserDisplay>
                        </>
                    )}
                </FieldArray>
            </CardBody>
        </Card>
    )
}


export default DisplayQualifications