import { useContext, useState } from "react";
import {
    Card,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    Col
} from 'reactstrap';
import SpordleTableProvider, { SearchInput, useSpordleTable } from '@spordle/datatables';
import Translate from "@spordle/intl-elements";
import { fail, success } from '@spordle/toasts';
import AddPostalCodesModal from './modals/AddPostalCodesModal';
import UserDisplay from "../../../../components/userDisplay/UserDisplay";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import CanDoAction from "../../../../components/permissions/CanDoAction";
import EditPostalCodesModal from "./modals/EditPostalCodesModal";
import ConfirmModal from "../../../../components/confirmModal/ConfirmModal";
import { stringBuilder } from "@spordle/helpers";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import EmptyLayout from "../../../../components/table/EmptyLayout";
import { RolesContext } from "../../../../contexts/RolesContext";

const OrganizationPostalCodes = () => {
    const { postal_codes, updateOrganisationPostalCode, organisation_id, setCurrentOrg } = useContext(OrganizationContext);
    const { canDoAction } = useContext(RolesContext);
    const [ addPostalCodesModalOpen, setAddPostalCodesModalOpen ] = useState(false);

    const toggleAddPostalCodesModal = () => setAddPostalCodesModalOpen((prev) => !prev);

    // Skip access since we only have a EDIT permission
    const canEdit = canDoAction("EDIT", "organization", "manage_organisation_postal_codes", true);

    return (
        <Card body className="card-shadow">
            <div className="h4 font-bold pb-1 border-bottom mb-3 card-title"><Translate id='organisation.profile.tabs.postalCodes' /></div>
            <SpordleTableProvider
                id='org-postalCodes'
                dataIndex='code'
                // pagination={24}
                searchKeys={[
                    'code',
                ]}
                rowIsHighlighted={(location) => !!location.checked}
                loadData={(from) => {
                    switch (from){
                        case 'REFRESH':
                        case 'CDM':
                            return Promise.resolve(
                                postal_codes.map((postalCode) => postalCode.split(' ')[0])
                                    .removeDuplicates()
                                    .map((postalCode) => ({ code: postalCode })), // Creating an object with the code to allow to have the search
                            );
                        default:
                            break;
                    }
                }}
            >
                {(spordleTable) => (
                    <>
                        <div className="d-flex justify-content-between mb-3">
                            <SearchInput />
                            <CanDoAction action="EDIT" componentCode="organization" componentPermissionCode="manage_organisation_postal_codes" skipAccess>
                                <AddPostalCodesModal
                                    isOpen={addPostalCodesModalOpen}
                                    toggle={toggleAddPostalCodesModal}
                                    selectedPostalCodes={postal_codes}
                                />
                                <Button color='primary' onClick={toggleAddPostalCodesModal}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                            </CanDoAction>
                        </div>
                        {spordleTable.dataToDisplay.length === 0 ?
                            <EmptyLayout hideArrow={!spordleTable.getInputValue()} />
                            :
                            <Row form tag='ul' className="mb-0 list-unstyled">
                                {spordleTable.dataToDisplay.map((postalCode) => (
                                    <Col key={postalCode.code} tag='li' className="mb-2" xs='6' sm='4' md='3' lg='2'>
                                        <UserDisplay card className='mb-0 min-w-100'>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>
                                                    {postalCode.code}
                                                </UserDisplay.Title>
                                            </UserDisplay.Container>
                                            <UserDisplay.Container className='ml-auto'>
                                                <UncontrolledDropdown inNavbar>
                                                    <DropdownToggle color="link" className="p-0">
                                                        <i className={stringBuilder('mdi mdi-dots-vertical')} />
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <PostalCodeEdit
                                                            code={postalCode.code}
                                                            canEdit={canEdit}
                                                        />
                                                        <ConfirmModal
                                                            toggler={(toggle) => (
                                                                <CanDoAction action='EDIT' componentCode='organization' componentPermissionCode='manage_organisation_postal_codes' skipAccess>
                                                                    {(godAccess) => (
                                                                        <DropdownItem
                                                                            onClick={toggle}
                                                                            className={godAccess ? 'text-purple' : undefined}
                                                                        >
                                                                            <Translate id='misc.delete' />
                                                                        </DropdownItem>
                                                                    )}
                                                                </CanDoAction>
                                                            )}
                                                            modalTitle={<Translate id='organization.profile.postalCodes.modals.delete.title' values={{ postalCode: postalCode.code }} />}
                                                            modalContent={<Translate id='organization.profile.postalCodes.modals.delete.content' values={{ postalCode: postalCode.code }} />}
                                                            actionButtonText='misc.delete'
                                                            translateActionButtonText
                                                            color='danger'
                                                            onConfirm={() => {
                                                                return updateOrganisationPostalCode(organisation_id, postal_codes.reduce((filteredPostalCodes, pCode) => {
                                                                    if(!pCode.toLowerCase().startsWith(postalCode.code.toLowerCase())){
                                                                        filteredPostalCodes.push(pCode);
                                                                    }
                                                                    return filteredPostalCodes;
                                                                }, []))
                                                                    .then(async() => {
                                                                        await setCurrentOrg(organisation_id).then(spordleTable.refreshTable);
                                                                        success();
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            fail({ info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />, skipInfoTranslate: true });
                                                                        }
                                                                    })
                                                            }}
                                                        />
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    </Col>
                                ))}
                            </Row>
                        }
                    </>
                )}
            </SpordleTableProvider>
        </Card>
    );
}

const PostalCodeEdit = ({ code, canEdit }) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const spordleTable = useSpordleTable();
    return (
        <>
            <EditPostalCodesModal isOpen={isOpen} toggle={() => setIsOpen(false)} postalCode={code} canEdit={canEdit} />
            <DropdownItem
                id={spordleTable.generateId('item', code, 'EDIT')}
                onClick={() => {
                    setIsOpen(true)
                }}
            >
                <Translate id={canEdit ? 'misc.edit' : 'misc.view'} />
            </DropdownItem>
        </>
    )
}

export default OrganizationPostalCodes;