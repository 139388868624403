export default{
    "settings.crc.tab"                                  : "Vérification d'antécédents",
    "settings.crc.title"                                : "Vérification d'antécédents",
    "settings.crc.memberType"                           : "Type de membre",
    "settings.crc.memberType.required"                  : "Type de membre est requis",
    "settings.crc.rules"                                : "Règles",
    "settings.crc.rules.none"                           : "Aucune règle définie",
    "settings.crc.rules.yup.validationType.required"    : "Veuillez sélectionner un type de validation",
    "settings.crc.rules.yup.duration.required"          : "Veuillez sélectionner une durée",
    "settings.crc.validationType"                       : "Type de validation",
    "settings.crc.validationType.background_check"      : "Vérification d'antécédents",
    "settings.crc.validationType.vsc"                   : "Vérification de la vulnérabilité",
    "settings.crc.validationType.declaration"           : "Déclaration",
    "settings.crc.duration"                             : "Durée",
    "settings.crc.duration.years"                       : "{yearCount, plural, one {# année} other {# années}}",
    "settings.crc.supportingDocuments"                  : "Document de support requis",
    "settings.crc.supportingDocuments.notRequired"      : "Document de support non-requis",
    "settings.crc.complianceDate"                       : "Date limite de conformité",
    "settings.crc.complianceDate.placeholder"           : "MM-JJ",
    "settings.crc.complianceDate.tip"                   : "Ceci représente la date maximale où la vérification d'antécédent doit être complétée. Dépassé cette date, si le membre n'a pas reçu sa preuve de vérification d'antécédent, il sera considéré comme invalide.",
    "settings.crc.minAge"                               : "Âge minimum",
    "settings.crc.minAge.tip"                           : "Si aucun âge minimum n'est saisi, la vérification d'antécédent sera obligatoire pour tous les membres de ce type.",
    "settings.crc.expirationMethod"                     : "Méthode d'expiration",
    "settings.crc.expirationMethod.tip.period"          : "La méthode période prédéterminée expirera la vérification d'antécédents après la date et la durée combinées. Si la durée totale dépasse la durée, le système coupera 1 an.",
    "settings.crc.expirationMethod.tip.date"            : "La méthode date fixe expirera la vérification d'antécédents après la durée à partir de la date de création de la vérification d'antécédents.",
    "settings.crc.expirationMethod.required"            : "Veuillez cocher une méthode d'expiration",
    "settings.crc.expirationMethod.expiresOn"           : "Expire le",
    "settings.crc.expirationMethod.date.required"       : "Date requise",
    "settings.crc.expirationMethod.date.format.invalid" : "Format de date non valide",
    "settings.crc.expirationMethod.date.placeholder"    : "MM-JJ",
    "settings.crc.required"                             : "Requis",
    "settings.crc.optional"                             : "Optionnel",
    "settings.crc.enable"                               : "Activer les vérifications d'antécédents",
    "settings.crc.enabled"                              : "Vérifications d'antécédents activées",
    "settings.crc.enabled.by"                           : "Activées par",
    "settings.crc.disabled"                             : "Vérifications d'antécédents désactivées",
    "settings.crc.enableTip"                            : "Activez les vérifications d'antécédents pour gérer les règles.",

    "settings.backgroundCheckType.add" : "Ajouter un type de vérification d'antécédent",
}