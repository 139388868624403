import { FormikError, FormikGroup, FormikRadioButton } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useFormikContext } from "formik";
import CrossFade from "../components/crossFade/CrossFade";
import { displayI18n, DisplayI18n } from "./i18nHelper";
import Required from '../components/formik/Required';
import { useContext } from "react";
import { PeriodsContext } from "../contexts/contexts";
import { I18nContext } from "../contexts/I18nContext";
import { CustomInput } from "reactstrap";
import UserDisplay from "../components/userDisplay/UserDisplay";

export const waiverNotSigned = 'NOT_SIGNED_YET';
export const waiverAccepted = 'YES';
export const waiverDeclined = 'NO';
export const waiverVerbal = 'VERBAL';
export const waiverPhysical = 'PHYSICAL'; // let's get PHYSICAL

export const getIcon = (answer) => {
    switch (answer){
        case waiverNotSigned:
            return <i className="mdi mdi-close text-danger" />
        case waiverAccepted:
            return <i className="mdi mdi-check text-success" />
        case waiverDeclined:
            return <i className="mdi mdi-alert-outline text-warning" />
    }
}

export const WaiverHeader = ({ waiver }) => {
    return (
        <div className="h6 mb-0">
            <div className="font-medium">
                <DisplayI18n
                    field='name'
                    defaultValue={waiver?.waiver.name}
                    i18n={waiver?.waiver.i18n}
                />
            </div>
        </div>
    )
}

export const WaiverContent = ({ waiver, item }) => {
    const periodsContext = useContext(PeriodsContext);
    const i18nContext = useContext(I18nContext);

    return (
        <>
            <div className="small">
                <DisplayI18n
                    field='name'
                    defaultValue={periodsContext.selectedPeriod.name}
                    i18n={periodsContext.selectedPeriod.i18n}
                />
            </div>
            <div className={stringBuilder("h5 font-bold mb-0", { "mb-2": !item })}>
                <DisplayI18n
                    field='name'
                    defaultValue={waiver?.invoiceItem.organisation.organisation_name}
                    i18n={waiver?.invoiceItem.organisation.i18n}
                />
            </div>
            {item &&
                <div className='font-bold h6 mb-2'>
                    <DisplayI18n
                        field='short_name'
                        defaultValue={item?.division?.short_name}
                        i18n={item?.division?.i18n}
                    />
                    {item?.team_category?.class &&
                        <>
                            <span className='mx-1'>-</span>
                            <DisplayI18n
                                field='short_name'
                                defaultValue={item?.team_category?.class?.short_name}
                                i18n={item?.team_category?.class?.i18n}
                            />
                        </>
                    }
                </div>
            }
            <div className="overflow-auto max-vh-50 border p-2 mb-3">
                <div dangerouslySetInnerHTML={{ __html: displayI18n("description", waiver?.waiver.i18n, waiver?.waiver.description, i18nContext.getGenericLocale()) }} />
            </div>
        </>
    )
}

export const WaiverMandatoryHelper = ({ waiver, forceNotMandatory }) => {

    return (
        <div className='d-flex align-items-center bg-light-inverse p-1 mb-3 rounded border'>
            <i className='text-primary mdi mdi-information-outline mx-2' />
            <div className='small'>
                {waiver?.mandatory == 1 && !forceNotMandatory ?
                    <>
                        <Translate id="form.fields.waiver.mandatory" /> <Required className="ml-1" />
                    </>
                    :
                    <Translate id='form.fields.waiver.notMandatory' />
                }
            </div>
        </div>
    )
}

export const WaiverFields = ({ waiver, allowSkip, fieldName }) => {
    const formik = useFormikContext();

    return (
        <div className="mb-3">
            <UserDisplay tag="label" htmlFor='waiverAcceptOption' card hover className={`mb-2 w-100 ${formik.values[fieldName] === waiverAccepted ? 'border-primary' : ''}`}>
                <UserDisplay.Container className="mr-0">
                    <CustomInput id='waiverAcceptOption' type="radio" name="type" onChange={() => formik.setFieldValue(fieldName, waiverAccepted)} />
                </UserDisplay.Container>
                <UserDisplay.Container className='d-flex align-items-center'>
                    <div className='font-bold text-dark w-100'>
                        {waiver?.confirm_label ?
                            <DisplayI18n field='confirm_label' defaultValue={waiver?.confirm_label} i18n={waiver?.i18n} />
                            :
                            <Translate id='form.fields.waiver.defaultAccept' />
                        }
                    </div>
                </UserDisplay.Container>
            </UserDisplay>

            {waiver?.decline_label &&
                <UserDisplay tag="label" htmlFor='waiverDeclineOption' card hover className={`mb-2 w-100 ${formik.values[fieldName] === waiverDeclined ? 'border-primary' : ''}`}>
                    <UserDisplay.Container className="mr-0">
                        <CustomInput id='waiverDeclineOption' type="radio" name="type" onChange={() => formik.setFieldValue(fieldName, waiverDeclined)} />
                    </UserDisplay.Container>
                    <UserDisplay.Container className='d-flex align-items-center'>
                        <div className='font-bold text-dark w-100'>
                            <DisplayI18n field='decline_label' defaultValue={waiver?.decline_label} i18n={waiver?.i18n} />
                        </div>
                    </UserDisplay.Container>
                </UserDisplay>
            }

            {allowSkip &&
                <UserDisplay tag="label" htmlFor='waiverSkipOption' card hover className={`mb-2 w-100 ${formik.values[fieldName] === waiverNotSigned ? 'border-primary' : ''}`}>
                    <UserDisplay.Container className="mr-0">
                        <CustomInput id='waiverSkipOption' type="radio" name="type" onChange={() => formik.setFieldValue(fieldName, waiverNotSigned)} />
                    </UserDisplay.Container>
                    <UserDisplay.Container className='d-flex align-items-center'>
                        <div className='font-bold text-dark w-100'>
                            <Translate id='form.fields.waiver.doNotSign' />
                        </div>
                    </UserDisplay.Container>
                </UserDisplay>
            }
            <FormikError name={fieldName} />
        </div>
    )
}

export const WaiverSignature = ({ fieldName, answer }) => {
    return (
        <CrossFade isVisible={!!answer && answer !== waiverNotSigned}>
            <FormikGroup name={fieldName} rowProps={{ className: 'flex-column ml-0' }}>
                <FormikRadioButton id='signatureTypeVerbal' name={fieldName} label='components.manualRegistration.waivers.VERBAL' translateLabel value={waiverVerbal} />
                <FormikRadioButton id='signatureTypePhysical' name={fieldName} label='components.manualRegistration.waivers.PHYSICAL' translateLabel value={waiverPhysical} />
            </FormikGroup>
        </CrossFade>
    )
}

export const WaiverSignatureDisplay = ({ waiver, minifiedView, noUnsignedSection, hideIcon }) => {
    const i18nContext = useContext(I18nContext);

    // waiver condition so it doesn't crash when un mounting a modal
    if(minifiedView && waiver){
        return (
            waiver.answer !== waiverNotSigned ?
                <div className="align-items-center">
                    {waiver.identity ?
                        <span className="text-dark">{!hideIcon && <span className="mr-1">{getIcon(waiver.answer)}</span>}<span className='font-bold'><Translate id={'members.profile.waiver.signed.by.' + waiver.answer} /></span> : {waiver.identity?.name} {waiver.identity?.family_name}</span>
                        :
                        <span className="text-dark">{!hideIcon && <span className="mr-1">{getIcon(waiver.answer)}</span>}<span className='font-bold'><Translate id={'members.profile.waiver.signed.by.' + waiver.answer} /></span> : {waiver.first_name} {waiver.last_name}</span>
                    }
                    {(waiver.api_partner) && <div className="text-dark"><span className="font-bold"><Translate id={'members.profile.waiver.signed.apiPartner'} /></span>: {waiver.api_partner.name}</div>}
                    <div className="text-muted small"><DateFormat value={waiver.updated_at} format={DateFormat.formats.longMonthDayYearTime} /></div>
                    {(waiver.signature_type && waiver.signature_type !== 'ONLINE') && <div className="small"><Translate id={'components.manualRegistration.waivers.' + waiver.signature_type} /></div>}
                    {/* Here we display the label that is shown when signing the waiver */}
                    {(waiver.answer == 'NO' && waiver.decline_label) &&
                        <div className="small"><Translate id={'members.profile.waiver.signed.declineLabel'} />{i18nContext.getGenericLocale() == 'fr' ? `«${waiver.decline_label}»` : `"${waiver.decline_label}"` }</div>
                    }
                    {(waiver.answer == 'YES' && waiver.confirm_label) &&
                        <div className="small"><Translate id={'members.profile.waiver.signed.confirmationLabel'} />{i18nContext.getGenericLocale() == 'fr' ? `«${waiver.confirm_label}»` : `"${waiver.confirm_label}"` }</div>
                    }
                </div>
                :
                !noUnsignedSection ?
                    <div className='d-flex'>
                        <i className="mdi mdi-close text-danger mr-2" /><span className='font-bold'><Translate id='members.profile.waiver.signed.not' /></span>
                    </div>
                    :
                    null
        )
    }else if(waiver){
        return (
            // NON MINIFIED VIEW
            waiver.answer !== waiverNotSigned ?
                <div className="align-items-center">
                    {waiver.identity ?
                        <span className="text-dark">{!hideIcon && <span className="mr-1">{getIcon(waiver.answer)}</span>}<span className='font-bold'><Translate id={'members.profile.waiver.signed.by.' + waiver.answer} /></span> : {waiver.identity?.name} {waiver.identity?.family_name}</span>
                        :
                        <span className="text-dark">{!hideIcon && <span className="mr-1">{getIcon(waiver.answer)}</span>}<span className='font-bold'><Translate id={'members.profile.waiver.signed.by.' + waiver.answer} /></span> : {waiver.first_name} {waiver.last_name}</span>
                    }
                    {(waiver.api_partner) &&
                        <div className="text-dark"><span className="font-bold"><Translate id={'members.profile.waiver.signed.apiPartner'} /></span>: {waiver.api_partner.name}</div>
                    }
                    <div className="text-muted"><DateFormat value={waiver.updated_at} format={DateFormat.formats.longMonthDayYearTime} /></div>
                    {(waiver.signature_type && waiver.signature_type !== 'ONLINE') && <div className="small"><Translate id={'components.manualRegistration.waivers.' + waiver.signature_type} /></div>}
                    {/* Here we display the label that is shown when signing the waiver */}
                    {(waiver.answer == 'NO' && waiver.decline_label) &&
                            <div className="small"><Translate id={'members.profile.waiver.signed.declineLabel'} />{i18nContext.getGenericLocale() == 'fr' ? `«${waiver.decline_label}»` : `"${waiver.decline_label}"` }</div>
                    }
                    {(waiver.answer == 'YES' && waiver.confirm_label) &&
                            <div className="small"><Translate id={'members.profile.waiver.signed.confirmationLabel'} />{i18nContext.getGenericLocale() == 'fr' ? `«${waiver.confirm_label}»` : `"${waiver.confirm_label}"` }</div>
                    }
                </div>
                :
                !noUnsignedSection ?
                    <div className='d-flex'>
                        <i className="mdi mdi-close text-danger mr-2" /><span className='font-bold'><Translate id='members.profile.waiver.signed.not' /></span>
                    </div>
                    :
                    null
        )
    }return null;

}