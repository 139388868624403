import { useDisclosure } from "@mantine/hooks";
import { useContext, useEffect } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import NotifBadge from "../notifBadge/NotifBadge";
import { Link } from "react-router-dom";
import { RolesContext } from "../../../../contexts/RolesContext";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import NoticesList from "../../../../components/noticesList/noticesList/NoticesList";
import { useReadNews } from "../../../../components/noticesList/useReadNews";
import { useNewsSWR } from "../../../../components/noticesList/useNewsSWR";
import { useTour } from "@reactour/tour";
import { getSessionStorageItem, setSessionStorageItem } from "../../../../helpers/browserStorage";
import { Tooltip } from "@mantine/core";

const NoticesNav = () => {
    const { hasAccessTo, canDoAction } = useContext(RolesContext);
    const { isGod } = useContext(IdentityRolesContext);
    const canSeeNews = hasAccessTo("communications", "communication_news_manage");
    const canManageNews = canDoAction("EDIT", "communications", "communication_news_manage") || canDoAction("DELETE", "communications", "communication_news_manage") || canDoAction("ADD", "communications", "communication_news_manage");
    const { data: notices, isValidating } = useNewsSWR(canSeeNews || isGod());

    return (
        ((canSeeNews && notices.length > 0) || canManageNews || isGod()) ? // Show button if can read and hase news OR if you have managing rights
            <NoticesNavManage
                canSeeNews={canSeeNews}
                canManageNews={canManageNews}
                notices={notices}
                isValidating={isValidating}
            />
            :
            null
    )

}

const NoticesNavManage = ({ canSeeNews, canManageNews, notices, isValidating }) => {
    const unreadNotices = (notices || []).filter((n) => !n.read);
    const [ listIsOpen, { open, close } ] = useDisclosure(false);
    const [ isOpen, { toggle } ] = useDisclosure(false);
    const [ readNotices ] = useReadNews(notices);
    const { isGod } = useContext(IdentityRolesContext);
    const { isOpen: tourIsOpen } = useTour();

    const handleOnOpen = () => {
        readNotices(unreadNotices);
        open();
    }

    const handleInitOpen = () => {
        if(!tourIsOpen && unreadNotices?.length > 0 && getSessionStorageItem('initializedNews') != 1){
            handleOnOpen();
        }
        if(!isValidating && unreadNotices?.length > 0){
            setSessionStorageItem('initializedNews', 1);
        }
    }

    useEffect(() => {
        handleInitOpen();
    }, [ unreadNotices?.length, isValidating ]);

    return (
        <>
            <Dropdown isOpen={isOpen} toggle={toggle} nav inNavbar>
                <DropdownToggle nav className="position-relative" onClick={(canManageNews || isGod()) ? toggle : handleOnOpen}>
                    <Tooltip
                        withArrow
                        label={<Translate id='header.notifications.tabs.news' />}
                        zIndex={3000}
                    >
                        <span className={stringBuilder({ "text-purple": !canSeeNews && !canManageNews && isGod() })}>
                            <i className="font-medium ti ti-announcement font-18" />
                            {unreadNotices?.length > 0 &&
                            <NotifBadge color={canSeeNews ? "federation" : "purple"}>
                                {unreadNotices.length > 9 ? "9+" : unreadNotices.length}
                            </NotifBadge>
                            }
                        </span>
                    </Tooltip>
                </DropdownToggle>
                {(canManageNews || isGod()) &&
                    <DropdownMenu right>
                        {(notices?.length > 0) &&
                            <DropdownItem onClick={handleOnOpen} className={stringBuilder({ "text-purple": !canSeeNews })}>
                                <Translate id="news.nav.view" />
                            </DropdownItem>
                        }
                        <DropdownItem className={stringBuilder({ "text-purple": !canManageNews })} tag={Link} to="/news">
                            <Translate id="news.nav.manage" />
                        </DropdownItem>
                    </DropdownMenu>
                }
            </Dropdown>
            <NoticesList
                notices={notices}
                isOpen={listIsOpen}
                toggle={close}
                canManageNews={canManageNews || isGod()}
            />
        </>
    );
}

export default NoticesNav;