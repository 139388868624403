import React from "react";
import { Link } from 'react-router-dom';
import {
    Table,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    FormGroup,
    Label,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row,
    Col
} from "reactstrap";

import { FormikInputNumber, FormikSelect } from '@spordle/formik-elements';
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import FormikEditable from "../../../../../../components/formik/FormikEditable";
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import { connect, useFormikContext } from "formik";
import I18nHelperContextProvider, { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import NoImgItem from '../../../../../../assets/images/NoImage_Item.svg'
import { addBreadcrumb, Severity } from "@sentry/react";

import UserImg from "../../../../../../components/UserImg";
import { stringBuilder } from "@spordle/helpers";
import { OtherFeesContext } from "../../../../../../contexts/OtherFeesContext";
import withContexts from "../../../../../../helpers/withContexts";
import OtherItemsSidepanel from "../../../../../catalog/otherItems/components/OtherItemsSidepanel";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";

class OnlineStoreOtherItemsSidepanel extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            activeTab: '1',
            isLoading: false,
            otherFee: null,
        }
    }

    toggleTab(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    removeItem = () => {
        this.props.tableRef.deleteRow(this.props.selectedRows[0].other_fee_id)
            .then(() => {
                this.props.formik.setFieldValue('otherItems', this.props.tableRef.getData())
            })
            .then(() => {
                this.props.saveItems();
            })
            .then(this.props.toggle)
    }


    getOtherFee = () => {
        this.setState(() => ({ isLoading: true }));
        this.props.OtherFeesContext.getOtherItem(this.props.selectedRows[0].other_fee_id)
            .then((otherFee) => {
                this.setState(() => ({ otherFee: otherFee }));
            })
            .catch((error) => { console.error(error.message) })
            .finally(() => this.setState(() => ({ isLoading: false })));
    }

    componentDidMount(){
        this.getOtherFee();
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedRows[0].other_fee_id != this.props.selectedRows[0].other_fee_id){
            this.getOtherFee();
        }
    }

    render(){
        const otherItemIndex = this.props.formik.values.otherItems.findIndex((item) => item.other_fee_id === this.props.selectedRows[0].other_fee_id);
        return (
            <>
                <div className="p-3">
                    <div className='d-flex mb-2'>
                        <button type='button' className='reset-btn p-2 text-dark' onClick={this.props.toggle}><i className='mdi mdi-arrow-right font-20' /></button>
                        <div className="ml-auto d-flex align-items-center">
                            <UncontrolledDropdown tag="span">
                                <DropdownToggle tag="button" caret className="btn btn-primary">
                                    <Translate id='misc.actions' />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem onClick={this.removeItem}>
                                        <Translate id='misc.remove' />
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                    <UserDisplay className="mb-3">
                        <UserDisplay.Container>
                            <UserImg
                                className="rounded-lg border shadow-none"
                                width={60}
                                src={this.props.selectedRows[0].image?.full_path || NoImgItem}
                                filePos={this.props.selectedRows[0].image?.file_position}
                                alt={this.props.selectedRows[0].fee.name}
                            />
                        </UserDisplay.Container>
                        <UserDisplay.Container className="min-w-0">
                            <div className="d-flex align-items-center">
                                <div className="font-bold h6 mb-0"><DisplayI18n field='name' i18n={this.props.selectedRows[0].fee.i18n} defaultValue={this.props.selectedRows[0].fee.name} /></div>
                                <div className="mx-2">|</div>
                                <div className="font-medium"><CurrencyFormat value={this.props.selectedRows[0].fee.amount / 100} /></div>
                            </div>
                            <div className='text-muted'>
                                <DisplayI18n i18n={this.props.selectedRows[0].fee.i18n} field='description' defaultValue={this.props.selectedRows[0].fee.description} />
                            </div>
                        </UserDisplay.Container>
                    </UserDisplay>
                    <div className="small"><Link to="/catalog/otheritems" className="reset-btn text-primary"><Translate id="onlineStore.components.onlineStoreItemsFees.otherFee.goTo" /></Link></div>

                </div>

                <Nav tabs>
                    <NavItem className="w-50 text-center">
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggleTab('1'); }}
                        >
                            <Translate id='onlineStore.components.onlineStoreItemsFees.item' />
                        </NavLink>
                    </NavItem>
                    <NavItem className="w-50 text-center" disabled={!this.state.registrationFee}>
                        <NavLink
                            className={stringBuilder({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggleTab('2'); }}
                        >
                            <Translate id='catalog.registrationFees.sidePanel.tab.fee' />
                        </NavLink>
                    </NavItem>
                </Nav>
                <div className="p-3 mb-5">
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for='active' className='text-muted'><Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.table.status' /></Label>
                                        <FormikEditable
                                            id='active'
                                            noConfirmation
                                            disabled={this.props.selectedRows[0].other_fee.active == 0} // Overwrite if fee is active=0}
                                            initialValues={{
                                                active: this.props.selectedRows[0].active,
                                            }}
                                            onSubmit={async(values) => {
                                                await this.props.syncRows(this.props.createNewValues(values));
                                                this.props.saveItems();
                                            }}
                                        >
                                            {(isEditing, options) => {
                                                if(!isEditing){
                                                    return (
                                                        <div className='font-medium text-dark'>
                                                            <Translate id={`onlineStore.components.onlineStoreItemsFees.status.${this.props.selectedRows[0].other_fee.active == 0 ? 'INACTIVE' : this.props.selectedRows[0].active == 1 ? 'ACTIVE' : 'INACTIVE'}`} />
                                                            {this.props.selectedRows[0].other_fee.active == 0 &&
                                                        <div>
                                                            <div className="small font-medium"><i className="mdi mdi-alert-outline mr-1 text-danger" /><Translate id="onlineStore.components.onlineStoreItemsFees.otherFee.inactive" /></div>
                                                        </div>
                                                            }
                                                        </div>
                                                    )
                                                }
                                                return (
                                                    <FormikSelect
                                                        name='active' id='active_OnlineStoreOtherItemsSidepanel' search={false}
                                                        autoFocus menuIsDefaultOpen
                                                        onOptionSelected={options.stopEditing}
                                                        loadingStatus='success'
                                                        defaultData={[
                                                            { id: 'ACTIVE', label: 'onlineStore.components.onlineStoreItemsFees.status.ACTIVE', translateLabel: true, value: '1', selected: this.props.selectedRows[0].active == 1 },
                                                            { id: 'INACTIVE', label: 'onlineStore.components.onlineStoreItemsFees.status.INACTIVE', translateLabel: true, value: '0', selected: this.props.selectedRows[0].active == 1 },
                                                        ]}
                                                    />
                                                )

                                            }}
                                        </FormikEditable>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for='display_order' className='text-muted'><Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.table.display_order' /></Label>
                                        <FormikEditable
                                            id='display_order'
                                            noConfirmation
                                            initialValues={{
                                                display_order: this.props.selectedRows[0].display_order || 0,
                                            }}
                                            onSubmit={async(values) => {
                                                await this.props.syncRows(this.props.createNewValues(values));
                                                this.props.saveItems();
                                            }}
                                        >
                                            {(isEditing) => {
                                                if(!isEditing){
                                                    return (
                                                        <div className='font-medium text-dark'>
                                                            {this.props.selectedRows[0].display_order || 0}
                                                        </div>
                                                    )
                                                }
                                                return (
                                                    <FormikInputNumber
                                                        autoFocus
                                                        name='display_order'
                                                        id='display_order_OnlineStoreRegistrationSidepanel'
                                                        allowNegative={false}
                                                    />
                                                )

                                            }}
                                        </FormikEditable>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="mb-3">
                                <div className="text-muted mb-2 d-flex justify-content-between align-items-center">
                                    <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.link.label' />
                                    {Array.isArray(this.props.formik.values.otherItems[otherItemIndex]?.linked_to) && this.props.formik.values.otherItems[otherItemIndex]?.linked_to?.some((linked) => linked.mandatory == 0 || !linked.mandatory) ?
                                        <button
                                            type='button'
                                            className='reset-btn text-link'
                                            onClick={() => {
                                                addBreadcrumb({
                                                    type: 'user',
                                                    message: 'Linking all registration(s) to an other item',
                                                    level: Severity.Log,
                                                    category: 'click',
                                                });
                                                // Proceed to add only the one that are not already linked
                                                // this.props.formik.values.otherItems[otherItemIndex].linked_to will contain only the ones that are already linked
                                                this.props.formik.setFieldValue(`otherItems.${otherItemIndex}.linked_to`, this.props.formik.values.registrationFees.reduce((builtValues, fee) => {
                                                    const index = builtValues.findIndex((item) => item.registration_fee_id === fee.registration_fee_id);
                                                    if(index === -1){
                                                        builtValues.push({
                                                            registration_fee_id: fee.registration_fee_id,
                                                            mandatory: true,
                                                        });
                                                    }else{
                                                        builtValues[index].mandatory = true;
                                                    }
                                                    return builtValues;
                                                }, this.props.formik.values.otherItems[otherItemIndex]?.linked_to || []))
                                                    .then(this.props.saveItems)
                                                    .catch((error) => { console.error(error.message) });
                                            }}
                                        >
                                            <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.link.all' />
                                        </button>
                                        :
                                        <button
                                            type='button'
                                            className='reset-btn text-link'
                                            onClick={() => {
                                                addBreadcrumb({
                                                    type: 'user',
                                                    message: 'Unlinking all registration(s) to an other item',
                                                    level: Severity.Log,
                                                    category: 'click',
                                                });
                                                // Proceed to add only the one that are not already linked
                                                // this.props.formik.values.otherItems[otherItemIndex].linked_to will contain only the ones that are already linked
                                                this.props.formik.setFieldValue(`otherItems.${otherItemIndex}.linked_to`, this.props.formik.values.registrationFees.reduce((builtValues, fee) => {
                                                    const index = builtValues.findIndex((item) => item.registration_fee_id === fee.registration_fee_id);
                                                    if(index === -1){
                                                        builtValues.push({
                                                            registration_fee_id: fee.registration_fee_id,
                                                            mandatory: false,
                                                        });
                                                    }else{
                                                        builtValues[index].mandatory = false;
                                                    }
                                                    return builtValues;
                                                }, this.props.formik.values.otherItems[otherItemIndex]?.linked_to || []))
                                                    .then(this.props.saveItems)
                                                    .catch((error) => { console.error(error.message) });
                                            }}
                                        >
                                            <Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.unlink.all' />
                                        </button>
                                    }
                                </div>
                                <Table bordered striped>
                                    <thead>
                                        <tr>
                                            <th><Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.registration' /></th>
                                            <th className="text-center d-none"><Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.mandatory' /></th>
                                            <th className="text-center"><Translate id='onlineStore.components.onlineStoreItemsFees.otherItems.sidePanel.column.mandatory' /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {otherItemIndex !== -1 && this.props.formik.values.registrationFees.map((registration, index) => (
                                            <LinkRegistrationRow key={registration.registration_fee_id} index={index} registration={registration} otherItemIndex={otherItemIndex} saveItems={this.props.saveItems} />
                                        ))}
                                    </tbody>
                                </Table>
                            </div>

                        </TabPane>
                        <TabPane tabId="2">
                            <OverlayLoader isLoading={this.state.isLoading}>
                                {!!this.state.otherFee &&
                                    <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
                                        <OtherItemsSidepanel
                                            readOnly
                                            selectedRows={[ this.state.otherFee ]}
                                        />
                                    </I18nHelperContextProvider>
                                }
                            </OverlayLoader>
                        </TabPane>
                    </TabContent>
                </div>
            </>
        )
    }
}

const LinkRegistrationRow = ({ registration, otherItemIndex, saveItems }) => {
    const formik = useFormikContext();
    /** @type {Array} */
    const linkedTo = formik.values.otherItems[otherItemIndex].linked_to || [];
    const linkIndex = linkedTo.findIndex((link) => link.registration_fee_id === registration.registration_fee_id);

    //const isLinked = linkIndex !== -1;
    // From the api, mandatory will be string '1' but with javascript, mandatory will be boolean -> == 1 will give true when '1' and true(boolean)
    const isMandatory = linkIndex === -1 ? false : formik.values.otherItems[otherItemIndex].linked_to[linkIndex].mandatory == 1;

    return (
        <tr>
            <td className="font-medium">
                <DisplayI18n
                    field='name'
                    i18n={registration.i18n}
                    defaultValue={registration.name}
                />
                {registration.team_category?.class &&
                    <>
                        <span className='mx-1'>-</span>
                        <DisplayI18n
                            field='short_name'
                            i18n={registration.team_category?.class?.i18n}
                            defaultValue={registration.team_category?.class?.short_name}
                        />
                    </>
                }
            </td>
            {/* <td className="text-center d-none">
                <CustomInput type='checkbox' id={`${registration.registration_fee_id}-checkbox`} checked={isMandatory}
                    onChange={(e) => {
                        if (isLinked) {// Already exists in the values
                            formik.setFieldValue(`otherItems.${otherItemIndex}.linked_to.${linkIndex}.mandatory`, e.currentTarget.checked);
                        } else {
                            linkedTo.push({
                                registration_fee_id: registration.registration_fee_id,
                                mandatory: e.currentTarget.checked
                            })
                            formik.setFieldValue(`otherItems.${otherItemIndex}.linked_to`, linkedTo);
                        }
                    }}
                />
            </td> */}
            <td className="text-center">
                <Label for={`${registration.registration_fee_id}-switch`} className='switch'>
                    <input
                        id={`${registration.registration_fee_id}-switch`} type='checkbox' checked={isMandatory}
                        onChange={(e) => {
                            addBreadcrumb({
                                type: 'user',
                                message: 'Linking/Unliking registration to an other item',
                                level: Severity.Log,
                                category: 'click',
                                data: {
                                    isLinking: e.currentTarget.checked,
                                },
                            });

                            // if(isLinked){ // Already exists in the values
                            //     formik.setFieldValue(`otherItems.${otherItemIndex}.linked_to.${linkIndex}.mandatory`, e.currentTarget.checked)
                            //         .then(saveItems)
                            //         .catch((error) => { console.error(error.message) });
                            // }else{
                            //     linkedTo.push({
                            //         registration_fee_id: registration.registration_fee_id,
                            //         mandatory: e.currentTarget.checked,
                            //     })
                            //     formik.setFieldValue(`otherItems.${otherItemIndex}.linked_to`, linkedTo)
                            //         .then(saveItems)
                            //         .catch((error) => { console.error(error.message) });
                            // }


                            // ** NEW ** PUT seems to not work well when we don't send all the registration fees
                            // ** SO we will loop and put them all, linked on/off at all times to prevent the PUT bug
                            formik.setFieldValue(`otherItems.${otherItemIndex}.linked_to`, formik.values.registrationFees.reduce((builtValues, fee) => {
                                // See if we already have it linked
                                const alreadyLinkedIndex = linkedTo.findIndex((item) => item.registration_fee_id === fee.registration_fee_id);

                                // current registration fee switch
                                const isCurrentRegistration = registration.registration_fee_id == fee.registration_fee_id;

                                // Take switch checked value if we are on current, if not take from what already exists
                                // using !!Number() to check if string "0" or "1" == false or true
                                const isNowMandatory = isCurrentRegistration ? e.currentTarget.checked : linkedTo[alreadyLinkedIndex] ? !!Number(linkedTo[alreadyLinkedIndex]?.mandatory) : false;

                                builtValues.push({
                                    registration_fee_id: fee.registration_fee_id,
                                    mandatory: isNowMandatory,
                                });

                                return builtValues;

                            }, []))
                                .then(saveItems)
                                .catch((error) => { console.error(error.message) });
                        }}
                    />
                    <span className="switch-toggler" />
                </Label>
            </td>
        </tr>
    )
}

export default withContexts(OtherFeesContext)(connect(OnlineStoreOtherItemsSidepanel));