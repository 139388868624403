import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import {
    ModalBody,
    ModalFooter,
    Button
} from "reactstrap";
import { DisplayI18n, displayI18n } from '../../helpers/i18nHelper';
import { object, string } from 'yup';
import Translate from '@spordle/intl-elements';

// contexts
import { I18nContext } from '../../contexts/I18nContext';
import withContexts from '../../helpers/withContexts';
import { WaiverFields, WaiverMandatoryHelper, waiverNotSigned, WaiverSignature } from '../../helpers/waiversHelper';
import CustomAlert from '../CustomAlert';

class ManualRegistrationWaivers extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            activeIndex: 0,
        }
    }

    displayWaiver = () => (
        <>
            <div className="text-info h4 mb-0 font-weight-bold">{this.props.waivers[this.state.activeIndex].organisation?.organisation_name}</div>
            <div className="font-weight-bold h5 mb-3"><DisplayI18n field="name" i18n={this.props.waivers[this.state.activeIndex].i18n} defaultValue={this.props.waivers[this.state.activeIndex].name} /></div>
            <div dangerouslySetInnerHTML={{ __html: displayI18n('description', this.props.waivers[this.state.activeIndex].i18n, this.props.waivers[this.state.activeIndex].description, this.props.I18nContext.getGenericLocale()) }} />
        </>
    )

    /**
     * Returns an object of the right format for the API call
     * @param {string} waiverId ID of the waiver to sign
     * @param {string} answer The answer to the waiver
     * @returns {Object}
     */
    getFormattedWaivers = (waiverId, answer, signatureType) => {
        return {
            waiver_id: waiverId,
            language_code: this.props.I18nContext.getGenericLocale(),
            answer: answer,
            signature_type: signatureType,
        }
    }

    /**
     * Recursive function that signs all waivers
     * @param {Function} setStatus SetStatus from formik to set the status in case of an error
     * @param {Number} waiverIndex index of the waiver to sign
     */
    skipAllWaivers = (setStatus, waiverIndex) => {
        const waiver = this.props.waivers[waiverIndex];
        this.props.onSubmit(
            this.getFormattedWaivers(waiver.waiver_id, waiverNotSigned, null),
            setStatus,
            waiverIndex,
            () => {
                this.setState((prevState) => ({ activeIndex: prevState.activeIndex + 1 }))
                this.skipAllWaivers(setStatus, waiverIndex + 1)
            },
        )
    }

    render(){
        return (
            <Formik
                key={this.state.activeIndex}
                initialValues={{
                    answer: '',
                    waiverId: this.props.waivers[this.state.activeIndex].waiver_id,
                    signatureType: '',
                }}
                validationSchema={object().shape({
                    answer: string(),
                    waiverId: string().required(),
                    signatureType: string().test({
                        name: 'signatureRequiredTest',
                        message: <Translate id='form.fields.waiver.signature.required' />,
                        test: function(value){
                            if(this.parent.answer && this.parent.answer !== waiverNotSigned){
                                return !!value
                            }
                            return true
                        },
                    }),
                })}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                    this.props.onSubmit(
                        this.getFormattedWaivers(values.waiverId, values.answer || waiverNotSigned, values.signatureType),
                        setStatus,
                        this.state.activeIndex,
                        () => this.setState((prevState) => ({ activeIndex: prevState.activeIndex + 1 })),
                    )
                }}
            >
                {(formik) => (
                    <Form>
                        <ModalBody key={this.state.activeIndex} className="py-4">
                            <div className="h4 font-bold d-flex justify-content-between">
                                <span>
                                    <Translate id='components.manualRegistration.waivers.title' /> ({this.state.activeIndex + 1} <Translate id='components.manualRegistration.waivers.of' /> {this.props.waivers.length})
                                </span>
                                {this.props.waivers.length > 1 &&
                                    <span className='small align-self-end'>
                                        <button className='reset-btn text-link' type='button' onClick={() => this.skipAllWaivers(formik.setStatus, this.state.activeIndex)}>
                                            <Translate id='components.manualRegistration.waivers.skipAll' /> <i className='mdi mdi-arrow-right' />
                                        </button>
                                    </span>
                                }
                            </div>

                            <div className="vh-50 bg-white overflow-auto mb-3 p-2 bg-light border rounded">
                                {this.displayWaiver()}
                            </div>

                            <WaiverMandatoryHelper waiver={this.props.waivers[this.state.activeIndex]} forceNotMandatory />

                            <WaiverFields waiver={this.props.waivers[this.state.activeIndex]} allowSkip fieldName='answer' />

                            <WaiverSignature fieldName='signatureType' answer={formik.values.answer} />

                            {formik.status &&
                                <CustomAlert color='danger' className='mt-2' toggle={() => formik.setStatus()} translateText={false} text={formik.status} />
                            }
                        </ModalBody>
                        <ModalFooter tag={'footer'}>
                            {(!this.props.hidePreviousButton || this.state.activeIndex !== 0) &&
                                <Button
                                    color="primary" outline className="mr-auto"
                                    onClick={() => {
                                        if(this.state.activeIndex === 0){
                                            this.props.goToPrevious()
                                        }else{
                                            this.setState((prevState) => ({ activeIndex: prevState.activeIndex - 1 }))
                                        }
                                    }}
                                ><Translate id='misc.previous' />
                                </Button>
                            }
                            <Button type='submit' color="primary"><Translate id='misc.next' /></Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default withContexts(I18nContext)(ManualRegistrationWaivers);

ManualRegistrationWaivers.propTypes = {
    waivers: PropTypes.array.isRequired,
    goToPrevious: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}