import { useContext, useEffect } from "react";
import { useCountriesSWR } from "../../api/useSWRHelpers/utilsSWR";
import { DisplayI18n } from "../../helpers/i18nHelper";
import { FormikElementsGlobalConfigsContext, FormikSelect } from "@spordle/formik-elements";
import { useFormikContext } from "formik";
import { formatSelectData } from "@spordle/spordle-select";
import Translate from "@spordle/intl-elements";

const FormikSelectCountries = ({ filterOptions, countryRestrictions, ...props }) => {
    const formikElementsConfigs = useContext(FormikElementsGlobalConfigsContext);
    const _countries = countryRestrictions || formikElementsConfigs?.formikAddress?.countryRestrictions || [ 'ca', 'us' ]
    const { setFieldValue, values } = useFormikContext();
    const { data, isValidating } = useCountriesSWR();
    const isLoading = !data && isValidating;
    const options = formatSelectData((data || []), {
        newGroupIndexes: {
            0: {
                label: "address.suggested",
                groupId: "suggested",
            },
        },
        getLabel: (option) => option.code,
        getValue: (option) => option.code,
        getGroupId: (option) => _countries.find((c) => c === (option.code || "").toLowerCase()) ? 'suggested' : void 0,
    });

    useEffect(() => {
        if(Array.isArray(data) && values.country_code && !values.country_id){
            setFieldValue('country_id', data.find(({ code }) => code == values.country_code)?.country_id)
        }
    }, [ Array.isArray(data), !!values.country_id, !!values.country_code ])

    return (
        <FormikSelect
            id="country_code"
            isLoading={isLoading}
            name="country_code"
            renderOption={({ option }) => (option.groupId === 'suggested' && option.isGroup) ? <Translate id="misc.select.suggested" /> : <DisplayI18n field="name" i18n={option.i18n} defaultValue={option.label} />}
            {...props}
            options={options}
            onOptionSelected={([ val ], select) => {
                props.onOptionSelected?.([ val ]);
                setFieldValue('province_code', '');
                setFieldValue('country_id', select.getSpordleTable().getData().find(({ value }) => value == val)?.country_id || "");
                setFieldValue('country_code', val || "");
                return true;
            }}
        />
    );
}

export default FormikSelectCountries;