import React, { createContext } from 'react';
import API_SPORDLE from '../api/API-Spordle';
import { serverError } from '../api/CancellableAPI';
import { stringifyUrl } from 'query-string';
import { IdentityRolesContext } from './IdentityRolesContext';
import withContexts from '../helpers/withContexts';

/** @type {React.Context<Omit<UtilsContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const UtilsContext = createContext();
UtilsContext.displayName = 'UtilsContext';

class UtilsContextProvider extends React.PureComponent{
    state = {
        // false for now -> as soon as a user loads a countries select (e.g. google places), this state will be filled with said countries and cache them
        countries: false,
    }

    /**
     * @private
     * Cached payment methods
     */
    _paymentMethods

    /** @private */
    _taxClasses

    /**
     * Returns all the available payment methods
     * @returns {Promise<Array<Object>>}
     */
    getPaymentMethods = (fromApi = false, withFunding = false) => {
        if(this._paymentMethods && !fromApi){
            return Promise.resolve(this._paymentMethods.filter((method) => (withFunding ? true : method.code !== 'FUNDING')));
        }
        return API_SPORDLE.get(stringifyUrl({ url: `/utils/payment-methods` }))
            .then((response) => {
                if(response.data.status){
                    return this._paymentMethods = response.data.payment_methods.filter((method) => (withFunding ? true : method.code !== 'FUNDING')).sort((pMa, pMb) => parseInt(pMa.display_order) - parseInt(pMb.display_order));
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Returns all the available relations
     * @returns {Promise}
     */
    getRelations = () => {
        return API_SPORDLE.get(stringifyUrl({ url: `/utils/relations` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.relations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Returns all countries from the state or makes an API call to get them
     * @returns {Promise<Array>}
     */
    getCountries = (params) => {
        if(this.state.countries && !params){
            return Promise.resolve(this.state.countries)
        }
        return API_SPORDLE.get(stringifyUrl({
            url: `/utils/countries`,
            query: params || {},
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    if(!params){
                        this.setState((prevState) => ({
                            ...prevState,
                            countries: response.data.countries,
                        }))
                    }
                    return response.data.countries;
                }
                throw response.data.errors[0];
            }, serverError)

    }

    /**
     * Returns all provinces for a country
     * @param {string} countryId ID of the country to get provinces from
     * @returns {Promise<Array>}
     */
    getProvinces = (countryId) => {
        return API_SPORDLE.get(stringifyUrl({ url: `/utils/countries/${countryId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.countries;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * @typedef {object} Setting
     * @property {string} code
     * @property {string} name
     * @property {Record<string, any>} i18n
     * @property {any} response
     */
    /**
     * Formats the settings info from the api to our JS structure
     * @param {Setting[]} settings
     * @returns {object}
     * @private
     */
    _formatSettings = (settings) => {
        return settings.reduce((formattedSettings, setting) => {
            switch (setting.type){
                case 'MULTIPLE':
                case 'RADIO':
                    formattedSettings[setting.code] = {
                        value: JSON.parse(setting.response),
                        name: setting.name,
                        i18n: setting.i18n,
                        active: setting.active == '1',
                        type: setting.type,
                        is_internal: setting.is_internal,
                        resource_id: setting.resource_id,
                        managed_by: setting.managed_by,
                    }
                    break;
                default:
                    formattedSettings[setting.code] = {
                        value: setting.response,
                        name: setting.name,
                        i18n: setting.i18n,
                        active: setting.active == '1',
                        type: setting.type,
                        is_internal: setting.is_internal,
                        resource_id: setting.resource_id,
                        managed_by: setting.managed_by,
                    };
                    break;
            }
            return formattedSettings;
        }, {});
    }

    /**
     * Gets settings according to the query params
     * @param {object} queryParams Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Utils/Apicontroller%5CUtils%5CUtils%3A%3AgetSettings documentation}
     * @returns {Promise<Array>}
     */
    getSettings = (queryParams) => {
        return API_SPORDLE.get(stringifyUrl({
            url: '/utils/settings',
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return this._formatSettings(response.data.settings);
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets tax classes
     * @param {boolean} [fromApi]
     * @returns {Promise<Array>}
     */
    getTaxClasses = (fromApi = false) => {
        if(Array.isArray(this._taxClasses) && !fromApi){
            return Promise.resolve(this._taxClasses);
        }
        return API_SPORDLE.get('/tax-classes')
            .then((response) => {
                if(response.data.status){
                    return this._taxClasses = response.data.tax_classes;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] - Create message attachments
     * @param {string} messageId ID of the message to create attachments for
     * @param {Array} attachments Array of the attachments (from FileUpload)
     * @returns {Promise}
     */
    createMessageAttachment = (messageId, attachments) => {
        const params = new FormData();
        for(let index = 0; index < attachments.length; index++){
            const attachment = attachments[index];
            params.append(`attachments[${index}][attachment]`, attachment)
        }

        return API_SPORDLE.post(stringifyUrl({ url: `/messages/${messageId}/attachments` }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] - Deletes a message attachment
     * @param {string} messageId ID of the message
     * @param {string} messageAttachmentId ID of the message attachment
     * @returns {Promise}
     */
    deleteMessageAttachment = (messageId, messageAttachmentId) => {
        return API_SPORDLE.delete(stringifyUrl({ url: `/messages/${messageId}/attachments/${messageAttachmentId}` }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get canadian postal codes of Canadian cities by parameters
     * @param {object} params ID of the message
     * @see https://api.id.dev.spordle.dev/documentations/#/Utils/d86e3f313f6cf1083fa5d150a3ee6db5
     * @returns {Promise.<Array>}
     */
    getPostalCodesFor = (params) => {
        return API_SPORDLE.get(stringifyUrl({
            url: '/postal-codes',
            query: params,
        }, {
            skipNull: true,
            skipEmptyString: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.postal_codes;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get all tags by organisation id in parameter
     * @param {object} params
     * @see https://api.id.dev.spordle.dev/documentations/#/Tags/3092999b4c0e7f95966f992cbbb389ab
     * @returns {Promise.<Array>}
     */
    getTags = (params) => {
        return API_SPORDLE.get(stringifyUrl({
            url: '/tags',
            query: params,
        }, {
            skipNull: true,
            skipEmptyString: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.tags;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Create a tag
     * @param {object} data
     * @see https://api.id.dev.spordle.dev/documentations/#/Tags/fa09242111afd6b9536418b825e68a77
     * @returns {Promise.<string>}
     */
    createTags = (data) => {
        const params = new URLSearchParams(data);
        return API_SPORDLE.post('/tags', params)
            .then((response) => {
                if(response.data.status){
                    return response.data.tag_id;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * [GET] - Gets the indigenous groups
     * @returns {Promise<Array>}
     */
    getIndigenousGroups = () => {
        return API_SPORDLE.get(stringifyUrl({
            url: `/utils/indigenous-groups`,
            query: {
                organisation_id: this.props.IdentityRolesContext.federation?.organisation_id,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.indigenous_groups;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] - Gets ethnicities
     * @returns {Promise<Array>}
     */
    getEthnicities = () => {
        return API_SPORDLE.get(stringifyUrl({
            url: `/utils/ethnicity`,
            query: {
                organisation_id: this.props.IdentityRolesContext.federation?.organisation_id,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.ethnicities;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getBackgroundCheckDurations = () => {
        return API_SPORDLE.get(stringifyUrl({
            url: 'background-check-durations',
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.background_check_durations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    calculateFees = (organizationId, amount, type) => {
        return API_SPORDLE.get(stringifyUrl({
            url: 'utils/fees-calculator',
            query: {
                organisation_id: organizationId,
                amount,
                type,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.fees;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    render(){
        return (
            <UtilsContext.Provider value={{
                ...this.state,
                ...this,
            }}
            >
                {this.props.children}
            </UtilsContext.Provider>
        );
    }
}

export default withContexts(IdentityRolesContext)(UtilsContextProvider);
