import Translate, { CurrencyFormat } from "@spordle/intl-elements"
import { Link } from "react-router-dom"
import CarousselWidget from "./widgets/CarousselWidget"
import GraphWidget from "./widgets/GraphWidget"
import ListDataType from "./widgets/ListDataType"
import PreviewDataType from "./widgets/PreviewDataType"
import TableDataType from "./widgets/TableDataType"

/**
 * @param {{ link: string, type: string }} widget
 * @returns {React.ReactNode}
 */
export const getLink = (widget) => {
    switch (widget.link){
        case 'catalog':
            return (
                <Link to='/catalog' className='text-nowrap'>
                    <Translate id={widget.type !== 'small' ? "sidebar.catalog" : "dashboard.widgets.view"} /><i className='mdi mdi-chevron-right' />
                </Link>
            )
        case 'transfers':
            return (
                <Link to='/tasks/transfer' className='text-nowrap'>
                    <Translate id={widget.type !== 'small' ? "sidebar.reports.transfers" : "dashboard.widgets.view"} /><i className='mdi mdi-chevron-right' />
                </Link>
            )
        case 'supportRequest':
            return (
                <Link to='/supportRequest' className='text-nowrap'>
                    <Translate id={widget.type !== 'small' ? "header.notifications.tabs.supportRequest" : "dashboard.widgets.view"} /><i className='mdi mdi-chevron-right' />
                </Link>
            )
        case 'members':
            return (
                <Link to='/members' className='text-nowrap'>
                    <Translate id={widget.type !== 'small' ? "sidebar.members" : "dashboard.widgets.view"} /><i className='mdi mdi-chevron-right' />
                </Link>
            )
        case 'teams':
            return (
                <Link to='/teams' className='text-nowrap'>
                    <Translate id={widget.type !== 'small' ? "sidebar.teams" : "dashboard.widgets.view"} /><i className='mdi mdi-chevron-right' />
                </Link>
            )
        case 'onlinestores':
            return (
                <Link to='/onlineregistration' className='text-nowrap'>
                    <Translate id={widget.type !== 'small' ? "sidebar.onlineStore" : "dashboard.widgets.view"} /><i className='mdi mdi-chevron-right' />
                </Link>
            )
        case 'clinics':
            return (
                <Link to='/clinics' className='text-nowrap'>
                    <Translate id={widget.type !== 'small' ? "sidebar.clinics" : "dashboard.widgets.view"} /><i className='mdi mdi-chevron-right' />
                </Link>
            )
        default:
            break;
    }
}

/**
 *
 * @param {object} widget
 * @param {object} widget.dataType
 * @param {string} widget.dataType.type
 * @param {() => Promise} getApiData
 * @returns
 */
export const parseData = (widget, getApiData) => {
    switch (widget.dataType.code){
        case 'currency':
        case 'count':
            return (
                <div className='m-auto'>
                    <svg viewBox='0 0 100 35' width='100%' height='100%' xmlns="http://www.w3.org/2000/svg">
                        <text x="50" y="5" textAnchor="middle" className='dashboard-grid-small-content'>
                            {widget.dataType.code === 'currency' ?
                                <CurrencyFormat value={widget.data} />
                                :
                                widget.data// count
                            }
                        </text>
                    </svg>
                </div>
            )
        case 'list':
            return <ListDataType getApiData={getApiData} data={widget.data} />
        case 'table':
            return <TableDataType getApiData={getApiData} data={widget.data} />
        case 'preview':
            return <PreviewDataType getApiData={getApiData} data={widget.data} widget={widget} />
        case 'caroussel':
            // Show an actual Caroussel only when there would be a caroussel
            return widget.data.length < 2 ? parseData(widget.data[0].dataType.code, widget.data[0].data) : <CarousselWidget getApiData={getApiData} data={widget.data} mainWidget={widget} />
        default:
            if(widget.dataType.code.endsWith('graph'))
                return <GraphWidget getApiData={getApiData} data={widget.data} graphType={widget.dataType.code.split('-')[0]} />
            break;
    }
}