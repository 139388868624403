import { useContext } from 'react';
import { Redirect, Route, useLocation, useRouteMatch } from 'react-router-dom';
import { TabContent, TabPane } from 'reactstrap';
import { RolesContext } from '../../contexts/RolesContext';

const TabRoutesPanes = ({ routes, getComponentProps }) => {
    const roleContext = useContext(RolesContext);
    const location = useLocation();
    const match = useRouteMatch();
    return (
        <TabContent activeTab={location.pathname} className="bg-transparent">
            {routes.map((route) => {
                if(route.redirect){
                    if(route.dynamicRedirect){
                        const redirectRoute = routes.find(roleContext.validateRouteAccess);// Find the first available route
                        if(redirectRoute){ // If route found, redirect to that route
                            return location.pathname === match.url &&
                                    <Redirect to={`${match.url}${redirectRoute.path}`} from={match.url} key={`redirect-${redirectRoute.path}`} exact />;
                        }// If something goes wrong redirect to the dashboard
                        return location.pathname === match.url &&
                                    <Redirect to='/dashboard' from={match.url} key={`redirect-${redirectRoute.path}`} exact />;

                    }
                    return location.pathname === match.url &&
                                <Redirect to={`${match.url}${route.to}`} from={match.url} key={`redirect-${route.to}`} exact />;

                }
                const routeAccess = roleContext.validateRouteAccess(route);
                return ((!route.hiddenTab && routeAccess) ?
                    // Render all components -> Tab pane will hide the ones that need to be hidden
                    <Route key={route.path} path={`${match.url}${route.exact ? route.path : ''}`} exact={route.exact}>
                        <TabPane key={route.path} tabId={`${match.url}${route.path}`}>
                            {route.tempTab ?// When tempTab, render only when is active
                                location.pathname === `${match.url}${route.path}` && <route.component {...getComponentProps?.(route)} />
                                :
                                <route.component {...getComponentProps?.(route)} />
                            }
                        </TabPane>
                    </Route>
                    :
                    location.pathname === `${match.url}${route.path}` && <Redirect to={match.url} from={`${match.url}${route.path}`} key={`redirect-no-access-${route.path}`} />
                )
            })}
        </TabContent>
    );

};
export default TabRoutesPanes;