import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";
import moment from "moment";
import { useContext } from "react";
import StatusBadge from "../../../../../components/badges/StatusBadge";
import { MembersContext } from "../../../../../contexts/MembersContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";

const MemberOverviewStatus = ({ lastLog }) => {
    const { currentMember } = useContext(MembersContext);
    const statusCreatedBy = lastLog?.created_by;

    return (
        <Tooltip
            withArrow
            position="bottom"
            label={
                <div style={{ maxWidth: 300 }} className="text-wrap">
                    <div>
                        <b><Translate id="members.profile.memberProfile.header.updated_by" /></b> {statusCreatedBy?.name ? `${statusCreatedBy.name} ${statusCreatedBy.family_name}` : <Translate id='members.profile.memberProfile.header.systemUpdate' />}
                    </div>
                    {lastLog && lastLog.organisation_deciding &&
                        <div>
                            <b><Translate id="members.profile.memberProfile.header.organisation_deciding" /></b>
                            <DisplayI18n
                                field="organisation"
                                i18n={lastLog.organisation_deciding.i18n}
                                defaultValue={lastLog.organisation_deciding.organisation_name}
                            />
                        </div>
                    }
                    <div><b><Translate id="members.profile.memberProfile.header.updated_at" /></b> {lastLog?.created_at ? moment(lastLog.created_at).format('YYYY-MM-DD') : '-'}</div>
                    {currentMember.has_been_merged == 1 &&
                        <div><b><Translate id="members.profile.memberProfile.header.merged" />:</b> {currentMember.merged_date ? moment(currentMember.merged_date).format('YYYY-MM-DD') : '-'}</div>
                    }
                    {(lastLog && lastLog.member_status_reason) &&
                        <div><b><Translate id='form.fields.reason' />:</b> <DisplayI18n field='name' defaultValue={lastLog.member_status_reason.name} i18n={lastLog.member_status_reason.i18n} /></div>
                    }
                </div>
            }
        >
            <div className="font-medium">
                <StatusBadge
                    color={currentMember.member_status?.color_code}
                    status={currentMember.member_status}
                    icon={currentMember.has_been_merged == 1 ? <i className='mdi mdi-call-merge' /> : null}
                />
            </div>
        </Tooltip>
    );
}

export default MemberOverviewStatus;