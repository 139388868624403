export default{
    "registration.settings.division.sidePanelRegistrationDivisions.title" : "Informations de Division",

    "registration.settings.division.sidePanelRegistrationDivisions.shortName.required"   : "Le nom est obligatoire",
    "registration.settings.division.sidePanelRegistrationDivisions.description.required" : "La description est obligatoire",

    "registration.settings.division.sidePanelRegistrationDivisions.status"          : "Statut",
    "registration.settings.division.sidePanelRegistrationDivisions.status.active"   : "Actif",
    "registration.settings.division.sidePanelRegistrationDivisions.status.inactive" : "Inactif",
    "registration.settings.division.sidePanelRegistrationDivisions.status.required" : "Le Statut est requis",

    "registration.settings.division.sidePanelRegistrationDivisions.update.success.text" : "Division mise à jour",
    "registration.settings.division.sidePanelRegistrationDivisions.update.fail.text"    : "Une erreur est survenue lors de la mise à jour de la Division, veuillez rafraichir la page et réessayer",

    // actions
    "registration.settings.division.sidePanelRegistrationDivisions.actions.remove"         : "Retirer",
    "registration.settings.division.sidePanelRegistrationDivisions.actions.remove.topText" : "Vous êtes sur le point de retirer cette division",

    // tabs
    "registration.settings.division.sidePanelRegistrationDivisions.tabs.details" : "Détails",
}