import React from "react";
import {
    Row,
    Col,
    Label
} from "reactstrap";
import { FormikInputNumber, FormikInputText, FormikSelect } from '@spordle/formik-elements';
import Translate from "@spordle/intl-elements";
import FormikEditable from "../../../../components/formik/FormikEditable";
import * as Yup from 'yup';
import { success, fail } from '@spordle/toasts';
import OverlayLoader from "../../../../components/loading/OverlayLoader";

//Contexts
import { displayI18n, DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';
import withContexts from "../../../../helpers/withContexts";
import { I18nContext } from "../../../../contexts/I18nContext";
import { RegistrationDivisionsContext } from "../../../../contexts/RegistrationDivisionsContext";
import { IdentityRolesContext } from "../../../../contexts/IdentityRolesContext";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import SidePanel from "../../../../components/sidePanel/SidePanel";

class SettingsRegistrationDivisionSidepanel extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
        }
    }

    componentDidMount(){
        this.setState({ isLoading: false });
    }

    toggleState = (bool) => this.setState((prevState) => ({ isLoading: bool ?? !prevState.isLoading }));

    render(){
        return (
            <I18nHelperContext.Consumer>
                { (i18nHelper) => (
                    <OverlayLoader isLoading={this.state.isLoading}>
                        <SidePanel.Header>
                            <div className='align-items-center d-flex mb-2'>
                                <SidePanel.ToggleButton />
                                <SidePanel.ActionsMenu action='DELETE' componentCode='teams' componentPermissionCode='team_divisions'>
                                    <SidePanel.MenuDelete
                                        translateModalMsg
                                        modalMsg='registration.settings.division.sidePanelRegistrationDivisions.actions.remove.topText'
                                        modalTitle={
                                            <DisplayI18n
                                                field="short_name"
                                                i18n={this.props.getCommonValue('i18n')}
                                                defaultValue={this.props.getCommonValue('short_name')}
                                            />
                                        }
                                        onConfirm={() => {
                                            return this.props.RegistrationDivisionsContext.deleteRegistrationDivision(this.props.getCommonValue('division_id'))
                                                .then(() => {
                                                    this.props.tableRef.deleteRow(this.props.getCommonValue('division_id'))
                                                    this.props.toggle();
                                                    success();
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        fail({
                                                            msg: 'misc.error',
                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                            skipInfoTranslate: true,
                                                        })
                                                    }
                                                })
                                        }}
                                    />
                                </SidePanel.ActionsMenu>
                            </div>
                            <SidePanel.Title>
                                <DisplayI18n
                                    field={'short_name'}
                                    defaultValue={this.props.selectedRows[0].short_name}
                                    i18n={this.props.selectedRows[0].i18n}
                                />
                            </SidePanel.Title>
                            <SidePanel.Subtitle>
                                <DisplayI18n
                                    field={'name'}
                                    defaultValue={this.props.selectedRows[0].name}
                                    i18n={this.props.selectedRows[0].i18n}
                                />
                            </SidePanel.Subtitle>
                        </SidePanel.Header>
                        <div className="p-3">
                            <Row form>
                                <RenderI18nForm field='short_name'>
                                    {({ fieldName, fieldLabel }) => (
                                        <Col sm="6" key={fieldName}>
                                            <div className="mb-3">
                                                <Label for={fieldName} className="text-muted">{fieldLabel}</Label>
                                                <FormikEditable
                                                    id={fieldName}
                                                    initialValues={
                                                        i18nHelper.getInitialValues(this.props.selectedRows[0])
                                                    }
                                                    validationSchema={Yup.object().shape(
                                                        i18nHelper.getValidationSchema({ short_name: Yup.string().required(<Translate id='registration.settings.division.sidePanelRegistrationDivisions.shortName.required' />) }),
                                                    )}
                                                    onSubmit={(values) => {
                                                        const newValues = this.props.createNewValues(values);
                                                        this.toggleState(true);

                                                        this.props.RegistrationDivisionsContext.updateRegistrationDivision(this.props.getCommonValue('division_id'), i18nHelper.getAPIValues(values))
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                                this.toggleState(false);
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                    this.toggleState(false);
                                                                }
                                                            })
                                                    }}
                                                >
                                                    {(isEditing) => {
                                                        if(!isEditing){
                                                            return (
                                                                <div className='font-medium text-dark'>
                                                                    <DisplayI18n
                                                                        field={fieldName}
                                                                        defaultValue={this.props.selectedRows[0].short_name || '-'}
                                                                        i18n={this.props.selectedRows[0].i18n}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                        return (
                                                            <FormikInputText id={fieldName} name={fieldName} autoFocus />
                                                        )

                                                    }}
                                                </FormikEditable>
                                            </div>
                                        </Col>
                                    )}
                                </RenderI18nForm>
                                <RenderI18nForm field='name'>
                                    {({ fieldName, fieldLabel }) => {
                                        const lang = fieldName.substring(0, fieldName.lastIndexOf('.'));

                                        return (
                                            <Col sm="6" key={fieldName}>
                                                <div className="mb-3">
                                                    <Label for={fieldName} className="text-muted">
                                                        <Translate id='form.fields.description.lang' values={{ lang: lang.substring(lang.indexOf('.') + 1, lang.length).toUpperCase() }} />
                                                    </Label>
                                                    <FormikEditable
                                                        id={fieldName}
                                                        initialValues={
                                                            i18nHelper.getInitialValues(this.props.selectedRows[0])
                                                        }
                                                        validationSchema={Yup.object().shape(
                                                            i18nHelper.getValidationSchema({ name: Yup.string().required(<Translate id='registration.settings.division.sidePanelRegistrationDivisions.description.required' />) }),
                                                        )}
                                                        onSubmit={(values) => {
                                                            const newValues = this.props.createNewValues(values);
                                                            this.toggleState(true);

                                                            this.props.RegistrationDivisionsContext.updateRegistrationDivision(this.props.getCommonValue('division_id'), i18nHelper.getAPIValues(values))
                                                                .then(() => {
                                                                    this.props.syncRows(newValues);
                                                                    success();
                                                                    this.toggleState(false);
                                                                })
                                                                .catch((error) => {
                                                                    if(!AxiosIsCancelled(error.message)){
                                                                        console.error(error.message)
                                                                        fail({
                                                                            msg: 'misc.error',
                                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                            skipInfoTranslate: true,
                                                                        })
                                                                        this.toggleState(false);
                                                                    }
                                                                })
                                                        }}
                                                    >
                                                        {(isEditing) => {
                                                            if(!isEditing){
                                                                return (
                                                                    <div className='font-medium text-dark'>
                                                                        <DisplayI18n
                                                                            field={fieldName}
                                                                            defaultValue={this.props.selectedRows[0].name || '-'}
                                                                            i18n={this.props.selectedRows[0].i18n}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                            return (
                                                                <FormikInputText id={fieldName} name={fieldName} autoFocus />
                                                            )

                                                        }}
                                                    </FormikEditable>
                                                </div>
                                            </Col>
                                        )
                                    }}
                                </RenderI18nForm>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted">
                                            <Translate id='registration.settings.class.form.label.status' />
                                        </div>
                                        <FormikEditable
                                            id='division_status'
                                            noConfirmation
                                            initialValues={{
                                                active: this.props.getCommonValue('active'),
                                            }}
                                            onSubmit={(values) => {
                                                if(values.active !== this.props.getCommonValue("active")){
                                                    this.toggleState(true);
                                                    const newValues = this.props.createNewValues(values);

                                                    this.props.RegistrationDivisionsContext.updateRegistrationDivision(this.props.getCommonValue('division_id'), values)
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            this.toggleState(false);
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                                this.toggleState(false);
                                                            }
                                                        })
                                                }
                                            }}
                                        >
                                            {(isEditing, options) => {
                                                if(!isEditing){
                                                    return (
                                                        <div className='font-medium text-dark'><Translate id={this.props.getCommonValue('active') === '1' ? 'misc.active' : 'misc.inactive'} /></div>
                                                    )
                                                }
                                                return (
                                                    <FormikSelect
                                                        search={false}
                                                        name='active'
                                                        id='active'
                                                        autoFocus
                                                        menuIsDefaultOpen
                                                        onOptionSelected={options.stopEditing}
                                                        loadingStatus='success'
                                                        defaultData={[
                                                            { value: '1', label: 'misc.active', translateLabel: true },
                                                            { value: '0', label: 'misc.inactive', translateLabel: true },
                                                        ]}
                                                    />
                                                )

                                            }}
                                        </FormikEditable>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="mb-3">
                                        <div className="text-muted"><Translate id='registration.settings.class.form.label.order' /></div>
                                        <FormikEditable
                                            id='division_display_order'
                                            initialValues={{
                                                display_order: this.props.getCommonValue("display_order"),
                                            }}
                                            validationSchema={Yup.object().shape({
                                                display_order: Yup.number()
                                                    .integer(<Translate id='registration.settings.class.form.validation.order.integer' />)
                                                    .moreThan(0, <Translate id='registration.settings.class.form.validation.order.moreThan0' />)
                                                    .required(<Translate id='registration.settings.class.form.validation.order.required' />),
                                            })}
                                            onSubmit={(values) => {
                                                if(values.display_order !== this.props.getCommonValue("display_order")){
                                                    this.toggleState(true);
                                                    const newValues = this.props.createNewValues(values);
                                                    this.props.RegistrationDivisionsContext.updateRegistrationDivision(this.props.getCommonValue('division_id'), values)
                                                        .then(() => {
                                                            this.props.syncRows(newValues);
                                                            this.toggleState(false);
                                                            success();
                                                        })
                                                        .catch((error) => {
                                                            if(!AxiosIsCancelled(error.message)){
                                                                console.error(error.message)
                                                                fail({
                                                                    msg: 'misc.error',
                                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                    skipInfoTranslate: true,
                                                                })
                                                                this.toggleState(false);
                                                            }
                                                        })
                                                }
                                            }}
                                        >
                                            {(isEditing) => {
                                                if(!isEditing){
                                                    return <div className='font-medium text-dark'>{this.props.getCommonValue("display_order") || '-'}</div>
                                                }
                                                return (
                                                    <FormikInputNumber
                                                        id='display_order'
                                                        name='display_order'
                                                        allowNegative={false}
                                                    />
                                                )

                                            }}
                                        </FormikEditable>
                                    </div>
                                </Col>
                                { this.props.viewAs == 1 &&
                                    <Col sm="12">
                                        <div className="mb-3">
                                            <div className="text-muted">
                                                <Translate id='registration.settings.category.label.federation.division.equivalent' values={{ name: displayI18n('name', this.props.IdentityRolesContext.federation.i18n, this.props.IdentityRolesContext.federation.organisation_name, this.props.I18nContext.getGenericLocale()) }} />
                                            </div>
                                            <FormikEditable
                                                id='division_id_parent'
                                                noConfirmation
                                                initialValues={{
                                                    division_id_parent: this.props.selectedRows[0].division_parent?.division_id,
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    division_id_parent: Yup.string().required(),
                                                })}
                                                onSubmit={(values) => {
                                                    if(values.division_id_parent !== this.props.selectedRows[0].division_parent?.division_id){
                                                        this.toggleState(true);
                                                        const newParent = this.props.fedDivisions?.find((fd) => fd.division_id == values.division_id_parent);
                                                        values.short_name = this.props.selectedRows[0].short_name;
                                                        const newValues = this.props.createNewValues({ division_parent: newParent });

                                                        const apiI18n = {};

                                                        for(const lang in this.props.selectedRows[0]?.i18n){
                                                            const key = `i18n[${lang}]`;
                                                            apiI18n[`${key}[short_name]`] = this.props.selectedRows[0]?.i18n?.[lang]?.short_name;
                                                            apiI18n[`${key}[name]`] = this.props.selectedRows[0]?.i18n?.[lang]?.name;
                                                        }

                                                        const apiValues = { ...newValues, ...apiI18n, division_id_parent: values.division_id_parent };
                                                        delete apiValues.i18n;
                                                        delete apiValues.organisation;
                                                        delete apiValues.division_parent;

                                                        this.props.RegistrationDivisionsContext
                                                            .updateRegistrationParentDivision(this.props.getCommonValue('division_id'), apiValues)
                                                            .then(() => {
                                                                this.props.syncRows(newValues);
                                                                success();
                                                                this.toggleState(false);
                                                            })
                                                            .catch((error) => {
                                                                if(!AxiosIsCancelled(error.message)){
                                                                    console.error(error.message)
                                                                    fail({
                                                                        msg: 'misc.error',
                                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                        skipInfoTranslate: true,
                                                                    })
                                                                    this.toggleState(false);
                                                                }
                                                            })
                                                    }
                                                }}
                                            >
                                                {(isEditing, options) => {
                                                    if(!isEditing){
                                                        return (
                                                            <div className='font-medium text-dark'>
                                                                <DisplayI18n
                                                                    field='short_name'
                                                                    i18n={this.props.selectedRows[0].division_parent?.i18n}
                                                                    defaultValue={this.props.selectedRows[0].division_parent?.short_name}
                                                                />
                                                                {` - `}
                                                                <DisplayI18n
                                                                    field='name'
                                                                    i18n={this.props.selectedRows[0].division_parent?.i18n}
                                                                    defaultValue={this.props.selectedRows[0].division_parent?.name}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <I18nContext.Consumer>
                                                            {({ getGenericLocale }) => (
                                                                <FormikSelect
                                                                    onOptionSelected={options.stopEditing}
                                                                    menuIsDefaultOpen
                                                                    autoFocus
                                                                    name='division_id_parent'
                                                                    id='division_id_parent'
                                                                    searchKeys={[
                                                                        `i18n.${getGenericLocale()}.short_name`,
                                                                    ]}
                                                                    renderOption={(option) => <DisplayI18n field='short_name' defaultValue={option.option.label} i18n={option.option.i18n} />}
                                                                    options={this.props.fedDivisions?.map((division) => ({
                                                                        value: division.division_id,
                                                                        label: division.short_name,
                                                                        i18n: division.i18n,
                                                                    }))}
                                                                />
                                                            )}
                                                        </I18nContext.Consumer>
                                                    )

                                                }}
                                            </FormikEditable>
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </OverlayLoader>
                )}
            </I18nHelperContext.Consumer>
        )
    }
}

export default withContexts(IdentityRolesContext, RegistrationDivisionsContext, I18nContext)(SettingsRegistrationDivisionSidepanel);