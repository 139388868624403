/**
 *
 * @param {string} status Name of the status
 * @returns {string} bootstrap color
 */
export function getStatusTheme(status){
    switch (status?.toUpperCase().trim()){
        case 'OUTSTANDING':
            return 'light';
        case 'PENDING':
            return 'warning';
        case 'COMPLETE':
        case 'COMPLETED':
            return 'success';
        case 'EXPIRED':
        case 'DENIED':
            return 'danger';
        case 'IN REVIEW':
        case 'RECEIPT_RECEIVED':
        default:
            return 'info';
    }
}