import { FormikInputText, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { FieldArray, Form, Formik } from "formik";
import { useState, useContext } from "react";
import { Button, Card, Col, Collapse, FormGroup, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { array, object, string } from "yup";
import CrossFade from "../../../../../../../components/crossFade/CrossFade";
import CustomAlert from "../../../../../../../components/CustomAlert";
import Required from "../../../../../../../components/formik/Required";
import BtnDisplay from "../../../../../../../components/input/BtnDisplay";
import { RolesContext } from "../../../../../../../contexts/RolesContext";
import generatePassword from "../../../../../../../helpers/passwordGenerator";

const CreateUsersStep = ({ toggle, from, setFormData, formData, onSubmit, previous }) => {
    const emptyCard = {
        roleId: '',
        email: '',
        firstName: '',
        lastName: '',
    }

    return (
        <Formik
            initialValues={{
                users: formData?.users ? formData.users : [],
            }}
            validationSchema={object().shape({
                users: array().of(object().shape({
                    roleId: string().required(<Translate id='organization.grouping.add.createUser.role.required' />),
                    email: string().required(<Translate id='form.validation.email.required' />)
                        .test({
                            name: 'no-duplicate-email',
                            message: <Translate id='organization.profile.users.multiple.addUser.duplicate' />,
                            test: function(){
                                const emailArr = this.from[this.from.length - 1].value.users.map((user) => user.email);
                                return (!emailArr.some((email, index) => {
                                    return emailArr.indexOf(email) != index; // checks that the occurence of email has the same index as current index (confirming that it's unique)
                                }));
                            },
                        }),
                    firstName: string().required(<Translate id='form.validation.firstName.required' />),
                    lastName: string().required(<Translate id='form.validation.lastName.required' />),
                })),
            })}
            onSubmit={(values, { setStatus }) => {
                const newData = {
                    ...formData,
                    ...values,
                }
                setFormData(newData)
                onSubmit(newData, setStatus);
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <div className="mb-3 font-medium text-dark">
                            <Translate id={'organization.grouping.add.createUser.text.' + from} />
                        </div>
                        <FieldArray name='users'>
                            {(helpers) => (
                                <>
                                    {formik.values.users.map((user, index) => (
                                        <UserCard
                                            key={user.id} index={index}
                                            parentOrgId={formData?.newOrg?.organisation_id_parent}
                                            helpers={helpers}
                                        />
                                    ))}
                                    <BtnDisplay
                                        className="w-100 flex-center"
                                        title={<span className="text-primary"><i className="mdi mdi-plus" /><Translate id='misc.add' /></span>}
                                        onClick={helpers.handlePush({ ...emptyCard, id: generatePassword() })}
                                    />
                                </>
                            )}
                        </FieldArray>

                        <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                            {formik.status &&
                                <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                            }
                        </Collapse>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <Button color='primary' outline type='button' onClick={previous}><Translate id='misc.previous' /></Button>

                        <div className="ml-auto">
                            <Button color='primary' type='submit' className="mr-2"><Translate id='misc.next' /></Button>
                            <Button color='primary' outline type='button' onClick={toggle}><Translate id='misc.cancel' /></Button>
                        </div>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

const UserCard = ({ index, parentOrgId, helpers }) => {
    const rolesContext = useContext(RolesContext)
    const [ currentRole, setCurrentRole ] = useState(null)
    const prefix = 'users.' + index + '.'

    return (
        <CrossFade isVisible appear>
            <Card body className="border card-shadow mb-2">
                <button className='reset-btn position-absolute top-0 right-0 p-2' type='button' onClick={helpers.handleRemove(index)}>
                    <i className='mdi mdi-delete text-danger font-16' />
                </button>

                <Row form>
                    <Col sm='6'>
                        <FormGroup>
                            <Label for={prefix + 'roleId'} className='text-muted'><Translate id='organization.profile.users.addUser.fields.role' /> <Required /></Label>
                            <FormikSelect
                                name={prefix + 'roleId'}
                                id={prefix + 'roleId'}
                                onOptionSelected={(role) => setCurrentRole(role[0])}
                                renderOption={(option) => (
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span>{option.option.label}</span>
                                        {option.option.active == 0 &&
                                            <i className='text-warning mdi mdi-alert-outline' />
                                        }
                                    </div>
                                )}
                                loadData={(from) => {
                                    switch (from){
                                        case 'CDM':
                                            return rolesContext.getOrganizationRoles(parentOrgId)
                                                .then((_roles) => {
                                                    return (_roles.reduce((newArray, role) => {
                                                        // filter by system because the system roles will automatically be added to new org
                                                        // the roles created by the parent org won't be available
                                                        if(role.is_system == 1){
                                                            newArray.push({
                                                                value: role.role_id,
                                                                label: role.title,
                                                                active: role.active,
                                                            })
                                                        }
                                                        return newArray
                                                    }, []))
                                                })
                                        default:
                                            break;
                                    }
                                }}
                            />
                            {currentRole?.active == 0 &&
                                <span className='text-warning small'><Translate id='organization.profile.users.addUser.fields.role.inactive' /></span>
                            }
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for={prefix + 'email'} className='text-muted'><Translate id='form.fields.email' /> <Required /></Label>
                            <FormikInputText
                                name={prefix + 'email'}
                                id={prefix + 'email'}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col sm="6">
                        <FormGroup>
                            <Label for={prefix + 'firstName'} className='text-muted'><Translate id='form.fields.firstName' /> <Required /></Label>
                            <FormikInputText
                                name={prefix + 'firstName'}
                                id={prefix + 'firstName'}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for={prefix + 'lastName'} className='text-muted'><Translate id='form.fields.lastName' /> <Required /></Label>
                            <FormikInputText
                                name={prefix + 'lastName'}
                                id={prefix + 'lastName'}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
        </CrossFade>
    )
}

export default CreateUsersStep