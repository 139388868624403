import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import Translate from '@spordle/intl-elements';
import { Formik, Form } from 'formik';
import { FormikSelect } from '@spordle/formik-elements';
import { useContext } from 'react';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import OverlayLoader from '../../../../../../components/loading/OverlayLoader';
import IsGod from '../../../../../../components/permissions/IsGod';
import { AxiosIsCancelled } from '../../../../../../api/CancellableAPI';
import { fail, success } from '@spordle/toasts';

const MerchantAccontProviderSettingsForm = ({ toggleIsEditing }) => {
    const organizationContext = useContext(OrganizationContext);


    return (
        <Formik
            initialValues={{
                organisationMerchantAccountProvider: organizationContext.settings.organisation_merchant_account_provider.value,
            }}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                setSubmitting(true);
                // organisation_contact_must_be_member setting submission if changed
                organizationContext.updateSettings(organizationContext.organisation_id, {
                    organisation_merchant_account_provider: values.organisationMerchantAccountProvider.toString(),
                })
                    .then(() => {
                        // this call re-gets the settings and updates them in state on success by itslef, therefore no need to reload anything
                        success();
                        setSubmitting(false);
                        toggleIsEditing();

                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message);
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                        setSubmitting(false);
                    })

            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <Row form>
                            {/* check if current org can_manage */}
                            <Col sm="6" className='mb-3 px-1'>
                                <Card className="card-shadow mb-0 h-100 p-1">
                                    <CardHeader className="bg-white">
                                        <div className="h5 font-bold mb-1"><DisplayI18n field='name' i18n={organizationContext.settings.organisation_contact_must_be_member?.i18n} defaultValue={organizationContext.settings.organisation_contact_must_be_member?.name} /></div>
                                        <div className="small font-medium mb-0"><Translate id="organization.settings.merchantAccounts.setting.desc" /></div>
                                        <IsGod><div className="small mt-1 font-medium text-purple">setting: organisation_merchant_account_provider</div></IsGod>
                                    </CardHeader>
                                    <CardBody>
                                        <FormikSelect
                                            name='organisationMerchantAccountProvider'
                                            id='organisationMerchantAccountProvider'
                                            multi clearable
                                            search={false}
                                            loadingStatus='success'
                                            defaultData={[
                                                {
                                                    value: 'PAYSAFE',
                                                    label: 'Paysafe',
                                                },
                                                {
                                                    value: 'QUICK_ENROLLMENT',
                                                    label: 'SportsPay',
                                                },
                                            ]}
                                            dataIndex="document_type_id"
                                            renderOption={(option) => <Translate id={`memberProfile.registration.merchantAccounts.provider.${option.option.value}`} />}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>

                        <div className="text-right">
                            <Button color="primary" className="mx-2" type='submit' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                            <Button color="primary" outline onClick={toggleIsEditing} type='button' disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                        </div>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    );
}

export default MerchantAccontProviderSettingsForm;