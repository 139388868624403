import SpordleTableProvider, { SpordleTableView, useSpordleTable } from '@spordle/datatables';
// Lang
import Translate, { DateFormat } from '@spordle/intl-elements';
import moment from 'moment';
import { useContext, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Card, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledButtonDropdown, UncontrolledDropdown } from 'reactstrap';
import { AxiosCancelAll, AxiosIsCancelled } from '../../../api/CancellableAPI';
import EmptyLayout from '../../../components/table/EmptyLayout';
import CheckboxStar from '../../../components/checkboxStar/CheckboxStar';
import CanDoAction from '../../../components/permissions/CanDoAction';
import SpordlePanelTable from '../../../components/sidePanel/SpordlePanel';
import { IdentityRolesContext } from '../../../contexts/IdentityRolesContext';
import { MembersContext } from '../../../contexts/MembersContext';

// Contexts
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { DisplayI18n } from '../../../helpers/i18nHelper';
import AdvancedSearchSection from './MemberAdvanceSearch';
import SidePanelMemberSearch from './SidePanelMemberSearch';
import UserDisplay from '../../../components/userDisplay/UserDisplay';
import { exportToCsv } from '../../../helpers/export';
import { setLocalStorageItem } from '../../../helpers/browserStorage';
import { fail, fire } from "@spordle/toasts";
import { ReportsContext } from '../../../contexts/ReportsContext';
import { I18nContext } from '../../../contexts/I18nContext';
import { PeriodsContext } from '../../../contexts/contexts';
import { handleExcelExport } from '../../../helpers/reportsHelper';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';
import StatusBadge from '../../../components/badges/StatusBadge';
import MemberStatusIcons from './components/MemberStatusIcons';
import SpordlePanelControl from '../../../components/sidePanel/SpordlepanelControl';
import { Tooltip } from '@mantine/core';
import useSavedSearch from '../../../components/customHooks/useSavedSearch';
import MemberAdd from './MemberAdd/MemberAdd';
import MemberAddIneligible from './MemberAddIneligible/MemberAddIneligible';
import MemberImport from './MemberImport/MemberImport';
import EnvFeatureFlag from '../../../components/featureFlag/EnvFeatureFlag';
import TablePagination from '../../../components/table/TablePagination';
import { RolesContext } from '../../../contexts/RolesContext';
import generatePassword from '../../../helpers/passwordGenerator';
import MemberFieldLabel from '../../../components/memberRegistration/MemberFieldLabel';
import DisplayBirthdateAge from '../../../components/DisplayBirthdateAge';
import DisplayMemberType from '../../../components/display/DisplayMemberType';

const formatFilters = (filters) => {
    if(filters.startDate !== '' && filters.endDate !== ''){
        return { startDate: filters.startDate.format('YYYY-MM-DD'), endDate: filters.endDate.format('YYYY-MM-DD'), ...filters }
    }
    return filters;
}

const MemberSearch = (props) => {
    const memberContext = useContext(MembersContext);
    const organizationContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const periodsContext = useContext(PeriodsContext);

    const [ addModal, setAddModal ] = useState(false);
    const [ addIneligibleModal, setAddIneligibleModal ] = useState(false);
    const [ importModal, setImportModal ] = useState(false);
    const toggleAddModal = () => setAddModal(!addModal);
    const toggleAddIneligibleModal = () => setAddIneligibleModal(!addIneligibleModal);
    const toggleImportModal = () => setImportModal(!importModal);

    useLayoutEffect(() => AxiosCancelAll, []);// componentWillUnmount

    const buildApiSearchQuery = (filters, isExcelExport) => {
        const apiSearch = {};
        apiSearch.period_id = filters.memberSearchFilters.period_id;
        if(filters.memberSearchFilters.unique_identifier){
            apiSearch.unique_identifier = filters.memberSearchFilters.unique_identifier;
            // org_id becomes the federation id to search through all organisations
            apiSearch.all = 1;
            apiSearch.organisation_id = identityRolesContext.federation.organisation_id;
        }else{
            apiSearch.first_name = filters.memberSearchFilters.firstName;
            apiSearch.last_name = filters.memberSearchFilters.lastName;
            apiSearch.email = filters.memberSearchFilters.email;
            apiSearch.gender = filters.memberSearchFilters.gender;

            if(filters.memberSearchFilters.organization?.length !== 0){
                apiSearch.organisation_id = filters.memberSearchFilters.organization
            }

            if(filters.memberSearchFilters.noMemberType){
                apiSearch.member_type_id = [ 'NONE' ]
            }else if(filters.memberSearchFilters.memberType?.length !== 0){
                apiSearch.member_type_id = filters.memberSearchFilters.memberType
            }

            if(filters.memberSearchFilters.crcExpired){
                apiSearch.invalid_crc = 1;
            }

            if(filters.memberSearchFilters.with_unconfirmed_address){
                apiSearch.with_unconfirmed_address = 1;
            }

            if(filters.memberSearchFilters.need_address_document){
                apiSearch.need_address_document = 1;
            }

            if(filters.memberSearchFilters.with_missing_required_field){
                apiSearch.with_missing_required_field = 1;
            }

            if(filters.memberSearchFilters.member_status){
                apiSearch.member_status = filters.memberSearchFilters.member_status;
            }

            if(filters.memberSearchFilters.minAge && filters.memberSearchFilters.maxAge){
                apiSearch.age = filters.memberSearchFilters.minAge + ',' + filters.memberSearchFilters.maxAge;
            }

            if(filters.memberSearchFilters.withoutProfilePicture){
                apiSearch.without_profile_picture = 1;
            }

            if(filters.memberSearchFilters.hasOutstandingBalance){
                apiSearch.with_outstanding_balance = 1;
                apiSearch.current_organisation_id = organizationContext.organisation_id; // required when with_outstanding_balance == 1
            }

            switch (filters.memberSearchFilters.searchType){
                case 'subOrgs':// Org_id already set in the if statement above
                    apiSearch.all = 1;
                    break;
                case 'all':// org_id becomes the federation id
                    apiSearch.all = 1;
                    apiSearch.organisation_id = identityRolesContext.federation.organisation_id;
                    break;
                default:
                    break;
            }

            // Format birthdate range data differently when exporting excel and for normal search
            // excel export, string format, sent in 'birthdate' field
            if(moment.isMoment(filters.memberSearchFilters.startDate) && moment.isMoment(filters.memberSearchFilters.endDate) && isExcelExport)
                apiSearch.birthdate = `${filters.memberSearchFilters.startDate.format('YYYY-MM-DD')},${filters.memberSearchFilters.endDate.format('YYYY-MM-DD')}`;

            // search and CSV, array format and sent in 'birthdate.btw' field
            if(moment.isMoment(filters.memberSearchFilters.startDate) && moment.isMoment(filters.memberSearchFilters.endDate) && !isExcelExport)
                apiSearch['birthdate.btw'] = [ filters.memberSearchFilters.startDate.format('YYYY-MM-DD'), filters.memberSearchFilters.endDate.format('YYYY-MM-DD') ];
        }
        return apiSearch;
    }

    const { savedSearch, saveSearch } = useSavedSearch(`member-search-filters-${organizationContext.organisation_id}-${identityRolesContext.identity_role_id}-${periodsContext.selectedPeriod.period_id}`);

    return (
        <>
            {/*--------------------------------------------------------------------------------*/}
            {/* View Header                                                                  */}
            {/*--------------------------------------------------------------------------------*/}
            <ViewHeaderV2 title='members.search.memberSearch.title' />

            <div className="page-content container-fluid px-4 pt-0 mt-0 mt-sm-n4">
                <div className="clearfix">
                    <div className="d-flex justify-content-end flex-wrap">
                        {/* <IsGod>
                            <MemberMerge isOpen={mergeModal} toggle={toggleMergeModal} />
                            <Button color='primary' className='ml-auto text-nowrap' onClick={toggleMergeModal}><i className='mdi mdi-account-convert'/> Merge members</Button>
                        </IsGod> */}
                        {/* <CanDoAction className="float-right mb-3 d-flex align-items-end" action='ADD' componentCode='members' componentPermissionCode='members_profile'>
                            <MemberAdd isOpen={addModal} toggle={toggleAddModal} />
                            <Button color='primary' className='ml-2 text-nowrap' onClick={toggleAddModal}><i className='mdi mdi-plus mr-1' /><Translate id='members.search.memberSearch.header.addMember' /></Button>
                        </CanDoAction> */}
                        <CanDoAction action='ADD' componentCode='members' componentPermissionCode='members_profile'>
                            <MemberAdd isOpen={addModal} toggle={toggleAddModal} />
                            <MemberAddIneligible isOpen={addIneligibleModal} toggle={toggleAddIneligibleModal} />
                            <EnvFeatureFlag env={[ "stage" ]}>
                                <MemberImport isOpen={importModal} toggle={toggleImportModal} />
                            </EnvFeatureFlag>
                            <UncontrolledDropdown tag="span" className='float-right mb-3 d-flex align-items-end'>
                                <DropdownToggle caret color='primary'>
                                    <i className='mdi mdi-plus mr-1' />
                                    <Translate id='members.search.memberAdd.title' />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem onClick={toggleAddModal}><Translate id="members.search.memberAdd.title" /></DropdownItem>

                                    <CanDoAction
                                        action='ADD'
                                        componentCode='members'
                                        componentPermissionCode='manage_potential_ineligible_members'
                                    >
                                        <DropdownItem onClick={toggleAddIneligibleModal}><Translate id="members.search.memberAddInleligible.title" /></DropdownItem>
                                    </CanDoAction>

                                    <EnvFeatureFlag env={[ "stage" ]}>
                                        <DropdownItem onClick={toggleImportModal}><Translate id="members.search.memberImport.title" /></DropdownItem>
                                    </EnvFeatureFlag>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </CanDoAction>
                    </div>
                </div>

                {/* TABLE */}
                <SpordlePanelTable
                    allowOutsideClick
                    dataIndex='member_id'
                    sidePanel={(props) => <SidePanelMemberSearch {...props} />}
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='member-search'
                                ref={panelProps.spordleTableRef}
                                tableHover striped bordered
                                clickable
                                pagination={savedSearch?.paginationValue || 10}
                                paginationMessage='members.search.memberSearch.table.pagination.message'
                                dataIndex='member_id'
                                desktopWhen='sm'
                                rowIsHighlighted={(member) => !!member.checked}
                                defaultSorting='+last_name'
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                initFilter={savedSearch ?
                                    {
                                        memberSearchFilters: {
                                            ...savedSearch,
                                            startDate: !savedSearch.startDate ? '' : moment(savedSearch.startDate),
                                            endDate: !savedSearch.endDate ? '' : moment(savedSearch.endDate),
                                        },
                                    }
                                    :
                                    {
                                        memberSearchFilters: {
                                            memberType: [],
                                            organization: organizationContext.organisation_id,
                                            period_id: periodsContext.selectedPeriod.period_id,
                                            firstName: '',
                                            lastName: '',
                                            email: '',
                                            startDate: '',
                                            endDate: '',
                                            status: [],
                                            gender: [],
                                            hcr: '',
                                            searchType: '',
                                            minAge: '',
                                            maxAge: '',
                                        },
                                    }
                                }
                                loadingStatus='success'
                                loadData={(from, filterData, spordleTable) => {
                                    switch (from){
                                        case 'REFRESH':
                                        case 'FILTER':
                                            spordleTable.setLoading();
                                            setLocalStorageItem('membersFilters', JSON.stringify(formatFilters(filterData.filters.memberSearchFilters)))
                                            const apiSearch = buildApiSearchQuery(filterData.filters);
                                            apiSearch.limit = 500;

                                            return memberContext.getMembers(apiSearch)
                                                .then((members) => {
                                                    panelProps.syncRefresh(members);

                                                    return members;
                                                })
                                                .catch((error) => {
                                                    if(AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        // return true to let the table know that the call is cancelled and not put hte table in an error state
                                                        return true;
                                                    }
                                                });
                                        default:
                                            break;
                                    }
                                }}
                                columns={[
                                    // {
                                    //     label: <Translate id='members.search.memberSearch.table.starred'/>,
                                    //     key: 'starred',
                                    //     className: 'text-center',
                                    //     mobile: true,
                                    //     sortable: true,
                                    // },
                                    {
                                        // yes keep this
                                        label: '-',
                                        key: 'checkbox',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                    },
                                    {
                                        label: <Translate id='members.search.memberSearch.table.member' />,
                                        key: "last_name",
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.search.memberSearch.table.member_status' />,
                                        key: "member_status",
                                        mobile: true,
                                        sortable: true,
                                        sortKey: 'member_status.name',
                                    },
                                    {
                                        label: <MemberFieldLabel className="mb-0" hideDescription field="birthdate" fallbackLabel={<Translate id='form.fields.dateOfBirth' />} />,
                                        key: "birthdate",
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.search.memberSearch.table.memberTypes' />,
                                        key: "types",
                                        mobile: true,
                                    },
                                    {
                                        label: <Translate id='members.search.memberSearch.table.lastActiveSeason' />,
                                        key: "lastActiveSeason",
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.search.memberSearch.table.organization' />,
                                        key: "organization",
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.search.memberSearch.table.branch' />,
                                        key: "branch",
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.search.memberSearch.table.divClass' />,
                                        key: "divClass",
                                        mobile: true,
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='members.search.memberSearch.table.team' />,
                                        key: "team",
                                        mobile: true,
                                        sortable: true,
                                    },
                                ]}
                                onColumnClick={(e, member, _spordleTable, columnKey) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            if(columnKey === 'checkbox'){
                                                panelProps.onMultiSelectChange(member);
                                            }else{
                                                panelProps.onSingleSelectChange(member);
                                            }
                                            break;
                                    }
                                }}
                                renderRow={(key, member, spordleTable) => {
                                    switch (key){
                                        case 'checkbox':
                                            return (
                                                <label className='my-auto' htmlFor={'checkbox-' + member.member_id} onClick={(e) => e.stopPropagation()}>
                                                    <input
                                                        onChange={() => panelProps.onMultiSelectChange(member)}
                                                        id={'checkbox-' + member.member_id}
                                                        type='checkbox'
                                                        checked={!!member.checked}
                                                    />
                                                    <span className='table-checkbox' />
                                                </label>
                                            )
                                        case 'starred':
                                            return (
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <CheckboxStar
                                                        checked={!!member.starred}
                                                        id={'star-' + member.member_id}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            spordleTable.updateDataWith(member.member_id, { starred: !member.starred }).then(() => {
                                                                fire({ msg: !member.starred ? 'misc.favorited' : 'misc.unfavorited' });
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            )
                                        case 'last_name':
                                            return <MemberLastNameRender member={member} />
                                        case 'member_status':
                                            return <StatusColumn member={member} />
                                        case 'birthdate':
                                            return <DisplayBirthdateAge birthdate={member.birthdate} age={member.age} defaultValue="-" splitter={<br />} />
                                        case 'organization':
                                            return (member.organisation ?
                                                <DisplayI18n
                                                    field='name'
                                                    defaultValue={member.organisation.organisation_name}
                                                    i18n={member.organisation.i18n}
                                                />
                                                :
                                                '-'
                                            )
                                        case 'types':
                                            if((member.member_types || []).length > 0){
                                                return (
                                                    <div className="text-nowrap">
                                                        {member.member_types?.map((type) => (
                                                            <div key={type.member_type?.member_type_id}>
                                                                <DisplayMemberType
                                                                    member={member}
                                                                    memberType={type}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )
                                            }
                                            return "-";
                                        case 'lastActiveSeason':
                                            return (
                                                <div>
                                                    {/* TODO: Waiting for api */}
                                                    <div>-</div>
                                                    {/* <div>2020-2021</div>
                                                    <div>-</div> */}
                                                </div>
                                            )
                                        case 'branch':
                                            return (
                                                <div>
                                                    {member.branch?.organisation_name || '-'}
                                                </div>
                                            )
                                        case 'divClass':
                                            return (
                                                <div>
                                                    {member.division ?
                                                        <>
                                                            <DisplayI18n field='short_name' defaultValue={member.division.short_name} i18n={member.division.i18n} />
                                                            {member.class &&
                                                                <>
                                                                    <span className='mx-1'>-</span>
                                                                    <DisplayI18n field='short_name' defaultValue={member.class.short_name} i18n={member.class.i18n} />
                                                                </>
                                                            }
                                                        </>
                                                        : '-'}
                                                </div>
                                            )
                                        case 'team':
                                            return (
                                                <div>
                                                    {member.team?.name || '-'}
                                                </div>
                                            )
                                        default:
                                            break;
                                    }
                                }}
                                emptyLayout={<EmptyLayout title='misc.search.empty.title' msg='misc.search.empty.message' translateTitle translateMsg />}
                            >
                                {/* ADVANCED SEARCH */}
                                <Card body className='card-shadow'>
                                    <AdvancedSearchSection />
                                </Card>

                                {/* TABS */}
                                {/* <Nav tabs className="mb-4 font-medium font-16 text-dark">
                                    <NavItem>
                                        <NavLink
                                            active={activeTab === 'results'}
                                            href='#results'
                                        >
                                            <Translate id='members.search.memberSearch.table.tabs.results'/>
                                        </NavLink>
                                    </NavItem>
                                    {/* TODO - only display the favorite/starred members when this tab is active *
                                    <NavItem>
                                        <NavLink
                                            active={activeTab === 'starred'}
                                            href='#starred'
                                            disabled
                                        >
                                            <i className='mdi mdi-star text-warning mr-1'/><Translate id='members.search.memberSearch.table.tabs.starred'/>
                                        </NavLink>
                                    </NavItem>
                                </Nav> */}


                                {/* TABLE SEARCH & EXPORT */}
                                <Card body className="card-shadow">
                                    <div className="w-100 pb-3 d-flex align-items-center justify-content-between">
                                        {/* yes we keep this checkbox */}
                                        <div className="d-flex">
                                            <SpordlePanelControl />
                                        </div>
                                        <TablePagination
                                            className='ml-auto mr-2'
                                            paginationArray={[ 10, 25, 50 ]}
                                            onChange={(val) => {
                                                saveSearch({ ...savedSearch, paginationValue: val })
                                            }}
                                        />
                                        <ExportDropDown buildApiSearchQuery={buildApiSearchQuery} getData={memberContext.getMembers} />
                                    </div>

                                    <SpordleTableView />
                                </Card>
                            </SpordleTableProvider>
                        )
                    }}
                />
            </div>
        </>
    )
}

const StatusColumn = ({ member }) => {
    const { formatMessage } = useIntl();
    const rolesContext = useContext(RolesContext);
    const lastLog = member.member_status_logs ? member.member_status_logs?.[member.member_status_logs.length - 1] || null : null;
    const canSeeNoteAndReason = member.last_member_status_note && (member.member_status.name !== "INELIGIBLE" ||
        (member.member_status.name === "INELIGIBLE" && rolesContext.hasAccessTo('members', 'manage_ineligible_members')))

    return (
        member.member_status ?
            <Tooltip
                zIndex={3000}
                label={
                    <div style={{ maxWidth: 300 }}>
                        <div><b><Translate id='members.profile.memberProfile.header.updated_by' /></b> {member.member_status_logs?.[member.member_status_logs?.length - 1]?.created_by?.name ? member.member_status_logs?.[member.member_status_logs?.length - 1]?.created_by?.name + ' ' + member.member_status_logs?.[member.member_status_logs?.length - 1]?.created_by?.family_name : formatMessage({ id: 'members.profile.memberProfile.header.systemUpdate' })}</div>
                        <div><b><Translate id='members.profile.memberProfile.header.updated_at' /></b> <DateFormat value={member.member_status_logs?.[member.member_status_logs?.length - 1]?.created_at} format='YYYY-MM-DD' /></div>
                        {member.has_been_merged == 1 &&
                            <div><b><Translate id="members.profile.memberProfile.header.merged" /></b> <i className="mdi mdi-call-merge" />: {moment(member.merged_date) ? moment(member.merged_date).format('YYYY-MM-DD') : '-'}</div>
                        }
                        {canSeeNoteAndReason &&
                            <>
                                {(lastLog && lastLog.member_status_reason) &&
                                    <div><b><Translate id='form.fields.reason' /></b>: <DisplayI18n field='name' defaultValue={lastLog.member_status_reason.name} i18n={lastLog.member_status_reason.i18n} /></div>
                                }
                                {member.last_member_status_note && (member.member_status.name !== "INELIGIBLE" || (member.member_status.name === "INELIGIBLE" && rolesContext.hasAccessTo('members', 'manage_ineligible_members'))) &&
                                    <div className="mb-2">
                                        <b><Translate id='members.profile.blockModal.field.note' />:</b>
                                        <div>
                                            {member.last_member_status_note.split('|').length > 1 ?
                                                <ul className='pl-4'>
                                                    {
                                                        member.last_member_status_note.split('|').map((note) => {
                                                            const id = generatePassword();
                                                            if(note === 'NEW_PROFILE' || note === 'ADDRESS_CHNAGE' || note === 'ADDRESS_CHANGE' || note === 'BASIC_FIELDS_CHANGE' || note === 'HISTORICAL_SANCTION') // added ADDRESS_CHANGE in case someone fixes the spelling of the enum
                                                                return <li className='text-wrap' key={id}><Translate id={`members.profile.header.note.${note}`} /></li>
                                                            return <li className='text-wrap' key={id}>{note}</li>;
                                                        })
                                                    }
                                                </ul>
                                                :
                                                <div className='text-wrap'>
                                                    {
                                                        (member.last_member_status_note === 'NEW_PROFILE' || member.last_member_status_note === 'ADDRESS_CHNAGE' || member.last_member_status_note === 'ADDRESS_CHANGE' || member.last_member_status_note === 'BASIC_FIELDS_CHANGE' || member.last_member_status_note === 'HISTORICAL_SANCTION') ? // added ADDRESS_CHANGE in case someone fixes the spelling of the enum
                                                            <Translate id={`members.profile.header.note.${member.last_member_status_note}`} />
                                                            :
                                                            member.last_member_status_note
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                }
            >
                <StatusBadge color={member.member_status.color_code} status={member.member_status} icon={member.has_been_merged == 1 ? <i style={{ transform: 'scale(1.5)' }} className='mdi mr-1 mdi-call-merge d-inline-block' /> : null} />
            </Tooltip>
            :
            '-'
    )
}

const MemberLastNameRender = ({ member }) => {
    return (
        <UserDisplay>
            <UserDisplay.Container className='w-100'>
                <UserDisplay.Title className='d-flex align-items-start justify-content-between'>
                    {member.last_name} {member.first_name}
                    <MemberStatusIcons member={member} />
                </UserDisplay.Title>
                {member.unique_identifier ?
                    // send data without member types because the member types from search and GET specific are different formats
                    <Link className="text-nowrap" to={{ pathname: `/members/profile/${member.member_id}`, data: { ...member, member_types: [] } }}>
                        #{member.unique_identifier}<i className="mdi mdi-chevron-right" />
                    </Link>
                    :
                    <Link to={`/members/profile/${member.member_id}`} className='mr-2'>
                        <Translate id='misc.goToProfile' /><i className="mdi mdi-chevron-right ml-1" />
                    </Link>
                }
                {member.gender_identity &&
                    <UserDisplay.Subtitle>
                        {member.gender_identity}
                    </UserDisplay.Subtitle>
                }
            </UserDisplay.Container>
        </UserDisplay>
    )
}

const ExportDropDown = ({ buildApiSearchQuery, getData }) => {
    const memberContext = useContext(MembersContext);
    const organizationContext = useContext(OrganizationContext);
    const identityRolesContext = useContext(IdentityRolesContext);
    const reportsContext = useContext(ReportsContext);
    const i18nContext = useContext(I18nContext);
    const periodsContext = useContext(PeriodsContext)
    const { getDisplayedData } = useSpordleTable()

    const spordleTable = useSpordleTable();
    const intl = useIntl();
    const [ isLoading, setLoading ] = useState(false);

    const [ canExport, setCanExport ] = useState(false);
    if(getDisplayedData().length > 0){
        if(!canExport)
            setCanExport(true);
    }else if(canExport)
        setCanExport(false);

    const formatData = (members) => {
        return members.map((member) => ([
            member.unique_identifier,
            member.first_name,
            member.last_name,
            moment(member.birthdate).format('YYYY-MM-DD'),
            member.email,
            intl.formatMessage({ id: `members.search.memberSearch.table.gender.${member.gender.toUpperCase()}` }),
            member.member_status.i18n?.[i18nContext.getGenericLocale()]?.name,
            member.confirmed_at,
        ]));
    }

    const reportType = 'member';

    // for excel export
    const apiSearch = buildApiSearchQuery(spordleTable.getFilters(), true);

    if(apiSearch.gender == [] || apiSearch.gender == ''){
        delete apiSearch.gender;
    }else{
        apiSearch.gender = apiSearch.gender ? apiSearch.gender.toString() : '';
    }

    if(apiSearch.member_type_id){
        apiSearch.member_type_id = apiSearch.member_type_id.toString();
    }

    return (
        <UncontrolledButtonDropdown>
            <DropdownToggle color='primary' outline caret>
                <i className='mdi mdi-download mr-1' /><Translate id='misc.export' />
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem
                    disabled={isLoading}
                    onClick={() => {
                        setLoading(true);
                        memberContext.getMembers(buildApiSearchQuery(spordleTable.getFilters()))
                            .then((members) => {
                                exportToCsv(`SpordleID-MemberSearch ${moment().format('YYYY-MM-DD H:mm')}.csv`, [
                                    [// Headers
                                        intl.formatMessage({ id: 'members.search.memberAdvanceSearch.filters.memberNumber' }),
                                        intl.formatMessage({ id: 'form.fields.firstName' }),
                                        intl.formatMessage({ id: 'form.fields.lastName' }),
                                        intl.formatMessage({ id: 'form.fields.dateOfBirth' }),
                                        intl.formatMessage({ id: 'form.fields.email' }),
                                        intl.formatMessage({ id: 'members.search.memberAdvanceSearch.filters.gender' }),
                                        intl.formatMessage({ id: 'members.search.memberAdvanceSearch.csv.member_status' }),
                                        intl.formatMessage({ id: 'members.search.memberAdvanceSearch.csv.confirmed_date' }),
                                    ],
                                    ...formatData(members),
                                ]);
                                setLoading(false);
                            })
                            .catch((error) => {
                                if(!AxiosIsCancelled(error.message)){
                                    console.error(error.message)
                                    fail({
                                        msg: 'misc.error',
                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                        skipInfoTranslate: true,
                                    })
                                    setLoading(false);
                                }
                            });
                    }}
                >
                    <i className='fas fa-file-excel mr-1' />CSV{isLoading && <Spinner size='sm' color='primary' type='grow' className='float-right' />}
                </DropdownItem>
                <DropdownItem
                    disabled={isLoading || !canExport}
                    onClick={() => handleExcelExport(
                        setLoading,
                        reportType,
                        { ...apiSearch, period_id: periodsContext.selectedPeriod.period_id },
                        spordleTable.getData().length,
                        reportsContext,
                        identityRolesContext.identity_role_id,
                        identityRolesContext.identity.identity_id,
                        i18nContext.getGenericLocale(),
                        organizationContext.organisation_id,
                    )}
                >
                    <i className='fas fa-file-excel mr-1' />
                    <Translate id='misc.excel' />
                </DropdownItem>
                <DropdownItem disabled><i className='fas fa-file-pdf mr-1' /><Translate id='misc.pdf' /></DropdownItem>
                <DropdownItem disabled><i className='fas fa-print mr-1' /><Translate id='misc.print' /></DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    )
}

export default MemberSearch;