import Translate from '@spordle/intl-elements';
import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    Card,
    CardBody,
    Button
} from "reactstrap";
import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext } from '../../../../helpers/i18nHelper';

// contexts
import { WaiversContext } from '../../../../contexts/WaiversContext';
import EmptyLayout from '../../../../components/table/EmptyLayout';
import CanDoAction from '../../../../components/permissions/CanDoAction';
import { I18nContext } from '../../../../contexts/I18nContext';
import MandatoryLabel from './MandatoryLabel';
import CriticalLabel from './CriticalLabel';

const WaiversList = () => {
    const history = useHistory();
    const waiversContext = useContext(WaiversContext);
    const i18nContext = useContext(I18nContext);

    return (
        <I18nHelperContextProvider fields={[ 'name', 'description' ]} labels={{ name: 'title' }}>
            <I18nHelperContext.Consumer>
                {(i18nHelper) => (
                    <Card className="card-shadow">
                        <CardBody>
                            <SpordleTableProvider
                                id='CatalogWaiversListTable'
                                tableHover bordered striped
                                clickable
                                desktopWhen
                                pagination={10}
                                dataIndex='waiver_id'
                                emptyLayout={<EmptyLayout />}
                                searchKeys={[
                                    `i18n.${i18nContext.getGenericLocale()}.name`,
                                    `i18n.${i18nContext.getGenericLocale()}.description`,
                                    'description',
                                    'name',
                                ]}
                                loadData={(from, filterData, spordleTable) => {
                                    switch (from){
                                        case 'REFRESH':
                                            spordleTable.setLoading(true);
                                        case 'CDM':
                                            return waiversContext.getWaivers();
                                        default:
                                            break;
                                    }
                                }}
                                columns={[
                                    ...i18nHelper.getTableColumns('name'),
                                    {
                                        label: <MandatoryLabel />,
                                        key: 'mandatory',
                                        dataClassName: 'text-center',
                                        sortable: true,
                                    },
                                    {
                                        label: <CriticalLabel />,
                                        key: 'critical',
                                        dataClassName: 'text-center',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='misc.status' />,
                                        key: 'active',
                                        dataClassName: 'text-center',
                                        sortable: true,
                                    },
                                ]}
                                renderRow={(columnKey, data) => {
                                    if(columnKey.includes('i18n')){
                                        return (
                                            <DisplayI18n
                                                field={columnKey}
                                                defaultValue={data.name}
                                                i18n={data.i18n}
                                            />
                                        )
                                    }
                                    switch (columnKey){
                                        case 'mandatory':
                                            return data.mandatory === '1' ? <i className="text-primary mdi mdi-check" /> : <i className="text-danger mdi mdi-close" />;
                                        case 'critical':
                                            return data.critical == 1 ? <i className="text-primary mdi mdi-check" /> : <i className="text-danger mdi mdi-close" />;
                                        case 'active':
                                            return data.active === '1' ? <i className="text-primary mdi mdi-check" /> : <i className="text-danger mdi mdi-close" />;
                                        default:
                                            break;
                                    }
                                }}
                                onColumnClick={(e, data) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            history.push({ pathname: 'waivers/edit', search: '?waiverId=' + data.waiver_id });
                                            break;
                                    }
                                }}
                                rowIsHighlighted={(data) => data.checked}
                            >
                                <div className='mb-2'>
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <SpordleTableProvider.SearchInput />
                                        <div className='d-flex ml-auto text-right'>
                                            <SpordleTableProvider.Refresh />
                                            <CanDoAction action='ADD' componentCode='catalog' componentPermissionCode='organization_waivers'>
                                                <Button tag={Link} className='ml-2' color='primary' to='waivers/add'><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                            </CanDoAction>
                                        </div>
                                    </div>
                                </div>
                                <SpordleTableView />
                            </SpordleTableProvider>
                        </CardBody>
                    </Card>
                )}
            </I18nHelperContext.Consumer>
        </I18nHelperContextProvider>
    );
}

export default WaiversList;