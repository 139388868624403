export default{
    "clinics.search.advancedSearch.select.placeholder.search" : "Search...",
    "clinics.search.advancedSearch.select.placeholder.select" : "Select...",

    "clinics.search.advancedSearch.organization.label"                  : "Organization",
    "clinics.search.advancedSearch.startDate.label"                     : "From",
    "clinics.search.advancedSearch.endDate.label"                       : "To",
    "clinics.search.advancedSearch.onlySelectedOrgs.label"              : "Show only clinics hosted by selected organizations",
    "clinics.search.advancedSearch.category.label"                      : "Categories",
    "clinics.search.advancedSearch.category.disabled"                   : "Currently disabled",
    "clinics.search.advancedSearch.category.textCountLabel"             : "{count} selected categorie(s)",
    "clinics.search.advancedSearch.category.noOptions"                  : "No category",
    "clinics.search.advancedSearch.qualification.label"                 : "Qualifications",
    "clinics.search.advancedSearch.qualification.textCountLabel"        : "{count} selected qualification(s)",
    "clinics.search.advancedSearch.qualification.noOptions"             : "No qualification",
    "clinics.search.advancedSearch.status.label"                        : "Status",
    "clinics.search.advancedSearch.status.option.DRAFT"                 : "Draft",
    "clinics.search.advancedSearch.status.option.PRIVATE"               : "Private",
    "clinics.search.advancedSearch.status.option.OPEN_PUBLIC_SHOW_LIST" : "Open to the public",
    "clinics.search.advancedSearch.status.option.OPEN_PUBLIC_SIGNUP"    : "Open registration",
    "clinics.search.advancedSearch.status.option.UNDER_CREATION"        : "In creation",
    "clinics.search.advancedSearch.status.textCountLabel"               : "{count} selected status(es)",
    "clinics.search.advancedSearch.clinicFormat.label"                  : "Clinic Format",
    "clinics.search.advancedSearch.clinicFormat.label.ONLINE"           : "Online - Recorded/On-Demand",
    "clinics.search.advancedSearch.clinicFormat.label.ONLINE_LIVE"      : "Online - Live",
    "clinics.search.advancedSearch.clinicFormat.label.ONSITE"           : "In Person",
    "clinics.search.advancedSearch.moreFilters"                         : "More filters",
    "clinics.search.advancedSearch.lessFilters"                         : "Less filters",
}