import { useContext } from 'react';
import { DropdownItem } from 'reactstrap';
import SidePanel from '../../SidePanel';
import CommentAction from './CommentAction';
import ApproveAction from './ApproveAction';
import DeclineAction from './DeclineAction';
import MemberHeader from './MemberHeader';
import RemoveShared from './RemoveShared';
import TasksHeader from './TasksHeader';
import CanDoAction from '../../../permissions/CanDoAction';

import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import QuickViewActionButton from '../../../quickView/QuickViewActionButton';

const TransferSidePanelHeader = ({ transfer, canDoWorkflow, onTransfer, from }) => {
    const organizationContext = useContext(OrganizationContext);

    const requestStateOpen = transfer.transfer_request_state !== 'CLOSED';
    const canRemoveShare = from === 'memberProfile' && !requestStateOpen && [ 'shared', 'nrp' ].includes((transfer.transfer_duration?.name || "").toLowerCase()) && !transfer.expired_share;
    const isAllowedOrganization = transfer.organisations_allowed && transfer.organisations_allowed.indexOf(organizationContext.organisation_id) !== -1
    // TODO: HARDCODED ([ 'NSO', 'Member branches', 'Member partner' ].includes(organizationContext.organisation_category.name))
    const canOverride = [ 'NSO', 'Member branches', 'Member partner' ].includes(organizationContext.organisation_category.name)

    return (
        <SidePanel.Header className="sticky-top bg-white z-index-1" noBorder>
            <div className='d-flex mb-2'>
                <SidePanel.ToggleButton />
                {(requestStateOpen || canRemoveShare) && // Actions are available only when the transfer is OPEN
                    <SidePanel.ActionsMenu action={[ 'EDIT', 'DELETE' ]} componentCode='members' componentPermissionCode='members_request_transfer'>
                        <CommentAction transfer={transfer} />
                        {requestStateOpen &&
                        <>
                            {/* Normal Workflow actions (Review, Approve, Refuse) */}
                            {((canDoWorkflow('APPROVE') || !!isAllowedOrganization) || (canDoWorkflow('DECLINE') || !!isAllowedOrganization)) &&
                                <>
                                    {(canDoWorkflow('APPROVE') || !!isAllowedOrganization) && <ApproveAction transfer={transfer} onTransfer={onTransfer} />}
                                    {(canDoWorkflow('DECLINE') || !!isAllowedOrganization) && <DeclineAction transfer={transfer} onTransfer={onTransfer} />}
                                    <DropdownItem divider />
                                </>
                            }

                            {/* OVERRIDE Workflow actions (Review, Approve, Refuse) - can only be seen if transfer type is BRANCH or ASSOCIATION */}
                            {(canOverride && (transfer.transfer_type === 'BRANCH' || transfer.transfer_type === 'ASSOCIATION')) &&
                                <CanDoAction action='ADD' componentCode='members' componentPermissionCode='member_transfer_override'>
                                    <ApproveAction transfer={transfer} onTransfer={onTransfer} override />
                                    <DeclineAction transfer={transfer} onTransfer={onTransfer} override />
                                    <DropdownItem divider />
                                </CanDoAction>
                            }
                        </>
                        }

                        {canRemoveShare &&
                            <RemoveShared transfer={transfer} onTransfer={onTransfer} />
                        }
                        <QuickViewActionButton member={transfer.member} />
                    </SidePanel.ActionsMenu>
                }
            </div>

            {from === 'tasks' ?
                <TasksHeader transfer={transfer} />
                : from === 'memberProfile' ?
                    <MemberHeader transfer={transfer} />
                    :
                    null
            }
        </SidePanel.Header>
    );
}

export default TransferSidePanelHeader;