import React from "react";
import {
    Button,
    FormGroup,
    Label,
    Row,
    Col,
    Table,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
    Card
} from "reactstrap";

// Spordle packages
import { FormikInputNumber, FormikInputText, FormikSelect, FormikTextArea } from "@spordle/formik-elements";

// Packages
import * as Yup from 'yup';
import { Form, Formik, Field, ErrorMessage } from "formik";

// Components
import OrganizationSearch from "../../../../components/organization/OrganizationSearch";

// Contexts
import withContexts from "../../../../helpers/withContexts";
import { I18nContext } from "../../../../contexts/I18nContext";
import { OrganizationContext } from "../../../../contexts/OrganizationContext";
import { QualificationsContext } from '../../../../contexts/QualificationsContext';
import { QualificationCategoriesContext } from "../../../../contexts/QualificationCategoriesContext";
import Translate from "@spordle/intl-elements";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import AnalyticsModal from "../../../../analytics/AnalyticsModal";
import Required from "../../../../components/formik/Required";

class ClinicSettingsAddQualifications extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            numLevels: 0,
            numPrerequisites: 0,

            addLevelModalOpen: false,
            editLevel: false,

            addPrerequisiteModalOpen: false,
            organization: false,
        };

        this.levels = []
        this.prerequisites = []
    }

    // Levels
    toggleAddLevelModal = () => {
        this.setState((prevState) => ({ addLevelModalOpen: !prevState.addLevelModalOpen }))
    }

    addLevel = (level) => {
        this.levels.push(level);
        this.setState(() => ({ numLevels: this.levels.length }))
    }

    removeLevel = (level) => {
        this.levels = this.levels.filter((l) => l.englishName !== level.englishName && l.frenchName !== level.frenchName);
        this.setState(() => ({ numLevels: this.levels.length }))
    }

    toggleEditLevel = (level) => {
        this.setState((prevState) => {
            return ({ editLevel: prevState.editLevel === false ? level : false })
        }, () => {
            this.toggleAddLevelModal();
        })
    }

    editLevel = (old, level) => {
        const index = this.levels.findIndex((l) => l.englishName === old.englishName && l.frenchName === old.frenchName)
        this.levels[index] = { englishName: level.englishName, frenchName: level.frenchName };
        this.setState({ numLevels: this.levels.length, editLevel: false })
    }

    // Prerequisites
    toggleAddPrerequisiteModal = () => {
        this.setState((prevState) => ({ addPrerequisiteModalOpen: !prevState.addPrerequisiteModalOpen }))
    }

    addPrerequisite = (prerequisite) => {
        this.prerequisites.push(prerequisite);
        this.setState(() => ({ numPrerequisites: this.prerequisites.length }))
    }

    removePrerequisite = (prerequisite) => {
        this.prerequisites = this.prerequisites.filter((p) => `${p.category}-${p.qualification}-${p.level}` !== `${prerequisite.category}-${p.qualification}-${p.level}`)
        this.setState(() => ({ numPrerequisites: this.prerequisites.length }))
    }

    render(){
        return (
            <Formik
                initialValues={{
                    category: '',
                    englishName: '',
                    englishDescription: '',
                    frenchName: '',
                    frenchDescription: '',
                    expiration: 'noExpiration',
                    amount: 6,
                    periods: 'months',
                    validationStart: 'lastDay',
                    organization: this.state.organization,
                    price: '',
                }}
                validationSchema={Yup.object().shape({
                    category: Yup.string().required('Select a Category'),
                    englishName: Yup.string().required('Enter an English Name'),
                    englishDescription: Yup.string().required('Enter an English Description'),
                    frenchName: Yup.string().required('Enter a French Name'),
                    frenchDescription: Yup.string().required('Enter a french Description'),
                    expiration: Yup.string().required('Select an Expiration'),
                    amount: Yup.string()
                        .when('expiration', {
                            is: 'manual',
                            then: Yup.string().required('Select an Number'),
                        }),
                    periods: Yup.string()
                        .when('expiration', {
                            is: 'manual',
                            then: Yup.string().required('Select either Months or Years'),
                        }),
                    validationStart: Yup.string()
                        .when('expiration', {
                            is: 'manual',
                            then: Yup.string().required('Select a starting point for the Validity Period'),
                        }),
                    organization: Yup.string().required('Select an Organization'),
                    price: Yup.string().required('Enter a Price'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false)
                    this.props.history.push('/settings/clinics/qualifications');
                }}
            >
                {(formik) => {
                    return (
                        <Form id='clinicSettingsAddQualification'>
                            <>
                                <Card body className="card-shadow">
                                    {/* GENERAL INFO */}
                                    <div className='h4 mb-3 font-bold pb-1 border-bottom card-title'>General Info</div>

                                    <FormGroup>
                                        <Label for="category" className="text-muted">Category <Required /></Label>
                                        <FormikSelect
                                            name="category"
                                            id="category"
                                            search={false}
                                            defaultData={[
                                                {
                                                    value: 'COACHES',
                                                    label: 'Coaches',
                                                },
                                                {
                                                    value: 'REFEREES',
                                                    label: 'Referees',
                                                },
                                                {
                                                    value: 'SCOREKEEPERS',
                                                    label: 'ScoreKeepers',
                                                },
                                                {
                                                    value: 'GENERAL',
                                                    label: 'General',
                                                },
                                            ]}
                                            loadingStatus='success'
                                        />
                                    </FormGroup>

                                    <Row>
                                        <Col className='order-1' md='12' lg="6">
                                            <FormGroup>
                                                <Label for="englishName" className="text-muted">English Name <Required /></Label>
                                                <FormikInputText id="englishName" name='englishName' />
                                            </FormGroup>
                                        </Col>
                                        <Col className='order-3 order-lg-2' md='12' lg="6">
                                            <FormGroup>
                                                <Label for="frenchName" className="text-muted">French Name <Required /></Label>
                                                <FormikInputText id="frenchName" name='frenchName' />
                                            </FormGroup>
                                        </Col>
                                        <Col className='order-2 order-ld-3' md='12' lg="6">
                                            <FormGroup>
                                                <Label for="englishDescription" className="text-muted">English Description <Required /></Label>
                                                <FormikTextArea rows={3} id="englishDescription" name='englishDescription' />
                                            </FormGroup>
                                        </Col>
                                        <Col className='order-4' md='12' lg="6">
                                            <FormGroup>
                                                <Label for="frenchDescription" className="text-muted">French Description <Required /></Label>
                                                <FormikTextArea rows={3} id="frenchDescription" name='frenchDescription' />
                                            </FormGroup>
                                        </Col>
                                        <Col className='order-5' md='6'>
                                            <FormGroup>
                                                <Label for='price' className='text-muted'>Price <Required /></Label>
                                                <FormikInputNumber
                                                    name='price'
                                                    id='price'
                                                    allowNegative={false}
                                                    prefix='$'
                                                    decimalScale={2}
                                                    thousandSeparator
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card>

                                {/* QUALIFICATION VALIDITY */}
                                <Card body className="card-shadow">
                                    <div className='h4 mb-3 font-bold pb-1 border-bottom card-title'>Qualification Validity</div>

                                    <FormGroup>
                                        <FormikSelect
                                            name="expiration"
                                            id="expiration"
                                            loadingStatus='success'
                                            search={false}
                                            defaultData={[
                                                {
                                                    value: 'noExpiration',
                                                    label: 'No Expiration',
                                                },
                                                {
                                                    value: 'validitySeason',
                                                    label: 'Valid only for Season',
                                                },
                                                {
                                                    value: 'manual',
                                                    label: 'Set Validity Period manually',
                                                },
                                            ]}
                                        />
                                    </FormGroup>

                                    <Collapse isOpen={formik.values.expiration === 'manual'}>
                                        <Row className='mb-1'>
                                            <Col sm='12' md='8' lg='6' className='d-flex align-items-center'>
                                                <div className='font-medium mr-1 text-nowrap'>Qualification is valid for</div>
                                                <FormGroup className='mb-0 mr-1 w-50'>
                                                    <FormikInputText type='number' min='1' max='12' label='' translateLabel={false} name='amount' id='amount' />
                                                </FormGroup>
                                                <FormGroup className='mb-0 w-100'>
                                                    <FormikSelect
                                                        name="periods"
                                                        id="periods"
                                                        search={false}
                                                        loadingStatus='success'
                                                        defaultData={[
                                                            {
                                                                value: 'months',
                                                                label: 'Months',
                                                            },
                                                            {
                                                                value: 'years',
                                                                label: 'Years',
                                                            },
                                                        ]}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md='6' className='d-flex align-items-center'>
                                                <div className='font-medium mr-1 text-nowrap'>Validity starts</div>
                                                <FormGroup className='mb-0 w-100'>
                                                    <FormikSelect
                                                        name="validationStart"
                                                        id="validationStart"
                                                        search={false}
                                                        loadingStatus='success'
                                                        defaultData={[
                                                            {
                                                                value: 'firstDay',
                                                                label: "on clinic's first day",
                                                            },
                                                            {
                                                                value: 'lastDay',
                                                                label: "on clinic's last day",
                                                            },
                                                            {
                                                                value: 'passing',
                                                                label: 'after passing',
                                                            },
                                                            {
                                                                value: 'season',
                                                                label: 'at the start of the season',
                                                            },
                                                        ]}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Collapse>
                                </Card>

                                {/* LEVELS */}
                                <Card body className="card-shadow">
                                    <div className={`d-flex align-items-end pb-1 ${this.state.numLevels === 0 ? 'border-bottom mb-3' : ''}`}>
                                        <div className='h4 mb-0 font-bold card-title'>Levels</div>
                                        <div className='ml-auto'>
                                            <Button color="primary" outline onClick={this.toggleAddLevelModal}><i className="mdi mdi-plus" /> Add</Button>
                                        </div>
                                    </div>

                                    {this.state.numLevels === 0 ?
                                        <div className='font-medium'>None</div>
                                        :
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th scope='col'>English Name</th>
                                                    <th scope='col'>French Name</th>
                                                    <th scope='col' className='text-center'>Edit</th>
                                                    <th scope='col' className='text-center'>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.levels.map((level, index) => {
                                                    return (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <tr key={index}>
                                                            <td>{level.englishName}</td>
                                                            <td>{level.frenchName}</td>
                                                            <td className='text-center'><button type="button" className='reset-btn' onClick={() => { this.toggleEditLevel(level) }}><i className='mdi mdi-lead-pencil text-primary' /></button></td>
                                                            <td className='text-center'><button type="button" className='reset-btn' onClick={() => { this.removeLevel(level) }}><i className='fas fa-trash text-danger' /></button></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    }
                                    <AnalyticsModal isOpen={this.state.addLevelModalOpen} toggle={this.toggleAddLevelModal} analyticsName='ClinicSettingsAddQualifications-Level'>
                                        <Formik
                                            initialValues={{
                                                englishName: this.state.editLevel === false ? '' : this.state.editLevel.englishName,
                                                frenchName: this.state.editLevel === false ? '' : this.state.editLevel.frenchName,
                                                uniqueNamesField: '',
                                            }}
                                            validationSchema={Yup.object().shape({
                                                englishName: Yup.string().required('Enter an English Name'),
                                                frenchName: Yup.string().required('Enter a French Name'),
                                                uniqueNamesField: Yup.string()
                                                    .when(([ 'englishName', 'frenchName' ]), {
                                                        is: (englishName, frenchName) => {
                                                            const en = this.levels.filter((l) => this.state.editLevel === false ? true : l.englishName !== this.state.editLevel.englishName).filter((l) => l.englishName === englishName);
                                                            const fr = this.levels.filter((l) => this.state.editLevel === false ? true : l.frenchName !== this.state.editLevel.frenchName).filter((l) => l.frenchName === frenchName);

                                                            return (en.length > 0 || fr.length > 0)
                                                        },
                                                        then: Yup.string().required('At least one of the names already exists as a Level'),
                                                    }),
                                            })}
                                            onSubmit={(values, { setSubmitting }) => {
                                                if(this.state.editLevel === false){
                                                    this.addLevel({ englishName: values.englishName, frenchName: values.frenchName });
                                                }else{
                                                    this.editLevel(this.state.editLevel, { englishName: values.englishName, frenchName: values.frenchName })
                                                }
                                                setSubmitting(false)
                                                this.toggleAddLevelModal();
                                            }}
                                        >
                                            {(formikAddLevel) => (
                                                <Form>
                                                    <ModalHeader>Add Level</ModalHeader>
                                                    <ModalBody>
                                                        <FormGroup>
                                                            <Label for='englishName'>English Name</Label>
                                                            <FormikInputText name='englishName' id='englishName' label='' translateLabel={false} />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for='frenchName'>French Name</Label>
                                                            <FormikInputText name='frenchName' id='frenchName' label='' translateLabel={false} />
                                                        </FormGroup>

                                                        {/* to display an error in case one of the names already exists in another level */}
                                                        <Field
                                                            name='uniqueNamesField'
                                                            component={({ field, form: { touched, errors } }) => (
                                                                <div>
                                                                    {touched[field.name] && errors[field.name] ? <div className='error' style={{ fontSize: '80%' }}>{errors[field.name]}</div> : null}
                                                                </div>
                                                            )}
                                                        />
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color='primary' type='button' outline onClick={this.state.editLevel === false ? this.toggleAddLevelModal : () => { this.toggleEditLevel(this.state.editLevel) }} disabled={formikAddLevel.isSubmitting}>Cancel</Button>
                                                        <Button color='primary' type='submit' disabled={formikAddLevel.isSubmitting}>{this.state.editLevel === false ? 'Add' : 'Save'}</Button>
                                                    </ModalFooter>
                                                </Form>
                                            )}
                                        </Formik>
                                    </AnalyticsModal>
                                </Card>

                                {/* Prerequisites */}
                                <Card body className="card-shadow">
                                    <div className={`d-flex align-items-end pb-1 ${this.state.numPrerequisites === 0 ? 'border-bottom mb-3' : ''}`}>
                                        <div className='h4 mb-0 font-bold card-title'>Prerequisites</div>
                                        <div className='ml-auto'>
                                            <Button color="primary" outline onClick={this.toggleAddPrerequisiteModal}><i className="mdi mdi-plus" /> Add</Button>
                                        </div>
                                    </div>

                                    {this.state.numPrerequisites === 0 ?
                                        <div className='font-medium'>None</div>
                                        :
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th scope='col'>Category Name</th>
                                                    <th scope='col'>Qualification Name</th>
                                                    <th scope='col'>Level</th>
                                                    <th scope='col' className='text-center'>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.prerequisites.map((prerequisite, index) => {
                                                    return (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <tr key={index}>
                                                            <td>{prerequisite.category}</td>
                                                            <td>{prerequisite.qualification}</td>
                                                            <td>{prerequisite.level}</td>
                                                            <td className='text-center'><button type="button" className='reset-btn' onClick={() => { this.removePrerequisite(prerequisite) }}><i className='fas fa-trash text-danger' /></button></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    }
                                    <AnalyticsModal isOpen={this.state.addPrerequisiteModalOpen} toggle={this.toggleAddPrerequisiteModal} analyticsName='ClinicSettingsAddQualifications-Prerequisite'>
                                        <Formik
                                            initialValues={{
                                                category: '',
                                                qualification: '',
                                                level: '',
                                                uniquePrerequisiteField: '',
                                            }}
                                            validationSchema={Yup.object().shape({
                                                category: Yup.string().required('Select a Category'),
                                                qualification: Yup.string().required('Select a Qualification'),
                                                level: Yup.string().required('Select a Level'),
                                                uniquePrerequisiteField: Yup.string()
                                                    .when(([ 'category', 'qualification', 'level' ]), {
                                                        is: (category, qualification, level) => {
                                                            const name = `${category}-${qualification}-${level}`;
                                                            return this.prerequisites.filter((p) => `${p.category}-${p.qualification}-${p.level}` === name).length > 0
                                                        },
                                                        then: Yup.string().required('This prerequisite already exists'),
                                                    }),
                                            })}
                                            onSubmit={(values, { setSubmitting }) => {
                                                this.addPrerequisite({ category: values.category, qualification: values.qualification, level: values.level });
                                                setSubmitting(false)
                                                this.toggleAddPrerequisiteModal();
                                            }}
                                        >
                                            {(formikAddLevel) => (
                                                <Form>
                                                    <ModalHeader>Add Prerequisite</ModalHeader>
                                                    <ModalBody>
                                                        <FormGroup>
                                                            <Label for='category' className='text-muted'>Category</Label>
                                                            <FormikSelect
                                                                name="category"
                                                                id="category"
                                                                search={false}
                                                                defaultData={[
                                                                    {
                                                                        value: 'COACHES',
                                                                        label: 'Coaches',
                                                                    },
                                                                    {
                                                                        value: 'REFEREES',
                                                                        label: 'Referees',
                                                                    },
                                                                    {
                                                                        value: 'SCOREKEEPERS',
                                                                        label: 'ScoreKeepers',
                                                                    },
                                                                    {
                                                                        value: 'GENERAL',
                                                                        label: 'General',
                                                                    },
                                                                ]}
                                                                loadingStatus='success'
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for='qualification' className='text-muted'>Qualification</Label>
                                                            <FormikSelect
                                                                name="qualification"
                                                                id="qualification"
                                                                loadingStatus='success'
                                                                defaultData={[
                                                                    {
                                                                        value: 'Adv1',
                                                                        label: '*Coach - Advanced',
                                                                    },
                                                                    {
                                                                        value: 'Adv2',
                                                                        label: '*Coach - Advanced Level One',
                                                                    },
                                                                    {
                                                                        value: 'Adv3',
                                                                        label: '*Coach - Advanced Level Two',
                                                                    },
                                                                    {
                                                                        value: 'Coa1',
                                                                        label: '*Coach - Coach Level',
                                                                    },
                                                                    {
                                                                        value: 'Coa2',
                                                                        label: '*Coach - Coach Stream',
                                                                    },
                                                                    {
                                                                        value: 'Coa3',
                                                                        label: '*Coach - Coach Stream and Speak Out',
                                                                    },
                                                                    {
                                                                        value: 'Coa4',
                                                                        label: '*Coach - Coach Stream Refresh',
                                                                    },
                                                                    {
                                                                        value: 'Cro',
                                                                        label: '*Coach - Cross-over',
                                                                    },
                                                                    {
                                                                        value: 'DEV',
                                                                        label: '*Coach - DEVELOPMENT 1',
                                                                    },
                                                                    {
                                                                        value: 'DEV1',
                                                                        label: '*Coach - DEVELOPMENT 2',
                                                                    },
                                                                    {
                                                                        value: 'Dev2',
                                                                        label: '*Coach - Developmental 1',
                                                                    },
                                                                    {
                                                                        value: 'Dev3',
                                                                        label: '*Coach - Developmental 1 and Speak Out',
                                                                    },
                                                                    {
                                                                        value: 'Dev4',
                                                                        label: '*Coach - Developmental 2',
                                                                    },
                                                                ]}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for='level' className='text-muted'>Level</Label>
                                                            <FormikSelect
                                                                name="level"
                                                                id="level"
                                                                search={false}
                                                                loadingStatus='success'
                                                                defaultData={[
                                                                    {
                                                                        value: '1',
                                                                        label: '1',
                                                                    },
                                                                    {
                                                                        value: '2',
                                                                        label: '2',
                                                                    },
                                                                    {
                                                                        value: '3',
                                                                        label: '3',
                                                                    },
                                                                    {
                                                                        value: 'beginner',
                                                                        label: 'Beginner',
                                                                    },
                                                                    {
                                                                        value: 'intermediate',
                                                                        label: 'Intermediate',
                                                                    },
                                                                    {
                                                                        value: 'advanced',
                                                                        label: 'Advanced',
                                                                    },
                                                                ]}
                                                            />
                                                        </FormGroup>

                                                        {/* to display an error in case one of the names already exists in another level */}
                                                        <ErrorMessage name='uniquePrerequisiteField'>{(msg) => <div className='error small'>{msg}</div>}</ErrorMessage>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color='primary' type='button' outline onClick={this.toggleAddPrerequisiteModal} disabled={formikAddLevel.isSubmitting}><Translate id='misc.cancel' /></Button>
                                                        <Button color='primary' type='submit' disabled={formikAddLevel.isSubmitting}><Translate id='misc.add' /></Button>
                                                    </ModalFooter>
                                                </Form>
                                            )}
                                        </Formik>
                                    </AnalyticsModal>
                                </Card>


                                {/* ORGANIZATION */}
                                <Card body className="card-shadow">
                                    <div className='h4 mb-3 font-bold pb-1 border-bottom card-title'>Specific Organization</div>
                                    <OrganizationSearch isMulti withFormik withTree name='organization' id='organization' className='w-50' menuPlacement='top' />
                                </Card>

                                <div className='d-flex justify-content-end'>
                                    <Button tag={Link} type='button' outline color='primary' className='mr-2' to='qualifications'><Translate id='misc.cancel' /></Button>
                                    <Button type='submit' color='primary' disabled={formik.isSubmitting}><Translate id='misc.save' /></Button>
                                </div>
                            </>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}

export default withRouter(withContexts(I18nContext, OrganizationContext, QualificationsContext, QualificationCategoriesContext)(ClinicSettingsAddQualifications));