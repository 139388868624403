import Translate from "@spordle/intl-elements";
import { I18nHelperContext } from "../../../../../../helpers/i18nHelper";
import { useContext, Fragment } from "react";
import { Card } from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import { Skeleton } from "@mantine/core";

const DemographicMsgView = ({ message, isLoading }) => {
    const { state } = useContext(I18nHelperContext);

    return (
        <>
            {state.languages.map((lang, i) => (
                <Fragment key={lang}>
                    {state.languages.length > 1 && // No need to label the lang if there's only one lang
                        <div className={stringBuilder("text-uppercase small mb-2 text-muted", { "mt-3": i > 0 })}>
                            <Translate id={`misc.${lang}.long`} />
                        </div>
                    }
                    <Card body className="card-shadow mb-0">
                        {isLoading ?
                            <>
                                <Skeleton height="1em" width="100%" />
                                <Skeleton height="1em" width="100%" />
                                <Skeleton height="1em" width="100%" />
                                <Skeleton height="1em" width="100%" />
                                <Skeleton height="1em" width="75%" />
                            </>
                            :
                            <div dangerouslySetInnerHTML={{ __html: message?.i18n?.[lang]?.body || "-" }} />
                        }
                    </Card>
                </Fragment>
            ))}
        </>
    );
}

export default DemographicMsgView;