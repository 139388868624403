export default{
    "insurance.title"                 : "Assurances",
    "insurance.export.cheques.pdf"    : "Chèques",
    "insurance.export.worksheet.pdf"  : "Feuille de travail",
    "insurance.export.printCheques"   : "Imprimer le(s) chèque(s)",
    "insurance.export.printWorksheet" : "Imprimer feuille de travail",

    "insurance.label.case"                   : "Dossier",
    "insurance.label.branch"                 : "Branche",
    "insurance.label.season"                 : "Saison",
    "insurance.label.service"                : "Service",
    "insurance.label.documents"              : "Documents",
    "insurance.label.insuranceClaim"         : "Réclamation d'assurance",
    "insurance.label.updatedBy"              : "Mis à jour par",
    "insurance.label.updatedOn"              : "Mis à jour le",
    "insurance.label.association"            : "Association",
    "insurance.label.claim"                  : "Réclamation",
    "insurance.label.claimStatus"            : "Statut de la réclamation",
    "insurance.label.previousClaimStatus"    : "Statut précédent",
    "insurance.label.printed"                : "Imprimée:",
    "insurance.label.printedBy"              : "par",
    "insurance.label.claimStatus.helper"     : "Le montant de la réserve est initialisé à 0$ lorsque le statut est classé ou fermé.",
    "insurance.label.accident"               : "Accident",
    "insurance.label.injury"                 : "Blessure",
    "insurance.label.insuranceInfo"          : "Info d'assurance",
    "insurance.label.member"                 : "Membre",
    "insurance.label.type"                   : "Type de réclamation",
    "insurance.label.reserve"                : "Reserve",
    "insurance.label.participantType"        : "Type de participant",
    "insurance.label.injuryNature"           : "Nature de la blessure",
    "insurance.label.bodyPart"               : "Partie du corps",
    "insurance.label.onSiteCare"             : "Soins sur place",
    "insurance.label.equipment"              : "Équipement porté",
    "insurance.label.equipment.count"        : "{count, plural, =0 {Aucun équipement sélectionné} one {# pièce d'équipment sélectionnée} other {# pièces d'équipment sélectionnées}}",
    "insurance.label.sanctionned"            : "Événement sanctionné",
    "insurance.label.previousInjury"         : "A déjà eu cette blessure",
    "insurance.label.previousInjury.longAgo" : "Veuillez spécifier il y a eu combien de temps",
    "insurance.label.absence"                : "Absence",
    "insurance.label.condition"              : "Temps de l'événement",
    "insurance.label.description"            : "Commentaires / Notes / Description",
    "insurance.label.provincialCoverage"     : "Couverture provinciale",
    "insurance.label.otherInsurance"         : "Autre assurance",
    "insurance.label.payTo"                  : "Payer à",
    "insurance.label.employed"               : "A un emploi",
    "insurance.label.employer"               : "Employeur",
    "insurance.label.submitted"              : "Soumis",
    "insurance.label.office"                 : "Bureau",
    "insurance.label.category"               : "Catégorie",
    "insurance.label.guardian"               : "Gardien",
    "insurance.label.arena"                  : "Aréna",
    "insurance.label.eventType"              : "Type d'événement",
    "insurance.label.environment"            : "Endroit où l'événement s'est produit",
    "insurance.label.penalty"                : "Pénalité",
    "insurance.label.cause"                  : "Cause de l'accident",
    "insurance.label.teamInfo"               : "Info Équipe",
    "insurance.label.team"                   : "Équipe",
    "insurance.label.teamName"               : "Nom équipe",
    "insurance.label.teamDivision"           : "Division de l'équipe",
    "insurance.label.teamClass"              : "Classe de l'équipe",
    "insurance.label.teamCategory"           : "Catégorie de l'équipe",
    "insurance.label.teamCategory.helper"    : "Veuillez spécifier au moins la division",
    "insurance.label.teamOfficePos"          : "Position du bureau",
    "insurance.label.teamNonListed"          : "Équipe non listée",
    "insurance.label.correctAge"             : "Âge correct pour la ligue",
    "insurance.label.healthInsuranceNb"      : "Numéro d'assurance santé",
    "insurance.label.healthProvince"         : "Province",
    "insurance.label.accidentDate"           : "Date de l'accident",
    "insurance.label.deliveredDate"          : "Date de livraison",
    "insurance.label.teamOfficial"           : "Officiel d'équipe",
    "insurance.label.teamOfficial.name"      : "Nom de l'officiel",
    "insurance.label.teamOfficial.position"  : "Position l'officiel",
    "insurance.label.official"               : "Officiel",
    "insurance.label.official.empty"         : "Aucun officiel lié",

    "insurance.label.injuryTypes.INFO"  : "Info seulement",
    "insurance.label.injuryTypes.CLAIM" : "Réclamation {name}",

    "insurance.label.participantType.PLAYER"        : "Joueur",
    "insurance.label.participantType.TEAM_OFFICIAL" : "Officiel d'équipe",
    "insurance.label.participantType.GAME_OFFICIAL" : "Officiel de match",
    "insurance.label.participantType.SPECTATOR"     : "Spectateur",

    "insurance.label.employedOptions.NO"                 : "Non",
    "insurance.label.employedOptions.NO_INFO"            : "Aucune information",
    "insurance.label.employedOptions.EMPLOYED"           : "Emploi à temps plein",
    "insurance.label.employedOptions.EMPLOYED_PART_TIME" : "Emploi à temps partiel",
    "insurance.label.employedOptions.UNEMPLOYED"         : "Sans emploi",
    "insurance.label.employedOptions.STUDENT"            : "Étudiant temps plein",

    "insurance.label.eventTypes.NO_INFO"        : "Sans information",
    "insurance.label.eventTypes.EXHIBITION"     : "Exhibition",
    "insurance.label.eventTypes.REGULAR_SEASON" : "Saison régulière",
    "insurance.label.eventTypes.PLAYOFF"        : "Séries éliminatoires",
    "insurance.label.eventTypes.TOURNAMENT"     : "Tournoi",
    "insurance.label.eventTypes.PRACTICE"       : "Pratique",
    "insurance.label.eventTypes.TRYOUT"         : "Essai",
    "insurance.label.eventTypes.OTHER"          : "Autre",

    "insurance.validation.noTeam.teamName"              : "Veuillez spécifier le nom de l'équipe",
    "insurance.validation.noTeam.teamDivision"          : "Veuillez spécifier la division de l'équipe",
    "insurance.validation.noTeam.teamClass"             : "Veuillez spécifier la classe de l'équipe",
    "insurance.validation.other.pleaseSpecify"          : "Veuillez spécifier",
    "insurance.validation.incidentDate.required"        : "La date de l'accident est requise",
    "insurance.validation.incidentDate.cannotBeFuture"  : "La date de l'incident ne peut pas être dans le futur.",
    "insurance.validation.deliveredDate.cannotBeFuture" : "La date de livraison ne peut pas être avant la date de l'incident et ne peut pas être dans le futur.",
    "insurance.validation.injuryType.required"          : "Veuillez spécifier le type de blessure",
    "insurance.validation.injuryCause.required"         : "Veuillez spécifier la cause de la blessure",
    "insurance.validation.injuryEnv.required"           : "Veuillez spécifier où l'événement s'est produit",
    "insurance.validation.eventType.required"           : "Veuillez spécifier le type d'événement",
    "insurance.validation.payTo.required"               : "Veuillez spécifier le payer à",
    "insurance.validation.bodyPart.required"            : "Veuillez spécifier la partie du corps affectée",
    "insurance.validation.absence.required"             : "Veuillez spécifier l'absence",
    "insurance.validation.nature.required"              : "Veuillez spécifier la nature de la blessure",
    "insurance.validation.documentType.required"        : "Veuillez spécifier au moins un {name}",
    "insurance.validation.condition.required"           : "Veuillez spécifier la condition de la blessure",
    "insurance.validation.equipment.required"           : "Veuillez spécifier l'équipement porté lorsque la blessure s'est produite",
    "insurance.validation.onSiteCare.required"          : "Veuillez spécifier les soins reçus",
    "insurance.validation.participantType.required"     : "Veuillez spécifier le type de participant",
    "insurance.validation.office.required"              : "L'office est requis",
    "insurance.validation.status.required"              : "Le status de la réclamation est requis",

    "insurance.sidePanel.deleteModal.msg" : "Vous êtes sur le point de supprimer cette réclamation",
    "insurance.sidePanel.delete.success"  : "Réclamation supprimée !",

    "insurance.sidePanel.cheque.editModal.title"                      : "Modifier chèque",
    "insurance.sidePanel.cheque.addModal.title"                       : "Créer chèque",
    "insurance.sidePanel.cheque.addModal.label.presetNotes"           : "Modèles de memo",
    "insurance.sidePanel.cheque.addModal.label.issued"                : "Chèque pour",
    "insurance.sidePanel.cheque.addModal.label.issued.CLAIMANT"       : "Plaintif",
    "insurance.sidePanel.cheque.addModal.label.issued.OTHER"          : "Autre",
    "insurance.sidePanel.cheque.addModal.label.issued.GUARDIAN"       : "Gardien",
    "insurance.sidePanel.cheque.addModal.label.notlisted"             : "Absent de la liste",
    "insurance.sidePanel.cheque.addModal.label.contactType"           : "Type de contact",
    "insurance.sidePanel.cheque.addModal.label.payee"                 : "Bénéficiaire",
    "insurance.sidePanel.cheque.addModal.label.payees"                : "Bénéficiaires",
    "insurance.sidePanel.cheque.addModal.label.createPayee"           : "Créer un bénéficiaire",
    "insurance.sidePanel.cheque.addModal.validation.issued"           : "Veuillez spécifier pour qui est ce chèque",
    "insurance.sidePanel.cheque.addModal.validation.date"             : "La date du chèque ne peut pas être avant la date de l'incident",
    "insurance.sidePanel.cheque.addModal.validation.amount"           : "Veuillez spécifier un montant",
    "insurance.sidePanel.cheque.addModal.validation.reserve.whenOpen" : "Un montant est requis lorsque le status de la réclamation est ouvert",
    "insurance.sidePanel.cheque.addModal.validation.number"           : "Veuillez spécifier un numéro de chèque",
    "insurance.sidePanel.cheque.addModal.validation.payee"            : "Veuillez spécifier un bénéficiaire",
    "insurance.sidePanel.cheque.addModal.validation.guardian"         : "Veuillez spécifier un gardien",
    "insurance.sidePanel.cheque.addModal.validation.service"          : "Veuillez spécifier un service",
    "insurance.sidePanel.cheque.addModal.validation.firstName"        : "Veuillez spécifier le prénom du gardien",
    "insurance.sidePanel.cheque.addModal.validation.lastName"         : "Veuillez spécifier le nom de famille du gardien",
    "insurance.sidePanel.cheque.addModal.validation.address"          : "Veuillez sélectionner une adresse",
    "insurance.sidePanel.cheque.addModal.success.msg"                 : "Cheque created",
    "insurance.sidePanel.cheque.addModal.helper.service"              : "Sélectionnez un service",
    "insurance.sidePanel.cheque.addModal.helper.guardianNotListed"    : "Spécifiez le prénom et le nom du gardien.",
    "insurance.sidePanel.cheque.download.msg"                         : "Génération...",
    "insurance.sidePanel.cheque.download.info"                        : "Le pdf est en train d'être généré",
    "insurance.sidePanel.cheque.addModal.success.info"                : "Le chèque a été créé avec succès !",
    "insurance.sidePanel.cheque.editModal.success.info"               : "Le chèque a été mis à jour avec succès !",
    "insurance.sidePanel.cheque.addBtn"                               : "Créer chèque",
    "insurance.sidePanel.cheque.printAll"                             : "Tout imprimer",
    "insurance.sidePanel.cheque.label"                                : "Chèques",
    "insurance.sidePanel.cheque.addBtn.subtitle"                      : "Cliquez ici pour ajouter un chèque",
    "insurance.sidePanel.cheque.for"                                  : "Pour",
    "insurance.sidePanel.cheque.toVoid"                               : "Annuler",
    "insurance.sidePanel.cheque.void"                                 : "Annulé",
    "insurance.sidePanel.cheque.voidedAt"                             : "Annulé le",
    "insurance.sidePanel.cheque.print.modal.title"                    : "Paramètres d'impression",
    "insurance.sidePanel.cheque.print.lastPrinted"                    : "Imprimé le",
    "insurance.sidePanel.cheque.print.btn.unprinted"                  : "Non-imprimés",
    "insurance.sidePanel.cheque.print.btn.unprinted.helper"           : "Chèques non-imprimés",
    "insurance.sidePanel.cheque.print.unprinted.text"                 : "Tous les chèques qui n'ont pas été exportés auparavant seront exportés.",
    "insurance.sidePanel.cheque.print.unprinted.start"                : "Premier numéro de chèque",
    "insurance.validation.startCheque.required"                       : "Veuillez spécifier le numéro de chèque du début de la série",
    "insurance.sidePanel.cheque.print.btn.chequeNb"                   : "Numéro",
    "insurance.sidePanel.cheque.print.btn.chequeNb.helper"            : "Par numéro d'insurance",
    "insurance.sidePanel.cheque.print.btn.chequeDate"                 : "Par date",
    "insurance.sidePanel.cheque.print.btn.chequeDate.helper"          : "Par date de chèque",
    "insurance.sidePanel.cheque.print.from"                           : "À partir de",
    "insurance.sidePanel.cheque.print.to"                             : "Jusqu'à",
    "insurance.sidePanel.cheque.print.noCheques.msg"                  : "Aucun chèque",
    "insurance.sidePanel.cheque.print.noCheques.info"                 : "Aucun chèque n'a été trouvé avec ces paramètres",
    "insurance.sidePanel.cheque.print.mode.required"                  : "Veuillez sélectionner un paramètre d'impression",
    "insurance.sidePanel.cheque.print.number.to.required"             : "Veuillez préciser un numéro de chèque de départ",
    "insurance.sidePanel.cheque.print.number.from.required"           : "Veuillez préciser un numéro de chèque de fin",
    "insurance.sidePanel.cheque.print.date.from.required"             : "Veuillez préciser une date de chèque de départ",
    "insurance.sidePanel.cheque.print.date.to.required"               : "Veuillez préciser une date de chèque de fin",
    "insurance.sidePanel.cheque.print.date.to.afterFrom"              : "La date de chèque de fin doit être après la date de départ",
    "insurance.sidePanel.cheque.print.chequeNb.identity"              : "Créés par moi",
    "insurance.sidePanel.cheque.print.chequeNb.identity.helper.title" : "Réclamations créées par moi",
    "insurance.sidePanel.cheque.print.chequeNb.identity.helper"       : "Imprime toutes les réclamations créées par moi qui n'ont pas été imprimées auparavant.",
    "insurance.sidePanel.cheque.print.chequeNb.range"                 : "Par numéro",
    "insurance.sidePanel.cheque.print.chequeNb.printedOnly"           : "Déjà imprimés",

    "insurance.addModal.noTeams"                       : "Aucune équipe disponible pour ce membre",
    "insurance.addModal.title"                         : "Réclamation d'assurance",
    "insurance.addModal.stepTeam.member.search"        : "Veuillez spécifier qui fait cette réclamation d'assurance",
    "insurance.addModal.stepTeam.addOfficial.search"   : "Chercher l'officiel",
    "insurance.addModal.stepTeam.addOfficial.title"    : "Ajouter un officiel",
    "insurance.addModal.stepTeam.addOfficial.change"   : "Changer",
    "insurance.addModal.stepTeam.addOfficial.subtitle" : "Il n'y a pas d'officiel lié à cette réclamation. Cliquez ici pour en lier un.",

    "insurance.search.branch.label"                          : "Branche",
    "insurance.search.accidentDate.start.label"              : "Date de début d'accident ",
    "insurance.search.accidentDate.approved.label"           : "Approuvé par branche",
    "insurance.search.accidentDate.start.validation.before"  : "La date de début doit être avant la date de fin",
    "insurance.search.accidentDate.end.label"                : "Date de fin d'accident ",
    "insurance.search.accidentDate.end.validation.after"     : "La date de fin doit être après la date de début",
    "insurance.search.createdDate.start.label"               : "Date de création de début",
    "insurance.search.createdDate.end.label"                 : "Date de création de fin",
    "insurance.search.uniqueIdentifier.firstLastNameIgnored" : "Le nom et le prénom seront ignorés",

    "insurance.modal.approved.tooltip"     : "Cocher cette boîte si cette réclamation d'assurance a été approuvé par la branche.",
    "insurance.sidepanel.approved.tooltip" : "Si la réclamation a été approuvé par la branche sélectionner 'Oui'.",
    "insurance.modal.approved.title"       : "Approbation de la branche",
}