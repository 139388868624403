import React, { useState } from "react";
import {
    Card, CardBody
} from 'reactstrap';
import { MembersContext } from '../../../../../../contexts/MembersContext';
import withContexts from '../../../../../../helpers/withContexts';
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import { I18nContext } from '../../../../../../contexts/I18nContext';
import { UtilsContext } from '../../../../../../contexts/UtilsContext';
import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import EmptyLayout from "../../../../../../components/table/EmptyLayout";
import UserImg from "../../../../../../components/UserImg";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { AuthContext } from "../../../../../../contexts/contexts";
import CrossFade from "../../../../../../components/crossFade/CrossFade";
import Translate from "@spordle/intl-elements";
import generatePassword from "../../../../../../helpers/passwordGenerator";
import InlineCopy from "../../../../../../components/inlineCopy/InlineCopy";

class MemberLinkedAccounts extends React.Component{
    render(){
        return (
            <>
                <Card className="card-shadow">
                    <CardBody>
                        {/* <CardSectionTitle title='members.profile.overview.linkedAccounts.title' /> */}
                        <div className="border-bottom pb-1 mb-3 d-flex align-items-end h-100">
                            <div className="card-title mb-0">
                                <span className='font-bold h4'><Translate id='members.profile.overview.linkedAccounts.title' /></span>
                            </div>
                        </div>
                        <SpordleTableProvider
                            key={this.props.MembersContext.currentMember.linked_identities?.length}
                            id='linkedAccountsMemberProfile'
                            tableHover clickable
                            viewMode='GRID'
                            dataIndex='identity_id'
                            gridConfig={{
                                col: {
                                    sm: 12,
                                    md: 6,
                                    className: 'mb-3',
                                },
                                gridRender: (user) => {
                                    return <LinkedUserAccount user={user} key={user.identity_id} componentKey={user.identity_id} />
                                },
                            }}
                            desktopWhen='md'
                            emptyLayout={<EmptyLayout hideMsg hideArrow translateTitle title='members.profile.overview.linkedAccounts.noLinked' />}
                            loadData={(from, _) => {
                                switch (from){
                                    case 'REFRESH':
                                        return this.props.MembersContext.getMember(this.props.MembersContext.currentMember.member_id)
                                            .then(() => this.props.MembersContext.currentMember.linked_identities);
                                    case 'CDM':
                                        return Promise.resolve(this.props.MembersContext.currentMember.linked_identities)
                                    default:
                                        break;
                                }
                            }}
                        >
                            <SpordleTableView />
                        </SpordleTableProvider>
                    </CardBody>
                </Card>
            </>
        )
    }
}

const LinkedUserAccount = ({ user }) => {
    const [ showRoles, setShowRoles ] = useState(false);

    return (
        <UserDisplay card className="w-100 mb-2">
            <UserDisplay.Container>
                <UserImg
                    abbr={user.name.substring(0, 1) + user.family_name.substring(0, 1)}
                    src={null}
                    width={40}
                    height={40}
                    alt={user.name}
                />
            </UserDisplay.Container>
            <UserDisplay.Container className='w-100'>
                <div className="d-flex">
                    <div className="w-100">
                        <UserDisplay.Title className="mr-2">{user.name} {user.family_name}</UserDisplay.Title>
                        <InlineCopy tag='div' toCopy={user.email} className="font-medium d-flex align-items-center">
                            {user.email
                                ?
                                <>{user.email}</>
                                : '-'
                            }
                        </InlineCopy>
                    </div>
                    <div className="d-flex flex-center">
                        {user.roles?.length > 0 &&
                            <div className="d-flex clickable w-100 text-nowrap text-primary" onClick={() => setShowRoles(!showRoles)}>
                                <Translate id={`${showRoles ? 'members.profile.overview.linkedAccounts.hideRoles' : 'members.profile.overview.linkedAccounts.showRoles'}`} />
                                <i className={`ml-1 mdi ${showRoles ? 'mdi-close' : 'mdi-plus'}`} />
                            </div>
                        }
                    </div>
                </div>
                <CrossFade isVisible={showRoles}>
                    {user.roles?.length > 0 &&
                        user.roles.map((role) => (
                            <UserDisplay.Subtitle key={role.role.role_id + generatePassword()}>
                                {role.organisation && <DisplayI18n field='name' defaultValue={role.organisation.organisation_name} i18n={role.organisation.i18n} />}
                                <span className="mx-2">-</span>
                                {role.role.title}
                            </UserDisplay.Subtitle>
                        ))
                    }
                </CrossFade>
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default withContexts(MembersContext, UtilsContext, I18nContext, AuthContext)(MemberLinkedAccounts);