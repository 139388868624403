import { FormikCheckedButton, FormikGroup } from '@spordle/formik-elements';
import Translate from '@spordle/intl-elements';
import { useFormikContext } from 'formik';
import UserDisplay from '../../../../../../components/userDisplay/UserDisplay';
import { DisplayI18n } from '../../../../../../helpers/i18nHelper';
import { stringBuilder } from '@spordle/helpers';
import { Button, Collapse, Label, ModalBody, ModalFooter } from 'reactstrap';
import CustomAlert from '../../../../../../components/CustomAlert';

const CancelItemSelection = ({ invoiceItems, toggle, setActiveStep, steps, ...props }) => {
    const formik = useFormikContext();

    const registrationsItems = invoiceItems.filter((item) => item.registration_fee)
    const otherItems = invoiceItems.filter((item) => item.other_fee)

    const handleClick = async() => {
        const errors = await formik.validateForm();
        if(!errors.items){
            setActiveStep(steps.cancelItemReason)
        }else{
            formik.setFieldTouched('items', true)
        }
    }

    return (
        <>
            <ModalBody>
                {registrationsItems.length > 0 &&
                    <>
                        <Label className='font-bold'><Translate id='memberProfile.registration.cancelRegistration.registrations.label' values={{ items: registrationsItems.length }} /></Label>
                        <FormikGroup name='items' rowProps={{ className: 'mx-0 d-block' }} manualError>
                            {registrationsItems.map((item) => (
                                <ItemCard key={item.invoice_item_id} item={item} />
                            ))}
                        </FormikGroup>
                    </>
                }

                {otherItems.length > 0 &&
                    <>
                        <Label className='font-bold'><Translate id='memberProfile.registration.cancelRegistration.others.label' values={{ items: otherItems.length }} /></Label>
                        <FormikGroup name='items' rowProps={{ className: 'mx-0 d-block' }}>
                            {otherItems.map((item) => (
                                <ItemCard key={item.invoice_item_id} item={item} />
                            ))}
                        </FormikGroup>
                    </>
                }

                <Collapse isOpen={!!formik.status} mountOnEnter unmountOnExit>
                    <CustomAlert className='mt-3' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                </Collapse>
            </ModalBody>

            <ModalFooter>
                <Button color='primary' type='button' onClick={handleClick} disabled={formik.isSubmitting}>
                    <Translate id='memberProfile.registration.cancelRegistration.submit' />
                </Button>
                <Button color='primary' type='button' onClick={toggle} outline className="ml-2" disabled={formik.isSubmitting}>
                    <Translate id='misc.cancel' />
                </Button>
            </ModalFooter>
        </>
    )
}

const ItemCard = ({ item }) => {
    const formik = useFormikContext();
    const fee = item.registration_fee?.fee || item.other_fee.fee || null;
    const disabled = !!item.cancelled_at;

    return (
        <UserDisplay
            tag="label" htmlFor={item.invoice_item_id} card
            hover={!disabled}
            className={stringBuilder('mb-2 p-4 w-100 font-medium text-dark', { 'border-primary': formik.values.itemId === item.invoice_item_id, 'text-muted bg-light-inverse': disabled })}
        >
            <UserDisplay.Container>
                <FormikCheckedButton
                    id={item.invoice_item_id}
                    name='items'
                    disabled={!!disabled}
                    value={item.invoice_item_id}
                />
            </UserDisplay.Container>
            <UserDisplay.Container>
                <UserDisplay.Title>
                    <DisplayI18n field="name" defaultValue={fee.name} i18n={fee.i18n} />
                </UserDisplay.Title>
                <UserDisplay.Subtitle>
                    {item.member.first_name} {item.member.last_name}
                </UserDisplay.Subtitle>
                {disabled &&
                    <UserDisplay.Subtitle>
                        <Translate id='memberProfile.registration.cancelRegistration.items.cancelled' />
                    </UserDisplay.Subtitle>
                }
            </UserDisplay.Container>
        </UserDisplay>
    )
}

export default CancelItemSelection