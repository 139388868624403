import { useEffect, useState } from "react";
import { getSessionStorageItem, setSessionStorageItem } from "../../helpers/browserStorage";
import { stringBuilder } from "@spordle/helpers";
import styles from './OverlayWarning.module.scss';
import Translate from "@spordle/intl-elements";
import { Button, CustomInput } from "reactstrap";

/**
 * Show a warning over an element and apply and blur visual effect to force the user to opt-in
 * @param {bool} showWarning Wether or not to show the warning over the children. Do not apply the local storage value here, it is done inside this component.
 * @param {string} storageKey The storage key to use
 * @param {React.ReactNode} text The text to show between the warning title and the opt-in checkbox
 * @param className Additional classnames to apply to the overlay
 */
const OverlayWarning = ({ children, showWarning = true, storageKey, text, className }) => {
    const [ hasContinued, setHasContinued ] = useState(true);
    const [ hasUnderstood, setHasUnderstood ] = useState(false);

    // declare another showWarning to merge the existing one with the local storage value
    // makes the integration easier
    const _showWarning = showWarning && !hasContinued;

    const confirmMessage = () => {
        setHasContinued(true);
        setSessionStorageItem(storageKey, 1);
    }

    useEffect(() => {
        const val = getSessionStorageItem(storageKey);

        setHasContinued(val == 1);
    }, []);

    return (
        _showWarning ?
            <div className={stringBuilder(styles.overlay, className)}>
                <div className={stringBuilder(styles.msg, "text-center position-absolute rounded-lg bg-white shadow border p-3 overflow-hidden")}>
                    <div className="h3 font-medium mdi mdi-alert-outline with-icon"><Translate id="misc.warning" /></div>
                    {text}
                    <div className="d-flex align-items-center justify-content-center mb-3">
                        <CustomInput
                            type="checkbox"
                            onChange={() => setHasUnderstood((prev) => !prev)}
                            isChecked={hasUnderstood}
                            name="optin"
                            id="affiliation-fees-warning-optin"
                        />
                        <label htmlFor="affiliation-fees-warning-optin" className="mb-0">
                            <Translate id="catalog.affiliationFees.iUnderstand" />
                        </label>
                    </div>
                    <Button
                        disabled={!hasUnderstood}
                        className={stringBuilder(styles.btn, "shadow-sm")}
                        type="button"
                        color="primary"
                        onClick={confirmMessage}
                    >
                        <Translate id="misc.continue" />
                    </Button>
                </div>
                <div className={stringBuilder(styles.blur)}>
                    {children}
                </div>
            </div>
            :
            children
    )
}

export default OverlayWarning;