import React from "react";
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { stringBuilder } from "@spordle/helpers";
import FormikEditable from '../../../../components/formik/FormikEditable';
import { FormikInputText } from '@spordle/formik-elements';
import { DisplayI18n, I18nHelperContext, RenderI18nForm } from '../../../../helpers/i18nHelper';

// Context
import { I18nContext } from '../../../../contexts/I18nContext';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { RolesContext } from '../../../../contexts/RolesContext';
import withContexts from '../../../../helpers/withContexts';

// Language
import Translate from "@spordle/intl-elements";
import SidePanel from "../../../../components/sidePanel/SidePanel";
import OverlayLoader from "../../../../components/loading/OverlayLoader";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import { object, string } from "yup";
import { deletePlatformLexicon, getPlatformLexicon, updatePlatformLexicon } from "../../../../api/client/lexicon";

class SidePanelPlatformLexicon extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            activeTab: '1',
        }

        this.canEdit = this.props.RolesContext.canDoAction("EDIT", "organization", "platform_lexicon");
    }

    refreshLexicon = () => {
        getPlatformLexicon()
            .then((lexicon) => {
                this.props.I18nContext.setLexicon(lexicon);
            })
            .catch(console.error);
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <I18nHelperContext.Consumer>
                    {(i18nHelper) => (
                        <>
                            <SidePanel.Header noBorder>
                                <div className='d-flex mb-2 align-items-center'>
                                    <SidePanel.ToggleButton />
                                    <SidePanel.ActionsMenu action='DELETE' componentCode='organization' componentPermissionCode='platform_lexicon'>
                                        <SidePanel.MenuDelete
                                            translateModalMsg
                                            modalMsg='organization.settings.sidePanelOrganizationPeriods.actions.remove.topText'
                                            modalContent={
                                                <div className='mt-3'>
                                                    <div className='font-medium'>
                                                        <DisplayI18n
                                                            field="term"
                                                            i18n={this.props.selectedRows[0].i18n}
                                                            defaultValue={this.props.selectedRows[0].term}
                                                        />
                                                    </div>
                                                    <div className='small'>{this.props.selectedRows[0].code}</div>
                                                </div>
                                            }
                                            onConfirm={() => {
                                                return deletePlatformLexicon(this.props.selectedRows[0].platform_lexicon_id)
                                                    .then(() => {
                                                        this.props.tableRef.deleteRow(this.props.selectedRows[0].platform_lexicon_id).then(this.props.toggle)
                                                        this.refreshLexicon()
                                                    })
                                                    .catch((error) => {
                                                        if(!AxiosIsCancelled(error.message)){
                                                            console.error(error.message)
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }
                                                    })
                                            }}
                                        />
                                    </SidePanel.ActionsMenu>
                                </div>
                                <SidePanel.Title>
                                    <DisplayI18n
                                        field='name'
                                        defaultValue={this.props.selectedRows[0].term}
                                        i18n={this.props.selectedRows[0].i18n}
                                    />
                                    <div className='small'>{this.props.selectedRows[0].code}</div>
                                </SidePanel.Title>
                            </SidePanel.Header>

                            <Nav tabs>
                                <NavItem className="flex-grow-1 text-center w-100">
                                    <NavLink
                                        className={stringBuilder({ active: this.state.activeTab === '1' })}
                                    >
                                        <Translate id='organization.settings.sidePanelOrganizationPeriods.tabs.details' />
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <div className='p-3 border-bottom'>
                                        <Row>
                                            <Col md='12'>
                                                <div className='mb-3'>
                                                    <div className='text-muted'>Code</div>
                                                    <FormikEditable
                                                        id={"code"}
                                                        disabled={!this.canEdit}
                                                        initialValues={{
                                                            code: this.props.selectedRows[0].code,
                                                        }}
                                                        // validationSchema={this.i18n.validationSchema[lang]}
                                                        onSubmit={(values) => {
                                                            this.setState({ isLoading: true });
                                                            const newValues = this.props.createNewValues(values);

                                                            updatePlatformLexicon(this.props.selectedRows[0].platform_lexicon_id, values)
                                                                .then(() => {
                                                                    this.props.syncRows(newValues);
                                                                    this.setState({ isLoading: false });
                                                                })
                                                                .catch((error) => {
                                                                    if(!AxiosIsCancelled(error.message)){
                                                                        fail({
                                                                            msg: 'misc.error',
                                                                            info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                            skipInfoTranslate: true,
                                                                        })
                                                                        console.error(error.message)
                                                                        this.setState({ isLoading: false });
                                                                    }
                                                                })
                                                        }}
                                                    >
                                                        {(isEditing) => {
                                                            if(!isEditing){
                                                                return (
                                                                    this.props.selectedRows[0].code || '-'
                                                                )
                                                            }
                                                            return (
                                                                <FormikInputText id='code' name='code' trim />
                                                            )

                                                        }}
                                                    </FormikEditable>
                                                </div>
                                            </Col>
                                        </Row>

                                        <RenderI18nForm field='term'>
                                            {({ fieldName, fieldLabel }) => {
                                                return (
                                                    <div className='mb-3' key={fieldName}>
                                                        <div className='text-muted'>{fieldLabel}</div>
                                                        <FormikEditable
                                                            id={fieldName}
                                                            disabled={!this.canEdit}
                                                            initialValues={i18nHelper.getInitialValues(this.props.selectedRows[0])}
                                                            validationSchema={object().shape(
                                                                i18nHelper.getValidationSchema({ term: string().required() }),
                                                            )}
                                                            onSubmit={(values) => {
                                                                this.setState(() => ({ isLoading: true }));
                                                                updatePlatformLexicon(this.props.selectedRows[0].platform_lexicon_id, i18nHelper.getAPIValues(values))
                                                                    .then(() => {
                                                                        this.props.syncRows(this.props.createNewValues(values));
                                                                        this.refreshLexicon()
                                                                        this.setState({ isLoading: false });
                                                                    })
                                                                    .catch((error) => {
                                                                        if(!AxiosIsCancelled(error.message)){
                                                                            fail({
                                                                                msg: 'misc.error',
                                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                                skipInfoTranslate: true,
                                                                            })
                                                                            console.error(error.message)
                                                                            this.setState({ isLoading: false });
                                                                        }
                                                                    })
                                                            }}
                                                        >
                                                            {(isEditing) => {
                                                                if(!isEditing){
                                                                    return (
                                                                        <DisplayI18n
                                                                            field={fieldName}
                                                                            defaultValue={this.props.selectedRows[0].name || '-'}
                                                                            i18n={this.props.selectedRows[0].i18n}
                                                                        />
                                                                    )
                                                                }
                                                                return (
                                                                    <FormikInputText id={fieldName} name={fieldName} trim />
                                                                )

                                                            }}
                                                        </FormikEditable>
                                                    </div>

                                                )
                                            }}
                                        </RenderI18nForm>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </>
                    )}
                </I18nHelperContext.Consumer>
            </OverlayLoader>
        )
    }
}

export default withContexts(I18nContext, OrganizationContext, RolesContext)(SidePanelPlatformLexicon);