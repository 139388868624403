import PropTypes from 'prop-types';
import { stringBuilder } from "@spordle/helpers";

/**
 * @param {React.ComponentType} [tag='div'] The desired htmlElement of the container.
 * @param {string} [className]
*/
const UserDisplaySubtitle = ({
    tag: Tag,
    className,
    ...attributes
}) => {

    return (
        <Tag {...attributes} className={stringBuilder('userDisplay-subtitle', className)} />
    );
};

export default UserDisplaySubtitle;

UserDisplaySubtitle.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
};
UserDisplaySubtitle.defaultProps = {
    tag: 'div',
};