import { Tooltip } from "@mantine/core";
import SpordleTableProvider, { SpordleTableView } from "@spordle/datatables";
import Translate, { DateFormat } from "@spordle/intl-elements";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Badge, Card } from "reactstrap";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import SpordlePanelControl from "../../../components/sidePanel/SpordlepanelControl";
import TablePagination from "../../../components/table/TablePagination";
import ViewHeaderV2 from "../../../components/viewHeader/ViewHeaderV2";
import { CartsContext } from "../../../contexts/CartsContext";
import WaitingListSidePanel from "./sidePanel/WaitingListSidePanel";
import WaitingListTopSection from "./WaitingListTopSection";

const WaitingList = () => {
    const { getWaitingListItems } = useContext(CartsContext);

    const getBadgeColour = (status) => {
        switch (status){
            case 'PENDING':
            case 'WAITING_FOR_CLIENT':
                return 'warning';
            case 'CANCELLED':
            case 'CANCELED':
                return 'danger';
            case 'CONVERTED':
                return 'success';
            default:
                return 'danger';
        }
    }

    return (
        <>
            <ViewHeaderV2 title='task.waitingList.title' />

            <div className="px-4">
                <SpordlePanelTable
                    allowOutsideClick
                    sidePanel={(props) => <WaitingListSidePanel {...props} getBadgeColour={getBadgeColour} />}
                    dataIndex='waiting_list_item_sequential_number'
                    table={(panelProps) => {
                        return (
                            <SpordleTableProvider
                                id='waitingList'
                                tableHover bordered striped
                                clickable
                                ref={panelProps.spordleTableRef}
                                desktopWhen
                                dataIndex='waiting_list_item_sequential_number'
                                pagination={25}
                                tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                defaultSorting='+created_at'
                                searchKeys={[
                                    { name: 'member.unique_identifier', weight: 2 },
                                    'waiting_list_item_sequential_number',
                                    'member.last_name',
                                    'member.first_name',
                                    'created_at',
                                    'birthdate',
                                ]}
                                onColumnClick={(e, data, _spordleTable, columnKey) => {
                                    switch (e.button){
                                        case 0: // Left mouse button
                                            if(columnKey === 'checkbox'){
                                                panelProps.onMultiSelectChange(data);
                                            }else{
                                                panelProps.onSingleSelectChange(data);
                                            }
                                            break;
                                    }
                                }}
                                loadData={(from, { filters }, spordleTable) => {
                                    switch (from){
                                        case 'FILTER':
                                        case 'REFRESH':
                                            spordleTable.setLoading();
                                        case 'CDM':
                                            if(filters.form.registrationFeeId){
                                                return getWaitingListItems({ type: 'REGISTRATION', item_id: filters.form.registrationFeeId })
                                                    .then((waitingListItems) => {
                                                        return waitingListItems.filter((waitingListItem) => filters.form.status ? waitingListItem.status === filters.form.status : true)
                                                    })
                                            }
                                            return Promise.resolve([])
                                        default:
                                            break;
                                    }
                                }}
                                columns={[
                                    {
                                        // yes keep this
                                        label: '-',
                                        key: 'checkbox',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                    },
                                    {
                                        label: <Translate id='task.waitingList.column.waiting_list_item_sequential_number' />,
                                        key: 'waiting_list_item_sequential_number',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='task.waitingList.column.created_at' />,
                                        key: 'created_at',
                                        className: 'th-shrink',
                                        dataClassName: 'td-shrink',
                                        sortable: true,
                                    },
                                    {
                                        label: <Translate id='form.fields.name' />,
                                        key: "name",
                                        className: 'text-left',
                                        dataClassName: 'text-left',
                                        mobile: true,
                                        sortable: true,
                                        sortKey: 'member.first_name',
                                    },
                                    {
                                        label: <Translate id='form.fields.dateOfBirth' />,
                                        key: "birthdate",
                                        className: 'text-left',
                                        dataClassName: 'text-left',
                                        mobile: true,
                                        sortable: true,
                                        sortKey: 'member.birthdate',
                                    },
                                    {
                                        label: <Translate id='form.fields.status' />,
                                        key: "status",
                                        mobile: true,
                                        sortable: true,
                                    },
                                ]}
                                renderRow={(columnKey, waitingList) => {
                                    switch (columnKey){
                                        case 'checkbox':
                                            return (
                                                <label
                                                    className='my-auto'
                                                    htmlFor={'checkbox-' + waitingList.waiting_list_item_sequential_number}
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    <input
                                                        onChange={() => panelProps.onMultiSelectChange(waitingList)}
                                                        id={'checkbox-' + waitingList.waiting_list_item_sequential_number}
                                                        type='checkbox'
                                                        checked={!!waitingList.checked}
                                                    />
                                                    <span className='table-checkbox' />
                                                </label>
                                            )
                                        case 'waiting_list_item_sequential_number':
                                            return (
                                                <>
                                                    <div>{waitingList.waiting_list_item_sequential_number}</div>
                                                    <small>
                                                        <i className="text-primary mdi mdi-information-outline mr-1" />
                                                        {waitingList.identity ?
                                                            <Translate id='task.waitingList.column.waiting_list_item_sequential_number.online' />
                                                            :
                                                            <Translate id='task.waitingList.column.waiting_list_item_sequential_number.admin' />
                                                        }
                                                    </small>
                                                </>
                                            );
                                        case 'name':
                                            return <NameRender member={waitingList.member} />;
                                        case 'birthdate':
                                            return (
                                                <>
                                                    {(waitingList.member.birthdate || waitingList.member.age) ?
                                                        <>
                                                            {waitingList.member.birthdate && <div><DateFormat value={waitingList.member.birthdate} utc /></div>}
                                                            {waitingList.member.age && <div className="text-muted small"><Translate id='misc.yearsOld' values={{ age: waitingList.member.age }} /></div>}
                                                        </>
                                                        : '-'}
                                                </>
                                            )
                                        case 'created_at':
                                            return <DateFormat value={waitingList.created_at} format='YYYY-MM-DD HH:mm' />;
                                        case 'status':
                                            return <Badge color={getBadgeColour(waitingList.status)}><Translate id={`task.waitlist.status.${waitingList.status}`} /></Badge>
                                        default:
                                            break;
                                    }
                                }}
                                rowIsHighlighted={(transfer) => !!transfer.checked}
                                initFilter={{
                                    form: {
                                        registrationFeeId: '',
                                        status: null,
                                    },
                                }}
                            >
                                {(spordleTable) => (
                                    <>
                                        <WaitingListTopSection />

                                        <Card body className="card-shadow">
                                            <div className="mb-2">
                                                <div className='d-flex flex-wrap justify-content-between mb-2'>
                                                    {/* yes we keep this checkbox */}
                                                    <div className="d-flex">
                                                        <SpordlePanelControl />
                                                        <SpordleTableProvider.SearchInput />
                                                    </div>
                                                    <div className='d-flex ml-auto'>
                                                        <TablePagination className='ml-2' paginationArray={[ 25, 50, 100 ]} />
                                                        <SpordleTableProvider.Refresh className='ml-2' />
                                                    </div>
                                                </div>
                                            </div>
                                            <SpordleTableView />
                                        </Card>
                                    </>
                                )}
                            </SpordleTableProvider>
                        )
                    }}
                />
            </div>
        </>
    );
};

const NameRender = ({ member }) => {
    return (
        <div className='d-flex align-items-start justify-content-between'>
            <div className='flex-grow-1'>
                <div className="font-medium">{member.first_name + ' ' + member.last_name}</div>
                <div className="small">
                    <Link className="text-nowrap" to={`/members/profile/${member.member_id}`}>
                        {member.unique_identifier ?
                            <>
                                #{member.unique_identifier}
                            </>
                            :
                            <Translate id='clinics.profile.waitlist.table.goToProfile' />
                        }
                        <i className="ml-1 mdi mdi-chevron-right" />
                    </Link>
                </div>
            </div>
            {member.confirmed == '0' &&
                <div className='flex-grow-0'>
                    <Tooltip withArrow label={<Translate id='task.waitlist.notConfirmed' />}>
                        <i className='text-danger mdi mdi-information-outline' style={{ fontSize: '18px' }} />
                    </Tooltip>

                </div>
            }
        </div>
    )
}

export default WaitingList;
