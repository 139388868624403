export default{
    "organization.profile.venues.modals.components.createVenueStep1.btn.back" : "Retour à la liste d'emplacements",

    "organization.profile.venues.modals.components.createVenueStep1.title" : "Étape 1 du formulaire d'ajout d'emplacement",

    "organization.profile.venues.modals.components.createVenueStep1.location.abbr"    : "Abbréviation",
    "organization.profile.venues.modals.components.createVenueStep1.location.address" : "Adresse",
    "organization.profile.venues.modals.components.createVenueStep1.location.name"    : "Nom de l'emplacement",
    "organization.profile.venues.modals.components.createVenueStep1.location.type"    : "Type d'emplacement",

    "organization.profile.venues.modals.components.createVenueStep1.location.error.address" : "L'addresse de l'emplacement est requise.",
    "organization.profile.venues.modals.components.createVenueStep1.location.error.abbr"    : "L'abbréviation de l'emplacement est requise.",
    "organization.profile.venues.modals.components.createVenueStep1.location.error.name"    : "Le nom de l'emplacement est requis.",
    "organization.profile.venues.modals.components.createVenueStep1.location.error.type"    : "Le type demplacement est requis.",
}