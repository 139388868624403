import Translate, { useTranslate } from "@spordle/intl-elements";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Label, Input, Spinner } from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { delay } from "../../../../../../helpers/helper";
import { Tooltip } from '@mantine/core';
import CustomAnimatedIcon from "../../../../../customAnimatedIcon/CustomAnimatedIcon";

const ORIGIN_NONE = 0;
const ORIGIN_NOTFOUND = 1;
const ORIGIN_EXISTS = 2;
const ORIGIN_ERROR = 3;
const ORIGIN_SUCCESS = 4;

const ORIGINS = {
    [ORIGIN_ERROR]: {
        color: "danger",
    },
    [ORIGIN_NOTFOUND]: {
        color: "danger",
        icon: <i className="mdi mdi-alert-outline" />,
    },
    [ORIGIN_EXISTS]: {
        color: "danger",
        icon: <i className="mdi mdi-alert-outline" />,
    },
    [ORIGIN_SUCCESS]: {
        color: "success",
        icon: (
            <>
                <div style={{ top: "50%", right: "50%", transform: "translate(50%, -50%)" }} className="position-absolute">
                    <CustomAnimatedIcon strokeWidth="16px" size="18px" icon="checkmark" />
                </div>
                <i style={{ visibility: "hidden" }} className="mdi mdi-plus" />
            </>
        ),
    },
}

const SearchInput = ({ addRecipient }) => {
    const { getMembers } = useContext(MembersContext);
    const btnRef = useRef(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ input, setInput ] = useState("");
    const [ feedback, setFeedback ] = useState({
        showError: false,
        origin: ORIGIN_NONE,
    });

    const showErrorMsg = [ ORIGIN_EXISTS, ORIGIN_NOTFOUND ].includes(feedback.origin);

    const handleOnChange = (e) => {
        setInput(e.currentTarget.value);
    }

    const handleOnEmpty = () => {
        setInput("");
    }

    const updateFeedback = (feedback) => {
        setFeedback((prev) => ({ ...prev, ...feedback }));
    }

    const handleOnSearch = () => {
        if(input){
            setIsLoading(true);

            getMembers({ unique_identifier: input, all: 1 })
                .then((members) => {
                    delay(500) // avoid changes too fast
                        .then(() => {
                            if(members.length <= 0){
                                updateFeedback({ origin: ORIGIN_NOTFOUND });
                            }else{
                                const isAdded = addRecipient(members[0]);

                                if(!isAdded){
                                    updateFeedback({ origin: ORIGIN_EXISTS });
                                }else{
                                    updateFeedback({ origin: ORIGIN_SUCCESS });
                                    handleOnEmpty();
                                }
                            }

                            setIsLoading(false);
                        });

                })
                .catch((e) => {
                    if(!AxiosIsCancelled(e.message)){
                        console.error(e);
                        setIsLoading(false);
                        updateFeedback({ origin: ORIGIN_NOTFOUND });

                    }
                });
        }else{
            updateFeedback({ showError: true, origin: ORIGIN_ERROR });

            setTimeout(() => {
                updateFeedback({ showError: false });
            }, 500)
        }
    }

    useEffect(() => {
        let timeout = null;

        if(feedback.origin !== ORIGIN_NONE){
            timeout = setTimeout(() => {
                updateFeedback({ origin: ORIGIN_NONE });
            }, 1300)
        }

        return () => {
            clearTimeout(timeout);
        }
    }, [ feedback.origin ])

    return (
        <>
            <Label for="unique_identifier" className="text-muted pt-3 mt-3 border-top w-100"><Translate id="components.communications.modal.label.searchAndAdd" /></Label>
            <div className="d-flex align-items-center mb-2">
                <div className="position-relative w-100 mr-2">
                    <Input
                        value={input}
                        invalid={feedback.origin === ORIGIN_ERROR}
                        className="transition"
                        onChange={handleOnChange}
                        placeholder={useTranslate({ id: 'form.fields.uniqueIdentifier' })}
                        name="unique_identifier"
                    />
                    {!!input &&
                        <button
                            className="reset-btn text-primary mdi mdi-close position-absolute top-0 bottom-0 right-0 p-2"
                            type="button"
                            onClick={handleOnEmpty}
                        />
                    }
                </div>
                <Tooltip
                    opened={showErrorMsg}
                    withArrow
                    label={
                        showErrorMsg &&
                            <Translate id={`components.communications.modal.btn.${feedback.origin}`} />
                    }
                >
                    <Button
                        innerRef={btnRef}
                        data-for="feedbackRes"
                        data-tip={feedback.origin}
                        disabled={isLoading || !!feedback.origin}
                        onClick={handleOnSearch}
                        className="text-nowrap position-relative"
                        color={ORIGINS[feedback.origin]?.color || "primary"}
                        type="button"
                    >
                        {isLoading ?
                            <div style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} className="position-absolute">
                                <Spinner color="white" size="sm" />
                            </div>
                            :
                            ORIGINS[feedback.origin]?.icon &&
                                <div style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} className="position-absolute">
                                    {ORIGINS[feedback.origin]?.icon}
                                </div>
                        }
                        <span style={{ opacity: (isLoading || !!ORIGINS[feedback.origin]?.icon) ? 0 : 1 }}>
                            <i className="mdi mdi-plus mr-1" /><Translate id="misc.add" />
                        </span>
                    </Button>
                </Tooltip>
            </div>
        </>
    );
}

export default SearchInput;