import React from 'react';

// Context Providers
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
// I18N


import withContexts from '../../../../helpers/withContexts';
import searchSidebar from '../../../../routes/navigationRoutes/searchSidebar';
import { RolesContext } from '../../../../contexts/RolesContext';
import { flattenSidebarRoutes, isActiveSidebar, wrapSubPermissionsRoutes } from '../../../../helpers/navigationHelper';
import { stringBuilder } from '@spordle/helpers';
import tasksSidebar from '../../../../routes/navigationRoutes/tasksSidebar';
import { Link } from 'react-router-dom';
import MobileFooterNavItem from './MobileFooterNavItem';
import MobileManage from '../../../../assets/images/icons/navigation/MobileManage';
import MobileTask from '../../../../assets/images/icons/navigation/MobileTask';
import HelpCenterV2 from '../../../../components/helpCenter/HelpCenterV2';
import MobileBottomProfileMenu from './profileMenu/MobileBottomProfileMenu';
import EnvBanner from '../../../../components/EnvBanner';

class MobileFooter extends React.Component{
    constructor(props){
        super(props);

        this.showForSearch = searchSidebar.some((r) => !!props.RolesContext.validateRouteAccess(r)) // Loop on group subroutes
        this.showForTasks = props.RolesContext.validateRouteAccess(wrapSubPermissionsRoutes(tasksSidebar))
    }

    componentDidUpdate(prevProps){
        if(!prevProps.footerMenuActiveTab && !!this.props.footerMenuActiveTab && this.props.hamburgerIsOpened){
            // burger menu is opened and we just opened the footer menu -> eat hamburger (close the burger menu)
            this.props.eatHamburger();
        }else if(!prevProps.hamburgerIsOpened && this.props.hamburgerIsOpened && !!this.props.footerMenuActiveTab){
            // footer menu is opened and we just opened the burger menu -> close the footer menu
            this.props.setFooterMenuActiveTab('');
        }
    }

    render(){
        return (
            <>
                <nav tabIndex={-1} onFocus={this.props.closeMenu} className="sid-mobile-footer">
                    <EnvBanner mobile />
                    <div className="sid-mobile-footer-inner">
                        <ul className="sid-mobile-footer-list">
                            <li className="sid-mobile-footer-item">
                                <div className="sid-mobile-footer-item-inner">
                                    <Link
                                        to="/dashboard"
                                        className={stringBuilder(
                                            "d-flex flex-center sid-mobile-nav-link sid-nav-link is-dashboard",
                                            { "is-active": this.props.location.pathname.startsWith("/dashboard") },
                                        )}
                                        onClick={() => {
                                            if(this.props.footerMenuActiveTab){
                                                this.props.setFooterMenuActiveTab('');
                                            }
                                        }}
                                    >
                                        <span className="sid-nav-dashboard-icon">
                                            <i className="sid-nav-dashboard-icon-top-squares" />
                                            <i className='sid-nav-dashboard-icon-bottom-squares' />
                                        </span>
                                    </Link>
                                </div>
                            </li>
                            {this.showForSearch &&
                                <li className="sid-mobile-footer-item">
                                    <MobileFooterNavItem
                                        icon={
                                            <span className={stringBuilder({ 'text-purple': this.showForSearch === 'ADMIN' })}>
                                                <MobileManage />
                                            </span>
                                        }
                                        isSmall
                                        isActive={isActiveSidebar(searchSidebar)}
                                        links={flattenSidebarRoutes(searchSidebar)}
                                        handleToggle={() => {
                                            this.props.setFooterMenuActiveTab(this.props.footerMenuActiveTab === this.props.menus.search ? '' : this.props.menus.search)
                                        }}
                                        isOpened={this.props.footerMenuActiveTab === this.props.menus.search}
                                    />
                                </li>
                            }
                            {this.showForTasks &&
                                <li className="sid-mobile-footer-item">
                                    <MobileFooterNavItem
                                        icon={
                                            <span className={stringBuilder({ 'text-purple': this.showForTasks === 'ADMIN' })}>
                                                <MobileTask />
                                            </span>
                                        }
                                        isSmall
                                        isActive={isActiveSidebar(tasksSidebar)}
                                        links={flattenSidebarRoutes(tasksSidebar)}
                                        handleToggle={() => {
                                            this.props.setFooterMenuActiveTab(this.props.footerMenuActiveTab === this.props.menus.tasks ? '' : this.props.menus.tasks)
                                        }}
                                        isOpened={this.props.footerMenuActiveTab === this.props.menus.tasks}
                                    />
                                </li>
                            }
                            <li className="sid-mobile-footer-item">
                                <HelpCenterV2
                                    fullscreen
                                    isOpen={this.props.footerMenuActiveTab === this.props.menus.help}
                                    toggle={() => this.props.setFooterMenuActiveTab(this.props.footerMenuActiveTab === this.props.menus.help ? '' : this.props.menus.help)}
                                    toggler={(toggle, isOpen) => (
                                        <div
                                            onClick={toggle}
                                            className={stringBuilder("sid-mobile-footer-item-inner", { "is-open": isOpen })}
                                            tabIndex="-1"
                                        >
                                            <i className="mdi mdi-help-circle-outline font-24" />
                                        </div>
                                    )}
                                />
                            </li>
                            <li className="sid-mobile-footer-item">
                                <MobileBottomProfileMenu
                                    handleToggle={() => {
                                        this.props.setFooterMenuActiveTab(
                                            this.props.footerMenuActiveTab === this.props.menus.profile ? '' : this.props.menus.profile,
                                        )
                                    }}
                                    isOpened={this.props.footerMenuActiveTab === this.props.menus.profile}
                                />
                            </li>
                        </ul>
                    </div>
                </nav>
            </>
        );
    }
}

export default withContexts(OrganizationContext, RolesContext)(MobileFooter);
