import React, { useContext } from 'react';

/**
 * Function that returns a HOC with the context's as props
 * NOTE: Define a displayName for your contexts
 * @param {...React.Context} contextsParams
 * @returns {(Component: React.ComponentType.<T>) => React.ComponentType.<T>}
 * @template T
 */
function withContexts(...contextsParams){
    const contexts = {};

    for(let index = 0; index < contextsParams.length; index++){
        const element = contextsParams[index];
        contexts[element.displayName] = element;
    }

    return function(Component){
        return React.forwardRef((props, ref) => {
            const tempContexts = {}
            for(const key in contexts){
                // eslint-disable-next-line no-prototype-builtins
                if(contexts.hasOwnProperty(key)){
                    tempContexts[key] = useContext(contexts[key]);
                }
            }

            return <Component {...props} {...tempContexts} ref={ref} />
        })
    };
}

export default withContexts;