import PropTypes from 'prop-types';
import { stringBuilder } from "@spordle/helpers";
import StepsItem from './StepsItem';

/**
* @param {string} activeStepName name of the active step, used for mobile display
* @param {string} [className] className used to position the container
* @example
<Steps activeStepName={'Session Information'} className="mb-4">
    <Steps.Item name={'Clinic Information'} number={1} setActiveStep={setActiveStep} isValid />
    <Steps.Item name={'Session Information'} number={2} setActiveStep={setActiveStep} isActive />
    <Steps.Item name={'Communication'} number={3} setActiveStep={setActiveStep} />
</Steps>
*/
const Steps = ({
    className,
    activeStepName,
    children,
    ...props
}) => {

    return (
        <div className={stringBuilder("wizard", className)} {...props}>
            <div className="step-list">
                {children}
            </div>
            <div className="text-center">
                <div className="step-title d-sm-none h2 mt-2 font-medium">
                    {activeStepName}
                </div>
            </div>
        </div>
    );
};

Steps.Item = StepsItem;

export default Steps;

Steps.propTypes = {
    activeStepName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
};
