import Translate from "@spordle/intl-elements"
import AnalyticsModal from "../../../analytics/AnalyticsModal"
import { FormikInputText, FormikSelect } from "@spordle/formik-elements"
import { Form, Formik } from "formik"
import { Badge, Button, Col, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { DisplayI18n } from "../../../helpers/i18nHelper";
import OrganizationSearch from "../../organization/OrganizationSearch"
import { useContext } from "react"
import { OrganizationContext } from "../../../contexts/OrganizationContext"
import { HelpCenterContext } from "../../../contexts/HelpCenterContext"
import { AxiosIsCancelled } from "../../../api/CancellableAPI"
import { fail } from "@spordle/toasts"
import { InlineConfirmation } from "../../InlineConfirmation"
import OverlayLoader from "../../loading/OverlayLoader"
import { object, string } from "yup"
import FormikTiny from "../../formik/FormikTiny"

const HelpCenterAdditionalAlertFormModal = ({ editAlertMode, editAlertModeHandlers, helpBubble, currentHelpBubbleAdditionalAlert, getHelpCenter, getColor }) => {
    const { organisation_id } = useContext(OrganizationContext)
    const { createHelpBubbleAlert, deleteHelpBubbleAlert } = useContext(HelpCenterContext)

    const getIcon = (color) => {
        switch (color){
            case 'success':
                return 'mdi mdi-check';
            case 'warning':
                return 'mdi mdi-alert-outline';
            case 'danger':
                return 'mdi mdi-close-circle-outline';
            case 'info':
                return 'mdi mdi-information-outline';
        }

    }

    return (
        <AnalyticsModal isOpen={editAlertMode} analyticsName={'helpCenterEditAlertModal'} size="xl">
            <Formik
                initialValues={{
                    color: currentHelpBubbleAdditionalAlert?.color || '3',
                    organisation_id: currentHelpBubbleAdditionalAlert?.organisation?.organisation_id || organisation_id,
                    titleEn: currentHelpBubbleAdditionalAlert?.i18n?.en?.title || '',
                    contentEn: currentHelpBubbleAdditionalAlert?.i18n?.en?.content || '',
                    titleFr: currentHelpBubbleAdditionalAlert?.i18n?.fr?.title || '',
                    contentFr: currentHelpBubbleAdditionalAlert?.i18n?.fr?.content || '',
                }}
                validationSchema={
                    object().shape({
                        titleEn: string().test({
                            name: 'titleEnIfNoTitleFr',
                            message: <Translate id='help_center.alert.editModal.validation.title.required' />,
                            test: function(value){
                                if(!this.parent.titleFr || !!this.parent.contentEn){
                                    return !!value;
                                }
                                return true;
                            },
                        }),
                        contentEn: string().when('titleEn', {
                            is: (val) => val,
                            then: string().required(<Translate id='help_center.alert.editModal.validation.content.required' />),
                            otherwise: string(),
                        }),
                        titleFr: string().test({
                            name: 'titleFrIfNoTitleEn',
                            message: <Translate id='help_center.alert.editModal.validation.title.required' />,
                            test: function(value){
                                if(!this.parent.titleEn || this.parent.contentFr){
                                    return !!value;
                                }
                                return true;
                            },
                        }),
                        contentFr: string().when('titleFr', {
                            is: (val) => val,
                            then: string().required(<Translate id='help_center.alert.editModal.validation.content.required' />),
                            otherwise: string(),
                        }),
                        color: string().required(<Translate id='help_center.alert.editModal.validation.type.required' />),
                        organisation_id: string().required(<Translate id='help_center.alert.editModal.validation.display.for.required' />),
                    })
                }
                onSubmit={(values) => {
                    const submitValues = {
                        help_bubble_id: helpBubble.help_bubble_id || '',
                        help_bubble_additional_id: currentHelpBubbleAdditionalAlert?.help_bubble_additional_id || '',
                        active: 1,
                        organisation_id: values.organisation_id,
                        title: values.titleEn || '',
                        content: values.contentEn || '',
                        color: values.color,
                        i18n: {
                            en: {
                                title: values.titleEn || '',
                                content: values.contentEn || '',
                            },
                            fr: {
                                title: values.titleFr || '',
                                content: values.contentFr || '',
                            },
                        },
                    }

                    //create (put)
                    return createHelpBubbleAlert(submitValues).then(() => {
                        getHelpCenter();
                        editAlertModeHandlers.close();
                    }).catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                        }
                    })

                }}
            >
                {(formik) => (
                    <Form>
                        <OverlayLoader isLoading={formik.isSubmitting}>
                            <ModalHeader toggle={editAlertModeHandlers.toggle}>
                                <Translate id="helpCenter.editAlertModal.title" />
                            </ModalHeader>
                            <ModalBody>
                                <div>
                                    <Row className="w-100 d-flex mb-1 mx-0">
                                        <Col md={6} className="form-group px-1">
                                            <Label for={`titleEn`}><Translate id='help.center.form.field.i18n.en.title' /></Label>
                                            <FormikInputText
                                                name={'titleEn'}
                                                id={'titleEn'}
                                            />
                                        </Col>
                                        <Col md={6} className="form-group px-1">
                                            <Label for={`titleFr`}><Translate id='help.center.form.field.i18n.fr.title' /></Label>
                                            <FormikInputText
                                                name={'titleFr'}
                                                id={'titleFr'}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="w-100 d-flex mb-1 mx-0">
                                        <Col md={6} className="form-group px-1">
                                            <Label for={'contentEn'}><Translate id='help.center.form.field.i18n.en.content' /></Label>
                                            <FormikTiny
                                                name={'contentEn'}
                                                id={'contentEn'}
                                                init={{
                                                    height: 150,
                                                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
                                                    forced_root_block: false,
                                                }}
                                            />
                                        </Col>
                                        <Col md={6} className="form-group px-1">
                                            <Label for={'contentFr'}><Translate id='help.center.form.field.i18n.fr.content' /></Label>
                                            <FormikTiny
                                                name={'contentFr'}
                                                id={'contentFr'}
                                                init={{
                                                    height: 150,
                                                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
                                                    forced_root_block: false,
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="d-flex">
                                        <div className="w-100 px-1">
                                            <Label><Translate id='help_center_additional.editModal.alert.color' /></Label>
                                            <FormikSelect
                                                name={`color`}
                                                id={`color`}
                                                loadingStatus='success'
                                                renderOption={(option) => {
                                                    return (
                                                        <Badge
                                                            color={getColor(option.option.value)}
                                                            pill
                                                        >
                                                            <i className={`mr-1 ${getIcon(getColor(option.option.value))}`} />
                                                            <Translate id={option.option.label} />
                                                        </Badge>
                                                    )
                                                }}
                                                options={[
                                                    {
                                                        label: 'helpCenter.editModal.alert.color.3',
                                                        value: '3',
                                                    },
                                                    {
                                                        label: 'helpCenter.editModal.alert.color.0',
                                                        value: '0',
                                                    },
                                                    {
                                                        label: 'helpCenter.editModal.alert.color.1',
                                                        value: '1',
                                                    },
                                                    {
                                                        label: 'helpCenter.editModal.alert.color.2',
                                                        value: '2',
                                                    },
                                                ]}
                                            />
                                        </div>
                                        <div className="w-100 px-1">
                                            <Label><Translate id='help_center_additional.editModal.alert.organisation_id' /></Label>
                                            <OrganizationSearch
                                                name={`organisation_id`}
                                                id={`organisation_id`}
                                                disabled={!!currentHelpBubbleAdditionalAlert?.help_bubble_additional_id}
                                                withFormik
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <InlineConfirmation
                                    disabled={!currentHelpBubbleAdditionalAlert?.help_bubble_additional_id}
                                    onClick={() => {
                                        formik.setSubmitting(true)
                                        deleteHelpBubbleAlert(currentHelpBubbleAdditionalAlert?.help_bubble_additional_id).then(() => {
                                            getHelpCenter();
                                            editAlertModeHandlers.close();
                                        }).catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                fail({
                                                    msg: 'misc.error',
                                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                    skipInfoTranslate: true,
                                                })
                                            }
                                        }).finally(() => {
                                            formik.setSubmitting(false);
                                        })
                                    }}
                                />
                                <div className="ml-auto">
                                    <Button
                                        onClick={editAlertModeHandlers.close}
                                        color="primary"
                                        outline
                                        className="mr-2"
                                    >
                                        <Translate id="misc.cancel" />
                                    </Button>
                                    <Button type="submit" color="primary"><Translate id="misc.submit" /></Button>
                                </div>
                            </ModalFooter>
                        </OverlayLoader>
                    </Form>
                )}
            </Formik>
        </AnalyticsModal>
    )
}

export default HelpCenterAdditionalAlertFormModal