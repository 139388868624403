import Translate from "@spordle/intl-elements";
import { Alert } from "reactstrap";

const MemberFieldsEmpty = () => {
    return (
        <Alert color="light" className="mb-0 text-center">
            <div className="font-weight-bold h5">
                <Translate id="organization.settings.registrationSettings.memberFields.empty" />
            </div>
            <p className="mb-0"><Translate id="organization.settings.registrationSettings.memberFields.empty.txt" /></p>
        </Alert>
    );
}

export default MemberFieldsEmpty;