import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { NotificationsContext } from "../../../../../contexts/NotificationsContext";
import getAccountUrl from "../../../../../helpers/getExternalUrl";
import HasAccessTo from "../../../../../components/permissions/HasAccessTo";
import NoticeLink from "./links/NoticeLink";

const LinksAndSignout = ({ setActiveStep, handleOnToggle }) => {
    const notificationsContext = useContext(NotificationsContext);
    return (
        <>
            <ul className="list-unstyled">
                {/* notifications */}
                <li
                    className="sid-mobile-footer-menu-subitem text-dark"
                    onClick={(e) => {
                        setActiveStep('notifications');
                        e.stopPropagation();
                    }}
                >
                    <div className="position-relative mr-3">
                        <i className="mdi mdi-bell font-22" />
                        { (notificationsContext.state.notificationsCount > 0) &&
                            <Badge className="sid-mobile-footer-notif" pill>
                                {notificationsContext.state.notificationsCount > 9 ? '9+' : notificationsContext.state.notificationsCount}
                            </Badge>
                        }
                    </div>
                    <Translate id="header.notifications.title" /> <i className="mdi mdi-chevron-right ml-auto font-18 line-height-1" />
                </li>

                {/* Communications */}
                <HasAccessTo tag="li" componentCode="communications" componentPermissionCode="communications_manage">
                    <Link
                        to='/communications'
                        className="sid-mobile-footer-menu-subitem text-dark"
                        onClick={handleOnToggle}
                    >
                        <i className="mdi mdi-email mr-3 font-22" />
                        <Translate id='sidebar.communications' />
                        <i className="mdi mdi-chevron-right ml-auto font-18 line-height-1" />
                    </Link>
                </HasAccessTo>

                {/* News */}
                <NoticeLink handleOnToggle={handleOnToggle} />

                {/* My support requests */}
                <li>
                    <Link
                        to='/supportRequest'
                        className="sid-mobile-footer-menu-subitem text-dark"
                        onClick={handleOnToggle}
                    >
                        <i className="mdi mdi-wrench mr-3 font-22" />
                        <Translate id='header.supportRequest' />
                        <i className="mdi mdi-chevron-right ml-auto font-18 line-height-1" />
                    </Link>
                </li>

                {/* My account */}
                <li>
                    <a
                        href={getAccountUrl('SPORDLE')}
                        className='sid-mobile-footer-menu-subitem text-dark'
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <i className="mdi mdi-home mr-3 font-22" />
                        <div>
                            <Translate id='header.account' />
                            <div className="small text-muted"><Translate id="misc.goTo" /> https://account.spordle.com</div>
                        </div>
                        <i className="mdi mdi-launch ml-auto font-18 line-height-1" />
                    </a>
                </li>
            </ul>
        </>
    )
}

export default LinksAndSignout