import { fail } from "@spordle/toasts";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import { separateFilesPerSize } from "../../../../../../components/uploader/uploadHelpers";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { OrganizationContext } from "../../../../../../contexts/OrganizationContext";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { DISCRIMINATION_TYPE_ANONYMOUS, DISCRIMINATION_TYPE_OFFICIAL } from "../../DiscriminationHelper";

/** @type {React.Context<Omit<Schedule, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>>} */
export const DiscriminationAddContext = createContext();
DiscriminationAddContext.displayName = 'DiscriminationAddContext';

function DiscriminationAddContextProvider(props){
    const { createDiscrimination, createDiscriminationAttachments } = useContext(OrganizationContext);
    const { currentMember } = useContext(MembersContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ step, setStep ] = useState(1);
    const [ complaint, setComplaintData ] = useState({
        complaint_by: {},
        incident: {},
        on_behalf: {},
        complainees: [],
        complaint_type: DISCRIMINATION_TYPE_OFFICIAL, // After update, complaint type is always OFFICIAL
    });
    const statusWithReasons = props.status?.reduce((reasonsIds, s) => {
        if(s.is_close_status == 1){
            reasonsIds.push(s.discrimination_status_id);
        }
        return reasonsIds;
    }, []);


    /**
     * @description Changes step of the modal
     * @param {1|2|3|4} nb
     */
    const changeStep = (nb) => {
        setStep(nb);
    };

    const next = () => setStep(step + 1);
    const previous = () => {
        if(step - 1 === 2 && complaint.complaint_type === DISCRIMINATION_TYPE_ANONYMOUS){
            setStep(1);
        }else{
            setStep(step - 1);
        }
    };

    /**
     * @description Adds data to the complaint
     * @param {string} field Object property to add to complaint data
     * @param {any} data Data to add to this property
     */
    const setComplaint = (field, data) => {
        setComplaintData((prev) => ({ ...prev, [field]: data }))
    };

    const submitComplaint = () => {
        const apiData = {};

        if(complaint.type === DISCRIMINATION_TYPE_ANONYMOUS){
            apiData.complaint_by = DISCRIMINATION_TYPE_ANONYMOUS;
        }else{
            apiData.complaint_by = complaint.complaint_type === DISCRIMINATION_TYPE_ANONYMOUS ? DISCRIMINATION_TYPE_ANONYMOUS : complaint.complaint_by.role || 'OFFICIAL';
            apiData.complaint_by_first_name = complaint.complaint_by.first_name || '';
            apiData.complaint_by_last_name = complaint.complaint_by.last_name || '';

            if(complaint.complaint_by.member_id){
                apiData.complaint_by_id = complaint.complaint_by.member_id;
            }

            if(complaint.complaint_by.email){
                apiData.complaint_by_email = complaint.complaint_by.email;
            }

            if(complaint.complaint_by.phone_number){
                apiData.complaint_by_phone = complaint.complaint_by.phone_number;
            }

            if(complaint.complaint_by.address?.city){
                apiData.complaint_by_city = complaint.complaint_by.address?.city;
                apiData.complaint_by_province_id = complaint.complaint_by.address?.state || '';
                apiData.complaint_by_postal_code = complaint.complaint_by.address?.zipCode || '';
                apiData.complaint_by_address = complaint.complaint_by.address?.streetNumber + ' ' + complaint.complaint_by.address?.address;
            }
        }

        const complaineeIsTeam = (data) => data.type === "TEAM";

        // Complainees
        apiData.members = complaint.complainees.map((member) => {
            if(complaineeIsTeam(member)){
                const teamObj = { belongs_to: "HOME" }

                if(member.team_id){
                    teamObj.team_id = member.team_id
                }else{
                    teamObj.team_name = member.team_name
                }

                return teamObj;
            }

            const memberObj = {
                first_name: member.first_name,
                last_name: member.last_name,
                role: member.role,
                member_id: member.member_id,
            }

            if(member.team_id){
                memberObj.team_id = member.team_id;
                memberObj.belongs_to = "HOME"; // This is temporarily hardcoded because it won't be there in the future. As of now, it's required in the API.
            }

            if(member.team_name){
                memberObj.team_name = member.team_name;
                memberObj.belongs_to = "HOME"; // This is temporarily hardcoded because it won't be there in the future. As of now, it's required in the API.
            }

            return memberObj;

        });

        // This is temporarily hardcoded because it won't be there in the future.
        // As of now, it's required in the API. Will be replaced with 'maltreatment_location_id'
        apiData.type = "ON_ICE";

        // Complaint
        apiData.detailed_summary = complaint.incident.summary;
        apiData.discrimination_reason = complaint.incident.grounds.join(',');

        apiData.incident_date = moment(complaint.incident.date).toISOString();
        apiData.maltreatment_location_id = complaint.incident.maltreatment_location_id;
        apiData.maltreatment_type_id = complaint.incident.maltreatment_type_id;
        apiData.discrimination_status_id = complaint.incident.discrimination_status_id;
        apiData.arena = complaint.incident.arena;
        apiData.reference_number = complaint.incident.reference_number;

        if(statusWithReasons.includes(complaint.incident.discrimination_status_id)){
            apiData.closed_reason = complaint.incident.closed_reason.join(',');
        }

        let formattedAttachments = null;

        if(complaint.incident.files?.length > 0){
            formattedAttachments = separateFilesPerSize(complaint.incident.files ?? []);
            apiData.attachments = formattedAttachments.splice(0, 1)[0];
        }

        return createDiscrimination(apiData)
            .then(async(discriminationId) => {
                if(Array.isArray(formattedAttachments) && formattedAttachments.length > 0){
                    await Promise.all(
                        formattedAttachments.map((subArr) => {
                            return createDiscriminationAttachments(
                                null,
                                discriminationId,
                                subArr,
                            )
                        }),
                    )
                        .catch((error) => {
                            if(!AxiosIsCancelled(error.message)){
                                console.error(error.message)
                                fail({
                                    msg: 'misc.error',
                                    info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                    skipInfoTranslate: true,
                                })
                            }
                        })
                }

                return discriminationId;
            });
    }

    useEffect(() => {
        if(props.fromMemberView) setComplaint('complainees', [ currentMember ]);

    }, []);

    return (
        <DiscriminationAddContext.Provider
            value={{
                isLoading,
                submitComplaint,
                step,
                changeStep,
                setLoading: (bool) => setIsLoading(bool ?? !isLoading),
                setComplaint,
                complaint,
                next,
                statusWithReasons,
                previous,
                ...props,
            }}
        >
            {props.children}
        </DiscriminationAddContext.Provider>
    )
}

export default DiscriminationAddContextProvider;