import { Tooltip } from "@mantine/core";
import { addExtension, DisplayPhoneNumber, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Label } from "reactstrap";
import { object, string } from "yup";
import CollapsibleCard from "../../../../../../../components/collapsibleCard/CollapsibleCard";
import ConfirmModal from "../../../../../../../components/confirmModal/ConfirmModal";
import FormikEditable from "../../../../../../../components/formik/FormikEditable";
import CanDoAction from "../../../../../../../components/permissions/CanDoAction";
import { OFFICIAL_TYPES } from "../../../gameIncidentHelper";

const GameIncidentOfficialCard = ({ official, index, updateValues, handleDelete, canEdit }) => {
    return (
        <CollapsibleCard
            initOpen={index === 0}
            className="mb-3"
            title={
                <span>
                    {official.member?.first_name + ' ' + official.member?.last_name}
                    {official.filled_request == "1" && <span className="ml-1 font-12 font-normal text-muted">(<Translate id='gameIncident.sidePanel.official.filledRequest' />)</span>}
                </span>
            }
            subtitle={'#' + official.member?.unique_identifier}
            extraInfo={
                official.filled_request == "1" ?
                    null
                    :
                    <ConfirmModal
                        modalMsg='gameIncident.sidePanel.delete.official'
                        translateModalMsg
                        modalTitle={official.member?.first_name + ' ' + official.member?.last_name}
                        toggler={(fn) => (
                            <CanDoAction
                                className="p-3 m-n3 align-self-start"
                                componentCode="organization"
                                componentPermissionCode="game_incidents"
                                action="DELETE"
                            >
                                <Tooltip zIndex={3000} withArrow label={<Translate id={'misc.delete'} />}>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            fn();
                                        }}
                                        className="reset-btn text-danger"
                                        type="button"
                                    >
                                        <small><i className="fas fa-trash" /></small>
                                    </button>
                                </Tooltip>
                            </CanDoAction>
                        )}
                        onConfirm={handleDelete}
                    />
            }
        >
            <Label className="d-block mb-0 text-muted"><Translate id='gameIncident.addModal.label.officialType' /></Label>
            <FormikEditable
                disabled={!canEdit}
                id="official_type"
                noConfirmation
                initialValues={{
                    official_type: official.official_type || '',
                }}
                className="editable-transparent"
                validationSchema={object().shape({
                    official_type: string().required(<Translate id='gameIncident.addModal.validation.officialType' />),
                })}
                onSubmit={(values) => {
                    if(values.official_type !== official.official_type){
                        updateValues(official.game_incident_official_id, values);
                    }
                }}
            >
                {(isEditing, options) => {
                    if(!isEditing){
                        return (
                            <div>
                                <Translate id={'gameIncident.addModal.steps.officials.type.' + official.official_type} />
                            </div>
                        )
                    }
                    return (
                        <FormikSelect
                            name='official_type'
                            id='official_type'
                            menuIsDefaultOpen
                            autoFocus
                            search={false}
                            onOptionSelected={options.stopEditing}
                            defaultData={OFFICIAL_TYPES.map((type) => ({
                                value: type,
                                label: 'gameIncident.addModal.steps.officials.type.' + type,
                                translateLabel: true,
                            }))}
                            loadingStatus='success'
                        />
                    )

                }}
            </FormikEditable>
            <Label className="d-block mt-2 mb-0 text-muted"><Translate id='form.fields.email' /></Label>
            <FormikEditable
                disabled={!canEdit}
                id="email"
                initialValues={{
                    email: official.email || '',
                }}
                className="editable-transparent"
                validationSchema={object().shape({
                    email: string().email(<Translate id='form.validation.email.valid' />).test({
                        name: 'isRequired',
                        message: <Translate id='gameIncident.addModal.validation.email' />,
                        test: function(email){
                            return index !== 0 || !!email
                        },
                    }),
                })}
                onSubmit={(values) => {
                    if(values.email !== official.email){
                        updateValues(official.game_incident_official_id, values);
                    }
                }}
            >
                {(isEditing, options) => {
                    if(!isEditing){
                        return (
                            <div className="text-truncate">{official.email || '-'}</div>
                        )
                    }
                    return (
                        <FormikInputText trim name="email" />
                    )

                }}
            </FormikEditable>
            <Label className="d-block mt-2 mb-0 text-muted"><Translate id='form.fields.phone' /></Label>
            <FormikEditable
                id="phone"
                disabled={!canEdit}
                initialValues={{
                    phone_number: official.phone_number?.split('#')?.[0] || '',
                    phoneExt: official.phone_number?.split('#')?.[1] || '',
                }}
                className="editable-transparent"
                validationSchema={object().shape({
                    phone_number: string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />)
                        .test({
                            name: 'isRequired',
                            message: <Translate id='gameIncident.addModal.validation.phone' />,
                            test: function(phone){
                                return index !== 0 || !!phone
                            },
                        }),
                    phoneExt: string(),
                })}
                onSubmit={async(values) => {
                    const phone = await addExtension(values.phone_number, values.phoneExt);
                    if(phone !== official.phone_number){
                        updateValues(official.game_incident_official_id, { phone_number: phone || "" });
                    }
                }}
            >
                {(isEditing, _options) => {
                    if(!isEditing){
                        return (
                            <div>
                                <DisplayPhoneNumber
                                    phoneString={official.phone_number}
                                    format='INTERNATIONAL'
                                    emptyValue='-'
                                />
                            </div>
                        )
                    }
                    return (
                        <div className="d-flex">
                            {/* PHONE NUMBER */}
                            <div>
                                <FormikPhoneInput
                                    name="phone_number"
                                    id='phone_number'
                                />
                            </div>
                            <FormikInputNumber
                                style={{
                                    flexBasis: '33%',
                                }}
                                prefix="#"
                                placeholder="Ext"
                                translatePlaceholder={false}
                                name='phoneExt'
                            />
                        </div>
                    )

                }}
            </FormikEditable>
        </CollapsibleCard>
    );
}

export default GameIncidentOfficialCard;