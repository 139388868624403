import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { Button, ModalBody, ModalFooter } from "reactstrap";
import UserDisplay from "../../../../../../../components/userDisplay/UserDisplay";
import { DiscriminationAddContext } from "../../components/DiscriminationAddContext";
import GuiltyMemberCard from "./GuiltyMemberCard";
import { object, string, array } from 'yup';

const GuiltyStepConfirm = ({ addMember, previous }) => {
    const { complaint, setComplaint, next } = useContext(DiscriminationAddContext);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                complainees: complaint.complainees,
            }}
            validationSchema={object().shape({
                complainees: array().of(object().shape({
                    team_id: string(),
                    team_name: string(),
                })),
            })}
            onSubmit={({ complainees }) => {
                setComplaint('complainees', complainees);
                next();
            }}
        >
            {(formik) => (
                <Form>
                    <ModalBody>
                        <div className="mb-4">
                            <p>
                                <Translate id='discrimination.addModal.stepGuilty.review.subtitle.1' values={{ count: complaint.complainees.length }} />
                            </p>
                        </div>
                        { complaint.complainees.filter((member) => !member.type || member.type !== "TEAM")
                            .map((member, index) => (
                                <GuiltyMemberCard
                                    key={member.temp_id}
                                    member={member}
                                    index={index}
                                />
                            ))
                        }
                        <UserDisplay onClick={addMember} style={{ borderStyle: 'dashed' }} className="d-flex justify-content-center border-primary text-primary" card hover>
                            <i className="mdi mdi-plus" /> <Translate id='discrimination.addModal.stepGuilty.review.btn.add' />
                        </UserDisplay>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={previous} color="primary" outline className="mr-auto" type="button"><Translate id="misc.previous" /></Button>
                        <Button color="primary" type="submit"><Translate id='misc.next' /></Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    )
}

export default GuiltyStepConfirm;