export default{
    // waiting list
    "components.manualRegistration.waitingList.title"                                 : "This clinic is sold out.",
    "components.manualRegistration.waitingList.text"                                  : "Click {confirm} to register the member to the waiting list.",
    // waiting list confirmation
    "components.manualRegistration.waitingListConfirmation.number"                    : "Waiting list number",
    "components.manualRegistration.waitingListConfirmation.goToWaitlist"              : "Go to waiting list",
    // waiting list return
    "components.manualRegistration.waitingListReturn.title"                           : "Complete registration",
    "components.manualRegistration.waitingListReturn.text"                            : "Complete the registration for {firstName} {lastName}.",
    "components.manualRegistration.waitingListReturn.manual"                          : "Manual registration",
    "components.manualRegistration.waitingListReturn.manual.desc"                     : "Complete the registration manually for this member.",
    "components.manualRegistration.waitingListReturn.identity"                        : "Select an identity",
    "components.manualRegistration.waitingListReturn.identity.desc"                   : "Send an email to a user to complete the registration.",
    // waiting list return confirmation
    "components.manualRegistration.waitingListReturnConfirmation.title"               : "You are almost done!",
    "components.manualRegistration.waitingListReturnConfirmation.text"                : "Click {confirm} to send an email to {name}",
    "components.manualRegistration.waitingListReturnConfirmation.confirmation"        : "Congratulations!",
    "components.manualRegistration.waitingListReturnConfirmation.confirmationMessage" : "The email has been sent. The member is now waiting for the client to complete the registration.",
}