import { useSpordleTable } from "@spordle/datatables";
import { stringBuilder } from "@spordle/helpers";
import { useContext, useEffect, useRef } from "react";
import { CustomInput } from "reactstrap";
import { AppContext } from "../../../../../contexts/contexts";
import { InboxContext } from "../../inboxContext/InboxContext";
import MobileCheckbox from "../../messagePreview/MobileCheckbox";

const SelectBatchTool = ({ selectAll, disabled = false, unselectAll, checked }) => {
    const checkboxRef = useRef(null);
    const { isMobileDevice } = useContext(AppContext);
    const { setShowTools, showTools } = useContext(InboxContext);
    const { getDisplayedData, generateId } = useSpordleTable();

    const handleOnSelectAll = () => {
        selectAll(getDisplayedData());
    }

    useEffect(() => {
        if(checkboxRef.current){
            if(checked.length > 0 && checked.length !== getDisplayedData().length){
                checkboxRef.current.indeterminate = true;
            }else if(checked.length === 0){
                checkboxRef.current.indeterminate = false;
            }
        }
    }, [ checked ]);

    return (
        isMobileDevice ?
            <MobileCheckbox
                className={stringBuilder("pl-0", "mr-0", "opacity-1", "w-auto")}
                id={generateId("selectBatch")}
                name={generateId("selectBatch")}
                checked={checked.length > 0 && checked.length === getDisplayedData().length}
                indeterminate={checked.length > 0 && checked.length !== getDisplayedData().length}
                onChange={() => {
                    if(checked.length === 0 || checked.length > 0 && checked.length !== getDisplayedData().length){
                        handleOnSelectAll();
                        if(!showTools){
                            setShowTools(true);
                        }
                    }else{
                        unselectAll();
                        if(showTools){
                            setShowTools(false);
                        }
                    }
                }}
            />
            :
            <div>
                <CustomInput
                    innerRef={checkboxRef}
                    id={generateId("selectBatch")}
                    bsSize="sm"
                    checked={checked.length > 0 && checked.length === getDisplayedData().length}
                    type="checkbox"
                    disabled={disabled}
                    onChange={() => {
                        if(checked.length === 0 || checked.length > 0 && checked.length !== getDisplayedData().length){
                            handleOnSelectAll();
                        }else{
                            unselectAll();
                        }
                    }}
                />
            </div>
    );
}

export default SelectBatchTool;