import React from "react"
import { DragDropContext } from "react-beautiful-dnd";
import { droppableMap, draggableMap } from './dragNDropMaps';

class SpordleDragDropContext extends React.Component{
    callHandler = (droppableId, handler, result) => {
        droppableMap[droppableId] && droppableMap[droppableId].callHandler(handler, result)
    }

    callHandlerOnDraggable = (draggableId, handler, result) => {
        draggableMap[draggableId] && draggableMap[draggableId].callHandler(handler, result)
    }

    getPayload = (droppableId) => droppableMap[droppableId] && droppableMap[droppableId].getPayload();

    getDraggablePayload = (draggableId) => draggableMap[draggableId] && draggableMap[draggableId].getPayload();

    handleEvent = (handler) => (result, provided) => {
        const { source, destination, draggableId } = result;

        if(source){ source.payload = this.getPayload(source.droppableId) }
        if(destination){ destination.payload = this.getPayload(destination.droppableId) }

        result.payload = this.getDraggablePayload(draggableId);

        this.callHandlerOnDraggable(draggableId, handler, result);

        if(destination){
            this.callHandler(destination.droppableId, handler, result);
        }

        if(!destination || destination.droppableId !== source.droppableId){
            this.callHandler(source.droppableId, handler, result);
        }

        this.props[handler] && this.props[handler](result, provided)
    }

    render(){
        return (
            <DragDropContext
                {...this.props}
                onBeforeDragStart={this.handleEvent('onBeforeDragStart')}
                onDragStart={this.handleEvent('onDragStart')}
                onDragUpdate={this.handleEvent('onDragUpdate')}
                onDragEnd={this.handleEvent('onDragEnd')}
            />
        )
    }
}

export default SpordleDragDropContext;