import Translate, { DateFormat } from '@spordle/intl-elements';
import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    Card,
    CardBody,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
    UncontrolledButtonDropdown
} from "reactstrap";
import { DisplayI18n } from '../../../helpers/i18nHelper';

// Spordle table
import SpordlePanelTable from '../../../components/sidePanel/SpordlePanel';
import SpordleTableProvider, { SearchInput, SpordleTableView, useSpordleTable } from '@spordle/datatables';
import EmptyLayout from '../../../components/table/EmptyLayout';

// Team components
import TeamSearchSidepanel from './sidepanel/TeamSearchSidepanel';
import TeamAdvancedSearch from './components/TeamAdvancedSearch';
import { getStatus, DisplayCategory, exportTeamsToCSV } from '../TeamHelpers';

// Visual
import UserDisplay from '../../../components/userDisplay/UserDisplay';
import UserImg from '../../../components/UserImg';

// Contexts
import { TeamsContext } from '../../../contexts/TeamsContext';
import { I18nContext } from '../../../contexts/I18nContext';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { AxiosIsCancelled } from '../../../api/CancellableAPI';
import { useIntl } from 'react-intl';
import CanDoAction from '../../../components/permissions/CanDoAction';
import HasAccessTo from '../../../components/permissions/HasAccessTo';
import TablePagination from '../../../components/table/TablePagination';
import { fail } from "@spordle/toasts";
import BatchCreationModal from '../add/BatchCreationModal';

const OrgTeamsSearch = () => {
    const { organisation_category } = useContext(OrganizationContext);
    const { getTeams, getTeamsWithPagination, getTeamStatus, clearCachedTeam } = useContext(TeamsContext);
    const { getGenericLocale } = useContext(I18nContext);
    const { formatMessage } = useIntl();
    const [ teamStatus, setTeamStatus ] = useState(null);

    const [ batchCreateModalIsOpen, setBatchCreateModalIsOpen ] = useState(false);
    const toggleBatchCreateModal = () => setBatchCreateModalIsOpen(!batchCreateModalIsOpen);

    const defaultLimit = (organisation_category.name == "Region" || organisation_category.name == "Member branches" || organisation_category.name == "NSO") ? 2000 : -1
    const [ paginationState, setPaginationState ] = useState({ limit: defaultLimit });

    const genericLocale = getGenericLocale();

    const history = useHistory();

    // When current org is an AHM, will hide the organization column
    // Edit: swap the organization for Leagues column
    const getColumns = () => {
        const columns = [
            {
                // yes keep this
                label: '-',
                key: 'checkbox',
                className: 'th-shrink',
                dataClassName: 'td-shrink d-none d-sm-table-cell', // hide checkbox on mobile
            },
            {
                label: <Translate id='teams.teamSearch.table.label.category' />,
                key: 'category',
                sortKey: () => `team_category.i18n.${genericLocale}.name`,
                fallbackSortKey: 'team_category.name',
                sortable: true,
            },
            {
                label: <Translate id='teams.teamSearch.table.label.team' />,
                key: 'team',
                sortKey: () => `i18n.${genericLocale}.name`,
                fallbackSortKey: 'name',
                sortable: true,
            },
            {
                label: <Translate id='teams.teamSearch.table.label.org' />,
                key: 'org',
                sortKey: () => `organisation.i18n.${genericLocale}.name`,
                fallbackSortKey: 'organisation.organisation_name',
                sortable: true,
            },
            // {
            //     label: <Translate id='teams.teamSearch.table.label.roster' />,
            //     key: 'roster',
            // },
            {
                //  ------> Make sure this sort by date, not by status name
                label: <Translate id='teams.teamSearch.table.label.statusDate' />,
                key: 'statusDate',
                sortKey: 'updated_at',
                fallbackSortKey: 'status',
                sortable: true,
            },
            {
                label: <Translate id='teams.teamSearch.table.pendingDeficiency' />,
                key: 'hasPendingDeficient',
                sortKey: 'has_pending_deficient',
                fallbackSortKey: 'organisation.organisation_name',
                sortable: true,
                dataClassName: 'text-center',
            },
        ];

        return columns.filter((col) => !(organisation_category?.name == "MHA" && col.key == "org"));
    };

    /**
     * Used to format the filters into the query params for the team api call
     * @param {object} search Search filters to use
     * @returns {object}
     */
    const formatApiParams = (search) => {
        let apiObj = {};
        const filterKeys = Object.keys(search);

        // eslint-disable-next-line no-cond-assign
        for(let i = 0, filter; filter = filterKeys[i]; i++){
            if(search[filter]?.length > 0){
                apiObj = {
                    ...apiObj,
                    [filter]: search[filter].join(','), // every array will become a string
                };
            }
        }

        return apiObj;
    }

    const getTeamsSearch = (params) => {
        return getTeamsWithPagination(params)
            .then((data) => {
                setPaginationState({ limit: data.limit === 999999 ? -1 : data.limit, count: data.count, total: data.total }) // bypass because api isn't accepting -1 as unlimited right now on this call
                return data.teams;
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
                if(AxiosIsCancelled(error.message))
                    return true;
            })
    }

    useEffect(() => {
        getTeamStatus(undefined, true)
            .then((status) => {
                setTeamStatus(status);
            }).catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    setTeamStatus([]);
                }
            });
    }, []);

    return (
        <SpordlePanelTable
            allowOutsideClick
            dataIndex="team_id"
            sidePanel={(props) => <TeamSearchSidepanel status={teamStatus} formatMessage={formatMessage} {...props} />}
            table={(panelProps) => {
                return (
                    <SpordleTableProvider
                        id='team-search'
                        tableHover bordered striped
                        clickable desktopWhen='sm'
                        pagination={25}
                        dataIndex="team_id"
                        defaultSorting="+category"
                        emptyLayout={<EmptyLayout />}
                        ref={panelProps.spordleTableRef}
                        rowIsHighlighted={(team) => team.checked}
                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                        paginationMessage={'teams.teamSearch.table.paginationMessage'}
                        initFilter={{
                            advancedSearch: {
                                team_category_id: [],
                                status: [],
                                gender: [],
                            },
                        }}
                        searchKeys={[
                            { name: "name", weight: 2 },
                            { name: "short_name", weight: 2 },
                            { name: "unique_identifier", weight: 2 },
                            "abbreviation",
                            `team_category.division.i18n.${genericLocale}.name`,
                            `team_category.division.i18n.${genericLocale}.short_name`,
                            'team_category.division.name',
                            'team_category.division.short_name',
                            `team_category.class.i18n.${genericLocale}.name`,
                            `team_category.class.i18n.${genericLocale}.short_name`,
                            'team_category.class.name',
                            'team_category.class.short_name',
                            'team_category.gender',
                            `organisation.i18n.${genericLocale}.name`,
                            'organisation.organisation_name',
                            `team_status.i18n.${genericLocale}.name`,
                            'team_status.name',
                        ]}
                        loadData={(from, { filters }, { setLoading }) => {
                            switch (from){
                                case 'REFRESH':
                                case 'FILTER':
                                    setLoading();
                                case 'CDM':
                                    return getTeamsSearch({ ...formatApiParams(filters.advancedSearch), limit: paginationState.limit === -1 ? 999999 : paginationState.limit });
                                default:
                                    break;
                            }
                        }}
                        columns={getColumns()}
                        onColumnClick={(e, team, _spordleTable, columnKey) => {
                            switch (e.button){
                                case 0: // Left mouse button
                                    if(columnKey === 'checkbox'){
                                        panelProps.onMultiSelectChange(team);
                                    }else{
                                        panelProps.onSingleSelectChange(team);
                                    }
                                    break;
                            }
                        }}
                        renderRow={(columnKey, team) => {
                            switch (columnKey){
                                case 'checkbox':
                                    return (
                                        <label className='my-auto' htmlFor={'checkbox-' + team.team_id} onClick={(e) => e.stopPropagation()}>
                                            <input
                                                onChange={() => panelProps.onMultiSelectChange(team)}
                                                id={'checkbox-' + team.team_id}
                                                type='checkbox'
                                                checked={!!team.checked}
                                            />
                                            <span className='table-checkbox' />
                                        </label>
                                    )
                                case 'category':
                                    return (
                                        <div>
                                            <UserDisplay.Title>
                                                <DisplayCategory short category={team.team_category} />
                                            </UserDisplay.Title>
                                            <UserDisplay.Subtitle className="text-dark">
                                                <DisplayCategory category={team.team_category} />
                                            </UserDisplay.Subtitle>
                                            {team.team_category?.gender &&
                                                <UserDisplay.Subtitle className="text-dark">
                                                    <Translate id={`form.fields.gender.${team.team_category.gender.toLowerCase()}`} />
                                                </UserDisplay.Subtitle>
                                            }
                                        </div>
                                    )
                                case 'org':
                                    return (
                                        <UserDisplay>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    abbr={team.organisation.organisation_name}
                                                    alt={team.organisation.organisation_name}
                                                    filePos={team.organisation.logo?.file_position}
                                                    src={team.organisation.logo?.full_path}
                                                    width={40}
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>
                                                    <DisplayI18n field="name" i18n={team.organisation.i18n} defaultValue={team.organisation.organisation_name} />
                                                </UserDisplay.Title>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    )
                                case 'team':
                                    return (
                                        <UserDisplay>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    abbr={team.abbreviation || team.short_name || team.name}
                                                    alt={team.name}
                                                    filePos={team.logo?.file_position}
                                                    src={team.logo?.full_path}
                                                    width={40}
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>{team.name}</UserDisplay.Title>
                                                { team.unique_identifier &&
                                                            <Link className="text-nowrap" to={{ pathname: `/teams/profile/${team.team_id}`, data: team }}>#{team.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" /></Link>
                                                }
                                                {/* {!!team.has_pending_deficient && <UserDisplay.Subtitle className="text-danger font-medium"><i className='mdi mdi-close-octagon' /> <Translate id="teams.teamSearch.table.pendingDeficiency" defaultMessage='Pending deficiency' /></UserDisplay.Subtitle>} */}
                                            </UserDisplay.Container>
                                            <UserDisplay.Container className="text-nowrap ml-auto">
                                                {/* This section should contain 1 icons per type
                                                        Ex: if 1 coach has an invalid CRC and another has a CRC in progress, we should only show the red icon

                                                        if crc is invalid */}
                                                {/* {false &&
                                                            <img src={CRC_Red} data-tip="Criminal Record Check Invalid" alt="Criminal record check invalid" width="25"/>
                                                        } */}
                                                {/* if CRC is about to expire (do this after release?) */}
                                                {/* {false &&
                                                            <img src={CRC_Yellow} data-tip="Criminal Record check will soon expire" alt="Certification required" width="25"/>
                                                        } */}

                                                {/* if at least 1 qualification is missing */}
                                                {/* {false &&
                                                            <img src={Certifications_Red} data-tip="Qualification missing" alt="Signed waivers missing" width="25"/>
                                                        } */}

                                                {/* if at least 1 qualification is in progress */}
                                                {/* {false &&
                                                            <img src={Certifications_Yellow} data-tip="Qualification in progress" alt="Signed waivers missing" width="25"/>
                                                        } */}

                                                {/* if at least 1 waiver has not been signed */}
                                                {/* {false &&
                                                            <img src={Waivers_Red} data-tip="Waivers not signed" alt="Signed waivers missing" width="25"/>
                                                        } */}
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    )
                                case 'roster':
                                    return (
                                        <div className="d-table"> {/* d-table instead of Row to make sure the cell stays small */}
                                            {/* <div className="d-table-row">
                                                        <div className="d-table-cell text-nowrap pr-2">
                                                            <span>Male: </span>
                                                            <span className="font-medium">{data.roster.male}</span>
                                                        </div>
                                                        <div className="d-table-cell text-nowrap">
                                                            <span>Female: </span>
                                                            <span className="font-medium">{data.roster.female}</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-table-row">
                                                        <div className="d-table-cell text-nowrap pr-2">
                                                            <span>X: </span>
                                                            <span className="font-medium">{data.roster.x}</span>
                                                        </div>
                                                        <div className="d-table-cell text-nowrap">
                                                            <span>Staff: </span>
                                                            <span className="font-medium">{data.roster.staff}</span>
                                                        </div>
                                                    </div> */}
                                        </div>
                                    )
                                case 'statusDate':
                                    return (
                                        <>
                                            <div>{getStatus(team.team_status)}</div>
                                            <div className="small">
                                                {team.updated_at ? <DateFormat value={team.updated_at} /> : '-'}
                                            </div>
                                        </>
                                    )

                                case 'hasPendingDeficient':
                                    return (
                                        team.has_pending_deficient ? <i className='font-18 text-danger mdi mdi-close-octagon' /> : '-'
                                    )
                                default:
                                    break;
                            }
                        }}
                    >
                        {(spordleTable) => (
                            <div className="mt-sm-n4">
                                <BatchCreationModal toggle={toggleBatchCreateModal} isOpen={batchCreateModalIsOpen} refreshTable={spordleTable.refreshTable} />

                                {/* Top right dropdowns (add team + rollover) */}
                                <div className="d-flex mb-3">
                                    <div className='ml-auto d-flex'>
                                        <HasAccessTo componentCode='settings' componentPermissionCode='team_settings' tag='span'>
                                            <Button color='primary' className='mr-2' tag={Link} to='/settings/team-restrictions'>
                                                <Translate id='teams.teamSearch.addTeam.btn.restrictions' />
                                            </Button>
                                        </HasAccessTo>
                                        <UncontrolledDropdown tag="span">
                                            <CanDoAction componentCode="teams" componentPermissionCode="teams_profile" action="ADD">
                                                <DropdownToggle caret color='primary'>
                                                    <i className='mdi mdi-plus mr-1' />
                                                    <Translate id='teams.teamSearch.addTeam.btn' />
                                                </DropdownToggle>
                                            </CanDoAction>
                                            <DropdownMenu right>
                                                <HasAccessTo componentCode='teams' componentPermissionCode='teams_rollover'>
                                                    <DropdownItem onClick={() => { clearCachedTeam(); history.push('/teams/rollover') }}><Translate id="teams.teamSearch.addTeam.btn.rollover" /></DropdownItem>
                                                </HasAccessTo>
                                                <DropdownItem onClick={() => { clearCachedTeam(); history.push('/teams/create') }}><Translate id="teams.teamSearch.addTeam.btn.newTeam" /></DropdownItem>
                                                <DropdownItem onClick={toggleBatchCreateModal}><Translate id="teams.teamSearch.addTeam.btn.batchCreate" /></DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                    {/* <TeamRollover refreshTable={refreshTable} isOpen={rolloverModal} toggle={toggleRollover} /> */}
                                </div>

                                {/* Advanced search */}
                                <TeamAdvancedSearch status={teamStatus} defaultLimit={defaultLimit} paginationState={paginationState} setPaginationState={setPaginationState} />

                                {/* table */}
                                <Card className="card-shadow">
                                    <CardBody>
                                        <div className='mb-2'>
                                            <div className='d-flex flex-wrap justify-content-between'>
                                                {/* yes we keep this checkbox */}
                                                <div className="d-flex mb-2 mb-sm-0">
                                                    <UncontrolledDropdown className='d-none d-sm-block'>
                                                        <DropdownToggle color='primary' className='d-flex align-items-center mr-2' caret>
                                                            <div className='align-self-center custom-checkbox custom-control'>
                                                                <input id='topCheckID' type='checkbox' className='custom-control-input d-none' checked={panelProps.topChecked} onChange={panelProps.onTopCheckChange} />
                                                                <label className='custom-control-label' htmlFor='topCheckID' />
                                                            </div>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={panelProps.checkAll}><Translate id='misc.all' /></DropdownItem>
                                                            <DropdownItem onClick={panelProps.uncheckAll}><Translate id='misc.none' /></DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    <SearchInput />
                                                </div>
                                                <div>
                                                    <TablePagination paginationArray={[ 25, 50, 100, 200 ]} className="mr-2" />
                                                    <ExportDropdown formatMessage={formatMessage} getData={getTeams} formatData={formatApiParams} />
                                                </div>
                                            </div>
                                        </div>
                                        <SpordleTableView />
                                    </CardBody>
                                </Card>
                            </div>
                        )}
                    </SpordleTableProvider>
                )
            }}
        />
    );
}

const ExportDropdown = ({ getData, formatData, formatMessage }) => {
    const { getFilters } = useSpordleTable();
    const [ isLoading, setLoading ] = useState(false);

    const handleExport = () => {
        setLoading(true);
        getData(formatData(getFilters().advancedSearch))
            .then((teams) => {
                exportTeamsToCSV(teams, formatMessage);
                setLoading(false);
            })
            .catch((e) => {
                if(!AxiosIsCancelled(e.message)){
                    console.error(e.message);
                    setLoading(false);
                }
            });
    }

    return (
        <UncontrolledButtonDropdown className='ml-auto'>
            <DropdownToggle color='primary' outline caret>
                <i className='mdi mdi-download mr-1' /><Translate id='misc.export' />
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem
                    disabled={isLoading}
                    onClick={handleExport}
                >
                    <i className='fas fa-file-excel mr-1' />CSV{isLoading && <Spinner size='sm' color='primary' type='grow' className='float-right' />}
                </DropdownItem>
                <DropdownItem disabled><i className='fas fa-file-excel mr-1' /><Translate id='misc.excel' /></DropdownItem>
                <DropdownItem disabled><i className='fas fa-file-pdf mr-1' /><Translate id='misc.pdf' /></DropdownItem>
                <DropdownItem disabled><i className='fas fa-print mr-1' /><Translate id='misc.print' /></DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    )
}

export default OrgTeamsSearch;