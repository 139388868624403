import Translate from "@spordle/intl-elements";
import moment from "moment";
import { useContext } from "react";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import QualificationsAttendeesListContainer from "./QualificationsAttendeesListContainer";

const TeamRosterSidePanelQualifications = ({ memberId }) => {
    const { getMemberQualifications } = useContext(MembersContext);
    const checkIfExpired = (date) => {
        return !date ? false : moment(date).isBefore(moment().add(1, 'day')); //moment(date).isBefore(moment().add(1, 'day'));
    }

    const sortQualifications = (qualifications) => {
        return qualifications
            .sort((a, b) => {
                const aExp = a.expiration_date;
                const bExp = b.expiration_date;
                if((checkIfExpired(aExp) && aExp) && (checkIfExpired(bExp) && bExp))return aExp < bExp ? -1 : 1
                if(checkIfExpired(aExp) && aExp)return 1
                if(checkIfExpired(bExp) && bExp)return -1

                return a.period.start_date > b.period.start_date ? -1 : a.period.start_date < b.period.start_date ? 1 : 0
            })
    }

    return (
        <QualificationsAttendeesListContainer
            title={<Translate id='teams.profile.roster.sidePanel.qualifications' />}
            getList={(id) => getMemberQualifications(id).then(sortQualifications)}
            memberId={memberId}
            emptyMsg={<Translate id='teams.profile.roster.sidePanel.emptyQualifications' />}
        />
    )
}

export default TeamRosterSidePanelQualifications;