const DocumentIcon = ({ ...props }) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 235.25 235.25">
            <defs>
                <style dangerouslySetInnerHTML={{ __html: ".document-icon-c{fill:#e6f1fe;}.document-icon-d{fill:#3994ff;}.document-icon-e{fill:#545e77;}" }} />
            </defs>
            <rect style={{ fill: "none" }} width="235.25" height="235.25" />
            <path
                className="document-icon-c"
                d="M216.26,115.63v98.55c0,11.61-9.45,21.07-21.07,21.07H87.33c-11.62,0-21.07-9.46-21.07-21.07V68.82c0-11.61,9.45-21.07,21.07-21.07h60.96s.03,.01,.04,.01c.22,0,.43,.01,.65,.03,.11,.01,.22,.03,.33,.05,.11,0,.23,.03,.35,.05s.24,.06,.36,.09c.1,.02,.2,.05,.3,.08,.12,.03,.24,.08,.35,.12,.1,.03,.2,.07,.3,.11,.11,.04,.21,.09,.32,.14l.3,.15c.1,.05,.2,.11,.29,.17l.3,.18c.1,.07,.2,.15,.3,.22,.08,.06,.17,.12,.25,.19,.15,.12,.29,.25,.43,.39,.03,.02,.06,.05,.08,.07l60.97,60.95c.16,.17,.32,.34,.46,.52,.06,.07,.12,.15,.17,.22,.08,.11,.17,.22,.24,.33,.07,.09,.12,.19,.18,.29,.06,.1,.12,.19,.17,.3,.06,.09,.1,.2,.15,.3,.05,.1,.1,.21,.15,.32,.04,.09,.07,.19,.11,.29,.04,.12,.08,.23,.12,.35,.03,.1,.05,.2,.07,.3,.04,.12,.07,.24,.09,.37,.03,.11,.04,.22,.06,.34,.01,.11,.03,.22,.05,.33,.02,.21,.02,.41,.03,.62Z"
            />
            <path
                className="document-icon-e"
                d="M192.48,91.25c-.02-.11-.04-.22-.05-.33-.02-.12-.03-.23-.06-.34-.02-.13-.05-.25-.09-.37-.02-.1-.04-.2-.07-.3-.04-.12-.08-.23-.12-.35-.04-.1-.07-.2-.11-.29-.05-.11-.1-.22-.15-.32-.05-.1-.09-.21-.15-.3-.05-.11-.11-.2-.17-.3-.06-.1-.11-.2-.18-.29-.07-.11-.16-.22-.24-.33-.05-.07-.11-.15-.17-.22-.14-.18-.3-.35-.46-.52l-60.97-60.95s-.05-.05-.08-.07c-.14-.14-.28-.27-.43-.39-.08-.07-.17-.13-.25-.19-.1-.07-.2-.15-.3-.22l-.3-.18c-.09-.06-.19-.12-.29-.17l-.3-.15c-.11-.05-.21-.1-.32-.14-.1-.04-.2-.08-.3-.11-.11-.04-.23-.09-.35-.12-.1-.03-.2-.06-.3-.08-.12-.03-.24-.07-.36-.09-.12-.02-.24-.04-.35-.05-.11-.02-.22-.04-.33-.05-.22-.02-.43-.03-.65-.03-.01,0-.03-.01-.04-.01H63.58c-11.62,0-21.07,9.46-21.07,21.07V190.42c0,11.61,9.45,21.07,21.07,21.07h107.86c11.62,0,21.07-9.46,21.07-21.07V91.87c-.01-.21-.01-.41-.03-.62Zm-60.94-43.36l37.07,37.05h-30c-3.89,0-7.07-3.17-7.07-7.06v-29.99Zm46.97,142.53c0,3.89-3.17,7.07-7.07,7.07H63.58c-3.9,0-7.07-3.18-7.07-7.07V45.06c0-3.89,3.17-7.07,7.07-7.07h53.96v39.89c0,1.45,.15,2.87,.43,4.24,.14,.68,.31,1.35,.52,2.01,.41,1.32,.95,2.58,1.6,3.78,.49,.89,1.04,1.75,1.64,2.56,3.85,5.14,9.99,8.47,16.88,8.47h39.9v91.48Z"
            />
            <path
                className="document-icon-d"
                d="M80.03,129.47c0,3.87,3.13,7,7,7h60.95c3.87,0,7-3.13,7-7s-3.13-7-7-7h-60.95c-3.87,0-7,3.13-7,7Z"
            />
            <path
                className="document-icon-d"
                d="M129.24,155.28h-42.2c-3.87,0-7,3.13-7,7s3.13,7,7,7h42.2c3.87,0,7-3.13,7-7s-3.13-7-7-7Z"
            />
        </svg>
    )
}

export default DocumentIcon;