import { useDisclosure } from "@mantine/hooks";
import { useSpordleTable } from "@spordle/datatables";
import { useContext, useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import EditColumnsModal from "../../views/reports/reportEngine/tableComponents/manageColumns/EditColumnsModal";
import IsGod from "../permissions/IsGod";
import ColumnDefineModal from "./ColumnDefineModal";
import { parse } from 'query-string';
import IsDev from "../permissions/IsDev";
import { ReportsContext } from "../../contexts/ReportsContext";
import { Tooltip } from "@mantine/core";
import Translate from "@spordle/intl-elements";

const ColumnDefineButton = ({ disabled }) => {
    const { currentReport } = useContext(ReportsContext);
    const [ isOpen, isOpenHandlers ] = useDisclosure(false);
    const [ columnDefineOpen, setColumnDefineOpen ] = useState(false);
    const [ availableColumns, setAvailableColumns ] = useState([]);
    const { generateId } = useSpordleTable();
    const isCustomReport = currentReport.report_id ? !!currentReport.custom_report_id : !!parse(location.search).custom_report_id

    return (
        <IsGod tooltipOff className="d-inline-block" tag={UncontrolledDropdown}>
            <Tooltip
                withArrow
                label={<Translate id='reports.customize.columns' />}
            >
                <DropdownToggle
                    caret
                    disabled={disabled}
                    color='purple'
                    outline
                    className='mr-2'
                >
                    <i className='mdi mdi-settings' />
                </DropdownToggle>
            </Tooltip>
            <DropdownMenu right>
                <DropdownItem className="text-purple" id={generateId('ColumnDefineButton')} onClick={() => { setColumnDefineOpen(true) }}>Define columns</DropdownItem>
                <IsDev className="w-100">
                    <DropdownItem className="text-dev" disabled={isCustomReport} id={generateId('ColumnReorderButton')} onClick={isOpenHandlers.open}>
                        Reorder columns
                        {isCustomReport && <div className="small">Cannot reorder a custom report&apos;s columns.</div>}
                    </DropdownItem>
                </IsDev>
            </DropdownMenu>
            <EditColumnsModal
                report={currentReport}
                isOpen={isOpen}
                toggle={isOpenHandlers.close}
            />
            <ColumnDefineModal
                setColumnDefineOpen={setColumnDefineOpen}
                columnDefineOpen={columnDefineOpen}
                availableColumns={availableColumns}
                setAvailableColumns={setAvailableColumns}
            />
        </IsGod>
    )
}

export default ColumnDefineButton;