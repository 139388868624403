import { useContext, useEffect, useState } from 'react';
import { FormikInputNumber, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate, { CurrencyFormat } from "@spordle/intl-elements";
import { Formik, Form } from "formik";
import { Alert, Button, Col, FormGroup, FormText, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { object, number, string } from 'yup';
import OverlayLoader from '../../../../../../../components/loading/OverlayLoader';
import Required from '../../../../../../../components/formik/Required';
import { UtilsContext } from '../../../../../../../contexts/UtilsContext';
import { DisplayI18n } from '../../../../../../../helpers/i18nHelper';
import { fail } from '@spordle/toasts';
import FormikTiny from '../../../../../../../components/formik/FormikTiny';

// Contexts
import { I18nContext } from "../../../../../../../contexts/I18nContext";
import { MembersContext } from '../../../../../../../contexts/MembersContext';
import { AxiosIsCancelled } from '../../../../../../../api/CancellableAPI';
import { RefundModalContext } from './OnlineStoreTransactionRefundModal';

const RefundModalRefundInformation = (props) => {

    const memberContext = useContext(MembersContext);
    const utilsContext = useContext(UtilsContext);
    const refundModalContext = useContext(RefundModalContext);

    const [ currentRefundAmounts, setCurrentRefundAmounts ] = useState({ 'payment_gateway': 0, 'manual': 0 });
    const [ currentPaymentMethod, setCurrentPaymentMethod ] = useState(refundModalContext.state.transaction.invoice_payment_method?.code);

    useEffect(() => {
        memberContext.getRefundAmounts(refundModalContext.state.transaction.invoice_number)
            .then((refundAmounts) => {
                setCurrentRefundAmounts(refundAmounts);
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, [ refundModalContext.state.transaction.invoice_number ])

    return (
        <Formik
            initialValues={{
                amount: '',
                payment_method: refundModalContext.state.transaction.invoice_payment_method?.code,
                internal_note: '',
                external_note: '',
                invoice_payment_id: refundModalContext.state.invoicePaymentId,
            }}
            validationSchema={object().shape({
                amount: number()
                    .positive(<Translate id='onlineStore.profile.transactions.modals.refundModal.form.validation.refundAmount.positive' />)
                    .max((currentPaymentMethod === 'CREDITCARD' ? currentRefundAmounts.payment_gateway : currentRefundAmounts.manual) / 100, <Translate id='onlineStore.profile.transactions.modals.refundModal.form.validation.refundAmount.max' values={{ paidAmount: <CurrencyFormat value={(currentPaymentMethod === 'CREDITCARD' ? currentRefundAmounts.payment_gateway : currentRefundAmounts.manual) / 100} /> }} />)
                    .required(<Translate id='onlineStore.profile.transactions.modals.refundModal.form.validation.refundAmount.required' />),
                payment_method: string().required(<Translate id='onlineStore.profile.transactions.modals.refundModal.form.validation.paymentMethod.required' />),
            })}
            onSubmit={(values, { setSubmitting, setStatus }) => {
                memberContext.refundInvoice(refundModalContext.state.transaction.invoice_number, { ...values, amount: Math.round(values.amount * 100) })
                    .then((refundId) => {
                        refundModalContext.handleOnRefund();
                        refundModalContext.setIsRefunded(true);
                        setSubmitting(false);
                        refundModalContext.goToView(refundModalContext.views.confirmation) // Removed cancel installments view
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                            setSubmitting(false);
                        }
                    })
            }}
        >
            {(formik) => (
                <OverlayLoader isLoading={formik.isSubmitting}>
                    <Form>
                        <ModalBody>
                            <Row>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Label for='amount'>
                                            <Translate id='onlineStore.profile.transactions.modals.refundModal.form.refundAmount' /> <Required />
                                        </Label>
                                        <I18nContext.Consumer>
                                            {({ getGenericLocale }) => (
                                                <FormikInputNumber
                                                    helper={
                                                        <div className="small text-muted">
                                                            <Translate id='onlineStore.profile.transactions.modals.refundModal.form.refundAmount.helper' />:{' '}
                                                            <button type="button" onClick={() => formik.setFieldValue('amount', (formik.values.payment_method === 'CREDITCARD' ? currentRefundAmounts.payment_gateway : currentRefundAmounts.manual) / 100)} className="reset-btn text-primary">
                                                                <CurrencyFormat
                                                                    value={(formik.values.payment_method === 'CREDITCARD' ? currentRefundAmounts.payment_gateway : currentRefundAmounts.manual) / 100} // this is the amount
                                                                />
                                                            </button>
                                                        </div>
                                                    }
                                                    allowLeadingZeros
                                                    fixedDecimalScale
                                                    thousandSeparator=' '
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    name="amount"
                                                    id="amount"
                                                    decimalSeparator={getGenericLocale() === 'fr' ? ',' : '.'}
                                                    suffix={getGenericLocale() === 'fr' ? ' $' : ''}
                                                    prefix={getGenericLocale() !== 'fr' ? '$ ' : ''}
                                                />
                                            )}
                                        </I18nContext.Consumer>
                                    </FormGroup>
                                </Col>
                                <Col sm='6'>
                                    <FormGroup>
                                        <Label><Translate id='onlineStore.profile.transactions.modals.refundModal.form.paymentMethod' /> <Required /></Label>
                                        <FormikSelect
                                            id='payment_method_refund_modal'
                                            name='payment_method'
                                            search={false}
                                            onOptionSelected={(method) => { setCurrentPaymentMethod(method[0]) }}
                                            loadData={(from) => {
                                                switch (from){
                                                    case 'CDM':
                                                    case 'FILTER':
                                                        return utilsContext.getPaymentMethods().then((paymentMethods) => {
                                                            return (paymentMethods.map((pMethod, index) => {
                                                                return {
                                                                    id: pMethod.code,
                                                                    value: pMethod.code,
                                                                    label: pMethod.code,
                                                                    i18n: pMethod.i18n,
                                                                    selected: refundModalContext.state.transaction.invoice_payment_method?.code == pMethod.code,
                                                                }
                                                            }))
                                                        })
                                                    default:
                                                        break;
                                                }
                                            }}
                                            renderOption={(option) => (<DisplayI18n field='name' defaultValue={option.option.name} i18n={option.option.i18n} />)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <FormikTextArea name='internal_note' id='refund-internal_note' trim rows='2' label='onlineStore.profile.transactions.modals.refundModal.form.internalNote' helper='onlineStore.profile.transactions.modals.refundModal.form.internalNote.helper' />
                            </FormGroup>
                            <div className="mb-3">
                                <Label for='refund-external_note'><Translate id='onlineStore.profile.transactions.modals.refundModal.form.externalNote' /></Label>
                                <FormikTiny name='external_note' id='refund-external_note' init={{ height: 150, selector: 'textarea' }} />
                                <FormText><Translate id='onlineStore.profile.transactions.modals.refundModal.form.externalNote.helper' /></FormText>
                            </div>
                            {formik.status &&
                                <Alert color='danger'>{formik.status}</Alert>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" color="primary" disabled={formik.isSubmitting}>
                                <Translate id='onlineStore.profile.transactions.modals.refundModal.btn.refund' />
                            </Button>
                            <Button type="button" color="primary" onClick={refundModalContext.handleToggle} outline disabled={formik.isSubmitting}><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </Form>
                </OverlayLoader>
            )}
        </Formik>
    )
}

export default RefundModalRefundInformation;
