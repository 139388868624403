import { useContext, useEffect, useState } from "react";
import { AxiosIsCancelled } from "../../../../api/CancellableAPI";
import { fail } from "@spordle/toasts";
import { InjuriesContext } from "../../../../contexts/InjuriesContext";
import { UtilsContext } from "../../../../contexts/UtilsContext";
import { DisplayI18n } from "../../../../helpers/i18nHelper";
import { getOtherNeededIds } from "./InsuranceHelper";

/**
 * @typedef {Object} Lists
 * @prop {object[]} absences
 * @prop {object[]} bodyParts
 * @prop {object[]} causes
 * @prop {object[]} conditions
 * @prop {object[]} conditions_w_other
 * @prop {object[]} equipments
 * @prop {object[]} environments
 * @prop {object[]} environments_w_other
 * @prop {object[]} natures
 * @prop {object[]} onSiteCares
 * @prop {object[]} payTos
 * @prop {object[]} payTos_w_other
 * @prop {object[]} status
 * @prop {object[]} provinces Canadian Provinces
 */

/**
 * @hook
 * @description hooks shared by task insurance && member insurance
 * @returns {Lists}
 */
export function useInsuranceContent(){
    const injuryContext = useContext(InjuriesContext);
    const { getCountries } = useContext(UtilsContext);
    const [ data, setData ] = useState({
        causes: [],
        conditions: [],
        conditions_w_other: [],
        equipments: [],
        natures: [],
        onSiteCares: [],
        payTos: [],
        payTos_w_other: [],
        bodyParts: [],
        absences: [],
        environments: [],
        environments_w_other: [],
        status: [],
        provinces: [],
    });

    const sortResults = (list) => list.sort((a, b) => parseInt(a.display_order) - parseInt(b.display_order));

    const itemIsActive = (item) => item.active == 1;

    /**
     * @param {array} list
     * @returns {array}
     */
    const sortAndFilterResults = (list) => {
        return sortResults(list).filter(itemIsActive);
    }

    useEffect(() => {
        Promise.all([
            injuryContext.getInjuryCauseList(null, { active: 1 }),
            injuryContext.getInjuryConditionList(null, { active: 1 }),
            injuryContext.getInjuryEquipmentList(null, { active: 1 }),
            injuryContext.getInjuryNatureList(null, { active: 1 }),
            injuryContext.getInjuryOnSiteCareList(null, { active: 1 }),
            injuryContext.getInjuryPayToList(null, { active: 1 }),
            injuryContext.getBodyPartsList(null, { active: 1 }),
            injuryContext.getInjuryAbsenceList(null, { active: 1 }),
            injuryContext.getInjuryEnvironmentList(null, { active: 1 }),
            injuryContext.getInjuryStatus(null, { active: 1 }),
            getCountries({ country_code: 'CA' }),
        ])
            .then((promises) => {
                const env = sortAndFilterResults(promises[8]);
                const cond = sortAndFilterResults(promises[1]);
                const pay = sortAndFilterResults(promises[5]);
                const provinces = promises[10].find((country) => country.code === 'CA')?.sub_divisions;

                // All in one to prevent useless rerenders
                setData({
                    causes: sortAndFilterResults(promises[0]),
                    conditions: cond,
                    conditions_w_other: getOtherNeededIds(cond, 'injury_condition_id'),
                    equipments: sortAndFilterResults(promises[2]),
                    natures: sortAndFilterResults(promises[3]),
                    onSiteCares: sortAndFilterResults(promises[4]),
                    payTos: pay,
                    payTos_w_other: getOtherNeededIds(pay, 'injury_pay_to_id'),
                    bodyParts: sortAndFilterResults(promises[6]),
                    absences: sortAndFilterResults(promises[7]),
                    environments: sortAndFilterResults(promises[8]),
                    environments_w_other: getOtherNeededIds(env, 'injury_environment_id'),
                    status: sortAndFilterResults(promises[9]),
                    provinces: provinces || [],
                });
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }, []);

    return data;
}