import SpordleTableProvider, { SpordleTableView } from '@spordle/datatables';
import Translate, { CurrencyFormat } from '@spordle/intl-elements';
import { useContext, useState } from 'react';
import {
    Card,
    CardBody,
    Button,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import SpordlePanelTable from '../../../components/sidePanel/SpordlePanel';
import UserDisplay from '../../../components/userDisplay/UserDisplay';
import ClinicItemsSidepanel from './components/ClinicItemsSidepanel';
import ClinicItemsAdd from './components/ClinicItemsAdd';
import { ClinicsContext } from '../../../contexts/ClinicsContext';

import I18nHelperContextProvider, { DisplayI18n, I18nHelperContext } from '../../../helpers/i18nHelper';
import { I18nContext } from '../../../contexts/I18nContext';
import NoImgItem from '../../../assets/images/NoImage_Item.svg'
import EmptyLayout from '../../../components/table/EmptyLayout';
import UserImg from '../../../components/UserImg';
import CanDoAction from '../../../components/permissions/CanDoAction';
import ViewHeaderV2 from '../../../components/viewHeader/ViewHeaderV2';

const ClinicItems = () => {
    const [ modalAdd, setModalAdd ] = useState(false);
    const toggleModalAdd = () => setModalAdd(!modalAdd);

    const clinicContext = useContext(ClinicsContext);
    const i18nContext = useContext(I18nContext);

    return (
        <I18nHelperContextProvider fields={[ 'name', 'description' ]}>
            <I18nHelperContext.Consumer>
                {(i18nHelper) => (
                    <>
                        <ViewHeaderV2
                            title='catalog.clinicItems.title'
                            breadcrumbsProps={{
                                disablePeriodSelect: true,
                            }}
                        />

                        <div className={"px-4"}>
                            <Card className="card-shadow">
                                <CardBody>
                                    <SpordlePanelTable
                                        allowOutsideClick
                                        sidePanel={(props) => <ClinicItemsSidepanel {...props} />}
                                        dataIndex='fee_id'
                                        table={(panelProps) => {
                                            return (
                                                <SpordleTableProvider
                                                    id={'CatalogClinicItemsTable'}
                                                    tableHover bordered striped
                                                    clickable
                                                    ref={panelProps.spordleTableRef}
                                                    desktopWhen
                                                    pagination={20}
                                                    tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                                                    searchKeys={[
                                                        `i18n.${i18nContext.getGenericLocale()}.name`,
                                                        'name',
                                                    ]}
                                                    dataIndex='fee_id'
                                                    loadData={(from, filterData, spordleTable) => {
                                                        switch (from){
                                                            case 'REFRESH':
                                                                spordleTable.setLoading(true);
                                                            case 'CDM':
                                                                return clinicContext.getClinicItems({ type: 'CLINIC' }).catch(console.error);
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                    columns={[
                                                        {
                                                            label: '-',
                                                            key: 'image',
                                                            sortable: false,
                                                            dataClassName: 'text-center',
                                                        },
                                                        ...i18nHelper.getTableColumns('name'),
                                                        // {
                                                        //     label: 'SKU',
                                                        //     key: 'sku',
                                                        //     sortable: true
                                                        // },
                                                        {
                                                            label: <Translate id='catalog.clinicItems.table.column.status.label' />,
                                                            key: 'active',
                                                            dataClassName: 'text-center',
                                                            sortable: true,
                                                        },
                                                        {
                                                            label: <Translate id='catalog.clinicItems.table.column.price.label' />,
                                                            key: 'amount',
                                                            sortable: true,
                                                        },
                                                        {
                                                            label: <Translate id='catalog.clinicItems.table.column.total_sold' />,
                                                            key: 'total_sold',
                                                            sortable: true,
                                                        },
                                                    ]}
                                                    renderRow={(columnKey, fee) => {
                                                        if(columnKey.includes('i18n')){
                                                            const lang = columnKey.substring(0, columnKey.lastIndexOf('.')); // Get column key lang for i18n helper
                                                            return (
                                                                <UserDisplay>
                                                                    <UserDisplay.Container>
                                                                        <UserDisplay.Title>
                                                                            <DisplayI18n
                                                                                field={columnKey}
                                                                                defaultValue={fee.name}
                                                                                i18n={fee.i18n}
                                                                            />
                                                                        </UserDisplay.Title>
                                                                        <UserDisplay.Title className='text-muted'>
                                                                            <DisplayI18n
                                                                                field={`${lang}.description`}
                                                                                defaultValue={fee.description}
                                                                                i18n={fee.i18n}
                                                                            />
                                                                        </UserDisplay.Title>
                                                                    </UserDisplay.Container>
                                                                </UserDisplay>
                                                            )
                                                        }
                                                        switch (columnKey){
                                                            case 'image':
                                                                return (
                                                                    <UserDisplay.Container>
                                                                        <UserImg
                                                                            className="rounded-lg border shadow-none"
                                                                            width={60}
                                                                            src={fee.img || NoImgItem}
                                                                            // filePos={data.image?.file_position}
                                                                            alt={fee.name}

                                                                        />
                                                                    </UserDisplay.Container>
                                                                )
                                                            case 'description':
                                                                return (fee.description.length > 15 ? fee.description.substring(0, 100) + '...' : fee.description || '-')
                                                            case 'active':
                                                                return fee.active === '1' ? <i className="text-primary mdi mdi-check" /> : <i className="text-danger mdi mdi-close" />;
                                                            case 'amount':
                                                                return <CurrencyFormat value={fee.amount / 100} currencyDisplay='narrowSymbol' />
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                    onColumnClick={(e, fee) => {
                                                        switch (e.button){
                                                            case 0: // Left mouse button
                                                                panelProps.onSingleSelectChange(fee);
                                                                break;
                                                        }
                                                    }}
                                                    rowIsHighlighted={(fee) => panelProps.selectedRows[0]?.fee_id === fee.fee_id}
                                                    emptyLayout={<EmptyLayout />}
                                                >
                                                    <div className='mb-2'>
                                                        <div className='d-flex flex-wrap justify-content-between'>
                                                            <SpordleTableProvider.SearchInput />
                                                            <div className='d-flex ml-auto text-right nowrap'>
                                                                <SpordleTableProvider.Refresh />
                                                                <UncontrolledButtonDropdown className='ml-2'>
                                                                    <DropdownToggle color='primary' outline caret disabled>
                                                                        <i className='mdi mdi-download mr-1' /><Translate id='misc.export' />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem><i className='fas fa-file-excel mr-1' /><Translate id='misc.excel' /></DropdownItem>
                                                                        <DropdownItem><i className='fas fa-file-pdf mr-1' /><Translate id='misc.pdf' /></DropdownItem>
                                                                        <DropdownItem><i className='fas fa-print mr-1' /><Translate id='misc.print' /></DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>

                                                                <CanDoAction action='ADD' componentCode='catalog' componentPermissionCode='qualification_fees'>
                                                                    <ClinicItemsAdd isOpen={modalAdd} toggle={toggleModalAdd} />
                                                                    <Button className='ml-2' color='primary' onClick={toggleModalAdd}><i className='mdi mdi-plus mr-1' /><Translate id='misc.add' /></Button>
                                                                </CanDoAction>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <SpordleTableView />
                                                </SpordleTableProvider>
                                            )
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </div>
                    </>
                )}
            </I18nHelperContext.Consumer>
        </I18nHelperContextProvider>
    );
}

export default ClinicItems;