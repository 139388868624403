import { useDisclosure } from "@mantine/hooks";
import SpordleTableProvider, { Refresh, SearchInput, SpordleTableView } from "@spordle/datatables";
import Translate, { DateFormat } from "@spordle/intl-elements";
import moment from "moment";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Card } from "reactstrap";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import SpordlePanelControl from "../../../components/sidePanel/SpordlepanelControl";
import TablePagination from "../../../components/table/TablePagination";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import { I18nContext } from "../../../contexts/I18nContext";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import MemberPermanentReleaseRequestsSidePanel from "./sidePanel/MemberPermanentReleaseRequestsSidePanel";

const MemberPermanentReleaseRequests = ({ changeRequestStatuses, ...props }) => {
    const i18nContext = useContext(I18nContext);

    const [ isModalOpen, isModalOpenHandlers ] = useDisclosure(false);

    return (
        <SpordlePanelTable
            allowOutsideClick
            sidePanel={(props) => (
                <MemberPermanentReleaseRequestsSidePanel
                    {...props} isModalOpen={isModalOpen} isModalOpenHandlers={isModalOpenHandlers}
                    changeRequestStatuses={changeRequestStatuses}
                />
            )}
            dataIndex='permanent_release_request_id'
            table={(panelProps) => {
                return (
                    <SpordleTableProvider
                        id='memberChangeRequestList'
                        tableHover bordered striped
                        clickable
                        ref={panelProps.spordleTableRef}
                        desktopWhen
                        dataIndex='permanent_release_request_id'
                        pagination={25}
                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : undefined}
                        defaultSorting='+created_at'
                        onColumnClick={(e, data, _spordleTable, columnKey) => {
                            switch (e.button){
                                case 0: // Left mouse button
                                    if(columnKey === 'checkbox'){
                                        panelProps.onMultiSelectChange(data);
                                    }else{
                                        panelProps.onSingleSelectChange(data);
                                    }
                                    break;
                            }
                        }}
                        searchKeys={[
                            `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                            `member.last_name`,
                            `member.first_name`,
                            `member.birthdate`,
                            `member.unique_identifier`,
                            `member.email`,
                            'created_at',
                            'created_by.email',
                            'created_by.family_name',
                            'created_by.name',
                            'submission_date',
                            `organisation_status.i18n.${i18nContext.getGenericLocale()}.name`,
                        ]}
                        loadData={(from, { filters }, spordleTable) => {
                            switch (from){
                                case 'REFRESH':
                                case 'CDM':
                                    spordleTable.setLoading();
                                    return props.getData();
                                default:
                                    break;
                            }
                        }}
                        columns={[
                            {
                                // yes keep this
                                label: '-',
                                key: 'checkbox',
                                className: 'th-shrink',
                                dataClassName: 'td-shrink',
                            },
                            {
                                label: <Translate id='task.unconfirmedOrg.sidepanel.submission.date' />,
                                key: "created_at",
                                mobile: true,
                                sortable: true,
                            },
                            {
                                label: <Translate id='task.mandatoryChangesRequests.row.request.requested_by' />,
                                key: "requested_by",
                                mobile: true,
                                sortable: true,
                                sortKey: 'created_by.family_name',
                            },
                            {
                                label: <Translate id='members.search.memberSearch.table.organization' />,
                                key: "organization",
                                mobile: true,
                                sortable: true,
                                sortKey: `organisation.i18n.${i18nContext.getGenericLocale()}.name`,
                                fallbackSortKey: 'organisation.organisation_name',
                            },
                            {
                                label: <Translate id='members.search.memberSearch.table.member' />,
                                key: "last_name",
                                mobile: true,
                                sortable: true,
                                sortKey: 'member.last_name',
                            },
                            {
                                label: <Translate id='form.fields.dateOfBirth' />,
                                key: "birthdate",
                                mobile: true,
                                sortable: true,
                                sortKey: 'member.birthdate',
                            },
                            {
                                label: <Translate id='form.fields.status' />,
                                key: "status",
                                mobile: true,
                                sortable: true,
                            },
                        ]}
                        renderRow={(key, member, spordleTable) => {
                            switch (key){
                                case 'checkbox':
                                    return (
                                        <label className='my-auto' htmlFor={'checked-' + member.permanent_release_request_id} onClick={(e) => { e.stopPropagation() }}>
                                            <input
                                                id={'checked-' + member.permanent_release_request_id} type="checkbox" checked={!!member.checked}
                                                onClick={() => { panelProps.onMultiSelectChange(member); }}
                                                onChange={function(){}}// This needs to be here to remove the console error
                                            />
                                            <span className="table-checkbox" />
                                        </label>
                                    )
                                case 'created_at':
                                    return moment(member.created_at).format("YYYY-MM-DD HH:mm")
                                case 'organization':
                                    return (member.organisation ?
                                        <div>
                                            <DisplayI18n
                                                field='name'
                                                defaultValue={member.organisation.organisation_name}
                                                i18n={member.organisation.i18n}
                                            />
                                        </div>

                                        : '-'
                                    )
                                case 'requested_by':
                                    if(member.created_by)
                                        return (
                                            <div className="">
                                                <div className="mb-0">{member.created_by.name + ' ' + member.created_by.family_name}</div>
                                                <a href={`mailto:${member.created_by.email}`}>{member.created_by.email}</a>
                                            </div>
                                        )
                                    return ' - '
                                case 'last_name':
                                    return <MemberLastNameRender member={member.member} row={member} />
                                case 'birthdate':
                                    return (
                                        member.member.birthdate ?
                                            <div>
                                                {member.member.birthdate && <div><DateFormat value={member.member.birthdate} utc /></div>}
                                                {member.member.age &&
                                                        <div className="text-muted small"><Translate id='misc.yearsOld' values={{ age: member.member.age || 0 }} /></div>
                                                }
                                            </div>
                                            : '-'
                                    )
                                case 'status':
                                    // hardcoded because this API call only returns pending member change requests
                                    return <Badge color={"warning"}><DisplayI18n defaultValue={member.request_change_status.name} field='name' i18n={member.request_change_status.i18n} /></Badge>
                                default:
                                    break;
                            }
                        }}
                    >
                        {(spordleTable) => (
                            <Card body className="card-shadow">
                                <div className="mb-2">
                                    <div className='d-flex flex-wrap justify-content-between mb-2'>
                                        <SpordlePanelControl />
                                        <SearchInput />
                                        <div className='d-flex ml-auto'>
                                            {spordleTable.getData().length > 0 &&
                                                    <Button
                                                        color='primary'
                                                        onClick={async() => {
                                                            await panelProps.checkAllPages()
                                                            isModalOpenHandlers.toggle()
                                                        }}
                                                    >
                                                        <Translate id='task.mandatoryChangesRequests.manage.all' />
                                                    </Button>
                                            }
                                            <TablePagination className='ml-2' paginationArray={[ 25, 50, 100 ]} />
                                            <Refresh className='ml-2' />
                                        </div>
                                    </div>
                                </div>
                                <SpordleTableView />
                            </Card>
                        )}
                    </SpordleTableProvider>
                )
            }}
        />
    );
};

const MemberLastNameRender = ({ member, row }) => {
    return (
        <UserDisplay>
            <UserDisplay.Container className='w-100'>
                <UserDisplay.Title className='d-flex align-items-start justify-content-between'>
                    {member.last_name} {member.first_name}
                </UserDisplay.Title>
                {member.unique_identifier ?
                    // send data without member types because the member types from search and GET specific are different formats
                    <Link className="text-nowrap" to={{ pathname: `/members/profile/${row.member_id}`, data: { ...member, member_types: [] } }}>
                        #{member.unique_identifier}<i className="ml-1 mdi mdi-chevron-right" />
                    </Link>
                    :
                    <Link to={`/members/profile/${row.member_id}`} className='mr-2'>
                        <Translate id='misc.goToProfile' /><i className="mdi mdi-chevron-right ml-1" />
                    </Link>
                }
                <UserDisplay.Subtitle>
                    <Translate id={'members.search.memberSearch.table.gender.' + member.gender?.toUpperCase() || 'PREFER_NOT_TO_SAY'} />
                </UserDisplay.Subtitle>
            </UserDisplay.Container>
        </UserDisplay>
    )
}


export default MemberPermanentReleaseRequests;
