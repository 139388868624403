import React, { createContext } from 'react';
import API_SPORDLE, { apiUrlEnv } from '../api/API-Spordle';
import API_PUBLIC from '../api/API-Public';
import { serverError } from '../api/CancellableAPI';
import queryString, { stringifyUrl } from 'query-string';
import withContexts from '../helpers/withContexts';
import { IdentityRolesContext } from './IdentityRolesContext';
import { AuthContext, PeriodsContext, AppContext } from './contexts';
import { jsObjectToApi } from '@spordle/helpers';
import { setContext, setTag } from '@sentry/react';

/** @type {React.Context<Omit<OrganizationContextProvider, keyof React.ComponentLifecycle<*, *> | 'render' | 'setState'>> & OrganizationContextProvider['state']} */
export const OrganizationContext = createContext();
OrganizationContext.displayName = 'OrganizationContext';

export const localStorageOrganizationId = `${apiUrlEnv}-organizationId`;

////////////////////////////////////////////////////////////////////////////////////
// Sections (CTRL + F)
// - Postal Codes
// - Venues
// - Organisation Terms
// - Organisation Transfers
// - Organization Contact Types
// - Organization Contacts
// - Organization Discriminations
// - Payees
// - Organization grouping
////////////////////////////////////////////////////////////////////////////////////

class OrganizationContextProvider extends React.Component{
    /*
    State will look like this:
    {
        abbreviation: "RE"
        active: "1"
        address: ""
        city: ""
        country_id: null
        created_at: "2020-11-23 09:46:44"
        fax: null
        identification_number: "",
        logo: {
            attachement_id: '123123',
            full_path: 'https://123123.com'
        },
        organisation_category: {
            organisation_category_id: "1eb19495-611e-65b8-9eec-e454e8be9b90",
            name: "Association / Club"
        }
        name: "Association / Club"
        organisation_category_id: "1eb19495-611e-65b8-9eec-e454e8be9b90"
        organisation_category_id: "5"
        organisation_email: null
        organisation_id: "1eb2d9ab-49d4-6fb8-866b-0ec110310a18"
        organisation_id_parent: "1eb1f975-74e6-6376-9b74-e454e80c8447"
        organisation_name: "Région Estrie"
        origin: "ADMIN"
        phone: ""
        province_id: ""
        short_url: "t3"
        updated_at: "2020-12-14 10:17:54"
        visible_on_website: "0"
        website: ""
        with_sub_organisation: "1"
        zip_code: ""
    }
    */

    /*state = {
        abbreviation: "SID",
        active: "1",
        address: null,
        city: null,
        country_id: null,
        created_at: "2020-11-17 11:46:19",
        fax: null,
        identification_number: "123456789",
        logo: {
            attachement_id: '123123',
            full_path: 'https://123123.com'
        },
        organisation_category: [],
        organisation_category_id: null,
        organisation_email: "infos@hockeycanada.ca",
        organisation_id: "041ed146-8a8a-40f5-8d82-bb9967cfeb42",
        organisation_id_parent: null,
        organisation_name: "Spordle ID",
        origin: "ADMIN",
        phone: null,
        province_id: null,
        short_url: "hcr",
        updated_at: null,
        visible_on_website: "1",
        website: null,
        with_sub_organisation: "1",
        zip_code: null
    }*/
    constructor(props){
        super(props);
        this.state = window.frameElement && window.frameElement?.getAttribute('organizationcontext') ? JSON.parse(window.frameElement?.getAttribute('organizationcontext')) : {}
    }

    componentDidUpdate(){
        setContext('organization', this.state?.organisation_id ? {
            organizationId: this.state.organisation_id,
            organizationName: this.state.organisation_name,
            languages: this.state.settings?.language.value.toString(),
        } : null);
        setTag('organizationId', this.state?.organisation_id);
    }

    /**
     * @type {?Array}
     * @description Cached organization tree
     */
    orgTree = null;

    /**
     * @type {?Array}
     * @description Cached organization tree
     */
    orgTreePublic = null;

    /**
     * @type {?Array}
     * @description Cached organization branch
     */
    orgBranch = null;

    /**
     * @type {?Array}
     * @description Cached organization branch
     */
    orgRegion = null;

    /**
     * @type {?Array}
     * @description Cached organization categories
     */
    orgCategories = null;

    /**
     * @typedef {object} Setting
     * @property {string} code
     * @property {string} name
     * @property {Record<string, any>} i18n
     * @property {any} response
     */
    /**
     * Formats the settings info from the api to our JS structure
     * @param {Setting[]} settings
     * @returns {object}
     * @private
     */
    _formatSettings = (settings) => {
        return Array.isArray(settings) ? settings.reduce((formattedSettings, setting) => {
            if(setting.code === 'language' && !Array.isArray(setting.response)){
                setting.response = [ setting.response ];
            }

            if(setting.code === 'language' && setting.response.length === 0){ // No languages set
                setting.response = [ 'en' ];// Defaults to english
            }

            formattedSettings[setting.code] = {
                value: setting.response,
                name: setting.name,
                i18n: setting.i18n,
                ressource: setting.ressource,
                period: setting.period,
                can_manage: setting.can_manage,
                organisation: setting.organisation,
            }
            return formattedSettings;
        }, {}) : { language: { value: [ 'en' ], name: 'Languages' } };
    }

    /**
     * Will be used inside the QuickView Iframe
     * @param {orgId} orgId
     * @returns {Promise}
     */
    setCurrentState = (state) => this.setState(() => ({ ...state }));

    /**
     * Set the new organization
     * @param {orgId} orgId
     * @returns {Promise}
     */
    setCurrentOrg = (orgId, getParams = {}) => {
        return this.getOrganization(orgId, getParams)
            .then((orgData) => {
                return this.getOrganizationSettings(orgId)
                    .then(async(settings) => {
                        const additionalFieldsComponents = await this.getAdditionalFieldsComponents(orgId);
                        const mandatoryMemberFields = await this.getMemberMandatoryFields();
                        this.setState((prevState) => ({ ...prevState, ...orgData, settings: settings, additionalFieldsComponents: additionalFieldsComponents, mandatoryMemberFields: mandatoryMemberFields }));
                        return orgData.organisation_id;
                    })
            })
    }

    /**
     * Get organisation tree with sub-organisation
     * @param {boolean} [fromCurrent=false] Will use the organisation id from the currently managed organisation
     * @param {boolean} [fromApi=false] Will fetch the data from the api. Consider this param as an update of the cached orgTree
     * @param {boolean} [onlyActive=false] Will only fetch the active organizations
     * @param {boolean} [orgId] Fetches any organizations below this parent organization
     * @returns {Promise}
     */
    getOrganizationsTree = (fromCurrent = false, fromApi = false, onlyActive = false, orgId) => {
        // If we have the orgTree in cache AND we don't to update the orgTree from the api
        if(Array.isArray(this.orgTree) && !fromApi){
            return Promise.resolve(this.orgTree);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `organisations/${orgId || (fromCurrent ? this.state.organisation_id : this.props.IdentityRolesContext.organisation.organisation_id)}/tree`,
            query: {
                active: onlyActive ? 1 : null,
            },
        }, {
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return this.orgTree = response.data.organisations;
                }
                this.orgTree = null;
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Get organisation tree FROM THE PUBLIC API with sub-organisation, we use the federation ID to receive the complete tree
     * @param {boolean} [fromApi=false] Will fetch the data from the api. Consider this param as an update of the cached orgTree
     * @param {boolean} [onlyActive=false] Will only fetch the active organizations
     * @returns {Promise}
     */
    getOrganizationsTreePublic = (fromApi = false, onlyActive = false) => {
        // If we have the orgTree in cache AND we don't want to update the orgTree from the api
        if(Array.isArray(this.orgTreePublic) && !fromApi){
            return Promise.resolve(this.orgTreePublic);
        }

        return API_PUBLIC.get(queryString.stringifyUrl({
            url: `organisations/${this.props.IdentityRolesContext.federation?.organisation_id}/tree`,
            query: {
                active: onlyActive ? 1 : null,
            },
        }, {
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return this.orgTreePublic = response.data.organisations;
                }
                this.orgTreePublic = null;
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Get organization info
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganization = (organizationId, params = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}`, query: params }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisation[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get organization info
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationPublic = (organizationId) => {
        return API_PUBLIC.get(queryString.stringifyUrl({ url: `organisations/${organizationId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisation[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get organization children
     * @param {string} [organizationId]
     * @returns {Promise.<Array>}
     */
    getOrganizationChildren = (organizationId = this.state.organisation_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId}/children` }))
            .then((response) => {
                if(response.data.status){
                    this.setState((prevState) => {
                        prevState.children = response.data.organisations;
                        return prevState;
                    })
                    return response.data.organisations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get organization branch
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationBranch = (organizationId, fromApi = false) => {
        // If we have the orgTree in cache AND we don't to update the orgTree from the api
        if(this.orgBranch && !fromApi){
            return Promise.resolve(this.orgBranch);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId || this.state.organisation_id}/branch` }))
            .then((response) => {
                if(response.data.status){
                    return this.orgBranch = response.data.organisations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get organization branch
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationRegion = (organizationId, fromApi = false) => {
        // If we have the orgTree in cache AND we don't to update the orgTree from the api
        if(this.orgRegion && !fromApi){
            return Promise.resolve(this.orgRegion);
        }

        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisations/${organizationId || this.state.organisation_id}/region` }))
            .then((response) => {
                if(response.data.status){
                    return this.orgRegion = response.data.organisations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets the Organization's Settings
     * @param {string} organizationId ID of the Organization we want to get the Settings from
     * @param {object} [params]
     * @see https://api.id.dev.spordle.dev/documentations/#/Organisation/c2f162f05f2cd63a126ddb723cfc0d33
     * @returns {Promise}
     */
    getOrganizationSettings = (organizationId = this.state.organisation_id, params) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `organisations/${organizationId}/settings`,
            query: params,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return this._formatSettings(response.data.settings);
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Updates the organization's settings
     * @param {string} organizationId ID of the Organization we want to update the Settings from
     * @param {Array} [values] Values to update the settings with - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Organisation/Apicontroller%5COrganisations%5CSettings%3A%3AsaveOrganisationSettings|documentation}
     * @returns {Promise}
     */
    updateSettings = (organizationId, values = {}, periodId, allPeriodSettings) => {
        const apiFormattedData = {};
        for(const key in values){ // Values that will overide the initial values
            if(Object.hasOwnProperty.call(values, key)){
                apiFormattedData[key] = {
                    code: key,
                    value: Array.isArray(values[key]) ? values[key].join(',') : values[key],
                }
                if(periodId && !allPeriodSettings[key]){
                    apiFormattedData[key].period_id = periodId
                }
            }
        }

        const apiValues = new URLSearchParams();
        jsObjectToApi({ settings: Object.values(apiFormattedData) }, apiValues);

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `organisations/${organizationId}/settings` }), apiValues)
            .then(async(response) => {
                if(response.data.status){
                    const settings = await this.getOrganizationSettings(organizationId);
                    this.setState(() => ({ settings: settings }));
                    return true;
                }

                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * [PATCH] Partially update a member on the discrimination claim
     * @param {string} organizationId org ID
     * @param {string} settingCode setting code
     * @param {object} value new value of setting
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Discriminations/4feb531e989f61c715612090c81230be|documentation}
     */
    updateOrganizationSetting = (organizationId, settingCode, value) => {
        const params = new URLSearchParams({ value: value });
        return API_SPORDLE.patch(
            queryString.stringifyUrl({
                url: `organisations/${organizationId}/settings/${settingCode}`,
            }),
            params,
        )
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get organization info by categories
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationByCategories = (organizationId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `organisations/${organizationId}/categories`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get organization info by settings
     * @param {string} organizationId
     * @param {object} query
     * @param {string} query.setting_code
     * @returns {Promise}
     */
    getOrganizationBySettings = (organizationId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `organisations/${organizationId}/by-settings`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisations[queryParams.setting_code] || [];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    getMemberFields = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `member-fields`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_fields || [];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    partialUpdateMemberField = (code, values = {}) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/member-fields/${code}` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.organisation_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get organization categories
     * @param {string} organizationId
     * @returns {Promise}
     */
    getOrganizationCategory = (organizationId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `organisations/${organizationId}/category`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.categories;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get all billable organizations
     * @returns {Promise}
     */
    getBillableOrganizations = () => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/billable`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create an org
     * @param {object} values
     * @returns {Promise}
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Organisation/Apicontroller%5COrganisations%5COrganisations%3A%3AlinkPostcodeToOrganisation|documentation}
     */
    createOrganization = (values) => {
        const params = new URLSearchParams();

        for(const key in values){
            if(Object.hasOwnProperty.call(values, key)){
                switch (key){
                    case 'name':
                        params.append('organisation_name', values[key] || '');
                        break;
                    case 'organisation_id_parent':
                        params.append('organisation_id_parent', values[key] || '');
                        break;
                    case 'organisation_category_id':
                        params.append('organisation_category_id', values[key] || '');
                        break;
                    case 'abbreviation':
                        params.append('abbreviation', values[key] || '');
                        break;
                    case 'email':
                        params.append('organisation_email', values[key] || '');
                        break;
                    case 'phone':
                        params.append('phone', values[key] || '');
                        break;
                    case 'website':
                        params.append('website', values[key] || '');
                        break;
                    case 'streetNumber':
                        params.append('street_number', values[key] || '');
                        break;
                    case 'address':
                        params.append('street', values[key] || '');
                        break;
                    case 'address2':
                        params.append('unit_number', values[key] || '');
                        break;
                    case 'city':
                        params.append('city', values[key] || '');
                        break;
                    case 'zipCode':
                        params.append('zip_code', values[key] || '');
                        break;
                    case 'state':
                        params.append('province_code', values[key] || '');
                        break;
                    case 'country':
                        params.append('country_code', values[key] || '');
                        break;
                    case 'mapsUrl':
                        params.append('map_url', values[key] || '');
                        break;
                    case 'origin':
                        params.append('origin_address', values[key] || '');
                        break;
                    case 'identification_number':
                        params.append('identification_number', values[key] || '');
                        break;
                    case 'withSubOrganisation':
                        params.append('with_sub_organisation', !!values[key] >>> 0);// Will always results in 0 or 1 as number
                        break;
                    case 'fields':
                        values.fields.forEach((field, index) => {
                            params.append(`fields[${index}][custom_form_field_id]`, field.custom_form_field_id);

                            // array.toString() formats it to a string with a comma separating the values
                            if(field.custom_form_field_option_id !== undefined && field.custom_form_field_option_id !== null)
                                params.append(`fields[${index}][custom_form_field_option_id]`, Array.isArray(field.custom_form_field_option_id) ? field.custom_form_field_option_id.toString() : field.custom_form_field_option_id);

                            params.append(`fields[${index}][answer]`, Array.isArray(field.answer) ? field.answer.toString() : field.answer);
                        })
                        break;
                    case 'usual_name':
                        params.append('usual_name', values[key] || '');
                        break;
                    default:
                        if(key.includes('i18n')){
                            params.append(key, values[key] || '');// Will always results in 0 or 1 as number
                        }
                        break;
                }
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/organisations` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.organisation_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Does a partial update of the org
     * @param {string} [organizationId]
     * @param {object} values
     * @param {string} values.orgNameEn
     * @param {string} values.orgNameFr
     * @param {string} values.abbreviation
     * @param {string} values.email
     * @param {string} values.phone The phone number with the extension in it. ex: +16135550106#123
     * @param {string} values.website
     * @param {string} values.address
     * @param {string} values.address2
     * @param {string} values.city
     * @param {string} values.zipCode
     * @param {string} values.province
     * @param {string} values.country
     * @param {string} values.identification_number
     * @param {1|0} values.withSubOrganisation
     *
     * @returns {Promise}
     */
    updateOrganizationPartial = (values, organizationId = this.state.organisation_id) => {
        /**
         * Data for the api
         */
        const patchData = new URLSearchParams();
        /**
         * Data to update the context's state
         */
        const formattedData = {};

        // Building the data partial data for the api call
        for(const key in values){
            if(Object.hasOwnProperty.call(values, key)){
                switch (key){
                    case 'name':
                        formattedData.organisation_name = values[key] || '';
                        patchData.append('organisation_name', values[key] || '');
                        break;
                    case 'abbreviation':
                        formattedData.abbreviation = values[key] || '';
                        patchData.append('abbreviation', values[key] || '');
                        break;
                    case 'email':
                        formattedData.organisation_email = values[key] || '';
                        patchData.append('organisation_email', values[key] || '');
                        break;
                    case 'phone':
                        formattedData.phone = values[key] || '';
                        patchData.append('phone', values[key] || '');
                        break;
                    case 'website':
                        formattedData.website = values[key] || '';
                        patchData.append('website', values[key] || '');
                        break;
                    case 'streetNumber':
                        formattedData.street_number = values[key] || '';
                        patchData.append('street_number', values[key] || '');
                        break;
                    case 'address':
                        formattedData.street = values[key] || '';
                        patchData.append('street', values[key] || '');
                        break;
                    case 'address2':
                        formattedData.unit_number = values[key] || '';
                        patchData.append('unit_number', values[key] || '');
                        break;
                    case 'city':
                        formattedData.city = values[key] || '';
                        patchData.append('city', values[key] || '');
                        break;
                    case 'zipCode':
                        formattedData.zip_code = values[key] || '';
                        patchData.append('zip_code', values[key] || '');
                        break;
                    case 'state':
                    case 'province':
                        formattedData.province_code = values[key] || '';
                        patchData.append('province_code', values[key] || '');
                        break;
                    case 'country':
                        formattedData.country_code = values[key] || '';
                        patchData.append('country_code', values[key] || '');
                        break;
                    case 'mapsUrl':
                        formattedData.map_url = values[key] || '';
                        patchData.append('map_url', values[key] || '');
                        break;
                    case 'origin':
                        formattedData.origin = values[key] || '';
                        patchData.append('origin_address', values[key] || '');
                        break;
                    case 'identification_number':
                        formattedData.identification_number = values[key] || '';
                        patchData.append('identification_number', values[key] || '');
                        break;
                    case 'withSubOrganisation':
                        formattedData.with_sub_organisation = (!!values[key] >>> 0).toString();// Will always results in 0 or 1 as string
                        patchData.append('with_sub_organisation', !!values[key] >>> 0);// Will always results in 0 or 1 as number
                        break;
                    case 'fields':
                        values.fields.forEach((field, index) => {
                            patchData.append(`fields[${index}][custom_form_field_id]`, field.custom_form_field_id);

                            // array.toString() formats it to a string with a comma separating the values
                            if(field.custom_form_field_option_id !== undefined && field.custom_form_field_option_id !== null)
                                patchData.append(`fields[${index}][custom_form_field_option_id]`, Array.isArray(field.custom_form_field_option_id) ? field.custom_form_field_option_id.toString() : field.custom_form_field_option_id);

                            patchData.append(`fields[${index}][answer]`, Array.isArray(field.answer) ? field.answer.toString() : field.answer);
                        })
                        break;
                    case 'usual_name':
                        patchData.append('usual_name', values[key] || '');
                        break;
                    case 'display_order':
                        if(values[key])
                            patchData.append('display_order', values[key] || '');
                        break;
                    default:
                        if(key.includes('i18n')){
                            if(typeof values[key] === 'object' && values[key]){ // && values[key] is fix to edit org profile without errors
                                formattedData.i18n = values[key];
                            }else if(values[key]){
                                patchData.append(key, values[key] || '');// Will always results in 0 or 1 as number
                            }
                        }
                        break;
                }
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/organisations/${organizationId}` }), patchData)
            .then((response) => {
                if(response.data.status){
                    this.setState((prevState) => ({ ...prevState, ...formattedData }));
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Determines if the french language is activated for this organization
     * @returns {boolean}
     */
    hasFrench = () => !!this.state.settings?.language?.value.includes('fr');

    /**
     * Determines if the english language is activated for this organization
     * @returns {boolean}
     */
    hasEnglish = () => !!this.state.settings?.language?.value.includes('en');

    ////////////////////////////////////////////////////////////////////////////////////
    // Postal Codes
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * Link Postal codes to an organisation
     * @param {string} organisationId
     * @param {string[]} [postalCodes] The query params for that call
     * @see https://api.id.dev.spordle.dev/documentations/#/Organisation/9c08a3c8588f9b563d0b97007577d7bc
     * @returns {Promise.<boolean>}
     */
    updateOrganisationPostalCode = (organisationId, postalCodes) => {
        const data = new URLSearchParams();

        if(postalCodes.length !== 0){
            data.append('postal_codes', JSON.stringify(postalCodes));
        }else{
            data.append('postal_codes', '');
        }

        return API_SPORDLE.put(`/organisations/${organisationId}/postal-codes`, data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Unlink Postal codes from an organisation
     * @param {string} organisationId
     * @param {string} postalCode
     * @see https://api.id.dev.spordle.dev/documentations/#/Organisation/1653d62c7a0243fb8c3f2ed37ff856a4
     * @returns {Promise.<boolean>}
     */
    deleteOrganisationPostalCode = (organisationId, postalCode) => {
        return API_SPORDLE.delete(`organisations/${organisationId}/postal-codes/${postalCode}`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    // Venues
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * Link Venues to an organisation
     * @param {string} organisation_id
     * @param {object} [values] The query params for that call -
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Organisation/Apicontroller%5COrganisations%5COrganisations%3A%3AlinkVenueToOrganisation|documentation}
     * @returns {Promise.<boolean>}
     */
    linkVenuesToOrganisation = (organisation_id, values) => {
        const data = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'venues':
                    data.append('venues', values.venues);
                    break;

                default:
                    if(key.includes('i18n'))
                        data.append(key, values[key])
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/organisations/${organisation_id}/venues`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * Unlink Venues from an organisation
     * @param {string} organisation_id
     * @param {string} venue_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Organisation/Apicontroller%5COrganisations%5COrganisations%3A%3AunlinkVenueToOrganisation|documentation}
     * @returns {Promise.<boolean>}
     */
    unlinkVenuesFromOrganisation = (organisation_id, venue_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `organisations/${organisation_id}/venues/${venue_id}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    // Organisation Logos
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * [POST] Adds a logo to an organisation
     * @param {string} orgId Id of the organisation
     * @param {object} values Logo values to post
     * @param {string} [dataUrl] temp file for immediate change in state
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Organisation/Apicontroller%5COrganisations%5COrganisations%3A%3AupdateOrganisationLogo|documentation}
     * @returns {Promise.<Array>}
     */
    createOrganisationLogo = (orgId, values, dataUrl) => {
        // https://developer.mozilla.org/en-US/docs/Web/API/FormData/FormData
        const params = new FormData();

        params.append('file_position', values.file_position);
        params.append('logo', values.logo);

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `organisations/${orgId}/logos`,
        }), params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.status){
                    return this.getOrganization(orgId).then((organisation) => {
                        // Updates where the logo is cached
                        this.props.AuthContext.getUserIdentityRole(true).catch(console.error);
                        // this.props.IdentityRolesContext.updateCurrentIdentityRole('organisation', { ...this.props.IdentityRolesContext.organisation, logo: organisation.logo });
                        this.setState((prev) => ({ ...prev, logo: organisation.logo }));
                        return organisation;
                    })
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes the organisation logo
     * @param {string} orgId
     * @see Refer to the - {@link https://api.id.spordle.dev/documentations/#/Organisation/Apicontroller%5COrganisations%5COrganisations%3A%3AdeleteOrganisationLogo|documentation}
     * @returns {Promise.<boolean>}
     */
    deleteOrganisationLogo = (orgId) => (
        API_SPORDLE.delete(queryString.stringifyUrl({ url: `/organisations/${orgId}/logos` }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    )

    ////////////////////////////////////////////////////////////////////////////////////
    // Organisation Terms
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * Get the organisation terms and conditions
     * @param {string} organisation_id
     * {object} [queryParams] The query params
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Organisation/Apicontroller%5COrganisations%5CTerms%3A%3AgetOrganisationTerms|documentation}
     * @returns {Promise.<Array>}
     */
    getOrganisationTerms = (organisation_id, queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/${organisation_id}/terms`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.terms;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get the organisation terms and conditions
     * @param {object} [queryParams] The query params
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Organisation/Apicontroller%5COrganisations%5CTerms%3A%3Aindex|documentation}
     * @returns {Promise.<Array>}
     */
    getTermsOfOrganisation = (queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/terms`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.terms;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Create a new terms and conditions for a given organisation
     * @param {object} values
     * @param {string} organisation_id
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Organisation/Apicontroller%5COrganisations%5CTerms%3A%3AcreateOrganisationTerms|documentation}
     * @returns {Promise}
     */
    createTermsForOrganisation = (organisation_id, values) => {
        const params = new URLSearchParams()

        for(const key in values){
            params.append(key, values);
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/organisations/${organisation_id}/terms` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates the terms in the french and english language
     * @param {string} organisation_id
     * @param {object} [values] The query params for that call -
     * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Organisation/Apicontroller%5COrganisations%5CTerms%3A%3AupdateOrganisationTerms|documentation}
     * @returns {Promise.<boolean>}
     */
    updateTermsOfOrganisation = (organisation_id, values) => {
        const data = new URLSearchParams()

        for(const key in values){
            data.append(key, values);
        }

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/organisations/${organisation_id}/terms`,
        }), data)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    /**
    * Updates the terms in the api supported language
    * @param {string} organisation_id
    * @param {string} annexe_id
    * @param {object} values Object containing the values to update
    * @see Refer to the {@link https://api.id.spordle.dev/documentations/#/Organisation/Apicontroller%5COrganisations%5CTerms%3A%3ApatchOrganisationTerms|documentation}
    * @returns {Promise}
    */
    updateTermsInAPISupported = (organisation_id, annexe_id, values) => {
        const params = new URLSearchParams();

        for(const key in values){
            params.append(key, values);
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `organisations/${organisation_id}/terms/${annexe_id}`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Admin Registration
     * Get the Organisation Registration Items for a given member (Members Profile -> Registrations -> Add)
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.spordle.dev/documentations/#/Items/GetOrganisationItems|documentation}
     * @returns {Promise.<Array>}
     */
    getOrganisationRegistrations = (queryParams) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/${this.state.organisation_id}/items`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.items;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Organisation Ledgers
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * [GET] Get all the member transfers for an organisation
     * @param {string} [orgId]
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Organisation%20Ledger/Apicontroller%5COrganisations%5COrganisations%3A%3AgetAllOrganisationLedger|documentation}
     * @returns {Promise.<Array>}
     */
    getOrganisationLedgers = (orgId = this.state.organisation_id, queryParams = {}) => (
        API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/${orgId}/ledgers`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.ledgers;
                }
                throw response.data.errors[0];
            }, serverError)
    )

    /**
     * [PATCH] Partially updates an organisation ledger
     * @param {string} orgId
     * @param {string} ledgerId
     * @param {object} values The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Organisation%20Ledger/Apicontroller%5COrganisations%5COrganisations%3A%3ApatchOrganisationLedger|documentation}
     * @returns {Promise.<Array>}
     */
    partiallyUpdateOrganisationLedgers = (orgId, ledgerId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/organisations/${orgId}/ledgers/${ledgerId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Create an org ledger
     * @param {string} organizationId
     * @param {object} values The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Organisation%20Ledger/Apicontroller%5COrganisations%5COrganisations%3A%3AcreateOrganisationLedger|documentation}
     * @returns {Promise.<string>}
     */
    createOrganisationLedger = (orgId, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/organisations/${orgId}/ledgers` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.organisation_ledger_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes an org ledger
     * @param {string} orgId
     * @param {string} ledgerId
     * @returns {Promise.<boolean>}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Organisation%20Ledger/Apicontroller%5COrganisations%5COrganisations%3A%3AdeleteOrganisationLedger|documentation}
     */
    deleteOrganisationLedger = (orgId, ledgerId) => (
        API_SPORDLE.delete(queryString.stringifyUrl({ url: `/organisations/${orgId}/ledgers/${ledgerId}` }))
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0];
            }, serverError)
    )

    //#endregion

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Organisation Transfers
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * Get all the member transfers for an organisation
     * @param {object} [organizationId]
     * @param {object} [queryParams] The query params for that call - Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Member%20Transfers/Apicontroller%5CMembers%5CMembertransfers%3A%3AgetAllMemberTransfersByOrganisation documentation}
     * @returns {Promise.<Array>}
     */
    getOrganisationTransfers = (organizationId = this.state.organisation_id, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/${organizationId}/transfers`,
            query: {
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                ...queryParams,
                lite: 1,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    const { // All sub-ressources
                        organisations,
                        members,
                        periods,
                        transfer_reason_types,
                        transfer_statuses,
                    } = response.data;
                    return response.data.member_transfers.map((memberTransfer) => {
                        memberTransfer.member = members[memberTransfer.member_id];

                        memberTransfer.organisation = organisations[memberTransfer.organisation_id];
                        memberTransfer.target_organisation = organisations[memberTransfer.target_organisation_id];
                        memberTransfer.client_organisation = organisations[memberTransfer.client_organisation_id];

                        memberTransfer.period = periods[memberTransfer.period_id];

                        memberTransfer.transfer_reason_type = transfer_reason_types[memberTransfer.transfer_reason_type_id];
                        memberTransfer.transfer_status = transfer_statuses[memberTransfer.transfer_status_id];

                        return memberTransfer;
                    });
                }
                throw response.data.errors[0];
            }, serverError)
    }
    //#endregion

    /**
     * POWER USER ONLY : Update the parent organization of a given organization
     * @param {string} [organizationId]
     * @param {string} [organizationParentId]
     *
     * @returns {Promise}
     */
    updateOrganizationParent = (organizationId, organizationParentId) => {
        const patchData = new URLSearchParams();
        patchData.append('organisation_parent_id', organizationParentId);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/organisations/${organizationId}/parents` }), patchData)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    ////////////////////////////////////////////////////////////////////////////////////
    //#region Organisation Contact Types
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * Get all the Contact Types within an organization
     * @param {string} organizationId The organization id we want to get the Contact Types from
     * @returns {Promise<Array>}
     */
    getOrganizationContactTypes = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisation-contact-types`, query: { organisation_id: organizationId } }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisation_contact_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get Organization Contact Type information
     * @param {string} contactTypeId
     * @returns {Promise}
     */
    getOrganizationContactType = (contactTypeId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `organisation-contact-types/${contactTypeId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisation_contact_types[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates a specified Organization Contact Type with new values
     * @param {string} contactTypeId ID of the Contact Type to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @returns {Promise}
     */
    updateOrganizationContactType = (contactTypeId, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                case 'englishName':
                    params.append('name', values[key]); // name should always be the same as English name
                    params.append('i18n[en][name]', values[key])
                    break;
                case 'frenchName':
                    params.append('i18n[fr][name]', values[key])
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `organisation-contact-types/${contactTypeId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates a Contact Type under an Organization with specified values
     * @param {string} organizationId ID of the Organization to add a Contact Type to
     * @param {string} englishName English Name of the Contact Type
     * @param {string} frenchName French Name of the Contact Type
     * @param {boolean} active Wether or not the Contact Type is active
     * @returns {Promise}
     */
    createOrganizationContactType = (organizationId, values) => {
        const params = new URLSearchParams();
        params.append('organisation_id', organizationId);
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                case 'englishName':
                    params.append('name', values[key]); // name should always be the same as English name
                    params.append('i18n[en][name]', values[key])
                    break;
                case 'frenchName':
                    params.append('i18n[fr][name]', values[key])
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `organisation-contact-types` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.organisation_contact_type_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes a specific Organization Contact Type
     * @param {string} contactTypeId ID of the Contact Type to delete
     * @returns {Promise}
     */
    deleteOrganizationContactType = (contactTypeId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `organisation-contact-types/${contactTypeId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Organisation Document Types
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * Get all the Document Types within an organization
     * @param {string} organizationId The organization id we want to get the Document Types from
     * @returns {Promise<Array>}
     */
    getOrganizationDocumentTypes = (organizationId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `document-types`, query: { organisation_id: organizationId } }))
            .then((response) => {
                if(response.data.status){
                    return response.data.document_types;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Get Organization Document Type information
     * @param {string} documentTypeId
     * @returns {Promise}
     */
    getOrganizationDocumentType = (documentTypeId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({ url: `document-types/${documentTypeId}` }))
            .then((response) => {
                if(response.data.status){
                    return response.data.document_types[0];
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Updates a specified Organization Document Type with new values
     * @param {string} documentTypeId ID of the Document Type to update
     * @param {object} values Object containing the values to update - The keys to the values need to be the same as the API ones
     * @returns {Promise}
     */
    updateOrganizationDocumentType = (documentTypeId, values) => {
        const params = new URLSearchParams();
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `document-types/${documentTypeId}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Creates a Document Type under an Organization with specified values
     * @param {string} organizationId ID of the Organization to add a Document Type to
     * @param {string} englishName English Name of the Document Type
     * @param {string} frenchName French Name of the Document Type
     * @param {boolean} active Wether or not the Document Type is active
     * @returns {Promise}
     */
    createOrganizationDocumentType = (organizationId, values) => {
        const params = new URLSearchParams();
        params.append('organisation_id', organizationId);
        for(const key in values){
            switch (key){
                case 'active':
                    params.append('active', (values[key] == '1') >>> 0);
                    break;
                default:
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `document-types` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.document_type_id;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * Deletes a specific Organization Document Type
     * @param {string} documentTypeId ID of the Document Type to delete
     * @returns {Promise}
     */
    deleteOrganizationDocumentType = (documentTypeId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({ url: `document-types/${documentTypeId}` }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }
    //#endregion

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Organisation Contacts
    ////////////////////////////////////////////////////////////////////////////////////

    /**
 * Get organisation contacts by organisation id
 * @param {string} organisation_id
 * @see Refer to the - {@link https://api.id.spordle.dev/documentations/#/Organisation%20Contacts/Apicontroller%5COrganisations%5CContacts%3A%3AgetAllOrganisationContact|documentation}
 * @returns {Promise<Array>}
 */
    getOrganizationContacts = (organisation_id, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `organisations/${organisation_id}/contacts`, query: Object.assign(queryParams),
        }))
            .then((response) => {
                if(response.data.status){
                    const contacts = response.data.contacts.map((contact) => ({
                        ...contact,
                        organisation: response.data.organisation,
                    }));

                    if(queryParams.with_identities == 1){
                        const identities = response.data.identities?.map((identity) => ({
                            ...identity,
                            organisation: response.data.organisation,
                        }));

                        return { contacts: contacts || [], identities: identities || [] };
                    }

                    this.setState((prevState) => ({ ...prevState, organizationContacts: contacts }));

                    return contacts || [];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get organisation contacts from multiple organizations
     * @param {string} organisation_id
     * @see Refer to the - {@link https://api.id.spordle.dev/documentations/#/Organisation%20Contacts/Apicontroller%5COrganisations%5CContacts%3A%3AgetAllOrganisationContact|documentation}
     * @returns {Promise<Array>}
     */
    getOrganizationsContacts = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `organisations/contacts`, query: Object.assign(queryParams),
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    const contacts = response.data.contacts.map((contact) => ({
                        ...contact,
                        organisation: response.data.organisation,
                    }));

                    if(queryParams.with_identities == 1){
                        const identities = response.data.identities?.map((identity) => ({
                            ...identity,
                            organisation: response.data.organisation,
                        }));

                        return { contacts: contacts || [], identities: identities || [] };
                    }

                    this.setState((prevState) => ({ ...prevState, organizationContacts: contacts }));

                    return contacts || [];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get a specific organisation contact by id
     * @param {string} organisation_id
     * @param {string} organisation_contact_id
     * @see Refer to the - {@link https://api.id.spordle.dev/documentations/#/Organisation%20Contacts/Apicontroller%5COrganisations%5CContacts%3A%3AgetOrganisationContactDetail|documentation}
     * @returns {Promise<Object>}
     */
    getOrganizationContact = (organisation_id, organisation_contact_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `organisations/${organisation_id}/contacts/${organisation_contact_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.contacts[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * create an organisation contact
     * @param {string} organisation_id
     * @param {object} values
     * @see Refer to the - {@link https://api.id.spordle.dev/documentations/#/Organisation%20Contacts/Apicontroller%5COrganisations%5CContacts%3A%3AcreateOrganisationContact|documentation}
     * @returns {Promise}
     */
    createOrganizationContact = (organisation_id, values) => {
        const params = new URLSearchParams();
        if(values.organisation_contact_types && values.organisation_contact_types.length > 0){
            values.organisation_contact_types.forEach((group, groupIndex) => {
                params.append(`organisation_contact_types[${groupIndex}][organisation_contact_type_id]`, group.organisation_contact_type_id)
                params.append(`organisation_contact_types[${groupIndex}][is_elected]`, group.is_elected)
                if(group.elected_until && group.is_elected == 1)
                    params.append(`organisation_contact_types[${groupIndex}][elected_until]`, group.elected_until)
                params.append(`organisation_contact_types[${groupIndex}][active]`, group.active)
            })
        }
        params.append('first_name', values.first_name);
        params.append('last_name', values.last_name);
        params.append('email', values.email);
        params.append('active', values.active);
        params.append('visible_on_website', values.visible_on_website);
        params.append('email_visible_on_website', values.email_visible_on_website);
        params.append('phone_visible_on_website', values.phone_visible_on_website);
        if(values?.phone)
            params.append('phone', values.phone);

        if(values.member_id)
            params.append('member_id', values.member_id);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `organisations/${organisation_id}/contacts` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.organisation_contact_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update the entire organisation contact entity, as well as the associated language attributes
     * @param {string} organisation_id
     * @param {string} organisation_contact_id
     * @param {string} values.first_name
     * @param {string} values.last_name
     * @param {string} values.email
     * @param {string} values.phone
     * @param {string} values.active
     * @see Refer to the - {@link https://api.id.spordle.dev/documentations/#/Organisation%20Contacts/Apicontroller%5COrganisations%5CContacts%3A%3AupdateOrganisationContact|documentation}
     * @returns {Promise}
     */
    updateOrganizationContact = (organisation_id, organisation_contact_id, values) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                case 'organisation_contact_types':
                    // organisation_contact_types
                    if(values.organisation_contact_types && values.organisation_contact_types.length > 0){
                        values.organisation_contact_types.forEach((group, groupIndex) => {
                            params.append(`organisation_contact_types[${groupIndex}][organisation_contact_type_id]`, group.organisation_contact_type_id)
                            params.append(`organisation_contact_types[${groupIndex}][is_elected]`, group.is_elected)
                            if(group.elected_until && group.is_elected == 1)
                                params.append(`organisation_contact_types[${groupIndex}][elected_until]`, group.elected_until)
                            params.append(`organisation_contact_types[${groupIndex}][active]`, group.active)
                        })
                    }
                    break;
                default: // name, descritpion, etc
                    if(values[key])
                        params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `organisations/${organisation_id}/contacts/${organisation_contact_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Update a specific organisation contact partially
     * @param {string} organisation_id
     * @param {string} organisation_contact_id
     * @param {string} values.first_name
     * @param {string} values.last_name
     * @param {string} values.email
     * @param {string} values.phone
     * @param {string} values.active
     * @see Refer to the - {@link https://api.id.spordle.dev/documentations/#/Organisation%20Contacts/Apicontroller%5COrganisations%5CContacts%3A%3ApatchOrganisationContact|documentation}
     * @returns {Promise}
     */
    updateOrganizationContactPartially = (organisation_id, organisation_contact_id, values) => {
        const params = new URLSearchParams()

        for(const key in values){
            switch (key){
                default: // name, descritpion, etc
                    params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `organisations/${organisation_id}/contacts/${organisation_contact_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * Deletes all Organization Contacts
     * @param {string} organisation_id
     * @see Refer to the - {@link https://api.id.spordle.dev/documentations/#/Organisation%20Contacts/Apicontroller%5COrganisations%5CContacts%3A%3AdeleteAllOrganisationContact|documentation}
     * @returns {Promise}
     */
    deleteAllOrganizationContact = (organisation_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `organisations/${organisation_id}/contacts`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes an Organization Contact
     * @param {string} organisation_id
     * @param {sting} organisation_contact_id
     * @see Refer to the - {@link https://api.id.spordle.dev/documentations/#/Organisation%20Contacts/Apicontroller%5COrganisations%5CContacts%3A%3AdeleteOrganisationContact|documentation}
     * @returns {Promise}
     */
    deleteOrganizationContact = (organisation_id, organisation_contact_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `organisations/${organisation_id}/contacts/${organisation_contact_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Organisation Discriminations
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * [GET] Gets all discrimination claims for an organisation
     * @param {string} [organisation_id] organisation_id. Will use the one in the state if none specified
     * @param {string} [period_id] period_id. Will use the one in PeriodsContext's state if none specified
     * @returns {Promise.<object[]}>} Returns an array of discrimination claim
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Discriminations/209b07ab80843dc399ed5d7c15e74ff4|documentation}
     */
    getDiscriminations = (organisation_id = this.state.organisation_id, period_id = this.props.PeriodsContext.selectedPeriod.period_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/${organisation_id}/discriminations`,
            query: {
                period_id: period_id,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    const {
                        attachments,
                        categories,
                        classes,
                        discrimination_statuses,
                        discriminations,
                        divisions,
                        document_types,
                        identities,
                        maltreatment_locations,
                        maltreatment_types,
                        member_suspensions,
                        members,
                        outcomes,
                        organisations,
                        periods,
                        suspensions,
                        teams,
                    } = response.data;

                    // *** SAME FORMATTING IS PRESENT IN: getMemberDiscriminations, getDiscriminations, getDiscriminationSpecific
                    return discriminations.map((discrimination) => {
                        discrimination.away_team = teams[discrimination.away_team_id];
                        delete discrimination.away_team_id;

                        discrimination.discrimination_attachments?.map((discrimination_attachment) => {
                            discrimination_attachment.attachment = attachments[discrimination_attachment.attachment_id];
                            delete discrimination_attachment.attachment_id;

                            discrimination_attachment.document_type = document_types[discrimination_attachment.document_type_id];
                            delete discrimination_attachment.document_type_id;
                        })

                        discrimination.complaint_by_member = members[discrimination.complaint_by_id];
                        delete discrimination.complaint_by_id;
                        if(discrimination.complaint_by_member){
                            discrimination.complaint_by_member.organisation = organisations[discrimination.complaint_by_member.organisation_id];
                            delete discrimination.complaint_by_member.organisation_id;
                        }

                        (discrimination.discrimination_member || []).map((member) => {
                            member.member = members[member.member_id];
                            member.team = teams[member.team_id];

                            if(member.team){
                                const category = categories[member.team.team_category_id];


                                if(category){
                                    category.division = divisions[category.division_id];
                                    category.class = classes[category.class_id];
                                }

                                member.team.category = category;
                            }

                            if(member.member){
                                member.member.organisation = organisations[member.member.organisation_id];
                                delete member.member.organisation_id;
                            }
                        })

                        discrimination.discrimination_status = discrimination_statuses[discrimination.discrimination_status_id];
                        delete discrimination.discrimination_status_id;

                        discrimination.home_team = teams[discrimination.home_team_id];
                        delete discrimination.home_team_id;

                        discrimination.identity = identities[discrimination.identity_id];
                        delete discrimination.identity_id;

                        discrimination.maltreatment_location = maltreatment_locations[discrimination.maltreatment_location_id];
                        delete discrimination.maltreatment_location_id;

                        discrimination.maltreatment_member_suspensions?.map((suspension) => {
                            suspension.linked_by = identities[suspension.linked_by];

                            suspension.member_suspension = member_suspensions[suspension.member_suspension_id];
                            delete suspension.member_suspension_id;

                            suspension.member_suspension.member = members[suspension.member_suspension.member_id];
                            delete suspension.member_suspension.member_id;

                            suspension.member_suspension.suspension = suspensions[suspension.member_suspension.suspension_id];
                            delete suspension.member_suspension.suspension_id;

                            suspension.member_suspension.organisation = organisations[suspension.member_suspension.organisation_id];
                            delete suspension.member_suspension.organisation_id;
                        })

                        discrimination.maltreatment_outcomes?.map((outcome) => {
                            outcome.created_by = identities[outcome.created_by];
                            outcome.updated_by = identities[outcome.updated_by];

                            outcome.outcome = outcomes[outcome.outcome_id];
                            delete outcome.outcome_id;
                        })

                        discrimination.maltreatment_type = maltreatment_types[discrimination.maltreatment_type_id];
                        delete discrimination.maltreatment_type_id;

                        discrimination.organisation = organisations[discrimination.organisation_id];
                        delete discrimination.organisation_id;

                        discrimination.period = periods[discrimination.period_id];
                        delete discrimination.period_id;

                        return discrimination;
                    });
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Gets all discrimination claims for an organisation
     * @param {string} [organisation_id] organisation_id. Will use the one in the state if none specified
     * @param {string} [period_id] period_id. Will use the one in PeriodsContext's state if none specified
     * @returns {Promise.<object[]}>} Returns an array of discrimination claim
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Discriminations/209b07ab80843dc399ed5d7c15e74ff4|documentation}
     */
    getDiscriminationSpecific = (organisation_id = this.state.organisation_id, period_id = this.props.PeriodsContext.selectedPeriod.period_id, discrimination_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/${organisation_id}/discriminations/${discrimination_id}`,
            query: {
                period_id: period_id,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    const {
                        attachments,
                        categories,
                        classes,
                        discrimination_statuses,
                        discriminations,
                        divisions,
                        document_types,
                        identities,
                        maltreatment_locations,
                        maltreatment_types,
                        member_suspensions,
                        members,
                        outcomes,
                        organisations,
                        periods,
                        suspensions,
                        teams,
                    } = response.data;
                    // *** SAME FORMATTING IS PRESENT IN: getMemberDiscriminations, getDiscriminations, getDiscriminationSpecific
                    return discriminations.map((discrimination) => {
                        discrimination.away_team = teams[discrimination.away_team_id];
                        delete discrimination.away_team_id;

                        discrimination.discrimination_attachments?.map((discrimination_attachment) => {
                            discrimination_attachment.attachment = attachments[discrimination_attachment.attachment_id];
                            delete discrimination_attachment.attachment_id;

                            discrimination_attachment.document_type = document_types[discrimination_attachment.document_type_id];
                            delete discrimination_attachment.document_type_id;
                        })

                        discrimination.complaint_by_member = members[discrimination.complaint_by_id];
                        delete discrimination.complaint_by_id;
                        if(discrimination.complaint_by_member){
                            discrimination.complaint_by_member.organisation = organisations[discrimination.complaint_by_member.organisation_id];
                            delete discrimination.complaint_by_member.organisation_id;
                        }

                        discrimination.discrimination_member.map((member) => {
                            member.member = members[member.member_id];
                            member.team = teams[member.team_id];

                            if(member.team){
                                const category = categories[member.team.team_category_id];


                                if(category){
                                    category.division = divisions[category.division_id];
                                    category.class = classes[category.class_id];
                                }

                                member.team.category = category;
                            }

                            if(member.member){
                                member.member.organisation = organisations[member.member.organisation_id];
                                delete member.member.organisation_id;
                            }
                        });

                        discrimination.discrimination_status = discrimination_statuses[discrimination.discrimination_status_id];
                        delete discrimination.discrimination_status_id;

                        discrimination.home_team = teams[discrimination.home_team_id];
                        delete discrimination.home_team_id;

                        discrimination.identity = identities[discrimination.identity_id];
                        delete discrimination.identity_id;

                        discrimination.maltreatment_location = maltreatment_locations[discrimination.maltreatment_location_id];
                        delete discrimination.maltreatment_location_id;

                        discrimination.maltreatment_member_suspensions?.map((suspension) => {
                            suspension.linked_by = identities[suspension.linked_by];

                            suspension.member_suspension = member_suspensions[suspension.member_suspension_id];
                            delete suspension.member_suspension_id;

                            suspension.member_suspension.member = members[suspension.member_suspension.member_id];
                            delete suspension.member_suspension.member_id;

                            suspension.member_suspension.suspension = suspensions[suspension.member_suspension.suspension_id];
                            delete suspension.member_suspension.suspension_id;

                            suspension.member_suspension.organisation = organisations[suspension.member_suspension.organisation_id];
                            delete suspension.member_suspension.organisation_id;
                        })

                        discrimination.maltreatment_outcomes?.map((outcome) => {
                            outcome.created_by = identities[outcome.created_by];
                            outcome.updated_by = identities[outcome.updated_by];

                            outcome.outcome = outcomes[outcome.outcome_id];
                            delete outcome.outcome_id;
                        })

                        discrimination.maltreatment_type = maltreatment_types[discrimination.maltreatment_type_id];
                        delete discrimination.maltreatment_type_id;

                        discrimination.organisation = organisations[discrimination.organisation_id];
                        delete discrimination.organisation_id;

                        discrimination.period = periods[discrimination.period_id];
                        delete discrimination.period_id;

                        return discrimination;
                    })[0];
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Create a discrimination claim
     * @param {object} values Values of the discrimination claim to create
     * @returns {Promise.<string>} Returns id of the newly created discrimination claim
     * @throws {object}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Discriminations/738cad63f7617c6634bc1ce129c8ad0b|documentation}
     */
    createDiscrimination = (values) => {
        const params = new FormData();

        params.append('period_id', this.props.PeriodsContext.selectedPeriod.period_id);

        for(const key in values){
            switch (key){
                case 'attachments':
                    for(let a = 0; a < values[key].length; a++){
                        const att = values[key][a];
                        params.append(`attachments[${a}][attachment]`, att.attachment);
                        params.append(`attachments[${a}][active]`, att.active);
                    }
                    break;
                case 'members':
                    values[key].forEach((member, index) => {
                        if(member.member_id) params.append(`members[${index}][member_id]`, member.member_id);
                        if(member.role) params.append(`members[${index}][role]`, member.role);
                        if(member.belongs_to) params.append(`members[${index}][belongs_to]`, member.belongs_to);
                        if(member.team_id) params.append(`members[${index}][team_id]`, member.team_id);
                        if(member.team_name) params.append(`members[${index}][team_name]`, member.team_name);
                        if(member.first_name) params.append(`members[${index}][first_name]`, member.first_name);
                        if(member.last_name) params.append(`members[${index}][last_name]`, member.last_name);
                    })
                    break;
                default:
                    if(values[key]) params.append(key, values[key]);
                    break;
            }
        }

        return API_SPORDLE.post(
            queryString.stringifyUrl({ url: `/organisations/${this.state.organisation_id}/discriminations` }),
            params,
            { headers: { 'Content-Type': 'multipart/form-data' } },
        )
            .then((response) => {
                if(response.data.status){
                    return response.data.discrimination_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get all discrimination status
     * @param {object} [queryParams] Query params
     * @returns {Promise.<object[]}>} Returns an array of discrimination claim
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Discrimination%20Status/Apicontroller%5COrganisations%5CDiscriminationstatus%3A%3AgetAllDiscriminationStatus|documentation}
     */
    getDiscriminationStatus = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/discrimination-status`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.discrimination_status;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get all maltreatment types
     * @param {object} [queryParams] Query params
     * @returns {Promise.<object[]}>} Returns an array of maltreatment types
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Maltreatment%20Types/57f3575cdcd1d6ea2555b3cabbe62c2f|documentation}
     */
    getMaltreatmentTypes = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/maltreatment-types`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.maltreatment_types;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get all maltreatment locations
     * @param {object} [queryParams] Query params
     * @returns {Promise.<object[]}>} Returns an array of maltreatment locations
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Maltreatment%20Locations/61c3eb6204d8e1432d4ce4742e9b0857|documentation}
     */
    getMaltreatmentLocations = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/maltreatment-locations`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.maltreatment_locations;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] Update partially a discrimination claim
     * @param {string} discrimination_id Discrimination ID
     * @param {object} values Values to update
     * @returns {Promise.<string>} Returns the id of the updated discrimination member
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Discriminations/826094880987162b1b89b880edb11cb0|documentation}
     */
    partiallyUpdateDiscrimination = (discrimination_id, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/organisations/${this.state.organisation_id}/discriminations/${discrimination_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.member_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] Add a member to an existing discrimination claim
     * @param {string} discrimination_id Discrimination ID
     * @param {object} values Values of the member to create
     * @returns {Promise.<boolean>} Returns the id of the newly created discrimination member
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Discriminations/fa37bbcca3f22e6c0e4aa8100ccf19ee|documentation}
     */
    addDiscriminationMember = (discrimination_id, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/organisations/${this.state.organisation_id}/discriminations/${discrimination_id}/members` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.discrimination_member_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] Partially update a member on the discrimination claim
     * @param {string} discrimination_id Discrimination ID
     * @param {string} discrimination_member_id Discrimination member ID
     * @param {object} values Values to update
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Discriminations/4feb531e989f61c715612090c81230be|documentation}
     */
    partiallyUpdateDiscriminationMember = (discrimination_id, discrimination_member_id, values) => {
        const params = new URLSearchParams(values);

        return API_SPORDLE.patch(
            queryString.stringifyUrl({
                url: `/organisations/${this.state.organisation_id}/discriminations/${discrimination_id}/members/${discrimination_member_id}`,
            }),
            params,
        )
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE]Delete a member from a discrimination claim
     * @param {string} [discrimination_id] Discrimination ID
     * @param {string} [discrimination_member_id] Discrimination member ID
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Discriminations/69b4a30571af7f08e7adab14511301b2|documentation}
     */
    deleteDiscriminationMember = (discrimination_id, discrimination_member_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `organisations/${this.state.organisation_id}/discriminations/${discrimination_id}/members/${discrimination_member_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * [DELETE]Delete a discrimination claim
     * @param {string} [discrimination_id] Discrimination ID
     * @returns {Promise.<boolean>}
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Discriminations/8cc87397f2595e9818b2e46ffe1656ee|documentation}
     */
    deleteDiscriminationClaim = (discrimination_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `organisations/${this.state.organisation_id}/discriminations/${discrimination_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Get all discrimination attachments
     * @param {string} [orgId] OrganisationId
     * @param {object} [discriminationId] discrimination id
     * @param {object} [queryParams] Query params
     * @returns {Promise.<object[]}>} Returns an array of discrimination attachments
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Discrimination%20Attachments/cba0cd245877d8ce8738d4dce3553e44|documentation}
     */
    getDiscriminationAttachments = (orgId, discriminationId, queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `organisations/${orgId || this.state.organisation_id}/discriminations/${discriminationId}/attachments`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.attachments;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [CREATE] Creates discrimination attachments
     * @param {string} [orgId]
     * @param {string} discriminationId
     * @param {string} attachmentId
     * @param {object[]} files
     */
    createDiscriminationAttachments = (orgId, discriminationId, files) => {
        const params = new FormData();

        for(let f = 0; f < files.length; f++){
            const file = files[f];
            params.append(`attachments[${f}][attachment]`, file.attachment);
            params.append(`attachments[${f}][active]`, file.active);
        }

        return API_SPORDLE.post(
            queryString.stringifyUrl({ url: `organisations/${orgId || this.state.organisation_id}/discriminations/${discriminationId}/attachments` }),
            params,
            { headers: { 'Content-Type': 'multipart/form-data' } },
        )
            .then((res) => {
                if(res.data.status){
                    return res.data.discrimination_attachments;
                }
                throw res.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] Returns a temp path for download of attachment
     * @param {string} [orgId]
     * @param {string} discriminationId
     * @param {string} attachmentId
     * @returns {string}
     */
    downloadDiscriminationAttachment = (orgId, discriminationId, attachmentId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `organisations/${orgId || this.state.organisation_id}/discriminations/${discriminationId}/attachments/${attachmentId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return {
                        full_path: response.data.full_path,
                        preview_full_path: response.data.preview_full_path,
                        original_file_mime_type: response.data.original_file_mime_type,
                    };
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] Deletes a discrimination attachment
     * @param {string} [orgId]
     * @param {string} discriminationId
     * @param {string} attachmentId
     * @returns {Promise.<boolean>}
     */
    deleteDiscriminationAttachment = (orgId, discriminationId, attachmentId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `organisations/${orgId || this.state.organisation_id}/discriminations/${discriminationId}/attachments/${attachmentId}`,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * [GET] Get all discrimination outcomes
     * @param {object} [queryParams] Query params
     * @returns {Promise.<object[]}>} Returns an array of discrimination claim
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/index.php#/Outcomes/0e51407c99466c10033bf885d74cbfbf|documentation}
     */
    getDiscriminationOutcomes = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/outcomes`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.outcomes.map((o) => ({ ...o, with_comment: 1 }));
                }
                throw response.data.errors[0];
            }, serverError)
    }


    /**
     * [POST] create a discrimination outcomes
     * @param {string} [maltreatment_id] Query params
     * @param {object} [values] Query params
     * @returns {Promise.<object[]}>} Returns an array of discrimination claim
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/index.php#/Outcomes/0e51407c99466c10033bf885d74cbfbf|documentation}
     */
    createDiscriminationOutcomes = (maltreatment_id, values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params, {
            skipEmptyString: true,
            skipNull: true,
        })

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/maltreatments/${maltreatment_id}/outcomes` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.outcome_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PATCH] update a discrimination outcomes
     * @param {string} [maltreatment_id] Query params
     * @param {object} [values] Query params
     * @returns {Promise.<object[]}>} Returns an array of discrimination claim
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/index.php#/Outcomes/0e51407c99466c10033bf885d74cbfbf|documentation}
     */
    updateDiscriminationOutcomes = (maltreatment_id, maltreatment_outcome_id, values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params, {
            skipEmptyString: true,
            skipNull: true,
        })

        return API_SPORDLE.patch(queryString.stringifyUrl({ url: `/maltreatments/${maltreatment_id}/outcomes/${maltreatment_outcome_id}` }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.matreatment_outcome_id;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] delete a discrimination outcomes
     * @param {string} [maltreatment_id] Query params
     * @param {string} [maltreatment_outcome_id] Query params
     * @returns {Promise.<object[]}>} Returns an array of discrimination claim
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/index.php#/Outcomes/0e51407c99466c10033bf885d74cbfbf|documentation}
     */
    deleteDiscriminationOutcomes = (maltreatment_id, maltreatment_outcome_id) => {
        return API_SPORDLE.delete(`/maltreatments/${maltreatment_id}/outcomes/${maltreatment_outcome_id}`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [POST] link a discrimination suspension
     * @param {string} [maltreatment_id] Query params
     * @param {object} [values] Query params
     * @returns {Promise.<object[]}>} Returns an array of discrimination claim
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/index.php#/Outcomes/0e51407c99466c10033bf885d74cbfbf|documentation}
     */
    createDiscriminationSuspension = (maltreatment_id, values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params, {
            skipEmptyString: true,
            skipNull: true,
        })

        return API_SPORDLE.post(queryString.stringifyUrl({ url: `/maltreatments/${maltreatment_id}/member-suspensions` }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] unlink a discrimination suspension
     * @param {string} [maltreatment_id] Query params
     * @param {object} [values] Query params
     * @returns {Promise.<object[]}>} Returns an array of discrimination claim
     * @throws {Error}
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/index.php#/Outcomes/0e51407c99466c10033bf885d74cbfbf|documentation}
     */
    deleteDiscriminationSuspension = (maltreatment_id, maltreatment_member_suspension_id) => {
        return API_SPORDLE.delete(`/maltreatments/${maltreatment_id}/member-suspensions/${maltreatment_member_suspension_id}`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    //#endregion Organisation Discriminations
    ////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Communications
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * [POST] Send emails
     * @param {string} [orgId] Organisation id
     * @param {object} [values] Query params
     */
    sendCommunication = (orgId, values) => {
        const params = new URLSearchParams();

        params.append("message", values.message);
        params.append("subject", values.subject);

        if(values.members){
            values.members.forEach((member, index) => {
                params.append(`member_id[${index}]`, member.member_id)
            });
        }

        if(values.organisations){
            values.organisations.forEach((organisationId, index) => {
                params.append(`organisations[${index}]`, organisationId)
            });
        }

        if(values.emails){
            values.emails.forEach((email, index) => {
                params.append(`emails[${index}]`, email)
            });
        }

        if(values.identities){
            values.identities.forEach((identityId, index) => {
                params.append(`identities[${index}]`, identityId)
            });
        }

        return API_SPORDLE.post(
            queryString.stringifyUrl({
                url: `/organisations/${orgId || this.state.organisation_id}/communications`,
            }),
            params,
        )
            .then((response) => {
                if(response.data.status){
                    return response.data;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    //#endregion Communications
    ////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////
    //#region Payees
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * [GET] Gets all payees
     * @param {string} [queryParams] Query params
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Payees/e79b77a581bc0670603f0cdb98d0cbad|documentation}
     * @returns {Promise.<object[]>} Returns an array of discrimination claim
     */
    getPayees = (queryParams = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/payees`,
            query: queryParams,
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.payees;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * [GET] Gets all service types
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Service%20Types/3132680e8e7abd49d780d41e18a47612|documentation}
     * @returns {Promise.<object[]>} Returns an array of discrimination claim
     */
    getServiceTypes = () => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/service-types`,
            query: {
                organisation_id: this.state.organisation_id,
            },
        }, {
            arrayFormat: 'comma',
            skipEmptyString: true,
            skipNull: true,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.service_types;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * [POST] Create a payee
     * @param {Object} values Values of the payee to create
     * @returns {Promise.<string>} Returns id of the newly created payee
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Payees/35eeed8d3f9586680b41520c811f35c2|documentation}
     */
    createPayee = (values) => {
        const params = new URLSearchParams();

        jsObjectToApi(values, params, {
            skipEmptyString: true,
            skipNull: true,
        })

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/payees`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return response.data.payee_id;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * [PATCH] Update a payee
     * @param {string} payee_id Id of the payee to update
     * @param {Object} values Values of the payee to create
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Payees/5066b539ccfdb21dd3a4dfff92090eb2|documentation}
     */
    updatePayee = (payee_id, values) => {
        const params = new URLSearchParams();

        for(const key in values){
            params.append(key, values[key]);
        }

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/payees/${payee_id}`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    /**
     * [DELETE] Delete a payee
     * @param {string} payee_id Payee id to delete
     * @see Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Payees/97835384eb7c6f9abbf3dd0f5653b1e9|documentation}
     */
    deletePayee = (payee_id) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: `/payees/${payee_id}`,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0]
            }, serverError)
    }

    ////////////////////////////////////////////////////////////////////////////////////
    //#endregion Payees
    ////////////////////////////////////////////////////////////////////////////////////

    /**
     * Opens the Org change modal for the DisplayOrganization
     * @param {boolean} isOpen
     * @param {sting} selected object representing selected org
     * @returns {Promise}
     */
    setDisplayOrgModal = (isOpen, selected) => {
        selected ?
            this.setState((prevState) => ({ ...prevState, isOrgChangeModalOpen: isOpen, selectedOrg: selected }))
            :
            this.setState((prevState) => ({ ...prevState, isOrgChangeModalOpen: isOpen }));
    }

    /**
     * Sets whether or not the org change modal is loading
     * @param {boolean} isLoading
     * @returns {Promise}
     */
    setOrgModalLoading = (isLoading) => {
        this.setState((prevState) => ({ ...prevState, isOgModalLoading: isLoading }))
    }

    /**
     * Get all organisation tags by organisation id in parameter
     * @param {string} orgId
     * @param {object} params
     * @see https://api.id.dev.spordle.dev/documentations/#/Organisation%20Tags/e46c4d46f8a45a201baa6c13c5e1145d
     * @returns {Promise.<Array>}
     */
    getOrganizationTags = (orgId = this.state.organisation_id, params) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/${orgId}/tags`,
            query: params,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisation_tags;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes existing organisation tags from the organisation id in param and creates a batch of organisation tags from the organisation id in param and body data
     * @param {string} orgId
     * @param {string[]} tagIds
     * @see https://api.id.dev.spordle.dev/documentations/#/Organisation%20Tags/0b9d202ad5c871477da3069cb47715aa
     * @returns {Promise.<Array>}
     */
    updateOrganizationTags = (orgId = this.state.organisation_id, tagIds) => {
        const params = new URLSearchParams();
        for(let index = 0; index < tagIds.length; index++){
            params.append(`tag_ids[${index}]`, tagIds[index])
        }
        return API_SPORDLE.put(`/organisations/${orgId}/tags`, params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [GET] - Get the background checks for an organization
     * @param {string} orgId
     * @see https://api.id.dev.spordle.dev/documentations/index.php#/Background%20Checks/8c24624a18b6da623353409667ba5499
     * @returns {Promise.<Array>}
     */
    getOrganizationBackgroundChecks = (orgId) => {
        return API_SPORDLE.get(`/organisations/${orgId}/background-checks`)
            .then((response) => {
                if(response.data.status){
                    return response.data.background_checks;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [PUT] - Update the background checks for an organization
     * @param {string} orgId
     * @param {object} values
     * @see https://api.id.dev.spordle.dev/documentations/index.php#/Background%20Checks/ebffdf39cb534c2a41755cbe83df12c5
     * @returns {Promise.<boolean>}
     */
    updateOrganizationBackgroundChecks = (orgId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params, {
            skipEmptyString: true,
        })
        return API_SPORDLE.put(`/organisations/${orgId}/background-checks`, params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * [DELETE] - Deletes an organization background check
     * @param {string} orgId Org ID to delete a background check from
     * @param {string} backgroundCheckId Background check ID to delete
     * @returns {Promise}
     */
    deleteOrganizationBackgroundCheck = (orgId, backgroundCheckId) => {
        return API_SPORDLE.delete(`/organisations/${orgId}/background-checks/${backgroundCheckId}`)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    // #region Competitions, League and Tournaments

    /**
     * Gets affiliated teams
     * @returns {Promise}
     */
    getCompetitions = (query = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/competitions`,
            query: {
                ...query,
                period_id: query?.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    const data = response.data;
                    const getFull = (field, id) => (data[field] || {})[id];

                    return data.competitions.map((competition) => {
                        const teamCategory = getFull('team_categories', competition.team_category_id);
                        const division = getFull('divisions', competition.division_id);

                        return ({
                            ...competition,
                            competition_level: getFull('competition_levels', competition.competition_level_id),
                            created_by: getFull('identities', competition.created_by),
                            decision_made_by: getFull('identities', competition.decision_made_by),
                            division: {
                                division_id: competition.division_id,
                                ...division,
                            },
                            period: getFull('periods', competition.period_id),
                            team_category: teamCategory ? {
                                ...teamCategory,
                                division: division,
                                class: getFull('classes', teamCategory?.class_id),
                            } : null,
                            organisation: getFull('organisations', competition.organisation_id),
                            request_change_status: getFull('request_change_statuses', competition.request_change_status_id),
                        })
                    });
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets affiliated teams
     * @returns {Promise}
     */
    getAvailableCompetitionTeams = (orgId, query = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/${orgId}/available-teams`,
            query: {
                ...query,
                period_id: query?.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    const data = response.data;
                    const getFull = (field, id) => (data[field] || {})[id];

                    return data.teams.map((team) => {
                        const category = getFull('team_categories', team.team_category_id);
                        const organisation = getFull("organisations", team.organisation_id);

                        return ({
                            ...team,
                            organisation: organisation ? {
                                ...organisation,
                                organisation_id: team.organisation_id,
                            } : null,
                            team_category: category ? {
                                ...category,
                                division: getFull('divisions', category?.division_id),
                                class: getFull('classes', category?.class_id),
                                organisation: getFull('organisations', team.organisation_id),
                            } : null,
                        })
                    });
                }
                throw response.data.errors[0];
            }, serverError)
    }


    addTeamToCompetition = (competitionId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params)

        return API_SPORDLE.put(queryString.stringifyUrl({
            url: `/competitions/${competitionId}/teams`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors;
            }, serverError)
    }

    /**
     * Gets affiliated teams
     * @returns {Promise}
     */
    getCompetitionTeams = (orgId, query = {}) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `organisations/${orgId}/competition-teams`,
            query: {
                ...query,
                period_id: query?.period_id || this.props.PeriodsContext.selectedPeriod.period_id,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    // const {} = response.data;
                    const getData = (field, id) => (response.data[field] || {})[id];

                    const teams = response.data.competitions_teams.map((compTeam) => {
                        const competition = getData('competitions', compTeam.competition_id);
                        const team = getData('teams', compTeam.team_id);

                        return ({
                            ...compTeam,
                            competition_team_status: getData('competition_team_status', compTeam.competition_team_status_id),
                            organisation: getData('organisations', compTeam.competition_organisation_id),
                            submitted_by: getData('identities', compTeam.submitted_by),
                            decision_made_by: getData('identities', compTeam.decision_made_by),
                            competition: competition ? {
                                ...competition,
                                division: getData('divisions', competition.division_id),
                                team_category: getData('team_categories', competition.team_category_id),
                            } : null,
                            team: team ? {
                                ...team,
                                team_category: getData('team_categories', team.team_category_id),
                                division: getData('divisions', team.division_id),
                                logo: getData('logos', team.logo),
                                organisation: getData('organisations', team.organisation_id),
                            } : null,
                        })
                    });

                    return {
                        teams: teams,
                        status: Object.entries(response.data.competition_team_status || {}).map(([ key, val ]) => ({
                            competition_team_status_id: key,
                            ...val,
                        }))
                            .sort((a, b) => {
                                const codeA = a.system;
                                const codeB = b.system;

                                if(codeA !== codeB){
                                    if(codeA === "APPROVED" || codeB === "DECLINED"){
                                        return -1;
                                    }

                                    if(codeB === "APPROVED" || codeA === "DECLINED"){
                                        return 1;
                                    }
                                }

                                return 0;
                            }),
                        competitions: Object.entries(response.data.competitions || {}).map(([ key, val ]) => ({
                            competition_id: key,
                            division: getData('divisions', val.division_id),
                            team_category: getData('team_categories', val.team_category_id),
                            ...val,
                        })),
                    }
                }
                throw response.data.errors[0];
            }, serverError)
    }

    partiallyUpdateCompetition = (competitionId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params);

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: `/competitions/${competitionId}`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true
                }
                throw response.data.errors[0]
            }, serverError)
    }

    // #endregion

    //////////////////////////////////////////////////////////
    // Organization grouping
    //////////////////////////////////////////////////////////


    /**
     * Gets affiliated organizations
     * @param {Object} values Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Affiliated%20Organisations/03d5a74ea0f84af87eda8d69c9fa3819|documentation}
     * @returns {Promise}
     */
    getAffiliatedOrganizations = (orgId) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: '/affiliated-organisations',
            query: {
                organisation_id: orgId,
                period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    const {
                        affiliated_organisations,
                        divisions,
                        organisations,
                        periods,
                        team_category,
                    } = response.data;

                    return affiliated_organisations.map((affiliatedOrg) => {
                        // Main ressources
                        affiliatedOrg.division = divisions[affiliatedOrg.division_id];
                        delete affiliatedOrg.division_id
                        affiliatedOrg.period = periods[affiliatedOrg.period_id];
                        delete affiliatedOrg.period_id
                        affiliatedOrg.organisation = organisations[affiliatedOrg.organisation_id];
                        delete affiliatedOrg.organisation_id
                        affiliatedOrg.linked_organisation = organisations[affiliatedOrg.linked_organisation_id];
                        delete affiliatedOrg.linked_organisation_id
                        affiliatedOrg.team_category = team_category[affiliatedOrg.team_category_id];
                        delete affiliatedOrg.team_category_id

                        return affiliatedOrg;
                    });
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Get an organization affiliated organizations
     * @param {string} [organizationId]
     * @param {string} [periodId]
     * @param {string} [divisionId]
     * @returns {Promise}
     */
    getOrganizationAffiliations = ({ organisation_id, period_id, division_id }) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisations/${organisation_id}/affiliated`,
            query: {
                organisation_id: organisation_id,
                period_id: period_id || this.props.PeriodsContext.selectedPeriod.period_id,
                division_id: division_id,
            },
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.organisations
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Creates an affiliated organization
     * @param {Object} values Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Affiliated%20Organisations/2ecd9976f9b1e519f17212c5ae1cfa09|documentation}
     * @returns {Promise}
     */
    createAffiliatedOrganization = (values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params)

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: '/affiliated-organisations',
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Updates an affiliated organization
     * @param {Object} values Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Affiliated%20Organisations/30f57561d9b5b3cf603f56d7de8c0c10|documentation}
     * @returns {Promise}
     */
    updateAffiliatedOrganization = (affiliatedOrgId, values) => {
        const params = new URLSearchParams();
        jsObjectToApi(values, params)

        return API_SPORDLE.patch(queryString.stringifyUrl({
            url: '/affiliated-organisations/' + affiliatedOrgId,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Deletes an affiliated organization
     * @returns {Promise}
     */
    deleteAffiliatedOrganization = (affiliatedOrgId) => {
        return API_SPORDLE.delete(queryString.stringifyUrl({
            url: '/affiliated-organisations/' + affiliatedOrgId,
        }))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Checks if the current org is a parent to or is the received organization
     * @returns {Promise}
     */
    isCurrentOrParentOrg = (organizationId) => {
        if(organizationId){
            return this.getOrganizationPublic(organizationId)
                .then((organization) => {
                    return organizationId === this.state.organisation_id || !!organization?.breadcrumbs?.some((parent) => parent.organisation_id === this.state.organisation_id)
                })
                .catch((error) => { console.error(error.message) });
        }
        return false;
    }

    //////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////


    /**
     * GET - get list of organisation api partners (1st version: Moodle)
     * @returns {Promise.<Array>}
    */
    getApiPartners = () => {
        return API_SPORDLE.get(`/organisations/${this.state.organisation_id}/api-partners`)
            .then((response) => {
                if(response.data.status){
                    return response.data.api_partners_organisation;
                }
                throw response.data.errors[0];
            }, serverError);
    }

    getAdditionalFieldsComponents = (orgId, queryParams = {}) => {
        return API_SPORDLE.get(stringifyUrl({
            url: `/organisations/${orgId}/component-additional-fields`,
            query: queryParams,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.component_additional_fields;
                }
                throw response.data.errors[0];
            }, serverError);
    }


    /**
     * Creates an approbation request
     * @param {Object} values Refer to the {@link https://api.id.dev.spordle.dev/documentations/#/Affiliated%20Organisations/2ecd9976f9b1e519f17212c5ae1cfa09|documentation}
     * @returns {Promise}
     */
    createApprobationRequest = (orgId) => {
        const params = new URLSearchParams();

        return API_SPORDLE.post(queryString.stringifyUrl({
            url: `/organisations/${orgId}/approbation-requests`,
        }), params)
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * GET - get list of organisations to review
     * @returns {Promise.<Array>}
    */
    getOrganizationsToReview = () => {
        return API_SPORDLE.get(`/organisations/${this.state.organisation_id}/to-review`)
            .then((response) => {
                if(response.data.status)
                    return response.data.organisations;
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * GET - get list of possible organization statuses
     * @returns {Promise.<Array>}
    */
    getOrganizationsStatuses = (organizationId = this.state.organisation_id) => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/organisation-status`,
            query: {
                organisation_id: organizationId,
            },
        }))
            .then((response) => {
                if(response.data.status)
                    return response.data.organisation_status;
                throw response.data.errors[0];
            }, serverError);
    }

    /**
     * [PUT] Updates the organizations status
     * @param {array} organizations array of organization ids
     * @param {string} organizationStatusId organization status id with which to update the organizations
     * @param {string} note note with which to update the organizations
     * @returns {Promise}
     */
    updateOrganizationStatuses = (organizations, organizationStatusId, note) => {
        const params = {};

        if(Array.isArray(organizations)){
            organizations.map((orgId, i) => {
                params[`organisations[${i}][organisation_id]`] = orgId;
                params[`organisations[${i}][organisation_status_id]`] = organizationStatusId;
                params[`organisations[${i}][note]`] = note || '';
            });
        }

        return API_SPORDLE.put(queryString.stringifyUrl({ url: `/organisations/approbations` }), new URLSearchParams(params))
            .then((response) => {
                if(response.data.status){
                    return true;
                }
                throw response.data.errors[0];
            }, serverError)
    }

    /**
     * Gets the member fields that are mandatory
     * @param {boolean} fromApi force refresh of cached mandatory fields from API
     * @returns {Promise<string>}
     */
    getMemberMandatoryFields = () => {
        return API_SPORDLE.get(queryString.stringifyUrl({
            url: `/member-fields`,
        }))
            .then((response) => {
                if(response.data.status){
                    return response.data.member_fields;
                }
                throw response.data.errors[0];
            }, serverError)
    }


    render(){
        return (
            <OrganizationContext.Provider value={{
                ...this.state,
                ...this,
            }}
            >
                {this.props.children}
            </OrganizationContext.Provider>
        );
    }
}

export default withContexts(AuthContext, IdentityRolesContext, PeriodsContext, AppContext)(OrganizationContextProvider);