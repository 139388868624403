import { FormikAddress, FormikInputNumber, FormikInputText, FormikPhoneInput, FormikSelect, getFormikAddressInitialValues } from "@spordle/formik-elements";
import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Collapse, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { number, object, string } from "yup";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import Required from "../../../../../../components/formik/Required";
import { MembersContext } from "../../../../../../contexts/MembersContext";
import { MEMBER_TYPE } from "../../DiscriminationHelper";
import { DiscriminationAddContext } from "../components/DiscriminationAddContext";
import DiscriminationSearchResults from "../components/DiscriminationSearchResults";
import { CrossFade } from "../DiscriminationAddModal";
import { fail } from "@spordle/toasts";
import { DisplayI18n } from "../../../../../../helpers/i18nHelper";
import { IdentityRolesContext } from "../../../../../../contexts/IdentityRolesContext";

const DiscriminationAddModalStepComplainer = ({ stepNb }) => {
    const { setComplaint, next, previous, setLoading, complaint, step } = useContext(DiscriminationAddContext);
    const { getMembers } = useContext(MembersContext);
    const { federation } = useContext(IdentityRolesContext);
    const [ subStep, setSubStep ] = useState(2);
    const [ results, setResults ] = useState(null);
    const isOfficial = complaint.complaint_type === "OFFICIAL";
    const formikRef = useRef();

    const selectByAndNext = (val) => {
        setComplaint('complaint_by', val);
        next();
    }

    const handleOnMemberSelect = (member, formikVal) => {
        const data = { ...formikVal, ...member };

        if(isOfficial){
            data.role = "OFFICIAL";
        }

        selectByAndNext(data);
    }

    useEffect(() => {
        formikRef.current?.resetForm();
    }, [ isOfficial ])

    const getFormikDefaultProps = () => {
        const initValidation = {
            first_name: string().test({
                name: 'wouldBeRequired',
                message: <Translate id='form.validation.firstName.required' />,
                test: function(data){
                    if(isOfficial){
                        return !!this.parent.unique_identifier || !!data;
                    }

                    return !!data;
                },
            }),
            last_name: string().test({
                name: 'wouldBeRequired',
                message: <Translate id='form.validation.lastName.required' />,
                test: function(data){
                    if(isOfficial){
                        return !!this.parent.unique_identifier || !!data;
                    }

                    return !!data;
                },
            }),
            unique_identifier: string(),
            email: string().email().test({
                name: 'wouldBeRequired',
                message: <Translate id={isOfficial ? "form.validation.email.required" : "discrimination.form.phoneOrEmail.required"} />,
                test: function(val){
                    return isOfficial || !!this.parent.phone_number || !!val;
                },
            }),
        };

        const initValues = {
            first_name: "",
            last_name: "",
            unique_identifier: "",
            email: "",
        };

        initValues.phone_number = '';
        initValues.role = '';
        initValues.address = getFormikAddressInitialValues();
        initValues.phone_ext = '';

        initValidation.role = string().test({
            name: 'wouldBeRequired',
            message: <Translate id='discrimination.label.validation.role.required' />,
            test: function(data){
                return isOfficial || !!data;
            },
        });

        initValidation.address = object().shape({
            streetNumber: string(),
            address: string(),
            city: string(),
            zipCode: string(),
            state: string(),
        }),

        initValidation.phone_number = string().isValidPhoneNumber(<Translate id='form.validation.phone.valid' />).test({
            name: 'wouldBeRequired',
            message: <Translate id="discrimination.form.phoneOrEmail.required" />,
            test: function(val){
                return isOfficial || !!this.parent.email || !!val;
            },
        })
        initValidation.phone_ext = number().integer();

        return { validationSchema: object().shape(initValidation), initialValues: initValues };
    }

    return (
        <Formik
            {...getFormikDefaultProps()}
            validateOnMount
            innerRef={formikRef}
            onSubmit={(values, { setStatus }) => {
                setLoading(true);
                const apiValues = {
                    all: 1,
                    // organisation_id: (isOfficial && branchInfo && branchInfo.length > 0) ? branchInfo.organisation_id : organisation_id,
                    organisation_id: federation.organisation_id,
                }

                if(values.unique_identifier){
                    apiValues.unique_identifier = values.unique_identifier;
                }else{
                    apiValues.first_name = values.first_name;
                    apiValues.last_name = values.last_name;
                }

                if(isOfficial){
                    apiValues.with_official_qualification = 1;
                }

                apiValues.period_id = null; //skip in this case ?

                getMembers(apiValues)
                    .then((members) => {
                        if(members.length > 0){
                            setStatus(null); // In case there was a status before
                            setResults(members);
                            setSubStep(3);
                        }else if(isOfficial){
                            setStatus(<Translate id='discrimination.addModal.stepComplainer.title.official.error' />);
                        }else{
                            selectByAndNext(values);
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            fail({
                                msg: 'misc.error',
                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                skipInfoTranslate: true,
                            })
                            setLoading(false);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />);
                        }
                    });
            }}
        >
            {({ status, values }) => (
                <Form>
                    <CrossFade isVisible={subStep === 3}>
                        <DiscriminationSearchResults
                            onMemberSelect={(m) => handleOnMemberSelect(m, values)}
                            previous={() => setSubStep(2)}
                            next={isOfficial ?
                                null
                                :
                                () => {
                                    selectByAndNext(values);
                                }
                            }
                            results={results}
                        />
                    </CrossFade>
                    <CrossFade isVisible={subStep === 2} unmountOnExit>
                        <ModalBody>
                            <div className="font-bold h4 mb-0">
                                <Translate id={'discrimination.addModal.stepComplainer.title' + stringBuilder({ '.official': complaint.complaint_type === "OFFICIAL" })} />
                            </div>
                            <Row className="mt-3" form>
                                <Col sm="6" className="form-group">
                                    <Label className="text-muted" for="complainer-first_name"><Translate id='form.fields.firstName' /> {(!isOfficial || !values.unique_identifier) && <Required />}</Label>
                                    <FormikInputText disabled={values.unique_identifier && isOfficial} name="first_name" id="complainer-first_name" />
                                </Col>
                                <Col sm="6" className="form-group">
                                    <Label className="text-muted" for="complainer-last_name"><Translate id='form.fields.lastName' /> {(!isOfficial || !values.unique_identifier) && <Required />}</Label>
                                    <FormikInputText disabled={values.unique_identifier && isOfficial} name="last_name" id="complainter-last_name" />
                                </Col>
                                <Col sm="6" className="form-group">
                                    <Label className="text-muted" for="complainer-email"><Translate id='form.fields.email' /> {!values.phone_number && !isOfficial && <Required />}</Label>
                                    <FormikInputText disabled={values.unique_identifier && isOfficial} id="complainer-email" name="email" />
                                </Col>
                                {!isOfficial ?
                                    <>
                                        <Col sm="6" className="form-group">
                                            <Label for="role" className="text-muted"><Translate id='discrimination.label.role' /> <Required /></Label>
                                            <FormikSelect
                                                id="role"
                                                name="role"
                                                defaultData={MEMBER_TYPE.filter((type) => type !== "OFFICIAL").map((type) => ({
                                                    label: 'discrimination.global.roles.' + type,
                                                    value: type,
                                                    translateLabel: true,
                                                }))}
                                                loadingStatus='success'
                                            />
                                        </Col>
                                        <Col sm="12">
                                            <FormikAddress
                                                id='address'
                                                name='address'
                                                label='form.fields.address'
                                                labelClassName="text-muted"
                                            />
                                        </Col>
                                        <Col sm="8">
                                            <Label for="member_type" className="text-muted"><Translate id='form.fields.phone' /> {!values.email && <Required />}</Label>
                                            <FormikPhoneInput
                                                name='phone_number'
                                                id="phone_number"
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Label className="text-muted" for="phoneExt"><Translate id='form.fields.extension' /></Label>
                                            <FormikInputNumber id="phoneExt" name="phoneExt" allowNegative={false} decimalSeparator={false} />
                                        </Col>
                                    </>
                                    :
                                    <Col sm="12" className="form-group">
                                        <div className="d-flex align-items-center mb-3"><div className="border-top flex-grow-1" /><div className="px-3 font-medium"><Translate id="members.search.memberAdvanceSearch.filters.or" /></div><div className="border-top flex-grow-1" /></div>
                                        <Label className="text-muted" for="complainer-unique_identifier"><Translate id="components.validateMember.uniqueIdentifier" /> {!values.first_name && !values.last_name && <Required />}</Label>
                                        <FormikInputText id="complainer-unique_identifier" name="unique_identifier" />
                                    </Col>
                                }
                            </Row>
                            {status &&
                                <Collapse appear isOpen>
                                    <Alert className="mt-3" color="danger">
                                        {status}
                                    </Alert>
                                </Collapse>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                type="button"
                                className="mr-auto"
                                onClick={previous}
                                disabled={stepNb !== step}
                                color="primary"
                                outline
                            >
                                <Translate id="misc.previous" />
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={stepNb !== step}
                            >
                                <Translate id="misc.next" />
                            </Button>
                        </ModalFooter>
                    </CrossFade>
                </Form>
            )}
        </Formik>
    )
}

export default DiscriminationAddModalStepComplainer;