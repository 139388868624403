import { FormikDateTime, FormikSelect, FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { mixed, object, string } from "yup";
import { AxiosIsCancelled } from "../../../../../../../../api/CancellableAPI";
import CustomAlert from "../../../../../../../../components/CustomAlert";
import Required from "../../../../../../../../components/formik/Required";
import OverlayLoader from "../../../../../../../../components/loading/OverlayLoader";
import { OrganizationContext } from "../../../../../../../../contexts/OrganizationContext";
import { DisplayI18n } from "../../../../../../../../helpers/i18nHelper";

const AddOutcomeForm = ({ maltreatment, onSuccess, toggle, editOutcomeData, header, disabled, skip }) => {
    const [ outcomesSelect, setOutcomesSelect ] = useState([]);
    const [ outcomesSelectLoading, setOutcomesSelectLoading ] = useState(true);

    const [ isLoading, setIsLoading ] = useState(false);
    const { getDiscriminationOutcomes, createDiscriminationOutcomes, updateDiscriminationOutcomes } = useContext(OrganizationContext);

    const createOrUpdate = (values) => {
        if(editOutcomeData && editOutcomeData.isEdit)
            return updateDiscriminationOutcomes(maltreatment.maltreatment_id || maltreatment.discrimination_id, editOutcomeData.maltreatment_outcome_id, { ...values, outcome_id: null, due_date: values.due_date ? values.due_date.format("YYYY-MM-DD") : null })

        const newValues = { outcomes: [ { ...values, due_date: values.due_date ? values.due_date.format("YYYY-MM-DD") : null } ] };
        return createDiscriminationOutcomes(maltreatment.maltreatment_id || maltreatment.discrimination_id, newValues)

    }

    useEffect(() => {
        getDiscriminationOutcomes()
            .then((outcomes) => {
                setOutcomesSelect(outcomes)
                setOutcomesSelectLoading(false)
            })
            .catch((error) => { setOutcomesSelectLoading(false); console.error(error.message) });
    }, [])

    return (
        <Formik
            initialValues={{
                outcome: editOutcomeData ? editOutcomeData.outcome : null,
                outcome_id: editOutcomeData ? editOutcomeData.outcome?.outcome_id || '' : '',
                status: editOutcomeData ? editOutcomeData.status || 'PENDING' : 'PENDING',
                due_date: (editOutcomeData && editOutcomeData.due_date) ? moment(editOutcomeData.due_date) : '',
                comment: editOutcomeData ? editOutcomeData.comment || '' : '',
                with_comment: false, // comes from outcome selection, will tell if we need to make comments required
            }}
            validationSchema={object().shape({
                outcome_id: string().required(<Translate id="discrimination.outcomes.outcome.required" />),
                due_date: mixed()
                    .isDate()
                    .test({
                        name: 'dueDateAfterMaltreatmentDate',
                        message: <Translate id='discrimination.outcomes.due.date.validation' />,
                        test: function(date){
                            if(date && maltreatment.incident_date){
                                return moment(date).isSameOrAfter(moment(maltreatment.incident_date))
                            }
                            return true
                        },
                    }), // optional & cannot be before maltreatment date
                comment: string()
                    .test({
                        name: 'with_comment',
                        message: <Translate id='discrimination.outcomes.comment.required' />,
                        test: function(value){
                            return !this.parent.with_comment || !!value
                        },
                    }),
                status: string(),
            })}
            onSubmit={(values, { setStatus }) => {
                setIsLoading(true);

                return createOrUpdate(values)
                    .then(() => {
                        onSuccess(values);
                    })
                    .catch((error) => {
                        if(!AxiosIsCancelled(error.message)){
                            console.error(error.message)
                            setIsLoading(false);
                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                        }
                    })
            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={isLoading}>
                        {header ?
                            typeof header === 'function' ? header(isLoading) : header
                            :
                            <ModalHeader toggle={isLoading ? void 0 : toggle}>
                                <Translate id='discrimination.outcomes.addOutcome' />
                            </ModalHeader>
                        }
                        <ModalBody>
                            <Row form>
                                <Col md="12">
                                    <FormGroup>
                                        <Label><Translate id="discrimination.outcomes.outcome" /> <Required /></Label>
                                        <FormikSelect
                                            clearable
                                            disabled={editOutcomeData.isEdit}
                                            name='outcome_id'
                                            id='outcome_id'
                                            isLoading={outcomesSelectLoading}
                                            renderOption={({ option, isDisabled }) => {
                                                return (
                                                    <>
                                                        <DisplayI18n
                                                            field='name'
                                                            defaultValue={option.label}
                                                            i18n={option.i18n}
                                                        /> {isDisabled && <>(<Translate id="discrimination.outcomes.selected" />)</>}
                                                        {option.causes_suspension == '1' && <div className="small text-muted"><i className="mdi mdi-information-outline text-primary mr-1" /><Translate id="discrimination.outcomes.causesSuspension" /></div>}
                                                    </>
                                                )
                                            }}
                                            isOptionDisabled={(option) => (maltreatment.maltreatment_outcomes || []).some(({ outcome }) => outcome.outcome_id == option.value)}
                                            options={outcomesSelect.filter((outcome) => outcome.active == "1").map((outcome) => ({
                                                id: outcome.outcome_id,
                                                value: outcome.outcome_id,
                                                label: outcome.name,
                                                i18n: outcome.i18n,
                                                causes_suspension: outcome.causes_suspension,
                                            }))}
                                            onOptionSelected={([ option ]) => {
                                                const selectedOutcome = outcomesSelect.find((outcome) => outcome.outcome_id == option);
                                                formik.setFieldValue("outcome", selectedOutcome);
                                                formik.setFieldValue("with_comment", selectedOutcome.with_comment == 1);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label><Translate id="form.fields.status" /></Label>
                                        <FormikSelect
                                            id='status'
                                            name='status'
                                            search={false}
                                            defaultData={[
                                                { value: 'PENDING', label: 'discrimination.outcomes.status.PENDING', translateLabel: true },
                                                { value: 'COMPLETED', label: 'discrimination.outcomes.status.COMPLETED', translateLabel: true },
                                            ]}
                                            loadingStatus='success'
                                            placeholder='form.fields.status'
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label><Translate id="discrimination.outcomes.dueDate" /></Label>
                                        <FormikDateTime
                                            name='due_date' id='due_date'
                                            timeFormat={false}
                                            dateFormat="YYYY-MM-DD"
                                            isValidDate={(current) => {
                                                return maltreatment.incident_date ? moment(current).isSameOrAfter(moment(maltreatment.incident_date)) : true
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md="12">
                                    <FormGroup>
                                        <Label><Translate id="form.fields.comment" /> {!!formik.values.with_comment && <Required />}</Label>
                                        <FormikTextArea
                                            name='comment' id='comment'
                                            rows='3'
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                {formik.status &&
                                    <CustomAlert className='mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                }
                            </Collapse>
                        </ModalBody>
                        <ModalFooter>
                            {skip &&
                                <Button onClick={skip} disabled={disabled || isLoading} color='link' type='button' className='mr-2'>
                                    <Translate id="misc.skip" />
                                </Button>
                            }
                            <Button disabled={disabled || isLoading} color='primary' type='submit' className='mr-2'><Translate id={editOutcomeData.isEdit ? 'misc.edit' : 'misc.create'} /></Button>
                            <Button disabled={isLoading} color='primary' type='button' onClick={toggle} outline><Translate id='misc.cancel' /></Button>
                        </ModalFooter>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}

export default AddOutcomeForm;