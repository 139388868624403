import { useContext } from 'react';


import { fail, success } from '@spordle/toasts';


import { DisplayI18n } from '../../../../helpers/i18nHelper';
import { RebatesContext } from '../../../../contexts/RebatesContext';
import AnalyticsModal from '../../../../analytics/AnalyticsModal';
import { AxiosIsCancelled } from '../../../../api/CancellableAPI';
import { AddPostalCodesModalInner } from '../../../organization/profile/cities/modals/AddPostalCodesModal';

const RebatesEditPostalCodes = ({ toggle, isOpen, sidepanelProps }) => {
    const rebatesContext = useContext(RebatesContext);
    const rebate = sidepanelProps.selectedRows[0];
    const selectedPostalCodes = rebate.rules[0].postal_codes;

    const postalCodesOnSubmit = (values, removedValues) => {
        const keptValues = (selectedPostalCodes || []).filter((code) => {
            // Do not filter out if does not exist in removed Values
            return removedValues.findIndex((c) => c === code) === -1;
        });

        const allCodes = Array.from(new Set([ ...(values.postalCodes || []), ...keptValues ]));

        const apiValues = {
            rules: rebate.rules.map((rule) => ({
                ...rule,
                postal_codes: allCodes,
            })),
        };

        return rebatesContext.partialUpdateRebate(rebate.rebate_id, apiValues)
            .then(() => {
                rebate.rules.forEach((rule) => {
                    rule.postal_codes = allCodes;
                })

                const newValues = sidepanelProps.createNewValues(rebate);
                sidepanelProps.syncRows(newValues);

                toggle();
                success();
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    return (
        <AnalyticsModal analyticsName='RebatesEditPostalCodes' isOpen={isOpen}>
            <AddPostalCodesModalInner
                toggle={toggle}
                onSubmit={postalCodesOnSubmit}
                canEdit
                selectedPostalCodes={selectedPostalCodes}
                isEdit
            />
        </AnalyticsModal>
    );
}

export default RebatesEditPostalCodes;