import { useContext, useEffect, useRef, useState } from "react"
import { Button, ModalBody, ModalFooter, Spinner } from "reactstrap"
import PerfectScrollbar from 'react-perfect-scrollbar'
import UserDisplay from "../../../../../components/userDisplay/UserDisplay"
import { DisplayI18n } from "../../../../../helpers/i18nHelper"
import Translate, { DateFormat } from "@spordle/intl-elements"
import CrossFade from "../../../../../components/crossFade/CrossFade"
import { PeriodsContext } from "../../../../../contexts/contexts"
import moment from "moment";
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI"
import { InstallmentModesContext } from "../../../../../contexts/InstallmentModesContext"
import { OrganizationContext } from "../../../../../contexts/OrganizationContext"
import CustomAnimatedIcon from "../../../../../components/customAnimatedIcon/CustomAnimatedIcon"

const RolloverModalConfirmation = ({ selectedInstallments, goToPrevious, toggle, refreshTable }) => {

    const [ promises, setPromises ] = useState(selectedInstallments.reduce((promisesObject, r) => {
        promisesObject[r.installment_mode_id] = false;
        return promisesObject
    }, {}))
    const [ hasFinished, setHasFinished ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const formikRef = useRef();

    const formattedInstallments = selectedInstallments.map((installment) => ({
        ...installment,
        installment_options: installment.installment_options.map((option) => {
            const date = moment(option.installment_option_percentage_date, 'YYYY-MM-DD');
            return {
                ...option,
                installment_option_percentage_date: date.set('year', date.get('year') + 1),
            }
        }),
    }))

    useEffect(() => {
        if(Object.keys(promises).every((key) => promises[key])){ // every promise is resolved
            setHasFinished(true);
            setIsSubmitting(false);
            formikRef.current?.setSubmitting(false);
        }
    }, [ promises ])

    return (
        <>
            <ModalBody className="overflow-hidden d-flex flex-column" style={{ maxHeight: '70vh' }}>
                <p className="h6 font-bold text-center mb-3">
                    <Translate id='catalog.installment.rollover.confirm' />
                </p>

                <PerfectScrollbar options={{ suppressScrollX: true }} className="px-3 mx-n3 min-h-0">
                    {formattedInstallments.map((row) => (
                        <RegistrationFeeCard
                            key={row.installment_mode_id}
                            row={row}
                            onFinally={(installmentId) => setPromises((prev) => ({ ...prev, [installmentId]: true }))}
                            isSubmitting={isSubmitting}
                        />
                    ))}
                </PerfectScrollbar>
            </ModalBody>
            <CrossFade isVisible={!hasFinished}>
                <ModalFooter className="justify-content-between">
                    <Button color='primary' outline type='button' onClick={goToPrevious} disabled={isSubmitting}><Translate id='misc.previous' /></Button>
                    <Button color='primary' type='button' onClick={() => setIsSubmitting(true)} disabled={isSubmitting}><Translate id='misc.create' /></Button>
                </ModalFooter>
            </CrossFade>
            <CrossFade isVisible={hasFinished}>
                <ModalFooter>
                    <Button
                        color='primary'
                        type='button'
                        disabled={isSubmitting}
                        onClick={() => {
                            toggle();
                            refreshTable();
                        }}
                    >
                        <Translate id='misc.close' />
                    </Button>
                </ModalFooter>
            </CrossFade>
        </>
    )
}

const RegistrationFeeCard = ({ row, onFinally, isSubmitting }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ status, setStatus ] = useState(null);
    const [ hasSubmitted, setHasSubmitted ] = useState(false);

    const orgContext = useContext(OrganizationContext);
    const periodsContext = useContext(PeriodsContext);
    const installmentsContext = useContext(InstallmentModesContext)

    useEffect(() => {
        if(isSubmitting){
            setIsLoading(true);
            setHasSubmitted(true);

            const newValues = {
                organisation_id: orgContext.organisation_id,
                period_id: periodsContext.selectedPeriod.period_id,
                number_of_payment: row.installment_options.length,
                name: row.name,
                initial_installment_percentage: row.initial_installment_percentage,
                installment_options: row.installment_options.map((option) => ({
                    installment_option_percentage: option.installment_option_percentage,
                    installment_option_percentage_date: option.installment_option_percentage_date.format('YYYY-MM-DD'),
                })),
            }

            installmentsContext.createInstallmentMode(newValues)
                .then(() => {
                    setIsLoading(false);
                    setStatus('success');
                })
                .catch((error) => {
                    if(!AxiosIsCancelled(error.message)){
                        console.error(error.message);
                        setIsLoading(false);
                        setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                    }
                })
                .finally(() => {
                    onFinally?.(row.installment_mode_id)
                })
        }
    }, [ isSubmitting ])

    return (
        <UserDisplay
            card block
            className='mb-2 align-items-center'
        >
            <UserDisplay.Container className='w-100'>
                <UserDisplay.Title>
                    <DisplayI18n field='name' defaultValue={row.name} i18n={row.i18n} />
                </UserDisplay.Title>
                <CrossFade isVisible={!hasSubmitted}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="text-center">
                            <span className="font-medium">{row.initial_installment_percentage}%</span> <Translate id='catalog.installment.table.initialPayment.text' />
                        </div>

                        <div className="text-center">
                            <ul className="list-unstyled d-table mb-0">
                                {row.installment_options.map((opt, i) => (
                                    <li key={opt.installment_option_id || row.installment_mode_id + opt.installment_option_percentage_date} className="d-table-row">
                                        <div className="d-table-cell px-1 text-nowrap">- <span className="font-medium">{opt.installment_option_percentage}%</span></div>
                                        <div className="d-table-cell px-1 text-nowrap"><Translate id='catalog.installment.rule.on' /></div>
                                        <div className="d-table-cell px-1 text-nowrap"><DateFormat value={opt.installment_option_percentage_date} /></div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </CrossFade>
                <CrossFade isVisible={!!status && status !== 'success'}>
                    <UserDisplay.Subtitle className="text-danger">
                        {status}
                    </UserDisplay.Subtitle>
                </CrossFade>
            </UserDisplay.Container>
            <CrossFade isVisible={hasSubmitted}>
                <UserDisplay.Container className='ml-auto'>
                    {isLoading &&
                        <Spinner color='primary' style={{ width: '25px', height: '25px', borderWidth: '1px' }} />
                    }
                    {((!isLoading && status)) &&
                        <CustomAnimatedIcon
                            id={'customStatusIcon-' + row.installment_mode_id}
                            size='25px'
                            icon={status === 'success' ? 'checkmark' : 'error'}
                            withCircle
                            className={status === 'success' ? 'text-success' : 'text-danger'}
                        />
                    }
                </UserDisplay.Container>
            </CrossFade>
        </UserDisplay>
    )
}

export default RolloverModalConfirmation