import { stringBuilder } from "@spordle/helpers";
import Translate from "@spordle/intl-elements";
import React from "react";
import {
    Badge,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import { AxiosIsCancelled } from "../../../../../../api/CancellableAPI";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import OverlayLoader from "../../../../../../components/loading/OverlayLoader";
import SidePanel from "../../../../../../components/sidePanel/SidePanel";
import { fail, success } from '@spordle/toasts';
import UserDisplay from "../../../../../../components/userDisplay/UserDisplay";
import UserImg from "../../../../../../components/UserImg";
import { PeriodsContext } from '../../../../../../contexts/contexts';
import { I18nContext } from "../../../../../../contexts/I18nContext";
import { MembersContext } from '../../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { displayI18n, DisplayI18n } from "../../../../../../helpers/i18nHelper";
// Contexts
import withContexts from '../../../../../../helpers/withContexts';
import OnlineStoreTransactionRefundModal from "../../../../../onlineStore/profile/transactions/components/modals/refundModal/OnlineStoreTransactionRefundModal";
import MemberProfileTransactionsSidepanelInvoice from "../../../transactions/components/Sidepanel/MemberProfileTransactionsSidepanelInvoice";
import MemberProfileRegistrationAddContext from '../AddModal/MemberProfileRegistrationAddContext';
import MemberProfileItemCancel from "../CancelItems/MemberProfileItemCancel";
import MemberProfileRegistrationConfirmWaivers from "../MemberProfileRegistrationConfirmWaivers";
import ConfirmCancelledBy, { getConfirmCancelled } from "./components/ConfirmCancelledBy";
import MemberProfileRegistrationSidepanelForms from "./components/MemberProfileRegistrationSidepanelForm";
import MemberProfileRegistrationSidepanelRegistration from "./components/MemberProfileRegistrationSidepanelRegistration";
import QuickViewButton from "../../../../../../components/quickView/QuickViewButton";
import UpdateInvoicePositionModal from "../../../../../../components/updateInvoicePositionModal/UpdateInvoicePositionModal";
import InlineCopy from "../../../../../../components/inlineCopy/InlineCopy";


class MemberProfileRegistrationSidepanel extends React.Component{

    constructor(props){
        super(props);

        this.toggleTabs = this.toggleTabs.bind(this);
        this.state = {
            activeTab: '1',
            cancelModal: false,
            waiversModal: false,
            refundModal: false,
            exchangeModal: false,
            updateInvoicePositionModalOpen: false,

            mode: '',

            refundAmounts: false,

            isLoading: false,
        };

        this.modes = {
            refundToCancel: 'refundToCancel',
            cancelToRefund: 'cancelToRefund',
        }

        this.waiverType = 'WAIVER'
    }

    componentDidMount(){
        if(this.props.selectedRows[0].invoice_number && this.props.selectedRows[0].invoice_in_current_organisation == 1){
            this.getRefundAmounts(this.props.selectedRows[0].invoice_number);
        }
    }

    componentDidUpdate(prevState){
        if(this.props.selectedRows[0].invoice_number && prevState.selectedRows[0].invoice_number !== this.props.selectedRows[0].invoice_number && this.props.selectedRows[0].invoice_in_current_organisation == 1){
            this.getRefundAmounts(this.props.selectedRows[0].invoice_number);
        }
    }

    toggleTabs(tab){
        if(this.state.activeTab !== tab){
            this.setState({
                activeTab: tab,
            });
        }
    }

    toggleModal = (modal) => {
        this.setState((prevState) => ({ [modal]: !prevState[modal] }));
    };

    getRefundAmounts = (invoiceNumber) => {
        this.setState(() => ({ refundAmounts: false }));
        this.props.MembersContext.getRefundAmounts(invoiceNumber)
            .then((refundAmounts) => {
                this.setState(() => ({ refundAmounts: refundAmounts }))
            })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                }
            })
    }

    getStatusBadge = () => {
        switch (getConfirmCancelled(this.props.selectedRows[0].memberRegistration)){
            case "CONFIRMED":
                return <Badge color="success" size="lg" className="px-3 align-self-start"><Translate id='memberProfile.registration.status.confirmed' /></Badge>
            case "PENDING":
                return <Badge color="warning" size="lg" className="px-3 align-self-start"><Translate id='memberProfile.registration.status.pending' /></Badge>
            case "CANCELLED":
                return <Badge color="danger" size="lg" className="px-3 align-self-start"><Translate id='memberProfile.registration.status.cancelled' /></Badge>
            default:
                return ''
        }
    }

    refreshTable = () => {
        this.props.forceCloseSidePanel();
        this.props.tableRef.refreshTable();
    }

    formatAndSyncRows = () => {
        this.setState(() => ({
            isLoading: true,
        }))

        // ATTENTION: Logic is used from formatData in MemberProfileRegistration, if changes are made make sure they are reflected in both places
        // get specific invoice
        return this.props.MembersContext.getMemberInvoices(this.props.MembersContext.currentMember.member_id, {
            organisation_id: this.props.OrganizationContext.organisation_id,
            period_id: this.props.PeriodsContext.selectedPeriod.period_id,
            invoice_number: this.props.selectedRows[0].invoice_number,
        }).then((fetchedInvoice) => {
            this.props.syncRows(this.props.formatSingleInvoiceData(fetchedInvoice[0]));
            this.setState(() => ({ isLoading: false }));
        }).catch(() => {
            this.setState(() => ({ isLoading: false }));
        })
            .catch((error) => {
                if(!AxiosIsCancelled(error.message)){
                    console.error(error.message)
                    fail({
                        msg: 'misc.error',
                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                        skipInfoTranslate: true,
                    })
                    this.setState(() => ({
                        isLoading: false,
                    }))
                }
            })
    }

    render(){
        return (
            <OverlayLoader isLoading={this.state.isLoading}>
                <SidePanel.Header noBorder>
                    <div className='d-flex mb-2'>
                        <SidePanel.ToggleButton />

                        {/* MODALS */}
                        <MemberProfileRegistrationConfirmWaivers
                            isOpen={this.state.waiversModal}
                            toggle={() => this.toggleModal('waiversModal')}
                        />
                        <MemberProfileItemCancel
                            isOpen={this.state.cancelModal}
                            toggle={() => this.toggleModal('cancelModal')}
                            toggleRefund={() => this.toggleModal('refundModal')}
                            chainWithRefund={this.state.mode === this.modes.cancelToRefund}
                            onCancel={() => { this.formatAndSyncRows().then(this.props.setForceTransactionRefresh); }}
                            availableRefund={this.state.refundAmounts}
                            invoiceItems={this.props.selectedRows[0].invoice_items}
                            defaultItemIds={[ this.props.selectedRows[0].memberRegistration.invoice_item_id ]}
                            refreshTable={this.refreshTable}
                            refundPermissions={{
                                action: "EDIT",
                                componentCode: "members",
                                componentPermissionCode: "member_transactions",
                            }}
                        />
                        <OnlineStoreTransactionRefundModal
                            transaction={this.props.selectedRows[0]}
                            isOpen={this.state.refundModal}
                            toggle={() => {
                                this.props.setForceTransactionRefresh();
                                this.toggleModal('refundModal');
                            }}
                            refreshTable={() => this.formatAndSyncRows()}
                            chainWithCancel={this.state.mode === this.modes.refundToCancel}
                            onRefund={() => {
                                this.props.setForceTransactionRefresh();
                                this.formatAndSyncRows();
                            }}
                            onCancelRegistration={() => {
                                this.toggleModal('refundModal');
                                this.toggleModal('cancelModal');
                            }}
                        />
                        {this.state.exchangeModal &&
                            <MemberProfileRegistrationAddContext
                                isOpen={this.state.exchangeModal}
                                toggle={() => this.toggleModal('exchangeModal')}
                                refreshTable={this.refreshTable}
                                invoiceItems={this.props.selectedRows[0].invoice_items}
                                formatAndSyncRows={this.formatAndSyncRows}
                                setForceRefresh={() => this.props.setForceTransactionRefresh()}
                            />
                        }
                        <UpdateInvoicePositionModal
                            isOpen={this.state.updateInvoicePositionModalOpen}
                            toggle={() => this.toggleModal('updateInvoicePositionModalOpen')}
                            invoiceItemPosition={this.props.selectedRows[0].memberRegistration.positions?.[0]}
                            onSuccess={() => {
                                this.props.setForceTransactionRefresh();
                                this.formatAndSyncRows();
                            }}
                        />
                        <SidePanel.ActionsMenu action='EDIT' componentCode='members' componentPermissionCode='members_registrations'>
                            {/* invoice_in_current_organisation flag works for both primary organizations and shared organizations, as long as the invoice is within the current org, the flag will be 1 */}
                            {this.props.selectedRows[0].invoice_in_current_organisation == 1 &&
                                <>
                                    <ConfirmModal
                                        color='primary'
                                        icon='mdi-check-circle-outline'
                                        translateActionButtonText
                                        actionButtonText='memberProfile.registration.confirmRegistration.confirm.btn'
                                        onConfirm={() => {
                                            Promise.all([
                                                this.props.MembersContext.confirmInvoiceItem(this.props.selectedRows[0].memberRegistration.invoice_item_id),
                                                this.props.selectedRows[0].memberRegistration.cancelled_at ? this.props.MembersContext.deleteInvoiceItemCancellation(this.props.selectedRows[0].memberRegistration.invoice_item_id) : Promise.resolve(),
                                            ])
                                                .then(() => {
                                                    this.props.setForceTransactionRefresh();
                                                    this.formatAndSyncRows()
                                                    success({ msg: 'memberProfile.registration.confirmRegistration.success' });
                                                })
                                                .catch((error) => {
                                                    if(!AxiosIsCancelled(error.message)){
                                                        console.error(error.message)
                                                        if(error.i18n){
                                                            fail({
                                                                msg: 'misc.error',
                                                                info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                                skipInfoTranslate: true,
                                                            })
                                                        }else{
                                                            fail({ msg: 'memberProfile.registration.confirmRegistration.error' });
                                                        }
                                                    }
                                                })
                                        }}
                                        modalTitle={<Translate id='memberProfile.registration.confirmRegistration.confirm' values={{ registrationName: displayI18n('name', this.props.selectedRows[0].memberRegistration.registration_fee?.i18n, this.props.selectedRows[0].memberRegistration.registration_fee?.name, this.props.I18nContext.getGenericLocale()) }} />}
                                        toggler={(fn) => (
                                            <SidePanel.MenuAction onClick={fn} disabled={!!this.props.selectedRows[0].memberRegistration.confirmed_at || this.props.selectedRows[0].type === this.waiverType || this.props.selectedRows[0].type === 'AFFILIATION'}>
                                                <Translate id='memberProfile.registration.sidepanel.confirmRegistration' />
                                                {!!this.props.selectedRows[0].memberRegistration.confirmed_at && <div className='small'><Translate id='memberProfile.registration.sidepanel.confirmRegistration.already' /></div>}
                                            </SidePanel.MenuAction>
                                        )}
                                    />
                                    <SidePanel.MenuAction disabled onClick={() => this.toggleModal('waiversModal')}>
                                        <Translate id='memberProfile.registration.sidepanel.confirmWaivers' />
                                    </SidePanel.MenuAction>

                                    <SidePanel.MenuAction divider />
                                    <SidePanel.MenuAction
                                        onClick={() => {
                                            this.setState(() => ({ mode: this.modes.cancelToRefund }))
                                            this.toggleModal('cancelModal')
                                        }}
                                        disabled={!!this.props.selectedRows[0].memberRegistration.cancelled_at || this.props.selectedRows[0].type === this.waiverType || this.props.selectedRows[0].type === 'AFFILIATION'}
                                    >
                                        <Translate id='memberProfile.registration.sidepanel.cancelRegistration' />
                                        {!!this.props.selectedRows[0].memberRegistration.cancelled_at && <div className='small'><Translate id='memberProfile.registration.sidepanel.cancelRegistration.already' /></div>}
                                    </SidePanel.MenuAction>

                                    {/* REFUND  */}
                                    <SidePanel.MenuAction
                                        onClick={() => {
                                            this.setState(() => ({ mode: this.modes.refundToCancel }))
                                            this.toggleModal('refundModal')
                                        }}
                                        disabled={parseInt(this.state.refundAmounts.payment_gateway) <= 0 && parseInt(this.state.refundAmounts.manual) <= 0}
                                    >
                                        <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.refund' />
                                    </SidePanel.MenuAction>

                                    <SidePanel.MenuAction
                                        onClick={() => this.toggleModal('exchangeModal')}
                                        disabled={
                                            this.props.selectedRows[0].refunds.length > 0 ||
                                            !!this.props.selectedRows[0].invoice_items.find((item) => item.registration_fee)?.payments.find((payment) => payment.payment_type === 'INSTALLMENT' && payment.status === 'PENDING' && payment.payment_method?.code === "CREDITCARD") ||
                                            this.props.selectedRows[0].type === this.waiverType ||
                                            this.props.selectedRows[0].type === 'AFFILIATION'
                                        }
                                    >
                                        <Translate id='memberProfile.registration.sidepanel.exchangeItems' />
                                    </SidePanel.MenuAction>

                                    {this.props.selectedRows[0].memberRegistration.positions && this.props.selectedRows[0].memberRegistration.positions[0] &&
                                        <SidePanel.MenuAction
                                            onClick={() => this.toggleModal('updateInvoicePositionModalOpen')}
                                        >
                                            <Translate id='components.updateInvoicePositionModal.header' />
                                        </SidePanel.MenuAction>
                                    }

                                    <SidePanel.MenuAction divider />
                                </>
                            }
                            {/* RESEND INVOICE */}
                            <SidePanel.MenuAction
                                onClick={() => {
                                    this.props.MembersContext.resendInvoice([ this.props.selectedRows[0].invoice_number ], false)
                                        .then(() => {
                                            success();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                if(error.i18n){
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }else{
                                                    fail({ msg: 'members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice.error' });
                                                }
                                            }
                                        })
                                }}
                            >
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice' />
                            </SidePanel.MenuAction>

                            <SidePanel.MenuAction
                                onClick={() => {
                                    this.props.MembersContext.resendInvoice([ this.props.selectedRows[0].invoice_number ], true)
                                        .then(() => {
                                            success();
                                        })
                                        .catch((error) => {
                                            if(!AxiosIsCancelled(error.message)){
                                                console.error(error.message)
                                                if(error.i18n){
                                                    fail({
                                                        msg: 'misc.error',
                                                        info: <DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />,
                                                        skipInfoTranslate: true,
                                                    })
                                                }else{
                                                    fail({ msg: 'members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoice.error' });
                                                }
                                            }
                                        })
                                }}
                            >
                                <Translate id='members.profile.transactions.memberProfileTransactions.sidePanel.actions.resendInvoicesWithReceipts' />
                            </SidePanel.MenuAction>

                            <SidePanel.MenuAction disabled>
                                <Translate id='memberProfile.registration.sidepanel.sendWaivers' />
                            </SidePanel.MenuAction>
                        </SidePanel.ActionsMenu>
                    </div>
                    <SidePanel.Subtitle>
                        <DisplayI18n
                            field='name'
                            defaultValue={this.props.PeriodsContext.selectedPeriod.name}
                            i18n={this.props.PeriodsContext.selectedPeriod.i18n}
                        />
                    </SidePanel.Subtitle>
                    <SidePanel.Title>
                        {this.props.selectedRows[0].type === this.waiverType ?
                            <Translate id='memberProfile.registration.form.registration.manual' />
                            : this.props.selectedRows[0].type === 'AFFILIATION' ?
                                <Translate id='memberProfile.registration.form.registration.affiliation' />
                                :
                                <DisplayI18n
                                    field='name'
                                    defaultValue={this.props.selectedRows[0].memberRegistration.registration_fee?.fee.name}
                                    i18n={this.props.selectedRows[0].memberRegistration.registration_fee?.fee.i18n}
                                />
                        }
                        <SidePanel.Subtitle className="mt-1">
                            {this.getStatusBadge()}
                        </SidePanel.Subtitle>
                    </SidePanel.Title>
                    <UserDisplay className="mt-3">
                        <UserDisplay.Container>
                            <UserImg
                                abbr={this.props.selectedRows[0].memberRegistration.member.first_name.substring(0, 1) + this.props.selectedRows[0].memberRegistration.member.last_name.substring(0, 1)}
                                src={undefined}
                                width={40}
                                alt={this.props.selectedRows[0].memberRegistration.member.first_name + ' ' + this.props.selectedRows[0].memberRegistration.member.last_name}
                            />
                        </UserDisplay.Container>
                        <UserDisplay.Container>
                            <UserDisplay.Title>
                                <QuickViewButton member={this.props.selectedRows[0].memberRegistration.member}>
                                    {this.props.selectedRows[0].memberRegistration.member.first_name + ' ' + this.props.selectedRows[0].memberRegistration.member.last_name}
                                </QuickViewButton>
                            </UserDisplay.Title>
                            <InlineCopy className="font-medium userDisplay-subtitle" toCopy={this.props.selectedRows[0].memberRegistration.member.unique_identifier}>
                                {this.props.selectedRows[0].memberRegistration.member.unique_identifier || '-'}
                            </InlineCopy>
                        </UserDisplay.Container>
                    </UserDisplay>

                    {this.props.selectedRows[0].organisation &&
                        <div className="my-3">
                            <div className="text-muted"><Translate id='memberProfile.registration.sidepanel.primaryOrg' /></div>
                            <div className="font-medium">
                                <DisplayI18n
                                    field='name'
                                    i18n={this.props.selectedRows[0].organisation.i18n}
                                    defaultValue={this.props.selectedRows[0].organisation.organisation_name}
                                />
                            </div>
                        </div>
                    }
                    <ConfirmCancelledBy invoiceItem={this.props.selectedRows[0].memberRegistration} />
                </SidePanel.Header>
                {this.props.selectedRows[0].invoice_in_current_organisation == 1 &&
                    <Nav tabs style={{ overflow: "hidden" }}>
                        <NavItem className="text-center">
                            <NavLink
                                className={stringBuilder({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggleTabs('1'); }}
                            >
                                <Translate id='memberProfile.registration.sidepanel.registration' />
                            </NavLink>
                        </NavItem>
                        <NavItem className="text-center">
                            <NavLink
                                className={stringBuilder({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggleTabs('2'); }}
                            >
                                <Translate id='memberProfile.registration.transaction' />
                            </NavLink>
                        </NavItem>
                        <NavItem className="text-center">
                            <NavLink
                                // disabled
                                className={stringBuilder({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggleTabs('3'); }}
                            >
                                <Translate id='memberProfile.registration.sidepanel.form' />
                            </NavLink>
                        </NavItem>
                    </Nav>
                }
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <MemberProfileRegistrationSidepanelRegistration {...this.props} />
                    </TabPane>
                    {this.props.selectedRows[0].invoice_in_current_organisation == 1 &&
                        <>
                            <TabPane tabId="2">
                                <MemberProfileTransactionsSidepanelInvoice {...this.props} hideHeader />
                                {/* <MemberProfileTransactionsSidepanelInvoice {...this.props} onlineStoreView={this.props.onlineStoreView} hideHeader /> */}
                            </TabPane>
                            <TabPane tabId="3">
                                <MemberProfileRegistrationSidepanelForms
                                    registration={this.props.selectedRows[0].memberRegistration}
                                    invoiceNumber={this.props.selectedRows[0].invoice_number}
                                    {...this.props}
                                />
                            </TabPane>
                        </>
                    }
                </TabContent>
            </OverlayLoader>
        )
    }
}

export default withContexts(OrganizationContext, MembersContext, PeriodsContext, I18nContext)(MemberProfileRegistrationSidepanel);