import { FormikDateTime, FormikInputText, FormikSelect, FormikTextArea } from '@spordle/formik-elements';
import { stringBuilder } from '@spordle/helpers';
// Language
import Translate from '@spordle/intl-elements';
import { formatSelectData } from "@spordle/spordle-select";
import { Form, Formik } from 'formik';
import moment from 'moment';
import React from "react";
import {
    Button, Card, CardBody, CardFooter, CardHeader, Col, Collapse, Fade, Label, Row
} from 'reactstrap';
import { string, object, array, mixed } from 'yup';
import { AxiosIsCancelled } from "../../../../../api/CancellableAPI";
import CardSectionTitle from "../../../../../components/CardSectionTitle";
import OverlayLoader from "../../../../../components/loading/OverlayLoader";
import CanDoAction from "../../../../../components/permissions/CanDoAction";
import { I18nContext } from "../../../../../contexts/I18nContext";
import { memberTypeSpecial } from '../../../../settings/organization/components/memberTypes/types/components/memberTypesConstants'
// Contexts
import { MembersContext } from '../../../../../contexts/MembersContext';
import { OrganizationContext } from '../../../../../contexts/OrganizationContext';
import { UtilsContext } from "../../../../../contexts/UtilsContext";
import { RolesContext } from "../../../../../contexts/RolesContext";
import { DisplayI18n } from "../../../../../helpers/i18nHelper";
import withContexts from '../../../../../helpers/withContexts';
import { PeriodsContext } from '../../../../../contexts/contexts';
import CustomAlert from '../../../../../components/CustomAlert';
import MemberGeneralEditTopSection from './MemberGeneralEditTopSection';
import IsGod from '../../../../../components/permissions/IsGod';
import { isARequiredSpecialField } from '@spordle/cart-engine';
import { arraysEqual } from '../../../../../helpers/helper';
import { InlineConfirmation } from '../../../../../components/InlineConfirmation';
import { MemberAddressPhoneEntry } from './memberAddressPhone/MemberAddressPhone';
import GenderLabel from '../../../../../components/GenderLabel';
import MemberFieldLabel from '../../../../../components/memberRegistration/MemberFieldLabel';
import { getMemberTypeIcon } from '../../../../settings/organization/components/memberTypes/types/components/memberTypesIcons';
import DisplayBirthdateAge from '../../../../../components/DisplayBirthdateAge';
import DisplayMemberType from '../../../../../components/display/DisplayMemberType';
import { mutate } from 'swr';
import { fire } from '@spordle/toasts';

class MemberGeneral extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            edit: false,
        };
    }

    toggleEdit = () => {
        this.setState((prevState) => ({ edit: !prevState.edit }))
    }

    canModifyMemberField = (code) => {
        return !this.props.OrganizationContext.settings?.request_mandatory_change_fields?.value?.some((field) => (field === code))
    }

    isValidDob = (dob) => {
        const settings = this.props.OrganizationContext.settings;
        const minDateOfBirth = moment().subtract(parseInt(settings.member_min_age?.value || "0"), "year");
        const maxDateOfBirth = moment().subtract(parseInt(settings.member_max_age?.value || "0"), "year");
        return (!settings.member_min_age || dob.isSameOrBefore(minDateOfBirth)) && (!settings.member_max_age || dob.isAfter(maxDateOfBirth));
    }

    primaryAddressIsInvalid = () => this.props.primaryAddress?.status !== 'CONFIRMED' && this.props.primaryAddress?.status !== "REJECTED";

    render(){
        const currentMemberTypes = this.props.MembersContext.currentMember.member_types?.reduce((newArray, type) => {
            if(type.current_season == 1 && type.organisation.organisation_id === this.props.OrganizationContext.organisation_id && !newArray.some((n) => n.member_type.member_type_id === type.member_type.member_type_id))
                newArray.push(type)
            return newArray
        }, []) || [];

        return (
            <>
                {!this.state.edit ?
                    <Fade>
                        <Card id='profileComponentMemberPrimaryInfo' body className={stringBuilder({ 'shadow-danger card-error': this.props.showRedBorder || this.primaryAddressIsInvalid() }, 'card-shadow')}>
                            <CardSectionTitle className="d-flex" title='members.profile.overview.memberPrimaryInformation.title'>
                                {!this.props.MembersContext.currentMember.minimalistic_view &&
                                    <CanDoAction className='ml-auto text-link' action='EDIT' componentCode='members' componentPermissionCode='members_profile'>
                                        <button className="reset-btn" type="button" onClick={this.toggleEdit}><Translate id='misc.edit' /></button>
                                    </CanDoAction>
                                }
                            </CardSectionTitle>
                            <Row>
                                <Col md='6'>
                                    <div className='mb-3'>
                                        <MemberFieldLabel
                                            className="text-muted mb-0"
                                            hideDescription
                                            field="first_name"
                                            fallbackLabel={<Translate id='form.fields.firstName' />}
                                            isRequired={!this.props.MembersContext.currentMember.first_name}
                                        />
                                        <div className='font-medium' id='memberInfo-firstName'>{this.props.MembersContext.currentMember.first_name}</div>
                                    </div>
                                </Col>
                                <Col md='6'>
                                    <div className='mb-3'>
                                        <MemberFieldLabel
                                            className="text-muted mb-0"
                                            hideDescription
                                            field="last_name"
                                            fallbackLabel={<Translate id='form.fields.lastName' />}
                                            isRequired={!this.props.MembersContext.currentMember.last_name}
                                        />
                                        <div className='font-medium' id='memberInfo-lastName'>{this.props.MembersContext.currentMember.last_name}</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md='6'>
                                    <div className='mb-3'>
                                        <MemberFieldLabel
                                            className="text-muted mb-0"
                                            hideDescription
                                            field="birthdate"
                                            fallbackLabel={<Translate id='form.fields.dateOfBirth' />}
                                            isRequired={!this.props.MembersContext.currentMember.birthdate}
                                        />
                                        <div className='font-medium' id='memberInfo-birthdate'><DisplayBirthdateAge age={this.props.MembersContext.currentMember.age} birthdate={this.props.MembersContext.currentMember.birthdate} defaultValue=" - " /></div>
                                    </div>
                                </Col>
                                <Col md='6'>
                                    <div className='mb-3'>
                                        <MemberFieldLabel
                                            className="text-muted mb-0"
                                            hideDescription
                                            field="email"
                                            fallbackLabel={<Translate id='form.fields.email' />}
                                            withSpecialRequired={!this.props.MembersContext.currentMember.email}
                                        />
                                        <div className='font-medium' id='memberInfo-email'>{this.props.MembersContext.currentMember.email || '-'}</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md='6'>
                                    <div className='mb-3'>
                                        <MemberFieldLabel
                                            className="text-muted mb-0"
                                            hideDescription
                                            field="gender"
                                            fallbackLabel={<Translate id='form.fields.gender' />}
                                            isRequired={!this.props.MembersContext.currentMember.gender}
                                        />
                                        {this.props.MembersContext.currentMember.gender ?
                                            <div className='font-medium' id='memberInfo-gender'>
                                                <GenderLabel gender={this.props.MembersContext.currentMember.gender} />
                                            </div>
                                            :
                                            ' - '
                                        }
                                        {this.props.MembersContext.currentMember.gender_description &&
                                            <small className="d-block">
                                                {this.props.MembersContext.currentMember.gender_description}
                                            </small>
                                        }
                                    </div>
                                </Col>
                                <Col md='6'>
                                    <div className='mb-3'>
                                        <div className='text-muted'>
                                            <Translate id='members.profile.overview.memberPrimaryInformation.memberTypes' /> <small>(<DisplayI18n field='name' defaultValue={this.props.OrganizationContext.organisation_name} i18n={this.props.OrganizationContext.i18n} />)</small>
                                        </div>
                                        <div className='font-medium' id='memberInfo-specialMemberTypes'>
                                            {!currentMemberTypes?.length ?
                                                '-'
                                                :
                                                currentMemberTypes
                                                    // ?.filter((m) => m.member_type.system_type !== memberTypeSpecial)
                                                    .map((m) => (
                                                        <div
                                                            key={m.member_type.member_type_id}
                                                            data-specialMemberTypeId={m.member_type.member_type_id}
                                                        >
                                                            <DisplayMemberType member={this.props.MembersContext.currentMember} memberType={m} />
                                                        </div>
                                                    ))
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md='6'>
                                    <div className='mb-3'>
                                        <MemberFieldLabel
                                            className="text-muted mb-0"
                                            hideDescription
                                            field="primary_language"
                                            fallbackLabel={<Translate id='form.fields.primaryLanguage' />}
                                            withSpecialRequired={!this.props.MembersContext.currentMember.primary_language}
                                        />
                                        {this.props.MembersContext.currentMember.primary_language ?
                                            <span className='font-medium' id='memberInfo-lang-primary'><Translate id={'misc.' + this.props.MembersContext.currentMember.primary_language + '.long'} /></span>
                                            : '-'}
                                    </div>
                                </Col>
                                <Col md='6'>
                                    <div className='mb-3'>
                                        <MemberFieldLabel
                                            className="text-muted mb-0"
                                            hideDescription
                                            field="secondary_languages"
                                            fallbackLabel={<Translate id='form.fields.secondaryLanguages' />}
                                            withSpecialRequired={this.props.MembersContext.currentMember.secondary_languages?.length === 0}
                                        />
                                        <span id='memberInfo-lang-secondary'>
                                            {this.props.MembersContext.currentMember.secondary_languages?.length === 0 ?
                                                <span className='font-medium'>-</span>
                                                :
                                                this.props.MembersContext.currentMember.secondary_languages?.filter((lang) => lang !== this.props.MembersContext.currentMember.primary_language).map((lang, key) => (
                                                    <span key={lang}>
                                                        <span className='font-medium' data-langcode={lang}><Translate id={'misc.' + lang + '.long'} /></span>
                                                        {key !== this.props.MembersContext.currentMember.secondary_languages.length - 1 ? ', ' : ''}
                                                    </span>
                                                ))
                                            }
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <MemberAddressPhoneEntry
                                key={this.props.primaryAddress?.member_address_id || 0}
                                index={0}
                                isPrimary
                                address={this.props.primaryAddress}
                                toggleAddressConfirmationModal={this.props.toggleAddressConfirmationModal}
                                toggleCreateEditModal={this.props.toggleCreateEditModal}
                                getAddresses={this.props.getAddresses}
                                isCurrentOrganizationOrParent={this.props.isCurrentOrganizationOrParent}
                            />
                        </Card>
                    </Fade>
                    :
                    <Formik
                        initialValues={{
                            firstName: this.props.MembersContext.currentMember.first_name,
                            lastName: this.props.MembersContext.currentMember.last_name,
                            dateOfBirth: this.props.MembersContext.currentMember.birthdate ? moment(this.props.MembersContext.currentMember.birthdate) : '',
                            email: this.props.MembersContext.currentMember.email || '',
                            gender: this.props.MembersContext.currentMember.gender || '',
                            gender_description: this.props.MembersContext.currentMember.gender_description || '',
                            primaryLanguage: this.props.MembersContext.currentMember.primary_language || '',
                            secondaryLanguages: this.props.MembersContext.currentMember.secondary_languages || [],
                            member_types: currentMemberTypes?.map((m) => (m.member_type.member_type_id)) || [],

                            enableRequestChangeFields: false, // state here because it is easier to manage inside formik
                        }}
                        validationSchema={object().shape({
                            firstName: string().required(<Translate id='form.validation.firstName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='members.search.memberAdd.firstName.regex' /> }),
                            lastName: string().required(<Translate id='form.validation.lastName.required' />).matches(/^[a-zA-ZÀ-ÿ-'.]+( [a-zA-ZÀ-ÿ-'.]+)*$/, { message: <Translate id='members.search.memberAdd.lastName.regex' /> }),
                            dateOfBirth: mixed().required(<Translate id='form.validation.dateOfBirth.required' />).test({
                                name: 'dateOfBirthFormat',
                                message: <Translate id='form.validation.dateOfBirth.valid' />,
                                test: moment.isMoment,
                            })
                                .test({
                                    name: 'dateOfBirthNotToday',
                                    message: <Translate id='members.search.memberAdd.dateOfBirth.minDate' />,
                                    test: function(dateOfBirth){
                                        return moment(dateOfBirth).isBefore(moment());
                                    },
                                })
                                .test({
                                    name: 'age-settings',
                                    message: "age_settings",
                                    test: (dob) => {
                                        if(moment.isMoment(dob)){
                                            return this.isValidDob(dob);
                                        }

                                        return true;
                                    },
                                }),
                            email: string().email(<Translate id='form.validation.email.valid' />).isRequiredSpecialField(isARequiredSpecialField('email', this.props.OrganizationContext.settings), <Translate id='form.validation.email.required' />),
                            gender: string().required(<Translate id='form.validation.gender.required' />),
                            gender_description: string(),
                            primaryLanguage: string().isRequiredSpecialField(isARequiredSpecialField('primary_language', this.props.OrganizationContext.settings), <Translate id='form.validation.primaryLanguage.required' />),
                            secondaryLanguages: array(),
                        })}
                        onSubmit={({ member_types, ...values }, { setStatus, setSubmitting }) => {
                            const currentMember = this.props.MembersContext.currentMember
                            const formattedNewDate = moment.isMoment(values.dateOfBirth) ? values.dateOfBirth.format('YYYY-MM-DD') : moment(values.dateOfBirth).format('YYYY-MM-DD');

                            const apiValues = {
                                first_name: (this.canModifyMemberField("first_name") || values.enableRequestChangeFields) && values.firstName !== currentMember.first_name ? values.firstName : null,
                                last_name: (this.canModifyMemberField("last_name") || values.enableRequestChangeFields) && values.lastName !== currentMember.last_name ? values.lastName : null,
                                birthdate: (this.canModifyMemberField("birthdate") || values.enableRequestChangeFields) && formattedNewDate !== currentMember.birthdate ? formattedNewDate : null,
                                email: (this.canModifyMemberField("email") || values.enableRequestChangeFields) && values.email !== currentMember.email ? values.email : null,
                                gender: (this.canModifyMemberField("gender") || values.enableRequestChangeFields) && values.gender !== currentMember.gender ? values.gender : null,
                                gender_description: (this.canModifyMemberField("gender_description") || values.enableRequestChangeFields) && values.gender_description != (this.props.MembersContext.currentMember.gender_description || '') ? values.gender_description : null,
                                primary_language: (this.canModifyMemberField("primary_language") || values.enableRequestChangeFields) && values.primaryLanguage !== currentMember.primary_language ? values.primaryLanguage : null,
                                secondary_languages: (this.canModifyMemberField("secondary_languages") || values.enableRequestChangeFields) && values.secondaryLanguages?.join() !== currentMember.secondary_languages?.join() ? values.secondaryLanguages : null,
                            }

                            Promise.all([
                                Object.keys(apiValues).some((key) => !!apiValues[key]) ?
                                    this.props.MembersContext.partiallyUpdateMember(this.props.MembersContext.currentMember.member_id, apiValues)
                                        .then((res) => {
                                            if((res.warnings || []).length > 0){
                                                fire({
                                                    theme: 'info',
                                                    msg: "misc.warning",
                                                    info:
                                                        <ul className="mb-0 pl-3">
                                                            {res.warnings.map((warning) => (
                                                                <li key={warning.origin + warning.code}>
                                                                    <DisplayI18n field="message" i18n={warning.i18n} defaultValue={warning.message} />
                                                                </li>
                                                            ))}
                                                        </ul>,
                                                });
                                            }
                                        })
                                    :
                                    Promise.resolve(),
                                this.props.RolesContext.canDoAction('EDIT', 'members', 'member_types') ?
                                    this.props.MembersContext.linkMemberTypesToMember(this.props.MembersContext.currentMember.member_id, {
                                        member_type_id: member_types.length > 0 ? member_types : '',
                                        period_id: this.props.PeriodsContext.selectedPeriod.period_id,
                                        organisation_id: this.props.OrganizationContext.organisation_id,
                                    })
                                        // global swr mutate because this is a class component and we cant use the hook here
                                        .then(() => mutate([ 'getMemberTypeRemovalRequests', this.props.MembersContext.currentMember.member_id ]))
                                    :
                                    Promise.resolve(),
                            ])
                                .then(async() => {
                                    await this.props.MembersContext.getAllMemberInfos(this.props.MembersContext.currentMember.member_id)
                                    this.setState(() => ({ edit: false }))
                                })
                                .catch((error) => {
                                    if(!AxiosIsCancelled(error.message)){
                                        console.error(error.message);

                                        if(error.i18n){
                                            setStatus(<DisplayI18n field='message' defaultValue={error.message} i18n={error.i18n} />)
                                        }else{
                                            setStatus(error.message);
                                        }

                                        setSubmitting(false);
                                    }
                                })
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Card className='card-shadow shadow-primary'>
                                    <OverlayLoader isLoading={formik.isSubmitting}>
                                        <CardHeader>
                                            <div className="d-flex align-items-center">
                                                <div className="h4 font-bold mb-0"><Translate id='members.profile.overview.memberPrimaryInformation.edit.title' /></div>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <CanDoAction action='EDIT' componentCode='members' componentPermissionCode='require_mandatory_fields'>
                                                {this.props.OrganizationContext.settings?.request_mandatory_change_fields?.value?.length > 0 && this.props.showRequestChange &&
                                                    <MemberGeneralEditTopSection refreshMemberProfile={this.props.refreshMemberProfile} />
                                                }
                                            </CanDoAction>
                                            <Row form>
                                                <Col md='6' className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="first_name"
                                                        isRequired
                                                        for='firstName'
                                                        fallbackLabel={<Translate id='form.fields.firstName' />}
                                                    />
                                                    <FormikInputText id='firstName' name='firstName' trim autoComplete='new-password' disabled={!this.canModifyMemberField("first_name") && !formik.values.enableRequestChangeFields} />
                                                </Col>
                                                <Col md='6' className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="last_name"
                                                        isRequired
                                                        for='lastName'
                                                        fallbackLabel={<Translate id='form.fields.lastName' />}
                                                    />
                                                    <FormikInputText name='lastName' id='lastName' trim autoComplete='new-password' disabled={!this.canModifyMemberField("last_name") && !formik.values.enableRequestChangeFields} />
                                                </Col>
                                                <Col md='6' className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="birthdate"
                                                        isRequired
                                                        for='dateOfBirth'
                                                        fallbackLabel={<Translate id='form.fields.dateOfBirth' />}
                                                    />
                                                    <FormikDateTime
                                                        name='dateOfBirth'
                                                        id='dateOfBirth'
                                                        timeFormat={false}
                                                        isValidDate={(current) => moment(current).isBefore(moment())}
                                                        manualError={formik.errors?.dateOfBirth === "age_settings"}
                                                        disabled={!this.canModifyMemberField("birthdate") && !formik.values.enableRequestChangeFields}
                                                    />
                                                    {(!!this.props.OrganizationContext.settings.member_max_age || !!this.props.OrganizationContext.settings.member_min_age) &&
                                                        <small className={`text-${formik.errors?.dateOfBirth === "age_settings" ? "danger" : "muted"}`}>
                                                            <Translate
                                                                id="misc.maxMinAge"
                                                                values={{
                                                                    max: this.props.OrganizationContext.settings.member_max_age?.value || "-",
                                                                    min: this.props.OrganizationContext.settings.member_min_age?.value || "-",
                                                                }}
                                                            />
                                                        </small>
                                                    }
                                                </Col>
                                                <Col md='6' className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="email"
                                                        for='email'
                                                        fallbackLabel={<Translate id='form.fields.email' />}
                                                        withSpecialRequired
                                                    />
                                                    <FormikInputText type='email' name='email' id='email' trim autoComplete='new-password' disabled={!this.canModifyMemberField("email") && !formik.values.enableRequestChangeFields} />
                                                </Col>
                                                <Col md='6' className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="gender"
                                                        for='gender'
                                                        fallbackLabel={<Translate id='form.fields.gender' />}
                                                        isRequired
                                                    />
                                                    <FormikSelect
                                                        name="gender"
                                                        id="gender"
                                                        disabled={!this.canModifyMemberField("gender") && !formik.values.enableRequestChangeFields}
                                                        placeholder='form.fields.gender.placeholder'
                                                        loadingStatus='success'
                                                        defaultData={this.props.OrganizationContext.settings.gender_selection?.value?.map((gender) => ({
                                                            value: gender,
                                                            label: <GenderLabel gender={gender} />,
                                                            translateLabel: false,
                                                        })) || []}
                                                    />
                                                </Col>
                                                <Col md='6' className="mb-3">
                                                    <Label className='text-muted' for='member_types'>
                                                        <Translate id='members.profile.overview.memberPrimaryInformation.memberTypes' />
                                                    </Label>
                                                    {this.props.RolesContext.canDoAction('EDIT', 'members', 'member_member_types') ?
                                                        <FormikSelect
                                                            name="member_types"
                                                            id="member_types"
                                                            disabled={!this.canModifyMemberField("member_types") && !formik.values.enableRequestChangeFields}
                                                            multi preventSelectedReorder
                                                            renderOption={({ option }) => option.isGroup ? <Translate id={option.label} /> : <DisplayI18n field='name' defaultValue={option.label} i18n={option.i18n} />}
                                                            searchKeys={[
                                                                    `i18n.${this.props.I18nContext.getGenericLocale()}.name`,
                                                            ]}
                                                            loadData={(from) => {
                                                                switch (from){
                                                                    case 'CDM':
                                                                        return this.props.MembersContext.getMemberTypes(this.props.OrganizationContext.organisation_id)
                                                                            .then((memberTypes) => {
                                                                                const filteredMemberTypes = memberTypes.reduce((newArray, m) => {
                                                                                    if(((m.system_type === memberTypeSpecial && m.active == 1) || currentMemberTypes.some((mType) => mType.member_type.member_type_id === m.member_type_id)) && !newArray.some((n) => n.member_type_id === m.member_type_id)){
                                                                                        newArray.push({
                                                                                            value: m.member_type_id,
                                                                                            label: m.name,
                                                                                            i18n: m.i18n,
                                                                                            systemType: m.system_type,
                                                                                        })
                                                                                    }
                                                                                    return newArray
                                                                                }, [])
                                                                                return formatSelectData(
                                                                                    filteredMemberTypes,
                                                                                    {
                                                                                        getGroupId: (m) => m.systemType === 'SPECIAL' ? 'special' : 'normal',
                                                                                        getValue: (m) => m.value,
                                                                                        getLabel: (m) => m.label,
                                                                                        newGroupIndexes: {
                                                                                            0: {
                                                                                                groupId: 'normal',
                                                                                                label: 'members.profile.overview.memberPrimaryInformation.memberTypes.group.normal',
                                                                                            },
                                                                                            1: {
                                                                                                groupId: 'special',
                                                                                                label: 'members.profile.overview.memberPrimaryInformation.memberTypes.group.special',
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                );
                                                                            })
                                                                }
                                                            }}
                                                        />
                                                        :
                                                        <div className='font-medium' id='memberInfo-specialMemberTypes'>
                                                            {!currentMemberTypes?.length ?
                                                                '-'
                                                                :
                                                                currentMemberTypes?.map((m) => (
                                                                    <div key={m.member_type.member_type_id} data-specialMemberTypeId={m.member_type.member_type_id}>
                                                                        {getMemberTypeIcon(m.member_type)}
                                                                        <DisplayI18n field='name' defaultValue={m.member_type.name} i18n={m.member_type.i18n} />
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>

                                                    }
                                                </Col>
                                                <Col sm={12}>
                                                    <Collapse isOpen={formik.values.gender === 'PREFER_TO_SELF_DESCRIBE'}>
                                                        <Row form>
                                                            <Col sm={6} className="pb-3">
                                                                <MemberFieldLabel
                                                                    className="text-muted"
                                                                    hideDescription
                                                                    field="gender_description"
                                                                    for='gender_description-input'
                                                                    fallbackLabel={<Translate id="form.fields.genderDescription" />}
                                                                />
                                                                <FormikTextArea
                                                                    id='gender_description-input'
                                                                    name='gender_description'
                                                                    rows={1}
                                                                    trim
                                                                    disabled={!this.canModifyMemberField("gender_description") && !formik.values.enableRequestChangeFields}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Collapse>
                                                </Col>
                                                <Col md='6' className="mb-3">
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="primary_language"
                                                        for='primaryLanguage'
                                                        fallbackLabel={<Translate id='form.fields.primaryLanguage' />}
                                                        withSpecialRequired
                                                    />
                                                    <FormikSelect
                                                        name="primaryLanguage"
                                                        id="primaryLanguage"
                                                        clearable
                                                        disabled={!this.canModifyMemberField("primary_language") && !formik.values.enableRequestChangeFields}
                                                        onOptionSelected={([ value ]) => {
                                                            formik.setFieldValue('secondaryLanguages', formik.values.secondaryLanguages.filter((lang) => lang !== value));
                                                        }}
                                                        defaultData={[
                                                            {
                                                                value: 'fr',
                                                                label: 'misc.fr.long',
                                                                translateLabel: true,
                                                            },
                                                            {
                                                                value: 'en',
                                                                label: 'misc.en.long',
                                                                translateLabel: true,
                                                            },
                                                        ]}
                                                        loadingStatus='success'
                                                        search={false}
                                                    />
                                                </Col>
                                                <Col md='6' className="mb-3" key={formik.values.primaryLanguage}>
                                                    <MemberFieldLabel
                                                        className="text-muted"
                                                        hideDescription
                                                        field="secondary_languages"
                                                        for='secondaryLanguages'
                                                        fallbackLabel={<Translate id='form.fields.secondaryLanguages' />}
                                                        withSpecialRequired
                                                    />
                                                    <FormikSelect
                                                        multi
                                                        clearable
                                                        search={false}
                                                        disabled={!this.canModifyMemberField("secondary_languages") && !formik.values.enableRequestChangeFields}
                                                        name="secondaryLanguages"
                                                        id="secondaryLanguages"
                                                        placeholder='form.fields.primaryLanguage.placeholder'
                                                        loadingStatus='success'
                                                        isOptionDisabled={(opt) => opt.value === formik.values.primaryLanguage}
                                                        defaultData={
                                                            [
                                                                {
                                                                    value: 'fr',
                                                                    label: 'misc.fr.long',
                                                                    translateLabel: true,
                                                                },
                                                                {
                                                                    value: 'en',
                                                                    label: 'misc.en.long',
                                                                    translateLabel: true,
                                                                },
                                                            ]
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Collapse isOpen={!!formik.status} appear mountOnEnter unmountOnExit>
                                                {formik.status &&
                                                    <CustomAlert className='mt-3 mb-0' color='danger' withTitle text={formik.status} translateText={false} toggle={() => formik.setStatus()} />
                                                }
                                            </Collapse>
                                        </CardBody>
                                        <CardFooter className='bg-white d-flex justify-content-between'>
                                            {this.props.OrganizationContext.settings?.request_mandatory_change_fields?.value?.length > 0 && this.props.showRequestChange &&
                                                <IsGod>
                                                    <Button type='button' color="primary" disabled={formik.isSubmitting} onClick={() => formik.setFieldValue('enableRequestChangeFields', !formik.values.enableRequestChangeFields)}>
                                                        <Translate id={formik.values.enableRequestChangeFields ? 'members.profile.overview.memberPrimaryInformation.edit.disableFields' : 'members.profile.overview.memberPrimaryInformation.edit.enableFields'} />
                                                    </Button>
                                                </IsGod>
                                            }
                                            <div className='ml-auto'>
                                                {(formik.dirty && !arraysEqual(currentMemberTypes?.map((m) => (m.member_type.member_type_id)), formik.values.member_types)) ?
                                                    <InlineConfirmation
                                                        primaryButtonTranslation={'members.profile.overview.memberPrimaryInformation.edit.save'}
                                                        confirmationTooltipTitle='members.profile.overview.memberPrimaryInformation.edit.saveConfirmTitle'
                                                        confirmationTooltipText='members.profile.overview.memberPrimaryInformation.edit.saveConfirmDesc'
                                                        color={'primary'}
                                                        disabled={formik.isSubmitting}
                                                        onClick={formik.submitForm}
                                                        tooltipZIndex={1}
                                                        solidConfirmButton
                                                    />
                                                    :
                                                    <Button type='submit' color="primary" disabled={formik.isSubmitting}>
                                                        <Translate id='members.profile.overview.memberPrimaryInformation.edit.save' />
                                                    </Button>
                                                }
                                                <Button type='button' className='ml-2' color="primary" outline onClick={this.toggleEdit} disabled={formik.isSubmitting}>
                                                    <Translate id='members.profile.overview.memberPrimaryInformation.edit.cancel' />
                                                </Button>
                                            </div>
                                        </CardFooter>
                                    </OverlayLoader>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                }
            </>
        )
    }
}

export default withContexts(MembersContext, UtilsContext, I18nContext, UtilsContext, OrganizationContext, PeriodsContext, RolesContext)(MemberGeneral);