import { stringBuilder } from "@spordle/helpers";
import styles from "./EmailIcon.module.scss";

const EmailIcon = ({ color, className }) => {
    const textColor = color ? `text-${color}` : "";

    return (
        <span className={stringBuilder(styles.Container, textColor, className)}>
            <span className={styles.Envelop}>
                <span className={styles.Letter} />
                <span className={styles.TopFlap} />
                <span className={styles.HorizontalFlaps} />
                <span className={styles.BottomFlap} />
            </span>
        </span>
    );
}

export default EmailIcon;