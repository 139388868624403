import SpordleTableProvider, { SearchInput, SpordleTableView } from "@spordle/datatables";
import Translate from "@spordle/intl-elements";
import { useContext } from "react";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import SpordlePanelTable from "../../../components/sidePanel/SpordlePanel";
import EmptyLayout from "../../../components/table/EmptyLayout";
import TablePagination from "../../../components/table/TablePagination";
import UserDisplay from "../../../components/userDisplay/UserDisplay";
import UserImg from "../../../components/UserImg";
import { I18nContext } from "../../../contexts/I18nContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { DisplayI18n } from "../../../helpers/i18nHelper";
import { DisplayCategory, DisplayTeamCategory } from "../TeamHelpers";
import CompetitionTeamsSearchFilters from "./CompetitionTeamsSearchFilters";
import CompetitionTeamsSearchSidePanel from "./sidePanel/CompetitionTeamsSearchSidePanel";
import CompetitionTeamStatus from "./CompetitionTeamStatus";
import CanDoAction from "../../../components/permissions/CanDoAction";
import AddCompetitionTeam from "./AddCompetitionTeam";
import DisplayOrg from "../../../components/DisplayOrg";
import { Link } from 'react-router-dom';

const CompetitionTeamSearch = () => {
    const { getGenericLocale } = useContext(I18nContext);
    const { getCompetitionTeams, organisation_id } = useContext(OrganizationContext);

    const genericLocale = getGenericLocale();

    return (
        <SpordlePanelTable
            allowOutsideClick
            dataIndex="competition_team_id"
            sidePanel={(props) => <CompetitionTeamsSearchSidePanel {...props} />}
            table={(panelProps) => {
                return (
                    <SpordleTableProvider
                        id='team-search'
                        tableHover bordered striped
                        clickable desktopWhen='sm'
                        pagination={25}
                        dataIndex="competition_team_id"
                        defaultSorting="+competition"
                        emptyLayout={<EmptyLayout />}
                        ref={panelProps.spordleTableRef}
                        rowIsHighlighted={(team) => team.checked}
                        tableClassName={panelProps.sidePanelOpen ? 'sidePanel-focus' : void 0}
                        paginationMessage='teams.teamSearch.table.paginationMessage'
                        initFilter={{
                            search: {
                                competition_id: '',
                                division_id: '',
                                team_category_id: '',
                                competition_team_status_id: '',
                            },
                        }}
                        defaultAdditionalData={{
                            competitions: null,
                            status: null,
                            teams: null,
                        }}
                        searchKeys={[
                            { name: `competition.i18n.${getGenericLocale()}.name`, weight: 2 },
                            `team.name`,
                            'team.unique_identifier',
                            `team.organisation.i18n.${getGenericLocale()}.name`,
                            `competition_team_status.i18n.${getGenericLocale()}.name`,
                            `competition.team_category.class.i18n.${getGenericLocale()}.name`,
                            `competition.division.i18n.${getGenericLocale()}.name`,
                            `team.team_category.class.i18n.${getGenericLocale()}.name`,
                            `team.division.i18n.${getGenericLocale()}.name`,
                        ]}
                        loadData={(from, { filters }, { setLoading, setAdditionalData }) => {
                            const searchFilters = filters.search;

                            const getData = (orgId) => {
                                setLoading();

                                return getCompetitionTeams(orgId)
                                    .then((data) => {
                                        setAdditionalData(data);
                                        return data.teams;
                                    });
                            }

                            switch (from){
                                case 'REFRESH':
                                case 'FILTER':
                                case 'CDM':
                                    return getData(organisation_id)
                                        .then((competitionTeams) => {
                                            const filtered = competitionTeams.reduce((filteredTeams, compTeam) => {
                                                const statusIsDiff = !!searchFilters.competition_team_status_id && (compTeam.competition_team_status_id !== searchFilters.competition_team_status_id);
                                                const compIsDiff = !!searchFilters.competition_id && (compTeam.competition_id !== searchFilters.competition_id);
                                                const orgIsDiff = !!searchFilters.organisation_id && (compTeam.team?.organisation_id !== searchFilters.organisation_id);

                                                const canAdd = !statusIsDiff && !compIsDiff && !orgIsDiff;

                                                if(canAdd){
                                                    filteredTeams.push(compTeam);
                                                }

                                                return filteredTeams;
                                            }, []);

                                            if([ "CDM", "REFRESH" ].includes(from)){
                                                panelProps.syncRefresh(filtered);
                                            }

                                            return filtered;
                                        })
                                default:
                                    break;
                            }
                        }}
                        columns={[
                            {
                                // yes keep this
                                label: '-',
                                key: 'checkbox',
                                className: 'th-shrink',
                                dataClassName: 'td-shrink d-none d-sm-table-cell', // hide checkbox on mobile
                            },
                            {
                                label: <Translate id="teams.teamSearch.competition" />,
                                key: 'competition',
                                sortKey: () => `competition.team_category.i18n.${genericLocale}.name`,
                                fallbackSortKey: 'competition.team_category.name',
                                sortable: true,
                            },
                            {
                                label: <Translate id='teams.teamSearch.table.label.team' />,
                                key: 'team',
                                sortKey: () => `team.i18n.${genericLocale}.name`,
                                fallbackSortKey: 'team.name',
                                sortable: true,
                            },
                            {
                                label: <Translate id='teams.teamSearch.table.label.org' />,
                                key: 'org',
                                sortKey: () => `team.organisation.i18n.${genericLocale}.name`,
                                fallbackSortKey: 'team.organisation.organisation_name',
                                sortable: true,
                            },
                            {
                                label: <Translate id='misc.status' />,
                                key: 'competition_team_status',
                                sortKey: () => `competition_team_status.i18n.${genericLocale}.name`,
                                fallbackSortKey: 'competition_team_status.name',
                                sortable: true,
                            },
                        ]}
                        onColumnClick={(e, competitionTeam, _, columnKey) => {
                            switch (e.button){
                                case 0: // Left mouse button
                                    if(columnKey === 'checkbox'){
                                        panelProps.onMultiSelectChange(competitionTeam);
                                    }else{
                                        panelProps.onSingleSelectChange(competitionTeam);
                                    }
                                    break;
                            }
                        }}
                        renderRow={(columnKey, competitionTeam) => {
                            switch (columnKey){
                                case 'checkbox':
                                    return (
                                        <label className='my-auto' htmlFor={'checkbox-' + competitionTeam.competition_team_id} onClick={(e) => e.stopPropagation()}>
                                            <input
                                                onChange={() => panelProps.onMultiSelectChange(competitionTeam)}
                                                id={'checkbox-' + competitionTeam.competition_team_id}
                                                type='checkbox'
                                                checked={!!competitionTeam.checked}
                                            />
                                            <span className='table-checkbox' />
                                        </label>
                                    )
                                case 'competition':
                                    const competition = competitionTeam.competition;

                                    return (
                                        <>
                                            <UserDisplay.Title>
                                                <DisplayI18n
                                                    field="name"
                                                    i18n={competition?.i18n}
                                                    defaultValue={competition?.name}
                                                />
                                            </UserDisplay.Title>
                                            <UserDisplay.Subtitle className="text-muted">
                                                <DisplayTeamCategory ressource={competition} />
                                            </UserDisplay.Subtitle>
                                        </>
                                    )
                                case 'org':
                                    const org = competitionTeam.team?.organisation;

                                    if(!org){
                                        return "-";
                                    }

                                    return (
                                        <UserDisplay>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    abbr={org.organisation_name}
                                                    alt={org.organisation_name}
                                                    filePos={org.logo?.file_position}
                                                    src={org.logo?.full_path}
                                                    width={40}
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>
                                                    <DisplayOrg org={org} />
                                                </UserDisplay.Title>
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    )
                                case 'team':
                                    const team = competitionTeam.team;

                                    if(!team){
                                        return "-";
                                    }

                                    return (
                                        <UserDisplay>
                                            <UserDisplay.Container>
                                                <UserImg
                                                    abbr={team.abbreviation || team.short_name || team.name}
                                                    alt={team.name}
                                                    filePos={team.logo?.file_position}
                                                    src={team.logo?.full_path}
                                                    width={40}
                                                />
                                            </UserDisplay.Container>
                                            <UserDisplay.Container>
                                                <UserDisplay.Title>{team.name}</UserDisplay.Title>
                                                <Link to={`/teams/profile/${competitionTeam.team_id}`} className="text-nowrap">
                                                    {team.unique_identifier ?
                                                        `#${team.unique_identifier}`
                                                        :
                                                        <Translate id="misc.goToProfile" />
                                                    }
                                                    <i className="ml-1 mdi mdi-chevron-right" />
                                                </Link>
                                                <UserDisplay.Subtitle>
                                                    <DisplayCategory category={team.team_category} />
                                                </UserDisplay.Subtitle>
                                                {team.team_category?.gender &&
                                                    <UserDisplay.Subtitle>
                                                        <Translate id={`form.fields.gender.${team.team_category.gender.toLowerCase()}`} />
                                                    </UserDisplay.Subtitle>
                                                }
                                            </UserDisplay.Container>
                                        </UserDisplay>
                                    )
                                case 'competition_team_status':
                                    return (
                                        <CompetitionTeamStatus
                                            status={competitionTeam.competition_team_status}
                                        />
                                    )
                                default:
                                    break;
                            }
                        }}
                    >
                        {(table) => (
                            <>
                                <CanDoAction action="ADD" componentPermissionCode="manage_competition_teams" componentCode="organization">
                                    <AddCompetitionTeam onSuccess={() => table.refreshTable()} />
                                </CanDoAction>
                                <CompetitionTeamsSearchFilters />
                                <Card className="card-shadow" body>
                                    <div className='mb-2'>
                                        <div className='d-flex flex-wrap justify-content-between'>
                                            {/* yes we keep this checkbox */}
                                            <div className="d-flex mb-2 mb-sm-0">
                                                <UncontrolledDropdown className='d-none d-sm-block'>
                                                    <DropdownToggle color='primary' className='d-flex align-items-center mr-2' caret>
                                                        <div className='align-self-center custom-checkbox custom-control'>
                                                            <input id='topCheckID' type='checkbox' className='custom-control-input d-none' checked={panelProps.topChecked} onChange={panelProps.onTopCheckChange} />
                                                            <label className='custom-control-label' htmlFor='topCheckID' />
                                                        </div>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={panelProps.checkAll}><Translate id='misc.all' /></DropdownItem>
                                                        <DropdownItem onClick={panelProps.uncheckAll}><Translate id='misc.none' /></DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                <SearchInput />
                                            </div>
                                            <TablePagination paginationArray={[ 25, 50, 100, 200 ]} />
                                        </div>
                                    </div>
                                    <SpordleTableView />
                                </Card>
                            </>
                        )}
                    </SpordleTableProvider>
                )
            }}
        />
    );
}

export default CompetitionTeamSearch;