import { FormikTextArea } from "@spordle/formik-elements";
import Translate from "@spordle/intl-elements";
import { Form, Formik } from "formik";
import moment from "moment";
import { useContext, useState } from "react";
import { Alert, Button, Collapse, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AnalyticsModal from "../../../../../../../analytics/AnalyticsModal";
import { AxiosIsCancelled } from "../../../../../../../api/CancellableAPI";
import CrossFade from "../../../../../../../components/crossFade/CrossFade";
import OverlayLoader from "../../../../../../../components/loading/OverlayLoader";
import { ClinicsContext } from "../../../../../../../contexts/ClinicsContext";
import { IdentityRolesContext } from "../../../../../../../contexts/IdentityRolesContext";
import { DisplayI18n } from "../../../../../../../helpers/i18nHelper";
import CancelAttendeeCard from "./CancelAttendeeCard";
import ConfirmStep from "./ConfirmStep";
import MultiCancelledAttendees from "./MultiCancelledAttendees";

const AttendeeModalCancel = (props) => {
    return (
        <AnalyticsModal isOpen={props.isOpen} analyticsName="AttendeeModalCancel">
            <AttendeeModalCancelInner {...props} />
        </AnalyticsModal>
    );
}

const AttendeeModalCancelInner = ({ toggle, attendees, sendMessage, syncRows, createNewValues }) => {
    const { currentClinic, partiallyUpdateClinicAttendeeState } = useContext(ClinicsContext);
    const { identity } = useContext(IdentityRolesContext);
    const [ confirmationStep, setConfirmationStep ] = useState({
        feedback: {
            failed: [],
            success: [],
            reason: null,
            cancelledAt: "",
        },
        isVisible: false,
    });

    const { valid, invalid } = attendees.reduce((sortedAttendees, attendee) => {
        const canBeCancelled = !attendee.cancelled_at && attendee.attended != "1";

        sortedAttendees[canBeCancelled ? 'valid' : 'invalid'].push(attendee);

        return sortedAttendees;
    }, { valid: [], invalid: [] });

    const refreshTable = (cancelReason) => {
        syncRows(createNewValues(
            {
                cancelled_at: confirmationStep.feedback.cancelledAt,
                cancelled_reason: cancelReason,
                cancelled_by: identity,
            },
            confirmationStep.feedback.failed.map((a) => a.clinic_attendee_id),
        ))
    }

    return (
        <Formik
            initialStatus={{
                isVisible: false,
                msg: null,
            }}
            initialValues={{
                cancelReason: "",
                mode: "CANCEL",
            }}
            onSubmit={(values, { setStatus }) => {
                if(valid.length > 0){
                    return partiallyUpdateClinicAttendeeState(currentClinic.clinic_id, { ...values, attendeesIds: valid.map((a) => a.clinic_attendee_id) })
                        .then((data) => {
                            const feedback = valid.reduce((sortedAttendees, attendee) => {
                                const warning = data.warnings?.find(({ origin }) => origin.split(' : ')[1] === attendee.clinic_attendee_id);

                                if(warning){
                                    sortedAttendees.failed.push({
                                        ...attendee,
                                        failReason: warning,
                                    })
                                }else{
                                    sortedAttendees.success.push(attendee)
                                }

                                return sortedAttendees;
                            }, { failed: [], success: [], reason: values.cancelReason, cancelledAt: moment().format('YYYY-MM-DD HH:mm:ss') });

                            setConfirmationStep({
                                isVisible: true,
                                feedback: feedback,
                            });
                        })
                        .catch((e) => {
                            if(!AxiosIsCancelled(e.message)){
                                console.error(e);
                                setStatus({ msg: <DisplayI18n field="message" i18n={e.i18n} defaultValue={e.message} />, isVisible: true })
                            }
                        })

                }
                toggle();

            }}
        >
            {(formik) => (
                <Form>
                    <OverlayLoader isLoading={formik.isSubmitting}>
                        <CrossFade onEntered={() => refreshTable(formik.values.cancelReason)} isVisible={confirmationStep.isVisible}>
                            <ConfirmStep attendeesLength={attendees.length} sendMessage={sendMessage} feedback={confirmationStep.feedback} toggle={toggle} />
                        </CrossFade>
                        <CrossFade isVisible={!confirmationStep.isVisible}>
                            <ModalHeader toggle={toggle}>
                                <Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee' values={{ count: attendees.length }} />
                            </ModalHeader>
                            <ModalBody>
                                {valid.length > 0 &&
                                    <FormGroup>
                                        <Label for="cancelReason" className="text-muted">
                                            <Translate id='clinics.profile.attendees.sidepanel.actions.cancelAttendee.reason.label' />
                                        </Label>
                                        <FormikTextArea rows="2" name="cancelReason" id="cancelAttendeeReason" />
                                    </FormGroup>
                                }
                                {attendees.length > 1 ?
                                    <MultiCancelledAttendees valid={valid} invalid={invalid} />
                                    :
                                    <CancelAttendeeCard attendee={attendees[0]} />
                                }
                                <Collapse isOpen={formik.status.isVisible}>
                                    <div className="pt-3">
                                        <Alert color="danger" toggle={() => formik.setStatus({ ...formik.status, isVisible: false })}>
                                            {formik.status.msg}
                                        </Alert>
                                    </div>
                                </Collapse>
                            </ModalBody>
                            <ModalFooter>
                                {valid.length > 0 &&
                                    <Button color="primary" type="submit"><Translate id="misc.submit" /></Button>
                                }
                                <Button type="button" color="primary" onClick={toggle} outline><Translate id="misc.cancel" /></Button>
                            </ModalFooter>
                        </CrossFade>
                    </OverlayLoader>
                </Form>
            )}
        </Formik>
    )
}


export default AttendeeModalCancel;