import { useState } from "react";
import { Button, Card } from "reactstrap";
import Barcode from "react-barcode";
import { stringBuilder } from "@spordle/helpers";
import UserImg from "../UserImg";
import { displayI18n } from "../../helpers/i18nHelper";

const MemberNumberPrintableCard = ({ locale, member = {}, federation = {} }) => {
    const [ isLandscape, setIsLandscape ] = useState(true);

    return (
        <>
            <Button onClick={() => setIsLandscape((p) => !p)} color="primary" outline className="mr-2 d-print-none">
                <i style={isLandscape ? void 0 : { transform: 'rotate(-90deg)' }} className="mdi mdi-rotate-left-variant d-inline-block" />
            </Button>
            <Button color="primary" id="printBtn" type="button" className="mdi mdi-printer with-icon d-print-none">{locale == "fr" ? "Imprimer" : "Print"}</Button>
            <Card
                style={{
                    width: `${isLandscape ? 3.5 : 2}in`,
                    height: `${isLandscape ? 2 : 3.5}in`,
                    padding: '0.15in',
                }}
                className={stringBuilder({ "flex-column": !isLandscape }, "mt-3 border")}
            >
                <div style={{ fontSize: '9pt' }} className="d-flex align-items-center mb-3">
                    <UserImg
                        width={20}
                        filePos={federation.logo?.file_position}
                        src={federation.logo?.full_path}
                        className="mr-2"
                    />
                    {displayI18n("name", federation.i18n, federation.organisation_name, locale)}
                </div>
                <div className={stringBuilder({ "flex-column w-100": !isLandscape }, "d-flex align-items-center my-auto")}>
                    <div className={`m${isLandscape ? 'r' : 'b'}-3 flex-shrink-0`}>
                        <UserImg
                            src={member.picture?.full_path}
                            filePos={member.picture?.file_position}
                            abbr={member.first_name}
                            alt={member.first_name}
                            width={80}
                        />
                    </div>
                    <div className="w-100">
                        <div className="font-bold">{member.unique_identifier}</div>
                        <div className="font-medium">{member.first_name}{" "}<wbr />{member.last_name}</div>
                        <div style={{ fontSize: '9pt' }}>{member.age} {locale == "fr" ? "ans" : "years old"}</div>
                        {member.picture_expiration_date && <div style={{ fontSize: '9pt' }} className="text-muted">Exp.: {member.picture_expiration_date}</div>}
                    </div>
                </div>
                <div className="mt-auto pt-3 text-center">
                    <Barcode
                        value={member.unique_identifier}
                        height={isLandscape ? 20 : 30}
                        width={1}
                        textMargin={0}
                        margin={0}
                        displayValue={false}
                    />
                </div>
            </Card>
        </>
    );
}

export default MemberNumberPrintableCard;